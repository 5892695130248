import { OperationId } from './../../../operation/store/operation.model';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  Input,
} from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';

@Component({
  selector: 'app-operation-prescripteurs-list',
  templateUrl: './operation-prescripteurs-list.component.html',
  styleUrls: ['./operation-prescripteurs-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationPrescripteursListComponent
  implements OnInit, OnChanges, OnDestroy
{
  subscribe = new Subject();
  newCardTitle: string = '';
  newCardDefinition: string = '';
  newCardDescription: string = 'Aucun prescripteur ajouté';
  title: string = 'Prescripteur';
  noModuleTitle: string = 'Aucun prescripteur ajouté';
  prescripteurs$: Observable<any> = of(null);
  loading$: Observable<boolean> = of(false);
  view$ = new BehaviorSubject<string>('list');
  @Input() operation$: Observable<OperationId | any> = of(null);
  @Input() user$: Observable<any> = of(null);

  constructor() {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  onNew(operation: OperationId): void {}
  onBack(): void {}
}
