<app-layout-list
  *ngIf="
    (data$ | async)?.informationsCollectives?.length ||
      (informationsCollectives$ | async) | size;
    else noResults
  "
  [new]="new"
  [card]="card"
  [source]="(data$ | async)?.informationsCollectives"
></app-layout-list>

<ng-template #new>
  <app-card-new
    [type]="'informationCollective'"
    [title]="newCardTitle"
    [height]="'250px'"
    [description]="''"
    [definition]="newCardDefinition"
    (add)="onNewInfoCollective()"
  ></app-card-new>
</ng-template>

<ng-template #card let-item let-position="idx">
  <app-information-collective-card></app-information-collective-card>
</ng-template>

<ng-template #noResults>
  <app-no-result
    [icon]="'recent_actors'"
    [addtitle]="newCardDescription"
    [notitle]="'Aucune information collective'"
    [description]="newCardDefinition"
    (new)="onNewInfoCollective()"
  ></app-no-result>
</ng-template>

<ng-template #participantTitleForm>
  <app-header-title [icon]="'recent_actors'" [title]="title"></app-header-title>
</ng-template>
