import { BehaviorSubject, Observable, of } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Update } from '@ngrx/entity';
import { OperationCadrage } from './../../store/operation-cadrage.model';
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { OperationId } from 'src/app/components/operation/store/operation.model';

@Component({
  selector: 'app-operation-cadrage-simple',
  template: `
    <ng-container *ngIf="operation">
      <nz-descriptions
        nzSize="small"
        nzBordered
        *ngIf="!(editing$ | async)"
        [nzColumn]="{ xxl: 4, xl: 4, lg: 4, md: 4, sm: 2, xs: 1 }"
      >
        <nz-descriptions-item [nzTitle]="title" [nzSpan]="4">
          <div
            [innerHTML]="
              operation?.description?.definition?.textHTML | stringhtml
            "
          ></div>
        </nz-descriptions-item>
      </nz-descriptions>

      <form nz-form [formGroup]="cadrageForm" *ngIf="editing$ | async">
        <app-text-edithing
          (add)="onSetInput($event)"
          [update]="inputUpdate$"
        ></app-text-edithing>
      </form>

      <ng-template #title>
        <mat-icon>subject</mat-icon>
      </ng-template>

      <ng-template #cardExtra>
        <nz-row nzAlign="middle" nzGutter="8" nzJustify="end">
          <nz-col *ngIf="!(editing$ | async)" [nzSpan]="24">
            <button
              nz-button
              nzShape="circle"
              (click)="onUpdate(operation)"
              nzType="text"
            >
              <mat-icon>edit_note</mat-icon>
            </button>
          </nz-col>
          <nz-col *ngIf="editing$ | async" [nzSpan]="12">
            <button
              nz-button
              nzType="primary"
              (click)="onValidate(operation, type)"
              [disabled]="!cadrageForm.valid"
            >
              Valider
            </button>
          </nz-col>
          <nz-col *ngIf="editing$ | async" [nzSpan]="12">
            <button nz-button (click)="editing$.next(false)" nzType="text">
              Annuler
            </button>
          </nz-col>
        </nz-row>
      </ng-template>
    </ng-container>
  `,
  styles: [
    `
      :host {
        width: 100%;
        height: 100%;
      }

      nz-card {
        height: calc(100% - 80em);
        overflow-y: auto;
      }

      mat-icon {
        color: gray;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationCadrageSimpleComponent implements OnInit, OnChanges {
  cadrageForm: UntypedFormGroup = this.fb.group({});
  input: UntypedFormControl = new UntypedFormControl('');
  inputUpdate$ = new BehaviorSubject<string>('');
  editing$ = new BehaviorSubject<boolean>(false);

  @Input() type: string = '';
  @Input() operation: OperationId | any;
  @Output() update: EventEmitter<Update<OperationCadrage>> = new EventEmitter(
    false
  );
  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.formCreate();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  formCreate(): void {
    this.cadrageForm = this.fb.group({
      input: this.input,
    });
  }

  onUpdate(input: any): void {
    this.editing$.next(true);

    if (!input?.textValue) {
      this.inputUpdate$.next('');
    } else {
      this.inputUpdate$.next(input.textValue);
    }
  }

  onSetInput(event: any): void {
    this.cadrageForm.patchValue({
      input: event,
    });
  }

  onValidate(cadrage: OperationCadrage, type: string): void {
    if (!this.cadrageForm.valid) return;
    const value = this.cadrageForm.get('input')?.value;

    switch (type) {
      case 'context':
        var nextCadre: Update<OperationCadrage> = {
          id: cadrage.id,
          changes: {
            ...cadrage,
            context: value,
          },
        };

        this.onConfirmation(nextCadre);

        break;
      case 'finality':
        var nextCadre: Update<OperationCadrage> = {
          id: cadrage.id,
          changes: {
            ...cadrage,
            finality: value,
          },
        };

        this.onConfirmation(nextCadre);
        break;
      case 'methodologie':
        var nextCadre: Update<OperationCadrage> = {
          id: cadrage.id,
          changes: {
            ...cadrage,
            methodologie: value,
          },
        };

        this.onConfirmation(nextCadre);
        break;
      case 'benefices':
        var nextCadre: Update<OperationCadrage> = {
          id: cadrage.id,
          changes: {
            ...cadrage,
            benefices: value,
          },
        };

        this.onConfirmation(nextCadre);
        break;

      default:
        break;
    }
  }

  onConfirmation(cadrage: Update<OperationCadrage>): void {
    this.update.emit(cadrage);
    this.cadrageForm.reset();
    this.editing$.next(false);
  }
}
