import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { add } from 'date-fns';
import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';
import { FicheDePosteState } from '../../store/fiche-de-poste.reducer';
import * as fromFicheDePosteAction from '../../store/fiche-de-poste.actions';
import * as fromFicheDePosteSelector from '../../store/fiche-de-poste.selectors';
import { ContratState } from 'src/app/contents/components/contrat/store/contrat.reducer';
import * as fromContratAction from 'src/app/contents/components/contrat/store/contrat.actions';

@Component({
  selector: 'app-fiches-de-poste-form',
  templateUrl: './fiches-de-poste-form.component.html',
  styleUrls: ['./fiches-de-poste-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FichesDePosteFormComponent implements OnInit, OnChanges {
  loading$: Observable<any> = of(null);
  currentStep: number = 0;
  currentStep$ = new BehaviorSubject<number>(0);
  currentEtablissement$ = new BehaviorSubject<any>(null);
  subscribe = new Subject();
  today: Date = new Date(Date.now());
  isAdd: boolean = true;
  stepOneTitle: string = 'Entreprise';
  stepTwoTitle: string = 'Mission';
  stepTreeTitle: string = 'Contacts';
  stepFourTitle: string = 'Finaliser';
  isContratUpdate: string = '';
  isMetierUpdate: string = '';
  contacts$ = new BehaviorSubject<any>(null);

  //FORM
  participantsSize = new FormControl(2, Validators.required);
  isClauseInsertion = new FormControl<boolean>(false);
  geoLimitation = new FormControl<{
    commune: { code: string; nom: string };
    departement: { code: string; nom: string };
    region: { code: string; nom: string };
  }>({
    commune: { code: '', nom: '' },
    departement: { code: '', nom: '' },
    region: { code: '', nom: '' },
  });

  entite = new FormControl<any>('', Validators.required);
  mission = new FormControl<any>('', Validators.required);
  contacts = new FormControl<any>([], Validators.required);
  recherche = new FormControl<{
    participantsSize: number;
    isClauseInsertion: boolean;
    geoLimitation: {
      commune: {
        code: string;
        nom: string;
      };
      departement: { code: string; nom: string };
      region: { code: string; nom: string };
    };
  }>(
    {
      participantsSize: 2,
      isClauseInsertion: false,
      geoLimitation: {
        commune: { code: '', nom: '' },
        departement: { code: '', nom: '' },
        region: { code: '', nom: '' },
      },
    },
    Validators.required
  );
  isClosed = new FormControl<boolean>(false);

  ficheDePosteForm = new FormGroup({
    entite: this.entite,
    mission: this.mission,
    contacts: this.contacts,
    recherche: this.recherche,
    isClosed: this.isClosed,
  });

  @Input() fiche$ = new BehaviorSubject<any>(null);
  @Input() operationItem: any = null;
  @Input() isOperation: boolean = true;
  @Input() lotItems: any = null;
  @Input() isLot: boolean = false;
  @Input() marcheItems: any = null;
  @Input() isMarche: boolean = false;

  @Input() validationTitle: string = 'Valider la fiche de poste';
  @Output() add = new EventEmitter(false);
  @Output() update = new EventEmitter(false);
  @Output() cancel = new EventEmitter(false);

  constructor(
    private fb: UntypedFormBuilder,
    private contratStore: Store<ContratState>
  ) {}

  ngOnInit(): void {
    this.formUpdate();
    this.load_referentiels();
  }
  ngOnChanges(changes: SimpleChanges): void {}

  onAdd(): void {
    if (!this.ficheDePosteForm.valid) return;
    const formvalues = this.ficheDePosteForm.value;
    this.add.emit(formvalues);
  }
  onCancel(): void {
    this.cancel.emit(true);
  }

  formUpdate(): void {
    this.fiche$.pipe(takeUntil(this.subscribe)).subscribe((fiche) => {
      if (!fiche) {
        this.isAdd = true;
        this.isContratUpdate = '';
        this.isMetierUpdate = '';

        return;
      }
      const { entite, mission, contacts, recherche, isClosed } = fiche;

      this.ficheDePosteForm.patchValue({
        entite: entite,
        recherche: recherche,
        isClosed: isClosed,
        contacts: contacts,
        mission: mission,
      });
      this.contacts$.next(contacts);

      this.isAdd = false;
      return;
    });
  }

  //REFERENTIELS
  load_referentiels(): void {
    this.load_naturescontrat();
    this.load_typescontrat();
    this.load_experiencescontrat();
    this.load_salairescontrat();
    this.load_permis();
    this.load_level_numerique();
    this.load_geo_deplacement();
    this.load_complement_salaire();
  }
  load_typescontrat(): void {
    this.contratStore.dispatch(fromContratAction.loadTypesContrats());
  }
  load_naturescontrat(): void {
    this.contratStore.dispatch(fromContratAction.loadNaturesContrats());
  }
  load_experiencescontrat(): void {
    this.contratStore.dispatch(fromContratAction.loadNiveauExperiences());
  }
  load_salairescontrat(): void {
    this.contratStore.dispatch(fromContratAction.loadNiveauSalaire());
  }
  load_permis(): void {
    this.contratStore.dispatch(fromContratAction.loadPermis());
  }
  load_level_numerique(): void {
    this.contratStore.dispatch(fromContratAction.loadLevelNumerique());
  }
  load_geo_deplacement(): void {
    this.contratStore.dispatch(fromContratAction.loadMobilityGeographic());
  }
  load_complement_salaire(): void {
    this.contratStore.dispatch(fromContratAction.loadComplementSalaire());
  }

  //METIER
  onSelectMetier(mission: any): void {
    if (!mission) return;
    this.ficheDePosteForm.patchValue({
      mission: mission,
    });
  }
  onUpdateMetier(mission: any): void {
    if (!mission) {
      this.isAdd = true;
      return;
    }
    const { adresse, metier, contrat, operation } = mission;
    const prevMission = this.ficheDePosteForm.value.mission;
    const prevMetier = prevMission.metier;
    const prevContrat = prevMission.contrat;
    const prevAdresse = prevMission.adresse;
    const prevOperation = prevMission?.operation || {};

    this.ficheDePosteForm.patchValue({
      mission: {
        ...prevMission,
        adresse: adresse?.adresse?.properties?.label ? adresse : prevAdresse,
        metier: metier?.libelle ? metier : prevMetier,
        contrat: contrat?.type?.libelle ? contrat : prevContrat,
        operation: operation?.denomination ? operation : prevOperation,
      },
    });

    this.isAdd = false;
  }

  onChangeContact(contacts: any): void {
    this.ficheDePosteForm.patchValue({
      contacts: contacts,
    });
  }

  onChangeRecherche(event: any): void {
    this.ficheDePosteForm.patchValue({
      recherche: { ...event },
    });
  }

  //FICHE NAVIGATION
  onStepBack(step: number): void {
    const nextStep = step > 0 ? step - 1 : 0;
    this.currentStep = nextStep;

    this.currentStep$.next(nextStep);
  }
  onStepNext(step: number): void {
    const nextStep = step < 3 ? step + 1 : 3;
    this.currentStep = nextStep;
    this.currentStep$.next(nextStep);
  }

  //ETABLISSEMENT
  //SELECT ETABLISSEMENT
  onSelectEntite(etablissement: any): void {
    if (!etablissement) return;
    const denomination: string =
      etablissement?.uniteLegale?.denominationUniteLegale;

    this.currentEtablissement$.next(denomination);
    this.ficheDePosteForm.patchValue({ entite: etablissement });
  }
  //CLEAR ETABLISSEMENT
  onClearEntite(): void {
    this.ficheDePosteForm.get('entite')?.setValue(null);
    this.currentEtablissement$.next('');
  }

  //MISSION
  //MISSION NAVIGATION
  onStepBackMission(step: number): void {
    if (step === 0) {
      this.onStepBack(0);
    }
  }

  //MISSION CLEAR
  onCleanMission(): void {
    const formValue = this.ficheDePosteForm.value;
    const mission = formValue.mission;
    this.isMetierUpdate = 'UPDATE_METIER';
    this.isContratUpdate = '';
    // this.ficheDePosteForm.patchValue({
    //   mission: {
    //     ...mission,
    //     metier: null,
    //   },
    // });
  }

  //CONTRAT
  //CLEAR CONTRAT
  onCleanContrat(): void {
    const formValue = this.ficheDePosteForm.value;
    const mission = formValue.mission;
    this.isContratUpdate = 'UPDATE_CONTRAT';
    this.isMetierUpdate = '';

    // this.ficheDePosteForm.patchValue({
    //   mission: {
    //     ...mission,
    //     contrat: null,
    //   },
    // });
  }

  onCancelMission(): void {
    this.currentStep = 0;
  }

  onValidateMission(): void {
    this.currentStep = 2;
  }

  onUpdateValidations(): void {
    if (!this.ficheDePosteForm.valid) return;
    const values = this.ficheDePosteForm.value;
    this.isContratUpdate = '';
    this.isMetierUpdate = '';
    this.update.emit(values);
  }
  onValidation(): void {
    if (!this.ficheDePosteForm.valid) return;
    const values = this.ficheDePosteForm.value;
    this.isContratUpdate = '';
    this.isMetierUpdate = '';
    this.add.emit(values);
  }
}
