import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, Subject, takeUntil } from 'rxjs';
import { EntiteId } from 'src/app/components/entite/store/entite.model';
import {
  ItemEvent,
  ItemEventId,
} from 'src/app/features/suivi/components/item-event/itemEvent.model';
import { EntiteSuiviState } from '../../store/entite-suivi.reducer';
import * as fromEntiteSuiviAction from '../../store/entite-suivi.actions';
import * as fromEntiteSuiviSelector from '../../store/entite-suivi.selectors';
import { Update } from '@ngrx/entity';

@Component({
  selector: 'app-entite-suivi',
  templateUrl: './entite-suivi.component.html',
  styleUrls: ['./entite-suivi.component.scss'],
})
export class EntiteSuiviComponent implements OnInit, OnDestroy {
  subscribe = new Subject();
  suivis$: Observable<ItemEventId[] | any> = of([]);
  loading$: Observable<boolean> = of(false);
  @Input() entite$: Observable<EntiteId | any> = of([]);

  constructor(private entiteSuiviStore: Store<EntiteSuiviState>) {}

  ngOnInit(): void {
    this.getSuivis();
    this.loadSuivis();
    this.getLoading();
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getLoading(): void {
    this.loading$ = this.entiteSuiviStore.select(
      fromEntiteSuiviSelector.loading
    );
  }

  getSuivis(): void {
    this.suivis$ = this.entiteSuiviStore.select(fromEntiteSuiviSelector.suivis);
  }

  loadSuivis(): void {
    this.entite$
      .pipe(takeUntil(this.subscribe))
      .subscribe((entite: EntiteId) => {
        if (!entite) {
          return;
        } else {
          const { id } = entite;
          this.entiteSuiviStore.dispatch(
            fromEntiteSuiviAction.loadEntiteSuivis({ id })
          );
        }
      });
  }

  onAdd(event: ItemEvent, entite: EntiteId): void {
    const { id } = entite;
    const suivi = {
      dateStart: event.dateStart,
      hourStart: event.hourStart,
      object: event.object,
      title: event.title,
      type: event.type,
      text: event.text,
      textHtml: event.textHtml,
    };
    this.entiteSuiviStore.dispatch(
      fromEntiteSuiviAction.addEntiteSuivi({ id, suivi })
    );
  }
  onUpdate(suivi: ItemEventId, entite: EntiteId): void {
    const { id } = entite;
    const nextSuivi: Update<ItemEventId> = {
      id: suivi.id,
      changes: {
        id: suivi.id,
        dateStart: suivi.dateStart,
        hourStart: suivi.hourStart,
        object: suivi.object,
        title: suivi.title,
        type: suivi.type,
        text: suivi.text,
        textHtml: suivi.textHtml,
      },
    };
    this.entiteSuiviStore.dispatch(
      fromEntiteSuiviAction.updateEntiteSuivi({ id, suivi: nextSuivi })
    );
  }
  onDelete(event: ItemEventId, entite: EntiteId): void {
    const { id } = entite;
    this.entiteSuiviStore.dispatch(
      fromEntiteSuiviAction.deleteEntiteSuivi({
        entiteId: id,
        id: event.id,
      })
    );
  }

  onDeleteAll(id: string): void {
    if (!id) return;
    this.entiteSuiviStore.dispatch(
      fromEntiteSuiviAction.deleteEntiteSuivis({ id, ids: [] })
    );
  }
}
