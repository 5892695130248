import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import {
  Participant,
  ParticipantSmallId,
  ParticipantId,
} from './participant.model';
import * as ParticipantActions from './participant.actions';
import { OperationSmallId } from '../../operation/store/operation.model';

export const participantsFeatureKey = 'participants';

export interface ParticipantState extends EntityState<ParticipantSmallId> {
  loading: boolean;
  participant: ParticipantId | any;
  operations: any;
  operation: any;
  fileProgress: any;
  aids: any;
  projetsprofessionnels: any;
  statistiques: any;
  filters: any;
  experiences: any;
  error: any;
}

export const adapter: EntityAdapter<ParticipantSmallId> =
  createEntityAdapter<ParticipantSmallId>();

export const initialState: ParticipantState = adapter.getInitialState({
  loading: false,
  participant: undefined,
  suivis: undefined,
  suivi: undefined,
  documents: undefined,
  document: undefined,
  operations: undefined,
  operation: undefined,
  fileProgress: undefined,
  aids: undefined,
  projetsprofessionnels: undefined,
  statistiques: undefined,
  filters: undefined,
  experiences: undefined,

  error: undefined,
});

export const reducer = createReducer(
  initialState,
  on(ParticipantActions.loading, (state, action) => {
    return {
      ...state,
      loading: action.loading,
    };
  }),
  //FILTERS
  on(ParticipantActions.loadParticipantFilters, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(ParticipantActions.loadParticipantFiltersSuccess, (state, action) => {
    return {
      ...state,
      filters: action.filters,
    };
  }),
  on(ParticipantActions.loadParticipantFiltersFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //LOAD PARTICIPANTS
  on(ParticipantActions.loadParticipants, (state, action) => {
    return {
      ...state,
      participant: undefined,
      operations: undefined,
      operation: undefined,
      fileProgress: undefined,
      error: undefined,
    };
  }),
  on(ParticipantActions.loadParticipantsSuccess, (state, action) =>
    adapter.setAll(action.participants, state)
  ),
  on(ParticipantActions.loadParticipantsFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //LOAD PARTICIPANT
  on(ParticipantActions.loadParticipantSuccess, (state, action) => {
    return {
      ...state,
      participant: action.participant,
    };
  }),
  on(ParticipantActions.loadParticipantFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //ADD ONE
  on(ParticipantActions.addParticipantSuccess, (state, action) =>
    adapter.addOne(action.participant, state)
  ),
  on(ParticipantActions.addParticipantFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //QUERY
  on(ParticipantActions.queryParticipants, (state, action) => {
    return {
      ...state,
      participant: undefined,
    };
  }),
  on(ParticipantActions.queryParticipantsSuccess, (state, action) =>
    adapter.setAll(action.participants, state)
  ),
  on(ParticipantActions.queryParticipantsFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //UPDATE ONE
  // on(ParticipantActions.updateParticipant, (state, action) => {
  //   return {
  //     ...state,
  //     participant: action.participant,
  //   };
  // }),
  on(ParticipantActions.updateParticipantFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //DELETE ONE
  on(ParticipantActions.deleteParticipant, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(ParticipantActions.deleteParticipantFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //DELETE MANY
  on(ParticipantActions.deleteParticipants, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),

  // on(ParticipantActions.clearParticipants, (state) => adapter.removeAll(state)),
  // on(ParticipantActions.clearParticipant, (state, action) => {
  //   return {
  //     ...state,
  //     participant: undefined,
  //     loading: false,
  //   };
  // }),

  //STATISTIQUES
  on(ParticipantActions.loadParticipantStatistiques, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(ParticipantActions.loadParticipantStatistiquesSuccess, (state, action) => {
    return {
      ...state,
      statistiques: action.statistiques,
      loading: false,
    };
  }),
  on(ParticipantActions.loadParticipantStatistiquesFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
      loading: false,
    };
  }),

  //EXPERIENCES
  //load
  on(ParticipantActions.loadParticipantExperiences, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(ParticipantActions.loadParticipantExperiencesSuccess, (state, action) => {
    return {
      ...state,
      experiences: action.experiences,
      loading: false,
    };
  }),
  on(ParticipantActions.loadParticipantExperiencesFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
      loading: false,
    };
  }),
  //add
  on(ParticipantActions.addParticipantExperience, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),

  //delete
  on(ParticipantActions.deleteParticipantExperience, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(ParticipantActions.deleteParticipantExperienceFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
      loading: false,
    };
  }),
  //delete ALL
  on(ParticipantActions.deleteAllParticipantExperiences, (state, action) => {
    return {
      ...state,
      loading: true,
      experiences: undefined,
    };
  }),
  on(
    ParticipantActions.deleteAllParticipantExperiencesFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
  ),

  //OPERATIONS
  on(ParticipantActions.loadOperationsSuccess, (state, action) => {
    return {
      ...state,
      operations: action.operations,
    };
  }),
  on(ParticipantActions.loadOperationsFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  // on(ParticipantActions.addOperationSuccess, (state, action) => {
  //   return {
  //     ...state,
  //     operations: [action.operation, ...state.operations],
  //   };
  // }),
  on(ParticipantActions.addOperationFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  on(ParticipantActions.deleteOperation, (state, action) => {
    return {
      ...state,
      operations: state.operations.filter(
        (o: OperationSmallId) => o.id !== action.operation.id
      ),
    };
  }),
  on(ParticipantActions.addOperationFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //AIDS
  on(ParticipantActions.loadAidsSuccess, (state, action) => {
    return {
      ...state,
      aids: action.aids,
    };
  }),
  on(ParticipantActions.loadAidsFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  on(ParticipantActions.addAidFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  on(ParticipantActions.updateAid, (state, action) => {
    const prevAid = state.aids.find((el: any) => el.id === action.aid.id);
    const nextAid = { ...prevAid, ...action.aid.changes };
    const removeAid = state.aids.filter((el: any) => el.id !== action.aid.id);

    return {
      ...state,
      aids: [...removeAid, nextAid],
    };
  }),
  on(ParticipantActions.updateAidFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  on(ParticipantActions.deleteAid, (state, action) => {
    return {
      ...state,
      aids: state.aids.filter((el: any) => el.id !== action.aidId),
    };
  }),
  on(ParticipantActions.deleteAidFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  // Export
  on(ParticipantActions.exportParticipant, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(ParticipantActions.exportParticipantSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
    };
  }),
  on(ParticipantActions.exportParticipantFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
      loading: false,
    };
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
