import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutListSkeltonComponent } from './components/layout-list-skelton/layout-list-skelton.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { LayoutListItemComponent } from './components/layout-list-item/layout-list-item.component';
import { LayoutListContainerComponent } from './components/layout-list-container/layout-list-container.component';
import { NoResultModule } from '../no-result/no-result.module';
import { LayoutListHeaderComponent } from './components/layout-list-header/layout-list-header.component';
import { ButtonsModule } from '../buttons/buttons.module';
import { HeaderModule } from '../header-title/header.module';
import { LayoutGridComponent } from './components/layout-grid/layout-grid.component';
import { LayoutListComponent } from './components/layout-list/layout-list.component';
import { LayoutListCardComponent } from './components/layout-list-card/layout-list-card.component';

@NgModule({
  declarations: [
    LayoutListSkeltonComponent,
    LayoutListItemComponent,
    LayoutListContainerComponent,
    LayoutListHeaderComponent,
    LayoutGridComponent,
    LayoutListComponent,
    LayoutListCardComponent,
  ],
  exports: [
    LayoutListSkeltonComponent,
    LayoutListItemComponent,
    LayoutListContainerComponent,
    LayoutListHeaderComponent,
    LayoutGridComponent,
    LayoutListComponent,
    LayoutListCardComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    NoResultModule,
    ButtonsModule,
    HeaderModule,
  ],
})
export class LayoutModule {}
