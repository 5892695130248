import { takeUntil } from 'rxjs/operators';
import { EntiteId } from './../../store/entite.model';
import { EMPTY, Observable, of, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { EntiteState } from '../../store/entite.reducer';
import * as fromEntiteSelector from '../../store/entite.selectors';
import * as fromEntiteAction from '../../store/entite.actions';

@Component({
  selector: 'app-entite-dashboard',
  templateUrl: './entite-dashboard.component.html',
  styleUrls: ['./entite-dashboard.component.scss'],
})
export class EntiteDashboardComponent implements OnInit, OnDestroy {
  statistiques$: Observable<any> = of(null);
  entite$: Observable<EntiteId> | any = of(null);
  subscribe = new Subject();

  constructor(private entiteStore: Store<EntiteState>) {}

  ngOnInit(): void {
    this.getEntite();
    this.getStatistiques();
    this.loadStatistiques();
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getEntite(): void {
    this.entite$ = this.entiteStore.select(fromEntiteSelector.entite);
  }
  getStatistiques(): void {
    this.statistiques$ = this.entiteStore.select(
      fromEntiteSelector.statistiques
    );
  }

  loadStatistiques(): void {
    this.entite$
      .pipe(takeUntil(this.subscribe))
      .subscribe((entite: EntiteId) => {
        if (!entite) {
          return;
        } else {
          this.entiteStore.dispatch(
            fromEntiteAction.loadEntiteStatistiques({ id: entite.id })
          );
        }
      });
  }
}
