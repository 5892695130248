<ng-template #extraDocument>
  <button
    nz-button
    nzSize="small"
    *ngIf="add"
    [disabled]="!entiteForm.valid"
    nzType="primary"
  >
    Ajouter
  </button>

  <button
    nz-button
    nzSize="small"
    *ngIf="update"
    [disabled]="!entiteForm.valid"
    nzType="primary"
  >
    Modifier
  </button>
  <nz-divider nzType="vertical"></nz-divider>
  <button nz-button nzSize="small" (click)="onCancel()">Ignorer</button>
</ng-template>

<form nz-form nzLayout="vertical" [formGroup]="entiteForm">
  <app-entite-search
    (addItem)="onSelectEntite($event)"
    *ngIf="!update"
  ></app-entite-search>
  <ng-container
    *ngIf="entiteForm?.value?.etablissement?.denomination; else noData"
  >
    <nz-descriptions
      nzBordered="true"
      nzSize="small"
      [nzTitle]="entiteForm.value?.etablissement?.denomination"
      span="8"
      [nzColumn]="{ xxl: 4, xl: 4, lg: 4, md: 4, sm: 4, xs: 4 }"
    >
      <nz-descriptions-item nzSpan="2" nzTitle="Siret">{{
        entiteForm.value?.etablissement?.siren
      }}</nz-descriptions-item>
      <nz-descriptions-item nzSpan="2" nzTitle="ESS">{{
        entiteForm.value?.etablissement?.economie_sociale_solidaire
      }}</nz-descriptions-item>
      <nz-descriptions-item nzSpan="4" nzTitle="Adresse">{{
        entiteForm.value?.etablissement?.etablissement_siege?.geo_adresse
      }}</nz-descriptions-item>
      <nz-descriptions-item nzSpan="4" nzTitle="Activité principale">{{
        entiteForm.value?.etablissement?.activite_principale_libelle
          ?.intitule_naf_65
      }}</nz-descriptions-item>
    </nz-descriptions>

    <nz-space nzDirection="vertical" [nzSize]="25"></nz-space>

    <nz-row
      nzAlign="middle"
      nzGutter="8"
      nzJustify="center"
      formGroupName="custom"
    >
      <nz-col nzSpan="24">
        <app-metiers
          [type]="'grandDomaine'"
          [showMetiers]="false"
          [showAppelations]="false"
          [showAppelation]="false"
          [showSoftskills]="false"
          [showAdresse]="false"
          (theme)="onSelectTheme($event)"
          (domaine)="onSelectDomaine($event)"
          (domainesProfessionnels)="onSelectDomaineProfessionnel($event)"
          (metiers)="onSelectMetiers($event)"
          (appelations)="onSelectAppelations($event)"
          (appelation)="onSelectAppelation($event)"
          (softskills)="onSelectSofskill($event)"
        ></app-metiers>
      </nz-col>
    </nz-row>

    <nz-row
      nzAlign="middle"
      nzGutter="2"
      nzJustify="start"
      formGroupName="custom"
    >
      <nz-col nzSpan="6">
        <nz-form-item>
          <nz-form-control>
            <label nz-checkbox formControlName="actif" class="checkbox"
              >Actif</label
            >
          </nz-form-control>
        </nz-form-item>
      </nz-col>
      <nz-col nzSpan="6">
        <span
          >Statut
          <span class="classRequied">*</span>
        </span>
        <app-utilities-select
          [id]="utilitairesId"
          [variable]="utilitaireStatut"
          [size]="'default'"
          [multi]="false"
          (select)="onSelectStatus($event)"
          [placeholder]="'Statut...'"
        ></app-utilities-select>
      </nz-col>
      <nz-col nzSpan="6">
        <span
          >Engagement
          <span class="classRequied">*</span>
        </span>
        <app-utilities-select
          [id]="utilitairesId"
          [variable]="utilitaireEngagement"
          [size]="'default'"
          [multi]="false"
          (select)="onSelectEngagement($event)"
          [placeholder]="'Engagement...'"
        ></app-utilities-select>
      </nz-col>
      <nz-col nzSpan="6">
        <span
          >Origine
          <span class="classRequied">*</span>
        </span>
        <app-utilities-select
          [id]="utilitairesId"
          [variable]="utilitaireOrigine"
          [size]="'default'"
          [multi]="false"
          (select)="onSelectOrigine($event)"
          [placeholder]="'Origine...'"
        ></app-utilities-select>
      </nz-col>
    </nz-row>

    <nz-divider nzType="horizontal" nzOrientation="center"></nz-divider>

    <nz-row
      nzAlign="middle"
      nzGutter="2"
      nzJustify="center"
      formGroupName="custom"
    >
      <nz-col nzSpan="4" formGroupName="coordonnees">
        <nz-form-item>
          <nz-form-label>Mobile</nz-form-label>
          <nz-form-control nzValidateStatus="success" [nzErrorTip]="fixError">
            <input
              nz-input
              formControlName="mobile"
              nzPlaceHolder="Mobile..."
              placeholder="Mobile..."
            />
          </nz-form-control>
          <ng-template #fixError>
            <small>Numéro non valide</small>
          </ng-template>
        </nz-form-item>
      </nz-col>
      <nz-col nzSpan="4" formGroupName="coordonnees">
        <nz-form-item>
          <nz-form-label>Fixe</nz-form-label>
          <nz-form-control [nzErrorTip]="fixError">
            <input
              nz-input
              formControlName="fixe"
              nzPlaceHolder="Fixe..."
              placeholder="Fixe..."
            />
          </nz-form-control>
          <ng-template #fixError>
            <small>Numéro non valide</small>
          </ng-template>
        </nz-form-item>
      </nz-col>
      <nz-col nzSpan="8" formGroupName="coordonnees">
        <nz-form-item>
          <nz-form-label>E-mail</nz-form-label>
          <nz-form-control [nzErrorTip]="emailError">
            <input
              nz-input
              nzPlaceHolder="E-mail..."
              placeholder="E-mail"
              formControlName="email"
            />
          </nz-form-control>
          <ng-template #emailError>
            <small>E-mail non valide</small>
          </ng-template>
        </nz-form-item>
      </nz-col>
      <nz-col nzSpan="8" formGroupName="coordonnees">
        <nz-form-item>
          <nz-form-label>Site</nz-form-label>
          <nz-form-control [nzErrorTip]="webError">
            <input
              nz-input
              nzPlaceHolder="Site..."
              placeholder="Site..."
              formControlName="web"
            />
          </nz-form-control>
          <ng-template #webError>
            <small>Adresse web non valide</small>
          </ng-template>
        </nz-form-item>
      </nz-col>
    </nz-row>
  </ng-container>
</form>

<nz-divider
  nzType="horizontal"
  nzOrientation="center"
  class="btnDivider"
></nz-divider>
<nz-row nzAlign="middle" nzGutter="8" nzJustify="end">
  <nz-col nzSpan="8"></nz-col>
  <nz-col nzSpan="8"></nz-col>
  <nz-col nzSpan="4">
    <button nz-button nzBlock (click)="onCancel()" nzType="text">
      <h5 nz-typography>Annuler</h5>
    </button>
  </nz-col>

  <nz-col nzSpan="4">
    <button
      nz-button
      nzBlock
      nzType="primary"
      *ngIf="add"
      [disabled]="!entiteForm.valid"
      (click)="onAdd()"
    >
      Ajouter
    </button>
    <button
      nz-button
      nzBlock
      nzType="primary"
      *ngIf="update && (entite$ | async) as entite"
      [disabled]="!entiteForm.valid"
      (click)="onUpdate(entite)"
    >
      Modifier
    </button>
  </nz-col>
</nz-row>

<ng-template #noData>
  <nz-card nzBorderless class="emptyCard">
    <i nz-icon nzType="bank" class="iconEmpty"></i>
    <h5 nz-typography class="contentEmpty">Aucune entité ajoutée</h5>
  </nz-card>
</ng-template>

<ng-template #noDataIcon>
  <i nz-icon nzType="bank" class="iconEmpty"></i>
</ng-template>
<ng-template #noDataContent>
  <h5 nz-typography class="contentEmpty">Aucune entité ajoutée</h5>
</ng-template>
