import { Update } from '@ngrx/entity';
import { takeUntil } from 'rxjs/operators';
import {
  Etablissement,
  Organisation,
  OrganisationId,
} from './../../store/organisation.model';
import { Observable, of, Subject, BehaviorSubject } from 'rxjs';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Store, select } from '@ngrx/store';
import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  Input,
  EventEmitter,
} from '@angular/core';
import { OrganisationState } from '../../store/organisation.reducer';
import * as fromOrganisationSelector from '../../store/organisation.selectors';
import * as fromOrganisationAction from '../../store/organisation.actions';
import { Timestamp } from 'firebase/firestore';

@Component({
  selector: 'app-organisation-form',
  templateUrl: './organisation-form.component.html',
  styleUrls: ['./organisation-form.component.scss'],
})
export class OrganisationFormComponent implements OnInit, OnDestroy {
  step: number = 0;
  organisation$: Observable<OrganisationId | any> = of(null);
  subscribe = new Subject();
  isUpdate: boolean = false;
  etablissmentEmpty = false;

  organisationForm: UntypedFormGroup = this.fb.group({});
  etablissement: UntypedFormControl = new UntypedFormControl('');
  app: UntypedFormControl = new UntypedFormControl('');
  appLink: UntypedFormControl = new UntypedFormControl('');
  logo: UntypedFormControl = new UntypedFormControl('');
  fileRef: UntypedFormControl = new UntypedFormControl('');
  statut: UntypedFormControl = new UntypedFormControl(''); // CA - Prospect -autre
  entiteType: UntypedFormControl = new UntypedFormControl(''); // structure || financeur || entreprise
  coordonnees: UntypedFormControl = new UntypedFormControl('');
  organisationEmail: UntypedFormControl = new UntypedFormControl('');
  organisationPhoneNumber: UntypedFormControl = new UntypedFormControl('');
  organisationdFixe: UntypedFormControl = new UntypedFormControl('');
  organisationMobile: UntypedFormControl = new UntypedFormControl('');
  organisationWeb: UntypedFormControl = new UntypedFormControl('');

  idCommunauty: UntypedFormControl = new UntypedFormControl('');
  primary: UntypedFormControl = new UntypedFormControl('');
  accent: UntypedFormControl = new UntypedFormControl('');
  warn: UntypedFormControl = new UntypedFormControl('');
  emailCommunication: UntypedFormControl = new UntypedFormControl('');
  dealGroup: UntypedFormControl = new UntypedFormControl('');
  publicStatsId: UntypedFormControl = new UntypedFormControl('');
  departements: UntypedFormControl = new UntypedFormControl('');
  dateStart: UntypedFormControl = new UntypedFormControl('');
  dateUpdate: UntypedFormControl = new UntypedFormControl('');
  userUpdate: UntypedFormControl = new UntypedFormControl('');
  supportEmail: UntypedFormControl = new UntypedFormControl('');
  supportTel1: UntypedFormControl = new UntypedFormControl('');
  supportTel2: UntypedFormControl = new UntypedFormControl('');
  supportWeb: UntypedFormControl = new UntypedFormControl('');
  objet: UntypedFormControl = new UntypedFormControl('');
  accroche: UntypedFormControl = new UntypedFormControl('');
  type: UntypedFormControl = new UntypedFormControl('');
  licence: UntypedFormControl = new UntypedFormControl();
  licenceMode: UntypedFormControl = new UntypedFormControl('');
  licenceCreateAt: UntypedFormControl = new UntypedFormControl('');
  licenceAdminId: UntypedFormControl = new UntypedFormControl('');
  licenceAdminEmail: UntypedFormControl = new UntypedFormControl('');
  licenceAdminPhone: UntypedFormControl = new UntypedFormControl('');
  licenceClientId: UntypedFormControl = new UntypedFormControl('');

  adminAccount: any;

  updateCoordonnes = new BehaviorSubject<any>(null);

  typeList = ['ESS', 'PRV', 'GRP'];

  @Input() typeForm: string = 'add';
  @Output() modalVisibility = new EventEmitter<boolean>(false);
  @Output() cancel = new EventEmitter<boolean>(false);
  @Output() add = new EventEmitter<boolean>(false);
  @Output() update = new EventEmitter<boolean>(false);

  constructor(
    private organisationStore: Store<OrganisationState>,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.formInit();
    this.formCreate();
    this.formSet();
  }

  get__ORGANISATION(): void {
    this.organisation$ = this.organisationStore.select(
      fromOrganisationSelector.organisation
    );
  }

  formSet(): void {
    this.organisation$
      .pipe(takeUntil(this.subscribe))
      .subscribe((organisation: OrganisationId): any => {
        if (!organisation) {
          return null;
        }
        const { coordonnees } = organisation.custom;

        this.organisationForm.patchValue({
          etablissement: { ...organisation.etablissement },
          custom: {
            app: organisation.custom.app,
            appLink: organisation.custom.appLink,
            logo: organisation.custom.logo,
            fileRef: organisation.custom.fileRef,
            type: organisation.custom.type,
            statut: organisation.custom.statut, // CA - Prospect -autre
            entiteType: organisation.custom.entiteType, // structure || financeur || entreprise
            coordonnees: organisation.custom.coordonnees,
            objet: organisation.custom.objet,
            accroche: organisation.custom.accroche,
            communauty: organisation.custom.communauty,
            colors: organisation.custom.colors,
            equipes: organisation.custom.equipes,
            emailCommunication: organisation.custom.emailCommunication,
            dealGroup: organisation.custom.dealGroup,
            publicStatsId: organisation.custom.publicStatsId,
            departements: organisation.custom.departements,
            dateStart: organisation.custom.dateStart,
            dateUpdate: organisation.custom.dateUpdate,
            userUpdate: organisation.custom.userUpdate,
            licence: organisation?.licence,
          },
        });

        this.updateCoordonnes.next(coordonnees);
      });
  }

  formInit() {
    this.etablissement = new UntypedFormControl('', Validators.required);
    this.app = new UntypedFormControl('', Validators.required);
    this.appLink = new UntypedFormControl('');
    this.type = new UntypedFormControl('');
    this.logo = new UntypedFormControl('');
    this.fileRef = new UntypedFormControl('');
    this.statut = new UntypedFormControl(''); // CA - Prospect -autre
    this.entiteType = new UntypedFormControl(''); // structure || financeur || entreprise
    this.coordonnees = new UntypedFormControl('');
    this.idCommunauty = new UntypedFormControl('');
    this.primary = new UntypedFormControl('');
    this.accent = new UntypedFormControl('');
    this.warn = new UntypedFormControl('');
    this.emailCommunication = new UntypedFormControl('');
    this.dealGroup = new UntypedFormControl('');
    this.publicStatsId = new UntypedFormControl('');
    this.departements = new UntypedFormControl([]);
    this.dateStart = new UntypedFormControl(Timestamp.now(), Validators.required);
    this.dateUpdate = new UntypedFormControl(Timestamp.now(), Validators.required);
    this.userUpdate = new UntypedFormControl('');
    this.supportEmail = new UntypedFormControl('');
    this.supportTel1 = new UntypedFormControl('');
    this.supportTel2 = new UntypedFormControl('');
    this.supportWeb = new UntypedFormControl('');
    this.objet = new UntypedFormControl('');
    this.accroche = new UntypedFormControl('');
    this.licence = new UntypedFormControl();
    this.organisationEmail = new UntypedFormControl('');
    this.organisationPhoneNumber = new UntypedFormControl('');
    this.organisationdFixe = new UntypedFormControl('');
    this.organisationMobile = new UntypedFormControl('');
    this.organisationWeb = new UntypedFormControl('');
    this.licenceMode = new UntypedFormControl('');
    this.licenceCreateAt = new UntypedFormControl('');
    this.licenceAdminId = new UntypedFormControl('');
    this.licenceAdminEmail = new UntypedFormControl('');
    this.licenceAdminPhone = new UntypedFormControl('');
    this.licenceClientId = new UntypedFormControl('');
  }

  formCreate() {
    this.organisationForm = this.fb.group({
      etablissement: this.etablissement,

      custom: new UntypedFormGroup({
        app: this.app,
        appLink: this.appLink,
        logo: this.logo,
        fileRef: this.fileRef,
        type: this.type,
        statut: this.statut, // CA - Prospect -autre
        entiteType: this.entiteType, // structure || financeur || entreprise
        coordonnees: new UntypedFormGroup({
          email: this.organisationEmail,
          fixe: this.organisationdFixe,
          mobile: this.organisationMobile,
          phoneNumber: this.organisationPhoneNumber,
          web: this.organisationWeb,
        }),
        objet: this.objet,
        accroche: this.accroche,
        communauty: this.idCommunauty,
        emailCommunication: this.emailCommunication,
        publicStatsId: this.publicStatsId,
        departements: this.departements,
        dateStart: this.dateStart,
        dateUpdate: this.dateUpdate,
      }),
      licence: new UntypedFormGroup({
        mode: this.licenceMode,
        createAt: this.licenceCreateAt,
        adminId: this.licenceAdminId,
        adminEmail: this.licenceAdminEmail,
        adminPhone: this.licenceAdminPhone,
        clientId: this.licenceClientId,
      }),
    });
  }

  nextStep(): void {
    this.step = this.step === 0 ? this.step + 1 : 1;
  }
  prevStep(): void {
    this.step = this.step === 1 ? this.step - 1 : 0;
  }

  selectedItem(etablissement: any): void {
    if (!etablissement) return;
    this.organisationForm.patchValue({
      etablissement: etablissement,
      custom: {
        app: etablissement?.denomination.toUpperCase(),
      },
    });
  }

  selectdAdminAccount(user: any): void {
    if (!user) return;
    this.adminAccount = user;
  }

  onChangeMobile(event: string, organisation: OrganisationId): void {
    const formValue = this.organisationForm.value;
    this.organisationForm.patchValue({
      custom: {
        coordonnees: {
          ...organisation.custom.coordonnees,
          ...formValue.custom.coordonnees,
          mobile: event,
        },
      },
    });
  }

  onChangeFixe(event: string, organisation: OrganisationId): void {
    const formValue = this.organisationForm.value;

    this.organisationForm.patchValue({
      custom: {
        coordonnees: {
          ...organisation.custom.coordonnees,
          ...formValue.custom.coordonnees,
          fixe: event,
        },
      },
    });
  }

  onChangeEmail(event: string, organisation: OrganisationId): void {
    const formValue = this.organisationForm.value;

    this.organisationForm.patchValue({
      custom: {
        coordonnees: {
          ...organisation.custom.coordonnees,
          ...formValue.custom.coordonnees,
          email: event,
        },
        emailCommunication: event,
      },
    });
  }

  onChangePhoneNumber(event: string, organisation: OrganisationId): void {
    const formValue = this.organisationForm.value;
    this.organisationForm.patchValue({
      curstom: {
        coordonnees: {
          ...organisation.custom.coordonnees,
          ...formValue.custom.coordonnees,
          phoneNumber: event,
        },
      },
    });
  }

  onAdd(user: any): void {
    if (!this.organisationForm.valid || !user) return;

    const organisation: any = this.organisationForm.value;
    const userAdmin: any = user;

    this.organisationStore.dispatch(
      fromOrganisationAction.addOrganisation({ organisation, userAdmin })
    );
    this.cancel.emit(true);
  }

  onUpdate(organisation: OrganisationId): any {
    if (!this.organisationForm.valid && !organisation) {
      return null;
    } else {
      const organisationForm: Organisation = this.organisationForm.value;

      const nextOrgansation: OrganisationId = {
        ...organisation,
        custom: {
          ...organisation.custom,
          ...organisationForm.custom,
          coordonnees: {
            ...organisation.custom.coordonnees,
            ...organisationForm.custom.coordonnees,
          },
        },
      };

      const newOrganisation: Update<OrganisationId> = {
        id: organisation.id,
        changes: {
          ...nextOrgansation,
        },
      };

      this.organisationStore.dispatch(
        fromOrganisationAction.updateOrganisation({
          organisation: newOrganisation,
        })
      );
      this.onCancel(true);
    }
  }

  onCancel(event: any): void {
    this.organisationForm.reset();
    this.cancel.emit(true);
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }
}
