import { NzMessageService } from 'ng-zorro-antd/message';
import { Departement } from './../../store/departement.model';
import { takeUntil } from 'rxjs/operators';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import {
  Component,
  OnDestroy,
  OnInit,
  EventEmitter,
  Output,
} from '@angular/core';
import { DepartementState } from '../../store/departement.reducer';
import * as fromDepartementAction from '../../store/departement.actions';
import * as fromDepartementSelector from '../../store/departement.selectors';

import { Store } from '@ngrx/store';
import { Subject, BehaviorSubject, Observable, of } from 'rxjs';
import { DepartementId } from '../../store/departement.model';
import { Update } from '@ngrx/entity';
import {
  NzNotificationPlacement,
  NzNotificationService,
} from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-departement-form',
  templateUrl: './departement-form.component.html',
  styleUrls: ['./departement-form.component.scss'],
})
export class DepartementFormComponent implements OnInit, OnDestroy {
  subscribe = new Subject();
  departement$: Observable<DepartementId | any> = of(null);
  departementsUsers: any = [];
  departementsManagers: any = [];

  updateItem$ = new BehaviorSubject<any>(null);
  updateComplement$ = new BehaviorSubject<any>(null);
  isAdd: boolean = true;
  isUpdate: boolean = false;
  NOTIFICATION_PLACEMENT: NzNotificationPlacement = 'topRight';

  departementForm: UntypedFormGroup = this.fb.group({});
  title: UntypedFormControl = new UntypedFormControl('');
  definition: UntypedFormControl = new UntypedFormControl('');
  users: UntypedFormControl = new UntypedFormControl([]);
  ids: UntypedFormControl = new UntypedFormControl([]);
  auteur: UntypedFormControl = new UntypedFormControl('');
  organisationId: UntypedFormControl = new UntypedFormControl('');
  organisationGeo_adresse: UntypedFormControl = new UntypedFormControl('');
  organisationNom_raison_sociale: UntypedFormControl = new UntypedFormControl(
    ''
  );
  organisationSiren: UntypedFormControl = new UntypedFormControl('');
  organisationSiret: UntypedFormControl = new UntypedFormControl('');
  dateStart: UntypedFormControl = new UntypedFormControl('');
  dateUpdate: UntypedFormControl = new UntypedFormControl('');
  userUpdate: UntypedFormControl = new UntypedFormControl('');
  metadata: UntypedFormControl = new UntypedFormControl();
  auth: UntypedFormControl = new UntypedFormControl([]);
  managers: UntypedFormControl = new UntypedFormControl([]);
  managersIds: UntypedFormControl = new UntypedFormControl([]);

  adresse: UntypedFormControl = new UntypedFormControl('');

  @Output() cancel = new EventEmitter<any>(false);
  @Output() add = new EventEmitter<any>(false);
  @Output() update = new EventEmitter<any>(false);

  constructor(
    private fb: UntypedFormBuilder,
    private departementStore: Store<DepartementState>,
    private message: NzMessageService,
    private notification: NzNotificationService
  ) {}

  ngOnInit(): void {
    this.getDepartement();
    this.form();
  }

  form(): void {
    this.formInit();
    this.formCreate();
    this.formSet();
  }
  formInit(): void {
    this.title = new UntypedFormControl('', Validators.required);
    this.definition = new UntypedFormControl('', Validators.required);
    this.users = new UntypedFormControl([]);
    this.ids = new UntypedFormControl([]);
    this.managers = new UntypedFormControl([]);
    this.managersIds = new UntypedFormControl([]);
    this.adresse = new UntypedFormControl({}, Validators.required);
  }
  formCreate(): void {
    this.departementForm = this.fb.group({
      title: this.title,
      definition: this.definition,
      users: this.users,
      managers: this.managers,
      managersIds: this.managersIds,
      ids: this.ids,
      adresse: this.adresse,
    });
  }

  formUpdate(departement: DepartementId): void {
    this.departementForm.patchValue({ ...departement });

    this.isUpdate = true;
    this.isAdd = false;

    const { users, managers, adresse } = departement;

    this.departementsUsers = users;
    this.departementsManagers = managers;

    this.updateItem$.next(adresse?.adresse);
    this.updateComplement$.next(adresse?.complement);
  }

  formSet(): void {
    this.departement$
      .pipe(takeUntil(this.subscribe))
      .subscribe((departement) => {
        if (!departement) return;

        this.formUpdate(departement);
      });
  }

  getDepartement(): void {
    this.departement$ = this.departementStore.select(
      fromDepartementSelector.departement
    );
  }

  onSelectManagers(users: any[]): void {
    if (!users?.length) return;

    const userWithService = users.filter((user) =>
      this.verifyHasDeparetement(user)
    );

    const userWithOutService = users.filter(
      (user) => !this.verifyHasDeparetement(user)
    );

    const ids = userWithOutService.map((user) => user.id);
    const departementForm = this.departementForm.value;

    this.departementForm.patchValue({
      users: [...departementForm?.users, ...userWithOutService],
      ids: [...departementForm.ids, ...ids],
      managers: userWithOutService,
      managersIds: ids,
    });

    this.messageUserWithoutService(userWithOutService);
    this.messageUserHasService(userWithService);
  }

  verifyHasDeparetement(user: any): boolean {
    return user?.service?.title ? true : false;
  }

  messageUserWithoutService(users: any): void {
    if (!users?.lenght) {
      return;
    } else {
      const usersIdentifty = users.join(', ');
      const messges: string = `${usersIdentifty} ont été intégrés au service`;
      this.notification.info('', messges, {
        nzDuration: 8000,
        nzAnimate: true,
        nzPlacement: this.NOTIFICATION_PLACEMENT,
      });
    }
  }

  messageUserHasService(users: any): void {
    if (!users?.lenght) {
      return;
    } else {
      const usersIdentifty = users.join(', ');
      const messges: string = `${usersIdentifty} sont déjà intégré.es dans au moins un autre service.`;
      this.notification.info('', messges, {
        nzDuration: 8000,
        nzAnimate: true,
        nzPlacement: this.NOTIFICATION_PLACEMENT,
      });
    }
  }

  onSelectUsers(users: any[]): void {
    if (!users?.length) return;
    const ids = users.map((user) => user.id);

    this.departementForm.patchValue({
      users,
      ids,
    });
  }

  onAdresse(adresse: any): void {
    if (!adresse) return;
    this.departementForm.patchValue({
      adresse: {
        adresse: adresse,
      },
    });
  }

  onClearAdresse(): void {
    this.updateItem$.next(null);
    this.departementForm.patchValue({
      adresse: {
        adresse: '',
      },
    });
  }

  onAdresseComplete(complete: any): void {
    if (!complete) return;
    this.departementForm.patchValue({
      adresse: {
        complement: complete,
      },
    });
  }

  onAdd(): void {
    if (!this.departementForm.valid) return;

    const departement: any = this.departementForm.value;

    this.departementStore.dispatch(
      fromDepartementAction.addDepartement({ departement })
    );
    this.onCancel();
  }

  onUpdate(departement: DepartementId): void {
    if (!this.departementForm.valid) return;
    const departementForm: Departement = this.departementForm.value;
    const changes = {
      ...departement,
      ...departementForm,
    };

    const nextDepartement: Update<DepartementId> = {
      id: departement.id,
      changes: { ...changes },
    };

    this.departementStore.dispatch(
      fromDepartementAction.updateDepartement({ departement: nextDepartement })
    );

    this.onCancel();
  }

  onCancel(): void {
    this.isUpdate = false;
    this.isAdd = true;
    this.cancel.emit(true);
    this.departementForm.reset();
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }
}
