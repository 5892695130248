import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { messageState } from '../../store/message.reducer';
import * as fromMessageActions from '../../store/message.actions';
import * as fromMessageSelectors from '../../store/message.selectors';
import { Observable, of } from 'rxjs';
import { MessageId } from '../../store/message.model';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';

@Component({
  selector: 'app-messages-screen-chat',
  templateUrl: './messages-screen-chat.component.html',
  styleUrls: ['./messages-screen-chat.component.scss'],
})
export class MessagesScreenChatComponent implements OnInit {
  user$: Observable<any | any> = of(null);
  message$: Observable<MessageId | any> = of(null);

  constructor(
    private userStore: Store<UserState>,
    private storeMessage: Store<messageState>
  ) {}

  ngOnInit(): void {
    this.get__USER();
    this.get__MESSAGE();
  }

  get__USER(): void {
    this.user$ = this.userStore.select(fromUserSelector.user);
  }

  get__MESSAGE(): void {
    this.message$ = this.storeMessage.select(
      fromMessageSelectors.selectMessage
    );
  }

  delete__MESSAGE(message: MessageId): any {
    if (!message) {
      return null;
    }

    this.storeMessage.dispatch(
      fromMessageActions.deleteMessage({ id: message.id })
    );
  }

  onCancel(): any {
    return null;
  }
}
