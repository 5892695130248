import { OperationTeamService } from './../services/operation-team.service';
import {
  NzNotificationPlacement,
  NzNotificationService,
} from 'ng-zorro-antd/notification';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, mergeMap, of, tap } from 'rxjs';
import * as fromOperationTeamAction from './operation-team.actions';

const NOTIFICATION_PLACEMENT: NzNotificationPlacement = 'topRight';

@Injectable()
export class OperationTeamEffects {
  //LOAD
  loadOperationTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationTeamAction.loadOperationTeams),
      mergeMap((action) =>
        this.operationTeamService.getAll(action.id).pipe(
          map((respons: any) => {
            const team = respons.data.getOperationTeam;
            return fromOperationTeamAction.loadOperationTeamsSucces({
              team,
            });
          }),
          catchError((error) => {
            this.onErrorNotification(error, 'LOAD_OPERATION_TEAM');
            return of(
              fromOperationTeamAction.loadOperationTeamsFailure({ error })
            );
          })
        )
      )
    )
  );

  //ADD
  addOperationTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationTeamAction.addOperationTeams),
      tap(() => {
        this.notification.info(
          'Equipe projet',
          "Création des membres de l'équipe projet en cours...",
          {
            nzDuration: 6000,
            nzAnimate: true,
            nzPlacement: NOTIFICATION_PLACEMENT,
            nzKey: 'OPERATION_CREATE_TEAM',
          }
        );
      }),
      mergeMap((action) =>
        this.operationTeamService
          .createOperatiosUsers(action.id, action.users)
          .pipe(
            map((respons: any) => {
              this.notification.success(
                'Equipe projet',
                respons?.data?.createOperationTeam,
                {
                  nzDuration: 8000,
                  nzAnimate: true,
                  nzPlacement: NOTIFICATION_PLACEMENT,
                  nzKey: 'OPERATION_CREATE_TEAM',
                }
              );

              return fromOperationTeamAction.addOperationTeamsSuccess({
                success: respons?.data?.createOperationTeam,
              });
            }),
            catchError((error) => {
              this.onErrorNotification(error, 'OPERATION_CREATE_TEAM');

              return of(
                fromOperationTeamAction.addOperationTeamsFailure({ error })
              );
            })
          )
      )
    )
  );

  // updateMembersOperation$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(fromOperationAction.updateOperationMembers),
  //     mergeMap((action) =>
  //       this.operationTeam
  //         .updateOne(
  //           action.member.id,
  //           action.member.changes,
  //           action.operationId
  //         )
  //         .pipe(
  //           map((members: any) => {
  //             const success: string = `Collaborateur ${action.member.changes.user.displayName} modifié`;
  //             this.message.success(success, { nzDuration: 6000 });
  //             return fromOperationAction.updateOperationMembersSuccess({
  //               members,
  //             });
  //           }),
  //           catchError((error) =>
  //             of(fromOperationAction.updateOperationMembersFailure({ error }))
  //           )
  //         )
  //     )
  //   )
  // );

  //DELETE ONE
  deleteOperationTeamMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationTeamAction.deleteOperationTeam),
      tap(() => {
        this.notification.info(
          'Retirer un collaboration',
          "Suppression du collaborateur de l'équipe projet en cours...",
          {
            nzDuration: 6000,
            nzAnimate: true,
            nzPlacement: NOTIFICATION_PLACEMENT,
            nzKey: 'OPERATION_DELETE_TEAM_MEMBER',
          }
        );
      }),
      concatMap((action) =>
        this.operationTeamService.deleteOne(action.operationId, action.id).pipe(
          map((respons: any) => {
            this.notification.success(
              'Retirer un collaboration',
              respons?.data?.deletOperationTeam,
              {
                nzDuration: 8000,
                nzAnimate: true,
                nzPlacement: NOTIFICATION_PLACEMENT,
                nzKey: 'OPERATION_DELETE_TEAM_MEMBER',
              }
            );

            return fromOperationTeamAction.deleteOperationTeamSuccess({
              success: respons?.data?.deleteOperationTeam,
            });
          }),
          catchError((error) => {
            this.onErrorNotification(error, 'OPERATION_DELETE_TEAM_MEMBER');

            return of(
              fromOperationTeamAction.deleteOperationTeamFailure({ error })
            );
          })
        )
      )
    )
  );

  //DELETE ALL
  deleteOperationAllTeamMembers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationTeamAction.deleteOperationTeams),
      tap(() => {
        this.notification.info(
          "Retirer toutes l'équipe projet",
          "Suppression de l'équipe projet en cours...",
          {
            nzDuration: 6000,
            nzAnimate: true,
            nzPlacement: NOTIFICATION_PLACEMENT,
            nzKey: 'OPERATION_DELETE_ALL_TEAM_MEMBERS',
          }
        );
      }),
      concatMap((action) =>
        this.operationTeamService.deleteAll(action.operationId).pipe(
          map((respons: any) => {
            this.notification.success(
              "Suppression l'équipe",
              respons?.data?.deleteOperationAllTeam,
              {
                nzDuration: 8000,
                nzAnimate: true,
                nzPlacement: NOTIFICATION_PLACEMENT,
                nzKey: 'OPERATION_DELETE_ALL_TEAM_MEMBERS',
              }
            );

            return fromOperationTeamAction.deleteOperationTeamsSuccess({
              success: respons?.data?.deleteOperationTeam,
            });
          }),
          catchError((error) => {
            this.onErrorNotification(
              error,
              'OPERATION_DELETE_ALL_TEAM_MEMBERS'
            );

            return of(
              fromOperationTeamAction.deleteOperationTeamsFailure({ error })
            );
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private operationTeamService: OperationTeamService,
    private notification: NzNotificationService
  ) {}

  onErrorNotification(error: any, key: string): void {
    this.notification.error('Error', error.message, {
      nzDuration: 10000,
      nzAnimate: true,
      nzPlacement: NOTIFICATION_PLACEMENT,
      nzKey: key ? key : '',
    });
  }
}
