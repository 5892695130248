<ng-container *ngIf="user$ | async as user">
  <!-- <ng-container>
    <nz-row nzAlign="top" nzGutter="2" nzJustify="center">
      <nz-col nzSpan="6"></nz-col>
      <nz-col nzSpan="4">
        <nz-select
          nzBorderless
          [(ngModel)]="typeSelectors"
          (ngModelChange)="selectType($event)"
        >
          <nz-option
            *ngFor="let type of dashboardType$ | async"
            nzCustomContent
            [nzValue]="type"
            [nzLabel]="type"
          >
            <nz-tag [nzColor]="type === 'Entité' ? 'purple' : 'magenta'">
              {{ type }}
            </nz-tag>
          </nz-option>
        </nz-select>
      </nz-col>

      <nz-col nzSpan="4">
        <nz-select
          nzBorderless
          [(ngModel)]="yearsSelectors"
          (ngModelChange)="selectYear($event)"
        >
          <nz-option
            *ngFor="let year of (filtersAdvance$ | async)?.years"
            nzCustomContent
            [nzValue]="year"
            [nzLabel]="year"
          >
            <nz-tag [nzColor]="year === actualYear ? 'purple' : 'default'">
              {{ year }}
            </nz-tag>
          </nz-option>
        </nz-select>
      </nz-col>
      <nz-col nzSpan="2">
        <button
          *ngIf="
            (dashboardEntite$ | async) &&
            ((user$ | async | isDev) || (user$ | async | isAdmin))
          "
          nz-button
          nzType="text"
          nz-dropdown
          nzTrigger="click"
          nz-tooltip
          nzTooltipTitle="Ajouter un objectif annuel"
          nzTooltipPlacement="bottom"
          [nzClickHide]="objectifVisibility__ENTITE"
          [nzDropdownMenu]="objectifMenu"
        >
          <i nz-icon nzType="plus-circle" class="iconSelectionHeader"></i>
          <h4 nz-typography>Objectif</h4>
        </button>
        <nz-dropdown-menu #objectifMenu="nzDropdownMenu">
          <ul nz-menu>
            <li nz-menu-item>
              <nz-input-number
                [(ngModel)]="objectif__ENTITE"
                nzPlaceHolder="Objectif..."
                nzMin="0"
              ></nz-input-number>
              <button
                nz-button
                nzShape="circle"
                *ngIf="objectif__ENTITE"
                (click)="add_entites_objectif()"
              >
                <i nz-icon nzType="plus-circle"></i>
              </button>
            </li>
          </ul>
        </nz-dropdown-menu>
      </nz-col>
      <nz-col nzSpan="4">
        <nz-button-group>
          <ng-container
            *ngIf="
              (dashboardEntite$ | async) &&
              (statistiquesYear$ | async)
                ?.entitesStatsYear as statistiquesEntiteYear
            "
          >
            <button
              nzType="text"
              nz-tooltip
              nzTooltipTitle="Exporter les statistiques annuelles"
              nzTooltipPlacement="bottom"
              nz-button
              nz-dropdown
              [nzDropdownMenu]="exportListMenu"
              nzTrigger="click"
              nzPlacement="bottomRight"
            >
              <i
                nz-icon
                nzTheme="outline"
                nzType="download"
                class="iconSelectionHeader"
              ></i>
            </button>

            <nz-dropdown-menu #exportListMenu="nzDropdownMenu">
              <ul nz-menu>
                <li
                  nz-menu-item
                  *ngFor="let item of exportList"
                  [nzDisabled]="item.disabled"
                  (click)="
                    onExport(
                      'entites',
                      item.name,
                      yearsSelectors,
                      statistiquesEntiteYear
                    )
                  "
                >
                  <i nz-icon [nzType]="item.icon"></i>
                  <span>{{ item.name }}</span>
                </li>
              </ul>
            </nz-dropdown-menu>
          </ng-container>

          <ng-container
            *ngIf="
              (dashboardContact$ | async) &&
              (statistiquesYear$ | async)
                ?.contactsStatsYear as contactsStatsYear
            "
          >
            <button
              nzType="text"
              nz-tooltip
              nzTooltipTitle="Exporter les statistiques annuelles"
              nzTooltipPlacement="bottom"
              nz-button
              nz-dropdown
              [nzDropdownMenu]="exportListMenu"
              nzTrigger="click"
              nzPlacement="bottomRight"
            >
              <i
                nz-icon
                nzTheme="outline"
                nzType="download"
                class="iconSelectionHeader"
              ></i>
            </button>

            <nz-dropdown-menu #exportListMenu="nzDropdownMenu">
              <ul nz-menu>
                <li
                  nz-menu-item
                  *ngFor="let item of exportList"
                  [nzDisabled]="item.disabled"
                  (click)="
                    onExport(
                      'contacts',
                      item.name,
                      yearsSelectors,
                      contactsStatsYear
                    )
                  "
                >
                  <i nz-icon [nzType]="item.icon"></i>
                  <span>{{ item.name }}</span>
                </li>
              </ul>
            </nz-dropdown-menu>
          </ng-container>
        </nz-button-group>
      </nz-col>
      <nz-col nzSpan="4">
        <small *ngIf="(dashboardActif$ | async) === 'Entité'">
          Dernière mise à jour :
          {{ entiteStatistiquesDateUpdate$ | async | dateFr: "small" }}
        </small>
        <small *ngIf="(dashboardActif$ | async) === 'Contact'">
          Dernière mise à jour :
          {{ contactStatistiquesDateUpdate$ | async | dateFr: "small" }}
        </small>
      </nz-col>
    </nz-row>
  </ng-container>
</ng-container> -->

  <div class="container" *ngIf="statistiques$ | async as statistiques">
    <nz-row
      nzJustify="center"
      nzAlign="middle"
      nzGutter="0"
      class="ENTITE_byIndicateur"
    >
      <nz-col nzSpan="8">
        <app-charts [source]="grandDomaine$"></app-charts>
      </nz-col>
      <nz-col nzSpan="16">
        <app-charts [source]="byMonths$"></app-charts>
      </nz-col>
    </nz-row>

    <nz-row nzJustify="center" nzAlign="middle" nzGutter="4">
      <nz-col nzSpan="12">
        <app-charts [source]="domaine$"></app-charts>
      </nz-col>
      <nz-col nzSpan="12">
        <app-charts [source]="categories$"></app-charts>
      </nz-col>
    </nz-row>

    <nz-row nzJustify="center" nzAlign="middle" nzGutter="4">
      <nz-col nzSpan="12">
        <app-charts [source]="activities$"></app-charts>
      </nz-col>
      <nz-col nzSpan="12">
        <app-charts [source]="juridiques$"></app-charts>
      </nz-col>
    </nz-row>

    <nz-row nzJustify="center" nzAlign="middle" nzGutter="4">
      <nz-col nzSpan="12">
        <app-charts [source]="entiteDepartements$"></app-charts>
      </nz-col>
      <nz-col nzSpan="12">
        <app-charts [source]="entiteCommunes$"></app-charts>
      </nz-col>
    </nz-row>
  </div>

  <ng-template #noData>
    <nz-empty nzNotFoundImage="Simple"></nz-empty>
  </ng-template>
</ng-container>
