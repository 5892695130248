import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { SideNavigation } from './side-navigation.model';
import * as SideNavigationActions from './side-navigation.actions';

export const sideNavigationsFeatureKey = 'sideNavigations';

export interface SideNavigationState extends EntityState<SideNavigation> {
  navigationState: any;

  navigation: any;
  currentNavigation: any;
}

export const adapter: EntityAdapter<SideNavigation> =
  createEntityAdapter<SideNavigation>();

export const initialState: SideNavigationState = adapter.getInitialState({
  navigationState: undefined,

  navigation: undefined,
  currentNavigation: undefined,
});

export const reducer = createReducer(
  initialState,

  //NAVIGATION STATE
  on(SideNavigationActions.loadNavigationStateSuccess, (state, action) => {
    return {
      ...state,
      navigationState: action.state,
    };
  }),

  //NAVIGATION SIDE
  on(SideNavigationActions.loadSideNavigation, (state, action) => {
    return {
      ...state,
      navigation: action.navigation,
    };
  }),
  on(SideNavigationActions.selectSideNavigation, (state, action) => {
    return {
      ...state,
      currentNavigation: action.navigation,
    };
  }),

  on(SideNavigationActions.clearSideNavigations, (state) =>
    adapter.removeAll(state)
  )
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
