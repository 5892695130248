import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-operation-participants-selectors',
  templateUrl: './operation-participants-selectors.component.html',
  styleUrls: ['./operation-participants-selectors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationParticipantsSelectorsComponent
  implements OnInit, OnChanges
{
  @Output() add = new EventEmitter<any>(false);
  @Output() cancel = new EventEmitter<any>(false);
  @Output() delete = new EventEmitter<any>(false);

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}

  onSelect(item: any): void {
    if (!item) return;
    this.add.emit(item);
  }
}
