<ng-container *ngIf="participant$ | async as participant">
  <nz-row nzAlign="middle" nzJustify="end" nzGutter="8">
    <nz-col nzSpan="6">
      <nz-select
        nzPlaceHolder="Période"
        nzBorderless
        nzAllowClear
        [nzLoading]="(filterMoments$ | async) ? false : true"
      >
        <nz-option
          *ngFor="let item of filterMoments$ | async"
          [nzLabel]="item"
          [nzValue]="item"
        >
        </nz-option>
      </nz-select>
    </nz-col>
    <nz-col nzSpan="8">
      <nz-date-picker
        [nzMode]="dateMode"
        [nzFormat]="dateFormat"
      ></nz-date-picker>
    </nz-col>
    <nz-col nzSpan="8">
      <nz-select
        nzPlaceHolder="Type d'événement"
        nzBorderless
        [nzLoading]="(types$ | async) ? false : true"
        nzAllowClear
      >
        <nz-option
          *ngFor="let item of types$ | async"
          nzCustomContent
          [nzLabel]="item.label"
          [nzValue]="item"
        >
          <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
            <nz-col nzSpan="2">
              <nz-badge [nzColor]="item.color"></nz-badge>
            </nz-col>
            <nz-col nzSpan="22">
              {{ item.label }}
            </nz-col>
          </nz-row>
        </nz-option>
      </nz-select>
    </nz-col>
    <nz-col nzSpan="2">
      <button
        nz-button
        nzType="text"
        nzShape="circle"
        (click)="onRemoveFilter()"
        nz-tooltip
        nzTooltipPlacement="bottom"
        nzTooltipTitle="Supprimer les filtres"
        [nzTooltipMouseEnterDelay]="0.5"
      >
        <mat-icon>filter_list_off</mat-icon>
      </button>
    </nz-col>
  </nz-row>
</ng-container>
