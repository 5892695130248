<nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
  <nz-col [nzSpan]="isButton ? 12 : 0">
    <nz-button-group>
      <button nz-button (click)="$event.preventDefault(); onNew()">
        Nouvelle tâche
      </button>
      <button
        nz-button
        nzType="text"
        nz-dropdown
        [nzDropdownMenu]="filtersMenu"
        nzPlacement="bottomRight"
        nzTrigger="click"
      >
        <mat-icon *ngIf="!(isFiltered$ | async)">more_vertical</mat-icon>
        <mat-icon *ngIf="isFiltered$ | async" class="filter_Active"
          >filter_list</mat-icon
        >
      </button>
      <nz-dropdown-menu #filtersMenu="nzDropdownMenu">
        <ul nz-menu>
          <li
            *ngFor="let item of filters$ | async"
            nz-menu-item
            (click)="$event.stopPropagation(); onAction(item)"
            [ngClass]="{ filter_Active: item === currentFilter }"
          >
            {{ item }}
          </li>
          <li nz-menu-divider></li>
          <li
            nz-menu-item
            nzDanger
            *ngIf="taskSize > 0"
            (click)="$event.stopPropagation()"
            nz-popconfirm
            (nzOnCancel)="onCancel()"
            (nzOnConfirm)="onDeleteAll()"
            nzPopconfirmTrigger="click"
            [nzPopconfirmTitle]="deleteAllType"
          >
            Supprimer tout
          </li>
        </ul>
      </nz-dropdown-menu>
    </nz-button-group>
  </nz-col>
  <nz-col [nzSpan]="isButton ? 0 : 2">
    <i nz-icon [nzType]="icon"></i>
  </nz-col>
  <nz-col [nzSpan]="isButton ? 2 : 12"></nz-col>
  <nz-col nzSpan="6">
    <h4>{{ title }}</h4>
  </nz-col>
  <nz-col [nzSpan]="4">
    <nz-badge nzStandalone [nzCount]="count"></nz-badge>
  </nz-col>
</nz-row>
