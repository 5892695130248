import { Action } from './../../action/store/action.model';
import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { OperationContacts } from './operation-contacts.model';
import { ActionId } from '../../action/store/action.model';
import { ContactSMALLid } from '../../contact/store/contact.model';

//LOADING
export const loadingState = createAction(
  '[OperationContacts/API] Loading OperationContact',
  props<{ loading: boolean }>()
);

//LOAD
export const loadOperationContacts = createAction(
  '[OperationContacts/API] Load OperationContacts',
  props<{ operationId: string }>()
);
export const loadOperationContactsSuccess = createAction(
  '[OperationContacts/API] Load OperationContacts Success',
  props<{ contacts: any[] }>()
);
export const loadOperationContactsFailure = createAction(
  '[OperationContacts/API] Load OperationContacts Failure',
  props<{ error: any }>()
);

//LOAD
export const loadOperationContact = createAction(
  '[OperationContacts/API] Load OperationContact',
  props<{ operationId: string; id: string }>()
);
export const loadOperationContactSuccess = createAction(
  '[OperationContacts/API] Load OperationContact Success',
  props<{ contact: any }>()
);
export const loadOperationContactFailure = createAction(
  '[OperationContacts/API] Load OperationContact Failure',
  props<{ error: any }>()
);

//ADD
export const addOperationContact = createAction(
  '[OperationContacts/API] Add OperationContact',
  props<{ operation: any; contact: ContactSMALLid; intervention: any }>()
);
export const addOperationContactSuccess = createAction(
  '[OperationContacts/API] Add OperationContact Success',
  props<{ success: string }>()
);
export const addOperationContactFailure = createAction(
  '[OperationContacts/API] Add OperationContact Failure',
  props<{ error: any }>()
);

//UPDATE
export const updateOperationContacts = createAction(
  '[OperationContacts/API] Update OperationContact',
  props<{ operationId: string; contact: Update<ContactSMALLid> }>()
);
export const updateOperationContactSuccess = createAction(
  '[OperationContacts/API] Update OperationContact Success',
  props<{ success: string }>()
);
export const updateOperationContactFailure = createAction(
  '[OperationContacts/API] Update OperationContact Failure',
  props<{ error: any }>()
);

//DELETE

export const deleteOperationContact = createAction(
  '[OperationContacts/API] Delete OperationContact',
  props<{ operationId: string; id: string }>()
);
export const deleteOperationContactSuccess = createAction(
  '[OperationContacts/API] Delete OperationContact Success',
  props<{ success: string }>()
);
export const deleteOperationContactFailure = createAction(
  '[OperationContacts/API] Delete OperationContact Failure',
  props<{ error: any }>()
);

//DELETE ALL
export const deleteOperationContacts = createAction(
  '[OperationContacts/API] Delete OperationContactss',
  props<{ ids: string[] }>()
);

//CLEAR
export const clearOperationContactss = createAction(
  '[OperationContacts/API] Clear OperationContactss'
);

//QUERY
export const queryOperationContacts = createAction(
  '[OperationContacts/API] Query OperationContacts',
  props<{ filter: string; filterType: string; operationId: string }>()
);
export const queryOperationContactsSuccess = createAction(
  '[OperationContacts/API] Query OperationContacts Success',
  props<{ contacts: any }>()
);
export const queryOperationContactsFailure = createAction(
  '[OperationContacts/API] Query OperationContacts Failure',
  props<{ error: any }>()
);
