<ng-container *ngIf="operation">
  <nz-card [nzTitle]="operationCadrage__definitionTitle">
    <nz-descriptions
      nzSize="small"
      nzBordered
      *ngIf="!(editing$ | async)"
      [nzColumn]="{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }"
    >
      <nz-descriptions-item [nzTitle]="title" [nzSpan]="1">
        {{
          operation?.description?.definition
            ? operation.description.definition?.textValue
            : "Non renseignée"
        }}
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Démarrage" [nzSpan]="1">
        {{
          operation?.description?.calendrier?.dateStart
            ? (operation?.description?.calendrier?.dateStart | dateFr: "small")
            : "Non renseignée"
        }}
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Échéance" [nzSpan]="1">
        {{
          operation?.description?.calendrier?.dateEnd
            ? (operation?.description?.calendrier?.dateEnd | dateFr: "small")
            : "Non renseignée"
        }}
      </nz-descriptions-item>

      <nz-descriptions-item nzTitle="Durée" [nzSpan]="1">
        {{
          operation?.description?.calendrier?.duree
            ? operation.description.calendrier.duree + " mois"
            : "Non renseignée"
        }}
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Adresse" [nzSpan]="1">
        {{
          operation?.description?.adresse?.adresse?.label
            ? operation.description.adresse.adresse.label
            : "Non renseignée"
        }}
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Complément" [nzSpan]="1">
        {{
          operation?.description?.adresse?.complement
            ? operation.description.adresse.complement
            : "Non renseignée"
        }}
      </nz-descriptions-item>
    </nz-descriptions>

    <form nz-form [formGroup]="cadrageForm" *ngIf="editing$ | async">
      <app-text-edithing
        (add)="on($event)"
        [update]="editingDefinition$"
      ></app-text-edithing>

      <nz-divider
        nzOrientation="center"
        nzText="Calendrier"
        nzType="horizontal"
      ></nz-divider>
      <nz-row
        formGroupName="description"
        nzAlign="middle"
        nzJustify="start"
        nzGutter="8"
      >
        <nz-col nzSpan="24">
          <nz-form-item nzFlex>
            <nz-form-control>
              <nz-range-picker
                nzBorderless
                [nzFormat]="format"
                formControlName="dates"
                [nzPlaceHolder]="['Démmarrage', 'Échénace']"
              ></nz-range-picker>
            </nz-form-control>
          </nz-form-item>
        </nz-col>
      </nz-row>

      <nz-divider
        nzOrientation="center"
        nzText="Adresse"
        nzType="horizontal"
      ></nz-divider>
      <app-communes-france
        [borderless]="true"
        [size]="'default'"
      ></app-communes-france>
    </form>
  </nz-card>

  <ng-template #operationCadrage__definitionTitle>
    <app-header-title
      [icon]="'subject'"
      [title]="'Définition'"
      [subtitle]="true"
    ></app-header-title>
  </ng-template>

  <ng-template #cardExtra>
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="end">
      <nz-col *ngIf="!(editing$ | async)" [nzSpan]="24">
        <button
          nz-button
          nzShape="circle"
          (click)="onModified(operation)"
          nzType="text"
        >
          <mat-icon>edit</mat-icon>
        </button>
      </nz-col>
      <nz-col *ngIf="editing$ | async" [nzSpan]="12">
        <button nz-button (click)="editing$.next(false)" nzType="text">
          Annuler
        </button>
      </nz-col>
      <nz-col *ngIf="editing$ | async" [nzSpan]="12">
        <button
          nz-button
          nzType="primary"
          (click)="onUpdate(operation)"
          [disabled]="!cadrageForm.valid"
        >
          Valider
        </button>
      </nz-col>
    </nz-row>
  </ng-template>

  <ng-template #title>
    <mat-icon>subject</mat-icon>
  </ng-template>
  <ng-template #event>
    <mat-icon>event</mat-icon>
  </ng-template>
</ng-container>
