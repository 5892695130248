<div class="w-screen flex items-center justify-between ml-12 mr-12">
  <div class="w-2/12 flex items-start">
    <app-header-title
      [title]="title"
      [icon]="icon"
      class="w-full"
    ></app-header-title>
  </div>
  <div class="w-6/12 flex items-start">
    <form
      [formGroup]="searchForm"
      class="relative w-full flex items-center justify-start"
    >
      <div
        class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
      >
        <svg
          class="iconSvg w-6 h-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
          />
        </svg>
      </div>

      <input
        formControlName="search"
        type="text"
        [placeholder]="placeholder"
        class="inputIconForm"
      />
      <div class="hidden">
        <button type="button" (click)="clearSearchForm()" *ngIf="search.dirty">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-7 h-7 hover:shadow-lg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <button type="button" (click)="$event.stopPropagation(); onSearch()">
          <mat-icon>search</mat-icon>
        </button>
      </div>
    </form>
  </div>
  <div class="w-4/12 flex items-center">
    <button class="btn-icon group/button">
      <svg
        class="iconSvg"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z"
        />
      </svg>
    </button>
    <app-button-dropdown
      [isDelete]="isDelete"
      [isDeleteAll]="isDeleteAll"
      [isExportPDF]="isExportPDF"
      [deleteAllType]="deleteAllType"
      [deleteType]="deleteType"
      (select)="onDropdownActions($event)"
    ></app-button-dropdown>
  </div>
</div>
