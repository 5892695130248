<ng-container *ngIf="action">
  <nz-page-header
    class="site-page-header"
    (nzBack)="onBack()"
    nzBackIcon
    [nzTitle]="action.title | uppercase"
    [nzSubtitle]="action?.type | uppercase"
  >
    <nz-page-header-content>
      <nz-row nzAlign="middle" nzGutter="8" nzJustify="center">
        <nz-col nzSpan="12">
          <nz-descriptions nzSize="small" [nzColumn]="3">
            <nz-descriptions-item nzTitle="Démarrage" [nzSpan]="1">{{
              action?.dateStart | dateFr : "time"
            }}</nz-descriptions-item>
            <nz-descriptions-item nzTitle="Échéance" [nzSpan]="1">{{
              action?.dateEnd | dateFr : "time"
            }}</nz-descriptions-item>
          </nz-descriptions>
        </nz-col>
        <nz-col nzSpan="12">
          <nz-row nzJustify="start" nzGutter="8" nzAlign="middle">
            <nz-col nzSpan="24">
              <nz-progress
                *ngIf="action?.progressionHours < 1"
                [nzPercent]="action?.progressionHours * 100"
                [nzStrokeColor]="progressDefaultColor"
                [nzShowInfo]="true"
              ></nz-progress>
              <nz-progress
                *ngIf="action?.progressionHours >= 1"
                [nzPercent]="action?.progressionHours * 100"
                [nzPercent]="100"
                [nzStrokeColor]="progressCompleteColor"
                [nzShowInfo]="true"
              ></nz-progress>
            </nz-col>
          </nz-row>
          <nz-row nzJustify="start" nzGutter="8" nzAlign="middle">
            <nz-col nzSpan="1">
              <mat-icon *ngIf="action?.progressionHours < 1"
                >date_range</mat-icon
              >
            </nz-col>
            <nz-col nzSpan="10">
              <span *ngIf="action?.progressionHours < 1">{{
                action?.dateEnd | dateFr : "distanceSuffix"
              }}</span>
            </nz-col>
            <nz-col nzSpan="1">
              <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
            </nz-col>
            <nz-col nzSpan="6">
              {{
                action?.objectifs
                  ? "Objectif : " +
                    (action?.objectifs | number : "1.1-2" : "fr")
                  : ""
              }}
            </nz-col>
            <nz-col nzSpan="6">
              {{
                action?.objectifs
                  ? "Réalisation : " +
                    (action?.objectifs | number : "1.1-2" : "fr")
                  : ""
              }}
            </nz-col>
          </nz-row>
        </nz-col>
      </nz-row>
    </nz-page-header-content>
    <nz-page-header-extra>
      <app-button-dropdown
        [isDelete]="true"
        [isUpdate]="true"
        [isExportPDF]="true"
        (click)="$event.stopPropagation()"
      ></app-button-dropdown>
    </nz-page-header-extra>
  </nz-page-header>
  <app-item-tabset
    [tabs]="[
      {
        name: 'Contexte',
        index: 0,
        template: actionContext
      },
      {
        name: 'Pariticipants',
        index: 1,
        template: actionParticipants
      },
      {
        name: 'Missions',
        index: 2,
        template: actionMissions
      },
      {
        name: 'Fiches de poste',
        index: 3,
        template: actionFichesPoste
      },
      {
        name: 'Suivis',
        index: 4,
        template: actionSuivis
      },
      {
        name: 'Documents',
        index: 5,
        template: actionDocuments
      }
    ]"
  ></app-item-tabset>

  <ng-template #actionTitle>
    <mat-icon class="title">dynamic_feed</mat-icon>
    <h4 nz-typography class="title">{{ action.title | uppercase }}</h4>
  </ng-template>
  <ng-template #actionContext>
    <app-action-details></app-action-details>
  </ng-template>

  <ng-template #actionMissions>
    <app-table-missions></app-table-missions>
  </ng-template>
  <ng-template #actionFichesPoste>
    <app-table-fiches-poste></app-table-fiches-poste>
  </ng-template>
  <ng-template #actionParticipants>
    <app-table-participants></app-table-participants>
  </ng-template>
  <ng-template #actionSuivis>
    <app-table-suivis [source]="suivisSource$ | async"></app-table-suivis>
  </ng-template>

  <ng-template #actionDocuments>
    <app-documents-list [source$]="documentsSource$"></app-documents-list>
  </ng-template>
</ng-container>
