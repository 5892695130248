import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, of, Subject, takeUntil } from 'rxjs';
import { UtilitiesState } from 'src/app/contents/components/utilities/store/utilities.reducer';
import * as fromUtilitiesSelector from 'src/app/contents/components/utilities/store/utilities.selectors';
import * as fromUtilitiesAction from 'src/app/contents/components/utilities/store/utilities.actions';

@Component({
  selector: 'app-participant-diagnostic-form-actions',
  templateUrl: './participant-diagnostic-form-actions.component.html',
  styleUrls: ['./participant-diagnostic-form-actions.component.scss'],
})
export class ParticipantDiagnosticFormActionsComponent
  implements OnInit, OnChanges, OnDestroy
{
  subscribe = new Subject();

  constructor(
    private fb: FormBuilder,
    private utilitiesStore: Store<UtilitiesState>
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {}
}
