import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-operation-sharing',
  templateUrl: './operation-sharing.component.html',
  styleUrls: ['./operation-sharing.component.scss'],
})
export class OperationSharingComponent implements OnInit {
  listOfData: any[] = [
    {
      key: '1',
      name: 'John Brown',
      age: 32,
      mobile: '0666666666',
      address: 'New York No. 1 Lake Park',
    },
    {
      key: '2',
      name: 'Jim Green',
      age: 42,
      mobile: '0666666666',

      address: 'London No. 1 Lake Park',
    },
    {
      key: '3',
      name: 'Joe Black',
      age: 32,
      mobile: '0666666666',

      address: 'Sidney No. 1 Lake Park',
    },
  ];

  constructor() {}

  ngOnInit(): void {}
}
