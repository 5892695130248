import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  NzNotificationPlacement,
  NzNotificationService,
} from 'ng-zorro-antd/notification';
import { EntiteTaskService } from 'src/app/service/entite.task.service';
import { EntiteTaskState } from './entite-task.reducer';
import * as fromEntiteTaskAction from './entite-task.actions';
import { catchError, map, mergeMap, of, tap } from 'rxjs';

const NOTIFICATION_PLACEMENT: NzNotificationPlacement = 'bottomRight';
@Injectable()
export class EntiteTaskEffects {
  //LOAD
  loadEntiteTaks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromEntiteTaskAction.loadEntiteTasks),
      tap(() => {
        this.entiteTaskStore.dispatch(
          fromEntiteTaskAction.filterEntiteTasks({ filter: false })
        );
      }),

      mergeMap((action) =>
        this.entiteTaskService.getAll(action.id).pipe(
          map((tasks: any) => {
            this.entiteTaskStore.dispatch(
              fromEntiteTaskAction.loadingEntiteTasks({ loading: false })
            );

            return fromEntiteTaskAction.loadEntiteTasksSuccess({ tasks });
          }),
          catchError((error) => {
            this.onErrorNotification(error, 'LOAD_ENTITE_TAKS');
            return of(fromEntiteTaskAction.loadEntiteTasksFailure({ error }));
          })
        )
      )
    )
  );

  //QUERIES MY TASKS
  loaEntitenMyTaks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromEntiteTaskAction.loadEntiteMyTasks),
      tap(() => {
        this.entiteTaskStore.dispatch(
          fromEntiteTaskAction.filterEntiteTasks({ filter: true })
        );
      }),
      mergeMap((action) =>
        this.entiteTaskService.getAllUserTasks(action.id).pipe(
          map((tasks: any) => {
            this.entiteTaskStore.dispatch(
              fromEntiteTaskAction.loadingEntiteTasks({ loading: false })
            );

            return fromEntiteTaskAction.loadEntiteMyTasksSuccess({
              tasks,
            });
          }),
          catchError((error) => {
            this.onErrorNotification(error, 'LOAD_ENTITE_MY_TAKS');
            return of(fromEntiteTaskAction.loadEntiteMyTasksFailure({ error }));
          })
        )
      )
    )
  );

  //QUERIES DAILY TASKS
  loadEntiteDailyTaks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromEntiteTaskAction.loadEntiteDailyTasks),
      tap(() => {
        this.entiteTaskStore.dispatch(
          fromEntiteTaskAction.filterEntiteTasks({ filter: true })
        );
      }),

      mergeMap((action) =>
        this.entiteTaskService.getAllTodayTasks(action.id).pipe(
          map((tasks: any) => {
            this.entiteTaskStore.dispatch(
              fromEntiteTaskAction.loadingEntiteTasks({ loading: false })
            );

            return fromEntiteTaskAction.loadEntiteDailyTasksSuccess({
              tasks,
            });
          }),
          catchError((error) => {
            this.onErrorNotification(error, 'LOAD_ENTITE_TODAY_TAKS');
            return of(
              fromEntiteTaskAction.loadEntiteDailyTasksFailure({ error })
            );
          })
        )
      )
    )
  );

  //QUERIES WEEKLY TASKS
  loadEntiteWeeklyTaks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromEntiteTaskAction.loadEntiteWeeklyTasks),
      tap(() => {
        this.entiteTaskStore.dispatch(
          fromEntiteTaskAction.filterEntiteTasks({ filter: true })
        );
      }),

      mergeMap((action) =>
        this.entiteTaskService.getAllWeekTasks(action.id).pipe(
          map((tasks: any) => {
            this.entiteTaskStore.dispatch(
              fromEntiteTaskAction.loadingEntiteTasks({ loading: false })
            );

            return fromEntiteTaskAction.loadEntiteWeeklyTasksSuccess({
              tasks,
            });
          }),
          catchError((error) => {
            this.onErrorNotification(error, 'LOAD_ENTITE_WEEK_TAKS');
            return of(
              fromEntiteTaskAction.loadEntiteWeeklyTasksFailure({ error })
            );
          })
        )
      )
    )
  );

  //QUERIES MONTH TASKS
  loadEntiteMonthlyTaks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromEntiteTaskAction.loadEntiteMonthlyTasks),
      tap(() => {
        this.entiteTaskStore.dispatch(
          fromEntiteTaskAction.filterEntiteTasks({ filter: true })
        );
      }),
      mergeMap((action) =>
        this.entiteTaskService.getAllMonthTasks(action.id).pipe(
          map((tasks: any) => {
            this.entiteTaskStore.dispatch(
              fromEntiteTaskAction.loadingEntiteTasks({ loading: false })
            );

            return fromEntiteTaskAction.loadEntiteMonthlyTasksSuccess({
              tasks,
            });
          }),
          catchError((error) => {
            this.onErrorNotification(error, 'LOAD_ENTITE_MONTH_TAKS');
            return of(
              fromEntiteTaskAction.loadEntiteMonthlyTasksFailure({ error })
            );
          })
        )
      )
    )
  );

  //ADD
  addEntiteTaks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromEntiteTaskAction.addEntiteTask),
      tap(() => {
        this.notification.info(
          'Nouveau',
          'Nouvelle tâche en cours de création...',
          {
            nzDuration: 6000,
            nzAnimate: true,
            nzPlacement: NOTIFICATION_PLACEMENT,
            nzKey: 'CREATE_TASK_ENTITE',
          }
        );
        fromEntiteTaskAction.loadingEntiteTasks({ loading: true });
      }),
      mergeMap((action) =>
        this.entiteTaskService.addOne(action.entiteId, action.task).pipe(
          map((respons: any) => {
            const success: string = respons.data.createEntiteTask;

            this.notification.success('Nouveau', success, {
              nzDuration: 8000,
              nzAnimate: true,
              nzPlacement: NOTIFICATION_PLACEMENT,
              nzKey: 'CREATE_TASK_ENTITE',
            });

            return fromEntiteTaskAction.addEntiteTaskSuccess({
              success,
            });
          }),
          catchError((error) => {
            this.onErrorNotification(error, 'CREATE_TASK_ENTITE');

            return of(fromEntiteTaskAction.addEntiteTaskFailure({ error }));
          })
        )
      ),
      tap(() => fromEntiteTaskAction.loadingEntiteTasks({ loading: false }))
    )
  );

  //UPDATE
  updateEntiteTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromEntiteTaskAction.updateEntiteTask),
      tap(() => fromEntiteTaskAction.loadingEntiteTasks({ loading: true })),
      mergeMap((action) =>
        this.entiteTaskService
          .updateOne(action?.task?.changes, action?.updateType)
          .pipe(
            map((respons: any) => {
              const success = respons.data.updateEntiteTask;

              return fromEntiteTaskAction.updateEntiteTaskSuccess({
                success,
              });
            }),
            catchError((error) => {
              this.onErrorNotification(error, 'UPDATE_TASK_ENTITE');

              return of(
                fromEntiteTaskAction.updateEntiteTaskFailure({ error })
              );
            })
          )
      ),
      tap(() => fromEntiteTaskAction.loadingEntiteTasks({ loading: false }))
    )
  );

  //DELETE ONE
  deleteEntiteTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromEntiteTaskAction.deleteEntiteTask),
      tap(() => {
        this.notification.info(
          'Suppression',
          `Suppression de la tâche en cours...`,
          {
            nzDuration: 6000,
            nzAnimate: true,
            nzPlacement: NOTIFICATION_PLACEMENT,
            nzKey: 'DELETE_TASK_ENTITE',
          }
        );
        fromEntiteTaskAction.loadingEntiteTasks({ loading: true });
      }),
      mergeMap((action) =>
        this.entiteTaskService.deleteOne(action.entiteId, action.id).pipe(
          map((respons: any) => {
            const success: string = respons.data.deleteEntiteTask;

            this.notification.success('Suppression', success, {
              nzDuration: 8000,
              nzAnimate: true,
              nzPlacement: NOTIFICATION_PLACEMENT,
              nzKey: 'DELETE_TASK_ENTITE',
            });

            return fromEntiteTaskAction.deleteEntiteTaskSuccess({
              success,
            });
          }),
          catchError((error) => {
            this.onErrorNotification(error, 'DELETE_TASK_ENTITE');

            return of(fromEntiteTaskAction.deleteEntiteTaskFailure({ error }));
          })
        )
      ),
      tap(() => fromEntiteTaskAction.loadingEntiteTasks({ loading: false }))
    )
  );

  //DELETE ALL
  deleteEntiteTaks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromEntiteTaskAction.deleteEntiteTasks),
      tap(() => {
        this.notification.info(
          'Suppression',
          `Suppression de la tâche en cours...`,
          {
            nzDuration: 6000,
            nzAnimate: true,
            nzPlacement: NOTIFICATION_PLACEMENT,
            nzKey: 'DELETE_TASKS_ENTITE',
          }
        );
        fromEntiteTaskAction.loadingEntiteTasks({ loading: true });
      }),
      mergeMap((action) =>
        this.entiteTaskService.deleteAll(action.entiteId).pipe(
          map((respons: any) => {
            const success: string = respons.data.deleteEntiteTasks;
            this.notification.success('Suppression', success, {
              nzDuration: 8000,
              nzAnimate: true,
              nzPlacement: NOTIFICATION_PLACEMENT,
              nzKey: 'DELETE_TASKS_ENTITE',
            });

            return fromEntiteTaskAction.deleteEntiteTasksSuccess({
              success,
            });
          }),
          catchError((error) => {
            this.onErrorNotification(error, 'DELETE_TASKS_ENTITE');

            return of(fromEntiteTaskAction.deleteEntiteTasksFailure({ error }));
          })
        )
      ),
      tap(() => fromEntiteTaskAction.loadingEntiteTasks({ loading: false }))
    )
  );

  constructor(
    private actions$: Actions,
    private notification: NzNotificationService,
    private entiteTaskService: EntiteTaskService,
    private entiteTaskStore: Store<EntiteTaskState>
  ) {}

  onErrorNotification(error: any, key: string): void {
    this.notification.error('Error', error.message, {
      nzDuration: 10000,
      nzAnimate: true,
      nzPlacement: NOTIFICATION_PLACEMENT,
      nzKey: key ? key : '',
    });
  }
}
