<ng-container *ngIf="user$ | async as user">
  <ng-container *ngIf="operation$ | async as operation">
    <nz-card
      [nzTitle]="todoHeaderCard"
      [nzActions]="[
        actionDone,
        actionTest,
        actionVerifed,
        actionAddUsers,
        actionDelete
      ]"
    >
      <nz-content>
        <div *ngIf="task.texts | size">
          <nz-list
            nzSize="small"
            *ngFor="let text of task.texts"
            nzItemLayout="vertical"
          >
            <nz-list-item nzNoFlex class="element-text">
              <i
                nz-icon
                nzType="check-circle"
                *ngIf="text.status"
                class="puce__text"
              ></i>
              <span
                *ngIf="!text.status"
                (click)="onChangeStatus(task, text, operation)"
                >{{ text.value }}</span
              >
              <del
                *ngIf="text.status"
                (click)="onChangeStatus(task, text, operation)"
                >{{ text.value }}</del
              >
              <nz-divider
                nzOrientation="center"
                nzType="horizontal"
              ></nz-divider>
            </nz-list-item>
          </nz-list>
        </div>
      </nz-content>
    </nz-card>

    <ng-template #todoHeaderCard>
      <nz-row nzAlign="middle" nzAlign="middle" nzJustify="start">
        <nz-col nzSpan="2">
          <app-avatar-group [source]="[task?.auteur]"></app-avatar-group>
        </nz-col>
        <nz-col nzSpan="1"> </nz-col>

        <nz-col nzSpan="7">
          <small nz-typography>{{ task?.dateStart | dateFr: "small" }}</small>
        </nz-col>
        <nz-col nzSpan="7">
          <small nz-typography>{{ task?.dateEnd | dateFr: "small" }}</small>
        </nz-col>
        <nz-col nzSpan="1">
          <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
        </nz-col>
        <nz-col nzSpan="6">
          <nz-content>
            <nz-avatar-group *ngFor="let item of task.users">
              <nz-avatar
                nz-col
                [nzSrc]="item?.user.photo ? item.user.photo : ''"
                [nzText]="item?.user.photo ? '' : item.user.avatar"
                nzSize="small"
                nz-popover
                [nzPopoverTitle]="item.user.displayName | titlecase"
                [nzPopoverContent]="popoverUserTemplate"
                nzPopoverPlacement="bottom"
                nzPopoverTrigger="click"
                [ngStyle]="{
                  'background-color': item.user?.setting?.color
                    ? item.user.setting?.color
                    : default
                }"
              >
              </nz-avatar>

              <ng-template #popoverUserTemplate>
                <button
                  *ngIf="user.id === task.auteur.id"
                  nz-button
                  nzSize="small"
                  nzDanger
                  nzBlock
                  (click)="removeUser(item, operation, task)"
                >
                  <span>Retirer</span>
                  <nz-space nzDirection="horizontal" nzSize="large"></nz-space>

                  <i nz-icon nzType="user-delete"></i>
                </button>
              </ng-template>
            </nz-avatar-group>
          </nz-content>
        </nz-col>
      </nz-row>
      <nz-space nzDirection="vertical" nzSize="small"></nz-space>
      <nz-row nzAlign="top" nzAlign="middle" nzJustify="start">
        <nz-col nzSpan="24">
          <strong nz-typography class="task_item_title">{{
            task?.title
          }}</strong>
        </nz-col>
      </nz-row>
    </ng-template>

    <ng-template #actionDone>
      <i
        nz-icon
        nzType="check-circle"
        [ngStyle]="{ color: task.completed ? '#73d13d' : 'grey' }"
        (click)="onCompleted(task, operation)"
        nz-tooltip
        nzTooltipPlacement="bottomRight"
        [nzTooltipTitle]="!task.completed ? 'Terminée' : 'À faire'"
        [nzTooltipMouseEnterDelay]="mouseEnterDelay"
      ></i>
    </ng-template>
    <ng-template #actionTest>
      <i
        nz-icon
        nzType="field-time"
        [ngStyle]="{ color: task.inprogress ? '#73d13d' : '#ff4d4f' }"
        (click)="onInProgress(task, operation)"
        nz-tooltip
        nzTooltipPlacement="bottomRight"
        [nzTooltipTitle]="!task.inprogress ? 'En cours' : 'À faire'"
        [nzTooltipMouseEnterDelay]="mouseEnterDelay"
      ></i>
    </ng-template>
    <ng-template #actionVerifed>
      <i
        nz-icon
        nzType="safety"
        [ngStyle]="{ color: task.verifed ? '#73d13d' : '#ff4d4f' }"
        (click)="onVerified(task, operation)"
        nz-tooltip
        nzTooltipPlacement="bottomRight"
        [nzTooltipTitle]="!task.verifed ? 'Vérifier' : 'À faire'"
        [nzTooltipMouseEnterDelay]="mouseEnterDelay"
      ></i>
    </ng-template>
    <ng-template #actionAddUsers>
      <i
        nz-icon
        nzType="user-add"
        nz-popover
        nzPopoverTitle="Partager"
        [nzPopoverContent]="popoverAddUserTemplate"
        nzPopoverPlacement="bottom"
        nzPopoverTrigger="click"
        *ngIf="user.id === task.auteur.id"
      ></i>
    </ng-template>
    <ng-template #actionDelete>
      <i nz-icon nzType="delete" (click)="onDelete(task, operation)"></i>
    </ng-template>

    <ng-template #popoverAddUserTemplate>
      <div class="teamUserContainer">
        <app-operation-team-selector
          (select)="addUser($event, task, operation)"
        ></app-operation-team-selector>
      </div>
    </ng-template>
  </ng-container>
</ng-container>
