import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { serverTimestamp } from 'firebase/firestore';
import { forEach } from 'lodash';
import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';
import { OperationNoteSujetId } from 'src/app/components/operation-note-sujet/store/operation-note-sujet.model';
import {
  OperationNoteDetailId,
  OperationNoteId,
} from 'src/app/components/operation-note/store/operation-note.model';
import { OperationId } from 'src/app/components/operation/store/operation.model';
import { OperationState } from 'src/app/components/operation/store/operation.reducer';
import * as fromOperationAction from 'src/app/components/operation/store/operation.actions';
import { ActivatedRoute, Data } from '@angular/router';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-operation-note-document-list',
  templateUrl: './operation-note-document-list.component.html',
  styleUrls: ['./operation-note-document-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationNoteDocumentListComponent implements OnInit {
  documents$: Observable<any[]> = of([]);
  utilities$: Observable<any> = of();
  subscribe = new Subject();
  new__DOCUMENT = new BehaviorSubject<boolean>(false);
  documentForm: UntypedFormGroup = this.fb.group({});

  @Input() operation$: Observable<OperationId | any> = of(null);
  @Input() note$: Observable<OperationNoteId | any> = of(null);
  @Input() sujet$: Observable<OperationNoteSujetId | any> = of(null);

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private operationStore: Store<OperationState>
  ) {
    const data: Data = this.route.snapshot.data;
    this.operationStore.dispatch(
      fromOperationAction.setActviteRoute({ title: data?.title })
    );
  }

  ngOnInit(): void {}

  get__SUJET_DOCUMENTS(): void {
    // this.documents$ = this.store.pipe(
    //   select(fromNoteSelectors.selectDocumentsBySujet),
    //   takeUntil(this.subscribe)
    // );
  }

  get documentArray() {
    return this.documentForm.get('documents') as UntypedFormArray;
  }

  onSelectDocument(file: any): void {
    window.open(file);
  }

  documentFormCreate(): void {
    this.documentForm = new UntypedFormGroup({
      documents: this.fb.array([]),
    });
  }

  handleChange__DOCUMENT(
    fileInput: any,
    sujet: OperationNoteSujetId,
    note: OperationNoteDetailId,
    operation: OperationId
  ): void {
    if (fileInput.target.files && fileInput.target.files[0]) {
      const { files } = fileInput.target;
      this.addDocumentFile(files, sujet, note, operation);
    }
  }

  addDocumentFile(
    files: FileList,
    sujet: OperationNoteSujetId,
    note: OperationNoteDetailId,
    operation: OperationId
  ) {
    if (files && files[0]) {
      forEach(files, (file) => {
        const { name, type } = file;
        const fileName = name.split('.')[0];
        const doc = this.fb.group({
          file: [file],
          doc: this.fb.group({
            auths: [''],
            access: [{}],
            dateStart: [serverTimestamp(), Validators.required],
            file: [''],
            type: [type, Validators.required],
            extension: [file.type, Validators.required],
            title: [fileName, Validators.required],
            auteur: [''],
            description: [''],
            fileRef: [''],
            recu: [true],
            valid: [true],
            dateValidation: [serverTimestamp()],
            correspondanceId: [sujet.id],
            organisation: [''],
            operation: [],
          }),
        });
        this.documentArray.push(doc);
      });

      this.save__DOCUMENTS(note, operation);
    }
  }

  save__DOCUMENTS(note: OperationNoteDetailId, operation: OperationId): void {
    const documentsList: any = this.documentArray.value;
    // if (this.documentArray.length > 0) {
    //   this.store.dispatch(
    //     fromNoteActions.addNoteDocuments({
    //       documents: documentsList,
    //       note: note,
    //       operation: operation,
    //     })
    //   );

    //   this.documentArray.reset();
    //   this.documentArray.clear();
    // }
  }

  delete__DOCUMENT(document: any): void {
    // this.store.dispatch(fromNoteActions.deleteDocument({ id: document.id }));
  }

  cancel_delete__DDOCUMENT(): void {
    return;
  }
}
