import { Pipe, PipeTransform } from '@angular/core';
import { format } from 'date-fns';
@Pipe({
  name: 'dateString',
})
export class DateStringPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    } else {
      const date: Date = new Date(value);
      const dateToString: string = format(date, 'dd/MM/yyyy');
      return dateToString;
    }
  }
}
