import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OperationPrescripteursListComponent } from './components/operation-prescripteurs-list/operation-prescripteurs-list.component';
import { OperationPrescripteursCardComponent } from './components/operation-prescripteurs-card/operation-prescripteurs-card.component';
import { OperationPrescripteursDetailsComponent } from './components/operation-prescripteurs-details/operation-prescripteurs-details.component';
import { ContentsModule } from 'src/app/contents/contents.module';
import { OperationPrescripteursItemComponent } from './components/operation-prescripteurs-item/operation-prescripteurs-item.component';
import { PipeModule } from 'src/app/pipe/pipe.module';
import { NoResultModule } from 'src/app/contents/components/no-result/no-result.module';
import { CoordonneesModule } from 'src/app/contents/components/coordonnees/coordonnees.module';
import { CommunesFranceModule } from 'src/app/contents/components/communes-france/communes-france.module';
import { HeaderModule } from 'src/app/contents/components/header-title/header.module';
import { LayoutModule } from 'src/app/contents/components/layout/layout.module';

@NgModule({
  declarations: [
    OperationPrescripteursListComponent,
    OperationPrescripteursCardComponent,
    OperationPrescripteursDetailsComponent,
    OperationPrescripteursItemComponent,
  ],
  imports: [
    CommonModule,
    ContentsModule,
    PipeModule,
    NoResultModule,
    CoordonneesModule,
    HeaderModule,
    CommunesFranceModule,
    LayoutModule,
  ],
  exports: [
    OperationPrescripteursListComponent,
    OperationPrescripteursCardComponent,
    OperationPrescripteursDetailsComponent,
    OperationPrescripteursItemComponent,
  ],
})
export class OperationPrescripteursModule {}
