import { ContactId } from './../../store/contact.model';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Observable, of, Subject } from 'rxjs';
import {
  ItemEventId,
  ItemEvent,
} from '../../../../features/suivi/components/item-event/itemEvent.model';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Update } from '@ngrx/entity';
import { ContactState } from './../../store/contact.reducer';
import * as fromContactAction from './../../store/contact.actions';
import * as fromContactSelector from './../../store/contact.selectors';

@Component({
  selector: 'app-contact-suivis',
  templateUrl: './contact-suivis.component.html',
  styleUrls: ['./contact-suivis.component.scss'],
})
export class ContactSuivisComponent implements OnInit, OnDestroy {
  subscribe = new Subject();
  contact$: Observable<ContactId | any> = of([]);

  suivis$: Observable<ItemEventId[] | any> = of([]);

  constructor(private contactStore: Store<ContactState>) {}

  ngOnInit(): void {
    this.getContact();
    this.getSuivis();
    this.loadSuivis();
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getSuivis(): void {
    this.suivis$ = this.contactStore.select(fromContactSelector.suivis);
  }

  getContact(): void {
    this.contact$ = this.contactStore.select(fromContactSelector.contact);
  }
  loadSuivis(): void {
    this.contact$
      .pipe(takeUntil(this.subscribe))
      .subscribe((contact: ContactId) => {
        if (!contact) {
          return;
        } else {
          this.contactStore.dispatch(
            fromContactAction.loadSuivis({ contactId: contact.id })
          );
        }
      });
  }

  onAdd(event: ItemEvent, contact: ContactId): void {
    const { id } = contact;
    this.contactStore.dispatch(
      fromContactAction.addSuivi({ contactId: id, suivi: event })
    );
  }
  onUpdate(event: ItemEventId, contact: ContactId): void {
    const { id } = contact;
    const nextSuivi: Update<ItemEventId> = {
      id: event.id,
      changes: { ...event },
    };
    this.contactStore.dispatch(
      fromContactAction.updateSuivi({
        suivi: nextSuivi,
        contactId: id,
      })
    );
  }
  onDelete(event: ItemEventId, contact: ContactId): void {
    const { id } = contact;
    this.contactStore.dispatch(
      fromContactAction.deleteSuivi({
        contactId: id,
        suiviId: event.id,
      })
    );
  }
}
