<form nz-form [formGroup]="missionForm">
  <nz-row nzAlign="top" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="4">
      <app-missions-form-steps
        [isAdd]="isAdd"
        [currentStep]="currentStep"
        [isMetierUpdate]="isMetierUpdate"
        [isContrat]="isContrat"
        [titleMission]="titleMission"
        [isContratUpdate]="isContratUpdate"
      ></app-missions-form-steps>
    </nz-col>
    <nz-col nzSpan="20">
      <div [ngSwitch]="currentStep">
        <div *ngSwitchCase="0">
          <nz-card
            [nzTitle]="missionAdresseTitleTpl"
            nzSize="small"
            nzBorderless
          >
            <ng-template #missionAdresseTitleTpl>
              <app-header-title
                [icon]="'room'"
                [title]="''"
                [subtitle]="true"
              ></app-header-title>
            </ng-template>
            <ng-container *ngIf="isOperation">
              <app-missions-form-operation
                [operationItem]="operationUpdateItem$ | async"
                (formChanges)="onAddOperation($event)"
              ></app-missions-form-operation>
              <nz-divider
                nzOrientation="center"
                nzType="horizontal"
              ></nz-divider>
            </ng-container>
            <app-communes-france
              [borderless]="true"
              [size]="sizeAdresse"
              [isComplement]="false"
              [isLabel]="true"
              [required]="true"
              [adresseItemUpdate]="missionForm.value?.adresse"
              [updateItem]="adresseUpdateItem$"
              (adresse)="onAddAdresse($event)"
              (complementIn)="onAddAdresseComplement($event)"
              (clear)="onAdresseClear()"
            ></app-communes-france>
          </nz-card>
        </div>
        <div *ngSwitchCase="1">
          <nz-card [nzTitle]="missionJobTitleTpl" nzSize="small" nzBorderless>
            <ng-template #missionJobTitleTpl>
              <app-header-title
                [icon]="'business_center'"
                [title]="''"
                [subtitle]="true"
              ></app-header-title>
            </ng-template>
            <app-metiers
              [type]="'grandDomaine'"
              [showTitle]="false"
              [showMetiers]="true"
              [showAppelations]="true"
              [showAppelation]="true"
              [showSoftskills]="true"
              [hideCancel]="false"
              [hideValidation]="true"
              [showQualification]="true"
              [showAppelationDetail]="true"
              [showAdresse]="false"
              (appelation)="onAddMetier($event)"
            ></app-metiers>
          </nz-card>
        </div>
        <div *ngSwitchCase="2">
          <app-metiers-definition
            [metier]="missionForm.value?.metier?.metier"
            (remove)="onCleanMission()"
          ></app-metiers-definition>
        </div>
        <div *ngSwitchCase="3">
          <nz-card
            nzSize="small"
            nzBorderless
            [nzTitle]="competencesCardHeader"
          >
            <app-item-tabset
              [tabs]="[
                {
                  name: 'Compétences de base',
                  template: tabCompetencesDeBaseTpl,
                  count: missionForm.value?.metier?.competencesDeBaseSize
                },
                {
                  name: 'Compétences spéficiques',
                  template: tabCompetenceSpecifiquesTpl,
                  count: missionForm.value?.metier?.competencesSpecifiquesSize
                },
                {
                  name: 'Compétences transversales',
                  template: tabCompetencesTransversalesTpl,
                  count: missionForm.value?.metier?.softskills?.length
                },
                {
                  name: 'Environnement',
                  template: tabEnvironnementalTpl,
                  count:
                    missionForm.value?.metier?.environnementsTravail?.length
                }
              ]"
            ></app-item-tabset>

            <ng-template #tabCompetencesDeBaseTpl>
              <app-metiers-competences
                [isCompetences]="true"
                [source]="currentCompetencesDeBase$ | async"
                (formChanges)="onMissionCompetencesDeBaseChanges($event)"
              ></app-metiers-competences>
            </ng-template>
            <ng-template #tabCompetenceSpecifiquesTpl>
              <app-metiers-competences
                [isCompetencesSpeficiques]="true"
                [source]="currentCompetencesSpecifiques$ | async"
                (formChanges)="onMissionCompetencesSpecifiquesChanges($event)"
              ></app-metiers-competences>
            </ng-template>
            <ng-template #tabCompetencesTransversalesTpl>
              <app-metiers-competences
                [isSoftskills]="true"
                [isItemLabel]="true"
                [source]="currentSoftskills$ | async"
                [itemLabel]="'summary'"
                [field]="'details'"
                (formChanges)="onMissionCompetencesTransversalesChanges($event)"
              ></app-metiers-competences>
            </ng-template>
            <ng-template #tabEnvironnementalTpl>
              <app-metiers-competences
                [isEnvironnement]="true"
                [source]="currentEnvironnementsTravail$ | async"
                (formChanges)="onMissionCompetencesTransversalesChanges($event)"
              ></app-metiers-competences>
            </ng-template>
          </nz-card>
        </div>
        <div *ngSwitchCase="4">
          <app-contrat-form-detail
            (onCurrentChanges)="onAddContratDetail($event)"
          ></app-contrat-form-detail>
        </div>
        <div *ngSwitchCase="5">
          <app-contrat-form-salaire
            (onCurrentChanges)="onAddContratRemuneration($event)"
          ></app-contrat-form-salaire>
        </div>
        <div *ngSwitchCase="6">
          <app-contrat-form-travail
            (onCurrentChanges)="onAddContratTravail($event)"
          ></app-contrat-form-travail>
        </div>
        <div *ngSwitchCase="7">
          <app-contrat-form-evolution
            (onCurrentChanges)="onAddContratEvolution($event)"
          ></app-contrat-form-evolution>
        </div>
        <div *ngSwitchDefault></div>
      </div>
    </nz-col>
  </nz-row>

  <div class="w-full flex items-end p-6 pr-12 space-x-4 h-40 m-2">
    <div class="w-3/4"></div>
    <div class="w-24">
      <button class="btn btn-sm btn-ghost" type="button" (click)="onCancel()">
        Annuler
      </button>
    </div>
    <div class="w-24">
      <button
        type="button"
        [class]="
          (isContratUpdate === 'UPDATE_CONTRAT' && currentStep === 4) ||
          (isMetierUpdate === 'UPDATE_METIER' && currentStep === 0)
            ? 'btn btn-sm btn-disabled'
            : 'btn btn-sm btn-default'
        "
        (click)="
          $event.stopPropagation();
          onStepBack(currentStep, isAdd, isMetierUpdate, isContratUpdate)
        "
      >
        Précédent
      </button>
    </div>
    <div class="w-24">
      <!-- ADRESSE VALIDATION -->
      <button
        type="button"
        [class]="
          !missionForm.value?.adresse?.adresse?.properties?.label ||
          !missionForm.value?.operation?.denomination
            ? 'btn btn-sm btn-disabled'
            : 'btn btn-sm btn-primary'
        "
        (click)="
          $event.stopPropagation();
          onStepNext(currentStep, isAdd, isMetierUpdate, isContratUpdate)
        "
        *ngIf="currentStep === 0"
        [disabled]="
          !missionForm.value?.adresse?.adresse?.properties?.label ||
          !missionForm.value?.operation?.denomination
        "
      >
        Suivant
      </button>

      <!-- METIER VALIDATION -->
      <button
        type="button"
        [class]="
          !missionForm.value?.metier?.code
            ? 'btn btn-sm btn-disabled'
            : 'btn btn-sm btn-primary'
        "
        (click)="
          $event.stopPropagation();
          onStepNext(currentStep, isAdd, isMetierUpdate, isContratUpdate)
        "
        *ngIf="currentStep === 1"
        [disabled]="!missionForm.value?.metier?.code"
      >
        Suivant
      </button>

      <!-- STEP DESCRIPTION && COMPÉTENCES -->
      <!-- ON ADD -->

      <button
        [class]="'btn btn-sm btn-primary'"
        type="button"
        (click)="
          $event.stopPropagation();
          onStepNext(currentStep, isAdd, isMetierUpdate, isContratUpdate)
        "
        *ngIf="
          isMetierUpdate !== 'UPDATE_METIER' &&
          currentStep > 1 &&
          currentStep < 4
        "
      >
        Suivant
      </button>

      <button
        [class]="'btn btn-sm btn-primary'"
        type="button"
        (click)="
          $event.stopPropagation();
          onStepNext(currentStep, isAdd, isMetierUpdate, isContratUpdate)
        "
        *ngIf="
          isMetierUpdate === 'UPDATE_METIER' &&
          currentStep > 1 &&
          currentStep < 3
        "
      >
        Suivant
      </button>

      <!-- ON UPDATE -->

      <button
        [class]="
          !missionForm.value?.metier
            ? 'btn btn-sm btn-disabled'
            : 'btn btn-sm btn-primary'
        "
        type="button"
        (click)="$event.stopPropagation(); onUpdateValidation()"
        *ngIf="isMetierUpdate === 'UPDATE_METIER' && currentStep === 3"
      >
        Valider
      </button>

      <!-- CONTRAT TYPE VALIDATION -->
      <button
        [class]="
          !missionForm.value?.contrat?.type
            ? 'btn btn-sm btn-disabled'
            : 'btn btn-sm btn-primary'
        "
        type="button"
        [disabled]="!missionForm.value?.contrat?.type"
        (click)="
          $event.stopPropagation();
          onStepNext(currentStep, isAdd, isMetierUpdate, isContratUpdate)
        "
        *ngIf="currentStep === 4"
      >
        Suivant
      </button>

      <!-- CONTRAT REMUNERATION VALIDATION -->
      <button
        [class]="
          !missionForm.value?.contrat?.salaire ||
          !missionForm.value?.contrat?.work_price
            ? 'btn btn-sm btn-disabled'
            : 'btn btn-sm btn-primary'
        "
        type="button"
        [disabled]="
          !missionForm.value?.contrat?.salaire ||
          !missionForm.value?.contrat?.work_price
        "
        (click)="
          $event.stopPropagation();
          onStepNext(currentStep, isAdd, isMetierUpdate, isContratUpdate)
        "
        *ngIf="currentStep === 5"
      >
        Suivant
      </button>

      <!-- CONTRAT TEMPS DE TRAVAIL VALIDATION -->
      <button
        [class]="
          !missionForm.value?.contrat?.amplitude_horaire ||
          !missionForm.value?.contrat?.duree_hebdomadaire ||
          !missionForm.value?.contrat?.horaireStartAM ||
          !missionForm.value?.contrat?.horaireEndAM ||
          !missionForm.value?.contrat?.horaireStartPM ||
          !missionForm.value?.contrat?.horaireEndPM
            ? 'btn btn-sm btn-disabled'
            : 'btn btn-sm btn-primary'
        "
        type="button"
        [disabled]="
          !missionForm.value?.contrat?.amplitude_horaire ||
          !missionForm.value?.contrat?.duree_hebdomadaire ||
          !missionForm.value?.contrat?.horaireStartAM ||
          !missionForm.value?.contrat?.horaireEndAM ||
          !missionForm.value?.contrat?.horaireStartPM ||
          !missionForm.value?.contrat?.horaireEndPM
        "
        (click)="
          $event.stopPropagation();
          onStepNext(currentStep, isAdd, isMetierUpdate, isContratUpdate)
        "
        *ngIf="currentStep === 6"
      >
        Suivant
      </button>

      <!-- ON ADD -->
      <button
        [class]="
          !missionForm.valid
            ? 'btn btn-sm btn-disabled'
            : 'btn btn-sm btn-primary'
        "
        type="button"
        (click)="$event.stopPropagation(); onValidation()"
        *ngIf="isContratUpdate !== 'UPDATE_CONTRAT' && currentStep === 7"
        [disabled]="!missionForm.valid"
      >
        <span>Suivant</span>
      </button>

      <!-- ON UPDATE -->
      <button
        [class]="
          !missionForm.value?.contrat
            ? 'btn btn-sm btn-disabled'
            : 'btn btn-sm btn-primary'
        "
        type="button"
        (click)="$event.stopPropagation(); onUpdateValidation()"
        *ngIf="isContratUpdate === 'UPDATE_CONTRAT' && currentStep === 7"
        [disabled]="!missionForm.value?.contrat"
      >
        <span>Valider</span>
      </button>
    </div>
  </div>
</form>

<ng-template #competencesCardHeader>
  <app-header-title [icon]="'article'"></app-header-title>
</ng-template>
