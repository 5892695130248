<form nz-form [formGroup]="mobiliteForm">
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col [nzSpan]="12">
      <nz-form-item>
        <nz-form-label [nzSpan]="24" nzFor="modeDeDeplacement"
          >Mode de transport
        </nz-form-label>
        <nz-form-control>
          <nz-select
            nzBorderless
            nzPlaceHolder="Utilisateur des transports"
            formControlName="transport_mode"
            nzMode="tags"
            [nzMaxTagCount]="2"
          >
            <nz-option
              *ngFor="let item of transport_mode$ | async"
              [nzValue]="item"
              [nzLabel]="item"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col [nzSpan]="12">
      <nz-form-item>
        <nz-form-label [nzSpan]="24" nzFor="geoMobility"
          >Mobilité géographique
        </nz-form-label>
        <nz-form-control>
          <nz-select
            nzBorderless
            nzPlaceHolder="Mobilité géographique professionnelle"
            formControlName="geo_mobility"
            nzMode="tags"
            [nzMaxTagCount]="2"
            [compareWith]="compareMobiliteGeographique"
          >
            <nz-option
              *ngFor="let item of geo_mobilite$ | async"
              [nzValue]="item"
              [nzLabel]="item.libelleTypeTerritoire"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="24">
      <nz-form-item>
        <nz-form-label [nzSpan]="24">Permis </nz-form-label>
        <nz-form-control>
          <nz-select
            nzBorderless
            nzPlaceHolder="Permis"
            formControlName="permis"
            nzMode="tags"
            [nzMaxTagCount]="3"
            [compareWith]="compareMobilitePermis"
          >
            <nz-option
              *ngFor="let item of permis$ | async"
              [nzLabel]="item.libelle"
              [nzValue]="item"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>

  <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="24">
      <nz-form-item>
        <nz-form-control>
          <label nz-checkbox formControlName="isFrein">{{
            isFreinTitle
          }}</label>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>
</form>
