import {
  Component,
  OnInit,
  Output,
  Input,
  EventEmitter,
  OnDestroy,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Validators, UntypedFormControl } from '@angular/forms';
import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailComponent implements OnInit, OnChanges, OnDestroy {
  subscribe = new Subject();
  mailControl = new UntypedFormControl(
    '',
    Validators.compose([
      Validators.required,
      Validators.email,
      Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
    ])
  );

  @Input() update: any;
  @Input() borderless: boolean = true;
  @Input() required: boolean = true;

  @Output() email = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {}
  ngOnChanges(changes: SimpleChanges): void {
    this.setUpdate(changes.update.currentValue);
  }

  sendMailAdresse(event: any): void {
    const input: string = event.target.value;
    if (input !== '') {
      input.trim();
      this.email.emit(input);
    }
  }

  clear(event: any): void {
    this.mailControl.setValue('');
    this.email.emit('');
  }

  setUpdate(doc: any): void {
    if (!doc) {
      this.mailControl.reset();
    }

    if (!doc?.email) {
      return;
    }

    const { email } = doc;
    this.mailControl.setValue(email);
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }
}
