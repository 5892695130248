import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { EMPTY, map, Observable, of } from 'rxjs';
import { filter } from 'lodash';
import { AllParticipantAccompagnementsGQL } from '../../GRAPHQL/queries/PARTICIPANT/accompagnements/query-participant-accompagnements.service';
import { ParticipantAccompagnementGQL } from '../../GRAPHQL/queries/PARTICIPANT/accompagnements/query-participant-accompagnement.service';

@Injectable({
  providedIn: 'any',
})
export class ParticipantAccompagnementService {
  GET_ACCOMPAGNEMENT_CHAMPS_TYPES = gql`
    query Referentiel_accompagnement_champs_types {
      referentiel_accompagnement_champs_types {
        code
        libelle
      }
    }
  `;

  GET_ACCOMPAGNEMENT_TYPES = gql`
    query Referentiel_accompagnement_types {
      referentiel_accompagnement_types
    }
  `;

  GET_ACCOMPAGNEMENTS = gql`
    query Accompagnements($id: ID!) {
      accompagnements(id: $id) {
        id
        champs {
          code
          libelle
        }
        type
        dateStart
        dateEnd
        duration {
          months
        }
        objectif
        isCompleted
        auteur {
          avatar
          displayName
          setting {
            color
          }
        }
      }
    }
  `;
  GET_ACCOMPAGNEMENTS_COMPLETED = gql`
    query AccompagnementsCompleted($id: ID!) {
      accompagnementsCompleted(id: $id) {
        id
        champs {
          code
          libelle
        }
        type
        dateStart
        dateEnd
        duration {
          months
        }
        objectif
        isCompleted
        auteur {
          avatar
          displayName
          fonction
          setting {
            color
          }
        }
      }
    }
  `;
  GET_ACCOMPAGNEMENTS_UNCOMPLETED = gql`
    query AccompagnementsUncompleted($id: ID!) {
      accompagnementsUncompleted(id: $id) {
        id
        champs {
          code
          libelle
        }
        type
        dateStart
        dateEnd
        duration {
          months
        }
        objectif
        isCompleted
        auteur {
          avatar
          displayName
          fonction
          setting {
            color
          }
        }
      }
    }
  `;

  GET_ACCOMPAGNEMENT = gql`
    query Accompagnement($participantId: ID!, $id: ID!) {
      accompagnement(participantId: $participantId, id: $id) {
        id
        champs {
          code
          libelle
        }
        type
        dateStart
        dateEnd
        duration {
          months
        }
        objectif
        isCompleted
        auteur {
          avatar
          displayName
          fonction
          setting {
            color
          }
        }
      }
    }
  `;

  CREATE_ACCOMPAGNEMENT = gql`
    mutation CreateAccompagnement(
      $id: ID!
      $accompagnementInput: AccompagnementInput!
    ) {
      createAccompagnement(id: $id, accompagnementInput: $accompagnementInput) {
        id
        champs {
          code
          libelle
        }
        type
        dateStart
        dateEnd
        duration {
          months
        }
        objectif
        isCompleted
        auteur {
          avatar
          displayName
          fonction
          setting {
            color
          }
        }
      }
    }
  `;

  UPDATE_ACCOMPAGNEMENT = gql`
    mutation UpdateAccompagnement(
      $id: ID!
      $accompagnementInput: AccompagnementUpdateInput!
    ) {
      updateAccompagnement(id: $id, accompagnementInput: $accompagnementInput) {
        id
        champs {
          code
          libelle
        }
        type
        dateStart
        dateEnd
        duration {
          months
        }
        objectif
        isCompleted
        auteur {
          avatar
          displayName
          fonction
          setting {
            color
          }
        }
      }
    }
  `;

  COPY_ACCOMPAGNEMENT = gql`
    mutation CopyAccompagnement($participantId: ID!, $id: ID!) {
      copyAccompagnement(participantId: $participantId, id: $id) {
        id
        champs {
          code
          libelle
        }
        type
        dateStart
        dateEnd
        duration {
          months
        }
        objectif
        isCompleted
        auteur {
          avatar
          displayName
          fonction
          setting {
            color
          }
        }
      }
    }
  `;

  DELETE_ACCOMPAGNEMENT = gql`
    mutation DeleteAccompagnement($participantId: ID!, $id: ID!) {
      deleteAccompagnement(participantId: $participantId, id: $id)
    }
  `;

  DELETE_ACCOMPAGNEMENTS = gql`
    mutation DeleteAccompagnements($participantId: ID!) {
      deleteAccompagnements(participantId: $participantId)
    }
  `;

  constructor(
    private apollo: Apollo,
    private allParticipantAccompagnementsGQL: AllParticipantAccompagnementsGQL,
    private participantAccompagnementGQL: ParticipantAccompagnementGQL
  ) {}

  getAccompagnementTypes(): Observable<any> {
    try {
      return this.apollo
        .watchQuery<any>({
          query: this.GET_ACCOMPAGNEMENT_TYPES,
        })
        .valueChanges.pipe(
          map(({ data, loading, error }) => {
            return { data, loading, error };
          })
        );
    } catch (error) {
      return of(error);
    }
  }
  getAccompagnementChampTypes(): Observable<any> {
    try {
      return this.apollo
        .watchQuery<any>({
          query: this.GET_ACCOMPAGNEMENT_CHAMPS_TYPES,
        })
        .valueChanges.pipe(
          map(({ data, loading, error }) => {
            return { data, loading, error };
          })
        );
    } catch (error) {
      return of(error);
    }
  }

  getAccompagnements(id: string): Observable<any> {
    try {
      if (!id) return EMPTY;
      return this.allParticipantAccompagnementsGQL
        .watch({
          id,
        })
        .valueChanges.pipe(
          map(({ data, loading, error }) => {
            return { data, loading, error };
          })
        );
    } catch (error) {
      return of(error);
    }
  }
  getAccompagnement(participantId: string, id: string): Observable<any> {
    try {
      return this.participantAccompagnementGQL
        .watch({
          participantId,
          id,
        })
        .valueChanges.pipe(
          map(({ data, loading, error }) => {
            return { data, loading, error };
          })
        );
    } catch (error) {
      return of(error);
    }
  }

  createAccompagnement(id: string, accompagnementInput: any): Observable<any> {
    try {
      return this.apollo.mutate<any>({
        mutation: this.CREATE_ACCOMPAGNEMENT,
        variables: { id, accompagnementInput },
        update: (cache, { data: { createAccompagnement } }) => {
          const data: any = cache.readQuery({
            query: this.allParticipantAccompagnementsGQL.document,
            variables: { id },
          });
          const accompagnements = [
            ...data.accompagnements,
            createAccompagnement,
          ];
          cache.writeQuery({
            query: this.allParticipantAccompagnementsGQL.document,
            variables: { id },
            data: { accompagnements },
          });
        },
      });
    } catch (error) {
      return of(error);
    }
  }

  updateAccompagnement(id: string, accompagnementInput: any): Observable<any> {
    try {
      return this.apollo.mutate<any>({
        mutation: this.UPDATE_ACCOMPAGNEMENT,
        variables: { id, accompagnementInput },
      });
    } catch (error) {
      return of(error);
    }
  }

  copyAccompagnement(participantId: string, id: string): Observable<any> {
    try {
      return this.apollo.mutate<any>({
        mutation: this.COPY_ACCOMPAGNEMENT,
        variables: { participantId, id },
      });
    } catch (error) {
      return of(error);
    }
  }

  deleteAccompagnement(participantId: string, id: string): Observable<any> {
    try {
      return this.apollo.mutate<any>({
        mutation: this.DELETE_ACCOMPAGNEMENT,
        variables: { participantId, id },
        update: (cache, { data: { deleteAccompagnement } }) => {
          const { accompagnements }: any = cache.readQuery({
            query: this.allParticipantAccompagnementsGQL.document,
            variables: { id: participantId },
          });

          cache.writeQuery({
            query: this.allParticipantAccompagnementsGQL.document,
            variables: { id: participantId },
            data: {
              accompagnements: filter(
                accompagnements,
                (doc) => doc.id !== deleteAccompagnement.id
              ),
            },
          });
        },
      });
    } catch (error) {
      return of(error);
    }
  }
  deleteAccompagnements(participantId: string): Observable<any> {
    try {
      return this.apollo.mutate<any>({
        mutation: this.DELETE_ACCOMPAGNEMENTS,
        variables: { participantId },
        update: (cache, { data: { deleteAccompagnements } }) => {
          cache.writeQuery({
            query: this.allParticipantAccompagnementsGQL.document,
            variables: { id: participantId },
            data: { accompagnements: [] },
          });
        },
      });
    } catch (error) {
      return of(error);
    }
  }
}
