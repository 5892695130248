import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, of } from 'rxjs';
import { MissionState } from '../components/missions/store/mission.reducer';
import * as fromMissionSelector from '../components/missions/store/mission.selectors';
import * as fromMissionAction from '../components/missions/store/mission.actions';

@Injectable({
  providedIn: 'any',
})
export class MissionsResolverService {
  constructor(
    private missionStore: Store<MissionState>,
    private router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any[] | any> | any {
    let find = this.loadMissions().toPromise();

    return find
      .then(() => {
        return this.getMissions();
      })
      .catch(() => {
        this.router.navigate(['/missions']);
        return EMPTY;
      });
  }

  getMissions(): Observable<any[] | any> {
    return EMPTY;
    // return this.missionStore.select(fromMissionAction.missions);
  }

  loadMissions(): Observable<any> {
    return EMPTY;

    // return of(
    //   this.missionStore.dispatch(fromMissionSelector.loadMissions())
    // );
  }
}
