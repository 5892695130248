<nz-row nzAlign="top" nzJustify="center" nzGutter="4">
  <nz-col nzSpan="15">
    <div class="content" *ngIf="!(editing$ | async)">
      <nz-card
        nzSize="small"
        [nzTitle]="ItemTitle"
        nzBorderless
        *ngIf="
          editingListItemShow$ | async as editingListItemShow;
          else noLivrable
        "
      >
        <ng-template #ItemTitle>
          <h5 nz-typography>{{ editingListItemShow?.title }}</h5>
        </ng-template>

        <cdk-virtual-scroll-viewport appendOnly itemSize="50" class="viewport">
          <nz-list
            [nzBordered]="false"
            nzSize="small"
            [nzHeader]="intervenantTile"
          >
            <ng-template #intervenantTile>
              <app-item-header
                [title]="'Sujets'"
                [source]="editingListItemShow?.sujets"
              ></app-item-header>
            </ng-template>
            <nz-list-item
              *cdkVirtualFor="let item of editingListItemShow?.sujets"
            >
              <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
                <nz-col nzSpan="2">
                  <mat-icon class="iconSujetItem">notes</mat-icon>
                </nz-col>
                <nz-col nzSpan="22">
                  <span>{{ item?.title }}</span>
                </nz-col>
              </nz-row>
            </nz-list-item>
          </nz-list>
        </cdk-virtual-scroll-viewport>
      </nz-card>
    </div>
  </nz-col>

  <nz-col nzSpan="1">
    <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
  </nz-col>
  <nz-col nzSpan="8">
    <nz-list [nzHeader]="planHeader" [nzBordered]="false" nzSize="small">
      <ng-template #planHeader>
        <nz-row nzJustify="start" nzAlign="middle" nzGutter="4">
          <nz-col nzSpan="12">
            <button
              nz-button
              (click)="onNew(cadrage)"
              nz-tooltip
              nzBlock
              [nzTooltipMouseEnterDelay]="0.5"
              nzTooltipPlacement="bottom"
              nzTooltipTitle="Nouveau plan d'action"
              class="btnPrimary"
              nzType="text"
            >
              <p nz-typography>Nouveau</p>
            </button>
          </nz-col>

          <nz-col nzSpan="12">
            <button
              nz-button
              nz-tooltip
              nzBlock
              [nzTooltipMouseEnterDelay]="0.5"
              nzTooltipPlacement="bottom"
              nzTooltipTitle="Supprimler tous les plans d'actions"
              nz-popconfirm
              nzPopconfirmTitle="Voulez-vous supprimer tous les plans d'actions ?"
              nzPopconfirmPlacement="bottom"
              (nzOnConfirm)="onDeleteAllList(cadrage)"
              (nzOnCancel)="onCancel()"
              nzType="text"
            >
              <p nz-typography>Supprimer</p>
            </button>
          </nz-col>
        </nz-row>
      </ng-template>
      <cdk-virtual-scroll-viewport appendOnly itemSize="75" class="viewport">
        <ng-container>
          <nz-list-item
            *cdkVirtualFor="let item of cadrage?.plans"
            (click)="$event.stopPropagation(); editingListItemShow$.next(item)"
          >
            <nz-row nzAlign="middle" nzJustify="center" nzGutter="4">
              <nz-col nzSpan="20">
                <span>{{ item?.title | carLimitation: 80 }}</span>
                <nz-row nzAlign="middle" nzGutter="2" nzJustify="start">
                  <nz-col nzSpan="6">
                    <mat-icon class="iconSujetItem">notes</mat-icon>
                  </nz-col>
                  <nz-col nzSpan="6">
                    <nz-badge
                      [nzCount]="item?.sujets | size"
                      [nzOverflowCount]="5"
                    >
                    </nz-badge>
                  </nz-col>
                  <nz-col nzSpan="12"> </nz-col>
                </nz-row>
              </nz-col>
              <nz-col nzSpan="4">
                <button
                  nzShape="circle"
                  nz-button
                  nz-dropdown
                  [nzDropdownMenu]="menu"
                  nzTrigger="click"
                  (click)="$event.stopPropagation()"
                >
                  <i nz-icon nzType="more"></i>
                </button>
              </nz-col>
            </nz-row>

            <nz-dropdown-menu #menu="nzDropdownMenu">
              <ul nz-menu>
                <li nz-menu-item (click)="onNew(cadrage, item)">
                  <i nz-icon nzType="edit"></i>
                  <span class="dropdowMenuSpan">Modifier</span>
                </li>
                <li nz-menu-divider></li>
                <li
                  nz-menu-item
                  nzDanger
                  nz-popconfirm
                  nzPopconfirmTitle="Voulez-vous supprimer ce plan d'action ?"
                  nzPopconfirmPlacement="bottom"
                  (nzOnConfirm)="onDeleteItem(item, cadrage)"
                  (nzOnCancel)="onCancel()"
                >
                  <i nz-icon nzType="delete"></i>
                  <span class="dropdowMenuSpan">Supprimer</span>
                </li>
              </ul>
            </nz-dropdown-menu>
          </nz-list-item>
        </ng-container>
      </cdk-virtual-scroll-viewport>
    </nz-list>
  </nz-col>
</nz-row>

<ng-template #noLivrable>
  <div class="containerEmpty">
    <div>
      <mat-icon class="iconEmpty">app_registration</mat-icon>
      <h4 nz-typography class="contentEmpty">
        Aucun plan d'action sélectionné
      </h4>
    </div>
  </div>
</ng-template>
