import { ParticipantId } from 'src/app/components/participant/store/participant.model';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-participant-freins',
  templateUrl: './participant-freins.component.html',
  styleUrls: ['./participant-freins.component.scss'],
})
export class ParticipantFreinsComponent implements OnInit {
  @Input() participant: ParticipantId | any = null;
  constructor() {}

  ngOnInit(): void {}
}
