<nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
  <nz-col nzSpan="6">
    <form nz-form [formGroup]="participantForm" nzLayout="vertical">
      <nz-form-item formGroupName="module">
        <nz-form-control>
          <input
            nz-input
            nzBorderless
            placeholder="Lots"
            formControlName="title"
          />
        </nz-form-control>
      </nz-form-item>
    </form>
  </nz-col>
  <nz-col nzSpan="6"> </nz-col>
  <nz-col nzSpan="8"> </nz-col>
  <nz-col nzSpan="4">
    <nz-button-group>
      <button
        nz-button
        nzBlock
        nzType="text"
        (click)="$event.stopPropagation(); onClear()"
      >
        Effacer
      </button>
      <button
        nz-button
        nzBlock
        nzType="primary"
        (click)="$event.stopPropagation(); onAdd()"
      >
        Ajouter
      </button>
    </nz-button-group>
  </nz-col>
</nz-row>
