import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';

@Component({
  selector: 'app-no-result',
  template: `
    <!-- <div class="hero min-h-screen bg-base-200">
  <div class="hero-content text-center">
  <div class="max-w-md">
  <h1 class="text-5xl font-bold" *ngIf="!isButton">
  <mat-icon class="contentEmpty iconFirst">{{ icon }}</mat-icon>
  </h1>
  <p class="py-6">{{ notitle }}
</p>
<p class="py-6">{{ description }}
</p>
<button class="btn btn-primary gap-4" (click)="onNew()"> <mat-icon>{{ icon }}</mat-icon> {{ buttonTitle }}</button>
</div>

</div>
</div> -->
    <div class="w-full h-full p-8 space-y-4">
      <div *ngIf="!isButton" class="center">
        <mat-icon class="contentEmpty iconFirst">{{ icon }}</mat-icon>
      </div>

      <p nz-typography class="contentEmpty center notitle tracking-wider">
        {{ notitle }}
      </p>
      <div class="center">
        <p nz-typography class="contentEmpty nodefinition tracking-wider">
          {{ description }}
        </p>
      </div>
      <div class="center_btn" *ngIf="isButton">
        <button class="btn btn-primary gap-x-2" (click)="onNew()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 4.5v15m7.5-7.5h-15"
            />
          </svg>

          {{ buttonTitle }}
        </button>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        justify-items: center;
        height: 100%;
        background-color: 'transparent';
      }
      nz-card {
        background-color: 'transparent';
      }
      .center {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        justify-items: center;
      }

      .center_btn {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        justify-items: center;
        width: 100%;
      }

      button {
        display: flex;
        flex-direction: column nowrap;
        justify-content: center;
        justify-items: center;
        width: 10em;

        nz-row {
          margin-top: -2px;

          nz-col {
            display: flex;
            flex-direction: column nowrap;
            justify-content: center;
            justify-items: center;
          }
        }
      }

      .btnPrimary {
        border-radius: 12px;
        background-color: #f5f5f5;

        h4,
        mat-icon {
          color: #2f54eb;
        }
      }

      mat-icon {
        font-size: 30px;
      }

      .notitle {
        font-size: 25px;
        text-align: center;
      }

      .iconFirst {
        color: #f5f5f5;
        margin-bottom: 20px;
      }
      .nodefinition {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        justify-items: center;
        width: 400px;
        font-size: 18px;
        text-align: center;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoResultComponent implements OnInit {
  @Input() notitle: string = '';
  @Input() addtitle: string = '';
  @Input() icon: string = '';
  @Input() description: string = '';
  @Input() isButton: boolean = true;
  @Input() buttonTitle: string = 'Ajouter';
  @Output() new = new EventEmitter<boolean>(false);
  constructor() {}

  ngOnInit(): void {}

  onNew(): void {
    this.new.emit(true);
  }
}
