<nz-card [nzTitle]="diagnosticCardTitle">
  <form nz-form [formGroup]="aptitudeForm">
    <nz-form-item>
      <nz-form-label nzSpan="24">Compétences transverses</nz-form-label>
      <nz-form-control>
        <nz-select
          nzBorderless
          nzPlaceHolder="Softskills"
          nzMode="tags"
          formControlName="softskills"
          [nzLoading]="!((softkills$ | async)?.skills | size)"
          [nzMaxTagCount]="6"
        >
          <nz-option
            *ngFor="let item of (softkills$ | async)?.skills"
            [nzValue]="item"
            [nzLabel]="item?.summary"
          ></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-card>

<ng-template #diagnosticCardTitle>
  <app-header-title [icon]="'psychology'"></app-header-title>
</ng-template>
