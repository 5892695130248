import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { ParticipantSuivi } from './participant-suivi.model';

//PARTICIPANT LOAD SUIVIS
export const loadParticipantSuivis = createAction(
  '[ParticipantSuivi/API] Load ParticipantSuivis',
  props<{ id: string }>()
);
export const loadParticipantSuivisSuccess = createAction(
  '[ParticipantSuivi/API] Load ParticipantSuivis Success',
  props<{ suivis: any[] }>()
);
export const loadParticipantSuivisFailure = createAction(
  '[ParticipantSuivi/API] Load ParticipantSuivis Failre',
  props<{ error: any }>()
);

//PARTICIPANT LOAD SUIVI
export const loadParticipantSuivi = createAction(
  '[ParticipantSuivi/API] Load ParticipantSuivi',
  props<{ participantId: string; id: string }>()
);
export const loadParticipantSuiviSuccess = createAction(
  '[ParticipantSuivi/API] Load ParticipantSuivi Success',
  props<{ suivi: any }>()
);
export const loadParticipantSuiviFailure = createAction(
  '[ParticipantSuivi/API] Load ParticipantSuivi Failre',
  props<{ error: any }>()
);

//PARTICIPANT CREATE SUIVI
export const addParticipantSuivi = createAction(
  '[ParticipantSuivi/API] Add ParticipantSuivi',
  props<{ id: string; suivi: any }>()
);
export const addParticipantSuiviSuccess = createAction(
  '[ParticipantSuivi/API] Add ParticipantSuivi Success',
  props<{ suivi: any }>()
);
export const addParticipantSuiviFailure = createAction(
  '[ParticipantSuivi/API] Add ParticipantSuivi Failure',
  props<{ error: any }>()
);

//PARTICIPANT UPDATE SUIVI
export const updateParticipantSuivi = createAction(
  '[ParticipantSuivi/API] Update ParticipantSuivi',
  props<{ id: string; suivi: Update<any> }>()
);
export const updateParticipantSuiviSuccess = createAction(
  '[ParticipantSuivi/API] Update ParticipantSuivi Success',
  props<{ suivi: any }>()
);
export const updateParticipantSuiviFailure = createAction(
  '[ParticipantSuivi/API] Update ParticipantSuivi Failure',
  props<{ error: any }>()
);

//PARTICIPANT DELETE SUIVI
export const deleteParticipantSuivi = createAction(
  '[ParticipantSuivi/API] Delete ParticipantSuivi',
  props<{ participantId: string; id: string }>()
);
export const deleteParticipantSuiviSuccess = createAction(
  '[ParticipantSuivi/API] Delete ParticipantSuivi Success',
  props<{ success: string }>()
);
export const deleteParticipantSuiviFailure = createAction(
  '[ParticipantSuivi/API] Delete ParticipantSuivi Failure',
  props<{ error: any }>()
);

//PARTICIPANT DELETE SUIVIS
export const deleteParticipantSuivis = createAction(
  '[ParticipantSuivi/API] Delete ParticipantSuivis',
  props<{ id: string; ids: string[] }>()
);
export const deleteParticipantSuivisSuccess = createAction(
  '[ParticipantSuivi/API] Delete ParticipantSuivis Success',
  props<{ success: string }>()
);
export const deleteParticipantSuivisFailure = createAction(
  '[ParticipantSuivi/API] Delete ParticipantSuivis Failure',
  props<{ error: any }>()
);

//PARTICIPANT CLEAR SUIVI
export const clearParticipantSuivis = createAction(
  '[ParticipantSuivi/API] Clear ParticipantSuivis'
);
