<ng-container *ngIf="user$ | async as user">
  <ng-container *ngIf="operation$ | async as operation">
    <ng-container *ngIf="cadrage$ | async as cadrage; else noData">
      <app-operation-cadrage-header
        [cadrage]="cadrage"
        [operation]="operation"
        [title]="itemCurrent$ | async"
      ></app-operation-cadrage-header>

      <nz-row nzAlign="top" nzJustify="center" nzGutter="4">
        <nz-col nzSpan="24">
          <app-operation-cadrage-description
            [operation]="operation"
            (update)="onValidCadrage($event, operation)"
          ></app-operation-cadrage-description>
        </nz-col>
      </nz-row>
      <nz-space></nz-space>
      <nz-row nzAlign="top" nzJustify="center" nzGutter="4">
        <nz-col nzSpan="24">
          <nz-card
            [nzTitle]="operationCadrage__contextTitle"
            class="operationCadrage__content"
          >
            <ng-template #operationCadrage__contextTitle>
              <app-header-title
                [icon]="'subject'"
                [title]="'Contexte'"
              ></app-header-title>
            </ng-template>
            <app-operation-cadrage-simple
              [type]="'context'"
              [operation]="operation"
              (update)="onValidCadrage($event, operation)"
            >
            </app-operation-cadrage-simple>
          </nz-card>
        </nz-col>
      </nz-row>

      <nz-dropdown-menu #selectionMenu="nzDropdownMenu">
        <ul nz-menu>
          <li
            nz-menu-item
            *ngFor="let item of items$ | async"
            (click)="onTagSelect(item)"
          >
            {{ item }}
          </li>
        </ul>
      </nz-dropdown-menu>

      <nz-dropdown-menu #menuDowload="nzDropdownMenu">
        <ul nz-menu>
          <li
            nz-menu-item
            *ngFor="let item of exportList"
            [nzDisabled]="item.disabled"
            (click)="onExport(operation, cadrage, item.name)"
          >
            <i nz-icon [nzType]="item.icon"></i>
            {{ item.name }}
          </li>
        </ul>
      </nz-dropdown-menu>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #noData>
  <nz-empty nzNotFoundImage="simple"></nz-empty>
</ng-template>
