<ng-container *ngIf="!(loading$ | async); else loadingList">
  <ng-container *ngIf="list | size; else noDataList">
    <nz-page-header
      *ngIf="isHeader"
      [nzBackIcon]="isBack ? layoutListContainerBackIconTpl : ''"
      [style.height]="headerHeight"
      (nzBack)="onBack($event)"
      [ngClass]="{ 'infinite-container-border': isBorder }"
    >
      <ng-template #layoutListContainerBackIconTpl>
        <i nz-icon nzType="arrow-left"></i>
      </ng-template>

      <nz-page-header-title>
        <ng-container *ngTemplateOutlet="title"></ng-container>
      </nz-page-header-title>
      <nz-page-header-subtitle>
        <ng-container *ngTemplateOutlet="subtitle"></ng-container>
      </nz-page-header-subtitle>
      <nz-page-header-extra>
        <ng-container *ngTemplateOutlet="extra"></ng-container>
      </nz-page-header-extra>
      <nz-page-header-content>
        <ng-container *ngTemplateOutlet="headerContent"></ng-container>
      </nz-page-header-content>
    </nz-page-header>

    <cdk-virtual-scroll-viewport
      [itemSize]="itemSize"
      class="infinite-container"
      [style.height]="height"
    >
      <nz-list>
        <nz-list-item
          [@listAnimation]
          *cdkVirtualFor="let item of list; index as i; trackBy: trackByFn"
        >
          <ng-container
            *ngTemplateOutlet="card; context: { $implicit: item, idx: i }"
          >
          </ng-container>
        </nz-list-item>
      </nz-list>
    </cdk-virtual-scroll-viewport>
  </ng-container>

  <ng-template #noDataList>
    <app-no-result
      [icon]="noDataIcon"
      [addtitle]="newAddTitle"
      [notitle]="newNoDataTitle"
      [description]="newDescription"
      [isButton]="isNew"
      (new)="onNew()"
    ></app-no-result>
  </ng-template>
</ng-container>
<ng-template #loadingList>
  <i nz-icon nzType="loading"></i>
</ng-template>
