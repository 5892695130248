import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';
import { UtilitiesState } from 'src/app/contents/components/utilities/store/utilities.reducer';
import * as fromUtilitiesSelector from 'src/app/contents/components/utilities/store/utilities.selectors';
import * as fromUtilitiesAction from 'src/app/contents/components/utilities/store/utilities.actions';
import { Timestamp } from 'firebase/firestore';

@Component({
  selector: 'app-participant-diagnostic-form-disponibility',
  templateUrl: './participant-diagnostic-form-disponibility.component.html',
  styleUrls: ['./participant-diagnostic-form-disponibility.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantDiagnosticFormDisponibilityComponent
  implements OnInit, OnChanges, OnDestroy
{
  subscribe = new Subject();
  dateFormat: string = 'HH:mm';
  today: Date = new Date(Date.now());

  //VARIABLES
  isFreinTitle: string = 'La disponibilité est-elle un frein identifié ?';
  disponibiliteFaibleVoireDifficileTitle: string =
    'Ne sait pas se rendre seul à son lieu de travail';
  disponibiliteAorganiserTitle: string = 'A organiser';
  disponibiliteImmediatementAvecTitle: string =
    'Immédiate avec des contraintes horaires';
  disponibiliteImmediatementSansTitle: string =
    'Immédiate et sans contraintes horaires';

  //FORM FIELD
  disponibiliteForm: FormGroup = this.fb.group({});
  disponibiliteFaibleVoireDifficileLibelle: FormControl<string | any> =
    new FormControl(
      'Faible voire difficile (garde d’enfant, ascendant, famille)'
    );
  disponibiliteAorganiserLibelle: FormControl<string | any> = new FormControl(
    'A organiser'
  );
  disponibiliteImmediatementAvecLibelle: FormControl<string | any> =
    new FormControl('Immédiate avec des contraintes horaires');
  disponibiliteImmediatementSansLibelle: FormControl<string | any> =
    new FormControl('Immédiate et sans contraintes horaires');
  disponibiliteFaibleVoireDifficile: FormControl<boolean | any> =
    new FormControl(false, Validators.required);
  disponibiliteAorganiser: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );
  disponibiliteImmediatementAvec: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );
  disponibiliteImmediatementSans: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );
  precision: FormControl<string | any> = new FormControl('');

  isFrein: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );

  @Input() disponibleInput$ = new BehaviorSubject<any>(null);
  @Output() add = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private utilitiesStore: Store<UtilitiesState>
  ) {}

  ngOnInit(): void {
    this.form();
    this.onFormChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  form(): void {
    this.formInit();
    this.formCreate();
    this.formSet();
  }

  formInit(): void {
    this.disponibiliteFaibleVoireDifficileLibelle = new FormControl(
      'Faible voire difficile (garde d’enfant, ascendant, famille)',
      Validators.required
    );
    this.disponibiliteAorganiserLibelle = new FormControl(
      'A organiser',
      Validators.required
    );
    this.disponibiliteImmediatementAvecLibelle = new FormControl(
      'Immédiate avec des contraintes horaires',
      Validators.required
    );
    this.disponibiliteImmediatementSansLibelle = new FormControl(
      'Immédiate et sans contraintes horaires',
      Validators.required
    );
    this.disponibiliteFaibleVoireDifficile = new FormControl(
      false,
      Validators.required
    );
    this.disponibiliteAorganiser = new FormControl(false, Validators.required);
    this.disponibiliteImmediatementAvec = new FormControl(
      false,
      Validators.required
    );
    this.disponibiliteImmediatementSans = new FormControl(
      false,
      Validators.required
    );
    this.precision = new FormControl('');
    this.isFrein = new FormControl<boolean>(false, Validators.required);
  }

  formCreate(): void {
    this.disponibiliteForm = this.fb.group({
      disponibiliteFaibleVoireDifficileLibelle:
        this.disponibiliteFaibleVoireDifficileLibelle,
      disponibiliteAorganiserLibelle: this.disponibiliteAorganiserLibelle,
      disponibiliteImmediatementAvecLibelle:
        this.disponibiliteImmediatementAvecLibelle,
      disponibiliteImmediatementSansLibelle:
        this.disponibiliteImmediatementSansLibelle,
      disponibiliteFaibleVoireDifficile: this.disponibiliteFaibleVoireDifficile,
      disponibiliteAorganiser: this.disponibiliteAorganiser,
      disponibiliteImmediatementAvec: this.disponibiliteImmediatementAvec,
      disponibiliteImmediatementSans: this.disponibiliteImmediatementSans,
      precision: this.precision,
      isFrein: this.isFrein,
    });
  }

  formSet(): void {
    this.disponibleInput$
      .pipe(takeUntil(this.subscribe))
      .subscribe((values) => {
        if (!values) return;
        this.disponibiliteForm.patchValue({ ...values });
      });
  }

  checkboxChanges(event: boolean, type: string): void {
    switch (type) {
      case 'disponibiliteFaibleVoireDifficile':
        this.disponibiliteForm.patchValue({
          disponibiliteFaibleVoireDifficile: event,
        });

        break;
      case 'disponibiliteAorganiser':
        this.disponibiliteForm.patchValue({
          disponibiliteAorganiser: event,
        });

        break;
      case 'disponibiliteImmediatementAvec':
        this.disponibiliteForm.patchValue({
          disponibiliteImmediatementAvec: event,
        });

        break;
      case 'disponibiliteImmediatementSans':
        this.disponibiliteForm.patchValue({
          disponibiliteImmediatementSans: event,
        });

        break;

      default:
        break;
    }
  }

  onFormChanges(): void {
    this.disponibiliteForm.valueChanges
      .pipe(takeUntil(this.subscribe))
      .subscribe((values) => {
        this.add.emit(values);
      });
  }
}
