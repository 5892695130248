import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'operationAdmin',
})
export class OperationAdminPipe implements PipeTransform {
  transform(values: string[], arg: string): boolean {
    if (!values.length) {
      return false;
    }

    if (values.find((id) => id === arg)) {
      return true;
    }

    return false;
  }
}
