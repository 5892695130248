<div [ngSwitch]="type">
  <div *ngSwitchCase="'professionnelle'">
    <h1>PROFESSIONNELLE</h1>
  </div>
  <div *ngSwitchCase="'sociale'">
    <h1>SOCIALE</h1>
  </div>

  <div *ngSwitchDefault></div>
</div>
