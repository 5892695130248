import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'any',
})
export class QueryGeoDistanceServiceGQL extends Query<Response> {
  document = gql`
    query GeoDistance($adresses: GeoDistanceCoordonneesInput!) {
      geoDistance(adresses: $adresses) {
        distanceKilometer
        distanceMeter
        distanceText
      }
    }
  `;
}
