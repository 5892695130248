<cdk-virtual-scroll-viewport itemSize="60" class="listContainer">
  <nz-list-item
    *cdkVirtualFor="let item of items"
    (click)="$event.stopPropagation(); onSelect(item)"
  >
    {{ item.code }} - {{ item.nom }}

    <nz-list-item-action *ngIf="isAction">
      <mat-icon>navigate_next</mat-icon>
    </nz-list-item-action>
  </nz-list-item>
</cdk-virtual-scroll-viewport>
