import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
  Input,
} from '@angular/core';

@Component({
  selector: 'app-entite-search-card',
  templateUrl: './entite-search-card.component.html',
  styleUrls: ['./entite-search-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntiteSearchCardComponent implements OnInit, OnChanges {
  @Input() etablissement: any = null;

  constructor() {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {}
}
