import { number } from 'echarts';
import { Coordonnees } from 'src/app/contents/components/coordonnees/coordonnees.model';
import { Adresse } from 'src/app/models/adresse.model';

export interface EntiteFavori {
  id: string;
  nom_raison_sociale: string;
  dateUpdate: any | Date;
  type: string;
}

export interface Entite {
  custom: {
    auths: any;
    stats: any;
    logo: string;
    fileRef: string;
    antenne: AntenneEntite[];
    effectifSalarier?: number;
    type: string; // structure || entitePublic || entreprise
    statut: string;
    member: boolean;
    coordonnees: Coordonnees;
    categorie: string;
    adherent?: boolean;
    adhesion: {
      id: string;
      statut: boolean;
      dataStart: Date | any;
      montant?: Number;
      reception: boolean;
      paiement: boolean;
      mode?: string;
      year?: string;
    };
    secteur: string;
    tags: any[];
    axeDeTravail: AxeDeTravail[];
    dateStart: any;
    dateUpdate: any;
    dateSelected: any;
    metiersThemes?: any;
    metiersDomaine?: any;
    metiersGrandDomaine?: any;
    metiers?: any;
    metiersMap?: any;
    metiersCompetencesDeBase?: any;
    metiersCompetencesDeBaseMap?: any;
    metiersCompetencesDeBaseCleMap?: any;
    metiersCompetencesDeSpeficique?: any;
    metiersCompetencesDeSpeficiqueMap?: any;
    metiersCompetencesDeSpeficiqueCleMap?: any;
    metiersEnvironnement?: any;
    metiersEnvironnementMap?: any;
    metiersSoftskills?: any;
    metiersSoftskillsMap?: any;
    metiersAppelations?: any;
    metiersAppelationsMap?: any;

    services: string[] | any;
    servicesMap?: any;
    operations: string[];
    operationsId?: string[];
    contacts: any[];
    contactsIds?: string[];
    contactsMapIds?: any;

    modules: string[];
    modulesIds?: string[];
    modulesMapIds?: any;
    actions?: string[];
    actionsIds?: string[];
    actionsMapIds?: any;

    participants: any[];
    participantsIds?: any[];
    participantsMapIds?: any;

    notes: any[];
    origine: string;
    organisation: any;
    engagement: string;
    auteur: any;
    userSelected: any;
    userUpdate: any;
    actif: boolean;
    ess: boolean;
    prescripteur: boolean;
    groups: string[];
    subscriptions: any[];
  };
  etablissement: Etablissement;
  organisation: any;
  metadata?: any;
}

export interface Etablissement {
  id: number;
  numero_tva_intra: string;
  siren: string;
  denomination: string;
  categorie_juridique: string;
  categorie_juridique_libelle: {
    libelle: string;
    code: string;
  };
  categorie_entreprise: string;
  activite_principale: string;
  nomenclature_activite_principale: string;
  nic_siege: string;
  economie_sociale_solidaire: string;
  activite_principale_libelle: {
    intitule_naf: string;
    intitule_naf_40: string;
    intitule_naf_65: string;
    code_naf: string;
  };
  etat_administratif: string;
  denomination_usuelle_1: string;
  etablissement_siege: EtablissementSierge;
}

export interface EtablissementSierge {
  nic: string;
  siret: string;
  etablissement_siege: string;
  type_voie: string;
  libelle_voie: string;
  code_postal: string;
  libelle_commune: string;
  code_commune: string;
  longitude: string;
  latitude: string;
  geo_adresse: string;
  geo_type: string;
  geo_score: string;
  geo_ligne: string;
  geo_l4: string;
  geo_l5: string;
  unite_legale_id: number;
  geo_id: string;
}

export interface EtablissementSmall {
  id: number;
  numero_tva_intra: string;
  siren: string;
  denomination: string;
  categorie_juridique_libelle: {
    libelle: string;
    code: string;
  };
  categorie_entreprise: string;
  economie_sociale_solidaire: string;
  activite_principale_libelle: {
    intitule_naf: string;
    code_naf: string;
  };
  denomination_usuelle_1: string;
  etablissement_siege: {
    nic: string;
    siret: string;
    etablissement_siege: string;
    code_postal: string;
    libelle_commune: string;
    code_commune: string;
    longitude: string;
    latitude: string;
    geo_adresse: string;
    geo_type: string;
    geo_score: string;
    geo_ligne: string;
  };
}

export interface EntiteId extends Entite {
  id: string;
}

export interface EntiteSMALL {
  etablissement: EtablissementSmall;
  actif?: boolean;
  type: string;
  secteur: string;
  adherent?: boolean;
  coordonnes: Coordonnees;
  organisationId: string;
  statut?: string;
  organisationRaisonSociale: string;
  metadata: any;
  prescripteur?: boolean;
  metiersThemes?: any;
  metiersDomaine?: any;
  metiersGrandDomaine?: any;
  metiers: any;
  metiersMap?: any;
  metiersCompetencesDeBase?: any;
  metiersCompetencesDeBaseMap?: any;
  metiersCompetencesDeBaseCleMap?: any;
  metiersCompetencesDeSpeficique?: any;
  metiersCompetencesDeSpeficiqueMap?: any;
  metiersCompetencesDeSpeficiqueCleMap?: any;
  metiersEnvironnement?: any;
  metiersEnvironnementMap?: any;
  metiersSoftskills?: any;
  metiersSoftskillsMap?: any;
  metiersAppelations?: any;
  metiersAppelationsMap?: any;
  dateStart?: any;
  deleteAt?: any | Date;
  contactsIds?: string[];
  contactsMapIds?: any;
  operations?: any[];
  createAtYear?: string;
  createAtMonth?: string | number;
  createAtDay?: string | number;
  auteur?: any;
  services: string[] | any;
  servicesMap: any;
}

export interface EntiteSMALLid extends EntiteSMALL {
  id: string;
}

export interface AntenneEntite {
  id: string;
  displayName: string;
  adresse: Adresse;
  coordonnees: {
    tel1: string;
    tel2: string;
    web: string;
    reseaux?: any[];
  };
}

export interface Adhesion {
  dateStart: Date | any;
  dateEnd: Date | any;
  dateUpdate: Date | any;
  datePaiement: Date | any;
  dateBulletin: Date | any;
  duration: number;
  entite: {
    id: string;
    nom_raison_sociale: string;
    siret: string;
    geo_adresse: string;
    activite_principale_entreprise: string;
    secteur: string;
  };
  contact: {
    id: string;
    lastName: string;
    firstName: string;
    fonction: string;
    displayName?: string;
    coordonness: Coordonnees;
  };
  document: {
    file: string;
    path: string;
  };
  auteur: any;
  montant: Number;
  statut: string; //réglé - en attente - relance
  reception: boolean;
  paiement: boolean;
  type?: string;
  userUpdate: any;
  note?: string;
}

export interface AdhesionId extends Adhesion {
  id: string;
}

export interface TaxeApprentisage {
  id: string;
  entite: EntiteId;
  montant: number;
  dateStart: Date | any;
  statut: string;
}

export interface AxeDeTravail {
  id: string;
  categorie: string;
  item: string;
  dateStart: Date;
}

export interface EntiteBesoin {
  entite: EntiteSMALLid;
  contacts: any[];
  rendezVous: any[];
  devis: any[];
  negociations: any[];
  conclue: any[];
  type: string;
  categorie: string;
  poste: string;
  competences: string;
  adresse: Adresse;
  acces: string;
  dateStart: Date | any;
  delai: Date | any;
  note: string;
  noteHTML: string;
  auteur: any;
}

export interface EntiteBesoinId extends EntiteBesoin {
  id: string;
}

export interface EntiteContrat {
  entite: EntiteSMALLid;
  participant: any;
  operation: any;
  module: any;
  numeroContrat: string | number;
  type: string;
  categorie: string;
  poste: string;
  competences: string;
  item: string;
  motif: string;
  mission: string;
  adresse: Adresse;
  acces: string;
  justification: string;
  dureeHebdomadaire: number;
  dureeCollectiveMoyenHebdomadaire: number;
  salaireReference: number;
  caracteristiquesParticuliere: string;
  salaireDeBase: number;
  modeDePaimenet: string;
  remboursements: ContratEemboursements[];
  dateStart: Date | any;
  dateEnd: Date | any;
  auteur: any;
}

export interface ContratEemboursements {
  id: string | number;
  item: string;
  pourcent: number;
  montant: number;
}

export interface EntiteContratId extends EntiteContrat {
  id: string;
}

export enum EntiteQuery {
  raison = 'raisonSociale',
  ess = 'ess',
  domaine = 'domaine',
  grandDomaine = 'grandDomaine',
  activite = 'activite',
  commune = 'commune',
  code = 'codepostal',
  engagement = 'engagement',
  origine = 'origine',
  status = 'status',
  favoris = 'favoris',
  metier = 'metier',
}

export interface EtablissementInput {
  siren: string;
  nic: string;
  siret: string;
  statutDiffusionEtablissement: string;
  dateCreationEtablissement: string;
  trancheEffectifsEtablissement: string;
  anneeEffectifsEtablissement: string;
  activitePrincipaleRegistreMetiersEtablissement: string;
  dateDernierTraitementEtablissement: string;
  etablissementSiege: boolean;
  nombrePeriodesEtablissement: number;
  uniteLegale: UniteLegale;
  adresseEtablissement: AdresseEtablissementInput;
  adresse2Etablissement: AdresseEtablissementInput;
  periodesEtablissement: [PeriodeEtablissement];
  conventionsCollectives: ConventionCollective;
}

export interface UniteLegale {
  statutDiffusionUniteLegale: string;
  unitePurgeeUniteLegale: string;
  dateCreationUniteLegale: string;
  sigleUniteLegale: string;
  sexeUniteLegale: string;
  prenom1UniteLegale: string;
  prenom2UniteLegale: string;
  prenom3UniteLegale: string;
  prenom4UniteLegale: string;
  prenomUsuelUniteLegale: string;
  pseudonymeUniteLegale: string;
  identifiantAssociationUniteLegale: string;
  trancheEffectifsUniteLegale: string;
  anneeEffectifsUniteLegale: string;
  dateDernierTraitementUniteLegale: string;
  nombrePeriodesUniteLegale: number;
  categorieEntreprise: string;
  anneeCategorieEntreprise: string;
  etatAdministratifUniteLegale: string;
  nomUniteLegale: string;
  denominationUniteLegale: string;
  denominationUsuelle1UniteLegale: string;
  denominationUsuelle2UniteLegale: string;
  denominationUsuelle3UniteLegale: string;
  activitePrincipaleUniteLegale: string;
  categorieJuridiqueUniteLegale: string;
  nicSiegeUniteLegale: string;
  nomenclatureActivitePrincipaleUniteLegale: string;
  nomUsageUniteLegale: string;
  economieSocialeSolidaireUniteLegale: string;
  societeMissionUniteLegale: string;
  caractereEmployeurUniteLegale: string;
  activite_principale_libelle: ActivitePrincipaleLibelle;
  categorie_juridique_libelle: CategorieJuridique;
}

export interface AdresseEtablissementInput {
  complementAdresseEtablissement: string;
  numeroVoieEtablissement: string;
  indiceRepetitionEtablissement: string;
  typeVoieEtablissement: string;
  libelleVoieEtablissement: string;
  codePostalEtablissement: string;
  libelleCommuneEtablissement: string;
  libelleCommuneEtrangerEtablissement: string;
  distributionSpecialeEtablissement: string;
  codeCommuneEtablissement: string;
  codeCedexEtablissement: string;
  libelleCedexEtablissement: string;
  codePaysEtrangerEtablissement: string;
  libellePaysEtrangerEtablissement: string;
  fullAdresse: string;
  commune: CommuneInput;
}

export interface PeriodeEtablissement {
  dateFin: string;
  dateDebut: string;
  etatAdministratifEtablissement: string;
  changementEtatAdministratifEtablissement: boolean;
  enseigne1Etablissement: string;
  enseigne2Etablissement: string;
  enseigne3Etablissement: string;
  changementEnseigneEtablissement: boolean;
  denominationUsuelleEtablissement: string;
  changementDenominationUsuelleEtablissement: boolean;
  activitePrincipaleEtablissement: string;
  nomenclatureActivitePrincipaleEtablissement: string;
  changementActivitePrincipaleEtablissement: boolean;
  caractereEmployeurEtablissement: string;
  changementCaractereEmployeurEtablissement: boolean;
}

export interface ActivitePrincipaleLibelle {
  intitule_naf: string;
  intitule_naf_40: string;
  intitule_naf_65: string;
  code_naf: string;
}

export interface CategorieJuridique {
  libelle: string;
  code: string;
}

export interface CommuneInput {
  code: string;
  nom: string;
  codesPostaux: [string];
  siren: string;
  codeEpci: string;
  codeDepartement: string;
  codeRegion: string;
  epci: EpciInput;
  departement: GeoDepartementInput;
  region: RegionInput;
  population: number;
  surface: number;
  centre: any;
  contour: any;
  mairie: any;
  bbox: any;
}

export interface EpciInput {
  code: string;
  nom: string;
  type: string;
  financement: string;
  codesDepartements: string[];
  codesRegions: string[];
  population: number;
  surface: number;
  centre: any;
  contour: any;
  bbox: any;
}

export interface GeoDepartementInput {
  code: string;
  nom: string;
  codeRegion: string;
  region: RegionInput;
}

export interface RegionInput {
  code: string;
  nom: string;
}

export interface ConventionCollective {
  activitePrincipale: string;
  activitePrincipaleUniteLegale: string;
  caractereEmployeurUniteLegale: string;
  categorieJuridiqueUniteLegale: string;
  conventions: [ConventionCollectiveKali];
  dateCreationUniteLegale: string;
  dateDebut: string;
  etablissements: number;
  etatAdministratifUniteLegale: string;
  highlightLabel: string;
  label: string;
  matching: number;
  simpleLabel: string;
  siren: string;
  activitePrincipaleEtablissement: string;
  address: string;
  categorieEntreprise: string;
  codeCommuneEtablissement: string;
  codePostalEtablissement: string;
  etablissementSiege: boolean;
  etatAdministratifEtablissement: string;
  idccs: string[];
  libelleCommuneEtablissement: string;
  siret: string;
}

export interface ConventionCollectiveKali {
  idcc: number;
  etat: string;
  id: string;
  mtime: number;
  shortTitle: string;
  texte_de_base: string;
  title: string;
  url: string;
}
