import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { FicheDePoste } from './fiche-de-poste.model';
import * as FicheDePosteActions from './fiche-de-poste.actions';

export const ficheDePostesFeatureKey = 'ficheDePostes';

export interface FicheDePosteState extends EntityState<FicheDePoste> {
  loading: boolean;
  error: any;
}

export const adapter: EntityAdapter<FicheDePoste> =
  createEntityAdapter<FicheDePoste>();

export const initialState: FicheDePosteState = adapter.getInitialState({
  loading: false,
  error: undefined,
});

export const reducer = createReducer(
  initialState,

  on(FicheDePosteActions.clearFicheDePostes, (state) =>
    adapter.removeAll(state)
  )
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
