import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { serverTimestamp, Timestamp } from 'firebase/firestore';
import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';
import { OperationNoteSubtitleId } from 'src/app/components/operation-note-subtitle/store/operation-note-subtitle.model';
import {
  OperationNoteDetailId,
  OperationNoteId,
} from 'src/app/components/operation-note/store/operation-note.model';
import {
  OperationNoteSujet,
  OperationNoteSujetId,
} from '../../store/operation-note-sujet.model';
import { OperationNoteSujetState } from '../../store/operation-note-sujet.reducer';
import * as fromOperationNoteSujetAction from '../../store/operation-note-sujet.actions';
import * as fromOperationNoteSujetSelector from '../../store/operation-note-sujet.selectors';
import { OperationNoteSujetFormComponent } from '../operation-note-sujet-form/operation-note-sujet-form.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Update } from '@ngrx/entity';

@Component({
  selector: 'app-operation-note-sujet-list',
  templateUrl: './operation-note-sujet-list.component.html',
  styleUrls: ['./operation-note-sujet-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationNoteSujetListComponent
  implements OnInit, OnChanges, OnDestroy
{
  subscribe = new Subject();
  sujets$: Observable<any> = of(null);
  sujet$: Observable<any> = of(null);
  loading$: Observable<any> = of(false);

  tooltipMouseEnterDelay: number = 0.5;
  title: string = 'Nouveau sujet';
  @ViewChild('sujetFormTitle', { static: false })
  sujetFormTitle: TemplateRef<any> | any;
  modalWidth: number = 780;

  @Input() note$: Observable<OperationNoteId | any> = of(null);
  @Input() subtitle$ = new BehaviorSubject<OperationNoteId | any>(null);

  constructor(
    private operationNoteSujetStore: Store<OperationNoteSujetState>,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.getSujets();
    this.loadSujets();
    this.getLoading();
  }
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getLoading(): void {
    this.loading$ = this.operationNoteSujetStore.select(
      fromOperationNoteSujetSelector.loading
    );
  }

  getSujets(): void {
    this.sujets$ = this.operationNoteSujetStore.select(
      fromOperationNoteSujetSelector.sujets
    );
  }

  loadSujets(): void {
    this.subtitle$
      .pipe(takeUntil(this.subscribe))
      .subscribe((subtitle: OperationNoteSubtitleId) => {
        if (!subtitle) return;
        this.operationNoteSujetStore.dispatch(
          fromOperationNoteSujetAction.loadOperationNoteSujets({ subtitle })
        );
      });
  }
  trackSujet(index: any, item: { id: string }) {
    return item ? item.id : undefined;
  }

  onSelect(sujet: OperationNoteSujetId): void {}

  onCancel(): void {}

  onNew(note: OperationNoteId, subtitle: OperationNoteSubtitleId): void {
    if (!note) return;
    const modal = this.modal.create({
      nzContent: OperationNoteSujetFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.sujetFormTitle,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
    });
    const instance = modal.getContentComponent();
    instance.note$ = of(note);
    instance.subtitle$ = of(subtitle);
    instance.cancel.subscribe((event: boolean): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
    instance.add.subscribe((sujet: OperationNoteSujet): any => {
      if (!sujet) {
        return null;
      } else {
        this.onAdd(sujet, note, subtitle);
        modal.close();
      }
    });
  }

  onAdd(
    sujet: OperationNoteSujet,
    note: OperationNoteDetailId,
    subtitle: OperationNoteSubtitleId
  ): void {
    if (!sujet || !note || !subtitle) return;
    this.operationNoteSujetStore.dispatch(
      fromOperationNoteSujetAction.addOperationNoteSujet({
        note,
        sujet,
      })
    );
  }

  onUpdate(sujet: Update<OperationNoteSujetId>, note: OperationNoteId): void {
    if (!sujet || !note) return;
    this.operationNoteSujetStore.dispatch(
      fromOperationNoteSujetAction.updateOperationNoteSujet({ note, sujet })
    );
  }

  onSaveTexte(event: any, subjet: OperationNoteSujetId): void {
    const update_Sujet: OperationNoteSujetId = {
      ...subjet,
      text: event.textValue,
      textHTML: event.textHTML,
    };

    // this.store.dispatch(fromNoteActions.updateSujet({ sujet: update_Sujet }));
  }

  onDelete(sujet: OperationNoteSujetId, note: OperationNoteId): void {
    if (!sujet || !note) return;

    const { id } = sujet;
    this.operationNoteSujetStore.dispatch(
      fromOperationNoteSujetAction.deleteOperationNoteSujet({ note, id })
    );
  }

  cancelDelete(): void {
    return;
  }

  toggleFullscreen() {
    let elem: any = document.querySelector('app-text-edithing');

    if (!document.fullscreenElement) {
      elem.requestFullscreen().catch((err: any) => {
        alert(
          `Error attempting to enable full-screen mode: ${err.message} (${err.name})`
        );
      });
    } else {
      document.exitFullscreen();
    }
  }

  updateBy__SUBJET(
    value: any,
    note: OperationNoteDetailId,
    sujet: OperationNoteSujetId
  ): void {
    const editObjet = {
      subtitle: sujet.subtitle.title,
      note: note.title,
      currentSujet: sujet.title,
      editing: value.textValue,
      editingHTML: value.textHTML,
    };

    // this.store.dispatch(
    //   fromNoteActions.editNote({ id: note.id, note: editObjet })
    // );
  }

  onSelectDropdown(
    event: string,
    note: OperationNoteId,
    sujets: OperationNoteSujetId[],
    subtitle: any
  ): void {
    if (!event) return;
    switch (event) {
      case 'deleteAll':
        const subtitleId: string = subtitle.id;
        const ids: string[] = sujets.map((el) => el.id);
        this.operationNoteSujetStore.dispatch(
          fromOperationNoteSujetAction.deleteAllOperationNoteSujets({
            note,
            subtitleId,
            ids,
          })
        );

        break;

      default:
        break;
    }
  }
}
