<nz-card
  nzSize="small"
  nzBorderless
  [nzTitle]="metierDefinitionCardTitle"
  [nzExtra]="extra"
  class="p-6"
>
  <nz-descriptions nzBordered>
    <nz-descriptions-item nzSpan="3" nzTitle="Définition">
      {{ metier?.informations?.definition }}
    </nz-descriptions-item>
    <nz-descriptions-item nzSpan="3" nzTitle="Accès">
      <nz-list *ngIf="metier?.informations.accesArray | size">
        <nz-list-item *ngFor="let item of metier?.informations?.accesArray">
          <nz-row nzAlign="top" nzGutter="0" nzJustify="start">
            <nz-col nzSpan="2">
              <mat-icon class="metier-definition-iconListItem"
                >short_text</mat-icon
              >
            </nz-col>
            <nz-col nzSpan="22">{{ item }}</nz-col>
          </nz-row>
        </nz-list-item>
      </nz-list>
    </nz-descriptions-item>
    <nz-descriptions-item nzSpan="3" nzTitle="Conditions">
      {{ metier?.informations?.condition }}
    </nz-descriptions-item>
  </nz-descriptions>
</nz-card>

<ng-template #metierDefinitionCardTitle>
  <app-header-title
    [icon]="'business_center'"
    [title]="metier?.libelle"
    [subtitle]="true"
  ></app-header-title>
</ng-template>

<ng-template #extra>
  <div class="w-60 whitespace-nowrap p-6">
    <button class="btn-default" (click)="onRemove()">Retirer le métier</button>
  </div>
</ng-template>
