import { Store } from '@ngrx/store';
import { ParticipantActionsAccompagnementFormComponent } from './../participant-actions-accompagnement-form/participant-actions-accompagnement-form.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ParticipantId } from './../../../participant/store/participant.model';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Observable, of } from 'rxjs';
import { ParticipantAccompagnementState } from '../../store/participant-action-accompagnement.reducer';
import * as fromParticipantAccompagnementAction from '../../store/participant-action-accompagnement.actions';
import * as fromParticipantAccompagnementSelector from '../../store/participant-action-accompagnement.selectors';

@Component({
  selector: 'app-participant-actions-accompagnement-list',
  templateUrl: './participant-actions-accompagnement-list.component.html',
  styleUrls: ['./participant-actions-accompagnement-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantActionsAccompagnementListComponent
  implements OnInit, OnChanges
{
  title: string = "Nouvelle action d'accompagnement";
  accompagnement$: Observable<any> = of(null);
  modalWidth: number = 870;

  @Input() participant$: Observable<ParticipantId | any> = of([]);

  @ViewChild('actionAccompagnementTitleForm', { static: false })
  actionAccompagnementTitleForm: TemplateRef<any> | any;

  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private participantAccompagnementStore: Store<ParticipantAccompagnementState>
  ) {}

  ngOnInit(): void {
    this.loadAccompagnementTypes();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  onNew(participant: ParticipantId): void {
    if (!participant) return;

    const modal = this.modal.create({
      nzContent: ParticipantActionsAccompagnementFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.actionAccompagnementTitleForm,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });
    const instance = modal.getContentComponent();
    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
    instance.add.subscribe((event) => {
      if (!event) return;
      this.onAdd(participant.id, event);
    });
  }

  loadAccompagnementTypes(): void {
    this.participantAccompagnementStore.dispatch(
      fromParticipantAccompagnementAction.loadParticipantAccompagnementChampsTypes()
    );
  }

  loadAccompagnements(): void {}

  onAdd(participantId: string, accompagnement: any): void {
    if (!participantId || !accompagnement) return;
    this.participantAccompagnementStore.dispatch(
      fromParticipantAccompagnementAction.addParticipantActionAccompagnement({
        participantId,
        accompagnement,
      })
    );
  }
}
