<ng-container *ngIf="participant$ | async as participant">
  <app-layout-list
    *ngIf="accompagnement$ | async | size; else noResults"
    [new]="new"
    [card]="card"
    [source]="[]"
  ></app-layout-list>

  <ng-template #new>
    <app-card-new
      [type]="'participant'"
      [title]="title"
      [description]="''"
      [definition]="''"
      [height]="'250px'"
    ></app-card-new>
  </ng-template>

  <ng-template #card let-item let-favoris let-position="idx">
    <app-participant-actions-accompagnement-card></app-participant-actions-accompagnement-card>
  </ng-template>

  <ng-template #noResults>
    <app-no-result
      [icon]="'checklist'"
      [addtitle]="'Ajouter un champs d\'accompagenement'"
      [notitle]="'Aucune accompagenement ajouté'"
      [description]="''"
      (new)="onNew(participant)"
    ></app-no-result>
  </ng-template>

  <ng-template #actionAccompagnementTitleForm>
    <app-header-title
      [icon]="'checklist'"
      [title]="title"
      [subtitle]="true"
    ></app-header-title>
  </ng-template>
</ng-container>
