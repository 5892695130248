<nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
  <nz-col nzSpan="12">
    <div [ngSwitch]="utilityId" class="container">
      <div *ngSwitchCase="'actionsUtility'">
        <nz-tag
          (click)="onSelect('etat')"
          [nzColor]="currentTag === 'etat' ? 'magenta' : 'default'"
          >États</nz-tag
        >
        <nz-tag
          (click)="onSelect('roles')"
          [nzColor]="currentTag === 'roles' ? 'magenta' : 'default'"
          >Rôles</nz-tag
        >
        <nz-tag
          (click)="onSelect('type')"
          [nzColor]="currentTag === 'type' ? 'magenta' : 'default'"
          >Types</nz-tag
        >
      </div>
      <div *ngSwitchCase="'document'">
        <nz-tag
          (click)="onSelect('typeList')"
          [nzColor]="currentTag === 'typeList' ? 'magenta' : 'default'"
          >Type</nz-tag
        >
      </div>
      <div *ngSwitchCase="'itemEventUtility'">
        <nz-tag
          (click)="onSelect('object')"
          [nzColor]="currentTag === 'object' ? 'magenta' : 'default'"
          >Objects</nz-tag
        >
        <nz-tag
          (click)="onSelect('type')"
          [nzColor]="currentTag === 'type' ? 'magenta' : 'default'"
          >Types</nz-tag
        >
      </div>
      <div *ngSwitchCase="'entityUtility'">
        <nz-tag
          (click)="onSelect('engagements')"
          [nzColor]="currentTag === 'engagements' ? 'magenta' : 'default'"
          >Engagements</nz-tag
        >
        <nz-tag
          (click)="onSelect('origine')"
          [nzColor]="currentTag === 'origine' ? 'magenta' : 'default'"
          >Origines</nz-tag
        >
        <nz-tag
          (click)="onSelect('secteur')"
          [nzColor]="currentTag === 'secteur' ? 'magenta' : 'default'"
          >Secteurs</nz-tag
        >
        <nz-tag
          (click)="onSelect('Statut')"
          [nzColor]="currentTag === 'Statut' ? 'magenta' : 'default'"
          >Statuts</nz-tag
        >
        <nz-tag
          (click)="onSelect('type')"
          [nzColor]="currentTag === 'type' ? 'magenta' : 'default'"
          >Types</nz-tag
        >
      </div>
      <div *ngSwitchCase="'moduleUtility'">
        <nz-tag
          (click)="onSelect('type')"
          [nzColor]="currentTag === 'type' ? 'magenta' : 'default'"
          >Types</nz-tag
        >
      </div>
      <div *ngSwitchCase="'operationUtility'">
        <nz-tag
          (click)="onSelect('types')"
          [nzColor]="currentTag === 'types' ? 'magenta' : 'default'"
          >Types</nz-tag
        >
      </div>
      <div *ngSwitchCase="'participantUtility'">
        <nz-tag
          (click)="onSelect('communication')"
          [nzColor]="currentTag === 'communication' ? 'magenta' : 'default'"
          >Types de communication</nz-tag
        >
        <nz-tag
          (click)="onSelect('couvertureMaladie')"
          [nzColor]="currentTag === 'couvertureMaladie' ? 'magenta' : 'default'"
          >Couvertures maladie</nz-tag
        >
        <nz-tag
          (click)="onSelect('languageWeb')"
          [nzColor]="currentTag === 'languageWeb' ? 'magenta' : 'default'"
          >Languages Web</nz-tag
        >
        <nz-tag
          (click)="onSelect('matrimonial')"
          [nzColor]="currentTag === 'matrimonial' ? 'magenta' : 'default'"
          >Matrimoniales</nz-tag
        >
        <nz-tag
          (click)="onSelect('minimaSociaux')"
          [nzColor]="currentTag === 'minimaSociaux' ? 'magenta' : 'default'"
          >Minima Sociaux</nz-tag
        >
      </div>

      <div *ngSwitchDefault></div>
    </div>
  </nz-col>

  <nz-col nzSpan="12">
    <app-utilities-form
      *ngIf="items | size"
      [items]="items"
      [utility]="utility"
      [variable]="variable"
      (refreshItems)="onRefresh($event)"
    ></app-utilities-form>
  </nz-col>
</nz-row>
