<nz-select
  *ngIf="entites$ | async | size"
  [(ngModel)]="currentSelected"
  (ngModelChange)="onSelect($event)"
  [ngClass]="{ borderless: borderless }"
  [nzBorderless]="borderless"
  [nzNotFoundContent]="noData"
  [nzPlaceHolder]="placeHolder"
>
  <nz-option
    *ngFor="let item of entites$ | async"
    [nzLabel]="item?.entite?.etablissement?.denomination"
    [nzValue]="item"
  ></nz-option>
</nz-select>

<ng-template #noData>
  <app-no-result [addtitle]="noitemtitle"></app-no-result>
</ng-template>
