import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { DepartementState } from '../../store/departement.reducer';
import * as fromDepartementAction from '../../store/departement.actions';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-departement-card',
  templateUrl: './departement-card.component.html',
  styleUrls: ['./departement-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DepartementCardComponent implements OnInit, OnChanges {
  user$: Observable<any> = of(null);
  deleteType: string =
    'Les collaborateurs concernés par ce service ne seront plus associés à un service. Voulez-vous supprimer ce service ? ';
  @Input() departement: any = null;
  constructor(
    private departementStore: Store<DepartementState>,
    private userStore: Store<UserState>
  ) {}

  ngOnInit(): void {
    this.getUser();
  }
  ngOnChanges(changes: SimpleChanges): void {}

  getUser(): void {
    this.user$ = this.userStore.select(fromUserSelector.user);
  }

  onAction(event: string, departement: any): void {
    if (!event || !departement) return;
    switch (event) {
      case 'delete':
        this.onDelete(departement.id);
        break;

      default:
        break;
    }
  }

  onDelete(id: string): void {
    if (!id) return;
    this.departementStore.dispatch(
      fromDepartementAction.deleteDepartement({ id })
    );
  }
}
