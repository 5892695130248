<div class="divide-y">
  <ng-container *ngIf="candidatureControls.length; else noCandidatAddedTpl">
    <ng-container *ngIf="isList">
      <!-- ADD NEW CANDIDAT -->
      <div class="w-full flex items-end gap-x-2 pr-8 mt-4">
        <div class="w-10/12"></div>

        <div [class]="'w-2/12'">
          <button class="btn-default" (click)="onNewCandidat()">
            Ajouter un candidat
          </button>
        </div>
      </div>

      <!-- CURRENT CANDIDATS -->
      <div
        class="w-full flex flex-start rounded-xl p-4 h-24 bg-slate-50 overflow-x-hidden overflow-y-auto"
      >
        <div
          class="w-96 h-12 flex items-start justify-center p-2"
          *ngFor="let item of candidatureControls.value; let i = index"
        >
          <div
            [class]="
              i === 0
                ? 'w-10/12 flex items-start justify-center gap-2 p-2 rounded-xl bg-blue-100 cursor-pointer text-sm tracking-wider hover:bg-blue-200'
                : 'w-10/12 flex items-start justify-center gap-2 p-2 rounded-xl bg-blue-50 cursor-pointer text-sm tracking-wider hover:bg-blue-200'
            "
            (click)="$event.stopPropagation(); onShowCandidat(i)"
          >
            {{ item?.civilite }}.
            {{ item?.lastName | uppercase | carLimitation : 8 }}
            {{ item?.firstName | titlecase | carLimitation : 8 }}
          </div>

          <div class="w-1/12 align-top mt-[-2px]">
            <button
              class="btn-icon"
              (click)="$event.stopPropagation(); onRemoveCandidat(i)"
            >
              <svg
                class="iconSvg"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 28 28"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </ng-container>
    <!-- CANDIDATS ARRAY FORM -->
    <form [formGroup]="candidatureForm">
      <div formArrayName="candidats" class="w-full p-2">
        <div
          class="w-full grid grid-cols-1 p-2 mt-2"
          *ngFor="let el of candidatureControls.controls; let i = index"
          [formGroupName]="i"
        >
          <ng-container *ngIf="i === 0">
            <div class="w-full flex items-start gap-x-2">
              <div class="w-2/12 flex flex-col pt-2 gap-y-6">
                <nz-steps
                  [nzCurrent]="step"
                  nzSize="small"
                  nzDirection="vertical"
                  nzProgressDot
                  (nzIndexChange)="onIndexChanges($event, i)"
                >
                  <nz-step [nzTitle]="'Identité'"> </nz-step>
                  <nz-step [nzTitle]="'Administratif'"> </nz-step>
                  <nz-step [nzTitle]="'Présentation'"></nz-step>
                </nz-steps>
              </div>

              <div [ngSwitch]="step" class="w-10/12 pt-2">
                <div *ngSwitchCase="0" class="w-full">
                  <app-person-form
                    class="w-full"
                    [isList]="false"
                    [isEtablissement]="false"
                    [isFonction]="false"
                    [isAdresse]="true"
                    [isRole]="false"
                    [isDateNaissance]="true"
                    [isDateNaissanceRequired]="true"
                    [isMobileRequired]="false"
                    [isEmailRequired]="false"
                    [isAdresseRequired]="true"
                    [contactsInput]="currentPersonIdentity$ | async"
                    (onCurrentChanges)="onPersonIdentityChanges($event, i)"
                  ></app-person-form>
                </div>

                <div *ngSwitchCase="1" class="w-full">
                  <app-participant-diagnostic-form-administratif
                    class="w-full"
                    [isFreinValidation]="false"
                    [isDatesValidation]="false"
                    [administratif$]="currentPersonDiagnostic$"
                    (add)="onPersonDiagnosticChanges($event, i)"
                  ></app-participant-diagnostic-form-administratif>
                </div>

                <div *ngSwitchCase="2" class="w-full">
                  <div formGroupName="diagnostic">
                    <label class="label">Présentation</label>
                    <textarea
                      formControlName="presentation"
                      [placeholder]="'Présenter le candidat à son avantage'"
                      class="textareaForm max-h-24"
                    ></textarea>
                  </div>
                </div>
                <div *ngSwitchDefault></div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </form>
  </ng-container>

  <!-- FORM FOOTER ACTIONS -->

  <div class="w-full flex items-end gap-x-2 pr-8 mt-4">
    <div class="w-4/12"></div>
    <div class="w-2/12">
      <button
        class="btn-default-text"
        (click)="$event.stopPropagation(); onCancel()"
      >
        Annuler
      </button>
    </div>
    <div class="w-2/12">
      <button
        class="btn-default"
        (click)="$event.stopPropagation(); onStepBack()"
      >
        Précédents
      </button>
    </div>
    <div [class]="'w-4/12'">
      <button
        (click)="$event.stopPropagation(); onValidate()"
        [class]="
          !candidatureControls.length || candiaturesInvalid
            ? 'btn-disabled'
            : 'btn-primary'
        "
        [disabled]="!candidatureControls.length || candiaturesInvalid"
      >
        Valider les candidats
      </button>
    </div>
  </div>
</div>

<ng-template #noCandidatAddedTpl>
  <app-no-result
    [icon]="'person'"
    [addtitle]="'Ajouter'"
    [description]="'Ajouter les candidats proposés par le prescripteur'"
    (new)="onNewCandidat()"
  ></app-no-result>
</ng-template>
