<nz-row
  nzAlign="middle"
  nzGutter="8"
  nzJustify="center"
  *ngIf="!(todoFormVisibility$ | async)"
>
  <nz-col nzSpan="24">
    <app-todo-header
      (filter)="removeTodosFilters($event)"
      (fortoday)="getTodosToday($event)"
      (forfutur)="getTodosFutur($event)"
      (forpriority)="getTodosPriority($event)"
      (forlater)="getTodosLate($event)"
      (open)="onOpen(modalMode)"
      (cancel)="onClose(modalMode)"
    ></app-todo-header>
  </nz-col>
</nz-row>

<nz-row nzAlign="middle" nzGutter="8" nzJustify="center">
  <nz-col nzSpan="24">
    <ng-container *ngIf="!(todoFormVisibility$ | async); else formDisplay">
      <app-todo-list
        [source]="todos$ | async"
        (new)="onOpenForm()"
        (select)="onOpen(modalMode)"
      ></app-todo-list>
    </ng-container>
    <ng-template #formDisplay>
      <app-todo-form (closeNewForm)="onCloseNewForm(modalMode)"></app-todo-form>
    </ng-template>
  </nz-col>
</nz-row>
