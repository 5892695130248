import { Injectable } from '@angular/core';
import { Firestore, collection, doc, getDoc } from '@angular/fire/firestore';
import { Observable, of } from 'rxjs';
import { switchMap, map, take, takeUntil } from 'rxjs/operators';
import { Auth } from '@angular/fire/auth';
import { Apollo, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'any',
})
export class DocumentService {
  typesCollection: any;
  typesDocument: any;
  types$: Observable<any[]> = of([]);

  GET_DOCUMENT_FILTERS = gql`
    query DocumentFilters {
      documentFilters {
        name
        typeList
      }
    }
  `;

  constructor(private db: Firestore, private apollo: Apollo) {}

  getDocumentList(): Observable<any> {
    try {
      this.typesCollection = collection(this.db, 'utility');
      this.typesDocument = doc(this.typesCollection, 'document');
      this.types$ = this.typesDocument.get().pipe(
        map((list: any) => {
          const data = list.data() as any;
          return { ...data };
        })
      );

      return this.types$;
    } catch (error) {
      return of(error);
    }
  }

  getDocumentFilters(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_DOCUMENT_FILTERS,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  downloadDocument(document: any): Observable<string> {
    try {
      let filename = document.title
        .substring(document.title.lastIndexOf('/') + 1)
        .split('?')[0];
      let xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = function () {
        var a = document.createElement('a');
        a.href = window.URL.createObjectURL(xhr.response);
        a.download = filename;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
      };
      xhr.open('GET', document.fileRef);
      xhr.send();

      const success: string = `votre document a été téléchargé`;

      return of(success);
    } catch (error: any) {
      return of(error.message);
    }
  }
}
