import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitJoin',
})
export class SplitJoinPipe implements PipeTransform {
  transform(value: string, separator: string, remplace: string): string {
    if (!value) return '';

    if (!separator || !remplace) return value;

    const nextValue: string = value.split(separator).join(`${remplace} `);

    return nextValue;
  }
}
