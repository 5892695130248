import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';
import { UtilitiesState } from 'src/app/contents/components/utilities/store/utilities.reducer';
import * as fromUtilitiesSelector from 'src/app/contents/components/utilities/store/utilities.selectors';
import * as fromUtilitiesAction from 'src/app/contents/components/utilities/store/utilities.actions';
import { Timestamp } from 'firebase/firestore';

@Component({
  selector: 'app-participant-diagnostic-form-btp',
  templateUrl: './participant-diagnostic-form-btp.component.html',
  styleUrls: ['./participant-diagnostic-form-btp.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantDiagnosticFormBtpComponent
  implements OnInit, OnChanges, OnDestroy
{
  subscribe = new Subject();
  today: Date = new Date(Date.now());
  dateFormat: string = 'dd/MM/yyyy';

  //VARIABLES
  isCarteBtpTitle: string = "La Carte d'identification professionnelle du BTP.";
  isExpiredCarteBtpTitle: string =
    "La Carte d'identification professionnelle du BTP est-elle à refaire ?";

  //FORM FIELDS
  btpForm: FormGroup = this.fb.group({});
  isCarteBtp: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );
  isExpiredCarteBtp: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );
  numeroCarteBtp: FormControl<string | any> = new FormControl(
    false,
    Validators.required
  );
  carteBtpDate: FormControl<Date | any> = new FormControl(
    this.today,
    Validators.required
  );

  //INIT
  @Input() btpUpdate$: Observable<any> = of(null);
  @Output() add = new EventEmitter(false);

  constructor(
    private fb: FormBuilder,
    private utilitiesStore: Store<UtilitiesState>
  ) {}

  ngOnInit(): void {
    this.form();
    this.onFormChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  form(): void {
    this.formInit();
    this.formCreate();
    this.formSet();
  }

  formInit(): void {
    this.isCarteBtp = new FormControl<boolean | any>(
      false,
      Validators.required
    );
    this.isExpiredCarteBtp = new FormControl<boolean | any>(
      false,
      Validators.required
    );
    this.numeroCarteBtp = new FormControl<string | any>(
      '',
      Validators.required
    );
    this.carteBtpDate = new FormControl(this.today, Validators.required);
  }

  formCreate(): void {
    this.btpForm = this.fb.group({
      isCarteBtp: this.isCarteBtp,
      isExpiredCarteBtp: this.isExpiredCarteBtp,
      carteBtpDate: this.carteBtpDate,
      numeroCarteBtp: this.numeroCarteBtp,
    });
  }

  formSet(): void {
    this.btpUpdate$.pipe(takeUntil(this.subscribe)).subscribe((btp) => {
      if (!btp) return;
      this.btpForm.patchValue({ ...btp });
    });
  }

  checkboxChanges(event: boolean, type: string): void {
    switch (type) {
      case 'isCarteBtp':
        this.btpForm.patchValue({
          isCarteBtp: event,
        });

        break;
      case 'isExpiredCarteBtp':
        this.btpForm.patchValue({
          isExpiredCarteBtp: event,
        });

        break;
      default:
        break;
    }
  }

  onFormChanges(): void {
    this.btpForm.valueChanges
      .pipe(takeUntil(this.subscribe))
      .subscribe((values) => {
        const { carteBtpDate } = values;
        const dateBtp = Timestamp.fromDate(carteBtpDate);
        const valuesChanges = {
          ...values,
          carteBtpDate: dateBtp,
        };

        this.add.emit(valuesChanges);
      });
  }
}
