import { Pipe, PipeTransform } from '@angular/core';
import { fromUnixTime, isFuture } from 'date-fns';

@Pipe({
  name: 'isFutur',
})
export class IsFuturPipe implements PipeTransform {
  today: Date = new Date(Date.now());

  transform(value: any): boolean {
    if (!value) {
      return false;
    }

    if (isFuture(fromUnixTime(value['seconds']))) {
      return true;
    }

    return false;
  }
}
