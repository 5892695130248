import { OperationCadrageRisquesFormComponent } from './../operation-cadrage-risques-form/operation-cadrage-risques-form.component';
import { OperationCadrage } from './../../store/operation-cadrage.model';
import { BehaviorSubject } from 'rxjs';
import { NzModalService } from 'ng-zorro-antd/modal';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewContainerRef,
} from '@angular/core';
import { Update } from '@ngrx/entity';

@Component({
  selector: 'app-operation-cadrage-risques',
  templateUrl: './operation-cadrage-risques.component.html',
  styleUrls: ['./operation-cadrage-risques.component.scss'],
})
export class OperationCadrageRisquesComponent implements OnInit {
  modalWidth: number = 650;
  editing$ = new BehaviorSubject<boolean>(false);
  editingListItem$ = new BehaviorSubject<any>(null);
  editingListItemShow$ = new BehaviorSubject<any>(null);

  @Input() cadrage: OperationCadrage | any = null;
  @Output() update: EventEmitter<Update<OperationCadrage>> = new EventEmitter();

  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {}

  onNew(cadrage: OperationCadrage, risque?: any): void {
    const modal = this.modal.create({
      nzContent: OperationCadrageRisquesFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: risque
        ? `Modifier le risque : ${risque.title}`
        : `Nouveau risque`,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
    });
    const instance = modal.getContentComponent();
    instance.updateItem$ = new BehaviorSubject<any>(risque);
    instance.cancel.subscribe((event: any): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
    instance.add.subscribe((event: any): any => {
      if (!event) {
        return null;
      } else {
        this.onAdd(cadrage, event);
        modal.close();
      }
    });
    instance.update.subscribe((event: any): any => {
      if (!event) {
        return null;
      } else {
        this.onUpdate(cadrage, event);
        modal.close();
      }
    });
  }

  onAdd(cadrage: OperationCadrage, risque: any): void {
    const items = cadrage?.risques?.length
      ? [...cadrage.risques, risque]
      : [risque];

    const newCadrage: Update<OperationCadrage> = {
      id: cadrage.id,
      changes: {
        ...cadrage,
        risques: items,
      },
    };

    this.update.emit(newCadrage);
  }

  onUpdate(cadrage: OperationCadrage, risque: any): void {
    const items = cadrage?.risques.filter(
      (element) => element.id !== risque.id
    );

    const newCadrage: Update<OperationCadrage> = {
      id: cadrage.id,
      changes: {
        ...cadrage,
        risques: [...items, risque],
      },
    };

    this.update.emit(newCadrage);
  }

  outputUpdate(cadrage: Update<OperationCadrage>): void {
    this.update.emit(cadrage);
  }

  onDeleteItem(item: any, cadrage: OperationCadrage): void {
    const items = cadrage.risques.filter((risque) => risque.id !== item.id);

    const newCadrage: Update<OperationCadrage> = {
      id: cadrage.id,
      changes: {
        ...cadrage,
        risques: items,
      },
    };

    this.outputUpdate(newCadrage);
  }

  onDeleteAllList(cadrage: OperationCadrage): void {
    const newCadrage: Update<OperationCadrage> = {
      id: cadrage.id,
      changes: {
        ...cadrage,
        risques: [],
      },
    };

    this.outputUpdate(newCadrage);
  }

  onCancel(): void {
    return;
  }
}
