<nz-card
  nzHoverable
  [nzTitle]="moduleCardTitle"
  [nzExtra]="cardModuleExtra"
  (click)="$event.stopPropagation(); onSelect(module)"
>
  <ng-template #moduleCardTitle>
    <app-header-title
      [icon]="'dynamic_feed'"
      [subtitle]="true"
      [height]="'35px'"
      [width]="'35px'"
      [subtitle]="module?.type"
    ></app-header-title>
  </ng-template>

  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="24">
      <strong nz-typography>
        {{ module?.title | carLimitation: 50 }}
      </strong>
    </nz-col>
  </nz-row>

  <nz-space></nz-space>

  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="2">
      <mat-icon>ssid_chart</mat-icon>
    </nz-col>
    <nz-col nzSpan="2"></nz-col>
    <nz-col nzSpan="20">
      <nz-progress
        *ngIf="module?.progressionHours < 1"
        [nzPercent]="module?.progressionHours * 100"
        [nzStrokeColor]="progressDefaultColor"
        [nzShowInfo]="true"
      ></nz-progress>
      <nz-progress
        *ngIf="module?.progressionHours >= 1"
        [nzPercent]="module?.progressionHours * 100"
        [nzPercent]="100"
        [nzStrokeColor]="progressCompleteColor"
        [nzShowInfo]="true"
      ></nz-progress>
    </nz-col>
  </nz-row>
  <nz-row
    nzAlign="middle"
    nzGutter="8"
    nzJustify="start"
    class="mission_card_title"
  >
    <nz-col nzSpan="4">
      <mat-icon>domain</mat-icon>
    </nz-col>
    <nz-col nzSpan="20">
      <nz-tag [nzColor]="module?.entiteLevel1?.[0]? 'green' : 'volcano'">
        {{
        module?.entiteLevel1?.[0]? 
        (module?.entiteLevel1?.[0]?.etablissement?.denomination | carLimitation: 20) :
        'Aucune entité'
        }}
      </nz-tag>
    </nz-col>
  </nz-row>

  <nz-row nzJustify="start" nzGutter="8" nzAlign="middle">
    <nz-col nzSpan="4">
      <mat-icon class="module_progress_timer">date_range</mat-icon>
    </nz-col>
    <nz-col nzSpan="20">
      <small *ngIf="module?.progressionHours < 1">{{
        module?.calendrier?.dateEnd
          | dateFr: "distanceSuffix"
          | carLimitation: 25
      }}</small>
      <small *ngIf="module?.progressionHours >= 1">
        {{ module?.calendrier?.dateEnd | dateFr: "small" }}
      </small>
    </nz-col>
  </nz-row>

  <!-- <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="10">
      <nz-tag nzColor="geekblue">{{ module?.type }}</nz-tag>
    </nz-col>
    <nz-col nzSpan="4">
      <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
    </nz-col>
    <nz-col nzSpan="10">
      <nz-tag nzColor="orange" *ngIf="!module?.isFinish">En cours</nz-tag>
      <nz-tag nzColor="green" *ngIf="module?.isFinish">Terminé</nz-tag>
    </nz-col>
  </nz-row> -->
</nz-card>

<ng-template #cardModuleExtra>
  <app-button-dropdown
    [isDelete]="true"
    [deleteType]="'Voulez-vous supprimer ' + module?.title + ' ?'"
    (select)="onDropdown($event, module)"
    (click)="$event.stopPropagation()"
  ></app-button-dropdown>
</ng-template>
