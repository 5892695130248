import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  SimpleChanges,
  OnChanges,
  OnDestroy,
  ChangeDetectionStrategy,
} from '@angular/core';
import {
  BehaviorSubject,
  combineLatestWith,
  map,
  Observable,
  of,
  Subject,
  takeUntil,
} from 'rxjs';
import { Store } from '@ngrx/store';
import { MetierState } from '../../store/metier.reducer';
import * as fromMetierAction from '../../store/metier.actions';
import * as fromMetierSelector from '../../store/metier.selectors';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ParticipantId } from 'src/app/components/participant/store/participant.model';
import {
  differenceInCalendarMonths,
  differenceInCalendarYears,
} from 'date-fns';
import { Timestamp } from 'firebase/firestore';
import { flatten } from 'lodash';

@Component({
  selector: 'app-metiers',
  templateUrl: './metiers.component.html',
  styleUrls: ['./metiers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetiersComponent implements OnInit, OnChanges, OnDestroy {
  selectedThemes: any = '';
  selectedDomaine: any = '';
  selectedDomaineProfessionnels: any = '';
  selectedMetiers: any;
  selectedAppelations: any;
  selectedQualification: any = '';
  selectedFap: any = '';
  selectedAppelation: any;
  selectedSoftskill: any;
  selectedEntite$ = new BehaviorSubject<any>(false);
  loading$: Observable<boolean> = of(false);
  subscribe = new Subject();
  updateAdresse = new BehaviorSubject<any>(null);
  updateAdresseComplete = new BehaviorSubject<any>(null);
  themes$: Observable<any> = of(null);
  domaines$: Observable<any> = of(null);
  domainesProfessionnels$: Observable<any> = of(null);
  metiers$: Observable<any> = of(null);
  metier$: Observable<any> = of(null);
  qualifications$: Observable<any> = of(null);
  fap$: Observable<any> = of(null);

  appelations$: Observable<any> = of(null);
  appelation$: Observable<any> = of(null);
  competencesDeBase$: Observable<any> = of(null);
  competencesDeSpecifique$: Observable<any> = of(null);
  environnement$: Observable<any> = of(null);
  softskills$: Observable<any> = of(null);
  dateFormat: string = 'MM/yyyy';
  today: Date = new Date(Date.now());

  @Input() type: string = 'grandDomaine';
  @Input() showTitle: boolean = true;

  @Input() showThemes: boolean = false;
  @Input() showGrandDomaine: boolean = true;
  @Input() showDomaineProfessionnel: boolean = true;
  @Input() showMetiers: boolean = true;
  @Input() showAppelations: boolean = true;
  @Input() showAppelation: boolean = false;
  @Input() showAppelationDetail: boolean = false;
  @Input() showSoftskills: boolean = false;
  @Input() showAreaCode: boolean = false;
  @Input() showQualification: boolean = false;
  @Input() showAdresse: boolean = true;

  @Input() showFap: boolean = false;
  @Input() hideCancel: boolean = false;
  @Input() hideValidation: boolean = false;
  @Input() experience: boolean = false;

  @Input() participant = new BehaviorSubject<ParticipantId | any>(null);

  @Output() theme = new EventEmitter<any>(false);
  @Output() domaine = new EventEmitter<any>(false);
  @Output() domainesProfessionnels = new EventEmitter<any>(false);
  @Output() metiers = new EventEmitter<any>(false);
  @Output() appelations = new EventEmitter<any>(false);
  @Output() appelation = new EventEmitter<any>(false);
  @Output() qualification = new EventEmitter<any>(false);

  @Output() softskills = new EventEmitter<any>(false);
  @Output() cancel = new EventEmitter<any>(false);
  @Output() validation = new EventEmitter<any>(false);

  //FORM
  metierForm: UntypedFormGroup = new UntypedFormGroup({});
  areaCode: UntypedFormControl = new UntypedFormControl(0);
  duration: UntypedFormControl = new UntypedFormControl(0);
  age: UntypedFormControl = new UntypedFormControl(0);
  adresse: UntypedFormControl = new UntypedFormControl('');
  dateStart: UntypedFormControl = new UntypedFormControl();
  dateEnd: UntypedFormControl = new UntypedFormControl();
  entite: UntypedFormControl = new UntypedFormControl();
  horsFrance: UntypedFormControl = new UntypedFormControl(false);

  constructor(
    private metierStore: Store<MetierState>,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.getLoading();
    this.getDomaines();
    this.getThemes();
    this.getMetiers();
    this.getAppelations();
    this.getAppelation();
    this.getDomainesProfessionnel();
    this.getMetier();
    this.getQualifications();
    this.getFap();
    this.form();
    this.setParticipantVariables();
    this.onAppelationOutput();
    this.loadQualification();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.type.currentValue) return;

    switch (changes.type.currentValue) {
      case 'grandDomaine':
        this.loadDomaines();

        break;
      case 'themes':
        this.loadThemes();

        break;
      default:
        break;
    }
  }

  form(): void {
    this.formInit();
    this.formCreate();
  }

  formInit(): void {
    this.areaCode = new UntypedFormControl('');
    this.duration = new UntypedFormControl(6, Validators.required);
    this.age = new UntypedFormControl('');
    this.adresse = new UntypedFormControl('');
    this.dateStart = new UntypedFormControl(this.today, Validators.required);
    this.dateEnd = new UntypedFormControl(this.today, Validators.required);
    this.entite = new UntypedFormControl();
    this.horsFrance = new UntypedFormControl(false);
  }

  formCreate(): void {
    this.metierForm = this.fb.group({
      areaCode: this.areaCode,
      duration: this.duration,
      age: this.age,
      adresse: this.adresse,
      dateStart: this.dateStart,
      dateEnd: this.dateEnd,
      entite: this.entite,
      horsFrance: this.horsFrance,
    });
  }

  setParticipantVariables(): void {
    this.participant
      .pipe(takeUntil(this.subscribe))
      .subscribe((participant: ParticipantId) => {
        if (!participant) return;
        const { informations, age, duration } = participant;
        const areaCode: string = informations?.adresse?.adresse?.properties
          ?.postcode
          ? informations?.adresse?.adresse?.properties?.postcode.substring(0, 2)
          : '';
        const adresse: string = informations?.adresse?.adresse?.properties
          ?.label
          ? informations?.adresse?.adresse?.properties?.label
          : '';
        const adresseComplement: string = informations?.adresse?.complement
          ? informations?.adresse?.complement
          : '';
        this.updateAdresseComplete.next(adresseComplement);
        this.updateAdresse.next(informations?.adresse?.adresse);

        this.metierForm.patchValue({
          areaCode: areaCode,
          duration: duration,
          age: age,
          adresse: adresse,
        });
      });
  }

  setAdresse(adresse: any): void {
    if (!adresse) return;
    this.metierForm.patchValue({
      areaCode: adresse.properties.postcode.substring(0, 2),
      adresse: {
        geometry: adresse.geometry,
        properties: adresse.properties,
      },
    });
  }

  getLoading(): void {
    this.loading$ = this.metierStore.select(fromMetierSelector.loading);
  }

  getThemes(): void {
    this.themes$ = this.metierStore.select(fromMetierSelector.themes);
  }

  getDomaines(): void {
    this.domaines$ = this.metierStore.select(fromMetierSelector.grandDomaines);
  }

  getDomainesProfessionnel(): void {
    this.domainesProfessionnels$ = this.metierStore.select(
      fromMetierSelector.domainesProfessionnelsByGrandDomaine
    );
  }

  getMetiers(): void {
    this.metiers$ = this.metierStore.select(fromMetierSelector.selectedMetiers);
  }

  getMetier(): void {
    this.metier$ = this.metierStore.select(fromMetierSelector.metier);
  }

  getFap(): void {
    this.fap$ = this.metierStore.select(fromMetierSelector.fap);
  }

  getQualifications(): void {
    this.qualifications$ = this.metierStore.select(
      fromMetierSelector.qualifications
    );
  }

  getAppelations(): void {
    this.appelations$ = this.metierStore.select(fromMetierSelector.appelations);
  }
  getAppelation(): void {
    this.appelation$ = this.metierStore.select(fromMetierSelector.appelation);
  }

  onAppelationOutput(): void {
    this.metier$
      .pipe(
        takeUntil(this.subscribe),
        combineLatestWith(this.appelation$),
        map(([metier, appelation]) => {
          if (!metier || !appelation) return;
          const { softskills } = metier;

          const skills = softskills?.length
            ? softskills.filter((skill: any) => skill.score >= 0.5)
            : [];
          const { competencesDeBase, groupesCompetencesSpecifiques } =
            appelation;
          const competencesDeBaseCle = competencesDeBase?.length
            ? competencesDeBase?.filter((el: any) => el.competenceCle)
            : [];
          const competencesSpecifiques = groupesCompetencesSpecifiques?.length
            ? flatten(
                groupesCompetencesSpecifiques.map((el: any) =>
                  el?.competences?.filter((item: any) => item.competenceCle)
                )
              )
            : [];

          return {
            ...appelation,
            competencesDeBase: competencesDeBaseCle,
            competencesSpecifiques: competencesSpecifiques,
            softskills: skills,
          };
        })
      )
      .subscribe((appelation) => {
        if (!appelation) return;

        this.appelation.emit(appelation);
      });
  }

  loadThemes(): void {
    this.metierStore.dispatch(fromMetierAction.loadThemes());
  }

  loadDomaines(): void {
    this.metierStore.dispatch(fromMetierAction.loadDomaines());
  }

  loadQualification(): void {
    this.metierStore.dispatch(fromMetierAction.loadQualifications());
  }

  loadDomainesProfessionnels(code: string): void {
    if (!code) return;

    this.metierStore.dispatch(
      fromMetierAction.loadDomainesProfessionnelsByGrandDomaine({ code })
    );
  }
  loadMetiersByDomaine(code: string): void {
    if (!code) return;
    this.metierStore.dispatch(fromMetierAction.loadMetiersByDomaine({ code }));
  }

  loadMetierDeta(code: string): void {
    if (!code) return;
    this.metierStore.dispatch(
      fromMetierAction.loadAppelationsByMetier({ code })
    );
  }

  loadAppelationDetail(code: string): void {
    if (!code) return;
    this.metierStore.dispatch(
      fromMetierAction.loadAppelationDetailByAppelation({ code })
    );
  }

  loadMetierDetail(code: string): void {
    if (!code) return;
    this.metierStore.dispatch(fromMetierAction.loadMetierDetail({ code }));
    this.loadQualification();
  }

  onSelectEntite(entite: any): void {
    if (!entite) return;
    this.metierForm.patchValue({ entite: entite });
    this.selectedEntite$.next(entite);
  }

  onSelectTheme(theme: any): void {
    if (!theme) return;
    this.selectedThemes = theme;
    this.theme.emit(theme);
  }

  onSelectDomaine(domaine: any): void {
    if (!domaine) return;
    this.selectedDomaine = domaine;
    this.domaine.emit(domaine);
    this.loadDomainesProfessionnels(domaine.code);
  }

  onSelectDomaineProfessionnel(domaine: any, showMetiers: boolean): void {
    if (!domaine) return;
    this.selectedDomaineProfessionnels = domaine;
    this.domainesProfessionnels.emit(domaine);
    if (!showMetiers) {
      return;
    } else {
      this.loadMetiersByDomaine(domaine.code);
    }
  }

  onSelectMetiers(metier: any, showSoftskills: boolean): void {
    if (!metier) return;
    this.selectedMetiers = metier;
    this.metiers.emit(this.selectedMetiers);
    this.loadMetierDetail(metier.code);
  }

  onSelectAppelation(appelation: any): void {
    if (!appelation) return;
    this.selectedAppelations = appelation;
    this.appelations.emit(this.selectedAppelations);
    this.loadAppelationDetail(appelation.code);
  }

  loadFap(code: string): void {
    if (!code) return;
    this.metierStore.dispatch(fromMetierAction.loadFap({ code }));
  }

  onSelectQualification(qualification: any, code: string): void {
    if (!qualification) return;
    this.selectedQualification = qualification;
    // this.qualification.emit(this.selectedQualification);
    // const codeQualification: string = `${code}${qualification['codeCaracteristique']}`;
    // this.loadFap(codeQualification);
  }

  onSelectSoftskill(softskill: any): void {
    if (!softskill) return;
    this.selectedSoftskill = softskill;
    this.softskills.emit(this.selectedSoftskill);
  }

  onValidation(): void {
    const grandDomaine = this.selectedDomaine;
    const domaineProfessionnel = this.selectedDomaineProfessionnels;
    const metiers = this.selectedMetiers;
    const appelation = this.selectedAppelations;
    const formValue = this.metierForm.value;
    const entite = {
      entite: formValue.entite,
      dateStart: formValue.dateStart,
      dateEnd: formValue.dateEnd,
    };

    const event = {
      grandDomaine,
      domaineProfessionnel,
      metiers,
      entite,
      appelation,
    };

    this.validation.emit(event);
  }

  onValidationWtAppelationDetail(appelationDetail: any): void {
    const grandDomaine = this.selectedDomaine;
    const domaineProfessionnel = this.selectedDomaineProfessionnels;
    const metiers = this.selectedMetiers;
    const appelation = this.selectedAppelations;

    const event = {
      grandDomaine,
      domaineProfessionnel,
      metiers,
      appelation,
      appelationDetail,
    };

    this.validation.emit(event);
  }

  onValidationMetierDetail(metier: any): void {
    const grandDomaine = this.selectedDomaine;
    const domaineProfessionnel = this.selectedDomaineProfessionnels;
    const metiers = this.selectedMetiers;
    const appelation = this.selectedAppelations;

    const event = {
      grandDomaine,
      domaineProfessionnel,
      metiers,
      appelation,
      metier: { ...metier },
    };

    this.validation.emit(event);
  }

  onValidationMetierFapDetail(
    metier: any,
    fap: any,
    appellation: any,
    experience: boolean
  ): void {
    if (!this.metierForm.valid) return;
    const qualification = this.selectedQualification;
    const formValue = this.metierForm.value;

    const event = {
      period: {
        start: formValue?.dateStart
          ? Timestamp.fromDate(formValue?.dateStart)
          : null,
        end: formValue?.dateEnd ? Timestamp.fromDate(formValue?.dateEnd) : null,
        durationMonths:
          formValue?.dateStart && formValue?.dateEnd
            ? differenceInCalendarMonths(
                formValue?.dateEnd,
                formValue?.dateStart
              )
            : 0,
        durationYears:
          formValue?.dateStart && formValue?.dateEnd
            ? differenceInCalendarYears(
                formValue?.dateEnd,
                formValue?.dateStart
              )
            : 0,
        horsFrance: formValue.horsFrance,
      },
      entite: formValue?.entite,
      metier: {
        ...metier,
        qualification: qualification,
        fap: fap,
        areaCode: formValue.areaCode.toString(),
        duration: formValue.duration,
        age: formValue.age,
        adresse: formValue.adresse,
        appellation: appellation,
        appellations: experience ? [] : metier.appellations,
        competencesDeBase: experience ? [] : metier.competencesDeBase,
        environnementsTravail: experience ? [] : metier.environnementsTravail,
        groupesCompetencesSpecifiques: experience
          ? []
          : metier.groupesCompetencesSpecifiques,
      },
    };

    this.validation.emit(event);
    this.metierForm.reset();
    this.metierStore.dispatch(fromMetierAction.clearMetiers());
  }

  onCancel(): void {
    this.cancel.emit(true);
    this.selectedThemes = '';
    this.selectedDomaine = '';
    this.selectedDomaineProfessionnels = '';
    this.selectedMetiers = '';
    this.selectedAppelations = '';
    this.selectedQualification = '';
    this.selectedFap = '';
    this.selectedAppelation = '';
    this.selectedSoftskill = '';
    this.loading$ = of(false);
    this.themes$ = of(null);
    this.domaines$ = of(null);
    this.domainesProfessionnels$ = of(null);
    this.metiers$ = of(null);
    this.metier$ = of(null);
    this.qualifications$ = of(null);
    this.fap$ = of(null);
    this.metierForm.reset();
    this.metierStore.dispatch(fromMetierAction.clearMetiers());
    this.selectedEntite$.next(null);
  }

  ngOnDestroy(): void {
    this.onCancel();
    this.subscribe.next(null);
    this.subscribe.complete();
  }
}
