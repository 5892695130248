import { NzMessageService } from 'ng-zorro-antd/message';
import { Injectable } from '@angular/core';
import {
  Firestore,
  doc,
  docSnapshots,
  setDoc,
  getDoc,
  deleteDoc,
  DocumentData,
  DocumentSnapshot,
} from '@angular/fire/firestore';
import { Observable, of, from, EMPTY } from 'rxjs';
import { switchMap, mergeMap, map } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { Auth } from '@angular/fire/auth';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';
import { Apollo, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'any',
})
export class UserFavorisService {
  constructor(
    private auth: Auth,
    private db: Firestore,
    private userStore: Store<UserState>,
    private message: NzMessageService,
    private apollo: Apollo
  ) {}

  //ENTITES
  getFavorisEntites(): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) EMPTY;

          const dbDocument = doc(this.db, `users/${user.id}/favoris/entites`);

          return docSnapshots(dbDocument).pipe(
            map((doc: DocumentSnapshot<DocumentData>) => {
              const data = doc.data() as any;
              const id = doc.id;
              return { id, ...data };
            })
          );
        })
      );
    } catch {
      return of(null);
    }
  }

  addEntiteToFavoris(id: string, nom_raison_sociale: string): Observable<any> {
    try {
      return this.userStore.pipe(
        select(fromUserSelector.user),
        switchMap((user) => {
          if (user) {
            const userFavori = {
              id: user.id,
              avatar: user.avatar,
              displayName: user.displayName,
              fonction: user.fonction,
              service: user.service,
              organisation: {
                id: user.organisation.id,
                nom_raison_social: user.organisation.nom_raison_sociale,
              },
            };
            const favoviPath = doc(
              this.db,
              `organisations/${user.organisation.id}/entites/${id}/favoris/${user.id}`
            );

            return from(
              setDoc(favoviPath, { ...userFavori }, { merge: true })
                .then(() => {
                  const success: string = `${nom_raison_sociale} ajouté à vos favoris.`;
                  this.message.success(success, { nzDuration: 6000 });

                  return this.getFavorisEntites();
                })
                .catch((error) => of(error))
            ).pipe(mergeMap((res) => res));
          } else {
            return of(null);
          }
        })
      );
    } catch (error: any) {
      return error;
    }
  }

  removeEntiteToFavoris(
    id: string,
    nom_raison_sociale: string
  ): Observable<string | any> {
    try {
      return this.userStore.pipe(
        select(fromUserSelector.user),
        switchMap((user) => {
          if (user) {
            const favoviPath = doc(
              this.db,
              `organisations/${user.organisation.id}/entites/${id}/favoris/${user.id}`
            );

            return from(
              deleteDoc(favoviPath)
                .then(() => {
                  const success: string = `${nom_raison_sociale} retiré de vos favoris.`;
                  this.message.success(success, { nzDuration: 6000 });

                  return this.getFavorisEntites();
                })
                .catch((error) => of(error))
            ).pipe(mergeMap((res) => res));
          } else {
            return of(null);
          }
        })
      );
    } catch (error: any) {
      return error;
    }
  }

  //CONTACTS
  getFavorisContacts(): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (user) {
            const dbDocument = doc(
              this.db,
              `users/${user.id}/favoris/contacts`
            );

            return docSnapshots(dbDocument).pipe(
              map((doc: DocumentSnapshot<DocumentData>) => {
                const data = doc.data() as any;
                const id = doc.id;
                return { id, ...data };
              })
            );
          } else {
            return of(null);
          }
        })
      );
    } catch {
      return of(null);
    }
  }

  addContactToFavoris(
    id: string,
    displayName: string
  ): Observable<string | any> {
    try {
      return this.userStore.pipe(
        select(fromUserSelector.user),
        switchMap((user) => {
          if (user) {
            const userFavori = {
              id: user.id,
              avatar: user.avatar,
              displayName: user.displayName,
              fonction: user.fonction,
              service: user.service,
              organisation: {
                id: user.organisation.id,
                nom_raison_social: user.organisation.nom_raison_sociale,
              },
            };
            const favoviPath = doc(
              this.db,
              `organisations/${user.organisation.id}/contacts/${id}/favoris/${user.id}`
            );

            return from(
              setDoc(favoviPath, { ...userFavori }, { merge: true })
                .then(() => {
                  const success: string = `${displayName} ajouté.e à vos favoris.`;
                  this.message.success(success, { nzDuration: 6000 });

                  return this.getFavorisContacts();
                })
                .catch((error) => of(error))
            ).pipe(mergeMap((res) => res));
          } else {
            return of(null);
          }
        })
      );
    } catch (error: any) {
      return error;
    }
  }

  removeContactToFavoris(
    id: string,
    displayName: string
  ): Observable<string | any> {
    try {
      return this.userStore.pipe(
        select(fromUserSelector.user),
        switchMap((user) => {
          if (user) {
            const favoviPath = doc(
              this.db,
              `organisations/${user.organisation.id}/contacts/${id}/favoris/${user.id}`
            );

            return from(
              deleteDoc(favoviPath)
                .then(() => {
                  const success: string = `${displayName} retiré.e de vos favoris.`;
                  this.message.success(success, { nzDuration: 6000 });

                  return this.getFavorisContacts();
                })
                .catch((error) => of(error))
            ).pipe(mergeMap((res) => res));
          } else {
            return of(null);
          }
        })
      );
    } catch (error: any) {
      return error;
    }
  }

  //PARTICIPANTS
  getFavorisParticipants(): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !user.id) EMPTY;
          const dbDocument = doc(
            this.db,
            `users/${user.id}/favoris/participants`
          );

          return docSnapshots(dbDocument).pipe(
            map((doc: DocumentSnapshot<DocumentData>) => {
              const data = doc.data() as any;
              const id = doc.id;
              return { id, ...data };
            })
          );
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  addParticipantToFavoris(
    id: string,
    displayName: string
  ): Observable<string | any> {
    try {
      return this.userStore.pipe(
        select(fromUserSelector.user),
        switchMap((user) => {
          if (user) {
            const userFavori = {
              id: user.id,
              avatar: user.avatar,
              displayName: user.displayName,
              fonction: user.fonction,
              service: user.service,
              organisation: {
                id: user.organisation.id,
                nom_raison_social: user.organisation.nom_raison_sociale,
              },
            };
            const favoviPath = doc(
              this.db,
              `organisations/${user.organisation.id}/participants/${id}/favoris/${user.id}`
            );

            return from(
              setDoc(favoviPath, { ...userFavori }, { merge: true })
                .then(() => {
                  const success: string = `${displayName} ajouté.e à vos favoris.`;
                  this.message.success(success, { nzDuration: 6000 });

                  return this.getFavorisParticipants();
                })
                .catch((error) => of(error))
            ).pipe(mergeMap((res) => res));
          } else {
            return of(null);
          }
        })
      );
    } catch (error: any) {
      return error;
    }
  }

  removeParticipantToFavoris(
    id: string,
    displayName: string
  ): Observable<string | any> {
    try {
      return this.userStore.pipe(
        select(fromUserSelector.user),
        switchMap((user) => {
          if (user) {
            const favoviPath = doc(
              this.db,
              `organisations/${user.organisation.id}/participants/${id}/favoris/${user.id}`
            );

            return from(
              deleteDoc(favoviPath)
                .then(() => {
                  const success: string = `${displayName} retiré.e de vos favoris.`;
                  this.message.success(success, { nzDuration: 6000 });

                  return this.getFavorisParticipants();
                })
                .catch((error) => of(error))
            ).pipe(mergeMap((res) => res));
          } else {
            return of(null);
          }
        })
      );
    } catch (error: any) {
      return error;
    }
  }
}
