import { Injectable } from '@angular/core';
import { OperationId } from './../components/operation/store/operation.model';
import {
  Firestore,
  collection,
  doc,
  docSnapshots,
  collectionChanges,
  addDoc,
  setDoc,
  deleteDoc,
  serverTimestamp,
  CollectionReference,
  DocumentReference,
  DocumentChange,
  DocumentSnapshot,
  Timestamp,
  query,
  where,
  arrayUnion,
  arrayRemove,
  collectionSnapshots,
  getDocs,
  DocumentData,
} from '@angular/fire/firestore';
import { Observable, of, from, EMPTY } from 'rxjs';
import { switchMap, mergeMap, map, catchError } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import {
  Storage,
  ref,
  uploadBytesResumable,
  deleteObject,
  getDownloadURL,
  StorageReference,
  UploadTask,
  UploadMetadata,
} from '@angular/fire/storage';
import { UserState } from '../components/user/store/user.reducer';
import * as fromUserSelector from '../components/user/store/user.selectors';
import { v4 as uuidV4 } from 'uuid';
import { add, eachMonthOfInterval, format, fromUnixTime } from 'date-fns';
import { fr } from 'date-fns/locale';
import { arrayToMap } from '../utils/utils.array';
import { MissionHour } from '../components/missions/store/mission.model';
import { differenceBy, sum, unionBy } from 'lodash';
import { Apollo, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'any',
})
export class OperationMissionsService {
  today = new Date(Date.now());

  DELETE_OPERATION_MISSION = gql`
    mutation DeleteOperationMission($operationId: ID!, $missionId: ID!) {
      deleteOperationMission(operationId: $operationId, missionId: $missionId)
    }
  `;

  DELETE_OPERATION_MISSIONS = gql`
    mutation DeleteAllOperationMissions($id: ID!) {
      deleteAllOperationMissions(id: $id)
    }
  `;

  constructor(
    private db: Firestore,
    private storage: Storage,
    private userStore: Store<UserState>,
    private apollo: Apollo
  ) {}

  getAll(operationId: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !operationId) of([]);

          const dbCollection: CollectionReference<DocumentData> = collection(
            this.db,
            `organisations/${user.organisation.id}/operations/${operationId}/missions`
          );

          const changes = collectionSnapshots(dbCollection).pipe(
            map((changes) => {
              return changes.map((snap) => {
                const data = snap.data();
                const id = snap.id;
                return { id, ...data };
              });
            })
          );

          return changes;
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  getOne(operationId: string, id: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !operationId || !id) {
            return of(null);
          } else {
            const dbCollection: CollectionReference<DocumentData> = collection(
              this.db,
              `organisations/${user.organisation.id}/operations/${operationId}/missions`
            );

            const documentRef: DocumentReference<DocumentData> = doc(
              dbCollection,
              id
            );

            return docSnapshots(documentRef).pipe(
              map((snap: DocumentSnapshot<DocumentData>) => {
                const data = snap.data();
                const id = snap.id;
                return { id, ...data };
              }),
              catchError((error) => EMPTY)
            );
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  addOne(mission: any): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !mission) {
            return of(null);
          } else {
            const dbDocument: CollectionReference<DocumentData> = collection(
              this.db,
              `organisations/${user.organisation.id}/operations/${mission.operation.id}/missions`
            );

            const { startMission, endMission } = mission.contrat;
            const hours = this.missionPlanning(startMission, endMission);
            const author: any = this.setAuthor(user);

            console.log(mission);

            return from(
              addDoc(dbDocument, {
                ...mission,
                user: author,
                userUpdate: author,
                hours: hours,
                organisation: {
                  id: user.organisation.id,
                  denomination:
                    user?.organisation?.denomination ||
                    user?.organisation?.nom_raison_sociale,
                },
              })
                .then(() => of(`Mission ajoutée`))
                .catch((err) => of(err))
            ).pipe(mergeMap((res) => res));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  updateOne(id: string, item: Partial<any>): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !id || !item) {
            return of(null);
          } else {
            const dbDocument: DocumentReference<DocumentData> = doc(
              this.db,
              `organisations/${user.organisation.id}/operations/${item.operation.id}/missions/${item.id}`
            );

            const author: any = this.setAuthor(user);
            const { startMission, endMission, dateStart, dateEnd } = item;
            const hoursChanged: MissionHour[] = item.hours?.length
              ? item.hours.filter((el: MissionHour) => el.sum > 0)
              : [];

            const total: number = item.hours?.length
              ? sum(
                  item.hours.map((el: MissionHour) => (el.sum > 0 ? el.sum : 0))
                )
              : 0;

            const totalWork: number = item.hours?.length
              ? sum(
                  item.hours.map((el: MissionHour) =>
                    el.work > 0 ? el.work : 0
                  )
                )
              : 0;

            const totalTraining: number = item.hours?.length
              ? sum(
                  item.hours.map((el: MissionHour) =>
                    el.training > 0 ? el.training : 0
                  )
                )
              : 0;

            const hoursChangedDate: string[] = hoursChanged?.length
              ? hoursChanged.map((el: MissionHour) =>
                  format(el.date, 'MM/yyyy', { locale: fr })
                )
              : [];

            const hours: MissionHour[] = this.missionPlanning(
              startMission,
              endMission
            );

            const difference = hours.filter(
              (el: MissionHour) =>
                !hoursChangedDate.includes(
                  format(el.date, 'MM/yyyy', { locale: fr })
                )
            );

            const nextHours = [...difference, ...hoursChanged];
            const nextHoursSorted = this.planingSortByDate(nextHours);

            return from(
              setDoc(dbDocument, {
                ...item,
                userUpdate: author,
                dateUpdate: Timestamp.now(),
                hours: nextHoursSorted,
                total: total,
                totalWork: totalWork,
                totalTraining: totalTraining,
              })
                .then(() => `Mission modifiée`)
                .catch((err) => err)
            ).pipe(mergeMap((res) => of(res)));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  deleteOne(operationId: string, id: string): Observable<any> {
    try {
      if (!operationId) EMPTY;

      return this.apollo.mutate({
        mutation: this.DELETE_OPERATION_MISSION,
        variables: {
          operationId: operationId,
          missionId: id,
        },
      });
    } catch (error) {
      return of(error);
    }
  }

  deleteAll(operationId: string): Observable<any> {
    try {
      if (!operationId) EMPTY;

      return this.apollo.mutate({
        mutation: this.DELETE_OPERATION_MISSIONS,
        variables: {
          id: operationId,
        },
      });
    } catch (error) {
      return of(error);
    }
  }

  setAuthor(user: any): any {
    const author = {
      id: user.id,
      avatar: user?.avatar,
      lastName: user?.lastName,
      firstName: user?.firstName,
      displayName: user?.displayName,
      fonction: user?.fonction,
    };

    return author;
  }

  capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase();
  }

  createKeywords(name: any): any {
    if (name) {
      const arrName: any = [];
      let curName = '';
      name.split('').forEach((letter: any) => {
        curName += letter;
        arrName.push(curName);
      });
      return arrName;
    }
  }

  createMetadata(item: any): any {
    const { adresse, participant } = item;
    const { lastName, firstName } = participant;
    const letterLastName = lastName ? this.capitalizeFirstLetter(lastName) : '';
    const letterFirstName = firstName
      ? this.capitalizeFirstLetter(firstName)
      : '';
    const arrayLastName = lastName ? this.createKeywords(lastName) : [];
    const arrayFirstName = firstName ? this.createKeywords(firstName) : [];

    const startYear =
      item.dateStart && item.dateStart['seconds']
        ? format(fromUnixTime(item.dateStart['seconds']), 'yyyy', {
            locale: fr,
          })
        : 'Non renseigné';
    const startMonth =
      item.dateStart && item.dateStart['seconds']
        ? format(fromUnixTime(item.dateStart['seconds']), 'MMMM', {
            locale: fr,
          })
        : 'Non renseigné';
    const endYear =
      item.dateEnd && item.dateEnd['seconds']
        ? format(fromUnixTime(item.dateEnd['seconds']), 'yyyy', { locale: fr })
        : 'Non renseigné';
    const endMonth =
      item.dateEnd && item.dateEnd['seconds']
        ? format(fromUnixTime(item.dateEnd['seconds']), 'MMMM', { locale: fr })
        : 'Non renseigné';

    const postcode = adresse?.properties
      ? adresse?.properties?.postcode
      : 'Non renseigné';

    const city = adresse?.properties
      ? adresse?.properties?.city
      : 'Non renseignée';

    const metatada = {
      letterLastName: letterLastName,
      letterFirstName: letterFirstName,
      arrayLastName: arrayLastName,
      arrayFirstName: arrayFirstName,
      startYear: startYear,
      startMonth: startMonth,
      endYear: endYear,
      endMonth: endMonth,
      postcode: postcode,
      city: city,
    };

    return metatada;
  }

  missionPlanning(start: any, end: any): any[] {
    if (!start || !end) return [];
    const dateStart: Date = fromUnixTime(start['seconds']);
    const dateEnd: Date = fromUnixTime(end['seconds']);

    const dates: Date[] = eachMonthOfInterval({
      start: dateStart,
      end: dateEnd,
    });

    const workMonths: MissionHour[] = dates.map((date: Date) => ({
      date: date,
      timestamp: Timestamp.fromDate(date),
      work: 0,
      training: 0,
      sum: 0,
    }));

    return workMonths;
  }

  planingSortByDate(planing: MissionHour[]): MissionHour[] {
    if (!planing.length) return [];
    return planing.sort(this.compareDates);
  }

  compareDates(a: MissionHour, b: MissionHour) {
    if (a.date < b.date) {
      return -1;
    }
    if (a.date > b.date) {
      return 1;
    }
    return 0;
  }
}
