import { ParticipantDiagnosticExportService } from './../../../service/participant/diagnostic/participant-diagnostic-export.service';
import { Router } from '@angular/router';
import { ParticipantDiagnosticService } from './../../../service/participant/diagnostic/participant-diagnostic.service';
import {
  NzNotificationPlacement,
  NzNotificationService,
} from 'ng-zorro-antd/notification';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, tap } from 'rxjs';
import * as fromParticipantDiagnosticAction from './participant-diagnostic.actions';

const NOTIFICATION_PLACEMENT: NzNotificationPlacement = 'bottomRight';

@Injectable()
export class ParticipantDiagnosticEffects {
  loadDiagnostic$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromParticipantDiagnosticAction.loadParticipantDiagnostic),
      mergeMap((action) =>
        this.participantDiagnosticService.getOne(action.id).pipe(
          map((respons: any) => {
            const diagnostic: any = respons.data?.participantDiagnostic;
            return fromParticipantDiagnosticAction.loadParticipantDiagnosticSuccess(
              {
                diagnostic: diagnostic ? diagnostic : undefined,
              }
            );
          }),
          catchError((error) => {
            this.onErrorNotification(error, 'LOAD_DIAGNOSTIC');
            return of(
              fromParticipantDiagnosticAction.loadParticipantDiagnosticFailure({
                error,
              })
            );
          })
        )
      )
    )
  );

  createDiagnostic$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromParticipantDiagnosticAction.addParticipantDiagnostic),
      tap(() => {
        this.notification.info(
          'Nouveau',
          'Nouveau diagnostic en cours de création...',
          {
            nzPlacement: NOTIFICATION_PLACEMENT,
            nzDuration: 8000,
            nzKey: 'CREATE_DIAGNOSTIC',
          }
        );
      }),

      mergeMap((action) =>
        this.participantDiagnosticService
          .addOne(action.id, action.diagnostic)
          .pipe(
            map(({ data, errors, loading }) => {
              const diagnostic: any = data.createParticipantDiagnostic;
              this.notification.success(
                'Nouveau',
                'Diagnostic crée avec succès',
                {
                  nzPlacement: NOTIFICATION_PLACEMENT,
                  nzDuration: 8000,
                  nzKey: 'CREATE_DIAGNOSTIC',
                }
              );
              return fromParticipantDiagnosticAction.addParticipantDiagnosticSuccess(
                {
                  diagnostic,
                }
              );
            }),
            catchError((error) => {
              this.onErrorNotification(error, 'CREATE_DIAGNOSTIC');

              return of(
                fromParticipantDiagnosticAction.addParticipantDiagnosticFailure(
                  {
                    error,
                  }
                )
              );
            })
          )
      )
    )
  );

  updateDiagnostic$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromParticipantDiagnosticAction.updateParticipantDiagnostic),
      tap(() => {
        this.notification.info(
          'Modification',
          'Modification du diagnostic en cours...',
          {
            nzPlacement: NOTIFICATION_PLACEMENT,
            nzDuration: 8000,
            nzKey: 'UPDATE_DIAGNOSTIC',
          }
        );
      }),
      mergeMap((action) =>
        this.participantDiagnosticService
          .updateOne(action.id, action.diagnostic)
          .pipe(
            map((respons: any) => {
              const diagnostic: any = respons.data.updateParticipantDiagnostic;
              this.notification.success(
                'Modification',
                'Diagnostic modifié avec succès',
                {
                  nzPlacement: NOTIFICATION_PLACEMENT,
                  nzDuration: 8000,
                  nzKey: 'UPDATE_DIAGNOSTIC',
                }
              );
              return fromParticipantDiagnosticAction.updateParticipantDiagnosticSuccess(
                {
                  diagnostic,
                }
              );
            }),
            catchError((error) => {
              this.onErrorNotification(error, 'UPDATE_DIAGNOSTIC');

              return of(
                fromParticipantDiagnosticAction.updateParticipantDiagnosticFailure(
                  {
                    error,
                  }
                )
              );
            })
          )
      )
    )
  );

  deleteDiagnostic$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromParticipantDiagnosticAction.deleteParticipantDiagnostic),
      tap((action) => {
        this.notification.info(
          'Suppression',
          'Suppression du diagnostic en cours...',
          {
            nzPlacement: NOTIFICATION_PLACEMENT,
            nzDuration: 8000,
            nzKey: 'DELETE_DIAGNOSTIC',
          }
        );
      }),

      mergeMap((action) =>
        this.participantDiagnosticService
          .deleteOne(action.participantId, action.id)
          .pipe(
            map((respons: any) => {
              const success: string = respons.data.deleteParticipantDiagnostic;
              this.notification.success('Suppression', success, {
                nzPlacement: NOTIFICATION_PLACEMENT,
                nzDuration: 8000,
                nzKey: 'DELETE_DIAGNOSTIC',
              });
              return fromParticipantDiagnosticAction.deleteParticipantDiagnosticSuccess(
                {
                  success,
                }
              );
            }),
            catchError((error) => {
              this.onErrorNotification(error, 'DELETE_DIAGNOSTIC');

              return of(
                fromParticipantDiagnosticAction.deleteParticipantDiagnosticFailure(
                  {
                    error,
                  }
                )
              );
            })
          )
      )
    )
  );

  exportDiagnosticPDF$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromParticipantDiagnosticAction.exportParticipantDiagnosticPDF),
        tap(() => {
          this.notification.info(
            'Export au format PDF',
            'Exportation du diagnostic en cours...',
            {
              nzPlacement: NOTIFICATION_PLACEMENT,
              nzDuration: 8000,
              nzKey: 'EXPORT_DIAGNOSTIC_PDF',
            }
          );
        }),
        mergeMap((action) =>
          this.participantDiagnosticExportService
            .exportPDF(action.participant, action.diagnostic)
            .pipe(
              map((success: any) => {
                this.notification.success('Export au format PDF', success, {
                  nzPlacement: NOTIFICATION_PLACEMENT,
                  nzDuration: 8000,
                  nzKey: 'EXPORT_DIAGNOSTIC_PDF',
                });
                return fromParticipantDiagnosticAction.deleteParticipantDiagnosticsSuccess(
                  {
                    success,
                  }
                );
              }),
              catchError((error) => {
                this.onErrorNotification(error, 'EXPORT_DIAGNOSTIC_PDF');
                return of(
                  fromParticipantDiagnosticAction.deleteParticipantDiagnosticFailure(
                    {
                      error,
                    }
                  )
                );
              })
            )
        )
      ),
    { dispatch: false }
  );

  exportDiagnosticWORD$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromParticipantDiagnosticAction.exportParticipantDiagnosticWORD),
        tap(() => {
          this.notification.info(
            'Export au format WORD',
            'Exportation du diagnostic en cours...',
            {
              nzPlacement: NOTIFICATION_PLACEMENT,
              nzDuration: 8000,
              nzKey: 'EXPORT_DIAGNOSTIC_WORD',
            }
          );
        }),
        mergeMap((action) =>
          this.participantDiagnosticExportService
            .exportWORD(action.participant, action.diagnostic)
            .pipe(
              map((success: any) => {
                this.notification.success('Export au format WORD', success, {
                  nzPlacement: NOTIFICATION_PLACEMENT,
                  nzDuration: 8000,
                  nzKey: 'EXPORT_DIAGNOSTIC_WORD',
                });
                return fromParticipantDiagnosticAction.deleteParticipantDiagnosticsSuccess(
                  {
                    success,
                  }
                );
              }),
              catchError((error) => {
                this.onErrorNotification(error, 'EXPORT_DIAGNOSTIC_WORD');
                return of(
                  fromParticipantDiagnosticAction.deleteParticipantDiagnosticFailure(
                    {
                      error,
                    }
                  )
                );
              })
            )
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private notification: NzNotificationService,
    private participantDiagnosticService: ParticipantDiagnosticService,
    private router: Router,
    private participantDiagnosticExportService: ParticipantDiagnosticExportService
  ) {}

  onErrorNotification(error: any, key: string): void {
    this.notification.error('Error', error.message, {
      nzDuration: 10000,
      nzAnimate: true,
      nzPlacement: NOTIFICATION_PLACEMENT,
      nzKey: key ? key : '',
    });
  }
}
