import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { OperationFicheDePoste } from './operation-fiche-de-poste.model';
import * as OperationFicheDePosteActions from './operation-fiche-de-poste.actions';

export const operationFicheDePostesFeatureKey = 'operationFicheDePostes';

export interface OperationFicheDePosteState
  extends EntityState<OperationFicheDePoste> {
  fiche: any;
  currentFicheId: string;
  loading: boolean;
  loadingItem: boolean;
  error: any;
}

export const adapter: EntityAdapter<OperationFicheDePoste> =
  createEntityAdapter<OperationFicheDePoste>();

export const initialState: OperationFicheDePosteState = adapter.getInitialState(
  {
    fiche: undefined,
    currentFicheId: '',
    loading: false,
    loadingItem: false,

    error: undefined,
  }
);

export const reducer = createReducer(
  initialState,

  //LOAD ALL
  on(
    OperationFicheDePosteActions.loadOperationFichesDePoste,
    (state, action) => {
      return {
        ...state,
        loading: true,
        fiche: undefined,
        error: undefined,
      };
    }
  ),
  on(
    OperationFicheDePosteActions.loadOperationFichesDePosteSuccess,
    (state, action) => adapter.setAll(action.fiches, state)
  ),
  on(
    OperationFicheDePosteActions.loadOperationFichesDePosteSuccess,
    (state, action) => {
      return {
        ...state,
        loading: false,
        fiche: undefined,
        error: undefined,
      };
    }
  ),
  on(
    OperationFicheDePosteActions.loadOperationFichesDePosteFailure,
    (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
  ),

  //lOAD ONE
  on(
    OperationFicheDePosteActions.loadOperationFicheDePoste,
    (state, action) => {
      return {
        ...state,
        currentFicheId: action.ficheId,
        error: undefined,
        loadingItem: true,
      };
    }
  ),
  on(
    OperationFicheDePosteActions.loadOperationFicheDePosteSuccess,
    (state, action) => {
      return {
        ...state,
        fiche: action.fiche,
        error: undefined,
        loadingItem: false,
      };
    }
  ),

  on(
    OperationFicheDePosteActions.loadOperationFicheDePosteFailure,
    (state, action) => {
      return {
        ...state,
        fiche: undefined,
        error: action.error,
        loadingItem: false,
      };
    }
  ),

  //ADD ONE
  on(OperationFicheDePosteActions.addOperationFicheDePoste, (state, action) => {
    return {
      ...state,
      error: undefined,
    };
  }),
  on(
    OperationFicheDePosteActions.addOperationFicheDePosteSuccess,
    (state, action) => adapter.addOne(action.fiche, state)
  ),

  on(
    OperationFicheDePosteActions.addOperationFicheDePosteFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
      };
    }
  ),

  //COPY ONE
  on(
    OperationFicheDePosteActions.copyOperationFicheDePoste,
    (state, action) => {
      return {
        ...state,
        error: undefined,
      };
    }
  ),
  on(
    OperationFicheDePosteActions.copyOperationFicheDePosteSuccess,
    (state, action) => adapter.addOne(action.fiche, state)
  ),

  on(
    OperationFicheDePosteActions.copyOperationFicheDePosteFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
      };
    }
  ),

  //UPDATE ONE
  on(
    OperationFicheDePosteActions.updateOperationFicheDePoste,
    (state, action) => {
      return {
        ...state,
        fiche: action.fiche,
        error: undefined,
      };
    }
  ),
  on(
    OperationFicheDePosteActions.updateOperationFicheDePosteSuccess,
    (state, action) => adapter.updateOne(action.fiche, state)
  ),
  on(
    OperationFicheDePosteActions.updateOperationFicheDePosteFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
      };
    }
  ),

  //UPDATE CURRENT FICHE
  on(
    OperationFicheDePosteActions.updateOperationFicheDePosteItem,
    (state, action) => {
      return {
        ...state,
        fiche: { ...state.fiche, ...action.fiche },
        error: undefined,
      };
    }
  ),
  on(
    OperationFicheDePosteActions.updateOperationFicheDePosteItemSuccess,
    (state, action) => {
      return {
        ...state,
        fiche: { ...state.fiche, ...action.fiche },
        error: undefined,
      };
    }
  ),
  on(
    OperationFicheDePosteActions.updateOperationFicheDePosteItemFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
      };
    }
  ),
  //DELETE ONE
  on(
    OperationFicheDePosteActions.deleteOperationFicheDePoste,
    (state, action) => {
      {
        return {
          ...state,
          fiche: undefined,
          error: undefined,
        };
      }
    }
  ),

  on(
    OperationFicheDePosteActions.deleteOperationFicheDePoste,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(
    OperationFicheDePosteActions.deleteOperationFicheDePosteSuccess,
    (state, action) => {
      {
        return {
          ...state,
          fiche: undefined,
          error: undefined,
        };
      }
    }
  ),
  on(
    OperationFicheDePosteActions.deleteOperationFicheDePosteFailure,
    (state, action) => {
      return {
        ...state,
        fiche: undefined,
        error: action.error,
      };
    }
  ),

  //DELETE ALL
  on(
    OperationFicheDePosteActions.deleteOperationFicheDePostes,
    (state, action) => {
      return {
        ...state,
        error: undefined,
        fiche: undefined,
      };
    }
  ),
  on(
    OperationFicheDePosteActions.deleteOperationFicheDePostes,
    (state, action) => adapter.removeAll(state)
  ),

  on(
    OperationFicheDePosteActions.deleteOperationFicheDePostesSuccess,
    (state, action) => {
      return {
        ...state,
        error: undefined,
        fiche: undefined,
      };
    }
  ),
  on(
    OperationFicheDePosteActions.deleteOperationFicheDePostesFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
        fiche: undefined,
      };
    }
  ),

  //CLEAR ALL

  on(OperationFicheDePosteActions.clearOperationFicheDePostes, (state) =>
    adapter.removeAll(state)
  )
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
