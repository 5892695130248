import { NoResultModule } from './components/no-result/no-result.module';
import { AidsEligiblesModule } from './components/aids-eligibles/aids-eligibles.module';
import { ChartsIndicatorsComponent } from './components/charts-indicators/charts-indicators.component';
import { PortalLayoutBodyService } from './../portals/portal-layout-body.service';
import { MaterialModule } from './../css/material.module';
import { TablePortalComponent } from './components/table-portal/table-portal.component';
import { ProgressionComponent } from './components/progression/progression.component';
import { ObjectifService } from './../service/objectif.service';
import { ObjectifComponent } from './components/objectif/objectif.component';
import { ExcelJsonViewerComponent } from './components/excel-json-viewer/excel-json-viewer.component';
import { TimeIndicatorsComponent } from './components/time-indicators/time-indicators.component';
import { SkeletonComponent } from './components/skeleton/skeleton.component';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipeModule } from './../pipe/pipe.module';
import { ZorroModule } from './../css/zorro.module';
import { NgModule, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalculatriceComponent } from './components/calculatrice/calculatrice.component';
import { ChartsComponent } from './components/charts/charts.component';
import { NgxEchartsModule } from 'ngx-echarts';
import * as fromItemOperations from './components/item-operations-select/store/operations-select.reducer';
import { OperationsSelectEffects } from './components/item-operations-select/store/operations-select.effects';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { UsersSelectComponent } from './components/users-select/users-select.component';
import * as fromEntiteSelect from './components/entite-select/store/entite-select.reducer';
import { EntiteSelectEffects } from './components/entite-select/store/entite-select.effects';

import { UtilitiesListComponent } from './components/utilities/components/utilities-list/utilities-list.component';
import { UtilitiesDetailComponent } from './components/utilities/components/utilities-detail/utilities-detail.component';
import { UtilitiesFormComponent } from './components/utilities/components/utilities-form/utilities-form.component';
import { UtilitiesTagsComponent } from './components/utilities/components/utilities-tags/utilities-tags.component';
import { UtilitiesSelectComponent } from './components/utilities/components/utilities-select/utilities-select.component';
import { UtilitiesItemsListComponent } from './components/utilities/components/utilities-items-list/utilities-items-list.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { CardIdentityComponent } from './components/card-identity/card-identity.component';
import { CardActionsComponent } from './components/card-actions/card-actions.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { ItemOperationsComponent } from './components/item-operations/item-operations.component';
import { ItemOperationsSelectComponent } from './components/item-operations-select/components/item-operations-select.component';
import { ItemOperationsTypeComponent } from './components/item-operations-type/item-operations-type.component';
import { ParticipantValidatorComponent } from './components/participant-validator/participant-validator.component';
import { EntiteValidatorComponent } from './components/entite-validator/entite-validator.component';
import { ContactValidatorComponent } from './components/contact-validator/contact-validator.component';
import { ItemOperationsRoleComponent } from './components/item-operations-role/item-operations-role.component';
import { EntiteSelectComponent } from './components/entite-select/components/entite-select.component';
import { ItemHeaderFiltersComponent } from './components/item-header-filters/item-header-filters.component';
import { ItemTabsetComponent } from './components/item-tabset/item-tabset.component';
import { ParticipantsSelectComponent } from './components/participants-select/components/participants-select.component';
import { ContactsSelectComponent } from './components/contacts-select/components/contacts-select.component';
import * as fromParticipantsSelect from './components/participants-select/store/participants-select.reducer';
import { ParticipantsSelectEffects } from './components/participants-select/store/participants-select.effects';
import * as fromContactsSelect from './components/contacts-select/store/contacts-select.reducer';
import { ContactsSelectEffects } from './components/contacts-select/store/contacts-select.effects';
import { ExportSelectComponent } from './components/export-select/export-select.component';
import { EntiteSearchSelectComponent } from './components/entite-search/components/entite-search-select/entite-search-select.component';
import { ItemIdentityComponent } from './components/item-identity/item-identity.component';
import { TimeIndicatorsStatusComponent } from './components/time-indicators-status/time-indicators-status.component';
import { UtilitiesStandardSelectorComponent } from './components/utilities/components/utilities-standard-selector/utilities-standard-selector.component';
import { TabTitleComponent } from './components/tab-title/tab-title.component';
import { ItemOperationsInterventionComponent } from './components/item-operations-intervention/item-operations-intervention.component';
import { CheckCardComponent } from './components/check-card/check-card.component';
import { PageBrandComponent } from './components/page-brand/page-brand.component';
import { UnderlineComponent } from './components/underline/underline.component';
import { ListSearchComponent } from './components/list-search/list-search.component';
import { SharingComponent } from './components/sharing/sharing.component';
import { InterventionTableComponent } from './components/intervention-table/intervention-table.component';
import { LoadingComponent } from './components/loading/loading.component';
import { DirectiveModule } from '../directive/directive.module';
import { ChartsGanttComponent } from './components/charts-gantt/charts-gantt.component';
import { ParticipantIndicatorComponent } from './components/participant-indicator/participant-indicator.component';
import { TableActionsComponent } from './components/table-actions/table-actions.component';
import { TableModulesComponent } from './components/table-modules/table-modules.component';
import { TableEntitesComponent } from './components/table-entites/table-entites.component';
import { TableContactsComponent } from './components/table-contacts/table-contacts.component';
import { TableParticipantsComponent } from './components/table-participants/table-participants.component';
import { TableMissionsComponent } from './components/table-missions/table-missions.component';
import { TableFichesPosteComponent } from './components/table-fiches-poste/table-fiches-poste.component';
import { TableOperationsComponent } from './components/table-operations/table-operations.component';
import { PdfViewerComponent } from './components/pdf-viewer/pdf-viewer.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { LoadingAnimationComponent } from './components/loading-animation/loading-animation.component';
import { EntiteSearchModule } from './components/entite-search/entite-search.module';
import { CommunesFranceModule } from './components/communes-france/communes-france.module';
import { CoordonneesModule } from './components/coordonnees/coordonnees.module';
import { UtilitiesModule } from './components/utilities/utilities.module';
import { ButtonsModule } from './components/buttons/buttons.module';
import { MetiersModule } from '../features/metiers/metiers.module';
import { HeaderModule } from './components/header-title/header.module';
import { TextEdithingModule } from '../features/text-edithing/text-edithing.module';
import { LayoutModule } from './components/layout/layout.module';
import { AvatarModule } from '../features/avatar/avatar.module';
import { UiModule } from '../ui/ui/ui.module';

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    CalculatriceComponent,
    ChartsComponent,
    SkeletonComponent,
    TimeIndicatorsComponent,
    ExcelJsonViewerComponent,
    ObjectifComponent,
    ProgressionComponent,
    TablePortalComponent,
    UsersSelectComponent,
    UtilitiesListComponent,
    UtilitiesDetailComponent,
    UtilitiesFormComponent,
    UtilitiesTagsComponent,
    UtilitiesSelectComponent,
    UtilitiesItemsListComponent,
    SearchBarComponent,
    CardIdentityComponent,
    CardActionsComponent,
    DatePickerComponent,
    ChartsIndicatorsComponent,
    ItemOperationsComponent,
    ItemOperationsSelectComponent,
    ItemOperationsTypeComponent,
    ParticipantValidatorComponent,
    EntiteValidatorComponent,
    ContactValidatorComponent,
    ItemOperationsRoleComponent,
    EntiteSelectComponent,
    ItemHeaderFiltersComponent,
    ItemTabsetComponent,
    ParticipantsSelectComponent,
    ContactsSelectComponent,
    ExportSelectComponent,
    EntiteSearchSelectComponent,
    ItemIdentityComponent,
    TimeIndicatorsStatusComponent,
    UtilitiesStandardSelectorComponent,
    TabTitleComponent,
    ItemOperationsInterventionComponent,
    CheckCardComponent,
    PageBrandComponent,
    UnderlineComponent,
    ListSearchComponent,
    SharingComponent,
    InterventionTableComponent,
    LoadingComponent,
    ChartsGanttComponent,
    ParticipantIndicatorComponent,
    TableActionsComponent,
    TableModulesComponent,
    TableEntitesComponent,
    TableContactsComponent,
    TableParticipantsComponent,
    TableMissionsComponent,
    TableFichesPosteComponent,
    TableOperationsComponent,
    PdfViewerComponent,
    LoadingAnimationComponent,
    ItemOperationsComponent,
  ],
  imports: [
    CommonModule,
    ZorroModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    PipeModule,
    ButtonsModule,
    DirectiveModule,
    AidsEligiblesModule,
    PdfViewerModule,
    EntiteSearchModule,
    CommunesFranceModule,
    NoResultModule,
    HeaderModule,
    CoordonneesModule,
    MetiersModule,
    TextEdithingModule,
    LayoutModule,
    AvatarModule,
    UiModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),

    UtilitiesModule,
    StoreModule.forFeature(
      fromItemOperations.operationsSelectsFeatureKey,
      fromItemOperations.reducer
    ),
    StoreModule.forFeature(
      fromEntiteSelect.entiteSelectsFeatureKey,
      fromEntiteSelect.reducer
    ),
    StoreModule.forFeature(
      fromContactsSelect.contactsSelectsFeatureKey,
      fromContactsSelect.reducer
    ),
    EffectsModule.forFeature([
      OperationsSelectEffects,
      EntiteSelectEffects,
      ParticipantsSelectEffects,
      ContactsSelectEffects,
    ]),

    LottieModule.forRoot({ player: playerFactory }),
  ],
  exports: [
    CalculatriceComponent,
    ChartsComponent,
    SkeletonComponent,
    TimeIndicatorsComponent,
    ExcelJsonViewerComponent,
    ObjectifComponent,
    ProgressionComponent,
    TablePortalComponent,
    UsersSelectComponent,
    UtilitiesListComponent,
    UtilitiesDetailComponent,
    UtilitiesFormComponent,
    UtilitiesTagsComponent,
    UtilitiesSelectComponent,
    UtilitiesItemsListComponent,
    SearchBarComponent,
    CardIdentityComponent,
    CardActionsComponent,
    DatePickerComponent,
    ChartsIndicatorsComponent,
    ItemOperationsComponent,
    ParticipantValidatorComponent,
    EntiteValidatorComponent,
    ContactValidatorComponent,
    ItemOperationsRoleComponent,
    EntiteSelectComponent,
    ItemHeaderFiltersComponent,
    ItemTabsetComponent,
    ParticipantsSelectComponent,
    ContactsSelectComponent,
    ExportSelectComponent,
    ItemIdentityComponent,
    UtilitiesStandardSelectorComponent,
    TabTitleComponent,
    ItemOperationsInterventionComponent,
    CheckCardComponent,
    PageBrandComponent,
    UnderlineComponent,
    ListSearchComponent,
    SharingComponent,
    InterventionTableComponent,
    LoadingComponent,
    ParticipantIndicatorComponent,
    TableActionsComponent,
    TableModulesComponent,
    TableEntitesComponent,
    TableContactsComponent,
    TableParticipantsComponent,
    TableMissionsComponent,
    TableFichesPosteComponent,
    TableOperationsComponent,
    PdfViewerComponent,
    LoadingAnimationComponent,
    AidsEligiblesModule,
  ],
  providers: [ObjectifService, PortalLayoutBodyService],
})
export class ContentsModule {}
