import { RouterActivateStateService } from './../../../resolvers/router/router-activate-state.service';
import { SideNavigationService } from './../../../service/side-navigation.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map } from 'rxjs/operators';
import { of } from 'rxjs';
import * as fromSideNavigationAction from './side-navigation.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Injectable()
export class SideNavigationEffects {
  loadNavigationState$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromSideNavigationAction.loadNavigationState),
        mergeMap(() =>
          this.routerStateService
            .resolve(this.route.snapshot, this.router.routerState.snapshot)
            .pipe(
              map((state) => {
                return fromSideNavigationAction.loadNavigationStateSuccess({
                  state,
                });
              })
            )
        )
      ),
    { dispatch: false }
  );

  loadNavigation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromSideNavigationAction.loadSideNavigation),
        mergeMap((action) =>
          this.sideService
            .onNavigation(action.navigation)
            .pipe(map(() => of(null)))
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private sideService: SideNavigationService,
    private routerStateService: RouterActivateStateService,
    private route: ActivatedRoute,
    private router: Router,
    private routeTitle: Title
  ) {}
}
