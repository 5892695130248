import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { ParticipantId } from 'src/app/components/participant/store/participant.model';
import { ParticipantState } from 'src/app/components/participant/store/participant.reducer';
import * as fromParticipantSelector from 'src/app/components/participant/store/participant.selectors';
import { ParticipantDiagnosticState } from '../../store/participant-diagnostic.reducer';
import * as fromParticipantDiagnosticSelector from '../../store/participant-diagnostic.selectors';

@Component({
  selector: 'app-participant-diagnostic-item-header',
  templateUrl: './participant-diagnostic-item-header.component.html',
  styleUrls: ['./participant-diagnostic-item-header.component.scss'],
})
export class ParticipantDiagnosticItemHeaderComponent implements OnInit {
  participant$: Observable<ParticipantId | any> = of(null);
  diagnostic$: Observable<ParticipantId | any> = of(null);

  constructor(
    private participantStore: Store<ParticipantState>,
    private participantDiagnosticStore: Store<ParticipantDiagnosticState>
  ) {}

  ngOnInit(): void {
    this.getParticipant();
    this.getParticipantDiagnostic();
  }

  getParticipant(): void {
    this.participant$ = this.participantStore.select(
      fromParticipantSelector.participant
    );
  }
  getParticipantDiagnostic(): void {
    this.diagnostic$ = this.participantDiagnosticStore.select(
      fromParticipantDiagnosticSelector.diagnostic
    );
  }
}
