import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CandidatureState,
  candidaturesFeatureKey,
  selectAll,
} from './candidature.reducer';

export const selectCandidatureState = createFeatureSelector<CandidatureState>(
  candidaturesFeatureKey
);

export const candidats = createSelector(selectCandidatureState, selectAll);

export const success = createSelector(
  selectCandidatureState,
  (state: CandidatureState) => state.success
);

export const loading = createSelector(
  selectCandidatureState,
  (state: CandidatureState) => state.loading
);
export const error = createSelector(
  selectCandidatureState,
  (state: CandidatureState) => state.error
);
