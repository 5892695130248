import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, of, Subject, takeUntil } from 'rxjs';
import { UtilitiesState } from 'src/app/contents/components/utilities/store/utilities.reducer';
import * as fromUtilitiesSelector from 'src/app/contents/components/utilities/store/utilities.selectors';
import * as fromUtilitiesAction from 'src/app/contents/components/utilities/store/utilities.actions';

@Component({
  selector: 'app-participant-diagnostic-form-postures',
  templateUrl: './participant-diagnostic-form-postures.component.html',
  styleUrls: ['./participant-diagnostic-form-postures.component.scss'],
})
export class ParticipantDiagnosticFormPosturesComponent
  implements OnInit, OnChanges, OnDestroy
{
  subscribe = new Subject();

  //VARIABLES
  isFreinTitle: string =
    'La capacité a occuper un poste est-il un frein identifié ?';
  incapaciteAoccuperUnPosteTitle: string =
    'Est dans l’incapacité immédiate d’occuper un poste de travail';
  aBesoinDaccompagnementTitle: string =
    'A besoin d’un accompagnement avant de pouvoir occuper un poste de travail';
  peutOccuperUnPosteAvecConditionTitle: string =
    'Peut occuper un poste de travail dans des conditions aménagées';
  peutOccuperUnPosteSansConditionTitle: string =
    'Peut occuper un poste de travail dans des conditions ordinaires';

  //FORM FIELDS
  postureForm: FormGroup = this.fb.group({});
  incapaciteAoccuperUnPoste: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );
  aBesoinDaccompagnement: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );
  peutOccuperUnPosteAvecCondition: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );
  peutOccuperUnPosteSansCondition: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );
  precision: FormControl<string | any> = new FormControl('');

  isFrein: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );

  //INIT
  @Input() postureInput$: Observable<any> = of(null);
  @Output() add = new EventEmitter(false);

  constructor(
    private fb: FormBuilder,
    private utilitiesStore: Store<UtilitiesState>
  ) {}

  ngOnInit(): void {
    this.form();
    this.onFormChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  form(): void {
    this.formInit();
    this.formCreate();
    this.formSet();
  }

  formInit(): void {
    this.incapaciteAoccuperUnPoste = new FormControl<boolean>(
      false,
      Validators.required
    );
    this.aBesoinDaccompagnement = new FormControl<boolean>(
      false,
      Validators.required
    );
    this.peutOccuperUnPosteAvecCondition = new FormControl<boolean>(
      false,
      Validators.required
    );
    this.peutOccuperUnPosteSansCondition = new FormControl<boolean>(
      false,
      Validators.required
    );
    this.precision = new FormControl('');
    this.isFrein = new FormControl<boolean>(false, Validators.required);
  }

  formCreate(): void {
    this.postureForm = this.fb.group({
      incapaciteAoccuperUnPoste: this.incapaciteAoccuperUnPoste,
      aBesoinDaccompagnement: this.aBesoinDaccompagnement,
      peutOccuperUnPosteAvecCondition: this.peutOccuperUnPosteAvecCondition,
      peutOccuperUnPosteSansCondition: this.peutOccuperUnPosteSansCondition,
      precision: this.precision,
      isFrein: this.isFrein,
    });
  }

  formSet(): void {
    this.postureInput$.pipe(takeUntil(this.subscribe)).subscribe((values) => {
      if (!values) return;
      this.postureForm.patchValue({ ...values });
    });
  }

  checkboxChanges(event: boolean, type: string): void {
    switch (type) {
      case 'incapaciteAoccuperUnPoste':
        this.postureForm.patchValue({
          incapaciteAoccuperUnPoste: event,
        });

        break;
      case 'aBesoinDaccompagnement':
        this.postureForm.patchValue({
          aBesoinDaccompagnement: event,
        });

        break;
      case 'peutOccuperUnPosteAvecCondition':
        this.postureForm.patchValue({
          peutOccuperUnPosteAvecCondition: event,
        });

        break;
      case 'peutOccuperUnPosteSansCondition':
        this.postureForm.patchValue({
          peutOccuperUnPosteSansCondition: event,
        });

        break;

      default:
        break;
    }
  }

  onFormChanges(): void {
    this.postureForm.valueChanges
      .pipe(takeUntil(this.subscribe))
      .subscribe((values) => this.add.emit(values));
  }
}
