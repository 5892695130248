<nz-card nzBorderless [nzTitle]="logo" [nzActions]="[loginPage]">
  <nz-card class="card_content">
    <nz-list nzSplit="true" *ngIf="!(loading$ | async); else loadingAnimation">
      <nz-list-item
        *ngFor="let item of options"
        nzNoFlex
        (click)="$event.stopPropagation(); onSelect(item)"
      >
        <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
          <nz-col nzSpan="8">
            <mat-icon>{{ item?.icon }}</mat-icon></nz-col
          >
          <nz-col nzSpan="16">
            <p>{{ item?.name }}</p>
            <small>{{ item?.description }}</small>
          </nz-col>
        </nz-row>
      </nz-list-item>
    </nz-list>
  </nz-card>
</nz-card>

<ng-template #logo>
  <img
    width="160px"
    height="80px"
    src="../../../../../assets/protein_logo.svg"
    alt="proteinLogo"
  />
</ng-template>

<ng-template #title>
  <app-header-title
    [title]="'Nouveau compte'"
    [subtitle]="true"
    [icon]="icon"
  ></app-header-title>
</ng-template>

<ng-template #loginPage>
  <button nz-button nzBlock (click)="$event.stopPropagation(); onBackToLogin()">
    Retourner à la page de connexion
  </button>
</ng-template>

<ng-template #contactFormTitle>
  <app-header-title [icon]="'person'" [title]="'title'"></app-header-title>
</ng-template>

<ng-template #userFormTitle>
  <app-header-title
    [icon]="'person'"
    [title]="'Compte utilisateur'"
    [subtitle]="true"
  ></app-header-title>
</ng-template>
<ng-template #organisationFormTitle>
  <app-header-title
    [icon]="'corporate_fare'"
    [title]="'Compte organisation'"
    [subtitle]="true"
  ></app-header-title>
</ng-template>

<ng-template #loadingAnimation>
  <app-loading-animation [loading$]="loading$"></app-loading-animation>
</ng-template>
