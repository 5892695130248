<nz-card
  nzSize="small"
  nzHoverable
  nzBorderless
  [nzCover]="todoCardCoverTpl"
  (click)="$event.stopPropagation(); show__TODO(todo)"
>
</nz-card>

<ng-template #todoCardCoverTpl>
  <nz-card class="cardContent">
    <nz-row nzAlign="top" nzGutter="8" nzJustify="center">
      <nz-col nzXXl="6" nzXl="6" nzLg="6" nzXs="0" nzSm="0" nzMd="4">
        <app-todo-status [todo]="todo"></app-todo-status>
      </nz-col>
      <nz-col nzXXl="12" nzXl="12" nzLg="12" nzXs="24" nzSm="24" nzMd="20">
        <nz-statistic
          [nzValue]="todo?.sujets | isComplete : 'status' : true | size"
          [nzPrefix]="prefixTpl"
          [nzSuffix]="'/' + (todo?.sujets | size)"
          [nzValueStyle]="{ 'font-size': '16px' }"
        ></nz-statistic>
        <ng-template #prefixTpl
          ><i
            nz-icon
            nzType="file-done"
            class="iconSujet"
            [ngStyle]="{
              color:
                (todo?.sujets | isComplete : 'status' : true | size) ===
                (todo?.sujets | size)
                  ? green
                  : red
            }"
          ></i
        ></ng-template>
      </nz-col>
      <nz-col nzXXl="6" nzXl="6" nzLg="6" nzXs="0" nzSm="0" nzMd="0">
        <mat-icon
          [ngClass]="{
            nonActiv: !todo.allDay,
            activ: todo.allDay
          }"
          >today</mat-icon
        >
      </nz-col>
    </nz-row>
    <nz-space nzSize="middle"></nz-space>

    <nz-row nzAlign="top" nzGutter="8" nzJustify="center">
      <nz-col nzSpan="6">
        <mat-icon>date_range</mat-icon>
      </nz-col>
      <nz-col nzSpan="6">
        <small>{{ todo?.dateStart | dateFr : "small" }}</small>
      </nz-col>
      <nz-col nzSpan="6">
        <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
      </nz-col>

      <nz-col nzSpan="6">
        <small>{{ todo?.dateEnd | dateFr : "small" }}</small>
      </nz-col>
    </nz-row>
  </nz-card>
  <nz-space nzSize="small"></nz-space>

  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="2"></nz-col>
    <nz-col nzSpan="18">
      <nz-row nzAlign="top" nzJustify="start" nzGutter="2">
        <nz-col nzSpan="24">
          <h3 nz-typograph>{{ todo.title | carLimitation : 50 }}</h3>
        </nz-col>
      </nz-row>
      <nz-row nzAlign="middle" nzJustify="start" nzGutter="2">
        <nz-col nzSpan="24">
          <small
            >Échéance : {{ todo?.dateEnd | dateFr : "distanceSuffix" }}</small
          >
        </nz-col>
      </nz-row>
    </nz-col>
    <nz-col nzSpan="4">
      <button
        nz-button
        nzShape="circle"
        nz-dropdown
        nzSize="small"
        nzTrigger="click"
        (click)="$event.stopPropagation()"
        [nzDropdownMenu]="todoActionMenu"
        nzPlacement="bottomRight"
        nzType="text"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
    </nz-col>
  </nz-row>
</ng-template>

<ng-template #todoActions>
  <button
    nz-button
    nzShape="circle"
    nz-dropdown
    nzSize="small"
    nzTrigger="click"
    (click)="$event.stopPropagation()"
    [nzDropdownMenu]="todoActionMenu"
    nzPlacement="bottomRight"
    nzType="text"
  >
    <mat-icon>more_vert</mat-icon>
  </button>
</ng-template>

<nz-dropdown-menu #todoActionMenu="nzDropdownMenu">
  <ul nz-menu>
    <li nz-menu-item (click)="$event.stopPropagation(); complete_TODO(todo)">
      <i nz-icon nzType="check-circle" class="menuActionItem"></i>
      <span>Compléter</span>
    </li>

    <li nz-menu-divider></li>
    <li
      nz-menu-item
      nzDanger
      (click)="$event.stopPropagation()"
      nz-popconfirm
      (nzOnCancel)="onCancel()"
      (nzOnConfirm)="delete_TODO(todo)"
      nzTooltipTrigger="click"
      nzPopconfirmPlacement="bottomRight"
      nzPopconfirmTitle="Voulez-vous supprimer ?"
    >
      <i nz-icon nzType="delete" class="menuActionItem"></i>
      <span>Supprimer</span>
    </li>
  </ul>
</nz-dropdown-menu>

<ng-template #todoActionUpdate>
  <ng-container !todo?.completed && (user$ | async) as user>
    <button
      nz-button
      [disabled]="user.id === todo?.auteur?.id"
      (click)="complete_TODO(todo)"
    >
      Compléter
    </button>
  </ng-container>
</ng-template>
<ng-template #todoActionDelete>
  <button
    nz-button
    *ngIf="user.id === todo?.auteur?.id"
    nz-popconfirm
    nzPopconfirmTitle="Voulez-vous supprimer cette tâche ?"
    nzPopconfirmPlacement="bottom"
    (nzOnConfirm)="delete_TODO(todo)"
    (nzOnCancel)="onCancel()"
  >
    Supprimer
  </button>
</ng-template>
