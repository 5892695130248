import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-candidature-item-prescripteur',
  templateUrl: './candidature-item-prescripteur.component.html',
  styleUrls: ['./candidature-item-prescripteur.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CandidatureItemPrescripteurComponent implements OnInit {
  view$ = new BehaviorSubject<string>('display');

  @Input() candidat: any;
  @Output() update = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  /**
   * Changer l'affichage actuelle
   * @param {string } event - Type de vue
   */
  onChangeView(event: string): void {
    this.view$.next(event);
  }

  onUpdate(candidat: any): void {
    if (!candidat) return;
  }
}
