<nz-card [nzTitle]="diagnosticCardTitle">
  <nz-steps
    nzType="navigation"
    nzSize="small"
    [nzCurrent]="currentStep"
    (nzIndexChange)="onIndexChange($event)"
  >
    <nz-step
      nzTitle="Social"
      nzStatus="wait"
      nzDescription="Les freins sociaux identifiés"
    ></nz-step>
    <nz-step
      nzTitle="Professionnel"
      nzStatus="wait"
      nzDescription="Les freins professionnels identifiés"
    ></nz-step>
  </nz-steps>

  <div [ngSwitch]="currentStep">
    <div *ngSwitchCase="0">
      <nz-card nzSize="small">
        <h1>Freins sociaux</h1>
      </nz-card>
    </div>
    <div *ngSwitchCase="1">
      <nz-card nzSize="small">
        <h1>Freins professionnels</h1>
      </nz-card>
    </div>
    <div *ngSwitchDefault></div>
  </div>
</nz-card>
<ng-template #diagnosticCardTitle>
  <app-header-title [icon]="'summarize'"></app-header-title>
</ng-template>
