<nz-card [nzTitle]="taskFormCardTitle">
  <form nz-form [formGroup]="taskForm" nzLayout="vertical">
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="center">
      <nz-col nzSpan="24">
        <nz-form-item>
          <nz-form-control>
            <input
              type="text"
              nzBorderless
              nz-input
              placeholder="Tâche..."
              formControlName="title"
              (keydown.enter)="addTask()"
            />
          </nz-form-control>
        </nz-form-item>
      </nz-col>
    </nz-row>
    <nz-space nzDirection="vertical"></nz-space>
  </form>
  <nz-row nzAlign="top" nzGutter="8" nzJustify="center">
    <nz-col [nzSpan]="4">
      <app-header-title
        [icon]="'date_range'"
        [title]="''"
        [subtitle]="true"
      ></app-header-title>
    </nz-col>
    <nz-col [nzSpan]="14">
      <nz-form-item>
        <nz-form-control>
          <nz-range-picker
            [(ngModel)]="dates"
            (ngModelChange)="onChangeDates($event)"
            name="dates"
            nzBorderless
            [nzFormat]="datesFormat"
            [nzPlaceHolder]="datesPalceholders"
          ></nz-range-picker>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col [nzSpan]="2"></nz-col>
    <nz-col [nzSpan]="4">
      <form nz-form [formGroup]="taskForm">
        <nz-input-group nzSize="small" nzCompact>
          <nz-form-item nzSize="small" class="taskFormPriority">
            <nz-form-label>
              <i nz-icon nzType="flag" class="flag-icon"></i>
              <span>Priorité</span>
            </nz-form-label>
            <nz-form-control>
              <label
                nz-checkbox
                formControlName="priority"
                class="priority_checkbox"
              ></label>
            </nz-form-control>
          </nz-form-item>
        </nz-input-group>
      </form>
    </nz-col>
  </nz-row>
</nz-card>

<ng-template #taskFormCardTitle>
  <app-header-title
    [icon]="'title'"
    [title]="'Titre'"
    [subtitle]="true"
  ></app-header-title>
</ng-template>

<nz-space nzDirection="vertical" nzSize="small"></nz-space>

<nz-card [nzTitle]="taskFormCardStepTitle">
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="center">
    <nz-col [nzSpan]="textsArray.length ? 20 : 24">
      <nz-form-item>
        <nz-form-control>
          <input
            type="text"
            nz-input
            nzBorderless
            placeholder="Étape..."
            [(ngModel)]="textInput"
            (ngModelChange)="ngModelChangeText($event)"
            (keydown.enter)="addText()"
          />
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col [nzSpan]="textsArray.length ? 4 : 0">
      <nz-badge
        [nzCount]="textsArray.length"
        nzStandalone
        [nzStyle]="badgeCounterStyle"
      ></nz-badge>
    </nz-col>
  </nz-row>
  <nz-space nzDirection="vertical"></nz-space>

  <form nz-form [formGroup]="taskForm">
    <div
      formArrayName="texts"
      class="textsContainer"
      *ngIf="textsArray?.length"
    >
      <ul *ngFor="let item of textsArray.controls; let i = index">
        <li (click)="removeText(i)" ngDefaultControl>
          {{ item.value.value | carLimitation: 100 }}
        </li>
      </ul>
    </div>
  </form>
</nz-card>

<ng-template #taskFormCardStepTitle>
  <app-header-title
    [icon]="'toc'"
    [title]="'Micros tâches'"
    [subtitle]="true"
  ></app-header-title>
</ng-template>

<nz-space nzDirection="horizontal" nzSize="middle"></nz-space>

<nz-row nzAlign="middle" nzGutter="8" nzJustify="end">
  <nz-col nzSpan="16"></nz-col>

  <nz-col nzSpan="4">
    <button
      nz-button
      nzBlock
      nzType="text"
      (click)="$event.stopPropagation(); closeModal__OPERATION()"
    >
      Annuler
    </button>
  </nz-col>
  <nz-col nzSpan="4">
    <button
      nz-button
      [disabled]="!taskForm.valid"
      nzType="primary"
      nzBlock
      (click)="$event.stopPropagation(); addTask()"
    >
      Ajouter
    </button>
  </nz-col>
</nz-row>
