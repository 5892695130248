import { NzMessageService } from 'ng-zorro-antd/message';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { OperationCadrageService } from './../../../service/operation-cadrage.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromOperationCadrageAction from './operation-cadrage.actions';
import { of } from 'rxjs';

@Injectable()
export class OperationCadrageEffects {
  loadOperationCadrage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationCadrageAction.loadOperationCadrage),
      mergeMap((action) =>
        this.operationCadrage.getOne(action.operationId).pipe(
          map((cadrage: any) =>
            fromOperationCadrageAction.loadOperationCadrageSuccess({
              cadrage,
            })
          ),
          catchError((error) =>
            of(
              fromOperationCadrageAction.loadOperationCadrageFailure({
                error,
              })
            )
          )
        )
      )
    )
  );
  updateOperationCadrage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationCadrageAction.updateOperationCadrage),
      mergeMap((action) =>
        this.operationCadrage
          .updateOne(
            action.cadrage.id,
            action.cadrage.changes,
            action.operationId
          )
          .pipe(
            map((success: string) => {
              this.message.success(success, { nzDuration: 6000 });
              return fromOperationCadrageAction.updateOperationCadrageSuccess({
                success,
              });
            }),
            catchError((error) =>
              of(
                fromOperationCadrageAction.updateOperationCadrageFailure({
                  error,
                })
              )
            )
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private operationCadrage: OperationCadrageService,
    private message: NzMessageService
  ) {}
}
