import { createFeatureSelector, createSelector } from '@ngrx/store';
import { usersFeatureKey, UserState, selectAll } from './user.reducer';

export const selectUserState =
  createFeatureSelector<UserState>(usersFeatureKey);

export const users = createSelector(selectUserState, selectAll);

export const team = createSelector(
  selectUserState,
  (state: UserState) => state.team
);

export const user = createSelector(
  selectUserState,
  (state: UserState) => state.user
);

export const statistiques = createSelector(
  selectUserState,
  (state: UserState) => state.statistiques
);

export const favorisEntites = createSelector(
  selectUserState,
  (state: UserState) => state.favorisEntites
);

export const favorisContacts = createSelector(
  selectUserState,
  (state: UserState) => state.favorisContacts
);

export const favorisParticipants = createSelector(
  selectUserState,
  (state: UserState) => state.favorisParticipants
);

export const loading = createSelector(
  selectUserState,
  (state: UserState) => state.loading
);

export const civilities = createSelector(
  selectUserState,
  (state: UserState) => state.civilities
);

export const error = createSelector(
  selectUserState,
  (state: UserState) => state.error
);
