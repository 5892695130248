import { NzMessageService } from 'ng-zorro-antd/message';
import { Update } from '@ngrx/entity';
import { takeUntil } from 'rxjs/operators';
import { NzModalService } from 'ng-zorro-antd/modal';
import { DepartementState } from './../../store/departement.reducer';
import * as fromDepartementSelector from './../../store/departement.selectors';
import * as fromDepartementAction from './../../store/departement.actions';
import { of, Observable, Subject, BehaviorSubject } from 'rxjs';
import {
  Component,
  OnInit,
  ViewContainerRef,
  OnDestroy,
  Output,
  EventEmitter,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { DepartementId } from '../../store/departement.model';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';

import { Store } from '@ngrx/store';
import { UsersSelectComponent } from 'src/app/contents/components/users-select/users-select.component';

@Component({
  selector: 'app-departement-users',
  templateUrl: './departement-users.component.html',
  styleUrls: ['./departement-users.component.scss'],
})
export class DepartementUsersComponent implements OnInit, OnDestroy {
  users$: Observable<any> = of(null);
  user$: Observable<any> = of(null);
  view$ = new BehaviorSubject<string>('list');

  departement$: Observable<DepartementId | any> = of(null);
  newCardTitle: string = 'Collaborateur';
  titleUser: string = 'Nouveau collaborateur au service';
  newCardDescription: string = 'Ajouter un collaborateur à ce service';
  newCardDefinition: string =
    'Une entité est une organisation, une entreprise ou structure collaborant avec votre organisation pour vos projets ou accompagner des participants.';
  modalWidth: number = 650;
  subscribe = new Subject();
  @ViewChild('userFormTitle', { static: false }) userFormTitle:
    | TemplateRef<any>
    | any;

  @Output() select = new EventEmitter<any>(false);

  constructor(
    private userStore: Store<UserState>,
    private departementStore: Store<DepartementState>,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private message: NzMessageService
  ) {}

  ngOnInit(): void {
    this.getDepartement();
    this.getDepartementUsers();
    this.getUser();
  }
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getUser(): void {
    this.user$ = this.userStore.select(fromUserSelector.user);
  }

  getDepartement(): void {
    this.departement$ = this.departementStore.select(
      fromDepartementSelector.departement
    );
  }

  getDepartementUsers(): void {
    this.users$ = this.departementStore.select(fromDepartementSelector.users);
  }

  onNewUser(departement: DepartementId, user: any): void {
    if (!user || user?.claims.member) {
      const alertMessage: string =
        "Vous n'avez pas accès à cette fonctionnalité. Veuillez solliciter votre administrateur.";
      this.message.success(alertMessage, { nzDuration: 6000 });

      return;
    }
    const users = departement?.users?.length ? departement?.users : [];
    const managers = departement?.managers?.length ? departement?.managers : [];
    const allDepartementMembers = [...users, ...managers];

    const modal = this.modal.create({
      nzContent: UsersSelectComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.titleUser,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
    });

    const instance = modal.getContentComponent();
    instance.source = allDepartementMembers;
    instance.select.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        this.onAddUser(event, departement);
        modal.close();
      }
    });
    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
  }

  onSelect(user: any): void {
    this.select.emit(user);
  }
  onBack(): void {
    this.view$.next('list');
  }

  onAddUser(collaborateur: any, departement: DepartementId): void {
    if (!collaborateur || !departement) return;

    const isExist = departement?.users?.length
      ? departement.users.find((user) => user.id === collaborateur.id)
      : null;

    if (isExist) {
      const isExistMessage: string = `Le collaborateur est déjà membre de ce service`;
      this.message.info(isExistMessage, { nzDuration: 6000 });
    }

    const nextUsers = [...departement.users, collaborateur];
    const usersIds = nextUsers?.map((user) => user.id);

    const changes: DepartementId = {
      ...departement,
      users: nextUsers,
      ids: usersIds,
    };

    const nextDepartement: Update<DepartementId> = {
      id: departement.id,
      changes: { ...changes },
    };

    this.departementStore.dispatch(
      fromDepartementAction.updateDepartement({ departement: nextDepartement })
    );
  }

  onAddUserManager(collaborateur: any, departement: DepartementId): void {
    if (!collaborateur || !departement) return;

    const isExist = departement?.managers?.length
      ? departement.managers.find((user: any) => user.id === collaborateur.id)
      : null;

    if (isExist) {
      const isExistMessage: string = `Le collaborateur est déjà membre de ce service`;
      this.message.info(isExistMessage, { nzDuration: 6000 });
    }

    const nextUsers = [...departement.users, collaborateur];
    const nextManagers = [...departement?.managers, collaborateur];
    const usersIds = nextUsers?.map((user) => user.id);
    const managersIds = nextManagers?.map((user) => user.id);

    const changes: DepartementId = {
      ...departement,
      users: nextUsers,
      managers: nextManagers,
      ids: usersIds,
      managersIds: managersIds,
    };

    const nextDepartement: Update<DepartementId> = {
      id: departement.id,
      changes: { ...changes },
    };

    this.departementStore.dispatch(
      fromDepartementAction.updateDepartement({ departement: nextDepartement })
    );
  }

  onUserDelete(user: any, departement: DepartementId): void {
    if (!user || !departement) return;

    const nextUsers = departement.users.filter((user) => user.id !== user.id);
    const usersIds = nextUsers?.map((user) => user.id);
    const managers = departement?.managers?.filter(
      (manager: any) => manager.id !== user.id
    );
    const managersIds = managers?.map((manager: any) => manager.id);
    const users = nextUsers.map((user) => user.id);
    const changes: DepartementId = {
      ...departement,
      users: users,
      ids: usersIds,
      managers: managers,
      managersIds: managersIds,
    };

    const nextDepartement: Update<DepartementId> = {
      id: departement.id,
      changes: { ...changes },
    };

    this.departementStore.dispatch(
      fromDepartementAction.updateDepartement({ departement: nextDepartement })
    );
  }

  onCancelUser(): void {}
}
