import { NoResultModule } from './../no-result/no-result.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContratComponent } from './components/contrat/contrat.component';
import { ZorroModule } from 'src/app/css/zorro.module';
import { StoreModule } from '@ngrx/store';
import * as fromContrat from './store/contrat.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ContratEffects } from './store/contrat.effects';
import { SharedModule } from 'src/app/shared/shared.module';
import { ContratItemComponent } from './components/contrat-item/contrat-item.component';
import { ContratCardComponent } from './components/contrat-card/contrat-card.component';
import { ContentsModule } from '../../contents.module';
import { ContratService } from './services/contrat.service';
import { ContratFormTravailComponent } from './components/contrat-form-travail/contrat-form-travail.component';
import { ContratFormEvolutionComponent } from './components/contrat-form-evolution/contrat-form-evolution.component';
import { ContratFormDetailComponent } from './components/contrat-form-detail/contrat-form-detail.component';
import { ContratFormSalaireComponent } from './components/contrat-form-salaire/contrat-form-salaire.component';
import { HeaderModule } from '../header-title/header.module';

@NgModule({
  declarations: [
    ContratComponent,
    ContratItemComponent,
    ContratCardComponent,
    ContratFormTravailComponent,
    ContratFormEvolutionComponent,
    ContratFormDetailComponent,
    ContratFormSalaireComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ZorroModule,
    ContentsModule,
    NoResultModule,
    HeaderModule,
    StoreModule.forFeature(fromContrat.contratsFeatureKey, fromContrat.reducer),
    EffectsModule.forFeature([ContratEffects]),
  ],
  exports: [
    ContratComponent,
    ContratItemComponent,
    ContratCardComponent,
    ContratFormTravailComponent,
    ContratFormEvolutionComponent,
    ContratFormDetailComponent,
    ContratFormSalaireComponent,
  ],

  providers: [ContratService],
})
export class ContratModule {}
