<ng-container>
  <app-item-operations
    *ngIf="entite$ | async as entite"
    [operations$]="operations$"
    [identity]="identity"
    [role]="true"
    (add)="onAdd($event, entite)"
    (update)="onUpdate($event, entite)"
    (delete)="onDelete($event, entite)"
  ></app-item-operations>
</ng-container>
