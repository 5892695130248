import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-information-collective-filter',
  templateUrl: './information-collective-filter.component.html',
  styleUrls: ['./information-collective-filter.component.scss'],
})
export class InformationCollectiveFilterComponent implements OnInit {
  cleaner: boolean = false;
  informationsCollectives$: Observable<any> = of(null);

  constructor() {}

  ngOnInit(): void {}
}
