<nz-page-header
  class="page-header"
  [nzTitle]="title$ | async"
  [nzSubtitle]="headerExtra"
  *ngIf="contact?.appelationsArray | size"
>
  <nz-page-header-extra>
    <!-- <button
      *ngIf="contact?.appelationsArray | size"
      nz-button
      nzType="text"
      (click)="$event.stopPropagation()"
      (click)="onSelectTab('Suggestions', 'nouveau')"
      class="btnNew"
    >
      <h5 nz-typography>Nouveau</h5>
    </button>
    <button
      nz-button
      nz-dropdown
      [nzDropdownMenu]="menuListActions"
      nzPlacement="bottomRight"
      nzTrigger="click"
      nzShape="circle"
    >
      <i nz-icon nzType="more"></i>
    </button> -->
  </nz-page-header-extra>
  <nz-page-header-content> </nz-page-header-content>

  <nz-dropdown-menu #menuListActions="nzDropdownMenu">
    <ul nz-menu>
      <li
        nz-menu-item
        (click)="$event.stopPropagation(); onSelectTab('Grands domaines', '')"
      >
        Grands domaines
      </li>
      <li
        nz-menu-item
        (click)="$event.stopPropagation(); onSelectTab('Domaines', '')"
      >
        Domaines
      </li>
      <li
        nz-menu-item
        (click)="$event.stopPropagation(); onSelectTab('Métiers', '')"
      >
        Métiers
      </li>
      <li
        nz-menu-item
        (click)="$event.stopPropagation(); onSelectTab('Appelations', '')"
      >
        Appelations
      </li>
    </ul>
  </nz-dropdown-menu>
</nz-page-header>

<ng-template #headerExtra>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="24">
      <nz-badge
        [nzCount]="
          (title$ | async) === 'Appelations'
            ? (contact?.appelationsArray | size)
            : (title$ | async) === 'Métiers'
            ? (contact?.metiersArray | size)
            : (title$ | async) === 'Domaines'
            ? (contact?.metiersDomainesArray | size)
            : (title$ | async) === 'Grands domaines'
            ? (contact?.metiersGrandDomainesArray | size)
            : 0
        "
        [nzOverflowCount]="99"
      >
      </nz-badge>
    </nz-col>
  </nz-row>
</ng-template>

<nz-divider></nz-divider>

<div
  *ngIf="(tab$ | async) === 'Suggestions' && (type$ | async) === 'nouveau'"
  class="metierFormContainer"
>
  <app-metiers
    [type]="'grandDomaine'"
    [showMetiers]="true"
    [showAppelations]="true"
    [hideCancel]="true"
    [hideValidation]="true"
    (validation)="onUpdate($event, contact)"
    (cancel)="onSelectTab('Appelations', '')"
  ></app-metiers>
</div>

<ng-container *ngIf="(tab$ | async) === 'Appelations'">
  <app-layout-list
    *ngIf="contact?.appelationsArray | size; else noResults"
    [new]="new"
    [card]="card"
    [source]="contact?.appelationsArray"
  ></app-layout-list>
</ng-container>

<ng-container *ngIf="(tab$ | async) === 'Métiers'">
  <app-layout-list
    *ngIf="contact?.metiersArray | size; else noResults"
    [new]="new"
    [card]="card"
    [source]="contact?.metiersArray"
  ></app-layout-list>
</ng-container>

<ng-container *ngIf="(tab$ | async) === 'Domaines'">
  <app-layout-list
    *ngIf="contact?.metiersDomainesArray | size; else noResults"
    [new]="new"
    [card]="card"
    [source]="contact?.metiersDomainesArray"
  ></app-layout-list>
</ng-container>

<ng-container *ngIf="(tab$ | async) === 'Grands domaines'">
  <app-layout-list
    *ngIf="contact?.metiersGrandDomainesArray | size; else noResults"
    [new]="new"
    [card]="card"
    [source]="contact?.metiersGrandDomainesArray"
  ></app-layout-list>
</ng-container>

<ng-template #new>
  <app-card-new
    [type]="'business'"
    [height]="'250px'"
    [title]="newCardTitle"
    [description]="newCardDescription"
    [definition]="''"
    (add)="onSelectTab('Suggestions', 'nouveau')"
  ></app-card-new>
</ng-template>

<ng-template #card let-item let-position="idx">
  <nz-card
    nzHoverable
    class="cardMetier"
    [nzTitle]="cardTtitleTag"
    [nzExtra]="cardActionBtn"
  >
    <nz-row
      nzAlign="middle"
      nzGutter="8"
      nzJustify="start"
      class="cardMetierLibelle"
    >
      <nz-col nzSpan="24">
        <h5 nz-typography>{{ item | carLimitation: 100 }}</h5>
      </nz-col>
    </nz-row>
  </nz-card>

  <ng-template #cardTtitleTag>
    <app-header-title
      [icon]="'business_center'"
      class="avatarCardHeader"
      [height]="'35px'"
      [width]="'35px'"
      [left]="'-4px'"
    ></app-header-title>
  </ng-template>

  <ng-template #cardActionBtn>
    <button
      nz-button
      nzShape="circle"
      nz-dropdown
      [nzDropdownMenu]="menuCardActions"
      nzTrigger="click"
      nzPlacement="bottomRight"
      nzType="text"
    >
      <i nz-icon nzType="more"></i>
    </button>
  </ng-template>

  <nz-dropdown-menu #menuCardActions="nzDropdownMenu">
    <ul nz-menu>
      <li nz-menu-item nzDanger (click)="onDelete(item, contact)">
        <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
          <nz-col nzSpan="4"><i nz-icon nzType="delete"></i></nz-col>
          <nz-col nzSpan="2"></nz-col>
          <nz-col nzSpan="18">Supprimer</nz-col>
        </nz-row>
      </li>
    </ul>
  </nz-dropdown-menu>
</ng-template>

<ng-template #noResults>
  <app-no-result
    [icon]="'business_center'"
    [notitle]="'Aucun métier'"
    [addtitle]="'Nouveau'"
    (click)="$event.stopPropagation()"
    (click)="onSelectTab('Suggestions', 'nouveau')"
  ></app-no-result>
</ng-template>
