import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent implements OnInit, OnChanges {
  @Input() loading: boolean = false;

  @Input() source: any[] = [];
  @Input() tableHeader: TemplateRef<any>;
  @Input() tableRow: TemplateRef<any>;
  @Input() height: string = 'calc(100vh - 8em)';

  @Input() description: string = '';
  @Output() select = new EventEmitter<any>(false);
  constructor() {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {}

  trackByFn(index: number, item: any) {
    return index;
  }

  onSelect(item: any): void {
    this.select.emit(item);
  }
}
