import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, Subject, takeUntil } from 'rxjs';
import { ParticipantId } from 'src/app/components/participant/store/participant.model';
import { ParticipantSuiviState } from '../../store/participant-suivi.reducer';
import * as fromParticipantSuiviAction from '../../store/participant-suivi.actions';
import * as fromParticipantSuiviSelector from '../../store/participant-suivi.selectors';
import { Update } from '@ngrx/entity';
import { ActivatedRoute, Router } from '@angular/router';
import { ParticipantState } from './../../../participant/store/participant.reducer';
import * as fromParticipantSelector from './../../../participant/store/participant.selectors';

@Component({
  selector: 'app-participant-suivi',
  templateUrl: './participant-suivi.component.html',
  styleUrls: ['./participant-suivi.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantSuiviComponent implements OnInit, OnDestroy, OnChanges {
  participant$: Observable<ParticipantId | any> = of(null);
  subscribe = new Subject();
  id: string = '';
  data$: Observable<any> = of(null);
  suivis$: Observable<any> = of([]);

  constructor(
    private participantSuiviStore: Store<ParticipantSuiviState>,
    private router: Router,
    private activateRouter: ActivatedRoute,
    private participantStore: Store<ParticipantState>
  ) {}

  ngOnInit(): void {
    this.getRouteData();
    this.getParticipant();
    this.getSuivis();
  }
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }
  ngOnChanges(changes: SimpleChanges): void {}
  getRouteData(): void {
    this.data$ = this.activateRouter.data;
  }

  getParticipant(): void {
    this.participant$ = this.participantStore.select(
      fromParticipantSelector.participant
    );
  }

  getSuivis(): void {
    this.suivis$ = this.participantSuiviStore.select(
      fromParticipantSuiviSelector.suivis
    );
  }

  onAdd(suivi: any, participant: ParticipantId): void {
    if (!suivi || !participant) return;
    const { id } = participant;

    const newSuivi = {
      dateStart: suivi.dateStart,
      hourStart: suivi.hourStart,
      object: suivi.object,
      title: suivi.title,
      type: suivi.type,
      text: suivi.text,
      textHtml: suivi.textHtml,
    };

    this.participantSuiviStore.dispatch(
      fromParticipantSuiviAction.addParticipantSuivi({ id, suivi: newSuivi })
    );
  }
  onUpdate(suivi: any, participant: ParticipantId): void {
    if (!suivi || !participant) return;
    const { id } = participant;

    const nextSuivi: Update<any> = {
      id: suivi.id,
      changes: {
        id: suivi.id,
        dateStart: suivi.dateStart,
        hourStart: suivi.hourStart,
        object: suivi.object,
        title: suivi.title,
        type: suivi.type,
        text: suivi.text,
        textHtml: suivi.textHtml,
      },
    };

    this.participantSuiviStore.dispatch(
      fromParticipantSuiviAction.updateParticipantSuivi({
        id,
        suivi: nextSuivi,
      })
    );
  }
  onDelete(suivi: any, participant: ParticipantId): void {
    if (!suivi || !participant) return;
    const { id } = participant;
    this.participantSuiviStore.dispatch(
      fromParticipantSuiviAction.deleteParticipantSuivi({
        participantId: id,
        id: suivi.id,
      })
    );
  }

  onDeleteAll(participant: ParticipantId): void {
    if (!participant) return;
    const { id } = participant;

    this.participantSuiviStore.dispatch(
      fromParticipantSuiviAction.deleteParticipantSuivis({ id, ids: [] })
    );
  }
}
