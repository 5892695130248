import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Todo, TodoId } from './todo.model';

export const loadTodosLoading = createAction(
  '[Todo/COMPONENT] Load Todos loading',
  props<{ event: boolean }>()
);

//LOAD TODOS
export const loadTodos = createAction(
  '[Todo/COMPONENT] Load Todos',
  props<{ completed: boolean }>()
);
export const loadTodosSuccess = createAction(
  '[Todo/API] Load Todos Success',
  props<{ todos: TodoId[] }>()
);
export const loadTodosFailure = createAction(
  '[Todo/API] Load Todos Failure',
  props<{ error: any }>()
);

//LOAD COMPLETED TODOS
export const loadCompletedTodos = createAction(
  '[Todo/COMPONENT] Load Completed Todos'
);
export const loadCompletedTodosSuccess = createAction(
  '[Todo/API] Load Completed Todos Success',
  props<{ todos: TodoId[] }>()
);
export const loadCompletedTodosFailure = createAction(
  '[Todo/API] Load Completed Todos Failure',
  props<{ error: any }>()
);

//LOAD TODO
export const loadTodo = createAction(
  '[Todo/COMPONENT] Load Todo',
  props<{ todo: any }>()
);
export const loadTodoSuccess = createAction(
  '[Todo/API] Load Todo Success',
  props<{ todo: TodoId }>()
);
export const loadTodoFailure = createAction(
  '[Todo/API] Load Todo Failure',
  props<{ error: any }>()
);

//ADD
export const addTodo = createAction(
  '[Todo/COMPONENT] Add Todo',
  props<{ todo: Todo | any }>()
);

export const addTodoAdding = createAction(
  '[Todo/API] Add Todo Addding',
  props<{ event: boolean }>()
);

export const addTodoSuccess = createAction(
  '[Todo/API] Add Todo Success',
  props<{ todo: TodoId | any }>()
);

export const addTodoFailure = createAction(
  '[Todo/API] Add Todo Failure',
  props<{ error: any }>()
);

//COMPLETED
export const completedTodo = createAction(
  '[Todo/COMPONENT] completed Todo',
  props<{ todo: Update<TodoId | any> }>()
);

export const completedTodoSuccess = createAction(
  '[Todo/API] completed Todo Success'
);

export const completedTodoFailure = createAction(
  '[Todo/API] completed Todo Fialure',
  props<{ error: any }>()
);

//UPDATE
export const updateTodo = createAction(
  '[Todo/COMPONENT] Update Todo',
  props<{ todo: Update<TodoId | any> }>()
);

export const updateTodoSuccess = createAction(
  '[Todo/API] Update Todo Success',
  props<{ todo: TodoId }>()
);

export const updateTodoFailure = createAction(
  '[Todo/API] Update Todo Fialure',
  props<{ error: any }>()
);

export const loadFileProgress = createAction(
  '[Todo/COMPONENT] Load File Progression Todo',
  props<{ progress: number }>()
);

//DELETE
export const deleteTodo = createAction(
  '[Todo/COMPONENT] Delete Todo',
  props<{ id: string }>()
);

export const deleteTodoSuccess = createAction(
  '[Todo/API] Delete Todo Success',
  props<{ success: any }>()
);

export const deleteTodoFailure = createAction(
  '[Todo/API] Delete Todo Failure',
  props<{ error: any }>()
);

export const updateTodos = createAction(
  '[Todo/API] Update Todos',
  props<{ todos: Update<Todo | any>[] }>()
);

//DELETE ALL
export const deleteTodos = createAction('[Todo/COMPONENT] Delete Todos');

export const deleteTodosSuccess = createAction(
  '[Todo/API] Delete Todos Success',
  props<{ success: any }>()
);

export const deleteTodosFailure = createAction(
  '[Todo/API] Delete Todos Failure',
  props<{ error: any }>()
);

//DELETE ALL COMPLETED
export const deleteCompletedTodos = createAction(
  '[Todo/COMPONENT] Delete Completetd Todos'
);

export const deleteCompletedTodosSuccess = createAction(
  '[Todo/API] Delete Completetd Todos Success',
  props<{ success: any }>()
);

export const deleteCompletedTodosFailure = createAction(
  '[Todo/API] Delete Completetd Todos Failure',
  props<{ error: any }>()
);

//COMPLETED ALL
export const completedTodos = createAction('[Todo/COMPONENT] Completed Todos');

export const completedTodosSuccess = createAction(
  '[Todo/API] Completed Todos Success',
  props<{ success: any }>()
);

export const completedTodosFailure = createAction(
  '[Todo/API] Completed Todos Failure',
  props<{ error: any }>()
);

export const clearTodo = createAction('[Todo/COMPONENT] Clear Todo');

export const clearTodos = createAction('[Todo/COMPONENT] Clear Todos');
