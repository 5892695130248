import { Pipe, PipeTransform } from '@angular/core';
import { differenceInYears, fromUnixTime } from 'date-fns';
import { isDate } from 'lodash';

@Pipe({
  name: 'birthday',
})
export class BirthdayPipe implements PipeTransform {
  today = new Date(Date.now());
  transform(value: any): string {
    if (!value) {
      return '';
    } else {
      if (isDate(value)) {
        const birthday: number = differenceInYears(this.today, value);
        return birthday.toString();
      } else {
        const birthday: number = differenceInYears(
          this.today,
          fromUnixTime(value['seconds'])
        );
        return birthday.toString();
        return '';
      }
    }
  }
}
