import { ActionFormComponent } from './../action-form/action-form.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ActionSmallId } from './../../store/action.model';
import { OperationId } from './../../../operation/store/operation.model';
import { Observable, of, Subject, BehaviorSubject } from 'rxjs';
import { Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActionState } from '../../store/action.reducer';
import * as fromActionAction from '../../store/action.actions';
import * as fromActionSelector from '../../store/action.selectors';
import { Action } from '../../store/action.model';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';
import {
  NzNotificationPlacement,
  NzNotificationService,
} from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-action-filters',
  template: `
    <nz-row
      nzAlign="middle"
      nzGutter="4"
      nzJustify="center"
      *ngIf="operation$ | async as operation"
    >
      <nz-col nzSpan="6" class="filterTitle">
        <nz-badge [nzCount]="actions$ | async | size" [nzOffset]="[8, 10]">
          <app-header-title
            [icon]="''"
            [title]="operation?.type | operationType: 'action'"
            [subtitle]="true"
          ></app-header-title>
        </nz-badge>
      </nz-col>
      <nz-col nzSpan="14" class="inputSearch">
        <input
          nz-input
          [(ngModel)]="inputModel"
          (ngModelChange)="onSeach($event, operation)"
          nzBorderless
          [placeholder]="'Rechercher par la dénomination'"
        />
      </nz-col>
      <nz-col nzSpan="1"></nz-col>
      <nz-col nzSpan="1">
        <button
          nz-button
          nzShape="circle"
          nzType="text"
          nz-tooltip
          [nzTooltipTitle]="
            operation?.type !== 'Clauses sociales'
              ? 'Filtrer les actions'
              : 'Filtrer les corps détat'
          "
          nzTooltipPlacement="bottom"
          [nzTooltipMouseEnterDelay]="0.5"
          nzTooltipPlacement="bottom"
          class="btnFilter"
        >
          <i nz-icon nzType="filter"></i>
        </button>
      </nz-col>

      <nz-col nzSpan="2">
        <button nz-button nzType="text" nzShape="circle">
          <app-button-dropdown
            [isDeleteAll]="true"
            [deleteAllType]="deleteAllType"
            (select)="onAction($event, operation)"
          ></app-button-dropdown>
        </button>
      </nz-col>
    </nz-row>
  `,
  styles: [
    `
      .filterTitle {
        display: flex;
        flex-direction: row;
        justify-content: center;
        justify-items: center;
        margin-top: 10px;
      }

      .addBtn {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        justify-items: flex-end;
        margin-top: -10px;
      }

      .btnFilter {
        margin-top: -10px;
      }

      .inputSearch {
        border-radius: 12px;
        background-color: #f5f5f5;
      }
    `,
  ],
})
export class ActionFiltersComponent implements OnInit {
  buttonColor: string = '#597ef7';
  modalWidth: number = 950;
  deleteAllType: string = 'Voulez-vous tous supprimer ?';
  subscribe = new Subject();
  inputSearch$ = new BehaviorSubject<string>('');
  inputModel: string = '';
  user$: Observable<any> = of(null);
  NOTIFICATION_PLACEMENT: NzNotificationPlacement = 'topRight';

  @Input() operation$: Observable<OperationId | any> = of();
  @Input() actions$: Observable<ActionSmallId[] | any> = of([]);
  constructor(
    private actionStore: Store<ActionState>,
    private userStore: Store<UserState>,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private notification: NzNotificationService
  ) {}

  ngOnInit(): void {
    this.getUser();
  }

  onSeach(event: string, operation: OperationId): void {
    if (!event) {
      this.actionStore.dispatch(
        fromActionAction.loadActions({ operationId: operation.id })
      );
      return;
    } else {
      const filter = {
        operationId: operation.id,
        event: event,
        type: 'title',
      };
      this.actionStore.dispatch(fromActionAction.queryActions({ filter }));
    }
  }

  getUser(): void {
    this.user$ = this.userStore.select(fromUserSelector.user);
  }

  onAction(event: string, operation: any): void {
    if (!event || !operation) return;

    switch (event) {
      case 'deleteAll':
        this.actionStore.dispatch(
          fromActionAction.deleteActions({ ids: [], operationId: operation.id })
        );
        break;

      default:
        break;
    }
  }

  onClearMdole(): void {}
}
