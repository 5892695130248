import {
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from '@angular/core';

@Directive({
  selector: '[appFileDrop]',
})
export class FileDropDirective {
  @Output() filesDropped = new EventEmitter<FileList>();
  @Output() filesHovered = new EventEmitter();

  constructor() {}

  @HostListener('drop', ['$event'])
  onDrop($event: Event | any) {
    $event.preventDefault();

    let transfer = $event?.dataTransfer;
    this.filesDropped.emit(transfer.files);
    this.filesHovered.emit(true);
  }

  @HostListener('dragover', ['$event'])
  onDragOver($event: Event | any) {
    $event.preventDefault();
    this.filesHovered.emit(true);
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave($event: Event | any) {
    $event.preventDefault();
    this.filesHovered.emit(false);
  }
}
