import { ActionId } from 'src/app/components/action/store/action.model';
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import {
  NzTableFilterFn,
  NzTableFilterList,
  NzTableSortFn,
  NzTableSortOrder,
} from 'ng-zorro-antd/table';
import { Observable, of, Subject, takeUntil } from 'rxjs';

interface ColumnItem {
  name: string;
  sortOrder: NzTableSortOrder | null;
  sortFn: NzTableSortFn<ActionId> | null;
  listOfFilter: NzTableFilterList;
  filterFn: NzTableFilterFn<ActionId> | null;
  filterMultiple: boolean;
  sortDirections: NzTableSortOrder[] | any;
  width?: string | any;
}
@Component({
  selector: 'app-table-actions',
  templateUrl: './table-actions.component.html',
  styleUrls: ['./table-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableActionsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() source$: Observable<ActionId[] | any> = of([]);
  @Input() icon: string = 'dashboard';
  @Input() noDataTitle: string = 'Aucun élément trouvé';
  @Input() addItemTitle: string = 'Ajouté un nouvel élément';
  @Input() scroll: any = { x: '1100px', y: '100%' };

  @Output() select = new EventEmitter(false);
  @Output() add = new EventEmitter(false);
  @Output() update = new EventEmitter(false);
  @Output() delete = new EventEmitter(false);
  @Output() export = new EventEmitter(false);
  subscribe = new Subject();

  listOfColumns: ColumnItem[] = [];

  //FILTERS
  filterByStatus: any = [
    { text: 'En cours', value: false },
    { text: 'Terminée', value: true },
  ];
  filterByStatusFn = (list: string[], item: any) =>
    list.some((status) => item.isFinished === status);

  filterByEntite: any = [];
  filterByEntiteFn = (list: string[], item: any) =>
    list.some(
      (denomination) =>
        item?.entite?.etablissement?.denomination.indexOf(denomination) !== -1
    );

  filterByEmployeur: any = [];
  filterByEmployeurFn = (list: string[], item: any) =>
    list.some((name) => item.name.indexOf(name) !== -1);

  filterByContrats: any = [];
  filterByContratsFn = (list: string[], item: any) =>
    list.some((contrat) => item.type_contrat?.CODE.indexOf(contrat) !== -1);

  //SORTS
  sortFnByMetier = (a: any, b: any) =>
    a.metier?.metier?.libelle.localeCompare(b.metier?.metier?.libelle);
  sortFnByEntite = (a: any, b: any) =>
    a?.entite?.etablissement?.denomination.localeCompare(
      b.entite?.etablissement?.denomination
    );
  sortFnByTitle = (a: any, b: any) => a?.title.localeCompare(b.title);

  sortFnByRealisation = (a: any, b: any) => a.realisations - b.realisations;
  sortFnByObjectif = (a: any, b: any) => a.objectifs - b.objectifs;
  sortFnByProgression = (a: any, b: any) => a.progression - b.progression;

  constructor() {}

  ngOnInit(): void {
    this.onSetFilter();
  }

  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {
    this.subscribe.next(false);
    this.subscribe.complete();
  }
  onSetFilter(): void {
    this.source$.pipe(takeUntil(this.subscribe)).subscribe((source) => {
      const titleFilters = source?.lenght
        ? source.map((el: any) => {
            const { title } = el;
            return { text: title, value: title };
          })
        : [];
      this.listOfColumns = [
        {
          name: 'Titre',
          sortOrder: null,
          sortFn: (a: ActionId, b: ActionId) => a.title.localeCompare(b.title),
          sortDirections: ['ascend', 'descend', null],
          filterMultiple: true,
          listOfFilter: titleFilters,
          filterFn: (list: string[], item: ActionId) =>
            list.some((title) => item.title.indexOf(title) !== -1),
        },
        {
          name: 'Collaboration',
          sortOrder: null,
          sortFn: (a: any, b: any) => a?.collaboration - b?.progression,
          sortDirections: ['ascend', 'descend', null],
          filterMultiple: false,
          listOfFilter: [],
          filterFn: null,
        },
        {
          name: 'Démarrage',
          sortOrder: null,
          sortFn: null,
          sortDirections: ['ascend', 'descend', null],
          filterMultiple: true,
          listOfFilter: [],
          filterFn: null,
        },
        {
          name: 'Participants',
          sortOrder: null,
          sortFn: (a: ActionId, b: ActionId) =>
            a?.participants?.length - b?.participants?.length,
          sortDirections: ['ascend', 'descend', null],
          filterMultiple: true,
          listOfFilter: [],
          filterFn: null,
        },
        {
          name: 'Objectif',
          sortOrder: null,
          sortFn: (a: ActionId, b: ActionId) => a?.objectifs - b?.objectifs,
          sortDirections: ['ascend', 'descend', null],
          filterMultiple: true,
          listOfFilter: [],
          filterFn: null,
        },
        {
          name: 'Réalisation',
          sortOrder: null,
          sortFn: (a: ActionId, b: ActionId) =>
            a?.realisations - b?.realisations,
          sortDirections: ['ascend', 'descend', null],
          filterMultiple: true,
          listOfFilter: [],
          filterFn: null,
        },

        {
          name: '%',
          sortOrder: null,
          sortFn: (a: any, b: any) => a?.progression - b?.progression,
          sortDirections: ['ascend', 'descend', null],
          filterMultiple: true,
          listOfFilter: [],
          filterFn: null,
        },

        {
          name: '',
          sortOrder: null,
          sortFn: null,
          sortDirections: [],
          filterMultiple: false,
          listOfFilter: [],
          filterFn: null,
        },
      ];
    });
  }

  onSelect(item: any): void {
    if (!item) return;
    this.select.emit(item);
  }

  onNew(): void {
    this.add.emit(true);
  }

  onSelectDropdown(event: string, item: any): void {
    if (!event || !item) return;

    switch (event) {
      case 'delete':
        this.delete.emit(item);

        break;

      default:
        break;
    }
  }
}
