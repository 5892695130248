import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import {
  AccompagnementChampsType,
  ParticipantActionAccompagnement,
} from './participant-action-accompagnement.model';

//TYPE
export const loadParticipantAccompagnementTypes = createAction(
  '[ParticipantActionAccompagnement/API] Load ParticipantActionAccompagnements Types'
);

export const loadParticipantAccompagnementTypesSuccess = createAction(
  '[ParticipantActionAccompagnement/API] Load ParticipantActionAccompagnements Types Success',
  props<{ types: string[] }>()
);
export const loadParticipantAccompagnementTypesFailure = createAction(
  '[ParticipantActionAccompagnement/API] Load ParticipantActionAccompagnements Types Failure',
  props<{ error: any }>()
);

//CHAMPS
export const loadParticipantAccompagnementChampsTypes = createAction(
  '[ParticipantActionAccompagnement/API] Load ParticipantActionAccompagnements Champs Types'
);

export const loadParticipantAccompagnementChampsTypesSuccess = createAction(
  '[ParticipantActionAccompagnement/API] Load ParticipantActionAccompagnements Champs Types Success',
  props<{ champs: AccompagnementChampsType[] }>()
);
export const loadParticipantAccompagnementChampsTypesFailure = createAction(
  '[ParticipantActionAccompagnement/API] Load ParticipantActionAccompagnements Champs Types Failure',
  props<{ error: any }>()
);

//ACCOMPAGNEMENTS
export const loadParticipantActionAccompagnements = createAction(
  '[ParticipantActionAccompagnement/API] Load ParticipantActionAccompagnements',
  props<{ id: string }>()
);

export const loadParticipantActionAccompagnementsSuccess = createAction(
  '[ParticipantActionAccompagnement/API] Load ParticipantActionAccompagnements Success',
  props<{ accompagnements: any[] }>()
);
export const loadParticipantActionAccompagnementsFailure = createAction(
  '[ParticipantActionAccompagnement/API] Load ParticipantActionAccompagnements Failure',
  props<{ error: any }>()
);

//ACCOMPAGNEMENT
export const loadParticipantActionAccompagnement = createAction(
  '[ParticipantActionAccompagnement/API] Load ParticipantActionAccompagnement',
  props<{ participantId: string; id: string }>()
);

export const loadParticipantActionAccompagnementSuccess = createAction(
  '[ParticipantActionAccompagnement/API] Load ParticipantActionAccompagnement Success',
  props<{ accompagnement: any }>()
);
export const loadParticipantActionAccompagnementFailure = createAction(
  '[ParticipantActionAccompagnement/API] Load ParticipantActionAccompagnement Failure',
  props<{ error: any }>()
);

// CREATE ACCOMPAGNEMENT
export const addParticipantActionAccompagnement = createAction(
  '[ParticipantActionAccompagnement/API] Add ParticipantActionAccompagnement',
  props<{
    participantId: string;
    accompagnement: any;
  }>()
);
export const addParticipantActionAccompagnementSuccess = createAction(
  '[ParticipantActionAccompagnement/API] Add ParticipantActionAccompagnement Success',
  props<{ accompagnement: any }>()
);
export const addParticipantActionAccompagnementFailure = createAction(
  '[ParticipantActionAccompagnement/API] Add ParticipantActionAccompagnement Failure',
  props<{ error: any }>()
);

// COPY ACCOMPAGNEMENT
export const copyParticipantActionAccompagnement = createAction(
  '[ParticipantActionAccompagnement/API] Copy ParticipantActionAccompagnement',
  props<{ participantId: string; id: any }>()
);
export const copyParticipantActionAccompagnementSuccess = createAction(
  '[ParticipantActionAccompagnement/API] Copy ParticipantActionAccompagnement Success',
  props<{ accompagnement: any }>()
);
export const copyParticipantActionAccompagnementFailure = createAction(
  '[ParticipantActionAccompagnement/API] Copy ParticipantActionAccompagnement Failure',
  props<{ error: any }>()
);

//UPDATE ACCOMPAGNEMENT
export const updateParticipantActionAccompagnement = createAction(
  '[ParticipantActionAccompagnement/API] Update ParticipantActionAccompagnement',
  props<{
    participantId: string;
    accompagnement: Update<any>;
  }>()
);
export const updateParticipantActionAccompagnementSuccess = createAction(
  '[ParticipantActionAccompagnement/API] Update ParticipantActionAccompagnement Success',
  props<{
    accompagnement: any;
  }>()
);
export const updateParticipantActionAccompagnementFailure = createAction(
  '[ParticipantActionAccompagnement/API] Update ParticipantActionAccompagnement Failure',
  props<{
    error: any;
  }>()
);

//DELETE ACCOMPAGNEMENT
export const deleteParticipantActionAccompagnement = createAction(
  '[ParticipantActionAccompagnement/API] Delete ParticipantActionAccompagnement',
  props<{ participantId: string; id: string }>()
);
export const deleteParticipantActionAccompagnementSuccess = createAction(
  '[ParticipantActionAccompagnement/API] Delete ParticipantActionAccompagnement Success',
  props<{ accompagnements: any[] }>()
);
export const deleteParticipantActionAccompagnementFailure = createAction(
  '[ParticipantActionAccompagnement/API] Delete ParticipantActionAccompagnement Failure',
  props<{ error: any }>()
);

//DELETE ACCOMPAGNEMENTS
export const deleteParticipantActionAccompagnements = createAction(
  '[ParticipantActionAccompagnement/API] Delete ParticipantActionAccompagnements',
  props<{ participantId: string }>()
);
export const deleteParticipantActionAccompagnementsSuccess = createAction(
  '[ParticipantActionAccompagnement/API] Delete ParticipantActionAccompagnements Success',
  props<{ success: string }>()
);

export const deleteParticipantActionAccompagnementsFailure = createAction(
  '[ParticipantActionAccompagnement/API] Delete ParticipantActionAccompagnements Failure',
  props<{ error: any }>()
);

export const clearParticipantActionAccompagnements = createAction(
  '[ParticipantActionAccompagnement/API] Clear ParticipantActionAccompagnements'
);
