import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { QueryUtilPlanningTypesGQL } from '../GRAPHQL/queries/utilities/query-util-planning-types.service';
import { Apollo } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class UtilitiesPlanningService {
  constructor(
    private apollo: Apollo,
    private utilPlanningTypesGQL: QueryUtilPlanningTypesGQL
  ) {}

  getUtilPlanningType(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.utilPlanningTypesGQL.document,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }
}
