import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { v4 as uuidV4 } from 'uuid';

@Component({
  selector: 'app-metiers-competences',
  templateUrl: './metiers-competences.component.html',
  styleUrls: ['./metiers-competences.component.scss'],
})
export class MetiersCompetencesComponent
  implements OnInit, OnChanges, OnDestroy
{
  competensCles: boolean[] = [true, false];
  competensType: string[] = ['Savoir', 'SavoirFaire'];
  softskillImportance: string[] = [
    'Forte importance',
    'Importance moyenne',
    'Faible importance',
  ];
  subscribe = new Subject();
  noDataTitle: string = '';
  isAdd: boolean = false;

  @Input() source: any = [];
  @Input() itemLabel: string = '';
  @Input() field: string = 'libelle';
  @Input() isItemLabel: boolean = false;
  @Input() isSoftskills: boolean = false;
  @Input() isEnvironnement: boolean = false;
  @Input() isCompetences: boolean = false;
  @Input() isCompetencesSpeficiques: boolean = false;
  @Input() isAddNew: boolean = false;

  @Output() formChanges = new EventEmitter<any>();
  competencesArray = new FormArray<any>([]);

  competencesForm = new FormGroup({
    competences: this.competencesArray,
  });

  @Input() competencesDeBaseArray: FormArray | any = new FormArray([]);
  @Input() competencesSpecifiquesArray: FormArray | any = new FormArray([]);
  @Input() environnementsTravailArray: FormArray | any = new FormArray([]);

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.onChange();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.source.currentValue?.length) return;
    this.setArrayForm(changes.source.currentValue);

    this.setNoDataTitle(
      changes.isSoftskills?.currentValue,
      changes.isEnvironnement?.currentValue,
      changes.isCompetences?.currentValue,
      changes.isCompetencesSpeficiques?.currentValue
    );
  }
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  get competencesArrayControls() {
    return (<FormArray>this.competencesForm.get('competences')).controls;
  }

  setArrayForm(items: any): void {
    items.forEach((item: any) => this.onAdd(item));
  }

  setNoDataTitle(
    isSoftskills: boolean,
    isEnvironnement: boolean,
    isCompetences: boolean,
    isCompetencesSpeficique: boolean
  ): void {
    if (isSoftskills) {
      this.noDataTitle = 'Aucune compétence transversale renseignée';
    }
    if (isEnvironnement) {
      this.noDataTitle = 'Aucun environnement de travail renseigné';
    }

    if (isCompetences) {
      this.noDataTitle = 'Aucune compétence de base renseignée';
    }

    if (isCompetencesSpeficique) {
      this.noDataTitle = 'Aucune compétence spécifique renseignée';
    }
  }

  onAdd(item: any): void {
    if (!item) return;
    const itemForm: FormGroup = this.fb.group({
      ...item,
    });

    this.competencesArray.push(itemForm);
  }

  onRemove(index: number): void {
    this.competencesArray.removeAt(index);
  }

  formCreateNewItem(): void {
    const item = this.fb.group({
      libelle: new FormControl('', Validators.required),
      typeCompetence: new FormControl('Savoir', Validators.required),
      competenceCle: new FormControl<boolean>(true, Validators.required),
      code: new FormControl(uuidV4(), Validators.required),
    });

    this.competencesArray.insert(0, item);
  }

  formCompare(item1: any, item2: any): any {
    return item1 === item2;
  }

  onAddNew(): void {
    this.isAdd = true;
    this.formCreateNewItem();
  }

  onCancelAdded(): void {
    this.isAdd = false;
  }

  onRemoveAll(
    isSoftskills: boolean,
    isEnvironnement: boolean,
    isCompetences: boolean,
    isCompetencesSpeficiques: boolean
  ): void {
    this.competencesArray.clear();
    this.setNoDataTitle(
      isSoftskills,
      isEnvironnement,
      isCompetences,
      isCompetencesSpeficiques
    );
  }

  onChange(): void {
    this.competencesForm
      .get('competences')
      ?.valueChanges.pipe(takeUntil(this.subscribe))
      .subscribe((value) => this.formChanges.emit(value));
  }
}
