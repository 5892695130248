import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-departement-user-item',
  templateUrl: './departement-user-item.component.html',
  styleUrls: ['./departement-user-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DepartementUserItemComponent implements OnInit, OnChanges {
  default: string = '#bfbfbf';
  avatarColor: string = 'white';
  alertMessage: string = 'Voulez-vous retirer ce collaborateur du service ?';

  @Input() user: any;
  @Input() currentUser: any;
  @Output() select = new EventEmitter(false);
  @Output() delete = new EventEmitter(false);

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}

  onSelect(user: any): void {
    if (!user) return;
    this.select.emit(user);
  }

  onDelete(user: any): void {
    if (!user) return;
    this.delete.emit(user);
  }

  onCancel() {
    return;
  }
}
