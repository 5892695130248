import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ticketsFeatureKey, selectAll, TicketState } from './ticket.reducer';

export const selectTicketState =
  createFeatureSelector<TicketState>(ticketsFeatureKey);

export const selectTickets = createSelector(selectTicketState, selectAll);

export const selectLoading = createSelector(
  selectTicketState,
  (state: TicketState) => state.loading
);

export const selectError = createSelector(
  selectTicketState,
  (state: TicketState) => state.error
);

export const selectDisplay = createSelector(
  selectTicketState,
  (state: TicketState) => state.display
);
