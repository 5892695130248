import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-prescripteur-list',
  templateUrl: './prescripteur-list.component.html',
  styleUrls: ['./prescripteur-list.component.scss']
})
export class PrescripteurListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
