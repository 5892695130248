<nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
  <nz-col nzSpan="20">
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="6">
        <h4 nz-typography>{{ "Diagnostic " + diagnostic?.type?.label }}</h4>
      </nz-col>
      <nz-col nzSpan="6">
        <small>{{ diagnostic?.dateStart | dateFr : "time" }}</small>
      </nz-col>
      <nz-col nzSpan="12"> </nz-col>
    </nz-row>
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="2">
        <app-avatar-group [source]="[diagnostic?.auteur]"></app-avatar-group>
      </nz-col>
      <nz-col nzSpan="6"
        ><small
          >Modifié le : {{ diagnostic?.dateUpdate | dateFr : "time" }}</small
        ></nz-col
      >
      <nz-col nzSpan="16">
        <nz-tag nzColor="geekblue" *ngFor="let el of diagnostic.besoins">
          {{ el?.libelle }}
        </nz-tag>
      </nz-col>
    </nz-row>
    <nz-space nzDirection="vertical"></nz-space>
  </nz-col>
  <nz-col nzSpan="4">
    <ul nz-list-item-actions>
      <nz-list-item-action>
        <app-button-dropdown
          (click)="$event.stopPropagation()"
          [isDelete]="true"
          (select)="onItemDropdown($event, diagnostic)"
          [deleteType]="'Voulez-vous supprimer ce diagnostic ?'"
        ></app-button-dropdown>
      </nz-list-item-action>
    </ul>
  </nz-col>
</nz-row>
