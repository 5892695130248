import { SharedModule } from 'src/app/shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OperationNoteSubtitleListComponent } from './components/operation-note-subtitle-list/operation-note-subtitle-list.component';
import { OperationNoteSubtitleItemComponent } from './components/operation-note-subtitle-item/operation-note-subtitle-item.component';
import { OperationNoteSubtitleFormComponent } from './components/operation-note-subtitle-form/operation-note-subtitle-form.component';
import { OperationNoteSubtitleService } from 'src/app/service/operation-note-subtitle.service';
import { ContentsModule } from 'src/app/contents/contents.module';
import { StoreModule } from '@ngrx/store';
import * as fromOperationNoteSubtitle from './store/operation-note-subtitle.reducer';
import { EffectsModule } from '@ngrx/effects';
import { OperationNoteSubtitleEffects } from './store/operation-note-subtitle.effects';
import { NoResultModule } from 'src/app/contents/components/no-result/no-result.module';
import { CoordonneesModule } from 'src/app/contents/components/coordonnees/coordonnees.module';
import { CommunesFranceModule } from 'src/app/contents/components/communes-france/communes-france.module';
import { ButtonsModule } from 'src/app/contents/components/buttons/buttons.module';
import { HeaderModule } from 'src/app/contents/components/header-title/header.module';

@NgModule({
  declarations: [
    OperationNoteSubtitleListComponent,
    OperationNoteSubtitleItemComponent,
    OperationNoteSubtitleFormComponent,
  ],
  imports: [
    CommonModule,
    ContentsModule,
    SharedModule,
    NoResultModule,
    CoordonneesModule,
    HeaderModule,
    CommunesFranceModule,
    ButtonsModule,
    StoreModule.forFeature(
      fromOperationNoteSubtitle.operationNoteSubtitlesFeatureKey,
      fromOperationNoteSubtitle.reducer
    ),
    EffectsModule.forFeature([OperationNoteSubtitleEffects]),
  ],
  exports: [
    OperationNoteSubtitleListComponent,
    OperationNoteSubtitleItemComponent,
    OperationNoteSubtitleFormComponent,
  ],
  providers: [OperationNoteSubtitleService],
})
export class OperationNoteSubtitleModule {}
