import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { OperationNoteId } from 'src/app/components/operation-note/store/operation-note.model';
import { OperationNoteParticipantId } from '../../store/operation-note-participant.model';

@Component({
  selector: 'app-operation-note-participant-list',
  templateUrl: './operation-note-participant-list.component.html',
  styleUrls: ['./operation-note-participant-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationNoteParticipantListComponent
  implements OnInit, OnChanges, OnDestroy
{
  subscribe = new Subject();
  noteParticipants$: Observable<OperationNoteParticipantId[] | any[]> = of([]);

  @Input() note$: Observable<OperationNoteId | any> = of(null);

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }
}
