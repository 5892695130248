<nz-card
  nzSize="small"
  [nzTitle]="actionsCardTitle"
  [nzExtra]="actionsCardExtra"
  class="moduleCard_actions_table"
  *ngIf="source$ | async | size; else noData"
>
  <ng-template #actionsCardTitle>
    <app-header-title [icon]="'table_chart'"></app-header-title>
  </ng-template>
  <ng-template #actionsCardExtra>
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="end">
      <nz-col nzSpan="16">
        <button
          nz-button
          nz-tooltip
          nzBlock
          nzTooltipPlacement="bottom"
          nzTooltipTitle="Ajouter une action"
          [nzTooltipMouseEnterDelay]="0.5"
          class="btn_add"
          (click)="onNew()"
        >
          Nouveau
        </button>
      </nz-col>
      <nz-col nzSpan="8">
        <app-button-dropdown [isDeleteAll]="true"></app-button-dropdown>
      </nz-col>
    </nz-row>
  </ng-template>
  <nz-table
    #filterTable
    [nzData]="source$ | async"
    nzTableLayout="fixed"
    nzSize="small"
    [nzScroll]="scroll"
    [nzLoadingDelay]="2"
    [nzBordered]="false"
    [nzOuterBordered]="false"
  >
    <thead>
      <tr>
        <th nzLeft [nzSortFn]="sortFnByTitle">Titre</th>
        <th>Collaboration</th>
        <th>Échéance</th>
        <th>Participants</th>
        <th [nzSortFn]="sortFnByRealisation">Objectif</th>
        <th [nzSortFn]="sortFnByObjectif">Réalisation</th>
        <th nzRight [nzSortFn]="sortFnByProgression">%</th>
        <th nzRight></th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let data of filterTable.data"
        (click)="$event.stopPropagation(); onSelect(data)"
      >
        <td nzLeft>{{ data?.title | uppercase | carLimitation : 20 }}</td>
        <td>{{ data?.collaboration }}</td>

        <td>
          {{ data?.dateEnd | dateFr : "distanceSuffix" }}
        </td>
        <td>{{ data?.participants | size }}</td>

        <td>{{ data?.objectifs | number : "1.1-2" : "fr" }}</td>
        <td>{{ data?.realisations | number : "1.1-2" : "fr" }}</td>
        <td nzRight>
          <nz-tag nzColor="warning" *ngIf="data?.progression < 0.5">
            <strong>{{ data?.progression | percent }}</strong>
          </nz-tag>
          <nz-tag
            nzColor="orange"
            *ngIf="data?.progression >= 0.5 && data?.progression < 1"
          >
            <strong>{{ data?.progression | percent }}</strong>
          </nz-tag>
          <nz-tag nzColor="success" *ngIf="data?.progression >= 1">
            <strong>{{ data?.progression | percent }}</strong>
          </nz-tag>
        </td>

        <td nzRight>
          <app-button-dropdown
            [direction]="'horizontal'"
            [isDelete]="true"
            (select)="onSelectDropdown($event, data)"
          ></app-button-dropdown>
        </td>
      </tr>
    </tbody>
  </nz-table>
</nz-card>

<ng-template #noData>
  <app-no-result
    (new)="onNew()"
    (click)="$event.stopPropagation()"
    [icon]="icon"
    [addtitle]="addItemTitle"
    [description]="noDataTitle"
  ></app-no-result>
</ng-template>
