import {
  NzNotificationPlacement,
  NzNotificationService,
} from 'ng-zorro-antd/notification';
import { Etablissement } from './../components/entite/store/entite.model';
import { Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  doc,
  docSnapshots,
  collectionChanges,
  getDoc,
  addDoc,
  setDoc,
  deleteDoc,
  serverTimestamp,
  DocumentData,
  DocumentSnapshot,
  Timestamp,
  query,
  where,
  arrayUnion,
  arrayRemove,
  collectionSnapshots,
  CollectionReference,
  DocumentReference,
  getDocs,
  documentId,
  GeoPoint,
} from '@angular/fire/firestore';
import { Observable, of, from, EMPTY } from 'rxjs';
import { switchMap, mergeMap, map, catchError } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { UserState } from '../components/user/store/user.reducer';
import * as fromUserSelector from '../components/user/store/user.selectors';
import { v4 as uuidV4 } from 'uuid';
import {
  Entite,
  EntiteId,
  EntiteSMALL,
  EntiteSMALLid,
} from '../components/entite/store/entite.model';
import { format, fromUnixTime } from 'date-fns';

@Injectable({
  providedIn: 'any',
})
export class EntiteService {
  NOTIFICATION_PLACEMENT: NzNotificationPlacement = 'topRight';

  constructor(
    private db: Firestore,
    private fns: Functions,
    private userStore: Store<UserState>,
    private notification: NzNotificationService
  ) {}

  getAll(): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) of([]);

          const dbCollection = collection(
            this.db,
            `organisations/${user.organisation.id}/entites`
          );

          const changes = collectionSnapshots(dbCollection).pipe(
            map((changes) => {
              return changes.map((snap) => {
                const data = snap.data();
                const id = snap.id;
                return { id, ...data };
              });
            })
          );

          return changes;
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  getOneDetail(id: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) {
            return of(null);
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/entites/${id}/details`
            );

            const documentRef = doc(dbCollection, id);
            return docSnapshots(documentRef).pipe(
              map((snap) => {
                const data = snap.data();
                const id = snap.id;
                return { id, ...data };
              }),
              catchError((error) => EMPTY)
            );
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  getOne(id: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) {
            return of(null);
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/entites/${id}/details`
            );

            const documentRef = doc(dbCollection, id);
            return from(
              getDoc(documentRef)
                .then((snap) => {
                  const data = snap.data();
                  const id = snap.id;
                  return { id, ...data };
                })
                .catch((error) => error)
            ).pipe(mergeMap((res: any) => of(res)));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  getOneStatistiques(id: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) {
            return of(null);
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/entites/${id}/statistiques`
            );

            const documentRef = doc(dbCollection, id);
            return from(
              getDoc(documentRef)
                .then((doc: DocumentSnapshot<DocumentData>) => {
                  const data = doc.data() as any;
                  const id = doc.id;
                  return { id, ...data };
                })
                .catch((error) => error)
            ).pipe(mergeMap((res) => of(res)));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  addOne(entite: Entite): Observable<EntiteSMALLid | any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !entite) EMPTY;

          const smallVersionCollection = collection(
            this.db,
            `organisations/${user.organisation.id}/entites`
          );

          const addServiceMap = user?.service?.id
            ? {
                [`${user.service.id}`]: true,
              }
            : null;

          const addServiceArray = user?.service?.id
            ? arrayUnion(user.service.id)
            : null;

          const entiteSmall: EntiteSMALL = this.createSmallVersion(
            entite,
            user
          );

          const addSmallVersionEntite = addDoc(
            smallVersionCollection,
            entiteSmall
          );

          return from(
            addSmallVersionEntite
              .then((docRef) => {
                if (!docRef.id) {
                  this.notification.error('Erreur', `Erreur server`, {
                    nzDuration: 6000,
                    nzAnimate: true,
                    nzPlacement: this.NOTIFICATION_PLACEMENT,
                    nzKey: 'ADD_ENTITE_NOTIFICATION',
                  });
                  return EMPTY;
                }
                const detailsCollection: DocumentReference<DocumentData> = doc(
                  this.db,
                  `organisations/${user.organisation.id}/entites/${docRef.id}/details/${docRef.id}`
                );
                const newEntite: Entite = {
                  ...entite,
                  custom: {
                    ...entite.custom,
                    organisation: user.organisation,
                    services: addServiceArray,
                    servicesMap: addServiceMap,
                    dateUpdate: serverTimestamp(),
                    dateStart: serverTimestamp(),
                    userUpdate: user,
                    auteur: user,
                    auths: {
                      [`${user.id}`]: true,
                    },
                  },
                  metadata: this.createMetadata(entite),
                  organisation: user.organisation,
                };
                this.addDetailOne(detailsCollection, newEntite, docRef.id);

                const messageSuccess: string = `${entite.etablissement.denomination} ajouté avec succès`;

                return of(messageSuccess);
              })
              .catch((error) => of(error))
          );
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  addDetailOne(
    detailsDollection: DocumentReference<DocumentData>,
    entite: Entite,
    id: string
  ): void {
    try {
      setDoc(detailsDollection, entite, { merge: true })
        .then(() => {})
        .catch((error) => of(error));
    } catch (error) {
      return;
    }
  }

  getSirets(): Observable<any[] | any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (user) {
            const dbDocument = doc(
              this.db,
              `organisations/${user.organisation.id}/sirets/${user.organisation.id}`
            );

            return from(
              getDoc(dbDocument)
                .then((snap: DocumentData) => {
                  const data = snap.data() as any;
                  const id = snap.id;
                  return { id, ...data };
                })
                .catch((error) => error)
            ).pipe(mergeMap((res) => of(res)));
          } else {
            return of(null);
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  updateOne(id: string | number, entite: Partial<EntiteId>): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !entite) EMPTY;

          const documentRef: DocumentReference<DocumentData> = doc(
            this.db,
            `organisations/${user.organisation.id}/entites/${entite.id}/details/${entite.id}`
          );
          const nextDocument: any = {
            ...entite,
            custom: {
              ...entite.custom,
              dateUpdate: serverTimestamp(),
              userUpdate: user,
            },
            metadata: this.createMetadata(entite),
          };
          return from(
            setDoc(documentRef, { ...nextDocument }, { merge: true })
              .then(() => of(entite))
              .catch((error) => of(error))
          ).pipe((res) => res);
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  deleteOne(id: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !id) EMPTY;
          const documentRef: DocumentReference<DocumentData> = doc(
            this.db,
            `organisations/${user.organisation.id}/entites/${id}/details/${id}`
          );

          return from(
            deleteDoc(documentRef)
              .then(() => `Entité supprimée.`)
              .catch((error) => error)
          ).pipe(mergeMap((res) => of(res)));
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  deleteAll(ids: string[]): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) {
            return of(null);
          } else {
            const data = { id: user.id };
            const call = httpsCallable(this.fns, 'entite-deleteAllDocuments');
            return from(
              call(data)
                .then((rest) => rest)
                .catch((err) => err)
            ).pipe(
              mergeMap((res) => {
                return of(res);
              })
            );
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase();
  }

  createKeywords(name: any): any {
    if (name) {
      const arrName: any = [];
      let curName = '';
      name.split('').forEach((letter: any) => {
        curName += letter;
        arrName.push(curName);
      });
      return arrName;
    }
  }

  createSmallVersion(
    entite: Entite | EntiteId,
    user: any
  ): EntiteSMALL | EntiteSMALLid {
    const { etablissement, custom } = entite;
    const small: EntiteSMALL | EntiteSMALLid = {
      createAtYear: format(fromUnixTime(custom.dateStart['seconds']), 'yyyy'),
      createAtMonth: format(fromUnixTime(custom.dateStart['seconds']), 'M'),
      createAtDay: format(fromUnixTime(custom.dateStart['seconds']), 'd'),
      etablissement: etablissement,
      actif: custom.actif,
      secteur: custom.secteur,
      adherent: custom?.adherent ? custom?.adherent : false,
      type: custom.type,
      coordonnes: custom.coordonnees,
      metiers: entite?.custom?.metiers?.length
        ? entite.custom?.metiers.map((el: any) => el.libelle)
        : [],

      statut: custom.statut,
      operations: custom?.operations ? custom.operations : [],
      services: custom?.services ? custom.services : null,
      servicesMap: custom?.servicesMap ? custom.servicesMap : null,
      prescripteur: custom.prescripteur,
      organisationId: user.organisation.id,
      organisationRaisonSociale: user.organisation.nom_raison_sociale,
      dateStart: custom.dateStart,
      auteur: user,
      metiersThemes: custom?.metiersThemes ? custom.metiersThemes : null,
      metiersDomaine: custom?.metiersDomaine ? custom.metiersDomaine : null,
      metiersGrandDomaine: custom?.metiersGrandDomaine
        ? custom.metiersGrandDomaine
        : null,
      metiersMap: custom?.metiersMap ? custom.metiersMap : null,
      metiersCompetencesDeBase: custom?.metiersCompetencesDeBase
        ? custom.metiersCompetencesDeBase
        : null,
      metiersCompetencesDeBaseMap: custom?.metiersCompetencesDeBaseMap
        ? custom.metiersCompetencesDeBaseMap
        : null,
      metiersCompetencesDeBaseCleMap: custom?.metiersCompetencesDeBaseCleMap
        ? custom.metiersCompetencesDeBaseCleMap
        : null,
      metiersCompetencesDeSpeficique: custom?.metiersCompetencesDeSpeficique
        ? custom.metiersCompetencesDeSpeficique
        : null,
      metiersCompetencesDeSpeficiqueMap:
        custom?.metiersCompetencesDeSpeficiqueMap
          ? custom.metiersCompetencesDeSpeficiqueMap
          : null,
      metiersCompetencesDeSpeficiqueCleMap:
        custom?.metiersCompetencesDeSpeficiqueCleMap
          ? custom.metiersCompetencesDeSpeficiqueCleMap
          : null,
      metiersEnvironnement: custom?.metiersEnvironnement
        ? custom.metiersEnvironnement
        : null,
      metiersEnvironnementMap: custom?.metiersEnvironnementMap
        ? custom.metiersEnvironnementMap
        : null,
      metiersSoftskills: custom?.metiersSoftskills
        ? custom.metiersSoftskills
        : null,
      metiersSoftskillsMap: custom?.metiersSoftskillsMap
        ? custom.metiersSoftskillsMap
        : null,
      metiersAppelations: custom?.metiersAppelations
        ? custom.metiersAppelations
        : null,
      metiersAppelationsMap: custom?.metiersAppelationsMap
        ? custom.metiersAppelationsMap
        : null,

      metadata: this.createMetadata(entite),
    };
    return small;
  }

  createMetadata(entite: Entite | EntiteId | any): any {
    const { custom } = entite;
    const etablissement: Etablissement = entite.etablissement;
    const { denomination, activite_principale_libelle, etablissement_siege } =
      etablissement;

    const letterRaisonSociale = denomination
      ? this.capitalizeFirstLetter(denomination)
      : '';
    const arrayRaisonSociale = denomination
      ? this.createKeywords(denomination)
      : [];

    const arrayActivity = activite_principale_libelle.intitule_naf
      ? this.createKeywords(activite_principale_libelle.intitule_naf)
      : [];

    const arrayCommune = etablissement_siege.libelle_commune
      ? this.createKeywords(etablissement_siege.libelle_commune)
      : [];

    const arrayDepartement = etablissement_siege.code_postal
      ? this.createKeywords(etablissement_siege.code_postal)
      : [];

    const metadata = {
      dateStart: entite.custom.dateStart,
      letterRaisonSociale: letterRaisonSociale,
      arrayRaisonSociale: arrayRaisonSociale,
      arrayActivity: arrayActivity,
      arrayCommune: arrayCommune,
      arrayDepartement: arrayDepartement,
      adhesion: entite.custom.adhesion,
      adherent: entite.custom.adherent ? entite.custom.adherent : false,
      commune: etablissement_siege.libelle_commune,
      code_postal: etablissement_siege.code_postal,
      categorie_entreprise: etablissement.categorie_entreprise,
      secteur: custom.secteur,
      actif: custom.actif,
      engagement: custom.engagement,
      origine: custom.origine,
      prescripteur: custom.prescripteur ? custom.prescripteur : false,
      statut: custom.statut,
      libelle_activite_principale:
        etablissement.activite_principale_libelle.intitule_naf,
      libelle_nature_juridique_entreprise:
        etablissement.categorie_juridique_libelle.libelle,
    };

    return metadata;
  }
}
