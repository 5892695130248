import { GeoAdresse } from './../contents/components/communes-france/store/geo-adresse.model';
import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, of, Observable, from } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Commune } from '../contents/components/communes-france/commune.model';
import { map, switchMap } from 'rxjs/operators';
import { Apollo, gql, Query } from 'apollo-angular';

import { Store, select } from '@ngrx/store';
import { capitalize } from 'lodash';
import { Auth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'any',
})
export class CommunesFranceService {
  communes = new Subject<any>();
  commune = new BehaviorSubject<any>(null);
  complement = new BehaviorSubject<string>('');
  qpvList: Observable<any[]> = of([]);
  qpv = new BehaviorSubject<any>(null);
  city = new BehaviorSubject<any>(null);
  adresses$: Observable<any[]> = of([]);

  GEO_ADRESSE = gql`
    query GeoAdresse($input: String!) {
      geoAdresse(input: $input) {
        features {
          properties {
            city
            label
            type
            postcode
            citycode
            context
            name
            id
            street
          }
          geometry {
            coordinates
            type
          }
        }
      }
    }
  `;

  constructor(
    private http: HttpClient,
    private auth: Auth,
    private apollo: Apollo
  ) {}

  getGeoAdresse(input: string): Observable<any> {
    if (!input) {
      return of(null);
    }
    return this.apollo
      .watchQuery<any>({
        query: this.GEO_ADRESSE,
        variables: {
          input: input,
        },
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  //QPV ADRESSE

  query__ADRESSES(
    input: string,
    limit: number = 25,
    postcode?: number
  ): Observable<any> {
    if (this.auth.currentUser?.uid) {
      const QueryAdresses = gql`
        query QueryAdresses($input: String!) {
          geoAdresse(input: $input) {
            features {
              properties {
                label
                city
                postcode
                citycode
              }
            }
          }
        }
      `;

      return this.apollo
        .watchQuery<any>({
          query: QueryAdresses,
          variables: { input: input },
        })
        .valueChanges.pipe(
          map<any, number>(({ data, loading, error }): any => {
            return data;
          })
        );
    } else {
      return of(null);
    }
  }

  setComplement(item: string): void {
    if (item !== '') {
      this.complement.next(item);
    }
  }

  setQpv(item: string) {
    return null;
  }

  selectCommune(item: any) {
    if (item) {
      this.commune.next(item);
    }
  }

  selectQpv(item: any): void {
    if (item) {
      this.qpv.next(item);
    }
  }

  removeCommune(item: Commune) {
    if (item) {
      this.commune.next(null);
    }
  }
}
