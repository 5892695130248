import { ProjetPrpfessionnelState } from './../../store/projet-professionnel.reducer';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';
import { ParticipantId } from 'src/app/components/participant/store/participant.model';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Store } from '@ngrx/store';
import * as fromProjetProAction from '../../store/projet-professionnel.actions';
import * as fromProjetProSelector from '../../store/projet-professionnel.selectors';
import { ProjetProfessionnelTodoFormComponent } from '../projet-professionnel-todo-form/projet-professionnel-todo-form.component';

@Component({
  selector: 'app-projet-professionnel-todo',
  templateUrl: './projet-professionnel-todo.component.html',
  styleUrls: ['./projet-professionnel-todo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjetProfessionnelTodoComponent
  implements OnInit, OnChanges, OnDestroy
{
  loading$: Observable<boolean> = of(false);
  todos$: Observable<any> = of(null);
  subscribe = new Subject();
  currentFilter = new BehaviorSubject<any>(null);
  modalWidth: number = 850;
  title: string = 'Nouvelle tâche projet professionnel';
  view$ = new BehaviorSubject<string>('list');
  current$ = new BehaviorSubject<any>(null);
  newCardTitle: string = 'Nouvelle tâche';
  newCardDescription: string = 'Ajouter une tâche au projet professionnel';
  newCardDefinition: string =
    'Une tâche projet professionnel est une ensemble de tâche à réaliser afin de progresser le projet.';

  @ViewChild('projetProfessionnelTodoForm', { static: false })
  projetProfessionnelTodoForm: TemplateRef<any> | any;

  @Input() item = new BehaviorSubject<any>(null);
  @Input() participant: ParticipantId | any = null;
  @Input() projetprofessionnel$ = new BehaviorSubject<any>(null);

  constructor(
    private projetProfessionnelStore: Store<ProjetPrpfessionnelState>,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.getTodosProjetpro();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadTodos(changes.participant.currentValue);
  }
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getLoading(): void {
    this.loading$ = this.projetProfessionnelStore.select(
      fromProjetProSelector.loading
    );
  }
  getTodosProjetpro(): void {
    this.todos$ = this.projetProfessionnelStore.select(
      fromProjetProSelector.todos
    );
  }

  loadTodos(participant: ParticipantId): void {
    if (!participant) return;
    this.projetprofessionnel$
      .pipe(takeUntil(this.subscribe))
      .subscribe((projetPro: any) => {
        if (!projetPro) return;

        this.projetProfessionnelStore.dispatch(
          fromProjetProAction.loadTodoProjetpro({
            participantId: participant.id,
            projetpro: projetPro,
          })
        );
      });
  }

  onSelect(formation: any): void {
    this.current$.next(formation);
    this.view$.next('details');
  }

  onBack(): void {
    this.current$.next(null);
    this.view$.next('list');
  }

  onCancel(): void {
    return;
  }

  onNew(participant: any, projet: any): void {
    if (!participant || !projet) return;
    const modal = this.modal.create({
      nzContent: ProjetProfessionnelTodoFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.projetProfessionnelTodoForm,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });
    const instance = modal.getContentComponent();
    // instance.item.next(projet);
    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
    instance.add.subscribe((todo): any => {
      if (!todo) {
        return null;
      } else {
        this.onAdd(participant, projet, todo);
        modal.close();
      }
    });
  }

  onAdd(participant: any, projet: any, todo: any): void {
    if (!participant || !projet || !todo) return;
    this.projetProfessionnelStore.dispatch(
      fromProjetProAction.addTodoProjetpro({
        participantId: participant.id,
        projetpro: projet,
        todo: todo,
      })
    );
  }

  onUpdate(participant: any, projet: any, todo: any): void {
    if (!participant || !projet || !todo) return;
  }

  onDelete(todo: any, participant: any, projet: any): void {
    if (!participant || !projet || !todo) return;
    this.projetProfessionnelStore.dispatch(
      fromProjetProAction.deleteTodoProjetpro({
        participantId: participant.id,
        projetpro: projet,
        id: todo.id,
      })
    );
  }
}
