<ng-container *ngIf="!(loading$ | async); else operationListSkelton">
  <ng-container *ngIf="user$ | async as user">
    <ng-container
      *ngIf="
        user?.claims?.dev || user?.claims?.admin || user?.claims?.manager;
        else operationListMemberTpl
      "
    >
      <app-layout-list
        *ngIf="
          (data$ | async).operations.length || (operations$ | async) | size;
          else noResults
        "
        [new]="new"
        [card]="card"
        [tableRow]="operationRow"
        [source]="(data$ | async)?.operations | async"
      ></app-layout-list>
    </ng-container>

    <ng-template #operationListMemberTpl>
      <app-layout-list
        *ngIf="
          (data$ | async).operations.length || (operations$ | async) | size;
          else noResults
        "
        [card]="card"
        [source]="(data$ | async)?.operations"
      ></app-layout-list>
    </ng-template>

    <ng-template #new>
      <app-card-new
        [title]="newCardTitle"
        [type]="'projet'"
        [height]="'200px'"
        [width]="'250px'"
        [description]="''"
        [definition]="newCardDefinition"
        (add)="onNewOperationForm()"
      ></app-card-new>
    </ng-template>

    <ng-template #card let-item let-position="idx">
      <app-operation-card
        [operation]="item"
        (export)="onExportSelect($event)"
        (delete)="onDelete($event)"
      ></app-operation-card>
    </ng-template>

    <ng-template #operationRow let-item let-position="idx">
      <div class="w-full">
        <div class="w-1/12"></div>
        <div class="w-11/12">
          {{ item?.title }}
        </div>
      </div>
    </ng-template>

    <ng-template #noResults>
      <app-no-result
        [icon]="'analytics'"
        [addtitle]="'Créer un projet'"
        [notitle]="'Aucun projet'"
        [description]="newCardDefinition"
        (new)="onNewOperationForm()"
      ></app-no-result>

      <app-no-result
        *ngIf="user | isMember"
        [icon]="'analytics'"
        [notitle]="'Aucun projet'"
        [description]="newCardDefinition"
        [isButton]="false"
      ></app-no-result>
    </ng-template>
  </ng-container>
</ng-container>

<ng-template #operationListSkelton>
  <app-layout-list-skelton></app-layout-list-skelton>
</ng-template>

<ng-template #projetTitleForm>
  <app-header-title [icon]="'analytics'" [title]="title"></app-header-title>
</ng-template>
<ng-template #exportTitleForm>
  <app-header-title
    [icon]="'download'"
    [title]="titleDownload"
  ></app-header-title>
</ng-template>
