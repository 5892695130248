import { Pipe, PipeTransform } from '@angular/core';
import memo from 'memo-decorator';

@Pipe({
  name: 'isfavori',
})
export class IsfavoriPipe implements PipeTransform {
  @memo()
  transform(value: any, favoris: any[]): boolean {
    if (!value || !favoris?.length) {
      return false;
    }

    const favori = favoris.find((item) => item.id === value.id);

    if (!favori) {
      return false;
    } else {
      return true;
    }
  }
}
