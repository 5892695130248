import { distinctUntilChanged, debounceTime, takeUntil } from 'rxjs/operators';
import { ContactSMALLid } from './../../../../components/contact/store/contact.model';
import { OperationId } from './../../../../components/operation/store/operation.model';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { of, Observable, BehaviorSubject, Subject } from 'rxjs';
import { ContactSelectState } from './../store/contacts-select.reducer';
import * as fromContactSelectAction from './../store/contacts-select.actions';
import * as fromContactSelectSelector from './../store/contacts-select.selectors';
import { EntiteSMALLid } from 'src/app/components/entite/store/entite.model';

@Component({
  selector: 'app-contacts-select',
  template: `
    <nz-divider
      nzOrientation="center"
      nzType="horizontal"
      [nzText]="entiteTitleTpl"
    ></nz-divider>
    <ng-template #entiteTitleTpl>
      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col nzSpan="12">
          <div class="avatar">
            <mat-icon>domain</mat-icon>
          </div>
        </nz-col>
        <nz-col nzSpan="12">Entité</nz-col>
      </nz-row>
    </ng-template>
    <nz-select
      nzShowSearch
      nzBorderless
      nzServerSearch
      [nzPlaceHolder]="placeholderEntite"
      [(ngModel)]="selectedEntiteValue"
      [nzShowArrow]="false"
      (ngModelChange)="onSelectEntite($event)"
    >
      <nz-option
        *ngFor="let o of entites$ | async"
        [nzLabel]="o.entite?.nom_raison_sociale"
        [nzValue]="o.entite"
        nzCustomContent
      >
        <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
          <nz-col nzSpan="4">
            <mat-icon>domain</mat-icon>
          </nz-col>
          <nz-col nzSpan="10">
            {{ o.entite?.nom_raison_sociale | uppercase }}
          </nz-col>
        </nz-row>
      </nz-option>
    </nz-select>
    <nz-divider
      nzOrientation="center"
      nzType="horizontal"
      [nzText]="nitervenantTitleTpl"
    ></nz-divider>
    <ng-template #nitervenantTitleTpl>
      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col nzSpan="12">
          <div class="avatar">
            <mat-icon>person</mat-icon>
          </div>
        </nz-col>
        <nz-col nzSpan="12">Intervenants</nz-col>
      </nz-row>
    </ng-template>
    <nz-select
      nzShowSearch
      nzBorderless
      nzServerSearch
      [nzPlaceHolder]="placeholder"
      [(ngModel)]="selectedContactsValue"
      [nzShowArrow]="false"
      (nzOnSearch)="search.next($event)"
      (ngModelChange)="onSelect($event)"
      nzMode="tags"
      [nzMaxTagCount]="3"
    >
      <nz-option
        *ngFor="let o of contacts$ | async"
        [nzLabel]="o | displayName"
        [nzValue]="o"
        nzCustomContent
      >
        <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
          <nz-col nzSpan="4">
            <nz-avatar nzIcon="contacts"></nz-avatar>
          </nz-col>
          <nz-col nzSpan="10">
            {{ o | displayName }}
            <strong> </strong>
          </nz-col>
        </nz-row>
      </nz-option>
    </nz-select>

    <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="end">
      <nz-col nzSpan="16"></nz-col>
      <nz-col nzSpan="4">
        <button nz-button nzType="primary" nzBlock (click)="onValidation()">
          Valider
        </button>
      </nz-col>
      <nz-col nzSpan="4">
        <button nz-button nzType="text" nzBlock (click)="onCancel()">
          Annuler
        </button>
      </nz-col>
    </nz-row>
  `,
  styles: [
    `
      nz-select {
        width: 100%;
        background-color: #f5f5f5;
        border-radius: 12px;
      }
      nz-avatar {
        background-color: #d6e4ff;
      }
      .avatar {
        vertical-align: middle;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background-color: #adc6ff;
        mat-icon {
          color: #fff;
          margin-top: 5px !important;
          margin-left: 2px !important;
        }
      }
    `,
  ],
})
export class ContactsSelectComponent implements OnInit, OnChanges, OnDestroy {
  contacts$: Observable<ContactSMALLid[] | any> = of(null);
  subscribe = new Subject();
  search = new BehaviorSubject<string>('');
  selectedContactsValue: any;
  selectedEntiteValue: any;
  selectedEntite$ = new BehaviorSubject<any>(null);
  @Input() placeholderEntite: string = 'Rechercher une entité...';
  @Input() placeholder: string = 'Sélectionner un intervenant...';
  @Input() entites$: Observable<any> = of(null);
  @Input() operation$: Observable<OperationId | any> = of(null);
  @Input() exists$: Observable<any> = of(null);
  @Input() entite: any = null;
  @Output() select = new EventEmitter<ContactSMALLid[] | any>(false);
  @Output() cancel = new EventEmitter<boolean>(false);

  constructor(private contactSelectStore: Store<ContactSelectState>) {}

  ngOnInit(): void {
    this.getContactss();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  getContactss(): void {
    this.contacts$ = this.contactSelectStore.select(
      fromContactSelectSelector.contacts
    );
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  onSelectEntite(entite: any): void {
    if (!entite) return;
    this.loadContacts(entite);
  }

  loadContacts(entite: any): void {
    if (!entite) return;
    const filterType: string = 'entite';
    this.contactSelectStore.dispatch(
      fromContactSelectAction.queryContacts({ filter: entite.id, filterType })
    );
  }

  onSelect(contact: ContactSMALLid): void {}

  onValidation(): void {
    const contacts: ContactSMALLid[] = this.selectedContactsValue;
    if (!contacts?.length) return;
    this.select.emit(contacts);
  }

  onCancel(): void {
    this.cancel.emit(true);
  }
}
