import { addProjetProfessionnel } from './../../../projets-professionnels/store/projet-professionnel.actions';
import { Store } from '@ngrx/store';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ParticipantState } from './../../store/participant.reducer';
import * as fromParticipantAction from './../../store/participant.actions';
import * as fromParticipantSelector from './../../store/participant.selectors';

import { NzModalService } from 'ng-zorro-antd/modal';
import { ParticipantId } from './../../store/participant.model';
import { Observable, of } from 'rxjs';
import { Component, Input, OnInit, ViewContainerRef } from '@angular/core';

@Component({
  selector: 'app-participant-projetprofessionnels',
  templateUrl: './participant-projetprofessionnels.component.html',
  styleUrls: ['./participant-projetprofessionnels.component.scss'],
})
export class ParticipantProjetprofessionnelsComponent implements OnInit {
  title$: Observable<string | any> = of('Projets professionnels');
  newCardTitle: string = 'Nouveau projet professionnel';
  newCardDescription: string = 'Ajouter un projet profesionnel';
  newCardDefinition: string =
    "Un projet professionnel est un projet d'emploi ou d'insertion.";

  projetsProfessionnels$: Observable<any> = of([]);
  @Input() participant: ParticipantId | any;
  @Input() view: string = '';

  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private participantStore: Store<ParticipantState>,
    private message: NzMessageService
  ) {}

  ngOnInit(): void {}

  onNew(): void {}
}
