<nz-card class="header__Card" *ngIf="operation">
  <!-- <h3 nz-typography>{{ operation.denomination | titlecase }}</h3>
  <h4 nz-typography>{{ operation.type | uppercase }}</h4>
  <p nz-typography>
    {{ operation?.description?.adresse?.properties?.label }}
  </p> -->
</nz-card>

<!-- OPERATION -->
<!-- <div class="rencontreOperation" *ngIf="operation">
  <span>{{ operation?.financeurs?.[0] | uppercase }}</span>
  {{ operation.description.adresse?.adresse?.properties?.label }}
</div> -->
<nz-space nzDirection="vertical" nzSize="large"></nz-space>
