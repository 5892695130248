import {
  UntypedFormControl,
  UntypedFormArray,
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { BehaviorSubject, of, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { fromUnixTime, add } from 'date-fns';
import { Todo, TodoId, TodoSujet } from '../../store/todo.model';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';
import { Update } from '@ngrx/entity';
import { v4 as uuidv4 } from 'uuid';
import { Store, select } from '@ngrx/store';
import { TodoState } from '../../store/todo.reducer';
import * as fromTodoSelector from '../../store/todo.selectors';
import * as fromTodoAction from '../../store/todo.actions';
import { Timestamp } from '@angular/fire/firestore';

@Component({
  selector: 'app-todo-form',
  templateUrl: './todo-form.component.html',
  styleUrls: ['./todo-form.component.scss'],
})
export class TodoFormComponent implements OnInit, OnDestroy {
  subscribe = new Subject();
  user$: Observable<any> = of(null);
  loading$: Observable<any> = of(null);
  addingState$: Observable<boolean> = of(false);
  fromList: boolean = false;

  //VARIABLES
  currentTodo$: Observable<TodoId | any> = of(null);
  sujets$ = new BehaviorSubject<any>([]);
  update: boolean = false;
  checkBoxAll: boolean = false;
  todoFileSize: string = '';
  today = new Date(Date.now());
  start: Date | any = this.today;
  end: Date | any = add(this.today, { hours: 2, minutes: 30 });
  dates: Date[] = [this.start, this.end];
  dateFormat = 'dd/MM/yyyy HH:mm';
  timeFormat = {
    nzMinuteStep: 30,
    nzFormat: 'HH:mm',
  };

  green: string = '#73d13d';
  red: string = '#ff4d4f';

  //SUJET ITEMS
  sujetItems = [
    {
      name: 'texte',
      icon: 'align-left',
    },
  ];

  //INDICATORS
  adding$: Observable<boolean> = of(false);

  //FORM FIELDS
  todoForm: UntypedFormGroup = this.fb.group({});
  title = new FormControl<string>('', Validators.required);
  completed = new FormControl<boolean>(false, Validators.required);
  dateStart = new FormControl<Date>(this.start, Validators.required);
  dateEnd = new FormControl<Date>(this.end, Validators.required);
  dateUpdate: UntypedFormControl = new UntypedFormControl('');
  allDay = new FormControl<boolean>(false, Validators.required);
  auths: UntypedFormControl = new UntypedFormControl('');
  auteur: UntypedFormControl = new UntypedFormControl('');
  share: UntypedFormControl = new UntypedFormControl('');
  priority = new FormControl<boolean>(false, Validators.required);
  sujets: UntypedFormArray = this.fb.array([]);
  users: UntypedFormControl = new UntypedFormControl('');
  comments: UntypedFormControl = new UntypedFormControl('');

  alReadyUsers = new BehaviorSubject<any>(null);

  //INTERACTIONS
  @Input() modalClose = new BehaviorSubject<boolean>(false);
  @Output() cancel = new EventEmitter<boolean>(false);
  @Output() closeNewForm = new EventEmitter<boolean>(false);
  @Output() modalOpen = new EventEmitter<boolean>(false);

  constructor(
    private userStore: Store<UserState>,
    private todoStore: Store<TodoState>,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.get__USER();
    this.get__TODO();
    this.formSetUp();
    this.get__Indicator();
  }

  get__USER(): void {
    this.user$ = this.userStore.select(fromUserSelector.user);
  }

  get__TODO(): void {
    this.currentTodo$ = this.todoStore.select(fromTodoSelector.todo);
  }

  get__Indicator(): void {
    this.adding$ = this.todoStore.select(fromTodoSelector.adding);
  }

  //FORM SETUP
  formSetUp(): void {
    this.formInit();
    this.formCreate();
    this.formSet();
  }
  formInit(): void {
    this.title = new UntypedFormControl('', Validators.required);
    this.completed = new UntypedFormControl(false, Validators.required);
    this.priority = new UntypedFormControl(false);
    this.dateStart = new UntypedFormControl(this.today, Validators.required);
    this.dateEnd = new UntypedFormControl(
      add(this.today, { hours: 2, minutes: 30 }),
      Validators.required
    );
    this.dateUpdate = new UntypedFormControl(this.today);
    this.auths = new UntypedFormControl(null);
    this.auteur = new UntypedFormControl('');
    this.allDay = new UntypedFormControl(false);
    this.sujets = this.fb.array([]);
  }

  formCreate(): void {
    this.todoForm = this.fb.group({
      title: this.title,
      completed: this.completed,
      priority: this.priority,
      dateStart: this.dateStart,
      dateEnd: this.dateEnd,
      dateUpdate: this.dateUpdate,
      allDay: this.allDay,
      auths: this.auths,
      auteur: this.auteur,
      sujets: this.sujets,
    });
  }

  formSet(): void {
    this.todoStore
      .select(fromTodoSelector.todo)
      .pipe(takeUntil(this.subscribe))
      .subscribe((todo: TodoId): any => {
        if (!todo) {
          this.update = false;
          return null;
        } else {
          const start = fromUnixTime(todo.dateStart['seconds']);
          const end = fromUnixTime(todo.dateEnd['seconds']);

          if (!start) {
            return null;
          }
          this.start = start;
          this.end = end;
          this.checkBoxAll = todo?.allDay ? true : false;

          this.todoForm.patchValue({
            title: todo.title,
            completed: todo.completed,
            priority: todo.priority,
            dateStart: start,
            dateEnd: end,
            dateUpdate: this.today,
            allDay: todo.allDay,
            auths: todo.auths,
            auteur: todo.auteur,
          });

          this.update = true;
          if (todo?.sujets?.length) {
            this.sujets.clear();
            todo?.sujets.forEach((sujet, index) => {
              const group = this.fb.group({ ...sujet });
              this.sujets.push(group);
            });
          }
        }
      });
  }

  get sujetsArray(): UntypedFormArray {
    return this.todoForm.get('sujets') as UntypedFormArray;
  }

  onChangeDates(dates: Date[]): void {
    if (!dates.length) return;
    const strat: Date = dates[0];
    const end: Date = dates[1];
    this.todoForm.patchValue({
      dateStart: strat,
      dateEnd: end,
    });
  }

  onAdd(): void {
    const todo: Todo | any = this.todoForm.value;
    const newtTodo = {
      allDay: todo.allDay,
      dateEnd: Timestamp.fromDate(todo.dateEnd),
      dateStart: Timestamp.fromDate(todo.dateStart),
      priority: todo.priority,
      title: todo.title,
    };

    this.todoStore.dispatch(fromTodoAction.addTodo({ todo: newtTodo }));
  }

  onUpdate(todo: TodoId): any {
    if (!todo) {
      return null;
    } else {
      const todoForm: TodoId = this.todoForm.value;
      const dateEnd = Timestamp.fromDate(todoForm.dateEnd);
      const dateStart = Timestamp.fromDate(todoForm.dateStart);
      const chnages = {
        id: todo.id,
        allDay: todoForm.allDay,
        completed: todoForm.completed,
        dateEnd: dateEnd,
        dateStart: dateStart,
        priority: todoForm.priority,
        sujets: todoForm.sujets,
        title: todoForm.title,
      };

      const nextTodo: Update<TodoId> = {
        id: todo.id,
        changes: chnages,
      };

      this.todoStore.dispatch(fromTodoAction.updateTodo({ todo: nextTodo }));
    }
  }

  onDelete(todo: TodoId, user: any): any {
    if (!todo || !user || todo.auteur.id !== user.id) {
      return null;
    } else {
      this.todoStore.dispatch(fromTodoAction.deleteTodo({ id: todo.id }));
    }
  }

  onCancel(update: boolean): any {
    this.todoForm.reset();
    this.cancel.emit(true);
    if (!update) {
      return null;
    } else {
      this.onNewForm(true);
    }
  }
  onNewForm(event: boolean): void {
    this.closeNewForm.emit(event);
    this.cancel.emit(true);
  }

  //SUEJTS
  addSujet(typeSujet: string, todo: TodoId): any {
    if (!typeSujet) {
      return null;
    }

    const next = this.fb.group({
      id: [uuidv4()],
      type: [typeSujet],
      text: [''],
      status: [false],
      priority: [false],
      dateUpdate: [Timestamp.now()],
    });

    this.sujets.push(next);

    this.onUpdate(todo);
  }

  checkedChangeSujet(
    event: boolean,
    index: number,
    sujet: any,
    todo: TodoId,
    user: any
  ): void {
    this.sujets.controls[index].patchValue({
      status: event,
      userUpdate: user,
    });

    this.onUpdate(todo);
  }

  prioritySujet(event: boolean, index: number, sujet: any, todo: TodoId): void {
    const prority: boolean = sujet.value.priority;
    this.sujets.at(index).patchValue({ priority: !prority });
    this.onUpdate(todo);
  }

  onUpdateSujet(sujet: any, user: any, todo: TodoId): any {
    if (!todo || !todo) {
      return null;
    } else {
      this.onUpdate(todo);
    }
  }

  onDeleteSujet(index: number, todo: TodoId): any {
    if (!todo || !index) {
      return null;
    } else {
      this.sujets.removeAt(index);
      this.onUpdate(todo);
    }
  }

  removeSujet(index: number, todo: TodoId): void {
    this.sujets.removeAt(index);

    this.onUpdate(todo);
  }

  onCancelDeleted(): void {
    return;
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }
}
