import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  ParticipantAccompagnementState,
  participantActionAccompagnementsFeatureKey,
  selectAll,
} from './participant-action-accompagnement.reducer';

export const selectParticipantAccompagnementState =
  createFeatureSelector<ParticipantAccompagnementState>(
    participantActionAccompagnementsFeatureKey
  );

export const accompagnments = createSelector(
  selectParticipantAccompagnementState,
  selectAll
);
export const accompagnement = createSelector(
  selectParticipantAccompagnementState,
  (state: ParticipantAccompagnementState) => state.accompagnement
);

export const loading = createSelector(
  selectParticipantAccompagnementState,
  (state: ParticipantAccompagnementState) => state.loading
);
export const types = createSelector(
  selectParticipantAccompagnementState,
  (state: ParticipantAccompagnementState) => state.types
);
export const champs = createSelector(
  selectParticipantAccompagnementState,
  (state: ParticipantAccompagnementState) => state.champs
);

export const error = createSelector(
  selectParticipantAccompagnementState,
  (state: ParticipantAccompagnementState) => state.error
);
