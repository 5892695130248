<ng-container *ngIf="user$ | async as user">
  <app-todo-form-header
    *ngIf="title?.dirty || update"
    [user]="user"
    [todo]="currentTodo$ | async"
    (add)="onAdd()"
    (updated)="onUpdate($event)"
    [update]="update"
    (cancel)="onCancel(update)"
    (delete)="onDelete($event, user)"
  ></app-todo-form-header>
  <nz-divider
    nzOrientation="center"
    nzType="horizontal"
    *ngIf="currentTodo$ | async as todo"
  ></nz-divider>

  <ng-container>
    <nz-row nzAlign="middle" nzGutter="2" nzJustify="center">
      <nz-col nzSpan="10">
        <nz-range-picker
          *ngIf="!(currentTodo$ | async)?.completed; else dateStartOnCopmpleted"
          nzSize="small"
          [nzPlaceHolder]="['Démarrage', 'Échéance']"
          [nzShowTime]="timeFormat"
          [nzFormat]="dateFormat"
          [nzAllowClear]="true"
          nzBorderless
          [(ngModel)]="dates"
        ></nz-range-picker>

        <ng-template #dateStartOnCopmpleted>
          <nz-tag>
            <i nz-icon nzType="field-time"></i>
            <span>{{
              (currentTodo$ | async)?.dateStart | dateFr : "time"
            }}</span>
          </nz-tag>
        </ng-template>
      </nz-col>
      <nz-col nzSpan="2"></nz-col>
      <nz-col nzSpan="3">
        <form
          nz-form
          [formGroup]="todoForm"
          nzLayout="vertical"
          [ngStyle]="{ float: 'center', width: '100%' }"
          *ngIf="!(currentTodo$ | async)?.completed; else allDayDisabled"
        >
          <nz-form-item [ngStyle]="{ float: 'center', width: '100%' }">
            <label
              nz-checkbox
              [(ngModel)]="checkBoxAll"
              [ngModelOptions]="{ standalone: true }"
              >Journée</label
            >
          </nz-form-item>
        </form>

        <ng-template #allDayDisabled>
          <p nz-typography class="priorityForCompletedTask">
            {{ (currentTodo$ | async)?.allDay ? "Journée" : "-" }}
          </p>
        </ng-template>
      </nz-col>
      <nz-col [nzSpan]="3">
        <div [ngStyle]="{ float: 'center', width: '100%' }">
          <form
            nz-form
            [formGroup]="todoForm"
            nzLayout="vertical"
            *ngIf="!(currentTodo$ | async)?.completed; else priorityDisabled"
          >
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox formControlName="priority">
                  <i
                    nz-icon
                    nzType="flag"
                    nzTheme="outline"
                    nz-tooltip
                    nzTooltipTitle="Priorité haute"
                    nzTooltipPlacement="bottom"
                  ></i>
                </label>
              </nz-form-control>
            </nz-form-item>
          </form>
          <ng-template #priorityDisabled>
            <p nz-typography class="priorityForCompletedTask">
              {{
                (currentTodo$ | async)?.priority
                  ? "Priorité haute"
                  : "Priorité basse"
              }}
            </p>
          </ng-template>
        </div>
      </nz-col>
      <nz-col nzSpan="3" *ngIf="currentTodo$ | async as todo">
        <nz-statistic
          [nzValue]="todo?.sujets | isComplete : 'status' : true | size"
          [nzPrefix]="prefixTpl"
          [nzSuffix]="'/ ' + (todo?.sujets | size)"
          [nzValueStyle]="{ 'font-size': '16px' }"
        ></nz-statistic>
        <ng-template #prefixTpl
          ><i
            nz-icon
            nzType="file-done"
            class="iconSujetsTodo"
            [ngStyle]="{
              color:
                (todo?.sujets | isComplete : 'status' : true | size) ===
                (todo?.sujets | size)
                  ? green
                  : red
            }"
          ></i
        ></ng-template>
      </nz-col>
      <nz-col [nzSpan]="3">
        <i
          nz-icon
          nzType="loading"
          nzTheme="outline"
          class="addingLoaderICON__TODO"
          *ngIf="!update && (adding$ | async); else todoFormActions"
        ></i>

        <ng-template #todoFormActions>
          <nz-button-group *ngIf="currentTodo$ | async as todo">
            <button
              nz-button
              nzType="text"
              nzShape="circle"
              (click)="$event.stopPropagation(); onUpdate(todo)"
              nz-tooltip
              nzTooltipTitle="Compléter"
              [nzTooltipMouseEnterDelay]="0.5"
              nzTooltipPlacement="bottomRight"
              class="btnTodo"
            >
              <mat-icon>check_circle</mat-icon>
            </button>
            <button
              nz-button
              nzType="text"
              nzShape="circle"
              class="btnTodo"
              (click)="$event.stopPropagation(); onAdd()"
              nz-tooltip
              nzTooltipTitle="Sauvegarder"
              [nzTooltipMouseEnterDelay]="0.5"
              nzTooltipPlacement="bottomRight"
            >
              <mat-icon>save</mat-icon>
            </button>
            <button
              nz-button
              nzType="text"
              class="btnTodo"
              nzShape="circle"
              (click)="$event.stopPropagation()"
              nz-tooltip
              nzTooltipTitle="Supprimer"
              [nzTooltipMouseEnterDelay]="0.5"
              nzTooltipPlacement="bottomRight"
              nz-popconfirm
              (nzOnCancel)="onCancelDeleted()"
              (nzOnConfirm)="onDelete(todo, user)"
              nzTooltipTrigger="click"
              nzPopconfirmPlacement="bottomRight"
              nzPopconfirmTitle="Voulez-vous supprimer ?"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </nz-button-group>
        </ng-template>
      </nz-col>
    </nz-row>
  </ng-container>

  <nz-divider
    nzOrientation="center"
    nzType="horizontal"
    *ngIf="currentTodo$ | async as todo"
  ></nz-divider>

  <nz-row nzAlign="top" nzGutter="8" nzJustify="end">
    <nz-col nzSpan="24">
      <form
        nz-form
        [formGroup]="todoForm"
        nzLayout="vertical"
        *ngIf="!(currentTodo$ | async)?.completed; else titleCompleted"
      >
        <nz-form-item nzRequired="true" class="texterea__TODO">
          <nz-form-control>
            <input
              #todoInput
              nzBorderless
              class="titleTODO"
              nz-input
              formControlName="title"
              nzSize="large"
              placeholder="Nouvelle tâche..."
              nzPlaceHolder="Nouvelle tâche..."
              required
            />
          </nz-form-control>
        </nz-form-item>
      </form>
      <ng-template #titleCompleted>
        <h2 nz-typography>{{ (currentTodo$ | async)?.title }}</h2>
      </ng-template>
    </nz-col>
  </nz-row>

  <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>

  <!-- ADD SUJET BUTTON -->
  <nz-row nzAlign="top" nzGutter="8" nzJustify="end">
    <nz-col nzSpan="20"> </nz-col>
    <nz-col nzSpan="4">
      <ng-container *ngIf="currentTodo$ | async as todo">
        <button
          nz-button
          (click)="$event.stopPropagation(); addSujet('texte', todo)"
          nz-tooltip
          nzBlock
          nzTooltipPlacement="bottom"
          [nzTooltipMouseEnterDelay]="0.5"
          nzTooltipTitle="Ajouter un sujet"
          class="todo_btn_add_center"
        >
          Nouveau
        </button>
      </ng-container>
    </nz-col>
  </nz-row>

  <!-- SUJETS -->
  <nz-tabset nzCentered *ngIf="currentTodo$ | async as todo">
    <nz-tab>
      <nz-row
        nzAlign="top"
        nzGutter="8"
        nzJustify="start"
        *ngIf="currentTodo$ | async as todo"
      >
        <nz-card nzBorderless class="formContentTODO">
          <form nz-form [formGroup]="todoForm" nzLayout="vertical">
            <div formArrayName="sujets">
              <ng-container *ngIf="sujetsArray.controls?.length; else noData">
                <div *ngFor="let sujet of sujetsArray.controls; let i = index">
                  <nz-card
                    [formGroupName]="i"
                    nzSize="small"
                    nzHoverable
                    nzBorderless
                    [nzExtra]="dataSujetExtra"
                    [nzTitle]="dataSujetTitle"
                  >
                    <ng-template #dataSujetTitle>
                      <i
                        nz-icon
                        nzType="align-left"
                        *ngIf="sujetsArray.controls[i].value.type === 'texte'"
                      ></i>

                      <i
                        nz-icon
                        nzType="unordered-list"
                        *ngIf="sujetsArray.controls[i].value.type === 'liste'"
                      ></i>
                      <small
                        *ngIf="sujetsArray.controls[i].value.status"
                        class="sujetTitle__TODO"
                      >
                        Réalisé par
                        {{
                          sujetsArray.controls[i].value?.userUpdate?.displayName
                            | carLimitation : 25
                        }}</small
                      >
                      <small
                        *ngIf="sujetsArray.controls[i].value.status"
                        class="sujetTitle__TODO"
                      >
                        le
                        {{
                          sujetsArray.controls[i].value?.dateUpdate
                            | dateFr : "time"
                        }}</small
                      >
                    </ng-template>
                    <ng-template #dataSujetExtra>
                      <nz-row nzAlign="middle" nzGutter="0" nzJustify="end">
                        <nz-col
                          [nzSpan]="(currentTodo$ | async)?.completed ? 12 : 10"
                        >
                          <nz-tag
                            *ngIf="!(currentTodo$ | async)?.completed"
                            (nzCheckedChange)="
                              checkedChangeSujet(
                                $event,
                                i,
                                sujet.value,
                                todo,
                                user
                              )
                            "
                            [nzChecked]="sujetsArray.controls[i].value.status"
                            [nzColor]="
                              sujetsArray.controls[i].value.status
                                ? 'success'
                                : 'orange'
                            "
                            nzMode="checkable"
                          >
                            <i
                              nz-icon
                              nzType="field-time"
                              *ngIf="!sujetsArray.controls[i].value.status"
                            ></i>
                            <i
                              nz-icon
                              nzType="check-circle"
                              *ngIf="sujetsArray.controls[i].value.status"
                            ></i>
                            <span *ngIf="sujetsArray.controls[i].value.status"
                              >Réalisée</span
                            >
                            <span *ngIf="!sujetsArray.controls[i].value.status"
                              >En attente</span
                            >
                          </nz-tag>

                          <nz-tag
                            *ngIf="(currentTodo$ | async)?.completed"
                            [nzChecked]="sujetsArray.controls[i].value.status"
                            [nzColor]="
                              sujetsArray.controls[i].value.status
                                ? 'success'
                                : 'orange'
                            "
                          >
                            <i
                              nz-icon
                              nzType="field-time"
                              *ngIf="!sujetsArray.controls[i].value.status"
                            ></i>
                            <i
                              nz-icon
                              nzType="check-circle"
                              *ngIf="sujetsArray.controls[i].value.status"
                            ></i>
                            <span *ngIf="sujetsArray.controls[i].value.status"
                              >Réalisée</span
                            >
                            <span *ngIf="!sujetsArray.controls[i].value.status"
                              >En attente</span
                            >
                          </nz-tag>
                        </nz-col>

                        <nz-col
                          [nzSpan]="(currentTodo$ | async)?.completed ? 12 : 9"
                        >
                          <nz-tag
                            *ngIf="!(currentTodo$ | async)?.completed"
                            [nzColor]="
                              sujetsArray.controls[i].value?.priority
                                ? 'red'
                                : 'default'
                            "
                            nzMode="checkable"
                            (nzCheckedChange)="
                              prioritySujet($event, i, sujet, todo)
                            "
                            nz-tooltip
                            nzTooltipTitle="Priorité"
                            [nzTooltipMouseEnterDelay]="1"
                            nzTooltipPlacement="bottom"
                            ><i nz-icon nzType="flag"></i>
                            <span>Priorité</span>
                          </nz-tag>

                          <nz-tag
                            *ngIf="(currentTodo$ | async)?.completed"
                            [nzColor]="
                              sujetsArray.controls[i].value?.priority
                                ? 'red'
                                : 'default'
                            "
                            ><i nz-icon nzType="flag"></i>
                            <span>Priorité</span>
                          </nz-tag>
                        </nz-col>

                        <nz-col
                          [nzSpan]="(currentTodo$ | async)?.completed ? 0 : 3"
                        >
                          <button
                            *ngIf="
                              sujetsArray.controls[i].dirty &&
                              !(currentTodo$ | async)?.completed
                            "
                            nz-button
                            nzShape="circle"
                            nzSize="small"
                            nzType="primary"
                            (click)="onUpdate(todo)"
                            nz-tooltip
                            nzTooltipTitle="Enregistrer"
                            [nzTooltipMouseEnterDelay]="0.5"
                            nzTooltipPlacement="bottom"
                          >
                            <i nz-icon nzType="save"></i>
                          </button>
                        </nz-col>
                        <nz-col
                          [nzSpan]="(currentTodo$ | async)?.completed ? 0 : 2"
                          *ngIf="!(currentTodo$ | async)?.completed"
                        >
                          <button
                            *ngIf="
                              todo?.auteur?.id === user.id ||
                              !(currentTodo$ | async)?.completed
                            "
                            nz-button
                            nzShape="circle"
                            nzSize="small"
                            (click)="removeSujet(i, todo)"
                          >
                            <i nz-icon nzType="delete"></i>
                          </button>
                        </nz-col>
                      </nz-row>
                    </ng-template>

                    <div [ngSwitch]="sujetsArray.controls[i].value.type">
                      <div *ngSwitchCase="'texte'">
                        <nz-row nzAlign="top" nzGutter="8" nzJustify="start">
                          <textarea
                            *ngIf="!sujetsArray.controls[i].value.status"
                            nz-input
                            formControlName="text"
                            nzBorderless
                            placeholder="Texte..."
                            [nzAutosize]="{ minRows: 4, maxRows: 8 }"
                          ></textarea>
                          <nz-comment
                            *ngIf="sujetsArray.controls[i].value.status"
                          >
                            <nz-comment-content>
                              <del>
                                {{ sujetsArray.controls[i].value.text }}
                              </del>
                            </nz-comment-content>
                          </nz-comment>
                        </nz-row>
                      </div>
                      <div *ngSwitchCase="'liste'">
                        <nz-list nzBordered="false" nzSize="small">
                          <nz-list-header nzNoFlex>
                            <nz-row>
                              <nz-col nzSpan="18">
                                <input
                                  *ngIf="
                                    !sujetsArray.controls[i].value.status ||
                                    !(currentTodo$ | async)?.completed
                                  "
                                  nz-input
                                  class="listTitleTODO"
                                  formControlName="title"
                                  nzSize="default"
                                  nzBorderless
                                  placeholder="Titre..."
                                />

                                <strong
                                  *ngIf="
                                    sujetsArray.controls[i].value.status ||
                                    (currentTodo$ | async)?.completed
                                  "
                                >
                                  {{ sujetsArray.controls[i].value.text }}
                                </strong>
                              </nz-col>
                              <nz-col nzSpan="6">
                                <button nz-button nzBlock nzType="text">
                                  <small>Micro tâche</small>
                                </button>
                              </nz-col>
                            </nz-row>
                          </nz-list-header>
                          <nz-list-item
                            *ngFor="
                              let itemList of sujetsArray.controls[i].value
                                .items?.controls;
                              let iList = index
                            "
                            [formGroupName]="iList"
                          >
                            <ul nz-list-item-actions>
                              <nz-list-item-action>
                                <label
                                  nz-checkbox
                                  formControlName="status"
                                ></label>
                              </nz-list-item-action>
                              <nz-list-item-action> </nz-list-item-action>
                            </ul>

                            <small>{{
                              itemList?.value?.dateStart | dateFr : "small"
                            }}</small>

                            <small *ngIf="itemList?.value?.dateUpdate">{{
                              itemList?.value?.dateUpdate | dateFr : "small"
                            }}</small>

                            <small *ngIf="itemList?.value?.userUpdate">{{
                              itemList?.value?.userUpdate | carLimitation : 25
                            }}</small>

                            <input
                              nz-input
                              formControlName="text"
                              nzBorderless
                              placeholder="Note..."
                            />
                          </nz-list-item>
                        </nz-list>
                      </div>
                      <div *ngSwitchDefault>output2</div>
                    </div>
                  </nz-card>
                </div>
              </ng-container>
            </div>
          </form>
        </nz-card>
      </nz-row>
    </nz-tab>
  </nz-tabset>

  <ng-template #noData>
    <app-no-result
      [description]="'Aucun sujet ajouté'"
      [icon]="'article'"
      [isButton]="false"
    ></app-no-result>
  </ng-template>

  <nz-dropdown-menu #sujetsMenu="nzDropdownMenu">
    <ul nz-menu *ngIf="currentTodo$ | async as todo">
      <li
        nz-menu-item
        *ngFor="let item of sujetItems"
        (click)="addSujet(item.name, todo)"
      >
        <nz-row nzJustify="start" nzAlign="top" nzGutter="4">
          <nz-col nzSpan="8">
            <i
              nz-icon
              [nzType]="item.icon"
              nzTheme="outline"
              class="iconDropDownList"
            ></i>
          </nz-col>
          <nz-col nzSpan="16">{{ item.name | titlecase }}</nz-col>
        </nz-row>
      </li>
    </ul>
  </nz-dropdown-menu>

  <nz-dropdown-menu #sujetsFilters="nzDropdownMenu">
    <ul nz-menu *ngIf="currentTodo$ | async as todo">
      <li nz-menu-item>Retirer le filtre</li>
      <li nz-menu-item>Priorité</li>
      <li nz-menu-item>Non priorité</li>
      <li nz-menu-item>Réalisés</li>
      <li nz-menu-item>En attentes</li>
    </ul>
  </nz-dropdown-menu>
</ng-container>
