import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  FormGroupName,
  Validators,
} from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromUtilitiesAction from 'src/app/contents/components/utilities/store/utilities.actions';
import * as fromUtilitiesSelector from 'src/app/contents/components/utilities/store/utilities.selectors';
import { Timestamp } from 'firebase/firestore';
import { IPerson } from '../../store/person.model';
import { UtilitiesState } from 'src/app/contents/components/utilities/store/utilities.reducer';

@Component({
  selector: 'app-person',
  templateUrl: './person.component.html',
  styleUrls: ['./person.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonComponent implements OnInit, OnChanges {
  subscribe = new Subject();
  civilite$ = this.utilitiesStore.select(fromUtilitiesSelector.civilities);
  genres$ = this.utilitiesStore.select(fromUtilitiesSelector.genre);
  dateFormat: string = 'dd/MM/yyyy';

  //FORM FIELDS
  @Input() personFormGroup: FormGroup;

  @Input() civilite: FormControl;
  @Input() lastName: FormControl;
  @Input() firstName: FormControl;
  @Input() coordonnees: FormControl | any = new FormControl<{
    mobile: string;
    phoneNumber: string;
    fixe: string;
    email: string;
  }>(
    {
      mobile: '',
      phoneNumber: '',
      fixe: '',
      email: '',
    },
    Validators.required
  );
  @Input() dateNaissance: FormControl | any = new FormControl();
  @Input() role: FormControl;
  @Input() fonction: FormControl;
  @Input() genre: FormControl | any = new FormControl();
  @Input() age: FormControl | any = new FormControl<number>(0);
  @Input() tranche: FormControl | any = new FormControl();

  adresseItem = new FormControl<{
    adresse: {
      properties: {
        label: string;
        score: number;
        housenumber: string;
        id: string;
        type: string;
        name: string;
        postcode: string;
        citycode: string;
        x: number;
        y: number;
        city: string;
        context: string;
        importance: number;
        street: string;
      };
      geometry: {
        type: string;
        coordinates: [number];
      };
    };
    complement: string;
    qpv: any;
  }>(
    {
      adresse: {
        properties: {
          label: '',
          score: 0,
          housenumber: '',
          id: '',
          type: '',
          name: '',
          postcode: '',
          citycode: '',
          x: 0,
          y: 0,
          city: '',
          context: '',
          importance: 0,
          street: '',
        },
        geometry: {
          type: '',
          coordinates: [0],
        },
      },
      complement: '',
      qpv: '',
    },
    Validators.required
  );

  @Input() isCoordonnees = true;
  @Input() isAdresse = true;
  @Input() isNaissance = true;
  @Input() isRole = true;
  @Input() roleTitle: string = 'Rôle du contact...';
  @Input() isFonction: boolean = true;
  @Input() isEmailRequired: boolean = true;
  @Input() isMobileRequired: boolean = true;
  @Input() isFixeRequired: boolean = true;
  @Input() isAdresseRequired: boolean = true;

  @Output() currentChanges = new EventEmitter(false);

  constructor(
    private fb: FormBuilder,
    private formCtrl: FormGroupDirective,
    private utilitiesStore: Store<UtilitiesState>
  ) {
    this.loadUtilitiesCivilite();
    this.loadUtilitiesGenres();
  }

  ngOnInit(): void {
    //this.formSet();
    //this.onFormChanges();
  }
  ngOnChanges(changes: SimpleChanges): void {}

  formSet(): void {}

  loadUtilitiesCivilite(): void {
    this.utilitiesStore.dispatch(fromUtilitiesAction.loadCivilities());
  }
  getUtilitiesCivilite(): void {
    this.civilite$ = this.utilitiesStore.select(
      fromUtilitiesSelector.civilities
    );
  }

  loadUtilitiesGenres(): void {
    this.utilitiesStore.dispatch(fromUtilitiesAction.loadCivilities());
  }
  getUtilitiesGenres(): void {
    this.genres$ = this.utilitiesStore.select(fromUtilitiesSelector.genre);
  }

  onCivilite(civilite: string): void {
    if (!civilite) return;
    //this.personForm.patchValue({ civilite: civilite });
  }

  onAdresse(adresseInput: any): void {
    if (!adresseInput) return;

    // const prevAdresse = this.personForm.get('adresse')?.value;
    // this.personForm.patchValue({
    //   adresse: {
    //     ...prevAdresse,
    //     adresse: adresseInput,
    //   },
    // });
  }

  onMobile(event: any): void {
    if (!event) return;
    //const coordonnees = this.personForm.get('coordonnees')?.value;
    // this.personForm.patchValue({
    //   coordonnees: {
    //     ...coordonnees,
    //     mobile: event.mobile,
    //     phoneNumber: event.phoneNumber,
    //   },
    // });
  }
  onFixe(fixe: any): void {
    if (!fixe) return;
    // const coordonnees = this.personForm.get('coordonnees')?.value;

    // this.personForm.patchValue({
    //   coordonnees: {
    //     ...coordonnees,
    //     fixe: fixe,
    //   },
    // });
  }
  onEmail(email: any): void {
    if (!email) return;
    // const coordonnees = this.personForm.get('coordonnees')?.value;

    // this.personForm.patchValue({
    //   coordonnees: {
    //     ...coordonnees,
    //     email: email,
    //   },
    // });
  }

  setGenre(civilite: string): string {
    switch (civilite) {
      case 'M':
        return 'Masculin';
        break;
      case 'Mme':
        return 'Féminin';
        break;
      default:
        return 'Autre';
        break;
    }
  }

  onFormChanges(): void {
    // this.personForm.valueChanges
    //   .pipe(takeUntil(this.subscribe))
    //   .subscribe((values) => {
    //     const { civilite, dateNaissance } = values;
    //     const today = new Date(Date.now());
    //     const genre = this.setGenre(civilite);
    //     const date = dateNaissance
    //       ? Timestamp.fromDate(dateNaissance)
    //       : Timestamp.fromDate(today);
    //     const person = {
    //       ...values,
    //       genre: genre,
    //       dateNaissance: date,
    //     };
    //     this.currentChanges.emit(person);
    //   });
  }
}
