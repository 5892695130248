<ng-container *ngIf="user$ | async as user">
  <nz-list
    nzBordered
    nzSize="small"
    class="list__TICKETS"
    *ngIf="!(loading$ | async); else skeleton"
  >
    <li
      nz-list-item
      *ngFor="let item of tickets$ | async | sortBy: 'dateStart':'des':'date'"
      nzNoFlex
    >
      <ul nz-list-item-actions>
        <nz-list-item-action *ngIf="user?.claims?.dev">
          <button
            nz-button
            nzSize="small"
            nzType="text"
            (click)="onSelect(item)"
          >
            <small>Afficher</small>
          </button>
          <button
            nz-button
            nzSize="small"
            nzType="text"
            (click)="onDelete(item)"
          >
            <i nz-icon nzType="delete"></i>
          </button>
        </nz-list-item-action>
      </ul>
      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col nzSpan="4">
          <small>{{ item?.dateStart | dateFr: "time" }}</small>
        </nz-col>
        <nz-col nzSpan="4">
          <nz-tag nzColor="purple" *ngIf="item?.type === 'Bug mineur'">{{
            item?.type
          }}</nz-tag>
          <nz-tag nzColor="error" *ngIf="item?.type === 'Bug majeur'">{{
            item?.type
          }}</nz-tag>
        </nz-col>
        <nz-col nzSpan="12">
          <span *ngIf="!item?.statut">{{ item?.message }}</span>
          <del *ngIf="item?.statut">{{ item?.message }}</del>
        </nz-col>
        <nz-col nzSpan="4">
          <nz-tag nzColor="processing" *ngIf="!item?.statut">
            Pris en charge
          </nz-tag>
          <nz-tag nzColor="success" *ngIf="item?.statut">Résolu</nz-tag>
        </nz-col>
      </nz-row>
    </li>
  </nz-list>
</ng-container>

<ng-template #skeleton>
  <app-skeleton></app-skeleton>
</ng-template>
