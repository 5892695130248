<nz-spin
  nzSimple
  [nzSize]="'large'"
  [nzIndicator]="spin"
  *ngIf="loading"
></nz-spin>

<ng-template #spin>
  <ng-lottie
    [options]="options"
    (animationCreated)="animationCreated($event)"
    width="350px"
    height="350px"
  ></ng-lottie>
</ng-template>
<div class="spinner"></div>

<div [@routeAnimations]="getRouteAnimationData()">
  <router-outlet> </router-outlet>
</div>
