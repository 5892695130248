<nz-card
  [nzBorderless]="isBorderless"
  nzSize="small"
  [nzBodyStyle]="{ height: heigth, width: width }"
  [nzTitle]="isTitle ? chartCardTitle : ''"
  [nzCover]="chartsContainerTpl"
  [nzActions]="[chartCardFooter]"
  #chartsContainerRef
  maximize
  #maximize="maximize"
>
  <ng-template #chartCardTitle>
    <app-header-title
      *ngIf="isTitle"
      [icon]="icon"
      [subtitle]="true"
      [title]="(source | async)?.title"
    ></app-header-title>
  </ng-template>

  <ng-template #chartCardFooter>
    <small>{{ (source | async)?.subtitle }}</small>
  </ng-template>
</nz-card>

<ng-template #chartsContainerTpl>
  <ng-container *ngIf="options; else noData">
    <div
      #chart
      echarts
      [options]="options"
      [theme]="(mode$ | async) === 'dark' ? 'dark' : 'light'"
      [ngStyle]="{
        height: (source | async)?.height ? (source | async)?.height : '200px'
      }"
      class="charts"
    ></div>
  </ng-container>
</ng-template>

<ng-template #chartExtra>
  <div maximize #maximize="maximize">
    <button
      nz-button
      nzShape="circle"
      nzType="text"
      (click)="maximize.minimize()"
    >
      <mat-icon>fullscreen_exit</mat-icon>
    </button>

    <button
      nz-button
      nzShape="circle"
      nzType="text"
      (click)="maximize.maximize()"
    >
      <mat-icon>fullscreen</mat-icon>
    </button>
  </div>
</ng-template>

<ng-template #noData>
  <app-no-result [icon]="'query_stats'"></app-no-result>
</ng-template>
