import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';
import { ProjetPrpfessionnelState } from '../../store/projet-professionnel.reducer';
import * as fromProjetProAction from '../../store/projet-professionnel.actions';
import * as fromProjetProSelector from '../../store/projet-professionnel.selectors';

@Component({
  selector: 'app-projet-professionnel-entites-form',
  templateUrl: './projet-professionnel-entites-form.component.html',
  styleUrls: ['./projet-professionnel-entites-form.component.scss'],
})
export class ProjetProfessionnelEntitesFormComponent implements OnInit {
  loading$: Observable<boolean> = of(false);
  entites$: Observable<any> = of(null);
  currentFormationDetails$: Observable<any> = of(null);
  subscribe = new Subject();
  current = new BehaviorSubject<any>(null);
  view$ = new BehaviorSubject<string>('list');
  distanceSelectors: string[] = ['10', '30', '50', '100', '200'];
  contractSelectors: any[] = [
    { name: 'Emploi', value: 'dpae' },
    { name: 'Alternance', value: 'alternance' },
  ];

  newCardTitle: string = 'Nouvelle entité projet professionnel';
  newCardDescription: string = 'Ajouter une entité au projet professionnel';
  newCardDefinition: string =
    'Une entité projet professionnel est une companie en capacité de participer à la réalisation du projet professionnel.';

  currentDetails = new BehaviorSubject<string>('Accès');
  @Output() cancel = new EventEmitter<any>(false);
  @Output() add = new EventEmitter<any>(false);
  @Input() item = new BehaviorSubject<any>(null);
  @Input() participant = new BehaviorSubject<any>(null);

  projetproEntiteForm: UntypedFormGroup = this.fb.group({});
  code: UntypedFormControl = new UntypedFormControl('');
  contract: UntypedFormControl = new UntypedFormControl('');
  distance: UntypedFormControl = new UntypedFormControl('');
  page: UntypedFormControl = new UntypedFormControl(1);
  longitude: UntypedFormControl = new UntypedFormControl('');
  latitude: UntypedFormControl = new UntypedFormControl('');

  constructor(
    private projetProfessionnelStore: Store<ProjetPrpfessionnelState>,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.getLoading();
    this.form();
    this.setProjetVariables();
    this.getEntitesSuggestions();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  getLoading(): void {
    this.loading$ = this.projetProfessionnelStore.select(
      fromProjetProSelector.loading
    );
  }
  form(): void {
    this.formInit();
    this.formCreate();
    this.setProjetVariables();
  }

  formInit(): void {
    this.code = new UntypedFormControl('', Validators.required);
    this.contract = new UntypedFormControl('dpae', Validators.required);
    this.distance = new UntypedFormControl('10', Validators.required);
    this.latitude = new UntypedFormControl('', Validators.required);
    this.longitude = new UntypedFormControl('', Validators.required);
    this.page = new UntypedFormControl(1);
  }

  formCreate(): void {
    this.projetproEntiteForm = this.fb.group({
      code: this.code,
      contract: this.contract,
      distance: this.distance,
      latitude: this.latitude,
      longitude: this.longitude,
      page: this.page,
    });
  }

  validedSearch(): void {
    if (!this.projetproEntiteForm.valid) return;
    const values = this.projetproEntiteForm.value;
    const { code, distance, latitude, longitude, contract, page } = values;
    this.projetProfessionnelStore.dispatch(
      fromProjetProAction.loadEntitesSuggestions({
        code,
        contract,
        distance,
        latitude,
        longitude,
        page,
      })
    );
  }
  onPage(page: number): void {
    if (!this.projetproEntiteForm.valid) return;
    const values = this.projetproEntiteForm.value;
    const { code, distance, latitude, longitude, contract } = values;
    this.projetProfessionnelStore.dispatch(
      fromProjetProAction.loadEntitesSuggestions({
        code,
        contract,
        distance,
        latitude,
        longitude,
        page,
      })
    );
  }

  onPaggination(type: string, total: number): void {
    if (!this.projetproEntiteForm.valid) return;
    const values = this.projetproEntiteForm.value;
    const increment = values.page + 1;
    const decriment = values.page - 1;
    const prev = type === 'prev' && decriment > 1 ? values.page - 1 : 1;
    const next = type === 'next' && increment <= total ? increment : total;

    const page: number = type === 'prev' ? prev : next;

    this.onPage(page);
  }

  getEntitesSuggestions(): void {
    this.entites$ = this.projetProfessionnelStore.select(
      fromProjetProSelector.entitesSuggestions
    );
  }

  getCurrentFormationDetails(): void {
    this.currentFormationDetails$ = this.projetProfessionnelStore.select(
      fromProjetProSelector.formationSuggestionDetail
    );
  }
  setProjetVariables(): void {
    this.item.pipe(takeUntil(this.subscribe)).subscribe((projetPro: any) => {
      if (!projetPro) return;
      const { adresse, code } = projetPro;
      const { geometry } = adresse;
      let latitude = geometry.coordinates[1].toString();
      let longitude = geometry.coordinates[0].toString();
      this.projetproEntiteForm.patchValue({
        code: code,
        distance: '10',
        latitude: latitude,
        longitude: longitude,
        page: 1,
      });
    });
  }

  onSelect(item: any, projetpro: any, participant: any): void {
    if (!item) return;

    this.add.emit(item);
  }

  onSelectDetailElement(event: any): void {}
  onNew(participant: any, projetpro: any): void {}

  onBack(): void {
    this.view$.next('list');
    this.current.next(null);
  }

  onAdd(item: any): void {
    console.log(item);
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }
}
