import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  StateDocument,
  documentsFeatureKey,
  selectAll,
} from './document.reducer';

export const selectDocumentState =
  createFeatureSelector<StateDocument>(documentsFeatureKey);

export const selectDocuments = createSelector(selectDocumentState, selectAll);

export const filters = createSelector(
  selectDocumentState,
  (state: StateDocument) => {
    return state.filters;
  }
);

export const loading = createSelector(
  selectDocumentState,
  (state: StateDocument) => {
    return state.loading;
  }
);

export const error = createSelector(
  selectDocumentState,
  (state: StateDocument) => {
    return state.error;
  }
);
