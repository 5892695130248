import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  NzNotificationPlacement,
  NzNotificationService,
} from 'ng-zorro-antd/notification';
import { mergeMap, map, catchError, of, tap } from 'rxjs';
import { ParticipantAccompagnementService } from 'src/app/service/participant/accompagnement/participant-accompagnement.service';
import * as fromAccompagnementAction from './participant-action-accompagnement.actions';
import { ParticipantAccompagnementState } from './participant-action-accompagnement.reducer';

const NOTIFICATION_PLACEMENT: NzNotificationPlacement = 'bottomRight';

@Injectable()
export class ParticipantActionAccompagnementEffects {
  loadAccompagnementTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAccompagnementAction.loadParticipantAccompagnementTypes),
      mergeMap(() =>
        this.accompagnemtService.getAccompagnementTypes().pipe(
          map((respons: any) => {
            const types: any[] = respons.data.referentiel_accompagnement_types;
            return fromAccompagnementAction.loadParticipantAccompagnementTypesSuccess(
              { types }
            );
          }),
          catchError((error) =>
            of(
              fromAccompagnementAction.loadParticipantAccompagnementTypesFailure(
                { error }
              )
            )
          )
        )
      )
    )
  );

  loadAccompagnementChampsTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAccompagnementAction.loadParticipantAccompagnementChampsTypes),
      mergeMap(() =>
        this.accompagnemtService.getAccompagnementChampTypes().pipe(
          map((respons: any) => {
            const champs: any[] =
              respons.data.referentiel_accompagnement_champs_types;
            return fromAccompagnementAction.loadParticipantAccompagnementChampsTypesSuccess(
              { champs }
            );
          }),
          catchError((error) =>
            of(
              fromAccompagnementAction.loadParticipantAccompagnementChampsTypesFailure(
                { error }
              )
            )
          )
        )
      )
    )
  );

  loadAccompagnements$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAccompagnementAction.loadParticipantActionAccompagnements),
      mergeMap((action) =>
        this.accompagnemtService.getAccompagnements(action.id).pipe(
          map((respons: any) => {
            const accompagnements: any[] = respons.data.accompagnements;
            return fromAccompagnementAction.loadParticipantActionAccompagnementsSuccess(
              { accompagnements }
            );
          }),
          catchError((error) =>
            of(
              fromAccompagnementAction.loadParticipantActionAccompagnementsFailure(
                { error }
              )
            )
          )
        )
      )
    )
  );

  loadAccompagnement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAccompagnementAction.loadParticipantActionAccompagnement),
      mergeMap((action) =>
        this.accompagnemtService
          .getAccompagnement(action.participantId, action.id)
          .pipe(
            map((respons: any) => {
              const accompagnement: any[] = respons.data.accompagnement;
              return fromAccompagnementAction.loadParticipantActionAccompagnementSuccess(
                { accompagnement }
              );
            }),
            catchError((error) =>
              of(
                fromAccompagnementAction.loadParticipantActionAccompagnementFailure(
                  { error }
                )
              )
            )
          )
      )
    )
  );

  createAccompagnement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAccompagnementAction.addParticipantActionAccompagnement),
      tap(() => {
        this.notification.info(
          'Nouveau',
          "Création du nouveau champs d'accompagnement en cours...",
          {
            nzKey: 'CREATE_PARTICIPANT_ACCOMPAGNEMENT',
            nzPlacement: NOTIFICATION_PLACEMENT,
            nzDuration: 6000,
          }
        );
      }),
      mergeMap((action) =>
        this.accompagnemtService
          .createAccompagnement(action.participantId, action.accompagnement)
          .pipe(
            map((respons: any) => {
              const accompagnement: any = respons.data.createAccompagnement;
              this.notification.success(
                'Nouveau',
                `${accompagnement.champs.libelle} ajouté avec succès`,
                {
                  nzKey: 'CREATE_PARTICIPANT_ACCOMPAGNEMENT',
                  nzPlacement: NOTIFICATION_PLACEMENT,
                  nzDuration: 6000,
                }
              );
              this.participantActionStore.dispatch(
                fromAccompagnementAction.loadParticipantActionAccompagnements({
                  id: action.participantId,
                })
              );
              return fromAccompagnementAction.addParticipantActionAccompagnementSuccess(
                { accompagnement }
              );
            }),
            catchError((error) => {
              this.onErrorNotification(
                error,
                'CREATE_PARTICIPANT_ACCOMPAGNEMENT'
              );
              return of(
                fromAccompagnementAction.addParticipantActionAccompagnementFailure(
                  { error }
                )
              );
            }),
            tap(() => {
              return fromAccompagnementAction.loadParticipantActionAccompagnements;
            })
          )
      )
    )
  );

  updateAccompagnement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAccompagnementAction.updateParticipantActionAccompagnement),
      tap(() => {
        this.notification.info(
          'Modification',
          "Modification du champs d'accompagnement en cours...",
          {
            nzKey: 'UPDATE_PARTICIPANT_ACCOMPAGNEMENT',
            nzPlacement: NOTIFICATION_PLACEMENT,
            nzDuration: 6000,
          }
        );
      }),
      mergeMap((action) =>
        this.accompagnemtService
          .updateAccompagnement(action.participantId, action.accompagnement)
          .pipe(
            map((respons: any) => {
              const accompagnement: any = respons.data.updateAccompagnement;
              this.notification.success(
                'Modification',
                `${accompagnement.type.libelle} modifié avec succès`,
                {
                  nzKey: 'UPDATE_PARTICIPANT_ACCOMPAGNEMENT',
                  nzPlacement: NOTIFICATION_PLACEMENT,
                  nzDuration: 6000,
                }
              );

              return fromAccompagnementAction.updateParticipantActionAccompagnementSuccess(
                { accompagnement }
              );
            }),
            catchError((error) => {
              this.onErrorNotification(
                error,
                'UPDATE_PARTICIPANT_ACCOMPAGNEMENT'
              );
              return of(
                fromAccompagnementAction.updateParticipantActionAccompagnementFailure(
                  { error }
                )
              );
            })
          )
      )
    )
  );

  copyAccompagnement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAccompagnementAction.copyParticipantActionAccompagnement),
      tap(() => {
        this.notification.info(
          'Dubliquer',
          "Duplication du champs d'accompagnement en cours...",
          {
            nzKey: 'COPY_PARTICIPANT_ACCOMPAGNEMENT',
            nzPlacement: NOTIFICATION_PLACEMENT,
            nzDuration: 6000,
          }
        );
      }),
      mergeMap((action) =>
        this.accompagnemtService
          .copyAccompagnement(action.participantId, action.id)
          .pipe(
            map((respons: any) => {
              const accompagnement: any = respons.data.updateAccompagnement;
              this.notification.success(
                'Copier',
                `${accompagnement.title} dupliqué avec succès`,
                {
                  nzKey: 'COPY_PARTICIPANT_ACCOMPAGNEMENT',
                  nzPlacement: NOTIFICATION_PLACEMENT,
                  nzDuration: 6000,
                }
              );
              this.participantActionStore.dispatch(
                fromAccompagnementAction.loadParticipantActionAccompagnements({
                  id: action.participantId,
                })
              );

              return fromAccompagnementAction.copyParticipantActionAccompagnementSuccess(
                { accompagnement }
              );
            }),
            catchError((error) => {
              this.onErrorNotification(
                error,
                'COPY_PARTICIPANT_ACCOMPAGNEMENT'
              );
              return of(
                fromAccompagnementAction.copyParticipantActionAccompagnementFailure(
                  { error }
                )
              );
            })
          )
      )
    )
  );

  deleteAccompagnement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAccompagnementAction.deleteParticipantActionAccompagnement),
      tap(() => {
        this.notification.info(
          'Suppression',
          "Suppression du champs d'accompagnement en cours...",
          {
            nzKey: 'DELETE_PARTICIPANT_ACCOMPAGNEMENT',
            nzPlacement: NOTIFICATION_PLACEMENT,
            nzDuration: 6000,
          }
        );
      }),
      mergeMap((action) =>
        this.accompagnemtService
          .deleteAccompagnement(action.participantId, action.id)
          .pipe(
            map((respons: any) => {
              const accompagnements: any = respons.data.deleteAccompagnement;
              this.notification.success(
                'Suppression',
                `Champs d'accompagnement supprimé avec succès`,
                {
                  nzKey: 'DELETE_PARTICIPANT_ACCOMPAGNEMENT',
                  nzPlacement: NOTIFICATION_PLACEMENT,
                  nzDuration: 6000,
                }
              );
              this.participantActionStore.dispatch(
                fromAccompagnementAction.loadParticipantActionAccompagnements({
                  id: action.participantId,
                })
              );

              return fromAccompagnementAction.deleteParticipantActionAccompagnementSuccess(
                { accompagnements }
              );
            }),
            catchError((error) => {
              this.onErrorNotification(
                error,
                'DELETE_PARTICIPANT_ACCOMPAGNEMENT'
              );
              return of(
                fromAccompagnementAction.deleteParticipantActionAccompagnementFailure(
                  { error }
                )
              );
            }),
            tap(() => {
              return fromAccompagnementAction.loadParticipantActionAccompagnements;
            })
          )
      )
    )
  );

  deleteAccompagnements$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAccompagnementAction.deleteParticipantActionAccompagnements),
      tap(() => {
        this.notification.info(
          'Tout supprimer',
          "Suppression des champs d'accompagnement en cours...",
          {
            nzKey: 'DELETE_ALL_PARTICIPANT_ACCOMPAGNEMENTS',
            nzPlacement: NOTIFICATION_PLACEMENT,
            nzDuration: 6000,
          }
        );
      }),
      mergeMap((action) =>
        this.accompagnemtService
          .deleteAccompagnements(action.participantId)
          .pipe(
            map((respons: any) => {
              const success: any = respons.data.deleteAccompagnements;
              this.notification.success(
                'Tout supprimer',
                `Les champs d'accompagnement ont été supprimés avec succès`,
                {
                  nzKey: 'DELETE_ALL_PARTICIPANT_ACCOMPAGNEMENTS',
                  nzPlacement: NOTIFICATION_PLACEMENT,
                  nzDuration: 6000,
                }
              );

              this.participantActionStore.dispatch(
                fromAccompagnementAction.loadParticipantActionAccompagnements({
                  id: action.participantId,
                })
              );
              return fromAccompagnementAction.deleteParticipantActionAccompagnementsSuccess(
                { success }
              );
            }),
            catchError((error) => {
              this.onErrorNotification(
                error,
                'DELETE_ALL_PARTICIPANT_ACCOMPAGNEMENTS'
              );
              return of(
                fromAccompagnementAction.deleteParticipantActionAccompagnementsFailure(
                  { error }
                )
              );
            })
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private accompagnemtService: ParticipantAccompagnementService,
    private notification: NzNotificationService,
    private participantActionStore: Store<ParticipantAccompagnementState>
  ) {}

  onErrorNotification(error: any, key: string): void {
    this.notification.error('Error', error.message, {
      nzDuration: 10000,
      nzAnimate: true,
      nzPlacement: NOTIFICATION_PLACEMENT,
      nzKey: key ? key : '',
    });
  }
}
