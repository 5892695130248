import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { OperationFicheCandidatDocument } from './operation-fiche-candidat-document.model';
import * as OperationFicheCandidatDocumentActions from './operation-fiche-candidat-document.actions';

export const operationFicheCandidatDocumentsFeatureKey =
  'operationFicheCandidatDocuments';

export interface OperationFicheCandidatDocumentState
  extends EntityState<OperationFicheCandidatDocument> {
  loading: boolean;
  error: any;
  document: any;
  success: string | null;
  view: string;
}

export const adapter: EntityAdapter<OperationFicheCandidatDocument> =
  createEntityAdapter<OperationFicheCandidatDocument>();

export const initialState: OperationFicheCandidatDocumentState =
  adapter.getInitialState({
    loading: false,
    error: undefined,
    document: undefined,
    success: null,
    view: 'display',
  });

export const reducer = createReducer(
  initialState,

  /**+
   * Indicateur de chargement
   */
  on(
    OperationFicheCandidatDocumentActions.loadingOperationFicheCandidatDocuments,
    (state, action) => {
      return {
        ...state,

        loading: action.loading,
      };
    }
  ),

  /**+
   * Afficher les fenêtres
   */
  on(
    OperationFicheCandidatDocumentActions.setViewOperationFicheCandidatDocuments,
    (state, action) => {
      return {
        ...state,
        view: action.view,
      };
    }
  ),

  /**
   * Trouver les documents du candidat
   */
  on(
    OperationFicheCandidatDocumentActions.loadOperationFicheCandidatDocuments,
    (state, action) => adapter.removeAll(state)
  ),
  on(
    OperationFicheCandidatDocumentActions.loadOperationFicheCandidatDocumentsSuccess,
    (state, action) => adapter.setAll(action.documents, state)
  ),
  on(
    OperationFicheCandidatDocumentActions.loadOperationFicheCandidatDocumentsFailure,
    (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
  ),

  /**
   * Trouver un document du candidat
   */
  on(
    OperationFicheCandidatDocumentActions.loadOperationFicheCandidatDocument,
    (state, action) => {
      return {
        ...state,
        loading: false,
        document: undefined,
        error: undefined,
      };
    }
  ),
  on(
    OperationFicheCandidatDocumentActions.loadOperationFicheCandidatDocumentSuccess,
    (state, action) => {
      return {
        ...state,
        document: action.document,
        loading: false,
        error: undefined,
      };
    }
  ),
  on(
    OperationFicheCandidatDocumentActions.loadOperationFicheCandidatDocumentsFailure,
    (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.error,
        document: undefined,
      };
    }
  ),

  /**
   * Création de nouveaux documents
   */

  on(
    OperationFicheCandidatDocumentActions.addOperationFicheCandidatDocumentSuccess,
    (state, action) => adapter.addOne(action.document, state)
  ),

  on(
    OperationFicheCandidatDocumentActions.addOperationFicheCandidatDocumentFailure,
    (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.error,
        document: undefined,
      };
    }
  ),

  /**
   * Suppresion d'un document du candidat
   */
  on(
    OperationFicheCandidatDocumentActions.deleteOperationFicheCandidatDocument,
    (state, action) => adapter.removeOne(action.documentId, state)
  ),
  on(
    OperationFicheCandidatDocumentActions.deleteOperationFicheCandidatDocumentSuccess,
    (state, action) => {
      return {
        ...state,
        loading: false,
        success: action.success,
      };
    }
  ),
  on(
    OperationFicheCandidatDocumentActions.deleteOperationFicheCandidatDocumentFailure,
    (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.error,
        document: undefined,
      };
    }
  ),

  /**
   * Suppression de tous les documents du candidat
   */
  on(
    OperationFicheCandidatDocumentActions.deleteOperationFicheCandidatDocuments,
    (state, action) => adapter.removeAll(state)
  ),
  on(
    OperationFicheCandidatDocumentActions.deleteOperationFicheCandidatDocumentsSuccess,
    (state, action) => {
      return {
        ...state,
        loading: false,
        success: action.success,
      };
    }
  ),
  on(
    OperationFicheCandidatDocumentActions.deleteOperationFicheCandidatDocumentsFailure,
    (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.error,
        document: undefined,
      };
    }
  ),

  /**
   * Reinitialiser le store de document
   */
  on(
    OperationFicheCandidatDocumentActions.clearOperationFicheCandidatDocuments,
    (state) => adapter.removeAll(state)
  )
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
