<app-tag-selector
  *ngIf="currentAdresse$ | async; else adresseSearch"
  [text]="currentAdresse$ | async"
  (clear)="onClearAdresse()"
></app-tag-selector>

<ng-template #adresseSearch>
  <nz-row nzAlign="middle" nzJustify="start" nzGutter="8">
    <nz-col [nzSpan]="(loading$ | async) ? 2 : 0">
      <i nz-icon nzType="loading" class="loading"></i>
    </nz-col>
    <nz-col
      [nzSpan]="(loading$ | async) ? 22 : isComplement ? 16 : 24"
      [ngClass]="{ borderless: borderless }"
    >
      <form [formGroup]="adresseForm" class="w-full flex flex-col items-start">
        <label [class]="required ? 'label required' : 'label'" *ngIf="isLabel"
          >Adresse</label
        >

        <div class="select-menu">
          <nz-select
            [nzBorderless]="true"
            nzAllowClear
            nzShowSearch
            nzPlaceHolder="Adresse..."
            (nzOnSearch)="searchControl.setValue($event)"
            [(ngModel)]="searchSelect"
            (ngModelChange)="select_GEO_ADRESSE($event)"
            [nzNotFoundContent]="noData"
            [compareWith]="compareAdresse"
            nzServerSearch
            [ngModelOptions]="{ standalone: true }"
          >
            <nz-option
              *ngFor="
                let adresse of GEO_ADRESSES$ | async as adresses;
                index as i;
                trackBy: trackByFn
              "
              [nzValue]="adresse"
              [nzLabel]="adresse.properties.label"
              nzCustomContent
            >
              <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
                <nz-col nzSpan="2">
                  <mat-icon>rooms</mat-icon>
                </nz-col>
                <nz-col nzSpan="22">
                  <strong>{{ adresse.properties.label }}</strong>
                  <br />
                  <small>{{ adresse?.properties?.context }}</small>
                </nz-col>
              </nz-row>
            </nz-option>
          </nz-select>
        </div>
        <small
          *ngIf="!searchForm.valid && searchForm.touched"
          class="inputFormAlert"
          >{{
            !searchForm.valid && searchForm.touched
              ? "Veuillez renseigner une adresse"
              : ""
          }}</small
        >
      </form>
    </nz-col>

    <nz-col
      [nzSpan]="isComplement ? 8 : 0"
      [ngClass]="{ borderless: borderless }"
    >
      <form
        [formGroup]="adresseForm"
        class="w-full flex flex-col items-center justify-start"
      >
        <label [class]="required ? 'label required' : 'label'" *ngIf="isLabel"
          >Complément</label
        >
        <input
          class="inputForm"
          placeholder="Adresse complémentaire"
          [(ngModel)]="complementValue"
          (ngModelChange)="complement.setValue($event)"
          [ngModelOptions]="{ standalone: true }"
        />
      </form>
    </nz-col>
  </nz-row>
</ng-template>
<ng-template #noData>
  <app-no-result
    [icon]="'room'"
    [description]="'Aucune adresse trouvée'"
    [isButton]="false"
  ></app-no-result>
</ng-template>
