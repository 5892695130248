import { flattenDeep } from 'lodash';

export const arrayToMap = (items: any[], property: string): any => {
  if (!items?.length || !property) {
    return;
  }

  return items.reduce((acc: any, cur: any) => {
    acc[cur[property]] = cur;
    return acc;
  }, {});
};

export const mapToArray = (items: any): any[] => {
  if (!items) {
    return [];
  }

  return flattenDeep(Object.entries(items));
};
