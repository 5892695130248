<form nz-form [formGroup]="aidForm" nzLayout="vertical">
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="24">
      <nz-form-item>
        <nz-form-label nzRequired nzSpan="24">Titre</nz-form-label>
        <nz-form-control>
          <input
            class="title"
            nz-input
            formControlName="name"
            nzSize="large"
            placeholder="Titre de l'allocation"
            nzBorderless
          />
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>
  <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="12">
      <nz-form-item>
        <nz-form-label nzRequired nzSpan="24">Description</nz-form-label>
        <nz-form-control class="aidFormItem">
          <textarea
            nz-input
            formControlName="what"
            placeholder="Description de l'allocation"
            nzBorderless
            [nzAutosize]="{ minRows: 6, maxRows: 6 }"
          ></textarea>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col nzSpan="12">
      <nz-form-item>
        <nz-form-label nzRequired nzSpan="24">Conditions</nz-form-label>
        <nz-form-control class="aidFormItem">
          <textarea
            nz-input
            formControlName="how_and_when"
            placeholder="Condition d'attribution de l'allocation"
            nzBorderless
            [nzAutosize]="{ minRows: 6, maxRows: 6 }"
          ></textarea>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="12">
      <nz-form-item>
        <nz-form-label nzRequired nzSpan="24">Montant</nz-form-label>
        <nz-form-control class="aidFormItem">
          <textarea
            nz-input
            formControlName="how_much"
            placeholder="Montant attribué en cas de validation"
            nzBorderless
            [nzAutosize]="{ minRows: 3, maxRows: 3 }"
          ></textarea>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col nzSpan="12">
      <nz-form-item>
        <nz-form-label nzRequired nzSpan="24">Limitation</nz-form-label>
        <nz-form-control class="aidFormItem">
          <textarea
            nz-input
            formControlName="limitations"
            placeholder="Limitation d'attribution de l'allocation"
            nzBorderless
            [nzAutosize]="{ minRows: 3, maxRows: 3 }"
          ></textarea>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>

  <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>

  <nz-row nzAlign="middle" nzGutter="8" nzJustify="end">
    <nz-col nzSpan="16"></nz-col>
    <nz-col nzSpan="4">
      <button
        nz-button
        nzBlock
        (click)="onAdd()"
        nzType="primary"
        [disabled]="!aidForm.valid"
      >
        Ajouter
      </button>
    </nz-col>
    <nz-col nzSpan="4">
      <button nz-button nzBlock (click)="onCancel()" nzType="text">
        <h5 nz-typography>Annuler</h5>
      </button>
    </nz-col>
  </nz-row>
</form>
