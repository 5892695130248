<form nz-form nzLayout="vertical" [formGroup]="participantForm">
  <nz-tabset
    nzCentered
    nzSize="small"
    [nzTabBarGutter]="2"
    (nzSelectedIndexChange)="selectedIndexChange($event, isSortie, isImpact)"
  >
    <nz-tab
      [nzTitle]="identity"
      class="identity__PARTICIPANT"
      *ngIf="
        (add || update || isIdentity) &&
        !isSortie &&
        !isSortie &&
        !isImpact &&
        !isImpact6 &&
        !isImpact12
      "
    >
      <ng-template #identity>
        <app-tab-title
          [title]="'Identité'"
          [isActiv]="tabActiv === 'identity' ? true : false"
        ></app-tab-title>
      </ng-template>
      <!-- IDENTITY -->
      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <!-- civility -->

        <nz-col [nzSpan]="(loading$ | async) ? 2 : 0">
          <i
            nz-icon
            nzType="loading"
            class="loading"
            *ngIf="loading$ | async"
          ></i>
        </nz-col>
        <nz-col [nzSpan]="(loading$ | async) ? 4 : 6">
          <nz-form-item style="width: 100%">
            <nz-form-label [nzSpan]="24" nzFor="civilite" nzRequired
              >Civilité</nz-form-label
            >

            <nz-form-control>
              <nz-select
                formControlName="civilite"
                placeholder="Civilité"
                nzBorderless
                nzPlaceHolder="Civilité"
              >
                <nz-option
                  *ngFor="let item of civilities$ | async"
                  [nzLabel]="item"
                  [nzValue]="item"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </nz-col>

        <!-- lastName -->
        <nz-col nzSpan="6">
          <nz-form-item>
            <nz-form-label [nzSpan]="18" nzFor="lastName" nzRequired
              >Nom</nz-form-label
            >
            <nz-form-control nzErrorTip="Aucun nom renseigné">
              <input
                nz-input
                nzBorderless
                formControlName="lastName"
                placeholder="Nom"
                id="lastName"
                required
              />
            </nz-form-control>
          </nz-form-item>
        </nz-col>

        <!-- firstName -->
        <nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label [nzSpan]="18" nzFor="firstName" nzRequired
              >Prénom</nz-form-label
            >
            <nz-form-control nzErrorTip="Aucun prénom renseigné">
              <input
                nz-input
                nzBorderless
                formControlName="firstName"
                placeholder="Prénom"
                id="firstName"
                required
              />
            </nz-form-control>
          </nz-form-item>
        </nz-col>
      </nz-row>
      <nz-space [nzSize]="100"></nz-space>

      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col [nzSpan]="add ? 6 : 6">
          <nz-form-item>
            <nz-form-label nzRequired="true" [nzSpan]="24" nzFor="inscription"
              >Date d'inscription
            </nz-form-label>
            <nz-form-control nzErrorTip="Aucun date d'inscription renseignée">
              <nz-date-picker
                nzBorderless
                nzPlaceHolder="Date d'inscription"
                formControlName="dateStart"
                id="inscription"
                nzFormat="dd/MM/yyyy"
              >
              </nz-date-picker>
            </nz-form-control>
          </nz-form-item>
        </nz-col>

        <nz-col [nzSpan]="add ? 6 : 6">
          <nz-form-item>
            <nz-form-label nzRequired="true" [nzSpan]="24" nzFor="inscription"
              >Durée du parcours
            </nz-form-label>
            <nz-form-control nzErrorTip="Aucun durée renseignée">
              <nz-input-number
                [nzMin]="1"
                formControlName="duration"
                nzPlaceHolder="En mois"
              ></nz-input-number>
            </nz-form-control>
          </nz-form-item>
        </nz-col>

        <nz-col [nzSpan]="add ? 12 : 6">
          <nz-form-item style="width: 100%" nzRequired="true">
            <nz-form-label [nzSpan]="24" nzFor="communication" nzRequired="true"
              >Communication
            </nz-form-label>
            <nz-form-control>
              <nz-select
                formControlName="communication"
                placeholder="Type de communication"
                nzBorderless
                nzPlaceHolder="Type de communication"
              >
                <nz-option
                  *ngFor="let item of communications$ | async"
                  [nzLabel]="item"
                  [nzValue]="item"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </nz-col>

        <nz-col [nzSpan]="add ? 0 : 6">
          <nz-form-item *ngIf="!add">
            <nz-form-label [nzSpan]="24" nzFor="nationality"
              >Nationalité
            </nz-form-label>
            <nz-form-control>
              <nz-select
                formControlName="nationality"
                placeholder="Nationalité"
                nzBorderless
                nzPlaceHolder="Nationalité"
                [compareWith]="compareNationality"
              >
                <nz-option
                  *ngFor="
                    let countrie of (utilityIdentity$ | async)?.countries?.data
                  "
                  [nzLabel]="countrie.nationalite | titlecase"
                  [nzValue]="countrie"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </nz-col>
      </nz-row>
      <nz-space [nzSize]="100"></nz-space>

      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start" *ngIf="!add">
        <!-- Date de naissance -->
        <nz-col nzSpan="6">
          <nz-form-item>
            <nz-form-label [nzSpan]="24" nzFor="birthday"
              >Date de naissance
            </nz-form-label>
            <nz-form-control nzErrorTip="Aucune date de naissance renseignée">
              <nz-date-picker
                nzBorderless
                nzPlaceHolder="Date..."
                formControlName="dateNaissance"
                id="birthday"
                nzFormat="dd/MM/yyyy"
              >
              </nz-date-picker>
            </nz-form-control>
          </nz-form-item>
        </nz-col>

        <!-- Âge -->
        <nz-col nzSpan="6">
          <nz-form-item>
            <nz-form-label [nzSpan]="24" nzFor="birthday">Âge </nz-form-label>
            <nz-form-control>
              <nz-input-number
                formControlName="age"
                placeholder="Âge"
              ></nz-input-number>
            </nz-form-control>
          </nz-form-item>

          <!-- <nz-tag nzColor="blue" nzSize="default">
            {{
              participantForm.value?.dateNaissance
                ? (participantForm.value?.dateNaissance | birthday | age)
                : "- Âge"
            }}
          </nz-tag> -->
        </nz-col>

        <!-- Lieu de naissance -->
        <nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label [nzSpan]="18" nzFor="lieuDeNaissance"
              >Lieu de naissance</nz-form-label
            >
            <nz-form-control nzErrorTip="Aucun lieu de naissance renseigné">
              <input
                nz-input
                nzBorderless
                formControlName="lieuDeNaissance"
                placeholder="Lieu de naissance"
                id="lieuDeNaissance"
              />
            </nz-form-control>
          </nz-form-item>
        </nz-col>
      </nz-row>
      <nz-space [nzSize]="100"></nz-space>

      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start" *ngIf="!add">
        <nz-col nzSpan="24">
          <app-coordonnees
            [update]="coordonneesUpdate"
            (mobile)="onMobile($event)"
            (phoneNumber)="onPhoneNumber($event)"
            (fixe)="onFixe($event)"
            (email)="onEmail($event)"
            [borderless]="true"
          >
          </app-coordonnees>
        </nz-col>
      </nz-row>

      <nz-space
        nzDirection="horizontal"
        nzAlign="center"
        [nzSize]="200"
      ></nz-space>
    </nz-tab>

    <nz-tab
      [nzTitle]="situation"
      *ngIf="
        (update || isSituation) &&
        !isSortie &&
        !isImpact &&
        !isImpact6 &&
        !isImpact12
      "
    >
      <ng-template #situation>
        <app-tab-title
          [title]="'Situation'"
          [isActiv]="tabActiv === 'situation' ? true : false"
        ></app-tab-title>
      </ng-template>
      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col [nzSpan]="(loading$ | async) ? 2 : 0">
          <i
            nz-icon
            nzType="loading"
            class="loading"
            *ngIf="loading$ | async"
          ></i>
        </nz-col>
        <!-- Date de validation -->
        <nz-col [nzSpan]="(loading$ | async) ? 6 : 8">
          <nz-form-item>
            <nz-form-label [nzSpan]="24" nzFor="dateValidation"
              >Date de validation
            </nz-form-label>
            <nz-form-control nzErrorTip="Aucun date de validation renseignée">
              <nz-date-picker
                nzBorderless
                nzPlaceHolder="Date de validation"
                formControlName="datetIntegration"
                id="dateValidation"
                nzFormat="dd/MM/yyyy"
              >
              </nz-date-picker>
            </nz-form-control>
          </nz-form-item>
        </nz-col>

        <!-- Niveau diplôme -->
        <nz-col nzSpan="16">
          <nz-form-item style="width: 100%">
            <nz-form-label [nzSpan]="24" nzFor="qualification"
              >Niveau de diplômes
            </nz-form-label>
            <nz-form-control>
              <nz-select
                formControlName="qualification"
                placeholder="Formation"
                nzBorderless
                nzPlaceHolder="Formation"
                [compareWith]="compareDiploma"
              >
                <nz-option
                  *ngFor="let item of (utilityIdentity$ | async)?.diploma?.data"
                  [nzLabel]="item.label"
                  [nzValue]="item"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </nz-col>
      </nz-row>

      <nz-space [nzSize]="100"></nz-space>

      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <!-- Niveau de français -->
        <nz-col nzSpan="8">
          <nz-form-item>
            <nz-form-label [nzSpan]="24" nzFor="maitriseFrancais"
              >Niveau de français oral
            </nz-form-label>

            <nz-form-control>
              <nz-select
                formControlName="maitriseFrancais"
                placeholder="Niveau de français oral"
                nzBorderless
                nzPlaceHolder="Niveau de français oral"
                [compareWith]="compareFrancais"
              >
                <nz-option-group
                  *ngFor="
                    let group of (utilityIdentity$ | async)?.french_level?.data
                  "
                  [nzLabel]="group.type"
                >
                  <nz-option
                    nzCustomContent="true"
                    *ngFor="let item of group.niveau"
                    [nzLabel]="item.item"
                    [nzValue]="item"
                    nz-tooltip
                    [nzTooltipMouseEnterDelay]="0.5"
                    [nzTooltipTitle]="item?.info"
                    nzTooltipPlacement="right"
                    ><p>
                      {{ item.item }}
                    </p>
                  </nz-option>
                </nz-option-group>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </nz-col>

        <!-- Niveau de français écrit-->
        <nz-col nzSpan="8">
          <nz-form-item style="width: 100%">
            <nz-form-label [nzSpan]="24" nzFor="maitriseFrancaisEcrit"
              >Niveau de français écrit
            </nz-form-label>
            <nz-form-control>
              <nz-select
                formControlName="maitriseFrancaisEcrit"
                placeholder="Niveau de français écrit"
                nzBorderless
                nzPlaceHolder="Niveau de français écrit"
                [compareWith]="compareFrancais"
              >
                <nz-option-group
                  *ngFor="
                    let group of (utilityIdentity$ | async)?.french_level?.data
                  "
                  [nzLabel]="group.type"
                >
                  <nz-option
                    nzCustomContent="true"
                    *ngFor="let item of group.niveau"
                    [nzLabel]="item.item"
                    [nzValue]="item"
                    nz-tooltip
                    [nzTooltipMouseEnterDelay]="0.5"
                    [nzTooltipTitle]="item?.info"
                    nzTooltipPlacement="right"
                  >
                    <p>
                      {{ item.item }}
                    </p>
                  </nz-option>
                </nz-option-group>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </nz-col>

        <!-- Inscription pôle emploi-->
        <nz-col nzSpan="8" formGroupName="informations">
          <nz-form-item>
            <nz-form-label [nzSpan]="24" nzFor="inscriptionPoleEmploi"
              >Pôle Emploi
            </nz-form-label>
            <ng-container formGroupName="poleEmploi">
              <nz-form-control>
                <nz-select
                  formControlName="inscription"
                  placeholder="Inscription au Pôle Emploi"
                  nzBorderless
                  nzPlaceHolder="Inscription au Pôle Emploi"
                  [compareWith]="comparePoleEmploi"
                >
                  <nz-option
                    *ngFor="
                      let item of (utilityIdentity$ | async)?.inscription_period
                        ?.data
                    "
                    [nzLabel]="item.label | titlecase"
                    [nzValue]="item"
                  >
                  </nz-option>
                </nz-select>
              </nz-form-control>
            </ng-container>
          </nz-form-item>
        </nz-col>
      </nz-row>
      <nz-space [nzSize]="100"></nz-space>
      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col nzSpan="8">
          <nz-form-item style="width: 100%">
            <nz-form-label [nzSpan]="24" nzFor="minima"
              >Allocation
            </nz-form-label>
            <nz-form-control>
              <nz-select
                formControlName="minimaSociaux"
                placeholder="Allocation"
                nzBorderless
                nzPlaceHolder="Allocation"
                [compareWith]="compareAllocation"
              >
                <nz-option
                  *ngFor="
                    let item of (utilityIdentity$ | async)?.allocation_type
                      ?.data
                  "
                  [nzLabel]="item.value | splitJoin: '_':','"
                  [nzValue]="item"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </nz-col>

        <nz-col nzSpan="8" formGroupName="situationPersonnelle">
          <nz-form-item>
            <nz-form-label [nzSpan]="24">Santé</nz-form-label>
            <nz-form-control>
              <nz-select
                formControlName="sante"
                placeholder="Niveau de santé"
                nzBorderless
                nzPlaceHolder="Niveau de santé"
                [compareWith]="compareSante"
              >
                <nz-option
                  *ngFor="let item of (utilityIdentity$ | async)?.sante?.data"
                  [nzLabel]="item"
                  [nzValue]="item"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </nz-col>

        <nz-col nzSpan="8" formGroupName="situationPersonnelle">
          <nz-form-item style="width: 100%">
            <nz-form-label [nzSpan]="24">Handicap </nz-form-label>
            <nz-form-control>
              <nz-select
                formControlName="handicap"
                placeholder="Type d'handicap"
                nzBorderless
                nzPlaceHolder="Type d'handicap"
                [nzDropdownRender]="renderOtherHandicapTemplate"
                [compareWith]="compareHandicap"
              >
                <nz-option
                  *ngFor="
                    let item of (utilityIdentity$ | async)?.mobility?.data
                  "
                  [nzLabel]="item?.item | titlecase"
                  [nzValue]="item"
                ></nz-option>
              </nz-select>
            </nz-form-control>
            <ng-template #renderOtherHandicapTemplate>
              <nz-divider></nz-divider>
              <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
                <nz-col nzSpan="1"></nz-col>
                <nz-col nzSpan="18">
                  <input
                    nz-input
                    nzBorderless
                    class="borderlessClass"
                    placeholder="Autre préciser..."
                    #inputElement
                  />
                </nz-col>
                <nz-col nzSpan="4">
                  <button
                    nz-button
                    nzShape="circle"
                    (click)="onSelectHandicap(inputElement)"
                  >
                    <i nz-icon nzType="plus"></i>
                  </button>
                </nz-col>
                <nz-col nzSpan="1"></nz-col>
              </nz-row>

              <nz-divider></nz-divider>
            </ng-template>
          </nz-form-item>
        </nz-col>
      </nz-row>
      <nz-space [nzSize]="100"></nz-space>
      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <!-- Date d'entrée -->
        <nz-col nzSpan="8">
          <nz-form-item>
            <nz-form-label [nzSpan]="24" nzFor="dateEntree"
              >Date d'entrée sur le territoire
            </nz-form-label>
            <nz-form-control>
              <nz-date-picker
                nzBorderless
                nzPlaceHolder="Sur le territoire"
                formControlName="dateEntree"
                id="dateEntree"
                nzFormat="dd/MM/yyyy"
              >
              </nz-date-picker>
            </nz-form-control>
          </nz-form-item>
        </nz-col>

        <nz-col nzSpan="8">
          <nz-form-item>
            <nz-form-label [nzSpan]="24" nzFor="cartes"
              >Type de carte de séjour
            </nz-form-label>
            <nz-form-control>
              <nz-select
                formControlName="typeCarteSejour"
                placeholder="Carte de séjour"
                nzBorderless
                nzPlaceHolder="Carte de séjour"
                [compareWith]="compareCarteSejour"
              >
                <nz-option-group
                  *ngFor="
                    let group of (utilityIdentity$ | async)?.titres_sejours
                      ?.data
                  "
                  [nzLabel]="group.type"
                >
                  <nz-option
                    nzCustomContent="true"
                    *ngFor="let item of group.cartes"
                    [nzLabel]="item.titre"
                    [nzValue]="item"
                  >
                    <p>
                      {{ item.titre }}
                    </p>
                  </nz-option>
                </nz-option-group>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </nz-col>

        <nz-col nzSpan="4">
          <nz-form-item>
            <nz-form-label [nzSpan]="26" nzFor="carte"
              >N° de carte</nz-form-label
            >
            <nz-form-control>
              <input
                nz-input
                nzBorderless
                name="carte"
                type="text"
                placeHolder="1234..."
                id="carte"
                formControlName="numbCarteSejour"
              />
            </nz-form-control>
          </nz-form-item>
        </nz-col>
        <!-- N° CAI-CIR -->
        <nz-col nzSpan="4">
          <nz-form-item>
            <nz-form-label [nzSpan]="26" nzFor="caiCir"
              >N° CAI-CIR</nz-form-label
            >
            <nz-form-control>
              <input
                nzBorderless
                nz-input
                name="caiCir"
                type="text"
                placeHolder="1234..."
                id="caiCir"
                formControlName="numCaiCir"
              />
            </nz-form-control>
          </nz-form-item>
        </nz-col>
      </nz-row>
      <nz-space [nzSize]="100"></nz-space>
      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col nzSpan="8">
          <app-check-card
            [title]="'Droit de travail'"
            [isActive]="participantForm.value.droitDeTravail"
            [definition]="droitDeTravailDefinition"
            [height]="'80px'"
            (select)="checkboxChanges($event, 'droitTravail')"
          ></app-check-card>
        </nz-col>

        <nz-col nzSpan="8">
          <app-check-card
            [title]="'RQTH'"
            [isActive]="participantForm.value.rqth"
            [definition]="rqthDefinition"
            [height]="'80px'"
            (select)="checkboxChanges($event, 'rqth')"
          ></app-check-card>
        </nz-col>

        <nz-col nzSpan="8">
          <app-check-card
            [title]="'Droit à l\'image'"
            [isActive]="participantForm.value.droitAimage"
            [definition]="droitAimageDefinition"
            [height]="'80px'"
            (select)="checkboxChanges($event, 'droitImage')"
          ></app-check-card>
        </nz-col>
      </nz-row>

      <nz-space [nzSize]="100"></nz-space>
    </nz-tab>

    <nz-tab
      [nzTitle]="familly"
      *ngIf="
        (update || isNumerique) &&
        !isSortie &&
        !isImpact &&
        !isImpact6 &&
        !isImpact12
      "
    >
      <ng-template #familly>
        <app-tab-title
          [title]="'Famille'"
          [isActiv]="tabActiv === 'familly' ? true : false"
        ></app-tab-title>
      </ng-template>

      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col nzSpan="12" formGroupName="situationPersonnelle">
          <nz-form-item>
            <nz-form-label [nzSpan]="24">État matrimonial</nz-form-label>
            <nz-form-control>
              <nz-select
                nzBorderless
                nzPlaceHolder="Situation matrimoniale"
                formControlName="familiale"
                [compareWith]="compareMatrimonial"
              >
                <nz-option
                  *ngFor="
                    let item of (utilityIdentity$ | async)?.matrimonial?.data
                  "
                  [nzValue]="item"
                  [nzLabel]="item"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </nz-col>
        <nz-col nzSpan="12">
          <nz-form-item formGroupName="situationPersonnelle">
            <nz-form-label [nzSpan]="24"></nz-form-label>
            <nz-form-control>
              <label
                nz-checkbox
                formControlName="enfantsAcharges"
                class="itemCenter"
                >Enfant.s à charge</label
              >
            </nz-form-control>
          </nz-form-item>
        </nz-col>
      </nz-row>
      <nz-space [nzSize]="100"></nz-space>

      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col nzSpan="12">
          <nz-form-item formGroupName="situationPersonnelle">
            <nz-form-control>
              <label
                nz-checkbox
                formControlName="enfantScolarise"
                class="itemCenter"
                >Enfant.s scolarisé.s</label
              >
            </nz-form-control>
          </nz-form-item>
        </nz-col>
        <nz-col nzSpan="12">
          <nz-form-item formGroupName="situationPersonnelle">
            <nz-form-control>
              <label
                nz-checkbox
                formControlName="enfantMoyenDeGarde"
                class="itemCenter"
                >Moyen de garde</label
              >
            </nz-form-control>
          </nz-form-item>
        </nz-col>
      </nz-row>
    </nz-tab>

    <nz-tab
      [nzTitle]="criteres"
      *ngIf="
        (update || isCriteres) &&
        !isSortie &&
        !isImpact &&
        !isImpact6 &&
        !isImpact12
      "
    >
      <ng-template #criteres>
        <app-tab-title
          [title]="'Critères'"
          [isActiv]="tabActiv === 'criteres' ? true : false"
        ></app-tab-title>
      </ng-template>

      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col [nzSpan]="(loading$ | async) ? 2 : 0">
          <i
            nz-icon
            nzType="loading"
            class="loading"
            *ngIf="loading$ | async"
          ></i>
        </nz-col>
        <nz-col [nzSpan]="(loading$ | async) ? 4 : 6">
          <app-check-card
            *ngIf="(utilityIdentity$ | async)?.criteres?.data[1] as critere1"
            [title]="critere1?.item | titlecase | carLimitation: 25"
            [isActive]="participantForm.value.criteresMap.jeunesDeMoinsDe26ans"
            [definition]="critere1?.info ? critere1?.info : critere1?.item"
            [height]="critereCardCheckboxHeight"
            (select)="checkboxCriteresChanges($event, critere1)"
          ></app-check-card>
        </nz-col>
        <nz-col nzSpan="6">
          <app-check-card
            *ngIf="(utilityIdentity$ | async)?.criteres?.data[7] as critere7"
            [title]="critere7?.item | titlecase | carLimitation: 25"
            [isActive]="participantForm.value.criteresMap.jeuneDecrocheur"
            [definition]="critere7?.info ? critere7?.info : critere7?.item"
            [height]="critereCardCheckboxHeight"
            (select)="checkboxCriteresChanges($event, critere7)"
          ></app-check-card>
        </nz-col>
        <nz-col nzSpan="6">
          <app-check-card
            *ngIf="(utilityIdentity$ | async)?.criteres?.data[11] as critere11"
            [title]="critere11?.item | titlecase | carLimitation: 25"
            [isActive]="participantForm.value.criteresMap.neet"
            [definition]="critere11?.info ? critere11?.info : critere11?.item"
            [height]="critereCardCheckboxHeight"
            (select)="checkboxCriteresChanges($event, critere11)"
          ></app-check-card>
        </nz-col>
        <nz-col nzSpan="6">
          <app-check-card
            *ngIf="(utilityIdentity$ | async)?.criteres?.data[5] as critere5"
            [title]="critere5?.item | titlecase | carLimitation: 25"
            [isActive]="participantForm.value.criteresMap.parentIsole"
            [definition]="critere5?.info ? critere5?.info : critere5?.item"
            [height]="critereCardCheckboxHeight"
            (select)="checkboxCriteresChanges($event, critere5)"
          ></app-check-card>
        </nz-col>
      </nz-row>
      <nz-space [nzSize]="80"></nz-space>
      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col nzSpan="6">
          <app-check-card
            *ngIf="(utilityIdentity$ | async)?.criteres?.data[2] as critere2"
            [title]="critere2?.item | titlecase | carLimitation: 25"
            [isActive]="
              participantForm.value.criteresMap.personnePriseEnChargeIAE
            "
            [definition]="critere2?.info ? critere2?.info : critere2?.item"
            [height]="critereCardCheckboxHeight"
            (select)="checkboxCriteresChanges($event, critere2)"
          ></app-check-card>
        </nz-col>

        <nz-col [nzSpan]="6">
          <app-check-card
            *ngIf="(utilityIdentity$ | async)?.criteres?.data[0] as critere0"
            [title]="critere0?.item | titlecase"
            [isActive]="participantForm.value.criteresMap.PersonneEmployeGEIQ"
            [definition]="critere0?.info ? critere0?.info : critere0?.item"
            [height]="critereCardCheckboxHeight"
            (select)="checkboxCriteresChanges($event, critere0)"
          ></app-check-card>
        </nz-col>
        <nz-col [nzSpan]="6">
          <app-check-card
            *ngIf="(utilityIdentity$ | async)?.criteres?.data[4] as critere4"
            [title]="critere4?.item | titlecase | carLimitation: 25"
            [isActive]="participantForm.value.criteresMap.primoArrivant"
            [definition]="critere4?.info ? critere4?.info : critere4?.item"
            [height]="critereCardCheckboxHeight"
            (select)="checkboxCriteresChanges($event, critere4)"
          ></app-check-card>
        </nz-col>
        <nz-col nzSpan="6">
          <app-check-card
            *ngIf="(utilityIdentity$ | async)?.criteres?.data[6] as critere6"
            [title]="critere6?.item | titlecase | carLimitation: 25"
            [isActive]="
              participantForm.value.criteresMap
                .beneficiaireProtectionInterRefugie
            "
            [definition]="critere6?.info ? critere6?.info : critere6?.item"
            [height]="critereCardCheckboxHeight"
            (select)="checkboxCriteresChanges($event, critere6)"
          ></app-check-card>
        </nz-col>
      </nz-row>
      <nz-space [nzSize]="80"></nz-space>
      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col nzSpan="6">
          <app-check-card
            *ngIf="(utilityIdentity$ | async)?.criteres?.data[12] as critere12"
            [title]="critere12?.item | titlecase | carLimitation: 25"
            [isActive]="
              participantForm.value.criteresMap
                .beneficiaireProtectionInterSubsidiaire
            "
            [definition]="critere12?.info ? critere12?.info : critere12?.item"
            [height]="critereCardCheckboxHeight"
            (select)="checkboxCriteresChanges($event, critere12)"
          ></app-check-card>
        </nz-col>

        <nz-col nzSpan="6">
          <app-check-card
            *ngIf="(utilityIdentity$ | async)?.criteres?.data[9] as critere9"
            [title]="critere9?.item | titlecase | carLimitation: 25"
            [isActive]="participantForm.value.criteresMap.fse"
            [definition]="critere9?.info ? critere9?.info : critere9?.item"
            [height]="critereCardCheckboxHeight"
            (select)="checkboxCriteresChanges($event, critere9)"
          ></app-check-card>
        </nz-col>
        <nz-col nzSpan="6">
          <app-check-card
            *ngIf="(utilityIdentity$ | async)?.criteres?.data[10] as critere10"
            [title]="critere10?.item | titlecase | carLimitation: 25"
            [isActive]="participantForm.value.criteresMap.ase"
            [definition]="critere10?.info ? critere10?.info : critere10?.item"
            [height]="critereCardCheckboxHeight"
            (select)="checkboxCriteresChanges($event, critere10)"
          ></app-check-card>
        </nz-col>
        <nz-col [nzSpan]="6">
          <app-check-card
            *ngIf="(utilityIdentity$ | async)?.criteres?.data[8] as critere8"
            [title]="critere8?.item | titlecase | carLimitation: 25"
            [isActive]="participantForm.value.criteresMap.autoentrepreneurQPV"
            [definition]="critere8?.info ? critere8?.info : critere8?.item"
            [height]="critereCardCheckboxHeight"
            (select)="checkboxCriteresChanges($event, critere8)"
          ></app-check-card>
        </nz-col>
      </nz-row>

      <nz-space [nzSize]="100"></nz-space>
    </nz-tab>

    <nz-tab
      [nzTitle]="hebergement"
      *ngIf="
        (update || isHebergement) &&
        !isSortie &&
        !isSortie &&
        !isImpact &&
        !isImpact6 &&
        !isImpact12
      "
    >
      <ng-template #hebergement>
        <app-tab-title
          [title]="'Hébergement'"
          [isActiv]="tabActiv === 'hebergement' ? true : false"
        ></app-tab-title>
      </ng-template>

      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start" *ngIf="!add">
        <nz-col nzSpan="24">
          <app-communes-france
            [updateItem]="adresseUpdate"
            [updateComplement]="adresseComplementUpdate"
            (adresse)="onAdresse($event)"
            (complement)="onComplement($event)"
            (qpvIn)="onQpv($event)"
            [borderless]="true"
            [size]="'default'"
          >
          </app-communes-france>
        </nz-col>
      </nz-row>
      <nz-space [nzSize]="100"></nz-space>

      <nz-divider
        nzOrientation="center"
        nzType="horizontal"
        nzText="QPV - QVA - ZRR"
      ></nz-divider>
      <ng-container formGroupName="informations">
        <nz-row
          nzAlign="top"
          nzGutter="8"
          nzJustify="start"
          formGroupName="adresse"
        >
          <nz-col nzSpan="8">
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox formControlName="isQPV"
                  >Quartier Politique de la Ville</label
                >
              </nz-form-control>
            </nz-form-item>
          </nz-col>
          <nz-col nzSpan="8">
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox formControlName="isQVA"
                  >Quartier de Veille Active</label
                >
              </nz-form-control>
            </nz-form-item>
          </nz-col>
          <nz-col nzSpan="8">
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox formControlName="isZRR"
                  >Zone de Revitalisation Rurale</label
                >
              </nz-form-control>
            </nz-form-item>
          </nz-col>
        </nz-row>
      </ng-container>
      <nz-divider
        nzOrientation="center"
        nzType="horizontal"
        nzText="Type d'hébergement"
      ></nz-divider>
      <nz-space [nzSize]="100"></nz-space>
      <ng-container formGroupName="situationPersonnelle">
        <nz-row
          nzAlign="top"
          nzGutter="8"
          nzJustify="start"
          formGroupName="hebergement"
        >
          <nz-col nzSpan="6">
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox formControlName="proprietaire"
                  >Propriétaire</label
                >
              </nz-form-control>
            </nz-form-item>
          </nz-col>
          <nz-col nzSpan="6">
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox formControlName="locataire">Locataire</label>
              </nz-form-control>
            </nz-form-item>
          </nz-col>
          <nz-col nzSpan="6">
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox formControlName="chezParent"
                  >Chez ses parents</label
                >
              </nz-form-control>
            </nz-form-item>
          </nz-col>
          <nz-col nzSpan="6">
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox formControlName="chezTier"
                  >Chez un tier</label
                >
              </nz-form-control>
            </nz-form-item>
          </nz-col>
        </nz-row>

        <nz-space [nzSize]="100"></nz-space>

        <nz-row
          nzAlign="top"
          nzGutter="8"
          nzJustify="start"
          formGroupName="hebergement"
        >
          <nz-col nzSpan="6">
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox formControlName="hotel">Dans un hôtel</label>
              </nz-form-control>
            </nz-form-item>
          </nz-col>
          <nz-col nzSpan="6">
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox formControlName="centreHebergement"
                  >Centre d'hébergement</label
                >
              </nz-form-control>
            </nz-form-item>
          </nz-col>
          <nz-col nzSpan="6">
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox formControlName="lieuPublic"
                  >Dans un lieu public</label
                >
              </nz-form-control>
            </nz-form-item>
          </nz-col>

          <nz-col nzSpan="6">
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox formControlName="autreLieu"
                  >Dans un autre lieu</label
                >
              </nz-form-control>
            </nz-form-item>
          </nz-col>
        </nz-row>

        <nz-space [nzSize]="100"></nz-space>
        <nz-row nzAlign="top" nzGutter="8" nzJustify="start">
          <nz-col nzSpan="6" formGroupName="hebergement">
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox formControlName="autre">Autre</label>
              </nz-form-control>
            </nz-form-item>
          </nz-col>
          <nz-col nzSpan="6" formGroupName="hebergement">
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox formControlName="nesaitpas"
                  >Ne sais pas</label
                >
              </nz-form-control>
            </nz-form-item>
          </nz-col>
          <nz-col nzSpan="12">
            <nz-form-item>
              <nz-form-control>
                <input
                  nz-input
                  nzBorderless
                  formControlName="hebergementPrecision"
                  placeholder="Précision d'hébergement..."
                />
              </nz-form-control>
            </nz-form-item>
          </nz-col>
        </nz-row>
      </ng-container>
    </nz-tab>
    <nz-tab
      [nzTitle]="mobility"
      *ngIf="
        (update || isMobilite) &&
        !isSortie &&
        !isImpact &&
        !isImpact6 &&
        !isImpact12
      "
    >
      <ng-template #mobility>
        <app-tab-title
          [title]="'Mobilité'"
          [isActiv]="tabActiv === 'mobility' ? true : false"
        ></app-tab-title>
      </ng-template>

      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col [nzSpan]="(loading$ | async) ? 2 : 0">
          <i
            nz-icon
            nzType="loading"
            class="loading"
            *ngIf="loading$ | async"
          ></i>
        </nz-col>
        <nz-col
          [nzSpan]="(loading$ | async) ? 10 : 12"
          formGroupName="situationPersonnelle"
        >
          <nz-form-item>
            <nz-form-label [nzSpan]="24" nzFor="modeDeDeplacement"
              >Mode de déplacement
            </nz-form-label>
            <nz-form-control>
              <nz-select
                nzBorderless
                nzPlaceHolder="Mode de déplacement"
                formControlName="mobilite"
                nzMode="tags"
                [compareWith]="compareModeDeplacement"
              >
                <nz-option
                  *ngFor="
                    let item of (utilityIdentity$ | async)?.transport_mode?.data
                  "
                  [nzValue]="item"
                  [nzLabel]="item"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </nz-col>
        <nz-col [nzSpan]="12" formGroupName="situationPersonnelle">
          <nz-form-item>
            <nz-form-label [nzSpan]="24" nzFor="geoMobility"
              >Déplacement professionnel
            </nz-form-label>
            <nz-form-control>
              <nz-select
                nzBorderless
                nzPlaceHolder="Mobilité géographique professionnelle"
                formControlName="geo_mobility"
                nzMode="tags"
                [compareWith]="compareDeplacementProfessionnel"
              >
                <nz-option
                  *ngFor="
                    let item of (utilityIdentity$ | async)?.deplacemenet_zone
                  "
                  [nzValue]="item"
                  [nzLabel]="item.NAME"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </nz-col>
      </nz-row>
      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col nzSpan="24" formGroupName="situationPersonnelle">
          <nz-form-item>
            <nz-form-label [nzSpan]="24">Permis </nz-form-label>
            <nz-form-control>
              <nz-select
                nzBorderless
                nzPlaceHolder="Permis"
                formControlName="permis"
                nzMode="tags"
                [compareWith]="comparePermis"
              >
                <nz-option
                  *ngFor="let item of (utilityIdentity$ | async)?.permis"
                  [nzValue]="item"
                  [nzLabel]="item.NAME"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </nz-col>
      </nz-row>
    </nz-tab>

    <nz-tab
      [nzTitle]="numerique"
      *ngIf="
        (update || isNumerique) &&
        !isSortie &&
        !isImpact &&
        !isImpact6 &&
        !isImpact12
      "
    >
      <ng-template #numerique>
        <app-tab-title
          [title]="'Numérique'"
          [isActiv]="tabActiv === 'numerique' ? true : false"
        ></app-tab-title>
      </ng-template>

      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col [nzSpan]="(loading$ | async) ? 2 : 0">
          <i
            nz-icon
            nzType="loading"
            class="loading"
            *ngIf="loading$ | async"
          ></i>
        </nz-col>
        <nz-col
          [nzSpan]="(loading$ | async) ? 22 : 24"
          formGroupName="situationPersonnelle"
        >
          <nz-form-item>
            <nz-form-label [nzSpan]="24">Niveau numérique </nz-form-label>
            <nz-form-control>
              <nz-select
                nzBorderless
                nzPlaceHolder="Autonomie numérique"
                formControlName="numerique"
                nzMode="tags"
                [compareWith]="compareNumeriqueLevel"
              >
                <nz-option-group
                  *ngFor="
                    let group of (utilityIdentity$ | async)?.numerique_level
                      ?.data
                  "
                  [nzLabel]="group.type"
                >
                  <nz-option
                    *ngFor="let item of group.items"
                    [nzLabel]="item.item"
                    [nzValue]="item"
                  >
                    {{ item.item }}
                  </nz-option>
                </nz-option-group>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </nz-col>
      </nz-row>
      <nz-space [nzSize]="100"></nz-space>
      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col nzSpan="12" formGroupName="situationPersonnelle">
          <nz-form-item>
            <nz-form-label [nzSpan]="24">Niveau en bureautique </nz-form-label>
            <nz-form-control>
              <nz-select
                nzBorderless
                nzPlaceHolder="Niveau d'utilisation des outils de bureautique"
                formControlName="bureautique_level"
                [compareWith]="compareBureautiqueLevel"
              >
                <nz-option
                  *ngFor="
                    let item of (utilityIdentity$ | async)?.bureautique_level
                  "
                  [nzLabel]="item.NAME"
                  [nzValue]="item"
                >
                  {{ item.item }}
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </nz-col>
        <nz-col nzSpan="12" formGroupName="situationPersonnelle">
          <nz-form-item>
            <nz-form-label [nzSpan]="24">Outils de bureautique</nz-form-label>
            <nz-form-control>
              <nz-select
                nzBorderless
                nzPlaceHolder="Outils de bureautique"
                formControlName="bureautique_tools"
                nzMode="tags"
                [compareWith]="compareBureautiqueTools"
              >
                <nz-option
                  *ngFor="
                    let item of (utilityIdentity$ | async)?.outils_bureautique
                  "
                  [nzLabel]="item.NAME"
                  [nzValue]="item"
                >
                  {{ item.item }}
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </nz-col>
      </nz-row>
      <nz-space [nzSize]="100"></nz-space>

      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col nzSpan="6">
          <app-check-card
            [title]="'Dispose d\'un mobile'"
            [isActive]="participantForm.value.situationPersonnelle.mobile"
            [definition]="checkboxDefinitionMobile"
            [height]="'220px'"
            (select)="checkboxChanges($event, 'mobile')"
          ></app-check-card>
        </nz-col>
        <nz-col nzSpan="6">
          <app-check-card
            [title]="'Dispose d\'un ordinateur'"
            [isActive]="participantForm.value.situationPersonnelle.ordinateur"
            [definition]="checkboxDefinitionOrdinateur"
            [height]="'220px'"
            (select)="checkboxChanges($event, 'ordinateur')"
          ></app-check-card>
        </nz-col>
        <nz-col nzSpan="6">
          <app-check-card
            [title]="'Accès internet'"
            [isActive]="participantForm.value.situationPersonnelle.internet"
            [definition]="checkboxDefinitionInternet"
            (select)="checkboxChanges($event, 'internet')"
            [height]="'220px'"
          ></app-check-card>
        </nz-col>
        <nz-col nzSpan="6">
          <app-check-card
            [title]="'Consentement'"
            [isActive]="participantForm.value.consentementDonneesPersonnelles"
            [definition]="checkboxDefinitionConsentement"
            [height]="'220px'"
            (select)="checkboxChanges($event, 'consentement')"
          ></app-check-card>
        </nz-col>
      </nz-row>
      <nz-space nzDirection="vertical" nzSize="small"></nz-space>
    </nz-tab>
    <nz-tab
      [nzTitle]="juridique"
      *ngIf="
        (update || isNumerique) &&
        !isSortie &&
        !isImpact &&
        !isImpact6 &&
        !isImpact12
      "
    >
      <ng-template #juridique>
        <app-tab-title
          [title]="'Judiciaire'"
          [isActiv]="tabActiv === 'judiciaire' ? true : false"
        ></app-tab-title>
      </ng-template>

      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col [nzSpan]="(loading$ | async) ? 2 : 0">
          <i
            nz-icon
            nzType="loading"
            class="loading"
            *ngIf="loading$ | async"
          ></i>
        </nz-col>
        <nz-col
          [nzSpan]="(loading$ | async) ? 10 : 12"
          formGroupName="situationPersonnelle"
        >
          <nz-form-item>
            <nz-form-label [nzSpan]="24"></nz-form-label>
            <nz-form-control>
              <label
                nz-checkbox
                class="rqth__participant"
                formControlName="caseJudiciaire"
                >Casier jusdiciaire</label
              >
            </nz-form-control>
          </nz-form-item>
        </nz-col>
        <nz-col nzSpan="12">
          <nz-form-item formGroupName="situationPersonnelle">
            <nz-form-label [nzSpan]="24">Restriction judiciaire </nz-form-label>
            <nz-form-control>
              <nz-select
                formControlName="justice"
                nzBorderless
                nzPlaceHolder="Situation judiciaire"
                [nzDropdownRender]="justiceOtherTemplate"
                [compareWith]="compareJudiciaire"
              >
                <nz-option
                  *ngFor="
                    let item of (utilityIdentity$ | async)?.judiciaire?.data
                  "
                  [nzValue]="item"
                  [nzLabel]="item"
                ></nz-option>

                <ng-template #justiceOtherTemplate>
                  <nz-divider></nz-divider>
                  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
                    <nz-col nzSpan="1"></nz-col>
                    <nz-col nzSpan="22">
                      <input
                        nz-input
                        nzBorderless
                        class="borderlessClass"
                        placeholder="Autre préciser..."
                        formControlName="justice"
                        #inputElement
                      />
                    </nz-col>
                    <nz-col nzSpan="1"></nz-col>
                  </nz-row>
                  <nz-divider></nz-divider>
                </ng-template>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </nz-col>
      </nz-row>
    </nz-tab>

    <nz-tab
      [nzTitle]="presentation"
      *ngIf="
        (update || isPresentation) &&
        !isSortie &&
        !isSortie &&
        !isImpact &&
        !isImpact6 &&
        !isImpact12
      "
    >
      <ng-template #presentation>
        <app-tab-title
          [title]="'Présentation'"
          [isActiv]="tabActiv === 'presentation' ? true : false"
        ></app-tab-title>
      </ng-template>

      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col nzSpan="24">
          <nz-form-item>
            <nz-form-label [nzSpan]="6" nzFor="presentation"
              >Présentation</nz-form-label
            >
            <nz-form-control>
              <textarea
                nz-input
                nzBorderless
                placeholder="Présentation"
                formControlName="presentation"
                [nzAutosize]="{ minRows: 6, maxRows: 6 }"
                name="presentation"
                type="text"
                id="presentation"
              ></textarea>
            </nz-form-control>
          </nz-form-item>
        </nz-col>
      </nz-row>
      <nz-space [nzSize]="100"></nz-space>

      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col nzSpan="24">
          <nz-form-item>
            <nz-form-label nzFor="observation">Observations </nz-form-label>
            <nz-form-control>
              <textarea
                nz-input
                nzBorderless
                placeholder="Observation"
                formControlName="observation"
                [nzAutosize]="{ minRows: 6, maxRows: 6 }"
                name="observation"
                type="text"
                id="observation"
              ></textarea>
            </nz-form-control>
          </nz-form-item>
        </nz-col>
      </nz-row>
    </nz-tab>

    <nz-tab
      [nzTitle]="sortie"
      *ngIf="isSortie && !isImpact && !isImpact6 && !isImpact12"
    >
      <ng-template #sortie>
        <app-tab-title
          [title]="'Sortie'"
          [isActiv]="tabActiv === 'sortie' ? true : false"
        ></app-tab-title>
      </ng-template>

      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col [nzSpan]="(loading$ | async) ? 2 : 0">
          <i
            nz-icon
            nzType="loading"
            class="loading"
            *ngIf="loading$ | async"
          ></i>
        </nz-col>

        <nz-col [nzSpan]="(loading$ | async) ? 10 : 12">
          <nz-form-item style="width: 100%">
            <nz-form-label [nzSpan]="24" nzFor="dateEnd"
              >Date de sortie
            </nz-form-label>
            <nz-form-control nzErrorTip="Aucune Date de sortie renseignée">
              <nz-date-picker
                nzPlaceHolder="Date de sortie"
                formControlName="dateEnd"
                nzBorderless
                id="dateEnd"
                nzFormat="dd/MM/yyyy"
                style="width: 100%"
              >
              </nz-date-picker>
            </nz-form-control>
          </nz-form-item>
        </nz-col>
        <nz-col nzSpan="6">
          <label
            class="sortiIncription__participant"
            nz-checkbox
            formControlName="sortiInscription"
            (ngModelChange)="onDesinscription($event)"
            >Désinscription</label
          >
        </nz-col>
        <nz-col nzSpan="6">
          <label
            class="sortiProgramme__participant"
            nz-checkbox
            formControlName="sortiProgramme"
            (ngModelChange)="onSortie($event)"
            >Sortie</label
          >
        </nz-col>
      </nz-row>

      <nz-form-item>
        <nz-form-label [nzSpan]="24" nzFor="motif"
          >Motif de sortie du projet
        </nz-form-label>
        <nz-form-control nzErrorTip="Aucun motif de sortie renseigné">
          <nz-select
            nzShowSearch
            nzBorderless
            nzAllowClear="true"
            formControlName="sortiProgrammeType"
            [compareWith]="compareSortie"
            nzPlaceHolder="Motif de sortie du projet..."
            id="motif"
          >
            <nz-option-group
              *ngFor="let group of utilitySortie$ | async"
              [nzLabel]="group.type"
            >
              <nz-option
                nzCustomContent="true"
                *ngFor="let item of group.sortie"
                [nzLabel]="item.motif"
                [nzValue]="item"
              >
                <p
                  nz-popover
                  nzPopoverTitle="{{ item.motif }}"
                  nzPopoverContent="{{ item.situation }}"
                  nzPopoverPlacement="left"
                  [nzPopoverMouseEnterDelay]="popoverDelay"
                >
                  {{ item.motif }}
                </p>
              </nz-option>
            </nz-option-group>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%">
        <nz-form-label [nzSpan]="24" nzFor="sortiProgrammeQualitatif"
          >Note qualitative
        </nz-form-label>
        <nz-form-control>
          <textarea
            nz-input
            nzBorderless
            placeholder="Commentaire..."
            formControlName="sortiProgrammeQualitatif"
            [nzAutosize]="{ minRows: 6, maxRows: 6 }"
            name="sortiProgrammeQualitatif"
            type="text"
            id="sortiProgrammeQualitatif"
          ></textarea>
        </nz-form-control>
      </nz-form-item>
    </nz-tab>

    <!-- IMPACTS -->
    <nz-tab [nzTitle]="impact" *ngIf="isImpact || isImpact6 || isImpact12">
      <ng-template #impact>
        <app-tab-title
          [title]="'Impacts'"
          [isActiv]="tabActiv === 'impact' ? true : false"
        ></app-tab-title>
      </ng-template>
      <nz-card nzSize="small" [nzTitle]="'Impact à 3 mois'" *ngIf="isImpact">
        <nz-content>
          <nz-row
            nzAlign="middle"
            nzGutter="8"
            nzJustify="start"
            formGroupName="impact3mois"
            class="impactHeader"
          >
            <nz-col [nzSpan]="(loading$ | async) ? 2 : 0">
              <i
                nz-icon
                nzType="loading"
                class="loading"
                *ngIf="loading$ | async"
              ></i>
            </nz-col>

            <nz-col [nzSpan]="(loading$ | async) ? 4 : 6">
              <nz-form-item>
                <nz-form-control
                  nzErrorTip="Aucune date d'évaluation renseignée"
                >
                  <nz-date-picker
                    nzSize="small"
                    nzBorderless
                    nzPlaceHolder="Date de l'évaluation"
                    formControlName="date"
                    id="dateImpact3"
                    nzFormat="dd/MM/yyyy"
                  >
                  </nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </nz-col>
            <nz-col nzSpan="18">
              <nz-form-item style="width: 100%">
                <nz-form-control nzErrorTip="Aucun impact renseigné">
                  <nz-select
                    nzShowSearch
                    nzSize="small"
                    nzAllowClear="true"
                    formControlName="type"
                    [compareWith]="compareSortie"
                    nzPlaceHolder="Type d'impact"
                    id="impact3"
                    nzBorderless
                  >
                    <nz-option-group
                      *ngFor="let group of utilitySortie$ | async"
                      [nzLabel]="group.type"
                    >
                      <nz-option
                        nzCustomContent="true"
                        *ngFor="let item of group.sortie"
                        [nzLabel]="item.motif"
                        [nzValue]="item"
                      >
                        <p
                          nz-popover
                          nzPopoverTitle="{{ item.motif }}"
                          nzPopoverContent="{{ item.situation }}"
                          nzPopoverPlacement="left"
                          [nzPopoverMouseEnterDelay]="popoverDelay"
                        >
                          {{ item.motif }}
                        </p>
                      </nz-option>
                    </nz-option-group>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </nz-col>
          </nz-row>
          <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
            <nz-col nzSpan="24">
              <nz-form-item nz-col nzSpan="24" formGroupName="impact3mois">
                <nz-form-control>
                  <textarea
                    nz-input
                    formControlName="info"
                    nzBorderless
                    [nzAutosize]="{ minRows: 6, maxRows: 6 }"
                    nzPlaceHolder="Commentaire"
                    placeHolder="Commentaire"
                  >
                  </textarea>
                </nz-form-control>
              </nz-form-item>
            </nz-col>
          </nz-row>
        </nz-content>
      </nz-card>

      <nz-card nzSize="small" [nzTitle]="'Impact à 6 mois'" *ngIf="isImpact6">
        <nz-content>
          <nz-row
            nzAlign="middle"
            nzGutter="8"
            nzJustify="start"
            formGroupName="impact6mois"
            class="impactHeader"
          >
            <nz-col [nzSpan]="(loading$ | async) ? 2 : 0">
              <i
                nz-icon
                nzType="loading"
                class="loading"
                *ngIf="loading$ | async"
              ></i>
            </nz-col>
            <nz-col [nzSpan]="(loading$ | async) ? 4 : 6">
              <nz-form-item>
                <nz-form-control
                  nzErrorTip="Aucune date d'évaluation renseignée"
                >
                  <nz-date-picker
                    nzSize="small"
                    nzBorderless
                    nzPlaceHolder="Date d'évaluation"
                    formControlName="date"
                    id="dateImpact6"
                    nzFormat="dd/MM/yyyy"
                  >
                  </nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </nz-col>
            <nz-col nzSpan="14">
              <nz-form-item>
                <nz-form-control nzErrorTip="Aucun impact renseigné">
                  <nz-select
                    nzShowSearch
                    nzBorderless
                    nzSize="small"
                    nzAllowClear="true"
                    formControlName="type"
                    [compareWith]="compareSortie"
                    nzPlaceHolder="Type d'impact"
                    id="impact6"
                  >
                    <nz-option-group
                      *ngFor="let group of utilitySortie$ | async"
                      [nzLabel]="group.type"
                    >
                      <nz-option
                        nzCustomContent="true"
                        *ngFor="let item of group.sortie"
                        [nzLabel]="item.motif"
                        [nzValue]="item"
                      >
                        <p
                          nz-popover
                          nzPopoverTitle="{{ item.motif }}"
                          nzPopoverContent="{{ item.situation }}"
                          nzPopoverPlacement="left"
                          [nzPopoverMouseEnterDelay]="popoverDelay"
                        >
                          {{ item.motif }}
                        </p>
                      </nz-option>
                    </nz-option-group>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </nz-col>
          </nz-row>
          <nz-row
            nzAlign="middle"
            nzGutter="8"
            nzJustify="start"
            class="impactHeader"
          >
            <nz-col nzSpan="24">
              <nz-form-item nz-col nzSpan="24" formGroupName="impact6mois">
                <nz-form-control>
                  <textarea
                    nz-input
                    nzBorderless
                    formControlName="info"
                    nzSize="small"
                    [nzAutosize]="{ minRows: 6, maxRows: 6 }"
                    nzPlaceHolder="Commentaire"
                    placeHolder="Commentaire"
                  >
                  </textarea>
                </nz-form-control>
              </nz-form-item>
            </nz-col>
          </nz-row>
        </nz-content>
      </nz-card>

      <nz-card nzSize="small" [nzTitle]="'Impact à 12 mois'" *ngIf="isImpact12">
        <nz-content>
          <nz-row
            nzAlign="middle"
            nzGutter="8"
            nzJustify="start"
            formGroupName="impact12mois"
            class="impactHeader"
          >
            <nz-col [nzSpan]="(loading$ | async) ? 2 : 0">
              <i
                nz-icon
                nzType="loading"
                class="loading"
                *ngIf="loading$ | async"
              ></i>
            </nz-col>
            <nz-col [nzSpan]="(loading$ | async) ? 4 : 6">
              <nz-form-item style="width: 100%">
                <nz-form-control
                  nzErrorTip="Aucune date d'évaluation renseignée"
                >
                  <nz-date-picker
                    nzSize="small"
                    nzBorderless
                    nzPlaceHolder="Date d'évaluation"
                    formControlName="date"
                    id="dateImpact12"
                    nzFormat="dd/MM/yyyy"
                  >
                  </nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </nz-col>
            <nz-col nzSpan="14">
              <nz-form-item style="width: 100%">
                <nz-form-control nzErrorTip="Aucun impact renseigné">
                  <nz-select
                    nzShowSearch
                    nzSize="small"
                    nzAllowClear="true"
                    formControlName="type"
                    [compareWith]="compareSortie"
                    nzPlaceHolder="Type d'impact"
                    id="impact12"
                    nzBorderless
                  >
                    <nz-option-group
                      *ngFor="let group of utilitySortie$ | async"
                      [nzLabel]="group.type"
                    >
                      <nz-option
                        nzCustomContent="true"
                        *ngFor="let item of group.sortie"
                        [nzLabel]="item.motif"
                        [nzValue]="item"
                      >
                        <p
                          nz-popover
                          nzPopoverTitle="{{ item.motif }}"
                          nzPopoverContent="{{ item.situation }}"
                          nzPopoverPlacement="left"
                          [nzPopoverMouseEnterDelay]="popoverDelay"
                        >
                          {{ item.motif }}
                        </p>
                      </nz-option>
                    </nz-option-group>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </nz-col>
          </nz-row>
          <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
            <nz-col nzSpan="24">
              <nz-form-item nz-col nzSpan="24" formGroupName="impact12mois">
                <nz-form-control>
                  <textarea
                    nz-input
                    nzBorderless
                    formControlName="info"
                    nzSize="small"
                    [nzAutosize]="{ minRows: 6, maxRows: 6 }"
                    nzPlaceHolder="Commentaire"
                    placeHolder="Commentaire"
                  >
                  </textarea>
                </nz-form-control>
              </nz-form-item>
            </nz-col>
          </nz-row>
        </nz-content>
      </nz-card>
    </nz-tab>
  </nz-tabset>
  <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
  <nz-row nzOrientation="center" nzGutter="8" nzJustify="end">
    <nz-col nzSpan="16"></nz-col>
    <nz-col nzSpan="4">
      <button
        nz-button
        nzBlock
        class="iconStandard"
        nzType="text"
        (click)="onCancel()"
      >
        <h5 nz-typography>Annuler</h5>
      </button>
    </nz-col>

    <nz-col nzSpan="4">
      <button
        *ngIf="add"
        nz-button
        nzBlock
        nzType="primary"
        [disabled]="!participantForm.valid"
        (click)="onAdd()"
      >
        Ajouter
      </button>

      <ng-container *ngIf="participant$ | async as participant">
        <button
          nz-button
          nzBlock
          nzType="primary"
          *ngIf="update"
          [disabled]="!participantForm.valid"
          (click)="onUpdate(participant)"
        >
          Modifier
        </button>
      </ng-container>
    </nz-col>
  </nz-row>
</form>
