import { ContactId } from './../../store/contact.model';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { of, Observable, Subject } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ContactState } from './../../store/contact.reducer';
import * as fromContactAction from './../../store/contact.actions';
import * as fromContactSelector from './../../store/contact.selectors';

@Component({
  selector: 'app-contact-documents',
  templateUrl: './contact-documents.component.html',
  styleUrls: ['./contact-documents.component.scss'],
})
export class ContactDocumentsComponent implements OnInit, OnDestroy {
  documents$: Observable<any> = of(null);
  contact$: Observable<ContactId | any> = of();
  subscribe = new Subject();

  constructor(private contactStore: Store<ContactState>) {}

  ngOnInit(): void {
    this.getContact();
    this.getDocuments();
    this.getDocuments();
    this.loadDocuments();
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getContact(): void {
    this.contact$ = this.contactStore.select(fromContactSelector.contact);
  }
  getDocuments(): void {
    this.documents$ = this.contactStore.select(fromContactSelector.documents);
  }

  loadDocuments(): void {
    this.contact$
      .pipe(takeUntil(this.subscribe))
      .subscribe((contact: ContactId) => {
        if (!contact) {
          return;
        } else {
          this.contactStore.dispatch(
            fromContactAction.loadDocuments({
              contactId: contact.id,
            })
          );
        }
      });
  }

  onAdd(event: any, contact: ContactId): void {
    if (!event || !contact) {
      return;
    } else {
      const { id } = contact;
      this.contactStore.dispatch(
        fromContactAction.addDocuments({ contact, documents: event })
      );
    }
  }
  onDelete(document: any, contact: ContactId): void {
    if (!document || !contact) {
      return;
    } else {
      const contactId: string = contact.id;

      this.contactStore.dispatch(
        fromContactAction.deleteDocument({
          contactId,
          document,
        })
      );
    }
  }
  onDeleteAll(): void {}
}
