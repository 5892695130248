<ng-container *ngIf="contact">
  <nz-descriptions
    [nzColumn]="{ xxl: 4, xl: 4, lg: 4, md: 4, sm: 2, xs: 1 }"
    nzBordered
  >
    <nz-descriptions-item nzTitle="E-mail" nzSpan="4">
      {{ contact?.coordonnees?.email }}
    </nz-descriptions-item>
    <nz-descriptions-item nzTitle="Réseaux" nzSpan="4"></nz-descriptions-item>
  </nz-descriptions>
  <nz-divider></nz-divider>
</ng-container>
