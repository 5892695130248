import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ButtonMenu } from 'src/app/contents/components/buttons/store/button.model';

@Component({
  selector: 'app-fiches-de-poste-item-header',
  templateUrl: './fiches-de-poste-item-header.component.html',
  styleUrls: ['./fiches-de-poste-item-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FichesDePosteItemHeaderComponent implements OnInit, OnChanges {
  statisticValuesStyle = {
    'font-size': '16px',
  };
  deleteType: string = 'Voulez-vous supprimer cette fiche de poste ?';
  menuNewItems: ButtonMenu[] = [
    {
      icon: '',
      title: 'Candidat',
    },
    {
      icon: '',
      title: 'Diffusion',
    },
    {
      icon: '',
      title: 'Note',
    },
  ];

  menuExportItems: ButtonMenu[] = [
    {
      icon: '',
      title: 'Fiche de poste',
    },
    {
      icon: '',
      title: 'Candidats',
    },
    {
      icon: '',
      title: 'Statistiques',
    },
  ];

  @Input() fiche: any;
  @Output() back = new EventEmitter();
  @Output() newActions = new EventEmitter();
  @Output() exportActions = new EventEmitter();
  @Output() actions = new EventEmitter();
  @Output() dashboardSelect = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {}

  onBackToList(): void {
    this.back.emit(true);
  }

  onNewActions(event: string): void {
    this.newActions.emit(event);
  }
  onExportActions(event: string): void {
    this.exportActions.emit(event);
  }

  onActionsDropdown(event: string): void {
    this.actions.emit(event);
  }

  onDashboarSelect(event: string): void {
    this.dashboardSelect.emit(event);
  }
}
