import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { OperationsSelect } from './operations-select.model';
import * as OperationsSelectActions from './operations-select.actions';
import { OperationSmallId } from 'src/app/components/operation/store/operation.model';

export const operationsSelectsFeatureKey = 'operationsSelects';

export interface OperationsSelectState extends EntityState<OperationSmallId> {
  error: any;
}

export const adapter: EntityAdapter<OperationSmallId> =
  createEntityAdapter<OperationSmallId>();

export const initialState: OperationsSelectState = adapter.getInitialState({
  error: undefined,
});

export const reducer = createReducer(
  initialState,
  on(OperationsSelectActions.loadOperationsSelectsSuccess, (state, action) =>
    adapter.setAll(action.operations, state)
  ),
  on(OperationsSelectActions.loadOperationsSelectsFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  on(OperationsSelectActions.clearOperationsSelects, (state) =>
    adapter.removeAll(state)
  )
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
