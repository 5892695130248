import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-projet-professionnel-metier',
  templateUrl: './projet-professionnel-metier.component.html',
  styleUrls: ['./projet-professionnel-metier.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjetProfessionnelMetierComponent implements OnInit, OnChanges {
  colors = {
    greenDark: '#52B788',
    blueDark: '#062949',
    redDarkWarm: '#ED1C24',
    redDark: '#D62839',
    white: '#FDFFFC',
  };

  @Input() item: any = null;
  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}
}
