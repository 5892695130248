import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Observable } from 'rxjs';
import { OperationFicheCandidatDocumentState } from '../../store/operation-fiche-candidat-document.reducer';
import { Store } from '@ngrx/store';
import * as fromOperationFicheCandidatDocumentAction from '../../store/operation-fiche-candidat-document.actions';
import * as fromOperationFicheCandidatDocumentSelector from '../../store/operation-fiche-candidat-document.selectors';
import { SelectType } from 'src/app/models/select.display.model';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';

@Component({
  selector: 'app-operation-fiche-candidat-documents',
  templateUrl: './operation-fiche-candidat-documents.component.html',
  styleUrls: ['./operation-fiche-candidat-documents.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationFicheCandidatDocumentsComponent
  implements OnInit, OnChanges
{
  user$: Observable<any> = this.userStore.select(fromUserSelector.user);
  view$: Observable<string> = this.operationFicheCandidatDocumentStore.select(
    fromOperationFicheCandidatDocumentSelector.view
  );
  selectTyped: SelectType = SelectType.SIDE;

  documents$: Observable<any> = this.operationFicheCandidatDocumentStore.select(
    fromOperationFicheCandidatDocumentSelector.documents
  );
  loading$: Observable<any> = this.operationFicheCandidatDocumentStore.select(
    fromOperationFicheCandidatDocumentSelector.loading
  );

  @Input() fiche: any;
  @Input() candidat: any;

  constructor(
    private userStore: Store<UserState>,
    private operationFicheCandidatDocumentStore: Store<OperationFicheCandidatDocumentState>
  ) {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {
    this.loadCandidatDocuments(
      changes?.fiche?.currentValue,
      changes?.candidat?.currentValue
    );
  }

  /**
   * Charger les documents du candidats
   * @param fiche - Fiche de poste
   * @param candidat - Candidature
   * @returns
   */
  loadCandidatDocuments(fiche: any, candidat: any): void {
    if (!fiche || !candidat) return;
    const operationId: string = fiche.mission.operation.id;
    const ficheId: string = fiche.id;
    const candidatId: string = candidat.id;

    this.operationFicheCandidatDocumentStore.dispatch(
      fromOperationFicheCandidatDocumentAction.loadOperationFicheCandidatDocuments(
        { operationId, ficheId, candidatId }
      )
    );
  }

  /**
   * Charger un document
   * @param document - Document
   * @param fiche - Fiche de poste
   * @param candidat - Candidat
   * @returns
   */
  loadCandidatDocument(document: any, fiche: any, candidat: any): void {
    if (!document || !fiche || !candidat) return;
    const operationId: string = fiche.mission.operation.id;
    const ficheId: string = fiche.id;
    const candidatId: string = candidat.id;
    const documentId: string = document.id;

    this.operationFicheCandidatDocumentStore.dispatch(
      fromOperationFicheCandidatDocumentAction.loadOperationFicheCandidatDocument(
        { operationId, ficheId, candidatId, documentId }
      )
    );
  }

  /**
   * Ajouter des documents au candidat
   * @param documents - Liste de documents à ajouter
   * @param fiche - Fiche de poste
   * @param candidat - Candidat
   * @returns
   */
  uploadCandidatDocuments(
    documents: any,
    user: any,
    fiche: any,
    candidat: any
  ): void {
    if (!documents?.length || !user || !fiche || !candidat) return;
    const operationId: string = fiche.mission.operation.id;
    const ficheId: string = fiche.id;
    const candidatId: string = candidat.id;

    this.operationFicheCandidatDocumentStore.dispatch(
      fromOperationFicheCandidatDocumentAction.uploadOperationFicheCandidatFiles(
        { user, operationId, ficheId, candidatId, documents }
      )
    );
    this.onCancel();
  }

  /**
   * Supprimer un document
   * @param document - Document
   * @param fiche - Fiche de poste
   * @param candidat - Candidat
   * @returns
   */
  onDelete(document: any, fiche: any, candidat: any): void {
    if (!document || !fiche || !candidat) return;
    const operationId: string = fiche.mission.operation.id;
    const ficheId: string = fiche.id;
    const candidatId: string = candidat.id;
    const documentId: string = document.id;

    this.operationFicheCandidatDocumentStore.dispatch(
      fromOperationFicheCandidatDocumentAction.deleteOperationFicheCandidatDocument(
        { operationId, ficheId, candidatId, documentId }
      )
    );
  }

  /**
   * Supprimer tous les documents du candidat
   * @param fiche - Fiche de poste
   * @param candidat - Candidat
   * @returns
   */
  onDeleteAll(fiche: any, candidat: any): void {
    if (!fiche || !candidat) return;
    const operationId: string = fiche.mission.operation.id;
    const ficheId: string = fiche.id;
    const candidatId: string = candidat.id;
    const ids: string[] = [];

    this.operationFicheCandidatDocumentStore.dispatch(
      fromOperationFicheCandidatDocumentAction.deleteOperationFicheCandidatDocuments(
        { operationId, ficheId, candidatId, ids }
      )
    );
  }

  onCancel(): void {
    this.operationFicheCandidatDocumentStore.dispatch(
      fromOperationFicheCandidatDocumentAction.setViewOperationFicheCandidatDocuments(
        { view: 'display' }
      )
    );
  }
}
