<nz-select
  [(ngModel)]="utilitySelected"
  [nzBorderless]="borderless"
  [nzPlaceHolder]="placeholder"
  [nzSize]="size"
  nzShowSearch
  (ngModelChange)="onSelect($event)"
  [ngClass]="{ borderlessClass: borderless }"
  [nzMode]="multi ? 'multiple' : 'default'"
  *ngIf="items$ | async | size"
  [nzDropdownRender]="renderOtherTemplate"
>
  <div [ngSwitch]="variable">
    <div *ngSwitchCase="'criteres'">
      <nz-option
        nzCustomContent
        *ngFor="let item of items$ | async"
        [nzValue]="item"
        [nzLabel]="item?.item | titlecase"
      >
        {{ item?.item | titlecase }}
      </nz-option>
    </div>
    <div *ngSwitchCase="'sortiesPositives'">
      <nz-option-group
        *ngFor="let group of items$ | async"
        [nzLabel]="group.type"
      >
        <nz-option
          nzCustomContent="true"
          *ngFor="let item of group.sortie"
          [nzLabel]="item.motif"
          [nzValue]="item"
          nz-popover
          nzPopoverTitle="{{ item.motif }}"
          nzPopoverContent="{{ item.situation }}"
          nzPopoverPlacement="left"
          [nzPopoverMouseEnterDelay]="1"
        >
          <p>
            {{ item.motif }}
          </p>
        </nz-option>
      </nz-option-group>
    </div>
    <div *ngSwitchCase="'mobility'">
      <nz-option
        nzCustomContent
        *ngFor="let item of items$ | async"
        [nzValue]="item"
        [nzLabel]="item?.item | titlecase"
      >
        {{ item?.item | titlecase }}
      </nz-option>
    </div>
    <div *ngSwitchCase="'numerique'">
      <nz-option-group
        *ngFor="let group of items$ | async"
        [nzLabel]="group.type"
      >
        <nz-option
          *ngFor="let item of group.items"
          [nzLabel]="item.item"
          [nzValue]="item"
        >
          {{ item.item }}
        </nz-option>
      </nz-option-group>
    </div>
    <div *ngSwitchCase="'pays'">
      <nz-option
        nzCustomContent
        *ngFor="let item of items$ | async"
        [nzValue]="item"
        [nzLabel]="item?.nationalite | titlecase"
      >
        {{ item?.nationalite | titlecase }}
      </nz-option>
    </div>
    <div *ngSwitchCase="'niveauFrancais'">
      <nz-option-group
        *ngFor="let group of items$ | async"
        [nzLabel]="group.type"
      >
        <nz-option
          nzCustomContent="true"
          *ngFor="let item of group.niveau"
          [nzLabel]="item.item"
          [nzValue]="item"
          nz-tooltip
          [nzTooltipMouseEnterDelay]="0.5"
          [nzTooltipTitle]="item.info"
          ><p>
            {{ item.item }}
          </p>
        </nz-option>
      </nz-option-group>
    </div>
    <div *ngSwitchCase="'niveauDiplome'">
      <nz-option
        nzCustomContent="true"
        *ngFor="let option of items$ | async"
        [nzLabel]="option.titre"
        [nzValue]="option"
        nz-popover
        nzPopoverTitle="{{ option.apresBac }}"
        nzPopoverContent="{{ option.apresBac }}"
        nzPopoverPlacement="left"
        [nzPopoverMouseEnterDelay]="1"
      >
        <p>{{ option.titre }} - {{ option.niveau }}</p>
      </nz-option>
    </div>
    <div *ngSwitchCase="'titreSejours'">
      <nz-option-group
        *ngFor="let group of items$ | async"
        [nzLabel]="group.type"
      >
        <nz-option
          nzCustomContent="true"
          *ngFor="let item of group.cartes"
          [nzLabel]="item.titre"
          [nzValue]="item"
        >
          <p>
            {{ item.titre }}
          </p>
        </nz-option>
      </nz-option-group>
    </div>
    <div *ngSwitchCase="'permis'">
      <nz-option
        nzCustomContent
        *ngFor="let item of items$ | async"
        [nzValue]="item"
        [nzLabel]="item?.item | titlecase"
      >
        {{ item?.item | titlecase }}
      </nz-option>
    </div>

    <div *ngSwitchDefault>
      <nz-option
        nzCustomContent
        *ngFor="let item of items$ | async"
        [nzValue]="item"
        [nzLabel]="item || item?.item | titlecase"
      >
        {{ item | titlecase }}
      </nz-option>
    </div>
  </div>

  <ng-template #renderOtherTemplate>
    <nz-divider *ngIf="other"></nz-divider>
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start" *ngIf="other">
      <nz-col nzSpan="1"></nz-col>
      <nz-col nzSpan="18">
        <input
          nz-input
          nzBorderless
          class="borderlessClass"
          placeholder="Autre préciser..."
          #inputElement
        />
      </nz-col>
      <nz-col nzSpan="4">
        <button nz-button nzShape="circle" (click)="onSelect(inputElement)">
          <i nz-icon nzType="plus"></i>
        </button>
      </nz-col>
      <nz-col nzSpan="1"></nz-col>
    </nz-row>

    <nz-divider></nz-divider>
  </ng-template>
</nz-select>
