import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, Subject, of } from 'rxjs';
import { DiffusionFormComponent } from '../diffusion-form/diffusion-form.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { DiffusionItemComponent } from '../diffusion-item/diffusion-item.component';
import { Update } from '@ngrx/entity';

@Component({
  selector: 'app-diffusion-list',
  templateUrl: './diffusion-list.component.html',
  styleUrls: ['./diffusion-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiffusionListComponent implements OnInit, OnChanges {
  // 1/ Gestion des diffusions - Diffusion auprès des prescripteurs et entreprise
  // 2/ Alerte à la date clôture définie de d'appel à candidature Typer la
  // diffusion : - 1/ Appel à candidature 2/ Envoi de candidature 3/ Informer les
  // prescripteurs concernées de la sélection ou non de leur candidat 4/
  // Indicateur de control : Profil, Pass IAE, Carte BTP, CV etc.. prescripteurs
  // concernées de la sélection ou non de leur candidat 4/ Indicateur

  @Input() titleDiffusion: string = 'Nouvelle diffusion';

  @Input() diffusions: any[] = [];
  @Input() deleteDiffusionType: string =
    'Voulez-vous supprimer cette diffusion ?';
  @Input() closeDiffusionType: string = 'Voulez-vous clôrer cette diffusion ?';
  @Input() newAddTitle: string = 'Ajouter une diffusion';
  @Input() newNoDataTitle: string = 'Aucune diffusion trouvée';
  @Input() newDescription: string =
    "Une diffusion est communication d'une information vers des destinataires (Appel à candidature, validation de la description du besoin, bilan. etc.";
  @Input() loading$: Observable<boolean> = of(false);
  @Input() title: string = 'Nouvelle diffusion';
  @Input() users$: Observable<any> = of();

  @Output() select = new EventEmitter();
  @Output() export = new EventEmitter();
  @Output() add = new EventEmitter();
  @Output() update = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Output() deleteAll = new EventEmitter();

  subscribe = new Subject();

  @ViewChild('diffusionFormTitle', { static: false })
  diffusionFormTitle: TemplateRef<any> | any;
  modalWidth: number = 1050;

  diffusionForm = new FormGroup({});

  constructor(
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService
  ) {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {}
  onSelect(item: any): void {
    if (!item) return;
    this.onSelectDiffusionItem(item);
  }
  onExport(event: any): void {}
  onNew(event: any): void {
    const modal = this.modal.create({
      nzContent: DiffusionFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.diffusionFormTitle,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '10px' },
    });
    const instance = modal.getContentComponent();
    instance.cancel.subscribe((): any => {
      modal.close();
    });
    instance.add.subscribe((fiche: any): any => {
      if (!fiche) {
        return null;
      } else {
        this.onAdd(fiche);
        modal.close();
      }
    });
  }

  onSelectDiffusionItem(diffusion: any): void {
    this.title = `${diffusion.title}`;
    this.titleDiffusion = `${diffusion.title}`;
    const modal = this.modal.create({
      nzContent: DiffusionFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.diffusionFormTitle,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });
    const instance = modal.getContentComponent();
    instance.diffusion$.next(diffusion);
    instance.isAdd = false;
    instance.isUpdate = true;
    instance.isEtablissement = true;
    instance.isAdresse = true;
    instance.cancel.subscribe((): any => {
      modal.close();
    });
    instance.update.subscribe((fiche: any): any => {
      if (!fiche) {
        return null;
      } else {
        this.onUpdate(fiche);
        modal.close();
      }
    });
  }

  onAdd(item: any): void {
    if (!item) return;
    this.add.emit(item);
  }

  onCloseDiffusion(diffusion: any): void {
    if (!diffusion) return;
    const nextDiffusion = { ...diffusion, isClosed: true };

    this.update.emit(nextDiffusion);
  }
  onUpdate(item: any): void {
    if (!item) return;
    this.update.emit(item);
  }

  onDelete(item: any): void {
    this.delete.emit(item);
  }
  onDeleteAll(): void {
    this.deleteAll.emit(true);
  }
  onDeleteCancel(): void {}
}
