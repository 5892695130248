import { NoResultModule } from './../no-result/no-result.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommunesFranceComponent } from './components/communes-france.component';
import * as fromGeoAdresse from './store/geo-adresse.reducer';
import { EffectsModule } from '@ngrx/effects';
import { GeoAdresseEffects } from './store/geo-adresse.effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from 'src/app/shared/shared.module';
import { CommunesFranceService } from 'src/app/service/communes-france.service';
import { UiModule } from 'src/app/ui/ui/ui.module';
import { AdresseItemComponent } from './components/adresse-item/adresse-item.component';
import { AdresseNoFoundComponent } from './components/adresse-no-found/adresse-no-found.component';

@NgModule({
  declarations: [CommunesFranceComponent, AdresseItemComponent, AdresseNoFoundComponent],
  exports: [CommunesFranceComponent, AdresseItemComponent],
  imports: [
    CommonModule,
    SharedModule,
    NoResultModule,
    UiModule,
    EffectsModule.forFeature([GeoAdresseEffects]),
    StoreModule.forFeature(
      fromGeoAdresse.geoAdressesFeatureKey,
      fromGeoAdresse.reducer
    ),
  ],
  providers: [CommunesFranceService],
})
export class CommunesFranceModule {}
