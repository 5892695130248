<div class="contentParticipantSituation">
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start" *ngIf="participant">
    <nz-col nzSpan="12">
      <nz-card [nzTitle]="presentationTitle">
        <ng-template #presentationTitle>
          <app-header-title
            [icon]="'subject'"
            [title]="'Présentation'"
            [subtitle]="true"
          ></app-header-title>
        </ng-template>

        <div
          class="contentParticipant"
          *ngIf="participant?.presentation; else noDataPresentation"
        >
          <p nz-typography *ngIf="participant?.presentation">
            {{ participant?.presentation }}
          </p>
        </div>
      </nz-card>
    </nz-col>
    <nz-col nzSpan="12">
      <nz-card [nzTitle]="observationTitle">
        <ng-template #observationTitle>
          <app-header-title
            [icon]="'subject'"
            [title]="'Observation'"
            [subtitle]="true"
          ></app-header-title>
        </ng-template>
        <div
          class="contentParticipant"
          *ngIf="participant?.observation; else noDataObservation"
        >
          <p nz-typography *ngIf="participant?.observation">
            {{ participant?.observation }}
          </p>
        </div>
      </nz-card>
    </nz-col>
  </nz-row>

  <ng-template #noDataPresentation>
    <app-no-result
      [icon]="''"
      [notitle]="'Aucune présentation renseignée'"
      [isButton]="false"
    ></app-no-result>
  </ng-template>

  <ng-template #noDataObservation>
    <app-no-result
      [icon]="''"
      [notitle]="'Aucune observation renseignée'"
      [isButton]="false"
    ></app-no-result>
  </ng-template>
</div>
