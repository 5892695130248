import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { flattenDeep, uniq } from 'lodash';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Update } from '@ngrx/entity';
import { OperationCadrage } from './../../store/operation-cadrage.model';
import { OperationId } from './../../../operation/store/operation.model';
import { Observable, of, BehaviorSubject, Subject } from 'rxjs';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { OperationCadrageState } from '../../store/operation-cadrage.reducer';
import * as fromOperationCadrageAction from '../../store/operation-cadrage.actions';
import * as fromOperationCadrageSelector from '../../store/operation-cadrage.selectors';

@Component({
  selector: 'app-operation-cadrage-item',
  templateUrl: './operation-cadrage-item.component.html',
  styleUrls: ['./operation-cadrage-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationCadrageItemComponent implements OnInit, OnDestroy {
  subscribe = new Subject();
  @Input() user$: Observable<any> = of(null);
  @Input() operation$: Observable<OperationId | any> = of(null);
  @Input() entites$: Observable<any> = of(null);
  cadrage$: Observable<any> = of(null);
  title: string = 'Fiche de cadrage';
  noInfo: string = 'Non renseignée';
  tabsetColor: any = {
    'background-color': '#f5f5f5',
    color: 'grey',
  };
  exportList = [
    {
      name: 'PDF',
      icon: 'file-pdf',
      disabled: false,
    },
    {
      name: 'Word',
      icon: 'file-pdf',
      disabled: false,
    },
  ];
  items$: Observable<string[]> = of([
    'Description',
    'Context',
    'Finalité',
    'Méthodologie',
    'Bénéfices',
    "Plan d'actions",
    'Pilotage',
    'Livrables',
    'Risques',
    'Ressources',
    'Objectifs',
  ]);
  currentStep: number = 0;
  itemCurrent$ = new BehaviorSubject<string>('Description');
  editing = new BehaviorSubject<boolean>(false);
  editingText = new BehaviorSubject<any>(null);
  editingListItem$ = new BehaviorSubject<boolean>(false);
  editingListItemShow$ = new BehaviorSubject<any>(null);
  editingListItemUpdate$ = new BehaviorSubject<any>(null);

  //FORM
  cadrageForm: UntypedFormGroup = this.fb.group({});

  constructor(
    private fb: UntypedFormBuilder,
    private operationCadrageStore: Store<OperationCadrageState>
  ) {}

  ngOnInit(): void {
    this.getCadrage();
    this.loadCadrage();
  }
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getCadrage(): void {
    this.cadrage$ = this.operationCadrageStore.select(
      fromOperationCadrageSelector.cadrage
    );
  }
  loadCadrage(): void {
    this.operation$
      .pipe(takeUntil(this.subscribe))
      .subscribe((operation: OperationId) => {
        if (!operation) return;
        this.operationCadrageStore.dispatch(
          fromOperationCadrageAction.loadOperationCadrage({
            operationId: operation.id,
          })
        );
      });
  }

  onTagSelect(item: string): void {
    this.itemCurrent$.next(item);
    this.onCancel();
  }
  onIndexChange(index: number): void {
    this.currentStep = index;
    switch (index) {
      case 0:
        this.onTagSelect('Description');
        break;
      case 1:
        this.onTagSelect('Finalité');
        break;
      case 2:
        this.onTagSelect("Plan d'actions");
        break;
      case 3:
        this.onTagSelect('Objectifs');
        break;
      case 4:
        this.onTagSelect('Livrables');
        break;
      case 5:
        this.onTagSelect('Ressources');
        break;
      case 6:
        this.onTagSelect('Risques');
        break;

      default:
        break;
    }
  }

  onEdit(item: any, cadrage: OperationCadrage): void {
    this.editing.next(true);
    switch (item) {
      case 'Description':
        const description = cadrage?.description ? cadrage.description : null;

        this.editingText.next(description);
        break;
      case 'Finalité':
        const definition = cadrage?.finality?.textHTML
          ? cadrage.finality.textHTML
          : '';

        this.editingText.next(definition);
        break;
      case 'Context':
        const context = cadrage?.context?.textHTML
          ? cadrage.context.textHTML
          : '';
        this.editingText.next(context);
        break;
      case 'Bénéfices':
        const benefices = cadrage?.benefices?.textHTML
          ? cadrage.benefices.textHTML
          : '';
        this.editingText.next(benefices);
        break;
      case 'Méthodologie':
        const methodologie = cadrage?.methodologie?.textHTML
          ? cadrage.methodologie.textHTML
          : '';
        this.editingText.next(methodologie);
        break;
      default:
        break;
    }
  }

  onEditing(event: any, type: string): void {
    switch (type) {
      case 'Description':
        this.cadrageForm.patchValue({
          description: event,
        });
        break;
      case 'Finalité':
        this.cadrageForm.patchValue({
          finality: event,
        });
        break;
      case 'Context':
        this.cadrageForm.patchValue({
          context: event,
        });
        break;
      case 'Bénéfices':
        this.cadrageForm.patchValue({
          benefices: event,
        });
        break;
      case 'Méthodologie':
        this.cadrageForm.patchValue({
          methodologie: event,
        });
        break;
      default:
        break;
    }
  }

  onEditingItemList(item: any): void {
    this.editingListItem$.next(true);
    this.editingListItemShow$.next(false);
    this.editingListItemUpdate$.next(item);
  }

  onValidCadrage(
    cadrage: Update<OperationCadrage>,
    operation: OperationId
  ): void {
    this.operationCadrageStore.dispatch(
      fromOperationCadrageAction.updateOperationCadrage({
        operationId: operation.id,
        cadrage: cadrage,
      })
    );
  }

  onUpdate(
    operation: OperationId,
    cadrage: OperationCadrage,
    type: string
  ): void {
    if (!this.cadrageForm.valid || !operation || !cadrage || !type) {
      return;
    }

    const form = this.cadrageForm.value;

    switch (type) {
      case 'Finalité':
        var formChange: Update<OperationCadrage> = {
          id: cadrage.id,
          changes: { ...cadrage, finality: form.finality },
        };

        this.onCancel();
        break;
      case 'Context':
        var formChange: Update<OperationCadrage> = {
          id: cadrage.id,
          changes: { ...cadrage, context: form.context },
        };

        this.onCancel();
        break;
      case 'Objectifs':
        var formChange: Update<OperationCadrage> = {
          id: cadrage.id,
          changes: { ...cadrage, objectifs: form.objectifs },
        };

        this.onCancel();
        break;
      case 'Livrables':
        var formChange: Update<OperationCadrage> = {
          id: cadrage.id,
          changes: { ...cadrage, livrables: form.livrables },
        };

        this.onCancel();
        break;
      case 'Ressources':
        var formChange: Update<OperationCadrage> = {
          id: cadrage.id,
          changes: { ...cadrage, ressources: form.ressources },
        };

        this.onCancel();
        break;
      case 'Pilotage':
        var formChange: Update<OperationCadrage> = {
          id: cadrage.id,
          changes: { ...cadrage, pilotage: form.pilotage },
        };

        this.onCancel();
        break;
      case 'Risques':
        var formChange: Update<OperationCadrage> = {
          id: cadrage.id,
          changes: { ...cadrage, risques: form.risques },
        };

        this.onCancel();
        break;
      case 'Bénéfices':
        var formChange: Update<OperationCadrage> = {
          id: cadrage.id,
          changes: { ...cadrage, benefices: form.benefices },
        };

        this.onCancel();
        break;
      case 'Méthodologie':
        var formChange: Update<OperationCadrage> = {
          id: cadrage.id,
          changes: { ...cadrage, methodologie: form.methodologie },
        };

        this.onCancel();
        break;
      default:
        this.onCancel();
        break;
    }
  }

  onUpdateList(
    item: any,
    operation: OperationId,
    cadrage: OperationCadrage,
    type: string
  ): void {
    if (!this.cadrageForm.valid || !operation || !cadrage || !type) {
      return;
    }

    const form = this.cadrageForm.value;

    switch (type) {
      case 'Description':
        var formChange: Update<OperationCadrage> = {
          id: cadrage.id,
          changes: {
            ...cadrage,
            description: { ...cadrage?.description, ...item },
          },
        };

        this.onCancel();
        break;
      case 'Objectifs':
        var formChange: Update<OperationCadrage> = {
          id: cadrage.id,
          changes: {
            ...cadrage,
            objectifs: [...cadrage?.objectifs, item],
          },
        };

        this.onCancel();
        break;
      case 'Livrables':
        var formChange: Update<OperationCadrage> = {
          id: cadrage.id,
          changes: { ...cadrage, livrables: [...cadrage?.livrables, item] },
        };

        this.onCancel();
        break;
      case 'Ressources':
        var formChange: Update<OperationCadrage> = {
          id: cadrage.id,
          changes: { ...cadrage, ressources: [...cadrage?.ressources, item] },
        };

        this.onCancel();
        break;
      case 'Pilotage':
        var formChange: Update<OperationCadrage> = {
          id: cadrage.id,
          changes: { ...cadrage, pilotage: [...cadrage?.pilotage, item] },
        };

        this.onCancel();
        break;
      case 'Risques':
        var formChange: Update<OperationCadrage> = {
          id: cadrage.id,
          changes: { ...cadrage, risques: [...cadrage?.risques, item] },
        };

        this.onCancel();
        break;
      case 'Bénéfices':
        var formChange: Update<OperationCadrage> = {
          id: cadrage.id,
          changes: { ...cadrage, benefices: form.benefices },
        };

        this.onCancel();
        break;
      case 'Méthodologie':
        var formChange: Update<OperationCadrage> = {
          id: cadrage.id,
          changes: { ...cadrage, methodologie: form.methodologie },
        };

        this.onCancel();
        break;
      default:
        this.onCancel();
        break;
    }
  }

  onUpdateListItem(
    item: any,
    operation: OperationId,
    cadrage: OperationCadrage,
    type: string
  ): void {
    if (!item || !operation || !cadrage || !type) {
      return;
    }

    switch (type) {
      case 'Objectifs':
        var removePrevObjectif = cadrage.objectifs.filter(
          (el: any) => el.id !== item.id
        );

        var formChange: Update<OperationCadrage> = {
          id: cadrage.id,
          changes: {
            ...cadrage,
            objectifs: [...removePrevObjectif, item],
          },
        };

        this.onCancel();
        break;
      case 'Livrables':
        var removePrevLivrable = cadrage.livrables.filter(
          (el) => el.id !== item.id
        );

        var formChange: Update<OperationCadrage> = {
          id: cadrage.id,
          changes: { ...cadrage, livrables: [...removePrevLivrable, item] },
        };

        this.onCancel();
        break;
      case 'Ressources':
        var removePrevRessource = cadrage.ressources.filter(
          (el) => el.id !== item.id
        );

        var formChange: Update<OperationCadrage> = {
          id: cadrage.id,
          changes: { ...cadrage, ressources: [...removePrevRessource, item] },
        };

        this.onCancel();
        break;
      case 'Pilotage':
        var removePrevPilotage = cadrage.pilotage.filter(
          (el) => el.id !== item.id
        );

        var formChange: Update<OperationCadrage> = {
          id: cadrage.id,
          changes: { ...cadrage, pilotage: [...removePrevPilotage, item] },
        };

        this.onCancel();
        break;
      case 'Risques':
        var formChange: Update<OperationCadrage> = {
          id: cadrage.id,
          changes: {
            ...cadrage,
            risques: cadrage?.equipe
              ? flattenDeep(uniq([cadrage?.equipe, item]))
              : flattenDeep(uniq(item)),
          },
        };

        this.onCancel();
        break;
      case 'Équipe':
        var removePrevEquipe = cadrage?.equipe.filter(
          (el) => el.id !== item.id
        );

        var formChange: Update<OperationCadrage> = {
          id: cadrage.id,
          changes: { ...cadrage, equipe: [...removePrevEquipe, item] },
        };

        this.onCancel();
        break;
      case 'Financeurs':
        var formChange: Update<OperationCadrage> = {
          id: cadrage.id,
          changes: {
            ...cadrage,
            financeurs: cadrage?.financeurs
              ? flattenDeep(uniq([cadrage?.financeurs, item]))
              : flattenDeep(uniq(item)),
          },
        };

        this.onCancel();
        break;
      case 'Partenaires':
        var formChange: Update<OperationCadrage> = {
          id: cadrage.id,
          changes: {
            ...cadrage,
            partenariats: cadrage?.partenariats
              ? flattenDeep(uniq([cadrage?.partenariats, item]))
              : flattenDeep(uniq(item)),
          },
        };

        this.onCancel();
        break;
      case "Plan d'actions":
        var formChange: Update<OperationCadrage> = {
          id: cadrage.id,
          changes: {
            ...cadrage,
            plans: cadrage?.plans ? [...cadrage?.plans, item] : [item],
          },
        };

        this.onCancel();
        break;

      default:
        this.onCancel();
        break;
    }
  }

  onDeleteList(
    item: any,
    operation: OperationId,
    cadrage: OperationCadrage,
    type: string
  ): void {
    if (!item || !operation || !cadrage || !type) {
      return;
    }

    switch (type) {
      case 'Objectifs':
        var formChange: Update<OperationCadrage> = {
          id: cadrage.id,
          changes: {
            ...cadrage,
            objectifs: cadrage.objectifs.filter((el: any) => el.id !== item.id),
          },
        };

        this.editingListItemShow$.next(null);
        this.onCancel();
        break;
      case 'Livrables':
        var formChange: Update<OperationCadrage> = {
          id: cadrage.id,
          changes: {
            ...cadrage,
            livrables: cadrage.livrables.filter((el) => el !== item),
          },
        };

        this.editingListItemShow$.next(null);
        this.onCancel();
        break;
      case 'Ressources':
        var formChange: Update<OperationCadrage> = {
          id: cadrage.id,
          changes: {
            ...cadrage,
            ressources: cadrage.ressources.filter((el) => el.id !== item.id),
          },
        };

        this.editingListItemShow$.next(null);
        this.onCancel();
        break;
      case 'Pilotage':
        var formChange: Update<OperationCadrage> = {
          id: cadrage.id,
          changes: {
            ...cadrage,
            pilotage: cadrage.pilotage.filter((el) => el.id !== item.id),
          },
        };

        this.editingListItemShow$.next(null);
        this.onCancel();
        break;
      case 'Risques':
        var formChange: Update<OperationCadrage> = {
          id: cadrage.id,
          changes: {
            ...cadrage,
            risques: cadrage.risques.filter((el) => el.id !== item.id),
          },
        };

        this.editingListItemShow$.next(null);
        this.onCancel();
        break;
      case 'Financeurs':
        var formChange: Update<OperationCadrage> = {
          id: cadrage.id,
          changes: {
            ...cadrage,
            financeurs: cadrage.financeurs.filter((el) => el.id !== item.id),
          },
        };

        this.editingListItemShow$.next(null);
        this.onCancel();
        break;
      case 'Partenaires':
        var formChange: Update<OperationCadrage> = {
          id: cadrage.id,
          changes: {
            ...cadrage,
            partenariats: cadrage.partenariats.filter(
              (el) => el.id !== item.id
            ),
          },
        };

        this.editingListItemShow$.next(null);
        this.onCancel();
        break;
      case 'Équipe':
        var formChange: Update<OperationCadrage> = {
          id: cadrage.id,
          changes: {
            ...cadrage,
            equipe: cadrage.equipe.filter((el) => el.id !== item.id),
          },
        };

        this.editingListItemShow$.next(null);
        this.onCancel();
        break;
      case "Plan d'actions":
        var formChange: Update<OperationCadrage> = {
          id: cadrage.id,
          changes: {
            ...cadrage,
            plans: cadrage.plans.filter((el) => el.id !== item.id),
          },
        };

        this.editingListItemShow$.next(null);
        this.onCancel();
        break;
      default:
        this.editingListItemShow$.next(null);
        this.onCancel();
        break;
    }
  }

  onDeleteAllList(
    operation: OperationId,
    cadrage: OperationCadrage,
    type: string
  ): void {
    if (!operation || !cadrage || !type) {
      return;
    }

    switch (type) {
      case 'Objectifs':
        var formChange: Update<OperationCadrage> = {
          id: cadrage.id,
          changes: {
            ...cadrage,
            objectifs: [],
          },
        };

        this.editingListItemShow$.next(null);
        this.onCancel();
        break;
      case 'Livrables':
        var formChange: Update<OperationCadrage> = {
          id: cadrage.id,
          changes: {
            ...cadrage,
            livrables: [],
          },
        };

        this.editingListItemShow$.next(null);
        this.onCancel();
        break;
      case 'Ressources':
        var formChange: Update<OperationCadrage> = {
          id: cadrage.id,
          changes: {
            ...cadrage,
            ressources: [],
          },
        };

        this.editingListItemShow$.next(null);
        this.onCancel();
        break;
      case 'Pilotage':
        var formChange: Update<OperationCadrage> = {
          id: cadrage.id,
          changes: {
            ...cadrage,
            pilotage: [],
          },
        };

        this.editingListItemShow$.next(null);
        this.onCancel();
        break;
      case 'Risques':
        var formChange: Update<OperationCadrage> = {
          id: cadrage.id,
          changes: {
            ...cadrage,
            risques: [],
          },
        };

        this.editingListItemShow$.next(null);
        this.onCancel();
        break;
      case 'Financeurs':
        var formChange: Update<OperationCadrage> = {
          id: cadrage.id,
          changes: {
            ...cadrage,
            financeurs: [],
          },
        };

        this.editingListItemShow$.next(null);
        this.onCancel();
        break;
      case 'Partenaires':
        var formChange: Update<OperationCadrage> = {
          id: cadrage.id,
          changes: {
            ...cadrage,
            partenariats: [],
          },
        };

        this.editingListItemShow$.next(null);
        this.onCancel();
        break;
      case 'Équipe':
        var formChange: Update<OperationCadrage> = {
          id: cadrage.id,
          changes: {
            ...cadrage,
            equipe: [],
          },
        };

        this.editingListItemShow$.next(null);
        this.onCancel();
        break;
      case "Plan d'actions":
        var formChange: Update<OperationCadrage> = {
          id: cadrage.id,
          changes: {
            ...cadrage,
            plans: [],
          },
        };

        this.editingListItemShow$.next(null);
        this.onCancel();
        break;
      default:
        this.editingListItemShow$.next(null);
        this.onCancel();
        break;
    }
  }

  onValidation__EQUIPE(
    operation: OperationId,
    cadrage: OperationCadrage
  ): void {}

  onValidation__FINANCEURS(
    operation: OperationId,
    cadrage: OperationCadrage
  ): void {}

  onValidationByItem(items: any, operation: OperationId): void {
    switch (items.type) {
      case 'Équipe':
        const equipe: any[] = items?.itemCadrage;
        const equipeIds: string[] = items?.itemCadrage.map((el: any) => el.id);

        var operationChange: Update<OperationId> = {
          id: operation.id,
          changes: { ...operation, equipe, equipeIds },
        };

        this.onCancel();

        break;
      case 'Financeurs':
        const financeurs: any[] = items?.itemCadrage;
        const financeursIds: string[] = items?.itemCadrage.map(
          (el: any) => el.id
        );

        var operationChange: Update<OperationId> = {
          id: operation.id,
          changes: { ...operation, financeurs, financeursIds },
        };

        this.onCancel();

        break;

      default:
        break;
    }
  }

  onValidation(operation: OperationId, cadrage: OperationCadrage): void {
    if (!this.cadrageForm.valid || !operation || !cadrage) {
      return;
    }

    var formChange: Update<OperationCadrage> = {
      id: cadrage.id,
      changes: { ...cadrage, validation: true },
    };

    this.onCancel();
  }

  onClearAll(operation: OperationId, cadrage: OperationCadrage): void {
    if (!operation || !cadrage) {
      return;
    }

    var formChange: Update<any> = {
      id: cadrage.id,
      changes: {
        ...cadrage,
        validation: false,
        equipe: [],
        plans: [],
        benefices: null,
        finality: null,
        context: null,
        financeurs: [],
        partenariats: [],
        ressources: [],
        objectifs: [],
        methodologie: null,
        risques: [],
        pilotage: [],
        livrables: [],
      },
    };

    this.onCancel();
  }

  onExport(
    operation: OperationId,
    cadrage: OperationCadrage,
    type: string
  ): void {
    if (!operation || !cadrage) {
      return;
    }

    switch (type) {
      case 'PDF':
        break;
      case 'Word':
        break;
      default:
        break;
    }
  }

  onDelete(operation: OperationId, cadrage: OperationCadrage): void {
    if (!operation || cadrage) {
      return;
    }
  }

  onCancel(): void {
    this.editing.next(false);
    this.editingText.next(null);
    this.editingListItem$.next(false);
    this.editingListItemUpdate$.next(null);
    this.editingListItemShow$.next(false);
  }
}
