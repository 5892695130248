import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  EntiteTaskState,
  entiteTasksFeatureKey,
  selectAll,
} from './entite-task.reducer';
import { toPairs } from 'lodash';
import {
  fromUnixTime,
  isThisMonth,
  isThisWeek,
  isThisYear,
  isToday,
} from 'date-fns';

export const selectEntiteTasksState = createFeatureSelector<EntiteTaskState>(
  entiteTasksFeatureKey
);

export const tasks = createSelector(selectEntiteTasksState, selectAll);

export const loading = createSelector(
  selectEntiteTasksState,
  (state: EntiteTaskState) => state.loading
);
export const isFiltered = createSelector(
  selectEntiteTasksState,
  (state: EntiteTaskState) => state.isFiltered
);

export const error = createSelector(
  selectEntiteTasksState,
  (state: EntiteTaskState) => state.error
);

export const tasksCurrents = createSelector(
  selectEntiteTasksState,
  (state: EntiteTaskState) => {
    const todosTopairs = toPairs(state.entities);
    const todosflat: any[] | any = todosTopairs.map((item) => item[1]);

    const todos: any[] = todosflat.filter(
      (item: any) => !item.completed && !item.inprogress && !item.verifed
    );

    const tasksInProgress = todos.filter(
      (el: any) => el.texts.filter((i: any) => i.status).length === 0
    );

    return tasksInProgress;
  }
);

export const tasksInProgress = createSelector(
  selectEntiteTasksState,
  (state: EntiteTaskState) => {
    const todosTopairs = toPairs(state.entities);
    const todosflat: any[] | any = todosTopairs.map((item) => item[1]);

    const todos: any[] = todosflat.filter(
      (item: any) => !item.completed && !item.verifed
    );

    const tasksInProgress = todos.filter(
      (el: any) =>
        el.texts.filter((i: any) => i.status).length > 0 || el.inprogress
    );

    return tasksInProgress;
  }
);

export const tasksVerifed = createSelector(
  selectEntiteTasksState,
  (state: EntiteTaskState) => {
    const todosTopairs = toPairs(state.entities);
    const todosflat: any[] | any = todosTopairs.map((item) => item[1]);

    const todos = todosflat.filter(
      (item: any) =>
        (!item.completed && item.inprogress && item.verifed) ||
        (!item.completed && !item.inprogress && item.verifed)
    );
    return todos;
  }
);

export const tasksDoned = createSelector(
  selectEntiteTasksState,
  (state: EntiteTaskState) => {
    const todosTopairs = toPairs(state.entities);
    const todosflat: any[] | any = todosTopairs.map((item) => item[1]);

    const todos = todosflat.filter((item: any) => item.completed);
    return todos;
  }
);

//TODAY TASKS
export const tasksToday = createSelector(
  selectEntiteTasksState,
  (state: EntiteTaskState) => {
    const todosTopairs = toPairs(state.entities);
    const todosflat: any[] | any = todosTopairs.map((item) => item[1]);

    const todos = todosflat.filter(
      (item: any) =>
        !item.completed &&
        !item.tested &&
        !item.verifed &&
        isToday(fromUnixTime(item.dateStart['seconds']))
    );
    return todos;
  }
);

//WEEK TASKS
export const tasksOfWeek = createSelector(
  selectEntiteTasksState,
  (state: EntiteTaskState) => {
    const todosTopairs = toPairs(state.entities);
    const todosflat: any[] | any = todosTopairs.map((item) => item[1]);

    const todos = todosflat.filter(
      (item: any) =>
        !item.completed &&
        !item.tested &&
        !item.verifed &&
        isThisWeek(fromUnixTime(item.dateStart['seconds']))
    );
    return todos;
  }
);

//MONTH TASKS
export const tasksOfMonth = createSelector(
  selectEntiteTasksState,
  (state: EntiteTaskState) => {
    const todosTopairs = toPairs(state.entities);
    const todosflat: any[] | any = todosTopairs.map((item) => item[1]);

    const todos = todosflat.filter(
      (item: any) =>
        !item.completed &&
        !item.tested &&
        !item.verifed &&
        isThisMonth(fromUnixTime(item.dateStart['seconds']))
    );
    return todos;
  }
);

//YEARS TAKS
export const tasksOfYear = createSelector(
  selectEntiteTasksState,
  (state: EntiteTaskState) => {
    const todosTopairs = toPairs(state.entities);
    const todosflat: any[] | any = todosTopairs.map((item) => item[1]);

    const todos = todosflat.filter(
      (item: any) =>
        !item.completed &&
        !item.tested &&
        !item.verifed &&
        isThisYear(fromUnixTime(item.dateStart['seconds']))
    );
    return todos;
  }
);
