import { Injectable } from '@angular/core';
import { Firestore, collection, doc, getDoc } from '@angular/fire/firestore';

import { Auth, onAuthStateChanged } from '@angular/fire/auth';

// import { CoreState } from '../../core/store/core.reducer';
// import * as fromCoreSelectors from '../../core/store/core.selectors';

import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'any',
})
export class ObjectifService {
  utilitiesCollection: any;
  utilitieDocmuent: any;
  utilitie$: Observable<any> = of(null);
  utilities$: Observable<any> = of(null);

  constructor(private db: Firestore, private auth: Auth) {}

  get__UTILITIES(): Observable<any> {
    try {
      return of(null);
      // onAuthStateChanged(this.auth, (snap) => snap?.uid)
      // return this.storeCore.select(fromCoreSelectors.selectUser).pipe(
      //   switchMap((user) => {
      //     if (!user) {
      //       return of(null);
      //     }

      //     this.utilitieDocmuent = this.db
      //       .collection('organisations')
      //       .doc(user.organisationId)
      //       .collection('utility')
      //       .doc('participantUtility');

      //     this.utilitie$ = this.utilitieDocmuent.get().pipe(
      //       map((snap) => {
      //         const data = snap.data() as any;
      //         const id = snap.id;
      //         return { id, ...data };
      //       })
      //     );
      //     return this.utilitie$;
      //   })
    } catch (error) {
      return of(error);
    }
  }
}
