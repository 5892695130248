<div
  class="flex flex-col"
  *ngIf="coordonnees?.mobile || coordonnees?.email || coordonnees?.fixe"
>
  <span class="w-full flex items-start gap-2" *ngIf="coordonnees?.mobile">
    <svg
      class="opacity-80"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="页面-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Device"
          transform="translate(-192.000000, 0.000000)"
          fill-rule="nonzero"
        >
          <g id="cellphone_line" transform="translate(192.000000, 0.000000)">
            <path
              d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z"
              id="MingCute"
              fill-rule="nonzero"
            ></path>
            <path
              d="M17,2 C18.0543909,2 18.9181678,2.81587733 18.9945144,3.85073759 L19,4 L19,20 C19,21.0543909 18.18415,21.9181678 17.1492661,21.9945144 L17,22 L7,22 C5.94563773,22 5.08183483,21.18415 5.00548573,20.1492661 L5,20 L5,4 C5,2.94563773 5.81587733,2.08183483 6.85073759,2.00548573 L7,2 L17,2 Z M17,4 L7,4 L7,20 L17,20 L17,4 Z M12.5,16 C12.7454222,16 12.9496,16.1769086 12.9919429,16.4101355 L13,16.5 L13,17.5 C13,17.7454222 12.8230914,17.9496 12.5898645,17.9919429 L12.5,18 L11.5,18 C11.2545778,18 11.0504,17.8230914 11.0080571,17.5898645 L11,17.5 L11,16.5 C11,16.2545778 11.1769086,16.0504 11.4101355,16.0080571 L11.5,16 L12.5,16 Z"
              id="形状"
              fill="#09244BFF"
            ></path>
          </g>
        </g>
      </g>
    </svg>
    {{ coordonnees?.mobile | phone }}
  </span>
  <span class="w-full flex items-start gap-2" *ngIf="coordonnees?.fixe">
    <svg
      class="fill-slate-600"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="页面-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g id="Contact" transform="translate(-384.000000, 0.000000)">
          <g id="phone_line" transform="translate(384.000000, 0.000000)">
            <path
              d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z"
              id="MingCute"
              fill-rule="nonzero"
            ></path>
            <path
              d="M8.17155,15.8285 C12.0174,19.6743 15.5802,20.0954 16.6259,20.1339 C17.8904,20.1804 19.1802,19.1483 19.7376,18.0906 C18.848,17.0465 17.6892,16.2367 16.4195,15.3587 C15.6712,16.107 14.7484,17.4973 13.5186,16.9999 C12.8203,16.7175 11.0941,15.9226 9.58577,14.4143 C8.07739,12.9059 7.28253,11.1797 7.00013,10.4815 C6.50231,9.25062 7.89573,8.32613 8.64459,7.57727 C7.76704,6.28658 6.97058,5.09819 5.92867,4.25321 C4.85725,4.8137 3.81906,6.09371 3.86617,7.37413 C3.90465,8.41979 4.32575,11.9827 8.17155,15.8285 Z M16.5523,22.1325 C15.1114,22.0795 11.0307,21.5161 6.75734,17.2427 C2.48395,12.9693 1.92055,8.88863 1.86753,7.44768 C1.78673,5.25222 3.46853,3.11903 5.41214,2.28606 C5.8682,2.0906 6.42059,2.12637 6.85727,2.4449 C8.4649,3.61757 9.57405,5.39532 10.5266,6.78732 C10.9324,7.38038 10.8654,8.18491 10.3502,8.70013 L8.99377,10.0565 C9.30858,10.7518 9.95085,11.9509 11,13 C12.0491,14.0492 13.2482,14.6914 13.9435,15.0063 L15.2992,13.6505 C15.8149,13.1349 16.6229,13.0669 17.2173,13.4787 C18.6373,14.4625 20.305,15.556 21.521,17.1133 C21.8625,17.5507 21.9133,18.1227 21.7096,18.5981 C20.8728,20.5507 18.7552,22.2136 16.5523,22.1325 Z"
              id="形状"
              fill="#09244BFF"
            ></path>
          </g>
        </g>
      </g>
    </svg>
    {{ coordonnees?.fixe | phone }}
  </span>
  <span class="w-full flex items-start gap-2" *ngIf="coordonnees?.email">
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="页面-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Contact"
          transform="translate(-144.000000, 0.000000)"
          fill-rule="nonzero"
        >
          <g id="mail_line" transform="translate(144.000000, 0.000000)">
            <path
              d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z"
              id="MingCute"
              fill-rule="nonzero"
            ></path>
            <path
              d="M20,4 C21.0543909,4 21.9181678,4.81587733 21.9945144,5.85073759 L22,6 L22,18 C22,19.0543909 21.18415,19.9181678 20.1492661,19.9945144 L20,20 L4,20 C2.94563773,20 2.08183483,19.18415 2.00548573,18.1492661 L2,18 L2,6 C2,4.94563773 2.81587733,4.08183483 3.85073759,4.00548573 L4,4 L20,4 Z M20,7.41424 L13.0606,14.3536 C12.4748,14.9394 11.5251,14.9394 10.9393,14.3536 L4,7.41424 L4,18 L20,18 L20,7.41424 Z M18.5858,6 L5.41409,6 L12,12.5859 L18.5858,6 Z"
              id="形状"
              fill="#09244BFF"
            ></path>
          </g>
        </g>
      </g>
    </svg>
    {{ coordonnees?.email }}</span
  >
</div>
<div
  class="w-full flex flex-col"
  *ngIf="!coordonnees?.mobile && !coordonnees?.email && !coordonnees?.fixe"
>
  <p class="font-bold opacity-50">Aucune coordonnée renseignée</p>
</div>
