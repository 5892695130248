<form nz-form [formGroup]="ficheDePosteForm">
  <nz-steps [nzCurrent]="currentStep" nzSize="small">
    <nz-step [nzTitle]="steOneTitleTpl"> </nz-step>
    <ng-template #steOneTitleTpl>
      {{
        ficheDePosteForm.value.entite?.uniteLegale?.denominationUniteLegale
          ? (ficheDePosteForm.value.entite?.uniteLegale?.denominationUniteLegale
            | carLimitation : 20)
          : stepOneTitle
      }}
    </ng-template>
    <nz-step [nzTitle]="steTowTitleTpl"></nz-step>
    <ng-template #steTowTitleTpl>
      {{
        ficheDePosteForm.value.mission?.metier?.metier?.libelle
          ? (ficheDePosteForm.value.mission?.metier?.metier?.libelle
            | titlecase
            | carLimitation : 20)
          : stepTwoTitle
      }}
    </ng-template>
    <nz-step [nzTitle]="stepTreeTitle"></nz-step>
    <nz-step [nzTitle]="stepFourTitle"></nz-step>
  </nz-steps>

  <div [ngSwitch]="currentStep" class="mt-6">
    <div *ngSwitchCase="0">
      <app-entite-search
        *ngIf="!ficheDePosteForm.value.entite?.siret; else etablissementItemTpl"
        [etablissementUpdate]="currentEtablissement$ | async"
        (select)="onSelectEntite($event)"
        (click)="$event.stopPropagation()"
      ></app-entite-search>

      <ng-template #etablissementItemTpl>
        <app-entite-search-item
          [entite]="ficheDePosteForm.value.entite"
          [isValidation]="false"
          [isAdd]="isAdd"
          [isCardLabel]="false"
          (clear)="onClearEntite()"
          (click)="$event.stopPropagation()"
        ></app-entite-search-item>
      </ng-template>
    </div>
    <div *ngSwitchCase="1">
      <ng-container
        *ngIf="
          !ficheDePosteForm.value?.mission.metier ||
            !ficheDePosteForm.value?.mission.contrat;
          else ficheDePosteMissionItem
        "
      >
        <app-missions-form
          (add)="onSelectMetier($event)"
          (update)="onUpdateMetier($event)"
          [isAdd]="isAdd"
          [operationItem]="operationItem"
          [isOperation]="isOperation"
          [isContratUpdate]="isContratUpdate"
          [isMetierUpdate]="isMetierUpdate"
          [mission]="ficheDePosteForm.value.mission"
          (previous)="onStepBackMission($event)"
          (cancel)="onCancelMission()"
        ></app-missions-form>
      </ng-container>

      <ng-template #ficheDePosteMissionItem>
        <app-missions-form-item
          [mission]="ficheDePosteForm.value.mission"
          [isAdd]="isAdd"
          (cleanMetier)="onCleanMission()"
          (cleanContrat)="onCleanContrat()"
          (validate)="onValidateMission()"
          (back)="onStepBackMission(0)"
        ></app-missions-form-item>
      </ng-template>
    </div>
    <div *ngSwitchCase="2">
      <app-fiches-de-poste-form-contact
        [contacts]="contacts$ | async"
        (onCurrentChanges)="onChangeContact($event)"
      ></app-fiches-de-poste-form-contact>
    </div>
    <div *ngSwitchCase="3">
      <app-fiches-de-poste-form-validation
        [fiche]="ficheDePosteForm.value"
        (onCurrentChanges)="onChangeRecherche($event)"
      ></app-fiches-de-poste-form-validation>
    </div>
    <div *ngSwitchDefault></div>
  </div>

  <ng-container *ngIf="currentStep !== 1">
    <nz-divider></nz-divider>
    <div class="w-full flex items-end p-6 pr-12 space-x-6 h-28 mt-6">
      <div class="w-3/4"></div>
      <div class="w-24">
        <button
          class="btn btn-sm btn-ghost"
          (click)="$event.stopPropagation(); onCancel()"
        >
          Annuler
        </button>
      </div>
      <div *ngIf="currentStep > 0 ? 4 : 0" class="w-24">
        <button
          class="btn btn-sm"
          (click)="$event.stopPropagation(); onStepBack(currentStep)"
          *ngIf="currentStep > 1"
        >
          Précédent
        </button>
      </div>
      <div *ngIf="currentStep !== 3 ? 4 : 0" class="w-24">
        <button
          *ngIf="currentStep === 0"
          (click)="$event.stopPropagation(); onStepNext(currentStep)"
          [class]="
            !ficheDePosteForm.value.entite
              ? 'btn btn-sm btn-disabled'
              : 'btn btn-sm btn-primary'
          "
          [disabled]="!ficheDePosteForm.value.entite"
        >
          Suivant
        </button>
        <button
          *ngIf="currentStep === 1"
          (click)="$event.stopPropagation(); onStepNext(currentStep)"
          [class]="
            !ficheDePosteForm.value.mission?.adresse?.adresse?.properties
              ?.label || currentStep === 1
              ? 'btn btn-sm btn-disabled'
              : 'btn btn-sm btn-primary'
          "
          [disabled]="
            !ficheDePosteForm.value.mission?.adresse?.adresse?.properties?.label
          "
        >
          Suivant
        </button>
        <button
          *ngIf="currentStep === 2"
          [class]="
            !ficheDePosteForm.value.contacts?.length ||
            !ficheDePosteForm.value.contacts[0]?.coordonnees?.mobile ||
            !ficheDePosteForm.value.contacts[0]?.coordonnees?.email ||
            !ficheDePosteForm.value.contacts[0]?.fonction
              ? 'btn btn-sm btn-disabled'
              : 'btn btn-sm btn-primary'
          "
          (click)="$event.stopPropagation(); onStepNext(currentStep)"
          [disabled]="
            !ficheDePosteForm.value.contacts?.length ||
            !ficheDePosteForm.value.contacts[0]?.coordonnees?.mobile ||
            !ficheDePosteForm.value.contacts[0]?.coordonnees?.email ||
            !ficheDePosteForm.value.contacts[0]?.fonction
          "
        >
          Suivant
        </button>
      </div>
      <nz-col [nzSpan]="currentStep === 3 ? 6 : 0">
        <button
          [class]="
            !ficheDePosteForm.valid
              ? 'btn btn-sm btn-disabled'
              : 'btn btn-sm btn-primary'
          "
          (click)="$event.stopPropagation(); onValidation()"
          *ngIf="currentStep === 3 && isAdd"
          [disabled]="!ficheDePosteForm.valid"
        >
          {{ validationTitle }}
        </button>

        <button
          [class]="
            !ficheDePosteForm.valid
              ? 'btn btn-sm btn-disabled'
              : 'btn btn-sm btn-primary'
          "
          (click)="$event.stopPropagation(); onUpdateValidations()"
          *ngIf="currentStep === 3 && !isAdd"
          [disabled]="!ficheDePosteForm.valid"
        >
          Modifier
        </button>
      </nz-col>
    </div>
  </ng-container>
</form>
