<div class="ficheDePosteItemDetailContainer">
  <nz-descriptions nzSize="small" nzBordered>
    <nz-descriptions-item nzSpan="1" nzTitle="Type">
      {{ fiche?.mission?.contrat?.type?.libelle }}
    </nz-descriptions-item>
    <nz-descriptions-item nzSpan="1" nzTitle="Nature">
      {{ fiche?.mission?.contrat?.natute?.libelle }}
    </nz-descriptions-item>
    <nz-descriptions-item nzSpan="1" nzTitle="Dates">
      du {{ fiche?.mission?.contrat?.startMission | dateFr : "small" }} au
      {{ fiche?.mission?.contrat?.endMission | dateFr : "small" }}
    </nz-descriptions-item>
    <nz-descriptions-item nzSpan="1" nzTitle="Adresse">
      {{ fiche?.adresse?.adresse?.properties?.label }}
    </nz-descriptions-item>
    <nz-descriptions-item nzSpan="1"></nz-descriptions-item>
    <nz-descriptions-item nzSpan="1"></nz-descriptions-item>
  </nz-descriptions>
</div>
