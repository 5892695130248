import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { UtilitiesState } from 'src/app/contents/components/utilities/store/utilities.reducer';
import { v4 as uuidV4 } from 'uuid';
import * as fromUtilitiesAction from 'src/app/contents/components/utilities/store/utilities.actions';
import * as fromUtilitiesSelector from 'src/app/contents/components/utilities/store/utilities.selectors';
import { capitalize, toUpper } from 'lodash';
import { Timestamp } from 'firebase/firestore';
import { fromUnixTime } from 'date-fns';

@Component({
  selector: 'app-diffusion-form-contacts',
  templateUrl: './diffusion-form-contacts.component.html',
  styleUrls: ['./diffusion-form-contacts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiffusionFormContactsComponent
  implements OnInit, OnChanges, OnDestroy
{
  subscribe = new Subject();
  civilite$: Observable<any> = this.utilitiesStore.select(
    fromUtilitiesSelector.civilities
  );

  @Input() noDataTitle: string = 'Contacts entreprise';
  @Input() isList: boolean = true;
  @Input() isAdresse: boolean = false;
  @Input() isRole: boolean = true;
  @Input() isFonction: boolean = true;
  @Input() isCoordonnees: boolean = true;
  @Input() isDateNaissance: boolean = false;
  @Input() isEtablissement: boolean = false;

  @Input() isEmailRequired: boolean = false;
  @Input() isMobileRequired: boolean = false;
  @Input() isFixeRequired: boolean = false;
  @Input() isDateNaissanceRequired: boolean = false;

  @Input() contactsInput: any;
  @Output() onCurrentChanges = new EventEmitter<any>(false);
  @Output() add = new EventEmitter<any>(false);

  contactEtablissement = new FormControl<{
    siret: string;
    denominationUniteLegale: string;
    categorie: string;
    activite: string;
    adresseLabel: string;
  }>({
    siret: '',
    denominationUniteLegale: '',
    categorie: '',
    activite: '',
    adresseLabel: '',
  });

  adresse = new FormControl<{
    adresse: {
      properties: {
        label: string;
        score: number;
        housenumber: string;
        id: string;
        type: string;
        name: string;
        postcode: string;
        citycode: string;
        x: number;
        y: number;
        city: string;
        context: string;
        importance: number;
        street: string;
      };
      geometry: {
        type: string;
        coordinates: [number];
      };
    };
    complement: string;
    qpv: string;
  }>({
    adresse: {
      properties: {
        label: '',
        score: 0,
        housenumber: '',
        id: '',
        type: '',
        name: '',
        postcode: '',
        citycode: '',
        x: 0,
        y: 0,
        city: '',
        context: '',
        importance: 0,
        street: '',
      },
      geometry: {
        type: '',
        coordinates: [0],
      },
    },
    complement: '',
    qpv: '',
  });

  contacts: FormArray = this.fb.array<
    {
      id: string;
      civilite: string;
      lastName: string;
      firstName: string;
      fonction: string;
      role: string;
      genre: string;
      age: string;
      tranche: string;
      dateNaissance: Date;
      coordonnees: {
        mobile: string;
        phoneNumber: string;
        email: string;
        fixe: string;
      };
      adresse: {
        adresse: {
          properties: {
            label: string;
            score: number;
            housenumber: string;
            id: string;
            type: string;
            name: string;
            postcode: string;
            citycode: string;
            x: number;
            y: number;
            city: string;
            context: string;
            importance: number;
            street: string;
          };
          geometry: {
            type: string;
            coordinates: [number];
          };
        };
        complement: string;
        qpv: string;
      };

      etablissement: {
        siret: string;
        denominationUniteLegale: string;
        categorie: string;
        activite: string;
        adresseLabel: string;
      };
    }[]
  >([]);

  diffusionContactForm = this.fb.group({
    contacts: this.contacts,
  });

  constructor(
    private fb: FormBuilder,
    private utilitiesStore: Store<UtilitiesState>
  ) {}

  ngOnInit(): void {
    this.loadUtilitiesCivilite();
    this.onChangesForm();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.onUpdateItems(changes?.contactsInput?.currentValue);
  }
  ngOnDestroy(): void {
    this.subscribe.next(false);
    this.subscribe.complete();
  }

  get contactsArrayControler() {
    return this.diffusionContactForm.get('contacts') as FormArray;
  }

  compareCivilite(item1: any, item2: any) {
    return item1 === item2;
  }

  loadUtilitiesCivilite(): void {
    this.utilitiesStore.dispatch(fromUtilitiesAction.loadCivilities());
  }

  onUpdateItems(items: any): void {
    if (!items?.length) {
      return;
    }

    items.forEach((item: any) => this.onUpdateItem(item));
  }

  onAdd(): void {
    const contact = this.fb.group({
      id: [uuidV4(), Validators.required],
      civilite: ['', Validators.required],
      lastName: ['', Validators.required],
      firstName: ['', Validators.required],
      fonction: ['', Validators.required],
      role: [''],
      genre: [''],
      age: [''],
      tranche: [''],
      dateNaissance: [new Date(Date.now())],
      coordonnees: this.fb.group({
        mobile: ['', Validators.required],
        phoneNumber: ['', Validators.required],
        fixe: [''],
        email: ['', Validators.required],
      }),
      adresse: this.fb.group({
        adresse: this.fb.group({
          properties: this.fb.group({
            label: [''],
            score: [0],
            housenumber: [''],
            id: [''],
            type: [''],
            name: [''],
            postcode: [''],
            citycode: [''],
            x: [0],
            y: [0],
            city: [''],
            context: [''],
            importance: [''],
            street: [''],
          }),
          geometry: this.fb.group({
            type: [''],
            coordinates: [0, 0],
          }),
        }),
        complement: [''],
        qpv: [''],
      }),
      etablissement: this.fb.group(
        {
          siret: ['', Validators.required],
          denominationUniteLegale: ['', Validators.required],
          categorie: ['', Validators.required],
          activite: ['', Validators.required],
          adresseLabel: [''],
        },
        Validators.required
      ),
    });

    this.contacts.insert(0, contact);
  }

  onAddAdresse(event: any, index: number): void {
    const { geometry, properties } = event;

    this.contacts.at(index).patchValue({
      adresse: {
        adresse: { geometry, properties },
      },
    });
  }

  onAddDEtablissement(etablissement: any, index: number): void {
    const { uniteLegale, adresseEtablissement } = etablissement;
    const siret: string = etablissement.siret;
    const denominationUniteLegale: string = uniteLegale.denominationUniteLegale;
    const categorie: string =
      uniteLegale?.categorie_juridique_libelle?.libelle || 'Non renseignée';
    const activite: string =
      uniteLegale?.activite_principale_libelle?.intitule_naf ||
      'Non renseignée';
    const adresseLabel: string =
      adresseEtablissement?.fullAddresse || 'Non renseignée';

    this.contacts.at(index).patchValue({
      etablissement: {
        siret: siret,
        denominationUniteLegale: denominationUniteLegale,
        categorie: categorie,
        activite: activite,
        adresseLabel: adresseLabel,
      },
    });
  }

  onUpdateItem(item: any): void {
    const dateNaissance = fromUnixTime(item.dateNaissance['seconds']);
    const role = item?.role || '';
    const contact = this.fb.group({ ...item, role, dateNaissance });

    this.contacts.push(contact);
  }
  onRemoveContact(index: number): void {
    this.contacts.removeAt(index);
  }
  onRemoveAllContacts(): void {
    this.contacts.clear();
  }

  onChangesCoordonnees(coordonnees: any, index: number): void {
    this.contacts.at(index).patchValue({
      coordonnees: {
        fixe: coordonnees?.fixe ? coordonnees.fixe : '',
        mobile: coordonnees?.mobile ? coordonnees.mobile : '',
        phoneNumber: coordonnees?.phoneNumber ? coordonnees.phoneNumber : '',
        email: coordonnees?.email ? coordonnees.email : '',
      },
    });
  }

  onChangesCoordonneesEmail(email: any, index: number): void {
    this.contacts.at(index).patchValue({
      coordonnees: {
        email: email ? email : '',
      },
    });
  }
  onChangesCoordonneesFixe(fixe: any, index: number): void {
    this.contacts.at(index).patchValue({
      coordonnees: {
        fixe: fixe ? fixe : '',
      },
    });
  }

  onRemove(index: number): void {
    this.contacts.removeAt(index);
  }

  onRemoveAll(): void {
    this.contacts.clear();
  }

  onChangesForm(): void {
    this.diffusionContactForm
      .get('contacts')
      ?.valueChanges.pipe(takeUntil(this.subscribe))
      .subscribe((value: any) => {
        if (!this.isList) {
          const item = value[0];
          const lastName = toUpper(item?.lastName.trim());
          const firstName = capitalize(item?.firstName.trim());
          const fonction = capitalize(item?.fonction.trim());
          const role = capitalize(item?.role.trim());
          const dateNaissance = Timestamp.fromDate(item.dateNaissance);

          const contact = {
            ...item,
            lastName,
            firstName,
            fonction,
            role,
            dateNaissance,
            genre:
              item?.civilite === 'M'
                ? 'Masculin'
                : item?.civilite === 'Mme'
                ? 'Féminin'
                : 'Autre',
          };

          this.onCurrentChanges.emit(contact);
          return;
        }

        const contacts = value?.length
          ? value.map((el: any) => {
              const lastName = toUpper(el?.lastName.trim());
              const firstName = capitalize(el?.firstName.trim());
              const fonction = capitalize(el?.fonction.trim());
              const role = capitalize(el?.role.trim());
              const dateNaissance = Timestamp.fromDate(el.dateNaissance);

              const contact = {
                ...el,
                lastName,
                firstName,
                fonction,
                role,
                dateNaissance,
                genre:
                  el?.civilite === 'M'
                    ? 'Masculin'
                    : el?.civilite === 'Mme'
                    ? 'Féminin'
                    : 'Autre',
              };

              return contact;
            })
          : [];

        this.onCurrentChanges.emit(contacts);
      });
  }
}
