import { OperationId } from './../components/operation/store/operation.model';
import { Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  doc,
  docSnapshots,
  collectionChanges,
  getDoc,
  addDoc,
  setDoc,
  deleteDoc,
  serverTimestamp,
  DocumentData,
  Timestamp,
  query,
  where,
  arrayUnion,
  arrayRemove,
  collectionSnapshots,
  CollectionReference,
  DocumentReference,
  getDocs,
  documentId,
} from '@angular/fire/firestore';
import { Observable, of, from, EMPTY } from 'rxjs';
import { switchMap, mergeMap, map, catchError } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Functions, httpsCallable } from '@angular/fire/functions';
import {
  Storage,
  ref,
  uploadBytesResumable,
  deleteObject,
  getDownloadURL,
  StorageReference,
  UploadTask,
  UploadMetadata,
} from '@angular/fire/storage';
import { UserState } from '../components/user/store/user.reducer';
import * as fromUserSelector from '../components/user/store/user.selectors';
import { v4 as uuidV4 } from 'uuid';
import { format, fromUnixTime } from 'date-fns';
import { ItemEvent } from '../features/suivi/components/item-event/itemEvent.model';
import { OperationTaskId } from '../components/operation-task/store/operation-task.model';
import { OperationNoteAction } from '../components/operation-note-action/store/operation-note-action.model';

@Injectable({
  providedIn: 'any',
})
export class OperationNoteActionService {
  constructor(
    private db: Firestore,
    private fns: Functions,
    private userStore: Store<UserState>
  ) {}
  getAll(operationId: string, id: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !operationId || !id) of([]);

          const dbCollection = collection(
            this.db,
            `organisations/${user.organisation.id}/operations/${operationId}/notes/${id}/documents`
          );

          return from(collectionSnapshots(dbCollection)).pipe(
            map((changes) => {
              return changes.map((snap) => {
                const data = snap.data();
                const id = snap.id;
                return { id, ...data };
              });
            })
          );
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  addOne(
    operation: OperationId,
    noteId: string,
    actions: OperationNoteAction[]
  ): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !operation || !noteId || !actions.length) {
            return EMPTY;
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/operations/${operation.id}/notes/${noteId}/actions`
            );

            actions.forEach((action) => {
              const document: any = {
                ...action,
                auteur: user,
                dateUpdate: serverTimestamp(),
              };

              return from(
                addDoc(dbCollection, document)
                  .then((docRef) => {
                    if (!docRef.id) {
                      const succss: string = `Actions n\'ont pas été ajoutées ! Veuillez rééssayer`;
                      return of(succss);
                    }

                    const succss: string = `Actions ajoutées`;
                    return of(succss);
                  })
                  .catch((error) => of(error))
              ).pipe(mergeMap((res) => res));
            });

            const succss: string = `Actions ajoutées`;
            return of(succss);
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  updateOne(
    note: Partial<OperationTaskId | any>,
    type?: string
  ): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !note) {
            return EMPTY;
          } else {
            const dbDocument = doc(
              this.db,
              `organisations/${user.organisation.id}/operations/${note.operation.id}/tasks/${note.id}`
            );

            return from(
              setDoc(
                dbDocument,
                { ...note, userUpdate: user, dateUpdate: serverTimestamp() },
                { merge: true }
              )
                .then(() => of(`Note modifiée`))
                .catch((error) => of(error))
            ).pipe(mergeMap((res) => res));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  deleteOne(operationId: string, noteId: string, id: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !id || !operationId || !noteId) {
            return EMPTY;
          } else {
            const dbDocument = doc(
              this.db,
              `organisations/${user.organisation.id}/operations/${operationId}/notes/${noteId}/actions/${id}`
            );

            return from(
              deleteDoc(dbDocument)
                .then(() => of(`Action supprimée`))
                .catch((error) => of(error))
            ).pipe(mergeMap((res) => res));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  deleteAll(operationId: string, ids: string[]): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) {
            return EMPTY;
          } else {
            //CALL HTTP

            return of(null);
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }
}
