<app-card-actions *ngIf="entite">
  <ul nz-menu [nzMode]="'inline'">
    <li nz-menu-item (click)="onSelect('details')">
      <mat-icon>domaine</mat-icon>

      <span>Profil</span>
    </li>
    <li
      nz-menu-item
      (click)="onSelect('collaborateurs')"
      nz-tooltip
      [nzTooltipMouseEnterDelay]="0.5"
      nzTooltipPlacement="right"
      nzTooltipTitle="Collaborateurs liés à l'entité"
    >
      <mat-icon>person</mat-icon>

      <span>Collaborateurs</span>
    </li>

    <li
      nz-menu-item
      (click)="onSelect('suivis')"
      nz-tooltip
      [nzTooltipMouseEnterDelay]="0.5"
      nzTooltipPlacement="right"
      nzTooltipTitle="Suivis de l'entité"
    >
      <mat-icon>article</mat-icon>

      <span>Suivis</span>
    </li>

    <li
      nz-menu-item
      (click)="onSelect('documents')"
      nz-tooltip
      [nzTooltipMouseEnterDelay]="0.5"
      nzTooltipPlacement="right"
      nzTooltipTitle="Documents de l'entité"
    >
      <mat-icon>insert_drive_file</mat-icon>
      <span>Documents</span>
    </li>
    <li
      nz-menu-item
      (click)="onSelect('metiers')"
      nz-tooltip
      [nzTooltipMouseEnterDelay]="0.5"
      nzTooltipPlacement="right"
      nzTooltipTitle="Métiers liés à l'entité"
    >
      <mat-icon> business_center </mat-icon>
      <span>Métiers</span>
    </li>
    <li
      nz-menu-item
      (click)="onSelect('projets')"
      nz-tooltip
      [nzTooltipMouseEnterDelay]="0.5"
      nzTooltipPlacement="right"
      nzTooltipTitle="Projets de l'entité"
    >
      <mat-icon> analytics </mat-icon>
      <span>Projets</span>
    </li>
    <!-- <li nz-menu-divider></li>
    <li nz-menu-item (click)="onSelect('besoins')">
      <mat-icon> pie_chart </mat-icon>
      <span>Mission</span>
    </li> -->
    <!-- <li
      nz-menu-item
      (click)="onSelect('dashboard')"
      nz-tooltip
      [nzTooltipMouseEnterDelay]="0.5"
      nzTooltipPlacement="right"
      nzTooltipTitle="Tableau de bord de l'entité"
    >
      <mat-icon> pie_chart </mat-icon>

      <span>Tableau de bord</span>
    </li> -->
  </ul>
</app-card-actions>
