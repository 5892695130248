<ng-container>
  <ng-container>
    <app-layout-list
      *ngIf="redactions$ | async | size; else noResults"
      [new]="new"
      [card]="card"
      [source]="redactions$ | async"
    ></app-layout-list>
  </ng-container>

  <ng-template #new>
    <app-card-new
      [title]="title"
      [type]="'service'"
      [description]="''"
      [definition]="definition"
      (add)="onNew()"
    ></app-card-new>
  </ng-template>

  <ng-template #card let-item let-position="idx">
    <app-redaction-card [item]="item"></app-redaction-card>
  </ng-template>

  <ng-template #noResults>
    <app-no-result
      [icon]="'note'"
      [addtitle]="'Ajouter une rédaction'"
      [notitle]="'Aucune rédaction trouvée'"
      [description]="description"
      (new)="onNew()"
    ></app-no-result>
  </ng-template>
</ng-container>
