import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-missions-card',
  templateUrl: './missions-card.component.html',
  styleUrls: ['./missions-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MissionsCardComponent implements OnInit, OnChanges {
  @Input() mission: any;

  @Output() select = new EventEmitter<any>(false);
  @Output() export = new EventEmitter<any>(false);

  @Output() delete = new EventEmitter<any>(false);
  @Output() cancel = new EventEmitter<any>(false);

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}

  onDropdown(item: string, mission: any): void {
    switch (item) {
      case 'copy':
        this.onCopy(mission);
        break;
      case 'exportPDF':
        this.onExportAsPdf(mission);
        break;
      case 'delete':
        this.onDelete(mission);
        break;
      default:
        break;
    }
  }

  onSelect(mission: any): void {
    if (!mission) return;
    this.select.emit(mission);
  }

  onCopy(mission: any): void {}

  onExportAsPdf(mission: any): void {
    if (!mission) return;
    this.export.emit(mission);
  }

  onDelete(mission: any): void {
    if (!mission) return;
    this.delete.emit(mission);
  }

  onCancel(): void {}
}
