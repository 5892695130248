import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import {
  Observable,
  of,
  BehaviorSubject,
  Subject,
  takeUntil,
  debounceTime,
  distinctUntilChanged,
} from 'rxjs';
import { EntiteSelectState } from 'src/app/contents/components/entite-select/store/entite-select.reducer';
import * as fromEntiteSelector from 'src/app/contents/components/entite-select/store/entite-select.selectors';
import * as fromEntiteAction from 'src/app/contents/components/entite-select/store/entite-select.actions';

import { EntiteSMALLid } from '../../store/entite.model';

@Component({
  selector: 'app-entite-selectors',
  templateUrl: './entite-selectors.component.html',
  styleUrls: ['./entite-selectors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntiteSelectorsComponent implements OnInit, OnDestroy {
  entites$: Observable<EntiteSMALLid[] | any> = of([]);
  loading$: Observable<boolean> = of(false);
  filterForm: UntypedFormGroup = this.fb.group({});
  input: UntypedFormControl = new UntypedFormControl();
  subscribe = new Subject();

  filterInputMode: string = '';
  filterInput$ = new BehaviorSubject<string>('');

  @Output() select = new EventEmitter<EntiteSMALLid | any>(false);

  constructor(
    private fb: UntypedFormBuilder,
    private entiteSelectStore: Store<EntiteSelectState>
  ) {}

  ngOnInit(): void {
    this.getEntites();
    this.queryEntites();
  }

  ngOnDestroy(): void {
    this.subscribe.next(false);
    this.subscribe.complete();
  }

  form(): void {
    this.formInit();
    this.formCreate();
  }
  formInit(): void {
    this.input = new UntypedFormControl('');
  }
  formCreate(): void {
    this.filterForm = this.fb.group({
      input: this.input,
    });
  }

  getEntites(): void {
    this.entites$ = this.entiteSelectStore.select(
      fromEntiteSelector.entitesSelect
    );
  }
  queryEntites(): void {
    this.filterInput$
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.subscribe),
        debounceTime(200)
      )
      .subscribe((value) => {
        if (!value || value?.length < 2) return;
        const filterType: string = 'raisonSociale';
        this.entiteSelectStore.dispatch(
          fromEntiteAction.loadEntiteSelects({ filter: value, filterType })
        );
      });
  }

  onSelect(item: EntiteSMALLid): void {
    if (!item) return;
    this.select.emit(item);
  }
}
