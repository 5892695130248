import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  ChangeDetectionStrategy,
} from '@angular/core';
import { EtablissementSierge } from 'src/app/components/entite/store/entite.model';

@Component({
  selector: 'app-entite-search-select',
  templateUrl: './entite-search-select.component.html',
  styleUrls: ['./entite-search-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntiteSearchSelectComponent implements OnInit {
  etablissements: EtablissementSierge[] | any[] = [];
  currentEtablissements: EtablissementSierge[] | any[] = [];
  is_siege: boolean = false;
  filterGroup: UntypedFormGroup = this.fb.group({});
  communeCtl: UntypedFormControl = new UntypedFormControl('');
  codePostalCtl: UntypedFormControl = new UntypedFormControl('');

  @Output() select = new EventEmitter<any>(false);
  @Output() cancel = new EventEmitter<any>(false);
  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.formInit();
    this.formCreate();
    this.onFilterChanges();
  }

  onSelect(etabllissment: any): void {
    this.select.emit(etabllissment);
  }

  onIsSiege(event: boolean): void {
    this.is_siege = event;
    const eventSelected: string = event ? 'true' : 'false';
    if (!event) {
      this.onReset();
    }

    if (event) {
      this.currentEtablissements = this.etablissements.filter(
        (el) => el.etablissement_siege === eventSelected
      );
    }
  }

  onFilterChanges(): void {
    this.filterGroup.valueChanges.subscribe((values: any) => {
      if (values.commune) {
        this.onCommuneFilter(values.commune);
      } else if (values.code_postal) {
        this.onCodeFilter(values.code_postal);
      } else {
        this.onReset();
      }
    });
  }

  onCommuneFilter(value: string): void {
    let input = value.toLowerCase();
    let filterEtablissements = this.etablissements.filter(
      (el: EtablissementSierge) =>
        el.libelle_commune.toLowerCase().includes(input)
    );
    this.currentEtablissements = filterEtablissements;
  }

  onCodeFilter(value: string): void {
    let filterEtablissements = this.etablissements.filter(
      (el: EtablissementSierge) => el.code_postal.includes(value)
    );

    this.currentEtablissements = filterEtablissements;
  }

  onReset() {
    this.currentEtablissements = this.etablissements;
  }

  formInit(): void {
    this.communeCtl = new UntypedFormControl('');
    this.codePostalCtl = new UntypedFormControl('');
  }

  formCreate(): void {
    this.filterGroup = this.fb.group({
      commune: this.codePostalCtl,
      code_postal: this.codePostalCtl,
    });
  }

  trackByFn(index: number, item: any) {
    return index;
  }
}
