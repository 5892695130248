<ng-container *ngIf="item">
  <div [ngSwitch]="item">
    <div *ngSwitchCase="'image/png' || 'image/jpeg'">
      <div class="avatar placeholder">
        <div class="bg-slate-50 rounded-full text-neutral-content w-12">
          <svg
            class="stroke-violet-600 fill-current"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g
              id="页面-1"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g
                id="File"
                transform="translate(-912.000000, 0.000000)"
                fill-rule="nonzero"
              >
                <g id="pic_line" transform="translate(912.000000, 0.000000)">
                  <path
                    d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z"
                    id="MingCute"
                    fill-rule="nonzero"
                  ></path>
                  <path
                    d="M20,3 C21.0543909,3 21.9181678,3.81587733 21.9945144,4.85073759 L22,5 L22,19 C22,20.0543909 21.18415,20.9181678 20.1492661,20.9945144 L20,21 L4,21 C2.94563773,21 2.08183483,20.18415 2.00548573,19.1492661 L2,19 L2,5 C2,3.94563773 2.81587733,3.08183483 3.85073759,3.00548573 L4,3 L20,3 Z M9.87852,12.0503 L4.22166,17.7071 C4.15418,17.7746 4.07946,17.8304 4,17.8746 L4,19 L20,19 L20,17.8747 C19.9204,17.8305 19.8456,17.7747 19.778,17.7071 L16.9496,14.8787 L16.2425,15.5858 L16.4496,15.7929 C16.8401,16.1834 16.8401,16.8166 16.4496,17.2071 C16.0591,17.5976 15.4259,17.5976 15.0354,17.2071 L9.87852,12.0503 Z M20,5 L4,5 L4,15.1003 L8.99463,10.1057 C9.450246,9.65009333 10.1700264,9.61971956 10.6608969,10.0145787 L10.7624,10.1057 L14.8283,14.1716 L16.0657,12.9341 C16.5213533,12.47854 17.2411276,12.4481693 17.731997,12.842988 L17.8335,12.9341 L20,15.1007 L20,5 Z M15.5,7 C16.3284,7 17,7.67157 17,8.5 C17,9.32843 16.3284,10 15.5,10 C14.6716,10 14,9.32843 14,8.5 C14,7.67157 14.6716,7 15.5,7 Z"
                    id="形状"
                    fill="#09244BFF"
                  ></path>
                </g>
              </g>
            </g>
          </svg>
        </div>
      </div>
    </div>
    <div
      *ngSwitchCase="
        'excel' ||
        'xlsx' ||
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      "
    >
      <div class="avatar placeholder">
        <div class="bg-slate-50 rounded-full text-neutral-content w-12">
          <svg
            class="stroke-green-600 fill-current"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g
              id="页面-1"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g
                id="File"
                transform="translate(-624.000000, -96.000000)"
                fill-rule="nonzero"
              >
                <g id="xls_line" transform="translate(624.000000, 96.000000)">
                  <path
                    d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z"
                    id="MingCute"
                    fill-rule="nonzero"
                  ></path>
                  <path
                    d="M13.5858,2 C14.1162,2 14.6249,2.21071 15,2.58579 L19.4142,7 C19.7893,7.37507 20,7.88378 20,8.41421 L20,20 C20,21.1046 19.1046,22 18,22 L6,22 C4.89543,22 4,21.1046 4,20 L4,4 C4,2.89543 4.89543,2 6,2 L13.5858,2 Z M12,4 L6,4 L6,20 L18,20 L18,10 L13.5,10 C12.6716,10 12,9.32843 12,8.5 L12,4 Z M10.9393,12.5251 L12,13.5858 L13.0607,12.5251 C13.4512,12.1346 14.0844,12.1346 14.4749,12.5251 C14.8655,12.9157 14.8655,13.5488 14.4749,13.9393 L13.4142,15.0001 L14.4748,16.0607 C14.8653,16.4512 14.8653,17.0844 14.4748,17.4749 C14.0843,17.8654 13.4511,17.8654 13.0606,17.4749 L12,16.4143 L10.9394,17.4749 C10.5489,17.8654 9.91571,17.8654 9.52518,17.4749 C9.13466,17.0844 9.13466,16.4512 9.52518,16.0607 L10.5858,15.0001 L9.52507,13.9393 C9.13455,13.5488 9.13455,12.9157 9.52507,12.5251 C9.9156,12.1346 10.5488,12.1346 10.9393,12.5251 Z M14,4.41421 L14,8 L17.5858,8 L14,4.41421 Z"
                    id="形状"
                    fill="#09244BFF"
                  ></path>
                </g>
              </g>
            </g>
          </svg>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="'application/pdf' || 'pdf'">
      <div class="avatar placeholder">
        <div class="bg-slate-50 rounded-full text-neutral-content w-12">
          <svg
            class="stroke-red-600 fill-current"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
          >
            <path
              d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z"
            ></path>
          </svg>
        </div>
      </div>
    </div>
    <div
      *ngSwitchCase="
        'ppt' ||
        'application/vnd.openxmlformats-officedocument.presentationml.presentation'
      "
    >
      <div class="avatar placeholder">
        <div class="bg-slate-50 rounded-full text-neutral-content w-12">
          <svg
            class="stroke-orange-600 fill-current"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <title>ppt_line</title>
            <g
              id="页面-1"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g id="File" transform="translate(-528.000000, -96.000000)">
                <g id="ppt_line" transform="translate(528.000000, 96.000000)">
                  <path
                    d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z"
                    id="MingCute"
                    fill-rule="nonzero"
                  ></path>
                  <path
                    d="M13.5858,2 C14.0572667,2 14.5115877,2.16648691 14.870172,2.46691468 L15,2.58579 L19.4142,7 C19.7476222,7.33339556 19.9511481,7.77238321 19.9922598,8.23835797 L20,8.41421 L20,20 C20,21.0543909 19.18415,21.9181678 18.1492661,21.9945144 L18,22 L6,22 C4.94563773,22 4.08183483,21.18415 4.00548573,20.1492661 L4,20 L4,4 C4,2.94563773 4.81587733,2.08183483 5.85073759,2.00548573 L6,2 L13.5858,2 Z M12,4 L6,4 L6,20 L18,20 L18,10 L13.5,10 C12.7203294,10 12.0795543,9.40511446 12.0068668,8.64446046 L12,8.5 L12,4 Z M12.5,11 C13.8807,11 15,12.1193 15,13.5 C15,14.8807 13.8807,16 12.5,16 L11,16 L11,17 C11,17.5523 10.5523,18 10,18 C9.44772,18 9,17.5523 9,17 L9,12.1 C9,11.4925 9.49249,11 10.1,11 L12.5,11 Z M12.5,13 L11,13 L11,14 L12.5,14 C12.7761,14 13,13.7761 13,13.5 C13,13.2545778 12.8230914,13.0504 12.5898645,13.0080571 L12.5,13 Z M14,4.41421 L14,8 L17.5858,8 L14,4.41421 Z"
                    id="形状"
                    fill="#09244BFF"
                  ></path>
                </g>
              </g>
            </g>
          </svg>
        </div>
      </div>
    </div>
    <div
      *ngSwitchCase="
        'doc' ||
        'docx' ||
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
        'application/msword'
      "
    >
      <div class="avatar placeholder">
        <div class="bg-slate-50 rounded-full text-neutral-content w-12">
          <svg
            class="stroke-blue-600 fill-current"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g
              id="页面-1"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g
                id="File"
                transform="translate(-96.000000, -96.000000)"
                fill-rule="nonzero"
              >
                <g id="doc_line" transform="translate(96.000000, 96.000000)">
                  <path
                    d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z"
                    id="MingCute"
                    fill-rule="nonzero"
                  ></path>
                  <path
                    d="M13.5858,2 C14.1162,2 14.6249,2.21071 15,2.58579 L19.4142,7 C19.7893,7.37507 20,7.88378 20,8.41421 L20,20 C20,21.1046 19.1046,22 18,22 L6,22 C4.89543,22 4,21.1046 4,20 L4,4 C4,2.89543 4.89543,2 6,2 L13.5858,2 Z M12,4 L6,4 L6,20 L18,20 L18,10 L13.5,10 C12.7203294,10 12.0795543,9.40511446 12.0068668,8.64446046 L12,8.5 L12,4 Z M14.0637,12.6489 C14.2576,12.1318 14.834,11.8698 15.3511,12.0637 C15.8312643,12.24375 16.0914699,12.7536087 15.9709451,13.2395462 L15.9363,13.3511 L14.53,17.1014 C14.1886174,18.0117217 12.9420907,18.0513009 12.522,17.2201376 L12.47,17.1014 L12,15.848 L11.53,17.1014 C11.1886174,18.0117217 9.94210904,18.0513009 9.52203734,17.2201376 L9.47004,17.1014 L8.06367,13.3511 C7.86975,12.834 8.13176,12.2576 8.64888,12.0637 C9.12906286,11.88365 9.66036821,12.0966974 9.88908227,12.5420706 L9.93633,12.6489 L10.5,14.152 L10.97,12.8986 C11.3113826,11.9882783 12.5579093,11.9486991 12.978,12.7798624 L13.03,12.8986 L13.5,14.152 L14.0637,12.6489 Z M14,4.41421 L14,8 L17.5858,8 L14,4.41421 Z"
                    id="形状"
                    fill="#09244BFF"
                  ></path>
                </g>
              </g>
            </g>
          </svg>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="'text'">
      <div class="avatar placeholder">
        <div class="bg-slate-50 rounded-full text-neutral-content w-12">
          <svg
            class="stroke-slate-600 fill-current"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g
              id="页面-1"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g id="File" transform="translate(-288.000000, 0.000000)">
                <g
                  id="docment_line"
                  transform="translate(288.000000, 0.000000)"
                >
                  <path
                    d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z"
                    id="MingCute"
                    fill-rule="nonzero"
                  ></path>
                  <path
                    d="M4,4 C4,2.89543 4.89543,2 6,2 L18,2 C19.1046,2 20,2.89543 20,4 L20,20 C20,21.1046 19.1046,22 18,22 L6,22 C4.89543,22 4,21.1046 4,20 L4,4 Z M18,4 L6,4 L6,20 L18,20 L18,4 Z M8,9 C8,8.44772 8.44772,8 9,8 L15,8 C15.5523,8 16,8.44772 16,9 C16,9.55228 15.5523,10 15,10 L9,10 C8.44772,10 8,9.55228 8,9 Z M9,13 C8.44772,13 8,13.4477 8,14 C8,14.5523 8.44772,15 9,15 L12,15 C12.5523,15 13,14.5523 13,14 C13,13.4477 12.5523,13 12,13 L9,13 Z"
                    id="形状"
                    fill="#09244BFF"
                  ></path>
                </g>
              </g>
            </g>
          </svg>
        </div>
      </div>
    </div>
    <div *ngSwitchDefault>
      <div class="avatar placeholder">
        <div class="bg-slate-50 rounded-full text-neutral-content w-12">
          <svg
            class="stroke-slate-600"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g
              id="页面-1"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g
                id="File"
                transform="translate(-864.000000, -192.000000)"
                fill-rule="nonzero"
              >
                <g
                  id="file_unknown_line"
                  transform="translate(864.000000, 192.000000)"
                >
                  <path
                    d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z"
                    id="MingCute"
                    fill-rule="nonzero"
                  ></path>
                  <path
                    d="M13.5858,2 C14.1162,2 14.6249,2.21071 15,2.58579 L19.4142,7 C19.7893,7.37507 20,7.88378 20,8.41421 L20,20 C20,21.1046 19.1046,22 18,22 L6,22 C4.89543,22 4,21.1046 4,20 L4,4 C4,2.89543 4.89543,2 6,2 L13.5858,2 Z M12,4 L6,4 L6,20 L18,20 L18,10 L13.5,10 C12.6716,10 12,9.32843 12,8.5 L12,8.5 L12,4 Z M12,17 C12.5523,17 13,17.4477 13,18 C13,18.5523 12.5523,19 12,19 C11.4477,19 11,18.5523 11,18 C11,17.4477 11.4477,17 12,17 Z M12,11 C13.3807,11 14.5,12.1193 14.5,13.5 C14.5,14.2976 14.1254,15.0083 13.5464,15.4644 C13.4521,15.5387 13.3543,15.6209 13.2543,15.705 C12.8466,16.0478 12.4015,16.4219 12,16.4219 C11.375,16.4219 11.1406,15.9324 11.1406,15.5781 C11.1406,15.2239 11.351,14.844 11.5252,14.6297 C11.7792,14.3172 12.0896,14.0661 12.3087,13.8934 C12.427,13.8002 12.5,13.6589 12.5,13.5 C12.5,13.2239 12.2761,13 12,13 C11.7239,13 11.5,13.2239 11.5,13.5 C11.5,14.0523 11.0523,14.5 10.5,14.5 C9.94772,14.5 9.5,14.0523 9.5,13.5 C9.5,12.1193 10.6193,11 12,11 Z M14,4.41421 L14,8 L17.5858,8 L14,4.41421 Z"
                    id="形状结合"
                    fill="#09244BFF"
                  ></path>
                </g>
              </g>
            </g>
          </svg>
        </div>
      </div>
    </div>
  </div>
</ng-container>
