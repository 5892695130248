import { ContentsModule } from 'src/app/contents/contents.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParticipantDocumentsComponent } from './components/participant-documents/participant-documents.component';
import { ParticipantStorageService } from 'src/app/service/participant/document/participant-storage.service';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ParticipantDocumentEffects } from './store/participant-document.effects';

import * as fromParticipantDocument from './store/participant-document.reducer';
import { DocumentModule } from 'src/app/features/document/document.module';

@NgModule({
  declarations: [ParticipantDocumentsComponent],
  imports: [
    CommonModule,
    ContentsModule,
    DocumentModule,
    StoreModule.forFeature(
      fromParticipantDocument.participantDocumentsFeatureKey,
      fromParticipantDocument.reducer
    ),
    EffectsModule.forFeature([ParticipantDocumentEffects]),
  ],
  exports: [ParticipantDocumentsComponent],
  providers: [ParticipantStorageService],
})
export class ParticipantDocumentsModule {}
