import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-tag-selector',
  templateUrl: './tag-selector.component.html',
  styleUrls: ['./tag-selector.component.scss'],
})
export class TagSelectorComponent implements OnInit {
  @Input() text: string | any = '';
  @Output() clear = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  onClear(): void {
    this.text = '';
    this.clear.emit(true);
  }
}
