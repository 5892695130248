import { ContactValidatorComponent } from '../../../../contents/components/contact-validator/contact-validator.component';
import { takeUntil } from 'rxjs/operators';
import { NzModalService } from 'ng-zorro-antd/modal';
import { OperationId } from '../../../operation/store/operation.model';
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewContainerRef,
} from '@angular/core';
import { Subject, BehaviorSubject, Observable, of } from 'rxjs';
import { ContactsSelectComponent } from 'src/app/contents/components/contacts-select/components/contacts-select.component';
import { Store } from '@ngrx/store';
import { OperationState } from '../../../operation/store/operation.reducer';
import * as fromOperationAction from '../../../operation/store/operation.actions';
import * as fromOperationSelector from '../../../operation/store/operation.selectors';
import { ContactSMALLid } from 'src/app/components/contact/store/contact.model';

@Component({
  selector: 'app-operation-contacts-filters',
  template: `
    <nz-row
      nzAlign="middle"
      nzGutter="4"
      nzJustify="center"
      *ngIf="operation$ | async as operation"
    >
      <nz-col nzSpan="6" class="filterTitle">
        <nz-badge [nzCount]="contacts$ | async | size" [nzOffset]="[10, 0]">
          <h4 nz-typography [ngStyle]="{ color: buttonColor }">
            {{ operation?.type | operationType: 'contact' }}
          </h4>
        </nz-badge>
      </nz-col>
      <nz-col nzSpan="14" class="inputSearch">
        <input
          nz-input
          [(ngModel)]="inputModel"
          (ngModelChange)="onSeach($event, operation)"
          nzBorderless
          placeholder="Rechercher un intervenant..."
        />
      </nz-col>

      <nz-col nzSpan="1"></nz-col>
      <nz-col nzSpan="1">
        <button nz-button nzShape="circle" nzType="text">
          <i nz-icon nzType="filter"></i>
        </button>
      </nz-col>
      <nz-col nzSpan="2">
        <button nz-button nzType="text" nzShape="circle">
          <mat-icon>more_vert</mat-icon>
        </button>
      </nz-col>
    </nz-row>
  `,
  styles: [
    `
      .filterTitle {
        display: flex;
        flex-direction: row;
        justify-content: center;
        justify-items: center;
        margin-top: 10px;
      }

      .addBtn {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        justify-items: flex-end;
        margin-top: -10px;
      }

      .btnFilter {
        margin-top: -10px;
      }

      .inputSearch {
        border-radius: 12px;
        background-color: #f5f5f5;
      }
    `,
  ],
})
export class OperationContactsFiltersComponent implements OnInit, OnDestroy {
  buttonColor: string = '#062949';
  modalWidth: number = 650;
  subscribe = new Subject();
  inputSearch$ = new BehaviorSubject<string>('');
  inputModel: string = '';
  entites$: Observable<any> = of();
  @Input() operation$: Observable<OperationId | any> = of();
  @Input() contacts$: Observable<any> = of(null);

  constructor(
    private operationStore: Store<OperationState>,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.getEntites();
    this.loadEntites();
  }
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  onNewContactForm(operation: OperationId): void {
    const modal = this.modal.create({
      nzContent: ContactsSelectComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: `Nouvel intervenant au projet : ${operation.denomination}`,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });
    const instance = modal.getContentComponent();
    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
    instance.select.subscribe((event: any): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
        this.onType(event, operation);
      }
    });
    instance.operation$ = this.operation$;
    instance.entites$ = this.entites$;
    instance.exists$ = this.contacts$;
  }

  getEntites(): void {
    this.entites$ = this.operationStore.select(fromOperationSelector.entites);
  }

  loadEntites(): void {
    this.operation$
      .pipe(takeUntil(this.subscribe))
      .subscribe((operation: OperationId) => {
        if (!operation) return;
        this.operationStore.dispatch(
          fromOperationAction.loadEntitesOperation({
            operationId: operation.id,
          })
        );
      });
  }

  onType(contacts: ContactSMALLid[], operation: OperationId): void {
    const modal = this.modal.create({
      nzContent: ContactValidatorComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: `Type d'intervention au projet : ${operation.denomination}`,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '10px' },
    });
    const instance = modal.getContentComponent();
    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
    instance.contacts = contacts;
    instance.validate.subscribe((contactsSelected: any) => {
      this.onAdd(contactsSelected, operation);
      modal.close();
    });
  }

  onAdd(contactsSelected: any, operation: OperationId): void {
    const { contacts } = contactsSelected;
    if (!contacts?.length || !operation) return;

    this.operationStore.dispatch(
      fromOperationAction.addContactsOperation({
        operation,
        contactsSelected: contacts,
      })
    );
  }

  onSeach(event: string, operation: OperationId): void {
    // if (!event) {
    //   this.moduleStore.dispatch(
    //     fromModuleAction.loadModules({ operationId: operation.id })
    //   );
    //   return;
    // } else {
    //   const filter = {
    //     operationId: operation.id,
    //     event: event,
    //     type: 'title',
    //   };
    //   this.moduleStore.dispatch(fromModuleAction.queryModules({ filter }));
    // }
  }

  onClearMdole(): void {
    return;
  }
}
