<nz-card
  nzSize="small"
  nzBorderless
  [nzTitle]="diffusionFormContactTitle"
  [nzExtra]="isList ? diffusionFormContactExtra : ''"
>
  <ng-container
    *ngIf="contactsArrayControler.controls?.length; else noContacts"
  ></ng-container>
  <form [formGroup]="diffusionContactForm">
    <div
      formArrayName="contacts"
      class="h-[450px] overflow-x-hidden overflow-y-auto p-2 divide-y"
    >
      <nz-card
        nzSize="small"
        [nzBorderless]="isList ? true : false"
        [nzTitle]="isList ? ficheContactFormItemTitle : ''"
        [nzExtra]="isList ? ficheContactFormExtraTpl : ''"
        *ngFor="let el of contactsArrayControler.controls; let i = index"
        [formGroupName]="i"
      >
        <div
          class="w-full flex flex-col items-start justify-between space-y-6 p-6"
        >
          <div class="w-full flex items-center justify-start">
            <div class="w-full flex items-center gap-x-2">
              <div [class]="'flex flex-col items-start w-2/12 mb-6'">
                <label class="label required">Civilité</label>
                <div
                  class="w-full pt-1 bg-slate-100 rounded-md h-10 hover:outline-1 hover:outline-slate-200 focus:outline-slate-400 active:outline-slate-400"
                >
                  <nz-select
                    formControlName="civilite"
                    nzPlaceHolder="Civilité"
                    nzBorderless
                    [compareWith]="compareCivilite"
                  >
                    <nz-option
                      *ngFor="let item of civilite$ | async"
                      [nzLabel]="item"
                      [nzValue]="item"
                    ></nz-option>
                  </nz-select>
                  <small
                    *ngIf="
                      el.value.civilite?.invalid &&
                      el.value.civilite?.dirty &&
                      el.value.civilite?.touched
                    "
                    class="inpurFormAlert"
                    >{{
                      el.value.civilite?.invalid &&
                      el.value.civilite?.dirty &&
                      el.value.civilite?.touched
                        ? "Renseignez la civilité du contact"
                        : ""
                    }}</small
                  >
                </div>
              </div>
              <div [class]="'flex flex-col items-start  w-4/12 mb-6'">
                <label class="label required">Nom</label>
                <input
                  formControlName="lastName"
                  placeholder="Nom..."
                  class="inputForm"
                />

                <small
                  *ngIf="
                    el.value.lastName?.invalid &&
                    el.value.lastName?.dirty &&
                    el.value.lastName?.touched
                  "
                  class="inputFormAlert"
                  >{{
                    el.value.lastName?.invalid &&
                    el.value.lastName?.dirty &&
                    el.value.lastName?.touched
                      ? "Renseignez le nom du contact"
                      : ""
                  }}</small
                >
              </div>
              <div [class]="'flex flex-col items-start  w-6/12 mb-6'">
                <label class="label required">Prénom</label>
                <input
                  class="inputForm"
                  formControlName="firstName"
                  placeholder="Prénom..."
                />
                <small
                  *ngIf="
                    el.value?.firstName?.invalid &&
                    el.value?.firstName?.dirty &&
                    el.value?.firstName?.touched
                  "
                  class="inputFormAlert"
                  >{{
                    el.value?.firstName?.invalid &&
                    el.value?.firstName?.dirty &&
                    el.value?.firstName?.touched
                      ? "Renseignez le prénom du contact"
                      : ""
                  }}</small
                >
              </div>
            </div>
          </div>
          <div class="w-full flex items-center justify-start space-x-2">
            <div
              [class]="
                isFonction && !isRole
                  ? 'w-full'
                  : isFonction && isRole
                  ? 'w-1/2'
                  : 'hidden'
              "
            >
              <div class="w-full flex flex-col items-start">
                <label class="label">Fonction</label>
                <input
                  formControlName="fonction"
                  [placeholder]="'Fonction...'"
                  class="inputForm"
                />
                <small
                  *ngIf="
                    el.value?.fonction?.invalid &&
                    el.value?.fonction?.dirty &&
                    el.value?.fonction?.touched
                  "
                  class="inputFormAlert"
                  >{{
                    el.value?.fonction?.invalid &&
                    el.value?.fonction?.dirty &&
                    el.value?.fonction?.touched
                      ? "Renseignez la fonction du contact"
                      : ""
                  }}</small
                >
              </div>
            </div>
            <div
              [class]="
                !isFonction && isRole
                  ? 'w-full'
                  : isFonction && isRole
                  ? 'w-1/2'
                  : 'hidden'
              "
            >
              <div class="w-full flex flex-col items-start">
                <label class="label">Rôle</label>

                <input
                  class="inputForm"
                  formControlName="role"
                  [placeholder]="'Rôle'"
                />
                <small
                  *ngIf="
                    el.value?.role?.invalid &&
                    el.value?.role?.dirty &&
                    el.value?.role?.touched
                  "
                  class="inpurFormAlert"
                  >{{
                    el.value?.role?.invalid &&
                    el.value?.role?.dirty &&
                    el.value?.role?.touched
                      ? "Veuillez renseigner le rôle du contact"
                      : ""
                  }}</small
                >
              </div>
            </div>
          </div>

          <div class="w-full flex items-center justify-star">
            <app-coordonnees
              [isFixeRequired]="false"
              [isMobile]="true"
              [update]="el.value.coordonnees"
              [isEmailRequired]="true"
              [isMobileRequired]="true"
              [isFixeRequired]="false"
              (coordonnees)="onChangesCoordonnees($event, i)"
              (fixe)="onChangesCoordonneesFixe($event, i)"
              (email)="onChangesCoordonneesEmail($event, i)"
              class="w-full"
            ></app-coordonnees>
          </div>
          <div class="w-full flex items-center justify-start space-x-2">
            <div
              [class]="
                isEtablissement
                  ? 'w-1/2 flex flex-col items-start'
                  : 'w-full flex flex-col items-start'
              "
              *ngIf="isAdresse"
            >
              <label class="label">Adresse</label>
              <app-communes-france
                [adresseItemUpdate]="el.value?.adresse"
                [isLabel]="false"
                [isComplement]="false"
                (adresse)="onAddAdresse($event, i)"
              ></app-communes-france>
            </div>

            <div
              [class]="
                isAdresse
                  ? 'w-1/2 flex flex-col items-start'
                  : 'w-full flex flex-col items-start'
              "
              *ngIf="isEtablissement"
            >
              <label class="label required">Établissement</label>
              <app-entite-search
                [etablissementUpdate]="
                  el.value?.etablissement?.denominationUniteLegale
                "
                (select)="onAddDEtablissement($event, i)"
              ></app-entite-search>
            </div>
          </div>
        </div>

        <ng-template #ficheContactFormItemTitle
          ><mat-icon>person</mat-icon></ng-template
        >
        <ng-template #ficheContactFormExtraTpl>
          <button class="btn-icon" (click)="onRemove(i)">
            <svg
              class="iconSvg"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 28 28"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
              />
            </svg>
          </button>
        </ng-template>
      </nz-card>
    </div>
  </form>
</nz-card>
<ng-template #diffusionFormContactTitle>
  <app-header-title
    [icon]="'send'"
    [title]="''"
    [subtitle]="true"
    [count]="contactsArrayControler.controls.length"
  ></app-header-title>
</ng-template>
<ng-template #diffusionFormContactExtra>
  <div class="p-6">
    <button
      (click)="onAdd()"
      nz-tooltip
      nzTooltipPlacement="bottom"
      nzTooltipTitle="Ajouter un contact"
      class="btn-default"
    >
      Ajouter un destinataire
    </button>
  </div>
</ng-template>

<ng-template #noContacts>
  <app-no-result
    [icon]="'group'"
    [title]="''"
    [description]="noDataTitle"
    [isButton]="false"
  ></app-no-result>
</ng-template>
