import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-information-collective-card',
  templateUrl: './information-collective-card.component.html',
  styleUrls: ['./information-collective-card.component.scss']
})
export class InformationCollectiveCardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
