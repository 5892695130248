import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  OrganisationState,
  organisationsFeatureKey,
  selectAll,
} from './organisation.reducer';

export const selectOrganisationsState =
  createFeatureSelector<OrganisationState>(organisationsFeatureKey);

export const organisations = createSelector(
  selectOrganisationsState,
  selectAll
);

export const organisation = createSelector(
  selectOrganisationsState,
  (state: OrganisationState) => state.organisation
);

export const utilities = createSelector(
  selectOrganisationsState,
  (state: OrganisationState) => state.utilities
);

export const loading = createSelector(
  selectOrganisationsState,
  (state: OrganisationState) => state.loading
);

export const error = createSelector(
  selectOrganisationsState,
  (state: OrganisationState) => state.error
);
