import { SideNavigationState } from './../../../side-navigation/store/side-navigation.reducer';
import { EntiteId } from './../../store/entite.model';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { EntiteState } from '../../store/entite.reducer';
import * as fromEntiteSelector from '../../store/entite.selectors';
import * as fromEntiteAction from '../../store/entite.actions';

import { Observable, of } from 'rxjs';
import * as sideNavigationAction from 'src/app/components/side-navigation/store/side-navigation.actions';
import { SideNavigation } from 'src/app/components/side-navigation/store/side-navigation.model';

@Component({
  selector: 'app-entite-item-header',
  templateUrl: './entite-item-header.component.html',
  styleUrls: ['./entite-item-header.component.scss'],
})
export class EntiteItemHeaderComponent implements OnInit {
  entite$: Observable<EntiteId | any> = of(null);
  deleteType: string = 'Voulez vous supprimer cette entité ?';
  constructor(
    private entiteStore: Store<EntiteState>,
    private sideNavigationStore: Store<SideNavigationState>
  ) {}

  ngOnInit(): void {
    this.getEntite();
  }

  getEntite(): void {
    this.entite$ = this.entiteStore.select(fromEntiteSelector.entite);
  }

  onSelectAction(type: string, entite: EntiteId): void {
    if (!type || !entite) return;

    switch (type) {
      case 'details':
        const navigation: SideNavigation = {
          type: 'entite',
          name: 'details',
          item: entite,
        };
        this.sideNavigationStore.dispatch(
          sideNavigationAction.loadSideNavigation({ navigation })
        );
        break;
      case 'dashboard':
        break;
      case 'download':
        break;
      case 'share':
        break;

      default:
        break;
    }
  }

  onAction(event: string, entite: EntiteId): void {
    if (!event || !entite) return;
    const { id } = entite;

    switch (event) {
      case 'delete':
        this.entiteStore.dispatch(fromEntiteAction.deleteEntite({ id }));
        break;

      default:
        break;
    }
  }
}
