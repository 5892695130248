import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-filter-list',
  templateUrl: './filter-list.component.html',
  styleUrls: ['./filter-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterListComponent implements OnInit, OnDestroy {
  subscribe = new Subject();
  isFilterBtnActive = false;

  filterForm: FormGroup = this.fb.group({});
  input = new FormControl('');

  @Input() placeholder: string = 'Rechercher...';
  @Input() tplLeft: TemplateRef<any> | any;
  @Input() tplRigth: TemplateRef<any> | any;
  @Input() isSearch: boolean = true;
  @Input() isFilterBtn: boolean = true;
  @Input() isDropdown: boolean = true;
  @Input() isDropdownExportWORD: boolean = true;
  @Input() isDropdownExportPDF: boolean = true;
  @Input() isDropdownExportExcel: boolean = true;
  @Input() isDropdownUpdate: boolean = true;
  @Input() isDropdownDelete: boolean = true;
  @Input() isDropdownDeleteAll: boolean = true;
  @Input() isDropdownDeleteTitle: string =
    'Voulez-vous supprimer cet éléments ?';

  @Output() currentFilter = new EventEmitter();
  @Output() actionSelected = new EventEmitter();

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.formCreate();
    this.onFormChanges();
  }
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  formCreate(): void {
    this.filterForm = this.fb.group({
      input: this.input,
    });
  }

  onAction(event: any): void {
    switch (event) {
      case 'on-search':
        this.onFormChanges();
        break;
      case 'off-search':
        this.filterForm.reset();
        this.currentFilter.emit('');
        break;
      case 'on-filter':
        this.isFilterBtnActive = !this.isFilterBtnActive;
        this.actionSelected.emit(event);
        break;

      case 'off-filter':
        this.isFilterBtnActive = !this.isFilterBtnActive;
        this.actionSelected.emit(event);
        break;

      default:
        this.actionSelected.emit(event);
        break;
    }
  }

  onFormChanges(): void {
    this.filterForm
      .get('input')
      ?.valueChanges.pipe(takeUntil(this.subscribe))
      .subscribe((value) => this.currentFilter.emit(value));
  }
}
