import { OperationId } from './../../../operation/store/operation.model';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import {
  Observable,
  of,
  BehaviorSubject,
  Subject,
  takeUntil,
  debounceTime,
  distinctUntilChanged,
} from 'rxjs';
import { OperationContactState } from './../../store/operation-contacts.reducer';
import * as fromOperationContactAction from './../../store/operation-contacts.actions';
import * as fromOperationContactSelector from './../../store/operation-contacts.selectors';

@Component({
  selector: 'app-operation-contacts-selectors',
  templateUrl: './operation-contacts-selectors.component.html',
  styleUrls: ['./operation-contacts-selectors.component.scss'],
})
export class OperationContactsSelectorsComponent implements OnInit {
  operation$: Observable<OperationId | any> = of(null);
  contacts$: Observable<any> = of([]);
  loading$: Observable<boolean> = of(false);
  filterForm: UntypedFormGroup = this.fb.group({});
  input: UntypedFormControl = new UntypedFormControl();
  subscribe = new Subject();
  filterInputMode: string = '';
  filterInput$ = new BehaviorSubject<string>('');

  @Output() select = new EventEmitter<any>(false);
  @Output() cancel = new EventEmitter<any>(false);

  constructor(
    private fb: UntypedFormBuilder,
    private operationContactStore: Store<OperationContactState>
  ) {}

  ngOnInit(): void {
    this.getContacts();
  }

  ngOnDestroy(): void {
    this.subscribe.next(false);
    this.subscribe.complete();
  }

  form(): void {
    this.formInit();
    this.formCreate();
  }
  formInit(): void {
    this.input = new UntypedFormControl('');
  }
  formCreate(): void {
    this.filterForm = this.fb.group({
      input: this.input,
    });
  }

  getContacts(): void {
    this.contacts$ = this.operationContactStore.select(
      fromOperationContactSelector.queries
    );
  }
  queryContacts(operation: OperationId): void {
    if (!operation) return;

    this.filterInput$
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.subscribe),
        debounceTime(200)
      )
      .subscribe((filter) => {
        if (!filter || filter?.length < 2) return;
        const filterType: string = 'name';
        const operationId: string = operation.id;

        this.operationContactStore.dispatch(
          fromOperationContactAction.queryOperationContacts({
            filter,
            filterType,
            operationId,
          })
        );
      });
  }

  onSelect(item: any): void {
    if (!item) return;
    this.select.emit(item);
  }

  onCancel(): void {
    this.cancel.emit(true);
  }
}
