<ng-container *ngIf="participant$ | async as participant">
  <ng-container *ngIf="diagnostics$ | async | size; else noResults">
    <nz-page-header>
      <nz-page-header-title>
        <app-header-title [icon]="icon"></app-header-title>
      </nz-page-header-title>
      <nz-page-header-extra>
        <nz-row nzAlign="top" nzGutter="8" nzJustify="end">
          <nz-col nzSpan="16">
            <button nz-button nzBlock (click)="onNew(participant)">
              Nouveau
            </button>
          </nz-col>
          <nz-col nzSpan="4"></nz-col>
          <nz-col nzSpan="4">
            <app-button-dropdown
              [isDeleteAll]="true"
              (select)="onDropDownSelect($event, participant)"
            ></app-button-dropdown>
          </nz-col>
        </nz-row>
      </nz-page-header-extra>
    </nz-page-header>
    <nz-space nzDirection="vertical" nzSize="large"></nz-space>
    <nz-card>
      <nz-list
        nzSize="default"
        [nzSplit]="true"
        [nzBordered]="false"
        nzItemLayout="vertical"
      >
        <nz-list-item
          nzNoFlex
          *ngFor="let item of diagnostics$ | async"
          [routerLink]="['/participant', participant.id, 'diagnostic', item.id]"
        >
          <app-participant-diagnostic-card
            [diagnostic]="item"
            (delete)="onDelete($event, participant)"
          ></app-participant-diagnostic-card>
        </nz-list-item>
      </nz-list>
    </nz-card>
  </ng-container>

  <ng-template #diagnosticListExtra>
    <nz-row nzAlign="top" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="4"> </nz-col>
      <nz-col nzSpan="4"></nz-col>
      <nz-col nzSpan="4">
        <button nz-button nzBlock (click)="onNew(participant)">Nouveau</button>
      </nz-col>
      <nz-col nzSpan="4">
        <button nz-button nzBlock>Point intérmédiaire</button>
      </nz-col>
      <nz-col nzSpan="4">
        <button nz-button nzBlock>Point final</button>
      </nz-col>
      <nz-col nzSpan="4">
        <app-button-dropdown [isDeleteAll]="true"></app-button-dropdown>
      </nz-col>
    </nz-row>
  </ng-template>

  <ng-template #noResults>
    <app-no-result
      [icon]="icon"
      [addtitle]="addtitle"
      [notitle]="notitle"
      [description]="description"
      (new)="onNew(participant)"
    ></app-no-result>
  </ng-template>

  <ng-template #diagnosticTitleForm>
    <app-header-title [icon]="icon" [title]="title"></app-header-title>
  </ng-template>
</ng-container>
