import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-card-new',
  templateUrl: './card-new.component.html',
  styleUrls: ['./card-new.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardNewComponent implements OnInit, OnChanges {
  @Input() user: any = null;
  @Input() type: string = '';
  @Input() title: string = '';
  @Input() description: string = '';
  @Input() definition: string = '';
  @Input() height: string = '350px';
  @Input() width: string = '350px';
  @Input() color: string = '#1890ff';
  @Input() cardColor: string = '#062949';

  @Output() add = new EventEmitter<any>(false);
  @Output() cancel = new EventEmitter<boolean>(false);
  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}

  onNew(): void {
    this.add.emit(true);
  }

  onCancel(): void {
    this.cancel.emit(true);
  }
}
