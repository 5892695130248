import { Injectable } from '@angular/core';
import { Observable, of, from } from 'rxjs';
import { map, tap, mergeMap, switchMap, catchError } from 'rxjs/operators';
import { Apollo, gql } from 'apollo-angular';
import {
  ParticipantId,
  ParticipantSmall,
  ParticipantSmallId,
} from './../components/participant/store/participant.model';
import {
  Firestore,
  collection,
  doc,
  docSnapshots,
  collectionChanges,
  getDoc,
  addDoc,
  setDoc,
  deleteDoc,
  serverTimestamp,
  DocumentData,
  Timestamp,
  query,
  where,
  arrayUnion,
  arrayRemove,
  collectionSnapshots,
  CollectionReference,
  DocumentReference,
  DocumentSnapshot,
  getDocs,
  documentId,
  limit,
} from '@angular/fire/firestore';
import { Store, select } from '@ngrx/store';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { UserState } from '../components/user/store/user.reducer';
import * as fromUserSelector from '../components/user/store/user.selectors';

@Injectable({
  providedIn: 'any',
})
export class ParticipantAidsService {
  constructor(
    private db: Firestore,
    private fns: Functions,
    private userStore: Store<UserState>
  ) {}

  getAll(id: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !id) {
            return of([]);
          } else {
            const dbCollection: CollectionReference<DocumentData> = collection(
              this.db,
              `organisations/${user.organisation.id}/participants/${id}/aids`
            );

            const changes = collectionSnapshots(dbCollection).pipe(
              map((changes) => {
                return changes.map((snap) => {
                  const data = snap.data();
                  const id = snap.id;
                  return { id, ...data };
                });
              })
            );

            return changes;
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  getOne(id: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) {
            return of(null);
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/participants/${id}/aids`
            );

            const documentRef = doc(dbCollection, id);
            return from(
              getDoc(documentRef)
                .then((snap) => {
                  const data = snap.data();
                  const id = snap.id;
                  return { id, ...data };
                })
                .catch((error) => error)
            ).pipe(mergeMap((res: any) => of(res)));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  addOne(id: string, aids: any[]): Observable<ParticipantSmallId | any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !id || !aids?.length) {
            return of(null);
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/participants/${id}/aids`
            );

            aids.forEach(async (aid: any) => {
              const newAid = await addDoc(dbCollection, {
                ...aid,
                receive: false,
                receiveDateStart: null,
                receiveDuration: 0,
                receiveDateEnd: null,
                reveiveValue: 0,
                dateStart: Timestamp.now(),
                dateUpdate: Timestamp.now(),
                userCreate: user,
                userUpdate: user,
              });
              if (newAid.id) {
                this.getAll(id);
              }
            });

            return of(null);
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  updateOne(
    id: string | number,
    aid: Partial<any>,
    participantId: string
  ): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !aid || !participantId) {
            return of(null);
          } else {
            const documentRef: DocumentReference<DocumentData> = doc(
              this.db,
              `organisations/${user.organisation.id}/participants/${participantId}/aids/${aid.id}`
            );
            const nextDocument: any = {
              ...aid,
              dateUpdate: serverTimestamp(),
              userUpdate: user,
            };
            return from(
              setDoc(documentRef, { ...nextDocument }, { merge: true })
                .then(() => of(`Allocation : ${aid?.name} modifiée`))
                .catch((error) => of(error))
            )
              .pipe((res) => res)
              .pipe(mergeMap((res) => res));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  deleteOne(participantId: string, id: any): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !id || !participantId) {
            return of(null);
          } else {
            const documentRef: DocumentReference<DocumentData> = doc(
              this.db,
              `organisations/${user.organisation.id}/participants/${participantId}/aids/${id}`
            );

            return from(
              deleteDoc(documentRef)
                .then(() => `Allocation supprimée`)
                .catch((error) => error)
            ).pipe(mergeMap((res) => of(res)));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  deleteAll(id: string, ids: string[]): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !ids?.length || !id) {
            return of(null);
          } else {
            //CALL HTTP

            return of(null);
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }
}
