import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EntiteState, entitesFeatureKey, selectAll } from './entite.reducer';

export const selectEntitesState =
  createFeatureSelector<EntiteState>(entitesFeatureKey);

export const entites = createSelector(selectEntitesState, selectAll);

export const entite = createSelector(
  selectEntitesState,
  (state: EntiteState) => state.entite
);

export const statistiques = createSelector(
  selectEntitesState,
  (state: EntiteState) => state.statistiques
);

export const entitesSearch = createSelector(
  selectEntitesState,
  (state: EntiteState) => state.entitesSearch
);

export const filters = createSelector(
  selectEntitesState,
  (state: EntiteState) => state.filters
);
export const sirets = createSelector(
  selectEntitesState,
  (state: EntiteState) => state.sirets
);

export const suivis = createSelector(
  selectEntitesState,
  (state: EntiteState) => state.suivis
);

export const suivi = createSelector(
  selectEntitesState,
  (state: EntiteState) => state.suivi
);
export const documents = createSelector(
  selectEntitesState,
  (state: EntiteState) => state.documents
);

export const document = createSelector(
  selectEntitesState,
  (state: EntiteState) => state.document
);

export const operations = createSelector(
  selectEntitesState,
  (state: EntiteState) => state.operations
);
export const operation = createSelector(
  selectEntitesState,
  (state: EntiteState) => state.operations
);

export const contacts = createSelector(
  selectEntitesState,
  (state: EntiteState) => state.contacts
);
export const contact = createSelector(
  selectEntitesState,
  (state: EntiteState) => state.contact
);

export const entitesByOperation = createSelector(
  selectEntitesState,
  (state: EntiteState) => state.entitesOperation
);

export const loading = createSelector(
  selectEntitesState,
  (state: EntiteState) => state.loading
);

export const error = createSelector(
  selectEntitesState,
  (state: EntiteState) => state.error
);
