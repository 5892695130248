import { ModuleId } from './../components/module/store/module.model';
import { ItemEventId } from '../features/suivi/components/item-event/itemEvent.model';
import { Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  doc,
  docSnapshots,
  collectionChanges,
  getDoc,
  addDoc,
  setDoc,
  deleteDoc,
  serverTimestamp,
  DocumentData,
  Timestamp,
  query,
  where,
  arrayUnion,
  arrayRemove,
  collectionSnapshots,
  CollectionReference,
  DocumentReference,
  getDocs,
  documentId,
} from '@angular/fire/firestore';
import { Observable, of, from, EMPTY } from 'rxjs';
import { switchMap, mergeMap, map, catchError } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { UserState } from '../components/user/store/user.reducer';
import * as fromUserSelector from '../components/user/store/user.selectors';
import { v4 as uuidV4 } from 'uuid';
import { format, fromUnixTime } from 'date-fns';
import { ItemEvent } from '../features/suivi/components/item-event/itemEvent.model';
import { ModuleState } from '../components/module/store/module.reducer';
import * as fromModuleSelector from '../components/module/store/module.selectors';

@Injectable({
  providedIn: 'any',
})
export class ModuleSuivisService {
  constructor(
    private db: Firestore,
    private fns: Functions,
    private userStore: Store<UserState>,
    private moduleStore: Store<ModuleState>
  ) {}

  getAll(operationId?: string, moduleId?: string): Observable<any> {
    try {
      return this.moduleStore.select(fromModuleSelector.module).pipe(
        switchMap((module: ModuleId) => {
          if (!module) {
            return EMPTY;
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${module.organisation.id}/operations/${module.operation.id}/modules/${module.id}/suivis`
            );

            return from(
              getDocs(dbCollection)
                .then((snap) => {
                  return snap.docs.map((doc: DocumentData) => {
                    const data = doc.data() as any;
                    const id = doc.id;
                    return { id, ...data };
                  });
                })
                .catch((error) => error)
            ).pipe(mergeMap((res) => of(res)));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  getOne(operationId: string, moduleId: string, id: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !operationId) {
            return of(null);
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/operations/${operationId}/modules/${moduleId}/suivis`
            );

            const documentRef = doc(dbCollection, id);
            return from(
              getDoc(documentRef)
                .then((snap) => {
                  const data = snap.data();
                  const id = snap.id;
                  return { id, ...data };
                })
                .catch((error) => error)
            ).pipe(mergeMap((res: any) => of(res)));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  addOne(operationId: string, moduleId: string, suivi: any): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !operationId) {
            return of(null);
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/operations/${operationId}/modules/${moduleId}/suivis`
            );

            const document: ItemEvent = {
              ...suivi,
              user: user,
              auteur: user,
              userUpdate: user,
              dateUpdate: serverTimestamp(),
            };

            return from(
              addDoc(dbCollection, document)
                .then((docRef) => {
                  if (docRef.id) {
                    return this.getOne(operationId, moduleId, docRef.id);
                  } else {
                    return of(null);
                  }
                })
                .catch((error) => of(error))
            ).pipe(mergeMap((res) => res));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  updateOne(
    operationId: string,
    moduleId: string,
    suivi: Partial<ItemEventId | any>
  ): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !operationId) {
            return of(null);
          } else {
            const dbDocument = doc(
              this.db,
              `organisations/${user.organisation.id}/operations/${operationId}/modules/${moduleId}/suivis/${suivi?.id}`
            );

            return from(
              setDoc(
                dbDocument,
                { ...suivi, userUpdate: user, dateUpdate: serverTimestamp() },
                { merge: true }
              )
                .then(() => this.getOne(operationId, moduleId, suivi.id))
                .catch((error) => of(error))
            ).pipe(mergeMap((res) => res));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  deleteOne(
    operationId: string,
    moduleId: string,
    id: string
  ): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !operationId) {
            return of(null);
          } else {
            const dbDocument = doc(
              this.db,
              `organisations/${user.organisation.id}/operations/${operationId}/modules/${moduleId}/suivis/${id}`
            );

            return from(
              deleteDoc(dbDocument)
                .then(() => of(`Suivi supprimé.`))
                .catch((error) => of(error))
            ).pipe(mergeMap((res) => res));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  deleteAll(operationId: string, ids: string[]): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) {
            return of(null);
          } else {
            //CALL HTTP

            return of(null);
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }
}
