import { GeoTerritoireModule } from './../geo-territoire/geo-territoire.module';
import { EntiteSearchEffects } from './store/entite-search.effects';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntiteSearchService } from './service/entite-search.service';
import { QueryEntiteSiretServiceGQL } from './graphql/queries/query-entite-siret.service';
import { QueryEntitesSearchServiceGQL } from './graphql/queries/query-entites-search.service';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as fromSearchEntite from './store/entite-search.reducer';
import { EntiteSearchComponent } from './components/entite-search/entite-search.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { EntiteSearchCardComponent } from './components/entite-search-card/entite-search-card.component';
import { EntiteSearchFiltersComponent } from './components/entite-search-filters/entite-search-filters.component';
import { EntiteSearchItemComponent } from './components/entite-search-item/entite-search-item.component';
import { NoResultModule } from '../no-result/no-result.module';
import { EntiteSearchItemConventionCardComponent } from './components/entite-search-item-convention-card/entite-search-item-convention-card.component';
import { UiModule } from 'src/app/ui/ui/ui.module';

@NgModule({
  declarations: [
    EntiteSearchComponent,
    EntiteSearchCardComponent,
    EntiteSearchCardComponent,
    EntiteSearchFiltersComponent,
    EntiteSearchItemComponent,
    EntiteSearchItemConventionCardComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    GeoTerritoireModule,
    NoResultModule,
    UiModule,
    EffectsModule.forFeature([EntiteSearchEffects]),
    StoreModule.forFeature(
      fromSearchEntite.entiteSearchesFeatureKey,
      fromSearchEntite.reducer
    ),
  ],
  exports: [EntiteSearchComponent, EntiteSearchItemComponent],

  providers: [
    EntiteSearchService,
    QueryEntiteSiretServiceGQL,
    QueryEntitesSearchServiceGQL,
  ],
})
export class EntiteSearchModule {}
