import { QueryParticipantPlanningFilterMonthTypeGQL } from './../../GRAPHQL/queries/PARTICIPANT/planning/query-participant-planning-filter-month-type.service';
import { QueryParticipantPlanningsGQL } from './../../GRAPHQL/queries/PARTICIPANT/planning/query-participant-plannings.service';
import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { EMPTY, map, Observable, of } from 'rxjs';
import { QueryParticipantPlanningGQL } from '../../GRAPHQL/queries/PARTICIPANT/planning/query-participant-planning.service';

@Injectable({
  providedIn: 'any',
})
export class ParticipantPlanningService {
  GET_JOURS_FERIES = gql`
    query getJoursFeries {
      getJoursFeries
    }
  `;

  CREATE_PARTICIPANT_EVENT = gql`
    mutation CreateParticipantPlanning($id: ID!, $event: PlanningInput!) {
      createParticipantPlanning(id: $id, event: $event) {
        id
        type {
          label
          color
          colorLabel
        }
        content
        createAt
        dateStart
        referent {
          avatar
          displayName
          fonction
          setting {
            color
          }
        }
        auteur {
          avatar
          displayName
          fonction
          setting {
            color
          }
        }
      }
    }
  `;

  UPDATE_PARTICIPANT_EVENT = gql`
    mutation UpdateParticipantPlanning($id: ID!, $event: PlanningUpdateInput!) {
      updateParticipantPlanning(id: $id, event: $event) {
        id
        type {
          label
          color
          colorLabel
          value
        }
        content
        createAt
        auteur {
          avatar
          displayName
          fonction
          setting {
            color
          }
        }

        referent {
          avatar
          displayName
          fonction
          setting {
            color
          }
        }
        dateStart
        dateUpdate
        isValided
        isPresent
        userUpdate {
          avatar
          displayName
          fonction
          setting {
            color
          }
        }
      }
    }
  `;

  DELETE_PARTICIPANT_EVENT = gql`
    mutation DeleteParticipantPlanning($participantId: ID!, $id: ID!) {
      deleteParticipantPlanning(participantId: $participantId, id: $id)
    }
  `;

  DELETE_PARTICIPANT_EVENTS = gql`
    mutation DeleteParticipantPlannings($id: ID!) {
      deleteParticipantPlannings(id: $id)
    }
  `;

  constructor(
    private apollo: Apollo,
    private queryParticipantPlanningsGQL: QueryParticipantPlanningsGQL,
    private queryParticipantPlanningGQL: QueryParticipantPlanningGQL,
    private queryParticipantPlanningFilterMonthTypeGQL: QueryParticipantPlanningFilterMonthTypeGQL
  ) {}

  getJoursFeries(): Observable<any> {
    try {
      return this.apollo
        .watchQuery<any>({
          query: this.GET_JOURS_FERIES,
        })
        .valueChanges.pipe(
          map(({ data, loading, error }) => {
            return { data, loading, error };
          })
        );
    } catch (error) {
      return of(error);
    }
  }

  getPlannings(id: string): Observable<any> {
    try {
      if (!id) return EMPTY;
      return this.queryParticipantPlanningFilterMonthTypeGQL
        .watch({ id })
        .valueChanges.pipe(
          map(({ data, loading, error }) => {
            return { data, loading, error };
          })
        );
    } catch (error) {
      return of(error);
    }
  }

  getPlanningsFiltered(
    id: string,
    filter: { type: string; createAtMonthYear: string }
  ): Observable<any> {
    try {
      if (!id || !filter.createAtMonthYear) return EMPTY;

      return this.queryParticipantPlanningFilterMonthTypeGQL
        .watch({ id, filter })
        .valueChanges.pipe(
          map(({ data, loading, error }) => {
            return { data, loading, error };
          })
        );
    } catch (error) {
      return of(error);
    }
  }

  getPlanningEvent(participantId: string, id: string): Observable<any> {
    try {
      if (!participantId || !id) EMPTY;

      return this.queryParticipantPlanningGQL
        .watch({
          participantId,
          id,
        })
        .valueChanges.pipe(
          map(({ data, loading, error }) => {
            return { data, loading, error };
          })
        );
    } catch (error) {
      return of(error);
    }
  }

  createPlanningEvent(id: string, event: any): Observable<any> {
    try {
      if (!id || !event) EMPTY;

      return this.apollo.mutate<any>({
        mutation: this.CREATE_PARTICIPANT_EVENT,
        variables: { id, event },
      });
    } catch (error) {
      return of(error);
    }
  }

  updatePlanningEvent(id: string, event: any): Observable<any> {
    try {
      if (!id || !event) EMPTY;

      return this.apollo.mutate<any>({
        mutation: this.UPDATE_PARTICIPANT_EVENT,
        variables: { id, event: event.changes },
      });
    } catch (error) {
      return of(error);
    }
  }

  deletePlanningEvent(participantId: string, id: string): Observable<any> {
    try {
      if (!participantId || !id) EMPTY;

      return this.apollo.mutate<any>({
        mutation: this.DELETE_PARTICIPANT_EVENT,
        variables: { participantId, id },
      });
    } catch (error) {
      return of(error);
    }
  }
  deletePlanningEvents(id: string): Observable<any> {
    try {
      if (!id) EMPTY;

      return this.apollo.mutate<any>({
        mutation: this.DELETE_PARTICIPANT_EVENTS,
        variables: { id },
      });
    } catch (error) {
      return of(error);
    }
  }
}
