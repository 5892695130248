import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { OperationTeamRoleComponent } from '../operation-team-role/operation-team-role.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { OperationId } from '../../../operation/store/operation.model';
import {
  Component,
  Input,
  OnInit,
  ViewContainerRef,
  OnDestroy,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { of, Observable, Subject, BehaviorSubject } from 'rxjs';
import { UsersSelectComponent } from 'src/app/contents/components/users-select/users-select.component';
import { UserListSelectorComponent } from 'src/app/components/user/components/user-list-selector/user-list-selector.component';
import { OperationTeamState } from '../../store/operation-team.reducer';
import * as fromOperationTeamAction from '../../store/operation-team.actions';
import * as fromOperationTeamSelector from '../../store/operation-team.selectors';
import { OperationState } from 'src/app/components/operation/store/operation.reducer';
import * as fromOperationSelector from 'src/app/components/operation/store/operation.selectors';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';

@Component({
  selector: 'app-operation-team',
  templateUrl: './operation-team.component.html',
  styleUrls: ['./operation-team.component.scss'],
})
export class OperationTeamComponent implements OnInit, OnDestroy {
  user$: Observable<any> = of();
  operation$: Observable<OperationId | any> = of();

  buttonColor: string = '#062949';
  bgColor: string = '#bfbfbf';
  bgDefault: string = '#fff';
  modalWidth: number = 650;
  members$: Observable<any> = of(null);
  subscribe = new Subject();
  titlePage: string = 'Équipe projet';

  view$ = new BehaviorSubject<string>('list');
  loading$: Observable<boolean> = of(false);
  currentUser$ = new BehaviorSubject<any>(false);
  title: string = 'Nouveau collaborateur';
  roleTitle: string = 'Rôle collaborateur';
  deleteAllType: string = "Voulez-vous supprimer toute l'équipe projet ?";

  newCardTitle: string = 'Nouveau collaborateur';
  newCardDescription: string = 'Ajouter un collaborateur au projet';
  noModuleTitle: string = 'Aucun collaborateur ajouté';
  newCardDefinition: string =
    "Un collaborateur est un membre de l'équipe projet.";

  @ViewChild('teamFormTitle', { static: false })
  teamFormTitle: TemplateRef<any> | any;
  @ViewChild('userFormTitle', { static: false })
  userFormTitle: TemplateRef<any> | any;

  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private operationTeamStore: Store<OperationTeamState>,
    private operationStore: Store<OperationState>,
    private userStore: Store<UserState>
  ) {
    this.getUser();
    this.getOperation();
  }

  ngOnInit(): void {
    this.getTeam();
    this.loadTeam();
  }

  getOperation(): void {
    this.operation$ = this.operationStore.select(
      fromOperationSelector.operation
    );
  }
  getUser(): void {
    this.user$ = this.userStore.select(fromUserSelector.user);
  }

  getTeam(): void {
    this.members$ = this.operationTeamStore.select(
      fromOperationTeamSelector.team
    );
  }

  loadTeam(): void {
    this.operation$.pipe(takeUntil(this.subscribe)).subscribe((operation) => {
      if (!operation) {
        return;
      } else {
        this.operationTeamStore.dispatch(
          fromOperationTeamAction.loadOperationTeams({
            id: operation.id,
          })
        );
      }
    });
  }

  onSelect(operation: OperationId, member: any): void {
    this.currentUser$.next(member);
  }

  onNew(operation: OperationId, usersmembers: any[]): void {
    const modal = this.modal.create({
      nzContent: UserListSelectorComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.teamFormTitle,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });
    const instance = modal.getContentComponent();
    instance.cancel.subscribe((event: any): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
    instance.select.subscribe((users: any[]): any => {
      if (!users || users.length === 0) {
        return null;
      } else {
        this.onUsersRoles(operation, users);
        modal.close();
      }
    });
  }

  onUsersRoles(operation: OperationId, members: any[]): void {
    const modal = this.modal.create({
      nzContent: OperationTeamRoleComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.userFormTitle,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px', height: '400px' },
    });
    const instance = modal.getContentComponent();
    instance.members.next(members);
    instance.cancel.subscribe((event: any): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
    instance.validate.subscribe((users: any): any => {
      if (!users) {
        return null;
      } else {
        modal.close();
        this.onAdd(operation, users);
      }
    });
  }

  onAdd(operation: OperationId, users: any[]): void {
    if (!operation?.id || !users?.length) return;

    const { id } = operation;

    this.operationTeamStore.dispatch(
      fromOperationTeamAction.addOperationTeams({
        id,
        users,
      })
    );
  }

  onAction(event: string, operation: OperationId): void {
    switch (event) {
      case 'deleteAll':
        this.onDeleteAll(operation);
        break;

      default:
        break;
    }
  }

  onDelete(event: any): void {
    const { operation, item } = event;
    this.operationTeamStore.dispatch(
      fromOperationTeamAction.deleteOperationTeam({
        operationId: operation.id,
        id: item.id,
      })
    );
  }

  onDeleteAll(operation: OperationId): void {
    this.operationTeamStore.dispatch(
      fromOperationTeamAction.deleteOperationTeams({
        operationId: operation.id,
        ids: [],
      })
    );
  }

  onCancel(): void {
    return;
  }

  onBack(): void {}

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }
}
