import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ParticipantId } from './../../store/participant.model';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { ParticipantState } from '../../store/participant.reducer';
import * as fromParticipantAction from '../../store/participant.actions';
import * as fromParticipantSelector from '../../store/participant.selectors';

import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';

@Component({
  selector: 'app-participant-identity',
  templateUrl: './participant-identity.component.html',
  styleUrls: ['./participant-identity.component.scss'],
})
export class ParticipantIdentityComponent implements OnInit {
  default: string = '#bfbfbf';
  avatarBackgroundColor: string = '#1890ff';
  user$: Observable<any> = of(null);

  @Input() participant: ParticipantId | any = null;
  @Output() update = new EventEmitter<boolean>(false);
  @Output() select = new EventEmitter<any>(false);

  constructor(
    private participantStore: Store<ParticipantState>,
    private userStore: Store<UserState>,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getUser();
  }

  getUser(): void {
    this.user$ = this.userStore.select(fromUserSelector.user);
  }

  onSelect(event: string): void {
    this.select.emit(event);
  }
  onUpdate(): void {
    this.update.emit(true);
  }
  onDelete(id: string): void {
    if (!id) {
      return;
    } else {
      this.participantStore.dispatch(
        fromParticipantAction.deleteParticipant({ id })
      );
      this.router.navigate(['participants']);
    }
  }
  onCancel(): void {}
}
