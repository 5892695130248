<form nz-form [formGroup]="itemform" nzLayout="vertical">
  <nz-row nzAlign="top" nzGutter="2" nzJustify="start">
    <nz-col nzSpan="16">
      <nz-form-item>
        <nz-form-label nzRequired>Titre</nz-form-label>
        <nz-form-control>
          <input
            nz-input
            nzBorderless
            formControlName="title"
            placeholder="Titre"
          />
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col nzSpan="8">
      <nz-form-item>
        <nz-form-label nzRequired>Type</nz-form-label>
        <nz-form-control>
          <nz-select nzBorderless formControlName="type" nzPlaceHolder="Type">
            <nz-option
              *ngFor="let item of types"
              [nzLabel]="item"
              [nzValue]="item"
            >
            </nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>

  <nz-row nzAlign="top" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="24">
      <nz-form-item>
        <nz-form-control>
          <textarea
            nz-input
            nzBorderless
            formControlName="description"
            placeholder="Description"
            [nzAutosize]="{ minRows: 4, maxRows: 4 }"
          ></textarea>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>
</form>
<nz-row nzAlign="top" nzGutter="8" nzJustify="end">
  <nz-col nzSpan="16"></nz-col>
  <nz-col nzSpan="4">
    <button
      *ngIf="isAdd"
      nz-button
      (click)="onAdd()"
      nzType="primary"
      [disabled]="!itemform.valid"
      nzBlock
    >
      <i nz-icon nzType="check-circle"></i>
      <span>Ajouter</span>
    </button>
    <button
      *ngIf="isUpdate"
      nz-button
      (click)="onUpdate()"
      nzType="primary"
      [disabled]="!itemform.valid"
      nzBlock
    >
      <i nz-icon nzType="check-circle"></i>
      <span>Modifier</span>
    </button>
  </nz-col>
  <nz-col nzSpan="4">
    <button nz-button (click)="onCancel()" nzBlock nzType="text">
      <i nz-icon nzType="close-circle"></i>
      <span>Annuler</span>
    </button>
  </nz-col>
</nz-row>
