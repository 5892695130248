<form nz-form [formGroup]="filterForm" *ngIf="operation$ | async as operation">
  <nz-form-item>
    <nz-form-control>
      <nz-select
        nzServerSearch
        nzBorderless
        nzAllowClear
        nzShowSearch
        [ngModelOptions]="{ standalone: true }"
        [nzNotFoundContent]="noData"
        [nzLoading]="loading$ | async"
        nzPlaceHolder="Rechercher"
        [(ngModel)]="filterInputMode"
        (nzOnSearch)="filterInput$.next($event); queryContacts(operation)"
        [nzOptionHeightPx]="100"
        (ngModelChange)="onSelect($event)"
      >
        <nz-option
          *ngFor="let item of contacts$ | async"
          [nzLabel]="item?.contact?.displayName"
          [nzValue]="item"
          nzCustomContent
        >
          <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
            <nz-col nzSpan="2">
              <mat-icon>person</mat-icon>
            </nz-col>
            <nz-col nzSpan="2">
              <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
            </nz-col>

            <nz-col nzSpan="20">
              <strong>{{
                item?.contact?.displayName | carLimitation: 120
              }}</strong>
            </nz-col>
          </nz-row>
          <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
            <nz-col nzSpan="2"> </nz-col>
            <nz-col nzSpan="2"> </nz-col>

            <nz-col nzSpan="8">
              <nz-tag nzColor="processing">{{
                item?.contact?.entitesTitles?.[0] | carLimitation: 40
              }}</nz-tag>
            </nz-col>
            <nz-col nzSpan="2">
              <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
            </nz-col>
            <nz-col nzSpan="6">
              <nz-tag nzColor="processing">
                {{ item?.intervention?.role | carLimitation: 20 }}
              </nz-tag>
            </nz-col>
            <nz-col nzSpan="4">
              <nz-tag nzColor="success">Disponible</nz-tag>
            </nz-col>
          </nz-row>
          <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
        </nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
</form>

<ng-template #noData>
  <app-no-result
    [icon]="'person'"
    [description]="'Aucun contact trouvé'"
    [isButton]="false"
  ></app-no-result>
</ng-template>
