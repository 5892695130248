<ng-container *ngIf="operation$ | async as operation">
  <div class="Operation_Details_Container">
    <nz-descriptions nzBordered>
      <nz-descriptions-item nzTitle="Description" nzSpan="3">
        {{ operation?.description?.definition }}
      </nz-descriptions-item>
    </nz-descriptions>
    <nz-descriptions nzBordered>
      <nz-descriptions-item nzTitle="Adresse" nzSpan="3">
      </nz-descriptions-item>
    </nz-descriptions>
    <nz-descriptions nzBordered>
      <nz-descriptions-item nzTitle="Financeurs" nzSpan="3">
      </nz-descriptions-item>
    </nz-descriptions>
  </div>
</ng-container>
