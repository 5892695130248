import { ContentsModule } from './../../contents/contents.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CandidatureFormComponent } from './components/candidature-form/candidature-form.component';
import { CandidatureListComponent } from './components/candidature-list/candidature-list.component';
import { CandidatureItemComponent } from './components/candidature-item/candidature-item.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { EntiteSearchModule } from 'src/app/contents/components/entite-search/entite-search.module';
import { PipeModule } from 'src/app/pipe/pipe.module';
import { CandidatureFormPersonComponent } from './components/candidature-form-person/candidature-form-person.component';
import { NoResultModule } from 'src/app/contents/components/no-result/no-result.module';
import { CoordonneesModule } from 'src/app/contents/components/coordonnees/coordonnees.module';
import { CommunesFranceModule } from 'src/app/contents/components/communes-france/communes-france.module';
import { HeaderModule } from 'src/app/contents/components/header-title/header.module';
import { CandidatureFormValidationComponent } from './components/candidature-form-validation/candidature-form-validation.component';
import { CandidatureExportComponent } from './components/candidature-export/candidature-export.component';
import { GeoTerritoireModule } from 'src/app/contents/components/geo-territoire/geo-territoire.module';
import { CandidatureFormSimplifyComponent } from './components/candidature-form-simplify/candidature-form-simplify.component';
import { LayoutModule } from 'src/app/contents/components/layout/layout.module';
import { ButtonsModule } from 'src/app/contents/components/buttons/buttons.module';
import { CandidatureItemDocumentsComponent } from './components/candidature-item-documents/candidature-item-documents.component';
import { CandidatureItemTransfertComponent } from './components/candidature-item-transfert/candidature-item-transfert.component';
import { StoreModule } from '@ngrx/store';
import * as fromCandidature from './store/candidature.reducer';
import { EffectsModule } from '@ngrx/effects';
import { CandidatureEffects } from './store/candidature.effects';
import { CandidatureExportExcelService } from './services/candidature-export-excel.service';
import { CandidatureExportPdfService } from './services/candidature-export-pdf.service';
import { CandidatureExportWordService } from './services/candidature-export-word.service';
import { CandidatureStartistiquesExportPdfService } from './services/candidature-startistiques-export-pdf.service';
import { CandidatureStartistiquesExportPptService } from './services/candidature-startistiques-export-ppt.service';
import { PersonModule } from 'src/app/features/person/person.module';
import { ParticipantDiagnosticModule } from 'src/app/components/participant-diagnostic/participant-diagnostic.module';
import { AvatarModule } from '../avatar/avatar.module';
import { SuiviModule } from '../suivi/suivi.module';
import { CandidatureFormPersonItemComponent } from './components/candidature-form-person-item/candidature-form-person-item.component';
import { TableModule } from '../table/table.module';
import { CandidatureItemActionsComponent } from './components/candidature-item-actions/candidature-item-actions.component';
import { CandidatureItemPrescripteurComponent } from './components/candidature-item-prescripteur/candidature-item-prescripteur.component';
import { DocumentModule } from '../document/document.module';
import { UiModule } from 'src/app/ui/ui/ui.module';

@NgModule({
  declarations: [
    CandidatureFormComponent,
    CandidatureListComponent,
    CandidatureItemComponent,
    CandidatureFormPersonComponent,
    CandidatureFormValidationComponent,
    CandidatureExportComponent,
    CandidatureFormSimplifyComponent,
    CandidatureItemDocumentsComponent,
    CandidatureItemTransfertComponent,
    CandidatureFormPersonItemComponent,
    CandidatureItemActionsComponent,
    CandidatureItemPrescripteurComponent,
  ],
  exports: [
    CandidatureFormComponent,
    CandidatureListComponent,
    CandidatureItemComponent,
  ],
  imports: [
    CommonModule,
    ContentsModule,
    SharedModule,
    EntiteSearchModule,
    PipeModule,
    PersonModule,
    ParticipantDiagnosticModule,
    NoResultModule,
    CoordonneesModule,
    HeaderModule,
    CommunesFranceModule,
    GeoTerritoireModule,
    LayoutModule,
    ButtonsModule,
    AvatarModule,
    SuiviModule,
    TableModule,
    DocumentModule,
    UiModule,
    StoreModule.forFeature(
      fromCandidature.candidaturesFeatureKey,
      fromCandidature.reducer
    ),
    EffectsModule.forFeature([CandidatureEffects]),
  ],
  providers: [
    CandidatureExportExcelService,
    CandidatureExportPdfService,
    CandidatureExportWordService,
    CandidatureStartistiquesExportPdfService,
    CandidatureStartistiquesExportPptService,
  ],
})
export class CandidatureModule {}
