<ng-container *ngIf="user$ | async as user">
  <div
    nzAlign="top"
    nzJustify="start"
    cdkDropListGroup
    class="group_container"
    *ngIf="!(loading$ | async); else taskLoading"
  >
    <div nzAlign="top" class="container content_todo">
      <app-task-header
        [isButton]="true"
        [user$]="user$"
        [taskSize]="tasks$ | async | size"
        (add)="onNew()"
        (deleteAll)="deleteTasks()"
        (action)="onFilterAction($event)"
        [title]="'À faire'"
        [count]="(tasks$ | async | size) ? (tasks$ | async | size) : 0"
        [icon]="''"
      ></app-task-header>

      <nz-space></nz-space>
      <div
        cdkDropList
        #todoList="cdkDropList"
        [cdkDropListData]="tasks$ | async"
        (cdkDropEntered)="entered($event)"
        (cdkDropExited)="exited($event)"
        [cdkDropListConnectedTo]="[doneList, testList, verifedList]"
        class="list"
        (cdkDropListDropped)="drop($event)"
      >
        <app-task-item
          [task]="item"
          [user]="user$ | async"
          [userSelectorRef]="userSelectorRef"
          class="box"
          *ngFor="let item of tasks$ | async; trackBy: trackBy"
          cdkDrag
          (delete)="deleteTask($event)"
          (update)="onUpdate($event)"
        ></app-task-item>
      </div>
    </div>

    <div nzSpan="6" class="container content_inprogress">
      <app-task-header
        [title]="'En cours'"
        [count]="
          (tasksInProgress$ | async | size)
            ? (tasksInProgress$ | async | size)
            : 0
        "
        [icon]="'security-scan'"
      ></app-task-header>
      <nz-space></nz-space>

      <div
        cdkDropList
        #testList="cdkDropList"
        [cdkDropListData]="tasksInProgress$ | async"
        (cdkDropEntered)="entered($event)"
        (cdkDropExited)="exited($event)"
        [cdkDropListConnectedTo]="[todoList, doneList, verifedList]"
        class="list"
        (cdkDropListDropped)="drop($event)"
      >
        <app-task-item
          class="box"
          *ngFor="let item of tasksInProgress$ | async; trackBy: trackBy"
          cdkDrag
          [task]="item"
          [user]="user$ | async"
          [type]="'tested'"
          [userSelectorRef]="userSelectorRef"
          (delete)="deleteTask($event)"
          (update)="onUpdate($event)"
        ></app-task-item>
      </div>
    </div>
    <div nzSpan="6" class="container content_verified">
      <app-task-header
        [title]="'Vérifiées'"
        [count]="(verifed$ | async | size) ? (verifed$ | async | size) : 0"
        [icon]="'safety'"
      ></app-task-header>
      <nz-space></nz-space>

      <div
        cdkDropList
        #verifedList="cdkDropList"
        [cdkDropListData]="verifed$ | async"
        (cdkDropEntered)="entered($event)"
        (cdkDropExited)="exited($event)"
        [cdkDropListConnectedTo]="[todoList, doneList, testList]"
        class="list"
        (cdkDropListDropped)="drop($event)"
      >
        <app-task-item
          class="box"
          *ngFor="let item of verifed$ | async; trackBy: trackBy"
          cdkDrag
          [task]="item"
          [user]="user$ | async"
          [type]="'verifed'"
          [userSelectorRef]="userSelectorRef"
          (delete)="deleteTask($event)"
          (update)="onUpdate($event)"
        ></app-task-item>
      </div>
    </div>
    <div nzSpan="6" class="container content_done">
      <app-task-header
        [title]="'Terminées'"
        [count]="(dones$ | async | size) ? (dones$ | async | size) : 0"
        [icon]="'check-circle'"
      ></app-task-header>
      <nz-space></nz-space>

      <div
        cdkDropList
        #doneList="cdkDropList"
        [cdkDropListData]="dones$ | async"
        (cdkDropEntered)="entered($event)"
        (cdkDropExited)="exited($event)"
        [cdkDropListConnectedTo]="[todoList, testList, verifedList]"
        class="list"
        (cdkDropListDropped)="drop($event)"
      >
        <app-task-item
          class="box"
          *ngFor="let item of dones$ | async; trackBy: trackBy"
          cdkDrag
          [task]="item"
          [user]="user$ | async"
          [type]="'done'"
          [userSelectorRef]="userSelectorRef"
          (delete)="deleteTask($event)"
          (update)="onUpdate($event)"
        ></app-task-item>
      </div>
    </div>
  </div>

  <ng-template #noData>
    <div class="list">
      <nz-empty></nz-empty>
    </div>
  </ng-template>
</ng-container>

<ng-template #taskFormTitle>
  <app-header-title
    [icon]="'playlist_add_check'"
    [title]="title"
  ></app-header-title>
</ng-template>

<ng-template #taskLoading>
  <app-loading-animation [loading$]="loading$"></app-loading-animation>
</ng-template>
