import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  concatMap,
  exhaustMap,
  map,
  mergeMap,
  tap,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { UserFavorisService } from 'src/app/service/user-favoris.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { UserState } from './user.reducer';
import * as fromUserAction from './user.actions';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { UserService } from './../../../service/user.service';
import { Store } from '@ngrx/store';
import {
  NzNotificationPlacement,
  NzNotificationService,
} from 'ng-zorro-antd/notification';

const NOTIFICATION_PLACEMENT: NzNotificationPlacement = 'topRight';
@Injectable()
export class UserEffects {
  loadMe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserAction.loadMe),
      exhaustMap((action) =>
        this.userService.getMe().pipe(
          map((respons: any) => {
            if (!respons) {
              of(fromUserAction.loadMeFailure({ error: 'Erreur server' }));
              this.notification.error('Erreur', 'Erreur server', {
                nzDuration: 6000,
                nzAnimate: true,
                nzPlacement: NOTIFICATION_PLACEMENT,
                nzKey: 'LOAD_ME',
              });
            }

            const me = respons?.data?.me;
            this.router.navigate(['/user', ``]);
            return fromUserAction.loadMeSuccess({ user: me });
          }),
          catchError((error) => {
            this.notification.error('Erreur', error.message, {
              nzDuration: 6000,
              nzAnimate: true,
              nzPlacement: NOTIFICATION_PLACEMENT,
              nzKey: 'LOAD_ME',
            });
            return of(fromUserAction.loadMeFailure({ error }));
          })
        )
      )
    )
  );

  loadUserAccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromUserAction.loadUserAccess),
        mergeMap((action) => this.userService.userAccess(action.camelCase))
      ),
    { dispatch: false }
  );

  loadTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserAction.loadTeam),
      mergeMap(() =>
        this.userService.getTeam().pipe(
          map((users: any) => {
            return fromUserAction.loadTeamSuccess({ team: users });
          }),
          catchError((error) => {
            this.onNotificationError(error);

            return of(fromUserAction.loadTeamFailure({ error }));
          })
        )
      )
    )
  );

  loadCivilities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserAction.loadCivilities),
      mergeMap(() =>
        this.userService.getCivilities().pipe(
          map((respons: any) => {
            const civilities: string[] = respons.data.referentiel_civilities;
            return fromUserAction.loadCivilitiesSuccess({ civilities });
          }),
          catchError((error) => {
            this.onNotificationError(error);

            return of(fromUserAction.loadCivilitiesFailure({ error }));
          })
        )
      )
    )
  );

  loadUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserAction.loadUsers),
      mergeMap(() =>
        this.userService.getAll().pipe(
          map((users: any) => fromUserAction.loadUsersSuccess({ users })),
          catchError((error) => {
            this.onNotificationError(error);
            return of(fromUserAction.loadUsersFailure({ error }));
          })
        )
      )
    )
  );

  loadUserStatistiques$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserAction.loadUserStatisques),
      mergeMap((action) =>
        this.userService.getStatistiques().pipe(
          map((statistiques) => {
            return fromUserAction.loadUserStatisquesSuccess({ statistiques });
          }),
          catchError((error) => {
            this.onNotificationError(error);
            return of(fromUserAction.loadUserStatisquesFailure({ error }));
          })
        )
      )
    )
  );

  createUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserAction.addUser),
      tap(() => {
        this.notification.info(
          'Nouveau utilisateur',
          `Création du profil utilisateur en cours...`,
          {
            nzDuration: 6000,
            nzAnimate: true,
            nzPlacement: NOTIFICATION_PLACEMENT,
            nzKey: 'CREATE_USER',
          }
        );
      }),
      mergeMap((action) =>
        this.userService.createUser(action.newuser, action.createType).pipe(
          map((response: any) => {
            const user = response?.data?.createUser;
            const { coordonnees } = user;
            const { email } = coordonnees;
            this.notification.success(
              'Nouveau utilisateur',
              `L'utilisateur ${email} a été ajouté avec succès`,
              {
                nzDuration: 6000,
                nzAnimate: true,
                nzPlacement: NOTIFICATION_PLACEMENT,
                nzKey: 'CREATE_USER',
              }
            );

            return fromUserAction.addUserSuccess({ user });
          }),
          catchError((error) => {
            this.notification.error('Erreur', error.message, {
              nzDuration: 6000,
              nzAnimate: true,
              nzPlacement: NOTIFICATION_PLACEMENT,
              nzKey: 'CREATE_USER',
            });
            return of(fromUserAction.addUserFailure({ error }));
          })
        )
      )
    )
  );

  addConfirmationUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserAction.addConfirmationUser),
      mergeMap((action) =>
        this.userService.addConfirmation(action.userItem, action.user).pipe(
          map((success) => {
            this.message.success(success, { nzDuration: 6000 });
            return fromUserAction.addConfirmationUserSuccess({ success });
          }),
          catchError((error) => {
            this.onNotificationError(error);

            return of(fromUserAction.addConfirmationUserFailure({ error }));
          })
        )
      )
    )
  );

  updataeUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserAction.updateUser),
      mergeMap((action) =>
        this.userService
          .updateOne(action.userItem.id, action.userItem.changes)
          .pipe(
            map((success) => {
              return fromUserAction.updateUserSuccess({ success });
            }),
            catchError((error) => {
              this.onNotificationError(error);
              return of(fromUserAction.updateUserFailure({ error }));
            })
          )
      )
    )
  );

  deleteUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserAction.deleteUser),
      mergeMap((action) =>
        this.userService.deleteOne(action.id).pipe(
          map((success) => {
            return fromUserAction.deleteUserSuccess({ success });
          }),
          catchError((error) => {
            this.onNotificationError(error);
            return of(fromUserAction.deleteUserFailure({ error }));
          })
        )
      )
    )
  );

  //FAVORIS ENTITES
  loadFavorisEntites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserAction.loadUserFavorisEntites),
      mergeMap((action) =>
        this.userFavori.getFavorisEntites().pipe(
          map((favoris) => {
            return fromUserAction.loadUserFavorisEntitesSuccess({ favoris });
          }),
          catchError((error) => {
            this.onNotificationError(error);
            return of(fromUserAction.loadUserFavorisEntitesFailure({ error }));
          })
        )
      )
    )
  );
  addFavorisEntite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserAction.addUserFavorisEntites),
      mergeMap((action) =>
        this.userFavori
          .addEntiteToFavoris(action.id, action.nom_raison_sociale)
          .pipe(
            map((favoris) =>
              fromUserAction.addUserFavorisEntitesSuccess({ favoris })
            ),
            catchError((error) => {
              this.onNotificationError(error);
              return of(fromUserAction.addUserFavorisEntitesFailure({ error }));
            })
          )
      )
    )
  );
  removeFavorisEntite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserAction.removeUserFavorisEntites),
      mergeMap((action) =>
        this.userFavori
          .removeEntiteToFavoris(action.id, action.nom_raison_sociale)
          .pipe(
            map((favoris) =>
              fromUserAction.removeUserFavorisEntitesSuccess({
                favoris,
              })
            ),
            catchError((error) => {
              this.onNotificationError(error);
              return of(
                fromUserAction.removeUserFavorisEntitesFailure({ error })
              );
            })
          )
      )
    )
  );

  //FAVORIS CONTACTS
  loadFavorisContacts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserAction.loadUserFavorisContacts),
      mergeMap((action) =>
        this.userFavori.getFavorisContacts().pipe(
          map((favoris) => {
            return fromUserAction.loadUserFavorisContactsSuccess({ favoris });
          }),
          catchError((error) => {
            this.onNotificationError(error);
            return of(fromUserAction.loadUserFavorisContactsFailure({ error }));
          })
        )
      )
    )
  );
  addFavorisContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserAction.addUserFavorisContact),
      mergeMap((action) =>
        this.userFavori.addContactToFavoris(action.id, action.displayName).pipe(
          map((favoris) =>
            fromUserAction.addUserFavorisContactSuccess({ favoris })
          ),
          catchError((error) => {
            this.onNotificationError(error);
            return of(fromUserAction.addUserFavorisContactFailure({ error }));
          })
        )
      )
    )
  );
  removeFavorisContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserAction.removeUserFavorisContact),
      mergeMap((action) =>
        this.userFavori
          .removeContactToFavoris(action.id, action.displayName)
          .pipe(
            map((favoris) =>
              fromUserAction.removeUserFavorisContactSuccess({
                favoris,
              })
            ),
            catchError((error) => {
              this.onNotificationError(error);
              return of(
                fromUserAction.removeUserFavorisContactFailure({ error })
              );
            })
          )
      )
    )
  );

  //FAVORIS PARTICIPANTS
  loadFavorisParticipants$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserAction.loadUserFavorisParticipants),
      mergeMap((action) =>
        this.userFavori.getFavorisParticipants().pipe(
          map((favoris) => {
            return fromUserAction.loadUserFavorisParticipantsSuccess({
              favoris,
            });
          }),
          catchError((error) => {
            this.onNotificationError(error);
            return of(
              fromUserAction.loadUserFavorisParticipantsFailure({ error })
            );
          })
        )
      )
    )
  );
  addFavorisParticipant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserAction.addUserFavorisParticipant),
      mergeMap((action) =>
        this.userFavori
          .addParticipantToFavoris(action.id, action.displayName)
          .pipe(
            map((favoris) =>
              fromUserAction.addUserFavorisParticipantSuccess({
                favoris,
              })
            ),
            catchError((error) => {
              this.onNotificationError(error);
              return of(
                fromUserAction.addUserFavorisParticipantFailure({ error })
              );
            })
          )
      )
    )
  );
  removeFavorisParticipant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserAction.removeUserFavorisParticipant),
      mergeMap((action) =>
        this.userFavori
          .removeParticipantToFavoris(action.id, action.displayName)
          .pipe(
            map((favoris) =>
              fromUserAction.removeUserFavorisParticipantSuccess({
                favoris,
              })
            ),
            catchError((error) => {
              this.onNotificationError(error);
              return of(
                fromUserAction.removeUserFavorisParticipantFailure({ error })
              );
            })
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private message: NzMessageService,
    private userFavori: UserFavorisService,
    private modal: NzModalService,
    private userStore: Store<UserState>,
    private notification: NzNotificationService,
    private router: Router
  ) {}

  warningExistUser(existUser: any): void {
    const { civilite, lastName, firstName, coordonnees } = existUser;
    const { email, mobile, phoneNumber } = coordonnees;
    const displayName: string = `${civilite}. ${lastName} ${firstName}`;
    const modal = this.modal.create({
      nzTitle: 'Utilisateur trouvé',
      nzContent: `<strong>${displayName}</strong> <br> a déjà été ajouté`,

      nzFooter: [
        // {
        //   label: 'Confirmer',
        //   type: 'primary',
        //   onClick: () => {
        //     this.userStore.dispatch(
        //       fromUserAction.addConfirmationUser({
        //         userItem,
        //         user,
        //       })
        //     );
        //     modal.destroy();
        //   },
        // },
        {
          label: 'Annuler',
          type: 'text',
          onClick: () => {
            this.onCancel();
            modal.destroy();
          },
        },
      ],
    });
  }

  onNotificationError(error: any): void {
    this.notification.error('Erreur', error.message, {
      nzDuration: 6000,
      nzAnimate: true,
      nzPlacement: NOTIFICATION_PLACEMENT,
    });
  }

  onCancel(): void {
    return;
  }
}
