import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  Auth,
  multiFactor,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  User,
} from '@angular/fire/auth';

@Component({
  selector: 'app-login-captcha',
  templateUrl: './login-captcha.component.html',
  styleUrls: ['./login-captcha.component.scss'],
})
export class LoginCaptchaComponent implements OnInit, AfterViewInit {
  recaptchaVerifier: RecaptchaVerifier | any;

  @ViewChild('captchaRef', { static: true }) captchaRef: HTMLElement | string =
    '';

  @Output() add = new EventEmitter<any>();

  constructor(private auth: Auth) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {}
  setCaptcha(): void {
    this.captchaRef = this.recaptchaVerifier(this.captchaRef, {
      size: 'invisible',
      callback: (response: any) => console.log('captcha solved!', response),
    });
  }

  setMultifactorAuth(): void {
    const user: User = this.auth?.currentUser as User;
    if (!user) return;
    const session = multiFactor(user).getSession();
    const { phoneNumber } = user;
    const mfaOption = { phoneNumber, session };

    // const verification = this.phoneAuthProvider.verifyPhoneNumber(
    //   mfaOption,
    //   ''
    // );
  }

  onSubmit(): void {}
}
