import { ParticipantSmallId } from './../../participant/store/participant.model';
import { ItemEvent } from 'src/app/features/suivi/components/item-event/itemEvent.model';
import { ItemEventId } from '../../../features/suivi/components/item-event/itemEvent.model';
import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Operation, OperationId, OperationSmallId } from './operation.model';
import { EntiteSMALLid } from '../../entite/store/entite.model';
import { Injectable } from '@angular/core';

export const loading = createAction(
  '[Operation/COMPONENT] Loading Participants',
  props<{ loading: boolean }>()
);
export const setActviteRoute = createAction(
  '[Operation/COMPONENT] Set Active route title Operation',
  props<{ title: string }>()
);

export const loadFileProgress = createAction(
  '[Operation/API] File upload progreess Operation',
  props<{ progress: any }>()
);

export const loadOperations = createAction(
  '[Operation/COMPONENT] Load Operations'
);

export const loadOperationsSuccess = createAction(
  '[Operation/API] Load Operations Success',
  props<{ operations: OperationSmallId[] }>()
);

export const loadOperationsFailure = createAction(
  '[Operation/API] Load Operations Failure',
  props<{ error: any }>()
);

export const loadOperation = createAction(
  '[Operation/COMPONENT] Load Operation',
  props<{ id: string }>()
);

export const loadOperationSuccess = createAction(
  '[Operation/API] Load Operation Success',
  props<{ operation: OperationId }>()
);

export const loadOperationFailure = createAction(
  '[Operation/API] Load Operation Failure',
  props<{ error: any }>()
);

//FILTER
export const loadOperationFilters = createAction(
  '[Operation/COMPONENT] Load Operation Filters'
);

export const loadOperationFiltersSuccess = createAction(
  '[Operation/API] Load Operation Filters Success',
  props<{ filters: any }>()
);

export const loadOperationFiltersFailure = createAction(
  '[Operation/API] Load Operation Filters Failure',
  props<{ error: any }>()
);

//OPERATION MARCHÉS TYPES
export const loadOperationMarcheTypes = createAction(
  '[Operation/COMPONENT] Load Operation Marches types'
);

export const loadOperationMarcheTypesSuccess = createAction(
  '[Operation/API] Load Operation Marches types Success',
  props<{ types: any }>()
);

export const loadOperationMarcheTypesFailure = createAction(
  '[Operation/API] Load Operation Marches types Failure',
  props<{ error: any }>()
);

//QUERY
export const queryOperations = createAction(
  '[Operation/COMPONENT] Query Operation',
  props<{ filter: { event: string; type: string } }>()
);

export const queryOperationsSuccess = createAction(
  '[Operation/API] Query Operation Success',
  props<{ operations: OperationSmallId[] }>()
);

export const queryOperationsFailure = createAction(
  '[Operation/API] Query Operation Failure',
  props<{ error: any }>()
);

export const addOperation = createAction(
  '[Operation/API] Add Operation',
  props<{ operation: Operation }>()
);
export const addOperationSuccess = createAction(
  '[Operation/API] Add Operation Success',
  props<{ success: string }>()
);
export const addOperationFailure = createAction(
  '[Operation/API] Add Operation Failure',
  props<{ error: any }>()
);

//update operartion
export const updateOperation = createAction(
  '[Operation/API] Update Operation',
  props<{ operation: Update<OperationId> }>()
);

export const updateOperationSuccess = createAction(
  '[Operation/API] Update Operation Success',
  props<{ success: string }>()
);

export const updateOperationFailure = createAction(
  '[Operation/API] Update Operation Failure',
  props<{ error: any }>()
);

//delete operation
export const deleteOperation = createAction(
  '[Operation/API] Delete Operation',
  props<{ id: string }>()
);
export const deleteOperationSuccess = createAction(
  '[Operation/API] Delete Operation Success',
  props<{ success: string }>()
);
export const deleteOperationFailure = createAction(
  '[Operation/API] Delete Operation Failure',
  props<{ error: any }>()
);

//delete operations
export const deleteAllOperations = createAction(
  '[Operation/API] Delete All Operation',
  props<{ ids: string }>()
);
export const deleteAllOperationsSuccess = createAction(
  '[Operation/API] Delete All Operation Success',
  props<{ success: string }>()
);
export const deleteAllOperationsFailure = createAction(
  '[Operation/API] Delete All Operation Failure',
  props<{ error: any }>()
);

//EXPORT
//PDF
export const exportPDFOperation = createAction(
  '[Operation/API] Export PDF Operation',
  props<{ operation: OperationId }>()
);
export const exportPDFOperationSuccess = createAction(
  '[Operation/API] Export PDF Operation Success',
  props<{ success: string }>()
);
export const exportPDFOperationFailure = createAction(
  '[Operation/API] Export PDF Operation Failure',
  props<{ error: any }>()
);
//PPT
export const exportPPTOperation = createAction(
  '[Operation/API] Export PPT Operation',
  props<{ operation: OperationId }>()
);
export const exportPPTOperationSuccess = createAction(
  '[Operation/API] Export PPT Operation Success',
  props<{ success: string }>()
);
export const exportPPTOperationFailure = createAction(
  '[Operation/API] Export PPT Operation Failure',
  props<{ error: any }>()
);
//EXCEL
export const exportEXCELOperation = createAction(
  '[Operation/API] Export EXCEL Operation',
  props<{ operation: OperationId }>()
);
export const exportEXCELOperationSuccess = createAction(
  '[Operation/API] Export EXCEL Operation Success',
  props<{ success: string }>()
);
export const exportEXCELOperationFailure = createAction(
  '[Operation/API] Export EXCEL Operation Failure',
  props<{ error: any }>()
);

export const clearOperations = createAction('[Operation/API] Clear Operations');
export const clearOperation = createAction('[Operation/API] Clear Operation');

//SUIVIS
//load suivis
export const loadSuivis = createAction(
  '[Operation/COMPONENT] Load Suivis Operation',
  props<{ operationId: string }>()
);

export const loadSuivisSuccess = createAction(
  '[Operation/API] Load Suivis Operation Success',
  props<{ suivis: ItemEventId[] }>()
);

export const loadSuivisFailure = createAction(
  '[Operation/API] Load Suivis Operation Failure',
  props<{ error: any }>()
);

//add suivi
export const addSuivi = createAction(
  '[Operation/COMPONENT] Add Suivi Operation',
  props<{ suivi: ItemEvent; operationId: string }>()
);

export const addSuiviSuccess = createAction(
  '[Operation/API] Add Suivi Operation Success',
  props<{ suivi: ItemEventId }>()
);

export const addSuiviFailure = createAction(
  '[Operation/API] Add Suivi Operation Failure',
  props<{ error: any }>()
);

//update suivi
export const updateSuivi = createAction(
  '[Operation/COMPONENT] Update Suivi Operation',
  props<{ suivi: Update<ItemEventId>; operationId: string }>()
);

export const updateSuiviSuccess = createAction(
  '[Operation/API] Update Suivi Operation Success',
  props<{ suivi: ItemEventId }>()
);

export const updateSuiviFailure = createAction(
  '[Operation/API] Update Suivi Operation Failure',
  props<{ error: any }>()
);
//delete suivi
export const deleteSuivi = createAction(
  '[Operation/COMPONENT] Delete Suivi Operation',
  props<{ suiviId: string; operationId: string }>()
);

export const deleteSuiviSuccess = createAction(
  '[Operation/API] Delete Suivi Operation Success',
  props<{ success: string }>()
);

export const deleteSuiviFailure = createAction(
  '[Operation/API] Delete Suivi Operation Failure',
  props<{ error: any }>()
);

//DOCUMENTS
//load documents
export const loadDocuments = createAction(
  '[Operation/COMPONENT] Load Documents Operation',
  props<{ operationId: string }>()
);

export const loadDocumentsSuccess = createAction(
  '[Operation/API] Load Documents Operation Success',
  props<{ documents: any }>()
);

export const loadDocumentsFailure = createAction(
  '[Operation/API] Load Documents Operation Failure',
  props<{ error: any }>()
);

//add document
export const addDocuments = createAction(
  '[Operation/COMPONENT] Add Documents Operation',
  props<{ operation: OperationId; documents: any[] }>()
);

export const addDocumentsSuccess = createAction(
  '[Operation/API] Add Documents Operation Success',
  props<{ documents: any[] }>()
);

export const addDocumentsFailure = createAction(
  '[Operation/API] Add Documents Operation Failure',
  props<{ error: any }>()
);

//delete document
export const deleteDocument = createAction(
  '[Operation/COMPONENT] Delete Document Operation',
  props<{ document: any; operationId: string }>()
);

export const deleteDocumentSuccess = createAction(
  '[Operation/API] Delete Document Operation Success',
  props<{ success: string }>()
);

export const deleteDocumentFailure = createAction(
  '[Operation/API] Delete Document Operation Failure',
  props<{ error: any }>()
);

//ENTITES
//load entites
export const loadEntitesOperation = createAction(
  '[Operation/COMPONENT] Load Entites Operation',
  props<{ operationId: string }>()
);

export const loadEntitesOperationSuccess = createAction(
  '[Operation/API] Load Entites Operation Success',
  props<{ entites: any }>()
);

export const loadEntitesOperationFailure = createAction(
  '[Operation/API] Load Entites Operation Failure',
  props<{ error: any }>()
);

//load entites selector
export const loadSelectorEntitesOperation = createAction(
  '[Operation/COMPONENT] Load Seletcor Entites Operation',
  props<{ operationId: string }>()
);

export const loadSelectorEntitesOperationSuccess = createAction(
  '[Operation/API] Load Seletcor Entites Operation Success',
  props<{ entites: any }>()
);

export const loadSelectorEntitesOperationFailure = createAction(
  '[Operation/API] Load Seletcor Entites Operation Failure',
  props<{ error: any }>()
);

//load entite
export const loadEntiteOperation = createAction(
  '[Operation/COMPONENT] Load Entite Operation',
  props<{ operationId: string; entiteId: string }>()
);

export const loadEntiteOperationSuccess = createAction(
  '[Operation/API] Load Entite Operation Success',
  props<{ entite: any }>()
);

export const loadEntiteOperationFailure = createAction(
  '[Operation/API] Load Entite Operation Failure',
  props<{ error: any }>()
);
//add entite
export const addEntiteOperation = createAction(
  '[Operation/COMPONENT] Add Entite Operation',
  props<{ operation: OperationId; entite: EntiteSMALLid; intervention: any }>()
);

export const addEntiteOperationSuccess = createAction(
  '[Operation/API] Add Entite Operation Success',
  props<{ entite: any }>()
);

export const addEntiteOperationFailure = createAction(
  '[Operation/API] Add Entite Operation Failure',
  props<{ error: any }>()
);
//delete entite
export const deleteEntiteOperation = createAction(
  '[Operation/COMPONENT] Delete Entite Operation',
  props<{ operationId: string; entiteId: string }>()
);

export const deleteEntiteOperationSuccess = createAction(
  '[Operation/API] Delete Entite Operation Success',
  props<{ success: string }>()
);

export const deleteEntiteOperationFailure = createAction(
  '[Operation/API] Delete Entite Operation Failure',
  props<{ error: any }>()
);
//CONTACTS
//load contacts
export const loadContactsOperation = createAction(
  '[Operation/COMPONENT] Load Contacts Operation',
  props<{ operationId: string }>()
);

export const loadContactsOperationSuccess = createAction(
  '[Operation/API] Load Contacts Operation Success',
  props<{ contacts: any }>()
);

export const loadContactsOperationFailure = createAction(
  '[Operation/API] Load Contacts Operation Failure',
  props<{ error: any }>()
);

//add contact
export const addContactsOperation = createAction(
  '[Operation/COMPONENT] Add Contact Operation',
  props<{ operation: OperationId; contactsSelected: any }>()
);

export const addContactsOperationSuccess = createAction(
  '[Operation/API] Add Contact Operation Success',
  props<{ success: string }>()
);

export const addContactsOperationFailure = createAction(
  '[Operation/API] Add Contact Operation Failure',
  props<{ error: any }>()
);

//delete contact
export const deleteContactOperation = createAction(
  '[Operation/COMPONENT] Delete Contact Operation',
  props<{ operationId: string; contactId: string }>()
);

export const deleteContactOperationSuccess = createAction(
  '[Operation/API] Delete Contact Operation Success',
  props<{ success: string }>()
);

export const deleteContactOperationFailure = createAction(
  '[Operation/API] Delete Contact Operation Failure',
  props<{ error: any }>()
);

//PARTICIPANTS
//load participants
export const loadParticipantsOperation = createAction(
  '[Operation/COMPONENT] Load Participants Operation',
  props<{ operationId: string }>()
);

export const loadParticipantsOperationSuccess = createAction(
  '[Operation/API] Load Participants Operation Success',
  props<{ participants: any }>()
);

export const loadParticipantsOperationFailure = createAction(
  '[Operation/API] Load Participants Operation Failure',
  props<{ error: any }>()
);
//load participant
export const loadParticipantOperation = createAction(
  '[Operation/COMPONENT] Load Participants Operation',
  props<{ operationId: string; id: string }>()
);

export const loadParticipantOperationSuccess = createAction(
  '[Operation/API] Load Participants Operation Success',
  props<{ participant: any }>()
);

export const loadParticipantOperationFailure = createAction(
  '[Operation/API] Load Participants Operation Failure',
  props<{ error: any }>()
);
//add entite
export const addParticipantOperation = createAction(
  '[Operation/COMPONENT] Add Participant Operation',
  props<{ operation: OperationId; participant: ParticipantSmallId }>()
);

export const addParticipantOperationSuccess = createAction(
  '[Operation/API] Add Participant Operation Success',
  props<{ participant: any }>()
);

export const addParticipantOperationFailure = createAction(
  '[Operation/API] Add Participant Operation Failure',
  props<{ error: any }>()
);

//delete participant
export const deleteParticipantOperation = createAction(
  '[Operation/COMPONENT] Delete Participant Operation',
  props<{ operationId: string; participantId: string }>()
);

export const deleteParticipantOperationSuccess = createAction(
  '[Operation/API] Delete Participant Operation Success',
  props<{ success: string }>()
);

export const deleteParticipantOperationFailure = createAction(
  '[Operation/API] Delete Participant Operation Failure',
  props<{ error: any }>()
);

//MISSIONS
//LOAD MISSIONS
export const loadOperationMissions = createAction(
  '[Operation/COMPONENT] Load Operation Missions',
  props<{ id: any }>()
);

export const loadOperationMissionsSuccess = createAction(
  '[Operation/API] Load Operation Missions Success',
  props<{ missions: any[] }>()
);

export const loadOperationMissionsFailure = createAction(
  '[Operation/API] Load Operation Missions Failure',
  props<{ error: any }>()
);
//LOAD MISSION
export const loadOperationMission = createAction(
  '[Operation/COMPONENT] Load Operation Mission',
  props<{ operationId: string; id: string }>()
);

export const loadOperationMissionSuccess = createAction(
  '[Operation/API] Load Operation Mission Success',
  props<{ mission: any }>()
);

export const loadOperationMissionFailure = createAction(
  '[Operation/API] Load Operation Mission Failure',
  props<{ error: any }>()
);
//ADD MISSION
export const addOperationMission = createAction(
  '[Operation/COMPONENT] Add Operation Mission',
  props<{ mission: any }>()
);

export const addOperationMissionSuccess = createAction(
  '[Operation/API] Add Operation Mission Success',
  props<{ success: string }>()
);

export const addOperationMissionFailure = createAction(
  '[Operation/API] Add Operation Mission Failure',
  props<{ error: any }>()
);

//UPDATE MISSION
export const updateOperationMission = createAction(
  '[Operation/COMPONENT] Update Operation Mission',
  props<{ id: string; mission: Update<any> }>()
);

export const updateOperationMissionSuccess = createAction(
  '[Operation/API] Update Operation Mission Success',
  props<{ success: string }>()
);

export const updateOperationMissionFailure = createAction(
  '[Operation/API] Update Operation Mission Failure',
  props<{ error: any }>()
);

//DELETE MISSION
export const deleteOperationMission = createAction(
  '[Operation/COMPONENT] Delete Operation Mission',
  props<{ operationId: string; id: any }>()
);

export const deleteOperationMissionSuccess = createAction(
  '[Operation/API] Delete Operation Mission Success',
  props<{ success: string }>()
);

export const deleteOperationMissionFailure = createAction(
  '[Operation/API] Delete Operation Mission Failure',
  props<{ error: any }>()
);
//CLEAR MISSION
export const clearOperationMission = createAction(
  '[Operation/COMPONENT] Clear Operation Mission'
);
