import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Todo } from './todo.model';
import * as TodoActions from './todo.actions';
import { TodoId } from 'src/app/models/todo.model';

export const todosFeatureKey = 'todoes';

export interface TodoState extends EntityState<Todo> {
  todo: TodoId | any;
  loading: boolean;
  adding: boolean;
  fileprogress: number;
  error: any;
}

export const adapter: EntityAdapter<Todo> = createEntityAdapter<Todo>();

export const initialState: TodoState = adapter.getInitialState({
  todo: undefined,
  loading: false,
  adding: false,
  fileprogress: 0,
  error: undefined,
});

export const reducer = createReducer(
  initialState,

  on(TodoActions.loadTodosLoading, (state, action) => {
    return {
      ...state,
      loading: action.event,
    };
  }),

  //LOAD TODOS

  on(TodoActions.loadTodosSuccess, (state, action) =>
    adapter.setAll(action.todos, state)
  ),

  on(TodoActions.loadTodosFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //LOAD COMPLETETD TODOS

  on(TodoActions.loadCompletedTodosSuccess, (state, action) =>
    adapter.setAll(action.todos, state)
  ),

  on(TodoActions.loadCompletedTodosFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //LOAD TODO
  on(TodoActions.loadTodo, (state, action) => {
    return {
      ...state,
      todo: action.todo,
    };
  }),

  on(TodoActions.loadTodoFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //ADD
  on(TodoActions.addTodoAdding, (state, action) => {
    return {
      ...state,
      adding: action.event,
    };
  }),

  on(TodoActions.addTodoSuccess, (state, action) => {
    return {
      ...state,
      todo: action.todo,
    };
  }),
  on(TodoActions.addTodoSuccess, (state, action) =>
    adapter.addOne(action.todo, state)
  ),

  on(TodoActions.addTodoFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //COMPLETED
  on(TodoActions.completedTodo, (state, action) =>
    adapter.updateOne(action.todo, state)
  ),
  on(TodoActions.completedTodoFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //UPDATE
  on(TodoActions.updateTodo, (state, action) =>
    adapter.updateOne(action.todo, state)
  ),
  on(TodoActions.updateTodoFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //DELETE
  on(TodoActions.deleteTodo, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(TodoActions.deleteTodoFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //DELETE ALL
  on(TodoActions.deleteTodos, (state, action) => adapter.removeAll(state)),
  on(TodoActions.deleteTodoFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //CLEAR

  on(TodoActions.clearTodo, (state, action) => {
    return {
      ...state,
      todo: undefined,
      error: undefined,
    };
  }),

  on(TodoActions.clearTodo, (state, action) => {
    return {
      ...state,
      todo: undefined,
      error: undefined,
    };
  }),
  on(TodoActions.clearTodos, (state) => adapter.removeAll(state))
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
