import { Injectable } from '@angular/core';
import { gql, Query } from 'apollo-angular';

@Injectable({
  providedIn: 'any',
})
export class QueryNiveauSalaireContratGQLService extends Query<Response> {
  document = gql`
    query ReferentielSalaire {
      referentielSalaire {
        caracteristiques {
          libelleCaracteristique
          codeTypeCaracteristique
        }
      }
    }
  `;
}
