<nz-row nzAlign="middle" nzGutter="8" nzJustify="center">
  <nz-col nzSpan="4">
    <nz-tag nzColor="geekblue"
      >{{ currentEtablissements | size }} / {{ etablissements | size }}</nz-tag
    >
  </nz-col>
  <nz-col nzSpan="8">
    <form nz-form [formGroup]="filterGroup">
      <nz-input-group [nzSuffix]="suffixIconSearch" nzSize="small">
        <input
          type="text"
          nz-input
          placeholder="Commune"
          formControlName="commune"
        />
      </nz-input-group>
    </form>
  </nz-col>
  <nz-col nzSpan="8">
    <form nz-form [formGroup]="filterGroup">
      <nz-input-group [nzSuffix]="suffixIconSearch" nzSize="small">
        <input
          type="text"
          nz-input
          placeholder="Code postal"
          formControlName="code_postal"
        />
      </nz-input-group>
    </form>
  </nz-col>
  <nz-col nzSpan="4">
    <nz-tag
      nzMode="checkable"
      [nzColor]="is_siege ? 'green' : 'default'"
      (nzCheckedChange)="onIsSiege($event)"
    >
      Siège
    </nz-tag>
  </nz-col>
  <ng-template #suffixIconSearch>
    <i nz-icon nzType="search"></i>
  </ng-template>
</nz-row>
<nz-divider></nz-divider>

<cdk-virtual-scroll-viewport itemSize="100" class="viewport">
  <nz-list nzSize="small">
    <nz-list-item
      *ngFor="let item of currentEtablissements; trackBy: trackByFn"
      (click)="$event.stopPropagation(); onSelect(item)"
    >
      <nz-descriptions
        nzSize="small"
        nzBordered
        [nzColumn]="{ xxl: 4, xl: 4, lg: 4, md: 4, sm: 2, xs: 1 }"
      >
        <nz-descriptions-item nzTitle="Siret" nzSpan="3">
          {{ item?.siret }}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Siège" nzSpan="1">
          <nz-tag
            [nzColor]="
              item.etablissement_siege === 'true' ? 'green' : 'default'
            "
            >{{ item.etablissement_siege === "true" ? "OUI" : "NON" }}</nz-tag
          >
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Adresse" nzSpan="4">
          <small>{{ item?.geo_adresse | carLimitation: 120 }}</small>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Code postal" nzSpan="2">
          {{ item?.code_postal }}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Commune" nzSpan="2">
          <small>{{ item?.libelle_commune | carLimitation: 50 }}</small>
        </nz-descriptions-item>
      </nz-descriptions>
    </nz-list-item>
  </nz-list>
</cdk-virtual-scroll-viewport>
