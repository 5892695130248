import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { User } from './user.model';

//ME
export const loadMe = createAction('[User/COMPONENT] Load Me');

export const loadMeSuccess = createAction(
  '[User/API] Load Me success',
  props<{ user: any }>()
);
export const loadMeFailure = createAction(
  '[User/API] Load Me Failure',
  props<{ error: any }>()
);

//ACCESS
export const loadUserAccess = createAction(
  '[User/COMPONENT] Load User Access',
  props<{ camelCase: string }>()
);

//CIVILITIES
export const loadCivilities = createAction(
  '[User/COMPONENT] Load User Civilities'
);
export const loadCivilitiesSuccess = createAction(
  '[User/COMPONENT] Load User Civilities Success',
  props<{ civilities: string[] }>()
);
export const loadCivilitiesFailure = createAction(
  '[User/COMPONENT] Load User Civilities Failure',
  props<{ error: any }>()
);

//TEAM
export const loadTeam = createAction('[User/COMPONENT] Load Organisation Team');

export const loadTeamSuccess = createAction(
  '[User/API] Load Team Organisation success',
  props<{ team: any }>()
);
export const loadTeamFailure = createAction(
  '[User/API] Load Team Organisation Failure',
  props<{ error: any }>()
);

//ALL ORGANISATION USERS
export const loadUsers = createAction('[User/COMPONENT] Load AllUsers');

export const loadUsersSuccess = createAction(
  '[User/API] Load AllUsers success',
  props<{ users: any }>()
);

export const loadUsersFailure = createAction(
  '[User/API] Load AllUsers Failure',
  props<{ error: any }>()
);

//STATISTIQUES
export const loadUserStatisques = createAction(
  '[User/COMPONENT] Load User Statistiques',
  props<{ uid: string }>()
);

export const loadUserStatisquesSuccess = createAction(
  '[User/API] Load User Statistiques Success',
  props<{ statistiques: any }>()
);

export const loadUserStatisquesFailure = createAction(
  '[User/API] Load User Statistiques Failure',
  props<{ error: any }>()
);

//ADD
export const addUser = createAction(
  '[User/COMPONENT] Add User',
  props<{ newuser: any; createType: string }>()
);

export const addUserSuccess = createAction(
  '[User/API] Add User Success',
  props<{ user: any }>()
);

export const addUserFailure = createAction(
  '[User/API] Add User Failure',
  props<{ error: any }>()
);

export const addConfirmationUser = createAction(
  '[User/COMPONENT] Add Confirmation User',
  props<{ userItem: any; user: any }>()
);

export const addConfirmationUserSuccess = createAction(
  '[User/API] Add Confirmation User Success',
  props<{ success: string }>()
);

export const addConfirmationUserFailure = createAction(
  '[User/API] Add Confirmation User Failure',
  props<{ error: any }>()
);

//UPDATE
export const updateUser = createAction(
  '[User/COMPONENT] Update User',
  props<{ userItem: Update<any> }>()
);

export const updateUserSuccess = createAction(
  '[User/API] Update User Success',
  props<{ success: string }>()
);

export const updateUserFailure = createAction(
  '[User/API] Update User Failure',
  props<{ error: any }>()
);

//DELETE
export const deleteUser = createAction(
  '[User/COMPONENT] Delete User',
  props<{ id: string }>()
);

export const deleteUserSuccess = createAction(
  '[User/API] Delete User Success',
  props<{ success: string }>()
);

export const deleteUserFailure = createAction(
  '[User/API] Delete User Failure',
  props<{ error: any }>()
);

//FAVORIS
// Entites
export const loadUserFavorisEntites = createAction(
  '[User/COMPONENT] Load User Favoris Entites'
);

export const loadUserFavorisEntitesSuccess = createAction(
  '[User/API] Load User Favoris Entites Success',
  props<{ favoris: any }>()
);

export const loadUserFavorisEntitesFailure = createAction(
  '[User/API] Load User Favoris Entites Failure',
  props<{ error: any }>()
);
//add entite to favoris
export const addUserFavorisEntites = createAction(
  '[User/COMPONENT] Add User Favoris Entites',
  props<{ id: string; nom_raison_sociale: string }>()
);

export const addUserFavorisEntitesSuccess = createAction(
  '[User/API] Add User Favoris Entites Success',
  props<{ favoris: any }>()
);

export const addUserFavorisEntitesFailure = createAction(
  '[User/API] Add User Favoris Entites Failure',
  props<{ error: any }>()
);
//remove entite to favoris
export const removeUserFavorisEntites = createAction(
  '[User/COMPONENT] Remove User Favoris Entites',
  props<{ id: string; nom_raison_sociale: string }>()
);

export const removeUserFavorisEntitesSuccess = createAction(
  '[User/API] Remove User Favoris Entites Success',
  props<{ favoris: any }>()
);

export const removeUserFavorisEntitesFailure = createAction(
  '[User/API] Remove User Favoris Entites Failure',
  props<{ error: any }>()
);

//Contacts
export const loadUserFavorisContacts = createAction(
  '[User/COMPONENT] Load User Favoris Contacts'
);

export const loadUserFavorisContactsSuccess = createAction(
  '[User/API] Load User Favoris Contacts Success',
  props<{ favoris: any }>()
);

export const loadUserFavorisContactsFailure = createAction(
  '[User/API] Load User Favoris Contacts Failure',
  props<{ error: any }>()
);
//add contact to favoris
export const addUserFavorisContact = createAction(
  '[User/COMPONENT] Add User Favoris Contact',
  props<{ id: string; displayName: string }>()
);

export const addUserFavorisContactSuccess = createAction(
  '[User/API] Add User Favoris Contact Success',
  props<{ favoris: any }>()
);

export const addUserFavorisContactFailure = createAction(
  '[User/API] Add User Favoris Contact Failure',
  props<{ error: any }>()
);
//remove contact to favoris
export const removeUserFavorisContact = createAction(
  '[User/COMPONENT] Remove User Favoris Contact',
  props<{ id: string; displayName: string }>()
);

export const removeUserFavorisContactSuccess = createAction(
  '[User/API] Remove User Favoris Contact Success',
  props<{ favoris: any }>()
);

export const removeUserFavorisContactFailure = createAction(
  '[User/API] Remove User Favoris Contact Failure',
  props<{ error: any }>()
);

//Participants
export const loadUserFavorisParticipants = createAction(
  '[User/COMPONENT] Load User Favoris Participants'
);

export const loadUserFavorisParticipantsSuccess = createAction(
  '[User/API] Load User Favoris Participants Success',
  props<{ favoris: any }>()
);

export const loadUserFavorisParticipantsFailure = createAction(
  '[User/API] Load User Favoris Participants Failure',
  props<{ error: any }>()
);
//add participant to favoris
export const addUserFavorisParticipant = createAction(
  '[User/COMPONENT] Add User Favoris Participant',
  props<{ id: string; displayName: string }>()
);

export const addUserFavorisParticipantSuccess = createAction(
  '[User/API] Add User Favoris Participant Success',
  props<{ favoris: any }>()
);

export const addUserFavorisParticipantFailure = createAction(
  '[User/API] Add User Favoris Participant Failure',
  props<{ error: any }>()
);
//remove participant to favoris
export const removeUserFavorisParticipant = createAction(
  '[User/COMPONENT] Rremove User Favoris Participant',
  props<{ id: string; displayName: string }>()
);

export const removeUserFavorisParticipantSuccess = createAction(
  '[User/API] Rremove User Favoris Participant Success',
  props<{ favoris: any }>()
);

export const removeUserFavorisParticipantFailure = createAction(
  '[User/API] Rremove User Favoris Participant Failure',
  props<{ error: any }>()
);

export const clearUsers = createAction('[User/COMPONENT] Clear Users');
