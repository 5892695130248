import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, catchError, tap, debounceTime } from 'rxjs/operators';
import { of } from 'rxjs';
import * as fromMetierAction from './metier.actions';
import { Store } from '@ngrx/store';
import { MetierState } from './metier.reducer';
import { QueryMetiersService } from '../services/query-metiers.service';

@Injectable()
export class MetierEffects {
  loadThemes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMetierAction.loadThemes),
      mergeMap((action) =>
        this.metiersService.getThemes().pipe(
          map((snap: any) =>
            fromMetierAction.loadThemesSuccess({ themes: snap?.data })
          ),
          catchError((error) => {
            this.metierStore.dispatch(
              fromMetierAction.loadingMetiers({ loading: false })
            );
            return of(fromMetierAction.loadThemesFailure({ error }));
          })
        )
      )
    )
  );

  loadDomaines$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMetierAction.loadDomaines),
      tap(() =>
        this.metierStore.dispatch(
          fromMetierAction.loadingMetiers({ loading: true })
        )
      ),
      mergeMap((action) =>
        this.metiersService.getDomaines().pipe(
          map((snap: any) => {
            this.metierStore.dispatch(
              fromMetierAction.loadingMetiers({ loading: snap?.loading })
            );
            return fromMetierAction.loadDomainesSuccess({
              domaines: snap?.data,
            });
          }),
          catchError((error) => {
            this.metierStore.dispatch(
              fromMetierAction.loadingMetiers({ loading: false })
            );
            return of(fromMetierAction.loadDomainesFailure({ error }));
          })
        )
      )
    )
  );

  loadDomainesProfessionnel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMetierAction.loadDomainesProfessionnels),
      tap(() =>
        this.metierStore.dispatch(
          fromMetierAction.loadingMetiers({ loading: true })
        )
      ),
      mergeMap((action) =>
        this.metiersService.getDomainesProfessionnel().pipe(
          map((snap: any) => {
            this.metierStore.dispatch(
              fromMetierAction.loadingMetiers({ loading: snap?.loading })
            );
            return fromMetierAction.loadDomainesProfessionnelsSuccess({
              domaines: snap?.data,
            });
          }),
          catchError((error) => {
            this.metierStore.dispatch(
              fromMetierAction.loadingMetiers({ loading: false })
            );
            return of(
              fromMetierAction.loadDomainesProfessionnelsFailure({ error })
            );
          })
        )
      )
    )
  );

  loadDomainesProfessionnelByGrandDoamine$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMetierAction.loadDomainesProfessionnelsByGrandDomaine),

      tap(() =>
        this.metierStore.dispatch(
          fromMetierAction.loadingMetiers({ loading: true })
        )
      ),
      mergeMap((action) =>
        this.metiersService
          .getDomainesProfessionnelByGrandDomaine(action.code)
          .pipe(
            map((snap: any) => {
              this.metierStore.dispatch(
                fromMetierAction.loadingMetiers({ loading: snap?.loading })
              );
              return fromMetierAction.loadDomainesProfessionnelsByGrandDomaineSuccess(
                {
                  domaines: snap?.data,
                }
              );
            }),
            catchError((error) => {
              this.metierStore.dispatch(
                fromMetierAction.loadingMetiers({ loading: false })
              );
              return of(
                fromMetierAction.loadDomainesProfessionnelsByGrandDomaineFailure(
                  { error }
                )
              );
            })
          )
      )
    )
  );

  loadMetiersByDomaine$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMetierAction.loadMetiersByDomaine),

      tap(() =>
        this.metierStore.dispatch(
          fromMetierAction.loadingMetiers({ loading: true })
        )
      ),
      mergeMap((action) =>
        this.metiersService.getMetiersByDomainesProfessionnel(action.code).pipe(
          map((snap: any) => {
            this.metierStore.dispatch(
              fromMetierAction.loadingMetiers({ loading: snap?.loading })
            );
            return fromMetierAction.loadMetiersByDomaineSuccess({
              metiers: snap?.data,
            });
          }),
          catchError((error) => {
            this.metierStore.dispatch(
              fromMetierAction.loadingMetiers({ loading: false })
            );
            return of(fromMetierAction.loadMetiersByDomaineFailure({ error }));
          })
        )
      )
    )
  );

  loadAppelationsByMetiers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMetierAction.loadAppelationsByMetier),

      tap(() =>
        this.metierStore.dispatch(
          fromMetierAction.loadingMetiers({ loading: true })
        )
      ),
      mergeMap((action) =>
        this.metiersService.getAppelationByMetier(action.code).pipe(
          map((snap: any) => {
            this.metierStore.dispatch(
              fromMetierAction.loadingMetiers({ loading: snap?.loading })
            );
            return fromMetierAction.loadAppelationsByMetierSuccess({
              appelations: snap?.data,
            });
          }),
          catchError((error) => {
            this.metierStore.dispatch(
              fromMetierAction.loadingMetiers({ loading: false })
            );
            return of(
              fromMetierAction.loadAppelationsByMetierFailure({ error })
            );
          })
        )
      )
    )
  );

  loadAppelationDetailByAppelation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMetierAction.loadAppelationDetailByAppelation),

      tap(() =>
        this.metierStore.dispatch(
          fromMetierAction.loadingMetiers({ loading: true })
        )
      ),
      mergeMap((action) =>
        this.metiersService.getAppelationDetail(action.code).pipe(
          map((snap: any) => {
            this.metierStore.dispatch(
              fromMetierAction.loadingMetiers({ loading: snap?.loading })
            );

            const appelation: any = snap?.data
              ? snap.data.appelationDetail
              : null;
            return fromMetierAction.loadAppelationDetailByAppelationSuccess({
              appelation,
            });
          }),
          catchError((error) => {
            this.metierStore.dispatch(
              fromMetierAction.loadingMetiers({ loading: false })
            );
            return of(
              fromMetierAction.loadAppelationDetailByAppelationFailure({
                error,
              })
            );
          })
        )
      )
    )
  );

  loadMetierDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMetierAction.loadMetierDetail),

      tap(() =>
        this.metierStore.dispatch(
          fromMetierAction.loadingMetiers({ loading: true })
        )
      ),
      mergeMap((action) =>
        this.metiersService.getMetierDetail(action.code).pipe(
          map((snap: any) => {
            this.metierStore.dispatch(
              fromMetierAction.loadingMetiers({ loading: snap?.loading })
            );
            const metier: any = snap?.data ? snap.data.metier : null;

            return fromMetierAction.loadMetierDetailSuccess({ metier });
          }),
          catchError((error) => {
            this.metierStore.dispatch(
              fromMetierAction.loadingMetiers({ loading: false })
            );
            return of(
              fromMetierAction.loadMetierDetailFailure({
                error,
              })
            );
          })
        )
      )
    )
  );

  loadMetierQualifidcations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMetierAction.loadQualifications),

      mergeMap((action) =>
        this.metiersService.getMetierqQualification().pipe(
          map((snap: any) => {
            const qualifications: any =
              snap?.data?.referentielNiveauQualification.caracteristiques;

            return fromMetierAction.loadQualificationsSuccess({
              qualifications,
            });
          }),
          catchError((error) => {
            return of(
              fromMetierAction.loadQualificationsFailure({
                error,
              })
            );
          })
        )
      )
    )
  );

  loadMetierFap$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMetierAction.loadFap),

      tap(() =>
        this.metierStore.dispatch(
          fromMetierAction.loadingMetiers({ loading: true })
        )
      ),
      mergeMap((action) =>
        this.metiersService.getMetierFap(action.code).pipe(
          map((snap: any) => {
            this.metierStore.dispatch(
              fromMetierAction.loadingMetiers({ loading: snap?.loading })
            );
            const fap: any = snap?.data ? snap.data.metierFap : null;

            return fromMetierAction.loadFapSuccess({ fap });
          }),
          catchError((error) => {
            this.metierStore.dispatch(
              fromMetierAction.loadingMetiers({ loading: false })
            );
            return of(
              fromMetierAction.loadFapFailure({
                error,
              })
            );
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private metiersService: QueryMetiersService,
    private metierStore: Store<MetierState>
  ) {}
}
