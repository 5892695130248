<nz-select
  [nzBorderless]="borderless"
  [nzPlaceHolder]="placeholder"
  [nzSize]="size"
  nzShowSearch
  (ngModelChange)="onSelect($event)"
  [ngClass]="{ borderlessClass: borderless }"
  [nzMode]="multi ? 'multiple' : 'default'"
  nzAllowClear
  [(ngModel)]="selected"
  *ngIf="source | size"
>
  <nz-option
    *ngFor="let item of source"
    [nzValue]="item"
    [nzLabel]="variable ? (item[variable] | titlecase) : (item | titlecase)"
  >
    {{ item?.item | titlecase }}
  </nz-option>
</nz-select>
