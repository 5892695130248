<ng-container *ngIf="user$ | async as user">
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col
      [nzXs]="{ span: 1 }"
      [nzMd]="{ span: 1 }"
      [nzLg]="{ span: 1 }"
      [nzXl]="{ span: 1 }"
    ></nz-col>
    <nz-col
      [nzXs]="{ span: 0 }"
      [nzMd]="{ span: 0 }"
      [nzLg]="{ span: 0 }"
      [nzXl]="{ span: 9 }"
    >
      <nz-row nzAlign="top" nzGutter="2" nzJustify="start">
        <nz-col nzSpan="10"
          ><h5 nz-typography>{{ messageTitle }}</h5>
        </nz-col>
        <nz-col nzSpan="8">
          <nz-badge
            nzStandalone
            [nzCount]="messages$ | async | size"
          ></nz-badge>
        </nz-col>
        <nz-col nzSpan="6"></nz-col>
      </nz-row>
    </nz-col>
    <nz-col
      [nzXs]="{ span: 0 }"
      [nzMd]="{ span: 0 }"
      [nzLg]="{ span: 0 }"
      [nzXl]="{ span: 8 }"
    >
    </nz-col>
    <nz-col
      [nzXs]="{ span: 12 }"
      [nzMd]="{ span: 12 }"
      [nzLg]="{ span: 12 }"
      [nzXl]="{ span: 2 }"
    >
      <button
        nz-button
        nzShape="circle"
        (click)="onMessage()"
        nz-tooltip
        [nzTooltipMouseEnterDelay]="1"
        nzTooltipPlacement="bottom"
        nzTooltipTitle="Nouveau message"
        nzTooltipTrigger="hover"
      >
        <i nz-icon nzType="plus"></i>
      </button>
    </nz-col>
    <nz-col
      [nzXs]="{ span: 6 }"
      [nzMd]="{ span: 6 }"
      [nzLg]="{ span: 6 }"
      [nzXl]="{ span: 2 }"
    >
      <button
        nz-button
        nz-dropdown
        nzShape="circle"
        nzTrigger="click"
        [nzDropdownMenu]="menuMoreMessageActions"
      >
        <i nz-icon nzType="more"></i>
      </button>

      <nz-dropdown-menu #menuMoreMessageActions="nzDropdownMenu">
        <ul nz-menu nzSelectable>
          <li
            nz-menu-item
            (click)="$event.preventDefault(); loadAll__MESSAGES()"
          >
            Afficher tous les messages
          </li>
          <li
            nz-menu-item
            (click)="$event.preventDefault(); loadReceive__MESSAGES()"
          >
            Afficher les messages reçus
          </li>
          <li
            nz-menu-item
            (click)="$event.preventDefault(); loadMy__MESSAGES()"
          >
            Afficher les messages envoyés
          </li>
          <li nz-menu-divider></li>
          <ng-container *ngIf="messages$ | async as messages">
            <li
              nz-menu-item
              nzDanger
              nz-popconfirm
              nzPopconfirmTitle="Voulez-vous supprimer tous vos messages envoyés ?"
              nzPopconfirmPlacement="bottom"
              (nzOnConfirm)="deleteAllMy__MESSAGES(user, messages)"
              (nzOnCancel)="onCancel()"
            >
              Supprimer mes messages envoyés
            </li>
            <li
              nz-menu-item
              nzDanger
              nz-popconfirm
              nzPopconfirmTitle="Voulez-vous supprimer tous vos messages reçus ?"
              nzPopconfirmPlacement="bottom"
              (nzOnConfirm)="deleteAllReceive__MESSAGES(user, messages)"
              (nzOnCancel)="onCancel()"
            >
              Supprimer les messages reçus
            </li>
            <li nz-menu-divider></li>

            <li
              nz-menu-item
              nzDanger
              nz-popconfirm
              nzPopconfirmTitle="Voulez-vous supprimer tous vos messages ?"
              nzPopconfirmPlacement="bottom"
              (nzOnConfirm)="deleteAll__MESSAGES(messages)"
              (nzOnCancel)="onCancel()"
              [nzDisabled]="!messages.length"
            >
              Supprimer toutes les messages
            </li>
          </ng-container>
        </ul>
      </nz-dropdown-menu>
    </nz-col>
    <nz-col
      [nzXs]="{ span: 6 }"
      [nzMd]="{ span: 6 }"
      [nzLg]="{ span: 6 }"
      [nzXl]="{ span: 2 }"
    >
    </nz-col>
  </nz-row>
</ng-container>
