<nz-card
  nzHoverable
  [nzTitle]="header"
  [nzExtra]="projetProCardExtra"
  nzSize="small"
>
  <div class="projetPro_card_title">
    <h5 nz-typography>
      {{ item?.appellation?.libelleCourt | carLimitation: 40 }}
    </h5>
  </div>

  <nz-row nzAlign="middle" nzGutter="2" nzJustify="center">
    <nz-col nzSpan="24">
      <app-charts [source]="chartType"></app-charts>
    </nz-col>
  </nz-row>
  <!-- <nz-row
    nzAlign="middle"
    nzGutter="8"
    nzJustify="start"
    class="tagEmployability__container"
  >
    <nz-col nzSpan="24">
      <nz-tag class="tagEmployability">
        <p nz-typography>{{ employablity }}</p>
        <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
        <h3 nz-typography>{{ employablityValue | percent }}</h3>
      </nz-tag>
    </nz-col>
  </nz-row> -->
</nz-card>

<ng-template #header>
  <app-header-title [icon]="'business_center'"></app-header-title>
</ng-template>

<ng-template #projetProCardExtra>
  <button
    nz-button
    nzShape="circle"
    nz-dropdown
    nzTrigger="click"
    nzPlacement="bottomRight"
    [nzDropdownMenu]="projetproCardExta"
    (click)="$event.stopPropagation()"
    nzType="text"
  >
    <mat-icon class="primaryBlue">more_vert</mat-icon>
  </button>
</ng-template>

<nz-dropdown-menu #projetproCardExta="nzDropdownMenu">
  <ul nz-menu>
    <li
      nz-menu-item
      nz-popconfirm
      nzPopconfirmTitle="Voulez-vous supprimer ce projet professionnel ?"
      nzPopconfirmPlacement="bottom"
      (nzOnConfirm)="onDelete(item)"
      (nzOnCancel)="onCancel()"
      nzDanger
    >
      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col nzSpan="4"> <mat-icon class="delete">delete</mat-icon></nz-col>
        <nz-col nzSpan="4">
          <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
        </nz-col>
        <nz-col nzSpan="16"><span>Supprimer</span></nz-col>
      </nz-row>
    </li>
  </ul>
</nz-dropdown-menu>
