import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'operationType',
})
export class OperationTypePipe implements PipeTransform {
  transform(type: string, item: string): string {
    if (!type) return '';

    if (item === 'module') {
      return this.moduleType(type);
    }

    if (item === 'action') {
      return this.actionType(type);
    }

    if (item === 'entite') {
      return this.entiteType(type);
    }

    if (item === 'contact') {
      return this.contactType(type);
    }

    if (item === 'participant') {
      return this.participantType(type);
    }

    return 'Modules';
  }

  moduleType(type: string): string {
    switch (type) {
      case 'Clauses sociales':
        return 'Marchés';
        break;
      case 'Parrainages':
        return 'Parrainages';
        break;
      default:
        return 'Ateliers';
        break;
    }
  }

  actionType(type: string): string {
    switch (type) {
      case 'Clauses sociales':
        return "Corps d'état";
        break;
      case 'Parrainage':
        return 'Entretiens';
        break;
      default:
        return 'Sessions';
        break;
    }
  }

  entiteType(type: string): string {
    switch (type) {
      case 'Clauses sociales':
        return 'Entreprises';
        break;
      case 'Parrainage':
        return 'Entreprises';
        break;
      default:
        return 'Entités';
        break;
    }
  }

  contactType(type: string): string {
    switch (type) {
      case 'Clauses sociales':
        return 'Contacts';
        break;
      case 'Parrainage':
        return 'Parrains/Marraines';
        break;
      default:
        return 'Contacts';
        break;
    }
  }

  participantType(type: string): string {
    switch (type) {
      case 'Clauses sociales':
        return 'Participants';
        break;
      case 'Parrainage':
        return 'Filleuls/ Filleules';
        break;
      default:
        return 'Bénéficiaires';
        break;
    }
  }
}
