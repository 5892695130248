import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Store } from '@ngrx/store';
import { catchError, EMPTY, lastValueFrom, map, Observable, of } from 'rxjs';
import { OperationFicheDePosteState } from '../store/operation-fiche-de-poste.reducer';
import * as fromOperationFicheDePosteSelector from '../store/operation-fiche-de-poste.selectors';
import * as fromOperationFicheDePosteAction from '../store/operation-fiche-de-poste.actions';

@Injectable({
  providedIn: 'any',
})
export class OperationFichesDePosteResolverService implements Resolve<any> {
  constructor(
    private operationFicheDePosteStore: Store<OperationFicheDePosteState>,
    private router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> | any {
    let id = route.parent?.params['id'];

    return this.loadFiches(id).pipe(
      map(() => this.getFiches()),
      catchError(() => {
        this.router.navigate(['/projet', id, 'fichesDePoste']);
        return EMPTY;
      })
    );
  }

  getFiches(): Observable<any> {
    return this.operationFicheDePosteStore.select(
      fromOperationFicheDePosteSelector.fiches
    );
  }

  loadFiches(id: string): Observable<any> {
    return of(
      this.operationFicheDePosteStore.dispatch(
        fromOperationFicheDePosteAction.loadOperationFichesDePoste({ id })
      )
    );
  }
}
