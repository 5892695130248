import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParticipantDashboardComponent } from './components/participant-dashboard/participant-dashboard.component';
import { ContentsModule } from 'src/app/contents/contents.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { HeaderModule } from 'src/app/contents/components/header-title/header.module';

@NgModule({
  declarations: [ParticipantDashboardComponent],
  imports: [CommonModule, ContentsModule, SharedModule, HeaderModule],
  exports: [ParticipantDashboardComponent],
})
export class ParticipantDashboardModule {}
