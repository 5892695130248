import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { ParticipantDiagnosticState } from '../../store/participant-diagnostic.reducer';
import * as fromParticipantDiagnosticSelector from '../../store/participant-diagnostic.selectors';

@Component({
  selector: 'app-participant-diagnostic-item-professionnel',
  templateUrl: './participant-diagnostic-item-professionnel.component.html',
  styleUrls: ['./participant-diagnostic-item-professionnel.component.scss'],
})
export class ParticipantDiagnosticItemProfessionnelComponent implements OnInit {
  diagnostic$: Observable<any> = of(null);

  constructor(
    private participantDiagnosticStore: Store<ParticipantDiagnosticState>
  ) {}

  ngOnInit(): void {
    this.getParticipantDiagnostic();
  }
  getParticipantDiagnostic(): void {
    this.diagnostic$ = this.participantDiagnosticStore.select(
      fromParticipantDiagnosticSelector.diagnostic
    );
  }
}
