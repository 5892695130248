import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { ParticipantDocument } from './participant-document.model';

//LOAD DOCUMENTS
export const loadParticipantDocuments = createAction(
  '[ParticipantDocument/API] Load ParticipantDocuments',
  props<{ id: string }>()
);
export const loadParticipantDocumentsSuccess = createAction(
  '[ParticipantDocument/API] Load ParticipantDocuments Success',
  props<{ documents: any[] }>()
);
export const loadParticipantDocumentsFailure = createAction(
  '[ParticipantDocument/API] Load ParticipantDocuments Failure',
  props<{ error: any }>()
);

//LOAD DOCUMENT
export const loadParticipantDocument = createAction(
  '[ParticipantDocument/API] Load ParticipantDocument',
  props<{ participantId: string; id: string }>()
);
export const loadParticipantDocumentSuccess = createAction(
  '[ParticipantDocument/API] Load ParticipantDocument Success',
  props<{ document: any }>()
);
export const loadParticipantDocumentFailure = createAction(
  '[ParticipantDocument/API] Load ParticipantDocument Failure',
  props<{ error: any }>()
);

//UPLOAD FILE
export const uploadParticipantDocuments = createAction(
  '[ParticipantDocument/API] upload ParticipantDocuments',
  props<{ organisationId: string; id: string; documents: any[] }>()
);
export const uploadParticipantDocumentsSuccess = createAction(
  '[ParticipantDocument/API] upload ParticipantDocuments Success',
  props<{ success: string }>()
);
export const uploadParticipantDocumentsFailure = createAction(
  '[ParticipantDocument/API] upload ParticipantDocuments Failure',
  props<{ error: any }>()
);

//ADD
export const addParticipantDocuments = createAction(
  '[ParticipantDocument/API] Add ParticipantDocuments',
  props<{ id: string; document: any }>()
);
export const addParticipantDocumentsSuccess = createAction(
  '[ParticipantDocument/API] Add ParticipantDocuments Success',
  props<{ document: any }>()
);
export const addParticipantDocumentsFailure = createAction(
  '[ParticipantDocument/API] Add ParticipantDocuments Failure',
  props<{ error: any }>()
);

//UPDATE

export const updateParticipantDocument = createAction(
  '[ParticipantDocument/API] Update ParticipantDocument',
  props<{ id: string; document: Update<ParticipantDocument> }>()
);
export const updateParticipantDocumentSuccess = createAction(
  '[ParticipantDocument/API] Update ParticipantDocument Success',
  props<{ success: string }>()
);
export const updateParticipantDocumentFailure = createAction(
  '[ParticipantDocument/API] Update ParticipantDocument Failure',
  props<{ error: any }>()
);

//DELETE
export const deleteParticipantDocument = createAction(
  '[ParticipantDocument/API] Delete ParticipantDocument',
  props<{ participantId: string; id: string }>()
);
export const deleteParticipantDocumentSuccess = createAction(
  '[ParticipantDocument/API] Delete ParticipantDocument Success',
  props<{ success: string }>()
);
export const deleteParticipantDocumentFailure = createAction(
  '[ParticipantDocument/API] Delete ParticipantDocument Failure',
  props<{ error: any }>()
);

//DELETE ALL
export const deleteParticipantDocuments = createAction(
  '[ParticipantDocument/API] Delete ParticipantDocuments',
  props<{ id: string; ids: string[] }>()
);
export const deleteParticipantDocumentsSuccess = createAction(
  '[ParticipantDocument/API] Delete ParticipantDocuments Sucess',
  props<{ success: string }>()
);
export const deleteParticipantDocumentsFailure = createAction(
  '[ParticipantDocument/API] Delete ParticipantDocuments Failure',
  props<{ error: any }>()
);

//CLEAR
export const clearParticipantDocuments = createAction(
  '[ParticipantDocument/API] Clear ParticipantDocuments'
);
