import { ContentsModule } from './../../contents/contents.module';
import { TicketsService } from './../../service/tickets.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TicketComponent } from './components/ticket/ticket.component';
import { StoreModule } from '@ngrx/store';
import * as fromTicket from './store/ticket.reducer';
import { EffectsModule } from '@ngrx/effects';
import { TicketEffects } from './store/ticket.effects';
import { TicketsComponent } from './components/tickets/tickets.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { TextEdithingModule } from 'src/app/features/text-edithing/text-edithing.module';

@NgModule({
  declarations: [TicketComponent, TicketsComponent],
  imports: [
    CommonModule,
    SharedModule,
    ContentsModule,
    TextEdithingModule,
    StoreModule.forFeature(fromTicket.ticketsFeatureKey, fromTicket.reducer),
    EffectsModule.forFeature([TicketEffects]),
  ],
  exports: [TicketComponent, TicketsComponent],
  providers: [TicketsService, NzMessageService],
})
export class TicketModule {}
