<nz-card
  [nzTitle]="cardTitle"
  nzSize="small"
  [nzExtra]="cardExtra"
  *ngIf="source$ | async | size; else noData"
>
  <nz-table
    #filterTable
    [nzData]="source$ | async"
    nzTableLayout="fixed"
    nzSize="small"
    [nzScroll]="scroll"
    [nzLoadingDelay]="2"
    [nzBordered]="false"
    [nzOuterBordered]="false"
  >
    <thead>
      <tr>
        <th nzLeft></th>
        <th nzLeft [nzFilters]="filterByStatus" [nzFilterFn]="filterByStatusFn">
          État
        </th>
        <th [nzSortFn]="sortFnByMetier">Métier</th>
        <th *ngIf="isEntite" [nzSortFn]="sortFnByEntite">Entité</th>
        <th *ngIf="isParticipant" [nzSortFn]="sortFnByParticipant">
          Participant
        </th>
        <th>Échéance</th>
        <th [nzFilters]="filterByContrats" [nzFilterFn]="filterByContratsFn">
          Contrat
        </th>
        <th nzRight [nzSortFn]="sortFnByTotal">Heures</th>
        <th nzRight></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let data of filterTable.data">
        <tr
          *ngFor="let data of filterTable.data"
          (click)="$event.stopPropagation(); onSelect(data)"
        >
          <td
            nzLeft
            [nzExpand]="expandSet.has(data.id)"
            (nzExpandChange)="onExpandChange(data.id, $event)"
          ></td>
          <td nzLeft>
            <nz-tag [nzColor]="data?.isFinished ? 'success' : 'processing'">
              <mat-icon
                nz-tooltip
                nzTooltipPlacement="bottom"
                [nzTooltipTitle]="data?.isFinished ? 'Terminée' : 'En cours'"
              >
                {{ data?.isFinished ? "check_circle" : "timer" }}
              </mat-icon>
            </nz-tag>
          </td>
          <td>
            {{ data?.metier?.metier?.libelle | carLimitation: 16 }}
          </td>
          <td *ngIf="isEntite">
            {{ data?.entite?.etablissement?.denomination | carLimitation: 20 }}
          </td>
          <td *ngIf="isParticipant">
            {{ data?.participant?.displayName | carLimitation: 20 }}
          </td>
          <td>
            {{
              data?.isFinished
                ? (data?.endMission | dateFr: "small")
                : (data?.endMission | dateFr: "distanceSuffix")
            }}
          </td>
          <td>{{ data?.type_contrat?.CODE | carLimitation: 10 }}</td>

          <td nzRight>
            <strong>{{ data?.total | number: "1.1-2":"fr" }}</strong>
          </td>

          <td nzRight>
            <app-button-dropdown
              [direction]="'horizontal'"
              [isDelete]="true"
              (select)="onSelectDropdown($event, data)"
            ></app-button-dropdown>
          </td>
        </tr>
        <tr [nzExpand]="expandSet.has(data.id)">
          <nz-descriptions
            nzSize="small"
            nzBordered
            [nzColumn]="{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }"
          >
            <nz-descriptions-item nzSpan="3" nzTitle="Métier">
              <strong>{{
                data?.metier?.metier?.libelle | carLimitation: 80
              }}</strong>
            </nz-descriptions-item>
            <nz-descriptions-item nzSpan="3" nzTitle="Action">
              {{ data?.action?.title | uppercase }}
            </nz-descriptions-item>
            <nz-descriptions-item nzSpan="3" nzTitle="Entité">
              {{
                data?.entite?.etablissement?.denomination | carLimitation: 80
              }}
            </nz-descriptions-item>
            <nz-descriptions-item nzSpan="3" nzTitle="Employeur">
              {{
                data?.employeur?.etablissement?.denomination | carLimitation: 80
              }}
            </nz-descriptions-item>
            <nz-descriptions-item nzSpan="3" nzTitle="Participant">
              {{ data?.participant?.displayName | carLimitation: 80 }}
            </nz-descriptions-item>
            <nz-descriptions-item nzSpan="1" nzTitle="Démarrage">
              {{ data?.startMission | dateFr: "small" }}
            </nz-descriptions-item>
            <nz-descriptions-item nzSpan="1" nzTitle="Échéance">
              {{ data?.endMission | dateFr: "small" }}
            </nz-descriptions-item>
            <nz-descriptions-item nzSpan="1" nzTitle="Mise à jour">
              {{ data?.dateUpdate | dateFr: "small" }}
            </nz-descriptions-item>
            <nz-descriptions-item nzSpan="1" nzTitle="Heures de travail">
              <strong>{{ data?.totalWork | number: "1.1-2":"fr" }}</strong>
            </nz-descriptions-item>
            <nz-descriptions-item nzSpan="1" nzTitle="Heure de formation">
              <strong>{{ data?.totalTraining | number: "1.1-2":"fr" }}</strong>
            </nz-descriptions-item>
            <nz-descriptions-item nzSpan="1" nzTitle="Référent">
              <app-avatar-group [source]="[data?.user]"></app-avatar-group>
            </nz-descriptions-item>
          </nz-descriptions>
        </tr>
      </ng-container>
    </tbody>
  </nz-table>
</nz-card>

<ng-template #cardTitle>
  <app-header-title [icon]="'table_chart'"></app-header-title>
</ng-template>
<ng-template #cardExtra>
  <nz-row nzAlign="middle" nzJustify="end">
    <nz-col nzSpan="16">
      <button
        nz-button
        nz-tooltip
        nzBlock
        nzTooltipPlacement="bottom"
        nzTooltipTitle="Ajouter une mission"
        [nzTooltipMouseEnterDelay]="0.5"
        class="btn_add"
        (click)="onNew()"
      >
        <mat-icon>add</mat-icon>
        <span>Ajouter</span>
      </button>
    </nz-col>
    <nz-col nzSpan="8">
      <app-button-dropdown [isDeleteAll]="true"></app-button-dropdown>
    </nz-col>
  </nz-row>
</ng-template>
<ng-template #noData>
  <app-no-result
    [icon]="'work_history'"
    [addtitle]="'Ajouter une mission'"
    [description]="''"
    [notitle]="'Aucune mission ajoutée'"
    (new)="onNew()"
  ></app-no-result>
</ng-template>
