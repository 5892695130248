import { ExportSelectComponent } from './../../../../contents/components/export-select/export-select.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ContactFormComponent } from './../contact-form/contact-form.component';
import { ContactItemHeaderComponent } from './../contact-item-header/contact-item-header.component';
import { ComponentPortal } from '@angular/cdk/portal';
import { PortalNavigationService } from './../../../../portals/portal-navigation.service';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
  TemplateRef,
} from '@angular/core';
import { EMPTY, Observable, of, Subject } from 'rxjs';
import { ContactId } from '../../store/contact.model';
import { Store } from '@ngrx/store';
import { ContactState } from '../../store/contact.reducer';
import * as fromContactAction from '../../store/contact.actions';
import * as fromContactSelector from '../../store/contact.selectors';
import * as sideNavigationSelector from 'src/app/components/side-navigation/store/side-navigation.selectors';
import * as sideNavigationAction from 'src/app/components/side-navigation/store/side-navigation.actions';
import { SideNavigationState } from 'src/app/components/side-navigation/store/side-navigation.reducer';

@Component({
  selector: 'app-contact-item',
  templateUrl: './contact-item.component.html',
  styleUrls: ['./contact-item.component.scss'],
})
export class ContactItemComponent implements OnInit, OnDestroy {
  subscribe = new Subject();
  routeData$: Observable<any> = of(null);
  contact$: Observable<ContactId | any> = of(null);
  statistiques$: Observable<any> = of(null);
  bodyComponent: string = 'details';
  title: string = 'Nouveau';
  modalWidth: number = 950;
  modalExportWidth: number = 480;

  @ViewChild('contactFormTitle', { static: false }) contactFormTitle:
    | TemplateRef<any>
    | any;

  componentPortalFilter: ComponentPortal<ContactItemHeaderComponent> | any;

  constructor(
    private activieRoute: ActivatedRoute,
    private router: Router,
    private contactStore: Store<ContactState>,
    private portalNavigationService: PortalNavigationService,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private sideNavigationStore: Store<SideNavigationState>
  ) {}

  ngOnInit(): void {
    this.getRouteData();
    this.getContact();
    this.getStatistiques();
    this.componentPortalFilter = new ComponentPortal(
      ContactItemHeaderComponent
    );
    this.setFilterPortal();
    this.onSetNavigation('contact');
    this.onNavigation();
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
    this.componentPortalFilter.detach();
  }

  getRouteData(): void {
    this.routeData$ = this.activieRoute.data;
  }

  getContact(): void {
    this.routeData$
      .pipe(takeUntil(this.subscribe))
      .subscribe((data: any): any => {
        if (!data?.contact) {
          this.router.navigate(['/contacts']);
          return EMPTY;
        } else {
          this.contact$ = this.contactStore.select(fromContactSelector.contact);
        }
      });
  }

  onSetNavigation(navigation: string): void {
    this.sideNavigationStore.dispatch(
      sideNavigationAction.selectSideNavigation({
        navigation: { type: 'side', name: navigation },
      })
    );
  }
  onNavigation(): void {
    this.sideNavigationStore
      .select(sideNavigationSelector.navigation)
      .pipe(takeUntil(this.subscribe))
      .subscribe((navigation) => {
        switch (navigation?.name) {
          case 'contact_details':
            this.bodyComponent = 'details';

            break;
          case 'contact_entites':
            this.bodyComponent = 'entites';

            break;
          case 'contact_suivis':
            this.bodyComponent = 'suivis';

            break;
          case 'contact_documents':
            this.bodyComponent = 'documents';
            break;
          case 'contact_metiers':
            this.bodyComponent = 'metiers';

            break;
          case 'contact_projets':
            this.bodyComponent = 'projets';

            break;

          default:
            this.bodyComponent = 'details';

            break;
        }
      });
  }

  loadStatistiques(): void {
    this.contactStore
      .select(fromContactSelector.contact)
      .pipe(takeUntil(this.subscribe))
      .subscribe((contact: ContactId): any => {
        if (!contact) {
          this.router.navigate(['/contacts']);
          return EMPTY;
        } else {
          this.contactStore.dispatch(
            fromContactAction.loadContactStatistiques({ id: contact.id })
          );
        }
      });
  }

  getStatistiques(): void {
    this.statistiques$ = this.contactStore.select(
      fromContactSelector.statistiques
    );
  }

  setFilterPortal(): void {
    this.portalNavigationService.setPortal(this.componentPortalFilter);
  }
  onSelectActions(item: string, contact: ContactId): void {
    switch (item) {
      case 'export':
        this.onExport(contact);
        break;
      case 'update':
        this.onUpdateContactForm(contact);
        break;
      case 'delete':
        this.onDelete(contact);
        break;

      default:
        this.bodyComponent = item;
        break;
    }
  }

  onUpdateContactForm(contact: ContactId): void {
    const { civilite, lastName, firstName } = contact;
    const displayName: string = `${civilite}. ${lastName} ${firstName}`;
    this.title = `Modification : ${displayName}`;
    const modal = this.modal.create({
      nzContent: ContactFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.contactFormTitle,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
    });

    const instance = modal.getContentComponent();
    instance.contact$ = of(contact);
    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
    instance.add.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        this.onAdd(event);
        modal.close();
      }
    });
    instance.update.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        this.onUpdate(event);
        modal.close();
      }
    });
  }

  onExport(contact: ContactId): void {
    if (!contact) return;
    const modal = this.modal.create({
      nzContent: ExportSelectComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalExportWidth,
      nzFooter: [],
      nzTitle: `Exporter la fiche contact`,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
    });

    const instance = modal.getContentComponent();

    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
    instance.select.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        this.onExportValidation(event, contact);
        modal.close();
      }
    });
  }

  onAdd(contact: ContactId): void {
    this.contactStore.dispatch(fromContactAction.addContact({ contact }));
  }

  onUpdate(contact: any): void {
    if (!contact) return;
    this.contactStore.dispatch(fromContactAction.updateContact({ contact }));
  }

  onExportValidation(type: string, contact: ContactId): void {}

  onDelete(contact: ContactId | any): void {
    this.contactStore.dispatch(
      fromContactAction.deleteContact({ id: contact.id })
    );
  }
}
