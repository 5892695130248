<ng-container *ngIf="participant$ | async as participant">
  <div [ngSwitch]="view">
    <div *ngSwitchCase="'list'">
      <nz-page-header
        class="page-header"
        [nzTitle]="header"
        [nzSubtitle]="cardExtra"
        *ngIf="orientations$ | async | size"
      >
        <nz-page-header-extra> </nz-page-header-extra>
        <nz-page-header-content> </nz-page-header-content>
      </nz-page-header>
      <nz-divider
        nzOrientation="center"
        nzType="horizontal"
        *ngIf="orientations$ | async | size"
      ></nz-divider>

      <ng-template #header>
        <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
          <nz-col nzSpan="2"> <mat-icon>route</mat-icon> </nz-col>
          <nz-col nzSpan="1"></nz-col>
          <nz-col nzSpan="21">
            <h4 nz-typography>{{ titlePage }}</h4>
          </nz-col>
        </nz-row>
      </ng-template>
      <ng-template #cardExtra>
        <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
          <nz-col nzSpan="24">
            <nz-badge
              [nzCount]="orientations$ | async | size"
              [nzOverflowCount]="10"
            >
            </nz-badge>
          </nz-col>
        </nz-row>
      </ng-template>

      <app-layout-list
        *ngIf="orientations$ | async | size; else noResults"
        [new]="new"
        [card]="card"
        [source]="orientations$ | async"
      ></app-layout-list>
    </div>
    <div *ngSwitchCase="'details'">
      <app-participant-orientations-item></app-participant-orientations-item>
    </div>
    <div *ngSwitchDefault>
      <app-layout-list
        *ngIf="orientations$ | async | size; else noResults"
        [new]="new"
        [card]="card"
        [source]="orientations$ | async"
      ></app-layout-list>
    </div>
  </div>

  <ng-template #new>
    <app-card-new
      [type]="'route'"
      [title]="newCardTitle"
      [description]="newCardDescription"
      [definition]="newCardDefinition"
      (add)="onNew(participant)"
      (back)="onBack()"
      (cancel)="onBack()"
    ></app-card-new>
  </ng-template>

  <ng-template #card let-item let-position="idx">
    <nz-card nzHoverable> </nz-card>
  </ng-template>

  <ng-template #noResults>
    <app-no-result
      [icon]="'route'"
      [addtitle]="newCardDescription"
      [notitle]="newCardNone"
      [description]="newCardDefinition"
      (new)="onNew(participant)"
    ></app-no-result>
  </ng-template>

  <ng-template #orientationTitleForm>
    <app-header-title
      [icon]="'route'"
      [title]="newCardTitle"
    ></app-header-title>
  </ng-template>
</ng-container>
