import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { AidState } from '../../store/aids.reducer';
import * as fromAidsAction from '../../store/aids.actions';
import * as fromAidsSelector from '../../store/aids.selectors';
import { of, Observable } from 'rxjs';

@Component({
  selector: 'app-aids-eligibles',
  templateUrl: './aids-eligibles.component.html',
  styleUrls: ['./aids-eligibles.component.scss'],
})
export class AidsEligiblesComponent implements OnInit {
  aids$: Observable<any> = of(null);
  loading$: Observable<any> = of(null);
  loadingAids$: Observable<boolean> = of(false);

  isFilter: boolean = true;
  selectedCurrent: any = null;
  conditionView: string = 'list';
  selectedAids: any[] = [];
  notitle: string = 'Aucune allocation trouvée';

  @Output() aidsValid = new EventEmitter<any>(false);

  @Output() cancel = new EventEmitter<boolean>(false);
  constructor(private aidStore: Store<AidState>) {}

  ngOnInit(): void {
    this.getLoading();
    this.getAids();
  }

  getLoading(): void {
    this.loading$ = this.aidStore.select(fromAidsSelector.loading);
    this.loadingAids$ = this.aidStore.select(fromAidsSelector.loadingAids);
  }

  getAids(): void {
    this.aids$ = this.aidStore.select(fromAidsSelector.aids);
  }

  onChangeFilters(filters: any): void {
    if (!filters) return;

    this.aidStore.dispatch(fromAidsAction.loadAids({ filters: filters }));
  }

  onSelect(aid: any): void {
    this.selectedCurrent = aid.details.aid;
    this.conditionView = 'details';
  }

  onBack(): void {
    this.conditionView = 'list';
  }

  onValidationAid(aid: any): void {
    this.selectedAids.push(aid);
    this.onBack();
  }

  onRemoveAid(aid: any): void {
    this.selectedAids = this.selectedAids.filter((a) => a.name === aid.name);
  }

  onValidationAll(aids: any): void {
    if (!aids?.length) return;
    this.aidsValid.emit(aids);
  }

  onReset(): void {
    this.isFilter = true;
    this.selectedCurrent = null;
    this.selectedAids = [];
  }
}
