import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  selectAll,
  UtilitiesState,
  utilitiesesFeatureKey,
} from './utilities.reducer';

export const selectUtilitiesState = createFeatureSelector<UtilitiesState>(
  utilitiesesFeatureKey
);

export const utilities = createSelector(selectUtilitiesState, selectAll);

export const utility = createSelector(
  selectUtilitiesState,
  (state: UtilitiesState) => state.utility
);
export const loading = createSelector(
  selectUtilitiesState,
  (state: UtilitiesState) => state.loading
);

export const civilities = createSelector(
  selectUtilitiesState,
  (state: UtilitiesState) => state.civilities
);
export const communications = createSelector(
  selectUtilitiesState,
  (state: UtilitiesState) => state.communications
);

export const numerique = createSelector(
  selectUtilitiesState,
  (state: UtilitiesState) => state.numerique
);
export const criteres = createSelector(
  selectUtilitiesState,
  (state: UtilitiesState) => state.criteres
);

export const countries = createSelector(
  selectUtilitiesState,
  (state: UtilitiesState) => state.countries
);

export const mobility = createSelector(
  selectUtilitiesState,
  (state: UtilitiesState) => state.mobility
);
export const sejours = createSelector(
  selectUtilitiesState,
  (state: UtilitiesState) => state.sejours
);
export const frenchLevel = createSelector(
  selectUtilitiesState,
  (state: UtilitiesState) => state.frenchLevel
);
export const diploma = createSelector(
  selectUtilitiesState,
  (state: UtilitiesState) => state.diploma
);
export const categories = createSelector(
  selectUtilitiesState,
  (state: UtilitiesState) => state.categories
);

export const periodInscription = createSelector(
  selectUtilitiesState,
  (state: UtilitiesState) => state.periodInscription
);

export const allocation = createSelector(
  selectUtilitiesState,
  (state: UtilitiesState) => state.allocation
);

export const reseauSociaux = createSelector(
  selectUtilitiesState,
  (state: UtilitiesState) => state.reseauSociaux
);
export const couvertureMaladie = createSelector(
  selectUtilitiesState,
  (state: UtilitiesState) => state.couvertureMaladie
);
export const minimas = createSelector(
  selectUtilitiesState,
  (state: UtilitiesState) => state.minimas
);
export const sante = createSelector(
  selectUtilitiesState,
  (state: UtilitiesState) => state.sante
);
export const genre = createSelector(
  selectUtilitiesState,
  (state: UtilitiesState) => state.genre
);
export const status = createSelector(
  selectUtilitiesState,
  (state: UtilitiesState) => state.status
);
export const transport = createSelector(
  selectUtilitiesState,
  (state: UtilitiesState) => state.transport
);
export const judiciaire = createSelector(
  selectUtilitiesState,
  (state: UtilitiesState) => state.judiciaire
);
export const matrimonial = createSelector(
  selectUtilitiesState,
  (state: UtilitiesState) => state.matrimonial
);
export const handicap = createSelector(
  selectUtilitiesState,
  (state: UtilitiesState) => state.handicap
);
export const bureautique = createSelector(
  selectUtilitiesState,
  (state: UtilitiesState) => state.bureautique
);
export const sortie = createSelector(
  selectUtilitiesState,
  (state: UtilitiesState) => state.sortie
);

export const permis = createSelector(
  selectUtilitiesState,
  (state: UtilitiesState) => state.permis
);

export const geoMobilite = createSelector(
  selectUtilitiesState,
  (state: UtilitiesState) => state.geoMobilite
);

export const softskills = createSelector(
  selectUtilitiesState,
  (state: UtilitiesState) => state.softskills
);

//**DIAGNOSTIC */
//**DIAGNOSTIC TYPES */
export const diagnosticTypes = createSelector(
  selectUtilitiesState,
  (state: UtilitiesState) => state.diagnosticTypes
);

//
export const participant = createSelector(
  selectUtilitiesState,
  (state: UtilitiesState) => state.participant
);

export const participantIdentity = createSelector(
  selectUtilitiesState,
  (state: UtilitiesState) => state.participantIdentity
);
export const participantSituation = createSelector(
  selectUtilitiesState,
  (state: UtilitiesState) => state.participantSituation
);

export const entite = createSelector(
  selectUtilitiesState,
  (state: UtilitiesState) => state.entite
);

export const contact = createSelector(
  selectUtilitiesState,
  (state: UtilitiesState) => state.contact
);

export const operation = createSelector(
  selectUtilitiesState,
  (state: UtilitiesState) => state.operation
);

export const missionUtilities = createSelector(
  selectUtilitiesState,
  (state: UtilitiesState) => state.missionUtilities
);

export const missionDeplacementUtilities = createSelector(
  selectUtilitiesState,
  (state: UtilitiesState) => state.missionDeplacementUtilities
);

export const operationMarcheTypes = createSelector(
  selectUtilitiesState,
  (state: UtilitiesState) => state.marchesTypes
);

export const operationObjectifTypes = createSelector(
  selectUtilitiesState,
  (state: UtilitiesState) => state.objectifTypes
);

//PLANNING
export const planningTypes = createSelector(
  selectUtilitiesState,
  (state: UtilitiesState) => state.planningTypes
);

export const error = createSelector(
  selectUtilitiesState,
  (state: UtilitiesState) => state.error
);
