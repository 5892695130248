import { NzModalService } from 'ng-zorro-antd/modal';
import { UntypedFormBuilder, FormGroup } from '@angular/forms';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  BehaviorSubject,
  combineLatestWith,
  map,
  Observable,
  of,
  Subject,
  takeUntil,
} from 'rxjs';
import { OperationNoteId } from '../../store/operation-note.model';
import { OperationNoteState } from '../../store/operation-note.reducer';
import * as fromOperationNoteAction from '../../store/operation-note.actions';
import * as fromOperationNoteSelector from '../../store/operation-note.selectors';

import { Store } from '@ngrx/store';
import { OperationId } from 'src/app/components/operation/store/operation.model';
import { DocumentId } from 'src/app/models/document.model';
import { OperationNoteSubtitleId } from 'src/app/components/operation-note-subtitle/store/operation-note-subtitle.model';
import { OperationNoteSujetId } from 'src/app/components/operation-note-sujet/store/operation-note-sujet.model';
import { OperationNoteActionId } from 'src/app/components/operation-note-action/store/operation-note-action.model';
import { OperationNoteSubtitleState } from 'src/app/components/operation-note-subtitle/store/operation-note-subtitle.reducer';
import * as fromOperationNoteSubtitleSelector from 'src/app/components/operation-note-subtitle/store/operation-note-subtitle.selectors';
import { OperationNoteExportComponent } from '../operation-note-export/operation-note-export.component';
import { OperationNoteSujetState } from 'src/app/components/operation-note-sujet/store/operation-note-sujet.reducer';
import * as fromOperationSujetAction from 'src/app/components/operation-note-sujet/store/operation-note-sujet.actions';
import * as fromOperationSujetSelector from 'src/app/components/operation-note-sujet/store/operation-note-sujet.selectors';
import { OperationNoteFormComponent } from '../operation-note-form/operation-note-form.component';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';
import { zip } from 'rxjs';

@Component({
  selector: 'app-operation-note-item',
  templateUrl: './operation-note-item.component.html',
  styleUrls: ['./operation-note-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationNoteItemComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() operation$: Observable<OperationId | any> = of(null);
  @Input() note$: Observable<OperationNoteId | any> = of(null);
  @Output() back = new EventEmitter<boolean>(false);
  user$: Observable<any> = of(null);

  subtitle$ = new BehaviorSubject<OperationNoteSubtitleId | any>(null);
  subtitles$: Observable<OperationNoteSubtitleId[] | any[]> = of([]);

  sujet$: Observable<OperationNoteSujetId | any> = of(null);
  loading$: Observable<boolean> = of(false);

  noteHeaderSubtitle: string = 'COMPTE RENDU ';

  actions$: Observable<OperationNoteActionId[]> = of([]);
  actionsStatut$: Observable<any[]> = of([]);
  documents$: Observable<DocumentId[]> = of([]);
  utilities$: Observable<any> = of(null);

  sujetsWarning$: Observable<any> = of(null);

  subscribe = new Subject();

  @ViewChild('exportNoteFormTitle', { static: false })
  exportNoteFormTitle: TemplateRef<any> | any;

  modalWidth: number = 880;

  constructor(
    private userStore: Store<UserState>,
    private fb: UntypedFormBuilder,
    private operationNoteStore: Store<OperationNoteState>,
    private operationNoteSubtitleStore: Store<OperationNoteSubtitleState>,
    private operationNoteSujetStore: Store<OperationNoteSujetState>,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.getUser();
    this.getSubtitles();
    this.getLoading();
    this.getSujetWarnings();
    this.loadSujetsWarning();
  }
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getUser(): void {
    this.user$ = this.userStore.select(fromUserSelector.user);
  }

  getLoading(): void {
    this.loading$ = this.operationNoteStore.select(
      fromOperationNoteSelector.loading
    );
  }

  getSujetWarnings(): void {
    this.sujetsWarning$ = this.operationNoteSujetStore.select(
      fromOperationSujetSelector.sujetsWarning
    );
  }

  loadSujetsWarning(): void {
    this.operation$
      .pipe(
        takeUntil(this.subscribe),
        combineLatestWith(this.note$),
        map(([operation, note]) => ({ operation, note }))
      )
      .subscribe(({ operation, note }) => {
        if (!operation?.id || !note?.id) return;
        this.operationNoteSujetStore.dispatch(
          fromOperationSujetAction.loadOperationNoteSujetsWarning({
            id: operation.id,
            noteId: note.id,
          })
        );
      });
  }

  onBack(): void {
    this.back.emit(true);
  }

  getSubtitles(): void {
    this.subtitles$ = this.operationNoteSubtitleStore.select(
      fromOperationNoteSubtitleSelector.subtitles
    );
  }

  loadSujets(note: OperationNoteId): void {
    this.operationNoteSujetStore.dispatch(
      fromOperationSujetAction.loadOperationNoteSujetsByNote({ note })
    );
  }

  onSelectSubtitle(subtitle: OperationNoteSubtitleId): void {
    this.subtitle$.next(subtitle);
  }

  getNoteDetails(): void {}

  onSelectDropdown(
    event: any,
    user: any,
    operation: OperationId,
    note: OperationNoteId,
    subtitles: OperationNoteSubtitleId[]
  ): void {
    if (!event || !operation || !note) return;
    switch (event) {
      case 'update':
        this.onUpdate(operation, note);
        break;
      case 'exportPDF':
        this.loadSujets(note);
        this.onExportPDF(user, operation, note, subtitles);
        // this.operationNoteSujetStore
        //   .select(fromOperationSujetSelector.sujetsByNote)
        //   .pipe(takeUntil(this.subscribe))
        //   .subscribe((sujets) =>
        //     this.operationNoteStore.dispatch(
        //       fromOperationNoteAction.exportPdfOperationNote({
        //         operation,
        //         note,
        //         subtitles,
        //         sujets,
        //       })
        //     )
        //   );

        break;
      case 'exportWRD':
        this.operationNoteStore.dispatch(
          fromOperationNoteAction.exportWordOperationNote({
            operation,
            note,
            subtitles,
          })
        );
        break;
      case 'delete':
        const operationId: string = operation.id;
        const { id } = note;
        this.operationNoteStore.dispatch(
          fromOperationNoteAction.deleteOperationNote({ operationId, id })
        );
        this.onBack();
        break;

      default:
        break;
    }
  }

  onUpdate(operation: OperationId, note: OperationNoteId): void {
    if (!operation) return;
    const modal = this.modal.create({
      nzContent: OperationNoteFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.exportNoteFormTitle,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
    });
    const instance = modal.getContentComponent();
    instance.operation$ = of(operation);
    instance.note$ = of(note);

    instance.cancel.subscribe((event: boolean): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
    instance.update.subscribe((event: any): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
  }

  onExportPDF(
    user: any,
    operation: OperationId,
    note: OperationNoteId,
    subtitles: OperationNoteSubtitleId[]
  ): void {
    if (!operation || !note) return;
    const modal = this.modal.create({
      nzContent: OperationNoteExportComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.exportNoteFormTitle,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
    });
    const instance = modal.getContentComponent();
    instance.user$ = of(user);
    instance.operation$ = of(operation);
    instance.note$ = of(note);
    instance.subtitles$ = of(subtitles);

    instance.cancel.subscribe((event: boolean): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
    // instance.add.subscribe((note: any): any => {
    //   if (!note) {
    //     return null;
    //   } else {
    //     this.onAdd(operation, note);
    //     modal.close();
    //   }
    // });
  }
}
