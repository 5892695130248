import {
  Component,
  OnDestroy,
  OnInit,
  EventEmitter,
  Output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { Observable, of, Subject, Subscriber } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Timestamp } from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs';
import * as fromTicketActions from '../../store/ticket.actions';
import { TicketState } from '../../../tickets/store/ticket.reducer';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';

@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss'],
})
export class TicketComponent implements OnInit, OnDestroy {
  user$: Observable<any> = of(null);
  error$: Observable<any> = of(null);
  subscribe = new Subject();
  title: string = 'Nouveau ticket';

  updateText = new BehaviorSubject<string>('');
  dateStart: UntypedFormControl = new UntypedFormControl('');
  type: UntypedFormControl = new UntypedFormControl('');
  auteur: UntypedFormControl = new UntypedFormControl('');
  message: UntypedFormControl = new UntypedFormControl('');
  messageHTML: UntypedFormControl = new UntypedFormControl('');

  //TICKET
  ticketModal: boolean = false;
  ticketForm: UntypedFormGroup = this.fb.group({});
  ticketDateStart: UntypedFormControl = new UntypedFormControl('');
  ticketType: UntypedFormControl = new UntypedFormControl('');
  ticketAuteur: UntypedFormControl = new UntypedFormControl('');
  ticketMessage: UntypedFormControl = new UntypedFormControl('');
  ticketStatut: UntypedFormControl = new UntypedFormControl('');
  ticketkMessageHTML: UntypedFormControl = new UntypedFormControl('');
  ticketDocFileRef: UntypedFormControl = new UntypedFormControl('');
  ticketDocPath: UntypedFormControl = new UntypedFormControl('');
  ticketDocName: UntypedFormControl = new UntypedFormControl('');
  ticketDocURL: UntypedFormControl = new UntypedFormControl('');
  ticketBugs = ['Bug majeur', 'Bug mineur'];

  @Output() close = new EventEmitter<boolean>(false);
  constructor(
    private fb: UntypedFormBuilder,
    private ticketStore: Store<TicketState>,
    private userStore: Store<UserState>
  ) {}

  ngOnInit(): void {
    this.ticketInit();
    this.getUser();
  }

  getUser(): void {
    this.user$ = this.userStore.select(fromUserSelector.user);
  }

  onTicket(): void {
    this.ticketModal = true;
    this.close.emit(false);
  }

  onCancelTicket(): any {
    this.ticketModal = false;
    this.ticketForm.reset();
    this.close.emit(true);
  }

  ticketInit(): void {
    this.formInit();
    this.formCreate();
    this.setUser__TICKET();
  }

  formInit(): void {
    this.ticketDateStart = new UntypedFormControl(
      Timestamp.now(),
      Validators.required
    );
    this.ticketType = new UntypedFormControl('', Validators.required);
    this.ticketAuteur = new UntypedFormControl('', Validators.required);
    this.ticketMessage = new UntypedFormControl('', Validators.required);
    this.ticketkMessageHTML = new UntypedFormControl('', Validators.required);
    this.ticketStatut = new UntypedFormControl('En attente', Validators.required);
    this.ticketDocFileRef = new UntypedFormControl('');
    this.ticketDocPath = new UntypedFormControl('');
    this.ticketDocName = new UntypedFormControl('');
    this.ticketDocURL = new UntypedFormControl('');
  }

  formCreate(): void {
    this.ticketForm = this.fb.group({
      dateStart: this.ticketDateStart,
      type: this.ticketType,
      auteur: this.ticketAuteur,
      message: this.ticketMessage,
      messageHTML: this.ticketkMessageHTML,
      statut: this.ticketStatut,
      document: this.fb.group({
        fileRef: this.ticketDocFileRef,
        path: this.ticketDocPath,
        name: this.ticketDocName,
        url: this.ticketDocURL,
      }),
    });
  }

  setUser__TICKET(): any {
    this.user$.pipe(takeUntil(this.subscribe)).subscribe((user): any => {
      if (user) {
        this.ticketForm.patchValue({
          auteur: user ? user : null,
        });
      } else {
        return null;
      }
    });
  }

  setMessage(event: any): any {
    if (event) {
      this.ticketForm.patchValue({
        message: event.textValue,
        messageHTML: event.textHTML,
      });
    } else {
      this.updateText.next('');
      return null;
    }
  }

  sendTicket(): any {
    if (!this.ticketForm.valid) {
      return null;
    } else {
      const ticket = this.ticketForm.value;
      this.ticketStore.dispatch(fromTicketActions.addTicket({ ticket }));
      this.ticketModal = false;
      this.ticketForm.reset();
      this.close.emit(true);
      this.updateText.next('');
    }
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }
}
