import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'app-missions-form-steps',
  templateUrl: './missions-form-steps.component.html',
  styleUrls: ['./missions-form-steps.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MissionsFormStepsComponent implements OnInit {
  @Input() isAdd: boolean = true;
  @Input() isOperation: boolean = true;

  @Input() isMetierUpdate: any;
  @Input() isContratUpdate: any;
  @Input() titleMission: any;
  @Input() isContrat: any;
  @Input() currentStep: number = 0;

  constructor() {}

  ngOnInit(): void {}
}
