<div class="contentParticipantSituation">
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="12">
      <nz-card [nzTitle]="infoTitle" [nzExtra]="participantSituation_criteres">
        <ng-template #infoTitle>
          <app-header-title
            [icon]="'rule'"
            [title]="'Critères sociaux'"
            [subtitle]="true"
          ></app-header-title>
        </ng-template>
        <ng-template #participantSituation_criteres>
          <nz-badge
            class="badgeCounter"
            *ngIf="participant?.criteres?.length > 2"
            [nzCount]="participant?.criteres?.length"
            nz-dropdown
            nzTrigger="click"
            [nzDropdownMenu]="menuCriteres"
            nzPlacement="bottomRight"
          >
          </nz-badge>
          <nz-dropdown-menu #menuCriteres="nzDropdownMenu">
            <ul nz-menu>
              <li
                nz-menu-item
                *ngFor="let item of participant?.criteres; let i = index"
              >
                <p nz-typography>{{ item.item }}</p>
              </li>
            </ul>
          </nz-dropdown-menu>
        </ng-template>
        <nz-descriptions
          nzBordered
          [nzColumn]="{ xxl: 4, xl: 4, lg: 4, md: 4, sm: 0, xs: 0 }"
          class="description_container"
        >
          <!-- CRITERES - ELIGIBILITE -->

          <nz-descriptions-item
            [nzTitle]="'Jeune de moins de 26ans'"
            [nzSpan]="4"
          >
            <mat-icon
              class="critereDisabed"
              *ngIf="!participant?.criteresMap?.jeunesDeMoinsDe26ans"
              >radio_button_unchecked</mat-icon
            >
            <mat-icon
              class="critereActiv"
              *ngIf="participant?.criteresMap?.jeunesDeMoinsDe26ans"
              >radio_button_checked</mat-icon
            >
          </nz-descriptions-item>
          <nz-descriptions-item [nzTitle]="'Jeune décrocheur'" [nzSpan]="4">
            <mat-icon
              class="critereDisabed"
              *ngIf="!participant?.criteresMap?.jeuneDecrocheur"
              >radio_button_unchecked</mat-icon
            >
            <mat-icon
              class="critereActiv"
              *ngIf="participant?.criteresMap?.jeuneDecrocheur"
              >radio_button_checked</mat-icon
            >
          </nz-descriptions-item>
          <nz-descriptions-item [nzTitle]="'Neet'" [nzSpan]="4">
            <mat-icon
              class="critereDisabed"
              *ngIf="!participant?.criteresMap?.neet"
              >radio_button_unchecked</mat-icon
            >
            <mat-icon
              class="critereActiv"
              *ngIf="participant?.criteresMap?.neet"
              >radio_button_checked</mat-icon
            >
          </nz-descriptions-item>
          <nz-descriptions-item [nzTitle]="'Parent isolé'" [nzSpan]="4">
            <mat-icon
              class="critereDisabed"
              *ngIf="!participant?.criteresMap?.parentIsole"
              >radio_button_unchecked</mat-icon
            >
            <mat-icon
              class="critereActiv"
              *ngIf="participant?.criteresMap?.parentIsole"
              >radio_button_checked</mat-icon
            >
          </nz-descriptions-item>

          <nz-descriptions-item
            [nzTitle]="'Personne prise en charge par une SIAE'"
            [nzSpan]="4"
          >
            <mat-icon
              class="critereDisabed"
              *ngIf="!participant?.criteresMap?.personnePriseEnChargeIAE"
              >radio_button_unchecked</mat-icon
            >
            <mat-icon
              class="critereActiv"
              *ngIf="participant?.criteresMap?.personnePriseEnChargeIAE"
              >radio_button_checked</mat-icon
            >
          </nz-descriptions-item>
          <nz-descriptions-item
            [nzTitle]="'Personne employé d\'un GEIQ'"
            [nzSpan]="4"
          >
            <mat-icon
              class="critereDisabed"
              *ngIf="!participant?.criteresMap?.PersonneEmployeGEIQ"
              >radio_button_unchecked</mat-icon
            >
            <mat-icon
              class="critereActiv"
              *ngIf="participant?.criteresMap?.PersonneEmployeGEIQ"
              >radio_button_checked</mat-icon
            >
          </nz-descriptions-item>
          <nz-descriptions-item [nzTitle]="'Primo-arrivant'" [nzSpan]="4">
            <mat-icon
              class="critereDisabed"
              *ngIf="!participant?.criteresMap?.primoArrivant"
              >radio_button_unchecked</mat-icon
            >
            <mat-icon
              class="critereActiv"
              *ngIf="participant?.criteresMap?.primoArrivant"
              >radio_button_checked</mat-icon
            >
          </nz-descriptions-item>
          <nz-descriptions-item [nzTitle]="'Réfugié'" [nzSpan]="4">
            <mat-icon
              class="critereDisabed"
              *ngIf="
                !participant?.criteresMap?.beneficiaireProtectionInterRefugie
              "
              >radio_button_unchecked</mat-icon
            >
            <mat-icon
              class="critereActiv"
              *ngIf="
                participant?.criteresMap?.beneficiaireProtectionInterRefugie
              "
              >radio_button_checked</mat-icon
            >
          </nz-descriptions-item>

          <nz-descriptions-item
            [nzTitle]="'Protection subsidiaire'"
            [nzSpan]="4"
          >
            <mat-icon
              class="critereDisabed"
              *ngIf="
                !participant?.criteresMap
                  ?.beneficiaireProtectionInterSubsidiaire
              "
              >radio_button_unchecked</mat-icon
            >
            <mat-icon
              class="critereActiv"
              *ngIf="
                participant?.criteresMap?.beneficiaireProtectionInterSubsidiaire
              "
              >radio_button_checked</mat-icon
            >
          </nz-descriptions-item>
          <nz-descriptions-item
            [nzTitle]="'Fonds Social Européen'"
            [nzSpan]="4"
          >
            <mat-icon
              class="critereDisabed"
              *ngIf="!participant?.criteresMap?.fse"
              >radio_button_unchecked</mat-icon
            >
            <mat-icon class="critereActiv" *ngIf="participant?.criteresMap?.fse"
              >radio_button_checked</mat-icon
            >
          </nz-descriptions-item>
          <nz-descriptions-item
            [nzTitle]="'Aide Sociale à l\'Enfance'"
            [nzSpan]="4"
          >
            <mat-icon
              class="critereDisabed"
              *ngIf="!participant?.criteresMap?.ase"
              >radio_button_unchecked</mat-icon
            >
            <mat-icon class="critereActiv" *ngIf="participant?.criteresMap?.ase"
              >radio_button_checked</mat-icon
            >
          </nz-descriptions-item>
          <nz-descriptions-item
            [nzTitle]="'Autoentrepreneur en QPV'"
            [nzSpan]="4"
          >
            <mat-icon
              class="critereDisabed"
              *ngIf="!participant?.criteresMap?.autoentrepreneurQPV"
              >radio_button_unchecked</mat-icon
            >
            <mat-icon
              class="critereActiv"
              *ngIf="participant?.criteresMap?.autoentrepreneurQPV"
              >radio_button_checked</mat-icon
            >
          </nz-descriptions-item>
        </nz-descriptions>
      </nz-card>
    </nz-col>
    <nz-col nzSpan="12">
      <nz-card [nzTitle]="hebergementTitle">
        <ng-template #hebergementTitle>
          <app-header-title
            [icon]="'place'"
            [title]="'Hébergement'"
            [subtitle]="true"
          ></app-header-title>
        </ng-template>
        <nz-descriptions
          nzBordered
          [nzColumn]="{ xxl: 4, xl: 4, lg: 4, md: 4, sm: 0, xs: 0 }"
          class="description_container"
          *ngIf="
            (participant?.informations?.adresse &&
              participant?.informations?.adresse?.adresse &&
              participant?.informations?.adresse?.adresse?.properties) ||
              (participant?.informations?.adresse &&
                participant?.informations?.adresse?.complement) ||
              participant?.situationPersonnelle?.hebergement?.chezTier ||
              participant?.situationPersonnelle?.hebergement
                ?.centreHebergement ||
              participant?.situationPersonnelle?.hebergement?.autre ||
              participant?.situationPersonnelle?.hebergement?.autreLieu ||
              participant?.situationPersonnelle?.hebergement?.proprietaire ||
              participant?.situationPersonnelle?.hebergement?.lieuPublic ||
              participant?.situationPersonnelle?.hebergement?.chezParent ||
              participant?.situationPersonnelle?.hebergement?.locataire ||
              participant?.situationPersonnelle?.hebergement?.hotel ||
              participant?.situationPersonnelle?.hebergement?.nesaitpas ||
              participant?.situationPersonnelle?.hebergementPrecision ||
              participant?.informations?.adresse?.isQPV ||
              participant?.informations?.adresse?.isQVA ||
              participant?.informations?.adresse?.isZRR;
            else noDataHebergement
          "
        >
          <p *ngIf="!participant?.informations?.adresse?.adresse">
            Veuillez actualiser ou renseigner l'adresse du participant
          </p>
          <nz-descriptions-item
            [nzTitle]="'Adresse'"
            [nzSpan]="4"
            class="description_item_adresse"
          >
            <p
              *ngIf="
                participant?.informations?.adresse &&
                participant?.informations?.adresse?.adresse &&
                participant?.informations?.adresse?.adresse?.properties
              "
              style="width: 100%"
            >
              {{
                participant?.informations?.adresse?.adresse?.properties?.label
              }}
            </p>
            <p
              *ngIf="
                participant?.informations?.adresse &&
                participant?.informations?.adresse?.fields &&
                participant?.informations?.adresse?.fields?.properties
              "
              style="width: 100%"
            >
              {{
                participant?.informations?.adresse?.fields?.properties?.label
              }}
            </p>
          </nz-descriptions-item>

          <nz-descriptions-item [nzSpan]="4" nzTitle="Complément">
            <small
              *ngIf="
                participant?.informations?.adresse &&
                participant?.informations?.adresse?.complement
              "
              nzTitle="Complément d'adresse"
            >
              {{ participant?.informations?.adresse?.complement }}
            </small>
          </nz-descriptions-item>

          <nz-descriptions-item nzTitle="Hébergement" [nzSpan]="4">
            <nz-tag
              *ngIf="participant?.situationPersonnelle?.hebergement?.chezTier"
              nzColor="geekblue"
            >
              Chez un tier
            </nz-tag>
            <nz-tag
              *ngIf="
                participant?.situationPersonnelle?.hebergement
                  ?.centreHebergement
              "
              nzColor="geekblue"
            >
              Centre d'hébergement
            </nz-tag>
            <nz-tag
              *ngIf="participant?.situationPersonnelle?.hebergement?.autre"
              nzColor="geekblue"
            >
              Autre
            </nz-tag>
            <nz-tag
              *ngIf="participant?.situationPersonnelle?.hebergement?.autreLieu"
              nzColor="geekblue"
            >
              Autre lieu
            </nz-tag>
            <nz-tag
              *ngIf="
                participant?.situationPersonnelle?.hebergement?.proprietaire
              "
              nzColor="geekblue"
            >
              Propriétaire
            </nz-tag>
            <nz-tag
              *ngIf="participant?.situationPersonnelle?.hebergement?.lieuPublic"
              nzColor="geekblue"
            >
              Lieu public
            </nz-tag>
            <nz-tag
              *ngIf="participant?.situationPersonnelle?.hebergement?.chezParent"
              nzColor="geekblue"
            >
              Chez ses parents
            </nz-tag>
            <nz-tag
              *ngIf="participant?.situationPersonnelle?.hebergement?.locataire"
              nzColor="geekblue"
            >
              Locataire
            </nz-tag>
            <nz-tag
              *ngIf="participant?.situationPersonnelle?.hebergement?.hotel"
              nzColor="geekblue"
            >
              Hôtel
            </nz-tag>
            <nz-tag
              *ngIf="participant?.situationPersonnelle?.hebergement?.nesaitpas"
              nzColor="geekblue"
            >
              Ne sait pas
            </nz-tag>
          </nz-descriptions-item>

          <nz-descriptions-item nzTitle="Politique emploi" [nzSpan]="4">
            <nz-tag
              nzColor="geekblue"
              *ngIf="participant?.informations?.adresse?.isQPV"
            >
              <strong>QPV</strong>
            </nz-tag>
            <nz-tag
              nzColor="geekblue"
              *ngIf="participant?.informations?.adresse?.isQVA"
            >
              <strong>QVA</strong>
            </nz-tag>
            <nz-tag
              nzColor="geekblue"
              *ngIf="participant?.informations?.adresse?.isZRR"
            >
              <strong>ZRR</strong>
            </nz-tag>
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="Précision" [nzSpan]="4">
            <p nz-typography>
              {{ participant?.situationPersonnelle?.hebergementPrecision }}
            </p>
          </nz-descriptions-item>
        </nz-descriptions>
      </nz-card>
    </nz-col>
  </nz-row>
  <nz-space [nzSize]="100"></nz-space>

  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="12">
      <nz-card [nzTitle]="sejourTitle">
        <ng-template #sejourTitle>
          <app-header-title
            [icon]="'subject'"
            [title]="'Séjour'"
            [subtitle]="true"
          ></app-header-title>
        </ng-template>
        <nz-descriptions
          nzBordered
          [nzColumn]="{ xxl: 4, xl: 4, lg: 4, md: 4, sm: 0, xs: 0 }"
          class="description_container"
          *ngIf="
            participant?.typeCarteSejour ||
              participant?.numbCarteSejour ||
              participant?.numCaiCir ||
              participant?.dateEntree;
            else noDataSejours
          "
        >
          <!-- CARTE DE SEJOURS -->
          <nz-descriptions-item nzTitle="Carte de séjour" [nzSpan]="4">
            <p nz-typography *ngIf="participant?.typeCarteSejour" nz-tooltip>
              {{ participant?.typeCarteSejour?.titre | carLimitation: 100 }}
            </p>
          </nz-descriptions-item>

          <!-- N° CARTE DE SEJOURS -->
          <nz-descriptions-item nzTitle="N° de carte" [nzSpan]="4">
            <p nz-typography *ngIf="participant?.numbCarteSejour">
              {{ participant?.numbCarteSejour }}
            </p>
          </nz-descriptions-item>

          <!-- N° CIA/CIR -->
          <nz-descriptions-item nzTitle="N° CAI/CIR" [nzSpan]="4">
            <p nz-typography *ngIf="participant?.numCaiCir">
              {{ participant?.numCaiCir }}
            </p>
          </nz-descriptions-item>
          <!-- DATE D'ENTREE SUR LE TERRITOIRE -->
          <nz-descriptions-item
            nzTitle="Date d'entrée sur le territoire"
            [nzSpan]="4"
          >
            <p
              nz-typography
              *ngIf="participant?.dateEntree"
              class="tabIdentityValue"
            >
              {{ participant?.dateEntree | dateFr: "small" }}
            </p>
          </nz-descriptions-item>
        </nz-descriptions>
      </nz-card>
    </nz-col>
    <nz-col nzSpan="12">
      <nz-card [nzTitle]="santeTitle">
        <ng-template #santeTitle>
          <app-header-title
            [icon]="'subject'"
            [title]="'Famille'"
            [subtitle]="true"
          ></app-header-title>
        </ng-template>
        <nz-descriptions
          nzBordered
          [nzColumn]="{ xxl: 4, xl: 4, lg: 4, md: 4, sm: 0, xs: 0 }"
          class="description_container"
          *ngIf="
            participant?.situationPersonnelle?.sante ||
              participant?.couvertureMaladie ||
              participant?.situationPersonnelle?.familiale ||
              participant?.situationPersonnelle?.enfantsAcharges ||
              participant?.situationPersonnelle?.enfantMoyenDeGarde ||
              participant?.situationPersonnelle?.enfantScolarise;
            else noDataHealth
          "
        >
          <!-- SANTE -->
          <nz-descriptions-item nzTitle="Santé" [nzSpan]="4">
            <p
              nz-typography
              *ngIf="participant?.situationPersonnelle"
              class="tabIdentityValue"
            >
              {{ participant?.situationPersonnelle?.sante }}
            </p>
          </nz-descriptions-item>

          <!-- COUVERTURE MALADIE-->
          <nz-descriptions-item nzTitle="Couverture maladie" [nzSpan]="4">
            <nz-tag *ngIf="participant?.couvertureMaladie" nzColor="geekblue">
              {{ participant?.couvertureMaladie }}
            </nz-tag>
          </nz-descriptions-item>

          <!-- MATROMINIAL -->
          <nz-descriptions-item nzTitle="Matrimonial" [nzSpan]="4">
            <p
              *ngIf="participant?.situationPersonnelle"
              class="tabIdentityValue"
            >
              {{ participant?.situationPersonnelle?.familiale }}
            </p>
          </nz-descriptions-item>

          <!-- ENFANTS -->
          <nz-descriptions-item nzTitle="Enfants" [nzSpan]="4">
            <nz-row nzAlign="middle" nzGutter="8" nzJustify="center">
              <nz-col nzSpan="2"></nz-col>
              <nz-col nzSpan="6">
                <div
                  class="matAvatar"
                  *ngIf="participant?.situationPersonnelle?.enfantsAcharges"
                  [ngStyle]="{
                    'background-color': participant?.situationPersonnelle
                      ?.enfantsAcharges
                      ? colorValidation
                      : default
                  }"
                >
                  <mat-icon
                    class="childIcon"
                    nz-tooltip
                    [nzTooltipMouseEnterDelay]="0.5"
                    [nzTooltipTitle]="
                      participant?.situationPersonnelle?.enfantsAcharges
                        ? 'Enfant.s à charge'
                        : ''
                    "
                    nzTooltipPlacement="bottom"
                    >escalator_warning</mat-icon
                  >
                </div>
                <!-- <small
                  *ngIf="participant?.situationPersonnelle?.enfantsAcharges"
                >
                  Enfant.s à charge
                </small> -->
              </nz-col>
              <nz-col nzSpan="2"></nz-col>
              <nz-col nzSpan="6">
                <div
                  class="matAvatar"
                  [ngStyle]="{
                    'background-color':
                      participant?.situationPersonnelle?.enfantsAcharges &&
                      participant?.situationPersonnelle?.enfantMoyenDeGarde
                        ? colorValidation
                        : colorAlert
                  }"
                  *ngIf="participant?.situationPersonnelle?.enfantsAcharges"
                >
                  <mat-icon
                    class="childIcon"
                    nz-tooltip
                    [nzTooltipMouseEnterDelay]="0.5"
                    [nzTooltipTitle]="
                      participant?.situationPersonnelle?.enfantMoyenDeGarde
                        ? 'Moyen.s de garde'
                        : ''
                    "
                    nzTooltipPlacement="bottom"
                    >child_friendly</mat-icon
                  >
                </div>
                <!-- <small
                  *ngIf="
                    participant?.situationPersonnelle?.enfantMoyenDeGarde;
                    else aucunMoyenDeGarde
                  "
                >
                  Moyen.s de garde
                </small> -->
                <ng-template #aucunMoyenDeGarde>
                  <small> Aucun moyen de garde </small>
                </ng-template>
              </nz-col>
              <nz-col nzSpan="2"></nz-col>
              <nz-col nzSpan="6">
                <div
                  class="matAvatar"
                  [ngStyle]="{
                    'background-color':
                      participant?.situationPersonnelle?.enfantsAcharges &&
                      participant?.situationPersonnelle?.enfantMoyenDeGarde
                        ? colorValidation
                        : colorAlert
                  }"
                  *ngIf="participant?.situationPersonnelle?.enfantsAcharges"
                >
                  <mat-icon
                    class="childIcon"
                    nz-tooltip
                    [nzTooltipMouseEnterDelay]="0.5"
                    [nzTooltipTitle]="
                      participant?.situationPersonnelle?.enfantScolarise
                        ? 'Enfant.s scolarisé.s'
                        : ''
                    "
                    nzTooltipPlacement="bottom"
                    >history_edu</mat-icon
                  >
                </div>
                <!-- <small
                  *ngIf="
                    participant?.situationPersonnelle?.enfantScolarise;
                    else aucunScolarisation
                  "
                >
                  Enfant.s scolarisé.s
                </small> -->

                <ng-template #aucunScolarisation>
                  <small> Aucune scolarisation </small>
                </ng-template>
              </nz-col>
            </nz-row>
          </nz-descriptions-item>
        </nz-descriptions>
      </nz-card>
    </nz-col>
  </nz-row>
</div>

<ng-template #prePlace
  ><mat-icon class="iconCoordonnees">place</mat-icon></ng-template
>

<ng-template #noDataHebergement>
  <app-no-result
    [icon]="''"
    [notitle]="'Aucune information d\'hébergement renseignée'"
    [isButton]="false"
  ></app-no-result>
</ng-template>

<ng-template #noDataHealth>
  <app-no-result
    [icon]="''"
    [notitle]="'Aucune information de santé ou familliale renseignée'"
    [isButton]="false"
  ></app-no-result>
</ng-template>

<ng-template #noDataSejours>
  <app-no-result
    [icon]="''"
    [notitle]="'Aucune information de type de séjour rensiegnée'"
    [isButton]="false"
  ></app-no-result>
</ng-template>

<ng-template #noDataCritere>
  <app-no-result
    [icon]="''"
    [notitle]="'Aucun critère rensiegné'"
    [isButton]="false"
  ></app-no-result>
</ng-template>
