import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-dashboard-headers',
  templateUrl: './dashboard-headers.component.html',
  styleUrls: ['./dashboard-headers.component.scss'],
})
export class DashboardHeadersComponent implements OnInit {
  @Input() title: string = '';
  @Input() total: number = 0;

  @Output() select = new EventEmitter<string>(false);
  constructor() {}

  ngOnInit(): void {}

  onSelect(event: string): void {
    this.select.emit(event);
  }
}
