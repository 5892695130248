import { ItemEventId } from '../features/suivi/components/item-event/itemEvent.model';
import { Injectable } from '@angular/core';
import { Observable, of, EMPTY } from 'rxjs';
import { map } from 'rxjs/operators';
import { Apollo, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'any',
})
export class EntiteSuivisService {
  GET_ENTITE_SUIVIS = gql`
    query EntiteSuivis($id: ID!) {
      entiteSuivis(id: $id) {
        id
        object
        title
        type
        text
        textHtml
        auteur {
          avatar
          photo
          displayName
          setting {
            color
          }
        }
        dateStart
        dateUpdate
        userUpdate {
          avatar
          photo
          displayName
          setting {
            color
          }
        }
      }
    }
  `;

  GET_ENTITE_SUIVI = gql`
    query EntiteSuivi($entiteId: ID!, $id: ID!) {
      entiteSuivi(entiteId: $entiteId, id: $id) {
        id
        object
        title
        type
        text
        textHtml
        auteur {
          avatar
          photo
          displayName
          setting {
            color
          }
        }
        dateStart
        dateUpdate
        userUpdate {
          avatar
          photo
          displayName
          setting {
            color
          }
        }
      }
    }
  `;

  CREATE_ENTITE_SUIVI = gql`
    mutation CreateEntiteSuivi($id: ID!, $suivi: SuiviInput!) {
      createEntiteSuivi(id: $id, suivi: $suivi) {
        id
        object
        title
        type
        text
        textHtml
        auteur {
          avatar
          photo
          displayName
          setting {
            color
          }
        }
        dateStart
        dateUpdate
        userUpdate {
          avatar
          photo
          displayName
          setting {
            color
          }
        }
      }
    }
  `;

  UPDATE_ENTITE_SUIVI = gql`
    mutation UpdateEntiteSuivi($id: ID!, $suivi: SuiviUpdateInput!) {
      updateEntiteSuivi(id: $id, suivi: $suivi) {
        id
        object
        title
        type
        text
        textHtml
        auteur {
          avatar
          photo
          displayName
          setting {
            color
          }
        }
        dateStart
        dateUpdate
        userUpdate {
          avatar
          photo
          displayName
          setting {
            color
          }
        }
      }
    }
  `;
  DELETE_ENTITE_SUIVI = gql`
    mutation DeleteEntiteSuivi($entiteId: ID!, $id: ID!) {
      deleteEntiteSuivi(entiteId: $entiteId, id: $id)
    }
  `;

  DELETE_ENTITE_SUIVIS = gql`
    mutation DeleteEntiteSuivis($id: ID!) {
      deleteEntiteSuivis(id: $id)
    }
  `;

  constructor(private apollo: Apollo) {}

  getAll(id: string): Observable<any> {
    try {
      return this.apollo
        .watchQuery<any>({
          query: this.GET_ENTITE_SUIVIS,
          variables: { id },
        })
        .valueChanges.pipe(
          map(({ data, loading, error }) => {
            return { data, loading, error };
          })
        );
    } catch (error) {
      return of(error);
    }
  }

  getOne(entiteId: string, id: string): Observable<any> {
    try {
      return this.apollo
        .watchQuery<any>({
          query: this.GET_ENTITE_SUIVI,
          variables: { entiteId, id },
        })
        .valueChanges.pipe(
          map(({ data, loading, error }) => {
            return { data, loading, error };
          })
        );
    } catch (error) {
      return of(error);
    }
  }

  addOne(id: string, suivi: any): Observable<any> {
    try {
      if (!id || !suivi) EMPTY;
      return this.apollo.mutate<any>({
        mutation: this.CREATE_ENTITE_SUIVI,
        variables: { id, suivi },
      });
    } catch (error) {
      return of(error);
    }
  }

  updateOne(id: string, suivi: Partial<ItemEventId | any>): Observable<any> {
    try {
      if (!id || !suivi) EMPTY;
      return this.apollo.mutate<any>({
        mutation: this.UPDATE_ENTITE_SUIVI,
        variables: { id, suivi: { ...suivi.changes } },
      });
    } catch (error) {
      return of(error);
    }
  }

  deleteOne(entiteId: string, id: string): Observable<any> {
    try {
      if (!entiteId || !id) EMPTY;
      return this.apollo.mutate<any>({
        mutation: this.DELETE_ENTITE_SUIVI,
        variables: { entiteId, id },
      });
    } catch (error) {
      return of(error);
    }
  }

  deleteAll(id: string): Observable<any> {
    try {
      if (!id) EMPTY;
      return this.apollo.mutate<any>({
        mutation: this.DELETE_ENTITE_SUIVIS,
        variables: { id },
      });
    } catch (error) {
      return of(error);
    }
  }
}
