<div class="space-y-4 divide-y">
  <app-loading *ngIf="isLoading"></app-loading>

  <pdf-viewer
    [src]="pdfSrc"
    [render-text]="true"
    [original-size]="false"
    [show-all]="false"
    [stick-to-page]="true"
    [render-text]="true"
    [fit-to-page]="true"
    [(page)]="page"
    (after-load-complete)="loadingFn($event)"
    (on-progress)="loadingProgressionFn($event)"
    [ngStyle]="{ width: '100%', height: '600px', 'overflow-x': 'hidden' }"
  ></pdf-viewer>

  <ng-container *ngIf="!isLoading">
    <div class="navbar">
      <div class="navbar-start">
        <div class="btn-group">
          <button class="btn btn-sm btn-ghost" (click)="onPrevPage()">
            Précédent
          </button>
          <button class="btn btn-sm btn-ghost" (click)="onNextPage()">
            Suivant
          </button>
        </div>
      </div>

      <div class="navbar-center">
        <div class="pagesNumberContainer">
          <p class="text-lg font-bold tracking-wider">
            Page {{ page }} sur {{ numPages }}
          </p>
        </div>
      </div>

      <div class="navbar-end">
        <div class="btn-group">
          <button class="btn btn-sm btn-ghost" (click)="onCancel()">
            Fermer
          </button>
          <button
            class="btn btn-sm btn-primary"
            nzType="primary"
            (click)="onDownload()"
          >
            Télécharger
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</div>
