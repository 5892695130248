<div class="dropdown dropdown-left">
  <label tabindex="0" class="btn btn-circle btn-sm btn-ghost">
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="页面-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="System"
          transform="translate(-144.000000, -288.000000)"
          fill-rule="nonzero"
        >
          <g id="more_2_line" transform="translate(144.000000, 288.000000)">
            <path
              d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z"
              id="MingCute"
              fill-rule="nonzero"
            ></path>
            <path
              d="M12,16.5 C12.8284,16.5 13.5,17.1716 13.5,18 C13.5,18.8284 12.8284,19.5 12,19.5 C11.1716,19.5 10.5,18.8284 10.5,18 C10.5,17.1716 11.1716,16.5 12,16.5 Z M12,10.5 C12.8284,10.5 13.5,11.1716 13.5,12 C13.5,12.8284 12.8284,13.5 12,13.5 C11.1716,13.5 10.5,12.8284 10.5,12 C10.5,11.1716 11.1716,10.5 12,10.5 Z M12,4.5 C12.8284,4.5 13.5,5.17157 13.5,6 C13.5,6.82843 12.8284,7.5 12,7.5 C11.1716,7.5 10.5,6.82843 10.5,6 C10.5,5.17157 11.1716,4.5 12,4.5 Z"
              id="形状"
              fill="#09244BFF"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  </label>
  <ul
    tabindex="0"
    class="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52 divide-y z-30"
  >
    <div *ngIf="expansion || isShare || isRelance || isCopy">
      <ng-container *ngTemplateOutlet="expansion"></ng-container>

      <ng-container *ngIf="isShare">
        <li (click)="onSelect('partage')">
          <a>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z"
              />
            </svg>
            Partager
          </a>
        </li>
      </ng-container>
      <ng-container *ngIf="isRelance">
        <li (click)="onSelect('relance')">
          <a>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
              />
            </svg>
            Relancer
          </a>
        </li>
      </ng-container>
      <li (click)="onSelect('copy')" *ngIf="isCopy">
        <a>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
            />
          </svg>
          Dupliquer
        </a>
      </li>
    </div>

    <div
      *ngIf="
        isExport || isExportPDF || isExportPPT || isExportEXL || isExportWRD
      "
      class="divide"
    >
      <li (click)="onSelect('export')" *ngIf="isExport">
        <a>
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.5 10.5l-3.25-3m3.25 3l3-3m-3 3V1m6 6v6.5h-12V7"
              stroke-width="2"
              stroke="currentColor"
            ></path>
          </svg>
          Exporter
        </a>
      </li>
      <li (click)="onSelect('exportPDF')" *ngIf="isExportPDF">
        <a>
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.5 6.5V6H2v.5h.5zm4 0V6H6v.5h.5zm0 4H6v.5h.5v-.5zm7-7h.5v-.207l-.146-.147-.354.354zm-3-3l.354-.354L10.707 0H10.5v.5zM2.5 7h1V6h-1v1zm.5 4V8.5H2V11h1zm0-2.5v-2H2v2h1zm.5-.5h-1v1h1V8zm.5-.5a.5.5 0 01-.5.5v1A1.5 1.5 0 005 7.5H4zM3.5 7a.5.5 0 01.5.5h1A1.5 1.5 0 003.5 6v1zM6 6.5v4h1v-4H6zm.5 4.5h1v-1h-1v1zM9 9.5v-2H8v2h1zM7.5 6h-1v1h1V6zM9 7.5A1.5 1.5 0 007.5 6v1a.5.5 0 01.5.5h1zM7.5 11A1.5 1.5 0 009 9.5H8a.5.5 0 01-.5.5v1zM10 6v5h1V6h-1zm.5 1H13V6h-2.5v1zm0 2H12V8h-1.5v1zM2 5V1.5H1V5h1zm11-1.5V5h1V3.5h-1zM2.5 1h8V0h-8v1zm7.646-.146l3 3 .708-.708-3-3-.708.708zM2 1.5a.5.5 0 01.5-.5V0A1.5 1.5 0 001 1.5h1zM1 12v1.5h1V12H1zm1.5 3h10v-1h-10v1zM14 13.5V12h-1v1.5h1zM12.5 15a1.5 1.5 0 001.5-1.5h-1a.5.5 0 01-.5.5v1zM1 13.5A1.5 1.5 0 002.5 15v-1a.5.5 0 01-.5-.5H1z"
              fill="currentColor"
            ></path>
          </svg>
          Exporter PDF
        </a>
      </li>
      <li (click)="onSelect('exportPPT')" *ngIf="isExportPPT">
        <a>
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.755 3.5a7 7 0 110 8M2.5 10V8.5m0 0v-3H5a1.5 1.5 0 110 3H2.5zm-1-5h6a1 1 0 011 1v6a1 1 0 01-1 1h-6a1 1 0 01-1-1v-6a1 1 0 011-1z"
              stroke="currentColor"
            ></path>
          </svg>
          Exporter PPT
        </a>
      </li>
      <li (click)="onSelect('exportEXL')" *ngIf="isExportEXL">
        <a>
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.5 3.5v-2a1 1 0 011-1h10a1 1 0 011 1v12a1 1 0 01-1 1h-10a1 1 0 01-1-1v-2m0-6l4 4m-4 0l4-4m-5-2h6a1 1 0 011 1v6a1 1 0 01-1 1h-6a1 1 0 01-1-1v-6a1 1 0 011-1z"
              stroke="currentColor"
            ></path>
          </svg>
          Exporter Excel</a
        >
      </li>
      <li (click)="onSelect('exportWRD')" *ngIf="isExportWRD">
        <a>
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.5 9.5l-.485.121a.5.5 0 00.901.156L3.5 9.5zm1-1.5l.416-.277a.5.5 0 00-.832 0L4.5 8zm1 1.5l-.416.277a.5.5 0 00.901-.156L5.5 9.5zM1.5 4h6V3h-6v1zm6.5.5v6h1v-6H8zM7.5 11h-6v1h6v-1zM1 10.5v-6H0v6h1zm.5.5a.5.5 0 01-.5-.5H0A1.5 1.5 0 001.5 12v-1zm6.5-.5a.5.5 0 01-.5.5v1A1.5 1.5 0 009 10.5H8zM7.5 4a.5.5 0 01.5.5h1A1.5 1.5 0 007.5 3v1zm-6-1A1.5 1.5 0 000 4.5h1a.5.5 0 01.5-.5V3zm.515 2.621l1 4 .97-.242-1-4-.97.242zm1.901 4.156l1-1.5-.832-.554-1 1.5.832.554zm.168-1.5l1 1.5.832-.554-1-1.5-.832.554zm1.901 1.344l1-4-.97-.242-1 4 .97.242zM3 3.5v-2H2v2h1zM3.5 1h10V0h-10v1zm10.5.5v12h1v-12h-1zM13.5 14h-10v1h10v-1zM3 13.5v-2H2v2h1zm.5.5a.5.5 0 01-.5-.5H2A1.5 1.5 0 003.5 15v-1zm10.5-.5a.5.5 0 01-.5.5v1a1.5 1.5 0 001.5-1.5h-1zM13.5 1a.5.5 0 01.5.5h1A1.5 1.5 0 0013.5 0v1zM3 1.5a.5.5 0 01.5-.5V0A1.5 1.5 0 002 1.5h1z"
              fill="currentColor"
            ></path>
          </svg>
          Exporter Word</a
        >
      </li>
    </div>

    <div *ngIf="isUpdate || isDelete || isDeleteAll" class="divide">
      <ng-container *ngIf="isUpdate">
        <li (click)="onSelect('update')">
          <a>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
              />
            </svg>

            Modifier
          </a>
        </li>
      </ng-container>
      <li
        *ngIf="isDelete"
        nz-popconfirm
        [nzPopconfirmTitle]="deleteType"
        nzPopconfirmPlacement="bottom"
        (nzOnConfirm)="onSelect('delete')"
        (nzOnCancel)="onCancel()"
        class="text-sm tracking-wider"
      >
        <a class="text-red-600"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
            />
          </svg>
          Supprimer</a
        >
      </li>
      <li
        *ngIf="isDeleteAll"
        nz-popconfirm
        [nzPopconfirmTitle]="deleteAllType"
        nzPopconfirmPlacement="bottom"
        (nzOnConfirm)="onSelect('deleteAll')"
        (nzOnCancel)="onCancel()"
        class="text-sm text-red-600 tracking-wider"
      >
        <a>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
            />
          </svg>

          Tout supprimer</a
        >
      </li>
    </div>
  </ul>

  <!-- <button
    nz-dropdown
    [nzDropdownMenu]="moreMenu"
    nzTrigger="click"
    nzPlacement="bottomRight"
    class="btn-icon group/button bg-opacity-4"
  >
    <svg
      class="iconSvg"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 28 28"
      stroke-width="1.5"
      stroke="currentColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
      />
    </svg>
  </button>

  <nz-dropdown-menu #moreMenu="nzDropdownMenu">
    <ul nz-menu>
      <ng-container *ngTemplateOutlet="expansion"></ng-container>

      <ng-container *ngIf="isUpdate">
        <li nz-menu-item (click)="onSelect('update')">
          <nz-row nzAlign="middle" nzGutter="0" nzJustify="start">
            <nz-col nzSpan="4"><mat-icon>edit</mat-icon></nz-col>
            <nz-col nzSpan="4"></nz-col>
            <nz-col nzSpan="16">Modifier</nz-col>
          </nz-row>
        </li>
      </ng-container>
      <ng-container *ngIf="isShare">
        <li nz-menu-item (click)="onSelect('partage')">
          <nz-row nzAlign="middle" nzGutter="0" nzJustify="start">
            <nz-col nzSpan="4"><mat-icon>share</mat-icon></nz-col>
            <nz-col nzSpan="4"></nz-col>
            <nz-col nzSpan="16">Partager</nz-col>
          </nz-row>
        </li>
      </ng-container>
      <ng-container *ngIf="isRelance">
        <li nz-menu-item (click)="onSelect('relance')">
          <nz-row nzAlign="middle" nzGutter="0" nzJustify="start">
            <nz-col nzSpan="4"><mat-icon>event_repeat</mat-icon></nz-col>
            <nz-col nzSpan="4"></nz-col>
            <nz-col nzSpan="16">Relancer</nz-col>
          </nz-row>
        </li>
      </ng-container>
      <li nz-menu-item (click)="onSelect('copy')" *ngIf="isCopy">
        <nz-row nzAlign="middle" nzGutter="0" nzJustify="start">
          <nz-col nzSpan="4"><mat-icon>content_copy</mat-icon></nz-col>
          <nz-col nzSpan="4"></nz-col>
          <nz-col nzSpan="16">Dupliquer</nz-col>
        </nz-row>
      </li>

      <li nz-menu-divider *ngIf="isUpdate || isCopy"></li>
      <li nz-menu-item (click)="onSelect('export')" *ngIf="isExport">
        <nz-row nzAlign="middle" nzGutter="0" nzJustify="start">
          <nz-col nzSpan="4"><i nz-icon nzType="edit"></i></nz-col>
          <nz-col nzSpan="4"></nz-col>
          <nz-col nzSpan="16">Exporter</nz-col>
        </nz-row>
      </li>
      <li nz-menu-item (click)="onSelect('exportPDF')" *ngIf="isExportPDF">
        <nz-row nzAlign="middle" nzGutter="0" nzJustify="start">
          <nz-col nzSpan="4"><i nz-icon nzType="file-pdf"></i></nz-col>
          <nz-col nzSpan="4"></nz-col>
          <nz-col nzSpan="16">Exporter PDF</nz-col>
        </nz-row>
      </li>
      <li nz-menu-item (click)="onSelect('exportPPT')" *ngIf="isExportPPT">
        <nz-row nzAlign="middle" nzGutter="0" nzJustify="start">
          <nz-col nzSpan="4"><i nz-icon nzType="file-ppt"></i></nz-col>
          <nz-col nzSpan="4"></nz-col>
          <nz-col nzSpan="16">Exporter PPT</nz-col>
        </nz-row>
      </li>
      <li nz-menu-item (click)="onSelect('exportEXL')" *ngIf="isExportEXL">
        <nz-row nzAlign="middle" nzGutter="0" nzJustify="start">
          <nz-col nzSpan="4"><i nz-icon nzType="file-excel"></i></nz-col>
          <nz-col nzSpan="4"></nz-col>
          <nz-col nzSpan="16">Exporter EXL</nz-col>
        </nz-row>
      </li>
      <li nz-menu-item (click)="onSelect('exportWRD')" *ngIf="isExportWRD">
        <nz-row nzAlign="middle" nzGutter="0" nzJustify="start">
          <nz-col nzSpan="4"><i nz-icon nzType="file-word"></i></nz-col>
          <nz-col nzSpan="4"></nz-col>
          <nz-col nzSpan="16">Exporter WRD</nz-col>
        </nz-row>
      </li>
      <li nz-menu-divider *ngIf="isDelete"></li>
      <li
        *ngIf="isDelete"
        nz-menu-item
        nzDanger
        nz-popconfirm
        [nzPopconfirmTitle]="deleteType"
        nzPopconfirmPlacement="bottom"
        (nzOnConfirm)="onSelect('delete')"
        (nzOnCancel)="onCancel()"
      >
        <nz-row nzAlign="middle" nzGutter="4" nzJustify="start">
          <nz-col nzSpan="4"><i nz-icon nzType="delete"></i></nz-col>
          <nz-col nzSpan="4"></nz-col>
          <nz-col nzSpan="16">Supprimer</nz-col>
        </nz-row>
      </li>
      <li
        *ngIf="isDeleteAll"
        nz-menu-item
        nzDanger
        nz-popconfirm
        [nzPopconfirmTitle]="deleteAllType"
        nzPopconfirmPlacement="bottom"
        (nzOnConfirm)="onSelect('deleteAll')"
        (nzOnCancel)="onCancel()"
      >
        <nz-row nzAlign="middle" nzGutter="4" nzJustify="start">
          <nz-col nzSpan="4"><i nz-icon nzType="delete"></i></nz-col>
          <nz-col nzSpan="4"></nz-col>
          <nz-col nzSpan="16">Tout supprimer</nz-col>
        </nz-row>
      </li>
    </ul>
  </nz-dropdown-menu> -->
</div>
