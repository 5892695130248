import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, of, Subject, takeUntil } from 'rxjs';
import { ContratState } from '../../store/contrat.reducer';
import * as fromContratAction from '../../store/contrat.actions';
import * as fromContratSelector from '../../store/contrat.selectors';

@Component({
  selector: 'app-contrat-form-evolution',
  templateUrl: './contrat-form-evolution.component.html',
  styleUrls: ['./contrat-form-evolution.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContratFormEvolutionComponent
  implements OnInit, OnChanges, OnDestroy
{
  subscribe = new Subject();
  evolutionTitle: string =
    'Quelles perspectives sont possibles à la fin du contrat ?';
  contratForm: FormGroup = this.fb.group({});
  evolution = new FormControl<{ code: string; libelle: string }>({
    code: '',
    libelle: '',
  });
  evolutionCondition = new FormControl<string>('');

  evolutions_types$: Observable<any[]> = of([]);

  @Input() evolutionInput: any;
  @Input() evolutionConditionInput: any;

  @Output() onCurrentChanges = new EventEmitter();
  constructor(
    private fb: FormBuilder,
    private contratStore: Store<ContratState>
  ) {}

  ngOnInit(): void {
    this.formCreate();
    this.getReferentielEvolution();
    this.load_evolution_contrat();
    this.onChanges();
  }
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  formCreate(): void {
    this.contratForm = this.fb.group({
      evolution: this.evolution,
      evolutionCondition: this.evolutionCondition,
    });
  }

  getReferentielEvolution(): void {
    this.evolutions_types$ = this.contratStore.select(
      fromContratSelector.evolutions
    );
  }
  load_evolution_contrat(): void {
    this.contratStore.dispatch(fromContratAction.loadEvolutionTypes());
  }

  onUpdateForm(evolution: any, condition: any): void {
    this.contratForm.patchValue({
      evolution: evolution,
      evolutionCondition: condition,
    });
  }

  onChanges(): void {
    this.contratForm.valueChanges
      ?.pipe(takeUntil(this.subscribe))
      .subscribe((valuesForm) => {
        this.onCurrentChanges.emit(valuesForm);
      });
  }
}
