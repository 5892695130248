import { createAction, props } from '@ngrx/store';

import {
  MetiersDomaineSuggestion,
  MetiersSuggestion,
} from './metiers-suggestion.model';

export const loadMetiersSuggestions = createAction(
  '[MetiersSuggestion/COMPONENTS] Load MetiersSuggestions',
  props<{ naf: string }>()
);
export const loadMetiersSuggestionsSuccess = createAction(
  '[MetiersSuggestion/API] Load MetiersSuggestions Success',
  props<{ suggestions: MetiersSuggestion[]; total: number }>()
);
export const loadMetiersSuggestionsFailure = createAction(
  '[MetiersSuggestion/API] Load MetiersSuggestions Failure',
  props<{ error: any }>()
);

export const loadDomaineMetiersSuggestions = createAction(
  '[MetiersSuggestion/COMPONENTS] Load Domaine MetiersSuggestions',
  props<{ code: string }>()
);
export const loadDomaineMetiersSuggestionsSuccess = createAction(
  '[MetiersSuggestion/API] Load Domaine MetiersSuggestions Success',
  props<{ suggestions: MetiersDomaineSuggestion[]; total: number }>()
);
export const loadDomaineMetiersSuggestionsFailure = createAction(
  '[MetiersSuggestion/API] Load Domaine MetiersSuggestions Failure',
  props<{ error: any }>()
);

//APPELATIONS
export const loadMetierAppelations = createAction(
  '[MetiersSuggestion/COMPONENTS] Load Metiers Appelations',
  props<{ code: string }>()
);
export const loadMetierAppelationsSuccess = createAction(
  '[MetiersSuggestion/API] Load Metiers Appelations Success',
  props<{ appelations: MetiersDomaineSuggestion[]; total: number }>()
);
export const loadMetierAppelationsFailure = createAction(
  '[MetiersSuggestion/API] Load Metiers Appelations Failure',
  props<{ error: any }>()
);

//METIER DETAILS
export const loadMetierDetails = createAction(
  '[MetiersSuggestion/COMPONENTS] Load MetiersDetails',
  props<{ rom: string }>()
);
export const loadMetierDetailsSuccess = createAction(
  '[MetiersSuggestion/API] Load MetiersDetails Success',
  props<{ metier: any }>()
);
export const loadMetierDetailsFailure = createAction(
  '[MetiersSuggestion/API] Load MetiersDetails Failure',
  props<{ error: any }>()
);
