<div class="w-full h-full space-y-2" *ngIf="source | size; else noData">
  <div [ngSwitch]="displayView$ | async">
    <div *ngSwitchCase="'suivi'">
      <app-suivi-item
        *ngIf="currentSuivi$ | async as currentSuivi"
        [size]="sizeItem"
        [suivi]="currentSuivi"
        (update)="onSetUpdateSuivi($event)"
        (cancel)="onCancel()"
      ></app-suivi-item>
    </div>
    <div *ngSwitchCase="'suiviUpdate'">
      <app-item-event
        [item]="currentSuivi$ | async"
        [updateActivity]="true"
        [size]="sizeItem"
        (update)="updateEvent($event)"
        (cancel)="onCancel()"
      ></app-item-event>
    </div>
    <div *ngSwitchDefault>
      <div class="w-full flex items-end justify-end">
        <div>
          <button
            *ngIf="isCanAdd"
            nz-button
            nz-tooltip
            nzBlock
            nzTooltipPlacement="bottom"
            nzTooltipTitle="Ajouter un suivi"
            [nzTooltipMouseEnterDelay]="0.5"
            class="btn_add"
            (click)="onNewEvent()"
          >
            Nouveau
          </button>
        </div>
        <div>
          <app-button-dropdown
            [isDeleteAll]="true"
            (select)="onSelectDropdownHeader($event)"
          ></app-button-dropdown>
        </div>
      </div>

      <nz-table
        #filterTable
        [nzData]="source"
        nzTableLayout="fixed"
        nzSize="small"
        [nzScroll]="scroll"
        [nzLoadingDelay]="2"
        [nzBordered]="false"
        [nzOuterBordered]="false"
        class="table-auto"
      >
        <thead>
          <tr>
            <th
              nzLeft
              [nzFilters]="filterByTypes"
              [nzFilterFn]="filterByTypeFn"
              nzWidth="100px"
              class="text-sm tracking-wider uppercase"
            >
              Type
            </th>
            <th nzLeft class="text-sm tracking-wider uppercase">Objet</th>
            <th class="text-sm tracking-wider uppercase">Titre</th>
            <th
              [nzSortFn]="sortFnByDate"
              nzWidth="100px"
              class="text-sm tracking-wider uppercase"
            >
              Date
            </th>
            <th nzWidth="100px" class="text-sm tracking-wider uppercase">
              Référent
            </th>
            <th nzRight nzWidth="50px"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let data of filterTable.data"
            (click)="$event.stopPropagation(); onSelect(data, selectTyped)"
          >
            <td nzLeft nzWidth="100px">
              <app-suivi-type [suivi]="data"></app-suivi-type>
            </td>
            <td nzLeft class="text-sm tracking-wider">
              {{ data?.object | carLimitation : 20 }}
            </td>
            <td class="text-sm tracking-wider">
              {{ data?.title | carLimitation : 20 }}
            </td>

            <td nzWidth="100px" class="text-sm tracking-wider">
              {{ data?.dateStart | dateFr : "small" }}
            </td>
            <td nzWidth="100px">
              <app-avatar-group
                [source]="[data.auteur]"
                [size]="30"
              ></app-avatar-group>
            </td>

            <td nzRight nzWidth="50px">
              <div class="flex items-center">
                <app-button-dropdown
                  [direction]="'horizontal'"
                  (click)="$event.stopPropagation()"
                  [isDelete]="true"
                  [deleteType]="'Voulez-vous supprimer ce suivi ?'"
                  (select)="onSelectDropdown($event, data)"
                ></app-button-dropdown>
              </div>
            </td>
          </tr>
        </tbody>
      </nz-table>
    </div>
  </div>
</div>

<ng-template #noData>
  <app-no-result
    (click)="$event.stopPropagation()"
    [icon]="'edit_note'"
    [addtitle]="'Ajouter une note'"
    [description]="''"
    [notitle]="'Aucune note ajoutée'"
    [isButton]="isCanAdd"
    (new)="onNewEvent()"
  ></app-no-result>
</ng-template>
<ng-template #suiviTitleForm>
  <app-header-title
    [icon]="'article'"
    [title]="newCardTitle"
  ></app-header-title>
</ng-template>
