import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  NzNotificationPlacement,
  NzNotificationService,
} from 'ng-zorro-antd/notification';
import { catchError, debounceTime, map, mergeMap, of } from 'rxjs';
import { GeoTerritoireService } from '../services/geo-territoire.service';
import * as fromGeoTerritoireActions from './geo-territoire.actions';

const NOTIFICATION_PLACEMENT: NzNotificationPlacement = 'bottomRight';

@Injectable()
export class GeoTerritoireEffects {
  loadRegions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromGeoTerritoireActions.loadGeoTerritoireRegions),
      debounceTime(1000),
      mergeMap(() =>
        this.geoTerritoireService.getGeoRegions().pipe(
          map((response) => {
            const regions = response.data.geoRegions;

            return fromGeoTerritoireActions.loadGeoTerritoireRegionsSuccess({
              regions,
            });
          }),
          catchError((error) => {
            this.notificationError(error);
            return of(
              fromGeoTerritoireActions.loadGeoTerritoireRegionsFailure({
                error,
              })
            );
          })
        )
      )
    )
  );

  loadDepartements$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromGeoTerritoireActions.loadGeoTerritoireDepartements),
      debounceTime(1000),

      mergeMap((action) =>
        this.geoTerritoireService.getGeoDepartements(action.code).pipe(
          map((response) => {
            const region = response.data.geoRegion;

            return fromGeoTerritoireActions.loadGeoTerritoireDepartementsSuccess(
              {
                region,
              }
            );
          }),
          catchError((error) => {
            this.notificationError(error);
            return of(
              fromGeoTerritoireActions.loadGeoTerritoireDepartementsFailure({
                error,
              })
            );
          })
        )
      )
    )
  );

  loadCommunes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromGeoTerritoireActions.loadGeoTerritoireCommunes),
      debounceTime(1000),

      mergeMap((action) =>
        this.geoTerritoireService.getGeoCommunes(action.code).pipe(
          map((response) => {
            const communes = response.data.communesByDepartement;

            return fromGeoTerritoireActions.loadGeoTerritoireCommunesSuccess({
              communes,
            });
          }),
          catchError((error) => {
            this.notificationError(error);
            return of(
              fromGeoTerritoireActions.loadGeoTerritoireCommunesFailure({
                error,
              })
            );
          })
        )
      )
    )
  );

  loadGeoDistance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromGeoTerritoireActions.loadGeoDistance),
      mergeMap((action) =>
        this.geoTerritoireService.getGeoDistance(action.adresses).pipe(
          map((response) => {
            const distance = response.data.geoDistance;

            return fromGeoTerritoireActions.loadGeoDistanceSuccess({
              distance,
            });
          }),
          catchError((error) => {
            this.notificationError(error);
            return of(
              fromGeoTerritoireActions.loadGeoDistanceFailure({
                error,
              })
            );
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private notification: NzNotificationService,
    private geoTerritoireService: GeoTerritoireService
  ) {}

  notificationError(error: any): void {
    this.notification.error('Erreur', error.message, {
      nzDuration: 6000,
      nzKey: 'LOAD REGIONS',
      nzPlacement: NOTIFICATION_PLACEMENT,
    });
  }
}
