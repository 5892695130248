import { BehaviorSubject, Observable, of } from 'rxjs';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-projet-professionnel-card',
  templateUrl: './projet-professionnel-card.component.html',
  styleUrls: ['./projet-professionnel-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjetProfessionnelCardComponent implements OnInit, OnChanges {
  chartType = new BehaviorSubject<any>(null);
  employablity: string = 'Employabilité';
  employablityValue: number = 0;
  chartMax: number = 10;
  @Input() item: any;
  @Output() delete: EventEmitter<any> = new EventEmitter(false);
  @Output() update: EventEmitter<any> = new EventEmitter(false);

  constructor() {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {
    this.setChart(changes.item.currentValue);
  }

  setChart(item: any): void {
    //console.log(item);
    const competencesCleDeBaseSize: number =
      item.appellation.competencesCleDeBaseSize;
    const competencesCleSpecifiquesSize: number =
      item.appellation.competencesCleSpecifiques > 10
        ? 10
        : item.appellation.competencesCleSpecifiques;
    const accesSize: number = item.accesSize > 10 ? 10 : item.accesSize;
    const skills: number =
      item.softskills.skills.length > 10 ? 10 : item.softskills.skills.length;
    const mobilitesMetiers: number =
      item.mobilitesProchesVersMetiers.lenght > 10
        ? 10
        : item.mobilitesProchesVersMetiers.lenght;
    const formations = [];

    const title = '';
    const isLegend = false;
    const legend: any = [];
    const type = 'radar';
    const data = [
      {
        value: [0, 0, 0, 0, 0, 0],
        name: '',
      },
      {
        value: [
          accesSize,
          competencesCleDeBaseSize,
          competencesCleSpecifiquesSize,
          skills,
          mobilitesMetiers,
          0,
        ],
        name: '',
      },
    ];
    const serieName = '';
    const radius: number = 80;
    const center = ['50%', '48%'];
    const height: string = '165px';

    const indicator = [
      { name: '', max: this.chartMax },
      { name: '', max: this.chartMax },
      { name: '', max: this.chartMax },
      { name: '', max: this.chartMax },
      { name: '', max: this.chartMax },
      { name: '', max: this.chartMax },
    ];
    const options = {
      title,
      type,
      data,
      isLegend,
      legend,
      indicator,
      serieName,
      radius,
      center,
      height,
    };
    this.chartType.next(options);
    this.employablityValue = 0;
  }

  onCancel(): void {}
  onUpdate(): void {}

  onDelete(item: any): void {
    this.delete.emit(item);
  }
}
