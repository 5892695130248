import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
  FormArray,
  Validators,
} from '@angular/forms';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-operation-note-participant-form',
  templateUrl: './operation-note-participant-form.component.html',
  styleUrls: ['./operation-note-participant-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationNoteParticipantFormComponent implements OnInit {
  result$: Observable<any> = of(null);
  participantForm: UntypedFormGroup = this.fb.group({});
  firstName: UntypedFormControl = new UntypedFormControl();
  lastName: UntypedFormControl = new UntypedFormControl();
  function: UntypedFormControl = new UntypedFormControl();
  quality: UntypedFormControl = new UntypedFormControl();
  email: UntypedFormControl = new UntypedFormControl();
  phoneNumber: UntypedFormControl = new UntypedFormControl();
  entiteTitle: UntypedFormControl = new UntypedFormControl();
  moduleTitle: UntypedFormControl = new UntypedFormControl();

  @Output() add = new EventEmitter<any>(false);

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.form();
  }

  form(): void {
    this.formInit();
    this.formCreate();
  }
  formInit(): void {
    this.moduleTitle = new UntypedFormControl('');
    this.entiteTitle = new UntypedFormControl('');
    this.email = new UntypedFormControl();
    this.phoneNumber = new UntypedFormControl();
    this.firstName = new UntypedFormControl();
    this.lastName = new UntypedFormControl();
    this.function = new UntypedFormControl();
  }
  formCreate(): void {
    this.participantForm = this.fb.group({
      firstName: this.firstName,
      lastName: this.lastName,
      function: this.function,

      module: this.fb.group({
        title: this.moduleTitle,
      }),
      entite: this.fb.group({
        title: this.entiteTitle,
      }),
      coordonnees: this.fb.group({
        email: this.email,
        phoneNumber: this.phoneNumber,
      }),
    });
  }

  onSearch(): void {}
  onClear(): void {
    this.participantForm.reset();
  }
  onAdd(): void {
    const formValue = this.participantForm.value;
    this.add.emit(formValue);
  }
}
