// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    apiKey: 'AIzaSyDmmt03OJElonFX5GY8EAZEG-miVUStGRY',
    authDomain: 'solusprojet.firebaseapp.com',
    databaseURL: 'https://solusprojet.firebaseio.com',
    projectId: 'solusprojet',
    storageBucket: 'solusprojet.appspot.com',
    messagingSenderId: '674834287898',
    appId: '1:674834287898:web:70f46e7a0d9f1a747eb0a3',
    measurementId: 'G-EK099WW7RQ',
  },
  graphql:
    'https://europe-west3-solusprojet.cloudfunctions.net/graphFire-graphFire',

  production: false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
