import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  ParticipantSuiviState,
  participantSuivisFeatureKey,
  selectAll,
} from './participant-suivi.reducer';

export const selectParticipantSuivisState =
  createFeatureSelector<ParticipantSuiviState>(participantSuivisFeatureKey);

export const suivis = createSelector(selectParticipantSuivisState, selectAll);
export const suivi = createSelector(
  selectParticipantSuivisState,
  (state: ParticipantSuiviState) => state.suivi
);
export const loading = createSelector(
  selectParticipantSuivisState,
  (state: ParticipantSuiviState) => state.loading
);

export const error = createSelector(
  selectParticipantSuivisState,
  (state: ParticipantSuiviState) => state.error
);
