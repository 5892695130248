import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Candidature } from './candidature.model';
import * as CandidatureActions from './candidature.actions';

export const candidaturesFeatureKey = 'candidatures';

export interface CandidatureState extends EntityState<any> {
  loading: boolean;
  success: string;
  error: any;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>();

export const initialState: CandidatureState = adapter.getInitialState({
  loading: false,
  success: '',
  error: undefined,
});

export const reducer = createReducer(
  initialState,

  //Exporter les candidatures au format PDF
  on(CandidatureActions.exportCandidaturesPdf, (state, action) => {
    return {
      ...state,
      loading: true,
      error: undefined,
    };
  }),
  on(CandidatureActions.exportCandidaturesPdfSuccess, (state, action) => {
    return {
      ...state,
      success: action.success,
      loading: false,
      error: undefined,
    };
  }),
  on(CandidatureActions.exportCandidaturesPdfFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      success: '',
      error: action.error,
    };
  }),

  //Exporter les candidatures au format Excel
  on(CandidatureActions.exportCandidaturesExcel, (state, action) => {
    return {
      ...state,
      loading: true,
      error: undefined,
    };
  }),
  on(CandidatureActions.exportCandidaturesExcelSuccess, (state, action) => {
    return {
      ...state,
      success: action.success,
      loading: false,
      error: undefined,
    };
  }),
  on(CandidatureActions.exportCandidaturesExcelFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      success: '',
      error: action.error,
    };
  }),

  //Exporter les candidatures au format WORD
  on(CandidatureActions.exportCandidaturesWord, (state, action) => {
    return {
      ...state,
      loading: true,
      error: undefined,
    };
  }),
  on(CandidatureActions.exportCandidaturesWordSuccess, (state, action) => {
    return {
      ...state,
      success: action.success,
      loading: false,
      error: undefined,
    };
  }),
  on(CandidatureActions.exportCandidaturesWordFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      success: '',
      error: action.error,
    };
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
