<app-card-actions *ngIf="participant">
  <ul nz-menu [nzMode]="'inline'">
    <li nz-menu-item (click)="onSelect('details')">
      <mat-icon>person</mat-icon>
      <span>Profil</span>
    </li>

    <li nz-menu-item (click)="onSelect('suivis')">
      <mat-icon>article</mat-icon>
      <span>Suivis</span>
    </li>

    <li nz-menu-item (click)="onSelect('documents')">
      <mat-icon>insert_drive_file</mat-icon>
      <span>Documents</span>
    </li>
    <li nz-menu-item (click)="onSelect('experiences')">
      <mat-icon>work_history</mat-icon>
      <span>Expériences</span>
    </li>
    <li nz-menu-item (click)="onSelect('projets')">
      <mat-icon> analytics </mat-icon>
      <span>Projets</span>
    </li>
    <li nz-menu-item (click)="onSelect('projetsProfessionnels')">
      <mat-icon>business_center</mat-icon>
      <span>Projets professionnels</span>
    </li>
    <li nz-menu-item (click)="onSelect('prescripteur')">
      <mat-icon>domain</mat-icon>
      <span>Prescripteurs</span>
    </li>
    <!-- <li nz-menu-item (click)="onSelect('aides')">
      <mat-icon>volunteer_activism</mat-icon>
      <span>Aides</span>
    </li> -->
    <!-- <li nz-menu-item (click)="onSelect('orientation')">
      <mat-icon>route</mat-icon>
      <span>Orientations</span>
    </li> -->
    <!-- <li nz-menu-item (click)="onSelect('dashboard')">
      <mat-icon>pie_chart</mat-icon>
      <span>Tableau de bord</span>
    </li> -->
  </ul>
</app-card-actions>
