import { Injectable } from '@angular/core';
import { EMPTY, Observable, from, of } from 'rxjs';
import {
  Document,
  Packer,
  Paragraph,
  TextRun,
  Table,
  TableRow,
  ImageRun,
  TableCell,
  Footer,
  Header,
  HeadingLevel,
  PageNumber,
  ParagraphChild,
} from 'docx';
import { saveAs } from 'file-saver';
import { CandidatureExport } from '../store/candidature.model';
import { snakeCase } from 'lodash';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
@Injectable({
  providedIn: 'root',
})
export class CandidatureExportWordService {
  constructor() {}

  onExportCandidatureWord(dataExport: CandidatureExport): Observable<any> {
    try {
      const { candidats, fiche, user, isAnonyme } = dataExport;

      if (!candidats.length || !fiche || !user) EMPTY;

      const { entite, mission, contacts, recherche } = fiche;
      const { metier, contrat, operation } = mission;

      const operationTitle: string = operation.denomination.substring(0, 10);
      const entiteTitle: string =
        entite?.uniteLegale?.denominationUniteLegale.substring(0, 10);

      const metierTitle: string = metier?.metier?.libelle;

      const fileName: string = `${operationTitle}_${entiteTitle}`;
      const textFontStandard = { font: 'Calibri', size: 20, bold: true };
      const currentDate = format(new Date(Date.now()), 'dd/MM/yyyy', {
        locale: fr,
      });

      //  Ajouter chaque candidat dans une ligne du tableau
      const candidatsRows: TableRow[] = candidats?.map((candidat: any) => {
        const elRow: TableRow = new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: `${candidat?.person?.displayName}`,
                      ...textFontStandard,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: `${candidat?.person?.age || ''}`,
                      ...textFontStandard,
                      bold: false,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: `${candidat?.person?.coordonnees?.mobile || ''}`,
                      ...textFontStandard,
                      bold: false,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: `${candidat?.person?.coordonnees?.email || ''}`,
                      ...textFontStandard,
                      bold: false,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: `${
                        candidat?.prescripteur?.etablissement?.uniteLegale
                          ?.denominationUniteLegale || 'Non renseigné'
                      }`,
                      ...textFontStandard,
                      bold: false,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: `${
                        candidat?.prescripteur?.etablissement
                          ?.adresseEtablissement?.commune?.nom ||
                        'Non renseigné'
                      }`,
                      ...textFontStandard,
                      bold: false,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: `${candidat?.prescripteur?.displayName}`,
                      ...textFontStandard,
                      bold: false,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: `${
                        candidat?.prescripteur?.coordonnees?.mobile ||
                        'Non renseigné'
                      }`,
                      ...textFontStandard,
                      bold: false,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: `${
                        candidat?.prescripteur?.coordonnees?.email ||
                        'Non renseigné'
                      }`,
                      ...textFontStandard,
                      bold: false,
                    }),
                  ],
                }),
              ],
            }),
          ],
        });

        return elRow;
      });

      // Ajouter un tableau de données pour chaque personne
      const candidatsTables: Table = new Table({
        rows: [
          new TableRow({
            tableHeader: true,
            cantSplit: true,
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Identité',
                        ...textFontStandard,
                        size: 20,
                        bold: false,
                      }),
                    ],
                  }),
                ],
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Âge',
                        ...textFontStandard,
                        size: 20,
                        bold: false,
                      }),
                    ],
                  }),
                ],
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Mobile',
                        ...textFontStandard,
                        size: 20,
                        bold: false,
                      }),
                    ],
                  }),
                ],
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'E-mail',
                        ...textFontStandard,
                        size: 20,
                        bold: false,
                      }),
                    ],
                  }),
                ],
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Prescripteur',
                        ...textFontStandard,
                        size: 20,
                        bold: false,
                      }),
                    ],
                  }),
                ],
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Prescripteur commune',
                        ...textFontStandard,
                        size: 20,
                        bold: false,
                      }),
                    ],
                  }),
                ],
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Référent',
                        ...textFontStandard,
                        size: 20,
                        bold: false,
                      }),
                    ],
                  }),
                ],
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Référent mobile',
                        ...textFontStandard,
                        size: 20,
                        bold: false,
                      }),
                    ],
                  }),
                ],
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Référent e-mail',
                        ...textFontStandard,
                        size: 20,
                        bold: false,
                      }),
                    ],
                  }),
                ],
              }),
            ],
          }),
          ...candidatsRows,
        ],
      });

      // Ajouter chaque contact dans une ligne
      const contactsRows: TableRow[] = contacts.map((candidat: any) => {
        const el = candidat.person;
        const elRow: TableRow = new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: `${el?.displayName}`,
                      ...textFontStandard,
                    }),
                  ],
                }),
              ],
              width: { size: 300 },
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: `${el?.fonction || ''}`,
                      ...textFontStandard,
                      bold: false,
                    }),
                  ],
                }),
              ],
              width: { size: 300 },
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: `${el?.role || ''}`,
                      ...textFontStandard,
                      bold: false,
                    }),
                  ],
                }),
              ],
              width: { size: 200 },
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: `${el?.coordonnees?.mobile || ''}`,
                      ...textFontStandard,
                      bold: false,
                    }),
                  ],
                }),
              ],
              width: { size: 200 },
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: `${el?.coordonnees?.email || ''}`,
                      ...textFontStandard,
                      bold: false,
                    }),
                  ],
                }),
              ],
              width: { size: 400 },
            }),
          ],
        });

        return elRow;
      });

      //Ajouter un tableau de données de chaque personne
      const contactsTables: Table = new Table({
        rows: [
          new TableRow({
            tableHeader: true,
            cantSplit: true,
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Identité',
                        ...textFontStandard,
                        size: 20,
                        bold: false,
                      }),
                    ],
                  }),
                ],
                width: { size: 400 },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Fonction',
                        ...textFontStandard,
                        size: 20,
                        bold: false,
                      }),
                    ],
                  }),
                ],
                width: { size: 400 },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Rôle',
                        ...textFontStandard,
                        size: 20,
                        bold: false,
                      }),
                    ],
                  }),
                ],
                width: { size: 400 },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Mobile',
                        ...textFontStandard,
                        size: 20,
                        bold: false,
                      }),
                    ],
                  }),
                ],
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'E-mail',
                        ...textFontStandard,
                        size: 20,
                        bold: false,
                      }),
                    ],
                  }),
                ],
                width: { size: 400 },
              }),
            ],
          }),
          ...contactsRows,
        ],
      });

      //Ajouter le tableau des conventions collectives
      const conventionsCollectivesRows: TableRow[] = entite
        ?.conventionsCollectives?.conventions?.length
        ? entite?.conventionsCollectives?.conventions?.map((el: any) => {
            const elRow: TableRow = new TableRow({
              children: [
                new TableCell({
                  children: [
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: `${el?.id}`,
                          ...textFontStandard,
                          size: 18,
                          bold: false,
                        }),
                      ],
                    }),
                  ],
                  width: { size: 200 },
                }),
                new TableCell({
                  children: [
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: `${el?.shortTitle || ''}`,
                          ...textFontStandard,
                          bold: false,
                        }),
                      ],
                    }),
                  ],
                  width: { size: 300 },
                }),
                new TableCell({
                  children: [
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: `${el?.url || ''}`,
                          ...textFontStandard,
                          bold: false,
                        }),
                      ],
                    }),
                  ],
                  width: { size: 300 },
                }),
              ],
            });

            return elRow;
          })
        : [];

      const conventionsCollectivesTables: Table = new Table({
        rows: [
          new TableRow({
            tableHeader: true,
            cantSplit: true,
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Identifiant',
                        ...textFontStandard,
                        bold: false,
                      }),
                    ],
                  }),
                ],
                width: { size: 200 },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Titre',
                        ...textFontStandard,
                        bold: false,
                      }),
                    ],
                  }),
                ],
                width: { size: 300 },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'URL',
                        ...textFontStandard,
                        bold: false,
                      }),
                    ],
                  }),
                ],
                width: { size: 300 },
              }),
            ],
          }),
          ...conventionsCollectivesRows,
        ],
      });

      const xhr = new XMLHttpRequest();
      xhr.open('GET', user.organisation.logo);
      xhr.responseType = 'blob';
      xhr.send();
      xhr.addEventListener('load', () => {
        const reader = new FileReader();
        reader.readAsDataURL(xhr.response);
        reader.addEventListener('loadend', (): any => {
          const logo = reader.result;
          if (logo) {
            const imageLogo = new ImageRun({
              data: logo,
              transformation: {
                width: 100,
                height: 40,
              },
            });

            const doc = new Document({
              sections: [
                {
                  properties: {},
                  children: [
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: '',
                          bold: true,
                          font: 'Calibri',
                        }),
                      ],
                      heading: HeadingLevel.HEADING_4,
                    }),
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: '',
                          bold: true,
                          font: 'Calibri',
                        }),
                      ],
                      heading: HeadingLevel.HEADING_4,
                    }),
                    // OPERATION
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: 'OPÉRATION',
                          bold: true,
                          font: 'Calibri',
                        }),
                      ],
                      heading: HeadingLevel.HEADING_4,
                    }),
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: '',
                          bold: true,
                          font: 'Calibri',
                        }),
                      ],
                      heading: HeadingLevel.HEADING_1,
                    }),

                    new Paragraph({
                      children: [
                        new TextRun({
                          text: 'Description du projet : ',
                          ...textFontStandard,
                        }),
                        new TextRun({
                          text: operation?.description || '',
                          ...textFontStandard,
                          bold: false,
                        }),
                      ],
                    }),
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: '',
                          bold: true,
                          font: 'Calibri',
                        }),
                      ],
                      heading: HeadingLevel.HEADING_1,
                    }),

                    // ENTREPRISE
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: 'ENTREPRISE',
                          bold: true,
                          font: 'Calibri',
                        }),
                      ],
                      heading: HeadingLevel.HEADING_4,
                    }),
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: '',
                          bold: true,
                          font: 'Calibri',
                        }),
                      ],
                      heading: HeadingLevel.HEADING_1,
                    }),

                    new Paragraph({
                      children: [
                        new TextRun({
                          text: `Dénomination : ${entite?.uniteLegale?.denominationUniteLegale} - SIRET : ${entite?.siret}`,
                          ...textFontStandard,
                          size: 20,
                          bold: true,
                        }),
                      ],
                    }),
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: `Catégorie : ${entite?.uniteLegale?.categorieEntreprise}`,
                          ...textFontStandard,
                          size: 20,
                          bold: true,
                        }),
                      ],
                    }),
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: `Activité : ${entite?.uniteLegale?.activite_principale_libelle?.intitule_naf} - APE : ${entite?.uniteLegale?.activitePrincipaleUniteLegale}`,
                          ...textFontStandard,
                          size: 20,
                          bold: false,
                        }),
                      ],
                    }),
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: `Catégorie : ${entite?.uniteLegale?.categorie_juridique_libelle?.libelle}`,
                          ...textFontStandard,
                          size: 20,
                          bold: false,
                        }),
                      ],
                    }),

                    new Paragraph({
                      children: [
                        new TextRun({
                          text: `Adresse : ${entite?.adresseEtablissement?.fullAdresse}`,
                          ...textFontStandard,
                          size: 20,
                          bold: true,
                        }),
                      ],
                    }),
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: '',
                          bold: true,
                          font: 'Calibri',
                        }),
                      ],
                      heading: HeadingLevel.HEADING_1,
                    }),

                    new Paragraph({
                      children: [
                        new TextRun({
                          text: '',
                          bold: true,
                          font: 'Calibri',
                        }),
                      ],
                      heading: HeadingLevel.HEADING_1,
                    }),

                    // CONTACTS
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: 'CONTACTS',
                          bold: true,
                          font: 'Calibri',
                        }),
                      ],
                      heading: HeadingLevel.HEADING_4,
                    }),
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: '',
                          bold: true,
                          font: 'Calibri',
                        }),
                      ],
                      heading: HeadingLevel.HEADING_1,
                    }),

                    contactsTables,

                    new Paragraph({
                      children: [
                        new TextRun({
                          text: '',
                          bold: true,
                          font: 'Calibri',
                        }),
                      ],
                      heading: HeadingLevel.HEADING_1,
                    }),

                    // RECHERCHE
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: 'RECHERCHE',
                          bold: true,
                          font: 'Calibri',
                        }),
                      ],
                      heading: HeadingLevel.HEADING_4,
                    }),
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: '',
                          bold: true,
                          font: 'Calibri',
                        }),
                      ],
                      heading: HeadingLevel.HEADING_1,
                    }),
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: `Métier : ${metierTitle} - ROME : ${metier?.metier?.code}`,
                          ...textFontStandard,
                          size: 20,
                        }),
                      ],
                    }),
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: `Grand domaine : ${metier?.metier?.domaineProfessionnel?.grandDomaine?.libelle}`,
                          ...textFontStandard,
                          size: 20,
                          bold: false,
                        }),
                      ],
                    }),
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: `Domaine professionnel : ${metier?.metier?.domaineProfessionnel?.libelle}`,
                          ...textFontStandard,
                          size: 20,
                          bold: false,
                        }),
                      ],
                    }),

                    new Paragraph({
                      children: [
                        new TextRun({
                          text: `Besoin : ${
                            recherche?.participantsSize
                          } profil${
                            recherche?.participantsSize > 1 ? 's' : ''
                          }`,
                          ...textFontStandard,
                          bold: true,
                        }),
                      ],
                    }),
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: `Restriction : ${recherche?.geoLimitation?.commune?.nom} - ${recherche?.geoLimitation?.departement?.nom} - ${recherche?.geoLimitation?.region?.nom}`,
                          ...textFontStandard,
                          bold: true,
                        }),
                      ],
                    }),
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: '',
                          bold: true,
                          font: 'Calibri',
                        }),
                      ],
                    }),

                    new Paragraph({
                      children: [
                        new TextRun({
                          text: '',
                          bold: true,
                          font: 'Calibri',
                        }),
                      ],
                      heading: HeadingLevel.HEADING_1,
                    }),

                    // CANDIDATURES
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: 'CANDIDATS',
                          bold: true,
                          font: 'Calibri',
                        }),
                      ],
                      heading: HeadingLevel.HEADING_4,
                    }),
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: '',
                          bold: true,
                          font: 'Calibri',
                        }),
                      ],
                      heading: HeadingLevel.HEADING_1,
                    }),
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: `${candidats?.length} profils reçu${
                            candidats?.length > 1 ? 's' : ''
                          }`,
                          ...textFontStandard,
                          size: 20,
                        }),
                      ],
                    }),

                    candidatsTables,
                  ],
                  headers: {
                    default: new Header({
                      // The standard default header on every page or header on odd pages when the 'Different Odd & Even Pages' option is activated
                      children: [
                        new Paragraph({
                          children: [imageLogo],
                          heading: HeadingLevel.HEADING_3,
                        }),

                        new Paragraph({
                          children: [
                            new TextRun({
                              text: "Nom de l'opération : ",
                              ...textFontStandard,
                              bold: false,
                            }),
                            new TextRun({
                              text: operation?.denomination,
                              ...textFontStandard,
                            }),
                          ],
                          heading: HeadingLevel.HEADING_3,
                        }),

                        new Paragraph({
                          children: [
                            new TextRun({
                              text: "Financeurs / Donneurs d'ordre : ",
                              ...textFontStandard,
                              bold: false,
                            }),
                            new TextRun({
                              text: operation?.financeurs?.length
                                ? operation?.financeurs
                                    .map(
                                      (financeur: any) =>
                                        financeur?.uniteLegale
                                          ?.denominationUniteLegale
                                    )
                                    .join(', ')
                                : '',
                              ...textFontStandard,
                            }),
                          ],
                          heading: HeadingLevel.HEADING_3,
                        }),
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: 'Type de projet : ',
                              ...textFontStandard,
                              bold: false,
                            }),
                            new TextRun({
                              text: operation?.type || '',
                              ...textFontStandard,
                            }),
                          ],
                          heading: HeadingLevel.HEADING_3,
                        }),
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: 'CANDIDATURES',
                              font: 'Calibri',
                            }),
                          ],
                          heading: HeadingLevel.HEADING_1,
                        }),
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: '',
                              font: 'Calibri',
                            }),
                          ],
                          heading: HeadingLevel.HEADING_1,
                        }),
                      ],
                    }),
                    first: new Header({
                      // The header on first page when the 'Different First Page' option is activated
                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: user?.organisation?.etablissement
                                ?.denonimantion,
                              font: 'Calibri',
                              size: 20,
                              color: '#062949',
                            }),
                          ],
                        }),
                      ],
                    }),
                    even: new Header({
                      // The header on even pages when the 'Different Odd & Even Pages' option is activated
                      children: [],
                    }),
                  },
                  footers: {
                    default: new Footer({
                      // The standard default footer on every page or footer on odd pages when the 'Different Odd & Even Pages' option is activated
                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              children: [
                                'Page ',
                                PageNumber.CURRENT,
                                ' / ',
                                PageNumber.TOTAL_PAGES,
                              ],
                            }),
                          ],
                        }),
                      ],
                    }),
                    first: new Footer({
                      // The footer on first page when the 'Different First Page' option is activated
                      children: [],
                    }),
                  },
                },
              ],
            });

            const mimeType =
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

            return from(
              Packer.toBlob(doc)
                .then((blob: any) => {
                  const docblob = blob.slice(0, blob.size, mimeType);
                  saveAs(
                    docblob,
                    `${snakeCase(fileName).toUpperCase()}_${currentDate}`
                  );
                })
                .then(() => 'Votre fichier est prêt !')
            );
          }
        });
      });

      return of('Success');
    } catch (error) {
      return of(error);
    }
  }
}
