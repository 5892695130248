import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-information-collective-item',
  templateUrl: './information-collective-item.component.html',
  styleUrls: ['./information-collective-item.component.scss']
})
export class InformationCollectiveItemComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
