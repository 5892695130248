<div class="overflow-y-auto overflow-x-hidden">
  <table class="table table-compact overflow-scroll w-full">
    <!-- head -->
    <thead>
      <ng-container *ngTemplateOutlet="tableHeader"></ng-container>
    </thead>
    <tbody>
      <tr
        *ngFor="let item of source; index as i; trackBy: trackByFn"
        class="hover:bg-slate-100 cursor-pointer"
        (click)="$event.stopPropagation(); onSelect(item)"
      >
        <ng-container
          *ngTemplateOutlet="tableRow; context: { $implicit: item, idx: i }"
        >
        </ng-container>
      </tr>
    </tbody>
  </table>
</div>
