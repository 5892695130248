import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParticipantSuiviComponent } from './components/participant-suivi/participant-suivi.component';
import { ContentsModule } from 'src/app/contents/contents.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { ParticipantSuivisService } from 'src/app/service/participant/suivi/participant-suivis.service';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromParticipantSuivi from './store/participant-suivi.reducer';
import { ParticipantSuiviEffects } from './store/participant-suivi.effects';
import { SuiviModule } from 'src/app/features/suivi/suivi.module';

@NgModule({
  declarations: [ParticipantSuiviComponent],
  imports: [
    CommonModule,
    ContentsModule,
    SharedModule,
    SuiviModule,
    StoreModule.forFeature(
      fromParticipantSuivi.participantSuivisFeatureKey,
      fromParticipantSuivi.reducer
    ),
    EffectsModule.forFeature([ParticipantSuiviEffects]),
  ],
  exports: [ParticipantSuiviComponent],
  providers: [ParticipantSuivisService],
})
export class ParticipantSuiviModule {}
