import { Pipe, PipeTransform } from '@angular/core';
import { fromUnixTime, format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { sortBy, last } from 'lodash';

@Pipe({
  name: 'indicatorCard',
})
export class IndicatorCardPipe implements PipeTransform {
  transform(value: any[], arg: string): string {
    if (!value) {
      return '';
    }

    const conversionToDate = value.map((item) => {
      const conversion = {
        id: item.id,
        nom: item?.nom_raison_sociale
          ? item.nom_raison_sociale
          : item.displayName,
        type: item.type,
        date: format(fromUnixTime(item.dateUpdate['seconds']), 'dd/MM/yyyy', {
          locale: fr,
        }),
      };

      return conversion;
    });

    const sortByDate = sortBy(conversionToDate, 'date');
    const lastItem : any= last(sortByDate);

    if (arg === 'id') {
      return lastItem.id;
    }
    if (arg === 'date') {
      return lastItem.date;
    }
    if (arg === 'type') {
      return lastItem.type;
    }
    if (arg === 'name') {
      return lastItem.nom;
    }

    return '';
  }
}
