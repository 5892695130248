<nz-row nzAlign="top" nzGutter="8" nzJustify="start" *ngIf="todo">
  <nz-card nzBorderless class="formContentTODO">
    <form nz-form [formGroup]="todoForm" nzLayout="vertical">
      <div formArrayName="sujets">
        <cdk-virtual-scroll-viewport
          itemSize="120"
          class="viewport"
          *ngIf="sujetsArray.controls?.length; else noData"
        >
          <div
            *cdkVirtualFor="let sujet of sujetsArray.controls; let i = index"
            class="item"
          >
            <nz-card
              [formGroupName]="i"
              nzSize="small"
              nzHoverable
              nzBorderless
              [nzExtra]="dataSujetExtra"
              [nzTitle]="dataSujetTitle"
            >
              <ng-template #dataSujetTitle>
                <i
                  nz-icon
                  nzType="align-left"
                  *ngIf="sujetsArray.controls[i].value.type === 'texte'"
                ></i>

                <i
                  nz-icon
                  nzType="unordered-list"
                  *ngIf="sujetsArray.controls[i].value.type === 'liste'"
                ></i>
                <small
                  *ngIf="sujetsArray.controls[i].value.status"
                  class="sujetTitle__TODO"
                >
                  Réalisé par
                  {{
                    sujetsArray.controls[i].value?.userUpdate?.displayName
                      | carLimitation: 25
                  }}</small
                >
                <small
                  *ngIf="sujetsArray.controls[i].value.status"
                  class="sujetTitle__TODO"
                >
                  le
                  {{
                    sujetsArray.controls[i].value?.dateUpdate | dateFr: "time"
                  }}</small
                >
              </ng-template>
              <ng-template #dataSujetExtra>
                <nz-row nzAlign="middle" nzGutter="0" nzJustify="end">
                  <nz-col [nzSpan]="todo?.completed ? 12 : 10">
                    <nz-tag
                      *ngIf="!todo?.completed"
                      [nzChecked]="sujetsArray.controls[i].value.status"
                      [nzColor]="
                        sujetsArray.controls[i].value.status
                          ? 'success'
                          : 'orange'
                      "
                      nzMode="checkable"
                    >
                      <i
                        nz-icon
                        nzType="field-time"
                        *ngIf="!sujetsArray.controls[i].value.status"
                      ></i>
                      <i
                        nz-icon
                        nzType="check-circle"
                        *ngIf="sujetsArray.controls[i].value.status"
                      ></i>
                      <span *ngIf="sujetsArray.controls[i].value.status"
                        >Réalisée</span
                      >
                      <span *ngIf="!sujetsArray.controls[i].value.status"
                        >En attente</span
                      >
                    </nz-tag>

                    <nz-tag
                      *ngIf="todo?.completed"
                      [nzChecked]="sujetsArray.controls[i].value.status"
                      [nzColor]="
                        sujetsArray.controls[i].value.status
                          ? 'success'
                          : 'orange'
                      "
                    >
                      <i
                        nz-icon
                        nzType="field-time"
                        *ngIf="!sujetsArray.controls[i].value.status"
                      ></i>
                      <i
                        nz-icon
                        nzType="check-circle"
                        *ngIf="sujetsArray.controls[i].value.status"
                      ></i>
                      <span *ngIf="sujetsArray.controls[i].value.status"
                        >Réalisée</span
                      >
                      <span *ngIf="!sujetsArray.controls[i].value.status"
                        >En attente</span
                      >
                    </nz-tag>
                  </nz-col>

                  <nz-col [nzSpan]="todo?.completed ? 12 : 9">
                    <nz-tag
                      *ngIf="!todo?.completed"
                      [nzColor]="
                        sujetsArray.controls[i].value?.priority
                          ? 'red'
                          : 'default'
                      "
                      nzMode="checkable"
                      nz-tooltip
                      nzTooltipTitle="Priorité"
                      [nzTooltipMouseEnterDelay]="1"
                      nzTooltipPlacement="bottom"
                      ><i nz-icon nzType="flag"></i>
                      <span>Priorité</span>
                    </nz-tag>

                    <nz-tag
                      *ngIf="todo?.completed"
                      [nzColor]="
                        sujetsArray.controls[i].value?.priority
                          ? 'red'
                          : 'default'
                      "
                      ><i nz-icon nzType="flag"></i>
                      <span>Priorité</span>
                    </nz-tag>
                  </nz-col>

                  <nz-col [nzSpan]="todo?.completed ? 0 : 3">
                    <nz-tag
                      *ngIf="sujetsArray.controls[i].dirty && !todo?.completed"
                      nzColor="blue"
                      nzMode="checkable"
                      nz-tooltip
                      nzTooltipTitle="Enregistrer"
                      [nzTooltipMouseEnterDelay]="1"
                      (click)="
                        $event.stopPropagation(); updateSujet(sujet, user)
                      "
                      nzTooltipPlacement="bottom"
                      ><i nz-icon nzType="save"></i
                    ></nz-tag>
                  </nz-col>
                  <nz-col
                    [nzSpan]="todo?.completed ? 0 : 2"
                    *ngIf="!todo?.completed"
                  >
                    <button
                      *ngIf="todo?.auteur?.id === user.id || !todo?.completed"
                      nz-button
                      nzShape="circle"
                      nzSize="small"
                      (click)="$event.stopPropagation(); deleteSujet(sujet)"
                    >
                      <i nz-icon nzType="delete"></i>
                    </button>
                  </nz-col>
                </nz-row>
              </ng-template>

              <div [ngSwitch]="sujetsArray.controls[i].value.type">
                <div *ngSwitchCase="'texte'">
                  <nz-row nzAlign="top" nzGutter="8" nzJustify="start">
                    <textarea
                      *ngIf="
                        !sujetsArray.controls[i].value.status ||
                        !todo?.completed
                      "
                      nz-input
                      formControlName="text"
                      nzBorderless
                      placeholder="Texte..."
                    ></textarea>
                    <nz-comment *ngIf="sujetsArray.controls[i].value.status">
                      <nz-comment-content>
                        <del>
                          {{ sujetsArray.controls[i].value.text }}
                        </del>
                      </nz-comment-content>
                    </nz-comment>
                  </nz-row>
                </div>
                <div *ngSwitchCase="'liste'">
                  <nz-list nzBordered="false" nzSize="small">
                    <nz-list-header nzNoFlex>
                      <nz-row>
                        <nz-col nzSpan="18">
                          <input
                            *ngIf="
                              !sujetsArray.controls[i].value.status ||
                              !todo?.completed
                            "
                            nz-input
                            class="listTitleTODO"
                            formControlName="title"
                            nzSize="default"
                            nzBorderless
                            placeholder="Titre..."
                          />

                          <strong
                            *ngIf="
                              sujetsArray.controls[i].value.status ||
                              todo?.completed
                            "
                          >
                            {{ sujetsArray.controls[i].value.text }}
                          </strong>
                        </nz-col>
                        <nz-col nzSpan="6">
                          <button nz-button nzBlock nzType="text">
                            <small>Micro tâche</small>
                          </button>
                        </nz-col>
                      </nz-row>
                    </nz-list-header>
                    <nz-list-item
                      *ngFor="
                        let itemList of sujetsArray.controls[i].value.items
                          ?.controls;
                        let iList = index
                      "
                      [formGroupName]="iList"
                    >
                      <ul nz-list-item-actions>
                        <nz-list-item-action>
                          <label nz-checkbox formControlName="status"></label>
                        </nz-list-item-action>
                        <nz-list-item-action>
                          <a>
                            <i nz-icon nzType="delete"></i>
                          </a>
                        </nz-list-item-action>
                      </ul>

                      <small>{{
                        itemList?.value?.dateStart | dateFr: "small"
                      }}</small>

                      <small *ngIf="itemList?.value?.dateUpdate">{{
                        itemList?.value?.dateUpdate | dateFr: "small"
                      }}</small>

                      <small *ngIf="itemList?.value?.userUpdate">{{
                        itemList?.value?.userUpdate | carLimitation: 25
                      }}</small>

                      <input
                        nz-input
                        formControlName="text"
                        nzBorderless
                        placeholder="Note..."
                      />
                    </nz-list-item>
                  </nz-list>
                </div>
                <div *ngSwitchDefault>output2</div>
              </div>
            </nz-card>
          </div>
        </cdk-virtual-scroll-viewport>
      </div>
    </form>
  </nz-card>
</nz-row>

<ng-template #noData>
  <div class="viewport">
    <nz-empty nzNotFoundImage="simple"></nz-empty>
  </div>
</ng-template>
