<ng-container>
  <app-item-operations
    *ngIf="participant$ | async as participant"
    [operations$]="operations$"
    [identity]="identity"
    [status]="true"
    [newCardDescription]="addProjetTitle"
    (add)="onAdd($event, participant)"
    (update)="onUpdate($event, participant)"
    (delete)="onDelete($event, participant)"
  ></app-item-operations>
</ng-container>
