import { OperationParticipantsSelectorsComponent } from './../../../operation-participants/components/operation-participants-selectors/operation-participants-selectors.component';
import { ActionFormComponent } from './../../../action/components/action-form/action-form.component';
import { OperationId } from 'src/app/components/operation/store/operation.model';
import { ModuleId } from './../../store/module.model';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
  ViewContainerRef,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { ModuleState } from '../../store/module.reducer';
import * as fromModuleAction from '../../store/module.actions';
import * as fromModuleSelector from '../../store/module.selectors';

import { Store } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ModuleFormComponent } from '../module-form/module-form.component';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { OperationState } from 'src/app/components/operation/store/operation.reducer';
import * as fromOperationSelector from 'src/app/components/operation/store/operation.selectors';
import { Action } from 'src/app/components/action/store/action.model';
import { MissionsFormComponent } from 'src/app/components/missions/components/missions-form/missions-form.component';

@Component({
  selector: 'app-module-item',
  templateUrl: './module-item.component.html',
  styleUrls: ['./module-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModuleItemComponent implements OnInit, OnChanges {
  view$ = new BehaviorSubject<string>('details');
  color: string = '#597ef7';
  progressCompleteColor: string = '#49d49d';
  progressDefaultColor: string = '#062949';
  title: string = 'Nouveau module';
  titleAction: string = 'Nouvelle action';
  titleMission: string = 'Nouvelle mission';
  titleParticipant: string = 'Nouveau participant';

  suivisSource$: Observable<any[]> = of([]);
  documentsSource$: Observable<any[]> = of([]);
  missionsSource$: Observable<any[]> = of([]);
  actionsSource$: Observable<any[]> = of([]);
  operation$: Observable<OperationId | any> = of();

  @ViewChild('moduleFormTitle', { static: false })
  moduleFormTitle: TemplateRef<any> | any;
  @ViewChild('actionFormTitle', { static: false })
  actionFormTitle: TemplateRef<any> | any;
  @ViewChild('missionFormTitle', { static: false })
  missionFormTitle: TemplateRef<any> | any;
  @ViewChild('participantFormTitle', { static: false })
  participantFormTitle: TemplateRef<any> | any;

  modalWidth: number = 850;

  @Input() module: ModuleId | any = null;
  @Output() back = new EventEmitter<string>(false);

  constructor(
    private moduleStore: Store<ModuleState>,
    private operationStore: Store<OperationState>,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.getOperations();
    this.getSuivis();
    this.getDocuments();
    this.getMissions();
    this.getActions();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  onBack(): void {
    this.back.emit('modules');
  }

  getOperations(): void {
    this.operation$ = this.operationStore.select(
      fromOperationSelector.operation
    );
  }

  onSelectDropdown(event: string, module: ModuleId): void {
    if (!event) return;

    switch (event) {
      case 'update':
        this.onSetUpdate(module);

        break;

      default:
        break;
    }
  }

  onSelectTab(tab: any): void {
    switch (tab) {
      case 0:
        break;
      case 1:
        this.loadActions();
        break;
      case 2:
        break;
      case 3:
        this.loadMissions();

        break;
      case 4:
        this.loadSuivis();

        break;
      case 5:
        this.loadDocuments();

        break;

      default:
        break;
    }
  }

  onSetUpdate(module: ModuleId): void {
    if (!module || !module?.id) return;
    this.title = `${module.title}`;
    const modal = this.modal.create({
      nzContent: ModuleFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.moduleFormTitle,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });
    const instance = modal.getContentComponent();
    instance.operation$ = of(module.operation);
    instance.module$ = of(module);
    instance.isAdd = false;
    instance.isUpdate = true;
    instance.cancel.subscribe((): any => modal.close());
    instance.update.subscribe((event: any): any => {
      if (!event) {
        return null;
      } else {
        this.onUpdate(event);
      }
    });
  }

  onUpdate(module: Update<ModuleId>): void {
    if (!module) return;

    const operationId: string = module.changes.operation?.id as string;

    this.moduleStore.dispatch(
      fromModuleAction.updateModule({
        operationId: operationId,
        module: module,
      })
    );
  }

  //ACTIONS

  //CONTACT

  //SUIVIS
  getSuivis(): void {
    this.suivisSource$ = this.moduleStore.select(fromModuleSelector.suivis);
  }
  loadSuivis(): void {
    this.moduleStore.dispatch(fromModuleAction.loadSuivis());
  }
  addSuivi(suivi: any, module: ModuleId): void {
    if (!suivi) return;

    this.moduleStore.dispatch(
      fromModuleAction.addSuivi({
        suivi: suivi,
        moduleId: module.id,
        operationId: module.operation.id,
      })
    );
  }
  updateSuivi(suivi: any, module: ModuleId): void {
    if (!suivi) return;
    const nextSuivi: Update<any> = {
      id: suivi.id,
      changes: { ...suivi },
    };
    this.moduleStore.dispatch(
      fromModuleAction.updateSuivi({
        suivi: nextSuivi,
        moduleId: module.id,
        operationId: module.operation.id,
      })
    );
  }
  deleteSuivi(suivi: any, module: ModuleId): void {
    if (!suivi) return;
    this.moduleStore.dispatch(
      fromModuleAction.deleteSuivi({
        suiviId: suivi.id,
        moduleId: module.id,
        operationId: module.operation.id,
      })
    );
  }

  //DOCUMENTS
  getDocuments(): void {
    this.documentsSource$ = this.moduleStore.select(
      fromModuleSelector.documents
    );
  }
  loadDocuments(): void {
    this.moduleStore.dispatch(fromModuleAction.loadDocuments());
  }
  addDocument(documents: any[], module: ModuleId): void {
    if (!documents?.length || !module.id) return;

    this.moduleStore.dispatch(
      fromModuleAction.addDocuments({
        module: module,
        documents: documents,
      })
    );
  }
  deleteDocument(document: any, module: ModuleId): void {
    if (!document) return;
    this.moduleStore.dispatch(
      fromModuleAction.deleteDocument({
        module: module,
        document: document,
      })
    );
  }

  //MISSIONS
  getMissions(): void {
    this.missionsSource$ = this.moduleStore.select(fromModuleSelector.missions);
  }
  loadMissions(): void {
    this.moduleStore.dispatch(fromModuleAction.loadMissions());
  }
  onNewMission(module: ModuleId): void {
    if (!module) return;

    const modal = this.modal.create({
      nzContent: MissionsFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.missionFormTitle,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });
    const instance = modal.getContentComponent();

    instance.cancel.subscribe((event: any): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });

    instance.add.subscribe((event: any): any => {
      if (!event) {
        return null;
      } else {
        this.onAddMission(event, module);
      }
    });
  }

  onAddMission(action: Action, module: ModuleId): void {
    if (!module || !!action) return;
  }

  //ACTIONS
  getActions(): void {
    this.actionsSource$ = this.moduleStore.select(fromModuleSelector.actions);
  }
  loadActions(): void {
    this.moduleStore.dispatch(fromModuleAction.loadActions());
  }

  onNewAction(module: ModuleId): void {
    if (!module) return;

    const modal = this.modal.create({
      nzContent: ActionFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.actionFormTitle,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });
    const instance = modal.getContentComponent();
    instance.operation$ = this.operation$;

    instance.cancel.subscribe((event: any): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });

    instance.add.subscribe((event: any): any => {
      if (!event) {
        return null;
      } else {
        this.onAddAction(event, module);
      }
    });
  }

  onAddAction(action: Action, module: ModuleId): void {
    if (!module || !!action) return;
  }

  //PARTICIPANTS
  getParticipants(): void {
    this.actionsSource$ = this.moduleStore.select(fromModuleSelector.actions);
  }
  loadParticipants(): void {
    this.moduleStore.dispatch(fromModuleAction.loadActions());
  }
  onNewParticipant(module: ModuleId): void {
    if (!module) return;

    const modal = this.modal.create({
      nzContent: OperationParticipantsSelectorsComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.participantFormTitle,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
    });
    const instance = modal.getContentComponent();
    //instance.operation$ = this.operation$;

    instance.cancel.subscribe((event: any): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });

    instance.add.subscribe((event: any): any => {
      if (!event) {
        return null;
      } else {
        this.onAddParticipant(event, module);
      }
    });
  }
  onAddParticipant(participant: any, module: ModuleId): void {
    if (!module || !!participant) return;
  }
}
