import { mergeMap, catchError, map } from 'rxjs/operators';
import { QueryParticipantsService } from './../../../../service/query-participants.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ParticipantSmallId } from 'src/app/components/participant/store/participant.model';
import * as fromParticipantSelectAction from './participants-select.actions';
import { of } from 'rxjs';

@Injectable()
export class ParticipantsSelectEffects {
  queryParticipants$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromParticipantSelectAction.queryParticipants),
      mergeMap((action) =>
        this.queryParticipantService
          .queryParticipants(action.filter, action.filterType)
          .pipe(
            map(
              (participants: ParticipantSmallId[]) =>
                fromParticipantSelectAction.queryParticipantsSuccess({
                  participants,
                }),
              catchError((error) =>
                of(
                  fromParticipantSelectAction.queryParticipantsFailure({
                    error,
                  })
                )
              )
            )
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private queryParticipantService: QueryParticipantsService
  ) {}
}
