<div class="submenu">
  <!-- <li
      nz-menu-item
      (click)="onSelect('participant_details')"
      nz-tooltip
      nzTooltipTitle="Profil participant"
      nzTooltipPlacement="right"
      [nzTooltipMouseEnterDelay]="tooltipDelay"
      class="active-subrouter"
    >
      <mat-icon>person</mat-icon>
      <span *ngIf="!collapse">Profil</span>
    </li> -->

  <li
    nz-menu-item
    (click)="onSelect('participant_suivis')"
    nz-tooltip
    nzTooltipTitle="Suivis du participant"
    nzTooltipPlacement="right"
    [nzTooltipMouseEnterDelay]="tooltipDelay"
    class="active-subrouter"
  >
    <mat-icon>article</mat-icon>
    <span *ngIf="!collapse">Suivis</span>
  </li>

  <li
    nz-menu-item
    (click)="onSelect('participant_documents')"
    nz-tooltip
    nzTooltipTitle="Documents du participant"
    nzTooltipPlacement="right"
    [nzTooltipMouseEnterDelay]="tooltipDelay"
    class="active-subrouter"
  >
    <mat-icon>insert_drive_file</mat-icon>
    <span *ngIf="!collapse">Documents</span>
  </li>
  <li nz-menu-divider></li>

  <li
    nz-menu-item
    (click)="onSelect('participant_experiences')"
    nz-tooltip
    nzTooltipTitle="Expériences du participantt"
    nzTooltipPlacement="right"
    [nzTooltipMouseEnterDelay]="tooltipDelay"
    class="active-subrouter"
  >
    <mat-icon>work_history</mat-icon>
    <span *ngIf="!collapse">Expériences</span>
  </li>
  <li
    nz-menu-item
    (click)="onSelect('participant_projets')"
    nz-tooltip
    nzTooltipTitle="Projets du participant"
    nzTooltipPlacement="right"
    [nzTooltipMouseEnterDelay]="tooltipDelay"
    class="active-subrouter"
  >
    <mat-icon> analytics </mat-icon>
    <span *ngIf="!collapse">Projets</span>
  </li>

  <li
    nz-menu-item
    (click)="onSelect('participant_prescripteur')"
    nz-tooltip
    nzTooltipTitle="Prescripteur"
    nzTooltipPlacement="right"
    [nzTooltipMouseEnterDelay]="tooltipDelay"
    class="active-subrouter"
  >
    <mat-icon>real_estate_agent</mat-icon>
    <span *ngIf="!collapse">Prescripteurs</span>
  </li>
  <li nz-menu-divider></li>
  <li
    nz-menu-item
    (click)="onSelect('participant_actions')"
    nz-tooltip
    nzTooltipTitle="Actions d'accompagnement"
    nzTooltipPlacement="right"
    [nzTooltipMouseEnterDelay]="tooltipDelay"
  >
    <mat-icon>checklist</mat-icon>
    <span *ngIf="!collapse">Actions</span>
  </li>
  <li
    nz-menu-item
    (click)="onSelect('participant_projetsProfessionnels')"
    nz-tooltip
    nzTooltipTitle="Projets professionnelst"
    nzTooltipPlacement="right"
    [nzTooltipMouseEnterDelay]="tooltipDelay"
  >
    <mat-icon>business_center</mat-icon>
    <span *ngIf="!collapse">Projets professionnels</span>
  </li>
  <li nz-menu-divider></li>
  <!-- <li nz-menu-item (click)="onSelect('participant_aides')"
                nz-tooltip
      nzTooltipTitle="Aides du participants"
      nzTooltipPlacement="right"
      [nzTooltipMouseEnterDelay]="tooltipDelay"
>
      <mat-icon>volunteer_activism</mat-icon>
      <span *ngIf="!collapse">Aides</span>
    </li> -->
  <!-- <li nz-menu-item (click)="onSelect('participant_orientation')"
                nz-tooltip
      nzTooltipTitle="Détail du projet"
      nzTooltipPlacement="right"
      [nzTooltipMouseEnterDelay]="tooltipDelay"
>
      <mat-icon>route</mat-icon>
      <span *ngIf="!collapse">Orientations</span>
    </li> -->
  <!-- <li nz-menu-item (click)="onSelect('participant_dashboard')"
                nz-tooltip
      nzTooltipTitle="Détail du projet"
      nzTooltipPlacement="right"
      [nzTooltipMouseEnterDelay]="tooltipDelay"
>
      <mat-icon>pie_chart</mat-icon>
      <span *ngIf="!collapse">Tableau de bord</span>
    </li> -->
</div>
