import { NzMessageService } from 'ng-zorro-antd/message';
import { UserState } from './../../../user/store/user.reducer';
import * as fromUserSelector from './../../../user/store/user.selectors';

import { PortalNavigationService } from './../../../../portals/portal-navigation.service';
import { DepartementFiltersComponent } from './../departement-filters/departement-filters.component';
import { ComponentPortal } from '@angular/cdk/portal';
import { NzModalService } from 'ng-zorro-antd/modal';
import { DepartementFormComponent } from './../departement-form/departement-form.component';
import { Observable, of, Subject } from 'rxjs';
import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { DepartementState } from '../../store/departement.reducer';
import * as fromDepartementAction from '../../store/departement.actions';
import * as fromDepartementSelector from '../../store/departement.selectors';

import { Store, select } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-departement-list',
  templateUrl: './departement-list.component.html',
  styleUrls: ['./departement-list.component.scss'],
})
export class DepartementListComponent implements OnInit, OnDestroy {
  subscribe = new Subject();
  data$: Observable<any> = of(null);
  user$: Observable<any> = of(null);
  componentPortalDepartementFilter:
    | ComponentPortal<DepartementFiltersComponent>
    | any;

  departements$: Observable<any> = of([]);
  modalWidth: number = 750;
  title: string = 'Nouveau service';
  titleUser: string = 'Nouveau collaborateur du service';

  description: string = 'Ajouter un service à votre organisation';
  definition: string =
    'Un service est une subdivision au sein de votre organisation.';
  deleteAllType: string = 'Voulez-vous supprimer tous les services ?';

  @ViewChild('departementFormTitle', { static: false }) departementFormTitle:
    | TemplateRef<any>
    | any;

  constructor(
    private departementStore: Store<DepartementState>,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private activateRouter: ActivatedRoute,
    private router: Router,
    private portalNavigationService: PortalNavigationService,
    private userStore: Store<UserState>,
    private message: NzMessageService
  ) {}

  ngOnInit(): void {
    this.getRouteData();
    this.componentPortalDepartementFilter = new ComponentPortal(
      DepartementFiltersComponent
    );
    this.setFilterPortal();
    this.getuser();
    this.getDepartemens();
  }

  ngOnDestroy(): void {
    this.componentPortalDepartementFilter.detach();
  }

  getRouteData(): void {
    this.data$ = this.activateRouter.data;
  }

  getuser(): void {
    this.user$ = this.userStore.select(fromUserSelector.user);
  }

  getDepartemens(): void {
    this.departements$ = this.departementStore.select(
      fromDepartementSelector.departements
    );
  }

  loadDepartemens(): void {
    this.departementStore.dispatch(fromDepartementAction.loadDepartements());
  }

  setFilterPortal(): void {
    this.portalNavigationService.setPortal(
      this.componentPortalDepartementFilter
    );
  }

  onNewDepartementForm(user: any): void {
    const { claims } = user;
    const modal = this.modal.create({
      nzContent: DepartementFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.departementFormTitle,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });

    const instance = modal.getContentComponent();

    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
    instance.add.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
  }

  onDropdownAction(event: string): void {
    if (!event) return;

    switch (event) {
      case 'deleteAll':
        this.onDeleteAll();
        break;

      default:
        break;
    }
  }

  onDeleteAll(): void {
    this.departementStore.dispatch(fromDepartementAction.deleteDepartements());
  }
}
