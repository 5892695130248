import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { Update } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import {
  NzNotificationPlacement,
  NzNotificationService,
} from 'ng-zorro-antd/notification';
import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';
import * as fromUserAction from 'src/app/components/user/store/user.actions';

@Component({
  selector: 'app-task-item',
  templateUrl: './task-item.component.html',
  styleUrls: ['./task-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskItemComponent implements OnInit, OnChanges {
  mouseEnterDelay: number = 0.5;
  NOTIFICATION_PLACEMENT: NzNotificationPlacement = 'topRight';
  default: string = '#bfbfbf';
  subscribe = new Subject();

  @Input() task: any;
  @Input() type: any;
  @Input() user: any;

  @Input() usersType$ = new BehaviorSubject<string>('all');
  @Input() userSelectorRef: TemplateRef<any> | any = null;

  @Output() update = new EventEmitter(false);
  @Output() delete = new EventEmitter(false);

  constructor(
    private userStore: Store<UserState>,
    private notifications: NzNotificationService
  ) {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  onChangeStatus(task: any, text: any): void {
    const nextText: any = {
      id: task.id,
      value: text.value,
      status: !text.status,
    };
    const prevTodo = task.texts.filter((item: any) => item.id !== text.id);
    const taskInProgress: any[] = task.texts.filter((el: any) => el.status);
    const nextTexts: any[] = [...prevTodo, nextText];

    const operationTask: Update<any> = {
      id: task.id,
      changes: {
        ...task,
        texts: nextTexts,
        completed: taskInProgress?.length === nextTexts?.length ? true : false,
        inprogress:
          taskInProgress?.length === nextTexts?.length ||
          taskInProgress?.length > 0
            ? true
            : false,
      },
    };

    this.update.emit({ task: operationTask, type: 'modifiée' });
  }

  onCompleted(task: any): void {
    const operationTask: Update<any> = {
      id: task.id,
      changes: {
        ...task,
        completed: !task.completed,
        inprogress: true,
      },
    };

    this.update.emit({ task: operationTask, type: 'modifiée' });
  }

  onInProgress(task: any): void {
    const operationTask: Update<any> = {
      id: task.id,
      changes: {
        ...task,
        tested: !task.tested,
        inprogress: !task.inprogress,
      },
    };

    this.update.emit({ task: operationTask, type: 'modifiée' });
  }

  onVerified(task: any): void {
    const operationTask: Update<any> = {
      id: task.id,
      changes: {
        ...task,
        verifed: !task.verifed,
        inprogress: !task.inprogress,
      },
    };

    this.update.emit({ task: operationTask, type: 'modifiée' });
  }

  addUser(member: any, task: any): void {
    if (!member || !task) return;
    const membertId: string = member?.user?.id ? member?.user?.id : member.id;

    const isExist = task?.users.find(
      (el: any) => el?.user?.id === membertId || el?.id === membertId
    );

    if (isExist) {
      const isExistUserName: string = isExist?.user?.displayName
        ? isExist?.user?.displayName
        : isExist?.displayName;

      const message: string = `Cette tâche a déjà été attribuée à ${isExistUserName}`;

      this.notifications.info('', message, {
        nzDuration: 6000,
        nzAnimate: true,
        nzPlacement: this.NOTIFICATION_PLACEMENT,
      });
      return;
    }

    const nextUsers: any[] = [...task.users, member];
    const ids: string[] = [...task.ids, membertId];

    const operationTask: Update<any> = {
      id: task.id,
      changes: {
        ...task,
        auths: {
          ...task.auths,
          [`${membertId}`]: true,
        },
        users: nextUsers,
        ids: ids,
      },
    };

    this.update.emit({ task: operationTask, type: 'modifiée' });
    return;
  }

  removeUser(member: any, task: any): void {
    if (!member || !task) {
      return;
    }

    const memberId: string = member?.user?.id ? member?.user?.id : member.id;

    delete task.auths[`${memberId}`];

    const operationTask: Update<any> = {
      id: task.id,
      changes: {
        ...task,
        users: task.users.filter(
          (el: any) => el.user.id !== memberId || el.id !== memberId
        ),
        ids: task.ids.filter((id: string) => id !== memberId),
      },
    };

    this.update.emit({ task: operationTask, type: 'modifiée' });
  }

  onDelete(task: any): void {
    if (!task) return;
    this.delete.emit(task);
  }
}
