import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'app-fiches-de-poste-item-contrat',
  templateUrl: './fiches-de-poste-item-contrat.component.html',
  styleUrls: ['./fiches-de-poste-item-contrat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FichesDePosteItemContratComponent implements OnInit {
  @Input() fiche: any;

  constructor() {}

  ngOnInit(): void {}
}
