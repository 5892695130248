<nz-card nzBorderless *ngIf="prescripteur$ | async as prescripteur">
  <nz-descriptions
    nzBordered
    nzSize="small"
    [nzColumn]="{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }"
  >
    <nz-descriptions-item nzTitle="Activité" nzSpan="1">
      <small>{{
        prescripteur?.activite_principale_libelle?.intitule_naf
      }}</small>
    </nz-descriptions-item>
    <nz-descriptions-item nzTitle="Catégorie" nzSpan="1">
      <small>{{ prescripteur?.categorie_juridique_libelle?.libelle }}</small>
    </nz-descriptions-item>
    <nz-descriptions-item nzTitle="Adresse" nzSpan="1">
      <small>{{ prescripteur?.etablissement_siege?.geo_adresse }}</small>
    </nz-descriptions-item>
    <nz-descriptions-item nzTitle="ESS" nzSpan="1">
      {{ prescripteur?.economie_sociale_solidaire === "O" ? "Oui" : "Non" }}
    </nz-descriptions-item>
    <nz-descriptions-item nzTitle="Siren" nzSpan="1">
      {{ prescripteur?.siren }}
    </nz-descriptions-item>
    <nz-descriptions-item nzTitle="N° tva intracommunautaire" nzSpan="1">
      {{ prescripteur?.numero_tva_intra }}
    </nz-descriptions-item>
  </nz-descriptions>

  <nz-space nzWrap [nzSize]="200">
    <nz-card nzSize="small" nzTitle="Référent" class="referentContainer">
      <ng-template #referentExtrar>
        <button nz-button nzSize="small" nzType="text" nzShape="circle">
          <mat-icon>more_vert</mat-icon>
        </button>
      </ng-template>

      <ng-container *ngIf="!newReferentForm">
        <ng-container *ngIf="prescripteur.referents | size; else noReferent">
          <ng-container *ngFor="let referent of prescripteur.referents">
            <nz-descriptions
              nzBordered
              nzSize="small"
              [nzExtra]="referentExtrat"
            >
              <ng-template #referentExtrat>
                <button
                  nz-button
                  nzSize="small"
                  nzType="text"
                  nz-dropdown
                  nzTrigger="click"
                  [nzDropdownMenu]="referentMenu"
                  nzShape="circle"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>
              </ng-template>

              <nz-dropdown-menu #referentMenu="nzDropdownMenu">
                <ul nz-menu>
                  <li
                    nz-menu-item
                    (click)="setUpdateReferent(referent, prescripteur)"
                  >
                    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
                      <nz-col nzSpan="8">
                        <i nz-icon nzType="edit"></i>
                      </nz-col>
                      <nz-col nzSpan="16"> Modifier </nz-col>
                    </nz-row>
                  </li>
                  <li nz-menu-divider></li>
                  <li
                    nz-menu-item
                    nzDanger
                    nzPopconfirmTitle="Voulez-vous supprimer ce référent ?"
                    nzPopconfirmPlacement="bottom"
                    (nzOnConfirm)="removeReferent(referent, prescripteur)"
                    (nzOnCancel)="onCancel()"
                    nz-popconfirm
                  >
                    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
                      <nz-col nzSpan="8">
                        <i nz-icon nzType="delete"></i>
                      </nz-col>
                      <nz-col nzSpan="16"> Supprimer </nz-col>
                    </nz-row>
                  </li>
                </ul>
              </nz-dropdown-menu>
              <nz-descriptions-item nzTitle="Identité" nzSpan="3">
                {{ referent | displayName }}
              </nz-descriptions-item>
              <nz-descriptions-item nzTitle="Fonction" nzSpan="3">
                {{ referent?.fonction }}
              </nz-descriptions-item>
              <nz-descriptions-item nzTitle="E-mail" nzSpan="1">
                {{ referent?.coordonnees?.email }}
              </nz-descriptions-item>
              <nz-descriptions-item nzTitle="Fixe" nzSpan="1">
                {{ referent?.coordonnees?.fixe }}
              </nz-descriptions-item>
              <nz-descriptions-item nzTitle="Mobile" nzSpan="1">
                {{ referent?.coordonnees?.mobile }}
              </nz-descriptions-item>
            </nz-descriptions>
          </ng-container>
        </ng-container>
      </ng-container>

      <form
        nz-form
        [formGroup]="prescripteurReferentForm"
        *ngIf="newReferentForm"
        nzLayout="vertical"
      >
        <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
          <nz-col [nzSpan]="(loading$ | async) ? 2 : 0">
            <i nz-icon nzType="loading" *ngIf="loading$ | async"></i>
          </nz-col>
          <nz-col [nzSpan]="(loading$ | async) ? 2 : add ? 4 : 0">
            <nz-form-item>
              <nz-form-label nzRequired>Civilité</nz-form-label>
              <nz-form-control>
                <nz-form-control>
                  <nz-select
                    formControlName="civilite"
                    placeholder="Civilité"
                    nzBorderless
                    nzPlaceHolder="Civilité"
                    [compareWith]="compareCivilite"
                  >
                    <nz-option
                      *ngFor="let item of (filters$ | async)?.civility?.data"
                      [nzLabel]="item"
                      [nzValue]="item"
                    ></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-control>
            </nz-form-item>
          </nz-col>
          <nz-col [nzSpan]="add ? 10 : 12">
            <nz-form-item>
              <nz-form-label nzRequired>Nom</nz-form-label>
              <nz-form-control>
                <input
                  nz-input
                  nzBorderless
                  formControlName="lastName"
                  placeholder="Nom"
                />
              </nz-form-control>
            </nz-form-item>
          </nz-col>
          <nz-col [nzSpan]="add ? 10 : 12">
            <nz-form-item>
              <nz-form-label nzRequired>Prénom</nz-form-label>
              <nz-form-control>
                <input
                  nz-input
                  nzBorderless
                  formControlName="firstName"
                  placeholder="Prénom"
                />
              </nz-form-control>
            </nz-form-item>
          </nz-col>
        </nz-row>
        <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
          <nz-col nzSpan="24">
            <nz-form-item>
              <nz-form-label nzRequired>Fonction</nz-form-label>
              <nz-form-control>
                <input
                  nz-input
                  nzBorderless
                  formControlName="fonction"
                  placeholder="Fonction"
                />
              </nz-form-control>
            </nz-form-item>
          </nz-col>
        </nz-row>
        <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
          <nz-col nzSpan="24">
            <app-coordonnees
              [update]="coordonneesUpdate$"
              (mobile)="onSetMobile($event)"
              (phoneNumber)="onSetPhoneNumber($event)"
              (email)="onSetEmail($event)"
              (fixe)="onSetFixe($event)"
            ></app-coordonnees>
          </nz-col>
        </nz-row>
        <nz-space [nzSize]="20"></nz-space>
        <nz-row nzAlign="middle" nzGutter="8" nzJustify="end">
          <nz-col nzSpan="8"></nz-col>
          <nz-col nzSpan="8"></nz-col>
          <nz-col nzSpan="4">
            <button
              nz-button
              nzBlock
              nzType="primary"
              type="button"
              [disabled]="!prescripteurReferentForm.valid"
              (click)="addReferent(prescripteur)"
              *ngIf="add"
            >
              Ajouter
            </button>
            <button
              nz-button
              nzBlock
              nzType="primary"
              type="button"
              [disabled]="!prescripteurReferentForm.valid"
              (click)="updateReferent(prescripteur, currentReferent)"
              *ngIf="!add && currentReferent"
            >
              Modifier
            </button>
          </nz-col>
          <nz-col nzSpan="4">
            <button
              type="button"
              nz-button
              nzBlock
              nzType="text"
              (click)="onCancel()"
            >
              <h5 nz-typography>Annuler</h5>
            </button>
          </nz-col>
        </nz-row>
      </form>
    </nz-card>
  </nz-space>
</nz-card>

<ng-template #noReferent>
  <app-no-result
    [icon]="'person'"
    [addtitle]="addtitle"
    [notitle]="notitle"
    (new)="newReferent()"
  ></app-no-result>
</ng-template>
