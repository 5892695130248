<ng-container *ngIf="module">
  <nz-page-header
    class="site-page-header"
    (nzBack)="onBack()"
    nzBackIcon
    [nzTitle]="module.title | uppercase"
    [nzSubtitle]="module?.type | uppercase"
  >
    <nz-page-header-content>
      <app-module-item-header [module]="module"></app-module-item-header>
    </nz-page-header-content>
    <nz-page-header-extra>
      <button
        nz-button
        nzShape="circle"
        nzType="text"
        nz-tooltip
        nzTooltipTitle="Module"
        [nzTooltipMouseEnterDelay]="0.5"
        nzTooltipPlacement="bottom"
        (click)="$event.stopPropagation(); view$.next('details')"
      >
        <mat-icon>dynamic_feed</mat-icon>
      </button>
      <button
        nz-button
        nzShape="circle"
        nzType="text"
        nz-tooltip
        nzTooltipTitle="Tableau de bord"
        [nzTooltipMouseEnterDelay]="0.5"
        nzTooltipPlacement="bottom"
        (click)="$event.stopPropagation(); view$.next('dashboard')"
      >
        <mat-icon>query_stats</mat-icon>
      </button>

      <button
        nz-button
        nzShape="circle"
        nzType="text"
        nz-tooltip
        nzTooltipTitle="Partage"
        [nzTooltipMouseEnterDelay]="0.5"
        nzTooltipPlacement="bottom"
        (click)="$event.stopPropagation(); view$.next('sharing')"
      >
        <mat-icon>share</mat-icon>
      </button>

      <app-button-dropdown
        [isDelete]="true"
        [isUpdate]="true"
        [isExportEXL]="true"
        [isExportPDF]="true"
        [isExportPPT]="true"
        [deleteType]="'Supprimer ce module ?'"
        (select)="onSelectDropdown($event, module)"
      ></app-button-dropdown>
    </nz-page-header-extra>
  </nz-page-header>
  <nz-space nzDirection="vertical"></nz-space>
  <div [ngSwitch]="view$ | async">
    <div *ngSwitchCase="'details'">
      <app-item-tabset
        (select)="onSelectTab($event)"
        [tabs]="[
          {
            name: 'Contexte',
            index: 0,
            template: moduleDetails
          },
          {
            name: 'Actions',
            index: 1,
            template: moduleActions,
            count: module?.actionsTotal > 0 ? module.actionsTotal : 0
          },
          {
            name: 'Participants',
            index: 2,
            template: moduleParticipants,
            count: module?.participants?.length
              ? (module?.participants | size)
              : 0
          },
          {
            name: 'Missions',
            index: 3,
            template: moduleMissions,
            count: module?.missions ? (module?.missions | size) : 0
          },
          {
            name: 'Suivis',
            index: 4,
            template: moduleSuivis,
            count: module?.suivisTotal > 0 ? module.suivisTotal : 0
          },
          {
            name: 'Documents',
            index: 5,
            template: moduleDocuments,
            count: module?.documentsTotal > 0 ? module.documentsTotal : 0
          }
        ]"
      ></app-item-tabset>
    </div>
    <div *ngSwitchCase="'dashboard'">
      <app-module-dashboard [module]="module"></app-module-dashboard>
    </div>
    <div *ngSwitchCase="'sharing'">
      <app-sharing></app-sharing>
    </div>
    <div *ngSwitchDefault></div>
  </div>

  <ng-template #moduleTitle>
    <mat-icon class="title">dynamic_feed</mat-icon>
    <h4 nz-typography class="title">{{ module.title | uppercase }}</h4>
  </ng-template>
  <ng-template #module_updateDateTpl>
    <nz-row nzAlign="middle" nzGutter="4" nzJustify="end">
      <nz-col nzSpan="22">
        <small
          >Mise à jour
          {{ module?.calendrier?.dateUpdate | dateFr : "small" }}</small
        >
      </nz-col>
      <nz-col nzSpan="2"></nz-col>
    </nz-row>
  </ng-template>

  <ng-template #moduleParticipants>
    <app-table-participants
      (add)="onNewParticipant(module)"
    ></app-table-participants>
  </ng-template>

  <ng-template #moduleActions>
    <app-table-actions
      [source$]="actionsSource$"
      (add)="onNewAction(module)"
    ></app-table-actions>
  </ng-template>
  <ng-template #moduleActionCard let-item let-position="idx">
    <nz-card>
      <h5 nz-typography>{{ item?.title | titlecase | carLimitation : 24 }}</h5>
    </nz-card>
  </ng-template>

  <ng-template #moduleMissions>
    <app-table-missions
      [source$]="missionsSource$"
      (add)="onNewMission(module)"
    ></app-table-missions>
  </ng-template>

  <ng-template #moduleMissionCard let-item let-position="idx">
    <nz-card>
      {{ item?.metier?.metier?.libelle | carLimitation : 24 }}
    </nz-card>
  </ng-template>

  <ng-template #moduleDetails>
    <app-module-details
      [module]="module"
      (update)="onUpdate($event)"
    ></app-module-details>
  </ng-template>

  <ng-template #moduleSuivis>
    <app-table-suivis
      [source]="suivisSource$ | async"
      (add)="addSuivi($event, module)"
      (update)="updateSuivi($event, module)"
      (delete)="deleteSuivi($event, module)"
    ></app-table-suivis>
  </ng-template>

  <ng-template #moduleDocuments>
    <app-table-documents
      [source$]="documentsSource$"
      (add)="addDocument($event, module)"
      (delete)="deleteDocument($event, module)"
    ></app-table-documents>
  </ng-template>

  <!-- MODULE HEADERS -->
  <!-- MODULE HEADER -->
  <ng-template #moduleFormTitle>
    <app-header-title
      [icon]="'dynamic_feed'"
      [title]="title"
    ></app-header-title>
  </ng-template>

  <!-- ACTION HEADER -->
  <ng-template #actionFormTitle>
    <app-header-title
      [icon]="'dashboard'"
      [title]="titleAction"
    ></app-header-title>
  </ng-template>

  <!-- MISSION HEADER -->
  <ng-template #missionFormTitle>
    <app-header-title
      [icon]="'work_history'"
      [title]="titleMission"
    ></app-header-title>
  </ng-template>

  <!-- PARTICIPANT HEADER -->
  <ng-template #participantFormTitle>
    <app-header-title
      [icon]="'group'"
      [title]="titleParticipant"
    ></app-header-title>
  </ng-template>
</ng-container>
