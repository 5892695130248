<form nz-form [formGroup]="itemform" nzLayout="vertical">
  <nz-divider
    nzOrientation="center"
    nzText="Titre"
    nzType="horizontal"
  ></nz-divider>
  <nz-row nzAlign="top" nzGutter="4" nzJustify="start">
    <nz-col nzSpan="24">
      <nz-form-item>
        <nz-form-control>
          <input
            nz-input
            formControlName="title"
            placeholder="Titre..."
            nzBorderless
          />
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>

  <nz-divider
    nzOrientation="center"
    [nzText]="sujetTitle"
    nzType="horizontal"
  ></nz-divider>
  <ng-template #sujetTitle>
    <button
      nz-button
      (click)="newSujet()"
      nzShape="round"
      nzBlock
      [disabled]="!itemform.valid"
    >
      <i nz-icon nzType="plus-circle"></i>
      <span>Sujets</span>
    </button>
  </ng-template>

  <div class="sujetsList">
    <nz-row
      nzAlign="top"
      nzGutter="2"
      nzJustify="center"
      formArrayName="sujets"
    >
      <div
        class="sujetsListContainer"
        *ngFor="let item of sujetsArray.controls; let i = index"
        [formGroupName]="i"
      >
        <nz-row nzAlign="top" nzGutter="4" nzJustify="start">
          <nz-col nzSpan="2">
            <mat-icon class="iconSujetItem">notes</mat-icon>
          </nz-col>

          <nz-col nzSpan="18">
            <nz-form-item nzRequired>
              <nz-form-control>
                <input
                  nz-input
                  formControlName="title"
                  placeholder="Axe, sujet..."
                  nzBorderless
                />
              </nz-form-control>
            </nz-form-item>
          </nz-col>
          <nz-col nzSpan="4">
            <button
              nz-button
              nzShape="circle"
              (click)="removeSujet(i)"
              nzDanger
            >
              <i nz-icon nzType="delete"></i>
            </button>
          </nz-col>
        </nz-row>
      </div>
    </nz-row>
  </div>

  <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
  <nz-row nzAlign="top" nzGutter="8" nzJustify="end">
    <nz-col nzSpan="16"></nz-col>
    <nz-col nzSpan="4">
      <button
        nz-button
        nzType="primary"
        (click)="onAdd()"
        [disabled]="!itemform.valid"
        *ngIf="isAdd"
      >
        <i nz-icon nzType="check-circle"></i>
        Ajouter
      </button>
      <button
        *ngIf="update && updateItem$ | async as updateItem"
        nz-button
        nzType="primary"
        (click)="onUpdate()"
        [disabled]="!itemform.valid"
      >
        <i nz-icon nzType="check-circle"></i>
        Modifier
      </button>
    </nz-col>
    <nz-col nzSpan="4">
      <button nz-button (click)="onCancel()" nzType="text">
        <i nz-icon nzType="check-circle"></i>
        Annuler
      </button>
    </nz-col>
  </nz-row>
</form>
