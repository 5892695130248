import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { format, fromUnixTime } from 'date-fns';
import { fr } from 'date-fns/locale';
import {
  chunk,
  flatten,
  groupBy,
  isNull,
  isUndefined,
  sum,
  last,
} from 'lodash';
import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-operation-dashboard-main',
  templateUrl: './operation-dashboard-main.component.html',
  styleUrls: ['./operation-dashboard-main.component.scss'],
})
export class OperationDashboardMainComponent implements OnInit {
  subscribe = new Subject();
  noExist = 'Non renseigné';
  //OPERATION
  operationTime$ = new BehaviorSubject<any>(null);
  operationBarByMonths$ = new BehaviorSubject<any>(null);
  operationBarByYears$ = new BehaviorSubject<any>(null);

  @Input() dashboard$: Observable<any> = of(null);

  constructor() {}

  ngOnInit(): void {
    this.setOperationDashboard();
  }

  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  chartsValues = (statistiques: any) => {
    const values = statistiques
      ? Object.values(statistiques).map((item) => {
          if (isNull(item) || isUndefined(item) || !item) {
            return this.noExist;
          }
          return item;
        })
      : [];

    const labels = statistiques
      ? Object.keys(statistiques).map((item) => {
          if (isNull(item) || isUndefined(item) || !item) {
            return this.noExist;
          }
          return item;
        })
      : [];

    return { values, labels };
  };

  itemsCounter = (items: any[]) => {
    return items.reduce((acc, cur) => {
      const curSize = items.filter((el) => el === cur).length;

      acc[cur] = curSize;
      return acc;
    }, {});
  };

  chartsTypePie = (count: any): any => {
    const transformToArray: any[] = Object.entries(count);
    const transformForChart: any[] = transformToArray.map((el) => ({
      name: el[0],
      value: el[1],
    }));

    return transformForChart;
  };

  setOperationDashboard(): void {
    this.dashboard$.pipe(takeUntil(this.subscribe)).subscribe((dashboard) => {
      if (!dashboard) return;
      const { planning } = dashboard;
      const objectifs: number = dashboard?.objectifs ? dashboard.objectifs : 0;
      const missions: any[] = dashboard?.missions?.data?.length
        ? dashboard?.missions?.data
        : [];

      const type: string = '';
      const respons: any = this.setOperationRealisations(planning, missions);
      this.setOperationTimeCharts(
        planning,
        objectifs,
        type,
        respons?.realisationsCumulByMonths
      );

      this.setOperationBarRealisationsByMonths(
        respons.planningToDatesByMonths,
        respons.realisationsByMonths
      );
      this.setOperationBarRealisationsByYears(
        respons.planningToDatesByYears,
        respons.realisationsByYears
      );
    });
  }

  setOperationTimeCharts(
    planning: any[],
    objectifs: number,
    type: string,
    realisations: number[]
  ): void {
    const dates = planning.map((date) =>
      format(fromUnixTime(date['seconds']), 'yyyy-MM', { locale: fr })
    );
    const size = dates?.length;

    const allPhases = this.setProjectionOptimisteCurve(dates, objectifs);
    let projectionResult: number[] = [];

    allPhases.reduce((acc, cur, index) => {
      let projectionResultSize: number = projectionResult?.length;
      let cumulation = Math.round(acc + cur);
      let isHigher: boolean = cumulation >= objectifs ? true : false;
      let isEnd: boolean = projectionResultSize === size - 2 ? true : false;
      let last = size - 1;
      let isLast: boolean = index === last || index > last ? true : false;
      if (isEnd) {
        projectionResult.push(objectifs);
      }

      if (isHigher) {
        projectionResult.push(objectifs);

        return acc + cur;
      }

      if (isLast) {
        projectionResult.push(objectifs);

        return 0;
      }

      projectionResult.push(cumulation);
      return acc + cur;
    });

    const operationTimer = {
      title: 'Répartition des réalisations',
      chartsTitle: '',
      type: 'time',
      data: {
        dates: dates,
        objectifs: projectionResult,
        realisations: realisations,
        objectifValue: objectifs,
      },
      center: ['50%', '70%'],
      radius: 120,
      height: '400px',

      option: {
        toolbox: true,
        dataView: true,
        saveAsImage: true,
        saveImageName: '',
        label: false,
        markPoint: true,
      },
    };

    this.operationTime$.next(operationTimer);
  }

  setOperationPhases(
    chunksDates: any[],
    planning: any[],
    objectifs: number
  ): any[] {
    const size: number = chunksDates.length - 1;
    const objectifsByMonths = objectifs / planning?.length;
    let counter: number[] = [];
    let counterSum = sum(counter);

    const estimation = chunksDates.map((array: any[], index: number) => {
      const isFirst = index <= 1 ? true : false;
      const isSecond = index <= 2 ? true : false;

      const isLast = index === size ? true : false;
      const isBeforeLast = index === size - 1 ? true : false;
      const isDescendo = index === size - 2 ? true : false;

      // if (isFirst) {
      //   const introduction = array.map((item: any, i: number) => {
      //     const introductionSize: number = array.length - 1;
      //     const isHalf: number = introductionSize / 2;
      //     if (index === 0 || index === 1 || index === 2) {
      //       counter.push(0);
      //       return 0;
      //     }
      //     const el =
      //       (objectifsByMonths / introductionSize) * introductionSize - i;
      //     counter.push(el);
      //     return el;
      //   });

      //   return introduction;
      // }

      if (isLast) {
        const maturite = array.map((item: any, i: number) => {
          const isLastDates =
            i === array.length - 1 ||
            i === array.length - 2 ||
            i === array.length - 3
              ? true
              : false;

          if (isLastDates) {
            counter.push(0);
            return 0;
          }
          const el = objectifsByMonths / (array.length - 3);
          counter.push(el);
          return el;
        });

        return maturite;
      }

      if (isDescendo) {
        const descendo = array.map((item: any, i: number) => {
          const descendoSize: number = array.length - 1;
          const isHalf: number = descendoSize / 2;

          if (i < isHalf) {
            const el = (objectifsByMonths / descendoSize) * (descendoSize + i);
            counter.push(el);
            return el;
          }

          const el = (objectifsByMonths / descendoSize) * (descendoSize - i);
          counter.push(el);
          return el;
        });

        return descendo;
      }

      if (isBeforeLast) {
        const beforeLast = array.map((item: any, i: number) => {
          const beforeLastSize: number = array.length - 1;
          const isHalf: number = beforeLastSize / 2;

          if (i < isHalf) {
            const el =
              (objectifsByMonths / beforeLastSize) * (beforeLastSize - i);
            counter.push(el);

            return el;
          }

          const el =
            (objectifsByMonths / beforeLastSize) * (beforeLastSize - i);
          counter.push(el);

          return el;
        });

        return beforeLast;
      }

      let croissanceDates: any[] = [];
      array.forEach((item: any, index: number) => {
        croissanceDates = [...croissanceDates, ...array];
      });

      let croissanceDatesSize: number = croissanceDates.length;
      let croissanceDatesChunkNumber: number = croissanceDatesSize / 3;
      const croissanceChunk = chunk(
        croissanceDates,
        croissanceDatesChunkNumber
      );

      const croissance = croissanceDates.map((item: any, i: number) => {
        const curveSize: number = croissanceDates.length - 1;
        const halfCurve: number = curveSize / 2;
        const thirdCurve: number = curveSize / 3;

        const objectifByDate = objectifsByMonths / halfCurve;
        if (i === 0 || i === 1 || i === 2 || i === 3 || i === 4 || i === 5) {
          return 0;
        }
        if (i < halfCurve) {
          const el = (objectifsByMonths / thirdCurve) * i;
          return el;
        }

        const el = objectifsByMonths / i;

        return el;
      });

      return croissance;
    });

    return flatten(estimation);
  }

  setProjectionOptimisteCurve(planning: string[], objectifs: number): any[] {
    const size: number = planning.length;
    const objectifsByMonth = Math.floor(objectifs / size);

    const lancement = Math.round(size * 0.05);
    const growth = Math.round(size * 0.05);
    const maturity = Math.round(size * 0.6);
    const declin = Math.round(size * 0.95);

    const lancementDates: string[] = planning.filter(
      (date, index) => index <= lancement
    );
    const lancementObjectifs = Math.round(
      objectifs * (lancementDates?.length / 100)
    );

    const objectifs_After_Lancement_ByMonth = Math.floor(
      objectifs / (size - lancementDates?.length)
    );

    const growthDates: string[] = planning.filter(
      (date, index) => index >= growth && index < maturity
    );

    const maturityDates: string[] = planning.filter(
      (date, index) => index >= maturity && index < declin
    );

    const declinDates: string[] = planning.filter(
      (date, index) => index >= declin
    );

    const growthObjectif = Math.round(objectifs * (growthDates?.length / 100));
    const maturityObjectif = Math.round(
      objectifs * (maturityDates?.length / 100)
    );
    const declinObjectif = Math.round(objectifs * (declinDates?.length / 100));

    let ascenssion = 0;

    const estimationLancement = lancementDates.map((date, i) => {
      const el = objectifsByMonth * (lancementDates?.length / 100) * i;

      return el;
    });
    const estimationGrowth = growthDates.map((date, i) => {
      const el = objectifsByMonth * (growthDates?.length / 100) * i;

      return el;
    });

    const estimationMaturity = maturityDates.map((date, i) => {
      const el = objectifsByMonth * (maturityDates?.length / 100) * i;

      return el;
    });

    const estimationDeclin = declinDates.map((date, i) => {
      const el = 0;

      return 0;
    });

    const estimation = planning.map((item: any, i: number) => {
      const isLancement = i <= lancement ? true : false;
      const isGrowth = i >= growth && i < maturity ? true : false;
      const isMature = i >= maturity && i < declin ? true : false;
      const isDeclin = i >= declin ? true : false;
      const isLastMonth: boolean = last(declinDates) === item ? true : false;

      if (isLancement) {
        const el = (objectifsByMonth / lancementDates?.length) * i;

        return 0;
      }
      if (isGrowth) {
        ascenssion = ascenssion + 2;
        const el = Math.floor(
          (objectifsByMonth / growthDates.length) * (ascenssion + i / 2)
        );
        return el;
      }

      if (isMature) {
        ascenssion = ascenssion - 0.05;
        const el = Math.floor(
          (objectifsByMonth / maturityDates.length) * (ascenssion - i)
        );

        return el > 0 ? el : 0;
      }

      if (isDeclin) {
        if (isLastMonth) {
          return 0;
        }

        const el = 0;

        return el;
      }

      return 0;
    });

    return estimation;
  }

  setOperationRealisations(planning: any[], missions: any[]): any {
    if (!planning?.length || !missions?.length) return [];
    const allMissions: any[][] = missions.map((mission) => mission?.hours);

    if (!allMissions?.length) return [];

    const transformsByMonths = allMissions.map((item) =>
      item.map((el: any) => ({
        date: format(fromUnixTime(el?.date['seconds']), 'MM/yyyy', {
          locale: fr,
        }),
        sum: el?.sum,
      }))
    );
    const transformsByYears = allMissions.map((item) =>
      item.map((el: any) => ({
        date: format(fromUnixTime(el?.date['seconds']), 'yyyy', {
          locale: fr,
        }),
        sum: el?.sum,
      }))
    );

    const groupByDatesByMonths = groupBy(flatten(transformsByMonths), 'date');
    const groupByDatesByYears = groupBy(flatten(transformsByYears), 'date');

    const groupToArrayByMonths = Object.entries(groupByDatesByMonths);
    const groupToArrayByYears = Object.entries(groupByDatesByYears);

    const groupMergeByMonths = groupToArrayByMonths.map((el: any) => {
      const date = el[0];
      const result = sum(el[1].map((i: any) => i.sum));
      return { date, result };
    });
    const groupMergeByYears = groupToArrayByYears.map((el: any) => {
      const date = el[0];
      const result = sum(el[1].map((i: any) => i.sum));
      return { date, result };
    });

    const planningToDatesByMonths: string[] = planning.map((date: any) =>
      format(fromUnixTime(date['seconds']), 'MM/yyyy', { locale: fr })
    );
    const planningToDatesByYears: string[] = groupMergeByYears.map(
      (el: any) => el?.date
    );

    const realisationsByMonths: number[] = planningToDatesByMonths.map(
      (el: any) => {
        const e: any = groupMergeByMonths.find((i) => i.date === el);
        if (!e) return 0;

        return e?.result;
      }
    );

    const realisationsByYears: number[] = groupMergeByYears.map(
      (el: any) => el?.result
    );

    const realisationsCumulByMonths: any[] = [];
    const realisationsCumulByYears: any[] = [];

    realisationsByMonths.reduce((acc, cur) => {
      realisationsCumulByMonths.push(acc);
      return acc + cur;
    });
    realisationsByYears.reduce((acc, cur) => {
      realisationsCumulByYears.push(acc);
      return acc + cur;
    });

    return {
      realisationsByMonths,
      realisationsByYears,
      planningToDatesByMonths,
      planningToDatesByYears,
      realisationsCumulByMonths,
      realisationsCumulByYears,
    };
  }

  setProjectionPessimisteCurve(planning: string[], objectifs: number): any[] {
    const size: number = planning.length;
    const sizeCroissance: number = size - 7;
    const objectifsByMonth = objectifs / sizeCroissance;

    const phaseDemarrage = Math.round(size * 0.125);
    const phaseAscendante = Math.round(size * 0.0875);

    let ascenssion = 0.15;
    let croissance = 0.285;
    let ascendantCoefficient = 0.025;
    let croissanceCoefficient = 0.25;
    let declinCoefficient = 0.085;

    const estimation = planning.map((item: any, i: number) => {
      const demarrageMonths = i <= phaseDemarrage ? true : false;
      const ascendantMonths =
        i > phaseDemarrage && i <= phaseDemarrage + phaseAscendante
          ? true
          : false;

      const croissanceMonths =
        i > phaseDemarrage + phaseAscendante &&
        i <= phaseDemarrage + phaseDemarrage + phaseDemarrage
          ? true
          : false;

      if (demarrageMonths) {
        return 0;
      }

      if (ascendantMonths) {
        ascenssion = ascenssion + ascendantCoefficient;
        const el = objectifsByMonth * ascenssion;
        return el;
      }
      if (croissanceMonths) {
        croissance = croissance + croissanceCoefficient;
        const el = objectifsByMonth * croissance;
        return el;
      }
      croissance = croissance - declinCoefficient;
      const el = croissance >= 0 ? objectifsByMonth * croissance : 0;

      return el;
    });

    return estimation;
  }

  setOperationBarRealisationsByMonths(planning: any[], realisations: []): void {
    const items = {
      title: 'Répartition des réalisations par mois',
      chartsTitle: '',
      type: 'barSimpler',
      data: {
        xAxis: planning,
        yAxis: realisations,
      },
      typeValue: 'hours',
      fontSize: 14,
      numberObWord: 7,
      center: ['50%', '70%'],
      radius: 120,
      height: '400px',
    };

    this.operationBarByMonths$.next(items);
  }

  setOperationBarRealisationsByYears(planning: any[], realisations: []): void {
    const items = {
      title: 'Répartition des réalisations par année',
      chartsTitle: '',
      type: 'barSimpler',
      data: {
        xAxis: planning,
        yAxis: realisations,
      },
      typeValue: 'hours',

      center: ['50%', '70%'],
      radius: 120,
      height: '400px',
    };

    this.operationBarByYears$.next(items);
  }
}
