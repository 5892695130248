<nz-row nzAlign="middle" nzGutter="8" nzJustify="end">
  <nz-col nzSpan="12"></nz-col>
  <nz-col nzSpan="4">
    <button
      nz-button
      nzBlock
      (click)="$event.stopPropagation(); onCancel()"
      nzType="text"
    >
      Annuler
    </button>
  </nz-col>
  <nz-col nzSpan="4">
    <button
      nz-button
      nzType="default"
      nzBlock
      (click)="
        $event.stopPropagation();
        onStepBack(currentStep, isAdd, isMetierUpdate, isContratUpdate)
      "
    >
      Précédent
    </button>
  </nz-col>
  <nz-col nzSpan="4">
    <!-- ADRESSE VALIDATION -->
    <button
      nz-button
      nzType="default"
      nzBlock
      [disabled]="!missionForm.value?.adresse"
      (click)="
        $event.stopPropagation();
        onStepNext(currentStep, isAdd, isMetierUpdate, isContratUpdate)
      "
      *ngIf="currentStep === 0"
    >
      Suivant
    </button>

    <!-- METIER VALIDATION -->
    <button
      nz-button
      nzType="default"
      nzBlock
      [disabled]="!missionForm.value?.metier?.code"
      (click)="
        $event.stopPropagation();
        onStepNext(currentStep, isAdd, isMetierUpdate, isContratUpdate)
      "
      *ngIf="currentStep === 1"
    >
      Suivant
    </button>

    <!-- STEP DESCRIPTION && COMPÉTENCES -->
    <!-- ON ADD -->

    <button
      nz-button
      nzType="default"
      nzBlock
      (click)="
        $event.stopPropagation();
        onStepNext(currentStep, isAdd, isMetierUpdate, isContratUpdate)
      "
      *ngIf="
        isMetierUpdate !== 'UPDATE_METIER' && currentStep > 1 && currentStep < 4
      "
    >
      Suivant
    </button>

    <button
      nz-button
      nzType="default"
      nzBlock
      (click)="
        $event.stopPropagation();
        onStepNext(currentStep, isAdd, isMetierUpdate, isContratUpdate)
      "
      *ngIf="
        isMetierUpdate === 'UPDATE_METIER' && currentStep > 1 && currentStep < 3
      "
    >
      Suivant
    </button>

    <!-- ON UPDATE -->

    <button
      nz-button
      nzType="default"
      nzBlock
      [disabled]="!missionForm.value?.metier"
      (click)="$event.stopPropagation(); onUpdateValidation()"
      *ngIf="isMetierUpdate === 'UPDATE_METIER' && currentStep === 3"
    >
      Valider
    </button>

    <!-- CONTRAT TYPE VALIDATION -->
    <button
      nz-button
      nzType="default"
      nzBlock
      [disabled]="!missionForm.value?.contrat?.type"
      (click)="
        $event.stopPropagation();
        onStepNext(currentStep, isAdd, isMetierUpdate, isContratUpdate)
      "
      *ngIf="currentStep === 4"
    >
      Suivant
    </button>

    <!-- CONTRAT REMUNERATION VALIDATION -->
    <button
      nz-button
      nzType="default"
      nzBlock
      [disabled]="
        !missionForm.value?.contrat?.salaire ||
        !missionForm.value?.contrat?.work_price
      "
      (click)="
        $event.stopPropagation();
        onStepNext(currentStep, isAdd, isMetierUpdate, isContratUpdate)
      "
      *ngIf="currentStep === 5"
    >
      Suivant
    </button>

    <!-- CONTRAT TEMPS DE TRAVAIL VALIDATION -->
    <button
      nz-button
      nzType="default"
      nzBlock
      [disabled]="
        !missionForm.value?.contrat?.amplitude_horaire ||
        !missionForm.value?.contrat?.duree_hebdomadaire ||
        !missionForm.value?.contrat?.horaireStartAM ||
        !missionForm.value?.contrat?.horaireEndAM ||
        !missionForm.value?.contrat?.horaireStartPM ||
        !missionForm.value?.contrat?.horaireEndPM
      "
      (click)="
        $event.stopPropagation();
        onStepNext(currentStep, isAdd, isMetierUpdate, isContratUpdate)
      "
      *ngIf="currentStep === 6"
    >
      Suivant
    </button>

    <!-- ON ADD -->
    <button
      nz-button
      nzBlock
      (click)="$event.stopPropagation(); onValidation()"
      *ngIf="isContratUpdate !== 'UPDATE_CONTRAT' && currentStep === 7"
      [disabled]="!missionForm.valid"
    >
      <span>Suivant</span>
    </button>

    <!-- ON UPDATE -->
    <button
      nz-button
      nzBlock
      (click)="$event.stopPropagation(); onUpdateValidation()"
      *ngIf="isContratUpdate === 'UPDATE_CONTRAT' && currentStep === 7"
      [disabled]="!missionForm.value?.contrat"
    >
      <span>Valider</span>
    </button>
  </nz-col>
</nz-row>
