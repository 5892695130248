import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { ContactTask } from './contact-task.model';

export const loadContactTasks = createAction(
  '[ContactTask/API] Load ContactTasks', 
  props<{ contactTasks: ContactTask[] }>()
);

export const addContactTask = createAction(
  '[ContactTask/API] Add ContactTask',
  props<{ contactTask: ContactTask }>()
);

export const upsertContactTask = createAction(
  '[ContactTask/API] Upsert ContactTask',
  props<{ contactTask: ContactTask }>()
);

export const addContactTasks = createAction(
  '[ContactTask/API] Add ContactTasks',
  props<{ contactTasks: ContactTask[] }>()
);

export const upsertContactTasks = createAction(
  '[ContactTask/API] Upsert ContactTasks',
  props<{ contactTasks: ContactTask[] }>()
);

export const updateContactTask = createAction(
  '[ContactTask/API] Update ContactTask',
  props<{ contactTask: Update<ContactTask> }>()
);

export const updateContactTasks = createAction(
  '[ContactTask/API] Update ContactTasks',
  props<{ contactTasks: Update<ContactTask>[] }>()
);

export const deleteContactTask = createAction(
  '[ContactTask/API] Delete ContactTask',
  props<{ id: string }>()
);

export const deleteContactTasks = createAction(
  '[ContactTask/API] Delete ContactTasks',
  props<{ ids: string[] }>()
);

export const clearContactTasks = createAction(
  '[ContactTask/API] Clear ContactTasks'
);
