<ng-container *ngIf="(besoins | size) > 3; else besoinsTags">
  <nz-tag nzColor="geekblue">
    {{ besoins[0]?.libelle | carLimitation : caractereLimitation }}
  </nz-tag>
  <nz-tag nzColor="geekblue" *ngFor="let el of besoins">
    {{ besoins[1]?.libelle | carLimitation : caractereLimitation }}
  </nz-tag>
  <nz-tag nzColor="geekblue" *ngFor="let el of besoins">
    {{ besoins[2]?.libelle | carLimitation : caractereLimitation }}
  </nz-tag>
  <nz-badge [nzCount]="(besoins | size) - 3"></nz-badge>
</ng-container>

<ng-template #besoinsTags>
  <nz-tag nzColor="geekblue" *ngFor="let el of besoins">
    {{ el?.libelle | carLimitation : caractereLimitation }}
  </nz-tag>
</ng-template>
