<app-metiers
  [type]="'grandDomaine'"
  [showTitle]="false"
  [showMetiers]="true"
  [showAppelations]="true"
  [showAppelation]="true"
  [showSoftskills]="true"
  [hideCancel]="false"
  [hideValidation]="true"
  [showQualification]="true"
  [showAppelationDetail]="true"
  [showAdresse]="false"
  (appelation)="onAdd($event)"
></app-metiers>
