import { Component, OnInit, Input, ViewContainerRef } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { messageState } from '../../store/message.reducer';
import * as fromMessageActions from '../../store/message.actions';
import { MessageId, MessageItem } from '../../store/message.model';
import { isToday, fromUnixTime } from 'date-fns';
import { Observable, of } from 'rxjs';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';
import { NavigationState } from 'src/app/components/navigation/store/navigation.reducer';
import * as fromNavigationAction from 'src/app/components/navigation/store/navigation.actions';

@Component({
  selector: 'app-message-item',
  templateUrl: './message-item.component.html',
  styleUrls: ['./message-item.component.scss'],
})
export class MessageItemComponent implements OnInit {
  user$: Observable<any> = of(null);
  statistiqueSize: string = '14px';
  statistiqueSizeMax: string = '18px';
  @Input() message: MessageId | any = null;
  @Input() fullscreen: boolean = false;

  constructor(
    private storeMessage: Store<messageState>,
    private userStore: Store<UserState>,
    private navigationStore: Store<NavigationState>
  ) {}

  ngOnInit(): void {
    this.get__USER();
  }

  get__USER(): void {
    this.user$ = this.userStore.pipe(select(fromUserSelector.user));
  }

  getAvatar__MEMBERS(message: MessageId): string[] {
    if (!message) {
      return [];
    }

    const auteurAvatar = message.auteur.avatar;
    const interlocuteursAvatars = message.interlocuteurs.map(
      (member) => member.avatar
    );

    return [auteurAvatar, ...interlocuteursAvatars];
  }

  onDisplay(message: MessageId, fullscreen: boolean): any {
    if (!message) {
      return null;
    }

    if (!fullscreen) {
      this.storeMessage.dispatch(
        fromMessageActions.loadMessage({ id: message.id })
      );

      this.navigationStore.dispatch(
        fromNavigationAction.openMessageDrawer({ event: true })
      );

      // this.storeMessage.dispatch(fromMessageActions.showMessage());
    } else {
      this.storeMessage.dispatch(
        fromMessageActions.loadMessage({ id: message.id })
      );
    }
  }

  onDelete(message: MessageId, fullscreen: boolean): any {
    if (!message) {
      return null;
    }
    this.storeMessage.dispatch(
      fromMessageActions.deleteMessage({ id: message.id })
    );
  }

  onCancel(): any {
    return null;
  }
}
