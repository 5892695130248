import { Pipe, PipeTransform } from '@angular/core';
import { fromUnixTime, isToday } from 'date-fns';

@Pipe({
  name: 'isToday',
})
export class IsTodayPipe implements PipeTransform {
  transform(item: any): boolean {
    if (!item) {
      return false;
    }

    if (isToday(fromUnixTime(item['seconds']))) {
      return true;
    } else {
      return false;
    }
  }
}
