import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';

interface TabSet {
  name: string;
  index?: number;
  template: TemplateRef<any> | any;
  count?: number;
}

@Component({
  selector: 'app-item-tabset',
  templateUrl: './item-tabset.component.html',
  styleUrls: ['./item-tabset.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemTabsetComponent implements OnInit, OnChanges {
  tabBarBtnColor: string = '#8c8c8c';
  default: string = '#bfbfbf';
  avatarBackgroundColor: string = '#1890ff';
  @Input() selectedIndex: number = 0;
  @Input() tabs: TabSet[] = [];
  @Input() extraTemplate: TemplateRef<any> | any = null;
  @Input() tabBarBgColor: string = '#FFFFFF';
  @Output() select = new EventEmitter<any>(false);

  constructor() {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {}

  onSelect(index: number): void {
    this.selectedIndex = index;
    this.select.emit(index);
  }
  onSelectChange(event: any): void {}
}
