<form nz-form [formGroup]="livrableForm" nzLayout="vertical">
  <nz-row nzAlign="top" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="10">
      <nz-form-item>
        <nz-form-label nzRequired>Titre</nz-form-label>
        <nz-form-control>
          <input
            nz-input
            formControlName="title"
            placeholder="Titre"
            nzBorderless
          />
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col nzSpan="8">
      <nz-form-item>
        <nz-form-label>Pilote / contributeur</nz-form-label>
        <nz-form-control>
          <input
            nz-input
            formControlName="pilote"
            placeholder="Pilote"
            nzBorderless
          />
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col nzSpan="6">
      <nz-form-item>
        <nz-form-label nzRequired>Échéance</nz-form-label>
        <nz-form-control>
          <nz-date-picker
            nzBorderless
            formControlName="echeance"
            nzAllowClear="true"
            nzFormat="dd/MM/yyyy"
          ></nz-date-picker>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>
  <nz-row nzAlign="top" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="24">
      <nz-form-item>
        <nz-form-control>
          <textarea
            nz-input
            nzBorderless
            formControlName="description"
            placeholder="Description"
            [nzAutosize]="{ minRows: 4, maxRows: 4 }"
          ></textarea>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>
</form>

<nz-row nzAlign="top" nzGutter="8" nzJustify="end">
  <nz-col nzSpan="16"></nz-col>
  <nz-col nzSpan="4">
    <button
      *ngIf="isAdd"
      nz-button
      (click)="onAdd()"
      nzType="primary"
      [disabled]="!livrableForm.valid"
      nzBlock
    >
      Ajouter
    </button>
    <button
      *ngIf="isUpdate"
      nz-button
      (click)="onUpdate()"
      nzType="primary"
      [disabled]="!livrableForm.valid"
      nzBlock
    >
      Modifier
    </button>
  </nz-col>
  <nz-col nzSpan="4">
    <button nz-button (click)="onCancel()" nzType="text" nzBlock>
      Annuler
    </button>
  </nz-col>
</nz-row>
