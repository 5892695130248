import { createFeatureSelector, createSelector } from '@ngrx/store';
import { format, fromUnixTime } from 'date-fns';
import { groupBy } from 'lodash';

import {
  ParticipantPlanningState,
  participantPlanningsFeatureKey,
  selectAll,
} from './participant-planning.reducer';

export const selectParticipantPlanningState =
  createFeatureSelector<ParticipantPlanningState>(
    participantPlanningsFeatureKey
  );

export const events = createSelector(selectParticipantPlanningState, selectAll);
export const eventsGroupByDate = createSelector(
  selectParticipantPlanningState,
  (state: ParticipantPlanningState) => {
    const eventsArray: any = Object.values(state.entities);
    const groupByDate: any = groupBy(eventsArray, 'createAt');
    const groupToArray: any[] = Object.values(groupByDate);
    return groupToArray;
  }
);

export const event = createSelector(
  selectParticipantPlanningState,
  (state: ParticipantPlanningState) => state.event
);
export const loading = createSelector(
  selectParticipantPlanningState,
  (state: ParticipantPlanningState) => state.loading
);

export const joursFeries = createSelector(
  selectParticipantPlanningState,
  (state: ParticipantPlanningState) => state.joursFeries
);

export const filterMoments = createSelector(
  selectParticipantPlanningState,
  (state: ParticipantPlanningState) => state.filterMoments
);

export const error = createSelector(
  selectParticipantPlanningState,
  (state: ParticipantPlanningState) => state.error
);

const convertTimestampToDate = (timestamp: {
  seconds: number;
  nanoseconds: number;
}): string => {
  const date: Date = fromUnixTime(timestamp.seconds);
  const dateFormated: string = format(date, 'MM/yyyy');
  return dateFormated;
};
