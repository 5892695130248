import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ContactTask } from './contact-task.model';
import * as ContactTaskActions from './contact-task.actions';

export const contactTasksFeatureKey = 'contactTasks';

export interface State extends EntityState<ContactTask> {
  // additional entities state properties
}

export const adapter: EntityAdapter<ContactTask> = createEntityAdapter<ContactTask>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export const reducer = createReducer(
  initialState,
  on(ContactTaskActions.addContactTask,
    (state, action) => adapter.addOne(action.contactTask, state)
  ),
  on(ContactTaskActions.upsertContactTask,
    (state, action) => adapter.upsertOne(action.contactTask, state)
  ),
  on(ContactTaskActions.addContactTasks,
    (state, action) => adapter.addMany(action.contactTasks, state)
  ),
  on(ContactTaskActions.upsertContactTasks,
    (state, action) => adapter.upsertMany(action.contactTasks, state)
  ),
  on(ContactTaskActions.updateContactTask,
    (state, action) => adapter.updateOne(action.contactTask, state)
  ),
  on(ContactTaskActions.updateContactTasks,
    (state, action) => adapter.updateMany(action.contactTasks, state)
  ),
  on(ContactTaskActions.deleteContactTask,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(ContactTaskActions.deleteContactTasks,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(ContactTaskActions.loadContactTasks,
    (state, action) => adapter.setAll(action.contactTasks, state)
  ),
  on(ContactTaskActions.clearContactTasks,
    state => adapter.removeAll(state)
  ),
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
