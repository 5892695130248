import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'utilities',
})
export class UtilitiesPipe implements PipeTransform {
  transform(value: any[], arg1: string, arg2: string): any[] {
    if (!value) {
      return [];
    }

    if (arg1 === 'civility') {
      const civilityData = value.find((item) => item.id === 'civilityList');
      return civilityData.list.sort();
    }

    if (arg1 === 'actions') {
      const actionData = value.find((item) => item.id === 'actionsUtility');
      if (arg2 === 'etat') {
        return actionData.etat.sort();
      }
      if (arg2 === 'roles') {
        return actionData.roles.sort();
      }
      if (arg2 === 'type') {
        return actionData.type.sort();
      }
    }

    if (arg1 === 'courriel') {
      const courrielData = value.find((item) => item.id === 'courrielType');
      return courrielData.list.sort();
    }

    if (arg1 === 'desinscription') {
      const desinscriptionData = value.find(
        (item) => item.id === 'desinscriptionList'
      );
      return desinscriptionData.list.sort();
    }

    if (arg1 === 'document') {
      const docData = value.find((item) => item.id === 'document');
      if (arg2 === 'type') {
        return docData.typeList.sort();
      }

      return [];
    }

    if (arg1 === 'entite') {
      const entityData = value.find((item) => item.id === 'entityUtility');

      if (arg2 === 'adhesion') {
        return entityData.adhesion.sort();
      }

      if (arg2 === 'engagements') {
        return entityData.engagements.sort();
      }

      if (arg2 === 'origine') {
        return entityData.origine.sort();
      }

      if (arg2 === 'secteur') {
        return entityData.secteur.sort();
      }

      if (arg2 === 'status') {
        return entityData.status.sort();
      }
      if (arg2 === 'type') {
        return entityData.type.sort();
      }
    }

    if (arg1 === 'events') {
      const eventData = value.find((item) => item.id === 'itemEventUtility');

      if (arg2 === 'object') {
        return eventData.object.sort();
      }

      if (arg2 === 'type') {
        return eventData.type.sort();
      }
    }

    if (arg1 === 'module') {
      const moduleData = value.find((item) => item.id === 'moduleUtility');
      return moduleData.type.sort();
    }

    if (arg1 === 'note') {
      const noteData = value.find((item) => item.id === 'noteUtility');

      if (arg2 === 'action') {
        return noteData.actionType.sort();
      }

      if (arg2 === 'type') {
        return noteData.type.sort();
      }
    }

    if (arg1 === 'participant') {
      const participantData = value.find(
        (item) => item.id === 'participantUtility'
      );

      if (arg2 === 'civility') {
        return participantData.civility.sort();
      }

      if (arg2 === 'communication') {
        return participantData.communication.sort();
      }
      if (arg2 === 'maladie') {
        return participantData.couvertureMaladie.sort();
      }

      if (arg2 === 'criteres') {
        return participantData.criteres.sort();
      }
      if (arg2 === 'web') {
        return participantData.languageWeb.sort();
      }

      if (arg2 === 'matrimonial') {
        return participantData.matrimonial.sort();
      }
      if (arg2 === 'minima') {
        return participantData.minimaSociaux.sort();
      }

      if (arg2 === 'mobility') {
        return participantData.mobility.sort();
      }
      if (arg2 === 'diplome') {
        return participantData.niveauDiplome.sort();
      }

      if (arg2 === 'francais') {
        return participantData.niveauFrancais.sort();
      }
      if (arg2 === 'numerique') {
        return participantData.numerique.sort();
      }

      if (arg2 === 'parcours') {
        return participantData.parcoursType.sort();
      }

      if (arg2 === 'pays') {
        return participantData.pays.sort();
      }
      if (arg2 === 'reseaux') {
        return participantData.reseauxSociaux.sort();
      }

      if (arg2 === 'sante') {
        return participantData.sante.sort();
      }
      if (arg2 === 'sexe') {
        return participantData.sexe.sort();
      }
      if (arg2 === 'sorties') {
        return participantData.sortiesPositives.sort();
      }

      if (arg2 === 'status') {
        return participantData.status.sort();
      }

      if (arg2 === 'sejour') {
        return participantData.titreSejour.sort();
      }
    }

    return [];
  }
}
