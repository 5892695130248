<div *ngIf="suivi" class="space-y-4 -mt-2">
  <div class="navbar">
    <div class="w-24">
      <app-suivi-type [suivi]="suivi"></app-suivi-type>
    </div>
    <div class="w-48">
      <p class="text-base font-bold tracking-wider break-keep">
        {{ suivi?.object }}
      </p>
    </div>

    <div class="w-96">
      <p class="text-base tracking-wider break-keep">
        Titre : {{ suivi?.title | carLimitation : 60 }}
      </p>
    </div>
  </div>

  <div
    class="w-full overflow-y-auto p-2 bg-slate-50 rounded-xl"
    [ngStyle]="{ height: size }"
  >
    <div [innerHTML]="suivi?.textHtml | nosanitizerpipe"></div>
  </div>

  <div class="w-full flex items-end justify-end space-x-2">
    <div>
      <button class="btn btn-sm btn-ghost" (click)="onCancel()">Annuler</button>
    </div>
    <div>
      <button class="btn btn-sm btn-primary" (click)="onUpdate(suivi)">
        Modifier
      </button>
    </div>
  </div>
</div>
