import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arraySort',
})
export class ArraySortPipe implements PipeTransform {
  transform(value: any[], direction: string): any {
    if (!value || !value?.length) {
      return null;
    }

    if (direction === 'asc') {
      return value.sort(function (a, b) {
        return a - b;
      });
    }

    if (direction === 'des') {
      return value.sort(function (a, b) {
        return b - a;
      });
    } else {
      return value;
    }
  }
}
