<nz-card nzBorderless>
  <form nz-form [formGroup]="todoProjetproForm">
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="24">
        <nz-form-item>
          <nz-form-label nzRequired>Titre</nz-form-label>
          <nz-form-control>
            <input
              nz-input
              formControlName="title"
              nzBorderless
              placeholder="Titre"
              class="projetpro_todo_form_title"
            />
          </nz-form-control>
        </nz-form-item>
      </nz-col>
    </nz-row>
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="12">
        <nz-form-item>
          <nz-form-label nzRequired>Démarrage</nz-form-label>
          <nz-form-control>
            <nz-date-picker
              formControlName="dateStart"
              nzBorderless
              [nzFormat]="dateFormat"
              nzAllowClear="true"
              nzPlaceHolder="Démarrage"
            ></nz-date-picker>
          </nz-form-control>
        </nz-form-item>
      </nz-col>
      <nz-col nzSpan="12">
        <nz-form-item>
          <nz-form-label nzRequired>Échéance</nz-form-label>
          <nz-form-control>
            <nz-date-picker
              formControlName="dateStart"
              nzBorderless
              [nzFormat]="dateFormat"
              nzAllowClear="true"
              nzPlaceHolder="Échéance"
            ></nz-date-picker>
          </nz-form-control>
        </nz-form-item>
      </nz-col>
    </nz-row>
    <nz-divider
      nzOrientation="center"
      nzType="horizontal"
      [nzText]="todoTitle"
    ></nz-divider>
    <ng-template #todoTitle>
      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col nzSpan="16">
          <button nz-button nzShape="circle" (click)="addTodo()">
            <mat-icon>add</mat-icon>
          </button>
        </nz-col>

        <nz-col nzSpan="8">
          <nz-badge [nzCount]="todos.value?.length"></nz-badge>
        </nz-col>
      </nz-row>
    </ng-template>

    <div class="todosContainer" formArrayName="todos">
      <ng-container *ngIf="todosArray.controls?.length; else noTodos">
        <div
          *ngFor="let todo of todosArray.controls; let i = index"
          class="projetproTodoList"
        >
          <nz-row
            nzAlign="middle"
            nzGutter="4"
            nzJustify="start"
            [formGroupName]="i"
          >
            <nz-col [nzSpan]="18">
              <nz-form-item>
                <nz-form-control>
                  <input
                    nz-input
                    formControlName="title"
                    nzBorderless
                    placeholder="Tâche..."
                  />
                </nz-form-control>
              </nz-form-item>
            </nz-col>

            <nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control>
                  <label nz-checkbox formControlName="completed"
                    >Réalisée</label
                  >
                </nz-form-control>
              </nz-form-item>
            </nz-col>
            <nz-col [nzSpan]="2">
              <button
                nz-button
                nzBlock
                class="projetproTodoList_itemBtn"
                (click)="removeTodo(i)"
                nzType="text"
                nzShape="circle"
              >
                <mat-icon class="iconRemoveTodo">delete</mat-icon>
              </button>
            </nz-col>
          </nz-row>
        </div>
      </ng-container>
    </div>

    <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="16"> </nz-col>
      <nz-col nzSpan="4">
        <button
          typz="submit"
          nz-button
          nzBlock
          nzType="primary"
          [disabled]="!todoProjetproForm.valid"
          (click)="$event.stopPropagation(); onAdd()"
        >
          Ajouter
        </button>
      </nz-col>
      <nz-col nzSpan="4">
        <button nz-button nzBlock nzType="text" (click)="onCancel()">
          <h5 nz-typography>Annuler</h5>
        </button>
      </nz-col>
    </nz-row>
  </form>
</nz-card>

<ng-template #noTodos>
  <app-no-result
    [icon]="'task'"
    [notitle]="'Aucun tâche ajoutée'"
    [isButton]="false"
  ></app-no-result>
</ng-template>
