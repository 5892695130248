import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Task } from './task.model';
import * as TaskActions from './task.actions';

export const tasksFeatureKey = 'tasks';

export interface TaskState extends EntityState<any> {
  filters: string[];
  isFiltered: boolean;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>();

export const initialState: TaskState = adapter.getInitialState({
  filters: ['Tout', 'Mes tâches', "Aujourd'hui", 'Cette semaine', 'Ce mois'],
  isFiltered: false,
});

export const reducer = createReducer(
  initialState,
  on(TaskActions.addTask, (state, action) =>
    adapter.addOne(action.task, state)
  ),
  on(TaskActions.upsertTask, (state, action) =>
    adapter.upsertOne(action.task, state)
  ),
  on(TaskActions.addTasks, (state, action) =>
    adapter.addMany(action.tasks, state)
  ),
  on(TaskActions.upsertTasks, (state, action) =>
    adapter.upsertMany(action.tasks, state)
  ),
  on(TaskActions.updateTask, (state, action) =>
    adapter.updateOne(action.task, state)
  ),
  on(TaskActions.updateTasks, (state, action) =>
    adapter.updateMany(action.tasks, state)
  ),
  on(TaskActions.deleteTask, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(TaskActions.deleteTasks, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(TaskActions.loadTasks, (state, action) =>
    adapter.setAll(action.tasks, state)
  ),
  on(TaskActions.clearTasks, (state) => adapter.removeAll(state))
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
