import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import {
  OperationNoteSubtitle,
  OperationNoteSubtitleId,
} from './operation-note-subtitle.model';
import * as OperationNoteSubtitleActions from './operation-note-subtitle.actions';

export const operationNoteSubtitlesFeatureKey = 'operationNoteSubtitles';

export interface OperationNoteSubtitleState
  extends EntityState<OperationNoteSubtitleId> {
  loading: boolean;
  error: any;
  subtitle: OperationNoteSubtitleId | any;
}

export const adapter: EntityAdapter<OperationNoteSubtitleId> =
  createEntityAdapter<OperationNoteSubtitleId>();

export const initialState: OperationNoteSubtitleState = adapter.getInitialState(
  {
    loading: false,
    error: undefined,
    subtitle: undefined,
  }
);

export const reducer = createReducer(
  initialState,
  //LOADING
  on(
    OperationNoteSubtitleActions.loadingOperationNoteSubtitles,
    (state, action) => {
      return {
        ...state,
        loading: action.loading,
      };
    }
  ),

  //LOAD
  on(
    OperationNoteSubtitleActions.loadOperationNoteSubtitles,
    (state, action) => {
      return {
        ...state,
        loading: true,
      };
    }
  ),
  on(
    OperationNoteSubtitleActions.loadOperationNoteSubtitlesSuccess,
    (state, action) => adapter.setAll(action.substitles, state)
  ),
  on(
    OperationNoteSubtitleActions.loadOperationNoteSubtitlesSuccess,
    (state, action) => {
      return {
        ...state,
        loading: false,
      };
    }
  ),

  on(
    OperationNoteSubtitleActions.loadOperationNoteSubtitlesFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
  ),

  //ADD
  on(
    OperationNoteSubtitleActions.addOperationNoteSubtitleFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
      };
    }
  ),

  //UPDATE

  on(
    OperationNoteSubtitleActions.updateOperationNoteSubtitle,
    (state, action) => adapter.updateOne(action.subtitle, state)
  ),
  on(
    OperationNoteSubtitleActions.updateOperationNoteSubtitleFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
      };
    }
  ),

  //DELETE
  on(
    OperationNoteSubtitleActions.deleteOperationNoteSubtitle,
    (state, action) => adapter.removeOne(action.id, state)
  ),

  //DELETE ALL
  on(
    OperationNoteSubtitleActions.deleteAllOperationNoteSubtitles,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(
    OperationNoteSubtitleActions.deleteAllOperationNoteSubtitlesFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
      };
    }
  ),

  on(OperationNoteSubtitleActions.clearOperationNoteSubtitles, (state) =>
    adapter.removeAll(state)
  )
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
