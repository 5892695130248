import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'any',
})
export class AllParticipantDiagnosticGQL extends Query<Response> {
  document = gql`
    query ParticipantDiagnostic($id: ID!) {
      participantDiagnostic(id: $id) {
        id
        dateStart
        dateUpdate
        auteur {
          avatar
          displayName
          fonction
          setting {
            color
          }
        }
        userUpdate {
          avatar
          displayName
          fonction
          setting {
            color
          }
        }
        besoins {
          libelle
        }
        presentation
        administratif {
          isFrein
          isFse
          isPassIae
          numCaiCir
          numbCarteSejour
          dateEntree
          dateInscription
          dateRecrutement
          typeCarteSejour {
            info
            titre
            type
          }
          criteres1 {
            justificatif
            label
            value
          }
          criteres2 {
            info
            item
            pieceAfournir
          }
        }

        famille {
          enfantMoyenDeGarde
          enfantScolarise
          enfantsAcharges
          familiale
          isFrein
        }

        logement {
          adresse {
            adresse {
              properties {
                label
                score
                housenumber
                id
                type
                name
                postcode
                citycode
                x
                y
                city
                context
                importance
                street
              }
              geometry {
                type
                coordinates
              }
            }
            complement
            qpv
          }
          chezTier
          centreHebergement
          autre
          autreLieu
          proprietaire
          lieuPublic
          chezParent
          locataire
          hotel
          nesaitpas
          isQPV
          isQVA
          isZRR
          precision
          isFrein
        }
        mobilite {
          geo_mobility {
            codeTypeTerritoire
            libelleTypeTerritoire
          }
          permis {
            code
            libelle
          }
          transport_mode
          contrainte_horaire
          isFrein
        }
        sante {
          couvertureMaladie
          handicap
          isFrein
          nirSecuriteSociale
          sante
        }
        justice {
          caseJudiciaire
          executionDePeineEnCours
          isFrein
          justice
          suiviSpipPjj
        }
        droit {
          neConnaitSesDroitsAucuneDemarche
          difficulteDeRenouvellementDeDroit
          connaitSesDroitsDemarcheEnCours
          beneficieDeSesDroits
          precision
          consentementRGPD
          droitDeTravail
          droitAimage
          isFrein
        }
        qualification {
          isFrein
          isFrenchLevelFle
          isFrenchLevelIlletrisme
          isFrenchLevelLaborieux
          isFrenchLevelParfait
          maitriseFrancais {
            info
            item
            type
          }
          qualification {
            label
            value
          }
        }
        numerique {
          bureautique_level
          internet
          isFrein
          mobile
          numerique_level {
            item
            type
          }
          ordinateur
        }
        ressources {
          besoinPrimaireLibelle
          besoinsPrimaires
          isFrein
          saitGererSonBudget
          saitGererSonBudgetLibelle
          surendettementAvecDossier
          surendettementAvecDossierLibelle
          surendettementSansDossier
          surendettementSansDossierLibelle
        }
        aptitudes {
          softskills {
            details
            summary
          }
        }
        disponibilite {
          disponibiliteAorganiser
          disponibiliteAorganiserLibelle
          disponibiliteFaibleVoireDifficile
          disponibiliteFaibleVoireDifficileLibelle
          disponibiliteImmediatementAvec
          disponibiliteImmediatementAvecLibelle
          disponibiliteImmediatementSans
          disponibiliteImmediatementSansLibelle
          isFrein
          precision
        }
        posture {
          aBesoinDaccompagnement
          incapaciteAoccuperUnPoste
          isFrein
          peutOccuperUnPosteAvecCondition
          peutOccuperUnPosteSansCondition
          precision
        }
      }
    }
  `;
}
