import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { OperationTeamState } from '../../store/operation-team.reducer';
import * as fromOperationTeamAction from '../../store/operation-team.actions';
import * as fromOperationTeamSelector from '../../store/operation-team.selectors';
import { OperationState } from 'src/app/components/operation/store/operation.reducer';
import * as fromOperationSelector from 'src/app/components/operation/store/operation.selectors';
import { Observable, of, Subject, takeUntil } from 'rxjs';
import { OperationId } from 'src/app/components/operation/store/operation.model';
import { NzSelectModeType } from 'ng-zorro-antd/select';

@Component({
  selector: 'app-operation-team-selector',
  templateUrl: './operation-team-selector.component.html',
  styleUrls: ['./operation-team-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationTeamSelectorComponent implements OnInit, OnDestroy {
  subscribe = new Subject();
  operation$: Observable<OperationId | any> = of(null);
  team$: Observable<any> = of(null);
  loading$: Observable<boolean> = of(false);
  userSelected: any = null;
  default: string = '#bfbfbf';
  avatarColor: string = 'white';

  @Input() mode: NzSelectModeType = 'default';
  @Output() select = new EventEmitter();
  constructor(
    private operationTeamStore: Store<OperationTeamState>,
    private operationStore: Store<OperationState>
  ) {}

  ngOnInit(): void {
    this.getOperation();
    this.getOperationTeam();
    this.loadOperationTeam();
    this.getLoading();
  }
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getOperation(): void {
    this.operation$ = this.operationStore.select(
      fromOperationSelector.operation
    );
  }
  loadOperationTeam(): void {
    this.operation$.pipe(takeUntil(this.subscribe)).subscribe((operation) => {
      if (!operation) return;
      const { id } = operation;
      this.operationStore.dispatch(
        fromOperationTeamAction.loadOperationTeamsSelection({ id })
      );
    });
  }

  getOperationTeam(): void {
    this.team$ = this.operationTeamStore.select(
      fromOperationTeamSelector.teamSelection
    );
  }

  getLoading(): void {
    this.loading$ = this.operationTeamStore.select(
      fromOperationTeamSelector.loading
    );
  }

  trackBy(index: number, item: any): number {
    return item.id;
  }

  onSelect(item: any): void {
    if (!item) return;
    this.select.emit(item);
  }
}
