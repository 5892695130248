import { UtilitiesPlanningService } from './../../../../service/utilities/utilities-planning.service';
import {
  NzNotificationPlacement,
  NzNotificationService,
} from 'ng-zorro-antd/notification';
import { UtilitiesService } from './../../../../service/utilities/utilities.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromUtilitiesAction from './utilities.actions';
import { of } from 'rxjs';
import { mergeMap, catchError, map } from 'rxjs/operators';

const NOTIFICATION_PLACEMENT: NzNotificationPlacement = 'topRight';
@Injectable()
export class UtilitiesEffects {
  //**REFERENTIELS UTILITIES **//

  //CIVILITIES
  loadCivilities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUtilitiesAction.loadCivilities),
      mergeMap(() =>
        this.utilitiesService.getCivilitiesUtilities().pipe(
          map((respons: any) => {
            const civilities: string[] = respons.data.referentiel_civilities;
            return fromUtilitiesAction.loadCivilitiesSuccess({
              civilities,
            });
          }),
          catchError((error) => {
            this.notification.error('Erreur', error.message, {
              nzPlacement: NOTIFICATION_PLACEMENT,
              nzDuration: 6000,
              nzAnimate: true,
            });
            return of(fromUtilitiesAction.loadCivilitiesFailure({ error }));
          })
        )
      )
    )
  );
  //COMMUNICATIONS
  loadCommunications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUtilitiesAction.loadCommunication),
      mergeMap(() =>
        this.utilitiesService.getCommunicationUtilities().pipe(
          map((respons: any) => {
            const communications: string[] =
              respons.data.referentiel_communication;
            return fromUtilitiesAction.loadCommunicationSuccess({
              communications,
            });
          }),
          catchError((error) => {
            this.notification.error('Erreur', error.message, {
              nzPlacement: NOTIFICATION_PLACEMENT,
              nzDuration: 6000,
              nzAnimate: true,
            });
            return of(fromUtilitiesAction.loadCommunicationFailure({ error }));
          })
        )
      )
    )
  );
  //NUMERIQUE
  loadNumerique$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUtilitiesAction.loadNumeriqueReferentiel),
      mergeMap(() =>
        this.utilitiesService.getNumeriqueUtilities().pipe(
          map((snap: any) => {
            const utilities = snap.data.referentiel_numerique_level;
            return fromUtilitiesAction.loadNumeriqueReferentielSuccess({
              utilities,
            });
          }),
          catchError((error) =>
            of(
              fromUtilitiesAction.loadNumeriqueReferentielFailure({
                error,
              })
            )
          )
        )
      )
    )
  );
  //CRITERES
  loadCriteres$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUtilitiesAction.loadCriteresReferentiel),
      mergeMap(() =>
        this.utilitiesService.getCriteresUtilities().pipe(
          map((snap: any) => {
            const utilities = snap.data.referentiel_criteres;
            return fromUtilitiesAction.loadCriteresReferentielSuccess({
              utilities,
            });
          }),
          catchError((error) =>
            of(
              fromUtilitiesAction.loadCriteresReferentielFailure({
                error,
              })
            )
          )
        )
      )
    )
  );
  //COUNTRIES
  loadCountry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUtilitiesAction.loadCountryReferentiel),
      mergeMap(() =>
        this.utilitiesService.getCountriesUtilities().pipe(
          map((snap: any) => {
            const utilities = snap.data.referentiel_countries;

            return fromUtilitiesAction.loadCountryReferentielSuccess({
              utilities,
            });
          }),
          catchError((error) =>
            of(
              fromUtilitiesAction.loadCountryReferentielFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  //SORTIES
  loadSortie$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUtilitiesAction.loadSortieReferentiel),
      mergeMap(() =>
        this.utilitiesService.getSortieUtilities().pipe(
          map((snap: any) => {
            const utilities = snap.data.sortieFilters;

            return fromUtilitiesAction.loadSortieReferentielSuccess({
              utilities,
            });
          }),
          catchError((error) =>
            of(
              fromUtilitiesAction.loadSortieReferentielFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  //MOBILITY
  loadMobility$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUtilitiesAction.loadMobilityReferentiel),
      mergeMap(() =>
        this.utilitiesService.getMobilityUtilities().pipe(
          map((snap: any) => {
            const utilities = snap.data.referentiel_mobility;

            return fromUtilitiesAction.loadMobilityReferentielSuccess({
              utilities,
            });
          }),
          catchError((error) =>
            of(
              fromUtilitiesAction.loadMobilityReferentielFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  //SEJOUR
  loadSejour$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUtilitiesAction.loadSejourReferentiel),
      mergeMap(() =>
        this.utilitiesService.getSejourUtilities().pipe(
          map((snap: any) => {
            const utilities = snap.data.referentiel_titres_sejours;

            return fromUtilitiesAction.loadSejourReferentielSuccess({
              utilities,
            });
          }),
          catchError((error) =>
            of(
              fromUtilitiesAction.loadSejourReferentielFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  //FRENCH LEVEL
  loadFrenchLevel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUtilitiesAction.loadFrenchLevelReferentiel),
      mergeMap(() =>
        this.utilitiesService.getFrenchLevelUtilities().pipe(
          map((snap: any) => {
            const utilities = snap.data.referentiel_french_level;

            return fromUtilitiesAction.loadFrenchLevelReferentielSuccess({
              utilities,
            });
          }),
          catchError((error) =>
            of(
              fromUtilitiesAction.loadFrenchLevelReferentielFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  //DIPLOMA
  loadDiploma$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUtilitiesAction.loadDiplomaReferentiel),
      mergeMap(() =>
        this.utilitiesService.getDiplomaUtilities().pipe(
          map((snap: any) => {
            const utilities = snap.data.referentiel_diploma;

            return fromUtilitiesAction.loadDiplomaReferentielSuccess({
              utilities,
            });
          }),
          catchError((error) =>
            of(
              fromUtilitiesAction.loadDiplomaReferentielFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  //PERIOD INSCRIPTION
  loadPeriodInscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUtilitiesAction.loadPeriodInscriptionReferentiel),
      mergeMap(() =>
        this.utilitiesService.getPeriodInscriptionUtilities().pipe(
          map((snap: any) => {
            const utilities = snap.data.referentiel_inscription_period;

            return fromUtilitiesAction.loadPeriodInscriptionReferentielSuccess({
              utilities,
            });
          }),
          catchError((error) =>
            of(
              fromUtilitiesAction.loadPeriodInscriptionReferentielFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  //ALLOCATION
  loadAllocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUtilitiesAction.loadAllocationReferentiel),
      mergeMap(() =>
        this.utilitiesService.getAllocationUtilities().pipe(
          map((snap: any) => {
            const utilities = snap.data.referentiel_allocation_type;

            return fromUtilitiesAction.loadAllocationReferentielSuccess({
              utilities,
            });
          }),
          catchError((error) =>
            of(
              fromUtilitiesAction.loadAllocationReferentielFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  //RESEAUX SOCIAUX
  loadReseauSociaux$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUtilitiesAction.loadReseauxSociauxReferentiel),
      mergeMap(() =>
        this.utilitiesService.getAllocationUtilities().pipe(
          map((snap: any) => {
            const utilities = snap.data.referentiel_allocation_type;

            return fromUtilitiesAction.loadReseauxSociauxReferentielSuccess({
              utilities,
            });
          }),
          catchError((error) =>
            of(
              fromUtilitiesAction.loadReseauxSociauxReferentielFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  //COUVETURE MALADIE
  loadCouverureMaladie$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUtilitiesAction.loadCouvertureMaladieReferentiel),
      mergeMap(() =>
        this.utilitiesService.getCouvertureMaladieUtilities().pipe(
          map((snap: any) => {
            const utilities = snap.data.referentiel_couverture_maladie;

            return fromUtilitiesAction.loadCouvertureMaladieReferentielSuccess({
              utilities,
            });
          }),
          catchError((error) =>
            of(
              fromUtilitiesAction.loadCouvertureMaladieReferentielFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  //MINIMA
  loadMinima$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUtilitiesAction.loadMinimaReferentiel),
      mergeMap(() =>
        this.utilitiesService.getMinimaSociauxUtilities().pipe(
          map((snap: any) => {
            const utilities = snap.data.referentiel_minima_processes;

            return fromUtilitiesAction.loadMinimaReferentielSuccess({
              utilities,
            });
          }),
          catchError((error) =>
            of(
              fromUtilitiesAction.loadMinimaReferentielFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  //SANTE
  loadSante$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUtilitiesAction.loadSanteReferentiel),
      mergeMap(() =>
        this.utilitiesService.getSanteUtilities().pipe(
          map((snap: any) => {
            const utilities = snap.data.referentiel_sante;

            return fromUtilitiesAction.loadSanteReferentielSuccess({
              utilities,
            });
          }),
          catchError((error) =>
            of(
              fromUtilitiesAction.loadSanteReferentielFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  //PERMIS
  loadPermisReferentiel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUtilitiesAction.loadPermisReferentiel),
      mergeMap(() =>
        this.utilitiesService.getSanteUtilities().pipe(
          map((snap: any) => {
            const utilities = snap.data.referentiel_sante;

            return fromUtilitiesAction.loadPermisReferentielSuccess({
              utilities,
            });
          }),
          catchError((error) =>
            of(
              fromUtilitiesAction.loadPermisReferentielFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  //GENRE
  loadGenre$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUtilitiesAction.loadGenreReferentiel),
      mergeMap(() =>
        this.utilitiesService.getSanteUtilities().pipe(
          map((snap: any) => {
            const utilities = snap.data.referentiel_sante;

            return fromUtilitiesAction.loadGenreReferentielSuccess({
              utilities,
            });
          }),
          catchError((error) =>
            of(
              fromUtilitiesAction.loadGenreReferentielFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  //STATUS
  loadStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUtilitiesAction.loadStatusReferentiel),
      mergeMap(() =>
        this.utilitiesService.getStatusUtilities().pipe(
          map((snap: any) => {
            const utilities = snap.data.referentiel_status;

            return fromUtilitiesAction.loadStatusReferentielSuccess({
              utilities,
            });
          }),
          catchError((error) =>
            of(
              fromUtilitiesAction.loadStatusReferentielFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  //TRANSPORT
  loadTransport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUtilitiesAction.loadTransportReferentiel),
      mergeMap(() =>
        this.utilitiesService.getTransportUtilities().pipe(
          map((snap: any) => {
            const utilities = snap.data.referentiel_transport_mode;

            return fromUtilitiesAction.loadTransportReferentielSuccess({
              utilities,
            });
          }),
          catchError((error) =>
            of(
              fromUtilitiesAction.loadTransportReferentielFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  //GEO MOBILITE
  loadGeoMobilite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUtilitiesAction.loadGeoMobiliteReferentiel),
      mergeMap(() =>
        this.utilitiesService.getGeographiqueMobiliteUtilities().pipe(
          map((snap: any) => {
            const utilities = snap.data.referentiel_mobilite_geographique;

            return fromUtilitiesAction.loadGeoMobiliteReferentielSuccess({
              utilities,
            });
          }),
          catchError((error) =>
            of(
              fromUtilitiesAction.loadGeoMobiliteReferentielFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  //JURICIAIRE
  loadJuriciaire$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUtilitiesAction.loadJudiciaireReferentiel),
      mergeMap(() =>
        this.utilitiesService.getJudiciaireUtilities().pipe(
          map((snap: any) => {
            const utilities = snap.data.referentiel_judiciaire;

            return fromUtilitiesAction.loadJudiciaireReferentielSuccess({
              utilities,
            });
          }),
          catchError((error) =>
            of(
              fromUtilitiesAction.loadJudiciaireReferentielFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  //MATROMINIALE
  loadMatrimonial$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUtilitiesAction.loadMatrimonialReferentiel),
      mergeMap(() =>
        this.utilitiesService.getMatrimonialeUtilities().pipe(
          map((snap: any) => {
            const utilities = snap.data.referentiel_matrimonial;

            return fromUtilitiesAction.loadMatrimonialReferentielSuccess({
              utilities,
            });
          }),
          catchError((error) =>
            of(
              fromUtilitiesAction.loadMatrimonialReferentielFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  //HANDICAP
  loadHandicap$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUtilitiesAction.loadHandicapReferentiel),
      mergeMap(() =>
        this.utilitiesService.gethandicapUtilities().pipe(
          map((snap: any) => {
            const utilities = snap.data.referentiel_handicap;

            return fromUtilitiesAction.loadHandicapReferentielSuccess({
              utilities,
            });
          }),
          catchError((error) =>
            of(
              fromUtilitiesAction.loadHandicapReferentielFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  //LEVEL NUMERIQUE
  loadLevelBureautique$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUtilitiesAction.loadLevelBureautiqueReferentiel),
      mergeMap(() =>
        this.utilitiesService.getLevelBureautiqueUtilities().pipe(
          map((snap: any) => {
            const utilities = snap.data.referentiel_levelNumerique;

            return fromUtilitiesAction.loadLevelBureautiqueReferentielSuccess({
              utilities,
            });
          }),
          catchError((error) =>
            of(
              fromUtilitiesAction.loadLevelBureautiqueReferentielFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  //SOFTSKILLS
  loadSoftskills$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUtilitiesAction.loadSoftskillsReferentiel),
      mergeMap(() =>
        this.utilitiesService.getSoftskillsUtilities().pipe(
          map((snap: any) => {
            const utilities = snap.data.getSoftSkills;

            return fromUtilitiesAction.loadSoftskillsReferentielSuccess({
              utilities,
            });
          }),
          catchError((error) =>
            of(
              fromUtilitiesAction.loadSoftskillsReferentielFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  //**DIAGNOSTIC */
  //**DIAGNOSTIC TYPES */
  loadDiagnosticTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUtilitiesAction.loadDiagnosticTypes),
      mergeMap(() =>
        this.utilitiesService.getDiagnosticTypes().pipe(
          map((snap: any) => {
            const types = snap.data.referentiel_diagnostic_types;

            return fromUtilitiesAction.loadDiagnosticTypesSuccess({
              types,
            });
          }),
          catchError((error) =>
            of(
              fromUtilitiesAction.loadDiagnosticTypesFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  //**AUTRES

  loadUtilities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUtilitiesAction.loadUtilities),
      mergeMap(() =>
        this.utilitiesService.getAll().pipe(
          map((utilities: any) =>
            fromUtilitiesAction.loadUtilitiesSuccess({
              utilities,
            })
          ),
          catchError((error) =>
            of(fromUtilitiesAction.loadUtilitiesFailure({ error }))
          )
        )
      )
    )
  );

  //**PARTICIPANT**//
  loadParticipantUtilities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUtilitiesAction.loadParticipantUtilities),
      mergeMap(() =>
        this.utilitiesService.getParticipantUtilities().pipe(
          map((snap: any) => {
            const utilities = snap.data.aidesEligiblesFilters;

            return fromUtilitiesAction.loadParticipantUtilitiesSuccess({
              utilities,
            });
          }),
          catchError((error) =>
            of(fromUtilitiesAction.loadParticipantUtilitiesFailure({ error }))
          )
        )
      )
    )
  );

  loadParticipantUtilitiesIdentity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUtilitiesAction.loadParticipantUtilitiesIdentity),
      mergeMap(() =>
        this.utilitiesService.getParticipantUtilitiesIdentity().pipe(
          map((snap: any) => {
            const utilities = snap.data.aidesEligiblesFilters;
            return fromUtilitiesAction.loadParticipantUtilitiesIdentitySuccess({
              utilities,
            });
          }),
          catchError((error) =>
            of(
              fromUtilitiesAction.loadParticipantUtilitiesIdentityFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  loadParticipantUtilitiesSituation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUtilitiesAction.loadParticipantUtilitiesSituation),
      mergeMap(() =>
        this.utilitiesService.getParticipantUtilitiesSituation().pipe(
          map((snap: any) => {
            const utilities = snap.data.aidesEligiblesFilters;
            return fromUtilitiesAction.loadParticipantUtilitiesSituationSuccess(
              {
                utilities,
              }
            );
          }),
          catchError((error) =>
            of(
              fromUtilitiesAction.loadParticipantUtilitiesSituationFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  //**ENTITES**
  loadEntiteUtilities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUtilitiesAction.loadEntiteUtilities),
      mergeMap(() =>
        this.utilitiesService.getEntiteUtilities().pipe(
          map(
            (data: any) => {
              const utilities = data.aidesEligiblesFilters;

              return fromUtilitiesAction.loadEntiteUtilitiesSuccess({
                utilities,
              });
            },
            catchError((error) =>
              of(fromUtilitiesAction.loadEntiteUtilitiesFailure({ error }))
            )
          )
        )
      )
    )
  );

  //**CONTACTS**
  loadContactUtilities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUtilitiesAction.loadContactUtilities),
      mergeMap(() =>
        this.utilitiesService.getContactUtilities().pipe(
          map(
            (utilities: any) =>
              fromUtilitiesAction.loadContactUtilitiesSuccess({
                utilities,
              }),
            catchError((error) =>
              of(fromUtilitiesAction.loadContactUtilitiesFailure({ error }))
            )
          )
        )
      )
    )
  );

  //**MISSIONS**
  loadMissionUtilitiesContrat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUtilitiesAction.loadMissionUtilities),
      mergeMap(() =>
        this.utilitiesService.getMissionUtilities().pipe(
          map((snap: any) => {
            const utilities = snap.data.missionContratFilters;

            return fromUtilitiesAction.loadMissionUtilitiesSuccess({
              utilities,
            });
          }),
          catchError((error) =>
            of(fromUtilitiesAction.loadMissionUtilitiesFailure({ error }))
          )
        )
      )
    )
  );

  loadMissionUtilitiesDeplacement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUtilitiesAction.loadMissionDeplacementUtilities),
      mergeMap(() =>
        this.utilitiesService.getMissionContratDeplacementUtilities().pipe(
          map((snap: any) => {
            const utilities = snap.data.missionDeplacementFilters;

            return fromUtilitiesAction.loadMissionDeplacementUtilitiesSuccess({
              utilities,
            });
          }),
          catchError((error) =>
            of(
              fromUtilitiesAction.loadMissionDeplacementUtilitiesFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  //**REFERENTIEL OPERATION**
  //OPERATIONS
  loadOperationUtilities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUtilitiesAction.loadOperationUtilities),
      mergeMap(() =>
        this.utilitiesService.getProjetUtilities().pipe(
          map(
            (utilities: any) =>
              fromUtilitiesAction.loadOperationUtilitiesSuccess({
                utilities,
              }),
            catchError((error) =>
              of(fromUtilitiesAction.loadOperationUtilitiesFailure({ error }))
            )
          )
        )
      )
    )
  );

  //OPERATIONS MARCHÉS TYPE
  loadOperationMarcheType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUtilitiesAction.loadOperationMarcheTypes),
      mergeMap(() =>
        this.utilitiesService.getOperationMarcheTypes().pipe(
          map((snap: any) => {
            const types = snap.data.operationType;

            return fromUtilitiesAction.loadOperationMarcheTypesSuccess({
              types,
            });
          }),
          catchError((error) =>
            of(fromUtilitiesAction.loadOperationMarcheTypesFailure({ error }))
          )
        )
      )
    )
  );

  //OPERATIONS OBJECTIF TYPE
  loadOperationObjectifTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUtilitiesAction.loadOperationObjectifTypes),
      mergeMap(() =>
        this.utilitiesService.getOperationObjectifTypes().pipe(
          map((snap: any) => {
            const objectifs = snap.data.objectifType;

            return fromUtilitiesAction.loadOperationObjectifTypesSuccess({
              objectifs,
            });
          }),
          catchError((error) =>
            of(fromUtilitiesAction.loadOperationObjectifTypesFailure({ error }))
          )
        )
      )
    )
  );

  //PLANNINGTYPE
  loadPlanningTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUtilitiesAction.loadPlanningfTypes),
      mergeMap(() =>
        this.utilitiesPlanningService.getUtilPlanningType().pipe(
          map((snap: any) => {
            const types = snap.data.referentielPlanningTypes;

            return fromUtilitiesAction.loadPlanningfTypesSuccess({
              types,
            });
          }),
          catchError((error) =>
            of(fromUtilitiesAction.loadPlanningfTypesFailure({ error }))
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private utilitiesService: UtilitiesService,
    private notification: NzNotificationService,
    private utilitiesPlanningService: UtilitiesPlanningService
  ) {}
}
