import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'navigationDelete',
})
export class NavigationDeletePipe implements PipeTransform {
  transform(navigation: any[]): string {
    if (!navigation) {
      return '';
    }

    const item: string = navigation.find(
      (element) => element.type === 'item'
    ).display;

    return `Voulez-vous supprimer ${item} ?`;
  }
}
