import { OperationService } from './operation.service';
import { EntiteSMALLid } from './../components/entite/store/entite.model';
import { OperationId } from './../components/operation/store/operation.model';
import { Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  doc,
  docSnapshots,
  collectionChanges,
  addDoc,
  setDoc,
  deleteDoc,
  serverTimestamp,
  CollectionReference,
  DocumentReference,
  DocumentChange,
  DocumentSnapshot,
  Timestamp,
  query,
  where,
  arrayUnion,
  arrayRemove,
  collectionSnapshots,
  getDocs,
  DocumentData,
} from '@angular/fire/firestore';
import { Observable, of, from, EMPTY } from 'rxjs';
import { switchMap, mergeMap, map, catchError } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Functions, httpsCallable } from '@angular/fire/functions';
import {
  Storage,
  ref,
  uploadBytesResumable,
  deleteObject,
  getDownloadURL,
  StorageReference,
  UploadTask,
  UploadMetadata,
} from '@angular/fire/storage';
import { UserState } from '../components/user/store/user.reducer';
import * as fromUserSelector from '../components/user/store/user.selectors';
import { v4 as uuidV4 } from 'uuid';
import { format, fromUnixTime } from 'date-fns';
import { fr } from 'date-fns/locale';

@Injectable({
  providedIn: 'any',
})
export class OperationEntitesService {
  constructor(
    private db: Firestore,
    private storage: Storage,
    private fns: Functions,
    private userStore: Store<UserState>,
    private operationService: OperationService
  ) {}

  getAll(operationId: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !operationId) {
            return of([]);
          } else {
            const dbCollection: CollectionReference<DocumentData> = collection(
              this.db,
              `organisations/${user.organisation.id}/operations/${operationId}/entites`
            );

            const changes = collectionSnapshots(dbCollection).pipe(
              map((changes) => {
                return changes.map((snap) => {
                  const data = snap.data();
                  const id = snap.id;
                  return { id, ...data };
                });
              })
            );

            return changes;
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }
  getSelectedAll(operationId: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !operationId) {
            return of([]);
          } else {
            const dbCollection: CollectionReference<DocumentData> = collection(
              this.db,
              `organisations/${user.organisation.id}/operations/${operationId}/entites`
            );

            const changes = getDocs(dbCollection).then((snap) => {
              return snap.docs.map((doc) => {
                const data = doc.data();
                const id = doc.id;
                return { id, ...data };
              });
            });

            return changes;
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  getOne(operationId: string, id: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) {
            return of(null);
          } else {
            const dbCollection: CollectionReference<DocumentData> = collection(
              this.db,
              `organisations/${user.organisation.id}/operations/${operationId}/entites`
            );

            const documentRef: DocumentReference<DocumentData> = doc(
              dbCollection,
              id
            );

            return docSnapshots(documentRef).pipe(
              map((snap: DocumentSnapshot<DocumentData>) => {
                const data = snap.data();
                const id = snap.id;
                return { id, ...data };
              }),
              catchError((error) => EMPTY)
            );
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  addOne(
    operation: OperationId,
    entite: EntiteSMALLid,
    intervention: any
  ): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) {
            return of(null);
          } else {
            const dbDocument: DocumentReference<DocumentData> = doc(
              this.db,
              `organisations/${user.organisation.id}/operations/${operation.id}/entites/${entite.id}`
            );

            const newEntite = {
              intervention: { ...intervention },
              entite: { ...entite },
            };

            return from(
              setDoc(dbDocument, newEntite)
                .then(() => this.getOne(operation.id, entite.id))
                .catch((err) => of(err))
            ).pipe(mergeMap((res) => res));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  updateOne(
    operationId: string,
    entite: any,
    intervention: any
  ): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) {
            return of(null);
          } else {
            const dbDocument: DocumentReference<DocumentData> = doc(
              this.db,
              `organisations/${user.organisation.id}/operations/${operationId}/entites/${entite.id}`
            );

            return from(
              setDoc(dbDocument, entite)
                .then(() => `Entité du projet modifié.`)
                .catch((err) => err)
            ).pipe(mergeMap((res) => of(res)));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  deleteOne(operationId: string, id: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) {
            return of(null);
          } else {
            const dbDocument: DocumentReference<DocumentData> = doc(
              this.db,
              `organisations/${user.organisation.id}/operations/${operationId}/entites/${id}`
            );

            return from(
              deleteDoc(dbDocument)
                .then(() => `Entité retitée du projet.`)
                .catch((err) => err)
            ).pipe(mergeMap((res) => of(res)));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  deleteAll(operationId: string, ids: string[]): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) {
            return of(null);
          } else {
            return EMPTY;
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }
}
