import { Injectable } from '@angular/core';
import { QueryEvolutionTypesContratGQLService } from './../graphql/queries/query-evolution-types-contrat-gql.service';
import { QueryJoursContratGQLService } from './../graphql/queries/query-jours-contrat-gql.service';
import { QueryMobilityGeoContratGQLService } from './../graphql/queries/query-mobility-geo-contrat-gql.service';
import { QueryTypesHorairesContratGQLService } from './../graphql/queries/query-types-horaires-contrat-gql.service';
import { QueryComplementSalaireContratGQLService } from './../graphql/queries/query-complement-salaire-contrat-gql.service';
import { QueryNiveauNumeriqueContratGQLService } from './../graphql/queries/query-niveau-numerique-contrat-gql.service';
import { QueryNiveauPermisContratGQLService } from './../graphql/queries/query-niveau-permis-contrat-gql.service';
import { QueryNiveauSalaireContratGQLService } from './../graphql/queries/query-niveau-salaire-contrat-gql.service';
import { QueryNiveauExperienceContratGQLService } from './../graphql/queries/query-niveau-experience-contrat-gql.service';
import { QueryNaturesContratContratGQLService } from './../graphql/queries/query-natures-contrat-contrat-gql.service';
import { QueryTypesContratGQLService } from './../graphql/queries/query-types-contrat-gql.service';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'any',
})
export class ContratService {
  constructor(
    private queryTypesContratGQLService: QueryTypesContratGQLService,
    private queryNaturesContratContratGQLService: QueryNaturesContratContratGQLService,
    private queryNiveauExperienceContratGQLService: QueryNiveauExperienceContratGQLService,
    private queryNiveauSalaireContratGQLService: QueryNiveauSalaireContratGQLService,
    private queryNiveauPermisContratGQLService: QueryNiveauPermisContratGQLService,
    private queryNiveauNumeriqueContratGQLService: QueryNiveauNumeriqueContratGQLService,
    private queryComplementSalaireContratGQLService: QueryComplementSalaireContratGQLService,
    private queryTypesHorairesContratGQLService: QueryTypesHorairesContratGQLService,
    private queryMobilityGeoContratGQLService: QueryMobilityGeoContratGQLService,
    private queryJoursContratGQLService: QueryJoursContratGQLService,
    private queryEvolutionTypesContratGQLService: QueryEvolutionTypesContratGQLService
  ) {}

  getTypesContrats(): Observable<any> {
    return this.queryTypesContratGQLService.watch().valueChanges.pipe(
      map(({ data, loading, error }) => {
        return { data, loading, error };
      })
    );
  }
  getNatureContrats(): Observable<any> {
    return this.queryNaturesContratContratGQLService.watch().valueChanges.pipe(
      map(({ data, loading, error }) => {
        return { data, loading, error };
      })
    );
  }

  getNiveauExperience(): Observable<any> {
    return this.queryNiveauExperienceContratGQLService
      .watch()
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }
  getNiveauSalaire(): Observable<any> {
    return this.queryNiveauSalaireContratGQLService.watch().valueChanges.pipe(
      map(({ data, loading, error }) => {
        return { data, loading, error };
      })
    );
  }

  getPermis(): Observable<any> {
    return this.queryNiveauPermisContratGQLService.watch().valueChanges.pipe(
      map(({ data, loading, error }) => {
        return { data, loading, error };
      })
    );
  }

  getLevelNumerique(): Observable<any> {
    return this.queryNiveauNumeriqueContratGQLService.watch().valueChanges.pipe(
      map(({ data, loading, error }) => {
        return { data, loading, error };
      })
    );
  }

  getComplementSalaire(): Observable<any> {
    return this.queryComplementSalaireContratGQLService
      .watch()
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  getTypesHoraires(): Observable<any> {
    return this.queryTypesHorairesContratGQLService.watch().valueChanges.pipe(
      map(({ data, loading, error }) => {
        return { data, loading, error };
      })
    );
  }

  getMobilityGeographique(): Observable<any> {
    return this.queryMobilityGeoContratGQLService.watch().valueChanges.pipe(
      map(({ data, loading, error }) => {
        return { data, loading, error };
      })
    );
  }

  getJoursContrat(): Observable<any> {
    return this.queryJoursContratGQLService.watch().valueChanges.pipe(
      map(({ data, loading, error }) => {
        return { data, loading, error };
      })
    );
  }

  getEvolutionTypes(): Observable<any> {
    return this.queryEvolutionTypesContratGQLService.watch().valueChanges.pipe(
      map(({ data, loading, error }) => {
        return { data, loading, error };
      })
    );
  }
}
