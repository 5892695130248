import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ActionState, actionsFeatureKey, selectAll } from './action.reducer';

export const selectActionState =
  createFeatureSelector<ActionState>(actionsFeatureKey);

export const actions = createSelector(selectActionState, selectAll);

export const action = createSelector(
  selectActionState,
  (state: ActionState) => state.action
);

export const actionsModule = createSelector(
  selectActionState,
  (state: ActionState) => state.actionsByModule
);

export const loading = createSelector(
  selectActionState,
  (state: ActionState) => state.loading
);

export const utils = createSelector(
  selectActionState,
  (state: ActionState) => state.utils
);

export const types = createSelector(
  selectActionState,
  (state: ActionState) => state.actionUtils.types
);

export const roles = createSelector(
  selectActionState,
  (state: ActionState) => state.actionUtils.roles
);

export const etats = createSelector(
  selectActionState,
  (state: ActionState) => state.actionUtils.etats
);

export const error = createSelector(
  selectActionState,
  (state: ActionState) => state.error
);
