<app-metiers
  [type]="'grandDomaine'"
  [showMetiers]="true"
  [showAppelations]="true"
  [showAppelation]="true"
  [showSoftskills]="true"
  [hideCancel]="true"
  [hideValidation]="true"
  [showQualification]="true"
  [showAppelationDetail]="true"
  [participant]="participant"
  (validation)="onAdd($event)"
  (cancel)="onCancel()"
></app-metiers>

<nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
