import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

//GET OPERATION FICHE DIFFUSIONS
export const loadingOperationFicheDiffusions = createAction(
  '[OperationFicheDiffusion/COMPONENT] Loading Operation Fiche Diffusions',
  props<{ loading: boolean }>()
);

//GET OPERATION FICHE DIFFUSIONS
export const loadOperationFicheDiffusions = createAction(
  '[OperationFicheDiffusion/COMPONENT] Load Operation Fiche Diffusions',
  props<{ operationId: string; ficheId: string }>()
);
export const loadOperationFicheDiffusionsSuccess = createAction(
  '[OperationFicheDiffusion/API] Load Operation Fiche Diffusions Success',
  props<{ diffusions: any[] }>()
);
export const loadOperationFicheDiffusionsFailure = createAction(
  '[OperationFicheDiffusion/API] Load Operation Fiche Diffusions Failure',
  props<{ error: any }>()
);

//GET OPERATION FICHE DIFFUSION
export const loadOperationFicheDiffusion = createAction(
  '[OperationFicheDiffusion/COMPONENT] Load Operation Fiche Diffusion',
  props<{ operationId: string; ficheId: string; diffusionId: string }>()
);
export const loadOperationFicheDiffusionSuccess = createAction(
  '[OperationFicheDiffusion/API] Load Operation Fiche Diffusion Success',
  props<{ diffusion: any }>()
);
export const loadOperationFicheDiffusionFailure = createAction(
  '[OperationFicheDiffusion/API] Load Operation Fiche Diffusion Failure',
  props<{ error: any }>()
);

//ADD OPERATION FICHE DIFFUSION
export const addOperationFicheDiffusion = createAction(
  '[OperationFicheDiffusion/COMPONENT] Add Operation Fiche Diffusion',
  props<{ operationId: string; ficheId: string; diffusion: any }>()
);
export const addOperationFicheDiffusionSuccess = createAction(
  '[OperationFicheDiffusion/API] Add Operation Fiche Diffusion Success',
  props<{ diffusion: any }>()
);
export const addOperationFicheDiffusionFailure = createAction(
  '[OperationFicheDiffusion/API] Add Operation Fiche Diffusion Failure',
  props<{ error: any }>()
);

//UPDATE OPERATION FICHE DIFFUSION
export const updateOperationFicheDiffusion = createAction(
  '[OperationFicheDiffusion/COMPONENT] Update Operation Fiche Diffusion',
  props<{ operationId: string; ficheId: string; diffusion: Update<any> }>()
);
export const updateOperationFicheDiffusionSuccess = createAction(
  '[OperationFicheDiffusion/API] Update Operation Fiche Diffusion Success',
  props<{ diffusion: any }>()
);
export const updateOperationFicheDiffusionFailure = createAction(
  '[OperationFicheDiffusion/API] Update Operation Fiche Diffusion Failure',
  props<{ error: any }>()
);

//DELETE OPERATION FICHE DIFFUSION
export const deleteOperationFicheDiffusion = createAction(
  '[OperationFicheDiffusion/COMPONENT] Delete Operation Fiche Diffusion',
  props<{ operationId: string; ficheId: string; diffusionId: string }>()
);
export const deleteOperationFicheDiffusionSuccess = createAction(
  '[OperationFicheDiffusion/API] Delete Operation Fiche Diffusion Success',
  props<{ success: any }>()
);
export const deleteOperationFicheDiffusionFailure = createAction(
  '[OperationFicheDiffusion/API] Delete Operation Fiche Diffusion Failure',
  props<{ error: any }>()
);

//DELETE OPERATION FICHE DIFFUSIONS
export const deleteOperationFicheDiffusions = createAction(
  '[OperationFicheDiffusion/COMPONENT] Delete Operation Fiche Diffusions',
  props<{ operationId: string; ficheId: string; ids: string[] }>()
);
export const deleteOperationFicheDiffusionsSuccess = createAction(
  '[OperationFicheDiffusion/API] Delete Operation Fiche Diffusions Success',
  props<{ success: any }>()
);
export const deleteOperationFicheDiffusionsFailure = createAction(
  '[OperationFicheDiffusion/API] Delete Operation Fiche Diffusions Failure',
  props<{ error: any }>()
);

export const clearOperationFicheDiffusions = createAction(
  '[OperationFicheDiffusion/API] Clear OperationFicheDiffusions'
);
