import { Observable, of } from 'rxjs';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { OperationId } from '../../store/operation.model';
import { Store } from '@ngrx/store';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';

@Component({
  selector: 'app-operation-actions',
  templateUrl: './operation-actions.component.html',
  styleUrls: ['./operation-actions.component.scss'],
})
export class OperationActionsComponent implements OnInit {
  user$: Observable<any> = of(null);
  tooltipMouseEnterDelay: number = 1;
  space: number = 600;
  @Input() operation: OperationId | any = null;
  @Output() select = new EventEmitter<string>(false);
  constructor(private userStore: Store<UserState>) {}

  ngOnInit(): void {
    this.getUser();
  }

  getUser(): void {
    this.user$ = this.userStore.select(fromUserSelector.user);
  }

  onSelect(event: string): void {
    this.select.emit(event);
  }
}
