<app-item-tabset
  [tabs]="[
    {
      name: 'Entitéss',
      index: 0,
      template: favorisEntites
    },
    {
      name: 'Participants',
      index: 1,
      template: favorisParticipants
    },
    {
      name: 'Métiers',
      index: 2,
      template: favorisMetiers
    }
  ]"
></app-item-tabset>
<ng-template #favorisEntites>
  <ng-container *ngIf="(entites$ | async)?.data; else noEntites">
    <app-favories-list
      [source]="(entites$ | async).data"
      [type]="'entites'"
    ></app-favories-list>
  </ng-container>
</ng-template>
<ng-template #favorisParticipants>
  <ng-container *ngIf="(participants$ | async)?.data; else noParticipant">
    <app-favories-list
      [source]="(participants$ | async).data"
      [type]="'participants'"
    ></app-favories-list>
  </ng-container>
</ng-template>
<ng-template #favorisMetiers>
  <ng-container *ngIf="(metiers$ | async)?.data; else noMetier">
    <app-favories-list
      [source]="(metiers$ | async).data"
      [type]="'metiers'"
    ></app-favories-list>
  </ng-container>
</ng-template>

<ng-template #noEntites>
  <app-no-result
    [icon]="'domaine'"
    [title]="'Aucune entité favorie'"
    [description]="'Aucune entité favorie'"
    [isButton]="false"
  ></app-no-result>
</ng-template>

<ng-template #noParticipant>
  <app-no-result
    [icon]="'group'"
    [title]="'Aucun participant favori'"
    [description]="'Aucun participant favori'"
    [isButton]="false"
  ></app-no-result>
</ng-template>

<ng-template #noMetier>
  <app-no-result
    [icon]="'business_center'"
    [title]="'Aucun métier favori'"
    [description]="'Aucun métier favori'"
    [isButton]="true"
    (new)="onNewFavoriMetier()"
  ></app-no-result>
</ng-template>

<ng-template #metierFormTitle>
  <app-header-title
    [icon]="'business_center'"
    [title]="metierFormTitleString"
  ></app-header-title>
</ng-template>
