import { dashboard } from './../../dashboard/store/dashboard.selectors';
import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { OperationDashboard } from './operation-dashboard.model';

export const loadOperationDashboard = createAction(
  '[OperationDashboard/COMPONENT] Load OperationDashboard',
  props<{ operationId: string }>()
);
export const loadOperationDashboardSuccess = createAction(
  '[OperationDashboard/API] Load OperationDashboard Success',
  props<{ dashboard: any }>()
);
export const loadOperationDashboardFailure = createAction(
  '[OperationDashboard/API] Load OperationDashboard Failure',
  props<{ error: any }>()
);

export const clearOperationDashboards = createAction(
  '[OperationDashboard/API] Clear OperationDashboards'
);
