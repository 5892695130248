<ng-container *ngIf="item">
  <nz-card [nzTitle]="projetProfesionnelInfoTitle">
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="8" [nzFlex]="1">
        <nz-progress
          [nzPercent]="75"
          nzType="circle"
          [nzWidth]="60"
          nzStrokeWidth="12"
        ></nz-progress>
      </nz-col>
      <nz-col nzSpan="16">
        <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
          <nz-col nzSpan="8">
            <nz-statistic
              [nzValue]="2"
              [nzSuffix]="'/ ' + item.accesSize"
              nzTitle="Prérequis"
            ></nz-statistic>
          </nz-col>
          <nz-col nzSpan="8">
            <nz-statistic
              [nzValue]="2"
              [nzSuffix]="'/ ' + item.appellation.competencesCleDeBaseSize"
              nzTitle="Compétences de base"
            ></nz-statistic>
          </nz-col>
          <nz-col nzSpan="8">
            <nz-statistic
              [nzValue]="2"
              [nzSuffix]="'/ ' + item.appellation.competencesCleSpecifiquesSize"
              nzTitle="Compétences spécifiques"
            ></nz-statistic>
          </nz-col>
        </nz-row>
        <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
          <nz-col nzSpan="8">
            <nz-statistic
              [nzValue]="2"
              [nzSuffix]="'/ ' + (item.softskills.skills | topScore | size)"
              nzTitle="Softskills"
            ></nz-statistic>
          </nz-col>
          <nz-col nzSpan="8">
            <nz-statistic
              [nzValue]="2"
              [nzSuffix]="'/ ' + item.mobilitesProchesVersMetiers?.length"
              nzTitle="Expériences"
            ></nz-statistic>
          </nz-col>
          <nz-col nzSpan="8">
            <nz-statistic
              [nzValue]="2"
              [nzSuffix]="'/ ' + item.mobilitesProchesVersMetiers?.length"
              nzTitle="Mobilité professionnelle"
            ></nz-statistic>
          </nz-col>
        </nz-row>
      </nz-col>
    </nz-row>
  </nz-card>

  <ng-template #projetProfesionnelInfoTitle>
    <app-header-title
      [icon]="'pin'"
      [title]="'Taux d\'employabilité'"
      [subtitle]="true"
    ></app-header-title>
  </ng-template>
</ng-container>

<ng-template #projetProIconDescription>
  <mat-icon>article</mat-icon>
</ng-template>

<ng-template #projetProIconEmployability>
  <h5 nz-typography></h5>
</ng-template>
