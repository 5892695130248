import { BehaviorSubject } from 'rxjs';
import { OperationCadragePlanFormComponent } from './../operation-cadrage-plan-form/operation-cadrage-plan-form.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { OperationCadrage } from './../../store/operation-cadrage.model';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewContainerRef,
} from '@angular/core';
import { Update } from '@ngrx/entity';

@Component({
  selector: 'app-operation-cadrage-plan',
  templateUrl: './operation-cadrage-plan.component.html',
  styleUrls: ['./operation-cadrage-plan.component.scss'],
})
export class OperationCadragePlanComponent implements OnInit {
  modalWidth: number = 650;
  editing$ = new BehaviorSubject<boolean>(false);
  editingListItem$ = new BehaviorSubject<any>(null);
  editingListItemShow$ = new BehaviorSubject<any>(null);

  @Input() cadrage: OperationCadrage | any = null;
  @Output() update: EventEmitter<Update<OperationCadrage>> = new EventEmitter();

  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {}

  onNew(cadrage: OperationCadrage, plan?: any): void {
    const modal = this.modal.create({
      nzContent: OperationCadragePlanFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: plan ? `Modifier : ${plan.title}` : `Nouveau plan d'action`,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });
    const instance = modal.getContentComponent();
    instance.updateItem$ = new BehaviorSubject<any>(plan);
    instance.cancel.subscribe((event: any): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
    instance.add.subscribe((event: any): any => {
      if (!event) {
        return null;
      } else {
        this.onAdd(cadrage, event);
        modal.close();
      }
    });
    instance.update.subscribe((event: any): any => {
      if (!event) {
        return null;
      } else {
        this.onUpdate(cadrage, event);
        modal.close();
      }
    });
  }

  onAdd(cadrage: OperationCadrage, plan: any): void {
    const items = cadrage?.plans?.length ? [...cadrage.plans, plan] : [plan];

    const newCadrage: Update<OperationCadrage> = {
      id: cadrage.id,
      changes: {
        ...cadrage,
        plans: items,
      },
    };

    this.update.emit(newCadrage);
  }

  onUpdate(cadrage: OperationCadrage, plan: any): void {
    const items = cadrage?.plans.filter((element) => element.id !== plan.id);

    const newCadrage: Update<OperationCadrage> = {
      id: cadrage.id,
      changes: {
        ...cadrage,
        plans: [...items, plan],
      },
    };

    this.update.emit(newCadrage);
  }

  outputUpdate(cadrage: Update<OperationCadrage>): void {
    this.update.emit(cadrage);
  }

  onDeleteItem(item: any, cadrage: OperationCadrage): void {
    const items = cadrage.plans.filter((livrable) => livrable.id !== item.id);

    const newCadrage: Update<OperationCadrage> = {
      id: cadrage.id,
      changes: {
        ...cadrage,
        plans: items,
      },
    };

    this.outputUpdate(newCadrage);
  }

  onDeleteAllList(cadrage: OperationCadrage): void {
    const newCadrage: Update<OperationCadrage> = {
      id: cadrage.id,
      changes: {
        ...cadrage,
        plans: [],
      },
    };

    this.outputUpdate(newCadrage);
  }

  onCancel(): void {
    return;
  }
}
