import { BehaviorSubject } from 'rxjs';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-entite-search-filters',
  templateUrl: './entite-search-filters.component.html',
  styleUrls: ['./entite-search-filters.component.scss'],
})
export class EntiteSearchFiltersComponent implements OnInit {
  title: string = 'Filtrer par territoire';
  @Output() isSiege: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  @Output() isFiltered: EventEmitter<boolean> = new EventEmitter<boolean>(
    false
  );
  @Output() filters: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  onSiegeFilter(event: boolean): void {
    this.isFiltered.emit(event);
  }

  onESSFilter(event: boolean): void {}

  onGeoTerritoireChange(territoire: any): void {
    this.filters.emit(territoire);
  }
}
