<nz-card [nzTitle]="diagnosticCardTitlte">
  <ul>
    <li>Actions d'orientation vers les partenaires ou autres...</li>
    <li>
      Actions de formations internes et externes (pôle emploi ou autres
      partenaires)
    </li>
    <li>Propositions et/ou inscriptions aux ateliers</li>
    <li>Propositions de missions/projets</li>
  </ul>
</nz-card>

<ng-template #diagnosticCardTitlte>
  <app-header-title [icon]="'checklist'"></app-header-title>
</ng-template>
