import { Injectable } from '@angular/core';
import { Observable, of, EMPTY } from 'rxjs';
import { capitalize } from 'lodash';
import { fromUnixTime, format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { saveAs } from 'file-saver';
declare const require: any;
const { jsPDF } = require('jspdf');
require('jspdf-autotable');

@Injectable({
  providedIn: 'any',
})
export class FichesDePosteExportPDFService {
  pageWidth: number = 515.28;

  constructor() {}

  onFicheDePosteExport(
    fiche: any,
    user: any,
    type: string,
    isAnonyme: boolean,
    referentRecrutement: any
  ): Observable<any> {
    try {
      if (!fiche || !user) EMPTY;

      const currentDate: Date = new Date(Date.now());
      const xhr = new XMLHttpRequest();
      xhr.open('GET', user.organisation.logo);
      xhr.responseType = 'blob';
      xhr.send();
      xhr.addEventListener('load', () => {
        const reader = new FileReader();
        reader.readAsDataURL(xhr.response);
        reader.addEventListener('loadend', (): any => {
          const logo = reader.result;
          if (logo) {
            const date = format(new Date(Date.now()), 'yyyyMM', {
              locale: fr,
            });

            // Date
            // set PDF
            const pdfSize = 'a4';
            const doc = new jsPDF('p', 'pt', pdfSize);
            doc.page = 1;
            // set propretie informations pdf
            doc.setProperties({
              title: `Fiche de poste`,
              type: 'Fiche de poste',
              date: format(new Date(Date.now()), 'dd/MM/yyyy', {
                locale: fr,
              }),
              author: user.displayName,
              keywords: 'fiche de poste',
            });

            if (fiche) {
              const { entite, mission, contacts, recherche } = fiche;
              const { metier, contrat, operation } = mission;

              this.headerPdf(fiche, user, doc, isAnonyme, logo);

              this.operation_table(operation, doc, logo, user, isAnonyme);

              this.entreprise_table(entite, doc, logo, user, isAnonyme);

              contacts?.length
                ? this.contacts_table(contacts, doc, logo, user, isAnonyme)
                : null;

              this.recherche_table(recherche, doc, logo, user, isAnonyme);

              this.metier_table(metier, doc, logo, user, isAnonyme);

              this.contrat_table(mission, doc, logo, user, isAnonyme);

              this.referent_recrutement_table(
                doc,
                logo,
                user,
                isAnonyme,
                referentRecrutement
              );

              this.footerPdf(user, doc);

              //open || download || print
              const { denomination } = operation;
              const { uniteLegale } = entite;
              const denominationUniteLegale: string =
                uniteLegale?.denominationUniteLegale?.substring(0, 10);
              const nameFile: string = `${denomination.substring(
                0,
                10
              )}_${denominationUniteLegale}_Fiche_De_Poste_${date}.pdf`;

              doc.save(`${nameFile}`);
            } else {
              return of(null);
            }
          }
        });

        return of(null);
      });

      return EMPTY;
    } catch (error) {
      return of(error);
    }
  }

  //UTILS
  headerPdf(data: any, user: any, doc: any, anonym: boolean, logo: any): any {
    doc.setFont('helvetica', 'normal');

    doc.setFontSize(10);
    doc.setFont('helvetica', 'bold');
    //doc.text(`${user?.organisation?.nom_raison_sociale}`, 40, 40);
    doc.setFontSize(7);
    doc.setFont('helvetica', 'normal');
    // doc.text(`Siret : ${user?.organisation?.siret}`, 40, 50);

    // doc.text(40, 58, `Adresse : ${user?.organisation?.geo_adresse}`, {
    //   maxWidth: 140,
    // });

    doc.setFontSize(10);
    doc.setFont('helvetica', 'normal');
    doc.text(
      `Date de création : ${format(new Date(Date.now()), 'dd/MM/yyyy', {
        locale: fr,
      })}`,
      40,
      90
    );

    // Set author
    doc.setFontSize(14);
    doc.setFont('helvetica', 'normal');
    doc.setFont('helvetica', 'bold');
    doc.text(`Fiche de poste`, 257.64, 40);
    doc.setFont('helvetica', 'italic');
    doc.setFontSize(12);
    doc.text(`${anonym ? 'Anonymisée' : ''}`, 257.64, 60);

    // Set logo organisme
    doc.setFontSize(10);
    doc.addImage(`${logo ? logo : ''}`, 'PNG', 40, 20, 60, 30);
  }

  footerPdf(user: any, doc: any): any {
    const pageCount = doc.internal.getNumberOfPages();
    let str = `Page ${doc.internal.getNumberOfPages()}`;
    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(7);
      doc.text(
        'Page ' + String(i) + ' sur ' + String(pageCount),
        515.28,
        810,
        null,
        null,
        'right'
      );
    }

    doc.setFontSize(7);
    doc.setFont('helvetica', 'normal');
    doc.text(
      `${user.organisation.nom_raison_sociale}`,
      280,
      810,
      null,
      null,
      'center'
    );
  }

  operation_table(
    operation: any,
    doc: any,
    logo: any,
    user: any,
    anonym: boolean = false
  ): any {
    const header = [
      [
        {
          content: `Projet`,
          colSpan: 4,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'bold',
            fillColor: [0, 55, 102],
            textColor: [255, 255, 255],
          },
        },
      ],
      [
        {
          content: `Nom du projet`,
          colSpan: 2,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: `Donneurs d'ordre/financeurs`,
          colSpan: 1,

          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: `Type de projet`,
          colSpan: 1,

          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
    ];

    const body = [
      [
        {
          content: capitalize(operation?.denomination),
          colSpan: 2,

          styles: {
            halign: 'left',
            fontStyle: 'bold',
          },
        },
        {
          content: operation?.financeurs?.length
            ? operation?.financeurs
                .map(
                  (financeur: any) =>
                    financeur?.uniteLegale?.denominationUniteLegale
                )
                .join(', ')
            : '',
          colSpan: 1,

          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },

        {
          content: operation?.type,
          colSpan: 1,

          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
      [
        {
          content: ` ${
            operation?.description
              ? 'Description : ' + operation?.description
              : 'Aucune description renseignée'
          }`,
          colSpan: 4,

          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
    ];

    doc.autoTable({
      theme: 'grid',
      head: header,
      body: body,
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },
      headStyles: {},
      columnStyles: {
        0: { cellWidth: 257.5 },
        2: 'auto',
        3: 'auto',
      },

      bodyStyles: {
        0: { fontStyle: 'bold' },
      },

      didDrawPage: (data: any) => {
        this.headerPdf(data, user, doc, anonym, logo);
      },

      margin: { top: 100 },
    });
  }

  entreprise_table(
    entite: any,
    doc: any,
    logo: any,
    user: any,
    anonym: boolean = false
  ): any {
    const header = [
      [
        {
          content:
            'Entreprise : ' +
            `${anonym ? '' : entite?.uniteLegale?.denominationUniteLegale}`,
          colSpan: 4,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'bold',
            fillColor: [0, 55, 102],
            textColor: [255, 255, 255],
          },
        },
      ],
      [
        {
          content: 'Siret',
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Acticité',
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Statut juridique',
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Adresse',
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
    ];

    const body = [
      [
        {
          content: anonym ? '' : `${entite?.siret}`,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content:
            entite?.uniteLegale?.activite_principale_libelle?.intitule_naf,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: entite?.uniteLegale?.categorieEntreprise,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: entite?.adresseEtablissement?.fullAdresse,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
    ];

    doc.autoTable({
      theme: 'grid',
      head: header,
      body: body,
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },
      headStyles: {},
      columnStyles: {
        0: 'auto',
        1: 'auto',
        2: 'auto',
        3: 'auto',
      },

      bodyStyles: {
        0: { fontStyle: 'bold' },
      },

      didDrawPage: (data: any) => {
        this.headerPdf(data, user, doc, anonym, logo);
      },

      margin: { top: 100 },
    });
  }
  contacts_table(
    contacts: any,
    doc: any,
    logo: any,
    user: any,
    anonym: boolean = false
  ): any {
    const header = [
      [
        {
          content: `Contacts`,
          colSpan: 5,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'bold',
            fillColor: [0, 55, 102],
            textColor: [255, 255, 255],
          },
        },
      ],
      [
        {
          content: 'Identité',
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Fonction',
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Rôle',
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Mobile',
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'E-mail',
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
    ];

    const body = contacts.map((contact: any) => [
      {
        content: anonym
          ? ''
          : `${contact?.civilite}. ${contact?.lastName} ${contact?.firstName}`,
        styles: {
          halign: 'left',
          fontStyle: 'normal',
        },
      },
      {
        content: contact.fonction,
        styles: {
          halign: 'left',
          fontStyle: 'normal',
        },
      },
      {
        content: contact.role,
        styles: {
          halign: 'left',
          fontStyle: 'normal',
        },
      },
      {
        content: anonym ? '' : contact.coordonnees?.mobile,
        styles: {
          halign: 'left',
          fontStyle: 'normal',
        },
      },
      {
        content: anonym ? '' : contact.coordonnees?.email,
        styles: {
          halign: 'left',
          fontStyle: 'normal',
        },
      },
      ,
    ]);

    doc.autoTable({
      theme: 'grid',
      head: header,
      body: body,
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },
      headStyles: {},
      columnStyles: {
        0: { cellWidth: 103 },
        1: 'auto',
        2: 'auto',
        3: 'auto',
        4: 'auto',
      },

      bodyStyles: {
        0: { fontStyle: 'bold' },
      },

      didDrawPage: (data: any) => {
        this.headerPdf(data, user, doc, anonym, logo);
      },

      margin: { top: 100 },
    });
  }
  recherche_table(
    recherche: any,
    doc: any,
    logo: any,
    user: any,
    anonym: boolean = false
  ): any {
    const header = [
      [
        {
          content: `Recherche`,
          colSpan: 2,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'bold',
            fillColor: [0, 55, 102],
            textColor: [255, 255, 255],
          },
        },
      ],
      [
        {
          content: `Nombre de candidatures`,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: `Type de mission`,
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
    ];

    const body = [
      [
        {
          content: recherche?.participantsSize,
          styles: {
            halign: 'left',
            fontStyle: 'bold',
          },
        },
        {
          content: `${
            recherche?.isClauseInsertion
              ? "Clause d'insertion sociale"
              : "Hors clause d'insertion sociale"
          }`,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
      [
        {
          content: `Limitation géographique`,
          colSpan: 2,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
      [
        {
          content: `Région : ${
            recherche?.geoLimitation?.region?.nom
              ? recherche?.geoLimitation?.region?.nom
              : 'Aucune restriction régionale'
          },\nDépartement : ${
            recherche?.geoLimitation?.departement?.nom
              ? recherche?.geoLimitation?.departement?.nom
              : 'Aucune restricte départementale'
          },\nCommune : ${
            recherche?.geoLimitation?.commune?.nom
              ? recherche?.geoLimitation?.commune?.nom
              : 'Aucune restriction communale'
          }`,
          colSpan: 2,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
    ];

    doc.autoTable({
      theme: 'grid',
      head: header,
      body: body,
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },
      headStyles: {},
      columnStyles: {
        0: 'auto',
        1: 'auto',
      },

      bodyStyles: {
        0: { fontStyle: 'bold' },
      },

      didDrawPage: (data: any) => {
        this.headerPdf(data, user, doc, anonym, logo);
      },

      margin: { top: 100 },
    });
  }

  metier_table(
    metier: any,
    doc: any,
    logo: any,
    user: any,
    anonym: boolean = false
  ): any {
    doc.addPage();

    const header = [
      [
        {
          content: `Métier : ${metier?.libelle} - ROME : ${metier?.metier?.code}`,
          colSpan: 2,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'bold',
            fillColor: [0, 55, 102],
            textColor: [255, 255, 255],
          },
        },
      ],
    ];

    const body = [
      [
        {
          content: `Grand domaine : ${metier?.metier?.domaineProfessionnel?.grandDomaine?.libelle}`,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: `Domaine professionnel : ${metier?.metier?.domaineProfessionnel?.libelle}`,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
      [
        {
          content: `Définition : ${metier?.metier?.informations?.definition?.replace(
            /\n/g,
            ' '
          )}`,
          colSpan: 2,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
      [
        {
          content: `Compétences de base - Savoir`,
          colSpan: 1,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: `Compétences de base - Savoir faire`,
          colSpan: 1,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],

      [
        {
          content: `${
            metier?.competencesDeBaseType['Savoir']?.length
              ? metier?.competencesDeBaseType['Savoir']
                  .map((el: any) => el.libelle)
                  .join(', ')
              : ''
          }`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: `${
            metier?.competencesDeBaseType['SavoirFaire']?.length
              ? metier?.competencesDeBaseType['SavoirFaire']
                  .map((el: any) => el.libelle)
                  .join(', ')
              : ''
          }`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
      [
        {
          content: `Compétences spéficiques - Savoir`,
          colSpan: 1,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: `Compétences spéficiques - Savoir faire`,
          colSpan: 1,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
      [
        {
          content: `${
            metier?.competencesSpecifiques?.length
              ? metier?.competencesSpecifiques
                  .map((el: any) => el.libelle)
                  .join(', ')
              : ''
          }`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: `${
            metier?.competencesSpecifiques?.length
              ? metier?.competencesSpecifiques
                  .map((el: any) => el.libelle)
                  .join(', ')
              : ''
          }`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
      [
        {
          content: `Compétences transversales`,
          colSpan: 2,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
      [
        {
          content: `${
            metier?.softSkills?.length
              ? metier?.softSkills.map((el: any) => el?.summary).join(', ')
              : ''
          }`,
          colSpan: 2,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
      [
        {
          content: `Environnement`,
          colSpan: 2,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
      [
        {
          content: `${
            metier?.environnementsTravail?.length
              ? metier?.environnementsTravail
                  .map((el: any) => el?.libelle)
                  .join(', ')
              : ''
          }`,
          colSpan: 2,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
    ];

    doc.autoTable({
      theme: 'grid',
      head: header,
      body: body,
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },
      headStyles: {},
      columnStyles: {
        0: { cellWidth: 257.5 },
        1: 'auto',
      },

      bodyStyles: {
        0: { fontStyle: 'bold' },
      },

      didDrawPage: (data: any) => {
        this.headerPdf(data, user, doc, anonym, logo);
      },

      margin: { top: 100 },
    });
  }
  contrat_table(
    mission: any,
    doc: any,
    logo: any,
    user: any,
    anonym: boolean = false
  ): any {
    doc.addPage();

    const header = [
      [
        {
          content: `Contrat`,
          colSpan: 2,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'bold',
            fillColor: [0, 55, 102],
            textColor: [255, 255, 255],
          },
        },
      ],
    ];

    const body = [
      [
        {
          content: 'Type de contrat',
          styles: {
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Nature du contrat',
          styles: {
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
      [
        {
          content: mission.contrat.type?.libelle,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: mission.contrat.nature?.libelle,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
      [
        {
          content: `Expérience`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: `Taux horaire`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
      [
        {
          content: mission.contrat?.experience?.libelleCaracteristique,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: `${mission.contrat?.work_price} €`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
      [
        {
          content: `Niveau de rémunération`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: `Compléments de salaire`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
      [
        {
          content: mission?.contrat?.salaire?.libelleCaracteristique,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: `${
            mission?.contrat?.salaireComplements?.length
              ? mission?.contrat?.salaireComplements.map(
                  (el: any, index: number) =>
                    `${index > 0 ? '\n' : ''}${capitalize(
                      el?.libelle?.libelle
                    )} - ${el.taux} ${el.unit}`
                )
              : ''
          }`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
      [
        {
          content: `Dates`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: `Souplesse de démarrage`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
      [
        {
          content: `Du ${format(
            fromUnixTime(mission.contrat?.datesContrat[0]['seconds']),
            'dd/MM/yyyy',
            { locale: fr }
          )} au ${format(
            fromUnixTime(mission.contrat?.datesContrat[1]['seconds']),
            'dd/MM/yyyy',
            { locale: fr }
          )}`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: `Du ${format(
            fromUnixTime(mission.contrat?.datesSouplesse[0]['seconds']),
            'dd/MM/yyyy',
            { locale: fr }
          )} au ${format(
            fromUnixTime(mission.contrat?.datesSouplesse[1]['seconds']),
            'dd/MM/yyyy',
            { locale: fr }
          )}`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
      [
        {
          content: `Adresse`,
          colSpan: 2,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
      [
        {
          content: `${mission?.adresse?.adresse?.properties?.label}`,
          colSpan: 2,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
      [
        {
          content: `Jours de travail`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: `Horaires`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
      [
        {
          content: `${
            mission?.contrat?.work_days?.length
              ? mission?.contrat?.work_days.map((el: any) => el).join(', ')
              : ''
          }`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: `Avant-midi: ${mission.contrat?.horaireStartAM} h - ${mission.contrat?.horaireEndAM} h,\nAprès-midi : ${mission.contrat?.horaireStartPM} h - ${mission.contrat?.horaireEndPM} h,\nTemps de pause : ${mission.contrat?.pause_dejeuner} h`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
      [
        {
          content: `Durée hebdomadaire`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: `Période d'essaie`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
      [
        {
          content: `${mission?.contrat?.duree_hebdomadaire}`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: `${mission.contrat?.periodeDessaie}`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
      [
        {
          content: `Astreinte et suivi renforcé`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: `Déplacement`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
      [
        {
          content: `Astreinte : ${
            mission?.contrat?.isAstreinte ? 'OUI' : 'NON'
          } - Suivi renforcé : ${
            mission?.contrat?.isSuiviRenforce ? 'OUI' : 'NON'
          }`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: `${
            mission?.contrat?.isDeplacement
              ? 'Des déplacements sont à prévoir'
              : 'Aucun déplacement prévu'
          },\nZones de déplacement : ${
            mission?.contrat?.zone_deplacement?.length
              ? mission?.contrat?.zone_deplacement
                  .map((el: any) => el.libelleTypeTerritoire)
                  .join(', ')
              : 'Aucune zone de déplacement'
          }`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
      [
        {
          content: `Permis de conduire`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: `Bureautique`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
      [
        {
          content: `${
            mission?.contrat?.permis?.length
              ? mission?.contrat?.permis.map(
                  (el: any, index: number) =>
                    `${index > 0 ? '\n' : ''}${el.libelle}`
                )
              : ''
          }`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: `${
            mission?.contrat?.bureautique?.length
              ? mission?.contrat?.bureautique.map(
                  (el: any, index: number) => `${index > 0 ? '\n' : ''}${el}`
                )
              : ''
          }`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
      [
        {
          content: `Évolution professionnelle`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: `Conditions d'évolution professionnelle`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
      [
        {
          content: mission?.contrat?.evolution
            ? mission?.contrat?.evolution?.libelle
            : '',
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: mission?.contrat?.evolutionCondition
            ? mission?.contrat?.evolutionCondition
            : '',
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
      [
        {
          content: `Motif`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: `Justificatif`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
      [
        {
          content: mission?.contrat?.motif,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: mission?.contrat?.justificatif,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
      [
        {
          content: `Suivi au sein de l'entreprise`,
          colSpan: 2,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
      [
        {
          content: anonym ? '' : mission?.contrat?.suiviInterne,
          colSpan: 2,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
    ];

    doc.autoTable({
      theme: 'grid',
      head: header,
      body: body,
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },
      headStyles: {},
      columnStyles: {
        0: { cellWidth: 257.5 },
        1: 'auto',
      },

      bodyStyles: {
        0: { fontStyle: 'bold' },
      },

      didDrawPage: (data: any) => {
        this.headerPdf(data, user, doc, anonym, logo);
      },

      margin: { top: 100 },
    });
  }

  referent_recrutement_table(
    doc: any,
    logo: any,
    user: any,
    anonym: boolean = false,
    referentRecrutement: any
  ): any {
    const header = [
      [
        {
          content: `Référent du recrutement`,
          colSpan: 3,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'bold',
            fillColor: [0, 55, 102],
            textColor: [255, 255, 255],
          },
        },
      ],
      [
        {
          content: `Identité`,
          colSpan: 1,

          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: `Mobile`,
          colSpan: 1,

          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: `E-mail`,
          colSpan: 1,

          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
    ];

    const body = [
      [
        {
          content: referentRecrutement?.user?.displayName || user?.displayName,
          colSpan: 1,

          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content:
            referentRecrutement?.user?.coordonnees.phoneNumber ||
            user.coordonnees.phoneNumber,
          colSpan: 1,

          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content:
            referentRecrutement?.user?.coordonnees.email ||
            user.coordonnees.email,
          colSpan: 1,

          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
    ];

    doc.autoTable({
      theme: 'grid',
      head: header,
      body: body,
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },
      headStyles: {},
      columnStyles: {
        0: { cellWidth: 171.66 },
        1: 'auto',
        2: 'auto',
      },

      bodyStyles: {
        0: { fontStyle: 'bold' },
      },

      didDrawPage: (data: any) => {
        this.headerPdf(data, user, doc, anonym, logo);
      },

      margin: { top: 100 },
    });
  }

  graphique(doc: any): void {
    // Création d'un graphique histogramme
    var data = [10, 20, 30, 40, 50];
    doc.text('Mon graphique histogramme', 10, 10);
    doc.line(10, 20, 100, 20);
    doc.line(10, 20, 10, 60);
    doc.text('0', 5, 63);
    doc.text('50', 5, 23);
    doc.text('100', 90, 23);
    for (var i = 0; i < data.length; i++) {
      doc.rect(15 + i * 20, 60 - data[i], 10, data[i]);
    }

    // Création d'un graphique camembert
    var data = [10, 20, 30, 40, 50];
    doc.text('Mon graphique camembert', 10, 10);
    doc.pieChart(50, 50, data, ['A', 'B', 'C', 'D', 'E']);

    // Création d'un graphique en ligne
    var data = [10, 20, 30, 40, 50];
    doc.text('Mon graphique en ligne', 10, 10);
    doc.line(10, 30, 100, 30);
    doc.text('0', 5, 33);
    doc.text('50', 5, 13);
    doc.text('100', 90, 13);
    for (var i = 0; i < data.length; i++) {
      doc.line(15 + i * 20, 30, 15 + i * 20, 30 - data[i]);
    }

    // Données pour la première ligne
    var data1 = [10, 20, 30, 40, 50];
    var labels1 = ['Jan', 'Fév', 'Mar', 'Avr', 'Mai'];

    // Données pour la deuxième ligne
    var data2 = [20, 30, 10, 40, 20];
    var labels2 = ['Jan', 'Fév', 'Mar', 'Avr', 'Mai'];

    // Création du graphique
    doc.text('Mon graphique à double ligne', 10, 10);
    doc.line(10, 20, 100, 20);
    doc.line(10, 20, 10, 60);
    doc.text('0', 5, 63);
    doc.text('50', 5, 23);
    doc.text('100', 90, 23);

    for (var i = 0; i < data1.length; i++) {
      var x = 15 + i * 20;
      var y1 = 60 - data1[i];
      var y2 = 60 - data2[i];

      // Dessin de la première ligne
      if (i > 0) {
        doc.line(x - 20, y1, x, y1);
      }
      doc.circle(x, y1, 1, 'F');

      // Dessin de la deuxième ligne
      if (i > 0) {
        doc.line(x - 20, y2, x, y2);
      }
      doc.circle(x, y2, 1, 'F');
    }

    // Légende pour la première ligne
    doc.setFillColor(255, 0, 0);
    doc.rect(10, 70, 5, 5, 'F');
    doc.text('Ligne 1', 20, 75);

    // Légende pour la deuxième ligne
    doc.setFillColor(0, 0, 255);
    doc.rect(10, 80, 5, 5, 'F');
    doc.text('Ligne 2', 20, 85);
  }
}
