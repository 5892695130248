<div [ngSwitch]="displayView$ | async">
  <div *ngSwitchCase="'Document'">
    <app-document
      (addDocument)="onAddDocument($event)"
      (cancel)="onCancel()"
    ></app-document>
  </div>
  <div *ngSwitchCase="'Detail'">
    <app-pdf-viewer
      (cancel)="onCancel()"
      (download)="onDowloadFile($event)"
    ></app-pdf-viewer>
  </div>
  <div *ngSwitchDefault>
    <div class="w-full" *ngIf="source$ | async | size; else noResults">
      <div class="navbar">
        <div class="navbar-start divide-x">
          <div>
            <form nz-form [formGroup]="searchForm">
              <nz-form-item>
                <nz-form-control *ngIf="isSearch">
                  <input
                    nz-input
                    placeholder="Recherche"
                    nzBorderless
                    formControlName="input"
                  />
                </nz-form-control>
              </nz-form-item>
            </form>
          </div>
        </div>

        <div class="navbar-end flex items-end justify-end space-x-2">
          <div>
            <button
              *ngIf="isCanAdd"
              nz-tooltip
              nzTooltipPlacement="bottom"
              nzTooltipTitle="Ajouter un document"
              [nzTooltipMouseEnterDelay]="0.5"
              class="btn btn-sm btn-ghost"
              (click)="
                $event.preventDefault();
                $event.stopPropagation();
                onNewDocument()
              "
            >
              Nouveau
            </button>
          </div>
          <div>
            <app-button-dropdown
              [isDeleteAll]="true"
              (select)="onDeleteAll($event)"
            ></app-button-dropdown>
          </div>
        </div>
      </div>

      <nz-table
        #filterTable
        [nzData]="source$ | async"
        nzTableLayout="fixed"
        nzSize="small"
        [nzScroll]="scroll"
        [nzLoadingDelay]="2"
        [nzBordered]="false"
        [nzOuterBordered]="false"
      >
        <thead>
          <tr>
            <th nzLeft nzWidth="100px" class="text-sm tracking-wider uppercase">
              Extension
            </th>
            <th nzLeft class="text-sm tracking-wider uppercase">Titre</th>
            <th
              [nzFilters]="filterByTypes"
              [nzFilterFn]="filterByTypesFn"
              class="text-sm tracking-wider uppercase"
            >
              Catégorie
            </th>
            <th
              [nzSortFn]="sortFnByDates"
              nzWidth="100px"
              class="text-sm tracking-wider uppercase"
            >
              Date
            </th>
            <th
              nzRight
              nzWidth="100px"
              class="text-sm tracking-wider uppercase"
            >
              Référent
            </th>
            <th
              nzRight
              nzWidth="50px"
              class="text-sm tracking-wider uppercase"
            ></th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let data of filterTable.data"
            (click)="$event.stopPropagation(); onSelect(data, selectTyped)"
          >
            <td nzLeft nzWidth="100px">
              <app-document-type [item]="data?.extension"></app-document-type>
            </td>
            <td nzLeft>{{ data?.title | carLimitation : 40 }}</td>
            <td>
              {{ data?.type | carLimitation : 30 }}
            </td>

            <td nzWidth="100px">
              {{ data?.dateStart | dateFr : "small" }}
            </td>
            <td nzRight nzWidth="100px">
              <app-avatar-group
                [source]="[data.auteur]"
                [size]="30"
              ></app-avatar-group>
            </td>

            <td nzRight nzWidth="50px">
              <app-button-dropdown
                class="z-20"
                [direction]="'horizontal'"
                (click)="$event.stopPropagation()"
                [isDelete]="true"
                [deleteType]="'Voulez-vous supprimer ce document ?'"
                (select)="onSelectDropdown($event, data)"
              ></app-button-dropdown>
            </td>
          </tr>
        </tbody>
      </nz-table>
    </div>
  </div>
</div>

<ng-template #cardTitle>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="2">
      <app-header-title [icon]="'insert_drive_file'"></app-header-title>
    </nz-col>
    <nz-col nzSpan="2"></nz-col>
    <nz-col nzSpan="6">
      <form nz-form [formGroup]="searchForm">
        <nz-form-item>
          <nz-form-control *ngIf="isSearch">
            <input
              nz-input
              placeholder="Recherche"
              nzBorderless
              formControlName="input"
            />
          </nz-form-control>
        </nz-form-item>
      </form>
    </nz-col>
    <nz-col nzSpan="14"></nz-col>
  </nz-row>
</ng-template>
<ng-template #cardExtra>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="end">
    <nz-col nzSpan="16">
      <button
        *ngIf="isCanAdd"
        nz-button
        nz-tooltip
        nzBlock
        nzTooltipPlacement="bottom"
        nzTooltipTitle="Ajouter un document"
        [nzTooltipMouseEnterDelay]="0.5"
        class="btn_add"
        (click)="onNewDocument()"
      >
        Nouveau
      </button>
    </nz-col>
    <nz-col nzSpan="8">
      <app-button-dropdown [isDeleteAll]="true"></app-button-dropdown>
    </nz-col>
  </nz-row>
</ng-template>

<ng-template #unknownExtension>
  <i nz-icon nzType="file-unknown"></i>
</ng-template>

<ng-template #noResults>
  <app-no-result
    (new)="onNewDocument()"
    [isButton]="isCanAdd"
    [icon]="'insert_drive_file'"
    [addtitle]="'Ajouter un document'"
    [notitle]="'Aucun document trouvé'"
  ></app-no-result>
</ng-template>

<ng-template #documentTitleForm>
  <app-header-title
    [icon]="'insert_drive_file'"
    [title]="newCardTitle"
  ></app-header-title>
</ng-template>

<ng-template #documentTitlePDFView>
  <app-header-title
    [icon]="'insert_drive_file'"
    [title]="titlePDFView"
  ></app-header-title>
</ng-template>
