import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzMessageService } from 'ng-zorro-antd/message';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { OrganisationId } from '../../store/organisation.model';
import { OrganisationState } from '../../store/organisation.reducer';
import * as fromOrganisationAction from '../../store/organisation.actions';
import * as fromOrganisationSelector from '../../store/organisation.selectors';
import { Observable, of } from 'rxjs';
import { UserState } from './../../../user/store/user.reducer';
import * as fromUserSelector from './../../../user/store/user.selectors';

@Component({
  selector: 'app-organisation-card',
  templateUrl: './organisation-card.component.html',
  styleUrls: ['./organisation-card.component.scss'],
})
export class OrganisationCardComponent implements OnInit {
  user$: Observable<any> = of(null);
  mouseEnterDelay: number = 0.5;
  files: File[] = [];
  selectedImage: any = null;
  url: any;
  progress$: Observable<number> = of(0);
  loading$: Observable<boolean> = of(false);

  @Input() source: any = null;
  @Output() selectedTab = new EventEmitter<string>(false);
  @Output() delete = new EventEmitter<any>(false);
  @Output() update = new EventEmitter<File>(false);
  @Output() add = new EventEmitter<File>(false);

  @ViewChild('imagePreview') imagePreview: ElementRef | any = null;

  constructor(
    private message: NzMessageService,
    private organisationStore: Store<OrganisationState>,
    private userStore: Store<UserState>,
    private notifications: NzNotificationService
  ) {}

  ngOnInit(): void {
    this.getLoadingLogo();
    this.getUser();
  }

  getUser(): void {
    this.user$ = this.userStore.select(fromUserSelector.user);
  }

  onUpdate(): void {
    this.selectedTab.emit('organisationForm');
  }

  formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  onChoseLogo__ORGANISATION(event: any, organisation: OrganisationId): any {
    if (!event.target.files || !event.target.files[0]) return null;

    this.files.push(event.target.files[0]);
    this.selectedImage = event.target.files[0];
    const size = (this.files[0].size / 1024 / 1024).toFixed(4);
    if (!size) {
      this.notifications.warning('', "Aucun logo n'a été téléchargé", {
        nzDuration: 6000,
        nzAnimate: true,
        nzPlacement: 'topRight',
      });
      return null;
    }
    if (Number(size) > 1) {
      this.notifications.warning(
        '',
        'Votre logo est trop volumineux. Veuillez reduire la taille de votre image',
        { nzDuration: 6000, nzAnimate: true, nzPlacement: 'topRight' }
      );
      return null;
    }

    this.addLogo__ORGANISATION(organisation);
    return null;
  }

  getLoadingLogo(): void {
    this.loading$ = this.organisationStore.select(
      fromOrganisationSelector.loading
    );
  }

  addLogo__ORGANISATION(organisation: OrganisationId): any {
    const file: File = this.files[0];
    if (!file) {
      this.files = [];
      this.selectedImage = undefined;

      return null;
    } else {
      this.add.emit(file);
      this.files = [];
      this.selectedImage = undefined;
      this.organisationStore.dispatch(
        fromOrganisationAction.addLogoOrganisation({ file })
      );
    }
  }

  deleteLogo(organisation: OrganisationId): any {
    if (!organisation) {
      return null;
    } else {
      this.organisationStore.dispatch(
        fromOrganisationAction.removeLogoOrganisation({ organisation })
      );
    }
  }

  cancelLogo(): any {
    return null;
  }
}
