import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Objectif } from './objectif.model';

export const loadParticipantUtilities = createAction(
  '[Objectif/Compoenent] Load Participants utilities objectifs'
);

export const loadParticipantUtilitiesSuccess = createAction(
  '[Objectif/Compoenent] Load Participants utilities objectifs Success',
  props<{ utilities: any }>()
);

export const loadParticipantUtilitiesFailure = createAction(
  '[Objectif/Compoenent] Load Participants utilities objectifs Failure',
  props<{ error: any }>()
);
