import { createAction, props } from '@ngrx/store';
import { ContactSMALLid } from 'src/app/components/contact/store/contact.model';

export const queryContacts = createAction(
  '[ContactsSelect/COMPONENT] Query Contacts',
  props<{ filter: string; filterType: string }>()
);
export const queryContactsSuccess = createAction(
  '[ContactsSelect/API] Query Contacts Success',
  props<{ contacts: ContactSMALLid[] }>()
);
export const queryContactsFailure = createAction(
  '[ContactsSelect/API] Query Contacts Failure',
  props<{ error: any }>()
);
