import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable, of } from 'rxjs';
import { OrganisationFormComponent } from 'src/app/components/organisation/components/organisation-form/organisation-form.component';
import { OrganisationState } from 'src/app/components/organisation/store/organisation.reducer';
import { UserFormComponent } from 'src/app/components/user/components/user-form/user-form.component';
import * as fromOrganisationSelector from 'src/app/components/organisation/store/organisation.selectors';

export interface AccompteOption {
  icon: string;
  name: string;
  type: string;
  description: string;
}
@Component({
  selector: 'app-new-account',
  templateUrl: './new-account.component.html',
  styleUrls: ['./new-account.component.scss'],
})
export class NewAccountComponent implements OnInit {
  icon: string = 'account_circle';
  options: AccompteOption[] = [
    {
      icon: 'person',
      name: 'Créer un compte utilisateur',
      type: 'utilisateur',
      description: 'Compte gratuit pour un utilisateur unique',
    },
    {
      icon: 'corporate_fare',
      name: 'Créer un compte organisation',
      type: 'organisation',
      description: 'Compte pour une organisation et ses utilisateurs',
    },
  ];
  loading$: Observable<boolean> = of(false);

  @ViewChild('userFormTitle', { static: false }) userFormTitle:
    | TemplateRef<any>
    | any;
  @ViewChild('organisationFormTitle', { static: false }) organisationFormTitle:
    | TemplateRef<any>
    | any;
  modalWidth: number = 950;

  constructor(
    private router: Router,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private organisationStore: Store<OrganisationState>
  ) {}

  ngOnInit(): void {
    this.getOrganisationLoading();
  }

  getOrganisationLoading(): void {
    this.loading$ = this.organisationStore.select(
      fromOrganisationSelector.loading
    );
  }

  onBackToLogin(): void {
    this.router.navigate(['/login']);
  }

  onSelect(option: AccompteOption): void {
    if (!option) return;
    switch (option.type) {
      case 'utilisateur':
        this.onNewUser();
        break;
      case 'organisation':
        this.onNewOrganisation();
        break;
      default:
        break;
    }
  }

  onNewUser(): void {
    const modal = this.modal.create({
      nzContent: UserFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.userFormTitle,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });

    const instance = modal.getContentComponent();
    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
        this.onBackToLogin();
      }
    });
    // instance.add.subscribe((event): any => {
    //   if (!event) {
    //     return null;
    //   } else {
    //     this.onAdd(event);
    //   }
    // });
    // instance.update.subscribe((event): any => {
    //   if (!event) {
    //     return null;
    //   } else {
    //     this.onUpdate(event);
    //   }
    // });
  }
  onNewOrganisation(): void {
    const modal = this.modal.create({
      nzContent: OrganisationFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.organisationFormTitle,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });

    const instance = modal.getContentComponent();
    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
        this.onBackToLogin();
      }
    });
    // instance.add.subscribe((event): any => {
    //   if (!event) {
    //     return null;
    //   } else {
    //   }
    // });
    // instance.update.subscribe((event): any => {
    //   if (!event) {
    //     return null;
    //   } else {
    //   }
    // });
  }
}
