import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-operation-note-export-participants',
  templateUrl: './operation-note-export-participants.component.html',
  styleUrls: ['./operation-note-export-participants.component.scss'],
})
export class OperationNoteExportParticipantsComponent implements OnInit {
  @Input() participants: any[] = [];
  constructor() {}

  ngOnInit(): void {}
}
