import { takeUntil } from 'rxjs/operators';
import { OperationId } from '../../../operation/store/operation.model';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { OperationState } from '../../../operation/store/operation.reducer';
import * as fromOperationAction from '../../../operation/store/operation.actions';
import * as fromOperationSelector from '../../../operation/store/operation.selectors';

import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ContactsSelectComponent } from 'src/app/contents/components/contacts-select/components/contacts-select.component';
import { ContactSMALLid } from 'src/app/components/contact/store/contact.model';
import { ContactValidatorComponent } from 'src/app/contents/components/contact-validator/contact-validator.component';

@Component({
  selector: 'app-operation-contacts',
  templateUrl: './operation-contacts.component.html',
  styleUrls: ['./operation-contacts.component.scss'],
})
export class OperationContactsComponent implements OnInit, OnDestroy {
  subscribe = new Subject();
  view$ = new BehaviorSubject<string>('list');
  loading$: Observable<boolean> = of(false);
  title: string = 'Nouvel intervenant';
  newCardTitle: string = 'Nouvel intervenant';
  newCardDescription: string = 'Ajouter un intervenant au projet';
  noModuleTitle: string = 'Aucun intervenant ajouté';
  newCardDefinition: string =
    "Un intervenant est un collaborateur d'entité ou d'entreprise participant au projet.";

  @ViewChild('contactFormTitle', { static: false })
  contactFormTitle: TemplateRef<any> | any;

  modalWidth: number = 850;

  @Input() operation$: Observable<OperationId | any> = of(null);
  @Output() select = new EventEmitter<string>(false);

  contacts$: Observable<any> = of(null);
  entites$: Observable<any> = of();

  constructor(
    private operationStore: Store<OperationState>,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.getContacts();
    this.loadContacts();
    this.getEntites();
    this.loadEntites();
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getContacts(): void {
    this.contacts$ = this.operationStore.select(fromOperationSelector.contacts);
  }
  loadContacts(): void {
    this.operation$.pipe(takeUntil(this.subscribe)).subscribe((operation) => {
      if (!operation) return;
      this.operationStore.dispatch(
        fromOperationAction.loadContactsOperation({ operationId: operation.id })
      );
    });
  }

  onSelect(operation: OperationId, contact: any): void {
    if (!operation || !contact) return;
    this.select.emit('contactItem');
    this.view$.next('details');
  }

  onNew(operation: OperationId): void {
    const modal = this.modal.create({
      nzContent: ContactsSelectComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.contactFormTitle,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });
    const instance = modal.getContentComponent();
    instance.operation$ = this.operation$;
    instance.entites$ = this.entites$;
    instance.exists$ = this.contacts$;

    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
    instance.select.subscribe((event: any): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
        this.onType(event, operation);
      }
    });
  }

  getEntites(): void {
    this.entites$ = this.operationStore.select(fromOperationSelector.entites);
  }

  loadEntites(): void {
    this.operation$
      .pipe(takeUntil(this.subscribe))
      .subscribe((operation: OperationId) => {
        if (!operation) return;
        this.operationStore.dispatch(
          fromOperationAction.loadEntitesOperation({
            operationId: operation.id,
          })
        );
      });
  }

  onType(contacts: ContactSMALLid[], operation: OperationId): void {
    const modal = this.modal.create({
      nzContent: ContactValidatorComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: `Type d'intervention au projet : ${operation.denomination}`,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '10px' },
    });
    const instance = modal.getContentComponent();
    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
    instance.contacts = contacts;
    instance.validate.subscribe((contactsSelected: any) => {
      this.onAdd(contactsSelected, operation);
      modal.close();
    });
  }

  onAdd(contactsSelected: any, operation: OperationId): void {
    const { contacts } = contactsSelected;
    if (!contacts?.length || !operation) return;

    this.operationStore.dispatch(
      fromOperationAction.addContactsOperation({
        operation,
        contactsSelected: contacts,
      })
    );
  }

  onDelete(contact: any, operation: OperationId): void {
    if (!operation || !contact) return;
    this.operationStore.dispatch(
      fromOperationAction.deleteContactOperation({
        operationId: operation.id,
        contactId: contact.id,
      })
    );
  }

  onBack(): void {
    this.view$.next('list');
  }

  onCancel(): void {}
}
