import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { OperationFicheDePoste } from './operation-fiche-de-poste.model';

//LOAD ALL FICHE DE POSTE
export const loadOperationFichesDePoste = createAction(
  '[OperationFicheDePoste/API] Load Operation FichesDePoste',
  props<{ id: string }>()
);
export const loadOperationFichesDePosteSuccess = createAction(
  '[OperationFicheDePoste/API] Load Operation FichesDePoste Success',
  props<{ fiches: any[] }>()
);
export const loadOperationFichesDePosteFailure = createAction(
  '[OperationFicheDePoste/API] Load Operation FichesDePoste Failure',
  props<{ error: any }>()
);

//LOAD ONE FICHE DE POSTE
export const loadOperationFicheDePoste = createAction(
  '[OperationFicheDePoste/API] Load Operation FicheDePoste',
  props<{ id: string; ficheId: string }>()
);
export const loadOperationFicheDePosteSuccess = createAction(
  '[OperationFicheDePoste/API] Load Operation FicheDePoste Success',
  props<{ fiche: any }>()
);
export const loadOperationFicheDePosteFailure = createAction(
  '[OperationFicheDePoste/API] Load Operation FicheDePoste Failure',
  props<{ error: any }>()
);

//ADD FICHE DE POSTE
export const addOperationFicheDePoste = createAction(
  '[OperationFicheDePoste/API] Add Operation FicheDePoste',
  props<{ id: string; fiche: any }>()
);
export const addOperationFicheDePosteSuccess = createAction(
  '[OperationFicheDePoste/API] Add Operation FicheDePoste Success',
  props<{ fiche: any }>()
);
export const addOperationFicheDePosteFailure = createAction(
  '[OperationFicheDePoste/API] Add Operation FicheDePoste Failure',
  props<{ error: any }>()
);

//COPY FICHE DE POSTE
export const copyOperationFicheDePoste = createAction(
  '[OperationFicheDePoste/API] Copy Operation FicheDePoste',
  props<{ id: string; ficheId: string }>()
);
export const copyOperationFicheDePosteSuccess = createAction(
  '[OperationFicheDePoste/API] Copy Operation FicheDePoste Success',
  props<{ fiche: any }>()
);

export const copyOperationFicheDePosteFailure = createAction(
  '[OperationFicheDePoste/API] Copy Operation FicheDePoste Failure',
  props<{ error: any }>()
);

//UPDATE FICHE DE POSTE
export const updateOperationFicheDePoste = createAction(
  '[OperationFicheDePoste/API] Update Operation FicheDePoste',
  props<{ id: string; fiche: any }>()
);
export const updateOperationFicheDePosteSuccess = createAction(
  '[OperationFicheDePoste/API] Update Operation FicheDePoste Success',
  props<{ fiche: any }>()
);

export const updateOperationFicheDePosteFailure = createAction(
  '[OperationFicheDePoste/API] Update Operation FicheDePoste Failure',
  props<{ error: any }>()
);

//UPDATE FICHE DE POSTE ITEM
export const updateOperationFicheDePosteItem = createAction(
  '[OperationFicheDePoste/API] Update Operation FicheDePoste Item',
  props<{ fiche: any }>()
);
export const updateOperationFicheDePosteItemSuccess = createAction(
  '[OperationFicheDePoste/API] Update Operation FicheDePoste Item Success',
  props<{ fiche: any }>()
);

export const updateOperationFicheDePosteItemFailure = createAction(
  '[OperationFicheDePoste/API] Update Operation FicheDePoste Item Failure',
  props<{ error: any }>()
);

//DELETE ONE FICHE DE POSTE
export const deleteOperationFicheDePoste = createAction(
  '[OperationFicheDePoste/API] Delete Operation FicheDePoste',
  props<{ operationId: string; id: string }>()
);
export const deleteOperationFicheDePosteSuccess = createAction(
  '[OperationFicheDePoste/API] Delete Operation FicheDePoste Success',
  props<{ success: any }>()
);
export const deleteOperationFicheDePosteFailure = createAction(
  '[OperationFicheDePoste/API] Delete Operation FicheDePoste Failure',
  props<{ error: any }>()
);

//DELE ALL FICHE DE POSTE

export const deleteOperationFicheDePostes = createAction(
  '[OperationFicheDePoste/API] Delete Operation FicheDePostes',
  props<{ id: string; ids: string[] }>()
);
export const deleteOperationFicheDePostesSuccess = createAction(
  '[OperationFicheDePoste/API] Delete Operation FicheDePostes Success',
  props<{ success: string }>()
);

export const deleteOperationFicheDePostesFailure = createAction(
  '[OperationFicheDePoste/API] Delete Operation FicheDePostes Failure',
  props<{ error: any }>()
);

//CLEAR ALL

export const clearOperationFicheDePostes = createAction(
  '[OperationFicheDePoste/API] Clear Operation FicheDePostes'
);
