import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  ContactSelectState,
  contactsSelectsFeatureKey,
  selectAll,
} from './contacts-select.reducer';

export const selectContactState = createFeatureSelector<ContactSelectState>(
  contactsSelectsFeatureKey
);

export const contacts = createSelector(selectContactState, selectAll);
