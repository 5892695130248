import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Metier } from './metier.model';

//LOADING
export const loadingMetiers = createAction(
  '[Metier/API] Loading Metiers Success',
  props<{ loading: boolean }>()
);

//THEMES
export const loadThemes = createAction('[Metier/COMPONENTS] Load Themes');

export const loadThemesSuccess = createAction(
  '[Metier/API] Load Themes Success',
  props<{ themes: any }>()
);

export const loadThemesFailure = createAction(
  '[Metier/API] Load Themes Failure',
  props<{ error: any }>()
);

//DOMAINES
export const loadDomaines = createAction('[Metier/COMPONENTS] Load Domaines');

export const loadDomainesSuccess = createAction(
  '[Metier/API] Load Domaines Success',
  props<{ domaines: any }>()
);

export const loadDomainesFailure = createAction(
  '[Metier/API] Load Domaines Failure',
  props<{ error: any }>()
);

//DOMAINES PROFESSIONNEL
export const loadDomainesProfessionnels = createAction(
  '[Metier/COMPONENTS] Load Domaines Professionnels'
);

export const loadDomainesProfessionnelsSuccess = createAction(
  '[Metier/API] Load Domaines Professionnels Success',
  props<{ domaines: any }>()
);

export const loadDomainesProfessionnelsFailure = createAction(
  '[Metier/API] Load Domaines Professionnels Failure',
  props<{ error: any }>()
);

//DOMAINES PROFESSIONNEL BY GRAND DOMAINE
export const loadDomainesProfessionnelsByGrandDomaine = createAction(
  '[Metier/COMPONENTS] Load Domaines Professionnels By Grand Domaine',
  props<{ code: string }>()
);

export const loadDomainesProfessionnelsByGrandDomaineSuccess = createAction(
  '[Metier/API] Load Domaines Professionnels By Grand Domaine Success',
  props<{ domaines: any }>()
);

export const loadDomainesProfessionnelsByGrandDomaineFailure = createAction(
  '[Metier/API] Load Domaines Professionnels By Grand Domaine Failure',
  props<{ error: any }>()
);

//METIERS BY DOMAINE
export const loadMetiersByDomaine = createAction(
  '[Metier/COMPONENTS] Load Metiers By Domaine',
  props<{ code: string }>()
);

export const loadMetiersByDomaineSuccess = createAction(
  '[Metier/API] Load Metiers By Domaine Success',
  props<{ metiers: any }>()
);

export const loadMetiersByDomaineFailure = createAction(
  '[Metier/API] Load Metiers By Domaine Failure',
  props<{ error: any }>()
);

//APPELATION BY METIER
export const loadAppelationsByMetier = createAction(
  '[Metier/COMPONENTS] Load Appelations By Metiers',
  props<{ code: string }>()
);

export const loadAppelationsByMetierSuccess = createAction(
  '[Metier/API] Load Appelations By Metiers Success',
  props<{ appelations: any }>()
);

export const loadAppelationsByMetierFailure = createAction(
  '[Metier/API] Load Appelations By Metiers Failure',
  props<{ error: any }>()
);

//APPELATION DETAIL BY APPELATION
export const loadAppelationDetailByAppelation = createAction(
  '[Metier/COMPONENTS] Load Appelation Detail By Appelation',
  props<{ code: string }>()
);

export const loadAppelationDetailByAppelationSuccess = createAction(
  '[Metier/API] Load Appelation Detail By Appelation Success',
  props<{ appelation: any }>()
);

export const loadAppelationDetailByAppelationFailure = createAction(
  '[Metier/API] Load Appelation Detail By Appelation Failure',
  props<{ error: any }>()
);

//METIERS
export const loadMetierDetail = createAction(
  '[Metier/COMPONENTS] Load Metier Detail',
  props<{ code: string }>()
);

export const loadMetierDetailSuccess = createAction(
  '[Metier/API] Load Metier Detail  Success',
  props<{ metier: any }>()
);

export const loadMetierDetailFailure = createAction(
  '[Metier/API] Load Metier Detail Failure',
  props<{ error: any }>()
);

//QUALIFICATION
export const loadQualifications = createAction(
  '[Metier/COMPONENTS] Load Metier Qualifications'
);

export const loadQualificationsSuccess = createAction(
  '[Metier/API] Load Metier Qualifications Success',
  props<{ qualifications: any }>()
);

export const loadQualificationsFailure = createAction(
  '[Metier/API] Load Metier Qualifications Failure',
  props<{ error: any }>()
);

//METIER FAP
export const loadFap = createAction(
  '[Metier/COMPONENTS] Load Metier Fap',
  props<{ code: string }>()
);

export const loadFapSuccess = createAction(
  '[Metier/API] Load Metier Fap Success',
  props<{ fap: any }>()
);

export const loadFapFailure = createAction(
  '[Metier/API] Load Metier Fap Failure',
  props<{ error: any }>()
);

export const clearMetiers = createAction('[Metier/API] Clear Metiers');
