<nz-card [nzTitle]="ficheCardMissionTitle" nzBorderless>
  <!-- CARD TITLE -->
  <ng-template #ficheCardMissionTitle>
    <nz-space nzDirection="horizontal" nzSize="small">
      <span *nzSpaceItem>{{ mission?.metier?.libelle }}</span>
      <nz-tag *nzSpaceItem>ROME : {{ mission?.metier?.metier?.code }}</nz-tag>
      <nz-divider
        *nzSpaceItem
        nzOrientation="center"
        nzType="vertical"
      ></nz-divider>
      <nz-tag *nzSpaceItem
        >ISCO : {{ mission?.metier?.metier?.codeIsco }}</nz-tag
      >
    </nz-space>
  </ng-template>
  <!-- MÉTIER -->
  <nz-card
    nzSize="small"
    [nzTitle]="ficheDePosteMetierTitlteCard"
    [nzExtra]="ficheDePosteEntiteCard"
    nzBorderless
  >
    <nz-descriptions nzSize="small" nzBordered>
      <nz-descriptions-item nzSpan="3" nzTitle="Grand domaine">
        {{
          mission?.metier?.metier?.domaineProfessionnel?.grandDomaine?.libelle
        }}
      </nz-descriptions-item>
      <nz-descriptions-item nzSpan="3" nzTitle="Domaine professionnel">
        {{ mission?.metier?.metier?.domaineProfessionnel?.libelle }}
      </nz-descriptions-item>
      <nz-descriptions-item nzSpan="3" nzTitle="Compétences de base">
        <nz-tag
          nzColor="geekblue"
          *ngIf="mission?.metier?.competencesDeBaseType?.Savoir | size"
          >Savoirs :
          {{
            mission?.metier?.competencesDeBaseType?.Savoir[0]?.libelle
              | carLimitation : 80
          }}</nz-tag
        >
        <nz-badge
          [nzCount]="mission?.metier?.competencesDeBaseType?.Savoir | size"
          [nzOverflowCount]="1"
          nz-popover
          nzPopoverPlacement="bottom"
          nzPopoverTitle="Compétences de base"
          nzPopoverTrigger="click"
          [nzPopoverContent]="competenceSavoirListTpl"
        ></nz-badge>
        <ng-template #competenceSavoirListTpl>
          <app-metiers-competences-list
            [source]="mission?.metier?.competencesDeBaseType?.Savoir"
            [item]="'libelle'"
          ></app-metiers-competences-list>
        </ng-template>
        <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
        <nz-tag
          nzColor="geekblue"
          *ngIf="mission?.metier?.competencesDeBaseType?.SavoirFaire | size"
          >Savoirs faire :
          {{
            mission?.metier?.competencesDeBaseType?.SavoirFaire[0]?.libelle
              | carLimitation : 80
          }}</nz-tag
        >
        <nz-badge
          [nzCount]="mission?.metier?.competencesDeBaseType?.SavoirFaire | size"
          [nzOverflowCount]="1"
          nz-popover
          nzPopoverPlacement="bottom"
          nzPopoverTitle="Compétences de base"
          nzPopoverTrigger="click"
          [nzPopoverContent]="competenceSavoirFaireListTpl"
        ></nz-badge>
        <ng-template #competenceSavoirFaireListTpl>
          <app-metiers-competences-list
            [source]="mission?.metier?.competencesDeBaseType?.SavoirFaire"
            [item]="'libelle'"
          ></app-metiers-competences-list>
        </ng-template>
      </nz-descriptions-item>
      <nz-descriptions-item nzSpan="3" nzTitle="Compétences spécifiques">
        <nz-tag
          nzColor="geekblue"
          *ngIf="mission?.metier?.competencesSpecifiques | size"
        >
          {{
            mission?.metier?.competencesSpecifiques[0]?.libelle
              | carLimitation : 80
          }}</nz-tag
        >
        <nz-badge
          [nzCount]="mission?.metier?.competencesSpecifiques | size"
          [nzOverflowCount]="1"
          nz-popover
          nzPopoverPlacement="bottom"
          nzPopoverTitle="Compétences spécifiques"
          nzPopoverTrigger="click"
          [nzPopoverContent]="competenceSpecifiquesListTpl"
        ></nz-badge>
        <ng-template #competenceSpecifiquesListTpl>
          <app-metiers-competences-list
            [source]="mission?.metier?.competencesSpecifiques"
            [item]="'libelle'"
          ></app-metiers-competences-list>
        </ng-template>
      </nz-descriptions-item>
      <nz-descriptions-item nzSpan="3" nzTitle="Compétences transversales">
        <nz-avatar-group
          *ngIf="
            (mission?.metier?.softskills | size) > 0 &&
            (mission?.metier?.softskills | size) < 3
          "
        >
          <nz-tag
            nzColor="geekblue"
            *ngFor="let skill of mission?.metier?.softskills"
            >{{ skill?.summary | carLimitation : 80 }}</nz-tag
          >
        </nz-avatar-group>

        <ng-container *ngIf="(mission?.metier?.softskills | size) >= 3">
          <nz-tag
            nzColor="geekblue"
            >{{ mission?.metier?.softskills?.[0]?.summary |carLimitation:80}}</nz-tag
          >
          <nz-badge
            [nzCount]="mission?.metier?.softskills | size"
            [nzOverflowCount]="3"
            nz-popover
            nzPopoverPlacement="bottom"
            nzPopoverTitle="Compétences transvervales"
            nzPopoverTrigger="click"
            [nzPopoverContent]="competenceSoftskillsListTpl"
          ></nz-badge>
        </ng-container>
        <ng-template #competenceSoftskillsListTpl>
          <app-metiers-competences-list
            [source]="mission?.metier?.softskills"
            [item]="'summary'"
          ></app-metiers-competences-list>
        </ng-template>
      </nz-descriptions-item>
      <nz-descriptions-item nzSpan="3" nzTitle="Environnement">
        {{
              mission?.metier?.environnementsTravail?.[0]?.libelle|carLimitation:80
        }}
        <nz-badge
          *ngIf="(mission?.metier?.environnementsTravail | size) > 1"
          [nzCount]="mission?.metier?.environnementsTravail | size"
          [nzOverflowCount]="1"
          nz-popover
          nzPopoverPlacement="bottom"
          nzPopoverTitle="Environnement de travail"
          nzPopoverTrigger="click"
          [nzPopoverContent]="competenceEnvironnementListTpl"
        ></nz-badge>
        <ng-template #competenceEnvironnementListTpl>
          <app-metiers-competences-list
            [source]="mission?.metier?.environnementsTravail"
            [item]="'libelle'"
          ></app-metiers-competences-list>
        </ng-template>
      </nz-descriptions-item>
      <nz-descriptions-item nzSpan="3" nzTitle="Adresse">
        {{ mission?.adresse?.adresse?.properties?.label }}
      </nz-descriptions-item>
      <nz-descriptions-item nzSpan="3" nzTitle="Projet">
        {{ mission?.operation?.denomination }}
        <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
        {{mission?.operation?.financeurs?.[0]?.uniteLegale?.denominationUniteLegale}}
      </nz-descriptions-item>
    </nz-descriptions>
  </nz-card>
  <nz-space nzDirection="vertical" nzSize="small"></nz-space>

  <!-- CONTRAT -->
  <nz-card
    nzSize="small"
    [nzTitle]="ficheDePosteContratTitlteCard"
    [nzExtra]="ficheDePosteContratCard"
    nzBorderless
  >
    <nz-descriptions nzSize="small" nzBordered>
      <nz-descriptions-item nzSpan="3" nzTitle="Nature du contrat">
        {{ mission?.contrat?.nature?.libelle | carLimitation : 80 }}
      </nz-descriptions-item>
      <nz-descriptions-item nzSpan="3" nzTitle="Salaire">
        <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
          <nz-col nzSpan="10">{{
            mission?.contrat?.salaire?.libelleCaracteristique
          }}</nz-col>
          <nz-col nzSpan="4"
            ><nz-divider nzOrientation="center" nzType="vertical"></nz-divider
          ></nz-col>
          <nz-col nzSpan="10"
            ><nz-tag
              >Taux horaire payé (brut) :
              {{
                mission?.contrat?.work_price
                  | currency : "EUR" : "symbol" : "2.3" : "fr"
              }}</nz-tag
            ></nz-col
          >
        </nz-row>
      </nz-descriptions-item>
      <nz-descriptions-item nzSpan="3" nzTitle="Expérience">
        {{ mission?.contrat?.experience?.libelleCaracteristique }}
      </nz-descriptions-item>
      <nz-descriptions-item nzSpan="3" nzTitle="Dates">
        Démarrage : {{ mission?.contrat?.startMission | dateFr : "small" }}
        <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
        Échéance : {{ mission?.contrat?.endMission | dateFr : "small" }}
      </nz-descriptions-item>
      <nz-descriptions-item nzSpan="3" nzTitle="Jours de travail">
        <div class="ficheDePoste_week_days">
          <p nz-typography *ngFor="let jour of mission?.contrat?.work_days">
            {{ jour }}
          </p>
        </div>
      </nz-descriptions-item>
    </nz-descriptions>
  </nz-card>
</nz-card>
<nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>

<!-- ACTIONS -->
<div class="w-full flex items-end p-6 space-x-4 h-40">
  <div class="w-2/4"></div>
  <div class="w-24">
    <button class="btn btn-sm btn-ghost" (click)="onCleanMetier()">
      Annuler
    </button>
  </div>
  <div class="w-40">
    <button class="btn-default" (click)="onBackToEtablissement()">
      Revenir à l'entreprise
    </button>
  </div>
  <div class="w-40">
    <button class="btn btn-sm btn-primary" (click)="onValidate()">
      Confirmer la mission
    </button>
  </div>
</div>

<!-- TEMPLATES -->
<ng-template #ficheDePosteEntiteCard>
  <div class="p-6">
    <button (click)="onCleanMetier()" class="btn btn-sm">
      Modifier le métier
    </button>
  </div>
</ng-template>

<ng-template #ficheDePosteContratCard>
  <div class="p-6">
    <button
      (click)="$event.stopPropagation(); onCleanContrat()"
      class="btn btn-sm"
    >
      Modifier le contrat
    </button>
  </div>
</ng-template>

<ng-template #ficheDePosteMetierTitlteCard>
  <app-header-title
    [icon]="'business_center'"
    [title]="''"
    [subtitle]="true"
  ></app-header-title>
</ng-template>
<ng-template #ficheDePosteContratTitlteCard>
  <app-header-title
    [icon]="'subject'"
    [title]="''"
    [subtitle]="true"
  ></app-header-title>
</ng-template>
