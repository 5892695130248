<form nz-form [formGroup]="aidsFiltersForm">
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col [nzSpan]="(loading$ | async) ? 2 : 0">
      <i nz-icon nzType="loading" *ngIf="loading$ | async"></i>
    </nz-col>
    <nz-col nzSpan="4" *ngIf="!(loading$ | async)">
      <nz-form-item nzAlign="middle" nzJustify="center" nzRequired>
        <nz-form-label nzRequired [nzSpan]="24" nzFor="age">Âge</nz-form-label>
        <nz-form-control>
          <nz-input-number
            nzPlaceHolder="Âge"
            formControlName="age"
            id="age"
            [nzMin]="16"
          ></nz-input-number>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col nzSpan="14" *ngIf="!(loading$ | async)">
      <nz-form-item>
        <nz-form-label [nzSpan]="24" nzFor="allocation"
          >Allocation</nz-form-label
        >

        <nz-form-control>
          <nz-select
            [nzBorderless]="true"
            [nzPlaceHolder]="'Type d\'allocation'"
            [nzSize]="'small'"
            id="allocation"
            nzShowSearch
            nzAllowClear
            formControlName="allocationType"
            *ngIf="(filters$ | async)?.allocation_type | size"
          >
            <nz-option
              *ngFor="let item of (filters$ | async)?.allocation_type"
              [nzValue]="item"
              [nzLabel]="item?.label"
              nzCustomContent
            >
              <nz-tag nzColor="geekblue">
                {{ item.value }}
              </nz-tag>
            </nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col nzSpan="6" *ngIf="!(loading$ | async)">
      <nz-form-item>
        <nz-form-label [nzSpan]="24" nzFor="valueAllocation"
          >Montant d'allocation</nz-form-label
        >
        <nz-form-control>
          <nz-input-number
            nzPlaceHolder="Montant de l'allocation"
            formControlName="monthlyAllocationValue"
            id="valueAllocation"
            [nzMin]="0"
          ></nz-input-number>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>
  <nz-row
    nzAlign="middle"
    nzGutter="8"
    nzJustify="start"
    *ngIf="!(loading$ | async)"
  >
    <nz-col nzSpan="10">
      <nz-form-item>
        <nz-form-label [nzSpan]="24" nzFor="filters">Besoin</nz-form-label>

        <nz-form-control>
          <nz-select
            [nzBorderless]="true"
            [nzPlaceHolder]="'Filtres'"
            [nzSize]="'small'"
            nzShowSearch
            id="filters"
            nzAllowClear
            formControlName="filters"
            *ngIf="(filters$ | async)?.filters | size"
          >
            <nz-option
              *ngFor="let item of (filters$ | async)?.filters"
              [nzValue]="item"
              [nzLabel]="item?.name"
            >
              {{ item?.name | titlecase }}
            </nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col nzSpan="8">
      <nz-form-item>
        <nz-form-label [nzSpan]="24" nzFor="diplome">Diplôme</nz-form-label>

        <nz-form-control>
          <nz-select
            [nzBorderless]="true"
            [nzPlaceHolder]="'Diplôme'"
            [nzSize]="'small'"
            nzShowSearch
            id="diplome"
            nzAllowClear
            formControlName="diploma"
            *ngIf="(filters$ | async)?.diploma | size"
          >
            <nz-option
              *ngFor="let item of (filters$ | async)?.diploma"
              [nzValue]="item"
              [nzLabel]="item?.label"
            >
              {{ item?.label | titlecase }}
            </nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </nz-col>

    <nz-col nzSpan="6" *ngIf="(filters$ | async)?.inscription_period | size">
      <nz-form-item nzRequired>
        <nz-form-label nzRequired [nzSpan]="24" nzFor="inscriptionPeriod"
          >Durée d'inscription</nz-form-label
        >
        <nz-form-control>
          <nz-select
            [nzBorderless]="true"
            [nzPlaceHolder]="'Durée d\'inscription'"
            [nzSize]="'small'"
            nzShowSearch
            id="inscriptionPeriod"
            nzAllowClear
            formControlName="inscriptionPeriod"
          >
            <nz-option
              *ngFor="let item of (filters$ | async)?.inscription_period"
              [nzValue]="item"
              [nzLabel]="item?.label"
            >
              {{ item?.label | titlecase }}
            </nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>

  <nz-row
    nzAlign="middle"
    nzGutter="8"
    nzJustify="start"
    *ngIf="!(loading$ | async)"
  >
    <nz-col nzSpan="10">
      <nz-form-item nzRequired>
        <nz-form-label nzRequired [nzSpan]="24" nzFor="category"
          >Catégorie</nz-form-label
        >
        <nz-form-control>
          <nz-select
            [nzBorderless]="true"
            [nzPlaceHolder]="'Catégorie'"
            [nzSize]="'small'"
            nzShowSearch
            nzAllowClear
            id="category"
            formControlName="category"
            *ngIf="(filters$ | async)?.category | size"
          >
            <nz-option
              *ngFor="let item of (filters$ | async)?.category"
              [nzValue]="item"
              [nzLabel]="item?.label"
            >
              {{ item?.label | titlecase | carLimitation: 50 }}
            </nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col nzSpan="6">
      <nz-form-item>
        <nz-form-label [nzSpan]="24" nzFor="commune">Commune</nz-form-label>

        <nz-form-control>
          <input
            nz-input
            nzBorderless
            placeHolder="Commune"
            id="commune"
            formControlName="locationCitycode"
          />
        </nz-form-control>
      </nz-form-item>
    </nz-col>

    <nz-col nzSpan="4">
      <nz-form-item>
        <nz-form-label [nzSpan]="24" nzFor="intermittent"></nz-form-label>

        <nz-tag
          nzMode="checkable"
          [nzColor]="aidsFiltersForm.value.spectacle ? 'processing' : 'default'"
          [nzChecked]="aidsFiltersForm.value.spectacle"
          (nzCheckedChange)="onTag($event, 'spectacle')"
          ><h5 nz-typography>Intermittent</h5></nz-tag
        >
      </nz-form-item>
    </nz-col>
    <nz-col nzSpan="4">
      <nz-form-item>
        <nz-form-label [nzSpan]="24" nzFor="handicap"></nz-form-label>
        <nz-tag
          nzMode="checkable"
          [nzColor]="aidsFiltersForm.value.disabled ? 'processing' : 'default'"
          [nzChecked]="aidsFiltersForm.value.disabled"
          (nzCheckedChange)="onTag($event, 'disabled')"
          ><h5 nz-typography>Handicap</h5></nz-tag
        >
      </nz-form-item>
    </nz-col>
  </nz-row>
  <nz-space [nzSize]="20"></nz-space>

  <nz-row
    nzAlign="middle"
    nzGutter="8"
    nzJustify="start"
    *ngIf="!(loading$ | async)"
  >
    <nz-col [nzSpan]="!(selectedAids | size) ? 10 : 4">
      <button
        nz-button
        (click)="onChanges()"
        nzBlock
        nzType="text"
        class="btnSearchAid"
        [disabled]="!aidsFiltersForm.valid"
      >
        <i nz-icon nzType="search"></i>
        <span>Rechercher</span>
      </button>
    </nz-col>
    <nz-col [nzSpan]="!(selectedAids | size) ? 14 : 10" class="aidsCounterFind">
      <nz-badge
        *ngIf="!(searchLoading$ | async); else aidLoaging"
        [nzCount]="aids$ | async | size"
        [nzOverflowCount]="10"
      ></nz-badge>

      <ng-template #aidLoaging>
        <i nz-icon nzType="loading"></i>
      </ng-template>
    </nz-col>

    <nz-col [nzSpan]="(selectedAids | size) ? 4 : 0">
      <button
        nz-button
        (click)="onValidationAll(selectedAids)"
        nzBlock
        nzType="primary"
        class="btnPrimaryAid"
      >
        <h5 nz-typography class="btnTitle">Ajouter</h5>
      </button>
    </nz-col>
  </nz-row>
</form>
