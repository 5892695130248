import { Pipe, PipeTransform } from '@angular/core';
import { size } from 'lodash';

@Pipe({
  name: 'size',
})
export class SizePipe implements PipeTransform {
  transform(value: any[]): number {
    if (!value) {
      return 0;
    }

    return size(value);
  }
}
