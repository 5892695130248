import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ParticipantDiagnostic } from './participant-diagnostic.model';
import * as ParticipantDiagnosticActions from './participant-diagnostic.actions';

export const participantDiagnosticsFeatureKey = 'participantDiagnostics';

export interface ParticipantDiagnosticState
  extends EntityState<ParticipantDiagnostic> {
  diagnostic: any;
  error: any;
}

export const adapter: EntityAdapter<ParticipantDiagnostic> =
  createEntityAdapter<ParticipantDiagnostic>();

export const initialState: ParticipantDiagnosticState = adapter.getInitialState(
  {
    diagnostic: undefined,
    error: undefined,
  }
);

export const reducer = createReducer(
  initialState,

  //LOAD ALL
  // on(
  //   ParticipantDiagnosticActions.loadParticipantDiagnosticsSuccess,
  //   (state, action) => adapter.setAll(action.diagnostics, state)
  // ),
  on(
    ParticipantDiagnosticActions.loadParticipantDiagnosticFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
      };
    }
  ),

  //LOAD ONE
  on(
    ParticipantDiagnosticActions.loadParticipantDiagnosticSuccess,
    (state, action) => {
      return {
        ...state,
        diagnostic: action.diagnostic,
      };
    }
  ),
  on(
    ParticipantDiagnosticActions.loadParticipantDiagnosticFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
      };
    }
  ),

  //ADD ONE
  on(ParticipantDiagnosticActions.addParticipantDiagnostic, (state, action) => {
    return {
      ...state,
      diagnostic: undefined,
    };
  }),
  on(
    ParticipantDiagnosticActions.addParticipantDiagnosticSuccess,
    (state, action) => {
      return {
        ...state,
        diagnostic: action.diagnostic,
      };
    }
  ),
  on(
    ParticipantDiagnosticActions.addParticipantDiagnosticFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
      };
    }
  ),

  //UPDATE ONE
  on(
    ParticipantDiagnosticActions.updateParticipantDiagnostic,
    (state, action) => {
      return {
        ...state,
        diagnostic: action.diagnostic.changes,
      };
    }
  ),
  // on(
  //   ParticipantDiagnosticActions.updateParticipantDiagnosticSuccess,
  //   (state, action) => adapter.updateOne(action.diagnostic, state)
  // ),
  on(
    ParticipantDiagnosticActions.updateParticipantDiagnosticFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
      };
    }
  ),

  //DELETE ONE
  on(
    ParticipantDiagnosticActions.deleteParticipantDiagnostic,
    (state, action) => {
      return {
        ...state,
        diagnostic: undefined,
      };
    }
  ),
  on(
    ParticipantDiagnosticActions.deleteParticipantDiagnosticFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
      };
    }
  ),

  //DELETE ALL
  on(ParticipantDiagnosticActions.deleteParticipantDiagnostics, (state) =>
    adapter.removeAll(state)
  ),
  on(
    ParticipantDiagnosticActions.deleteParticipantDiagnosticFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
      };
    }
  )
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
