<div class="w-full flex flex-col items-start justify-between space-y-6 p-6">
  <div class="w-full flex items-center justify-start">
    <div class="w-full flex items-center gap-x-2">
      <div [class]="'flex flex-col items-start w-2/12 mb-6'">
        <label class="label required">Civilité</label>
        <div
          class="w-full pt-1 bg-slate-100 rounded-md h-10 hover:outline-1 hover:outline-slate-200 focus:outline-slate-400 active:outline-slate-400"
        >
          <nz-select
            [formControl]="civilite"
            nzPlaceHolder="Civilité"
            nzBorderless
            name="civilite"
          >
            <nz-option
              *ngFor="let item of civilite$ | async"
              [nzLabel]="item"
              [nzValue]="item"
            ></nz-option>
          </nz-select>
          <small
            *ngIf="civilite.invalid && civilite.dirty && civilite.touched"
            class="inpurFormAlert"
            >{{
              civilite.invalid && civilite.dirty && civilite.touched
                ? "Renseignez la civilité du contact"
                : ""
            }}</small
          >
        </div>
      </div>
      <div [class]="'flex flex-col items-start  w-4/12 mb-6'">
        <label class="label required">Nom</label>
        <input
          [formControl]="lastName"
          name="name"
          name="lastName"
          placeholder="Nom..."
          class="inputForm"
        />

        <small
          *ngIf="lastName.invalid && lastName.dirty && lastName.touched"
          class="inputFormAlert"
          >{{
            lastName.invalid && lastName.dirty && lastName.touched
              ? "Renseignez le nom du contact"
              : ""
          }}</small
        >
      </div>
      <div
        [class]="
          isNaissance
            ? 'flex flex-col items-start  w-3/6 mb-6'
            : 'flex flex-col items-start  w-6/12 mb-6'
        "
      >
        <label class="label required">Prénom</label>
        <input
          class="inputForm"
          [formControl]="firstName"
          name="name"
          name="firstName"
          placeholder="Prénom..."
        />
        <small
          *ngIf="firstName.invalid && firstName.dirty && firstName.touched"
          class="inputFormAlert"
          >{{
            firstName.invalid && firstName.dirty && firstName.touched
              ? "Renseignez le prénom du contact"
              : ""
          }}</small
        >
      </div>
      <div
        [class]="
          isNaissance ? 'w-3/12 flex flex-col items-start mb-6' : 'hidden'
        "
      >
        <label class="label required whitespace-nowrap"
          >Date de naissance</label
        >
        <div
          class="w-full pt-1 bg-slate-100 rounded-md h-10 hover:outline-1 hover:outline-slate-200 focus:outline-slate-400 active:outline-slate-400"
        >
          <nz-date-picker
            [nzFormat]="dateFormat"
            nzBorderless
            name="dateNaissance"
            [formControl]="dateNaissance"
            nzPlaceHolder="dd/MM/yyyy"
          ></nz-date-picker>
        </div>
        <small
          *ngIf="
            dateNaissance.invalid &&
            dateNaissance.dirty &&
            dateNaissance.touched
          "
          class="inpurFormAlert"
          >{{
            dateNaissance.invalid &&
            dateNaissance.dirty &&
            dateNaissance.touched
              ? "Veuillez renseigner la date de naissance"
              : ""
          }}</small
        >
      </div>
    </div>
  </div>

  <div *ngIf="isCoordonnees" class="w-full flex items-center justify-star">
    <app-coordonnees
      [isFixeRequired]="false"
      [isMobile]="true"
      [update]="coordonnees"
      [isEmailRequired]="isEmailRequired"
      [isMobileRequired]="isMobileRequired"
      [isFixeRequired]="isFixeRequired"
      (coordonnees)="onMobile($event)"
      (fixe)="onFixe($event)"
      (email)="onEmail($event)"
      class="w-full"
    ></app-coordonnees>
  </div>

  <div *ngIf="isFonction" class="w-full flex items-center justify-start">
    <div class="w-full flex items-center">
      <div class="w-full flex flex-col items-start">
        <label class="label">Fonction</label>
        <input
          [formControl]="fonction"
          name="name"
          name="fonction"
          [placeholder]="'Fonction...'"
          class="inputForm"
        />
        <small
          *ngIf="fonction.invalid && fonction.dirty && fonction.touched"
          class="inputFormAlert"
          >{{
            fonction.invalid && fonction.dirty && fonction.touched
              ? "Renseignez la fonction du contact"
              : ""
          }}</small
        >
      </div>
    </div>
  </div>

  <div *ngIf="isRole" class="w-full flex items-center justify-start">
    <div class="w-full flex items-center">
      <div class="w-full flex flex-col items-start justify-between">
        <label class="label">Rôle</label>

        <input
          class="inputForm"
          [formControl]="role"
          name="role"
          [placeholder]="roleTitle"
        />
        <small
          *ngIf="role.invalid && role.dirty && role.touched"
          class="inpurFormAlert"
          >{{
            role.invalid && role.dirty && role.touched
              ? "Veuillez renseigner le rôle du contact"
              : ""
          }}</small
        >
      </div>
    </div>
  </div>
  <div *ngIf="isAdresse" class="w-full flex items-center justify-start">
    <app-communes-france
      [isComplement]="false"
      [isLabel]="true"
      [required]="isAdresseRequired"
      [borderless]="true"
      (adresse)="onAdresse($event)"
    ></app-communes-france>
  </div>
</div>
