<div class="grid grid-cols-2 gap-x-4">
  <div class="rounded-xl p-4 bg-slate-50">
    <span class="text-sm tracking-wider">{{
      candidat?.prescripteur?.etablissement?.uniteLegale
        ?.denominationUniteLegale
    }}</span>
    <span
      class="text-sm flex items-start space-x-2 tracking-wider text-gray-600 opacity-50"
    >
      <p>
        {{
          candidat?.prescripteur?.etablissement?.adresseEtablissement?.commune
            ?.nom
        }}
      </p>
      <p>
        {{
          candidat?.prescripteur?.etablissement?.adresseEtablissement?.commune
            ?.departement?.nom
        }}
      </p>
    </span>
    <span class="text-sm flex items-start space-x-2 tracking-wider opacity-50">
      <p>
        {{
          candidat?.prescripteur?.etablissement?.adresseEtablissement?.commune
            ?.region?.nom
        }}
      </p>
    </span>
  </div>
  <div class="rounded-xl p-4 bg-slate-50">
    <div class="tracking-wider">
      {{ candidat?.prescripteur?.displayName }}
    </div>
    <div class="tracking-wider grid grid-cols-1 space-y-2 text-sm opacity-50">
      <app-coordonnees-display
        [coordonnees]="candidat?.prescripteur?.coordonnees"
      ></app-coordonnees-display>
    </div>
  </div>
</div>
