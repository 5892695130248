import { ActivatedRoute, Router } from '@angular/router';
import { ParticipantDiagnosticFormComponent } from './../participant-diagnostic-form/participant-diagnostic-form.component';
import {
  ChangeDetectionStrategy,
  Component,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { UtilitiesState } from 'src/app/contents/components/utilities/store/utilities.reducer';
import * as fromUtilitiesAction from 'src/app/contents/components/utilities/store/utilities.actions';
import * as fromUtilitiesSelector from 'src/app/contents/components/utilities/store/utilities.selectors';
import { Observable, of, Subject, takeUntil } from 'rxjs';
import { ParticipantId } from 'src/app/components/participant/store/participant.model';
import { ParticipantState } from 'src/app/components/participant/store/participant.reducer';
import * as fromParticipantSelector from 'src/app/components/participant/store/participant.selectors';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ParticipantDiagnosticState } from '../../store/participant-diagnostic.reducer';
import * as fromParticipantDiagnosticAction from '../../store/participant-diagnostic.actions';
import * as fromParticipantDiagnosticSelector from '../../store/participant-diagnostic.selectors';

@Component({
  selector: 'app-participant-diagnostic-liste',
  templateUrl: './participant-diagnostic-liste.component.html',
  styleUrls: ['./participant-diagnostic-liste.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantDiagnosticListeComponent implements OnInit, OnChanges {
  participant$: Observable<ParticipantId | any> = of(null);
  diagnostics$: Observable<any> = of([]);
  subscribe = new Subject();
  data$: Observable<any> = of(null);

  //VARIABLES
  icon: string = 'fact_check';
  addtitle: string = 'Ajouter un diagnostic';
  notitle: string = 'Aucun diagnostic';
  description: string =
    "Le diagnostic est établi par un professionnel de l'inclusion sur deux axes : la situation professionnelle et la situation sociale de la personne en insertion.";
  title: string = 'Diagnostic';
  modalWidth: number = 1100;
  @ViewChild('diagnosticTitleForm', { static: false }) diagnosticTitleForm:
    | TemplateRef<any>
    | any;

  //**REFERENTIELS**//
  constructor(
    private utilitiesStore: Store<UtilitiesState>,
    private participantStore: Store<ParticipantState>,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private diagnosticStore: Store<ParticipantDiagnosticState>,
    private activateRouter: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getParticipant();
    this.getDiganostics();
    this.loadDiagnostics();
    this.loadReferentiels();
    this.getRouteData();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  getRouteData(): void {
    this.data$ = this.activateRouter.data;
  }

  getParticipant(): void {
    this.participant$ = this.participantStore.select(
      fromParticipantSelector.participant
    );
  }

  getDiganostics(): void {
    this.diagnostics$ = this.diagnosticStore.select(
      fromParticipantDiagnosticSelector.diagnostics
    );
  }

  loadReferentiels(): void {
    this.loadAllocationsReferentiel();
    this.loadSejourReferentiel();
    this.loadCriteresReferentiel();
  }

  loadAllocationsReferentiel(): void {
    this.utilitiesStore.dispatch(
      fromUtilitiesAction.loadAllocationReferentiel()
    );
  }

  loadSejourReferentiel(): void {
    this.utilitiesStore.dispatch(fromUtilitiesAction.loadSejourReferentiel());
  }

  loadCriteresReferentiel(): void {
    this.utilitiesStore.dispatch(fromUtilitiesAction.loadCriteresReferentiel());
  }

  loadDiagnostics(): void {
    this.participant$
      .pipe(takeUntil(this.subscribe))
      .subscribe((participant) => {
        if (!participant) return;
        const { id } = participant;
        this.diagnosticStore.dispatch(
          fromParticipantDiagnosticAction.loadParticipantDiagnostics({ id })
        );
      });
  }

  onSelect(participant: ParticipantId, diagnostic: any): void {
    if (!participant || !diagnostic) return;

    this.router.navigate([
      [
        '/participant',
        { id: participant.id },
        'diagnostic',
        { itemId: diagnostic.id },
      ],
    ]);
  }

  onNew(participant: ParticipantId): void {
    if (!participant) return;
    const modal = this.modal.create({
      nzContent: ParticipantDiagnosticFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.diagnosticTitleForm,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });
    const instance = modal.getContentComponent();
    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });

    instance.add.subscribe((diagnostic): any => {
      if (!diagnostic) return;
      this.onAdd(participant, diagnostic);
      modal.close();
    });
  }

  onAdd(participant: ParticipantId, diagnostic: any): void {
    if (!diagnostic || !participant) return;

    const { id } = participant;
    this.diagnosticStore.dispatch(
      fromParticipantDiagnosticAction.addParticipantDiagnostic({
        id,
        diagnostic,
      })
    );
  }

  onDropDownSelect(event: string, participant: ParticipantId): void {
    if (!event || !participant) return;
    switch (event) {
      case 'deleteAll':
        this.onDeleteAll(participant);
        break;

      default:
        break;
    }
  }

  onDelete(diagnostic: any, participant: ParticipantId): void {
    if (!participant || !diagnostic) return;

    this.diagnosticStore.dispatch(
      fromParticipantDiagnosticAction.deleteParticipantDiagnostic({
        participantId: participant.id,
        id: diagnostic.id,
      })
    );
  }

  onDeleteAll(participant: ParticipantId): void {
    if (!participant) return;

    const { id } = participant;
    this.diagnosticStore.dispatch(
      fromParticipantDiagnosticAction.deleteParticipantDiagnostics({
        id,
        ids: [],
      })
    );
  }
}
