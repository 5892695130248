import { ContactTaskModule } from './../contact-task/contact-task.module';
import { ContactOperationsService } from './../../service/contact-operations.service';
import { ContactStorageService } from './../../service/contact-storage.service';
import { ContactSuivisService } from './../../service/contact-suivis.service';
import { UserFavorisService } from './../../service/user-favoris.service';
import { QueryContactsService } from './../../service/query-contacts.service';
import { PortalNavigationService } from './../../portals/portal-navigation.service';
import { ContactsResolverService } from './../../resolvers/contacts-resolver.service';
import { ContentsModule } from './../../contents/contents.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ContactRoutingModule } from './contact-routing.module';
import { ContactComponent } from './components/contact/contact.component';
import { ContactListComponent } from './components/contact-list/contact-list.component';
import { ContactCardComponent } from './components/contact-card/contact-card.component';
import { ContactActionsComponent } from './components/contact-actions/contact-actions.component';
import { ContactDetailComponent } from './components/contact-detail/contact-detail.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { StoreModule } from '@ngrx/store';
import * as fromContact from './store/contact.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ContactEffects } from './store/contact.effects';
import { ContactsService } from 'src/app/service/contacts.service';
import { ContactResolverService } from 'src/app/resolvers/contact-resolver.service';
import { ContactItemComponent } from './components/contact-item/contact-item.component';
import { ContactIdentityComponent } from './components/contact-identity/contact-identity.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { ContactFiltersComponent } from './components/contact-filters/contact-filters.component';
import { ContactSuivisComponent } from './components/contact-suivis/contact-suivis.component';
import { ContactDocumentsComponent } from './components/contact-documents/contact-documents.component';
import { ContactItemHeaderComponent } from './components/contact-item-header/contact-item-header.component';
import { ContactOperationsComponent } from './components/contact-operations/contact-operations.component';
import { ContactDashboardComponent } from './components/contact-dashboard/contact-dashboard.component';
import { ContactMetiersComponent } from './components/contact-metiers/contact-metiers.component';
import { ContactEntitesComponent } from './components/contact-entites/contact-entites.component';
import { ContactEntitesService } from 'src/app/service/contact-entites.service';
import { ContactMetiersService } from 'src/app/service/contact-metiers.service';
import { NoResultModule } from 'src/app/contents/components/no-result/no-result.module';
import { CoordonneesModule } from 'src/app/contents/components/coordonnees/coordonnees.module';
import { CommunesFranceModule } from 'src/app/contents/components/communes-france/communes-france.module';
import { MetiersModule } from 'src/app/features/metiers/metiers.module';
import { HeaderModule } from 'src/app/contents/components/header-title/header.module';
import { LayoutModule } from 'src/app/contents/components/layout/layout.module';
import { AvatarModule } from 'src/app/features/avatar/avatar.module';
import { SuiviModule } from 'src/app/features/suivi/suivi.module';
import { DocumentModule } from 'src/app/features/document/document.module';
import { UiModule } from 'src/app/ui/ui/ui.module';

@NgModule({
  declarations: [
    ContactComponent,
    ContactListComponent,
    ContactCardComponent,
    ContactActionsComponent,
    ContactDetailComponent,
    ContactItemComponent,
    ContactIdentityComponent,
    ContactFormComponent,
    ContactFiltersComponent,
    ContactSuivisComponent,
    ContactDocumentsComponent,
    ContactItemHeaderComponent,
    ContactOperationsComponent,
    ContactDashboardComponent,
    ContactMetiersComponent,
    ContactEntitesComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ContentsModule,
    ContactTaskModule,
    ContactRoutingModule,
    NoResultModule,
    CoordonneesModule,
    HeaderModule,
    LayoutModule,
    CommunesFranceModule,
    MetiersModule,
    AvatarModule,
    SuiviModule,
    DocumentModule,
    UiModule,
    StoreModule.forFeature(fromContact.contactsFeatureKey, fromContact.reducer),
    EffectsModule.forFeature([ContactEffects]),
  ],
  exports: [
    ContactComponent,
    ContactListComponent,
    ContactCardComponent,
    ContactActionsComponent,
    ContactDetailComponent,
    ContactItemComponent,
    ContactIdentityComponent,
    ContactFormComponent,
    ContactFiltersComponent,
    ContactSuivisComponent,
    ContactDocumentsComponent,
    ContactItemHeaderComponent,
    ContactOperationsComponent,
    ContactDashboardComponent,
  ],
  providers: [
    ContactsService,
    ContactResolverService,
    ContactsResolverService,
    PortalNavigationService,
    QueryContactsService,
    UserFavorisService,
    ContactSuivisService,
    ContactStorageService,
    ContactOperationsService,
    ContactEntitesService,
    ContactMetiersService,
  ],
})
export class ContactModule {}
