import { OperationExportPdfService } from './../../../service/operation-export-pdf.service';
import { OperationTeamService } from './../../operation-team/services/operation-team.service';
import { OperationParticipantsService } from './../../../service/operation-participants.service';
import { OperationContactsService } from './../../../service/operation-contacts.service';
import { OperationEntitesService } from './../../../service/operation-entites.service';
import { OperationStorageService } from './../../../service/operation-storage.service';
import { ItemEventId } from '../../../features/suivi/components/item-event/itemEvent.model';
import { OperationSuivisService } from './../../../service/operation-suivis.service';
import { QueryOperationsService } from './../../../service/query-operations.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { OperationService } from './../../../service/operation.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromOperationAction from './operation.actions';
import { of, timer, concatMap, delay } from 'rxjs';
import { tap, mergeMap, exhaustMap, map, catchError } from 'rxjs/operators';
import { OperationSmallId } from './operation.model';
import { Router } from '@angular/router';
import { OperationExportPptService } from 'src/app/service/operation-export-ppt.service';
import { OperationExportExcelService } from 'src/app/service/operation-export-excel.service';
import { OperationSharingService } from 'src/app/service/operation-sharing.service';
import { OperationMissionsService } from 'src/app/service/operation-missions.service';
import {
  NzNotificationPlacement,
  NzNotificationService,
} from 'ng-zorro-antd/notification';

const NOTIFICATION_PLACEMENT: NzNotificationPlacement = 'bottomRight';
@Injectable()
export class OperationEffects {
  loadOperations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationAction.loadOperations),
      mergeMap((action) =>
        this.operationService.getAll().pipe(
          delay(2000),
          map((operations: any) =>
            fromOperationAction.loadOperationsSuccess({ operations })
          ),
          catchError((error) =>
            of(fromOperationAction.loadOperationsFailure({ error }))
          )
        )
      )
    )
  );

  loadOperation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationAction.loadOperation),
      mergeMap((action) =>
        this.operationService.getOne(action.id).pipe(
          delay(2000),

          map((operation: any) =>
            fromOperationAction.loadOperationSuccess({ operation })
          ),
          catchError((error) =>
            of(fromOperationAction.loadOperationFailure({ error }))
          )
        )
      )
    )
  );

  addOperation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationAction.addOperation),
      tap((action) => {
        this.notification.info(
          'Nouveau',
          `Le projet ${action.operation.denomination} est en cours de création...`,
          {
            nzDuration: 6000,
            nzAnimate: true,
            nzPlacement: NOTIFICATION_PLACEMENT,
            nzKey: 'CREATE_PROJET',
          }
        );
      }),
      mergeMap((action) =>
        this.operationService.addOne(action.operation).pipe(
          map((success: string) => {
            this.notification.success('Nouveau', success, {
              nzDuration: 6000,
              nzAnimate: true,
              nzPlacement: NOTIFICATION_PLACEMENT,
              nzKey: 'CREATE_PROJET',
            });

            return fromOperationAction.addOperationSuccess({
              success,
            });
          }),
          catchError((error) => {
            this.onErrorNotification(error, 'CREATE_PROJET');
            return of(fromOperationAction.addOperationFailure({ error }));
          })
        )
      )
    )
  );

  updateOperation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationAction.updateOperation),
      mergeMap((action) =>
        this.operationService
          .updateOne(action.operation.id, action.operation.changes)
          .pipe(
            map((success: any) => {
              this.message.success(success, { nzDuration: 6000 });
              return fromOperationAction.updateOperationSuccess({
                success,
              });
            }),
            catchError((error) =>
              of(fromOperationAction.updateOperationFailure({ error }))
            )
          )
      )
    )
  );

  //DELETE OPERATION
  deleteOperation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationAction.deleteOperation),
      tap(() => {
        this.notification.info(
          'Suppression',
          `Projet en cours de suppression...`,
          {
            nzDuration: 6000,
            nzAnimate: true,
            nzKey: 'DELETE_OPERATION',
            nzPlacement: NOTIFICATION_PLACEMENT,
          }
        );
        this.router.navigate(['/projets']);
      }),
      mergeMap((action) =>
        this.operationService.deleteOperation(action.id).pipe(
          map((respons: any) => {
            const success: string = respons?.data?.deleteOperation;
            this.notification.success('Suppression', success, {
              nzDuration: 6000,
              nzAnimate: true,
              nzKey: 'DELETE_OPERATION',
              nzPlacement: NOTIFICATION_PLACEMENT,
            });

            return fromOperationAction.deleteOperationSuccess({
              success,
            });
          }),
          catchError((error) => {
            this.onErrorNotification(error, 'DELETE_OPERATION');
            return of(fromOperationAction.deleteOperationFailure({ error }));
          })
        )
      )
    )
  );

  //DELETE OPERATION
  deleteOperations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationAction.deleteAllOperations),
      tap(() => {
        this.notification.info(
          'Suppression',
          `Vos projets sont en cours de suppression...`,
          {
            nzDuration: 6000,
            nzAnimate: true,
            nzKey: 'DELETE_OPERATIONS',
            nzPlacement: NOTIFICATION_PLACEMENT,
          }
        );
        this.router.navigate(['/projets']);
      }),
      mergeMap((action) =>
        this.operationService.deleteOperations().pipe(
          map((respons: any) => {
            const success: string = respons?.data?.deleteOperations;
            this.notification.success('Suppression', success, {
              nzDuration: 6000,
              nzAnimate: true,
              nzKey: 'DELETE_OPERATIONS',
              nzPlacement: NOTIFICATION_PLACEMENT,
            });

            return fromOperationAction.deleteAllOperationsSuccess({
              success,
            });
          }),
          catchError((error) => {
            this.onErrorNotification(error, 'DELETE_OPERATIONS');
            return of(
              fromOperationAction.deleteAllOperationsFailure({ error })
            );
          })
        )
      )
    )
  );

  //EXPORT PDF
  exportPDF$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationAction.exportPDFOperation),
      mergeMap((action) =>
        this.operationExportPdf.onExportPDF(action.operation).pipe(
          map((success: any) => {
            this.message.success(success, { nzDuration: 6000 });

            return fromOperationAction.exportPDFOperationSuccess({
              success,
            });
          }),
          catchError((error) =>
            of(fromOperationAction.exportPDFOperationFailure({ error }))
          )
        )
      )
    )
  );

  //EXPORT PPT
  exportPPT$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationAction.exportPPTOperation),
      mergeMap((action) =>
        this.operationExportPpt.onExportPPT(action.operation).pipe(
          map((success: any) => {
            this.message.success(success, { nzDuration: 6000 });

            return fromOperationAction.exportPPTOperationSuccess({
              success,
            });
          }),
          catchError((error) =>
            of(fromOperationAction.exportPPTOperationFailure({ error }))
          )
        )
      )
    )
  );

  //EXPORT EXCEL
  exportEXCEL$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationAction.exportEXCELOperation),
      mergeMap((action) =>
        this.operationExportExcel.exportEXCEL(action.operation).pipe(
          map((success: any) => {
            this.message.success(success, { nzDuration: 6000 });

            return fromOperationAction.exportEXCELOperationSuccess({
              success,
            });
          }),
          catchError((error) =>
            of(fromOperationAction.exportEXCELOperationFailure({ error }))
          )
        )
      )
    )
  );

  //FILTERS
  loadOperationFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationAction.loadOperationFilters),
      mergeMap((action) =>
        this.queryOperations.getFilters().pipe(
          map((filters: any) =>
            fromOperationAction.loadOperationFiltersSuccess({ filters })
          ),
          catchError((error) =>
            of(fromOperationAction.loadOperationFiltersFailure({ error }))
          )
        )
      )
    )
  );

  //QUERY
  queyOperation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationAction.queryOperations),
      mergeMap((action) =>
        this.queryOperations.queryOperations(action.filter).pipe(
          map((operations: OperationSmallId[]) =>
            fromOperationAction.queryOperationsSuccess({ operations })
          ),
          catchError((error) =>
            of(fromOperationAction.queryOperationsFailure({ error }))
          )
        )
      )
    )
  );

  //SUIVIS
  loadSuivis$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationAction.loadSuivis),
      mergeMap((action) =>
        this.operationSuivis.getAll(action.operationId).pipe(
          map((suivis: ItemEventId[]) =>
            fromOperationAction.loadSuivisSuccess({
              suivis,
            })
          ),
          catchError((error) =>
            of(fromOperationAction.loadSuivisFailure({ error }))
          )
        )
      )
    )
  );
  addSuivi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationAction.addSuivi),
      mergeMap((action) =>
        this.operationSuivis.addOne(action.operationId, action.suivi).pipe(
          map((suivi: ItemEventId) => {
            this.message.success(`Suivi ajouté`, { nzDuration: 6000 });
            return fromOperationAction.addSuiviSuccess({
              suivi,
            });
          }),
          catchError((error) =>
            of(fromOperationAction.addSuiviFailure({ error }))
          )
        )
      )
    )
  );
  updateSuivi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationAction.updateSuivi),
      mergeMap((action) =>
        this.operationSuivis
          .updateOne(action.operationId, action.suivi.changes)
          .pipe(
            map((suivi: ItemEventId) => {
              this.message.success(`Suivi modifié`, { nzDuration: 6000 });
              return fromOperationAction.updateSuiviSuccess({
                suivi,
              });
            }),
            catchError((error) =>
              of(fromOperationAction.updateSuiviFailure({ error }))
            )
          )
      )
    )
  );
  deleteSuivi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationAction.deleteSuivi),
      mergeMap((action) =>
        this.operationSuivis.deleteOne(action.operationId, action.suiviId).pipe(
          map((success: string) => {
            this.message.success(success, { nzDuration: 6000 });
            return fromOperationAction.deleteSuiviSuccess({
              success,
            });
          }),
          catchError((error) =>
            of(fromOperationAction.deleteSuiviFailure({ error }))
          )
        )
      )
    )
  );

  //DOCUMENT
  //load
  loadDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationAction.loadDocuments),
      mergeMap((action) =>
        this.operationStorage.getAll(action.operationId).pipe(
          map((documents: any) =>
            fromOperationAction.loadDocumentsSuccess({
              documents,
            })
          ),
          catchError((error) =>
            of(fromOperationAction.loadDocumentsFailure({ error }))
          )
        )
      )
    )
  );

  //add
  addDocument$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromOperationAction.addDocuments),
        mergeMap((action) =>
          this.operationStorage
            .addFiles(action.operation, action.documents)
            .pipe(
              map((documents: any) => {
                const plurial: string = `${'s'}`;

                const success: string = `Document${plurial} ajouté${plurial}`;
                this.message.success(success, { nzDuration: 6000 });
                return fromOperationAction.addDocumentsSuccess({
                  documents,
                });
              }),
              catchError((error) =>
                of(fromOperationAction.addDocumentsFailure({ error }))
              )
            )
        )
      ),
    { dispatch: false }
  );

  //delete
  deleteDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationAction.deleteDocument),
      mergeMap((action) =>
        this.operationStorage
          .deleteOne(action.operationId, action.document)
          .pipe(
            map((success: string) => {
              this.message.success(success, { nzDuration: 6000 });
              return fromOperationAction.deleteDocumentSuccess({
                success,
              });
            }),
            catchError((error) =>
              of(fromOperationAction.deleteDocumentFailure({ error }))
            )
          )
      )
    )
  );

  //CONTACTS
  loadContactsOperation = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationAction.loadContactsOperation),
      mergeMap((action) =>
        this.operationContactsService.getAll(action.operationId).pipe(
          map((contacts: any) =>
            fromOperationAction.loadContactsOperationSuccess({
              contacts,
            })
          ),
          catchError((error) =>
            of(fromOperationAction.loadContactsOperationFailure({ error }))
          )
        )
      )
    )
  );
  addContactsOperation = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationAction.addContactsOperation),
      mergeMap((action) =>
        this.operationContactsService
          .addAll(action.operation, action.contactsSelected)
          .pipe(
            map(() => {
              const plurial: string = 's';
              const success: string = `Contact${plurial} ajouté${plurial}`;
              this.message.success(success, { nzDuration: 6000 });
              return fromOperationAction.addContactsOperationSuccess({
                success,
              });
            }),
            catchError((error) =>
              of(fromOperationAction.addContactsOperationFailure({ error }))
            )
          )
      )
    )
  );

  deleteContactOperation = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationAction.deleteContactOperation),
      mergeMap((action) =>
        this.operationContactsService
          .deleteOne(action.operationId, action.contactId)
          .pipe(
            map((success: string) => {
              this.message.success(success, { nzDuration: 6000 });
              return fromOperationAction.deleteContactOperationSuccess({
                success,
              });
            }),
            catchError((error) =>
              of(fromOperationAction.deleteContactOperationFailure({ error }))
            )
          )
      )
    )
  );
  //PARTICIPANTS
  loadParticipantsOperation = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationAction.loadParticipantsOperation),
      mergeMap((action) =>
        this.operationParticipantsService.getAll(action.operationId).pipe(
          map((participants: any) =>
            fromOperationAction.loadParticipantsOperationSuccess({
              participants,
            })
          ),
          catchError((error) =>
            of(fromOperationAction.loadParticipantsOperationFailure({ error }))
          )
        )
      )
    )
  );
  loadParticipantOperation = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationAction.loadParticipantOperation),
      mergeMap((action) =>
        this.operationParticipantsService
          .getOne(action.operationId, action.id)
          .pipe(
            map((participant: any) =>
              fromOperationAction.loadParticipantOperationSuccess({
                participant,
              })
            ),
            catchError((error) =>
              of(fromOperationAction.loadParticipantOperationFailure({ error }))
            )
          )
      )
    )
  );
  addParticipantOperation = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationAction.addParticipantOperation),
      mergeMap((action) =>
        this.operationParticipantsService
          .addOne(action.operation, action.participant)
          .pipe(
            map((participant: any) => {
              const success: string = `${participant.participant.lastName} ajouté au projet.`;
              this.message.success(success, { nzDuration: 6000 });
              return fromOperationAction.addParticipantOperationSuccess({
                participant,
              });
            }),
            catchError((error) =>
              of(fromOperationAction.addParticipantOperationFailure({ error }))
            )
          )
      )
    )
  );

  deleteParticipantOperation = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationAction.deleteParticipantOperation),
      mergeMap((action) =>
        this.operationParticipantsService
          .deleteOne(action.operationId, action.participantId)
          .pipe(
            map((success: string) => {
              console.log(success);
              this.message.success(success, { nzDuration: 6000 });
              return fromOperationAction.deleteParticipantOperationSuccess({
                success,
              });
            }),
            catchError((error) =>
              of(
                fromOperationAction.deleteParticipantOperationFailure({
                  error,
                })
              )
            )
          )
      )
    )
  );

  //MISSIONS
  loadMissionsOperation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationAction.loadOperationMissions),
      mergeMap((action) =>
        this.operationMission.getAll(action.id).pipe(
          map((missions: any) =>
            fromOperationAction.loadOperationMissionsSuccess({
              missions,
            })
          ),
          catchError((error) =>
            of(fromOperationAction.loadOperationMissionsFailure({ error }))
          )
        )
      )
    )
  );
  loadMissionOperation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationAction.loadOperationMission),
      mergeMap((action) =>
        this.operationMission.getOne(action.operationId, action.id).pipe(
          map((mission: any) =>
            fromOperationAction.loadOperationMissionSuccess({
              mission,
            })
          ),
          catchError((error) =>
            of(fromOperationAction.loadOperationMissionFailure({ error }))
          )
        )
      )
    )
  );

  addMissionOperation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromOperationAction.addOperationMission),
        mergeMap((action) =>
          this.operationMission.addOne(action.mission).pipe(
            map((success: any) => {
              this.message.success(success, { nzDuration: 6000 });
              return fromOperationAction.addOperationMissionSuccess({
                success,
              });
            }),
            catchError((error) =>
              of(fromOperationAction.addOperationMissionFailure({ error }))
            )
          )
        )
      ),
    { dispatch: false }
  );

  updateMissionOperation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationAction.updateOperationMission),
      mergeMap((action) =>
        this.operationMission.updateOne(action.id, action.mission.changes).pipe(
          map((success: any) => {
            this.message.success(success, { nzDuration: 6000 });

            return fromOperationAction.updateOperationMissionSuccess({
              success,
            });
          }),
          catchError((error) =>
            of(fromOperationAction.updateOperationMissionFailure({ error }))
          )
        )
      )
    )
  );

  deleteMissionOperation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationAction.deleteOperationMission),
      mergeMap((action) =>
        this.operationMission.deleteOne(action.operationId, action.id).pipe(
          map((success: any) => {
            this.message.success(success, { nzDuration: 6000 });
            return fromOperationAction.deleteOperationMissionSuccess({
              success,
            });
          }),
          catchError((error) =>
            of(fromOperationAction.deleteOperationMissionFailure({ error }))
          )
        )
      )
    )
  );

  //SHARING

  constructor(
    private actions$: Actions,
    private operationService: OperationService,
    private message: NzMessageService,
    private queryOperations: QueryOperationsService,
    private operationSuivis: OperationSuivisService,
    private operationStorage: OperationStorageService,
    private operationContactsService: OperationContactsService,
    private operationParticipantsService: OperationParticipantsService,
    private operationTeam: OperationTeamService,
    private router: Router,
    private operationExportPdf: OperationExportPdfService,
    private operationExportPpt: OperationExportPptService,
    private operationExportExcel: OperationExportExcelService,
    private operationMission: OperationMissionsService,
    private operationSharing: OperationSharingService,
    private notification: NzNotificationService
  ) {}

  onErrorNotification(error: any, key: string): void {
    this.notification.error('Error', error.message, {
      nzDuration: 10000,
      nzAnimate: true,
      nzPlacement: NOTIFICATION_PLACEMENT,
      nzKey: key ? key : '',
    });
  }
}
