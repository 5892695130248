import { Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  doc,
  docSnapshots,
  collectionChanges,
  getDoc,
  addDoc,
  setDoc,
  deleteDoc,
  serverTimestamp,
  DocumentData,
  Timestamp,
  query,
  where,
  arrayUnion,
  arrayRemove,
  collectionSnapshots,
  CollectionReference,
  DocumentReference,
  getDocs,
  documentId,
} from '@angular/fire/firestore';
import { Observable, of, from, EMPTY } from 'rxjs';
import { switchMap, mergeMap, map, catchError } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Functions, httpsCallable } from '@angular/fire/functions';
import {
  Storage,
  ref,
  uploadBytesResumable,
  deleteObject,
  getDownloadURL,
  StorageReference,
  UploadTask,
  UploadMetadata,
} from '@angular/fire/storage';
import { UserState } from '../components/user/store/user.reducer';
import * as fromUserSelector from '../components/user/store/user.selectors';
import { v4 as uuidV4 } from 'uuid';
import { format, fromUnixTime } from 'date-fns';
import { OperationNoteId } from '../components/operation-note/store/operation-note.model';
import { OperationId } from '../components/operation/store/operation.model';
import {
  OperationNoteSubtitle,
  OperationNoteSubtitleId,
} from '../components/operation-note-subtitle/store/operation-note-subtitle.model';
import { Apollo, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'any',
})
export class OperationNoteSubtitleService {
  DELETE_OPERATION_NOTE_SUBTITLES = gql`
    mutation DeleteAllOperationNoteSubtitles(
      $operationId: String!
      $noteId: String!
    ) {
      deleteAllOperationNoteSubtitles(
        operationId: $operationId
        noteId: $noteId
      )
    }
  `;

  constructor(
    private db: Firestore,
    private fns: Functions,
    private userStore: Store<UserState>,
    private apollo: Apollo
  ) {}

  getAll(note: OperationNoteId): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !note) of([]);
          const dbCollection = collection(
            this.db,
            `organisations/${user.organisation.id}/operations/${note.operation.id}/notes/${note.id}/subtitles`
          );

          return from(collectionSnapshots(dbCollection)).pipe(
            map((changes) => {
              return changes.map((snap) => {
                const data = snap.data();
                const id = snap.id;
                return { id, ...data };
              });
            })
          );
        })
      );
    } catch (error: any) {
      return of(error?.message);
    }
  }
  addOne(
    note: OperationNoteId,
    subtitle: OperationNoteSubtitle
  ): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !note || !subtitle) {
            return EMPTY;
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/operations/${note.operation.id}/notes/${note.id}/subtitles/`
            );

            const document: OperationNoteSubtitle = {
              ...subtitle,
              auteur: user,
              dateUpdate: serverTimestamp(),
            };

            return from(
              addDoc(dbCollection, document)
                .then((docRef) => {
                  if (!docRef.id) {
                    const succss: string = `Sous-titre n\'a pas été ajouté ! Veuillez rééssayer`;
                    return of(succss);
                  }

                  const succss: string = `Sous-titre ajouté`;
                  return of(succss);
                })
                .catch((error: any) => of(error?.message))
            ).pipe(mergeMap((res) => res));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  updateOne(
    note: OperationNoteId,
    subtitle: Partial<OperationNoteSubtitleId | any>
  ): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !note || !subtitle) {
            return EMPTY;
          } else {
            const dbDocument = doc(
              this.db,
              `organisations/${user.organisation.id}/operations/${note.operation.id}/notes/${note.id}/subtitles/${subtitle.id}`
            );

            return from(
              setDoc(
                dbDocument,
                {
                  ...subtitle,
                  userUpdate: user,
                },
                { merge: true }
              )
                .then(() => of(`Sous-titre modifié`))
                .catch((error: any) => of(error?.message))
            ).pipe(mergeMap((res) => res));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  deleteOne(note: OperationNoteId, id: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !id || !note) {
            return EMPTY;
          } else {
            const dbDocument = doc(
              this.db,
              `organisations/${user.organisation.id}/operations/${note.operation.id}/notes/${note.id}/subtitles/${id}`
            );

            return from(
              deleteDoc(dbDocument)
                .then(() => of(`Sous-titre supprimé`))
                .catch((error: any) => of(error?.message))
            ).pipe(mergeMap((res) => res));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  deleteAll(note: OperationNoteId): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !note) {
            return EMPTY;
          } else {
            const operationId: string = note.operation.id;
            const noteId: string = note.id;

            return this.apollo.mutate({
              mutation: this.DELETE_OPERATION_NOTE_SUBTITLES,
              variables: {
                operationId: operationId,
                noteId: noteId,
              },
            });
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }
}
