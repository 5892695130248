import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  MaxValidator,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';
import { UtilitiesState } from 'src/app/contents/components/utilities/store/utilities.reducer';
import * as fromUtilitiesSelector from 'src/app/contents/components/utilities/store/utilities.selectors';
import * as fromUtilitiesAction from 'src/app/contents/components/utilities/store/utilities.actions';
import { Timestamp } from 'firebase/firestore';

@Component({
  selector: 'app-participant-diagnostic-form-justice',
  templateUrl: './participant-diagnostic-form-justice.component.html',
  styleUrls: ['./participant-diagnostic-form-justice.component.scss'],
})
export class ParticipantDiagnosticFormJusticeComponent
  implements OnInit, OnChanges, OnDestroy
{
  judiciaire$: Observable<any> = of(null);
  subscribe = new Subject();

  //VARIABLES
  isFreinTitle: string =
    'La situation judiciaire est-elle un frein identifié ?';
  suiviSpipPjjTitlte: string = 'Suivi SPIP/PJJ après exécution de peine';
  executionDePeineEnCoursTitle: string = 'Exécution de peine en cours';
  casierJudiciaireTitle: string = 'Participant a-t-il un casier judiciaire ?';

  //FORM FIELD
  justiceForm: FormGroup = this.fb.group({});
  caseJudiciaire: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );
  suiviSpipPjj: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );
  executionDePeineEnCours: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );

  justice: FormControl<any> = new FormControl('', Validators.required);
  isFrein: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );

  //
  @Input() justice$ = new BehaviorSubject<any>(null);
  @Output() add = new EventEmitter<any>(false);

  constructor(
    private fb: FormBuilder,
    private utilitiesStore: Store<UtilitiesState>
  ) {}

  ngOnInit(): void {
    this.getJusticeReferentiel();
    this.loadJusticeReferentiel();
    this.form();
    this.onFormChanges();
  }
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getJusticeReferentiel(): void {
    this.judiciaire$ = this.utilitiesStore.select(
      fromUtilitiesSelector.judiciaire
    );
  }
  loadJusticeReferentiel(): void {
    this.utilitiesStore.dispatch(
      fromUtilitiesAction.loadJudiciaireReferentiel()
    );
  }

  form(): void {
    this.formInit();
    this.formCreate();
    this.formSet();
  }

  formInit(): void {
    this.caseJudiciaire = new FormControl<boolean>(false, Validators.required);
    this.justice = new FormControl<string>('');
    this.isFrein = new FormControl<boolean>(false, Validators.required);
    this.suiviSpipPjj = new FormControl(false, Validators.required);
    this.executionDePeineEnCours = new FormControl(false, Validators.required);
  }

  formCreate(): void {
    this.justiceForm = this.fb.group({
      caseJudiciaire: this.caseJudiciaire,
      suiviSpipPjj: this.suiviSpipPjj,
      executionDePeineEnCours: this.executionDePeineEnCours,
      justice: this.justice,
      isFrein: this.isFrein,
    });
  }

  formSet(): void {
    this.justice$.pipe(takeUntil(this.subscribe)).subscribe((justice) => {
      if (!justice) return;
      this.justiceForm.patchValue({ ...justice });
    });
  }

  checkboxChanges(event: boolean, type: string): void {
    switch (type) {
      case 'casier':
        this.justiceForm.patchValue({
          caseJudiciaire: event,
        });

        break;
      case 'execution':
        this.justiceForm.patchValue({
          executionDePeineEnCours: event,
        });

        break;
      case 'suiviSpipPjj':
        this.justiceForm.patchValue({
          suiviSpipPjj: event,
        });

        break;

      default:
        break;
    }
  }

  onFormChanges(): void {
    this.justiceForm.valueChanges
      .pipe(takeUntil(this.subscribe))
      .subscribe((values) => this.add.emit(values));
  }
}
