<ng-container *ngIf="module">
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="8">
      <app-header-title
        [icon]="'query_stats'"
        [title]="'Tableau de bord'"
        [subtitle]="true"
      ></app-header-title>
    </nz-col>
    <nz-col nzSpan="16"></nz-col>
  </nz-row>
  <app-item-tabset
    (select)="onSelectTab($event)"
    [tabs]="[
      {
        name: 'Module',
        index: 0,
        template: moduleStatistic
      },
      {
        name: 'Actions',
        index: 1,
        template: actionsStatistic
      },
      {
        name: 'Missions',
        index: 2,
        template: missionsStatistic
      },
      {
        name: 'Participants',
        index: 3,
        template: participantStatistic
      },
      {
        name: 'Entités',
        index: 4,
        template: entiteStatistic
      },
      {
        name: 'Collaborateurs',
        index: 5,
        template: collaborateursStatistic
      }
    ]"
  ></app-item-tabset>

  <ng-template #moduleStatistic>
    <h3 nz-typography>Module statistic</h3>
  </ng-template>
  <ng-template #actionsStatistic>
    <h3 nz-typography>Actions statistic</h3>
  </ng-template>
  <ng-template #missionsStatistic>
    <h3 nz-typography>Missions statistic</h3>
  </ng-template>
  <ng-template #participantStatistic>
    <h3 nz-typography>Participants statistic</h3>
  </ng-template>
  <ng-template #entiteStatistic>
    <h3 nz-typography>Entites statistic</h3>
  </ng-template>
  <ng-template #collaborateursStatistic>
    <h3 nz-typography>Collaboration statistic</h3>
  </ng-template>
</ng-container>
