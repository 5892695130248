import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { ParticipantId } from 'src/app/components/participant/store/participant.model';
import { ParticipantState } from 'src/app/components/participant/store/participant.reducer';
import * as fromParticipantSelector from 'src/app/components/participant/store/participant.selectors';

@Component({
  selector: 'app-participant-impacts',
  templateUrl: './participant-impacts.component.html',
  styleUrls: ['./participant-impacts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantImpactsComponent implements OnInit, OnChanges {
  participant$: Observable<ParticipantId | any> = of(null);
  @Output() select = new EventEmitter(false);

  constructor(private participantStore: Store<ParticipantState>) {}

  ngOnInit(): void {
    this.getParticipant();
  }
  ngOnChanges(changes: SimpleChanges): void {}
  getParticipant(): void {
    this.participant$ = this.participantStore.select(
      fromParticipantSelector.participant
    );
  }

  onSelect(type: string, participant: ParticipantId): void {
    this.select.emit(type);
  }
}
