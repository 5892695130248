import { Injectable } from '@angular/core';
import { CandidatureExport } from '../store/candidature.model';
import { writeFileXLSX, utils } from 'xlsx';
import * as XLSX from 'xlsx';
import { capitalize } from 'lodash';
import { fromUnixTime, format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Observable, of, EMPTY } from 'rxjs';

const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'any',
})
export class CandidatureExportExcelService {
  constructor() {}

  onExportCandidatsExcel(dataExport: CandidatureExport): Observable<any> {
    try {
      const { candidats, fiche, user, isAnonyme } = dataExport;

      if (!candidats.length || !fiche || !user) EMPTY;

      const { entite, mission, contacts, recherche } = fiche;
      const { metier, contrat, operation } = mission;

      const operationTitle: string = operation.denomination.substring(0, 10);
      const entiteTitle: string =
        entite?.uniteLegale?.denominationUniteLegale.substring(0, 10);

      const excelCandidatures: any[] = this.candidatsToSheet(
        candidats,
        isAnonyme
      );
      const excelRecherche: any[] = this.besoinToSheet(mission, recherche);

      const currentDate = format(new Date(Date.now()), 'dd/MM/yyyy', {
        locale: fr,
      });
      const fileName = `${operationTitle}_${entiteTitle}_${currentDate}_${EXCEL_EXTENSION}`;

      const worksheetCandidatures: XLSX.WorkSheet =
        utils.json_to_sheet(excelCandidatures);
      const worksheetBesoin: XLSX.WorkSheet =
        utils.json_to_sheet(excelRecherche);

      const workbook: XLSX.WorkBook = {
        Sheets: {
          Besoin: worksheetBesoin,
          Candidatures: worksheetCandidatures,
        },
        SheetNames: ['Besoin', 'Candidatures'],
      };

      writeFileXLSX(workbook, fileName, { compression: true });

      return of('Success');
    } catch (error) {
      return of(error);
    }
  }

  candidatsToSheet(candidats: any, isAnonyme: boolean): any {
    const exlData = candidats.map((candidat: any) => {
      const civilite = candidat?.person?.civilite || 'Non rensigné';
      const nom = candidat?.person?.lastName?.toUpperCase() || 'Non rensigné';
      const prenom = capitalize(candidat?.person?.firstName) || 'Non rensigné';
      const age = candidat?.person?.age || 'Non rensigné';
      const genre = candidat?.person?.genre || 'Non rensigné';
      const mobile = candidat?.person?.coordonnees?.mobile || 'Non rensigné';
      const fixe = candidat?.person?.coordonnees?.fixe || 'Non rensigné';
      const email = candidat?.person?.coordonnees?.email || 'Non rensigné';
      const distance =
        candidat?.person?.distance?.distanceKilometer?.toFixed(0) || 0;
      const prescripteur =
        candidat?.prescripteur?.etablissement?.uniteLegale
          ?.denominationUniteLegale || 'Non rensigné';
      const prescripteurCommune =
        candidat?.prescripteur?.etablissement?.adresseEtablissement?.commune
          ?.nom || 'Non rensigné';
      const referent = candidat?.prescripteur?.displayName || 'Non rensigné';
      const referentMobile =
        candidat?.prescripteur?.coordonnees?.mobile || 'Non rensigné';
      const referentEmail =
        candidat?.prescripteur?.coordonnees?.email || 'Non rensigné';
      const presentation =
        candidat?.person?.diagnostic?.administratif?.presentation ||
        'Non rensigné';

      // const criteres = i.criteres
      //   ? i.criteres.reduce((acc: any, cur: any) => {
      //       acc[cur.item] = 1;
      //       return acc;
      //     }, {})
      //   : {};

      const obj = {
        CIVILITÉ: civilite,
        NOM: !isAnonyme ? nom : 'Anonymisé',
        PRÉNOM: !isAnonyme ? prenom : 'Anonymisé',
        GENRE: genre,
        ÂGE: age,
        MOBILE: !isAnonyme ? mobile : 'Anonymisé',
        FIXE: !isAnonyme ? fixe : 'Anonymisé',
        'E-MAIL': !isAnonyme ? email : 'Anonymisé',
        ADRESSE: !isAnonyme ? '' : 'Anonymisé',
        'DISTANCE EN KM DU LIEU DE TRAVAIL': distance,
        PRESCRIPTEUR: prescripteur,
        'COMMUNE DU PRESCRIPTEUR': prescripteurCommune,
        RÉFÉRENT: referent,
        'RÉFÉRENT MOBILE': referentMobile,
        'RÉFÉRENT E-MAIL': referentEmail,
        PRÉSENTATION: presentation,
        COMMENTAIRE: '',
      };

      console.log('EXCEL EXPORT');

      return { ...obj };
    });

    return exlData;
  }

  besoinToSheet(mission: any, recherche: any): any {
    const { operation } = mission;
    const financeurs = operation?.financeurs?.length
      ? operation?.financeurs
          .map((el: any) => el?.uniteLegale?.denominationUniteLegale)
          .join(', ')
      : 'Non renseigné';

    const rowOperation: any[] = [
      {
        OPÉRATION: operation.denomination.toUpperCase(),
        "FINANCEUR / DONNEUR D'ORDRE": financeurs || 'Non renseigné',
        ADRESSE:
          mission?.adresse?.adresse?.properties?.label || 'Non renseigné',
        ENTRERPISE:
          mission?.entite?.etablissement?.uniteLegale?.denominationUniteLgale ||
          'Non renseigné',
        'ENTREPRISE SIRET':
          mission?.entite?.etablissement?.siret || 'Non renseigné',
        'ENTREPRISE ACTIVITÉ':
          mission?.entite?.etablissement?.activite_principale_libelle
            ?.intitule_naf || 'Non renseignée',
        RECCHERCHE: recherche?.participantsSize || 0,
        MÉTIER: `${mission?.metier?.libelle} - ROME : ${mission?.metier?.metier?.code}`,
        DOMAINE:
          mission?.metier?.metier?.domaineProfessionnel?.libelle ||
          'Non renseigné',
        'DOMAINE PROFESSIONNEL':
          mission?.metier?.metier?.domaineProfessionnel?.grandDomaine
            ?.libelle || 'Non renseigné',
        'NATURE DU CONTRAT':
          mission?.contrat?.nature?.libelle || 'Non renseigné',
        'TYPE DE CONTRAT': mission?.contrat?.type?.libelle || 'Non renseigné',
        'DATE DE DÉBUT DE MISSION':
          format(
            fromUnixTime(mission?.contrat?.startMission['seconds']),
            'dd/MM/yyyy',
            { locale: fr }
          ) || 'Non renseigné',
        'DATE DE FIN DE MISSION':
          format(
            fromUnixTime(mission?.contrat?.endMission['seconds']),
            'dd/MM/yyyy',
            { locale: fr }
          ) || 'Non renseigné',
      },
    ];

    return rowOperation;
  }
}
