import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ParticipantsSelect } from './participants-select.model';
import * as ParticipantsSelectActions from './participants-select.actions';
import { ParticipantSmallId } from 'src/app/components/participant/store/participant.model';

export const participantsSelectsFeatureKey = 'participantsSelects';

export interface ParticipantSelectState
  extends EntityState<ParticipantSmallId> {
  // additional entities state properties
  loading: boolean;
}

export const adapter: EntityAdapter<ParticipantSmallId> =
  createEntityAdapter<ParticipantSmallId>();

export const initialState: ParticipantSelectState = adapter.getInitialState({
  // additional entity state properties
  loading: false,
});

export const reducer = createReducer(
  initialState,
  on(ParticipantsSelectActions.queryParticipants, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(ParticipantsSelectActions.queryParticipantsSuccess, (state, action) =>
    adapter.setAll(action.participants, state)
  ),
  on(ParticipantsSelectActions.queryParticipantsFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
