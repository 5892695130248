<div
  class="contentParticipantSituation"
  *ngIf="participant$ | async as participant"
>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="12">
      <nz-card [nzTitle]="sortieTitle" [nzExtra]="extraSortiTpl">
        <ng-template #sortieTitle>
          <app-header-title
            [icon]="'timeline'"
            [title]="'Sortie'"
            [subtitle]="true"
          ></app-header-title>
        </ng-template>
        <ng-template #extraSortiTpl>
          <button
            nz-button
            nzShape="circle"
            nzType="text"
            (click)="$event.stopPropagation(); onSelect('sortie', participant)"
            nz-tooltip
            [nzTooltipMouseEnterDelay]="0.5"
            nzTooltipPlacement="bottomRight"
            nzTooltipTrigger="hover"
            nzTooltipTitle="Modifier"
          >
            <mat-icon class="btn_primary">edit</mat-icon>
          </button>
        </ng-template>
        <nz-descriptions
          nzSize="small"
          nzBordered
          [nzColumn]="{ xxl: 4, xl: 4, lg: 4, md: 0, sm: 0, xs: 0 }"
          *ngIf="participant?.sortiProgrammeType?.motif; else noData"
        >
          <nz-descriptions-item nzTitle="Motif de sortie" [nzSpan]="4">
            <div class="typeCardSortie">
              <strong
                *ngIf="
                  participant?.sortiInscription || participant?.sortiProgramme
                "
              >
                {{ participant?.sortiProgrammeType?.motif }}
              </strong>
            </div>
          </nz-descriptions-item>

          <nz-descriptions-item nzTitle="Date" [nzSpan]="4">
            <nz-tag nzColor="geekblue" *ngIf="participant?.dateEnd">
              {{ participant?.dateEnd | dateFr : "small" }}
            </nz-tag>
          </nz-descriptions-item>

          <nz-descriptions-item nzTitle="Commentaire" [nzSpan]="4">
            <div class="contentParticipant">
              <p nz-typography *ngIf="participant?.sortiProgrammeQualitatif">
                {{ participant?.sortiProgrammeQualitatif }}
              </p>
            </div>
          </nz-descriptions-item>
        </nz-descriptions>
      </nz-card>
    </nz-col>
    <nz-col nzSpan="12">
      <nz-card [nzTitle]="impact3Title" [nzExtra]="impact3SortiTpl">
        <ng-template #impact3Title>
          <app-header-title
            [icon]="'verified_user'"
            [title]="'Impact 3 mois'"
            [subtitle]="true"
          ></app-header-title>
        </ng-template>
        <ng-template #impact3SortiTpl>
          <button
            nz-button
            nzShape="circle"
            nzType="text"
            (click)="$event.stopPropagation(); onSelect('impact3', participant)"
            nz-tooltip
            [nzTooltipMouseEnterDelay]="0.5"
            nzTooltipPlacement="bottomRight"
            nzTooltipTrigger="hover"
            nzTooltipTitle="Modifier"
          >
            <mat-icon class="btn_primary">edit</mat-icon>
          </button>
        </ng-template>

        <nz-descriptions
          nzSize="small"
          nzBordered
          [nzColumn]="{ xxl: 4, xl: 4, lg: 4, md: 0, sm: 0, xs: 0 }"
          *ngIf="participant?.impact3mois?.type?.motif; else noDataImpact"
        >
          <nz-descriptions-item [nzSpan]="4" nzTitle="Type de situation">
            <div class="typeCardSortie">
              <strong *ngIf="participant?.impact3mois?.type?.motif">
                {{ participant?.impact3mois?.type?.motif }}
              </strong>
            </div>
          </nz-descriptions-item>

          <nz-descriptions-item nzTitle="Date" [nzSpan]="4">
            <div class="typeCardDateSortie">
              <nz-tag nzColor="geekblue" *ngIf="participant?.impact3mois?.date">
                {{ participant?.impact3mois?.date | dateFr : "small" }}
              </nz-tag>
            </div>
          </nz-descriptions-item>

          <nz-descriptions-item nzTitle="Commentaire" [nzSpan]="4">
            <div class="contentParticipant">
              <p nz-typography *ngIf="participant?.impact3mois?.info">
                {{ participant?.impact3mois?.info }}
              </p>
            </div>
          </nz-descriptions-item>
        </nz-descriptions>
      </nz-card>
    </nz-col>
  </nz-row>
  <nz-space [nzSize]="100"></nz-space>

  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="12">
      <nz-card [nzTitle]="impact6Title" [nzExtra]="impact6SortiTpl">
        <ng-template #impact6Title>
          <app-header-title
            [icon]="'verified_user'"
            [title]="'Impact 6 mois'"
            [subtitle]="true"
          ></app-header-title>
        </ng-template>
        <ng-template #impact6SortiTpl>
          <button
            nz-button
            nzShape="circle"
            nzType="text"
            (click)="$event.stopPropagation(); onSelect('impact6', participant)"
            nz-tooltip
            [nzTooltipMouseEnterDelay]="0.5"
            nzTooltipPlacement="bottomRight"
            nzTooltipTrigger="hover"
            nzTooltipTitle="Modifier"
          >
            <mat-icon class="btn_primary">edit</mat-icon>
          </button>
        </ng-template>

        <nz-descriptions
          nzSize="small"
          nzBordered
          [nzColumn]="{ xxl: 4, xl: 4, lg: 4, md: 0, sm: 0, xs: 0 }"
          *ngIf="participant?.impact6mois?.type?.motif; else noDataImpact"
        >
          <nz-descriptions-item [nzSpan]="4" nzTitle="Type de situation">
            <div class="typeCardSortie">
              <strong *ngIf="participant?.impact6mois?.type?.motif">
                {{ participant?.impact6mois?.type?.motif }}
              </strong>
            </div>
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="Date" [nzSpan]="4">
            <div class="typeCardDateSortie">
              <nz-tag nzColor="geekblue" *ngIf="participant?.impact6mois?.date">
                {{ participant?.impact6mois?.date | dateFr : "small" }}
              </nz-tag>
            </div>
          </nz-descriptions-item>

          <nz-descriptions-item nzTitle="Commentaire" [nzSpan]="4">
            <div class="contentParticipant">
              <p nz-typography *ngIf="participant?.impact6mois?.info">
                {{ participant?.impact6mois?.info }}
              </p>
            </div>
          </nz-descriptions-item>
        </nz-descriptions>
      </nz-card>
    </nz-col>
    <nz-col nzSpan="12">
      <nz-card [nzTitle]="impact12Title" [nzExtra]="impact12SortiTpl">
        <ng-template #impact12Title>
          <app-header-title
            [icon]="'verified_user'"
            [title]="'Impact 12 mois'"
            [subtitle]="true"
          ></app-header-title>
        </ng-template>
        <ng-template #impact12SortiTpl>
          <button
            nz-button
            nzShape="circle"
            nzType="text"
            (click)="
              $event.stopPropagation(); onSelect('impact12', participant)
            "
            nz-tooltip
            [nzTooltipMouseEnterDelay]="0.5"
            nzTooltipPlacement="bottomRight"
            nzTooltipTrigger="hover"
            nzTooltipTitle="Modifier"
          >
            <mat-icon class="btn_primary">edit</mat-icon>
          </button>
        </ng-template>

        <nz-descriptions
          nzSize="small"
          nzBordered
          [nzColumn]="{ xxl: 4, xl: 4, lg: 4, md: 0, sm: 0, xs: 0 }"
          *ngIf="participant?.impact12mois?.type?.motif; else noDataImpact"
        >
          <nz-descriptions-item [nzSpan]="4" nzTitle="Type de situation">
            <div class="typeCardSortie">
              <strong *ngIf="participant?.impact12mois?.type?.motif">
                {{ participant?.impact12mois?.type?.motif }}
              </strong>
            </div>
          </nz-descriptions-item>

          <nz-descriptions-item nzTitle="Date" [nzSpan]="4">
            <div class="typeCardDateSortie">
              <nz-tag
                nzColor="geekblue"
                *ngIf="participant?.impact12mois?.date"
              >
                {{ participant?.impact12mois?.date | dateFr : "small" }}
              </nz-tag>
            </div>
          </nz-descriptions-item>

          <nz-descriptions-item nzTitle="Commentaire" [nzSpan]="4">
            <div class="contentParticipant">
              <p nz-typography *ngIf="participant?.impact12mois?.info">
                {{ participant?.impact12mois?.info }}
              </p>
            </div>
          </nz-descriptions-item>
        </nz-descriptions>
      </nz-card>
    </nz-col>
  </nz-row>

  <ng-template #noData>
    <app-no-result
      [icon]="''"
      [notitle]="'Aucune sortie renseignée'"
      [isButton]="false"
    ></app-no-result>
  </ng-template>

  <ng-template #noDataImpact>
    <app-no-result
      [icon]="''"
      [notitle]="'Aucun impact renseigné'"
      [isButton]="false"
    ></app-no-result>
  </ng-template>
</div>
