import { DepartementId } from './../components/departement/store/departement.model';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { DepartementState } from '../components/departement/store/departement.reducer';
import * as fromDepartementAction from '../components/departement/store/departement.actions';
import * as fromDepartementSelector from '../components/departement/store/departement.selectors';

import { Store, select } from '@ngrx/store';
import { EMPTY, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'any',
})
export class DepartementResolverService
  implements Resolve<DepartementId | any>
{
  constructor(
    private departementStore: Store<DepartementState>,
    private router: Router
  ) {}

  resolve(
    route: ActivatedRouteSnapshot
  ):
    | Observable<DepartementId>
    | Promise<DepartementId | any>
    | DepartementId
    | any {
    let id: string = route.params['id'];
    let find = this.loadDepartement(id).toPromise();

    return find
      .then(() => {
        return this.getDepartement();
      })
      .catch(() => {
        this.router.navigate(['/services']);
        return EMPTY;
      });
  }

  getDepartement(): Observable<DepartementId | any> {
    return this.departementStore.select(fromDepartementSelector.departement);
  }

  loadDepartement(id: string): Observable<any> {
    return of(
      this.departementStore.dispatch(
        fromDepartementAction.loadDepartement({ id })
      )
    );
  }
}
