import { ContentsModule } from './../../contents/contents.module';
import { SharedModule } from './../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MissionsListComponent } from './components/missions-list/missions-list.component';
import { MissionsItemComponent } from './components/missions-item/missions-item.component';
import { MissionsCardComponent } from './components/missions-card/missions-card.component';
import { MissionsFormComponent } from './components/missions-form/missions-form.component';
import { MissionsFiltersComponent } from './components/missions-filters/missions-filters.component';
import { StoreModule } from '@ngrx/store';
import * as fromMission from './store/mission.reducer';
import { EffectsModule } from '@ngrx/effects';
import { MissionEffects } from './store/mission.effects';
import { MissionsHoursComponent } from './components/missions-hours/missions-hours.component';
import { MissionsDetailsComponent } from './components/missions-details/missions-details.component';
import { MissionExportPdfService } from 'src/app/service/mission-export-pdf.service';
import { ContratModule } from 'src/app/contents/components/contrat/contrat.module';
import { MissionsEntiteFormComponent } from './components/missions-entite-form/missions-entite-form.component';
import { MissionsFormPosteComponent } from './components/missions-form-poste/missions-form-poste.component';
import { PipeModule } from 'src/app/pipe/pipe.module';
import { NoResultModule } from 'src/app/contents/components/no-result/no-result.module';
import { CoordonneesModule } from 'src/app/contents/components/coordonnees/coordonnees.module';
import { CommunesFranceModule } from 'src/app/contents/components/communes-france/communes-france.module';
import { ButtonsModule } from 'src/app/contents/components/buttons/buttons.module';
import { MetiersModule } from 'src/app/features/metiers/metiers.module';
import { HeaderModule } from 'src/app/contents/components/header-title/header.module';
import { MissionsFormStepsComponent } from './components/missions-form-steps/missions-form-steps.component';
import { MissionsFormFooterComponent } from './components/missions-form-footer/missions-form-footer.component';
import { MissionsFormOperationComponent } from './components/missions-form-operation/missions-form-operation.component';
import { EntiteSearchModule } from 'src/app/contents/components/entite-search/entite-search.module';
import { MissionsFormItemComponent } from './components/missions-form-item/missions-form-item.component';
import { LayoutModule } from 'src/app/contents/components/layout/layout.module';
import { AvatarModule } from 'src/app/features/avatar/avatar.module';
import { SuiviModule } from 'src/app/features/suivi/suivi.module';
import { DocumentModule } from 'src/app/features/document/document.module';
import { UiModule } from 'src/app/ui/ui/ui.module';

@NgModule({
  declarations: [
    MissionsListComponent,
    MissionsItemComponent,
    MissionsCardComponent,
    MissionsFormComponent,
    MissionsFiltersComponent,
    MissionsHoursComponent,
    MissionsDetailsComponent,
    MissionsEntiteFormComponent,
    MissionsFormPosteComponent,
    MissionsFormStepsComponent,
    MissionsFormFooterComponent,
    MissionsFormOperationComponent,
    MissionsFormItemComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ContentsModule,
    ContratModule,
    PipeModule,
    NoResultModule,
    CoordonneesModule,
    HeaderModule,
    CommunesFranceModule,
    ButtonsModule,
    MetiersModule,
    EntiteSearchModule,
    LayoutModule,
    AvatarModule,
    SuiviModule,
    DocumentModule,
    UiModule,
    StoreModule.forFeature(fromMission.missionsFeatureKey, fromMission.reducer),
    EffectsModule.forFeature([MissionEffects]),
  ],
  exports: [
    MissionsListComponent,
    MissionsItemComponent,
    MissionsCardComponent,
    MissionsFormComponent,
    MissionsFiltersComponent,
    MissionsFormItemComponent,
  ],
  providers: [MissionExportPdfService],
})
export class MissionsModule {}
