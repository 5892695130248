import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-adresse-item',
  templateUrl: './adresse-item.component.html',
  styleUrls: ['./adresse-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdresseItemComponent implements OnInit, OnChanges {
  @Input() adresse: any;
  constructor() {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {}
}
