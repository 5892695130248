import {
  CdkDragDrop,
  CdkDragEnter,
  CdkDragExit,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Update } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BehaviorSubject, filter, map, Observable, of, Subject } from 'rxjs';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';
import { TaskState } from '../../store/task.reducer';
import * as fromTaskSelector from '../../store/task.selectors';
import { TaskFormComponent } from '../task-form/task-form.component';
import { toPairs } from 'lodash';
import {
  fromUnixTime,
  isThisMonth,
  isThisWeek,
  isThisYear,
  isToday,
} from 'date-fns';

@Component({
  selector: 'app-task-kanban',
  templateUrl: './task-kanban.component.html',
  styleUrls: ['./task-kanban.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskKanbanComponent implements OnInit, OnChanges, OnDestroy {
  filters$: Observable<string[] | any> = of(null);
  subscribe = new Subject();

  visibilityFormModal: boolean = false;
  viewMyTodos: boolean = false;
  formModalActivity = new BehaviorSubject<boolean>(false);
  filtersActicity: boolean = false;
  filterTitle: string = '';

  title: string = 'Nouvelle tâche';

  @ViewChild('taskFormTitle', { static: false })
  moduleFormTitle: TemplateRef<any> | any;
  modalWidth: number = 780;

  @Input() user$: Observable<any> = of(null);
  @Input() tasks$: Observable<any> = of([]);
  @Input() taksAll$: Observable<any> = of([]);
  @Input() tasksInProgress$: Observable<any> = of([]);
  @Input() verifed$: Observable<any> = of([]);
  @Input() dones$: Observable<any> = of([]);
  @Input() loading$: Observable<boolean> = of(false);
  @Input() isFiltered$: Observable<boolean> = of(false);
  @Input() userSelectorRef: TemplateRef<any> | any = null;

  @Output() back = new EventEmitter(false);
  @Output() action = new EventEmitter(false);
  @Output() update = new EventEmitter(false);
  @Output() add = new EventEmitter(false);
  @Output() delete = new EventEmitter(false);
  @Output() deleteAll = new EventEmitter(false);

  constructor(
    private taskStore: Store<TaskState>,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private userStore: Store<UserState>
  ) {}

  ngOnInit(): void {
    this.getUser();
    this.getFilters();
  }
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getUser(): void {
    this.user$ = this.userStore.select(fromUserSelector.user);
  }

  trackBy(index: number, item: any): any {
    return item.id;
  }

  onBack(): void {
    this.back.emit(true);
  }

  onNew(): void {
    this.formModalActivity.next(true);
    this.title = 'Nouvelle tâche';
    const modal = this.modal.create({
      nzContent: TaskFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.moduleFormTitle,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });
    const instance = modal.getContentComponent();

    instance.cancel.subscribe((event: boolean): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
    instance.add.subscribe((task: any): any => {
      if (!task) {
        return null;
      } else {
        this.add.emit(task);
      }
    });
  }

  getFilters(): void {
    this.filters$ = this.taskStore.select(fromTaskSelector.filters);
  }

  onFilterAction(event: string): void {
    if (!event) return;
    this.action.emit(event);
  }

  closeForm__TODO(): void {
    this.visibilityFormModal = false;
    this.formModalActivity.next(false);
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );

      this.updateTask(
        event.previousContainer.id,
        event.container.id,
        event.container.data[0]
      );
    }
  }

  entered(event: CdkDragEnter<string[]> | any) {}

  exited(event: CdkDragExit<string[]> | any) {}

  updateTask(prevListId: string, currentListId: string, task: any): void {
    if (!task) return;

    let updateType: string = this.getListById(currentListId);

    switch (updateType) {
      case 'todo':
        var textStatusInit: any[] = task.texts.map((el: any) => ({
          value: el.value,
          status: false,
          id: el.id,
        }));

        var nextTask: Update<any> = {
          id: task.id,
          changes: {
            ...task,
            texts: textStatusInit,
            completed: false,
            tested: false,
            verifed: false,
            inprogress: false,
          },
        };

        this.update.emit({ task: nextTask, type: updateType });

        break;
      case 'inprogress':
        var nextTask: Update<any> = {
          id: task.id,
          changes: {
            ...task,
            completed: false,
            tested: false,
            verifed: false,
            inprogress: true,
          },
        };

        this.update.emit({ task: nextTask, type: updateType });

        break;
      case 'verifed':
        var nextTask: Update<any> = {
          id: task.id,
          changes: {
            ...task,
            completed: false,
            tested: true,
            verifed: true,
            inprogress: true,
          },
        };

        this.update.emit({ task: nextTask, type: updateType });

        break;
      case 'done':
        var textStatusInit: any[] = task.texts.map((el: any) => ({
          value: el.value,
          status: true,
          id: el.id,
        }));

        var nextTask: Update<any> = {
          id: task.id,
          changes: {
            ...task,
            texts: textStatusInit,
            completed: true,
            tested: true,
            verifed: true,
            inprogress: true,
          },
        };

        this.update.emit({ task: nextTask, type: updateType });

        break;

      default:
        break;
    }
  }
  onUpdate(event: any): void {
    if (!event) return;
    const { task, type } = event;
    this.update.emit({ task, type });
  }
  deleteTask(task: any): void {
    if (!task) return;
    this.delete.emit(task);
  }
  deleteTasks(): void {
    this.deleteAll.emit(true);
  }

  getListById(listId: string): string {
    switch (listId) {
      case 'cdk-drop-list-0':
        return 'todo';
      case 'cdk-drop-list-1':
        return 'inprogress';
      case 'cdk-drop-list-2':
        return 'verifed';
      case 'cdk-drop-list-3':
        return 'done';
      default:
        return '';
        break;
    }
  }

  clearFilter(): void {
    this.viewMyTodos = false;
    this.filtersActicity = false;
  }
}
