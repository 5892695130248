<nz-card nzHoverable [nzTitle]="cardTtitle" [nzExtra]="cardModuleExtra">
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="24">
      <strong nz-typography>
        {{ action?.title | uppercase | carLimitation: 20 }}
      </strong>
    </nz-col>
  </nz-row>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="24">
      <nz-tag nzColor="geekblue">
        {{ action?.module?.title | carLimitation: 20 }}
      </nz-tag>
    </nz-col>
  </nz-row>
  <nz-space></nz-space>

  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="2">
      <mat-icon>ssid_chart</mat-icon>
    </nz-col>
    <nz-col nzSpan="2"></nz-col>
    <nz-col nzSpan="20">
      <nz-progress
        *ngIf="action?.progressionHours < 1"
        [nzPercent]="action?.progressionHours * 100"
        [nzStrokeColor]="progressDefaultColor"
        [nzShowInfo]="true"
      ></nz-progress>
      <nz-progress
        *ngIf="action?.progressionHours >= 1"
        [nzPercent]="action?.progressionHours * 100"
        [nzPercent]="100"
        [nzStrokeColor]="progressCompleteColor"
        [nzShowInfo]="true"
      ></nz-progress>
    </nz-col>
  </nz-row>

  <nz-row
    nzAlign="middle"
    nzGutter="8"
    nzJustify="start"
    class="mission_card_title"
  >
    <nz-col nzSpan="4">
      <mat-icon>domain</mat-icon>
    </nz-col>
    <nz-col nzSpan="20">
      <nz-tag [nzColor]="action?.entiteLevel1?.[0]? 'green' : 'volcano'">
        {{
          action?.entiteLevel1?.[0]? 
          (action?.entiteLevel1?.[0]?.etablissement?.denomination | carLimitation: 20) :
          'Aucune entité'
        }}
      </nz-tag>
    </nz-col>
  </nz-row>
  <nz-row nzJustify="start" nzGutter="8" nzAlign="middle">
    <nz-col nzSpan="4">
      <mat-icon class="module_progress_timer">date_range</mat-icon>
    </nz-col>
    <nz-col nzSpan="20">
      <small *ngIf="action?.progressionHours < 1">{{
        action?.dateEnd | dateFr: "distanceSuffix" | carLimitation: 25
      }}</small>
      <small *ngIf="action?.progressionHours >= 1">
        {{ action?.dateEnd | dateFr: "small" }}
      </small>
    </nz-col>
  </nz-row>
</nz-card>

<ng-template #cardTtitle>
  <app-header-title
    [icon]="'dashboard'"
    [subtitle]="true"
    [height]="'35px'"
    [width]="'35px'"
    [subtitle]="false"
  ></app-header-title>
</ng-template>

<ng-template #cardModuleExtra>
  <app-button-dropdown
    [isDelete]="true"
    [deleteType]="'Voulez-vous supprimer ' + action.title + ' ?'"
    (click)="$event.stopPropagation()"
    (select)="onSelectDropdown($event, action)"
  ></app-button-dropdown>
</ng-template>
