import { PortalNavigationService } from './../../../../portals/portal-navigation.service';
import { DashboardFiltersComponent } from './../dashboard-filters/dashboard-filters.component';
import { ComponentPortal } from '@angular/cdk/portal';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { DashboardState } from '../../store/dashboard.reducer';
import * as fromDashboardAction from '../../store/dashboard.actions';
import * as fromDashboardSelector from '../../store/dashboard.selectors';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  dashboardComponentPortalFilter:
    | ComponentPortal<DashboardFiltersComponent>
    | any;

  selected$: Observable<string | any> = of('projets');
  notitle: string = 'Aucune statistique disponible';
  title: string = '';
  total: number = 0;
  years$: Observable<string[] | any> = of(null);
  dashboardProjet$: Observable<any> = of(null);
  dashboardEntite$: Observable<any> = of(null);
  dashboardContact$: Observable<any> = of(null);
  dashboardParticipant$: Observable<any> = of(null);

  constructor(
    private portalNavigationService: PortalNavigationService,
    private dashboardStore: Store<DashboardState>
  ) {}

  ngOnInit(): void {
    this.dashboardComponentPortalFilter = new ComponentPortal(
      DashboardFiltersComponent
    );
    this.setFilterPortal();
    this.getCurrentSelector();
    this.getCurrentDashboard();
  }
  ngOnDestroy(): void {
    this.dashboardComponentPortalFilter.detach();
  }

  getCurrentDashboard(): void {
    this.dashboardEntite$ = this.dashboardStore.select(
      fromDashboardSelector.entites
    );
    this.dashboardContact$ = this.dashboardStore.select(
      fromDashboardSelector.contacts
    );
    this.dashboardProjet$ = this.dashboardStore.select(
      fromDashboardSelector.projets
    );
    this.dashboardParticipant$ = this.dashboardStore.select(
      fromDashboardSelector.participants
    );
  }

  getCurrentSelector(): void {
    this.selected$ = this.dashboardStore.select(fromDashboardSelector.select);
  }

  setFilterPortal(): void {
    this.portalNavigationService.setPortal(this.dashboardComponentPortalFilter);
  }

  setTitle(title: string): void {
    this.title = title;
  }

  setTotal(total: number): void {
    this.total = total;
  }
}
