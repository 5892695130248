import { Injectable } from '@angular/core';
import { Firestore, doc, getDoc } from '@angular/fire/firestore';
import { Observable, of, from, EMPTY } from 'rxjs';
import { switchMap, mergeMap, map, catchError } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { UserState } from '../components/user/store/user.reducer';
import * as fromUserSelector from '../components/user/store/user.selectors';
import {
  toUpper,
  upperFirst,
  capitalize,
  forEach,
  camelCase,
  isUndefined,
  sum,
  compact,
  groupBy,
  isNumber,
} from 'lodash';
import {
  fromUnixTime,
  format,
  formatDistance,
  formatDistanceToNow,
  formatDistanceStrict,
  differenceInYears,
  differenceInHours,
  sub,
  eachYearOfInterval,
} from 'date-fns';
import { fr } from 'date-fns/locale';
import { OperationState } from '../components/operation/store/operation.reducer';
import * as fromOperationAction from '../components/operation/store/operation.actions';
import * as fromOperationSelector from '../components/operation/store/operation.selectors';
import { OperationId } from '../components/operation/store/operation.model';
import { NzMessageService } from 'ng-zorro-antd/message';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

declare var require: any;

const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
const WORD_TYPE =
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
const WORD_EXTENSION = '.docx';
const PDF_EXTENSION = '.pdf';

@Injectable({
  providedIn: 'any',
})
export class OperationExportExcelService {
  constructor(
    private db: Firestore,
    private userStore: Store<UserState>,
    private operationStore: Store<OperationState>,
    private message: NzMessageService
  ) {}

  exportEXCEL(operation: OperationId): Observable<any> {
    try {
      return this.userStore.pipe(
        select(fromUserSelector.user),
        switchMap((user) => {
          if (!user || !operation) {
            return `Erreur d'exportation. Veuillez renouveller l'action`;
          }

          const dbDocument = doc(
            this.db,
            `organisations/${user.organisation.id}/operations/${operation.id}/statistiques/${operation.id}`
          );

          const statistiquesDoc = getDoc(dbDocument).then((snap) =>
            snap.data()
          );

          return from(
            statistiquesDoc
              .then((statistiques: any) => {
                if (!statistiques) return `Aucune statistique trouvée`;

                const participants = statistiques?.participants?.data?.length
                  ? statistiques?.participants?.data
                  : [];
                const excelParticipants =
                  this.participantsToSheet(participants);
                const entites = statistiques?.entites?.data?.length
                  ? statistiques?.entites?.data
                  : [];
                const excelEntites = this.entitesToSheet(entites);
                const contacts = statistiques?.contacts?.data?.length
                  ? statistiques?.contacts?.data
                  : [];
                const excelContacts = this.contactsToSheet(contacts);

                const modules = statistiques?.modules?.data?.length
                  ? statistiques?.modules?.data
                  : [];
                const excelModules = this.modulesToSheet(modules);
                const actions = statistiques?.actions?.data?.length
                  ? statistiques?.actions?.data
                  : [];
                const excelActions = this.actionsToSheet(actions);
                const missions = statistiques?.missions?.data?.length
                  ? statistiques?.missions?.data
                  : [];

                const date = format(new Date(Date.now()), 'dd/MM/yyyy', {
                  locale: fr,
                });
                const fileName = `projet_${operation.denomination}_${date}_${EXCEL_EXTENSION}`;

                const worksheetParticipants: XLSX.WorkSheet =
                  XLSX.utils.json_to_sheet(excelParticipants);
                const worksheetEntites: XLSX.WorkSheet =
                  XLSX.utils.json_to_sheet(excelEntites);
                const worksheetContacts: XLSX.WorkSheet =
                  XLSX.utils.json_to_sheet(excelContacts);
                const worksheetModules: XLSX.WorkSheet =
                  XLSX.utils.json_to_sheet(excelModules);
                const worksheetActions: XLSX.WorkSheet =
                  XLSX.utils.json_to_sheet(excelActions);
                const worksheetMissions: XLSX.WorkSheet =
                  XLSX.utils.json_to_sheet(missions);

                const workbook: XLSX.WorkBook = {
                  Sheets: {
                    Entités: worksheetEntites,
                    Collaborateurs: worksheetContacts,
                    Participants: worksheetParticipants,
                    Modules: worksheetModules,
                    Actions: worksheetActions,
                    Missions: worksheetMissions,
                  },
                  SheetNames: [
                    'Entités',
                    'Collaborateurs',
                    'Participants',
                    'Modules',
                    'Actions',
                    'Missions',
                  ],
                };
                const excelBuffer: any = XLSX.write(workbook, {
                  bookType: 'xlsx',
                  type: 'array',
                });

                const data: Blob = new Blob([excelBuffer], {
                  type: EXCEL_TYPE,
                });
                FileSaver.saveAs(data, `${fileName}`);
                return 'Votre bilan est téléchargé';
              })
              .catch((error) => `${error?.message}`)
          );
        })
      );
    } catch (err) {
      return of(err);
    }
  }

  participantsToSheet(participants: any): any {
    if (!participants) return null;

    const exlData = participants.map((i: any) => {
      const nom = i.lastName ? i.lastName.toUpperCase() : 'Non rensigné';
      const prenom = i.firstName ? capitalize(i.firstName) : 'Non rensigné';
      const age = i.age ? i.age : 'Non rensigné';
      const naissance = 'Non rensigné';
      const sexe = i.sexe ? i.sexe : 'Non rensigné';
      const rqth = i.rqth && i.rqth === true ? 'OUI' : 'NON';
      const minimaSociaux = i.minimaSociaux ? i.minimaSociaux : 'Non rensigné';

      const commune =
        i.informations?.adresse?.adresse &&
        i.informations?.adresse?.adresse?.properties
          ? i.informations?.adresse?.adresse?.properties?.city
          : '';
      const codePostal =
        i.informations?.adresse?.adresse &&
        i.informations?.adresse?.adresse?.properties
          ? i.informations?.adresse?.adresse?.properties?.postcode
          : '';
      const communication = i.communication ? i.communication : '';
      const criteres = i.criteres
        ? i.criteres.reduce((acc: any, cur: any) => {
            acc[cur.item] = 1;
            return acc;
          }, {})
        : {};
      const referents = i.referents?.length
        ? i.referents.reduce((acc: any, cur: any) => {
            acc[cur.dispayName] = 1;
            return acc;
          }, {})
        : {};
      const dateInscription = 'Non renseigné';
      const dateIntegration = 'Non renseigné';
      const dateSortie = 'Non renseigné';

      const dateEntree = 'Non renseigné';

      const desinscriptions = i.sortiInscription ? 'OUI' : 'NON';

      const sorties = i.sortiProgramme ? 'OUI' : 'NON';

      const motifDeSortie = i?.sortiProgrammeType?.motif
        ? i?.sortiProgrammeType?.motif
        : 'Non renseigné';

      const typeDeSortie = i?.sortiProgrammeType?.type
        ? i?.sortiProgrammeType?.type
        : 'Non renseigné';

      const sante = i?.situationPersonnelle?.sante
        ? i?.situationPersonnelle?.sante
        : 'Non renseigné';

      const logement = i?.situationPersonnelle?.logement
        ? i?.situationPersonnelle?.logement
        : 'Non renseigné';

      const disposeMobile = i?.situationPersonnelle?.mobile
        ? i?.situationPersonnelle?.mobile
        : 'Non renseigné';

      const internet = i?.situationPersonnelle?.internet
        ? i?.situationPersonnelle?.internet
        : 'Non renseigné';

      const ordinateur = i?.situationPersonnelle?.ordinateur
        ? i?.situationPersonnelle?.ordinateur
        : 'Non renseigné';

      const mobility = i?.situationPersonnelle?.mobilite?.item
        ? i?.situationPersonnelle?.mobilite?.item
        : 'Non renseigné';

      const numerique = i?.situationPersonnelle?.numerique?.length
        ? i?.situationPersonnelle?.numerique?.reduce((acc: any, cur: any) => {
            acc[cur.item] = 1;
            return acc;
          }, {})
        : {};

      const impact3 = i?.impact3mois?.type?.motif
        ? i?.impact3mois?.type?.motif
        : 'Non renseigné';

      const impact6 = i?.impact6mois?.type?.motif
        ? i?.impact6mois?.type?.motif
        : 'Non renseigné';

      const impact12 = i?.impact12mois?.type?.motif
        ? i?.impact12mois?.type?.motif
        : 'Non renseigné';

      const nationality = i?.nationality?.nationalite
        ? i?.nationality?.nationalite
        : 'Non renseigné';

      const niveauFrancais = i?.maitriseFrancais?.item
        ? `${i?.maitriseFrancais?.item} - ${i?.maitriseFrancais?.type}`
        : 'Non renseigné';

      const qualificationTitre = i?.qualification?.titre
        ? i?.qualification?.titre
        : 'Non renseigné';

      const qualificationNiveau = i?.qualification?.niveau
        ? i?.qualification?.niveau
        : 'Non renseigné';

      const qualificationApreBas = i?.qualification?.apresBac
        ? i?.qualification?.apresBac
        : 'Non renseigné';

      const droitTravail = i?.droitDeTravail ? 'Oui' : 'Non';

      const carteDeSejourType = i?.typeCarteSejour?.type
        ? i?.typeCarteSejour?.type
        : 'Non renseigné';

      const carteDeSejourTitre = i?.typeCarteSejour?.titre
        ? i?.typeCarteSejour?.titre
        : 'Non renseigné';

      const obj = {
        Nom: nom,
        Prénom: prenom,
        Genre: sexe,
        Âge: age,
        'Date de naissance': naissance,
        Qualification: qualificationTitre,
        'Niveau de qualification': qualificationNiveau,
        'Niveau après BAC': qualificationApreBas,
        'Droit de travail': droitTravail,
        Nationalité: nationality,
        'Niveau de français': niveauFrancais,
        RQTH: rqth,
        Mobilité: mobility,
        Santé: sante,
        Commune: commune,
        'Code postal': codePostal,
        Communication: communication,
        'Minima sociaux': minimaSociaux,
        "Date d'inscription": dateInscription,
        "Date d'intégration": dateIntegration,
        'Date de sortie': dateSortie,
        'Titre de carte de séjour': carteDeSejourTitre,
        'Type de carte de séjour': carteDeSejourType,
        "Date d'entrée sur le territoire": dateEntree,
        'Référents.es': referents,
        'Motif de sortie': motifDeSortie,
        'Type de sortie': typeDeSortie,
        Désinscription: desinscriptions,
        "Dispose d'un logement": logement,
        "Dispose d'un mobile": disposeMobile,
        "Dispose d'un accès internet": internet,
        "Dispose d'un ordinateur": ordinateur,
        Sortie: sorties,
        'Impact 3 mois': impact3,
        'Impact 6 mois': impact6,
        'Impact 12 mois': impact12,
      };
      return { ...obj, ...numerique, ...criteres };
    });

    return exlData;
  }

  modulesToSheet(module: any): any {
    if (!module) return null;

    const exlData = module.map((i: any) => {
      const title = i?.titre ? i.titre.toUpperCase() : null;
      const type = i?.type ? capitalize(i.type) : null;
      const statut = i?.statut ? i?.statut : null;
      const start: string = i?.demarrage
        ? format(new Date(i.demarrage), 'dd/MM/yyyy', { locale: fr })
        : 'Non renseigné';
      const end: string = i?.echeance
        ? format(new Date(i.echeance), 'dd/MM/yyyy', { locale: fr })
        : 'Non renseigné';

      const obj = {
        Titre: title,
        Type: type,
        Avancement: statut,
        Demarrage: start,
        Échéance: end,
        'Total participants': 0,
        'Total entités': 0,
        'Total intervenants': 0,
        'Total missions': 0,
      };
      return { ...obj };
    });

    return exlData;
  }

  actionsToSheet(actions: any): any {
    if (!actions) return null;

    const exlData = actions.map((i: any) => {
      const title = i?.titre ? i.titre.toUpperCase() : null;
      const type = i?.type ? capitalize(i.type) : null;
      const statut = i?.statut ? i?.statut : null;
      const start: string = i?.demarrage
        ? format(new Date(i.demarrage), 'dd/MM/yyyy', { locale: fr })
        : 'Non renseigné';
      const end: string = i?.echeance
        ? format(new Date(i.echeance), 'dd/MM/yyyy', { locale: fr })
        : 'Non renseigné';

      const obj = {
        Titre: title,
        Type: type,
        Avancement: statut,
        Demarrage: start,
        Échéance: end,
        'Total participants': 0,
        'Total entités': 0,
        'Total intervenants': 0,
        'Total missions': 0,
      };
      return { ...obj };
    });

    return exlData;
  }

  missionsToSheet(missions: any): any {
    if (!missions) return null;

    const exlData = missions.map((i: any) => {
      const title = i?.titre ? i.titre.toUpperCase() : null;
      const type = i?.type ? capitalize(i.type) : null;
      const statut = i?.statut ? i?.statut : null;
      const start: string = i?.demarrage
        ? format(new Date(i.demarrage), 'dd/MM/yyyy', { locale: fr })
        : 'Non renseigné';
      const end: string = i?.echeance
        ? format(new Date(i.echeance), 'dd/MM/yyyy', { locale: fr })
        : 'Non renseigné';

      const obj = {
        Titre: title,
        Type: type,
        Avancement: statut,
        Demarrage: start,
        Échéance: end,
        'Total participants': 0,
        'Total entités': 0,
        'Total intervenants': 0,
        'Total missions': 0,
      };
      return { ...obj };
    });

    return exlData;
  }

  entitesToSheet(entites: any): any {
    if (!entites) return null;
    const nonRenseigné = 'Non renseigné';

    const exlData = entites.map((i: any) => {
      const denomination = i?.denomination ? i.denomination : nonRenseigné;
      const siret = i?.siret ? i?.siret : nonRenseigné;
      const siren = i?.siren ? i?.siren : nonRenseigné;
      const activity = i?.activite_principale
        ? i?.activite_principale
        : nonRenseigné;
      const categorie = i?.categorie ? i?.categorie : nonRenseigné;
      const ess = i?.economie_sociale_solidaire === 'O' ? 'OUI' : 'NON';
      const commune = i?.libelle_commune ? i?.libelle_commune : 'NON';

      const domaine: string = nonRenseigné;
      const grandDomaine: string = nonRenseigné;

      const role: string = i?.intervention_role
        ? i.intervention_role
        : nonRenseigné;

      const obj = {
        Dénomination: denomination,
        'Activité principale': activity,
        Categorie: categorie,
        Domaine: domaine,
        'Grand domaine': grandDomaine,
        Siret: siret,
        Siren: siren,
        'Économie Sociale et Solidaire': ess,
        Commune: commune,
        Rôle: role,
        Objectifs: nonRenseigné,
        'Modules (Ateliers, marchés, ilots, activités, etc.)': 0,
        "Actions (Sessions, interventions, corps d'état, etc.)": 0,
        Missions: 0,
        'Participants suivis': 0,
      };
      return { ...obj };
    });

    return exlData;
  }

  contactsToSheet(contacts: any): any {
    if (!contacts) return null;
    const nonRenseigné = 'Non renseigné';

    const exlData = contacts.map((i: any) => {
      const civilite = i?.civility ? i.civility : nonRenseigné;
      const lastName = i?.lastName ? i.lastName : nonRenseigné;
      const firstName = i?.firstName ? i.firstName : nonRenseigné;
      const displayName = i?.displayName ? i.displayName : nonRenseigné;
      const sexe = i?.sexe ? i.sexe : nonRenseigné;
      const domaines = i?.domaines?.length
        ? i.domaines.join(', ')
        : nonRenseigné;
      const metiers = i?.metiers?.length ? i.metiers.join(', ') : nonRenseigné;
      const entites = i?.entites?.length ? i.entites.join(', ') : nonRenseigné;

      const role: string = i?.intervention.role
        ? i.intervention.role
        : nonRenseigné;
      const benevolat: string = i?.benevolat ? 'OUI' : 'NON';
      const benevolatHours: number = i?.benevolatHours ? i.benevolatHours : 0;
      const parraingage: string = i?.isParrain ? 'OUI' : 'NON';

      const obj = {
        Nom: lastName,
        Prénom: firstName,
        Genre: sexe,
        'Entité.s (Entreprises, Établissments, Structures, etc.)': entites,
        "Domaine d'activité": domaines,
        Métiers: metiers,

        Bénévolat: benevolat,
        'Heures de bénévolat': benevolatHours,
        Parrainage: parraingage,
        Rôle: role,
        'Modules (Ateliers, marchés, ilots, activités, etc.)': 0,
        "Actions (Sessions, interventions, corps d'état, etc.)": 0,
        'Participants suivis': 0,
      };
      return { ...obj };
    });

    return exlData;
  }
}
