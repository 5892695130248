import {
  ParticipantCriteres,
  ParticipantId,
} from 'src/app/components/participant/store/participant.model';
import { Injectable } from '@angular/core';
import { Firestore, doc, getDoc } from '@angular/fire/firestore';
import { Observable, of, from, EMPTY } from 'rxjs';
import { switchMap, mergeMap, map, catchError } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { UserState } from '../components/user/store/user.reducer';
import * as fromUserSelector from '../components/user/store/user.selectors';
import {
  toUpper,
  upperFirst,
  capitalize,
  forEach,
  camelCase,
  flatten,
} from 'lodash';
import {
  fromUnixTime,
  format,
  formatDistance,
  formatDistanceToNow,
  formatDistanceStrict,
  differenceInYears,
} from 'date-fns';
import { fr } from 'date-fns/locale';
// var FileSaver = require('file-saver');
import { saveAs } from 'file-saver';
declare const require: any;
const { jsPDF } = require('jspdf');
require('jspdf-autotable');
import * as html2canvas from 'html2canvas';

@Injectable({
  providedIn: 'any',
})
export class MissionExportPdfService {
  pageWidth: number = 515.28;

  constructor(private db: Firestore, private userStore: Store<UserState>) {}

  export_MISSION(mission: any, anonym: boolean = false): Observable<any> {
    try {
      return this.userStore.pipe(
        select(fromUserSelector.user),
        switchMap((user) => {
          if (!user || !mission) {
            return of(null);
          }

          const checkCrossOrigin = false;
          const missionPromess = new Promise((res, rej) => {
            if (mission) {
              res(mission);
            }
            rej();
          });

          return from(
            missionPromess
              .then((snap: any) => {
                const {
                  metier,
                  adresse,
                  bureautique_level,
                  permis,
                  comment,
                  competencesDeBaseSavoir,
                  competencesDeBaseSavoirFaire,
                  competencesSkills,
                  competencesSpecifiquesSavoir,
                  competencesSpecifiquesSavoirFaire,
                  environnementsTravail,
                  complement_salaire,
                  condition_travail,
                  nature_contrat,
                  type_contrat,
                  type_salaire,
                  experience_souhait,
                  type_horaire,
                  startMission,
                  endMission,
                  duration,
                  duree_contrat,
                  conditionsEmploi,
                  duree_contrat_value,
                  contacts,
                  type_deplacement,
                  zone_deplacement,
                  mobilite_geographique,
                  frequence_deplacement,
                  deplacement_professionnel,
                } = snap;

                const { libelle } = metier?.metier;

                const skills = competencesSkills?.length
                  ? competencesSkills.filter((skill: any) => skill.score > 0.5)
                  : [];
                const adresseLabel = adresse?.adresse?.properties?.label;

                const xhr = new XMLHttpRequest();
                xhr.open('GET', user?.organisation?.logo);
                xhr.responseType = 'blob';
                xhr.send();
                xhr.addEventListener('load', () => {
                  const reader = new FileReader();
                  reader.readAsDataURL(xhr.response);
                  reader.addEventListener('loadend', (): any => {
                    const logo = reader.result;
                    if (logo) {
                      const date = format(new Date(Date.now()), 'yyyyMM', {
                        locale: fr,
                      });

                      // Date
                      // set PDF
                      const pdfSize = 'a4';
                      const doc = new jsPDF('p', 'pt', pdfSize);
                      doc.page = 1;
                      // set propretie informations pdf
                      doc.setProperties({
                        title: `Fiche de poste ${libelle}`,
                        type: 'Fiche de poste',
                        mission: `${libelle}`,
                        date: format(new Date(Date.now()), 'dd/MM/yyyy', {
                          locale: fr,
                        }),
                        author: user?.displayName,
                        keywords: 'fiche de poste',
                      });

                      if (snap) {
                        //OPERATION

                        this.operation_table(mission, anonym, doc, logo, user);

                        //IDENTITE
                        this.identity_table(
                          metier?.metier,
                          doc,
                          logo,
                          user,
                          anonym
                        );

                        this.description_table(
                          'Définition',
                          metier?.metier?.definition,
                          doc
                        );

                        this.description_table(
                          "Conditions d'exercice de l'activité",
                          metier?.metier?.condition,
                          doc
                        );

                        //CONTRAT
                        this.contrat_table(
                          adresseLabel,
                          condition_travail,
                          nature_contrat,
                          type_contrat,
                          type_salaire,
                          experience_souhait,
                          type_horaire,
                          bureautique_level,
                          startMission,
                          endMission,
                          duration,
                          complement_salaire,
                          duree_contrat_value,
                          duree_contrat,
                          doc,
                          'Contrat'
                        );

                        //ENVIRONNEMENT
                        this.environnement_table(
                          environnementsTravail,
                          doc,
                          'Environnement de travail'
                        );
                        this.subtitle_table(doc, 'Compétences de base');

                        //COMPETENCES DE BASE SAVOIRS
                        this.competences_table(
                          competencesDeBaseSavoir,
                          doc,
                          'Savoir'
                        );

                        //COMPETENCES DE BASE SAVOIRS FAIRES
                        this.competences_table(
                          competencesDeBaseSavoirFaire,
                          doc,
                          'Savoir-faire'
                        );

                        this.subtitle_table(doc, 'Compétences spécifiques');

                        //COMPETENCES SPÉCIFIQUES SAVOIRS
                        this.competences_table(
                          competencesSpecifiquesSavoir,
                          doc,
                          'Savoir'
                        );
                        //COMPETENCES SPÉCIFIQUES FAIRES
                        this.competences_table(
                          competencesSpecifiquesSavoirFaire,
                          doc,
                          'Savoir-faire'
                        );

                        //SOFTSKILLS
                        this.skills_table(
                          skills,
                          doc,
                          'Compétences transverses'
                        );

                        //DEPLACEMENT
                        this.deplacement_table(
                          type_deplacement,
                          zone_deplacement,
                          mobilite_geographique,
                          frequence_deplacement,
                          deplacement_professionnel,
                          permis,
                          doc,
                          'Déplacements'
                        );

                        this.footerPdf(user, doc);

                        //open || download || print
                        const nameFile: string = anonym
                          ? `Fiche_De_Poste_${date}.pdf`
                          : `Fiche_De_Poste_${libelle}_${date}.pdf`;
                        doc.save(`${nameFile}`);
                      } else {
                        return of(null);
                      }
                    }
                  });

                  return of(null);
                });
              })
              .then(() => {
                // this.participantStore.dispatch(
                //   fromParticipantAction.loading({ loading: false })
                // );
                return `Fiche de poste téléchargée`;
              })

              .catch((err) => err.message)
          );
        })
      );
    } catch (err) {
      return of(err);
    }
  }

  headerPdf(data: any, user: any, doc: any, anonym: boolean, logo: any): any {
    doc.setFont('helvetica', 'normal');

    doc.setFontSize(10);
    doc.setFont('helvetica', 'bold');
    doc.text(`${user?.organisation?.nom_raison_sociale}`, 40, 40);
    doc.setFontSize(7);
    doc.setFont('helvetica', 'normal');
    doc.text(`Siret : ${user?.organisation?.siret}`, 40, 50);

    doc.text(40, 58, `Adresse : ${user?.organisation?.geo_adresse}`, {
      maxWidth: 140,
    });

    doc.setFontSize(10);
    doc.setFont('helvetica', 'bold');
    doc.text(
      `Date de création : ${format(new Date(Date.now()), 'dd/MM/yyyy', {
        locale: fr,
      })}`,
      40,
      90
    );

    // Set author
    doc.setFontSize(14);
    doc.setFont('helvetica', 'normal');
    doc.setFont('helvetica', 'bold');
    doc.text(`Fiche de poste`, 257.64, 40);
    doc.setFont('helvetica', 'italic');
    doc.setFontSize(12);
    doc.text(`${anonym ? 'Anonymisée' : ''}`, 257.64, 60);

    // Set logo organisme
    doc.setFontSize(10);
    logo ? doc.addImage(logo, 'PNG', 480, 15, 80, 80) : null;
  }

  footerPdf(user: any, doc: any): any {
    const pageCount = doc.internal.getNumberOfPages();
    let str = `Page ${doc.internal.getNumberOfPages()}`;
    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(7);
      doc.text(
        'Page ' + String(i) + ' sur ' + String(pageCount),
        515.28,
        810,
        null,
        null,
        'right'
      );
    }

    doc.setFontSize(7);
    doc.setFont('helvetica', 'normal');
    doc.text(
      `${user?.organisation?.nom_raison_sociale}`,
      280,
      810,
      null,
      null,
      'center'
    );
  }

  operation_table(
    mission: any,
    anonym: boolean,
    doc: any,
    logo: any,
    user: any
  ): any {
    const header = [
      [
        {
          content: 'Opération',
          colSpan: 2,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'bold',
            fillColor: [0, 55, 102],
            textColor: [255, 255, 255],
          },
        },
      ],
    ];

    const body = [
      [
        {
          content: `${mission?.operation?.denomination.toUpperCase()}`,
          colSpan: 2,

          styles: {
            halign: 'left',
            fontSize: 12,
            fontStyle: 'bold',
          },
        },
      ],
      [
        {
          content: `Adresse : ${mission?.adresse?.adresse?.properties?.label}`,
          colSpan: 2,
          styles: {
            halign: 'left',
            fontSize: 10,
            fontStyle: 'normal',
          },
        },
      ],
      [
        {
          content: `Marché`,
          colSpan: 2,

          styles: {
            halign: 'left',
            fontSize: 10,
            fontStyle: 'bold',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
      [
        {
          content: `${mission?.module?.title.toUpperCase()}`,
          colSpan: 2,

          styles: {
            halign: 'left',
            fontSize: 10,
            fontStyle: 'bold',
          },
        },
      ],
      [
        {
          content: `Entreprise`,
          colSpan: 2,

          styles: {
            halign: 'left',
            fontSize: 10,
            fontStyle: 'bold',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
      [
        {
          content: anonym ? '' : ``,
          colSpan: 2,

          styles: {
            halign: 'left',
            fontSize: 10,
            fontStyle: 'bold',
          },
        },
      ],
      [
        {
          content: `Siret : ${anonym ? '' : ''}`,
          colSpan: 1,

          styles: {
            halign: 'left',
            fontSize: 10,
            fontStyle: 'normal',
          },
        },
        {
          content: `Activité : `,
          colSpan: 1,

          styles: {
            halign: 'left',
            fontSize: 10,
            fontStyle: 'normal',
          },
        },
      ],
      [
        {
          content: `Contact`,
          colSpan: 2,

          styles: {
            halign: 'left',
            fontSize: 10,
            fontStyle: 'bold',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
      [
        {
          content: anonym ? `` : ``,
          colSpan: 1,

          styles: {
            halign: 'left',
            fontSize: 10,
            fontStyle: 'normal',
          },
        },
        {
          content: `Tél. Mob. : ${anonym ? `` : ``}`,
          colSpan: 1,

          styles: {
            halign: 'left',
            fontSize: 10,
            fontStyle: 'normal',
          },
        },
      ],
      [
        {
          content: `E-mail : ${anonym ? `` : ``}`,
          colSpan: 2,

          styles: {
            halign: 'left',
            fontSize: 10,
            fontStyle: 'normal',
          },
        },
      ],
      [
        {
          content: `Fonction : ${anonym ? `` : ``}`,
          colSpan: 2,

          styles: {
            halign: 'left',
            fontSize: 10,
            fontStyle: 'normal',
          },
        },
      ],
      [
        {
          content: `Besoin`,
          colSpan: 2,

          styles: {
            halign: 'left',
            fontSize: 10,
            fontStyle: 'bold',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
      [
        {
          content: `Nombre de salariés : `,
          colSpan: 1,

          styles: {
            halign: 'left',
            fontSize: 10,
            fontStyle: 'normal',
          },
        },
        {
          content: `Nombre d'heures : `,
          colSpan: 1,

          styles: {
            halign: 'left',
            fontSize: 10,
            fontStyle: 'normal',
          },
        },
      ],
    ];

    doc.autoTable({
      theme: 'grid',
      head: header,
      body: body,
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },
      headStyles: {},
      columnStyles: {
        0: { cellWidth: 257.64 },
        1: { cellWidth: 257.64 },
      },

      bodyStyles: {
        0: { fontStyle: 'bold' },
      },

      didDrawPage: (data: any) => {
        this.headerPdf(data, user, doc, anonym, logo);
      },

      margin: { top: 100 },
    });
  }

  identity_table(
    metier: any,
    doc: any,
    logo: any,
    user: any,
    anonym: boolean = false
  ): any {
    const header = [
      [
        {
          content: 'Poste',
          colSpan: 2,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'bold',
            fillColor: [0, 55, 102],
            textColor: [255, 255, 255],
          },
        },
      ],
      [
        {
          content: `ROME : ${metier?.code}`,
          colSpan: 1,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: `FAP CODE : ${metier?.fap?.fap?.code}`,
          colSpan: 1,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
      [
        {
          content: `FAP libellé : ${metier?.fap?.fap?.libelle}`,
          colSpan: 2,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
    ];

    const body = [
      [
        {
          content: `Metier : ${metier?.libelle}`,
          colSpan: 2,
          styles: {
            halign: 'left',
            fontSize: 12,
            fontStyle: 'bold',
          },
        },
      ],
      [
        {
          content: `Domaine professionnel : ${metier?.domaineProfessionnel?.libelle}`,
          colSpan: 1,

          styles: {
            halign: 'left',
            fontSize: 10,
            fontStyle: 'normal',
          },
        },
        {
          content: `Grand domaine : ${metier?.domaineProfessionnel?.grandDomaine?.libelle}`,
          colSpan: 1,

          styles: {
            halign: 'left',
            fontSize: 10,
            fontStyle: 'normal',
          },
        },
      ],
      [
        {
          content: `Appellation : ${metier?.appellation?.libelle}`,
          colSpan: 1,

          styles: {
            halign: 'left',
            fontSize: 10,
            fontStyle: 'normal',
          },
        },
        {
          content: `Qualification : ${metier?.qualification?.NAME}`,
          colSpan: 1,

          styles: {
            halign: 'left',
            fontSize: 10,
            fontStyle: 'normal',
          },
        },
      ],
    ];

    doc.autoTable({
      theme: 'grid',
      head: header,
      body: body,
      showHead: 'everyPage',

      /* foot: foot, */
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },
      headStyles: {},

      columnStyles: {
        0: { cellWidth: 257.64 },
        1: { cellWidth: 257.64 },
      },

      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
    });
  }

  description_table(title: string, item: any, doc: any): any {
    const header = [
      [
        {
          content: `${title}`,
          colSpan: 2,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
          },
        },
      ],
    ];

    const body = [
      [
        {
          content: `${item?.replace(/\r?\n|\r/g, ' ')}`,
          colSpan: 2,

          styles: {
            halign: 'left',
            fontSize: 10,
            fontStyle: 'normal',
          },
        },
      ],
    ];

    doc.autoTable({
      theme: 'grid',
      head: header,
      body: body,
      showHead: 'everyPage',

      /* foot: foot, */
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },
      headStyles: {},

      columnStyles: {
        0: { cellWidth: 257.64 },
        1: { cellWidth: 257.64 },
      },

      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
    });
  }

  subtitle_table(doc: any, title: string): any {
    const fontSize: number = 10;

    const rowStyle = {
      fontSize: 10,
      halign: 'left',
      fontStyle: 'normal',
    };
    const header = [
      [
        {
          content: title,
          colSpan: 2,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'bold',
            fillColor: [0, 55, 102],
            textColor: [255, 255, 255],
          },
        },
      ],
    ];

    doc.autoTable({
      theme: 'grid',
      head: header,
      showHead: 'everyPage',

      /* foot: foot, */
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },
      headStyles: {},

      columnStyles: {
        0: { cellWidth: 400 },
        1: { cellWidth: 115.28 },
      },

      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
    });
  }

  competences_table(items: any[], doc: any, title: string): any {
    const fontSize: number = 10;

    const rowStyle = {
      fontSize: 10,
      halign: 'left',
      fontStyle: 'normal',
    };
    const header = [
      [
        {
          content: title,
          colSpan: 2,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'bold',
            fillColor: [217, 217, 217],
          },
        },
      ],
    ];

    const body = items?.length
      ? items.map((el) => {
          const row = [
            [
              {
                content: el?.libelle,
                colSpan: 1,
                styles: {
                  ...rowStyle,
                  fontStyle: 'normal',
                  fontSize: fontSize,
                  fillColor: [255, 255, 255],
                },
              },
              {
                content: `Compétence clé : ${el.competenceCle ? 'Oui' : 'Non'}`,
                colSpan: 1,
                styles: {
                  ...rowStyle,
                  fontStyle: 'normal',
                  halign: 'left',
                  fontSize: fontSize,
                  fillColor: [255, 255, 255],
                },
              },
            ],
          ];

          return flatten(row);
        })
      : [];

    doc.autoTable({
      theme: 'grid',
      head: header,
      body: body,
      showHead: 'everyPage',

      /* foot: foot, */
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },
      headStyles: {},

      columnStyles: {
        0: { cellWidth: 400 },
        1: { cellWidth: 115.28 },
      },

      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
    });
  }

  skills_table(items: any[], doc: any, title: string): any {
    const fontSize: number = 10;

    const rowStyle = {
      fontSize: 10,
      halign: 'left',
      fontStyle: 'normal',
    };
    const header = [
      [
        {
          content: title,
          colSpan: 2,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'bold',
            fillColor: [0, 55, 102],
            textColor: [255, 255, 255],
          },
        },
      ],
    ];

    const body = items?.length
      ? items.map((el) => {
          const row = [
            [
              {
                content: el?.summary,
                colSpan: 1,
                styles: {
                  ...rowStyle,
                  fontStyle: 'bold',
                  fontSize: fontSize,
                  fillColor: [255, 255, 255],
                },
              },
            ],
            [
              {
                content: el?.details,
                colSpan: 1,
                styles: {
                  ...rowStyle,
                  fontStyle: 'normal',
                  halign: 'left',
                  fontSize: fontSize,
                  fillColor: [255, 255, 255],
                },
              },
            ],
          ];

          return flatten(row);
        })
      : [];

    doc.autoTable({
      theme: 'grid',
      head: header,
      body: body,
      showHead: 'everyPage',

      /* foot: foot, */
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },
      headStyles: {},

      // columnStyles: {
      //   0: { cellWidth: 400 },
      //   1: { cellWidth: 112 },
      // },

      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
    });
  }

  environnement_table(items: any[], doc: any, title: string): any {
    const fontSize: number = 10;

    const rowStyle = {
      fontSize: 10,
      halign: 'left',
      fontStyle: 'normal',
    };
    const header = [
      [
        {
          content: title,
          colSpan: 1,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'bold',
            fillColor: [0, 55, 102],
            textColor: [255, 255, 255],
          },
        },
      ],
    ];

    const body = items?.length
      ? items.map((el) => {
          const row = [
            [
              {
                content: el?.libelle,
                colSpan: 1,
                styles: {
                  ...rowStyle,
                  fontStyle: 'normal',
                  fontSize: fontSize,
                  fillColor: [255, 255, 255],
                },
              },
            ],
          ];

          return flatten(row);
        })
      : [];

    doc.autoTable({
      theme: 'grid',
      head: header,
      body: body,
      showHead: 'everyPage',

      /* foot: foot, */
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },
      headStyles: {},

      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
    });
  }

  contrat_table(
    adresse: string,
    condition_travail: any,
    nature_contrat: any,
    type_contrat: any,
    type_salaire: any,
    experience_souhait: any,
    type_horaire: any,
    bureautique_level: any,
    start: any,
    end: any,
    duration: any,
    complement_salaire: any,
    duree_contrat_value: any,
    duree_contrat: any,
    doc: any,
    title: string
  ): any {
    const fontSize: number = 10;

    const rowStyle = {
      fontSize: 10,
      halign: 'left',
      fontStyle: 'normal',
    };
    const header = [
      [
        {
          content: title,
          colSpan: 2,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'bold',
            fillColor: [0, 55, 102],
            textColor: [255, 255, 255],
          },
        },
      ],
    ];

    const body = [
      [
        {
          content: `Type de contrat : ${type_contrat?.NAME}`,
          colSpan: 1,
          styles: {
            ...rowStyle,
            fontStyle: 'bold',
            fontSize: fontSize,
            fillColor: [255, 255, 255],
          },
        },
        {
          content: `Nature du contrat : ${nature_contrat?.NAME}`,
          colSpan: 1,
          styles: {
            ...rowStyle,
            fontStyle: 'bold',
            fontSize: fontSize,
            fillColor: [255, 255, 255],
          },
        },
      ],
      [
        {
          content: `Condition de travail : ${condition_travail?.NAME}`,
          colSpan: 1,
          styles: {
            ...rowStyle,
            fontStyle: 'normal',
            fontSize: fontSize,
            fillColor: [255, 255, 255],
          },
        },
        {
          content: `Type de salaire : ${type_salaire?.NAME}`,
          colSpan: 1,
          styles: {
            ...rowStyle,
            fontStyle: 'normal',
            fontSize: fontSize,
            fillColor: [255, 255, 255],
          },
        },
      ],
      [
        {
          content: `Expérience : ${experience_souhait?.NAME}`,
          colSpan: 1,
          styles: {
            ...rowStyle,
            fontStyle: 'normal',
            fontSize: fontSize,
            fillColor: [255, 255, 255],
          },
        },
        {
          content: `Horaire : ${type_horaire?.NAME}`,
          colSpan: 1,
          styles: {
            ...rowStyle,
            fontStyle: 'normal',
            fontSize: fontSize,
            fillColor: [255, 255, 255],
          },
        },
      ],
      [
        {
          content: `Bureautique : ${bureautique_level?.NAME}`,
          colSpan: 1,
          styles: {
            ...rowStyle,
            fontStyle: 'normal',
            fontSize: fontSize,
            fillColor: [255, 255, 255],
          },
        },
        {
          content: `Durée du contrat : ${duree_contrat_value} ${duree_contrat?.NAME}`,
          colSpan: 1,
          styles: {
            ...rowStyle,
            fontStyle: 'normal',
            fontSize: fontSize,
            fillColor: [255, 255, 255],
          },
        },
      ],

      [
        {
          content: `Démarrage : ${
            start
              ? format(fromUnixTime(start['seconds']), 'dd/MM/yyyy', {
                  locale: fr,
                })
              : ''
          }`,
          colSpan: 1,
          styles: {
            ...rowStyle,
            fontStyle: 'bold',
            fontSize: fontSize,
            fillColor: [255, 255, 255],
          },
        },
        {
          content: `Échéance : ${
            end
              ? format(fromUnixTime(end['seconds']), 'dd/MM/yyyy', {
                  locale: fr,
                })
              : ''
          }`,
          colSpan: 1,
          styles: {
            ...rowStyle,
            fontStyle: 'bold',
            fontSize: fontSize,
            fillColor: [255, 255, 255],
          },
        },
      ],
    ];

    doc.autoTable({
      theme: 'grid',
      head: header,
      body: body,
      showHead: 'everyPage',

      /* foot: foot, */
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },
      headStyles: {},

      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
    });
  }

  deplacement_table(
    type_deplacement: any,
    zone_deplacement: any,
    mobilite_geographique: any,
    frequence_deplacement: any,
    deplacement_professionnel: any,
    permis: any,
    doc: any,
    title: string
  ): any {
    const fontSize: number = 10;

    const rowStyle = {
      fontSize: 10,
      halign: 'left',
      fontStyle: 'normal',
    };
    const header = [
      [
        {
          content: title,
          colSpan: 2,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'bold',
            fillColor: [0, 55, 102],
            textColor: [255, 255, 255],
          },
        },
      ],
    ];

    const body = [
      [
        {
          content: `Déplacement professionnel : ${
            deplacement_professionnel?.NAME
              ? deplacement_professionnel?.NAME
              : '-'
          }`,
          colSpan: 1,
          styles: {
            ...rowStyle,
            fontStyle: 'normal',
            fontSize: fontSize,
            fillColor: [255, 255, 255],
          },
        },
        {
          content: `Fréquence de déplacement : ${
            frequence_deplacement?.NAME ? frequence_deplacement?.NAME : '-'
          }`,
          colSpan: 1,
          styles: {
            ...rowStyle,
            fontStyle: 'normal',
            fontSize: fontSize,
            fillColor: [255, 255, 255],
          },
        },
      ],
      [
        {
          content: `Type de déplacement : ${
            mobilite_geographique?.NAME ? mobilite_geographique?.NAME : '-'
          }`,
          colSpan: 1,
          styles: {
            ...rowStyle,
            fontStyle: 'normal',
            fontSize: fontSize,
            fillColor: [255, 255, 255],
          },
        },
        {
          content: `Zone de déplacement : ${
            zone_deplacement?.NAME ? zone_deplacement?.NAME : '-'
          }`,
          colSpan: 1,
          styles: {
            ...rowStyle,
            fontStyle: 'normal',
            fontSize: fontSize,
            fillColor: [255, 255, 255],
          },
        },
      ],
      [
        {
          content: `Mobilité gréographique : ${
            type_deplacement?.NAME ? type_deplacement?.NAME : '-'
          }`,
          colSpan: 2,
          styles: {
            ...rowStyle,
            fontStyle: 'normal',
            fontSize: fontSize,
            fillColor: [255, 255, 255],
          },
        },
      ],
      [
        {
          content: `Permis : ${
            permis?.length ? permis.map((el: any) => el?.NAME).join(', ') : '-'
          }`,
          colSpan: 2,
          styles: {
            ...rowStyle,
            fontStyle: 'normal',
            fontSize: fontSize,
            fillColor: [255, 255, 255],
          },
        },
      ],
    ];

    doc.autoTable({
      theme: 'grid',
      head: header,
      body: body,
      showHead: 'everyPage',

      /* foot: foot, */
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },
      headStyles: {},

      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
    });
  }
}
