import { Store } from '@ngrx/store';
import { NzCalendarMode } from 'ng-zorro-antd/calendar';
import { Observable, of, Subject } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { UtilitiesState } from 'src/app/contents/components/utilities/store/utilities.reducer';
import * as fromUtilitiesSelector from 'src/app/contents/components/utilities/store/utilities.selectors';
import { ParticipantPlanningState } from './../../store/participant-planning.reducer';
import * as fromParticipantPlanningAction from './../../store/participant-planning.actions';
import * as fromParticipantPlanningSelector from './../../store/participant-planning.selectors';
import { ParticipantState } from 'src/app/components/participant/store/participant.reducer';
import * as fromParticipantSelector from 'src/app/components/participant/store/participant.selectors';

import { ParticipantId } from 'src/app/components/participant/store/participant.model';

@Component({
  selector: 'app-participant-planning-filter',
  templateUrl: './participant-planning-filter.component.html',
  styleUrls: ['./participant-planning-filter.component.scss'],
})
export class ParticipantPlanningFilterComponent implements OnInit {
  subscribe = new Subject();
  dateFormat: string = 'MM/yyyy';
  dateMode: NzCalendarMode = 'month';
  participant$: Observable<ParticipantId | any> = of(null);

  types$: Observable<
    { alue: string; label: string; color: string; colorLabel: string }[]
  > = of([]);
  filterMoments$: Observable<string[]> = of([]);

  planningFilterForm: FormGroup = this.fb.group({});
  type: FormControl = new FormControl<string>('');
  createAtMonthYear: FormControl = new FormControl<string>('');
  year: FormControl = new FormControl<string>('');
  dateSelected: FormControl = new FormControl<Date | any>(null);

  constructor(
    private fb: FormBuilder,
    private utilitiesStore: Store<UtilitiesState>,
    private participantPlanningStore: Store<ParticipantPlanningState>,
    private participantStore: Store<ParticipantState>
  ) {}

  ngOnInit(): void {
    this.getParticipant();
    this.getPlanningTypes();
    this.getPlanningFilterMoment();
  }

  getPlanningTypes(): void {
    this.types$ = this.utilitiesStore.select(
      fromUtilitiesSelector.planningTypes
    );
  }
  getPlanningFilterMoment(): void {
    this.filterMoments$ = this.participantPlanningStore.select(
      fromParticipantPlanningSelector.filterMoments
    );
  }

  getParticipant(): void {
    this.participant$ = this.participantStore.select(
      fromParticipantSelector.participant
    );
  }

  onFilter(
    id: string,
    filter: { type: string; createAtMonthYear: string }
  ): void {
    if (!id || !filter.createAtMonthYear) return;
    this.participantPlanningStore.dispatch(
      fromParticipantPlanningAction.loadParticipantPlanningsFiltered({
        id,
        filter,
      })
    );
  }

  onRemoveFilter(): void {}
}
