import { Update } from '@ngrx/entity';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { UtilitiesState } from '../../store/utilities.reducer';
import * as fromUtilitiesAction from '../../store/utilities.actions';
import * as fromUtilitiesSelector from '../../store/utilities.selectors';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-utilities-items-list',
  templateUrl: './utilities-items-list.component.html',
  styleUrls: ['./utilities-items-list.component.scss'],
})
export class UtilitiesItemsListComponent implements OnInit {
  @Input() items: any = [];
  @Input() utility: any = null;
  @Input() variable: string = '';
  constructor(private utilityStore: Store<UtilitiesState>) {}

  ngOnInit(): void {}

  onDelete(item: any, items: any[], utility: any, variable: any): any {
    if (!item || !items || !utility || !variable) {
      return null;
    } else {
      const filtertList = items.filter((el: any) => el !== item);

      const nextList: Update<any> = {
        id: utility.id,
        changes: {
          ...utility,
          [`${variable}`]: filtertList,
        },
      };

      this.utilityStore.dispatch(
        fromUtilitiesAction.updateUtility({ utility: nextList })
      );
      this.items = filtertList;
    }
  }

  cancel(): any {
    return null;
  }
}
