<table class="participantTable" *ngFor="let item of participants">
  <thead>
    <tr class="rowParticipanteHeader">
      <th style="font-weight: normal">{{ item.qualite }}</th>
    </tr>
    <tr classs="rowRepresentantHeader">
      <th
        class="entite"
        style="
          width: 133px;
          background-color: grey;
          font-weight: normal;
          color: white;
          float: left;
        "
      >
        Entité
      </th>
      <th
        class="adresse"
        style="
          width: 160px;
          background-color: grey;
          font-weight: normal;
          color: white;
          float: left;
        "
      >
        Adresse
      </th>
      <th
        class="representant"
        style="
          width: 145px;
          background-color: grey;
          font-weight: normal;
          color: white;
          float: left;
        "
      >
        Représentants
      </th>
      <th
        class="coordonnees"
        style="
          width: 112px;
          background-color: grey;
          font-weight: normal;
          color: white;
          float: left;
        "
      >
        Coordonnées
      </th>
      <th
        class="present"
        style="
          width: 10px;
          background-color: grey;
          font-weight: normal;
          color: white;
        "
      >
        P
      </th>
      <th
        class="absent"
        style="
          width: 10px;
          background-color: grey;
          font-weight: normal;
          color: white;
        "
      >
        A
      </th>
      <th
        class="convoque"
        style="
          width: 10px;
          background-color: grey;
          font-weight: normal;
          color: white;
        "
      >
        C
      </th>
      <th
        class="diffuse"
        style="
          width: 10px;
          background-color: grey;
          font-weight: normal;
          color: white;
        "
      >
        D
      </th>
    </tr>
  </thead>
  <tbody *ngFor="let r of item.representants">
    <tr>
      <td class="entite" style="width: 133px; float: left; font-size: 8px">
        {{ r.entite | titlecase }}
      </td>
      <td class="adresse" style="width: 160px; float: left; font-size: 8px">
        <span>{{ r.adresse.numero }}, {{ r.adresse.rue }}</span>
        <span>{{ r.adresse.commune }},{{ r.adresse.codePostal }}</span>
      </td>
      <td
        class="representant"
        style="
          width: 145px;
          float: left;
          font-size: 8px;
          display: flex;
          flex-flow: column;
        "
      >
        <span>{{ r.displayName }} {{ r.lastName }}</span>
        <span>{{ r.coordonnees.mail }}</span>
      </td>
      <td class="coordonnees" style="width: 115px; float: left; font-size: 8px">
        {{ r.coordonnees.telephone }} - {{ r.coordonnees.mobile }}
      </td>
      <td class="partPresent" style="width: 10px"></td>
      <td class="partAbsent" style="width: 10px"></td>
      <td class="partConvoque" style="width: 10px"></td>
      <td class="partDiffuse" style="width: 10px"></td>
    </tr>
  </tbody>
</table>
