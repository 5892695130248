<ng-container *ngIf="entite$ | async as entite">
  <app-table-suivis
    *ngIf="!(loading$ | async); else loading"
    [source]="suivis$ | async"
    (add)="onAdd($event, entite)"
    (update)="onUpdate($event, entite)"
    (delete)="onDelete($event, entite)"
    (deleteAll)="onDeleteAll(entite.id)"
  ></app-table-suivis>
</ng-container>

<ng-template #loading>
  <app-loading-animation [loading$]="loading$"></app-loading-animation>
</ng-template>
