<app-layout-list
  *ngIf="
    (data$ | async).contacts.length || (contacts$ | async) | size;
    else noResults
  "
  [new]="new"
  [card]="card"
  [source]="(data$ | async)?.contacts"
></app-layout-list>

<ng-template #new>
  <app-card-new
    [type]="'person'"
    [height]="'250px'"
    [title]="newCardTitle"
    [description]="''"
    [definition]="newCardDefinition"
    (add)="onNewContactForm()"
  ></app-card-new>
</ng-template>

<ng-template #card let-item let-favoris let-position="idx">
  <app-contact-card
    [contact]="item"
    [favoris]="favoris$ | async"
  ></app-contact-card>
</ng-template>

<ng-template #noResults>
  <app-no-result
    [icon]="'person_add'"
    [addtitle]="'Ajouter un contact'"
    [notitle]="'Aucun contact'"
    [description]="newCardDefinition"
    (new)="onNewContactForm()"
  ></app-no-result>
</ng-template>

<ng-template #contactFormTitle>
  <app-header-title [icon]="'person'" [title]="title"></app-header-title>
</ng-template>
