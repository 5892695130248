import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, Subject } from 'rxjs';
import { ParticipantId } from 'src/app/components/participant/store/participant.model';
import { ParticipantPlanningState } from '../../store/participant-planning.reducer';
import * as fromParticipantPlanningAction from './../../store/participant-planning.actions';
import * as fromParticipantPlanningSelector from './../../store/participant-planning.selectors';
import { ParticipantState } from 'src/app/components/participant/store/participant.reducer';
import * as fromParticipantSelector from 'src/app/components/participant/store/participant.selectors';

@Component({
  selector: 'app-participant-planning-list',
  templateUrl: './participant-planning-list.component.html',
  styleUrls: ['./participant-planning-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantPlanningListComponent
  implements OnInit, OnChanges, OnDestroy
{
  subscribe = new Subject();
  selectedValue = new Date(Date.now());
  events$: Observable<any> = of([]);
  participant$: Observable<ParticipantId | any> = of(null);

  constructor(
    private participantPlanningStore: Store<ParticipantPlanningState>,
    private participantStore: Store<ParticipantState>
  ) {}

  ngOnInit(): void {
    this.getParticipant();
    this.getEvents();
  }
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  trackByFn(index: number, item: any): number {
    return item.id;
  }
  getParticipant(): void {
    this.participant$ = this.participantStore.select(
      fromParticipantSelector.participant
    );
  }

  getMonthData(date: Date): number | null {
    if (!date) return null;
    const d = (
      date.getDate() +
      '-' +
      date.getMonth() +
      '-' +
      date.getFullYear()
    ).toString();
    return date.getMonth() + 1;
  }

  selectChange(select: Date): void {}

  panelChange(event: any): void {}

  getEvents(): void {
    this.events$ = this.participantPlanningStore.select(
      fromParticipantPlanningSelector.eventsGroupByDate
    );
  }
  onDelete(id: string, participant: ParticipantId): void {
    if (!id || !participant) return;
    this.participantPlanningStore.dispatch(
      fromParticipantPlanningAction.deleteParticipantPlanning({
        participantId: participant.id,
        id: id,
      })
    );
  }
}
