<form nz-form [formGroup]="experienceForm">
  <nz-row
    nzAlign="middle"
    nzGutter="8"
    nzJustify="end"
    *ngIf="experiences$ | async | size"
  >
    <nz-col nzSpan="20"></nz-col>
    <nz-col nzSpan="4">
      <button nz-button (click)="onNew()" nzBlock>Ajouter</button>
    </nz-col>
  </nz-row>
  <nz-table
    #filterTable
    [nzData]="experiences$ | async"
    nzTableLayout="fixed"
    nzSize="small"
    [nzScroll]="scroll"
    [nzLoadingDelay]="2"
    [nzBordered]="false"
    [nzOuterBordered]="false"
    [nzNoResult]="noResult"
  >
    <thead>
      <tr>
        <th nzWidth="300px">Poste</th>
        <th>Entreprise</th>

        <th nzWidth="50px"></th>
      </tr>
    </thead>
    <tbody>
      <tr
        [@listAnimation]
        *ngFor="let data of filterTable.data"
        (click)="$event.stopPropagation()"
      >
        <td nzWidth="300px"></td>
        <td></td>

        <td nzRight nzWidth="50px">
          <app-button-dropdown
            [direction]="'horizontal'"
            (click)="$event.stopPropagation()"
            [isDelete]="true"
            [deleteType]="'Voulez-vous supprimer cette expérience'"
            (select)="onSelectDropdown($event, data)"
          ></app-button-dropdown>
        </td>
      </tr>
    </tbody>
  </nz-table>

  <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="24">
      <nz-form-item>
        <nz-form-control>
          <label nz-checkbox formControlName="isFrein">{{
            isFreinTitle
          }}</label>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>
</form>

<ng-template #noResult>
  <app-no-result
    [addtitle]="'Ajouter une expérience professionnelle'"
    [description]="'Aucune expérience professionnelle'"
    [icon]="'business_center'"
    [buttonTitle]="'Ajouter'"
    (new)="onNew()"
  ></app-no-result>
</ng-template>

<ng-template #diagnosticExperienceTitleForm>
  <app-header-title
    [icon]="'business_center'"
    [title]="title"
  ></app-header-title>
</ng-template>
