import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-operation-cadrage-interface',
  templateUrl: './operation-cadrage-interface.component.html',
  styleUrls: ['./operation-cadrage-interface.component.scss']
})
export class OperationCadrageInterfaceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
