import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  SuggestionsNafState,
  metiersSuggestionsFeatureKey,
  selectAll,
} from './metiers-suggestion.reducer';

export const selectSuggestionNafState =
  createFeatureSelector<SuggestionsNafState>(metiersSuggestionsFeatureKey);

export const suggestions = createSelector(
  selectSuggestionNafState,
  (state: SuggestionsNafState) => state.suggestions
);

export const appelations = createSelector(
  selectSuggestionNafState,
  (state: SuggestionsNafState) => state.appelations
);

export const total = createSelector(
  selectSuggestionNafState,
  (state: SuggestionsNafState) => state.total
);

export const loading = createSelector(
  selectSuggestionNafState,
  (state: SuggestionsNafState) => state.loading
);

export const metier = createSelector(
  selectSuggestionNafState,
  (state: SuggestionsNafState) => state.metier
);

export const error = createSelector(
  selectSuggestionNafState,
  (state: SuggestionsNafState) => state.error
);
