import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'participantsDisponibility',
})
export class ParticipantsDisponibilityPipe implements PipeTransform {
  transform(value: any[], arg: string, type: string, id: string): any {
    if (!value) {
      return [];
    }

    if (type === 'actions') {
      switch (arg) {
        case 'presents':
          return value.filter(
            (item: any) =>
              item?.actions.filter((el: any) => el.id === id && el.presence).length
          );

          break;
        case 'excuses':
          return value.filter(
            (item:any) =>
              item?.actions.filter((el:any) => el.id === id && el.excuse).length
          );

          break;
        case 'absences':
          return value.filter(
            (item:any) =>
              item?.actions.filter((el:any) => el.id === id && el.absence).length
          );

          break;
        default:
          return [];
          break;
      }
    }

    if (type === 'modules') {
      switch (arg) {
        case 'presents':
          return value.filter(
            (item:any) =>
              item?.modules.filter((el:any) => el.id === id && el.presence).length
          );

          break;
        case 'excuses':
          return value.filter(
            (item:any) =>
              item?.modules.filter((el:any) => el.id === id && el.excuse).length
          );

          break;
        case 'absences':
          return value.filter(
            (item:any) =>
              item?.modules.filter((el:any) => el.id === id && el.absence).length
          );

          break;
        default:
          return [];
          break;
      }
    }

    if (type === 'entites') {
      switch (arg) {
        case 'presents':
          return value;

          break;
        case 'excuses':
          return value;

          break;
        case 'absences':
          return value;
          break;
        default:
          return [];
          break;
      }
    }

    if (type === 'contacts') {
      switch (arg) {
        case 'presents':
          return value;

          break;
        case 'excuses':
          return value;

          break;
        case 'absences':
          return value;
          break;
        default:
          return [];
          break;
      }
    }

    if (type === 'operations') {
      switch (arg) {
        case 'presents':
          return value.filter(
            (item:any) =>
              item?.operations.filter((el:any) => el.id === id && el.presence)
                .length
          );

          break;
        case 'excuses':
          return value.filter(
            (item:any) =>
              item?.operations.filter((el:any) => el.id === id && el.excuse).length
          );

          break;
        case 'absences':
          return value.filter(
            (item:any) =>
              item?.operations.filter((el:any) => el.id === id && el.absence).length
          );

          break;
        default:
          return [];
          break;
      }
    }
  }
}
