import { OperationTask } from './../../store/operation-task.model';
import { OperationTaskState } from './../../store/operation-task.reducer';
import * as fromOperationTaskSelector from './../../store/operation-task.selectors';
import * as fromOperationTaskAction from './../../store/operation-task.actions';

import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { add } from 'date-fns';
import { NzMessageService } from 'ng-zorro-antd/message';
import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';
import { OperationId } from 'src/app/components/operation/store/operation.model';
import { OperationState } from 'src/app/components/operation/store/operation.reducer';
import * as fromOperationSelector from 'src/app/components/operation/store/operation.selectors';
import { Timestamp } from 'firebase/firestore';

@Component({
  selector: 'app-operation-task-form',
  templateUrl: './operation-task-form.component.html',
  styleUrls: ['./operation-task-form.component.scss'],
})
export class OperationTaskFormComponent implements OnInit {
  user$: Observable<any> = of(null);
  users$: Observable<any[]> = of([]);
  operation$: Observable<OperationId | any> = of(null);

  subscribe = new Subject();
  textInput: string = '';
  today: Date = new Date(Date.now());
  dates: Date[] = [this.today, add(this.today, { days: 2 })];
  datesFormat: string = 'dd/MM/yyyy';
  datesPalceholders: string[] = ['Démarrage', 'Livraison'];

  badgeCounterStyle = {
    backgroundColor: '#ffab00',
    color: 'black',
    fontWeigth: 'bold',
  };

  todoFormTitle: string = 'Nouvelle planification';
  todoFormMessage: string = '';
  todoForm = this.fb.group({});
  title = new UntypedFormControl();
  messages = new UntypedFormControl();
  description = new UntypedFormControl();
  texts = new UntypedFormArray([]);
  auteur = new UntypedFormControl();
  auths = new UntypedFormControl();
  ids = new UntypedFormControl();
  users = new UntypedFormControl();
  completed = new UntypedFormControl();
  tested = new UntypedFormControl();
  verifed = new UntypedFormControl();
  inprogress = new UntypedFormControl();
  userUpdate = new UntypedFormControl();
  priority = new UntypedFormControl(false);
  dateStart = new UntypedFormControl();
  dateUpdate = new UntypedFormControl();
  dateEnd = new UntypedFormControl();
  organisation = new UntypedFormControl();
  operation = new UntypedFormControl();

  @Output() cancel = new EventEmitter<boolean>();
  @Output() add = new EventEmitter<any>();

  @Input() modalVisibility = new BehaviorSubject<boolean>(false);

  constructor(
    private fb: UntypedFormBuilder,
    private storeUser: Store<UserState>,
    private storeOperation: Store<OperationState>,
    private storeOperationTodo: Store<OperationTaskState>,
    private nzMessage: NzMessageService
  ) {}

  ngOnInit(): void {
    this.get__USER();
    this.get__OPERATION();
    this.modalInit();
    this.formInit();
    this.formCreate();
  }

  modalInit(): void {
    this.modalVisibility
      .pipe(takeUntil(this.subscribe))
      .subscribe((val: any) => {
        if (!val) {
          return;
        }
        this.formInit();
        this.formCreate();
      });
  }

  get__USER(): void {
    this.user$ = this.storeUser.pipe(select(fromUserSelector.user));
  }

  get__USERS(): void {
    this.users$ = this.storeUser.pipe(select(fromUserSelector.users));
  }

  get__OPERATION(): void {
    this.operation$ = this.storeOperation.select(
      fromOperationSelector.operation
    );
  }

  formInit(): void {
    this.texts = new UntypedFormArray([]);
    this.title = new UntypedFormControl('', Validators.required);
    this.messages = new UntypedFormControl([]);
    this.description = new UntypedFormControl();
    this.auteur = new UntypedFormControl();
    this.auths = new UntypedFormControl();
    this.ids = new UntypedFormControl([]);
    this.users = new UntypedFormControl([]);
    this.completed = new UntypedFormControl(false, Validators.required);
    this.tested = new UntypedFormControl(false, Validators.required);
    this.verifed = new UntypedFormControl(false, Validators.required);
    this.inprogress = new UntypedFormControl(false, Validators.required);
    this.userUpdate = new UntypedFormControl();
    this.priority = new UntypedFormControl(false);
    this.dateStart = new UntypedFormControl(this.dates[0], Validators.required);
    this.dateUpdate = new UntypedFormControl();
    this.dateEnd = new UntypedFormControl(this.dates[1], Validators.required);
    this.organisation = new UntypedFormControl({});
    this.operation = new UntypedFormControl({});
  }

  formCreate(): void {
    this.todoForm = this.fb.group({
      title: this.title,
      messages: this.messages,
      description: this.description,
      texts: this.texts,
      auteur: this.auteur,
      auths: this.auths,
      ids: this.ids,
      users: this.users,
      completed: this.completed,
      tested: this.tested,
      verifed: this.verifed,
      inprogress: this.inprogress,
      userUpdate: this.userUpdate,
      priority: this.priority,
      dateStart: this.dateStart,
      dateUpdate: this.dateUpdate,
      dateEnd: this.dateEnd,
      organisation: this.organisation,
      operation: this.operation,
    });
  }

  get textsArray() {
    return this.todoForm.get('texts') as UntypedFormArray;
  }

  ngModelChangeText(event: any): void {
    this.todoFormMessage = '';
  }

  addText(): void {
    const newText = this.fb.group({
      value: [this.textInput],
      status: [false],
    });

    this.texts.push(newText);
    this.textInput = '';
    this.todoFormMessage = '';
  }

  removeText(index: number): void {
    this.texts.removeAt(index);
  }

  onChangeDates(event: Date[]): void {
    const start: Date = event[0] ? event[0] : this.dates[0];
    const end: Date = event[1] ? event[1] : this.dates[1];

    this.todoForm.patchValue({
      dateStart: start,
      dateEnd: end,
    });
  }

  addUsers(users: any[]): void {
    if (!users?.length) {
      return;
    }

    this.todoForm.patchValue({
      users: users,
      ids: users.map((user) => user.id),
    });
  }

  addTodo__OPERATION(operation: OperationId): void {
    if (!this.todoForm.valid || !operation) {
      return;
    }

    const formValue: OperationTask = this.todoForm.value;

    if (!formValue.title) {
      const message: string = 'Veuillez ajouter une tâche !';
      this.todoFormMessage = message;
      this.nzMessage.warning(message, { nzDuration: 4000 });
      return;
    }

    const start = Timestamp.fromDate(formValue.dateStart);
    const end = Timestamp.fromDate(formValue.dateEnd);

    const task: OperationTask = {
      ...formValue,
      dateStart: start,
      dateEnd: end,
    };

    this.storeOperationTodo.dispatch(
      fromOperationTaskAction.addOperationTask({ operation, task })
    );

    this.onCancel();
    this.formInit();
    this.formCreate();
  }

  closeModal__OPERATION(): void {
    this.cancel.emit(true);
    this.todoFormMessage = '';
  }

  onCancel(): void {
    this.todoForm.reset();
    this.textInput = '';
    this.dates = [this.today, add(this.today, { days: 2 })];
    this.texts = new UntypedFormArray([]);
    this.textsArray.clear();
    this.todoFormMessage = '';
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }
}
