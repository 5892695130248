import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-time-indicators',
  templateUrl: './time-indicators.component.html',
  styleUrls: ['./time-indicators.component.scss'],
})
export class TimeIndicatorsComponent implements OnInit {
  bleu: string = '#1890ff';
  red: string = '#f5222d';
  green: string = '#52c41a';
  default: string = '#bfbfbf';
  @Input() bordeless: boolean = true;

  @Input() start: any;
  @Input() startTitle: string = 'Démarrage';
  @Input() events: any[] = [];

  @Input() end: any;
  @Input() endTitle: string = 'Livraison';

  @Input() size: string = 'small';
  @Input() status: boolean = false;
  @Input() step: number = 6;
  @Input() dates: boolean = true;
  @Input() graph: boolean = true;
  @Input() info: boolean = true;
  @Input() isIntermediaire: boolean = false;

  @Input() distance: string = 'hours';
  @Input() timeFormat: string = 'time';
  constructor() {}

  ngOnInit(): void {}
}
