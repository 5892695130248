import { ParticipantId } from 'src/app/components/participant/store/participant.model';
import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { ParticipantState } from 'src/app/components/participant/store/participant.reducer';
import * as fromParticipantSelector from 'src/app/components/participant/store/participant.selectors';

import { Store } from '@ngrx/store';
import { ParticipantAccompagnementState } from '../../store/participant-action-accompagnement.reducer';
import * as fromParticipantAccompagnementAction from '../../store/participant-action-accompagnement.actions';
import * as fromParticipantAccompagnementSelector from '../../store/participant-action-accompagnement.selectors';

@Component({
  selector: 'app-participant-actions-accompagnement-item',
  templateUrl: './participant-actions-accompagnement-item.component.html',
  styleUrls: ['./participant-actions-accompagnement-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantActionsAccompagnementItemComponent implements OnInit {
  accompagnement$: Observable<any> = of(null);
  participant$: Observable<ParticipantId | any> = of(null);

  constructor(
    private router: Router,
    private participantStore: Store<ParticipantState>,
    private participantAccompagnementStore: Store<ParticipantAccompagnementState>
  ) {}

  ngOnInit(): void {
    this.getParticipant();
    this.getAccompagnement();
  }

  getParticipant(): void {
    this.participant$ = this.participantStore.select(
      fromParticipantSelector.participant
    );
  }
  getAccompagnement(): void {
    this.accompagnement$ = this.participantAccompagnementStore.select(
      fromParticipantAccompagnementSelector.accompagnement
    );
  }

  onBack(participant: ParticipantId): void {
    this.router.navigate(['participant', participant.id, 'actions']);
  }
}
