<ng-container *ngIf="user$ | async as user">
  <nz-row nzAlign="bottom" nzGutter="0" nzJustify="end">
    <nz-col nzSpan="3" *ngIf="message?.auteur?.id !== user.id">
      <nz-avatar
        [nzText]="message.auteur.avatar"
        [ngStyle]="{
          'background-color': message?.auteur?.setting?.color
            ? message.auteur.setting.color
            : '#bfbfbf',
          color: 'white'
        }"
        nz-tooltip
        [nzTooltipMouseEnterDelay]="1"
        nzTooltipPlacement="bottom"
        [nzTooltipTitle]="message.auteur.displayName"
        [nzTooltipMouseLeaveDelay]="0.1"
        nzTooltipTrigger="click"
      ></nz-avatar>
    </nz-col>
    <nz-col nzSpan="21">
      <small>{{ message?.auteur?.displayName }}</small>
      <nz-comment
        [nzDatetime]="message.date | dateFr: 'distanceSuffix'"
        [ngStyle]="{
          backgroundColor:
            user.id === message.auteur.id ? eventBlue : eventGrey,
          color: user.id === message.auteur.id ? 'white' : 'black'
        }"
        [ngClass]="{
          right: user.id === message.auteur.id,
          left: user.id !== message.auteur.id
        }"
      >
        <nz-comment-content>
          {{ message.text }}
        </nz-comment-content>
      </nz-comment>
    </nz-col>
    <nz-col nzSpan="3" *ngIf="message?.auteur?.id === user.id">
      <nz-avatar
        [nzText]="message.auteur.avatar"
        [ngStyle]="{
          'background-color': message?.auteur?.setting?.color
            ? message.auteur.setting.color
            : '#bfbfbf',
          color: 'white'
        }"
        nz-tooltip
        [nzTooltipMouseEnterDelay]="1"
        nzTooltipPlacement="bottom"
        [nzTooltipTitle]="message.auteur.displayName"
        [nzTooltipMouseLeaveDelay]="0.1"
        nzTooltipTrigger="click"
      ></nz-avatar>
    </nz-col>
  </nz-row>
</ng-container>
