import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import memo from 'memo-decorator';

@Pipe({
  name: 'stringhtml',
})
export class StringhtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}
  @memo()
  transform(value: any, args?: any): any {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}
