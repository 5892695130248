import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  objectivesFeatureKey,
  ObjectifState,
  selectAll,
} from './objectif.reducer';

export const selecObectifState =
  createFeatureSelector<ObjectifState>(objectivesFeatureKey);

export const selectParticipantUtilities = createSelector(
  selecObectifState,
  (state: ObjectifState) => state.participantUtilities
);

export const selectError = createSelector(
  selecObectifState,
  (state: ObjectifState) => state.error
);
