import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-utilities-tags',
  templateUrl: './utilities-tags.component.html',
  styleUrls: ['./utilities-tags.component.scss'],
})
export class UtilitiesTagsComponent implements OnInit {
  @Input() utilityId: string = '';
  @Input() items: any = [];
  @Input() utility: any = null;
  @Input() variable: string = '';
  @Input() currentTag: string = '';
  @Output() select = new EventEmitter<any>(false);
  @Output() refresh = new EventEmitter<any>(false);
  constructor() {}

  ngOnInit(): void {}

  onSelect(item: any): void {
    this.select.emit(item);
    this.currentTag = item;
  }

  onRefresh(items: any): void {
    this.refresh.emit(items);
  }
}
