<div class="container" *ngIf="operation$ | async as operation">
  <i nz-icon nzType="loading" class="loading" *ngIf="loading$ | async"></i>
  <div [ngSwitch]="view$ | async">
    <div *ngSwitchCase="'list'">
      <ng-container *ngIf="participants$ | async | size; else noResults">
        <app-operation-participants-filters
          [operation$]="operation$"
          [participants$]="participants$"
        ></app-operation-participants-filters>
        <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
        <app-layout-list
          [card]="card"
          [new]="new"
          [source]="participants$ | async"
        ></app-layout-list>
      </ng-container>
    </div>
    <div *ngSwitchCase="'details'">
      <app-operation-participants-item
        (back)="onBack()"
      ></app-operation-participants-item>
    </div>
    <div *ngSwitchDefault>
      <app-layout-list
        *ngIf="participants$ | async | size; else noResults"
        [card]="card"
        [new]="new"
        [source]="participants$ | async"
      ></app-layout-list>
    </div>
  </div>

  <ng-template #new>
    <app-card-new
      [type]="'participant'"
      [title]="newCardTitle"
      [description]="''"
      [definition]="newCardDefinition"
      [width]="'100%'"
      [height]="'260px'"
      (add)="onNew(operation)"
      (back)="onBack()"
      (cancel)="onBack()"
    ></app-card-new>
  </ng-template>

  <ng-template #card let-item let-position="idx">
    <app-participant-card
      [participant]="item.participant"
      [isCoordonnate]="false"
      [isParticipantsPage]="false"
      (click)="onSelect(operation, item.participant)"
      (action)="onCardActions($event, item, operation)"
      [height]="'260px'"
    ></app-participant-card>
  </ng-template>

  <ng-template #noResults>
    <app-no-result
      [icon]="'people'"
      [addtitle]="newCardDescription"
      [notitle]="noModuleTitle"
      [description]="newCardDefinition"
      (new)="onNew(operation)"
    ></app-no-result>
  </ng-template>

  <ng-template #participantFormTitle>
    <app-header-title [icon]="'people'" [title]="title"></app-header-title>
  </ng-template>
</div>
