import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, of, Observable } from 'rxjs';
import { ParticipantId } from 'src/app/components/participant/store/participant.model';
import { ParticipantState } from 'src/app/components/participant/store/participant.reducer';
import * as fromParticipantSelector from 'src/app/components/participant/store/participant.selectors';

@Component({
  selector: 'app-participant-diagnostic-table',
  templateUrl: './participant-diagnostic-table.component.html',
  styleUrls: ['./participant-diagnostic-table.component.scss'],
})
export class ParticipantDiagnosticTableComponent implements OnInit {
  currenteCard$ = new BehaviorSubject<string>('professionnele');
  participant$: Observable<ParticipantId | any> = of(null);

  constructor(private participantStore: Store<ParticipantState>) {}

  ngOnInit(): void {
    this.getParticipant();
  }

  onSelectCard(type: string): void {
    this.currenteCard$.next(type);
  }

  getParticipant(): void {
    this.participant$ = this.participantStore.select(
      fromParticipantSelector.participant
    );
  }
}
