import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { TaskState } from '../../store/task.reducer';
import * as fromTaskSelector from '../../store/task.selectors';
import * as fromTaskAction from '../../store/task.actions';

@Component({
  selector: 'app-task-header',
  templateUrl: './task-header.component.html',
  styleUrls: ['./task-header.component.scss'],
})
export class TaskHeaderComponent implements OnInit, OnChanges {
  deleteAllType: string = 'Voulez-vous supprimer toutes les tâches ?';
  currentFilter: string = 'Tout';
  isFiltered$ = new BehaviorSubject<boolean>(false);
  @Input() isButton: boolean = false;
  @Input() icon: string = '';
  @Input() title: string = '';
  @Input() count: number = 0;
  @Input() filters$: Observable<string[]> = of([]);
  @Input() operation$: Observable<any> = of(null);
  @Input() user$: Observable<any> = of(null);
  @Input() taskSize: number = 0;

  @Output() add: EventEmitter<any> = new EventEmitter();
  @Output() action: EventEmitter<string> = new EventEmitter(false);
  @Output() deleteAll: EventEmitter<boolean> = new EventEmitter(false);

  constructor(private taskStore: Store<TaskState>) {}

  ngOnInit(): void {
    this.getTaskFilters();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  onNew(): void {
    this.add.emit(true);
  }

  getTaskFilters(): void {
    this.filters$ = this.taskStore.select(fromTaskSelector.filters);
  }

  onAction(event: string): void {
    this.action.emit(event);
    this.currentFilter = event;

    if (event === 'Tout') {
      this.isFiltered$.next(false);
      return;
    }
    this.isFiltered$.next(true);
    return;
  }

  onDeleteAll(): void {
    this.deleteAll.emit(true);
  }

  onCancel(): void {
    return;
  }
}
