import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  FormArray,
  Validators,
} from '@angular/forms';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { v4 as uuidV4 } from 'uuid';

@Component({
  selector: 'app-operation-cadrage-objectif-form',
  templateUrl: './operation-cadrage-objectif-form.component.html',
  styleUrls: ['./operation-cadrage-objectif-form.component.scss'],
})
export class OperationCadrageObjectifFormComponent implements OnInit {
  subscribe = new Subject();
  isAdd: boolean = true;
  isUpdate: boolean = false;
  today: Date = new Date(Date.now());

  @Input() updateItem$ = new BehaviorSubject<any>(null);
  @Output() add = new EventEmitter<any>(false);
  @Output() update = new EventEmitter<any>(false);
  @Output() cancel = new EventEmitter<boolean>(false);

  constructor() {}

  ngOnInit(): void {}
}
