import { Store } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';
import { AuthState } from '../../store/auth.reducer';
import * as fromAuthAction from '../../store/auth.actions';
@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.scss'],
})
export class NotfoundComponent implements OnInit {
  message: string = "Ooops! Cette page n'existe pas.";
  retourToLogin: string = "Revenir à l'authentification";

  constructor(private authStore: Store<AuthState>) {}

  ngOnInit(): void {}

  onBackToLogin(): void {
    this.authStore.dispatch(fromAuthAction.backToLogin());
  }
}
