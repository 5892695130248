import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NzButtonGroupSize } from 'ng-zorro-antd/button';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-buttons-profil-actions',
  templateUrl: './buttons-profil-actions.component.html',
  styleUrls: ['./buttons-profil-actions.component.scss'],
})
export class ButtonsProfilActionsComponent implements OnInit {
  @Input() loading$: Observable<boolean> = of(false);
  @Input() item: any = null;
  @Input() name: string = '';
  @Input() isExport: boolean = true;
  @Input() isUpdate: boolean = true;
  @Input() isDelete: boolean = true;
  @Input() isFavori: boolean = false;

  @Input() size: NzButtonGroupSize = 'default';

  @Output() update = new EventEmitter(false);
  @Output() export = new EventEmitter(false);
  @Output() delete = new EventEmitter(false);

  constructor() {}

  ngOnInit(): void {}
  onUpdate(item: any): void {
    this.update.emit(item);
  }
  onExport(item: any): void {
    this.export.emit(item);
  }
  onDelete(item: any): void {
    this.delete.emit(item);
  }
  onCancel(): void {}
}
