import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { OperationCadrage } from './../../store/operation-cadrage.model';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { of, Observable, BehaviorSubject } from 'rxjs';
import { Update } from '@ngrx/entity';
import { differenceInCalendarMonths, fromUnixTime } from 'date-fns';
import { Timestamp } from 'firebase/firestore';
import { OperationId } from 'src/app/components/operation/store/operation.model';

@Component({
  selector: 'app-operation-cadrage-description',
  templateUrl: './operation-cadrage-description.component.html',
  styles: [
    `
      nz-range-picker {
        width: 100%;
      }

      nz-form-control {
        border-radius: 12px;
        background-color: #f5f5f5;
      }
      mat-icon {
        color: gray;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationCadrageDescriptionComponent implements OnInit {
  editing$ = new BehaviorSubject<boolean>(false);
  editingDefinition$ = new BehaviorSubject<string>('');
  adresseUpdate$ = new BehaviorSubject<any>(null);
  adresseUpdateComplete$ = new BehaviorSubject<any>(null);

  format: string = 'dd/MM/yyyy';
  today: Date = new Date();
  cadrageForm: UntypedFormGroup = this.fb.group({});
  definition: UntypedFormControl = new UntypedFormControl('');
  adresse: UntypedFormControl = new UntypedFormControl('');
  adresseComplement: UntypedFormControl = new UntypedFormControl('');
  adresseQpv: UntypedFormControl = new UntypedFormControl('');

  dates: UntypedFormControl = new UntypedFormControl(
    [this.today, this.today],
    Validators.required
  );
  @Input() operation: OperationId | any;
  @Output() update: EventEmitter<Update<OperationCadrage>> = new EventEmitter(
    false
  );
  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.formCreate();
  }

  formCreate(): void {
    this.cadrageForm = this.fb.group({
      description: this.fb.group({
        definition: this.definition,
        dates: this.dates,
        adresse: this.adresse,
        complement: this.adresseComplement,
        adresseQpv: this.adresseQpv,
      }),
    });
  }

  on(event: any): void {
    this.cadrageForm.patchValue({ description: { definition: event } });
  }

  onModified(cadrage: OperationCadrage): void {
    this.editing$.next(true);

    this.editingDefinition$.next(cadrage.description.definition?.textValue);
    const start: Date = fromUnixTime(
      cadrage.description.calendrier.dateStart['seconds']
    );
    const end: Date = fromUnixTime(
      cadrage.description.calendrier.dateEnd['seconds']
    );
    this.cadrageForm.patchValue({
      description: {
        definition: cadrage.description.definition,
        dates: [start, end],
        adresse: cadrage.description?.adresse?.adresse
          ? cadrage.description?.adresse?.adresse
          : null,
        adresseComplement: cadrage.description?.adresse?.complement
          ? cadrage.description?.adresse?.complement
          : null,
        qpv: cadrage.description?.adresse?.qpv
          ? cadrage.description?.adresse?.qpv
          : null,
      },
    });
  }

  onUpdate(cadrage: OperationCadrage): void {
    if (!this.cadrageForm.valid) return;

    const description = this.cadrageForm.get('description')?.value;
    const { definition, dates, adresse, adresseComplement, adresseQpv } =
      description;
    const duration: number = differenceInCalendarMonths(dates[1], dates[0]);
    const nextCadre: Update<OperationCadrage> = {
      id: cadrage.id,
      changes: {
        ...cadrage,
        description: {
          ...cadrage?.description,
          definition: definition,
          calendrier: {
            ...cadrage.description?.calendrier,
            dateStart: Timestamp.fromDate(dates[0]),
            dateEnd: Timestamp.fromDate(dates[1]),
            duree: duration,
          },
          adresse: {
            adresse: adresse ? adresse : null,
            complement: adresseComplement ? adresseComplement : null,
            qpv: adresseQpv ? adresseQpv : null,
          },
        },
      },
    };

    this.update.emit(nextCadre);
    this.cadrageForm.reset();
    this.editing$.next(false);
  }
}
