import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { registerLocaleData } from '@angular/common';
import { NZ_I18N, en_US, en_GB, fr_FR } from 'ng-zorro-antd/i18n';
import { IconDefinition } from '@ant-design/icons-angular';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzCommentModule } from 'ng-zorro-antd/comment';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { NzSwitchModule } from 'ng-zorro-antd/switch';

import {
  NzIconModule,
  NZ_ICON_DEFAULT_TWOTONE_COLOR,
  NZ_ICONS,
} from 'ng-zorro-antd/icon';

import { NzMessageModule } from 'ng-zorro-antd/message';
import {
  AlertFill,
  AlertOutline,
  AppstoreOutline,
  WifiOutline,
  CalculatorOutline,
  FileDoneOutline,
  ProjectOutline,
  ReconciliationOutline,
  ApartmentOutline,
  AuditOutline,
  BranchesOutline,
  ClusterOutline,
  DisconnectOutline,
  DeploymentUnitOutline,
  SolutionOutline,
  FormOutline,
  SettingOutline,
  TeamOutline,
  BankOutline,
  ContainerOutline,
  DeleteOutline,
  CheckOutline,
  NotificationOutline,
  BookOutline,
  PoweroffOutline,
  SearchOutline,
  EditOutline,
  FilterOutline,
  SaveOutline,
  CloseSquareOutline,
  EyeInvisibleOutline,
  EyeOutline,
  ArrowUpOutline,
  LockOutline,
  CopyOutline,
  EnvironmentOutline,
  CloudUploadOutline,
  MailOutline,
  PhoneOutline,
  MenuFoldOutline,
  MenuUnfoldOutline,
  FileAddOutline,
  BarChartOutline,
  ContactsOutline,
  UserOutline,
  MessageOutline,
  BugOutline,
  FlagOutline,
  FieldTimeOutline,
  FieldStringOutline,
  FieldNumberOutline,
  UserAddOutline,
  DownloadOutline,
  PlusCircleOutline,
  PieChartOutline,
  ProfileOutline,
  FileTextOutline,
  FilePdfOutline,
  FileExcelOutline,
  FileWordOutline,
  FilePptOutline,
  HomeOutline,
  MobileOutline,
  SisternodeOutline,
  SendOutline,
  MinusCircleOutline,
  MoreOutline,
  GlobalOutline,
  DesktopOutline,
  FullscreenOutline,
  FileJpgOutline,
  LeftOutline,
  RightOutline,
  FullscreenExitOutline,
  ShareAltOutline,
  LinkedinOutline,
  FacebookOutline,
  InstagramOutline,
  TwitterOutline,
  EuroCircleOutline,
  ExportOutline,
  AppstoreAddOutline,
  BlockOutline,
  UserSwitchOutline,
  UserDeleteOutline,
  ControlOutline,
  PlusOutline,
  FlagFill,
  FilterFill,
  StarOutline,
  StarFill,
  ClearOutline,
  FileImageOutline,
  IssuesCloseOutline,
  SafetyOutline,
  SecurityScanOutline,
  UsergroupAddOutline,
  UnorderedListOutline,
  InfoCircleOutline,
  ReloadOutline,
  ArrowRightOutline,
  AlignLeftOutline,
  OrderedListOutline,
  PictureOutline,
  FileOutline,
  TableOutline,
  SyncOutline,
  PercentageOutline,
  CloseOutline,
  AimOutline,
  ArrowDownOutline,
  VerticalAlignMiddleOutline,
  FileUnknownOutline,
  DownCircleOutline,
  MinusOutline,
  PauseOutline,
  LineChartOutline,
  CompassOutline,
  ArrowLeftOutline,
  NumberOutline,
  InfoOutline,
  BackwardOutline,
} from '@ant-design/icons-angular/icons';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzCascaderModule } from 'ng-zorro-antd/cascader';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTransferModule } from 'ng-zorro-antd/transfer';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzStepsModule } from 'ng-zorro-antd/steps';

const icons: IconDefinition[] = [
  AlertFill,
  AlertOutline,
  AppstoreOutline,
  WifiOutline,
  CalculatorOutline,
  FileDoneOutline,
  ProjectOutline,
  ReconciliationOutline,
  ApartmentOutline,
  AuditOutline,
  BranchesOutline,
  ClusterOutline,
  DisconnectOutline,
  DeploymentUnitOutline,
  SolutionOutline,
  FormOutline,
  SettingOutline,
  TeamOutline,
  BankOutline,
  ContainerOutline,
  DeleteOutline,
  CheckOutline,
  NotificationOutline,
  BookOutline,
  PoweroffOutline,
  SearchOutline,
  EditOutline,
  FilterOutline,
  SaveOutline,
  CloseSquareOutline,
  EyeInvisibleOutline,
  EyeOutline,
  ArrowUpOutline,
  LockOutline,
  CopyOutline,
  EnvironmentOutline,
  CloudUploadOutline,
  MailOutline,
  PhoneOutline,
  MenuFoldOutline,
  MenuUnfoldOutline,
  FileAddOutline,
  BarChartOutline,
  ContactsOutline,
  UserOutline,
  MessageOutline,
  BugOutline,
  FlagOutline,
  FieldTimeOutline,
  FieldStringOutline,
  FieldNumberOutline,
  UserAddOutline,
  DownloadOutline,
  PlusCircleOutline,
  PieChartOutline,
  ProfileOutline,
  FileTextOutline,
  FilePdfOutline,
  FileExcelOutline,
  FileWordOutline,
  FilePptOutline,
  HomeOutline,
  MobileOutline,
  SisternodeOutline,
  SendOutline,
  MinusCircleOutline,
  MoreOutline,
  GlobalOutline,
  DesktopOutline,
  FullscreenOutline,
  FileJpgOutline,
  LeftOutline,
  RightOutline,
  FullscreenExitOutline,
  ShareAltOutline,
  LinkedinOutline,
  FacebookOutline,
  InstagramOutline,
  TwitterOutline,
  EuroCircleOutline,
  ExportOutline,
  AppstoreAddOutline,
  BlockOutline,
  UserSwitchOutline,
  UserDeleteOutline,
  ControlOutline,
  PlusOutline,
  FlagFill,
  FilterFill,
  StarOutline,
  StarFill,
  ClearOutline,
  FileImageOutline,
  IssuesCloseOutline,
  SafetyOutline,
  SecurityScanOutline,
  UsergroupAddOutline,
  UnorderedListOutline,
  InfoCircleOutline,
  ReloadOutline,
  ArrowRightOutline,
  AlignLeftOutline,
  OrderedListOutline,
  PictureOutline,
  FileOutline,
  TableOutline,
  SyncOutline,
  PercentageOutline,
  CloseOutline,
  AimOutline,
  ArrowDownOutline,
  VerticalAlignMiddleOutline,
  FileUnknownOutline,
  DownCircleOutline,
  MinusOutline,
  PauseOutline,
  LineChartOutline,
  CompassOutline,
  ArrowLeftOutline,
  NumberOutline,
  InfoOutline,
  BackwardOutline,
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NzNotificationModule,
    NzIconModule,
    NzTableModule,
    NzMessageModule,
    NzSpaceModule,
    NzDrawerModule,
    NzStepsModule,
    NzModalModule,
    NzListModule,
    NzResultModule,
    NzDatePickerModule,
    NzLayoutModule,
    NzCommentModule,
    NzDescriptionsModule,
    NzEmptyModule,
    NzTagModule,
    NzDividerModule,
    NzBadgeModule,
    NzCardModule,
    NzButtonModule,
    NzFormModule,
    NzTabsModule,
    NzInputModule,
    NzInputNumberModule,
    NzCollapseModule,
    NzSelectModule,
    NzAvatarModule,
    NzCalendarModule,
    NzGridModule,
    NzTimePickerModule,
    NzCascaderModule,
    NzProgressModule,
    NzCheckboxModule,
    NzDropDownModule,
    NzStatisticModule,
    NzTimelineModule,
    NzToolTipModule,
    NzSpinModule,
    NzTransferModule,
    NzBreadCrumbModule,
    NzPopoverModule,
    NzPopconfirmModule,
    NzPageHeaderModule,
    NzRadioModule,
    NzSkeletonModule,
    NzSwitchModule,
    NzTypographyModule,
  ],
  providers: [
    { provide: NZ_I18N, useValue: fr_FR },
    { provide: NZ_ICONS, useValue: icons },
  ],
  exports: [
    NzNotificationModule,
    NzIconModule,
    NzTableModule,
    NzMessageModule,
    NzSpaceModule,
    NzDrawerModule,
    NzModalModule,
    NzStepsModule,
    NzListModule,
    NzResultModule,
    NzDatePickerModule,
    NzLayoutModule,
    NzCommentModule,
    NzDescriptionsModule,
    NzEmptyModule,
    NzRadioModule,
    NzTagModule,
    NzDividerModule,
    NzBadgeModule,
    NzCardModule,
    NzButtonModule,
    NzFormModule,
    NzTabsModule,
    NzInputModule,
    NzInputNumberModule,
    NzCollapseModule,
    NzSelectModule,
    NzAvatarModule,
    NzCalendarModule,
    NzGridModule,
    NzTimePickerModule,
    NzCascaderModule,
    NzProgressModule,
    NzCheckboxModule,
    NzDropDownModule,
    NzStatisticModule,
    NzTimelineModule,
    NzToolTipModule,
    NzSpinModule,
    NzTransferModule,
    NzBreadCrumbModule,
    NzPopoverModule,
    NzPopconfirmModule,
    NzPageHeaderModule,
    NzSkeletonModule,
    NzSwitchModule,
    NzTypographyModule,
  ],
})
export class ZorroModule {}
