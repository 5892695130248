<app-layout-list
  *ngIf="
    (data$ | async).participants.length || (participants$ | async) | size;
    else noResults
  "
  [new]="new"
  [card]="card"
  [source]="(data$ | async)?.participants"
></app-layout-list>

<ng-template #new>
  <app-card-new
    [type]="'participant'"
    [title]="newCardTitle"
    [height]="'250px'"
    [description]="''"
    [definition]="newCardDefinition"
    (add)="onParticipantForm()"
  ></app-card-new>
</ng-template>

<ng-template #card let-item let-favoris let-position="idx">
  <app-participant-card
    [participant]="item"
    [favoris]="favoris$ | async"
  ></app-participant-card>
</ng-template>

<ng-template #noResults>
  <app-no-result
    [icon]="'supervisor_account'"
    [addtitle]="'Ajouter un participant'"
    [notitle]="'Aucun participant'"
    [description]="newCardDefinition"
    (new)="onParticipantForm()"
  ></app-no-result>
</ng-template>

<ng-template #participantTitleForm>
  <app-header-title [icon]="'person'" [title]="title"></app-header-title>
</ng-template>
