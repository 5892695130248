import { NzMessageService } from 'ng-zorro-antd/message';
import { Injectable } from '@angular/core';
import { OperationNoteSujetService } from 'src/app/service/operation-note-sujet.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, timer, concatMap, delay } from 'rxjs';
import {
  tap,
  mergeMap,
  exhaustMap,
  map,
  catchError,
  distinctUntilChanged,
} from 'rxjs/operators';
import * as fromOperationNoteSujetAction from './operation-note-sujet.actions';
import { OperationNoteSujetId } from './operation-note-sujet.model';
import {
  NzNotificationPlacement,
  NzNotificationService,
} from 'ng-zorro-antd/notification';

const NOTIFICATION_PLACEMENT: NzNotificationPlacement = 'topRight';

@Injectable()
export class OperationNoteSujetEffects {
  source = of(1, 2, 3);

  //LOAD ALL
  loadOperationNoteSujets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationNoteSujetAction.loadOperationNoteSujets),
      mergeMap((action) =>
        this.operationNoteSujetService.getAll(action.subtitle).pipe(
          delay(2000),
          map((sujets: OperationNoteSujetId[]) =>
            fromOperationNoteSujetAction.loadOperationNoteSujetsSuccess({
              sujets,
            })
          ),
          catchError((error) => {
            this.message.error(error, { nzDuration: 6000 });

            return of(
              fromOperationNoteSujetAction.loadOperationNoteSujetsFailure({
                error,
              })
            );
          })
        )
      )
    )
  );

  //LOAD ALL BY NOTE
  loadOperationNoteSujetsByNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationNoteSujetAction.loadOperationNoteSujetsByNote),
      mergeMap((action) =>
        this.operationNoteSujetService.getAllByNote(action.note).pipe(
          delay(2000),
          map((sujets: OperationNoteSujetId[]) =>
            fromOperationNoteSujetAction.loadOperationNoteSujetsByNoteSuccess({
              sujets,
            })
          ),
          catchError((error) => {
            this.message.error(error, { nzDuration: 6000 });

            return of(
              fromOperationNoteSujetAction.loadOperationNoteSujetsByNoteFailure(
                {
                  error,
                }
              )
            );
          })
        )
      )
    )
  );

  //LOAD SUJET WARNING
  loadOperationNoteSujetsWarning$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationNoteSujetAction.loadOperationNoteSujetsWarning),
      mergeMap((action) =>
        this.operationNoteSujetService
          .getWarningSujets(action.id, action.noteId)
          .pipe(
            delay(2000),
            map((repons: any) => {
              const snap = repons.data.operationNoteSujetsAlert;
              return fromOperationNoteSujetAction.loadOperationNoteSujetsWarningSuccess(
                {
                  snap,
                }
              );
            }),
            catchError((error) => {
              this.onErrorNotification(
                error,
                'LOAD_WARNING_OPERATION_NOTE_SUJETS'
              );
              return of(
                fromOperationNoteSujetAction.loadOperationNoteSujetsWarningFailure(
                  {
                    error,
                  }
                )
              );
            })
          )
      )
    )
  );

  //ADD
  addOperationNoteSujet$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromOperationNoteSujetAction.addOperationNoteSujet),
        tap(() => {
          this.notification.info(
            'Création du sujet',
            'Création du sujet en cours...',
            {
              nzDuration: 6000,
              nzAnimate: true,
              nzPlacement: NOTIFICATION_PLACEMENT,
              nzKey: 'ADD_ONE_OPERATION_NOTE_SUJET',
            }
          );
          fromOperationNoteSujetAction.loadingOperationNoteSujets({
            loading: true,
          });
        }),
        mergeMap((action) =>
          this.operationNoteSujetService.addOne(action.note, action.sujet).pipe(
            map((success: string) => {
              this.notification.success('Création du sujet', success, {
                nzDuration: 8000,
                nzAnimate: true,
                nzPlacement: NOTIFICATION_PLACEMENT,
                nzKey: 'ADD_ONE_OPERATION_NOTE_SUJET',
              });
              return fromOperationNoteSujetAction.addOperationNoteSujetSuccess({
                success,
              });
            }),
            catchError((error) => {
              this.onErrorNotification(error, 'ADD_ONE_OPERATION_NOTE_SUJET');

              return of(
                fromOperationNoteSujetAction.addOperationNoteSujetFailure({
                  error,
                })
              );
            })
          )
        ),
        tap(() =>
          fromOperationNoteSujetAction.loadingOperationNoteSujets({
            loading: false,
          })
        )
      ),
    { dispatch: false }
  );

  //UPDATE
  updateOperationNoteSujet$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromOperationNoteSujetAction.updateOperationNoteSujet),
        tap(() => {
          this.notification.info(
            'Modification du sujet',
            'Modification du sujet en cours...',
            {
              nzDuration: 6000,
              nzAnimate: true,
              nzPlacement: NOTIFICATION_PLACEMENT,
              nzKey: 'UPDATE_ONE_OPERATION_NOTE_SUJET',
            }
          );
          fromOperationNoteSujetAction.loadingOperationNoteSujets({
            loading: true,
          });
        }),
        mergeMap((action) =>
          this.operationNoteSujetService
            .updateOne(action.note, action.sujet)
            .pipe(
              map((success: any) => {
                this.notification.success('Modification du sujet', success, {
                  nzDuration: 8000,
                  nzAnimate: true,
                  nzPlacement: NOTIFICATION_PLACEMENT,
                  nzKey: 'UPDATE_ONE_OPERATION_NOTE_SUJET',
                });
                return fromOperationNoteSujetAction.updateOperationNoteSujetSuccess(
                  {
                    success,
                  }
                );
              }),
              catchError((error) => {
                this.onErrorNotification(
                  error,
                  'UPDATE_ONE_OPERATION_NOTE_SUJET'
                );

                return of(
                  fromOperationNoteSujetAction.updateOperationNoteSujetFailure({
                    error,
                  })
                );
              })
            )
        ),
        tap(() =>
          fromOperationNoteSujetAction.loadingOperationNoteSujets({
            loading: false,
          })
        )
      ),
    { dispatch: false }
  );

  //DELETE
  deleteOperationNoteSujet$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromOperationNoteSujetAction.deleteOperationNoteSujet),
        tap(() => {
          this.notification.info(
            'Suppression de sujet',
            'Suppression du sujet en cours...',
            {
              nzDuration: 6000,
              nzAnimate: true,
              nzPlacement: NOTIFICATION_PLACEMENT,
              nzKey: 'DELETE_ONE_OPERATION_NOTE_SUJET',
            }
          );
          fromOperationNoteSujetAction.loadingOperationNoteSujets({
            loading: true,
          });
        }),
        mergeMap((action) =>
          this.operationNoteSujetService.deleteOne(action.note, action.id).pipe(
            map((success: any) => {
              this.message.success(success, { nzDuration: 6000 });
              this.notification.success('Suppression de sujet', success, {
                nzDuration: 8000,
                nzAnimate: true,
                nzPlacement: NOTIFICATION_PLACEMENT,
                nzKey: 'DELETE_ONE_OPERATION_NOTE_SUJET',
              });
              return fromOperationNoteSujetAction.deleteOperationNoteSujetSuccess(
                {
                  success,
                }
              );
            }),
            catchError((error) => {
              this.onErrorNotification(
                error,
                'DELETE_ONE_OPERATION_NOTE_SUJET'
              );

              return of(
                fromOperationNoteSujetAction.deleteOperationNoteSujetFailure({
                  error,
                })
              );
            })
          )
        ),
        tap(() =>
          fromOperationNoteSujetAction.loadingOperationNoteSujets({
            loading: false,
          })
        )
      ),
    { dispatch: false }
  );

  //DELETE ALL
  deleteAllOperationNoteSujets$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromOperationNoteSujetAction.deleteAllOperationNoteSujets),
        tap(() => {
          this.notification.info(
            'Suppression des sujets',
            'Suppression des sujets du titre',
            {
              nzDuration: 6000,
              nzAnimate: true,
              nzPlacement: NOTIFICATION_PLACEMENT,
              nzKey: 'DELETE_ALL_OPERATION_NOTE_SUJETS',
            }
          );
          fromOperationNoteSujetAction.loadingOperationNoteSujets({
            loading: true,
          });
        }),
        mergeMap((action) =>
          this.operationNoteSujetService
            .deleteAll(action.note, action.subtitleId)
            .pipe(
              map((respons: any) => {
                const { data } = respons;
                const success = data.deleteAllOperationNoteSujets;

                this.notification.success('Suppression des sujets', success, {
                  nzDuration: 8000,
                  nzAnimate: true,
                  nzPlacement: NOTIFICATION_PLACEMENT,
                  nzKey: 'DELETE_ALL_OPERATION_NOTE_SUJETS',
                });

                return fromOperationNoteSujetAction.deleteAllOperationNoteSujetsSuccess(
                  {
                    success,
                  }
                );
              }),
              catchError((error) => {
                this.onErrorNotification(
                  error,
                  'DELETE_ALL_OPERATION_NOTE_SUJETS'
                );
                return of(
                  fromOperationNoteSujetAction.deleteAllOperationNoteSujetsFailure(
                    {
                      error,
                    }
                  )
                );
              })
            )
        ),
        tap(() =>
          fromOperationNoteSujetAction.loadingOperationNoteSujets({
            loading: false,
          })
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private message: NzMessageService,
    private operationNoteSujetService: OperationNoteSujetService,
    private notification: NzNotificationService
  ) {}

  onErrorNotification(error: any, key: string): void {
    this.notification.error('Error', error.message, {
      nzDuration: 10000,
      nzAnimate: true,
      nzPlacement: NOTIFICATION_PLACEMENT,
      nzKey: key ? key : '',
    });
  }
}
