import { QueryEntitesService } from './../../../../service/query-entites.service';
import { EntiteService } from './../../../../service/entite.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromEntiteSelectAction from './entite-select.actions';
import { EntiteSMALLid } from 'src/app/components/entite/store/entite.model';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class EntiteSelectEffects {
  queryEntites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromEntiteSelectAction.loadEntiteSelects),
      mergeMap((action) =>
        this.queryEntitesService
          .queryEntites(action.filter, action.filterType)
          .pipe(
            map(
              (entites: EntiteSMALLid[]) =>
                fromEntiteSelectAction.loadEntiteSelectsSuccess({
                  entites,
                }),
              catchError((error) =>
                of(fromEntiteSelectAction.loadEntiteSelectsFailure({ error }))
              )
            )
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private queryEntitesService: QueryEntitesService
  ) {}
}
