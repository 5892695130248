import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'carLimitation'
})
export class CarLimitationPipe implements PipeTransform {

  number: number = 25
  transform(value: string, args?: number): any {
    if (!args && value && value.length > this.number ){
      const newValue: string = `${value.substr(0, this.number)}...`
      return newValue

    }
    else if (args && value && value.length > args){
      const newValue: string = `${value.substr(0, args)}...`
      return newValue
    }
    return value;
  }

}
