import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-participant-planning-export-validation',
  templateUrl: './participant-planning-export-validation.component.html',
  styleUrls: ['./participant-planning-export-validation.component.scss'],
})
export class ParticipantPlanningExportValidationComponent implements OnInit {
  @Output() cancel = new EventEmitter<boolean>();
  @Output() validation = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  onValidation(event: string): void {
    this.validation.emit(event);
  }

  onCancel(): void {
    this.cancel.emit(true);
  }
}
