<form nz-form [formGroup]="numeriqueForm" nzLayout="vertical">
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="12">
      <nz-form-item>
        <nz-form-label [nzSpan]="24">Niveau numérique </nz-form-label>
        <nz-form-control>
          <nz-select
            nzBorderless
            nzPlaceHolder="Autonomie numérique"
            formControlName="numerique_level"
            nzServerSearch
            nzMode="tags"
            [nzMaxTagCount]="2"
            [compareWith]="compareNumericLevel"
            [nzLoading]="((numerique$ | async)?.data | size) ? false : true"
          >
            <nz-option-group
              *ngFor="let group of (numerique$ | async)?.data"
              [nzLabel]="group.type"
            >
              <nz-option
                *ngFor="let item of group?.items"
                [nzLabel]="item.item"
                [nzValue]="item"
              >
                {{ item.item }}
              </nz-option>
            </nz-option-group>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col nzSpan="12">
      <nz-form-item>
        <nz-form-label [nzSpan]="24">Niveau bureautique</nz-form-label>
        <nz-form-control>
          <nz-select
            nzPlaceHolder="Outil bureautique"
            nzBorderless
            formControlName="bureautique_level"
            nzServerSearch
            nzMode="tags"
            [nzMaxTagCount]="2"
            [compareWith]="compareBureautiqueLevel"
            [nzLoading]="(bureautique$ | async | size) ? false : true"
          >
            <nz-option
              *ngFor="let item of bureautique$ | async"
              [nzLabel]="item"
              [nzValue]="item"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>
  <nz-space [nzSize]="'small'"></nz-space>

  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="8">
      <app-check-card
        [title]="'Dispose d\'un mobile'"
        [isActive]="numeriqueForm.value.mobile"
        [definition]="checkboxDefinitionMobile"
        [height]="'100px'"
        [fontSize]="'14px'"
        (select)="checkboxChanges($event, 'mobile')"
      ></app-check-card>
    </nz-col>
    <nz-col nzSpan="8">
      <app-check-card
        [title]="'Dispose d\'un ordinateur'"
        [isActive]="numeriqueForm.value.ordinateur"
        [definition]="checkboxDefinitionOrdinateur"
        [height]="'100px'"
        [fontSize]="'14px'"
        (select)="checkboxChanges($event, 'ordinateur')"
      ></app-check-card>
    </nz-col>
    <nz-col nzSpan="8">
      <app-check-card
        [title]="'Accès internet'"
        [isActive]="numeriqueForm.value.internet"
        [definition]="checkboxDefinitionInternet"
        (select)="checkboxChanges($event, 'internet')"
        [height]="'100px'"
        [fontSize]="'14px'"
      ></app-check-card>
    </nz-col>
  </nz-row>
  <nz-space [nzSize]="'small'"></nz-space>

  <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="24">
      <nz-form-item>
        <nz-form-control>
          <label nz-checkbox formControlName="isFrein">{{
            isFreinTitle
          }}</label>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>
</form>
