import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, of } from 'rxjs';
import { ContactId } from '../components/contact/store/contact.model';
import { ContactState } from '../components/contact/store/contact.reducer';
import * as fromContactAction from '../components/contact/store/contact.actions';
import * as fromContactSelector from '../components/contact/store/contact.selectors';

@Injectable({
  providedIn: 'any',
})
export class ContactResolverService {
  constructor(
    private contactStore: Store<ContactState>,
    private router: Router
  ) {}

  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<ContactId> | Promise<ContactId | any> | ContactId | any {
    let id = route.params['id'];
    let find = this.loadContact(id).toPromise();

    return find
      .then(() => {
        return this.getContact();
      })
      .catch(() => {
        this.router.navigate(['/contacts']);
        return EMPTY;
      });
  }

  getContact(): Observable<ContactId[] | any> {
    return this.contactStore.select(fromContactSelector.contact);
  }

  loadContact(id: string): Observable<any> {
    return of(
      this.contactStore.dispatch(fromContactAction.loadContact({ id }))
    );
  }
}
