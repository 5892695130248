import { SharedModule } from './../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromOperationContacts from './store/operation-contacts.reducer';
import { EffectsModule } from '@ngrx/effects';
import { OperationContactsEffects } from './store/operation-contacts.effects';
import { OperationContactsItemComponent } from './components/operation-contacts-item/operation-contacts-item.component';
import { OperationContactsSelectorsComponent } from './components/operation-contacts-selectors/operation-contacts-selectors.component';
import { OperationContactsFiltersComponent } from './components/operation-contacts-filters/operation-contacts-filters.component';
import { OperationContactsComponent } from './components/operation-contacts/operation-contacts.component';
import { OperationContactsService } from 'src/app/service/operation-contacts.service';
import { ContentsModule } from 'src/app/contents/contents.module';
import { ContactModule } from '../contact/contact.module';
import { NoResultModule } from 'src/app/contents/components/no-result/no-result.module';
import { ButtonsModule } from 'src/app/contents/components/buttons/buttons.module';
import { HeaderModule } from 'src/app/contents/components/header-title/header.module';
import { LayoutModule } from 'src/app/contents/components/layout/layout.module';
import { UiModule } from 'src/app/ui/ui/ui.module';

@NgModule({
  declarations: [
    OperationContactsComponent,
    OperationContactsFiltersComponent,
    OperationContactsItemComponent,
    OperationContactsSelectorsComponent,
  ],
  imports: [
    CommonModule,
    ContentsModule,
    ContactModule,
    SharedModule,
    NoResultModule,
    HeaderModule,
    ButtonsModule,
    LayoutModule,
    UiModule,
    StoreModule.forFeature(
      fromOperationContacts.operationContactsFeatureKey,
      fromOperationContacts.reducer
    ),
    EffectsModule.forFeature([OperationContactsEffects]),
  ],
  exports: [
    OperationContactsComponent,
    OperationContactsFiltersComponent,
    OperationContactsItemComponent,
    OperationContactsSelectorsComponent,
  ],
  providers: [OperationContactsService],
})
export class OperationContactsModule {}
