import { Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  doc,
  docSnapshots,
  collectionChanges,
  getDoc,
  addDoc,
  setDoc,
  deleteDoc,
  serverTimestamp,
  DocumentData,
  Timestamp,
  query,
  where,
  arrayUnion,
  arrayRemove,
  collectionSnapshots,
  CollectionReference,
  DocumentReference,
  getDocs,
  documentId,
} from '@angular/fire/firestore';
import { Observable, of, from, EMPTY } from 'rxjs';
import { switchMap, mergeMap, map, catchError } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Functions, httpsCallable } from '@angular/fire/functions';
import {
  Storage,
  ref,
  uploadBytesResumable,
  deleteObject,
  getDownloadURL,
  StorageReference,
  UploadTask,
  UploadMetadata,
} from '@angular/fire/storage';
import { UserState } from '../components/user/store/user.reducer';
import * as fromUserSelector from '../components/user/store/user.selectors';
import { v4 as uuidV4 } from 'uuid';
import { EntiteState } from './../components/entite/store/entite.reducer';
import * as fromEntiteAction from './../components/entite/store/entite.actions';
import * as fromEntiteSelector from './../components/entite/store/entite.selectors';

import { EntiteId } from './../components/entite/store/entite.model';

@Injectable({
  providedIn: 'any',
})
export class EntiteStorageService {
  constructor(
    private db: Firestore,
    private fns: Functions,
    private userStore: Store<UserState>,
    private storage: Storage,
    private entiteStore: Store<EntiteState>
  ) {}

  getAll(entiteId: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) {
            return of([]);
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/entites/${entiteId}/documents`
            );

            const changes = collectionSnapshots(dbCollection).pipe(
              map((changes) => {
                return changes.map((snap) => {
                  const data = snap.data();
                  const id = snap.id;
                  return { id, ...data };
                });
              })
            );

            return changes;
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  getOne(entiteId: string, id: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) {
            return of(null);
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/entites/${entiteId}/documents`
            );

            const documentRef = doc(dbCollection, id);
            return from(
              getDoc(documentRef)
                .then((snap) => {
                  const data = snap.data();
                  const id = snap.id;
                  return { id, ...data };
                })
                .catch((error) => error)
            ).pipe(mergeMap((res: any) => of(res)));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  addFiles(entite: EntiteId, documents: any[]): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !documents?.length) {
            return of(null);
          } else {
            documents.forEach((document) => {
              const { file, doc } = document;
              const extension: string = `${file.name.split('.').pop()}`;
              const storagePah: string = `organisations/${user.organisation.id}/entites/${entite.id}/documents/${file.name}`;
              const storageRef: StorageReference = ref(
                this.storage,
                storagePah
              );
              const storageMetadata: UploadMetadata = {
                contentType: file.type,
                contentEncoding: '',
                contentLanguage: 'fr',
                cacheControl: '',
                customMetadata: {
                  entiteId: entite.id,
                  organisationId: user.organisation.id,
                  name: file.name,
                  extension: extension,
                  date: new Date(Date.now()).toDateString(),
                },
                md5Hash: '',
              };

              const task: UploadTask = uploadBytesResumable(
                storageRef,
                file,
                storageMetadata
              );
              task.on(
                'state_changed',
                (snap) => {
                  let progress =
                    (snap.bytesTransferred / snap.totalBytes) * 100;
                  this.entiteStore.dispatch(
                    fromEntiteAction.loadFileProgress({ progress })
                  );
                },
                (error) => {
                  this.entiteStore.dispatch(
                    fromEntiteAction.addDocumentsFailure({ error })
                  );
                  this.entiteStore.dispatch(
                    fromEntiteAction.loadFileProgress({ progress: 0 })
                  );
                },
                () => {
                  getDownloadURL(task.snapshot.ref)
                    .then((downloadURL) => {
                      const documentFile = {
                        ...doc,
                        access: {
                          [`${user.id}`]: true,
                        },
                        auteur: user,
                        auths: [user.id],
                        correspondanceId: entite.id,
                        organisation: user.organisation,
                        fileRef: downloadURL,
                        operationId: entite.id,
                        dateStart: Timestamp.now(),
                        file: {
                          name: file.name,
                          path: storagePah,
                          fileName: file.name,
                          size: file.size,
                          extension: file.name.split('.').pop(),
                        },
                      };

                      const documentRef = collection(
                        this.db,
                        `organisations/${user.organisation.id}/entites/${entite.id}/documents`
                      );
                      const nextDocument = {
                        ...documentFile,
                        dateUpdate: serverTimestamp(),
                        userUpdate: user,
                      };
                      return from(
                        addDoc(documentRef, nextDocument)
                          .then((docRef) => {
                            if (docRef?.id) {
                              this.getOne(entite.id, docRef?.id);
                            } else {
                            }
                          })
                          .then(() => {
                            this.entiteStore.dispatch(
                              fromEntiteAction.loadFileProgress({
                                progress: 0,
                              })
                            );
                            return null;
                          })
                          .catch((error) => of(error))
                      );
                    })
                    .then(() => {
                      this.entiteStore.dispatch(
                        fromEntiteAction.loadDocuments({
                          entiteId: entite.id,
                        })
                      );
                    });
                }
              );
              return of(null);
            });

            return of(null);
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  deleteOne(entiteId: string, document: any): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !entiteId || !document) {
            return of(null);
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/entites/${entiteId}/documents/`
            );

            const dbDocument = doc(dbCollection, document.id);

            return from(
              deleteDoc(dbDocument)
                .then(() => of(`Document supprimé.`))
                .catch((error) => of(error))
            ).pipe(mergeMap((res) => res));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  deleteAll(): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) {
            return of(null);
          } else {
            const data = { id: user.id };
            const call = httpsCallable(this.fns, 'entite-deleteAllDocuments');
            return from(
              call(data)
                .then((rest) => rest)
                .catch((err) => err)
            ).pipe(
              mergeMap((res) => {
                return of(res);
              })
            );
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }
}
