<nz-row nzAlign="top" nzGutter="8" nzJustify="start">
  <nz-col nzSpan="6">
    <div class="list-filter-header-Container">
      <ng-container *ngTemplateOutlet="tplLeft"></ng-container>
    </div>
  </nz-col>
  <nz-col nzSpan="12">
    <form nz-form [formGroup]="filterForm">
      <nz-form-item>
        <nz-form-control>
          <input
            nz-input
            formControlName="input"
            nzBorderless
            [placeholder]="placeholder"
          />
        </nz-form-control>
      </nz-form-item>
    </form>
  </nz-col>
  <nz-col nzSpan="6">
    <ng-container *ngIf="tplRigth; else filterRightTpl">
      <ng-container *ngTemplateOutlet="tplLeft"></ng-container>
    </ng-container>
  </nz-col>
</nz-row>

<ng-template #filterRightTpl>
  <nz-space nzDirection="horizontal" nzSize="large">
    <ng-container *ngIf="isSearch">
      <ng-container *ngIf="!filterForm.value?.input?.length">
        <button
          nz-button
          *nzSpaceItem
          nzShape="circle"
          nzType="text"
          (click)="onAction('on-search')"
        >
          <mat-icon>search</mat-icon>
        </button>
      </ng-container>
      <ng-container *ngIf="filterForm.value?.input?.length">
        <button
          nz-button
          *nzSpaceItem
          nzShape="circle"
          nzType="text"
          (click)="onAction('off-search')"
        >
          <mat-icon>clear</mat-icon>
        </button>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="isFilterBtn">
      <ng-container *ngIf="!isFilterBtnActive">
        <button
          nz-button
          *nzSpaceItem
          nzType="text"
          nzShape="circle"
          (click)="onAction('on-filter')"
        >
          <mat-icon>filter_list</mat-icon>
        </button>
      </ng-container>
      <ng-container *ngIf="isFilterBtnActive">
        <button
          nz-button
          *nzSpaceItem
          nzShape="circle"
          nzType="text"
          (click)="onAction('off-filter')"
        >
          <mat-icon>filter_list_off</mat-icon>
        </button>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="isDropdown">
      <app-button-dropdown
        *nzSpaceItem
        (select)="onAction($event)"
        [isUpdate]="isDropdownUpdate"
        [isDelete]="isDropdownDelete"
        [isDeleteAll]="isDropdownDeleteAll"
        [deleteAllType]="isDropdownDeleteTitle"
      ></app-button-dropdown>
    </ng-container>
  </nz-space>
</ng-template>
