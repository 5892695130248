<app-card-identity *ngIf="contact">
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col>
      <nz-avatar nzSize="default" nzIcon="mail"> </nz-avatar>
    </nz-col>
    <nz-col>
      <nz-tag class="tagCoordonnes">
        {{ contact?.coordonnees?.email | carLimitation: 25 }}
      </nz-tag>
    </nz-col>
  </nz-row>
  <nz-space [nzSize]="5"></nz-space>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col>
      <nz-avatar nzSize="default" nzIcon="mobile"> </nz-avatar>
    </nz-col>
    <nz-col>
      <nz-tag class="tagCoordonnes">
        {{ contact?.coordonnees?.mobile | phone }}
      </nz-tag>
    </nz-col>
  </nz-row>
  <nz-space [nzSize]="5"></nz-space>

  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col>
      <nz-avatar nzSize="default" nzIcon="phone"> </nz-avatar>
    </nz-col>
    <nz-col>
      <nz-tag class="tagCoordonnes">
        {{ contact?.coordonnees?.fixe | phone }}
      </nz-tag>
    </nz-col>
  </nz-row>

  <nz-divider class="identityDivider"></nz-divider>

  <nz-row nzAlign="middle" nzGutter="8" nzJustify="center">
    <nz-col nzSpan="4"></nz-col>

    <nz-col nzSpan="4">
      <button
        nz-button
        nzShape="circle"
        nz-tooltip
        [nzTooltipMouseEnterDelay]="0.5"
        nzTooltipTitle="Exporter"
      >
        <i nz-icon nzType="download"></i>
      </button>
    </nz-col>
    <nz-col nzSpan="4">
      <button
        nz-button
        nzShape="circle"
        nz-tooltip
        [nzTooltipMouseEnterDelay]="0.5"
        nzTooltipTitle="Modifier"
        (click)="onUpdate()"
      >
        <i nz-icon nzType="form"></i>
      </button>
    </nz-col>
    <nz-col nzSpan="4">
      <button
        *ngIf="contact"
        nz-button
        nzShape="circle"
        nz-tooltip
        [nzTooltipMouseEnterDelay]="0.5"
        nzTooltipTitle="Supprimer"
        nz-popconfirm
        [nzPopconfirmTitle]="
          'Voulez-vous supprimer ce projet ' +
          (contact?.displayName | carLimitation: 25) +
          ' ?'
        "
        nzPopconfirmPlacement="bottom"
        (nzOnConfirm)="onDelete(contact)"
        (nzOnCancel)="onCancel()"
      >
        <i nz-icon nzType="delete"></i>
      </button>
    </nz-col>
    <nz-col nzSpan="4"></nz-col>
  </nz-row>
</app-card-identity>
