import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';
import { UtilitiesState } from 'src/app/contents/components/utilities/store/utilities.reducer';
import * as fromUtilitiesSelector from 'src/app/contents/components/utilities/store/utilities.selectors';
import * as fromUtilitiesAction from 'src/app/contents/components/utilities/store/utilities.actions';

@Component({
  selector: 'app-participant-diagnostic-logement',
  templateUrl: './participant-diagnostic-logement.component.html',
  styleUrls: ['./participant-diagnostic-logement.component.scss'],
})
export class ParticipantDiagnosticLogementComponent
  implements OnInit, OnChanges, OnDestroy
{
  subscribe = new Subject();

  logementForm: FormGroup = this.fb.group({});

  //VARIABLES
  isFreinTitle: string = 'Le logement est-il un frein identifié ?';
  isQPVTitle: string = 'Quartier Politique de la Ville';
  isQVATitle: string = 'Quartier de Veille Active';
  isZRRTitle: string = 'Zone de Revitalisation Rurale';

  //LOGEMENT FORM CONTROL

  proprietaire: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );
  locataire: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );
  chezParent: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );
  chezMoi: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );
  lieuPublic: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );
  autreLieu: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );
  chezTier: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );
  hotel: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );
  centreHebergement: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );
  autre: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );
  nesaitpas: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );
  isQPV: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );
  isQVA: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );
  isZRR: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );
  precision: FormControl<string | any> = new FormControl(
    '',
    Validators.required
  );
  isFrein: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );

  adresse = new FormControl<{
    adresse: {
      properties: {
        label: string;
        score: number;
        housenumber: string;
        id: string;
        type: string;
        name: string;
        postcode: string;
        citycode: string;
        x: number;
        y: number;
        city: string;
        context: string;
        importance: number;
        street: string;
      };
      geometry: {
        type: string;
        coordinates: [number];
      };
    };
    complement: string;
    qpv: string;
  }>({
    adresse: {
      properties: {
        label: '',
        score: 0,
        housenumber: '',
        id: '',
        type: '',
        name: '',
        postcode: '',
        citycode: '',
        x: 0,
        y: 0,
        city: '',
        context: '',
        importance: 0,
        street: '',
      },
      geometry: {
        type: '',
        coordinates: [0],
      },
    },
    complement: '',
    qpv: '',
  });

  @Input() logement$ = new BehaviorSubject<any>(null);
  @Output() add = new EventEmitter(false);

  constructor(
    private fb: FormBuilder,
    private utilitiesStore: Store<UtilitiesState>
  ) {}

  ngOnInit(): void {
    this.form();
    this.onFormChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  form(): void {
    this.formInit();
    this.formCreate();
    this.formSet();
  }

  formInit(): void {
    this.adresse = new FormControl<{
      adresse: {
        properties: {
          label: string;
          score: number;
          housenumber: string;
          id: string;
          type: string;
          name: string;
          postcode: string;
          citycode: string;
          x: number;
          y: number;
          city: string;
          context: string;
          importance: number;
          street: string;
        };
        geometry: {
          type: string;
          coordinates: [number];
        };
      };
      complement: string;
      qpv: string;
    }>({
      adresse: {
        properties: {
          label: '',
          score: 0,
          housenumber: '',
          id: '',
          type: '',
          name: '',
          postcode: '',
          citycode: '',
          x: 0,
          y: 0,
          city: '',
          context: '',
          importance: 0,
          street: '',
        },
        geometry: {
          type: '',
          coordinates: [0],
        },
      },
      complement: '',
      qpv: '',
    });

    this.proprietaire = new FormControl(false, Validators.required);
    this.locataire = new FormControl(false, Validators.required);
    this.chezParent = new FormControl(false, Validators.required);
    this.chezMoi = new FormControl(false, Validators.required);
    this.lieuPublic = new FormControl(false, Validators.required);
    this.autreLieu = new FormControl(false, Validators.required);
    this.chezTier = new FormControl(false, Validators.required);
    this.hotel = new FormControl(false, Validators.required);
    this.centreHebergement = new FormControl(false, Validators.required);
    this.autre = new FormControl(false, Validators.required);
    this.nesaitpas = new FormControl(false, Validators.required);
    this.precision = new FormControl<string>('', Validators.required);

    this.isQPV = new FormControl(false, Validators.required);
    this.isQVA = new FormControl(false, Validators.required);
    this.isZRR = new FormControl(false, Validators.required);
    this.isFrein = new FormControl(false, Validators.required);
  }
  formCreate(): void {
    this.logementForm = this.fb.group({
      proprietaire: this.proprietaire,
      locataire: this.locataire,
      chezParent: this.chezParent,
      chezMoi: this.chezMoi,
      lieuPublic: this.lieuPublic,
      autreLieu: this.autreLieu,
      chezTier: this.chezTier,
      hotel: this.hotel,
      centreHebergement: this.centreHebergement,
      autre: this.autre,
      nesaitpas: this.nesaitpas,
      precision: this.precision,
      isFrein: this.isFrein,

      adresse: this.adresse,
      isQPV: this.isQPV,
      isQVA: this.isQVA,
      isZRR: this.isZRR,
    });
  }
  formSet(): void {
    this.logement$.pipe(takeUntil(this.subscribe)).subscribe((values: any) => {
      if (!values) return;
      this.logementForm.patchValue({ ...values });
    });
  }
  checkboxChanges(event: boolean, type: string): void {
    switch (type) {
      case 'isQPV':
        this.logementForm.patchValue({
          isQPV: event,
        });

        break;
      case 'isQVA':
        this.logementForm.patchValue({
          isQVA: event,
        });

        break;
      case 'isZRR':
        this.logementForm.patchValue({
          isZRR: event,
        });

        break;
      default:
        break;
    }
  }

  onFormChanges(): void {
    this.logementForm.valueChanges
      .pipe(takeUntil(this.subscribe))
      .subscribe((formValue) => {
        this.add.emit(formValue);
      });
  }
}
