<ng-container *ngIf="user$ | async as user">
  <nz-card *ngIf="message$ | async as message; else noData" nzBorderless>
    <cdk-virtual-scroll-viewport
      itemSize="50"
      class="viewport"
      *ngIf="message.messages?.length; else skeleton"
    >
      <div
        *cdkVirtualFor="let item of message.messages"
        class="chatEvent__CHAT"
        [ngClass]="{
          chatLeft: message.auteur.id !== user.id,
          chatRight: message.auteur.id === user.id
        }"
      >
        <app-messages-screen-chat-event
          [message]="item"
        ></app-messages-screen-chat-event>
      </div>
    </cdk-virtual-scroll-viewport>
  </nz-card>
</ng-container>

<ng-template #inputFooter>
  <app-messages-screen-input></app-messages-screen-input>
</ng-template>

<ng-template #noData>
  <nz-empty
    [nzNotFoundImage]="noDataIcon"
    [nzNotFoundContent]="noDataContent"
  ></nz-empty>
</ng-template>

<ng-template #noDataIcon>
  <i nz-icon nzType="message" class="iconEmpty"></i>
</ng-template>
<ng-template #noDataContent>
  <h5 nz-typography class="contentEmpty">Aucun message sélectionné</h5>
</ng-template>

<ng-template #skeleton>
  <app-skeleton></app-skeleton>
</ng-template>
