import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-utilities-detail',
  templateUrl: './utilities-detail.component.html',
  styleUrls: ['./utilities-detail.component.scss'],
})
export class UtilitiesDetailComponent implements OnInit {
  @Input() utility: any = null;
  @Input() items: any = null;
  @Input() variable: string = '';
  @Input() currentTag: string = '';
  constructor() {}

  ngOnInit(): void {}

  onSelectItem(item: any, utility: any): void {
    const selection = utility[item];
    this.items = selection;
    this.variable = item;
  }

  onRefreshItems(items: any): void {
    this.items = items;
  }
}
