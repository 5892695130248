<nz-card [nzTitle]="cardTtitle" [nzExtra]="cardModuleExtra" nzHoverable>
  <div class="mission_card_participant">
    <h5 nz-typography *ngIf="mission?.participant">
      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col nzSpan="4">
          <mat-icon>supervisor_account</mat-icon>
        </nz-col>
        <nz-col nzSpan="20"
          ><strong nz-typography>{{
            mission?.participant | displayName | carLimitation: 20
          }}</strong></nz-col
        >
      </nz-row>
    </h5>
  </div>
  <nz-divider></nz-divider>
  <nz-row
    nzAlign="middle"
    nzGutter="8"
    nzJustify="start"
    class="mission_card_title"
  >
    <nz-col nzSpan="4">
      <mat-icon>business_center</mat-icon>
    </nz-col>
    <nz-col nzSpan="20">
      <p nz-typography>
        {{ mission?.metier?.metier?.libelle | carLimitation: 24 }}
      </p>
    </nz-col>
  </nz-row>

  <nz-row
    nzAlign="middle"
    nzGutter="8"
    nzJustify="start"
    class="mission_card_title"
  >
    <nz-col nzSpan="4">
      <mat-icon>domain</mat-icon>
    </nz-col>
    <nz-col nzSpan="20">
      <p nz-typography>
        {{ mission?.employeur?.denomination | carLimitation: 24 }}
      </p>
    </nz-col>
  </nz-row>
  <nz-space nzSize="small" nzDirection="vertical"></nz-space>

  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="12">
      <nz-tag nzColor="geekblue">
        {{ mission?.module?.title | carLimitation: 8 }}
      </nz-tag>
    </nz-col>
    <nz-col nzSpan="12">
      <nz-tag nzColor="orange" *ngIf="!mission?.isFinish">
        {{ mission?.endMission | dateFr: "distanceSuffix" }}
      </nz-tag>
      <nz-tag nzColor="green" *ngIf="mission?.isFinish">Terminé</nz-tag>
    </nz-col>
  </nz-row>
</nz-card>

<ng-template #cardTtitle>
  <app-header-title
    [icon]="'work_history'"
    [subtitle]="true"
    [height]="'35px'"
    [width]="'35px'"
    [subtitle]="false"
  ></app-header-title>
</ng-template>

<ng-template #cardModuleExtra>
  <app-button-dropdown
    [isRelance]="true"
    [isCopy]="true"
    [isDelete]="true"
    [isExportPDF]="true"
    (select)="onDropdown($event, mission)"
    (click)="$event.stopPropagation()"
  ></app-button-dropdown>
</ng-template>

<ng-template #noParticipant>
  <nz-tag nzColor="processing">En cours de recrutement</nz-tag>
</ng-template>
