import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class ParticipantSuiviGQL extends Query<Response> {
  document = gql`
    query ParticipantSuivi($participantId: ID!, $id: ID!) {
      participantSuivi(participantId: $participantId, id: $id) {
        id
        auteur {
          avatar
          displayName
          fonction
          setting {
            color
          }
        }
        dateStart
        dateUpdate
        hourStart
        hourUpdate
        object
        title
        type
        text
        textHtml
        userUpdate {
          avatar
          displayName
          fonction
          setting {
            color
          }
        }
      }
    }
  `;
}
