import { ExportSelectComponent } from './../../../../contents/components/export-select/export-select.component';
import { ParticipantFormComponent } from './../participant-form/participant-form.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ParticipantItemHeaderComponent } from './../participant-item-header/participant-item-header.component';
import { ComponentPortal } from '@angular/cdk/portal';
import { PortalNavigationService } from './../../../../portals/portal-navigation.service';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Observable, of, EMPTY, BehaviorSubject } from 'rxjs';
import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
  TemplateRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { ParticipantId } from '../../store/participant.model';
import { Store } from '@ngrx/store';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserselector from 'src/app/components/user/store/user.selectors';

import { ParticipantState } from '../../store/participant.reducer';
import * as fromParticipantAction from '../../store/participant.actions';
import * as fromParticipantSelector from '../../store/participant.selectors';
import { SideNavigationState } from 'src/app/components/side-navigation/store/side-navigation.reducer';
import * as sideNavigationSelector from 'src/app/components/side-navigation/store/side-navigation.selectors';
import * as sideNavigationAction from 'src/app/components/side-navigation/store/side-navigation.actions';

@Component({
  selector: 'app-participant-item',
  templateUrl: './participant-item.component.html',
  styleUrls: ['./participant-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantItemComponent implements OnInit, OnDestroy {
  subscribe = new Subject();
  routeData$: Observable<any> = of(null);
  participant$: Observable<ParticipantId | any> = of(null);
  statistiques$: Observable<any> = of(null);
  bodyComponent$ = new BehaviorSubject<string>('details');
  title: string = '';
  titleDownload: string = '';
  modalWidth: number = 1100;
  modalExportWidth: number = 480;

  @ViewChild('participantTitleForm', { static: false }) participantTitleForm:
    | TemplateRef<any>
    | any;

  @ViewChild('exportTitleForm', { static: false }) exportTitleForm:
    | TemplateRef<any>
    | any;

  componentPortalFilter: ComponentPortal<ParticipantItemHeaderComponent> | any;

  constructor(
    private userStore: Store<UserState>,
    private participantStore: Store<ParticipantState>,
    private activieRoute: ActivatedRoute,
    private router: Router,
    private portalNavigationService: PortalNavigationService,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private sideNavigationStore: Store<SideNavigationState>
  ) {}

  ngOnInit(): void {
    this.getRouteData();
    this.getParticipant();
    this.componentPortalFilter = new ComponentPortal(
      ParticipantItemHeaderComponent
    );
    this.setFilterPortal();
    //this.onSetNavigation('participant_details');
    this.onChangesNavigation();
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
    this.componentPortalFilter.detach();
  }

  getRouteData(): void {
    this.routeData$ = this.activieRoute.data;
  }

  getParticipant(): void {
    this.routeData$
      .pipe(takeUntil(this.subscribe))
      .subscribe((data: any): any => {
        if (!data?.participant) {
          this.router.navigate(['/participants']);
          return EMPTY;
        } else {
          this.participant$ = data?.participant;
        }
      });
  }

  onChangesNavigation(): void {
    this.sideNavigationStore
      .select(sideNavigationSelector.navigation)
      .pipe(takeUntil(this.subscribe))
      .subscribe((navigation) => {
        switch (navigation?.name) {
          case 'participant_details':
            this.bodyComponent$.next('details');

            break;
          case 'participant_actions':
            this.bodyComponent$.next('actions_accompagnement');

            break;
          case 'participant_impacts':
            this.bodyComponent$.next('impacts');

            break;
          case 'participant_dashboard':
            this.bodyComponent$.next('dashboard');

            break;
          case 'participant_planning':
            this.bodyComponent$.next('planning');

            break;
          case 'participant_dashboard':
            this.bodyComponent$.next('dashboard');

            break;

          case 'participant_suivis':
            this.bodyComponent$.next('suivis');

            break;

          case 'participant_projetsProfessionnels':
            this.bodyComponent$.next('projetsProfessionnels');

            break;

          case 'participant_documents':
            this.bodyComponent$.next('documents');

            break;

          case 'participant_experiences':
            this.bodyComponent$.next('experiences');

            break;
          case 'participant_projets':
            this.bodyComponent$.next('projets');
            break;
          case 'participant_prescripteur':
            this.bodyComponent$.next('prescripteurs');

            break;
          case 'participant_diagnostics':
            this.bodyComponent$.next('diagnostics');

            break;

          case 'participant_prescripteurs':
            this.bodyComponent$.next('prescripteurs');

            break;
          case 'participant_update':
            this.onParticipantForm(navigation.item, 'update');

            break;
          case 'participant_exportPDF':
            this.onExportSelect(navigation.item);

            break;

          default:
            this.bodyComponent$.next('details');

            break;
        }
      });
  }

  onSetNavigation(navigation: string): void {
    this.sideNavigationStore.dispatch(
      sideNavigationAction.selectSideNavigation({
        navigation: { type: 'side', name: navigation },
      })
    );
  }

  loadStatistiques(): void {
    this.participantStore
      .select(fromParticipantSelector.participant)
      .pipe(takeUntil(this.subscribe))
      .subscribe((participant: ParticipantId): any => {
        if (!participant) {
          this.router.navigate(['/participants']);
          return EMPTY;
        } else {
          // this.participantStore.dispatch(
          //   fromParticipantAction.loadEntiteStatistiques({ id: entite.id })
          // );
        }
      });
  }

  getStatistiques(): void {
    // this.statistiques$ = this.participantStore.select(
    //   fromParticipantSelector.statistiques
    // );
  }

  onParticipantForm(participant: ParticipantId, type: string): void {
    if (!participant) return;
    if (type === 'update') {
      this.onUpdateIdentity(participant);
    } else if (
      type === 'sortie' ||
      type === 'impact3' ||
      type === 'impact6' ||
      type === 'impact12'
    ) {
      this.onUpdateSortieImpact(participant, type);
    } else {
    }
  }

  setFilterPortal(): void {
    this.portalNavigationService.setPortal(this.componentPortalFilter);
  }
  onSelectActions(item: string, participant: ParticipantId): void {
    switch (item) {
      case 'export':
        this.onExportSelect(participant);
        break;
      case 'update':
        this.onParticipantForm(participant, 'update');
        break;
      case 'sortie':
        this.onParticipantForm(participant, 'sortie');
        break;
      case 'impact3':
        this.onParticipantForm(participant, 'impact3');
        break;
      case 'impact6':
        this.onParticipantForm(participant, 'impact6');
        break;
      case 'impact12':
        this.onParticipantForm(participant, 'impact12');
        break;

      case 'delete':
        this.onDelete(participant);
        break;

      default:
        this.bodyComponent$.next(item);
        break;
    }
  }

  onExportSelect(participant: ParticipantId): void {
    if (!participant) return;
    const { civilite, lastName, firstName } = participant;
    const displayName = `${civilite}. ${lastName} ${firstName}`;
    this.titleDownload = `${displayName}`;

    const modal = this.modal.create({
      nzContent: ExportSelectComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalExportWidth,
      nzFooter: [],
      nzTitle: this.exportTitleForm,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
    });

    const instance = modal.getContentComponent();
    instance.isAnomyne = true;
    instance.isInclusSuivi = true;

    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
    instance.select.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        this.onExportValidation(event, participant);
        modal.close();
      }
    });
  }

  onExportValidation(event: any, participant: ParticipantId): void {
    if (!participant) return;
    const { item, anonym, suivi } = event;
    this.participantStore.dispatch(
      fromParticipantAction.exportParticipant({
        participant,
        incluSuivi: suivi,
        anonym: anonym,
      })
    );
  }

  onUpdateIdentity(participant: ParticipantId): void {
    const { civilite, lastName, firstName } = participant;
    const displayName = `${civilite}. ${lastName} ${firstName}`;
    this.title = `Modification : ${displayName}`;

    const modal = this.modal.create({
      nzContent: ParticipantFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.participantTitleForm,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });
    const instance = modal.getContentComponent();
    //instance.loadUtilitySortie()
    instance.loadUtilityIdentity();
    instance.update = true;
    instance.participant$ = of(participant);
    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
    instance.updateParticipant.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
  }
  onUpdateSortieImpact(participant: ParticipantId, type: string): void {
    const { civilite, lastName, firstName } = participant;
    const displayName = `${civilite}. ${lastName} ${firstName}`;
    this.title = `Modification : ${displayName}`;

    const modal = this.modal.create({
      nzContent: ParticipantFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.participantTitleForm,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });
    const instance = modal.getContentComponent();
    instance.loadUtilitySortie();
    instance.update = false;
    instance.isSortie = type === 'sortie' ? true : false;
    instance.isImpact = type === 'impact3' ? true : false;
    instance.isImpact6 = type === 'impact6' ? true : false;
    instance.isImpact12 = type === 'impact12' ? true : false;

    instance.participant$ = of(participant);
    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
    instance.updateParticipant.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
  }
  onDelete(participant: ParticipantId): void {
    if (!participant) return;
    this.participantStore.dispatch(
      fromParticipantAction.deleteParticipant({ id: participant.id })
    );
    this.router.navigate(['participants']);
  }
}
