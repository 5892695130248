import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'any',
})
export class MissionsService {
  constructor() {}

  exportPDF(data: any, isAnonym: boolean = false): any {}
  exportWORD(data: any, isAnonym: boolean = false): any {}
}
