import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isUser',
})
export class IsUserPipe implements PipeTransform {
  transform(user: any): boolean {
    if (!user) {
      return false;
    }else{
      return true
    }
  }
}
