import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import {
  GeoDistance,
  GeoDistanceCoordonnees,
} from 'src/app/contents/components/geo-territoire/store/geo-territoire.model';

@Component({
  selector: 'app-candidature-form',
  templateUrl: './candidature-form.component.html',
  styleUrls: ['./candidature-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CandidatureFormComponent implements OnInit, OnChanges, OnDestroy {
  subscribe = new Subject();
  currentStep: number = 0;
  currentPrescripteur$ = new BehaviorSubject<any>(null);
  currentCandidats$ = new BehaviorSubject<any>(null);

  contacts$ = new BehaviorSubject<any>(null);

  @Input() candidat: any;
  @Input() adresseMission: GeoDistanceCoordonnees = {
    latitude: 0,
    longitude: 0.0,
  };

  @Output() add = new EventEmitter<any>(false);
  @Output() update = new EventEmitter<any>(false);
  @Output() cancel = new EventEmitter<any>(false);

  prescripteurCtl = new FormControl<{
    id: string;
    civilite: string;
    lastName: string;
    firstName: string;
    fonction: string;
    role: string;
    genre: string;
    tranche: string;
    coordonnees: {
      fixe: string;
      mobile: string;
      phoneNumber: string;
      email: string;
    };
    adresse: any;
    age: any;
    dateNaissance: any;
    etablissement: any;
  }>(
    {
      id: '',
      civilite: '',
      lastName: '',
      firstName: '',
      fonction: '',
      role: '',
      genre: '',
      tranche: '',
      coordonnees: {
        fixe: '',
        mobile: '',
        phoneNumber: '',
        email: '',
      },
      adresse: {},
      age: '',
      dateNaissance: null,
      etablissement: null,
    },
    Validators.required
  );

  candidatCtl = new FormControl([], Validators.required);

  distance = new FormControl<GeoDistance>({
    distanceKilometer: 0.0,
    distanceMeter: 0,
    distanceText: '',
  });

  isSelected = new FormControl<boolean>(false, Validators.required);
  isRejected = new FormControl<boolean>(false, Validators.required);
  isInterviewed = new FormControl<boolean>(false, Validators.required);
  isValided = new FormControl<boolean>(false, Validators.required);

  candidatureForm: FormGroup = new FormGroup(
    {
      prescripteur: this.prescripteurCtl,
      candidats: this.candidatCtl,
    },
    Validators.required
  );

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.candiat?.currentValue) return;
    this.formUpdate(changes.candiat?.currentValue);
  }
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  //Tab précédente
  onPrevStep(): void {
    this.currentStep = this.currentStep === 0 ? 0 : this.currentStep - 1;

    const candidats = this.candidatureForm.get('candidats')?.value;
    this.currentCandidats$.next(candidats);
  }
  //Tab suivante
  onNextStep(): void {
    this.currentStep =
      this.currentStep < 2 ? this.currentStep + 1 : this.currentStep;
    const prescripteur = this.candidatureForm.get('prescripteur')?.value;
    this.currentPrescripteur$.next([prescripteur]);
  }

  //Actualiser les candidatures dans le formulaire
  formUpdate(candidat: any): void {
    this.candidatureForm.patchValue({ ...candidat });
  }

  //Ajouter l'etablissement prescripteur
  onAddPrescripteur(prescripteur: any): void {
    if (!prescripteur) return;
    this.candidatureForm.patchValue({ prescripteur });
  }

  //Effacer l'établissement prescripteur
  onClearPrescripteur(): void {
    // const prescripteur = this.candidatureForm.get('prescripteur');
    // this.currentPrescripteur$.next([prescripteur]);
  }

  //Ecouter des changes d'état des candidats
  onCandidatsChanges(candidats: any): void {
    this.candidatureForm.patchValue({ candidats });
  }

  //Ajouter candidats
  onAddCandidats(candidats: any): void {
    this.candidatureForm.patchValue({ candidats });

    const formValues = this.candidatureForm.value;
    this.add.emit(formValues);
  }

  onChangesDistance(distance: GeoDistance): void {
    this.candidatureForm.patchValue({
      distance: distance,
    });
  }

  //Annuler l'ajout de candidats
  onCancel(): void {
    this.candidatureForm.reset();
    this.cancel.emit(true);
  }
}
