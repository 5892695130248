import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MetiersDefinitionComponent } from './components/metiers-definition/metiers-definition.component';
import { MetiersEnvironnementComponent } from './components/metiers-environnement/metiers-environnement.component';
import { MetiersComponent } from './components/metiers/metiers.component';
import { MetiersCompetencesComponent } from './components/metiers-competences/metiers-competences.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { PipeModule } from 'src/app/pipe/pipe.module';
import { QueryMetiersService } from './services/query-metiers.service';
import { QueryMetiersAppelationDetailsService } from './graphql/queries/query-metiers-appelation-details.service';
import { QueryMetierDetailsService } from './graphql/queries/query-metier-details.service';
import { StoreModule } from '@ngrx/store';
import * as fromMetier from './store/metier.reducer';
import { MetierEffects } from './store/metier.effects';
import { EffectsModule } from '@ngrx/effects';
import { EntiteSearchModule } from '../../contents/components/entite-search/entite-search.module';
import { CommunesFranceModule } from '../../contents/components/communes-france/communes-france.module';
import { HeaderModule } from '../../contents/components/header-title/header.module';
import { NoResultModule } from 'src/app/contents/components/no-result/no-result.module';
import { MetiersItemComponent } from './components/metiers-item/metiers-item.component';
import { MetiersCompetencesListComponent } from './components/metiers-competences-list/metiers-competences-list.component';

@NgModule({
  declarations: [
    MetiersDefinitionComponent,
    MetiersEnvironnementComponent,
    MetiersComponent,
    MetiersCompetencesComponent,
    MetiersItemComponent,
    MetiersCompetencesListComponent,
  ],
  exports: [
    MetiersDefinitionComponent,
    MetiersEnvironnementComponent,
    MetiersComponent,
    MetiersCompetencesComponent,
    MetiersItemComponent,
    MetiersCompetencesListComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    PipeModule,
    EntiteSearchModule,
    CommunesFranceModule,
    HeaderModule,
    NoResultModule,
    StoreModule.forFeature(fromMetier.metiersFeatureKey, fromMetier.reducer),
    EffectsModule.forFeature([MetierEffects]),
  ],
  providers: [
    QueryMetiersService,
    QueryMetiersAppelationDetailsService,
    QueryMetierDetailsService,
  ],
})
export class MetiersModule {}
