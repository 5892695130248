<nz-row nzJustify="center" nzAlign="middle" nzGutter="0">
  <nz-col [nzSpan]="add ? 24 : 0">
    <button
      nz-button
      nzBlock
      (click)="$event.preventDefault(); addObjectif()"
      nz-tooltip
      nzTooltipTitle="Ajouter un objectif"
      nzTooltipPlacement="bottom"
      nzType="text"
      class="btnPrimary"
    >
      <p nz-typography>Ajouter</p>
    </button>
  </nz-col>
</nz-row>
<nz-row nzJustify="center" nzAlign="middle" nzGutter="0">
  <nz-col nzSpan="24">
    <form nz-form [formGroup]="objectifs">
      <div
        class="listContainer"
        formArrayName="items"
        *ngIf="objectifItems.controls?.length; else empty"
      >
        <nz-list *ngFor="let el of objectifItems.controls; let i = index">
          <nz-list-item [formGroupName]="i">
            <nz-card [nzTitle]="cardAction" nzBorderless>
              <ng-template #cardAction>
                <nz-row nzJustify="start" nzAlign="middle" nzGutter="0">
                  <nz-col nzSpan="4">
                    <nz-badge
                      [nzCount]="i + 1"
                      [nzStyle]="badgeStyle"
                    ></nz-badge>
                  </nz-col>
                  <nz-col nzSpan="16"></nz-col>
                  <nz-col nzSpan="4">
                    <button
                      nz-button
                      (click)="onRemoveObjectif(i)"
                      nzShape="circle"
                    >
                      <i
                        nz-icon
                        nzType="delete"
                        nzTheme="outline"
                        class="iconDelete"
                      ></i>
                    </button>
                  </nz-col>
                </nz-row>
              </ng-template>

              <nz-row nzJustify="start" nzAlign="middle" nzGutter="0">
                <nz-col nzSpan="24">
                  <nz-form-item *ngIf="add">
                    <nz-form-label nzRequired="true" nzSpan="24"
                      >Catégorie</nz-form-label
                    >
                    <nz-form-control>
                      <nz-select
                        nzAllowClear="false"
                        nzBorderless
                        formControlName="categorie"
                        nzPlaceHolder="Catégorie..."
                        placeholder="Catégorie..."
                        (ngModelChange)="setCategorieOfIndicator($event, i)"
                      >
                        <nz-option
                          *ngFor="let type of types"
                          [nzLabel]="type | titlecase"
                          [nzValue]="type"
                        >
                        </nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                  <div *ngIf="!add" class="updateContent">
                    <label>Catégorie :</label>
                    <nz-tag nzColor="success">
                      <strong>{{ el?.value?.categorie | titlecase }}</strong>
                    </nz-tag>
                  </div>
                </nz-col>
              </nz-row>
              <nz-row nzJustify="start" nzAlign="middle" nzGutter="0">
                <nz-col [nzSpan]="add ? 12 : 24">
                  <nz-form-item *ngIf="add">
                    <nz-form-label nzRequired="true" nzSpan="24"
                      >Type</nz-form-label
                    >
                    <nz-form-control>
                      <nz-select
                        nzAllowClear="false"
                        nzBorderless
                        formControlName="type"
                        nzPlaceHolder="Type..."
                        placeholder="Type..."
                        (ngModelChange)="
                          guardTypeObjectif($event, el?.value?.categorie, i)
                        "
                      >
                        <nz-option
                          *ngFor="let item of objectifList"
                          [nzLabel]="item | titlecase"
                          [nzValue]="item"
                        >
                        </nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                  <div *ngIf="!add" class="updateContent">
                    <label>Type :</label>
                    <nz-tag nzColor="success" *ngIf="!add">
                      <strong>{{ el?.value?.type | titlecase }}</strong>
                    </nz-tag>
                  </div>
                </nz-col>
                <nz-col [nzSpan]="add ? 12 : 24">
                  <nz-form-item>
                    <nz-form-label nzRequired="true" nzSpan="24"
                      >Valeur</nz-form-label
                    >
                    <nz-form-control>
                      <nz-input-number
                        formControlName="value"
                        aria-placeholder="Valeur"
                        nzPlaceHolder="Valeur"
                        [nzMin]="2"
                      ></nz-input-number>
                    </nz-form-control>
                  </nz-form-item>
                </nz-col>
              </nz-row>
              <nz-row nzJustify="start" nzAlign="middle" nzGutter="0">
                <nz-col nzSpan="24">
                  <nz-form-item *ngIf="add && el?.value?.type === 'critères'">
                    <nz-form-label nzRequired="true" nzSpan="24"
                      >Item</nz-form-label
                    >
                    <app-utilities-select
                      [id]="utilitairesId"
                      [variable]="utilitaireCriteres"
                      [size]="'default'"
                      [multi]="false"
                      (select)="addCritere($event, i)"
                      [placeholder]="'Critères...'"
                    ></app-utilities-select>
                  </nz-form-item>
                  <div
                    *ngIf="!add && el?.value?.type === 'critères'"
                    class="updateContent"
                  >
                    <label>Item :</label>
                    <nz-tag nzColor="success">
                      <strong>{{ el?.value?.item?.item | titlecase }}</strong>
                    </nz-tag>
                  </div>
                </nz-col>
              </nz-row>
            </nz-card>
          </nz-list-item>
        </nz-list>
      </div>
    </form>
  </nz-col>
</nz-row>

<nz-row nzJustify="center" nzAlign="middle" nzGutter="0">
  <nz-col [nzSpan]="objectifItems.length ? 22 : 24">
    <button
      nz-button
      nzType="primary"
      nzBlock
      [disabled]="!objectifItems.length"
      (click)="$event.preventDefault(); onSaveObjectif()"
      nz-tooltip
      nzTooltipTitle="Enregistrer les objectifs"
      nzTooltipPlacement="bottom"
    >
      <p nz-typography>Enregistrer</p>
    </button>
  </nz-col>
  <nz-col [nzSpan]="objectifItems.controls.length ? 2 : 0">
    <nz-badge [nzCount]="objectifItems.controls.length" [nzOffset]="[2, -20]">
    </nz-badge>
  </nz-col>
</nz-row>

<ng-template #empty>
  <nz-empty nzNotFoundImage="simple"></nz-empty>
</ng-template>

<ng-template #noData>
  <div class="containerEmpty">
    <div>
      <nz-empty nzNotFoundImage="simple" class="iconEmpty"></nz-empty>
      <h4 nz-typography class="contentEmpty">Aucun objectif sélectionné</h4>
    </div>
  </div>
</ng-template>
