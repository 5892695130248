import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, of, lastValueFrom, from } from 'rxjs';
import { ParticipantDocumentState } from 'src/app/components/participant-documents/store/participant-document.reducer';
import * as fromParticipantDocumentAction from 'src/app/components/participant-documents/store/participant-document.actions';
import * as fromParticipantDocumentSelector from 'src/app/components/participant-documents/store/participant-document.selectors';

@Injectable({
  providedIn: 'root',
})
export class ParticipantDocumentsResolverService {
  constructor(
    private participantDocumentStore: Store<ParticipantDocumentState>,
    private router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    let id = route.parent?.params['id'];

    let find = lastValueFrom(this.loadParticipantDocuments(id))
      .then(() => {
        return this.getParticipantDocuments();
      })
      .catch(() => {
        this.router.navigate([`/participants/:${id}`]);
        return EMPTY;
      });

    return from(find);
  }

  getParticipantDocuments(): Observable<any> {
    return this.participantDocumentStore.select(
      fromParticipantDocumentSelector.documents
    );
  }

  loadParticipantDocuments(id: string): Observable<any> {
    return of(
      this.participantDocumentStore.dispatch(
        fromParticipantDocumentAction.loadParticipantDocuments({ id })
      )
    );
  }
}
