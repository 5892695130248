<!-- <app-layout-list-container
  [list]="candidats"
  [card]="card"
  [isHeader]="false"
  [extra]="listExtraTpl"
  [height]="'calc(100vh - 30em)'"
  [itemSize]="'50'"
  [headerHeight]="'60px'"
  [isBorder]="false"
  [isBack]="false"
  [isNew]="false"
  [newAddTitle]="newAddItemTitle"
  [newNoDataTitle]="noDataTitle"
  [newDescription]="newItemDefinition"
  [loading$]="loading$"
></app-layout-list-container> -->

<app-table
  [source]="candidats"
  [tableHeader]="tableHeader"
  [tableRow]="tableRox"
  (select)="onSelectDrawser($event)"
></app-table>

<div class="drawer-side w-4/12 visible">
  <label for="my-drawer-4" class="drawer-overlay"></label>
  <app-candidature-item></app-candidature-item>
</div>

<ng-template #tableHeader>
  <tr>
    <th>
      <label>
        <input type="checkbox" class="checkbox" />
      </label>
    </th>
    <th>Identité</th>
    <th>Administratif</th>
    <th>Statut</th>
  </tr>
</ng-template>
<ng-template #tableRox let-item let-position="idx">
  <th>
    <label (click)="$event.stopPropagation()">
      <input type="checkbox" class="checkbox" />
    </label>
  </th>
  <td>
    <div class="flex items-center space-x-3">
      <div class="avatar placeholder">
        <div
          class="text-neutral-content rounded-full w-12"
          [ngStyle]="{ 'background-color': item?.person?.setting?.color }"
        >
          <span>{{ item?.person?.avatar }}</span>
        </div>
      </div>
      <div>
        <div class="font-bold">
          {{ item.person.displayName | carLimitation : 30 }}
        </div>
        <div class="text-sm opacity-50 divide-x gap-x-4">
          <div>
            {{
              item.prescripteur?.etablissement?.uniteLegale
                ?.denominationUniteLegale | carLimitation : 30
            }}
          </div>
          <div>
            {{
              item.prescripteur?.etablissement?.adresseEtablissement?.commune
                ?.nom | carLimitation : 20
            }}
          </div>
        </div>
      </div>
    </div>
  </td>
  <td>
    <span
      *ngIf="item.person.diagnostic?.administratif?.criteres1?.value"
      [class]="'badge badge-ghost badge-md'"
      >{{
        item.person.diagnostic?.administratif?.criteres1?.value
          | splitJoin : "_" : ","
      }}</span
    >
    <br />
    <ng-container
      *ngIf="item.person.diagnostic?.administratif?.criteres2?.length"
    >
      <span
        *ngFor="let critere of item.person.diagnostic?.administratif?.criteres2"
        [class]="'badge badge-ghost badge-md'"
        >{{ critere?.item }}</span
      >
    </ng-container>
    <span
      [class]="
        item.person.diagnostic?.administratif?.isPassIae
          ? 'badge badge-ghost badge-md'
          : 'badge badge-ghost badge-md'
      "
      >{{
        item.person.diagnostic?.administratif?.isPassIae
          ? "Pass IAE"
          : "Aucun Pass IAE"
      }}</span
    >
  </td>
  <td>
    <span
      *ngIf="item.person.isValided && !item?.person?.isSelected"
      [class]="'badge badge-success badge-md'"
      >Vérification validée</span
    >
    <span *ngIf="!item.person.isValided" [class]="'badge badge-ghost badge-md'"
      >En cours de vérification</span
    >
    <span
      *ngIf="item?.person?.isSelected"
      [class]="'badge badge-success badge-md'"
      >Sélectionné</span
    >
    <span
      *ngIf="item.person.isInterviewed"
      [class]="'badge badge-ghost badge-md'"
      >Entretien</span
    >
    <span
      *ngIf="item.person.isRejected"
      [class]="'badge badge-warning badge-md'"
      >Non retenu</span
    >
  </td>
</ng-template>
<ng-template #card let-item let-position="idx">
  <app-layout-list-item
    [icon]="itemIconTpl"
    [content]="operationCandidatCardContentTpl"
    [actions]="itemActionsTpl"
    [cardHeigth]="'90px'"
    (click)="$event.stopPropagation(); onSelect(item)"
  ></app-layout-list-item>
  <ng-template #itemIconTpl>
    <app-avatar-group [source]="[item.person]" [size]="40"></app-avatar-group>
  </ng-template>

  <ng-template #operationCandidatCardContentTpl>
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="10" class="mt-6">
        <strong>{{ item.person.displayName | carLimitation : 30 }}</strong>
        <div class="w-full flex items-start justify-start divide-x gap-2">
          <small class="tracking-wider">
            {{
              item.prescripteur?.etablissement?.uniteLegale
                ?.denominationUniteLegale | carLimitation : 30
            }}
          </small>
          <small class="tracking-wider">
            {{
              item.prescripteur?.etablissement?.adresseEtablissement?.commune
                ?.nom | carLimitation : 20
            }}
          </small>
        </div>
      </nz-col>
      <nz-col nzSpan="2">
        <nz-space nzDirection="horizontal" nzSize="small">
          <ng-container
            *ngIf="item.person.diagnostic?.administratif?.isPassIae"
          >
            <nz-tag *nzSpaceItem nzColor="blue">
              {{
                item.person.diagnostic?.administratif?.isPassIae
                  ? "PASS IAE"
                  : "PAS DE PASS IAE"
              }}
            </nz-tag>
          </ng-container>
          <ng-container *ngIf="item?.isValided">
            <nz-tag *nzSpaceItem nzColor="success">
              <i nz-icon nzType="check-circle"></i>
              {{ item?.isValided ? "Validée" : "" }}
            </nz-tag>
          </ng-container>
          <ng-container *ngIf="item?.isInterviewed">
            <nz-tag *nzSpaceItem nzColor="blue">
              <i nz-icon nzType="profile"></i>
              {{ item?.isInterviewed ? "Entretien" : "" }}
            </nz-tag>
          </ng-container>

          <ng-container *ngIf="item?.isSelected">
            <nz-tag *nzSpaceItem nzColor="success">
              <i nz-icon nzType="check-circle"></i>
              {{ item?.isSelected ? "Selectionné" : "" }}
            </nz-tag>
          </ng-container>
          <ng-container *ngIf="item?.isRejected">
            <nz-tag *nzSpaceItem nzColor="warning">
              <i nz-icon nzType="close-circle"></i>
              {{ item?.isSelected ? "Rejeté" : "" }}
            </nz-tag>
          </ng-container>
        </nz-space>
      </nz-col>
      <nz-col nzSpan="12"> </nz-col>
    </nz-row>
    <nz-row nzAlign="top" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="10">
        <nz-space nzDirection="horizontal">
          <small nz-typography *nzSpaceItem>
            {{ item.person.coordonnees?.mobile | phone }}
          </small>
          <nz-divider
            nzOrientation="center"
            nzType="vertical"
            *nzSpaceItem
          ></nz-divider>
          <small *nzSpaceItem>{{ item.person.coordonnees?.email }}</small>
        </nz-space>
      </nz-col>
      <nz-col nzSpan="2"> </nz-col>
      <nz-col nzSpan="12">
        <nz-space nzDirection="horizontal">
          <small *nzSpaceItem>{{
            item.prescripteur.displayName | carLimitation : 30
          }}</small>
          <nz-divider
            nzOrientation="center"
            nzType="vertical"
            *nzSpaceItem
          ></nz-divider>

          <small *nzSpaceItem>{{
            item.prescripteur.coordonnees?.mobile | phone
          }}</small>
          <nz-divider
            nzOrientation="center"
            nzType="vertical"
            *nzSpaceItem
          ></nz-divider>

          <small *nzSpaceItem>{{ item.prescripteur.coordonnees?.email }}</small>
        </nz-space>
      </nz-col>
    </nz-row>
  </ng-template>

  <ng-template #itemActionsTpl>
    <div class="w-full flex items-end justify-end space-x-2">
      <!-- <app-button-menu
        [title]="'Export'"
        [type]="'default'"
        [menu]="menuNewItems"
        (select)="onExportActions($event)"
        class="mr-6"
      ></app-button-menu> -->

      <button
        nz-button
        nzType="text"
        nzShape="circle"
        nz-tooltip
        nzTooltipTitle="Supprimer le candidat"
        nzTooltipPlacement="top"
        class="delete-action"
        (click)="$event.stopPropagation()"
        nz-popconfirm
        [nzPopconfirmTitle]="deleteCandidatType"
        nzPopconfirmPlacement="bottom"
        (nzOnConfirm)="onDelete(item)"
        (nzOnCancel)="onDeleteCancel()"
      >
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </ng-template>
</ng-template>

<ng-template #listExtraTpl>
  <nz-space nzDirection="horizontal" nzSize="small">
    <button
      nz-button
      *nzSpaceItem
      nzType="primary"
      (click)="onNew(adresseMission)"
    >
      <nz-space nzDirection="horizontal">
        <!-- <i nz-icon nzType="plus" *nzSpaceItem></i> -->
        <span *nzSpaceItem>Nouveau</span>
        <!-- <i nz-icon nzType="down" *nzSpaceItem></i> -->
      </nz-space>
    </button>
    <app-button-dropdown
      *nzSpaceItem
      [isDeleteAll]="true"
      [isExportEXL]="true"
      [isExportPDF]="true"
      (select)="onExport($event)"
    ></app-button-dropdown>
  </nz-space>
</ng-template>

<ng-template #ficheDePosteCandidatFormTitle>
  <app-header-title
    [icon]="'person'"
    [title]="title"
    [subtitle]="true"
  ></app-header-title>
</ng-template>
<ng-template #ficheDePosteCandidatDetailFormTitle>
  <app-header-title
    [icon]="'feed'"
    [title]="titleDetail"
    [subtitle]="true"
  ></app-header-title>
</ng-template>

<ng-template #ficheDePosteCandidatTransfertFormTitle>
  <app-header-title
    [icon]="'swap_horiz'"
    [title]="titleTransfert"
    [subtitle]="true"
  ></app-header-title>
</ng-template>
<ng-template #ficheDePosteCandidatDocumentFormTitle>
  <app-header-title
    [icon]="'folder_open'"
    [title]="titleDocuments"
    [subtitle]="true"
  ></app-header-title>
</ng-template>

<ng-template #ficheDePosteCandidatsExportFormTitle>
  <app-header-title
    [icon]="'file_download'"
    [title]="titleExport"
    [subtitle]="true"
  ></app-header-title>
</ng-template>

<ng-template #candidatureItemExtraTpl>
  <app-candidature-item-actions
    *ngIf="candidat"
    [candidat]="candidat"
    (action)="onCandidaAction($event, candidat)"
  ></app-candidature-item-actions>
</ng-template>
