import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-ui-input',
  templateUrl: './ui-input.component.html',
  styleUrls: ['./ui-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiInputComponent implements OnInit {
  @Input() inputId: string = '';
  @Input() control: FormControl<any> = new FormControl();
  @Input() label: string = '';
  @Input() type: string = 'text';
  @Input() placeholder: string = '';
  @Input() borderless: boolean = false;
  @Input() required: boolean = false;

  errorMessages: Record<string, string> = {
    required: 'Ce champs est requis',
    email: "L'e-mail est invalide",
  };

  constructor() {}

  ngOnInit(): void {}
}
