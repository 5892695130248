import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-participant-validator',
  templateUrl: './participant-validator.component.html',
  styleUrls: ['./participant-validator.component.scss'],
})
export class ParticipantValidatorComponent implements OnInit {
  @Output() validate = new EventEmitter<any>(false);
  @Output() cancel = new EventEmitter(false);
  constructor() {}

  ngOnInit(): void {}

  onValidate(): void {
    this.validate.emit(true);
  }
  onCancel(): void {
    this.cancel.emit(true);
  }
}
