import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrescripteurCardComponent } from './components/prescripteur-card/prescripteur-card.component';
import { PrescripteurListComponent } from './components/prescripteur-list/prescripteur-list.component';
import { PrescripteurItemComponent } from './components/prescripteur-item/prescripteur-item.component';
import { PrescripteurFormComponent } from './components/prescripteur-form/prescripteur-form.component';
import { PrescripteurSelectComponent } from './components/prescripteur-select/prescripteur-select.component';

@NgModule({
  declarations: [
    PrescripteurCardComponent,
    PrescripteurListComponent,
    PrescripteurItemComponent,
    PrescripteurFormComponent,
    PrescripteurSelectComponent,
  ],
  imports: [CommonModule],
  exports: [
    PrescripteurCardComponent,
    PrescripteurListComponent,
    PrescripteurItemComponent,
    PrescripteurFormComponent,
    PrescripteurSelectComponent,
  ],
})
export class PrescripteurModule {}
