import { Subject, takeUntil } from 'rxjs';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import {
  fromUnixTime,
  differenceInCalendarMonths,
  add,
  format,
} from 'date-fns';
import { fr } from 'date-fns/locale';
import { Timestamp } from 'firebase/firestore';
import { Update } from '@ngrx/entity';

@Component({
  selector: 'app-aids-item',
  templateUrl: './aids-item.component.html',
  styleUrls: ['./aids-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AidsItemComponent implements OnInit, OnChanges, OnDestroy {
  @Input() aid: any = null;
  @Input() isUpdate: boolean = false;
  @Input() isDelete: boolean = false;

  @Output() validation = new EventEmitter<any>(false);
  @Output() update = new EventEmitter<any>(false);
  @Output() back = new EventEmitter<boolean>(false);

  aidForm: UntypedFormGroup = this.fb.group({});
  receiveDateStart = new UntypedFormControl();
  receive = new UntypedFormControl();
  receiveDuration = new UntypedFormControl();
  receiveDateEnd = new UntypedFormControl();
  reveiveValue = new UntypedFormControl();
  dateFormat: string = 'dd/MM/yyyy';
  subscribe = new Subject();

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.onDateStartChange();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.formInit(changes.aid.currentValue);
    this.fromCreate();
  }

  formInit(aid: any): void {
    if (!aid) return;
    const start: Date | any = aid?.receiveDateStart
      ? fromUnixTime(aid.receiveDateStart['seconds'])
      : null;
    const end: Date | any = aid?.receiveDateEnd
      ? fromUnixTime(aid.receiveDateEnd['seconds'])
      : null;
    const durantion: number =
      start && end ? differenceInCalendarMonths(end, start) : 0;
    const value: number = aid?.reveiveValue ? aid.reveiveValue : 0;
    this.receiveDateStart = new UntypedFormControl(start, Validators.required);
    this.receive = new UntypedFormControl(aid?.receive, Validators.required);
    this.receiveDuration = new UntypedFormControl(durantion, Validators.required);
    this.receiveDateEnd = new UntypedFormControl(end, Validators.required);
    this.reveiveValue = new UntypedFormControl(value, Validators.required);
  }
  fromCreate(): void {
    this.aidForm = this.fb.group({
      receiveDateStart: this.receiveDateStart,
      receive: this.receive,
      receiveDuration: this.receiveDuration,
      receiveDateEnd: this.receiveDateEnd,
      reveiveValue: this.reveiveValue,
    });
  }

  onDateStartChange(): void {
    this.aidForm
      .get('receiveDuration')
      ?.valueChanges.pipe(takeUntil(this.subscribe))
      .subscribe((duration: number) => {
        if (!duration) return;

        let start: Date = this.aidForm.get('receiveDateStart')?.value;

        if (!duration && !start) return;

        this.aidForm.patchValue({
          receiveDateEnd: add(start, { months: duration }),
        });
      });
  }

  onValidate(aid: any): void {
    this.validation.emit(aid);
  }

  onReceive(event: boolean): void {
    this.aidForm.patchValue({ receive: event });
  }

  onUpdate(aid: any): void {
    if (!aid || !this.aidForm.valid) return;
    const value = this.aidForm.value;
    const start: any = value?.receiveDateStart
      ? Timestamp.fromDate(value.receiveDateStart)
      : null;
    const end: any = value?.receiveDateEnd
      ? Timestamp.fromDate(value.receiveDateEnd)
      : null;
    const duration: number = differenceInCalendarMonths(end, start);

    const nextAid: Update<any> = {
      id: aid.id,
      changes: {
        ...aid,
        receiveDateStart: start,
        receive: value.receive,
        receiveDuration: duration,
        receiveDateEnd: end,
        reveiveValue: value.reveiveValue,
      },
    };

    this.update.emit(nextAid);
  }

  onBack(): void {
    this.back.emit(true);
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }
}
