import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class ParticipantDocumentGQL extends Query<Response> {
  document = gql`
    query ParticipantDocument(
      $participantId: ID!
      $participantDocumentId: ID!
    ) {
      participantDocument(
        participantId: $participantId
        id: $participantDocumentId
      ) {
        id
        fileName
        fileRef
        title
        type
        extension
        description
        correspondanceId
        auteur {
          avatar
          displayName
          setting {
            color
          }
        }
        dateStart
      }
    }
  `;
}
