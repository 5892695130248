import { ParticipantPlanningService } from './../../../service/participant/planning/participant-planning.service';
import { Injectable } from '@angular/core';
import {
  NzNotificationService,
  NzNotificationPlacement,
} from 'ng-zorro-antd/notification';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError, of, tap } from 'rxjs';
import * as fromParticipantPlanningAction from './participant-planning.actions';

const NOTIFICATION_PLACEMENT: NzNotificationPlacement = 'bottomRight';

@Injectable()
export class ParticipantPlanningEffects {
  loadJoursFeries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromParticipantPlanningAction.loadJoursFerier),
      mergeMap((action) =>
        this.participantPlanningService.getJoursFeries().pipe(
          map((respons: any) => {
            const jours: any = respons.data.getJoursFeries;

            return fromParticipantPlanningAction.loadJoursFerierSuccess({
              jours,
            });
          }),
          catchError((error) => {
            this.onErrorNotification(error, 'LOAD_JOURS_FERIES');
            return of(
              fromParticipantPlanningAction.loadJoursFerierFailure({
                error,
              })
            );
          })
        )
      )
    )
  );

  loadParticipantPlanningEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromParticipantPlanningAction.loadParticipantPlannings),

      mergeMap((action) =>
        this.participantPlanningService.getPlannings(action.id).pipe(
          map((respons: any) => {
            const events: any = respons.data.participantPlanning;

            return fromParticipantPlanningAction.loadParticipantPlanningsSuccess(
              { events }
            );
          }),
          catchError((error) => {
            this.onErrorNotification(error, 'LOAD_PARTICIPANT_EVENTS');
            return of(
              fromParticipantPlanningAction.loadParticipantPlanningsFailure({
                error,
              })
            );
          })
        )
      )
    )
  );
  loadParticipantPlanningEventsFiltered$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromParticipantPlanningAction.loadParticipantPlanningsFiltered),

      mergeMap((action) =>
        this.participantPlanningService
          .getPlanningsFiltered(action.id, action.filter)
          .pipe(
            map((respons: any) => {
              const events: any =
                respons.data.participantPlanningByMonthYearType;

              return fromParticipantPlanningAction.loadParticipantPlanningsFilteredSuccess(
                { events }
              );
            }),
            catchError((error) => {
              this.onErrorNotification(
                error,
                'LOAD_PARTICIPANT_EVENTS_FILTERED'
              );
              return of(
                fromParticipantPlanningAction.loadParticipantPlanningsFilteredFailure(
                  {
                    error,
                  }
                )
              );
            })
          )
      )
    )
  );

  addParticipantPlanningEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromParticipantPlanningAction.addParticipantPlanning),
      tap(() => {
        this.notification.info('Nouveau', 'Événement en cours de création...', {
          nzKey: 'CREATE_PARTICIPANT_EVENT',
          nzPlacement: NOTIFICATION_PLACEMENT,
          nzDuration: 6000,
        });
      }),
      mergeMap((action) =>
        this.participantPlanningService
          .createPlanningEvent(action.id, action.event)
          .pipe(
            map((respons: any) => {
              const event: any = respons.data.createParticipantPlanning;
              this.notification.success(
                'Nouveau',
                'Événement ajouté avec succès',
                {
                  nzKey: 'CREATE_PARTICIPANT_EVENT',
                  nzPlacement: NOTIFICATION_PLACEMENT,
                  nzDuration: 8000,
                }
              );
              return fromParticipantPlanningAction.addParticipantPlanningSuccess(
                { event }
              );
            }),
            catchError((error) => {
              this.onErrorNotification(error, 'CREATE_PARTICIPANT_EVENT');
              return of(
                fromParticipantPlanningAction.addParticipantPlanningFailure({
                  error,
                })
              );
            })
          )
      )
    )
  );
  updateParticipantPlanningEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromParticipantPlanningAction.updateParticipantPlanning),
      tap(() => {
        this.notification.info(
          'Modification',
          'Événement en cours de modification...',
          {
            nzKey: 'UPDATE_PARTICIPANT_EVENT',
            nzPlacement: NOTIFICATION_PLACEMENT,
            nzDuration: 6000,
          }
        );
      }),
      mergeMap((action) =>
        this.participantPlanningService
          .createPlanningEvent(action.id, action.event)
          .pipe(
            map((respons: any) => {
              const event: any = respons.data.updateParticipantPlanning;
              this.notification.success(
                'Modification',
                'Événement a été modifié avec succès',
                {
                  nzKey: 'UPDATE_PARTICIPANT_EVENT',
                  nzPlacement: NOTIFICATION_PLACEMENT,
                  nzDuration: 8000,
                }
              );
              return fromParticipantPlanningAction.addParticipantPlanningSuccess(
                { event }
              );
            }),
            catchError((error) => {
              this.onErrorNotification(error, 'UPDATE_PARTICIPANT_EVENT');
              return of(
                fromParticipantPlanningAction.addParticipantPlanningFailure({
                  error,
                })
              );
            })
          )
      )
    )
  );

  deleteParticipantPlanningEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromParticipantPlanningAction.deleteParticipantPlanning),
      tap(() => {
        this.notification.info(
          'Suppression',
          'Suppression du document en cours...',
          {
            nzKey: 'DELETE_PARTICIPANT_PLANNING_EVENT',
            nzPlacement: NOTIFICATION_PLACEMENT,
            nzDuration: 6000,
          }
        );
      }),
      mergeMap((action) =>
        this.participantPlanningService
          .deletePlanningEvent(action.participantId, action.id)
          .pipe(
            map((respons: any) => {
              const success: any = respons.data.deleteParticipantPlanning;
              this.notification.success('Suppression', success, {
                nzKey: 'DELETE_PARTICIPANT_PLANNING_EVENT',
                nzPlacement: NOTIFICATION_PLACEMENT,
                nzDuration: 6000,
              });

              return fromParticipantPlanningAction.deleteParticipantPlanningSuccess(
                { success }
              );
            }),
            catchError((error) => {
              this.onErrorNotification(
                error,
                'DELETE_PARTICIPANT_PLANNING_EVENT'
              );
              return of(
                fromParticipantPlanningAction.deleteParticipantPlanningFailure({
                  error,
                })
              );
            })
          )
      )
    )
  );

  deleteParticipantPlanningEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromParticipantPlanningAction.deleteParticipantPlannings),
      tap(() => {
        this.notification.info(
          'Tout supprimer',
          'Suppression des documents en cours...',
          {
            nzKey: 'DELETE_PARTICIPANT_PLANNING_EVENTS',
            nzPlacement: NOTIFICATION_PLACEMENT,
            nzDuration: 6000,
          }
        );
      }),
      mergeMap((action) =>
        this.participantPlanningService.deletePlanningEvents(action.id).pipe(
          map((respons: any) => {
            const success: any = respons.data.deleteParticipantPlannings;
            this.notification.success('Tout supprimer', success, {
              nzKey: 'DELETE_PARTICIPANT_PLANNING_EVENTS',
              nzPlacement: NOTIFICATION_PLACEMENT,
              nzDuration: 6000,
            });

            return fromParticipantPlanningAction.deleteParticipantPlanningsSuccess(
              { success }
            );
          }),
          catchError((error) => {
            this.onErrorNotification(
              error,
              'DELETE_PARTICIPANT_PLANNING_EVENTS'
            );
            return of(
              fromParticipantPlanningAction.deleteParticipantPlanningsFailure({
                error,
              })
            );
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private notification: NzNotificationService,
    private participantPlanningService: ParticipantPlanningService
  ) {}

  onErrorNotification(error: any, key: string): void {
    this.notification.error('Error', error.message, {
      nzDuration: 10000,
      nzAnimate: true,
      nzPlacement: NOTIFICATION_PLACEMENT,
      nzKey: key ? key : '',
    });
  }
}
