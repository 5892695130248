import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { v4 as uuidV4 } from 'uuid';
import { isEmpty, omit } from 'lodash';
import { Update } from '@ngrx/entity';

@Component({
  selector: 'app-candidature-form-person-item',
  templateUrl: './candidature-form-person-item.component.html',
  styleUrls: ['./candidature-form-person-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CandidatureFormPersonItemComponent
  implements OnInit, OnChanges, OnDestroy
{
  subscribe = new Subject();
  step: number = 0;
  currentPersonIdentity$ = new BehaviorSubject<any>(null);
  currentPersonDiagnostic$ = new BehaviorSubject<any>(null);
  currentPersonPresentation$ = new BehaviorSubject<any>(null);
  today = new Date(Date.now());
  isCandiatureValid: boolean = false;

  // FORM FIELDS
  etablissementUniteLegaleForm = new FormGroup({
    statutDiffusionUniteLegale: new FormControl(''),
    unitePurgeeUniteLegale: new FormControl(''),
    dateCreationUniteLegale: new FormControl(''),
    sigleUniteLegale: new FormControl(''),
    sexeUniteLegale: new FormControl(''),
    prenom1UniteLegale: new FormControl(''),
    prenom2UniteLegale: new FormControl(''),
    prenom3UniteLegale: new FormControl(''),
    prenom4UniteLegale: new FormControl(''),
    prenomUsuelUniteLegale: new FormControl(''),
    pseudonymeUniteLegale: new FormControl(''),
    identifiantAssociationUniteLegale: new FormControl(''),
    trancheEffectifsUniteLegale: new FormControl(''),
    anneeEffectifsUniteLegale: new FormControl(''),
    dateDernierTraitementUniteLegale: new FormControl(''),
    nombrePeriodesUniteLegale: new FormControl(0),
    categorieEntreprise: new FormControl(''),
    anneeCategorieEntreprise: new FormControl(''),
    etatAdministratifUniteLegale: new FormControl(''),
    nomUniteLegale: new FormControl(''),
    denominationUniteLegale: new FormControl(''),
    denominationUsuelle1UniteLegale: new FormControl(''),
    denominationUsuelle2UniteLegale: new FormControl(''),
    denominationUsuelle3UniteLegale: new FormControl(''),
    activitePrincipaleUniteLegale: new FormControl(''),
    categorieJuridiqueUniteLegale: new FormControl(''),
    nicSiegeUniteLegale: new FormControl(''),
    nomenclatureActivitePrincipaleUniteLegale: new FormControl(''),
    nomUsageUniteLegale: new FormControl(''),
    economieSocialeSolidaireUniteLegale: new FormControl(''),
    societeMissionUniteLegale: new FormControl(''),
    caractereEmployeurUniteLegale: new FormControl(''),
    activite_principale_libelle: new FormGroup({
      intitule_naf: new FormControl(''),
      intitule_naf_40: new FormControl(''),
      intitule_naf_65: new FormControl(''),
      code_naf: new FormControl(''),
    }),
    categorie_juridique_libelle: new FormGroup({
      libelle: new FormControl(''),
      code: new FormControl(''),
    }),
  });
  etablissementAdresseCommuneForm = new FormGroup({
    code: new FormControl(''),
    nom: new FormControl(''),
    codesPostaux: new FormControl([]),
    siren: new FormControl(''),
    codeEpci: new FormControl(''),
    codeDepartement: new FormControl(''),
    codeRegion: new FormControl(''),
    epci: new FormGroup({
      code: new FormControl(''),
      nom: new FormControl(''),
      type: new FormControl(''),
      financement: new FormControl(''),
      codesDepartements: new FormControl(['']),
      codesRegions: new FormControl(['']),
      population: new FormControl(0),
      surface: new FormControl(0),
      centre: new FormControl(null),
      contour: new FormControl(null),
      bbox: new FormControl(null),
    }),
    departement: new FormGroup({
      code: new FormControl(''),
      nom: new FormControl(''),
      codeRegion: new FormControl(''),
      region: new FormGroup({}),
    }),
    region: new FormGroup({
      code: new FormControl(''),
      nom: new FormControl(''),
    }),
    population: new FormControl(0),
    surface: new FormControl(0),
    centre: new FormControl(null),
    contour: new FormControl(null),
    mairie: new FormControl(null),
    bbox: new FormControl(null),
  });
  etablissementAdresseForm = new FormGroup({
    complementAdresseEtablissement: new FormControl(''),
    numeroVoieEtablissement: new FormControl(''),
    indiceRepetitionEtablissement: new FormControl(''),
    typeVoieEtablissement: new FormControl(''),
    libelleVoieEtablissement: new FormControl(''),
    codePostalEtablissement: new FormControl(''),
    libelleCommuneEtablissement: new FormControl(''),
    libelleCommuneEtrangerEtablissement: new FormControl(''),
    distributionSpecialeEtablissement: new FormControl(''),
    codeCommuneEtablissement: new FormControl(''),
    codeCedexEtablissement: new FormControl(''),
    libelleCedexEtablissement: new FormControl(''),
    codePaysEtrangerEtablissement: new FormControl(''),
    libellePaysEtrangerEtablissement: new FormControl(''),
    fullAdresse: new FormControl(''),
    commune: this.etablissementAdresseCommuneForm,
  });
  candidatureForm: FormGroup = new FormGroup(
    {
      id: new FormControl(uuidV4(), Validators.required),
      civilite: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      firstName: new FormControl('', Validators.required),
      fonction: new FormControl(''),
      role: new FormControl(''),
      genre: new FormControl(''),
      age: new FormControl(0),
      tranche: new FormControl(''),
      dateNaissance: new FormControl(this.today, Validators.required),
      coordonnees: new FormGroup({
        mobile: new FormControl(''),
        phoneNumber: new FormControl(''),
        fixe: new FormControl(''),
        email: new FormControl(''),
      }),
      adresse: new FormGroup({
        adresse: new FormGroup({
          properties: new FormGroup({
            label: new FormControl(''),
            score: new FormControl(''),
            housenumber: new FormControl(''),
            id: new FormControl(''),
            type: new FormControl(''),
            name: new FormControl(''),
            postcode: new FormControl(''),
            citycode: new FormControl(''),
            x: new FormControl(''),
            y: new FormControl(''),
            city: new FormControl(''),
            context: new FormControl(''),
            importance: new FormControl(''),
            street: new FormControl(''),
          }),
          geometry: new FormGroup({
            type: new FormControl(''),
            coordinates: new FormControl(''),
          }),
        }),
        complement: new FormControl(''),
        qpv: new FormControl(''),
      }),
      diagnostic: new FormGroup({
        administratif: new FormGroup({
          dateRecrutement: new FormControl<Date | any>('', Validators.required),
          dateInscription: new FormControl<Date | any>(''),
          dateEntree: new FormControl<Date | any>(''),
          numbCarteSejour: new FormControl(''),
          numCaiCir: new FormControl(''),
          criteres1: new FormControl<{
            justificatif: string;
            label: string;
            value: string;
          }>(
            {
              justificatif: '',
              label: '',
              value: '',
            },
            Validators.required
          ),
          typeCarteSejour: new FormControl<{
            info: string;
            titre: string;
            type: string;
          }>({
            info: '',
            titre: '',
            type: '',
          }),
          criteres2: new FormControl<{ info: string; item: string }[] | any>(
            {} || [],
            Validators.required
          ),
          isPassIae: new FormControl(false, Validators.required),
          isFse: new FormControl(false, Validators.required),
          isFrein: new FormControl<boolean>(false, Validators.required),
          dateStartPassIae: new FormControl<Date | any>(null),
          dateEndPassIae: new FormControl<Date | any>(null),
        }),
        presentation: new FormControl(''),
      }),
      etablissement: new FormGroup({
        siren: new FormControl(''),
        nic: new FormControl(''),
        siret: new FormControl(''),
        statutDiffusionEtablissement: new FormControl(''),
        dateCreationEtablissement: new FormControl(''),
        trancheEffectifsEtablissement: new FormControl(''),
        anneeEffectifsEtablissement: new FormControl(''),
        activitePrincipaleRegistreMetiersEtablissement: new FormControl(''),
        dateDernierTraitementEtablissement: new FormControl(''),
        etablissementSiege: new FormControl(false),
        nombrePeriodesEtablissement: new FormControl(0),
        uniteLegale: this.etablissementUniteLegaleForm,
        adresseEtablissement: this.etablissementAdresseForm,
        adresse2Etablissement: this.etablissementAdresseForm,
        periodesEtablissement: new FormGroup([]),
        conventionsCollectives: new FormGroup({
          activitePrincipale: new FormControl(''),
          activitePrincipaleUniteLegale: new FormControl(''),
          caractereEmployeurUniteLegale: new FormControl(''),
          categorieJuridiqueUniteLegale: new FormControl(''),
          conventions: new FormControl([]),
          dateCreationUniteLegale: new FormControl(''),
          dateDebut: new FormControl(''),
          etablissements: new FormControl(0),
          etatAdministratifUniteLegale: new FormControl(''),
          highlightLabel: new FormControl(''),
          label: new FormControl(''),
          matching: new FormControl(0),
          simpleLabel: new FormControl(''),
          siren: new FormControl(''),
          activitePrincipaleEtablissement: new FormControl(''),
          address: new FormControl(''),
          categorieEntreprise: new FormControl(''),
          codeCommuneEtablissement: new FormControl(''),
          codePostalEtablissement: new FormControl(''),
          etablissementSiege: new FormControl(false),
          etatAdministratifEtablissement: new FormControl(''),
          idccs: new FormControl([]),
          libelleCommuneEtablissement: new FormControl(''),
          siret: new FormControl(''),
        }),
      }),
      setting: new FormGroup({
        color: new FormControl(''),
      }),
    },
    Validators.required
  );

  @Input() isUpdate: boolean = false;
  @Input() isList: boolean = true;
  @Input() index: number = 0;
  @Input() isAction: boolean = false;
  @Input() candidatInput: any;
  @Output() currentChanges = new EventEmitter();
  @Output() add = new EventEmitter();
  @Output() update = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @Output() back = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    this.onCandidatureChanges();
    this.onCheckPersonValidation();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (!changes?.candidatInput?.currentValue) return;
    this.onSetCandidats(changes?.candidatInput?.currentValue);
  }
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  /**
   * Écouter les changements de tabulation
   * @param step
   * @param index
   */
  onIndexChanges(step: number, index: number): void {
    this.step = step;

    switch (step) {
      case 0:
        this.onSetCurrentIdentity(index);
        break;
      case 1:
        this.onSetCurrentDiagnostic(index);
        break;
      default:
        break;
    }
  }

  //ajouter des candidats existants
  onSetCandidats(candidat: any): void {
    if (!candidat) return;
    this.onSetCandidat(candidat);
    this.onSetCurrentIdentity(0);
  }

  /**
   * Renseigner le formulaire de candidature
   * @param item
   * @returns
   */
  onSetCandidat(candidat: any): void {
    if (!candidat.person) return;

    const person = candidat.person;

    this.candidatureForm.patchValue({
      id: candidat.id,
      civilite: person.civilite,
      lastName: person.lastName,
      firstName: person.firstName,
      fonction: person.fonction,
      role: person.role,
      genre: person.genre,
      age: person.age,
      tranche: person.tranche,
      dateNaissance: person.dateNaissance,
      coordonnees: {
        mobile: person.coordonnees.mobile,
        phoneNumber: person.coordonnees.phoneNumber,
        fixe: person.coordonnees.fixe,
        email: person.coordonnees.email,
      },
      adresse: {
        adresse: {
          properties: {
            label: person.adresse.adresse.properties.label,
            score: person.adresse.adresse.properties.score,
            housenumber: person.adresse.adresse.properties.housenumber,
            id: person.adresse.adresse.properties.id,
            type: person.adresse.adresse.properties.type,
            name: person.adresse.adresse.properties.name,
            postcode: person.adresse.adresse.properties.postcode,
            citycode: person.adresse.adresse.properties.citycode,
            x: person.adresse.adresse.properties.x,
            y: person.adresse.adresse.properties.y,
            city: person.adresse.adresse.properties.city,
            context: person.adresse.adresse.properties.context,
            importance: person.adresse.adresse.properties.importance,
            street: person.adresse.adresse.properties.street,
          },
          geometry: {
            type: person.adresse.adresse.geometry.type,
            coordinates: person.adresse.adresse.geometry.coordinates,
          },
        },
        complement: person.adresse.complement,
        qpv: person.adresse.qpv,
      },
      diagnostic: {
        administratif: {
          dateRecrutement: person.diagnostic.administratif.dateRecrutement,
          dateInscription: person.diagnostic.administratif.dateInscription,
          dateEntree: person.diagnostic.administratif.dateEntree,
          numbCarteSejour: person.diagnostic.administratif.numbCarteSejour,
          numCaiCir: person.diagnostic.administratif.numCaiCir,
          criteres1: {
            justificatif:
              person.diagnostic.administratif.criteres1.justificatif,
            label: person.diagnostic.administratif.criteres1.label,
            value: person.diagnostic.administratif.criteres1.value,
          },

          typeCarteSejour: {
            info: person.diagnostic.administratif.typeCarteSejour.info,
            titre: person.diagnostic.administratif.typeCarteSejour.titre,
            type: person.diagnostic.administratif.typeCarteSejour.type,
          },
          criteres2: person.diagnostic.administratif.criteres2 || [],
          isPassIae: person.diagnostic.administratif.isPassIae,
          isFse: person.diagnostic.administratif.isFse,
          isFrein: person.diagnostic.administratif.isFrein,
          dateStartPassIae: person.diagnostic.administratif.dateStartPassIae,
          dateEndPassIae: person.diagnostic.administratif.dateEndPassIae,
        },
        presentation: person.diagnostic?.presentation || '',
      },
      etablissement: { ...person.etablissement },
      setting: {
        color: person.setting.color,
      },
    });

    this.currentPersonIdentity$.next(person);

    this.step = 0;
  }

  /**
   * Actualiser l'identité du candidat
   * @param index
   */
  onSetCurrentIdentity(index: number): void {
    const itemFormValue = this.candidatureForm?.value;
    const identity = {
      id: itemFormValue.id,
      civilite: itemFormValue.civilite,
      lastName: itemFormValue.lastName,
      firstName: itemFormValue.firstName,
      fonction: itemFormValue.fonction,
      avatar: itemFormValue.avatar,
      displayName: itemFormValue.displayName,
      role: itemFormValue.role,
      genre: itemFormValue.genre,
      age: itemFormValue.age,
      tranche: itemFormValue.tranche,
      dateNaissance: itemFormValue.dateNaissance,
      coordonnees: itemFormValue.coordonnees,
      adresse: itemFormValue.adresse,
      etablissement: itemFormValue.etablissement,
      diagnostic: itemFormValue.diagnostic,
      setting: itemFormValue.setting,
      isSelected: itemFormValue.isSelected,
      isRejected: itemFormValue.isRejected,
      isInterviewed: itemFormValue.isInterviewed,
      isValided: itemFormValue.isValided,
    };

    this.currentPersonIdentity$.next(identity);
  }

  /**
   * Actualiser le diagnostic du candidat
   * @param index
   */
  onSetCurrentDiagnostic(index: number): void {
    const itemFormValue = this.candidatureForm?.value;
    const administratif = itemFormValue.diagnostic.administratif;

    this.currentPersonDiagnostic$.next(administratif);
  }

  /**
   * Ajouter l'identité du candidat
   * @param person
   * @param index
   */
  onPersonIdentityChanges(person: any, index: number): void {
    this.candidatureForm.patchValue({ ...person });
  }

  /**
   * Ajouter un diagnostic un candiat
   * @param diagAdministratif
   * @param index
   */
  onPersonDiagnosticChanges(diagAdministratif: any, index: number): void {
    this.candidatureForm.patchValue({
      diagnostic: {
        administratif: diagAdministratif,
      },
    });
  }

  /**
   * Validation des informations liées au candidat
   */
  onCheckPersonValidation(): void {
    this.candidatureForm.valueChanges
      .pipe(takeUntil(this.subscribe))
      .subscribe((person: any) => {
        if (!person) return;

        this.isCandiatureValid = this.onCheckPerson(person);
      });
  }

  /**
   * Vérification du minium d'informations à rensiegner pour qu'un candidat soit valide
   * @param person Informations sur la person renseignée
   * @returns
   */
  onCheckPerson(person: any): boolean {
    const checkPerson: boolean =
      isEmpty(person.lastName) ||
      isEmpty(person.firstName) ||
      !person.dateNaissance ||
      isEmpty(person.adresse.adresse.properties.label) ||
      isEmpty(person.diagnostic.administratif?.criteres1?.label) ||
      !person.diagnostic.administratif?.criteres2?.length
        ? false
        : true;

    return checkPerson;
  }

  /**
   * Valider la création d'un candidat
   */
  onAdd(): void {
    const formValue = this.candidatureForm?.value;
    this.add.emit(formValue);
  }

  /**
   * Valider les midifications du formulaire candidat
   * @param candidat
   * @returns
   */
  onUpdate(candidat: any): void {
    if (!candidat) return;
    const formValue = this.candidatureForm?.value;
    const person = {
      ...candidat.person,
      ...formValue,
    };
    const nextPerson = omit(person, [
      'dateStart',
      'dateUpdate',
      'updateUser',
      'auteur',
    ]);
    const changes = {
      ...candidat,
      person: nextPerson,
    };

    const nextCandidat: Update<any> = {
      id: candidat.id,
      changes: changes,
    };

    this.update.emit(nextCandidat);
  }

  /**
   * Streaming des changes d'informations dans le formulaire
   */
  onCandidatureChanges(): void {
    this.candidatureForm.valueChanges
      .pipe(takeUntil(this.subscribe))
      .subscribe((candidat) => {
        this.currentChanges.emit(candidat);
      });
  }

  /**
   * Annuler le formulaire
   */
  onCancel(): void {
    this.cancel.emit(true);
  }

  /**
   * Action  de retour vers la fenêtre précédente
   */
  onBack(): void {
    this.back.emit(true);
  }
}
