<div
  class="organisationDetail__container"
  *ngIf="organisation$ | async as organisation"
>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col class="organisationDetail__content" nzSpan="12">
      <nz-card [nzTitle]="organisationDetail_identity">
        <ng-template #organisationDetail_identity>
          <app-header-title
            [icon]="'business'"
            [title]="'Identité'"
            [subtitle]="true"
          ></app-header-title>
        </ng-template>
        <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
          <nz-col nzSpan="2"></nz-col>

          <nz-col nzSpan="20">
            <app-organisation-card
              [source]="organisation"
            ></app-organisation-card>
          </nz-col>
          <nz-col nzSpan="2"></nz-col>
        </nz-row>
      </nz-card>
    </nz-col>
    <nz-col class="organisationDetail__content" nzSpan="12">
      <nz-card [nzTitle]="organisationDetail_admin">
        <ng-template #organisationDetail_admin>
          <app-header-title
            [icon]="'admin_panel_settings'"
            [title]="'Identifiants administratifs'"
            [subtitle]="true"
          ></app-header-title>
        </ng-template>
        <nz-descriptions
          nzBordered
          nzSize="small"
          [nzColumn]="{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }"
        >
          <nz-descriptions-item nzTitle="Siret" nzSpan="1">
            {{ organisation?.etablissement?.etablissement_siege?.siret }}
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="Activité" nzSpan="1">
            <div class="organsationDetail_Activity__container">
              {{
                organisation?.etablissement?.activite_principale_libelle
                  ?.intitule_naf
              }}
            </div>
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="Catégorie" nzSpan="1">
            {{
              organisation?.etablissement?.categorie_juridique_libelle?.libelle
            }}
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="Mise à jour" nzSpan="1">
            <!-- {{ source?.custom?.dateUpdate | dateFr: "small" }} -->
          </nz-descriptions-item>
        </nz-descriptions>
      </nz-card>
    </nz-col>
  </nz-row>
  <nz-space [nzSize]="50" nzDirection="vertical"></nz-space>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col class="organisationDetail__content" nzSpan="12">
      <nz-card [nzTitle]="organisationDetail_coordonnees">
        <ng-template #organisationDetail_coordonnees>
          <app-header-title
            [icon]="'room'"
            [title]="'Coordonnées'"
            [subtitle]="true"
          ></app-header-title>
        </ng-template>

        <nz-descriptions
          nzBordered
          nzSize="small"
          [nzColumn]="{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }"
        >
          <nz-descriptions-item nzTitle="Adresse" nzSpan="1">
            {{ organisation?.etablissement?.etablissement_siege?.geo_adresse }}
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="Code postal" nzSpan="1">
            {{ organisation?.etablissement?.etablissement_siege?.code_postal }}
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="Commune" nzSpan="1">
            {{
              organisation?.etablissement?.etablissement_siege?.libelle_commune
            }}
          </nz-descriptions-item>
        </nz-descriptions>
      </nz-card>
    </nz-col>
    <nz-col class="organisationDetail__content" nzSpan="12">
      <nz-card [nzTitle]="organisationDetail_adonnement">
        <nz-descriptions
          nzBordered
          nzSize="small"
          [nzColumn]="{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }"
        >
          <nz-descriptions-item nzTitle="Type" nzSpan="1">
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="Prochain prélèvement" nzSpan="1">
          </nz-descriptions-item>

          <nz-descriptions-item nzTitle="Administrateur" nzSpan="1">
          </nz-descriptions-item>
        </nz-descriptions>
      </nz-card>
      <ng-template #organisationDetail_adonnement>
        <app-header-title
          [icon]="'assured_workload'"
          [title]="'Abonnement'"
          [subtitle]="true"
        ></app-header-title>
      </ng-template>
    </nz-col>
  </nz-row>
  <nz-space [nzSize]="50" nzDirection="vertical"></nz-space>
</div>
