import { Injectable } from '@angular/core';
import { gql, Query } from 'apollo-angular';

@Injectable({
  providedIn: 'any',
})
export class QueryNiveauExperienceContratGQLService extends Query<Response> {
  document = gql`
    query ReferentielNiveauExperience {
      referentielNiveauExperience {
        caracteristiques {
          codeTypeCaracteristique
          libelleCaracteristique
        }
      }
    }
  `;
}
