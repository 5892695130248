import { Pipe, PipeTransform } from '@angular/core';
import {
  fromUnixTime,
  format,
  formatDistanceToNow,
  isDate,
  getMonth,
  getYear,
  getDate,
} from 'date-fns';
import { fr } from 'date-fns/locale';

@Pipe({
  name: 'dateFr',
})
export class DateFrPipe implements PipeTransform {
  transform(value: any, arg?: string): any {
    if (!value || !this.isValidDate(value)) {
      return '';
    } else if (arg === 'small') {
      const time: number = value['seconds']
        ? value['seconds']
        : value['_seconds'];
      const unix: Date = fromUnixTime(time);
      const newFormat = format(unix, 'P', { locale: fr });
      return newFormat;
    } else if (arg === 'medium') {
      const time: number = value['seconds']
        ? value['seconds']
        : value['_seconds'];
      const unix: Date = fromUnixTime(time);
      const newFormat = format(unix, 'PP', { locale: fr });
      return newFormat;
    } else if (arg === 'time') {
      const time: number = value['seconds']
        ? value['seconds']
        : value['_seconds'];
      const unix: Date = fromUnixTime(time);
      const newFormat = format(unix, 'dd/MM/yyyy HH:mm', { locale: fr });
      return newFormat;
    } else if (arg === 'toFr') {
      const time: Date = new Date(value);
      const newFormat = format(time, 'dd/MM/yyyy HH:mm:ss', { locale: fr });
      return newFormat;
    } else if (arg === 'monthYear') {
      const time: number = value['seconds']
        ? value['seconds']
        : value['_seconds'];
      const unix: Date = fromUnixTime(time);
      const newFormat = format(unix, 'MM/yyyy', { locale: fr });
      return newFormat;
    } else if (arg === 'date') {
      const time: number = value['seconds']
        ? value['seconds']
        : value['_seconds'];
      const unix: Date = fromUnixTime(time);
      const newFormat = getDate(unix);

      return newFormat;
    } else if (arg === 'month') {
      const time: number = value['seconds']
        ? value['seconds']
        : value['_seconds'];
      const unix: Date = fromUnixTime(time);
      const newFormat = getMonth(unix);

      return newFormat;
    } else if (arg === 'year') {
      const time: number = value['seconds']
        ? value['seconds']
        : value['_seconds'];
      const unix: Date = fromUnixTime(time);
      const newFormat = getYear(unix);
      return newFormat;
    } else if (arg === 'distanceSuffix') {
      const time: number = value['seconds']
        ? value['seconds']
        : value['_seconds'];
      const unix: Date = fromUnixTime(time);
      const newFormat = formatDistanceToNow(unix, {
        addSuffix: true,
        locale: fr,
      });
      return newFormat;
    } else if (arg === 'distance') {
      const time: number = value['seconds']
        ? value['seconds']
        : value['_seconds'];
      const unix: Date = fromUnixTime(time);
      const newFormat = formatDistanceToNow(unix, { locale: fr });
      return newFormat;
    } else if (arg === 'long') {
      const time: number = value['seconds']
        ? value['seconds']
        : value['_seconds'];
      const unix: Date = fromUnixTime(time);
      const newFormat = format(unix, 'PPP', { locale: fr });
      return newFormat;
    } else {
      const time: number = value['seconds']
        ? value['seconds']
        : value['_seconds'];
      const unix: Date = fromUnixTime(time);
      const newFormat = format(unix, 'PPPP', { locale: fr });
      return newFormat;
    }
  }

  isValidDate(value: any): boolean {
    const time: number = value['seconds']
      ? value['seconds']
      : value['_seconds'];

    const date: Date = fromUnixTime(time);

    return isDate(date);
  }
}
