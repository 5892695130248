import { BehaviorSubject } from 'rxjs';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-participant-diagnostic-form-steps',
  templateUrl: './participant-diagnostic-form-steps.component.html',
  styleUrls: ['./participant-diagnostic-form-steps.component.scss'],
})
export class ParticipantDiagnosticFormStepsComponent implements OnInit {
  currentStep: number = 0;

  @Input() view$ = new BehaviorSubject<string>('particpant');
  @Output() step = new EventEmitter<number>(false);
  constructor() {}

  ngOnInit(): void {}

  onStep(index: number): void {
    this.currentStep = index;
    this.step.emit(index);
  }
}
