<div class="w-full flex flex-col items-start justify-center">
  <label [class]="required ? 'label required' : 'label'">
    <span *ngIf="type === 'Mobile'">Mobile</span>
    <span *ngIf="type === 'Fixe'">Fixe</span>
  </label>

  <div class="relative">
    <div
      class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
    >
      <svg
        *ngIf="type === 'Mobile'"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="iconSvg text-slate-800"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
        />
      </svg>
    </div>
    <div
      class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
    >
      <svg
        *ngIf="type === 'Fixe'"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="iconSvg text-slate-800"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
        />
      </svg>
    </div>
    <input
      [minlength]="10"
      [maxlength]="10"
      [placeholder]="type"
      [formControl]="phoneForm"
      (keyup)="setPhone($event)"
      required
      class="inputIconForm"
    />
  </div>

  <!-- <nz-input-group
      [nzPrefix]="prefixTemplateFixe"
      [nzSuffix]="suffixTemplateFixe"
      *ngSwitchCase="false"
      nzCompact
    >
      <input
        nz-input
        [nzBorderless]="borderless"
        type="text"
        minlength="10"
        maxlength="10"
        [placeholder]="type"
        [formControl]="phoneForm"
        (keyup)="setPhone($event)"
      />
    </nz-input-group>

    <nz-input-group
      [nzPrefix]="prefixTemplateMobile"
      [nzSuffix]="suffixTemplateFixe"
      *ngSwitchDefault
      nzCompact
    >
      <input
        nz-input
        [nzBorderless]="borderless"
        type="text"
        minlength="10"
        maxlength="10"
        [placeholder]="type"
        [formControl]="phoneForm"
        (keyup)="setPhone($event)"
        required
      />
    </nz-input-group> -->

  <ng-template #prefixTemplateFixe>
    <i nz-icon nzType="phone"></i>
  </ng-template>
  <ng-template #prefixTemplateMobile>
    <i nz-icon nzType="mobile"></i>
  </ng-template>
  <ng-template #suffixTemplateFixe>
    <i nz-icon nzType="clear" *ngIf="phoneForm.dirty" (click)="onClear()"></i>
  </ng-template>
</div>

<small
  *ngIf="!phoneForm.valid && phoneForm.dirty && phoneForm.touched && required"
  class="inputFormAlert"
  >{{
    !phoneForm.valid && phoneForm.dirty && phoneForm.touched && required
      ? "Veuillez renseigner le " + (type | titlecase)
      : ""
  }}</small
>
