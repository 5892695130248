<form nz-form [formGroup]="ficheExportForm">
  <nz-card nzSize="small">
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="center">
      <nz-col nzSpan="8"></nz-col>
      <nz-col nzSpan="4">
        <div class="w-full space-y-4">
          <label class="label">Format</label>
          <nz-form-control>
            <i nz-icon nzType="file-pdf"></i>
          </nz-form-control>
        </div>
      </nz-col>
      <nz-col nzSpan="4">
        <div class="w-full space-y-4">
          <label class="label">Anonymiser</label>
          <nz-form-control>
            <label nz-checkbox formControlName="isAnonyme">
              {{ ficheExportForm.value?.isAnonyme ? "Oui" : "Non" }}
            </label>
          </nz-form-control>
        </div>
      </nz-col>
      <nz-col nzSpan="8"></nz-col>
    </nz-row>
    <nz-divider
      *ngIf="isReferentSelect"
      nzOrientation="left"
      nzType="horizontal"
      nzText="Référent du recrutement"
    ></nz-divider>
    <ng-container *ngIf="isReferentSelect && users?.length">
      <div class="select-menu">
        <nz-select
          formControlName="referentRecrutement"
          class="w-full bg-transparent border-0"
          nzBorderless
          [nzOptionHeightPx]="40"
          nzAllowClear
          [nzDropdownStyle]="{ height: '100px' }"
          nzPlaceHolder="Sélectionner un référent"
        >
          <nz-option
            *ngFor="let item of users"
            [nzValue]="item"
            [nzLabel]="item?.displayName"
            nzCustomContent
          >
            <div class="w-full flex items-start space-x-6">
              <div class="w-20">
                <app-avatar-group [source]="[item]"></app-avatar-group>
              </div>
              <div class="w-2/3">
                <p class="font-bold text-sm">{{ item?.displayName }}</p>
              </div>
            </div>
          </nz-option>
        </nz-select>
      </div>
    </ng-container>

    <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
    <div class="w-full flex items-end space-x-6">
      <div class="w-3/4"></div>
      <div class="w-20">
        <button class="btn btn-sm btn-ghost" (click)="onCancel()">
          Annuler
        </button>
      </div>
      <div class="w-20">
        <button class="btn btn-sm btn-primary" (click)="onValidation()">
          Exporter
        </button>
      </div>
    </div>
  </nz-card>
</form>
