import {
  OperationSmall,
  OperationId,
  Operation,
} from './../components/operation/store/operation.model';
import { format } from 'date-fns';
import { OperationState } from './../components/operation/store/operation.reducer';
import { Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  doc,
  docSnapshots,
  getDoc,
  collectionChanges,
  addDoc,
  setDoc,
  deleteDoc,
  serverTimestamp,
  Timestamp,
  query,
  where,
  arrayUnion,
  arrayRemove,
  collectionSnapshots,
  getDocs,
  DocumentData,
  DocumentSnapshot,
} from '@angular/fire/firestore';
import { Observable, of, from, EMPTY } from 'rxjs';
import { switchMap, mergeMap, map, catchError } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { UserState } from '../components/user/store/user.reducer';
import * as fromUserSelector from '../components/user/store/user.selectors';
import { v4 as uuidV4 } from 'uuid';
import { fromUnixTime } from 'date-fns';
import { fr } from 'date-fns/locale';

@Injectable({
  providedIn: 'any',
})
export class OperationCadrageService {
  constructor(
    private db: Firestore,
    private fns: Functions,
    private userStore: Store<UserState>,
    private operationStore: Store<OperationState>
  ) {}

  getOne(operationId: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !operationId) {
            return of(null);
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/operations/${operationId}/cadrages`
            );

            const documentRef = doc(dbCollection, operationId);
            return docSnapshots(documentRef).pipe(
              map((snap) => {
                const data = snap.data();
                const id = snap.id;
                return { id, ...data };
              }),
              catchError((error) => error)
            );
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  updateOne(
    id: string | number,
    cadrage: Partial<any>,
    operationId: string
  ): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) {
            return of(null);
          } else {
            const documentRef = doc(
              this.db,
              `organisations/${user.organisation.id}/operations/${operationId}/cadrages/${operationId}`
            );
            const nextDocument = {
              ...cadrage,
              dateUpdate: serverTimestamp(),
              userUpdate: user,
            };
            return from(
              setDoc(documentRef, { ...nextDocument }, { merge: true })
                .then(() => `Cadrage modifié`)
                .catch((error) => error)
            );
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }
}
