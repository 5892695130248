import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { OperationCandidatureState } from '../../store/operation-fiche-de-poste-candidature.reducer';
import * as fromOperationCandidatureAction from '../../store/operation-fiche-de-poste-candidature.actions';
import * as fromOperationCandidatureSelector from '../../store/operation-fiche-de-poste-candidature.selectors';
import { BehaviorSubject, Observable } from 'rxjs';
import { Update } from '@ngrx/entity';
import { omit } from 'lodash';
import { OperationFicheCandidatDocumentState } from 'src/app/components/operation-fiche-candidat-documents/store/operation-fiche-candidat-document.reducer';
import * as fromOperationFicheCandidatDocumentAction from 'src/app/components/operation-fiche-candidat-documents/store/operation-fiche-candidat-document.actions';
import * as fromOperationFicheCandidatDocumentSelector from 'src/app/components/operation-fiche-candidat-documents/store/operation-fiche-candidat-document.selectors';
import { OperationFicheCandidatNoteState } from 'src/app/components/operation-fiche-candidat-notes/store/operation-fiche-candidat-note.reducer';
import * as fromOperationFicheCandidatNoteAction from 'src/app/components/operation-fiche-candidat-notes/store/operation-fiche-candidat-note.actions';
import * as fromOperationFicheCandidatNoteSelector from 'src/app/components/operation-fiche-candidat-notes/store/operation-fiche-candidat-note.selectors';

@Component({
  selector: 'app-operation-fiche-de-poste-candidatures',
  templateUrl: './operation-fiche-de-poste-candidatures.component.html',
  styleUrls: ['./operation-fiche-de-poste-candidatures.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationFicheDePosteCandidaturesComponent
  implements OnInit, OnChanges
{
  currentView$ = new BehaviorSubject<string>('display');

  @Input() loading$: Observable<boolean> =
    this.operationCandidatureStore.select(
      fromOperationCandidatureSelector.loading
    );
  @Input() candidat$: Observable<boolean> =
    this.operationCandidatureStore.select(
      fromOperationCandidatureSelector.candidat
    );
  @Input() candidats: any[] = [];
  @Input() fiche: any = null;

  constructor(
    private operationCandidatureStore: Store<OperationCandidatureState>,
    private operationFicheCandidatDocumentStore: Store<OperationFicheCandidatDocumentState>,
    private operationFicheCandidatNoteStore: Store<OperationFicheCandidatNoteState>
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}

  /**
   * Écoute des actions sélectionnées
   * @param data - Informations regroupant l'action et le candidat concerné
   * @param fiche - Fiche de poste concernée
   */
  onCandidatActions(data: { event: string; candidat: any }, fiche: any): void {
    const event: string = data.event;
    this.operationCandidatureStore.dispatch(
      fromOperationCandidatureAction.setOperationFicheCandidatView({
        view: event,
      })
    );
    const candidat: any = data.candidat;
    const candidatOmitFields = omit(candidat.person, [
      'dateStart',
      'dateUpdate',
      'updateUser',
      'auteur',
    ]);

    this.operationFicheCandidatNoteStore.dispatch(
      fromOperationFicheCandidatNoteAction.setViewOperationFicheCandidatNotes({
        view: event,
      })
    );
    this.operationFicheCandidatDocumentStore.dispatch(
      fromOperationFicheCandidatDocumentAction.setViewOperationFicheCandidatDocuments(
        { view: event }
      )
    );

    switch (event) {
      case 'delete':
        this.onDeleteCandidat(candidat, fiche);
        break;
      case 'Valider':
        const candidatValidation: Update<any> = {
          id: candidat.id,
          changes: {
            ...candidat,
            person: {
              ...candidatOmitFields,
              isValided: true,
              isRejected: false,
            },
          },
        };
        this.onUpdateCandidat(candidatValidation, fiche);

        break;
      case 'Entretien':
        const candidatEntretien: Update<any> = {
          id: candidat.id,
          changes: {
            ...candidat,
            person: { ...candidatOmitFields, isInterviewed: true },
          },
        };
        this.onUpdateCandidat(candidatEntretien, fiche);

        break;
      case 'Rejeter':
        const candidatRejected: Update<any> = {
          id: candidat.id,
          changes: {
            ...candidat,
            person: {
              ...candidatOmitFields,
              isValided: false,
              isRejected: true,
              isSelected: false,
            },
          },
        };
        this.onUpdateCandidat(candidatRejected, fiche);

        break;
      case 'Selectionner':
        const candidatSelected: Update<any> = {
          id: candidat.id,
          changes: {
            ...candidat,
            person: {
              ...candidatOmitFields,
              isSelected: true,
              isValided: true,
              isRejected: false,
            },
          },
        };
        this.onUpdateCandidat(candidatSelected, fiche);

        break;
      case 'Transferer':
        break;
      case 'update':
        this.currentView$.next('form');
        break;
      case 'display':
        this.currentView$.next('display');

        break;
      default:
        this.currentView$.next(event);
        break;
    }
  }

  /**
   * Selection d'un candidat
   * @param candidat - Candidat sélectionné
   * @param fiche - Fiche de poste concernée
   * @returns
   */
  onSelect(candidat: any, fiche: any): void {
    if (!candidat || !fiche) return;
    const operationId: string = fiche.mission.operation.id;
    const ficheId: string = fiche.id;
    const candidatId: string = candidat.id;

    this.operationCandidatureStore.dispatch(
      fromOperationCandidatureAction.loadOperationCandidature({
        operationId,
        ficheId,
        candidatId,
      })
    );
  }

  /**
   * Changement d'état de l'affichage
   * @param {string} event - Etat de l'affichage - Formulaire ou Affichage simple
   */
  onSetCandidatItemView(event: string): void {
    this.currentView$.next(event);
  }

  /**
   * Création du nouveau candidat
   * @param data - Informations du nouveau candidat
   * @param fiche - Fiche de poste concernée
   */
  onAddCandidats(data: any, fiche: any): void {
    // const candidats = data.candidat;
    // const fiche = data.fiche;
    // const operation = fiche.mission.operation;
    // this.operationCandidatureStore.dispatch(
    //   fromOperationCandidatureAction.addOperationCandidature({
    //     operationId: operationId,
    //     ficheId: fiche.id,
    //     candidats: candidats,
    //   })
    // );
  }

  /**
   * Suppression d'un candidat
   * @param {any} candidat - Information du candidat concerné
   * @param {any} fiche - Fiche de poste concernée
   * @returns
   */
  onDeleteCandidat(candidat: any, fiche: any): void {
    if (!candidat || !fiche) return;

    const operation = fiche.mission.operation;
    this.operationCandidatureStore.dispatch(
      fromOperationCandidatureAction.deleteOperationCandidature({
        operationId: operation.id,
        ficheId: fiche.id,
        candidatId: candidat.id,
      })
    );
  }

  /**
   * Modification du candidat
   * @param {any} candidat - Candidat concerné
   * @param {any} fiche -Fiche de poste concerné
   * @returns
   */
  onUpdateCandidat(candidat: Update<any>, fiche: any): void {
    if (!candidat.id || !fiche.id) return;
    const operation = fiche.mission.operation;

    this.operationCandidatureStore.dispatch(
      fromOperationCandidatureAction.updateOperationCandidature({
        operationId: operation.id,
        ficheId: fiche.id,
        candidat: candidat,
      })
    );
    this.currentView$.next('display');
  }

  /**
   * Suppression de tous les candidats
   * @param {any} data - Informations liées à la fiche de poste actuelle
   * @param {string} operationId - Identifiant de l'operation concernée
   */
  onDeleteCandidats(data: { fiche: any }, operationId: string): void {
    const fiche = data.fiche;
    const nextFiche = {
      ...fiche,
      candidats: [],
    };
    const operation = fiche.mission.operation;
    this.operationCandidatureStore.dispatch(
      fromOperationCandidatureAction.deleteOperationCandidatures({
        operationId: operation.id,
        ficheId: fiche.id,
        ids: [],
      })
    );
  }
}
