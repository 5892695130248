import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { EMPTY, lastValueFrom, Observable, of } from 'rxjs';
import { UserState } from '../../components/user/store/user.reducer';
import * as fromUserAction from '../../components/user/store/user.actions';
import * as fromUserSelector from '../../components/user/store/user.selectors';

@Injectable({
  providedIn: 'any',
})
export class UsersResolverService {
  constructor(private userStore: Store<UserState>, private router: Router) {}

  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<any[]> | Promise<any[] | any> | any[] | any {
    let id = route.parent?.params['id'];
    let find = lastValueFrom(this.loadUsers());

    return find
      .then(() => {
        return this.getUsers();
      })
      .catch(() => {
        this.router.navigate(['/organisation', id]);
        return EMPTY;
      });
  }

  getUsers(): Observable<any[] | any> {
    return this.userStore.select(fromUserSelector.team);
  }

  loadUsers(): Observable<any> {
    return of(this.userStore.dispatch(fromUserAction.loadUsers()));
  }
}
