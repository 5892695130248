import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of, Subject } from 'rxjs';
import { OperationId } from 'src/app/components/operation/store/operation.model';
import { OperationState } from 'src/app/components/operation/store/operation.reducer';
import * as fromOperationAction from 'src/app/components/operation/store/operation.actions';

@Component({
  selector: 'app-operation-note-list',
  templateUrl: './operation-note-list.component.html',
  styleUrls: ['./operation-note-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationNoteListComponent
  implements OnInit, OnChanges, OnDestroy
{
  subscribe = new Subject();
  loading$: Observable<boolean> = of(false);

  constructor(
    private route: ActivatedRoute,
    private operationStore: Store<OperationState>
  ) {
    const data: Data = this.route.snapshot.data;
    this.operationStore.dispatch(
      fromOperationAction.setActviteRoute({ title: data?.title })
    );
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  loadAll(): void {}

  getAll(): void {}

  loadOne(): void {}

  getOne(): void {}

  onNew(): void {}

  onAdd(): void {}

  onDelete(): void {}

  onDeleteAll(event: string, operation: OperationId): void {
    if (!event || !operation) return;

    switch (event) {
      case 'deleteAll':
        break;

      default:
        break;
    }
  }
}
