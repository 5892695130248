import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-operations',
  templateUrl: './table-operations.component.html',
  styleUrls: ['./table-operations.component.scss'],
})
export class TableOperationsComponent implements OnInit {
  @Input() scroll: any = { x: '1100px', y: '100%' };

  constructor() {}

  ngOnInit(): void {}
}
