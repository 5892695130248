import { PipeModule } from './../pipe/pipe.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ZorroModule } from './../css/zorro.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../css/material.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ZorroModule,
    MaterialModule,
    PipeModule,
  ],
  exports: [
    ZorroModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    PipeModule,
  ],
})
export class SharedModule {}
