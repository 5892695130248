import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, Subject, takeUntil } from 'rxjs';
import { OperationNoteId } from 'src/app/components/operation-note/store/operation-note.model';
import { OperationId } from 'src/app/components/operation/store/operation.model';
import { OperationNoteParticipantId } from '../../store/operation-note-participant.model';
import { OperationNoteParticipantState } from '../../store/operation-note-participant.reducer';
import * as fromOperationNoteParticipantAction from '../../store/operation-note-participant.actions';
import * as fromOperationNoteParticipantSelector from '../../store/operation-note-participant.selectors';

@Component({
  selector: 'app-operation-note-participant-table',
  templateUrl: './operation-note-participant-table.component.html',
  styleUrls: ['./operation-note-participant-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationNoteParticipantTableComponent
  implements OnInit, OnDestroy
{
  subscribe = new Subject();
  @Input() note$: Observable<OperationNoteId | any> = of(null);

  participants$: Observable<OperationNoteParticipantId[] | any> = of([
    {
      id: 0,
      quality: '',
      module: {
        id: 0,
        title: 'Gros oeuvre',
      },
      entite: {
        denomination: 'LENGGO',
      },
      contacts: [
        {
          id: 0,
          displayName: 'Jean DUPONT',
          fonction: 'Conducteur de travaux',
          coordonnees: {
            email: 'jdupont@gmail.com',
            phoneNumber: '+330099887766',
            mobile: '0688989887',
          },
        },
        {
          id: 1,
          displayName: 'Jeanne DUPONT',
          fonction: 'Conductrice de travaux',
          coordonnees: {
            email: 'jnedupont@gmail.com',
            phoneNumber: '+330099887766',
            mobile: '0688989887',
          },
        },
      ],
    },
    {
      id: 1,
      quality: '',
      module: {
        id: 0,
        title: 'Menuiserie',
      },
      entite: {
        denomination: 'LEFTEAM',
      },
      contacts: [
        {
          id: 0,
          displayName: 'Eric DUPONT',
          fonction: 'Conducteur de travaux',
          coordonnees: {
            email: 'jdupont@gmail.com',
            phoneNumber: '+330099887766',
            mobile: '0688989887',
          },
        },
      ],
    },
    {
      id: 2,
      quality: '',
      module: {
        id: 0,
        title: 'Sol souple',
      },
      entite: {
        denomination: 'Sol France',
      },
      contacts: [
        {
          id: 0,
          displayName: 'Eric DUPONT',
          fonction: 'Conducteur de travaux',
          coordonnees: {
            email: 'jdupont@gmail.com',
            phoneNumber: '+330099887766',
            mobile: '0688989887',
          },
        },
      ],
    },
  ]);
  @Input() scroll: any = { x: '1100px', y: '100%' };
  @Input() operation: OperationId | any = null;

  constructor(
    private operationNoteParticipantStore: Store<OperationNoteParticipantState>
  ) {}

  ngOnInit(): void {
    this.getParticipants();
    this.loadParticipants();
  }
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getParticipants(): void {
    // this.participants$ = this.operationNoteParticipantStore.select(
    //   fromOperationNoteParticipantSelector.participants
    // );
  }
  loadParticipants(): void {
    this.note$
      .pipe(takeUntil(this.subscribe))
      .subscribe((note: OperationNoteId) => {
        if (!note) return;

        this.operationNoteParticipantStore.dispatch(
          fromOperationNoteParticipantAction.loadOperationNoteParticipants({
            note,
          })
        );
      });
  }

  onAction(
    event: string,
    note: OperationNoteId,
    item: OperationNoteParticipantId
  ): void {
    if (!event || !item) return;

    switch (event) {
      case 'update':
        this.onUpdate(note, item);
        break;
      case 'delete':
        this.onDelete(note, item.id);
        break;
      default:
        break;
    }
  }

  onUpdate(note: OperationNoteId, participant: any): void {
    if (!note || !participant) return;
  }

  onDelete(note: OperationNoteId, id: string): void {
    if (!note || !id) return;
    this.operationNoteParticipantStore.dispatch(
      fromOperationNoteParticipantAction.deleteOperationNoteParticipant({
        note,
        id,
      })
    );
  }
}
