import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'age',
})
export class AgePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return `${value}`;
    } else {
      return `${value} ans`;
    }
  }
}
