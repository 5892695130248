import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, of, Subject, takeUntil } from 'rxjs';
import { ContratState } from '../../store/contrat.reducer';
import * as fromContratAction from '../../store/contrat.actions';
import * as fromContratSelector from '../../store/contrat.selectors';
import { v4 as uuidV4 } from 'uuid';

@Component({
  selector: 'app-contrat-form-detail',
  templateUrl: './contrat-form-detail.component.html',
  styleUrls: ['./contrat-form-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContratFormDetailComponent
  implements OnInit, OnChanges, OnDestroy
{
  subscribe = new Subject();
  today: Date = new Date(Date.now());
  dateFormat: string = 'dd/MM/yyyy';

  loading$: Observable<boolean> = of(false);
  timeDefaultOpenValue: Date = this.today;
  typesContrat$: Observable<any[]> = of([]);
  natureContrat$: Observable<any> = of([]);
  experienceContrat$: Observable<any[]> = of([]);
  niveauSalaire$: Observable<any[]> = of([]);
  permis$: Observable<any[]> = of([]);
  levelNumerique$: Observable<any[]> = of([]);
  complementSalaire$: Observable<any[]> = of([]);
  typesHoraires$: Observable<any[]> = of([]);
  mobilityGeographic$: Observable<any[]> = of([]);
  contrat_days$: Observable<string[]> = of([]);
  evolutions_types$: Observable<any[]> = of([]);
  unit_types$: Observable<any[]> = of([
    'Eur/H',
    'Eur/Jt',
    'Eur/Sm',
    'Eur/Mo',
    'Eur/An',
  ]);
  complementSalaireDividerTitle: string = 'Compléments salaire';

  isDeplacementModel: boolean = false;
  noComplementsSalaireDefinition: string =
    'Aucun complément de salaire renseigné';

  contratForm: FormGroup = this.fb.group({});

  typeControl: FormControl<{ code: string; libelle: string }> =
    new FormControl<any>(null, Validators.required);
  natureControl: FormControl<{ code: string; libelle: string }> =
    new FormControl<any>(
      { code: 'I1', libelle: "Insertion par l'activ.éco." },
      Validators.required
    );
  experienceControl: FormControl<{
    codeTypeCaracteristique: string;
    libelleCaracteristique: string;
  }> = new FormControl<any>(
    {
      codeTypeCaracteristique: 'NIVEXP',
      libelleCaracteristique: 'Non renseigné',
    },
    Validators.required
  );

  permisControl: FormControl<any> = new FormControl<
    { code: string; libelle: string }[]
  >([]);
  bureautique_level = new FormControl<string[]>([]);
  isDeplacement = new FormControl<boolean>(false);
  observationContrat = new FormControl<string>('');
  motif = new FormControl<string>('');
  justificatif = new FormControl<string>('');

  zone_deplacement = new FormControl<{
    codeTypeTerritoire: string;
    libelleTypeTerritoire: string;
  }>({ codeTypeTerritoire: '', libelleTypeTerritoire: '' });
  isSupplementaires = new FormControl<boolean>(false);
  isAstreinte = new FormControl<boolean>(false);
  isTeamWork = new FormControl<boolean>(true);
  isReferentPresence = new FormControl<boolean>(true);
  isSuviRenforce = new FormControl<boolean>(true);

  @Input() typeInput: any;
  @Input() natureInput: any;
  @Input() experienceInput: any;
  @Input() salaireInput: any;
  @Input() permisInput: any;
  @Input() bureautiqueInput: any;
  @Input() work_priceInput: any;
  @Input() salaireComplementInput: any;
  @Input() isDeplacementInput: any;
  @Input() zone_deplacementInput: any;

  @Output() onCurrentChanges = new EventEmitter<any>();

  constructor(
    private fb: FormBuilder,
    private contratStore: Store<ContratState>
  ) {}

  ngOnInit(): void {
    this.get_referentiels();
    this.formCreate();
    this.onChange();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.onUpdateForm(
      changes.typeInput.currentValue,
      changes.natureInput.currentValue,
      changes.experienceInput.currentValue,
      changes.salaireInput.currentValue,
      changes.permisInput.currentValue,
      changes.bureautiqueInput.currentValue,
      changes.work_priceInput.currentValue,
      changes.salaireComplementInput.currentValue,
      changes.isDeplacementInput.currentValue,
      changes.zone_deplacementInput.currentValue
    );
  }
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }
  formCreate(): void {
    this.contratForm = this.fb.group({
      type: this.typeControl,
      nature: this.natureControl,
      experience: this.experienceControl,
      permis: this.permisControl,
      bureautique: this.bureautique_level,
      isDeplacement: this.isDeplacement,
      zone_deplacement: this.zone_deplacement,
      isAstreinte: this.isAstreinte,
      isReferentPresence: this.isReferentPresence,
      isSuviRenforce: this.isSuviRenforce,
      observationContrat: this.observationContrat,
      motif: this.motif,
      justificatif: this.justificatif,
    });
  }

  get salaireComplementsArray(): any {
    return this.contratForm.get('salaireComplements') as FormArray;
  }

  formCompare(item1: any, item2: any): any {
    return item1 === item2;
  }

  formCompareNature(item1: any, item2: any): any {
    return item1 && item2 ? item1?.libelle === item2?.libelle : item1 && item2;
  }
  formCompareExperience(item1: any, item2: any): any {
    return item1 && item2
      ? item1?.libelleCaracteristique === item2?.libelleCaracteristique
      : item1 && item2;
  }

  onIsDeplacementChange(event: boolean): void {
    //this.contratForm.patchValue({ isDeplacement: event });
  }

  get_loading(): void {
    this.loading$ = this.contratStore.select(fromContratSelector.loading);
  }

  get_referentiels(): void {
    this.complementSalaire$ = this.contratStore.select(
      fromContratSelector.complement
    );

    this.levelNumerique$ = this.contratStore.select(
      fromContratSelector.numerique
    );

    this.niveauSalaire$ = this.contratStore.select(
      fromContratSelector.salaires
    );

    this.experienceContrat$ = this.contratStore.select(
      fromContratSelector.experiences
    );

    this.natureContrat$ = this.contratStore.select(fromContratSelector.natures);
    this.typesContrat$ = this.contratStore.select(fromContratSelector.types);
    this.permis$ = this.contratStore.select(fromContratSelector.permis);
    this.typesHoraires$ = this.contratStore.select(
      fromContratSelector.horaires
    );

    this.mobilityGeographic$ = this.contratStore.select(
      fromContratSelector.mobilities
    );
  }

  load_referentiels(): void {
    this.load_naturescontrat();
    this.load_typescontrat();
    this.load_experiencescontrat();
    // this.load_salairescontrat();
    this.load_permis();
    this.load_level_numerique();
    this.load_geo_deplacement();
    // this.load_complement_salaire();
  }
  load_typescontrat(): void {
    this.contratStore.dispatch(fromContratAction.loadTypesContrats());
  }
  load_naturescontrat(): void {
    this.contratStore.dispatch(fromContratAction.loadNaturesContrats());
  }
  load_experiencescontrat(): void {
    this.contratStore.dispatch(fromContratAction.loadNiveauExperiences());
  }
  load_salairescontrat(): void {
    this.contratStore.dispatch(fromContratAction.loadNiveauSalaire());
  }
  load_permis(): void {
    this.contratStore.dispatch(fromContratAction.loadPermis());
  }
  load_level_numerique(): void {
    this.contratStore.dispatch(fromContratAction.loadLevelNumerique());
  }
  load_geo_deplacement(): void {
    this.contratStore.dispatch(fromContratAction.loadMobilityGeographic());
  }
  load_complement_salaire(): void {
    this.contratStore.dispatch(fromContratAction.loadComplementSalaire());
  }

  onUpdateForm(
    type: any,
    nature: any,
    experience: any,
    salaire: any,
    permis: any,
    bureautique: any,
    work_price: any,
    salaireComplement: any,
    isDeplacement: any,
    zone_deplacement: any
  ): void {
    this.contratForm.patchValue({
      type,
      nature,
      experience,
      salaire,
      permis,
      bureautique,
      work_price,
      salaireComplement,
      isDeplacement,
      zone_deplacement,
    });
  }

  onChange(): void {
    this.contratForm.valueChanges
      .pipe(takeUntil(this.subscribe))
      .subscribe((formValue) => {
        this.onCurrentChanges.emit(formValue);
      });
  }
}
