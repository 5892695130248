import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ParticipantSuivi } from './participant-suivi.model';
import * as ParticipantSuiviActions from './participant-suivi.actions';

export const participantSuivisFeatureKey = 'participantSuivis';

export interface ParticipantSuiviState extends EntityState<ParticipantSuivi> {
  loading: boolean;
  suivi: any;
  error: any;
}

export const adapter: EntityAdapter<ParticipantSuivi> =
  createEntityAdapter<ParticipantSuivi>();

export const initialState: ParticipantSuiviState = adapter.getInitialState({
  loading: false,
  suivi: undefined,
  error: undefined,
});

export const reducer = createReducer(
  initialState,

  //LOAD ALL
  on(ParticipantSuiviActions.loadParticipantSuivis, (state, action) => {
    return {
      ...state,
      loading: true,
      error: undefined,
    };
  }),

  on(ParticipantSuiviActions.loadParticipantSuivisSuccess, (state, action) =>
    adapter.setAll(action.suivis, state)
  ),
  on(ParticipantSuiviActions.loadParticipantSuivisSuccess, (state, action) => {
    return {
      ...state,
      error: undefined,
      loading: false,
    };
  }),

  on(ParticipantSuiviActions.loadParticipantSuivisFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
      loading: false,
    };
  }),

  //LOAD ONE
  on(ParticipantSuiviActions.loadParticipantSuiviSuccess, (state, action) => {
    return {
      ...state,
      error: undefined,
      loading: true,
    };
  }),
  on(ParticipantSuiviActions.loadParticipantSuiviSuccess, (state, action) => {
    return {
      ...state,
      error: undefined,
      loading: false,
      suivi: action.suivi,
    };
  }),
  on(ParticipantSuiviActions.loadParticipantSuivisFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
      suivi: undefined,
      loading: false,
    };
  }),

  //CREATE ONE
  on(ParticipantSuiviActions.addParticipantSuiviSuccess, (state, action) =>
    adapter.addOne(action.suivi, state)
  ),
  on(ParticipantSuiviActions.addParticipantSuiviFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
      suivi: undefined,
      loading: false,
    };
  }),

  //UPDATE ONE
  on(ParticipantSuiviActions.updateParticipantSuivi, (state, action) =>
    adapter.updateOne(action.suivi, state)
  ),
  on(ParticipantSuiviActions.updateParticipantSuiviFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
      suivi: undefined,
      loading: false,
    };
  }),

  //DELETE ONE
  on(ParticipantSuiviActions.deleteParticipantSuivi, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(ParticipantSuiviActions.deleteParticipantSuiviFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
      loading: false,
    };
  }),

  //DELETE ALL
  on(ParticipantSuiviActions.deleteParticipantSuivis, (state, action) =>
    adapter.removeAll(state)
  ),
  on(
    ParticipantSuiviActions.deleteParticipantSuivisFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
  ),

  //CLEAR

  on(ParticipantSuiviActions.clearParticipantSuivis, (state) =>
    adapter.removeAll(state)
  )
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
