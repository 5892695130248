import {
  UtilitiesIds,
  UtilitiesModuleVariables,
} from './../../../../contents/components/utilities/store/utilities.model';
import { takeUntil } from 'rxjs/operators';
import { OperationId } from './../../../operation/store/operation.model';
import {
  FormControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  Input,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { ModuleState } from '../../store/module.reducer';
import * as fromModuleAction from '../../store/module.actions';
import * as fromModuleSelector from '../../store/module.selectors';
import { Observable, of, Subject, BehaviorSubject } from 'rxjs';
import { Module, ModuleId } from '../../store/module.model';
import { Update } from '@ngrx/entity';
import { Timestamp } from '@firebase/firestore';
import {
  add,
  differenceInCalendarDays,
  differenceInMonths,
  fromUnixTime,
  isDate,
} from 'date-fns';
import { OperationState } from 'src/app/components/operation/store/operation.reducer';
import * as fromOperationSelector from 'src/app/components/operation/store/operation.selectors';
import { capitalize } from 'lodash';

@Component({
  selector: 'app-module-form',
  templateUrl: './module-form.component.html',
  styleUrls: ['./module-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModuleFormComponent implements OnInit, OnChanges, OnDestroy {
  operation$: Observable<OperationId | any> = of(null);
  subscribe = new Subject();
  isAdd: boolean = true;
  isUpdate: boolean = false;
  titleModule: string = '';
  currentStep: number = 0;
  updateAdresse = new BehaviorSubject<any>(null);
  updateAdresseComplement = new BehaviorSubject<any>('');
  types$: Observable<string[]> = of([]);
  module$: Observable<ModuleId | any> = of(null);

  @Output() cancel = new EventEmitter<boolean>(false);
  @Output() add = new EventEmitter<any>(false);
  @Output() update = new EventEmitter<any>(false);

  moduleForm: UntypedFormGroup = this.fb.group({});
  type: UntypedFormControl = new UntypedFormControl();
  id: UntypedFormControl = new UntypedFormControl();
  auths: UntypedFormControl = new UntypedFormControl();
  access: UntypedFormControl = new UntypedFormControl();
  auteur: UntypedFormControl = new UntypedFormControl();
  userUpdate: UntypedFormControl = new UntypedFormControl();
  operationId: UntypedFormControl = new UntypedFormControl();
  operationDenomination: UntypedFormControl = new UntypedFormControl();
  departements: UntypedFormControl = new UntypedFormControl();
  organisationId: UntypedFormControl = new UntypedFormControl();
  organisationNomRaisonSociale: UntypedFormControl = new UntypedFormControl();
  organisationSiret: UntypedFormControl = new UntypedFormControl();
  organisationSiren: UntypedFormControl = new UntypedFormControl();
  organisationLogo: UntypedFormControl = new UntypedFormControl();
  entites: UntypedFormControl = new UntypedFormControl();
  entitesIds: UntypedFormControl = new UntypedFormControl();
  entitesMapIds: UntypedFormControl = new UntypedFormControl();

  dependencies: UntypedFormArray = new UntypedFormArray([]);
  title: UntypedFormControl = new UntypedFormControl();
  numero: UntypedFormControl = new UntypedFormControl();
  adresse: UntypedFormControl = new UntypedFormControl();
  qpv: UntypedFormControl = new UntypedFormControl();
  complement: UntypedFormControl = new UntypedFormControl();
  adresseOperation: UntypedFormControl = new UntypedFormControl();
  qpvOperation: UntypedFormControl = new UntypedFormControl();
  complementOperation: UntypedFormControl = new UntypedFormControl();
  objectifsOperation: UntypedFormControl = new UntypedFormControl();
  interventions: UntypedFormControl = new UntypedFormControl();
  contacts: UntypedFormControl = new UntypedFormControl();
  dateStart: UntypedFormControl = new UntypedFormControl();
  dateUpdate: UntypedFormControl = new UntypedFormControl();
  dateEnd: UntypedFormControl = new UntypedFormControl();
  dateSelected: UntypedFormControl = new UntypedFormControl();
  duree: UntypedFormControl = new UntypedFormControl();
  dateStartOperation: UntypedFormControl = new UntypedFormControl();
  dateUpdateOperation: UntypedFormControl = new UntypedFormControl();
  dateEndOperation: UntypedFormControl = new UntypedFormControl();
  dureeOperation: UntypedFormControl = new UntypedFormControl();
  objectifs: UntypedFormControl = new UntypedFormControl();
  remarque: UntypedFormControl = new UntypedFormControl();
  definition: UntypedFormControl = new UntypedFormControl();
  deroule: UntypedFormControl = new UntypedFormControl();
  accroche: UntypedFormControl = new UntypedFormControl();
  searchKey: UntypedFormControl = new UntypedFormControl();
  nombreParticipant: UntypedFormControl = new UntypedFormControl();
  bureau: UntypedFormControl = new UntypedFormControl();
  participants: UntypedFormControl = new UntypedFormControl();
  formulaire: UntypedFormControl = new UntypedFormControl();
  documents: UntypedFormControl = new UntypedFormControl();
  budget: UntypedFormControl = new UntypedFormControl();
  metadata: UntypedFormControl = new UntypedFormControl();
  referents: UntypedFormControl = new UntypedFormControl();
  referentsId: UntypedFormControl = new UntypedFormControl();
  effectifMin: UntypedFormControl = new UntypedFormControl();
  effectifMax: UntypedFormControl = new UntypedFormControl();
  depenses: UntypedFormControl = new UntypedFormControl();
  statut: UntypedFormControl = new UntypedFormControl();
  stats: UntypedFormControl = new UntypedFormControl();
  missions: UntypedFormControl = new UntypedFormControl([]);
  isFinish: UntypedFormControl = new UntypedFormControl(false);
  documentsTotal: UntypedFormControl = new UntypedFormControl(0);
  suivis: UntypedFormControl = new UntypedFormControl([]);
  suivisTotal: UntypedFormControl = new UntypedFormControl(0);
  progression: UntypedFormControl = new UntypedFormControl(0);
  collaboration: UntypedFormControl = new UntypedFormControl('Bonne');
  objectifsType: UntypedFormControl = new UntypedFormControl('heures');
  objectifHours: FormControl<number> = new FormControl(0, {
    nonNullable: true,
  });
  objectifHoursTheoric: FormControl<number> = new FormControl(0, {
    nonNullable: true,
  });
  objectifPersons: FormControl<number> = new FormControl(0, {
    nonNullable: true,
  });
  objectifPersonsTheoric: FormControl<number> = new FormControl(0, {
    nonNullable: true,
  });
  progressionHours: FormControl<number> = new FormControl(0, {
    nonNullable: true,
  });
  progressionPersons: FormControl<number> = new FormControl(0, {
    nonNullable: true,
  });

  constructor(
    private moduleStore: Store<ModuleState>,
    private operationStore: Store<OperationState>,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.form();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getOperaiton(): void {
    this.operation$ = this.operationStore.select(
      fromOperationSelector.operation
    );
  }

  getModule(): void {
    this.module$ = this.moduleStore.select(fromModuleSelector.module);
  }

  form(): void {
    this.formInit();
    this.formCreate();
    this.formSet();
    this.onDateStart();
    this.onDateEnd();
    this.getModuleTypes();
  }

  formInit(): void {
    this.type = new UntypedFormControl('', Validators.required);
    this.auths = new UntypedFormControl([]);
    this.auteur = new UntypedFormControl({});
    this.access = new UntypedFormControl({});
    this.userUpdate = new UntypedFormControl({});
    this.objectifHours = new FormControl(0, { nonNullable: true });
    this.objectifHoursTheoric = new FormControl(0, { nonNullable: true });
    this.objectifPersons = new FormControl(0, { nonNullable: true });
    this.objectifPersonsTheoric = new FormControl(0, { nonNullable: true });
    this.progressionHours = new FormControl(0, { nonNullable: true });
    this.progressionPersons = new FormControl(0, { nonNullable: true });

    this.departements = new UntypedFormControl();
    this.organisationId = new UntypedFormControl('');
    this.organisationNomRaisonSociale = new UntypedFormControl('');
    this.organisationSiret = new UntypedFormControl();
    this.organisationSiren = new UntypedFormControl();
    this.organisationLogo = new UntypedFormControl();
    this.metadata = new UntypedFormControl();
    this.referents = new UntypedFormControl([]);
    this.referentsId = new UntypedFormControl([]);
    this.dependencies = new UntypedFormArray([]);
    this.effectifMin = new UntypedFormControl();
    this.effectifMax = new UntypedFormControl();
    this.collaboration = new UntypedFormControl('Bonne', Validators.required);
    this.entites = new UntypedFormControl([]);
    this.contacts = new UntypedFormControl([]);
    this.participants = new UntypedFormControl([]);
    this.formulaire = new UntypedFormControl('');
    this.documents = new UntypedFormControl([]);
    this.budget = new UntypedFormControl();
    this.searchKey = new UntypedFormControl();

    this.title = new UntypedFormControl('', Validators.required);
    this.operationId = new UntypedFormControl('');
    this.operationDenomination = new UntypedFormControl('');
    this.adresse = new UntypedFormControl();
    this.qpv = new UntypedFormControl();
    this.complement = new UntypedFormControl();
    this.adresseOperation = new UntypedFormControl();
    this.qpvOperation = new UntypedFormControl();
    this.complementOperation = new UntypedFormControl();
    this.objectifsOperation = new UntypedFormControl();
    this.objectifsType = new UntypedFormControl('');
    this.interventions = new UntypedFormControl([]);
    this.dateStart = new UntypedFormControl(
      new Date(Date.now()),
      Validators.required
    );
    this.dateUpdate = new UntypedFormControl(
      Timestamp.fromDate(new Date(Date.now())),
      Validators.required
    );
    this.dateEnd = new UntypedFormControl('', Validators.required);
    this.dateSelected = new UntypedFormControl('');
    this.duree = new UntypedFormControl('', Validators.required);

    this.dateStartOperation = new UntypedFormControl('');
    this.dateUpdateOperation = new UntypedFormControl('');
    this.dateEndOperation = new UntypedFormControl('');
    this.dureeOperation = new UntypedFormControl('');
    this.statut = new UntypedFormControl('En attente');
    this.objectifs = new UntypedFormControl([]);
    this.remarque = new UntypedFormControl('');
    this.accroche = new UntypedFormControl('');
    this.definition = new UntypedFormControl('');
    this.deroule = new UntypedFormControl('');
    this.bureau = new UntypedFormControl({});
    this.stats = new UntypedFormControl({});
    this.isFinish = new UntypedFormControl(false);
    this.missions = new UntypedFormControl([]);
    this.documentsTotal = new UntypedFormControl(0);
    this.suivis = new UntypedFormControl([]);
    this.suivisTotal = new UntypedFormControl(0);
    this.progression = new UntypedFormControl(0);
  }

  formCreate(): void {
    this.moduleForm = this.fb.group({
      referents: this.referents,
      referentsId: this.referentsId,
      effectifMin: this.effectifMin,
      effectifMax: this.effectifMax,

      objectifHours: this.objectifHours,
      objectifHoursTheoric: this.objectifHoursTheoric,
      objectifPersons: this.objectifPersons,
      objectifPersonsTheoric: this.objectifPersonsTheoric,
      progressionHours: this.progressionHours,
      progressionPersons: this.progressionPersons,

      statut: this.statut,
      progression: this.progression,
      type: this.type,
      entites: this.entites,
      entitesIds: this.entitesIds,
      entitesMapIds: this.entitesMapIds,
      dependencies: this.dependencies,
      formulaire: this.formulaire,
      isFinish: this.isFinish,
      operation: new UntypedFormGroup({
        id: this.operationId,
      }),
      title: this.title,
      auteur: this.auteur,
      collaboration: this.collaboration,
      calendrier: new UntypedFormGroup({
        dateStart: this.dateStart,
        dateUpdate: this.dateUpdate,
        dateEnd: this.dateEnd,
        duree: this.duree,
      }),
      adresse: new UntypedFormGroup({
        adresse: this.adresse,
        complement: this.complement,
        qpv: this.qpv,
      }),

      objectifs: this.objectifs,
      objectifsType: this.objectifsType,
      remarque: this.remarque,
      description: new UntypedFormGroup({
        definition: this.definition,
        deroule: this.deroule,
        accroche: this.accroche,
      }),
    });
  }

  formSet(): void {
    this.module$
      .pipe(takeUntil(this.subscribe))
      .subscribe((module: ModuleId) => {
        if (!module) {
          this.newModule();
          return;
        } else {
          this.upsetModule(module);
        }
      });
  }

  getModuleTypes(): void {
    this.types$ = this.moduleStore.select(fromModuleSelector.types);
  }

  newModule(): void {
    this.isAdd = true;
    this.isUpdate = false;
    this.titleModule = 'Nouveau module';
    this.form();
  }

  upsetModule(module: ModuleId): void {
    this.titleModule = `Modifier le module : ${module.title}`;
    const { calendrier } = module;
    const { dateEnd, dateStart, dateUpdate, duree } = calendrier;
    const start = fromUnixTime(dateStart['seconds']);
    const end = fromUnixTime(dateEnd['seconds']);
    const update = fromUnixTime(dateUpdate['seconds']);

    this.moduleForm.patchValue({
      ...module,
      calendrier: {
        dateStart: start,
        dateUpdate: update,
        dateEnd: end,
        duree: duree,
      },
    });

    this.updateAdresse.next(module?.adresse?.adresse);
    this.updateAdresseComplement.next(module?.adresse?.complement);
  }

  //DISABLED OPERATIONS OUTSIDE DATES
  compareType(o1: any, o2: any) {
    return o1 === o2 ? o1.type === o2.type : o1 === o2;
  }

  disabledDateStart = (current: Date): boolean => {
    let isDisabled: boolean = false;
    this.operation$.pipe(takeUntil(this.subscribe)).subscribe((operation) => {
      const startDate = fromUnixTime(
        operation.description.calendrier.dateStart
      );
      isDisabled = differenceInCalendarDays(current, startDate) > 0;
    });

    return isDisabled;
  };

  disabledDateEnd = (current: Date): boolean => {
    let isDisabled: boolean = false;
    this.operation$.pipe(takeUntil(this.subscribe)).subscribe((operation) => {
      const endDate = fromUnixTime(operation.description.calendrier.dateEnd);
      isDisabled = differenceInCalendarDays(current, endDate) > 0;
    });

    return isDisabled;
  };

  compareAdresse(o1: any, o2: any) {
    return o1 === o2 ? o1.properties.label === o2.properties.label : o1 === o2;
  }

  //ENTITE
  onSelectEntite(item: any): void {
    if (!item) return;
    const id = item?.id;
    this.moduleForm.patchValue({
      entites: {
        id: id,
        etablissement: item?.entite?.etablissement,
        role: item?.intervention?.role,
      },
      entitesIds: [id],
      entitesMapIds: { id },
    });
  }

  //DATES
  getModuleStart(event: any): void {
    if (event) {
      this.moduleForm.patchValue({
        calendrier: {
          dateStart: event,
          dateUpdate: event,
        },
      });
    }
  }

  getModuleEnd(event: any): void {
    if (event) {
      this.moduleForm.patchValue({
        calendrier: {
          dateEnd: event,
        },
      });
    }
  }

  getModuleDuration(event: any): void {
    if (event) {
      this.moduleForm.patchValue({
        calendrier: {
          duree: event,
        },
      });
    }
  }

  onDateStart(): void {
    this.moduleForm
      .get('calendrier')
      ?.get('dateStart')
      ?.valueChanges.subscribe((date) => {
        if (date && isDate(date)) {
          const dateEnd = this.moduleForm
            ?.get('calendrier')
            ?.get('dateEnd')?.value;
          this.moduleForm.patchValue({
            calendrier: {
              dateUpdate: Timestamp.fromDate(date),
              duree: dateEnd ? differenceInMonths(dateEnd, date) : null,
            },
          });
        }
      });
  }

  onDateEnd(): void {
    this.moduleForm
      ?.get('calendrier')
      ?.get('dateEnd')
      ?.valueChanges.subscribe((date) => {
        if (date && isDate(date)) {
          const start = this.moduleForm
            ?.get('calendrier')
            ?.get('dateStart')?.value;
          const duree = this.moduleForm?.get('calendrier')?.get('duree')?.value;
          const diffrence = differenceInMonths(date, start);

          if ((start && !duree) || duree === 0) {
            this.moduleForm.patchValue({
              calendrier: {
                duree: diffrence,
              },
            });
          } else if (duree && duree !== diffrence) {
            this.moduleForm.patchValue({
              calendrier: {
                duree: diffrence,
              },
            });
          }
        }
      });
  }

  onDuration(): void {
    this.moduleForm
      ?.get('calendrier')
      ?.get('duree')
      ?.valueChanges.subscribe((numb) => {
        if (numb && numb > 0) {
          const start = this.moduleForm
            ?.get('calendrier')
            ?.get('dateStart')?.value;
          if (isDate(start)) {
            const dateEnd = this.moduleForm
              ?.get('calendrier')
              ?.get('dateEnd')?.value;
            const end = add(start, { months: numb });
            this.moduleForm.patchValue({
              calendrier: {
                dateEnd: end,
              },
            });
          }
        }
      });
  }

  //ADRESSE
  getCommuneAdresse(event: any): void {
    if (event) {
      this.moduleForm.patchValue({
        adresse: {
          adresse: event,
        },
      });
    }
  }

  getCommuneAdresseComplement(event: any): void {
    if (event) {
      this.moduleForm.patchValue({
        adresse: {
          complement: event,
        },
      });
    }
  }

  //TYPE
  onSelectType(type: any): void {
    this.moduleForm.patchValue({
      type: type,
    });
  }

  //GET ARRAYS CONTROLS
  get interventionForm() {
    return this.moduleForm.get('interventions') as UntypedFormArray;
  }

  get entiteForm() {
    return this.moduleForm.get('entite') as UntypedFormArray;
  }

  get objectifsForm() {
    return this.moduleForm.get('objectifs') as UntypedFormArray;
  }

  get dependenceForm() {
    return this.moduleForm.get('dependences') as UntypedFormArray;
  }

  //STEPS
  onPrevStep(): void {
    this.currentStep = this.currentStep === 0 ? 0 : this.currentStep - 1;
  }

  onNextStep(): void {
    this.currentStep = this.currentStep === 2 ? 2 : this.currentStep + 1;
  }

  /* ADD */
  onAdd(operation: OperationId): void {
    if (this.moduleForm.invalid) {
      return;
    }

    const moduleForm: any = this.moduleForm.value;
    const calendrierModule = {
      dateStart: Timestamp.fromDate(moduleForm.calendrier.dateStart),
      dateUpdate: moduleForm.calendrier.dateUpdate,
      duree: differenceInMonths(
        moduleForm.calendrier.dateEnd,
        moduleForm.calendrier.dateStart
      ),
      dateEnd: Timestamp.fromDate(moduleForm.calendrier.dateEnd),
    };

    const {
      title,
      type,
      adresse,
      budget,
      calendrier,
      description,
      contacts,
      contactsIds,
      effectifMax,
      effectifMin,
      objectifHours,
      objectifHoursTheoric,
      objectifPersons,
      objectifPersonsTheoric,
      progressionHours,
      progressionPersons,
      entites,
      entitesIds,
      isFinish,
      referents,
      referentsId,
      dependencies,
      statut,
      remarque,
      formulaire,
      collaboration,
    } = moduleForm;

    const module = {
      operation: { id: operation.id },
      title: capitalize(title),
      type,
      adresse,
      budget,
      calendrier: calendrierModule,
      description,
      contacts,
      contactsIds,
      effectifMax,
      effectifMin,
      objectifHours,
      objectifHoursTheoric,
      objectifPersons,
      objectifPersonsTheoric,
      progressionHours,
      progressionPersons,
      entites,
      entitesIds,
      isFinish,
      referents,
      referentsId,
      dependencies,
      statut,
      remarque,
      formulaire,
      collaboration,
    };

    this.add.emit({ operation, module });

    this.moduleForm.reset();
    this.formInit();
    this.formCreate();
  }

  // UPDATE
  onUpdate(operation: OperationId, module: ModuleId): void {
    if (this.moduleForm.invalid) {
      return;
    }
    const moduleForm: Module = this.moduleForm.value;
    const moduleUpdate: ModuleId = {
      ...moduleForm,
      id: module.id,
      calendrier: {
        dateStart: Timestamp.fromDate(moduleForm.calendrier.dateStart),
        dateUpdate: moduleForm.calendrier.dateUpdate,
        duree: differenceInMonths(
          moduleForm.calendrier.dateEnd,
          moduleForm.calendrier.dateStart
        ),
        dateEnd: Timestamp.fromDate(moduleForm.calendrier.dateEnd),
      },
    };
    const updateData: Update<ModuleId> = {
      id: moduleUpdate.id,
      changes: moduleUpdate,
    };

    this.update.emit({ operation, updateData });
    this.moduleForm.reset();
    this.cancel.emit(false);
    this.onCancel();
  }

  onCancel(): void {
    this.cancel.emit(true);
  }
}
