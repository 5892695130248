import { OperationId } from './../components/operation/store/operation.model';
import { OperationTask } from './../components/operation-task/store/operation-task.model';
import { Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  doc,
  docSnapshots,
  collectionChanges,
  getDoc,
  addDoc,
  setDoc,
  deleteDoc,
  serverTimestamp,
  DocumentData,
  Timestamp,
  query,
  where,
  arrayUnion,
  arrayRemove,
  collectionSnapshots,
  CollectionReference,
  DocumentReference,
  getDocs,
  documentId,
} from '@angular/fire/firestore';
import { Observable, of, from, EMPTY } from 'rxjs';
import { switchMap, mergeMap, map, catchError } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Functions, httpsCallable } from '@angular/fire/functions';
import {
  Storage,
  ref,
  uploadBytesResumable,
  deleteObject,
  getDownloadURL,
  StorageReference,
  UploadTask,
  UploadMetadata,
} from '@angular/fire/storage';
import { UserState } from '../components/user/store/user.reducer';
import * as fromUserSelector from '../components/user/store/user.selectors';
import { v4 as uuidV4 } from 'uuid';
import {
  endOfMonth,
  endOfWeek,
  format,
  fromUnixTime,
  startOfMinute,
  startOfWeek,
} from 'date-fns';
import { ItemEvent } from '../features/suivi/components/item-event/itemEvent.model';
import { OperationTaskId } from '../components/operation-task/store/operation-task.model';
import { Apollo, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'any',
})
export class OperationTaskService {
  CREATE_OPERATION_TASK = gql`
    mutation CreateOperationTask($task: OperationTaskInput!) {
      createOperationTask(task: $task)
    }
  `;

  DELETE_OPERATION_TASK = gql`
    mutation DeleteOperationTask($operationId: ID!, $id: ID!) {
      deleteOperationTask(operationId: $operationId, id: $id)
    }
  `;
  DELETE_OPERATION_ALL_TAKS = gql`
    mutation DeleteOpeationTasks($id: ID!) {
      deleteOperationTasks(id: $id)
    }
  `;

  constructor(
    private db: Firestore,
    private userStore: Store<UserState>,
    private apollo: Apollo
  ) {}

  todoTypeMessageSucces(todo: OperationTaskId | any): string {
    if (todo.completed && !todo.tested && !todo.verifed) {
      return `Votre tâche a été modifiée avec succès`;
    }
    if (todo.completed && todo.tested && !todo.verifed) {
      return `Votre tâche a été testée avec succès`;
    }

    if (todo.completed && todo.tested && todo.verifed) {
      return `Votre tâche a été vérifiée et validée avec succès`;
    }

    return '';
  }

  getAll(operationId: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !operationId) {
            return of([]);
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/operations/${operationId}/tasks`
            );

            const changes = collectionSnapshots(dbCollection).pipe(
              map((changes) => {
                return changes.map((snap) => {
                  const data = snap.data();
                  const id = snap.id;
                  return { id, ...data };
                });
              })
            );

            return changes;
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  getAllUserTasks(operationId: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !operationId) {
            return of([]);
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/operations/${operationId}/tasks`
            );

            const queries = query(
              dbCollection,
              where(`ids`, 'array-contains', user.id)
            );

            const changes = collectionSnapshots(queries).pipe(
              map((changes) => {
                return changes.map((snap) => {
                  const data = snap.data();
                  const id = snap.id;
                  return { id, ...data };
                });
              })
            );

            return changes;
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  getAllTodayTasks(operationId: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !operationId) {
            return of([]);
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/operations/${operationId}/tasks`
            );

            const today = new Date(Date.now());
            const todayDate = Timestamp.fromDate(today);

            const queries = query(
              dbCollection,
              where(`dateStart`, '==', todayDate)
            );

            const changes = collectionSnapshots(queries).pipe(
              map((changes) => {
                return changes.map((snap) => {
                  const data = snap.data();
                  const id = snap.id;
                  return { id, ...data };
                });
              })
            );

            return changes;
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }
  getAllWeekTasks(operationId: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !operationId) {
            return of([]);
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/operations/${operationId}/tasks`
            );

            const today = new Date(Date.now());
            const startDate: Date = startOfWeek(today);
            const endDate: Date = endOfWeek(today);
            const startTime = Timestamp.fromDate(startDate);
            const endTime = Timestamp.fromDate(endDate);

            const queries = query(
              dbCollection,
              where(`dateStart`, '>=', startTime),
              where(`dateStart`, '<=', endTime)
            );

            const changes = collectionSnapshots(queries).pipe(
              map((changes) => {
                return changes.map((snap) => {
                  const data = snap.data();
                  const id = snap.id;
                  return { id, ...data };
                });
              })
            );

            return changes;
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }
  getAllMonthTasks(operationId: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !operationId) {
            return of([]);
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/operations/${operationId}/tasks`
            );

            const today = new Date(Date.now());
            const startDate: Date = startOfMinute(today);
            const endDate: Date = endOfMonth(today);
            const startTime = Timestamp.fromDate(startDate);
            const endTime = Timestamp.fromDate(endDate);

            const queries = query(
              dbCollection,
              where(`dateStart`, '>=', startTime),
              where(`dateStart`, '<=', endTime)
            );

            const changes = collectionSnapshots(queries).pipe(
              map((changes) => {
                return changes.map((snap) => {
                  const data = snap.data();
                  const id = snap.id;
                  return { id, ...data };
                });
              })
            );

            return changes;
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  getOne(operationId: string, id: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !operationId) {
            return of(null);
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/operations/${operationId}/tasks`
            );

            const documentRef = doc(dbCollection, id);
            return from(
              getDoc(documentRef)
                .then((snap) => {
                  const data = snap.data();
                  const id = snap.id;
                  return { id, ...data };
                })
                .catch((error) => error)
            ).pipe(mergeMap((res: any) => of(res)));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  addOne(operation: OperationId, task: OperationTask): Observable<any> {
    try {
      if (!task || !operation) return EMPTY;

      const { title, description, texts, priority, dateStart, dateEnd } = task;

      const newTask = {
        title,
        description,
        texts,
        priority,
        dateStart,
        dateEnd,
        operation: { id: operation.id },
      };
      return this.apollo.mutate({
        mutation: this.CREATE_OPERATION_TASK,
        variables: {
          task: newTask,
        },
      });
    } catch (error) {
      return of(error);
    }
  }

  updateOne(
    task: Partial<OperationTaskId | any>,
    type?: string
  ): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !task) {
            return of(null);
          } else {
            const dbDocument = doc(
              this.db,
              `organisations/${user.organisation.id}/operations/${task.operation.id}/tasks/${task.id}`
            );

            const typeOfUpdate: string =
              type === 'todo'
                ? 'modifiée'
                : type === 'done'
                ? 'terminée'
                : type === 'tested'
                ? ''
                : type === 'verifed'
                ? 'à vérifier'
                : '';

            return from(
              setDoc(
                dbDocument,
                { ...task, userUpdate: user, dateUpdate: serverTimestamp() },
                { merge: true }
              )
                .then(() => {
                  if (
                    typeOfUpdate === 'modifiée' ||
                    typeOfUpdate === 'terminée'
                  ) {
                    return of(`Tâche ${typeOfUpdate}`);
                  }
                  return of(null);
                })
                .catch((error) => of(error))
            ).pipe(mergeMap((res) => res));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  deleteOne(operationId: string, id: string): Observable<any> {
    try {
      if (!operationId || !id) return EMPTY;
      return this.apollo.mutate({
        mutation: this.DELETE_OPERATION_TASK,
        variables: {
          operationId: operationId,
          id: id,
        },
      });
    } catch (error) {
      return of(error);
    }
  }

  deleteAll(operationId: string): Observable<any> {
    try {
      if (!operationId) return EMPTY;
      return this.apollo.mutate({
        mutation: this.DELETE_OPERATION_ALL_TAKS,
        variables: {
          id: operationId,
        },
      });
    } catch (error) {
      return of(error);
    }
  }
}
