<nz-card nzSize="small" [nzBorderless]="bordeless">
  <nz-row nzAlign="top" nzGutter="4" nzJustify="center" *ngIf="graph">
    <nz-col nzSpan="24">
      <nz-steps
        [nzCurrent]="1"
        [nzSize]="size === 'small' ? 'small' : 'default'"
        nzDirection="horizontal"
      >
        <nz-step
          [nzTitle]="startTitle"
          [nzDescription]="startTpl"
          nzIcon="field-time"
          [nzSubtitle]="startSubtitleTpl"
          [nzStatus]="
            (([start, end] | distancePercent: distance)?.percent >= 100 &&
              status) ||
            (([start, end] | distancePercent: distance)?.percent <= 100 &&
              status) ||
            status === true
              ? 'finish'
              : ([start, end] | distancePercent: distance)?.percent >= 100 &&
                !status
              ? 'error'
              : ([start, end] | distancePercent: distance)?.percent < 100 &&
                !status &&
                ([start, end] | distancePercent: distance)?.percent > 0 &&
                !status
              ? 'process'
              : 'wait'
          "
        ></nz-step>
        <ng-template #startTpl>
          <span *ngIf="info">{{
            start | dateFr: (timeFormat ? timeFormat : "time")
          }}</span>
        </ng-template>
        <ng-template #startSubtitleTpl
          ><small *ngIf="info">
            {{ start | dateFr: "distanceSuffix" }}</small
          ></ng-template
        >

        <nz-step
          *ngIf="isIntermediaire"
          nzTitle="Point intermédiaire"
          [nzDescription]="middleTpl"
        ></nz-step>
        <ng-template #middleTpl>
          {{ [start, end] | dateMiddle: "P" }}
        </ng-template>

        <nz-step
          [nzTitle]="endTitle"
          [nzDescription]="endTpl"
          [nzSubtitle]="endSubtitleTpl"
          nzIcon="field-time"
          [nzStatus]="
            (([start, end] | distancePercent: distance)?.percent >= 100 &&
              status) ||
            (([start, end] | distancePercent: distance)?.percent <= 100 &&
              status) ||
            status === true
              ? 'finish'
              : ([start, end] | distancePercent: distance)?.percent >= 100 &&
                !status
              ? 'error'
              : ([start, end] | distancePercent: distance)?.percent < 100 &&
                !status &&
                ([start, end] | distancePercent: distance)?.percent > 0 &&
                !status
              ? 'process'
              : 'wait'
          "
        ></nz-step>
        <ng-template #endTpl>
          <span *ngIf="info">{{
            end | dateFr: (timeFormat ? timeFormat : "time")
          }}</span>
        </ng-template>
        <ng-template #endSubtitleTpl
          ><small *ngIf="info">{{
            end | dateFr: "distanceSuffix"
          }}</small></ng-template
        >
      </nz-steps>
    </nz-col>

    <!-- <nz-col nzSpan="2">
      <app-time-indicators-status
        [type]="'begin'"
        [start]="start"
        [end]="end"
        [distance]="distance"
      ></app-time-indicators-status>
    </nz-col>
    <nz-col nzSpan="6">
      <small *ngIf="dates">{{
        start | dateFr: (timeFormat ? timeFormat : "time")
      }}</small>
    </nz-col>
    <nz-col nzSpan="8" class="progressTimeIndicators">
      <nz-progress
        [nzPercent]="
          status ? 100 : ([start, end] | distancePercent: distance)?.percent
        "
        [nzStrokeColor]="
          status
            ? green
            : ([start, end] | distancePercent: distance)?.percent < 100 &&
              !status &&
              ([start, end] | distancePercent: distance)?.percent > 0 &&
              !status
            ? bleu
            : red
        "
        [nzSteps]="step"
        [nzWidth]="300"
        [nzStatus]="
          (([start, end] | distancePercent: distance)?.percent >= 100 &&
            status) ||
          (([start, end] | distancePercent: distance)?.percent <= 100 &&
            status) ||
          status
            ? 'success'
            : ([start, end] | distancePercent: distance)?.percent >= 100 &&
              !status
            ? 'exception'
            : ([start, end] | distancePercent: distance)?.percent < 100 &&
              !status &&
              ([start, end] | distancePercent: distance)?.percent > 0 &&
              !status
            ? 'active'
            : 'normal'
        "
      ></nz-progress>
    </nz-col>
    <nz-col nzSpan="6">
      <small *ngIf="end && dates">{{
        end | dateFr: (timeFormat ? timeFormat : "time")
      }}</small>
    </nz-col>
    <nz-col nzSpan="2">
      <app-time-indicators-status
        [type]="'finish'"
        [start]="start"
        [end]="end"
      ></app-time-indicators-status>
    </nz-col> -->
  </nz-row>

  <!-- <nz-row nzAlign="top" nzGutter="4" nzJustify="center" *ngIf="info">
    <nz-col nzSpan="8">
      <nz-card nzBorderless class="smallContainer" nzSize="small">
        <small>Démarrage {{ start | dateFr: "distanceSuffix" }}</small>
      </nz-card>
    </nz-col>
    <nz-col nzSpan="1">
    </nz-col>
    <nz-col nzSpan="6"> </nz-col>
    <nz-col nzSpan="1">
    </nz-col>

    <nz-col nzSpan="8">
      <nz-card nzBorderless class="smallContainer" nzSize="small">
        <small *ngIf="end">
          Échéance {{ end | dateFr: "distanceSuffix" }}</small
        >
      </nz-card>
    </nz-col>
  </nz-row> -->
</nz-card>
