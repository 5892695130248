<nz-descriptions
  nzSize="small"
  nzBordered
  [nzColumn]="{ xxl: 4, xl: 4, lg: 4, md: 4, sm: 2, xs: 1 }"
>
</nz-descriptions>

<ng-template #prePlace
  ><mat-icon aria-hidden="false" aria-label="Handicap" class="iconCoordonnees"
    >place</mat-icon
  ></ng-template
>
