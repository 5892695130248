<ng-container *ngIf="user$ | async as user">
  <form [formGroup]="messageForm" nz-form nzLayout="vertical">
    <nz-form-item nzRequired="true" class="input__MESSAGE">
      <nz-form-control>
        <input
          nz-input
          nzBorderless
          formControlName="subtitle"
          nzSize="default"
          placeholder="Objet..."
          required
          class="titleForm__MESSAGE"
        />
      </nz-form-control>
    </nz-form-item>
  </form>

  <app-users-select
    [borderless]="true"
    (select)="addUser_MESSAGE($event)"
  ></app-users-select>

  <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
  <form [formGroup]="messageForm" nz-form nzLayout="vertical">
    <nz-form-item nzRequired="true" class="texterea__MESSAGE">
      <textarea
        [(ngModel)]="messageText"
        nz-input
        nzBorderless
        nzSize="default"
        placeholder="Message..."
        nzPlaceHolder="Message..."
        [nzAutosize]="{ minRows: 4, maxRows: 4 }"
        [ngModelOptions]="{ standalone: true }"
        (ngModelChange)="addText_MESSAGE($event, user)"
        required
      ></textarea>
    </nz-form-item>
  </form>
  <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>

  <nz-button-group class="btns__MESSAGE">
    <button nz-button (click)="onCancel()" nzBlock nzType="text">
      <i nz-icon nzType="close"></i>
      Ignorer
    </button>
    <button
      nz-button
      nzType="text"
      nzBlock
      (click)="onAdd()"
      [disabled]="messageForm.invalid"
    >
      <i nz-icon nzType="send"></i>
      Envoyer
    </button>
  </nz-button-group>
</ng-container>
