import { Injectable } from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'any',
})
export class PortalNavigationService {
  private activePortal = new Subject<ComponentPortal<any>>();
  readonly portal$ = this.activePortal.asObservable();

  constructor() {}
  setPortal(portal: ComponentPortal<any>) {
    this.activePortal.next(portal);
  }
}
