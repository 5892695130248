import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Apollo, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'any',
})
export class TodosService {
  GET_TODOS = gql`
    query Todos {
      todos {
        id
        allDay
        auteur {
          displayName
          avatar
          photo
        }
        completed
        dateEnd
        dateStart
        dateUpdate
        priority
        sujets {
          id
          dateUpdate
          priority
          status
          text
          type
        }
        title
      }
    }
  `;

  GET_COMPLETED_TODOS = gql`
    query CompletedTodos {
      completedTodos {
        id
        allDay
        auteur {
          displayName
          avatar
          photo
        }
        completed
        dateEnd
        dateStart
        dateUpdate
        priority
        sujets {
          id
          dateUpdate
          priority
          status
          text
          type
        }
        title
      }
    }
  `;

  GET_PRIORITY_TODOS = gql`
    query PriorityTodos {
      priorityTodos {
        id
        allDay
        auteur {
          displayName
          avatar
          photo
        }
        completed
        dateEnd
        dateStart
        dateUpdate
        priority
        sujets {
          id
          dateUpdate
          priority
          status
          text
          type
        }
        title
      }
    }
  `;
  GET_ALL_DAY_TODOS = gql`
    query AllDayTodos {
      allDayTodos {
        id
        allDay
        auteur {
          displayName
          avatar
          photo
        }
        completed
        dateEnd
        dateStart
        dateUpdate
        priority
        sujets {
          id
          dateUpdate
          priority
          status
          text
          type
        }
        title
      }
    }
  `;

  GET_TODO = gql`
    query Todo($id: ID!) {
      todo(id: $id) {
        id
        allDay
        auteur {
          displayName
          avatar
          photo
        }
        completed
        dateEnd
        dateStart
        dateUpdate
        priority
        sujets {
          id
          dateUpdate
          priority
          status
          text
          type
        }
        title
      }
    }
  `;

  CREATE_TODO = gql`
    mutation CreateTodo($todo: TodoInput!) {
      createTodo(todo: $todo) {
        id
        allDay
        auteur {
          displayName
          avatar
          photo
        }
        completed
        dateEnd
        dateStart
        dateUpdate
        priority
        sujets {
          id
          dateUpdate
          priority
          status
          text
          type
        }
        title
      }
    }
  `;
  UPDATE_TODO = gql`
    mutation UpdateTodo($todo: TodoUpdateInput!) {
      updateTodo(todo: $todo) {
        id
        allDay
        auteur {
          displayName
          avatar
          photo
        }
        completed
        dateEnd
        dateStart
        dateUpdate
        priority
        sujets {
          id
          dateUpdate
          priority
          status
          text
          type
        }
        title
      }
    }
  `;

  COMPLETED_TODO = gql`
    mutation CompleteTodo($id: ID!) {
      completeTodo(id: $id) {
        id
        allDay
        auteur {
          id
          displayName
          avatar
          photo
        }
        completed
        dateEnd
        dateStart
        dateUpdate
        priority
        sujets {
          id
          dateUpdate
          priority
          status
          text
          type
        }
        title
      }
    }
  `;

  DELETE_TODO = gql`
    mutation DeleteTodo($id: ID!) {
      deleteTodo(id: $id)
    }
  `;
  DELETE_COMPLETED_TODOS = gql`
    mutation DeleteCompletedTodos {
      deleteCompletedTodos
    }
  `;

  DELETE_TODOS = gql`
    mutation DeleteTodos {
      deleteTodos
    }
  `;

  constructor(private apollo: Apollo) {}

  getAll(): Observable<any> {
    try {
      return this.apollo
        .watchQuery<any>({
          query: this.GET_TODOS,
        })
        .valueChanges.pipe(
          map(({ data, loading, error }) => {
            return { data, loading, error };
          })
        );
    } catch (error) {
      return of(error);
    }
  }

  getAllCompleted(): Observable<any> {
    try {
      return this.apollo
        .watchQuery<any>({
          query: this.GET_COMPLETED_TODOS,
        })
        .valueChanges.pipe(
          map(({ data, loading, error }) => {
            return { data, loading, error };
          })
        );
    } catch (error) {
      return of(error);
    }
  }

  getAllPriority(): Observable<any> {
    try {
      return this.apollo
        .watchQuery<any>({
          query: this.GET_PRIORITY_TODOS,
        })
        .valueChanges.pipe(
          map(({ data, loading, error }) => {
            return { data, loading, error };
          })
        );
    } catch (error) {
      return of(error);
    }
  }
  getAllDays(): Observable<any> {
    try {
      return this.apollo
        .watchQuery<any>({
          query: this.GET_ALL_DAY_TODOS,
        })
        .valueChanges.pipe(
          map(({ data, loading, error }) => {
            return { data, loading, error };
          })
        );
    } catch (error) {
      return of(error);
    }
  }

  getOne(id: string): Observable<any> {
    try {
      return this.apollo
        .watchQuery<any>({
          query: this.GET_TODO,
          variables: { id: id },
        })
        .valueChanges.pipe(
          map(({ data, loading, error }) => {
            return { data, loading, error };
          })
        );
    } catch (error) {
      return of(error);
    }
  }

  addOne(todo: any): Observable<any> {
    try {
      return this.apollo.mutate({
        mutation: this.CREATE_TODO,
        variables: {
          todo: todo,
        },
        refetchQueries: [{ query: this.GET_TODOS }],
      });
    } catch (error) {
      return of(error);
    }
  }

  updateOne(todo: any): Observable<any> {
    try {
      return this.apollo.mutate({
        mutation: this.UPDATE_TODO,
        variables: {
          todo: todo,
        },
        refetchQueries: [{ query: this.GET_TODOS }],
      });
    } catch (error) {
      return of(error);
    }
  }

  completeOne(id: string | any): Observable<any> {
    try {
      return this.apollo.mutate({
        mutation: this.COMPLETED_TODO,
        variables: {
          id: id,
        },
        refetchQueries: [{ query: this.GET_TODOS }],
      });
    } catch (error) {
      return of(error);
    }
  }

  deleteOne(id: string): Observable<any> {
    try {
      return this.apollo.mutate({
        mutation: this.DELETE_TODO,
        variables: {
          id: id,
        },
        refetchQueries: [{ query: this.GET_TODOS }],
      });
    } catch (error) {
      return of(error);
    }
  }

  deleteAllCompleted(): Observable<any> {
    try {
      return this.apollo.mutate({
        mutation: this.DELETE_TODOS,
        refetchQueries: [{ query: this.GET_TODOS }],
      });
    } catch (error) {
      return of(error);
    }
  }

  deleteAll(): Observable<any> {
    try {
      return this.apollo.mutate({
        mutation: this.DELETE_COMPLETED_TODOS,
        refetchQueries: [{ query: this.GET_TODOS }],
      });
    } catch (error) {
      return of(error);
    }
  }
}
