import {
  NzNotificationPlacement,
  NzNotificationService,
} from 'ng-zorro-antd/notification';
import { FichesDePosteExportPDFService } from '../service/fiches-de-poste-export-pdf.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, tap } from 'rxjs';
import * as fromFicheDePosteAction from './fiche-de-poste.actions';

const NOTIFICATION_PLACEMENT: NzNotificationPlacement = 'bottomRight';

@Injectable()
export class FicheDePosteEffects {
  exportOperationFicheDePoste$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromFicheDePosteAction.exportFicheDePoste),
        tap(() => {
          this.notification.info(
            'Export PDF',
            "La fiche de poste est en cours d'exportation...",
            {
              nzAnimate: true,
              nzDuration: 6000,
              nzPlacement: NOTIFICATION_PLACEMENT,
              nzKey: 'EXPORT FICHE DE POSTE',
            }
          );
        }),
        mergeMap((action) =>
          this.fichesDePosteExportPDFService
            .onFicheDePosteExport(
              action.fiche,
              action.user,
              action.exportType,
              action.isAnonyme,
              action.referentRecrutement
            )
            .pipe(
              map((success: any) => {
                this.notification.success(
                  'Export PDF',
                  'Votre fiche de poste est prête',
                  {
                    nzAnimate: true,
                    nzDuration: 6000,
                    nzPlacement: NOTIFICATION_PLACEMENT,
                    nzKey: 'EXPORT FICHE DE POSTE',
                  }
                );
                return fromFicheDePosteAction.exportFicheDePosteSuccess({
                  success,
                });
              }),
              catchError((error) => {
                this.notification.error('Export PDF', error?.message, {
                  nzAnimate: true,
                  nzDuration: 6000,
                  nzPlacement: NOTIFICATION_PLACEMENT,
                  nzKey: 'EXPORT FICHE DE POSTE',
                });
                return of(
                  fromFicheDePosteAction.exportFicheDePosteFailure({ error })
                );
              })
            )
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private fichesDePosteExportPDFService: FichesDePosteExportPDFService,
    private notification: NzNotificationService
  ) {}
}
