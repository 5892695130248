import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-coordonnees',
  templateUrl: './coordonnees.component.html',
  styleUrls: ['./coordonnees.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoordonneesComponent implements OnInit, OnChanges, OnDestroy {
  subscribe = new Subject();
  type1 = 'Mobile';
  type2 = 'Fixe';
  coordonnees$ = new BehaviorSubject<any>(null);

  @Input() update: any;
  @Input() updateMobile: any;
  @Input() updateEmail: any;
  @Input() updateFixe: any;
  @Input() isLabel: boolean = true;
  @Input() webItem: boolean = false;
  @Input() isEmail: boolean = true;
  @Input() isMobile: boolean = true;
  @Input() isFixe: boolean = true;
  @Input() borderless: boolean = true;
  @Input() required: boolean = true;
  @Input() isFixeRequired: boolean = true;
  @Input() isMobileRequired: boolean = true;
  @Input() isEmailRequired: boolean = true;

  @Output() mobile = new EventEmitter<string>();
  @Output() fixe = new EventEmitter<string>();
  @Output() email = new EventEmitter<string>();
  @Output() phoneNumber = new EventEmitter<string>();
  @Output() web = new EventEmitter<string>();
  @Output() reseaux = new EventEmitter<string>();
  @Output() coordonnees = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.onUpdate(changes.update?.currentValue);
  }

  setMobilePhone(event: string): any {
    const indicatif = 33;
    const slice = event.slice(1, 10);
    const phoneNumber = `+${indicatif}${slice}`;

    this.mobile.emit(event);
    this.phoneNumber.emit(phoneNumber);
    const setMobile = {
      mobile: event,
      phoneNumber: phoneNumber,
      email: null,
      fixe: null,
      reseaux: null,
    };

    this.coordonnees.emit(setMobile);
    return setMobile;
  }

  setFixePhone(event: string): string {
    this.fixe.emit(event);
    return event;
  }

  setEmail(event: string): string {
    this.email.emit(event);
    return event;
  }

  setWeb(event: any): string {
    this.web.emit(event.target.value);
    return event;
  }

  setReseau(event: any): string {
    this.reseaux.emit(event);
    return event;
  }

  onUpdate(coordonnees: any): void {}

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }
}
