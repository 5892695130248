import { Pipe, PipeTransform } from '@angular/core';
import memo from 'memo-decorator'

@Pipe({
  name: 'initial'
})
export class InitialPipe implements PipeTransform {
  @memo()
  transform(value: string, args?: any): any {
    if(!value){
      return '?'
    }
    const format = value.split(' ')
    const first: string = format[0].split('')[0].toUpperCase()
    const last: string = format[1] ? format[1].split('')[0].toUpperCase() : '.'
    const letter = `${first}${last}`

    return letter;
  }

}
