<nz-row
  nzAlign="middle"
  nzGutter="4"
  nzJustify="center"
  *ngIf="operation$ | async as operation"
>
  <nz-col nzSpan="6" class="filterTitle">
    <nz-badge [nzCount]="fiches$ | async | size" [nzOffset]="[10, 0]">
      <h4 nz-typography>Fiches de poste</h4>
    </nz-badge>
  </nz-col>
  <nz-col nzSpan="15" class="inputSearch">
    <input
      nz-input
      [(ngModel)]="inputModel"
      (ngModelChange)="onSeach($event, operation)"
      nzBorderless
      placeholder="Rechercher une fiche de poste..."
    />
  </nz-col>

  <nz-col nzSpan="1"></nz-col>
  <nz-col nzSpan="1">
    <button
      nz-button
      nzShape="circle"
      nzType="text"
      nz-tooltip
      nzTooltipTitle="Filtrer les fiches de poste"
      nzTooltipPlacement="bottom"
      [nzTooltipMouseEnterDelay]="0.5"
      nzTooltipPlacement="bottom"
      class="btnFilter"
    >
      <i nz-icon nzType="filter"></i>
    </button>
  </nz-col>

  <nz-col nzSpan="1">
    <app-button-dropdown [isRelance]="true"></app-button-dropdown>
  </nz-col>
</nz-row>
`,
