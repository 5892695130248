import { OperationDashboardComponent } from './components/operation-dashboard/operation-dashboard.component';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { ContentsModule } from 'src/app/contents/contents.module';
import * as fromOperationDashboard from './store/operation-dashboard.reducer';
import { OperationDashboardEffects } from './store/operation-dashboard.effects';
import { OperationDashboardHeaderComponent } from './components/operation-dashboard-header/operation-dashboard-header.component';
import { OperationDashboardHeaderCardComponent } from './components/operation-dashboard-header-card/operation-dashboard-header-card.component';
import { DirectiveModule } from 'src/app/directive/directive.module';
import { OperationDashboardParticipantsComponent } from './components/operation-dashboard-participants/operation-dashboard-participants.component';
import { OperationDashboardModulesComponent } from './components/operation-dashboard-modules/operation-dashboard-modules.component';
import { OperationDashboardEntitesComponent } from './components/operation-dashboard-entites/operation-dashboard-entites.component';
import { OperationDashboardActionsComponent } from './components/operation-dashboard-actions/operation-dashboard-actions.component';
import { OperationDashboardMissionsComponent } from './components/operation-dashboard-missions/operation-dashboard-missions.component';
import { OperationDashboardIntervenantsComponent } from './components/operation-dashboard-intervenants/operation-dashboard-intervenants.component';
import { OperationDashboardMainComponent } from './components/operation-dashboard-main/operation-dashboard-main.component';
import { OperationStatistiquesService } from './services/operation-statistiques.service';
import { NoResultModule } from 'src/app/contents/components/no-result/no-result.module';
import { HeaderModule } from 'src/app/contents/components/header-title/header.module';

@NgModule({
  declarations: [
    OperationDashboardComponent,
    OperationDashboardHeaderComponent,
    OperationDashboardHeaderCardComponent,
    OperationDashboardParticipantsComponent,
    OperationDashboardModulesComponent,
    OperationDashboardEntitesComponent,
    OperationDashboardActionsComponent,
    OperationDashboardMissionsComponent,
    OperationDashboardIntervenantsComponent,
    OperationDashboardMainComponent,
  ],
  imports: [
    SharedModule,
    ContentsModule,
    CommonModule,
    NoResultModule,
    HeaderModule,

    StoreModule.forFeature(
      fromOperationDashboard.operationDashboardsFeatureKey,
      fromOperationDashboard.reducer
    ),
    EffectsModule.forFeature([OperationDashboardEffects]),
    DirectiveModule,
  ],
  exports: [OperationDashboardComponent],
  providers: [OperationStatistiquesService],
})
export class OperationDashboardModule {}
