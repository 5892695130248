<nz-table
  #filterTable
  [nzData]="listOfData"
  nzTableLayout="fixed"
  [nzNoResult]="noData"
  nzSize="small"
  [nzScroll]="scroll"
  [nzLoadingDelay]="2"
>
  <thead>
    <tr>
      <th
        *ngFor="let column of listOfColumns"
        [nzSortOrder]="column.sortOrder"
        [nzSortFn]="column.sortFn"
        [nzSortDirections]="column.sortDirections"
        [nzFilterMultiple]="column.filterMultiple"
        [nzFilters]="column.listOfFilter"
        [nzFilterFn]="column.filterFn"
      >
        {{ column.name }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of filterTable.data">
      <td>{{ data.name }}</td>
      <td>{{ data.age }}</td>
      <td>{{ data.address }}</td>
    </tr>
  </tbody>
</nz-table>

<ng-template #noData>
  <app-no-result
    [icon]="icon"
    [addtitle]="addItemTitle"
    [description]="noDataTitle"
  ></app-no-result>
</ng-template>
