import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { OperationId } from 'src/app/components/operation/store/operation.model';

import { ActionSmallId } from '../../store/action.model';
import { ActionState } from '../../store/action.reducer';
import * as fromActionAction from '../../store/action.actions';
import * as fromActionSelector from '../../store/action.selectors';
import { OperationState } from 'src/app/components/operation/store/operation.reducer';
import * as fromOperationAction from 'src/app/components/operation/store/operation.actions';
import * as fromOperationSelector from 'src/app/components/operation/store/operation.selectors';

@Component({
  selector: 'app-action-select',
  template: `
    <nz-select
      *ngIf="operation$ | async as operation"
      nzShowSearch
      nzBorderless
      nzServerSearch
      [nzPlaceHolder]="placeHolder"
      [(ngModel)]="inputModel"
      [nzShowArrow]="false"
      (nzOnSearch)="onSeach($event, operation)"
      (ngModelChange)="onSelect($event)"
    >
      <nz-option
        *ngFor="let action of actions$ | async"
        [nzLabel]="action.title"
        [nzValue]="action"
        nzCustomContent
      >
        <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
          <nz-col nzSpan="4">
            <mat-icon class="matIcon">dashboard</mat-icon>
          </nz-col>
          <nz-col nzSpan="16">
            <p nz-typography>{{ action?.title | uppercase }}</p>
          </nz-col>
          <nz-col nzSpan="4">
            <nz-tag nzColor="success" *ngIf="!action?.isFinished"
              >En cours</nz-tag
            >
            <nz-tag nzColor="green" *ngIf="action?.isFinished">Terminé</nz-tag>
          </nz-col>
        </nz-row>
      </nz-option>
    </nz-select>
  `,
  styles: [
    `
      nz-select {
        width: 100% !important;
        border-radius: 12px;
        background-color: #f5f5f5;
      }

      mat-ion,
      h5 {
        color: #062949;
      }

      nz-tag {
        border: none;
        border-radius: 12px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionSelectComponent implements OnInit, OnDestroy {
  actions$: Observable<ActionSmallId[] | any> = of(null);
  operation$: Observable<OperationId | any> = of(null);
  subscribe = new Subject();
  inputSearch$ = new BehaviorSubject<string>('');
  inputModel: string = '';

  @Input() placeHolder: string = 'Ajouter une action';
  @Output() select = new EventEmitter<any>(false);

  constructor(
    private actionStore: Store<ActionState>,
    private operationStore: Store<OperationState>
  ) {}

  ngOnInit(): void {
    this.getOperation();
    this.getActions();
  }
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getOperation(): void {
    this.operation$ = this.operationStore.select(
      fromOperationSelector.operation
    );
  }
  getActions(): void {
    this.actions$ = this.actionStore.select(fromActionSelector.actions);
  }
  onSeach(event: string, operation: OperationId): void {
    if (!event) {
      this.actionStore.dispatch(
        fromActionAction.loadActions({ operationId: operation.id })
      );
      return;
    } else {
      const filter = {
        operationId: operation.id,
        event: event,
        type: 'title',
      };
      this.actionStore.dispatch(fromActionAction.queryActions({ filter }));
    }
  }

  onSelect(action: ActionSmallId): void {
    if (!action) return;
    this.select.emit(action);
  }
}
