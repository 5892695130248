import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { EntiteSearch } from './entite-search.model';
import * as EntiteSearchActions from './entite-search.actions';

export const entiteSearchesFeatureKey = 'entiteSearches';

export interface EtablissementState extends EntityState<any> {
  header: any;
  etablissements: any[];
  etablissement: any;
  loading: boolean;
  error: any;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>();

export const initialState: EtablissementState = adapter.getInitialState({
  header: { nomber: 0, total: 0 },
  etablissements: [],
  etablissement: undefined,

  loading: false,
  error: undefined,
});

export const reducer = createReducer(
  initialState,

  on(EntiteSearchActions.loadEntiteSearch, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),

  on(EntiteSearchActions.loadEntiteSearchSuccess, (state, action) => {
    return {
      ...state,
      etablissements: action.result.etablissements,
      header: action.result.header,
      loading: false,
    };
  }),

  on(EntiteSearchActions.loadEntiteSearchFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
      loading: false,
    };
  }),
  on(EntiteSearchActions.loadEntiteSearchClear, (state) =>
    adapter.removeAll(state)
  ),
  on(EntiteSearchActions.loadingEntiteSearch, (state, action) => {
    return {
      ...state,
      loading: action.loading,
    };
  }),

  //ETABLISSEMENT
  on(EntiteSearchActions.loadEtablissement, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),

  on(EntiteSearchActions.loadEtablissementSuccess, (state, action) => {
    return {
      ...state,
      etablissement: action.etablissement,
      loading: false,
    };
  }),

  on(EntiteSearchActions.loadEtablissementFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
      loading: false,
    };
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
