import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-operation-cadrage-financeurs-form',
  templateUrl: './operation-cadrage-financeurs-form.component.html',
  styleUrls: ['./operation-cadrage-financeurs-form.component.scss']
})
export class OperationCadrageFinanceursFormComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
