import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-tab-title',
  template: `
    <nz-badge [nzCount]="count" [nzOffset]="[0, 10]">
      <button
        nz-button
        (click)="onSelect(title)"
        nzSize="small"
        nzShape="round"
        [ngClass]="{ tabButton: isActiv, default: !isActiv }"
      >
        {{ title }}
      </button>
    </nz-badge>
  `,
  styles: [
    `
      .default {
        background-color: transparent;
        color: grey;
        border: none;
      }

      button {
        border: none !important;
      }
    `,
  ],
})
export class TabTitleComponent implements OnInit {
  @Input() isActiv: boolean = false;
  @Input() color: string = '';
  @Input() title: string = '';
  @Input() count: number | any = 0;

  @Output() select = new EventEmitter<string>(false);
  constructor() {}

  ngOnInit(): void {}

  onSelect(title: string): void {
    this.select.emit(title);
  }
}
