import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'completed',
})
export class CompletedPipe implements PipeTransform {
  transform(values: any): any[] {
    if (!values?.length) return [];

    const completed = values.filter((el: any) => el.completed);
    return completed;
  }
}
