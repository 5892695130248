import { takeUntil } from 'rxjs/operators';
import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, Subject, BehaviorSubject } from 'rxjs';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';
import { DashboardState } from '../../store/dashboard.reducer';
import * as fromDashboardSelector from '../../store/dashboard.selectors';
import * as fromDashboardAction from '../../store/dashboard.actions';

@Component({
  selector: 'app-dashboard-entites',
  templateUrl: './dashboard-entites.component.html',
  styleUrls: ['./dashboard-entites.component.scss'],
})
export class DashboardEntitesComponent implements OnInit, OnDestroy {
  user$: Observable<any> = of(null);
  subscribe = new Subject();
  statistiques$: Observable<any> = of(null);
  currentDate = new Date(Date.now());
  currentYear: string = this.currentDate.getFullYear().toString();
  selectedYear: string = '';

  entitesTotal: number = 0;
  entitesObjectif: number = 0;
  activities$ = new BehaviorSubject<any>(null);
  secteurs$ = new BehaviorSubject<any>(null);
  categories$ = new BehaviorSubject<any>(null);
  types$ = new BehaviorSubject<any>(null);
  byMonths$ = new BehaviorSubject<any>(null);
  entiteRegions$ = new BehaviorSubject<any>(null);
  entiteCommunes$ = new BehaviorSubject<any>(null);
  entiteDepartements$ = new BehaviorSubject<any>(null);
  juridiques$ = new BehaviorSubject<any>(null);
  domaine$ = new BehaviorSubject<any>(null);
  grandDomaine$ = new BehaviorSubject<any>(null);

  @Output() total = new EventEmitter<number>(false);
  @Output() title = new EventEmitter<string>(false);

  constructor(
    private userStore: Store<UserState>,
    private dashboardStore: Store<DashboardState>
  ) {}

  ngOnInit(): void {
    this.getUser();
    this.loadStatistiques();
    this.getStatistiques();
    this.setStatistiques();
  }
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getUser(): void {
    this.user$ = this.userStore.select(fromUserSelector.user);
  }

  getStatistiques(): any {
    this.statistiques$ = this.dashboardStore.select(
      fromDashboardSelector.entites
    );
  }

  loadStatistiques(year: string = this.currentYear): any {
    if (!year) {
      return null;
    } else {
      this.dashboardStore.dispatch(
        fromDashboardAction.loadDashboardsEntites({ year })
      );
    }
  }

  setStatistiques(): void {
    this.statistiques$
      .pipe(takeUntil(this.subscribe))
      .subscribe((statistique) => {
        this.getEntiteSTATS(statistique);
      });
  }

  getEntiteSTATS(stats: any): any {
    if (!stats) {
      return null;
    }

    const charts = stats.charts;

    const adhesion = charts.adhesion;
    const total = stats.total;
    const objectif = stats.objectif;
    const title: string = 'Entités';
    this.total.emit(total);
    this.title.emit(title);

    this.entitesObjectif = objectif ? objectif : 0;

    this.activities$.next({
      title: 'Activité principale',
      type: 'pie',
      data: stats.activite_principale,
    });

    this.secteurs$.next({
      title: 'Secteur',
      type: 'pie',
      data: stats.secteur,
    });

    this.categories$.next({
      title: 'Catégorie',
      type: 'pie',
      data: stats.categorie_entreprise,
    });
    this.types$.next({
      title: 'Type',
      type: 'pie',
      data: stats.type,
    });
    this.byMonths$.next({
      title: 'Répartition par mois',
      type: 'barMonths',
      data: stats.byMonths,
    });

    this.entiteCommunes$.next({
      title: 'Commune',
      type: 'pie',
      data: stats.commune,
    });
    this.entiteDepartements$.next({
      title: 'Département',
      type: 'pie',
      data: stats.code_postal,
    });
    this.juridiques$.next({
      title: 'Catégorie juridique',
      type: 'pie',
      data: stats.categorie_juridique_entreprises,
    });

    console.log(stats);

    this.domaine$.next({
      title: 'Domaine professionnel',
      type: 'pie',
      data: stats.domaineProfessionnel,
    });

    this.grandDomaine$.next({
      title: 'Grand domaine',
      type: 'pie',
      data: stats.grandDomaine,
    });

    //SETTING
    const width = 500;
    const height = 400;
    // this.indicateurs(
    //   total,
    //   ess,
    //   title,
    //   this.entiteByIndicateurs,
    //   220,
    //   300,
    //   objectif
    // );
  }

  selectYear(year: any): void {}
}
