import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  Output,
  EventEmitter,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  MaxValidator,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';
import { UtilitiesState } from 'src/app/contents/components/utilities/store/utilities.reducer';
import * as fromUtilitiesSelector from 'src/app/contents/components/utilities/store/utilities.selectors';
import * as fromUtilitiesAction from 'src/app/contents/components/utilities/store/utilities.actions';
import { Timestamp } from 'firebase/firestore';
import { listAnimation } from 'src/app/app-animation';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-participant-diagnostic-form-experience-form',
  templateUrl: './participant-diagnostic-form-experience-form.component.html',
  styleUrls: ['./participant-diagnostic-form-experience-form.component.scss'],
})
export class ParticipantDiagnosticFormExperienceFormComponent
  implements OnInit, OnChanges, OnDestroy
{
  subscribe = new Subject();
  currentStep: number = 0;
  // export interface ParticipantDiagnosticExperience {
  //   id: string;
  //   date: {debut: string, fin: string};
  //   description: string;
  //   duree: number;
  //   enPoste: boolean;
  //   entreprise: string (ENTRERPISE DATA);
  //   etranger: boolean;
  //   intitule: string;
  //   lieu: string;
  //   isFormation: boolean;
  //   softSkills: any[]
  //   certification: any
  //   carteProfessionnelleRequis: boolean
  //  carteProfessionnelleRequisPrecision: string
  // Certifications
  // }

  //FORM FIELDS

  @Input() experience = new BehaviorSubject<any>(null);
  @Output() cancel = new EventEmitter();
  @Output() update = new EventEmitter();
  @Output() add = new EventEmitter();

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.form();
  }
  ngOnChanges(changes: SimpleChanges): void {}

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  form(): void {
    this.formInit();
    this.formCreate();
    this.formSet();
  }
  formInit(): void {}
  formCreate(): void {}
  formSet(): void {}

  onAddMetier(item: any): void {
    console.log(item);
  }

  onAdd(): void {
    this.add.emit(true);
  }

  onUpdate(): void {
    this.update.emit(true);
  }

  onCancel(): void {
    this.cancel.emit(true);
  }
}
