<form nz-form [formGroup]="utilityGroup" nzLayout="horizontal">
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="22">
      <nz-form-item nzRequired>
        <nz-form-control>
          <input
            [nzBorderless]="borderless"
            [ngClass]="{ borderlessClass: borderless }"
            nz-input
            formControlName="input"
            nzSize="default"
            placeholder="Nouvelle variable..."
          />
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col nzSpan="2">
      <button
        nz-button
        nzShape="circle"
        nzType="primary"
        type="button"
        [disabled]="!utilityGroup.valid || !variable"
        (click)="onAdd(items, utility, variable)"
      >
        <i nz-icon nzType="plus"></i>
      </button>
    </nz-col>
  </nz-row>
</form>
