import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { ButtonDropdownComponent } from './components/button-dropdown/button-dropdown.component';
import { ButtonsProfilActionsComponent } from './components/buttons-profil-actions/buttons-profil-actions.component';
import { MaterialModule } from 'src/app/css/material.module';
import { ZorroModule } from 'src/app/css/zorro.module';
import { ButtonMenuComponent } from './components/button-menu/button-menu.component';

@NgModule({
  declarations: [
    ButtonDropdownComponent,
    ButtonsProfilActionsComponent,
    ButtonMenuComponent,
  ],
  exports: [
    ButtonDropdownComponent,
    ButtonsProfilActionsComponent,
    ButtonMenuComponent,
  ],
  imports: [CommonModule, SharedModule],
})
export class ButtonsModule {}
