import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'contactsDisponibility',
})
export class ContactsDisponibilityPipe implements PipeTransform {
  transform(value: any[], type: string): any[] {
    if (!value || !value?.length) {
      return [];
    }

    if (type === 'disponibles') {
      return value.filter((el) => el.disponibility);
    }

    if (type === 'indisponibles') {
      return value.filter((el) => !el.disponibility);
    }else{
      return []
    }
  }
}
