import { Pipe, PipeTransform } from '@angular/core';
import { differenceBy } from 'lodash';
@Pipe({
  name: 'arrayDifference',
})
export class ArrayDifferencePipe implements PipeTransform {
  transform(value: any, propertie: any): boolean {
    if (!value || !propertie || !value[0] || !value[1]) {
      return true;
    }

    const sourceToCompare = value[0];
    const source = value[1];

    const isDifferenceBy = differenceBy(
      source,
      sourceToCompare,
      `${propertie}`
    );

    if (isDifferenceBy?.length) {
      return true;
    } else {
      return false;
    }
  }
}
