<nz-row
  *ngIf="contact$ | async as contact"
  nzAlign="middle"
  nzGutter="8"
  nzJustify="end"
  class="rowTitle"
>
  <nz-col nzSpan="3"> </nz-col>

  <nz-col nzSpan="21">
    <div class="titleHeader">
      <h5 nz-typography (click)="onSelectAction('details', contact)">
        {{ contact | displayName | carLimitation: 50 }}
      </h5>
    </div>
  </nz-col>
</nz-row>
