import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { NzButtonType } from 'ng-zorro-antd/button';
import { ButtonMenu } from '../../store/button.model';

@Component({
  selector: 'app-button-menu',
  templateUrl: './button-menu.component.html',
  styleUrls: ['./button-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonMenuComponent implements OnInit {
  @Input() title: string = '';
  @Input() icon: string;
  @Input() type: string = 'default';

  @Input() menu: ButtonMenu[] = [];

  @Output() select = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  onSelect(event: string): void {
    this.select.emit(event);
  }
}
