import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Utilities } from './utilities.model';
import * as UtilitiesActions from './utilities.actions';

export const utilitiesesFeatureKey = 'utilitieses';

export interface UtilitiesState extends EntityState<any> {
  loading: boolean;
  // REFERENTIELS
  civilities: any;
  communications: any;
  numerique: any;
  sortie: any;
  countries: any;
  criteres: any;
  mobility: any;
  sejours: any;
  frenchLevel: any;
  diploma: any;
  categories: any;
  periodInscription: any;
  allocation: any;
  reseauSociaux: any;
  couvertureMaladie: any;
  minimas: any;
  sante: any;
  genre: any;
  status: any;
  transport: any;
  judiciaire: any;
  matrimonial: any;
  handicap: any;
  bureautique: any;
  softskills: any;
  permis: any;
  geoMobilite: any;
  diagnosticTypes: any;

  //PLANNING
  planningTypes: any[];

  //
  utility: any;
  participant: any;
  participantIdentity: any;
  participantSituation: any;

  entite: any;
  contact: any;

  //MISSION
  missionUtilities: any;
  missionDeplacementUtilities: any;
  marchesTypes: any;
  objectifTypes: any;
  operation: any;

  error: any;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>();

export const initialState: UtilitiesState = adapter.getInitialState({
  loading: false,

  // REFERENTIELS
  civilities: undefined,
  communications: undefined,
  numerique: undefined,
  sortie: undefined,
  criteres: undefined,
  countries: undefined,

  mobility: undefined,
  sejours: undefined,
  frenchLevel: undefined,
  diploma: undefined,
  categories: undefined,
  periodInscription: undefined,
  allocation: undefined,
  reseauSociaux: undefined,
  couvertureMaladie: undefined,
  minimas: undefined,
  sante: undefined,
  genre: undefined,
  status: undefined,
  transport: undefined,
  judiciaire: undefined,
  matrimonial: undefined,
  handicap: undefined,
  bureautique: undefined,
  softskills: undefined,
  permis: undefined,
  geoMobilite: undefined,
  utility: undefined,
  diagnosticTypes: undefined,
  participant: undefined,
  participantIdentity: undefined,
  participantSituation: undefined,
  entite: undefined,
  contact: undefined,
  operation: undefined,
  missionUtilities: undefined,
  missionDeplacementUtilities: undefined,
  marchesTypes: undefined,
  objectifTypes: undefined,

  //PLANNING
  planningTypes: [],

  error: undefined,
});

export const reducer = createReducer(
  initialState,

  on(UtilitiesActions.loadUtilitiesSuccess, (state, action) =>
    adapter.setAll(action.utilities, state)
  ),
  on(UtilitiesActions.updateUtility, (state, action) =>
    adapter.updateOne(action.utility, state)
  ),

  //CIVILITIES
  on(UtilitiesActions.loadCivilitiesSuccess, (state, action) => {
    return {
      ...state,
      civilities: action.civilities,
      loading: false,
    };
  }),

  //COMMUNICATIONS
  on(UtilitiesActions.loadCommunicationSuccess, (state, action) => {
    return {
      ...state,
      communications: action.communications,
      loading: false,
    };
  }),

  //MATRIMONIAL
  on(UtilitiesActions.loadMatrimonialReferentielSuccess, (state, action) => {
    return {
      ...state,
      matrimonial: action.utilities,
      loading: false,
    };
  }),

  //SANTE
  on(UtilitiesActions.loadSanteReferentielSuccess, (state, action) => {
    return {
      ...state,
      sante: action.utilities,
      loading: false,
    };
  }),

  //COUVERTURE MALADIE
  on(
    UtilitiesActions.loadCouvertureMaladieReferentielSuccess,
    (state, action) => {
      return {
        ...state,
        couvertureMaladie: action.utilities,
        loading: false,
      };
    }
  ),

  //HANDICAP
  on(UtilitiesActions.loadHandicapReferentielSuccess, (state, action) => {
    return {
      ...state,
      handicap: action.utilities,
      loading: false,
    };
  }),

  //ALLOCATION
  on(UtilitiesActions.loadAllocationReferentielSuccess, (state, action) => {
    return {
      ...state,
      allocation: action.utilities,
      loading: false,
    };
  }),

  //DIPLOMA
  on(UtilitiesActions.loadDiplomaReferentielSuccess, (state, action) => {
    return {
      ...state,
      diploma: action.utilities,
      loading: false,
    };
  }),

  //PERMIS
  on(UtilitiesActions.loadPermisReferentielSuccess, (state, action) => {
    return {
      ...state,
      permis: action.utilities,
      loading: false,
    };
  }),

  //MODE DE TRANSPORT
  on(UtilitiesActions.loadTransportReferentielSuccess, (state, action) => {
    return {
      ...state,
      transport: action.utilities,
      loading: false,
    };
  }),

  //GEOGRAPHIQUE MOBILITE
  on(UtilitiesActions.loadGeoMobiliteReferentielSuccess, (state, action) => {
    return {
      ...state,
      geoMobilite: action.utilities,
      loading: false,
    };
  }),

  //SORTIES
  on(UtilitiesActions.loadSortieReferentielSuccess, (state, action) => {
    return {
      ...state,
      sortie: action.utilities,
      loading: false,
    };
  }),

  //CRITERES
  on(UtilitiesActions.loadCriteresReferentielSuccess, (state, action) => {
    return {
      ...state,
      criteres: action.utilities,
      loading: false,
    };
  }),

  //SEJOURS
  on(UtilitiesActions.loadSejourReferentielSuccess, (state, action) => {
    return {
      ...state,
      sejours: action.utilities,
      loading: false,
    };
  }),

  //BUREAUTIQUE
  on(
    UtilitiesActions.loadLevelBureautiqueReferentielSuccess,
    (state, action) => {
      return {
        ...state,
        bureautique: action.utilities,
        loading: false,
      };
    }
  ),

  //INSCRIPTION PERIOD
  on(
    UtilitiesActions.loadPeriodInscriptionReferentielSuccess,
    (state, action) => {
      return {
        ...state,
        periodInscription: action.utilities,
        loading: false,
      };
    }
  ),

  //FRENCH LEVEL
  on(UtilitiesActions.loadFrenchLevelReferentielSuccess, (state, action) => {
    return {
      ...state,
      frenchLevel: action.utilities,
      loading: false,
    };
  }),

  //JUDICIAIRE
  on(UtilitiesActions.loadJudiciaireReferentielSuccess, (state, action) => {
    return {
      ...state,
      judiciaire: action.utilities,
      loading: false,
    };
  }),

  //**DIAGNOSTIC */
  //**DIAGNOSTIC TYPES */
  on(UtilitiesActions.loadDiagnosticTypesSuccess, (state, action) => {
    return {
      ...state,
      diagnosticTypes: action.types,
      loading: false,
    };
  }),
  //PARTICIPANTS
  // on(UtilitiesActions.loadParticipantUtilities, (state, action) => {
  //   return {
  //     ...state,
  //     loading: true,
  //   };
  // }),
  // on(UtilitiesActions.loadParticipantUtilitiesSuccess, (state, action) => {
  //   return {
  //     ...state,
  //     participant: action.utilities,
  //     loading: false,
  //   };
  // }),
  // on(UtilitiesActions.loadParticipantUtilitiesFailure, (state, action) => {
  //   return {
  //     ...state,
  //     error: action.error,
  //     loading: false,
  //   };
  // }),

  //identity
  // on(UtilitiesActions.loadParticipantUtilitiesIdentity, (state, action) => {
  //   return {
  //     ...state,
  //     loading: true,
  //   };
  // }),
  // on(
  //   UtilitiesActions.loadParticipantUtilitiesIdentitySuccess,
  //   (state, action) => {
  //     return {
  //       ...state,
  //       participantIdentity: action.utilities,
  //       loading: false,
  //     };
  //   }
  // ),
  // on(
  //   UtilitiesActions.loadParticipantUtilitiesIdentityFailure,
  //   (state, action) => {
  //     return {
  //       ...state,
  //       error: action.error,
  //       loading: false,
  //     };
  //   }
  // ),
  //situation
  on(UtilitiesActions.loadParticipantUtilitiesSituation, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(
    UtilitiesActions.loadParticipantUtilitiesSituationSuccess,
    (state, action) => {
      return {
        ...state,
        participantSituation: action.utilities,
        loading: false,
      };
    }
  ),
  on(
    UtilitiesActions.loadParticipantUtilitiesSituationFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
  ),

  on(UtilitiesActions.loadNumeriqueReferentielSuccess, (state, action) => {
    return {
      ...state,
      numerique: action.utilities,
      loading: false,
    };
  }),

  //SOFTSKILLS
  on(UtilitiesActions.loadSoftskillsReferentielSuccess, (state, action) => {
    return {
      ...state,
      softskills: action.utilities,
      loading: false,
    };
  }),

  //ENTITES
  on(UtilitiesActions.loadEntiteUtilities, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(UtilitiesActions.loadEntiteUtilitiesSuccess, (state, action) => {
    return {
      ...state,
      entite: action.utilities,
      loading: false,
    };
  }),
  on(UtilitiesActions.loadEntiteUtilitiesFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
      loading: false,
    };
  }),
  //CONTACTS
  on(UtilitiesActions.loadContactUtilities, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(UtilitiesActions.loadContactUtilitiesSuccess, (state, action) => {
    return {
      ...state,
      contact: action.utilities,
      loading: false,
    };
  }),
  on(UtilitiesActions.loadContactUtilitiesFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
      loading: false,
    };
  }),
  //OPERATIONS
  on(UtilitiesActions.loadContactUtilities, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(UtilitiesActions.loadOperationUtilitiesSuccess, (state, action) => {
    return {
      ...state,
      operation: action.utilities,
      loading: false,
    };
  }),
  on(UtilitiesActions.loadOperationUtilitiesFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
      loading: false,
    };
  }),

  //MISSIONS
  //MISSIONS CONTRATS UTILITIES
  on(UtilitiesActions.loadMissionUtilities, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(UtilitiesActions.loadMissionUtilitiesSuccess, (state, action) => {
    return {
      ...state,
      missionUtilities: action.utilities,
      loading: false,
    };
  }),
  on(UtilitiesActions.loadMissionUtilitiesFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
      loading: false,
    };
  }),
  //MISSIONS DEPLACEMENT UTILITIES
  on(UtilitiesActions.loadMissionDeplacementUtilities, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(
    UtilitiesActions.loadMissionDeplacementUtilitiesSuccess,
    (state, action) => {
      return {
        ...state,
        missionDeplacementUtilities: action.utilities,
        loading: false,
      };
    }
  ),
  on(
    UtilitiesActions.loadMissionDeplacementUtilitiesFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
  ),

  //OPERATION MARCHÉS TYPE
  on(UtilitiesActions.loadOperationMarcheTypes, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(UtilitiesActions.loadOperationMarcheTypesSuccess, (state, action) => {
    return {
      ...state,
      marchesTypes: action.types,
      loading: false,
    };
  }),
  on(UtilitiesActions.loadOperationMarcheTypesFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
      loading: false,
    };
  }),

  //OPERATION OBJECTIFS TYPE
  on(UtilitiesActions.loadOperationObjectifTypes, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(UtilitiesActions.loadOperationObjectifTypesSuccess, (state, action) => {
    return {
      ...state,
      objectifTypes: action.objectifs,
      loading: false,
    };
  }),
  on(UtilitiesActions.loadOperationObjectifTypesFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
      loading: false,
    };
  }),

  //PLANNING TYPES
  on(UtilitiesActions.loadPlanningfTypesSuccess, (state, action) => {
    return {
      ...state,
      planningTypes: action.types,
      loading: false,
    };
  }),
  on(UtilitiesActions.loadPlanningfTypesFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
      loading: false,
    };
  }),

  //CLEAR
  on(UtilitiesActions.clearUtilitiess, (state) => adapter.removeAll(state))
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
