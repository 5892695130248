import {
  Component,
  OnDestroy,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';
import { Store } from '@ngrx/store';
import { Observable, of, Subject } from 'rxjs';
import { messageState } from './../../store/message.reducer';

import * as fromMessageAction from './../../store/message.actions';
import { Timestamp } from '@angular/fire/firestore';
import { MessageUser } from './../../store/message.model';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-message-form',
  templateUrl: './message-form.component.html',
  styleUrls: ['./message-form.component.scss'],
})
export class MessageFormComponent implements OnInit, OnDestroy {
  subscribe = new Subject();
  user$: Observable<any> = of(null);
  users$: Observable<any[]> = of([]);

  today = new Date(Date.now());
  usersReceivers: any[] = [];
  messageText: string = '';

  messageForm: UntypedFormGroup = this.fb.group({});
  auteur: UntypedFormControl = new UntypedFormControl('');
  subtitle: UntypedFormControl = new UntypedFormControl('');
  body: UntypedFormControl = new UntypedFormControl('');
  interlocuteurs: UntypedFormControl = new UntypedFormControl('');
  ids: UntypedFormControl = new UntypedFormControl('');
  icon: UntypedFormControl = new UntypedFormControl('');
  dateStart: UntypedFormControl = new UntypedFormControl('');
  dateUpdate: UntypedFormControl = new UntypedFormControl('');
  dateSend: UntypedFormControl = new UntypedFormControl('');
  messages: UntypedFormControl = new UntypedFormControl('');
  channelId: UntypedFormControl = new UntypedFormControl('');
  userUpdate: UntypedFormControl = new UntypedFormControl('');
  lastMessage: UntypedFormControl = new UntypedFormControl('');

  @Output() cancel = new EventEmitter<Boolean>();
  @Input() close: boolean = false;
  @Input() open: boolean = false;
  @Input() isModal: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private userStore: Store<UserState>,
    private storeMessage: Store<messageState>
  ) {}

  ngOnInit(): void {
    this.get__USER();

    this.formInit();
    this.formCreate();
    this.isClose();
  }

  get__USER(): void {
    this.user$ = this.userStore.select(fromUserSelector.user);
  }
  formInit(): void {
    this.channelId = new UntypedFormControl();
    this.userUpdate = new UntypedFormControl();
    this.auteur = new UntypedFormControl();
    this.subtitle = new UntypedFormControl('', Validators.required);
    this.body = new UntypedFormControl('', Validators.required);
    this.interlocuteurs = new UntypedFormControl([], Validators.required);
    this.ids = new UntypedFormControl([], Validators.required);
    this.icon = new UntypedFormControl('');
    this.dateStart = new UntypedFormControl(
      Timestamp.fromDate(this.today),
      Validators.required
    );
    this.dateUpdate = new UntypedFormControl(
      Timestamp.fromDate(this.today),
      Validators.required
    );
    this.dateSend = new UntypedFormControl(
      Timestamp.fromDate(this.today),
      Validators.required
    );
    this.messages = new UntypedFormControl([]);
    this.lastMessage = new UntypedFormControl();
  }

  formCreate(): void {
    this.messageForm = this.fb.group({
      auteur: this.auteur,
      channelId: this.channelId,
      userUpdate: this.userUpdate,
      subtitle: this.subtitle,
      interlocuteurs: this.interlocuteurs,
      ids: this.ids,
      icon: this.icon,
      dateStart: this.dateStart,
      dateUpdate: this.dateUpdate,
      dateSend: this.dateSend,
      messages: this.messages,
      lastMessage: this.lastMessage,
    });
  }

  addUser_MESSAGE(users: any[]): any {
    if (!users.length) {
      return null;
    }

    const userItem: MessageUser[] = users.map((user) => {
      return this.convertUser_MESSAGE(user);
    });

    const ids = userItem.map((user) => user.id);

    this.messageForm.patchValue({
      interlocuteurs: userItem,
      ids: ids,
    });
  }

  addText_MESSAGE(text: string, user: any): any {
    if (!text || !user) {
      return null;
    }

    const newMessage = {
      id: uuidv4(),
      text: text,
      auteur: this.convertUser_MESSAGE(user),
      date: Timestamp.now(),
    };

    this.messageForm.patchValue({
      messages: [newMessage],
      lastMessage: newMessage,
    });
  }

  convertUser_MESSAGE(user: any): MessageUser | any {
    if (!user) {
      return null;
    }
    const item: MessageUser = {
      id: user.id,
      avatar: user.avatar,
      displayName: user.displayName,
      fonction: user.fonction,
      service: user?.service ? user.service : null,
      setting: user?.setting ? user.setting : null,
    };

    return item;
  }

  onAdd(): any {
    if (this.messageForm.invalid) {
      return null;
    }

    const message = this.messageForm.value;
    this.storeMessage.dispatch(fromMessageAction.addMessage({ message }));
    this.onCancel();
  }

  onCancel(): void {
    this.messageForm.reset();
    this.cancel.emit(true);
    this.usersReceivers = [];
    this.messageText = '';
    this.formInit();
    this.formCreate();
  }

  isClose(): void {}

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }
}
