import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, tap, mergeMap, catchError, exhaustMap } from 'rxjs/operators';
import { NzMessageService } from 'ng-zorro-antd/message';

import { TicketsService } from '../../../service/tickets.service';
import * as fromTicketActions from './ticket.actions';
import { Store, select } from '@ngrx/store';
import { TicketState } from './ticket.reducer';

@Injectable()
export class TicketEffects {
  load_Tickets$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromTicketActions.loadTickets),
      tap(() => {
        this.ticketStore.dispatch(
          fromTicketActions.loadingTickets({ loader: true })
        );
      }),
      mergeMap((action) =>
        this.ticketsService.get__TICKETS().pipe(
          map((tickets) => fromTicketActions.loadTicketsSuccess({ tickets })),
          catchError((error) =>
            of(fromTicketActions.loadTicketsFailure({ error }))
          )
        )
      ),
      tap(() => {
        this.ticketStore.dispatch(
          fromTicketActions.loadingTickets({ loader: false })
        );
      })
    );
  });

  add_Ticket$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromTicketActions.addTicket),
      tap(() => {}),
      exhaustMap((action) =>
        this.ticketsService.add__TICKET(action.ticket).pipe(
          map((ticket) => fromTicketActions.addTicketSuccess({ ticket })),
          catchError((error) =>
            of(fromTicketActions.addTicketFailure({ error }))
          )
        )
      ),
      tap(() => {})
    );
  });

  update_Ticket$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromTicketActions.updateTicket),
      tap(() => {}),
      exhaustMap((action) =>
        this.ticketsService
          .update__TICKET(action.ticket.id, action.ticket.changes)
          .pipe(
            map((ticket) => fromTicketActions.updateTicketSuccess({ ticket })),
            catchError((error) =>
              of(fromTicketActions.updateTicketFailure({ error }))
            )
          )
      ),
      tap(() => {})
    );
  });

  delete_Ticket$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromTicketActions.deleteTicket),
      tap(() => {}),
      exhaustMap((action) =>
        this.ticketsService.delete__TICKET(action.id).pipe(
          map(() => fromTicketActions.deleteTicketSuccess()),
          catchError((error) =>
            of(fromTicketActions.deleteTicketFailure({ error }))
          )
        )
      ),
      tap(() => {})
    );
  });

  constructor(
    private actions$: Actions,
    private ticketsService: TicketsService,
    private message: NzMessageService,
    private ticketStore: Store<TicketState>
  ) {}
}
