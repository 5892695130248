import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderTitleComponent } from './components/header-title.component';
import { ItemHeaderComponent } from './components/item-header.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [HeaderTitleComponent, ItemHeaderComponent],
  exports: [HeaderTitleComponent, ItemHeaderComponent],
  imports: [CommonModule, SharedModule],
})
export class HeaderModule {}
