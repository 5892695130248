<div class="calendar_container">
  <nz-calendar
    *ngIf="participant$ | async as participant"
    [(ngModel)]="selectedValue"
    (nzSelectChange)="selectChange($event, participant)"
    (nzPanelChange)="panelChange($event, participant)"
    [nzFullscreen]="true"
    [nzMode]="calendarMode"
  >
    <ul
      *nzDateCell="let date"
      class="events"
      (click)="$event.stopPropagation(); selectChange(date, participant)"
      nz-popover
      nzPopoverTrigger="click"
      [nzPopoverContent]="planningCard"
      nzPopoverPlacement="top"
    >
      <li
        *ngFor="let item of events$ | async; let i = index; trackBy: trackByFn"
      >
        <ng-container *ngIf="item.createAt === (date | dateToString)">
          <nz-badge
            [nzColor]="item.type.color"
            [nzText]="item.type.label | carLimitation : 30"
            [nzTitle]="item.type.label"
          >
          </nz-badge>
        </ng-container>
      </li>
      <ng-template #planningCard>
        <div class="planningCardList" *ngIf="selectedValue | dateToString">
          <ul *ngFor="let item of events$ | async; trackBy: trackByFn">
            <li>
              <app-participant-planning-card
                *ngIf="item.createAt === (selectedValue | dateToString)"
                [item]="item"
                (delete)="onDelete($event, participant)"
              ></app-participant-planning-card>
            </li>
          </ul>
        </div>
        <ng-template #currentTitle>
          <app-header-title
            [icon]="'event'"
            [title]="selectedValue | dateToString"
            [subtitle]="true"
            [isIcon]="true"
          ></app-header-title>
        </ng-template>
      </ng-template>
    </ul>
  </nz-calendar>
</div>
