import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-metiers-card',
  templateUrl: './metiers-card.component.html',
  styleUrls: ['./metiers-card.component.scss']
})
export class MetiersCardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
