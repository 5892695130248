import { OperationNoteSubtitleId } from 'src/app/components/operation-note-subtitle/store/operation-note-subtitle.model';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-operation-note-subtitle-item',
  templateUrl: './operation-note-subtitle-item.component.html',
  styleUrls: ['./operation-note-subtitle-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationNoteSubtitleItemComponent implements OnInit, OnChanges {
  @Input() subtitle: OperationNoteSubtitleId | any = null;
  @Output() update: EventEmitter<OperationNoteSubtitleId | any> =
    new EventEmitter(false);
  @Output() delete: EventEmitter<OperationNoteSubtitleId | any> =
    new EventEmitter(false);

  constructor() {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {}

  onUpdate(subtitle: OperationNoteSubtitleId): void {
    if (!subtitle) return;
    this.update.next(subtitle);
  }
  onDelete(subtitle: OperationNoteSubtitleId): void {
    if (!subtitle) return;

    this.delete.next(subtitle.id);
  }

  onSelectDropdown(event: string, subtitle: OperationNoteSubtitleId): void {
    if (!event || !subtitle) return;

    switch (event) {
      case 'update':
        this.update.emit(subtitle);
        break;
      case 'delete':
        this.onDelete(subtitle);
        break;

      default:
        break;
    }
  }
}
