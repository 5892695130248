import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-operation-dashboard-header-card',
  template: `
    <nz-card
      [class]="type === 'primary' ? 'bg-blue-950 ' : 'bg-blue-100 '"
      *ngIf="!isKpi"
      nzHoverable
    >
      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col nzSpan="24">
          <nz-statistic
            [nzValue]="total > 0 ? (total | number)! : 0"
            [nzTitle]="iconPrefix"
            [nzValueStyle]="{ color: colorSubject }"
          ></nz-statistic>
        </nz-col>
      </nz-row>
      <ng-template #iconPrefix>
        <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
          <nz-col nzSpan="8">
            <mat-icon *ngIf="icon" [ngStyle]="{ color: colorSubject }">{{
              icon
            }}</mat-icon>
          </nz-col>
          <nz-col nzSpan="16">
            <span [ngStyle]="{ color: colorSubject }">{{ title }}</span>
          </nz-col>
        </nz-row>
      </ng-template>
    </nz-card>

    <nz-card
      [class]="type === 'primary' ? 'bg-blue-950 ' : 'bg-blue-100 '"
      *ngIf="isKpi"
      nzHoverable
    >
      <nz-row nzAlign="top" nzGutter="8" nzJustify="start">
        <nz-col nzSpan="12">
          <nz-statistic
            [nzValue]="
              kpiPourcent > 0
                ? (kpiPourcent | percent) + ''
                : (0 | percent) + ''
            "
            [nzTitle]="iconPrefix"
            [nzValueStyle]="{
              color: color === '#fff' ? '#000' : '#fff',
              fontSize: '40px',
              fontWeight: 'bold'
            }"
          ></nz-statistic>
        </nz-col>
        <nz-col nzSpan="1">
          <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
        </nz-col>
        <nz-col nzSpan="5">
          <nz-statistic
            [nzValue]="(kpiReal | number : '1.1-2' : 'fr') + ''"
            [nzTitle]="realisationsPrefix"
            [nzValueStyle]="{
              color: color === '#fff' ? '#000' : '#fff',
              fontSize: '20px',
              fontWeight: 'bold'
            }"
          ></nz-statistic>
        </nz-col>
        <nz-col nzSpan="1">
          <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
        </nz-col>
        <nz-col nzSpan="5">
          <nz-statistic
            [nzValue]="(kpiObjectif | number : '1.1-2' : 'fr') + ''"
            [nzTitle]="objectifsPrefix"
            [nzValueStyle]="{
              color: color === '#fff' ? '#000' : '#fff',
              fontSize: '20px',
              fontWeight: 'bold'
            }"
          ></nz-statistic>
        </nz-col>

        <!-- <nz-col nzSpan="6">
          <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
            <nz-col nzSpan="8"
              ><nz-tag nzColor="processing" *ngIf="modulesSize > 0"
                >Modules : {{ modulesSize }}</nz-tag
              ></nz-col
            >
            <nz-col nzSpan="4">
              <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
            </nz-col>
            <nz-col nzSpan="12"
              ><nz-tag nzColor="processing" *ngIf="actionsSize > 0"
                >Actions : {{ actionsSize }}</nz-tag
              ></nz-col
            >
          </nz-row>
        </nz-col> -->
      </nz-row>
      <ng-template #kpiTitle>
        <nz-row nzAlign="middle" nzJustify="start">
          <nz-col nzSpan="6"> </nz-col>
          <nz-col nzSpan="6">
            <small>{{ dateStart }}</small>
          </nz-col>
          <nz-col nzSpan="6">
            <small>{{ dateEnd }}</small>
          </nz-col>
          <nz-col nzSpan="6">
            <small>{{ dateUpdate }}</small>
          </nz-col>
        </nz-row>
      </ng-template>
      <ng-template #iconPrefix>
        <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
          <nz-col nzSpan="8">
            <mat-icon
              *ngIf="icon"
              [ngStyle]="{ color: color === '#fff' ? '#000' : '#fff' }"
              >{{ icon }}</mat-icon
            >
          </nz-col>
        </nz-row>
      </ng-template>
      <ng-template #realisationsPrefix>
        <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
          <nz-col nzSpan="24"><p>Réalisations</p></nz-col>
        </nz-row>
      </ng-template>
      <ng-template #objectifsPrefix>
        <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
          <nz-col nzSpan="24"><p>Objectifs</p></nz-col>
        </nz-row>
      </ng-template>
    </nz-card>
  `,
  styles: [
    `
      nz-card {
        border-radius: 12px;
        height: 120px;
      }

      small {
        color: #fff;
      }

      nz-tag {
        border: none;
        border-radius: 12px;
      }

      nz-divider {
        color: white;
        width: 20px !important;
      }
      p {
        color: white;
      }
      i,
      mat-icon {
        color: white;
      }

      .content {
        display: flex;
        flex-direction: column;
      }
    `,
  ],
})
export class OperationDashboardHeaderCardComponent implements OnInit {
  @Input() title: string = '';
  @Input() total: number | any = 0;
  @Input() color: string = '#fff';
  @Input() colorSubject: string = '#062949';
  @Input() type: string = '';

  @Input() objectifs: number = 0;
  @Input() realisations: number = 0;
  @Input() modulesSize: number = 0;
  @Input() actionsSize: number = 0;
  @Input() operation: number = 0;

  @Input() icon: string = '';
  @Input() isKpi: boolean = false;
  @Input() titleKpi: string = 'Progression';
  @Input() kpiPourcent: number = 0;
  @Input() kpiReal: number = 0;
  @Input() kpiRealTitle: string = 'Réalisations';
  @Input() kpiObjectif: number = 0;
  @Input() kpiObjectifTitle: string = 'Objectifs';
  @Input() kpiObjectifTheoriqueTitle: string = 'Théoriques';
  @Input() kpiObjectifTheorique: number = 0;

  @Input() dateStart: string = '';
  @Input() dateEnd: string = '';
  @Input() dateUpdate: string = '';

  constructor() {}

  ngOnInit(): void {}
}
