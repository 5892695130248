import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, Subject, takeUntil } from 'rxjs';
import { EntiteId } from 'src/app/components/entite/store/entite.model';
import { EntiteState } from 'src/app/components/entite/store/entite.reducer';
import * as fromEntiteSelector from 'src/app/components/entite/store/entite.selectors';
import { EntiteTaskState } from '../../store/entite-task.reducer';
import * as fromEntiteTaskSelector from '../../store/entite-task.selectors';
import * as fromEntiteTaskAction from '../../store/entite-task.actions';
import { Update } from '@ngrx/entity';

@Component({
  selector: 'app-entite-task',
  templateUrl: './entite-task.component.html',
  styleUrls: ['./entite-task.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntiteTaskComponent implements OnInit, OnChanges {
  subscribe = new Subject();
  entite$: Observable<EntiteId | any> = of(null);
  tasksAll$: Observable<any> = of([]);
  tasks$: Observable<any> = of([]);
  verifed$: Observable<any> = of([]);
  tasksInProgress$: Observable<any> = of([]);
  dones$: Observable<any> = of([]);

  loading$: Observable<boolean> = of(false);
  isFiltered$: Observable<boolean> = of(false);

  @Input() user$: Observable<any> = of(null);

  constructor(
    private entiteStore: Store<EntiteState>,
    private entiteTaskStore: Store<EntiteTaskState>
  ) {}

  ngOnInit(): void {
    this.getLoading();
    this.getIsFilterd();
    this.getEntite();
    this.getEntiteTasks();
    this.loadEntiteTasks();
    this.getEntiteTasksLoading();
    this.getTasksCurrent();
    this.getTasksDoned();
    this.getTasksInPorgress();
    this.getTasksVerifed();
    this.getTasksVerifed();
  }
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getLoading(): void {
    this.loading$ = this.entiteTaskStore.select(fromEntiteTaskSelector.loading);
  }
  getIsFilterd(): void {
    this.isFiltered$ = this.entiteTaskStore.select(
      fromEntiteTaskSelector.isFiltered
    );
  }

  getEntite(): void {
    this.entite$ = this.entiteStore.select(fromEntiteSelector.entite);
  }

  getEntiteTasks(): void {
    this.tasksAll$ = this.entiteStore.select(fromEntiteTaskSelector.tasks);
  }

  getEntiteTasksLoading(): void {
    this.loading$ = this.entiteTaskStore.select(fromEntiteTaskSelector.loading);
  }

  loadEntiteTasks(): void {
    this.entite$.pipe(takeUntil(this.subscribe)).subscribe((entite) => {
      if (!entite) return;
      const { id } = entite;

      this.entiteStore.dispatch(fromEntiteTaskAction.loadEntiteTasks({ id }));
    });
  }

  getTasksCurrent(): void {
    this.tasks$ = this.entiteTaskStore.select(
      fromEntiteTaskSelector.tasksCurrents
    );
  }
  getTasksDoned(): void {
    this.dones$ = this.entiteTaskStore.select(
      fromEntiteTaskSelector.tasksDoned
    );
  }
  getTasksInPorgress(): void {
    this.tasksInProgress$ = this.entiteTaskStore.select(
      fromEntiteTaskSelector.tasksInProgress
    );
  }
  getTasksVerifed(): void {
    this.verifed$ = this.entiteTaskStore.select(
      fromEntiteTaskSelector.tasksVerifed
    );
  }

  onAction(event: string, entite: EntiteId): void {
    if (!event || !entite) return;

    switch (event) {
      case 'Tout':
        this.loadTasks(entite);
        break;
      case 'Mes tâches':
        this.loadMyTasks(entite);

        break;
      case "Aujourd'hui":
        this.loadDailyTasks(entite);

        break;
      case 'Cette semaine':
        this.loadWeeklyTasks(entite);

        break;
      case 'Ce mois':
        this.loadMonthlyTasks(entite);

        break;

      default:
        break;
    }
  }

  loadTasks(entite: EntiteId): void {
    const { id } = entite;
    this.entiteStore.dispatch(fromEntiteTaskAction.loadEntiteTasks({ id }));
  }
  loadMyTasks(entite: EntiteId): void {
    const { id } = entite;
    this.entiteStore.dispatch(fromEntiteTaskAction.loadEntiteMyTasks({ id }));
  }
  loadDailyTasks(entite: EntiteId): void {
    const { id } = entite;
    this.entiteStore.dispatch(
      fromEntiteTaskAction.loadEntiteDailyTasks({ id })
    );
  }
  loadWeeklyTasks(entite: EntiteId): void {
    const { id } = entite;
    this.entiteStore.dispatch(
      fromEntiteTaskAction.loadEntiteWeeklyTasks({ id })
    );
  }
  loadMonthlyTasks(entite: EntiteId): void {
    const { id } = entite;
    this.entiteStore.dispatch(
      fromEntiteTaskAction.loadEntiteMonthlyTasks({ id })
    );
  }

  onAdd(task: any, entite: EntiteId): void {
    if (!task || !entite) return;

    const entiteId: string = entite.id;
    this.entiteStore.dispatch(
      fromEntiteTaskAction.addEntiteTask({ task, entiteId })
    );
  }
  onUpdate(event: any, entite: EntiteId): void {
    if (!event || !entite) return;
    const task: Update<any> = event.task;
    const type: string = event.type;

    this.entiteStore.dispatch(
      fromEntiteTaskAction.updateEntiteTask({ task, updateType: type })
    );
  }

  onDelete(task: any, entite: EntiteId): void {
    if (!task || !entite) return;
    this.entiteStore.dispatch(
      fromEntiteTaskAction.deleteEntiteTask({
        entiteId: entite.id,
        id: task.id,
      })
    );
  }

  onDeleteAll(entite: EntiteId): void {
    if (!entite) return;
    this.entiteStore.dispatch(
      fromEntiteTaskAction.deleteEntiteTasks({ ids: [], entiteId: entite.id })
    );
  }
}
