import { Apollo, gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'any',
})
export class EntiteMetiersSuggestionsService {
  GET_NAF_METIERS_SUGGESTIONS = gql`
    query EntrepriseMetiersSuggestions($naf: String!) {
      entrepriseMetiersSuggestions(naf: $naf) {
        total
        correspondances {
          id
          code_naf
          naf
          label_naf
          code_rome
          label_rome
        }
      }
    }
  `;

  GET_DOMAINE_METIERS_SUGGESTIONS = gql`
    query MetiersByDomaine($code: String!) {
      metiersByDomaine(code: $code) {
        code
        libelle
        riasecMajeur
        riasecMineur
        id
      }
    }
  `;

  GET_METIER_APPELATIONS = gql`
    query Appelations($code: String!) {
      appelations(code: $code) {
        code
        libelle
        libelleCourt
        particulier
      }
    }
  `;

  GET_METIERS_DETAILS = gql`
    query MetiersPoleEmploi($input: String!) {
      metiersPoleEmploi(input: $input) {
        code
        libelle
        riasecMajeur
        riasecMineur
        codeIsco
        particulier
        domaineProfessionnel {
          code
          libelle
          grandDomaine {
            code
            libelle
          }
        }
      }
    }
  `;

  constructor(private apollo: Apollo) {}

  getNafMetiersSuggestions(naf: string): Observable<any> {
    try {
      return this.apollo
        .watchQuery<any>({
          query: this.GET_NAF_METIERS_SUGGESTIONS,
          variables: {
            naf: naf,
          },
        })
        .valueChanges.pipe(
          map(({ data, loading, error }) => {
            return { data, loading, error };
          })
        );
    } catch (error) {
      return of(error);
    }
  }

  getDomaineMetiersSuggestions(code: string): Observable<any> {
    try {
      return this.apollo
        .watchQuery<any>({
          query: this.GET_DOMAINE_METIERS_SUGGESTIONS,
          variables: {
            code: code,
          },
        })
        .valueChanges.pipe(
          map(({ data, loading, error }) => {
            return { data, loading, error };
          })
        );
    } catch (error) {
      return of(error);
    }
  }

  getMetierAppelations(code: string): Observable<any> {
    try {
      return this.apollo
        .watchQuery<any>({
          query: this.GET_METIER_APPELATIONS,
          variables: {
            code: code,
          },
        })
        .valueChanges.pipe(
          map(({ data, loading, error }) => {
            return { data, loading, error };
          })
        );
    } catch (error) {
      return of(error);
    }
  }

  getMetierDetails(input: string): Observable<any> {
    try {
      return this.apollo
        .watchQuery<any>({
          query: this.GET_METIERS_DETAILS,
          variables: {
            input: input,
          },
        })
        .valueChanges.pipe(
          map(({ data, loading, error }) => {
            return { data, loading, error };
          })
        );
    } catch (error) {
      return of(error);
    }
  }
}
