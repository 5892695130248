import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-item-identity',
  template: `
    <app-card-identity>
      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col nzSpan="4">
          <app-header-title [icon]="'email'"></app-header-title>
        </nz-col>
        <nz-col nzSpan="20">
          <nz-tag class="tagCoordonnes">
            {{ email | carLimitation: 25 }}
          </nz-tag>
        </nz-col>
      </nz-row>
      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col nzSpan="4">
          <app-header-title [icon]="'smartphone'"></app-header-title>
        </nz-col>
        <nz-col nzSpan="20">
          <nz-tag class="tagCoordonnes">
            {{ mobile | phone }}
          </nz-tag>
        </nz-col>
      </nz-row>

      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col nzSpan="4">
          <app-header-title [icon]="'phone'"></app-header-title>
        </nz-col>
        <nz-col nzSpan="20">
          <nz-tag class="tagCoordonnes">
            {{ fixe | phone }}
          </nz-tag>
        </nz-col>
      </nz-row>
      <nz-divider class="identityDivider"></nz-divider>
      <nz-row nzAlign="middle" nzGutter="0" nzJustify="center">
        <nz-col [nzSpan]="12">
          <app-avatar-group
            *ngIf="isReferent"
            [size]="35"
            [limit]="4"
            [source]="referents"
          ></app-avatar-group>
        </nz-col>
        <nz-col [nzSpan]="4"></nz-col>
        <nz-col [nzSpan]="4">
          <!-- <button
            nz-button
            nzShape="circle"
            nzType="text"
            (click)="$event.stopPropagation(); onUpdate(item)"
            nz-tooltip
            nzTooltipTitle="Modifier"
            [nzTooltipMouseEnterDelay]="0.5"
            nzTooltipTrigger="hover"
            nzTooltipPlacement="bottomRight"
          >
            <mat-icon>edit</mat-icon>
          </button> -->
        </nz-col>
        <nz-col [nzSpan]="4">
          <app-button-dropdown
            [deleteType]="'Supprimer ce participant ?'"
            [isExportPDF]="true"
            [isUpdate]="true"
            [isDelete]="true"
            (select)="onDropdownSelect($event)"
          ></app-button-dropdown>
          <!-- <app-buttons-profil-actions
            [item]="item"
            [name]="item"
            [isUpdate]="true"
            (export)="onExport($event)"
            (update)="onUpdate(item)"
            (delete)="onDelete($event)"
          ></app-buttons-profil-actions> -->
        </nz-col>
      </nz-row>

      <!-- <nz-row
        nzAlign="middle"
        nzGutter="8"
        nzJustify="center"
        *ngIf="isButtons"
      >
        <nz-col nzSpan="4"></nz-col>
        <nz-col nzSpan="4"> </nz-col>
        <nz-col nzSpan="4">
          <button
            nz-button
            nzShape="circle"
            nz-tooltip
            [nzTooltipMouseEnterDelay]="0.5"
            nzTooltipTitle="Modifier"
            (click)="onSelect('update')"
          >
            <i nz-icon nzType="form"></i>
          </button>
        </nz-col>
        <nz-col nzSpan="4">
          <button
            nz-button
            nzShape="circle"
            nz-tooltip
            [nzTooltipMouseEnterDelay]="0.5"
            nzTooltipTitle="Exporter"
            (click)="onSelect('export')"
          >
            <i nz-icon nzType="download"></i>
          </button>
        </nz-col>

        <nz-col nzSpan="4">
          <button
            nz-button
            nzShape="circle"
            nz-tooltip
            [nzTooltipMouseEnterDelay]="0.5"
            nzTooltipTitle="Supprimer"
            nz-popconfirm
            [nzPopconfirmTitle]="
              'Voulez-vous supprimer ' + (item | carLimitation: 25) + ' ?'
            "
            nzPopconfirmPlacement="bottom"
            (nzOnConfirm)="onSelect('delete')"
            (nzOnCancel)="onCancel()"
          >
            <i nz-icon nzType="delete"></i>
          </button>
        </nz-col>
        <nz-col nzSpan="4"></nz-col>
      </nz-row> -->
    </app-card-identity>
  `,
  styles: [
    `
      $colorGrey: #f5f5f5;

      :host {
        width: 100%;
        height: 100%;
        overflow: hidden;
        margin-top: 0px !important;
      }

      nz-row {
        margin: 4px !important;
      }
      .iconEvent {
        color: grey;
        font-size: 18px;
      }

      .container {
        height: 120px;
      }

      .identityDivider {
        margin-top: 20px !important;
        margin-bottom: 10px !important;
      }

      nz-tag {
        border: none;
        border-radius: 12px;
      }

      .tagCoordonnes {
        background-color: $colorGrey;
        width: 100% !important;
        border: 'none' !important;
        font-size: 13px;
      }

      button {
        border: none;
        background-color: $colorGrey;
      }

      .participant_dateUpdate {
        display: flex;
        flex-flow: row nowrap;
        justify-items: flex-start;
        justify-content: flex-end;
      }
    `,
  ],
})
export class ItemIdentityComponent implements OnInit {
  @Input() email: string = '';
  @Input() mobile: string = '';
  @Input() fixe: string = '';
  @Input() item: string = '';
  @Input() date: any = null;
  @Input() referents: any[] = [];
  @Input() isButtons: boolean = true;
  @Input() isDate: boolean = false;
  @Input() isReferent: boolean = false;
  @Input() dateUpdate: any = null;
  @Output() select = new EventEmitter<string>();
  @Output() update = new EventEmitter<any>();
  @Output() export = new EventEmitter<any>();
  @Output() delete = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  onDropdownSelect(item: string): void {
    if (!item) return;
    switch (item) {
      case 'update':
        this.onUpdate(item);
        break;
      case 'exportPDF':
        this.onExport('export');
        break;
      case 'delete':
        this.onDelete(item);
        break;

      default:
        break;
    }
  }

  onSelect(event: string): void {
    this.select.emit(event);
  }
  onUpdate(event: string): void {
    this.update.emit(event);
  }
  onExport(event: string): void {
    this.export.emit(event);
  }
  onDelete(event: string): void {
    this.delete.emit(event);
  }

  onCancel(): void {
    return;
  }
}
