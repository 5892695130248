import { Injectable } from '@angular/core';
import { Firestore, doc, getDoc } from '@angular/fire/firestore';
import { Observable, of, from, EMPTY } from 'rxjs';
import { switchMap, mergeMap, map, catchError } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { UserState } from '../components/user/store/user.reducer';
import * as fromUserSelector from '../components/user/store/user.selectors';
import {
  toUpper,
  upperFirst,
  capitalize,
  forEach,
  camelCase,
  isUndefined,
  sum,
  compact,
  groupBy,
  isNumber,
} from 'lodash';
import {
  fromUnixTime,
  format,
  formatDistance,
  formatDistanceToNow,
  formatDistanceStrict,
  differenceInYears,
  differenceInHours,
  sub,
  eachYearOfInterval,
} from 'date-fns';
import { fr } from 'date-fns/locale';

import pptxgen from 'pptxgenjs';
import { OperationState } from '../components/operation/store/operation.reducer';
import * as fromOperationAction from '../components/operation/store/operation.actions';
import * as fromOperationSelector from '../components/operation/store/operation.selectors';
import { OperationId } from '../components/operation/store/operation.model';
import { NzMessageService } from 'ng-zorro-antd/message';

@Injectable({
  providedIn: 'any',
})
export class OperationExportPptService {
  constructor(
    private db: Firestore,
    private userStore: Store<UserState>,
    private operationStore: Store<OperationState>,
    private message: NzMessageService
  ) {}

  convertDATA(items: any[]): any {
    const convertion =
      items && items?.length
        ? items.reduce((acc, cur) => {
            let curSize =
              items.filter((el) => el === cur) &&
              items.filter((el) => el === cur)?.length
                ? items.filter((el) => el === cur)?.length
                : [];

            acc[cur] = curSize;
            return acc;
          }, {})
        : {};

    return convertion;
  }

  createPPT_chartBar(
    ppt: any,
    logo: any,
    title: string,
    data: any,
    options: any,
    section?: string
  ): any {
    let slideTitle = `silde${title.replace(/\s/g, '')}`;
    let slide = section
      ? ppt.addSlide({ masterName: 'MASTER_SLIDE', sectionTitle: `${section}` })
      : ppt.addSlide({ masterName: 'MASTER_SLIDE' });

    slide.addImage({
      path: logo.toString(),
      x: 0.2,
      w: 1,
      h: 0.5,
    });
    slide.addText(`${title}`, {
      ...options,
    });

    let dataChartBar = [
      {
        name: ``,
        labels:
          data && data?.length ? Object.keys(this.convertDATA(data)) : null,
        values:
          data && data?.length ? Object.values(this.convertDATA(data)) : null,
      },
    ];

    slide.addChart(
      ppt.ChartType.bar,
      dataChartBar,

      {
        x: 0.2,
        y: 0.8,
        w: 9.5,
        h: 4,
        catAxisLabelFontSize: 8,
        valAxisLabelFontSize: 11,
        valAxisLineShow: false,
        showDataTable: true,
        showDataTableKeys: true,
        showLabel: true,
        showTitle: false,
        title: ``,
        titleFontSize: 11,
        barDir: 'bar',
        chartColors: ['003b75'],
      }
    );
  }

  createPPT_chartBarForCollaborateur(
    ppt: any,
    logo: any,
    title: string,
    data: any,
    options: any,
    section?: string
  ): any {
    let slideTitle = `silde${title.replace(/\s/g, '')}`;
    let slide = section
      ? ppt.addSlide({ masterName: 'MASTER_SLIDE', sectionTitle: `${section}` })
      : ppt.addSlide({ masterName: 'MASTER_SLIDE' });
    slide.addImage({
      path: logo.toString(),
      x: 0.2,
      w: 1,
      h: 0.5,
    });
    slide.addText(`${title}`, {
      ...options,
    });

    let dataChartBar = [
      {
        name: ``,
        labels: data.map((item: any) => item.entite),
        values: data.map((item: any) => item.sumHours),
      },
    ];

    slide.addChart(
      ppt.ChartType.bar,
      dataChartBar,

      {
        x: 0.2,
        y: 0.8,
        w: 9.5,
        h: 4,
        catAxisLabelFontSize: 8,
        valAxisLabelFontSize: 10,
        valAxisLineShow: false,
        showDataTable: true,
        showDataTableKeys: true,
        showLabel: true,
        showTitle: false,
        title: ``,
        titleFontSize: 11,
        barDir: 'bar',
        chartColors: ['003b75'],
      }
    );
  }

  createPPT_chartPie(
    ppt: any,
    logo: any,
    title: string,
    optionsTitle: any,
    data: any,
    options: any,
    section?: string
  ): any {
    let slide = section
      ? ppt.addSlide({ masterName: 'MASTER_SLIDE', sectionTitle: `${section}` })
      : ppt.addSlide({ masterName: 'MASTER_SLIDE' });

    slide.addText(`${title}`, {
      ...optionsTitle,
    });

    slide.addChart(
      ppt.ChartType.pie,
      data,

      {
        ...options,
      }
    );
  }

  createPPT_textShape(ppt: any, slide: any, number: number, option: any): any {
    slide.addText(`${number}`, {
      shape: ppt.ShapeType.ellipse,
      w: 0.3,
      h: 0.3,
      align: 'center',
      fill: 'ffab00',
      fontFace: 'Roboto',
      ...option,
    });
  }

  onExportPPT(operation: OperationId): Observable<any> {
    try {
      return this.userStore.pipe(
        select(fromUserSelector.user),
        switchMap((user) => {
          if (!user || !operation) {
            return `Erreur d'exportation. Veuillez renouveller l'action`;
          } else {
            const dbDocument = doc(
              this.db,
              `organisations/${user.organisation.id}/operations/${operation.id}/statistiques/${operation.id}`
            );

            const statistiquesDoc = getDoc(dbDocument).then((snap) =>
              snap.data()
            );

            return from(
              statistiquesDoc
                .then((statistiques: any) => {
                  if (!statistiques) return `Aucune statistique trouvée`;
                  const checkCrossOrigin = false;
                  const partData = statistiques as any;
                  const contacts = partData?.contacts ? partData?.contacts : [];
                  const entites = partData?.entites ? partData?.entites : [];

                  const operations = partData?.operations
                    ? partData?.operations
                    : null;
                  const modules = partData?.modules ? partData?.modules : [];
                  const actions = partData?.actions ? partData?.actions : [];
                  const participants = partData?.participants
                    ? partData?.participants
                    : [];
                  const planning = partData?.planning ? partData?.planning : [];
                  const planningMonthsCostEstimation =
                    partData?.planningMonthsCostEstimation
                      ? partData?.planningMonthsCostEstimation
                      : [];

                  const planningMonthsCostReel =
                    partData?.planningMonthsCostReel
                      ? partData?.planningMonthsCostReel
                      : [];

                  const planningMonthsHours = partData?.planningMonthsHours
                    ? partData?.planningMonthsHours
                    : [];

                  const equipe = partData?.equipe ? partData?.equipe : [];

                  const suivis = partData?.suivis ? partData?.suivis : [];
                  const contrats = partData?.contrats ? partData?.contrats : [];

                  const subtitleY: number = 0.7;

                  // if (!user?.organisation?.logo) {
                  //   const messageError: string = `Aucun logo n'est paramétré pour votre rapport. Veuillez vous rapprocher de votre administrateur.`;
                  //   this.spinner.hide();
                  //   this.nzMessage.warning(messageError, { nzDuration: 4000 });
                  //   return of(null);
                  // }
                  const xhr = new XMLHttpRequest();
                  xhr.open('GET', user?.organisation?.logo);
                  xhr.responseType = 'blob';
                  xhr.send();
                  xhr.addEventListener('load', () => {
                    const reader = new FileReader();
                    reader.readAsDataURL(xhr.response);
                    return reader.addEventListener('loadend', (): void => {
                      const logo = reader.result;
                      if (logo) {
                        const dateYear = format(
                          new Date(Date.now()),
                          'dd/MM/yyyy',
                          {
                            locale: fr,
                          }
                        );

                        let pres = new pptxgen();
                        const lenggoYellowColor = 'ffab00';
                        const lenggoBlueColor = '003b75';

                        pres.author = user.displayName;
                        pres.title = operation?.denomination;

                        const titleOption = {
                          x: 4,
                          y: 0.15,
                          bold: true,
                          color: '003b75',
                        };

                        const slideTitle = {
                          x: 4,
                          y: 0.2,
                          bold: true,
                          color: '003b75',
                        };

                        const subtitleOption = {};

                        const tableOption = {
                          x: 0.2,
                          y: 1.4,
                          w: 9.5,
                          rowH: 0.2,
                          fontFace: 'Roboto',
                          autoPage: true,
                        };

                        //SLIDE TEMPLATE
                        pres.defineSlideMaster({
                          title: 'MASTER_SLIDE',
                          margin: [0.5, 0.25, 1.0, 0.25],
                          objects: [
                            {
                              image: {
                                x: 0.2,
                                w: 1,
                                h: 0.5,
                                path: logo,
                              },
                            },
                            {
                              rect: {
                                x: 0,
                                y: 5.12,
                                w: '100%',
                                h: 0.5,
                                fill: { color: '003b75' },
                              },
                            },
                            {
                              text: {
                                text: `${user?.organisation?.nom_raison_sociale}`,
                                options: {
                                  x: 0,
                                  y: 5.2,
                                  w: '100%',
                                  align: 'center',
                                  color: 'FFFFFF',
                                  fontSize: 12,
                                },
                              },
                            },
                          ],
                          slideNumber: { x: 1.0, y: 5.2, color: 'FFFFFF' },
                        });

                        let slide = pres.addSlide();

                        //SLIDE DE GARDE
                        let logoPageDeGardeOpts = {
                          x: 1,
                          y: 1,
                          w: 2,
                          h: 1,
                          align: pres.AlignH.center,
                        };

                        //ORGANISATION LOGO
                        slide.addImage({
                          path: logo.toString(),
                          x: 4,
                          y: 1,
                          w: 2,
                          h: 1,
                        });

                        //DOCUMENT TITLE
                        slide.addText(
                          `BILAN ${operation.denomination.toUpperCase()}`,
                          {
                            x: 1.2,
                            y: 2.5,
                            fontFace: 'Roboto',
                            align: pres.AlignH.center,
                            breakLine: true,
                            bold: true,
                            fontSize: 25,
                            color: '003b75',
                          }
                        );

                        //TITLE PROJET
                        slide.addText(`${dateYear}`, {
                          x: '12%',
                          y: 3,
                          fontFace: 'Roboto',
                          align: pres.AlignH.center,
                          fontSize: 18,
                          color: '003b75',
                          italic: true,
                        });

                        let slideTitleOptions = {
                          x: '36%',
                          y: 0.15,
                          bold: true,
                          color: '003b75',
                          // underline: true,
                        };

                        //SECTION DESCRIPTION
                        pres.addSection({ title: `Description` });

                        //SLIDE  - PROJET
                        let slideProjet = pres.addSlide({
                          masterName: 'MASTER_SLIDE',
                          sectionTitle: 'Description',
                        });

                        slideProjet.addText(
                          `${operation.denomination.toUpperCase()}`,
                          {
                            ...slideTitle,
                          }
                        );

                        slideProjet.addText(`DESCRIPTION`, {
                          x: 0.2,
                          y: 0.8,
                          bold: true,
                          fontSize: 14,
                          fontFace: 'Roboto',
                          color: lenggoYellowColor,
                        });

                        slideProjet.addText(
                          `${
                            operation.description?.definition
                              ? operation.description?.definition
                              : 'Aucune'
                          }`,
                          {
                            x: 0.2,
                            y: 0.4,
                            w: '95%',
                            h: '45%',
                            fontSize: 11,
                            breakLine: true,
                          }
                        );

                        slideProjet.addText(`CALENDRIER`, {
                          x: 0.2,
                          y: 3,
                          w: '30%',
                          bold: true,
                          fontSize: 14,
                          fontFace: 'Roboto',
                          color: lenggoYellowColor,
                        });
                        const start =
                          operation.description.calendrier.dateStart['seconds'];
                        const end =
                          operation.description.calendrier.dateEnd['seconds'];

                        slideProjet.addText(
                          `Démarrage : ${format(
                            fromUnixTime(
                              operation.description.calendrier.dateStart[
                                'seconds'
                              ]
                            ),
                            'dd/MM/yyyy',
                            { locale: fr }
                          )}
                      `,
                          {
                            x: 0.6,
                            y: 3.4,
                            fontSize: 12,
                            bullet: true,
                          }
                        );
                        slideProjet.addText(
                          ` Échéance : ${format(
                            fromUnixTime(
                              operation.description.calendrier.dateEnd[
                                'seconds'
                              ]
                            ),
                            'dd/MM/yyyy',
                            { locale: fr }
                          )}
                      `,
                          {
                            x: 0.6,
                            y: 3.8,
                            fontSize: 12,
                            bullet: true,
                          }
                        );

                        let nbHoursToEnd =
                          operation?.description?.calendrier?.dateEnd &&
                          operation?.description?.calendrier?.dateStart
                            ? differenceInHours(
                                fromUnixTime(
                                  operation.description.calendrier.dateEnd[
                                    'seconds'
                                  ]
                                ),
                                fromUnixTime(
                                  operation.description.calendrier.dateStart[
                                    'seconds'
                                  ]
                                )
                              )
                            : 'Aucune';

                        let nbHoursToNow =
                          operation?.description?.calendrier?.dateEnd &&
                          operation?.description?.calendrier?.dateStart
                            ? differenceInHours(
                                fromUnixTime(
                                  operation.description.calendrier.dateEnd[
                                    'seconds'
                                  ]
                                ),
                                new Date(Date.now())
                              )
                            : 'Aucune';

                        // slideProjet.addText(`${nbHours} ${nbHours ? 'heures réalisées' : ''}`, {
                        //   x: 0.4,
                        //   y: 3.8,
                        //   fontSize: 10,
                        //   bold: true,
                        //   bullet: true,
                        // });

                        //SLIDE FINANCEURS

                        //SLIDE ÉQUIPE

                        //SECTION MODULES

                        //SECTION ENTREPRISES

                        //SECTION COLLABORATEURS

                        //SECTION PARTICIPANTS

                        //SAVE
                        let fileName: string = `BILAN ${
                          operation.denomination
                        } ${format(new Date(Date.now()), 'PP', {
                          locale: fr,
                        })}.pptx`;

                        pres
                          .writeFile({ fileName, compression: true })
                          .then(() => {
                            return `Votre bilan est téléchargé`;
                          });
                      } else {
                        return;
                      }
                    });
                  });
                  return `Votre bilan est téléchargé`;
                })
                .catch((error) => of(error?.message))
            );
          }
        })
      );
    } catch (err: any) {
      this.message.warning(err.message, { nzDuration: 6000 });
      return of(err.message);
    }
  }

  pptTable__financeurs(
    slide: any,
    title: string,
    headers: string[],
    source: any[],
    color: any,
    y: number
  ): any {
    const options = {
      y: 0.3,
      rowH: 0.2,
      align: 'left',
      fontSize: 7,
      fill: '003b75',
      color: 'FFFFFF',
      fontFace: 'Roboto',
    };

    slide.addText(`${title.toUpperCase()}`, {
      x: 10,
      y,
      w: '40%',
      bold: true,
      fontSize: 14,
      fontFace: 'Roboto',
      color: color,
    });

    source && source?.length
      ? slide.addText(source.map((el) => el.nom_raison_sociale).join(', '), {
          x: 3.5,
          y: 3.4,
          w: '30%',
          bold: true,
          fontSize: 9,
          fontFace: 'Roboto',
          breakLine: true,
        })
      : [];

    let header = headers.map((el) => {
      return {
        text: capitalize(el),
        options,
      };
    });

    let rows: any[] =
      source && source?.length
        ? source.map((el) => {
            const operationTable = [
              {
                text: capitalize(el.displayName),
                options: {
                  y: 0.4,
                  align: 'left',
                  fontSize: 7,
                  fontFace: 'Roboto',
                },
              },
              {
                text: el?.fonction ? el.fonction : 'Non renseignée',
                options: {
                  y: 0.4,
                  align: 'left',
                  fontSize: 7,
                  fontFace: 'Roboto',
                },
              },
            ];

            return operationTable;
          })
        : [];

    let lines = [...header, ...rows];

    slide.addTable(lines, {
      x: 6.2,
      y: 3.4,
      w: 2,
      rowH: 0.2,
      align: 'left',
      fontFace: 'Roboto',
      colW: [1.5, 2],
      autoPage: true,
    });
  }

  financeurs(
    pres: any,
    tableOption: any,
    slideTitle: any,
    financeurs: any
  ): any {
    let slideFinanceurs = pres.addSlide({
      masterName: 'MASTER_SLIDE',
      sectionTitle: 'Description',
    });
    slideFinanceurs.addText(`FINANCEURS`, {
      ...slideTitle,
    });

    let financeursRow: any[] =
      financeurs && financeurs?.length
        ? financeurs.map((el: any) => {
            const operationTable = [
              {
                text: capitalize(el.nom_raison_sociale),
                options: {
                  y: 0.4,
                  align: 'left',
                  fontSize: 7,
                  fontFace: 'Roboto',
                },
              },
              {
                text: '',
                options: {
                  y: 0.4,
                  align: 'left',
                  fontSize: 7,
                  fontFace: 'Roboto',
                },
              },
            ];

            return operationTable;
          })
        : [];

    let rowsFinanceurs = [
      [
        {
          text: 'Raison sociale',
          options: {
            y: 0.3,
            rowH: 0.2,
            align: 'left',
            fontSize: 7,
            fill: '003b75',
            color: 'FFFFFF',
            fontFace: 'Roboto',
          },
        },
        {
          text: '',
          options: {
            y: 0.3,
            rowH: 0.2,
            align: 'left',
            fontSize: 7,
            fill: '003b75',
            color: 'FFFFFF',
            fontFace: 'Roboto',
          },
        },
      ],
      ...financeursRow,
    ];

    slideFinanceurs.addTable(rowsFinanceurs, {
      ...tableOption,
      colW: [5.5, 4],
    });
  }

  team(equipe: any, pres: any, slideTitle: any, tableOption: any): any {
    let slideEquipe = pres.addSlide({
      masterName: 'MASTER_SLIDE',
      sectionTitle: 'Description',
    });

    slideEquipe.addText(`ÉQUIPE`, {
      ...slideTitle,
    });

    let equipeRow: any[] =
      equipe && equipe?.length
        ? equipe.map((el: any) => {
            const operationTable = [
              {
                text: capitalize(el.displayName),
                options: {
                  y: 0.4,
                  align: 'left',
                  fontSize: 7,
                  fontFace: 'Roboto',
                },
              },
              {
                text: el?.fonction ? el.fonction : 'Non renseignée',
                options: {
                  y: 0.4,
                  align: 'left',
                  fontSize: 7,
                  fontFace: 'Roboto',
                },
              },
              {
                text: el?.service?.title
                  ? el.service.title.toUpperCase()
                  : 'Non renseigné'
                  ? el.fonction
                  : 'Non renseignée',
                options: {
                  y: 0.4,
                  align: 'left',
                  fontSize: 7,
                  fontFace: 'Roboto',
                },
              },
            ];

            return operationTable;
          })
        : [];

    let rowsEquipe = [
      [
        {
          text: 'Identité',
          options: {
            y: 0.3,
            rowH: 0.2,
            align: 'left',
            fontSize: 7,
            fill: '003b75',
            color: 'FFFFFF',
            fontFace: 'Roboto',
          },
        },
        {
          text: 'Fonction',
          options: {
            y: 0.3,
            rowH: 0.2,
            align: 'left',
            fontSize: 7,
            fill: '003b75',
            color: 'FFFFFF',
            fontFace: 'Roboto',
          },
        },
        {
          text: 'Service',
          options: {
            y: 0.3,
            rowH: 0.2,
            align: 'left',
            fontSize: 7,
            fill: '003b75',
            color: 'FFFFFF',
            fontFace: 'Roboto',
          },
        },
      ],
      ...equipeRow,
    ];

    slideEquipe.addTable(rowsEquipe, {
      ...tableOption,
      align: 'left',
      colW: [3.5, 3, 3],
    });
  }

  participants(
    pres: any,
    slideTitle: any,
    participants: any,
    slideTitleOptions: any,
    logo: any,
    operation: any
  ): any {
    pres.addSection({ title: 'Participants' });
    //SLIDE  - PARTICIPANT
    let slideParticipantSexe = pres.addSlide({
      masterName: 'MASTER_SLIDE',
      sectionTitle: 'Participants',
    });

    slideParticipantSexe.addText('PARTICIPANTS', {
      ...slideTitle,
    });
    const participantsSexe =
      participants?.data && participants?.data?.length
        ? participants?.data?.map((el: any) => el['Sexe'])
        : [];

    const participantsRQTH =
      participants?.data && participants?.data?.length
        ? participants?.data?.map((el: any) => (el['RQTH'] ? 'Oui' : 'Non'))
        : [];

    let dataChartPieImplication = [
      {
        name: 'Réparticipant des participants par sexe',
        labels:
          participantsSexe && participantsSexe?.length
            ? Object.keys(this.convertDATA(participantsSexe))
            : null,
        values:
          participantsSexe && participantsSexe?.length
            ? Object.values(this.convertDATA(participantsSexe))
            : null,
      },
    ];

    let dataChartPieRQTH = [
      {
        name: 'Réparticipant des participants par RQTH',
        labels:
          participantsRQTH && participantsRQTH?.length
            ? Object.keys(this.convertDATA(participantsRQTH))
            : null,
        values:
          participantsRQTH && participantsRQTH?.length
            ? Object.values(this.convertDATA(participantsRQTH))
            : null,
      },
    ];

    slideParticipantSexe.addText(
      `${
        participants?.data && participants?.data?.length
          ? participants?.data?.length
          : 0
      }`,
      {
        x: 0.5,
        y: 0.5,
        w: 1.5,
        h: 0.3,
        align: 'center',
        fontFace: 'Roboto',
      }
    );

    slideParticipantSexe.addText(
      `
${
  participants?.data && participants?.data?.length
    ? `${
        participants?.data && participants?.data?.length > 1
          ? 'participants mobilisés'
          : 'participant mobilisé'
      }`
    : `Aucun participant mobilisé`
}`,
      {
        x: 1.2,
        y: 0.5,
        fontSize: 14,
        color: 'ffab00',
      }
    );

    slideParticipantSexe.addText(
      `
${
  participantsSexe &&
  participantsSexe?.length &&
  participantsSexe.filter((el: any) => el === 'Féminin')?.length
    ? `dont ${
        participantsSexe.filter((el: any) => el === 'Féminin').length
      } de sexe féminin`
    : 'dont aucun de sexe féminin '
}`,
      {
        x: 1.2,
        y: 0.8,
        bold: false,
        fontSize: 10,
        bullet: true,
      }
    );

    slideParticipantSexe.addText(
      `
${
  participantsSexe &&
  participantsSexe?.length &&
  participantsSexe.filter((el: any) => el === 'Masculin')?.length
    ? `dont ${
        participantsSexe.filter((el: any) => el === 'Masculin').length
      } de sexe masculin`
    : 'dont aucun de sexe masculin'
}`,
      {
        x: 1.2,
        y: 1,
        bold: false,
        fontSize: 10,
        bullet: true,
      }
    );

    slideParticipantSexe.addText(
      `
${
  participantsSexe &&
  participantsSexe?.length &&
  participantsSexe.filter((el: any) => el === 'Autre')?.length
    ? `dont ${
        participantsSexe.filter((el: any) => el === 'Autre').length
      } autre`
    : 'dont aucun autre sexe'
}`,
      {
        x: 1.2,
        y: 1.2,
        bold: false,
        fontSize: 10,
        bullet: true,
      }
    );

    slideParticipantSexe.addChart(
      pres.ChartType.pie,
      dataChartPieImplication,

      {
        x: 0.4,
        y: 1.8,
        w: 4,
        h: 3,
        dataTableFontSize: 7,
        showLegend: true,
        showTitle: true,
        title: 'Répartition des participants par sexe',
        titleFontSize: 11,
      }
    );

    // RQTH
    slideParticipantSexe.addText(
      `
${
  participantsRQTH &&
  participantsRQTH?.length &&
  participantsRQTH.filter((el: any) => el === 'Oui')?.length
    ? `dont ${
        participantsRQTH.filter((el: any) => el === 'Oui').length
      } reconnaissance de la qualité de travailleur handicapé`
    : 'dont aucune reconnaissance de la qualité de travailleur handicapé'
}`,
      {
        x: 5,
        y: 0.8,
        bold: false,
        fontSize: 10,
        bullet: true,
      }
    );

    slideParticipantSexe.addChart(
      pres.ChartType.pie,
      dataChartPieRQTH,

      {
        x: 5,
        y: 1.8,
        w: 4,
        h: 3,
        dataTableFontSize: 7,
        showLegend: true,
        showTitle: true,
        title: 'Répartition des participants par RQTH',
        titleFontSize: 11,
      }
    );

    const participantsOperation =
      participants?.data && participants?.data?.length
        ? participants?.data?.map(
            (el: any) => el['OperationMap'][`${operation.id}`]
          )
        : [];
    //ELIBILITÉ
    const participantsEligility =
      participants?.data && participants?.data?.length
        ? participants?.data?.map((el: any) => el['Éligibilité'])
        : [];

    const eligibility = participantsEligility.map((el: any) =>
      isUndefined(el['Éligibilité']) ? 'Non renseigné' : el['Éligibilité']
    );

    const participantsEligilityTitle =
      "PARTICIPANTS : répartition par critère d'éligibilité";

    this.createPPT_chartBar(
      pres,
      logo,
      participantsEligilityTitle,
      eligibility,
      {
        ...slideTitleOptions,
        x: 1.5,
        y: 0.2,
        fontSize: 16,
      },
      'Participants'
    );

    let dataChartEligibilityPie = [
      {
        name: participantsEligilityTitle,
        labels:
          eligibility && eligibility?.length
            ? Object.keys(this.convertDATA(eligibility))
            : null,
        values:
          eligibility && eligibility?.length
            ? Object.values(this.convertDATA(eligibility))
            : null,
      },
    ];

    let slideEligibilityPie = pres.addSlide({
      masterName: 'MASTER_SLIDE',
      sectionTitle: 'Participants',
    });

    slideEligibilityPie.addText(participantsEligilityTitle, {
      ...slideTitleOptions,
      x: 1.5,
      y: 0.2,
      fontSize: 16,
    });
    slideEligibilityPie.addChart(
      pres.ChartType.pie,
      dataChartEligibilityPie,

      {
        x: 0.2,
        y: 1,
        w: 4,
        h: 3,
        dataTableFontSize: 7,
        showLegend: true,
        showTitle: true,
        title: "Répartition par critère d'éligibilité",
        titleFontSize: 11,
      }
    );

    //TRANCHES
    const participantsTrancheAge =
      participants?.data && participants?.data?.length
        ? participants?.data?.map((el: any) => el['Tranche'])
        : [];
    const participantsTrancheTitle =
      "PARTICIPANTS : répartition par tranche d'âge";
    this.createPPT_chartBar(
      pres,
      logo,
      participantsTrancheTitle,
      participantsTrancheAge,
      {
        ...slideTitleOptions,
        x: 1.5,
        y: 0.2,
        fontSize: 16,
      },
      'Participants'
    );

    let dataChartPie = [
      {
        name: participantsTrancheTitle,
        labels:
          participantsTrancheAge && participantsTrancheAge?.length
            ? Object.keys(this.convertDATA(participantsTrancheAge))
            : null,
        values:
          participantsTrancheAge && participantsTrancheAge?.length
            ? Object.values(this.convertDATA(participantsTrancheAge))
            : null,
      },
    ];

    let slideTranchePie = pres.addSlide({
      masterName: 'MASTER_SLIDE',
      sectionTitle: 'Participants',
    });

    slideTranchePie.addText(participantsTrancheTitle, {
      ...slideTitleOptions,
      x: 1.5,
      y: 0.2,
      fontSize: 16,
    });
    slideTranchePie.addChart(
      pres.ChartType.pie,
      dataChartPie,

      {
        x: 0.2,
        y: 1,
        w: 4,
        h: 3,
        dataTableFontSize: 7,
        showLegend: true,
        showTitle: true,
        title: participantsTrancheTitle,
        titleFontSize: 11,
      }
    );

    //COMMUNE
    const participantsCommune =
      participants?.data && participants?.data?.length
        ? participants?.data?.map((el: any) => el['Commune'])
        : [];

    let communeTitle =
      'PARTICIPANTS : répartition des participants par commune';
    this.createPPT_chartBar(
      pres,
      logo,
      communeTitle,
      participantsCommune,
      {
        ...slideTitleOptions,
        x: 1.5,
        y: 0.2,
        fontSize: 16,
      },
      'Participants'
    );

    let dataChartCommunePie = [
      {
        name: communeTitle,
        labels:
          participantsCommune && participantsCommune?.length
            ? Object.keys(this.convertDATA(participantsCommune))
            : null,
        values:
          participantsCommune && participantsCommune?.length
            ? Object.values(this.convertDATA(participantsCommune))
            : null,
      },
    ];

    let slideCommunePie = pres.addSlide({
      masterName: 'MASTER_SLIDE',
      sectionTitle: 'Participants',
    });

    slideCommunePie.addText(communeTitle, {
      ...slideTitleOptions,
      x: 1.5,
      y: 0.2,
      fontSize: 16,
    });

    slideCommunePie.addChart(
      pres.ChartType.pie,
      dataChartCommunePie,

      {
        x: 0.2,
        y: 1,
        w: 4,
        h: 3,
        dataTableFontSize: 7,
        showLegend: true,
        showTitle: true,
        title: 'Répartition par commune',
        titleFontSize: 11,
      }
    );

    //SANTÉ
    const participantsSante =
      participants?.data && participants?.data?.length
        ? participants?.data?.map((el: any) => el['Santé'])
        : [];
    let santeTitle = 'PARTICIPANTS : répartition par santé';
    this.createPPT_chartBar(
      pres,
      logo,
      santeTitle,
      participantsSante,
      {
        ...slideTitleOptions,
        x: 1.5,
        y: 0.2,
        fontSize: 16,
      },
      'Participants'
    );

    let dataChartSantePie = [
      {
        name: santeTitle,
        labels:
          participantsSante && participantsSante?.length
            ? Object.keys(this.convertDATA(participantsSante))
            : null,
        values:
          participantsSante && participantsSante?.length
            ? Object.values(this.convertDATA(participantsSante))
            : null,
      },
    ];

    let slideSantePie = pres.addSlide({
      masterName: 'MASTER_SLIDE',
      sectionTitle: 'Participants',
    });

    slideSantePie.addText(santeTitle, {
      ...slideTitleOptions,
      x: 1.5,
      y: 0.2,
      fontSize: 16,
    });
    slideSantePie.addChart(
      pres.ChartType.pie,
      dataChartSantePie,

      {
        x: 0.2,
        y: 1,
        w: 4,
        h: 3,
        dataTableFontSize: 7,
        showLegend: true,
        showTitle: true,
        title: 'Répartition par santé',
        titleFontSize: 11,
      }
    );

    //CODE POSTAL
    const participantsCodePostal =
      participants?.data && participants?.data?.length
        ? participants?.data?.map((el: any) => el['Code postal'])
        : [];
    let codePosatTitle = 'PARTICIPANTS : répartition par code postal';
    this.createPPT_chartBar(
      pres,
      logo,
      codePosatTitle,
      participantsCodePostal,
      {
        ...slideTitleOptions,
        x: 1.5,
        y: 0.2,
        fontSize: 16,
      },
      'Participants'
    );

    let dataChartCodePostalPie = [
      {
        name: codePosatTitle,
        labels:
          participantsCodePostal && participantsCodePostal?.length
            ? Object.keys(this.convertDATA(participantsCodePostal))
            : null,
        values:
          participantsCodePostal && participantsCodePostal?.length
            ? Object.values(this.convertDATA(participantsCodePostal))
            : null,
      },
    ];

    let slideCodePostalPie = pres.addSlide({
      masterName: 'MASTER_SLIDE',
      sectionTitle: 'Participants',
    });

    slideCodePostalPie.addText(codePosatTitle, {
      ...slideTitleOptions,
      x: 1.5,
      y: 0.2,
      fontSize: 16,
    });
    slideCodePostalPie.addChart(
      pres.ChartType.pie,
      dataChartCodePostalPie,

      {
        x: 0.2,
        y: 1,
        w: 4,
        h: 3,
        dataTableFontSize: 7,
        showLegend: true,
        showTitle: true,
        title: 'Répartition par code postal',
        titleFontSize: 11,
      }
    );

    //COMMUNICATION PREFERENTIELLE
    const participantsCommunication =
      participants?.data && participants?.data?.length
        ? participants?.data?.map((el: any) => el['Communication'])
        : [];

    let typeCommunicationTitle =
      'PARTICIPANTS : Répartition par type de communication';
    this.createPPT_chartBar(
      pres,
      logo,
      typeCommunicationTitle,
      participantsCommunication,
      {
        ...slideTitleOptions,
        x: 1.5,
        y: 0.2,
        fontSize: 16,
      },
      'Participants'
    );

    let dataChartDoposeInternetPie = [
      {
        name: typeCommunicationTitle,
        labels:
          participantsCommunication && participantsCommunication?.length
            ? Object.keys(this.convertDATA(participantsCommunication))
            : null,
        values:
          participantsCommunication && participantsCommunication?.length
            ? Object.values(this.convertDATA(participantsCommunication))
            : null,
      },
    ];

    let slideTypeCommunicationPie = pres.addSlide({
      masterName: 'MASTER_SLIDE',
      sectionTitle: 'Participants',
    });

    slideTypeCommunicationPie.addText(typeCommunicationTitle, {
      ...slideTitleOptions,
      x: 1.5,
      y: 0.2,
      fontSize: 16,
    });
    slideTypeCommunicationPie.addChart(
      pres.ChartType.pie,
      dataChartDoposeInternetPie,

      {
        x: 0.2,
        y: 1,
        w: 4,
        h: 3,
        dataTableFontSize: 7,
        showLegend: true,
        showTitle: true,
        title: 'Répartition par type de communication',
        titleFontSize: 11,
      }
    );

    //DISPOSITION INTERNET
    const participantsDisposeInternet =
      participants?.data && participants?.data?.length
        ? participants?.data?.map((el: any) =>
            el["Dispose d'un accès internet"] ? 'Oui' : 'Non'
          )
        : [];

    let participantDisposeInternetTitle =
      "PARTICIPANTS : Répartition par disposition d'un accès internet";

    this.createPPT_chartBar(
      pres,
      logo,
      participantDisposeInternetTitle,
      participantsDisposeInternet,
      {
        ...slideTitleOptions,
        x: 1.5,
        y: 0.2,
        fontSize: 16,
      },
      'Participants'
    );

    let dataChartDiposeInternetPie = [
      {
        name: participantDisposeInternetTitle,
        labels:
          participantsDisposeInternet && participantsDisposeInternet?.length
            ? Object.keys(this.convertDATA(participantsDisposeInternet))
            : null,
        values:
          participantsDisposeInternet && participantsDisposeInternet?.length
            ? Object.values(this.convertDATA(participantsDisposeInternet))
            : null,
      },
    ];

    let slideDisposeInternetPie = pres.addSlide({
      masterName: 'MASTER_SLIDE',
      sectionTitle: 'Participants',
    });
    slideDisposeInternetPie.addImage({
      path: logo.toString(),
      x: 0.2,
      w: 1,
      h: 0.5,
    });

    slideDisposeInternetPie.addText(participantDisposeInternetTitle, {
      ...slideTitleOptions,
      x: 1.5,
      y: 0.2,
      fontSize: 16,
    });
    slideDisposeInternetPie.addChart(
      pres.ChartType.pie,
      dataChartDiposeInternetPie,

      {
        x: 0.2,
        y: 1,
        w: 4,
        h: 3,
        dataTableFontSize: 7,
        showLegend: true,
        showTitle: true,
        title: 'Répartition par posséssion aacès internet',
        titleFontSize: 11,
      }
    );

    //DISPOSITION LOGEMENT
    const participantsDisposeLogement =
      participants?.data && participants?.data?.length
        ? participants?.data?.map((el: any) =>
            el["Dispose d'un logement"] ? 'Oui' : 'Non'
          )
        : [];

    let logementTitle =
      "PARTICIPANTS : répartition par disposition d'un logement";
    this.createPPT_chartBar(
      pres,
      logo,
      logementTitle,
      participantsDisposeLogement,
      {
        ...slideTitleOptions,
        x: 1.5,
        y: 0.2,
        fontSize: 16,
      },
      'Participants'
    );

    let slideDisposeLogementPie = pres.addSlide({
      masterName: 'MASTER_SLIDE',
      sectionTitle: 'Participants',
    });

    let dataChartDoposeLogementPie = [
      {
        name: logementTitle,
        labels:
          participantsDisposeLogement && participantsDisposeLogement?.length
            ? Object.keys(this.convertDATA(participantsDisposeLogement))
            : null,
        values:
          participantsDisposeLogement && participantsDisposeLogement?.length
            ? Object.values(this.convertDATA(participantsDisposeLogement))
            : null,
      },
    ];

    slideDisposeLogementPie.addText(logementTitle, {
      ...slideTitleOptions,
      x: 1.5,
      y: 0.2,
      fontSize: 16,
    });
    slideDisposeLogementPie.addChart(
      pres.ChartType.pie,
      dataChartDoposeLogementPie,

      {
        x: 0.2,
        y: 1,
        w: 4,
        h: 3,
        dataTableFontSize: 7,
        showLegend: true,
        showTitle: true,
        title: "Répartition par posséssion d'un mobile",
        titleFontSize: 11,
      }
    );

    //DISPOSITION MOBILE
    const participantsDisposeMobile =
      participants?.data && participants?.data?.length
        ? participants?.data?.map((el: any) =>
            el["Dispose d'un mobile"] ? 'Oui' : 'Non'
          )
        : [];

    let disposeMobileTitle =
      "PARTICIPANTS : répartition par disposition d'un mobile";

    this.createPPT_chartBar(
      pres,
      logo,
      disposeMobileTitle,
      participantsDisposeMobile,
      {
        ...slideTitleOptions,
        x: 1.5,
        y: 0.2,
        fontSize: 16,
      },
      'Participants'
    );

    let dataChartDoposeMobilePie = [
      {
        name: disposeMobileTitle,
        labels:
          participantsDisposeMobile && participantsDisposeMobile?.length
            ? Object.keys(this.convertDATA(participantsDisposeMobile))
            : null,
        values:
          participantsDisposeMobile && participantsDisposeMobile?.length
            ? Object.values(this.convertDATA(participantsDisposeMobile))
            : null,
      },
    ];

    let slideDisposeMobilePie = pres.addSlide({
      masterName: 'MASTER_SLIDE',
      sectionTitle: 'Participants',
    });
    slideDisposeMobilePie.addText(disposeMobileTitle, {
      ...slideTitleOptions,
      x: 1.5,
      y: 0.2,
      fontSize: 16,
    });
    slideDisposeMobilePie.addChart(
      pres.ChartType.pie,
      dataChartDoposeMobilePie,

      {
        x: 0.2,
        y: 1,
        w: 4,
        h: 3,
        dataTableFontSize: 7,
        showLegend: true,
        showTitle: true,
        title: "Répartition par posséssion d'un mobile",
        titleFontSize: 11,
      }
    );

    //DISPOSITION ORDINATEUR
    const participantsDisposeOrdinateur =
      participants?.data && participants?.data?.length
        ? participants?.data?.map((el: any) =>
            el["Dispose d'un ordinateur"] ? 'Oui' : 'Non'
          )
        : [];

    let disposeOrdinateurTitle =
      "PARTICIPANTS : répartition par disposition d'un ordinateur";
    this.createPPT_chartBar(
      pres,
      logo,
      disposeOrdinateurTitle,
      participantsDisposeOrdinateur,
      {
        ...slideTitleOptions,
        x: 1.5,
        y: 0.2,
        fontSize: 16,
      },
      'Participants'
    );

    let dataChartDoposeOrdinateurPie = [
      {
        name: disposeOrdinateurTitle,
        labels:
          participantsDisposeOrdinateur && participantsDisposeOrdinateur?.length
            ? Object.keys(this.convertDATA(participantsDisposeOrdinateur))
            : null,
        values:
          participantsDisposeOrdinateur && participantsDisposeOrdinateur?.length
            ? Object.values(this.convertDATA(participantsDisposeOrdinateur))
            : null,
      },
    ];

    let slideDisposeOrdinateurPie = pres.addSlide({
      masterName: 'MASTER_SLIDE',
      sectionTitle: 'Participants',
    });
    slideDisposeOrdinateurPie.addText(disposeOrdinateurTitle, {
      ...slideTitleOptions,
      x: 1.5,
      y: 0.2,
      fontSize: 16,
    });
    slideDisposeOrdinateurPie.addChart(
      pres.ChartType.pie,
      dataChartDoposeOrdinateurPie,

      {
        x: 0.2,
        y: 1,
        w: 4,
        h: 3,
        dataTableFontSize: 7,
        showLegend: true,
        showTitle: true,
        title: "Répartition par posséssion d'un ordinateur",
        titleFontSize: 11,
      }
    );

    //NIVEAU DE FRANÇAIS
    const participantsNiveauFrancais =
      participants?.data && participants?.data?.length
        ? participants?.data?.map((el: any) => el['Français'])
        : [];

    let niveauFrancaisTitle =
      'PARTICIPANTS : répartition par niveau de français';

    this.createPPT_chartBar(
      pres,
      logo,
      niveauFrancaisTitle,
      participantsNiveauFrancais,
      {
        ...slideTitleOptions,
        x: 1.5,
        y: 0.2,
        fontSize: 16,
      },
      'Participants'
    );

    let dataChartNiveauFrancaisPie = [
      {
        name: niveauFrancaisTitle,
        labels:
          participantsNiveauFrancais && participantsNiveauFrancais?.length
            ? Object.keys(this.convertDATA(participantsNiveauFrancais))
            : null,
        values:
          participantsNiveauFrancais && participantsNiveauFrancais?.length
            ? Object.values(this.convertDATA(participantsNiveauFrancais))
            : null,
      },
    ];

    let slideNiveauFrancaisPie = pres.addSlide({
      masterName: 'MASTER_SLIDE',
      sectionTitle: 'Participants',
    });
    slideNiveauFrancaisPie.addText(niveauFrancaisTitle, {
      ...slideTitleOptions,
      x: 1.5,
      y: 0.2,
      fontSize: 16,
    });
    slideNiveauFrancaisPie.addChart(
      pres.ChartType.pie,
      dataChartNiveauFrancaisPie,

      {
        x: 0.2,
        y: 1,
        w: 4,
        h: 3,
        dataTableFontSize: 7,
        showLegend: true,
        showTitle: true,
        title: 'Répartition par niveau de français',
        titleFontSize: 11,
      }
    );

    //MINIMA SOCIAUX
    const participantsMinimaSociaux =
      participants?.data && participants?.data?.length
        ? participants?.data?.map((el: any) => el['Minima sociaux'])
        : [];

    let minimaSociauxTitle =
      'PARTICIPANTS : répartition par accès aux minima sociaux';

    this.createPPT_chartBar(
      pres,
      logo,
      minimaSociauxTitle,
      participantsMinimaSociaux,
      {
        ...slideTitleOptions,
        x: 1.5,
        y: 0.2,
        fontSize: 16,
      },
      'Participants'
    );

    let dataChartMinimaSociauxPie = [
      {
        name: minimaSociauxTitle,
        labels:
          participantsMinimaSociaux && participantsMinimaSociaux?.length
            ? Object.keys(this.convertDATA(participantsMinimaSociaux))
            : null,
        values:
          participantsMinimaSociaux && participantsMinimaSociaux?.length
            ? Object.values(this.convertDATA(participantsMinimaSociaux))
            : null,
      },
    ];

    let slideMinimaSociauxPie = pres.addSlide({
      masterName: 'MASTER_SLIDE',
      sectionTitle: 'Participants',
    });
    slideMinimaSociauxPie.addText(minimaSociauxTitle, {
      ...slideTitleOptions,
      x: 1.5,
      y: 0.2,
      fontSize: 16,
    });
    slideMinimaSociauxPie.addChart(
      pres.ChartType.pie,
      dataChartMinimaSociauxPie,

      {
        x: 0.2,
        y: 1,
        w: 4,
        h: 3,
        dataTableFontSize: 7,
        showLegend: true,
        showTitle: true,
        title: 'Répartition par accès aux minima sociaux',
        titleFontSize: 11,
      }
    );

    //MISSION LOCALE
    const participantsMissionLocale =
      participants?.data && participants?.data?.length
        ? participants?.data?.map((el: any) => el['Mission locale'])
        : [];
    let missionLocaleTitle = 'PARTICIPANTS : Répartition par mission locale';
    this.createPPT_chartBar(
      pres,
      logo,
      missionLocaleTitle,
      participantsMissionLocale,
      {
        ...slideTitleOptions,
        x: 1.5,
        y: 0.2,
        fontSize: 16,
      },
      'Participants'
    );

    let dataChartMissionLocalePie = [
      {
        name: missionLocaleTitle,
        labels:
          participantsMissionLocale && participantsMissionLocale?.length
            ? Object.keys(this.convertDATA(participantsMissionLocale))
            : null,
        values:
          participantsMissionLocale && participantsMissionLocale?.length
            ? Object.values(this.convertDATA(participantsMissionLocale))
            : null,
      },
    ];

    let slideMissionLocalePie = pres.addSlide({
      masterName: 'MASTER_SLIDE',
      sectionTitle: 'Participants',
    });
    slideMissionLocalePie.addText(missionLocaleTitle, {
      ...slideTitleOptions,
      x: 1.5,
      y: 0.2,
      fontSize: 16,
    });
    slideMissionLocalePie.addChart(
      pres.ChartType.pie,
      dataChartMissionLocalePie,

      {
        x: 0.2,
        y: 1,
        w: 4,
        h: 3,
        dataTableFontSize: 7,
        showLegend: true,
        showTitle: true,
        title: 'Répartition par mission locale',
        titleFontSize: 11,
      }
    );

    //MOBILITÉ
    const participantsMobility =
      participants?.data && participants?.data?.length
        ? participants?.data?.map((el: any) => el['Mobilité'])
        : [];

    let mobilityTitle = 'PARTICIPANTS : Répartition par type de mobilité';
    this.createPPT_chartBar(
      pres,
      logo,
      mobilityTitle,
      participantsMobility,
      {
        ...slideTitleOptions,
        x: 1.5,
        y: 0.2,
        fontSize: 16,
      },
      'Participants'
    );

    let dataChartMobilityPie = [
      {
        name: mobilityTitle,
        labels:
          participantsMobility && participantsMobility?.length
            ? Object.keys(this.convertDATA(participantsMobility))
            : null,
        values:
          participantsMobility && participantsMobility?.length
            ? Object.values(this.convertDATA(participantsMobility))
            : null,
      },
    ];

    let slideMobilityPie = pres.addSlide({
      masterName: 'MASTER_SLIDE',
      sectionTitle: 'Participants',
    });
    slideMobilityPie.addText(mobilityTitle, {
      ...slideTitleOptions,
      x: 1.5,
      y: 0.2,
      fontSize: 16,
    });
    slideMobilityPie.addChart(
      pres.ChartType.pie,
      dataChartMobilityPie,

      {
        x: 0.2,
        y: 1,
        w: 4,
        h: 3,
        dataTableFontSize: 7,
        showLegend: true,
        showTitle: true,
        title: 'Répartition par type de mobilité',
        titleFontSize: 11,
      }
    );

    //NATIONALITÉ
    const participantsNationality =
      participants?.data && participants?.data?.length
        ? participants?.data?.map((el: any) => el['Nationalité'])
        : [];

    let nationalityTitle = 'PARTICIPANTS : répartition par nationalité';
    this.createPPT_chartBar(
      pres,
      logo,
      nationalityTitle,
      participantsNationality,
      {
        ...slideTitleOptions,
        x: 1.5,
        y: 0.2,
        fontSize: 16,
      },
      'Participants'
    );

    let dataChartNationalityPie = [
      {
        name: nationalityTitle,
        labels:
          participantsNationality && participantsNationality?.length
            ? Object.keys(this.convertDATA(participantsNationality))
            : null,
        values:
          participantsNationality && participantsNationality?.length
            ? Object.values(this.convertDATA(participantsNationality))
            : null,
      },
    ];

    let slideAgenceNationalityPie = pres.addSlide({
      masterName: 'MASTER_SLIDE',
      sectionTitle: 'Participants',
    });
    slideAgenceNationalityPie.addText(nationalityTitle, {
      ...slideTitleOptions,
      x: 1.5,
      y: 0.2,
      fontSize: 16,
    });
    slideAgenceNationalityPie.addChart(
      pres.ChartType.pie,
      dataChartNationalityPie,

      {
        x: 0.2,
        y: 1,
        w: 4,
        h: 3,
        dataTableFontSize: 7,
        showLegend: true,
        showTitle: true,
        title: 'Répartition par nationalité',
        titleFontSize: 11,
      }
    );

    //NUMERIQUE
    const participantsNiveauNumerique =
      participants?.data && participants?.data?.length
        ? participants?.data?.map((el: any) => el['Niveau numérique'])
        : [];

    //POLE EMPLOI
    const participantsPoleEmploi =
      participants?.data && participants?.data?.length
        ? participants?.data?.map((el: any) => el['Pôle Emploi'])
        : [];

    let agencePoleEmploiTitle =
      'PARTICIPANTS : répartition par agence Pôle Emploi';
    this.createPPT_chartBar(
      pres,
      logo,
      agencePoleEmploiTitle,
      participantsPoleEmploi,
      {
        ...slideTitleOptions,
        x: 1.5,
        y: 0.2,
        fontSize: 16,
      },
      'Participants'
    );

    let dataChartAgencePoleEmploiPie = [
      {
        name: agencePoleEmploiTitle,
        labels:
          participantsPoleEmploi && participantsPoleEmploi?.length
            ? Object.keys(this.convertDATA(participantsPoleEmploi))
            : null,
        values:
          participantsPoleEmploi && participantsPoleEmploi?.length
            ? Object.values(this.convertDATA(participantsPoleEmploi))
            : null,
      },
    ];

    let slideAgencePoleEmploiPie = pres.addSlide({
      masterName: 'MASTER_SLIDE',
      sectionTitle: 'Participants',
    });
    slideAgencePoleEmploiPie.addText(agencePoleEmploiTitle, {
      ...slideTitleOptions,
      x: 1.5,
      y: 0.2,
      fontSize: 16,
    });
    slideAgencePoleEmploiPie.addChart(
      pres.ChartType.pie,
      dataChartAgencePoleEmploiPie,

      {
        x: 0.2,
        y: 1,
        w: 4,
        h: 3,
        dataTableFontSize: 7,
        showLegend: true,
        showTitle: true,
        title: 'Répartition par agence pôle emploi',
        titleFontSize: 11,
      }
    );

    //TYPE DE QUALIFICATION
    const participantsTypeQualification =
      participants?.data && participants?.data?.length
        ? participants?.data?.map((el: any) => el['Qualification'])
        : [];
    this.createPPT_chartBar(
      pres,
      logo,
      'PARTICIPANTS : répartition par type de qualification',
      participantsTypeQualification,
      {
        ...slideTitleOptions,
        x: 1.5,
        y: 0.2,
        fontSize: 16,
      },
      'Participants'
    );

    let dataChartTypeQualificationPie = [
      {
        name: 'PARTICIPANTS : répartition par type de qualification',
        labels:
          participantsTypeQualification && participantsTypeQualification?.length
            ? Object.keys(this.convertDATA(participantsTypeQualification))
            : null,
        values:
          participantsTypeQualification && participantsTypeQualification?.length
            ? Object.values(this.convertDATA(participantsTypeQualification))
            : null,
      },
    ];

    let slideTypeQualificationPie = pres.addSlide({
      masterName: 'MASTER_SLIDE',
      sectionTitle: 'Participants',
    });
    slideTypeQualificationPie.addText(
      'PARTICIPANTS : répartition par type de qualification',
      {
        ...slideTitleOptions,
        x: 1.5,
        y: 0.2,
        fontSize: 16,
      }
    );
    slideTypeQualificationPie.addChart(
      pres.ChartType.pie,
      dataChartTypeQualificationPie,

      {
        x: 0.2,
        y: 1,
        w: 4,
        h: 3,
        dataTableFontSize: 7,
        showLegend: true,
        showTitle: true,
        title: 'Répartition par type de qualification',
        titleFontSize: 11,
      }
    );

    //NIVEAU DE QUALIFICATION
    const participantsNiveauQualification =
      participants?.data && participants?.data?.length
        ? participants?.data?.map((el: any) => el['Qualification niveau'])
        : [];

    let niveauQualificationTitlte =
      'PARTICIPANTS : Répartition par niveau de qualification';
    this.createPPT_chartBar(
      pres,
      logo,
      niveauQualificationTitlte,
      participantsNiveauQualification,
      {
        ...slideTitleOptions,
        x: 1.5,
        y: 0.2,
        fontSize: 16,
      },
      'Participants'
    );

    let dataChartNiveauQualificationPie = [
      {
        name: niveauQualificationTitlte,
        labels:
          participantsNiveauQualification &&
          participantsNiveauQualification?.length
            ? Object.keys(this.convertDATA(participantsNiveauQualification))
            : null,
        values:
          participantsNiveauQualification &&
          participantsNiveauQualification?.length
            ? Object.values(this.convertDATA(participantsNiveauQualification))
            : null,
      },
    ];

    let slideNiveauQualificationPie = pres.addSlide({
      masterName: 'MASTER_SLIDE',
      sectionTitle: 'Participants',
    });
    slideNiveauQualificationPie.addText(niveauQualificationTitlte, {
      ...slideTitleOptions,
      x: 1.5,
      y: 0.2,
      fontSize: 16,
    });
    slideNiveauQualificationPie.addChart(
      pres.ChartType.pie,
      dataChartNiveauQualificationPie,

      {
        x: 0.2,
        y: 1,
        w: 4,
        h: 3,
        dataTableFontSize: 7,
        showLegend: true,
        showTitle: true,
        title: 'Répartition par niveau de qualification',
        titleFontSize: 11,
      }
    );

    //SITUATION MATRIMONIALE
    const participantsSituationMatrimoniale =
      participants?.data && participants?.data?.length
        ? participants?.data?.map((el: any) => el['Situation matrimoniale'])
        : [];

    let matromonialeTitle =
      'PARTICIPANTS : répartition par situation matrimoniale';
    this.createPPT_chartBar(
      pres,
      logo,
      matromonialeTitle,
      participantsSituationMatrimoniale,
      {
        ...slideTitleOptions,
        x: 1.5,
        y: 0.2,
        fontSize: 16,
      },
      'Participants'
    );

    let dataChartMatrimonialePie = [
      {
        name: matromonialeTitle,
        labels:
          participantsSituationMatrimoniale &&
          participantsSituationMatrimoniale?.length
            ? Object.keys(this.convertDATA(participantsSituationMatrimoniale))
            : null,
        values:
          participantsSituationMatrimoniale &&
          participantsSituationMatrimoniale?.length
            ? Object.values(this.convertDATA(participantsSituationMatrimoniale))
            : null,
      },
    ];

    let slideMatrimonialePie = pres.addSlide({
      masterName: 'MASTER_SLIDE',
      sectionTitle: 'Participants',
    });
    slideMatrimonialePie.addText(matromonialeTitle, {
      ...slideTitleOptions,
      x: 1.5,
      y: 0.2,
      fontSize: 16,
    });
    slideMatrimonialePie.addChart(
      pres.ChartType.pie,
      dataChartMatrimonialePie,

      {
        x: 0.2,
        y: 1,
        w: 4,
        h: 3,
        dataTableFontSize: 7,
        showLegend: true,
        showTitle: true,
        title: 'Répartition par situation matrimoniale',
        titleFontSize: 11,
      }
    );

    //TITRE DE CARTE DE SEJOUR
    const participantsTitreCarteSejour =
      participants?.data && participants?.data?.length
        ? participants?.data?.map((el: any) => el['Titre de carte séjour'])
        : [];

    let titreCarteSejourTitle =
      'PARTICIPANTS : répartition des participants par titre de carte de séjour';
    this.createPPT_chartBar(
      pres,
      logo,
      titreCarteSejourTitle,
      participantsTitreCarteSejour,
      {
        ...slideTitleOptions,
        x: 1.5,
        y: 0.2,
        fontSize: 16,
      },
      'Participants'
    );

    let dataChartTitreCarteSejoursPie = [
      {
        name: titreCarteSejourTitle,
        labels:
          participantsTitreCarteSejour && participantsTitreCarteSejour?.length
            ? Object.keys(this.convertDATA(participantsTitreCarteSejour))
            : null,
        values:
          participantsTitreCarteSejour && participantsTitreCarteSejour?.length
            ? Object.values(this.convertDATA(participantsTitreCarteSejour))
            : null,
      },
    ];

    let slideTitreCarteDeSejoursPie = pres.addSlide({
      masterName: 'MASTER_SLIDE',
      sectionTitle: 'Participants',
    });
    slideTitreCarteDeSejoursPie.addText(titreCarteSejourTitle, {
      ...slideTitleOptions,
      x: 1.5,
      y: 0.2,
      fontSize: 16,
    });
    slideTitreCarteDeSejoursPie.addChart(
      pres.ChartType.pie,
      dataChartTitreCarteSejoursPie,

      {
        x: 0.2,
        y: 1,
        w: 4,
        h: 3,
        dataTableFontSize: 7,
        showLegend: true,
        showTitle: true,
        title: 'Répartition par titre de carte de séjour',
        titleFontSize: 11,
      }
    );

    //TYPE DE CARTE DE SEJOUR
    const participantsTypeCarteSejour =
      participants?.data && participants?.data?.length
        ? participants?.data?.map((el: any) => el['Type de carte séjour'])
        : [];

    let typeCarteSejourTitle =
      'PARTICIPANTS : Répartition par type de carte de séjour';

    this.createPPT_chartBar(
      pres,
      logo,
      typeCarteSejourTitle,
      participantsTypeCarteSejour,
      {
        ...slideTitleOptions,
        x: 1.5,
        y: 0.2,
        fontSize: 16,
      },
      'Participants'
    );

    let dataChartTypeCarteSejoursPie = [
      {
        name: typeCarteSejourTitle,
        labels:
          participantsTypeCarteSejour && participantsTypeCarteSejour?.length
            ? Object.keys(this.convertDATA(participantsTypeCarteSejour))
            : null,
        values:
          participantsTypeCarteSejour && participantsTypeCarteSejour?.length
            ? Object.values(this.convertDATA(participantsTypeCarteSejour))
            : null,
      },
    ];

    let slideTypeCarteDeSejoursPie = pres.addSlide({
      masterName: 'MASTER_SLIDE',
      sectionTitle: 'Participants',
    });
    slideTypeCarteDeSejoursPie.addText(typeCarteSejourTitle, {
      ...slideTitleOptions,
      x: 1.5,
      y: 0.2,
      fontSize: 16,
    });
    slideTypeCarteDeSejoursPie.addChart(
      pres.ChartType.pie,
      dataChartTypeCarteSejoursPie,

      {
        x: 0.2,
        y: 1,
        w: 4,
        h: 3,
        dataTableFontSize: 7,
        showLegend: true,
        showTitle: true,
        title: 'Répartition par statut de carte de séjour',
        titleFontSize: 11,
      }
    );

    //TYPE DE SORTIE
    const participantsTypeDeSortie =
      participants?.data && participants?.data?.length
        ? participants?.data?.map((el: any) => el['Type de sortie'])
        : [];
    let typeDeSortieTitle = 'PARTICIPANTS : répartition par type de sortie';
    this.createPPT_chartBar(
      pres,
      logo,
      typeDeSortieTitle,
      participantsTypeDeSortie,
      {
        ...slideTitleOptions,
        x: 1.5,
        y: 0.2,
        fontSize: 16,
      },
      'Participants'
    );

    let dataChartTypeDeSortiePie = [
      {
        name: typeDeSortieTitle,
        labels:
          participantsTypeDeSortie && participantsTypeDeSortie?.length
            ? Object.keys(this.convertDATA(participantsTypeDeSortie))
            : null,
        values:
          participantsTypeDeSortie && participantsTypeDeSortie?.length
            ? Object.values(this.convertDATA(participantsTypeDeSortie))
            : null,
      },
    ];

    let slideTypeDeSortiePie = pres.addSlide({
      masterName: 'MASTER_SLIDE',
      sectionTitle: 'Participants',
    });
    slideTypeDeSortiePie.addText(typeDeSortieTitle, {
      ...slideTitleOptions,
      x: 1.5,
      y: 0.2,
      fontSize: 16,
    });
    slideTypeDeSortiePie.addChart(
      pres.ChartType.pie,
      dataChartTypeDeSortiePie,

      {
        x: 0.2,
        y: 1,
        w: 4,
        h: 3,
        dataTableFontSize: 7,
        showLegend: true,
        showTitle: true,
        title: 'Répartition par type de sortie',
        titleFontSize: 11,
      }
    );

    //MOTIF DE SORTIE
    const participantsTypeDeMotif =
      participants?.data && participants?.data?.length
        ? participants?.data?.map((el: any) => el['Motif de sortie'])
        : [];
    let motifDeSortieTitle = 'PARTICIPANTS : répartition par motif de sortie';
    this.createPPT_chartBar(
      pres,
      logo,
      motifDeSortieTitle,
      participantsTypeDeMotif,
      {
        ...slideTitleOptions,
        x: 1.5,
        y: 0.2,
        fontSize: 16,
      },
      'Participants'
    );

    let dataChartMotifDeSortiePie = [
      {
        name: motifDeSortieTitle,
        labels:
          participantsTypeDeMotif && participantsTypeDeMotif?.length
            ? Object.keys(this.convertDATA(participantsTypeDeMotif))
            : null,
        values:
          participantsTypeDeMotif && participantsTypeDeMotif?.length
            ? Object.values(this.convertDATA(participantsTypeDeMotif))
            : null,
      },
    ];

    let slideMotifDeSortiePie = pres.addSlide({
      masterName: 'MASTER_SLIDE',
      sectionTitle: 'Participants',
    });
    slideMotifDeSortiePie.addText(motifDeSortieTitle, {
      ...slideTitleOptions,
      x: 1.5,
      y: 0.2,
      fontSize: 16,
    });
    slideMotifDeSortiePie.addChart(
      pres.ChartType.pie,
      dataChartMotifDeSortiePie,

      {
        x: 0.2,
        y: 1,
        w: 4,
        h: 3,
        dataTableFontSize: 7,
        showLegend: true,
        showTitle: true,
        title: 'Répartition motif de sortie',
        titleFontSize: 11,
      }
    );

    //IMPACT 3
    const participantsImpact3mois =
      participants?.data && participants?.data?.length
        ? participants?.data?.map((el: any) => el['Impact à 3 mois'])
        : [];

    let impact3Title =
      "PARTICIPANTS : Impact 3 mois après la fin de l'accompagnement";
    this.createPPT_chartBar(
      pres,
      logo,
      impact3Title,
      participantsImpact3mois,
      {
        ...slideTitleOptions,
        x: 1.5,
        y: 0.2,
        fontSize: 16,
      },
      'Participants'
    );
    let dataChartImpact3Pie = [
      {
        name: impact3Title,
        labels:
          participantsImpact3mois && participantsImpact3mois?.length
            ? Object.keys(this.convertDATA(participantsImpact3mois))
            : null,
        values:
          participantsImpact3mois && participantsImpact3mois?.length
            ? Object.values(this.convertDATA(participantsImpact3mois))
            : null,
      },
    ];

    let slideImpact3Pie = pres.addSlide({
      masterName: 'MASTER_SLIDE',
      sectionTitle: 'Participants',
    });
    slideImpact3Pie.addText(impact3Title, {
      ...slideTitleOptions,
      x: 1.5,
      y: 0.2,
      fontSize: 16,
    });
    slideImpact3Pie.addChart(
      pres.ChartType.pie,
      dataChartImpact3Pie,

      {
        x: 0.2,
        y: 1,
        w: 4,
        h: 3,
        dataTableFontSize: 7,
        showLegend: true,
        showTitle: true,
        title: "Répartition par type d'impact 3 mois",
        titleFontSize: 11,
      }
    );

    //IMPACT 6

    const participantsImpact6mois =
      participants?.data && participants?.data?.length
        ? participants?.data?.map((el: any) => el['Impact à 6 mois'])
        : [];

    let impact6title =
      "PARTICIPANTS : Impact 6 mois après la fin de l'accompagnement";
    this.createPPT_chartBar(
      pres,
      logo,
      impact6title,
      participantsImpact6mois,
      {
        ...slideTitleOptions,
        x: 1.5,
        y: 0.2,
        fontSize: 16,
      },
      'Participants'
    );

    let dataChartImpact6Pie = [
      {
        name: impact6title,
        labels:
          participantsImpact6mois && participantsImpact6mois?.length
            ? Object.keys(this.convertDATA(participantsImpact6mois))
            : null,
        values:
          participantsImpact6mois && participantsImpact6mois?.length
            ? Object.values(this.convertDATA(participantsImpact6mois))
            : null,
      },
    ];

    let slideImpact6Pie = pres.addSlide({
      masterName: 'MASTER_SLIDE',
      sectionTitle: 'Participants',
    });
    slideImpact6Pie.addText(impact6title, {
      ...slideTitleOptions,
      x: 1.5,
      y: 0.2,
      fontSize: 16,
    });
    slideImpact6Pie.addChart(
      pres.ChartType.pie,
      dataChartImpact6Pie,

      {
        x: 0.2,
        y: 1,
        w: 4,
        h: 3,
        dataTableFontSize: 7,
        showLegend: true,
        showTitle: true,
        title: "Répartition par type d'impact 6 mois",
        titleFontSize: 11,
      }
    );

    //IMPACT 12
    const participantsImpact12mois =
      participants?.data && participants?.data?.length
        ? participants?.data?.map((el: any) => el['Impact à 12 mois'])
        : [];
    let impact12Title =
      "PARTICIPANTS : Impact 12 mois après la fin de l'accompagnement";

    this.createPPT_chartBar(
      pres,
      logo,
      impact12Title,
      participantsImpact12mois,
      {
        ...slideTitleOptions,
        x: 1.5,
        y: 0.2,
        fontSize: 16,
      },
      'Participants'
    );

    let dataChartImpact12Pie = [
      {
        name: communeTitle,
        labels:
          participantsImpact12mois && participantsImpact12mois?.length
            ? Object.keys(this.convertDATA(participantsImpact12mois))
            : null,
        values:
          participantsImpact12mois && participantsImpact12mois?.length
            ? Object.values(this.convertDATA(participantsImpact12mois))
            : null,
      },
    ];

    let slideImpact12Pie = pres.addSlide({
      masterName: 'MASTER_SLIDE',
      sectionTitle: 'Participants',
    });
    slideImpact12Pie.addText(impact12Title, {
      ...slideTitleOptions,
      x: 1.5,
      y: 0.2,
      fontSize: 16,
    });
    slideImpact12Pie.addChart(
      pres.ChartType.pie,
      dataChartImpact12Pie,

      {
        x: 0.2,
        y: 1,
        w: 4,
        h: 3,
        dataTableFontSize: 7,
        showLegend: true,
        showTitle: true,
        title: "Répartition par type d'impact 12 mois",
        titleFontSize: 11,
      }
    );

    const participantsAge =
      participants?.data && participants?.data?.length
        ? participants?.data?.map((el: any) => el['Âge'])
        : [];

    const projetEnd: Date = new Date(Date.now());
    const projetStart: Date = sub(projetEnd, { years: 5 });
    const yearRange: Date[] = eachYearOfInterval({
      start: projetStart,
      end: projetEnd,
    });
    let dataChartProjet = [
      {
        name: 'Progression',
        labels: yearRange.map((el) => {
          return format(el, 'yyyy');
        }),
        values: [0, 0, 0, 0, 0, 3],
      },
    ];
  }

  collaborateurs(
    pres: any,
    slideTitleOptions: any,
    slideTitle: any,
    contacts: any,
    logo: any
  ) {
    pres.addSection({ title: 'Collaborateurs' });
    //SLIDE COLLABORATEURS
    let slideIntervenantCollaborateurs = pres.addSlide({
      masterName: 'MASTER_SLIDE',
      sectionTitle: 'Collaborateurs',
    });
    slideIntervenantCollaborateurs.addText('COLLABORATEURS', {
      x: 4,
      y: 0.15,
      bold: true,
      color: '003b75',
    });

    //Nombre de collaborateurs
    slideIntervenantCollaborateurs.addText(
      `${contacts && contacts?.data?.length ? contacts?.data?.length : 0}`,
      {
        x: 0.6,
        y: 0.6,
        w: 1.5,
        h: 0.3,
        align: 'left',
        fontFace: 'Roboto',
      }
    );

    slideIntervenantCollaborateurs.addText(
      `
${
  contacts && contacts?.data?.length
    ? `${
        contacts?.data && contacts?.data?.length > 1
          ? 'collaborateurs mobilisés'
          : 'collaborateur mobilisé'
      }`
    : `Aucun collaborateur mobilisé`
}`,
      {
        x: 0.5,
        y: 0.6,
        bold: true,
        fontSize: 14,
        color: 'ffab00',
      }
    );

    //Nombre d'heures de bénévolat
    const benevolatHoursArray =
      contacts?.data && contacts?.data?.length
        ? compact(
            contacts.data.map((contact: any) => {
              const benevolat = contact['Heures de bénévolat']
                ? contact['Heures de bénévolat']
                : null;

              return benevolat && isNumber(benevolat.hours)
                ? benevolat.hours
                : null;
            })
          )
        : [];

    slideIntervenantCollaborateurs.addText(
      `${
        benevolatHoursArray && benevolatHoursArray?.length
          ? sum(benevolatHoursArray)
          : 0
      }`,
      {
        x: 5,
        y: 0.6,
        w: 1.5,
        h: 0.3,
        align: 'left',
        fontFace: 'Roboto',
      }
    );

    slideIntervenantCollaborateurs.addText(
      `
${
  benevolatHoursArray && benevolatHoursArray?.length
    ? `${
        sum(benevolatHoursArray) > 1
          ? 'heures de bénévolat réalisées'
          : 'heure de bénévolat réalisée'
      }`
    : `Aucun heure de bénévolat réalisée`
}`,
      {
        x: 5,
        y: 0.6,
        bold: true,
        fontSize: 14,
        color: 'ffab00',
      }
    );

    let collaborateurRow: any[] =
      contacts && contacts?.data?.length
        ? contacts.data.map((el: any) => {
            const operationTable = [
              {
                text: el['Fonction'] ? el['Fonction'] : 'Non renseignée',
                options: {
                  y: 0.4,
                  align: 'left',
                  fontSize: 7,
                  fontFace: 'Roboto',
                },
              },
              {
                text: el['Entité'] ? el['Entité'] : 'Non renseignée',
                options: {
                  y: 0.4,
                  align: 'left',
                  fontSize: 7,
                  fontFace: 'Roboto',
                },
              },
              {
                text: el['Heures de bénévolat']
                  ? `${el['Heures de bénévolat']['hours']} heures`
                  : 'Non renseignée',
                options: {
                  y: 0.4,
                  align: 'left',
                  fontSize: 7,
                  fontFace: 'Roboto',
                },
              },
            ];

            return operationTable;
          })
        : [];

    let rowsCollaborateur = [
      [
        {
          text: 'Fonction',
          options: {
            y: 0.3,
            rowH: 0.2,
            align: 'left',
            fontSize: 7,
            fill: '003b75',
            color: 'FFFFFF',
            fontFace: 'Roboto',
          },
        },
        {
          text: 'Entité',
          options: {
            y: 0.3,
            rowH: 0.2,
            align: 'left',
            fontSize: 7,
            fill: '003b75',
            color: 'FFFFFF',
            fontFace: 'Roboto',
          },
        },
        {
          text: 'Heures de bénévolat',
          options: {
            y: 0.3,
            rowH: 0.2,
            align: 'left',
            fontSize: 7,
            fill: '003b75',
            color: 'FFFFFF',
            fontFace: 'Roboto',
          },
        },
      ],
      ...collaborateurRow,
    ];

    // SLIDE - COLLABORATEURS PAR FONCTION

    const collaborateurParFonctionTitlte =
      'COLLABORATEURS - Répartition par fonction';

    const collaborateursFonction =
      contacts?.data && contacts?.data?.length
        ? contacts?.data?.map((el: any) =>
            el['Fonction'] ? el['Fonction'] : 'Non renseignée'
          )
        : [];

    let dataChartPieCollaborateurFonction = [
      {
        name: 'Réparticipant des collaborateurs par fonction',
        labels:
          collaborateursFonction && collaborateursFonction?.length
            ? Object.keys(this.convertDATA(collaborateursFonction))
            : null,
        values:
          collaborateursFonction && collaborateursFonction?.length
            ? Object.values(this.convertDATA(collaborateursFonction))
            : null,
      },
    ];

    const collaborateursParSexe =
      contacts?.data && contacts?.data?.length
        ? contacts?.data?.map((el: any) =>
            el['Sexe'] ? el['Sexe'] : 'Non renseignée'
          )
        : [];

    let dataChartPieCollaborateuSexe = [
      {
        name: 'Réparticipant des collaborateurs par sexe',
        labels:
          collaborateursParSexe && collaborateursParSexe?.length
            ? Object.keys(this.convertDATA(collaborateursParSexe))
            : null,
        values:
          collaborateursParSexe && collaborateursParSexe?.length
            ? Object.values(this.convertDATA(collaborateursParSexe))
            : null,
      },
    ];
    slideIntervenantCollaborateurs.addChart(
      pres.ChartType.pie,
      dataChartPieCollaborateurFonction,

      {
        x: 0.4,
        y: 1.2,
        w: 4,
        h: 3,
        dataTableFontSize: 7,
        showLegend: true,
        showTitle: true,
        title: 'Répartition des collaborateurs par fonction',
        titleFontSize: 11,
      }
    );

    slideIntervenantCollaborateurs.addChart(
      pres.ChartType.pie,
      dataChartPieCollaborateuSexe,

      {
        x: 5,
        y: 1.2,
        w: 4,
        h: 3,
        dataTableFontSize: 7,
        showLegend: true,
        showTitle: true,
        title: 'Réparticipant des collaborateurs par sexe',
        titleFontSize: 11,
      }
    );

    // SLIDE - COLLABORATEURS PAR FONCTION
    const collaborateurParFonctionParHeureTitlte =
      'COLLABORATEURS - Répartition des heures de bénévolat par fonction';

    const groupCollaborateursByFonction =
      contacts?.data && contacts?.data?.length
        ? groupBy(contacts?.data, 'Fonction')
        : [];

    const groupCollaborateursByFonctionToArray = Object.entries(
      groupCollaborateursByFonction
    );

    const groupHourByFonction =
      groupCollaborateursByFonctionToArray &&
      groupCollaborateursByFonctionToArray?.length
        ? groupCollaborateursByFonctionToArray.map((item) => {
            const entite = item[0];
            const benevolat = compact(
              item[1].map((contact) =>
                contact['Heures de bénévolat']
                  ? contact['Heures de bénévolat'].hours
                  : null
              )
            );

            const sumHours = benevolat ? sum(benevolat) : 0;
            return { entite, sumHours };
          })
        : [];

    this.createPPT_chartBarForCollaborateur(
      pres,
      logo,
      collaborateurParFonctionParHeureTitlte,
      groupHourByFonction,
      {
        ...slideTitleOptions,
        x: 1.5,
        y: 0.2,
        fontSize: 16,
      },
      'Collaborateurs'
    );

    //SLIDE - COLLABORATEUR REPARTITION DES HEURES

    const collaborateurParEntiteParHeureTitlte =
      'COLLABORATEURS - Répartition des heures de bénévolat par entreprise';

    const groupCollaborateursByEntite = contacts?.data?.length
      ? groupBy(contacts?.data, 'Entité')
      : [];

    const groupCollaborateursByEntiteToArray = Object.entries(
      groupCollaborateursByEntite
    );

    const groupHourByEntite =
      groupCollaborateursByEntiteToArray &&
      groupCollaborateursByEntiteToArray?.length
        ? groupCollaborateursByEntiteToArray.map((item: any) => {
            const entite = item[0];
            const benevolat = compact(
              item[1].map((contact: any) =>
                contact['Heures de bénévolat']
                  ? contact['Heures de bénévolat'].hours
                  : null
              )
            );

            const sumHours = benevolat ? sum(benevolat) : 0;
            return { entite, sumHours };
          })
        : [];

    this.createPPT_chartBarForCollaborateur(
      pres,
      logo,
      collaborateurParEntiteParHeureTitlte,
      groupHourByEntite,
      {
        ...slideTitleOptions,
        x: 1.5,
        y: 0.2,
        fontSize: 16,
      },
      'Collaborateurs'
    );
  }

  entites(
    pres: any,
    logo: any,
    slideTitle: any,
    entites: any,
    subtitleY: any,
    tableOption: any,
    slideTitleOptions: any
  ): any {
    pres.addSection({ title: `Entreprises` });

    //SLIDE ENTREPRISES
    let slideEntreprises = pres.addSlide({
      masterName: 'MASTER_SLIDE',
      sectionTitle: 'Entreprises',
    });
    slideEntreprises.addText('ENTREPRISES', {
      ...slideTitle,
    });
    slideEntreprises.addText(
      `${entites?.data && entites?.data?.length ? entites?.data?.length : 0}`,
      {
        x: 0.3,
        y: subtitleY,
        w: 1.5,
        h: 0.3,
        align: 'center',
        fontFace: 'Roboto',
      }
    );

    slideEntreprises.addText(
      `
${
  entites?.data && entites?.data?.length
    ? `${
        entites?.data && entites?.data?.length > 1
          ? 'entreprises engagées'
          : 'entreprise engagée'
      }`
    : `Aucun entreprise engagée`
}`,
      {
        x: 0.5,
        y: subtitleY,
        bold: true,
        fontSize: 14,
        color: 'ffab00',
      }
    );
    let entrepriseRow: any[] =
      entites?.data && entites?.data?.length
        ? entites.data.map((el: any) => {
            const operationTable = [
              {
                text: el['Raison sociale']
                  ? el['Raison sociale']
                  : 'Non renseignée',
                options: {
                  y: 0.4,
                  align: 'left',
                  fontSize: 7,
                  fontFace: 'Roboto',
                },
              },
              {
                text: el['Secteur'] ? el['Secteur'] : 'Non renseignée',
                options: {
                  y: 0.4,
                  align: 'left',
                  fontSize: 7,
                  fontFace: 'Roboto',
                },
              },
              {
                text: el['Activité'] ? el['Activité'] : 'Non renseignée',
                options: {
                  y: 0.4,
                  align: 'left',
                  fontSize: 7,
                  fontFace: 'Roboto',
                },
              },
              {
                text: el['Commune'] ? `${el['Commune']}` : 'Non renseignée',
                options: {
                  y: 0.4,
                  align: 'left',
                  fontSize: 7,
                  fontFace: 'Roboto',
                },
              },
              {
                text: el['Département']
                  ? `${el['Département']}`
                  : 'Non renseignée',
                options: {
                  y: 0.4,
                  align: 'left',
                  fontSize: 7,
                  fontFace: 'Roboto',
                },
              },
            ];

            return operationTable;
          })
        : [];

    let rowsEntreprise = [
      [
        {
          text: 'Raison sociale',
          options: {
            y: 0.3,
            rowH: 0.2,
            align: 'left',
            fontSize: 7,
            fill: '003b75',
            color: 'FFFFFF',
            fontFace: 'Roboto',
          },
        },
        {
          text: 'Secteur',
          options: {
            y: 0.3,
            rowH: 0.2,
            align: 'left',
            fontSize: 7,
            fill: '003b75',
            color: 'FFFFFF',
            fontFace: 'Roboto',
          },
        },
        {
          text: 'Activité',
          options: {
            y: 0.3,
            rowH: 0.2,
            align: 'left',
            fontSize: 7,
            fill: '003b75',
            color: 'FFFFFF',
            fontFace: 'Roboto',
          },
        },
        {
          text: 'Commune',
          options: {
            y: 0.3,
            rowH: 0.2,
            align: 'left',
            fontSize: 7,
            fill: '003b75',
            color: 'FFFFFF',
            fontFace: 'Roboto',
          },
        },
        {
          text: 'Département',
          options: {
            y: 0.3,
            rowH: 0.2,
            align: 'left',
            fontSize: 7,
            fill: '003b75',
            color: 'FFFFFF',
            fontFace: 'Roboto',
          },
        },
      ],
      ...entrepriseRow,
    ];

    slideEntreprises.addTable(rowsEntreprise, {
      ...tableOption,
      align: 'left',
      colW: [2, 2, 2, 2, 1.5],
    });

    //REPARTITION PAR SECTEUR
    const entrepriseParSecteurTitlte = 'ENTREPRISE - Répartition par secteur';
    const secteur =
      entites?.data && entites?.data?.length
        ? entites.data.map((el: any) =>
            el['Secteur'] ? el['Secteur'] : 'Non renseigné'
          )
        : [];

    this.createPPT_chartBar(
      pres,
      logo,
      entrepriseParSecteurTitlte,
      secteur,
      {
        ...slideTitleOptions,
        x: 1.5,
        y: 0.2,
        fontSize: 16,
      },
      'Entreprises'
    );

    let slideEntreprisesSecteurPie = pres.addSlide({
      masterName: 'MASTER_SLIDE',
      sectionTitle: 'Entreprises',
    });
    slideEntreprisesSecteurPie.addText(entrepriseParSecteurTitlte, {
      ...slideTitleOptions,
      x: 1.5,
      y: 0.2,
      fontSize: 16,
    });

    let dataChartPieEntrepriseSecteur = [
      {
        name: 'Réparticipant par secteur',
        labels:
          secteur && secteur?.length
            ? Object.keys(this.convertDATA(secteur))
            : null,
        values:
          secteur && secteur?.length
            ? Object.values(this.convertDATA(secteur))
            : null,
      },
    ];

    slideEntreprisesSecteurPie.addChart(
      pres.ChartType.pie,
      dataChartPieEntrepriseSecteur,

      {
        x: 1,
        y: 0.6,
        w: 4,
        h: 3,
        dataTableFontSize: 7,
        showLegend: true,
        showTitle: true,
        title: 'Répartition par secteur',
        titleFontSize: 10,
      }
    );

    //REPARTITION PAR ACTIVITÉ
    const entrepriseParActivityTitlte = 'ENTREPRISE - Répartition par activité';
    const activity =
      entites?.data && entites?.data?.length
        ? entites.data.map((el: any) =>
            el['Activité'] ? el['Activité'] : 'Non renseigné'
          )
        : [];

    this.createPPT_chartBar(
      pres,
      logo,
      entrepriseParActivityTitlte,
      activity,
      {
        ...slideTitleOptions,
        x: 1.5,
        y: 0.2,
        fontSize: 16,
      },
      'Entreprises'
    );

    let slideEntreprisesActivityPie = pres.addSlide({
      masterName: 'MASTER_SLIDE',
      sectionTitle: 'Entreprises',
    });
    slideEntreprisesActivityPie.addText(entrepriseParActivityTitlte, {
      ...slideTitleOptions,
      x: 1.5,
      y: 0.2,
      fontSize: 16,
    });

    let dataChartPieEntrepriseActivity = [
      {
        name: 'Réparticipant par activité',
        labels:
          activity && activity?.length
            ? Object.keys(this.convertDATA(activity))
            : null,
        values:
          activity && activity?.length
            ? Object.values(this.convertDATA(activity))
            : null,
      },
    ];

    slideEntreprisesActivityPie.addChart(
      pres.ChartType.pie,
      dataChartPieEntrepriseActivity,

      {
        x: 1,
        y: 0.6,
        w: 4,
        h: 3,
        dataTableFontSize: 7,
        showLegend: true,
        showTitle: true,
        title: 'Répartition par activité',
        titleFontSize: 10,
      }
    );

    //REPARTITION PAR CATEGORIE
    const entrepriseParCategorieTitlte =
      'ENTREPRISE - Répartition par catégorie';
    const categorie =
      entites?.data && entites?.data?.length
        ? entites.data.map((el: any) =>
            el['Catégorie'] ? el['Catégorie'] : 'Non renseigné'
          )
        : [];

    this.createPPT_chartBar(
      pres,
      logo,
      entrepriseParCategorieTitlte,
      categorie,
      {
        ...slideTitleOptions,
        x: 1.5,
        y: 0.2,
        fontSize: 16,
      },
      'Entreprises'
    );

    let slideEntreprisesCategoriePie = pres.addSlide({
      masterName: 'MASTER_SLIDE',
      sectionTitle: 'Entreprises',
    });

    let dataChartPieEntrepriseCategorie = [
      {
        name: 'Réparticipant par catégorie',
        labels:
          categorie && categorie?.length
            ? Object.keys(this.convertDATA(categorie))
            : null,
        values:
          categorie && categorie?.length
            ? Object.values(this.convertDATA(categorie))
            : null,
      },
    ];

    slideEntreprisesCategoriePie.addText(entrepriseParCategorieTitlte, {
      ...slideTitleOptions,
      x: 1.5,
      y: 0.2,
      fontSize: 16,
    });

    slideEntreprisesCategoriePie.addChart(
      pres.ChartType.pie,
      dataChartPieEntrepriseCategorie,

      {
        x: 1,
        y: 0.6,
        w: 4,
        h: 3,
        dataTableFontSize: 7,
        showLegend: true,
        showTitle: true,
        title: 'Répartition par catégorie',
        titleFontSize: 10,
      }
    );

    //REPARTITION PAR COMMUNE
    const entrepriseParCommuneTitlte = 'ENTREPRISE - Répartition par commune';
    const commune =
      entites?.data && entites?.data?.length
        ? entites.data.map((el: any) =>
            el['Commune'] ? el['Commune'] : 'Non renseigné'
          )
        : [];

    this.createPPT_chartBar(
      pres,
      logo,
      entrepriseParCommuneTitlte,
      commune,
      {
        ...slideTitleOptions,
        x: 1.5,
        y: 0.2,
        fontSize: 16,
      },
      'Entreprises'
    );

    let slideEntreprisesCommunePie = pres.addSlide({
      masterName: 'MASTER_SLIDE',
      sectionTitle: 'Entreprises',
    });

    let dataChartPieEntrepriseCommune = [
      {
        name: 'Réparticipant par commune',
        labels:
          commune && commune?.length
            ? Object.keys(this.convertDATA(commune))
            : null,
        values:
          commune && commune?.length
            ? Object.values(this.convertDATA(commune))
            : null,
      },
    ];

    slideEntreprisesCommunePie.addText(entrepriseParCommuneTitlte, {
      ...slideTitleOptions,
      x: 1.5,
      y: 0.2,
      fontSize: 16,
    });

    slideEntreprisesCommunePie.addChart(
      pres.ChartType.pie,
      dataChartPieEntrepriseCommune,
      {
        x: 1,
        y: 0.6,
        w: 4,
        h: 3,
        dataTableFontSize: 7,
        showLegend: true,
        showTitle: true,
        title: 'Répartition par commune',
        titleFontSize: 10,
      }
    );

    //REPARTITION PAR DEPARTEMENT
    const entrepriseParDepartementTitlte =
      'ENTREPRISE - Répartition par département';
    const departement =
      entites?.data && entites?.data?.length
        ? entites.data.map((el: any) =>
            el['Département'] ? el['Département'] : 'Non renseigné'
          )
        : [];

    this.createPPT_chartBar(
      pres,
      logo,
      entrepriseParDepartementTitlte,
      departement,
      {
        ...slideTitleOptions,
        x: 1.5,
        y: 0.2,
        fontSize: 16,
      },
      'Entreprises'
    );

    let slideEntreprisesDepartementPie = pres.addSlide({
      masterName: 'MASTER_SLIDE',
      sectionTitle: 'Entreprises',
    });

    let dataChartPieEntrepriseDepartement = [
      {
        name: 'Réparticipant par département',
        labels:
          departement && departement?.length
            ? Object.keys(this.convertDATA(departement))
            : null,
        values:
          departement && departement?.length
            ? Object.values(this.convertDATA(departement))
            : null,
      },
    ];

    slideEntreprisesDepartementPie.addText(entrepriseParDepartementTitlte, {
      ...slideTitleOptions,
      x: 1.5,
      y: 0.2,
      fontSize: 16,
    });

    slideEntreprisesDepartementPie.addChart(
      pres.ChartType.pie,
      dataChartPieEntrepriseDepartement,
      {
        x: 1,
        y: 0.6,
        w: 4,
        h: 3,
        dataTableFontSize: 7,
        showLegend: true,
        showTitle: true,
        title: 'Répartition par département',
        titleFontSize: 10,
      }
    );
  }

  modules(
    pres: any,
    tableOption: any,
    slideTitle: any,
    modules: any,
    subtitleY: any
  ): void {
    pres.addSection({ title: `Modules` });
    //SLIDE MODULES
    let slideModules = pres.addSlide({
      masterName: 'MASTER_SLIDE',
      sectionTitle: 'Modules',
    });

    slideModules.addText('MODULES', {
      ...slideTitle,
    });

    slideModules.addText(
      `${modules?.data && modules?.data?.length ? modules?.data?.length : 0}`,
      {
        x: 0.3,
        y: subtitleY,
        w: 1.5,
        h: 0.3,
        align: 'center',
        fontFace: 'Roboto',
      }
    );

    slideModules.addText(
      `
${
  modules?.data && modules?.data?.length
    ? `${
        modules?.data && modules?.data?.length > 1
          ? 'modules réalisés'
          : 'module réalisé'
      }`
    : `Aucun module réalisée}`
}`,
      {
        x: 0.5,
        y: subtitleY,
        bold: true,
        fontSize: 14,
        color: 'ffab00',
      }
    );

    let modulesRowData =
      modules?.data && modules?.data?.length
        ? modules.data.map((el: any) => {
            const moduleTable = [
              {
                text: el['Titre'],
                options: {
                  y: 0.4,
                  align: 'left',
                  fontSize: 8,
                  fontFace: 'Roboto',
                },
              },
              {
                text: el['Type'],
                options: {
                  y: 0.4,
                  align: 'left',
                  fontSize: 8,
                  fontFace: 'Roboto',
                },
              },
              {
                text: `${el['Démarrage'] ? el['Démarrage'] : ''}`,
                options: {
                  y: 0.4,
                  align: 'left',
                  fontSize: 8,
                  fontFace: 'Roboto',
                },
              },
              {
                text: `${el['Échéance'] ? el['Échéance'] : ''}`,
                options: {
                  y: 0.4,
                  align: 'left',
                  fontSize: 8,
                  fontFace: 'Roboto',
                },
              },
              {
                text:
                  el?.['Actions'] && el?.['Actions']?.length
                    ? el['Actions']?.length
                    : 0,
                options: {
                  y: 0.4,
                  fontSize: 8,
                  bold: true,
                  fontFace: 'Roboto',
                  w: 0.15,
                  h: 0.15,
                  align: 'center',
                },
              },
            ];

            return moduleTable;
          })
        : [];

    const moduleTableRow = [
      [
        {
          text: 'Intituté',
          options: {
            y: 0.3,
            rowH: 0.2,
            align: 'left',
            fontSize: 7,
            fill: '003b75',
            color: 'FFFFFF',
            fontFace: 'Roboto',
          },
        },
        {
          text: 'Type',
          options: {
            y: 0.3,
            rowH: 0.2,
            align: 'left',
            fontSize: 7,
            fill: '003b75',
            color: 'FFFFFF',
            fontFace: 'Roboto',
          },
        },
        {
          text: 'Démarrage',
          options: {
            y: 0.3,
            rowH: 0.2,
            align: 'left',
            fontSize: 7,
            fill: '003b75',
            color: 'FFFFFF',
            fontFace: 'Roboto',
          },
        },
        {
          text: 'Échéance',
          options: {
            y: 0.3,
            rowH: 0.2,
            align: 'left',
            fontSize: 7,
            fill: '003b75',
            color: 'FFFFFF',
            fontFace: 'Roboto',
          },
        },

        {
          text: 'Actions',
          options: {
            y: 0.3,
            rowH: 0.2,
            align: 'left',
            fontSize: 7,
            fill: '003b75',
            color: 'FFFFFF',
            fontFace: 'Roboto',
          },
        },
      ],
      ...modulesRowData,
    ];

    slideModules.addTable(moduleTableRow, {
      ...tableOption,
      align: 'left',
      colW: [3.5, 2, 1.5, 1.5, 1],
    });
  }
}
