import { Observable, of, BehaviorSubject } from 'rxjs';
import { ParticipantSelectState } from './../store/participants-select.reducer';
import * as fromParticipantAction from './../store/participants-select.actions';
import * as fromParticipantSelector from './../store/participants-select.selectors';

import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { ParticipantSmallId } from 'src/app/components/participant/store/participant.model';

@Component({
  selector: 'app-participants-select',
  template: ` <nz-select
    nzShowSearch
    nzBorderless
    nzServerSearch
    [nzPlaceHolder]="placeholder"
    [(ngModel)]="selectedValue"
    [nzShowArrow]="false"
    (nzOnSearch)="filtersByLastName($event)"
    (ngModelChange)="onSelect($event)"
  >
    <nz-option
      *ngFor="let o of participants$ | async"
      [nzLabel]="o.lastName"
      [nzValue]="o"
      nzCustomContent
    >
      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col nzSpan="4">
          <nz-avatar nzIcon="user"></nz-avatar>
        </nz-col>
        <nz-col nzSpan="10">
          {{ o.civilite }}. {{ o.lastName | uppercase }}
          {{ o.firstName | titlecase }}
        </nz-col>
      </nz-row>
    </nz-option>
  </nz-select>`,
  styles: [
    `
      nz-select {
        width: 100%;
        background-color: #f5f5f5;
        border-radius: 12px;
      }

      nz-avatar {
        background-color: #d6e4ff;
      }
    `,
  ],
})
export class ParticipantsSelectComponent implements OnInit {
  participants$: Observable<ParticipantSmallId[] | any> = of(null);
  selectedValue: any;
  search$ = new BehaviorSubject<string>('');
  @Input() placeholder: string = 'Rechercher un participant...';
  @Output() cancel = new EventEmitter<boolean>(false);
  @Output() select = new EventEmitter<ParticipantSmallId | any>(false);
  constructor(private participantsSelectStore: Store<ParticipantSelectState>) {}

  ngOnInit(): void {
    this.getParticipants();
  }

  getParticipants(): void {
    this.participants$ = this.participantsSelectStore.select(
      fromParticipantSelector.participants
    );
  }

  filtersByLastName(event: string): void {
    if (!event) {
      return;
    } else {
      const type = 'lastname';

      this.participantsSelectStore.dispatch(
        fromParticipantAction.queryParticipants({
          filter: event,
          filterType: type,
        })
      );
    }
  }

  onSelect(participant: ParticipantSmallId): void {
    if (!participant) return;
    this.select.emit(participant);
  }

  onCancel(): void {
    this.cancel.emit(true);
  }
}
