import { takeUntil } from 'rxjs/operators';
import { OperationId } from './../../../operation/store/operation.model';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { of, Observable, Subject, BehaviorSubject } from 'rxjs';
import { OperationDashboardState } from '../../store/operation-dashboard.reducer';
import * as fromOperationDashboardAction from '../../store/operation-dashboard.actions';
import * as fromOperationDashboardSelector from '../../store/operation-dashboard.selectors';
import { OperationState } from 'src/app/components/operation/store/operation.reducer';
import {
  chunk,
  first,
  flatten,
  flattenDeep,
  groupBy,
  isNull,
  isUndefined,
  last,
  sum,
} from 'lodash';
import { fade } from 'src/app/app-animation';
import {
  add,
  format,
  fromUnixTime,
  isAfter,
  isBefore,
  max,
  min,
  sub,
} from 'date-fns';
import { fr } from 'date-fns/locale';

@Component({
  selector: 'app-operation-dashboard',
  templateUrl: './operation-dashboard.component.html',
  styleUrls: ['./operation-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fade],
})
export class OperationDashboardComponent
  implements OnInit, OnChanges, OnDestroy
{
  dashboard$: Observable<any> = of(null);
  error: Observable<any> = of(null);
  subscribe = new Subject();
  noExist = 'Non renseigné';
  view$ = new BehaviorSubject<string>('operation');
  @Input() operation$: Observable<OperationId | any> = of();

  //INTERVENATS
  contacts$ = new BehaviorSubject<any>([]);

  //FULLSCREEN REFERENCES
  @ViewChild('chartsContainerRef', { static: false })
  chartsContainerRef: ElementRef = new ElementRef<any>(false);

  constructor(
    private operationDashboardStore: Store<OperationDashboardState>
  ) {}

  ngOnInit(): void {
    this.getDashboard();
    this.loadDashboad();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  onSelect(type: string): void {
    if (!type) return;
    this.view$.next(type);
  }

  getDashboard(): void {
    this.dashboard$ = this.operationDashboardStore.select(
      fromOperationDashboardSelector.dashboard
    );
  }

  //INTERVENANTS
  setIntervenatDashboard(): void {
    this.dashboard$.pipe(takeUntil(this.subscribe)).subscribe((dashboard) => {
      if (!dashboard) return;

      const contacts: any[] = dashboard?.contacts?.data;

      if (!contacts?.length) return;
      this.contacts$.next(contacts);
    });
  }

  //UTILS

  chartsValues = (statistiques: any) => {
    const values = statistiques
      ? Object.values(statistiques).map((item) => {
          if (isNull(item) || isUndefined(item) || !item) {
            return this.noExist;
          }
          return item;
        })
      : [];

    const labels = statistiques
      ? Object.keys(statistiques).map((item) => {
          if (isNull(item) || isUndefined(item) || !item) {
            return this.noExist;
          }
          return item;
        })
      : [];

    return { values, labels };
  };

  itemsCounter = (items: any[]) => {
    return items.reduce((acc, cur) => {
      const curSize = items.filter((el) => el === cur).length;

      acc[cur] = curSize;
      return acc;
    }, {});
  };

  chartsTypePie = (count: any): any => {
    const transformToArray: any[] = Object.entries(count);
    const transformForChart: any[] = transformToArray.map((el) => ({
      name: el[0],
      value: el[1],
    }));

    return transformForChart;
  };

  getError(): void {
    this.error = this.operationDashboardStore.select(
      fromOperationDashboardSelector.error
    );
  }

  loadDashboad(): void {
    this.operation$.pipe(takeUntil(this.subscribe)).subscribe((operation) => {
      if (!operation) return;
      this.operationDashboardStore.dispatch(
        fromOperationDashboardAction.loadOperationDashboard({
          operationId: operation.id,
        })
      );
    });
  }
}
