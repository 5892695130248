<ng-container *ngIf="mission$ | async as mission">
  <nz-page-header
    class="site-page-header"
    (nzBack)="onBack()"
    nzBackIcon
    [nzTitle]="mission?.metier?.metier?.libelle | uppercase"
    [nzSubtitle]="mission?.metier?.metier?.code | uppercase"
  >
    <nz-page-header-content>
      <nz-descriptions nzSize="small" [nzColumn]="2">
        <nz-descriptions-item nzTitle="Date de création" [nzSpan]="1">{{
          mission?.dateStart | dateFr : "small"
        }}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="Référent" [nzSpan]="1">{{
          mission?.user?.displayName
        }}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="Démarrage" [nzSpan]="1">{{
          mission?.startMission | dateFr : "small"
        }}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="Échéance" [nzSpan]="1">{{
          mission?.endMission | dateFr : "small"
        }}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="Participant" [nzSpan]="1">
          {{ mission?.participant | displayName }}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Entité" [nzSpan]="1">{{
          mission?.entite?.denomination
        }}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="Module" [nzSpan]="1">
          <nz-tag nzColor="geekblue">{{ mission?.module?.title }}</nz-tag>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Statut" [nzSpan]="1">
          <nz-tag nzColor="orange" *ngIf="!mission?.isFinish">En cours</nz-tag>
          <nz-tag nzColor="green" *ngIf="mission?.isFinish">Terminé</nz-tag>
        </nz-descriptions-item>
      </nz-descriptions>
    </nz-page-header-content>

    <nz-page-header-extra>
      <app-button-dropdown
        [isUpdate]="true"
        [isDelete]="true"
        [isExportPDF]="true"
        (select)="dropdownSelect($event, mission)"
        (click)="$event.preventDefault()"
      ></app-button-dropdown>
    </nz-page-header-extra>
  </nz-page-header>
  <app-item-tabset
    [tabs]="[
      {
        name: 'Heures',
        template: heuresTpl
      },
      {
        name: 'Contrat',
        template: contratTpl
      },
      {
        name: 'Employeur',
        template: null
      },
      {
        name: 'Prescripteur',
        template: null
      },
      {
        name: 'Suivis',
        template: suiviTpl
      },
      {
        name: 'Documents',
        template: documentTpl
      }
    ]"
  ></app-item-tabset>

  <ng-template #heuresTpl>
    <app-missions-hours
      [total]="mission?.total"
      [totalWork]="mission?.totalWork"
      [totalTraining]="mission?.totalTraining"
      [planing$]="planing$"
      [updateLoading$]="updateLoading$"
      (update)="onUpdateHours($event, mission)"
      (click)="$event.preventDefault()"
    ></app-missions-hours>
  </ng-template>

  <ng-template #suiviTpl>
    <app-table-suivis [source]="suivisSource$ | async"></app-table-suivis>
  </ng-template>
  <ng-template #documentTpl>
    <app-table-documents [source$]="documentsSource$"></app-table-documents>
  </ng-template>
  <ng-template #contratTpl>
    <h2>CONTRAT DETAILS</h2>
  </ng-template>

  <ng-template #moduleSuivis> </ng-template>

  <ng-template #moduleDocuments> </ng-template>
</ng-container>
