<ng-container *ngIf="note$ | async as note">
  <ng-container *ngIf="subtitle$ | async as subtitle">
    <nz-row nzAlign="top" nzGutter="4" nzJustify="center">
      <nz-col nzSpan="24">
        <app-text-edithing
          *ngIf="subtitle$ | async as subtitle"
          (add)="onTextChanges($event)"
        ></app-text-edithing>
      </nz-col>
    </nz-row>

    <nz-divider nzType="horizontal" nzOrientation="center"></nz-divider>
    <nz-row nzAlign="top" nzGutter="4" nzJustify="end">
      <nz-col nzSpan="16"></nz-col>
      <nz-col nzSpan="4">
        <button
          nz-button
          nzType="text"
          nzBlock
          (click)="$event.stopPropagation(); onCancel()"
        >
          Annuler
        </button>
      </nz-col>
      <nz-col nzSpan="4">
        <button
          nz-button
          nzType="primary"
          nzBlock
          (click)="$event.stopPropagation(); onAdd(note, subtitle)"
          [disabled]="!sujetForm.valid"
        >
          Ajouter
        </button>
      </nz-col>
    </nz-row>
  </ng-container>
</ng-container>
