<nz-list>
  <nz-list-item>
    <nz-skeleton [nzActive]="active" [nzAvatar]="avatar"> </nz-skeleton>
  </nz-list-item>
  <nz-list-item>
    <nz-skeleton [nzActive]="active" [nzAvatar]="avatar"> </nz-skeleton>
  </nz-list-item>
  <nz-list-item>
    <nz-skeleton [nzActive]="active" [nzAvatar]="avatar"> </nz-skeleton>
  </nz-list-item>
  <nz-list-item>
    <nz-skeleton [nzActive]="active" [nzAvatar]="avatar"> </nz-skeleton>
  </nz-list-item>
</nz-list>
