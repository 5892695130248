import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { NzModalService } from 'ng-zorro-antd/modal';
import { FicheDePosteState } from '../../store/fiche-de-poste.reducer';
import { FichesDePosteFormComponent } from '../fiches-de-poste-form/fiches-de-poste-form.component';
import * as fromFicheDePosteSelctor from '../../store/fiche-de-poste.selectors';
import * as fromFicheDePosteAction from '../../store/fiche-de-poste.actions';
import { Observable, of } from 'rxjs';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';
import { FichesDePosteFormExportComponent } from '../fiches-de-poste-form-export/fiches-de-poste-form-export.component';
import { GeoDistanceCoordonnees } from 'src/app/contents/components/geo-territoire/store/geo-territoire.model';
import { DiffusionFormComponent } from 'src/app/features/diffusion/components/diffusion-form/diffusion-form.component';
import { Update } from '@ngrx/entity';
import { CandidatureExportComponent } from 'src/app/features/candidature/components/candidature-export/candidature-export.component';
import { CandidatureFormComponent } from 'src/app/features/candidature/components/candidature-form/candidature-form.component';
import { ItemEventComponent } from 'src/app/features/suivi/components/item-event/item-event.component';

@Component({
  selector: 'app-fiches-de-poste-item',
  templateUrl: './fiches-de-poste-item.component.html',
  styleUrls: ['./fiches-de-poste-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FichesDePosteItemComponent implements OnInit, OnChanges {
  user$: Observable<any> = this.useStore.select(fromUserSelector.user);
  currentTabView: string = 'candidatures';
  @Input() users: any;

  modalUpdateWidth: number = 1050;

  //FICHE DE POSTE
  title: string = 'Exporter la fiche de poste';
  titleUpdate: string = 'Modifier la fiche de poste';
  @ViewChild('ficheDePosteExportTitle', { static: false })
  ficheDePosteExportTitle: TemplateRef<any> | any;
  @ViewChild('ficheDePosteFormTitle', { static: false })
  ficheDePosteFormTitle: TemplateRef<any> | any;

  //CANDIDAT
  titleCandidat: string = 'Nouveau candidat';
  titleCandidatsExport: string = 'Exporter la liste des candidatures';

  @ViewChild('ficheDePosteCandidatFormTitle', { static: false })
  ficheDePosteCandidatFormTitle: TemplateRef<any> | any;
  @ViewChild('ficheDePosteCandidatsExportFormTitle', { static: false })
  ficheDePosteCandidatsExportFormTitle: TemplateRef<any> | any;

  //NOTE
  titleNote: string = 'Nouvelle note';
  noteDescription: string = 'Aucune note trouvée';
  noteDefinition: string =
    'Une note est un commentaire, une description ou un suivi des événements';
  @ViewChild('noteFormTitle', { static: false })
  noteFormTitle: TemplateRef<any> | any;

  //TASK

  //DIFUSION
  titleDiffusion: string = 'Nouvelle diffusion';
  @ViewChild('diffusionFormTitle', { static: false })
  diffusionFormTitle: TemplateRef<any> | any;

  //VARIABLES REACTIVES

  @Input() fiche: any;
  @Input() notes: any = [];
  @Input() tasks: any;
  @Input() diffusions: any[] = [];
  @Input() notes$: Observable<any>;
  @Input() tasks$: Observable<any>;

  @Input() operation: any;
  @Input() isOperation: boolean;
  @Input() candidats: any[] = [];
  @Input() candidatsTemplate: TemplateRef<any> | any;

  @Input() candidatsLoading$: Observable<boolean> = of(false);
  @Input() diffusionsLoading$: Observable<boolean> = of(false);
  @Input() noteLoading$: Observable<boolean> = of(false);
  @Input() tasksLoading$: Observable<boolean> = of(false);

  @Output() update = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Output() back = new EventEmitter();

  //CANDIDATS
  @Output() addCandidats = new EventEmitter();
  @Output() updateCandidat = new EventEmitter();
  @Output() deleteCandidat = new EventEmitter();
  @Output() deleteCandidats = new EventEmitter();
  @Output() tabsChanges = new EventEmitter();

  //DIFFUSIONS
  @Output() addDiffusion = new EventEmitter();
  @Output() updateDiffusion = new EventEmitter();
  @Output() deleteDiffusion = new EventEmitter();
  @Output() deleteDiffusions = new EventEmitter();

  //NOTES
  @Output() addNote = new EventEmitter();
  @Output() updateNote = new EventEmitter();
  @Output() deleteNote = new EventEmitter();
  @Output() deleteNotes = new EventEmitter();

  //TASKS
  @Output() addTask = new EventEmitter();
  @Output() updateTask = new EventEmitter();
  @Output() deleteTask = new EventEmitter();
  @Output() deleteTasks = new EventEmitter();

  constructor(
    private useStore: Store<UserState>,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private ficheDePosteStore: Store<FicheDePosteState>
  ) {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {}

  getUser(): void {
    this.user$ = this.useStore.select(fromUserSelector.user);
  }

  onChangesTabs(event: any): void {
    this.tabsChanges.emit(event);
    this.currentTabView = event;
  }

  onNewActions(
    event: any,
    fiche: any,
    user: any,
    operation: any,
    users: any
  ): void {
    switch (event) {
      case 'Candidat':
        const adresseMission: GeoDistanceCoordonnees = {
          latitude: fiche?.mission?.adresse?.adresse?.geometry?.coordinates[0],
          longitude: fiche?.mission?.adresse?.adresse?.geometry?.coordinates[1],
        };
        this.onNewCandidat(adresseMission, fiche);
        break;
      case 'Note':
        this.onNewNote(fiche, users);
        break;
      case 'Diffusion':
        this.onNewDiffusion(fiche, users);
        break;
      case 'Tâche':
        break;

      default:
        break;
    }
  }

  onExportActions(
    event: any,
    fiche: any,
    user: any,
    operation: any,
    candidats: any[],
    users: any[]
  ): void {
    switch (event) {
      case 'Fiche de poste':
        this.onExportModal(fiche, user, users);
        break;
      case 'Candidats':
        this.onExportCandidatsModal(fiche, user, candidats, users);
        break;
      default:
        break;
    }
  }

  onDropdown(
    event: any,
    fiche: any,
    user: any,
    operation: any,
    isOperation: boolean,
    users: any
  ): void {
    if (!event) return;
    switch (event) {
      case 'update':
        this.onModifyFiche(fiche, user, operation, isOperation);
        break;
      case 'exportPDF':
        this.onExportModal(fiche, user, users);
        break;
      case 'delete':
        this.onDelete(fiche);
        break;
      default:
        break;
    }
  }

  onExportModal(fiche: any, user: any, users: any): void {
    if (!fiche || !user) return;

    const modal = this.modal.create({
      nzContent: FichesDePosteFormExportComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalUpdateWidth,
      nzFooter: [],
      nzTitle: this.ficheDePosteExportTitle,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
    });
    const instance = modal.getContentComponent();
    instance.users = users;
    instance.cancel.subscribe((): any => {
      modal.close();
    });
    instance.export.subscribe((options: any): any => {
      if (!options) {
        return null;
      } else {
        this.onExport(fiche, user, options);
        modal.close();
      }
    });
  }

  onExport(fiche: any, user: any, options: any): void {
    if (!fiche || !user) return;
    const { type, isAnonyme, referentRecrutement } = options;
    this.ficheDePosteStore.dispatch(
      fromFicheDePosteAction.exportFicheDePoste({
        fiche,
        user,
        exportType: type,
        isAnonyme: isAnonyme,
        referentRecrutement: referentRecrutement,
      })
    );
  }

  onModifyFiche(
    fiche: any,
    user: any,
    operation: any,
    isOperation: boolean
  ): void {
    if (!fiche) return;

    const modal = this.modal.create({
      nzContent: FichesDePosteFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalUpdateWidth,
      nzFooter: [],
      nzTitle: this.ficheDePosteFormTitle,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '10px' },
    });
    const instance = modal.getContentComponent();
    instance.fiche$.next(fiche);
    instance.operationItem = operation;
    instance.isOperation = isOperation;
    instance.cancel.subscribe((): any => {
      modal.close();
    });
    instance.update.subscribe((updateFiche: any): any => {
      if (!updateFiche) {
        return null;
      } else {
        this.onUpdate(fiche, updateFiche);
        modal.close();
      }
    });
  }

  onUpdate(fiche: any, updateFiche: any): void {
    const changes = {
      ...fiche,
      mission: {
        ...fiche.mission,
        ...updateFiche.mission,
        adresse: updateFiche.mission?.adresse
          ? updateFiche.mission.adresse
          : fiche.mission.adresse,
      },
      ...updateFiche,
    };

    const ficheUpdate: any = {
      id: fiche.id,
      ...changes,
    };

    delete ficheUpdate.auteur;
    delete ficheUpdate.userUpdate;
    delete ficheUpdate.dateStart;
    delete ficheUpdate.dateUpdate;
    delete ficheUpdate?.search;

    this.update.emit(ficheUpdate);
  }

  onDelete(fiche: any): void {
    if (!fiche) return;
    this.delete.emit(fiche);
  }

  onBack(): void {
    this.back.emit(true);
  }

  //CANDIDAT
  onNewCandidat(adresseMission: GeoDistanceCoordonnees, fiche: any): void {
    const modal = this.modal.create({
      nzContent: CandidatureFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalUpdateWidth,
      nzFooter: [],
      nzTitle: this.ficheDePosteCandidatFormTitle,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });
    const instance = modal.getContentComponent();
    instance.adresseMission = adresseMission;
    instance.cancel.subscribe((): any => {
      modal.close();
    });
    instance.add.subscribe((candidat: any): any => {
      if (!candidat) {
        return null;
      } else {
        this.onAddCandidat(candidat, fiche);
        modal.close();
      }
    });
  }

  onExportCandidatsModal(
    fiche: any,
    user: any,
    candidats: any[],
    users: any
  ): void {
    const modal = this.modal.create({
      nzContent: CandidatureExportComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalUpdateWidth,
      nzFooter: [],
      nzTitle: this.ficheDePosteCandidatsExportFormTitle,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
    });
    const instance = modal.getContentComponent();
    instance.fiche = fiche;
    instance.candidats = candidats;
    instance.cancel.subscribe((): any => {
      modal.close();
    });
    instance.export.subscribe((): any => {
      modal.close();
    });
  }

  onAddCandidat(candidat: any, fiche: any): void {
    if (!candidat || !fiche) return;
    this.addCandidats.emit({ candidat, fiche });
  }
  onUpdateCandidat(candidat: any, fiche: any): void {
    if (!candidat || !fiche) return;
    this.updateCandidat.emit({ candidat, fiche });
  }
  onDeleteCandidat(candidat: any, fiche: any): void {
    if (!candidat || !fiche) return;
    this.deleteCandidat.emit({ candidat, fiche });
  }
  onDeleteCandidats(event: any, fiche: any): void {
    if (!event) return;
    this.deleteCandidats.emit({ fiche });
  }

  //DIFFUSION

  onNewDiffusion(fiche: any, users: any): void {
    const modal = this.modal.create({
      nzContent: DiffusionFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalUpdateWidth,
      nzFooter: [],
      nzTitle: this.diffusionFormTitle,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });
    const instance = modal.getContentComponent();
    instance.users$ = of(users);
    instance.isAdresse = true;
    instance.isEtablissement = true;
    instance.cancel.subscribe((): any => {
      modal.close();
    });
    instance.add.subscribe((diffusion: any): any => {
      if (!diffusion) {
        return null;
      } else {
        this.onAddDiffusion(diffusion, fiche);
        modal.close();
      }
    });
    instance.update.subscribe((diffusion: any): any => {
      if (!diffusion) {
        return null;
      } else {
        this.onUpdateDiffusion(diffusion, fiche);
        modal.close();
      }
    });
  }

  onAddDiffusion(diffusion: any, fiche: any): void {
    const operation = {
      id: fiche.mission.operation.id,
      denomination: fiche.mission.operation.denomination,
      type: fiche.mission.operation.type,
      financeurs: fiche.mission.operation.financeurs?.length
        ? fiche.mission.operation.financeurs
            ?.map((el: any) => el?.uniteLegale?.denominationUniteLegale)
            .join(', ')
        : '',
    };

    const newDiffusion = {
      ...diffusion,
      operation: operation,
      adresse: fiche.mission.adresse,
      entiteDenomination: fiche.entite?.uniteLegale?.denominationUniteLegale,
      entiteSiret: fiche.entite?.siret,
      metierLibelle: fiche.mission.metier.metier.libelle,
      metierRome: fiche.mission.metier.metier.code,
      grangDomaineLibelle:
        fiche.mission.metier.metier.domaineProfessionnel?.grandDomaine?.libelle,
      domaineLibelle: fiche.mission.metier.metier.domaineProfessionnel?.libelle,
      contratTypeLibelle: fiche.mission.contrat.type?.libelle,
      contratNatureLibelle: fiche.mission.contrat.nature?.libelle,
      missionStart: fiche.mission.contrat?.startMission,
      missionEnd: fiche.mission.contrat?.endMission,
    };

    this.addDiffusion.emit(newDiffusion);
  }
  onUpdateDiffusion(diffusion: any, fiche: any): void {
    const operation = {
      id: fiche.mission.operation.id,
      denomination: fiche.mission.operation.denomination,
      type: fiche.mission.operation.type,
      financeurs: fiche.mission.operation.financeurs?.length
        ? fiche.mission.operation.financeurs
            ?.map((el: any) => el?.uniteLegale?.denominationUniteLegale)
            .join(', ')
        : '',
    };

    const newDiffusion = {
      ...diffusion,
      operation: operation,
      adresse: fiche.mission.adresse,
      entiteDenomination: fiche.entite?.uniteLegale?.denominationUniteLegale,
      entiteSiret: fiche.entite?.siret,
      metierLibelle: fiche.mission.metier.metier.libelle,
      metierRome: fiche.mission.metier.metier.code,
      grangDomaineLibelle:
        fiche.mission.metier.metier.domaineProfessionnel?.grandDomaine?.libelle,
      domaineLibelle: fiche.mission.metier.metier.domaineProfessionnel?.libelle,
      contratTypeLibelle: fiche.mission.contrat.type?.libelle,
      contratNatureLibelle: fiche.mission.contrat.nature?.libelle,
      missionStart: fiche.mission.contrat?.startMission,
      missionEnd: fiche.mission.contrat?.endMission,
    };

    const diffusionChanges: Update<any> = {
      id: diffusion.id,
      changes: newDiffusion,
    };

    this.updateDiffusion.emit(diffusionChanges);
  }
  onDeleteDiffusion(diffusion: any, fiche: any): void {
    const diffusionId: string = diffusion.id;
    const ficheId: string = fiche.id;

    this.deleteDiffusion.emit({ diffusionId, ficheId });
  }
  onDeleteDiffusions(fiche: any): void {
    const ficheId: string = fiche.id;

    this.deleteDiffusions.emit(ficheId);
  }

  //NOTE
  onNewNote(fiche: any, users: any): void {
    const modal = this.modal.create({
      nzContent: ItemEventComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalUpdateWidth,
      nzFooter: [],
      nzTitle: this.noteFormTitle,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });
    const instance = modal.getContentComponent();
    instance.cancel.subscribe((): any => {
      modal.close();
    });
    instance.add.subscribe((note: any): any => {
      if (!note) {
        return null;
      } else {
        this.onAddNoteFiche(note, fiche);
        modal.close();
      }
    });
    instance.update.subscribe((note: any): any => {
      if (!note) {
        return null;
      } else {
        this.onUpdateNoteFiche(note, fiche);
        modal.close();
      }
    });
  }

  onAddNoteFiche(note: any, fiche: any): void {
    if (!note || !fiche) return;
    this.addNote.emit(note);
  }
  onUpdateNoteFiche(note: any, fiche: any): void {
    if (!note || !fiche) return;

    const nextNote: Update<any> = {
      id: note.id,
      changes: { ...note },
    };
    this.updateNote.emit(nextNote);
  }
  onDeleteNoteFiche(note: any, fiche: any): void {
    if (!note || !fiche) return;
    const noteId: string = note.id;
    const ficheId: string = fiche.id;

    this.deleteNote.emit({ noteId, ficheId });
  }
  onDeleteAllNoteFiche(note: any, fiche: any): void {
    const ficheId: string = fiche.id;

    this.deleteNotes.emit(ficheId);
  }

  //TASK

  onAddTaskFiche(event: any): void {}
  onUpdateTaskFiche(event: any): void {}
  onDeleteTaskFiche(event: any): void {}
  onDeleteAllTaskFiche(event: any): void {}
}
