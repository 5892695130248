import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'any',
})
export class QueryGeoTerritoireCommunesServiceGQL extends Query<Response> {
  document = gql`
    query CommunesByDepartement($code: String!) {
      communesByDepartement(code: $code) {
        code
        nom
      }
    }
  `;
}
