<nz-card [nzTitle]="diagnosticCardTitlte">
  <nz-card [nzTitle]="'Rendez-vous'">
    Propositions de dates de prochains rendez-vous / rendez-vous avec le
    référent emploi ou autre
  </nz-card>
  <nz-space nzSize="small"></nz-space>
</nz-card>

<ng-template #diagnosticCardTitlte>
  <app-header-title [icon]="'calendar_month'"></app-header-title>
</ng-template>
