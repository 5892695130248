import {
  Component,
  OnInit,
  Output,
  Input,
  EventEmitter,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneComponent implements OnInit, OnChanges, OnDestroy {
  subscribe = new Subject();
  updateActivity = false;
  phoneForm = new UntypedFormControl(
    '',
    Validators.compose([
      Validators.maxLength(10),
      Validators.minLength(10),
      Validators.pattern('^[0-9]*$'),
    ])
  );
  @Input() update: any;
  @Input() required: boolean = false;
  @Input() type: string = '';
  @Input() borderless: boolean = true;

  @Output() phone = new EventEmitter<string>();
  @Output() clear = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit() {}
  ngOnChanges(changes: SimpleChanges): void {
    this.onUpdate(changes.update.currentValue);
  }

  setPhone(input: any) {
    this.phone.emit(input.target.value);
  }

  onClear(): void {
    this.phoneForm.reset();
    this.clear.emit(true);
  }

  onUpdate(item: any): void {
    if (!item) {
      this.phoneForm.reset();
    }

    if (!item?.mobile) {
      return;
    }

    if (item.mobile && this.type === 'Mobile') {
      this.phoneForm.patchValue(item.mobile);
    } else if (item.mobile && this.type === 'Fixe') {
      this.phoneForm.patchValue(item.fixe);
    }
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }
}
