import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Navigation } from './navigation.model';
import * as NavigationActions from './navigation.actions';

export const navigationsFeatureKey = 'navigations';

export interface NavigationState extends EntityState<Navigation> {
  messageDrawer: boolean;
}

export const adapter: EntityAdapter<Navigation> =
  createEntityAdapter<Navigation>();

export const initialState: NavigationState = adapter.getInitialState({
  messageDrawer: false,
});

export const reducer = createReducer(
  initialState,
  on(NavigationActions.addNavigation, (state, action) =>
    adapter.addOne(action.navigation, state)
  ),
  on(NavigationActions.upsertNavigation, (state, action) =>
    adapter.upsertOne(action.navigation, state)
  ),
  on(NavigationActions.addNavigations, (state, action) =>
    adapter.addMany(action.navigations, state)
  ),
  on(NavigationActions.upsertNavigations, (state, action) =>
    adapter.upsertMany(action.navigations, state)
  ),
  on(NavigationActions.updateNavigation, (state, action) =>
    adapter.updateOne(action.navigation, state)
  ),
  on(NavigationActions.updateNavigations, (state, action) =>
    adapter.updateMany(action.navigations, state)
  ),
  on(NavigationActions.deleteNavigation, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(NavigationActions.deleteNavigations, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(NavigationActions.loadNavigations, (state, action) =>
    adapter.setAll(action.navigations, state)
  ),

  //MESSAGES DRAWER
  on(NavigationActions.openMessageDrawer, (state, action) => {
    return {
      ...state,
      messageDrawer: action.event,
    };
  }),

  on(NavigationActions.clearNavigations, (state) => adapter.removeAll(state))
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
