import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  OperationCandidatureState,
  operationFicheDePosteCandidaturesFeatureKey,
  selectAll,
} from './operation-fiche-de-poste-candidature.reducer';

export const selectOperationCandidatureState =
  createFeatureSelector<OperationCandidatureState>(
    operationFicheDePosteCandidaturesFeatureKey
  );

export const candidats = createSelector(
  selectOperationCandidatureState,
  selectAll
);

export const loading = createSelector(
  selectOperationCandidatureState,
  (state: OperationCandidatureState) => state.loading
);
export const error = createSelector(
  selectOperationCandidatureState,
  (state: OperationCandidatureState) => state.error
);

export const view = createSelector(
  selectOperationCandidatureState,
  (state: OperationCandidatureState) => state.view
);

export const documents = createSelector(
  selectOperationCandidatureState,
  (state: OperationCandidatureState) => state.documents
);
export const candidat = createSelector(
  selectOperationCandidatureState,
  (state: OperationCandidatureState) => state.candidat
);
