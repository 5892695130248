import { ParticipantListComponent } from './components/participant-list/participant-list.component';
import { ParticipantImpactsComponent } from './../participant-impacts/components/participant-impacts/participant-impacts.component';
import { ParticipantDiagnosticItemComponent } from './../participant-diagnostic/components/participant-diagnostic-item/participant-diagnostic-item.component';
import { ParticipantDiagnosticTableComponent } from './../participant-diagnostic/components/participant-diagnostic-table/participant-diagnostic-table.component';
import { ParticipantActionsResolverService } from '../../resolvers/participant/actions/participant-actions-resolver.service';
import { ParticipantActionsAccompagnementItemComponent } from './../participant-actions-accompagnement/components/participant-actions-accompagnement-item/participant-actions-accompagnement-item.component';
import { ParticipantResolverService } from '../../resolvers/participant/participant-resolver.service';
import { ParticipantItemComponent } from './components/participant-item/participant-item.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AuthGuard,
  hasCustomClaim,
  redirectUnauthorizedTo,
  redirectLoggedInTo,
} from '@angular/fire/auth-guard';
import { ParticipantActionAccompagnementTableComponent } from '../participant-actions-accompagnement/components/participant-action-accompagnement-table/participant-action-accompagnement-table.component';
import { ParticipantActionResolverService } from 'src/app/resolvers/participant/actions/participant-action-resolver.service';
import { ParticipantSuiviComponent } from '../participant-suivi/components/participant-suivi/participant-suivi.component';
import { ParticipantDocumentsComponent } from '../participant-documents/components/participant-documents/participant-documents.component';
import { ParticipantPlanningComponent } from '../participant-planning/components/participant-planning/participant-planning.component';
import { ParticipantDashboardComponent } from '../participant-dashboard/components/participant-dashboard/participant-dashboard.component';
import { ParticipantSuivisResolverService } from 'src/app/resolvers/participant/suivis/participant-suivis-resolver.service';
import { ParticipantDocumentsResolverService } from 'src/app/resolvers/participant/documents/participant-documents-resolver.service';
import { ShellComponent } from '../shell/shell.component';
import { ParticipantsResolverService } from 'src/app/resolvers/participant/participants-resolver.service';
const adminOnly = () => hasCustomClaim('admin');
const devOnly = () => hasCustomClaim('dev');
const clientOnly = () => hasCustomClaim('client');
const managerOnly = () => hasCustomClaim('manager');
const memberOnly = () => hasCustomClaim('member');
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToItems = () => redirectLoggedInTo(['items']);
//const belongsToAccount = (next) => hasCustomClaim(`account-${next.params.id}`);

const routes: Routes = [
  // {
  //   path: '',
  //   component: ShellComponent,
  //   children: [
  //   ],
  // },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ParticipantRoutingModule {}
