import { EntiteSuiviComponent } from './components/entite-suivi/entite-suivi.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromEntiteSuivi from './store/entite-suivi.reducer';
import { EffectsModule } from '@ngrx/effects';
import { EntiteSuiviEffects } from './store/entite-suivi.effects';
import { EntiteSuivisService } from 'src/app/service/entite-suivis.service';
import { ContentsModule } from 'src/app/contents/contents.module';
import { LayoutModule } from '@angular/cdk/layout';
import { SuiviModule } from 'src/app/features/suivi/suivi.module';

@NgModule({
  declarations: [EntiteSuiviComponent],
  imports: [
    CommonModule,
    ContentsModule,
    LayoutModule,
    SuiviModule,
    StoreModule.forFeature(
      fromEntiteSuivi.entiteSuivisFeatureKey,
      fromEntiteSuivi.reducer
    ),
    EffectsModule.forFeature([EntiteSuiviEffects]),
  ],
  providers: [EntiteSuivisService],
  exports: [EntiteSuiviComponent],
})
export class EntiteSuiviModule {}
