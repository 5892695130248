import { ParticipantDiagnosticExportService } from './../../service/participant/diagnostic/participant-diagnostic-export.service';
import { RouterModule } from '@angular/router';
import { ParticipantDiagnosticService } from './../../service/participant/diagnostic/participant-diagnostic.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromParticipantDiagnostic from './store/participant-diagnostic.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ParticipantDiagnosticEffects } from './store/participant-diagnostic.effects';
import { ParticipantDiagnosticTableComponent } from './components/participant-diagnostic-table/participant-diagnostic-table.component';
import { ParticipantDiagnosticListeComponent } from './components/participant-diagnostic-liste/participant-diagnostic-liste.component';
import { ParticipantDiagnosticFormComponent } from './components/participant-diagnostic-form/participant-diagnostic-form.component';
import { ParticipantDiagnosticItemComponent } from './components/participant-diagnostic-item/participant-diagnostic-item.component';
import { ContentsModule } from 'src/app/contents/contents.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { ParticipantDiagnosticFormNumeriqueComponent } from './components/participant-diagnostic-form-numerique/participant-diagnostic-form-numerique.component';
import { ParticipantDiagnosticFormSanteComponent } from './components/participant-diagnostic-form-sante/participant-diagnostic-form-sante.component';
import { ParticipantDiagnosticFormFamilleComponent } from './components/participant-diagnostic-form-famille/participant-diagnostic-form-famille.component';
import { ParticipantDiagnosticFormProfessionnelleComponent } from './components/participant-diagnostic-form-professionnelle/participant-diagnostic-form-professionnelle.component';
import { ParticipantDiagnosticFormMobiliteComponent } from './components/participant-diagnostic-form-mobilite/participant-diagnostic-form-mobilite.component';
import { ParticipantDiagnosticFormJusticeComponent } from './components/participant-diagnostic-form-justice/participant-diagnostic-form-justice.component';
import { ParticipantDiagnosticFormAdministratifComponent } from './components/participant-diagnostic-form-administratif/participant-diagnostic-form-administratif.component';
import { ParticipantDiagnosticFormAptitudesComponent } from './components/participant-diagnostic-form-aptitudes/participant-diagnostic-form-aptitudes.component';
import { ParticipantDiagnosticLogementComponent } from './components/participant-diagnostic-logement/participant-diagnostic-logement.component';
import { ParticipantDiagnosticQualificationComponent } from './components/participant-diagnostic-qualification/participant-diagnostic-qualification.component';
import { ParticipantDiagnosticFormResumComponent } from './components/participant-diagnostic-form-resum/participant-diagnostic-form-resum.component';
import { ParticipantDiagnosticFormActionsComponent } from './components/participant-diagnostic-form-actions/participant-diagnostic-form-actions.component';
import { ParticipantDiagnosticFormRessourcesComponent } from './components/participant-diagnostic-form-ressources/participant-diagnostic-form-ressources.component';
import { ParticipantDiagnosticFormDroitComponent } from './components/participant-diagnostic-form-droit/participant-diagnostic-form-droit.component';
import { ParticipantDiagnosticFormDisponibilityComponent } from './components/participant-diagnostic-form-disponibility/participant-diagnostic-form-disponibility.component';
import { ParticipantDiagnosticFormStepsComponent } from './components/participant-diagnostic-form-steps/participant-diagnostic-form-steps.component';
import { ParticipantDiagnosticFormEventsComponent } from './components/participant-diagnostic-form-events/participant-diagnostic-form-events.component';
import { ParticipantDiagnosticFormPosturesComponent } from './components/participant-diagnostic-form-postures/participant-diagnostic-form-postures.component';
import { ParticipantDiagnosticFormExperienceComponent } from './components/participant-diagnostic-form-experience/participant-diagnostic-form-experience.component';
import { ParticipantDiagnosticFormPoleEmploiComponent } from './components/participant-diagnostic-form-pole-emploi/participant-diagnostic-form-pole-emploi.component';
import { ParticipantDiagnosticFormBtpComponent } from './components/participant-diagnostic-form-btp/participant-diagnostic-form-btp.component';
import { ParticipantDiagnosticFormCertificationsComponent } from './components/participant-diagnostic-form-certifications/participant-diagnostic-form-certifications.component';
import { ParticipantDiagnosticFormDefinitionComponent } from './components/participant-diagnostic-form-definition/participant-diagnostic-form-definition.component';
import { ParticipantDiagnosticFormExperienceFormComponent } from './components/participant-diagnostic-form-experience-form/participant-diagnostic-form-experience-form.component';
import { ParticipantDiagnosticCardComponent } from './components/participant-diagnostic-card/participant-diagnostic-card.component';
import { ParticipantDiagnosticFormLogementComponent } from './components/participant-diagnostic-form-logement/participant-diagnostic-form-logement.component';
import { ParticipantDiagnosticFormQualificationComponent } from './components/participant-diagnostic-form-qualification/participant-diagnostic-form-qualification.component';
import { ParticipantDiagnosticItemProfessionnelComponent } from './components/participant-diagnostic-item-professionnel/participant-diagnostic-item-professionnel.component';
import { ParticipantDiagnosticItemSocialComponent } from './components/participant-diagnostic-item-social/participant-diagnostic-item-social.component';
import { ParticipantDiagnosticItemHeaderComponent } from './components/participant-diagnostic-item-header/participant-diagnostic-item-header.component';
import { ParticipantDiagnosticItemHeaderTagsComponent } from './components/participant-diagnostic-item-header-tags/participant-diagnostic-item-header-tags.component';
import { ParticipantDiagnosticItemPresentationComponent } from './components/participant-diagnostic-item-presentation/participant-diagnostic-item-presentation.component';
import { EntiteSearchModule } from 'src/app/contents/components/entite-search/entite-search.module';
import { NoResultModule } from 'src/app/contents/components/no-result/no-result.module';
import { CoordonneesModule } from 'src/app/contents/components/coordonnees/coordonnees.module';
import { CommunesFranceModule } from 'src/app/contents/components/communes-france/communes-france.module';
import { ButtonsModule } from 'src/app/contents/components/buttons/buttons.module';
import { MetiersModule } from 'src/app/features/metiers/metiers.module';
import { HeaderModule } from 'src/app/contents/components/header-title/header.module';
import { AvatarModule } from 'src/app/features/avatar/avatar.module';

@NgModule({
  declarations: [
    ParticipantDiagnosticTableComponent,
    ParticipantDiagnosticListeComponent,
    ParticipantDiagnosticFormComponent,
    ParticipantDiagnosticItemComponent,
    ParticipantDiagnosticFormNumeriqueComponent,
    ParticipantDiagnosticFormSanteComponent,
    ParticipantDiagnosticFormFamilleComponent,
    ParticipantDiagnosticFormProfessionnelleComponent,
    ParticipantDiagnosticFormMobiliteComponent,
    ParticipantDiagnosticFormJusticeComponent,
    ParticipantDiagnosticFormAdministratifComponent,
    ParticipantDiagnosticFormAptitudesComponent,
    ParticipantDiagnosticLogementComponent,
    ParticipantDiagnosticQualificationComponent,
    ParticipantDiagnosticFormResumComponent,
    ParticipantDiagnosticFormActionsComponent,
    ParticipantDiagnosticFormRessourcesComponent,
    ParticipantDiagnosticFormDroitComponent,
    ParticipantDiagnosticFormDisponibilityComponent,
    ParticipantDiagnosticFormStepsComponent,
    ParticipantDiagnosticFormEventsComponent,
    ParticipantDiagnosticFormPosturesComponent,
    ParticipantDiagnosticFormExperienceComponent,
    ParticipantDiagnosticFormPoleEmploiComponent,
    ParticipantDiagnosticFormBtpComponent,
    ParticipantDiagnosticFormCertificationsComponent,
    ParticipantDiagnosticFormDefinitionComponent,
    ParticipantDiagnosticFormExperienceFormComponent,
    ParticipantDiagnosticCardComponent,
    ParticipantDiagnosticFormLogementComponent,
    ParticipantDiagnosticFormQualificationComponent,
    ParticipantDiagnosticItemProfessionnelComponent,
    ParticipantDiagnosticItemSocialComponent,
    ParticipantDiagnosticItemHeaderComponent,
    ParticipantDiagnosticItemHeaderTagsComponent,
    ParticipantDiagnosticItemPresentationComponent,
  ],
  imports: [
    CommonModule,
    ContentsModule,
    RouterModule,
    SharedModule,
    EntiteSearchModule,
    NoResultModule,
    CoordonneesModule,
    HeaderModule,
    CommunesFranceModule,
    ButtonsModule,
    MetiersModule,
    AvatarModule,
    StoreModule.forFeature(
      fromParticipantDiagnostic.participantDiagnosticsFeatureKey,
      fromParticipantDiagnostic.reducer
    ),
    EffectsModule.forFeature([ParticipantDiagnosticEffects]),
  ],
  exports: [
    ParticipantDiagnosticTableComponent,
    ParticipantDiagnosticListeComponent,
    ParticipantDiagnosticFormNumeriqueComponent,
    ParticipantDiagnosticFormSanteComponent,
    ParticipantDiagnosticFormFamilleComponent,
    ParticipantDiagnosticFormProfessionnelleComponent,
    ParticipantDiagnosticFormMobiliteComponent,
    ParticipantDiagnosticFormJusticeComponent,
    ParticipantDiagnosticFormAdministratifComponent,
    ParticipantDiagnosticFormAptitudesComponent,
    ParticipantDiagnosticLogementComponent,
    ParticipantDiagnosticQualificationComponent,
    ParticipantDiagnosticFormResumComponent,
    ParticipantDiagnosticFormActionsComponent,
    ParticipantDiagnosticFormRessourcesComponent,
    ParticipantDiagnosticFormDroitComponent,
    ParticipantDiagnosticFormDisponibilityComponent,
    ParticipantDiagnosticFormStepsComponent,
    ParticipantDiagnosticFormEventsComponent,
    ParticipantDiagnosticFormPosturesComponent,
    ParticipantDiagnosticFormExperienceComponent,
    ParticipantDiagnosticFormPoleEmploiComponent,
    ParticipantDiagnosticFormBtpComponent,
    ParticipantDiagnosticFormCertificationsComponent,
    ParticipantDiagnosticFormDefinitionComponent,
    ParticipantDiagnosticFormExperienceFormComponent,
    ParticipantDiagnosticCardComponent,
    ParticipantDiagnosticFormLogementComponent,
    ParticipantDiagnosticFormQualificationComponent,
    ParticipantDiagnosticItemProfessionnelComponent,
    ParticipantDiagnosticItemSocialComponent,
    ParticipantDiagnosticItemHeaderComponent,
    ParticipantDiagnosticItemHeaderTagsComponent,
    ParticipantDiagnosticItemPresentationComponent,
  ],
  providers: [ParticipantDiagnosticService, ParticipantDiagnosticExportService],
})
export class ParticipantDiagnosticModule {}
