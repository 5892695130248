import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-participant-actions-accompagnement-action',
  templateUrl: './participant-actions-accompagnement-action.component.html',
  styleUrls: ['./participant-actions-accompagnement-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantActionsAccompagnementActionComponent
  implements OnInit, OnChanges
{
  @Input() type: string = '';
  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}
}
