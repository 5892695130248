<ng-container *ngIf="user$ | async as user">
  <app-layout-list
    *ngIf="users$ | async | size; else noResults"
    [new]="(user | isDev) || (user | isAdmin) ? new : null"
    [card]="card"
    [source]="users$ | async"
  ></app-layout-list>
  <ng-template #card let-item let-position="idx">
    <app-user-card [user]="item" (click)="onSelect(item)"></app-user-card>
  </ng-template>

  <ng-template #new>
    <app-card-new
      [title]="'Nouveau utilisateur'"
      [type]="'person'"
      [height]="'250px'"
      [definition]="newDefinition"
      (add)="onNew(user, type)"
    >
    </app-card-new>
  </ng-template>

  <ng-template #noResults>
    <app-no-result
      [icon]="'group_add'"
      [isButton]="(user | isDev) || (user | isAdmin) ? true : false"
      [addtitle]="newDefinition"
      [notitle]="noTitle"
      [description]="newCardDefinition"
      (new)="onNew(user, type)"
    ></app-no-result>
  </ng-template>

  <ng-template #userTitleForm>
    <app-header-title [icon]="'person'" [title]="title"></app-header-title>
  </ng-template>
</ng-container>
