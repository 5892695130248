import { ParticipantStorageService } from '../../../service/participant/document/participant-storage.service';
import {
  NzNotificationService,
  NzNotificationPlacement,
} from 'ng-zorro-antd/notification';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError, of, tap } from 'rxjs';
import * as fromParticipantDocumentAction from './participant-document.actions';
import { ParticipantDocumentState } from './participant-document.reducer';
import { Store } from '@ngrx/store';

const NOTIFICATION_PLACEMENT: NzNotificationPlacement = 'bottomRight';

@Injectable()
export class ParticipantDocumentEffects {
  loadParticipantDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromParticipantDocumentAction.loadParticipantDocuments),

      mergeMap((action) =>
        this.participantDocumentService.getAll(action.id).pipe(
          map((respons: any) => {
            const documents: any = respons.data.participantDocuments;

            return fromParticipantDocumentAction.loadParticipantDocumentsSuccess(
              { documents }
            );
          }),
          catchError((error) => {
            this.onErrorNotification(error, 'LOAD_PARTICIPANT_DOCUMENTS');
            return of(
              fromParticipantDocumentAction.loadParticipantDocumentsFailure({
                error,
              })
            );
          })
        )
      )
    )
  );
  uploadParticipantDocument$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromParticipantDocumentAction.uploadParticipantDocuments),
        mergeMap((action) =>
          this.participantDocumentService
            .uploadFiles(action.organisationId, action.id, action.documents)
            .pipe(
              map((respons: any) => {
                return fromParticipantDocumentAction.uploadParticipantDocumentsSuccess(
                  { success: 'Document téléchargé' }
                );
              }),
              catchError((error) => {
                this.onErrorNotification(error, 'UPLOAD_PARTICIPANT_DOCUMENTS');
                return of(
                  fromParticipantDocumentAction.addParticipantDocumentsFailure({
                    error,
                  })
                );
              })
            )
        )
      ),
    { dispatch: false }
  );
  addParticipantDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromParticipantDocumentAction.addParticipantDocuments),
      mergeMap((action) =>
        this.participantDocumentService
          .addFile(action.id, action.document)
          .pipe(
            map((respons) => {
              const document = respons.data.createParticipantDocuments;

              this.notification.success(
                'Téléchargement',
                `${document.title.substring(0, 25)} ajouté avec succès`,
                {
                  nzAnimate: true,
                  nzKey: 'CREATE_PARTICIPANT_DOCUMENT_PROGRESS',
                  nzDuration: 6000,
                  nzPlacement: NOTIFICATION_PLACEMENT,
                }
              );

              // this.participantDocumentStore.dispatch(
              //   fromParticipantDocumentAction.loadParticipantDocuments({
              //     id: action.id,
              //   })
              // );

              return fromParticipantDocumentAction.addParticipantDocumentsSuccess(
                { document }
              );
            }),
            catchError((error) => {
              this.onErrorNotification(error, 'CREATE_PARTICIPANT_DOCUMENTS');
              return of(
                fromParticipantDocumentAction.addParticipantDocumentsFailure({
                  error,
                })
              );
            })
          )
      )
    )
  );
  deleteParticipantDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromParticipantDocumentAction.deleteParticipantDocument),
      tap(() => {
        this.notification.info(
          'Suppression',
          'Suppression du document en cours...',
          {
            nzKey: 'DELETE_PARTICIPANT_DOCUMENT',
            nzPlacement: NOTIFICATION_PLACEMENT,
            nzDuration: 6000,
          }
        );
      }),
      mergeMap((action) =>
        this.participantDocumentService
          .deleteOne(action.participantId, action.id)
          .pipe(
            map((respons: any) => {
              const success: any = respons?.data?.deleteParticipantDocument;
              this.notification.success('Suppression', success, {
                nzKey: 'DELETE_PARTICIPANT_DOCUMENT',
                nzPlacement: NOTIFICATION_PLACEMENT,
                nzDuration: 6000,
              });
              // this.participantDocumentStore.dispatch(
              //   fromParticipantDocumentAction.loadParticipantDocuments({
              //     id: action.id,
              //   })
              // );

              return fromParticipantDocumentAction.deleteParticipantDocumentSuccess(
                { success }
              );
            }),
            catchError((error) => {
              this.onErrorNotification(error, 'DELETE_PARTICIPANT_DOCUMENT');
              return of(
                fromParticipantDocumentAction.deleteParticipantDocumentFailure({
                  error,
                })
              );
            })
          )
      )
    )
  );

  deleteParticipantDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromParticipantDocumentAction.deleteParticipantDocuments),
      tap(() => {
        this.notification.info(
          'Tout supprimer',
          'Suppression des documents en cours...',
          {
            nzKey: 'DELETE_ALL_PARTICIPANT_DOCUMENTS',
            nzPlacement: NOTIFICATION_PLACEMENT,
            nzDuration: 6000,
          }
        );
      }),
      mergeMap((action) =>
        this.participantDocumentService.deleteAll(action.id).pipe(
          map((respons: any) => {
            const success: any = respons?.data?.deleteParticipantDocuments;
            this.notification.success('Tout supprimer', success, {
              nzKey: 'DELETE_ALL_PARTICIPANT_DOCUMENTS',
              nzPlacement: NOTIFICATION_PLACEMENT,
              nzDuration: 6000,
            });
            // this.participantDocumentStore.dispatch(
            //   fromParticipantDocumentAction.loadParticipantDocuments({
            //     id: action.id,
            //   })
            // );

            return fromParticipantDocumentAction.deleteParticipantDocumentsSuccess(
              { success }
            );
          }),
          catchError((error) => {
            this.onErrorNotification(error, 'DELETE_ALL_PARTICIPANT_DOCUMENTS');
            return of(
              fromParticipantDocumentAction.deleteParticipantDocumentsFailure({
                error,
              })
            );
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private notification: NzNotificationService,
    private participantDocumentService: ParticipantStorageService
  ) {}

  onErrorNotification(error: any, key: string): void {
    this.notification.error('Error', error.message, {
      nzDuration: 10000,
      nzAnimate: true,
      nzPlacement: NOTIFICATION_PLACEMENT,
      nzKey: key ? key : '',
    });
  }
}
