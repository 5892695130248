import { PortalNavigationService } from './../../../../portals/portal-navigation.service';
import { OperationFiltersComponent } from './../operation-filters/operation-filters.component';
import { ComponentPortal } from '@angular/cdk/portal';
import { OperationFormComponent } from './../operation-form/operation-form.component';
import { Observable, of } from 'rxjs';
import { NzModalService } from 'ng-zorro-antd/modal';
import {
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef,
  TemplateRef,
  AfterViewInit,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { OperationState } from '../../store/operation.reducer';
import * as fromOperationAction from '../../store/operation.actions';
import * as fromOperationSelector from '../../store/operation.selectors';

import { ActivatedRoute, Router } from '@angular/router';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';
import { Operation, OperationId } from '../../store/operation.model';
import { Update } from '@ngrx/entity';
import { ExportSelectComponent } from 'src/app/contents/components/export-select/export-select.component';

@Component({
  selector: 'app-operation-list',
  templateUrl: './operation-list.component.html',
  styleUrls: ['./operation-list.component.scss'],
})
export class OperationListComponent implements OnInit, AfterViewInit {
  data$: Observable<any> = of(null);
  user$: Observable<any> = of(null);
  operations$: Observable<any> = of([]);
  loading$: Observable<boolean> = of(false);
  modalWidth: number = 850;
  title: string = 'Nouveau projet';
  titleDownload: string = '';

  modalExportWidth: number = 480;

  componentPortalFilter: ComponentPortal<OperationFiltersComponent>;

  newCardTitle: string = 'Nouveau projet';
  newCardDescription: string = 'Ajouter un projet à votre organisation';
  newCardDefinition: string =
    "Un projet est la gestion d'un ensemble d’actions dans le but de répondre à un besoin.";

  @ViewChild('projetTitleForm', { static: false })
  projetTitleForm: TemplateRef<any>;

  @ViewChild('exportTitleForm', { static: false })
  exportTitleForm: TemplateRef<any>;

  constructor(
    private operationStore: Store<OperationState>,
    private userStore: Store<UserState>,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private activateRouter: ActivatedRoute,
    private router: Router,
    private portalNavigationService: PortalNavigationService
  ) {
    this.getLoading();
  }

  ngOnInit(): void {
    this.componentPortalFilter = new ComponentPortal(OperationFiltersComponent);

    this.getRouteData();
    this.getOperations();
    this.getUser();
  }

  ngAfterViewInit(): void {
    this.setFilterPortal();
  }

  ngOnDestroy(): void {
    //this.componentPortalFilter.detach();
  }

  getRouteData(): void {
    this.data$ = this.activateRouter.data;
  }

  getUser(): void {
    this.user$ = this.userStore.select(fromUserSelector.user);
  }

  getLoading(): void {
    this.loading$ = this.operationStore.select(fromOperationSelector.loading);
  }

  getOperations(): void {
    this.operations$ = this.operationStore.select(
      fromOperationSelector.operations
    );
  }

  loadDepartemens(): void {
    this.operationStore.dispatch(fromOperationAction.loadOperations());
  }

  setFilterPortal(): void {
    this.portalNavigationService.setPortal(this.componentPortalFilter);
  }

  onNewOperationForm(): void {
    this.title = 'Nouveau projet';
    const modal = this.modal.create({
      nzContent: OperationFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.projetTitleForm,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
    });

    const instance = modal.getContentComponent();
    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
    instance.add.subscribe((operation): any => {
      if (!operation) {
        return null;
      } else {
        this.onAdd(operation);
        modal.close();
      }
    });
    instance.update.subscribe((operation): any => {
      if (!operation) {
        return null;
      } else {
        this.onUpdate(operation);
        modal.close();
      }
    });
  }

  onExportSelect(operation: OperationId): void {
    if (!operation) return;
    const { denomination } = operation;
    this.titleDownload = `${denomination}`;

    const modal = this.modal.create({
      nzContent: ExportSelectComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalExportWidth,
      nzFooter: [],
      nzTitle: this.exportTitleForm,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
    });

    const instance = modal.getContentComponent();
    instance.isAnomyne = false;
    instance.isInclusSuivi = false;
    instance.isEXL = true;
    instance.isPDF = true;
    instance.isPPT = true;

    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
    instance.select.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        this.onExportValidation(event, operation);
        modal.close();
      }
    });
  }

  onExportValidation(event: any, operation: OperationId): void {
    if (!operation) return;

    switch (event.item) {
      case 'PPT':
        this.onExportPPT(operation);
        break;
      case 'EXCEL':
        this.onExportEXCEL(operation);
        break;
      case 'PDF':
        this.onExportPDF(operation);
        break;
      default:
        break;
    }
  }

  onExportPPT(operation: OperationId): void {
    this.operationStore.dispatch(
      fromOperationAction.exportPPTOperation({ operation })
    );
  }
  onExportPDF(operation: OperationId): void {
    this.operationStore.dispatch(
      fromOperationAction.exportPDFOperation({ operation })
    );
  }
  onExportEXCEL(operation: OperationId): void {
    this.operationStore.dispatch(
      fromOperationAction.exportEXCELOperation({ operation })
    );
  }

  onAdd(operation: Operation): void {
    if (!operation) return;
    this.operationStore.dispatch(
      fromOperationAction.addOperation({ operation })
    );
  }
  onUpdate(operation: Update<OperationId>): void {
    if (!operation) return;
    this.operationStore.dispatch(
      fromOperationAction.updateOperation({ operation })
    );
  }

  onPPT(operation: Operation): void {
    if (!operation) return;
  }

  onDelete(id: string): void {
    if (!id) return;
    this.operationStore.dispatch(fromOperationAction.deleteOperation({ id }));
  }
  onCancel(): void {
    return;
  }
}
