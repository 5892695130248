import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subject, of, takeUntil } from 'rxjs';
import { UtilitiesState } from 'src/app/contents/components/utilities/store/utilities.reducer';
import * as fromUtilitiesSelector from 'src/app/contents/components/utilities/store/utilities.selectors';
import * as fromUtilitiesAction from 'src/app/contents/components/utilities/store/utilities.actions';
import { v4 as uuidV4 } from 'uuid';
import { EtablissementInput } from 'src/app/components/entite/store/entite.model';

@Component({
  selector: 'app-missions-form-operation',
  templateUrl: './missions-form-operation.component.html',
  styleUrls: ['./missions-form-operation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MissionsFormOperationComponent
  implements OnInit, OnChanges, OnDestroy
{
  subscribe = new Subject();
  types$: Observable<any> = of();
  tooltipMessage: string =
    'Ajouter un financeur du projet/ programme/ opération';
  noFinanceursTitle: string =
    "Veuillez renseigner le.s financeur.s / donneurs d'ordre du projet / programme";
  @Input() operationItem: any = null;
  @Output() formChanges = new EventEmitter(false);

  denomination = new FormControl<string>('', Validators.required);
  description = new FormControl<string>('');
  type = new FormControl<string>('', Validators.required);

  missionOperationForm: FormGroup = new FormGroup(
    {
      id: new FormControl(uuidV4(), Validators.required),
      denomination: this.denomination,
      description: this.description,
      type: this.type,
    },
    Validators.required
  );

  constructor(
    private fb: FormBuilder,
    private utilitiesStore: Store<UtilitiesState>
  ) {
    this.getOperationType();
    this.loadOperationType();
  }

  ngOnInit(): void {
    this.onChanges();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.operationItem?.currentValue) return;
    this.formSet(changes.operationItem?.currentValue);
  }
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  formSet(operation: any): void {
    const { id, denomination, type, description, financeurs } = operation;

    this.missionOperationForm.patchValue({
      id,
      denomination,
      description,
      type,
    });
  }

  get financeursArray(): FormArray {
    return this.missionOperationForm.get('financeurs') as FormArray;
  }

  compareWithType(item1: any, item2: any) {
    return item1 === item2;
  }

  getOperationType(): void {
    this.types$ = this.utilitiesStore.select(
      fromUtilitiesSelector.operationMarcheTypes
    );
  }

  loadOperationType(): void {
    this.utilitiesStore.dispatch(
      fromUtilitiesAction.loadOperationMarcheTypes()
    );
  }

  onAddFinanceur(entite: any): void {
    if (!entite) return;
    const financeur = this.fb.group({
      ...entite,
    });
  }
  onRemoveFinanceur(index: number): void {}

  onChanges(): void {
    this.missionOperationForm.valueChanges
      .pipe(takeUntil(this.subscribe))
      .subscribe((operation) => this.formChanges.emit(operation));
  }
}
