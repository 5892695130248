import { Store } from '@ngrx/store';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ParticipantState } from './../../store/participant.reducer';
import * as fromParticipantAction from './../../store/participant.actions';
import * as fromParticipantSelector from './../../store/participant.selectors';

import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewContainerRef,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ParticipantId } from '../../store/participant.model';
import { EntiteFormComponent } from 'src/app/components/entite/components/entite-form/entite-form.component';
import { EntiteSearchSelectComponent } from 'src/app/contents/components/entite-search/components/entite-search-select/entite-search-select.component';
import { EntiteSearchComponent } from 'src/app/contents/components/entite-search/components/entite-search/entite-search.component';
import { Update } from '@ngrx/entity';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-participant-prescripteur',
  templateUrl: './participant-prescripteur.component.html',
  styleUrls: ['./participant-prescripteur.component.scss'],
})
export class ParticipantPrescripteurComponent implements OnInit {
  title: string = 'Prescripteurs';
  titleForm: string = 'Prescripteurs';
  LIMIT: number = 2;
  newCardTitle: string = 'Nouveau prescripteur';
  newCardDescription: string = 'Ajouter un prescripteur';
  newCardNone: string = 'Aucune prescripteur';
  modalWidth: number = 860;
  view$ = new BehaviorSubject<string>('list');
  currentPrescipteur$ = new BehaviorSubject<any>(null);
  newCardDefinition: string =
    "Le prescripteur a pour mission d’orienter une personne en difficulté vers les employeurs solidaires afin de l'aider à trouver un emploi durable pour retrouver sa place dans la société.";

  @ViewChild('prescripteurTitleForm', { static: false }) prescripteurTitleForm:
    | TemplateRef<any>
    | any;

  @Input() participant: ParticipantId | any = null;
  @Output() add = new EventEmitter<any>(false);
  @Output() deleted = new EventEmitter<any>(false);
  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private participantStore: Store<ParticipantState>,
    private message: NzMessageService
  ) {}

  ngOnInit(): void {}

  onNew(participant: ParticipantId): void {
    if (
      participant?.prescripteurs?.length &&
      participant.prescripteurs.length >= this.LIMIT
    ) {
      const messageAlert: string = `Le nombre maximal de prescripteurs est atteint`;
      this.message.info(messageAlert, { nzDuration: 6000 });
      return;
    }
    this.titleForm = 'Nouveau prescripteur';
    const modal = this.modal.create({
      nzContent: EntiteSearchComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.prescripteurTitleForm,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '10px' },
    });
    const instance = modal.getContentComponent();

    instance.addItem.subscribe((prescripteur: any): any => {
      if (!prescripteur || !participant) return;
      this.onAdd(participant, prescripteur);
      modal.close();
    });
  }

  onAdd(participant: ParticipantId, prescripteur: any): void {
    const prescripteurs: any[] = participant?.prescripteurs?.length
      ? participant.prescripteurs
      : [];
    const isExist = prescripteurs?.length
      ? prescripteurs.find(
          (el) =>
            el.etablissement_siege.siret ===
            prescripteur.etablissement_siege.siret
        )
      : null;

    if (isExist) {
      const messageAlert: string = `Le prescripteur ${prescripteur?.denomination} est déjà existant`;
      this.message.info(messageAlert, { nzDuration: 6000 });
      return;
    }

    const nextParticipant: Update<ParticipantId> = {
      id: participant.id,
      changes: {
        ...participant,
        prescripteurs: prescripteurs?.length
          ? [...prescripteurs, prescripteur]
          : [prescripteur],
      },
    };
    this.participantStore.dispatch(
      fromParticipantAction.updateParticipant({ participant: nextParticipant })
    );
  }

  onUpdateReferent(prescripteur: any, participant: ParticipantId): void {
    if (!participant || !prescripteur) return;

    const prescripteurs: any[] = participant?.prescripteurs?.length
      ? participant.prescripteurs
      : [];
    const prevPrescripteur = prescripteurs?.length
      ? prescripteurs.filter((el: any) => el.id !== prescripteur.id)
      : [];

    const nextPrescripteurs = prevPrescripteur?.length
      ? [prescripteur, ...prevPrescripteur]
      : [prevPrescripteur];

    const nextParticipant: Update<ParticipantId> = {
      id: participant.id,
      changes: {
        ...participant,
        prescripteurs: nextPrescripteurs,
      },
    };

    this.participantStore.dispatch(
      fromParticipantAction.updateParticipant({ participant: nextParticipant })
    );
  }

  onSelect(prescripteur: any): void {
    this.view$.next('details');
    this.currentPrescipteur$.next(prescripteur);
  }

  onBack(): void {
    this.view$.next('list');
    this.currentPrescipteur$.next(null);
  }

  onCancel(): void {}

  onDelete(prescripteur: any, participant: ParticipantId): void {
    if (!participant || !prescripteur) return;
    const nextPrescripteurs: any[] = participant.prescripteurs.filter(
      (el: any) =>
        el.etablissement_siege.siret !== prescripteur.etablissement_siege.siret
    );
    const nextParticipant: Update<ParticipantId> = {
      id: participant.id,
      changes: { ...participant, prescripteurs: nextPrescripteurs },
    };
    this.participantStore.dispatch(
      fromParticipantAction.updateParticipant({ participant: nextParticipant })
    );
  }
}
