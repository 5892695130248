import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isComplete',
})
export class IsCompletePipe implements PipeTransform {
  transform(values: any[], propertie: string, state: boolean): any[] {
    if (!values?.length || !propertie || !state) {
      return [];
    }

    const array: any[] = values.filter((el) => el[`${propertie}`] === state);

    return array;
  }
}
