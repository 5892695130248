import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of } from 'rxjs';
import { ContratService } from '../services/contrat.service';
import * as fromContactAction from './contrat.actions';

@Injectable()
export class ContratEffects {
  loadTypeContrats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromContactAction.loadTypesContrats),
      mergeMap(() =>
        this.contratService.getTypesContrats().pipe(
          map((respons: any) => {
            const types = respons.data.referentielTypesContrats;
            return fromContactAction.loadTypesContratsSuccess({ types });
          }),
          catchError((error) =>
            of(fromContactAction.loadTypesContratsFailure({ error }))
          )
        )
      )
    )
  );
  loadNatureContrats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromContactAction.loadNaturesContrats),
      mergeMap(() =>
        this.contratService.getNatureContrats().pipe(
          map((respons: any) => {
            const natures = respons.data.referentielNaturesContrats;
            return fromContactAction.loadNaturesContratsSuccess({ natures });
          }),
          catchError((error) =>
            of(fromContactAction.loadNaturesContratsFailure({ error }))
          )
        )
      )
    )
  );
  loadNiveauExperience$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromContactAction.loadNiveauExperiences),
      mergeMap(() =>
        this.contratService.getNiveauExperience().pipe(
          map((respons: any) => {
            const experiences =
              respons.data.referentielNiveauExperience.caracteristiques;
            return fromContactAction.loadNiveauExperiencesSuccess({
              experiences,
            });
          }),
          catchError((error) =>
            of(fromContactAction.loadNiveauExperiencesFailure({ error }))
          )
        )
      )
    )
  );

  loadNiveauSalaire$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromContactAction.loadNiveauSalaire),
      mergeMap(() =>
        this.contratService.getNiveauSalaire().pipe(
          map((respons: any) => {
            const salaires = respons.data.referentielSalaire.caracteristiques;
            return fromContactAction.loadNiveauSalaireSuccess({ salaires });
          }),
          catchError((error) =>
            of(fromContactAction.loadNiveauSalaireFailure({ error }))
          )
        )
      )
    )
  );

  loadPermis$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromContactAction.loadPermis),
      mergeMap(() =>
        this.contratService.getPermis().pipe(
          map((respons: any) => {
            const permis = respons.data.referentielPermis;
            return fromContactAction.loadPermisSuccess({ permis });
          }),
          catchError((error) =>
            of(fromContactAction.loadPermisFailure({ error }))
          )
        )
      )
    )
  );

  loadLevelNumerique$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromContactAction.loadLevelNumerique),
      mergeMap(() =>
        this.contratService.getLevelNumerique().pipe(
          map((respons: any) => {
            const numerique = respons.data.referentiel_levelNumerique;
            return fromContactAction.loadLevelNumeriqueSuccess({ numerique });
          }),
          catchError((error) =>
            of(fromContactAction.loadLevelNumeriqueFailure({ error }))
          )
        )
      )
    )
  );

  loadComplementSalaire$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromContactAction.loadComplementSalaire),
      mergeMap(() =>
        this.contratService.getComplementSalaire().pipe(
          map((respons: any) => {
            const complement = respons.data.referentiel_complement_salaire;
            return fromContactAction.loadComplementSalaireSuccess({
              complement,
            });
          }),
          catchError((error) =>
            of(fromContactAction.loadComplementSalaireFailure({ error }))
          )
        )
      )
    )
  );
  loadTypesHoraires$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromContactAction.loadTypesHoraire),
      mergeMap(() =>
        this.contratService.getTypesHoraires().pipe(
          map((respons: any) => {
            const horaires = respons.data.referentiel_type_horaire;
            return fromContactAction.loadTypesHoraireSuccess({
              horaires,
            });
          }),
          catchError((error) =>
            of(fromContactAction.loadTypesHoraireFailure({ error }))
          )
        )
      )
    )
  );

  loadMobilityGeographic = createEffect(() =>
    this.actions$.pipe(
      ofType(fromContactAction.loadMobilityGeographic),
      mergeMap(() =>
        this.contratService.getMobilityGeographique().pipe(
          map((respons: any) => {
            const mobilities = respons.data.referentiel_mobilite_geographique;
            return fromContactAction.loadMobilityGeographicSuccess({
              mobilities,
            });
          }),
          catchError((error) =>
            of(fromContactAction.loadMobilityGeographicFailure({ error }))
          )
        )
      )
    )
  );

  loadJoursContrat = createEffect(() =>
    this.actions$.pipe(
      ofType(fromContactAction.loadContratJours),
      mergeMap(() =>
        this.contratService.getJoursContrat().pipe(
          map((respons: any) => {
            const jours = respons.data.referentiel_contrat_jours;
            return fromContactAction.loadContratJoursSuccess({
              jours,
            });
          }),
          catchError((error) =>
            of(fromContactAction.loadContratJoursFailure({ error }))
          )
        )
      )
    )
  );

  loadEvolutionTypes = createEffect(() =>
    this.actions$.pipe(
      ofType(fromContactAction.loadEvolutionTypes),
      mergeMap(() =>
        this.contratService.getEvolutionTypes().pipe(
          map((respons: any) => {
            const evolutions = respons.data.referentiel_evolution_mission;
            return fromContactAction.loadEvolutionTypesSuccess({
              evolutions,
            });
          }),
          catchError((error) =>
            of(fromContactAction.loadEvolutionTypesFailure({ error }))
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private contratService: ContratService
  ) {}
}
