import { ContactId } from './../../store/contact.model';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-contact-detail',
  templateUrl: './contact-detail.component.html',
  styleUrls: ['./contact-detail.component.scss'],
})
export class ContactDetailComponent implements OnInit {
  @Input() contact: ContactId | any = null;

  constructor() {}

  ngOnInit(): void {}
}
