import { Store } from '@ngrx/store';
import { DepartementState } from './../../store/departement.reducer';
import * as fromDepartementAction from './../../store/departement.actions';

import { Update } from '@ngrx/entity';
import { DepartementFormComponent } from './../departement-form/departement-form.component';
import { NzModalService, NzModalRef } from 'ng-zorro-antd/modal';
import { PortalNavigationService } from './../../../../portals/portal-navigation.service';
import { DepartementItemHeaderComponent } from './../departement-item-header/departement-item-header.component';
import { DepartementDetailsComponent } from './../departement-details/departement-details.component';
import { DepartementId } from './../../store/departement.model';
import { EMPTY, Observable, of, Subject } from 'rxjs';
import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { PortalLayoutBodyService } from 'src/app/portals/portal-layout-body.service';
import { CdkPortal, ComponentPortal } from '@angular/cdk/portal';
import {
  NzNotificationPlacement,
  NzNotificationService,
} from 'ng-zorro-antd/notification';
import { UserListSelectorComponent } from 'src/app/components/user/components/user-list-selector/user-list-selector.component';
import { NzSelectModeType } from 'ng-zorro-antd/select';

@Component({
  selector: 'app-departement-item',
  templateUrl: './departement-item.component.html',
  styleUrls: ['./departement-item.component.scss'],
})
export class DepartementItemComponent implements OnInit, OnDestroy {
  subscribe = new Subject();
  routeData$: Observable<any> | any;
  departement$: Observable<DepartementId | any> = of(null);
  bodyComponent: string = 'details';
  componentPortalFilter: ComponentPortal<DepartementItemHeaderComponent> | any;
  selectUsers: any = null;
  modalWidth: number = 670;
  NOTIFICATION_PLACEMENT: NzNotificationPlacement = 'topRight';

  @ViewChild('departementFormTitle', { static: false }) departementFormTitle:
    | TemplateRef<any>
    | any;

  @ViewChild('departementNewMemberTitle', { static: false })
  departementNewMemberTitle: TemplateRef<any> | any;

  @ViewChild('tplUserModalFooter', { static: false })
  tplUserModalFooter: TemplateRef<any> | any;

  constructor(
    private activateRouter: ActivatedRoute,
    private router: Router,
    private portalBodyService: PortalLayoutBodyService,
    private portalNavigationService: PortalNavigationService,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private departementStore: Store<DepartementState>,
    private notification: NzNotificationService
  ) {}

  ngOnInit(): void {
    this.getDeparrement();
    this.componentPortalFilter = new ComponentPortal(
      DepartementItemHeaderComponent
    );
    this.setFilterPortal();
  }

  getDeparrement(): void {
    this.getRoute();
    this.getData();
  }

  getRoute(): void {
    this.routeData$ = this.activateRouter.data;
  }

  getData(): void {
    this.routeData$
      .pipe(takeUntil(this.subscribe))
      .subscribe((data: any): any => {
        if (!data?.departement) {
          this.router.navigate(['/services']);
          return EMPTY;
        } else {
          this.departement$ = data?.departement;
        }
      });
  }

  setComponent(component: string): void {
    switch (component) {
      case 'form':
        break;
      case 'details':
        this.bodyComponent = component;
        break;
      case 'users':
        this.bodyComponent = component;
        break;
      default:
        break;
    }
  }

  onSetUpdate(departement: any): void {
    if (!departement) return;
    const modal = this.modal.create({
      nzContent: DepartementFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.departementFormTitle,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });

    const instance = modal.getContentComponent();
    instance.departement$ = of(departement);
    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
  }

  onNewMember(departement: any): void {
    if (!departement) return;
    const mode: NzSelectModeType = 'tags';
    const users: any[] = [];
    const modal: NzModalRef = this.modal.create({
      nzContent: UserListSelectorComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzTitle: this.departementNewMemberTitle,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
      nzFooter: [
        {
          label: 'Annuler',
          onClick: () => modal.close(),
        },
        {
          label: 'Ajouter',
          type: 'primary',
          onClick: () => {
            this.onCheckUserDepartement(departement, this.selectUsers);
            modal.close();
          },
        },
      ],
    });

    const instance = modal.getContentComponent();
    instance.type$.next('all');
    instance.mode = mode;

    instance.select.subscribe((users: any) => {
      if (!users) null;
      this.selectUsers = users;
    });
  }

  onSelectUser(user: any, component: string): void {
    this.bodyComponent = component;
  }

  setFilterPortal(): void {
    this.portalNavigationService.setPortal(this.componentPortalFilter);
  }

  onUserDelete(user: any, departement: DepartementId): void {
    if (!user || !departement) return;

    const { users, ids } = departement;

    const usersFilter = users.filter((user) => user.id !== user.id);
    const idsFilter = ids?.filter((id) => id !== user.id);

    const changes: DepartementId = {
      ...departement,
      users: usersFilter,
      ids: idsFilter,
    };

    const nextDepartement: Update<DepartementId> = {
      id: departement.id,
      changes: { ...changes },
    };

    this.departementStore.dispatch(
      fromDepartementAction.updateDepartement({ departement: nextDepartement })
    );
  }

  onCheckUserDepartement(departement: any, users: any[]): void {
    if (!users.length) return;

    const departemetAlreadyExistForUsers = users.filter(
      (user: any) => user.service.title !== ''
    );
    const departementNotExistForUsers = users.filter(
      (user: any) => user.service.title === ''
    );

    if (departemetAlreadyExistForUsers?.length) {
      const usersDisplayName: string[] = departemetAlreadyExistForUsers.map(
        (user: any) => user.displayName
      );

      const usersIdentifty = usersDisplayName.join(', ');
      const messges: string = `${usersIdentifty} ont déjà été intégrés à un service. Voule-vous modifier le service des collaborateurs concernés ?`;

      const modal: NzModalRef = this.modal.create({
        nzContent: messges,
        nzViewContainerRef: this.viewContainerRef,
        nzWidth: this.modalWidth,
        nzTitle: this.departementNewMemberTitle,
        nzComponentParams: {},
        nzCloseIcon: '',
        nzClosable: false,
        nzStyle: { top: '20px' },
        nzFooter: [
          {
            label: 'Annuler',
            onClick: () => {
              modal.close();
            },
          },
          {
            label: 'Valider avec changement',
            show: departemetAlreadyExistForUsers?.length ? true : false,
            onClick: () => {
              const nextUsers = [
                ...departementNotExistForUsers,
                ...departemetAlreadyExistForUsers,
              ];
              this.onUpdate(departement, nextUsers);
              modal.close();
            },
          },
          {
            label: 'Valider sans changement',
            type: 'primary',
            show: departementNotExistForUsers?.length ? true : false,
            onClick: () => {
              const nextUsers = [...departementNotExistForUsers];
              this.onUpdate(departement, nextUsers);
              modal.close();
            },
          },
        ],
      });

      return;
    }

    this.onUpdate(departement, departementNotExistForUsers);
    return;
  }

  onUpdate(departement: DepartementId, users: any[]): void {
    if (!departement || !users?.length) return;

    const ids: string[] = users.map((user: any) => user.id);

    const nextDepartement: Update<DepartementId> = {
      id: departement.id,
      changes: {
        ...departement,
        users: [...departement.users, ...users],
        ids: [...departement.ids, ...ids],
      },
    };

    this.departementStore.dispatch(
      fromDepartementAction.updateDepartement({ departement: nextDepartement })
    );
  }

  onRemoveUser(user: any, departement: any): void {
    if (!user || !departement) return;
    const ids: string[] = departement.ids.filter((id: any) => id !== user.id);
    const users: any[] = departement.users.filter(
      (user: any) => user.id !== user.id
    );

    const nextDepartement: Update<DepartementId> = {
      id: departement.id,
      changes: {
        ...departement,
        users: [...departement.users, ...users],
        ids: [...departement.ids, ...ids],
      },
    };
    this.departementStore.dispatch(
      fromDepartementAction.updateDepartement({ departement: nextDepartement })
    );
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
    this.componentPortalFilter.detach();
  }
}
