<div *ngIf="entite" class="entiteDetail__container">
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="12">
      <nz-card nzSize="small" [nzTitle]="entiteDetailDomaine">
        <nz-descriptions
          nzBordered
          [nzColumn]="{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }"
        >
          <nz-descriptions-item nzTitle="Activité principale" nzSpan="1">
            <p nz-typography>
              {{
                entite?.etablissement?.activite_principale_libelle?.intitule_naf
              }}
            </p>
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="Nature juridique" nzSpan="1">
            <p nz-typography>
              {{ entite?.etablissement?.categorie_juridique_libelle?.libelle }}
            </p>
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="Grand domaine" nzSpan="1">
            <p nz-typography>
              {{ entite?.custom?.metiersGrandDomaine?.libelle }}
              <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
              <nz-tag nzColor="geekblue"
                >Code : {{ entite?.custom?.metiersGrandDomaine?.code }}</nz-tag
              >
            </p>
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="Domaine professionnel" nzSpan="1">
            <p nz-typography>
              {{ entite?.custom?.metiersDomaine?.libelle }}
              <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
              <nz-tag nzColor="geekblue"
                >Code : {{ entite?.custom?.metiersDomaine?.code }}</nz-tag
              >
            </p>
          </nz-descriptions-item>

          <nz-descriptions-item nzTitle="Catéggorie" nzSpan="1">
            <p nz-typography>
              {{ entite?.etablissement?.categorie_entreprise }}
            </p>
          </nz-descriptions-item>
        </nz-descriptions>
      </nz-card>
    </nz-col>
    <nz-col nzSpan="12">
      <nz-card nzSize="small" [nzTitle]="entiteDetailAdministratif">
        <nz-descriptions
          nzBordered
          [nzColumn]="{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }"
        >
          <nz-descriptions-item nzTitle="Siret" nzSpan="1">
            {{ entite?.etablissement?.etablissement_siege.siret }}
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="APE" nzSpan="1">
            {{ entite?.etablissement?.activite_principale }}
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="Adresse" nzSpan="1">
            {{
              entite?.etablissement?.etablissement_siege?.geo_adresse
                | carLimitation: 200
            }}
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="Commune" nzSpan="1">
            {{
              entite?.etablissement?.etablissement_siege?.libelle_commune
                | carLimitation: 150
            }}
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="Code postal" nzSpan="1">
            {{ entite?.etablissement?.etablissement_siege?.code_postal }}
          </nz-descriptions-item>
        </nz-descriptions>
      </nz-card>
    </nz-col>
  </nz-row>
  <nz-space nzSize="large" nzDirection="vertical"></nz-space>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="12">
      <nz-card nzSize="small" [nzTitle]="entiteEngagement">
        <nz-descriptions
          nzBordered
          [nzColumn]="{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }"
        >
          <nz-descriptions-item nzTitle="Engagement" nzSpan="1">
            {{ entite?.custom?.engagement }}
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="Statut" nzSpan="1">
            {{ entite?.custom?.statut }}
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="Origine" nzSpan="1">
            {{ entite?.custom?.origine }}
          </nz-descriptions-item>
        </nz-descriptions>
      </nz-card>
    </nz-col>
    <nz-col nzSpan="12">
      <nz-card nzSize="small" [nzTitle]="entiteCoordonnees">
        <nz-descriptions
          nzBordered
          [nzColumn]="{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }"
        >
          <nz-descriptions-item nzTitle="E-mail" nzSpan="1">
            {{ entite?.custom?.coordonnees?.email | carLimitation: 200 }}
          </nz-descriptions-item>
        </nz-descriptions>
      </nz-card>
    </nz-col>
  </nz-row>

  <ng-template #entiteDetailDomaine>
    <app-header-title
      [icon]="'domaine'"
      [title]="'Activité'"
      [subtitle]="true"
    ></app-header-title>
  </ng-template>
  <ng-template #entiteDetailAdministratif>
    <app-header-title
      [icon]="'admin_panel_settings'"
      [title]="'Administratif'"
      [subtitle]="true"
    ></app-header-title>
  </ng-template>

  <ng-template #entiteEngagement>
    <app-header-title
      [icon]="'volunteer_activism'"
      [title]="'Engagement'"
      [subtitle]="true"
    ></app-header-title>
  </ng-template>
  <ng-template #entiteCoordonnees>
    <app-header-title
      [icon]="'email'"
      [title]="'Coordonnées'"
      [subtitle]="true"
    ></app-header-title>
  </ng-template>
</div>
