import { NzMessageService } from 'ng-zorro-antd/message';
import {
  UtilitiesIds,
  UtilitiesParticipantVariables,
} from './../utilities/store/utilities.model';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, of, Subject, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { keyBy, forEach } from 'lodash';
import * as fromObjectifsAction from './store/objectif.actions';
import * as fromObjectifsSelectors from './store/objectif.selectors';
import { ObjectifState } from './store/objectif.reducer';
import { Store, select } from '@ngrx/store';

@Component({
  selector: 'app-objectif',
  templateUrl: './objectif.component.html',
  styleUrls: ['./objectif.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObjectifComponent implements OnInit, OnDestroy {
  subscribe = new Subject();
  add: boolean = true;
  utilities$: Observable<any> = of(null);
  badgeStyle = {
    backgroundColor: '#fff',
    color: '#999',
    boxShadow: '0 0 0 1px #d9d9d9 inset',
  };
  categories: string[] = ['quantitatif', 'qualitatif'];
  types: string[] = [
    'actions',
    'collaborateurs',
    'entités',
    'participants',
    'missions',
  ];
  objectifList: string[] = [];

  participantUtilities$: Observable<any> = of(null);

  criteresList$: Observable<any> = of(null);

  actionObjectifList: string[] = ['total', 'critères'];
  utilitairesId: UtilitiesIds = UtilitiesIds.participant;

  utilitaireCriteres = UtilitiesParticipantVariables.criteres;

  objectifs: UntypedFormGroup = this.fb.group({});
  objectifItems: UntypedFormArray = this.fb.array([]);

  @Input() updateItem$ = new BehaviorSubject<any>(null);
  @Input() existObjectifs: any[] = [];

  @Output() list = new EventEmitter<any>();
  @Output() update = new EventEmitter<any>();

  @Output() select = new EventEmitter<any>();
  @Output() cancel = new EventEmitter<any>();

  constructor(
    private fb: UntypedFormBuilder,
    private ObjectifStore: Store<ObjectifState>,
    private message: NzMessageService
  ) {}

  ngOnInit(): void {
    this.formInit();
    this.formCreate();
    this.formSet();
    this.load__ParticipantUtilities();
    this.get__ParticipantUtilities();
  }

  load__ParticipantUtilities(): void {
    this.ObjectifStore.dispatch(fromObjectifsAction.loadParticipantUtilities());
  }
  get__ParticipantUtilities(): void {
    this.participantUtilities$ = this.ObjectifStore.select(
      fromObjectifsSelectors.selectParticipantUtilities
    );
  }

  formInit(): void {
    this.objectifItems = this.fb.array([]);
  }

  formCreate(): void {
    this.objectifs = this.fb.group({
      items: this.objectifItems,
    });
  }

  formSet(): void {
    this.updateItem$.pipe(takeUntil(this.subscribe)).subscribe((objectifs) => {
      if (!objectifs) {
        this.add = true;
        this.objectifList = ['total', 'critères'];
      }
      if (objectifs) {
        this.add = false;
        this.updateObjectif(objectifs);
      }
    });
  }

  updateObjectif(objectifs: any[]): void {
    objectifs.forEach((objectif) => {
      const item: UntypedFormGroup = this.setObjectifItem(objectif);
      this.objectifItems.push(item);
    });
  }

  setCategorieOfIndicator(type: string, index: number): void {
    switch (type) {
      case 'missions':
        this.objectifList = ['total', 'heures'];
        break;
      case 'participants':
        this.objectifList = ['total', 'critères'];

        break;
      case 'entités':
        this.objectifList = ['total'];

        break;
      case 'collaborateurs':
        this.objectifList = ['total'];

        break;
      case 'actions':
        this.objectifList = ['total'];

        break;
      default:
        break;
    }
  }

  guardTypeObjectif(type: string, categorie: string, index: number): void {
    if (this.objectifItems?.length > 1) {
      const isExist: any[] = this.objectifItems?.value.filter(
        (el: any) => el?.categorie === categorie && el?.type === type
      );

      if (!isExist || isExist?.length === 1) {
        return;
      } else {
        const message: string = `Objectif existant`;
        this.message.info(message, { nzDuration: 6000 });
        this.objectifItems.removeAt(index);
      }
    } else {
      return;
    }
  }

  get objectifArray(): UntypedFormArray {
    return this.objectifs.get('items')?.value as UntypedFormArray;
  }

  setObjectif(): void {
    this.objectifs.valueChanges
      .pipe(takeUntil(this.subscribe))
      .subscribe((value: any): any => {
        if (!value) {
          return null;
        }

        const objet = keyBy(value.items, 'id');
        const array = value.items;

        this.list.emit({ objet, array });
      });
  }

  addObjectif(): void {
    const item: UntypedFormGroup = this.setObjectifItem();
    this.objectifItems.push(item);
  }

  setObjectifItem(objectif?: any): UntypedFormGroup {
    if (!objectif) {
      const item: UntypedFormGroup = this.fb.group({
        id: [uuid()],
        categorie: ['participants', Validators.required],
        type: ['total', Validators.required],
        item: [''],
        value: [20, Validators.required],
        realisation: [''],
      });
      return item;
    } else {
      const item: UntypedFormGroup = this.fb.group({
        id: [objectif?.id],
        categorie: [objectif?.categorie, Validators.required],
        type: [objectif?.type, Validators.required],
        item: [objectif?.item],
        value: [objectif?.value, Validators.required],
        realisation: [objectif?.realisation],
      });
      return item;
    }
  }
  addCritere(critere: any, index: number): void {
    if (!critere) {
      this.objectifItems.at(index).patchValue({ item: null });
    } else {
      this.objectifItems.at(index).patchValue({ item: critere });
    }
  }

  onSaveObjectif(): any {
    if (!this.objectifItems || !this.objectifItems?.length) {
      return null;
    }
    const objectifs = this.objectifItems.value;

    if (this.add) {
      this.list.emit(objectifs);
    } else {
      this.update.emit(objectifs);
    }
    this.objectifItems.clear();
  }

  onClear(): void {
    this.objectifItems.clear();
  }

  onRemoveObjectif(index: number): void {
    this.objectifItems.removeAt(index);
  }

  onDelete(): void {
    this.objectifs.reset();
  }

  onCancel(): void {
    this.cancel.emit(true);
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }
}
