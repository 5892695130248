import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class ParticipantAccompagnementGQL extends Query<Response> {
  document = gql`
    query Accompagnement($participantId: ID!, $id: ID!) {
      accompagnement(participantId: $participantId, id: $id) {
        id
        champs {
          code
          libelle
        }
        type
        dateStart
        dateEnd
        distance
        progress
        status
        statusColor

        duration {
          months
        }
        objectif
        isCompleted
        auteur {
          avatar
          displayName
          fonction
          setting {
            color
          }
        }
      }
    }
  `;
}
