<nz-card [nzTitle]="contratEvolution" nzBorderless>
  <ng-template #contratEvolution>
    <app-header-title
      [icon]="'subject'"
      [title]="''"
      [subtitle]="true"
    ></app-header-title>
  </ng-template>
  <form nz-form [formGroup]="contratForm" class="space-y-6 p-6">
    <div class="w-full mb-6">
      <div class="w-full flex flex-col">
        <label class="label">Type d'évolution</label>
        <div class="select-menu">
          <nz-select
            [nzPlaceHolder]="evolutionTitle"
            nzBorderless
            formControlName="evolution"
            nzServerSearch
          >
            <nz-option
              *ngFor="let item of evolutions_types$ | async"
              [nzLabel]="item?.libelle"
              [nzValue]="item"
            ></nz-option>
          </nz-select>
        </div>
      </div>
    </div>

    <div class="w-full">
      <label class="label">Condition d'évolution</label>
      <div class="w-full">
        <textarea
          class="textareaForm"
          formControlName="evolutionCondition"
          placeholder="Conditions d'évolution"
        ></textarea>
      </div>
    </div>
  </form>
</nz-card>
