import { MetiersComponent } from '../../../../features/metiers/components/metiers/metiers.component';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable, of, Subject, takeUntil } from 'rxjs';
import { ParticipantId } from '../../store/participant.model';
import { ParticipantState } from '../../store/participant.reducer';
import * as fromParticipantAction from '../../store/participant.actions';
import * as fromParticipantSelector from '../../store/participant.selectors';

@Component({
  selector: 'app-participant-experiences',
  templateUrl: './participant-experiences.component.html',
  styleUrls: ['./participant-experiences.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantExperiencesComponent
  implements OnInit, OnChanges, OnDestroy
{
  experiences$: Observable<any> = of(null);
  subscribe = new Subject();
  title: string = 'Expériences';
  titlePage: string = 'Expériences';
  view: string = 'list';
  currentSelected: any = null;
  filters$: Observable<any> = of(null);
  loading$: Observable<boolean> = of(false);
  aids$: Observable<any> = of([]);
  aidsSuggestions$: Observable<any> = of(null);
  suggestionAidsTitle: string = 'Suggestions';
  newAidsTitle: string = 'Formulaire vide';
  popoverTitle: string = 'Mode de renseignement';
  newCardTitle: string = 'Nouvelle expérience';
  newCardDescription: string =
    'Ajouter une expérience au profil du participant bénéficiaire';
  newCardNone: string = 'Aucune expérience ajoutée';
  newCardDefinition: string =
    "Une expérience professionnelle est un ensemble des connaissances acquises par une personne lors de l'exercice de sa (ou ses) profession(s).";

  modalWidth: number = 960;
  @ViewChild('experienceTitleForm', { static: false }) experienceTitleForm:
    | TemplateRef<any>
    | any;

  @Input() participant$: Observable<ParticipantId | any> = of(null);
  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private participantStore: Store<ParticipantState>
  ) {}

  ngOnInit(): void {
    this.getExperiences();
    this.loadExperiences();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getExperiences(): void {
    this.experiences$ = this.participantStore.select(
      fromParticipantSelector.experiences
    );
  }

  loadExperiences(): void {
    this.participant$
      .pipe(takeUntil(this.subscribe))
      .subscribe((participant: ParticipantId) => {
        if (!participant) return;
        this.participantStore.dispatch(
          fromParticipantAction.loadParticipantExperiences({
            id: participant.id,
          })
        );
      });
  }

  onSelect(experience: any, participant: ParticipantId): void {
    this.currentSelected = experience;
    //this.view = 'details';
  }

  onNew(participant: ParticipantId): void {
    if (!participant) return;

    const modal = this.modal.create({
      nzContent: MetiersComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.experienceTitleForm,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });
    const instance = modal.getContentComponent();
    instance.type = 'grandDomaine';
    instance.showMetiers = true;
    instance.showAppelations = true;
    instance.showAppelation = true;
    instance.showSoftskills = true;
    instance.hideCancel = true;
    instance.hideValidation = true;
    instance.showQualification = true;
    instance.showAppelationDetail = true;
    instance.experience = true;
    instance.participant.next(participant);
    instance.loadDomaines();

    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });

    instance.validation.subscribe((experience): any => {
      if (!experience) return null;
      this.onAdd(participant, experience);
      modal.close();
    });
  }

  onAdd(participant: ParticipantId, experience: any): void {
    if (!participant || !experience) return;
    const { metier } = experience;
    this.participantStore.dispatch(
      fromParticipantAction.addParticipantExperience({
        id: participant.id,
        experience: experience,
      })
    );
  }

  onUpdate(participant: ParticipantId, experience: any): void {
    if (!participant || !experience) return;
  }

  onDelete(participant: ParticipantId, experience: any): void {
    if (!participant || !experience) return;
    this.participantStore.dispatch(
      fromParticipantAction.deleteParticipantExperience({
        participantId: participant.id,
        experienceId: experience.id,
      })
    );
  }

  onBack(): void {
    this.currentSelected = null;
    this.view = 'list';
  }

  onCancel(): void {}
}
