import { OperationService } from './../../../../service/operation.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { OperationSmallId } from 'src/app/components/operation/store/operation.model';
import { mergeMap, map, catchError } from 'rxjs/operators';
import * as fromOperationSelectAction from './operations-select.actions';

@Injectable()
export class OperationsSelectEffects {
  loadOperations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationSelectAction.loadOperationsSelects),
      mergeMap((action) =>
        this.operationService.getAll().pipe(
          map(
            (operations: OperationSmallId[]) =>
              fromOperationSelectAction.loadOperationsSelectsSuccess({
                operations,
              }),
            catchError((error) =>
              of(
                fromOperationSelectAction.loadOperationsSelectsFailure({
                  error,
                })
              )
            )
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private operationService: OperationService
  ) {}
}
