import { takeUntil } from 'rxjs/operators';
import { OperationId } from './../../../operation/store/operation.model';
import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Output,
  EventEmitter,
  ViewChild,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { of, Observable, Subject, BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromOperationSelector from './../../../operation/store/operation.selectors';
import { NzModalService } from 'ng-zorro-antd/modal';
import { EntiteSMALLid } from 'src/app/components/entite/store/entite.model';
import { EntiteValidatorComponent } from 'src/app/contents/components/entite-validator/entite-validator.component';
import { EntiteSelectorsComponent } from 'src/app/components/entite/components/entite-selectors/entite-selectors.component';
import { OperationEntiteState } from '../../store/operation-entites.reducer';
import * as fromOperationEntiteAction from '../../store/operation-entites.actions';
import * as fromOperationEntiteSelector from '../../store/operation-entites.selectors';
import { ActivatedRoute, Data } from '@angular/router';
import { OperationState } from 'src/app/components/operation/store/operation.reducer';
import * as fromOperationAction from 'src/app/components/operation/store/operation.actions';

@Component({
  selector: 'app-operation-entites',
  templateUrl: './operation-entites.component.html',
  styleUrls: ['./operation-entites.component.scss'],
})
export class OperationEntitesComponent implements OnInit, OnDestroy {
  subscribe = new Subject();
  view$ = new BehaviorSubject<string>('list');
  loading$: Observable<boolean> = of(false);
  title: string = 'Nouvelle entité';
  titleIntervention: string = "Type d'intervention";

  newCardTitle: string = 'Nouvelle entité';
  newCardDescription: string = 'Ajouter une entité au projet';
  noModuleTitle: string = 'Aucune entité ajoutée';
  newCardDefinition: string =
    'Une entité est une structure, une entreprise, un établissement qui participe au projet.';

  @ViewChild('entiteFormTitle', { static: false })
  entiteFormTitle: TemplateRef<any> | any;

  @ViewChild('entiteInterventionFormTitle', { static: false })
  entiteInterventionFormTitle: TemplateRef<any> | any;

  currentSelected$ = new BehaviorSubject<any>(null);

  modalWidth: number = 850;

  @Input() operation$: Observable<OperationId | any> = of(null);
  entites$: Observable<any> = of(null);
  @Output() select = new EventEmitter<string>(false);
  constructor(
    private operationEntiteStore: Store<OperationEntiteState>,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private route: ActivatedRoute,
    private operationStore: Store<OperationState>
  ) {
    const data: Data = this.route.snapshot.data;
    this.operationStore.dispatch(
      fromOperationAction.setActviteRoute({ title: data?.title })
    );
  }

  ngOnInit(): void {
    this.getEntites();
    this.loadEntites();
  }
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getEntites(): void {
    this.entites$ = this.operationEntiteStore.select(
      fromOperationEntiteSelector.entites
    );
  }
  loadEntites(): void {
    this.operation$.pipe(takeUntil(this.subscribe)).subscribe((operation) => {
      if (!operation) return;
      this.operationEntiteStore.dispatch(
        fromOperationEntiteAction.loadOperationEntites({
          operationId: operation.id,
        })
      );
    });
  }

  onSelect(operation: OperationId, entite: any): void {
    if (!operation || !entite) return;
    this.view$.next('details');
    this.currentSelected$.next(entite);

    //this.select.emit('entiteItem');

    // this.operationStore.dispatch(
    //   fromOperationAction.loadEntiteOperation({
    //     operationId: operation.id,
    //     entiteId: entite.id,
    //   })
    // );
    this.view$.next('details');
  }

  onNew(operation: OperationId): void {
    if (!operation) return;
    this.title = 'Nouvelle entité';
    const modal = this.modal.create({
      nzContent: EntiteSelectorsComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.entiteFormTitle,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzBodyStyle: { height: '400px' },
    });
    const instance = modal.getContentComponent();

    instance.select.subscribe((entite: EntiteSMALLid): any => {
      if (!entite) {
        return null;
      } else {
        modal.close();
        this.onType(entite, operation);
      }
    });
  }

  onType(entite: EntiteSMALLid, operation: OperationId): void {
    if (!entite || !operation) return;
    this.titleIntervention = `Type d'intervention de ${entite?.etablissement?.denomination}`;
    const modal = this.modal.create({
      nzContent: EntiteValidatorComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.entiteInterventionFormTitle,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      // nzStyle: { top: '20px' },
    });
    const instance = modal.getContentComponent();
    instance.cancel.subscribe((event: any): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
    instance.validate.subscribe((intervention: any): any => {
      if (!intervention) {
        return null;
      } else {
        modal.close();
        this.onAdd(entite, intervention, operation);
      }
    });
  }

  onAdd(
    entite: EntiteSMALLid,
    intervention: any,
    operation: OperationId
  ): void {
    this.operationEntiteStore.dispatch(
      fromOperationEntiteAction.addOperationEntite({
        operation,
        entite,
        intervention,
      })
    );
  }

  onDelete(operation: OperationId, entite: any): void {
    if (!operation || !entite) return;
    this.operationEntiteStore.dispatch(
      fromOperationEntiteAction.deleteOperationEntite({
        operationId: operation.id,
        entiteId: entite.id,
      })
    );
  }

  onBack(): void {
    this.view$.next('list');
    this.currentSelected$.next(null);
  }
  onCancel(): void {}
}
