import { OperationNote } from './../../store/operation-note.model';
import { OperationNoteFormComponent } from './../operation-note-form/operation-note-form.component';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  Input,
  ViewChild,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';
import { OperationNoteId } from '../../store/operation-note.model';
import { OperationNoteState } from '../../store/operation-note.reducer';
import * as fromOperationNoteAction from '../../store/operation-note.actions';
import * as fromOperationNoteSelector from '../../store/operation-note.selectors';
import { OperationId } from 'src/app/components/operation/store/operation.model';
import { NzModalService } from 'ng-zorro-antd/modal';
import {
  NzTableFilterFn,
  NzTableFilterList,
  NzTableSortFn,
  NzTableSortOrder,
} from 'ng-zorro-antd/table';
import * as _ from 'lodash';
import { format, fromUnixTime } from 'date-fns';
import { fr } from 'date-fns/locale';
import { UntypedFormControl } from '@angular/forms';
import { first, last } from 'lodash';
import { OperationNoteSujetState } from 'src/app/components/operation-note-sujet/store/operation-note-sujet.reducer';
import * as fromOperationNoteSujetAction from 'src/app/components/operation-note-sujet/store/operation-note-sujet.actions';
import * as fromOperationNoteSujetSelector from 'src/app/components/operation-note-sujet/store/operation-note-sujet.selectors';
import { OperationState } from 'src/app/components/operation/store/operation.reducer';
import * as fromOperationSelector from 'src/app/components/operation/store/operation.selectors';

interface ColumnItem {
  name: string;
  sortOrder?: NzTableSortOrder;
  sortFn?: NzTableSortFn<OperationNoteId>;
  listOfFilter?: NzTableFilterList;
  filterFn?: NzTableFilterFn;
  filterMultiple?: boolean;
  sortDirections?: NzTableSortOrder[];
}

@Component({
  selector: 'app-operation-note-table',
  templateUrl: './operation-note-table.component.html',
  styleUrls: ['./operation-note-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationNoteTableComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() operation$: Observable<OperationId | any> =
    this.operationStore.select(fromOperationSelector.operation);
  @Input() scroll: any = { x: '1100px', y: '100%' };

  subscribe = new Subject();
  loading$: Observable<boolean> = of(false);
  notes$: Observable<OperationNoteId[] | any> = of([]);
  note$: Observable<OperationNoteId | any> = of(null);

  view$ = new BehaviorSubject('list');
  title: string = 'Nouvelle note';
  @ViewChild('noteFormTitle', { static: false })
  noteFormTitle: TemplateRef<any> | any;
  modalWidth: number = 780;

  //VARIABLES TABLE
  searchControl = new UntypedFormControl();
  searchValue: string = '';
  searchResponses: OperationNoteId[] | any = [];

  menuTitle: string = '';
  dropdownTitleVisible: boolean = false;

  noteTotal: number = 0;
  notePageCache = [];
  notePageIndexe = 1;
  noteNav = null;
  mynoteUser = null;
  notesLoading = true;
  noteFiltered = false;
  noteLastData: any = null;
  isFirstParticipant = false;
  noteFirstData: any = null;
  noteNotSelectedList: any[] = [];
  noteData: any[] = [];
  noteSort: string | null = '';
  noteSortValue: string | null = '';
  noteListTitle: any[] = [];
  noteListLastName: any[] = [];
  noteListDate: any[] = [];
  noteListCivilite: any[] = [];
  noteSearchCivilite: string = '';
  noteListOfSearch: string[] = [];
  titleList: string[] = [];
  notelistOfDisplayData = this.noteData ? [...this.noteData] : [];

  listOfColumns: ColumnItem[] = [];
  listOfData: OperationNoteId[] = [];

  expandSet = new Set<number>();

  constructor(
    private operationNoteStore: Store<OperationNoteState>,
    private operationStore: Store<OperationState>,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.getLoading();
    this.getAll();
    this.getOne();
    this.loadAll();
    this.setData();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getLoading(): void {
    this.loading$ = this.operationNoteStore.select(
      fromOperationNoteSelector.loading
    );
  }

  loadAll(): void {
    this.operation$
      .pipe(takeUntil(this.subscribe))
      .subscribe((operation: OperationId) => {
        if (!operation) return;
        const { id } = operation;
        this.operationNoteStore.dispatch(
          fromOperationNoteAction.loadOperationNotes({ operationId: id })
        );
      });
  }

  getAll(): void {
    this.notes$ = this.operationNoteStore.select(
      fromOperationNoteSelector.notes
    );
  }

  loadOne(operationId: string, note: OperationNoteId): void {
    if (!operationId || !note) return;
    this.operationNoteStore.dispatch(
      fromOperationNoteAction.loadOperationNote({
        operationId,
        noteId: note.id,
      })
    );
    this.onSwitchView('detail');
  }

  getOne(): void {
    this.note$ = this.operationNoteStore.select(fromOperationNoteSelector.note);
  }

  setData(): void {
    this.notes$
      .pipe(takeUntil(this.subscribe))
      .subscribe((items: OperationNoteId[]) => {
        this.notesLoading = true;

        if (items?.length) {
          this.noteData = items;
          this.noteTotal = items.length;
          this.noteLastData = last(items);
          this.noteFirstData = first(items);
          const titleList: any = [];
          const dateList: any = [];

          items.forEach((e) => {
            const objTitle = {
              text: e.title,
              value: e.title,
            };
            titleList.push(objTitle);

            const dateStart = format(
              fromUnixTime(e.dateStart['seconds']),
              'P',
              { locale: fr }
            );

            const dateTitle = {
              text: e.dateStart,
              value: e.dateStart,
            };
            dateList.push(dateTitle);
          });

          this.noteListTitle = _.uniqWith(titleList, _.isEqual);
          this.notelistOfDisplayData = this.noteData;

          this.notesLoading = false;

          this.listOfColumns = [
            {
              name: 'Titre',
              sortOrder: null,
              sortFn: (a: OperationNoteId, b: OperationNoteId) =>
                a.title.toLowerCase().localeCompare(b.title.toLowerCase()),
            },
            {
              name: 'Date',
              sortOrder: null,
              sortFn: (a: OperationNoteId, b: OperationNoteId) =>
                a.dateStart['seconds'] - b.dateStart['seconds'],
              sortDirections: ['descend', 'ascend'],
            },
          ];

          this.listOfData = items;
          this.searchResponses = [];
        } else {
          this.listOfColumns = [
            {
              name: 'Titre',
              sortOrder: null,
              sortFn: (a: any, b: any) =>
                a.title.toLowerCase().localeCompare(b.title.toLowerCase()),
            },
          ];

          this.listOfData = [];
          this.searchResponses = [];
          this.notesLoading = false;
        }
      });
  }

  onExpandChange(id: number, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }

  onSelect(operationId: string, note: OperationNoteId): void {
    if (!operationId || !note) return;
    this.loadOne(operationId, note);
  }

  onBack(): void {
    this.onSwitchView('list');
  }

  onNew(operation: OperationId): void {
    if (!operation) return;
    const modal = this.modal.create({
      nzContent: OperationNoteFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.noteFormTitle,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
    });
    const instance = modal.getContentComponent();
    instance.operation$ = of(operation);

    instance.cancel.subscribe((event: boolean): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
    instance.add.subscribe((note: any): any => {
      if (!note) {
        return null;
      } else {
        this.onAdd(operation, note);
        modal.close();
      }
    });
  }

  onAdd(operation: OperationId, note: OperationNote): void {
    if (!operation || !note) return;

    // this.operationNoteStore.dispatch(
    //   fromOperationNoteAction.addOperationNote({ operation, note })
    // );
  }

  onItemActions(
    event: string,
    operationId: string,
    note: OperationNoteId
  ): void {
    if (!event || !operationId || !note) return;

    const noteId: string = note.id;
    switch (event) {
      case 'copy':
        this.operationNoteStore.dispatch(
          fromOperationNoteAction.copyOperationNote({ operationId, noteId })
        );
        break;
      case 'delete':
        this.operationNoteStore.dispatch(
          fromOperationNoteAction.deleteOperationNote({
            operationId,
            id: note.id,
          })
        );
        break;

      default:
        break;
    }
  }

  onCancel(): void {
    return;
  }

  onSwitchView(view: string): void {
    this.view$.next(view);
  }
  onSelectDropdown(
    event: string,
    operation: OperationId,
    notes: OperationNoteId[]
  ): void {
    if (!event || !operation || !notes?.length) return;

    switch (event) {
      case 'deleteAll':
        const ids: string[] = notes.map((note) => note.id);
        const operationId: string = operation.id;
        this.operationNoteStore.dispatch(
          fromOperationNoteAction.deleteOperationNotes({ operationId, ids })
        );

        break;

      default:
        break;
    }
  }
}
