import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

@Component({
  selector: 'app-text-edithing',
  templateUrl: './text-edithing.component.html',
  styleUrls: ['./text-edithing.component.scss'],
})
export class TextEdithingComponent implements OnInit, OnDestroy {
  subscribe = new Subject();
  content: any = 'test update text';
  edithText: UntypedFormGroup = this.fb.group({});
  text: UntypedFormControl = new UntypedFormControl('');

  /**
   * Suggestion d'une personne (interlocuteur d'entreprise/ entreprise/ participant/ utilisateur) avec la touche @
   * EXEMPLE : [
    { id: 1, value: 'Fredrik Sundqvist', link: 'https://google.com' },
    { id: 2, value: 'Patrik Sjölin' }
  ];
   */
  @Input() atValues: any = [];

  /**
   * Suggestion d'une personne (interlocuteur d'entreprise/ entreprise/ participant/ utilisateur) avec la touche @
   * EXEMPLE : [
    { id: 3, value: 'Fredrik Sundqvist 2' },
    { id: 4, value: 'Patrik Sjölin 2' }
  ]
   */
  @Input() hashValues: any = [];

  quillConfig = {
    //toolbar: '.toolbar',
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ size: ['12px', '14px', '16px', '18px', '20px', '22px', '24px'] }], // toggled buttons
        ['code-block'],
        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: 'ordered' }, { list: 'bullet' }],
        //[{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        //[{ 'direction': 'rtl' }],                         // text direction

        [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
        [{ header: [1, 2, 3, 4, 5, 6, false] }],

        //[{ 'font': [] }],
        //[{ 'align': [] }],

        ['clean'], // remove formatting button

        //['link'],
        //['link', 'image', 'video']
      ],
    },

    // mention: {
    //   allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
    //   mentionDenotationChars: ['@', '#'],
    //   source: (searchTerm: any, renderList: any, mentionChar: any) => {
    //     let values;

    //     if (mentionChar === '@') {
    //       values = this.atValues;
    //     } else {
    //       values = this.hashValues;
    //     }

    //     if (searchTerm.length === 0) {
    //       renderList(values, searchTerm);
    //     } else {
    //       const matches = [];
    //       for (var i = 0; i < values.length; i++)
    //         if (
    //           ~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())
    //         )
    //           matches.push(values[i]);
    //       renderList(matches, searchTerm);
    //     }
    //   },
    // },
    // 'emoji-toolbar': true,
    // 'emoji-textarea': false,
    // 'emoji-shortname': true,
    keyboard: {
      bindings: {
        // shiftEnter: {
        //   key: 13,
        //   shiftKey: true,
        //   handler: (range, context) => {
        //     // Handle shift+enter
        //     console.log("shift+enter")
        //   }
        // },
        enter: {
          key: 13,
          handler: (range: any, context: any) => {
            return true;
          },
        },
      },
    },
  };

  @Input() update = new BehaviorSubject<string>('');
  @Input() save: boolean = false;
  @Input() autosave: boolean = false;
  @Input() server: any;
  @Input() size: string = '200px';

  @Output() add = new EventEmitter<any>();
  @Output() modify = new EventEmitter<any>();
  @Output() cancel = new EventEmitter<any>();
  @Output() updateBy = new EventEmitter<any>();
  @ViewChild('editor') editor: any;
  updateText: boolean = false;

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit() {
    this.formInit();
    //this.formCreate();
    this.updateContent();
    //this.onContentChanged();
    // this.onTextUpdate();
  }

  formInit(): void {
    this.text = new UntypedFormControl();
  }

  formCreate(): void {
    this.edithText = this.fb.group({
      text: this.text,
    });
  }

  updateContent(): void {
    this.update.pipe(takeUntil(this.subscribe)).subscribe((val: string) => {
      if (val) {
        this.content = val;
        this.updateText = true;
      } else {
        this.content = '';
        this.updateText = true;
      }
    });
  }

  onEditorTextChanged(event: any): void {
    const { html, text } = event;
    const textValue = text.slice(0, -1);
    const textHTML = html;
    this.add.emit({ textValue, textHTML });
  }

  onClear(event: any): void {
    event.preventDefault();
    this.content = null;
  }

  onContentChanged(): void {
    this.text.valueChanges.subscribe((text) => console.log(text));
    // this.edithText.valueChanges
    //   .pipe(takeUntil(this.subscribe))
    //   .subscribe((form) => {
    //     if (!form.text) {
    //       return null;
    //     }
    //     const { text } = form;
    //     const textValue = this.content;
    //     const textHTML = text;
    //     this.add.emit({ textValue, textHTML });
    //   });
  }

  textByUser(): void {
    this.edithText.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.subscribe)
      )
      .subscribe((val) => {
        if (val) {
          // const textValue = document.querySelector('.ql-editor').textContent;
          // const textHTML = document.querySelector('.ql-editor').innerHTML;
          // this.updateBy.emit({ textValue, textHTML });
        }
      });
  }

  compare_DATA_HOTFIRE(): void {
    this.edithText.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.subscribe)
      )
      .subscribe((val) => {
        if (val) {
          // const textValue = document.querySelector('.ql-editor').textContent;
          // const textHTML = document.querySelector('.ql-editor').innerHTML;
          document
            ?.querySelector('.ql-editor')
            ?.append('<h6 style="color: grey">Serve Charly</h6>');
          // const hotHTML = this.server.note.textHTML;
          // const hotValue = this.server.note.textValue;
          // console.log('HTML', { textHTML, hotHTML });
          // console.log('VALUE', { textValue, hotValue });
        }
      });
  }

  onEditorChanged(event: any): void {
    const textValue = this.editor.content;
    const textHTML = event.html;
    this.modify.emit({ textValue, textHTML });
  }

  onSubmit(event: any): any {
    event.preventDefault();
    event.stopPropagation();
    if (this.edithText.invalid) {
      return null;
    } else {
      // const textValue = document.querySelector('.ql-editor').textContent;
      // const textHTML = document.querySelector('.ql-editor').innerHTML;
      // this.add.emit({ textValue, textHTML });
    }
  }

  onUpdate(event: any): any {
    event.preventDefault();
    event.stopPropagation();
    if (this.edithText.invalid) {
      return null;
    } else {
      const textValue = document?.querySelector('.ql-editor')?.textContent;
      const textHTML = document?.querySelector('.ql-editor')?.innerHTML;
      this.modify.emit({ textValue, textHTML });
    }
  }

  onCancel(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.cancel.emit(true);
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }
}
