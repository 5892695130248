import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import { EChartsOption } from 'echarts';
import { BehaviorSubject, Subject, Observable, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { ThemeOption } from 'ngx-echarts';

export interface DataChart {
  name: string;
  value: number;
}

@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartsComponent implements OnInit, OnChanges, OnDestroy {
  subscribe = new Subject();
  mode$: Observable<string> = of('light');
  today: Date = new Date(Date.now());
  currentYear: string = format(new Date(Date.now()), 'yyyy');
  currentMonth: string = format(new Date(Date.now()), 'M');
  currentYearMonth = format(this.today, 'yyyy-MM', { locale: fr });
  currentColor: string = 'rgb(255, 170, 0)';
  standardColor: string = 'rgb(0,55,102)';
  colorsLine = ['#5470C6', '#EE6666'];
  colors = {
    greenDark: '#52B788',
    blueDark: '#062949',
    redDarkWarm: '#ED1C24',
    redDark: '#D62839',
    white: '#FDFFFC',
  };

  colorsArray: string[] = [
    '#062949',
    '#D62839',
    '#52B788',
    '#062949',
    '#30BCED',
    '#F3A712',
    '#119DA4',
    '#EF767A',
    '#CFD6EA',
    '#7D7E75',
    '#FF6666',
    '#2EC4B6',
    '#A14DA0',
    '#9D79BC',
    '#B91372',
    '#32908F',
    '#C33149',
    '#7189FF',
    '#70A9A1',
  ];

  @Input() heigth: string = '100%';
  @Input() width: string = '100%';
  @Input() icon: string = '';
  @Input() isTitle: boolean = false;
  @Input() isBorderless: boolean = true;
  @Input() isDownload: boolean = true;

  options: EChartsOption = {};
  theme: string | ThemeOption = 'roma';

  actionsMenu = [
    {
      label: 'Ligth',
      value: 'ligth',
    },
    {
      label: 'Roma',
      value: 'roma',
    },
    {
      label: 'Vintage',
      value: 'vintage',
    },
    {
      label: 'Wonderland',
      value: 'wonderland',
    },
    {
      label: 'Chalk',
      value: 'chalk',
    },
    {
      label: 'Macarons',
      value: 'macarons',
    },
    {
      label: 'Shine',
      value: 'shine',
    },
    {
      label: 'Chalk',
      value: 'chalk',
    },
    {
      label: 'Halloween',
      value: 'halloween',
    },
    {
      label: 'Essos',
      value: 'essos',
    },
    {
      label: 'Walden',
      value: 'walden',
    },
    {
      label: 'Infographic',
      value: 'infographic',
    },
    {
      label: 'Purple-Passion',
      value: 'purple-passion',
    },
    {
      label: 'Dark',
      value: 'dark',
    },
  ];

  @Input() source = new BehaviorSubject<any>(null);
  @Output() select = new EventEmitter<any>(false);

  @ViewChild('chart', { static: true }) chart: ElementRef = new ElementRef<any>(
    ''
  );
  @ViewChild('chartsContainerRef', { static: true })
  chartsContainerRef: ElementRef = new ElementRef<any>('');

  constructor() {}

  ngOnInit(): void {
    this.setChartByType();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  setChartByType(): void {
    this.source
      .pipe(takeUntil(this.subscribe))
      .subscribe((values: any): any => {
        if (!values) {
          return null;
        }
        if (values) {
          const { type } = values;
          switch (type) {
            case 'pie':
              this.setChartPie(values);
              break;
            case 'pieClassique':
              this.setChartPieStandard(values);
              break;
            case 'bar':
              this.setChartBar(values);
              break;
            case 'barSimpler':
              this.setChartBarSimpler(values);
              break;
            case 'barHorizontal':
              this.setChartBarHorizontal(values);
              break;
            case 'barVertical':
              this.setChartBarVertical(values);
              break;
            case 'donut':
              this.setChartDonut(values);
              break;
            case 'barMonths':
              this.setChartBarMonth({ ...values, type: 'bar' });
              break;
            case 'radar':
              this.setChartRadar(values);
              break;
            case 'time':
              this.setChartTimeLine(values);
              break;
            case 'barCompare':
              this.setChartDoubleBar(values);
              break;
            case 'barProgress':
              this.setChartBarProgress(values);
              break;

            default:
              break;
          }
        } else {
          return null;
        }
      });
  }

  setChartPie(source: any): void {
    const { title, type, data } = source;
    const sanity = data
      ? Object.entries(data).filter((el: any) => {
          return el[1] > 0;
        })
      : [];

    const series = sanity?.length
      ? sanity.map((el) => {
          const item: DataChart = {
            name: el[0] as string,
            value: el[1] as number,
          };
          return item;
        })
      : [];

    this.options = {
      title: {
        text: title ? title : '',
        subtext: '',
        left: 'center',
      },
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b} : {c} ({d}%)',
      },
      legend: {
        orient: 'horizontal',
        top: source?.legend?.top ? source.legend.top : 'bottom',
        left: 'auto',
        type: 'scroll',
      },
      width: source?.width ? source.width : 'auto',
      height: source?.height ? source.height : 'auto',
      series: [
        {
          name: title ? title : '',
          type: type,
          center: source?.center ? source.center : ['50%', '50%'],
          radius: source?.radius ? source.radius : '50%',
          data: series,
          label: {
            show: true,
            formatter: '{b} : {c} ({d}%)',
            position: 'outer',
            alignTo: 'edge',
            valueAnimation: true,
          },

          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
          right: 0,
          top: 0,
          bottom: 0,
        },
      ],
      themeName: 'roma',
    };
  }

  setChartPieStandard(source: any): void {
    const { title, type, data } = source;

    this.options = {
      title: {
        text: title ? title : '',
        subtext: '',
        left: 'center',
      },
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b} : {c} ({d}%)',
      },
      legend: {
        orient: 'horizontal',
        top: source?.legend?.top ? source.legend.top : 'bottom',
        left: 'auto',
        type: 'scroll',
      },
      width: source?.width ? source.width : 'auto',
      height: source?.height ? source.height : 'auto',
      series: [
        {
          name: title ? title : '',
          type: 'pie',
          center: source?.center ? source.center : ['50%', '50%'],
          radius: source?.radius ? source.radius : '50%',
          data: data,
          label: {
            show: true,
            formatter: '{b} : {c} ({d}%)',
            position: 'outer',
            alignTo: 'edge',
            valueAnimation: true,
          },

          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
          right: 0,
          top: 0,
          bottom: 0,
        },
      ],
    };
  }

  setChartDonut(source: any): void {
    const { title, type, data } = source;

    this.options = {
      title: {
        text: source?.chartsTitle ? source.chartsTitle : '',
        subtext: '',
        left: 'center',
      },
      tooltip: {
        trigger: 'item',
      },
      legend: {
        top: '5%',
        left: 'center',
        padding: 20,
      },
      toolbox: {
        show: true,
        feature: {
          magicType: { type: ['bar'], title: { bar: '' } },
          dataView: {
            readOnly: false,
            title: '',
            buttonTextColor: '#062949',
            buttonColor: '#ffff',
          },
          saveAsImage: {
            name: source?.option?.saveImageName
              ? source?.option.saveImageName
              : 'image_protein',
            title: '',
            type: 'png',
            backgroundColor: 'transparent',
            pixelRatio: 1,
            lang: ['fr'],
          },
        },
      },

      radius: source?.radius ? source.radius : 160,
      center: source?.center ? source.center : ['50%', '20%'],

      series: [
        {
          name: 'Access From',
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          top: 40,
          label: {
            show: false,
            position: 'center',
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '15',
              fontWeight: 'bold',
            },
          },
          labelLine: {
            show: false,
          },
          data: data,
        },
      ],
    };
  }

  setChartBarHorizontal(source: any): void {
    const { title, chartsTitle, type, data } = source;

    this.options = {
      title: {
        text: chartsTitle ? chartsTitle : '',
        left: 'center',
        padding: -10,
        top: '10%',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          // Use axis to trigger tooltip
          type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
        },
      },

      legend: {
        top: '5%',
        left: 'center',
        padding: 2,
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      xAxis: {
        type: 'value',
      },
      yAxis: {
        type: 'category',
        data: data.yAxis,
      },
      series: data.series.map((el: any) => {
        return {
          name: el?.title,
          type: 'bar',
          stack: 'total',
          top: '5%',
          label: {
            show: true,
          },
          emphasis: {
            focus: 'series',
          },
          data: el?.data,
        };
      }),
    };
  }

  setChartBar(source: any): void {
    const { title, type, data } = source;
    const sanity = data
      ? Object.entries(data).filter((el: any) => el[1] > 0)
      : [];
    const series = data
      ? sanity.map((el) => {
          const item: DataChart = {
            name: el[0] as string,
            value: el[1] as number,
          };
          return item;
        })
      : [];

    const xAxis = data ? sanity.map((el) => el[0]) : [];
    const yAxis = data ? sanity.map((el) => el[1]) : [];
    // {
    //   value: 200,
    //   itemStyle: {
    //     color: '#a90000'
    //   }
    // },

    this.options = {
      title: {
        text: '',
        subtext: '',
        left: 'center',
      },
      tooltip: {
        trigger: 'item',
      },
      legend: {
        orient: 'horizontal',
        top: 'bottom',
        left: 'auto',
      },
      xAxis: {
        type: 'category',
        data: xAxis,
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          name: '',
          type: type,
          data: yAxis,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
    };
  }

  setChartBarSimpler(source: any): void {
    const { title, type, data } = source;

    const xAxis = data ? data?.xAxis : [];
    const yAxis = data ? data?.yAxis : [];

    this.options = {
      title: {
        text: '',
        subtext: '',
        left: 'center',
      },
      tooltip: {
        trigger: 'item',
      },
      toolbox: {
        show: true,
        feature: {
          dataView: { show: false, readOnly: false, title: '' },
          magicType: {
            show: true,
            type: ['line', 'bar'],
            title: { line: '', bar: '' },
          },
          saveAsImage: {
            show: true,
            title: title ? title : 'image_protein',
            backgroundColor: 'transparent',
          },
        },
      },
      dataZoom: [
        {
          show: true,
          realtime: true,
          start: 0,
          end: 100,
          xAxisIndex: [0, 1],
        },
      ],
      grid: {
        left: 60,
        right: 50,
        height: '55%',
        top: 80,
      },
      legend: {
        orient: 'horizontal',
        top: 'bottom',
        left: 'auto',
      },
      xAxis: {
        type: 'category',
        data: xAxis,
        axisLabel: {
          interval: 0,
          margin: 20,
          rotate: source?.labelRotate ? source?.labelRotate : 0,
          formatter: function (params: any) {
            var newParamsName = ''; // The final concatenated string
            var paramsNameNumber = params.length; // Number of actual labels
            var provideNumber = source?.numberObWord ? source.numberObWord : 20; // Number of words per line
            var rowNumber = Math.ceil(paramsNameNumber / provideNumber); // If you change lines, you need to show a few lines and take them up.
            /**
             * Determine whether the number of tags is greater than the specified number, and if it is greater than the number of new lines, if it is not greater than, that is, equal to or less than, return to the original tag.
             */
            // The condition is equal to rowNumber > 1
            if (paramsNameNumber > provideNumber) {
              /** Loop each row, p represents the row */
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = ''; // A string representing each intercept
                var start = p * provideNumber; // Starting interception position
                var end = start + provideNumber; // End Interception Position
                // The index value of the last row is specially handled here
                if (p == rowNumber - 1) {
                  // No change of line for the last time
                  tempStr = params.substring(start, paramsNameNumber);
                } else {
                  // Each concatenation of strings and line breaks
                  tempStr = params.substring(start, end) + '\n';
                }
                newParamsName += tempStr; // The final string
              }
            } else {
              // Assign the value of the old tag to the new tag
              newParamsName = params;
            }
            //Returns the final string
            return newParamsName;
          },
        },
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          name: '',
          type: 'bar',
          data: yAxis,
          color: this.colors.greenDark,

          label: {
            show: true,
            position: 'inside',
            formatter:
              source?.typeValue === 'percent'
                ? '{c}  {name|{a}%}'
                : source?.typeValue === 'hours'
                ? '{c}  {name|{a}h}'
                : '{c}  {name|{a}}',
            fontSize: source?.fontSize ? source?.fontSize : 12,
            fontWeight: 'normal',
            color: this.colors.white,
            rotate: source?.rotate
              ? source?.rotate
              : source?.rotate === 0
              ? 0
              : 90,
            rich: {
              name: {},
            },
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
    };
  }

  setChartBarProgress(source: any): void {
    const { title, type, data } = source;

    const xAxis = data ? data.map((el: any) => el.label) : [];
    const yAxis = data ? data.map((el: any) => el.value) : [];

    this.options = {
      title: {
        text: '',
        subtext: '',
        left: 'center',
      },
      tooltip: {
        trigger: 'item',
      },
      toolbox: {
        show: true,
        feature: {
          dataView: { show: true, readOnly: false, title: '' },
          magicType: {
            show: true,
            type: ['line'],
            title: { line: '' },
          },
          restore: { show: true, title: '' },
          saveAsImage: {
            show: true,
            title: '',
            backgroundColor: 'transparent',
          },
        },
      },
      calculable: true,

      legend: {
        orient: 'horizontal',
        top: 'bottom',
        left: 'auto',
      },
      xAxis: {
        type: 'category',
        data: xAxis,
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          name: '',
          type: 'bar',
          label: {
            show: true,
            position: 'top',
            formatter: '{c}  {name|{a}%}',
            fontSize: 16,
            fontWeight: 'bold',
            rich: {
              name: {},
            },
          },
          itemStyle: {
            color: this.colors.greenDark,
          },
          data: yAxis,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
    };
  }
  setChartDoubleBar(source: any): void {
    const { title, type, data } = source;
    const sanity = data
      ? Object.entries(data).filter((el: any) => el[1] > 0)
      : [];
    const series = data
      ? sanity.map((el) => {
          const item: DataChart = {
            name: el[0] as string,
            value: el[1] as number,
          };
          return item;
        })
      : [];

    const xAxis = data ? sanity.map((el) => el[0]) : [];
    const yAxis = data ? sanity.map((el) => el[1]) : [];

    this.options = {
      title: {
        text: '',
        subtext: '',
      },
      tooltip: {
        trigger: 'axis',
      },
      legend: {
        data: ['Objectifs', 'Réalisations', 'Progressions'],
      },
      toolbox: {
        show: true,
        feature: {
          dataView: { show: true, readOnly: false, title: '' },
          // magicType: {
          //   show: true,
          //   type: ['line', 'bar'],
          //   title: { line: '', bar: '' },
          // },
          //restore: { show: true, title: '' },
          saveAsImage: {
            show: true,
            title: 'objectifs_realisation_progression',
            backgroundColor: 'transparent',
          },
        },
      },
      calculable: true,
      xAxis: [
        {
          type: 'category',
          // prettier-ignore
          data: data?.items,
        },
      ],
      yAxis: [
        {
          type: 'value',
        },
      ],
      series: [
        {
          name: 'Objectifs',
          type: 'bar',
          barGap: 0,
          emphasis: {
            focus: 'series',
          },
          data: data?.objectifs,
          label: {
            show: true,
            position: 'top',
            fontSize: 12,
            fontWeight: 'bold',
            color: this.colors.blueDark,
            rich: {
              name: {},
            },
          },
          labelLayout: {
            draggable: true,
          },
          // markPoint: {
          //   data: [
          //     { type: 'max', name: 'Max' },
          //     { type: 'min', name: 'Min' },
          //   ],
          // },
          // markLine: {
          //   data: [{ type: 'max', name: 'max' }],
          // },
          itemStyle: {
            color: this.colors.blueDark,
          },
        },
        {
          name: 'Réalisations',
          type: 'bar',
          barGap: 0,
          emphasis: {
            focus: 'series',
          },
          data: data?.realisations,
          label: {
            show: true,
            position: 'top',
            fontSize: 12,
            fontWeight: 'bold',
            color: this.colors.blueDark,
            rich: {
              name: {},
            },
          },
          labelLayout: {
            draggable: true,
          },
          // markPoint: {
          //   data: [
          //     { name: 'Max', value: 182.2, xAxis: 7, yAxis: 183 },
          //     { name: 'Min', value: 2.3, xAxis: 11, yAxis: 3 },
          //   ],
          // },
          // markLine: {
          //   data: [{ type: 'max', name: 'max' }],
          // },
          itemStyle: {
            color: this.colors.redDark,
          },
        },
        {
          name: 'Progressions',
          type: 'bar',
          barGap: 0,
          emphasis: {
            focus: 'series',
          },
          data: data?.progressions,
          label: {
            show: true,
            position: 'top',
            //formatter: '{c}  {name|{a}%}',
            formatter: '{c}{name|%}',
            fontSize: 16,
            fontWeight: 'bold',
            color: this.colors.blueDark,
            rich: {
              name: {},
            },
          },
          itemStyle: {
            color: this.colors.greenDark,
          },
        },
      ],
    };
  }

  setChartBarVertical(source: any): void {
    const { title, type, data, option } = source;
    const sanity = data
      ? Object.entries(data).filter((el: any) => el[1] > 0)
      : [];

    const xAxisData: any[] = data ? sanity.map((el) => el[0]) : [];
    const yAxisData: any[] = data ? sanity.map((el) => el[1]) : [];

    this.options = {
      title: {
        text: '',
        subtext: '',
        left: 'center',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },

      grid: { containLabel: true, left: 10 },
      toolbox: {
        show: true,
        feature: {
          // magicType: { type: ['line', 'bar'], title: { line: '', bar: '' } },
          dataView: {
            readOnly: false,
            title: '',
            // lang: ['french'],
            buttonTextColor: '#062949',
            buttonColor: '#ffff',
          },
          saveAsImage: {
            name: option?.saveImageName
              ? option.saveImageName
              : 'image_protein',
            title: '',
            type: 'png',
            backgroundColor: 'transparent',
            pixelRatio: 1,
            lang: ['fr'],
          },
        },
      },
      xAxis: { data: xAxisData },
      yAxis: {
        type: 'value',
      },

      visualMap: option?.visualMap,
      dataset: {
        source: [option?.columns, ...sanity],
      },
      series: [
        {
          type: 'bar',
          name: '',
          label: option?.label,
          encode: option?.encode ? option?.encode : null,
          data: yAxisData,
        },
      ],
    };
  }

  setChartBarMonth(source: any): void {
    const { title, type, data } = source;
    const sanity = data
      ? Object.entries(data).filter((el: any) => el[1] > 0)
      : [];
    const months = [
      'Jan',
      'Fév',
      'Mar',
      'Avr',
      'Mai',
      'Jui',
      'Jui',
      'Aôu',
      'Sep',
      'Oct',
      'Nov',
      'Déc',
    ];

    const monthsValue = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    const series = sanity?.length
      ? sanity.map((el) => {
          const item: DataChart = {
            name: el[0] as string,
            value: el[1] as number,
          };
          return item;
        })
      : months;

    const xAxis = sanity?.length
      ? sanity.map((el) =>
          format(new Date(`${this.currentYear}, ${el[0]}`), 'MMM', {
            locale: fr,
          })
        )
      : [];
    const yAxis = sanity?.length
      ? sanity.map((el) =>
          el[0] === this.currentMonth
            ? {
                value: el[1],
                itemStyle: {
                  color: this.currentColor,
                },
              }
            : el[1]
        )
      : monthsValue;

    this.options = {
      title: {
        text: title ? title : '',
        subtext: '',
        left: 'center',
      },
      tooltip: {
        trigger: 'item',
      },

      xAxis: {
        type: 'category',
        data: xAxis,
      },
      yAxis: {
        type: 'value',
      },
      width: source?.width ? source.width : 'auto',
      height: source?.height ? source.height : 'auto',

      series: [
        {
          name: title ? title : '',
          type: type,
          data: yAxis,
          label: {
            show: true,
            valueAnimation: true,
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
    };
  }

  setChartRadar(source: any): void {
    const { title, type, data, isLegend, legend, indicator, serieName } =
      source;
    this.options = {
      title: {
        text: title,
      },
      legend: {
        data: legend?.length ? legend : [],
      },
      tooltip: {
        trigger: 'none',
        axisPointer: {
          type: 'cross',
        },
      },

      toolbox: {
        show: source?.option?.toolbox,
        feature: {
          dataView: source?.option?.dataView
            ? {
                readOnly: false,
                title: '',
                buttonTextColor: '#062949',
                buttonColor: '#ffff',
              }
            : {},
          saveAsImage: source?.option?.saveAsImage
            ? {
                name: source?.option?.saveImageName
                  ? source?.option.saveImageName
                  : 'projection',
                title: '',
                type: 'png',
                backgroundColor: 'transparent',
                pixelRatio: 1,
                lang: ['fr'],
              }
            : {},
        },
      },
      radar: {
        // shape: 'circle',
        indicator: indicator?.length ? indicator : [],
        radius: source?.radius ? source.radius : 160,
        center: source?.center ? source.center : ['50%', '20%'],
        axisName: {
          color: '#fff',
          backgroundColor: '#062949',
          borderRadius: 12,
          padding: [4, 5],
          fontSize: 14,
        },
      },
      series: [
        {
          name: serieName,
          type: type,
          data: data,
        },
      ],
    };
  }

  setChartTimeLine(source: any): void {
    const {
      title,
      type,
      data,
      option,
      isLegend,
      legend,
      indicator,
      serieName,
    } = source;
    const currentDate = data?.dates?.find(
      (date: string) => date === this.currentYearMonth
    );
    const currentDateIndex = data?.dates?.length
      ? data.dates.indexOf(currentDate)
      : 0;

    const lastDateIndex = data?.dates?.length;

    const currentObjectifs = data?.objectifs?.length
      ? data?.objectifs.find(
          (objectif: any, index: number) => index === currentDateIndex
        )
      : 0;
    const currentRealisation = data?.realisations?.length
      ? data?.realisations.find(
          (realisation: any, index: number) => index === currentDateIndex
        )
      : 0;
    this.options = {
      color: this.colorsLine,
      tooltip: {
        trigger: 'none',
        axisPointer: {
          type: 'cross',
        },
      },
      legend: {},

      toolbox: {
        show: source?.option?.toolbox,
        feature: {
          magicType: { type: ['line', 'bar'], title: { line: '', bar: '' } },
          dataView: source?.option?.dataView
            ? {
                readOnly: false,
                title: '',
                buttonTextColor: '#062949',
                buttonColor: '#ffff',
              }
            : {},
          saveAsImage: source?.option?.saveAsImage
            ? {
                name: source?.option?.saveImageName
                  ? source?.option.saveImageName
                  : 'projection',
                title: '',
                type: 'png',
                backgroundColor: 'transparent',
                pixelRatio: 1,
                lang: ['fr'],
              }
            : {},
        },
      },
      calculable: true,
      dataZoom: [
        {
          show: true,
          realtime: true,
          start: 0,
          end: 100,
          xAxisIndex: [0, 1],
        },
      ],
      grid: {
        left: 60,
        right: 50,
        height: '55%',
        top: 80,
      },
      xAxis: [
        {
          type: 'category',
          axisTick: {
            alignWithLabel: true,
          },
          axisLine: {
            onZero: false,
            lineStyle: {
              color: this.colors.blueDark,
            },
          },
          axisPointer: {
            label: {
              margin: 10,
              formatter: function (params) {
                return (
                  'Théorique  ' +
                  params.value +
                  (params.seriesData.length
                    ? '：' + params.seriesData[0].data
                    : '')
                );
              },
              fontWeight: 'bold',
            },
          },
          // prettier-ignore

          data: data?.dates ? data.dates : [],
        },
        {
          type: 'category',
          axisTick: {
            alignWithLabel: true,
          },
          axisLine: {
            onZero: false,
            lineStyle: {
              color: this.colors.blueDark,
            },
          },
          axisPointer: {
            label: {
              formatter: function (params) {
                return (
                  'Réalisation  ' +
                  params.value +
                  (params.seriesData.length
                    ? '：' + params.seriesData[0].data
                    : '')
                );
              },
            },
          },
          // prettier-ignore
          data: [],
        },
      ],
      yAxis: [
        {
          type: 'value',
        },
      ],
      series: [
        {
          name: 'Réalisations',
          type: 'line',
          smooth: true,
          color: this.colors.blueDark,
          emphasis: {
            focus: 'series',
          },
          label: {
            show: option?.label,
          },
          data: data?.realisations?.length ? data?.realisations : [],
          markPoint: option?.markPoint
            ? {
                symbol: 'pin',
                label: {
                  fontWeight: 'bold',
                  //color: this.colors.white,
                },

                data: [
                  {
                    name: 'Réalisation',
                    value: currentRealisation,
                    xAxis: currentDateIndex,
                    yAxis: currentRealisation,
                  },
                ],
              }
            : {},
        },
        {
          name: 'Objectifs',
          type: 'line',
          smooth: true,
          color: this.colors.greenDark,
          label: {
            show: option?.label,
            minMargin: 50,
            distance: 5,
          },
          markPoint: option?.markPoint
            ? {
                symbol: 'pin',
                symbolKeepAspect: true,

                label: {
                  fontWeight: 'bold',

                  color: this.colors.blueDark,
                  // formatter: ['{c |number}'].join('\n'),
                },
                data: [
                  {
                    name: 'Théorique',
                    value: currentObjectifs,
                    xAxis: currentDateIndex,
                    yAxis: currentObjectifs,
                  },
                  {
                    name: 'Objectif',
                    value: data?.objectifValue,
                    xAxis: lastDateIndex - 1,
                    yAxis: data?.objectifValue,
                  },
                ],
              }
            : {},

          emphasis: {
            focus: 'series',
          },
          data: data?.objectifs?.length ? data?.objectifs : [],
        },
      ],
    };
  }

  setChartGannt(source: any): void {
    this.options = {};
  }

  onSelect(event: any): void {
    this.select.emit(event);
  }

  onAction(event: string): void {
    if (event === 'exporter') {
      this.onDownload();
    } else {
      this.theme = event.toLowerCase();
    }
  }

  onDownload(): void {
    var a = document.createElement('a');
    const element = this.chart.nativeElement;
    a.href = element.toBase64Image();
    a.download = 'chartDowload.png';

    a.click();
  }

  onfullScreenON(): void {}
  onfullScreenOFF(): void {
    let elem = this.chartsContainerRef.nativeElement;
    let methodToBeInvoked =
      elem.requestFullscreen ||
      elem.requestFullscreen ||
      elem['mozRequestFullscreen'] ||
      elem['msRequestFullscreen'];
    if (methodToBeInvoked) methodToBeInvoked.call(elem);
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }
}
