<div class="space-y-16">
  <div>
    <app-fiches-de-poste-item-header
      *ngIf="user$ | async as user"
      (dashboardSelect)="onChangesTabs($event)"
      (back)="onBack()"
      (actions)="onDropdown($event, fiche, user, operation, isOperation, users)"
      (newActions)="onNewActions($event, fiche, user, operation, users)"
      (exportActions)="
        onExportActions($event, fiche, user, operation, candidats, users)
      "
      [fiche]="fiche"
    ></app-fiches-de-poste-item-header>
  </div>
  <div [ngSwitch]="currentTabView">
    <div *ngSwitchCase="'candidatures'">
      <div *ngTemplateOutlet="candidatsTemplate"></div>
    </div>
    <div *ngSwitchCase="'besoins'">
      <app-fiches-de-poste-item-entreprise
        [fiche]="fiche"
      ></app-fiches-de-poste-item-entreprise>
      <app-fiches-de-poste-item-contrat
        [fiche]="fiche"
      ></app-fiches-de-poste-item-contrat>
    </div>
    <div *ngSwitchCase="'notes'">
      <app-table-suivis
        [source]="notes"
        [isCanAdd]="false"
        [newCardDefinition]="noteDefinition"
        [newCardDescription]="noteDescription"
        (update)="onUpdateNoteFiche($event, fiche)"
        (delete)="onDeleteNoteFiche($event, fiche)"
        (deleteAll)="onDeleteAllNoteFiche($event, fiche)"
      ></app-table-suivis>
    </div>
    <div *ngSwitchCase="'diffusions'">
      <app-diffusion-list
        [diffusions]="diffusions"
        [loading$]="diffusionsLoading$"
        (update)="onUpdateDiffusion($event, fiche)"
        (delete)="onDeleteDiffusion($event, fiche)"
      ></app-diffusion-list>
    </div>
    <div *ngSwitchCase="'terme'"></div>
    <div *ngSwitchDefault></div>
  </div>
</div>

<!-- <app-item-tabset
  (select)="onChangesTabs($event)"
  [tabs]="[
    {
      name: 'Candidatures',
      template: itemFicheDePosteCandidatures,
      count: candidats | size
    },
    {
      name: 'Diffusions',
      template: itemFicheDePosteDiffusions,
      count: diffusions.length
    },
    {
      name: 'Notes',
      template: itemFicheDePosteNotes,
      count: notes | size
    },

    {
      name: 'Entreprise',
      template: itemFicheDePosteEntreprise
    },
    {
      name: 'Poste',
      template: itemFicheDePosteDetails
    },
    {
      name: 'Contrat',
      template: itemFicheDePosteContrat
    }
  ]"
></app-item-tabset> -->

<ng-template #itemFicheDePosteEntreprise>
  <app-fiches-de-poste-item-entreprise
    [fiche]="fiche"
  ></app-fiches-de-poste-item-entreprise>
</ng-template>
<ng-template #itemFicheDePosteContrat>
  <app-fiches-de-poste-item-contrat
    [fiche]="fiche"
  ></app-fiches-de-poste-item-contrat>
</ng-template>
<ng-template #itemFicheDePosteDetails>
  <app-fiches-de-poste-item-detail
    [fiche]="fiche"
  ></app-fiches-de-poste-item-detail>
</ng-template>
<ng-template #itemFicheDePosteCandidatures>
  <app-candidature-list
    [adresseMission]="{
      latitude: fiche?.mission?.adresse?.adresse?.geometry?.coordinates[0],
      longitude: fiche?.mission?.adresse?.adresse?.geometry?.coordinates[1]
    }"
    [candidats]="candidats"
    [loading$]="candidatsLoading$"
    (add)="onAddCandidat($event, fiche)"
    (update)="onUpdateCandidat($event, fiche)"
    (delete)="onDeleteCandidat($event, fiche)"
    (deleteAll)="onDeleteCandidats($event, fiche)"
  ></app-candidature-list>
</ng-template>
<ng-template #itemFicheDePosteDiffusions>
  <app-diffusion-list
    [diffusions]="diffusions"
    [loading$]="diffusionsLoading$"
    (update)="onUpdateDiffusion($event, fiche)"
    (delete)="onDeleteDiffusion($event, fiche)"
  ></app-diffusion-list>
</ng-template>

<ng-template #itemFicheDePosteNotes>
  <app-table-suivis
    [source]="notes"
    [isCanAdd]="false"
    [newCardDefinition]="noteDefinition"
    [newCardDescription]="noteDescription"
    (update)="onUpdateNoteFiche($event, fiche)"
    (delete)="onDeleteNoteFiche($event, fiche)"
    (deleteAll)="onDeleteAllNoteFiche($event, fiche)"
  ></app-table-suivis>
</ng-template>
<ng-template #itemFicheDePosteTasks>
  <app-task-kanban
    (add)="onAddTaskFiche($event)"
    (update)="onUpdateTaskFiche($event)"
    (delete)="onDeleteTaskFiche($event)"
    (deleteAll)="onDeleteAllTaskFiche($event)"
  ></app-task-kanban>
</ng-template>

<ng-template #ficheDePosteExportTitle>
  <app-header-title
    [icon]="'file_download'"
    [title]="title"
    [subtitle]="true"
  ></app-header-title>
</ng-template>
<ng-template #ficheDePosteFormTitle>
  <app-header-title
    [icon]="'ballot'"
    [title]="titleUpdate"
    [subtitle]="true"
  ></app-header-title>
</ng-template>

<ng-template #ficheDePosteCandidatFormTitle>
  <app-header-title
    [icon]="'person'"
    [title]="titleCandidat"
    [subtitle]="true"
  ></app-header-title>
</ng-template>

<ng-template #diffusionFormTitle>
  <app-header-title
    [icon]="'send'"
    [title]="titleDiffusion"
    [subtitle]="true"
  ></app-header-title>
</ng-template>

<ng-template #noteFormTitle>
  <app-header-title
    [icon]="'description'"
    [title]="titleNote"
    [subtitle]="true"
  ></app-header-title>
</ng-template>

<ng-template #ficheDePosteCandidatsExportFormTitle>
  <app-header-title
    [icon]="'file_download'"
    [title]="titleCandidatsExport"
    [subtitle]="true"
  ></app-header-title>
</ng-template>
