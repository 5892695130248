<form nz-form [formGroup]="rolesForm">
  <div formArrayName="users" class="roleContainer">
    <ng-container *ngFor="let item of memberRoles.controls; let i = index">
      <div [formGroupName]="i">
        <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
          <nz-col nzSpan="2">
            <nz-avatar
              [nzSrc]="item?.value?.user?.photo ? item.value.user.photo : ''"
              [nzText]="!item?.value?.user?.photo ? item.value.user.avatar : ''"
              nzSize="large"
              [ngStyle]="{
                'background-color': item?.value?.user?.setting?.color
                  ? item.value.user.setting.color
                  : defaultColor,
                color: '#fff'
              }"
            ></nz-avatar>
          </nz-col>
          <nz-col nzSpan="22">
            <h5 nz-typography>{{ item.value.user.displayName }}</h5>
          </nz-col>
        </nz-row>
        <nz-space [nzSize]="80"></nz-space>
        <nz-row nzAlign="middle" nzGutter="8" nzJustify="center">
          <nz-col nzSpan="11">
            <nz-form-item>
              <nz-form-label nzRequired>Rôle</nz-form-label>
              <nz-form-control>
                <input
                  nz-input
                  nzBorderless
                  formControlName="role"
                  placeholder="Rôle..."
                />
              </nz-form-control>
            </nz-form-item>
          </nz-col>
          <nz-col nzSpan="5" class="tagClass">
            <nz-tag
              nzMode="checkable"
              [nzChecked]="memberRoles.controls[i].value['isAdmin']"
              [nzColor]="
                memberRoles.controls[i].value['isAdmin']
                  ? 'success'
                  : defaultColor
              "
              (nzCheckedChange)="onSetRole($event, 'isAdmin', i)"
            >
              Administrateur
            </nz-tag>
          </nz-col>

          <nz-col nzSpan="3" class="tagClass">
            <nz-tag
              nzMode="checkable"
              [nzChecked]="memberRoles.controls[i].value['canRead']"
              [nzColor]="
                memberRoles.controls[i].value['canRead']
                  ? 'success'
                  : defaultColor
              "
              (nzCheckedChange)="onSetRole($event, 'canRead', i)"
            >
              Lecture
            </nz-tag>
          </nz-col>
          <nz-col nzSpan="3" class="tagClass">
            <nz-tag
              nzMode="checkable"
              [nzChecked]="memberRoles.controls[i].value['canWrite']"
              [nzColor]="
                memberRoles.controls[i].value['canWrite']
                  ? 'success'
                  : defaultColor
              "
              (nzCheckedChange)="onSetRole($event, 'canWrite', i)"
            >
              Écriture
            </nz-tag>
          </nz-col>
          <nz-col nzSpan="2" class="tagClass">
            <button nz-button nzShape="circle" (click)="onRemoveMember(i)">
              <i
                nz-icon
                nzType="delete"
                nz-tooltip
                [nzTooltipMouseEnterDelay]="0.5"
                nzTooltipPlacement="right"
                nzTooltipTitle="Retirer l'intervenant"
              ></i>
            </button>
          </nz-col>
        </nz-row>
      </div>
      <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
    </ng-container>
  </div>
</form>

<nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
<nz-row nzAlign="middle" nzGutter="8" nzJustify="end">
  <nz-col nzSpan="16"> </nz-col>
  <nz-col nzSpan="4">
    <button nz-button nzBlock nzType="text" (click)="onCancel()">
      Annuler
    </button>
  </nz-col>
  <nz-col nzSpan="4">
    <button
      nz-button
      nzBlock
      nzType="primary"
      (click)="onValidate()"
      [disabled]="!rolesForm.valid"
    >
      Valider
    </button>
  </nz-col>
</nz-row>

<ng-template #noData>
  <app-no-result
    [icon]="'group'"
    [description]="'Aucun collaborateur sélectionné'"
    [isButton]="false"
  ></app-no-result>
</ng-template>
