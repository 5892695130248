import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { EMPTY, from, lastValueFrom, Observable, of } from 'rxjs';
import { ParticipantState } from '../../components/participant/store/participant.reducer';
import * as fromParticipantAction from '../../components/participant/store/participant.actions';
import * as fromParticipantSelector from '../../components/participant/store/participant.selectors';
import { ParticipantId } from '../../components/participant/store/participant.model';

@Injectable({
  providedIn: 'any',
})
export class ParticipantResolverService {
  constructor(
    private participantStore: Store<ParticipantState>,
    private router: Router
  ) {}

  resolve(
    route: ActivatedRouteSnapshot
  ):
    | Observable<ParticipantId>
    | Promise<ParticipantId | any>
    | ParticipantId
    | any {
    let id = route?.params?.['id'];

    let find = lastValueFrom(this.loadParticipant(id))
      .then(() => {
        return this.getParticipant();
      })
      .catch(() => {
        this.router.navigate([`/participants`]);
        return EMPTY;
      });

    return from(find);
  }

  getParticipant(): Observable<ParticipantId | any> {
    return this.participantStore.select(fromParticipantSelector.participant);
  }

  loadParticipant(id: string): Observable<any> {
    return of(
      this.participantStore.dispatch(
        fromParticipantAction.loadParticipant({ id })
      )
    );
  }
}
