import { Injectable } from '@angular/core';
import {
  Firestore,
  doc,
  docSnapshots,
  addDoc,
  deleteDoc,
} from '@angular/fire/firestore';
import { Store, select } from '@ngrx/store';
import { from, Observable, of } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { UserState } from '../components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';

@Injectable({
  providedIn: 'any',
})
export class FavorisService {
  constructor(private userStore: Store<UserState>, private db: Firestore) {}

  getEntites(): Observable<any> {
    try {
      return this.userStore.pipe(
        select(fromUserSelector.user),
        switchMap((user) => {
          if (!user) of([]);

          const entitesDoc = doc(this.db, `users/${user.id}/favoris/entites`);

          return docSnapshots(entitesDoc).pipe(
            map((snap) => {
              const data = snap.data() as any;
              const id = snap.id;
              return { id, ...data };
            })
          );
        })
      );
    } catch (err) {
      return of(err);
    }
  }

  getParticipants(): Observable<any> {
    try {
      return this.userStore.pipe(
        select(fromUserSelector.user),
        switchMap((user) => {
          if (!user) {
            return of(null);
          } else {
            const entitesDoc = doc(
              this.db,
              `users/${user.id}/favoris/participants`
            );

            return docSnapshots(entitesDoc).pipe(
              map((snap) => {
                const data = snap.data() as any;
                const id = snap.id;
                return { id, ...data };
              })
            );
          }
        })
      );
    } catch (err) {
      return of(err);
    }
  }

  addFavori(): Observable<any> {
    try {
      return of(null);
    } catch (err) {
      return of(err);
    }
  }

  deleteFavori(): Observable<any> {
    try {
      return of(null);
    } catch (err) {
      return of(err);
    }
  }

  deleteAllFavoris(type: string): Observable<any> {
    try {
      try {
        return this.userStore.pipe(
          select(fromUserSelector.user),
          switchMap((user) => {
            if (!user) {
              return of(null);
            } else {
              if (type === 'entites') {
                const entitesDoc = doc(
                  this.db,
                  `users/${user.id}/favoris/entites`
                );

                return from(deleteDoc(entitesDoc).catch((error) => error));
              } else {
                const participantDoc = doc(
                  this.db,
                  `users/${user.id}/favoris/participants`
                );

                return from(deleteDoc(participantDoc).catch((error) => error));
              }
            }
          })
        );
      } catch (err) {
        return of(err);
      }
    } catch (err) {
      return of(err);
    }
  }
}
