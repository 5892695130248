<nz-card
  nzHoverable
  [nzTitle]="listCardCoverTitleTpl"
  [nzCover]="listCardCoverTpl"
  [nzExtra]="listCardExtra"
  nzSize="small"
>
</nz-card>

<ng-template #listCardCoverTitleTpl>
  <ng-container *ngTemplateOutlet="title"></ng-container>
</ng-template>

<ng-template #listCardCoverTpl>
  <div class="listCardCoverContainer">
    <ng-container *ngTemplateOutlet="subtitle"></ng-container>

    <ng-container *ngTemplateOutlet="body"></ng-container>

    <nz-space nzDirection="vertical" [nzSize]="spaceSize"></nz-space>

    <ng-container *ngTemplateOutlet="footer"></ng-container>
  </div>
</ng-template>

<ng-template #listCardExtra>
  <ng-container *ngTemplateOutlet="extra"></ng-container>
</ng-template>
