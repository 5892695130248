<nz-card
  *ngIf="note$ | async as note"
  [nzTitle]="sujetHeader"
  [nzExtra]="substitleExtra"
>
  <ng-container *ngIf="subtitle$ | async as subtitle; else noSubtitle">
    <ng-container *ngIf="!(loading$ | async); else loadingAnimated">
      <nz-list
        nzBordered
        nzSize="small"
        nzItemLayout="vertical"
        [nzSplit]="true"
        *ngIf="sujets$ | async | size; else noData"
      >
        <nz-list-item
          *ngFor="let item of sujets$ | async; trackBy: trackSujet"
          (click)="onSelect(item)"
          nzNoFlex
        >
          <app-operation-note-sujet-item
            [sujet]="item"
            (update)="onUpdate($event, note)"
            (delete)="onDelete($event, note)"
          ></app-operation-note-sujet-item>
        </nz-list-item>
      </nz-list>
    </ng-container>

    <ng-template #sujetFooter> </ng-template>
    <ng-template #sujetFormTitle>
      <app-header-title [icon]="'title'" [title]="title"></app-header-title>
    </ng-template>
    <ng-template #noData>
      <app-no-result
        [notitle]="'Aucun sujet trouvé'"
        [isButton]="false"
      ></app-no-result>
    </ng-template>
  </ng-container>
  <ng-template #noSubtitle>
    <app-no-result
      [notitle]="'Aucun titre sélectionné'"
      [isButton]="false"
    ></app-no-result>
  </ng-template>

  <ng-template #sujetHeader>
    <nz-row
      nzAlign="middle"
      nzGutter="8"
      nzJustify="start"
      class="headerList"
      *ngIf="subtitle$ | async as subtitle; else noSubtitle"
    >
      <nz-col nzSpan="2">
        <mat-icon class="itemIcon">subject</mat-icon>
      </nz-col>
      <nz-col nzSpan="12">
        <strong nz-typography>{{ subtitle?.title | carLimitation: 80 }}</strong>
      </nz-col>
      <nz-col nzSpan="2"> </nz-col>
      <nz-col nzSpan="4">
        <nz-badge
          [nzCount]="sujets$ | async | size"
          [nzStandalone]="true"
          [nzOffset]="[0, -10]"
        ></nz-badge>
      </nz-col>

      <nz-col nzSpan="4"> </nz-col>
    </nz-row>
    <ng-template #noSubtitle>
      <nz-row
        nzAlign="middle"
        nzGutter="8"
        nzJustify="start"
        class="headerList"
      >
        <nz-col nzSpan="24"></nz-col>
      </nz-row>
    </ng-template>
  </ng-template>
  <ng-template #substitleExtra>
    <nz-button-group *ngIf="subtitle$ | async as subtitle">
      <button
        nz-button
        (click)="onNew(note, subtitle)"
        nzShape="circle"
        nzType="text"
        nz-tooltip
        [nzTooltipMouseEnterDelay]="tooltipMouseEnterDelay"
        nzTooltipPlacement="bottom"
        nzTooltipTitle="Ajouter un sujet à ce titre"
      >
        <mat-icon>add</mat-icon>
      </button>
      <app-button-dropdown
        *ngIf="sujets$ | async as sujets"
        [isDeleteAll]="true"
        [deleteAllType]="'Supprimer ces sujets ?'"
        (select)="onSelectDropdown($event, note, sujets, subtitle)"
      ></app-button-dropdown>
    </nz-button-group>
  </ng-template>

  <ng-template #loadingAnimated>
    <app-loading [size]="'24px'"></app-loading>
  </ng-template>
</nz-card>
