import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { EMPTY, lastValueFrom, Observable, of } from 'rxjs';
import { OperationDashboardState } from './../store/operation-dashboard.reducer';
import * as fromOperationDashboardAction from './../store/operation-dashboard.actions';
import * as fromOperationDashboardSelectore from './../store/operation-dashboard.selectors';

@Injectable({
  providedIn: 'any',
})
export class OperationDashboardResolverService {
  constructor(
    private operationStatisticStore: Store<OperationDashboardState>,
    private router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> | any {
    let id = route.parent?.params['id'];
    let find = lastValueFrom(this.loadOperationDashboard(id));

    return find
      .then(() => {
        return this.getOperationDashboard();
      })
      .catch(() => {
        this.router.navigate(['/projet', id]);
        return EMPTY;
      });
  }

  getOperationDashboard(): Observable<any> {
    return this.operationStatisticStore.select(
      fromOperationDashboardSelectore.dashboard
    );
  }

  loadOperationDashboard(id: string): Observable<any> {
    return of(
      this.operationStatisticStore.dispatch(
        fromOperationDashboardAction.loadOperationDashboard({ operationId: id })
      )
    );
  }
}
