import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading',
  template: ` <i nz-icon nzType="loading"></i> `,
  styles: [
    `
      i {
        color: colorStoke;
        font-weight: 'bold';
        font-size: size;
      }
    `,
  ],
})
export class LoadingComponent implements OnInit {
  @Input() size: string = '14px';
  @Input() colorStoke: string = 'orange';
  constructor() {}

  ngOnInit(): void {}
}
