import { Injectable } from '@angular/core';
import { Observable, of, from, EMPTY } from 'rxjs';
import { map, tap, mergeMap, switchMap, catchError } from 'rxjs/operators';
import { Apollo, gql } from 'apollo-angular';
import { Auth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'any',
})
export class OperationGanttService {
  GET_GANTT = gql`
    query Query($operationId: ID!) {
      operationGantt(operationId: $operationId) {
        dateEnd
        dateStart
        modules {
          start
          end
          type
          name
          operationId
          id
          group
          custom_class
          progress
          dependencies
        }
        actions {
          id
          type
          operationId
          name
          group
          start
          end
          custom_class
          progress
          dependencies
        }
      }
    }
  `;

  constructor(private apollo: Apollo, private auth: Auth) {}

  getGantt(operationId: string): Observable<any> {
    if (!operationId) {
      return EMPTY;
    } else {
      return this.apollo
        .watchQuery<any>({
          query: this.GET_GANTT,
          variables: {
            operationId: operationId,
          },
        })
        .valueChanges.pipe(
          map(({ data, loading, error }) => {
            return { data, loading, error };
          })
        );
    }
  }
}
