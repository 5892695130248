import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Message, MessageId } from './message.model';

export const loadMessages = createAction(
  '[Message/Component] Load List Messages'
);
export const loadMessagesSuccess = createAction(
  '[Message/Effect] Load List Messages Success',
  props<{ messages: MessageId[] }>()
);

export const loadMessagesFailure = createAction(
  '[Message/Effect] Load List Messages Failure',
  props<{ error: any }>()
);

export const listenOnNewMessages = createAction(
  '[Message/Component] Listen changes Messages'
);

//LOAD MY MESSAGES

export const loadMyMessages = createAction(
  '[Message/Component] Load My List Messages'
);
export const loadMyMessagesSuccess = createAction(
  '[Message/Effect] Load My List Messages Success',
  props<{ messages: MessageId[] }>()
);

export const loadMyMessagesFailure = createAction(
  '[Message/Effect] Load My List Messages Failure',
  props<{ error: any }>()
);

// LOAD RECEIVE MESSAGES
export const loadReceiveMessages = createAction(
  '[Message/Component] Load Receive List Messages'
);
export const loadReceiveMessagesSuccess = createAction(
  '[Message/Effect] Load Receive List Messages Success',
  props<{ messages: MessageId[] }>()
);

export const loadReceiveMessagesFailure = createAction(
  '[Message/Effect] Load Receive List Messages Failure',
  props<{ error: any }>()
);

//LOAD MESSAGE

export const loadMessage = createAction(
  '[Message/COMPONENT] Load Message',
  props<{ id: string | any }>()
);

export const loadMessageSuccess = createAction(
  '[Message/API] Load Message Success',
  props<{ message: MessageId }>()
);

export const loadMessageFailure = createAction(
  '[Message/API] Load Message Failure',
  props<{ error: any }>()
);

export const loadMessageClear = createAction(
  '[Message/COMPONENT] Load Message Clear'
);

export const showMessage = createAction('[Message/Component] Show Message');

export const hideMessage = createAction('[Message/Component] Hide Message');

export const addMessage = createAction(
  '[Message/Component] Add New Message',
  props<{ message: Message }>()
);

export const addMessageSuccess = createAction(
  '[Message/Effect] Add New Message Succes',
  props<{ message: MessageId }>()
);

export const addMessageFailure = createAction(
  '[Message/Effect] Add New Message Failure',
  props<{ error: any }>()
);

export const updateMessage = createAction(
  '[Message/Component] Update Message',
  props<{ message: Update<MessageId> }>()
);

export const updateMessageSuccess = createAction(
  '[Message/Effect] Update Message Success',
  props<{ message: MessageId }>()
);

export const updateMessageFailure = createAction(
  '[Message/Effect] Update Message Failure',
  props<{ error: any }>()
);

export const deleteMessage = createAction(
  '[Message/Component] Delete Message',
  props<{ id: string | any }>()
);

export const deleteMessageSuccess = createAction(
  '[Message/Effect] Delete Message Success',
  props<{ success: string }>()
);

export const deleteMessageFailure = createAction(
  '[Message/Effect] Delete Message Failure',
  props<{ error: any }>()
);

export const deleteMessages = createAction(
  '[Message/Component] Delete All Messages',
  props<{ ids: string[] }>()
);

export const deleteMessagesSuccess = createAction(
  '[Message/Effect] Delete All Messages Success',
  props<{ succes: string }>()
);

export const deleteMessagesFailure = createAction(
  '[Message/Effect] Delete All Messages Failure',
  props<{ error: any }>()
);

//OWN
export const deleteMyMessages = createAction(
  '[Message/Component] Delete All My Messages',
  props<{ ids: string[] }>()
);

export const deleteMyMessagesSuccess = createAction(
  '[Message/Effect] Delete All My Messages Success',
  props<{ succes: string }>()
);

export const deleteMyMessagesFailure = createAction(
  '[Message/Effect] Delete All My Messages Failure',
  props<{ error: any }>()
);

//RECEIVE
export const deleteReceiveMessages = createAction(
  '[Message/Component] Delete All Receive Messages',
  props<{ ids: string[] }>()
);

export const deleteReceiveMessagesSuccess = createAction(
  '[Message/Effect] Delete All Receive Messages Success',
  props<{ succes: string }>()
);

export const deleteReceiveMessagesFailure = createAction(
  '[Message/Effect] Delete All Receive Messages Failure',
  props<{ error: any }>()
);

export const loadingMessages = createAction(
  '[Message/Effect] Loading Messages',
  props<{ loader: boolean }>()
);

export const clearMessages = createAction('[Message/Component] Clear Messages');
