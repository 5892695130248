<nz-card *ngIf="note$ | async as note" [nzTitle]="substitleHeader">
  <ng-container *ngIf="!(loading$ | async); else loadingTpl">
    <nz-list
      nzBordered
      nzSize="small"
      nzItemLayout="vertical"
      [nzSplit]="true"
      *ngIf="subtitles$ | async | size; else noData"
    >
      <nz-list-item
        *ngFor="let item of subtitles$ | async; trackBy: trackSubtitles"
        (click)="$event.stopImmediatePropagation(); onSelect(item)"
        nzNoFlex
      >
        <app-operation-note-subtitle-item
          [subtitle]="item"
          (update)="onUpdateForm($event, note)"
          (delete)="onDelete($event, note)"
        ></app-operation-note-subtitle-item>
      </nz-list-item>
    </nz-list>
  </ng-container>
  <ng-template #substitleHeader>
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start" class="headerList">
      <nz-col nzSpan="8">
        <h5 nz-typography>Titres</h5>
      </nz-col>
      <nz-col nzSpan="4">
        <nz-badge
          [nzCount]="subtitles$ | async | size"
          [nzStandalone]="true"
          [nzOffset]="[0, -10]"
        ></nz-badge>
      </nz-col>
      <nz-col nzSpan="6"></nz-col>
      <nz-col nzSpan="6">
        <nz-button-group>
          <button
            nz-button
            (click)="onNew(note)"
            nzShape="round"
            nzType="text"
            nz-tooltip
            [nzTooltipMouseEnterDelay]="tooltipMouseEnterDelay"
            nzTooltipPlacement="bottom"
            nzTooltipTitle="Ajouter un titre"
          >
            <mat-icon>playlist_add</mat-icon>
          </button>
          <ng-container *ngIf="subtitles$ | async | size">
            <app-button-dropdown
              *ngIf="subtitles$ | async as subtitles"
              [isDeleteAll]="true"
              [deleteAllType]="'Supprimer les titres ?'"
              (select)="onSelectDropdown($event, note, subtitles)"
            ></app-button-dropdown>
          </ng-container>
        </nz-button-group>
      </nz-col>
    </nz-row>
  </ng-template>
  <ng-template #substitleExtra> </ng-template>
  <ng-template #subtitleFormTitle>
    <app-header-title [icon]="'title'" [title]="title"></app-header-title>
  </ng-template>
  <ng-template #noData>
    <app-no-result
      [notitle]="'Aucun titre trouvé'"
      [isButton]="false"
      [icon]="'subject'"
    ></app-no-result>
  </ng-template>
  <ng-template #loadingTpl>
    <app-loading [size]="'24px'"></app-loading>
  </ng-template>
</nz-card>
