import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReseauComponent } from './components/reseau/reseau.component';
import { EmailComponent } from './components/email/email.component';
import { PhoneComponent } from './components/phone/phone.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { CoordonneesService } from './services/coordonnees.service';
import { CoordonneesComponent } from './components/coordonnees.component';
import { UiModule } from 'src/app/ui/ui/ui.module';

@NgModule({
  declarations: [
    CoordonneesComponent,
    EmailComponent,
    PhoneComponent,
    ReseauComponent,
  ],
  exports: [
    CoordonneesComponent,
    EmailComponent,
    PhoneComponent,
    ReseauComponent,
  ],
  imports: [CommonModule, SharedModule, UiModule],
  providers: [CoordonneesService],
})
export class CoordonneesModule {}
