<ng-container *ngIf="operation$ | async as operation">
  <div [ngSwitch]="view$ | async">
    <div *ngSwitchCase="'list'">
      <ng-container *ngIf="notes$ | async | size">
        <nz-page-header class="site-page-header">
          <nz-page-header-title>
            <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
              <nz-col nzSpan="18">
                <app-header-title
                  [icon]="''"
                  [title]="'Comptes rendus'"
                  [subtitle]="true"
                ></app-header-title>
              </nz-col>
              <nz-col nzSpan="6"
                ><nz-badge
                  [nzCount]="notes$ | async | size"
                  [nzStandalone]="true"
                >
                </nz-badge
              ></nz-col>
            </nz-row>
          </nz-page-header-title>
          <nz-page-header-content> </nz-page-header-content>
          <nz-page-header-extra>
            <nz-row nzAlign="middle" nzGutter="8" nzJustify="end">
              <nz-col nzSpan="12">
                <button
                  nz-button
                  (click)="onNew(operation)"
                  nzType="text"
                  nzShape="circle"
                  nz-tooltip
                  nzTooltipPlacement="bottom"
                  [nzTooltipMouseEnterDelay]="0.5"
                  nzTooltipTitle="Ajouter un compte rendu"
                >
                  <mat-icon>post_add</mat-icon>
                </button>
              </nz-col>
              <nz-col nzSpan="12">
                <app-button-dropdown
                  *ngIf="notes$ | async as notes"
                  [isDeleteAll]="true"
                  [deleteAllType]="'Supprimer tous les comptes rendus ?'"
                  (select)="onSelectDropdown($event, operation, notes)"
                ></app-button-dropdown>
              </nz-col>
            </nz-row>
          </nz-page-header-extra>
        </nz-page-header>
        <nz-space nzDirection="vertical"></nz-space>
      </ng-container>
      <nz-table
        #tableRight
        [nzData]="listOfData"
        nzSize="small"
        nzTableLayout="fixed"
        [nzPageSize]="10"
        [nzScroll]="scroll"
        *ngIf="notes$ | async | size; else noData"
      >
        <thead>
          <tr>
            <th nzLeft nzWidth="100">Titre</th>
            <th nzWidth="20">Date</th>
            <th>Auteur</th>
            <th nzRight>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let data of tableRight.data"
            (click)="onSelect(operation.id, data); $event.stopPropagation()"
          >
            <td nzLeft nzWidth="100">{{ data.title }}</td>
            <td nzWidth="20">{{ data.dateStart | dateFr : "small" }}</td>
            <td>
              <app-avatar-group
                [source]="[data.auteur]"
                [limit]="4"
              ></app-avatar-group>
            </td>
            <td nzRight>
              <app-button-dropdown
                [direction]="'horizontal'"
                [deleteType]="'Supprimer ce compte rendu ?'"
                [isDelete]="true"
                [isCopy]="true"
                (click)="$event.stopPropagation()"
                (select)="onItemActions($event, operation.id, data)"
              ></app-button-dropdown>
            </td>
          </tr>
        </tbody>
      </nz-table>
    </div>
    <div *ngSwitchCase="'detail'">
      <app-operation-note-item
        [operation$]="operation$"
        [note$]="note$"
        (back)="onBack()"
      ></app-operation-note-item>
    </div>
    <div *ngSwitchDefault></div>
  </div>

  <ng-template #noData>
    <app-no-result
      [icon]="'edit_note'"
      [title]="'Aucun compte rendu'"
      [notitle]="'Aucun compte rendu'"
      [addtitle]="'Ajouter un compte rendu'"
      [isButton]="true"
      (new)="onNew(operation)"
    ></app-no-result>
  </ng-template>

  <ng-template #noteFormTitle>
    <app-header-title
      [icon]="'edit_note'"
      [title]="'Comptes rendus'"
      [subtitle]="true"
    ></app-header-title>
  </ng-template>

  <ng-template #loadingAnimation>
    <app-loading-animation [loading$]="loading$"></app-loading-animation>
  </ng-template>
</ng-container>
