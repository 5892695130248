import { Pipe, PipeTransform } from '@angular/core';
import memo from 'memo-decorator';
import { fromUnixTime, differenceInCalendarMonths } from 'date-fns';

@Pipe({
  name: 'differenceMonths',
})
export class DifferenceMonthsPipe implements PipeTransform {
  @memo()
  transform(value: any, ...args: unknown[]): number {
    const today: any = new Date(Date.now());
    const start: any = value?.dateStart
      ? fromUnixTime(value.dateStart['seconds'])
      : '';
    const end: any = value?.dateEnd
      ? fromUnixTime(value.dateEnd['seconds'])
      : null;

    const difference: number =
      start && end ? differenceInCalendarMonths(end, start) : 0;

    const differenceStartToday: number = start
      ? differenceInCalendarMonths(today, start)
      : 0;

    const progression = Math.floor((differenceStartToday / difference) * 100);

    return progression;
  }
}
