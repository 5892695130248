import { debounceTime, takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
} from '@angular/forms';
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchBarComponent implements OnInit, OnChanges, OnDestroy {
  subscribe = new Subject();
  searchForm: UntypedFormGroup = this.fb.group({});
  searchInput: UntypedFormControl = new UntypedFormControl('');

  @Input() searchBar: boolean = true;
  @Input() tags: boolean = false;
  @Input() tagsList: any = [];
  @Input() filter: boolean = false;
  @Input() download: boolean = false;
  @Input() cleaner: boolean = false;
  @Input() loading: boolean = false;

  @Input() placeholder: string = 'Recherche...';
  @Output() searching = new EventEmitter<string>(false);
  @Output() clear = new EventEmitter<boolean>(false);

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.formInit();
    this.formCreate();
    this.onSearch();
  }
  ngOnChanges(changes: SimpleChanges): void {}

  formInit(): void {
    this.searchInput = new UntypedFormControl('');
  }
  formCreate(): void {
    this.searchForm = this.fb.group({
      search: this.searchInput,
    });
  }

  onSearch(): void {
    this.searchForm
      .get('search')
      ?.valueChanges.pipe(
        takeUntil(this.subscribe),
        distinctUntilChanged(),
        debounceTime(200)
      )
      .subscribe((value: any): void => {
        this.searching.emit(value);
        if (!value) {
          this.cleaner = false;
        } else {
          this.cleaner = true;
        }
      });
  }

  clearSearchForm(): void {
    this.searching.next('');
    this.searchForm.reset();
    this.cleaner = false;
    this.clear.emit(true);
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }
}
