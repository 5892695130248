<div class="w-full flex items-center justify-center">
  <div class="stats shadow">
    <div
      class="stat cursor-pointer hover:bg-slate-100"
      (click)="$event.stopPropagation(); onSelect('besoins')"
    >
      <div
        [class]="
          currentSelected === 'besoins'
            ? 'stat-figure text-primary'
            : 'stat-figure'
        "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          class="inline-block w-8 h-8 stroke-current"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z"
          />
        </svg>
      </div>
      <div class="stat-title">Besoin</div>
      <div
        [class]="
          currentSelected === 'besoins'
            ? 'stat-value text-primary'
            : 'stat-value'
        "
      >
        {{ fiche?.recherche?.participantsSize }}
      </div>
      <div class="stat-desc">
        Salarié{{ fiche?.recherche?.participantsSize > 1 ? ".s" : "" }}
      </div>
    </div>

    <div
      class="stat cursor-pointer hover:bg-slate-100"
      (click)="$event.stopPropagation(); onSelect('candidatures')"
    >
      <div
        [class]="
          currentSelected === 'candidatures'
            ? 'stat-figure text-primary'
            : 'stat-figure'
        "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          class="inline-block w-8 h-8 stroke-current"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
          />
        </svg>
      </div>
      <div class="stat-title">Candidatures</div>
      <div
        [class]="
          currentSelected === 'candidatures'
            ? 'stat-value text-primary'
            : 'stat-value'
        "
      >
        {{ fiche?.candidatureSelectedCount }} / {{ fiche?.candidats?.length }}
      </div>
      <div
        class="stat-desc"
        *ngIf="
          !fiche?.candidatureSelectedCount ||
          fiche?.candidatureSelectedCount === 0
        "
      >
        Aucun candidat séléctionné
      </div>
      <div
        class="stat-desc"
        *ngIf="
          fiche?.candidatureSelectedCount || fiche?.candidatureSelectedCount > 0
        "
      >
        {{ fiche?.candidatureSelectedCount }} candidat.s séléctionné.s
      </div>
    </div>
    <div
      class="stat cursor-pointer hover:bg-slate-100"
      (click)="$event.stopPropagation(); onSelect('diffusions')"
    >
      <div
        [class]="
          currentSelected === 'diffusions'
            ? 'stat-figure text-primary'
            : 'stat-figure'
        "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          class="inline-block w-8 h-8 stroke-current"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
          />
        </svg>
      </div>
      <div class="stat-title">Diffusions</div>
      <div
        [class]="
          currentSelected === 'diffusions'
            ? 'stat-value text-primary'
            : 'stat-value'
        "
      >
        {{ fiche?.diffusions?.length }}
      </div>
      <div class="stat-desc">Diffusions prescripteurs</div>
    </div>

    <div
      class="stat cursor-pointer hover:bg-slate-100"
      (click)="$event.stopPropagation(); onSelect('notes')"
    >
      <div
        [class]="
          currentSelected === 'notes'
            ? 'stat-figure text-primary'
            : 'stat-figure'
        "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          class="inline-block w-8 h-8 stroke-current"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
          />
        </svg>
      </div>
      <div class="stat-title">Notes</div>
      <div
        [class]="
          currentSelected === 'notes' ? 'stat-value text-primary' : 'stat-value'
        "
      >
        {{ fiche?.notes?.length }}
      </div>
      <div class="stat-desc">Notes de suivi</div>
    </div>

    <div class="stat cursor-pointer hover:bg-slate-100">
      <div
        [class]="
          currentSelected === 'jours'
            ? 'stat-figure text-primary'
            : 'stat-figure'
        "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          class="inline-block w-8 h-8 stroke-current"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
          />
        </svg>
      </div>
      <div class="stat-title">Prise de poste</div>
      <div class="stat-value flex items-start flex-nowrap">
        {{ fiche?.mission?.contrat?.startMission | dateFr : "date" }}
        <div class="stat-desc">
          <span> /</span
          >{{ fiche?.mission?.contrat?.startMission | dateFr : "month" }}/{{
            fiche?.mission?.contrat?.startMission | dateFr : "year"
          }}
        </div>
      </div>
      <div class="stat-desc">
        {{ fiche?.mission?.contrat?.startMission | dateFr : "distanceSuffix" }}
      </div>
    </div>

    <div
      class="stat cursor-pointer hover:bg-slate-100"
      *ngIf="fiche?.mission?.contrat?.startSouplesse"
    >
      <div
        [class]="
          currentSelected === 'jours'
            ? 'stat-figure text-primary'
            : 'stat-figure'
        "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          class="inline-block w-8 h-8 stroke-current"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
          />
        </svg>
      </div>
      <div class="stat-title">Souplesse</div>
      <div class="stat-value flex items-start flex-nowrap">
        {{ fiche?.mission?.contrat?.startSouplesse | dateFr : "date" }}
        <div class="stat-desc">
          <span> /</span
          >{{ fiche?.mission?.contrat?.startSouplesse | dateFr : "month" }}/{{
            fiche?.mission?.contrat?.startSouplesse | dateFr : "year"
          }}
        </div>
      </div>
      <div class="stat-desc">
        {{
          fiche?.mission?.contrat?.startSouplesse | dateFr : "distanceSuffix"
        }}
      </div>
    </div>
  </div>
</div>
<ng-template #ficheItemCardRechercheTitle>
  <mat-icon>person_search</mat-icon>
</ng-template>
<ng-template #ficheItemCardRechercheCandidatTitle>
  <mat-icon>reduce_capacity</mat-icon>
</ng-template>
<ng-template #ficheItemCardRechercheDiffusionTitle>
  <mat-icon>send</mat-icon>
</ng-template>
<ng-template #ficheItemCardRechercheTimeTitle>
  <mat-icon>schedule</mat-icon>
</ng-template>
