<nz-card
  [nzTitle]="projetProMetierCardTitle"
  class="projetpro_metier_container"
>
  <ng-template #projetProMetierCardTitle>
    <app-header-title [icon]="'table_chart'"></app-header-title>
  </ng-template>
  <nz-descriptions
    nzBordered
    [nzColumn]="{ xxl: 4, xl: 4, lg: 4, md: 4, sm: 2, xs: 1 }"
  >
    <nz-descriptions-item nzTitle="Description" nzSpan="4">
      <div class="projetPro_definition">
        {{ item.definition }}
      </div>
    </nz-descriptions-item>
    <nz-descriptions-item nzTitle="Accès" nzSpan="4">
      <div class="projetPro_acces">
        <nz-list nzSize="small">
          <nz-list-item *ngFor="let item of item.accesArray">
            <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
              <nz-col nzSpan="2">
                <mat-icon class="alert">gpp_maybe</mat-icon>
              </nz-col>
              <nz-col nzSpan="22">
                {{ item }}
              </nz-col>
            </nz-row>
          </nz-list-item>
        </nz-list>
      </div>
    </nz-descriptions-item>
    <nz-descriptions-item nzTitle="Conditions" nzSpan="4">
      <div class="projetPro_conditions">
        {{ item.condition }}
      </div>
    </nz-descriptions-item>
  </nz-descriptions>
</nz-card>
