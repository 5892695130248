<div class="submenu">
  <!-- <li
      nz-menu-item
      nz-tooltip
      nzTooltipTitle="Détail du projet"
      nzTooltipPlacement="right"
      [nzTooltipMouseEnterDelay]="tooltipDelay"
      (click)="onSelect('operation_details')"
    >
      <mat-icon>subtitles</mat-icon>
      <span *ngIf="!collapse">Détails</span>
    </li>
    <li nz-menu-divider></li> -->
  <li
    nz-menu-item
    [nzTooltipMouseEnterDelay]="tooltipDelay"
    nzTooltipPlacement="right"
    nz-tooltip
    nzTooltipTitle="Équipe"
    (click)="onSelect('operation_team')"
    class="active-subrouter"
  >
    <mat-icon>groups</mat-icon>
    <span *ngIf="!collapse">Équipe</span>
  </li>

  <!-- <li
    nz-menu-item
    nz-tooltip
    [nzTooltipMouseEnterDelay]="tooltipDelay"
    nzTooltipPlacement="right"
    nz-tooltip
    nzTooltipTitle="Suivis"
    (click)="onSelect('operation_suivis')"
    class="active-subrouter"
  >
    <mat-icon>article</mat-icon>
    <span *ngIf="!collapse"> Suivis</span>
  </li> -->
  <li
    nz-menu-item
    [nzTooltipMouseEnterDelay]="tooltipDelay"
    nzTooltipPlacement="right"
    nz-tooltip
    nzTooltipTitle="Suivis"
    (click)="onSelect('operation_notes')"
    class="active-subrouter"
  >
    <!-- <mat-icon>edit_note</mat-icon> -->
    <mat-icon>article</mat-icon>

    <span *ngIf="!collapse">Suivis</span>
  </li>
  <li
    nz-menu-item
    [nzTooltipMouseEnterDelay]="tooltipDelay"
    nzTooltipPlacement="right"
    nz-tooltip
    nzTooltipTitle="Documents"
    (click)="onSelect('operation_documents')"
    class="active-subrouter"
  >
    <mat-icon>insert_drive_file</mat-icon>
    <span *ngIf="!collapse">Documents</span>
  </li>
  <li
    nz-menu-item
    [nzTooltipMouseEnterDelay]="tooltipDelay"
    nzTooltipPlacement="right"
    nz-tooltip
    nzTooltipTitle="Tâches"
    (click)="onSelect('operation_tasks')"
    class="active-subrouter"
  >
    <mat-icon>checklist</mat-icon>
    <span *ngIf="!collapse">Tâches</span>
  </li>

  <li nz-menu-divider></li>

  <li
    nz-menu-item
    (click)="onSelect('operation_entites')"
    [nzTooltipMouseEnterDelay]="tooltipDelay"
    nzTooltipPlacement="right"
    nz-tooltip
    nzTooltipTitle="Entités"
    class="active-subrouter"
  >
    <mat-icon>domain</mat-icon>
    <span *ngIf="operation?.type && !collapse">{{
      operation?.type | operationType: "entite"
    }}</span>
  </li>
  <!-- <li
    nz-menu-item
    (click)="onSelect('operation_contacts')"
    nz-tooltip
    [nzTooltipMouseEnterDelay]="tooltipDelay"
    nzTooltipPlacement="right"
    nz-tooltip
    nzTooltipTitle="Collaborateurs"
    class="active-subrouter"
  >
    <mat-icon>person</mat-icon>
    <span *ngIf="operation?.type && !collapse">{{
      operation?.type | operationType: "contact"
    }}</span>
  </li> -->
  <li
    nz-menu-item
    (click)="onSelect('operation_participants')"
    [nzTooltipMouseEnterDelay]="tooltipDelay"
    nzTooltipPlacement="right"
    nz-tooltip
    nzTooltipTitle="Participants"
    class="active-subrouter"
  >
    <mat-icon>supervisor_account</mat-icon>
    <span *ngIf="!collapse">{{
      operation?.type | operationType: "participant"
    }}</span>
  </li>
  <li
    nz-menu-item
    (click)="onSelect('operation_prescripteurs')"
    [nzTooltipMouseEnterDelay]="tooltipDelay"
    nzTooltipPlacement="right"
    nz-tooltip
    nzTooltipTitle="Prescripteurs"
    class="active-subrouter"
  >
    <mat-icon>real_estate_agent</mat-icon>
    <span *ngIf="!collapse">Prescripteurs</span>
  </li>
  <li nz-menu-divider></li>
  <!-- <li
          nz-menu-item
          (click)="onSelect('planification')"
          nz-tooltip
          [nzTooltipMouseEnterDelay]="tooltipDelay"
          nzTooltipPlacement="right"
          nzTooltipTitle="Planification permet le management des tâches à réaliser pour le projet."
          *ngIf="!collapse"
        >
          <mat-icon>task</mat-icon>
          <span>Planification</span>
        </li> -->
  <li
    nz-menu-item
    (click)="onSelect('operation_modules')"
    [nzTooltipMouseEnterDelay]="tooltipDelay"
    nzTooltipPlacement="right"
    nz-tooltip
    nzTooltipTitle="Modules"
    class="active-subrouter"
  >
    <mat-icon outline>dynamic_feed</mat-icon>
    <span *ngIf="operation?.type && !collapse">{{
      operation?.type | operationType: "module"
    }}</span>
  </li>
  <li
    nz-menu-item
    (click)="onSelect('operation_actions')"
    [nzTooltipMouseEnterDelay]="tooltipDelay"
    nzTooltipPlacement="right"
    nz-tooltip
    nzTooltipTitle="Actions"
    class="active-subrouter"
  >
    <mat-icon>dashboard</mat-icon>
    <span *ngIf="operation?.type && !collapse">{{
      operation?.type | operationType: "action"
    }}</span>
  </li>
  <li
    nz-menu-item
    (click)="onSelect('operation_besoins')"
    [nzTooltipMouseEnterDelay]="tooltipDelay"
    nzTooltipPlacement="right"
    nz-tooltip
    nzTooltipTitle="Fiches de poste"
    class="active-subrouter"
  >
    <mat-icon>inventory</mat-icon>
    <span *ngIf="!collapse">Fiches de poste</span>
  </li>
  <li
    nz-menu-item
    (click)="onSelect('operation_missions')"
    [nzTooltipMouseEnterDelay]="tooltipDelay"
    nzTooltipPlacement="right"
    nz-tooltip
    nzTooltipTitle="Missions"
    class="active-subrouter"
  >
    <mat-icon>work_history</mat-icon>
    <span *ngIf="!collapse">Missions</span>
  </li>
  <!-- <li
    nz-menu-item
    nz-tooltip
    [nzTooltipMouseEnterDelay]="tooltipDelay"
    nzTooltipPlacement="right"
    nz-tooltip
    nzTooltipTitle="Événements"
    (click)="onSelect('operation_events')"
    class="active-subrouter"
  >
    <mat-icon>event</mat-icon>
    <span *ngIf="!collapse">Évènements</span>
  </li> -->

  <!-- <li
          nz-menu-item
          (click)="onSelect('time')"
          nz-tooltip
          [nzTooltipMouseEnterDelay]="tooltipDelay"
          nzTooltipPlacement="right"
          nzTooltipTitle="Suivi temps permet le management du temps passé par membre de l'équipe projet."
        >
          <mat-icon>timer</mat-icon>
          <span  *ngIf="!collapse">Suivi temps</span>
        </li> -->

  <li nz-menu-divider></li>
</div>
