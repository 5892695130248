import { TodoId } from './../../store/todo.model';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-todo-status',
  templateUrl: './todo-status.component.html',
  styleUrls: ['./todo-status.component.scss'],
})
export class TodoStatusComponent implements OnInit {
  @Input() todo: TodoId | any = null;
  constructor() {}

  ngOnInit(): void {}
}
