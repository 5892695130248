import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Favorie } from './favorie.model';
import * as FavorieActions from './favorie.actions';

export const favoriesFeatureKey = 'favories';

export interface FavoriState extends EntityState<Favorie> {
  entites: any;
  participants: any;
  error: any;
}

export const adapter: EntityAdapter<Favorie> = createEntityAdapter<Favorie>();

export const initialState: FavoriState = adapter.getInitialState({
  entites: undefined,
  participants: undefined,
  error: undefined,
});

export const reducer = createReducer(
  initialState,
  on(FavorieActions.loadEntitesSuccess, (state, action) => {
    return {
      ...state,
      entites: action.entites,
    };
  }),

  on(FavorieActions.loadEntitesFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  on(FavorieActions.loadParticipantsSuccess, (state, action) => {
    return {
      ...state,
      participants: action.participants,
    };
  }),
  on(FavorieActions.loadEntitesFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  on(FavorieActions.clearFavories, (state) => adapter.removeAll(state))
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
