import { createAction, props } from '@ngrx/store';

import { AuthUser, EmailValidation } from './auth.model';

//LOADING
export const loadingAuth = createAction(
  '[Auth/COMPONENT] LOADING Auth',
  props<{ loading: boolean }>()
);

//EMAIL CHECK
export const emailCheck = createAction(
  '[Auth/COMPONENT] EMAIL CHECK Auth',
  props<{ email: string }>()
);
export const emailCheckValidation = createAction(
  '[Auth/COMPONENT] EMAIL CHECK Auth Validation'
);
export const emailCheckSuccess = createAction(
  '[Auth/COMPONENT] EMAIL CHECK Auth Success',
  props<{ validation: EmailValidation }>()
);
export const emailCheckFailure = createAction(
  '[Auth/COMPONENT] EMAIL CHECK Auth Failure',
  props<{ error: string }>()
);

//EMAIL VALIDATION
export const emailValidation = createAction(
  '[Auth/COMPONENT] USER EMAIL VALIDATION Auth',
  props<{ email: string }>()
);

export const emailValidationSuccess = createAction(
  '[Auth/API] USER EMAIL VALIDATION Auth Success',
  props<{ validation: EmailValidation }>()
);

export const emailValidationFailure = createAction(
  '[Auth/API] USER EMAIL VALIDATION Auth Failure',
  props<{ error: any }>()
);

//LOGIN
export const login = createAction(
  '[Auth/COMPONENT] LOGIN Auth',
  props<{ email: string; password: string }>()
);

export const loginSuccess = createAction('[Auth/API] LOGIN Auth Success');

export const loginSuccessEmailVerification = createAction(
  '[Auth/API] LOGIN Auth Email Verification verification'
);

export const loginFailure = createAction(
  '[Auth/API] LOGIN Auth Failure',
  props<{ error: any }>()
);

//LOGOUT
export const logout = createAction('[Auth/COMPONENT] LOGOUT Auth');

//LOST PASSWORD
export const lostPassword = createAction(
  '[Auth/COMPONENT] LOST PASSWORD PAGE Auth'
);

//FORGOT PASSEWORD
export const forgotPassword = createAction(
  '[Auth/COMPONENT] FORGOT PASSEWORD Auth',
  props<{ email: string }>()
);

export const forgotPasswordSuccess = createAction(
  '[Auth/API] FORGOT PASSEWORD Auth Success'
);

export const forgotPasswordFailure = createAction(
  '[Auth/API] FORGOT PASSEWORD Auth Failure',
  props<{ error: any }>()
);

//RESET PASSEWORD
export const resetPassword = createAction(
  '[Auth/COMPONENT] RESET PASSEWORD Auth',
  props<{ email: string }>()
);

export const resetPasswordSuccess = createAction(
  '[Auth/API] RESET PASSEWORD Auth Success'
);

export const resetPasswordFailure = createAction(
  '[Auth/API] RESET PASSEWORD Auth Failure',
  props<{ error: any }>()
);

//RETURN
export const backToLogin = createAction('[Auth/COMPONENT] BACK TO LOGIN PAGE');

//DISABLED
export const disabedUser = createAction(
  '[Auth/COMPONENT] DISABLED USER Auth',
  props<{ user: any }>()
);

export const disabedUserSuccess = createAction(
  '[Auth/API] DISABLED USER Auth Success'
);

export const disabedUserFailure = createAction(
  '[Auth/API] DISABLED USER Auth Failure',
  props<{ error: any }>()
);

//ACTIVATED
export const activatedUser = createAction(
  '[Auth/COMPONENT] ACTIVATED USER Auth',
  props<{ user: any }>()
);

export const activatedUserSuccess = createAction(
  '[Auth/API] ACTIVATED USER Auth Success'
);

export const activatedUserFailure = createAction(
  '[Auth/API] ACTIVATED USER Auth Failure',
  props<{ error: any }>()
);

//UPDATE PASSEWORD
export const updatePassword = createAction(
  '[Auth/COMPONENT] UPDATE PASSSWORD USER Auth',
  props<{ password: string }>()
);

export const updatePasswordSuccess = createAction(
  '[Auth/API] UPDATE PASSSWORD USER Auth Success'
);

export const updatePasswordFailure = createAction(
  '[Auth/API] UPDATE PASSSWORD USER Auth Failure',
  props<{ error: any }>()
);

//DELETE USER
export const deleteUser = createAction(
  '[Auth/COMPONENT] DELETE USER Auth',
  props<{ user: any }>()
);

export const deleteUserSuccess = createAction(
  '[Auth/API] DELETE USER Auth Success'
);

export const deleteUserFailure = createAction(
  '[Auth/API] DELETEUSER Auth Failure',
  props<{ error: any }>()
);

export const clearAuths = createAction('[Auth/API] Clear Auths');
