import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { OperationTask, OperationTaskId } from './operation-task.model';
import * as OperationTaskActions from './operation-task.actions';

export const operationTasksFeatureKey = 'operationTasks';

export interface OperationTaskState extends EntityState<OperationTaskId> {
  loading: boolean;
  task: OperationTaskId | any;
  filters: string[];
  isFiltered: boolean;
  error: any;
}

export const adapter: EntityAdapter<OperationTaskId> =
  createEntityAdapter<OperationTaskId>();

export const initialState: OperationTaskState = adapter.getInitialState({
  loading: false,
  task: undefined,
  filters: ['Tout', 'Mes tâches', "Aujourd'hui", 'Cette semaine', 'Ce mois'],
  isFiltered: false,
  error: null,
});

export const reducer = createReducer(
  initialState,
  //LOADING
  on(OperationTaskActions.loadingOperationTasks, (state, action) => {
    return {
      ...state,
      loading: action.loading,
    };
  }),

  //LOAD ALL
  on(OperationTaskActions.loadOperationTasks, (state, action) => {
    return {
      ...state,
      loading: true,
      isFiltered: false,
      error: undefined,
    };
  }),
  on(OperationTaskActions.loadOperationTasksSuccess, (state, action) =>
    adapter.setAll(action.tasks, state)
  ),
  on(OperationTaskActions.loadOperationTasksFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      isFiltered: false,

      error: action.error,
    };
  }),

  //LOAD ONE
  on(OperationTaskActions.loadOperationTaskSuccess, (state, action) => {
    return {
      ...state,
      task: action.task,
    };
  }),
  on(OperationTaskActions.loadOperationTaskFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //ADD ONE

  on(OperationTaskActions.addOperationTaskFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //UPDATE ONE
  on(OperationTaskActions.updateOperationTask, (state, action) =>
    adapter.updateOne(action.operationTask, state)
  ),

  on(OperationTaskActions.updateOperationTaskFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //DELETE ONE
  on(OperationTaskActions.deleteOperationTask, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(OperationTaskActions.deleteOperationTaskFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //DELETE ALL
  on(OperationTaskActions.deleteOperationTasks, (state, action) =>
    adapter.removeAll(state)
  ),
  on(OperationTaskActions.deleteOperationTasksSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      error: undefined,
    };
  }),

  on(OperationTaskActions.deleteOperationTasksFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  }),

  //CLEAR

  on(OperationTaskActions.clearOperationTasks, (state) =>
    adapter.removeAll(state)
  ),

  //QUERIES
  //MY TAKS
  on(OperationTaskActions.loadOperationMyTasks, (state, action) => {
    return {
      ...state,
      loading: true,
      isFiltered: true,

      error: undefined,
    };
  }),
  on(OperationTaskActions.loadOperationMyTasksSuccess, (state, action) =>
    adapter.setAll(action.tasks, state)
  ),
  on(OperationTaskActions.loadOperationMyTasksFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      isFiltered: false,

      error: action.error,
    };
  }),
  //TODAY TAKS
  on(OperationTaskActions.loadOperationTodayTasks, (state, action) => {
    return {
      ...state,
      loading: true,
      isFiltered: true,

      error: undefined,
    };
  }),
  on(OperationTaskActions.loadOperationTodayTasksSuccess, (state, action) =>
    adapter.setAll(action.tasks, state)
  ),
  on(OperationTaskActions.loadOperationTodayTasksFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      isFiltered: false,

      error: action.error,
    };
  }),

  //WEEK TASKS
  on(OperationTaskActions.loadOperationWeekTasks, (state, action) => {
    return {
      ...state,
      loading: true,
      isFiltered: true,

      error: undefined,
    };
  }),
  on(OperationTaskActions.loadOperationWeekTasksSuccess, (state, action) =>
    adapter.setAll(action.tasks, state)
  ),
  on(OperationTaskActions.loadOperationWeekTasksFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      isFiltered: false,

      error: action.error,
    };
  }),
  //MONTH TAKS
  on(OperationTaskActions.loadOperationMonthTasks, (state, action) => {
    return {
      ...state,
      loading: true,
      isFiltered: true,
      error: undefined,
    };
  }),
  on(OperationTaskActions.loadOperationMonthTasksSuccess, (state, action) =>
    adapter.setAll(action.tasks, state)
  ),
  on(OperationTaskActions.loadOperationMonthTasksFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      isFiltered: false,
      error: action.error,
    };
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
