<div *ngIf="user$ | async as user" class="flex flex-col items-start mt-1">
  <ul
    nz-menu
    [nzTheme]="theme"
    nzMode="inline"
    [nzInlineIndent]="24"
    [nzSelectable]="true"
  >
    <li
      nz-menu-item
      nz-tooltip="Admin Tools"
      nzTooltipPlacement="right"
      [nzTooltipMouseEnterDelay]="tooltipDelay"
      [routerLink]="['/admin']"
      routerLinkActive="active-router"
      *ngIf="user | isDev"
    >
      <i nz-icon nzType="control" class="large"></i>
      <span *ngIf="!collapse">Admin Tools</span>
    </li>
    <li
      nz-menu-item
      nz-tooltip="Organisation"
      nzTooltipPlacement="right"
      [nzTooltipMouseEnterDelay]="tooltipDelay"
      [routerLink]="['/organisation', user.organisation.id]"
      routerLinkActive="active-router"
    >
      <mat-icon>view_cozy</mat-icon>

      <span *ngIf="!collapse">Organisation</span>
    </li>
    <li
      nz-menu-item
      nz-tooltip="Services"
      nzTooltipPlacement="right"
      [nzTooltipMouseEnterDelay]="tooltipDelay"
      [routerLink]="['/services']"
      routerLinkActive="active-router"
    >
      <mat-icon>group_work</mat-icon>
      <span *ngIf="!collapse">Services</span>
    </li>
    <ng-container *ngIf="(currentPage$ | async) === 'service'">
      <ng-container *ngIf="isDepartement$ | async as departement">
        <li nz-menu-divider></li>
        <div class="submenu bg-blue-900">
          <!-- <li
          nz-menu-item
          (click)="onSelect('departement_users')"
          nz-tooltip
          nzTooltipTitle="Collaborateurs"
          nzTooltipPlacement="right"
          [nzTooltipMouseEnterDelay]="tooltipDelay"
          class="active-subrouter"
        >
          <mat-icon>groups</mat-icon>
          <span *ngIf="!collapse">Collaborateurs</span>
        </li> -->
        </div>
        <li nz-menu-divider></li>
      </ng-container>
    </ng-container>
    <li
      nz-menu-item
      nz-tooltip="Entités"
      nzTooltipPlacement="right"
      [nzTooltipMouseEnterDelay]="tooltipDelay"
      [routerLink]="['/entites']"
      routerLinkActive="active-router"
    >
      <mat-icon>domain</mat-icon>
      <span *ngIf="!collapse">Entités</span>
    </li>
    <ng-container *ngIf="(currentPage$ | async) === 'entite'">
      <ng-container *ngIf="isEntite$ | async as entite">
        <li nz-menu-divider></li>
        <div class="submenu bg-blue-900">
          <li
            nz-menu-item
            (click)="onSelect('entite_suivis')"
            nz-tooltip
            nzTooltipTitle="Suivis"
            nzTooltipPlacement="right"
            [nzTooltipMouseEnterDelay]="tooltipDelay"
            class="active-subrouter"
          >
            <mat-icon>article</mat-icon>

            <span *ngIf="!collapse">Suivis</span>
          </li>
          <li
            nz-menu-item
            [nzTooltipMouseEnterDelay]="tooltipDelay"
            nzTooltipPlacement="right"
            nz-tooltip
            nzTooltipTitle="Tâches"
            (click)="onSelect('entite_tasks')"
            class="active-subrouter"
          >
            <mat-icon>checklist</mat-icon>
            <span *ngIf="!collapse">Tâches</span>
          </li>

          <li
            nz-menu-item
            (click)="onSelect('entite_documents')"
            nz-tooltip
            nzTooltipTitle="Documents"
            nzTooltipPlacement="right"
            [nzTooltipMouseEnterDelay]="tooltipDelay"
            class="active-subrouter"
          >
            <mat-icon>insert_drive_file</mat-icon>
            <span *ngIf="!collapse">Documents</span>
          </li>
          <li
            nz-menu-item
            (click)="onSelect('entite_collaborateurs')"
            nz-tooltip
            nzTooltipTitle="Collaborateurs"
            nzTooltipPlacement="right"
            [nzTooltipMouseEnterDelay]="tooltipDelay"
            class="active-subrouter"
          >
            <mat-icon>person</mat-icon>

            <span *ngIf="!collapse">Collaborateurs</span>
          </li>

          <li
            nz-menu-item
            (click)="onSelect('entite_metiers')"
            nz-tooltip
            nzTooltipTitle="Métiers"
            nzTooltipPlacement="right"
            [nzTooltipMouseEnterDelay]="tooltipDelay"
            class="active-subrouter"
          >
            <mat-icon> business_center </mat-icon>
            <span *ngIf="!collapse">Métiers</span>
          </li>
          <li
            nz-menu-item
            (click)="onSelect('entite_actions')"
            nz-tooltip
            nzTooltipTitle="Actions"
            nzTooltipPlacement="right"
            [nzTooltipMouseEnterDelay]="tooltipDelay"
            class="active-subrouter"
          >
            <mat-icon>checklist</mat-icon>
            <span *ngIf="!collapse">Actions</span>
          </li>
          <!-- <li
          nz-menu-item
          (click)="onSelect('entite_projets')"
          nz-tooltip
          nzTooltipTitle="Projets"
          nzTooltipPlacement="right"
          [nzTooltipMouseEnterDelay]="tooltipDelay"
          class="active-subrouter"
        >
          <mat-icon> analytics </mat-icon>
          <span *ngIf="!collapse">Projets</span>
        </li> -->
          <li
            nz-menu-item
            (click)="onSelect('entite_dashboard')"
            nz-tooltip
            nzTooltipTitle="Tableau de bord"
            nzTooltipPlacement="right"
            [nzTooltipMouseEnterDelay]="tooltipDelay"
            class="active-subrouter"
          >
            <mat-icon>query_stats</mat-icon>
            <span *ngIf="!collapse">Tableau de bord</span>
          </li>
        </div>
      </ng-container>
    </ng-container>
    <li
      nz-menu-item
      nz-tooltip="Contacts"
      nzTooltipPlacement="right"
      [nzTooltipMouseEnterDelay]="tooltipDelay"
      [routerLink]="['/contacts']"
      routerLinkActive="active-router"
    >
      <mat-icon>person</mat-icon>
      <span *ngIf="!collapse">Contacts</span>
    </li>
    <ng-container *ngIf="(currentPage$ | async) === 'contact'">
      <ng-container *ngIf="isContact$ | async as contact">
        <li nz-menu-divider></li>
        <div class="submenu bg-blue-900">
          <li
            nz-menu-item
            (click)="onSelect('contact_details')"
            nz-tooltip
            nzTooltipTitle="Profil"
            nzTooltipPlacement="right"
            [nzTooltipMouseEnterDelay]="tooltipDelay"
            class="active-subrouter"
          >
            <mat-icon>person</mat-icon>
            <span *ngIf="!collapse">Profil</span>
          </li>
          <li
            nz-menu-item
            (click)="onSelect('contact_suivis')"
            nz-tooltip
            nzTooltipTitle="Suivis"
            nzTooltipPlacement="right"
            [nzTooltipMouseEnterDelay]="tooltipDelay"
            class="active-subrouter"
          >
            <mat-icon>article</mat-icon>
            <span *ngIf="!collapse">Suivis</span>
          </li>

          <li
            nz-menu-item
            (click)="onSelect('contact_documents')"
            nz-tooltip
            nzTooltipTitle="Documents"
            nzTooltipPlacement="right"
            [nzTooltipMouseEnterDelay]="tooltipDelay"
            class="active-subrouter"
          >
            <mat-icon>insert_drive_file</mat-icon>

            <span *ngIf="!collapse">Documents</span>
          </li>

          <li nz-menu-divider></li>
          <li
            nz-menu-item
            (click)="onSelect('contact_actions')"
            nz-tooltip
            nzTooltipTitle="Actions"
            nzTooltipPlacement="right"
            [nzTooltipMouseEnterDelay]="tooltipDelay"
            class="active-subrouter"
          >
            <mat-icon>checklist</mat-icon>
            <span *ngIf="!collapse">Actions</span>
          </li>

          <li
            nz-menu-item
            (click)="onSelect('contact_entites')"
            nz-tooltip
            nzTooltipTitle="Entités"
            nzTooltipPlacement="right"
            [nzTooltipMouseEnterDelay]="tooltipDelay"
            class="active-subrouter"
          >
            <mat-icon>domaine</mat-icon>
            <span *ngIf="!collapse">Entités</span>
          </li>

          <li
            nz-menu-item
            (click)="onSelect('contact_metiers')"
            nz-tooltip
            nzTooltipTitle="Métiers"
            nzTooltipPlacement="right"
            [nzTooltipMouseEnterDelay]="tooltipDelay"
            class="active-subrouter"
          >
            <mat-icon>business_center</mat-icon>
            <span *ngIf="!collapse">Métiers</span>
          </li>

          <li
            nz-menu-item
            (click)="onSelect('contact_projets')"
            nz-tooltip
            nzTooltipTitle="Projets"
            nzTooltipPlacement="right"
            [nzTooltipMouseEnterDelay]="tooltipDelay"
            class="active-subrouter"
          >
            <mat-icon> analytics </mat-icon>
            <span *ngIf="!collapse">Projets</span>
          </li>
        </div>

        <li nz-menu-divider></li>
      </ng-container>
    </ng-container>
    <li
      nz-menu-item
      nz-tooltip="Participants"
      nzTooltipPlacement="right"
      [nzTooltipMouseEnterDelay]="tooltipDelay"
      [routerLink]="['/participants']"
      routerLinkActive="active-router"
    >
      <mat-icon>supervisor_account</mat-icon>
      <span *ngIf="!collapse">Participants</span>
    </li>
    <ng-container *ngIf="(currentPage$ | async) === 'participant'">
      <ng-container *ngIf="isParticipant$ | async as participant">
        <li nz-menu-divider></li>
        <div class="submenu bg-blue-900">
          <!-- <li
            nz-menu-item
            nz-tooltip
            nzTooltipTitle="Diagnostic"
            nzTooltipPlacement="right"
            [nzTooltipMouseEnterDelay]="tooltipDelay"
            [routerLink]="['/participant', participant.id, 'diagnostic']"
            class="active-subrouter"
          >
            <mat-icon>fact_check</mat-icon>
            <span *ngIf="!collapse">Diagnostics</span>
          </li> -->
          <li
            nz-menu-item
            nz-tooltip
            nzTooltipTitle="Suivis"
            nzTooltipPlacement="right"
            [nzTooltipMouseEnterDelay]="tooltipDelay"
            [routerLink]="['/participant', participant.id, 'suivis']"
            class="active-subrouter"
          >
            <mat-icon>article</mat-icon>
            <span *ngIf="!collapse">Suivis</span>
          </li>

          <li
            nz-menu-item
            nz-tooltip
            nzTooltipTitle="Documents"
            nzTooltipPlacement="right"
            [nzTooltipMouseEnterDelay]="tooltipDelay"
            [routerLink]="['/participant', participant.id, 'documents']"
            class="active-subrouter"
          >
            <mat-icon>insert_drive_file</mat-icon>
            <span *ngIf="!collapse">Documents</span>
          </li>

          <li
            nz-menu-item
            nz-tooltip
            nzTooltipTitle="Actions"
            nzTooltipPlacement="right"
            [nzTooltipMouseEnterDelay]="tooltipDelay"
            [routerLink]="['/participant', participant.id, 'actions']"
            class="active-subrouter"
          >
            <mat-icon>checklist</mat-icon>
            <span *ngIf="!collapse">Actions</span>
          </li>
          <li
            nz-menu-item
            nz-tooltip
            nzTooltipTitle="Planning"
            nzTooltipPlacement="right"
            [nzTooltipMouseEnterDelay]="tooltipDelay"
            [routerLink]="['/participant', participant.id, 'planning']"
            class="active-subrouter"
          >
            <mat-icon>calendar_month</mat-icon>
            <span *ngIf="!collapse">Planning</span>
          </li>
          <li
            nz-menu-item
            nz-tooltip
            nzTooltipTitle="Impacts"
            nzTooltipPlacement="right"
            [nzTooltipMouseEnterDelay]="tooltipDelay"
            [routerLink]="['/participant', participant.id, 'impacts']"
            class="active-subrouter"
          >
            <mat-icon>radar</mat-icon>
            <span *ngIf="!collapse">Impacts</span>
          </li>

          <li
            nz-menu-item
            nz-tooltip
            nzTooltipTitle="Tableau de bord"
            nzTooltipPlacement="right"
            [nzTooltipMouseEnterDelay]="tooltipDelay"
            [routerLink]="['/participant', participant.id, 'dashboard']"
            class="active-subrouter"
          >
            <mat-icon>query_stats</mat-icon>
            <span *ngIf="!collapse">Tableau de bord</span>
          </li>

          <!-- 
        <li
          nz-menu-item
          (click)="onSelect('participant_projets')"
          nz-tooltip
          nzTooltipTitle="Projets"
          nzTooltipPlacement="right"
          [nzTooltipMouseEnterDelay]="tooltipDelay"
          class="active-subrouter"
        >
          <mat-icon> analytics </mat-icon>
          <span *ngIf="!collapse">Projets</span>
        </li> -->

          <!-- <li
          nz-menu-item
          (click)="onSelect('participant_prescripteur')"
          nz-tooltip
          nzTooltipTitle="Prescripteur"
          nzTooltipPlacement="right"
          [nzTooltipMouseEnterDelay]="tooltipDelay"
          class="active-subrouter"
        >
          <mat-icon>real_estate_agent</mat-icon>
          <span *ngIf="!collapse">Prescripteurs</span>
        </li> -->
          <!-- 
        <li
          nz-menu-item
          (click)="onSelect('participant_projetsProfessionnels')"
          nz-tooltip
          nzTooltipTitle="Projets professionnels"
          nzTooltipPlacement="right"
          [nzTooltipMouseEnterDelay]="tooltipDelay"
        >
          <mat-icon>business_center</mat-icon>
          <span *ngIf="!collapse">Projets professionnels</span>
        </li> -->
          <!-- <li nz-menu-item (click)="onSelect('participant_aides')"
                      nz-tooltip
            nzTooltipTitle="Aides du participants"
            nzTooltipPlacement="right"
            [nzTooltipMouseEnterDelay]="tooltipDelay"
      >
            <mat-icon>volunteer_activism</mat-icon>
            <span *ngIf="!collapse">Aides</span>
          </li> -->
          <!-- <li nz-menu-item (click)="onSelect('participant_orientation')"
                      nz-tooltip
            nzTooltipTitle="Détail du projet"
            nzTooltipPlacement="right"
            [nzTooltipMouseEnterDelay]="tooltipDelay"
      >
            <mat-icon>route</mat-icon>
            <span *ngIf="!collapse">Orientations</span>
          </li> -->
          <!-- <li nz-menu-item (click)="onSelect('participant_dashboard')"
                      nz-tooltip
            nzTooltipTitle="Détail du projet"
            nzTooltipPlacement="right"
            [nzTooltipMouseEnterDelay]="tooltipDelay"
      >
            <mat-icon>pie_chart</mat-icon>
            <span *ngIf="!collapse">Tableau de bord</span>
          </li> -->
        </div>

        <li nz-menu-divider></li>
      </ng-container>
    </ng-container>
    <li
      nz-menu-item
      nz-tooltip="Missions"
      nzTooltipPlacement="right"
      [nzTooltipMouseEnterDelay]="tooltipDelay"
      [routerLink]="['/missions']"
      routerLinkActive="active-router"
    >
      <mat-icon>work_history</mat-icon>
      <span *ngIf="!collapse">Missions</span>
    </li>
    <ng-container *ngIf="(currentPage$ | async) === 'mission'">
      <ng-container *ngIf="isMission$ | async as mission">
        <li nz-menu-divider></li>
        <div class="submenu bg-blue-900"></div>
      </ng-container>
    </ng-container>

    <li
      nz-menu-item
      nz-tooltip="Projets"
      nzTooltipPlacement="right"
      [nzTooltipMouseEnterDelay]="tooltipDelay"
      [routerLink]="['/projets']"
      routerLinkActive="active-router"
    >
      <mat-icon>analytics</mat-icon>
      <span *ngIf="!collapse">Projets</span>
    </li>
    <!-- <li
      nz-menu-item
      nz-tooltip="Tableau de bord"
      nzTooltipPlacement="right"
      [nzTooltipMouseEnterDelay]="tooltipDelay"
      [routerLink]="['/dashboard']"
      routerLinkActive="active-router"
    >
      <i nz-icon nzType="pie-chart" class="large"></i>
      <span *ngIf="!collapse">Tableau de bord</span>
    </li> -->
    <!-- <ng-container *ngIf="(currentPage$ | async) === 'projet'">
      <ng-container *ngIf="isOperation$ | async as operation">
        <li nz-menu-divider></li>
        <div class="submenu bg-blue-950">
          <li
            nz-menu-item
            [nzTooltipMouseEnterDelay]="tooltipDelay"
            nzTooltipPlacement="right"
            nz-tooltip
            nzTooltipTitle="Équipe"
            [routerLink]="['/projet', operation.id, 'team']"
            class="active-subrouter"
          >
            <mat-icon>groups</mat-icon>
            <span *ngIf="!collapse">Équipe</span>
          </li>
          <li
            nz-menu-item
            [nzTooltipMouseEnterDelay]="tooltipDelay"
            nzTooltipPlacement="right"
            nz-tooltip
            nzTooltipTitle="Suivis"
            [routerLink]="['/projet', operation.id, 'suivis']"
            class="active-subrouter"
          >
            <mat-icon>article</mat-icon>

            <span *ngIf="!collapse">Suivis</span>
          </li>
          <li
            nz-menu-item
            [nzTooltipMouseEnterDelay]="tooltipDelay"
            nzTooltipPlacement="right"
            nz-tooltip
            nzTooltipTitle="Documents"
            [routerLink]="['/projet', operation.id, 'documents']"
            class="active-subrouter"
          >
            <mat-icon>insert_drive_file</mat-icon>
            <span *ngIf="!collapse">Documents</span>
          </li>
          <li
            nz-menu-item
            [nzTooltipMouseEnterDelay]="tooltipDelay"
            nzTooltipPlacement="right"
            nz-tooltip
            nzTooltipTitle="Tâches"
            [routerLink]="['/projet', operation.id, 'tasks']"
            class="active-subrouter"
          >
            <mat-icon>checklist</mat-icon>
            <span *ngIf="!collapse">Tâches</span>
          </li>

          <li
            nz-menu-item
            *ngIf="operation?.type !== 'Clauses sociales'"
            (click)="onSelect('operation_entites')"
            [nzTooltipMouseEnterDelay]="tooltipDelay"
            nzTooltipPlacement="right"
            nz-tooltip
            nzTooltipTitle="Entités"
            class="active-subrouter"
          >
            <mat-icon>domain</mat-icon>
            <span *ngIf="operation?.type && !collapse">{{
              operation?.type | operationType : "entite"
            }}</span>
          </li>
          <li
            nz-menu-item
            *ngIf="operation?.type !== 'Clauses sociales'"
            (click)="onSelect('operation_contacts')"
            nz-tooltip
            [nzTooltipMouseEnterDelay]="tooltipDelay"
            nzTooltipPlacement="right"
            nz-tooltip
            nzTooltipTitle="Collaborateurs"
            class="active-subrouter"
          >
            <mat-icon>person</mat-icon>
            <span *ngIf="operation?.type && !collapse">{{
              operation?.type | operationType : "contact"
            }}</span>
          </li>
          <li
            nz-menu-item
            [routerLink]="['/projet', operation.id, 'participants']"
            [nzTooltipMouseEnterDelay]="tooltipDelay"
            nzTooltipPlacement="right"
            nz-tooltip
            nzTooltipTitle="Participants"
            class="active-subrouter"
          >
            <mat-icon>supervisor_account</mat-icon>
            <span *ngIf="!collapse">{{
              operation?.type | operationType : "participant"
            }}</span>
          </li>
          <li
            nz-menu-item
            [routerLink]="['/projet', operation.id, 'prescripteurs']"
            [nzTooltipMouseEnterDelay]="tooltipDelay"
            nzTooltipPlacement="right"
            nz-tooltip
            nzTooltipTitle="Prescripteurs"
            class="active-subrouter"
          >
            <mat-icon>real_estate_agent</mat-icon>
            <span *ngIf="!collapse">Prescripteurs</span>
          </li>
          <li
                nz-menu-item
                (click)="onSelect('planification')"
                nz-tooltip
                [nzTooltipMouseEnterDelay]="tooltipDelay"
                nzTooltipPlacement="right"
                nzTooltipTitle="Planification permet le management des tâches à réaliser pour le projet."
                *ngIf="!collapse"
              >
                <mat-icon>task</mat-icon>
                <span>Planification</span>
              </li>
          <li
            nz-menu-item
            [routerLink]="['/projet', operation.id, 'modules']"
            [nzTooltipMouseEnterDelay]="tooltipDelay"
            nzTooltipPlacement="right"
            nz-tooltip
            [nzTooltipTitle]="operation?.type | operationType : 'module'"
            class="active-subrouter"
          >
            <mat-icon outline>dynamic_feed</mat-icon>
            <span *ngIf="operation?.type && !collapse">{{
              operation?.type | operationType : "module"
            }}</span>
          </li>
          <li
            nz-menu-item
            [nzTooltipMouseEnterDelay]="tooltipDelay"
            nzTooltipPlacement="right"
            nz-tooltip
            [nzTooltipTitle]="operation?.type | operationType : 'action'"
            class="active-subrouter"
            [routerLink]="['/projet', operation.id, 'actions']"
          >
            <mat-icon>dashboard</mat-icon>
            <span *ngIf="operation?.type && !collapse">{{
              operation?.type | operationType : "action"
            }}</span>
          </li>
          <li
            nz-menu-item
            [nzTooltipMouseEnterDelay]="tooltipDelay"
            nzTooltipPlacement="right"
            nz-tooltip
            nzTooltipTitle="Fiches de poste"
            class="active-subrouter"
            *ngIf="operation?.type === 'Clauses sociales'"
            [routerLink]="['/projet', operation.id, 'fichesDePoste']"
          >
            <mat-icon>ballot</mat-icon>
            <span *ngIf="!collapse">Fiches de poste</span>
          </li>
          <li
            nz-menu-item
            [nzTooltipMouseEnterDelay]="tooltipDelay"
            nzTooltipPlacement="right"
            nz-tooltip
            nzTooltipTitle="Missions"
            class="active-subrouter"
            *ngIf="operation?.type === 'Clauses sociales'"
            [routerLink]="['/projet', operation.id, 'missions']"
          >
            <mat-icon>work_history</mat-icon>
            <span *ngIf="!collapse">Missions</span>
          </li> -->
    <!-- <li
          nz-menu-item
          nz-tooltip
          [nzTooltipMouseEnterDelay]="tooltipDelay"
          nzTooltipPlacement="right"
          nz-tooltip
          nzTooltipTitle="Événements"
          (click)="onSelect('operation_events')"
          class="active-subrouter"
        >
          <mat-icon>event</mat-icon>
          <span *ngIf="!collapse">Évènements</span>
        </li> -->

    <!-- <li
                nz-menu-item
                (click)="onSelect('time')"
                nz-tooltip
                [nzTooltipMouseEnterDelay]="tooltipDelay"
                nzTooltipPlacement="right"
                nzTooltipTitle="Suivi temps permet le management du temps passé par membre de l'équipe projet."
              >
                <mat-icon>timer</mat-icon>
                <span  *ngIf="!collapse">Suivi temps</span>
              </li> -->
    <!-- </div>

        <li nz-menu-divider></li>
      </ng-container>
    </ng-container> -->

    <li nz-menu-divider></li>

    <li
      nz-menu-item
      nz-tooltip="Calculatrice"
      nzTooltipPlacement="right"
      [nzTooltipMouseEnterDelay]="tooltipDelay"
      (click)="onCalculator()"
    >
      <button class="group/button btn-icon btn-hover">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 28 28"
          stroke-width="1.5"
          stroke="currentColor"
          class="iconSvg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15.75 15.75V18m-7.5-6.75h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V13.5zm0 2.25h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V18zm2.498-6.75h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V13.5zm0 2.25h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V18zm2.504-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zm0 2.25h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V18zm2.498-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zM8.25 6h7.5v2.25h-7.5V6zM12 2.25c-1.892 0-3.758.11-5.593.322C5.307 2.7 4.5 3.65 4.5 4.757V19.5a2.25 2.25 0 002.25 2.25h10.5a2.25 2.25 0 002.25-2.25V4.757c0-1.108-.806-2.057-1.907-2.185A48.507 48.507 0 0012 2.25z"
          />
        </svg>
      </button>
      <span *ngIf="!collapse">Calculatrice</span>
    </li>
    <li nz-menu-divider></li>

    <!-- <li
      nz-menu-item
      nz-tooltip="Tickets"
      nzTooltipPlacement="right"
      [nzTooltipMouseEnterDelay]="tooltipDelay"
      (click)="onTickets()"
    >
<mat-icon>receipt</mat-icon>
      
      <span *ngIf="!collapse">Tickets</span>
    </li>
    <li nz-menu-item>
      <nz-switch
        [ngModel]="false"
        [nzCheckedChildren]="checkedTemplate"
        [nzUnCheckedChildren]="unCheckedTemplate"
        (ngModelChange)="onSwitchMode($event)"
      ></nz-switch>
      <ng-template #checkedTemplate><i nz-icon nzType="check"></i></ng-template>
      <ng-template #unCheckedTemplate
        ><i nz-icon nzType="close"></i
      ></ng-template>
    </li> -->
    <!-- <li
      nz-menu-item
      nz-tooltip="Tâches"
      nzTooltipPlacement="right"
      [nzTooltipMouseEnterDelay]="tooltipDelay"
      class="pr-8"
      (click)="onTodos()"
    >
      <button class="group/button btn-icon btn-hover">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 28 28"
          stroke-width="1.5"
          stroke="currentColor"
          fill="none"
          class="iconSvg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75"
          />
        </svg>
      </button>
      <span *ngIf="!collapse">Tâches</span>
    </li>
    <li
      nz-menu-item
      nz-tooltip="Messages"
      nzTooltipPlacement="right"
      [nzTooltipMouseEnterDelay]="tooltipDelay"
      class="pr-8"
      (click)="onMessage()"
    >
      <button class="group/button btn-icon btn-hover">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 28 28"
          stroke-width="1.5"
          fill="none"
          stroke="currentColor"
          class="w-7 h-7 stroke-1 hover:stroke-blue-600"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
          />
        </svg>
      </button>
      <span *ngIf="!collapse">Messages</span>
    </li>
    <li
      nz-menu-item
      nz-tooltip="Favoris"
      nzTooltipPlacement="right"
      [nzTooltipMouseEnterDelay]="tooltipDelay"
      class="pr-8"
      (click)="onFavoris()"
    >
      <button class="group/button btn-icon btn-hover">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 28 28"
          stroke-width="1.5"
          fill="none"
          stroke="currentColor"
          class="iconSvg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
          />
        </svg>
      </button>
      <span *ngIf="!collapse">Favoris</span>
    </li> -->

    <li nz-menu-item></li>
  </ul>
</div>

<ng-template #ticketFormTitle>
  <app-header-title
    [icon]="'bug_report'"
    [title]="titleTicket"
  ></app-header-title>
</ng-template>

<ng-template #calculatorFormTitle>
  <app-header-title
    [icon]="'calculate'"
    [title]="titleCalculator"
  ></app-header-title>
</ng-template>
