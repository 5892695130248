import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoteTableComponent } from './components/note-table/note-table.component';
import { NoteListComponent } from './components/note-list/note-list.component';
import { NoteFormComponent } from './components/note-form/note-form.component';
import { NoteCardComponent } from './components/note-card/note-card.component';
import { NoteItemComponent } from './components/note-item/note-item.component';
import { NoteSubtitleListComponent } from './components/note-subtitle-list/note-subtitle-list.component';
import { NoteSubtitleCardComponent } from './components/note-subtitle-card/note-subtitle-card.component';
import { NoteSubtitleFormComponent } from './components/note-subtitle-form/note-subtitle-form.component';
import { NoteSubtitleItemComponent } from './components/note-subtitle-item/note-subtitle-item.component';
import { NoteSujetItemComponent } from './components/note-sujet-item/note-sujet-item.component';
import { NoteSujetFormComponent } from './components/note-sujet-form/note-sujet-form.component';
import { NoteSujetCardComponent } from './components/note-sujet-card/note-sujet-card.component';
import { NoteSujetListComponent } from './components/note-sujet-list/note-sujet-list.component';
import { NoteSujetAlertListComponent } from './components/note-sujet-alert-list/note-sujet-alert-list.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { NoResultModule } from '../../contents/components/no-result/no-result.module';
import { HeaderModule } from '../../contents/components/header-title/header.module';

@NgModule({
  declarations: [
    NoteTableComponent,
    NoteListComponent,
    NoteFormComponent,
    NoteCardComponent,
    NoteItemComponent,
    NoteSubtitleListComponent,
    NoteSubtitleCardComponent,
    NoteSubtitleFormComponent,
    NoteSubtitleItemComponent,
    NoteSujetItemComponent,
    NoteSujetFormComponent,
    NoteSujetCardComponent,
    NoteSujetListComponent,
    NoteSujetAlertListComponent,
  ],
  exports: [
    NoteTableComponent,
    NoteListComponent,
    NoteFormComponent,
    NoteCardComponent,
    NoteItemComponent,
    NoteSubtitleListComponent,
    NoteSubtitleCardComponent,
    NoteSubtitleFormComponent,
    NoteSubtitleItemComponent,
    NoteSujetItemComponent,
    NoteSujetFormComponent,
    NoteSujetCardComponent,
    NoteSujetListComponent,
    NoteSujetAlertListComponent,
  ],
  imports: [CommonModule, SharedModule, NoResultModule, HeaderModule],
})
export class NoteModule {}
