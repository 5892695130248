<nz-card nzBorderless [nzTitle]="logo">
  <nz-card [nzTitle]="title" class="card_content">
    <nz-form [formGroup]="emailForm">
      <nz-form-item>
        <nz-form-control [nzErrorTip]="'Aucun e-mail renseigné'">
          <input
            nz-input
            formControlName="email"
            placeholder="Renseigner votre e-mail utilisateur"
          />
        </nz-form-control>
      </nz-form-item>
    </nz-form>
    <nz-space
      nzDirection="horizontal"
      nzAlign="center"
      nzSize="middle"
    ></nz-space>
    <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>

    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="10">
        <button
          nz-button
          nzType="text"
          nzBlock
          (click)="$event.stopPropagation(); onBackToLogin()"
        >
          Retourner de connexion
        </button>
      </nz-col>
      <nz-col nzSpan="4"></nz-col>
      <nz-col nzSpan="10">
        <button
          nz-button
          nzType="text"
          nzBlock
          [disabled]="!this.emailForm.valid"
          [ngClass]="{
            btn_primary_VALIDED: this.emailForm.valid,
            btn_primary_DISABLED: !this.emailForm.valid
          }"
          (click)="onSubmit($event)"
        >
          Réinitialiser
        </button>
      </nz-col>
    </nz-row>
  </nz-card>
</nz-card>

<ng-template #logo>
  <img
    width="160px"
    height="80px"
    src="../../../../../assets/protein_logo.svg"
    alt="proteinLogo"
  />
</ng-template>

<ng-template #title>
  <app-header-title
    [title]="titleCard"
    [subtitle]="true"
    [icon]="icon"
  ></app-header-title>
</ng-template>

<ng-template #contactFormTitle>
  <app-header-title [icon]="'person'" [title]="'title'"></app-header-title>
</ng-template>

<ng-template #userFormTitle>
  <app-header-title
    [icon]="'person'"
    [title]="'Compte utilisateur'"
    [subtitle]="true"
  ></app-header-title>
</ng-template>
<ng-template #organisationFormTitle>
  <app-header-title
    [icon]="'corporate_fare'"
    [title]="'Compte organisation'"
    [subtitle]="true"
  ></app-header-title>
</ng-template>

<ng-template #loadingAnimation>
  <app-loading-animation [loading$]="loading$"></app-loading-animation>
</ng-template>

<router-outlet> </router-outlet>
