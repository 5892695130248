import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, of } from 'rxjs';
import { ContactSMALLid } from '../components/contact/store/contact.model';
import { ContactState } from '../components/contact/store/contact.reducer';
import * as fromContactAction from '../components/contact/store/contact.actions';
import * as fromContactSelector from '../components/contact/store/contact.selectors';

@Injectable({
  providedIn: 'any',
})
export class ContactsResolverService {
  constructor(
    private contactStore: Store<ContactState>,
    private router: Router
  ) {}

  resolve(
    route: ActivatedRouteSnapshot
  ):
    | Observable<ContactSMALLid[]>
    | Promise<ContactSMALLid[] | any>
    | ContactSMALLid[]
    | any {
    let find = this.loadContacts().toPromise();

    return find
      .then(() => {
        return this.getContacts();
      })
      .catch(() => {
        this.router.navigate(['/contacts']);
        return EMPTY;
      });
  }

  getContacts(): Observable<ContactSMALLid[] | any> {
    return this.contactStore.select(fromContactSelector.contacts);
  }

  loadContacts(): Observable<any> {
    return of(this.contactStore.dispatch(fromContactAction.loadContacts()));
  }
}
