import { Pipe, PipeTransform } from '@angular/core';
import { camelCase, trim } from 'lodash';
import memo from 'memo-decorator';

@Pipe({
  name: 'camelcase',
})
export class CamelcasePipe implements PipeTransform {
  @memo()
  transform(value: any): string {
    if (!value) {
      return '';
    } else {
      return camelCase(trim(value));
    }
  }
}
