import { NzModalService } from 'ng-zorro-antd/modal';
import { ContactOperationsService } from './../../../service/contact-operations.service';
import { ContactStorageService } from './../../../service/contact-storage.service';
import { ItemEventId } from '../../../features/suivi/components/item-event/itemEvent.model';
import { ContactSuivisService } from './../../../service/contact-suivis.service';
import { QueryContactsService } from './../../../service/query-contacts.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ContactsService } from 'src/app/service/contacts.service';
import { ContactState } from './contact.reducer';
import { of, EMPTY } from 'rxjs';
import { map, mergeMap, catchError, tap, delay } from 'rxjs/operators';
import { Router } from '@angular/router';
import * as fromContactAction from './contact.actions';
import { Contact, ContactId, ContactSMALLid } from './contact.model';
import { OperationSmallId } from '../../operation/store/operation.model';
import { ContactEntitesService } from 'src/app/service/contact-entites.service';
import { EntiteSMALLid } from '../../entite/store/entite.model';

@Injectable()
export class ContactEffects {
  loadContacts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromContactAction.loadContacts),
      mergeMap((action) =>
        this.contactService.getAll().pipe(
          delay(2000),

          map((contacts: ContactSMALLid[]) =>
            fromContactAction.loadContactsSuccess({ contacts })
          ),
          catchError((error) =>
            of(fromContactAction.loadContactsFailure({ error }))
          )
        )
      )
    )
  );

  loadContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromContactAction.loadContact),
      mergeMap((action) =>
        this.contactService.getOne(action.id).pipe(
          delay(2000),

          map((contact: ContactId) =>
            fromContactAction.loadContactSuccess({ contact })
          ),
          catchError((error) =>
            of(fromContactAction.loadContactFailure({ error }))
          )
        )
      )
    )
  );

  loadContactsFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromContactAction.loadContactsFilters),
      tap(() =>
        this.contactStore.dispatch(fromContactAction.loading({ loading: true }))
      ),
      mergeMap((action) =>
        this.queryContacts.getFilters().pipe(
          map((respons: any) => {
            const filters = respons?.data?.me?.organisation?.filtersContact;
            this.contactStore.dispatch(
              fromContactAction.loading({ loading: false })
            );
            return fromContactAction.loadContactsFiltersSuccess({ filters });
          }),
          catchError((error) => {
            this.contactStore.dispatch(
              fromContactAction.loading({ loading: false })
            );
            return of(fromContactAction.loadContactsFiltersFailure({ error }));
          })
        )
      )
    )
  );

  queryContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromContactAction.queryContacts),
      mergeMap((action) =>
        this.queryContacts.queryContacts(action.filter).pipe(
          map((contacts: ContactSMALLid[]) =>
            fromContactAction.queryContactsSuccess({ contacts })
          ),
          catchError((error) =>
            of(fromContactAction.queryContactsFailure({ error }))
          )
        )
      )
    )
  );

  addContact$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromContactAction.addContact),
        mergeMap((action) =>
          this.contactService.addOne(action.contact).pipe(
            map((response) => {
              const { exists, user } = response;
              if (exists?.length || exists?.length > 0) {
                const contactFind: ContactSMALLid = exists[0];
                this.warningExistContact(action.contact, contactFind, user);
              } else {
                this.contactStore.dispatch(
                  fromContactAction.addContactConfirmation({
                    contact: action.contact,
                    user,
                  })
                );
              }
              return of(null);
            }),
            catchError((error) =>
              of(fromContactAction.addContactFailure({ error }))
            )
          )
        )
      ),
    { dispatch: false }
  );

  addContactConfirmation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromContactAction.addContactConfirmation),
      mergeMap((action) =>
        this.contactService.addConfirmation(action.contact, action.user).pipe(
          map((contact: ContactSMALLid) => {
            const { civilite, lastName, firstName } = contact;

            const messageSucces: string = `${civilite}. ${lastName} ${firstName} ajouté`;
            this.message.success(messageSucces, { nzDuration: 4000 });

            return fromContactAction.addContactSuccess({ contact });
          }),
          catchError((error) =>
            of(fromContactAction.addContactFailure({ error }))
          )
        )
      )
    )
  );

  updateContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromContactAction.updateContact),
      mergeMap((action) =>
        this.contactService
          .updateOne(action.contact.id, action.contact.changes)
          .pipe(
            map((success: string) => {
              this.message.success(success, { nzDuration: 6000 });
              return fromContactAction.updateContactSuccess({ success });
            }),
            catchError((error) =>
              of(fromContactAction.updateContactFailure({ error }))
            )
          )
      )
    )
  );

  deleteContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromContactAction.deleteContact),
      tap(() => this.router.navigate(['/contacts'])),
      mergeMap((action) =>
        this.contactService.deleteOne(action.id).pipe(
          map((success: string) => {
            this.message.success(success, { nzDuration: 6000 });
            return fromContactAction.deleteContactSuccess({ success });
          }),

          catchError((error) =>
            of(fromContactAction.deleteContactFailure({ error }))
          )
        )
      )
    )
  );

  //SUIVIS
  loadSuivis$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromContactAction.loadSuivis),
      mergeMap((action) =>
        this.contactSuivis.getAll(action.contactId).pipe(
          map((suivis: ItemEventId[]) =>
            fromContactAction.loadSuivisSuccess({
              suivis,
            })
          ),
          catchError((error) =>
            of(fromContactAction.loadSuivisFailure({ error }))
          )
        )
      )
    )
  );
  addSuivi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromContactAction.addSuivi),
      mergeMap((action) =>
        this.contactSuivis.addOne(action.contactId, action.suivi).pipe(
          map((suivi: ItemEventId) => {
            this.message.success(`Suivi ajouté`, { nzDuration: 6000 });
            return fromContactAction.addSuiviSuccess({
              suivi,
            });
          }),
          catchError((error) =>
            of(fromContactAction.addSuiviFailure({ error }))
          )
        )
      )
    )
  );
  updateSuivi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromContactAction.updateSuivi),
      mergeMap((action) =>
        this.contactSuivis
          .updateOne(action.contactId, action.suivi.changes)
          .pipe(
            map((suivi: ItemEventId) => {
              this.message.success(`Suivi modifié`, { nzDuration: 6000 });
              return fromContactAction.updateSuiviSuccess({
                suivi,
              });
            }),
            catchError((error) =>
              of(fromContactAction.updateSuiviFailure({ error }))
            )
          )
      )
    )
  );
  deleteSuivi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromContactAction.deleteSuivi),
      mergeMap((action) =>
        this.contactSuivis.deleteOne(action.contactId, action.suiviId).pipe(
          map((success: string) => {
            this.message.success(success, { nzDuration: 6000 });
            return fromContactAction.deleteSuiviSuccess({
              success,
            });
          }),
          catchError((error) =>
            of(fromContactAction.deleteSuiviFailure({ error }))
          )
        )
      )
    )
  );

  //DOCUMENT
  //load
  loadDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromContactAction.loadDocuments),
      mergeMap((action) =>
        this.contactStorage.getAll(action.contactId).pipe(
          map((documents: any) =>
            fromContactAction.loadDocumentsSuccess({
              documents,
            })
          ),
          catchError((error) =>
            of(fromContactAction.loadDocumentsFailure({ error }))
          )
        )
      )
    )
  );

  //add
  addDocument$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromContactAction.addDocuments),
        mergeMap((action) =>
          this.contactStorage.addFiles(action.contact, action.documents).pipe(
            map((documents: any) => {
              const plurial: string = `${
                action.documents?.length > 1 ? 's' : ''
              }`;

              const success: string = `Document${plurial} ajouté${plurial}`;
              this.message.success(success, { nzDuration: 6000 });
              return fromContactAction.addDocumentsSuccess({
                documents,
              });
            }),
            catchError((error) =>
              of(fromContactAction.addDocumentsFailure({ error }))
            )
          )
        )
      ),
    { dispatch: false }
  );

  //delete
  deleteDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromContactAction.deleteDocument),
      mergeMap((action) =>
        this.contactStorage.deleteOne(action.contactId, action.document).pipe(
          map((success: string) => {
            this.message.success(success, { nzDuration: 6000 });
            return fromContactAction.deleteDocumentSuccess({
              success,
            });
          }),
          catchError((error) =>
            of(fromContactAction.deleteDocumentFailure({ error }))
          )
        )
      )
    )
  );

  //OPERATIONS
  //load
  loadOperations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromContactAction.loadOperations),
      mergeMap((action) =>
        this.contactOperations.getAll(action.contactId).pipe(
          map((operations: any) =>
            fromContactAction.loadOperationsSuccess({
              operations,
            })
          ),
          catchError((error) =>
            of(fromContactAction.loadOperationsFailure({ error }))
          )
        )
      )
    )
  );
  //add
  addOperations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromContactAction.addOperation),
      mergeMap((action) =>
        this.contactOperations
          .addOne(action.contact, action.operation, action.intervention)
          .pipe(
            map((success: string) => {
              this.message.success(success, { nzDuration: 6000 });
              return fromContactAction.addOperationSuccess({
                success,
              });
            }),
            catchError((error) =>
              of(fromContactAction.addOperationFailure({ error }))
            )
          )
      )
    )
  );

  //load
  updateOperations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromContactAction.updateOperation),
      mergeMap((action) =>
        this.contactOperations.updateOne(action.contact, action.operation).pipe(
          map((success: string) => {
            this.message.success(success, { nzDuration: 6000 });
            return fromContactAction.addOperationSuccess({
              success,
            });
          }),
          catchError((error) =>
            of(fromContactAction.addOperationFailure({ error }))
          )
        )
      )
    )
  );

  //delete
  deleteOperations$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromContactAction.deleteOperation),
        mergeMap((action) =>
          this.contactOperations
            .deleteOne(action.contact, action.operation)
            .pipe(
              map((success: string) => {
                this.message.success(success, { nzDuration: 6000 });
                return fromContactAction.deleteOperationSuccess({
                  success,
                });
              }),
              catchError((error) =>
                of(fromContactAction.deleteOperationFailure({ error }))
              )
            )
        )
      ),
    { dispatch: false }
  );

  //ENTITES
  loadEntites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromContactAction.loadContactEntites),
      mergeMap((action) =>
        this.contactEntiteService.getAll(action.contact).pipe(
          map((entites: EntiteSMALLid[]) => {
            return fromContactAction.loadContactEntitesSuccess({
              entites,
            });
          }),
          catchError((error) =>
            of(fromContactAction.loadContactEntitesFailure({ error }))
          )
        )
      )
    )
  );

  addEntite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromContactAction.addContactEntites),
      mergeMap((action) =>
        this.contactEntiteService.addOne(action.contact, action.entite).pipe(
          map((success: string) => {
            this.message.success(success, { nzDuration: 6000 });

            return fromContactAction.addContactEntitesSuccess({
              success,
            });
          }),
          catchError((error) =>
            of(fromContactAction.addContactEntitesFailure({ error }))
          )
        )
      )
    )
  );

  deleteEntite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromContactAction.deleteContactEntites),
      mergeMap((action) =>
        this.contactEntiteService.deleteOne(action.contact, action.entite).pipe(
          map((success: string) => {
            this.message.success(success, { nzDuration: 6000 });
            return fromContactAction.deleteContactEntitesSuccess({
              success,
            });
          }),
          catchError((error) =>
            of(fromContactAction.deleteContactEntitesFailure({ error }))
          )
        )
      )
    )
  );

  //METIERS

  constructor(
    private actions$: Actions,
    private router: Router,
    private contactService: ContactsService,
    private contactStore: Store<ContactState>,
    private message: NzMessageService,
    private queryContacts: QueryContactsService,
    private contactSuivis: ContactSuivisService,
    private contactStorage: ContactStorageService,
    private contactOperations: ContactOperationsService,
    private modal: NzModalService,
    private contactEntiteService: ContactEntitesService
  ) {}

  warningExistContact(
    contact: Contact,
    existContact: ContactSMALLid,
    user: any
  ): void {
    const { civilite, lastName, firstName } = existContact;
    const displayName: string = `${civilite}. ${lastName} ${firstName}`;
    const modal = this.modal.create({
      nzTitle: 'Contact trouvé',
      nzContent: `<strong>${displayName}</strong> <br> a déjà été ajouté. Voulez-vous confirmer l\'enregistrement de ce contact ?`,

      nzFooter: [
        {
          label: 'Confirmer',
          type: 'primary',
          onClick: () => {
            this.contactStore.dispatch(
              fromContactAction.addContactConfirmation({ contact, user })
            );
            modal.destroy();
          },
        },
        {
          label: 'Annuler',
          type: 'text',
          onClick: () => {
            this.onCancel();
            modal.destroy();
          },
        },
      ],
    });
  }

  onCancel(): void {
    return;
  }
}
