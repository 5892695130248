<nz-comment
  [nzAuthor]="comment?.auteur?.displayName"
  [nzDatetime]="dateAndAlertTpl"
  *ngIf="user$ | async as user"
>
  <nz-avatar
    nz-comment-avatar
    [nzText]="comment?.auteur?.avatar"
    nzSize="small"
    [ngStyle]="{
      'background-color': comment?.auteur?.setting?.color
        ? comment?.auteur?.setting?.color
        : default
    }"
  ></nz-avatar>

  <nz-comment-content>
    <nz-tag *ngIf="comment?.userUpdate" nzColor="orange">
      Modifier par {{ comment?.userUpdate?.displayName }} le
      {{ comment?.dateUpdate | dateFr: "small" }}
    </nz-tag>
    <p
      *ngIf="!(isUpdate$ | async)"
      [innerHTML]="comment.textHTML | stringhtml"
    ></p>

    <nz-card
      *ngIf="isUpdate$ | async"
      nzSize="small"
      nzTitle="Modifier le commentaire"
      [nzExtra]="cardUpdateBtn"
    >
      <ng-template #cardUpdateBtn>
        <nz-button-group nzSize="small">
          <button
            nz-button
            nzType="text"
            (click)="$event.stopPropagation(); onCancelComment()"
          >
            Annuler
          </button>
          <button
            nz-button
            nzSize="small"
            nzType="primary"
            (click)="
              $event.stopPropagation(); onUpdateValidation(user, sujet, comment)
            "
          >
            Valider
          </button>
        </nz-button-group>
      </ng-template>
      <app-text-edithing
        [update]="currentSujetUpdate$"
        (add)="onCommentTextChanges($event)"
      ></app-text-edithing>
    </nz-card>
  </nz-comment-content>

  <nz-comment-action>
    <mat-icon
      *ngIf="!(isUpdate$ | async)"
      nz-tooltip
      nzTooltipPlacement="top"
      [nzTooltipTitle]="comment?.isWarning ? 'Retirer l\'alerte' : 'Alerter'"
      [nzTooltipMouseEnterDelay]="tooltipDelayEnter"
      (click)="$event.stopPropagation(); onAlert(sujet, comment, user)"
      >feedback</mat-icon
    >
  </nz-comment-action>
  <nz-comment-action>
    <mat-icon
      *ngIf="!(isUpdate$ | async)"
      nz-tooltip
      nzTooltipPlacement="top"
      [nzTooltipTitle]="
        sujet?.isWarning ? 'Rétirer la réalisation' : 'Réaliser'
      "
      [nzTooltipMouseEnterDelay]="tooltipDelayEnter"
      (click)="$event.stopPropagation(); onDone(sujet, comment, user)"
      >fact_check</mat-icon
    >
  </nz-comment-action>
  <nz-comment-action>
    <mat-icon
      *ngIf="!(isUpdate$ | async)"
      nz-tooltip
      nzTooltipPlacement="top"
      nzTooltipTitle="Modifier"
      (click)="$event.stopPropagation(); onUpdateComment(sujet, comment, user)"
      [nzTooltipMouseEnterDelay]="tooltipDelayEnter"
      >edit</mat-icon
    >
  </nz-comment-action>
  <nz-comment-action>
    <mat-icon
      *ngIf="!(isUpdate$ | async)"
      nz-tooltip
      nzTooltipPlacement="top"
      nzTooltipTitle="Supprimer"
      [nzTooltipMouseEnterDelay]="tooltipDelayEnter"
      (click)="$event.stopPropagation()"
      nz-popconfirm
      [nzPopconfirmTitle]="'Supprimer ce commentaire ?'"
      nzPopconfirmPlacement="bottom"
      (nzOnConfirm)="onDelete(sujet, comment)"
      (nzOnCancel)="onCancel()"
      >delete</mat-icon
    >
  </nz-comment-action>

  <ng-template #dateAndAlertTpl>
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="10">
        {{ comment?.dateStart | dateFr: "time" }}
      </nz-col>
      <nz-col nzSpan="4"> </nz-col>
      <nz-col nzSpan="5">
        <nz-tag *ngIf="comment?.isWarning" nzColor="error"> Alerte </nz-tag>
      </nz-col>
      <nz-col nzSpan="5">
        <nz-tag *ngIf="comment?.isDone" nzColor="success"> Réalisé </nz-tag>
      </nz-col>
    </nz-row>
  </ng-template>
</nz-comment>
