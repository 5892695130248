<nz-card nzBorderless>
  <app-utilities-detail
    [utility]="utility$ | async"
    [items]="items"
    [variable]="variable"
    [currentTag]="currentTag"
  ></app-utilities-detail>

  <nz-space [nzSize]="100" nzDirection="horizontal"></nz-space>
  <ul nz-list [nzDataSource]="utilities$ | async" nzBordered nzSize="small">
    <cdk-virtual-scroll-viewport itemSize="50" class="viewport">
      <li
        nz-list-item
        *cdkVirtualFor="let item of utilities$ | async"
        nzNoFlex
        class="item"
      >
        <ul nz-list-item-actions>
          <nz-list-item-action>
            <button
              nz-button
              nzShape="circle"
              nzSize="small"
              (click)="onSelect(item)"
              nz-tooltip
              [nzTooltipMouseEnterDelay]="0.5"
              nzTooltipPlacement="left"
              nzTooltipTitle="Afficher"
            >
              <i nz-icon nzType="eye"></i>
            </button>
          </nz-list-item-action>
        </ul>
        {{ item?.name | titlecase }}
      </li>
    </cdk-virtual-scroll-viewport>
  </ul>
</nz-card>
