import { Injectable } from '@angular/core';
import { OperationId } from './../components/operation/store/operation.model';
import {
  Firestore,
  collection,
  doc,
  docSnapshots,
  collectionChanges,
  getDoc,
  addDoc,
  setDoc,
  deleteDoc,
  serverTimestamp,
  DocumentData,
  Timestamp,
  query,
  where,
  arrayUnion,
  arrayRemove,
  collectionSnapshots,
  CollectionReference,
  DocumentReference,
  getDocs,
  documentId,
} from '@angular/fire/firestore';
import { Observable, of, from, EMPTY } from 'rxjs';
import { switchMap, mergeMap, map, catchError } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Functions, httpsCallable } from '@angular/fire/functions';
import {
  Storage,
  ref,
  uploadBytesResumable,
  deleteObject,
  getDownloadURL,
  StorageReference,
  UploadTask,
  UploadMetadata,
} from '@angular/fire/storage';
import { UserState } from '../components/user/store/user.reducer';
import * as fromUserSelector from '../components/user/store/user.selectors';
import { v4 as uuidV4 } from 'uuid';
import { format, fromUnixTime } from 'date-fns';
import { ItemEvent } from '../features/suivi/components/item-event/itemEvent.model';
import { OperationTaskId } from '../components/operation-task/store/operation-task.model';
import { OperationNoteId } from '../components/operation-note/store/operation-note.model';

@Injectable({
  providedIn: 'any',
})
export class OperationNoteDocumentService {
  constructor(
    private db: Firestore,
    private fns: Functions,
    private userStore: Store<UserState>
  ) {}

  getAll(operationId: string, id: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !operationId || !id) of([]);
          const dbCollection = collection(
            this.db,
            `organisations/${user.organisation.id}/operations/${operationId}/notes/${id}/subtitles`
          );

          return from(collectionSnapshots(dbCollection)).pipe(
            map((changes) => {
              return changes.map((snap) => {
                const data = snap.data();
                const id = snap.id;
                return { id, ...data };
              });
            })
          );
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  addOne(
    operation: OperationId,
    noteId: string,
    documents: any[]
  ): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !operation || !noteId || !documents.length) {
            return EMPTY;
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/operations/${operation.id}/notes/${noteId}/documents`
            );

            const document: any = {
              ...documents,
              auteur: user,
              acces: {
                [`${user.id}`]: true,
              },
              organisation: user.organisation,
              auths: {
                [`${user.id}`]: true,
              },
              participants: [],
              dateUpdate: serverTimestamp(),
            };

            return from(
              addDoc(dbCollection, document)
                .then((docRef) => {
                  if (!docRef.id) {
                    const succss: string = `Note n\'a pas été ajoutée ! Veuillez rééssayer`;
                    return of(succss);
                  }

                  const succss: string = `Note ajoutée`;
                  return of(succss);
                })
                .catch((error) => of(error))
            ).pipe(mergeMap((res) => res));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  updateOne(
    noteDocument: Partial<OperationTaskId | any>,
    note: OperationNoteId,
    type?: string
  ): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !noteDocument) {
            return EMPTY;
          } else {
            const dbDocument = doc(
              this.db,
              `organisations/${user.organisation.id}/operations/${note.operation.id}/notes/${note.id}/documents/${noteDocument.id}`
            );

            return from(
              setDoc(
                dbDocument,
                {
                  ...noteDocument,
                  userUpdate: user,
                  dateUpdate: serverTimestamp(),
                },
                { merge: true }
              )
                .then(() => of(`Note modifiée`))
                .catch((error) => of(error))
            ).pipe(mergeMap((res) => res));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  deleteOne(operationId: string, noteId: string, id: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !id || !operationId || !noteId) {
            return EMPTY;
          } else {
            const dbDocument = doc(
              this.db,
              `organisations/${user.organisation.id}/operations/${operationId}/notes/${noteId}/documents/${id}`
            );

            return from(
              deleteDoc(dbDocument)
                .then(() => of(`Document supprimé`))
                .catch((error) => of(error))
            ).pipe(mergeMap((res) => res));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  deleteAll(operationId: string, ids: string[]): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) {
            return EMPTY;
          } else {
            //CALL HTTP

            return of(null);
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }
}
