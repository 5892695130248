import { createAction, props } from '@ngrx/store';
import { DocumentId } from 'src/app/models/document.model';

export const loadDocumentFilters = createAction(
  '[Document/COMPONENT] Load Documents Filters'
);
export const loadDocumentFiltersSuccess = createAction(
  '[Document/API] Load Documents Filters Success',
  props<{ filters: any[] }>()
);

export const loadDocumentFiltersFailure = createAction(
  '[Document/API] Load Documents Filters',
  props<{ error: any }>()
);

export const exportDocumentFilters = createAction(
  '[Document/COMPONENT] Export Documents ',
  props<{ document: DocumentId }>()
);
export const exportDocumentFiltersSuccess = createAction(
  '[Document/API] Export Documents Success',
  props<{ success: string }>()
);

export const exportDocumentFiltersFailure = createAction(
  '[Document/API] Export Documents Failure',
  props<{ error: any }>()
);
