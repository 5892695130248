import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';

@Component({
  selector: 'app-layout-list-header',
  templateUrl: './layout-list-header.component.html',
  styleUrls: ['./layout-list-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutListHeaderComponent implements OnInit, OnChanges {
  subscribe = new Subject();

  @Input() isDelete: boolean = false;
  @Input() isDeleteAll: boolean = false;
  @Input() isExportPDF: boolean = false;
  @Input() isExportWORD: boolean = false;
  @Input() isExportEXCEL: boolean = false;
  @Input() isExportPPT: boolean = false;
  @Input() deleteType: string = '';
  @Input() deleteAllType: string = '';
  @Input() title: string = '';
  @Input() icon: string = '';

  @Input() placeholder: string = 'Recherche...';
  @Input() cleaner: boolean = false;
  @Input() loading: boolean = false;

  @Output() currentFilter = new EventEmitter<string>(false);
  @Output() clear = new EventEmitter<boolean>(false);
  @Output() actionSelected = new EventEmitter<any>(false);

  search = new FormControl('');
  searchForm = new FormGroup({
    search: this.search,
  });

  constructor() {}

  ngOnInit(): void {
    this.onSearch();
  }
  ngOnChanges(changes: SimpleChanges): void {}

  onSearch(): void {
    this.searchForm
      .get('search')
      ?.valueChanges.pipe(
        takeUntil(this.subscribe),
        distinctUntilChanged(),
        debounceTime(500)
      )
      .subscribe((value: any): void => {
        this.currentFilter.emit(value);
        if (!value) {
          this.cleaner = false;
        } else {
          this.cleaner = true;
        }
      });
  }

  onDropdownActions(action: any): void {
    this.actionSelected.emit(action);
  }

  clearSearchForm(): void {
    this.currentFilter.next('');
    this.searchForm.reset();
    this.cleaner = false;
    this.clear.emit(true);
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }
}
