import { Component, Input, OnInit } from '@angular/core';

export enum TagIndicatorType {
  SUCCESS = '',
  PROCESS = '',
  WARNING = '',
}

@Component({
  selector: 'app-tag-indicator',
  templateUrl: './tag-indicator.component.html',
  styleUrls: ['./tag-indicator.component.scss'],
})
export class TagIndicatorComponent implements OnInit {
  @Input() type: string = '';
  @Input() text: string = '';
  constructor() {}

  ngOnInit(): void {}
}
