import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'any',
})
export class QueryOperationTeamServiceGQL extends Query<any> {
  document = gql`
    query GetOperationTeam($getOperationTeamId: ID!) {
      getOperationTeam(id: $getOperationTeamId) {
        id
        isAdmin
        user {
          avatar
          fonction
          displayName
          id
          photo
          searchFields
          service {
            title
            id
          }
          setting {
            color
          }
          coordonnees {
            email
            phoneNumber
          }
        }
        canRead
        canWrite
        role
      }
    }
  `;
}
