import { ParticipantSmallId } from './../components/participant/store/participant.model';
import { Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  doc,
  docSnapshots,
  collectionChanges,
  getDoc,
  addDoc,
  setDoc,
  deleteDoc,
  serverTimestamp,
  DocumentData,
  Timestamp,
  query,
  where,
  arrayUnion,
  arrayRemove,
  collectionSnapshots,
  getDocs,
  documentId,
} from '@angular/fire/firestore';
import { Observable, of, from, EMPTY } from 'rxjs';
import { switchMap, mergeMap, map, catchError } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { UserState } from '../components/user/store/user.reducer';
import * as fromUserSelector from '../components/user/store/user.selectors';
import { ParticipantService } from './participant.service';
import { Apollo, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'any',
})
export class QueryParticipantsService {
  GET_FILTERS = gql`
    query Me {
      me {
        organisation {
          filtersContact {
            categorie_entreprise
            code_postal
            communes
            economie_sociale_solidaire
            engagement
            libelle_activite_principale
            metiers
            metiersDomaine
            metiersGrandDomaine
            nature_juridique
            origine
            statuts
            types
          }
        }
      }
    }
  `;

  constructor(
    private db: Firestore,
    private fns: Functions,
    private userStore: Store<UserState>,
    private participantService: ParticipantService,
    private apollo: Apollo
  ) {}

  //FILTERS
  getFilters(): Observable<any[] | any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (user) {
            const dbDocument = doc(
              this.db,
              `organisations/${user.organisation.id}/filters/participants`
            );

            return from(
              getDoc(dbDocument)
                .then((snap: DocumentData) => {
                  const data = snap.data() as any;
                  const id = snap.id;
                  return { id, ...data };
                })
                .catch((error) => error)
            ).pipe(mergeMap((res) => of(res)));
          } else {
            return of(null);
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  //QUERY
  queryParticipants(
    filter: any,
    type: string
  ): Observable<ParticipantSmallId[] | any> {
    switch (type) {
      case 'lastname':
        return this.queryLastName(filter);
        break;
      case 'firstname':
        return this.queryFirstName(filter);
        break;
      case 'sexe':
        return this.querySexe(filter);
        break;
      case 'commune':
        return this.queryCommune(filter);
        break;
      case 'favoris':
        return this.queryFavoris(filter);
        break;
      case 'codepostal':
        return this.queryPostalCode(filter);
        break;
      default:
        return this.participantService.getAll();
        break;
    }
  }

  queryLastName(value: string): Observable<ParticipantSmallId[] | any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (user) {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/participants`
            );
            const dbQuery = query(
              dbCollection,
              where(
                'metadata.arrayLastName',
                'array-contains',
                value.toUpperCase()
              )
            );

            return from(
              getDocs(dbQuery)
                .then((snap) => {
                  return snap.docs.map((doc: DocumentData) => {
                    const data = doc.data() as any;
                    const id = doc.id;
                    return { id, ...data };
                  });
                })
                .catch((error) => error)
            ).pipe(mergeMap((res) => of(res)));
          } else {
            return of(null);
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  queryFirstName(value: string): Observable<ParticipantSmallId[] | any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (user) {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/participants`
            );
            const dbQuery = query(
              dbCollection,
              where(
                'metadata.arrayFirstName',
                'array-contains',
                value.toUpperCase()
              )
            );

            return from(
              getDocs(dbQuery)
                .then((snap) => {
                  return snap.docs.map((doc: DocumentData) => {
                    const data = doc.data() as any;
                    const id = doc.id;
                    return { id, ...data };
                  });
                })
                .catch((error) => error)
            ).pipe(mergeMap((res) => of(res)));
          } else {
            return of(null);
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  querySexe(filter: string): Observable<ParticipantSmallId[]> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (user) {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/contacts`
            );

            const dbQuery = query(dbCollection, where('sexe', '==', filter));

            return from(
              getDocs(dbQuery)
                .then((snap) => {
                  return snap.docs.map((doc: DocumentData) => {
                    const data = doc.data() as any;
                    const id = doc.id;
                    return { id, ...data };
                  });
                })
                .catch((error) => error)
            ).pipe(mergeMap((res) => of(res)));
          } else {
            return of(null);
          }
        })
      );
    } catch (error: any) {
      return of(error);
    }
  }

  queryCommune(filter: string): Observable<ParticipantSmallId[]> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (user) {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/contacts`
            );

            const dbQuery = query(
              dbCollection,
              where('adresse.adresse.properties.city', '==', filter)
            );

            return from(
              getDocs(dbQuery)
                .then((snap) => {
                  return snap.docs.map((doc: DocumentData) => {
                    const data = doc.data() as any;
                    const id = doc.id;
                    return { id, ...data };
                  });
                })
                .catch((error) => error)
            ).pipe(mergeMap((res) => of(res)));
          } else {
            return of(null);
          }
        })
      );
    } catch (error: any) {
      return of(error);
    }
  }

  queryPostalCode(filter: string): Observable<ParticipantSmallId[]> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (user) {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/contacts`
            );

            const dbQuery = query(
              dbCollection,
              where('adresse.adresse.properties.postcode', '==', filter)
            );

            return from(
              getDocs(dbQuery)
                .then((snap) => {
                  return snap.docs.map((doc: DocumentData) => {
                    const data = doc.data() as any;
                    const id = doc.id;
                    return { id, ...data };
                  });
                })
                .catch((error) => error)
            ).pipe(mergeMap((res) => of(res)));
          } else {
            return of(null);
          }
        })
      );
    } catch (error: any) {
      return of(error);
    }
  }

  queryFavoris(favoris: any[]): Observable<ParticipantSmallId[] | any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (user) {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/participants`
            );
            const ids = favoris.map((item) => item.id);
            const dbQuery = query(dbCollection, where(documentId(), 'in', ids));

            return from(
              getDocs(dbQuery)
                .then((snap) => {
                  return snap.docs.map((doc: DocumentData) => {
                    const data = doc.data() as any;
                    const id = doc.id;
                    return { id, ...data };
                  });
                })
                .catch((error) => error)
            ).pipe(mergeMap((res) => of(res)));
          } else {
            return of(null);
          }
        })
      );
    } catch (err) {
      return of(err);
    }
  }
}
