import { Action, ActionSmallId } from './../../store/action.model';
import { takeUntil } from 'rxjs/operators';
import { OperationId } from './../../../operation/store/operation.model';
import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { of, Observable, Subject, BehaviorSubject } from 'rxjs';
import { ActionId } from '../../store/action.model';
import { ActionState } from './../../store/action.reducer';
import * as fromActionAction from './../../store/action.actions';
import * as fromActionSelector from './../../store/action.selectors';
import { OperationState } from 'src/app/components/operation/store/operation.reducer';
import * as fromOperationSelector from 'src/app/components/operation/store/operation.selectors';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ActionFormComponent } from '../action-form/action-form.component';

@Component({
  selector: 'app-action-list',
  templateUrl: './action-list.component.html',
  styleUrls: ['./action-list.component.scss'],
})
export class ActionListComponent implements OnInit, OnDestroy {
  operation$: Observable<OperationId | any> = of(null);
  actions$: Observable<ActionId | any> = of(null);
  currentAction$: Observable<ActionId | any> = of(null);

  subscribe = new Subject();
  view$ = new BehaviorSubject<string>('list');
  loading$: Observable<boolean> = of(false);
  title: string = 'Nouvelle action';
  titleClauses: string = "Nouveau corps d'état";

  newCardTitle: string = 'Nouvelle action';
  newCardTitleClauses: string = "Nouveau corps d'état";

  newCardDescription: string = "Ajouter une action, un corps d'état";
  noModuleTitle: string = 'Aucun action ajoutée';
  newCardDefinition: string =
    "Une action est un fragment d'un module. Une action peut être une session, une intervention entreprise, un marché, un coaching ou autre dans un module.";

  newCardDefinitionClauses: string =
    "Partie d'une construction formant une unité ou présentant une certaine autonomie";

  @ViewChild('actionFormTitle', { static: false })
  actionFormTitle: TemplateRef<any> | any;
  modalWidth: number = 850;

  @Output() select = new EventEmitter<any>(false);

  constructor(
    private actionStore: Store<ActionState>,
    private operationStore: Store<OperationState>,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.getOperation();
    this.getActions();
    this.getAction();
    this.loadActions();
    this.loadActionTypes();
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getOperation(): void {
    this.operation$ = this.operationStore.select(
      fromOperationSelector.operation
    );
  }
  getActions(): void {
    this.actions$ = this.actionStore.select(fromActionSelector.actions);
  }

  getAction(): void {
    this.currentAction$ = this.actionStore.select(fromActionSelector.action);
  }

  loadActionTypes(): void {
    this.actionStore.dispatch(fromActionAction.loadActionTypes());
  }

  loadActions(): void {
    this.operation$.pipe(takeUntil(this.subscribe)).subscribe((operation) => {
      if (!operation) {
        return;
      } else {
        this.actionStore.dispatch(
          fromActionAction.loadActions({ operationId: operation.id })
        );
      }
    });
  }

  onSelect(action: ActionSmallId): void {
    if (!action) return;
    this.actionStore.dispatch(
      fromActionAction.loadAction({
        operationId: action.module.operation.id,
        actionId: action.id,
      })
    );
    this.view$.next('details');
  }

  onNew(operation: OperationId): void {
    if (!operation) return;

    const modal = this.modal.create({
      nzContent: ActionFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.actionFormTitle,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });
    const instance = modal.getContentComponent();
    instance.cancel.subscribe(
      (event: { operation: OperationId; action: Action }): any => {
        if (!event) {
          return null;
        } else {
          modal.close();
        }
      }
    );
    instance.add.subscribe((event: any): any => {
      if (!event) {
        return null;
      } else {
        this.onAdd(event);
      }
    });
    instance.operation$ = this.operation$;
  }

  onAdd(event: { operation: OperationId; action: Action }): void {
    this.actionStore.dispatch(
      fromActionAction.addAction({
        operationId: event.operation.id,
        action: event.action,
      })
    );
  }

  onDelete(operation: OperationId, action: any): void {
    if (!operation || !action) return;
    this.actionStore.dispatch(
      fromActionAction.deleteAction({
        operationId: operation.id,
        id: action.id,
      })
    );
  }

  onBack(): void {
    this.view$.next('list');
  }

  onCancel(): void {
    return;
  }
}
