import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Update } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { Timestamp } from 'firebase/firestore';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { fade } from 'src/app/app-animation';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';
import {
  OperationNoteSujet,
  OperationNoteSujetId,
} from '../../store/operation-note-sujet.model';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-operation-note-sujet-item',
  templateUrl: './operation-note-sujet-item.component.html',
  styleUrls: ['./operation-note-sujet-item.component.scss'],
  animations: [fade],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationNoteSujetItemComponent
  implements OnInit, OnChanges, OnDestroy
{
  user$: Observable<any> = of(null);
  subscribe = new Subject();
  tooltipDelayEnter: number = 0.5;
  isUpdate$ = new BehaviorSubject<boolean>(false);
  isComment$ = new BehaviorSubject<boolean>(false);
  default: string = '#bfbfbf';

  currentSujetUpdate$ = new BehaviorSubject<OperationNoteSujetId | any>(null);
  currentSujetCommentUpdate$ = new BehaviorSubject<any>(null);

  @Input() sujet: OperationNoteSujetId | any;
  @Output() delete = new EventEmitter<OperationNoteSujetId | any>(false);
  @Output() update = new EventEmitter<OperationNoteSujetId | any>(false);

  //SUJET FORM
  sujetForm: UntypedFormGroup = this.fb.group({});
  auteur: UntypedFormControl = new UntypedFormControl('');
  userUpdate: UntypedFormControl = new UntypedFormControl('');
  subtitle: UntypedFormControl = new UntypedFormControl('');
  noteId: UntypedFormControl = new UntypedFormControl('');
  title: UntypedFormControl = new UntypedFormControl('');
  text: UntypedFormControl = new UntypedFormControl('');
  textHTML: UntypedFormControl = new UntypedFormControl('');
  actions: UntypedFormControl = new UntypedFormControl();
  documents: UntypedFormControl = new UntypedFormControl();
  dateStart: UntypedFormControl = new UntypedFormControl('');
  dateUpdate: UntypedFormControl = new UntypedFormControl('');
  children: UntypedFormControl = new UntypedFormControl([]);
  isWarning: UntypedFormControl = new UntypedFormControl(false);

  //COMMENT SUJET FROM
  sujetCommentForm: UntypedFormGroup = this.fb.group({});
  commentText: UntypedFormControl = new UntypedFormControl('');
  commentTextHTML: UntypedFormControl = new UntypedFormControl('');
  commentDateStart: UntypedFormControl = new UntypedFormControl('');
  commentDateUpdate: UntypedFormControl = new UntypedFormControl('');
  commentAuteur: UntypedFormControl = new UntypedFormControl('');
  commentWarning: UntypedFormControl = new UntypedFormControl(false);

  constructor(private fb: UntypedFormBuilder, private userStore: Store<UserState>) {}

  ngOnInit(): void {
    this.getUser();
  }
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  //USER
  getUser(): void {
    this.user$ = this.userStore.select(fromUserSelector.user);
  }

  //DONE
  onDone(sujet: OperationNoteSujetId): void {
    if (!sujet) return;
    const nextSujet: OperationNoteSujetId = {
      ...sujet,
      isDone: !sujet.isDone,
    };

    this.update.next(nextSujet);
  }

  //ALERT
  onAlert(sujet: OperationNoteSujetId): void {
    if (!sujet) return;
    const nextSujet: OperationNoteSujetId = {
      ...sujet,
      isWarning: !sujet.isWarning,
    };

    this.update.next(nextSujet);
  }

  //SUJET
  formSujet(sujet: OperationNoteSujetId): void {
    this.formSujetInit();
    this.formSujetCreate();
    this.formSujetSet(sujet);
  }

  formSujetInit(): void {
    this.auteur = new UntypedFormControl('');
    this.userUpdate = new UntypedFormControl('');
    this.subtitle = new UntypedFormControl('');
    this.noteId = new UntypedFormControl('');
    this.title = new UntypedFormControl('');
    this.text = new UntypedFormControl('', Validators.required);
    this.textHTML = new UntypedFormControl('', Validators.required);
    this.actions = new UntypedFormControl();
    this.documents = new UntypedFormControl();
    this.dateStart = new UntypedFormControl('');
    this.dateUpdate = new UntypedFormControl('');
    this.children = new UntypedFormControl('');
    this.isWarning = new UntypedFormControl(false);
  }
  formSujetCreate(): void {
    this.sujetForm = this.fb.group({
      auteur: this.auteur,
      userUpdate: this.userUpdate,
      subtitle: this.subtitle,
      note: {
        id: this.noteId,
      },
      title: this.title,
      text: this.text,
      textHTML: this.textHTML,
      actions: this.actions,
      documents: this.documents,
      dateStart: this.dateStart,
      dateUpdate: this.dateUpdate,
      children: this.children,
      isWarning: this.isWarning,
    });
  }

  formSujetSet(sujet: OperationNoteSujetId): void {
    this.sujetForm.patchValue({ ...sujet });
  }

  onUpdate(sujet: OperationNoteSujetId): void {
    if (!sujet) return;
    this.formSujet(sujet);
    this.currentSujetUpdate$.next(sujet.textHTML);
    this.isUpdate$.next(true);
  }

  onCancelUpdate(): void {
    this.isUpdate$.next(false);
    this.currentSujetUpdate$.next('');
  }

  onUpdateSujetText(event: any): void {
    if (!event) return;
    this.sujetForm.patchValue({
      textHTML: event.textHTML,
      text: event.textValue,
    });
  }

  onUpdateValidation(sujet: OperationNoteSujetId): void {
    if (!sujet || !this.sujetForm.valid) {
      this.onCancelUpdate();
      return;
    }

    const sujetFormValue: OperationNoteSujet = this.sujetForm.value;
    const nextSujet: OperationNoteSujetId = { ...sujet, ...sujetFormValue };

    this.update.next(nextSujet);
    this.onCancelUpdate();
  }
  onCommentChange(sujet: OperationNoteSujetId): void {
    if (!sujet) return;

    this.update.next(sujet);
  }

  onDelete(sujet: OperationNoteSujetId): void {
    if (!sujet) return;
    this.delete.next(sujet);
  }

  onCancel(): void {
    return;
  }

  //COMMENT
  formSujetComment(sujet: OperationNoteSujetId): void {
    this.formSujetCommentInit();
    this.formSujetCommentCreate();
    this.formSujetCommentSet();
  }

  formSujetCommentInit(): void {
    this.commentText = new UntypedFormControl('', Validators.required);
    this.commentTextHTML = new UntypedFormControl('', Validators.required);
    this.commentDateStart = new UntypedFormControl(
      Timestamp.now(),
      Validators.required
    );
    this.commentDateUpdate = new UntypedFormControl(
      Timestamp.now(),
      Validators.required
    );
    this.commentAuteur = new UntypedFormControl('');
    this.commentWarning = new UntypedFormControl(false, Validators.required);
  }
  formSujetCommentCreate(): void {
    this.sujetCommentForm = this.fb.group({
      text: this.commentText,
      textHTML: this.commentTextHTML,
      dateStart: this.commentDateStart,
      dateUpdate: this.commentDateUpdate,
      auteur: this.commentAuteur,
      isWarning: this.commentWarning,
    });
  }
  formSujetCommentSet(): void {
    this.sujetCommentForm = this.fb.group({
      text: this.commentText,
      textHTML: this.commentTextHTML,
      dateStart: this.commentDateStart,
      dateUpdate: this.commentDateUpdate,
      auteur: this.commentAuteur,
      isWarning: this.commentWarning,
    });
  }

  onCancelComment(): void {
    this.isComment$.next(false);
    this.sujetCommentForm.reset();
  }
  onTextCommentChanges(event: any): void {
    if (!event) return;
    this.sujetCommentForm.patchValue({
      textHTML: event.textHTML,
      text: event.textValue,
    });
  }
  onValidateNewComment(user: any, sujet: OperationNoteSujetId): void {
    if (!user || !sujet || !this.sujetCommentForm.valid) return;
    const { id, displayName, avatar } = user;
    const auteur: any = { id, displayName, avatar };
    const commentValue = this.sujetCommentForm.value;
    commentValue.auteur = auteur;
    commentValue.id = uuidv4();
    const children: any[] = [...sujet?.children, commentValue];

    const nextSujet: OperationNoteSujetId = { ...sujet, children: children };

    this.update.next(nextSujet);
    this.onCancelComment();
  }
  onDeleteComment(user: any, sujet: OperationNoteSujetId, comment: any): void {
    if (!user || !sujet || !comment) return;
    const children: any[] = sujet.children.filter((el) => el.id !== comment.id);

    const nextSujet: Update<OperationNoteSujetId> = {
      id: sujet.id,
      changes: { ...sujet, children: children },
    };

    this.update.next(nextSujet);
    this.onCancelComment();
  }
  onComment(sujet: OperationNoteSujetId): void {
    if (!sujet) return;
    this.isComment$.next(true);
    this.formSujetComment(sujet);
  }
}
