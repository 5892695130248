<i nz-icon nzType="loading" *ngIf="!(filter$ | async)"></i>

<nz-select
  *ngIf="filter$ | async as filter"
  nzShowSearch
  nzBorderless
  nzServerSearch
  [nzPlaceHolder]="placeHolder"
  [(ngModel)]="inputModel"
  [nzShowArrow]="false"
  (nzOnSearch)="onSeach($event, filter)"
  (ngModelChange)="onSelect($event)"
  [nzDisabled]="!(actions$ | async)"
  [nzDropdownStyle]="{ height: '200px' }"
>
  <nz-option
    *ngFor="let action of actions$ | async"
    [nzLabel]="action.title"
    [nzValue]="action"
    nzCustomContent
  >
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="4">
        <mat-icon class="matIcon">dashboard</mat-icon>
      </nz-col>
      <nz-col nzSpan="16">
        <p nz-typography>{{ action?.title | uppercase }}</p>
      </nz-col>
      <nz-col nzSpan="4">
        <nz-tag nzColor="success" *ngIf="!action?.isFinished">En cours</nz-tag>
        <nz-tag nzColor="green" *ngIf="action?.isFinished">Terminé</nz-tag>
      </nz-col>
    </nz-row>
  </nz-option>
</nz-select>
