import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Mission } from './mission.model';

export const loadMissions = createAction(
  '[Mission/API] Load Missions',
  props<{ missions: Mission[] }>()
);

export const exportMissionPDF = createAction(
  '[Mission/API] Export Mission PDF',
  props<{ mission: any }>()
);
export const exportMissionPDFSuccess = createAction(
  '[Mission/API] Export Mission PDF Success',
  props<{ success: string }>()
);
export const exportMissionPDFFailure = createAction(
  '[Mission/API] Export Mission PDF Failure',
  props<{ error: any }>()
);

export const clearMissions = createAction('[Mission/API] Clear Missions');
