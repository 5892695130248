import { Pipe, PipeTransform } from '@angular/core';
import memo from 'memo-decorator';

@Pipe({
  name: 'displayName',
})
export class DisplayNamePipe implements PipeTransform {
  displayName: string = '';

  @memo()
  transform(value: any, civility?: boolean): string {
    if (!value) {
      return '';
    } else {
      const civilite = civility && value?.civilite ? `${value.civilite}.` : '';
      const lastName = value?.lastName ? value.lastName : '';
      const firstName = value?.firstName ? value.firstName : '';

      this.displayName = `${civilite} ${lastName} ${firstName}`;

      return this.displayName;
    }
  }
}
