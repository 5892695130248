import { OperationService } from './operation.service';
import { Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  doc,
  docSnapshots,
  collectionChanges,
  getDoc,
  addDoc,
  setDoc,
  deleteDoc,
  serverTimestamp,
  DocumentData,
  Timestamp,
  query,
  where,
  arrayUnion,
  arrayRemove,
  collectionSnapshots,
  getDocs,
  documentId,
} from '@angular/fire/firestore';
import { Observable, of, from, EMPTY } from 'rxjs';
import { switchMap, mergeMap, map, catchError } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { UserState } from '../components/user/store/user.reducer';
import * as fromUserSelector from '../components/user/store/user.selectors';
import { OperationSmallId } from '../components/operation/store/operation.model';

@Injectable({
  providedIn: 'any',
})
export class QueryOperationsService {
  constructor(
    private db: Firestore,
    private fns: Functions,
    private userStore: Store<UserState>,
    private operationService: OperationService
  ) {}

  //FILTERS
  getFilters(): Observable<any[] | any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (user) {
            const dbDocument = doc(
              this.db,
              `organisations/${user.organisation.id}/filters/operations`
            );

            return from(
              getDoc(dbDocument)
                .then((snap: DocumentData) => {
                  const data = snap.data() as any;
                  const id = snap.id;
                  return { id, ...data };
                })
                .catch((error) => error)
            ).pipe(mergeMap((res) => of(res)));
          } else {
            return of(null);
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  //QUERY
  queryOperations(filter: {
    event: string;
    type: string;
  }): Observable<OperationSmallId[] | any> {
    switch (filter.type) {
      case 'title':
        return this.queryTitle(filter.event);
        break;
      case 'financeur':
        return this.queryFinanceur(filter.event);
        break;
      case 'year':
        return this.queryYear(filter.event);
        break;
      case 'letter':
        return this.queryLetter(filter.event);
        break;
      case 'service':
        return this.queryService(filter.event);
        break;
      case 'type':
        return this.queryType(filter.event);
        break;
      case 'complete':
        return this.queryCompleted(filter.event);
        break;
      case 'status':
        return this.queryStatus(filter.event);
        break;
      default:
        return this.operationService.getAll();
        break;
    }
  }

  queryTitle(value: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) {
            return of([]);
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/operations`
            );

            if (user?.claims?.admin || user?.claims?.dev) {
              const queryCollection = query(
                dbCollection,
                where(
                  'metadata.arrayDenomination',
                  'array-contains',
                  value.toUpperCase()
                )
              );

              return collectionSnapshots(queryCollection).pipe(
                map((snap) => {
                  return snap.map((doc: DocumentData) => {
                    const data = doc.data() as any;
                    const id = doc.id;
                    return { id, ...data };
                  });
                }),
                catchError((error) => EMPTY)
              );
            } else {
              const queryCollection = query(
                dbCollection,
                where(
                  'metadata.arrayDenomination',
                  'array-contains',
                  value.toUpperCase()
                )
              );

              return collectionSnapshots(queryCollection).pipe(
                map((snap) => {
                  return snap.map((doc: DocumentData) => {
                    const data = doc.data() as any;
                    const id = doc.id;
                    return { id, ...data };
                  });
                }),
                catchError((error) => EMPTY)
              );
            }
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  queryFinanceur(value: string): Observable<OperationSmallId[] | any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (user) {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/operations`
            );
            const dbQuery = query(
              dbCollection,
              where('metadata.arrayFinanceur', 'array-contains', value)
            );

            return from(
              getDocs(dbQuery)
                .then((snap) => {
                  return snap.docs.map((doc: DocumentData) => {
                    const data = doc.data() as any;
                    const id = doc.id;
                    return { id, ...data };
                  });
                })
                .catch((error) => error)
            ).pipe(mergeMap((res) => of(res)));
          } else {
            return of(null);
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  queryYear(value: string): Observable<OperationSmallId[] | any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (user) {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/operations`
            );
            const dbQuery = query(
              dbCollection,
              where('metadata.startYear', '==', value)
            );

            return from(
              getDocs(dbQuery)
                .then((snap) => {
                  return snap.docs.map((doc: DocumentData) => {
                    const data = doc.data() as any;
                    const id = doc.id;
                    return { id, ...data };
                  });
                })
                .catch((error) => error)
            ).pipe(mergeMap((res) => of(res)));
          } else {
            return of(null);
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  queryLetter(value: string): Observable<OperationSmallId[] | any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (user) {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/operations`
            );
            const dbQuery = query(
              dbCollection,
              where('metadata.letterDenomination', '==', value)
            );

            return from(
              getDocs(dbQuery)
                .then((snap) => {
                  return snap.docs.map((doc: DocumentData) => {
                    const data = doc.data() as any;
                    const id = doc.id;
                    return { id, ...data };
                  });
                })
                .catch((error) => error)
            ).pipe(mergeMap((res) => of(res)));
          } else {
            return of(null);
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  queryService(value: string): Observable<OperationSmallId[] | any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (user) {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/operations`
            );
            const dbQuery = query(
              dbCollection,
              where('servicesIds', 'array-contains', value)
            );

            return from(
              getDocs(dbQuery)
                .then((snap) => {
                  return snap.docs.map((doc: DocumentData) => {
                    const data = doc.data() as any;
                    const id = doc.id;
                    return { id, ...data };
                  });
                })
                .catch((error) => error)
            ).pipe(mergeMap((res) => of(res)));
          } else {
            return of(null);
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  queryType(value: string): Observable<OperationSmallId[] | any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (user) {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/operations`
            );
            const dbQuery = query(dbCollection, where('type', '==', value));

            return from(
              getDocs(dbQuery)
                .then((snap) => {
                  return snap.docs.map((doc: DocumentData) => {
                    const data = doc.data() as any;
                    const id = doc.id;
                    return { id, ...data };
                  });
                })
                .catch((error) => error)
            ).pipe(mergeMap((res) => of(res)));
          } else {
            return of(null);
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  queryCompleted(value: string): Observable<OperationSmallId[] | any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (user) {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/operations`
            );
            const dbQuery = query(
              dbCollection,
              where('completed', '==', value)
            );

            return from(
              getDocs(dbQuery)
                .then((snap) => {
                  return snap.docs.map((doc: DocumentData) => {
                    const data = doc.data() as any;
                    const id = doc.id;
                    return { id, ...data };
                  });
                })
                .catch((error) => error)
            ).pipe(mergeMap((res) => of(res)));
          } else {
            return of(null);
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  queryStatus(value: string): Observable<OperationSmallId[] | any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (user) {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/operations`
            );
            const dbQuery = query(dbCollection, where('status', '==', value));

            return from(
              getDocs(dbQuery)
                .then((snap) => {
                  return snap.docs.map((doc: DocumentData) => {
                    const data = doc.data() as any;
                    const id = doc.id;
                    return { id, ...data };
                  });
                })
                .catch((error) => error)
            ).pipe(mergeMap((res) => of(res)));
          } else {
            return of(null);
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }
}
