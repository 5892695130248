import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  OnDestroy,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageId } from '../../store/message.model';
import { messageState } from '../../store/message.reducer';
import * as fromMessageSelector from '../../store/message.selectors';
import * as fromMessageAction from '../../store/message.actions';

import { BehaviorSubject, Subject, of, Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  takeUntil,
  startWith,
  delay,
  tap,
} from 'rxjs/operators';

@Component({
  selector: 'app-messages-list',
  templateUrl: './messages-list.component.html',
  styleUrls: ['./messages-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessagesListComponent implements OnInit, OnDestroy {
  messages$: Observable<MessageId[] | any> = of([]);
  subscribe = new Subject();
  searchInput: any = null;
  searchInput$ = new BehaviorSubject<any>(null);
  loading$: Observable<boolean> = of(false);

  @Input() fullscreen: boolean = false;

  constructor(private messageStore: Store<messageState>) {}

  ngOnInit(): void {
    this.get_MESSAGES();
    this.getLoading__MESSAGE();
    this.onSearch();
  }

  get_MESSAGES(): void {
    this.messages$ = this.messageStore.select(
      fromMessageSelector.selectMessages
    );
  }

  getLoading__MESSAGE(): void {
    this.loading$
      .pipe(
        startWith(false),
        delay(0),
        tap(() => this.messageStore.select(fromMessageSelector.selectLoader))
      )
      .subscribe();
  }

  onSearch(): void {
    this.searchInput$
      .pipe(distinctUntilChanged(), takeUntil(this.subscribe))
      .subscribe((values): any => {
        if (!values?.text) {
          this.searchInput = null;
          this.get_MESSAGES();
          return null;
        } else {
          const { text, messages } = values;

          if (!text || !messages?.length) {
            this.searchInput = null;
            this.get_MESSAGES();
            return null;
          } else {
            const findMessages = messages.filter((message: MessageId) =>
              message.subtitle.toLowerCase().includes(text.toLowerCase())
            );

            if (!findMessages?.length) {
              this.messages$ = of([]);
              return null;
            } else {
              this.messages$ = of(findMessages);
            }
          }
        }
      });
  }

  loadMessages(): void {
    this.messageStore.dispatch(fromMessageAction.loadMessages());
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }
}
