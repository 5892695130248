import { Observable, of } from 'rxjs';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { OperationState } from 'src/app/components/operation/store/operation.reducer';
import * as fromOperationAction from 'src/app/components/operation/store/operation.actions';
import * as fromOperationSelector from 'src/app/components/operation/store/operation.selectors';

@Component({
  selector: 'app-operation-entites-item',
  templateUrl: './operation-entites-item.component.html',
  styleUrls: ['./operation-entites-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationEntitesItemComponent implements OnInit, OnChanges {
  @Input() entite: any;
  @Output() back = new EventEmitter<string>(false);
  constructor(private operationStore: Store<OperationState>) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}

  onBack(): void {
    this.back.emit('entites');
  }

  getEntite(): void {}
}
