<ng-container *ngIf="operation$ | async as operation">
  <form
    nz-form
    [formGroup]="noteForm"
    nzLayout="vertical"
    (ngSubmit)="onAdd(operation)"
  >
    <nz-card [nzTitle]="formNoteTitle">
      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col nzSpan="24">
          <nz-form-item nzRequired="true">
            <nz-form-label nzRequired="true" nzFor="titre">Titre</nz-form-label>
            <nz-form-control required nzErrorTip="Aucun titre renseigné">
              <input
                nzBorderless
                nz-input
                formControlName="title"
                nzPlaceHolder="Titre..."
                placeHolder="Titre..."
                id="titre"
              />
            </nz-form-control>
          </nz-form-item>
        </nz-col>
      </nz-row>
    </nz-card>
    <ng-template #formNoteTitle>
      <app-header-title [icon]="'title'" [subtitle]="true"></app-header-title>
    </ng-template>

    <nz-space nzSize="small" nzDirection="vertical"></nz-space>

    <nz-card [nzTitle]="formNoteOptionTitle">
      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col nzSpan="12">
          <nz-form-item nzRequired="true">
            <nz-form-label [nzSpan]="24" nzFor="dateStart" nzRequired="true"
              >Date</nz-form-label
            >
            <nz-form-control nzErrorTip="Aucune date drenseignée">
              <nz-date-picker
                nzBorderless
                nzPlaceHolder="Date..."
                formControlName="dateStart"
                id="dateStart"
                nzFormat="dd/MM/yyyy HH:mm"
                [nzShowTime]="{ nzFormat: 'hh:mm' }"
                nzShowToday="true"
                required
              >
              </nz-date-picker>
            </nz-form-control>
          </nz-form-item>
        </nz-col>
        <nz-col nzSpan="12">
          <nz-form-item nzRequired="false">
            <nz-form-label nzRequired="false" nzFor="label"
              >Label</nz-form-label
            >
            <nz-form-control>
              <input
                nz-input
                nzBorderless
                maxlength="28"
                formControlName="label"
                nzPlaceHolder="Label..."
                placeHolder="Label..."
                id="label"
              />
            </nz-form-control>
          </nz-form-item>
        </nz-col>
      </nz-row>

      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col nzSpan="24">
          <app-communes-france
            (adresse)="onAddAdresse($event)"
            (complementIn)="onAddAdresseComplement($event)"
            [updateItem]="adresseItem$"
            [borderless]="true"
            [isComplement]="false"
          ></app-communes-france>
        </nz-col>
      </nz-row>
    </nz-card>

    <ng-template #formNoteOptionTitle>
      <app-header-title
        [icon]="'settings'"
        [subtitle]="true"
      ></app-header-title>
    </ng-template>
  </form>
  <nz-divider nzType="vertical"></nz-divider>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="16"></nz-col>
    <nz-col nzSpan="4">
      <button nz-button nzBlock nzType="text" (click)="onCancel()">
        Annuler
      </button>
    </nz-col>

    <nz-col nzSpan="4">
      <button
        nz-button
        nzBlock
        nzType="primary"
        *ngIf="isAdd"
        [disabled]="noteForm.invalid"
        (click)="onAdd(operation)"
      >
        Ajouter
      </button>
      <ng-container *ngIf="isUpdate">
        <button
          nz-button
          nzBlock
          nzType="primary"
          *ngIf="note$ | async as note"
          [disabled]="noteForm.invalid"
          (click)="onUpdate(operation, note)"
        >
          Modifier
        </button>
      </ng-container>
    </nz-col>
  </nz-row>
</ng-container>
