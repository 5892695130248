import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'errorType',
})
export class ErrorTypePipe implements PipeTransform {
  transform(value: any): string {
    if (!value) {
      return '';
    }

    if (value.message === 'logResponse.user is undefined') {
      const notExite: string =
        "Ce compte utilisateur n'existe pas ou est désactivé. Veuillez vous rapprocher de votre administrateur.";
      return notExite;
    }

    if (value.message === "Cannot read property 'emailVerified' of undefined") {
      const noEmail: string =
        'Votre adress e-mail et/ou mot de passe sont erronés. Veuillez réessayer ou vous rapprocher de votre administrateur.';
      return noEmail;
    }else{
      return ''
    }

    
  }
}
