<ng-container *ngIf="actions$ | async | size; else noAction">
  <div class="Operation_Dashboard_Container"></div>
</ng-container>

<ng-template #noData>
  <app-no-result
    [icon]="'query_stats'"
    [isButton]="false"
    [title]="''"
  ></app-no-result>
</ng-template>

<ng-template #noAction>
  <app-no-result
    [icon]="'query_stats'"
    [isButton]="false"
    [title]="'Aucun action'"
    [description]="'Aucune action'"
  ></app-no-result>
</ng-template>
