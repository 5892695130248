import { UntypedFormControl, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { EntiteId } from './../../../entite/store/entite.model';
import {
  MetierAppelation,
  MetiersDomaineSuggestion,
  MetiersSuggestion,
} from './../../store/metiers-suggestion.model';
import { Store } from '@ngrx/store';
import { SuggestionsNafState } from './../../store/metiers-suggestion.reducer';
import * as fromSuggestionsAction from './../../store/metiers-suggestion.actions';
import * as fromSuggestionsSelector from './../../store/metiers-suggestion.selectors';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { of, Observable } from 'rxjs';
import { groupBy } from 'lodash';

@Component({
  selector: 'app-metiers-naf-suggestions',
  templateUrl: './metiers-naf-suggestions.component.html',
  styleUrls: ['./metiers-naf-suggestions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetiersNafSuggestionsComponent implements OnInit, OnChanges {
  loading$: Observable<boolean> = of(false);
  total$: Observable<number | any> = of(0);
  suggestions$: Observable<MetiersSuggestion[] | any> = of(null);
  suggestion: MetiersSuggestion | any = null;
  appelations$: Observable<MetierAppelation[] | any> = of(null);
  appelation: MetierAppelation | any = null;
  appelationsSelected: MetierAppelation[] = [];
  filterForm: UntypedFormGroup = new UntypedFormGroup({});
  appelationSeaching: UntypedFormControl = new UntypedFormControl('');

  metier$: Observable<any> = of(null);
  metierModel: any = null;
  appelationModel: any = null;

  @Input() entite: EntiteId | any = null;
  @Input() type: string = '';

  @Output() confirm = new EventEmitter<any>(false);
  @Output() cancel = new EventEmitter<any>(false);

  constructor(
    private metiersSuggestionsStore: Store<SuggestionsNafState>,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.getSuggestions();
    this.getSuggestionsTotal();
    this.getSuggestionsLoading();
    this.getMetierDetails();
    this.getAppelations();
    this.onApplicationSearching();
    this.filterFormInit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
    this.loadSuggestion(
      changes?.entite.currentValue,
      changes?.type.currentValue
    );
  }

  loadSuggestion(entite: EntiteId, type: string): void {
    if (!entite) return;
    if (type === 'naf') {
      const naf = entite.etablissement?.activite_principale;
      this.metiersSuggestionsStore.dispatch(
        fromSuggestionsAction.loadMetiersSuggestions({ naf })
      );
    } else if (type === 'domaine') {
      const code = entite.custom.metiersDomaine.code;
      this.metiersSuggestionsStore.dispatch(
        fromSuggestionsAction.loadDomaineMetiersSuggestions({ code })
      );
    } else {
      return;
    }
  }

  getSuggestions(): void {
    this.suggestions$ = this.metiersSuggestionsStore.select(
      fromSuggestionsSelector.suggestions
    );
  }

  getSuggestionsLoading(): void {
    this.loading$ = this.metiersSuggestionsStore.select(
      fromSuggestionsSelector.loading
    );
  }

  getSuggestionsTotal(): void {
    this.total$ = this.metiersSuggestionsStore.select(
      fromSuggestionsSelector.total
    );
  }

  getAppelations(): void {
    this.appelations$ = this.metiersSuggestionsStore.select(
      fromSuggestionsSelector.appelations
    );
  }

  getMetierDetails(): void {
    this.metier$ = this.metiersSuggestionsStore.select(
      fromSuggestionsSelector.metier
    );
  }

  trackByFn(index: number, item: any) {
    return index; // or item.id
  }

  trackAppelationByFn(index: number, item: any) {
    return index; // or item.id
  }

  filterFormInit(): void {
    this.filterForm = this.fb.group({
      filter: this.appelationSeaching,
    });
  }

  onSelect(suggestion: any): void {
    const code: string = suggestion?.code_rome
      ? suggestion.code_rome
      : suggestion.code;

    this.suggestion = suggestion;

    this.metiersSuggestionsStore.dispatch(
      fromSuggestionsAction.loadMetierAppelations({ code })
    );
  }

  onApplicationSearching(): void {
    this.filterForm.valueChanges.subscribe((val) => {
      const { filter } = val;
      console.log(filter);
    });
  }

  onSelectAppelation(appelation: MetierAppelation): void {
    const isExist = this.appelationsSelected.find(
      (el) => el.code === appelation.code
    );

    if (isExist) return;

    this.appelationsSelected.push(appelation);
  }
  onRemoveAppelation(appelation: MetierAppelation): void {
    const nextSelection = this.appelationsSelected.filter(
      (el) => el.code !== appelation.code
    );
    this.appelationsSelected = nextSelection;
  }
  onRemoveAllAppelations(): void {
    this.appelationsSelected = [];
  }

  onClear(): void {
    this.onRemoveAllAppelations();
  }

  onConfirmMetiers(): void {
    if (!this.appelationsSelected?.length) return;

    const metiersMap = this.transforToMap(this.appelationsSelected);
    const metiers = this.appelationsSelected;
    this.confirm.emit({ metiersMap, metiers });
  }

  transforToMap(array: any[]): any {
    const reduce = array.reduce((acc, cur) => {
      acc[cur['libelle']] = cur;
      return acc;
    }, {});

    return reduce;
  }

  onCancel(): void {
    this.cancel.emit(true);
  }
}
