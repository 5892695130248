import {
  NzNotificationPlacement,
  NzNotificationService,
} from 'ng-zorro-antd/notification';
import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { ContactTasksService } from 'src/app/service/contact-tasks.service';
import { Store } from '@ngrx/store';
import * as fromContactTaskAction from './contact-task.actions';

import { catchError, map, mergeMap, of, tap } from 'rxjs';

const NOTIFICATION_PLACEMENT: NzNotificationPlacement = 'topRight';

@Injectable()
export class ContactTaskEffects {
  constructor(
    private actions$: Actions,
    private notification: NzNotificationService,
    private contactTaskService: ContactTasksService
  ) {}

  onErrorNotification(error: any, key: string): void {
    this.notification.error('Error', error.message, {
      nzDuration: 10000,
      nzAnimate: true,
      nzPlacement: NOTIFICATION_PLACEMENT,
      nzKey: key ? key : '',
    });
  }
}
