<form nz-form [formGroup]="disponibiliteForm">
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="12">
      <app-check-card
        [title]="'Disponibilité faible'"
        [isActive]="disponibiliteForm.value.disponibiliteFaibleVoireDifficile"
        [definition]="disponibiliteFaibleVoireDifficileTitle"
        [height]="'40px'"
        [fontSize]="'14px'"
        (select)="checkboxChanges($event, 'disponibiliteFaibleVoireDifficile')"
      ></app-check-card>
    </nz-col>
    <nz-col nzSpan="12">
      <app-check-card
        [title]="'Disponibilité à organiser'"
        [isActive]="disponibiliteForm.value.disponibiliteAorganiser"
        [definition]="disponibiliteAorganiserTitle"
        [height]="'40px'"
        [fontSize]="'14px'"
        (select)="checkboxChanges($event, 'disponibiliteAorganiser')"
      ></app-check-card>
    </nz-col>
  </nz-row>
  <nz-space nzDirection="vertical" nzSize="small"></nz-space>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="12">
      <app-check-card
        [title]="'Disponibilité avec contrainte'"
        [isActive]="disponibiliteForm.value.disponibiliteImmediatementAvec"
        [definition]="disponibiliteImmediatementAvecTitle"
        (select)="checkboxChanges($event, 'disponibiliteImmediatementAvec')"
        [height]="'40px'"
        [fontSize]="'14px'"
      ></app-check-card>
    </nz-col>
    <nz-col nzSpan="12">
      <app-check-card
        [title]="'Disponibilité sans contrainte'"
        [isActive]="disponibiliteForm.value.disponibiliteImmediatementSans"
        [definition]="disponibiliteImmediatementSansTitle"
        [height]="'40px'"
        [fontSize]="'14px'"
        (select)="checkboxChanges($event, 'disponibiliteImmediatementSans')"
      ></app-check-card>
    </nz-col>
  </nz-row>

  <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="24">
      <nz-form-item>
        <nz-form-label nzSpan="24">Précision</nz-form-label>
        <nz-form-control>
          <textarea
            nz-input
            formControlName="precision"
            [nzAutosize]="{ minRows: 2, maxRows: 2 }"
            nzBorderless
            placeholder="Précisez les raisons de l'indisponibilité...'"
          >
          </textarea>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>

  <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="24">
      <nz-form-item>
        <nz-form-control>
          <label nz-checkbox formControlName="isFrein">{{
            isFreinTitle
          }}</label>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>
</form>
