import { createFeatureSelector, createSelector } from '@ngrx/store';
import { flatten, groupBy } from 'lodash';

import {
  OperationNoteSujetState,
  operationNoteSujetsFeatureKey,
  selectAll,
} from './operation-note-sujet.reducer';

export const selectOpearationNoteSujetState =
  createFeatureSelector<OperationNoteSujetState>(operationNoteSujetsFeatureKey);

export const sujets = createSelector(selectOpearationNoteSujetState, selectAll);

export const sujet = createSelector(
  selectOpearationNoteSujetState,
  (state: OperationNoteSujetState) => state.sujet
);

export const sujetsByNote = createSelector(
  selectOpearationNoteSujetState,
  (state: OperationNoteSujetState) =>
    Object.values(groupBy(state.sujets, 'subtitle.id'))
);

export const subtitlesFromSujets = createSelector(
  selectOpearationNoteSujetState,
  (state: OperationNoteSujetState) =>
    state.sujets.map((sujet) => {
      const title: string = sujet.subtitle.title;
      const dateStart: any = sujet.subtitle?.dateStart;
    })
);
export const sujetsWarning = createSelector(
  selectOpearationNoteSujetState,
  (state: OperationNoteSujetState) => state.sujetsWarning
);

export const loading = createSelector(
  selectOpearationNoteSujetState,
  (state: OperationNoteSujetState) => state.loading
);

export const error = createSelector(
  selectOpearationNoteSujetState,
  (state: OperationNoteSujetState) => state.error
);
