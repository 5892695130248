import { Injectable } from '@angular/core';
import { ParticipantService } from './participant.service';
import { Update } from '@ngrx/entity';
import {
  Firestore,
  collection,
  doc,
  docSnapshots,
  collectionChanges,
  getDoc,
  addDoc,
  setDoc,
  deleteDoc,
  serverTimestamp,
  DocumentData,
  Timestamp,
  query,
  where,
  arrayUnion,
  arrayRemove,
  collectionSnapshots,
  CollectionReference,
  QueryDocumentSnapshot,
  DocumentChange,
  DocumentReference,
  getDocs,
  documentId,
} from '@angular/fire/firestore';
import { Observable, of, from, EMPTY } from 'rxjs';
import { switchMap, mergeMap, map, catchError } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { UserState } from '../components/user/store/user.reducer';
import * as fromUserSelector from '../components/user/store/user.selectors';
import { format, fromUnixTime, add } from 'date-fns';
import { ParticipantState } from './../components/participant/store/participant.reducer';
import * as fromParticipantAction from './../components/participant/store/participant.actions';
import * as fromParticipantSelector from './../components/participant/store/participant.selectors';

import {
  ParticipantId,
  ParticipantSmallId,
} from './../components/participant/store/participant.model';
import { OperationSmallId } from './../components/operation/store/operation.model';

@Injectable({
  providedIn: 'any',
})
export class ParticipantExperiencesService {
  constructor(
    private db: Firestore,
    private fns: Functions,
    private userStore: Store<UserState>,
    private participantStore: Store<ParticipantState>,
    private participantService: ParticipantService
  ) {}

  getAll(participantId: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !participantId) {
            return of([]);
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/participants/${participantId}/experiences`
            );
            const changes = collectionSnapshots(dbCollection).pipe(
              map((changes) => {
                return changes.map((snap) => {
                  const data = snap.data();
                  const id = snap.id;
                  return { id, ...data };
                });
              })
            );

            return changes;
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  getOne(participantId: string, id: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !participantId) {
            return of(null);
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/participants/${participantId}/experiences`
            );

            const documentRef = doc(dbCollection, id);
            return from(
              getDoc(documentRef)
                .then((snap) => {
                  const data = snap.data();
                  const id = snap.id;
                  return { id, ...data };
                })
                .catch((error) => error)
            ).pipe(mergeMap((res: any) => of(res)));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  addOne(participantId: string, experience: any): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !participantId || !experience) {
            return of(null);
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/participants/${participantId}/experiences`
            );

            const document = {
              ...experience,
              auteur: user,
              userUpdate: user,
              dateUpdate: serverTimestamp(),
            };

            return from(
              addDoc(dbCollection, document)
                .then((docRef) => {
                  if (docRef.id) {
                    return of(`Expérience ajoutée`);
                  } else {
                    return of(null);
                  }
                })
                .catch((error) => of(error))
            ).pipe(mergeMap((res) => res));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  updateOne(participantId: string, experience: Partial<any>): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !participantId) {
            return of(null);
          } else {
            const dbDocument = doc(
              this.db,
              `organisations/${user.organisation.id}/participants/${participantId}/experiences/${experience?.id}`
            );

            return from(
              setDoc(
                dbDocument,
                {
                  ...experience,
                  userUpdate: user,
                  dateUpdate: serverTimestamp(),
                },
                { merge: true }
              )
                .then(() => this.getOne(participantId, experience.id))
                .catch((error) => of(error))
            ).pipe(mergeMap((res) => res));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  deleteOne(participantId: string, id: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !participantId) {
            return of(null);
          } else {
            const dbDocument = doc(
              this.db,
              `organisations/${user.organisation.id}/participants/${participantId}/experiences/${id}`
            );

            return from(
              deleteDoc(dbDocument)
                .then(() => of(`Expérience supprimée`))
                .catch((error) => of(error))
            ).pipe(mergeMap((res) => res));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  deleteAll(participantId: string, ids: string[]): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) {
            return of(null);
          } else {
            //CALL HTTP

            return of(null);
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }
}
