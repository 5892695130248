<nz-row nzAlign="top" nzJustify="center" nzGutter="4">
  <nz-col nzSpan="15">
    <div class="content" *ngIf="!(editing$ | async)">
      <nz-card
        nzSize="small"
        [nzTitle]="risqueItemTitlte"
        *ngIf="editingListItemShow$ | async as editingListItemShow"
      >
        <ng-template #risqueItemTitlte>
          {{ editingListItemShow?.nom_raison_sociale }}
        </ng-template>

        <nz-row nzAlign="top" nzJustify="center" nzGutter="4">
          <nz-col nzSpan="12">{{ editingListItemShow?.activity }}</nz-col>
          <nz-col nzSpan="12">{{ editingListItemShow?.secteur }}</nz-col>
        </nz-row>
        <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
        <nz-row
          nzAlign="top"
          nzJustify="center"
          nzGutter="4"
          class="itemContent"
        >
          <nz-col nzSpan="24" class="itemContent">
            <p class="itemDescription"></p>
          </nz-col>
        </nz-row>
      </nz-card>
    </div>
    <!-- <app-organisation-entite-search
        *ngIf="editingListItem$ | async"
        (cancel)="editingListItem$.next(false)"
        (selected)="
          onUpdateListItem(
            $event,
            operation,
            cadrage,
            'Partenaires'
          )
        "
      ></app-organisation-entite-search> -->
  </nz-col>
  <nz-col nzSpan="1">
    <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
  </nz-col>
  <nz-col nzSpan="8">
    <nz-list [nzHeader]="partenariatHeader" nzBordered nzSize="small">
      <ng-template #partenariatHeader>
        <nz-row nzJustify="start" nzAlign="middle" nzGutter="4">
          <nz-col nzSpan="24">
            <nz-button-group nzSize="small">
              <button nz-button nzSize="small">
                <i nz-icon nzType="check-circle"></i>
                <span>Confimer</span>
              </button>
              <button
                nz-button
                nzSize="small"
                (click)="editingListItem$.next(true)"
              >
                <i nz-icon nzType="plus-circle"></i>
                <span>Nouveau</span>
              </button>
              <button
                nz-button
                nzSize="small"
                (click)="onDeleteAllList(cadrage)"
              >
                <i nz-icon nzType="delete"></i>
                <span>Supprimer</span>
              </button>
            </nz-button-group>
          </nz-col>
        </nz-row>
      </ng-template>
      <cdk-virtual-scroll-viewport
        appendOnly
        itemSize="75"
        class="list-viewport"
      >
        <ng-container>
          <nz-list-item *cdkVirtualFor="let item of cadrage?.partenariats">
            <nz-row nzAlign="top" nzJustify="center" nzGutter="4">
              <nz-col nzSpan="20">
                <span>{{ item?.nom_raison_sociale | uppercase }}</span>
                <nz-row nzAlign="top" nzGutter="2" nzJustify="start">
                  <!-- <nz-col nzSpan="12">
                <small>{{ item?.pilote }}</small>
              </nz-col>
              <nz-col nzSpan="12">
                <small>{{
                  item?.echeance | dateFr: 'small'
                }}</small>
              </nz-col> -->
                </nz-row>
              </nz-col>
              <nz-col nzSpan="2"
                ><i
                  nz-icon
                  nzType="eye"
                  class="icon"
                  (click)="editingListItemShow$.next(item)"
                ></i
              ></nz-col>
              <nz-col nzSpan="2"
                ><i
                  nz-icon
                  nzType="delete"
                  class="iconDelete"
                  (click)="onDeleteItem(item, cadrage)"
                ></i
              ></nz-col>
            </nz-row>
          </nz-list-item>
        </ng-container>
      </cdk-virtual-scroll-viewport>
    </nz-list>
  </nz-col>
</nz-row>
