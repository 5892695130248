import { OperationTaskFormComponent } from './../operation-task-form/operation-task-form.component';
import { OperationTaskState } from './../../store/operation-task.reducer';
import * as fromOperationTaskSelector from './../../store/operation-task.selectors';
import * as fromOperationTaskAction from './../../store/operation-task.actions';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewContainerRef,
  ViewChild,
  TemplateRef,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  OperationTaskId,
  OperationTaskText,
} from '../../store/operation-task.model';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  CdkDragEnter,
  CdkDragExit,
} from '@angular/cdk/drag-drop';

import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Update } from '@ngrx/entity';
import { includes } from 'lodash';
import {
  isToday,
  isThisWeek,
  isThisMonth,
  isThisYear,
  format,
  fromUnixTime,
} from 'date-fns';
import { fr } from 'date-fns/locale';
import { OperationId } from 'src/app/components/operation/store/operation.model';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';
import { NzModalService } from 'ng-zorro-antd/modal';
import { OperationState } from 'src/app/components/operation/store/operation.reducer';
import * as fromOperationSelector from 'src/app/components/operation/store/operation.selectors';
import { ActivatedRoute, Data } from '@angular/router';
import * as fromOperationAction from './../../../operation/store/operation.actions';

@Component({
  selector: 'app-operation-task-kanban',
  templateUrl: './operation-task-kanban.component.html',
  styleUrls: ['./operation-task-kanban.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationTaskKanbanComponent implements OnInit, OnChanges {
  user$: Observable<any> = of(null);
  operation$: Observable<OperationId | any> = of(null);

  // operation$: Observable<OperationId | any> = of(null);
  filters$: Observable<string[] | any> = of(null);
  subscribe = new Subject();

  loading$: Observable<boolean> = of(false);

  taksAll$: Observable<OperationTaskId[] | any> = of(null);
  tasks$: Observable<OperationTaskId[] | any> = of(null);
  tasksInProgress$: Observable<OperationTaskId[] | any> = of(null);
  verifed$: Observable<OperationTaskId[] | any> = of(null);
  dones$: Observable<OperationTaskId[] | any> = of(null);

  visibilityFormModal: boolean = false;
  viewMyTodos: boolean = false;
  formModalActivity = new BehaviorSubject<boolean>(false);
  filtersActicity: boolean = false;
  filterTitle: string = '';

  title: string = 'Nouvelle tâche';

  @ViewChild('taskFormTitle', { static: false })
  moduleFormTitle: TemplateRef<any> | any;
  modalWidth: number = 780;

  @Output() back = new EventEmitter(false);
  constructor(
    private userStore: Store<UserState>,
    private operationTaskStore: Store<OperationTaskState>,
    private operationStore: Store<OperationState>,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private route: ActivatedRoute
  ) {
    const data: Data = this.route.snapshot.data;
    this.operationStore.dispatch(
      fromOperationAction.setActviteRoute({ title: data?.title })
    );

    this.getOperation();
  }

  ngOnInit(): void {
    this.getUser();
    this.getLoading();
    this.loadTaks();
    this.getTasksCurrent();
    this.getTasksDoned();
    this.getTasksInPorgress();
    this.getTasksVerifed();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  trackBy(index: number, item: any): any {
    return item.id;
  }

  onBack(): void {
    this.back.emit(true);
  }

  getUser(): void {
    this.user$ = this.userStore.select(fromUserSelector.user);
  }

  getOperation(): void {
    this.operation$ = this.operationStore.select(
      fromOperationSelector.operation
    );
  }

  getLoading(): void {
    this.loading$ = this.operationTaskStore.select(
      fromOperationTaskSelector.loading
    );
  }

  loadTaks(): void {
    this.operation$
      .pipe(takeUntil(this.subscribe))
      .subscribe((operation: any) => {
        if (!operation) {
          return null;
        }

        this.operationTaskStore.dispatch(
          fromOperationTaskAction.loadOperationTasks({
            operationId: operation.id,
          })
        );

        return;
      });
  }

  getAllTasks(): void {
    this.taksAll$ = this.operationTaskStore.select(
      fromOperationTaskSelector.tasks
    );
  }

  getTasks(): void {
    this.tasks$ = this.operationTaskStore.select(
      fromOperationTaskSelector.tasksCurrents
    );
  }
  onNew(operation: OperationId): void {
    if (!operation) return;
    this.formModalActivity.next(true);
    this.title = 'Nouvelle tâche';
    const modal = this.modal.create({
      nzContent: OperationTaskFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.moduleFormTitle,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });
    const instance = modal.getContentComponent();
    instance.operation$ = this.operation$;

    instance.cancel.subscribe((event: boolean): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
    instance.add.subscribe((event: any): any => {
      if (!event) {
        return null;
      } else {
      }
    });
  }

  getTodosFilters__OPERATION(): void {
    this.filters$ = this.operationTaskStore.select(
      fromOperationTaskSelector.filters
    );
  }

  closeForm__TODO(): void {
    this.visibilityFormModal = false;
    this.formModalActivity.next(false);
  }

  drop(event: CdkDragDrop<string[]>, operation: OperationId) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );

      this.updateTask(
        event.previousContainer.id,
        event.container.id,
        event.container.data[0],
        operation
      );
    }
  }

  entered(event: CdkDragEnter<string[]> | any) {}

  exited(event: CdkDragExit<string[]> | any) {}

  updateTask(
    prevListId: string,
    currentListId: string,
    todo: any,
    operation: OperationId
  ): void {
    if (!todo) {
      return;
    }

    let updateType: string = this.getListById(currentListId);

    switch (updateType) {
      case 'todo':
        var textStatusInit: OperationTaskText[] = todo.texts.map(
          (el: OperationTaskText) => ({ value: el.value, status: false })
        );

        var operationTask: Update<OperationTaskId> = {
          id: todo.id,
          changes: {
            ...todo,
            texts: textStatusInit,
            completed: false,
            tested: false,
            verifed: false,
            inprogress: false,
          },
        };

        this.operationTaskStore.dispatch(
          fromOperationTaskAction.updateOperationTask({
            operationTask,
            updateType,
          })
        );
        break;
      case 'inprogress':
        var operationTask: Update<OperationTaskId> = {
          id: todo.id,
          changes: {
            ...todo,
            completed: false,
            tested: false,
            verifed: false,
            inprogress: true,
          },
        };

        this.operationTaskStore.dispatch(
          fromOperationTaskAction.updateOperationTask({
            operationTask,
            updateType,
          })
        );
        break;
      case 'verifed':
        var operationTask: Update<OperationTaskId> = {
          id: todo.id,
          changes: {
            ...todo,
            completed: false,
            tested: true,
            verifed: true,
            inprogress: true,
          },
        };

        this.operationTaskStore.dispatch(
          fromOperationTaskAction.updateOperationTask({
            operationTask,
            updateType,
          })
        );
        break;
      case 'done':
        var textStatusInit: OperationTaskText[] = todo.texts.map(
          (el: OperationTaskText) => ({ value: el.value, status: true })
        );

        var operationTask: Update<OperationTaskId> = {
          id: todo.id,
          changes: {
            ...todo,
            texts: textStatusInit,
            completed: true,
            tested: true,
            verifed: true,
            inprogress: true,
          },
        };

        this.operationTaskStore.dispatch(
          fromOperationTaskAction.updateOperationTask({
            operationTask,
            updateType,
          })
        );
        break;

      default:
        break;
    }
  }

  getListById(listId: string): string {
    switch (listId) {
      case 'cdk-drop-list-0':
        return 'todo';
      case 'cdk-drop-list-1':
        return 'inprogress';
      case 'cdk-drop-list-2':
        return 'verifed';
      case 'cdk-drop-list-3':
        return 'done';
      default:
        return '';
        break;
    }
  }

  getAllTodos__OPERATION(): void {
    this.taksAll$ = this.operationTaskStore.select(
      fromOperationTaskSelector.tasks
    );
    this.viewMyTodos = false;
  }

  getTodosDone__OPERATION(): void {
    this.dones$ = this.taksAll$.pipe(
      switchMap((todos: OperationTaskId[]) => {
        if (!todos?.length) {
          return of([]);
        }
        const todosMap = todos.filter(
          (todo: OperationTaskId) =>
            todo.completed && !todo.tested && !todo.verifed
        );
        return of(todosMap);
      })
    );
  }
  getTodosTested__OPERATION(): void {
    this.tasksInProgress$ = this.taksAll$.pipe(
      switchMap((todos: OperationTaskId[]) => {
        if (!todos?.length) {
          return of([]);
        }
        const todosMap = todos.filter(
          (todo: OperationTaskId) =>
            todo.completed && todo.tested && !todo.verifed
        );
        return of(todosMap);
      })
    );
  }
  getTodosVerifed__OPERATION(): void {
    this.verifed$ = this.taksAll$.pipe(
      switchMap((todos: OperationTaskId[]) => {
        if (!todos?.length) {
          return of([]);
        }
        const todosMap = todos.filter(
          (todo: OperationTaskId) =>
            (todo.completed && todo.verifed && todo.tested) ||
            (todo.completed && todo.verifed && !todo.tested)
        );
        return of(todosMap);
      })
    );
  }

  getTodayTodos__OPERATION(user: any): void {
    this.tasks$ = this.taksAll$.pipe(
      switchMap((todos: OperationTaskId[]) => {
        if (!todos?.length) {
          return of([]);
        }
        const todosMap = todos.filter(
          (todo: OperationTaskId) =>
            !todo.completed &&
            !todo.tested &&
            !todo.verifed &&
            isToday(fromUnixTime(todo.dateStart['seconds']))
        );
        return of(todosMap);
      })
    );
  }

  getTasksCurrent(user?: any): void {
    this.tasks$ = this.operationTaskStore.select(
      fromOperationTaskSelector.tasksCurrents
    );

    // this.taksAll$.pipe(
    //   switchMap((todos: OperationTaskId[]) => {
    //     if (!todos?.length) {
    //       return of([]);
    //     }
    //     const todosMap = todos.filter(
    //       (todo: OperationTaskId) =>
    //         todo.completed &&
    //         !todo.tested &&
    //         !todo.verifed &&
    //         isToday(fromUnixTime(todo.dateStart['seconds']))
    //     );
    //     return of(todosMap);
    //   })
    // );
  }

  getTasksDoned(user?: any): void {
    this.dones$ = this.operationTaskStore.select(
      fromOperationTaskSelector.tasksDoned
    );

    // this.taksAll$.pipe(
    //   switchMap((todos: OperationTaskId[]) => {
    //     if (!todos?.length) {
    //       return of([]);
    //     }
    //     const todosMap = todos.filter(
    //       (todo: OperationTaskId) =>
    //         todo.completed &&
    //         !todo.tested &&
    //         !todo.verifed &&
    //         isToday(fromUnixTime(todo.dateStart['seconds']))
    //     );
    //     return of(todosMap);
    //   })
    // );
  }
  getTasksInPorgress(user?: any): void {
    this.tasksInProgress$ = this.operationTaskStore.select(
      fromOperationTaskSelector.tasksInProgress
    );

    // this.taksAll$.pipe(
    //   switchMap((todos: OperationTaskId[]) => {
    //     if (!todos?.length) {
    //       return of([]);
    //     }
    //     const todosMap = todos.filter(
    //       (todo: OperationTaskId) =>
    //         todo.completed &&
    //         todo.tested &&
    //         !todo.verifed &&
    //         isToday(fromUnixTime(todo.dateStart['seconds']))
    //     );
    //     return of(todosMap);
    //   })
    // );
  }
  getTasksVerifed(user?: any): void {
    this.verifed$ = this.operationTaskStore.select(
      fromOperationTaskSelector.tasksVerifed
    );

    // this.taksAll$.pipe(
    //   switchMap((todos: OperationTaskId[]) => {
    //     if (!todos?.length) {
    //       return of([]);
    //     }
    //     const todosMap = todos.filter(
    //       (todo: OperationTaskId) =>
    //         (todo.completed &&
    //           todo.verifed &&
    //           todo.tested &&
    //           isToday(fromUnixTime(todo.dateStart['seconds']))) ||
    //         (todo.completed &&
    //           todo.verifed &&
    //           !todo.tested &&
    //           isToday(fromUnixTime(todo.dateStart['seconds'])))
    //     );
    //     return of(todosMap);
    //   })
    // );
  }

  getThisWeekTodos__OPERATION(user: any): void {
    this.tasks$ = this.taksAll$.pipe(
      switchMap((todos: OperationTaskId[]) => {
        if (!todos?.length) {
          return of([]);
        }
        const todosMap = todos.filter(
          (todo: OperationTaskId) =>
            !todo.completed &&
            !todo.tested &&
            !todo.verifed &&
            isThisWeek(fromUnixTime(todo.dateStart['seconds']), { locale: fr })
        );
        return of(todosMap);
      })
    );
  }
  getThisWeekTodosDone__OPERATION(user: any): void {
    this.dones$ = this.taksAll$.pipe(
      switchMap((todos: OperationTaskId[]) => {
        if (!todos?.length) {
          return of([]);
        }
        const todosMap = todos.filter(
          (todo: OperationTaskId) =>
            todo.completed &&
            !todo.tested &&
            !todo.verifed &&
            isThisWeek(fromUnixTime(todo.dateStart['seconds']), { locale: fr })
        );
        return of(todosMap);
      })
    );
  }
  getThisWeekTodosTested__OPERATION(user: any): void {
    this.tasksInProgress$ = this.taksAll$.pipe(
      switchMap((todos: OperationTaskId[]) => {
        if (!todos?.length) {
          return of([]);
        }
        const todosMap = todos.filter(
          (todo: OperationTaskId) =>
            todo.completed &&
            todo.tested &&
            !todo.verifed &&
            isThisWeek(fromUnixTime(todo.dateStart['seconds']), { locale: fr })
        );
        return of(todosMap);
      })
    );
  }
  getThisWeekTodosVerifed__OPERATION(user: any): void {
    this.verifed$ = this.taksAll$.pipe(
      switchMap((todos: OperationTaskId[]) => {
        if (!todos?.length) {
          return of([]);
        }
        const todosMap = todos.filter(
          (todo: OperationTaskId) =>
            (todo.completed &&
              todo.verifed &&
              todo.tested &&
              isThisWeek(fromUnixTime(todo.dateStart['seconds']), {
                locale: fr,
              })) ||
            (todo.completed &&
              todo.verifed &&
              !todo.tested &&
              isThisWeek(fromUnixTime(todo.dateStart['seconds']), {
                locale: fr,
              }))
        );
        return of(todosMap);
      })
    );
  }

  getThisMonthTodos__OPERATION(user: any): void {
    this.tasks$ = this.taksAll$.pipe(
      switchMap((todos: OperationTaskId[]) => {
        if (!todos?.length) {
          return of([]);
        }
        const todosMap = todos.filter(
          (todo: OperationTaskId) =>
            !todo.completed &&
            !todo.tested &&
            !todo.verifed &&
            isThisMonth(fromUnixTime(todo.dateStart['seconds']))
        );
        return of(todosMap);
      })
    );
  }
  getThisMonthTodosDone__OPERATION(user: any): void {
    this.dones$ = this.taksAll$.pipe(
      switchMap((todos: OperationTaskId[]) => {
        if (!todos?.length) {
          return of([]);
        }
        const todosMap = todos.filter(
          (todo: OperationTaskId) =>
            todo.completed &&
            !todo.tested &&
            !todo.verifed &&
            isThisMonth(fromUnixTime(todo.dateStart['seconds']))
        );
        return of(todosMap);
      })
    );
  }
  getThisMonthTodosTested__OPERATION(user: any): void {
    this.tasksInProgress$ = this.taksAll$.pipe(
      switchMap((todos: OperationTaskId[]) => {
        if (!todos?.length) {
          return of([]);
        }
        const todosMap = todos.filter(
          (todo: OperationTaskId) =>
            todo.completed &&
            todo.tested &&
            !todo.verifed &&
            isThisMonth(fromUnixTime(todo.dateStart['seconds']))
        );
        return of(todosMap);
      })
    );
  }
  getThisMonthTodosVerifed__OPERATION(user: any): void {
    this.verifed$ = this.taksAll$.pipe(
      switchMap((todos: OperationTaskId[]) => {
        if (!todos?.length) {
          return of([]);
        }
        const todosMap = todos.filter(
          (todo: OperationTaskId) =>
            (todo.completed &&
              todo.verifed &&
              todo.tested &&
              isThisMonth(fromUnixTime(todo.dateStart['seconds']))) ||
            (todo.completed &&
              todo.verifed &&
              !todo.tested &&
              isThisMonth(fromUnixTime(todo.dateStart['seconds'])))
        );
        return of(todosMap);
      })
    );
  }

  getThisYearTodos__OPERATION(user: any): void {
    this.tasks$ = this.taksAll$.pipe(
      switchMap((todos: OperationTaskId[]) => {
        if (!todos?.length) {
          return of([]);
        }
        const todosMap = todos.filter(
          (todo: OperationTaskId) =>
            !todo.completed &&
            !todo.tested &&
            !todo.verifed &&
            isThisYear(fromUnixTime(todo.dateStart['seconds']))
        );
        return of(todosMap);
      })
    );
  }
  getThisYearTodosDone__OPERATION(user: any): void {
    this.dones$ = this.taksAll$.pipe(
      switchMap((todos: OperationTaskId[]) => {
        if (!todos?.length) {
          return of([]);
        }
        const todosMap = todos.filter(
          (todo: OperationTaskId) =>
            todo.completed &&
            !todo.tested &&
            !todo.verifed &&
            isThisYear(fromUnixTime(todo.dateStart['seconds']))
        );
        return of(todosMap);
      })
    );
  }
  getThisYearTodosTested__OPERATION(user: any): void {
    this.tasksInProgress$ = this.taksAll$.pipe(
      switchMap((todos: OperationTaskId[]) => {
        if (!todos?.length) {
          return of([]);
        }
        const todosMap = todos.filter(
          (todo: OperationTaskId) =>
            todo.completed &&
            todo.tested &&
            !todo.verifed &&
            isThisYear(fromUnixTime(todo.dateStart['seconds']))
        );
        return of(todosMap);
      })
    );
  }
  getThisYearTodosVerifed__OPERATION(user: any): void {
    this.verifed$ = this.taksAll$.pipe(
      switchMap((todos: OperationTaskId[]) => {
        if (!todos?.length) {
          return of([]);
        }
        const todosMap = todos.filter(
          (todo: OperationTaskId) =>
            (todo.completed &&
              todo.verifed &&
              todo.tested &&
              isThisYear(fromUnixTime(todo.dateStart['seconds']))) ||
            (todo.completed &&
              todo.verifed &&
              !todo.tested &&
              isThisYear(fromUnixTime(todo.dateStart['seconds'])))
        );
        return of(todosMap);
      })
    );
  }

  getMyTodos__OPERATION(user: any): void {
    this.tasks$ = this.taksAll$.pipe(
      switchMap((todos: OperationTaskId[]) => {
        if (!todos?.length) {
          return of([]);
        }
        const todosMap = todos.filter(
          (todo: OperationTaskId) =>
            includes(todo.ids, user.id) &&
            !todo.completed &&
            !todo.tested &&
            !todo.verifed
        );
        return of(todosMap);
      })
    );
  }
  getMyTodosDone__OPERATION(user: any): void {
    this.dones$ = this.taksAll$.pipe(
      switchMap((todos: OperationTaskId[]) => {
        if (!todos?.length) {
          return of([]);
        }
        const todosMap = todos.filter(
          (todo: OperationTaskId) =>
            includes(todo.ids, user.id) &&
            todo.completed &&
            !todo.tested &&
            !todo.verifed
        );
        return of(todosMap);
      })
    );
  }
  getMyTodosTested__OPERATION(user: any): void {
    this.tasksInProgress$ = this.taksAll$.pipe(
      switchMap((todos: OperationTaskId[]) => {
        if (!todos?.length) {
          return of([]);
        }
        const todosMap = todos.filter(
          (todo: OperationTaskId) =>
            includes(todo.ids, user.id) &&
            todo.completed &&
            todo.tested &&
            !todo.verifed
        );
        return of(todosMap);
      })
    );
  }
  getMyTodosVerifed__OPERATION(user: any): void {
    this.verifed$ = this.taksAll$.pipe(
      switchMap((todos: OperationTaskId[]) => {
        if (!todos?.length) {
          return of([]);
        }
        const todosMap = todos.filter(
          (todo: OperationTaskId) =>
            includes(todo.ids, user.id) &&
            todo.completed &&
            todo.tested &&
            todo.verifed
        );
        return of(todosMap);
      })
    );
  }

  getTodosByFilter__OPERATION(filter: string, user: any): void {
    switch (filter) {
      case 'Tout':
        this.getTasks();
        this.getTodosDone__OPERATION();
        this.getTodosTested__OPERATION();
        this.getTodosVerifed__OPERATION();
        this.filtersActicity = false;
        this.filterTitle = '';
        break;
      case 'Mes tâches':
        this.filtersActicity = true;
        this.getMyTodos__OPERATION(user);
        this.getMyTodosDone__OPERATION(user);
        this.getMyTodosTested__OPERATION(user);
        this.getMyTodosVerifed__OPERATION(user);
        this.filterTitle = filter;
        break;
      case "Aujourd'hui":
        this.filterTitle = filter;
        this.getTodayTodos__OPERATION(user);
        this.getTasksDoned(user);
        this.getTasksInPorgress(user);
        this.getTasksVerifed(user);

        break;
      case 'Cette semaine':
        this.filterTitle = filter;
        this.getThisWeekTodos__OPERATION(user);
        this.getThisWeekTodosDone__OPERATION(user);
        this.getThisWeekTodosTested__OPERATION(user);
        this.getThisWeekTodosVerifed__OPERATION(user);

        break;
      case 'Ce mois':
        this.filterTitle = filter;
        this.getThisMonthTodos__OPERATION(user);
        this.getThisMonthTodosDone__OPERATION(user);
        this.getThisMonthTodosTested__OPERATION(user);
        this.getThisMonthTodosVerifed__OPERATION(user);

        break;
      case 'Cette année':
        this.filterTitle = filter;
        this.getThisYearTodos__OPERATION(user);
        this.getThisYearTodosDone__OPERATION(user);
        this.getThisYearTodosTested__OPERATION(user);
        this.getThisYearTodosVerifed__OPERATION(user);

        break;
      case "l'année prochaine":
        this.filterTitle = filter;

        break;
      default:
        break;
    }
  }

  clearFilter(): void {
    this.viewMyTodos = false;
    this.filtersActicity = false;
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }
}
