import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { EntiteSelect } from './entite-select.model';
import * as EntiteSelectActions from './entite-select.actions';
import { EntiteSMALLid } from 'src/app/components/entite/store/entite.model';

export const entiteSelectsFeatureKey = 'entiteSelects';

export interface EntiteSelectState extends EntityState<EntiteSMALLid> {
  // additional entities state properties
}

export const adapter: EntityAdapter<EntiteSMALLid> =
  createEntityAdapter<EntiteSMALLid>();

export const initialState: EntiteSelectState = adapter.getInitialState({
  // additional entity state properties
});

export const reducer = createReducer(
  initialState,
  on(EntiteSelectActions.loadEntiteSelectsSuccess, (state, action) =>
    adapter.setAll(action.entites, state)
  ),
  on(EntiteSelectActions.clearEntiteSelects, (state) =>
    adapter.removeAll(state)
  )
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
