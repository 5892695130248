import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';

import { Component, OnInit } from '@angular/core';
import { EntiteState } from '../../store/entite.reducer';
import * as fromEntiteAction from '../../store/entite.actions';
import * as fromEntiteSelector from '../../store/entite.selectors';
import { EntiteQuery } from '../../store/entite.model';
import { Observable, of } from 'rxjs';
@Component({
  selector: 'app-entite-filters',
  templateUrl: './entite-filters.component.html',
  styleUrls: ['./entite-filters.component.scss'],
})
export class EntiteFiltersComponent implements OnInit {
  title: string = 'Entites';
  entites$: Observable<any> = of(null);
  filters$: Observable<any> = of(null);
  roms$: Observable<any> = of([]);
  favoris$: Observable<any> = of([]);
  tags$: Observable<any> = of([]);
  filtersActivated: boolean = false;
  filtersOrders: any[] = [];
  cleaner: boolean = false;
  optionCategorie: any;
  optionActivity: any;
  optionSecteur: any;
  optionCommune: any;
  optionCodePostal: any;
  optionRegion: any;
  optionNature: any;
  optionType: any;
  optionStatut: any;
  optionEss: any;
  optionAdhesion: any;
  optionYear: any;
  optionOrgine: any;
  optionMetiers: any;

  filterForm: UntypedFormGroup = this.fb.group({});
  constructor(
    private userStore: Store<UserState>,
    private entiteStore: Store<EntiteState>,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.getEntites();
  }

  getEntites(): void {
    this.entites$ = this.entiteStore.select(fromEntiteSelector.entites);
  }

  getFavoris(): void {
    this.favoris$ = this.userStore.select(fromUserSelector.favorisEntites);
  }

  getTags(): void {
    this.tags$ = this.userStore.select(fromUserSelector.favorisEntites);
  }

  loadEntites(): void {
    this.filtersActivated = false;
    this.cleaner = false;
    this.filtersOrders = [];
    this.entiteStore.dispatch(fromEntiteAction.loadEntites());
  }

  filtersByRaisonSociale(value: string): void {
    if (!value) {
      this.entiteStore.dispatch(fromEntiteAction.loadEntites());
      this.filtersActivated = false;
    } else {
      const type = EntiteQuery.raison;
      this.entiteStore.dispatch(
        fromEntiteAction.queryEntites({ filter: value, filterType: type })
      );
      this.filtersActivated = true;
    }
  }

  onClear(): void {
    this.loadEntites();
  }
}
