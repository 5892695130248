import { SideNavigationState } from 'src/app/components/side-navigation/store/side-navigation.reducer';
import { Observable, of } from 'rxjs';
import { OrganisationId } from './../../store/organisation.model';
import { OrganisationState } from './../../store/organisation.reducer';
import * as fromOrganisationSelector from './../../store/organisation.selectors';

import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { SideNavigation } from 'src/app/components/side-navigation/store/side-navigation.model';
import * as fromSideNavigationAction from 'src/app/components/side-navigation/store/side-navigation.actions';
@Component({
  selector: 'app-organisation-header',
  templateUrl: './organisation-header.component.html',
  styleUrls: ['./organisation-header.component.scss'],
})
export class OrganisationHeaderComponent implements OnInit {
  organisation$: Observable<any> = of(null);
  constructor(
    private organisationStore: Store<OrganisationState>,
    private sideNavigationStore: Store<SideNavigationState>
  ) {}

  ngOnInit(): void {
    this.getOrganisation();
  }

  getOrganisation(): void {
    this.organisation$ = this.organisationStore.select(
      fromOrganisationSelector.organisation
    );
  }

  onSelectAction(type: string, organisation: any): void {
    if (!type || !organisation) return;

    switch (type) {
      case 'details':
        this.onOrganisationDetailsView(organisation);
        break;
      case 'users':
        this.onOrganisationUsersView(organisation);
        break;

      default:
        break;
    }
  }

  onOrganisationDetailsView(organisation: any): void {
    const navigation: SideNavigation = {
      type: 'side',
      name: 'organisation_details',
      item: organisation,
    };

    this.sideNavigationStore.dispatch(
      fromSideNavigationAction.loadSideNavigation({ navigation })
    );
  }

  onOrganisationUsersView(organisation: any): void {
    const navigation: SideNavigation = {
      type: 'side',
      name: 'organisation_users',
      item: organisation,
    };

    this.sideNavigationStore.dispatch(
      fromSideNavigationAction.loadSideNavigation({ navigation })
    );
  }
}
