<div [ngSwitch]="type">
  <div *ngSwitchCase="'sociale'">
    <mat-icon
      nz-tooltip
      [nzTooltipMouseEnterDelay]="0.5"
      nzTooltipPlacement="bottom"
      [nzTooltipTitle]="'Sociale'"
      class="social"
      >volunteer_activism</mat-icon
    >
  </div>
  <div *ngSwitchCase="'professionnelle'">
    <mat-icon
      nz-tooltip
      [nzTooltipMouseEnterDelay]="0.5"
      nzTooltipPlacement="bottom"
      [nzTooltipTitle]="'Professionnelle'"
      class="professionnel"
      >business_center</mat-icon
    >
  </div>
  <div *ngSwitchDefault></div>
</div>
