import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Update } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { add, fromUnixTime } from 'date-fns';
import { Timestamp } from 'firebase/firestore';
import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';
import { ParticipantId } from 'src/app/components/participant/store/participant.model';
import { ParticipantState } from 'src/app/components/participant/store/participant.reducer';
import * as fromParticipantSelector from 'src/app/components/participant/store/participant.selectors';
import { UtilitiesState } from 'src/app/contents/components/utilities/store/utilities.reducer';
import * as fromUtilitiesAction from 'src/app/contents/components/utilities/store/utilities.actions';

@Component({
  selector: 'app-participant-diagnostic-form',
  templateUrl: './participant-diagnostic-form.component.html',
  styleUrls: ['./participant-diagnostic-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantDiagnosticFormComponent
  implements OnInit, OnChanges, OnDestroy
{
  participant$: Observable<ParticipantId | any> = of(null);
  subscribe = new Subject();
  currentStep: number = 0;
  formStatus$ = new BehaviorSubject<string>('add');

  //VARIABLES
  today: Date = new Date(Date.now());
  dateFormat: string = 'dd/MM/yyyy';

  @Input() diagnostic$: Observable<any> = of(null);
  @Output() add = new EventEmitter<any>(false);
  @Output() update = new EventEmitter<any>(false);
  @Output() cancel = new EventEmitter<any>(false);

  //FORM
  diagnosticForm: FormGroup = this.fb.group({});

  //DEFINITION
  presentation: FormControl<string | any> = new FormControl('');
  dateStart: FormControl<Date | any> = new FormControl(
    this.today,
    Validators.required
  );
  besoins: FormControl<{ code: string; libelle: string }[] | any> =
    new FormControl([], Validators.required);

  //ADMINISTRATIF
  dateRecrutement: FormControl<Date | any> = new FormControl(
    this.today,
    Validators.required
  );
  dateInscription = new FormControl<Date | any>('');
  dateEntree: FormControl<Date | any> = new FormControl<Date | any>('');
  numbCarteSejour: FormControl<string | any> = new FormControl('');
  numCaiCir: FormControl<string | any> = new FormControl('');
  criteres1: FormControl<{ label: string; value: string } | any> =
    new FormControl(null, Validators.required);
  criteres2: FormControl<{ info: string; item: string }[] | any> =
    new FormControl([]);
  typeCarteSejour: FormControl<
    { info: string; titre: string; type: string } | any
  > = new FormControl({ info: '', titre: '', type: '' });
  isFse: FormControl<boolean | any> = new FormControl(false);
  isPassIae: FormControl<boolean | any> = new FormControl(false);
  administratifIsFrein: FormControl<boolean | any> = new FormControl(false);

  // FAMILLE
  enfantMoyenDeGarde: FormControl<boolean | any> = new FormControl(false);
  enfantScolarise: FormControl<boolean | any> = new FormControl(false);
  enfantsAcharges: FormControl<boolean | any> = new FormControl(false);
  familiale: FormControl<string | any> = new FormControl('');
  familleIsFrein: FormControl<boolean | any> = new FormControl(false);

  //LOGEMENT
  proprietaire: FormControl<boolean | any> = new FormControl(false);
  locataire: FormControl<boolean | any> = new FormControl(false);
  chezParent: FormControl<boolean | any> = new FormControl(false);
  chezMoi: FormControl<boolean | any> = new FormControl(false);
  lieuPublic: FormControl<boolean | any> = new FormControl(false);
  autreLieu: FormControl<boolean | any> = new FormControl(false);
  chezTier: FormControl<boolean | any> = new FormControl(false);
  hotel: FormControl<boolean | any> = new FormControl(false);
  centreHebergement: FormControl<boolean | any> = new FormControl(false);
  autre: FormControl<boolean | any> = new FormControl(false);
  nesaitpas: FormControl<boolean | any> = new FormControl(false);
  logementPrecision: FormControl<string | any> = new FormControl(false);
  adresse = new FormControl<{
    adresse: {
      properties: {
        label: string;
        score: number;
        housenumber: string;
        id: string;
        type: string;
        name: string;
        postcode: string;
        citycode: string;
        x: number;
        y: number;
        city: string;
        context: string;
        importance: number;
        street: string;
      };
      geometry: {
        type: string;
        coordinates: [number];
      };
    };
    complement: string;
    qpv: string;
  }>({
    adresse: {
      properties: {
        label: '',
        score: 0,
        housenumber: '',
        id: '',
        type: '',
        name: '',
        postcode: '',
        citycode: '',
        x: 0,
        y: 0,
        city: '',
        context: '',
        importance: 0,
        street: '',
      },
      geometry: {
        type: '',
        coordinates: [0],
      },
    },
    complement: '',
    qpv: '',
  });
  isQPV = new FormControl(false);
  isQVA = new FormControl(false);
  isZRR = new FormControl(false);
  logementIsFrein: FormControl<string | any> = new FormControl(false);

  //MOBILITE
  permis: FormControl<any> = new FormControl([]);
  transport_mode: FormControl<any> = new FormControl([]);
  geo_mobility: FormControl<any> = new FormControl([]);
  contrainte_horaire: FormControl<any> = new FormControl([]);
  mobiliteIsFrein: FormControl<boolean | any> = new FormControl(false);

  //SANTE
  sante: FormControl<any> = new FormControl('');
  couvertureMaladie: FormControl<any> = new FormControl('');
  handicap: FormControl<string | any> = new FormControl('');
  nirSecuriteSociale: FormControl<number | any> = new FormControl(0);
  santeIsFrein: FormControl<boolean | any> = new FormControl(false);

  // JUSTICE
  caseJudiciaire: FormControl<boolean | any> = new FormControl(false);
  suiviSpipPjj: FormControl<boolean | any> = new FormControl(false);
  executionDePeineEnCours: FormControl<boolean | any> = new FormControl(false);

  justice: FormControl<any> = new FormControl('');
  justiceIsFrein: FormControl<boolean | any> = new FormControl(false);

  //DROIT
  neConnaitSesDroitsAucuneDemarche: FormControl<boolean | any> =
    new FormControl(false);
  difficulteDeRenouvellementDeDroit: FormControl<boolean | any> =
    new FormControl(false);
  connaitSesDroitsDemarcheEnCours: FormControl<boolean | any> = new FormControl(
    false
  );
  beneficieDeSesDroits: FormControl<boolean | any> = new FormControl(false);
  precision: FormControl<string | any> = new FormControl('');
  consentementRGPD: FormControl<boolean | any> = new FormControl(true);
  droitDeTravail: FormControl<boolean | any> = new FormControl(true);
  droitAimage: FormControl<boolean | any> = new FormControl(true);
  droitIsFrein: FormControl<boolean | any> = new FormControl(false);

  //FORM NUMERIQUE
  numerique_level: FormControl<any[] | any> = new FormControl([]);
  mobile: FormControl<boolean | any> = new FormControl(false);
  internet: FormControl<boolean | any> = new FormControl(false);
  ordinateur: FormControl<boolean | any> = new FormControl(false);
  bureautique_level: FormControl<string[] | any> = new FormControl([]);
  numeriqueIsFrein: FormControl<boolean | any> = new FormControl(false);

  //QUALIFICATION
  qualification: FormControl<any> = new FormControl<any>({});
  maitriseFrancais: FormControl<
    { info: string; item: string; type: string } | any
  > = new FormControl<any>({});
  isFrenchLevelIlletrisme: FormControl<boolean | any> = new FormControl(false);
  isFrenchLevelFle: FormControl<boolean | any> = new FormControl(false);
  isFrenchLevelLaborieux: FormControl<boolean | any> = new FormControl(false);
  isFrenchLevelParfait: FormControl<boolean | any> = new FormControl(false);

  qualificationIsFrein = new FormControl(false);

  //RESSOURCES
  surendettementSansDossier: FormControl<boolean | any> = new FormControl(
    false
  );
  surendettementAvecDossier: FormControl<boolean | any> = new FormControl(
    false
  );
  besoinsPrimaires: FormControl<boolean | any> = new FormControl(false);

  saitGererSonBudget: FormControl<boolean | any> = new FormControl(false);
  surendettementSansDossierLibelle: FormControl<string | any> = new FormControl(
    'Situation de surendettement sans dépôt de dossier et/ou sans ressources'
  );
  surendettementAvecDossierLibelle: FormControl<string | any> = new FormControl(
    'Surendettement avec dossier déposé et suivi'
  );
  besoinPrimaireLibelle: FormControl<string | any> = new FormControl(
    'Besoins primaires assurés au jour le jour et/ou mesure de protection type curatelle'
  );
  saitGererSonBudgetLibelle: FormControl<string | any> = new FormControl(
    'Sait gérer son budget, avec capacité de financer par exemple un permis de conduire'
  );

  ressourceIsFrein: FormControl<boolean | any> = new FormControl(false);

  //APTITUDES
  softskills: FormControl<any> = new FormControl<
    { details: string; summary: string }[] | any
  >([]);

  //POLE EMPLOI
  identifiant: FormControl<any> = new FormControl('');
  inscription: FormControl<{ label: string; value: string } | any> =
    new FormControl({ label: '', value: '' });

  //PROJET PROFESSIONNEL

  //EXPEREICNES

  //DISPONIBILITES
  disponibiliteFaibleVoireDifficileLibelle: FormControl<string | any> =
    new FormControl(
      'Faible voire difficile (garde d’enfant, ascendant, famille)'
    );
  disponibiliteAorganiserLibelle: FormControl<string | any> = new FormControl(
    'A organiser'
  );
  disponibiliteImmediatementAvecLibelle: FormControl<string | any> =
    new FormControl('Immédiate avec des contraintes horaires');
  disponibiliteImmediatementSansLibelle: FormControl<string | any> =
    new FormControl('Immédiate et sans contraintes horaires');
  disponibiliteFaibleVoireDifficile: FormControl<boolean | any> =
    new FormControl(false);
  disponibiliteAorganiser: FormControl<boolean | any> = new FormControl(false);
  disponibiliteImmediatementAvec: FormControl<boolean | any> = new FormControl(
    false
  );
  disponibiliteImmediatementSans: FormControl<boolean | any> = new FormControl(
    false
  );
  disponibilitePrecision: FormControl<string | any> = new FormControl('');

  disponibiliteIsFrein: FormControl<boolean | any> = new FormControl(false);

  //POSTURES
  incapaciteAoccuperUnPoste: FormControl<boolean | any> = new FormControl(
    false
  );
  aBesoinDaccompagnement: FormControl<boolean | any> = new FormControl(false);
  peutOccuperUnPosteAvecCondition: FormControl<boolean | any> = new FormControl(
    false
  );
  peutOccuperUnPosteSansCondition: FormControl<boolean | any> = new FormControl(
    false
  );
  posturePrecision: FormControl<string | any> = new FormControl('');

  postureIsFrein: FormControl<boolean | any> = new FormControl(false);

  //RESUMÉ

  //ACTIONS

  //RENDEZ-VOUS

  prochainRdv: FormControl<Date | any> = new FormControl(
    add(this.today, { months: 2 }),
    Validators.required
  );

  //DIAGNOSTIC FORM UPDATE
  updateDefinition$ = new BehaviorSubject<any>({});
  updateAdministratif$ = new BehaviorSubject<any>({});
  updateFamille$ = new BehaviorSubject<any>({});
  updateLogement$ = new BehaviorSubject<any>({});
  updateDroit$ = new BehaviorSubject<any>({});
  updateRessources$ = new BehaviorSubject<any>({});
  updateQualification$ = new BehaviorSubject<any>({});
  updateNumerique$ = new BehaviorSubject<any>({});
  updateAptitudes$ = new BehaviorSubject<any>({});
  updateMobilite$ = new BehaviorSubject<any>({});
  updateDisponibilite$ = new BehaviorSubject<any>({});
  updateSante$ = new BehaviorSubject<any>({});
  updateJustice$ = new BehaviorSubject<any>({});
  updateDisponible$ = new BehaviorSubject<any>({});
  updatePosture$ = new BehaviorSubject<any>({});
  updatePoleEmploi$ = new BehaviorSubject<any>({});

  @Input() view$ = new BehaviorSubject<string>('participant');

  constructor(
    private fb: FormBuilder,
    private utilitiesStore: Store<UtilitiesState>,
    private participantStore: Store<ParticipantState>
  ) {}

  ngOnInit(): void {
    this.getParticipant();
    this.form();
  }
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getParticipant(): void {
    this.participant$ = this.participantStore.select(
      fromParticipantSelector.participant
    );
  }

  onStepsChanges(index: number): void {
    this.currentStep = index;
  }

  loadUtilities(): void {
    this.loadAllocation();
    this.loadCriteres();
    this.loadSejours();
  }

  loadCriteres(): void {
    this.utilitiesStore.dispatch(fromUtilitiesAction.loadCriteresReferentiel());
  }
  loadAllocation(): void {
    this.utilitiesStore.dispatch(
      fromUtilitiesAction.loadAllocationReferentiel()
    );
  }
  loadSejours(): void {
    this.utilitiesStore.dispatch(fromUtilitiesAction.loadSejourReferentiel());
  }

  form(): void {
    this.formInt();
    this.formCreate();
    this.formSet();
  }
  formInt(): void {
    this.presentation = new FormControl<string>('');
    this.dateStart = new FormControl<any>(this.today, Validators.required);
    this.besoins = new FormControl<{ code: string; libelle: string }[] | any>(
      [],
      Validators.required
    );

    //ADMINISTRATIF
    this.dateRecrutement = new FormControl<Date | any>(
      this.today,
      Validators.required
    );
    this.dateInscription = new FormControl<Date | any>('');
    this.dateEntree = new FormControl<Date | any>('');
    this.numbCarteSejour = new FormControl('');
    this.numCaiCir = new FormControl('');
    this.criteres1 = new FormControl<{
      justificatif: string;
      label: string;
      value: string;
    }>({ justificatif: '', label: '', value: '' }, Validators.required);
    this.typeCarteSejour = new FormControl<{
      info: string;
      titre: string;
      type: string;
    }>({ info: '', titre: '', type: '' });
    this.criteres2 = new FormControl<{ info: string; item: string }[] | any>(
      []
    );
    this.isPassIae = new FormControl(false);

    this.isFse = new FormControl(false);
    this.administratifIsFrein = new FormControl<boolean>(
      false,
      Validators.required
    );

    // FAMILLE
    this.enfantMoyenDeGarde = new FormControl(false);
    this.enfantScolarise = new FormControl(false);
    this.enfantsAcharges = new FormControl(false);
    this.familiale = new FormControl('');
    this.familleIsFrein = new FormControl(false);

    //LOGEMENT
    this.adresse = new FormControl<{
      adresse: {
        properties: {
          label: string;
          score: number;
          housenumber: string;
          id: string;
          type: string;
          name: string;
          postcode: string;
          citycode: string;
          x: number;
          y: number;
          city: string;
          context: string;
          importance: number;
          street: string;
        };
        geometry: {
          type: string;
          coordinates: [number];
        };
      };
      complement: string;
      qpv: string;
    }>({
      adresse: {
        properties: {
          label: '',
          score: 0,
          housenumber: '',
          id: '',
          type: '',
          name: '',
          postcode: '',
          citycode: '',
          x: 0,
          y: 0,
          city: '',
          context: '',
          importance: 0,
          street: '',
        },
        geometry: {
          type: '',
          coordinates: [0],
        },
      },
      complement: '',
      qpv: '',
    });

    this.proprietaire = new FormControl(false, Validators.required);
    this.locataire = new FormControl(false, Validators.required);
    this.chezParent = new FormControl(false, Validators.required);
    this.chezMoi = new FormControl(false, Validators.required);
    this.lieuPublic = new FormControl(false, Validators.required);
    this.autreLieu = new FormControl(false, Validators.required);
    this.chezTier = new FormControl(false, Validators.required);
    this.hotel = new FormControl(false, Validators.required);
    this.centreHebergement = new FormControl(false, Validators.required);
    this.autre = new FormControl(false, Validators.required);
    this.nesaitpas = new FormControl(false, Validators.required);
    this.precision = new FormControl<string>('', Validators.required);

    this.isQPV = new FormControl(false, Validators.required);
    this.isQVA = new FormControl(false, Validators.required);
    this.isZRR = new FormControl(false, Validators.required);
    this.logementIsFrein = new FormControl<boolean>(false);

    //MOBILE
    this.permis = new FormControl<any>([]);
    this.transport_mode = new FormControl<any>([]);
    this.geo_mobility = new FormControl<any>([]);
    this.contrainte_horaire = new FormControl('');
    this.mobiliteIsFrein = new FormControl<boolean>(false);

    //SANTE
    this.sante = new FormControl('');
    this.couvertureMaladie = new FormControl('');
    this.handicap = new FormControl('');
    this.nirSecuriteSociale = new FormControl(0);
    this.santeIsFrein = new FormControl(false);

    //JUSTICE
    this.caseJudiciaire = new FormControl<boolean>(false);
    this.justice = new FormControl<string>('');
    this.justiceIsFrein = new FormControl<boolean>(false);
    this.suiviSpipPjj = new FormControl(false);
    this.executionDePeineEnCours = new FormControl(false);

    //DROIT
    this.droitIsFrein = new FormControl<boolean>(false);
    this.neConnaitSesDroitsAucuneDemarche = new FormControl(false);
    this.difficulteDeRenouvellementDeDroit = new FormControl(false);
    this.connaitSesDroitsDemarcheEnCours = new FormControl(false);
    this.beneficieDeSesDroits = new FormControl(false);
    this.consentementRGPD = new FormControl<boolean>(true);
    this.droitDeTravail = new FormControl<boolean>(true);
    this.droitAimage = new FormControl(true);
    this.precision = new FormControl('');

    //QUALIFICATION
    this.qualification = new FormControl<
      | {
          label: string;
          value: string;
        }
      | any
    >({ label: '', value: '' });
    this.isFrenchLevelIlletrisme = new FormControl(false);
    this.isFrenchLevelFle = new FormControl(false);
    this.isFrenchLevelLaborieux = new FormControl(false);
    this.isFrenchLevelParfait = new FormControl(false);
    this.maitriseFrancais = new FormControl<
      { info: string; item: string; type: string } | any
    >({ info: '', item: '', type: '' });
    this.qualificationIsFrein = new FormControl(false);

    //NUMERIQUE
    this.mobile = new FormControl<boolean>(false);
    this.internet = new FormControl<boolean>(false);
    this.ordinateur = new FormControl<boolean>(false);
    this.numerique_level = new FormControl<any[]>([]);
    this.bureautique_level = new FormControl<any[]>([]);
    this.numeriqueIsFrein = new FormControl<boolean>(false);

    //RESSOURCES
    this.surendettementSansDossier = new FormControl(false);
    this.surendettementAvecDossier = new FormControl(false);
    this.besoinsPrimaires = new FormControl(false);

    this.saitGererSonBudget = new FormControl(false);
    this.surendettementSansDossierLibelle = new FormControl(
      'Situation de surendettement sans dépôt de dossier et/ou sans ressources'
    );
    this.surendettementAvecDossierLibelle = new FormControl(
      'Surendettement avec dossier déposé et suivi'
    );
    this.besoinPrimaireLibelle = new FormControl(
      'Besoins primaires assurés au jour le jour et/ou mesure de protection type curatelle'
    );
    this.saitGererSonBudgetLibelle = new FormControl(
      'Sait gérer son budget, avec capacité de financer par exemple un permis de conduire'
    );

    this.ressourceIsFrein = new FormControl<boolean>(false);

    //APTITUDES
    this.softskills = new FormControl<
      { details: string; summary: string }[] | any
    >([]);

    //DISPONIBILITE
    this.disponibiliteFaibleVoireDifficileLibelle = new FormControl(
      'Faible voire difficile (garde d’enfant, ascendant, famille)'
    );
    this.disponibiliteAorganiserLibelle = new FormControl('A organiser');
    this.disponibiliteImmediatementAvecLibelle = new FormControl(
      'Immédiate avec des contraintes horaires'
    );
    this.disponibiliteImmediatementSansLibelle = new FormControl(
      'Immédiate et sans contraintes horaires'
    );
    this.disponibiliteFaibleVoireDifficile = new FormControl(false);
    this.disponibiliteAorganiser = new FormControl(false);
    this.disponibiliteImmediatementAvec = new FormControl(false);
    this.disponibiliteImmediatementSans = new FormControl(false);
    this.disponibilitePrecision = new FormControl('');
    this.disponibiliteIsFrein = new FormControl<boolean>(false);

    //POSTURE
    this.incapaciteAoccuperUnPoste = new FormControl<boolean>(false);
    this.aBesoinDaccompagnement = new FormControl<boolean>(false);
    this.peutOccuperUnPosteAvecCondition = new FormControl<boolean>(false);
    this.peutOccuperUnPosteSansCondition = new FormControl<boolean>(false);
    this.posturePrecision = new FormControl('');
    this.postureIsFrein = new FormControl<boolean>(false);

    //POLE EMPLOI
    this.identifiant = new FormControl<string>('');
    this.inscription = new FormControl<{ label: string; value: string } | any>({
      label: '',
      value: '',
    });

    //PROJET PROFESSIONNEL

    //EXPEREINCE
  }
  formCreate(): void {
    this.diagnosticForm = this.fb.group({
      dateStart: this.dateStart,
      presention: this.presentation,
      besoins: this.besoins,

      // ADMINSTRATIF
      administratif: this.fb.group({
        dateRecrutement: this.dateRecrutement,
        dateInscription: this.dateInscription,
        dateEntree: this.dateEntree,
        numbCarteSejour: this.numbCarteSejour,
        numCaiCir: this.numCaiCir,
        criteres1: this.criteres1,
        typeCarteSejour: this.typeCarteSejour,
        criteres2: this.criteres2,
        isPassIae: this.isPassIae,
        isFse: this.isFse,
        isFrein: this.administratifIsFrein,
      }),

      //FAMILLE
      famille: this.fb.group({
        enfantMoyenDeGarde: this.enfantMoyenDeGarde,
        enfantScolarise: this.enfantScolarise,
        enfantsAcharges: this.enfantsAcharges,
        familiale: this.familiale,
        isFrein: this.familleIsFrein,
      }),

      //LOGEMENT
      logement: this.fb.group({
        proprietaire: this.proprietaire,
        locataire: this.locataire,
        chezParent: this.chezParent,
        chezMoi: this.chezMoi,
        lieuPublic: this.lieuPublic,
        autreLieu: this.autreLieu,
        chezTier: this.chezTier,
        hotel: this.hotel,
        centreHebergement: this.centreHebergement,
        autre: this.autre,
        nesaitpas: this.nesaitpas,
        precision: this.precision,
        adresse: this.adresse,
        isQPV: this.isQPV,
        isQVA: this.isQVA,
        isZRR: this.isZRR,
        isFrein: this.logementIsFrein,
      }),

      // MOBILE
      mobilite: this.fb.group({
        permis: this.permis,
        transport_mode: this.transport_mode,
        geo_mobility: this.geo_mobility,
        contrainte_horaire: this.contrainte_horaire,
        isFrein: this.mobiliteIsFrein,
      }),

      // SANTE
      sante: this.fb.group({
        sante: this.sante,
        couvertureMaladie: this.couvertureMaladie,
        handicap: this.handicap,
        nirSecuriteSociale: this.nirSecuriteSociale,
        isFrein: this.santeIsFrein,
      }),

      //JUSTICE
      justice: this.fb.group({
        caseJudiciaire: this.caseJudiciaire,
        suiviSpipPjj: this.suiviSpipPjj,
        executionDePeineEnCours: this.executionDePeineEnCours,
        justice: this.justice,
        isFrein: this.justiceIsFrein,
      }),

      //DROIT
      droit: this.fb.group({
        neConnaitSesDroitsAucuneDemarche: this.neConnaitSesDroitsAucuneDemarche,
        difficulteDeRenouvellementDeDroit:
          this.difficulteDeRenouvellementDeDroit,
        connaitSesDroitsDemarcheEnCours: this.connaitSesDroitsDemarcheEnCours,
        beneficieDeSesDroits: this.beneficieDeSesDroits,
        precision: this.precision,
        consentementRGPD: this.consentementRGPD,
        droitDeTravail: this.droitDeTravail,
        droitAimage: this.droitAimage,
        isFrein: this.droitIsFrein,
      }),

      //QUALIFICATION
      qualification: this.fb.group({
        qualification: this.qualification,
        isFrenchLevelIlletrisme: this.isFrenchLevelIlletrisme,
        isFrenchLevelFle: this.isFrenchLevelFle,
        isFrenchLevelLaborieux: this.isFrenchLevelLaborieux,
        isFrenchLevelParfait: this.isFrenchLevelParfait,
        maitriseFrancais: this.maitriseFrancais,
        isFrein: this.qualificationIsFrein,
      }),

      //NUMERIQUE
      numerique: this.fb.group({
        mobile: this.mobile,
        internet: this.internet,
        ordinateur: this.ordinateur,
        numerique_level: this.numerique_level,
        bureautique_level: this.bureautique_level,
        isFrein: this.numeriqueIsFrein,
      }),

      //RESSOURCE
      ressources: this.fb.group({
        surendettementSansDossier: this.surendettementSansDossier,
        surendettementAvecDossier: this.surendettementAvecDossier,
        besoinsPrimaires: this.besoinsPrimaires,
        saitGererSonBudget: this.saitGererSonBudget,
        surendettementSansDossierLibelle: this.surendettementSansDossierLibelle,
        surendettementAvecDossierLibelle: this.surendettementAvecDossierLibelle,
        besoinPrimaireLibelle: this.besoinPrimaireLibelle,
        saitGererSonBudgetLibelle: this.saitGererSonBudgetLibelle,
        isFrein: this.ressourceIsFrein,
      }),

      //APTITUDES
      aptitudes: this.fb.group({
        softskills: this.softskills,
      }),

      //POLE EMPLOI
      poleEmploi: this.fb.group({
        inscription: this.inscription,
        identifiant: this.identifiant,
      }),

      //EXPERIENCES
      experiences: this.fb.group({}),

      //PROJET PROFESSIONNEL
      projetProfessionnel: this.fb.group({}),

      //DISPONIBILITY
      disponibilite: this.fb.group({
        disponibiliteFaibleVoireDifficileLibelle:
          this.disponibiliteFaibleVoireDifficileLibelle,
        disponibiliteAorganiserLibelle: this.disponibiliteAorganiserLibelle,
        disponibiliteImmediatementAvecLibelle:
          this.disponibiliteImmediatementAvecLibelle,
        disponibiliteImmediatementSansLibelle:
          this.disponibiliteImmediatementSansLibelle,
        disponibiliteFaibleVoireDifficile:
          this.disponibiliteFaibleVoireDifficile,
        disponibiliteAorganiser: this.disponibiliteAorganiser,
        disponibiliteImmediatementAvec: this.disponibiliteImmediatementAvec,
        disponibiliteImmediatementSans: this.disponibiliteImmediatementSans,
        precision: this.disponibilitePrecision,
        isFrein: this.disponibiliteIsFrein,
      }),

      //POSTURE
      posture: this.fb.group({
        incapaciteAoccuperUnPoste: this.incapaciteAoccuperUnPoste,
        aBesoinDaccompagnement: this.aBesoinDaccompagnement,
        peutOccuperUnPosteAvecCondition: this.peutOccuperUnPosteAvecCondition,
        peutOccuperUnPosteSansCondition: this.peutOccuperUnPosteSansCondition,
        precision: this.posturePrecision,
        isFrein: this.postureIsFrein,
      }),

      //RESUME
      resume: this.fb.group({}),

      //ACTIONS
      actions: this.fb.group({}),

      //RENDEZ-VOUS
    });
  }
  formSet(): void {
    this.diagnostic$.pipe(takeUntil(this.subscribe)).subscribe((diagnostic) => {
      if (!diagnostic) {
        this.formStatus$.next('add');
        return;
      }

      this.formStatus$.next('update');

      const { dateStart, type, presention, besoins } = diagnostic;

      const start: Date = fromUnixTime(dateStart['seconds']);

      //SET FORM DEFINITION
      const definition = {
        dateStart: start,
        presention,
        besoins,
      };
      this.updateDefinition$.next(definition);

      //SET FORM ADMINITRATION
      this.updateAdministratif$.next(diagnostic.administratif);

      //SET FORM LOGEMENT
      this.updateLogement$.next(diagnostic.logement);

      //SET FORM FAMILLE
      this.updateFamille$.next(diagnostic.famille);

      //SET FORM MOBILITE
      this.updateMobilite$.next(diagnostic.mobilite);

      //SET FORM DISPONIBILITE
      this.updateDisponibilite$.next(diagnostic.disponibilite);

      //SET FORM RESSOURCES
      this.updateRessources$.next(diagnostic.ressources);

      //SET FORM JUSTICE
      this.updateJustice$.next(diagnostic.justice);

      //SET FORM DROIT
      this.updateDroit$.next(diagnostic.droit);

      //SET FORM SANTE
      this.updateSante$.next(diagnostic.sante);

      //SET FORM APTITUDES
      this.updateAptitudes$.next(diagnostic.adptitudes);

      //SET FORM QUALIFICATION
      this.updateQualification$.next(diagnostic.qualification);

      //SET FORM NUMERIQUE
      this.updateNumerique$.next(diagnostic.numerique);

      //SET FORM POLE EMPLOI
      this.updatePoleEmploi$.next(diagnostic.poleEmploi);

      //SET FORM DIGANOSTIC
      this.diagnosticForm.patchValue({
        dateStart: start,
        type,
        presention,
        besoins,
        administratif: diagnostic.administratif,
        logement: diagnostic.logement,
        famille: diagnostic.famille,
        mobilite: diagnostic.mobilite,
        disponibilite: diagnostic.disponibilite,
        ressources: diagnostic.ressources,
        justice: diagnostic.justice,
        droit: diagnostic.droit,
        sante: diagnostic.sante,
        poleEmploi: diagnostic.poleEmploi,
        numerique: diagnostic.numerique,
        qualification: diagnostic.qualification,
        adptitudes: diagnostic.adptitudes,
      });
    });
  }

  // DEFINITION
  onSetDefinition(items: any): void {
    this.diagnosticForm.patchValue({
      dateStart: items.dateStart,
      type: items.type,
      besoins: items.besoins,
      presentation: items.presentation,
    });
  }

  //SOCIAL INFORMATIONS
  onSetAdministratif(items: any): void {
    this.diagnosticForm.patchValue({
      administratif: items,
    });
  }

  onSetFamille(items: any): void {
    this.diagnosticForm.patchValue({
      famille: items,
    });
  }

  onSetLogement(items: any): void {
    this.diagnosticForm.patchValue({
      logement: items,
    });
  }

  onSetMobilite(items: any): void {
    this.diagnosticForm.patchValue({
      mobilite: items,
    });
  }

  onSetSante(items: any): void {
    this.diagnosticForm.patchValue({
      sante: items,
    });
  }
  onSetJustice(items: any): void {
    this.diagnosticForm.patchValue({
      justice: items,
    });
  }

  onSetDroit(items: any): void {
    this.diagnosticForm.patchValue({
      droit: items,
    });
  }

  //FORMATION INFORMATIONS
  onSetQualification(items: any): void {
    console.log(items);
    this.diagnosticForm.patchValue({
      qualification: items,
    });
  }
  onSetNumerique(items: any): void {
    this.diagnosticForm.patchValue({
      numerique: items,
    });
  }

  //RESSOURCES INFORMATIONS
  onSetRessource(items: any): void {
    this.diagnosticForm.patchValue({
      ressources: items,
    });
  }

  //APTITUDE INFORMATIONS
  onSetAptitudes(items: any): void {
    this.diagnosticForm.patchValue({
      aptitudes: {
        softskills: items,
      },
    });
  }

  //DISPONIBILITY INFORMATIONS
  onSetDisponibilite(items: any): void {
    this.diagnosticForm.patchValue({
      disponibilite: items,
    });
  }
  //POSRTURE INFORMATIONS
  onSetPosture(items: any): void {
    this.diagnosticForm.patchValue({
      posture: items,
    });
  }

  //POLE EMPLOI
  onSetPoleEmploi(items: any): void {
    this.diagnosticForm.patchValue({
      poleEmploi: items,
    });
  }

  //PROJET PROFESSIONNEL INFORMATIONS
  onSetProjetProfessionnel(items: any): void {
    console.log(items);
    this.diagnosticForm.patchValue({
      projetProfessionnel: items,
    });
  }

  //EXPEREINCES
  onSetExperiences(items: any): void {
    console.log(items);
    this.diagnosticForm.patchValue({
      projetProfessionnel: items,
    });
  }

  //RESUMÉ INFORMATIONS
  onSetResume(items: any): void {
    console.log(items);
    this.diagnosticForm.patchValue({
      resume: items,
    });
  }

  //ACTIONS INFORMATIONS
  onSetActions(items: any): void {
    console.log(items);
    this.diagnosticForm.patchValue({
      actions: items,
    });
  }

  //RENDEZ-VOUS INFORMATIONS
  onSetRendezVous(items: any): void {
    console.log(items);
    this.diagnosticForm.patchValue({
      rendezVous: items,
    });
  }

  //COMPARE BEFORE UPDATE
  compareQualification(obj1: any, obj2: any) {
    return obj1 && obj2 && obj1.titre === obj2.titre;
  }

  compareProjet(obj1: any, obj2: any) {
    return obj1.denomination === obj2.denomination;
  }

  compareCarteSejour(obj1: any, obj2: any) {
    return obj1 && obj2 && obj1.titre === obj2.titre;
  }

  compareCarteIdentite(obj1: any, obj2: any) {
    return obj1 && obj2 && obj1.titre === obj2.titre;
  }

  compareSortie(obj1: any, obj2: any) {
    return obj1 && obj2 && obj1.motif === obj2.motif;
  }

  compareNationality(obj1: any, obj2: any) {
    return obj1 && obj2 && obj1.nationalite === obj2.nationalite;
  }

  compareFrancais(obj1: any, obj2: any) {
    return obj1 && obj2 && obj1.item === obj2.item;
  }

  compareDiploma(obj1: any, obj2: any) {
    return obj1 && obj2 && obj1.label === obj2.label;
  }

  comparePoleEmploi(obj1: any, obj2: any) {
    return obj1 && obj2 && obj1.label === obj2.label;
  }

  compareAllocation(obj1: any, obj2: any) {
    return obj1 && obj2 && obj1.value === obj2.value;
  }

  compareSante(item1: any, item2: any) {
    return item1 && item2 && item1 === item2;
  }

  compareHandicap(item1: any, item2: any) {
    return item1 && item2 && item1.item === item2.item;
  }

  compareMatrimonial(item1: any, item2: any) {
    return item1 && item2 && item1 === item2;
  }

  compareModeDeplacement(item1: any, item2: any) {
    return item1 && item2 && item1 === item2;
  }

  compareDeplacementProfessionnel(obj1: any, obj2: any) {
    return obj1 && obj2 && obj1.NAME === obj2.NAME;
  }
  comparePermis(obj1: any, obj2: any) {
    return obj1 && obj2 && obj1.NAME === obj2.NAME;
  }
  compareNumeriqueLevel(obj1: any, obj2: any) {
    return obj1 && obj2 && obj1.item === obj2.item;
  }

  compareBureautiqueLevel(obj1: any, obj2: any) {
    return obj1 && obj2 && obj1.NAME === obj2.NAME;
  }
  compareBureautiqueTools(obj1: any, obj2: any) {
    return obj1 && obj2 && obj1.NAME === obj2.NAME;
  }

  compareJudiciaire(item1: any, item2: any) {
    return item1 && item2 && item1 === item2;
  }

  compareCritere(obj1: any, obj2: any) {
    return obj1 && obj2 && obj1.item === obj2.item;
  }

  onAdd(participant: ParticipantId): void {
    if (!this.diagnosticForm.valid) {
      return;
    }

    const diagnostic = this.diagnosticForm.value;

    delete diagnostic.experiences;
    delete diagnostic.projetProfessionnel;
    delete diagnostic.resume;
    delete diagnostic.actions;

    this.add.next(diagnostic);
  }

  onUpdate(diagnostic: any): void {
    if (!this.diagnosticForm.valid || !diagnostic) {
      return;
    }
    const diagnosticFormValues = this.diagnosticForm.value;
    delete diagnosticFormValues.experiences;
    delete diagnosticFormValues.projetProfessionnel;
    delete diagnosticFormValues.resume;
    delete diagnosticFormValues.actions;

    const nextDiagnostic: Update<any> = {
      id: diagnostic.id,
      changes: { ...diagnostic, ...diagnosticFormValues },
    };

    this.update.next(nextDiagnostic);
  }

  onCancel(): void {
    this.diagnosticForm.reset();
    this.cancel.next(true);
  }
}
