<nz-row nzAlign="middle" nzGutter="8" nzJustify="start" *ngIf="showAdresse">
  <nz-col [nzSpan]="experience ? 0 : 16">
    <app-communes-france
      [borderless]="true"
      [size]="'default'"
      [updateItem]="updateAdresse"
      [updateComplement]="updateAdresseComplete"
      (adresse)="setAdresse($event)"
      *ngIf="showQualification"
    ></app-communes-france>
  </nz-col>
  <nz-col [nzSpan]="experience ? 12 : 4">
    <form nz-form [formGroup]="metierForm" nzLayout="vertical">
      <nz-form-item *ngIf="!experience">
        <nz-form-label nzRequired>
          <small>Durée d'accompagnement</small>
        </nz-form-label>
        <nz-form-control>
          <nz-input-number
            formControlName="duration"
            [nzMin]="01"
            [nzMax]="99"
            nzPlaceHolder="Durée en mois"
            nzSize="small"
          >
          </nz-input-number>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item *ngIf="experience">
        <nz-form-label nzRequired>
          <small>Démarrage</small>
        </nz-form-label>
        <nz-form-control>
          <nz-date-picker
            formControlName="dateStart"
            nzPlaceHolder="Date de début"
            nzBorderless
            [nzMode]="'month'"
            [nzFormat]="dateFormat"
            nzAllowClear="true"
          ></nz-date-picker>
        </nz-form-control>
      </nz-form-item>
    </form>
  </nz-col>
  <nz-col [nzSpan]="experience ? 12 : 4">
    <form nz-form [formGroup]="metierForm" nzLayout="vertical">
      <nz-form-item *ngIf="!experience">
        <nz-form-label nzRequired>
          <small>Âge du participant</small>
        </nz-form-label>
        <nz-form-control>
          <nz-input-number
            formControlName="age"
            [nzMin]="01"
            [nzMax]="99"
            nzPlaceHolder="Âge"
            nzSize="small"
          >
          </nz-input-number>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item *ngIf="experience">
        <nz-form-label nzRequired>
          <small>Échéance</small>
        </nz-form-label>
        <nz-form-control>
          <nz-date-picker
            formControlName="dateEnd"
            nzPlaceHolder="Date de fin"
            nzBorderless
            [nzMode]="'month'"
            [nzFormat]="dateFormat"
            nzAllowClear="true"
          ></nz-date-picker>
        </nz-form-control>
      </nz-form-item>
    </form>
  </nz-col>
</nz-row>

<nz-row
  nzAlign="middle"
  nzGutter="8"
  nzJustify="start"
  *ngIf="type === 'theme'"
>
  <nz-col nzSpan="24">
    <span>Thème</span>
    <nz-select
      [(ngModel)]="selectedThemes"
      (ngModelChange)="onSelectDomaine($event)"
      nzBorderless
      nzShowSearch
      class="borderlessClass"
      nzPlaceHolder="Theme métier"
      [nzDisabled]="!((themes$ | async)?.themeMetiers | size)"
      nzAllowClear
      nzServerSearch
      nzSize="large"
    >
      <nz-option
        *ngFor="let item of (themes$ | async)?.themeMetiers"
        [nzLabel]="item?.libelle"
        [nzValue]="item"
      ></nz-option>
    </nz-select>
  </nz-col>
</nz-row>

<ng-container *ngIf="experience">
  <nz-divider nzText="Entité"></nz-divider>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="20">
      <app-entite-search (addItem)="onSelectEntite($event)"></app-entite-search>
    </nz-col>
    <nz-col nzSpan="4">
      <form nz-form [formGroup]="metierForm" nzLayout="vertical">
        <nz-form-item>
          <nz-form-control>
            <label nz-checkbox formControlName="horsFrance"
              >Hors de france</label
            >
          </nz-form-control>
        </nz-form-item>
      </form>
    </nz-col>
  </nz-row>
  <nz-row
    nzAlign="middle"
    nzGutter="8"
    nzJustify="start"
    *ngIf="selectedEntite$ | async"
  >
    <nz-col nzSpan="24">
      <nz-tag nzColor="geekblue">
        <strong>{{ (selectedEntite$ | async)?.denomination }}</strong>
        <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
        <strong>{{ (selectedEntite$ | async)?.siren }}</strong>
      </nz-tag>
    </nz-col>
  </nz-row>
</ng-container>

<nz-divider nzText="Métier" *ngIf="showTitle"></nz-divider>
<div class="w-full space-y-6">
  <div
    class="w-full flex items-center justify-between"
    *ngIf="type === 'grandDomaine'"
  >
    <div [class]="(loading$ | async) ? 'block w-1/12' : 'hidden'">
      <i nz-icon nzType="loading" class="iconLoading"></i>
    </div>

    <div [class]="(loading$ | async) ? 'w-5/12 mr-2' : 'w-6/12 mr-2'">
      <label class="label required">Grand Domaine</label>
      <div class="select-menu">
        <nz-select
          [(ngModel)]="selectedDomaine"
          (ngModelChange)="onSelectDomaine($event)"
          nzBorderless
          nzShowSearch
          nzPlaceHolder="Grand domaine"
          [nzDisabled]="!((domaines$ | async)?.grandDomaine | size)"
          nzAllowClear
          nzServerSearch
        >
          <nz-option
            *ngFor="let item of (domaines$ | async)?.grandDomaine"
            [nzLabel]="item?.libelle"
            [nzValue]="item"
          ></nz-option>
        </nz-select>
      </div>
    </div>
    <div class="w-6/12">
      <label class="label required">Domaine professionnel</label>
      <div class="select-menu">
        <nz-select
          [(ngModel)]="selectedDomaineProfessionnels"
          (ngModelChange)="onSelectDomaineProfessionnel($event, showMetiers)"
          nzBorderless
          nzShowSearch
          nzPlaceHolder="Domaine professional"
          [nzDisabled]="
            !(
              (domainesProfessionnels$ | async)
                ?.domainesProfessionnelsByGrandDomaine | size
            )
          "
          nzAllowClear
        >
          <nz-option
            *ngFor="
              let item of (domainesProfessionnels$ | async)
                ?.domainesProfessionnelsByGrandDomaine
            "
            [nzLabel]="item?.libelle"
            [nzValue]="item"
          ></nz-option>
        </nz-select>
      </div>
    </div>
  </div>

  <div class="w-full flex items-center justify-start" *ngIf="showMetiers">
    <div [class]="(metier$ | async) ? 'w-6/12 mr-2' : 'w-full'">
      <label class="label required w-full">Métiers</label>
      <div class="select-menu">
        <nz-select
          [(ngModel)]="selectedMetiers"
          (ngModelChange)="onSelectMetiers($event, showSoftskills)"
          nzBorderless
          nzShowSearch
          nzPlaceHolder="Métiers..."
          [nzDisabled]="!((metiers$ | async)?.metiersByDomaine | size)"
          nzAllowClear
          nzServerSearch
        >
          <nz-option
            *ngFor="let item of (metiers$ | async)?.metiersByDomaine"
            [nzLabel]="item?.libelle"
            [nzValue]="item"
          ></nz-option>
        </nz-select>
      </div>
    </div>
    <div [class]="showQualification && (metier$ | async) ? 'w-6/12' : 'hidden'">
      <label class="label required">Qualification métier</label>
      <div class="select-menu">
        <nz-select
          [(ngModel)]="selectedQualification"
          *ngIf="metier$ | async as metier"
          (ngModelChange)="onSelectQualification($event, metier.code)"
          nzBorderless
          nzShowSearch
          nzPlaceHolder="Qualification métier..."
          [nzDisabled]="!(qualifications$ | async | size)"
          nzAllowClear
        >
          <nz-option
            *ngFor="let item of qualifications$ | async"
            [nzLabel]="item?.libelleCaracteristique"
            [nzValue]="item"
          ></nz-option>
        </nz-select>
      </div>
    </div>
  </div>

  <nz-space
    nzDirection="vertical"
    nzAlign="center"
    *ngIf="metier$ | async"
  ></nz-space>
  <ng-container *ngIf="metier$ | async as metier">
    <div class="w-full">
      <div class="tagSelection">
        <strong class="tagSelectionText"
          >Métier : {{ metier?.libelle | carLimitation : 80 }}</strong
        >
        <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
        <strong class="tagSelectionText">Rome : {{ metier?.code }}</strong>
      </div>
    </div>

    <!-- <nz-space nzDirection="horizontal" [nzSize]="250" nzAlign="center"></nz-space>

  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col [nzSpan]="18">
      <label></label>
      <nz-tag nzColor="geekblue">
        <strong *ngIf="fap$ | async as fap"
          >FAP : {{ fap.fap.libelle | carLimitation: 50 }}
        </strong>
      </nz-tag>
    </nz-col>
    <nz-col [nzSpan]="6">
      <label></label>
      <nz-tag nzColor="geekblue">
        <strong *ngIf="fap$ | async as fap"
          >FAP CODE : {{ fap.fap.code }}
        </strong>
      </nz-tag>
    </nz-col>
  </nz-row> -->
    <div class="w-full flex flex-col">
      <label class="label required">Appellation</label>
      <div class="select-menu">
        <nz-select
          nzBorderless
          [(ngModel)]="selectedAppelations"
          (ngModelChange)="onSelectAppelation($event)"
          nzPlaceHolder="Sélectionner une appellation"
          nzServerSearch
          [disabled]="!selectedQualification"
        >
          <nz-option
            *ngFor="let item of metier.appellations"
            [nzValue]="item"
            [nzLabel]="item.libelle"
          >
            {{ item.libelle }}
          </nz-option>
        </nz-select>
      </div>
    </div>

    <nz-space nzDirection="vertical" [nzSize]="300" nzAlign="center"></nz-space>

    <nz-divider></nz-divider>
    <div class="w-full" *ngIf="appelation$ | async as appellation">
      <div class="tagSelection">
        <strong class="tagSelectionText"
          >Appellation : {{ appellation.libelle | carLimitation : 80 }}
        </strong>
        <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>

        <strong class="tagSelectionText">Code : {{ appellation.code }} </strong>
      </div>
    </div>
  </ng-container>

  <nz-divider></nz-divider>
</div>

<nz-row nzAlign="middle" nzGutter="8" nzJustify="end">
  <nz-col nzSpan="16"></nz-col>
  <nz-col nzSpan="4">
    <button (click)="onCancel()" class="btn-default-text" *ngIf="hideCancel">
      Annuler
    </button>
  </nz-col>
  <nz-col nzSpan="4">
    <button
      (click)="onValidation()"
      *ngIf="hideValidation && !showAppelationDetail"
      class="btn-primary"
    >
      Valider
    </button>

    <ng-container *ngIf="metier$ | async as metier">
      <ng-container *ngIf="fap$ | async as fap">
        <button
          *ngIf="
            hideValidation &&
            showAppelationDetail &&
            showQualification &&
            (appelation$ | async) as appelation
          "
          class="btn-primary"
          [disabled]="!metierForm.valid"
          (click)="
            onValidationMetierFapDetail(metier, fap, appelation, experience)
          "
        >
          Valider
        </button>
      </ng-container>
    </ng-container>

    <button
      class="btn-primary"
      *ngIf="
        hideValidation &&
        showAppelationDetail &&
        !showQualification &&
        (metier$ | async) as metier
      "
      (click)="onValidationMetierDetail(metier)"
    >
      Valider
    </button>
  </nz-col>
</nz-row>
