import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-item-header',
  template: `
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="20">
        <h3
          nz-typography
          *ngIf="title !== select && colorText"
          class="coloredText"
        >
          {{ title }}
        </h3>
        <h3 nz-typography *ngIf="title !== select && !colorText">
          {{ title }}
        </h3>
        <h3 nz-typography *ngIf="title === select">{{ title }}</h3>
      </nz-col>
      <nz-col nzSpan="4">
        <nz-badge
          *ngIf="showCount"
          [nzCount]="source | size"
          [nzOverflowCount]="over"
          [nzStyle]="color ? null : styleBadgeNeutral"
        ></nz-badge>
      </nz-col>
    </nz-row>
  `,
  styles: [
    `
      :host {
        width: 50%;
      }

      h4 {
        color: #ffab00;
      }
      .coloredText {
        color: rgb(145, 145, 145);
      }
    `,
  ],
})
export class ItemHeaderComponent implements OnInit {
  styleBadgeNeutral = {
    backgroundColor: '#fff',
    color: '#999',
    boxShadow: '0 0 0 1px #d9d9d9 inset',
  };

  @Input() source: any[] = [];
  @Input() showCount: boolean = true;
  @Input() title: string = '';
  @Input() select: string = '';
  @Input() color: boolean = false;
  @Input() colorText: boolean = false;
  @Input() over: number = 20;

  constructor() {}

  ngOnInit(): void {}
}
