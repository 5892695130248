<nz-divider [nzText]="metierTitle"></nz-divider>

<ng-template #metierTitle>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="8">
      <nz-badge [nzCount]="total$ | async" [nzOffset]="[14, 0]">
        <span>Métiers</span>
      </nz-badge>
    </nz-col>
  </nz-row>
</ng-template>

<nz-row
  nzAlign="middle"
  nzGutter="8"
  nzJustify="start"
  class="sugegstionsContainer"
>
  <nz-col [nzSpan]="(loading$ | async) ? 2 : 0">
    <i
      nz-icon
      nzType="loading"
      class="iconLoading"
      *ngIf="loading$ | async"
    ></i>
  </nz-col>
  <nz-col [nzSpan]="(loading$ | async) ? 22 : 24" class="borderlessContainer">
    <nz-select
      nzBorderless
      [(ngModel)]="metierModel"
      (ngModelChange)="onSelect($event)"
      nzPlaceHolder="Métier"
    >
      <nz-option
        *ngFor="
          let item of suggestions$ | async as suggestions;
          trackBy: trackByFn
        "
        [nzValue]="item"
        [nzLabel]="
          item?.label_rome
            ? (item.label_rome | carLimitation: 60)
            : item?.libelle
            ? (item.libelle | carLimitation: 60)
            : ''
        "
      ></nz-option>
    </nz-select>
  </nz-col>
</nz-row>

<nz-divider [nzText]="appelationTitle"></nz-divider>
<ng-template #appelationTitle>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="24">
      <nz-badge [nzCount]="appelations$ | async | size" [nzOffset]="[14, 0]">
        <span>Appelations</span>
      </nz-badge>
    </nz-col>
  </nz-row>
</ng-template>
<nz-row
  nzAlign="middle"
  nzGutter="8"
  nzJustify="start"
  *ngIf="appelations$ | async | size"
>
  <nz-col nzSpan="24" class="borderlessContainer">
    <nz-select
      nzBorderless
      [(ngModel)]="appelationModel"
      (ngModelChange)="onSelectAppelation($event)"
      nzPlaceHolder="Appelations"
    >
      <nz-option
        *ngFor="
          let item of appelations$ | async as appelations;
          trackBy: trackAppelationByFn
        "
        [nzValue]="item"
        [nzLabel]="item?.libelle | carLimitation: 100"
      ></nz-option>
    </nz-select>
  </nz-col>
</nz-row>

<nz-divider nzPlain></nz-divider>
<nz-row
  nzAlign="middle"
  nzGutter="8"
  nzJustify="start"
  *ngIf="appelationsSelected?.length"
>
  <nz-col nzSpan="18">
    <nz-badge [nzCount]="appelationsSelected.length" [nzOffset]="[14, 0]">
      <h5 nz-typography>Sélections</h5>
    </nz-badge>
  </nz-col>

  <nz-col nzSpan="3">
    <button
      nz-button
      nzType="primary"
      nzShape="round"
      nzBlock
      *ngIf="appelationsSelected?.length"
      [disabled]="!appelationsSelected.length"
      nz-tooltip
      [nzTooltipMouseEnterDelay]="0.5"
      nzTooltipTitle="Confirmer la sélection"
      (click)="$event.stopPropagation(); onConfirmMetiers()"
    >
      Ajouter
    </button>
  </nz-col>
  <nz-col nzSpan="3">
    <button
      nz-button
      nzType="text"
      nzBlock
      *ngIf="appelationsSelected?.length"
      [disabled]="!appelationsSelected.length"
      nz-tooltip
      [nzTooltipMouseEnterDelay]="0.5"
      nzTooltipTitle="Retirer toutes les sélections"
      (click)="$event.stopPropagation(); onRemoveAllAppelations()"
    >
      <p nz-typography>Retirer</p>
    </button>
  </nz-col>
</nz-row>
<nz-list *ngIf="appelationsSelected?.length; else noDataSelection">
  <nz-list-item *ngFor="let item of appelationsSelected">
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="20">
        <nz-tag class="appelationSelected" nzColor="geekblue">{{
          item?.libelle
        }}</nz-tag>
      </nz-col>
      <nz-col nzSpan="2"></nz-col>
      <nz-col nzSpan="2">
        <button
          nz-button
          nzShape="circle"
          nzType="text"
          nzDanger
          (click)="$event.stopPropagation(); onRemoveAppelation(item)"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </nz-col>
    </nz-row>
  </nz-list-item>
</nz-list>

<ng-template #noDataAppelation>
  <nz-card nzBorderless class="emptyCard">
    <h4 nz-typography class="contentEmpty">Aucune trouvée</h4>
  </nz-card>
</ng-template>

<ng-template #noDataSelection>
  <nz-card nzBorderless>
    <div class="center">
      <mat-icon class="iconEmpty"> business_center </mat-icon>
    </div>
    <nz-space nzSize="middle"></nz-space>
    <div class="center">
      <h4 nz-typography class="contentEmpty">Aucun métier ajouté</h4>
    </div>
  </nz-card>
</ng-template>
