import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import {
  BehaviorSubject,
  filter,
  map,
  Observable,
  of,
  Subject,
  takeUntil,
} from 'rxjs';
import { UtilitiesState } from 'src/app/contents/components/utilities/store/utilities.reducer';
import * as fromUtilitiesSelector from 'src/app/contents/components/utilities/store/utilities.selectors';
import * as fromUtilitiesAction from 'src/app/contents/components/utilities/store/utilities.actions';
import { Timestamp } from 'firebase/firestore';
import { ParticipantAccompagnementState } from './../../../participant-actions-accompagnement/store/participant-action-accompagnement.reducer';
import * as fromAcccompagnementAction from './../../../participant-actions-accompagnement/store/participant-action-accompagnement.actions';
import * as fromAcccompagnementSelector from './../../../participant-actions-accompagnement/store/participant-action-accompagnement.selectors';

@Component({
  selector: 'app-participant-diagnostic-form-definition',
  templateUrl: './participant-diagnostic-form-definition.component.html',
  styleUrls: ['./participant-diagnostic-form-definition.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantDiagnosticFormDefinitionComponent
  implements OnInit, OnChanges, OnDestroy
{
  subscribe = new Subject();
  types$: Observable<{ label: string; value: string }[] | any> = of([]);
  besoins$: Observable<any> = of([]);

  dateFormat: string = 'dd/MM/yyy HH:mm';
  timeFormat = 'HH:mm';
  today: Date = new Date(Date.now());
  nzFilterOption = (): boolean => true;

  //FORM FIELDS
  definitionForm: FormGroup = this.fb.group({});
  besoins: FormControl<any> = new FormControl([], Validators.required);
  presentation: FormControl<string | any> = new FormControl('');

  dateStart: FormControl<Date | any> = new FormControl(
    this.today,
    Validators.required
  );

  @Input() definition$ = new BehaviorSubject<any>(null);
  @Output() add = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private utilitiesStore: Store<UtilitiesState>,
    private actionAccompagnementStore: Store<ParticipantAccompagnementState>
  ) {}

  ngOnInit(): void {
    this.loadAccompagnementChampsTypes();
    this.getAccompagnementChampsTypes();
    this.form();
    this.onFormChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getAccompagnementChampsTypes(): void {
    this.besoins$ = this.actionAccompagnementStore.select(
      fromAcccompagnementSelector.champs
    );
  }

  loadAccompagnementChampsTypes(): void {
    this.actionAccompagnementStore.dispatch(
      fromAcccompagnementAction.loadParticipantAccompagnementChampsTypes()
    );
  }

  form(): void {
    this.formInit();
    this.formCreate();
    this.formSet();
  }

  formInit(): void {
    this.besoins = new FormControl([], Validators.required);
    this.presentation = new FormControl('');
    this.dateStart = new FormControl(this.today, Validators.required);
  }

  formCreate(): void {
    this.definitionForm = this.fb.group({
      besoins: this.besoins,
      presentation: this.presentation,
      dateStart: this.dateStart,
    });
  }

  formSet(): void {
    this.definition$
      .pipe(takeUntil(this.subscribe))
      .subscribe((updateValue) => {
        if (!updateValue) return;
        const { type, besoins, presentation, dateStart } = updateValue;
        this.definitionForm.patchValue({
          type,
          besoins,
          presentation,
          dateStart,
        });
      });
  }

  //COMPARE UPDATE
  compareTypes(obj1: any, obj2: any) {
    return obj1 && obj2 && obj1.label === obj2.label;
  }

  compareBesoins(obj1: any, obj2: any) {
    return obj1 && obj2 && obj1.libelle === obj2.libelle;
  }

  search(event: string): void {
    if (!event.length) {
      this.getAccompagnementChampsTypes();
      return;
    }

    const input = event.toLowerCase();

    this.besoins$ = this.actionAccompagnementStore
      .select(fromAcccompagnementSelector.champs)
      .pipe(
        takeUntil(this.subscribe),
        map((snap) => {
          if (!snap?.length) {
            return this.getAccompagnementChampsTypes();
          }

          return snap.map((el) => el.libelle.toLowerCase().includes(input));
        })
      );
  }

  onFormChanges(): void {
    this.definitionForm.valueChanges
      .pipe(takeUntil(this.subscribe))
      .subscribe((values) => {
        const { dateStart } = values;
        const start: Timestamp | any = dateStart
          ? Timestamp.fromDate(dateStart)
          : { seconds: 0, nanoseconds: 0 };
        const nextValues = {
          ...values,
          dateStart: start,
        };
        this.add.emit(nextValues);
      });
  }
}
