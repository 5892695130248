import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import { Observable, of } from 'rxjs';
import { OperationId } from 'src/app/components/operation/store/operation.model';

@Component({
  selector: 'app-operation-team-card',
  templateUrl: './operation-team-card.component.html',
  styleUrls: ['./operation-team-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationTeamCardComponent implements OnInit {
  bgColor: string = '#bfbfbf';
  @Input() item: any;
  @Input() operation: any;
  @Input() user$: Observable<any> = of(null);
  @Output() select = new EventEmitter<any>(false);
  @Output() delete = new EventEmitter<any>(false);

  constructor() {}

  ngOnInit(): void {}

  onSelect(operation: any, item: any): void {
    this.select.emit({ operation, item });
  }

  onAction(event: string, operation: OperationId, item: any): void {
    if (!event || !operation || !item) return;
    switch (event) {
      case 'delete':
        this.onDelete(operation, item);
        break;

      default:
        break;
    }
  }

  onDelete(operation: any, item: any): void {
    this.delete.emit({ operation, item });
  }

  onCancel(): void {
    return;
  }
}
