import { FavorisService } from './../../../service/favoris.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NzMessageService } from 'ng-zorro-antd/message';
import { of } from 'rxjs';
import { catchError, mergeMap, map, tap, exhaustMap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import * as fromFavoriAction from './favorie.actions';
import { FavoriState } from './favorie.reducer';

@Injectable()
export class FavorieEffects {
  loadEntites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromFavoriAction.loadEntites),
      mergeMap((action) =>
        this.favoriService.getEntites().pipe(
          map((entites: any) => {
            return fromFavoriAction.loadEntitesSuccess({ entites });
          }),
          catchError((error) =>
            of(fromFavoriAction.loadEntitesFailure({ error }))
          )
        )
      )
    )
  );
  loadParticipants$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromFavoriAction.loadParticipants),
      mergeMap((action) =>
        this.favoriService.getParticipants().pipe(
          map((participants: any) => {
            return fromFavoriAction.loadParticipantsSuccess({ participants });
          }),
          catchError((error) =>
            of(fromFavoriAction.loadParticipantsFailure({ error }))
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private favoriService: FavorisService,
    private message: NzMessageService
  ) {}
}
