<ng-container *ngIf="departement$ | async as departement">
  <app-layout-grid [details]="details"></app-layout-grid>

  <ng-template #details>
    <nz-card nzBorderless>
      <app-departement-details
        [departement]="departement$ | async"
        (update)="onSetUpdate($event)"
        (newMember)="onNewMember(departement)"
      ></app-departement-details>
    </nz-card>
  </ng-template>
</ng-container>

<ng-template #departementFormTitle>
  <app-header-title
    [title]="'Service'"
    [icon]="'group_work'"
    [subtitle]="true"
  ></app-header-title>
</ng-template>

<ng-template #departementNewMemberTitle>
  <app-header-title
    [title]="'Collaborateurs'"
    [icon]="'group'"
    [subtitle]="true"
  ></app-header-title>
</ng-template>
