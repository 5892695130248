import { createAction, props } from '@ngrx/store';

import { EntiteSearchFilter, EntiteSearchResult } from './entite-search.model';

export const loadingEntiteSearch = createAction(
  '[EntiteSearch/COMPONENT] Loading EntiteSearchs',
  props<{ loading: boolean }>()
);
export const loadEntiteSearch = createAction(
  '[EntiteSearch/COMPONENT] Load EntiteSearchs',
  props<{
    filter: EntiteSearchFilter;
  }>()
);

export const loadEntiteSearchSuccess = createAction(
  '[EntiteSearch/API] Load EntiteSearchs success',
  props<{ result: EntiteSearchResult }>()
);

export const loadEntiteSearchFailure = createAction(
  '[EntiteSearch/API] Load EntiteSearchs failure',
  props<{ error: any }>()
);

//LOAD ETABLISSEMENT
export const loadEtablissement = createAction(
  '[EntiteSearch/API] Load Etablissement',
  props<{ siret: string }>()
);
export const loadEtablissementSuccess = createAction(
  '[EntiteSearch/API] Load Etablissement success',
  props<{ etablissement: any }>()
);

export const loadEtablissementFailure = createAction(
  '[EntiteSearch/API] Load Etablissement failure',
  props<{ error: any }>()
);

export const loadEntiteSearchClear = createAction(
  '[EntiteSearch/API] Load EntiteSearchs failure'
);
