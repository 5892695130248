import {
  NzNotificationPlacement,
  NzNotificationService,
} from 'ng-zorro-antd/notification';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  mergeMap,
  exhaustMap,
  map,
  catchError,
  debounceTime,
  distinctUntilChanged,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { EntiteSearchService } from '../service/entite-search.service';
import * as fromEntiteSearchActions from './entite-search.actions';

const NOTIFICATION_PLACEMENT: NzNotificationPlacement = 'bottomRight';

@Injectable()
export class EntiteSearchEffects {
  loadEtablissements$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromEntiteSearchActions.loadEntiteSearch),
      debounceTime(2000),
      distinctUntilChanged(),
      mergeMap((action) =>
        this.entiteSearchService.getEntreprises(action.filter).pipe(
          map((response) => {
            const result = response.data?.entreprisesUniteLegale || [];

            return fromEntiteSearchActions.loadEntiteSearchSuccess({ result });
          }),
          catchError((error) => {
            this.notification.error('Erreur', error.message, {
              nzDuration: 6000,
              nzKey: 'ETABLISSEMENTS SEARCH',
              nzPlacement: NOTIFICATION_PLACEMENT,
            });
            return of(
              fromEntiteSearchActions.loadEntiteSearchFailure({ error })
            );
          })
        )
      )
    )
  );

  loadEtablissement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromEntiteSearchActions.loadEtablissement),
      mergeMap((action) =>
        this.entiteSearchService.getEtablissementBySiret(action.siret).pipe(
          map((response) => {
            const etablissement = response.data.etablissementBySiret;

            return fromEntiteSearchActions.loadEtablissementSuccess({
              etablissement,
            });
          }),
          catchError((error) => {
            this.notification.error('Erreur', error.message, {
              nzDuration: 6000,
              nzKey: 'ETABLISSEMENT SEARCH',
              nzPlacement: NOTIFICATION_PLACEMENT,
            });
            return of(
              fromEntiteSearchActions.loadEtablissementFailure({ error })
            );
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private entiteSearchService: EntiteSearchService,
    private notification: NzNotificationService
  ) {}
}
