<nz-table
  #filterTable
  nzShowSizeChanger
  [nzData]="listOfData"
  nzTableLayout="fixed"
  [nzNoResult]="noData"
  nzSize="small"
  [nzPageSize]="10"
  [nzScroll]="{ y: '240px' }"
  [nzTitle]="tableHeader"
>
  <thead>
    <tr>
      <th
        *ngFor="let column of listOfColumns"
        [nzSortOrder]="column.sortOrder"
        [nzSortFn]="column.sortFn"
        [nzSortDirections]="column.sortDirections"
        [nzFilterMultiple]="column.filterMultiple"
        [nzFilters]="column.listOfFilter"
        [nzFilterFn]="column.filterFn"
      >
        {{ column.name }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of filterTable.data">
      <td>
        <strong>{{ data.title | titlecase | carLimitation: 15 }}</strong>
      </td>
      <td>{{ data?.objectifs | number: "1.1-2":"fr" }}</td>
      <td>{{ data?.realisation | number: "1.1-2":"fr" }}</td>
      <td>
        <nz-tag *ngIf="data.progression >= 1" nzColor="green">{{
          data?.progression | percent
        }}</nz-tag>
        <nz-tag
          *ngIf="data.progression >= 0.5 && data.progression < 1"
          nzColor="orange"
          >{{ data?.progression | percent }}</nz-tag
        >
        <nz-tag
          *ngIf="data.progression > 0 && data.progression < 0.5"
          nzColor="geekblue"
          >{{ data?.progression | percent }}</nz-tag
        >
        <nz-tag *ngIf="data.progression === 0" nzColor="default">{{
          data?.progression | percent
        }}</nz-tag>
      </td>
      <td>{{ data.participants | number: "1.0":"fr" }}</td>
      <td>
        <mat-icon
          [ngClass]="{
            green: data?.collaboration === 3,
            alert: data?.collaboration === 2,
            warning:
              data?.collaboration === 1 ||
              data?.collaboration === 0 ||
              !data?.collaboration
          }"
          >flag</mat-icon
        >
      </td>
    </tr>
  </tbody>
</nz-table>

<ng-template #noData>
  <app-no-result [description]="'Aucun module'"></app-no-result>
</ng-template>

<ng-template #tableHeader></ng-template>
