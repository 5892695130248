import { ItemEventId } from '../../../features/suivi/components/item-event/itemEvent.model';
import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Operation, OperationId, OperationSmallId } from './operation.model';
import * as OperationActions from './operation.actions';

export const operationsFeatureKey = 'operations';

export interface OperationState extends EntityState<OperationSmallId> {
  operation: OperationId | any;
  activeRouteTitle: string;
  filters: any;
  suivis: ItemEventId[] | any;
  suivi: ItemEventId | any;
  documents: any;
  document: any;
  loading: boolean;
  entites: any;
  entitesSelection: any;
  entite: any;
  contacts: any;
  contact: any;
  participants: any;
  participant: any;
  missions: any;
  mission: any;
  statistiques: any;
  updateLoading: boolean;
  error: any;
}

export const adapter: EntityAdapter<OperationSmallId> =
  createEntityAdapter<OperationSmallId>();

export const initialState: OperationState = adapter.getInitialState({
  operation: undefined,
  activeRouteTitle: '',
  filters: undefined,
  suivis: undefined,
  suivi: undefined,
  documents: undefined,
  document: undefined,
  loading: false,
  entites: undefined,
  entitesSelection: undefined,
  entite: undefined,
  contacts: undefined,
  contact: undefined,
  participants: undefined,
  participant: undefined,
  missions: undefined,
  mission: undefined,
  statistiques: undefined,
  updateLoading: false,
  error: undefined,
});

export const reducer = createReducer(
  initialState,
  on(OperationActions.loadOperations, (state, action) => {
    return {
      ...state,
      operation: undefined,
      suivis: undefined,
      suivi: undefined,
      documents: undefined,
      document: undefined,
      entites: undefined,
      entite: undefined,
      contacts: undefined,
      contact: undefined,
      participants: undefined,
      participant: undefined,
      error: undefined,
      loading: true,
    };
  }),
  on(OperationActions.loadOperationsSuccess, (state, action) =>
    adapter.setAll(action.operations, state)
  ),
  on(OperationActions.loadOperations, (state, action) => {
    return {
      ...state,
      operation: undefined,
      loading: false,
    };
  }),

  on(OperationActions.loadOperationsFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
      loading: false,
    };
  }),
  on(OperationActions.setActviteRoute, (state, action) => {
    return {
      ...state,
      activeRouteTitle: action.title,
    };
  }),

  on(OperationActions.loadOperationSuccess, (state, action) => {
    return {
      ...state,
      operation: action.operation,
    };
  }),
  on(OperationActions.loadOperationFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //ADD
  on(OperationActions.addOperationFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  //UPDATE
  on(OperationActions.updateOperationFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  //DELETE
  on(OperationActions.deleteOperationFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //FILTERS
  on(OperationActions.loadOperationFiltersSuccess, (state, action) => {
    return {
      ...state,
      filters: action.filters,
    };
  }),
  on(OperationActions.loadOperationFiltersFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //QUERY
  on(OperationActions.queryOperationsSuccess, (state, action) =>
    adapter.setAll(action.operations, state)
  ),
  on(OperationActions.queryOperationsFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  on(OperationActions.updateOperation, (state, action) =>
    adapter.updateOne(action.operation, state)
  ),
  on(OperationActions.deleteOperation, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(OperationActions.deleteAllOperations, (state) => adapter.removeAll(state)),

  // on(OperationActions.clearOperations, (state) => adapter.removeAll(state)),
  // on(OperationActions.clearOperation, (state, action) => {
  //   return {
  //     ...state,
  //     operation: undefined,
  //   };
  // }),

  //LOADING
  on(OperationActions.loading, (state, action) => {
    return {
      ...state,
      loading: action.loading,
    };
  }),
  //SUIVIS
  on(OperationActions.loadSuivisSuccess, (state, action) => {
    return {
      ...state,
      suivis: action.suivis,
    };
  }),
  on(OperationActions.loadSuivisFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  // on(OperationActions.addSuiviSuccess, (state, action) => {
  //   return {
  //     ...state,
  //     suivis: [...state.suivis, action.suivi],
  //   };
  // }),
  on(OperationActions.addSuiviFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  // on(OperationActions.updateSuivi, (state, action) => {
  //   const removePrev = state.suivis.filter(
  //     (el: any) => el.id !== action.suivi.changes.id
  //   );
  //   return {
  //     ...state,
  //     suivis: [...removePrev, action.suivi.changes],
  //   };
  // }),
  on(OperationActions.updateSuiviFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  on(OperationActions.deleteSuivi, (state, action) => {
    return {
      ...state,
      suivis: state.suivis.filter((el: any) => el.id !== action.suiviId),
    };
  }),
  on(OperationActions.deleteSuiviFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //DOCUMENTS
  on(OperationActions.loadDocumentsSuccess, (state, action) => {
    return {
      ...state,
      documents: action.documents,
    };
  }),
  on(OperationActions.loadDocumentsFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  // on(OperationActions.addDocumentsSuccess, (state, action) => {
  //   return {
  //     ...state,
  //     documents: action.documents,
  //   };
  // }),
  on(OperationActions.addDocumentsFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  on(OperationActions.deleteDocument, (state, action) => {
    return {
      ...state,
      documents: state.documents.filter(
        (doc: any) => doc.id !== action.document.id
      ),
    };
  }),
  on(OperationActions.deleteDocumentFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //ENTITES
  //load entites
  on(OperationActions.loadEntitesOperationSuccess, (state, action) => {
    return {
      ...state,
      entites: action.entites,
    };
  }),
  on(OperationActions.loadEntitesOperationFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  //load entite
  on(OperationActions.loadEntiteOperationSuccess, (state, action) => {
    return {
      ...state,
      entite: action.entite,
    };
  }),
  on(OperationActions.loadEntiteOperationFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  //load entite selection
  on(OperationActions.loadSelectorEntitesOperationSuccess, (state, action) => {
    return {
      ...state,
      entitesSelection: action.entites,
    };
  }),
  on(OperationActions.loadEntiteOperationFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  //add entite
  on(OperationActions.loadSelectorEntitesOperationFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  //delete entite
  on(OperationActions.deleteEntiteOperation, (state, action) => {
    return {
      ...state,
      entites: state.entites.filter((doc: any) => doc.id !== action.entiteId),
    };
  }),
  on(OperationActions.deleteEntiteOperationFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //CONTACTS
  //load contacts
  on(OperationActions.loadContactsOperationSuccess, (state, action) => {
    return {
      ...state,
      contacts: action.contacts,
    };
  }),
  on(OperationActions.loadContactsOperationFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  //add contacts
  on(OperationActions.addContactsOperationFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  //delete contact
  on(OperationActions.deleteContactOperation, (state, action) => {
    return {
      ...state,
      contacts: state.contacts.filter(
        (doc: any) => doc.id !== action.contactId
      ),
    };
  }),
  on(OperationActions.deleteContactOperationFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //PARTICIPANT
  //load participants
  on(OperationActions.loadParticipantsOperationSuccess, (state, action) => {
    return {
      ...state,
      participants: action.participants,
    };
  }),
  on(OperationActions.loadParticipantsOperationFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  //load participant
  on(OperationActions.loadParticipantOperationSuccess, (state, action) => {
    return {
      ...state,
      participant: action.participant,
    };
  }),
  on(OperationActions.loadParticipantOperationFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  //Add
  on(OperationActions.addParticipantOperationFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  //delete participant
  on(OperationActions.deleteParticipantOperation, (state, action) => {
    return {
      ...state,
      participants: state.participants.filter(
        (doc: any) => doc.id !== action.participantId
      ),
    };
  }),
  on(OperationActions.deleteParticipantOperationFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //MISSIONS
  on(OperationActions.loadOperationMissions, (state, action) => {
    return {
      ...state,
      updateLoading: true,
    };
  }),
  on(OperationActions.loadOperationMissionsSuccess, (state, action) => {
    return {
      ...state,
      missions: action.missions,
      updateLoading: false,
    };
  }),
  on(OperationActions.loadOperationMissionsFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
      updateLoading: false,
    };
  }),
  on(OperationActions.addOperationMission, (state, action) => {
    return {
      ...state,
      updateLoading: true,
    };
  }),
  on(OperationActions.addOperationMissionSuccess, (state, action) => {
    return {
      ...state,
      updateLoading: false,
    };
  }),
  on(OperationActions.addOperationMissionFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
      updateLoading: false,
    };
  }),
  on(OperationActions.updateOperationMission, (state, action) => {
    return {
      ...state,
      updateLoading: true,
    };
  }),
  on(OperationActions.updateOperationMissionSuccess, (state, action) => {
    return {
      ...state,
      updateLoading: false,
    };
  }),
  on(OperationActions.updateOperationMissionFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
      updateLoading: false,
    };
  }),
  on(OperationActions.deleteOperationMission, (state, action) => {
    return {
      ...state,
      missions: state.missions.filter((el: any) => el.id !== action.id),
      updateLoading: false,
    };
  }),
  on(OperationActions.deleteSuiviFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
      updateLoading: false,
    };
  }),

  //MISSION
  on(OperationActions.loadOperationMission, (state, action) => {
    return {
      ...state,
      updateLoading: true,
    };
  }),
  on(OperationActions.loadOperationMissionSuccess, (state, action) => {
    return {
      ...state,
      mission: action.mission,
      updateLoading: false,
    };
  }),
  on(OperationActions.loadOperationMissionFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
      updateLoading: false,
    };
  }),
  on(OperationActions.clearOperationMission, (state, action) => {
    return {
      ...state,
      mission: undefined,
      updateLoading: false,
    };
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
