import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'topScore',
})
export class TopScorePipe implements PipeTransform {
  transform(values: any[]): any {
    if (!values?.length) return [];

    const result: any[] = values.filter(
      (item: any) => Number(item.score) >= 0.5
    );

    return result;
  }
}
