import { ProjetProfessionnelId } from './../../../participant/store/participant.model';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-projet-professionnel-info',
  templateUrl: './projet-professionnel-info.component.html',
  styleUrls: ['./projet-professionnel-info.component.scss'],
})
export class ProjetProfessionnelInfoComponent implements OnInit {
  employablity: string = 'Employabilité';
  @Input() item: ProjetProfessionnelId | any;
  constructor() {}

  ngOnInit(): void {}
}
