<form nz-form [formGroup]="droitForm">
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="12">
      <app-check-card
        [title]="'Ne connaît pas'"
        [isActive]="droitForm.value.neConnaitSesDroitsAucuneDemarche"
        [definition]="neConnaitSesDroitsAucuneDemarcheTitle"
        [height]="'50px'"
        [fontSize]="'14px'"
        (select)="checkboxChanges($event, 'neConnaitSesDroitsAucuneDemarche')"
      ></app-check-card>
    </nz-col>
    <nz-col nzSpan="12">
      <app-check-card
        [title]="'Difficulté de renouvellement'"
        [isActive]="droitForm.value.difficulteDeRenouvellementDeDroit"
        [definition]="difficulteDeRenouvellementDeDroitTitle"
        [height]="'50px'"
        [fontSize]="'14px'"
        (select)="checkboxChanges($event, 'difficulteDeRenouvellementDeDroit')"
      ></app-check-card>
    </nz-col>
  </nz-row>
  <nz-space nzSize="small" nzDirection="vertical"></nz-space>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="12">
      <app-check-card
        [title]="'Démarche en cours'"
        [isActive]="droitForm.value.connaitSesDroitsDemarcheEnCours"
        [definition]="connaitSesDroitsDemarcheEnCoursTitle"
        [height]="'50px'"
        [fontSize]="'14px'"
        (select)="checkboxChanges($event, 'connaitSesDroitsDemarcheEnCours')"
      ></app-check-card>
    </nz-col>
    <nz-col nzSpan="12">
      <app-check-card
        [title]="'Bénéficie de ses droits'"
        [isActive]="droitForm.value.beneficieDeSesDroits"
        [definition]="beneficieDeSesDroitsTitle"
        [height]="'50px'"
        [fontSize]="'14px'"
        (select)="checkboxChanges($event, 'beneficieDeSesDroits')"
      ></app-check-card>
    </nz-col>
  </nz-row>

  <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="8">
      <app-check-card
        [title]="'Droit de travail'"
        [isActive]="droitForm.value.droitDeTravail"
        [definition]="droitDeTravailTitle"
        [height]="'140px'"
        [fontSize]="'14px'"
        (select)="checkboxChanges($event, 'droitDeTravail')"
      ></app-check-card>
    </nz-col>
    <nz-col nzSpan="8">
      <app-check-card
        [title]="'Droit à l\'image'"
        [isActive]="droitForm.value.droitAimage"
        [definition]="droitAimageTitle"
        [height]="'140px'"
        [fontSize]="'14px'"
        (select)="checkboxChanges($event, 'image')"
      ></app-check-card>
    </nz-col>
    <nz-col nzSpan="8">
      <app-check-card
        [title]="'Consentement RGPD'"
        [isActive]="droitForm.value.consentementRGPD"
        [definition]="consentementRgpdTitle"
        [height]="'140px'"
        [fontSize]="'14px'"
        (select)="checkboxChanges($event, 'rgpd')"
      ></app-check-card>
    </nz-col>
  </nz-row>

  <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="24">
      <nz-form-item>
        <nz-form-label nzSpan="24">Précision</nz-form-label>
        <nz-form-control>
          <input
            nz-input
            nzBorderless
            formControlName="precision"
            nzBorderless
            placeholder="Précisez la difficulté..."
          />
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>

  <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="24">
      <nz-form-item>
        <nz-form-control>
          <label nz-checkbox formControlName="isFrein">{{
            isFreinTitle
          }}</label>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>
</form>
