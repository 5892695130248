import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, of, lastValueFrom, from } from 'rxjs';
import { ParticipantAccompagnementState } from '../../../components/participant-actions-accompagnement/store/participant-action-accompagnement.reducer';
import * as fromParticipantAccompagnementSelector from '../../../components/participant-actions-accompagnement/store/participant-action-accompagnement.selectors';
import * as fromParticipantAccompagnementAction from '../../../components/participant-actions-accompagnement/store/participant-action-accompagnement.actions';

@Injectable({
  providedIn: 'root',
})
export class ParticipantActionResolverService {
  constructor(
    private participantActionAccompagnementStore: Store<ParticipantAccompagnementState>,
    private router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    let id = route.parent?.params['id'];
    let itemId = route.params['itemId'];

    let find = lastValueFrom(this.loadParticipantAccompagnement(id, itemId))
      .then(() => {
        return this.getParticipantAccompagnement();
      })
      .catch(() => {
        this.router.navigate([`/participant/:${id}/actions`]);
        return EMPTY;
      });

    return from(find);
  }

  getParticipantAccompagnement(): Observable<any> {
    return this.participantActionAccompagnementStore.select(
      fromParticipantAccompagnementSelector.accompagnement
    );
  }

  loadParticipantAccompagnement(id: string, itemId: string): Observable<any> {
    return of(
      this.participantActionAccompagnementStore.dispatch(
        fromParticipantAccompagnementAction.loadParticipantActionAccompagnement(
          { participantId: id, id: itemId }
        )
      )
    );
  }
}
