import { OperationId } from './../../operation/store/operation.model';
import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { OperationTask, OperationTaskId } from './operation-task.model';

//LOADING
export const loadingOperationTasks = createAction(
  '[OperationTask/API] Loading OperationTasks',
  props<{ loading: boolean }>()
);

//LOAD ALL
export const loadOperationTasks = createAction(
  '[OperationTask/API] Load OperationTasks',
  props<{ operationId: string }>()
);
export const loadOperationTasksSuccess = createAction(
  '[OperationTask/API] Load OperationTasks Success',
  props<{ tasks: OperationTaskId[] }>()
);
export const loadOperationTasksFailure = createAction(
  '[OperationTask/API] Load OperationTasks Failure',
  props<{ error: any }>()
);

//LOAD ONE
export const loadOperationTask = createAction(
  '[OperationTask/API] Load OperationTask',
  props<{ operationId: string; id: string }>()
);
export const loadOperationTaskSuccess = createAction(
  '[OperationTask/API] Load OperationTask Success',
  props<{ task: OperationTaskId }>()
);
export const loadOperationTaskFailure = createAction(
  '[OperationTask/API] Load OperationTask Failure',
  props<{ error: any }>()
);

//ADD
export const addOperationTask = createAction(
  '[OperationTask/API] Add OperationTask',
  props<{ operation: OperationId; task: OperationTask }>()
);
export const addOperationTaskSuccess = createAction(
  '[OperationTask/API] Add OperationTask Success',
  props<{ success: string }>()
);
export const addOperationTaskFailure = createAction(
  '[OperationTask/API] Add OperationTask Failure',
  props<{ error: any }>()
);

//UPDATE
export const updateOperationTask = createAction(
  '[OperationTask/API] Update OperationTask',
  props<{ operationTask: Update<OperationTaskId>; updateType?: string }>()
);
export const updateOperationTaskSuccess = createAction(
  '[OperationTask/API] Update OperationTask Success',
  props<{ success: string }>()
);
export const updateOperationTaskFailure = createAction(
  '[OperationTask/API] Update OperationTask Failure',
  props<{ error: any }>()
);

//DELETE ONE
export const deleteOperationTask = createAction(
  '[OperationTask/API] Delete OperationTask',
  props<{ operationId: string; id: string }>()
);
export const deleteOperationTaskSuccess = createAction(
  '[OperationTask/API] Delete OperationTask Success',
  props<{ success: string }>()
);
export const deleteOperationTaskFailure = createAction(
  '[OperationTask/API] Delete OperationTask Failure',
  props<{ error: any }>()
);

//DELETE ALL
export const deleteOperationTasks = createAction(
  '[OperationTask/API] Delete OperationTasks',
  props<{ ids: string[]; operationId: string }>()
);
export const deleteOperationTasksSuccess = createAction(
  '[OperationTask/API] Delete OperationTasks Success',
  props<{ success: string }>()
);
export const deleteOperationTasksFailure = createAction(
  '[OperationTask/API] Delete OperationTasks Failure',
  props<{ error: any }>()
);

//CLEAR
export const clearOperationTasks = createAction(
  '[OperationTask/API] Clear OperationTasks'
);

//QUERIES

//ALL MY TAKS
export const loadOperationMyTasks = createAction(
  '[OperationTask/API] Load OperationMyTasks',
  props<{ operationId: string }>()
);
export const loadOperationMyTasksSuccess = createAction(
  '[OperationTask/API] Load OperationMyTasks Success',
  props<{ tasks: OperationTaskId[] }>()
);
export const loadOperationMyTasksFailure = createAction(
  '[OperationTask/API] Load OperationMyTasks Failure',
  props<{ error: any }>()
);

//ALL TODAY TASKS
export const loadOperationTodayTasks = createAction(
  '[OperationTask/API] Load OperationTodayTasks',
  props<{ operationId: string }>()
);
export const loadOperationTodayTasksSuccess = createAction(
  '[OperationTask/API] Load OperationTodayTasks Success',
  props<{ tasks: OperationTaskId[] }>()
);
export const loadOperationTodayTasksFailure = createAction(
  '[OperationTask/API] Load OperationTodayTasks Failure',
  props<{ error: any }>()
);

//ALL WEEK TASKS
export const loadOperationWeekTasks = createAction(
  '[OperationTask/API] Load OperationWeekTasks',
  props<{ operationId: string }>()
);
export const loadOperationWeekTasksSuccess = createAction(
  '[OperationTask/API] Load OperationWeekTasks Success',
  props<{ tasks: OperationTaskId[] }>()
);
export const loadOperationWeekTasksFailure = createAction(
  '[OperationTask/API] Load OperationWeekTasks Failure',
  props<{ error: any }>()
);

//ALL MONTH TASKS
export const loadOperationMonthTasks = createAction(
  '[OperationTask/API] Load OperationMonthTasks',
  props<{ operationId: string }>()
);
export const loadOperationMonthTasksSuccess = createAction(
  '[OperationTask/API] Load OperationMonthTasks Success',
  props<{ tasks: OperationTaskId[] }>()
);
export const loadOperationMonthTasksFailure = createAction(
  '[OperationTask/API] Load OperationMonthTasks Failure',
  props<{ error: any }>()
);
