import { Observable, of, takeUntil, Subject } from 'rxjs';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { AidState } from '../../store/aids.reducer';
import * as fromAidsAction from '../../store/aids.actions';
import * as fromAidsSelector from '../../store/aids.selectors';

@Component({
  selector: 'app-aids-filters',
  templateUrl: './aids-filters.component.html',
  styleUrls: ['./aids-filters.component.scss'],
})
export class AidsFiltersComponent implements OnInit, OnDestroy {
  filters$: Observable<any> = of(null);
  loading$: Observable<boolean> = of(false);

  selected: string = '';
  subscribe = new Subject();

  aidsFiltersForm: UntypedFormGroup = new UntypedFormGroup({});
  age: UntypedFormControl = new UntypedFormControl();
  inscriptionPeriod: UntypedFormControl = new UntypedFormControl();
  category: UntypedFormControl = new UntypedFormControl();
  diploma: UntypedFormControl = new UntypedFormControl();
  allocationType: UntypedFormControl = new UntypedFormControl();
  monthlyAllocationValue: UntypedFormControl = new UntypedFormControl();
  locationCitycode: UntypedFormControl = new UntypedFormControl();
  filters: UntypedFormControl = new UntypedFormControl();
  disabled: UntypedFormControl = new UntypedFormControl();
  spectacle: UntypedFormControl = new UntypedFormControl();

  @Input() selectedAids: any[] = [];
  @Input() aids$: Observable<any> = of(null);
  @Input() searchLoading$: Observable<boolean> = of(false);

  @Output() filtersList = new EventEmitter<any>(false);
  @Output() validationAll = new EventEmitter<any>(false);
  constructor(private fb: UntypedFormBuilder, private aidsStore: Store<AidState>) {}

  ngOnInit(): void {
    this.form();
    this.getAidsFiltersLoading();
    this.getAidsFilters();
    this.loadAidsFilters();
  }

  getAidsFilters(): void {
    this.filters$ = this.aidsStore.select(fromAidsSelector.filters);
  }
  getAidsFiltersLoading(): void {
    this.loading$ = this.aidsStore.select(fromAidsSelector.loading);
  }

  loadAidsFilters(): void {
    this.aidsStore.dispatch(fromAidsAction.loadAidsFilters());
  }

  form(): void {
    this.formInit();
    this.formCreate();
  }

  formInit(): void {
    this.age = new UntypedFormControl('', Validators.required);
    this.inscriptionPeriod = new UntypedFormControl('', Validators.required);
    this.category = new UntypedFormControl('', Validators.required);
    this.diploma = new UntypedFormControl();
    this.allocationType = new UntypedFormControl();
    this.monthlyAllocationValue = new UntypedFormControl();
    this.locationCitycode = new UntypedFormControl();
    this.filters = new UntypedFormControl();
    this.disabled = new UntypedFormControl(false, Validators.required);
    this.spectacle = new UntypedFormControl(false, Validators.required);
  }

  formCreate(): void {
    this.aidsFiltersForm = this.fb.group({
      age: this.age,
      inscriptionPeriod: this.inscriptionPeriod,
      category: this.category,
      diploma: this.diploma,
      allocationType: this.allocationType,
      monthlyAllocationValue: this.monthlyAllocationValue,
      locationCitycode: this.locationCitycode,
      filters: this.filters,
      disabled: this.disabled,
      spectacle: this.spectacle,
    });
  }

  onSelect(item: any): void {}

  onTag(event: boolean, type: string): void {
    this.aidsFiltersForm.patchValue({
      [`${type}`]: event,
    });
  }

  onValidationAll(aidsList: any[]): void {
    this.validationAll.emit(aidsList);
  }

  onChanges(): void {
    const values = this.aidsFiltersForm.value;
    if (!values) return;
    const age = values?.age ? values.age : null;
    const inscriptionPeriod = values.inscriptionPeriod?.value
      ? values.inscriptionPeriod.value
      : '';
    const category = values?.category?.value ? values.category.value : '';
    const diploma = values?.diploma?.value ? values.diploma.value : '';
    const allocationType = values?.allocationType?.value
      ? values.allocationType.value
      : '';
    const monthlyAllocationValue = values?.monthlyAllocationValue
      ? values.monthlyAllocationValue.toString()
      : null;
    const locationCitycode = values?.locationCitycode
      ? values.locationCitycode
      : '';
    const filters = values.filters?.slug ? values.filters?.slug : '';
    const disabled = values?.disabled ? values.disabled : false;
    const spectacle = values?.spectacle ? values.spectacle : false;
    const filtersList = {
      age,
      inscriptionPeriod,
      category,
      diploma,
      allocationType,
      monthlyAllocationValue,
      locationCitycode,
      filters,
      disabled,
      spectacle,
    };

    this.filtersList.emit(filtersList);
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }
}
