<div class="w-full flex items-center justify-between space-x-4 -m-3">
  <div class="w-2/12 flex items-center">
    <nz-badge
      [nzCount]="operations$ | async | size"
      *ngIf="filtersActivated"
      nzStandalone="true"
      [nzOffset]="[-20, 0]"
    ></nz-badge>
  </div>

  <div class="w-8/12">
    <app-search-bar
      [filter]="true"
      [cleaner]="cleaner"
      (clear)="onClear()"
      [placeholder]="'Rechercher par le titre du projet'"
      (searching)="filtersByTitle($event)"
    ></app-search-bar>
  </div>

  <div class="w-2/12 flex items-center">
    <!-- <button
      nz-popover
      [nzPopoverContent]="contentFilterTemplate"
      nzPopoverPlacement="bottomRight"
      nzPopoverTrigger="click"
      class=btn-icon"
    >
      <i nz-icon nzType="filter"></i>
    </button> -->
    <!-- <button class="btn-icon" *ngIf="filtersActivated">
      <i nz-icon nzType="download"></i>
    </button> -->
  </div>
</div>
