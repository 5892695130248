<form
  nz-form
  [formGroup]="subtitleForm"
  nzLayout="vertical"
  *ngIf="note$ | async as note"
>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="24">
      <nz-form-item>
        <nz-form-control>
          <input
            nz-input
            formControlName="title"
            nzBorderless
            placeholder="Titre"
            (keyup.enter)="onAdd(note)"
          />
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>

  <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="12">
      <button nz-button nzBlock nzType="text" (click)="onCancel()">
        Annuler
      </button>
    </nz-col>
    <nz-col nzSpan="12">
      <button
        nz-button
        nzBlock
        nzType="primary"
        (click)="onAdd(note)"
        [disabled]="!subtitleForm.valid"
        *ngIf="isAdd"
      >
        Ajouter
      </button>
      <ng-container *ngIf="isUpdate">
        <button
          nz-button
          nzBlock
          nzType="primary"
          *ngIf="subtitle$ | async as subtitle"
          [disabled]="!subtitleForm.valid"
          (click)="onUpdate(subtitle)"
        >
          Modifier
        </button>
      </ng-container>
    </nz-col>
  </nz-row>
</form>
