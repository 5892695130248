import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { OperationCadrage } from './operation-cadrage.model';
import * as OperationCadrageActions from './operation-cadrage.actions';

export const operationCadragesFeatureKey = 'operationCadrages';

export interface OperationCadrageState extends EntityState<OperationCadrage> {
  cadrage: OperationCadrage | any;
  loading: boolean;
  error: any;
}

export const adapter: EntityAdapter<OperationCadrage> =
  createEntityAdapter<OperationCadrage>();

export const initialState: OperationCadrageState = adapter.getInitialState({
  cadrage: undefined,
  loading: false,
  error: undefined,
});

export const reducer = createReducer(
  initialState,
  on(OperationCadrageActions.loadOperationCadrageSuccess, (state, action) => {
    return {
      ...state,
      cadrage: action.cadrage,
    };
  }),
  on(OperationCadrageActions.loadOperationCadrageFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  on(OperationCadrageActions.updateOperationCadrage, (state, action) =>
    adapter.updateOne(action.cadrage, state)
  ),
  on(OperationCadrageActions.updateOperationCadrageFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
