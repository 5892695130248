import { QueryMissionsService } from './../../service/query-missions.service';
import { QueryModulesService } from './../../service/query-modules.service';
import { ModuleStorageService } from './../../service/module-storage.service';
import { ModuleSuivisService } from './../../service/module-suivis.service';
import { ModuleService } from './../../service/module.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { ContentsModule } from './../../contents/contents.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ModuleRoutingModule } from './module-routing.module';
import { ModuleListComponent } from './components/module-list/module-list.component';
import { ModuleItemComponent } from './components/module-item/module-item.component';
import { ModuleFormComponent } from './components/module-form/module-form.component';
import { StoreModule } from '@ngrx/store';
import * as fromModule from './store/module.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ModuleEffects } from './store/module.effects';
import { ModuleCardComponent } from './components/module-card/module-card.component';
import { ModuleFiltersComponent } from './components/module-filters/module-filters.component';
import { ModuleSelectComponent } from './components/module-select/module-select.component';
import { EntiteModule } from '../entite/entite.module';
import { ModuleDetailsComponent } from './components/module-details/module-details.component';
import { ModuleItemHeaderComponent } from './components/module-item-header/module-item-header.component';
import { ModulesTableComponent } from './components/modules-table/modules-table.component';
import { ModuleDashboardComponent } from './components/module-dashboard/module-dashboard.component';
import { ModuleImportExcelComponent } from './components/module-import-excel/module-import-excel.component';
import { NoResultModule } from 'src/app/contents/components/no-result/no-result.module';
import { CoordonneesModule } from 'src/app/contents/components/coordonnees/coordonnees.module';
import { CommunesFranceModule } from 'src/app/contents/components/communes-france/communes-france.module';
import { ButtonsModule } from 'src/app/contents/components/buttons/buttons.module';
import { HeaderModule } from 'src/app/contents/components/header-title/header.module';
import { LayoutModule } from 'src/app/contents/components/layout/layout.module';
import { AvatarModule } from 'src/app/features/avatar/avatar.module';
import { SuiviModule } from 'src/app/features/suivi/suivi.module';
import { DocumentModule } from 'src/app/features/document/document.module';
import { UiModule } from 'src/app/ui/ui/ui.module';

@NgModule({
  declarations: [
    ModuleListComponent,
    ModuleItemComponent,
    ModuleFormComponent,
    ModuleCardComponent,
    ModuleFiltersComponent,
    ModuleSelectComponent,
    ModuleDetailsComponent,
    ModuleItemHeaderComponent,
    ModulesTableComponent,
    ModuleDashboardComponent,
    ModuleImportExcelComponent,
  ],
  imports: [
    CommonModule,
    ModuleRoutingModule,
    ContentsModule,
    SharedModule,
    EntiteModule,
    NoResultModule,
    CoordonneesModule,
    HeaderModule,
    CommunesFranceModule,
    ButtonsModule,
    LayoutModule,
    AvatarModule,
    SuiviModule,
    DocumentModule,
    UiModule,
    StoreModule.forFeature(fromModule.modulesFeatureKey, fromModule.reducer),
    EffectsModule.forFeature([ModuleEffects]),
  ],
  exports: [
    ModuleListComponent,
    ModuleItemComponent,
    ModuleFormComponent,
    ModuleFiltersComponent,
    ModuleSelectComponent,
    ModulesTableComponent,
  ],
  providers: [
    ModuleService,
    ModuleSuivisService,
    ModuleStorageService,
    QueryModulesService,
    QueryMissionsService,
  ],
})
export class ModuleModule {}
