import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-charts-indicators',
  templateUrl: './charts-indicators.component.html',
  styleUrls: ['./charts-indicators.component.scss'],
})
export class ChartsIndicatorsComponent implements OnInit {
  @Input() total: number = 0;
  @Input() objectif: number = 0;
  @Input() type: string = '';
  @Input() extraInfo1: string = '';
  @Input() extraInfo2: string = '';
  @Input() orientation: string = 'vertical';
  @Input() showObjectif: boolean = true;

  constructor() {}

  ngOnInit(): void {}
}
