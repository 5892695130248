<nz-row
  *ngIf="entite$ | async as entite"
  nzAlign="middle"
  nzGutter="8"
  nzJustify="end"
  class="rowTitle"
>
  <nz-col nzXl="1" nzXXl="1" nzLg="0" nzXs="0" nzSm="0" nzMd="0">
    <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
  </nz-col>
  <nz-col nzXl="1" nzXXl="1" nzLg="0" nzXs="0" nzSm="0" nzMd="0">
    <mat-icon class="itemTypeIcon">domaine</mat-icon>
  </nz-col>

  <nz-col nzXl="15" nzXXl="15" nzLg="24" nzXs="24" nzSm="24" nzMd="24">
    <h5 nz-typography (click)="onSelectAction('details', entite)">
      {{ entite?.etablissement?.denomination | carLimitation: 50 }}
    </h5>
  </nz-col>

  <nz-col nzXl="1" nzXXl="1" nzLg="0" nzXs="0" nzSm="0" nzMd="0">
    <div class="dropdown_action">
      <app-button-dropdown
        [isDelete]="true"
        [deleteType]="deleteType"
        (select)="onAction($event, entite)"
      ></app-button-dropdown>
    </div>
  </nz-col>
  <nz-col nzXl="1" nzXXl="1" nzLg="0" nzXs="0" nzSm="0" nzMd="0">
    <!-- <nz-divider nzOrientation="center" nzType="vertical"></nz-divider> -->
  </nz-col>
  <nz-col nzXl="1" nzXXl="1" nzLg="0" nzXs="0" nzSm="0" nzMd="0">
    <!-- <button
      *ngIf="operation"
      nz-button
      class="btn_action"
      nzShape="circle"
      nz-tooltip
      [nzTooltipMouseEnterDelay]="0.5"
      nzTooltipTitle="Gantt"
      nzPopconfirmPlacement="bottom"
      nzType="text"
      (click)="onSelectAction('gantt', operation)"
    >
      <i nz-icon nzType="bar-chart" [nzRotate]="90" class="icon_item"></i>
    </button> -->
  </nz-col>

  <nz-col nzXl="2" nzXXl="2" nzLg="0" nzXs="0" nzSm="0" nzMd="0">
    <!-- <button
      class="btn_action"
      nz-button
      nzShape="circle"
      nzType="text"
      nz-dropdown
      [nzDropdownMenu]="operationHeaderActions"
      nzPlacement="bottomCenter"
      nz-tooltip
      [nzTooltipMouseEnterDelay]="0.5"
      nzTooltipTitle="Plus d'actions"
      nzPopconfirmPlacement="bottom"
      nzTrigger="click"
    >
      <i nz-icon nzType="appstore" class="icon_item"></i>
    </button> -->
  </nz-col>

  <nz-col nzXl="1" nzXXl="1" nzLg="0" nzXs="0" nzSm="0" nzMd="0">
    <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
  </nz-col>
</nz-row>
