import { ItemEventId } from '../../../features/suivi/components/item-event/itemEvent.model';
import { ItemEvent } from 'src/app/features/suivi/components/item-event/itemEvent.model';
import { statistiques } from './../../user/store/user.selectors';
import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Contact, ContactId, ContactSMALLid } from './contact.model';
import { OperationSmallId } from '../../operation/store/operation.model';
import { EntiteSMALLid } from '../../entite/store/entite.model';

export const loadContacts = createAction('[Contact/COMPONENT] Load Contacts');
export const loadContactsSuccess = createAction(
  '[Contact/API] Load Contacts Success',
  props<{ contacts: ContactSMALLid[] }>()
);
export const loadContactsFailure = createAction(
  '[Contact/API] Load Contacts Failure',
  props<{ error: any }>()
);

export const loadContact = createAction(
  '[Contact/COMPONENT] Load Contact',
  props<{ id: string }>()
);
export const loadContactSuccess = createAction(
  '[Contact/API] Load Contact Success',
  props<{ contact: ContactId }>()
);
export const loadContactFailure = createAction(
  '[Contact/API] Load Contact Failure',
  props<{ error: any }>()
);

export const loadContactStatistiques = createAction(
  '[Contact/COMPONENT] Load Contact statistiques',
  props<{ id: string }>()
);
export const loadContactStatistiquesSuccess = createAction(
  '[Contact/API] Load Contact statistiques Success',
  props<{ statistiques: any }>()
);
export const loadContactStatistiquesFailure = createAction(
  '[Contact/API] Load Contact statistiques Failure',
  props<{ error: any }>()
);

//FILTERS
export const loadContactsFilters = createAction(
  '[Contact/COMPONENT] Load Contacts Filters'
);
export const loadContactsFiltersSuccess = createAction(
  '[Contact/API] Load Contacts Filters Success',
  props<{ filters: any }>()
);
export const loadContactsFiltersFailure = createAction(
  '[Contact/API] Load Contacts Filters Failure',
  props<{ error: any }>()
);

//QUERY
export const queryContacts = createAction(
  '[Contact/COMPONENT] Query Contacts',
  props<{ filter: { event: string; type: string } }>()
);
export const queryContactsSuccess = createAction(
  '[Contact/API] Query Contacts Success',
  props<{ contacts: ContactSMALLid[] }>()
);
export const queryContactsFailure = createAction(
  '[Contact/API] Query Contacts Failure',
  props<{ error: any }>()
);

//ADD

export const addContact = createAction(
  '[Contact/COMPONENT] Add Contact',
  props<{ contact: Contact }>()
);
export const addContactConfirmation = createAction(
  '[Contact/Effect] Add Contact confirmation',
  props<{ contact: Contact; user: any }>()
);

export const addContactSuccess = createAction(
  '[Contact/API] Add Contact Success',
  props<{ contact: ContactSMALLid }>()
);
export const addContactFailure = createAction(
  '[Contact/API] Add Contact Failure',
  props<{ error: any }>()
);

export const updateContact = createAction(
  '[Contact/API] Update Contact',
  props<{ contact: Update<ContactId> }>()
);
export const updateContactSuccess = createAction(
  '[Contact/API] Update Contact Success',
  props<{ success: string }>()
);
export const updateContactFailure = createAction(
  '[Contact/API] Update Contact Failure',
  props<{ error: any }>()
);

export const deleteContact = createAction(
  '[Contact/API] Delete Contact',
  props<{ id: string }>()
);

export const deleteContactSuccess = createAction(
  '[Contact/API] Delete Contact Success',
  props<{ success: string }>()
);
export const deleteContactFailure = createAction(
  '[Contact/API] Delete Contact',
  props<{ error: any }>()
);

export const deleteContacts = createAction(
  '[Contact/API] Delete Contacts',
  props<{ ids: string[] }>()
);

export const clearContacts = createAction('[Contact/API] Clear Contacts');

//SUIVIS
//load suivis
export const loadSuivis = createAction(
  '[Contact/COMPONENT] Load Suivis Contact',
  props<{ contactId: string }>()
);

export const loadSuivisSuccess = createAction(
  '[Contact/API] Load Suivis Contact Success',
  props<{ suivis: any }>()
);

export const loadSuivisFailure = createAction(
  '[Contact/API] Load Suivis Contact Failure',
  props<{ error: any }>()
);

//add suivi
export const addSuivi = createAction(
  '[Contact/COMPONENT] Add Suivi Contact',
  props<{ suivi: ItemEvent; contactId: string }>()
);

export const addSuiviSuccess = createAction(
  '[Contact/API] Add Suivi Contact Success',
  props<{ suivi: ItemEventId }>()
);

export const addSuiviFailure = createAction(
  '[Contact/API] Add Suivi Contact Failure',
  props<{ error: any }>()
);

//update suivi
export const updateSuivi = createAction(
  '[Contact/COMPONENT] Update Suivi Contact',
  props<{ suivi: Update<ItemEventId>; contactId: string }>()
);

export const updateSuiviSuccess = createAction(
  '[Contact/API] Update Suivi Contact Success',
  props<{ suivi: ItemEventId }>()
);

export const updateSuiviFailure = createAction(
  '[Contact/API] Update Suivi Contact Failure',
  props<{ error: any }>()
);
//delete suivi
export const deleteSuivi = createAction(
  '[Contact/COMPONENT] Delete Suivi Contact',
  props<{ suiviId: string; contactId: string }>()
);

export const deleteSuiviSuccess = createAction(
  '[Contact/API] Delete Suivi Contact Success',
  props<{ success: string }>()
);

export const deleteSuiviFailure = createAction(
  '[Contact/API] Delete Suivi Contact Failure',
  props<{ error: any }>()
);

//DOCUMENTS
export const loading = createAction(
  '[Contact/COMPONENT] Loading Contact',
  props<{ loading: boolean }>()
);
export const loadFileProgress = createAction(
  '[Contact/API] File upload progreess Contact',
  props<{ progress: any }>()
);

//load documents
export const loadDocuments = createAction(
  '[Contact/COMPONENT] Load Documents Contact',
  props<{ contactId: string }>()
);

export const loadDocumentsSuccess = createAction(
  '[Contact/API] Load Documents Contact Success',
  props<{ documents: any }>()
);

export const loadDocumentsFailure = createAction(
  '[Contact/API] Load Documents Contact Failure',
  props<{ error: any }>()
);

//add document
export const addDocuments = createAction(
  '[Contact/COMPONENT] Add Documents Contact',
  props<{ contact: ContactId; documents: any[] }>()
);

export const addDocumentsSuccess = createAction(
  '[Contact/API] Add Documents Contact Success',
  props<{ documents: any[] }>()
);

export const addDocumentsFailure = createAction(
  '[Contact/API] Add Documents Contact Failure',
  props<{ error: any }>()
);

//delete document
export const deleteDocument = createAction(
  '[Contact/COMPONENT] Delete Document Contact',
  props<{ contactId: string; document: any }>()
);

export const deleteDocumentSuccess = createAction(
  '[Contact/API] Delete Document Contact Success',
  props<{ success: string }>()
);

export const deleteDocumentFailure = createAction(
  '[Contact/API] Delete Document Contact Failure',
  props<{ error: any }>()
);

//OPERATIONS
//load
export const loadOperations = createAction(
  '[Contact/COMPONENT] Load Operation Contact',
  props<{ contactId: string }>()
);

export const loadOperationsSuccess = createAction(
  '[Contact/API] Load Operation Contact Success',
  props<{ operations: any[] }>()
);

export const loadOperationsFailure = createAction(
  '[Contact/API] Load Operation Contact Failure',
  props<{ error: any }>()
);

//add
export const addOperation = createAction(
  '[Contact/COMPONENT] Add Operation Contact',
  props<{
    contact: ContactId;
    operation: OperationSmallId;
    intervention: any;
  }>()
);

export const addOperationSuccess = createAction(
  '[Contact/API] Add Operation Contact Success',
  props<{ success: string }>()
);

export const addOperationFailure = createAction(
  '[Contact/API] Add Operation Contact Failure',
  props<{ error: any }>()
);

//update
export const updateOperation = createAction(
  '[Contact/COMPONENT] Update Operation Contact',
  props<{
    contact: ContactId;
    operation: Update<any>;
  }>()
);

export const updateOperationSuccess = createAction(
  '[Contact/API] Update Operation Contact Success',
  props<{ success: string }>()
);

export const updateOperationFailure = createAction(
  '[Contact/API] Update Operation Contact Failure',
  props<{ error: any }>()
);

//delete
export const deleteOperation = createAction(
  '[Contact/COMPONENT] Delete Operation Contact',
  props<{ contact: ContactId; operation: OperationSmallId }>()
);

export const deleteOperationSuccess = createAction(
  '[Contact/API] Delete Operation Contact Success',
  props<{ success: string }>()
);

export const deleteOperationFailure = createAction(
  '[Contact/API] Delete Operation Contact Failure',
  props<{ error: any }>()
);

//ENTITES
//load
export const loadContactEntites = createAction(
  '[Contact/COMPONENT] Load Contact Entites',
  props<{ contact: ContactId }>()
);

export const loadContactEntitesSuccess = createAction(
  '[Contact/API] Load Contact Entites Success',
  props<{ entites: EntiteSMALLid[] }>()
);

export const loadContactEntitesFailure = createAction(
  '[Contact/API] Load Contact Entites Failure',
  props<{ error: any }>()
);

//add
export const addContactEntites = createAction(
  '[Contact/COMPONENT] Add Contact Entites',
  props<{ contact: ContactId; entite: EntiteSMALLid }>()
);

export const addContactEntitesSuccess = createAction(
  '[Contact/API] Add Contact Entites Success',
  props<{ success: string }>()
);

export const addContactEntitesFailure = createAction(
  '[Contact/API] Add Contact Entites Failure',
  props<{ error: any }>()
);

//delete
export const deleteContactEntites = createAction(
  '[Contact/COMPONENT] Delete Contact Entites',
  props<{ contact: ContactId; entite: EntiteSMALLid }>()
);

export const deleteContactEntitesSuccess = createAction(
  '[Contact/API] Delete Contact Entites Success',
  props<{ success: string }>()
);

export const deleteContactEntitesFailure = createAction(
  '[Contact/API] Delete Contact Entites Failure',
  props<{ error: any }>()
);

//METIERS
export const clearContact = createAction('[Contact/API] clear Contact');
