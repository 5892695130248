import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'any',
})
export class QueryEntiteSiretServiceGQL extends Query<any> {
  document = gql`
    query EtablissementBySiret($siret: String!) {
      etablissementBySiret(siret: $siret) {
        activitePrincipaleRegistreMetiersEtablissement
        adresse2Etablissement {
          complementAdresseEtablissement
          numeroVoieEtablissement
          indiceRepetitionEtablissement
          typeVoieEtablissement
          libelleVoieEtablissement
          codePostalEtablissement
          libelleCommuneEtablissement
          libelleCommuneEtrangerEtablissement
          distributionSpecialeEtablissement
          codeCommuneEtablissement
          codeCedexEtablissement
          libelleCedexEtablissement
          codePaysEtrangerEtablissement
          libellePaysEtrangerEtablissement
          fullAdresse
          commune {
            code
            nom
            codesPostaux
            siren
            codeEpci
            codeDepartement
            codeRegion
            epci {
              code
              nom
              type
              financement
              codesDepartements
              codesRegions
              population
              surface
              centre
              contour
              bbox
            }
            departement {
              code
              nom
              codeRegion
              region {
                code
                nom
              }
            }
            region {
              code
              nom
            }
            population
            surface
            centre
            contour
            mairie
            bbox
          }
        }
        adresseEtablissement {
          complementAdresseEtablissement
          numeroVoieEtablissement
          indiceRepetitionEtablissement
          typeVoieEtablissement
          libelleVoieEtablissement
          codePostalEtablissement
          libelleCommuneEtablissement
          libelleCommuneEtrangerEtablissement
          distributionSpecialeEtablissement
          codeCommuneEtablissement
          codeCedexEtablissement
          libelleCedexEtablissement
          codePaysEtrangerEtablissement
          libellePaysEtrangerEtablissement
          fullAdresse
          commune {
            code
            nom
            codesPostaux
            siren
            codeEpci
            codeDepartement
            codeRegion
            epci {
              code
              nom
              type
              financement
              codesDepartements
              codesRegions
              population
              surface
              centre
              contour
              bbox
            }
            departement {
              code
              nom
              codeRegion
              region {
                code
                nom
              }
            }
            region {
              code
              nom
            }
            population
            surface
            centre
            contour
            mairie
            bbox
          }
        }
        anneeEffectifsEtablissement
        conventionsCollectives {
          activitePrincipale
          activitePrincipaleUniteLegale
          caractereEmployeurUniteLegale
          categorieJuridiqueUniteLegale
          conventions {
            idcc
            etat
            id
            mtime
            shortTitle
            texte_de_base
            title
            url
          }
          dateCreationUniteLegale
          dateDebut
          etablissements
          etatAdministratifUniteLegale
          highlightLabel
          label
          matching
          simpleLabel
          siren
          activitePrincipaleEtablissement
          address
          categorieEntreprise
          codeCommuneEtablissement
          codePostalEtablissement
          etablissementSiege
          etatAdministratifEtablissement
          idccs
          libelleCommuneEtablissement
          siret
        }
        dateCreationEtablissement
        dateDernierTraitementEtablissement
        etablissementSiege
        nic
        nombrePeriodesEtablissement
        periodesEtablissement {
          dateFin
          dateDebut
          etatAdministratifEtablissement
          changementEtatAdministratifEtablissement
          enseigne1Etablissement
          enseigne2Etablissement
          enseigne3Etablissement
          changementEnseigneEtablissement
          denominationUsuelleEtablissement
          changementDenominationUsuelleEtablissement
          activitePrincipaleEtablissement
          nomenclatureActivitePrincipaleEtablissement
          changementActivitePrincipaleEtablissement
          caractereEmployeurEtablissement
          changementCaractereEmployeurEtablissement
        }
        siren
        siret
        statutDiffusionEtablissement
        trancheEffectifsEtablissement
        uniteLegale {
          statutDiffusionUniteLegale
          unitePurgeeUniteLegale
          dateCreationUniteLegale
          sigleUniteLegale
          sexeUniteLegale
          prenom1UniteLegale
          prenom2UniteLegale
          prenom3UniteLegale
          prenom4UniteLegale
          prenomUsuelUniteLegale
          pseudonymeUniteLegale
          identifiantAssociationUniteLegale
          trancheEffectifsUniteLegale
          anneeEffectifsUniteLegale
          dateDernierTraitementUniteLegale
          nombrePeriodesUniteLegale
          categorieEntreprise
          anneeCategorieEntreprise
          etatAdministratifUniteLegale
          nomUniteLegale
          denominationUniteLegale
          denominationUsuelle1UniteLegale
          denominationUsuelle2UniteLegale
          denominationUsuelle3UniteLegale
          activitePrincipaleUniteLegale
          activite_principale_libelle {
            intitule_naf
            intitule_naf_40
            intitule_naf_65
            code_naf
          }
          categorieJuridiqueUniteLegale
          categorie_juridique_libelle {
            libelle
            code
          }
          nicSiegeUniteLegale
          nomenclatureActivitePrincipaleUniteLegale
          nomUsageUniteLegale
          economieSocialeSolidaireUniteLegale
          societeMissionUniteLegale
          caractereEmployeurUniteLegale
        }
      }
    }
  `;
}
