<nz-ribbon
  [nzText]="ribbonText"
  nzColor="#722ed1"
  nzPlacement="start"
  *ngIf="contact | isfavori : favoris?.data"
>
  <nz-card
    [nzTitle]="contactCardTitle"
    [nzExtra]="contactActions"
    [nzCover]="contactCard__cover_tpl"
    nzHoverable
    [routerLink]="['/contact', contact.id]"
  >
  </nz-card>
</nz-ribbon>

<nz-card
  [nzTitle]="contactCardTitle"
  [nzExtra]="contactActions"
  [nzCover]="contactCard__cover_tpl"
  nzHoverable
  *ngIf="!(contact | isfavori : favoris?.data)"
  [routerLink]="['/contact', contact.id]"
>
</nz-card>

<ng-template #contactCard__cover_tpl>
  <div class="contactCard_container">
    <div class="contactCardTitle__container">
      <strong>{{ contact?.displayName | carLimitation : 25 }}</strong>
    </div>
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start" class="fonctionRow">
      <nz-col nzSpan="24">
        <small>{{
          contact?.appelationsArray?.[0]  ? (contact?.appelationsArray?.[0] | uppercase | carLimitation: 40): 'Aucune fonction'
        }}</small>
      </nz-col>
    </nz-row>

    <nz-space nzAlign="start" nzAlign="center" nzSize="middle"></nz-space>

    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col [nzSpan]="24">
        <nz-tag nzColor="geekblue">
          {{
            (contact?.entitesTitles | size)
              ? (contact?.entitesTitles[0] | carLimitation : 40)
              : "Aucune entité"
          }}
        </nz-tag>
      </nz-col>
    </nz-row>
  </div>
</ng-template>

<ng-template #contactCardTitle>
  <app-header-title [icon]="'person'"></app-header-title>
</ng-template>

<ng-template #contactActions>
  <button
    nz-button
    nzBlock
    nzSize="default"
    nzShape="circle"
    nzType="text"
    nz-dropdown
    [nzDropdownMenu]="contactActionsMenu"
    nzPlacement="bottomRight"
    nzTrigger="click"
    (click)="$event.stopPropagation()"
  >
    <mat-icon>more_vert</mat-icon>
  </button>
</ng-template>

<ng-template #ribbonText>
  <i nz-icon nzType="star"></i>
</ng-template>
<nz-dropdown-menu #contactActionsMenu="nzDropdownMenu">
  <ul nz-menu>
    <li nz-menu-item [routerLink]="['/contacts', contact.id]">
      <i nz-icon nzType="eye"></i>
      <span class="item-dropdown">Afficher</span>
    </li>
    <li nz-menu-divider></li>
    <li nz-menu-item (click)="onRemoveToFavori(contact)">
      <i nz-icon nzType="star"></i>
      <span class="item-dropdown">Retirer des favoris</span>
    </li>
    <li nz-menu-divider></li>
    <li
      nz-menu-item
      nzDanger
      nz-popconfirm
      [nzPopconfirmTitle]="
        'Voulez-vous supprimer ' + contact?.displayName + ' ?'
      "
      nzPopconfirmPlacement="bottom"
      (nzOnConfirm)="onDelete(contact)"
      (nzOnCancel)="onCancel()"
    >
      <i nz-icon nzType="delete"></i>
      <span class="item-dropdown">Supprimer</span>
    </li>
  </ul>
</nz-dropdown-menu>
