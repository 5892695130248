import { ContactSMALLid } from 'src/app/components/contact/store/contact.model';
import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { ContactState } from '../../store/contact.reducer';
import * as fromContactAction from '../../store/contact.actions';
import * as fromContactSelector from '../../store/contact.selectors';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserAction from 'src/app/components/user/store/user.actions';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';

@Component({
  selector: 'app-contact-card',
  templateUrl: './contact-card.component.html',
  styleUrls: ['./contact-card.component.scss'],
})
export class ContactCardComponent implements OnInit {
  @Input() contact: any = null;
  @Input() favoris: any;

  constructor(
    private contactStore: Store<ContactState>,
    private userStore: Store<UserState>
  ) {}

  ngOnInit(): void {}

  onDelete(contact: ContactSMALLid): void {
    if (!contact) return;
    this.contactStore.dispatch(
      fromContactAction.deleteContact({ id: contact.id })
    );
  }

  onAddToFavori(contact: ContactSMALLid): void {
    if (!contact) return;
    const { civilite, lastName, firstName } = contact;
    const displayName: string = `${civilite}. ${lastName} ${firstName}`;
    const id: string = contact.id;
    this.userStore.dispatch(
      fromUserAction.addUserFavorisContact({ id, displayName })
    );
  }
  onRemoveToFavori(contact: ContactSMALLid): void {
    if (!contact) return;
    const { civilite, lastName, firstName } = contact;
    const displayName: string = `${civilite}. ${lastName} ${firstName}`;
    const id: string = contact.id;
    this.userStore.dispatch(
      fromUserAction.removeUserFavorisContact({ id, displayName })
    );
  }

  onCancel(): void {
    return;
  }
}
