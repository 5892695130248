import { SharedModule } from './../../shared/shared.module';
import { ContentsModule } from './../../contents/contents.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromOperationParticipants from './store/operation-participants.reducer';
import { EffectsModule } from '@ngrx/effects';
import { OperationParticipantsEffects } from './store/operation-participants.effects';
import { OperationParticipantsItemComponent } from './components/operation-participants-item/operation-participants-item.component';
import { OperationParticipantsComponent } from './components/operation-participants/operation-participants.component';
import { OperationParticipantsFiltersComponent } from './components/operation-participants-filters/operation-participants-filters.component';
import { OperationParticipantsSelectorsComponent } from './components/operation-participants-selectors/operation-participants-selectors.component';
import { OperationParticipantsService } from 'src/app/service/operation-participants.service';
import { ParticipantModule } from '../participant/participant.module';
import { NoResultModule } from 'src/app/contents/components/no-result/no-result.module';
import { CoordonneesModule } from 'src/app/contents/components/coordonnees/coordonnees.module';
import { CommunesFranceModule } from 'src/app/contents/components/communes-france/communes-france.module';
import { HeaderModule } from 'src/app/contents/components/header-title/header.module';
import { LayoutModule } from 'src/app/contents/components/layout/layout.module';
import { UiModule } from 'src/app/ui/ui/ui.module';

@NgModule({
  declarations: [
    OperationParticipantsItemComponent,
    OperationParticipantsComponent,
    OperationParticipantsFiltersComponent,
    OperationParticipantsSelectorsComponent,
  ],
  imports: [
    CommonModule,
    ContentsModule,
    SharedModule,
    ParticipantModule,
    NoResultModule,
    CoordonneesModule,
    HeaderModule,
    CommunesFranceModule,
    LayoutModule,
    UiModule,
    StoreModule.forFeature(
      fromOperationParticipants.operationParticipantsesFeatureKey,
      fromOperationParticipants.reducer
    ),
    EffectsModule.forFeature([OperationParticipantsEffects]),
  ],
  exports: [
    OperationParticipantsItemComponent,
    OperationParticipantsComponent,
    OperationParticipantsFiltersComponent,
    OperationParticipantsSelectorsComponent,
  ],
  providers: [OperationParticipantsService],
})
export class OperationParticipantsModule {}
