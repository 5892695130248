import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { ModuleSmallId } from '../../store/module.model';
import { ModuleState } from '../../store/module.reducer';
import * as fromModuleAction from '../../store/module.actions';
import * as fromModuleSelector from '../../store/module.selectors';

@Component({
  selector: 'app-module-card',
  templateUrl: './module-card.component.html',
  styleUrls: ['./module-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModuleCardComponent implements OnInit, OnChanges {
  progressCompleteColor: string = '#49d49d';
  progressDefaultColor: string = '#062949';

  @Input() module: ModuleSmallId | any;
  @Output() delete: EventEmitter<ModuleSmallId | any> = new EventEmitter(false);
  @Output() select: EventEmitter<ModuleSmallId | any> = new EventEmitter(false);

  constructor(private moduleStore: Store<ModuleState>) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}
  onSelect(module: ModuleSmallId): void {
    if (!module) return;
    this.select.emit(module);
  }

  onDropdown(item: string, module: any): void {
    switch (item) {
      case 'delete':
        this.onDelete(module);
        break;

      default:
        break;
    }
  }

  onDelete(module: ModuleSmallId): void {
    if (!module) return;
    this.delete.emit(module);
  }
  onCancel(): void {}
}
