import {
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
  Router,
  Event,
  RouterOutlet,
  ChildrenOutletContexts,
  ResolveStart,
  ResolveEnd,
} from '@angular/router';
import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserState } from './components/user/store/user.reducer';
import * as fromUserAction from './components/user/store/user.actions';
import * as fromUserSelector from './components/user/store/user.selectors';

import { messageState } from './components/messages/store/message.reducer';
import * as fromMessageAction from './components/messages/store/message.actions';
import { slideInAnimation } from './app-animation';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

import {
  of,
  timer,
  concatMap,
  Subject,
  takeUntil,
  Observable,
  map,
  filter,
  merge,
} from 'rxjs';
import { AuthState } from './components/auth/store/auth.reducer';
import * as fromAuthAction from './components/auth/store/auth.actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [slideInAnimation],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Lenggo';
  userState: boolean = false;
  isDark = false;
  sideMode: boolean = true;
  sideWidth: number = 200;
  loading: boolean = false;
  source = of(1, 2, 3);
  options: AnimationOptions = {
    path: '../assets/animations/loading_animation_v2.json',
    loop: true,
    autoplay: true,
  };

  lottie: Partial<CSSStyleDeclaration> = {
    marginBottom: '-20rem !important',
  };

  subscribe = new Subject();

  animationCreated(animationItem: AnimationItem): void {}
  private _showLoaderEvent$!: Observable<boolean>;
  private _hideLoaderEvent$!: Observable<boolean>;

  constructor(
    private userStore: Store<UserState>,
    private authStore: Store<AuthState>,
    private router: Router,
    private contexts: ChildrenOutletContexts
  ) {
    router.events.subscribe((routerEevent: Event) => {
      this.checkRouterEvent(routerEevent);
    });
  }

  @HostBinding('class')
  get themMode() {
    return this.isDark ? 'theme-dark' : 'theme-ligth ';
  }

  ngOnInit() {
    this.checkCurrentUser();
  }
  ngOnDestroy(): void {
    this.subscribe.next(false);
    this.subscribe.complete();
  }

  checkRouterEvent(routerEvent: Event): void {
    if (routerEvent instanceof NavigationStart) {
      this.loading = true;
    }
    if (
      routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError
    ) {
      timer(2000)
        .pipe(concatMap(() => this.source))
        .subscribe(() => {
          this.loading = false;
        });
    }
  }

  routerResolve(): void {
    this._showLoaderEvent$ = this.router.events.pipe(
      filter((e) => e instanceof ResolveStart),
      map(() => true)
    );
    this._hideLoaderEvent$ = this.router.events.pipe(
      filter((e) => e instanceof ResolveEnd),
      map(() => false)
    );
  }

  checkCurrentUser(): void {
    this.userStore
      .select(fromUserSelector.user)
      .pipe(takeUntil(this.subscribe))
      .subscribe((user) => {
        if (!user) {
          this.authStore.dispatch(fromAuthAction.clearAuths());
          this.router.navigate(['/login']);
        } else {
          return;
        }
      });
  }

  loadMe(): void {
    this.userStore.dispatch(fromUserAction.loadMe());
  }

  getRouteAnimationData() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.[
      'animation'
    ];
  }
}
