<ng-container *ngIf="participants$ | async | size; else noParticipant">
  <app-item-tabset
    [tabs]="[
      {
        name: 'Par participants',
        template: participantsTemplate
      },
      {
        name: 'Par missions',
        template: participantsMissionsTemplate
      },
      {
        name: 'Sur objectif',
        template: null
      },
      {
        name: 'Impact 3 mois',
        template: null
      },
      {
        name: 'Impact 6 mois',
        template: null
      },
      {
        name: 'Impact 12 mois',
        template: null
      }
    ]"
  ></app-item-tabset>
  <ng-template #participantsTemplate>
    <div class="Operation_Dashboard_Container">
      <nz-row nzJustify="center" nzAlign="middle" nzGutter="2">
        <nz-col nzSpan="12">
          <app-charts
            [source]="genres$"
            [icon]="'query_stats'"
            [isBorderless]="false"
            [isTitle]="true"
          ></app-charts>
        </nz-col>
        <nz-col nzSpan="12">
          <app-charts
            [source]="rqth$"
            [icon]="'query_stats'"
            [isBorderless]="false"
            [isTitle]="true"
          ></app-charts>
        </nz-col>
      </nz-row>
      <nz-space></nz-space>

      <nz-row nzJustify="center" nzAlign="middle" nzGutter="2">
        <nz-col nzSpan="12">
          <app-charts
            [source]="commune$"
            [icon]="'query_stats'"
            [isBorderless]="false"
            [isTitle]="true"
          ></app-charts>
        </nz-col>
        <nz-col nzSpan="12">
          <app-charts
            [source]="tranche$"
            [icon]="'query_stats'"
            [isBorderless]="false"
            [isTitle]="true"
          ></app-charts>
        </nz-col>
      </nz-row>
      <nz-space></nz-space>

      <nz-row nzJustify="center" nzAlign="middle" nzGutter="2">
        <nz-col nzSpan="12">
          <app-charts
            [source]="codePostal$"
            [icon]="'query_stats'"
            [isBorderless]="false"
            [isTitle]="true"
          ></app-charts>
        </nz-col>
        <nz-col nzSpan="12">
          <app-charts
            [source]="qualificationLevel$"
            [icon]="'query_stats'"
            [isBorderless]="false"
            [isTitle]="true"
          ></app-charts>
        </nz-col>
      </nz-row>
      <nz-space></nz-space>

      <nz-row nzJustify="center" nzAlign="middle" nzGutter="2">
        <nz-col nzSpan="12">
          <app-charts
            [source]="minimaSociaux$"
            [icon]="'query_stats'"
            [isBorderless]="false"
            [isTitle]="true"
          ></app-charts>
        </nz-col>
        <nz-col nzSpan="12">
          <app-charts
            [source]="francaisLevel$"
            [icon]="'query_stats'"
            [isBorderless]="false"
            [isTitle]="true"
          ></app-charts>
        </nz-col>
      </nz-row>
      <nz-space></nz-space>

      <nz-row nzJustify="center" nzAlign="middle" nzGutter="2">
        <nz-col nzSpan="12">
          <app-charts
            [source]="typeSejour$"
            [icon]="'query_stats'"
            [isBorderless]="false"
            [isTitle]="true"
          ></app-charts>
        </nz-col>
        <nz-col nzSpan="12">
          <app-charts
            [source]="francaisLevel$"
            [icon]="'query_stats'"
            [isBorderless]="false"
            [isTitle]="true"
          ></app-charts>
        </nz-col>
      </nz-row>
    </div>
  </ng-template>
  <ng-template #participantsMissionsTemplate>
    <div class="Operation_Dashboard_Container"></div>
  </ng-template>
</ng-container>
<ng-template #noParticipant>
  <app-no-result
    [icon]="'query_stats'"
    [isButton]="false"
    [title]="'Aucun participant'"
    [description]="'Aucun participant'"
  ></app-no-result>
</ng-template>
