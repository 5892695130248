<div class="container">
  <nz-list nzSize="small" nzSplit="true" nzBordered>
    <nz-list-item nzNoFlex *ngFor="let item of sujets">
      <nz-descriptions [nzTitle]="item.title" nzBordered nzSize="small">
        <nz-descriptions-item nzSpan="3" nzTitle="Note">
          <p nz-typography>{{ item.text | carLimitation: 100 }}</p>
        </nz-descriptions-item>
        <nz-descriptions-item nzSpan="1" nzTitle="Modifié le">
          {{ item?.updateAt }}
        </nz-descriptions-item>
        <nz-descriptions-item nzSpan="1" nzTitle="Modifié par">
          {{ item?.updateBy | carLimitation: 40 }}
        </nz-descriptions-item>
      </nz-descriptions>
    </nz-list-item>
  </nz-list>
</div>
<ng-template #sujetsWarningHeader>
  <app-header-title
    [icon]="''"
    [title]="'Sujets en alerte'"
    [subtitle]="true"
  ></app-header-title>
</ng-template>
