import { Injectable } from '@angular/core';
import { gql, Query } from 'apollo-angular';

@Injectable({
  providedIn: 'any',
})
export class QueryJoursContratGQLService extends Query<Response> {
  document = gql`
    query Referentiel_contrat_jours {
      referentiel_contrat_jours
    }
  `;
}
