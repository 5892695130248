import { ContentsModule } from 'src/app/contents/contents.module';
import { OperationGanttComponent } from './components/operation-gantt/operation-gantt.component';
import { OperationGanttService } from './../../service/operation-gantt.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromOperationGantt from './store/operation-gantt.reducer';
import { EffectsModule } from '@ngrx/effects';
import { OperationGanttEffects } from './store/operation-gantt.effects';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [OperationGanttComponent],
  imports: [
    CommonModule,
    ContentsModule,
    SharedModule,
    StoreModule.forFeature(
      fromOperationGantt.operationGanttsFeatureKey,
      fromOperationGantt.reducer
    ),
    EffectsModule.forFeature([OperationGanttEffects]),
  ],
  exports: [OperationGanttComponent],
  providers: [OperationGanttService],
})
export class OperationGanttModule {}
