import { Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  doc,
  docSnapshots,
  collectionChanges,
  getDoc,
  addDoc,
  setDoc,
  deleteDoc,
  serverTimestamp,
  DocumentData,
  DocumentSnapshot,
  Timestamp,
  query,
  where,
  arrayUnion,
  arrayRemove,
  collectionSnapshots,
  CollectionReference,
  DocumentReference,
  getDocs,
  documentId,
  GeoPoint,
} from '@angular/fire/firestore';
import { Observable, of, from, EMPTY } from 'rxjs';
import { switchMap, mergeMap, map, catchError } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { UserState } from '../components/user/store/user.reducer';
import * as fromUserSelector from '../components/user/store/user.selectors';
import { v4 as uuidV4 } from 'uuid';

@Injectable({
  providedIn: 'any'
})
export class ParticipantExportPptService {

  constructor() { }
}
