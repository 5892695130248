import { Subject, Observable, of } from 'rxjs';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Store, select } from '@ngrx/store';
import { FavoriState } from 'src/app/components/favories/store/favorie.reducer';
import * as fromFavoriAction from 'src/app/components/favories/store/favorie.actions';
import * as fromFavoriSelector from 'src/app/components/favories/store/favorie.selectors';
import { TodoState } from 'src/app/components/todos/store/todo.reducer';
import * as fromTodoSelector from 'src/app/components/todos/store/todo.selectors';

@Component({
  selector: 'app-user-indicators',
  templateUrl: './user-indicators.component.html',
  styleUrls: ['./user-indicators.component.scss'],
})
export class UserIndicatorsComponent implements OnInit {
  subscribe = new Subject();
  today = new Date(Date.now());
  todos$: Observable<any> = of([]);
  noTODOS: number = 0;
  todayString: string = format(Date.now(), 'd EEE', { locale: fr });
  currentWeekNumber: string = format(Date.now(), 'I', { locale: fr });
  TODOS__nowPriority: any = '';

  favorisEntites$: Observable<any> = of(null);
  favorisParticipants$: Observable<any> = of(null);

  @Output() select = new EventEmitter<string | any>(false);

  constructor(
    private favoriStore: Store<FavoriState>,
    private todoStore: Store<TodoState>
  ) {}

  ngOnInit(): void {
    this.getFavoris();
    this.loadFavoris();
  }

  onSelect(event: any): void {}

  //FAVORIS
  getFavoris(): void {
    this.getTodos();
    this.getEntites();
    this.getParticipants();
  }

  getEntites(): void {
    this.favorisEntites$ = this.favoriStore.select(fromFavoriSelector.entites);
  }
  getParticipants(): void {
    this.favorisParticipants$ = this.favoriStore.select(
      fromFavoriSelector.participants
    );
  }

  getTodos(): void {
    this.todos$ = this.todoStore.select(fromTodoSelector.todos);
  }

  loadFavoris(): void {
    this.loadEntites();
    this.loadParticipants();
  }

  loadEntites(): void {
    this.favoriStore.dispatch(fromFavoriAction.loadEntites());
  }
  loadParticipants(): void {
    this.favoriStore.dispatch(fromFavoriAction.loadParticipants());
  }
}
