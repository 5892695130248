import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-person-selector',
  templateUrl: './person-selector.component.html',
  styleUrls: ['./person-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonSelectorComponent implements OnInit, OnChanges {
  default: string = '#bfbfbf';
  avatarColor: string = 'white';
  personSelected: any = null;
  personsListSelected: any[] = [];

  @Input() loading$: Observable<boolean> = of(false);
  @Input() persons$: Observable<any> = of(null);
  @Input() mode: string = 'default';
  @Input() isFonction: boolean = false;
  @Input() isRole: boolean = false;
  @Input() isCoordonnees: boolean = false;
  @Input() placeholder: string = 'Selectionner un référent';

  @Output() select = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {}

  trackBy(index: number, item: any): number {
    return item.id;
  }

  onSelect(person: any): void {
    if (!person) return;
    this.select.emit(person);
  }
}
