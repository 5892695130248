import { OperationId } from './../components/operation/store/operation.model';
import { Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  doc,
  docSnapshots,
  collectionChanges,
  addDoc,
  setDoc,
  deleteDoc,
  serverTimestamp,
  CollectionReference,
  DocumentReference,
  DocumentChange,
  DocumentSnapshot,
  Timestamp,
  query,
  where,
  arrayUnion,
  arrayRemove,
  collectionSnapshots,
  getDocs,
  DocumentData,
} from '@angular/fire/firestore';
import { Observable, of, from, EMPTY } from 'rxjs';
import { switchMap, mergeMap, map, catchError } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Functions, httpsCallable } from '@angular/fire/functions';
import {
  Storage,
  ref,
  uploadBytesResumable,
  deleteObject,
  getDownloadURL,
  StorageReference,
  UploadTask,
  UploadMetadata,
} from '@angular/fire/storage';
import { UserState } from '../components/user/store/user.reducer';
import * as fromUserSelector from '../components/user/store/user.selectors';
import { v4 as uuidV4 } from 'uuid';
import { format, fromUnixTime } from 'date-fns';
import { fr } from 'date-fns/locale';
import { ContactSMALLid } from '../components/contact/store/contact.model';

@Injectable({
  providedIn: 'any',
})
export class OperationContactsService {
  constructor(
    private db: Firestore,
    private storage: Storage,
    private fns: Functions,
    private userStore: Store<UserState>
  ) {}

  getAll(operationId: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !operationId) of([]);

          const dbCollection: CollectionReference<DocumentData> = collection(
            this.db,
            `organisations/${user.organisation.id}/operations/${operationId}/contacts`
          );

          const changes = collectionSnapshots(dbCollection).pipe(
            map((changes) => {
              return changes.map((snap) => {
                const data = snap.data();
                const id = snap.id;
                return { id, ...data };
              });
            })
          );

          return changes;
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  getOne(operationId: string, id: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) {
            return of(null);
          } else {
            const dbCollection: CollectionReference<DocumentData> = collection(
              this.db,
              `organisations/${user.organisation.id}/operations/${operationId}/contacts`
            );

            const documentRef: DocumentReference<DocumentData> = doc(
              dbCollection,
              id
            );

            return docSnapshots(documentRef).pipe(
              map((snap: DocumentSnapshot<DocumentData>) => {
                const data = snap.data();
                const id = snap.id;
                return { id, ...data };
              }),
              catchError((error) => EMPTY)
            );
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  addOne(operationId: string, contact: any): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) {
            return of(null);
          } else {
            const dbDocument: DocumentReference<DocumentData> = doc(
              this.db,
              `organisations/${user.organisation.id}/operations/${operationId}/contacts/${contact.id}`
            );

            return from(
              setDoc(dbDocument, contact)
                .then(() => `Intervenant ajouté au projet.`)
                .catch((err) => err)
            ).pipe(mergeMap((res) => of(res)));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  addAll(operation: OperationId, contactsSelected: any[]): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user: any): any => {
          if (!user || !operation || !contactsSelected?.length) {
            return of(null);
          } else {
            const dbCollection: CollectionReference<DocumentData> = collection(
              this.db,
              `organisations/${user.organisation.id}/operations/${operation.id}/contacts`
            );

            let contacts_size: number = contactsSelected?.length;
            let contacts_AddCount: number = 0;

            if (contacts_AddCount !== contacts_size) {
              contactsSelected.forEach(
                (el: { contact: ContactSMALLid; intervention: any }) => {
                  const { contact, intervention } = el;
                  const dbDocument: DocumentReference<DocumentData> = doc(
                    dbCollection,
                    contact.id
                  );
                  const nextContact = {
                    contact: contact,
                    intervention: intervention,
                    operation: operation,
                  };

                  return from(
                    setDoc(dbDocument, nextContact)
                      .then(() => (contacts_AddCount += 1))
                      .catch((err) => err)
                  );
                }
              );
              return of(null);
            } else {
              return of(`${contacts_size} ajoutés au projet.`);
            }
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  updateOne(operationId: string, contact: any): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) {
            return of(null);
          } else {
            const dbDocument: DocumentReference<DocumentData> = doc(
              this.db,
              `organisations/${user.organisation.id}/operations/${operationId}/contacts/${contact.id}`
            );

            return from(
              setDoc(dbDocument, contact)
                .then(() => `Intervenant modifié.`)
                .catch((err) => err)
            ).pipe(mergeMap((res) => of(res)));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  deleteOne(operationId: string, id: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) {
            return of(null);
          } else {
            const dbDocument: DocumentReference<DocumentData> = doc(
              this.db,
              `organisations/${user.organisation.id}/operations/${operationId}/contacts/${id}`
            );

            return from(
              deleteDoc(dbDocument)
                .then(() => `Intervenant retité du projet.`)
                .catch((err) => err)
            ).pipe(mergeMap((res) => of(res)));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  deleteAll(operationId: string, ids: string[]): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) {
            return of(null);
          } else {
            return EMPTY;
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }
}
