import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'app-participant-diagnostic-item-header-tags',
  templateUrl: './participant-diagnostic-item-header-tags.component.html',
  styleUrls: ['./participant-diagnostic-item-header-tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantDiagnosticItemHeaderTagsComponent implements OnInit {
  caractereLimitation: number = 40;
  @Input() besoins: any[] = [];
  constructor() {}

  ngOnInit(): void {}
}
