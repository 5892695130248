<div class="container">
  <i nz-icon nzType="loading" class="loading" *ngIf="loading$ | async"></i>
  <div [ngSwitch]="view$ | async">
    <div *ngSwitchCase="'list'">
      <ng-container *ngIf="missions$ | async | size; else noResults">
        <app-missions-filters
          [operation$]="operation$"
          [missions$]="missions$"
        ></app-missions-filters>
        <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
        <app-layout-list
          [card]="card"
          [new]="new"
          [height]="'calc(100vh - 18em)'"
          [source]="missions$ | async"
        ></app-layout-list>
      </ng-container>
    </div>
    <div *ngSwitchCase="'details'">
      <app-missions-item
        *ngIf="mission$ | async; else loading"
        [mission$]="mission$"
        [planing$]="currentPlaning$"
        [updateLoading$]="updateLoading$"
        (back)="onBack()"
        (update)="onUpdate($event)"
        (export)="onExport($event)"
        (delete)="onDelete($event)"
      ></app-missions-item>
    </div>
    <div *ngSwitchDefault>
      <app-layout-list
        *ngIf="missions$ | async | size; else noResults"
        [card]="card"
        [new]="new"
        [height]="'calc(100vh - 18em)'"
        [source]="missions$ | async"
      ></app-layout-list>
    </div>
  </div>

  <ng-template #new>
    <app-card-new
      [type]="'mission'"
      [title]="newCardTitle"
      [description]="''"
      [definition]="newCardDefinition"
      [width]="'300px'"
      [height]="'280px'"
      (add)="onNew()"
      (back)="onBack()"
      (cancel)="onBack()"
    ></app-card-new>
  </ng-template>

  <ng-template #card let-item let-position="idx">
    <app-missions-card
      [mission]="item"
      (click)="$event.stopPropagation(); onSelect(item)"
      (export)="onExport(item)"
      (delete)="onDelete(item)"
    ></app-missions-card>
  </ng-template>

  <ng-template #noResults>
    <app-no-result
      [icon]="'work_history'"
      [addtitle]="newCardDescription"
      [notitle]="noModuleTitle"
      [description]="newCardDefinition"
      (new)="onNew()"
    ></app-no-result>
  </ng-template>

  <ng-template #loading>
    <i nz-icon nzType="loading"> </i>
  </ng-template>

  <ng-template #missionFormTitle>
    <app-header-title [icon]="'topic'" [title]="title"></app-header-title>
  </ng-template>
</div>
