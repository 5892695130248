<form nz-form [formGroup]="logementForm" nzLayout="vertical">
  <nz-card nzSize="small" nzTitle="Adresse">
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="24">
        <app-communes-france [borderless]="true" [size]="'default'">
        </app-communes-france>
      </nz-col>
    </nz-row>
  </nz-card>
  <nz-space [nzSize]="50"></nz-space>
  <nz-card nzSize="small" nzTitle="QPV - QVA - ZRR">
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="8">
        <app-check-card
          [title]="'QPV'"
          [isActive]="logementForm.value.isQPV"
          [definition]="isQPVTitle"
          [height]="'40px'"
          [fontSize]="'14px'"
          (select)="checkboxChanges($event, 'isQPV')"
        ></app-check-card>
      </nz-col>
      <nz-col nzSpan="8">
        <app-check-card
          [title]="'QVA'"
          [isActive]="logementForm.value.isQVA"
          [definition]="isQVATitle"
          [height]="'40px'"
          [fontSize]="'14px'"
          (select)="checkboxChanges($event, 'isQVA')"
        ></app-check-card>
      </nz-col>
      <nz-col nzSpan="8">
        <app-check-card
          [title]="'ZRR'"
          [isActive]="logementForm.value.isZRR"
          [definition]="isZRRTitle"
          (select)="checkboxChanges($event, 'isZRR')"
          [height]="'40px'"
          [fontSize]="'14px'"
        ></app-check-card>
      </nz-col>
    </nz-row>
  </nz-card>
  <nz-space [nzSize]="50"></nz-space>

  <nz-card nzSize="small" nzTitle="Type d'hébergement">
    <nz-row nzAlign="top" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="6">
        <nz-form-item>
          <nz-form-control>
            <label nz-checkbox formControlName="proprietaire"
              >Propriétaire</label
            >
          </nz-form-control>
        </nz-form-item>
      </nz-col>
      <nz-col nzSpan="6">
        <nz-form-item>
          <nz-form-control>
            <label nz-checkbox formControlName="locataire">Locataire</label>
          </nz-form-control>
        </nz-form-item>
      </nz-col>
      <nz-col nzSpan="6">
        <nz-form-item>
          <nz-form-control>
            <label nz-checkbox formControlName="chezParent"
              >Chez ses parents</label
            >
          </nz-form-control>
        </nz-form-item>
      </nz-col>
      <nz-col nzSpan="6">
        <nz-form-item>
          <nz-form-control>
            <label nz-checkbox formControlName="chezTier">Chez un tier</label>
          </nz-form-control>
        </nz-form-item>
      </nz-col>
    </nz-row>

    <nz-space [nzSize]="50"></nz-space>

    <nz-row nzAlign="top" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="6">
        <nz-form-item>
          <nz-form-control>
            <label nz-checkbox formControlName="hotel">Dans un hôtel</label>
          </nz-form-control>
        </nz-form-item>
      </nz-col>
      <nz-col nzSpan="6">
        <nz-form-item>
          <nz-form-control>
            <label nz-checkbox formControlName="centreHebergement"
              >Centre d'hébergement</label
            >
          </nz-form-control>
        </nz-form-item>
      </nz-col>
      <nz-col nzSpan="6">
        <nz-form-item>
          <nz-form-control>
            <label nz-checkbox formControlName="lieuPublic"
              >Dans un lieu public</label
            >
          </nz-form-control>
        </nz-form-item>
      </nz-col>

      <nz-col nzSpan="6">
        <nz-form-item>
          <nz-form-control>
            <label nz-checkbox formControlName="autreLieu"
              >Dans un autre lieu</label
            >
          </nz-form-control>
        </nz-form-item>
      </nz-col>
    </nz-row>

    <nz-space [nzSize]="50"></nz-space>
    <nz-row nzAlign="top" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="6">
        <nz-form-item>
          <nz-form-control>
            <label nz-checkbox formControlName="autre">Autre</label>
          </nz-form-control>
        </nz-form-item>
      </nz-col>
      <nz-col nzSpan="6">
        <nz-form-item>
          <nz-form-control>
            <label nz-checkbox formControlName="nesaitpas">Ne sais pas</label>
          </nz-form-control>
        </nz-form-item>
      </nz-col>
      <nz-col nzSpan="12">
        <nz-form-item>
          <nz-form-control>
            <input
              nz-input
              nzBorderless
              formControlName="precision"
              placeholder="Précision du lieu d'hébergement..."
            />
          </nz-form-control>
        </nz-form-item>
      </nz-col>
    </nz-row>
  </nz-card>

  <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>

  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="24">
      <nz-form-item>
        <nz-form-control>
          <label nz-checkbox formControlName="isFrein">{{
            isFreinTitle
          }}</label>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>
</form>
