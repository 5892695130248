import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable, of } from 'rxjs';
import { ParticipantId } from '../../store/participant.model';
import { ParticipantState } from '../../store/participant.reducer';
import { ParticipantOrientationsFormComponent } from '../participant-orientations-form/participant-orientations-form.component';

@Component({
  selector: 'app-participant-orientations',
  templateUrl: './participant-orientations.component.html',
  styleUrls: ['./participant-orientations.component.scss'],
})
export class ParticipantOrientationsComponent implements OnInit {
  orientations$: Observable<any> = of(null);
  title: string = 'Orientations vers une autre stucture';
  titlePage: string = 'Orientations vers autre structure';
  view: string = 'list';
  currentSelected: any = null;
  filters$: Observable<any> = of(null);
  loading$: Observable<boolean> = of(false);
  aids$: Observable<any> = of([]);
  aidsSuggestions$: Observable<any> = of(null);
  suggestionAidsTitle: string = 'Suggestions';
  newAidsTitle: string = 'Formulaire vide';
  popoverTitle: string = 'Mode de renseignement';
  newCardTitle: string = 'Nouvelle orientation';
  newCardDescription: string = 'Ajouter une orientation';
  newCardNone: string = 'Aucune orientation ajoutée';
  newCardDefinition: string =
    'Une orientation est une orientation vers une structure sanitaire, sociale ou professionnelle';

  modalWidth: number = 960;
  @ViewChild('orientationTitleForm', { static: false }) orientationTitleForm:
    | TemplateRef<any>
    | any;

  @Input() participant$: Observable<ParticipantId | any> = of(null);

  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private participantStore: Store<ParticipantState>
  ) {}

  ngOnInit(): void {}

  onSelect(oriantation: any): void {}

  onNew(participant: ParticipantId): void {
    if (!participant) return;

    const modal = this.modal.create({
      nzContent: ParticipantOrientationsFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.orientationTitleForm,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });
    const instance = modal.getContentComponent();

    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });

    instance.add.subscribe((orientation): any => {
      if (!orientation) return null;
      this.onAdd(participant, orientation);
      modal.close();
    });
  }

  onAdd(participant: ParticipantId, orientation: any): void {
    if (!participant || !orientation) return;
    console.log(orientation);
  }

  onUpdate(participant: ParticipantId, orientation: any): void {
    if (!participant || !orientation) return;
  }

  onDelete(participant: ParticipantId, orientation: any): void {
    if (!participant || !orientation) return;
  }

  onBack(): void {
    this.currentSelected = null;
    this.view = 'list';
  }

  onCancel(): void {}
}
