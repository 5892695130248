import { ParticipantProjetproContactService } from './../../../service/participant-projetpro-contact.service';
import { ParticipantProjetproEntiteService } from './../../../service/participant-projetpro-entite.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ParticipantProjetproService } from 'src/app/service/participant-projetpro.service';
import { map, mergeMap, tap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import * as fromProjetProAction from './projet-professionnel.actions';
import { ParticipantProjetproFormationService } from 'src/app/service/participant-projetpro-formation.service';
import { ParticipantProjetproTodoService } from 'src/app/service/participant-projetpro-todo.service';
import { ParticipantProjetproEvaluationService } from 'src/app/service/participant-projetpro-evaluation.service';
import { ParticipantProjetproExportPdfService } from 'src/app/service/participant-projetpro-export-pdf.service';

@Injectable()
export class ProjetProfessionnelEffects {
  loadProjetsPros$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromProjetProAction.loadProjetsProfessionnels),
      mergeMap((action) =>
        this.projetProService.getAll(action.id).pipe(
          map((projetProfessionnels: any[]) =>
            fromProjetProAction.loadProjetsProfessionnelsSuccess({
              projetProfessionnels,
            })
          ),
          catchError((error) =>
            of(fromProjetProAction.loadProjetsProfessionnelsFailure({ error }))
          )
        )
      )
    )
  );

  loadProjetPro$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromProjetProAction.loadProjetProfessionnel),
      mergeMap((action) =>
        this.projetProService.getOne(action.participantId, action.id).pipe(
          map((projetProfessionnel: any) =>
            fromProjetProAction.loadProjetProfessionnelSuccess({
              projetProfessionnel,
            })
          ),
          catchError((error) =>
            of(fromProjetProAction.loadProjetProfessionnelFailure({ error }))
          )
        )
      )
    )
  );

  addProjetPro$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromProjetProAction.addProjetProfessionnel),
      mergeMap((action) =>
        this.projetProService
          .addOne(action.id, action.projetProfessionnel)
          .pipe(
            map((success: string) => {
              this.message.success(success, { nzDuration: 6000 });
              return fromProjetProAction.addProjetProfessionnelSuccess({
                success,
              });
            }),
            catchError((error) =>
              of(fromProjetProAction.addProjetProfessionnelFailure({ error }))
            )
          )
      )
    )
  );

  updateProjetPro$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromProjetProAction.updateProjetProfessionnel),
      mergeMap((action) =>
        this.projetProService
          .updateOne(action.id, action.projetProfessionnel.changes)
          .pipe(
            map((success: string) => {
              this.message.success(success, { nzDuration: 6000 });
              return fromProjetProAction.updateProjetProfessionnelSuccess({
                success,
              });
            }),
            catchError((error) =>
              of(
                fromProjetProAction.updateProjetProfessionnelFailure({
                  error,
                })
              )
            )
          )
      )
    )
  );

  deleteProjetPro$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromProjetProAction.deleteProjetProfessionnel),
      mergeMap((action) =>
        this.projetProService.deleteOne(action.participantId, action.id).pipe(
          map((success: string) => {
            this.message.success(success, { nzDuration: 6000 });
            return fromProjetProAction.deleteProjetProfessionnelSuccess({
              success,
            });
          }),
          catchError((error) =>
            of(fromProjetProAction.deleteProjetProfessionnelFailure({ error }))
          )
        )
      )
    )
  );

  loadStatistiquesEmploi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromProjetProAction.loadStatistiquesEmploi),
      mergeMap((action) =>
        this.projetProService
          .getStatistiques(
            action.codeRome,
            action.areaCode,
            action.codeFap,
            action.age
          )
          .pipe(
            map((snap: any) => {
              const statistiques = snap?.data?.metierStatistiques;
              return fromProjetProAction.loadStatistiquesEmploiSuccess({
                statistiques,
              });
            }),
            catchError((error) =>
              of(fromProjetProAction.loadStatistiquesEmploiFailure({ error }))
            )
          )
      )
    )
  );

  //FORMATIONS SUGGESTIONS
  loadFormationsSuggestion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromProjetProAction.loadFormationsSuggestions),
      mergeMap((action) =>
        this.projetProFormationService
          .getFormationsProfessionnelles(
            action.rome,
            action.latitude,
            action.longitude,
            action.radius,
            action.diploma
          )
          .pipe(
            map((snap: any) => {
              const formations = snap.data.formationsProByRome;
              return fromProjetProAction.loadFormationsSuggestionsSuccess({
                formations,
              });
            }),
            catchError((error) =>
              of(
                fromProjetProAction.loadFormationsSuggestionsFailure({ error })
              )
            )
          )
      )
    )
  );

  //FORMATION SUGGESTION DETAILS
  loadFormationSuggestionDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromProjetProAction.loadFormationSuggestionDetails),
      mergeMap((action) =>
        this.projetProFormationService.getFormationDetail(action.libelle).pipe(
          map((snap: any) => {
            const details = snap.data.formationOnisep;
            return fromProjetProAction.loadFormationSuggestionDetailsSuccess({
              details,
            });
          }),
          catchError((error) =>
            of(
              fromProjetProAction.loadFormationSuggestionDetailsFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  //LOAD FORMATION
  loadFormationProjetPro$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromProjetProAction.LoadFormations),
      mergeMap((action) =>
        this.projetProFormationService
          .getAll(action.participantId, action.projetpro)
          .pipe(
            map((formations: any) => {
              return fromProjetProAction.LoadFormationsSuccess({
                formations,
              });
            }),
            catchError((error) =>
              of(
                fromProjetProAction.LoadFormationsFailure({
                  error,
                })
              )
            )
          )
      )
    )
  );

  //ADD FORMATION
  addFormationProjetPro$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromProjetProAction.addFormation),
      mergeMap((action) =>
        this.projetProFormationService
          .addOne(action.participantId, action.projetpro, action.formation)
          .pipe(
            map((succes: any) => {
              this.message.success(succes, { nzDuration: 6000 });
              return fromProjetProAction.addFormationSuccess({
                succes,
              });
            }),
            catchError((error) =>
              of(
                fromProjetProAction.addFormationFailure({
                  error,
                })
              )
            )
          )
      )
    )
  );

  //UPDATE FORMATION
  updateFormationProjetPro$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromProjetProAction.updateFormation),
      mergeMap((action) =>
        this.projetProFormationService
          .updateOne(action.participantId, action.projetpro, action.formation)
          .pipe(
            map((succes: any) => {
              this.message.success(succes, { nzDuration: 6000 });
              return fromProjetProAction.updateFormationSuccess({
                succes,
              });
            }),
            catchError((error) =>
              of(
                fromProjetProAction.updateFormationFailure({
                  error,
                })
              )
            )
          )
      )
    )
  );

  //DELETE FORMATION
  deleteFormationProjetPro$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromProjetProAction.deleteFormation),
      mergeMap((action) =>
        this.projetProFormationService
          .deleteOne(action.participantId, action.projetpro, action.id)
          .pipe(
            map((succes: any) => {
              this.message.success(succes, { nzDuration: 6000 });
              return fromProjetProAction.deleteFormationSuccess({
                succes,
              });
            }),
            catchError((error) =>
              of(
                fromProjetProAction.deleteFormationFailure({
                  error,
                })
              )
            )
          )
      )
    )
  );

  //FORMATIONS JOBS
  //load formations jobs

  //ENTITES
  //load entites suggestions
  loadEntitesSuggestion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromProjetProAction.loadEntitesSuggestions),
      mergeMap((action) =>
        this.projetProEntiteService
          .getEntiteProjetProfessionnel(
            action.code,
            action.contract,
            action.distance,
            action.latitude,
            action.longitude,
            action.page
          )
          .pipe(
            map((snap: any) => {
              const entitesSuggestions = snap.data.entitesProjetProfessionnel;
              return fromProjetProAction.loadEntitesSuggestionsSuccess({
                entitesSuggestions,
              });
            }),
            catchError((error) =>
              of(fromProjetProAction.loadEntitesSuggestionsFailure({ error }))
            )
          )
      )
    )
  );
  //load entites projet pro
  loadEntitesProjetPro$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromProjetProAction.loadEntitesProjetPro),
      mergeMap((action) =>
        this.projetProEntiteService
          .getAll(action.participantId, action.projetpro)
          .pipe(
            map((entites: any) => {
              return fromProjetProAction.loadEntitesProjetProSuccess({
                entitesProjetPro: entites,
              });
            }),
            catchError((error) =>
              of(fromProjetProAction.loadEntitesProjetProFailure({ error }))
            )
          )
      )
    )
  );

  //add entites projet pro
  addEntiteProjetPro$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromProjetProAction.addEntiteProjetPro),
      mergeMap((action) =>
        this.projetProEntiteService
          .addOne(action.participantId, action.projetpro, action.entite)
          .pipe(
            map((succes: string) => {
              this.message.success(succes, { nzDuration: 6000 });
              return fromProjetProAction.addEntiteProjetProSuccess({
                succes,
              });
            }),
            catchError((error) =>
              of(fromProjetProAction.addEntiteProjetProFailure({ error }))
            )
          )
      )
    )
  );

  //update entite projet pro
  updateEntitesProjetPro$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromProjetProAction.updateEntiteProjetPro),
      mergeMap((action) =>
        this.projetProEntiteService
          .updateOne(action.participantId, action.projetpro, action.entite)
          .pipe(
            map((succes: string) => {
              this.message.success(succes, { nzDuration: 6000 });
              return fromProjetProAction.updateEntiteProjetProSuccess({
                succes,
              });
            }),
            catchError((error) =>
              of(fromProjetProAction.updateEntiteProjetProFailure({ error }))
            )
          )
      )
    )
  );

  //delete entite projet pro
  deleteEntiteProjetPro$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromProjetProAction.deleteEntiteProjetPro),
      mergeMap((action) =>
        this.projetProEntiteService
          .deleteOne(action.participantId, action.projetpro, action.id)
          .pipe(
            map((succes: string) => {
              this.message.success(succes, { nzDuration: 6000 });
              return fromProjetProAction.deleteEntiteProjetProSuccess({
                succes,
              });
            }),
            catchError((error) =>
              of(fromProjetProAction.deleteEntiteProjetProFailure({ error }))
            )
          )
      )
    )
  );

  //TODOS
  //load
  loadTodosProjetPro$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromProjetProAction.loadTodoProjetpro),
      mergeMap((action) =>
        this.projetProTodoService
          .getAll(action.participantId, action.projetpro)
          .pipe(
            map((todos: any) => {
              return fromProjetProAction.loadTodoProjetproSuccess({
                todos,
              });
            }),
            catchError((error) =>
              of(fromProjetProAction.loadTodoProjetproFailure({ error }))
            )
          )
      )
    )
  );
  //add
  addTodoProjetPro$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromProjetProAction.addTodoProjetpro),
      mergeMap((action) =>
        this.projetProTodoService
          .addOne(action.participantId, action.projetpro, action.todo)
          .pipe(
            map((success: string) => {
              this.message.success(success, { nzDuration: 6000 });
              return fromProjetProAction.addTodoProjetproSuccess({
                success,
              });
            }),
            catchError((error) =>
              of(fromProjetProAction.addTodoProjetproFailure({ error }))
            )
          )
      )
    )
  );
  //update
  updateTodoProjetPro$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromProjetProAction.updateTodoProjetpro),
      mergeMap((action) =>
        this.projetProTodoService
          .updateOne(action.participantId, action.projetpro, action.todo)
          .pipe(
            map((success: string) => {
              return fromProjetProAction.updateTodoProjetproSuccess({
                success,
              });
            }),
            catchError((error) =>
              of(fromProjetProAction.updateTodoProjetproFailure({ error }))
            )
          )
      )
    )
  );
  //delete
  deleteTodoProjetPro$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromProjetProAction.deleteTodoProjetpro),
      mergeMap((action) =>
        this.projetProTodoService
          .deleteOne(action.participantId, action.projetpro, action.id)
          .pipe(
            map((success: string) => {
              this.message.success(success, { nzDuration: 6000 });

              return fromProjetProAction.deleteTodoProjetproSuccess({
                success,
              });
            }),
            catchError((error) =>
              of(fromProjetProAction.deleteTodoProjetproFailure({ error }))
            )
          )
      )
    )
  );

  //EVALUATIONS
  //load
  loadEvaluationsProjetPro$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromProjetProAction.loadEvaluationProjetpro),
      mergeMap((action) =>
        this.projetProEvaluationService
          .getAll(action.participantId, action.projetpro)
          .pipe(
            map((evaluations: any) => {
              return fromProjetProAction.loadEvaluationProjetproSuccess({
                evaluations,
              });
            }),
            catchError((error) =>
              of(fromProjetProAction.loadEvaluationProjetproFailure({ error }))
            )
          )
      )
    )
  );
  //add
  addEvaluationProjetPro$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromProjetProAction.addEvaluationProjetpro),
      mergeMap((action) =>
        this.projetProEvaluationService
          .addOne(action.participantId, action.projetpro, action.evaluation)
          .pipe(
            map((success: string) => {
              this.message.success(success, { nzDuration: 6000 });

              return fromProjetProAction.addEvaluationProjetproSuccess({
                success,
              });
            }),
            catchError((error) =>
              of(fromProjetProAction.addEvaluationProjetproFailure({ error }))
            )
          )
      )
    )
  );
  //update
  updateEvaluationProjetPro$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromProjetProAction.updateEvaluationProjetpro),
      mergeMap((action) =>
        this.projetProEvaluationService
          .updateOne(action.participantId, action.projetpro, action.evaluation)
          .pipe(
            map((success: string) => {
              return fromProjetProAction.updateEvaluationProjetproSuccess({
                success,
              });
            }),
            catchError((error) =>
              of(
                fromProjetProAction.updateEvaluationProjetproFailure({ error })
              )
            )
          )
      )
    )
  );
  //delete
  deleteEvaluationProjetPro$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromProjetProAction.deleteEvaluationProjetpro),
      mergeMap((action) =>
        this.projetProEvaluationService
          .deleteOne(action.participantId, action.projetpro, action.id)
          .pipe(
            map((success: string) => {
              this.message.success(success, { nzDuration: 6000 });

              return fromProjetProAction.deleteEvaluationProjetproSuccess({
                success,
              });
            }),
            catchError((error) =>
              of(
                fromProjetProAction.deleteEvaluationProjetproFailure({ error })
              )
            )
          )
      )
    )
  );

  //EXPORT
  //PDF
  exportPDFProjetPro$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromProjetProAction.exportPDF),
      mergeMap((action) =>
        this.projetProExportPdfService
          .export_PROJETPROFESSIONNEL(action.participant, action.projetpro)
          .pipe(
            map((success: any) => {
              this.message.success(success, { nzDuration: 6000 });
              return fromProjetProAction.exportPDFSuccess({
                success,
              });
            }),
            catchError((error) =>
              of(fromProjetProAction.exportPDFFailure({ error }))
            )
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private projetProService: ParticipantProjetproService,
    private message: NzMessageService,
    private projetProFormationService: ParticipantProjetproFormationService,
    private projetProEntiteService: ParticipantProjetproEntiteService,
    private projetProContactService: ParticipantProjetproContactService,
    private projetProTodoService: ParticipantProjetproTodoService,
    private projetProEvaluationService: ParticipantProjetproEvaluationService,
    private projetProExportPdfService: ParticipantProjetproExportPdfService
  ) {}
}
