import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-participant-justice',
  templateUrl: './participant-justice.component.html',
  styleUrls: ['./participant-justice.component.scss']
})
export class ParticipantJusticeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
