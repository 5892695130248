import { Pipe, PipeTransform } from '@angular/core';
import { fromUnixTime, isBefore } from 'date-fns';

@Pipe({
  name: 'isLater',
})
export class IsLaterPipe implements PipeTransform {
  today: Date = new Date(Date.now());

  transform(value: any): unknown {
    if (!value) {
      return null;
    }

    if (isBefore(fromUnixTime(value['seconds']), this.today)) {
      return true;
    }

    return false;
  }
}
