import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParticipantBilanComponent } from './components/participant-bilan/participant-bilan.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ContentsModule } from 'src/app/contents/contents.module';
import { HeaderModule } from 'src/app/contents/components/header-title/header.module';

@NgModule({
  declarations: [ParticipantBilanComponent],
  imports: [CommonModule, SharedModule, ContentsModule, HeaderModule],
  exports: [ParticipantBilanComponent],
})
export class ParticipantBilanModule {}
