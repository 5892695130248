<nz-card
  nzHoverable
  nzSize="small"
  [nzTitle]="titleTpl"
  [ngClass]="{ active: isActive, nonActive: !isActive }"
  (click)="onSelect(isActive)"
  [ngStyle]="{ width: width }"
>
  <ng-template #titleTpl>
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="4">
        <label nz-checkbox [(ngModel)]="isActive"></label>
      </nz-col>
      <nz-col
        nzSpan="20"
        [ngStyle]="{ color: isActive ? colorActive : 'gray' }"
        >{{ title }}</nz-col
      >
    </nz-row>
  </ng-template>
  <div [ngStyle]="{ height: height }" class="checkboxContent">
    <mat-icon
      *ngIf="icon"
      [ngStyle]="{ color: isActive ? colorActive : 'gray' }"
      >{{ icon }}</mat-icon
    >
    <nz-space
      *ngIf="icon && definition"
      nzDirection="vertical"
      nzSize="small"
    ></nz-space>
    <small
      *ngIf="definition"
      [ngStyle]="{
        color: isActive ? colorActive : 'gray',
        'font-size': fontSize
      }"
      >{{ definition }}</small
    >
  </div>
</nz-card>
