import { environment } from './../environments/environment';
import * as environmentProd from './../environments/environment.prod';

import { NgModule } from '@angular/core';
import { APOLLO_OPTIONS } from 'apollo-angular';
import {
  ApolloClientOptions,
  InMemoryCache,
  ApolloLink,
} from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { Auth, getAuth, IdTokenResult } from '@angular/fire/auth';
import { setContext } from '@apollo/client/link/context';
// @ts-ignore
import extractFiles from 'extract-files/extractFiles.mjs';
// @ts-ignore
import isExtractableFile from 'extract-files/isExtractableFile.mjs';

const middleWareLink = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    const omitTypename = (key: any, value: any) =>
      key === '__typename' ? undefined : value;
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename
    );
  }
  return forward(operation);
});

const uri = environment.graphql;

export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {
  const basic = setContext((operation, context) => ({
    headers: {
      Accept: 'charset=utf-8',
    },
  }));

  const currentAuth: Auth = getAuth();

  const auth = setContext((operation, context) => {
    return currentAuth?.currentUser
      ?.getIdTokenResult()
      .then((idToken: IdTokenResult) => {
        if (!idToken && !currentAuth?.currentUser) {
          return {
            headers: null,
          };
        }
        if (!idToken && currentAuth?.currentUser) {
          const refreshToken: string | any =
            currentAuth?.currentUser?.refreshToken;

          if (refreshToken === null) {
            return {};
          } else {
            return {
              headers: {
                Authorization: `Bearer ${refreshToken}`,
              },
            };
          }
        } else {
          //console.log(idToken.token);
          return {
            headers: {
              Authorization: `Bearer ${idToken.token}`,
            },
          };
        }
      })
      .catch((error) => {
        return '';
      });
  });

  return {
    link: ApolloLink.from([
      basic,
      auth,
      middleWareLink,
      httpLink.create({
        uri,
        extractFiles: (body) => extractFiles(body, isExtractableFile),
      }),
    ]),
    cache: new InMemoryCache({
      addTypename: true,
      resultCaching: true,
      typePolicies: {
        Person: {
          merge: true,
        },
        Etablissement: {
          merge: true,
        },
        FicheDePoste: {
          merge: true,
        },
        Adresse: {
          merge: true,
        },
        User: {
          merge: true,
        },
        Coordonnees: {
          merge: true,
        },
        FicheDePosteRecherche: {
          merge: true,
        },
        FicheDePosteMission: {
          merge: true,
        },
      },
    }),

    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      },
      mutate: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      },
    },
  };
}

@NgModule({
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {
  constructor() {}
}
