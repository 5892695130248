<app-layout-list
  *ngIf="source | size; else noData"
  [new]="new"
  [card]="card"
  [source]="source"
  [height]="'calc(100vh - 20em)'"
></app-layout-list>

<ng-template #new>
  <app-card-new
    [title]="'Nouvelle tâche'"
    [type]="'task'"
    [height]="'200px'"
    [width]="'250px'"
    [definition]="newDefinition"
    (add)="onNew()"
  >
  </app-card-new>
</ng-template>
<ng-template #card let-item let-position="idx">
  <app-layout-list-card
    [title]="todoCardTitleTpl"
    [extra]="todoCardExtraTpl"
    [body]="todoCardBodyTpl"
    (click)="$event.stopPropagation(); show__TODO(item)"
  ></app-layout-list-card>

  <ng-template #todoCardTitleTpl>
    <h4 nz-typograph>{{ item.title | carLimitation : 30 }}</h4>
  </ng-template>
  <ng-template #todoCardExtraTpl>
    <app-button-dropdown
      (click)="$event.stopPropagation()"
    ></app-button-dropdown>
  </ng-template>
  <ng-template #todoCardBodyTpl>
    <div class="container w-auto flex items-start">
      <div class="w-3/6">
        <app-todo-status [todo]="item"></app-todo-status>
      </div>
      <div class="w-3/6">
        <mat-icon
          [ngClass]="{
            nonActiv: !item.allDay,
            activ: item.allDay
          }"
          >today</mat-icon
        >
      </div>
    </div>

    <div class="w-auto flex items-start">
      <div class="w-2/6">
        <nz-statistic
          [nzValue]="item?.sujets | isComplete : 'status' : true | size"
          [nzPrefix]="prefixTpl"
          [nzSuffix]="'/' + (item?.sujets | size)"
          [nzValueStyle]="{ 'font-size': '18px' }"
        ></nz-statistic>
        <ng-template #prefixTpl
          ><i
            nz-icon
            nzType="file-done"
            class="iconSujet"
            [ngStyle]="{
              color:
                (item?.sujets | isComplete : 'status' : true | size) ===
                (item?.sujets | size)
                  ? green
                  : red
            }"
          ></i
        ></ng-template>
      </div>
      <div class="w-2/6">
        <small *nzSpaceItem>{{ item?.dateStart | dateFr : "small" }}</small>
      </div>
      <div class="w-2/6">
        <small *nzSpaceItem>{{ item?.dateEnd | dateFr : "small" }}</small>
      </div>
    </div>

    <div class="w-full my-auto">
      <nz-tag nzColor="geekblue">
        Échéance : {{ item?.dateEnd | dateFr : "distanceSuffix" }}
      </nz-tag>
    </div>
  </ng-template>
</ng-template>

<ng-template #noData>
  <app-no-result
    [icon]="'task'"
    [notitle]="newDefinition"
    [notitle]="noTodoTitle"
    (new)="onNew()"
  ></app-no-result>
</ng-template>
