import { EntiteState } from './../components/entite/store/entite.reducer';
import { ParticipantState } from './../components/participant/store/participant.reducer';
import { ActionState } from './../components/action/store/action.reducer';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  Firestore,
  collection,
  doc,
  docSnapshots,
  collectionChanges,
  getDoc,
  addDoc,
  setDoc,
  deleteDoc,
  serverTimestamp,
  DocumentData,
  DocumentSnapshot,
  Timestamp,
  query,
  where,
  arrayUnion,
  arrayRemove,
  collectionSnapshots,
  CollectionReference,
  DocumentReference,
  getDocs,
  documentId,
} from '@angular/fire/firestore';
import { Observable, of, from, EMPTY } from 'rxjs';
import { switchMap, mergeMap, map, catchError } from 'rxjs/operators';

import { ModuleState } from '../components/module/store/module.reducer';
import * as fromModuleSelector from '../components/module/store/module.selectors';

@Injectable({
  providedIn: 'any',
})
export class QueryMissionsService {
  constructor(
    private db: Firestore,

    private moduleStore: Store<ModuleState>,
    private actionStore: Store<ActionState>,
    private participantStore: Store<ParticipantState>,
    private entiteStore: Store<EntiteState>
  ) {}

  getAllByModule(): Observable<any> {
    try {
      return this.moduleStore.select(fromModuleSelector.module).pipe(
        switchMap((module) => {
          if (!module) {
            return EMPTY;
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${module.organisation.id}/operations/${module.operation.id}/missions`
            );

            const dbQuery = query(
              dbCollection,
              where('module.id', '==', module.id)
            );

            return collectionSnapshots(dbQuery).pipe(
              map((snap: DocumentSnapshot<DocumentData>[]) => {
                return snap.map((doc: DocumentData) => {
                  const data = doc.data() as any;
                  const id = doc.id;
                  return { id, ...data };
                });
              }),
              catchError((error) => error)
            );
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  getAllByModuleFinish(operationId: string, moduleId: string): Observable<any> {
    return EMPTY;
  }
  getAllByAction(operationId: string, actionId: string): Observable<any> {
    return EMPTY;
  }

  getAllByParticipant(
    operationId: string,
    participantId: string
  ): Observable<any> {
    return EMPTY;
  }
  getAllByEntite(operationId: string, entiteId: string): Observable<any> {
    return EMPTY;
  }
  getAllByContact(operationId: string, entiteId: string): Observable<any> {
    return EMPTY;
  }
}
