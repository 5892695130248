<nz-card nzBorderless>
  <nz-steps [nzCurrent]="step">
    <nz-step nzTitle="Organisation" [nzIcon]="stepOrganisationIcon"></nz-step>
    <nz-step nzTitle="Administrateur" [nzIcon]="stepAdminIcon"></nz-step>
    <!-- <nz-step nzTitle="Paiment" [nzIcon]="stepPaimentIcon"></nz-step> -->

    <ng-template #stepOrganisationIcon>
      <mat-icon>corporate_fare</mat-icon>
    </ng-template>
    <ng-template #stepAdminIcon>
      <mat-icon>admin_panel_settings</mat-icon>
    </ng-template>
    <ng-template #stepPaimentIcon>
      <mat-icon>credit_card</mat-icon>
    </ng-template>
  </nz-steps>

  <nz-space nzSize="large" nzDirection="vertical"></nz-space>

  <div [ngSwitch]="step">
    <div *ngSwitchCase="0">
      <nz-row
        nzAlign="middle"
        nzGutter="8"
        nzJustify="start"
        *ngIf="typeForm === 'add'"
      >
        <nz-col nzSpan="24">
          <app-entite-search
            (addItem)="selectedItem($event)"
          ></app-entite-search>
        </nz-col>
      </nz-row>
      <nz-space nzSize="middle" nzDirection="vertical"></nz-space>
      <nz-divider nzType="horizontal"></nz-divider>

      <nz-card
        nzSize="small"
        *ngIf="
          typeForm === 'add' &&
          organisationForm.value?.etablissement?.denomination
        "
      >
        <nz-descriptions
          nzSize="small"
          [nzTitle]="organisationTitleTpl"
          nzBordered
        >
          <nz-descriptions-item nzTitle="Catégorie" nzSpan="1">
            <p nz-typography>
              {{ organisationForm.value?.etablissement?.categorie_entreprise }}
            </p>
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="Activité" nzSpan="2">
            <p nz-typography>
              {{
                organisationForm.value?.etablissement
                  ?.activite_principale_libelle?.intitule_naf
              }}
            </p>
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="Siret" nzSpan="1">
            {{
              organisationForm.value?.etablissement?.etablissement_siege?.siret
            }}
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="Adresse" nzSpan="2">
            {{
              organisationForm.value?.etablissement?.etablissement_siege
                ?.geo_adresse
            }}
          </nz-descriptions-item>
        </nz-descriptions>

        <ng-template #organisationTitleTpl>
          <h4 nz-typography>
            {{
              organisationForm.value?.etablissement?.denomination | uppercase
            }}
          </h4>
        </ng-template>
      </nz-card>

      <nz-row
        nzAlign="middle"
        nzGutter="8"
        nzJustify="start"
        *ngIf="organisation$ | async as organisation"
      >
        <nz-col nzSpan="24">
          <app-coordonnees
            [borderless]="true"
            [update]="updateCoordonnes"
            (mobile)="onChangeMobile($event, organisation)"
            (fixe)="onChangeFixe($event, organisation)"
            (email)="onChangeEmail($event, organisation)"
            (phoneNumber)="onChangePhoneNumber($event, organisation)"
          ></app-coordonnees>
        </nz-col>
      </nz-row>
    </div>
    <div *ngSwitchCase="1">
      <nz-card nzSize="small">
        <app-user-form
          (cancel)="prevStep()"
          (add)="onAdd($event)"
        ></app-user-form>
      </nz-card>
    </div>
    <div *ngSwitchDefault></div>
  </div>
</nz-card>
<nz-divider nzType="horizontal"></nz-divider>
<nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
  <nz-col [nzSpan]="12"></nz-col>
  <nz-col nzSpan="6">
    <button
      nz-button
      nzBlock
      (click)="onCancel($event)"
      nzType="text"
      *ngIf="step === 0"
    >
      <h4>Annuler</h4>
    </button>
  </nz-col>

  <nz-col nzSpan="6">
    <button
      nz-button
      nzBlock
      [disabled]="!organisationForm.value?.etablissement"
      (click)="$event.stopPropagation(); nextStep()"
      *ngIf="typeForm === 'add' && step === 0"
    >
      Suivant
    </button>
    <!-- <button
      nz-button
      nzBlock
      nzType="primary"
      [disabled]="organisationForm.invalid"
      (click)="add__ORGANISATION($event)"
      *ngIf="typeForm === 'add'"
    >
      Ajouter
    </button>
    <button
      nz-button
      nzBlock
      nzType="primary"
      [disabled]="organisationForm.invalid"
      *ngIf="typeForm === 'update' && (organisation$ | async) as organisation"
      (click)="update__ORGANISATION(organisation)"
    >
      Modifier
    </button> -->
  </nz-col>
</nz-row>
