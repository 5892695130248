import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CoordonneesService {
  mobile = new BehaviorSubject<string>('')
  fixe = new BehaviorSubject<string>('')
  phoneNumber = new BehaviorSubject<string>('')
  email = new BehaviorSubject<string>('')
  updateItems = new BehaviorSubject<any>(null)



  constructor() { }

  onCoordonne(type: string, item: string): void {
    switch (type) {
      case 'mobile':
        this.mobile.next(item)

        const indicatif: number = 33
        const slice = item.slice(1, 10)
        const phoneNumber = `+${indicatif}${slice}`
        this.phoneNumber.next(phoneNumber)
        break;
      case 'fixe':
        this.fixe.next(item)

        break;

      case 'email':
        this.email.next(item)
        break;
    }
  }

  onCoordonneUpdate(items: any): void {
    if(items){
      const mobile = items['mobile']
      const fixe = items['fixe']
      const phoneNumber = items['phoneNumber']
      const email = items['email']
      const update = {
        mobile, fixe, email
      }
      this.updateItems.next(update)


    }
  }

  clearMobile(type: string): void {
    switch (type) {
      case 'mobile':
        this.mobile.next('')
        this.phoneNumber.next('')
        break;

      case 'fixe':
        this.fixe.next('')
        break;

      case 'email':
        this.email.next('')
        break;
    }
  }
}
