<nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
  <!-- REGIONS -->
  <nz-col [nzSpan]="8">
    <nz-list
      nzSize="small"
      [nzHeader]="regionsListHeader"
      [ngClass]="{
        typeList: type === 'list',
        typeSelect: type === 'select'
      }"
    >
      <ng-template #regionsListHeader>
        <app-geo-territoire-search-list-header
          [type]="type"
          [list]="regions$ | async"
          [isLoading]="isRegionsLoading$ | async"
          [selectedItem$]="selectedRegion$"
          [placeholder]="'Rechercher une région'"
          (changes)="onChangeRegion($event, type)"
          (clean)="onCleanSearchRegion()"
        ></app-geo-territoire-search-list-header>
      </ng-template>
      <app-geo-territoire-search-list
        *ngIf="type === 'list'"
        [items]="regions$ | async"
        [isAction]="true"
        (selected)="onSelectRegion($event)"
      ></app-geo-territoire-search-list>
    </nz-list>
  </nz-col>

  <!-- DEPARTEMENT -->
  <nz-col [nzSpan]="8">
    <nz-list
      nzSize="small"
      [nzHeader]="departementsListHeader"
      [ngClass]="{
        typeList: type === 'list',
        typeSelect: type === 'select'
      }"
    >
      <ng-template #departementsListHeader>
        <app-geo-territoire-search-list-header
          [type]="type"
          [list]="departements$ | async"
          [isLoading]="isDepartementsLoading$ | async"
          [selectedItem$]="selectedDepartement$"
          [placeholder]="'Rechercher un département'"
          (changes)="onChangeDepartement($event, type)"
          (clean)="onCleanSearchDepartement(); getDepartements()"
        ></app-geo-territoire-search-list-header>
      </ng-template>

      <app-geo-territoire-search-list
        *ngIf="type === 'list'"
        [items]="departements$ | async"
        [isAction]="true"
        (selected)="onSelectDepartement($event)"
      ></app-geo-territoire-search-list>
    </nz-list>
  </nz-col>

  <!-- COMMUNES -->

  <nz-col [nzSpan]="8">
    <nz-list
      nzSize="small"
      [nzHeader]="communesListHeader"
      [ngClass]="{
        typeList: type === 'list',
        typeSelect: type === 'select'
      }"
    >
      <ng-template #communesListHeader>
        <app-geo-territoire-search-list-header
          [type]="type"
          [list]="communes$ | async"
          [isLoading]="isCommunesLoading$ | async"
          [selectedItem$]="selectedCommune$"
          [placeholder]="'Rechercher une commune'"
          (changes)="onChangeCommune($event, type)"
          (clean)="onCleanSearchCommune(); getCommunes()"
        ></app-geo-territoire-search-list-header>
      </ng-template>
      <app-geo-territoire-search-list
        *ngIf="type === 'list'"
        [items]="communes$ | async"
        (selected)="onSelectCommune($event)"
      ></app-geo-territoire-search-list>
    </nz-list>
  </nz-col>
</nz-row>
