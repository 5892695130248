import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Observable, of, Subject, takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-fiches-de-poste-form-validation',
  templateUrl: './fiches-de-poste-form-validation.component.html',
  styleUrls: ['./fiches-de-poste-form-validation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FichesDePosteFormValidationComponent implements OnInit, OnChanges {
  subscribe = new Subject();
  @Input() fiche: any;
  @Input() entite: any;

  @Output() onCurrentChanges = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @Output() modify = new EventEmitter();

  //FORM
  participantsSize = new FormControl(2, Validators.required);
  isClauseInsertion = new FormControl<boolean>(true);
  geoLimitation = new FormControl<{
    commune: { code: string; nom: string };
    departement: { code: string; nom: string };
    region: { code: string; nom: string };
  }>({
    commune: { code: '', nom: '' },
    departement: { code: '', nom: '' },
    region: { code: '', nom: '' },
  });
  ficheDePosteValidationForm: FormGroup = this.fb.group({
    participantsSize: this.participantsSize,
    isClauseInsertion: this.isClauseInsertion,
    geoLimitation: this.geoLimitation,
  });

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.formCreate();
    this.onChange();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.formSet(changes.fiche.currentValue);
  }
  OnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  formCreate(): void {
    this.ficheDePosteValidationForm = this.fb.group({
      participantsSize: this.participantsSize,
      isClauseInsertion: this.isClauseInsertion,
      geoLimitation: this.geoLimitation,
    });
  }

  formSet(fiche: any): void {
    if (!fiche) return;

    const { geoLimitation, isClauseInsertion, participantsSize } =
      fiche.recherche;
    const { commune, departement, region } = geoLimitation;

    this.ficheDePosteValidationForm.patchValue({
      participantsSize: participantsSize,
      isClauseInsertion: isClauseInsertion,
      geoLimitation: {
        commune: commune,
        departement: departement,
        region: region,
      },
    });
  }

  onLimitTerritoire(territoire: any): void {
    const { region, departement, commune } = territoire;
    this.ficheDePosteValidationForm.patchValue({
      geoLimitation: {
        region: region?.code && region?.nom ? region : { code: '', nom: '' },
        departement:
          departement?.code && departement?.nom
            ? departement
            : { code: '', nom: '' },
        commune:
          commune?.code && commune?.nom ? commune : { code: '', nom: '' },
      },
    });
  }

  onCancel(): void {
    this.cancel.emit(true);
  }
  onModify(): void {
    this.modify.emit(true);
  }
  onChange(): void {
    this.ficheDePosteValidationForm.valueChanges
      .pipe(takeUntil(this.subscribe))
      .subscribe((value) => {
        this.onCurrentChanges.emit(value);
      });
  }
}
