import { createAction, props } from '@ngrx/store';

//LOADING
export const loading = createAction(
  '[Aids/COMPONENT] Loading Aidss',
  props<{ loading: boolean }>()
);

//FILTERS
export const loadAidsFilters = createAction('[Aids/API] Load Filters Aidss');

export const loadAidsFiltersSuccess = createAction(
  '[Aids/API] Load Filters Aidss Success',
  props<{ filters: any[] }>()
);

export const loadAidsFiltersFailure = createAction(
  '[Aids/API] Load Filters Aidss Failure',
  props<{ error: any }>()
);

//AIDS
export const loadAids = createAction(
  '[Aids/API] Load Aidss',
  props<{ filters: any }>()
);

export const loadAidsSuccess = createAction(
  '[Aids/API] Load Aidss Success',
  props<{ aids: any[] }>()
);

export const loadAidsFailure = createAction(
  '[Aids/API] Load Aidss Failure',
  props<{ error: any }>()
);
