<div class="w-8 flex items-end justify-between gap-2 place-items-baseline">
  <button class="btn btn-circle btn-ghost" (click)="onTodos()">
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      class="h-6 w-6"
    >
      <title>Tâches</title>
      <g
        id="Icon"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g id="File" transform="translate(-720.000000, -384.000000)">
          <g id="task_2_line" transform="translate(720.000000, 384.000000)">
            <path
              d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5935,23.2578 L12.5819,23.2595 L12.5109,23.295 L12.4919,23.2987 L12.4767,23.295 L12.4057,23.2595 C12.3958,23.2564 12.387,23.259 12.3821,23.2649 L12.378,23.2758 L12.3609,23.7031 L12.3659,23.7235 L12.3769,23.7357 L12.4805,23.8097 L12.4953,23.8136 L12.5071,23.8097 L12.6107,23.7357 L12.6233,23.7197 L12.6267,23.7031 L12.6096,23.2758 C12.6076,23.2657 12.601,23.2593 12.5935,23.2578 Z M12.8584,23.1453 L12.8445,23.1473 L12.6598,23.2397 L12.6499,23.2499 L12.6472,23.2611 L12.6651,23.6906 L12.6699,23.7034 L12.6784,23.7105 L12.8793,23.8032 C12.8914,23.8069 12.9022,23.803 12.9078,23.7952 L12.9118,23.7812 L12.8777,23.1665 C12.8753,23.1546 12.8674,23.147 12.8584,23.1453 Z M12.143,23.1473 C12.1332,23.1424 12.1222,23.1453 12.1156,23.1526 L12.1099,23.1665 L12.0758,23.7812 C12.0751,23.7927 12.0828,23.8019 12.0926,23.8046 L12.1083,23.8032 L12.3092,23.7105 L12.3186,23.7024 L12.3225,23.6906 L12.3404,23.2611 L12.3372,23.2485 L12.3278,23.2397 L12.143,23.1473 Z"
              id="MingCute"
              fill-rule="nonzero"
            ></path>
            <path
              d="M12,4 C10.8954,4 10,4.89543 10,6 L14,6 C14,4.89543 13.1046,4 12,4 Z M9.35418,3 C10.0593,2.37764 10.9856,2 12,2 C13.0144,2 13.9407,2.37764 14.6458,3 L18,3 C19.1046,3 20,3.89543 20,5 L20,20 C20,21.1046 19.1046,22 18,22 L6,22 C4.89543,22 4,21.1046 4,20 L4,5 C4,3.89543 4.89543,3 6,3 L9.35418,3 Z M8.12602,5 L6,5 L6,20 L18,20 L18,5 L15.874,5 C15.9562,5.31962 16,5.6547 16,6 L16,7 C16,7.55228 15.5523,8 15,8 L9,8 C8.44772,8 8,7.55228 8,7 L8,6 C8,5.6547 8.04375,5.31962 8.12602,5 Z M8,11 C8,10.4477 8.44772,10 9,10 L15,10 C15.5523,10 16,10.4477 16,11 C16,11.5523 15.5523,12 15,12 L9,12 C8.44772,12 8,11.5523 8,11 Z M8,15 C8,14.4477 8.44772,14 9,14 L12,14 C12.5523,14 13,14.4477 13,15 C13,15.5523 12.5523,16 12,16 L9,16 C8.44772,16 8,15.5523 8,15 Z"
              id="形状"
              fill="#09244BFF"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  </button>
  <button class="btn btn-circle btn-ghost" (click)="onMessage()">
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      class="h-6 w-6"
    >
      <title>Messages</title>
      <g
        id="页面-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Contact"
          transform="translate(-816.000000, 0.000000)"
          fill-rule="nonzero"
        >
          <g id="message_3_line" transform="translate(816.000000, 0.000000)">
            <path
              d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z"
              id="MingCute"
              fill-rule="nonzero"
            ></path>
            <path
              d="M19,3 C20.6569,3 22,4.34315 22,6 L22,16 C22,17.6569 20.6569,19 19,19 L7.33333,19 L4,21.5 C3.17596,22.118 2,21.5301 2,20.5 L2,6 C2,4.34315 3.34315,3 5,3 L19,3 Z M19,5 L5,5 C4.44772,5 4,5.44772 4,6 L4,19 L6.13333,17.4 C6.47953,17.1404 6.90059,17 7.33333,17 L19,17 C19.5523,17 20,16.5523 20,16 L20,6 C20,5.44772 19.5523,5 19,5 Z M11,12 C11.5523,12 12,12.4477 12,13 C12,13.51285 11.613973,13.9355092 11.1166239,13.9932725 L11,14 L8,14 C7.44772,14 7,13.5523 7,13 C7,12.48715 7.38604429,12.0644908 7.88337975,12.0067275 L8,12 L11,12 Z M16,8 C16.5523,8 17,8.44772 17,9 C17,9.55228 16.5523,10 16,10 L8,10 C7.44772,10 7,9.55228 7,9 C7,8.44772 7.44772,8 8,8 L16,8 Z"
              id="形状"
              fill="#09244BFF"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  </button>
  <!-- <button class="btn btn-circle btn-ghost" (click)="onFavoris()">
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      class="h-6 w-6"
    >
      <title>Favories</title>
      <g
        id="页面-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g id="Shape" transform="translate(-240.000000, 0.000000)">
          <g id="star_line" transform="translate(240.000000, 0.000000)">
            <path
              d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z"
              id="MingCute"
              fill-rule="nonzero"
            ></path>
            <path
              d="M10.9198,2.8677 C11.402,2.03987 12.598,2.03987 13.0801,2.8677 L15.8751,7.66643 L21.3027,8.84175 C22.239,9.0445 22.6086,10.1819 21.9703,10.8963 L18.2701,15.0374 L18.8295,20.5625 C18.926,21.5156 17.9585,22.2186 17.0818,21.8323 L12,19.5929 L6.91816,21.8323 C6.04149,22.2186 5.07395,21.5156 5.17046,20.5625 L5.72987,15.0374 L2.02972,10.8963 C1.3914,10.1819 1.76097,9.0445 2.69728,8.84175 L8.12484,7.66643 L10.9198,2.8677 Z M12,4.98699 L9.68673,8.95861 C9.5101,9.26187 9.21413,9.4769 8.87114,9.55117 L4.37906,10.5239 L7.44146,13.9513 C7.67529,14.2129 7.78834,14.5609 7.75299,14.91 L7.29,19.4828 L11.4959,17.6294 C11.8171,17.4879 12.1829,17.4879 12.504,17.6294 L16.71,19.4828 L16.247,14.91 C16.2116,14.5609 16.3247,14.2129 16.5585,13.9513 L19.6209,10.5239 L15.1288,9.55117 C14.7858,9.4769 14.4899,9.26186 14.3132,8.95861 L12,4.98699 Z"
              id="形状"
              fill="#09244BFF"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  </button>

  <button class="btn btn-circle btn-ghost" (click)="onCalculator()">
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <title>counter_line</title>
      <g
        id="页面-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Education"
          transform="translate(-240.000000, 0.000000)"
          fill-rule="nonzero"
        >
          <g id="counter_line" transform="translate(240.000000, 0.000000)">
            <path
              d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z"
              id="MingCute"
              fill-rule="nonzero"
            ></path>
            <path
              d="M19,3 C20.0543909,3 20.9181678,3.81587733 20.9945144,4.85073759 L21,5 L21,19 C21,20.0543909 20.18415,20.9181678 19.1492661,20.9945144 L19,21 L5,21 C3.94563773,21 3.08183483,20.18415 3.00548573,19.1492661 L3,19 L3,5 C3,3.94563773 3.81587733,3.08183483 4.85073759,3.00548573 L5,3 L19,3 Z M19,5 L5,5 L5,19 L19,19 L19,5 Z M7.79286,13.3787 L8.49996,14.0858 L9.20707,13.3787 C9.5976,12.9882 10.2308,12.9882 10.6213,13.3787 C11.0118,13.7692 11.0118,14.4024 10.6213,14.7929 L9.91418,15.5 L10.6213,16.2071 C11.0118,16.5976 11.0118,17.2308 10.6213,17.6213 C10.2308,18.0118 9.5976,18.0118 9.20707,17.6213 L8.49996,16.9142 L7.79286,17.6213 C7.40233,18.0118 6.76917,18.0118 6.37864,17.6213 C5.98812,17.2308 5.98812,16.5976 6.37864,16.2071 L7.08575,15.5 L6.37864,14.7929 C5.98812,14.4024 5.98812,13.7692 6.37864,13.3787 C6.76917,12.9882 7.40233,12.9882 7.79286,13.3787 Z M17,15.75 C17.5523,15.75 18,16.1977 18,16.75 C18,17.26285 17.613973,17.6855092 17.1166239,17.7432725 L17,17.75 L14,17.75 C13.4477,17.75 13,17.3023 13,16.75 C13,16.23715 13.386027,15.8144908 13.8833761,15.7567275 L14,15.75 L17,15.75 Z M17,13.25 C17.5523,13.25 18,13.6977 18,14.25 C18,14.8023 17.5523,15.25 17,15.25 L14,15.25 C13.4477,15.25 13,14.8023 13,14.25 C13,13.6977 13.4477,13.25 14,13.25 L17,13.25 Z M15.5,6.5 C16.0523,6.5 16.5,6.94772 16.5,7.5 L16.5,8 L17,8 C17.5523,8 18,8.44772 18,9 C18,9.55228 17.5523,10 17,10 L16.5,10 L16.5,10.5 C16.5,11.0523 16.0523,11.5 15.5,11.5 C14.9477,11.5 14.5,11.0523 14.5,10.5 L14.5,10 L14,10 C13.4477,10 13,9.55228 13,9 C13,8.44772 13.4477,8 14,8 L14.5,8 L14.5,7.5 C14.5,6.94772 14.9477,6.5 15.5,6.5 Z M10,8 C10.5523,8 11,8.44772 11,9 C11,9.55228 10.5523,10 10,10 L7,10 C6.44772,10 6,9.55228 6,9 C6,8.44772 6.44772,8 7,8 L10,8 Z"
              id="形状"
              fill="#09244BFF"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  </button> -->

  <!-- <div>
    <nz-badge nzDot>
      <i
        nz-icon
        nzType="file-done"
        nz-tooltip="Tâches"
        nzTooltipPlacement="bottom"
        [nzTooltipMouseEnterDelay]="tooltipDelay"
        class="iconLarge"
        (click)="onTodos()"
        [ngClass]="{ dark: theme === 'dark' }"
      ></i>
    </nz-badge>
  </div>
  <div>
    <nz-badge nzDot>
      <i
        nz-icon
        nzType="message"
        nz-tooltip="Messages"
        nzTooltipPlacement="bottom"
        [nzTooltipMouseEnterDelay]="tooltipDelay"
        class="iconLarge"
        (click)="onMessage()"
        [ngClass]="{ dark: theme === 'dark' }"
      ></i>
    </nz-badge>
  </div>
  <div>
    <i
      nz-icon
      nzType="star"
      nz-tooltip="Favoris"
      nzTooltipPlacement="bottom"
      [nzTooltipMouseEnterDelay]="tooltipDelay"
      class="iconLarge"
      (click)="onFavoris()"
      [ngClass]="{ dark: theme === 'dark' }"
    ></i>
  </div> -->
</div>
