import { NzSelectSizeType } from 'ng-zorro-antd/select';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-geo-territoire-search-list-header',
  templateUrl: './geo-territoire-search-list-header.component.html',
  styleUrls: ['./geo-territoire-search-list-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeoTerritoireSearchListHeaderComponent
  implements OnInit, OnDestroy
{
  subscribe = new Subject();
  @Input() type: string = 'list';
  @Input() list: any = [];
  @Input() size: NzSelectSizeType = 'default';
  @Input() isLoading: boolean | any = false;
  @Input() selectedItem$ = new BehaviorSubject<any>(null);
  @Input() placeholder: string = 'Rechercher...';
  @Output() changes = new EventEmitter<any>();
  @Output() clean = new EventEmitter<boolean>();

  searchForm = this.fb.group({
    input: '',
  });

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.onFormChanges();
    this.onItemSelected();
  }
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  onFormChanges(): void {
    this.searchForm
      .get('input')
      ?.valueChanges.pipe(takeUntil(this.subscribe))
      .subscribe((value) => {
        this.changes.emit(value);
      });
  }

  onItemSelected(): void {
    this.selectedItem$
      .pipe(takeUntil(this.subscribe))
      .subscribe((item) => this.searchForm.patchValue({ input: item }));
  }
  onClean() {
    this.clean.emit(true);
  }
}
