<nz-card [nzTitle]="excelJsonHeader" [nzCover]="render" nzBorderless></nz-card>

<ng-template #excelJsonHeader>
  <div class="containerInput">
    <label for="file">
      <mat-icon>file_download</mat-icon>
      <span>{{ selectionMessage }}</span>
    </label>

    <input
      id="file"
      placeholder="Fichier..."
      (change)="fileUpload($event)"
      type="file"
      accept=".xls, .xlsx"
      class="file-input"
    />
  </div>
</ng-template>
