import { QueryOperationEntitesService } from './../../../service/query-operation-entites.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { OperationEntitesService } from 'src/app/service/operation-entites.service';
import { of } from 'rxjs';
import { tap, exhaustMap, mergeMap, map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ItemEventId } from '../../../features/suivi/components/item-event/itemEvent.model';
import * as fromOperationEntiteAction from './operation-entites.actions';

import { NzMessageService } from 'ng-zorro-antd/message';

@Injectable()
export class OperationEntitesEffects {
  //GET ENTITES
  loadEntitesOperation = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationEntiteAction.loadOperationEntites),
      tap(() => fromOperationEntiteAction.loadingState({ loading: true })),
      exhaustMap((action) =>
        this.operationEntiteService.getAll(action.operationId).pipe(
          map((entites: any) =>
            fromOperationEntiteAction.loadOperationEntitesSuccess({
              entites,
            })
          ),
          catchError((error) =>
            of(fromOperationEntiteAction.loadOperationEntitesFailure({ error }))
          )
        )
      ),
      tap(() => fromOperationEntiteAction.loadingState({ loading: false }))
    )
  );

  //GET ENTITE
  loadEntiteOperation = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationEntiteAction.loadOperationEntite),
      tap(() => fromOperationEntiteAction.loadingState({ loading: true })),
      mergeMap((action) =>
        this.operationEntiteService
          .getOne(action.operationId, action.entiteId)
          .pipe(
            map((entite: any) =>
              fromOperationEntiteAction.loadOperationEntiteSuccess({
                entite,
              })
            ),
            catchError((error) =>
              of(
                fromOperationEntiteAction.loadOperationEntiteFailure({ error })
              )
            )
          )
      ),
      tap(() => fromOperationEntiteAction.loadingState({ loading: false }))
    )
  );

  //ADD
  addEntiteOperation = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationEntiteAction.addOperationEntite),
      tap(() => fromOperationEntiteAction.loadingState({ loading: true })),
      mergeMap((action) =>
        this.operationEntiteService
          .addOne(action.operation, action.entite, action.intervention)
          .pipe(
            map((entite: any) => {
              const success: string = `${action.entite.etablissement.denomination} ajouté au projet.`;
              this.message.success(success, { nzDuration: 6000 });
              return fromOperationEntiteAction.addOperationEntiteSuccess({
                entite,
              });
            }),
            catchError((error) =>
              of(fromOperationEntiteAction.addOperationEntiteFailure({ error }))
            )
          )
      ),
      tap(() => fromOperationEntiteAction.loadingState({ loading: false }))
    )
  );

  //DELETE
  deleteEntiteOperation = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationEntiteAction.deleteOperationEntite),
      tap(() => fromOperationEntiteAction.loadingState({ loading: true })),
      mergeMap((action) =>
        this.operationEntiteService
          .deleteOne(action.operationId, action.entiteId)
          .pipe(
            map((success: string) => {
              this.message.success(success, { nzDuration: 6000 });
              return fromOperationEntiteAction.deleteOperationEntiteSuccess({
                success,
              });
            }),
            catchError((error) =>
              of(
                fromOperationEntiteAction.deleteOperationEntiteFailure({
                  error,
                })
              )
            )
          )
      ),
      tap(() => fromOperationEntiteAction.loadingState({ loading: false }))
    )
  );

  //QUERY
  queryOperationEntites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationEntiteAction.queryOperationEntites),
      tap(() => fromOperationEntiteAction.loadingState({ loading: true })),
      mergeMap((action) =>
        this.queryOperationEntitesService
          .queryEntites(action.filter, action.filterType, action.operationId)
          .pipe(
            map((entites: any[]) =>
              fromOperationEntiteAction.queryOperationEntitesSuccess({
                entites,
              })
            ),
            catchError((error) =>
              of(
                fromOperationEntiteAction.queryOperationEntitesFailure({
                  error,
                })
              )
            )
          )
      ),
      tap(() => fromOperationEntiteAction.loadingState({ loading: false }))
    )
  );

  constructor(
    private actions$: Actions,
    private message: NzMessageService,
    private operationEntiteService: OperationEntitesService,
    private queryOperationEntitesService: QueryOperationEntitesService
  ) {}
}
