import {
  ChangeDetectionStrategy,
  Component,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Observable, of, Subject, takeUntil } from 'rxjs';
import { ParticipantId } from 'src/app/components/participant/store/participant.model';
import { ParticipantDocumentState } from './../../store/participant-document.reducer';
import * as fromParticipantDocumentAction from './../../store/participant-document.actions';
import * as fromParticipantDocumentSelector from './../../store/participant-document.selectors';
import { Store } from '@ngrx/store';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';
import { ParticipantState } from 'src/app/components/participant/store/participant.reducer';
import * as fromParticipantSelector from 'src/app/components/participant/store/participant.selectors';

@Component({
  selector: 'app-participant-documents',
  templateUrl: './participant-documents.component.html',
  styleUrls: ['./participant-documents.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantDocumentsComponent implements OnInit, OnChanges {
  subscribe = new Subject();
  documents$: Observable<any> = of([]);
  user$: Observable<any> = of(null);
  participant$: Observable<ParticipantId | any> = of(null);

  constructor(
    private participantDocumentStore: Store<ParticipantDocumentState>,
    private userStore: Store<UserState>,
    private participantStore: Store<ParticipantState>
  ) {}

  ngOnInit(): void {
    this.getUser();
    this.getParticipant();
    this.getDocuments();
  }
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getUser(): void {
    this.user$ = this.userStore.select(fromUserSelector.user);
  }

  getParticipant(): void {
    this.participant$ = this.participantStore.select(
      fromParticipantSelector.participant
    );
  }

  getDocuments(): void {
    this.documents$ = this.participantDocumentStore.select(
      fromParticipantDocumentSelector.documents
    );
  }

  onAdd(events: any, participant: ParticipantId, organisationId: string) {
    if (!events?.length || !participant) return;
    const { id } = participant;
    const documents = events.map((docData: any) => {
      const doc = {
        extension: docData.doc.extension,
        recu: docData.doc.recu,
        title: docData.doc.title,
        type: docData.doc.type,
        valid: docData.doc.valid,
      };
      const file = docData.file;

      return { doc, file };
    });

    this.participantDocumentStore.dispatch(
      fromParticipantDocumentAction.uploadParticipantDocuments({
        organisationId,
        id,
        documents,
      })
    );
  }
  onDelete(document: any, participant: ParticipantId) {
    if (!document || !participant) return;
    const { id } = participant;
    this.participantDocumentStore.dispatch(
      fromParticipantDocumentAction.deleteParticipantDocument({
        participantId: id,
        id: document.id,
      })
    );
  }
  onDeleteAll(participant: ParticipantId) {
    if (!participant) return;
    const { id } = participant;
    this.participantDocumentStore.dispatch(
      fromParticipantDocumentAction.deleteParticipantDocuments({ id, ids: [] })
    );
  }
}
