import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ParticipantId } from '../../store/participant.model';

@Component({
  selector: 'app-participant-situation-tab',
  templateUrl: './participant-situation-tab.component.html',
  styleUrls: ['./participant-situation-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantSituationTabComponent implements OnInit, OnChanges {
  default: string = '#bfbfbf';
  colorValidation: string = '#85a5ff';
  colorAlert: string = '#ff7875';
  avatarBackgroundColor: string = '#1890ff';

  @Input() participant: ParticipantId | any = null;
  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}
}
