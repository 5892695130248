import { AuthService } from 'src/app/service/auth.service';
import { Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  doc,
  docSnapshots,
  collectionChanges,
  getDoc,
  addDoc,
  setDoc,
  deleteDoc,
  serverTimestamp,
  DocumentData,
  Timestamp,
  query,
  where,
  arrayUnion,
  arrayRemove,
  collectionSnapshots,
  CollectionReference,
  DocumentReference,
  DocumentChange,
  DocumentSnapshot,
  getDocs,
  documentId,
  limit,
} from '@angular/fire/firestore';
import { Observable, of, from, EMPTY, BehaviorSubject } from 'rxjs';
import { switchMap, mergeMap, map, catchError } from 'rxjs/operators';

import { Auth, onAuthStateChanged } from '@angular/fire/auth';
import { Store, select } from '@ngrx/store';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';
import { Apollo, gql } from 'apollo-angular';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'any',
})
export class UserService {
  user$ = new BehaviorSubject<any>(null);

  GET_ME = gql`
    query Me {
      me {
        avatar
        claims {
          admin
          dev
          manager
          member
        }
        coordonnees {
          email
          phoneNumber
        }
        organisation {
          id
          nom_raison_sociale
          licence {
            mode
          }
          logo
        }
        createTime
        disabled
        displayName
        fonction
        photo
        emailVerified
        readTime
        id
        updateTime
        service {
          id
          title
        }
        setting {
          color
        }
        searchFields
      }
    }
  `;

  GET_TEAM = gql`
    query Team {
      team {
        users {
          avatar
          coordonnees {
            email
            phoneNumber
          }
          displayName
          fonction
          photo
          id
          service {
            id
            title
          }
          setting {
            color
          }
          searchFields
        }
      }
    }
  `;

  GET_ORGANISATION_USERS = gql`
    query OrganisationUsers {
      organisationUsers {
        users {
          avatar
          claims {
            admin
            dev
            manager
            member
          }
          coordonnees {
            email
            phoneNumber
          }
          displayName
          fonction
          photo
          id
          service {
            title
          }
          setting {
            color
          }
          searchFields
        }
      }
    }
  `;

  GET_CIVILITIES = gql`
    query Referentiel_civilities {
      referentiel_civilities
    }
  `;

  CREATE_USER = gql`
    mutation CreateUser($newuser: NewUserInput!, $type: String!) {
      createUser(input: { newuser: $newuser, type: $type }) {
        id
        displayName
        fonction
        photo
        avatar
        coordonnees {
          email
          phoneNumber
        }
        disabled
        emailVerified
        searchFields
        organisation {
          id
          nom_raison_sociale
          licence {
            mode
          }
          logo
          adresse
          siret
        }
        claims {
          member
          admin
          manager
        }
        service {
          title
          id
        }
      }
    }
  `;

  constructor(
    private auth: Auth,
    private db: Firestore,
    private userStore: Store<UserState>,
    private apollo: Apollo,
    private router: Router
  ) {}

  getMe(): Observable<any> {
    try {
      return this.apollo
        .watchQuery<any>({
          query: this.GET_ME,
        })
        .valueChanges.pipe(
          map(({ data, loading, error }) => {
            return { data, loading, error };
          })
        );
    } catch (error) {
      return of(error);
    }
  }

  getCivilities(): Observable<any> {
    try {
      return this.apollo
        .watchQuery<any>({
          query: this.GET_CIVILITIES,
        })
        .valueChanges.pipe(
          map(({ data, loading, error }) => {
            return { data, loading, error };
          })
        );
    } catch (error) {
      return of(error);
    }
  }

  getTeam(): Observable<any> {
    try {
      return this.apollo
        .watchQuery<any>({
          query: this.GET_TEAM,
        })
        .valueChanges.pipe(
          map(({ data }) => {
            const users: any[] = data?.team?.users ? data.team.users : [];
            return users;
          })
        );
    } catch (error) {
      return of(error);
    }
  }

  getAll(): Observable<any> {
    try {
      return this.apollo
        .watchQuery<any>({
          query: this.GET_ORGANISATION_USERS,
        })
        .valueChanges.pipe(
          map(({ data }) => {
            const users: any = data?.organisationUsers?.users
              ? data.organisationUsers.users
              : [];
            return users;
          })
        );
    } catch (error) {
      return of(error);
    }
  }

  // getAll(): Observable<any> {
  //   try {
  //     return this.userStore.select(fromUserSelector.user).pipe(
  //       switchMap((user) => {
  //         if (!user) EMPTY;

  //         const dbDocument = collection(this.db, `users`);

  //         const result = query(
  //           dbDocument,
  //           where('organisation.id', '==', user.organisation.id)
  //         );

  //         return from(getDocs(result)).pipe(
  //           map((snap) =>
  //             snap.docs.map((doc) => {
  //               const data = doc.data() as any;
  //               const id = doc.id;
  //               return { id, ...data };
  //             })
  //           )
  //         );
  //       })
  //     );
  //   } catch (error) {
  //     return of(error);
  //   }
  // }

  createUser(newuser: any, type: string): Observable<any> {
    try {
      if (!newuser || !type) return EMPTY;
      return this.apollo.mutate({
        mutation: this.CREATE_USER,
        variables: {
          newuser: newuser,
          type: type,
        },
      });
    } catch (error) {
      return of(error);
    }
  }

  userAccess(camelCase: string): Observable<any> {
    this.router.navigate(['/user', ``]);

    return EMPTY;
  }

  getOne(uid: string): Observable<any> {
    try {
      return EMPTY;
      // if (!uid) {
      //   return of(null);
      // } else {
      //   const userRef = doc(this.db, `users/${uid}`);
      //   return from(
      //     getDoc(userRef)
      //       .then((snap: any): any => {
      //         if (!snap.exists) {
      //           return of(null);
      //         } else {
      //           const data = snap.data() as any;
      //           const id: string = snap.id;
      //           const user = { id, ...data };
      //           return user;
      //         }
      //       })
      //       .catch((error) => error)
      //   ).pipe(
      //     mergeMap((res) => {
      //       return of(res);
      //     })
      //   );
      // }
    } catch (error) {
      return of(error);
    }
  }

  getStatistiques(): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) EMPTY;

          const dbDocument = doc(
            this.db,
            `users/${user.id}/statistiques/${user.id}`
          );

          return docSnapshots(dbDocument).pipe(
            map((doc: DocumentSnapshot<DocumentData>) => {
              const data = doc.data() as any;
              const id = doc.id;
              return { id, ...data };
            })
          );
        })
      );
    } catch (err) {
      return of(err);
    }
  }

  //FAVORIS
  getFavorisEntites(): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (user) {
            const dbDocument = doc(this.db, `users/${user.id}/favoris/entites`);

            return docSnapshots(dbDocument).pipe(
              map((doc: DocumentSnapshot<DocumentData>) => {
                const data = doc.data() as any;
                const id = doc.id;
                return { id, ...data };
              })
            );
          } else {
            return of(null);
          }
        })
      );
    } catch {
      return of(null);
    }
  }

  getFavorisContacts(): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (user) {
            const dbDocument = doc(
              this.db,
              `users/${user.id}/favoris/contacts`
            );

            return docSnapshots(dbDocument).pipe(
              map((doc: DocumentSnapshot<DocumentData>) => {
                const data = doc.data() as any;
                const id = doc.id;
                return { id, ...data };
              })
            );
          } else {
            return of(null);
          }
        })
      );
    } catch {
      return of(null);
    }
  }

  getFavorisParticipants(): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (user) {
            const dbDocument = doc(
              this.db,
              `users/${user.id}/favoris/participants`
            );

            return docSnapshots(dbDocument).pipe(
              map((doc: DocumentSnapshot<DocumentData>) => {
                const data = doc.data() as any;
                const id = doc.id;
                return { id, ...data };
              })
            );
          } else {
            return of(null);
          }
        })
      );
    } catch {
      return of(null);
    }
  }

  //TAGS
  getTagsEntites(): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (user) {
            const dbCollection = collection(
              this.db,
              `users/${user.id}/tags/entites/tags`
            );

            return collectionChanges(dbCollection).pipe(
              map((snap: DocumentChange<DocumentData>[]) => {
                return snap.map((doc: DocumentData) => {
                  const data = doc.data() as any;
                  const id = doc.id;
                  return { id, ...data };
                });
              })
            );
          } else {
            return of(null);
          }
        })
      );
    } catch {
      return of(null);
    }
  }

  getTagsContacts(): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (user) {
            const dbCollection = collection(
              this.db,
              `users/${user.id}/tags/contacts/tags`
            );

            return collectionChanges(dbCollection).pipe(
              map((snap: DocumentChange<DocumentData>[]) => {
                return snap.map((doc: DocumentData) => {
                  const data = doc.data() as any;
                  const id = doc.id;
                  return { id, ...data };
                });
              })
            );
          } else {
            return of(null);
          }
        })
      );
    } catch {
      return of(null);
    }
  }

  getTagsParticipants(): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (user) {
            const dbCollection = collection(
              this.db,
              `users/${user.id}/tags/participants/tags`
            );

            return collectionChanges(dbCollection).pipe(
              map((snap: DocumentChange<DocumentData>[]) => {
                return snap.map((doc: DocumentData) => {
                  const data = doc.data() as any;
                  const id = doc.id;
                  return { id, ...data };
                });
              })
            );
          } else {
            return of(null);
          }
        })
      );
    } catch {
      return of(null);
    }
  }

  //USER
  addOne(userItem: any): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!userItem) EMPTY;

          if (!user || !user.claims.dev || !user.claims.admin) EMPTY;

          return this.isExist(userItem, user).pipe(
            switchMap((exists) => {
              return of({ exists, user });
            })
          );
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  addUserPhoto(userItem: any): Observable<any> {
    try {
      return EMPTY;
    } catch (error) {
      return of(error);
    }
  }
  isExist(userItem: any, user: any): Observable<any> {
    try {
      if (user) {
        const { lastName, firstName, coordonnees } = userItem;
        const { email, mobile, phoneNumber } = coordonnees;
        const userCollection = collection(this.db, `users`);

        const queryContacts = query(
          userCollection,
          where('lastName', '==', lastName),
          where('firstName', '==', firstName),
          where('coordonnees.email', '==', email),
          where('coordonnees.mobile', '==', mobile),
          where('coordonnees.phoneNumber', '==', phoneNumber),
          limit(1)
        );

        return from(
          getDocs(queryContacts)
            .then((snap) => {
              return snap.docs.map((doc) => {
                const data = doc.data();
                const id = doc.id;
                return { id, ...data };
              });
            })
            .catch((error) => error)
        ).pipe(mergeMap((res) => of(res)));
      } else {
        return of([]);
      }
    } catch (err) {
      return of(err);
    }
  }

  addConfirmation(userItem: any, user: any): Observable<any> {
    try {
      if (!userItem) EMPTY;
      if (!user || !user.claims.dev || !user.claims.admin) EMPTY;
      const detailsCollection = collection(this.db, `users`);

      return from(
        addDoc(detailsCollection, userItem)
          .then((docRef) => {
            if (!docRef?.id) {
              return of(
                `Une erreur est survenue lors de l\'enregistrement. Veuillez renouveller l\'action.`
              );
            } else {
              return of(`Utilisateur ajouté`);
            }
          })
          .catch((error) => of(error))
      ).pipe(mergeMap((res) => res));
    } catch (error: any) {
      return of(error.message);
    }
  }

  updateOne(id: string | number, userItem: Partial<any>): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !userItem) {
            return of(null);
          } else {
            const docRefId: string = userItem?.id ? userItem.id : '';
            const { civilite, lastName, firstName } = userItem;
            const displayName: string = `${civilite} ${lastName} ${firstName}`;
            const documentRef: DocumentReference<DocumentData> = doc(
              this.db,
              `users/${docRefId}`
            );
            return from(
              setDoc(documentRef, { ...userItem }, { merge: true })
                .then(() => of(`${displayName} modifié.e`))
                .catch((error) => of(error))
            )
              .pipe((res) => res)
              .pipe(mergeMap((res) => res));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  deleteOne(id: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !id) {
            return of(null);
          } else {
            const documentRef: DocumentReference<DocumentData> = doc(
              this.db,
              `users/${id}`
            );

            return from(
              deleteDoc(documentRef)
                .then(() => `Utilisateur supprimé.`)
                .catch((error) => error)
            ).pipe(mergeMap((res) => of(res)));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }
}
