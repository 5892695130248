<nz-comment
  *ngIf="user$ | async as user"
  [nzAuthor]="sujet?.auteur?.displayName"
  [nzDatetime]="dateAndAlertTpl"
>
  <nz-avatar
    nz-comment-avatar
    [nzText]="sujet?.auteur?.avatar"
    nzSize="small"
    [ngStyle]="{
      'background-color': sujet?.auteur?.setting?.color
        ? sujet?.auteur?.setting?.color
        : default
    }"
  ></nz-avatar>
  <nz-comment-content>
    <p
      [innerHTML]="sujet.textHTML | stringhtml"
      *ngIf="!(isUpdate$ | async)"
    ></p>

    <app-text-edithing
      *ngIf="isUpdate$ | async"
      [update]="currentSujetUpdate$"
      (add)="onUpdateSujetText($event)"
    ></app-text-edithing>
    <nz-card
      *ngIf="isComment$ | async"
      nzSize="small"
      nzTitle="Nouveau commentaire"
      [nzExtra]="commentExtra"
    >
      <app-text-edithing
        (add)="onTextCommentChanges($event)"
      ></app-text-edithing>
      <ng-template #commentExtra>
        <button
          nz-button
          (click)="$event.stopPropagation(); onCancelComment()"
          nzType="text"
          nzSize="small"
          nz-tooltip
          nzTooltipPlacement="top"
          nzTooltipTitle="Annuler"
          [nzTooltipMouseEnterDelay]="tooltipDelayEnter"
        >
          Annuler</button
        ><button
          nz-button
          nzType="primary"
          nzSize="small"
          nz-tooltip
          nzTooltipPlacement="top"
          nzTooltipTitle="Valider"
          [nzTooltipMouseEnterDelay]="tooltipDelayEnter"
          (click)="$event.stopPropagation(); onValidateNewComment(user, sujet)"
        >
          Valider
        </button>
      </ng-template>
    </nz-card>
  </nz-comment-content>

  <nz-comment-action>
    <mat-icon
      *ngIf="!(isUpdate$ | async)"
      nz-tooltip
      nzTooltipPlacement="top"
      nzTooltipTitle="Commenter"
      [nzTooltipMouseEnterDelay]="tooltipDelayEnter"
      (click)="$event.stopPropagation(); onComment(sujet)"
      >insert_comment</mat-icon
    >
  </nz-comment-action>
  <nz-comment-action>
    <mat-icon
      *ngIf="!(isUpdate$ | async)"
      nz-tooltip
      nzTooltipPlacement="top"
      [nzTooltipTitle]="sujet?.isDone ? 'Rétirer la réalisation' : 'Réaliser'"
      [nzTooltipMouseEnterDelay]="tooltipDelayEnter"
      (click)="$event.stopPropagation(); onDone(sujet)"
      >fact_check</mat-icon
    >
  </nz-comment-action>
  <nz-comment-action>
    <mat-icon
      *ngIf="!(isUpdate$ | async)"
      nz-tooltip
      nzTooltipPlacement="top"
      [nzTooltipTitle]="sujet?.isWarning ? 'Rétirer l\'alerte' : 'Alerter'"
      [nzTooltipMouseEnterDelay]="tooltipDelayEnter"
      (click)="$event.stopPropagation(); onAlert(sujet)"
      >feedback</mat-icon
    >
  </nz-comment-action>
  <nz-comment-action>
    <mat-icon
      *ngIf="!(isUpdate$ | async)"
      nz-tooltip
      nzTooltipPlacement="top"
      nzTooltipTitle="Modifier"
      (click)="$event.stopPropagation(); onUpdate(sujet)"
      [nzTooltipMouseEnterDelay]="tooltipDelayEnter"
      >edit</mat-icon
    >
  </nz-comment-action>
  <nz-comment-action>
    <mat-icon
      *ngIf="!(isUpdate$ | async)"
      nz-tooltip
      nzTooltipPlacement="top"
      nzTooltipTitle="Supprimer"
      [nzTooltipMouseEnterDelay]="tooltipDelayEnter"
      (click)="$event.stopPropagation()"
      nz-popconfirm
      [nzPopconfirmTitle]="'Supprimer ce sujet ?'"
      nzPopconfirmPlacement="bottom"
      (nzOnConfirm)="onDelete(sujet)"
      (nzOnCancel)="onCancel()"
      >delete</mat-icon
    >
  </nz-comment-action>
  <nz-comment-action>
    <button
      *ngIf="isUpdate$ | async"
      nz-button
      nzSize="small"
      nzType="text"
      (click)="$event.stopPropagation(); onCancelUpdate()"
    >
      Annuler
    </button>
  </nz-comment-action>
  <nz-comment-action>
    <button
      *ngIf="isUpdate$ | async"
      nz-button
      nzType="primary"
      nzSize="small"
      (click)="$event.stopPropagation(); onUpdateValidation(sujet)"
    >
      Valider
    </button>
  </nz-comment-action>

  <ng-container *ngIf="sujet.children && sujet.children.length">
    <app-operation-note-sujet-comment
      *ngFor="let item of sujet.children"
      [sujet]="sujet"
      [comment]="item"
      (update)="onCommentChange($event)"
    ></app-operation-note-sujet-comment>
  </ng-container>

  <ng-template #dateAndAlertTpl>
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="10">
        {{ sujet?.dateStart | dateFr: "time" }}
      </nz-col>
      <nz-col nzSpan="4"></nz-col>
      <nz-col nzSpan="5">
        <nz-tag *ngIf="sujet?.isWarning" nzColor="error"> Alerte </nz-tag>
      </nz-col>
      <nz-col nzSpan="5">
        <nz-tag *ngIf="sujet?.isDone" nzColor="success"> Réalisé </nz-tag>
      </nz-col>
    </nz-row>
  </ng-template>
</nz-comment>
