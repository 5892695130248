import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-note-subtitle-item',
  templateUrl: './note-subtitle-item.component.html',
  styleUrls: ['./note-subtitle-item.component.scss']
})
export class NoteSubtitleItemComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
