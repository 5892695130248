<div [ngSwitch]="type" class="dateContainer">
  <div *ngSwitchCase="'date'" class="dateContent">
    <h5 nz-typography *ngIf="label">{{ labelTiles }}</h5>
    <div [ngClass]="{ borderlessClass: borderless }" class="dateContent">
      <nz-date-picker
        [(ngModel)]="date"
        name="value"
        [nzPlaceHolder]="placeholder"
        [nzShowTime]="time"
        [nzBorderless]="borderless"
        [nzFormat]="format"
        (ngModelChange)="onSelect($event)"
      ></nz-date-picker>
    </div>
  </div>
  <div *ngSwitchCase="'range'">DATES</div>
  <div *ngSwitchDefault>VARIABLES MANQUANTES !</div>
</div>
