import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { OperationGantt } from './operation-gantt.model';
import * as OperationGanttActions from './operation-gantt.actions';

export const operationGanttsFeatureKey = 'operationGantts';

export interface OperationGanttState extends EntityState<OperationGantt> {
  // additional entities state properties
  loading: boolean;
  gantt: any;
  error: any;
}

export const adapter: EntityAdapter<OperationGantt> =
  createEntityAdapter<OperationGantt>();

export const initialState: OperationGanttState = adapter.getInitialState({
  // additional entity state properties
  loading: false,
  gantt: undefined,
  error: undefined,
});

export const reducer = createReducer(
  initialState,
  on(OperationGanttActions.loadOperationGantt, (state, action) => {
    return {
      ...state,
      loading: true,
      error: undefined,
    };
  }),
  on(OperationGanttActions.loadOperationGanttSuccess, (state, action) => {
    return {
      ...state,
      gantt: action.gantt.data?.operationGantt,
    };
  }),
  on(OperationGanttActions.loadOperationGanttFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
