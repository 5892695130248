import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { AccompagnementChampsType } from '../../store/participant-action-accompagnement.model';

@Component({
  selector: 'app-participant-action-accompagnement-type',
  templateUrl: './participant-action-accompagnement-type.component.html',
  styleUrls: ['./participant-action-accompagnement-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantActionAccompagnementTypeComponent
  implements OnInit, OnChanges
{
  @Input() type: AccompagnementChampsType = { code: '', libelle: '' };

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}
}
