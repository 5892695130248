import { ProjetProfessionnelId } from './../../participant/store/participant.model';
import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ProjetProfessionnel } from './projet-professionnel.model';
import * as ProjetProfessionnelActions from './projet-professionnel.actions';

export const projetProfessionnelsFeatureKey = 'projetProfessionnels';

export interface ProjetPrpfessionnelState
  extends EntityState<ProjetProfessionnelId> {
  projetProfessionnel: ProjetProfessionnelId | any;
  statistiques: any;
  loading: boolean;
  formationsSuggestions: any;
  entitesSuggestions: any;
  contactsSuggestions: any;
  formationSuggestionDetail: any;
  formations: any;
  entites: any;
  contacts: any;
  evaluations: any;
  todos: any;
  jobs: any;

  error: any;
}

export const adapter: EntityAdapter<ProjetProfessionnelId> =
  createEntityAdapter<ProjetProfessionnelId>();

export const initialState: ProjetPrpfessionnelState = adapter.getInitialState({
  projetProfessionnel: undefined,
  statistiques: undefined,
  loading: false,
  formationsSuggestions: undefined,
  entitesSuggestions: undefined,
  contactsSuggestions: undefined,
  formationSuggestionDetail: undefined,
  formations: undefined,
  entites: undefined,
  contacts: undefined,
  evaluations: undefined,
  todos: undefined,
  jobs: undefined,
  error: undefined,
});

export const reducer = createReducer(
  initialState,

  on(ProjetProfessionnelActions.updateProjetProfessionnel, (state, action) =>
    adapter.updateOne(action.projetProfessionnel, state)
  ),
  on(ProjetProfessionnelActions.deleteProjetProfessionnel, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(ProjetProfessionnelActions.deleteProjetProfessionnels, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(
    ProjetProfessionnelActions.loadProjetsProfessionnelsSuccess,
    (state, action) => adapter.setAll(action.projetProfessionnels, state)
  ),
  on(ProjetProfessionnelActions.clearProjetProfessionnels, (state) =>
    adapter.removeAll(state)
  ),
  on(
    ProjetProfessionnelActions.loadingProjetsProfessionnels,
    (state, action) => {
      return {
        ...state,
        loading: action.loading,
      };
    }
  ),
  on(
    ProjetProfessionnelActions.loadProjetsProfessionnelsFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
      };
    }
  ),
  on(
    ProjetProfessionnelActions.loadProjetProfessionnelFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
      };
    }
  ),
  on(
    ProjetProfessionnelActions.addProjetProfessionnelFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
      };
    }
  ),
  on(
    ProjetProfessionnelActions.updateProjetProfessionnelFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
      };
    }
  ),
  on(
    ProjetProfessionnelActions.deleteProjetProfessionnelFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
      };
    }
  ),
  //STATISTIQUES
  on(ProjetProfessionnelActions.loadStatistiquesEmploi, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(
    ProjetProfessionnelActions.loadStatistiquesEmploiSuccess,
    (state, action) => {
      return {
        ...state,
        statistiques: action.statistiques,
        loading: false,
      };
    }
  ),
  on(
    ProjetProfessionnelActions.loadStatistiquesEmploiFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
  ),

  //FORMATIONS SUGGESTIONS
  on(ProjetProfessionnelActions.loadFormationsSuggestions, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(
    ProjetProfessionnelActions.loadFormationsSuggestionsSuccess,
    (state, action) => {
      return {
        ...state,
        formationsSuggestions: action.formations,
        loading: false,
      };
    }
  ),
  on(
    ProjetProfessionnelActions.loadFormationsSuggestionsFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
  ),
  on(
    ProjetProfessionnelActions.loadFormationsSuggestionsClear,
    (state, action) => {
      return {
        ...state,
        loading: false,
        formations: undefined,
      };
    }
  ),
  //FORMATIONS
  on(ProjetProfessionnelActions.LoadFormations, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(ProjetProfessionnelActions.LoadFormationsSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      formations: action.formations,
    };
  }),
  on(ProjetProfessionnelActions.LoadFormationsFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  }),

  //FORMATIONS JOBS
  on(ProjetProfessionnelActions.loadFormationsJobs, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(ProjetProfessionnelActions.loadFormationsJobsSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      jobs: action.jobs,
    };
  }),
  on(ProjetProfessionnelActions.loadFormationsJobsFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  }),

  //FORMATIONS SUGGESTIONS
  on(
    ProjetProfessionnelActions.loadFormationSuggestionDetails,
    (state, action) => {
      return {
        ...state,
        loading: true,
      };
    }
  ),
  on(
    ProjetProfessionnelActions.loadFormationSuggestionDetailsSuccess,
    (state, action) => {
      return {
        ...state,
        formationSuggestionDetail: action.details,
        loading: false,
      };
    }
  ),
  on(
    ProjetProfessionnelActions.loadFormationSuggestionDetailsFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
  ),

  //ENTITES SUGGESTIONS
  on(ProjetProfessionnelActions.loadEntitesSuggestions, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(
    ProjetProfessionnelActions.loadEntitesSuggestionsSuccess,
    (state, action) => {
      return {
        ...state,
        entitesSuggestions: action.entitesSuggestions,
        loading: false,
      };
    }
  ),
  on(
    ProjetProfessionnelActions.loadEntitesSuggestionsFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
  ),
  //ENTITE LOAD
  on(ProjetProfessionnelActions.loadEntitesProjetPro, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(
    ProjetProfessionnelActions.loadEntitesProjetProSuccess,
    (state, action) => {
      return {
        ...state,
        loading: false,
        entites: action.entitesProjetPro,
      };
    }
  ),
  on(
    ProjetProfessionnelActions.loadEntitesProjetProFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
  ),

  //TODOS
  //load
  on(ProjetProfessionnelActions.loadTodoProjetpro, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(ProjetProfessionnelActions.loadTodoProjetproSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      todos: action.todos,
    };
  }),
  on(ProjetProfessionnelActions.loadTodoProjetproFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
      loading: false,
    };
  }),
  //add
  on(ProjetProfessionnelActions.addTodoProjetpro, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),

  on(ProjetProfessionnelActions.addTodoProjetproFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
      loading: false,
    };
  }),
  //update
  on(ProjetProfessionnelActions.updateTodoProjetpro, (state, action) => {
    const prevTodos: any[] = state.todos.filter(
      (el: any) => el.id !== action.todo.id
    );
    return {
      ...state,
      loading: true,
      todos: [action.todo, ...prevTodos],
    };
  }),

  on(ProjetProfessionnelActions.updateTodoProjetproFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
      loading: false,
    };
  }),
  //delete
  on(ProjetProfessionnelActions.deleteTodoProjetpro, (state, action) => {
    return {
      ...state,
      loading: true,
      todos: state.todos.filter((todo: any) => todo.id !== action.id),
    };
  }),

  on(ProjetProfessionnelActions.deleteTodoProjetproFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
      loading: false,
    };
  }),

  //EVALUATIONS
  //load
  on(ProjetProfessionnelActions.loadEvaluationProjetpro, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(
    ProjetProfessionnelActions.loadEvaluationProjetproSuccess,
    (state, action) => {
      return {
        ...state,
        loading: false,
        evaluations: action.evaluations,
      };
    }
  ),
  on(
    ProjetProfessionnelActions.loadEvaluationProjetproFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
  ),
  //add
  on(ProjetProfessionnelActions.addEvaluationProjetpro, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),

  on(
    ProjetProfessionnelActions.addEvaluationProjetproFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
  ),
  //update
  on(ProjetProfessionnelActions.updateEvaluationProjetpro, (state, action) => {
    const prevEvaluations: any[] = state.evaluations.filter(
      (el: any) => el.id !== action.evaluation.id
    );
    return {
      ...state,
      loading: true,
      evaluations: [action.evaluation, ...prevEvaluations],
    };
  }),

  on(
    ProjetProfessionnelActions.updateEvaluationProjetproFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
  ),
  //delete
  on(ProjetProfessionnelActions.deleteEvaluationProjetpro, (state, action) => {
    return {
      ...state,
      loading: true,
      evaluations: state.evaluations.filter(
        (evaluation: any) => evaluation.id !== action.id
      ),
    };
  }),

  on(
    ProjetProfessionnelActions.deleteEvaluationProjetproFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
  )
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
