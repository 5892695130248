import { EMPTY, from, lastValueFrom, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ParticipantState } from '../../components/participant/store/participant.reducer';
import * as fromParticipantSelector from '../../components/participant/store/participant.selectors';
import * as fromParticipantAction from '../../components/participant/store/participant.actions';

import { ParticipantSmallId } from '../../components/participant/store/participant.model';

@Injectable({
  providedIn: 'any',
})
export class ParticipantsResolverService {
  constructor(
    private participantStore: Store<ParticipantState>,
    private router: Router
  ) {}

  resolve(
    route: ActivatedRouteSnapshot
  ):
    | Observable<ParticipantSmallId[]>
    | Promise<ParticipantSmallId[] | any>
    | ParticipantSmallId[]
    | any {
    let find = lastValueFrom(this.loadParticipants())
      .then(() => {
        return this.getParticipants();
      })
      .catch(() => {
        this.router.navigate([`/participants`]);
        return EMPTY;
      });

    return from(find);
  }

  getParticipants(): Observable<ParticipantSmallId[] | any> {
    return this.participantStore.select(fromParticipantSelector.participants);
  }

  loadParticipants(): Observable<any> {
    return of(
      this.participantStore.dispatch(fromParticipantAction.loadParticipants())
    );
  }
}
