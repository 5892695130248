<app-layout-list-container
  [list]="diffusions"
  [card]="card"
  [isHeader]="false"
  [extra]="listExtraTpl"
  [height]="'calc(100vh - 30em)'"
  [itemSize]="'50'"
  [headerHeight]="'60px'"
  [isBorder]="false"
  [isBack]="false"
  [isNew]="false"
  [newAddTitle]="newAddTitle"
  [newNoDataTitle]="newNoDataTitle"
  [newDescription]="newDescription"
  [loading$]="loading$"
></app-layout-list-container>

<ng-template #card let-item let-position="idx">
  <app-layout-list-item
    [content]="diffusionCardContentTpl"
    [actions]="itemActionsTpl"
    [cardHeigth]="'120px'"
    class="w-40"
  ></app-layout-list-item>

  <ng-template #itemActionsTpl>
    <div class="w-full flex justify-end">
      <nz-badge [nzCount]="item?.contacts?.length" [nzOffset]="[-5, -8]">
        <button
          class="btn-icon group/button"
          (click)="$event.stopPropagation()"
          nz-popover
          nzPopoverTrigger="click"
          nzPopoverPlacement="bottomRight"
          [nzPopoverContent]="diffusionContactListTpl"
        >
          <svg
            class="iconSvg"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
            />
          </svg>
        </button>
      </nz-badge>

      <ng-container *ngIf="!item?.isClosed">
        <button
          class="btn-icon group/button"
          nz-tooltip
          nzTooltipPlacement="bottom"
          nzTooltipTitle="Modifier la diffusion"
          (click)="$event.stopPropagation(); onSelectDiffusionItem(item)"
        >
          <svg
            class="iconSvg"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 28 28"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
            />
          </svg>
        </button>

        <button
          class="btn-icon group/button"
          (click)="$event.stopPropagation()"
          nz-tooltip
          nzTooltipPlacement="bottom"
          nzTooltipTitle="Clôturer la diffusion"
          (click)="$event.stopPropagation()"
          nz-popconfirm
          nzPopconfirmPlacement="bottom"
          nzPopconfirmTrigger="click"
          [nzPopconfirmTitle]="closeDiffusionType"
          (nzOnConfirm)="onCloseDiffusion(item)"
          (nzOnCancel)="onDeleteCancel()"
        >
          <svg
            class="iconSvg"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 28 28"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
            />
          </svg>
        </button>
      </ng-container>
      <button
        class="btn-icon group/button"
        (click)="$event.stopPropagation()"
        nz-popconfirm
        nzPopconfirmPlacement="bottom"
        nzPopconfirmTrigger="click"
        [nzPopconfirmTitle]="deleteDiffusionType"
        (nzOnConfirm)="onDelete(item)"
        (nzOnCancel)="onDeleteCancel()"
      >
        <svg
          class="iconSvg group-hover/button:text-red-500"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 28 28"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
          />
        </svg>
      </button>
    </div>
  </ng-template>

  <ng-template #diffusionCardContentTpl>
    <div class="w-full flex items-start space-x-4">
      <div class="w-1/4">
        <strong>{{ item?.title | titlecase | carLimitation : 30 }}</strong>
        <div class="mt-2">
          <p class="text-sm">Type : {{ item?.destinataireType }}</p>
          <p class="text-sm">
            Crée le : {{ item?.dateStart | dateFr : "small" }}
          </p>
          <p class="text-sm">
            Modifié le :
            {{ item?.dateUpdate | dateFr : "small" }}
          </p>
        </div>
      </div>
      <div class="w-1/4 space-y-2">
        <p class="text-sm">
          Pilotée par :
          {{ item?.recrutementBy?.displayName | carLimitation : 20 }}
        </p>
        <div>
          <p class="text-sm">
            Modifié par :
            {{ item?.userUpdate?.displayName | carLimitation : 20 }}
          </p>
        </div>
      </div>
      <div class="w-1/4 space-y-2">
        <div class="w-full flex flex-col">
          <span class="text-sm"
            >Terme : {{ item?.endAt | dateFr : "time" }}
          </span>
          <span class="text-gray-800 text-sm">{{
            item?.endAt | dateFr : "distanceSuffix"
          }}</span>
        </div>

        <span class="w-full"
          >Nombre de contacts : {{ item?.contacts | size }}</span
        >
        <span class="w-full"
          >Nombre de profils recherchés : {{ item?.profilNumber }}</span
        >
      </div>
      <div class="w-1/4">
        <app-tag-indicator
          [type]="item?.isClosed ? 'success' : 'process'"
          [text]="item?.isClosed ? 'Clôturée' : 'En cours'"
        ></app-tag-indicator>
      </div>
    </div>
  </ng-template>

  <ng-template #diffusionContactListTpl>
    <app-diffusion-contacts [contacts]="item.contacts"></app-diffusion-contacts>
  </ng-template>
</ng-template>

<ng-template #listExtraTpl>
  <div class="w-full flex items-center space-x-2">
    <button class="btn-default" (click)="onNew($event)">Nouveau</button>
    <app-button-dropdown
      *nzSpaceItem
      [isDeleteAll]="true"
      [isExportEXL]="true"
      [isExportPDF]="true"
      (select)="onExport($event)"
    ></app-button-dropdown>
  </div>
</ng-template>

<ng-template #diffusionFormTitle>
  <app-header-title
    [icon]="'send'"
    [title]="titleDiffusion"
    [subtitle]="true"
  ></app-header-title>
</ng-template>
