import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { EntiteId } from 'src/app/components/entite/store/entite.model';

@Component({
  selector: 'app-side-navigation-submenu-entite',
  templateUrl: './side-navigation-submenu-entite.component.html',
  styleUrls: ['./side-navigation-submenu-entite.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideNavigationSubmenuEntiteComponent implements OnInit {
  tooltipDelay: number = 0.5;

  @Input() collapse: boolean = true;
  @Input() theme: string = 'light';
  @Input() entite: EntiteId | any;
  @Output() select = new EventEmitter<string>(false);
  constructor() {}

  ngOnInit(): void {}

  onSelect(event: string): void {
    if (!event) return;
    this.select.emit(event);
  }
}
