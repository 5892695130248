import { MissionsFormComponent } from './../../../missions/components/missions-form/missions-form.component';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FavoriState } from '../../store/favorie.reducer';
import * as fromFavoriAction from '../../store/favorie.actions';
import * as fromFavoriSelector from '../../store/favorie.selectors';

import { Store, select } from '@ngrx/store';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-favories',
  templateUrl: './favories.component.html',
  styleUrls: ['./favories.component.scss'],
})
export class FavoriesComponent implements OnInit {
  participants$: Observable<any> = of(null);
  entites$: Observable<any> = of(null);
  metiers$: Observable<any> = of(null);
  displayType = new BehaviorSubject<string>('entites');

  //FAVORI METIER
  @ViewChild('metierFormTitle', { static: false })
  metierFormTitle: TemplateRef<any> | any;
  metierFormTitleString: string = 'Nouveau favorie métier';
  modalWidth: number = 820;
  isAddMetier: boolean = false;

  constructor(
    private favoriStore: Store<FavoriState>,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.getFavoris();
  }

  getFavoris(): void {
    this.getEntites();
    this.getParticipants();
  }

  getEntites(): void {
    this.entites$ = this.favoriStore.select(fromFavoriSelector.entites);
  }
  getParticipants(): void {
    this.participants$ = this.favoriStore.select(
      fromFavoriSelector.participants
    );
  }

  loadFavoris(): void {
    this.loadEntites();
    this.loadParticipants();
  }

  loadEntites(): void {
    this.favoriStore.dispatch(fromFavoriAction.loadEntites());
  }
  loadParticipants(): void {
    this.favoriStore.dispatch(fromFavoriAction.loadParticipants());
  }
  loadFavoriesMetiers(): void {}

  onNewFavoriMetier(): void {
    const modal = this.modal.create({
      nzContent: MissionsFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.metierFormTitle,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });
    const instance = modal.getContentComponent();
    instance.cancel.subscribe((): any => {
      modal.close();
    });
    instance.add.subscribe((event: any): any => {
      if (!event) {
        return null;
      } else {
        this.onAddFavoriMetier(event);
        modal.close();
      }
    });
  }

  onAddFavoriMetier(event: any): void {
    console.log(event);
  }
  onRemoveFavoriMetier(event: any): void {
    console.log(event);
  }
}
