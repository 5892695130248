import { createFeatureSelector, createSelector } from '@ngrx/store';
import { authsFeatureKey, AuthState } from './auth.reducer';

export const selectAuthState =
  createFeatureSelector<AuthState>(authsFeatureKey);

export const userAuth = createSelector(
  selectAuthState,
  (state: AuthState) => state.authUser
);

export const loading = createSelector(
  selectAuthState,
  (state: AuthState) => state.loading
);

export const emailValidation = createSelector(
  selectAuthState,
  (state: AuthState) => state.emailCheck
);

export const loaded = createSelector(
  selectAuthState,
  (state: AuthState) => state.loaded
);

export const loadingCheckEmail = createSelector(
  selectAuthState,
  (state: AuthState) => state.loadingCheckEmail
);

export const error = createSelector(
  selectAuthState,
  (state: AuthState) => state.error
);
