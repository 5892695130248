<ng-container *ngIf="note$ | async as note">
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="12">Date : {{ note.dateStart | dateFr: "time" }}</nz-col>
  </nz-row>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="24">
      Adresse :
      {{ note.adresse?.adresse?.properties?.label | carLimitation: 25 }}
    </nz-col>
  </nz-row>
</ng-container>
