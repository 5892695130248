import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Update } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { Timestamp } from 'firebase/firestore';
import { sortBy } from 'lodash';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';

import { OperationNoteSujetId } from '../../store/operation-note-sujet.model';

@Component({
  selector: 'app-operation-note-sujet-comment',
  templateUrl: './operation-note-sujet-comment.component.html',
  styleUrls: ['./operation-note-sujet-comment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationNoteSujetCommentComponent
  implements OnInit, OnChanges, OnDestroy
{
  user$: Observable<any> = of(null);
  subscribe = new Subject();
  isUpdate$ = new BehaviorSubject<boolean>(false);
  currentSujetUpdate$ = new BehaviorSubject<any>(null);
  tooltipDelayEnter: number = 0.5;
  default: string = '#bfbfbf';

  sujetCommentForm: UntypedFormGroup = this.fb.group({});
  commentText: UntypedFormControl = new UntypedFormControl('');
  commentTextHTML: UntypedFormControl = new UntypedFormControl('');
  commentDateStart: UntypedFormControl = new UntypedFormControl('');
  commentDateUpdate: UntypedFormControl = new UntypedFormControl('');
  commentAuteur: UntypedFormControl = new UntypedFormControl('');
  commentWarning: UntypedFormControl = new UntypedFormControl(false);

  @Input() comment: any = null;
  @Input() sujet: OperationNoteSujetId | any = null;
  @Output() update: EventEmitter<any> = new EventEmitter();
  @Output() delete: EventEmitter<any> = new EventEmitter();

  constructor(private fb: UntypedFormBuilder, private userStore: Store<UserState>) {}

  ngOnInit(): void {
    this.getUser();
  }
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getUser(): void {
    this.user$ = this.userStore.select(fromUserSelector.user);
  }

  onDone(sujet: OperationNoteSujetId, comment: any, user: any): void {
    if (!sujet || !comment || !user) return;
    const children: any[] = sujet.children.filter((el) => el.id !== comment.id);

    const nextComment: any = {
      ...comment,
      isDone: !comment.isDone,
      userUpdate: {
        id: user.id,
        displayName: user.displayName,
        avatar: user.avatar,
      },
      dateUpdate: Timestamp.now(),
    };
    const nextChildren: any[] = [...children, nextComment];

    const nextSujet: OperationNoteSujetId = {
      ...sujet,
      children: nextChildren,
    };
    this.update.next(nextSujet);
  }

  onAlert(sujet: OperationNoteSujetId, comment: any, user: any): void {
    if (!sujet || !comment || !user) return;
    const children: any[] = sujet.children.filter((el) => el.id !== comment.id);

    const nextComment: any = {
      ...comment,
      isWarning: !comment.isWarning,
      userUpdate: {
        id: user.id,
        displayName: user.displayName,
        avatar: user.avatar,
      },
      dateUpdate: Timestamp.now(),
    };
    const nextChildren: any[] = [...children, nextComment];

    const nextSujet: OperationNoteSujetId = {
      ...sujet,
      children: nextChildren,
    };
    this.update.next(nextSujet);
  }
  onDelete(sujet: OperationNoteSujetId, comment: any): void {
    if (!sujet || !comment) return;
    const children: any[] = sujet.children.filter((el) => el.id !== comment.id);

    const nextSujet = { ...sujet, children };
    this.update.next(nextSujet);
  }

  onUpdateComment(sujet: OperationNoteSujetId, comment: any, user: any): void {
    if (!sujet || !comment || !user) return;
    const { textHTML } = comment;
    this.currentSujetUpdate$.next(textHTML);
    this.isUpdate$.next(true);

    this.formSujetComment(comment);
  }

  formSujetComment(comment: OperationNoteSujetId): void {
    this.formSujetCommentInit();
    this.formSujetCommentCreate();
    this.formSujetCommentSet(comment);
  }

  formSujetCommentInit(): void {
    this.commentText = new UntypedFormControl('', Validators.required);
    this.commentTextHTML = new UntypedFormControl('', Validators.required);
    this.commentDateStart = new UntypedFormControl(
      Timestamp.now(),
      Validators.required
    );
    this.commentDateUpdate = new UntypedFormControl(
      Timestamp.now(),
      Validators.required
    );
    this.commentAuteur = new UntypedFormControl('');
    this.commentWarning = new UntypedFormControl(false, Validators.required);
  }
  formSujetCommentCreate(): void {
    this.sujetCommentForm = this.fb.group({
      text: this.commentText,
      textHTML: this.commentTextHTML,
      dateStart: this.commentDateStart,
      dateUpdate: this.commentDateUpdate,
      auteur: this.commentAuteur,
      isWarning: this.commentWarning,
    });
  }
  formSujetCommentSet(comment: any): void {
    this.sujetCommentForm.patchValue({ ...comment });
  }

  onCancelComment(): void {
    this.sujetCommentForm.reset();
    this.isUpdate$.next(false);
    this.currentSujetUpdate$.next(null);
  }
  onCommentTextChanges(event: any): void {
    if (!event) return;
    this.sujetCommentForm.patchValue({
      textHTML: event.textHTML,
      text: event.textValue,
    });
  }
  onUpdateValidation(
    user: any,
    sujet: OperationNoteSujetId,
    comment: any
  ): void {
    if (!user || !sujet || !comment || !this.sujetCommentForm.valid) return;
    const { id, displayName, avatar } = user;
    const userUpdate: any = { id, displayName, avatar };
    const commentValue = this.sujetCommentForm.value;
    const nextCommnet: any = {
      ...comment,
      ...commentValue,
      userUpdate: userUpdate,
      dateUpdate: Timestamp.now(),
    };
    const children: any[] = sujet?.children.filter(
      (el) => el.id !== comment.id
    );

    const nextChildren: any[] = [...children, nextCommnet];

    const nextSujet: OperationNoteSujetId = {
      ...sujet,
      children: nextChildren,
    };
    this.update.next(nextSujet);
    this.onCancelComment();
  }

  onDeleteComment(user: any, sujet: OperationNoteSujetId, comment: any): void {
    if (!user || !sujet || !comment) return;
    const children: any[] = sujet.children.filter((el) => el.id !== comment.id);
    const nextSujet: OperationNoteSujetId = { ...sujet, children: children };

    this.update.next(nextSujet);
    this.onCancelComment();
  }
  onCancel(): void {
    return;
  }
}
