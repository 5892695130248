import { of, Observable, BehaviorSubject } from 'rxjs';
import { ProjetProfessionnelFormComponent } from './../projet-professionnel-form/projet-professionnel-form.component';
import {
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef,
  TemplateRef,
  Input,
  OnChanges,
  ChangeDetectionStrategy,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import {
  ParticipantId,
  ProjetProfessionnelId,
} from 'src/app/components/participant/store/participant.model';
import { Store } from '@ngrx/store';
import { ProjetPrpfessionnelState } from '../../store/projet-professionnel.reducer';
import * as fromProjetProAction from '../../store/projet-professionnel.actions';
import * as fromProjetProSelector from '../../store/projet-professionnel.selectors';
import { Update } from '@ngrx/entity';

@Component({
  selector: 'app-projet-professionnel-list',
  templateUrl: './projet-professionnel-list.component.html',
  styleUrls: ['./projet-professionnel-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjetProfessionnelListComponent implements OnInit, OnChanges {
  title: string = 'Projet professionnel';
  titlePage: string = 'Projets professionnels';
  titleForm: string = 'Nouveau projet professionnel';
  projetsProfessionnels$: Observable<ProjetProfessionnelId[] | any> = of(null);
  projetProfessionnel$ = new BehaviorSubject<ProjetProfessionnelId[] | any>(
    null
  );

  @Input() view: string = 'list';
  @Input() participant: ParticipantId | any = null;
  @Output() delete = new EventEmitter<any>(false);

  modalWidth: number = 960;
  newCardTitle: string = 'Nouveau projet professionnel';
  newCardDescription: string = 'Ajouter un projet professionnel au participant';
  newCardDefinition: string =
    "Un projet professionnel se définit comme un processus d'accompagnement d'un participant vers son employabilité dans un éco-système professionnel.";

  @ViewChild('projetProfessionnelTitleForm', { static: false })
  projetProfessionnelTitleForm: TemplateRef<any> | any;

  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private projetProfessionnelStore: Store<ProjetPrpfessionnelState>
  ) {}

  ngOnInit(): void {
    this.getProjetsProfessionnels();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadProjetsProfesssionnels(changes.participant.currentValue);
  }

  getProjetsProfessionnels(): void {
    this.projetsProfessionnels$ = this.projetProfessionnelStore.select(
      fromProjetProSelector.projetsprofessionnels
    );
  }
  getProjetProfessionnel(): void {
    // this.projetProfessionnel$ = this.projetProfessionnelStore.select(
    //   fromProjetProSelector.projetprofessionnel
    // );
  }

  loadProjetsProfesssionnels(participant: ParticipantId): void {
    if (!participant) return;
    this.projetProfessionnelStore.dispatch(
      fromProjetProAction.loadProjetsProfessionnels({ id: participant.id })
    );
  }

  onSelect(projetpro: any, participant: ParticipantId): void {
    if (!participant || !projetpro) return;
    this.view = 'details';
    this.projetProfessionnel$.next(projetpro);
  }

  onNew(participant: ParticipantId): void {
    if (!participant) return;
    this.titleForm = 'Nouveau projet professionnel';
    const modal = this.modal.create({
      nzContent: ProjetProfessionnelFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.projetProfessionnelTitleForm,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });
    const instance = modal.getContentComponent();
    instance.participant.next(participant);
    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
    instance.add.subscribe((projetprofessionnel): any => {
      if (!projetprofessionnel) {
        return null;
      } else {
        this.onAdd(participant, projetprofessionnel);
        modal.close();
      }
    });
  }

  onAdd(participant: ParticipantId, projetprofessionnel: any): void {
    if (!projetprofessionnel) return;
    this.projetProfessionnelStore.dispatch(
      fromProjetProAction.addProjetProfessionnel({
        id: participant.id,
        projetProfessionnel: projetprofessionnel,
      })
    );
  }

  onBack(): void {
    this.view = 'list';
    this.projetProfessionnel$.next(null);
  }

  onUpdate(
    projetprofessionnel: Update<ProjetProfessionnelId>,
    participant: ParticipantId
  ): void {
    if (!projetprofessionnel || !participant) return;
    this.projetProfessionnelStore.dispatch(
      fromProjetProAction.updateProjetProfessionnel({
        id: participant.id,
        projetProfessionnel: projetprofessionnel,
      })
    );
  }

  onExport(projetprofessionnel: any, participant: any): void {
    if (!projetprofessionnel || !participant) return;
    this.projetProfessionnelStore.dispatch(
      fromProjetProAction.exportPDF({
        participant: participant,
        projetpro: projetprofessionnel,
      })
    );
  }

  onDelete(projetprofessionnel: any, participant: ParticipantId): void {
    if (!projetprofessionnel || !participant) return;
    this.projetProfessionnelStore.dispatch(
      fromProjetProAction.deleteProjetProfessionnel({
        participantId: participant.id,
        id: projetprofessionnel.id,
      })
    );
  }
}
