import { OperationNoteSujetId } from './../../../operation-note-sujet/store/operation-note-sujet.model';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-operation-note-export-sujets',
  templateUrl: './operation-note-export-sujets.component.html',
  styleUrls: ['./operation-note-export-sujets.component.scss'],
})
export class OperationNoteExportSujetsComponent implements OnInit {
  @Input() sujets: OperationNoteSujetId[] | any[] = [];

  constructor() {}

  ngOnInit(): void {}
}
