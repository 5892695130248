import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-operation-note-sujet-action',
  templateUrl: './operation-note-sujet-action.component.html',
  styleUrls: ['./operation-note-sujet-action.component.scss']
})
export class OperationNoteSujetActionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
