<nz-select
  [(ngModel)]="userSelected"
  (ngModelChange)="onSelect($event)"
  nzSize="small"
  nzPlaceHolder="Rechercher un collaborateur"
  [nzOptionHeightPx]="40"
  [nzNotFoundContent]="noTeam"
  [nzLoading]="loading$ | async"
  [nzClearIcon]="clearIconTpl"
  nzAllowClear
  [nzMode]="mode"
  [nzMaxTagCount]="8"
  [nzBorderless]="true"
>
  <nz-option
    *ngFor="let member of team$ | async; trackBy: trackBy"
    nzCustomContent
    [nzLabel]="member.user.displayName"
    [nzValue]="member"
  >
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="4"
        ><nz-avatar
          [nzText]="!member.user?.phone ? member.user?.avatar : ''"
          [nzSrc]="member.user?.phone ? member.user?.phone : ''"
          [nzSize]="20"
          [ngStyle]="{
            'background-color': member.user?.setting?.color
              ? member.user?.setting?.color
              : default,
            color: avatarColor
          }"
        ></nz-avatar
      ></nz-col>
      <nz-col nzSpan="20">
        <nz-row nzAlign="middle" nzGutter="8" nzJustify="start"
          ><h5 nz-typography>{{ member.user?.displayName }}</h5></nz-row
        >
      </nz-col>
    </nz-row>
  </nz-option>
</nz-select>

<ng-template #noTeam>
  <app-no-result
    [icon]="'person'"
    [isButton]="false"
    [description]="'Aucun utilisateur'"
  ></app-no-result>
</ng-template>
<ng-template #loading>
  <app-loading></app-loading>
</ng-template>

<ng-template #clearIconTpl>
  <mat-icon>cancel</mat-icon>
</ng-template>

<ng-template #suffixIconTpl>
  <button nz-button nzType="primary" (click)="$event.stopPropagation()">
    <mat-icon
      nz-tooltip
      [nzTooltipMouseEnterDelay]="0.5"
      nzTooltipPlacement="bottom"
      nzTooltipTitle="Ajouter"
      >person_add_alt</mat-icon
    >
  </button>
</ng-template>
