import { takeUntil } from 'rxjs/operators';
import { OperationId } from './../../../operation/store/operation.model';
import { OperationState } from './../../../operation/store/operation.reducer';
import * as fromOperationSelector from './../../../operation/store/operation.selectors';

import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { Module, ModuleId, ModuleSmallId } from '../../store/module.model';
import { ModuleState } from '../../store/module.reducer';
import * as fromModuleAction from '../../store/module.actions';
import * as fromModuleSelector from '../../store/module.selectors';
import { ModuleFormComponent } from '../module-form/module-form.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ModuleImportExcelComponent } from '../module-import-excel/module-import-excel.component';

@Component({
  selector: 'app-module-list',
  templateUrl: './module-list.component.html',
  styleUrls: ['./module-list.component.scss'],
})
export class ModuleListComponent implements OnInit, OnDestroy {
  modules$: Observable<ModuleSmallId[] | any> = of(null);
  operation$: Observable<OperationId | any> = of(null);
  subscribe = new Subject();
  currentModule$: Observable<ModuleId | any> = of(null);

  view$ = new BehaviorSubject<string>('list');
  loading$: Observable<boolean> = of(false);
  title: string = 'Nouveau module';
  titleImport: string = 'Importer vos modules';
  newCardTitle: string = 'Nouveau module';
  newCardTitleClauses: string = 'Nouveau lot';
  newCardDescription: string = 'Ajouter un module';
  noModuleTitle: string = 'Aucun module ajouté';
  newCardDefinition: string =
    "Un module est un allotissement d'un programme, un ensemble d'ateliers, un ensemble d'actions";

  newCardDefinitionClauses: string =
    "Un lot ou marché est une unité autonome d'une opération";

  @ViewChild('moduleFormTitle', { static: false })
  moduleFormTitle: TemplateRef<any> | any;
  @ViewChild('moduleImportTitle', { static: false })
  moduleImportTitle: TemplateRef<any> | any;

  modalWidth: number = 850;
  modalImportWidth: number = 1100;

  @Output() select = new EventEmitter<any>(false);
  constructor(
    private moduleStore: Store<ModuleState>,
    private operationStore: Store<OperationState>,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.getOperation();
    this.getModules();
    this.getModule();
    this.loadModules();
    this.loadModuleTypes();
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getOperation(): void {
    this.operation$ = this.operationStore.select(
      fromOperationSelector.operation
    );
  }
  getModules(): void {
    this.modules$ = this.moduleStore.select(fromModuleSelector.modules);
  }
  getModule(): void {
    this.currentModule$ = this.moduleStore.select(fromModuleSelector.module);
  }

  loadModules(): void {
    this.operation$
      .pipe(takeUntil(this.subscribe))
      .subscribe((operation: OperationId) => {
        if (!operation) {
          return;
        } else {
          this.moduleStore.dispatch(
            fromModuleAction.loadModules({ operationId: operation.id })
          );
        }
      });
  }

  loadModuleTypes(): void {
    this.moduleStore.dispatch(fromModuleAction.loadModuleTypes());
  }

  onSelect(module: ModuleSmallId | any): void {
    if (!module) return;
    this.moduleStore.dispatch(
      fromModuleAction.loadModule({
        operationId: module.operation.id,
        moduleId: module.id,
      })
    );
    this.view$.next('details');
  }

  //ADD NEW MODULE
  onNew(operation: OperationId): void {
    if (!operation) return;
    this.title =
      operation?.type === 'Clauses sociales' ? 'Nouveau lot' : 'Nouveau module';
    const modal = this.modal.create({
      nzContent: ModuleFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.moduleFormTitle,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });
    const instance = modal.getContentComponent();
    instance.operation$ = this.operation$;

    instance.cancel.subscribe(
      (event: { operation: OperationId; module: Module }): any => {
        if (!event) {
          return null;
        } else {
          modal.close();
        }
      }
    );
    instance.add.subscribe((event: any): any => {
      if (!event) {
        return null;
      } else {
        this.onAdd(event);
      }
    });
  }

  onAdd(event: any): void {
    const { operation, module } = event;
    this.moduleStore.dispatch(
      fromModuleAction.addModule({ operationId: operation.id, module })
    );
  }

  onDelete(operation: OperationId, module: ModuleSmallId): void {
    if (!operation || !module) return;
    this.moduleStore.dispatch(
      fromModuleAction.deleteModule({
        operationId: operation.id,
        id: module.id,
      })
    );
  }

  //IMPORT MODULES FROM EXCEL
  onNewImport(operation: OperationId): void {
    if (!operation) return;
    this.title =
      operation?.type === 'Clauses sociales' ? 'Nouveau lot' : 'Nouveau module';
    const modal = this.modal.create({
      nzContent: ModuleImportExcelComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalImportWidth,
      nzFooter: [],
      nzTitle: this.moduleImportTitle,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });
    const instance = modal.getContentComponent();

    instance.cancel.subscribe(
      (event: { operation: OperationId; module: Module }): any => {
        if (!event) {
          return null;
        } else {
          modal.close();
        }
      }
    );
    instance.add.subscribe((event: any): any => {
      if (!event) {
        return null;
      } else {
        this.onImportValidation(event);
        modal.close();
      }
    });
  }

  onImportValidation(modules: any): void {}

  onBack(): void {
    this.view$.next('list');
  }

  onCancel(): void {
    return;
  }
}
