import { EMPTY, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { EntiteState } from '../components/entite/store/entite.reducer';
import * as fromEntiteAction from '../components/entite/store/entite.actions';
import * as fromEntiteSelector from '../components/entite/store/entite.selectors';
import { EntiteSMALLid } from '../components/entite/store/entite.model';

@Injectable({
  providedIn: 'any',
})
export class EntiteResolverService {
  constructor(
    private entiteStore: Store<EntiteState>,
    private router: Router
  ) {}

  resolve(
    route: ActivatedRouteSnapshot
  ):
    | Observable<EntiteSMALLid[]>
    | Promise<EntiteSMALLid[] | any>
    | EntiteSMALLid[]
    | any {
    let id = route.params['id'];
    let find = this.loadEntite(id).toPromise();

    return find
      .then(() => {
        return this.getEntite();
      })
      .catch(() => {
        this.router.navigate(['/entites']);
        return EMPTY;
      });
  }

  getEntite(): Observable<EntiteSMALLid[] | any> {
    return this.entiteStore.select(fromEntiteSelector.entite);
  }

  loadEntite(id: string): Observable<any> {
    return of(this.entiteStore.dispatch(fromEntiteAction.loadEntite({ id })));
  }
}
