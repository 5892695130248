<div class="space-y-8 p-2">
  <div class="w-full p-4 rounded-xl bg-slate-50 space-y-4">
    <div class="w-full flex items-end justify-end gap-8">
      <div *ngIf="multiplicateur">
        <p class="text-xs font-semibold tracking-wider">
          Ratio : {{ multiplicateur }}
        </p>
      </div>
      <div *ngIf="etp">
        <p class="text-xs font-semibold tracking-wider">
          {{ etp }}
        </p>
      </div>
    </div>

    <div claas="w-full flex items-start">
      <p class="text-4xl font-bold">{{ display }}</p>
    </div>
  </div>

  <div class="w-full grid grid-cols-1 p-8">
    <div class="w-full">
      <form
        nz-form
        [formGroup]="calculatorForm"
        nzLayout="vertical"
        class="gap-y-2"
      >
        <div class="w-full flex items-start justify-between gap-2">
          <div class="w-1/2">
            <nz-form-item>
              <nz-form-control>
                <input
                  nz-input
                  nzBorderless
                  placeholder="Montant HT"
                  formControlName="montantInput"
                />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div class="w-1/2">
            <nz-form-item>
              <nz-form-control>
                <input
                  nz-input
                  nzBorderless
                  placeholder="Heures définies"
                  formControlName="heureInput"
                />
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <div class="w-full flex items-start justify-between gap-2">
          <div class="w-1/3">
            <nz-form-item>
              <nz-form-control>
                <input
                  nz-input
                  nzBorderless
                  placeholder="Effort d'insertion"
                  formControlName="effortInput"
                />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div class="w-1/3">
            <nz-form-item>
              <nz-form-control>
                <input
                  nz-input
                  nzBorderless
                  placeholder="Main d'oeuvre"
                  formControlName="mainDoeuvreInput"
              /></nz-form-control>
            </nz-form-item>
          </div>
          <div class="w-1/3">
            <nz-form-item>
              <nz-form-control>
                <input
                  nz-input
                  nzBorderless
                  placeholder="Coût horaire"
                  formControlName="coutHoraire"
                />
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </form>
    </div>
    <div class="w-full flex items-end justify-end space-x-2">
      <div>
        <button
          (click)="$event.preventDefault(); $event.stopPropagation(); clear()"
          class="btn btn-sm btn-ghost"
        >
          Effacer
        </button>
      </div>

      <div>
        <button
          (click)="
            $event.preventDefault();
            $event.stopPropagation();
            performOperationClause()
          "
          class="btn btn-sm btn-primary"
        >
          Évaluer la clause sociale
        </button>
      </div>
    </div>
  </div>
</div>
