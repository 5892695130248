import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TodoState, todosFeatureKey, selectAll } from './todo.reducer';

export const selectTodosState =
  createFeatureSelector<TodoState>(todosFeatureKey);

export const todos = createSelector(selectTodosState, selectAll);

export const todo = createSelector(
  selectTodosState,
  (state: TodoState) => state.todo
);

export const loading = createSelector(
  selectTodosState,
  (state: TodoState) => state.loading
);

export const adding = createSelector(
  selectTodosState,
  (state: TodoState) => state.adding
);

export const progressFile = createSelector(
  selectTodosState,
  (state: TodoState) => state.fileprogress
);

export const error = createSelector(
  selectTodosState,
  (state: TodoState) => state.error
);
