import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { EntiteTask } from './entite-task.model';

//LOADING INDICATOR
export const loadingEntiteTasks = createAction(
  '[EntiteTask/API] Loading EntiteTasks',
  props<{ loading: boolean }>()
);

//FILTER INDICATOR
export const filterEntiteTasks = createAction(
  '[EntiteTask/API] Loading EntiteTasks',
  props<{ filter: boolean }>()
);

//LOAD TASKS
export const loadEntiteTasks = createAction(
  '[EntiteTask/API] Load EntiteTasks',
  props<{ id: string }>()
);
export const loadEntiteTasksSuccess = createAction(
  '[EntiteTask/API] Load EntiteTasks Success',
  props<{ tasks: any[] }>()
);
export const loadEntiteTasksFailure = createAction(
  '[EntiteTask/API] Load EntiteTasks Failure',
  props<{ error: any }>()
);

//LOAD MY TASKS
export const loadEntiteMyTasks = createAction(
  '[EntiteTask/API] Load EntiteMyTasks',
  props<{ id: string }>()
);
export const loadEntiteMyTasksSuccess = createAction(
  '[EntiteTask/API] Load EntiteMyTasks Success',
  props<{ tasks: any[] }>()
);
export const loadEntiteMyTasksFailure = createAction(
  '[EntiteTask/API] Load EntiteMyTasks Failure',
  props<{ error: any }>()
);

//LOAD DAILY TASKS
export const loadEntiteDailyTasks = createAction(
  '[EntiteTask/API] Load EntiteDailyTasks',
  props<{ id: string }>()
);
export const loadEntiteDailyTasksSuccess = createAction(
  '[EntiteTask/API] Load EntiteDailyTasks Success',
  props<{ tasks: any[] }>()
);
export const loadEntiteDailyTasksFailure = createAction(
  '[EntiteTask/API] Load EntiteDailyTasks Failure',
  props<{ error: any }>()
);

//LOAD WEEKLY TASKS
export const loadEntiteWeeklyTasks = createAction(
  '[EntiteTask/API] Load EntiteWeeklyTasks',
  props<{ id: string }>()
);
export const loadEntiteWeeklyTasksSuccess = createAction(
  '[EntiteTask/API] Load EntiteWeeklyTasks Success',
  props<{ tasks: any[] }>()
);
export const loadEntiteWeeklyTasksFailure = createAction(
  '[EntiteTask/API] Load EntiteWeeklyTasks Failure',
  props<{ error: any }>()
);

//LOAD MONTHLY TASKS
export const loadEntiteMonthlyTasks = createAction(
  '[EntiteTask/API] Load EntiteMonthlyTasks',
  props<{ id: string }>()
);
export const loadEntiteMonthlyTasksSuccess = createAction(
  '[EntiteTask/API] Load EntiteMonthlyTasks Success',
  props<{ tasks: any[] }>()
);
export const loadEntiteMonthlyTasksFailure = createAction(
  '[EntiteTask/API] Load EntiteMonthlyTasks Failure',
  props<{ error: any }>()
);

//ADD TASK
export const addEntiteTask = createAction(
  '[EntiteTask/API] Add EntiteTask',
  props<{ task: any; entiteId: string }>()
);
export const addEntiteTaskSuccess = createAction(
  '[EntiteTask/API] Add EntiteTask',
  props<{ success: string }>()
);
export const addEntiteTaskFailure = createAction(
  '[EntiteTask/API] Add EntiteTask',
  props<{ error: any }>()
);

// UPDATE TASK
export const updateEntiteTask = createAction(
  '[EntiteTask/API] Update EntiteTask',
  props<{ task: Update<any>; updateType: string }>()
);
export const updateEntiteTaskSuccess = createAction(
  '[EntiteTask/API] Update EntiteTask',
  props<{ success: string }>()
);
export const updateEntiteTaskFailure = createAction(
  '[EntiteTask/API] Update EntiteTask',
  props<{ error: any }>()
);

//DELETE TASK
export const deleteEntiteTask = createAction(
  '[EntiteTask/API] Delete EntiteTask',
  props<{ entiteId: string; id: string }>()
);
export const deleteEntiteTaskSuccess = createAction(
  '[EntiteTask/API] Delete EntiteTask Success',
  props<{ success: string }>()
);
export const deleteEntiteTaskFailure = createAction(
  '[EntiteTask/API] Delete EntiteTask Failure',
  props<{ error: any }>()
);

//DELETE ALL TASKS
export const deleteEntiteTasks = createAction(
  '[EntiteTask/API] Delete EntiteTasks',
  props<{ ids: string[]; entiteId: string }>()
);
export const deleteEntiteTasksSuccess = createAction(
  '[EntiteTask/API] Delete EntiteTasks Success',
  props<{ success: string }>()
);
export const deleteEntiteTasksFailure = createAction(
  '[EntiteTask/API] Delete EntiteTasks Failure',
  props<{ error: any }>()
);

export const clearEntiteTasks = createAction(
  '[EntiteTask/API] Clear EntiteTasks'
);
