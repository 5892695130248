import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-card-actions',
  template: `
    <nz-card
      nzBorderless
      cdk-virtual-scroll-viewport
      itemSize="120"
      class="viewport"
    >
      <div class="viewport">
        <ng-content></ng-content>
      </div>
    </nz-card>
  `,
  styles: [
    `
      nz-card {
        width: 100%;
        height: 100% !important;
        border-radius: 12px;
        margin: 2px;
      }

      ng-content {
        height: 100%;
        border-radius: 12px;
        margin: 2px;
        overflow-y: auto;
      }

      nz-row {
        width: 100%;
      }

      .viewport {
        width: 100%;
        overflow-x: hidden !important;
        height: calc(100vh - 440px);
        background-color: #fff;
      }
    `,
  ],
})
export class CardActionsComponent implements OnInit {
  @Input() source: any[] = [];
  @Output() select = new EventEmitter<any>(false);
  constructor() {}

  ngOnInit(): void {}

  onSelect(item: string): void {
    this.select.emit(item);
  }
}
