import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  UntypedFormArray,
  FormControl,
  FormGroup,
} from '@angular/forms';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { Observable, of } from 'rxjs';
import { add } from 'date-fns';
import { Store } from '@ngrx/store';
import { ParticipantAccompagnementState } from '../../store/participant-action-accompagnement.reducer';
import * as fromParticipantAccompagnementSelector from '../../store/participant-action-accompagnement.selectors';
import { Timestamp } from 'firebase/firestore';
import { Update } from '@ngrx/entity';
import { capitalize } from 'lodash';

@Component({
  selector: 'app-participant-actions-accompagnement-form',
  templateUrl: './participant-actions-accompagnement-form.component.html',
  styleUrls: ['./participant-actions-accompagnement-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantActionsAccompagnementFormComponent implements OnInit {
  today = new Date();
  dateFormat: string = 'dd/MM/yyyy';
  dates: Date[] = [this.today, add(this.today, { months: 2 })];
  datesModel: any;
  step: number = 0;
  champs$: Observable<any[]> = of([]);
  types$: Observable<any[]> = of([]);

  listActions$: Observable<string[]> = of([
    'Action emploi',
    'Action accès aux droit & citoyenneté',
    'Action accompagnement global individualisé',
    'Action apprendre le fraiçais',
    "Action création d'activité",
    'Action Equipement et alimentation',
    'Action famille',
    'Action handicap',
    'Action Illettrisme',
    'Action logement - hébergement',
    'Action mobilité',
    'Action numérique',
    'Action rémobilisation',
    'Action santé',
    'Action justice',
  ]);

  actionAccompagnementForm = new FormGroup({});
  champs = new FormControl<{ code: string; libelle: string }>(
    { code: '', libelle: '' },
    Validators.required
  );
  type = new FormControl<string>('', Validators.required);
  dateStart = new FormControl<Date>(this.today, Validators.required);
  dateEnd = new FormControl<Date>(
    add(this.today, { months: 2 }),
    Validators.required
  );
  title = new FormControl<string>('', Validators.required);
  actions = new UntypedFormArray([]);
  duration = new FormControl<number>(2);
  description = new FormControl<string>('');
  objectif = new FormControl<string>('', Validators.required);
  isCompleted = new FormControl<boolean>(false, Validators.required);
  isCollectif = new FormControl<boolean>(false, Validators.required);
  isIndividuel = new FormControl<boolean>(true, Validators.required);

  actionsSociales = new FormControl<any[]>([]);
  actionsProfessionnelles = new FormControl<any[]>([]);

  @Output() add = new EventEmitter(false);
  @Output() update = new EventEmitter(false);
  @Output() cancel = new EventEmitter(false);

  constructor(
    private fb: UntypedFormBuilder,
    private participantAccompagnementStore: Store<ParticipantAccompagnementState>
  ) {}

  ngOnInit(): void {
    this.getParticipantAccompagnementChamps();
    this.getParticipantAccompagnementTypes();
    this.form();
  }

  form(): void {
    this.formInit();
    this.formCreate();
    this.formSet();
  }

  formInit(): void {
    this.champs = new FormControl<{ code: string; libelle: string }>(
      { code: '', libelle: '' },
      Validators.required
    );
    this.type = new FormControl<string>('', Validators.required);
    this.dateStart = new FormControl<Date>(this.today, Validators.required);
    this.dateEnd = new FormControl<Date>(
      add(this.today, { months: 2 }),
      Validators.required
    );
    this.objectif = new FormControl<string>('', Validators.required);

    this.actions = new UntypedFormArray([]);
    this.duration = new FormControl<number>(2);
    this.description = new FormControl<string>('');
    this.isCompleted = new FormControl<boolean>(false, Validators.required);
    this.isCollectif = new FormControl<boolean>(false, Validators.required);
    this.isIndividuel = new FormControl<boolean>(true, Validators.required);

    this.actionsSociales = new FormControl<any[]>([]);
    this.actionsProfessionnelles = new FormControl<any[]>([]);
  }
  formCreate(): void {
    this.actionAccompagnementForm = this.fb.group({
      type: this.type,
      dateStart: this.dateStart,
      dateEnd: this.dateEnd,
      duration: this.duration,
      champs: this.champs,
      actions: this.actions,
      description: this.description,
      objectif: this.objectif,
      isCompleted: this.isCompleted,
      isCollectif: this.isCollectif,
      isIndividuel: this.isIndividuel,
      actionsSociales: this.actionsSociales,
      actionsProfessionnelles: this.actionsProfessionnelles,
    });
  }

  formSet(): void {}

  onChangeDates(dates: Date[]): void {
    if (!dates.length) return;

    this.actionAccompagnementForm.patchValue({
      dateStart: dates[0],
      dateEnd: dates[1],
    });
  }

  getParticipantAccompagnementChamps(): void {
    this.champs$ = this.participantAccompagnementStore.select(
      fromParticipantAccompagnementSelector.champs
    );
  }
  getParticipantAccompagnementTypes(): void {
    this.types$ = this.participantAccompagnementStore.select(
      fromParticipantAccompagnementSelector.types
    );
  }

  setDateEndFromDuration(): void {
    this.actionAccompagnementForm.valueChanges.subscribe((values: any) => {
      const { duration, dateStart } = values;
      if (!duration || duration !== 0 || !dateStart) return;
      this.actionAccompagnementForm.patchValue({
        dateEnd: add(dateStart, { months: duration }),
      });
    });
  }

  onAdd(): void {
    if (!this.actionAccompagnementForm.valid) return;

    const values: any = this.actionAccompagnementForm.value;

    const start = Timestamp.fromDate(values.dateStart);
    const end = Timestamp.fromDate(values.dateEnd);

    const accompagnement = {
      type: values.type,
      description: values.description.trim(),
      dateStart: start,
      dateEnd: end,
      objectif: capitalize(values.objectif.trim()),
      champs: values.champs,
      isCollectif: values.isCollectif,
      isIndividuel: values.isIndividuel,
    };

    this.add.emit(accompagnement);
    this.actionAccompagnementForm.reset();
  }
  onUpdate(accompagnement: any): void {
    if (!this.actionAccompagnementForm.valid) return;

    const values: any = this.actionAccompagnementForm.value;

    const start = Timestamp.fromDate(values.dateStart);
    const end = Timestamp.fromDate(values.dateEnd);

    const newAccompagnement = {
      type: values.type,
      title: values.title,
      description: values.description,
      dateStart: start,
      dateEnd: end,
      objectif: values.objectif,
      isCollectif: values.isCollectif,
      isIndividuel: values.isIndividuel,
    };

    const nextAccompagnement: Update<any> = {
      id: accompagnement.id,
      changes: { ...newAccompagnement },
    };

    this.update.emit(nextAccompagnement);
    this.actionAccompagnementForm.reset();
  }

  onCancel(): void {
    this.cancel.emit(true);
    this.actionAccompagnementForm.reset();
  }
}
