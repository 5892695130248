import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  OperationFicheCandidatDocumentState,
  operationFicheCandidatDocumentsFeatureKey,
  selectAll,
} from './operation-fiche-candidat-document.reducer';

export const selectOperationFicheCandidatDocumentState =
  createFeatureSelector<OperationFicheCandidatDocumentState>(
    operationFicheCandidatDocumentsFeatureKey
  );

export const documents = createSelector(
  selectOperationFicheCandidatDocumentState,
  selectAll
);

export const document = createSelector(
  selectOperationFicheCandidatDocumentState,
  (state: OperationFicheCandidatDocumentState) => state.document
);

export const loading = createSelector(
  selectOperationFicheCandidatDocumentState,
  (state: OperationFicheCandidatDocumentState) => state.loading
);
export const success = createSelector(
  selectOperationFicheCandidatDocumentState,
  (state: OperationFicheCandidatDocumentState) => state.success
);

export const view = createSelector(
  selectOperationFicheCandidatDocumentState,
  (state: OperationFicheCandidatDocumentState) => state.view
);

export const error = createSelector(
  selectOperationFicheCandidatDocumentState,
  (state: OperationFicheCandidatDocumentState) => state.error
);
