<nz-row nzAlign="middle" nzGutter="8" nzJustify="center">
  <nz-col nzXl="12" nzXXl="12" nzLg="24" nzXs="24" nzSm="24" nzMd="24">
    <nz-card class="page-header-card">
      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col nzSpan="8" [nzFlex]="1">
          <nz-statistic
            [nzValueTemplate]="progressStatisticValueTpl"
            [nzValue]="''"
            [nzTitle]="'Progression'"
          ></nz-statistic>
          <ng-template #progressStatisticValueTpl>
            <h2 nz-typography>
              {{ module?.progressionHours | percent }}
            </h2>
          </ng-template>
        </nz-col>
        <nz-col nzSpan="16">
          <nz-row nzJustify="start" nzGutter="8" nzAlign="middle">
            <nz-col nzSpan="2">
              <mat-icon class="module_progress_timer">ssid_chart</mat-icon>
            </nz-col>
            <nz-col nzSpan="22">
              <nz-progress
                *ngIf="module?.progressionHours < 1"
                [nzPercent]="module?.progressionHours * 100"
                [nzStrokeColor]="progressDefaultColor"
                [nzShowInfo]="true"
              ></nz-progress>
              <nz-progress
                *ngIf="module?.progressionHours >= 1"
                [nzPercent]="module?.progressionHours * 100"
                [nzPercent]="100"
                [nzStrokeColor]="progressCompleteColor"
                [nzShowInfo]="true"
              ></nz-progress>
            </nz-col>
          </nz-row>
          <nz-row nzJustify="start" nzGutter="8" nzAlign="middle">
            <nz-col nzSpan="2">
              <mat-icon class="module_progress_timer">date_range</mat-icon>
            </nz-col>
            <nz-col nzSpan="6">
              <small>
                {{ module?.calendrier?.dateStart | dateFr: "small" }}
              </small>
            </nz-col>
            <nz-col nzSpan="4"> </nz-col>
            <nz-col nzSpan="12">
              <small *ngIf="module?.progressionHours < 1">{{
                module?.calendrier?.dateEnd | dateFr: "distanceSuffix"
              }}</small>
              <small *ngIf="module?.progressionHours >= 1">
                {{ module?.calendrier?.dateEnd | dateFr: "small" }}
              </small>
            </nz-col>
          </nz-row>
        </nz-col>
      </nz-row>
    </nz-card>
  </nz-col>
  <nz-col nzXl="12" nzXXl="12" nzLg="0" nzXs="0" nzSm="0" nzMd="0">
    <nz-card class="page-header-card">
      <nz-row nzAlign="middle" nzGutter="8" nzJustify="center">
        <nz-col nzSpan="8">
          <nz-statistic
            [nzValueTemplate]="realisationStatisticValueTpl"
            [nzValue]=""
            [nzTitle]="'Réalisation'"
          ></nz-statistic>
          <ng-template #realisationStatisticValueTpl>
            <h3 nz-typography>
              {{
                module?.realisations
                  ? (module?.realisations | number: "1.1-2":"fr")
                  : 0
              }}
            </h3>
          </ng-template>
        </nz-col>
        <nz-col nzSpan="8">
          <nz-statistic
            [nzValueTemplate]="objectifStatisticValueTpl"
            [nzValue]=""
            [nzTitle]="'Objectif'"
          ></nz-statistic>
          <ng-template #objectifStatisticValueTpl>
            <h3 nz-typography>
              {{
                module?.objectifs
                  ? (module?.objectifs | number: "1.1-2":"fr")
                  : 0
              }}
            </h3>
          </ng-template>
        </nz-col>
        <nz-col nzSpan="8">
          <nz-statistic
            [nzValueTemplate]="collaborationStatisticValueTpl"
            [nzValue]=""
            [nzTitle]="'Collaboration'"
            [nzPrefix]="collaborationSuffixTpl"
            [nzValueStyle]="{
              'font-size': '16px',
              'margin-top': '-6px'
            }"
          ></nz-statistic>
          <ng-template #collaborationStatisticValueTpl>
            <p nz-typography>
              {{
                module?.collaboration === "2"
                  ? "Bonne"
                  : module?.collaboration === "1"
                  ? "Acceptable"
                  : "Mauvaise"
              }}
            </p>
          </ng-template>
          <ng-template #collaborationSuffixTpl>
            <mat-icon class="warning">flag</mat-icon>
          </ng-template>
        </nz-col>
      </nz-row>
    </nz-card>
  </nz-col>
</nz-row>
