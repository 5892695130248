import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';
import { UtilitiesState } from 'src/app/contents/components/utilities/store/utilities.reducer';
import * as fromUtilitiesSelector from 'src/app/contents/components/utilities/store/utilities.selectors';
import * as fromUtilitiesAction from 'src/app/contents/components/utilities/store/utilities.actions';
import { ParticipantId } from 'src/app/components/participant/store/participant.model';
import { ParticipantState } from 'src/app/components/participant/store/participant.reducer';
import * as fromParticipantSelector from 'src/app/components/participant/store/participant.selectors';
import { Timestamp } from 'firebase/firestore';
import { differenceInYears, fromUnixTime, isDate } from 'date-fns';

@Component({
  selector: 'app-participant-diagnostic-form-administratif',
  templateUrl: './participant-diagnostic-form-administratif.component.html',
  styleUrls: ['./participant-diagnostic-form-administratif.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantDiagnosticFormAdministratifComponent
  implements OnInit, OnChanges, OnDestroy
{
  participant$: Observable<ParticipantId | any> = of(null);

  //VARIBALES
  allocation_type$: Observable<ParticipantId | any> = of(null);
  criteres$: Observable<ParticipantId | any> = of(null);
  titres_sejours$: Observable<ParticipantId | any> = of(null);
  subscribe = new Subject();
  dateFormat: string = 'dd/MM/yyyy';
  today: Date = new Date(Date.now());
  isFreinTitle: string =
    'La situation administrative est-elle un frein identifié ?';

  //FORM FIELD
  administratifForm: FormGroup = this.fb.group({});
  dateRecrutement: FormControl<Date | any> = new FormControl(
    this.today,
    Validators.required
  );
  dateInscription: FormControl<Date | any> = new FormControl<Date | any>(null);
  dateStartPassIae: FormControl<Date | any> = new FormControl<Date | any>(null);
  dateEndPassIae: FormControl<Date | any> = new FormControl<Date | any>(null);

  dateEntree: FormControl<Date | any> = new FormControl<Date | any>(null);
  numbCarteSejour: FormControl<string | any> = new FormControl('');
  numCaiCir: FormControl<string | any> = new FormControl('');
  criteres1: FormControl<{ label: string; value: string } | any> =
    new FormControl({}, Validators.required);
  criteres2: FormControl<{ info: string; item: string }[] | any> =
    new FormControl([]);
  typeCarteSejour: FormControl<
    { info: string; titre: string; type: string } | any
  > = new FormControl({ info: '', titre: '', type: '' });
  isFse: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );
  isPassIae: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );

  //LANGUE PARLÉES
  //EU - HORS EU - Non connu

  isFrein: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );

  //BIND
  @Input() administratif$ = new BehaviorSubject<any>(null);
  @Input() isDatesValidation: boolean = true;
  @Input() isFreinValidation: boolean = true;

  @Output() add = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private utilitiesStore: Store<UtilitiesState>,
    private participantStore: Store<ParticipantState>
  ) {}

  ngOnInit(): void {
    this.getParticipant();
    this.setParticipantInfo();
    this.getAllocationsReferentiel();
    this.loadAllocationsReferentiel();
    this.getSejourReferentiel();
    this.loadSejourReferentiel();
    this.getCriteresReferentiel();
    this.loadCriteresReferentiel();
    this.form();
    this.onFormChanges();
  }
  ngOnChanges(changes: SimpleChanges): void {}

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  //GET CURRENT PARTICIPANT
  getParticipant(): void {
    this.participant$ = this.participantStore.select(
      fromParticipantSelector.participant
    );
  }

  //SET CURRENT PARTICIPANT FIELDS
  setParticipantInfo(): void {
    this.participant$
      .pipe(takeUntil(this.subscribe))
      .subscribe((participant) => {
        if (!participant) return;
      });
  }

  //GET ALLOCATIONS REFERENTIELS
  getAllocationsReferentiel(): void {
    this.allocation_type$ = this.utilitiesStore.select(
      fromUtilitiesSelector.allocation
    );
  }

  //LOAD ALLOCATIONS REFERENTIELS
  loadAllocationsReferentiel(): void {
    this.utilitiesStore.dispatch(
      fromUtilitiesAction.loadAllocationReferentiel()
    );
  }

  //GET IDENTITY SEJOUR REFERENTIELS
  getSejourReferentiel(): void {
    this.titres_sejours$ = this.utilitiesStore.select(
      fromUtilitiesSelector.sejours
    );
  }

  //LOAD IDENTITY SEJOUR REFERENTIELS
  loadSejourReferentiel(): void {
    this.utilitiesStore.dispatch(fromUtilitiesAction.loadSejourReferentiel());
  }

  //GET CRITERES REFERENTIELS
  getCriteresReferentiel(): void {
    this.criteres$ = this.utilitiesStore.select(fromUtilitiesSelector.criteres);
  }

  //LOAD CRITERES REFERENTIELS
  loadCriteresReferentiel(): void {
    this.utilitiesStore.dispatch(fromUtilitiesAction.loadCriteresReferentiel());
  }

  //FORM
  form(): void {
    this.formInit();
    this.formCreate();
    this.formSet();
  }

  //FORM INIT FIELDS
  formInit(): void {
    this.dateRecrutement = new FormControl<Date | any>(
      null,
      Validators.required
    );
    this.dateInscription = new FormControl<Date | any>(null);
    this.dateEntree = new FormControl<Date | any>(null);
    this.numbCarteSejour = new FormControl('');
    this.numCaiCir = new FormControl('');
    this.criteres1 = new FormControl<{
      justificatif: string;
      label: string;
      value: string;
    }>({ justificatif: '', label: '', value: '' }, Validators.required);
    this.typeCarteSejour = new FormControl<{
      info: string;
      titre: string;
      type: string;
    }>({ info: '', titre: '', type: '' });
    this.criteres2 = new FormControl<{ info: string; item: string }[] | any>(
      [],
      Validators.required
    );
    this.isPassIae = new FormControl(false, Validators.required);

    this.isFse = new FormControl(false, Validators.required);
    this.isFrein = new FormControl<boolean>(false, Validators.required);
    this.dateStartPassIae = new FormControl<Date | any>(null);
    this.dateEndPassIae = new FormControl<Date | any>(null);
  }

  //FORM CREATE
  formCreate(): void {
    this.administratifForm = this.fb.group({
      dateRecrutement: this.dateRecrutement,
      dateInscription: this.dateInscription,
      dateEntree: this.dateEntree,
      numbCarteSejour: this.numbCarteSejour,
      numCaiCir: this.numCaiCir,
      criteres1: this.criteres1,
      typeCarteSejour: this.typeCarteSejour,
      criteres2: this.criteres2,
      isPassIae: this.isPassIae,
      isFse: this.isFse,
      isFrein: this.isFrein,
      dateStartPassIae: this.dateStartPassIae,
      dateEndPassIae: this.dateEndPassIae,
    });
  }

  //FORM SET UPDATE ITEM
  formSet(): void {
    this.administratif$
      .pipe(takeUntil(this.subscribe))
      .subscribe((administratif) => {
        if (!administratif || !administratif?.criteres1?.label) return;

        const dateRecrutement: Date | any =
          administratif.dateRecrutement &&
          administratif.dateRecrutement?.['seconds'] > 0 &&
          isDate(fromUnixTime(administratif?.dateRecrutement?.['seconds']))
            ? fromUnixTime(administratif?.dateRecrutement?.['seconds'])
            : null;

        const dateEndPassIae: Date | any =
          administratif.dateRecrutement &&
          administratif.dateEndPassIae?.['seconds'] > 0 &&
          isDate(fromUnixTime(administratif?.dateEndPassIae?.['seconds']))
            ? fromUnixTime(administratif?.dateEndPassIae?.['seconds'])
            : null;

        const dateStartPassIae: Date | any =
          administratif.dateRecrutement &&
          administratif.dateEndPassIae?.['seconds'] > 0 &&
          isDate(fromUnixTime(administratif?.dateEndPassIae?.['seconds']))
            ? fromUnixTime(administratif?.dateEndPassIae?.['seconds'])
            : null;

        const dateInscription: Date | any =
          administratif.dateInscription &&
          administratif.dateInscription?.['seconds'] > 0 &&
          isDate(fromUnixTime(administratif?.dateInscription?.['seconds']))
            ? fromUnixTime(administratif?.dateInscription?.['seconds'])
            : null;

        const dateEntree: Date | any =
          administratif.dateEntree &&
          administratif.dateEntree?.['seconds'] > 0 &&
          isDate(fromUnixTime(administratif?.dateEntree?.['seconds']))
            ? fromUnixTime(administratif?.dateEntree?.['seconds'])
            : null;

        this.administratifForm.patchValue({
          ...administratif,
          dateRecrutement: dateRecrutement,
          dateInscription: dateInscription,
          dateEntree: dateEntree,
          dateEndPassIae: dateEndPassIae,
          dateStartPassIae: dateStartPassIae,
        });
      });
  }

  //COMPARE SELECT LIST UPDATE
  compareCritere1(obj1: any, obj2: any) {
    return obj1 && obj2 && obj1.value === obj2.value;
  }
  compareCritere2(obj1: any, obj2: any) {
    return obj1 && obj2 && obj1.item === obj2.item;
  }
  compareSejour(obj1: any, obj2: any) {
    return obj1 && obj2 && obj1.type === obj2.type && obj1.titre === obj2.titre;
  }

  //CHECKBOX CHANGES
  checkboxChanges(event: boolean, type: string): void {
    switch (type) {
      case 'neConnaitSesDroitsAucuneDemarche':
        this.administratifForm.patchValue({
          neConnaitSesDroitsAucuneDemarche: event,
        });

        break;
      case 'difficulteDeRenouvellementDeDroit':
        this.administratifForm.patchValue({
          difficulteDeRenouvellementDeDroit: event,
        });

        break;
      case 'connaitSesDroitsDemarcheEnCours':
        this.administratifForm.patchValue({
          connaitSesDroitsDemarcheEnCours: event,
        });

        break;
      case 'beneficieDeSesDroits':
        this.administratifForm.patchValue({
          beneficieDeSesDroits: event,
        });

        break;
      case 'rgpd':
        this.administratifForm.patchValue({
          consentementRGPD: event,
        });

        break;
      case 'droitDeTravail':
        this.administratifForm.patchValue({
          droitDeTravail: event,
        });

        break;
      case 'image':
        this.administratifForm.patchValue({
          droitAimage: event,
        });

        break;

      default:
        break;
    }
  }

  //FORM CHANGES
  onFormChanges(): void {
    this.administratifForm.valueChanges
      .pipe(takeUntil(this.subscribe))
      .subscribe((values) => {
        const {
          dateRecrutement,
          datetInscription,
          dateEntree,
          dateStartPassIae,
          dateEndPassIae,
        } = values;
        const recrutementDate =
          dateRecrutement && isDate(dateRecrutement)
            ? Timestamp.fromDate(dateRecrutement)
            : Timestamp.now();
        const inscriptionDate =
          datetInscription && isDate(datetInscription)
            ? Timestamp.fromDate(datetInscription)
            : { seconds: 0, nanoseconds: 0 };
        const entreeDate =
          dateEntree && isDate(dateEntree)
            ? Timestamp.fromDate(dateEntree)
            : { seconds: 0, nanoseconds: 0 };

        const startPassIae =
          dateStartPassIae && isDate(dateStartPassIae)
            ? Timestamp.fromDate(dateStartPassIae)
            : { seconds: 0, nanoseconds: 0 };

        const endPassIae =
          dateEndPassIae && isDate(dateEndPassIae)
            ? Timestamp.fromDate(dateEndPassIae)
            : { seconds: 0, nanoseconds: 0 };

        const newtValue = {
          ...values,
          dateRecrutement: recrutementDate,
          dateInscription: inscriptionDate,
          dateEntree: entreeDate,
          dateStartPassIae: startPassIae,
          dateEndPassIae: endPassIae,
        };
        this.add.emit(newtValue);
      });
  }
}
