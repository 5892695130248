import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { isNull, isUndefined } from 'lodash';
import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-operation-dashboard-entites',
  templateUrl: './operation-dashboard-entites.component.html',
  styleUrls: ['./operation-dashboard-entites.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationDashboardEntitesComponent
  implements OnInit, OnChanges, OnDestroy
{
  subscribe = new Subject();
  noExist = 'Non renseigné';
  //ENTITES
  entites$ = new BehaviorSubject<any>([]);
  economie_sociale_solidaire$ = new BehaviorSubject<any>(null);
  grandDomaine_code$ = new BehaviorSubject<any>(null);
  grandDomaine_libelle$ = new BehaviorSubject<any>(null);
  activite_principale$ = new BehaviorSubject<any>(null);
  categorie$ = new BehaviorSubject<any>(null);

  @Input() dashboard$: Observable<any> = of(null);
  constructor() {}

  ngOnInit(): void {
    this.setEntiteDashboard();
  }

  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  chartsValues = (statistiques: any) => {
    const values = statistiques
      ? Object.values(statistiques).map((item) => {
          if (isNull(item) || isUndefined(item) || !item) {
            return this.noExist;
          }
          return item;
        })
      : [];

    const labels = statistiques
      ? Object.keys(statistiques).map((item) => {
          if (isNull(item) || isUndefined(item) || !item) {
            return this.noExist;
          }
          return item;
        })
      : [];

    return { values, labels };
  };

  itemsCounter = (items: any[]) => {
    return items.reduce((acc, cur) => {
      const curSize = items.filter((el) => el === cur).length;

      acc[cur] = curSize;
      return acc;
    }, {});
  };

  chartsTypePie = (count: any): any => {
    const transformToArray: any[] = Object.entries(count);
    const transformForChart: any[] = transformToArray.map((el) => ({
      name: el[0],
      value: el[1],
    }));

    return transformForChart;
  };

  setEntiteDashboard(): void {
    this.dashboard$.pipe(takeUntil(this.subscribe)).subscribe((dashboard) => {
      if (!dashboard) return;

      const entites: any[] = dashboard?.entites?.data;

      if (!entites?.length) return;
      this.entites$.next(entites);
      this.setEntitesCharts(entites);
    });
  }

  setEntitesCharts(entites: any[]): void {
    this.setEntiteActivities(entites);
    this.setEntitreCategorrie(entites);
    this.setEntiteDomaineLibelle(entites);
  }

  setEntiteESS(): void {}

  setEntiteActivities(entites: any[]): void {
    const activites: any[] = entites.map(
      (entite) => entite.activite_principale
    );
    const counter = this.itemsCounter(activites);
    const chatsData = this.chartsValues(counter);

    const chartsOptions = {
      title: 'Activités économique impliquées',
      chartsTitle: '',
      type: 'barVertical',
      data: counter,
      center: ['50%', '70%'],
      radius: 120,

      height: '400px',
      option: {
        saveImageName: 'entreprises_par_activite',
        xAxis: 'entites',
        yAxis: 'category',
        columns: ['activite', 'entites'],
        label: {
          show: true,
          position: 'inside',
          color: '#ffff',
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontSize: 20,
        },
        visualMap: {
          orient: 'horizontal',
          left: 'center',
          min: 10,
          max: 100,
          text: ['Plus de entitées', 'Moins de entités'],
          dimension: 0,
          inRange: {
            color: ['#FD665F', '#FFCE34', '#65B581'],
          },
        },
        encode: {
          x: 'entites',
          y: 'activite',
        },
      },
    };
    this.activite_principale$.next(chartsOptions);
  }
  setEntitreCategorrie(entites: any[]): void {
    const items = entites.map((entite) => entite.categorie);

    const dataCounter: any = this.itemsCounter(items);
    const pieData: any[] = this.chartsTypePie(dataCounter);

    const genre = {
      title: "Catégories d'entités impliquées",
      chartsTitle: '',
      type: 'donut',
      data: pieData,
      center: ['50%', '70%'],
      radius: 120,
      height: '400px',
    };

    this.categorie$.next(genre);
  }
  setEntiteDomaineLibelle(entites: any[]): void {
    const items: any[] = entites.map((entite) => entite.grandDomaine_libelle);
    const counter = this.itemsCounter(items);
    const chatsData = this.chartsValues(counter);

    const chartsOptions = {
      title: 'Activités économique impliquées',
      chartsTitle: '',
      type: 'barVertical',
      data: counter,
      center: ['50%', '70%'],
      radius: 120,

      height: '400px',
      option: {
        saveImageName: 'entreprises_par_activite',
        xAxis: 'entites',
        yAxis: 'category',
        columns: ['activite', 'entites'],
        label: {
          show: true,
          position: 'inside',
          color: '#ffff',
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontSize: 20,
        },
        visualMap: {
          orient: 'horizontal',
          left: 'center',
          min: 10,
          max: 100,
          text: ['Plus de entitées', 'Moins de entités'],
          dimension: 0,
          inRange: {
            color: ['#FD665F', '#FFCE34', '#65B581'],
          },
        },
        encode: {
          x: 'entites',
          y: 'activite',
        },
      },
    };
    this.grandDomaine_libelle$.next(chartsOptions);
  }

  // economie_sociale_solidaire$ = new BehaviorSubject<any>(null);
  // grandDomaine_code$ = new BehaviorSubject<any>(null);
  // grandDomaine_libelle$ = new BehaviorSubject<any>(null);
}
