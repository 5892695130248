import {
  Component,
  OnChanges,
  OnInit,
  SimpleChanges,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'app-fiches-de-poste-item-dashboard',
  templateUrl: './fiches-de-poste-item-dashboard.component.html',
  styleUrls: ['./fiches-de-poste-item-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FichesDePosteItemDashboardComponent implements OnInit, OnChanges {
  currentSelected: string = 'candidatures';

  @Input() fiche: any = null;
  @Output() select = new EventEmitter<string>();
  constructor() {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {}

  onSelect(event: string): void {
    this.currentSelected = event;
    this.select.emit(event);
  }
}
