<nz-row nzAlign="middle" nzGutter="0" nzJustify="center">
  <nz-col nzSpan="24" class="headerContenaire__MESSAGE">
    <app-message-header></app-message-header>
  </nz-col>
</nz-row>
<nz-row nzAlign="middle" nzGutter="0" nzJustify="center">
  <nz-col nzSpan="24">
    <app-messages-list></app-messages-list>
  </nz-col>
</nz-row>
