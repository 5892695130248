import { ContactId } from './../../store/contact.model';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-contact-identity',
  templateUrl: './contact-identity.component.html',
  styleUrls: ['./contact-identity.component.scss'],
})
export class ContactIdentityComponent implements OnInit {
  @Input() contact: ContactId | any;
  @Output() select = new EventEmitter<any>(false);
  @Output() delete = new EventEmitter<ContactId | any>(false);

  constructor() {}

  ngOnInit(): void {}

  onUpdate(): void {}
  onSelect(event: string): void {
    this.select.emit(event);
  }

  onDelete(contact: ContactId): void {
    if (!contact) return;
    this.delete.emit(contact);
  }
  onCancel(): void {}
}
