import { PortalLayoutBodyService } from '../../../../../portals/portal-layout-body.service';
import { TemplatePortal } from '@angular/cdk/portal';
import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-layout-grid',
  templateUrl: './layout-grid.component.html',
  styleUrls: ['./layout-grid.component.scss'],
})
export class LayoutGridComponent implements OnInit {
  portal$: Observable<TemplatePortal> | any;
  isScreenSmall$: Observable<boolean> = of(false);

  @Input() identity: TemplateRef<any> | any;
  @Input() actions: TemplateRef<any> | any;
  @Input() extra: TemplateRef<any> | any;
  @Input() details: TemplateRef<any> | any;

  constructor(
    private portalBodyService: PortalLayoutBodyService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.breackPointer();
  }

  ngOnInit(): void {
    this.setBodyPortal();
  }

  breackPointer(): void {
    this.isScreenSmall$ = this.breakpointObserver
      .observe(['(max-width: 1200px)'])
      .pipe(map(({ matches }) => matches));
  }

  setBodyPortal(): void {
    this.portal$ = this.portalBodyService.portal$;
    this.portal$.subscribe((portal: any) => console.log(portal));
  }
}
