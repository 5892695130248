<nz-row nzAlign="middle" nzGutter="8" nzJustify="center">
  <nz-col
    [nzXs]="{ span: 0 }"
    [nzMd]="{ span: 0 }"
    [nzLg]="{ span: 0 }"
    [nzXl]="{ span: 2 }"
  >
    <!-- <h5 nz-typography class="title__TODO">{{ todoTitle }}</h5> -->
  </nz-col>
  <nz-col
    [nzXs]="{ span: 0 }"
    [nzMd]="{ span: 0 }"
    [nzLg]="{ span: 0 }"
    [nzXl]="{ span: 2 }"
  >
    <!-- <nz-badge nzStandalone [nzCount]="todos$ | async | size"> </nz-badge> -->
  </nz-col>

  <nz-col
    [nzXs]="{ span: 12 }"
    [nzMd]="{ span: 12 }"
    [nzLg]="{ span: 12 }"
    [nzXl]="{ span: 6 }"
  >
    <button
      *ngIf="isModal && addingTodo"
      nz-button
      nzShape="circle"
      (click)="close__TODO(isModal)"
    >
      <i nz-icon nzType="close"></i>
    </button>
  </nz-col>
  <nz-col
    [nzXs]="{ span: 12 }"
    [nzMd]="{ span: 12 }"
    [nzLg]="{ span: 12 }"
    [nzXl]="{ span: 14 }"
  >
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="end">
      <nz-col
        [nzXs]="{ span: 0 }"
        [nzMd]="{ span: 0 }"
        [nzLg]="{ span: 0 }"
        [nzXl]="{ span: 0 }"
        [nzXXl]="{ span: 8 }"
      >
      </nz-col>
      <nz-col
        [nzXs]="{ span: 0 }"
        [nzMd]="{ span: 12 }"
        [nzLg]="{ span: 12 }"
        [nzXl]="{ span: 4 }"
        [nzXXl]="{ span: 4 }"
      >
      </nz-col>
      <nz-col
        [nzXs]="{ span: 0 }"
        [nzMd]="{ span: 0 }"
        [nzLg]="{ span: 0 }"
        [nzXl]="{ span: 2 }"
      >
      </nz-col>
      <nz-col
        [nzXs]="{ span: 0 }"
        [nzMd]="{ span: 0 }"
        [nzLg]="{ span: 0 }"
        [nzXl]="{ span: 2 }"
      >
        <button
          *ngIf="!addingTodo"
          nz-button
          nzShape="circle"
          nz-dropdown
          nzTrigger="click"
          [nzDanger]="todosFilter"
          [nzDropdownMenu]="menuMoreTodoFilters"
          nzPlacement="bottomRight"
          nzType="text"
        >
          <i nz-icon nzType="filter"></i>
        </button>
      </nz-col>
      <nz-col
        [nzXs]="{ span: 0 }"
        [nzMd]="{ span: 0 }"
        [nzLg]="{ span: 0 }"
        [nzXl]="{ span: 2 }"
      >
        <button
          *ngIf="!addingTodo"
          nz-button
          nzShape="circle"
          nz-dropdown
          nzTrigger="click"
          [nzDropdownMenu]="menuMoreTodoActions"
          nzPlacement="bottomRight"
          nzType="text"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
      </nz-col>
    </nz-row>
  </nz-col>
</nz-row>

<nz-dropdown-menu #menuMoreTodoFilters="nzDropdownMenu">
  <ul nz-menu nzSelectable>
    <ng-container *ngIf="todosFilter">
      <li
        nz-menu-item
        nzDanger
        (click)="$event.preventDefault(); removeTodosFilters()"
      >
        Retirer les filtres
      </li>

      <li nz-menu-divider></li>
    </ng-container>
    <li
      nz-menu-item
      *ngIf="!addingTodo"
      (click)="$event.preventDefault(); getCompleted_TODOS()"
      [ngClass]="{ active: activeFilter === 'completed' }"
    >
      Réalisées
    </li>
    <li
      nz-menu-item
      *ngIf="!addingTodo"
      (click)="$event.preventDefault(); get_TODOS()"
      [ngClass]="{ active: activeFilter === 'uncompleted' }"
    >
      À réaliser
    </li>
    <li nz-menu-divider></li>
    <li nz-menu-item (click)="$event.preventDefault(); getTodosToday()">
      Aujourd'hui
    </li>
    <li nz-menu-item (click)="$event.preventDefault(); getTodosPriority()">
      Priorités
    </li>
    <li nz-menu-item (click)="$event.preventDefault(); getTodosFutur()">
      À venir
    </li>
    <li nz-menu-item (click)="$event.preventDefault(); getTodosLate()">
      En retard
    </li>
  </ul>
</nz-dropdown-menu>

<nz-dropdown-menu #menuMoreTodoActions="nzDropdownMenu">
  <ul nz-menu nzSelectable>
    <li nz-menu-divider></li>
    <li
      nz-menu-item
      nzDanger
      (click)="$event.preventDefault(); deleteAll_TODOS()"
    >
      Supprimer toutes les tâches
    </li>
  </ul>
</nz-dropdown-menu>

<ng-template #noTodosLength>
  <nz-avatar nzSize="small" nzText="0"></nz-avatar>
</ng-template>
