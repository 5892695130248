import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'app-participant-diagnostic-item-presentation',
  templateUrl: './participant-diagnostic-item-presentation.component.html',
  styleUrls: ['./participant-diagnostic-item-presentation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantDiagnosticItemPresentationComponent implements OnInit {
  noData: string = 'Aucune présentation renseigné lors du diagnostic partagé';
  @Input() presentation: string = '';

  constructor() {}

  ngOnInit(): void {}
}
