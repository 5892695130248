import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-operation-team-member',
  templateUrl: './operation-team-member.component.html',
  styleUrls: ['./operation-team-member.component.scss'],
})
export class OperationTeamMemberComponent implements OnInit {
  @Output() back = new EventEmitter<boolean>(false);
  constructor() {}

  ngOnInit(): void {}

  onBack(): void {
    this.back.emit(true);
  }
}
