<nz-avatar
  [nzSize]="24"
  nz-comment-avatar
  [nzIcon]="
    todo?.completed
      ? 'check'
      : !todo?.completed && todo?.priority
      ? 'flag'
      : !todo?.completed && !todo?.priority
      ? 'flag'
      : 'field-time'
  "
  [ngClass]="{
    todo_avatarYellow: todo?.completed,
    todo_avatarRed: !todo?.completed && todo?.priority,
    todo_avatarBlue: (todo | todosCalendar : 'futur'),
    todo_avatarRedLater: (todo | todosCalendar : 'latest'),
    todo_avatarGrey: !todo?.completed && !todo?.priority
  }"
></nz-avatar>
