import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StringhtmlPipe } from './stringhtml.pipe';
import { DateFrPipe } from './date-fr.pipe';
import { InitialPipe } from './initial.pipe';
import { CarLimitationPipe } from './car-limitation.pipe';
import { TimestampToDatePipe } from './timestamp-to-date.pipe';
import { PhonePipe } from './phone.pipe';
import { HoursPipe } from './hours.pipe';
import { DisplayNamePipe } from './display-name.pipe';
import { CamelcasePipe } from './camelcase.pipe';
import { DifferenceMonthsPipe } from './difference-months.pipe';
import { NumberToPercentPipe } from './number-to-percent.pipe';
import { IsfavoriPipe } from './isfavori.pipe';
import { IndicatorCardPipe } from './indicator-card.pipe';
import { TodosCalendarPipe } from './todos-calendar.pipe';
import { IsLoadingPipe } from './is-loading.pipe';
import { SizePipe } from './size.pipe';
import { IsUserPipe } from './is-user.pipe';
import { IsAdminPipe } from './is-admin.pipe';
import { IsManagerPipe } from './is-manager.pipe';
import { IsDevPipe } from './is-dev.pipe';
import { IsMemberPipe } from './is-member.pipe';
import { IsCalculatorPipe } from './is-calculator.pipe';
import { IsTodayPipe } from './is-today.pipe';
import { ErrorTypePipe } from './error-type.pipe';
import { UtilitiesPipe } from './utilities.pipe';
import { NavigationDeletePipe } from './navigation-delete.pipe';
import { ArrayContainPipe } from './array-contain.pipe';
import { OperationAdminPipe } from './operation-admin.pipe';
import { IsFuturPipe } from './is-futur.pipe';
import { IsLaterPipe } from './is-later.pipe';
import { AvatarPipe } from './avatar.pipe';
import { TimeProgressionPipe } from './time-progression.pipe';
import { ParticipantsDisponibilityPipe } from './participants-disponibility.pipe';
import { ContactsDisponibilityPipe } from './contacts-disponibility.pipe';
import { ParticipantActivityPipe } from './participant-activity.pipe';
import { ModuleActivityPipe } from './module-activity.pipe';
import { ArraySortPipe } from './array-sort.pipe';
import { ArrayDifferencePipe } from './array-difference.pipe';
import { DistancePercentPipe } from './distance-percent.pipe';
import { NumbersPercentPipe } from './numbers-percent.pipe';
import { IsCompletePipe } from './is-complete.pipe';
import { DifferenceByIdPipe } from './difference-by-id.pipe';
import { SortByPipe } from './sort-by.pipe';
import { StringPipe } from './string.pipe';
import { DateStringPipe } from './date-string.pipe';
import { AgePipe } from './age.pipe';
import { BirthdayPipe } from './birthday.pipe';
import { NosanitizerpipePipe } from './nosanitizerpipe.pipe';
import { CoordonneesPipe } from './coordonnees.pipe';
import { SplitJoinPipe } from './split-join.pipe';
import { DateMiddlePipe } from './date-middle.pipe';
import { CompletedPipe } from './completed.pipe';
import { TopScorePipe } from './top-score.pipe';
import { OperationTypePipe } from './operation-type.pipe';
import { DateToStringPipe } from './date-to-string.pipe';
import { EventsByDatePipe } from './events-by-date.pipe';

@NgModule({
  declarations: [
    StringhtmlPipe,
    DateFrPipe,
    InitialPipe,
    CarLimitationPipe,
    TimestampToDatePipe,
    PhonePipe,
    HoursPipe,
    DisplayNamePipe,
    CamelcasePipe,
    DifferenceMonthsPipe,
    NumberToPercentPipe,
    IsfavoriPipe,
    IndicatorCardPipe,
    TodosCalendarPipe,
    IsLoadingPipe,
    SizePipe,
    IsUserPipe,
    IsAdminPipe,
    IsManagerPipe,
    IsDevPipe,
    IsMemberPipe,
    IsCalculatorPipe,
    IsTodayPipe,
    ErrorTypePipe,
    UtilitiesPipe,
    NavigationDeletePipe,
    ArrayContainPipe,
    OperationAdminPipe,
    IsFuturPipe,
    IsLaterPipe,
    AvatarPipe,
    TimeProgressionPipe,
    ParticipantsDisponibilityPipe,
    ContactsDisponibilityPipe,
    ParticipantActivityPipe,
    ModuleActivityPipe,
    ArraySortPipe,
    ArrayDifferencePipe,
    DistancePercentPipe,
    NumbersPercentPipe,
    IsCompletePipe,
    DifferenceByIdPipe,
    SortByPipe,
    StringPipe,
    DateStringPipe,
    AgePipe,
    BirthdayPipe,
    NosanitizerpipePipe,
    CoordonneesPipe,
    SplitJoinPipe,
    DateMiddlePipe,
    CompletedPipe,
    TopScorePipe,
    OperationTypePipe,
    DateToStringPipe,
    EventsByDatePipe,
  ],
  imports: [CommonModule],
  exports: [
    DateFrPipe,
    StringhtmlPipe,
    InitialPipe,
    CarLimitationPipe,
    TimestampToDatePipe,
    PhonePipe,
    HoursPipe,
    DisplayNamePipe,
    CamelcasePipe,
    DifferenceMonthsPipe,
    NumberToPercentPipe,
    IsfavoriPipe,
    IndicatorCardPipe,
    TodosCalendarPipe,
    IsLoadingPipe,
    SizePipe,
    IsUserPipe,
    IsAdminPipe,
    IsManagerPipe,
    IsDevPipe,
    IsMemberPipe,
    IsCalculatorPipe,
    IsTodayPipe,
    ErrorTypePipe,
    UtilitiesPipe,
    NavigationDeletePipe,
    ArrayContainPipe,
    OperationAdminPipe,
    IsFuturPipe,
    IsLaterPipe,
    AvatarPipe,
    TimeProgressionPipe,
    ParticipantsDisponibilityPipe,
    ContactsDisponibilityPipe,
    ParticipantActivityPipe,
    ModuleActivityPipe,
    ArraySortPipe,
    ArrayDifferencePipe,
    DistancePercentPipe,
    NumbersPercentPipe,
    IsCompletePipe,
    DifferenceByIdPipe,
    SortByPipe,
    StringPipe,
    DateStringPipe,
    AgePipe,
    BirthdayPipe,
    NosanitizerpipePipe,
    CoordonneesPipe,
    SplitJoinPipe,
    DateMiddlePipe,
    CompletedPipe,
    TopScorePipe,
    OperationTypePipe,
    DateToStringPipe,
    EventsByDatePipe,
  ],
  providers: [],
})
export class PipeModule {}
