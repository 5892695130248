<div class="operationSelector_container">
  <!-- <nz-select
    nzShowSearch
    nzServerSearch
    nzBorderless
    nzPlaceHolder="Sélectionner un projet..."
    [(ngModel)]="selectedOperation"
    [nzShowArrow]="false"
    nzAllowClear
    (ngModelChange)="onSelect($event, identity)"
    [nzOpen]="(operations$ | async | size) ? true : false"
  >
    <nz-option
      *ngFor="let item of operations$ | async"
      nzCustomContent
      [nzLabel]="item?.denomination"
      [nzValue]="item"
    >
      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col nzSpan="2">
          <mat-icon>analytics</mat-icon>
        </nz-col>
        <nz-col nzSpan="2">
          <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
        </nz-col>
        <nz-col nzSpan="20">
          <strong>{{ item?.denomination | carLimitation: 120 }}</strong>
        </nz-col>
      </nz-row>
    </nz-option>
  </nz-select> -->

  <ul
    nz-list
    [nzDataSource]="operations$ | async"
    [nzBordered]="false"
    nzSize="small"
  >
    <nz-list-header>
      <nz-input-group [nzSuffix]="suffixIconSearch">
        <input
          nzBorderless
          type="text"
          nz-input
          placeholder="Rechercher le projet"
        />
      </nz-input-group>
      <ng-template #suffixIconSearch>
        <i nz-icon nzType="search"></i>
      </ng-template>
    </nz-list-header>
    <li
      nz-list-item
      *ngFor="let item of operations$ | async"
      nzNoFlex
      (click)="$event.stopPropagation(); onSelect(item, identity)"
    >
      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col nzSpan="4">
          <mat-icon>domaine</mat-icon>
        </nz-col>
        <nz-col nzSpan="20">
          <p nz-typography>{{ item.denomination | carLimitation: 25 }}</p>
          <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
            <nz-col nzSpan="7">
              <small>{{
                item?.description?.calendrier?.dateStart | dateFr: "small"
              }}</small>
            </nz-col>
            <nz-col nzSpan="2">
              <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
            </nz-col>
            <nz-col nzSpan="7">
              <small>{{
                item?.description?.calendrier?.dateEnd | dateFr: "small"
              }}</small>
            </nz-col>
            <nz-col nzSpan="8">
              <nz-tag nzColor="geekblue">{{ item?.status }}</nz-tag>
            </nz-col>
          </nz-row>
        </nz-col>
      </nz-row>
    </li>
  </ul>

  <ng-template #interventionTitle>
    <app-header-title
      [icon]="'badge'"
      [title]="titleInterventionForm"
    ></app-header-title>
  </ng-template>
</div>
