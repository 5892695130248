import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OperationNoteSujetListComponent } from './components/operation-note-sujet-list/operation-note-sujet-list.component';
import { OperationNoteSujetItemComponent } from './components/operation-note-sujet-item/operation-note-sujet-item.component';
import { OperationNoteSujetFormComponent } from './components/operation-note-sujet-form/operation-note-sujet-form.component';
import { OperationNoteSujetService } from 'src/app/service/operation-note-sujet.service';
import { ContentsModule } from 'src/app/contents/contents.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { StoreModule } from '@ngrx/store';
import * as fromOperationNoteSujet from './store/operation-note-sujet.reducer';
import { EffectsModule } from '@ngrx/effects';
import { OperationNoteSujetEffects } from './store/operation-note-sujet.effects';
import { OperationNoteSujetCommentComponent } from './components/operation-note-sujet-comment/operation-note-sujet-comment.component';
import { OperationNoteSujetActionComponent } from './components/operation-note-sujet-action/operation-note-sujet-action.component';
import { OperationNoteSujetWarningListComponent } from './components/operation-note-sujet-warning-list/operation-note-sujet-warning-list.component';
import { NoResultModule } from 'src/app/contents/components/no-result/no-result.module';
import { CoordonneesModule } from 'src/app/contents/components/coordonnees/coordonnees.module';
import { CommunesFranceModule } from 'src/app/contents/components/communes-france/communes-france.module';
import { ButtonsModule } from 'src/app/contents/components/buttons/buttons.module';
import { HeaderModule } from 'src/app/contents/components/header-title/header.module';
import { TextEdithingModule } from 'src/app/features/text-edithing/text-edithing.module';

@NgModule({
  declarations: [
    OperationNoteSujetListComponent,
    OperationNoteSujetItemComponent,
    OperationNoteSujetFormComponent,
    OperationNoteSujetCommentComponent,
    OperationNoteSujetActionComponent,
    OperationNoteSujetWarningListComponent,
  ],
  imports: [
    CommonModule,
    ContentsModule,
    SharedModule,
    NoResultModule,
    CoordonneesModule,
    HeaderModule,
    CommunesFranceModule,
    ButtonsModule,
    TextEdithingModule,
    StoreModule.forFeature(
      fromOperationNoteSujet.operationNoteSujetsFeatureKey,
      fromOperationNoteSujet.reducer
    ),
    EffectsModule.forFeature([OperationNoteSujetEffects]),
  ],
  exports: [
    OperationNoteSujetListComponent,
    OperationNoteSujetItemComponent,
    OperationNoteSujetFormComponent,
    OperationNoteSujetWarningListComponent,
  ],
  providers: [OperationNoteSujetService],
})
export class OperationNoteSujetModule {}
