<ng-container *ngIf="user$ | async as user">
  <ng-container *ngIf="participant$ | async as participant">
    <app-table-documents
      [source$]="documents$"
      (add)="onAdd($event, participant, user.organisation.id)"
      (delete)="onDelete($event, participant)"
      (deleteAll)="onDeleteAll(participant)"
    ></app-table-documents>
  </ng-container>
</ng-container>
