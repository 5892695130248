import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 'app-button-dropdown',
  templateUrl: './button-dropdown.component.html',
  styleUrls: ['./button-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonDropdownComponent implements OnInit, OnChanges {
  @Input() direction: string = 'vertical';
  @Input() placement: string = 'vertical';

  @Input() type: string = 'more';

  @Input() isUpdate: boolean = false;
  @Input() isCopy: boolean = false;
  @Input() isDelete: boolean = false;
  @Input() isDeleteAll: boolean = false;
  @Input() isShare: boolean = false;

  @Input() isExport: boolean = false;
  @Input() isRelance: boolean = false;
  @Input() isExportWRD: boolean = false;
  @Input() isExportPDF: boolean = false;
  @Input() isExportEXL: boolean = false;
  @Input() isExportPPT: boolean = false;
  @Input() expansion: TemplateRef<any> | any;
  @Input() isExportAnonym: boolean = false;
  @Input() deleteType: string = 'Supprimer cette mission ?';
  @Input() deleteAllType: string = 'Voulez-vous tout supprimer ?';

  @Output() select = new EventEmitter(false);
  constructor() {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {}

  onSelect(item: string): void {
    if (!item) return;
    this.select.emit(item);
  }

  onCancel(): void {
    return;
  }
}
