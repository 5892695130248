import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-projet-professionnel-formations-form-detail',
  templateUrl: './projet-professionnel-formations-form-detail.component.html',
  styleUrls: ['./projet-professionnel-formations-form-detail.component.scss'],
})
export class ProjetProfessionnelFormationsFormDetailComponent
  implements OnInit, OnDestroy, OnChanges
{
  subscribe = new Subject();
  isApprentissage: string = 'Apprentissage';

  @Input() formation: any = null;
  @Input() isForm: boolean = true;

  @Output() select = new EventEmitter<any>(false);
  @Output() add = new EventEmitter<any>(false);
  @Output() back = new EventEmitter<any>(false);

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  onSelect(formation: any): void {
    if (!formation) return;
    this.select.emit(formation);
  }
  onAdd(formation: any): void {
    if (!formation) return;
    this.add.emit(formation);
  }

  onOpenOnisepLinkClick(url: string): void {
    if (!url) return;

    window.open(url, '_blank');
  }

  onBack(): void {
    this.back.emit(true);
  }
}
