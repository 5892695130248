import {
  OperationSmall,
  OperationId,
  Operation,
} from './../components/operation/store/operation.model';
import { format } from 'date-fns';
import { OperationState } from './../components/operation/store/operation.reducer';
import { Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  doc,
  docSnapshots,
  getDoc,
  collectionChanges,
  addDoc,
  setDoc,
  deleteDoc,
  serverTimestamp,
  Timestamp,
  query,
  where,
  arrayUnion,
  arrayRemove,
  collectionSnapshots,
  getDocs,
  DocumentData,
  DocumentSnapshot,
} from '@angular/fire/firestore';
import { Observable, of, from, EMPTY } from 'rxjs';
import { switchMap, mergeMap, map, catchError } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { UserState } from '../components/user/store/user.reducer';
import * as fromUserSelector from '../components/user/store/user.selectors';
import { fromUnixTime } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Apollo, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'any',
})
export class OperationService {
  DELETE_OPERATION = gql`
    mutation DeleteOperation($id: ID!) {
      deleteOperation(id: $id)
    }
  `;

  DELETE_OPERATIONS = gql`
    mutation DeleteOperations {
      deleteOperations
    }
  `;

  constructor(
    private db: Firestore,
    private userStore: Store<UserState>,
    private apollo: Apollo
  ) {}

  getAll(completed: boolean = false): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) {
            return of([]);
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/operations`
            );

            if (user?.claims?.admin) {
              return collectionSnapshots(dbCollection).pipe(
                map((snap) => {
                  return snap.map((doc: DocumentData) => {
                    const data = doc.data() as any;
                    const id = doc.id;
                    return { id, ...data };
                  });
                }),
                catchError((error) => EMPTY)
              );
            } else {
              const queryCollection = query(
                dbCollection,
                where('equipeIds', 'array-contains', user.id)
              );

              return collectionSnapshots(queryCollection).pipe(
                map((snap) => {
                  return snap.map((doc: DocumentData) => {
                    const data = doc.data() as any;
                    const id = doc.id;
                    return { id, ...data };
                  });
                }),
                catchError((error) => EMPTY)
              );
            }
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  getOne(id: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) {
            return of(null);
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/operations`
            );

            const documentRef = doc(dbCollection, id);
            return docSnapshots(documentRef).pipe(
              map((snap) => {
                const data = snap.data();
                const id = snap.id;
                return { id, ...data };
              }),
              catchError((error) => EMPTY)
            );
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  getOneStatistiques(id: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !id) {
            return of(null);
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/operations/${id}/statistiques`
            );

            const documentRef = doc(dbCollection, id);

            return docSnapshots(documentRef).pipe(
              map((snap: DocumentSnapshot<DocumentData>) => {
                const data = snap.data();
                const id = snap.id;
                return { id, ...data };
              }),
              catchError((error) => EMPTY)
            );
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  addOne(operation: Operation): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || user.claims.member || !operation) {
            return of("Votre accès ne permet pas l'enregistrement de projets.");
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/operations`
            );

            const adminUser = {
              user: user,
              role: 'Administrateur',
              isAdmin: true,
              canRead: true,
              canWrite: true,
            };

            const newOperation: Operation = {
              ...operation,
              organisationsId: [user.organisation.id],
              organisation: user.organisation,
              auths: user?.claims?.admin || user?.claims?.dev ? [] : [user.id],
              acces:
                user?.claims?.admin || user?.claims?.dev
                  ? null
                  : {
                      ...operation.acces,
                      [`${user.id}`]: true,
                    },
              auteur: user,
              userUpdate: user,
              equipe: user?.claims?.admin || user?.claims?.dev ? [] : [user],
              equipeAcces:
                user?.claims?.admin || user?.claims?.dev ? [] : [adminUser],

              equipeIds:
                user?.claims?.admin || user?.claims?.dev ? [] : [user.id],
              adminIds:
                user?.claims?.admin || user?.claims?.dev ? [] : [user.id],
              metadata: this.createOperationMetadata(operation),
              servicesIds: [user?.service?.id],
            };

            return from(
              addDoc(dbCollection, newOperation)
                .then((docRef) => {
                  if (!docRef?.id) {
                    return of(
                      "Erreur d'enregistrement. Veuillez renouveller l'opération."
                    );
                  } else {
                    return of('Projet ajouté');
                  }
                })
                .catch((error) => of(error))
            ).pipe(mergeMap((res) => res));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  updateOne(id: string | number, operation: Partial<any>): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !operation || !operation?.id) {
            return of(null);
          } else {
            const documentRef = doc(
              this.db,
              `organisations/${user.organisation.id}/operations/${operation.id}`
            );
            const nextDocument = {
              ...operation,
              dateUpdate: serverTimestamp(),
              userUpdate: user,
              metadata: this.createOperationMetadata(operation),
            };
            return from(
              setDoc(documentRef, { ...nextDocument }, { merge: true })
                .then(() => 'Projet modifié')
                .catch((error) => of(error))
            );
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  deleteOperation(id: string): Observable<any> {
    try {
      if (!id) EMPTY;
      return this.apollo.mutate({
        mutation: this.DELETE_OPERATION,
        variables: {
          id: id,
        },
      });
    } catch (error) {
      return of(error);
    }
  }

  deleteOperations(): Observable<any> {
    try {
      return this.apollo.mutate({
        mutation: this.DELETE_OPERATIONS,
      });
    } catch (error) {
      return of(error);
    }
  }

  capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase();
  }

  createKeywords(name: any): any {
    if (name) {
      const arrName: any = [];
      let curName = '';
      name.split('').forEach((letter: any) => {
        curName += letter;
        arrName.push(curName);
      });
      return arrName;
    }
  }

  createOperationMetadata(operation: any): any {
    const { denomination } = operation;
    const letterDenomination = denomination
      ? this.capitalizeFirstLetter(denomination)
      : '';
    const arrayDenomination = denomination
      ? this.createKeywords(denomination)
      : [];
    const arrFinanceurs = operation.financeursIds
      ? operation.financeursIds.reduce((acc: any, cur: any) => {
          acc[cur] = true;
          return acc;
        }, {})
      : {};

    const startYear =
      operation.description.calendrier.dateStart &&
      operation.description.calendrier.dateStart['seconds']
        ? format(
            fromUnixTime(operation.description.calendrier.dateStart['seconds']),
            'yyyy',
            { locale: fr }
          )
        : 'Non renseigné';

    const startMonth =
      operation.description.calendrier.dateStart &&
      operation.description.calendrier.dateStart['seconds']
        ? format(
            fromUnixTime(operation.description.calendrier.dateStart['seconds']),
            'MMMM',
            { locale: fr }
          )
        : 'Non renseigné';

    const endYear =
      operation.description.calendrier.dateEnd &&
      operation.description.calendrier.dateEnd['seconds']
        ? format(
            fromUnixTime(operation.description.calendrier.dateEnd['seconds']),
            'yyyy',
            { locale: fr }
          )
        : 'Non renseigné';

    const endMonth =
      operation.description.calendrier.dateEnd &&
      operation.description.calendrier.dateEnd['seconds']
        ? format(
            fromUnixTime(operation.description.calendrier.dateEnd['seconds']),
            'MMMM',
            { locale: fr }
          )
        : 'Non renseigné';

    const metadata = {
      letterDenomination: letterDenomination,
      arrayDenomination: arrayDenomination,
      arrayFinanceur: arrFinanceurs,

      startYear: startYear,
      startMonth: startMonth,
      endYear: endYear,
      endMonth: endMonth,
    };

    return metadata;
  }
}
