import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import {
  GeoDistance,
  GeoDistanceCoordonnees,
} from 'src/app/contents/components/geo-territoire/store/geo-territoire.model';
import { GeoTerritoireState } from 'src/app/contents/components/geo-territoire/store/geo-territoire.reducer';
import * as fromGeoTerritoireSelector from 'src/app/contents/components/geo-territoire/store/geo-territoire.selectors';
import * as fromGeoTerritoireAction from 'src/app/contents/components/geo-territoire/store/geo-territoire.actions';
import { GeoDistanceInput } from 'src/app/contents/components/geo-territoire/store/geo-territoire.model';
import { Observable, Subject, of, takeUntil } from 'rxjs';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-candidature-form-validation',
  templateUrl: './candidature-form-validation.component.html',
  styleUrls: ['./candidature-form-validation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CandidatureFormValidationComponent
  implements OnInit, OnChanges, OnDestroy
{
  distanceLoading$: Observable<boolean> = of(false);
  distance$: Observable<GeoDistance | any> = of(null);
  subscribe = new Subject();

  @Input() candidature: any;
  @Input() isDistance: boolean = true;
  @Input() adresseMission: GeoDistanceCoordonnees = {
    latitude: 0,
    longitude: 0.0,
  };

  @Output() validation = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @Output() currentChanges = new EventEmitter();
  @Output() currentPresentationChanges = new EventEmitter();

  validationForm = new FormGroup({
    presentation: new FormControl(''),
  });

  constructor(
    private geoTerritoireStore: Store<GeoTerritoireState>,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.getDistanceLoading();
    this.getDistance();
    this.onValidationChanges();
    this.onValidationPresentationChanges();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (
      !changes?.adresseMission?.currentValue ||
      !changes?.candidature?.currentValue?.adresse?.adresse?.geometry
        ?.coordinates
    )
      return;

    const coordonnees1: GeoDistanceCoordonnees =
      changes.adresseMission.currentValue;
    const coordonnees2: GeoDistanceCoordonnees = {
      latitude:
        changes.candidature?.currentValue?.adresse?.adresse?.geometry
          ?.coordinates[0],
      longitude:
        changes.candidature?.currentValue?.adresse?.adresse?.geometry
          ?.coordinates[1],
    };

    this.onFindAdressesDistance(coordonnees1, coordonnees2);
  }
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  onFindAdressesDistance(
    coordonnees1: GeoDistanceCoordonnees,
    coordonnees2: GeoDistanceCoordonnees
  ): void {
    if (!coordonnees1 || !coordonnees2) return;
    const adresses: GeoDistanceInput = { coordonnees1, coordonnees2 };
    this.geoTerritoireStore.dispatch(
      fromGeoTerritoireAction.loadGeoDistance({ adresses: adresses })
    );
  }

  getDistance(): void {
    this.distance$ = this.geoTerritoireStore.select(
      fromGeoTerritoireSelector.distance
    );
  }
  getDistanceLoading(): void {
    this.distanceLoading$ = this.geoTerritoireStore.select(
      fromGeoTerritoireSelector.loadingDistance
    );
  }

  onValidationChanges(): void {
    this.distance$
      .pipe(takeUntil(this.subscribe))
      .subscribe((distance) => this.currentChanges.emit(distance));
  }
  onValidationPresentationChanges(): void {
    this.validationForm
      .get('presentation')
      ?.valueChanges.pipe(takeUntil(this.subscribe))
      .subscribe((presentation) =>
        this.currentPresentationChanges.emit(presentation)
      );
  }
}
