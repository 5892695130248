import { Observable, of } from 'rxjs';
import { UserService } from '../../../../service/user.service';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/service/auth.service';
import {
  Auth,
  onAuthStateChanged,
  IdTokenResult,
  signOut,
} from '@angular/fire/auth';
import { format, fromUnixTime } from 'date-fns';
import { Store } from '@ngrx/store';
import { AuthState } from 'src/app/components/auth/store/auth.reducer';
import * as fromAuthAction from 'src/app/components/auth/store/auth.actions';
import { UserState } from '../../store/user.reducer';
import * as fromUserSelector from '../../store/user.selectors';
import * as fromUserAction from '../../store/user.actions';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit {
  user$: Observable<any> = of(null);
  currentDate = new Date(Date.now());
  constructor(
    private userStore: Store<UserState>,
    private authService: AuthService,
    private auth: Auth,
    private authStore: Store<AuthState>
  ) {}

  ngOnInit(): void {
    this.getAuthUser();
  }

  loadUer(): void {
    this.userStore.dispatch(fromUserAction.loadMe());
  }

  getAuthUser(): void {
    this.user$ = this.userStore.select(fromUserSelector.user);
  }

  updateEmail(email: string): void {
    this.authService.sendResetPassword(email);
  }

  onSinOut(): void {
    this.authStore.dispatch(fromAuthAction.logout());
  }
}
