<nz-breadcrumb [ngClass]="{ dark: theme === 'dark' }" *ngIf="user">
  <nz-breadcrumb-item
    nz-tooltip
    [nzTooltipMouseEnterDelay]="0.5"
    nzTooltipPlacement="bottom"
    [nzTooltipTitle]="'Accueil'"
    [routerLink]="['/user', user.id]"
    isActive
  >
    <i nz-icon nzType="home" class="large"></i>
  </nz-breadcrumb-item>
  <!-- <nz-breadcrumb-item *ngIf="url !== '/user'">{{
    url | uppercase
  }}</nz-breadcrumb-item> -->
</nz-breadcrumb>
