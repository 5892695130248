<ng-container *ngIf="projetprofessionnel$ | async as projetprofessionnel">
  <i nz-icon nzType="loading" class="loading" *ngIf="loading$ | async"></i>
  <div [ngSwitch]="view$ | async">
    <div *ngSwitchCase="'list'">
      <app-layout-list
        *ngIf="todos$ | async | size; else noResults"
        [card]="card"
        [new]="new"
        [source]="todos$ | async"
      ></app-layout-list>
    </div>
    <div *ngSwitchCase="'details'">
      <app-projet-professionnel-todo-item
        [todo$]="current$"
        (back)="onBack()"
      ></app-projet-professionnel-todo-item>
    </div>
    <div *ngSwitchDefault>
      <app-layout-list
        *ngIf="todos$ | async | size; else noResults"
        [card]="card"
        [new]="new"
        [source]="todos$ | async"
      ></app-layout-list>
    </div>
  </div>

  <ng-template #new>
    <app-card-new
      [type]="'task'"
      [title]="newCardTitle"
      [description]="newCardDescription"
      [definition]="newCardDefinition"
      [width]="'300px'"
      [height]="'280px'"
      (add)="onNew(participant, projetprofessionnel)"
      (back)="onBack()"
      (cancel)="onBack()"
    ></app-card-new>
  </ng-template>

  <ng-template #card let-item let-position="idx">
    <nz-card
      nzHoverable
      nzSize="small"
      [nzTitle]="entiteProjetproTitle"
      class="card"
      (click)="$event.stopPropagation(); onSelect(item)"
      [nzExtra]="cardEntiteProjetproExtra"
    >
      <ng-template #entiteProjetproTitle>
        <app-header-title
          [icon]="'task'"
          [subtitle]="true"
          [height]="'35px'"
          [width]="'35px'"
        ></app-header-title>
      </ng-template>
      <div class="title">
        {{ item?.title | carLimitation: 40 }}
      </div>
      <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>

      <div class="subtitle">
        <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
          <nz-col nzSpan="2">
            <mat-icon class="projetpro_todo_card_event_icon">event</mat-icon>
          </nz-col>
          <nz-col nzSpan="1"></nz-col>
          <nz-col nzSpan="8">
            <small>{{ item.dateStart | dateFr: "small" }}</small>
          </nz-col>
          <nz-col nzSpan="2">
            <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
          </nz-col>
          <nz-col nzSpan="2">
            <mat-icon class="projetpro_todo_card_event_icon">event</mat-icon>
          </nz-col>
          <nz-col nzSpan="1"></nz-col>
          <nz-col nzSpan="8">
            <small>{{ item.dateEnd | dateFr: "small" }}</small>
          </nz-col>
        </nz-row>
      </div>
      <div>
        <nz-row [nzGutter]="16" nzAlign="middle" nzJustify="center">
          <nz-col [nzSpan]="6"></nz-col>
          <nz-col [nzSpan]="12" class="projetpro_todo_statistics">
            <nz-statistic
              [nzValue]="item?.todos | completed | size"
              [nzTitle]="'Tâches'"
              [nzSuffix]="'/' + (item?.todos | size)"
            ></nz-statistic>
          </nz-col>
          <nz-col [nzSpan]="6"></nz-col>
        </nz-row>
      </div>
    </nz-card>
    <nz-dropdown-menu #cardExtraEntiteProjetprojetpro="nzDropdownMenu">
      <ul nz-menu>
        <li
          nz-menu-item
          nzDanger
          nz-popconfirm
          nzPopconfirmTitle="Voulez-vous retirer cette tâche ?"
          nzPopconfirmPlacement="bottom"
          (nzOnConfirm)="onDelete(item, participant, projetprofessionnel)"
          (nzOnCancel)="onCancel()"
        >
          <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
            <nz-col nzSpan="6">
              <i nz-icon nzType="delete"></i>
            </nz-col>
            <nz-col nzSpan="18"> Supprimer </nz-col>
          </nz-row>
        </li>
      </ul>
    </nz-dropdown-menu>
    <ng-template #cardEntiteProjetproExtra>
      <button
        nz-button
        nzShape="circle"
        nz-dropdown
        [nzDropdownMenu]="cardExtraEntiteProjetprojetpro"
        nzTrigger="click"
        nzPlacement="bottomRight"
        nzType="text"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
    </ng-template>
  </ng-template>

  <ng-template #noResults>
    <app-no-result
      [icon]="'task'"
      [addtitle]="newCardDescription"
      [notitle]="'Aucune tâche projet professionnel ajoutée'"
      (new)="onNew(participant, projetprofessionnel)"
    ></app-no-result>
  </ng-template>

  <ng-template #projetProfessionnelTodoForm>
    <app-header-title [icon]="'task'" [title]="title"></app-header-title>
  </ng-template>
</ng-container>
