import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OperationNoteDocumentListComponent } from './components/operation-note-document-list/operation-note-document-list.component';
import { OperationNoteDocumentItemComponent } from './components/operation-note-document-item/operation-note-document-item.component';
import { OperationNoteDocumentFormComponent } from './components/operation-note-document-form/operation-note-document-form.component';
import { OperationNoteDocumentService } from 'src/app/service/operation-note-document.service';
import { ContentsModule } from 'src/app/contents/contents.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { NoResultModule } from 'src/app/contents/components/no-result/no-result.module';
import { CoordonneesModule } from 'src/app/contents/components/coordonnees/coordonnees.module';
import { CommunesFranceModule } from 'src/app/contents/components/communes-france/communes-france.module';
import { HeaderModule } from 'src/app/contents/components/header-title/header.module';

@NgModule({
  declarations: [
    OperationNoteDocumentListComponent,
    OperationNoteDocumentItemComponent,
    OperationNoteDocumentFormComponent,
  ],
  imports: [
    CommonModule,
    ContentsModule,
    SharedModule,
    NoResultModule,
    CoordonneesModule,
    HeaderModule,
    CommunesFranceModule,
  ],
  exports: [
    OperationNoteDocumentListComponent,
    OperationNoteDocumentItemComponent,
    OperationNoteDocumentFormComponent,
  ],
  providers: [OperationNoteDocumentService],
})
export class OperationNoteDocumentModule {}
