import { EMPTY, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { OperationState } from '../components/operation/store/operation.reducer';
import * as fromOperationAction from '../components/operation/store/operation.actions';
import * as fromOperationSelector from '../components/operation/store/operation.selectors';
import {
  OperationSmallId,
  OperationId,
} from '../components/operation/store/operation.model';

@Injectable({
  providedIn: 'any',
})
export class OperationResolverService {
  constructor(
    private operationStore: Store<OperationState>,
    private router: Router
  ) {}

  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<OperationId> | Promise<OperationId | any> | OperationId | any {
    let id = route.params['id'];
    let find = this.loadOperation(id).toPromise();

    return find
      .then(() => {
        return this.getOperation();
      })
      .catch(() => {
        this.router.navigate(['/projets']);
        return EMPTY;
      });
  }

  getOperation(): Observable<OperationId | any> {
    return this.operationStore.select(fromOperationSelector.operation);
  }

  loadOperation(id: string): Observable<any> {
    return of(
      this.operationStore.dispatch(fromOperationAction.loadOperation({ id }))
    );
  }
}
