import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  OperationsSelectState,
  operationsSelectsFeatureKey,
  selectAll,
} from './operations-select.reducer';

export const selectOperationsSelectsState =
  createFeatureSelector<OperationsSelectState>(operationsSelectsFeatureKey);

export const operations = createSelector(
  selectOperationsSelectsState,
  selectAll
);

export const error = createSelector(
  selectOperationsSelectsState,
  (state: OperationsSelectState) => state.error
);
