import { QueryOperationContactsService } from './../../../service/query-operation-contacts.service';
import { OperationContactsService } from 'src/app/service/operation-contacts.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { tap, exhaustMap, mergeMap, map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ItemEventId } from '../../../features/suivi/components/item-event/itemEvent.model';
import { NzMessageService } from 'ng-zorro-antd/message';
import * as fromOperationContactAction from './operation-contacts.actions';

@Injectable()
export class OperationContactsEffects {
  //GET CONTACTS
  loadContactsOperation = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationContactAction.loadOperationContacts),
      tap(() => fromOperationContactAction.loadingState({ loading: true })),
      exhaustMap((action) =>
        this.operationContactsService.getAll(action.operationId).pipe(
          map((contacts: any) =>
            fromOperationContactAction.loadOperationContactsSuccess({
              contacts,
            })
          ),
          catchError((error) =>
            of(
              fromOperationContactAction.loadOperationContactsFailure({ error })
            )
          )
        )
      ),
      tap(() => fromOperationContactAction.loadingState({ loading: false }))
    )
  );

  //GET CONTACT
  loadContactOperation = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationContactAction.loadOperationContact),
      tap(() => fromOperationContactAction.loadingState({ loading: true })),
      mergeMap((action) =>
        this.operationContactsService
          .getOne(action.operationId, action.id)
          .pipe(
            map((contact: any) =>
              fromOperationContactAction.loadOperationContactSuccess({
                contact,
              })
            ),
            catchError((error) =>
              of(
                fromOperationContactAction.loadOperationContactFailure({
                  error,
                })
              )
            )
          )
      ),
      tap(() => fromOperationContactAction.loadingState({ loading: false }))
    )
  );

  //ADD
  addContactOperation = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationContactAction.addOperationContact),
      tap(() => fromOperationContactAction.loadingState({ loading: true })),
      mergeMap((action) =>
        this.operationContactsService
          .addOne(action.operation, action.contact)
          .pipe(
            map((contact: any) => {
              const success: string = `${action.contact.displayName} ajouté au projet.`;
              this.message.success(success, { nzDuration: 6000 });
              return fromOperationContactAction.addOperationContactSuccess({
                success,
              });
            }),
            catchError((error) =>
              of(
                fromOperationContactAction.addOperationContactFailure({ error })
              )
            )
          )
      ),
      tap(() => fromOperationContactAction.loadingState({ loading: false }))
    )
  );

  //DELETE
  deleteContactOperation = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationContactAction.deleteOperationContact),
      tap(() => fromOperationContactAction.loadingState({ loading: true })),
      mergeMap((action) =>
        this.operationContactsService
          .deleteOne(action.operationId, action.id)
          .pipe(
            map((success: string) => {
              this.message.success(success, { nzDuration: 6000 });
              return fromOperationContactAction.deleteOperationContactSuccess({
                success,
              });
            }),
            catchError((error) =>
              of(
                fromOperationContactAction.deleteOperationContactFailure({
                  error,
                })
              )
            )
          )
      ),
      tap(() => fromOperationContactAction.loadingState({ loading: false }))
    )
  );

  //QUERY
  queryOperationContacts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationContactAction.queryOperationContacts),
      tap(() => fromOperationContactAction.loadingState({ loading: true })),
      mergeMap((action) =>
        this.queryOperationContactsService
          .queryContacts(action.filter, action.filterType, action.operationId)
          .pipe(
            map((contacts: any[]) =>
              fromOperationContactAction.queryOperationContactsSuccess({
                contacts,
              })
            ),
            catchError((error) =>
              of(
                fromOperationContactAction.queryOperationContactsFailure({
                  error,
                })
              )
            )
          )
      ),
      tap(() => fromOperationContactAction.loadingState({ loading: false }))
    )
  );

  constructor(
    private actions$: Actions,
    private message: NzMessageService,
    private operationContactsService: OperationContactsService,
    private queryOperationContactsService: QueryOperationContactsService
  ) {}
}
