import { Injectable } from '@angular/core';
import { Observable, of, EMPTY } from 'rxjs';
import { capitalize } from 'lodash';
import { fromUnixTime, format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { CandidatureExport } from '../store/candidature.model';
declare const require: any;
const { jsPDF } = require('jspdf');
require('jspdf-autotable');
@Injectable({
  providedIn: 'any',
})
export class CandidatureExportPdfService {
  pageWidth: number = 515.28;

  constructor() {}

  onExportCandidatsPDF(data: CandidatureExport): Observable<any> {
    try {
      const { candidats, fiche, user, isAnonyme } = data;

      if (!candidats?.length || !fiche || !user) EMPTY;

      const currentDate: Date = new Date(Date.now());
      const xhr = new XMLHttpRequest();
      xhr.open('GET', user.organisation.logo);
      xhr.responseType = 'blob';
      xhr.send();
      xhr.addEventListener('load', () => {
        const reader = new FileReader();
        reader.readAsDataURL(xhr.response);
        reader.addEventListener('loadend', (): any => {
          const logo = reader.result;
          if (logo) {
            const date = format(new Date(Date.now()), 'yyyyMM', {
              locale: fr,
            });

            // Date
            // set PDF
            const pdfSize = 'a4';
            const doc = new jsPDF('p', 'pt', pdfSize);
            doc.page = 1;
            // set propretie informations pdf
            doc.setProperties({
              title: `Candidatures`,
              type: 'Candidatures',
              date: format(new Date(Date.now()), 'dd/MM/yyyy', {
                locale: fr,
              }),
              author: user.displayName,
              keywords: 'candidatures',
            });

            if (fiche) {
              const { entite, mission, contacts, recherche } = fiche;
              const { metier, contrat, operation } = mission;

              this.headerPdf(fiche, user, doc, isAnonyme, logo);

              this.operation_table(operation, doc, logo, user, isAnonyme);

              this.entreprise_table(entite, doc, logo, user, isAnonyme);

              contacts?.length
                ? this.contacts_table(contacts, doc, logo, user, isAnonyme)
                : null;

              this.recherche_table(
                recherche,
                metier,
                contrat,
                doc,
                logo,
                user,
                isAnonyme
              );

              this.candidats_title_table(candidats, doc, logo, user, isAnonyme);
              this.candidats_presentation_table(
                candidats,
                doc,
                logo,
                user,
                isAnonyme
              );

              this.candidats_profils_title_table(
                candidats,
                doc,
                logo,
                user,
                isAnonyme
              );
              this.candidats_profils_table(
                candidats,
                doc,
                logo,
                user,
                isAnonyme
              );

              this.footerPdf(user, doc);

              //open || download || print
              const { denomination } = operation;
              const { uniteLegale } = entite;
              const denominationUniteLegale: string =
                uniteLegale?.denominationUniteLegale?.substring(0, 10);

              const denominationMinify: string = denomination.substring(0, 10);

              const nameFile: string = `${denominationMinify}_${denominationUniteLegale}_candidatures_${date}.pdf`;

              doc.save(`${nameFile}`);

              return of('success');
            } else {
              return of(null);
            }
          }
        });

        return of(null);
      });

      return EMPTY;
    } catch (error) {
      return of(error);
    }
  }

  //UTILS
  headerPdf(data: any, user: any, doc: any, anonym: boolean, logo: any): any {
    doc.setFont('helvetica', 'normal');

    doc.setFontSize(10);
    doc.setFont('helvetica', 'bold');
    //doc.text(`${user?.organisation?.nom_raison_sociale}`, 40, 40);
    doc.setFontSize(7);
    doc.setFont('helvetica', 'normal');
    // doc.text(`Siret : ${user?.organisation?.siret}`, 40, 50);

    // doc.text(40, 58, `Adresse : ${user?.organisation?.geo_adresse}`, {
    //   maxWidth: 140,
    // });

    doc.setFontSize(10);
    doc.setFont('helvetica', 'normal');
    doc.text(
      `Date de création : ${format(new Date(Date.now()), 'dd/MM/yyyy', {
        locale: fr,
      })}`,
      40,
      90
    );

    // Set author
    doc.setFontSize(14);
    doc.setFont('helvetica', 'normal');
    doc.setFont('helvetica', 'bold');
    doc.text(`Candidatures`, 257.64, 40);
    doc.setFont('helvetica', 'italic');
    doc.setFontSize(12);
    doc.text(`${anonym ? 'Anonymisée' : ''}`, 257.64, 60);

    // Set logo organisme
    doc.setFontSize(10);
    doc.addImage(`${logo ? logo : ''}`, 'PNG', 40, 20, 60, 30);
  }

  footerPdf(user: any, doc: any): any {
    const pageCount = doc.internal.getNumberOfPages();
    let str = `Page ${doc.internal.getNumberOfPages()}`;
    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(7);
      doc.text(
        'Page ' + String(i) + ' sur ' + String(pageCount),
        515.28,
        810,
        null,
        null,
        'right'
      );
    }

    doc.setFontSize(7);
    doc.setFont('helvetica', 'normal');
    doc.text(
      `${user.organisation.nom_raison_sociale}`,
      280,
      810,
      null,
      null,
      'center'
    );
  }

  operation_table(
    operation: any,
    doc: any,
    logo: any,
    user: any,
    anonym: boolean = false
  ): any {
    const header = [
      [
        {
          content: `Projet`,
          colSpan: 4,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'bold',
            fillColor: [0, 55, 102],
            textColor: [255, 255, 255],
          },
        },
      ],
      [
        {
          content: `Nom du projet`,
          colSpan: 2,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: `Donneurs d'ordre/financeurs`,
          colSpan: 1,

          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: `Type de projet`,
          colSpan: 1,

          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
    ];

    const body = [
      [
        {
          content: capitalize(operation?.denomination),
          colSpan: 2,

          styles: {
            halign: 'left',
            fontStyle: 'bold',
          },
        },
        {
          content: operation?.financeurs?.length
            ? operation?.financeurs
                .map(
                  (financeur: any) =>
                    financeur?.uniteLegale?.denominationUniteLegale
                )
                .join(', ')
            : '',
          colSpan: 1,

          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },

        {
          content: operation?.type,
          colSpan: 1,

          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
      [
        {
          content: ` ${
            operation?.description
              ? 'Description : ' + operation?.description
              : 'Aucune description renseignée'
          }`,
          colSpan: 4,

          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
    ];

    doc.autoTable({
      theme: 'grid',
      head: header,
      body: body,
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },
      headStyles: {},
      columnStyles: {
        0: { cellWidth: 257.5 },
        2: 'auto',
        3: 'auto',
      },

      bodyStyles: {
        0: { fontStyle: 'bold' },
      },

      didDrawPage: (data: any) => {
        this.headerPdf(data, user, doc, anonym, logo);
      },

      margin: { top: 100 },
    });
  }

  entreprise_table(
    entite: any,
    doc: any,
    logo: any,
    user: any,
    anonym: boolean = false
  ): any {
    const header = [
      [
        {
          content:
            'Entreprise : ' +
            `${anonym ? '' : entite?.uniteLegale?.denominationUniteLegale}`,
          colSpan: 4,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'bold',
            fillColor: [0, 55, 102],
            textColor: [255, 255, 255],
          },
        },
      ],
      [
        {
          content: 'Siret',
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Acticité',
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Statut juridique',
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Adresse',
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
    ];

    const body = [
      [
        {
          content: anonym ? '' : `${entite?.siret}`,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content:
            entite?.uniteLegale?.activite_principale_libelle?.intitule_naf,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: entite?.uniteLegale?.categorieEntreprise,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: entite?.adresseEtablissement?.fullAdresse,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
    ];

    doc.autoTable({
      theme: 'grid',
      head: header,
      body: body,
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },
      headStyles: {},
      columnStyles: {
        0: 'auto',
        1: 'auto',
        2: 'auto',
        3: 'auto',
      },

      bodyStyles: {
        0: { fontStyle: 'bold' },
      },

      didDrawPage: (data: any) => {
        this.headerPdf(data, user, doc, anonym, logo);
      },

      margin: { top: 100 },
    });
  }
  contacts_table(
    contacts: any,
    doc: any,
    logo: any,
    user: any,
    anonym: boolean = false
  ): any {
    const header = [
      [
        {
          content: `Contacts`,
          colSpan: 5,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'bold',
            fillColor: [0, 55, 102],
            textColor: [255, 255, 255],
          },
        },
      ],
      [
        {
          content: 'Identité',
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Fonction',
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Rôle',
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Mobile',
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'E-mail',
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
    ];

    const body = contacts.map((contact: any) => [
      {
        content: anonym
          ? ''
          : `${contact?.civilite}. ${contact?.lastName} ${contact?.firstName}`,
        styles: {
          halign: 'left',
          fontStyle: 'normal',
        },
      },
      {
        content: contact.fonction,
        styles: {
          halign: 'left',
          fontStyle: 'normal',
        },
      },
      {
        content: contact.role,
        styles: {
          halign: 'left',
          fontStyle: 'normal',
        },
      },
      {
        content: anonym ? '' : contact.coordonnees?.mobile,
        styles: {
          halign: 'left',
          fontStyle: 'normal',
        },
      },
      {
        content: anonym ? '' : contact.coordonnees?.email,
        styles: {
          halign: 'left',
          fontStyle: 'normal',
        },
      },
      ,
    ]);

    doc.autoTable({
      theme: 'grid',
      head: header,
      body: body,
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },
      headStyles: {},
      columnStyles: {
        0: { cellWidth: 103 },
        1: 'auto',
        2: 'auto',
        3: 'auto',
        4: 'auto',
      },

      bodyStyles: {
        0: { fontStyle: 'bold' },
      },

      didDrawPage: (data: any) => {
        this.headerPdf(data, user, doc, anonym, logo);
      },

      margin: { top: 100 },
    });
  }
  recherche_table(
    recherche: any,
    metier: any,
    contrat: any,
    doc: any,
    logo: any,
    user: any,
    anonym: boolean = false
  ): any {
    const header = [
      [
        {
          content: `Recherche`,
          colSpan: 2,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'bold',
            fillColor: [0, 55, 102],
            textColor: [255, 255, 255],
          },
        },
      ],
      [
        {
          content: `Nombre de candidatures`,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: `Type de mission`,
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
    ];

    const body = [
      [
        {
          content: recherche?.participantsSize,
          styles: {
            halign: 'left',
            fontStyle: 'bold',
          },
        },
        {
          content: `${
            recherche?.isClauseInsertion
              ? "Clause d'insertion sociale"
              : "Hors clause d'insertion sociale"
          }`,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
      [
        {
          content: `Limitation géographique`,
          colSpan: 2,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
      [
        {
          content: `Région : ${
            recherche?.geoLimitation?.region?.nom
              ? recherche?.geoLimitation?.region?.nom
              : 'Aucune restriction régionale'
          },\nDépartement : ${
            recherche?.geoLimitation?.departement?.nom
              ? recherche?.geoLimitation?.departement?.nom
              : 'Aucune restricte départementale'
          },\nCommune : ${
            recherche?.geoLimitation?.commune?.nom
              ? recherche?.geoLimitation?.commune?.nom
              : 'Aucune restriction communale'
          }`,
          colSpan: 2,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
      [
        {
          content: `Grand domaine`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: `Domaine professionnel`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
      [
        {
          content: `${metier?.metier?.domaineProfessionnel?.grandDomaine?.libelle}`,
          styles: {
            halign: 'left',
            fontStyle: 'bold',
          },
        },
        {
          content: `${metier?.metier?.domaineProfessionnel?.libelle}`,
          styles: {
            halign: 'left',
            fontStyle: 'bold',
          },
        },
      ],

      [
        {
          content: `Métier`,
          colSpan: 2,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
      [
        {
          content: `${metier?.libelle} - ROME : ${metier?.metier?.code}`,
          colSpan: 2,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'bold',
          },
        },
      ],
      [
        {
          content: 'Type de contrat',
          styles: {
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Nature du contrat',
          styles: {
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
      [
        {
          content: contrat.type?.libelle,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: contrat.nature?.libelle,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
    ];

    doc.autoTable({
      theme: 'grid',
      head: header,
      body: body,
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },
      headStyles: {},
      columnStyles: {
        0: 'auto',
        1: 'auto',
      },

      bodyStyles: {
        0: { fontStyle: 'bold' },
      },

      didDrawPage: (data: any) => {
        this.headerPdf(data, user, doc, anonym, logo);
      },

      margin: { top: 100 },
    });
  }

  metier_table(
    metier: any,
    doc: any,
    logo: any,
    user: any,
    anonym: boolean = false
  ): any {
    const header = [
      [
        {
          content: `Métier : ${metier?.libelle} - ROME : ${metier?.metier?.code}`,
          colSpan: 2,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'bold',
            fillColor: [0, 55, 102],
            textColor: [255, 255, 255],
          },
        },
      ],
    ];

    const body = [
      [
        {
          content: `Grand domaine : ${metier?.metier?.domaineProfessionnel?.grandDomaine?.libelle}`,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: `Domaine professionnel : ${metier?.metier?.domaineProfessionnel?.libelle}`,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
      [
        {
          content: `Définition : ${metier?.metier?.informations?.definition?.replace(
            /\n/g,
            ' '
          )}`,
          colSpan: 2,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
      [
        {
          content: `Compétences de base - Savoir`,
          colSpan: 1,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: `Compétences de base - Savoir faire`,
          colSpan: 1,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],

      [
        {
          content: `${
            metier?.competencesDeBaseType['Savoir']?.length
              ? metier?.competencesDeBaseType['Savoir']
                  .map((el: any) => el.libelle)
                  .join(', ')
              : ''
          }`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: `${
            metier?.competencesDeBaseType['SavoirFaire']?.length
              ? metier?.competencesDeBaseType['SavoirFaire']
                  .map((el: any) => el.libelle)
                  .join(', ')
              : ''
          }`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
      [
        {
          content: `Compétences spéficiques - Savoir`,
          colSpan: 1,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: `Compétences spéficiques - Savoir faire`,
          colSpan: 1,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
      [
        {
          content: `${
            metier?.competencesSpecifiques?.length
              ? metier?.competencesSpecifiques
                  .map((el: any) => el.libelle)
                  .join(', ')
              : ''
          }`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: `${
            metier?.competencesSpecifiques?.length
              ? metier?.competencesSpecifiques
                  .map((el: any) => el.libelle)
                  .join(', ')
              : ''
          }`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
      [
        {
          content: `Compétences transversales`,
          colSpan: 2,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
      [
        {
          content: `${
            metier?.softSkills?.length
              ? metier?.softSkills.map((el: any) => el?.summary).join(', ')
              : ''
          }`,
          colSpan: 2,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
      [
        {
          content: `Environnement`,
          colSpan: 2,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
      [
        {
          content: `${
            metier?.environnementsTravail?.length
              ? metier?.environnementsTravail
                  .map((el: any) => el?.libelle)
                  .join(', ')
              : ''
          }`,
          colSpan: 2,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
    ];

    doc.autoTable({
      theme: 'grid',
      head: header,
      body: body,
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },
      headStyles: {},
      columnStyles: {
        0: { cellWidth: 257.5 },
        1: 'auto',
      },

      bodyStyles: {
        0: { fontStyle: 'bold' },
      },

      didDrawPage: (data: any) => {
        this.headerPdf(data, user, doc, anonym, logo);
      },

      margin: { top: 100 },
    });
  }
  contrat_table(
    mission: any,
    doc: any,
    logo: any,
    user: any,
    anonym: boolean = false
  ): any {
    const header = [
      [
        {
          content: `Contrat`,
          colSpan: 2,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'bold',
            fillColor: [0, 55, 102],
            textColor: [255, 255, 255],
          },
        },
      ],
    ];

    const body = [
      [
        {
          content: 'Type de contrat',
          styles: {
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Nature du contrat',
          styles: {
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
      [
        {
          content: mission.contrat.type?.libelle,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: mission.contrat.nature?.libelle,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
      [
        {
          content: `Expérience`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: `Taux horaire`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
      [
        {
          content: mission.contrat?.experience?.libelleCaracteristique,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: `${mission.contrat?.work_price} €`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
      [
        {
          content: `Niveau de rémunération`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: `Compléments de salaire`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
      [
        {
          content: mission?.contrat?.salaire?.libelleCaracteristique,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: `${
            mission?.contrat?.salaireComplements?.length
              ? mission?.contrat?.salaireComplements.map(
                  (el: any, index: number) =>
                    `${index > 0 ? '\n' : ''}${capitalize(
                      el?.libelle?.libelle
                    )} - ${el.taux} ${el.unit}`
                )
              : ''
          }`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
      [
        {
          content: `Dates`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: `Souplesse de démarrage`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
      [
        {
          content: `Du ${format(
            fromUnixTime(mission.contrat?.datesContrat[0]['seconds']),
            'dd/MM/yyyy',
            { locale: fr }
          )} au ${format(
            fromUnixTime(mission.contrat?.datesContrat[1]['seconds']),
            'dd/MM/yyyy',
            { locale: fr }
          )}`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: `Du ${format(
            fromUnixTime(mission.contrat?.datesSouplesse[0]['seconds']),
            'dd/MM/yyyy',
            { locale: fr }
          )} au ${format(
            fromUnixTime(mission.contrat?.datesSouplesse[1]['seconds']),
            'dd/MM/yyyy',
            { locale: fr }
          )}`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
      [
        {
          content: `Adresse`,
          colSpan: 2,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
      [
        {
          content: `${mission?.adresse?.adresse?.properties?.label}`,
          colSpan: 2,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
      [
        {
          content: `Jours de travail`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: `Horaires`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
      [
        {
          content: `${
            mission?.contrat?.work_days?.length
              ? mission?.contrat?.work_days.map((el: any) => el).join(', ')
              : ''
          }`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: `Avant-midi: ${mission.contrat?.horaireStartAM} h - ${mission.contrat?.horaireEndAM} h,\nAprès-midi : ${mission.contrat?.horaireStartPM} h - ${mission.contrat?.horaireEndPM} h,\nTemps de pause : ${mission.contrat?.pause_dejeuner} h`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
      [
        {
          content: `Durée hebdomadaire`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: `Période d'essaie`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
      [
        {
          content: `${mission?.contrat?.duree_hebdomadaire}`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: `${mission.contrat?.periodeDessaie}`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
      [
        {
          content: `Astreinte et suivi renforcé`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: `Déplacement`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
      [
        {
          content: `Astreinte : ${
            mission?.contrat?.isAstreinte ? 'OUI' : 'NON'
          } - Suivi renforcé : ${
            mission?.contrat?.isSuiviRenforce ? 'OUI' : 'NON'
          }`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: `${
            mission?.contrat?.isDeplacement
              ? 'Des déplacements sont à prévoir'
              : 'Aucun déplacement prévu'
          },\nZones de déplacement : ${
            mission?.contrat?.zone_deplacement?.length
              ? mission?.contrat?.zone_deplacement
                  .map((el: any) => el.libelleTypeTerritoire)
                  .join(', ')
              : 'Aucune zone de déplacement'
          }`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
      [
        {
          content: `Permis de conduire`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: `Bureautique`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
      [
        {
          content: `${
            mission?.contrat?.permis?.length
              ? mission?.contrat?.permis.map(
                  (el: any, index: number) =>
                    `${index > 0 ? '\n' : ''}${el.libelle}`
                )
              : ''
          }`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: `${
            mission?.contrat?.bureautique?.length
              ? mission?.contrat?.bureautique.map(
                  (el: any, index: number) => `${index > 0 ? '\n' : ''}${el}`
                )
              : ''
          }`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
      [
        {
          content: `Évolution professionnelle`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: `Conditions d'évolution professionnelle`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
      [
        {
          content: mission?.contrat?.evolution
            ? mission?.contrat?.evolution?.libelle
            : '',
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: mission?.contrat?.evolutionCondition
            ? mission?.contrat?.evolutionCondition
            : '',
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
      [
        {
          content: `Motif`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: `Justificatif`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
      [
        {
          content: mission?.contrat?.motif,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: mission?.contrat?.justificatif,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
      [
        {
          content: `Suivi au sein de l'entreprise`,
          colSpan: 2,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
      [
        {
          content: anonym ? '' : mission?.contrat?.suiviInterne,
          colSpan: 2,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
    ];

    doc.autoTable({
      theme: 'grid',
      head: header,
      body: body,
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },
      headStyles: {},
      columnStyles: {
        0: { cellWidth: 257.5 },
        1: 'auto',
      },

      bodyStyles: {
        0: { fontStyle: 'bold' },
      },

      didDrawPage: (data: any) => {
        this.headerPdf(data, user, doc, anonym, logo);
      },

      margin: { top: 100 },
    });
  }

  candidats_title_table(
    candidats: any[],
    doc: any,
    logo: any,
    user: any,
    anonym: boolean = false
  ): any {
    doc.addPage();

    const header = [
      [
        {
          content: `Présentation des ${candidats?.length} candidat${
            candidats?.length > 1 ? 's' : ''
          }`,
          colSpan: 2,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'bold',
            fillColor: [0, 55, 102],
            textColor: [255, 255, 255],
          },
        },
      ],
    ];

    doc.autoTable({
      theme: 'grid',
      head: header,
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },
      headStyles: {},
      columnStyles: {
        0: 'auto',
      },

      bodyStyles: {
        0: { fontStyle: 'bold' },
      },

      didDrawPage: (data: any) => {
        this.headerPdf(data, user, doc, anonym, logo);
      },

      margin: { top: 100 },
    });
  }

  candidats_presentation_table(
    candidats: any[],
    doc: any,
    logo: any,
    user: any,
    anonym: boolean = false
  ): any {
    candidats.map((candidat: any) => {
      const header = [
        [
          {
            content: !anonym ? `${candidat?.person?.displayName}` : 'Anonymisé',
            colSpan: 1,
            styles: {
              halign: 'left',
              fontStyle: 'bold',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
          {
            content: `${
              candidat?.person?.genre ? candidat?.person?.genre : ''
            }`,
            colSpan: 1,
            styles: {
              halign: 'left',
              fontStyle: 'bold',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
        ],
      ];

      const body = [
        [
          {
            content: `${
              candidat?.person?.diagnostic?.presentation
                ? 'Présentation : ' + candidat?.person?.diagnostic?.presentation
                : 'Aucune présentation renseignée par le prescripteur'
            }`,
            colSpan: 2,
            styles: {
              font: 'helvetica',
              halign: 'left',
              fontStyle: 'normal',
            },
          },
        ],
      ];

      doc.autoTable({
        theme: 'grid',
        head: header,
        body: body,
        styles: {
          cellPadding: 3,
          fontSize: 10,
          textColor: [0, 0, 0],
        },
        headStyles: {},
        columnStyles: {
          0: 'auto',
          1: 150,
        },

        bodyStyles: {
          0: { fontStyle: 'bold' },
        },

        didDrawPage: (data: any) => {
          this.headerPdf(data, user, doc, anonym, logo);
        },

        margin: { top: 100 },
      });
    });
  }

  candidats_profils_title_table(
    candidats: any[],
    doc: any,
    logo: any,
    user: any,
    anonym: boolean = false
  ): any {
    doc.addPage();
    const header = [
      [
        {
          content: `Les ${candidats?.length} profil${
            candidats?.length > 1 ? 's' : ''
          } reçu${candidats?.length > 1 ? 's' : ''}`,
          colSpan: 4,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'bold',
            fillColor: [0, 55, 102],
            textColor: [255, 255, 255],
          },
        },
      ],
    ];

    doc.autoTable({
      theme: 'grid',
      head: header,
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },
      headStyles: {},
      columnStyles: {
        0: 50,
        1: 'auto',
        2: 200,
        3: 50,
      },

      bodyStyles: {
        0: { fontStyle: 'bold' },
      },

      didDrawPage: (data: any) => {
        this.headerPdf(data, user, doc, anonym, logo);
      },

      margin: { top: 100 },
    });
  }

  candidats_profils_table(
    candidats: any[],
    doc: any,
    logo: any,
    user: any,
    anonym: boolean = false
  ): any {
    candidats.map((candidat: any, index: number) => {
      const header = [
        [
          {
            content: `${index + 1}`,
            colSpan: 4,
            styles: {
              font: 'helvetica',
              halign: 'left',
              fontSize: 12,
              fontStyle: 'bold',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
        ],
        [
          {
            content: `${candidat?.person?.displayName}`,
            colSpan: 3,
            styles: {
              font: 'helvetica',
              halign: 'left',
              fontStyle: 'bold',
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
            },
          },
          {
            content: `${
              candidat?.person?.genre ? candidat?.person?.genre : ''
            }`,
            colSpan: 1,
            styles: {
              font: 'helvetica',
              halign: 'left',
              fontStyle: 'bold',
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
            },
          },
        ],
      ];

      const body = [
        [
          {
            content: 'Mobile',
            colSpan: 1,
            styles: {
              halign: 'left',
              fontStyle: 'normal',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
          {
            content: `E-mail`,
            colSpan: 1,
            styles: {
              halign: 'left',
              fontStyle: 'normal',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
          {
            content: `Adresse`,
            colSpan: 1,
            styles: {
              halign: 'left',
              fontStyle: 'normal',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
          {
            content: `Distance`,
            colSpan: 1,
            styles: {
              halign: 'left',
              fontStyle: 'normal',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
        ],

        [
          {
            content: !anonym
              ? `${
                  candidat?.person?.coordonnees?.mobile
                    ? candidat?.person?.coordonnees?.mobile
                    : 'Non renseigné'
                }`
              : 'Anonymisé',
            colSpan: 1,
            styles: {
              font: 'helvetica',
              halign: 'left',
              fontStyle: 'normal',
            },
          },
          {
            content: !anonym
              ? `${
                  candidat?.person?.coordonnees?.email
                    ? candidat?.person?.coordonnees?.email
                    : 'Non renseigné'
                }`
              : 'Anonymisé',
            colSpan: 1,
            styles: {
              font: 'helvetica',
              halign: 'left',
              fontStyle: 'normal',
            },
          },
          {
            content: !anonym
              ? `${
                  candidat?.person?.adresse?.adresse?.label
                    ? candidat?.person?.adresse?.adresse?.label
                    : 'Non renseignée'
                }`
              : 'Anonymisée',
            colSpan: 1,
            styles: {
              font: 'helvetica',
              halign: 'left',
              fontStyle: 'normal',
            },
          },
          {
            content: !anonym
              ? `${
                  candidat?.person?.distance?.distanceKilometer
                    ? 'Réside à ' +
                      candidat?.person?.distance?.distanceKilometer +
                      'Km du lieu de travail'
                    : ''
                }`
              : 'Non renseignée ou anonymisé',
            colSpan: 1,
            styles: {
              font: 'helvetica',
              halign: 'left',
              fontStyle: 'normal',
            },
          },
        ],
        [
          {
            content: 'Prescripteur',
            colSpan: 2,
            styles: {
              halign: 'left',
              fontStyle: 'normal',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
          {
            content: `Commune`,
            colSpan: 2,
            styles: {
              halign: 'left',
              fontStyle: 'normal',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
        ],

        [
          {
            content:
              candidat?.prescripteur?.etablissement?.uniteLegale
                ?.denominationUniteLegale,
            colSpan: 2,
            styles: {
              font: 'helvetica',
              halign: 'left',
              fontStyle: 'normal',
            },
          },
          {
            content: candidat?.prescripteur?.etablissement?.adresseEtablissement
              ?.commune?.nom
              ? candidat?.prescripteur?.etablissement?.adresseEtablissement
                  ?.commune?.nom
              : '',
            colSpan: 2,
            styles: {
              font: 'helvetica',
              halign: 'left',
              fontStyle: 'normal',
            },
          },
        ],
        [
          {
            content: 'Référent',
            colSpan: 2,
            styles: {
              halign: 'left',
              fontStyle: 'normal',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
          {
            content: `Mobile`,
            colSpan: 1,
            styles: {
              halign: 'left',
              fontStyle: 'normal',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
          {
            content: `E-mail`,
            colSpan: 1,
            styles: {
              halign: 'left',
              fontStyle: 'normal',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
        ],

        [
          {
            content: `${candidat?.prescripteur?.displayName}`,
            colSpan: 2,
            styles: {
              font: 'helvetica',
              halign: 'left',
              fontStyle: 'normal',
            },
          },
          {
            content: `${
              candidat?.prescripteur?.coordonnees?.mobile
                ? candidat?.prescripteur?.coordonnees?.mobile
                : 'Non renseigné'
            }`,
            colSpan: 1,
            styles: {
              font: 'helvetica',
              halign: 'left',
              fontStyle: 'normal',
            },
          },
          {
            content: `${
              candidat?.prescripteur?.coordonnees?.email
                ? candidat?.prescripteur?.coordonnees?.email
                : 'Non renseigné'
            }`,
            colSpan: 1,
            styles: {
              font: 'helvetica',
              halign: 'left',
              fontStyle: 'normal',
            },
          },
        ],
      ];

      doc.autoTable({
        theme: 'grid',
        head: header,
        body: body,
        styles: {
          cellPadding: 3,
          fontSize: 10,
          textColor: [0, 0, 0],
        },
        headStyles: {},
        columnStyles: {
          0: 'auto',
          1: 'auto',
          2: 200,
          3: 50,
        },

        bodyStyles: {
          0: { fontStyle: 'bold' },
        },

        didDrawPage: (data: any) => {
          this.headerPdf(data, user, doc, anonym, logo);
        },

        margin: { top: 100 },
      });
    });
  }
}
