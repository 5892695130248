<nz-page-header
  class="page-header"
  [nzTitle]="header"
  [nzSubtitle]="cardExtra"
  *ngIf="source$ | async | size"
>
  <nz-page-header-extra>
    <app-button-dropdown></app-button-dropdown>
  </nz-page-header-extra>
  <nz-page-header-content> </nz-page-header-content>
</nz-page-header>
<nz-divider *ngIf="source$ | async | size"></nz-divider>
<ng-template #header>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="24">
      <h4 nz-typography>Suivis</h4>
    </nz-col>
  </nz-row>
</ng-template>
<ng-template #cardExtra>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="24">
      <nz-badge [nzCount]="source$ | async | size" [nzOverflowCount]="99">
      </nz-badge>
    </nz-col>
  </nz-row>
</ng-template>

<app-layout-list
  *ngIf="source$ | async | size; else noResults"
  [card]="card"
  [new]="new"
  [source]="source$ | async"
></app-layout-list>

<ng-template #card let-item let-position="idx">
  <nz-card
    nzHoverable
    class="cardItem"
    [nzTitle]="cardTitle"
    [nzExtra]="cardExtra"
    (click)="$event.stopPropagation(); onSelect(item)"
  >
    <ng-template #cardTitle>
      <app-header-title [icon]="'edit_note'"></app-header-title>
    </ng-template>
    <ng-template #cardExtra>
      <button
        nz-button
        nzShape="circle"
        nzType="text"
        nz-dropdown
        [nzDropdownMenu]="suiviMenuCard"
        nzPlacement="bottomRight"
        nzTrigger="click"
        (click)="$event.stopPropagation()"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
    </ng-template>

    <nz-dropdown-menu #suiviMenuCard="nzDropdownMenu">
      <ul nz-menu>
        <li nz-menu-item (click)="$event.stopPropagation(); onSelect(item)">
          <nz-row nzAlign="top" nzGutter="8" nzJustify="start">
            <nz-col nzSpan="4">
              <mat-icon>visibility</mat-icon>
            </nz-col>
            <nz-col nzSpan="2">
              <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
            </nz-col>
            <nz-col nzSpan="18"> Afficher </nz-col>
          </nz-row>
        </li>
        <li nz-menu-divider></li>
        <li
          nz-menu-item
          nzDanger
          nz-typography
          (click)="$event.stopPropagation()"
          nz-popconfirm
          [nzPopconfirmTitle]="messageAlert"
          nzPopconfirmPlacement="bottom"
          (nzOnConfirm)="onDelete(item)"
          (nzOnCancel)="cancelDelete()"
        >
          <nz-row nzAlign="top" nzGutter="8" nzJustify="start">
            <nz-col nzSpan="4">
              <mat-icon>delete</mat-icon>
            </nz-col>
            <nz-col nzSpan="2">
              <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
            </nz-col>
            <nz-col nzSpan="18"> Supprimer </nz-col>
          </nz-row>
        </li>
      </ul>
    </nz-dropdown-menu>

    <div class="cardContentSuivi">
      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col nzSpan="24">
          <strong nz-typography>{{
            item.object | titlecase | carLimitation: 100
          }}</strong>
        </nz-col>
      </nz-row>
    </div>

    <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
    <nz-row nzAlign="top" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="12">
        <app-avatar-group [source]="[item.auteur]"></app-avatar-group>
      </nz-col>
      <nz-col nzSpan="12">
        <small>{{ item?.dateStart | dateFr: "small" }}</small>
      </nz-col>
    </nz-row>
  </nz-card>

  <ng-template #cardAction>
    <p
      nz-typography
      (click)="$event.stopPropagation()"
      nz-popconfirm
      nzPopconfirmTitle="Voulez-vous supprimer cette activité : {{
        item.object
      }} ?"
      nzPopconfirmPlacement="bottom"
      (nzOnConfirm)="onDelete(item)"
      (nzOnCancel)="cancelDelete()"
    >
      Supprimer
    </p>
  </ng-template>
</ng-template>

<ng-template #new>
  <app-card-new
    [type]="'suivi'"
    [height]="'260px'"
    [width]="'300px'"
    [title]="newCardTitle"
    [description]="''"
    [definition]="newCardDefinition"
    (add)="onNewEvent()"
  ></app-card-new>
</ng-template>

<ng-template #noResults>
  <app-no-result
    (new)="onNewEvent()"
    [icon]="'post_add'"
    [addtitle]="'Ajouter un suivi'"
    [notitle]="'Aucune suivi trouvé'"
  ></app-no-result>
</ng-template>

<ng-template #suiviTitleForm>
  <app-header-title
    [icon]="'article'"
    [title]="newCardTitle"
  ></app-header-title>
</ng-template>
