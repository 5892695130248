<nz-card
  nzHoverabl
  [nzTitle]="formationSuggestionCardTitle"
  nzSize="small"
  (click)="onSelect(formation)"
>
  <div class="formation_company">
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="24">
        {{ formation?.company?.name | carLimitation: 35 }}
      </nz-col>
    </nz-row>
  </div>
  <nz-space nzDirection="vertical" [nzSize]="50"></nz-space>

  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="24">
      <nz-tag nzColor="geekblue">
        {{ formation?.rncpLabel | carLimitation: 25 }}
      </nz-tag>
    </nz-col>
  </nz-row>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="24">
      <nz-tag nzColor="volcano" *ngIf="formation?.rncpEligibleApprentissage">
        {{ isApprentissage }}
      </nz-tag>
    </nz-col>
  </nz-row>
  <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="24">
      <nz-tag nzColor="geekblue">
        <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
          <nz-col nzSpan="4"><mat-icon>map</mat-icon></nz-col>
          <nz-col nzSpan="4"></nz-col>
          <nz-col nzSpan="16">{{
            formation?.place?.city | carLimitation: 14
          }}</nz-col>
        </nz-row>
      </nz-tag>
    </nz-col>
  </nz-row>

  <!-- title
diplomaLevel
rncpCode
rncpLabel
period
capacity
onisepUrl
company {
  name
  headquarter {
    place {
      address
      zipCode
      city
    }
  }
}
place {
  fullAddress
  latitude
  longitude
  city
  region
}
diploma
cfd
longTitle
rncpEligibleApprentissage
training -->
</nz-card>

<ng-template #formationSuggestionCardTitle>
  {{ formation?.title | carLimitation: 30 }}
</ng-template>
