<app-card-actions *ngIf="contact">
  <ul nz-menu [nzMode]="'inline'">
    <li nz-menu-item (click)="onSelect('details')">
      <mat-icon>person</mat-icon>
      <span>Profil</span>
    </li>
    <li nz-menu-item (click)="onSelect('entites')">
      <mat-icon>domaine</mat-icon>
      <span>Entités</span>
    </li>

    <li nz-menu-item (click)="onSelect('metiers')">
      <mat-icon>business_center</mat-icon>
      <span>Métiers</span>
    </li>

    <li nz-menu-item (click)="onSelect('suivis')">
      <mat-icon>article</mat-icon>
      <span>Suivis</span>
    </li>

    <li nz-menu-item (click)="onSelect('documents')">
      <mat-icon>insert_drive_file</mat-icon>

      <span>Documents</span>
    </li>

    <li nz-menu-item (click)="onSelect('projets')">
      <mat-icon> analytics </mat-icon>
      <span>Projets</span>
    </li>

    <!-- <li nz-menu-item (click)="onSelect('dashboard')">
      <mat-icon> pie_chart </mat-icon>
      <span>Tableau de bord</span>
    </li> -->
  </ul>
</app-card-actions>
