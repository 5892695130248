import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  OperationNoteSubtitleState,
  operationNoteSubtitlesFeatureKey,
  selectAll,
} from './operation-note-subtitle.reducer';

export const selectOpearationNoteSubtitlesState =
  createFeatureSelector<OperationNoteSubtitleState>(
    operationNoteSubtitlesFeatureKey
  );

export const subtitles = createSelector(
  selectOpearationNoteSubtitlesState,
  selectAll
);

export const subtitle = createSelector(
  selectOpearationNoteSubtitlesState,
  (state: OperationNoteSubtitleState) => state.subtitle
);
export const loading = createSelector(
  selectOpearationNoteSubtitlesState,
  (state: OperationNoteSubtitleState) => state.loading
);

export const error = createSelector(
  selectOpearationNoteSubtitlesState,
  (state: OperationNoteSubtitleState) => state.error
);
