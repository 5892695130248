import { FichesDePosteFormComponent } from '../fiches-de-poste-form/fiches-de-poste-form.component';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';

@Component({
  selector: 'app-fiches-de-poste-list',
  templateUrl: './fiches-de-poste-list.component.html',
  styleUrls: ['./fiches-de-poste-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FichesDePosteListComponent implements OnInit, OnChanges {
  fichesDePoste$: Observable<any> = of(null);
  subscribe = new Subject();
  view$ = new BehaviorSubject<string>('list');
  title: string = 'Nouvelle fiche de poste';
  titleClauses: string = 'Nouvelle fiche';
  titleHeader: string = 'Fiches de poste';
  titleHeaderPlaceholder: string =
    'Recherche une fiche de poste par métier ou entreprise';
  titleHeaderDrowndownDeleteAll: string =
    'Voulez-vous supprimer toutes les fiches de poste ?';

  newCardTitleClauses: string = 'Nouvelle fiche';
  newAddItemTitle: string = 'Ajouter une fiche de poste';
  noDataTitle: string = 'Aucune fiche de poste ajoutée';
  newItemDefinition: string =
    'Une fiche de poste est un outil de recrutement permettant une description des caractéristiques d’un poste de travail';

  rechercheEnCoursTitle: string = 'Recherche en cours';
  rechercheClotureTitle: string = 'Recherche clôturée';
  deleteType: string = 'Voulez-vous supprimer cette fiche de poste ?';
  statisticValuesStyle = {
    'font-size': '12px',
  };

  @ViewChild('ficheDePosteFormTitle', { static: false })
  ficheDePosteFormTitle: TemplateRef<any> | any;
  modalWidth: number = 1050;

  @Input() fiches$: Observable<any> = of([]);
  @Input() loading: boolean = false;
  @Input() operationItem: any = null;
  @Input() isOperation: boolean = true;

  @Output() select = new EventEmitter<any>(false);
  @Output() add = new EventEmitter<any>(false);
  @Output() update = new EventEmitter<any>(false);
  @Output() delete = new EventEmitter<any>(false);
  @Output() copy = new EventEmitter<any>(false);
  @Output() deleteAll = new EventEmitter<any>(false);
  @Output() filtered = new EventEmitter<any>(false);
  @Output() searched = new EventEmitter<any>(false);

  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}

  onNew(operationItem: any, isOperation: boolean): void {
    const modal = this.modal.create({
      nzContent: FichesDePosteFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.ficheDePosteFormTitle,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '10px' },
    });
    const instance = modal.getContentComponent();
    instance.operationItem = operationItem;
    instance.isOperation = isOperation;
    instance.cancel.subscribe((): any => {
      modal.close();
    });
    instance.add.subscribe((fiche: any): any => {
      if (!fiche) {
        return null;
      } else {
        this.onAdd(fiche);
        modal.close();
      }
    });
  }

  onSelect(fiche: any): void {
    if (!fiche) return;
    this.select.emit(fiche);
  }

  onFilerSearch(event: any): void {
    this.searched.next(event);
  }

  onFilerAction(event: any): void {
    switch (event) {
      case 'on-search':
        this.searched.next(event);
        break;
      case 'off-search':
        this.searched.next(event);

        break;
      case 'on-filter':
        this.filtered.emit(event);

        break;
      case 'off-filter':
        this.filtered.emit(event);
        break;
      case 'deleteAll':
        this.deleteAll.emit(event);
        break;
      default:
        break;
    }
  }

  onCardDropdown(event: string, fiche: any): void {
    if (!event || !fiche) return;
    switch (event) {
      case 'delete':
        this.onDelete(fiche);
        break;
      case 'update':
        this.onUpdate(fiche);
        break;
      case 'copy':
        this.onCopy(fiche);
        break;
      default:
        break;
    }
  }

  onAdd(fiche: any): void {
    if (!fiche) return;
    this.add.emit(fiche);
  }
  onUpdate(fiche: any): void {
    if (!fiche) return;
    this.update.emit(fiche);
  }
  onCopy(fiche: any): void {
    if (!fiche) return;
    this.copy.emit(fiche);
  }
  onDelete(fiche: any): void {
    if (!fiche) return;
    this.delete.emit(fiche);
  }
  onDeleteAll(): void {
    this.deleteAll.emit(true);
  }
}
