import { Injectable } from '@angular/core';
import { Mutation, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class CreateParticipantDocumentGQL extends Mutation {
  document = gql`
    mutation CreateParticipantDocuments($id: ID!, $document: DocumentInput!) {
      createParticipantDocuments(id: $id, document: $document) {
        id
        fileName
        fileRef
        title
        type
        extension
        description
        correspondanceId
        auteur {
          avatar
          displayName
          setting {
            color
          }
        }
        dateStart
      }
    }
  `;
}
