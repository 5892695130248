import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
  OnDestroy,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Commune } from '../commune.model';
import {
  UntypedFormControl,
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  FormGroup,
} from '@angular/forms';
import * as _ from 'lodash';
import { of, Subject } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable, BehaviorSubject } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { GeoAdresseState } from '../store/geo-adresse.reducer';
import * as fromGeoActions from '../store/geo-adresse.actions';
import * as fromGeoSelectors from '../store/geo-adresse.selectors';
import { GeoAdresse } from '../store/geo-adresse.model';
import { NzSelectSizeType } from 'ng-zorro-antd/select';

@Component({
  selector: 'app-communes-france',
  templateUrl: './communes-france.component.html',
  styleUrls: ['./communes-france.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommunesFranceComponent implements OnInit, OnChanges, OnDestroy {
  subscribe = new Subject();
  loading$: Observable<boolean> = of(false);
  GEO_ADRESSES$: Observable<GeoAdresse[] | any> = of(null);
  @Input() currentAdresse$ = new BehaviorSubject<string>('');

  searchControl = new UntypedFormControl();
  searchSelect: any = null;
  complement = new UntypedFormControl();
  complementValue: string = '';
  qpv = new UntypedFormControl();
  qpvValue: any;

  loading: boolean = false;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  communeSelected: any[] = [];
  communeList: any[] = [];
  qpvList: any[] = [];

  focus: boolean = false;
  textVal: string = '';

  communes: any = null;
  searchForm: FormGroup = new FormGroup({});
  input: UntypedFormControl = new UntypedFormControl('');
  limit: UntypedFormControl = new UntypedFormControl('');
  commune: string = '';
  codePostal: number = 0;

  adresseForm: FormGroup = new FormGroup({});
  adresseControl: UntypedFormControl = new UntypedFormControl(
    '',
    Validators.required
  );
  cityControl: UntypedFormControl = new UntypedFormControl('');
  postcodeControl: UntypedFormControl = new UntypedFormControl('');
  complementControl: UntypedFormControl = new UntypedFormControl('');

  @Input() required: boolean = false;
  @Input() borderless: boolean = false;
  @Input() size: NzSelectSizeType = 'large';
  @Input() isComplement: boolean = true;
  @Input() adresseItemUpdate: any = null;
  @Input() updateItem = new BehaviorSubject<any>(null);
  @Input() updateComplement = new BehaviorSubject<any>(null);
  @Input() qpvActiv: boolean = false;
  @Input() isLabel: boolean = true;
  @Output() adresse = new EventEmitter<any>();
  @Output() complementIn = new EventEmitter<any>();
  @Output() qpvIn = new EventEmitter<any>();
  @Output() clear = new EventEmitter<any>();

  constructor(
    private store: Store<GeoAdresseState>,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.form();
    this.setComplement();
    this.update_Complement();
    this.getLoading();
    this.getAdresse();
    this.search_GEO_ADRESSE();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.update_adresse(changes?.adresseItemUpdate?.currentValue);
  }

  getLoading(): void {
    this.loading$ = this.store.pipe(select(fromGeoSelectors.loading));
  }

  getAdresse(): void {
    this.GEO_ADRESSES$ = this.store.pipe(select(fromGeoSelectors.adresses));
  }

  /* FORM */
  form(): void {
    this.formInit();
    this.formCreate();
  }
  formInit(): void {
    this.input = new UntypedFormControl('');
    this.limit = new UntypedFormControl();
    this.adresseControl = new UntypedFormControl('');
    this.cityControl = new UntypedFormControl('');
    this.postcodeControl = new UntypedFormControl('');
    this.complementControl = new UntypedFormControl('');
  }

  formCreate(): void {
    this.searchForm = this.fb.group({
      input: this.input,
      limit: this.limit,
      adresse: this.adresseControl,
      city: this.cityControl,
      postcode: this.postcodeControl,
      complement: this.complementControl,
    });
  }

  /* SEARCH */
  search_GEO_ADRESSE(): void {
    this.searchControl.valueChanges
      .pipe(takeUntil(this.subscribe))
      .subscribe((input) => {
        if (input && input?.length > 6) {
          this.store.dispatch(
            fromGeoActions.loadGeoAdresses({
              input: input,
            })
          );
        }
      });
  }

  // UPDATE
  update_adresse(updateItem: any): void {
    if (!updateItem?.adresse) return;

    const { adresse } = updateItem;
    const { properties } = adresse;
    const { label } = properties;
    this.currentAdresse$.next(label);
  }

  compareAdresse(obj1: any, obj2: any) {
    return obj1 === obj2 && obj1.properties.label === obj2.properties.label;
  }

  update_Complement(): void {
    this.updateComplement.pipe(takeUntil(this.subscribe)).subscribe((val) => {
      if (val) {
        this.complement.setValue(val);
        this.complementValue = val;
      }
    });
  }

  // SELECT
  select_GEO_ADRESSE(event: any): void {
    if (_.isEmpty(event?.properties?.label)) {
      this.currentAdresse$.next('');
      const adressesSelected = {
        geometry: {
          type: '',
          coordinates: [0, 0],
        },
        properties: {
          label: '',
          id: '',
          type: '',
          name: '',
          postcode: '',
          citycode: '',
          city: '',
          context: '',
          street: '',
          score: 0.0,
          housenumber: '',
          x: 0.0,
          y: 0.0,
          importance: 0.0,
        },
      };
      this.adresse.emit(adressesSelected);
    }
    const { properties } = event;
    const { scrore, housenumber, x, y, importance, label } = properties;

    const adressesSelected = {
      ...event,
      properties: {
        ...properties,
        score: scrore || 0.0,
        housenumber: housenumber || '',
        x: x || 0.0,
        y: y || 0.0,
        importance: importance || 0.0,
      },
    };

    this.currentAdresse$.next(label);
    this.adresse.emit(adressesSelected);
  }

  setComplement(): void {
    this.complement.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.subscribe)
      )
      .subscribe((value: any) => {
        this.complementIn.emit(value);
      });
  }

  onQpv(event: any, item: any) {
    event.preventDefault();
    event.stopPropagation();
    this.qpvIn.emit(item);
  }

  onClear(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.searchControl.setValue('');
    this.adresse.emit(null);
    this.searchForm.reset();
    this.communeSelected = [];
    this.removable = false;
  }

  trackByFn(index: number, item: any) {
    return index;
  }

  onClearAdresse(): void {
    this.currentAdresse$.next('');
    this.clear.emit('');
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }
}
