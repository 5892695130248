import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'app-time-indicators-status',
  template: `
    <i
      nz-icon
      nzType="field-time"
      [ngStyle]="{
        color:
          ([start, end] | distancePercent: distance)?.percent >= 100 || status
            ? green
            : ([start, end] | distancePercent: distance)?.percent >= 100
            ? red
            : ([start, end] | distancePercent: distance)?.percent < 100 &&
              !status &&
              ([start, end] | distancePercent: distance)?.percent > 0 &&
              !status
            ? bleu
            : default
      }"
    ></i>
  `,
  styles: [
    `
      i {
        font-size: 25px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeIndicatorsStatusComponent implements OnInit {
  bleu: string = '#1890ff';
  red: string = '#f5222d';
  green: string = '#52c41a';
  default: string = '#bfbfbf';
  @Input() status: boolean = false;
  @Input() distance: string = 'month';
  @Input() start: any;
  @Input() end: any;
  @Input() type: string = 'begin';
  constructor() {}

  ngOnInit(): void {}
}
