import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-metiers-competences-list',
  templateUrl: './metiers-competences-list.component.html',
  styleUrls: ['./metiers-competences-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetiersCompetencesListComponent implements OnInit, OnChanges {
  @Input() source: any[] = [];
  @Input() item: string = '';

  constructor() {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {}
}
