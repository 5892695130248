import { MetierAppelation } from './../../store/metiers-suggestion.model';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import { of, Observable } from 'rxjs';

@Component({
  selector: 'app-metiers-naf-details',
  templateUrl: './metiers-naf-details.component.html',
  styleUrls: ['./metiers-naf-details.component.scss'],
})
export class MetiersNafDetailsComponent implements OnInit, OnChanges {
  @Input() appelation: MetierAppelation | any = null;
  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}

  onConfirm(): void {}
}
