<app-card-identity>
  <nz-card nzBorderless [nzActions]="[btnModify, btnDowload, btnDelete]">
    <nz-row nzAlign="middle" nzGutter="2" nzJustify="center">
      <nz-col nzSpan="3"></nz-col>
      <nz-col nzSpan="18">
        <img
          *ngIf="source?.custom.logo; else noLogo"
          src="{{ source?.custom.logo }}"
          alt="logo"
        />
        <ng-template #noLogo>
          <nz-row nzAlign="middle" nzGutter="2" nzJustify="center">
            <nz-col nzSpan="24">
              <i nz-icon nzType="file-image" class="noImg"></i>
            </nz-col>
          </nz-row>
          <nz-row
            nzAlign="middle"
            nzGutter="2"
            nzJustify="center"
            class="rowNoLogo"
          >
            <nz-col nzSpan="6"></nz-col>
            <nz-col nzSpan="12">
              <nz-progress
                *ngIf="progress$ | async as progress"
                [nzPercent]="progress"
                [nzSteps]="8"
              ></nz-progress>
            </nz-col>
            <nz-col nzSpan="6"></nz-col>
          </nz-row>
        </ng-template>
      </nz-col>
      <nz-col nzSpan="3"></nz-col>
    </nz-row>
  </nz-card>

  <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>

  <ng-template #btnModify>
    <ng-container *ngIf="user$ | async as user">
      <button
        *ngIf="(user | isDev) || (user | isAdmin)"
        nz-button
        nzShape="circle"
        nz-tooltip
        [nzTooltipMouseEnterDelay]="mouseEnterDelay"
        nzTooltipPlacement="bottom"
        nzTooltipTitle="Modifier votre organisation"
        (click)="onUpdate()"
        nzType="text"
      >
        <mat-icon>mode_edit</mat-icon>
      </button>
    </ng-container>
  </ng-template>

  <ng-template #btnDowload>
    <ng-container *ngIf="user$ | async as user">
      <label
        *ngIf="(user | isDev) || (user | isAdmin)"
        for="fileInput"
        class="label-file"
        nz-tooltip
        [nzTooltipMouseEnterDelay]="mouseEnterDelay"
        nzTooltipPlacement="bottom"
        nzTooltipTitle="Ajouter votre logo"
      >
        <mat-icon>download</mat-icon>
      </label>
      <input
        *ngIf="(user | isDev) || (user | isAdmin)"
        id="fileInput"
        class="input-file"
        type="file"
        (change)="onChoseLogo__ORGANISATION($event, source)"
      />
    </ng-container>
  </ng-template>

  <ng-template #btnDelete>
    <ng-container *ngIf="user$ | async as user">
      <button
        nz-button
        *ngIf="(user | isDev) || (user | isAdmin)"
        nzShape="circle"
        nz-tooltip
        [nzTooltipMouseEnterDelay]="mouseEnterDelay"
        nzTooltipPlacement="bottom"
        nzTooltipTitle="Supprimer votre logo"
        nz-popconfirm
        nzPopconfirmTitle="Supprimer ce logo ?"
        nzPopconfirmPlacement="bottom"
        (nzOnConfirm)="deleteLogo(source)"
        (nzOnCancel)="cancelLogo()"
        nzType="text"
      >
        <mat-icon>delete</mat-icon>
      </button>
    </ng-container>
  </ng-template>
</app-card-identity>
