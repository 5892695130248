import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  OperationFicheCandidatNoteState,
  operationFicheCandidatNotesFeatureKey,
  selectAll,
} from './operation-fiche-candidat-note.reducer';

export const selectOperationFicheCandidatNoteState =
  createFeatureSelector<OperationFicheCandidatNoteState>(
    operationFicheCandidatNotesFeatureKey
  );

export const notes = createSelector(
  selectOperationFicheCandidatNoteState,
  selectAll
);

export const note = createSelector(
  selectOperationFicheCandidatNoteState,
  (state: OperationFicheCandidatNoteState) => state.note
);

export const loading = createSelector(
  selectOperationFicheCandidatNoteState,
  (state: OperationFicheCandidatNoteState) => state.loading
);
export const view = createSelector(
  selectOperationFicheCandidatNoteState,
  (state: OperationFicheCandidatNoteState) => state.view
);

export const success = createSelector(
  selectOperationFicheCandidatNoteState,
  (state: OperationFicheCandidatNoteState) => state.success
);

export const error = createSelector(
  selectOperationFicheCandidatNoteState,
  (state: OperationFicheCandidatNoteState) => state.error
);
