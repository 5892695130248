import { ContactValidatorComponent } from './../../contact-validator/contact-validator.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { EntiteValidatorComponent } from './../../entite-validator/entite-validator.component';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewContainerRef,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import {
  OperationId,
  OperationSmallId,
} from 'src/app/components/operation/store/operation.model';
import { OperationsSelectState } from '../store/operations-select.reducer';
import * as fromOperationSelectSelect from '../store/operations-select.selectors';
import * as fromOperationSelectAction from '../store/operations-select.actions';
import { filter, combineLatestWith, map } from 'rxjs/operators';
import { differenceInCalendarMonths, fromUnixTime } from 'date-fns';

@Component({
  selector: 'app-item-operations-select',
  templateUrl: './item-operations-select.component.html',
  styleUrls: ['./item-operations-select.component.scss'],
})
export class ItemOperationsSelectComponent implements OnInit {
  operations$: Observable<OperationSmallId[] | any> = of();
  selectedOperation: any = null;
  titleInterventionForm: string = "Type d'intervention sur le projet";
  modalWidth: number = 550;

  @Input() identity: string | any = '';
  @Input() operationsAlreayExist$: Observable<OperationSmallId[]> = of([]);
  @Output() cancel = new EventEmitter<any>(false);
  @Output() select = new EventEmitter<any>(false);

  @ViewChild('interventionTitle', { static: false }) interventionTitle:
    | TemplateRef<any>
    | any;

  constructor(
    private operationSelectStore: Store<OperationsSelectState>,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.getOperations();
    this.loadOperations();
  }

  getOperations(): void {
    const loadingOperations$: Observable<OperationSmallId[]> =
      this.operationSelectStore.select(fromOperationSelectSelect.operations);

    const mergeForFilterOperations: Observable<OperationSmallId[]> =
      loadingOperations$.pipe(
        combineLatestWith(this.operationsAlreayExist$),
        map(([o1, o2]) => {
          const operationsAlreayIds = o2.map((item) => item.id);
          const operationLoadingFilter = o1.filter(
            (item) => !operationsAlreayIds.includes(item.id)
          );

          return operationLoadingFilter;
        })
      );

    this.operations$ = mergeForFilterOperations;
  }
  loadOperations(): void {
    this.operationSelectStore.dispatch(
      fromOperationSelectAction.loadOperationsSelects()
    );
  }

  onSetEntiteIntervention(operation: OperationSmallId): void {
    if (!operation) return;
    const { description } = operation;
    const { calendrier } = description;
    const { dateStart, dateEnd } = calendrier;

    const modal = this.modal.create({
      nzContent: EntiteValidatorComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.interventionTitle,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });
    const instance = modal.getContentComponent();
    instance.roleForm.patchValue({
      dateStartIntervention: fromUnixTime(dateStart['seconds']),
      dateEndIntervention: fromUnixTime(dateEnd['seconds']),
      durationIntervention: differenceInCalendarMonths(
        fromUnixTime(dateEnd['seconds']),
        fromUnixTime(dateStart['seconds'])
      ),
    });

    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
        this.cancel.emit(true);
      }
    });
    instance.validate.subscribe((event) => {
      if (!event) {
        return;
      } else {
        this.onValidateIntervention({ intervention: event, operation });
        modal.close();
        this.cancel.emit(true);
      }
    });
  }

  onSetContactIntervention(operation: OperationSmallId): void {
    if (!operation) return;
    const { description } = operation;
    const { calendrier } = description;
    const { dateStart, dateEnd, duree } = calendrier;
    const modal = this.modal.create({
      nzContent: ContactValidatorComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.interventionTitle,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });
    const instance = modal.getContentComponent();
    instance.onUpdateDate(dateStart, dateEnd, duree);
    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
    instance.validate.subscribe((event) => {
      if (!event) {
        return;
      } else {
        this.onValidateIntervention({ event, operation });
        modal.close();
        this.cancel.emit(true);
      }
    });
  }

  onSelect(operation: OperationSmallId, identity: string): void {
    if (!operation || !identity) {
      return;
    } else {
      switch (identity) {
        case 'participant':
          this.select.emit(operation);
          break;
        case 'entite':
          this.onSetEntiteIntervention(operation);
          break;
        case 'contact':
          this.onSetContactIntervention(operation);
          break;
        default:
          break;
      }
    }
  }

  onValidateIntervention(intervention: any): void {
    this.select.emit(intervention);
  }
}
