import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-candidature-item-documents',
  templateUrl: './candidature-item-documents.component.html',
  styleUrls: ['./candidature-item-documents.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CandidatureItemDocumentsComponent implements OnInit {
  documents$: Observable<any>;

  constructor() {}

  ngOnInit(): void {}

  onAdd(document: any): void {}
  onUpdate(document: any): void {}
  onDelete(document: any): void {}
  onDeleteAll(document: any): void {}
  onSearch(document: any): void {}
}
