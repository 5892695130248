import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { UtilitiesState } from 'src/app/contents/components/utilities/store/utilities.reducer';
import * as fromUtilitiesAction from 'src/app/contents/components/utilities/store/utilities.actions';
import * as fromUtilitiesSelector from 'src/app/contents/components/utilities/store/utilities.selectors';
import { v4 as uuidV4 } from 'uuid';

@Component({
  selector: 'app-contact-controler-list',
  templateUrl: './contact-controler-list.component.html',
  styleUrls: ['./contact-controler-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactControlerListComponent implements OnInit, OnChanges {
  subscribe = new Subject();
  civilite$: Observable<any> = this.utilitiesStore.select(
    fromUtilitiesSelector.civilities
  );

  @Input() contactsItems = new FormArray([]);

  @Input() noDataTitle: string = 'Contacts entreprise';
  @Input() contactsInput: any[];

  @Output() onCurrentChanges = new EventEmitter<any>(false);
  @Output() add = new EventEmitter<any>(false);

  contactsCtl: FormArray = this.fb.array<
    {
      id: string;
      civilite: string;
      lastName: string;
      firstName: string;
      fonction: string;
      role: string;
      coordonnees: {
        mobile: string;
        phoneNumber: string;
        email: string;
        fixe: string;
      };
    }[]
  >([]);

  contactForm = this.fb.group({
    contacts: this.contactsCtl,
  });

  constructor(
    private fb: FormBuilder,
    private utilitiesStore: Store<UtilitiesState>
  ) {}

  ngOnInit(): void {
    this.onContactsChanges();
  }

  ngOnDestroy(): void {
    this.subscribe.next(false);
    this.subscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setUpdateArray(changes?.contactsItems?.currentValue);
  }

  get contactsArrayControler() {
    return this.contactForm.get('contacts') as FormArray;
  }

  loadUtilitiesCivilite(): void {
    this.utilitiesStore.dispatch(fromUtilitiesAction.loadCivilities());
  }

  setUpdateArray(contacts: FormArray): void {
    if (!contacts) return;
    this.contactsCtl = contacts;
  }

  onAdd(): void {
    const contact = this.fb.group({
      id: [uuidV4(), Validators.required],
      civilite: ['', Validators.required],
      lastName: ['', Validators.required],
      firstName: ['', Validators.required],
      fonction: ['', Validators.required],
      role: ['', Validators.required],
      dateNaissance: ['', Validators.required],
      genre: ['', Validators.required],
      age: [0, Validators.required],
      tranche: ['', Validators.required],

      coordonnees: this.fb.group({
        mobile: ['', Validators.required],
        phoneNumber: ['', Validators.required],
        fixe: ['', Validators.required],
        email: ['', Validators.required],
      }),
    });
    this.contactsCtl.push(contact);
  }
  onRemoveContact(index: number): void {
    this.contactsCtl.removeAt(index);
  }
  onRemoveAllContacts(): void {
    this.contactsCtl.clear();
  }

  onChangesCoordonnees(coordonnees: any, index: number): void {
    this.contactsCtl.at(index).patchValue({
      coordonnees: {
        fixe: coordonnees?.fixe ? coordonnees.fixe : '',
        mobile: coordonnees?.mobile ? coordonnees.mobile : '',
        phoneNumber: coordonnees?.phoneNumber ? coordonnees.phoneNumber : '',
        email: coordonnees?.email ? coordonnees.email : '',
      },
    });
  }

  onChangesCoordonneesEmail(email: any, index: number): void {
    this.contactsCtl.at(index).patchValue({
      coordonnees: {
        email: email ? email : '',
      },
    });
  }
  onChangesCoordonneesFixe(fixe: any, index: number): void {
    this.contactsCtl.at(index).patchValue({
      coordonnees: {
        fixe: fixe ? fixe : '',
      },
    });
  }

  onRemove(index: number): void {
    this.contactsCtl.removeAt(index);
  }

  onRemoveAll(): void {
    this.contactsCtl.clear();
  }

  onContactsChanges(): void {
    this.contactForm
      .get('contacts')
      ?.valueChanges.pipe(takeUntil(this.subscribe))
      .subscribe((contacts) => this.onCurrentChanges.emit(contacts));
  }
}
