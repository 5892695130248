import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { serverTimestamp, Timestamp } from 'firebase/firestore';
import { Observable, of, Subject, takeUntil } from 'rxjs';
import { OperationNoteId } from 'src/app/components/operation-note/store/operation-note.model';
import {
  OperationNoteSubtitle,
  OperationNoteSubtitleId,
} from '../../store/operation-note-subtitle.model';
import { Update } from '@ngrx/entity';
import { fromUnixTime } from 'date-fns';

@Component({
  selector: 'app-operation-note-subtitle-form',
  templateUrl: './operation-note-subtitle-form.component.html',
  styleUrls: ['./operation-note-subtitle-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationNoteSubtitleFormComponent
  implements OnInit, OnChanges, OnDestroy
{
  subscribe = new Subject();
  isAdd: boolean = false;
  isUpdate: boolean = false;
  today = new Date(Date.now());
  dateFormat: string = 'dd/MM/yyyy HH:mm';

  @Input() note$: Observable<OperationNoteId | any> = of(null);
  @Input() subtitle$: Observable<OperationNoteSubtitleId | any> = of(null);

  @Output() add = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @Output() update = new EventEmitter();

  subtitleForm: UntypedFormGroup = this.fb.group({});
  dateStart: UntypedFormControl = new UntypedFormControl();
  dateUpdate: UntypedFormControl = new UntypedFormControl();
  auteur: UntypedFormControl = new UntypedFormControl();
  title: UntypedFormControl = new UntypedFormControl('', Validators.required);
  notes: UntypedFormControl = new UntypedFormControl(0);
  noteId: UntypedFormControl = new UntypedFormControl();

  operationId: UntypedFormControl = new UntypedFormControl();

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.onForm();
  }
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  onForm(): void {
    this.onFormInit();
    this.onFormCreate();
    this.onFormSet();
  }
  onFormInit(): void {
    this.dateStart = new UntypedFormControl(Timestamp.now(), Validators.required);
    this.dateUpdate = new UntypedFormControl(Timestamp.now(), Validators.required);
    this.auteur = new UntypedFormControl();
    this.title = new UntypedFormControl('', Validators.required);
    this.notes = new UntypedFormControl(0);
    this.noteId = new UntypedFormControl();
    this.operationId = new UntypedFormControl();
  }
  onFormCreate(): void {
    this.subtitleForm = this.fb.group({
      dateStart: this.dateStart,
      dateUpdate: this.dateUpdate,
      auteur: this.auteur,
      title: this.title,
      notes: this.notes,
      note: {
        id: this.noteId,
      },
      operation: {
        id: this.operationId,
      },
    });
  }
  onFormSet(): void {
    this.subtitle$
      .pipe(takeUntil(this.subscribe))
      .subscribe((subtitle: OperationNoteSubtitleId) => {
        if (!subtitle) {
          this.isAdd = true;
          this.isUpdate = false;
        } else {
          this.isAdd = false;
          this.isUpdate = true;

          this.subtitleForm.patchValue({
            ...subtitle,
            dateStart: fromUnixTime(subtitle.dateStart['seconds']),
            dateUpdate: fromUnixTime(subtitle.dateUpdate['seconds']),
          });
        }
      });
  }

  onAdd(note: OperationNoteId): void {
    if (!note || !this.subtitleForm.valid) return;
    const subtitle: OperationNoteSubtitle = this.subtitleForm.value;
    subtitle.note.id = note.id;
    subtitle.operation.id = note.operation.id;
    this.add.next(subtitle);
  }
  onUpdate(item: OperationNoteSubtitleId): void {
    if (!this.subtitleForm.valid) return;
    const subtitle: OperationNoteSubtitle = this.subtitleForm.value;
    subtitle.dateStart = Timestamp.fromDate(subtitle.dateStart);
    subtitle.dateUpdate = Timestamp.now();

    const subtitleNext: Update<OperationNoteSubtitleId> = {
      id: item.id,
      changes: { ...item, ...subtitle },
    };
    this.update.next(subtitleNext);
  }
  onCancel(): void {
    this.cancel.next(true);
  }
}
