import { ContactId } from './../components/contact/store/contact.model';
import { Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  doc,
  docSnapshots,
  getDoc,
  collectionChanges,
  addDoc,
  setDoc,
  deleteDoc,
  serverTimestamp,
  documentId,
  DocumentData,
  DocumentSnapshot,
  DocumentReference,
  CollectionReference,
  Timestamp,
  query,
  where,
  arrayUnion,
  arrayRemove,
  collectionSnapshots,
  getDocs,
  limit,
  FieldValue,
  FieldPath,
  deleteField,
} from '@angular/fire/firestore';
import { Observable, of, from, EMPTY } from 'rxjs';
import { switchMap, mergeMap, map, catchError } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { UserState } from '../components/user/store/user.reducer';
import * as fromUserSelector from '../components/user/store/user.selectors';
import { EntiteSMALLid } from '../components/entite/store/entite.model';

@Injectable({
  providedIn: 'any',
})
export class ContactEntitesService {
  constructor(private db: Firestore, private userStore: Store<UserState>) {}

  getAll(contact: ContactId): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !contact) {
            return of([]);
          } else {
            const dbCollection: CollectionReference = collection(
              this.db,
              `organisations/${user.organisation.id}/entites`
            );
            const filter = contact?.entitesIds?.length
              ? contact.entitesIds
              : [];

            if (!filter?.length) {
              return of([]);
            }

            const queryContacts = query(
              dbCollection,
              where(documentId(), 'in', filter)
            );

            const changes = collectionSnapshots(queryContacts).pipe(
              map((changes) => {
                return changes.map((snap) => {
                  const data = snap.data();
                  const id = snap.id;
                  return { id, ...data };
                });
              })
            );

            return changes;
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  addOne(contact: ContactId, entite: EntiteSMALLid): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !entite || !contact) {
            return of(null);
          } else {
            const documentRef = doc(
              this.db,
              `organisations/${user.organisation.id}/contacts/${contact.id}/details/${contact.id}`
            );

            return from(
              setDoc(
                documentRef,
                {
                  entitesIds: arrayUnion(entite.id),
                  entitesTitles: arrayUnion(entite.etablissement.denomination),
                  entitesMapIds: {
                    [`${entite.id}`]: {
                      denomination: entite.etablissement.denomination,
                      siret: entite.etablissement.etablissement_siege.siret,
                      siren: entite.etablissement.siren,
                    },
                  },
                },
                { merge: true }
              )
                .then(() => of(`Entité ajouté`))
                .catch((error) => of(error))
            ).pipe(mergeMap((res) => res));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  deleteOne(contact: ContactId, entite: EntiteSMALLid): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !contact || !entite) {
            return of(null);
          } else {
            const documentRef = doc(
              this.db,
              `organisations/${user.organisation.id}/contacts/${contact.id}/details/${contact.id}`
            );

            return from(
              setDoc(
                documentRef,
                {
                  entitesIds: arrayRemove(entite.id),
                  entitesTitles: arrayRemove(entite.etablissement.denomination),
                  entitesMapIds: {
                    [`${entite.id}`]: deleteField(),
                  },
                },
                { merge: true }
              )
                .then(() => of(`Entité retirée`))
                .catch((error) => of(error))
            ).pipe(mergeMap((res) => res));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }
}
