import { Injectable } from '@angular/core';
import { gql, Query } from 'apollo-angular';

@Injectable({
  providedIn: 'any',
})
export class QueryEvolutionTypesContratGQLService extends Query<Response> {
  document = gql`
    query Referentiel_evolution_mission {
      referentiel_evolution_mission {
        code
        libelle
      }
    }
  `;
}
