import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'participantActivity',
})
export class ParticipantActivityPipe implements PipeTransform {
  transform(value: any[], id: string, type: string): any {
    if (!value || !value?.length) {
      return null;
    }

    switch (type) {
      case 'presence':
        var exist = value.find((el) => el.id === id);
        var status = exist?.presence ? exist.presence : null;
        return status ? true : false;

        break;
      case 'excuse':
        var exist = value.find((el) => el.id === id);
        var status = exist?.excuse ? exist.excuse : null;

        return status ? true : false;

        break;
      case 'absence':
        var exist = value.find((el) => el.id === id);
        var status = exist?.absence ? exist.absence : null;

        return status ? true : false;

        break;

      default:
        return false;
        break;
    }
  }
}
