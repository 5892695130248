import { Component, Input, OnInit } from '@angular/core';
import { ParticipantId } from '../../store/participant.model';

@Component({
  selector: 'app-participant-ressources',
  templateUrl: './participant-ressources.component.html',
  styleUrls: ['./participant-ressources.component.scss'],
})
export class ParticipantRessourcesComponent implements OnInit {
  title: string = 'Ressources';
  @Input() participant: ParticipantId | any = null;
  constructor() {}

  ngOnInit(): void {}
}
