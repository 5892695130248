import { EntiteId } from './../../store/entite.model';
import { EntiteState } from './../../store/entite.reducer';
import * as fromEntiteAction from './../../store/entite.actions';
import * as fromEntiteSelector from './../../store/entite.selectors';

import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { of, Observable, Subject } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-entite-documents',
  templateUrl: './entite-documents.component.html',
  styleUrls: ['./entite-documents.component.scss'],
})
export class EntiteDocumentsComponent implements OnInit {
  documents$: Observable<any> = of(null);
  entite$: Observable<EntiteId | any> = of();
  subscribe = new Subject();

  constructor(private entiteStore: Store<EntiteState>) {}

  ngOnInit(): void {
    this.getEntite();
    this.getDocuments();
    this.getDocuments();
    this.loadDocuments();
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getEntite(): void {
    this.entite$ = this.entiteStore.select(fromEntiteSelector.entite);
  }
  getDocuments(): void {
    this.documents$ = this.entiteStore.select(fromEntiteSelector.documents);
  }

  loadDocuments(): void {
    this.entite$
      .pipe(takeUntil(this.subscribe))
      .subscribe((entite: EntiteId) => {
        if (!entite) {
          return;
        } else {
          this.entiteStore.dispatch(
            fromEntiteAction.loadDocuments({
              entiteId: entite.id,
            })
          );
        }
      });
  }

  onAdd(event: any, entite: EntiteId): void {
    if (!event || !entite) {
      return;
    } else {
      const { id } = entite;
      this.entiteStore.dispatch(
        fromEntiteAction.addDocuments({ entite, documents: event })
      );
    }
  }
  onDelete(document: any, entite: EntiteId): void {
    if (!document || !entite) {
      return;
    } else {
      const entiteId: string = entite.id;

      this.entiteStore.dispatch(
        fromEntiteAction.deleteDocument({
          entiteId,
          document,
        })
      );
    }
  }
  onDeleteAll(): void {}
}
