import { ParticipantSmallId } from 'src/app/components/participant/store/participant.model';
import { OperationId } from './../components/operation/store/operation.model';
import { Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  doc,
  docSnapshots,
  collectionChanges,
  addDoc,
  setDoc,
  deleteDoc,
  serverTimestamp,
  CollectionReference,
  DocumentReference,
  DocumentChange,
  DocumentSnapshot,
  Timestamp,
  query,
  where,
  arrayUnion,
  arrayRemove,
  collectionSnapshots,
  getDocs,
  DocumentData,
} from '@angular/fire/firestore';
import { Observable, of, from, EMPTY } from 'rxjs';
import { switchMap, mergeMap, map, catchError } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Functions, httpsCallable } from '@angular/fire/functions';
import {
  Storage,
  ref,
  uploadBytesResumable,
  deleteObject,
  getDownloadURL,
  StorageReference,
  UploadTask,
  UploadMetadata,
} from '@angular/fire/storage';
import { UserState } from '../components/user/store/user.reducer';
import * as fromUserSelector from '../components/user/store/user.selectors';
import { v4 as uuidV4 } from 'uuid';
import { add, format, fromUnixTime } from 'date-fns';
import { fr } from 'date-fns/locale';

@Injectable({
  providedIn: 'any',
})
export class OperationParticipantsService {
  today = new Date(Date.now());
  constructor(
    private db: Firestore,
    private storage: Storage,
    private fns: Functions,
    private userStore: Store<UserState>
  ) {}

  getAll(operationId: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !operationId) of([]);

          const dbCollection: CollectionReference<DocumentData> = collection(
            this.db,
            `organisations/${user.organisation.id}/operations/${operationId}/participants`
          );

          const changes = collectionSnapshots(dbCollection).pipe(
            map((changes) => {
              return changes.map((snap) => {
                const data = snap.data();
                const id = snap.id;
                return { id, ...data };
              });
            })
          );

          return changes;
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  getOne(operationId: string, id: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !operationId || !id) {
            return of(null);
          } else {
            const dbCollection: CollectionReference<DocumentData> = collection(
              this.db,
              `organisations/${user.organisation.id}/operations/${operationId}/participants`
            );

            const documentRef: DocumentReference<DocumentData> = doc(
              dbCollection,
              id
            );

            return docSnapshots(documentRef).pipe(
              map((snap: DocumentSnapshot<DocumentData>) => {
                const data = snap.data();
                const id = snap.id;
                return { id, ...data };
              }),
              catchError((error) => EMPTY)
            );
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  addOne(
    operation: OperationId,
    participant: ParticipantSmallId
  ): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !operation || !participant) {
            return of(null);
          } else {
            const dbDocument: DocumentReference<DocumentData> = doc(
              this.db,
              `organisations/${user.organisation.id}/operations/${operation.id}/participants/${participant.id}`
            );

            const intervention = {
              role: 'participant',
              isInscription: true,
              isParticipant: false,
              isSorite: false,
              isDesinscription: false,
              isInMission: false,
              isParrain: false,
              isFinanceur: false,
              isPrescripteur: false,
              isSupport: false,
              isTitulaire: false,
              isSousTraitant: false,
              missions: [],
              modules: [],
              actions: [],
              projetpro: [],
              dateInscription: serverTimestamp(),
              dateSortie: add(this.today, {
                months: 6,
              }),
              duration: 6,
              motifSorties: null,
              typeSorties: null,
              yearString: new Date(Date.now()).getFullYear().toString(),
              monthString: new Date(Date.now()).getMonth().toString(),
              yearNumber: new Date(Date.now()).getFullYear(),
              monthNumber: new Date(Date.now()).getMonth(),
              userUpdate: user,
            };

            const newParticipant = {
              operation: { ...operation },
              participant: { ...participant },
              intervention: { ...intervention },
            };

            return from(
              setDoc(dbDocument, newParticipant)
                .then(() => this.getOne(operation.id, participant.id))
                .catch((err) => of(err))
            ).pipe(mergeMap((res) => res));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  updateOne(operationId: string, participant: any): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !operationId || !participant) {
            return of(null);
          } else {
            const dbDocument: DocumentReference<DocumentData> = doc(
              this.db,
              `organisations/${user.organisation.id}/operations/${operationId}/participants/${participant.id}`
            );

            return from(
              setDoc(dbDocument, participant)
                .then(() => `Participant modifié.`)
                .catch((err) => err)
            ).pipe(mergeMap((res) => of(res)));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  deleteOne(operationId: string, id: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !operationId || !id) {
            return of(null);
          } else {
            const dbDocument: DocumentReference<DocumentData> = doc(
              this.db,
              `organisations/${user.organisation.id}/operations/${operationId}/participants/${id}`
            );

            return from(
              deleteDoc(dbDocument)
                .then(() => `Participant retité du projet.`)
                .catch((err) => err)
            ).pipe(mergeMap((res) => of(res)));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  deleteAll(operationId: string, ids: string[]): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) {
            return of(null);
          } else {
            return EMPTY;
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }
}
