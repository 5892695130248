<ng-container *ngIf="operation$ | async as operation" class="bg-red-300">
  <app-layout-grid [details]="details"></app-layout-grid>

  <ng-template #details>
    <nz-card nzBorderless>
      <router-outlet></router-outlet>
    </nz-card>
  </ng-template>

  <ng-template #projetTitleForm>
    <app-header-title [icon]="'analytics'" [title]="title"></app-header-title>
  </ng-template>

  <ng-template #exportTitleForm>
    <app-header-title
      [icon]="'download'"
      [title]="titleDownload"
    ></app-header-title>
  </ng-template>
</ng-container>
