<nz-card
  [nzTitle]="cardTitle"
  nzSize="small"
  [nzExtra]="cardExtra"
  *ngIf="source$ | async | size; else noData"
>
</nz-card>

<ng-template #cardTitle>
  <app-header-title [icon]="'table_chart'"></app-header-title>
</ng-template>
<ng-template #cardExtra>
  <button
    nz-button
    nz-tooltip
    nzBlock
    nzTooltipPlacement="bottom"
    nzTooltipTitle="Ajouter une fiche de poste"
    [nzTooltipMouseEnterDelay]="0.5"
    class="btn_add"
  >
    <mat-icon>add</mat-icon>
    <span>Ajouter</span>
  </button>
</ng-template>
<ng-template #noData>
  <app-no-result
    [icon]="'checklist'"
    [addtitle]="'Ajouter une fiche de poste'"
    [title]="'Ajouter une fiche de poste'"
    [notitle]="'Aucune fiche de poste ajoutée'"
    [description]="''"
  ></app-no-result>
</ng-template>
