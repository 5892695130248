import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  BehaviorSubject,
  debounceTime,
  fromEvent,
  Observable,
  of,
  Subject,
  takeUntil,
} from 'rxjs';
import { Store } from '@ngrx/store';
import { ParticipantPlanningState } from './../../store/participant-planning.reducer';
import * as fromParticipantPlanningAction from './../../store/participant-planning.actions';
import * as fromParticipantPlanningSelector from './../../store/participant-planning.selectors';
import { ParticipantId } from 'src/app/components/participant/store/participant.model';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ParticipantPlanningFormComponent } from '../participant-planning-form/participant-planning-form.component';
import { ParticipantState } from 'src/app/components/participant/store/participant.reducer';
import * as fromParticipantSelector from 'src/app/components/participant/store/participant.selectors';
import { UtilitiesState } from 'src/app/contents/components/utilities/store/utilities.reducer';
import * as fromUtilitiesAction from 'src/app/contents/components/utilities/store/utilities.actions';
import { ParticipantPlanningExportValidationComponent } from '../participant-planning-export-validation/participant-planning-export-validation.component';
import { format } from 'date-fns';

@Component({
  selector: 'app-participant-planning',
  templateUrl: './participant-planning.component.html',
  styleUrls: ['./participant-planning.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantPlanningComponent
  implements OnInit, OnChanges, AfterViewInit
{
  subscribe = new Subject();
  selectedValue = new Date(Date.now());

  modalWidth: number = 870;
  title: string = 'Nouvel événement';
  view$ = new BehaviorSubject<string>('calendar');
  currentFilter$ = new BehaviorSubject<string>('');
  noEventDescription: string = 'Aucun événement ajouté';
  joursFeries$: any = null;
  events$: Observable<any> = of([]);
  today: Date = new Date(Date.now());
  currentMonth: string = format(this.today, 'MM/yyyy');

  currentDate$ = new BehaviorSubject<Date>(this.today);

  @ViewChild('eventtTitleForm', { static: false })
  eventtTitleForm: TemplateRef<any> | any;

  @Input() participant$: Observable<ParticipantId | any> = of(null);

  constructor(
    private participantPlanningStore: Store<ParticipantPlanningState>,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private participantStore: Store<ParticipantState>,
    private utilitiesStore: Store<UtilitiesState>
  ) {
    this.loadPlanningTypes();
  }

  ngOnInit(): void {
    this.getParticipant();
    this.setCurrentDate();
    this.loadEvents();
  }

  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  ngAfterViewInit(): void {}

  getParticipant(): void {
    this.participant$ = this.participantStore.select(
      fromParticipantSelector.participant
    );
  }

  getJoursFeries(): void {
    this.joursFeries$ = this.participantPlanningStore.select(
      fromParticipantPlanningSelector.joursFeries
    );
  }
  loadJoursFeries(): void {
    this.participantPlanningStore.dispatch(
      fromParticipantPlanningAction.loadJoursFerier()
    );
  }

  loadPlanningTypes(): void {
    this.utilitiesStore.dispatch(fromUtilitiesAction.loadPlanningfTypes());
  }

  loadEvents(): void {
    this.participant$
      .pipe(takeUntil(this.subscribe))
      .subscribe((participant) => {
        if (!participant) return;
        const { id } = participant;
        this.participantPlanningStore.dispatch(
          fromParticipantPlanningAction.loadParticipantPlanningsFiltered({
            id,
            filter: { type: '', createAtMonthYear: this.currentMonth },
          })
        );
      });
  }

  setCurrentDate(): void {
    const today = new Date(Date.now());
    this.selectedValue = today;
  }

  trackByFn(index: number, item: any): number {
    return item.id;
  }

  getMonthData(date: Date): number | null {
    if (!date) return null;
    const d = (
      date.getDate() +
      '-' +
      date.getMonth() +
      '-' +
      date.getFullYear()
    ).toString();
    return date.getMonth() + 1;
  }

  changeView(page: string): void {
    this.view$.next(page);
  }
  selectChange(select: Date): void {}

  panelChange(event: any): void {}

  onNew(participant: ParticipantId): void {
    if (!participant) return;

    const modal = this.modal.create({
      nzContent: ParticipantPlanningFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.eventtTitleForm,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });
    const instance = modal.getContentComponent();
    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
    instance.add.subscribe((event) => {
      if (!event) return;
      modal.close();

      this.onAdd(participant.id, event);
    });
  }

  onExport(participant: ParticipantId): void {
    if (!participant) return;

    const modal = this.modal.create({
      nzContent: ParticipantPlanningExportValidationComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.eventtTitleForm,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });
    const instance = modal.getContentComponent();
    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
    instance.validation.subscribe((event) => {
      if (!event) return;
      modal.close();
    });
  }

  onDropdownSelect(event: string, participant: ParticipantId): void {
    if (!event || !participant) return;

    switch (event) {
      case 'deleteAll':
        this.onDeleteAll(participant);
        break;
      case 'exportPDF':
        this.onExport(participant);
        break;

      default:
        break;
    }
  }

  onAdd(id: string, event: string): void {
    if (!id || !event) return;
    this.participantPlanningStore.dispatch(
      fromParticipantPlanningAction.addParticipantPlanning({ id, event })
    );
  }

  onPlanningExport(id: string, event: string): void {
    if (!id || !event) return;
  }

  onDelete(id: string, participant: ParticipantId): void {
    if (!id || !participant) return;
    this.participantPlanningStore.dispatch(
      fromParticipantPlanningAction.deleteParticipantPlanning({
        participantId: participant.id,
        id: id,
      })
    );
  }

  onDeleteAll(participant: ParticipantId): void {
    if (!participant) return;
    const { id } = participant;
    this.participantPlanningStore.dispatch(
      fromParticipantPlanningAction.deleteParticipantPlannings({ id, ids: [] })
    );
  }
}
