import { MessageFormComponent } from './../message-form/message-form.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewContainerRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { MessageId } from '../../store/message.model';
import { messageState } from '../../store/message.reducer';
import * as fromMessageSelectors from '../../store/message.selectors';
import * as fromMessageActions from '../../store/message.actions';
import { NzMessageService } from 'ng-zorro-antd/message';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';

@Component({
  selector: 'app-message-header',
  templateUrl: './message-header.component.html',
  styleUrls: ['./message-header.component.scss'],
})
export class MessageHeaderComponent implements OnInit {
  user$: Observable<any> = of(null);
  messageTitle: string = 'MESSAGES';
  messageFilter: boolean = false;
  messageBadgeStyle = {
    backgroundColor: '#fff',
    color: '#999',
    boxShadow: '0 0 0 1px #d9d9d9 inset',
  };
  newMessage: boolean = false;
  newMessage$ = new BehaviorSubject<boolean>(false);

  messages$: Observable<MessageId[] | any> = of([]);
  message$: Observable<MessageId | any> = of(null);
  modalWidth: number = 500;
  error$: Observable<any> = of(null);

  @Input() closeNewMessage: Observable<boolean> = of(false);
  @Input() fullscreen: boolean = false;
  @Output() openNewMessage = new EventEmitter<boolean>(false);

  constructor(
    private userStore: Store<UserState>,
    private storeMessage: Store<messageState>,
    private messageAlerte: NzMessageService,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.get__USER();
    this.get__MESSAGES();
    this.get__MESSAGE();
  }

  onMessage(): void {
    const modal = this.modal.create({
      nzTitle: 'Nouveau message',
      nzContent: MessageFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
    });

    const instance = modal.getContentComponent();
    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
  }

  get__USER(): void {
    this.user$ = this.userStore.select(fromUserSelector.user);
  }

  get__MESSAGES(): void {
    this.messages$ = this.storeMessage.select(
      fromMessageSelectors.selectMessages
    );
  }

  loadAll__MESSAGES(): void {
    this.storeMessage.dispatch(fromMessageActions.loadMessages());
  }

  loadMy__MESSAGES(): void {
    this.storeMessage.dispatch(fromMessageActions.loadMyMessages());
  }

  loadReceive__MESSAGES(): void {
    this.storeMessage.dispatch(fromMessageActions.loadReceiveMessages());
  }

  get__MESSAGE(): void {
    this.message$ = this.storeMessage.select(
      fromMessageSelectors.selectMessage
    );
  }

  deleteAll__MESSAGES(messages: MessageId[]): any {
    if (!messages.length) {
      return null;
    }
    const ids: string[] = messages.map((message: any) => message.id);
    this.storeMessage.dispatch(fromMessageActions.deleteMessages({ ids }));
  }

  deleteAllMy__MESSAGES(user: any, messages: MessageId[]): any {
    if (!messages.length || !user) {
      return null;
    }
    const myMessages = messages.filter(
      (message) => message.auteur.id === user.id
    );

    if (!myMessages.length) {
      this.messageAlerte.info("Vous n'avez aucun message envoyé");
      return null;
    }

    const ids = myMessages.map((message: any) => message.id);
    this.storeMessage.dispatch(fromMessageActions.deleteMyMessages({ ids }));
  }

  deleteAllReceive__MESSAGES(user: any, messages: MessageId[]): any {
    if (!messages.length || !user) {
      return null;
    }
    const othersMessages = messages.filter(
      (message) => message.auteur.id !== user.id
    );

    if (!othersMessages.length) {
      this.messageAlerte.info("Vous n'avez aucun message reçu.");
      return null;
    }

    const ids = othersMessages.map((message: any) => message.id);
    this.storeMessage.dispatch(
      fromMessageActions.deleteReceiveMessages({ ids })
    );
  }

  getError__MESSAGE(): void {
    this.error$ = this.storeMessage.select(fromMessageSelectors.selectError);
  }

  onCancel(): any {
    return null;
  }

  new_MESSAGE(event: boolean): void {
    this.newMessage$.next(event);
    this.openNewMessage.emit(event);
  }
}
