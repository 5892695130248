<nz-card
  nzBorderless
  [nzTitle]="planningTitle"
  [nzExtra]="planningExtra"
  *ngIf="participant$ | async as participant"
>
  <div [ngSwitch]="view$ | async">
    <div *ngSwitchCase="'calendar'">
      <app-participant-planning-calendar></app-participant-planning-calendar>
    </div>
    <div *ngSwitchCase="'list'">
      <app-participant-planning-list></app-participant-planning-list>
    </div>

    <div *ngSwitchDefault></div>
  </div>

  <ng-template #planningTitle>
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="4">
        <app-header-title [icon]="'calendar_month'"></app-header-title>
      </nz-col>
      <nz-col nzSpan="4">
        <nz-tag nzColor="processing" *ngIf="currentFilter$ | async">{{
          currentFilter$ | async
        }}</nz-tag>
      </nz-col>
    </nz-row>
  </ng-template>

  <ng-template #planningExtra>
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col [nzSpan]="(view$ | async) === 'list' ? 10 : 14">
        <button nz-button (click)="onNew(participant)" nzBlock>Nouveau</button>
      </nz-col>
      <nz-col nzSpan="2"></nz-col>
      <nz-col nzSpan="4">
        <button
          nz-button
          (click)="changeView('calendar')"
          nzShape="circle"
          nzType="text"
          *ngIf="(view$ | async) === 'list'"
          nz-tooltip
          [nzTooltipMouseEnterDelay]="0.5"
          nzTooltipPlacement="bottom"
          nzTooltipTitle="Vue calendrier"
        >
          <mat-icon>event</mat-icon>
        </button>
        <button
          nz-button
          (click)="changeView('list')"
          nzShape="circle"
          nzType="text"
          *ngIf="(view$ | async) === 'calendar'"
          nz-tooltip
          [nzTooltipMouseEnterDelay]="0.5"
          nzTooltipPlacement="bottom"
          nzTooltipTitle="Vue liste"
        >
          <mat-icon>table_view</mat-icon>
        </button>
      </nz-col>
      <nz-col nzSpan="2"></nz-col>
      <nz-col [nzSpan]="(view$ | async) === 'list' ? 2 : 0">
        <button
          nz-button
          nzShape="circle"
          nzType="text"
          nz-popover
          nzPopoverTrigger="click"
          nzPopoverPlacement="left"
          [nzPopoverContent]="filterContent"
        >
          <mat-icon>filter_list</mat-icon>
        </button>
      </nz-col>
      <nz-col [nzSpan]="(view$ | async) === 'list' ? 2 : 0"></nz-col>
      <nz-col nzSpan="2">
        <app-button-dropdown
          [isDeleteAll]="true"
          [isExportPDF]="true"
          (select)="onDropdownSelect($event, participant)"
        ></app-button-dropdown>
      </nz-col>
    </nz-row>
  </ng-template>

  <ng-template #filterContent>
    <app-participant-planning-filter></app-participant-planning-filter>
  </ng-template>

  <ng-template #noData>
    <app-no-result
      [description]="noEventDescription"
      [icon]="'event'"
      [isButton]="false"
    ></app-no-result>
  </ng-template>

  <ng-template #eventtTitleForm>
    <app-header-title
      [icon]="'event'"
      [title]="title"
      [subtitle]="true"
    ></app-header-title>
  </ng-template>
</nz-card>
