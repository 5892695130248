<form nz-form [formGroup]="administratifForm" nzLayout="vertical">
  <ng-container *ngIf="isDatesValidation">
    <nz-card nzSize="small" nzTitle="Inscription">
      <div class="w-full flex items-start gap-2">
        <div class="w-1/2 flex flex-col">
          <label class="label required" nzFor="recrutement">Recrutement </label>
          <div class="numberForm">
            <nz-date-picker
              class="numberField"
              nzBorderless
              nzPlaceHolder="Date de recrutement"
              formControlName="dateRecrutement"
              id="recrutement"
              [nzFormat]="dateFormat"
            >
            </nz-date-picker>
          </div>
          <small
            *ngIf="
              administratifForm.value.dateRecrutement?.invalid &&
              administratifForm.value.dateRecrutement?.dirty &&
              administratifForm.value.dateRecrutement?.touched
            "
            class="inpurFormAlert"
            >{{
              administratifForm.value.dateRecrutement?.invalid &&
              administratifForm.value.dateRecrutement?.dirty &&
              administratifForm.value.dateRecrutement?.touched
                ? "Aucun date de recrutement renseignée"
                : ""
            }}</small
          >
        </div>
        <div class="w-1/2 flex flex-col">
          <label class="label required" nzFor="inscription">Inscription </label>
          <div
            class="numberForm"
            nzErrorTip="Aucun date d'inscription renseignée"
          >
            <nz-date-picker
              class="numberField"
              nzBorderless
              nzPlaceHolder="Date d'inscription"
              formControlName="dateInscription"
              id="inscription"
              [nzFormat]="dateFormat"
            >
            </nz-date-picker>
          </div>
          <small
            *ngIf="
              administratifForm.value.dateInscription?.invalid &&
              administratifForm.value.dateInscription?.dirty &&
              administratifForm.value.dateInscription?.touched
            "
            class="inpurFormAlert"
            >{{
              administratifForm.value.dateInscription?.invalid &&
              administratifForm.value.dateInscription?.dirty &&
              administratifForm.value.dateInscription?.touched
                ? "Aucun date de recrutement renseignée"
                : ""
            }}</small
          >
        </div>
      </div>
    </nz-card>
  </ng-container>

  <div class="w-full space-y-2">
    <nz-card nzSize="small" nzTitle="Éligibilité">
      <div class="w-full flex items-start gap-2">
        <div class="w-1/2 flex flex-col">
          <label nzFor="minima" class="label required">Critère niveau 1 </label>
          <div class="select-menu">
            <nz-select
              formControlName="criteres1"
              placeholder="Critère..."
              nzBorderless
              nzPlaceHolder="Critère..."
              nzServerSearch
              [compareWith]="compareCritere1"
              [nzLoading]="(allocation_type$ | async | size) ? false : true"
            >
              <nz-option
                *ngFor="let item of allocation_type$ | async"
                [nzLabel]="item.value | splitJoin : '_' : ','"
                [nzValue]="item"
              ></nz-option>
            </nz-select>
            <small
              *ngIf="
                administratifForm.value.criteres1?.invalid &&
                administratifForm.value.criteres1?.dirty &&
                administratifForm.value.criteres1?.touched
              "
              class="inpurFormAlert"
              >{{
                administratifForm.value.criteres1?.invalid &&
                administratifForm.value.criteres1?.dirty &&
                administratifForm.value.criteres1?.touched
                  ? "Renseignez le critère d'éligibilité de niveau 1"
                  : ""
              }}</small
            >
          </div>
        </div>
        <div class="w-1/2 flex flex-col">
          <nz-form-item style="width: 100%">
            <label class="label required" nzFor="criteres"
              >Critère niveau 2</label
            >
            <div class="select-menu">
              <nz-select
                formControlName="criteres2"
                nzBorderless
                nzPlaceHolder="Critères..."
                nzMode="tags"
                [nzMaxTagCount]="1"
                nzServerSearch
                [compareWith]="compareCritere2"
                [nzLoading]="((criteres$ | async)?.data | size) ? false : true"
              >
                <nz-option
                  *ngFor="let item of (criteres$ | async)?.data"
                  [nzLabel]="item.item"
                  [nzValue]="item"
                ></nz-option>
              </nz-select>
              <small
                *ngIf="
                  administratifForm.value.criteres2?.invalid &&
                  administratifForm.value.criteres2?.dirty &&
                  administratifForm.value.criteres2?.touched
                "
                class="inpurFormAlert"
                >{{
                  administratifForm.value.criteres2?.invalid &&
                  administratifForm.value.criteres2?.dirty &&
                  administratifForm.value.criteres2?.touched
                    ? "Renseignez les critères d'éligibilité de niveau 2"
                    : ""
                }}</small
              >
            </div>
          </nz-form-item>
        </div>
      </div>
    </nz-card>

    <nz-card nzSize="small" nzTitle="IAE">
      <div class="w-full flex items-start gap-2">
        <div class="w-4/12 flex flex-col pt-5">
          <label class="label" nzFor="minima"></label>
          <div
            class="form-control w-full border-s-slate-400 bg-slate-100 h-10 rounded-md"
          >
            <label class="label cursor-pointer space-x-4">
              <input
                type="checkbox"
                checked="checked"
                class="checkbox"
                formControlName="isPassIae"
              />
              <span class="label-text -mt-2">Pass IAE</span>
            </label>
          </div>
        </div>
        <div class="w-4/12 flex flex-col">
          <label class="label" nzFor="minima">Date de début IAE </label>

          <div class="numberForm">
            <nz-date-picker
              nzBorderless
              class="numberField"
              nzPlaceHolder="Date de début du Pass IAE"
              formControlName="dateStartPassIae"
              id="recrutement"
              [nzFormat]="dateFormat"
            >
            </nz-date-picker>
          </div>
        </div>
        <div class="w-4/12 flex flex-col">
          <label class="label" nzFor="criteres">Date d'échénace IAE</label>
          <div class="numberForm">
            <nz-date-picker
              class="numberField"
              nzBorderless
              nzPlaceHolder="Date de fin du Pass IAE"
              formControlName="dateEndPassIae"
              id="recrutement"
              [nzFormat]="dateFormat"
            >
            </nz-date-picker>
          </div>
        </div>
      </div>
    </nz-card>

    <nz-card nzSize="small" nzTitle="Réfugié">
      <div class="space-y-6">
        <div class="w-full flex flex-col">
          <label class="label" nzFor="cartes">Situation administrative </label>
          <div class="select-menu">
            <nz-select
              formControlName="typeCarteSejour"
              placeholder="Justificatif"
              nzBorderless
              nzPlaceHolder="Justificatif..."
              [compareWith]="compareSejour"
            >
              <nz-option-group
                *ngFor="let group of (titres_sejours$ | async)?.data"
                [nzLabel]="group.type"
              >
                <nz-option
                  nzCustomContent="true"
                  *ngFor="let item of group.cartes"
                  [nzLabel]="item.titre"
                  [nzValue]="item"
                >
                  <p>
                    {{ item.titre }}
                  </p>
                </nz-option>
              </nz-option-group>
            </nz-select>
          </div>
        </div>

        <div class="w-full flex items-start gap-x-2 mt-6">
          <div class="w-2/6 flex flex-col">
            <label class="label" nzFor="carte">N° de carte</label>
            <input
              name="carte"
              class="inputForm"
              type="text"
              placeHolder="1234..."
              id="carte"
              formControlName="numbCarteSejour"
            />
          </div>

          <div class="w-2/6 flex flex-col">
            <label class="label" nzFor="caiCir">N° CAI-CIR</label>
            <input
              class="inputForm"
              name="caiCir"
              type="text"
              placeHolder="1234..."
              id="caiCir"
              formControlName="numCaiCir"
            />
          </div>
          <div class="w-2/6 flex flex-col">
            <label class="label" nzFor="dateEntree"
              >Date d'entrée sur le territoire
            </label>
            <div class="numberForm">
              <nz-date-picker
                class="numberField"
                nzBorderless
                nzPlaceHolder="Sur le territoire"
                formControlName="dateEntree"
                id="dateEntree"
                [nzFormat]="dateFormat"
              >
              </nz-date-picker>
            </div>
          </div>
        </div>
      </div>
    </nz-card>

    <ng-container *ngIf="isFreinValidation">
      <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col nzSpan="24">
          <nz-form-item>
            <nz-form-control>
              <label class="label" nz-checkbox formControlName="isFrein">{{
                isFreinTitle
              }}</label>
            </nz-form-control>
          </nz-form-item>
        </nz-col>
      </nz-row>
    </ng-container>
  </div>
</form>

<!-- <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">



        <nz-col [nzSpan]="add ? 6 : 6">
          <nz-form-item>
            <nz-form-label nzRequired="true" [nzSpan]="24" nzFor="inscription"
              >Durée du parcours
            </nz-form-label>
            <nz-form-control nzErrorTip="Aucun durée renseignée">
              <nz-input-number
                [nzMin]="1"
                formControlName="duration"
                nzPlaceHolder="En mois"
              ></nz-input-number>
            </nz-form-control>
          </nz-form-item>
        </nz-col>



        <nz-col [nzSpan]="add ? 0 : 6">
          <nz-form-item *ngIf="!add">
            <nz-form-label [nzSpan]="24" nzFor="nationality"
              >Nationalité
            </nz-form-label>
            <nz-form-control>
              <nz-select
                formControlName="nationality"
                placeholder="Nationalité"
                nzBorderless
                nzPlaceHolder="Nationalité"
                [compareWith]="compareNationality"
              >
                <nz-option
                  *ngFor="
                    let countrie of (utilityIdentity$ | async)?.countries?.data
                  "
                  [nzLabel]="countrie.nationalite | titlecase"
                  [nzValue]="countrie"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </nz-col>
      </nz-row>
      <nz-space [nzSize]="100"></nz-space>

      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start" *ngIf="!add">
        <nz-col nzSpan="6">
          <nz-form-item>
            <nz-form-label [nzSpan]="24" nzFor="birthday"
              >Date de naissance
            </nz-form-label>
            <nz-form-control nzErrorTip="Aucune date de naissance renseignée">
              <nz-date-picker
                nzBorderless
                nzPlaceHolder="Date..."
                formControlName="dateNaissance"
                id="birthday"
                nzFormat="dd/MM/yyyy"
              >
              </nz-date-picker>
            </nz-form-control>
          </nz-form-item>
        </nz-col>

        <nz-col nzSpan="6">
          <nz-form-item>
            <nz-form-label [nzSpan]="24" nzFor="birthday">Âge </nz-form-label>
            <nz-form-control>
              <nz-input-number
                formControlName="age"
                placeholder="Âge"
              ></nz-input-number>
            </nz-form-control>
          </nz-form-item>


        </nz-col>

        <nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label [nzSpan]="18" nzFor="lieuDeNaissance"
              >Lieu de naissance</nz-form-label
            >
            <nz-form-control nzErrorTip="Aucun lieu de naissance renseigné">
              <input
                nz-input
                nzBorderless
                formControlName="lieuDeNaissance"
                placeholder="Lieu de naissance"
                id="lieuDeNaissance"
              />
            </nz-form-control>
          </nz-form-item>
        </nz-col>
      </nz-row>
      <nz-space [nzSize]="100"></nz-space>

      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start" *ngIf="!add">
        <nz-col nzSpan="24">
          <app-coordonnees
            [update]="coordonneesUpdate"
            (mobile)="onMobile($event)"
            (phoneNumber)="onPhoneNumber($event)"
            (fixe)="onFixe($event)"
            (email)="onEmail($event)"
            [borderless]="true"
          >
          </app-coordonnees>
        </nz-col>
      </nz-row> -->

<!-- <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">

     
 -->
