<form nz-form [formGroup]="btpForm">
  <nz-card nzSize="small" nzTitle="BTP">
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="12">
        <app-check-card
          [title]="'Carte BTP'"
          [isActive]="btpForm.value.isCarteBtp"
          [definition]="isCarteBtpTitle"
          [height]="'40px'"
          [fontSize]="'14px'"
          (select)="checkboxChanges($event, 'isCarteBtp')"
        ></app-check-card>
      </nz-col>
      <nz-col nzSpan="12">
        <app-check-card
          [title]="'Carte BTP : validité'"
          [isActive]="btpForm.value.isExpiredCarteBtp"
          [definition]="isExpiredCarteBtpTitle"
          [height]="'40px'"
          [fontSize]="'14px'"
          (select)="checkboxChanges($event, 'isExpiredCarteBtp')"
        ></app-check-card>
      </nz-col>
    </nz-row>
    <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="12">
        <nz-form-item>
          <nz-form-label nzSpan="24">Date de délivrable</nz-form-label>
          <nz-form-control>
            <nz-date-picker
              formControlName="carteBtpDate"
              nzBorderless
              nzPlaceHolder="Date de validité"
              [nzFormat]="dateFormat"
            ></nz-date-picker>
          </nz-form-control>
        </nz-form-item>
      </nz-col>
      <nz-col nzSpan="12">
        <nz-form-item>
          <nz-form-label nzSpan="24">N° de carte BTP</nz-form-label>
          <nz-form-control>
            <input
              nz-input
              formControlName="numeroCarteBtp"
              type="number"
              max="10"
              nzBorderless
              nzPlaceHolder="Numéro d'identification"
              placeholder="Numéro d'identification"
            />
          </nz-form-control>
        </nz-form-item>
      </nz-col>
    </nz-row>
  </nz-card>
</form>
