import {
  ChangeDetectionStrategy,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Component({
  selector: 'app-information-collective-form',
  templateUrl: './information-collective-form.component.html',
  styleUrls: ['./information-collective-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationCollectiveFormComponent
  implements OnInit, OnChanges, OnDestroy
{
  subcribe = new Subject();

  @Input() informationCollective$ = new BehaviorSubject<any>(null);
  @Output() cancel = new EventEmitter<any>(false);
  @Output() add = new EventEmitter<any>(false);
  @Output() update = new EventEmitter<any>(false);

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnDestroy(): void {
    this.subcribe.next(null);
    this.subcribe.complete();
  }
}
