<form nz-form [formGroup]="familleForm" nzLayout="vertical">
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="24">
      <nz-form-item>
        <nz-form-label [nzSpan]="24">Situation matrimonial</nz-form-label>
        <nz-form-control>
          <nz-select
            nzBorderless
            nzPlaceHolder="Identifier la situation matrimoniale"
            nzServerSearch
            formControlName="familiale"
            [nzLoading]="(matrimonial$ | async | size) ? false : true"
            [compareWith]="comparePatrimonial"
          >
            <nz-option
              *ngFor="let item of matrimonial$ | async"
              [nzValue]="item"
              [nzLabel]="item"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>
  <nz-space [nzSize]="100"></nz-space>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="8">
      <app-check-card
        [title]="'Enfants à charge'"
        [isActive]="familleForm.value.enfantsAcharges"
        [definition]="checkboxDefinitionEnfantAcCharge"
        [height]="'100px'"
        [fontSize]="'14px'"
        (select)="checkboxChanges($event, 'enfantsAcharges')"
      ></app-check-card>
    </nz-col>
    <nz-col nzSpan="8">
      <app-check-card
        [title]="'Enfants scolarisés'"
        [isActive]="familleForm.value.enfantScolarise"
        [definition]="checkboxDefinitionEnfantScolarise"
        [height]="'100px'"
        [fontSize]="'14px'"
        (select)="checkboxChanges($event, 'enfantScolarise')"
      ></app-check-card>
    </nz-col>
    <nz-col nzSpan="8">
      <app-check-card
        [title]="'Moyen de garde'"
        [isActive]="familleForm.value.enfantMoyenDeGarde"
        [definition]="checkboxDefinitionMoyenDeGarde"
        (select)="checkboxChanges($event, 'enfantMoyenDeGarde')"
        [height]="'100px'"
        [fontSize]="'14px'"
      ></app-check-card>
    </nz-col>
  </nz-row>

  <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>

  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="24">
      <nz-form-item>
        <nz-form-control>
          <label nz-checkbox formControlName="isFrein" class="itemCenter">{{
            isFreinTitle
          }}</label>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>
</form>

<ng-template #cardTitle>
  <app-header-title [icon]="'diversity_3'"></app-header-title>
</ng-template>
