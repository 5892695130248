import { Pipe, PipeTransform } from '@angular/core';
import { fromUnixTime, format, isSameDay, isBefore, isAfter } from 'date-fns';
import { fr } from 'date-fns/locale';

@Pipe({
  name: 'todosCalendar',
})
export class TodosCalendarPipe implements PipeTransform {
  today: Date = new Date(Date.now());

  transform(value: any[], arg: string): any {
    if (!value || !value?.length) {
      return null;
    }

    if (arg === 'today') {
      const items = value.filter((todo: any) => {
        const start: Date = fromUnixTime(todo.dateStart['seconds']);

        if (isSameDay(start, this.today)) {
          return {
            jours: format(start, 'd EEE', { locale: fr }),
            tâches: 1,
            label: '1',
          };
        }else{
          return null
        }
      });

      const priority: any[] = items.filter((item) => item.priority === true);

      return { items, priority };
    }

    if (arg === 'futur') {
      const items = value.filter((todo: any) => {
        const start: Date = fromUnixTime(todo.dateStart['seconds']);

        if (isAfter(start, this.today)) {
          return {
            jours: format(start, 'd EEE', { locale: fr }),
            tâches: 1,
            label: '1',
          };
        }else{
          return null
        }
      });

      const priority: any[] = items.filter((item) => item.priority === true);

      return { items, priority };
    }

    if (arg === 'latest') {
      const items = value.filter((todo: any) => {
        const start: Date = fromUnixTime(todo.dateStart['seconds']);
        const end: Date = fromUnixTime(todo.dateEnd['seconds']);

        if (isBefore(end, this.today) && !todo.completed) {
          return {
            jours: format(start, 'd EEE', { locale: fr }),
            tâches: 1,
            label: '1',
          };
        }else{
          return null
        }
      });

      const priority: any[] = items.filter((item) => item.priority === true);

      return { items, priority };
    } else {
      return null;
    }
  }
}
