import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss'],
})
export class UserCardComponent implements OnInit {
  avatarBackground: string = '#bfbfbf';
  avatarColor: string = 'white';
  buttonColor: string = '#36cfc9';
  bgColor: string = '#bfbfbf';
  bgDefault: string = '#fff';

  @Input() user: any;

  @Output() select: EventEmitter<any> = new EventEmitter<any>();
  constructor() {}

  ngOnInit(): void {}

  onSelect(user: any): void {
    this.select.emit(user);
  }
}
