import { NzMessageService } from 'ng-zorro-antd/message';
import { DocumentComponent } from '../document/document.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { takeUntil } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  ViewContainerRef,
  ViewChild,
  TemplateRef,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { DocumentId } from 'src/app/models/document.model';
import { last, first, uniqWith, isEqual, sortBy } from 'lodash';
import { fr } from 'date-fns/locale';
import { format, fromUnixTime } from 'date-fns';

@Component({
  selector: 'app-documents-list',
  templateUrl: './documents-list.component.html',
  styleUrls: ['./documents-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentsListComponent implements OnInit, OnChanges, OnDestroy {
  subscribe = new Subject();
  @Input() newCardTitle: string = 'Nouveau document';
  @Input() newCardDescription: string = 'Ajouter un document';
  newCardDefinition: string = '';

  newDocument = false;
  newEvent = false;
  modalWidth: number = 950;

  @Input() source$: Observable<DocumentId[] | any> = of(null);
  @Output() add = new EventEmitter<any>(false);
  @Output() delete = new EventEmitter<any>(false);

  @ViewChild('documentTitleForm', { static: false }) documentTitleForm:
    | TemplateRef<any>
    | any;

  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private message: NzMessageService
  ) {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  onNewDocument(event: any): void {
    const modal = this.modal.create({
      nzContent: DocumentComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.documentTitleForm,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
    });
    const instance = modal.getContentComponent();
    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });

    instance.addDocument.subscribe((event): any => {
      this.onAddDocument(event);
      modal.close();
    });
  }

  onSelectDocument(event: DocumentId): void {
    const { file } = event;
    window.open(file, '_blank');

    const fileRef = event.fileRef;
    const ext = event.fileRef.split('/').slice(-2)[0];
  }

  onDowloadFile(item: DocumentId): void {
    let filename = item.title
      .substring(item.title.lastIndexOf('/') + 1)
      .split('?')[0];
    let xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = function () {
      var a = document.createElement('a');
      a.href = window.URL.createObjectURL(xhr.response);
      a.download = filename;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
    };
    xhr.open('GET', item.fileRef);
    xhr.send();

    const success: string = `votre document a été téléchargé`;
    this.message.success(success, { nzDuration: 6000 });
  }

  onAddDocument(documents: any): void {
    this.add.emit(documents);
    this.newDocument = false;
  }

  onNewDocumentCancel(event: any): void {
    this.newDocument = false;
  }

  /* DELETE */
  onCancel() {
    return;
  }

  onDelete(document: DocumentId): void {
    this.delete.emit(document);
  }
  onDeleteAll(): void {}
}
