<nz-card
  [nzTitle]="mission?.libelle | carLimitation : 100"
  nzSize="small"
  [nzExtra]="missionExtraTpl"
>
  <nz-descriptions nzSize="small" nzTitle="false">
    <nz-descriptions-item nzSpan="3" nzTitle="ROME">
      <nz-tag>
        {{ mission?.metier?.code }}
      </nz-tag>
    </nz-descriptions-item>
    <nz-descriptions-item
      nzTitle="Compétences de base"
      nzSpan="3"
    ></nz-descriptions-item>
    <nz-descriptions-item
      nzTitle="Compétences spécifiques"
      nzSpan="3"
    ></nz-descriptions-item>
    <nz-descriptions-item
      nzTitle="Softskills"
      nzSpan="3"
    ></nz-descriptions-item>
    <nz-descriptions-item
      nzTitle="Environnements"
      nzSpan="3"
    ></nz-descriptions-item>
  </nz-descriptions>
</nz-card>

<ng-template #missionExtraTpl>
  <button nz-button (click)="onClean()">Effacer</button>
</ng-template>
