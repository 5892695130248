import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PdfViewerComponent implements OnInit {
  isLoading: boolean = true;
  numPages: number = 0;
  page: number = 1;
  @Input() pdfSrc: string = '';
  @Output() cancel = new EventEmitter<boolean>(false);
  @Output() download = new EventEmitter<boolean>(false);

  constructor() {}

  ngOnInit(): void {}

  loadingFn(pdf: PDFDocumentProxy): void {
    if (!pdf) {
      this.isLoading = true;
      return;
    }
    const { _pdfInfo } = pdf;
    const { fingerprints, numPages } = _pdfInfo;
    this.numPages = numPages;
    this.isLoading = false;
  }
  loadingProgressionFn(pdf: any): void {
    if (!pdf) return;
    this.isLoading = false;
  }

  onPrevPage(): void {
    this.page = this.page !== 1 ? this.page - 1 : this.page;
  }
  onNextPage(): void {
    this.page = this.page !== this.numPages ? this.page + 1 : this.numPages;
  }

  onCancel(): void {
    this.cancel.emit(true);
  }
  onDownload(): void {
    this.download.emit(true);
  }
}
