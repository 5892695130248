import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from './components/table/table.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { NoResultModule } from 'src/app/contents/components/no-result/no-result.module';
import { ButtonsModule } from 'src/app/contents/components/buttons/buttons.module';

@NgModule({
  declarations: [TableComponent],
  imports: [CommonModule, SharedModule, NoResultModule, ButtonsModule],
  exports: [TableComponent],
})
export class TableModule {}
