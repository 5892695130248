import { Coordonnees } from './../contents/components/coordonnees/coordonnees.model';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'coordonnees',
})
export class CoordonneesPipe implements PipeTransform {
  transform(item: any, property: string): string {
    const coordonnees: Coordonnees | any = item?.coordonnees;

    if (!coordonnees || !property) {
      return '';
    } else {
      switch (property) {
        case 'mobile':
          const mobile = coordonnees?.mobile;
          const mobileNum1 = mobile?.substring(0, 2);
          const mobileNum2 = mobile?.substring(2, 4);
          const mobileNum3 = mobile?.substring(4, 6);
          const mobileNum4 = mobile?.substring(6, 8);
          const mobileNum5 = mobile?.substring(8, 10);
          const mobileNum6 = mobile?.substring(10, 12);
          const mobileTel = `${mobileNum1} ${mobileNum2} ${mobileNum3} ${mobileNum4} ${mobileNum5} ${mobileNum6}`;

          return mobileTel;

          break;
        case 'fixe':
          const fixe = coordonnees?.fixe;
          const fixeNum1 = fixe?.substring(0, 2);
          const fixeNum2 = fixe?.substring(2, 4);
          const fixeNum3 = fixe?.substring(4, 6);
          const fixeNum4 = fixe?.substring(6, 8);
          const fixeNum5 = fixe?.substring(8, 10);
          const fixeNum6 = fixe?.substring(10, 12);
          const fixTel = `${fixeNum1} ${fixeNum2} ${fixeNum3} ${fixeNum4} ${fixeNum5} ${fixeNum6}`;

          return fixTel;
          break;
        case 'email':
          return coordonnees?.email;

          break;
        case 'phoneNumber':
          return coordonnees?.phoneNumber;
          break;
        case 'web':
          return coordonnees?.web;
          break;
        default:
          return '';
          break;
      }
    }
  }
}
