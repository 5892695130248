<nz-page-header
  class="page-header"
  [nzTitle]="title$ | async"
  [nzSubtitle]="headerExtra"
  *ngIf="entite?.custom?.metiers | size"
>
  <nz-page-header-extra>
    <button
      nz-button
      nzType="text"
      class="btnStandar"
      nz-tooltip
      [nzTooltipMouseEnterDelay]="0.5"
      nzTooltipPlacement="bottom"
      nzTooltipTitle="Afficher la liste de métiers ajoutés"
      (click)="$event.stopPropagation(); onSelectTab('Métiers', '')"
    >
      <p nz-typography>Liste</p>
    </button>
    <button
      *ngIf="entite?.custom?.metiers | size"
      nz-button
      nzType="text"
      (click)="$event.stopPropagation()"
      nz-popover
      nzPopoverTrigger="click"
      [nzPopoverTitle]="addtypeSelectionTitle"
      [nzPopoverContent]="addtypeSelection"
      nzPopoverPlacement="right"
      class="btnNew"
    >
      <h5 nz-typography>Nouveau</h5>
    </button>
  </nz-page-header-extra>
  <nz-page-header-content> </nz-page-header-content>
</nz-page-header>

<ng-template #headerExtra>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="24">
      <nz-badge
        [nzCount]="entite?.custom?.metiers | size"
        [nzOverflowCount]="99"
      >
      </nz-badge>
    </nz-col>
  </nz-row>
</ng-template>

<nz-divider *ngIf="entite?.custom?.metiers | size"></nz-divider>
<app-metiers-naf-suggestions
  *ngIf="
    ((tab$ | async) === 'Suggestions' && (type$ | async) === 'naf') ||
    (type$ | async) === 'domaine'
  "
  [entite]="entite"
  [type]="type$ | async"
  (confirm)="onUpdate($event, entite)"
></app-metiers-naf-suggestions>

<app-metiers
  *ngIf="(tab$ | async) === 'Suggestions' && (type$ | async) === 'nouveau'"
  [type]="'grandDomaine'"
  [showMetiers]="true"
  [showAppelations]="true"
  [showAppelation]="true"
></app-metiers>

<ng-container *ngIf="(tab$ | async) === 'Métiers'">
  <app-layout-list
    *ngIf="entite?.custom?.metiers | size; else noResults"
    [card]="card"
    [source]="entite?.custom?.metiers"
  ></app-layout-list>
</ng-container>

<ng-template #card let-item let-position="idx">
  <nz-card
    nzHoverable
    class="cardMetier"
    [nzTitle]="cardTtitleTag"
    [nzExtra]="cardActionBtn"
  >
    <nz-row
      nzAlign="middle"
      nzGutter="8"
      nzJustify="start"
      class="cardMetierLibelle"
    >
      <nz-col nzSpan="24">
        <h5 nz-typography>{{ item?.libelle | carLimitation: 40 }}</h5>
      </nz-col>
    </nz-row>
    <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="24">
        <nz-tag nzColor="geekblue">CODE : {{ item?.code }}</nz-tag>
      </nz-col></nz-row
    >
  </nz-card>

  <ng-template #cardTtitleTag>
    <app-header-title [icon]="'business_center'"></app-header-title>
  </ng-template>

  <ng-template #cardActionBtn>
    <button
      nz-button
      nzShape="circle"
      nz-dropdown
      [nzDropdownMenu]="menuCardActions"
      nzTrigger="click"
      nzPlacement="bottomRight"
      nzType="text"
    >
      <mat-icon>more_vert</mat-icon>
    </button>
  </ng-template>

  <nz-dropdown-menu #menuCardActions="nzDropdownMenu">
    <ul nz-menu>
      <li nz-menu-item nzDanger (click)="onDelete(item, entite)">
        <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
          <nz-col nzSpan="6">
            <mat-icon>delete</mat-icon>
          </nz-col>
          <nz-col nzSpan="2">
            <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
          </nz-col>
          <nz-col nzSpan="16">Supprimer</nz-col>
        </nz-row>
      </li>
    </ul>
  </nz-dropdown-menu>
</ng-template>

<ng-template #addtypeSelectionTitle>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="center">
    <nz-col nzSpan="4">
      <mat-icon>business_center</mat-icon>
    </nz-col>
    <nz-col nzSpan="20"> Métier </nz-col>
  </nz-row>
</ng-template>

<ng-template #addtypeSelection>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="center" class="addTypeRow">
    <nz-col nzSpan="12">
      <nz-card
        class="addTypeCard"
        (click)="$event.stopPropagation()"
        nzTitle="Nouveau"
        nzSize="small"
        nzHoverable
        (click)="onSelectTab('Suggestions', 'nouveau')"
      >
        <h5 nz-typography class="center newPrimary">Fiche métier vierge</h5>
      </nz-card>
    </nz-col>
    <nz-col nzSpan="12">
      <nz-card
        class="addTypeCard"
        (click)="$event.stopPropagation()"
        nzTitle="Suggestions"
        nzSize="small"
        nzHoverable
      >
        <nz-row nzAlign="middle" nzGutter="8" nzJustify="center">
          <nz-col nzSpan="10">
            <button
              nz-button
              class="addTypeBtn"
              (click)="onSelectTab('Suggestions', 'naf')"
            >
              <p nz-typography>Activité</p>
            </button>
          </nz-col>
          <nz-col nzSpan="4"></nz-col>
          <nz-col nzSpan="10">
            <button
              nz-button
              class="addTypeBtn"
              (click)="onSelectTab('Suggestions', 'domaine')"
            >
              <p nz-typography>Domaine</p>
            </button>
          </nz-col>
        </nz-row>
      </nz-card>
    </nz-col>
  </nz-row>
</ng-template>

<ng-template #noResults>
  <app-no-result
    [icon]="'business_center'"
    [notitle]="'Aucun métier'"
    [addtitle]="'Nouveau'"
    (click)="$event.stopPropagation()"
    nz-popover
    nzPopoverTrigger="click"
    [nzPopoverTitle]="addtypeSelectionTitle"
    [nzPopoverContent]="addtypeSelection"
    nzPopoverPlacement="bottom"
  ></app-no-result>
</ng-template>
