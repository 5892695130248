<nz-page-header
  class="page-header"
  [nzTitle]="header"
  [nzSubtitle]="cardExtra"
  *ngIf="aid"
  (nzBack)="onBack()"
  [ngStyle]="{ height: isUpdate ? '150px' : '60px' }"
  nzBackIcon
>
  <nz-page-header-extra>
    <nz-tag
      class="tagStateReceive"
      nzColor="green"
      *ngIf="isUpdate && aid?.receive; else aidNoReceive"
    >
      <i nz-icon nzType="check-circle"></i>
    </nz-tag>

    <ng-template #aidNoReceive>
      <nz-tag
        class="tagStateReceive"
        nzColor="orange"
        *ngIf="isUpdate && !aid?.receive"
      >
        <i nz-icon nzType="field-time"></i>
      </nz-tag>
    </ng-template>
  </nz-page-header-extra>
  <nz-page-header-content>
    <form nz-form [formGroup]="aidForm" nzLayout="horizontal" *ngIf="isUpdate">
      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col nzSpan="4">
          <nz-form-item nzAlign="middle">
            <nz-form-label nzSpan="24">Validité</nz-form-label>
            <nz-tag
              class="tagReceive"
              [nzColor]="aidForm.value.receive ? 'success' : 'orange'"
              [nzChecked]="aidForm.value.receive"
              nzMode="checkable"
              (nzCheckedChange)="onReceive($event)"
            >
              {{ aidForm.value.receive ? "Validé" : "Invalidé" }}
            </nz-tag>
          </nz-form-item>
        </nz-col>
        <nz-col nzSpan="6">
          <nz-form-item nzAlign="middle">
            <nz-form-label nzSpan="24">Début de validité</nz-form-label>
            <nz-form-control>
              <nz-date-picker
                nzAllowClear="true"
                formControlName="receiveDateStart"
                nzShowNow="true"
                nzBorderless
                [nzFormat]="dateFormat"
                nzPlaceHolder="Date de début"
              ></nz-date-picker>
            </nz-form-control>
          </nz-form-item>
        </nz-col>
        <nz-col nzSpan="4">
          <nz-form-item nzAlign="middle">
            <nz-form-label nzSpan="24">Durée (mois)</nz-form-label>
            <nz-form-control>
              <nz-input-number
                formControlName="receiveDuration"
                nzPlaceHolder="Durée"
                [nzMin]="0"
              ></nz-input-number>
            </nz-form-control>
          </nz-form-item>
        </nz-col>
        <nz-col nzSpan="6">
          <nz-form-item nzAlign="middle">
            <nz-form-label nzSpan="24">Fin de validité</nz-form-label>
            <nz-form-control>
              <nz-date-picker
                nzAllowClear="true"
                formControlName="receiveDateEnd"
                nzShowNow="true"
                nzBorderless
                [nzFormat]="dateFormat"
                nzPlaceHolder="Date de fin"
              ></nz-date-picker>
            </nz-form-control>
          </nz-form-item>
        </nz-col>
        <nz-col nzSpan="4">
          <nz-form-item nzAlign="middle">
            <nz-form-label nzSpan="24">Montant réçu (euro)</nz-form-label>
            <nz-form-control>
              <nz-input-number
                formControlName="reveiveValue"
                nzPlaceHolder="Montant"
                [nzMin]="0"
              ></nz-input-number>
            </nz-form-control>
          </nz-form-item>
        </nz-col>
      </nz-row>
    </form>
  </nz-page-header-content>
</nz-page-header>

<ng-template #header>
  <h4 nz-typography>{{ aid?.name }}</h4>
</ng-template>
<ng-template #cardExtra></ng-template>

<nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
<div class="aidDetailContainer">
  <nz-descriptions
    nzBordered
    nzSize="small"
    [nzColumn]="{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }"
  >
    <nz-descriptions-item nzSpan="3" nzTitle="Description">
      <div [innerHTML]="aid?.what | nosanitizerpipe"></div>
    </nz-descriptions-item>
    <nz-descriptions-item nzSpan="3" nzTitle="Allocation">
      <div [innerHTML]="aid?.how_much | nosanitizerpipe"></div>
    </nz-descriptions-item>
    <nz-descriptions-item nzSpan="3" nzTitle="Conditions">
      <div [innerHTML]="aid?.additionnal_conditions | nosanitizerpipe"></div>
    </nz-descriptions-item>
    <nz-descriptions-item nzSpan="3" nzTitle="Démarches">
      <div [innerHTML]="aid?.how_and_when | nosanitizerpipe"></div>
    </nz-descriptions-item>
    <nz-descriptions-item nzSpan="3" nzTitle="Limites">
      <div [innerHTML]="aid?.limitations | nosanitizerpipe"></div>
    </nz-descriptions-item>
  </nz-descriptions>
</div>
<nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>

<nz-row nzAlign="middle" nzGutter="8" nzJustify="end">
  <nz-col nzSpan="20"></nz-col>
  <nz-col nzSpan="4">
    <button
      *ngIf="!isUpdate"
      nz-button
      nzBlock
      nzType="primary"
      (click)="onValidate(aid)"
    >
      Validation
    </button>
    <button
      *ngIf="isUpdate"
      nz-button
      nzBlock
      nzType="primary"
      [disabled]="!aidForm.valid"
      (click)="onUpdate(aid)"
    >
      Modifier
    </button>
  </nz-col>
</nz-row>
