import { createFeatureSelector, createSelector } from '@ngrx/store';

import { FavoriState, favoriesFeatureKey, selectAll } from './favorie.reducer';

export const selectFavoriState =
  createFeatureSelector<FavoriState>(favoriesFeatureKey);

export const selectMessages = createSelector(selectFavoriState, selectAll);

export const entites = createSelector(
  selectFavoriState,
  (state: FavoriState) => state.entites
);

export const participants = createSelector(
  selectFavoriState,
  (state: FavoriState) => state.participants
);

export const error = createSelector(
  selectFavoriState,
  (state: FavoriState) => state.error
);
