import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-fiches-de-poste-form-export',
  templateUrl: './fiches-de-poste-form-export.component.html',
  styleUrls: ['./fiches-de-poste-form-export.component.scss'],
})
export class FichesDePosteFormExportComponent implements OnInit {
  types$: Observable<string[]> = of(['PDF', 'WORD']);

  isAnonyme = new FormControl(false);
  type = new FormControl('PDF');
  referentRecrutement = new FormControl();

  ficheExportForm: FormGroup = this.fb.group({
    isAnonyme: this.isAnonyme,
    type: this.type,
    referentRecrutement: this.referentRecrutement,
  });

  @Input() isReferentSelect: boolean = true;
  @Input() users: any = [];
  @Output() cancel = new EventEmitter();
  @Output() export = new EventEmitter();

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {}

  onCancel(): void {
    this.cancel.emit(true);
  }

  onValidation(): void {
    const value = this.ficheExportForm.value;
    this.export.emit(value);
  }
}
