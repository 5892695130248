import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, Subject, takeUntil } from 'rxjs';
import { OperationFicheDePosteState } from '../../store/operation-fiche-de-poste.reducer';
import * as fromOperationFicheDePosteAction from '../../store/operation-fiche-de-poste.actions';
import * as fromOperationFicheDePosteSelector from '../../store/operation-fiche-de-poste.selectors';
import { OperationState } from 'src/app/components/operation/store/operation.reducer';
import * as fromOperationSelector from 'src/app/components/operation/store/operation.selectors';
import * as fromOperationAction from 'src/app/components/operation/store/operation.actions';
import { ActivatedRoute, Data, Router } from '@angular/router';

@Component({
  selector: 'app-operation-fiches-de-poste-list',
  templateUrl: './operation-fiches-de-poste-list.component.html',
  styleUrls: ['./operation-fiches-de-poste-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationFichesDePosteListComponent
  implements OnInit, OnChanges, OnDestroy
{
  fiches$: Observable<any>;
  loading$: Observable<boolean | any> = this.operationFicheDePosteStore.select(
    fromOperationFicheDePosteSelector.loading
  );

  subscribe = new Subject();
  operation$: Observable<any> = this.operationStore.select(
    fromOperationSelector.operation
  );
  lot$: Observable<any> = of({ id: '', title: '' });
  marche$: Observable<any> = of({ id: '', title: '' });

  constructor(
    private operationStore: Store<OperationState>,
    private operationFicheDePosteStore: Store<OperationFicheDePosteState>,
    private router: Router,
    private route: ActivatedRoute
  ) {
    const data: Data = this.route.snapshot.data;
    this.fiches$ = data.fiches;
    this.operationStore.dispatch(
      fromOperationAction.setActviteRoute({ title: data?.title })
    );
  }

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
    this.operationStore.dispatch(
      fromOperationAction.setActviteRoute({ title: '' })
    );
  }

  loadFicheDePoste(fiche: any, operation: any): void {
    if (!operation || !fiche) return;
    const { id } = operation;
    this.operationFicheDePosteStore.dispatch(
      fromOperationFicheDePosteAction.loadOperationFicheDePoste({
        id: id,
        ficheId: fiche.id,
      })
    );
  }

  onSelect(fiche: any, id: string): void {
    if (!fiche || !id) return;
    const itemId: string = fiche.id;
    this.router.navigate(['/projet', id, 'ficheDePoste', itemId]);
  }

  onActions(event: string, operationId: string): void {
    if (!event || !operationId) return;
  }
  onFilters(event: string, operationId: string): void {
    if (!event || !operationId) return;
  }

  onAdd(fiche: any, id: string): void {
    if (!id || !fiche) return;
    this.operationFicheDePosteStore.dispatch(
      fromOperationFicheDePosteAction.addOperationFicheDePoste({ id, fiche })
    );
  }
  onCopy(fiche: any, id: string): void {
    if (!id || !fiche) return;
    this.operationFicheDePosteStore.dispatch(
      fromOperationFicheDePosteAction.copyOperationFicheDePoste({
        id,
        ficheId: fiche.id,
      })
    );
  }
  onUpdate(fiche: any): void {}
  onDelete(fiche: any, id: string): void {
    if (!id || !fiche) return;

    this.operationFicheDePosteStore.dispatch(
      fromOperationFicheDePosteAction.deleteOperationFicheDePoste({
        operationId: id,
        id: fiche.id,
      })
    );
  }
  onDeleteAll(event: string, id: string): void {
    if (!id || event !== 'deleteAll') return;
    this.operationFicheDePosteStore.dispatch(
      fromOperationFicheDePosteAction.deleteOperationFicheDePostes({
        id: id,
        ids: [],
      })
    );
  }
}
