<app-card-identity *ngIf="participant">
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col>
      <nz-avatar nzSize="default" nzIcon="mail"> </nz-avatar>
    </nz-col>
    <nz-col>
      <nz-tag class="tagCoordonnes">
        {{ participant?.coordonnees?.email | carLimitation: 20 }}
      </nz-tag>
    </nz-col>
  </nz-row>
  <nz-space [nzSize]="5"></nz-space>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col>
      <nz-avatar nzSize="default" nzIcon="mobile"> </nz-avatar>
    </nz-col>
    <nz-col>
      <nz-tag class="tagCoordonnes">
        {{ participant?.coordonnees?.mobile | phone }}
      </nz-tag>
    </nz-col>
  </nz-row>
  <nz-space [nzSize]="5"></nz-space>

  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col>
      <nz-avatar nzSize="default" nzIcon="phone"> </nz-avatar>
    </nz-col>
    <nz-col>
      <nz-tag class="tagCoordonnes">
        {{ participant?.coordonnees?.fixe | phone }}
      </nz-tag>
    </nz-col>
  </nz-row>

  <nz-divider class="identityDivider"></nz-divider>

  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col
      nzSpan="12
    "
    >
      <app-avatar-group [source]="participant?.referents"></app-avatar-group>
    </nz-col>
    <nz-col nzSpan="12">
      <div class="participant_dateUpdate">
        <mat-icon>event</mat-icon>
        <span>{{ participant?.dateUpdate | dateFr: "small" }}</span>
      </div>
    </nz-col>
  </nz-row>
</app-card-identity>

<ng-template #prefixMobile>
  <nz-avatar
    nzIcon="mobile"
    nzSize="small"
    class="iconCoordonnees"
    [style]="{ 'background-color': avatarBackgroundColor }"
  ></nz-avatar>
</ng-template>
<ng-template #prefixMail>
  <nz-avatar
    nzIcon="mail"
    nzSize="small"
    class="iconCoordonnees"
    [style]="{ 'background-color': avatarBackgroundColor }"
  ></nz-avatar>
</ng-template>
<ng-template #preReferent
  ><i nz-icon nzType="user" class="iconCoordonnees"></i
></ng-template>
<ng-template #preHandicap
  ><mat-icon aria-hidden="false" aria-label="Handicap" class="iconCoordonnees"
    >accessibility</mat-icon
  ></ng-template
>

<ng-template #referentAvatar>
  <nz-avatar-group>
    <nz-avatar
      [nzText]="participant?.referents[0].avatar"
      nzSize="small"
      [ngStyle]="{
        'background-color': participant?.referents[0].setting?.color
          ? participant?.referents[0].setting?.color
          : default,
        margin: '8px'
      }"
    ></nz-avatar>
    <nz-avatar
      *ngIf="(participant?.referents | size) > 1"
      [nzText]="'+' + ((participant?.referents | size) - 1)"
      nzSize="small"
      [ngStyle]="{
        margin: '8px',
        'margin-left': '-13px',
        'background-color': '#ffa940'
      }"
    ></nz-avatar>
  </nz-avatar-group>
</ng-template>
