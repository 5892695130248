import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { OperationCadrage } from './operation-cadrage.model';

export const loadOperationCadrage = createAction(
  '[OperationCadrage/COMPONENT] Load OperationCadrage',
  props<{ operationId: string }>()
);
export const loadOperationCadrageSuccess = createAction(
  '[OperationCadrage/API] Load OperationCadrage Success',
  props<{ cadrage: OperationCadrage }>()
);
export const loadOperationCadrageFailure = createAction(
  '[OperationCadrage/API] Load OperationCadrage Failure',
  props<{ error: any }>()
);

export const updateOperationCadrage = createAction(
  '[OperationCadrage/COMPONENT] Update OperationCadrage',
  props<{ operationId: string; cadrage: Update<OperationCadrage> }>()
);
export const updateOperationCadrageSuccess = createAction(
  '[OperationCadrage/API] Update OperationCadrage',
  props<{ success: string }>()
);
export const updateOperationCadrageFailure = createAction(
  '[OperationCadrage/API] Update OperationCadrage',
  props<{ error: any }>()
);
