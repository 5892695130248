import { OperationCadrage } from './../../store/operation-cadrage.model';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { of, Observable } from 'rxjs';
import { OperationId } from 'src/app/components/operation/store/operation.model';

@Component({
  selector: 'app-operation-cadrage-header',
  template: `
    <nz-page-header
      class="page-header"
      [nzTitle]="header"
      [nzSubtitle]="cardExtra"
    >
      <ng-template #header>
        <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
          <nz-col nzSpan="24">
            <h4 nz-typography>{{ title }}</h4>
          </nz-col>
        </nz-row>
      </ng-template>
      <ng-template #cardExtra>
        <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
          <nz-col nzSpan="2"></nz-col>
          <nz-col nzSpan="22"></nz-col>
        </nz-row>
      </ng-template>

      <nz-page-header-extra> </nz-page-header-extra>
    </nz-page-header>
  `,
  styles: [
    `
      .page-header {
        height: 60px !important;
      }
      h5,
      h4 {
        color: #062949;
      }
      p {
        color: gray;
        margin-top: 5px;
      }
    `,
  ],
})
export class OperationCadrageHeaderComponent implements OnInit {
  @Input() cadrage: OperationCadrage | any = null;
  @Input() operation: OperationId | any = null;

  @Input() title: string | any = '';
  @Output() select = new EventEmitter(false);

  constructor() {}

  ngOnInit(): void {}

  onSelect(item: string): void {
    this.select.emit(item);
  }
}
