import { Router, ActivatedRoute } from '@angular/router';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Navigation } from '../../store/navigation.model';

@Component({
  selector: 'app-navigation-buttons',
  templateUrl: './navigation-buttons.component.html',
  styleUrls: ['./navigation-buttons.component.scss'],
})
export class NavigationButtonsComponent implements OnInit, OnChanges {
  buttons: Navigation[] = [];

  @Input() url: string = '';
  @Input() theme: string = 'light';
  @Output() selector = new EventEmitter<any>(false);

  constructor(private router: Router, private activeRoute: ActivatedRoute) {}

  ngOnChanges(changes: SimpleChanges): void {
    switch (changes?.url?.currentValue) {
      case '/admin':
        this.setAdminButtons();
        break;
      case '/organisations':
        this.setNull();
        break;
      case '/services':
        this.setNull();
        break;
      case '/crm':
        this.setNull();
        break;
      case '/participants':
        this.setNull();
        break;
      case '/projets':
        this.setNull();
        break;

      default:
        this.setNull();
        break;
    }
  }

  ngOnInit(): void {
    this.activeRoute.paramMap.subscribe((p) => console.log(p));
  }

  onSelect(event: any, name: string): void {
    event.preventDefault();
    event.stopPropagation();
  }

  setAdminButtons(): void {
    const buttonsBTN: Navigation[] = [
      {
        name: 'Organisations',
      },
      {
        name: 'Utilisateurs',
      },
      {
        name: 'Tickets',
      },
      {
        name: 'Utilities',
      },
    ];
    this.buttons = buttonsBTN;
  }
  setOrganisationButtons(): void {
    const buttonsBTN: Navigation[] = [
      {
        name: 'Organisations',
      },
      {
        name: 'Utilisateurs',
      },
      {
        name: 'Parametre',
        icon: 'setting',
      },
    ];
    this.buttons = buttonsBTN;
  }
  setServiceButtons(): void {
    const buttonsBTN: Navigation[] = [
      {
        name: 'Collaborateurs',
      },
      {
        name: 'Projets',
      },
    ];
    this.buttons = buttonsBTN;
  }
  setCRMButtons(): void {
    const buttonsBTN: Navigation[] = [
      {
        name: 'Analyse',
      },
      {
        name: 'Entités',
      },
      {
        name: 'Contacts',
      },
    ];
    this.buttons = buttonsBTN;
  }

  setNull(): void {
    this.buttons = [];
  }
}
