import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { OperationFicheCandidatDocument } from './operation-fiche-candidat-document.model';

/**
 * Indicateur de chargement
 */
export const loadingOperationFicheCandidatDocuments = createAction(
  '[OperationFicheCandidatDocument/API] Loading OperationFicheCandidatDocuments',
  props<{ loading: boolean }>()
);

/**
 * Afficher les difference fenêtre
 */
export const setViewOperationFicheCandidatDocuments = createAction(
  '[OperationFicheCandidatDocument/API] Set View OperationFicheCandidatDocuments',
  props<{ view: string }>()
);

/**
 * Trouver les documents du candidat
 */
export const loadOperationFicheCandidatDocuments = createAction(
  '[OperationFicheCandidatDocument/API] Load OperationFicheCandidatDocuments',
  props<{ operationId: string; ficheId: string; candidatId: string }>()
);
export const loadOperationFicheCandidatDocumentsSuccess = createAction(
  '[OperationFicheCandidatDocument/API] Load OperationFicheCandidatDocuments Success',
  props<{ documents: any[] }>()
);
export const loadOperationFicheCandidatDocumentsFailure = createAction(
  '[OperationFicheCandidatDocument/API] Load OperationFicheCandidatDocument Failure',
  props<{ error: any }>()
);

/**
 * Trouver un document du candidat
 */
export const loadOperationFicheCandidatDocument = createAction(
  '[OperationFicheCandidatDocument/API] Load OperationFicheCandidatDocument',
  props<{
    operationId: string;
    ficheId: string;
    candidatId: string;
    documentId: string;
  }>()
);
export const loadOperationFicheCandidatDocumentSuccess = createAction(
  '[OperationFicheCandidatDocument/API] Load OperationFicheCandidatDocument Success',
  props<{ document: any }>()
);
export const loadOperationFicheCandidatDocumentFailure = createAction(
  '[OperationFicheCandidatDocument/API] Load OperationFicheCandidatDocument Failure',
  props<{ error: any }>()
);

/**
 * Charger des documents au storage du candidat
 */

export const uploadOperationFicheCandidatFiles = createAction(
  '[OperationFicheCandidatDocument/API] Upload OperationFicheCandidat Files',
  props<{
    user: any;
    operationId: string;
    ficheId: string;
    candidatId: string;
    documents: any[];
  }>()
);
export const uploadOperationFicheCandidatFilesSuccess = createAction(
  '[OperationFicheCandidatDocument/API] Upload OperationFicheCandidat Files Success',
  props<{ document: any }>()
);
export const uploadOperationFicheCandidatFilesFailure = createAction(
  '[OperationFicheCandidatDocument/API] Upload OperationFicheCandidat Files Failure',
  props<{ error: any }>()
);

/**
 * Création de nouvelles documents au candidat
 */

export const addOperationFicheCandidatDocument = createAction(
  '[OperationFicheCandidatDocument/API] Add OperationFicheCandidatDocument',
  props<{
    operationId: string;
    ficheId: string;
    candidatId: string;
    document: any;
  }>()
);
export const addOperationFicheCandidatDocumentSuccess = createAction(
  '[OperationFicheCandidatDocument/API] Add OperationFicheCandidatDocument Success',
  props<{ document: any }>()
);
export const addOperationFicheCandidatDocumentFailure = createAction(
  '[OperationFicheCandidatDocument/API] Add OperationFicheCandidatDocument Failure',
  props<{ error: any }>()
);

/**
 * Suppression de document du candidat
 */
export const deleteOperationFicheCandidatDocument = createAction(
  '[OperationFicheCandidatDocument/API] Delete OperationFicheCandidatDocument',
  props<{
    operationId: string;
    ficheId: string;
    candidatId: string;
    documentId: string;
  }>()
);
export const deleteOperationFicheCandidatDocumentSuccess = createAction(
  '[OperationFicheCandidatDocument/API] Delete OperationFicheCandidatDocument Success',
  props<{ success: string }>()
);
export const deleteOperationFicheCandidatDocumentFailure = createAction(
  '[OperationFicheCandidatDocument/API] Delete OperationFicheCandidatDocument Failure',
  props<{ error: any }>()
);

/**
 * Suppression de tous les documents du candidat
 */
export const deleteOperationFicheCandidatDocuments = createAction(
  '[OperationFicheCandidatDocument/API] Delete OperationFicheCandidatDocuments',
  props<{
    operationId: string;
    ficheId: string;
    candidatId: string;
    ids: string[];
  }>()
);
export const deleteOperationFicheCandidatDocumentsSuccess = createAction(
  '[OperationFicheCandidatDocument/API] Delete OperationFicheCandidatDocumentsSuccess',
  props<{ success: string }>()
);
export const deleteOperationFicheCandidatDocumentsFailure = createAction(
  '[OperationFicheCandidatDocument/API] Delete OperationFicheCandidatDocumentsFailure',
  props<{ error: any }>()
);

/**
 * Effacer le store de documents du candidat
 */
export const clearOperationFicheCandidatDocuments = createAction(
  '[OperationFicheCandidatDocument/API] Clear OperationFicheCandidatDocuments'
);
