<ng-container *ngIf="subtitle">
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="22">
      {{ subtitle?.title | carLimitation: 35 }}
    </nz-col>
    <nz-col nzSpan="2">
      <app-button-dropdown
        [isUpdate]="true"
        [isDelete]="true"
        [deleteType]="'Supprimer ce titre ?'"
        (select)="onSelectDropdown($event, subtitle)"
        (click)="$event.stopPropagation()"
      ></app-button-dropdown>
    </nz-col>
  </nz-row>
</ng-container>
