import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'app-missions-form-poste',
  templateUrl: './missions-form-poste.component.html',
  styleUrls: ['./missions-form-poste.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MissionsFormPosteComponent
  implements OnInit, OnDestroy, OnChanges
{
  title: string = 'Mission';
  @Input() mission: any = null;
  @Output() clean = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
  ngOnDestroy(): void {}
  ngOnChanges(changes: SimpleChanges): void {}

  onClean(): void {
    this.clean.emit(true);
  }
}
