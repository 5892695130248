<ng-container *ngIf="participant">
  <nz-ribbon
    [nzText]="ribbonText"
    nzColor="#722ed1"
    nzPlacement="start"
    *ngIf="participant | isfavori : favoris?.data"
    [routerLink]="isParticipantsPage ? ['/participant', participant.id] : null"
    [ngStyle]="{ height: height }"
  >
    <nz-card
      [nzTitle]="participantCardTitle"
      nzHoverable
      [nzExtra]="participantActions"
      *ngIf="participant"
    >
      <div class="participantCard__content">
        <div class="participantCard__title">
          <strong>
            {{ participant | displayName | titlecase | carLimitation : 40 }}
          </strong>
        </div>
        <nz-space nzSize="large"></nz-space>

        <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
          <nz-col nzSpan="24">
            <small
              >Fin d'accompagnement
              {{
                participant?.dateEnd
                  | dateFr : "distanceSuffix"
                  | carLimitation : 30
              }}</small
            >
          </nz-col>
        </nz-row>
        <nz-space nzSize="large"></nz-space>

        <nz-row nzAlign="middle" nzGutter="8" nzJustify="center">
          <nz-col nzSpan="11">
            <nz-tag nzColor="geekblue">{{ participant?.sexe }}</nz-tag>
          </nz-col>
          <nz-col nzSpan="2">
            <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
          </nz-col>
          <nz-col nzSpan="11">
            <nz-tag nzColor="blue">{{
              participant?.age || participant?.age !== "0"
                ? (participant?.age | age)
                : ""
            }}</nz-tag>
          </nz-col>
        </nz-row>

        <!-- <ng-container *ngIf="isCoordonnate">
        <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
          <nz-col nzSpan="8">
            <mat-icon
              class="participantCard__icon"
              nz-tooltip
              nzTooltipPlacement="bottom"
              [nzTooltipTitle]="coordonneesMobile"
              [nzTooltipMouseEnterDelay]="0.15"
              >smartphone</mat-icon
            >
            <ng-template #coordonneesMobile>
              {{
                participant?.informations?.coordonnees?.mobile
                  ? (participant?.informations?.coordonnees?.mobile | phone)
                  : "Non renseigné"
              }}
            </ng-template>
          </nz-col>
          <nz-col nzSpan="8">
            <mat-icon
              class="participantCard__icon"
              nz-tooltip
              nzTooltipPlacement="bottom"
              [nzTooltipTitle]="coordonneesMail"
              [nzTooltipMouseEnterDelay]="0.15"
              >email</mat-icon
            >
            <ng-template #coordonneesMail>
              {{
                participant?.informations?.coordonnees?.email
                  ? (participant?.informations?.coordonnees?.email
                    | carLimitation: 25)
                  : "Non renseigné"
              }}
            </ng-template>
          </nz-col>
          <nz-col nzSpan="8"> </nz-col>
        </nz-row>
      </ng-container> -->
      </div>
    </nz-card>
  </nz-ribbon>

  <nz-card
    [nzTitle]="participantCardTitle"
    nzHoverable
    [nzExtra]="participantActions"
    *ngIf="!(participant | isfavori : favoris?.data)"
    [routerLink]="isParticipantsPage ? ['/participant', participant.id] : null"
  >
    <div class="participantCard__content">
      <div class="participantCard__title">
        <strong>
          {{ participant | displayName | titlecase | carLimitation : 40 }}
        </strong>
      </div>
      <nz-space nzSize="large"></nz-space>

      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col nzSpan="24">
          <small
            >Fin d'accompagnement
            {{
              participant?.dateEnd
                | dateFr : "distanceSuffix"
                | carLimitation : 30
            }}</small
          >
        </nz-col>
      </nz-row>
      <nz-space nzSize="large"></nz-space>

      <nz-row nzAlign="middle" nzGutter="8" nzJustify="center">
        <nz-col nzSpan="11">
          <nz-tag nzColor="geekblue">{{ participant?.sexe }}</nz-tag>
        </nz-col>
        <nz-col nzSpan="2">
          <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
        </nz-col>
        <nz-col nzSpan="11">
          <nz-tag nzColor="blue">{{
            participant?.age || participant?.age !== "0"
              ? (participant?.age | age)
              : ""
          }}</nz-tag>
        </nz-col>
      </nz-row>

      <!-- <ng-container *ngIf="isCoordonnate">
      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col nzSpan="8">
          <mat-icon
            class="participantCard__icon"
            nz-tooltip
            nzTooltipPlacement="bottom"
            [nzTooltipTitle]="coordonneesMobile"
            [nzTooltipMouseEnterDelay]="0.15"
            >smartphone</mat-icon
          >
          <ng-template #coordonneesMobile>
            {{
              participant?.informations?.coordonnees?.mobile
                ? (participant?.informations?.coordonnees?.mobile | phone)
                : "Non renseigné"
            }}
          </ng-template>
        </nz-col>
        <nz-col nzSpan="8">
          <mat-icon
            class="participantCard__icon"
            nz-tooltip
            nzTooltipPlacement="bottom"
            [nzTooltipTitle]="coordonneesMail"
            [nzTooltipMouseEnterDelay]="0.15"
            >email</mat-icon
          >
          <ng-template #coordonneesMail>
            {{
              participant?.informations?.coordonnees?.email
                ? (participant?.informations?.coordonnees?.email
                  | carLimitation: 25)
                : "Non renseigné"
            }}
          </ng-template>
        </nz-col>
        <nz-col nzSpan="8"> </nz-col>
      </nz-row>
    </ng-container> -->
    </div>
  </nz-card>

  <ng-template #participantCard__hover> </ng-template>

  <ng-template #preReferent
    ><i nz-icon nzType="user" class="iconCoordonnees"></i
  ></ng-template>

  <ng-template #referentAvatar>
    <app-avatar-group
      [source]="[participant?.referents]"
      [limit]="8"
    ></app-avatar-group>
  </ng-template>

  <ng-template #participantActions>
    <button
      nz-button
      nzShape="circle"
      nzType="text"
      nz-dropdown
      [nzDropdownMenu]="participantActionsMenu"
      nzPlacement="bottomRight"
      nzTrigger="click"
      (click)="$event.stopPropagation()"
    >
      <mat-icon>more_vert</mat-icon>
    </button>
  </ng-template>

  <nz-dropdown-menu #participantActionsMenu="nzDropdownMenu">
    <ul nz-menu *ngIf="isParticipantsPage">
      <li
        nz-menu-item
        (click)="$event.stopPropagation(); onExportSelect(participant)"
      >
        <i nz-icon nzType="download"></i>
        <span class="item-dropdown">Exporter la fiche</span>
      </li>
      <li nz-menu-divider></li>
      <li
        nz-menu-item
        *ngIf="!(participant | isfavori : favoris?.data)"
        (click)="$event.stopPropagation(); onAddToFavori(participant)"
      >
        <i nz-icon nzType="star"></i>
        <span class="item-dropdown">Ajouter aux favoris</span>
      </li>
      <li
        nz-menu-item
        *ngIf="participant | isfavori : favoris?.data"
        (click)="$event.stopPropagation(); onRemoveToFavori(participant)"
      >
        <i nz-icon nzType="star"></i>
        <span class="item-dropdown">Retirer des favoris</span>
      </li>
      <li nz-menu-divider></li>

      <li
        nz-menu-item
        nzDanger
        nz-popconfirm
        [nzPopconfirmTitle]="'Supprimer le participant ?'"
        nzPopconfirmPlacement="bottom"
        (nzOnConfirm)="onDelete(participant)"
        (nzOnCancel)="onCancel()"
      >
        <i nz-icon nzType="delete"></i>
        <span class="item-dropdown">Supprimer</span>
      </li>
    </ul>
    <ul nz-menu *ngIf="!isParticipantsPage">
      <li
        nz-menu-item
        (click)="$event.stopPropagation(); onAction('export', participant)"
      >
        <i nz-icon nzType="download"></i>
        <span class="item-dropdown">Exporter la participation</span>
      </li>
      <li nz-menu-divider></li>
      <li
        nz-menu-item
        nzDanger
        nz-popconfirm
        [nzPopconfirmTitle]="'Retirer le participant ?'"
        nzPopconfirmPlacement="bottom"
        (nzOnConfirm)="onAction('delete', participant)"
        (nzOnCancel)="onCancel()"
      >
        <i nz-icon nzType="delete"></i>
        <span class="item-dropdown">Retirer</span>
      </li>
    </ul>
  </nz-dropdown-menu>

  <ng-template #ribbonText>
    <i nz-icon nzType="star"></i>
  </ng-template>

  <ng-template #participantCardTitle>
    <app-header-title [icon]="'supervisor_account'"></app-header-title>
  </ng-template>
  <ng-template #exportTitleForm>
    <app-header-title
      [icon]="'download'"
      [title]="titleDownload"
    ></app-header-title>
  </ng-template>
</ng-container>
