import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Observable, of } from 'rxjs';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-loading-animation',
  templateUrl: './loading-animation.component.html',
  styleUrls: ['./loading-animation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingAnimationComponent implements OnInit, OnChanges {
  options: AnimationOptions = {
    path: '../assets/animations/loading_animation_v2.json',
    loop: true,
    autoplay: true,
  };

  lottie: Partial<CSSStyleDeclaration> = {
    marginBottom: '-20rem !important',
  };

  animationCreated(animationItem: AnimationItem): void {}

  @Input() loading$: Observable<boolean> = of(false);

  constructor() {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {}
}
