import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PersonComponent } from './components/person/person.component';
import { StoreModule } from '@ngrx/store';
import * as fromPerson from './store/person.reducer';
import { EffectsModule } from '@ngrx/effects';
import { PersonEffects } from './store/person.effects';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CommunesFranceModule } from 'src/app/contents/components/communes-france/communes-france.module';
import { HeaderModule } from 'src/app/contents/components/header-title/header.module';
import { CoordonneesModule } from 'src/app/contents/components/coordonnees/coordonnees.module';
import { UtilitiesModule } from 'src/app/contents/components/utilities/utilities.module';
import { EntiteSearchModule } from 'src/app/contents/components/entite-search/entite-search.module';
import { PersonFormComponent } from './components/person-form/person-form.component';
import { NoResultModule } from 'src/app/contents/components/no-result/no-result.module';
import { PipeModule } from 'src/app/pipe/pipe.module';
import { PersonItemComponent } from './components/person-item/person-item.component';

@NgModule({
  declarations: [PersonComponent, PersonFormComponent, PersonItemComponent],
  exports: [PersonComponent, PersonFormComponent, PersonItemComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    CommunesFranceModule,
    HeaderModule,
    CoordonneesModule,
    UtilitiesModule,
    EntiteSearchModule,
    NoResultModule,
    PipeModule,
    StoreModule.forFeature(fromPerson.personsFeatureKey, fromPerson.reducer),
    EffectsModule.forFeature([PersonEffects]),
  ],
})
export class PersonModule {}
