import { EMPTY, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { OperationState } from '../components/operation/store/operation.reducer';
import * as fromOperationAction from '../components/operation/store/operation.actions';
import * as fromOperationSelector from '../components/operation/store/operation.selectors';
import { OperationSmallId } from '../components/operation/store/operation.model';

@Injectable({
  providedIn: 'any',
})
export class OperationsResolverService {
  constructor(
    private operationStore: Store<OperationState>,
    private router: Router
  ) {}

  resolve(
    route: ActivatedRouteSnapshot
  ):
    | Observable<OperationSmallId[]>
    | Promise<OperationSmallId[] | any>
    | OperationSmallId[]
    | any {
    let find = this.loadOperations().toPromise();

    return find
      .then(() => {
        return this.getOperations();
      })
      .catch(() => {
        this.router.navigate(['/projets']);
        return EMPTY;
      });
  }

  getOperations(): Observable<OperationSmallId | any> {
    return this.operationStore.select(fromOperationSelector.operations);
  }

  loadOperations(): Observable<any> {
    return of(
      this.operationStore.dispatch(fromOperationAction.loadOperations())
    );
  }
}
