import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Dashboard } from './dashboard.model';

export const loadDashboardsEntites = createAction(
  '[Dashboard/API] Load Dashboards Entites',
  props<{ year: string }>()
);

export const loadDashboardsEntitesSuccess = createAction(
  '[Dashboard/API] Load Dashboards Entites Success',
  props<{ statistiques: any }>()
);

export const loadDashboardsEntitesFailure = createAction(
  '[Dashboard/API] Load Dashboards Entites Failure',
  props<{ error: any }>()
);

export const loadDashboardsContacts = createAction(
  '[Dashboard/API] Load Dashboards Contacts',
  props<{ year: string }>()
);

export const loadDashboardsContactsSuccess = createAction(
  '[Dashboard/API] Load Dashboards Contacts Success',
  props<{ statistiques: any }>()
);

export const loadDashboardsContactsFailure = createAction(
  '[Dashboard/API] Load Dashboards Contacts Failure',
  props<{ error: any }>()
);

export const loadDashboardsParticipants = createAction(
  '[Dashboard/API] Load Dashboards Participants',
  props<{ year: string }>()
);

export const loadDashboardsParticipantsSuccess = createAction(
  '[Dashboard/API] Load Dashboards Participants Success',
  props<{ statistiques: any }>()
);

export const loadDashboardsParticipantsFailure = createAction(
  '[Dashboard/API] Load Dashboards Participants Failure',
  props<{ error: any }>()
);

export const loadDashboardsProjets = createAction(
  '[Dashboard/API] Load Dashboards Projets',
  props<{ year: string }>()
);

export const loadDashboardsProjetsSuccess = createAction(
  '[Dashboard/API] Load Dashboards Projets Success',
  props<{ statistiques: any }>()
);

export const loadDashboardsProjetsFailure = createAction(
  '[Dashboard/API] Load Dashboards Projets Failure',
  props<{ error: any }>()
);

export const deleteDashboards = createAction(
  '[Dashboard/API] Delete Dashboards',
  props<{ ids: string[] }>()
);

//SELECTORS
export const selectDashboards = createAction(
  '[Dashboard/COMPOENENT] Select Dashboards',
  props<{ select: string }>()
);

export const clearDashboards = createAction('[Dashboard/API] Clear Dashboards');
