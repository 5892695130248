import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Update } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { add, fromUnixTime } from 'date-fns';
import { Timestamp } from 'firebase/firestore';
import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';
import { UtilitiesState } from '../utilities/store/utilities.reducer';
import * as fromUtilityAction from 'src/app/contents/components/utilities/store/utilities.actions';
import * as fromUtilitySelector from 'src/app/contents/components/utilities/store/utilities.selectors';
import { serverTimestamp } from '@angular/fire/firestore';
import { fade } from 'src/app/app-animation';

@Component({
  selector: 'app-item-operations-intervention',
  templateUrl: './item-operations-intervention.component.html',
  styleUrls: ['./item-operations-intervention.component.scss'],
  animations: [fade],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemOperationsInterventionComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() operation$ = new BehaviorSubject<any>(null);
  @Input() identity: string = 'participant';
  @Input() header: boolean = true;
  @Output() back = new EventEmitter<boolean>(false);
  @Output() update = new EventEmitter<any>(false);
  @Output() delete = new EventEmitter<any>(false);
  isFormChanges: boolean = false;
  subscribe = new Subject();
  dateFormat: string = 'dd/MM/yyyy';
  tabSetColor: any = {
    'background-color': '#f5f5f5',
    color: 'grey',
  };
  currentSelected: number = 0;
  popoverDelay: number = 0.5;
  radioValue: any = null;

  utilitySortie$: Observable<any> = of(null);
  loading$: Observable<any> = of(null);
  definitionInscription: string = 'Intérêt pour le projet.';
  definitionDesinscription: string = 'Désintéret pour le projet proposé.';
  definitionIntegration: string =
    'Confirmation de la participant au projet proposé.';
  definitionSortie: string = 'Sortie du projet.';

  interventionForm: UntypedFormGroup = this.fb.group({});
  role: UntypedFormControl = new UntypedFormControl('');
  isInscription: UntypedFormControl = new UntypedFormControl(false);
  isParticipant: UntypedFormControl = new UntypedFormControl(false);
  isSortie: UntypedFormControl = new UntypedFormControl(false);
  isDesinscription: UntypedFormControl = new UntypedFormControl(false);
  isInMission: UntypedFormControl = new UntypedFormControl(false);
  isParrain: UntypedFormControl = new UntypedFormControl(false);
  isFinanceur: UntypedFormControl = new UntypedFormControl(false);
  isPrescripteur: UntypedFormControl = new UntypedFormControl(false);
  isSupport: UntypedFormControl = new UntypedFormControl(false);
  isTitulaire: UntypedFormControl = new UntypedFormControl(false);
  isSousTraitant: UntypedFormControl = new UntypedFormControl(false);
  isCoTraitant: UntypedFormControl = new UntypedFormControl(false);

  isAutre: UntypedFormControl = new UntypedFormControl(false);
  missions: UntypedFormControl = new UntypedFormControl([]);
  modules: UntypedFormControl = new UntypedFormControl([]);
  actions: UntypedFormControl = new UntypedFormControl([]);
  projetpro: UntypedFormControl = new UntypedFormControl([]);
  participants: UntypedFormControl = new UntypedFormControl([]);
  dateInscription: UntypedFormControl = new UntypedFormControl();
  dateIntegration: UntypedFormControl = new UntypedFormControl();
  dateSortie: UntypedFormControl = new UntypedFormControl();
  dateUpdate: UntypedFormControl = new UntypedFormControl();
  dateEndIntervention: UntypedFormControl = new UntypedFormControl();
  dateStartIntervention: UntypedFormControl = new UntypedFormControl();
  durationIntervention: UntypedFormControl = new UntypedFormControl();
  isAssistance: UntypedFormControl = new UntypedFormControl(false);
  isControl: UntypedFormControl = new UntypedFormControl();
  isPilotage: UntypedFormControl = new UntypedFormControl();
  isFinished: UntypedFormControl = new UntypedFormControl();
  duration: UntypedFormControl = new UntypedFormControl();
  motifSorties: UntypedFormControl = new UntypedFormControl();
  typeSorties: UntypedFormControl = new UntypedFormControl();
  yearString: UntypedFormControl = new UntypedFormControl();
  monthString: UntypedFormControl = new UntypedFormControl();
  yearNumber: UntypedFormControl = new UntypedFormControl();
  monthNumber: UntypedFormControl = new UntypedFormControl();
  userUpdate: UntypedFormControl = new UntypedFormControl();
  sortiProgrammeQualitatif: UntypedFormControl = new UntypedFormControl('');
  titulaire: UntypedFormControl = new UntypedFormControl('');
  auteur: UntypedFormControl = new UntypedFormControl('');
  autrePrecision: UntypedFormControl = new UntypedFormControl('');
  hoursBenevolat: UntypedFormControl = new UntypedFormControl('');

  constructor(
    private fb: UntypedFormBuilder,
    private utilityStore: Store<UtilitiesState>
  ) {}

  ngOnInit(): void {
    this.form();
    this.getUtilitiesSortie();
    this.loadUtilitySortie();
    this.getUtilitiesLoading();
  }

  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  form(): void {
    this.formInit();
    this.formCreate();
    this.formSet();
    this.formChanges();
    this.onDurationChanges();
  }
  formInit(): void {
    this.role = new UntypedFormControl('');
    this.isInscription = new UntypedFormControl(false);
    this.isParticipant = new UntypedFormControl(false);
    this.isSortie = new UntypedFormControl(false);
    this.isDesinscription = new UntypedFormControl(false);
    this.isInMission = new UntypedFormControl(false);
    this.isParrain = new UntypedFormControl(false);
    this.isFinanceur = new UntypedFormControl(false);
    this.isPrescripteur = new UntypedFormControl(false);
    this.isSupport = new UntypedFormControl(false);
    this.isTitulaire = new UntypedFormControl(false);
    this.isSousTraitant = new UntypedFormControl(false);
    this.isCoTraitant = new UntypedFormControl(false);
    this.durationIntervention = new UntypedFormControl();
    this.isControl = new UntypedFormControl(false);
    this.isAutre = new UntypedFormControl(false);
    this.isPilotage = new UntypedFormControl(false);
    this.isAssistance = new UntypedFormControl(false);
    this.isFinished = new UntypedFormControl(false);
    this.titulaire = new UntypedFormControl();
    this.missions = new UntypedFormControl([]);
    this.modules = new UntypedFormControl([]);
    this.actions = new UntypedFormControl([]);
    this.projetpro = new UntypedFormControl([]);
    this.participants = new UntypedFormControl([]);
    this.dateInscription = new UntypedFormControl();
    this.dateIntegration = new UntypedFormControl();
    this.dateEndIntervention = new UntypedFormControl();
    this.dateStartIntervention = new UntypedFormControl();
    this.dateSortie = new UntypedFormControl();
    this.dateUpdate = new UntypedFormControl(
      { value: '', disabled: true },
      Validators.required
    );

    this.duration = new UntypedFormControl();
    this.motifSorties = new UntypedFormControl();
    this.typeSorties = new UntypedFormControl();
    this.yearString = new UntypedFormControl();
    this.monthString = new UntypedFormControl();
    this.yearNumber = new UntypedFormControl();
    this.monthNumber = new UntypedFormControl();
    this.userUpdate = new UntypedFormControl();
    this.sortiProgrammeQualitatif = new UntypedFormControl('');
    this.auteur = new UntypedFormControl();
    this.autrePrecision = new UntypedFormControl('');
    this.hoursBenevolat = new UntypedFormControl();
  }
  formCreate(): void {
    this.interventionForm = this.fb.group({
      role: this.role,
      isInscription: this.isInscription,
      isParticipant: this.isParticipant,
      isSortie: this.isSortie,
      isDesinscription: this.isDesinscription,
      isInMission: this.isInMission,
      isParrain: this.isParrain,
      isFinanceur: this.isFinanceur,
      isPrescripteur: this.isPrescripteur,
      isSupport: this.isSupport,
      isTitulaire: this.isTitulaire,
      isSousTraitant: this.isSousTraitant,
      isCoTraitant: this.isCoTraitant,
      hoursBenevolat: this.hoursBenevolat,
      isControl: this.isControl,
      isPilotage: this.isPilotage,
      isAssistance: this.isAssistance,
      isFinished: this.isFinished,
      isAutre: this.isAutre,
      titulaire: this.titulaire,
      missions: this.missions,
      modules: this.modules,
      actions: this.actions,
      projetpro: this.projetpro,
      participants: this.participants,
      dateInscription: this.dateInscription,
      dateIntegration: this.dateIntegration,
      dateSortie: this.dateSortie,
      dateUpdate: this.dateUpdate,
      dateEndIntervention: this.dateEndIntervention,
      dateStartIntervention: this.dateStartIntervention,
      duration: this.duration,
      motifSorties: this.motifSorties,
      typeSorties: this.typeSorties,
      yearString: this.yearString,
      monthString: this.monthString,
      yearNumber: this.yearNumber,
      monthNumber: this.monthNumber,
      userUpdate: this.userUpdate,
      sortiProgrammeQualitatif: this.sortiProgrammeQualitatif,
      auteur: this.auteur,
      autrePrecision: this.autrePrecision,
      durationIntervention: this.durationIntervention,
    });
  }
  formSet(): void {
    this.operation$.pipe(takeUntil(this.subscribe)).subscribe((item) => {
      if (!item) return;
      const { intervention } = item;
      const dateInscription = intervention?.dateInscription
        ? fromUnixTime(intervention.dateInscription['seconds'])
        : null;
      const dateIntegration = intervention?.dateIntegration
        ? fromUnixTime(intervention.dateIntegration['seconds'])
        : null;
      const dateSortie = intervention?.dateSortie
        ? fromUnixTime(intervention.dateSortie['seconds'])
        : null;
      const dateUpdate = intervention?.dateUpdate
        ? fromUnixTime(intervention.dateUpdate['seconds'])
        : new Date(Date.now());

      const dateEndIntervention = intervention?.dateEndIntervention
        ? fromUnixTime(intervention.dateEndIntervention['seconds'])
        : new Date(Date.now());

      const dateStartIntervention = intervention?.dateStartIntervention
        ? fromUnixTime(intervention.dateStartIntervention['seconds'])
        : new Date(Date.now());
      const sortiProgrammeQualitatif = intervention?.sortiProgrammeQualitatif
        ? intervention.sortiProgrammeQualitatif
        : null;

      this.interventionForm.setValue({
        ...intervention,
        dateInscription: dateInscription,
        dateIntegration: dateIntegration,
        dateSortie: dateSortie,
        dateUpdate: dateUpdate,
        dateEndIntervention: dateEndIntervention,
        dateStartIntervention: dateStartIntervention,
        sortiProgrammeQualitatif: sortiProgrammeQualitatif,
      });

      this.onUpdateRole(intervention);
    });
  }

  formChanges(): void {
    this.interventionForm.valueChanges.subscribe((val) => {
      this.isFormChanges = true;
    });
  }

  hide_modification_validation(): void {
    this.isFormChanges = false;
  }

  onDurationChanges(): void {
    this.interventionForm
      .get('durationIntervention')
      ?.valueChanges.subscribe((val) => {
        if (!val || val === 0) {
        } else {
          const start = this.interventionForm.get(
            'dateStartIntervention'
          )?.value;
          this.interventionForm.patchValue({
            dateEndIntervention: add(start, { months: val }),
          });
        }
      });
  }

  compareSortie(obj1: any, obj2: any) {
    return obj1 && obj2 && obj1.motif === obj2.motif;
  }

  getUtilitiesLoading(): void {
    this.loading$ = this.utilityStore.select(fromUtilitySelector.loading);
  }

  getUtilitiesSortie(): void {
    this.utilitySortie$ = this.utilityStore.select(fromUtilitySelector.sortie);
  }

  loadUtilitySortie(): void {
    this.utilityStore.dispatch(fromUtilityAction.loadSortieReferentiel());
  }

  selectedIndexChange(index: number): void {
    this.currentSelected = index;
  }

  selectStatusChange(event: boolean, type: string): void {
    switch (type) {
      case 'inscription':
        this.interventionForm.patchValue({
          isInscription: event,
        });

        break;
      case 'integration':
        this.interventionForm.patchValue({
          isParticipant: event,
        });

        break;
      case 'desinscription':
        this.interventionForm.patchValue({
          isDesinscription: event,
        });

        break;
      case 'sortie':
        this.interventionForm.patchValue({
          isSortie: event,
        });

        break;
      default:
        break;
    }
  }

  onSetRole(event: any): void {
    this.radioValue = event;
    switch (event) {
      case 'financeur':
        this.interventionForm.patchValue({
          isFinanceur: true,
          isParrain: false,
          isPrescripteur: false,
          isSupport: false,
          isTitulaire: false,
          isSousTraitant: false,
          isCoTraitant: false,

          isPilotage: false,
          isAssistance: false,
          isControl: false,
          isAutre: false,
          autrePrecision: '',
          titulaire: null,
        });
        break;
      case 'prescripteur':
        this.interventionForm.patchValue({
          isFinanceur: false,
          isParrain: false,
          isPrescripteur: true,
          isSupport: false,
          isTitulaire: false,
          isSousTraitant: false,
          isPilotage: false,
          isAssistance: false,
          isControl: false,
          isAutre: false,
          isCoTraitant: false,
          autrePrecision: '',
          titulaire: null,
        });
        break;
      case 'support':
        this.interventionForm.patchValue({
          isFinanceur: false,
          isParrain: false,
          isPrescripteur: false,
          isSupport: true,
          isTitulaire: false,
          isSousTraitant: false,
          isPilotage: false,
          isAssistance: false,
          isControl: false,
          isAutre: false,
          isCoTraitant: false,

          autrePrecision: '',
          titulaire: null,
        });

        break;
      case 'titulaire':
        this.interventionForm.patchValue({
          isFinanceur: false,
          isParrain: false,
          isPrescripteur: false,
          isSupport: false,
          isTitulaire: true,
          isSousTraitant: false,
          isPilotage: false,
          isAssistance: false,
          isControl: false,
          isAutre: false,
          isCoTraitant: false,

          autrePrecision: '',
        });

        break;
      case 'parrainage':
        this.interventionForm.patchValue({
          isFinanceur: false,
          isParrain: true,
          isPrescripteur: false,
          isSupport: false,
          isTitulaire: false,
          isSousTraitant: false,
          isPilotage: false,
          isAssistance: false,
          isControl: false,
          isAutre: false,
          isCoTraitant: false,

          autrePrecision: '',
          titulaire: null,
        });

        break;
      case 'sousTraitant':
        this.interventionForm.patchValue({
          isFinanceur: false,
          isParrain: false,
          isPrescripteur: false,
          isSupport: false,
          isTitulaire: false,
          isSousTraitant: true,
          isPilotage: false,
          isAssistance: false,
          isControl: false,
          isAutre: false,
          isCoTraitant: false,

          autrePrecision: '',
          titulaire: null,
        });

        break;
      case 'pilotage':
        this.interventionForm.patchValue({
          isFinanceur: false,
          isParrain: false,
          isPrescripteur: false,
          isSupport: false,
          isTitulaire: false,
          isSousTraitant: false,
          isPilotage: true,
          isAssistance: false,
          isControl: false,
          isAutre: false,
          isCoTraitant: false,

          autrePrecision: '',
          titulaire: null,
        });

        break;
      case 'assistance':
        this.interventionForm.patchValue({
          isFinanceur: false,
          isParrain: false,
          isPrescripteur: false,
          isSupport: false,
          isTitulaire: false,
          isSousTraitant: false,
          isPilotage: false,
          isAssistance: true,
          isControl: false,
          isAutre: false,
          isCoTraitant: false,

          autrePrecision: '',
          titulaire: null,
        });

        break;
      case 'control':
        this.interventionForm.patchValue({
          isFinanceur: false,
          isParrain: false,
          isPrescripteur: false,
          isSupport: false,
          isTitulaire: false,
          isSousTraitant: false,
          isPilotage: false,
          isAssistance: false,
          isControl: true,
          isAutre: false,
          isCoTraitant: false,

          autrePrecision: '',
          titulaire: null,
        });

        break;
      case 'cotraitant':
        this.interventionForm.patchValue({
          isFinanceur: false,
          isParrain: false,
          isPrescripteur: false,
          isSupport: false,
          isTitulaire: false,
          isSousTraitant: false,
          isPilotage: false,
          isAssistance: false,
          isControl: false,
          isCoTraitant: true,
          isAutre: false,
          autrePrecision: '',
          titulaire: null,
        });

        break;
      case 'autre':
        this.interventionForm.patchValue({
          isFinanceur: false,
          isParrain: false,
          isPrescripteur: false,
          isSupport: false,
          isTitulaire: false,
          isSousTraitant: false,
          isPilotage: false,
          isAssistance: false,
          isControl: false,
          isCoTraitant: false,

          isAutre: true,
          autrePrecision: '',
          titulaire: null,
        });

        break;
      default:
        break;
    }
  }

  onUpdateRole(event: any): void {
    if (event?.isFinanceur) {
      this.radioValue = 'financeur';
    }

    if (event?.isParrain) {
      this.radioValue = 'parrainage';
    }

    if (event?.isPrescripteur) {
      this.radioValue = 'prescripteur';
    }

    if (event?.isSupport) {
      this.radioValue = 'support';
    }

    if (event?.isTitulaire) {
      this.radioValue = 'titulaire';
    }

    if (event?.isSousTraitant) {
      this.radioValue = 'soustraitant';
    }

    if (event?.isPilotage) {
      this.radioValue = 'pilotage';
    }

    if (event?.isAssistance) {
      this.radioValue = 'assistance';
    }

    if (event?.isControl) {
      this.radioValue = 'control';
    }

    if (event?.isAutre) {
      this.radioValue = 'autre';
    }
  }

  onBack(): void {
    this.back.emit(true);
  }

  onUpdate(item: any): void {
    if (!item) return;
    if (!this.interventionForm.valid) return;

    const valueForm = this.interventionForm.value;

    const dateInscription = valueForm?.dateInscription
      ? Timestamp.fromDate(valueForm.dateInscription)
      : null;
    const dateIntegration = valueForm?.dateIntegration
      ? Timestamp.fromDate(valueForm.dateIntegration)
      : null;
    const dateSortie = valueForm?.dateSortie
      ? Timestamp.fromDate(valueForm.dateSortie)
      : null;

    const dateEndIntervention = valueForm?.dateEndIntervention
      ? Timestamp.fromDate(valueForm.dateEndIntervention)
      : null;

    const dateStartIntervention = valueForm?.dateStartIntervention
      ? Timestamp.fromDate(valueForm.dateStartIntervention)
      : null;

    const interventionChanges = {
      ...item.intervention,
      ...valueForm,
      dateInscription: dateInscription,
      dateIntegration: dateIntegration,
      dateSortie: dateSortie,
      dateEndIntervention: dateEndIntervention,
      dateStartIntervention: dateStartIntervention,
      dateUpdate: serverTimestamp(),
    };

    const operation: Update<any> = {
      id: item.id,
      changes: {
        operation: item.operation,
        intervention: interventionChanges,
      },
    };

    this.update.emit(operation);
  }
  onDelete(item: any): void {
    if (!item) return;
    this.delete.emit(item);
    this.onBack();
  }
  onCancel(): void {}
}
