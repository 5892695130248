import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { User } from './user.model';
import * as UserActions from './user.actions';

export const usersFeatureKey = 'users';

export interface UserState extends EntityState<any[]> {
  team: any;
  user: any;
  userSelected: any;
  utilities: any[];
  statistiques: any;
  favorisEntites: any;
  favorisContacts: any;
  favorisParticipants: any;
  loading: boolean;
  civilities: string[];
  error: any;
}

export const adapter: EntityAdapter<any[]> = createEntityAdapter<any[]>();

export const initialState: UserState = adapter.getInitialState({
  team: undefined,
  user: undefined,
  userSelected: undefined,
  utilities: [],
  statistiques: undefined,
  favorisEntites: undefined,
  favorisContacts: undefined,
  favorisParticipants: undefined,
  loading: false,
  civilities: [],
  error: undefined,
});

export const reducer = createReducer(
  initialState,
  //ME
  on(UserActions.loadMe, (state, action) => {
    return {
      ...state,
      loading: true,
      user: undefined,
    };
  }),
  on(UserActions.loadMeSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      user: action.user,
    };
  }),
  on(UserActions.loadMeFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
      user: undefined,
    };
  }),

  //TEAM
  on(UserActions.loadTeam, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(UserActions.loadTeamSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      team: action.team,
    };
  }),
  on(UserActions.loadTeamFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
      team: undefined,
    };
  }),

  //ALL USERS
  on(UserActions.loadUsers, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(UserActions.loadUsersSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      team: action.users,
    };
  }),
  on(UserActions.loadUsersFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //CIVILITIES
  on(UserActions.loadCivilitiesSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      civilities: action.civilities,
    };
  }),
  on(UserActions.loadCivilitiesFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //STATISTIQUES
  on(UserActions.loadUserStatisquesSuccess, (state, action) => {
    return {
      ...state,
      statistiques: action.statistiques,
    };
  }),
  on(UserActions.loadUserStatisquesFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //ADD
  on(UserActions.addUser, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(UserActions.addUserSuccess, (state, action) =>
    adapter.addOne(action.user, state)
  ),
  on(UserActions.addUserFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  }),

  //UPDATE
  on(UserActions.updateUser, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(UserActions.updateUserSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
    };
  }),
  on(UserActions.updateUserFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  }),

  //DELETE
  on(UserActions.deleteUser, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(UserActions.deleteUserSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
    };
  }),
  on(UserActions.deleteUserFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  }),

  //FAVORIS ENTITES
  on(UserActions.loadUserFavorisEntitesSuccess, (state, action) => {
    return {
      ...state,
      favorisEntites: action.favoris,
    };
  }),
  on(UserActions.loadUserFavorisEntitesFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  on(UserActions.addUserFavorisEntitesSuccess, (state, action) => {
    return {
      ...state,
      favorisEntites: action.favoris,
    };
  }),
  on(UserActions.addUserFavorisEntitesFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  on(UserActions.removeUserFavorisEntitesSuccess, (state, action) => {
    return {
      ...state,
      favorisEntites: action.favoris,
    };
  }),
  on(UserActions.removeUserFavorisEntitesFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //FAVORIS CONTACTS
  on(UserActions.loadUserFavorisContactsSuccess, (state, action) => {
    return {
      ...state,
      favorisContacts: action.favoris,
    };
  }),
  on(UserActions.loadUserFavorisContactsFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  on(UserActions.addUserFavorisContactSuccess, (state, action) => {
    return {
      ...state,
      favorisContacts: action.favoris,
    };
  }),
  on(UserActions.addUserFavorisContactFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  on(UserActions.removeUserFavorisContactSuccess, (state, action) => {
    return {
      ...state,
      favorisContacts: action.favoris,
    };
  }),
  on(UserActions.removeUserFavorisContactFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //FAVORIS PARTICIPANTS
  on(UserActions.loadUserFavorisParticipantsSuccess, (state, action) => {
    return {
      ...state,
      favorisParticipants: action.favoris,
    };
  }),
  on(UserActions.loadUserFavorisParticipantsFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  on(UserActions.addUserFavorisParticipantSuccess, (state, action) => {
    return {
      ...state,
      favorisParticipants: action.favoris,
    };
  }),
  on(UserActions.addUserFavorisParticipantFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  on(UserActions.removeUserFavorisParticipantSuccess, (state, action) => {
    return {
      ...state,
      favorisParticipants: action.favoris,
    };
  }),
  on(UserActions.removeUserFavorisParticipantFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  on(UserActions.clearUsers, (state) => adapter.removeAll(state))
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
