<form nz-form [formGroup]="postureForm" nzLayout="vertical">
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="12">
      <app-check-card
        [title]="'Incapacité'"
        [isActive]="postureForm.value.incapaciteAoccuperUnPoste"
        [definition]="incapaciteAoccuperUnPosteTitle"
        [height]="'60px'"
        [fontSize]="'14px'"
        (select)="checkboxChanges($event, 'incapaciteAoccuperUnPoste')"
      ></app-check-card>
    </nz-col>
    <nz-col nzSpan="12">
      <app-check-card
        [title]="'Besoin d\'accompagnement'"
        [isActive]="postureForm.value.aBesoinDaccompagnement"
        [definition]="aBesoinDaccompagnementTitle"
        [height]="'60px'"
        [fontSize]="'14px'"
        (select)="checkboxChanges($event, 'aBesoinDaccompagnement')"
      ></app-check-card>
    </nz-col>
  </nz-row>
  <nz-space [nzSize]="'small'"></nz-space>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="12">
      <app-check-card
        [title]="'Peut occuper avec condition'"
        [isActive]="postureForm.value.peutOccuperUnPosteAvecCondition"
        [definition]="peutOccuperUnPosteAvecConditionTitle"
        (select)="checkboxChanges($event, 'peutOccuperUnPosteAvecCondition')"
        [height]="'60px'"
        [fontSize]="'14px'"
      ></app-check-card>
    </nz-col>
    <nz-col nzSpan="12">
      <app-check-card
        [title]="'Peut occuper un poste'"
        [isActive]="postureForm.value.peutOccuperUnPosteSansCondition"
        [definition]="peutOccuperUnPosteSansConditionTitle"
        (select)="checkboxChanges($event, 'peutOccuperUnPosteSansCondition')"
        [height]="'60px'"
        [fontSize]="'14px'"
      ></app-check-card>
    </nz-col>
  </nz-row>

  <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="24">
      <nz-form-item>
        <nz-form-label>Précision</nz-form-label>
        <nz-form-control>
          <textarea
            nz-input
            formControlName="precision"
            [nzAutosize]="{ minRows: 2, maxRows: 2 }"
            nzBorderless
            placeholder="Précisez la capacité à occuper un poste de travail"
          >
          </textarea>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>

  <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="24">
      <nz-form-item>
        <nz-form-control>
          <label nz-checkbox formControlName="isFrein">{{
            isFreinTitle
          }}</label>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>
</form>
