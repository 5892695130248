import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Dashboard } from './dashboard.model';
import * as DashboardActions from './dashboard.actions';

export const dashboardsFeatureKey = 'dashboards';

export interface DashboardState extends EntityState<any> {
  entitesStatistique: any;
  contactsStatistique: any;
  participantsStatistique: any;
  projetsStatistique: any;
  select: string;
  error: any;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>();

export const initialState: DashboardState = adapter.getInitialState({
  entitesStatistique: undefined,
  contactsStatistique: undefined,
  participantsStatistique: undefined,
  projetsStatistique: undefined,
  select: 'projets',
  error: undefined,
});

export const reducer = createReducer(
  initialState,
  on(DashboardActions.loadDashboardsEntitesSuccess, (state, action) => {
    return {
      ...state,
      entitesStatistique: action.statistiques,
    };
  }),
  on(DashboardActions.loadDashboardsEntitesFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  on(DashboardActions.loadDashboardsContactsSuccess, (state, action) => {
    return {
      ...state,
      contactsStatistique: action.statistiques,
    };
  }),
  on(DashboardActions.loadDashboardsContactsFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  on(DashboardActions.loadDashboardsParticipantsSuccess, (state, action) => {
    return {
      ...state,
      participantsStatistique: action.statistiques,
    };
  }),
  on(DashboardActions.loadDashboardsParticipantsFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  on(DashboardActions.loadDashboardsProjetsSuccess, (state, action) => {
    return {
      ...state,
      projetsStatistique: action.statistiques,
    };
  }),
  on(DashboardActions.loadDashboardsProjetsFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  on(DashboardActions.clearDashboards, (state) => adapter.removeAll(state)),

  //SELECTORS
  on(DashboardActions.selectDashboards, (state, action) => {
    return {
      ...state,
      select: action.select,
    };
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
