<ng-container *ngIf="user">
  <!-- <nz-avatar
    [nzText]="!user?.phone ? user?.avatar : ''"
    [nzSrc]="user?.phone ? user?.phone : ''"
    [nzSize]="size"
    [ngStyle]="{
      'background-color': user?.setting?.color
        ? user?.setting?.color
        : avatarBackground,
      color: avatarColor
    }"
    nz-popover
    [(nzPopoverVisible)]="visible"
    (nzPopoverVisibleChange)="onVisibleChange($event)"
    nzPopoverTrigger="click"
    [nzPopoverContent]="userInfo"
    nzPopoverPlacement="bottomRight"
    [class]="
      'hover:shadow-lg hover:shadow-[' +
      user?.setting?.color +
      '] transition ease-in-out delay-75 hover:-translate-y-1 hover:scale-100 duration-300'
    "
  ></nz-avatar> -->

  <ng-template #userInfo>
    <nz-card
      nzBorderless
      [ngClass]="{ dark: theme === 'dark' }"
      [nzActions]="[
        userActionModfied,
        userActionChangePassword,
        userTicket,
        userDisconnect
      ]"
    >
      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col nzSpan="4"
          ><nz-avatar
            [nzText]="!user?.phone ? user?.avatar : ''"
            [nzSrc]="user?.phone ? user?.phone : ''"
            [nzSize]="60"
            [ngStyle]="{
              'background-color': user?.setting?.color
                ? user?.setting?.color
                : avatarBackground,
              color: avatarColor
            }"
          ></nz-avatar
        ></nz-col>
        <nz-col nzSpan="20">
          <nz-row nzAlign="middle" nzGutter="8" nzJustify="start"
            ><h4 nz-typography>{{ user?.displayName }}</h4></nz-row
          >
          <nz-row nzAlign="middle" nzGutter="8" nzJustify="start"
            ><p nz-typography>{{ user?.fonction }}</p></nz-row
          >
        </nz-col>
      </nz-row>

      <nz-space nzSize="middle" nzDirection="vertical"></nz-space>
      <nz-descriptions nzBordered nzSize="small" [nzColumn]="2">
        <nz-descriptions-item [nzSpan]="2" nzTitle="E-mail">
          {{ user?.coordonnees?.email }}
        </nz-descriptions-item>
        <nz-descriptions-item [nzSpan]="2" nzTitle="Mobile">
          {{ user?.coordonnees?.phoneNumber | phone }}
        </nz-descriptions-item>
      </nz-descriptions>

      <ng-container *ngIf="updateVisible">
        <nz-divider
          nzPlain
          nzText="Modificiation"
          nzOrientation="center"
          nzType="horizontal"
        ></nz-divider>
        <app-user-form></app-user-form>
      </ng-container>
    </nz-card>
  </ng-template>

  <ng-template #userIdentity>
    <p class="identity">{{ user?.avatar }}</p>
  </ng-template>

  <ng-template #userActionModfied>
    <!-- <button
      nz-button
      nzType="text"
      nzBlock
      (click)="onUpdateVisible($event, user)"
    >
      <i nz-icon nzType="form"></i>
      Modifier
    </button> -->
  </ng-template>

  <ng-template #userActionChangePassword>
    <button
      nz-button
      nzType="text"
      nzBlock
      (click)="$event.stopPropagation(); onResetPass(user)"
    >
      <i nz-icon nzType="reload"></i>
      Mot de passe
    </button>
  </ng-template>

  <ng-template #userDisconnect>
    <button nz-button nzType="text" nzBlock (click)="onDisconnect($event)">
      <i nz-icon nzType="poweroff"></i>
      Quitter
    </button>
  </ng-template>

  <ng-template #userTicket>
    <button nz-button nzType="text" nzBlock (click)="onNewTicket($event)">
      <i nz-icon nzType="bug"></i>
      Ticket
    </button>
  </ng-template>

  <div class="dropdown dropdown-end">
    <label
      *ngIf="!user?.photo"
      tabindex="0"
      class="avatar placeholder cursor-pointer"
    >
      <div
        class="text-neutral-content rounded-full w-8"
        [ngStyle]="{ 'background-color': user?.setting?.color }"
      >
        <span class="text-xs" [ngStyle]="{ color: 'white' }">{{
          user?.avatar
        }}</span>
      </div>
    </label>

    <label
      *ngIf="user?.photo"
      tabindex="0"
      class="btn btn-circle avatar cursor-pointer"
    >
      <div class="w-8 rounded-full'">
        <img src="user?.photo" />
      </div>
    </label>
    <ul
      tabindex="0"
      class="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52"
    >
      <li>
        <a class="gap-2">
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <title>user_4_line</title>
            <g
              id="Icon"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g id="User" transform="translate(-240.000000, 0.000000)">
                <g id="user_4_line" transform="translate(240.000000, 0.000000)">
                  <path
                    d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5935,23.2578 L12.5819,23.2595 L12.5109,23.295 L12.4919,23.2987 L12.4767,23.295 L12.4057,23.2595 C12.3958,23.2564 12.387,23.259 12.3821,23.2649 L12.378,23.2758 L12.3609,23.7031 L12.3659,23.7235 L12.3769,23.7357 L12.4805,23.8097 L12.4953,23.8136 L12.5071,23.8097 L12.6107,23.7357 L12.6233,23.7197 L12.6267,23.7031 L12.6096,23.2758 C12.6076,23.2657 12.601,23.2593 12.5935,23.2578 Z M12.8584,23.1453 L12.8445,23.1473 L12.6598,23.2397 L12.6499,23.2499 L12.6472,23.2611 L12.6651,23.6906 L12.6699,23.7034 L12.6784,23.7105 L12.8793,23.8032 C12.8914,23.8069 12.9022,23.803 12.9078,23.7952 L12.9118,23.7812 L12.8777,23.1665 C12.8753,23.1546 12.8674,23.147 12.8584,23.1453 Z M12.143,23.1473 C12.1332,23.1424 12.1222,23.1453 12.1156,23.1526 L12.1099,23.1665 L12.0758,23.7812 C12.0751,23.7927 12.0828,23.8019 12.0926,23.8046 L12.1083,23.8032 L12.3092,23.7105 L12.3186,23.7024 L12.3225,23.6906 L12.3404,23.2611 L12.3372,23.2485 L12.3278,23.2397 L12.143,23.1473 Z"
                    id="MingCute"
                    fill-rule="nonzero"
                  ></path>
                  <path
                    d="M12,2 C17.5228,2 22,6.47715 22,12 C22,14.4016 21.1534,16.6055 19.7424,18.3294 L19.7617,18.3521 L19.6304,18.4638 C17.7961,20.627 15.0584,22 12,22 C9.05084786,22 6.39986973,20.7233202 4.56956356,18.6925212 L4.3696,18.4638 L4.23825,18.3521 L4.25763,18.3294 L4.25763,18.3294 C2.8466,16.6055 2,14.4016 2,12 C2,6.47715 6.47715,2 12,2 Z M12,17 C10.1403,17 8.45892,17.5921 7.20656,18.4055 C8.54249,19.4068 10.202,20 12,20 C13.798,20 15.4575,19.4068 16.7934,18.4055 C15.5411,17.5921 13.8597,17 12,17 Z M12,4 C7.58172,4 4,7.58172 4,12 C4,13.8847 4.65173,15.6172 5.74211,16.9843 C7.36303,15.8211 9.57491,15 12,15 C14.4251,15 16.637,15.8211 18.2579,16.9843 C19.3483,15.6172 20,13.8847 20,12 C20,7.58172 16.4183,4 12,4 Z M12,6 C14.2091,6 16,7.79086 16,10 C16,12.2091 14.2091,14 12,14 C9.79086,14 8,12.2091 8,10 C8,7.79086 9.79086,6 12,6 Z M12,8 C10.8954,8 10,8.89543 10,10 C10,11.1046 10.8954,12 12,12 C13.1046,12 14,11.1046 14,10 C14,8.89543 13.1046,8 12,8 Z"
                    id="形状"
                    fill="#09244BFF"
                  ></path>
                </g>
              </g>
            </g>
          </svg>
          Profile
        </a>
      </li>

      <li (click)="onFavoris()">
        <a class="gap-2">
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            class="h-6 w-6"
          >
            <title>Favories</title>
            <g
              id="页面-1"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g id="Shape" transform="translate(-240.000000, 0.000000)">
                <g id="star_line" transform="translate(240.000000, 0.000000)">
                  <path
                    d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z"
                    id="MingCute"
                    fill-rule="nonzero"
                  ></path>
                  <path
                    d="M10.9198,2.8677 C11.402,2.03987 12.598,2.03987 13.0801,2.8677 L15.8751,7.66643 L21.3027,8.84175 C22.239,9.0445 22.6086,10.1819 21.9703,10.8963 L18.2701,15.0374 L18.8295,20.5625 C18.926,21.5156 17.9585,22.2186 17.0818,21.8323 L12,19.5929 L6.91816,21.8323 C6.04149,22.2186 5.07395,21.5156 5.17046,20.5625 L5.72987,15.0374 L2.02972,10.8963 C1.3914,10.1819 1.76097,9.0445 2.69728,8.84175 L8.12484,7.66643 L10.9198,2.8677 Z M12,4.98699 L9.68673,8.95861 C9.5101,9.26187 9.21413,9.4769 8.87114,9.55117 L4.37906,10.5239 L7.44146,13.9513 C7.67529,14.2129 7.78834,14.5609 7.75299,14.91 L7.29,19.4828 L11.4959,17.6294 C11.8171,17.4879 12.1829,17.4879 12.504,17.6294 L16.71,19.4828 L16.247,14.91 C16.2116,14.5609 16.3247,14.2129 16.5585,13.9513 L19.6209,10.5239 L15.1288,9.55117 C14.7858,9.4769 14.4899,9.26186 14.3132,8.95861 L12,4.98699 Z"
                    id="形状"
                    fill="#09244BFF"
                  ></path>
                </g>
              </g>
            </g>
          </svg>

          Favories
        </a>
      </li>
      <li (click)="onCalculator()">
        <a class="gap-2">
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <title>Calculatrice</title>
            <g
              id="页面-1"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g
                id="Education"
                transform="translate(-240.000000, 0.000000)"
                fill-rule="nonzero"
              >
                <g
                  id="counter_line"
                  transform="translate(240.000000, 0.000000)"
                >
                  <path
                    d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z"
                    id="MingCute"
                    fill-rule="nonzero"
                  ></path>
                  <path
                    d="M19,3 C20.0543909,3 20.9181678,3.81587733 20.9945144,4.85073759 L21,5 L21,19 C21,20.0543909 20.18415,20.9181678 19.1492661,20.9945144 L19,21 L5,21 C3.94563773,21 3.08183483,20.18415 3.00548573,19.1492661 L3,19 L3,5 C3,3.94563773 3.81587733,3.08183483 4.85073759,3.00548573 L5,3 L19,3 Z M19,5 L5,5 L5,19 L19,19 L19,5 Z M7.79286,13.3787 L8.49996,14.0858 L9.20707,13.3787 C9.5976,12.9882 10.2308,12.9882 10.6213,13.3787 C11.0118,13.7692 11.0118,14.4024 10.6213,14.7929 L9.91418,15.5 L10.6213,16.2071 C11.0118,16.5976 11.0118,17.2308 10.6213,17.6213 C10.2308,18.0118 9.5976,18.0118 9.20707,17.6213 L8.49996,16.9142 L7.79286,17.6213 C7.40233,18.0118 6.76917,18.0118 6.37864,17.6213 C5.98812,17.2308 5.98812,16.5976 6.37864,16.2071 L7.08575,15.5 L6.37864,14.7929 C5.98812,14.4024 5.98812,13.7692 6.37864,13.3787 C6.76917,12.9882 7.40233,12.9882 7.79286,13.3787 Z M17,15.75 C17.5523,15.75 18,16.1977 18,16.75 C18,17.26285 17.613973,17.6855092 17.1166239,17.7432725 L17,17.75 L14,17.75 C13.4477,17.75 13,17.3023 13,16.75 C13,16.23715 13.386027,15.8144908 13.8833761,15.7567275 L14,15.75 L17,15.75 Z M17,13.25 C17.5523,13.25 18,13.6977 18,14.25 C18,14.8023 17.5523,15.25 17,15.25 L14,15.25 C13.4477,15.25 13,14.8023 13,14.25 C13,13.6977 13.4477,13.25 14,13.25 L17,13.25 Z M15.5,6.5 C16.0523,6.5 16.5,6.94772 16.5,7.5 L16.5,8 L17,8 C17.5523,8 18,8.44772 18,9 C18,9.55228 17.5523,10 17,10 L16.5,10 L16.5,10.5 C16.5,11.0523 16.0523,11.5 15.5,11.5 C14.9477,11.5 14.5,11.0523 14.5,10.5 L14.5,10 L14,10 C13.4477,10 13,9.55228 13,9 C13,8.44772 13.4477,8 14,8 L14.5,8 L14.5,7.5 C14.5,6.94772 14.9477,6.5 15.5,6.5 Z M10,8 C10.5523,8 11,8.44772 11,9 C11,9.55228 10.5523,10 10,10 L7,10 C6.44772,10 6,9.55228 6,9 C6,8.44772 6.44772,8 7,8 L10,8 Z"
                    id="形状"
                    fill="#09244BFF"
                  ></path>
                </g>
              </g>
            </g>
          </svg>

          Calculatrice
        </a>
      </li>

      <li class="divide"></li>

      <li (click)="$event.stopPropagation(); onNewTicket($event)">
        <a class="gap-2">
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <title>bug_line</title>
            <g
              id="页面-1"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g
                id="Development"
                transform="translate(-240.000000, 0.000000)"
                fill-rule="nonzero"
              >
                <g id="bug_line" transform="translate(240.000000, 0.000000)">
                  <path
                    d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z"
                    id="MingCute"
                    fill-rule="nonzero"
                  ></path>
                  <path
                    d="M20.4472,6.10557 C20.9412,6.35256 21.1414,6.95324 20.8944,7.44721 C20.5411,8.15391 19.8932,8.77306 19.2422,9.20705 C18.9509,9.40128 18.6218,9.5831 18.2759,9.72351 C18.5654,10.434 18.7748,11.1988 18.8918,12 L21,12 C21.5523,12 22,12.4477 22,13 C22,13.5523 21.5523,14 21,14 L18.9878,14 C18.9304,15.1717 18.6728,16.3028 18.2484,17.3273 C18.8198,17.6571 19.2112,18.1864 19.4569,18.6778 C19.851,19.4659 20,20.3744 20,21 C20,21.5523 19.5523,22 19,22 C18.48715,22 18.0644908,21.613973 18.0067275,21.1166239 L18,21 C18,20.6256 17.899,20.0341 17.6681,19.5722 C17.5381,19.3122 17.4085,19.1633 17.2826,19.083 C16.0307,20.8443 14.1769,22 12,22 C9.82309,22 7.96929,20.8443 6.71736,19.083 C6.59145,19.1633 6.46195,19.3122 6.33193,19.5722 C6.101,20.0341 6,20.6256 6,21 C6,21.5523 5.55228,22 5,22 C4.44772,22 4,21.5523 4,21 C4,20.3744 4.149,19.4659 4.54307,18.6778 C4.78878,18.1864 5.18022,17.6571 5.75156,17.3273 C5.32717,16.3028 5.0696,15.1717 5.01223,14 L3,14 C2.44772,14 2,13.5523 2,13 C2,12.4477 2.44772,12 3,12 L5.10825,12 C5.22524,11.1988 5.43459,10.434 5.72408,9.72351 C5.37824,9.5831 5.04914,9.40128 4.7578,9.20705 C4.10682,8.77306 3.45892,8.15391 3.10557,7.44721 C2.85858,6.95324 3.05881,6.35256 3.55279,6.10557 C4.01147643,5.87622214 4.56217301,6.03248745 4.83625773,6.45148925 L4.89443,6.55279 C5.04108,6.84609 5.39318,7.22694 5.8672,7.54295 C6.35266,7.86659 6.78116,8 7,8 L17,8 C17.2188,8 17.6473,7.86659 18.1328,7.54295 C18.54755,7.26644125 18.8689703,6.94028938 19.0411225,6.66658377 L19.1056,6.55279 C19.3526,6.05881 19.9532,5.85858 20.4472,6.10557 Z M16.2083,10 L7.79166,10 C7.29439,11.0035 7,12.202 7,13.5 C7,16.8086 8.83681,19.2729 11,19.864 L11,13 C11,12.4477 11.4477,12 12,12 C12.5523,12 13,12.4477 13,13 L13,19.864 C15.1632,19.2729 17,16.8086 17,13.5 C17,12.202 16.7056,11.0035 16.2083,10 Z M12,3 C13.852,3 15.4675,4.00738 16.3305,5.49927 L17.1986,7 L6.80143,7 L7.66955,5.49927 C8.53256,4.00738 10.1481,3 12,3 Z"
                    id="形状"
                    fill="#09244BFF"
                  ></path>
                </g>
              </g>
            </g>
          </svg>
          Tickets</a
        >
      </li>
      <li class="divide"></li>
      <li (click)="$event.stopPropagation(); onDisconnect($event)">
        <a class="gap-2">Déconnexion</a>
      </li>
    </ul>
  </div>
</ng-container>
