<nz-skeleton
  *nzSpaceItem
  [nzActive]="true"
  [nzParagraph]="{ rows: 2 }"
  [nzTitle]="true"
>
</nz-skeleton>

<nz-space nzDirection="vertical" nzSize="large"></nz-space>

<nz-space nzDirection="horizontal">
  <nz-skeleton
    *nzSpaceItem
    [nzActive]="true"
    [nzParagraph]="{ rows: 8, width: 300 }"
  >
    <nz-card></nz-card>
  </nz-skeleton>
  <nz-skeleton
    *nzSpaceItem
    [nzActive]="true"
    [nzParagraph]="{ rows: 8, width: 300 }"
  >
    <nz-card></nz-card>
  </nz-skeleton>
  <nz-skeleton
    *nzSpaceItem
    [nzActive]="true"
    [nzParagraph]="{ rows: 8, width: 300 }"
  >
    <nz-card></nz-card>
  </nz-skeleton>
  <nz-skeleton
    *nzSpaceItem
    [nzActive]="true"
    [nzParagraph]="{ rows: 8, width: 300 }"
  >
    <nz-card></nz-card>
  </nz-skeleton>
</nz-space>
<nz-space nzDirection="vertical" nzSize="small"></nz-space>

<nz-space nzDirection="horizontal">
  <nz-skeleton
    *nzSpaceItem
    [nzActive]="true"
    [nzParagraph]="{ rows: 8, width: 300 }"
  >
    <nz-card></nz-card>
  </nz-skeleton>
  <nz-skeleton
    *nzSpaceItem
    [nzActive]="true"
    [nzParagraph]="{ rows: 8, width: 300 }"
  >
    <nz-card></nz-card>
  </nz-skeleton>
  <nz-skeleton
    *nzSpaceItem
    [nzActive]="true"
    [nzParagraph]="{ rows: 8, width: 300 }"
  >
    <nz-card></nz-card>
  </nz-skeleton>
  <nz-skeleton
    *nzSpaceItem
    [nzActive]="true"
    [nzParagraph]="{ rows: 8, width: 300 }"
  >
    <nz-card></nz-card>
  </nz-skeleton>
</nz-space>
<nz-space nzDirection="vertical" nzSize="small"></nz-space>

<nz-space nzDirection="horizontal">
  <nz-skeleton
    *nzSpaceItem
    [nzActive]="true"
    [nzParagraph]="{ rows: 8, width: 300 }"
  >
    <nz-card></nz-card>
  </nz-skeleton>
  <nz-skeleton
    *nzSpaceItem
    [nzActive]="true"
    [nzParagraph]="{ rows: 8, width: 300 }"
  >
    <nz-card></nz-card>
  </nz-skeleton>
  <nz-skeleton
    *nzSpaceItem
    [nzActive]="true"
    [nzParagraph]="{ rows: 8, width: 300 }"
  >
    <nz-card></nz-card>
  </nz-skeleton>
  <nz-skeleton
    *nzSpaceItem
    [nzActive]="true"
    [nzParagraph]="{ rows: 8, width: 300 }"
  >
    <nz-card></nz-card>
  </nz-skeleton>
</nz-space>
<nz-space nzDirection="vertical" nzSize="small"></nz-space>

<nz-space nzDirection="horizontal">
  <nz-skeleton
    *nzSpaceItem
    [nzActive]="true"
    [nzParagraph]="{ rows: 8, width: 300 }"
  >
    <nz-card></nz-card>
  </nz-skeleton>
  <nz-skeleton
    *nzSpaceItem
    [nzActive]="true"
    [nzParagraph]="{ rows: 8, width: 300 }"
  >
    <nz-card></nz-card>
  </nz-skeleton>
  <nz-skeleton
    *nzSpaceItem
    [nzActive]="true"
    [nzParagraph]="{ rows: 8, width: 300 }"
  >
    <nz-card></nz-card>
  </nz-skeleton>
  <nz-skeleton
    *nzSpaceItem
    [nzActive]="true"
    [nzParagraph]="{ rows: 8, width: 300 }"
  >
    <nz-card></nz-card>
  </nz-skeleton>
</nz-space>
