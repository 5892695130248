<nz-page-header
  class="page-header"
  [nzTitle]="header"
  [nzSubtitle]="cardExtra"
  *ngIf="operations$ | async | size"
>
  <nz-page-header-extra> </nz-page-header-extra>
  <nz-page-header-content> </nz-page-header-content>
</nz-page-header>
<nz-divider *ngIf="operations$ | async | size"></nz-divider>
<ng-template #header>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="24">
      <h4 nz-typography>Projets</h4>
    </nz-col>
  </nz-row>
</ng-template>
<ng-template #cardExtra>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="24">
      <nz-badge [nzCount]="operations$ | async | size" [nzOverflowCount]="99">
      </nz-badge>
    </nz-col>
  </nz-row>
</ng-template>

<div [ngSwitch]="view$ | async">
  <div *ngSwitchCase="'list'">
    <app-layout-list
      *ngIf="operations$ | async | size; else noResults"
      [card]="card"
      [new]="new"
      [source]="operations$ | async"
    ></app-layout-list>
  </div>
  <div *ngSwitchCase="'details'">
    <app-item-operations-intervention
      [operation$]="currentSelected"
      [identity]="identity"
      (back)="onBack()"
      (update)="onUpdate($event)"
      (delete)="onDelete($event)"
    ></app-item-operations-intervention>
  </div>
  <div *ngSwitchDefault>
    <app-layout-list
      *ngIf="operations$ | async | size; else noResults"
      [card]="card"
      [new]="new"
      [source]="operations$ | async"
    ></app-layout-list>
  </div>
</div>

<ng-template #new>
  <app-card-new
    [type]="'projet'"
    [height]="'280px'"
    [width]="'300px'"
    [title]="newCardTitle"
    [description]="newCardDescription"
    [definition]="newCardDefinition"
    (add)="onNewProjet()"
  ></app-card-new>
</ng-template>

<ng-template #card let-item let-position="idx">
  <nz-card
    nzHoverable
    class="cardDocument"
    [nzTitle]="documentTitle"
    [nzExtra]="documentExtra"
    (click)="$event.stopPropagation(); onSelect(item)"
  >
    <nz-row
      nzAlign="middle"
      nzGutter="8"
      nzJustify="start"
      class="cardDocumentTitle"
    >
      <nz-col nzSpan="24">
        <strong>{{
          item?.operation?.denomination | carLimitation: 100
        }}</strong>
      </nz-col>
    </nz-row>
    <nz-divider
      nzOrientation="center"
      nzType="horizontal"
      [nzText]="cardOperationTitle"
    ></nz-divider>
    <ng-template #cardOperationTitle>
      <small>Intervention</small>
    </ng-template>

    <div [ngSwitch]="identity">
      <div *ngSwitchCase="'participant'">
        <nz-row
          nzAlign="middle"
          nzGutter="8"
          nzJustify="start"
          *ngIf="item?.intervention?.isInscription"
        >
          <nz-col [nzSpan]="24" *ngIf="status">
            <nz-tag nzColor="geekblue"> Inscription </nz-tag>
          </nz-col>
        </nz-row>
        <nz-row
          nzAlign="middle"
          nzGutter="8"
          nzJustify="start"
          *ngIf="item?.intervention?.isParticipant"
        >
          <nz-col [nzSpan]="24">
            <nz-tag nzColor="geekblue"> Participant </nz-tag>
          </nz-col>
        </nz-row>
        <nz-row
          nzAlign="middle"
          nzGutter="8"
          nzJustify="start"
          *ngIf="item?.intervention?.isDesinscription"
        >
          <nz-col [nzSpan]="24">
            <nz-tag nzColor="red"> Désinscription </nz-tag>
          </nz-col>
        </nz-row>
        <nz-row
          nzAlign="middle"
          nzGutter="8"
          nzJustify="start"
          *ngIf="item?.intervention?.isSortie"
        >
          <nz-col [nzSpan]="24">
            <nz-tag nzColor="green"> Sortie </nz-tag>
          </nz-col>
        </nz-row>
      </div>
      <div *ngSwitchCase="'entite'">
        <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
          <nz-col nzSpan="24">
            <nz-tag nzColor="geekblue">{{ item?.intervention?.role }}</nz-tag>
          </nz-col>
          <nz-col nzSpan="24">
            <nz-tag *ngIf="item?.intervention?.isFinished" nzColor="green"
              >Fin d'intervention</nz-tag
            >
            <nz-tag *ngIf="!item?.intervention?.isFinished" nzColor="geekblue"
              >En cours</nz-tag
            >
          </nz-col>
        </nz-row>
      </div>
      <div *ngSwitchCase="'contact'">
        <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
          <nz-col nzSpan="24" *ngIf="status"> </nz-col>
          <nz-col nzSpan="24">
            <nz-tag *ngIf="item?.intervention?.isFinished" nzColor="green"
              >Fin d'intervention</nz-tag
            >
            <nz-tag *ngIf="!item?.intervention?.isFinished" nzColor="geekblue"
              >En cours</nz-tag
            >
          </nz-col>
        </nz-row>
      </div>
      <div *ngSwitchDefault></div>
    </div>
  </nz-card>

  <ng-template #documentTitle>
    <app-header-title [icon]="'analytics'"></app-header-title>
  </ng-template>

  <ng-template #documentExtra>
    <button
      nz-button
      nzShape="circle"
      nzType="text"
      nz-dropdown
      [nzDropdownMenu]="operationMenuCard"
      nzPlacement="bottomRight"
      nzTrigger="click"
      (click)="$event.stopPropagation()"
    >
      <mat-icon>more_vert</mat-icon>
    </button>
  </ng-template>
  <nz-dropdown-menu #operationMenuCard="nzDropdownMenu">
    <ul nz-menu>
      <li
        nz-menu-item
        nzDanger
        nz-popconfirm
        nzPopconfirmTitle="Voulez-vous supprimer ce projet : {{
          item?.operation?.denomination
        }} ?"
        nzPopconfirmPlacement="bottom"
        (nzOnConfirm)="onDelete(item)"
        (nzOnCancel)="onCancelDelete()"
        (click)="$event.stopPropagation()"
      >
        <nz-row nzAlign="top" nzGutter="8" nzJustify="start">
          <nz-col nzSpan="4">
            <mat-icon>delete</mat-icon>
          </nz-col>
          <nz-col nzSpan="2">
            <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
          </nz-col>
          <nz-col nzSpan="18"> Supprimer </nz-col>
        </nz-row>
      </li>
    </ul>
  </nz-dropdown-menu>
</ng-template>

<ng-template #noResults>
  <app-no-result
    (new)="onNewProjet()"
    [icon]="'add_circle_outline'"
    [addtitle]="'Ajouter un projet'"
    [notitle]="'Aucun projet'"
  ></app-no-result>
</ng-template>

<ng-template #projetFormTitle>
  <app-header-title [icon]="'analytics'" [title]="title"></app-header-title>
</ng-template>
