import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-projet-professionnel-todo-item',
  templateUrl: './projet-professionnel-todo-item.component.html',
  styleUrls: ['./projet-professionnel-todo-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjetProfessionnelTodoItemComponent implements OnInit, OnChanges {
  @Input() todo$ = new BehaviorSubject<any>(null);

  @Output() back = new EventEmitter<boolean>(false);
  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}

  onBack(): void {
    this.back.emit(true);
  }
}
