import { SharedModule } from './../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromFavorie from './store/favorie.reducer';
import { EffectsModule } from '@ngrx/effects';
import { FavorieEffects } from './store/favorie.effects';
import { FavoriesComponent } from './components/favories/favories.component';
import { FavoriesListComponent } from './components/favories-list/favories-list.component';
import { ContentsModule } from 'src/app/contents/contents.module';
import { NoResultModule } from 'src/app/contents/components/no-result/no-result.module';
import { HeaderModule } from 'src/app/contents/components/header-title/header.module';
import { LayoutModule } from '@angular/cdk/layout';

@NgModule({
  declarations: [FavoriesComponent, FavoriesListComponent],
  imports: [
    CommonModule,
    SharedModule,
    ContentsModule,
    NoResultModule,
    HeaderModule,
    LayoutModule,
    StoreModule.forFeature(fromFavorie.favoriesFeatureKey, fromFavorie.reducer),
    EffectsModule.forFeature([FavorieEffects]),
  ],
  exports: [FavoriesComponent, FavoriesListComponent],
})
export class FavoriesModule {}
