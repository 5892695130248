import { Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  doc,
  docSnapshots,
  collectionChanges,
  getDoc,
  addDoc,
  setDoc,
  deleteDoc,
  serverTimestamp,
  DocumentData,
  DocumentSnapshot,
  Timestamp,
  query,
  where,
  arrayUnion,
  arrayRemove,
  collectionSnapshots,
  CollectionReference,
  DocumentReference,
  getDocs,
  documentId,
} from '@angular/fire/firestore';
import { Observable, of, from, EMPTY } from 'rxjs';
import { switchMap, mergeMap, map, catchError } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Functions, httpsCallable } from '@angular/fire/functions';
import {
  Storage,
  ref,
  uploadBytesResumable,
  deleteObject,
  getDownloadURL,
  StorageReference,
  UploadTask,
  UploadMetadata,
} from '@angular/fire/storage';
import { UserState } from '../components/user/store/user.reducer';
import * as fromUserSelector from '../components/user/store/user.selectors';
import { v4 as uuidV4 } from 'uuid';
import {
  Entite,
  EntiteId,
  EntiteSMALL,
  EntiteSMALLid,
} from '../components/entite/store/entite.model';
import { format, fromUnixTime } from 'date-fns';

@Injectable({
  providedIn: 'any',
})
export class EntiteContactsService {
  constructor(
    private db: Firestore,
    private storage: Storage,
    private fns: Functions,
    private userStore: Store<UserState>
  ) {}

  getAll(id: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !id) {
            return of([]);
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/contacts`
            );

            const queryCollection = query(
              dbCollection,
              where(`entitesIds`, 'array-contains', id)
            );

            const changes = collectionSnapshots(queryCollection).pipe(
              map((changes) => {
                return changes.map((snap) => {
                  const data = snap.data();
                  const id = snap.id;
                  return { id, ...data };
                });
              })
            );

            return changes;
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  getOneDetail(id: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) {
            return of(null);
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/entites/${id}/details`
            );

            const documentRef = doc(dbCollection, id);
            return docSnapshots(documentRef).pipe(
              map((snap) => {
                const data = snap.data();
                const id = snap.id;
                return { id, ...data };
              }),
              catchError((error) => EMPTY)
            );
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  getOne(id: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) {
            return of(null);
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/entites`
            );

            const documentRef = doc(dbCollection, id);
            return from(
              getDoc(documentRef)
                .then((snap) => {
                  const data = snap.data();
                  const id = snap.id;
                  return { id, ...data };
                })
                .catch((error) => error)
            ).pipe(mergeMap((res: any) => of(res)));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  addOne(contact: any): Observable<EntiteSMALLid | any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) {
            return of(null);
          } else {
            const smallVersionCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/entites`
            );

            const addServiceMap = user?.service?.id
              ? {
                  [`${user.service.id}`]: true,
                }
              : null;

            const addServiceArray = user?.service?.id
              ? arrayUnion(user.service.id)
              : null;

            return of(null);
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  addDetailOne(
    detailsDollection: DocumentReference<DocumentData>,
    entite: Entite,
    id: string
  ): Observable<any> {
    try {
      return from(
        setDoc(detailsDollection, entite, { merge: true })
          .then(() => {
            return this.getOne(id);
          })
          .catch((error) => of(error))
      ).pipe(
        mergeMap((res) => {
          return res;
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  updateOne(id: string | number, entite: Partial<EntiteId>): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) {
            return of(null);
          } else {
            const docRefId: string = entite?.id ? entite.id : '';
            const documentRef: DocumentReference<DocumentData> = doc(
              this.db,
              `organisations/${user.organisation.id}/entites/${entite.id}/details/${entite.id}`
            );

            return EMPTY;
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  deleteOne(id: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) {
            return of(null);
          } else {
            const documentRef: DocumentReference<DocumentData> = doc(
              this.db,
              `organisations/${user.organisation.id}/entites/${id}/details/${id}`
            );

            return from(
              deleteDoc(documentRef)
                .then(() => `Entité supprimée.`)
                .catch((error) => error)
            ).pipe(mergeMap((res) => of(res)));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  deleteAll(ids: string[]): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) {
            return of(null);
          } else {
            //CALL HTTP

            return of(null);
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }
}
