import { NzMessageService } from 'ng-zorro-antd/message';
import { Store } from '@ngrx/store';
import { ContactState } from './../../store/contact.reducer';
import * as fromContactSelector from './../../store/contact.selectors';
import * as fromContactAction from './../../store/contact.actions';

import { ContactId } from './../../store/contact.model';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';
import { Update } from '@ngrx/entity';

@Component({
  selector: 'app-contact-metiers',
  templateUrl: './contact-metiers.component.html',
  styleUrls: ['./contact-metiers.component.scss'],
})
export class ContactMetiersComponent implements OnInit {
  title$: Observable<string | any> = of('Appelations');
  tab$ = new BehaviorSubject<string | any>('Appelations');
  type$ = new BehaviorSubject<string | any>('Appelations');
  limit: number = 5;
  newCardTitle: string = 'Nouveau métier';
  newCardDescription: string = 'Ajouter un métier au contact';
  newCardDefinition: string =
    "Un projet professionnel se définit comme un processus d'accompagnement d'un participant vers son employabilité dans un éco-système professionnel.";

  @Input() contact: ContactId | any = null;

  constructor(
    private contactStore: Store<ContactState>,
    private message: NzMessageService
  ) {}

  ngOnInit(): void {}

  onSelectTab(tab: string, type: string): void {
    if (tab === 'Nouveau') return;
    this.tab$.next(tab);
    this.type$.next(type);
    this.title$ = of(tab);
  }

  onUpdate(event: any, contact: ContactId): void {
    if (!event || !contact) return;
    const { appelation, domaineProfessionnel, grandDomaine, metiers } = event;

    if (this.isAppelationExist(contact, appelation)) {
      const alert: string = `L'appelation ${appelation.libelle} est déjà existante`;
      this.message.info(alert, { nzDuration: 6000 });
      return;
    }

    if (this.isOverLimit(contact)) {
      const alert: string = `Vous avez atteint le nombre maximal de métiers`;
      this.message.info(alert, { nzDuration: 6000 });
      return;
    }

    const nextContact: Update<ContactId> = {
      id: contact.id,
      changes: {
        ...contact,
        //GRANDS DOMAINES
        metiersGrandDomainesArray: contact?.metiersGrandDomainesArray?.length
          ? [...contact.metiersGrandDomainesArray, grandDomaine.libelle]
          : [grandDomaine.libelle],
        metiersGrandDomainesMap: {
          ...contact.metiersGrandDomainesMap,
          [`${grandDomaine.libelle}`]: grandDomaine,
        },

        //DOMAINES
        metiersDomainesArray: contact?.metiersDomainesArray?.length
          ? [...contact.metiersDomainesArray, domaineProfessionnel.libelle]
          : [domaineProfessionnel.libelle],
        metiersDomaineMap: {
          ...contact.metiersDomaineMap,
          [`${domaineProfessionnel.libelle}`]: domaineProfessionnel,
        },

        //METIERS
        metiersArray: contact?.metiersArray?.length
          ? [...contact.metiersArray, metiers.libelle]
          : [metiers.libelle],
        metiersMap: {
          ...contact.metiersMap,
          [`${metiers.libelle}`]: metiers,
        },

        //APPELATIONS
        appelationsArray: contact?.appelationsArray?.length
          ? [...contact.appelationsArray, appelation.libelle]
          : [appelation.libelle],
        appelationsMap: {
          ...contact.appelationsMap,
          [`${appelation.libelle}`]: appelation,
        },
      },
    };

    this.contactStore.dispatch(
      fromContactAction.updateContact({ contact: nextContact })
    );
    this.onSelectTab('Métiers', '');
  }

  isAppelationExist(contact: ContactId, appelation: any): boolean {
    const exist = contact.appelationsArray?.length
      ? contact.appelationsArray.find((el: string) => el === appelation.libelle)
      : null;
    return exist ? true : false;
  }

  isOverLimit(contact: ContactId): boolean {
    const limit = contact?.appelationsArray?.lenght
      ? contact.appelationsArray.lenght
      : 0;

    return limit >= this.limit ? true : false;
  }

  onDelete(appelation: any, contact: ContactId): void {
    if (!appelation || !contact) return;

    // const nextContact: Update<ContactId> = {
    //   id: contact.id,
    //   changes: {
    //     ...contact,

    //     metiers,
    //     metiersMap,
    //   },
    // };

    // this.contactStore.dispatch(
    //   fromContactAction.updateContact({ contact: nextContact })
    // );
    // this.onSelectTab('Métiers', '');
  }

  transforToMap(array: any[]): any {
    if (!array?.length) {
      return null;
    }

    if (array?.length) {
      const reduce = array.reduce((acc, cur) => {
        acc[cur['libelle']] = cur;
        return acc;
      }, {});

      return reduce;
    }
  }
}
