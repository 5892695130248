import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
  UntypedFormArray,
  Validators,
} from '@angular/forms';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-operation-team-role',
  templateUrl: './operation-team-role.component.html',
  styles: [
    `
      form {
        height: 400px;
      }

      .roleContainer {
        height: 320px;
        overflow-y: auto;
        overflow-x: hidden;
      }

      nz-tag {
        font-size: 14px;
        border-radius: 12px;
      }
      .tagClass {
        padding-bottom: 25px;
      }

      nz-form-control {
        border-radius: 12px;
        background-color: #f5f5f5;
      }

      nz-date-picker {
        width: 100%;
      }

      .avatar {
        mat-icon {
          color: #fff;
        }
      }
    `,
  ],
})
export class OperationTeamRoleComponent
  implements OnInit, OnChanges, OnDestroy
{
  rolesForm: UntypedFormGroup = this.fb.group({});
  memberRoles: UntypedFormArray = this.fb.array([]);
  defaultColor: string = '#d9d9d9';
  today = new Date(Date.now());
  role: UntypedFormControl = new UntypedFormControl('');
  bgColor: string = '#bfbfbf';
  bgDefault: string = '#fff';
  subscribe = new Subject();
  @Input() members = new BehaviorSubject<any[]>([]);
  @Output() validate = new EventEmitter<any>(false);
  @Output() cancel = new EventEmitter<boolean>(false);
  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.form();
  }
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  form(): void {
    this.formInit();
    this.formCreate();
    this.formSet();
  }
  formInit(): void {
    this.memberRoles = new UntypedFormArray([]);
  }
  formCreate(): void {
    this.rolesForm = this.fb.group({
      users: this.memberRoles,
    });
  }
  formSet(): void {
    this.members.pipe(takeUntil(this.subscribe)).subscribe((users: any[]) => {
      if (!users) return;
      if (users.length > 0) {
        users.forEach((user) => {
          const memberGroup = this.fb.group({
            user: [user],
            role: ['', Validators.required],
            canRead: [true, Validators.required],
            canWrite: [false, Validators.required],
            isAdmin: [false, Validators.required],
          });

          this.memberRoles.push(memberGroup);
        });
      }

      [users].forEach((user) => {
        const memberGroup = this.fb.group({
          user: [user],
          role: ['', Validators.required],
          canRead: [true, Validators.required],
          canWrite: [false, Validators.required],
          isAdmin: [false, Validators.required],
        });

        this.memberRoles.push(memberGroup);
      });
    });
  }

  get rolesArray(): UntypedFormArray {
    return this.rolesForm.get('users') as UntypedFormArray;
  }

  onSetRole(event: boolean, name: string, index: number): void {
    switch (name) {
      case 'isAdmin':
        this.memberRoles.at(index)?.patchValue({
          isAdmin: event,
        });
        break;
      case 'canRead':
        this.memberRoles.at(index)?.patchValue({
          canRead: event,
        });
        break;
      case 'canWrite':
        this.memberRoles.at(index)?.patchValue({
          canWrite: event,
        });
        break;
      default:
        break;
    }
  }

  onRemoveMember(index: number): void {
    this.memberRoles.removeAt(index);
  }

  onValidate(): void {
    const users = this.rolesForm.get('users')?.value;
    if (!users?.length) {
      this.onCancel();
    } else {
      this.validate.emit(users);
    }
  }
  onCancel(): void {
    this.cancel.emit(true);
  }
}
