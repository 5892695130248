<div
  class="w-full gap-y-2 divide-y overflow-hidden"
  *ngIf="candidat$ | async as candidat"
>
  <div [ngSwitch]="view$ | async" class="w-full">
    <div *ngSwitchCase="'form'" class="w-full divide-y">
      <app-candidature-form-person-item
        [isAction]="true"
        [isUpdate]="true"
        [isAction]="true"
        [candidatInput]="candidat$ | async"
        (update)="onCandidatChanges($event)"
        (cancel)="onViewChanges('display')"
        (back)="onViewChanges('display')"
      ></app-candidature-form-person-item>
    </div>
    <div *ngSwitchDefault class="w-full">
      <div class="w-full flex items-end mb-2">
        <div class="w-8/12 flex items-start">
          <p class="text-sm opacity-50">
            Crée le
            {{ candidat.person?.dateStart | dateFr : "small" }} par
            {{ candidat.person?.auteur?.displayName | carLimitation : 25 }}
          </p>
        </div>
        <div class="w-4/12 flex items-start">
          <p class="text-sm opacity-50">
            Modifié le
            {{ candidat.person?.dateUpdate | dateFr : "small" }} par
            {{ candidat.person?.updateUser?.displayName | carLimitation : 25 }}
          </p>
        </div>
      </div>

      <div class="w-full flex items-end mr-4">
        <div class="w-full space-y-8">
          <div class="w-full flex items-start rounded-xl bg-slate-50 p-4 h-48">
            <div class="w-1/2 gap-2">
              <div class="grid grid-cols-6 items-start space-x-3">
                <div class="col-span-1">
                  <div class="avatar placeholder">
                    <div
                      class="text-neutral-content rounded-full w-12"
                      [ngStyle]="{
                        'background-color': candidat?.person?.setting?.color
                      }"
                    >
                      <span>{{ candidat?.person?.avatar }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-span-5 space-y-4">
                  <div class="text-base font-bold tracking-wider">
                    {{ candidat.person.displayName | carLimitation : 50 }}
                  </div>
                  <div class="text-sm opacity-50 flex flex-col tracking-wider">
                    <div class="w-full flex items-start gap-2 divide-x">
                      <svg
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                      >
                        <g
                          id="页面-1"
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <g
                            id="Business"
                            transform="translate(-144.000000, 0.000000)"
                            fill-rule="nonzero"
                          >
                            <g
                              id="calendar_line"
                              transform="translate(144.000000, 0.000000)"
                            >
                              <path
                                d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z"
                                id="MingCute"
                                fill-rule="nonzero"
                              ></path>
                              <path
                                d="M16,3 C16.51285,3 16.9355092,3.38604429 16.9932725,3.88337975 L17,4 L17,5 L19,5 C20.0543909,5 20.9181678,5.81587733 20.9945144,6.85073759 L21,7 L21,19 C21,20.0543909 20.18415,20.9181678 19.1492661,20.9945144 L19,21 L5,21 C3.94563773,21 3.08183483,20.18415 3.00548573,19.1492661 L3,19 L3,7 C3,5.94563773 3.81587733,5.08183483 4.85073759,5.00548573 L5,5 L7,5 L7,4 C7,3.44772 7.44772,3 8,3 C8.51283143,3 8.93550653,3.38604429 8.9932722,3.88337975 L9,4 L9,5 L15,5 L15,4 C15,3.44772 15.4477,3 16,3 Z M19,12 L5,12 L5,19 L19,19 L19,12 Z M19,7 L5,7 L5,10 L19,10 L19,7 Z"
                                id="形状"
                                fill="#09244BFF"
                              ></path>
                            </g>
                          </g>
                        </g>
                      </svg>
                      <span>{{
                        candidat?.person?.dateNaissance | dateFr : "small"
                      }}</span>
                      <span
                        *ngIf="
                          candidat?.person?.age && candidat?.person?.age > 0
                        "
                        >{{ candidat?.person?.age + "ans" }}</span
                      >
                    </div>
                    <div class="w-full flex items-start gap-2">
                      <svg
                        class="fill-gray-500"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                      >
                        <g
                          id="页面-1"
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <g id="location_line">
                            <path
                              d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z"
                              id="MingCute"
                              fill-rule="nonzero"
                            ></path>
                            <path
                              d="M12,2 C16.9706,2 21,6.02944 21,11 C21,14.0738 19.3241,16.5892 17.5584,18.3945 C16.5896727,19.3848818 15.5596562,20.1973165 14.6817052,20.8113077 L14.2563896,21.1016714 C14.1879449,21.1472775 14.1208032,21.1914819 14.0550879,21.2342726 L13.6789009,21.4739579 L13.6789009,21.4739579 L13.3428744,21.6791459 L13.3428744,21.6791459 L12.9271,21.921 L12.9271,21.921 C12.3479,22.2481 11.6521,22.2481 11.0729,21.921 L10.6571257,21.6791459 L10.6571257,21.6791459 L10.1376557,21.3583918 C10.0749168,21.3184403 10.010628,21.2770633 9.94491277,21.2342726 L9.53449244,20.9606593 C8.61244219,20.3314866 7.49106273,19.4674136 6.44162,18.3945 C4.6759,16.5892 3,14.0738 3,11 C3,6.02944 7.02944,2 12,2 Z M12,4 C8.13401,4 5,7.13401 5,11 C5,13.3224 6.27202,15.3608 7.87141,16.996 C8.60686632,17.7479263 9.38754127,18.3894817 10.0929208,18.9063179 L10.5506965,19.232241 C10.6986884,19.3346181 10.8416395,19.4306787 10.9782273,19.5202655 L11.3675782,19.7694466 L11.3675782,19.7694466 L11.7108141,19.9788397 L11.7108141,19.9788397 L12,20.1475 L12,20.1475 L12.4545461,19.8791757 L12.4545461,19.8791757 L12.8218351,19.6497705 C13.016678,19.5251603 13.2273265,19.3858066 13.4493166,19.232241 L13.9070964,18.9063179 C14.6124814,18.3894817 15.3931579,17.7479263 16.1286,16.996 C17.728,15.3608 19,13.3224 19,11 C19,7.13401 15.866,4 12,4 Z M12,7 C14.2091,7 16,8.79086 16,11 C16,13.2091 14.2091,15 12,15 C9.79086,15 8,13.2091 8,11 C8,8.79086 9.79086,7 12,7 Z M12,9 C10.8954,9 10,9.89543 10,11 C10,12.1046 10.8954,13 12,13 C13.1046,13 14,12.1046 14,11 C14,9.89543 13.1046,9 12,9 Z"
                              id="形状"
                              fill="#09244BFF"
                            ></path>
                          </g>
                        </g>
                      </svg>
                      {{
                        candidat?.person?.adresse?.adresse?.properties?.context
                      }}
                    </div>
                    <app-coordonnees-display
                      [coordonnees]="candidat?.person?.coordonnees"
                    ></app-coordonnees-display>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-1/2 space-y-4">
              <div class="w-full flex items-start gap-2">
                <span
                  *ngIf="
                    candidat.person.isValided && !candidat?.person?.isSelected
                  "
                  [class]="'badge badge-success badge-md'"
                  >Vérification validée</span
                >
                <span
                  *ngIf="!candidat?.person?.isValided"
                  [class]="'badge badge-ghost badge-md'"
                  >En cours de vérification</span
                >
                <span
                  *ngIf="candidat?.person?.isSelected"
                  [class]="'badge badge-success badge-md'"
                  >Sélectionné</span
                >

                <span
                  *ngIf="candidat?.person?.isInterviewed"
                  [class]="'badge badge-ghost badge-md'"
                  >Entretien</span
                >
                <span
                  *ngIf="candidat?.person?.isRejected"
                  [class]="'badge badge-warning badge-md'"
                  >Rejeté</span
                >
              </div>
              <div class="w-full space-x-1 space-y-1">
                <div class="badge badge-ghost badge-md">
                  <span>{{
                    candidat?.person?.diagnostic?.administratif?.criteres1
                      ?.value | splitJoin : "_" : ","
                  }}</span>
                </div>
                <span
                  class="badge badge-ghost badge-md"
                  *ngFor="
                    let critere of candidat?.person?.diagnostic?.administratif
                      ?.criteres2
                  "
                >
                  {{ critere?.item }}
                </span>
                <div
                  class="badge badge-ghost 0 badge-md gap-2"
                  *ngIf="candidat?.person?.diagnostic?.administratif?.isPassIae"
                >
                  Pass IAE
                </div>
                <div
                  class="badge badge-ghost badge-md gap-2"
                  *ngIf="
                    candidat?.person?.diagnostic?.administratif?.typeCarteSejour
                      ?.titre
                  "
                >
                  <span>{{
                    candidat?.person?.diagnostic?.administratif?.typeCarteSejour
                      ?.type
                  }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="w-full">
            <app-item-tabset
              [tabIndex]="tabIndex"
              (select)="onTabChanges($event)"
              [tabs]="[
                {
                  name: 'Notes',
                  template: candidatPersonSuivisTpl
                },
                {
                  name: 'Documents',
                  template: candidatPersonDocumentsTpl
                },
                {
                  name: 'Administratif',
                  template: candidatPersonAdministratifTpl
                },
                {
                  name: 'Présentation',
                  template: candidatPersonPresentationTpl
                },

                {
                  name: 'Prescripteur',
                  template: candidatPersonPrescripteurTpl
                }
              ]"
            ></app-item-tabset>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #candidatPersonSuivisTpl>
    <div *ngTemplateOutlet="notesTpl"></div>
  </ng-template>
  <ng-template #candidatPersonDocumentsTpl>
    <div *ngTemplateOutlet="documentTpl"></div>
  </ng-template>
  <ng-template #candidatPersonPrescripteurTpl>
    <app-candidature-item-prescripteur
      [candidat]="candidat"
    ></app-candidature-item-prescripteur>
  </ng-template>
  <ng-template #candidatPersonAdministratifTpl>
    <div class="grid grid-cols-1 h-80 p-4 rounded-xl bg-slate-50">
      <!-- <div>
        <span>{{
          candidat?.person?.diagnostic?.administratif?.isPassIae
            ? "Pass IAE"
            : "Aucun Pass IAE"
        }}</span>
        :
        <span
          *ngIf="candidat?.person?.diagnostic?.administratif?.dateStartPassIae"
          >Du
          {{
            candidat?.person?.diagnostic?.administratif?.dateStartPassIae
              ? (candidat?.person?.diagnostic?.administratif?.dateStartPassIae
                | dateFr : "small")
              : ""
          }}</span
        >
        <span
          *ngIf="candidat?.person?.diagnostic?.administratif?.dateEndPassIae"
        >
          au
          {{
            candidat?.person?.diagnostic?.administratif?.dateEndPassIae
              ? (candidat?.person?.diagnostic?.administratif?.dateEndPassIae
                | dateFr : "small")
              : ""
          }}</span
        >
      </div>

      <div
        class="badge badge-info bg-opacity-20 badge-md gap-2"
        *ngIf="
          candidat?.person?.diagnostic?.administratif?.typeCarteSejour?.titre
        "
      >
        <span
          >{{
            candidat?.person?.diagnostic?.administratif?.typeCarteSejour?.titre
          }}
        </span>
        -
        <span>{{
          candidat?.person?.diagnostic?.administratif?.typeCarteSejour?.type
        }}</span>
      </div>

      <span
        class="badge badge-info bg-opacity-20 badge-md gap-2"
        *ngIf="candidat?.person?.diagnostic?.administratif?.dateEntree"
        >Entrée sur le territoir le :
        {{
          candidat?.person?.diagnostic?.administratif?.dateEntree
            ? (candidat?.person?.diagnostic?.administratif?.dateEntree
              | dateFr : "small")
            : ""
        }}</span
      > -->
    </div>
  </ng-template>
  <ng-template #candidatPersonPresentationTpl>
    <div
      class="grid grid-cols-1 h-80"
      *ngIf="candidat?.person?.diagnostic?.presentation"
    >
      <div class="rounded-xl p-4 bg-slate-50 h-72 overflow-y-auto">
        <p class="tracking-wider">
          {{ candidat?.person?.diagnostic?.presentation }}
        </p>
      </div>
    </div>
    <div
      class="grid grid-cols-1"
      *ngIf="!candidat?.person?.diagnostic?.presentation"
    >
      <app-no-result
        [description]="'Aucune présentation renseignée'"
        [isButton]="false"
      ></app-no-result>
    </div>
  </ng-template>
</div>

<ng-template #suiviTitleForm>
  <app-header-title
    [icon]="'article'"
    [title]="newCardTitle"
  ></app-header-title>
</ng-template>
