<form [formGroup]="candidatureForm">
  <div class="w-full space-y-6">
    <div class="w-full flex items-start gap-x-2">
      <div class="w-2/12 flex flex-col pt-2 gap-y-6">
        <nz-steps
          [nzCurrent]="step"
          nzSize="small"
          nzDirection="vertical"
          nzProgressDot
          (nzIndexChange)="onIndexChanges($event, index)"
        >
          <nz-step [nzTitle]="'Identité'"> </nz-step>
          <nz-step [nzTitle]="'Administratif'"> </nz-step>
          <nz-step [nzTitle]="'Présentation'"></nz-step>
        </nz-steps>
      </div>

      <div [ngSwitch]="step" class="w-10/12 pt-2">
        <div *ngSwitchCase="0" class="w-full">
          <app-person-form
            class="w-full"
            [isList]="false"
            [isEtablissement]="false"
            [isFonction]="false"
            [isAdresse]="true"
            [isRole]="false"
            [isDateNaissance]="true"
            [isDateNaissanceRequired]="true"
            [isMobileRequired]="false"
            [isEmailRequired]="false"
            [isAdresseRequired]="true"
            [contactsInput]="currentPersonIdentity$ | async"
            (onCurrentChanges)="onPersonIdentityChanges($event, index)"
          ></app-person-form>
        </div>

        <div *ngSwitchCase="1" class="w-full">
          <app-participant-diagnostic-form-administratif
            class="w-full"
            [isFreinValidation]="false"
            [isDatesValidation]="false"
            [administratif$]="currentPersonDiagnostic$"
            (add)="onPersonDiagnosticChanges($event, index)"
          ></app-participant-diagnostic-form-administratif>
        </div>

        <div *ngSwitchCase="2" class="w-full">
          <div formGroupName="diagnostic">
            <label class="label">Présentation</label>
            <textarea
              formControlName="presentation"
              [placeholder]="'Présenter le candidat à son avantage'"
              class="textareaForm max-h-24"
            ></textarea>
          </div>
        </div>
        <div *ngSwitchDefault></div>
      </div>
    </div>

    <div *ngIf="isAction" class="w-full flex items-end justify-end gap-2">
      <div>
        <button (click)="onCancel()" class="btn btn-ghost btn-sm">
          Annuler
        </button>
      </div>
      <div>
        <button
          (click)="onUpdate(candidatInput)"
          [class]="
            isUpdate && isCandiatureValid
              ? 'btn btn-primary btn-sm'
              : 'btn btn-disabled btn-sm'
          "
          *ngIf="isUpdate"
        >
          Modifier
        </button>
        <button
          (click)="onAdd()"
          [class]="
            !isUpdate && isCandiatureValid
              ? 'btn btn-primary btn-sm'
              : 'btn btn-disabled btn-sm'
          "
          *ngIf="!isUpdate"
        >
          Valider
        </button>
      </div>
    </div>
  </div>
</form>
