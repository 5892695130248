import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  OperationContactState,
  operationContactsFeatureKey,
  selectAll,
} from './operation-contacts.reducer';

export const selectOperationState =
  createFeatureSelector<OperationContactState>(operationContactsFeatureKey);

export const contacts = createSelector(selectOperationState, selectAll);

export const queries = createSelector(
  selectOperationState,
  (state: OperationContactState) => state.queries
);

export const contact = createSelector(
  selectOperationState,
  (state: OperationContactState) => state.contact
);

export const loading = createSelector(
  selectOperationState,
  (state: OperationContactState) => state.loading
);

export const suivis = createSelector(
  selectOperationState,
  (state: OperationContactState) => state.suivis
);

export const documents = createSelector(
  selectOperationState,
  (state: OperationContactState) => state.documents
);

export const error = createSelector(
  selectOperationState,
  (state: OperationContactState) => state.error
);
