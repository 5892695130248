<nz-row nzAlign="top" nzJustify="center" nzGutter="4">
  <nz-col nzSpan="15">
    <div class="content" *ngIf="!(editing$ | async)">
      <nz-card
        nzSize="small"
        nzBorderless
        [nzTitle]="livrableItemTitlte"
        *ngIf="editingListItemShow$ | async as editingListItemShow; else noData"
      >
        <ng-template #livrableItemTitlte>
          {{ editingListItemShow?.categorie | titlecase }}
        </ng-template>

        <nz-row nzAlign="top" nzJustify="center" nzGutter="4">
          <nz-col nzSpan="16"></nz-col>
          <nz-col nzSpan="8"></nz-col>
        </nz-row>
        <nz-descriptions nzSize="small" nzBordered>
          <nz-descriptions-item nzSpan="3" nzTitle="Type">
            {{ editingListItemShow?.type | titlecase }}
          </nz-descriptions-item>
          <nz-descriptions-item
            nzSpan="3"
            nzTitle="Item"
            *ngIf="editingListItemShow?.item"
          >
            {{ editingListItemShow?.item?.item }}
          </nz-descriptions-item>
          <nz-descriptions-item nzSpan="3" [nzTitle]="itemDescription">
            {{ editingListItemShow?.value | number }}
          </nz-descriptions-item>
        </nz-descriptions>
        <ng-template #itemDescription>
          <mat-icon class="iconSujetItem">grading</mat-icon>
        </ng-template>
      </nz-card>
    </div>
  </nz-col>
  <nz-col nzSpan="1">
    <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
  </nz-col>
  <nz-col nzSpan="8">
    <nz-list [nzHeader]="listHeader" [nzBordered]="false" nzSize="small">
      <ng-template #listHeader>
        <nz-row nzJustify="start" nzAlign="middle" nzGutter="4">
          <nz-col nzSpan="12">
            <button
              nz-button
              nz-tooltip
              nzBlock
              [nzTooltipMouseEnterDelay]="0.5"
              nzTooltipPlacement="bottom"
              nzTooltipTitle="Nouvel objectif"
              nzType="text"
              class="btnPrimary"
              (click)="onNew(cadrage)"
            >
              <p nz-typography>Nouveau</p>
            </button>
          </nz-col>
          <nz-col nzSpan="12">
            <button
              nz-button
              nz-tooltip
              nzBlock
              [nzTooltipMouseEnterDelay]="0.5"
              nzTooltipPlacement="bottom"
              nzTooltipTitle="Supprimler tous les objectifs"
              nz-popconfirm
              nzPopconfirmTitle="Voulez-vous supprimer tous les objectifs ?"
              nzPopconfirmPlacement="bottom"
              (nzOnConfirm)="onDeleteAllList(cadrage)"
              (nzOnCancel)="onCancel()"
              nzType="text"
            >
              <p nz-typography>Supprimer</p>
            </button>
          </nz-col>
        </nz-row>
      </ng-template>
      <cdk-virtual-scroll-viewport appendOnly itemSize="75" class="viewport">
        <ng-container>
          <nz-list-item
            *cdkVirtualFor="let item of cadrage?.objectifs"
            (click)="editingListItemShow$.next(item)"
          >
            <nz-row nzAlign="top" nzJustify="center" nzGutter="4">
              <nz-col nzSpan="20">
                <span>{{ item?.categorie | titlecase }}</span>
                <nz-row nzAlign="top" nzGutter="2" nzJustify="center">
                  <nz-col nzSpan="4">
                    <mat-icon class="iconSujetItem">grading</mat-icon>
                  </nz-col>
                  <nz-col nzSpan="10">
                    {{ item?.type | titlecase }}
                  </nz-col>
                  <nz-col nzSpan="10">
                    {{
                      item?.value
                        ? ": " + (item?.value | carLimitation: 8 | number)
                        : ""
                    }}
                  </nz-col>
                </nz-row>
              </nz-col>
              <nz-col nzSpan="4">
                <button
                  nzShape="circle"
                  nz-button
                  nz-dropdown
                  [nzDropdownMenu]="menu"
                  nzTrigger="click"
                  (click)="$event.stopPropagation()"
                >
                  <i nz-icon nzType="more"></i>
                </button>
              </nz-col>
            </nz-row>
            <nz-dropdown-menu #menu="nzDropdownMenu">
              <ul nz-menu>
                <li nz-menu-item (click)="onNew(cadrage, cadrage.objectifs)">
                  <i nz-icon nzType="edit"></i>
                  <span class="dropdowMenuSpan">Modifier</span>
                </li>
                <li nz-menu-divider></li>
                <li
                  nz-menu-item
                  nzDanger
                  nz-popconfirm
                  nzPopconfirmTitle="Voulez-vous supprimer cet objectif ?"
                  nzPopconfirmPlacement="bottom"
                  (nzOnConfirm)="onDeleteItem(item, cadrage)"
                  (nzOnCancel)="onCancel()"
                >
                  <i nz-icon nzType="delete"></i>
                  <span class="dropdowMenuSpan">Supprimer</span>
                </li>
              </ul>
            </nz-dropdown-menu>
          </nz-list-item>
        </ng-container>
      </cdk-virtual-scroll-viewport>
    </nz-list>
  </nz-col>
</nz-row>

<ng-template #noData>
  <div class="containerEmpty">
    <div>
      <mat-icon class="iconEmpty">app_registration</mat-icon>
      <h4 nz-typography class="contentEmpty">Aucun objectif sélectionné</h4>
    </div>
  </div>
</ng-template>
