import { ContentsModule } from 'src/app/contents/contents.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParticipantImpactsComponent } from './components/participant-impacts/participant-impacts.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { NoResultModule } from 'src/app/contents/components/no-result/no-result.module';
import { CoordonneesModule } from 'src/app/contents/components/coordonnees/coordonnees.module';
import { CommunesFranceModule } from 'src/app/contents/components/communes-france/communes-france.module';
import { HeaderModule } from 'src/app/contents/components/header-title/header.module';

@NgModule({
  declarations: [ParticipantImpactsComponent],
  imports: [
    CommonModule,
    ContentsModule,
    SharedModule,
    NoResultModule,
    CoordonneesModule,
    HeaderModule,
    CommunesFranceModule,
  ],
  exports: [ParticipantImpactsComponent],
})
export class ParticipantImpactsModule {}
