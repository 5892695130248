<div class="w-full overflow-hidden" *ngIf="user$ | async as user">
  <div [ngSwitch]="view$ | async" class="w-full overflow-hidden">
    <div *ngSwitchCase="'Document'" class="w-full overflow-hidden">
      <app-document
        (addDocument)="uploadCandidatDocuments($event, user, fiche, candidat)"
        (cancel)="onCancel()"
      ></app-document>
    </div>
    <div *ngSwitchDefault class="w-full overflow-hidden">
      <app-progress-loading *ngIf="loading$ | async"></app-progress-loading>

      <app-table-documents
        *ngIf="!(loading$ | async)"
        [source$]="documents$"
        [scroll]="{ x: '100%', y: 'calc(100vh - 40rem)' }"
        [selectTyped]="selectTyped"
        [isCanAdd]="false"
        (delete)="onDelete($event, fiche, candidat)"
        (deleteAll)="onDeleteAll(fiche, candidat)"
      ></app-table-documents>
    </div>
  </div>
</div>
