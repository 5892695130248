import { Observable, of } from 'rxjs';
import { SideNavigation } from './../components/side-navigation/store/side-navigation.model';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'any',
})
export class SideNavigationService {
  constructor(private router: Router) {}

  onNavigation(event: SideNavigation): Observable<any> {
    try {
      return of(null);
    } catch (error) {
      return of(null);
    }
  }
}
