import { ProjetPrpfessionnelState } from './../../store/projet-professionnel.reducer';
import * as fromProjetProSelector from './../../store/projet-professionnel.selectors';
import * as fromProjetProAction from './../../store/projet-professionnel.actions';

import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-projet-professionnel-jobs',
  templateUrl: './projet-professionnel-jobs.component.html',
  styleUrls: ['./projet-professionnel-jobs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjetProfessionnelJobsComponent
  implements OnInit, OnChanges, OnDestroy
{
  loading$: Observable<boolean> = of(false);
  jobs$: Observable<any> = of([]);
  view$ = new BehaviorSubject<string>('list');
  currentSelected$ = new BehaviorSubject<any>(null);
  subscribe = new Subject();

  @Input() projetprofessionnel: Observable<any> = of(null);

  constructor(
    private projetProfessionnelStore: Store<ProjetPrpfessionnelState>
  ) {}

  ngOnInit(): void {
    this.onLoad();
    this.onGet();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  onLoad(): void {
    this.onLoadJobs();
  }

  onLoadJobs(): void {
    this.projetprofessionnel
      .pipe(takeUntil(this.subscribe))
      .subscribe((projet) => {
        if (!projet) return;

        console.log(projet);
      });
  }

  onGet(): void {
    this.getJobsLoading();
    this.getJobs();
  }

  getJobsLoading(): void {
    this.loading$ = this.projetProfessionnelStore.select(
      fromProjetProSelector.loading
    );
  }

  getJobs(): void {
    this.jobs$ = this.projetProfessionnelStore.select(
      fromProjetProSelector.jobs
    );
  }

  onSelect(job: any): void {
    if (!job) return;
    this.currentSelected$.next(job);
    this.view$.next('detail');
  }
}
