import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class AllParticipantDocumentsGQL extends Query<Response> {
  document = gql`
    query ParticipantDocuments($id: ID!) {
      participantDocuments(id: $id) {
        id
        fileName
        fileRef
        title
        type
        extension
        description
        correspondanceId
        auteur {
          avatar
          displayName
          setting {
            color
          }
        }
        dateStart
      }
    }
  `;
}
