import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Input,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-metiers-definition',
  templateUrl: './metiers-definition.component.html',
  styleUrls: ['./metiers-definition.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetiersDefinitionComponent implements OnInit, OnChanges {
  @Input() metier: any;
  @Input() metierUpdate: any;
  @Output() remove = new EventEmitter();
  @Output() currentChanges = new EventEmitter();

  //subscription
  subscribe = new Subject();

  //initialisation du formulaire
  definition = new FormControl('');
  acces = new FormArray([]);

  metierDefinitionForm = new FormGroup({
    definition: this.definition,
    acces: this.acces,
  });

  constructor() {}

  ngOnInit(): void {
    this.onChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  onUpdateForm(metier: any): void {}

  onChanges(): void {
    this.metierDefinitionForm?.valueChanges
      .pipe(takeUntil(this.subscribe))
      .subscribe((value) => this.currentChanges.emit(value));
  }

  onRemove(): void {
    this.remove.emit(true);
  }
}
