<div class="ficheDePosteItemDetailContainer">
  <nz-descriptions nzSize="small" nzBordered>
    <nz-descriptions-item nzSpan="1" nzTitle="Dénomination">
      <strong>{{ fiche?.entite?.uniteLegale?.denominationUniteLegale }}</strong>
    </nz-descriptions-item>
    <nz-descriptions-item nzSpan="1" nzTitle="Siret">
      {{ fiche?.entite?.siret }}
    </nz-descriptions-item>
    <nz-descriptions-item nzSpan="1" nzTitle="Catégorie">
      {{ fiche?.entite?.uniteLegale?.categorieEntreprise }}
    </nz-descriptions-item>
    <nz-descriptions-item nzSpan="1" nzTitle="Activité">
      {{
        fiche?.entite?.uniteLegale?.activite_principale_libelle?.intitule_naf
      }}
    </nz-descriptions-item>
    <nz-descriptions-item nzSpan="1" nzTitle="Siège">
      <nz-tag *ngIf="fiche?.entite?.etablissementSiege">{{
        fiche?.entite?.etablissementSiege ? "OUI" : "NON"
      }}</nz-tag>
    </nz-descriptions-item>
    <nz-descriptions-item nzSpan="1" nzTitle="Adresse">
      {{ fiche?.entite?.adresseEtablissement?.fullAdresse }}
    </nz-descriptions-item>
    <nz-descriptions-item nzSpan="3" nzTitle="Contacts">
      <nz-tag
        *ngFor="let contact of fiche?.contacts"
        (click)="$event.stopPropagation()"
        nz-popover
        nzPopoverTrigger="click"
        nzPopoverPlacement="bottom"
        [nzPopoverContent]="contactEntiteSnipTpl"
      >
        {{ contact | displayName }}

        <ng-template #contactEntiteSnipTpl>
          <nz-card nzSize="small" class="ficheItemEntrepriseContact">
            <nz-descriptions nzSize="small" nzBordered>
              <nz-descriptions-item nzTitle="Fonction" nzSpan="3">{{
                contact?.fonction
              }}</nz-descriptions-item>
              <nz-descriptions-item nzTitle="Rôle" nzSpan="3">{{
                contact?.role
              }}</nz-descriptions-item>
              <nz-descriptions-item nzTitle="Mobile" nzSpan="3">{{
                contact?.coordonnees?.mobile | phone
              }}</nz-descriptions-item>
              <nz-descriptions-item nzTitle="E-mail" nzSpan="3">{{
                contact?.coordonnees?.email
              }}</nz-descriptions-item>
            </nz-descriptions>
          </nz-card>
        </ng-template>
      </nz-tag>
    </nz-descriptions-item>
  </nz-descriptions>
</div>
