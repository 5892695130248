import { ContentsModule } from './../../contents/contents.module';
import { SharedModule } from './../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TodoFormComponent } from './components/todo-form/todo-form.component';
import { TodoHeaderComponent } from './components/todo-header/todo-header.component';
import { TodoItemComponent } from './components/todo-item/todo-item.component';
import { TodoListComponent } from './components/todo-list/todo-list.component';
import { StoreModule } from '@ngrx/store';
import * as fromTodo from './store/todo.reducer';
import { EffectsModule } from '@ngrx/effects';
import { TodoEffects } from './store/todo.effects';
import { TodosComponent } from './components/todos/todos.component';
import { TodoFormHeaderComponent } from './components/todo-form-header/todo-form-header.component';
import { TodoFormSujetsComponent } from './components/todo-form-sujets/todo-form-sujets.component';
import { TodoStatusComponent } from './components/todo-status/todo-status.component';
import { NoResultModule } from 'src/app/contents/components/no-result/no-result.module';
import { CoordonneesModule } from 'src/app/contents/components/coordonnees/coordonnees.module';
import { CommunesFranceModule } from 'src/app/contents/components/communes-france/communes-france.module';
import { ButtonsModule } from 'src/app/contents/components/buttons/buttons.module';
import { HeaderModule } from 'src/app/contents/components/header-title/header.module';
import { LayoutModule } from 'src/app/contents/components/layout/layout.module';
import { UiModule } from 'src/app/ui/ui/ui.module';

@NgModule({
  declarations: [
    TodoFormComponent,
    TodoHeaderComponent,
    TodoItemComponent,
    TodoListComponent,
    TodosComponent,
    TodoFormHeaderComponent,
    TodoFormSujetsComponent,
    TodoStatusComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ContentsModule,
    NoResultModule,
    CoordonneesModule,
    HeaderModule,
    CommunesFranceModule,
    LayoutModule,
    ButtonsModule,
    UiModule,
    StoreModule.forFeature(fromTodo.todosFeatureKey, fromTodo.reducer),
    EffectsModule.forFeature([TodoEffects]),
  ],
  exports: [
    TodoFormComponent,
    TodoHeaderComponent,
    TodoItemComponent,
    TodoListComponent,
    TodosComponent,
  ],
})
export class TodosModule {}
