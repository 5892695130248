import { ObjectifComponent } from './../../../../contents/components/objectif/objectif.component';
import { OperationCadrage } from './../../store/operation-cadrage.model';
import { BehaviorSubject } from 'rxjs';
import { NzModalService } from 'ng-zorro-antd/modal';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewContainerRef,
} from '@angular/core';
import { Update } from '@ngrx/entity';

@Component({
  selector: 'app-operation-cadrage-objectif',
  templateUrl: './operation-cadrage-objectif.component.html',
  styleUrls: ['./operation-cadrage-objectif.component.scss'],
})
export class OperationCadrageObjectifComponent implements OnInit {
  modalWidth: number = 500;
  editing$ = new BehaviorSubject<boolean>(false);
  editingListItem$ = new BehaviorSubject<any>(null);
  editingListItemShow$ = new BehaviorSubject<any>(null);

  @Input() cadrage: OperationCadrage | any = null;
  @Output() update: EventEmitter<Update<OperationCadrage>> = new EventEmitter();

  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {}

  onNew(cadrage: OperationCadrage, objectifs?: any): void {
    const modal = this.modal.create({
      nzContent: ObjectifComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: objectifs ? `Modifier les objectifs` : `Nouvels objectifs`,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
    });
    const instance = modal.getContentComponent();
    instance.updateItem$ = new BehaviorSubject<any>(objectifs);
    instance.cancel.subscribe((event: any): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
    instance.list.subscribe((event: any): any => {
      if (!event) {
        return null;
      } else {
        this.onAdd(cadrage, event);
        modal.close();
      }
    });
    instance.update.subscribe((event: any): any => {
      if (!event) {
        return null;
      } else {
        this.onUpdate(cadrage, event);
        modal.close();
      }
    });
  }

  onAdd(cadrage: OperationCadrage, objectifs: any[]): void {
    const items = cadrage?.objectifs?.length
      ? [...cadrage.objectifs, ...objectifs]
      : objectifs;

    const newCadrage: Update<OperationCadrage> = {
      id: cadrage.id,
      changes: {
        ...cadrage,
        objectifs: items,
      },
    };

    this.update.emit(newCadrage);
  }

  onUpdate(cadrage: OperationCadrage, objectifs: any[]): void {
    const newCadrage: Update<OperationCadrage> = {
      id: cadrage.id,
      changes: {
        ...cadrage,
        objectifs: objectifs,
      },
    };

    this.update.emit(newCadrage);
  }

  outputUpdate(cadrage: Update<OperationCadrage>): void {
    this.update.emit(cadrage);
  }

  onDeleteItem(item: any, cadrage: OperationCadrage): void {
    const items = cadrage.objectifs.filter(
      (objectif: any) => objectif.id !== item.id
    );

    const newCadrage: Update<OperationCadrage> = {
      id: cadrage.id,
      changes: {
        ...cadrage,
        objectifs: items,
      },
    };

    this.outputUpdate(newCadrage);
  }

  onDeleteAllList(cadrage: OperationCadrage): void {
    const newCadrage: Update<OperationCadrage> = {
      id: cadrage.id,
      changes: {
        ...cadrage,
        objectifs: [],
      },
    };

    this.outputUpdate(newCadrage);
  }

  onCancel(): void {
    return;
  }
}
