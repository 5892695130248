import { QueryDepartementsService } from './../../service/query-departements.service';
import { DepartementsResolverService } from './../../resolvers/departements-resolver.service';
import { DepartementResolverService } from './../../resolvers/departement-resolver.service';
import { DepartementService } from './../../service/departement.service';
import { RouterModule } from '@angular/router';
import { UserModule } from './../user/user.module';
import { DepartementEffects } from './store/departement.effects';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ContentsModule } from './../../contents/contents.module';
import { SharedModule } from './../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DepartementRoutingModule } from './departement-routing.module';
import { DepartementListComponent } from './components/departement-list/departement-list.component';
import { DepartementItemComponent } from './components/departement-item/departement-item.component';
import { DepartementFormComponent } from './components/departement-form/departement-form.component';
import { DepartementCardComponent } from './components/departement-card/departement-card.component';
import * as fromDepartement from './store/departement.reducer';
import { DepartementDetailsComponent } from './components/departement-details/departement-details.component';
import { DepartementUsersComponent } from './components/departement-users/departement-users.component';
import { DepartementFiltersComponent } from './components/departement-filters/departement-filters.component';
import { DepartementItemHeaderComponent } from './components/departement-item-header/departement-item-header.component';
import { DepartementSelectorComponent } from './components/departement-selector/departement-selector.component';
import { DepartementUserItemComponent } from './components/departement-user-item/departement-user-item.component';
import { NoResultModule } from 'src/app/contents/components/no-result/no-result.module';
import { CoordonneesModule } from 'src/app/contents/components/coordonnees/coordonnees.module';
import { CommunesFranceModule } from 'src/app/contents/components/communes-france/communes-france.module';
import { ButtonsModule } from 'src/app/contents/components/buttons/buttons.module';
import { HeaderModule } from 'src/app/contents/components/header-title/header.module';
import { LayoutModule } from 'src/app/contents/components/layout/layout.module';
import { AvatarModule } from 'src/app/features/avatar/avatar.module';
import { UiModule } from 'src/app/ui/ui/ui.module';
import { DocumentModule } from 'src/app/features/document/document.module';
import { SuiviModule } from 'src/app/features/suivi/suivi.module';

@NgModule({
  declarations: [
    DepartementListComponent,
    DepartementItemComponent,
    DepartementFormComponent,
    DepartementCardComponent,
    DepartementDetailsComponent,
    DepartementUsersComponent,
    DepartementFiltersComponent,
    DepartementItemHeaderComponent,
    DepartementSelectorComponent,
    DepartementUserItemComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ContentsModule,
    RouterModule,
    DepartementRoutingModule,
    UserModule,
    NoResultModule,
    CoordonneesModule,
    HeaderModule,
    CommunesFranceModule,
    ButtonsModule,
    LayoutModule,
    AvatarModule,
    UiModule,
    DocumentModule,
    SuiviModule,
    StoreModule.forFeature(
      fromDepartement.departementsFeatureKey,
      fromDepartement.reducer
    ),
    EffectsModule.forFeature([DepartementEffects]),
  ],
  exports: [
    DepartementListComponent,
    DepartementItemComponent,
    DepartementFormComponent,
    DepartementCardComponent,
    DepartementDetailsComponent,
    DepartementUsersComponent,
    DepartementFiltersComponent,
    DepartementItemHeaderComponent,
    DepartementSelectorComponent,
  ],
  providers: [
    DepartementService,
    DepartementResolverService,
    DepartementsResolverService,
    QueryDepartementsService,
  ],
})
export class DepartementModule {}
