<ng-container *ngIf="participant$ | async as participant">
  <app-item-tabset
    [tabs]="[
      {
        name: 'Identité',
        index: 0,
        template: participantIdentity
      },
      {
        name: 'Situation',
        index: 1,
        template: participantSituation
      },

      {
        name: 'Freins',
        index: 2,
        template: participantFreins
      },
      {
        name: 'Présentation',
        index: 3,
        template: participantPresentation
      }
    ]"
  ></app-item-tabset>

  <ng-template #participantIdentity>
    <app-participant-identity-tab
      [participant]="participant"
    ></app-participant-identity-tab>
  </ng-template>

  <ng-template #participantSituation>
    <app-participant-situation-tab
      [participant]="participant"
    ></app-participant-situation-tab>
  </ng-template>

  <ng-template #participantPresentation>
    <app-participant-presentation-tab
      [participant]="participant"
    ></app-participant-presentation-tab>
  </ng-template>

  <ng-template #participantFreins>
    <app-participant-freins
      [participant]="participant"
    ></app-participant-freins>
  </ng-template>

  <ng-template #participantSorties>
    <app-participant-sortie-tab
      [participant]="participant"
      (select)="onSortieImpact($event)"
    ></app-participant-sortie-tab>
  </ng-template>

  <ng-template #participant_updateDateTpl>
    <nz-row nzAlign="middle" nzGutter="4" nzJustify="end">
      <nz-col nzSpan="22">
        <small
          >Mise à jour {{ participant.dateUpdate | dateFr : "small" }}</small
        >
      </nz-col>
      <nz-col nzSpan="2"></nz-col>
    </nz-row>
  </ng-template>

  <ng-template #prefixMobile>
    <nz-avatar
      nzIcon="mobile"
      nzSize="small"
      class="iconCoordonnees"
      [style]="{ 'background-color': avatarBackgroundColor }"
    ></nz-avatar>
  </ng-template>
  <ng-template #prefixMail>
    <nz-avatar
      nzIcon="mail"
      nzSize="small"
      class="iconCoordonnees"
      [style]="{ 'background-color': avatarBackgroundColor }"
    ></nz-avatar>
  </ng-template>
  <ng-template #preReferent
    ><i nz-icon nzType="user" class="iconCoordonnees"></i
  ></ng-template>
  <ng-template #preHandicap
    ><mat-icon aria-hidden="false" aria-label="Handicap" class="iconCoordonnees"
      >accessibility</mat-icon
    ></ng-template
  >
  <ng-template #preDate
    ><mat-icon aria-hidden="false" aria-label="Handicap" class="iconCoordonnees"
      >today</mat-icon
    ></ng-template
  >

  <ng-template #preSchool
    ><mat-icon aria-hidden="false" aria-label="Handicap" class="iconCoordonnees"
      >school</mat-icon
    ></ng-template
  >
  <ng-template #preNumber>
    <i nz-icon nzType="field-number" class="iconCoordonnees"></i>
  </ng-template>

  <ng-template #preSortie
    ><mat-icon aria-hidden="false" aria-label="Handicap" class="iconCoordonnees"
      >event</mat-icon
    ></ng-template
  >

  <ng-template #prePalce
    ><mat-icon aria-hidden="false" aria-label="Handicap" class="iconMaterial"
      >place</mat-icon
    ></ng-template
  >

  <ng-template #referentAvatar>
    <nz-avatar-group *ngIf="participant?.referents | size">
      <nz-avatar
        [nzText]="participant?.referents[0].avatar"
        nzSize="small"
        [ngStyle]="{
          'background-color': participant?.referents[0].setting?.color
            ? participant?.referents[0].setting?.color
            : default,
          margin: '8px'
        }"
      ></nz-avatar>
      <nz-avatar
        *ngIf="(participant?.referents | size) > 1"
        [nzText]="'+' + ((participant.referents | size) - 1)"
        nzSize="small"
        [ngStyle]="{
          margin: '8px',
          'margin-left': '-13px',
          'background-color': '#ffa940'
        }"
      ></nz-avatar>
    </nz-avatar-group>
  </ng-template>
</ng-container>
