import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { NzSelectSizeType } from 'ng-zorro-antd/select';
import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';
import { OperationId } from 'src/app/components/operation/store/operation.model';
import { UtilitiesState } from 'src/app/contents/components/utilities/store/utilities.reducer';
import * as fromUtilitiesAction from 'src/app/contents/components/utilities/store/utilities.actions';
import * as fromUtilitiesSelector from 'src/app/contents/components/utilities/store/utilities.selectors';
import { flatten, flattenDeep } from 'lodash';
import {
  UtilitiesIds,
  UtilitiesParticipantVariables,
} from 'src/app/contents/components/utilities/store/utilities.model';
import { EtablissementInput } from 'src/app/components/entite/store/entite.model';

@Component({
  selector: 'app-missions-form',
  templateUrl: './missions-form.component.html',
  styleUrls: ['./missions-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MissionsFormComponent implements OnInit, OnChanges, OnDestroy {
  sizeAdresse: NzSelectSizeType = 'default';
  utilitiesDeplacement$: Observable<any> = of(null);
  utilitiesContact$: Observable<any> = of(null);
  utilitaireCivilite = UtilitiesParticipantVariables.civility;
  utilitairesId: UtilitiesIds = UtilitiesIds.participant;
  civiliteUtilitySelected: string = '';

  loading$: Observable<any> = of(null);
  currentStep: number = 0;
  dateFormat: string = 'dd/MM/yyyy';
  currentStep$ = new BehaviorSubject<number>(0);
  subscribe = new Subject();
  today: Date = new Date(Date.now());
  inputModule$ = new BehaviorSubject<OperationId | any>(null);
  competensCles: boolean[] = [true, false];
  currentCompetencesDeBase$ = new BehaviorSubject<any>([]);
  currentCompetencesSpecifiques$ = new BehaviorSubject<any>([]);
  currentSoftskills$ = new BehaviorSubject<any>([]);
  currentEnvironnementsTravail$ = new BehaviorSubject<any>([]);
  adresseUpdateItem$ = new BehaviorSubject<any>(null);
  operationUpdateItem$ = new BehaviorSubject<any>(null);

  @Input() titleMission: string = 'mission';
  @Input() mission: { adress: any; metier: any; contrat: any } | any = null;
  @Input() operationItem: any = null;
  @Input() isOperation: boolean = true;
  @Input() lotItems: any = null;
  @Input() isLot: boolean = false;
  @Input() marcheItems: any = null;
  @Input() isMarche: boolean = false;
  @Input() isContrat: boolean = true;
  @Input() isAdd: boolean = true;
  @Input() isMetierUpdate: string = '';
  @Input() isContratUpdate: string = '';
  @Output() add = new EventEmitter<any>(false);
  @Output() update = new EventEmitter<any>(false);
  @Output() cancel = new EventEmitter<any>(false);
  @Output() previous = new EventEmitter<any>(false);

  //FORM

  metier = new FormControl<any>(Validators.required);

  contacts = new FormArray([], Validators.required);
  contrat = new FormControl<{
    evolution: { code: string; libelle: string };
    evolutionCondition: string;
  }>(
    {
      evolution: { code: '', libelle: '' },
      evolutionCondition: '',
    },
    Validators.required
  );
  operation = new FormControl<{
    denomination: string;
    description: string;
    type: string;
    lot: {
      id: string;
      title: string;
    };
    marche: {
      id: string;
      title: string;
    };
  }>(
    {
      denomination: '',
      description: '',
      type: '',
      lot: {
        id: '',
        title: '',
      },
      marche: {
        id: '',
        title: '',
      },
    },
    Validators.required
  );
  startMission: FormControl<any> = new FormControl();
  endMission: FormControl<any> = new FormControl();
  profilRechercher = new FormControl();
  conditionsEmploi = new FormControl();
  adresse = new FormControl<{
    adresse: {
      properties: {
        label: string;
        score: number;
        housenumber: string;
        id: string;
        type: string;
        name: string;
        postcode: string;
        citycode: string;
        x: number;
        y: number;
        city: string;
        context: string;
        importance: number;
        street: string;
      };
      geometry: {
        type: string;
        coordinates: [number];
      };
    };
    complement: string;
    qpv: string;
  }>({
    adresse: {
      properties: {
        label: '',
        score: 0,
        housenumber: '',
        id: '',
        type: '',
        name: '',
        postcode: '',
        citycode: '',
        x: 0,
        y: 0,
        city: '',
        context: '',
        importance: 0,
        street: '',
      },
      geometry: {
        type: '',
        coordinates: [0],
      },
    },
    complement: '',
    qpv: '',
  });

  heures = new FormControl();
  total = new FormControl(0);
  totalWork = new FormControl(0);
  totalTraining = new FormControl(0);
  totalExtra = new FormControl(0);
  comment = new FormControl();
  legale = new FormControl();
  pricing = new FormControl();

  hours = new FormArray([]);
  isFinished = new FormControl<boolean>(false, Validators.required);
  valid = new FormControl<boolean>(false, Validators.required);
  convention_collective = new FormControl();
  competencesDeBase = new FormArray<any>([]);
  competencesSpecifiques = new FormArray<any>([]);
  softskills = new FormArray<any>([]);
  environnementsTravail = new FormArray<any>([]);

  missionForm: FormGroup = this.fb.group({
    metier: this.metier,
    adresse: this.adresse,
    contrat: this.contrat,
    operation: this.operation,
    competencesDeBase: this.competencesDeBase,
    competencesSpecifiques: this.competencesSpecifiques,
    softskills: this.softskills,
    environnementsTravail: this.environnementsTravail,
  });

  constructor(
    private fb: FormBuilder,
    private utilityStore: Store<UtilitiesState>
  ) {}

  ngOnInit(): void {
    this.form();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const isMissionUpdate = changes?.isMissionUpdate?.currentValue;
    const isContratUpdate = changes?.isContratUpdate?.currentValue;

    this.formSet(
      changes.mission.currentValue,
      changes.operationItem.currentValue
    );

    if (isContratUpdate === 'UPDATE_CONTRAT') {
      this.currentStep = 4;

      this.isAdd = false;
      const prevMission = this.missionForm.value;
      this.missionForm.patchValue({
        ...prevMission,
        contrat: null,
      });

      return;
    }

    if (isMissionUpdate === 'UPDATE_METIER') {
      this.isAdd = false;
      this.currentStep = 0;

      const prevMission = this.missionForm.value;

      this.missionForm.patchValue({
        ...prevMission,
        metier: null,
      });

      return;
    }

    this.isAdd = true;
  }

  ngOnDestroy(): void {
    this.subscribe.next(false);
    this.subscribe.complete();
  }

  onStepBack(
    step: number,
    isAdd: boolean,
    isMissionUpdate: any,
    isContratUpdate: any
  ): void {
    const nextStep =
      isAdd && step > 0
        ? step - 1
        : !isAdd && isMissionUpdate === 'UPDATE_METIER' && step > 0 && step < 4
        ? step - 1
        : !isAdd && isContratUpdate === 'UPDATE_CONTRAT' && step > 4
        ? step - 1
        : 0;

    this.currentStep = nextStep;

    this.currentStep$.next(nextStep);
    this.previous.emit(step);
  }
  onStepNext(
    step: number,
    isAdd: boolean,
    isMissionUpdate: any,
    isContratUpdate: any
  ): void {
    const nextStep =
      isAdd && step < 7
        ? step + 1
        : !isAdd && isMissionUpdate === 'UPDATE_METIER' && step < 4
        ? step + 1
        : !isAdd && isContratUpdate === 'UPDATE_CONTRAT' && step > 4 && step < 7
        ? step + 1
        : step + 1;

    this.currentStep = nextStep;
    this.currentStep$.next(nextStep);
  }
  form(): void {
    this.formInit();
  }

  formInit(): void {
    this.metier = new FormControl({}, Validators.required);
    this.contrat = new FormControl<any>({}, Validators.required);
    this.operation = new FormControl<{
      denomination: string;
      description: string;
      type: string;
      lot: {
        id: string;
        title: string;
      };
      marche: {
        id: string;
        title: string;
      };
    }>(
      {
        denomination: '',
        description: '',
        type: '',
        lot: {
          id: '',
          title: '',
        },
        marche: {
          id: '',
          title: '',
        },
      },
      Validators.required
    );
    this.adresse = new FormControl<{
      adresse: {
        properties: {
          label: string;
          score: number;
          housenumber: string;
          id: string;
          type: string;
          name: string;
          postcode: string;
          citycode: string;
          x: number;
          y: number;
          city: string;
          context: string;
          importance: number;
          street: string;
        };
        geometry: {
          type: string;
          coordinates: [number];
        };
      };
      complement: string;
      qpv: string;
    }>(
      {
        adresse: {
          properties: {
            label: '',
            score: 0,
            housenumber: '',
            id: '',
            type: '',
            name: '',
            postcode: '',
            citycode: '',
            x: 0,
            y: 0,
            city: '',
            context: '',
            importance: 0,
            street: '',
          },
          geometry: {
            type: '',
            coordinates: [0],
          },
        },
        complement: '',
        qpv: '',
      },
      Validators.required
    );

    this.convention_collective = new FormControl({});
    this.isFinished = new FormControl(false, Validators.required);
    this.valid = new FormControl(true, Validators.required);

    this.competencesDeBase = new FormArray<any>([]);
    this.competencesSpecifiques = new FormArray<any>([]);
    this.softskills = new FormArray<any>([]);
    this.environnementsTravail = new FormArray<any>([]);

    this.profilRechercher = new FormControl();
    this.conditionsEmploi = new FormControl();
  }

  formSet(mission: any, operationItem: any): void {
    if (!mission) {
      this.isAdd = true;
      this.operationUpdateItem$.next(operationItem);

      this.missionForm.patchValue({
        operation: operationItem,
      });
      return;
    }

    const { metier, contrat, adresse, operation } = mission;

    this.isAdd = false;
    this.adresseUpdateItem$.next(adresse);
    this.missionForm.patchValue({
      adresse: adresse?.adresse?.properties?.label ? adresse : null,
      contrat: contrat,
      metier: metier,
      operation: operation,
    });

    this.operationUpdateItem$.next(operation);
    return;
  }

  loadUtilities(): void {
    this.utilityStore.dispatch(fromUtilitiesAction.loadMissionUtilities());
  }
  loadUtilitiesDeplacement(): void {
    this.utilityStore.dispatch(
      fromUtilitiesAction.loadMissionDeplacementUtilities()
    );
  }

  //AJOUTER UN MÉTIER
  onAddMetier(appellation: any): void {
    if (!appellation) return;

    const competencesSpeficiques = appellation?.groupesCompetencesSpecifiques
      ?.length
      ? flatten(
          flattenDeep(appellation.groupesCompetencesSpecifiques).map(
            (el: any) => flattenDeep(el?.competences)
          )
        )
      : [];

    this.missionForm.patchValue({ metier: appellation });
    this.currentCompetencesDeBase$.next(appellation?.competencesDeBase);
    this.onMissionCompetencesDeBaseChanges(appellation?.competencesDeBase);
    this.currentCompetencesSpecifiques$.next(competencesSpeficiques);
    this.onMissionCompetencesSpecifiquesChanges(competencesSpeficiques);
    this.currentSoftskills$.next(appellation?.softskills);
    this.onMissionCompetencesTransversalesChanges(appellation?.softskills);
    this.currentEnvironnementsTravail$.next(appellation?.environnementsTravail);
    this.onMissionEnvironnementChanges(appellation?.environnementsTravail);
  }

  onCleanMission(): void {
    this.missionForm.patchValue({
      metier: null,
      contrat: null,
      competencesDeBase: null,
      competencesSpecifiques: null,
      softskills: null,
      environnementsTravail: null,
    });
    this.currentStep = 1;
  }

  onValidationMission(): void {
    this.currentStep = 3;
  }

  //COMPETENCES DE BASE DU MÉTIER
  get competencesDeBaseArray() {
    return this.missionForm.get('competencesDeBase') as FormArray;
  }

  onMissionCompetencesDeBaseChanges(items: any[]): void {
    const size: number = items?.length || 0;

    const savoirArray: any[] =
      size > 0 ? items.filter((el) => el.typeCompetence === 'Savoir') : [];

    const savoirFaireArray: any[] =
      size > 0 ? items.filter((el) => el.typeCompetence === 'SavoirFaire') : [];

    const previousMetier = this.missionForm.get('metier')?.value;
    const competencesCle: any[] = this.competenCleFilter(items);

    this.missionForm.patchValue({
      metier: {
        ...previousMetier,
        competencesCleDeBaseSize:
          size > 0 ? this.competenCleFilter(items)?.length : 0,
        competencesDeBaseSize: size,
        partCompetencesDeBaseSize: competencesCle?.length / size,
        competencesDeBase: items,
        competencesDeBaseType: {
          Savoir: savoirArray,
          SavoirFaire: savoirFaireArray,
        },
      },
    });

    this.currentCompetencesDeBase$.next(items);
  }

  //COMPETENCES SPECIFIQUES DU MÉTIER
  get competencesSpecifiquesArray() {
    return this.missionForm.get('competencesSpecifiques') as FormArray;
  }

  onMissionCompetencesSpecifiquesChanges(items: any[]): void {
    const size: number = items?.length;
    const savoirArray: any[] =
      size > 0 ? items.filter((el) => el.typeCompetence === 'Savoir') : [];

    const savoirFaireArray: any[] =
      size > 0 ? items.filter((el) => el.typeCompetence === 'SavoirFaire') : [];

    const previousMetier = this.missionForm.get('metier')?.value;
    const competencesCle: any[] = this.competenCleFilter(items);

    this.missionForm.patchValue({
      metier: {
        ...previousMetier,
        competencesSpecifiquesSize: size > 0 ? size : 0,
        competencesCleSpecifiquesSize: size,
        competencesSpecifiques: items,
        partCompetencesSpeficiquesSize: competencesCle?.length
          ? competencesCle?.length / size
          : 0,
        competencesSpecifiquesType: {
          Savoir: savoirArray,
          SavoirFaire: savoirFaireArray,
        },
      },
    });

    this.currentCompetencesSpecifiques$.next(items);
  }

  competenCleFilter(items: any[]): any[] {
    return items?.length ? items.filter((el) => el?.competenceCle) : [];
  }

  //COMPÉTENCES TRANSVERSALES DU MÉTIER
  get competencesSkillsArray() {
    return this.missionForm.get('softskills') as FormArray;
  }
  onMissionCompetencesTransversalesChanges(items: any[]): void {
    const previousMetier = this.missionForm.get('metier')?.value;
    this.missionForm.patchValue({
      metier: {
        ...previousMetier,
        softskills: items,
      },
    });
    this.currentSoftskills$.next(items);
  }

  //ENVIRONNEMENT DE TRAVAIL
  get environnementsTravailArray() {
    return this.missionForm.get('environnementsTravail') as FormArray;
  }

  onMissionEnvironnementChanges(items: any[]): void {
    const previousMetier = this.missionForm.get('metier')?.value;
    this.missionForm.patchValue({
      metier: {
        ...previousMetier,
        environnementsTravail: items,
      },
    });
    this.currentEnvironnementsTravail$.next(items);
  }

  //CONTRAT DÉTAILS
  onAddContratDetail(detail: any): void {
    const contrat = this.missionForm.get('contrat')?.value;

    this.missionForm.patchValue({
      contrat: {
        ...contrat,
        ...detail,
      },
    });
  }

  //CONTRAT REMUNÉRATION
  onAddContratRemuneration(elementSalaire: any): void {
    const contrat = this.missionForm.get('contrat')?.value;

    this.missionForm.patchValue({
      contrat: {
        ...contrat,
        ...elementSalaire,
      },
    });
  }

  //CONTRAT TEMPS DE TRAVAIL
  onAddContratTravail(travail: any): void {
    const contrat = this.missionForm.get('contrat')?.value;

    this.missionForm.patchValue({
      contrat: {
        ...contrat,
        ...travail,
      },
    });
  }

  //CONTRAT EVOLUTION
  onAddContratEvolution(item: any): void {
    const contrat = this.missionForm.get('contrat')?.value;
    this.missionForm.patchValue({
      contrat: {
        ...contrat,
        evolution: item?.evolution ? item.evolution : '',
        evolutionCondition: item?.evolutionCondition
          ? item?.evolutionCondition
          : '',
      },
    });
  }

  //PROJET/OPERATION
  onAddOperation(operation: any): void {
    this.missionForm.patchValue({ operation });
  }

  //ADRESSE
  onAddAdresse(input: any): void {
    if (!input) return;
    const prevAdresse = this.missionForm.get('adresse')?.value;
    this.missionForm.patchValue({
      adresse: {
        ...prevAdresse,
        adresse: input,
      },
    });
  }

  onAdresseClear(): void {
    this.missionForm.get('adresse')?.reset;
  }

  onAddAdresseComplement(complement: any): void {
    if (!complement) return;
    this.missionForm.patchValue({
      adresse: {
        complement: complement,
      },
    });
  }

  //VALIDATION ADD OR UPDATE

  onValidation(): void {
    if (!this.missionForm.valid) return;

    const mission = this.missionForm.value;

    delete mission.competencesDeBase;
    delete mission.competencesSpecifiques;
    delete mission.softskills;
    delete mission.environnementsTravail;
    delete mission.metier.groupesCompetencesSpecifiques;

    this.add.emit(mission);

    this.missionForm.reset();
  }

  onUpdateValidation(): void {
    const mission = this.missionForm.value;

    delete mission.competencesDeBase;
    delete mission.competencesSpecifiques;
    delete mission.softskills;
    delete mission.environnementsTravail;
    delete mission.metier.groupesCompetencesSpecifiques;

    this.update.emit(mission);
    this.missionForm.reset();
  }

  //CANCEL

  onCancel(): void {
    this.cancel.emit(true);
  }
}
