<div class="overflow-hidden">
  <div [ngSwitch]="view$ | async">
    <div *ngSwitchCase="'Note'" class="overflow-hidden">
      <app-item-event
        [size]="'150px'"
        (add)="onAdd($event, fiche, candidat)"
        (cancel)="onCancel()"
      ></app-item-event>
    </div>
    <div *ngSwitchDefault class="overflow-hidden">
      <app-progress-loading *ngIf="loading$ | async"></app-progress-loading>

      <app-table-suivis
        *ngIf="!(loading$ | async)"
        [scroll]="{ x: '100%', y: 'calc(100vh - 40rem)' }"
        [isCanAdd]="false"
        [source]="notes$ | async"
        [selectTyped]="selectType"
        (update)="onUpdate($event, fiche, candidat)"
        (delete)="onDelete($event, fiche, candidat)"
        (deleteAll)="onDeleteAll(fiche, candidat)"
      ></app-table-suivis>
    </div>
  </div>
</div>
