import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, of, Subject, takeUntil } from 'rxjs';
import { UtilitiesState } from 'src/app/contents/components/utilities/store/utilities.reducer';
import * as fromUtilitiesSelector from 'src/app/contents/components/utilities/store/utilities.selectors';
import * as fromUtilitiesAction from 'src/app/contents/components/utilities/store/utilities.actions';

@Component({
  selector: 'app-participant-diagnostic-form-pole-emploi',
  templateUrl: './participant-diagnostic-form-pole-emploi.component.html',
  styleUrls: ['./participant-diagnostic-form-pole-emploi.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantDiagnosticFormPoleEmploiComponent
  implements OnInit, OnChanges, OnDestroy
{
  subscribe = new Subject();
  inscription_period$: Observable<any> = of([]);
  //FORM FIELD
  poleEmploiForm: FormGroup = this.fb.group({});
  identifiant: FormControl<any> = new FormControl('');
  agence: FormControl<any> = new FormControl('');
  inscription: FormControl<{ label: string; value: string } | any> =
    new FormControl({ label: '', value: '' });

  @Input() poleEmploiInput$: Observable<any> = of(null);
  @Output() add = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private utilitiesStore: Store<UtilitiesState>
  ) {}

  ngOnInit(): void {
    this.getInscriptionPeriod();
    this.loadInscriptionPeriod();
    this.form();
    this.onFormChanges();
  }
  ngOnChanges(changes: SimpleChanges): void {}

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getInscriptionPeriod(): void {
    this.inscription_period$ = this.utilitiesStore.select(
      fromUtilitiesSelector.periodInscription
    );
  }

  loadInscriptionPeriod(): void {
    this.utilitiesStore.dispatch(
      fromUtilitiesAction.loadPeriodInscriptionReferentiel()
    );
  }

  form(): void {
    this.formInit();
    this.formCreate();
    this.formSet();
  }

  formInit(): void {
    this.identifiant = new FormControl<string>('');
    this.inscription = new FormControl<{ label: string; value: string } | any>(
      {}
    );
  }

  formCreate(): void {
    this.poleEmploiForm = this.fb.group({
      inscription: this.inscription,
      identifiant: this.identifiant,
    });
  }

  formSet(): void {
    this.poleEmploiInput$
      .pipe(takeUntil(this.subscribe))
      .subscribe((poleEmploi) => {
        if (!poleEmploi) return;
        this.poleEmploiForm.patchValue({ ...poleEmploi });
      });
  }

  checkboxChanges(event: boolean, type: string): void {
    // switch (type) {
    //   case 'mobile':
    //     this.numeriqueForm.patchValue({
    //       mobile: event,
    //     });
    //     break;
    //   case 'ordinateur':
    //     this.numeriqueForm.patchValue({
    //       ordinateur: event,
    //     });
    //     break;
    //   case 'internet':
    //     this.numeriqueForm.patchValue({
    //       internet: event,
    //     });
    //     break;
    //   default:
    //     break;
    // }
  }

  onFormChanges(): void {
    this.poleEmploiForm.valueChanges
      .pipe(takeUntil(this.subscribe))
      .subscribe((values) => this.add.emit(values));
  }
}
