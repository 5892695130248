import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { DepartementState } from '../../store/departement.reducer';
import * as fromDepartementAction from '../../store/departement.actions';
import * as fromDepartementSelector from '../../store/departement.selectors';

@Component({
  selector: 'app-departement-filters',
  templateUrl: './departement-filters.component.html',
  styleUrls: ['./departement-filters.component.scss'],
})
export class DepartementFiltersComponent implements OnInit {
  title: string = 'Services';
  filtersActivated: boolean = false;
  filtersOrders: any[] = [];
  cleaner: boolean = false;

  constructor(private departementStore: Store<DepartementState>) {}

  ngOnInit(): void {}

  loadDepartments(): void {
    this.filtersActivated = false;
    this.filtersOrders = [];
    this.cleaner = false;

    this.departementStore.dispatch(fromDepartementAction.loadDepartements());
  }

  filtersByTitle(event: string): void {
    if (!event) {
      this.loadDepartments();
    } else {
      const type = 'title';
      this.filtersActivated = true;
      this.departementStore.dispatch(
        fromDepartementAction.queryDepartements({ filter: { event, type } })
      );
    }
  }

  onClear(): void {
    this.loadDepartments();
  }
}
