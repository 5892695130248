<app-layout-list
  *ngIf="
    (data$ | async).entites.length || (entites$ | async) | size;
    else noResults
  "
  [new]="new"
  [card]="card"
  [source]="(data$ | async)?.entites"
></app-layout-list>

<ng-template #new>
  <app-card-new
    [type]="'domain'"
    [height]="'250px'"
    [title]="newCardTitle"
    [description]="''"
    [definition]="newCardDefinition"
    (add)="onNewEntiteForm()"
  ></app-card-new>
</ng-template>

<ng-template #card let-item let-favoris let-position="idx">
  <app-entite-card
    [entite]="item"
    [favoris]="favoris$ | async"
  ></app-entite-card>
</ng-template>

<ng-template #noResults>
  <app-no-result
    (new)="onNewEntiteForm()"
    [icon]="'domain_add'"
    [notitle]="'Aucune entité'"
    [addtitle]="'Ajouter une entité'"
    [description]="newCardDefinition"
  ></app-no-result>
</ng-template>

<ng-template #entiteTitleForm>
  <app-header-title [icon]="'domaine'" [title]="title"></app-header-title>
</ng-template>
