import { GeoAdresse } from 'src/app/contents/components/communes-france/store/geo-adresse.model';
import { Adresse } from 'src/app/models/adresse.model';

export interface Operation {
  auths: string[];
  acces?: any;
  roles: any;
  auteur: any;
  link: string;
  logoProgramme: string | string[];
  organisationsId: string[];
  type: string;
  nature?: string;
  organisation: {
    id: string;
    coordonnees?: {
      email: string;
      fixe: string;
      mobile: string;
      web: string;
    };
    emailCommunication?: string;
    geo_adresse?: string;
    nom_raison_sociale: string;
    logo?: string;
    siren?: string;
    siret?: string;
  };
  equipe: any[];
  equipeAcces?: any;
  equipeIds?: string[];
  ressource?: any[];
  departement: {
    id: string;
    title?: string;
  };
  denomination: string;
  financeurs: any[];
  financeursIds?: string[];
  entitesIds?: string[];
  contactsIds?: string[];
  participantsIds?: string[];
  soutiens: any[];
  status?: string;
  completed?: boolean;
  description: {
    definition: string;
    adresse: {
      adresse: Adresse | GeoAdresse | any;
      complement: string;
      qpv: any;
    };
    calendrier: {
      dateStart: Date | any;
      dateEnd: Date | any;
      dateUpdate: Date | any;
      duree: number;
      businessHours?: number;
    };
    conventions?: any;
    objectifs?: any;
    objectifsType?: string;
    budget?: any;
  };
  params: {
    parent: boolean;
  };

  dateCreate: Date | any;
  userUpdate: any;
  updateKey: string;
  courrier: any[];
  services?: any;
  servicesIds?: any;
  stats: any;
  metadata?: any;
  adminIds: string[];
  objectifs?: any;
  financeursMap?: any;
  realisations?: number;
  progression?: number;
  numeroMarche?: string;
  service?: string;
  cadrage?: any;
}

export interface OperationId extends Operation {
  id: string;
}

export interface OperationSmall {
  acces?: any;
  auteur?: any;
  progression?: number;
  logoProgramme: string | string[];
  type: string;
  services?: any;
  servicesIds?: any;
  financeursIds?: string[];
  entitesIds?: string[];
  contactsIds?: string[];
  participantsIds?: string[];
  adminIds: string[];
  organisation: {
    id: string;
    custom?: {
      logo: string;
    };
    etablissement?: {
      nom_raison_sociale: string;
      siret: string;
      siren: string;
      geo_adresse: string;
    };
  };
  equipe?: any[];
  equipeIds?: string[];
  equipeAcces?: any;

  denomination: string;
  financeurs: any[];
  status?: string;
  description: {
    definition: string;
    calendrier: {
      dateStart: Date | any;
      dateEnd: Date | any;
      dateUpdate: Date | any;
      duree: number;
      businessHours?: number;
    };
  };

  metadata?: any;
  financeursMap?: any;
  role?: string;
}

export interface OperationSmallId extends OperationSmall {
  id: string;
}

export interface OperationCadrage {
  id: string;
  denomination: string;
  auteur: any;
  departement: any;
  version: number | number[];
  codeProjet: any;
  description: {
    definition: string;
    adresse: {
      adresse: Adresse | GeoAdresse | any;
      complement: string;
      qpv: any;
    };
    calendrier: {
      dateStart: Date | any;
      dateEnd: Date | any;
      dateUpdate: Date | any;
      duree: number;
      businessHours?: number;
    };
    conventions?: any;
    objectifs?: any;
    budget?: any;
  };
  equipe: any[];
  context: {
    textValue: string;
    textHTML: string;
  };
  finality: {
    textValue: string;
    textHTML: string;
  };
  objectifsIndicateurs: any;
  livrables: any[];
  ressources: any[];
  pilotage: any[];
  plans: any[];
  financeurs: any[];
  risques: any[];
  partenariats: any[];
  logoProgramme: any;
  lienAutreProjet: any[];
  dateValidation: Date | any;
  validation: boolean;
  userValidation?: any;
  steps?: any[];
  benefices: {
    textValue: string;
    textHTML: string;
  };
  methodologie: {
    textValue: string;
    textHTML: string;
  };

  dateUpdate: Date | any;
  duration?: number;
  role?: string;
}

// export interface OperationFinanceur extends EntiteSMALLid {
//   financementType?: string;
//   contacts?: OperationFinanceurContact[];
//   criteres?: Critere[];
//   objectifs?: Objectif[];
//   listeValidation?: any[];
//   documents?: DocumentId[];
//   budget?: any[] | any[];
//   stats: any;
// }

export enum OperationQuery {
  title = 'title',
  service = 'service',
  financeurs = 'financeurs',
  year = 'year',
  letter = 'letter',
  type = 'type',
  complete = 'complete',
  status = 'status',
}
