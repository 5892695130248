<ng-container *ngIf="participant$ | async as participant">
  <nz-row nzAlign="top" nzGutter="4" nzJustify="center">
    <nz-col nzSpan="24">
      <app-time-indicators
        [bordeless]="false"
        [start]="participant?.dateStart"
        [end]="participant?.dateEnd"
        [distance]="'month'"
        [endTitle]="'Sortie'"
        [isIntermediaire]="false"
        [info]="true"
        [step]="16"
        [dates]="true"
        [timeFormat]="'small'"
        [events]="suivis$ | async"
        [status]="participant?.sortiProgramme || participant?.sortiInscription"
      ></app-time-indicators>
    </nz-col>
  </nz-row>

  <nz-space nzDirection="vertical"></nz-space>

  <div class="participant_dashboard_container">
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="center">
      <nz-col nzSpan="12">
        <nz-card nzSize="small" [nzTitle]="cardTitle"></nz-card>
      </nz-col>
      <nz-col nzSpan="12">
        <nz-card nzSize="small" [nzTitle]="cardTitle">
          <app-participant-indicator
            [frenchLevel]="false"
            [workReady]="false"
            [house]="false"
            [lawAcces]="false"
            [mobility]="false"
            [finance]="false"
            [justice]="false"
            [disponility]="false"
          ></app-participant-indicator>
        </nz-card>
      </nz-col>
    </nz-row>
    <nz-space nzDirection="vertical"></nz-space>
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="center">
      <nz-col nzSpan="12">
        <nz-card nzSize="small" [nzTitle]="cardTitle"></nz-card>
      </nz-col>
      <nz-col nzSpan="12">
        <nz-card nzSize="small" [nzTitle]="cardTitle"></nz-card>
      </nz-col>
    </nz-row>
  </div>

  <ng-template #cardTitle>
    <app-header-title [icon]="'query_stats'"></app-header-title>
  </ng-template>
</ng-container>
