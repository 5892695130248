import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { OrganisationId } from '../../store/organisation.model';
import { OrganisationState } from '../../store/organisation.reducer';
import * as fromOrganisationSelector from '../../store/organisation.selectors';

@Component({
  selector: 'app-organisation-detail',
  templateUrl: './organisation-detail.component.html',
  styleUrls: ['./organisation-detail.component.scss'],
})
export class OrganisationDetailComponent implements OnInit {
  organisation$: Observable<OrganisationId | any> = of(null);
  constructor(private organisationStore: Store<OrganisationState>) {}

  ngOnInit(): void {
    this.getOrganisation();
  }

  getOrganisation(): void {
    this.organisation$ = this.organisationStore.select(
      fromOrganisationSelector.organisation
    );
  }
}
