<nz-row nzAlign="middle" nzGutter="2" nzJustify="start">
  <nz-col nzSpan="3"></nz-col>

  <nz-col nzSpan="1">
    <nz-badge
      [nzCount]="participants$ | async | size"
      *ngIf="filtersActivated"
      nzStandalone="true"
      [nzOffset]="[-20, 0]"
    ></nz-badge>
  </nz-col>
  <nz-col nzSpan="14">
    <app-search-bar
      [filter]="true"
      [cleaner]="cleaner"
      (clear)="onClear()"
      [placeholder]="'Recherche par nom du participant'"
      (searching)="filtersByLastName($event)"
    ></app-search-bar>
  </nz-col>
  <nz-col nzSpan="4">
    <!-- <button
      nz-button
      nzShape="circle"
      nz-popover
      [nzPopoverContent]="contactsFilterTemplate"
      nzPopoverPlacement="bottomRight"
      nzPopoverTrigger="click"
    >
      <i nz-icon nzType="filter"></i>
      
    </button> -->
    <button
      nz-button
      nzType="text"
      [routerLink]="['/informationsCollectives']"
      nz-tooltip
      [nzTooltipMouseEnterDelay]="0.5"
      nzTooltipPlacement="bottom"
      nzTooltipTitle="Liste d'informations collectives"
      (click)="setCurrentView('informationsCollectives')"
      class="buttonCls"
    >
      <mat-icon>recent_actors</mat-icon>
    </button>
  </nz-col>
  <nz-col nzSpan="2"> </nz-col>
</nz-row>
