<nz-card
  nzHoverable
  [nzTitle]="prescripteurCadreHeader"
  [nzExtra]="prescripteurCardExta"
>
  <ng-template #prescripteurCadreHeader>
    <app-header-title [icon]="'domain'" [subtitle]="true"></app-header-title>
  </ng-template>
  <div class="prescripteur_title">
    <h5 nz-typography>
      {{ item?.denomination | carLimitation: 80 }}
    </h5>
  </div>
  <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
  <div class="prescripteur_card_naf">
    <P nz-typography>
      {{ item?.activite_principale_libelle?.intitule_naf | carLimitation: 80 }}
    </P>
  </div>
  <ng-container *ngIf="item?.economie_sociale_solidaire === 'O'">
    <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
    <nz-tag nzColor="geekblue">{{
      item?.economie_sociale_solidaire === "O" ? "Structure ESS" : ""
    }}</nz-tag>
  </ng-container>
</nz-card>

<ng-template #prescripteurCardExta>
  <button
    nz-button
    nzShape="circle"
    nz-dropdown
    [nzDropdownMenu]="prescripteurMenu"
    nzTrigger="click"
    nzPlacement="bottomCenter"
    (click)="$event.stopPropagation()"
    nzType="text"
  >
    <mat-icon class="primaryBlue">more_vert</mat-icon>
  </button>
</ng-template>

<nz-dropdown-menu #prescripteurMenu="nzDropdownMenu">
  <ul nz-menu>
    <li
      nz-menu-item
      nzDanger
      nz-popconfirm
      [nzPopconfirmTitle]="
        'Voulez-vous supprimer le prescripteur ' + item?.denomination + ' ?'
      "
      nzTooltipTrigger="click"
      (nzOnCancel)="onCancel()"
      (nzOnConfirm)="onDelete(item)"
    >
      <span>Supprimer</span>
      <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
      <i nz-icon nzType="delete"></i>
    </li>
  </ul>
</nz-dropdown-menu>
