import { EntiteId } from './../../../components/entite/store/entite.model';
import { EntiteValidatorComponent } from './../entite-validator/entite-validator.component';
import { NzTableComponent } from 'ng-zorro-antd/table';
import { takeUntil } from 'rxjs/operators';
import { OperationSmallId } from './../../../components/operation/store/operation.model';
import { ItemOperationsSelectComponent } from './../item-operations-select/components/item-operations-select.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Observable, of, Subject, BehaviorSubject } from 'rxjs';
import { first, isEqual, last, uniqWith, sortBy } from 'lodash';
import { format, fromUnixTime } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Update } from '@ngrx/entity';

@Component({
  selector: 'app-item-operations',
  templateUrl: './item-operations.component.html',
  styleUrls: ['./item-operations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemOperationsComponent implements OnInit, OnChanges, OnDestroy {
  title: string = 'Nouveau projet';
  newCardTitle: string = 'Nouveau projet';
  @Input() newCardDescription: string =
    "Ajouter l'entité à un projet existant.";
  newCardDefinition: string = '';
  view$ = new BehaviorSubject<string>('list');
  currentSelected = new BehaviorSubject<any>(null);
  modalWidth: number = 640;
  subscribe = new Subject();
  total: number = 0;
  pageCache = [];
  pageIndexe = 1;
  nav: any = null;
  loading: boolean = true;
  filtered: boolean = false;

  @Input() operations$: Observable<OperationSmallId[] | any> = of(null);
  @Input() identity: string | any;
  @Input() status: boolean = false;
  @Input() role: boolean = false;
  @Output() add = new EventEmitter<OperationSmallId | any>(false);
  @Output() delete = new EventEmitter<OperationSmallId | any>(false);
  @Output() update = new EventEmitter<any>(false);

  @ViewChild('virtualTable', { static: false })
  nzTableComponent?: NzTableComponent<OperationSmallId>;

  @ViewChild('projetFormTitle', { static: false }) projetFormTitle:
    | TemplateRef<any>
    | any;

  scrollToIndex(index: number): void {
    this.nzTableComponent?.cdkVirtualScrollViewport?.scrollToIndex(index);
  }

  trackByIndex(_: number, data: OperationSmallId): string {
    return data.id;
  }
  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.operations$.currentValue) return;
  }

  ngOnDestroy(): void {
    this.subscribe.next('');
    this.subscribe.complete();
    this.currentSelected.next(null);
  }

  onNewProjet(): void {
    const modal = this.modal.create({
      nzContent: ItemOperationsSelectComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.projetFormTitle,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });
    const instance = modal.getContentComponent();
    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });

    instance.select.subscribe((operation: OperationSmallId): void => {
      this.onAdd(operation);
      modal.close();
    });
    instance.identity = this.identity;
    instance.operationsAlreayExist$ = this.operations$;
  }

  onAdd(operation: OperationSmallId): void {
    if (!operation) return;
    this.add.emit(operation);
  }
  onSelect(operation: OperationSmallId | any): void {
    this.currentSelected.next(operation);
    this.view$.next('details');
  }
  onUpdate(operation: Update<any>): void {
    if (!operation) return;
    this.update.emit(operation);
  }
  onDelete(operation: any): void {
    if (!operation) return;
    this.delete.emit(operation);
  }
  onCancelDelete(): void {
    return;
  }

  onBack(): void {
    this.view$.next('list');
    this.currentSelected.next(null);
  }
}
