import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ParticipantPlanning } from './participant-planning.model';
import * as ParticipantPlanningActions from './participant-planning.actions';

export const participantPlanningsFeatureKey = 'participantPlannings';

export interface ParticipantPlanningState
  extends EntityState<ParticipantPlanning> {
  loading: boolean;
  event: any;
  joursFeries: any;
  filterMoments: string[];
  error: any;
}

export const adapter: EntityAdapter<ParticipantPlanning> =
  createEntityAdapter<ParticipantPlanning>();

export const initialState: ParticipantPlanningState = adapter.getInitialState({
  loading: false,
  event: undefined,
  joursFeries: undefined,
  filterMoments: [
    'Ce mois',
    '3 mois après',
    '6 mois après',
    '3 mois avant',
    '6 mois avant',
  ],

  error: undefined,
});

export const reducer = createReducer(
  initialState,

  //LOAD JOURS FERIES
  on(ParticipantPlanningActions.loadJoursFerier, (state, action) => {
    return {
      ...state,
      error: undefined,
      loading: false,
    };
  }),
  on(ParticipantPlanningActions.loadJoursFerierSuccess, (state, action) => {
    return {
      ...state,
      joursFeries: action.jours,
      loading: false,
    };
  }),
  on(ParticipantPlanningActions.loadJoursFerierFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
      loading: false,
    };
  }),

  //LOAD EVENTS
  on(ParticipantPlanningActions.loadParticipantPlannings, (state, action) => {
    return {
      ...state,
      error: undefined,
      loading: true,
    };
  }),
  on(
    ParticipantPlanningActions.loadParticipantPlanningsSuccess,
    (state, action) => adapter.setAll(action.events, state)
  ),
  on(
    ParticipantPlanningActions.loadParticipantPlanningsSuccess,
    (state, action) => {
      return {
        ...state,
        error: undefined,
        loading: false,
      };
    }
  ),
  on(
    ParticipantPlanningActions.loadParticipantPlanningsFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
  ),

  //LOAD EVENTS FILTERED
  on(
    ParticipantPlanningActions.loadParticipantPlanningsFiltered,
    (state, action) => {
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    }
  ),
  on(
    ParticipantPlanningActions.loadParticipantPlanningsFilteredSuccess,
    (state, action) => adapter.setAll(action.events, state)
  ),
  on(
    ParticipantPlanningActions.loadParticipantPlanningsFilteredSuccess,
    (state, action) => {
      return {
        ...state,
        error: undefined,
        loading: false,
      };
    }
  ),
  on(
    ParticipantPlanningActions.loadParticipantPlanningsFilteredFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
  ),

  //ADD
  on(ParticipantPlanningActions.addParticipantPlanning, (state, action) => {
    return {
      ...state,
      error: undefined,
      loading: false,
    };
  }),
  on(
    ParticipantPlanningActions.addParticipantPlanningSuccess,
    (state, action) => adapter.addOne(action.event, state)
  ),
  on(
    ParticipantPlanningActions.addParticipantPlanningFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
  ),

  //UPDATE
  on(ParticipantPlanningActions.updateParticipantPlanning, (state, action) =>
    adapter.updateOne(action.event, state)
  ),
  on(
    ParticipantPlanningActions.updateParticipantPlanningFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
  ),

  //DELETE EVENT
  on(ParticipantPlanningActions.deleteParticipantPlanning, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(
    ParticipantPlanningActions.deleteParticipantPlanningFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
  ),

  //DELETE EVENTS
  on(ParticipantPlanningActions.deleteParticipantPlannings, (state, action) =>
    adapter.removeAll(state)
  ),
  on(
    ParticipantPlanningActions.deleteParticipantPlanningsFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
  ),

  //CLEAR
  on(ParticipantPlanningActions.clearParticipantPlannings, (state) =>
    adapter.removeAll(state)
  )
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
