import { takeUntil } from 'rxjs/operators';
import { Module, ModuleSmallId } from './../../store/module.model';
import { OperationId } from './../../../operation/store/operation.model';
import { ModuleFormComponent } from './../module-form/module-form.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewContainerRef,
} from '@angular/core';
import { of, Observable, BehaviorSubject, Subject } from 'rxjs';
import { Store } from '@ngrx/store';

import { ModuleState } from '../../store/module.reducer';
import * as fromModuleAction from '../../store/module.actions';
import * as fromModuleSelector from '../../store/module.selectors';

@Component({
  selector: 'app-module-filters',
  template: `
    <nz-row
      nzAlign="middle"
      nzGutter="4"
      nzJustify="start"
      *ngIf="operation$ | async as operation"
    >
      <nz-col nzSpan="6" class="filterTitle">
        <nz-badge [nzCount]="modules$ | async | size" [nzOffset]="[8, 10]">
          <app-header-title
            [icon]="''"
            [title]="operation?.type | operationType: 'module'"
            [subtitle]="true"
          ></app-header-title>
        </nz-badge>
      </nz-col>
      <nz-col nzSpan="14" class="inputSearch">
        <input
          nz-input
          [(ngModel)]="inputModel"
          (ngModelChange)="onSeach($event, operation)"
          nzBorderless
          [placeholder]="
            operation?.type !== 'Clauses sociales'
              ? 'Rechercher par la dénomination du module'
              : 'Rechercher par la dénomination du lot...'
          "
        />
      </nz-col>

      <nz-col nzSpan="1"></nz-col>
      <nz-col nzSpan="1">
        <button
          nz-button
          nzShape="circle"
          nzType="text"
          nz-tooltip
          [nzTooltipTitle]="
            operation?.type !== 'Clauses sociales'
              ? 'Filtrer les modules'
              : 'Filtrer les lots'
          "
          nzTooltipPlacement="bottom"
          [nzTooltipMouseEnterDelay]="0.5"
          nzTooltipPlacement="bottom"
        >
          <i nz-icon nzType="filter"></i>
        </button>
      </nz-col>

      <nz-col nzSpan="2">
        <button nz-button nzType="text" nzShape="circle">
          <mat-icon>more_vert</mat-icon>
        </button>
      </nz-col>
    </nz-row>
  `,
  styles: [
    `
      .filterTitle {
        display: flex;
        flex-direction: row;
        justify-content: center;
        justify-items: center;
        margin-top: 10px;
      }

      .addBtn {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        justify-items: flex-end;
        margin-top: -10px;
      }

      .btnFilter {
        margin-top: -10px;
      }

      .inputSearch {
        border-radius: 12px;
        background-color: #f5f5f5;
      }
    `,
  ],
})
export class ModuleFiltersComponent implements OnInit, OnDestroy {
  buttonColor: string = '#597ef7';
  modalWidth: number = 950;
  subscribe = new Subject();
  inputSearch$ = new BehaviorSubject<string>('');
  inputModel: string = '';
  @Input() operation$: Observable<OperationId | any> = of();
  @Input() modules$: Observable<ModuleSmallId[] | any> = of([]);

  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private moduleStore: Store<ModuleState>
  ) {}

  ngOnInit(): void {}
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  onNewModuleForm(): void {
    const modal = this.modal.create({
      nzContent: ModuleFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: 'Nouveau module',
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });
    const instance = modal.getContentComponent();
    instance.cancel.subscribe(
      (event: { operation: OperationId; module: Module }): any => {
        if (!event) {
          return null;
        } else {
          modal.close();
        }
      }
    );
    instance.add.subscribe((event: any): any => {
      if (!event) {
        return null;
      } else {
        this.onAdd(event);
      }
    });
    instance.operation$ = this.operation$;
  }

  onAdd(event: any): void {
    const { operation, module } = event;
    this.moduleStore.dispatch(
      fromModuleAction.addModule({ operationId: operation.id, module })
    );
  }

  onSeach(event: string, operation: OperationId): void {
    if (!event) {
      this.moduleStore.dispatch(
        fromModuleAction.loadModules({ operationId: operation.id })
      );
      return;
    } else {
      const filter = {
        operationId: operation.id,
        event: event,
        type: 'title',
      };
      this.moduleStore.dispatch(fromModuleAction.queryModules({ filter }));
    }
  }

  onClearMdole(): void {}
}
