import {
  UntypedFormBuilder,
  UntypedFormGroup,
  FormControl,
  UntypedFormArray,
  Validators,
} from '@angular/forms';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Timestamp } from 'firebase/firestore';

export interface SharingPerson {
  id: string;
  email: string;
  mobile: number;
  phoneNumber: string;
  lastName: string;
  firstName: string;
  displayName: string;
  avatar: string;
  status: boolean;
  dateStart: any;
}

@Component({
  selector: 'app-sharing',
  templateUrl: './sharing.component.html',
  styleUrls: ['./sharing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharingComponent implements OnInit, OnChanges, OnDestroy {
  @Input() sharingPersons = new BehaviorSubject<SharingPerson[] | any>([]);
  @Input() sharingPersonsPermanent = new BehaviorSubject<SharingPerson[] | any>(
    []
  );

  @Input() isTitle: boolean = true;
  @Input() title: string = 'Partage';

  @Output() cancel = new EventEmitter<any>(false);
  @Output() select = new EventEmitter<any>(false);
  @Output() add = new EventEmitter<any>(false);

  sharingForm: UntypedFormGroup = this.fb.group({});
  persons: UntypedFormArray = new UntypedFormArray([]);

  sharingPermanentForm: UntypedFormGroup = this.fb.group({});
  personsPermanent: UntypedFormArray = new UntypedFormArray([]);

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.form();
  }
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {}

  form(): void {
    this.formInit();
    this.formCreate();
    this.formSet();
  }
  formInit(): void {
    this.persons = new UntypedFormArray([]);
  }
  formCreate(): void {
    this.sharingForm = this.fb.group({
      persons: this.persons,
    });
  }
  formSet(): void {}

  get personsArray(): UntypedFormArray {
    return this.sharingForm.get('persons') as UntypedFormArray;
  }

  onSelect(event: Event): void {}
  onAddPerson(): void {
    const person = this.fb.group({
      id: '',
      email: ['', Validators.email],
      mobile: [0, Validators.required],
      phoneNumber: '',
      lastName: ['', Validators.required],
      firstName: ['', Validators.required],
      displayName: ['', Validators.required],
      avatar: [''],
      status: false,
      dateStart: Timestamp.now(),
    });
    this.personsArray.push(person);
  }
  onRemovePerson(index: number): void {
    this.personsArray.removeAt(index);
  }

  onSharing(): void {}

  onCancel(event: Event): void {
    this.cancel.emit(event);
  }
}
