<ng-container *ngIf="modules$ | async | size; else noModule">
  <div class="Operation_Dashboard_Container">
    <nz-row nzJustify="center" nzAlign="middle" nzGutter="2">
      <nz-col nzSpan="24">
        <app-charts
          *ngIf="modulesCompare$ | async; else noModule"
          [source]="modulesCompare$"
          [icon]="'bar_chart'"
          [isBorderless]="false"
          [isTitle]="true"
        ></app-charts>
      </nz-col>
    </nz-row>
    <nz-space></nz-space>

    <nz-row
      nzJustify="center"
      nzAlign="middle"
      nzGutter="2"
      *ngIf="modules$ | async | size"
    >
      <nz-col nzSpan="24">
        <nz-card [nzTitle]="interventionHeaderTpl">
          <ng-template #interventionHeaderTpl>
            <app-header-title
              [icon]="'table_chart'"
              [title]="'Module : tableau des réalisations'"
              [subtitle]="true"
            ></app-header-title>
          </ng-template>
          <app-intervention-table [source$]="modules$"></app-intervention-table>
        </nz-card>
      </nz-col>
    </nz-row>
  </div>
</ng-container>

<ng-template #noModule>
  <app-no-result
    [icon]="'query_stats'"
    [isButton]="false"
    [title]="'Aucun module'"
    [description]="'Aucun module'"
  ></app-no-result>
</ng-template>
