import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { AuthUser, EmailValidation } from './auth.model';
import * as AuthActions from './auth.actions';
import { Auth, User } from '@angular/fire/auth';
export const authsFeatureKey = 'auth';

export interface AuthState extends EntityState<any> {
  loading: boolean;
  loadingCheckEmail: boolean;
  loaded: boolean;
  emailCheck: EmailValidation | any;
  emailValidation: EmailValidation | any;
  authUser: User | any;
  error: any;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>();

export const initialState: AuthState = adapter.getInitialState({
  loading: false,
  loadingCheckEmail: false,

  loaded: false,
  emailCheck: undefined,
  emailValidation: undefined,
  authUser: undefined,
  error: undefined,
});

export const reducer = createReducer(
  initialState,

  //LOADING
  on(AuthActions.loadingAuth, (state, action) => {
    return {
      ...state,
      loading: action.loading,
    };
  }),
  //EMAIL CHECK
  on(AuthActions.emailCheck, (state, action) => {
    return {
      ...state,
      loading: true,
      loadingCheckEmail: true,
      error: undefined,
    };
  }),
  on(AuthActions.emailCheckValidation, (state, action) => {
    return {
      ...state,
      loading: false,
      loadingCheckEmail: false,

      error: undefined,
    };
  }),
  on(AuthActions.emailCheckSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      loadingCheckEmail: false,

      error: undefined,
      emailCheck: action.validation,
    };
  }),
  on(AuthActions.emailCheckFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
      emailValidation: undefined,
    };
  }),

  //EMAIL VALIDATION
  on(AuthActions.emailValidation, (state, action) => {
    return {
      ...state,
      loading: true,
      error: undefined,
    };
  }),
  on(AuthActions.emailValidationSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      error: undefined,
      emailValidation: action.validation,
    };
  }),
  on(AuthActions.emailValidationFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
      emailValidation: undefined,
    };
  }),

  //LOGIN
  on(AuthActions.login, (state, action) => {
    return {
      ...state,
      loaded: false,
      loading: true,
    };
  }),

  on(AuthActions.loginSuccess, (state, action) => {
    return {
      ...state,
      loaded: true,
      loading: false,
    };
  }),

  on(AuthActions.loginFailure, (state, action) => {
    return {
      ...state,
      loaded: true,
      loading: false,
      error: action.error,
    };
  }),

  on(AuthActions.loginSuccessEmailVerification, (state, action) => {
    return {
      ...state,
      loaded: true,
      loading: false,
    };
  }),

  //RESET PASSEWORD
  on(AuthActions.resetPassword, (state, action) => {
    return {
      ...state,
      loaded: false,
      loading: true,
    };
  }),

  on(AuthActions.resetPasswordSuccess, (state, action) => {
    return {
      ...state,
      loaded: true,
      loading: false,
    };
  }),

  on(AuthActions.resetPasswordFailure, (state, action) => {
    return {
      ...state,
      loaded: true,
      loading: false,
      error: action.error,
    };
  }),

  //LOGOUT
  on(AuthActions.logout, (state) => adapter.removeAll(state)),
  //
  on(AuthActions.clearAuths, (state) => adapter.removeAll(state))
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
