import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { NzSelectSizeType } from 'ng-zorro-antd/select';

@Component({
  selector: 'app-utilities-standard-selector',
  templateUrl: './utilities-standard-selector.component.html',
  styleUrls: ['./utilities-standard-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UtilitiesStandardSelectorComponent implements OnInit {
  @Input() source: any[] | any = [];
  @Input() variable: any = null;

  @Input() borderless: boolean = true;
  @Input() multi: boolean = false;
  @Input() tags: boolean = false;
  @Input() placeholder: string = 'Sélection...';
  @Input() selected: any = null;
  @Input() size: NzSelectSizeType = 'default';
  @Output() select = new EventEmitter<any>(false);

  constructor() {}

  ngOnInit(): void {}

  onSelect(event: any): void {
    if (!event) return;
    this.select.emit(event);
  }
}
