import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { isNull, isUndefined } from 'lodash';
import { of, Subject, Observable, BehaviorSubject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-operation-dashboard-actions',
  templateUrl: './operation-dashboard-actions.component.html',
  styleUrls: ['./operation-dashboard-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationDashboardActionsComponent
  implements OnInit, OnChanges, OnDestroy
{
  subscribe = new Subject();
  noExist = 'Non renseigné';
  //ACTIONS
  actions$ = new BehaviorSubject<any>([]);

  @Input() dashboard$: Observable<any> = of(null);

  constructor() {}

  ngOnInit(): void {
    this.setActionsnDashboard();
  }

  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  chartsValues = (statistiques: any) => {
    const values = statistiques
      ? Object.values(statistiques).map((item) => {
          if (isNull(item) || isUndefined(item) || !item) {
            return this.noExist;
          }
          return item;
        })
      : [];

    const labels = statistiques
      ? Object.keys(statistiques).map((item) => {
          if (isNull(item) || isUndefined(item) || !item) {
            return this.noExist;
          }
          return item;
        })
      : [];

    return { values, labels };
  };

  itemsCounter = (items: any[]) => {
    return items.reduce((acc, cur) => {
      const curSize = items.filter((el) => el === cur).length;

      acc[cur] = curSize;
      return acc;
    }, {});
  };

  chartsTypePie = (count: any): any => {
    const transformToArray: any[] = Object.entries(count);
    const transformForChart: any[] = transformToArray.map((el) => ({
      name: el[0],
      value: el[1],
    }));

    return transformForChart;
  };

  setActionsnDashboard(): void {
    this.dashboard$.pipe(takeUntil(this.subscribe)).subscribe((dashboard) => {
      if (!dashboard) return;
      const { planning } = dashboard;
      const actions = dashboard?.actions?.data?.length
        ? dashboard.actions.data
        : [];

      this.actions$.next(actions);
    });
  }
}
