<ng-container *ngIf="participant">
  <nz-page-header
    class="page-header"
    [nzTitle]="header"
    [nzSubtitle]="cardExtra"
    *ngIf="participant?.prescripteurs | size"
    (nzBack)="onBack()"
    [nzBackIcon]="(currentPrescipteur$ | async) ? '' : null"
  >
    <nz-page-header-extra>
      <button
        nz-button
        nzSize="small"
        nzType="text"
        nz-dropdown
        nzTrigger="click"
        [nzDropdownMenu]="prescripteurMenu"
        nzShape="circle"
        *ngIf="(view$ | async) === 'details'"
      >
        <mat-icon>more_vert</mat-icon>
      </button>

      <nz-dropdown-menu #prescripteurMenu="nzDropdownMenu">
        <ul nz-menu>
          <li
            nz-menu-item
            nzDanger
            nzPopconfirmTitle="Voulez-vous supprimer ce prescripteur ?"
            nzPopconfirmPlacement="bottom"
            (nzOnCancel)="onCancel()"
            nz-popconfirm
          >
            <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
              <nz-col nzSpan="8">
                <i nz-icon nzType="delete"></i>
              </nz-col>
              <nz-col nzSpan="16"> Supprimer </nz-col>
            </nz-row>
          </li>
        </ul>
      </nz-dropdown-menu>
    </nz-page-header-extra>
    <nz-page-header-content>
      <nz-row
        nzAlign="middle"
        nzGutter="8"
        nzJustify="start"
        *ngIf="currentPrescipteur$ | async"
        class="prescripteursTitle"
      >
        <nz-col nzSpan="24">
          <strong>
            {{ (currentPrescipteur$ | async)?.denomination }}
          </strong>
        </nz-col>
      </nz-row>
    </nz-page-header-content>
  </nz-page-header>
  <nz-divider
    nzOrientation="center"
    nzType="horizontal"
    *ngIf="participant?.prescripteurs | size"
  ></nz-divider>
  <div [ngSwitch]="view$ | async">
    <div *ngSwitchCase="'list'">
      <app-layout-list
        *ngIf="participant?.prescripteurs | size; else noResults"
        [new]="new"
        [card]="card"
        [source]="participant?.prescripteurs"
      ></app-layout-list>
    </div>
    <div *ngSwitchCase="'details'">
      <app-participant-prescripteur-item
        [prescripteur$]="currentPrescipteur$"
        (update)="onUpdateReferent($event, participant)"
      ></app-participant-prescripteur-item>
    </div>
    <div *ngSwitchDefault></div>
  </div>

  <ng-template #new>
    <app-card-new
      [type]="'domain'"
      [title]="title"
      [definition]="newCardDefinition"
      [description]="''"
      (add)="onNew(participant)"
    ></app-card-new>
  </ng-template>

  <ng-template #card let-item let-position="idx">
    <app-participant-prescripteur-card
      [item]="item"
      (delete)="onDelete($event, participant)"
      (click)="$event.stopPropagation(); onSelect(item)"
    ></app-participant-prescripteur-card>
  </ng-template>

  <ng-template #header>
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="24">
        <h4 nz-typography>{{ title }}</h4>
      </nz-col>
    </nz-row>
  </ng-template>
  <ng-template #cardExtra>
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="24">
        <nz-badge
          [nzCount]="participant?.prescripteurs | size"
          [nzOverflowCount]="1"
        >
        </nz-badge>
      </nz-col>
    </nz-row>
  </ng-template>

  <ng-template #noResults>
    <app-no-result
      [icon]="'domain'"
      [addtitle]="newCardDescription"
      [notitle]="newCardNone"
      [description]="newCardDefinition"
      (new)="onNew(participant)"
    ></app-no-result>
  </ng-template>
</ng-container>

<ng-template #prescripteurTitleForm>
  <app-header-title [icon]="'domaine'" [title]="title"></app-header-title>
</ng-template>
