<div
  class="projetPro_statistics_container"
  *ngIf="statistiques$ | async; else noData"
>
  <nz-row nzJustify="center" nzAlign="middle" nzGutter="2">
    <nz-col nzSpan="12">
      <app-charts
        *ngIf="repriseEmploi$ | async; else noData"
        [source]="repriseEmploi$"
        [icon]="'query_stats'"
        [isBorderless]="false"
        [isTitle]="true"
      ></app-charts>
    </nz-col>
    <nz-col nzSpan="12">
      <app-charts
        *ngIf="typesDeContrats$ | async; else noData"
        [source]="typesDeContrats$"
        [icon]="'query_stats'"
        [isBorderless]="false"
        [isTitle]="true"
      ></app-charts>
    </nz-col>
  </nz-row>
  <nz-space nzDirection="vertical" nzSize="small"></nz-space>
  <nz-row nzJustify="center" nzAlign="middle" nzGutter="2">
    <nz-col nzSpan="24">
      <app-charts
        *ngIf="salaires$ | async as salaire; else noData"
        [source]="salaires$"
        [icon]="'query_stats'"
        [isBorderless]="false"
        [isTitle]="true"
      ></app-charts>
    </nz-col>
  </nz-row>
  <nz-space nzDirection="vertical" nzSize="small"></nz-space>

  <nz-row nzJustify="center" nzAlign="middle" nzGutter="2">
    <nz-col nzSpan="12">
      <nz-card
        nzSize="small"
        [nzTitle]="offreTitle"
        *ngIf="offresEmploi$ | async as offre"
      >
        <ng-template #offreTitle>
          <app-header-title
            [icon]="'query_stats'"
            [subtitle]="true"
            [title]="offre?.title"
          ></app-header-title>
        </ng-template>
        <nz-row [nzGutter]="8">
          <nz-col [nzSpan]="9">
            <nz-statistic
              [nzValue]="(offre.data?.demandesFinMois | number)!"
              [nzTitle]="demandeFinMoisTitle"
            ></nz-statistic>
            <ng-template #demandeFinMoisTitle>
              <small>Demandes fin de mois</small>
            </ng-template>
          </nz-col>
          <nz-col [nzSpan]="8">
            <nz-statistic
              [nzValue]="(offre.data?.offreFinMois | number)!"
              [nzTitle]="offreFinMoisTitle"
            ></nz-statistic>
            <ng-template #offreFinMoisTitle>
              <small>Offres fin de mois</small>
            </ng-template>
          </nz-col>
          <nz-col [nzSpan]="7">
            <nz-statistic
              [nzValue]="(offre.data?.demandesFinMois | number)!"
              [nzTitle]="ratioTitle"
              [nzSuffix]="'/' + (offre.data?.offreFinMois | number)!"
            ></nz-statistic>
          </nz-col>
        </nz-row>
        <nz-row [nzGutter]="8">
          <nz-col [nzSpan]="9">
            <nz-statistic
              [nzValue]="(offre.data?.demandesDeniereSemaine | number)!"
              [nzTitle]="demandeDernierSemaineTitle"
            ></nz-statistic>
            <ng-template #demandeDernierSemaineTitle>
              <small>Demandes dernière semaine</small>
            </ng-template>
          </nz-col>
          <nz-col [nzSpan]="8">
            <nz-statistic
              [nzValue]="(offre.data?.offreDeniereSemaine | number)!"
              [nzTitle]="offreDernierSemaineTitle"
            ></nz-statistic>
            <ng-template #offreDernierSemaineTitle>
              <small>Offres dernière semaine</small>
            </ng-template>
          </nz-col>
          <nz-col [nzSpan]="7">
            <nz-statistic
              [nzValue]="(offre.data?.demandesDeniereSemaine | number)!"
              [nzTitle]="ratioTitle"
              [nzSuffix]="'/' + (offre.data?.offreDeniereSemaine | number)!"
            ></nz-statistic>
          </nz-col>
        </nz-row>
        <nz-divider
          nzOrientation="center"
          nzType="horizontal"
          [nzText]="bestBassin"
        ></nz-divider>
        <ng-template #bestBassin>
          <small>Bassin le plus riche en offres</small>
        </ng-template>
        <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
          <nz-col nzSpan="24">
            <p nz-typography>{{ offre.data?.bestDepartement }}</p>
          </nz-col>
        </nz-row>
        <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
        <nz-descriptions
          nzBordered
          [nzColumn]="{ xxl: 2, xl: 2, lg: 2, md: 0, sm: 0, xs: 0 }"
        >
          <nz-descriptions-item nzSpan="1" nzTitle="Tension">
            {{ offre.data?.tension }} {{ offre.data?.tension ? "/ 10" : "" }}
          </nz-descriptions-item>
          <nz-descriptions-item nzSpan="1" nzTitle="Saisonnalité">
            {{ offre.data?.seasonal }}
          </nz-descriptions-item>
        </nz-descriptions>
      </nz-card>
    </nz-col>
    <nz-col nzSpan="12">
      <app-charts
        *ngIf="bmo$ | async; else noData"
        [source]="bmo$"
        [icon]="'query_stats'"
        [isBorderless]="false"
        [isTitle]="true"
      ></app-charts>
    </nz-col>
  </nz-row>
</div>

<ng-template #ratioTitle>
  <small>Ratio</small>
</ng-template>
<ng-template #noData>
  <ng-container *ngIf="loading$ | async">
    <i nz-icon nzType="loading" class="loading"></i>
  </ng-container>
  <app-no-result
    [icon]="'query_stats'"
    [notitle]="'Aucune statistique professionnelle trouvée'"
    [isButton]="false"
  ></app-no-result>
</ng-template>
