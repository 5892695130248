import { Injectable } from '@angular/core';
import { OperationId } from './../components/operation/store/operation.model';
import {
  Firestore,
  collection,
  doc,
  docSnapshots,
  collectionChanges,
  getDoc,
  addDoc,
  setDoc,
  deleteDoc,
  serverTimestamp,
  DocumentData,
  Timestamp,
  query,
  where,
  arrayUnion,
  arrayRemove,
  collectionSnapshots,
  CollectionReference,
  DocumentReference,
  getDocs,
  documentId,
} from '@angular/fire/firestore';
import { Observable, of, from, EMPTY } from 'rxjs';
import { switchMap, mergeMap, map, catchError } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { UserState } from '../components/user/store/user.reducer';
import * as fromUserSelector from '../components/user/store/user.selectors';
import { v4 as uuidV4 } from 'uuid';
import { format, fromUnixTime } from 'date-fns';
import { ItemEvent } from '../features/suivi/components/item-event/itemEvent.model';
import { OperationTaskId } from '../components/operation-task/store/operation-task.model';
import { OperationNoteId } from '../components/operation-note/store/operation-note.model';
import {
  OperationNoteSujet,
  OperationNoteSujetId,
} from '../components/operation-note-sujet/store/operation-note-sujet.model';
import { OperationNoteSubtitleId } from '../components/operation-note-subtitle/store/operation-note-subtitle.model';
import { orderBy } from 'firebase/firestore';
import { Apollo, gql } from 'apollo-angular';
import { OperationNoteSujetState } from '../components/operation-note-sujet/store/operation-note-sujet.reducer';
import * as fromOperationNoteSujetAction from '../components/operation-note-sujet/store/operation-note-sujet.actions';

@Injectable({
  providedIn: 'any',
})
export class OperationNoteSujetService {
  GET_OPERATION_NOTE_SUJET_WARNING = gql`
    query OperationNoteSujetsAlert($id: ID!, $noteId: ID!) {
      operationNoteSujetsAlert(id: $id, noteId: $noteId) {
        size
        sujets {
          id
          text
          updateAt
          updateBy
          title
        }
      }
    }
  `;

  DELETE_OPERATION_NOTE_SUJETS = gql`
    mutation DeleteOperationNoteSujets(
      $operationId: String!
      $noteId: String!
      $subtitleId: String!
    ) {
      deleteAllOperationNoteSujets(
        operationId: $operationId
        noteId: $noteId
        subtitleId: $subtitleId
      )
    }
  `;

  constructor(
    private db: Firestore,
    private fns: Functions,
    private userStore: Store<UserState>,
    private apollo: Apollo,
    private operationNoteSujetStore: Store<OperationNoteSujetState>
  ) {}

  getAll(subtitle: OperationNoteSubtitleId): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !subtitle) {
            return EMPTY;
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/operations/${subtitle.operation.id}/notes/${subtitle.note.id}/subjects`
            );

            const dbCollectionQuery = query(
              dbCollection,
              where('subtitle.id', '==', subtitle.id)
            );

            return from(collectionSnapshots(dbCollectionQuery)).pipe(
              map((changes) => {
                return changes.map((snap) => {
                  const data = snap.data();
                  const id = snap.id;
                  return { id, ...data };
                });
              })
            );
          }
        })
      );
    } catch (error: any) {
      return of(error?.message);
    }
  }
  addOne(note: OperationNoteId, sujet: OperationNoteSujet): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !note || !sujet) {
            return EMPTY;
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/operations/${note.operation.id}/notes/${note.id}/subjects/`
            );

            const document: OperationNoteSujet = {
              ...sujet,
              auteur: user,
              dateStart: serverTimestamp(),
              dateUpdate: serverTimestamp(),
            };

            return from(
              addDoc(dbCollection, document)
                .then((docRef) => {
                  if (!docRef.id) {
                    const succss: string = `Sujet n\'a pas été ajouté ! Veuillez rééssayer`;
                    return of(succss);
                  }

                  const succss: string = `Sujet ajouté`;
                  return of(succss);
                })
                .catch((error: any) => of(error?.message))
            ).pipe(mergeMap((res) => res));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }
  getAllByNote(note: OperationNoteId): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !note) {
            return EMPTY;
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/operations/${note.operation.id}/notes/${note.id}/subjects`
            );

            return from(getDocs(dbCollection)).pipe(
              map((changes) => {
                return changes.docs.map((doc) => {
                  const data = doc.data();
                  const id = doc.id;
                  return { id, ...data };
                });
              })
            );
          }
        })
      );
    } catch (error: any) {
      return of(error?.message);
    }
  }

  getWarningSujets(id: string, noteId: string): Observable<any> {
    try {
      if (!id || !noteId) return EMPTY;

      return this.apollo
        .watchQuery<any>({
          query: this.GET_OPERATION_NOTE_SUJET_WARNING,
          variables: {
            id: id,
            noteId: noteId,
          },
        })
        .valueChanges.pipe(
          map(({ data, loading, error }) => {
            return { data, loading, error };
          })
        );
    } catch (error) {
      return of(error);
    }
  }

  updateOne(
    note: OperationNoteId,
    sujet: Partial<OperationNoteSujetId | any>
  ): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !note || !sujet) {
            return EMPTY;
          } else {
            const dbDocumentRef = doc(
              this.db,
              `organisations/${user.organisation.id}/operations/${note.operation.id}/notes/${note.id}/subjects/${sujet.id}`
            );

            const document: OperationNoteSujetId | any = {
              ...sujet,
              userUpdate: user,
              dateUpdate: serverTimestamp(),
            };

            return from(
              setDoc(
                dbDocumentRef,
                {
                  ...document,
                },
                { merge: true }
              )
                .then(() =>
                  this.operationNoteSujetStore.dispatch(
                    fromOperationNoteSujetAction.loadOperationNoteSujetsWarning(
                      { id: note.operation.id, noteId: note.id }
                    )
                  )
                )
                .then(() => of(`Sujet modifié avec succès`))
                .catch((error: any) => of(error?.message))
            ).pipe(mergeMap((res) => res));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }
  deleteOne(note: OperationNoteId, id: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !note || !id) {
            return EMPTY;
          } else {
            const dbDocument = doc(
              this.db,
              `organisations/${user.organisation.id}/operations/${note.operation.id}/notes/${note.id}/subjects/${id}`
            );

            return from(
              deleteDoc(dbDocument)
                .then(() => of(`Sujet supprimé`))
                .catch((error: any) => of(error?.message))
            ).pipe(mergeMap((res) => res));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }
  deleteAll(note: OperationNoteId, subtitleId: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !note || !subtitleId) {
            return EMPTY;
          } else {
            const operationId: string = note.operation.id;
            const noteId: string = note.id;

            return this.apollo.mutate({
              mutation: this.DELETE_OPERATION_NOTE_SUJETS,
              variables: {
                operationId: operationId,
                noteId: noteId,
                subtitleId: subtitleId,
              },
            });
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }
}
