<ng-container *ngIf="etablissement">
  <nz-card nzBorderless nzSize="small" [nzTitle]="etablissementHeaderTpl">
    <ng-template #etablissementHeaderTpl>
      <nz-row nzAlign="middle" nzGutter="4" nzJustify="start">
        <nz-col nzSpan="2">
          <mat-icon>domaine</mat-icon>
        </nz-col>
        <nz-col nzSpan="12">
          <strong>{{
            etablissement?.uniteLegale.denominationUniteLegale
              | carLimitation : 50
          }}</strong>
        </nz-col>
        <nz-col nzSpan="2">
          <nz-tag
            [nzColor]="
              etablissement?.uniteLegale
                ?.economieSocialeSolidaireUniteLegale === 'O'
                ? 'success'
                : 'default'
            "
            >{{
              etablissement?.uniteLegale
                ?.economieSocialeSolidaireUniteLegale === "O"
                ? "ESS"
                : ""
            }}</nz-tag
          >
        </nz-col>
        <nz-col nzSpan="2">
          <nz-tag
            [nzColor]="
              etablissement?.etablissementSiege ? 'geekblue' : 'default'
            "
            >{{ etablissement?.etablissementSiege ? "Siège" : "" }}</nz-tag
          >
        </nz-col>
        <nz-col nzSpan="6">
          {{ etablissement?.siret | carLimitation : 14 }}
        </nz-col>
      </nz-row>
    </ng-template>

    <nz-row nzAlign="middle" nzGutter="4" nzJustify="start">
      <nz-col nzSpan="2">
        <mat-icon>place</mat-icon>
      </nz-col>
      <nz-col nzSpan="22">
        <small>
          {{
            etablissement?.adresseEtablissement?.fullAdresse
              | carLimitation : 80
          }}
        </small>
        <br />
        <div class="rowContent">
          <small
            >{{
              etablissement?.adresseEtablissement?.commune?.departement?.nom
                | titlecase
                | carLimitation : 50
            }}
          </small>
          -
          <small>{{
            etablissement?.adresseEtablissement?.commune?.region?.nom
              | titlecase
              | carLimitation : 50
          }}</small>
        </div>
      </nz-col>
    </nz-row>
    <nz-space nzSize="small"></nz-space>
    <nz-row nzAlign="middle" nzGutter="4" nzJustify="start">
      <nz-col nzSpan="2">
        <mat-icon>business_center</mat-icon>
      </nz-col>
      <nz-col nzSpan="18">
        <small>
          {{
            etablissement?.uniteLegale?.categorie_juridique_libelle?.libelle
              | carLimitation : 80
          }}
        </small>
        <br />
        <nz-tag nzColor="geekblue">
          {{
            etablissement?.uniteLegale?.activite_principale_libelle
              ?.intitule_naf | carLimitation : 80
          }}
        </nz-tag>
      </nz-col>
      <nz-col nzSpan="4"> </nz-col>
    </nz-row>
    <nz-space nzSize="small"></nz-space>
  </nz-card>
</ng-container>
