import { ItemEventId } from '../../../features/suivi/components/item-event/itemEvent.model';
import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Action, ActionId, ActionSmallId } from './action.model';
import { ItemEvent } from 'src/app/features/suivi/components/item-event/itemEvent.model';

export const loading = createAction(
  '[Action/COMPONENT] Loading Action',
  props<{ loading: boolean }>()
);
export const loadUtils = createAction('[Action/COMPONENT] Utils Action');
export const loadUtilsSuccess = createAction(
  '[Action/COMPONENT] Utils Action Success',
  props<{ utils: any }>()
);

export const loadUtilsFailure = createAction(
  '[Action/COMPONENT] Utils Action Failure',
  props<{ error: any }>()
);

export const loadFileProgress = createAction(
  '[Action/API] File upload progreess Action',
  props<{ progress: any }>()
);

//LOAD ACTION TYPES
export const loadActionTypes = createAction(
  '[Action/COMPONENT] Load Action Types'
);
export const loadActionTypesSuccess = createAction(
  '[Action/API] Load Action Types Success',
  props<{ types: any }>()
);
export const loadActionTypesFailure = createAction(
  '[Action/API] Load Action Types Failure',
  props<{ error: any }>()
);

//LOAD ACTIONS
export const loadActions = createAction(
  '[Action/COMPONENT] Load Actions',
  props<{ operationId: string; moduleId?: string }>()
);
export const loadActionsSuccess = createAction(
  '[Action/API] Load Actions Success',
  props<{ actions: ActionSmallId[] }>()
);
export const loadActionsFailure = createAction(
  '[Action/API] Load Actions Failure',
  props<{ error: any }>()
);

//QUERY MANY
export const queryActions = createAction(
  '[Action/COMPONENT] Query Actions',
  props<{ filter: { operationId: string; event: string; type: string } }>()
);
export const queryActionsSuccess = createAction(
  '[Action/API] Query Actions Success',
  props<{ actions: ActionSmallId[] }>()
);
export const queryActionsFailure = createAction(
  '[Action/API] Query Actions Failure',
  props<{ error: any }>()
);

//QUERY BY MODULE
export const queryActionsByModule = createAction(
  '[Action/COMPONENT] Query Actions By Module',
  props<{ operationId: string; module: any }>()
);
export const queryActionsByModuleSuccess = createAction(
  '[Action/API] Query Actions By Module Success',
  props<{ actions: ActionSmallId[] }>()
);
export const queryActionsByModuleFailure = createAction(
  '[Action/API] Query Actions By Module Failure',
  props<{ error: any }>()
);

//LOAD
export const loadAction = createAction(
  '[Action/COMPONENT] Load Action',
  props<{ operationId: string; actionId: string; moduleId?: string }>()
);
export const loadActionSuccess = createAction(
  '[Action/API] Load Action Success',
  props<{ action: ActionId }>()
);
export const loadActionFailure = createAction(
  '[Action/API] Load Action Failure',
  props<{ error: any }>()
);

//ADD
export const addAction = createAction(
  '[Action/API] Add Action',
  props<{ operationId: string; action: Action }>()
);
export const addActionSuccess = createAction(
  '[Action/API] Add Action Success',
  props<{ success: string }>()
);

export const addActionFailure = createAction(
  '[Action/API] Add Action Failure',
  props<{ error: any }>()
);

//UPDATE
export const updateAction = createAction(
  '[Action/API] Update Action',
  props<{ operationId: string; action: Update<Action> }>()
);
export const updateActionSuccess = createAction(
  '[Action/API] Update Action',
  props<{ success: string }>()
);
export const updateActionFailure = createAction(
  '[Action/API] Update Action',
  props<{ error: any }>()
);

//DELETE
export const deleteAction = createAction(
  '[Action/COMPONENT] Delete Action',
  props<{ operationId: string; id: string }>()
);
export const deleteActionSuccess = createAction(
  '[Action/API] Delete Action Success',
  props<{ success: string }>()
);

export const deleteActionFailure = createAction(
  '[Action/API] Delete Action Failure',
  props<{ error: any }>()
);

//DELETE ALL
export const deleteActions = createAction(
  '[Action/API] Delete Actions',
  props<{ ids: string[]; operationId: string }>()
);
export const deleteActionsSuccess = createAction(
  '[Action/API] Delete Actions Success',
  props<{ success: string }>()
);

export const deleteActionsFailure = createAction(
  '[Action/API] Delete Actions Failure',
  props<{ error: any }>()
);

//SUIVIS
//load suivis
export const loadSuivis = createAction(
  '[Action/COMPONENT] Load Suivis Action',
  props<{ actionId: string }>()
);

export const loadSuivisSuccess = createAction(
  '[Action/API] Load Suivis Action Success',
  props<{ suivis: any }>()
);

export const loadSuivisFailure = createAction(
  '[Action/API] Load Suivis Action Failure',
  props<{ error: any }>()
);

//add suivi
export const addSuivi = createAction(
  '[Action/COMPONENT] Add Suivi Action',
  props<{ suivi: ItemEvent; actionId: string }>()
);

export const addSuiviSuccess = createAction(
  '[Action/API] Add Suivi Action Success',
  props<{ suivi: ItemEventId }>()
);

export const addSuiviFailure = createAction(
  '[Action/API] Add Suivi Action Failure',
  props<{ error: any }>()
);

//update suivi
export const updateSuivi = createAction(
  '[Action/COMPONENT] Update Suivi Action',
  props<{ suivi: Update<ItemEventId>; actionId: string }>()
);

export const updateSuiviSuccess = createAction(
  '[Action/API] Update Suivi Action Success',
  props<{ suivi: ItemEventId }>()
);

export const updateSuiviFailure = createAction(
  '[Action/API] Update Suivi Action Failure',
  props<{ error: any }>()
);
//delete suivi
export const deleteSuivi = createAction(
  '[Action/COMPONENT] Delete Suivi Action',
  props<{ suiviId: string; actionId: string }>()
);

export const deleteSuiviSuccess = createAction(
  '[Action/API] Delete Suivi Action Success',
  props<{ success: string }>()
);

export const deleteSuiviFailure = createAction(
  '[Action/API] Delete Suivi Action Failure',
  props<{ error: any }>()
);

//DOCUMENTS
//load documents
export const loadDocuments = createAction(
  '[Action/COMPONENT] Load Documents Action',
  props<{ actionId: string }>()
);

export const loadDocumentsSuccess = createAction(
  '[Action/API] Load Documents Action Success',
  props<{ documents: any }>()
);

export const loadDocumentsFailure = createAction(
  '[Action/API] Load Documents Action Failure',
  props<{ error: any }>()
);

//add document
export const addDocuments = createAction(
  '[Action/COMPONENT] Add Documents Action',
  props<{ Action: ActionId; documents: any[] }>()
);

export const addDocumentsSuccess = createAction(
  '[Action/API] Add Documents Action Success',
  props<{ documents: any[] }>()
);

export const addDocumentsFailure = createAction(
  '[Action/API] Add Documents Action Failure',
  props<{ error: any }>()
);

//delete document
export const deleteDocument = createAction(
  '[Action/COMPONENT] Delete Document Action',
  props<{ actionId: string; document: any }>()
);

export const deleteDocumentSuccess = createAction(
  '[Action/API] Delete Document Action Success',
  props<{ success: string }>()
);

export const deleteDocumentFailure = createAction(
  '[Action/API] Delete Document Action Failure',
  props<{ error: any }>()
);

//CLEAR
export const clearActions = createAction('[Action/API] Clear Actions');
export const clearAction = createAction('[Action/API] Clear Action');
