import { ModuleId } from 'src/app/components/module/store/module.model';
import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-module-dashboard',
  templateUrl: './module-dashboard.component.html',
  styleUrls: ['./module-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModuleDashboardComponent implements OnInit {
  @Input() module: Observable<ModuleId | any> = of(null);

  constructor() {}

  ngOnInit(): void {}

  onSelectTab(event: any): void {}
}
