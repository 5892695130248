import { ContentsModule } from './../../contents/contents.module';
import { MessagesService } from './../../service/messages.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromMessage from './store/message.reducer';
import { MessageEffects } from './store/message.effects';
import { MessageComponent } from './components/message/message.component';
import { MessageFormComponent } from './components/message-form/message-form.component';
import { MessageItemComponent } from './components/message-item/message-item.component';
import { MessagesListComponent } from './components/messages-list/messages-list.component';
import { MessagesScreenComponent } from './components/messages-screen/messages-screen.component';
import { MessagesScreenInputComponent } from './components/messages-screen-input/messages-screen-input.component';
import { MessageHeaderComponent } from './components/message-header/message-header.component';
import { MessagesScreenChatComponent } from './components/messages-screen-chat/messages-screen-chat.component';
import { MessagesScreenChatEventComponent } from './components/messages-screen-chat-event/messages-screen-chat-event.component';
import { MessageNotificationsComponent } from './components/message-notifications/message-notifications.component';
import { MessageActionsComponent } from './components/message-actions/message-actions.component';
import { NoResultModule } from 'src/app/contents/components/no-result/no-result.module';
import { HeaderModule } from 'src/app/contents/components/header-title/header.module';

@NgModule({
  declarations: [
    MessageComponent,
    MessageFormComponent,
    MessageItemComponent,
    MessagesListComponent,
    MessagesScreenComponent,
    MessagesScreenInputComponent,
    MessageHeaderComponent,
    MessagesScreenChatComponent,
    MessagesScreenChatEventComponent,
    MessageNotificationsComponent,
    MessageActionsComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ContentsModule,
    NoResultModule,
    HeaderModule,

    StoreModule.forFeature(fromMessage.messagesFeatureKey, fromMessage.reducer),
    EffectsModule.forFeature([MessageEffects]),
  ],
  exports: [
    MessageComponent,
    MessageFormComponent,
    MessageItemComponent,
    MessagesListComponent,
    MessagesScreenComponent,
    MessagesScreenInputComponent,
    MessageHeaderComponent,
    MessagesScreenChatComponent,
    MessagesScreenChatEventComponent,
  ],
  providers: [NzMessageService, MessagesService],
})
export class MessagesModule {}
