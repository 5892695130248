export interface Utilities {
  id: string;
  name: string;
  [key: string]: string;
}

export enum UtilitiesIds {
  action = 'actionsUtility',
  document = 'document',
  entite = 'entityUtility',
  suivi = 'itemEventUtility',
  module = 'moduleUtility',
  projet = 'operationUtility',
  participant = 'participantUtility',
}

export enum UtilitiesActionVariables {
  role = 'role',
  etat = 'etat',
  type = 'type',
}

export enum UtilitiesDocumentVariables {
  type = 'typeList',
}

export enum UtilitiesEntityVariables {
  secteur = 'secteur',
  origine = 'origine',
  type = 'type',
  status = 'status',
  adhesion = 'adhesion',
  civility = 'civilite',
  engagements = 'engagements',
}

export enum UtilitiesSuiviVariables {
  type = 'type',
  object = 'object',
}

export enum UtilitiesModuleVariables {
  type = 'type',
}

export enum UtilitiesProjetVariables {
  type = 'types',
}

export enum UtilitiesParticipantVariables {
  couverture = 'couvertureMaladie',
  minima = 'minimaSociaux',
  niveauFrancais = 'niveauFrancais',
  parcoursType = 'parcoursType',
  diplome = 'niveauDiplome',
  civility = 'civility',
  pays = 'pays',
  matrimonial = 'matrimonial',
  status = 'status',
  titreSejours = 'titreSejours',
  mobility = 'mobility',
  languageWeb = 'languageWeb',
  numerique = 'numerique',
  reseauxSociaux = 'reseauxSociaux',
  criteres = 'criteres',
  communication = 'communication',
  sante = 'sante',
  sexe = 'sexe',
  sorties = 'sortiesPositives',
  permis = 'permis',
  transports = 'transports',
}
