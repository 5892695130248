<nz-row
  *ngIf="participant$ | async as participant"
  nzAlign="middle"
  nzGutter="6"
  nzJustify="end"
  class="rowTitle"
>
  <nz-col nzXl="1" nzXXl="1" nzLg="0" nzXs="0" nzSm="0" nzMd="0">
    <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
  </nz-col>
  <nz-col nzXl="1" nzXXl="1" nzLg="0" nzXs="0" nzSm="0" nzMd="0">
    <mat-icon class="itemTypeIcon">supervisor_account</mat-icon>
  </nz-col>
  <nz-col nzXl="1" nzXXl="1" nzLg="0" nzXs="0" nzSm="0" nzMd="0"> </nz-col>

  <nz-col nzXl="15" nzXXl="15" nzLg="24" nzXs="24" nzSm="24" nzMd="24">
    <div [routerLink]="['/participant', participant.id]">
      <h5 nz-typography>
        {{ participant | displayName | carLimitation : 50 }}
      </h5>
      <!-- <nz-descriptions-item [nzTitle]="'Mobile'" [nzSpan]="2">
      {{ participant?.informations?.coordonnees?.mobile }}
    </nz-descriptions-item>
    <nz-descriptions-item [nzTitle]="'E-mail'" [nzSpan]="4">
      {{ participant?.informations?.coordonnees?.email }}
    </nz-descriptions-item> -->
    </div>
  </nz-col>
  <nz-col nzXl="1" nzXXl="1" nzLg="0" nzXs="0" nzSm="0" nzMd="0">
    <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
  </nz-col>

  <nz-col nzXl="2" nzXXl="2" nzLg="0" nzXs="0" nzSm="0" nzMd="0"> </nz-col>
  <nz-col nzXl="2" nzXXl="2" nzLg="0" nzXs="0" nzSm="0" nzMd="0">
    <div class="btn_action">
      <app-button-dropdown
        [type]="'more'"
        [direction]="'vertical'"
        [isUpdate]="true"
        [isExportPDF]="true"
        [isDelete]="true"
        [deleteType]="'Voulez-vous supprimer ce participant ?'"
        (select)="onSelectAction($event, participant)"
      ></app-button-dropdown>
    </div>
  </nz-col>
  <nz-col nzXl="1" nzXXl="1" nzLg="0" nzXs="0" nzSm="0" nzMd="0">
    <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
  </nz-col>
</nz-row>
