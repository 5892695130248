<div [ngSwitch]="conditionView">
  <div *ngSwitchCase="'list'">
    <ng-container>
      <app-aids-filters
        (filtersList)="onChangeFilters($event)"
        (validationAll)="onValidationAll($event)"
        [selectedAids]="selectedAids"
        [aids$]="aids$"
        [searchLoading$]="loadingAids$"
      ></app-aids-filters>
      <nz-divider
        nzOrientation="center"
        nzType="horizontal"
        nzPlain
        [nzText]="aidsCounter"
      ></nz-divider>
      <ng-template #aidsCounter>
        <nz-badge
          *ngIf="!(loadingAids$ | async)"
          [nzCount]="selectedAids | size"
          [nzStyle]="{ backgroundColor: '#52c41a' }"
          [nzOverflowCount]="10"
          nz-popover
          nzPopoverTrigger="click"
          nzTooltipPlacement="bottom"
          [nzPopoverContent]="aidsSelection"
        ></nz-badge>

        <ng-template #aidsSelection>
          <nz-list nzSize="small" nzHeader="Allocations sélectionnées">
            <nz-list-item *ngFor="let item of selectedAids">
              {{ item?.name | carLimitation: 25 }}

              <i nz-icon nzType="delete" (click)="onRemoveAid(item)"></i>
            </nz-list-item>
          </nz-list>
        </ng-template>
      </ng-template>
      <ng-template #aidLoaging>
        <i nz-icon nzType="loading"></i>
      </ng-template>

      <cdk-virtual-scroll-viewport itemSize="25" class="viewport">
        <nz-list nzGrid>
          <div nz-row [nzGutter]="16">
            <div
              nz-col
              [nzXXl]="6"
              [nzXl]="6"
              [nzLg]="8"
              [nzMd]="12"
              [nzSm]="24"
              [nzXs]="24"
              *cdkVirtualFor="let item of aids$ | async; index as i"
              class="item"
            >
              <nz-list-item>
                <nz-card
                  nzHoverable
                  nzSize="small"
                  (click)="$event.stopPropagation(); onSelect(item)"
                >
                  <div class="cardTitle">
                    <h5 nz-typography>
                      {{ item?.name | carLimitation: 50 }}
                    </h5>
                  </div>
                  <nz-divider
                    nzOrientation="center"
                    nzType="horizontal"
                  ></nz-divider>
                  <nz-content>
                    {{ item?.short_description | carLimitation: 80 }}
                  </nz-content>
                </nz-card>
              </nz-list-item>
            </div>
          </div>
        </nz-list>
      </cdk-virtual-scroll-viewport>

      <ng-template #noResults>
        <nz-card nzBorderless>
          <div class="center">
            <mat-icon class="contentEmpty iconFirst"
              >volunteer_activism</mat-icon
            >
          </div>

          <p nz-typography class="contentEmpty center notitle">{{ notitle }}</p>
        </nz-card>
      </ng-template>
    </ng-container>
  </div>
  <div *ngSwitchCase="'details'">
    <app-aids-item
      [aid]="selectedCurrent"
      (back)="onBack()"
      (validation)="onValidationAid($event)"
    ></app-aids-item>
  </div>
  <div *ngSwitchDefault></div>
</div>
