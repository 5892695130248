import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Document } from './document.model';
import * as DocumentActions from './document.actions';

export const documentsFeatureKey = 'documents';

export interface StateDocument extends EntityState<any> {
  filters: any;
  loading: boolean;
  error: any;
}

export const adapter: EntityAdapter<Document> = createEntityAdapter<any>();

export const initialState: StateDocument = adapter.getInitialState({
  filters: undefined,
  loading: false,
  error: undefined,
});

export const reducer = createReducer(
  initialState,
  //LOAD FILTERS
  on(DocumentActions.loadDocumentFilters, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(DocumentActions.loadDocumentFiltersSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      filters: action.filters,
    };
  }),
  on(DocumentActions.loadDocumentFiltersFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  }),

  //EXPORT
  on(DocumentActions.exportDocumentFilters, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),

  on(DocumentActions.exportDocumentFiltersFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
