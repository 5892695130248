import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-operation-note-action-item',
  templateUrl: './operation-note-action-item.component.html',
  styleUrls: ['./operation-note-action-item.component.scss']
})
export class OperationNoteActionItemComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
