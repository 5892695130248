import { NzModalService } from 'ng-zorro-antd/modal';
import { takeUntil } from 'rxjs/operators';
import { OperationId } from './../../../operation/store/operation.model';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { OperationState } from './../../../operation/store/operation.reducer';
import * as fromOperationAction from './../../../operation/store/operation.actions';
import * as fromOperationSelector from './../../../operation/store/operation.selectors';
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { ParticipantsSelectComponent } from 'src/app/contents/components/participants-select/components/participants-select.component';
import { ParticipantValidatorComponent } from 'src/app/contents/components/participant-validator/participant-validator.component';
import { ParticipantSmallId } from 'src/app/components/participant/store/participant.model';
import { ActivatedRoute, Data } from '@angular/router';

@Component({
  selector: 'app-operation-participants',
  templateUrl: './operation-participants.component.html',
  styleUrls: ['./operation-participants.component.scss'],
})
export class OperationParticipantsComponent implements OnInit, OnDestroy {
  participants$: Observable<any> = of(null);
  subscribe = new Subject();
  view$ = new BehaviorSubject<string>('list');
  loading$: Observable<boolean> = of(false);
  title: string = 'Nouveau participant';
  newCardTitle: string = 'Nouveau participant';
  newCardDescription: string = 'Ajouter un participant au projet';
  noModuleTitle: string = 'Aucun participant ajouté';
  newCardDefinition: string = 'Un participant est un bénéficiaire du projet.';

  @ViewChild('participantFormTitle', { static: false })
  participantFormTitle: TemplateRef<any> | any;

  modalWidth: number = 850;

  @Input() operation$: Observable<OperationId | any> = of(null);
  @Output() select = new EventEmitter<string>(false);

  constructor(
    private operationStore: Store<OperationState>,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private route: ActivatedRoute
  ) {
    const data: Data = this.route.snapshot.data;
    this.operationStore.dispatch(
      fromOperationAction.setActviteRoute({ title: data?.title })
    );
  }

  ngOnInit(): void {
    this.getParticipants();
    this.loadParticipants();
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getParticipants(): void {
    this.participants$ = this.operationStore.select(
      fromOperationSelector.participants
    );
  }
  loadParticipants(): void {
    this.operation$.pipe(takeUntil(this.subscribe)).subscribe((operation) => {
      if (!operation) return;
      this.operationStore.dispatch(
        fromOperationAction.loadParticipantsOperation({
          operationId: operation.id,
        })
      );
    });
  }

  onSelect(operation: OperationId, participant: any): void {
    if (!operation || !participant) return;
    this.select.emit('participantItem');
    this.operationStore.dispatch(
      fromOperationAction.loadParticipantOperation({
        operationId: operation.id,
        id: participant.id,
      })
    );
    this.view$.next('details');
  }

  onNew(operation: OperationId): void {
    const modal = this.modal.create({
      nzContent: ParticipantsSelectComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.participantFormTitle,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });
    const instance = modal.getContentComponent();
    instance.cancel.subscribe((event: any): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
    instance.select.subscribe((participant: ParticipantSmallId): any => {
      if (!participant) {
        return null;
      } else {
        modal.close();
        this.onAdd(participant, operation);
      }
    });
    // instance.operation$ = this.operation$;
  }

  onType(participant: ParticipantSmallId, operation: OperationId): void {
    const modal = this.modal.create({
      nzContent: ParticipantValidatorComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: `Type d'intervention au projet ${operation.denomination}`,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });
    const instance = modal.getContentComponent();
    instance.cancel.subscribe((event: any): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
    instance.validate.subscribe((intervention: any): any => {
      if (!intervention) {
        return null;
      } else {
        modal.close();
        this.onAdd(participant, operation);
      }
    });
  }

  onAdd(participant: ParticipantSmallId, operation: OperationId): void {
    if (!operation || !participant) return;
    this.operationStore.dispatch(
      fromOperationAction.addParticipantOperation({ operation, participant })
    );
  }

  onDelete(operation: OperationId, participant: any): void {
    if (!operation || !participant) return;
    this.operationStore.dispatch(
      fromOperationAction.deleteParticipantOperation({
        operationId: operation.id,
        participantId: participant.id,
      })
    );
  }

  onCardActions(event: any, item: any, operation: OperationId): void {
    const { type } = event;
    switch (type) {
      case 'export':
        break;
      case 'delete':
        this.onDelete(operation, item);

        break;
      default:
        break;
    }
  }

  onBack(): void {
    this.view$.next('list');
  }

  onCancel(): void {}
}
