<form nz-form [formGroup]="justiceForm">
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="24">
      <nz-form-item>
        <nz-form-label [nzSpan]="24">Restriction judiciaire </nz-form-label>
        <nz-form-control>
          <nz-select
            formControlName="justice"
            nzBorderless
            nzPlaceHolder="Situation judiciaire"
            [nzDropdownRender]="justiceOtherTemplate"
            [nzLoading]="(judiciaire$ | async | size) ? false : true"
          >
            <nz-option
              *ngFor="let item of judiciaire$ | async"
              [nzValue]="item"
              [nzLabel]="item"
            ></nz-option>

            <ng-template #justiceOtherTemplate>
              <nz-divider></nz-divider>
              <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
                <nz-col nzSpan="1"></nz-col>
                <nz-col nzSpan="22">
                  <input
                    nz-input
                    nzBorderless
                    class="borderlessClass"
                    placeholder="Autre préciser..."
                    formControlName="justice"
                    #inputElement
                  />
                </nz-col>
                <nz-col nzSpan="1"></nz-col>
              </nz-row>
              <nz-divider></nz-divider>
            </ng-template>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>

  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="8">
      <app-check-card
        [title]="'Casier jusdiciaire'"
        [isActive]="justiceForm.value.caseJudiciaire"
        [definition]="casierJudiciaireTitle"
        [height]="'50px'"
        [fontSize]="'14px'"
        (select)="checkboxChanges($event, 'casier')"
      ></app-check-card>
    </nz-col>
    <nz-col nzSpan="8">
      <app-check-card
        [title]="'Peine judiciaire'"
        [isActive]="justiceForm.value.executionDePeineEnCours"
        [definition]="executionDePeineEnCoursTitle"
        [height]="'50px'"
        [fontSize]="'14px'"
        (select)="checkboxChanges($event, 'execution')"
      ></app-check-card>
    </nz-col>
    <nz-col nzSpan="8">
      <app-check-card
        [title]="'Suivi SPIP/PJJ'"
        [isActive]="justiceForm.value.suiviSpipPjj"
        [definition]="suiviSpipPjjTitlte"
        (select)="checkboxChanges($event, 'suiviSpipPjj')"
        [height]="'50px'"
        [fontSize]="'14px'"
      ></app-check-card>
    </nz-col>
  </nz-row>

  <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="24">
      <nz-form-item>
        <nz-form-control>
          <label nz-checkbox formControlName="isFrein">{{
            isFreinTitle
          }}</label>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>
</form>

<ng-template #cardTitle>
  <app-header-title [icon]="'balance'"></app-header-title>
</ng-template>
