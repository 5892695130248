import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-redaction-card',
  templateUrl: './redaction-card.component.html',
  styleUrls: ['./redaction-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RedactionCardComponent implements OnInit, OnChanges {
  @Input() item: any = null;
  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}
}
