import { ParticipantId } from 'src/app/components/participant/store/participant.model';
import { Injectable } from '@angular/core';
import { Firestore, doc, getDoc } from '@angular/fire/firestore';
import { Observable, of, from, EMPTY } from 'rxjs';
import { switchMap, mergeMap, map, catchError } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { UserState } from '../components/user/store/user.reducer';
import * as fromUserSelector from '../components/user/store/user.selectors';
import { toUpper, upperFirst, capitalize, forEach, camelCase } from 'lodash';
import {
  fromUnixTime,
  format,
  formatDistance,
  formatDistanceToNow,
  formatDistanceStrict,
  differenceInYears,
} from 'date-fns';
import { fr } from 'date-fns/locale';
// var FileSaver = require('file-saver');
import { saveAs } from 'file-saver';
declare const require: any;
const { jsPDF } = require('jspdf');
require('jspdf-autotable');
import * as html2canvas from 'html2canvas';
import { ParticipantState } from '../components/participant/store/participant.reducer';
import { ParticipantService } from './participant.service';
import * as fromParticipantAction from './../components/participant/store/participant.actions';
import * as fromParticipantSelector from './../components/participant/store/participant.selectors';

@Injectable({
  providedIn: 'any',
})
export class ParticipantProjetproExportPdfService {
  pageWidth: number = 515.28;

  constructor(
    private db: Firestore,
    private userStore: Store<UserState>,
    private participantStore: Store<ParticipantState>
  ) {}

  export_PROJETPROFESSIONNEL(
    participant: ParticipantId,
    projetprofessionnel: any,
    anonym: boolean = false
  ): Observable<any> {
    try {
      return this.userStore.pipe(
        select(fromUserSelector.user),
        switchMap((user) => {
          if (!user || !participant || !projetprofessionnel) {
            return of(null);
          }

          const checkCrossOrigin = false;
          const xhr = new XMLHttpRequest();
          xhr.open('GET', user.organisation.logo);
          xhr.responseType = 'blob';
          xhr.send();
          xhr.addEventListener('load', () => {
            const reader = new FileReader();
            reader.readAsDataURL(xhr.response);
            reader.addEventListener('loadend', (): any => {
              const logo = reader.result;
              if (logo) {
                const date = format(new Date(Date.now()), 'yyyyMM', {
                  locale: fr,
                });

                // Date
                // set PDF
                const pdfSize = 'a4';
                const doc = new jsPDF('p', 'pt', pdfSize);
                doc.page = 1;
                // set propretie informations pdf
                doc.setProperties({
                  title: `Fiche liaison `,
                  type: 'Fiche liaison',
                  particpant: ``,
                  date: format(new Date(Date.now()), 'dd/MM/yyyy', {
                    locale: fr,
                  }),
                  author: user.displayName,
                  keywords: 'avis de situation projet professionnel',
                });

                this.footerPdf(user, doc);

                //open || download || print
                const nameFile: string = anonym
                  ? `Fiche_Liaison_${date}.pdf`
                  : `Fiche_Liaison_${date}.pdf`;
                doc.save(`${nameFile}`);
              }
            });

            return of(null);
          });

          this.participantStore.dispatch(
            fromParticipantAction.loading({ loading: false })
          );
          return of(`Avis de situation téléchargé`);
        })
      );
    } catch (err) {
      return of(err);
    }
  }

  headerPdf(data: any, user: any, doc: any, anonym: boolean, logo: any): any {
    doc.setFont('helvetica', 'normal');

    doc.setFontSize(10);
    doc.setFont('helvetica', 'bold');
    doc.text(`${user.organisation.nom_raison_sociale}`, 40, 40);
    doc.setFontSize(7);
    doc.setFont('helvetica', 'normal');
    doc.text(`Siret : ${user.organisation.siret}`, 40, 50);

    doc.text(40, 58, `Adresse : ${user.organisation.geo_adresse}`, {
      maxWidth: 140,
    });

    doc.setFontSize(10);
    doc.setFont('helvetica', 'bold');
    doc.text(
      `Date de création : ${format(new Date(Date.now()), 'dd/MM/yyyy', {
        locale: fr,
      })}`,
      40,
      90
    );

    // Set author
    doc.setFontSize(14);
    doc.setFont('helvetica', 'normal');
    doc.setFont('helvetica', 'bold');
    doc.text(`Avis de situation`, 257.64, 40);
    doc.setFont('helvetica', 'italic');
    doc.setFontSize(12);
    doc.text(`${anonym ? 'Anonymisée' : ''}`, 257.64, 60);

    // Set logo organisme
    doc.setFontSize(10);
    logo ? doc.addImage(logo, 'PNG', 480, 15, 80, 80) : null;
  }

  footerPdf(user: any, doc: any): any {
    const pageCount = doc.internal.getNumberOfPages();
    let str = `Page ${doc.internal.getNumberOfPages()}`;
    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(7);
      doc.text(
        'Page ' + String(i) + ' sur ' + String(pageCount),
        515.28,
        810,
        null,
        null,
        'right'
      );
    }

    doc.setFontSize(7);
    doc.setFont('helvetica', 'normal');
    doc.text(
      `${user.organisation.nom_raison_sociale}`,
      280,
      810,
      null,
      null,
      'center'
    );
  }

  identity_table(
    displayName: string,
    participant: ParticipantId,
    doc: any,
    logo: any,
    user: any,
    anonym: boolean = false
  ): any {
    const PARTICIPANTheadTitle = [
      [
        {
          content: 'Participant',
          colSpan: 4,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'bold',
            fillColor: [0, 55, 102],
            textColor: [255, 255, 255],
          },
        },
      ],
      [
        {
          content: 'Identité',
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Sexe',
          styles: {
            fontStyle: 'normal',
            halign: 'center',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Naissance',
          styles: {
            fontStyle: 'normal',
            halign: 'center',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Âge',
          styles: {
            fontStyle: 'normal',
            halign: 'center',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
    ];

    const PARTICIPANTbodyTitle = [
      [
        {
          content: anonym ? '' : `${displayName.toUpperCase()}`,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: participant.sexe ? participant.sexe : 'Non renseigné',
          styles: {
            halign: 'center',
            fontStyle: 'normal',
          },
        },
        {
          content: participant?.dateNaissance
            ? format(
                fromUnixTime(participant?.dateNaissance['seconds']),
                'dd/MM/yyyy',
                { locale: fr }
              )
            : 'Non renseignée',
          styles: {
            halign: 'center',
            fontStyle: 'normal',
          },
        },
        {
          content: participant?.dateNaissance
            ? `${differenceInYears(
                new Date(Date.now()),
                fromUnixTime(participant?.dateNaissance['seconds'])
              )} ans`
            : 'Non renseigné',
          styles: {
            halign: 'center',
            fontStyle: 'normal',
          },
        },
      ],
    ];

    doc.autoTable({
      theme: 'grid',
      head: PARTICIPANTheadTitle,
      body: PARTICIPANTbodyTitle,
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },
      headStyles: {},
      columnStyles: {
        0: { cellWidth: 290.28 },
        1: { cellWidth: 75 },
        2: { cellWidth: 75 },
        3: { cellWidth: 75 },
      },

      bodyStyles: {
        0: { fontStyle: 'bold' },
      },

      didDrawPage: (data: any) => {
        this.headerPdf(data, user, doc, anonym, logo);
      },

      margin: { top: 100 },
    });
  }
}
