import { Etablissement } from 'src/app/components/organisation/store/organisation.model';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-participant-prescripteur-card',
  templateUrl: './participant-prescripteur-card.component.html',
  styleUrls: ['./participant-prescripteur-card.component.scss'],
})
export class ParticipantPrescripteurCardComponent implements OnInit {
  siretTitle: string = 'Siret';
  @Input() item: any;
  @Output() delete: EventEmitter<any> = new EventEmitter(false);

  constructor() {}

  ngOnInit(): void {}

  onCancel(): void {}
  onDelete(prescripteur: Etablissement): void {
    if (!prescripteur) return;
    this.delete.emit(prescripteur);
  }
}
