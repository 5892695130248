import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Navigation } from './navigation.model';

export const loadNavigations = createAction(
  '[Navigation/API] Load Navigations',
  props<{ navigations: Navigation[] }>()
);

export const addNavigation = createAction(
  '[Navigation/API] Add Navigation',
  props<{ navigation: Navigation }>()
);

export const upsertNavigation = createAction(
  '[Navigation/API] Upsert Navigation',
  props<{ navigation: Navigation }>()
);

export const addNavigations = createAction(
  '[Navigation/API] Add Navigations',
  props<{ navigations: Navigation[] }>()
);

export const upsertNavigations = createAction(
  '[Navigation/API] Upsert Navigations',
  props<{ navigations: Navigation[] }>()
);

export const updateNavigation = createAction(
  '[Navigation/API] Update Navigation',
  props<{ navigation: Update<Navigation> }>()
);

export const updateNavigations = createAction(
  '[Navigation/API] Update Navigations',
  props<{ navigations: Update<Navigation>[] }>()
);

export const deleteNavigation = createAction(
  '[Navigation/API] Delete Navigation',
  props<{ id: string }>()
);

export const deleteNavigations = createAction(
  '[Navigation/API] Delete Navigations',
  props<{ ids: string[] }>()
);

//MESSAGES DRAWER
export const openMessageDrawer = createAction(
  '[Navigation/COMPONENT] MESSAGE DRAWER EVENTS',
  props<{ event: boolean }>()
);

export const clearNavigations = createAction(
  '[Navigation/API] Clear Navigations'
);
