<ng-container *ngIf="participant$ | async as participant">
  <nz-row nzAlign="top" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="4">
      <nz-space nzDirection="vertical" nzSize="small"></nz-space>
      <app-participant-diagnostic-form-steps
        (step)="onStepsChanges($event)"
        [view$]="view$"
      ></app-participant-diagnostic-form-steps>
    </nz-col>
    <nz-col nzSpan="20">
      <div [ngSwitch]="currentStep">
        <div *ngSwitchCase="0">
          <app-participant-diagnostic-form-definition
            [definition$]="updateDefinition$"
            (add)="onSetDefinition($event)"
          ></app-participant-diagnostic-form-definition>
        </div>
        <div *ngSwitchCase="1">
          <nz-card [nzTitle]="diagnosticCardTitle">
            <app-item-tabset
              [tabs]="
                (view$ | async) === 'participant'
                  ? [
                      {
                        name: 'Administratif',
                        index: 0,
                        template: diagnosticAdministratif
                      },
                      {
                        name: 'Famille',
                        index: 1,
                        template: diagnosticFamille
                      },
                      {
                        name: 'Logement',
                        index: 2,
                        template: diagnosticLogement
                      },

                      {
                        name: 'Mobilité',
                        index: 3,
                        template: diagnosticMobilite
                      },
                      {
                        name: 'Santé',
                        index: 4,
                        template: diagnosticSante
                      },
                      {
                        name: 'Justice',
                        index: 6,
                        template: diagnosticJustice
                      },
                      {
                        name: 'Droit',
                        index: 7,
                        template: diagnosticDroit
                      }
                    ]
                  : [
                      {
                        name: 'Administratif',
                        index: 0,
                        template: diagnosticAdministratif
                      }
                    ]
              "
            ></app-item-tabset>
          </nz-card>
          <ng-template #diagnosticCardTitle>
            <app-header-title [icon]="'volunteer_activism'"></app-header-title>
          </ng-template>
        </div>
        <div *ngSwitchCase="2">
          <nz-card [nzTitle]="diagnosticQualificationcardTitle">
            <app-item-tabset
              [tabs]="[
                {
                  name: 'Qualification',
                  index: 0,
                  template: diagnosticQualification
                },
                {
                  name: 'Numérique',
                  index: 1,
                  template: diagnosticNumerique
                }
              ]"
            ></app-item-tabset>
          </nz-card>
          <ng-template #diagnosticQualificationcardTitle>
            <app-header-title [icon]="'school'"></app-header-title>
          </ng-template>
        </div>
        <div *ngSwitchCase="3">
          <app-participant-diagnostic-form-ressources
            (add)="onSetRessource($event)"
          ></app-participant-diagnostic-form-ressources>
        </div>
        <div *ngSwitchCase="4">
          <app-participant-diagnostic-form-aptitudes
            (add)="onSetAptitudes($event)"
          ></app-participant-diagnostic-form-aptitudes>
        </div>
        <div *ngSwitchCase="5">
          <nz-card [nzTitle]="diagnosticCardProfessionnelTitle">
            <app-item-tabset
              [tabs]="[
                {
                  name: 'Pôle Emploi',
                  index: 0,
                  template: diagnosticPoleEmploi
                },
                {
                  name: 'Expériences',
                  index: 1,
                  template: diagnosticExperience
                },

                {
                  name: 'Projet professionnel',
                  index: 2,
                  template: diagnosticProjetProfessionnel
                },
                {
                  name: 'Disponibilité',
                  index: 3,
                  template: diagnosticDisponibilite
                },
                {
                  name: 'Postures',
                  index: 4,
                  template: diagnosticPosture
                }
              ]"
            ></app-item-tabset>
          </nz-card>
          <ng-template #diagnosticCardProfessionnelTitle>
            <app-header-title [icon]="'business_center'"></app-header-title>
          </ng-template>
        </div>
        <div *ngSwitchCase="6">
          <app-participant-diagnostic-form-resum></app-participant-diagnostic-form-resum>
        </div>
        <div *ngSwitchCase="7">
          <app-participant-diagnostic-form-actions></app-participant-diagnostic-form-actions>
        </div>
        <div *ngSwitchCase="8">
          <app-participant-diagnostic-form-events></app-participant-diagnostic-form-events>
        </div>
        <div *ngSwitchDefault></div>
      </div>
    </nz-col>
  </nz-row>

  <nz-space nzDirection="vertical" nzSize="middle"></nz-space>

  <nz-row nzAlign="middle" nzJustify="end" nzGutter="8">
    <nz-col nzSpan="16"></nz-col>
    <nz-col nzSpan="4">
      <button
        nz-button
        nzBlock
        nzType="text"
        (click)="$event.stopPropagation(); onCancel()"
      >
        Annuler
      </button>
    </nz-col>
    <nz-col nzSpan="4">
      <button
        *ngIf="(formStatus$ | async) === 'add'"
        nz-button
        nzBlock
        (click)="$event.stopPropagation(); onAdd(participant)"
        [disabled]="!diagnosticForm.valid"
        nzType="primary"
      >
        Ajouter
      </button>

      <ng-container *ngIf="(formStatus$ | async) === 'update'">
        <button
          *ngIf="diagnostic$ | async as diagnostic"
          nz-button
          nzBlock
          (click)="$event.stopPropagation(); onUpdate(diagnostic)"
          [disabled]="!diagnosticForm.valid"
          nzType="primary"
        >
          Modifier
        </button>
      </ng-container>
    </nz-col>
  </nz-row>

  <ng-template #diagnosticAdministratif>
    <app-participant-diagnostic-form-administratif
      [administratif$]="updateAdministratif$"
      (add)="onSetAdministratif($event)"
    ></app-participant-diagnostic-form-administratif>
  </ng-template>
  <ng-template #diagnosticFamille>
    <app-participant-diagnostic-form-famille
      [famille$]="updateFamille$"
      (add)="onSetFamille($event)"
    ></app-participant-diagnostic-form-famille>
  </ng-template>
  <ng-template #diagnosticLogement>
    <app-participant-diagnostic-logement
      [logement$]="updateLogement$"
      (add)="onSetLogement($event)"
    ></app-participant-diagnostic-logement>
  </ng-template>
  <ng-template #diagnosticJustice>
    <app-participant-diagnostic-form-justice
      [justice$]="updateJustice$"
      (add)="onSetJustice($event)"
    ></app-participant-diagnostic-form-justice>
  </ng-template>

  <ng-template #diagnosticMobilite>
    <app-participant-diagnostic-form-mobilite
      [mobilite$]="updateMobilite$"
      (add)="onSetMobilite($event)"
    ></app-participant-diagnostic-form-mobilite>
  </ng-template>

  <ng-template #diagnosticSante>
    <app-participant-diagnostic-form-sante
      [santeInput$]="updateSante$"
      (add)="onSetSante($event)"
    ></app-participant-diagnostic-form-sante>
  </ng-template>

  <ng-template #diagnosticDroit>
    <app-participant-diagnostic-form-droit
      [droit$]="updateDroit$"
      (add)="onSetDroit($event)"
    ></app-participant-diagnostic-form-droit>
  </ng-template>

  <ng-template #diagnosticQualification>
    <app-participant-diagnostic-qualification
      [qualificationInput$]="updateQualification$"
      (add)="onSetQualification($event)"
    ></app-participant-diagnostic-qualification>
  </ng-template>
  <ng-template #diagnosticNumerique>
    <app-participant-diagnostic-form-numerique
      [numeriqueInput$]="updateNumerique$"
      (add)="onSetNumerique($event)"
    ></app-participant-diagnostic-form-numerique>
  </ng-template>

  <ng-template #diagnosticProjetProfessionnel>
    <app-participant-diagnostic-form-professionnelle
      (add)="onSetProjetProfessionnel($event)"
    ></app-participant-diagnostic-form-professionnelle>
  </ng-template>

  <ng-template #diagnosticDisponibilite>
    <app-participant-diagnostic-form-disponibility
      [disponibleInput$]="updateDisponible$"
      (add)="onSetDisponibilite($event)"
    ></app-participant-diagnostic-form-disponibility>
  </ng-template>

  <ng-template #diagnosticPosture>
    <app-participant-diagnostic-form-postures
      [postureInput$]="updatePosture$"
      (add)="onSetPosture($event)"
    ></app-participant-diagnostic-form-postures>
  </ng-template>

  <ng-template #diagnosticExperience>
    <app-participant-diagnostic-form-experience
      (add)="onSetExperiences($event)"
    ></app-participant-diagnostic-form-experience>
  </ng-template>
  <ng-template #diagnosticPoleEmploi>
    <app-participant-diagnostic-form-pole-emploi
      [poleEmploiInput$]="updatePoleEmploi$"
      (add)="onSetPoleEmploi($event)"
    ></app-participant-diagnostic-form-pole-emploi>
  </ng-template>
</ng-container>
