import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NzMessageService } from 'ng-zorro-antd/message';
import { of } from 'rxjs';
import { catchError, mergeMap, map, tap, exhaustMap } from 'rxjs/operators';
import { MessagesService } from '../../../service/messages.service';
import * as fromMessageActions from './message.actions';
import { messageState } from './message.reducer';
import { Store, select } from '@ngrx/store';

@Injectable()
export class MessageEffects {
  loadMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMessageActions.loadMessages),
      tap(() =>
        this.storeMessage.dispatch(
          fromMessageActions.loadingMessages({ loader: true })
        )
      ),
      mergeMap((action) =>
        this.messageService.get__MESSAGES().pipe(
          map((messages: any) => {
            return fromMessageActions.loadMessagesSuccess({ messages });
          }),
          catchError((error) =>
            of(fromMessageActions.loadMessagesFailure({ error }))
          )
        )
      ),
      tap(() =>
        this.storeMessage.dispatch(
          fromMessageActions.loadingMessages({ loader: false })
        )
      )
    )
  );

  listenMessages$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromMessageActions.listenOnNewMessages),
        mergeMap((action) =>
          this.messageService.listner__MESSAGES().pipe(
            map((notification: any) => {
              if (!notification) {
                return null;
              } else {
                const { user, type, index, audioURL } = notification;

                const data = notification?.data ? notification.data : null;

                if (!data) {
                  return of(null);
                } else {
                  const { displayName, id } = data.lastMessage.auteur;
                  const userId: string = user.id;
                  const isCurrentUser = id === userId ? true : false;
                  const typeMessage =
                    type === 'added'
                      ? `Nouveau message de ${displayName}`
                      : `Réponse de ${displayName} au sujet ${data.subtitle}`;
                  if (type === 'added') {
                    if (isCurrentUser) {
                      return of(null);
                    } else {
                      if (index < 0) {
                        return null;
                      } else {
                        const notificationMessage: string = typeMessage;
                        this.message.info(notificationMessage, {
                          nzDuration: 4000,
                        });

                        const audio = new Audio(audioURL);
                        audio.play();

                        return of(null);
                      }
                    }
                  } else {
                    if (isCurrentUser) {
                      return of(null);
                    } else {
                      if (index < 0) {
                        return null;
                      } else {
                        const notificationMessage: string = typeMessage;
                        this.message.info(notificationMessage, {
                          nzDuration: 4000,
                        });
                        const audio = new Audio(audioURL);
                        audio.play();

                        return of(null);
                      }
                    }
                  }
                }
              }
            })
          )
        )
      ),
    { dispatch: false }
  );

  loadMyMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMessageActions.loadMyMessages),
      tap(() =>
        this.storeMessage.dispatch(
          fromMessageActions.loadingMessages({ loader: true })
        )
      ),

      mergeMap((action) =>
        this.messageService.getMy__MESSAGES().pipe(
          map((messages: any) =>
            fromMessageActions.loadMyMessagesSuccess({
              messages,
            })
          ),
          catchError((error) =>
            of(fromMessageActions.loadMyMessagesFailure({ error }))
          )
        )
      ),
      tap(() =>
        this.storeMessage.dispatch(
          fromMessageActions.loadingMessages({ loader: false })
        )
      )
    )
  );

  loadReceiveMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMessageActions.loadReceiveMessages),
      tap(() =>
        this.storeMessage.dispatch(
          fromMessageActions.loadingMessages({ loader: true })
        )
      ),
      mergeMap((action) =>
        this.messageService.getReceive__MESSAGES().pipe(
          map((messages: any) =>
            fromMessageActions.loadReceiveMessagesSuccess({
              messages,
            })
          ),
          catchError((error) =>
            of(fromMessageActions.loadReceiveMessagesFailure({ error }))
          )
        )
      ),
      tap(() =>
        this.storeMessage.dispatch(
          fromMessageActions.loadingMessages({ loader: false })
        )
      )
    )
  );

  loadMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMessageActions.loadMessage),
      mergeMap((action) =>
        this.messageService.get__MESSAGE(action.id).pipe(
          map((message: any) =>
            fromMessageActions.loadMessageSuccess({ message })
          ),
          catchError((error) =>
            of(fromMessageActions.loadMessageFailure({ error }))
          )
        )
      )
    )
  );

  addMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMessageActions.addMessage),
      mergeMap((action) =>
        this.messageService.add__MESSAGE(action.message).pipe(
          map((message: any) => {
            this.message.success('Message envoyé');
            return fromMessageActions.addMessageSuccess({ message });
          }),
          catchError((error) =>
            of(fromMessageActions.addMessageFailure({ error }))
          )
        )
      )
    )
  );

  updateMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMessageActions.updateMessage),
      mergeMap((action) =>
        this.messageService
          .add__new_text_MESSAGE(action.message.id, action.message.changes)
          .pipe(
            map((message: any) =>
              fromMessageActions.updateMessageSuccess({ message })
            ),
            catchError((error) =>
              of(fromMessageActions.updateMessageFailure({ error }))
            )
          )
      )
    )
  );

  deleteMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMessageActions.deleteMessage),
      mergeMap((action) =>
        this.messageService.delete__MESSAGE(action.id).pipe(
          map((messageSuccess: any) => {
            const success: string = 'Message supprimée';
            this.message.success(success);
            return fromMessageActions.deleteMessageSuccess({ success });
          }),
          catchError((error) =>
            of(fromMessageActions.deleteMessageFailure({ error }))
          )
        )
      )
    )
  );

  deleteAllMessage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromMessageActions.deleteMessages),
        tap(() => {
          this.storeMessage.dispatch(
            fromMessageActions.loadingMessages({ loader: true })
          );
        }),

        mergeMap((action) =>
          this.messageService.deleteAll__MESSAGES().pipe(
            map((success: any) =>
              fromMessageActions.deleteMessageSuccess({ success })
            ),
            catchError((error) =>
              of(fromMessageActions.deleteMessagesFailure({ error }))
            )
          )
        ),
        tap(() => {
          this.storeMessage.dispatch(
            fromMessageActions.loadingMessages({ loader: false })
          );
        })
      ),
    { dispatch: false }
  );

  deleteAllMyMessage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromMessageActions.deleteMyMessages),
        tap(() => {
          this.storeMessage.dispatch(
            fromMessageActions.loadingMessages({ loader: true })
          );
        }),
        mergeMap((action) =>
          this.messageService.deleteAll__MESSAGES().pipe(
            map((succes: string) =>
              fromMessageActions.deleteMyMessagesSuccess({ succes })
            ),
            catchError((error) =>
              of(fromMessageActions.deleteMyMessagesFailure({ error }))
            )
          )
        ),
        tap(() => {
          this.storeMessage.dispatch(
            fromMessageActions.loadingMessages({ loader: false })
          );
        })
      ),
    { dispatch: false }
  );

  deleteAllReceiveMessage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromMessageActions.deleteReceiveMessages),
        tap(() => {
          this.storeMessage.dispatch(
            fromMessageActions.loadingMessages({ loader: true })
          );
        }),

        mergeMap((action) =>
          this.messageService.deleteAllReceive__MESSAGES().pipe(
            map((succes: string) =>
              fromMessageActions.deleteReceiveMessagesSuccess({
                succes,
              })
            ),
            catchError((error) =>
              of(fromMessageActions.deleteReceiveMessagesFailure({ error }))
            )
          )
        ),
        tap(() => {
          this.storeMessage.dispatch(
            fromMessageActions.loadingMessages({ loader: false })
          );
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private message: NzMessageService,
    private messageService: MessagesService,
    private storeMessage: Store<messageState>
  ) {}
}
