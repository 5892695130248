<!-- <ng-container *ngIf="total > 0; else noResult"></ng-container> -->
<app-dashboard-headers
  [title]="selected$ | async | uppercase"
  [total]="total"
></app-dashboard-headers>
<div class="container">
  <app-dashboard-entites
    *ngIf="(selected$ | async) === 'entites'"
    (title)="setTitle($event)"
    (total)="setTotal($event)"
  ></app-dashboard-entites>
  <app-dashboard-contacts
    *ngIf="(selected$ | async) === 'contacts'"
  ></app-dashboard-contacts>
  <app-dashboard-participants
    *ngIf="(selected$ | async) === 'participants'"
  ></app-dashboard-participants>
  <app-dashboard-projets
    *ngIf="(selected$ | async) === 'projets'"
  ></app-dashboard-projets>
</div>

<ng-template #noResult>
  <app-no-result
    [icon]="'pie_chart'"
    [notitle]="notitle"
    [isButton]="false"
  ></app-no-result>
</ng-template>
