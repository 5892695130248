import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  GeoAdresseState,
  geoAdressesFeatureKey,
  selectAll,
} from './geo-adresse.reducer';

export const selectGeoState = createFeatureSelector<GeoAdresseState>(
  geoAdressesFeatureKey
);

export const selectAdresses = createSelector(selectGeoState, selectAll);

export const selectAdresse = createSelector(
  selectGeoState,
  (state: GeoAdresseState) => {
    return state.adresse;
  }
);

export const selectComplement = createSelector(
  selectGeoState,
  (state: GeoAdresseState) => {
    return state.complement;
  }
);

export const selectQpv = createSelector(
  selectGeoState,
  (state: GeoAdresseState) => {
    return state.qpv;
  }
);

export const adresses = createSelector(
  selectGeoState,
  (state: GeoAdresseState) => {
    return state.adresses;
  }
);

export const loading = createSelector(
  selectGeoState,
  (state: GeoAdresseState) => {
    return state.loading;
  }
);

export const selectError = createSelector(
  selectGeoState,
  (state: GeoAdresseState) => {
    return state.error;
  }
);
