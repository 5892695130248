<!-- <button
  [class]="
    type === 'primary'
      ? 'btn-primary'
      : type === 'default'
      ? 'btn-default'
      : 'btn-default-text'
  "
  nz-dropdown
  [nzDropdownMenu]="buttonMenus"
  nzTrigger="click"
  nzPlacement="bottomRight"
>
  {{ title }}
</button>

<nz-dropdown-menu #buttonMenus="nzDropdownMenu">
  <ul nz-menu nzSelectable>
    <ng-container *ngFor="let item of menu">
      <li
        *ngIf="!item?.isDivider"
        nz-menu-item
        (click)="$event.stopPropagation(); onSelect(item.title)"
      >
        <nz-space nzDirection="horizontal" nzSize="large">
          <ng-container *ngIf="item?.isMatIcon">
            <mat-icon
              *nzSpaceItem
              [ngStyle]="{ color: item?.color ? item.color : '#0096c7' }"
              >{{ item?.icon }}</mat-icon
            >
          </ng-container>
          <ng-container *ngIf="!item?.isMatIcon">
            <i
              nz-icon
              [nzType]="item?.icon ? item.icon : 'file-pdf'"
              [ngStyle]="{
                color: item?.color ? item.color : '#0096c7',
                'font-size': '24px'
              }"
            ></i>
          </ng-container>
          <nz-space
            *nzSpaceItem
            nzDirection="horizontal"
            nzSize="large"
          ></nz-space>
          <span *nzSpaceItem class="title">{{ item?.title }}</span>
        </nz-space>
      </li>
      <li *ngIf="item?.isDivider" nz-menu-divider></li>
    </ng-container>
  </ul>
</nz-dropdown-menu> -->

<div class="dropdown dropdown-left">
  <label
    tabindex="0"
    [class]="
      type === 'primary'
        ? 'btn btn-sm btn-primary m-1'
        : type === 'default'
        ? 'btn btn-sm m-1'
        : 'btn btn-sm btn-ghost m-1'
    "
    *ngIf="title"
  >
    {{ title }}
  </label>
  <button class="btn btn-circle btn-ghost" *ngIf="!title">
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="页面-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g id="System" transform="translate(-912.000000, -288.000000)">
          <g id="classify_line" transform="translate(912.000000, 288.000000)">
            <path
              d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z"
              id="MingCute"
              fill-rule="nonzero"
            ></path>
            <path
              d="M7,3 C4.79086,3 3,4.79086 3,7 C3,9.20914 4.79086,11 7,11 C9.20914,11 11,9.20914 11,7 C11,4.79086 9.20914,3 7,3 Z M5,7 C5,5.89543 5.89543,5 7,5 C8.10457,5 9,5.89543 9,7 C9,8.10457 8.10457,9 7,9 C5.89543,9 5,8.10457 5,7 Z M7,13 C4.79086,13 3,14.7909 3,17 C3,19.2091 4.79086,21 7,21 C9.20914,21 11,19.2091 11,17 C11,14.7909 9.20914,13 7,13 Z M5,17 C5,15.8954 5.89543,15 7,15 C8.10457,15 9,15.8954 9,17 C9,18.1046 8.10457,19 7,19 C5.89543,19 5,18.1046 5,17 Z M13,7 C13,4.79086 14.7909,3 17,3 C19.2091,3 21,4.79086 21,7 C21,9.20914 19.2091,11 17,11 C14.7909,11 13,9.20914 13,7 Z M17,5 C15.8954,5 15,5.89543 15,7 C15,8.10457 15.8954,9 17,9 C18.1046,9 19,8.10457 19,7 C19,5.89543 18.1046,5 17,5 Z M17,13 C14.7909,13 13,14.7909 13,17 C13,19.2091 14.7909,21 17,21 C19.2091,21 21,19.2091 21,17 C21,14.7909 19.2091,13 17,13 Z M15,17 C15,15.8954 15.8954,15 17,15 C18.1046,15 19,15.8954 19,17 C19,18.1046 18.1046,19 17,19 C15.8954,19 15,18.1046 15,17 Z"
              id="形状"
              fill="#09244BFF"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  </button>

  <ul
    tabindex="0"
    class="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52 z-30"
  >
    <ng-container *ngFor="let item of menu; let i = index">
      <ng-container *ngIf="item?.isDivider">
        <li class="divide" *ngIf="i !== 0"></li>
        <li class="menu-title">
          <span>{{ item?.title }}</span>
        </li>
      </ng-container>

      <ng-container *ngIf="!item?.isDivider">
        <li
          (click)="$event.stopPropagation(); onSelect(item.title)"
          class="gap-2"
        >
          <ng-container *ngIf="item?.icon">
            {{ item?.icon }}
          </ng-container>
          <a>{{ item?.title }}</a>
        </li>
      </ng-container>
    </ng-container>
  </ul>
</div>
