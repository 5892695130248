import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Message, MessageId } from './message.model';
import * as MessageActions from './message.actions';

export const messagesFeatureKey = 'messages';

export interface messageState extends EntityState<MessageId> {
  // additional entities state properties
  message: MessageId | any;
  show: boolean;
  loading: boolean;
  error: any;
}

export const adapter: EntityAdapter<MessageId> =
  createEntityAdapter<MessageId>();

export const initialState: messageState = adapter.getInitialState({
  // additional entity state properties
  message: undefined,
  show: false,
  loading: false,
  error: undefined,
});

export const reducer = createReducer(
  initialState,

  // LOAD ALL MESSAGES
  on(MessageActions.loadMessages, (state, action) => {
    return {
      ...state,
      message: undefined,
    };
  }),

  on(MessageActions.loadMessagesSuccess, (state, action) =>
    adapter.setAll(action.messages, state)
  ),

  on(MessageActions.loadMessagesFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  // LOAD MY MESSAGES
  on(MessageActions.loadMyMessages, (state, action) => {
    return {
      ...state,
      message: undefined,
    };
  }),

  on(MessageActions.loadMyMessagesSuccess, (state, action) =>
    adapter.setAll(action.messages, state)
  ),

  on(MessageActions.loadMyMessagesFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  // LOAD RECEIVE MESSAGES
  on(MessageActions.loadReceiveMessages, (state, action) => {
    return {
      ...state,
      message: undefined,
    };
  }),

  on(MessageActions.loadReceiveMessagesSuccess, (state, action) =>
    adapter.setAll(action.messages, state)
  ),

  on(MessageActions.loadReceiveMessagesFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  // on(MessageActions.addMessageSuccess, (state, action) =>
  //   adapter.addOne(action.message, state)
  // ),

  on(MessageActions.addMessageFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  on(MessageActions.loadMessageSuccess, (state, action) => {
    return {
      ...state,
      message: action.message,
    };
  }),

  on(MessageActions.loadMessageClear, (state, action) => {
    return {
      ...state,
      message: undefined,
    };
  }),

  on(MessageActions.showMessage, (state, action) => {
    return {
      ...state,
      show: true,
    };
  }),

  on(MessageActions.hideMessage, (state, action) => {
    return {
      ...state,
      message: undefined,
      show: false,
    };
  }),

  // on(MessageActions.updateMessage, (state, action) =>
  //   adapter.updateOne(action.message, state)
  // ),

  // on(MessageActions.updateMessageSuccess, (state, action) => {
  //   return {
  //     ...state,
  //     message: action.message,
  //   };
  // }),

  // on(MessageActions.deleteMessage, (state, action) =>
  //   adapter.removeOne(action.id, state)
  // ),

  on(MessageActions.deleteMessageSuccess, (state, action) => {
    return {
      ...state,
      message: undefined,
    };
  }),

  on(MessageActions.deleteMessages, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),

  on(MessageActions.deleteMessagesSuccess, (state, action) => {
    return {
      ...state,
      message: undefined,
    };
  }),

  on(MessageActions.deleteReceiveMessages, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),

  on(MessageActions.deleteReceiveMessagesSuccess, (state, action) => {
    return {
      ...state,
      message: undefined,
    };
  }),

  on(MessageActions.deleteMyMessages, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),

  on(MessageActions.deleteMyMessagesSuccess, (state, action) => {
    return {
      ...state,
      message: undefined,
    };
  }),

  on(MessageActions.loadingMessages, (state, action) => {
    return {
      ...state,
      loading: action.loader,
    };
  }),

  on(MessageActions.clearMessages, (state) => adapter.removeAll(state))
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
