import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';
import { UtilitiesState } from 'src/app/contents/components/utilities/store/utilities.reducer';
import * as fromUtilitiesSelector from 'src/app/contents/components/utilities/store/utilities.selectors';
import * as fromUtilitiesAction from 'src/app/contents/components/utilities/store/utilities.actions';
import { ThisReceiver } from '@angular/compiler';

@Component({
  selector: 'app-participant-diagnostic-form-famille',
  templateUrl: './participant-diagnostic-form-famille.component.html',
  styleUrls: ['./participant-diagnostic-form-famille.component.scss'],
})
export class ParticipantDiagnosticFormFamilleComponent
  implements OnInit, OnChanges, OnDestroy
{
  matrimonial$: Observable<any> = of(null);
  subscribe = new Subject();
  //VARIABLES
  checkboxDefinitionEnfantAcCharge: string =
    'Le participant a-t-il des enfants à charge ?';
  checkboxDefinitionEnfantScolarise: string =
    'Les enfants du participant sont-ils scolairés ?';
  checkboxDefinitionMoyenDeGarde: string =
    'Le participant a-t-il un moyen de garde pour les enfants ?';
  isFreinTitle: string = 'La situation familiale est-elle un frein identifié ?';

  //FORM INIT
  familleForm: FormGroup = this.fb.group({});

  //FORM FIELDS
  familiale: FormControl<any> = new FormControl({}, Validators.required);
  enfantsAcharges: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );
  enfantScolarise: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );
  enfantMoyenDeGarde: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );
  isFrein: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );

  @Input() famille$ = new BehaviorSubject<any>(null);
  @Output() add = new EventEmitter(false);

  constructor(
    private fb: FormBuilder,
    private utilitiesStore: Store<UtilitiesState>
  ) {}

  ngOnInit(): void {
    this.getMatrinonialReferentiel();
    this.loadMatrinonialReferentiel();
    this.form();
    this.onFormChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getMatrinonialReferentiel(): void {
    this.matrimonial$ = this.utilitiesStore.select(
      fromUtilitiesSelector.matrimonial
    );
  }
  loadMatrinonialReferentiel(): void {
    this.utilitiesStore.dispatch(
      fromUtilitiesAction.loadMatrimonialReferentiel()
    );
  }

  form(): void {
    this.formInit();
    this.formCreate();
    this.formSet();
  }

  formInit(): void {
    this.familiale = new FormControl({}, Validators.required);
    this.enfantsAcharges = new FormControl(false, Validators.required);
    this.enfantScolarise = new FormControl(false, Validators.required);
    this.enfantMoyenDeGarde = new FormControl(false, Validators.required);
    this.isFrein = new FormControl(false, Validators.required);
  }
  formCreate(): void {
    this.familleForm = this.fb.group({
      familiale: this.familiale,
      enfantsAcharges: this.enfantsAcharges,
      enfantScolarise: this.enfantScolarise,
      enfantMoyenDeGarde: this.enfantMoyenDeGarde,
      isFrein: this.isFrein,
    });
  }
  formSet(): void {
    this.famille$.pipe(takeUntil(this.subscribe)).subscribe((values) => {
      if (!values) return;
      this.familleForm.patchValue({ ...values });
    });
  }

  //COMPARE SELECT LIST UPDATE
  comparePatrimonial(obj1: any, obj2: any) {
    return obj1 && obj2 && obj1 === obj2;
  }

  checkboxChanges(event: boolean, type: string): void {
    switch (type) {
      case 'enfantsAcharges':
        this.familleForm.patchValue({
          enfantsAcharges: event,
        });

        break;
      case 'enfantScolarise':
        this.familleForm.patchValue({
          enfantScolarise: event,
        });

        break;
      case 'enfantMoyenDeGarde':
        this.familleForm.patchValue({
          enfantMoyenDeGarde: event,
        });

        break;
      default:
        break;
    }
  }

  onFormChanges(): void {
    this.familleForm.valueChanges
      .pipe(takeUntil(this.subscribe))
      .subscribe((formValue) => {
        this.add.emit(formValue);
      });
  }
}
