<app-item-tabset
  [tabs]="[
    {
      name: 'Par intervenants',
      template: null
    },
    {
      name: 'Par missions',
      template: null
    },
    {
      name: 'Sur objectif',
      template: null
    }
  ]"
></app-item-tabset>

<h1>Intervenants</h1>
<nz-row nzJustify="center" nzAlign="middle" nzGutter="2">
  <nz-col nzSpan="12"> </nz-col>
</nz-row>
<nz-space></nz-space>

<nz-row nzJustify="center" nzAlign="middle" nzGutter="2">
  <nz-col nzSpan="12"> </nz-col>
</nz-row>
<nz-space></nz-space>

<nz-row nzJustify="center" nzAlign="middle" nzGutter="2">
  <nz-col nzSpan="12"> </nz-col>
</nz-row>

<ng-template #noData>
  <app-no-result
    [icon]="'query_stats'"
    [isButton]="false"
    [title]="''"
  ></app-no-result>
</ng-template>
