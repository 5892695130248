import { Component, Input, OnInit } from '@angular/core';
import { ItemEvent } from '../item-event/itemEvent.model';

@Component({
  selector: 'app-suivi-type',
  templateUrl: './suivi-type.component.html',
  styleUrls: ['./suivi-type.component.scss'],
})
export class SuiviTypeComponent implements OnInit {
  @Input() suivi: ItemEvent | any;
  constructor() {}

  ngOnInit(): void {}
}
