import { EntiteId } from './../../store/entite.model';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-entite-identity',
  templateUrl: './entite-identity.component.html',
  styleUrls: ['./entite-identity.component.scss'],
})
export class EntiteIdentityComponent implements OnInit {
  @Input() entite: EntiteId | any;
  @Input() statistiques: any;
  @Output() select = new EventEmitter<any>(false);

  constructor() {}

  ngOnInit(): void {}

  onUpdate(): void {}
  onSelect(event: string): void {
    this.select.emit(event);
  }
  onDelete(entite: EntiteId): void {}
  onCancel(): void {}
}
