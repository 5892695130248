import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { EntiteSelectState } from '../store/entite-select.reducer';
import * as fromEntiteSelectAction from '../store/entite-select.actions';
import * as fromEntiteSelectSelector from '../store/entite-select.selectors';
import { EntiteSMALLid } from 'src/app/components/entite/store/entite.model';
import { of, Observable, BehaviorSubject, Subject } from 'rxjs';

@Component({
  selector: 'app-entite-select',
  template: `
    <nz-select
      nzShowSearch
      nzBorderless
      nzServerSearch
      [nzPlaceHolder]="placeholder"
      [(ngModel)]="selectedValue"
      [nzShowArrow]="false"
      (nzOnSearch)="search.next($event)"
      (ngModelChange)="onSelect($event)"
      [nzNotFoundContent]="entiteNoDataContent"
      [nzDropdownStyle]="{ heigth: '600px' }"
      [nzOptionHeightPx]="110"
      nzAllowClear
    >
      <nz-option
        *ngFor="let o of entites$ | async"
        [nzLabel]="o?.etablissement?.denomination"
        [nzValue]="o"
        nzCustomContent
      >
        <nz-card>
          <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
            <nz-col nzSpan="2">
              <mat-icon>domain</mat-icon>
            </nz-col>
            <nz-col nzSpan="22">
              <strong>{{
                o?.etablissement?.denomination | uppercase | carLimitation: 60
              }}</strong>
              <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
                <nz-col nzSpan="16">
                  <small>{{
                    o?.etablissement?.activite_principale_libelle?.intitule_naf
                      | carLimitation: 60
                  }}</small>
                </nz-col>
                <nz-col nzSpan="4">
                  <small>
                    {{ o?.etablissement?.siren }}
                  </small>
                </nz-col>
                <nz-col nzSpan="4">
                  <nz-tag nzColor="success">
                    {{
                      o?.etablissement?.economie_sociale_solidaire === 'O'
                        ? 'ESS'
                        : ''
                    }}
                  </nz-tag>
                </nz-col>
              </nz-row>
            </nz-col>
          </nz-row>
        </nz-card>
      </nz-option>
    </nz-select>

    <ng-template #entiteNoDataContent>
      <app-no-result
        [icon]="'domaine'"
        [description]="'Aucune entité trouvée'"
      ></app-no-result>
    </ng-template>
  `,
  styles: [
    `
      nz-card {
        border-radius: 12px;
      }
      nz-select {
        width: 100%;
        background-color: #f5f5f5;
        border-radius: 12px;
      }
      nz-avatar {
        background-color: #d6e4ff;
      }

      nz-tag {
        border-radius: 12px;
        width: 100%;
      }

      mat-icon,
      small {
        color: gray;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntiteSelectComponent implements OnInit, OnChanges, OnDestroy {
  entites$: Observable<EntiteSMALLid[] | any> = of([]);
  subscribe = new Subject();
  search = new BehaviorSubject<string>('');
  selectedValue: any;
  @Input() placeholder: string = "Rechercher l'entité";

  @Output() select = new EventEmitter<EntiteSMALLid | any>(false);
  @Output() cancel = new EventEmitter<boolean>(false);
  constructor(private entiteSelectStore: Store<EntiteSelectState>) {}

  ngOnInit(): void {
    this.getEntiteSelect();
    this.loadEntitesSelect();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getEntiteSelect(): void {
    this.entites$ = this.entiteSelectStore.select(
      fromEntiteSelectSelector.entitesSelect
    );
  }

  loadEntitesSelect(): void {
    this.search
      .pipe(
        distinctUntilChanged(),
        debounceTime(200),
        takeUntil(this.subscribe)
      )
      .subscribe((filter) => {
        if (!filter) return;
        const filterType: string = 'raisonSociale';
        this.entiteSelectStore.dispatch(
          fromEntiteSelectAction.loadEntiteSelects({ filter, filterType })
        );
      });
  }

  onSelect(entite: EntiteSMALLid): void {
    this.select.emit(entite);
  }
}
