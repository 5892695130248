<app-item-tabset
  *ngIf="missions$ | async | size; else noData"
  [tabs]="[
    {
      name: 'Par missions',
      template: statisticsByMissions
    },
    {
      name: 'Par heures',
      template: statisticsByMissionsHours
    },
    {
      name: 'Par mois/années',
      template: statisticsByMissionsTime
    },

    {
      name: 'Sur objectif',
      template: statisticsByMissionsHoursObjectif
    },
    {
      name: 'Par employeurs',
      template: statisticsByMissionsByEmployeur
    },
    {
      name: 'Par prescripteurs',
      template: statisticsByMissionsByPrescripteurs
    },
    {
      name: 'Par territoire',
      template: statisticsByMissionsByTerritoires
    }
  ]"
></app-item-tabset>

<ng-template #statisticsByMissions>
  <div class="Operation_Dashboard_Container">
    <nz-row nzJustify="center" nzAlign="middle" nzGutter="2">
      <nz-col nzSpan="12">
        <app-charts
          [source]="missionsLibelle$"
          [icon]="'query_stats'"
          [isBorderless]="false"
          [isTitle]="true"
        ></app-charts>
      </nz-col>
      <nz-col nzSpan="12">
        <app-charts
          [source]="missionsDomaine$"
          [icon]="'query_stats'"
          [isBorderless]="false"
          [isTitle]="true"
        ></app-charts>
      </nz-col>
    </nz-row>
    <nz-space></nz-space>

    <nz-row nzJustify="center" nzAlign="middle" nzGutter="2">
      <nz-col nzSpan="12">
        <app-charts
          [source]="missionsGrandDomaine$"
          [icon]="'query_stats'"
          [isBorderless]="false"
          [isTitle]="true"
        ></app-charts>
      </nz-col>
      <nz-col nzSpan="12">
        <app-charts
          [source]="missionsQualification$"
          [icon]="'query_stats'"
          [isBorderless]="false"
          [isTitle]="true"
        ></app-charts>
      </nz-col>
    </nz-row>
    <nz-space></nz-space>

    <nz-row nzJustify="center" nzAlign="middle" nzGutter="2">
      <nz-col nzSpan="12">
        <app-charts
          [source]="missionsFapPcs$"
          [icon]="'query_stats'"
          [isBorderless]="false"
          [isTitle]="true"
        ></app-charts>
      </nz-col>
      <nz-col nzSpan="12">
        <app-charts
          [source]="missionsExperience$"
          [icon]="'query_stats'"
          [isBorderless]="false"
          [isTitle]="true"
        ></app-charts>
      </nz-col>
    </nz-row>
    <nz-space></nz-space>

    <nz-row nzJustify="center" nzAlign="middle" nzGutter="2">
      <nz-col nzSpan="12">
        <app-charts
          [source]="missionsTypeContrat$"
          [icon]="'query_stats'"
          [isBorderless]="false"
          [isTitle]="true"
        ></app-charts>
      </nz-col>
      <nz-col nzSpan="12">
        <app-charts
          [source]="missionsNatureContrat$"
          [icon]="'query_stats'"
          [isBorderless]="false"
          [isTitle]="true"
        ></app-charts>
      </nz-col>
    </nz-row>
  </div>
</ng-template>
<ng-template #statisticsByMissionsHours>
  <div class="Operation_Dashboard_Container">
    <nz-row nzJustify="center" nzAlign="middle" nzGutter="2">
      <nz-col nzSpan="24">
        <app-charts
          [source]="missionsLibellesByHours$"
          [icon]="'bar_chart'"
          [isBorderless]="false"
          [isTitle]="true"
        ></app-charts>
      </nz-col>
    </nz-row>
    <nz-space></nz-space>

    <nz-row nzJustify="center" nzAlign="middle" nzGutter="2">
      <nz-col nzSpan="24">
        <app-charts
          [source]="missionsGrandsDomainesByHours$"
          [icon]="'bar_chart'"
          [isBorderless]="false"
          [isTitle]="true"
        ></app-charts>
      </nz-col>
    </nz-row>
    <nz-space></nz-space>

    <nz-row nzJustify="center" nzAlign="middle" nzGutter="2">
      <nz-col nzSpan="24">
        <app-charts
          [source]="missionsDomainesProfessionnelsByHours$"
          [icon]="'bar_chart'"
          [isBorderless]="false"
          [isTitle]="true"
        ></app-charts>
      </nz-col>
    </nz-row>
    <nz-space></nz-space>
    <nz-row nzJustify="center" nzAlign="middle" nzGutter="2">
      <nz-col nzSpan="24">
        <app-charts
          [source]="missionsQualificationsByHours$"
          [icon]="'bar_chart'"
          [isBorderless]="false"
          [isTitle]="true"
        ></app-charts>
      </nz-col>
    </nz-row>
    <nz-space></nz-space>
    <nz-row nzJustify="center" nzAlign="middle" nzGutter="2">
      <nz-col nzSpan="24">
        <app-charts
          [source]="missionsFapPcsByHours$"
          [icon]="'bar_chart'"
          [isBorderless]="false"
          [isTitle]="true"
        ></app-charts>
      </nz-col>
    </nz-row>
    <nz-space></nz-space>
    <nz-row nzJustify="center" nzAlign="middle" nzGutter="2">
      <nz-col nzSpan="24">
        <app-charts
          [source]="missionsTypesDeContratsByHours$"
          [icon]="'bar_chart'"
          [isBorderless]="false"
          [isTitle]="true"
        ></app-charts>
      </nz-col>
    </nz-row>
    <nz-space></nz-space>
    <nz-row nzJustify="center" nzAlign="middle" nzGutter="2">
      <nz-col nzSpan="24">
        <app-charts
          [source]="missionsNaturesDeContratsByHours$"
          [icon]="'bar_chart'"
          [isBorderless]="false"
          [isTitle]="true"
        ></app-charts>
      </nz-col>
    </nz-row>
    <nz-space></nz-space>
    <nz-row nzJustify="center" nzAlign="middle" nzGutter="2">
      <nz-col nzSpan="24">
        <app-charts
          [source]="missionsExperiencesByHours$"
          [icon]="'bar_chart'"
          [isBorderless]="false"
          [isTitle]="true"
        ></app-charts>
      </nz-col>
    </nz-row>
  </div>
</ng-template>
<ng-template #statisticsByMissionsTime>
  <div class="Operation_Dashboard_Container">
    <nz-row nzJustify="center" nzAlign="middle" nzGutter="2">
      <nz-col nzSpan="24">
        <app-charts
          [source]="missionsByMonths$"
          [icon]="'bar_chart'"
          [isBorderless]="false"
          [isTitle]="true"
        ></app-charts>
      </nz-col>
    </nz-row>
    <nz-space></nz-space>
    <nz-row nzJustify="center" nzAlign="middle" nzGutter="2">
      <nz-col nzSpan="24">
        <app-charts
          [source]="missionsByYears$"
          [icon]="'bar_chart'"
          [isBorderless]="false"
          [isTitle]="true"
        ></app-charts>
      </nz-col>
    </nz-row>
  </div>
</ng-template>
<ng-template #statisticsByMissionsHoursObjectif>
  <div class="Operation_Dashboard_Container">
    <nz-row nzJustify="center" nzAlign="middle" nzGutter="2">
      <nz-col nzSpan="24">
        <app-charts
          [source]="missionsLibelleByPercent$"
          [icon]="'bar_chart'"
          [isBorderless]="false"
          [isTitle]="true"
        ></app-charts>
      </nz-col>
    </nz-row>
    <nz-space></nz-space>

    <nz-row nzJustify="center" nzAlign="middle" nzGutter="2">
      <nz-col nzSpan="24">
        <app-charts
          [source]="missionsGrandDomaineByPercent$"
          [icon]="'bar_chart'"
          [isBorderless]="false"
          [isTitle]="true"
        ></app-charts>
      </nz-col>
    </nz-row>
    <nz-space></nz-space>

    <nz-row nzJustify="center" nzAlign="middle" nzGutter="2">
      <nz-col nzSpan="24">
        <app-charts
          [source]="missionsDomaineByPercent$"
          [icon]="'bar_chart'"
          [isBorderless]="false"
          [isTitle]="true"
        ></app-charts>
      </nz-col>
    </nz-row>
    <nz-space></nz-space>
    <nz-row nzJustify="center" nzAlign="middle" nzGutter="2">
      <nz-col nzSpan="24">
        <app-charts
          [source]="missionsFapPcsByPercent$"
          [icon]="'bar_chart'"
          [isBorderless]="false"
          [isTitle]="true"
        ></app-charts>
      </nz-col>
    </nz-row>
    <nz-space></nz-space>
    <nz-row nzJustify="center" nzAlign="middle" nzGutter="2">
      <nz-col nzSpan="24">
        <app-charts
          [source]="missionsQualificationByPercent$"
          [icon]="'bar_chart'"
          [isBorderless]="false"
          [isTitle]="true"
        ></app-charts>
      </nz-col>
    </nz-row>
    <nz-space></nz-space>
    <nz-row nzJustify="center" nzAlign="middle" nzGutter="2">
      <nz-col nzSpan="24">
        <app-charts
          [source]="missionsTypeContratByPercent$"
          [icon]="'bar_chart'"
          [isBorderless]="false"
          [isTitle]="true"
        ></app-charts>
      </nz-col>
    </nz-row>
    <nz-space></nz-space>
    <nz-row nzJustify="center" nzAlign="middle" nzGutter="2">
      <nz-col nzSpan="24">
        <app-charts
          [source]="missionsNatureContratByPercent$"
          [icon]="'bar_chart'"
          [isBorderless]="false"
          [isTitle]="true"
        ></app-charts>
      </nz-col>
    </nz-row>
    <nz-space></nz-space>
    <nz-row nzJustify="center" nzAlign="middle" nzGutter="2">
      <nz-col nzSpan="24">
        <app-charts
          [source]="missionsExperienceByPercent$"
          [icon]="'bar_chart'"
          [isBorderless]="false"
          [isTitle]="true"
        ></app-charts>
      </nz-col>
    </nz-row>
  </div>
</ng-template>
<ng-template #statisticsByMissionsByEmployeur>
  <div class="Operation_Dashboard_Container"></div>
</ng-template>
<ng-template #statisticsByMissionsByPrescripteurs>
  <div class="Operation_Dashboard_Container"></div>
</ng-template>
<ng-template #statisticsByMissionsByTerritoires>
  <div class="Operation_Dashboard_Container"></div>
</ng-template>

<ng-template #noData>
  <app-no-result
    [icon]="'query_stats'"
    [isButton]="false"
    [title]="'Aucune mission'"
    [description]="'Aucune mission'"
  ></app-no-result>
</ng-template>
