import { format, fromUnixTime } from 'date-fns';
import { fr } from 'date-fns/locale';
import {
  Action,
  ActionSmallId,
  ActionSmall,
} from './../components/action/store/action.model';
import { Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  doc,
  docSnapshots,
  collectionChanges,
  addDoc,
  setDoc,
  deleteDoc,
  serverTimestamp,
  Timestamp,
  query,
  where,
  arrayUnion,
  arrayRemove,
  collectionSnapshots,
  getDocs,
  DocumentData,
  CollectionReference,
  DocumentSnapshot,
  DocumentReference,
} from '@angular/fire/firestore';
import { Observable, of, from, EMPTY } from 'rxjs';
import { switchMap, mergeMap, map, catchError } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { UserState } from '../components/user/store/user.reducer';
import * as fromUserSelector from '../components/user/store/user.selectors';
import { OperationState } from '../components/operation/store/operation.reducer';
import * as fromOperationSelector from '../components/operation/store/operation.selectors';
import { Apollo, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'any',
})
export class ActionService {
  GET_ACTION_TYPES = gql`
    query ActionType {
      actionType {
        etats
        roles
        types
      }
    }
  `;
  CREATE_ACTION = gql`
    mutation CreateAction($action: ActionInput!) {
      createAction(action: $action)
    }
  `;

  DELETE_ACTION = gql`
    mutation DeleteAction($operationId: ID!, $actionId: ID!) {
      deleteAction(operationId: $operationId, actionId: $actionId)
    }
  `;

  DELETE_ACTIONS = gql`
    mutation DeleteActions($id: ID!) {
      deleteActions(id: $id)
    }
  `;

  constructor(
    private db: Firestore,
    private userStore: Store<UserState>,
    private operationStore: Store<OperationState>,
    private apollo: Apollo
  ) {}

  getAll(operationId: string, moduleId?: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !operationId) of([]);
          const dbCollection: CollectionReference<DocumentData> = collection(
            this.db,
            `organisations/${user.organisation.id}/operations/${operationId}/actions`
          );

          return collectionSnapshots(dbCollection).pipe(
            map((snap: DocumentSnapshot<DocumentData>[]) => {
              return snap.map((doc: DocumentData) => {
                const data = doc.data() as any;
                const id = doc.id;
                return { id, ...data };
              });
            }),
            catchError((error) => error)
          );
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  getAllByOperation(): Observable<any> {
    try {
      return this.operationStore.select(fromOperationSelector.operation).pipe(
        switchMap((operation) => {
          if (!operation || !operation?.id) {
            return of([]);
          } else {
            const dbCollection: CollectionReference<DocumentData> = collection(
              this.db,
              `organisations/${operation.organisation.id}/operations/${operation.id}/actions`
            );

            return collectionSnapshots(dbCollection).pipe(
              map((snap: DocumentSnapshot<DocumentData>[]) => {
                return snap.map((doc: DocumentData) => {
                  const data = doc.data() as any;
                  const id = doc.id;
                  return { id, ...data };
                });
              }),
              catchError((error) => error)
            );
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  getOne(operationId: string, id: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) {
            return of(null);
          } else {
            const dbCollection: CollectionReference<DocumentData> = collection(
              this.db,
              `organisations/${user.organisation.id}/operations/${operationId}/actions`
            );

            const documentRef: DocumentReference<DocumentData> = doc(
              dbCollection,
              id
            );

            return docSnapshots(documentRef).pipe(
              map((snap: DocumentSnapshot<DocumentData>) => {
                const data = snap.data();
                const id = snap.id;
                return { id, ...data };
              }),
              catchError((error) => EMPTY)
            );
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }
  getOneDetails(operationId: string, id: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) {
            return of(null);
          } else {
            const dbCollection: CollectionReference<DocumentData> = collection(
              this.db,
              `organisations/${user.organisation.id}/operations/${operationId}/actions`
            );

            const documentRef: DocumentReference<DocumentData> = doc(
              dbCollection,
              id
            );

            return docSnapshots(documentRef).pipe(
              map((snap: DocumentSnapshot<DocumentData>) => {
                const data = snap.data();
                const id = snap.id;
                return { id, ...data };
              }),
              catchError((error) => EMPTY)
            );
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  getActionTypes(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_ACTION_TYPES,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  createAction(action: any): Observable<any> {
    try {
      if (!action) EMPTY;
      return this.apollo.mutate({
        mutation: this.CREATE_ACTION,
        variables: {
          action: action,
        },
      });
    } catch (error) {
      return of(error);
    }
  }
  updateAction(action: any): Observable<any> {
    try {
      if (!action) EMPTY;

      //console.log(action);
      return EMPTY;
      // return this.apollo.mutate({
      //   mutation: this.UPDATE_ACTION,
      //   variables: {
      //     action: action,
      //   },
      // });
    } catch (error) {
      return of(error);
    }
  }

  deleteAction(operationId: string, actionId: string): Observable<any> {
    try {
      if (!operationId || !actionId) EMPTY;

      return this.apollo.mutate({
        mutation: this.DELETE_ACTION,
        variables: {
          operationId: operationId,
          actionId: actionId,
        },
      });
    } catch (error) {
      return of(error);
    }
  }

  deleteActions(id: string): Observable<any> {
    try {
      if (!id) EMPTY;

      return this.apollo.mutate({
        mutation: this.DELETE_ACTIONS,
        variables: {
          id: id,
        },
      });
    } catch (error) {
      return of(error);
    }
  }

  //TRANSFORMATION
  createMetadata(action: any): any {
    const { title } = action;
    const letterTitle = title ? this.capitalizeFirstLetter(title) : '';
    const arrayTitle = title ? this.createKeywords(title) : [];
    const arrEntities = action.entites
      ? action.entites.reduce((acc: any, cur: any) => {
          acc[cur.item] = true;
          return acc;
        }, {})
      : {};

    const startYear =
      action.dateStart && action.dateStart['seconds']
        ? format(fromUnixTime(action.dateStart['seconds']), 'yyyy', {
            locale: fr,
          })
        : 'Non renseigné';

    const startMonth =
      action.dateStart && action.dateStart['seconds']
        ? format(fromUnixTime(action.dateStart['seconds']), 'MMMM', {
            locale: fr,
          })
        : 'Non renseigné';

    const endYear =
      action.dateEnd && action.dateEnd['seconds']
        ? format(fromUnixTime(action.dateEnd['seconds']), 'yyyy', {
            locale: fr,
          })
        : 'Non renseigné';

    const endMonth =
      action.dateEnd && action.dateEnd['seconds']
        ? format(fromUnixTime(action.dateEnd['seconds']), 'MMMM', {
            locale: fr,
          })
        : 'Non renseigné';

    const metadata = {
      letterTitle: letterTitle,
      arrayTitle: arrayTitle,
      arrayEntities: arrEntities,

      startYear: startYear,
      startMonth: startMonth,
      endYear: endYear,
      endMonth: endMonth,
    };

    return metadata;
  }

  createSmallVersion(action: any): any {
    if (action) {
      const small: ActionSmall = {
        type: action.type,
        title: action.title,
        access: action.access,
        searchKey: action?.searchKey,
        metadata: action.metadata,
        objectifs: action.objectifs,
        realisations: action.realisations ? action.realisations : null,
        allDay: action.allDay,
        cout: action.cout ? action.cout : null,
        progression: action?.progression ? action?.progression : 0,
        referents: action.referents,

        dateStart: action.dateStart,
        dateUpdate: action.dateUpdate,
        dateEnd: action.dateEnd,
        duration: action.duration,

        module: action.module,

        organisation: action.organisation,

        dependences: action?.dependences ? action?.dependences : null,
        phases: action.phases,
        etat: action.etat,
        definition: action.definition,
        repriodicite: action.realisations ? action.realisations : null,

        participantsId: action.participantsId,
        contactId: action.contactId,
        entiteId: action.entiteId,
      };

      return small;
    } else {
      return null;
    }
  }

  capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase();
  }

  createKeywords(name: any): any {
    if (name) {
      const arrName: any = [];
      let curName = '';
      name.split('').forEach((letter: any) => {
        curName += letter;
        arrName.push(curName);
      });
      return arrName;
    }
  }
}
