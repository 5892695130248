import { createAction, props } from '@ngrx/store';
import { OperationGantt } from './operation-gantt.model';

export const loadOperationGantt = createAction(
  '[OperationGantt/API] Load OperationGantt',
  props<{ operationId: string }>()
);
export const loadOperationGanttSuccess = createAction(
  '[OperationGantt/API] Load OperationGantt Success',
  props<{ gantt: any }>()
);
export const loadOperationGanttFailure = createAction(
  '[OperationGantt/API] Load OperationGantt Failure',
  props<{ error: any }>()
);
