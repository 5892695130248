import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Observable } from 'rxjs';
import {
  Auth,
  multiFactor,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  User,
} from '@angular/fire/auth';

@Component({
  selector: 'app-login-sms-validator',
  templateUrl: './login-sms-validator.component.html',
  styleUrls: ['./login-sms-validator.component.scss'],
})
export class LoginSmsValidatorComponent implements OnInit, AfterViewInit {
  loading$: Observable<boolean> | any;
  icon: string = 'sms';
  titleCard: string = 'Authentification à deux facteurs';
  messageCard: string =
    'Votre compte est protegé avec une authentification à deux facteurs. Nous vous avons transmis un message. Veuillez inscrire le numéro unique communiqué.';

  @ViewChild('faCaptcha', { static: true }) faCaptcha: ElementRef =
    new ElementRef<any>('');

  recaptchaVerifier: RecaptchaVerifier | any;

  constructor(
    private auth: Auth,
    private phoneAuthProvider: PhoneAuthProvider
  ) {}

  ngOnInit(): void {}
  ngAfterViewInit(): void {}
}
