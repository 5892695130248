import { QueryActionsService } from './../../../service/query-actions.service';
import { ActionSmallId, ActionId } from './action.model';
import { ActionService } from './../../../service/action.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, map, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as fromActionAction from './action.actions';
import {
  NzNotificationPlacement,
  NzNotificationService,
} from 'ng-zorro-antd/notification';

const NOTIFICATION_PLACEMENT: NzNotificationPlacement = 'topRight';

@Injectable()
export class ActionEffects {
  loadActions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActionAction.loadActions),
      mergeMap((action) =>
        this.actionService.getAll(action.operationId).pipe(
          map((actions: ActionSmallId[]) =>
            fromActionAction.loadActionsSuccess({
              actions,
            })
          ),
          catchError((error) =>
            of(fromActionAction.loadActionsFailure({ error }))
          )
        )
      )
    )
  );

  loadActionTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActionAction.loadActionTypes),
      mergeMap((action) =>
        this.actionService.getActionTypes().pipe(
          map((respons: any) => {
            const types = respons?.data?.actionType;
            return fromActionAction.loadActionTypesSuccess({
              types,
            });
          }),
          catchError((error) => {
            this.onErrorNotification(error, 'LOAD_ACTION_TYPES');
            return of(fromActionAction.loadActionTypesFailure({ error }));
          })
        )
      )
    )
  );

  //QUERY
  queryActions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActionAction.queryActions),
      mergeMap((action) =>
        this.queryActions.queryActions(action.filter).pipe(
          map((actions: ActionSmallId[]) =>
            fromActionAction.queryActionsSuccess({
              actions,
            })
          ),
          catchError((error) =>
            of(fromActionAction.queryActionsFailure({ error }))
          )
        )
      )
    )
  );

  //QUERY BY MODULE
  queryActionsByModule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActionAction.queryActionsByModule),
      mergeMap((action) =>
        this.queryActions.getByModule(action.operationId, action.module).pipe(
          map((actions: ActionSmallId[]) =>
            fromActionAction.queryActionsByModuleSuccess({
              actions,
            })
          ),
          catchError((error) =>
            of(fromActionAction.queryActionsByModuleFailure({ error }))
          )
        )
      )
    )
  );

  //GET ONE

  loadAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActionAction.loadAction),
      mergeMap((action) =>
        this.actionService
          .getOneDetails(action.operationId, action.actionId)
          .pipe(
            map((action: ActionId) =>
              fromActionAction.loadActionSuccess({
                action,
              })
            ),
            catchError((error) =>
              of(fromActionAction.loadActionFailure({ error }))
            )
          )
      )
    )
  );

  //ADD
  addAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActionAction.addAction),
      tap((action) => {
        this.notification.info('Nouveau', `Création en cours...`, {
          nzDuration: 6000,
          nzAnimate: true,
          nzPlacement: NOTIFICATION_PLACEMENT,
          nzKey: 'CREATE_ACTION',
        });
      }),
      mergeMap((action) =>
        this.actionService.createAction(action.action).pipe(
          map((respons: any) => {
            const success: string = respons?.data?.createAction;
            this.notification.success('Nouveau', success, {
              nzDuration: 8000,
              nzAnimate: true,
              nzPlacement: NOTIFICATION_PLACEMENT,
              nzKey: 'CREATE_ACTION',
            });
            return fromActionAction.addActionSuccess({
              success,
            });
          }),
          catchError((error) => {
            this.onErrorNotification(error, 'CREATE_ACTION');
            return of(fromActionAction.addActionFailure({ error }));
          })
        )
      )
    )
  );

  //UPDATE
  updateAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActionAction.updateAction),
      tap((action) => {
        this.notification.info(
          'Modification',
          `Modification ${action?.action.changes.title} en cours...`,
          {
            nzDuration: 6000,
            nzAnimate: true,
            nzPlacement: NOTIFICATION_PLACEMENT,
            nzKey: 'UPDATE_ACTION',
          }
        );
      }),
      mergeMap((action) =>
        this.actionService.updateAction(action.action).pipe(
          map((respons: any) => {
            const success: string = respons?.data?.updateAction;
            this.notification.success('Nouveau', success, {
              nzDuration: 8000,
              nzAnimate: true,
              nzPlacement: NOTIFICATION_PLACEMENT,
              nzKey: 'UPDATE_ACTION',
            });
            return fromActionAction.updateActionSuccess({
              success,
            });
          }),
          catchError((error) => {
            this.onErrorNotification(error, 'UPDATE_ACTION');
            return of(fromActionAction.updateActionFailure({ error }));
          })
        )
      )
    )
  );

  //DELETE
  deleteAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActionAction.deleteAction),
      tap((action) => {
        this.notification.info('Suppression', `Suppression en cours...`, {
          nzDuration: 6000,
          nzAnimate: true,
          nzPlacement: NOTIFICATION_PLACEMENT,
          nzKey: 'DELETE_ACTION',
        });
      }),
      mergeMap((action) =>
        this.actionService.deleteAction(action.operationId, action.id).pipe(
          map((respons: any) => {
            const success: string = respons?.data?.deleteAction;
            this.notification.success('Suppression', success, {
              nzDuration: 8000,
              nzAnimate: true,
              nzPlacement: NOTIFICATION_PLACEMENT,
              nzKey: 'DELETE_ACTION',
            });
            return fromActionAction.deleteActionSuccess({
              success,
            });
          }),
          catchError((error) => {
            this.onErrorNotification(error, 'DELETE_ACTION');
            return of(fromActionAction.deleteActionFailure({ error }));
          })
        )
      )
    )
  );

  //DELETE ALL

  deleteActions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActionAction.deleteActions),
      tap((action) => {
        this.notification.info('Suppression', `Suppression en cours...`, {
          nzDuration: 6000,
          nzAnimate: true,
          nzPlacement: NOTIFICATION_PLACEMENT,
          nzKey: 'DELETE_ACTIONS',
        });
      }),
      mergeMap((action) =>
        this.actionService.deleteActions(action.operationId).pipe(
          map((respons: any) => {
            const success: string = respons?.data?.deleteActions;
            this.notification.success('Suppression', success, {
              nzDuration: 8000,
              nzAnimate: true,
              nzPlacement: NOTIFICATION_PLACEMENT,
              nzKey: 'DELETE_ACTIONS',
            });
            return fromActionAction.deleteActionSuccess({
              success,
            });
          }),
          catchError((error) => {
            this.onErrorNotification(error, 'DELETE_ACTIONS');
            return of(fromActionAction.deleteActionFailure({ error }));
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private actionService: ActionService,
    private queryActions: QueryActionsService,
    private notification: NzNotificationService
  ) {}

  onErrorNotification(error: any, key: string): void {
    this.notification.error('Error', error.message, {
      nzDuration: 10000,
      nzAnimate: true,
      nzPlacement: NOTIFICATION_PLACEMENT,
      nzKey: key ? key : '',
    });
  }
}
