import { OperationId } from './../../store/operation.model';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Observable, of, Subject } from 'rxjs';
import {
  ItemEventId,
  ItemEvent,
} from '../../../../features/suivi/components/item-event/itemEvent.model';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Update } from '@ngrx/entity';
import { OperationState } from './../../store/operation.reducer';
import * as fromOperationAction from './../../store/operation.actions';
import * as fromOperationSelector from './../../store/operation.selectors';

@Component({
  selector: 'app-operation-suivis',
  templateUrl: './operation-suivis.component.html',
  styleUrls: ['./operation-suivis.component.scss'],
})
export class OperationSuivisComponent implements OnInit, OnDestroy {
  subscribe = new Subject();
  operation$: Observable<OperationId | any> = of([]);

  suivis$: Observable<ItemEventId[] | any> = of([]);

  constructor(private operationStore: Store<OperationState>) {}

  ngOnInit(): void {
    this.getOperation();
    this.getSuivis();
    this.loadSuivis();
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getSuivis(): void {
    this.suivis$ = this.operationStore.select(fromOperationSelector.suivis);
  }

  getOperation(): void {
    this.operation$ = this.operationStore.select(
      fromOperationSelector.operation
    );
  }
  loadSuivis(): void {
    this.operation$
      .pipe(takeUntil(this.subscribe))
      .subscribe((operation: OperationId) => {
        if (!operation) {
          return;
        } else {
          this.operationStore.dispatch(
            fromOperationAction.loadSuivis({ operationId: operation.id })
          );
        }
      });
  }

  onAdd(event: ItemEvent, operation: OperationId): void {
    const { id } = operation;
    this.operationStore.dispatch(
      fromOperationAction.addSuivi({ operationId: id, suivi: event })
    );
  }
  onUpdate(event: ItemEventId, operation: OperationId): void {
    const { id } = operation;
    const nextSuivi: Update<ItemEventId> = {
      id: event.id,
      changes: { ...event },
    };
    this.operationStore.dispatch(
      fromOperationAction.updateSuivi({
        suivi: nextSuivi,
        operationId: id,
      })
    );
  }
  onDelete(event: ItemEventId, operation: OperationId): void {
    const { id } = operation;
    this.operationStore.dispatch(
      fromOperationAction.deleteSuivi({
        operationId: id,
        suiviId: event.id,
      })
    );
  }
}
