import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { GeoAdresse, GeoAdresseAPI } from './geo-adresse.model';
import * as GeoAdresseActions from './geo-adresse.actions';

export const geoAdressesFeatureKey = 'geoAdresses';

export interface GeoAdresseState extends EntityState<any> {
  adresses: any;
  adresse: any;
  complement: string;
  loading: boolean;
  qpv: any;
  error: any;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>();

export const initialState: GeoAdresseState = adapter.getInitialState({
  adresses: undefined,
  adresse: undefined,
  complement: '',
  loading: false,
  qpv: undefined,
  error: undefined,
});

export const reducer = createReducer(
  initialState,
  on(GeoAdresseActions.loadGeoAdresses, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),

  // on(GeoAdresseActions.loadGeoAdressesSuccess, (state, action) =>
  //   adapter.setAll(action.adresses, state)
  // ),

  on(GeoAdresseActions.loadGeoAdressesSuccess, (state, action) => {
    return {
      ...state,
      adresses: action.adresses,
      loading: false,
    };
  }),

  on(GeoAdresseActions.loadGeoAdressesFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
      loading: false,
    };
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
