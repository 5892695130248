<nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
  <nz-col nzSpan="3"></nz-col>

  <nz-col nzSpan="1">
    <nz-badge
      [nzCount]="entites$ | async | size"
      *ngIf="filtersActivated"
      nzStandalone="true"
      [nzOffset]="[-20, 0]"
    >
    </nz-badge>
  </nz-col>
  <nz-col nzSpan="16">
    <app-search-bar
      [filter]="true"
      [cleaner]="cleaner"
      (clear)="onClear()"
      [placeholder]="'Recherche par raison sociale'"
      (searching)="filtersByRaisonSociale($event)"
    ></app-search-bar>
  </nz-col>
  <nz-col nzSpan="2">
    <!-- <button
      nz-button
      nzShape="circle"
      nz-popover
      [nzPopoverContent]="contentFilterTemplate"
      nzPopoverPlacement="bottomRight"
      nzPopoverTrigger="click"
      nzType="text"
    >
      <i nz-icon nzType="filter"></i>
    </button> -->
  </nz-col>

  <nz-col nzSpan="2">
    <button nz-button nzShape="circle" *ngIf="filtersActivated">
      <i nz-icon nzType="download"></i>
    </button>
  </nz-col>
</nz-row>

<ng-template #contentFilterTemplate>
  <app-entite-filters-content
    (reload)="loadEntites()"
  ></app-entite-filters-content>
</ng-template>
