import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Favorie } from './favorie.model';

export const loadEntites = createAction(
  '[Favorie/COMPONENT] Load Favories Entites'
);

export const loadEntitesSuccess = createAction(
  '[Favorie/API] Load Favories Entites Success',
  props<{ entites: any }>()
);

export const loadEntitesFailure = createAction(
  '[Favorie/API] Load Favories Entites Failure',
  props<{ error: any }>()
);

export const loadParticipants = createAction(
  '[Favorie/COMPONENT] Load Favories Participants'
);

export const loadParticipantsSuccess = createAction(
  '[Favorie/API] Load Favories Participants Success',
  props<{ participants: any }>()
);

export const loadParticipantsFailure = createAction(
  '[Favorie/API] Load Favories Participants Failure',
  props<{ error: any }>()
);

export const addFavorie = createAction(
  '[Favorie/COMPONENT] Add Favorie',
  props<{ favorie: any }>()
);

export const addFavorieSuccess = createAction(
  '[Favorie/API] Add Favorie Success'
);
export const addFavorieFailure = createAction(
  '[Favorie/API] Add Favorie Failure',
  props<{ error: any }>()
);

export const deleteFavorie = createAction(
  '[Favorie/COMPONENT] Delete Favorie',
  props<{ id: string }>()
);

export const deleteFavorieSuccess = createAction(
  '[Favorie/API] Delete Favorie Success'
);
export const deleteFavorieFailure = createAction(
  '[Favorie/API] Delete Favorie Failure',
  props<{ error: any }>()
);

export const deleteFavories = createAction(
  '[Favorie/API] Delete Favories',
  props<{ ids: string[] }>()
);

export const clearFavories = createAction('[Favorie/API] Clear Favories');
