import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { OperationId } from '../../store/operation.model';
import { OperationState } from '../../store/operation.reducer';
import * as fromOperationSelector from '../../store/operation.selectors';
import * as fromOperationAction from '../../store/operation.actions';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-operation-details',
  templateUrl: './operation-details.component.html',
  styleUrls: ['./operation-details.component.scss'],
})
export class OperationDetailsComponent implements OnInit {
  operation$: Observable<any> = of(null);

  constructor(private operationStore: Store<OperationState>) {
    this.getOperation();
  }

  ngOnInit(): void {}

  getOperation(): void {
    this.operation$ = this.operationStore.select(
      fromOperationSelector.operation
    );
  }
}
