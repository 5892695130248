<div class="ficheDePosteItemDetailContainer">
  <nz-descriptions nzSize="small" nzBordered>
    <nz-descriptions-item nzSpan="2" nzTitle="Métier">
      <nz-space nzDirection="horizontal" nzSize="middle">
        <span *nzSpaceItem>{{ fiche?.mission?.metier.libelle }}</span>
        <nz-tag *nzSpaceItem
          >ROME : {{ fiche?.mission?.metier?.metier?.code }}</nz-tag
        >
      </nz-space>
    </nz-descriptions-item>
    <nz-descriptions-item nzSpan="1" nzTitle="Domaine">
      {{ fiche?.mission?.metier?.metier?.domaineProfessionnel?.libelle }}
    </nz-descriptions-item>
    <nz-descriptions-item nzSpan="3" nzTitle="Compétences">
      <nz-space nzDirection="horizontal" nzSize="large">
        <nz-tag *nzSpaceItem>Compétences de base</nz-tag>
        <nz-tag *nzSpaceItem>Compétences spécifiques</nz-tag>
        <nz-tag *nzSpaceItem>Compétences transversales</nz-tag>
        <nz-tag *nzSpaceItem>Environnement</nz-tag>
      </nz-space>
    </nz-descriptions-item>
    <nz-descriptions-item nzSpan="3" nzTitle="Définition">
      <div class="descriptionItemContainer">
        {{ fiche?.mission?.metier?.metier?.informations?.definition }}
      </div>
    </nz-descriptions-item>
    <nz-descriptions-item nzSpan="3" nzTitle="Accès">
      <div class="descriptionItemContainer">
        <nz-list>
          <nz-list-item
            *ngFor="
              let item of fiche?.mission?.metier?.metier?.informations
                ?.accesArray
            "
          >
            {{ item }}
          </nz-list-item>
        </nz-list>
      </div>
    </nz-descriptions-item>
  </nz-descriptions>
</div>
