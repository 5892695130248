import { OperationId } from './../../operation/store/operation.model';
import {
  ItemEventId,
  ItemEvent,
} from '../../../features/suivi/components/item-event/itemEvent.model';
import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Module, ModuleSmallId, ModuleId } from './module.model';

export const loading = createAction(
  '[Module/COMPONENT] Loading Module',
  props<{ loading: boolean }>()
);

export const loadModuleTypes = createAction(
  '[Module/COMPONENT] Load Module Types'
);
export const loadModuleTypesSuccess = createAction(
  '[Module/COMPONENT] Load Module Types Success',
  props<{ types: string[] }>()
);

export const loadModuleTypesFailure = createAction(
  '[Module/COMPONENT] Load Module Types Failure',
  props<{ error: any }>()
);

export const loadUtils = createAction('[Module/COMPONENT] Utils Module');
export const loadUtilsSuccess = createAction(
  '[Module/COMPONENT] Utils Module Success',
  props<{ utils: any }>()
);

export const loadUtilsFailure = createAction(
  '[Module/COMPONENT] Utils Module Failure',
  props<{ error: any }>()
);

export const loadFileProgress = createAction(
  '[Module/API] File upload progreess Module',
  props<{ progress: any }>()
);

export const clearModule = createAction('[Module/COMPONENT] clear Modules');

//LOAD MANY
export const loadModules = createAction(
  '[Module/COMPONENT] Load Modules',
  props<{ operationId: string }>()
);
export const loadModulesSuccess = createAction(
  '[Module/API] Load Modules Success',
  props<{ modules: ModuleSmallId[] }>()
);
export const loadModulesFailure = createAction(
  '[Module/API] Load Modules Failure',
  props<{ error: any }>()
);

//QUERY MANY
export const queryModules = createAction(
  '[Module/COMPONENT] Query Modules',
  props<{ filter: { operationId: string; event: string; type: string } }>()
);
export const queryModulesSuccess = createAction(
  '[Module/API] Query Modules Success',
  props<{ modules: ModuleSmallId[] }>()
);
export const queryModulesFailure = createAction(
  '[Module/API] Query Modules Failure',
  props<{ error: any }>()
);

//LOAD ONE
export const loadModule = createAction(
  '[Module/COMPONENT] Load Module',
  props<{ operationId: string; moduleId: string }>()
);
export const loadModuleSuccess = createAction(
  '[Module/API] Load Module Success',
  props<{ module: ModuleId }>()
);
export const loadModuleFailure = createAction(
  '[Module/API] Load Module Failure',
  props<{ error: any }>()
);

//ADD
export const addModule = createAction(
  '[Module/API] Add Module',
  props<{ operationId: string; module: Module }>()
);
export const addModuleSuccess = createAction(
  '[Module/API] Add Module Success',
  props<{ success: string }>()
);
export const addModuleFailure = createAction(
  '[Module/API] Add Module Failure',
  props<{ error: any }>()
);

//UPDATE ONE
export const updateModule = createAction(
  '[Module/API] Update Module',
  props<{ operationId: string; module: Update<ModuleId> }>()
);
export const updateModuleSuccess = createAction(
  '[Module/API] Update Module Success',
  props<{ success: string }>()
);
export const updateModuleFailure = createAction(
  '[Module/API] Update Module Failure',
  props<{ error: any }>()
);

//DELETE ONE
export const deleteModule = createAction(
  '[Module/API] Delete Module',
  props<{ operationId: string; id: string }>()
);
export const deleteModuleSuccess = createAction(
  '[Module/API] Delete Module Success',
  props<{ success: string }>()
);
export const deleteModuleFailure = createAction(
  '[Module/API] Delete Module',
  props<{ error: any }>()
);

//DELETE MANY
export const deleteModules = createAction(
  '[Module/API] Delete Modules',
  props<{ ids: string[]; operationId: string }>()
);
export const deleteModulesSuccess = createAction(
  '[Module/API] Delete Modules Success',
  props<{ success: string }>()
);

export const deleteModulesFailure = createAction(
  '[Module/API] Delete Modules Failure',
  props<{ error: any }>()
);

export const clearModules = createAction('[Module/API] Clear Modules');

//SUIVIS
//load suivis
export const loadSuivis = createAction('[Module/COMPONENT] Load Suivis Module');

export const loadSuivisSuccess = createAction(
  '[Module/API] Load Suivis Module Success',
  props<{ suivis: any }>()
);

export const loadSuivisFailure = createAction(
  '[Module/API] Load Suivis Module Failure',
  props<{ error: any }>()
);

//add suivi
export const addSuivi = createAction(
  '[Module/COMPONENT] Add Suivi Module',
  props<{ suivi: ItemEvent; moduleId: string; operationId: string }>()
);

export const addSuiviSuccess = createAction(
  '[Module/API] Add Suivi Module Success',
  props<{ suivi: ItemEventId }>()
);

export const addSuiviFailure = createAction(
  '[Module/API] Add Suivi Module Failure',
  props<{ error: any }>()
);

//update suivi
export const updateSuivi = createAction(
  '[Module/COMPONENT] Update Suivi Module',
  props<{ suivi: Update<ItemEventId>; moduleId: string; operationId: string }>()
);

export const updateSuiviSuccess = createAction(
  '[Module/API] Update Suivi Module Success',
  props<{ suivi: ItemEventId }>()
);

export const updateSuiviFailure = createAction(
  '[Module/API] Update Suivi Module Failure',
  props<{ error: any }>()
);
//delete suivi
export const deleteSuivi = createAction(
  '[Module/COMPONENT] Delete Suivi Module',
  props<{ suiviId: string; moduleId: string; operationId: string }>()
);

export const deleteSuiviSuccess = createAction(
  '[Module/API] Delete Suivi Module Success',
  props<{ success: string }>()
);

export const deleteSuiviFailure = createAction(
  '[Module/API] Delete Suivi Module Failure',
  props<{ error: any }>()
);

//DOCUMENTS
//load documents
export const loadDocuments = createAction(
  '[Module/COMPONENT] Load Documents Module'
);

export const loadDocumentsSuccess = createAction(
  '[Module/API] Load Documents Module Success',
  props<{ documents: any }>()
);

export const loadDocumentsFailure = createAction(
  '[Module/API] Load Documents Module Failure',
  props<{ error: any }>()
);

//add document
export const addDocuments = createAction(
  '[Module/COMPONENT] Add Documents Module',
  props<{ module: ModuleId; documents: any[] }>()
);

export const addDocumentsSuccess = createAction(
  '[Module/API] Add Documents Module Success',
  props<{ documents: any[] }>()
);

export const addDocumentsFailure = createAction(
  '[Module/API] Add Documents Module Failure',
  props<{ error: any }>()
);

//delete document
export const deleteDocument = createAction(
  '[Module/COMPONENT] Delete Document Module',
  props<{ module: ModuleId; document: any }>()
);

export const deleteDocumentSuccess = createAction(
  '[Module/API] Delete Document Module Success',
  props<{ success: string }>()
);

export const deleteDocumentFailure = createAction(
  '[Module/API] Delete Document Module Failure',
  props<{ error: any }>()
);

//MISSIONS
//load missions
export const loadMissions = createAction(
  '[Module/COMPONENT] Load Missions Module'
);

export const loadMissionsSuccess = createAction(
  '[Module/API] Load Missions Module Success',
  props<{ missions: any }>()
);

export const loadMissionsFailure = createAction(
  '[Module/API] Load Missions Module Failure',
  props<{ error: any }>()
);

//ACTIONS
//load actions
export const loadActions = createAction(
  '[Module/COMPONENT] Load Actions Module'
);

export const loadActionsSuccess = createAction(
  '[Module/API] Load Actions Module Success',
  props<{ actions: any }>()
);

export const loadActionsFailure = createAction(
  '[Module/API] Load Actions Module Failure',
  props<{ error: any }>()
);

//QUERY
export const queryModulesByOperation = createAction(
  '[Module/COMPONENT] QUERY Modules by operation'
);

export const queryModulesByOperationSuccess = createAction(
  '[Module/API] QUERY Modules by operatione Success',
  props<{ modules: any[] }>()
);

export const queryModulesByOperationFailure = createAction(
  '[Module/API] QUERY Modules by operation Failure',
  props<{ error: any }>()
);
