<div class="overflow-hidden bg-transparent">
  <ng-container *ngIf="operation$ | async as operation">
    <!-- 1/ Possibilité de merger plusieurs fiches de poste pour la diffusion à une
  entreprise -->

    <app-progress-loading *ngIf="!(fiches$ | async)"></app-progress-loading>

    <app-fiches-de-poste-list
      [fiches$]="fiches$"
      [isOperation]="false"
      [operationItem]="{
        id: operation.id,
        denomination: operation.denomination,
        description: operation.description?.definition,
        type: operation?.nature,
        financeurs: operation.financeurs || [],
        financeursIds: operation.financeursIds || [],
        lot: lot$ | async,
        marche: marche$ | async
      }"
      [loading]="loading$ | async"
      (add)="onAdd($event, operation.id)"
      (copy)="onCopy($event, operation.id)"
      (delete)="onDelete($event, operation.id)"
      (deleteAll)="onDeleteAll($event, operation.id)"
      (select)="onSelect($event, operation.id)"
      (filtered)="onActions($event, operation.id)"
      (searched)="onFilters($event, operation.id)"
    ></app-fiches-de-poste-list>
  </ng-container>
</div>
