import { NoResultComponent } from './components/no-result.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [NoResultComponent],
  exports: [NoResultComponent],

  imports: [CommonModule, SharedModule],
})
export class NoResultModule {}
