import { QueryGeoTerritoireRegionsServiceGQL } from './../graphql/queries/query-geo-territoire-regions.service';
import { QueryGeoTerritoireDepartementsServiceGQL } from './../graphql/queries/query-geo-territoire-departements.service';
import { QueryGeoTerritoireCommunesServiceGQL } from './../graphql/queries/query-geo-territoire-communes.service';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { GeoDistanceInput } from '../store/geo-territoire.model';
import { QueryGeoDistanceServiceGQL } from '../graphql/queries/query-geo-distance.service';

@Injectable({
  providedIn: 'any',
})
export class GeoTerritoireService {
  constructor(
    private queryGeoTerritoireCommunesServiceGQL: QueryGeoTerritoireCommunesServiceGQL,
    private queryGeoTerritoireDepartementsServiceGQL: QueryGeoTerritoireDepartementsServiceGQL,
    private queryGeoTerritoireRegionsServiceGQL: QueryGeoTerritoireRegionsServiceGQL,
    private queryGeoDistanceService: QueryGeoDistanceServiceGQL
  ) {}

  getGeoRegions(): Observable<any> {
    try {
      return this.queryGeoTerritoireRegionsServiceGQL.watch().valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  getGeoDepartements(code: string): Observable<any> {
    try {
      if (!code) return EMPTY;
      return this.queryGeoTerritoireDepartementsServiceGQL
        .watch({
          code,
        })
        .valueChanges.pipe(
          map(({ data, loading, error }) => {
            return { data, loading, error };
          })
        );
    } catch (error) {
      return of(error);
    }
  }
  getGeoCommunes(code: string): Observable<any> {
    try {
      if (!code) return EMPTY;
      return this.queryGeoTerritoireCommunesServiceGQL
        .watch({
          code,
        })
        .valueChanges.pipe(
          map(({ data, loading, error }) => {
            return { data, loading, error };
          })
        );
    } catch (error) {
      return of(error);
    }
  }
  getGeoDistance(adresses: GeoDistanceInput): Observable<any> {
    try {
      if (!adresses) return EMPTY;
      return this.queryGeoDistanceService
        .watch({
          adresses,
        })
        .valueChanges.pipe(
          map(({ data, loading, error }) => {
            return { data, loading, error };
          })
        );
    } catch (error) {
      return of(error);
    }
  }
}
