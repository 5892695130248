<div class="static w-full h-screen grid grid-cols-1 items-center">
  <div class="w-screen h-full flex flex-col items-center justify-items-center">
    <img [src]="logoLink" class="relative w-18 h-14 mt-10" />
    <div
      class="w-[32rem] p-6 flex items-start justify-center xl:justify-start my-[2rem] mx-[6rem]"
    >
      <form
        [formGroup]="logginForm"
        class="w-full rounded-xl shadow-lg hover:shadow-blue-800/40 flex flex-col space-x-4 space-y-6 p-6 pr-10"
      >
        <div
          class="w-full ml-4 flex flex-row items-start justify-between border-b border-s-slate-200"
        >
          <app-header-title
            [title]="titleForm"
            [subtitle]="true"
            [icon]="'account_circle'"
            class="mb-6"
          ></app-header-title>
          <app-loading *ngIf="loadingEmailCheck$ | async"></app-loading>
        </div>

        <div class="w-full flex flex-col items-start mb-4 space-y-2">
          <label for="email" class="label" *ngIf="email.dirty">E-mail</label>
          <input
            formControlName="email"
            placeholder="E-mail"
            id="email"
            required
            [ngClass]="{
              inputForm: !email.touched || !email.dirty || email.valid,
              inputFormWarning: email.touched && email.dirty && !email.valid,
              inputFormSuccess: (emailValidationCheck$ | async)?.status
            }"
          />
          <small
            class="inputFormAlert"
            *ngIf="email.touched && email.dirty && email.invalid"
            >{{ alertMessageNoEmail }}</small
          >
          <small
            *ngIf="
              (emailValidationCheck$ | async)?.status
                ? (emailValidationCheck$ | async)?.status
                : false
            "
            class="inputFormAlert"
            >{{ (error$ | async)?.message }}</small
          >

          <!-- <nz-form-item class="min-w-fit">
            <nz-form-control
              [nzHasFeedback]="
                (emailValidationCheck$ | async)?.status
                  ? (emailValidationCheck$ | async)?.status
                  : false
              "
              [nzErrorTip]="emailErrorTip"
              class="min-w-ful"
            >
              <ng-template #emailErrorTip>
                <small>{{ (error$ | async)?.message }}</small>
              </ng-template>
            </nz-form-control>
          </nz-form-item> -->
        </div>

        <button
          *ngIf="!(emailValidationCheck$ | async)?.status"
          [class]="
            !email.valid ? 'w-full btn btn-disabled' : 'w-full btn btn-primary'
          "
          (click)="$event.stopPropagation(); emailVerification()"
          [disabled]="!email.valid"
        >
          Valider votre adresse e-mail
        </button>

        <div
          *ngIf="(emailValidationCheck$ | async)?.status"
          class="w-full flex flex-col items-center mb-4"
        >
          <div
            *ngIf="(emailValidationCheck$ | async)?.status"
            class="w-full flex flex-col items-start space-y-2"
          >
            <label class="label" for="password">Mot de pass</label>
            <div class="w-full flex items-center justify-between">
              <div class="flex flex-col w-10/12 space-y-4">
                <input
                  formControlName="password"
                  [type]="hide ? 'password' : 'text'"
                  id="password"
                  placeholder="Mot de passe"
                  (keydown.capsLock)="onMajuscul($event)"
                  (keydown.shift)="onMajuscul($event)"
                  required
                  class="inputForm w-3/4"
                />
                <small
                  class="inputFormAlert"
                  *ngIf="password.touched && password.dirty && password.invalid"
                  >Veuillez renseigner votre mot de passe !</small
                >
              </div>
              <div class="w-2/12 flex items-center pl-4">
                <button
                  class="btn btn-ghost btn-circle group/button"
                  (click)="hide = !hide"
                >
                  <svg
                    *ngIf="hide"
                    class="iconSvg group-hover/button:animate-pulse"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                    />
                  </svg>
                  <svg
                    *ngIf="!hide"
                    class="iconSvg group-hover/button:animate-pulse"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                    />
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>

          <button
            [disabled]="!(emailValidationCheck$ | async)?.status"
            (click)="onSubmit($event)"
            [class]="
              !(emailValidationCheck$ | async)?.status
                ? 'w-full btn btn-disabled mt-4 mb-4'
                : 'w-full btn btn-primary mt-4 mb-4'
            "
          >
            Connexion
          </button>

          <div class="divide"></div>
          <button
            (click)="onForgetPassword()"
            type="button"
            class="w-full btn btn-ghost mt-4"
            [disabled]="!(emailValidationCheck$ | async)?.status"
          >
            {{ forgot }}
          </button>
        </div>

        <div
          *ngIf="!(emailValidationCheck$ | async)?.status && (error$ | async)"
          class="flex space-x-4 ql-color-red"
        >
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
              />
            </svg>
          </span>
          <span>
            <p class="errorCore">{{ (error$ | async)?.message?.message }}</p>
          </span>
        </div>

        <div class="divide"></div>
        <button
          *ngIf="!(emailValidationCheck$ | async)?.status || (error$ | async)"
          (click)="onNewAccount($event)"
          class="btn btn-ghost text-sm"
        >
          {{ createNewUser__Title }}
        </button>
      </form>
    </div>
  </div>
  <!-- <div class="hidden h-full xl:block items-center justify-center">
    <app-page-brand></app-page-brand>
  </div> -->
</div>

<router-outlet> </router-outlet>
