import { AidsEligibleService } from './../../../../service/aids-eligible.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AidState } from './aids.reducer';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import * as fromAidsAction from './aids.actions';

@Injectable()
export class AidsEffects {
  loadfilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAidsAction.loadAidsFilters),
      mergeMap((action) =>
        this.aidsEligibleService.getFiltersParticipants().pipe(
          map((response: any) => {
            const filters = response.data.aidesEligiblesFilters;
            const loading: boolean = response.loading;
            this.aidStore.dispatch(
              fromAidsAction.loading({ loading: loading })
            );
            return fromAidsAction.loadAidsFiltersSuccess({
              filters: filters,
            });
          }),
          catchError((error) => {
            this.aidStore.dispatch(fromAidsAction.loading({ loading: false }));
            return of(fromAidsAction.loadAidsFiltersFailure({ error }));
          })
        )
      )
    )
  );

  loadAids$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAidsAction.loadAids),
      mergeMap((action) =>
        this.aidsEligibleService.getAidsSuggestions(action.filters).pipe(
          map((response: any) => {
            const aids = response.data.aidesEligibles;
            this.aidStore.dispatch(fromAidsAction.loading({ loading: false }));
            return fromAidsAction.loadAidsSuccess({
              aids,
            });
          }),
          catchError((error) => {
            this.aidStore.dispatch(fromAidsAction.loading({ loading: false }));
            return of(fromAidsAction.loadAidsFailure({ error }));
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private aidsEligibleService: AidsEligibleService,
    private aidStore: Store<AidState>
  ) {}
}
