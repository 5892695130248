import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'any',
})
export class PortalLayoutBodyService {
  private activePortal = new Subject<TemplatePortal | ComponentPortal<any>>();
  readonly portal$ = this.activePortal.asObservable();
  constructor() {}

  setPortal(portal: TemplatePortal | ComponentPortal<any>) {
    this.activePortal.next(portal);
  }
}
