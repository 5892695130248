import { BehaviorSubject, Observable, of } from 'rxjs';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';

@Component({
  selector: 'app-participant-actions-accompagnement',
  templateUrl: './participant-actions-accompagnement.component.html',
  styleUrls: ['./participant-actions-accompagnement.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantActionsAccompagnementComponent implements OnInit {
  view$ = new BehaviorSubject<string>('list');
  currentAction$ = new BehaviorSubject<any>(null);

  @Input() participant$: Observable<any> = of(null);

  constructor() {}

  ngOnInit(): void {}

  onBack(): void {
    this.view$.next('list');
    this.currentAction$.next(null);
  }

  onSelect(item: any): void {
    if (!item) return;
    this.currentAction$.next(item);
    this.view$.next('detail');
  }
}
