import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router, Data } from '@angular/router';
import { Store } from '@ngrx/store';
import { OperationState } from 'src/app/components/operation/store/operation.reducer';
import * as fromOperationSelector from 'src/app/components/operation/store/operation.selectors';
import * as fromOperationAction from 'src/app/components/operation/store/operation.actions';

import { OperationFicheDePosteState } from '../../store/operation-fiche-de-poste.reducer';

import * as fromOperationFicheDePosteAction from '../../store/operation-fiche-de-poste.actions';
import * as fromOperationFicheDePosteSelector from '../../store/operation-fiche-de-poste.selectors';
import { Observable, Subject, map, of, takeUntil } from 'rxjs';
import { Update } from '@ngrx/entity';
import { OperationCandidatureState } from 'src/app/components/operation-fiche-de-poste-candidature/store/operation-fiche-de-poste-candidature.reducer';
import * as fromOperationCandidatureAction from 'src/app/components/operation-fiche-de-poste-candidature/store/operation-fiche-de-poste-candidature.actions';
import * as fromOperationCandidatureSelector from 'src/app/components/operation-fiche-de-poste-candidature/store/operation-fiche-de-poste-candidature.selectors';
import { OperationTeamState } from 'src/app/components/operation-team/store/operation-team.reducer';
import * as fromOperationTeamAction from 'src/app/components/operation-team/store/operation-team.actions';
import * as fromOperationTeamSelector from 'src/app/components/operation-team/store/operation-team.selectors';
import { OperationDiffusionState } from 'src/app/components/operation-fiche-diffusion/store/operation-fiche-diffusion.reducer';
import * as fromOperationDiffusionAction from 'src/app/components/operation-fiche-diffusion/store/operation-fiche-diffusion.actions';
import * as fromOperationDiffusionSelector from 'src/app/components/operation-fiche-diffusion/store/operation-fiche-diffusion.selectors';
import { OperationFicheNoteState } from 'src/app/components/operation-fiche-note/store/operation-fiche-note.reducer';
import * as fromOperationFicheNoteSelector from 'src/app/components/operation-fiche-note/store/operation-fiche-note.selectors';
import * as fromOperationFicheNoteAction from 'src/app/components/operation-fiche-note/store/operation-fiche-note.actions';
import { OperationFicheDePosteCandidaturesComponent } from 'src/app/components/operation-fiche-de-poste-candidature/components/operation-fiche-de-poste-candidatures/operation-fiche-de-poste-candidatures.component';

@Component({
  selector: 'app-operation-fiches-de-poste-item',
  templateUrl: './operation-fiches-de-poste-item.component.html',
  styleUrls: ['./operation-fiches-de-poste-item.component.scss'],
})
export class OperationFichesDePosteItemComponent implements OnInit, OnDestroy {
  operation$: Observable<any> = this.operationStore.select(
    fromOperationSelector.operation
  );
  loading$: Observable<boolean> = of(false);
  fiche$: Observable<any> = this.operationFicheDePosteStore.select(
    fromOperationFicheDePosteSelector.fiche
  );

  team$: Observable<any> = this.operationTeamStore.select(
    fromOperationTeamSelector.team
  );
  users$: Observable<any> = of([]);

  subscribe = new Subject();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private operationStore: Store<OperationState>,
    private operationFicheDePosteStore: Store<OperationFicheDePosteState>,
    private operationCandidatureStore: Store<OperationCandidatureState>,
    private operationDiffusionStore: Store<OperationDiffusionState>,
    private operationFicheNoteStore: Store<OperationFicheNoteState>,

    private operationTeamStore: Store<OperationTeamState>
  ) {
    const data: Data = this.route.snapshot.data;
    this.fiche$ = data.fiche;
    this.operationStore.dispatch(
      fromOperationAction.setActviteRoute({ title: data?.title })
    );
  }

  ngOnInit(): void {
    this.loadOperationTeam();
    this.getOperationUsers();
    this.getFicheLoading();
  }
  ngOnDestroy(): void {
    this.subscribe.next(false);
    this.subscribe.complete();
  }

  loadOperationTeam(): void {
    this.operation$.pipe(takeUntil(this.subscribe)).subscribe((operation) => {
      if (!operation) return;
      const { id } = operation;
      this.operationTeamStore.dispatch(
        fromOperationTeamAction.loadOperationTeams({ id })
      );
    });
  }

  getOperationUsers(): void {
    this.team$.pipe(takeUntil(this.subscribe)).subscribe((team: any) => {
      if (!team?.length) return;

      const users = team.map((el: any) => el.user);

      this.users$ = of(users);
    });
  }

  onTabsChanges(event: number, operation: any, fiche: any): void {
    const operationId: string = operation.id;
    const ficheId: string = fiche.id;

    switch (event) {
      case 0:
        break;
      case 1:
        break;
      case 2:
        break;
      case 3:
        break;
      case 4:
        break;
      case 5:
        break;
      case 6:
        break;
      default:
        break;
    }
  }

  //FICHE DE POSTE
  getFicheLoading(): void {
    this.loading$ = this.operationFicheDePosteStore.select(
      fromOperationFicheDePosteSelector.loadingItem
    );
  }

  onBackToList(operationId: string): void {
    if (!operationId) return;

    this.router.navigate(['/projet', operationId, 'fichesDePoste']);
  }

  onUpdate(fiche: any, id: string): void {
    this.operationFicheDePosteStore.dispatch(
      fromOperationFicheDePosteAction.updateOperationFicheDePoste({ id, fiche })
    );
  }
  onDuplicate(id: string, operationId: string): void {
    if (!id || !operationId) return;
  }
  onDelete(fiche: any, operationId: string): void {
    const { id } = fiche;
    this.operationFicheDePosteStore.dispatch(
      fromOperationFicheDePosteAction.deleteOperationFicheDePoste({
        operationId,
        id,
      })
    );
  }

  //LOADFICHEDEPENDANCE
  loadFicheDependance(): void {
    this.fiche$.pipe(takeUntil(this.subscribe)).subscribe((fiche: any) => {
      if (!fiche) return;
      const { mission, id } = fiche;
      const { operation } = mission;
      this.loadOperationDiffusions(operation.id, id);
    });
  }

  //CANDIDATURE

  onAddCandidats(
    data: { candidat: { prescripteur: any; candidats: any[] }; fiche: any },
    operationId: string
  ): void {
    const candidats = data.candidat;
    const fiche = data.fiche;
    const operation = fiche.mission.operation;

    this.operationCandidatureStore.dispatch(
      fromOperationCandidatureAction.addOperationCandidature({
        operationId: operationId,
        ficheId: fiche.id,
        candidats: candidats,
      })
    );
  }

  onUpdateCandidat(event: any, operationId: string): void {
    // if (!data.candidat.id || !data.fiche.id) return;
    // const candidat = data.candidat;
    // const fiche = data.fiche;
    // this.operationCandidatureStore.dispatch(
    //   fromOperationCandidatureAction.updateOperationCandidature({
    //     operationId: fiche.operation.id,
    //     ficheId: fiche.id,
    //     candidat: candidat,
    //   })
    // );
  }

  onTransfertCandidat(data: { candidat: any; fiche: any }): void {
    const candidat = data.candidat;
    const currentFiche = data.fiche;
    const transfertFiche = null;
  }

  onDeleteCandidat(
    data: { candidat: any; fiche: any },
    operationId: string
  ): void {
    const candidat = data.candidat;
    const fiche = data.fiche;
    const operation = fiche.mission.operation;
    this.operationCandidatureStore.dispatch(
      fromOperationCandidatureAction.deleteOperationCandidature({
        operationId: operation.id,
        ficheId: fiche.id,
        candidatId: candidat.id,
      })
    );
  }
  onDeleteCandidats(data: { fiche: any }, operationId: string): void {
    const fiche = data.fiche;
    const operation = fiche.mission.operation;
    this.operationCandidatureStore.dispatch(
      fromOperationCandidatureAction.deleteOperationCandidatures({
        operationId: operation.id,
        ficheId: fiche.id,
        ids: [],
      })
    );
  }

  //DIFFUSION
  loadOperationDiffusions(operationId: string, ficheId: string): void {
    if (!operationId || !ficheId) return;

    this.operationDiffusionStore.dispatch(
      fromOperationDiffusionAction.loadOperationFicheDiffusions({
        operationId,
        ficheId,
      })
    );
  }
  onAddDiffusion(diffusion: any, operationId: string, ficheId: string): void {
    if (!diffusion || !operationId || !ficheId) return;

    this.operationDiffusionStore.dispatch(
      fromOperationDiffusionAction.addOperationFicheDiffusion({
        operationId,
        ficheId,
        diffusion,
      })
    );
  }
  onUpdateDiffusion(
    diffusion: Update<any>,
    operationId: string,
    ficheId: string,
    fiche: any
  ): void {
    if (!diffusion || !operationId || !ficheId || !fiche) return;

    // const diffusions = fiche.diffusions.filter((el:any) => el.id !== event.diffusionId )
    // const nextFiche = {...fiche, diffusions}
    // this.operationFicheDePosteStore.dispatch(fromOperationFicheDePosteAction.updateOperationFicheDePosteItem({fiche: nextFiche}))

    this.operationDiffusionStore.dispatch(
      fromOperationDiffusionAction.updateOperationFicheDiffusion({
        operationId,
        ficheId,
        diffusion,
      })
    );
  }
  onDeleteDiffusion(event: any, operationId: string, fiche: any): void {
    if (!event.diffusionId || !operationId || !event.ficheId || !fiche) return;

    // const diffusions = fiche.diffusions.filter(
    //   (el: any) => el.id !== event.diffusionId
    // );
    // const nextFiche = { ...fiche, diffusions };
    // this.operationFicheDePosteStore.dispatch(
    //   fromOperationFicheDePosteAction.updateOperationFicheDePosteItem({
    //     fiche: nextFiche,
    //   })
    // );

    this.operationDiffusionStore.dispatch(
      fromOperationDiffusionAction.deleteOperationFicheDiffusion({
        operationId,
        ficheId: event.ficheId,
        diffusionId: event.diffusionId,
      })
    );
  }
  onDeleteDiffusions(operationId: string, ficheId: string, fiche: any): void {
    if (!operationId || !ficheId || !fiche) return;

    // const nextFiche = { ...fiche, diffusions: [] };
    // this.operationFicheDePosteStore.dispatch(
    //   fromOperationFicheDePosteAction.updateOperationFicheDePosteItem({
    //     fiche: nextFiche,
    //   })
    // );

    this.operationDiffusionStore.dispatch(
      fromOperationDiffusionAction.deleteOperationFicheDiffusions({
        operationId,
        ficheId,
        ids: [],
      })
    );
  }

  //NOTE
  loadOperationFicheNotes(operationId: string, ficheId: string): void {
    if (!operationId || !ficheId) return;

    this.operationDiffusionStore.dispatch(
      fromOperationDiffusionAction.loadOperationFicheDiffusions({
        operationId,
        ficheId,
      })
    );
  }
  onAddFicheNote(note: any, operationId: string, ficheId: string): void {
    if (!note || !operationId || !ficheId) return;

    this.operationFicheNoteStore.dispatch(
      fromOperationFicheNoteAction.addOperationFicheNote({
        operationId,
        ficheId,
        note,
      })
    );
  }
  onUpdateFicheNote(
    note: Update<any>,
    operationId: string,
    ficheId: string,
    fiche: any
  ): void {
    if (!note || !operationId || !ficheId || !fiche) return;

    this.operationFicheNoteStore.dispatch(
      fromOperationFicheNoteAction.updateOperationFicheNote({
        operationId,
        ficheId,
        note,
      })
    );
  }
  onDeleteFicheNote(
    event: { noteId: string; ficheId: string },
    operationId: string,
    fiche: any
  ): void {
    if (!event.noteId || !operationId || !event.ficheId || !fiche) return;
    const { noteId, ficheId } = event;

    this.operationFicheNoteStore.dispatch(
      fromOperationFicheNoteAction.deleteOperationFicheNote({
        operationId,
        ficheId,
        noteId,
      })
    );
  }
  onDeleteFicheNotes(operationId: string, ficheId: string, fiche: any): void {
    if (!operationId || !ficheId || !fiche) return;

    this.operationFicheNoteStore.dispatch(
      fromOperationFicheNoteAction.deleteOperationFicheNotes({
        operationId,
        ficheId,
        ids: [],
      })
    );
  }
}
