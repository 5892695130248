import { ModuleId } from './../components/module/store/module.model';
import { Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  doc,
  docSnapshots,
  collectionChanges,
  getDoc,
  addDoc,
  setDoc,
  deleteDoc,
  serverTimestamp,
  DocumentData,
  DocumentSnapshot,
  Timestamp,
  query,
  where,
  arrayUnion,
  arrayRemove,
  collectionSnapshots,
  CollectionReference,
  DocumentReference,
  getDocs,
  documentId,
} from '@angular/fire/firestore';
import { Observable, of, from, EMPTY } from 'rxjs';
import { switchMap, mergeMap, map, catchError } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Functions, httpsCallable } from '@angular/fire/functions';
import {
  Storage,
  ref,
  uploadBytesResumable,
  deleteObject,
  getDownloadURL,
  StorageReference,
  UploadTask,
  UploadMetadata,
} from '@angular/fire/storage';
import { UserState } from '../components/user/store/user.reducer';
import * as fromUserSelector from '../components/user/store/user.selectors';
import { v4 as uuidV4 } from 'uuid';
import { format, fromUnixTime } from 'date-fns';
import { ModuleState } from './../components/module/store/module.reducer';
import * as fromModuleAction from './../components/module/store/module.actions';
import * as fromModuleSelector from './../components/module/store/module.selectors';

import { OperationId } from './../components/operation/store/operation.model';

@Injectable({
  providedIn: 'any',
})
export class ModuleStorageService {
  constructor(
    private db: Firestore,
    private fns: Functions,
    private userStore: Store<UserState>,
    private storage: Storage,
    private moduleStore: Store<ModuleState>
  ) {}

  getAll(operationId?: string, moduleId?: string): Observable<any> {
    try {
      return this.moduleStore.select(fromModuleSelector.module).pipe(
        switchMap((module) => {
          if (!module) {
            return EMPTY;
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${module.organisation.id}/operations/${module.operation.id}/modules/${module.id}/documents`
            );

            return collectionSnapshots(dbCollection).pipe(
              map((snap: DocumentSnapshot<DocumentData>[]) => {
                return snap.map((doc: DocumentData) => {
                  const data = doc.data() as any;
                  const id = doc.id;
                  return { id, ...data };
                });
              }),
              catchError((error) => error)
            );
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  getOne(operationId: string, moduleId: string, id: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !operationId || !moduleId) {
            return of(null);
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/operations/${operationId}/modules/${moduleId}/documents`
            );

            const documentRef = doc(dbCollection, id);
            return from(
              getDoc(documentRef)
                .then((snap) => {
                  const data = snap.data();
                  const id = snap.id;
                  return { id, ...data };
                })
                .catch((error) => error)
            ).pipe(mergeMap((res: any) => of(res)));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  addFiles(module: ModuleId, documents: any[]): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !module || !documents?.length) {
            return of(null);
          } else {
            documents.forEach((document) => {
              const { file, doc } = document;
              const extension: string = `${file.name.split('.').pop()}`;
              const storagePah: string = `organisations/${user.organisation.id}/operations/${module.operation.id}/modules/${module.id}/documents/${file.name}`;
              const storageRef: StorageReference = ref(
                this.storage,
                storagePah
              );
              const storageMetadata: UploadMetadata = {
                contentType: file.type,
                contentEncoding: '',
                contentLanguage: 'fr',
                cacheControl: '',
                customMetadata: {
                  moduleId: module.id,
                  organisationId: user.organisation.id,
                  name: file.name,
                  extension: extension,
                  date: new Date(Date.now()).toDateString(),
                },
                md5Hash: '',
              };

              const task: UploadTask = uploadBytesResumable(
                storageRef,
                file,
                storageMetadata
              );
              task.on(
                'state_changed',
                (snap) => {
                  let progress =
                    (snap.bytesTransferred / snap.totalBytes) * 100;
                  this.moduleStore.dispatch(
                    fromModuleAction.loadFileProgress({ progress })
                  );
                },
                (error) => {
                  this.moduleStore.dispatch(
                    fromModuleAction.addDocumentsFailure({ error })
                  );
                  this.moduleStore.dispatch(
                    fromModuleAction.loadFileProgress({ progress: 0 })
                  );
                },
                () => {
                  getDownloadURL(task.snapshot.ref).then((downloadURL) => {
                    const documentFile = {
                      ...doc,
                      access: {
                        [`${user.id}`]: true,
                      },
                      auteur: user,
                      auths: [user.id],
                      correspondanceId: module.id,
                      organisation: user.organisation,
                      fileRef: downloadURL,
                      operationId: module.operation.id,
                      dateStart: Timestamp.now(),
                      file: {
                        name: file.name,
                        path: storagePah,
                        fileName: file.name,
                        size: file.size,
                        extension: file.name.split('.').pop(),
                      },
                    };

                    const documentRef = collection(
                      this.db,
                      `organisations/${user.organisation.id}/operations/${module.operation.id}/modules/${module.id}/documents`
                    );
                    const nextDocument = {
                      ...documentFile,
                      dateUpdate: serverTimestamp(),
                      userUpdate: user,
                    };
                    return from(
                      addDoc(documentRef, nextDocument)
                        .then((docRef) => {
                          if (docRef?.id) {
                            this.getOne(
                              module.operation.id,
                              module.id,
                              docRef?.id
                            );
                          } else {
                          }
                        })
                        .then(() => {
                          this.moduleStore.dispatch(
                            fromModuleAction.loadFileProgress({
                              progress: 0,
                            })
                          );
                          return null;
                        })
                        .catch((error) => of(error))
                    );
                  });
                  // .then(() => {
                  //   this.moduleStore.dispatch(
                  //     fromModuleAction.loadDocuments()
                  //   );
                  // });
                }
              );
              return of(null);
            });

            return of(null);
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  deleteOne(module: ModuleId, document: any): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !module || !document) {
            return EMPTY;
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${module.organisation.id}/operations/${module.operation.id}/modules/${module.id}/documents`
            );

            const dbDocument = doc(dbCollection, document.id);

            return from(
              deleteDoc(dbDocument)
                .then(() => of(`Document supprimé`))
                .catch((error) => of(error))
            ).pipe(mergeMap((res) => res));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  deleteAll(): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) {
            return of(null);
          } else {
            const data = { id: user.id };
            const call = httpsCallable(
              this.fns,
              'participant-deleteAllDocuments'
            );
            return from(
              call(data)
                .then((rest) => rest)
                .catch((err) => err)
            ).pipe(
              mergeMap((res) => {
                return of(res);
              })
            );
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }
}
