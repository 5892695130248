<div class="w-full flex items-start justify-center gap-4">
  <app-button-menu
    [title]="'Ajouter'"
    [menu]="menuNew"
    [type]="''"
    *ngIf="!candidat.person.isRejected"
    (select)="onAction($event)"
  ></app-button-menu>
  <button
    class="btn btn-sm btn-ghost"
    (click)="onAction('Selectionner')"
    *ngIf="!candidat.person.isSelected"
  >
    Sélectionner
  </button>

  <app-button-dropdown
    [expansion]="candidatItemMenuExpansion"
    [isUpdate]="true"
    [isDelete]="true"
    [deleteType]="deleteType"
    (select)="onAction($event)"
  ></app-button-dropdown>

  <ng-template #candidatItemMenuExpansion>
    <li
      (click)="onAction('Valider')"
      *ngIf="!candidat.person.isValided && !candidat.person.isSelected"
    >
      <a>Valider</a>
    </li>
    <li (click)="onAction('Entretien')"><a>En entretien</a></li>

    <li (click)="onAction('Dupliquer')"><a>Dupliquer</a></li>

    <!-- <li (click)="onAction('Rejeter')" *ngIf="!candidat.person.isRejected">
      <a>Non retenu</a>
    </li> -->
    <li class="divide"></li>
    <li (click)="onAction('Contrat')">
      <a>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
          />
        </svg>

        Créer la mission</a
      >
    </li>
  </ng-template>
</div>
