import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-candidature-item-transfert',
  templateUrl: './candidature-item-transfert.component.html',
  styleUrls: ['./candidature-item-transfert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CandidatureItemTransfertComponent implements OnInit {
  fiches$: Observable<any>;
  noDataTitle: string = 'Aucune fiche trouvée';

  @Output() select = new EventEmitter<any>();
  constructor() {}

  ngOnInit(): void {}

  onSelect(item: any): void {
    this.select.emit(item);
  }
}
