<nz-card *ngIf="utility; else noData" [nzTitle]="utility?.name | titlecase">
  <app-utilities-tags
    [utilityId]="utility.id"
    [items]="items"
    [utility]="utility"
    [variable]="variable"
    [currentTag]="currentTag"
    (select)="onSelectItem($event, utility)"
    (refresh)="onRefreshItems($event)"
  ></app-utilities-tags>
  <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
  <app-utilities-items-list
    [items]="items"
    [utility]="utility"
    [variable]="variable"
  ></app-utilities-items-list>
</nz-card>

<ng-template #noData>
  <nz-card>
    <nz-empty nzNotFoundImage="simple"></nz-empty>
  </nz-card>
</ng-template>
