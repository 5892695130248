<nz-row nzAlign="middle" nzGutter="2" nzJustify="start">
  <nz-col nzSpan="3"></nz-col>

  <nz-col nzSpan="1"> </nz-col>
  <nz-col nzSpan="14">
    <app-search-bar
      [filter]="true"
      [cleaner]="cleaner"
      [placeholder]="'Rechercher une information collective...'"
    ></app-search-bar>
  </nz-col>
  <nz-col nzSpan="4">
    <!-- <button
        nz-button
        nzShape="circle"
        nz-popover
        [nzPopoverContent]="contactsFilterTemplate"
        nzPopoverPlacement="bottomRight"
        nzPopoverTrigger="click"
      >
        <i nz-icon nzType="filter"></i>
        
      </button> -->

    <button
      nz-button
      nzShape="circle"
      nzType="text"
      [routerLink]="['/participants']"
      nz-tooltip
      [nzTooltipMouseEnterDelay]="0.5"
      nzTooltipPlacement="bottom"
      nzTooltipTitle="Liste des participants"
      class="buttonCls"
    >
      <mat-icon>supervisor_account</mat-icon>
    </button>
  </nz-col>
  <nz-col nzSpan="2"> </nz-col>
</nz-row>
