import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
  Validator,
  Validators,
} from '@angular/forms';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Observable, of, Subject, takeUntil } from 'rxjs';
import { OperationNoteSubtitleId } from 'src/app/components/operation-note-subtitle/store/operation-note-subtitle.model';
import { OperationNoteId } from 'src/app/components/operation-note/store/operation-note.model';
import {
  OperationNoteSujet,
  OperationNoteSujetId,
} from '../../store/operation-note-sujet.model';
import { Update } from '@ngrx/entity';
import { Timestamp } from 'firebase/firestore';

@Component({
  selector: 'app-operation-note-sujet-form',
  templateUrl: './operation-note-sujet-form.component.html',
  styleUrls: ['./operation-note-sujet-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationNoteSujetFormComponent
  implements OnInit, OnChanges, OnDestroy
{
  subscribe = new Subject();

  @Input() note$: Observable<OperationNoteId | any> = of(null);
  @Input() subtitle$: Observable<OperationNoteSubtitleId | any> = of(null);
  @Input() sujet$: Observable<OperationNoteSujetId | any> = of(null);

  @Output() add = new EventEmitter<OperationNoteSujet>();
  @Output() update = new EventEmitter<Update<OperationNoteSujetId>>();
  @Output() cancel = new EventEmitter();

  sujetForm: UntypedFormGroup = this.fb.group({});
  auteur: UntypedFormControl = new UntypedFormControl('');
  userUpdate: UntypedFormControl = new UntypedFormControl('');
  subtitle: UntypedFormControl = new UntypedFormControl('');
  noteId: UntypedFormControl = new UntypedFormControl('');
  title: UntypedFormControl = new UntypedFormControl('');
  text: UntypedFormControl = new UntypedFormControl('');
  textHTML: UntypedFormControl = new UntypedFormControl('');
  actions: UntypedFormControl = new UntypedFormControl();
  documents: UntypedFormControl = new UntypedFormControl();
  dateStart: UntypedFormControl = new UntypedFormControl('');
  dateUpdate: UntypedFormControl = new UntypedFormControl('');
  children: UntypedFormControl = new UntypedFormControl([]);
  isWarning: UntypedFormControl = new UntypedFormControl(false);
  isDone: UntypedFormControl = new UntypedFormControl(false);

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.form();
  }
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
    this.sujetForm.reset();
  }

  form(): void {
    this.formInit();
    this.formCreate();
    this.formSet();
  }
  formInit(): void {
    this.auteur = new UntypedFormControl('');
    this.userUpdate = new UntypedFormControl('');
    this.subtitle = new UntypedFormControl('');
    this.noteId = new UntypedFormControl('');
    this.title = new UntypedFormControl('');
    this.text = new UntypedFormControl('', Validators.required);
    this.textHTML = new UntypedFormControl('', Validators.required);
    this.actions = new UntypedFormControl();
    this.documents = new UntypedFormControl();
    this.dateStart = new UntypedFormControl('');
    this.dateUpdate = new UntypedFormControl('');
    this.children = new UntypedFormControl([]);
    this.isWarning = new UntypedFormControl(false);
    this.isDone = new UntypedFormControl(false);
  }
  formCreate(): void {
    this.sujetForm = this.fb.group({
      auteur: this.auteur,
      userUpdate: this.userUpdate,
      subtitle: this.subtitle,
      note: {
        id: this.noteId,
      },
      title: this.title,
      text: this.text,
      textHTML: this.textHTML,
      actions: this.actions,
      documents: this.documents,
      dateStart: this.dateStart,
      dateUpdate: this.dateUpdate,
      children: this.children,
      isWarning: this.isWarning,
      isDone: this.isDone,
    });
  }
  formSet(): void {
    this.sujet$
      .pipe(takeUntil(this.subscribe))
      .subscribe((sujet: OperationNoteSujetId) => {
        if (!sujet) return;
      });
  }

  onTextChanges(event: any): void {
    if (!event) return;
    this.sujetForm.patchValue({
      textHTML: event.textHTML,
      text: event.textValue,
    });
  }
  onAdd(note: OperationNoteId, subtitle: OperationNoteSubtitleId): void {
    if (!note || !subtitle) return;
    if (!this.sujetForm.valid) return;
    const sujet: OperationNoteSujet = this.sujetForm.value;
    sujet.note.id = note.id;
    sujet.subtitle = subtitle;
    sujet.dateStart = Timestamp.now();

    this.add.next(sujet);
    this.sujetForm.reset();
  }
  onUpcate(
    note: OperationNoteId,
    subtitle: OperationNoteSubtitleId,
    sujet: OperationNoteSujetId
  ): void {
    if (!note || !subtitle || !sujet) return;
    if (!this.sujetForm.valid) return;
    const sujetForm: OperationNoteSujet = this.sujetForm.value;

    const nextSujet: Update<OperationNoteSujetId> = {
      id: sujet.id,
      changes: { ...sujet, ...sujetForm },
    };

    this.update.next(nextSujet);
    this.sujetForm.reset();
  }
  onCancel(): void {
    this.sujetForm.reset();
  }
}
