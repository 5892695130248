import { DepartementId } from './../../store/departement.model';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Observable, of } from 'rxjs';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';

import { Store } from '@ngrx/store';

@Component({
  selector: 'app-departement-details',
  template: `
    <nz-row nzAlign="middle" nzJustify="start" nzGutter="8">
      <nz-col nzSpan="12">
        <nz-card
          [nzTitle]="departementDetails__cardDefinition"
          [nzExtra]="departementUpdateBtnTpl"
        >
          <ng-template #departementDetails__cardDefinition>
            <app-header-title
              [icon]="'group_work'"
              [title]="'Service'"
              [subtitle]="true"
            ></app-header-title>
          </ng-template>
          <ng-template #departementUpdateBtnTpl>
            <button
              *ngIf="(user$ | async | isAdmin) || (user$ | async | isDev)"
              nz-button
              nzType="text"
              nzShape="circle"
              (click)="$event.stopPropagation(); onUpdate(departement)"
            >
              <mat-icon>edit</mat-icon>
            </button>
          </ng-template>
          <nz-descriptions
            *ngIf="departement"
            nzBordered
            [nzColumn]="{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }"
          >
            <nz-descriptions-item nzTitle="Définition" nzSpan="1">
              <div class="departementDetail__content">
                <p nz-typography>{{ departement?.definition }}</p>
              </div>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Adresse" nzSpan="1">
              <div class="departementDetail__content_adresse">
                <p nz-typography>
                  {{ departement?.adresse?.adresse?.properties?.label }}
                </p>
              </div>
            </nz-descriptions-item>
          </nz-descriptions>
        </nz-card>
      </nz-col>
      <nz-col nzSpan="12">
        <nz-card
          [nzTitle]="departementDetails__cardManager"
          [nzExtra]="
            (user$ | async | isAdmin) || (user$ | async | isDev)
              ? departementAddMemberTpl
              : ''
          "
        >
          <ng-template #departementDetails__cardManager>
            <app-header-title
              [icon]="'group'"
              [title]="'Collaborateurs'"
              [subtitle]="true"
            ></app-header-title>
          </ng-template>
          <div class="container_list">
            <nz-list
              nzSize="small"
              *ngIf="departement?.users | size; else noData"
            >
              <nz-list-item
                *ngFor="let item of departement?.users; trackBy: trackUserBy"
              >
                <app-departement-user-item
                  [user]="item"
                  [currentUser]="user$ | async"
                  (click)="$event.stopPropagation()"
                ></app-departement-user-item>
              </nz-list-item>
            </nz-list>

            <ng-template #noData>
              <app-no-result
                [icon]="'person'"
                [isButton]="false"
                [notitle]="'Aucun collaborateur ajouté'"
              ></app-no-result>
            </ng-template>

            <ng-template #departementAddMemberTpl>
              <button
                nz-button
                nzType="text"
                nzShape="circle"
                (click)="$event.stopPropagation(); onNewMember()"
              >
                <mat-icon>person_add_alt</mat-icon>
              </button>
            </ng-template>
          </div>
        </nz-card>
      </nz-col>
    </nz-row>
  `,
  styles: [
    `
      :host {
        width: 100%;
        overflow: hidden;
      }

      nz-card {
        border-radius: 12px;
        width: 100%;
        height: calc(100vh - 9em);
        overflow: hidden;
      }

      .container_list {
        height: calc(100vh - 18em);
        overflow-y: auto;
      }

      nz-list {
        height: 100%;
      }

      nz-list-item {
        cursor: pointer;
        :hover {
          font-weight: bold;
        }
      }

      .departementDetail__content {
        height: 220px;
        overflow-y: auto;
      }
      .departementDetail__content_adresse {
        height: 100px;
        overflow-y: auto;
      }

      nz-descriptions {
        border-radius: 12px !important;
      }

      app-departement-user-item {
        width: 100%;
        :hover {
          font-weight: bold;
        }
      }

      mat-icon {
        color: #062949;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DepartementDetailsComponent implements OnInit, OnChanges {
  user$: Observable<any> = of(null);
  bgTag: string = '#bfbfbf';
  @Input() departement: DepartementId | any = null;
  @Output() update: EventEmitter<any> = new EventEmitter();
  @Output() newMember: EventEmitter<any> = new EventEmitter();

  constructor(private userStore: Store<UserState>) {}

  ngOnInit(): void {
    this.getUser();
  }
  ngOnChanges(changes: SimpleChanges): void {}

  trackUserBy(user: any): any {
    return user.id;
  }

  getUser(): void {
    this.user$ = this.userStore.select(fromUserSelector.user);
  }
  onUpdate(departement: any): void {
    if (!departement) return;
    this.update.emit(departement);
  }

  onNewMember(): void {
    this.newMember.emit(true);
  }
}
