<nz-card nzBorderless>
  <form nz-form [formGroup]="userForm" nzLayout="vertical">
    <nz-row nzAlign="top" nzGutter="2" nzJustify="start" *ngIf="type === 'add'">
      <nz-col [nzSpan]="4">
        <i
          nz-icon
          nzType="loading"
          class="loading"
          *ngIf="loading$ | async"
        ></i>
        <nz-form-item style="width: 100%" *ngIf="!(loading$ | async)">
          <nz-form-label [nzSpan]="24" nzFor="civilite" nzRequired
            >Civilité</nz-form-label
          >

          <nz-form-control>
            <nz-select
              formControlName="civilite"
              placeholder="Civilité"
              nzBorderless
              nzPlaceHolder="Civilité"
            >
              <nz-option
                *ngFor="let civilitie of civilities$ | async"
                [nzLabel]="civilitie"
                [nzValue]="civilitie"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </nz-col>
      <nz-col [nzSpan]="8">
        <nz-form-item nzRequired="true">
          <nz-form-label nzRequired="true">Nom</nz-form-label>
          <nz-form-control>
            <input
              nz-input
              nzBorderless
              placeholder="Nom"
              formControlName="lastName"
            />
          </nz-form-control>
        </nz-form-item>
      </nz-col>
      <nz-col [nzSpan]="12">
        <nz-form-item nzRequired="true">
          <nz-form-label nzRequired="true">Prénom</nz-form-label>
          <nz-form-control>
            <input
              nz-input
              nzBorderless
              placeholder="prénom"
              formControlName="firstName"
            />
          </nz-form-control>
        </nz-form-item>
      </nz-col>
    </nz-row>

    <nz-row nzAlign="top" nzGutter="2" nzJustify="start">
      <nz-col nzSpan="24">
        <nz-form-item nzRequired="true">
          <nz-form-label nzRequired="true">Fonction</nz-form-label>
          <nz-form-control>
            <input
              nz-input
              nzBorderless
              placeholder="Fonction"
              formControlName="fonction"
            />
          </nz-form-control>
        </nz-form-item>
      </nz-col>
    </nz-row>

    <nz-row nzAlign="top" nzGutter="2" nzJustify="start">
      <nz-col nzSpan="24">
        <app-communes-france
          [borderless]="true"
          [size]="'default'"
          [isComplement]="false"
          [updateItem]="adresse$"
          [updateComplement]="adresseComplement$"
          [qpvActiv]="false"
          (adresse)="onSetAdresse($event)"
          (complement)="onSetAdresseComplement($event)"
        >
        </app-communes-france>
      </nz-col>
    </nz-row>
    <nz-space nzDirection="vertical" [nzSize]="200"></nz-space>

    <nz-row nzAlign="top" nzGutter="2" nzJustify="start">
      <nz-col nzSpan="24">
        <app-coordonnees
          [borderless]="true"
          [isFixe]="false"
          [update]="coordonnees$"
          (phoneNumber)="onSetCoordonneesPhoneNumber($event)"
          (mobile)="onSetCoordonneesMobile($event)"
          (email)="onSetCoordonneesEmail($event)"
        ></app-coordonnees>
      </nz-col>
    </nz-row>
  </form>
  <ng-container *ngIf="user$ | async">
    <nz-divider
      nzOrientation="center"
      nzType="horizontal"
      nzText="Rôle de l'utilisateur"
    ></nz-divider>

    <nz-row nzAlign="top" nzGutter="2" nzJustify="center">
      <nz-col nzSpan="8">
        <app-check-card
          [title]="'Administrateur'"
          [definition]="'Gestion de l\'organisation'"
          [height]="checkCard"
          [isActive]="userForm.value?.claims.admin"
          (select)="setUserRole($event, 'admin')"
        ></app-check-card>
      </nz-col>
      <nz-col nzSpan="8">
        <app-check-card
          [title]="'Manager'"
          [height]="checkCard"
          [definition]="
            'Gestion d\'un service, gestion de projets, gestion d\'équipe projet, gestion de participants, gestion d\'entités'
          "
          [isActive]="userForm.value?.claims.manager"
          (select)="setUserRole($event, 'manager')"
        ></app-check-card>
      </nz-col>
      <nz-col nzSpan="8">
        <app-check-card
          [title]="'Chargé(e) de mission'"
          [height]="checkCard"
          [definition]="
            'Gestion de participants, contribution aux projets, gestion d\'entités'
          "
          [isActive]="userForm.value?.claims.member"
          (select)="setUserRole($event, 'member')"
        ></app-check-card>
      </nz-col>
    </nz-row>
  </ng-container>
  <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>

  <nz-row nzAlign="middle" nzGutter="8" nzJustify="end">
    <nz-col nzSpan="12"></nz-col>
    <nz-col nzSpan="6">
      <button
        nz-button
        nzBlock
        nzType="text"
        (click)="$event.stopPropagation(); onCancel()"
      >
        <h5 nz-typography>Annuler</h5>
      </button>
    </nz-col>
    <nz-col nzSpan="6">
      <button
        nz-button
        nzBlock
        nzType="primary"
        *ngIf="type === 'add'"
        [disabled]="!userForm.valid"
        (click)="$event.stopPropagation(); onAdd()"
      >
        Ajouter
      </button>
      <button
        nz-button
        nzBlock
        nzType="primary"
        [disabled]="!userForm.valid"
        *ngIf="type === 'update' && (item$ | async) as detail"
        (click)="$event.stopPropagation(); onUpdate($event, detail)"
      >
        Modifier
      </button>
    </nz-col>
  </nz-row>
  <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="24">
      <p nz-typography class="alert">*{{ alertMessage }}</p>
    </nz-col>
  </nz-row>
</nz-card>
