import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import {
  NzTableFilterFn,
  NzTableFilterList,
  NzTableSortFn,
  NzTableSortOrder,
} from 'ng-zorro-antd/table';
import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';

interface DataItem {
  title: string;
  objectifs: number;
  realisation: number;
  progression: number;
  participants: number;
  collaboration: number;
}

interface ColumnItem {
  name: string;
  sortOrder: NzTableSortOrder | null;
  sortFn: NzTableSortFn<DataItem> | null;
  listOfFilter: NzTableFilterList;
  filterFn: NzTableFilterFn<DataItem> | null;
  filterMultiple: boolean;
  sortDirections: NzTableSortOrder[];
}

@Component({
  selector: 'app-intervention-table',
  templateUrl: './intervention-table.component.html',
  styleUrls: ['./intervention-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InterventionTableComponent
  implements OnInit, OnChanges, OnDestroy
{
  subscribe = new Subject();
  @Input() source$ = new BehaviorSubject<any[]>([]);
  @Output() select = new EventEmitter<any>(false);

  listOfColumns: ColumnItem[] = [
    {
      name: 'Module',
      sortOrder: null,
      sortFn: (a: DataItem, b: DataItem) => a.title.localeCompare(b.title),
      sortDirections: ['ascend', 'descend', null],
      filterMultiple: true,
      listOfFilter: [],
      filterFn: (list: string[], item: DataItem) =>
        list.some((title) => item.title.indexOf(title) !== -1),
    },
    {
      name: 'Objectifs',
      sortOrder: 'descend',
      sortFn: (a: DataItem, b: DataItem) => a.objectifs - b.objectifs,
      sortDirections: ['descend', null],
      listOfFilter: [],
      filterFn: null,
      filterMultiple: true,
    },
    {
      name: 'Réalisations',
      sortOrder: 'descend',
      sortFn: (a: DataItem, b: DataItem) => a.realisation - b.realisation,
      sortDirections: ['descend', null],
      listOfFilter: [],
      filterFn: null,
      filterMultiple: true,
    },
    {
      name: '%',
      sortOrder: 'descend',
      sortFn: (a: DataItem, b: DataItem) => a.progression - b.progression,
      sortDirections: ['descend', null],
      listOfFilter: [],
      filterFn: null,
      filterMultiple: true,
    },
    {
      name: 'Participants',
      sortOrder: 'descend',
      sortFn: (a: DataItem, b: DataItem) => a.participants - b.participants,
      sortDirections: ['descend', null],
      listOfFilter: [],
      filterFn: null,
      filterMultiple: true,
    },
    {
      name: 'Collaboration',
      sortOrder: null,
      sortFn: (a: DataItem, b: DataItem) => a.participants - b.participants,
      sortDirections: ['ascend', 'descend', null],
      filterMultiple: true,
      listOfFilter: [],
      filterFn: (list: string[], item: DataItem) =>
        list.some((collaboration) => item.title.indexOf(collaboration) !== -1),
    },
  ];

  listOfData: DataItem[] = [];

  constructor() {}

  ngOnInit(): void {
    this.onSetTable();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.onSetTable();
  }

  ngOnDestroy(): void {
    this.subscribe.next(false);
    this.subscribe.complete();
  }

  onSetTable(): void {
    this.source$.pipe(takeUntil(this.subscribe)).subscribe((items: any[]) => {
      if (!items?.length) return;
      const modulesConvertion: DataItem[] = items.map((item: any) => {
        const title: string = item.titre;
        const objectifs: number = item?.objectifHours ? item?.objectifHours : 0;
        const realisation: number = item?.realisations ? item?.realisations : 0;
        const progression: number = item?.progressionHours
          ? item?.progressionHours
          : 0;
        const participants: number = item?.progressionPersons
          ? item?.progressionPersons
          : 0;
        const collaboration: number = 0;
        return {
          title,
          objectifs,
          realisation,
          progression,
          participants,
          collaboration,
        };
      });
      this.listOfData = modulesConvertion;
    });
  }

  onSelect(item: any): void {
    if (!item) return;
    this.select.emit(item);
  }
}
