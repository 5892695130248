import { QueryActionsService } from './../../service/query-actions.service';
import { ActionStorageService } from './../../service/action-storage.service';
import { ActionSuivisService } from './../../service/action-suivis.service';
import { ActionService } from './../../service/action.service';
import { SharedModule } from './../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ActionRoutingModule } from './action-routing.module';
import { ActionListComponent } from './components/action-list/action-list.component';
import { ActionItemComponent } from './components/action-item/action-item.component';
import { ActionFormComponent } from './components/action-form/action-form.component';
import { ContentsModule } from 'src/app/contents/contents.module';
import { StoreModule } from '@ngrx/store';
import * as fromAction from './store/action.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ActionEffects } from './store/action.effects';
import { ActionCardComponent } from './components/action-card/action-card.component';
import { ActionFiltersComponent } from './components/action-filters/action-filters.component';
import { ModuleModule } from '../module/module.module';
import { ActionSelectComponent } from './components/action-select/action-select.component';
import { ActionModuleSelectComponent } from './components/action-module-select/action-module-select.component';
import { EntiteModule } from '../entite/entite.module';
import { ActionItemHeaderComponent } from './components/action-item-header/action-item-header.component';
import { ActionTableComponent } from './components/action-table/action-table.component';
import { ActionDetailsComponent } from './components/action-details/action-details.component';
import { ActionSuivisModule } from '../action-suivis/action-suivis.module';
import { ActionDocumentsModule } from '../action-documents/action-documents.module';
import { ActionMissionsModule } from '../action-missions/action-missions.module';
import { NoResultModule } from 'src/app/contents/components/no-result/no-result.module';
import { CoordonneesModule } from 'src/app/contents/components/coordonnees/coordonnees.module';
import { CommunesFranceModule } from 'src/app/contents/components/communes-france/communes-france.module';
import { ButtonsModule } from 'src/app/contents/components/buttons/buttons.module';
import { HeaderModule } from 'src/app/contents/components/header-title/header.module';
import { LayoutModule } from 'src/app/contents/components/layout/layout.module';
import { AvatarModule } from 'src/app/features/avatar/avatar.module';
import { SuiviModule } from 'src/app/features/suivi/suivi.module';
import { DocumentModule } from 'src/app/features/document/document.module';
import { UiModule } from 'src/app/ui/ui/ui.module';

@NgModule({
  declarations: [
    ActionListComponent,
    ActionItemComponent,
    ActionFormComponent,
    ActionCardComponent,
    ActionFiltersComponent,
    ActionSelectComponent,
    ActionModuleSelectComponent,
    ActionItemHeaderComponent,
    ActionTableComponent,
    ActionDetailsComponent,
  ],
  imports: [
    CommonModule,
    ActionRoutingModule,
    ContentsModule,
    SharedModule,
    ModuleModule,
    EntiteModule,
    LayoutModule,
    ActionSuivisModule,
    ActionDocumentsModule,
    ActionMissionsModule,
    NoResultModule,
    CoordonneesModule,
    HeaderModule,
    CommunesFranceModule,
    ButtonsModule,
    AvatarModule,
    SuiviModule,
    DocumentModule,
    UiModule,
    StoreModule.forFeature(fromAction.actionsFeatureKey, fromAction.reducer),
    EffectsModule.forFeature([ActionEffects]),
  ],
  exports: [
    ActionListComponent,
    ActionItemComponent,
    ActionFormComponent,
    ActionCardComponent,
    ActionFiltersComponent,
    ActionSelectComponent,
    ActionModuleSelectComponent,
    ActionItemHeaderComponent,
    ActionTableComponent,
    ActionDetailsComponent,
  ],
  providers: [
    ActionService,
    ActionSuivisService,
    ActionStorageService,
    QueryActionsService,
  ],
})
export class ActionModule {}
