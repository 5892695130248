import { ParticipantPlanningFormComponent } from './components/participant-planning-form/participant-planning-form.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParticipantPlanningComponent } from './components/participant-planning/participant-planning.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { PipeModule } from 'src/app/pipe/pipe.module';
import { ContentsModule } from 'src/app/contents/contents.module';
import { StoreModule } from '@ngrx/store';
import * as fromParticipantPlanning from './store/participant-planning.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ParticipantPlanningEffects } from './store/participant-planning.effects';
import { ParticipantPlanningService } from 'src/app/service/participant/planning/participant-planning.service';
import { ParticipantPlanningItemComponent } from './components/participant-planning-item/participant-planning-item.component';
import { ParticipantPlanningCardComponent } from './components/participant-planning-card/participant-planning-card.component';
import { UserModule } from '../user/user.module';
import { ParticipantPlanningListComponent } from './components/participant-planning-list/participant-planning-list.component';
import { ParticipantPlanningListCardComponent } from './components/participant-planning-list-card/participant-planning-list-card.component';
import { ParticipantPlanningListHeaderComponent } from './components/participant-planning-list-header/participant-planning-list-header.component';
import { ParticipantPlanningCalendarComponent } from './components/participant-planning-calendar/participant-planning-calendar.component';
import { ParticipantPlanningCalendarCardComponent } from './components/participant-planning-calendar-card/participant-planning-calendar-card.component';
import { ParticipantPlanningFilterComponent } from './components/participant-planning-filter/participant-planning-filter.component';
import { ParticipantPlanningExportValidationComponent } from './components/participant-planning-export-validation/participant-planning-export-validation.component';
import { NoResultModule } from 'src/app/contents/components/no-result/no-result.module';
import { CoordonneesModule } from 'src/app/contents/components/coordonnees/coordonnees.module';
import { CommunesFranceModule } from 'src/app/contents/components/communes-france/communes-france.module';
import { ButtonsModule } from 'src/app/contents/components/buttons/buttons.module';
import { HeaderModule } from 'src/app/contents/components/header-title/header.module';
import { AvatarModule } from 'src/app/features/avatar/avatar.module';

@NgModule({
  declarations: [
    ParticipantPlanningComponent,
    ParticipantPlanningItemComponent,
    ParticipantPlanningCardComponent,
    ParticipantPlanningFormComponent,
    ParticipantPlanningListComponent,
    ParticipantPlanningListCardComponent,
    ParticipantPlanningListHeaderComponent,
    ParticipantPlanningCalendarComponent,
    ParticipantPlanningCalendarCardComponent,
    ParticipantPlanningFilterComponent,
    ParticipantPlanningExportValidationComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ContentsModule,
    NoResultModule,
    CoordonneesModule,
    HeaderModule,
    CommunesFranceModule,
    ButtonsModule,
    UserModule,
    AvatarModule,
    StoreModule.forFeature(
      fromParticipantPlanning.participantPlanningsFeatureKey,
      fromParticipantPlanning.reducer
    ),
    EffectsModule.forFeature([ParticipantPlanningEffects]),
  ],
  exports: [ParticipantPlanningComponent],
  providers: [ParticipantPlanningService],
})
export class ParticipantPlanningModule {}
