import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-participant-experiences-item',
  templateUrl: './participant-experiences-item.component.html',
  styleUrls: ['./participant-experiences-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantExperiencesItemComponent implements OnInit, OnChanges {
  @Input() experience: any = null;
  @Output() back = new EventEmitter(false);
  constructor() {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {}

  onBack(): void {
    this.back.emit(true);
  }
}
