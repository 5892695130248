<nz-row nzAlign="top" nzJustify="center" nzGutter="4">
  <nz-col nzSpan="15">
    <div class="content" *ngIf="!(editing$ | async)">
      <nz-card
        nzBorderless
        [nzTitle]="pilotageItemTitlte"
        *ngIf="
          editingListItemShow$ | async as editingListItemShow;
          else noLivrable
        "
      >
        <ng-template #pilotageItemTitlte>
          <nz-row nzAlign="top" nzJustify="start" nzGutter="2">
            <nz-col nzSpan="24">{{ editingListItemShow?.title }}</nz-col>
          </nz-row>
          <nz-row nzAlign="top" nzJustify="start" nzGutter="2">
            <nz-col nzSpan="2">
              <mat-icon class="iconSujetItem">event</mat-icon>
            </nz-col>
            <nz-col nzSpan="22">
              <small>{{ editingListItemShow?.date | dateFr: "small" }}</small>
            </nz-col>
          </nz-row>
        </ng-template>

        <nz-descriptions nzSize="small" nzBordered>
          <nz-descriptions-item nzSpan="3" [nzTitle]="descriptionItem">
            <p class="itemDescription">
              {{ editingListItemShow?.description }}
            </p>
          </nz-descriptions-item>
        </nz-descriptions>
        <ng-template #descriptionItem>
          <mat-icon class="iconSujetItem">subject</mat-icon>
        </ng-template>

        <!-- <cdk-virtual-scroll-viewport
          appendOnly
          itemSize="50"
          class="viewportIntervenant"
        >
          <nz-list nzBordered nzSize="small" [nzHeader]="intervenantTile">
            <ng-template #intervenantTile>
              <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
                <nz-col nzSpan="20">
                  <h5 nz-typography>Intervenants</h5>
                </nz-col>
                <nz-col nzSpan="4">
                  <nz-badge
                    [nzCount]="editingListItemShow?.intervenants | size"
                  ></nz-badge>
                </nz-col>
              </nz-row>
            </ng-template>
            <nz-list-item
              *cdkVirtualFor="let item of editingListItemShow?.intervenants"
            >
              {{ item?.displayName }}
              <br />
              {{ item?.fonction }}
              <nz-divider nzType="vertical"></nz-divider>
              <strong>{{ item?.entite?.nom_raison_sociale }}</strong>
            </nz-list-item>
          </nz-list>
        </cdk-virtual-scroll-viewport> -->
      </nz-card>
    </div>
  </nz-col>
  <nz-col nzSpan="1">
    <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
  </nz-col>
  <nz-col nzSpan="8">
    <nz-list [nzHeader]="listHeader" [nzBordered]="false" nzSize="small">
      <ng-template #listHeader>
        <nz-row nzJustify="start" nzAlign="middle" nzGutter="8">
          <nz-col nzSpan="12">
            <button
              nz-button
              (click)="onNew(cadrage, operation)"
              nz-tooltip
              nzBlock
              [nzTooltipMouseEnterDelay]="0.5"
              nzTooltipPlacement="bottom"
              nzTooltipTitle="Nouvelle instance pilotage"
              nzType="text"
              class="btnPrimary"
            >
              <p nz-typography>Nouveau</p>
            </button>
          </nz-col>
          <nz-col nzSpan="12">
            <button
              nz-button
              nzType="text"
              nz-tooltip
              nzBlock
              [nzTooltipMouseEnterDelay]="0.5"
              nzTooltipPlacement="bottom"
              nzTooltipTitle="Supprimler toutes les instances de pilotage"
              nz-popconfirm
              nzPopconfirmTitle="Voulez-vous supprimer toutes les instances de pilotage ?"
              nzPopconfirmPlacement="bottom"
              (nzOnConfirm)="onDeleteAllList(cadrage)"
              (nzOnCancel)="onCancel()"
            >
              <p nz-typography>Supprimer</p>
            </button>
          </nz-col>
        </nz-row>
      </ng-template>
      <cdk-virtual-scroll-viewport appendOnly itemSize="75" class="viewport">
        <ng-container>
          <nz-list-item
            *cdkVirtualFor="let item of cadrage?.pilotage"
            (click)="editingListItemShow$.next(item)"
          >
            <nz-row nzAlign="top" nzJustify="center" nzGutter="4">
              <nz-col nzSpan="18">
                <span>{{ item?.title }}</span>
                <nz-row nzAlign="top" nzGutter="2" nzJustify="start">
                  <nz-col [nzSpan]="(item?.intervenants | size) ? 6 : 0">
                    <i nz-icon nzType="user"></i>
                  </nz-col>
                  <nz-col [nzSpan]="(item?.intervenants | size) ? 6 : 0">
                    <nz-badge
                      [nzCount]="item?.intervenants | size"
                      [nzOverflowCount]="10"
                    ></nz-badge>
                  </nz-col>

                  <nz-col [nzSpan]="(item?.intervenants | size) ? 18 : 12">
                    <small>{{ item?.date | dateFr: "small" }}</small>
                  </nz-col>
                </nz-row>
              </nz-col>

              <nz-col nzSpan="4">
                <button
                  nzShape="circle"
                  nz-button
                  nz-dropdown
                  [nzDropdownMenu]="menu"
                  nzTrigger="click"
                  (click)="$event.stopPropagation()"
                >
                  <i nz-icon nzType="more"></i>
                </button>
              </nz-col>
            </nz-row>
            <nz-dropdown-menu #menu="nzDropdownMenu">
              <ul nz-menu>
                <li nz-menu-item (click)="onNew(cadrage, operation, item)">
                  <i nz-icon nzType="edit"></i>
                  <span class="dropdowMenuSpan">Modifier</span>
                </li>
                <li nz-menu-divider></li>
                <li
                  nz-menu-item
                  nzDanger
                  nz-popconfirm
                  nzPopconfirmTitle="Voulez-vous supprimer cette instance pilotage ?"
                  nzPopconfirmPlacement="bottom"
                  (nzOnConfirm)="onDeleteItem(item, cadrage)"
                  (nzOnCancel)="onCancel()"
                >
                  <i nz-icon nzType="delete"></i>
                  <span class="dropdowMenuSpan">Supprimer</span>
                </li>
              </ul>
            </nz-dropdown-menu>
          </nz-list-item>
        </ng-container>
      </cdk-virtual-scroll-viewport>
    </nz-list>
  </nz-col>
</nz-row>

<ng-template #noLivrable>
  <div class="containerEmpty">
    <div>
      <mat-icon class="iconEmpty">app_registration</mat-icon>
      <h4 nz-typography class="contentEmpty">Aucun pilotage sélectionné</h4>
    </div>
  </div>
</ng-template>
