import { Observable, of } from 'rxjs';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { OperationState } from 'src/app/components/operation/store/operation.reducer';
import * as fromOperationAction from 'src/app/components/operation/store/operation.actions';
import * as fromOperationSelector from 'src/app/components/operation/store/operation.selectors';

@Component({
  selector: 'app-operation-contacts-item',
  templateUrl: './operation-contacts-item.component.html',
  styleUrls: ['./operation-contacts-item.component.scss'],
})
export class OperationContactsItemComponent implements OnInit {
  contact$: Observable<any> = of(null);
  @Output() back = new EventEmitter<string>(false);

  constructor(private operationStore: Store<OperationState>) {}

  ngOnInit(): void {
    this.getContact();
  }

  onBack(): void {
    this.back.emit('contacts');
  }

  getContact(): void {
    this.contact$ = this.operationStore.select(fromOperationSelector.contact);
  }
}
