import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { OperationEntites } from './operation-entites.model';
import * as OperationEntitesActions from './operation-entites.actions';

export const operationEntitesFeatureKey = 'operationEntiteses';

export interface OperationEntiteState extends EntityState<OperationEntites> {
  loading: boolean;
  error: any;
  entite: any;
  suivis: any;
  queries: any;
  documents: any;
}

export const adapter: EntityAdapter<OperationEntites> =
  createEntityAdapter<OperationEntites>();

export const initialState: OperationEntiteState = adapter.getInitialState({
  loading: false,
  error: undefined,
  entite: undefined,
  suivis: undefined,
  queries: undefined,
  documents: undefined,
});

export const reducer = createReducer(
  initialState,

  on(OperationEntitesActions.addOperationEntiteSuccess, (state, action) =>
    adapter.addOne(action.entite, state)
  ),

  on(OperationEntitesActions.updateOperationEntites, (state, action) =>
    adapter.updateOne(action.operationEntites, state)
  ),

  on(OperationEntitesActions.deleteOperationEntite, (state, action) =>
    adapter.removeOne(action.entiteId, state)
  ),
  on(OperationEntitesActions.loadOperationEntitesSuccess, (state, action) =>
    adapter.setAll(action.entites, state)
  ),
  on(OperationEntitesActions.clearOperationEntites, (state) =>
    adapter.removeAll(state)
  ),

  on(OperationEntitesActions.loadingState, (state, action) => {
    return {
      ...state,
      loading: action.loading,
    };
  }),
  on(OperationEntitesActions.queryOperationEntitesSuccess, (state, action) => {
    return {
      ...state,
      queries: action.entites,
    };
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
