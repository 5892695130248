import { ParticipantId } from './../../store/participant.model';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-participant-sortie-tab',
  templateUrl: './participant-sortie-tab.component.html',
  styleUrls: ['./participant-sortie-tab.component.scss'],
})
export class ParticipantSortieTabComponent implements OnInit {
  @Input() participant: ParticipantId | any = null;
  @Output() select = new EventEmitter(false);

  constructor() {}

  ngOnInit(): void {}

  onSelect(type: string, participant: ParticipantId): void {
    this.select.emit(type);
  }
}
