import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Input,
} from '@angular/core';

@Component({
  selector: 'app-coordonnees-display',
  templateUrl: './coordonnees-display.component.html',
  styleUrls: ['./coordonnees-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoordonneesDisplayComponent implements OnInit {
  @Input() coordonnees: any;
  constructor() {}

  ngOnInit(): void {}
}
