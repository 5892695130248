<ng-container *ngIf="operation$ | async as operation">
  <form nz-form [formGroup]="moduleForm" nzLayout="vertical">
    <nz-steps [nzCurrent]="currentStep" nzSize="small">
      <nz-step nzTitle="Description"> </nz-step>
      <nz-step nzTitle="Calendrier"> </nz-step>
      <nz-step nzTitle="Objectif"> </nz-step>
    </nz-steps>
    <nz-space nzDirection="vertical"></nz-space>

    <div [ngSwitch]="currentStep">
      <div *ngSwitchCase="0">
        <nz-card [nzTitle]="cardTitleDefinition">
          <nz-row nzAlign="top" nzGutter="2" nzJustify="start">
            <nz-col nzSpan="16">
              <nz-form-item>
                <nz-form-label
                  [nzSpan]="18"
                  nzFor="title"
                  nzRequired="true"
                  nzNoColon
                >
                  Titre</nz-form-label
                >
                <nz-form-control nzErrorTip="Aucun titre renseigné">
                  <input
                    nz-input
                    nzBorderless
                    formControlName="title"
                    placeholder="Titre"
                    id="title"
                    required
                  />
                </nz-form-control>
              </nz-form-item>
            </nz-col>
            <nz-col nzSpan="8">
              <nz-form-item nzRequired="true" style="width: 100%">
                <nz-form-label [nzSpan]="24" nzFor="type" nzRequired="true"
                  >Type</nz-form-label
                >
                <nz-form-control nzErrorTip="Aucune type renseigné">
                  <nz-select
                    nzBorderless
                    [nzPlaceHolder]="'Choisir un type'"
                    [compareWith]="compareType"
                    nzShowSearch
                    *ngIf="types$ | async as types"
                    [nzDisabled]="!(types$ | async)"
                    formControlName="type"
                  >
                    <nz-option
                      *ngFor="let item of types"
                      [nzValue]="item"
                      [nzLabel]="item"
                    >
                      {{ item | titlecase }}
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </nz-col>
          </nz-row>

          <nz-row nzAlign="top" nzGutter="2" nzJustify="start">
            <nz-col nzSpan="24" formGroupName="description">
              <nz-form-item nzRequired="false" style="width: 100%">
                <nz-form-label [nzSpan]="24" nzFor="type" nzRequired="true"
                  >Description</nz-form-label
                >
                <nz-form-control>
                  <textarea
                    nz-input
                    nzBorderless
                    formControlName="definition"
                    placeholder="Description..."
                    [nzAutosize]="{ minRows: 4, maxRows: 4 }"
                  ></textarea>
                </nz-form-control>
              </nz-form-item>
            </nz-col>
          </nz-row>
        </nz-card>
        <nz-space nzSize="small"></nz-space>
        <nz-card [nzTitle]="cardTitleMap">
          <nz-row nzAlign="top" nzGutter="2" nzJustify="start">
            <nz-col nzSpan="24">
              <app-communes-france
                [updateItem]="updateAdresse"
                [borderless]="true"
                [isComplement]="false"
                [size]="'default'"
                [updateComplement]="updateAdresseComplement"
                (adresse)="getCommuneAdresse($event)"
                (complementIn)="getCommuneAdresseComplement($event)"
              ></app-communes-france>
            </nz-col>
          </nz-row>
        </nz-card>
      </div>
      <div *ngSwitchCase="1">
        <nz-card [nzTitle]="cardTitleContext">
          <nz-row nzAlign="top" nzGutter="2" nzJustify="start">
            <nz-col nzSpan="8" formGroupName="calendrier">
              <nz-form-item nzRequired="true" style="width: 100%">
                <nz-form-label [nzSpan]="24" nzFor="dateStart" nzRequired="true"
                  >Démarrage</nz-form-label
                >
                <nz-form-control
                  nzErrorTip="Aucune date de démarrage renseignée"
                >
                  <nz-date-picker
                    nzBorderless
                    [nzShowTime]="{
                      nzMinuteStep: 30,
                      nzFormat: 'HH:mm'
                    }"
                    formControlName="dateStart"
                    nzPlaceHolder="Démarrage..."
                    nzFormat="dd/MM/yyyy HH:mm"
                    (ngModelChange)="onDateStart()"
                    style="width: 100%"
                    id="dateStart"
                    required
                  >
                  </nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </nz-col>
            <nz-col nzSpan="8" formGroupName="calendrier">
              <nz-form-item nzRequired="true" style="width: 100%">
                <nz-form-label [nzSpan]="24" nzFor="duree" nzRequired="true"
                  >Durée
                </nz-form-label>
                <nz-form-control
                  nzErrorTip="Aucune durée renseignée"
                  style="width: 100%"
                >
                  <nz-input-number
                    formControlName="duree"
                    nzPlaceHolder="Durée en mois..."
                    [nzMin]="1"
                    [nzMax]="1200"
                    [nzStep]="1"
                    id="duree"
                  >
                  </nz-input-number>
                </nz-form-control>
              </nz-form-item>
            </nz-col>
            <nz-col nzSpan="8" formGroupName="calendrier">
              <nz-form-item nzRequired="true" style="width: 100%">
                <nz-form-label [nzSpan]="24" nzFor="dateEnd" nzRequired="true"
                  >Échéance</nz-form-label
                >
                <nz-form-control nzErrorTip="Aucune échéance renseignée">
                  <nz-date-picker
                    [nzShowTime]="{
                      nzMinuteStep: 30,
                      nzFormat: 'HH:mm'
                    }"
                    nzBorderless
                    nzPlaceHolder="Échéance"
                    formControlName="dateEnd"
                    nzFormat="dd/MM/yyyy HH:mm"
                    nzAllowClear="true"
                    (ngModelChange)="onDateEnd()"
                    style="width: 100%"
                    id="dateEnd"
                    required
                  >
                  </nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </nz-col>
          </nz-row>
        </nz-card>
      </div>
      <div *ngSwitchCase="2">
        <nz-card [nzTitle]="cardObjectif">
          <nz-row nzAlign="top" nzGutter="2" nzJustify="start">
            <nz-col nzSpan="2">
              <mat-icon>supervisor_account</mat-icon>
            </nz-col>
            <nz-col nzSpan="8">
              <nz-form-item>
                <nz-form-control>
                  <nz-input-number
                    [nzMin]="0"
                    formControlName="objectifPersons"
                    nzPlaceHolder="Nombre de participants"
                  >
                  </nz-input-number>
                </nz-form-control>
              </nz-form-item>
            </nz-col>
            <nz-col nzSpan="4">
              <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
            </nz-col>
            <nz-col nzSpan="2">
              <mat-icon>timer</mat-icon>
            </nz-col>
            <nz-col nzSpan="8">
              <nz-form-item>
                <nz-form-control>
                  <nz-input-number
                    [nzMin]="0"
                    formControlName="objectifHours"
                    nzPlaceHolder="Nombre d'heures d'insertion"
                  >
                  </nz-input-number>
                </nz-form-control>
              </nz-form-item>
            </nz-col>
          </nz-row>
        </nz-card>
        <nz-space nzSize="small"></nz-space>
        <nz-card [nzTitle]="cardObjectifTheoric">
          <nz-row nzAlign="top" nzGutter="2" nzJustify="start">
            <nz-col nzSpan="2">
              <mat-icon>supervisor_account</mat-icon>
            </nz-col>
            <nz-col nzSpan="8">
              <nz-form-item>
                <nz-form-control>
                  <nz-input-number
                    [nzMin]="0"
                    formControlName="objectifPersonsTheoric"
                    nzPlaceHolder="Nombre de participants"
                  >
                  </nz-input-number>
                </nz-form-control>
              </nz-form-item>
            </nz-col>
            <nz-col nzSpan="4">
              <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
            </nz-col>
            <nz-col nzSpan="2">
              <mat-icon>timer</mat-icon>
            </nz-col>
            <nz-col nzSpan="8">
              <nz-form-item>
                <nz-form-control>
                  <nz-input-number
                    [nzMin]="0"
                    formControlName="objectifHoursTheoric"
                    nzPlaceHolder="Nombre d'heures d'insertion"
                  >
                  </nz-input-number>
                </nz-form-control>
              </nz-form-item>
            </nz-col>
          </nz-row>
        </nz-card>
      </div>
      <div *ngSwitchDefault></div>
    </div>
  </form>
  <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>

  <nz-row nzAlign="middle" nzGutter="8" nzJustify="end">
    <nz-col nzSpan="12"></nz-col>
    <nz-col nzSpan="4">
      <button nz-button nzBlock nzType="text" (click)="onCancel()">
        Annuler
      </button>
    </nz-col>
    <nz-col nzSpan="4">
      <button
        nz-button
        nzBlock
        (click)="$event.stopPropagation(); onPrevStep()"
      >
        Précédent
      </button>
    </nz-col>

    <nz-col nzSpan="4">
      <button
        *ngIf="currentStep < 2"
        nz-button
        nzBlock
        (click)="$event.stopPropagation(); onNextStep()"
      >
        Suivant
      </button>

      <button
        nz-button
        nzBlock
        nzType="primary"
        (click)="onAdd(operation)"
        *ngIf="isAdd && currentStep === 2"
        [disabled]="!moduleForm.valid"
      >
        <span>Créer un </span
        ><span>{{ operation.type | operationType: "module" | lowercase }}</span>
      </button>
      <ng-container *ngIf="module$ | async as module">
        <button
          nz-button
          nzBlock
          nzType="primary"
          (click)="onUpdate(operation, module)"
          *ngIf="isUpdate && currentStep === 2"
          [disabled]="!moduleForm.valid"
        >
          Modifier
        </button>
      </ng-container>
    </nz-col>
  </nz-row>

  <ng-template #cardTitleDefinition>
    <app-header-title
      [icon]="'article'"
      [subtitle]="true"
      [title]="''"
    ></app-header-title>
  </ng-template>
  <ng-template #cardTitleMap>
    <app-header-title
      [icon]="'place'"
      [subtitle]="true"
      [title]="''"
    ></app-header-title>
  </ng-template>

  <ng-template #cardTitleDescription>
    <app-header-title
      [icon]="'article'"
      [subtitle]="true"
      [title]="''"
    ></app-header-title>
  </ng-template>

  <ng-template #cardTitleContext>
    <app-header-title
      [icon]="'article'"
      [subtitle]="true"
      [title]="''"
    ></app-header-title>
  </ng-template>

  <ng-template #cardObjectif>
    <app-header-title
      [icon]="'track_changes'"
      [subtitle]="true"
      [title]="'Objectifs engagés'"
    ></app-header-title>
  </ng-template>
  <ng-template #cardObjectifTheoric>
    <app-header-title
      [icon]="'track_changes'"
      [subtitle]="true"
      [title]="'Objectifs théoriques'"
    ></app-header-title>
  </ng-template>
</ng-container>
