<div class="w-full flex gap-x-2">
  <div [class]="isFixe ? 'w-3/12 mb-6' : 'w-6/12 mb-6'">
    <app-phone
      *ngIf="isMobile"
      [borderless]="borderless"
      [type]="'Mobile'"
      [update]="update"
      (phone)="setMobilePhone($event)"
      [required]="isMobileRequired"
    ></app-phone>
  </div>
  <div [class]="isFixe ? 'w-3/12 mb-6 ' : 'hidden'">
    <app-phone
      *ngIf="isFixe"
      [type]="'Fixe'"
      [update]="update"
      [borderless]="borderless"
      (phone)="setFixePhone($event)"
      [required]="isFixeRequired"
    ></app-phone>
  </div>
  <div class="w-6/12 mb-6">
    <app-email
      *ngIf="isEmail"
      [update]="update"
      [required]="isEmailRequired"
      [borderless]="borderless"
      (email)="setEmail($event)"
    ></app-email>
  </div>
  <div [class]="webItem ? 'w-auto mb-6 ' : 'hidden'">
    <input
      type="text"
      placeholder="Site web..."
      (keyup)="setWeb($event)"
      class="inputForm"
    />
  </div>
</div>
