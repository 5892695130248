import { Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  doc,
  getDocs,
  getDoc,
  addDoc,
  setDoc,
  deleteDoc,
} from '@angular/fire/firestore';
import { Observable, of, from, pipe } from 'rxjs';
import { switchMap, mergeMap, map } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { UserState } from '../../components/user/store/user.reducer';
import * as fromUserSelector from '../../components/user/store/user.selectors';

import { Apollo, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'any',
})
export class UtilitiesService {
  //CIVITIES
  GET_CIVILITIES_UTILITIES = gql`
    query Referentiel_civilities {
      referentiel_civilities
    }
  `;

  //COMMUNICATION
  GET_COMMUNICATION_TYPES = gql`
    query Referentiel_communication {
      referentiel_communication
    }
  `;

  //CRITERES
  GET_CRITERES_UTILITIES = gql`
    query Referentiel_criteres {
      referentiel_criteres {
        data {
          info
          item
          pieceAfournir
        }
        id
      }
    }
  `;

  //COUNTRIES
  GET_COUNTRIES_UTILITIES = gql`
    query Referentiel_countries {
      referentiel_countries {
        id
        nationalite
      }
    }
  `;

  //NUMERIQUE
  GET_NUMERIQUE_UTILITIES = gql`
    query Referentiel_numerique_level {
      referentiel_numerique_level {
        data {
          type
          items {
            item
            type
          }
        }
        id
      }
    }
  `;

  //SORTIES
  GET_SORTIES_UTILITIES = gql`
    query referentiel_sorties {
      referentiel_sorties {
        data {
          sortie {
            motif
            situation
            type
          }
          type
        }
      }
    }
  `;

  //MOBILITY
  GET_MOBILITY_UTILITIES = gql`
    query Referentiel_mobility {
      referentiel_mobility {
        info
        item
      }
    }
  `;

  //SEJOUR
  GET_SEJOUR_UTILITIES = gql`
    query Referentiel_titres_sejours {
      referentiel_titres_sejours {
        data {
          type
          cartes {
            info
            titre
            type
          }
        }
      }
    }
  `;

  //FRENCH LEVEL
  GET_FRENCH_UTILITIES = gql`
    query Referentiel_french_level {
      referentiel_french_level {
        data {
          type
          niveau {
            info
            item
            type
          }
        }
      }
    }
  `;

  //DIPLOMA
  GET_DIPLOMA_UTILITIES = gql`
    query Referentiel_diploma {
      referentiel_diploma {
        label
        value
      }
    }
  `;

  //CATEGORIE
  GET_CATEGORY_UTILITIES = gql`
    query Referentiel_category {
      referentiel_category {
        label
        value
      }
    }
  `;

  //PERIOD INSCRIPTION
  GET_PERIOD_INSCRIPTION_UTILITIES = gql`
    query Referentiel_inscription_period {
      referentiel_inscription_period {
        label
        value
      }
    }
  `;

  //ALLOCATION
  GET_ALLOCATION_UTILITIES = gql`
    query Referentiel_allocation_type {
      referentiel_allocation_type {
        label
        value
        justificatif
      }
    }
  `;

  //RESEAUX SOCIAUX
  GET_RESEAU_SOCIAUX_UTILITIES = gql`
    query Referentiel_reseaux_sociaux {
      referentiel_reseaux_sociaux
    }
  `;

  //PERMIS
  //   GET_PERMIS_UTILITIES = gql`
  //   query Referentiel_couverture_maladie {
  //     referentiel_couverture_maladie
  //   }
  // `;

  //MOBILITY GEOGRAPHIQUE
  GET_GEOGRAPHIC_MOBILITE_UTILITIES = gql`
    query referentiel_mobilite_geographique {
      referentiel_mobilite_geographique {
        codeTypeTerritoire
        libelleTypeTerritoire
      }
    }
  `;

  //COUVETURE MALADIE
  GET_COUVERTURE_MALADIE_UTILITIES = gql`
    query Referentiel_couverture_maladie {
      referentiel_couverture_maladie
    }
  `;

  //MINIMA
  GET_MINIMA_UTILITIES = gql`
    query Referentiel_minima_processes {
      referentiel_minima_processes
    }
  `;

  //SANTE
  GET_SANTE_UTILITIES = gql`
    query Referentiel_sante {
      referentiel_sante
    }
  `;

  //GENRE
  GET_GENRE_UTILITIES = gql`
    query Referentiel_genre {
      referentiel_genre
    }
  `;

  //STATUS
  GET_STATUS_UTILITIES = gql`
    query referentiel_status {
      referentiel_status
    }
  `;

  //TRANSPORT
  GET_TRANSPORT_MODE_UTILITIES = gql`
    query Referentiel_transport_mode {
      referentiel_transport_mode
    }
  `;

  //JURICIAIRE
  GET_JURICIAIRE_UTILITIES = gql`
    query referentiel_judiciaire {
      referentiel_judiciaire
    }
  `;

  //MATROMINIALE
  GET_MATRIMONIAL_UTILITIES = gql`
    query Referentiel_matrimonial {
      referentiel_matrimonial
    }
  `;

  //HANDICAP
  GET_HANDICAP_UTILITIES = gql`
    query Referentiel_handicap {
      referentiel_handicap
    }
  `;

  //LEVEL NUMERIQUE
  GET_LEVEL_BUREAUTIQUE_UTILITIES = gql`
    query Referentiel_levelNumerique {
      referentiel_levelNumerique
    }
  `;

  //SOFTSKILLS
  GET_SOFTSKILLS_LIST = gql`
    query GetSoftSkills {
      getSoftSkills {
        skills {
          summary
          details
        }
      }
    }
  `;

  //DIAGNOSTIC
  GET_DIAGNOSTICS_TYPES = gql`
    query Referentiel_diagnostic_types {
      referentiel_diagnostic_types {
        label
        value
      }
    }
  `;

  GET_PARTICIPANT_UTILITIES = gql`
    query AidesEligiblesFilters {
      aidesEligiblesFilters {
        transport_mode
        status
        reseaux_sociaux
        experience_souhait {
          CODE
          NAME
        }
        duree_contrat {
          CODE
          NAME
        }
        nature_contrat {
          CODE

          NAME
        }
        type_contrat {
          CODE

          NAME
        }
        type_salaire {
          CODE
          NAME
        }
        poste_qualication {
          CODE
          NAME
        }
        condition_travail {
          CODE
          NAME
        }
        type_horaire {
          CODE

          NAME
        }
        # domaine_formation {
        #   CODE
        #   NAME
        # }
        complement_salaire {
          CODE
          NAME
        }
        mobilite_geographique {
          CODE
          NAME
        }
        deplacement_professionnel {
          CODE
          NAME
        }
        deplacemenet_zone {
          NAME
          CODE
        }

        avantages {
          CODE
          NAME
        }
        frequances_deplacements {
          CODE
          NAME
        }
        types_deplacements {
          CODE
          NAME
        }
        qualification {
          NAME
          CODE
        }
        experience {
          NAME
          CODE
        }
        couverture_maladie
      }
    }
  `;

  GET_PARTICIPANT_UTILITIES_IDENTITY = gql`
    query AidesEligiblesFilters {
      aidesEligiblesFilters {
        genre {
          data
        }
        civility {
          data
        }
        communication {
          data
        }
        status {
          data
        }
        reseaux_sociaux {
          data
        }
        countries {
          data {
            nationalite
          }
        }

        titres_sejours {
          data {
            type
            cartes {
              titre
              type
              info
            }
          }
        }
        french_level {
          data {
            niveau {
              info
              item
              type
            }
            type
          }
        }
        transport_mode {
          data
        }

        deplacemenet_zone {
          NAME
          CODE
        }
        permis {
          NAME
          CODE
        }
        diploma {
          data {
            value
            label
          }
        }
        inscription_period {
          data {
            value
            label
          }
        }
        allocation_type {
          data {
            value
            label
          }
        }
        sante {
          data
        }
        couverture_maladie {
          data
        }
        matrimonial {
          data
        }

        mobility {
          data {
            info
            item
          }
        }
        judiciaire {
          data
        }

        numerique_level {
          data {
            type
            items {
              item
              type
            }
          }
        }
        outils_bureautique {
          CODE
          NAME
        }
        bureautique_level {
          NAME
          CODE
        }
        criteres {
          data {
            info
            item
          }
        }
      }
    }
  `;

  GET_PARTICIPANT_UTILITIES_SITUATION = gql`
    query AidesEligiblesFilters {
      aidesEligiblesFilters {
        titres_sejours {
          data {
            type
            cartes {
              titre
              type
              info
            }
          }
        }
        french_level {
          data {
            niveau {
              info
              item
              type
            }
            type
          }
        }
        transport_mode {
          data
        }
        mobilite_geographique {
          CODE
          NAME
          _id
        }
        permis {
          NAME
          CODE
          _id
        }
        diploma {
          data {
            value
            label
          }
        }
        inscription_period {
          data {
            value
            label
          }
          id
        }
        allocation_type {
          data {
            value
            label
          }
          id
        }
        sante {
          data
          id
        }
        couverture_maladie {
          data
          id
        }
        matrimonial {
          data
          id
        }

        mobility {
          data {
            info
            item
          }
          id
        }
        judiciaire {
          data
          id
        }
      }
    }
  `;

  //MISSION CONTRAT TYPES

  GET_MISSION_CONTRAT_UTILITIES = gql`
    query Experience_souhait {
      missionContratFilters {
        experience_souhait {
          NAME
          CODE
        }
        duree_contrat {
          NAME
          CODE
        }
        nature_contrat {
          CODE
          NAME
        }
        type_contrat {
          CODE
          NAME
        }
        condition_travail {
          NAME
          CODE
        }
        type_horaire {
          NAME
          CODE
        }
        complement_salaire {
          NAME
          CODE
        }
        type_salaire {
          NAME
          CODE
        }
      }
    }
  `;

  //MISSION CONTRAT DEPLACEMENT
  GET_MISSION_CONTRAT_DEPLACEMENT_UTILITIES = gql`
    query Permis {
      missionDeplacementFilters {
        permis {
          NAME
          CODE
        }
        outils_bureautique {
          NAME
          CODE
        }
        bureautique_level {
          NAME
          CODE
        }
        mobilite_geographique {
          NAME
          CODE
        }
        deplacement_professionnel {
          NAME
          CODE
        }
        deplacemenet_zone {
          NAME
          CODE
        }
        experience {
          NAME
          CODE
        }
        frequances_deplacements {
          CODE
          NAME
        }
        types_deplacements {
          NAME
          CODE
        }
        qualification {
          NAME
          CODE
        }
      }
    }
  `;

  //OPERATION MARCHES TYPES
  GET_OPERATION_MARCHE_TYPES = gql`
    query OperationType {
      operationType {
        types
      }
    }
  `;

  //OPERATION OBJECTIFS TYPES
  GET_OPERATION_OBJECTIF_TYPES = gql`
    query ObjectifType {
      objectifType {
        objectifs
      }
    }
  `;

  constructor(
    private db: Firestore,
    private apollo: Apollo,
    private userStore: Store<UserState>
  ) {}

  getAll(): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (user?.claims?.dev) {
            const utilCollection = collection(this.db, `utility`);
            return from(
              getDocs(utilCollection)
                .then((snap) => {
                  return snap.docs.map((doc) => {
                    const data = doc.data() as any;
                    const id = doc.id;
                    return { id, ...data };
                  });
                })
                .catch((error) => error)
            ).pipe(mergeMap((res) => of(res)));
          } else if (
            user?.claims?.admin ||
            user?.claims?.manager ||
            user?.claims?.member
          ) {
            const utilCollection = collection(this.db, `utility`);

            return from(
              getDocs(utilCollection)
                .then((snap) => {
                  return snap.docs.map((doc) => {
                    const data = doc.data() as any;
                    const id = doc.id;
                    return { id, ...data };
                  });
                })
                .catch((error) => error)
            ).pipe(mergeMap((res) => of(res)));
          } else {
            return of([]);
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }
  //PARTICIPANT

  //CIVILITIES
  getCivilitiesUtilities(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_CIVILITIES_UTILITIES,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  //CRITERE
  getCriteresUtilities(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_CRITERES_UTILITIES,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  //COMMUNICATION
  getCommunicationUtilities(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_COMMUNICATION_TYPES,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }
  //COUNTRIES
  getCountriesUtilities(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_COUNTRIES_UTILITIES,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  //NUMERIQUE
  getNumeriqueUtilities(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_NUMERIQUE_UTILITIES,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  //SORTIES
  getSortieUtilities(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_SORTIES_UTILITIES,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  //MOBILITY
  getMobilityUtilities(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_MOBILITY_UTILITIES,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  //SEJOUR
  getSejourUtilities(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_SEJOUR_UTILITIES,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  //FRENCH LEVEL
  getFrenchLevelUtilities(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_FRENCH_UTILITIES,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  //DIPLOMA
  getDiplomaUtilities(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_DIPLOMA_UTILITIES,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  //CATEGORIE
  getCategorieUtilities(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_CATEGORY_UTILITIES,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  //PERIOD INSCRIPTION
  getPeriodInscriptionUtilities(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_PERIOD_INSCRIPTION_UTILITIES,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  //ALLOCATION
  getAllocationUtilities(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_ALLOCATION_UTILITIES,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  //RESEAUX SOCIAUX
  getReseauSociauxUtilities(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_RESEAU_SOCIAUX_UTILITIES,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  //COUVETURE MALADIE
  getCouvertureMaladieUtilities(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_COUVERTURE_MALADIE_UTILITIES,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  //MINIMA
  getMinimaSociauxUtilities(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_MINIMA_UTILITIES,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  //SANTE
  getSanteUtilities(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_SANTE_UTILITIES,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  //GENRE
  getGenreUtilities(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_GENRE_UTILITIES,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  //STATUS
  getStatusUtilities(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_STATUS_UTILITIES,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  //TRANSPORT
  getTransportUtilities(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_TRANSPORT_MODE_UTILITIES,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  //GEOGRAPHIQUE MOBILITE
  getGeographiqueMobiliteUtilities(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_GEOGRAPHIC_MOBILITE_UTILITIES,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  //JURICIAIRE
  getJudiciaireUtilities(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_JURICIAIRE_UTILITIES,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  //MATROMINIALE
  getMatrimonialeUtilities(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_MATRIMONIAL_UTILITIES,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  //HANDICAP
  gethandicapUtilities(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_HANDICAP_UTILITIES,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  //LEVEL NUMERIQUE
  getLevelBureautiqueUtilities(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_LEVEL_BUREAUTIQUE_UTILITIES,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  //SOFTSKILLS
  getSoftskillsUtilities(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_SOFTSKILLS_LIST,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  //**DIAGNOSTIC**
  getDiagnosticTypes(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_DIAGNOSTICS_TYPES,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  //**PARTICIPANT */
  getParticipantUtilities(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_PARTICIPANT_UTILITIES,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  getParticipantUtilitiesIdentity(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_PARTICIPANT_UTILITIES_IDENTITY,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  getParticipantUtilitiesSituation(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_PARTICIPANT_UTILITIES_SITUATION,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  //ENTITE
  getEntiteUtilities(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_PARTICIPANT_UTILITIES,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  //CONTACT
  getContactUtilities(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_PARTICIPANT_UTILITIES,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  getProjetUtilities(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_PARTICIPANT_UTILITIES,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  //**MISSION**
  getMissionUtilities(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_MISSION_CONTRAT_UTILITIES,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }
  getMissionContratDeplacementUtilities(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_MISSION_CONTRAT_DEPLACEMENT_UTILITIES,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  // **REFERENTIELS OPERATION**

  //OPERATION MARCHES TYPES
  getOperationMarcheTypes(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_OPERATION_MARCHE_TYPES,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  getOperationObjectifTypes(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_OPERATION_OBJECTIF_TYPES,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }
}
