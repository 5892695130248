import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  navigationsFeatureKey,
  selectAll,
  NavigationState,
} from './navigation.reducer';

export const selectTicketState = createFeatureSelector<NavigationState>(
  navigationsFeatureKey
);

export const messageDrawer = createSelector(
  selectTicketState,
  (state: NavigationState) => state.messageDrawer
);
