import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  OperationFicheDePosteState,
  operationFicheDePostesFeatureKey,
  selectAll,
} from './operation-fiche-de-poste.reducer';

export const selectOperationFicheDePosteState =
  createFeatureSelector<OperationFicheDePosteState>(
    operationFicheDePostesFeatureKey
  );

export const fiches = createSelector(
  selectOperationFicheDePosteState,
  selectAll
);

export const fiche = createSelector(
  selectOperationFicheDePosteState,
  (state: OperationFicheDePosteState) => state.fiche
);

export const loading = createSelector(
  selectOperationFicheDePosteState,
  (state: OperationFicheDePosteState) => state.loading
);

export const loadingItem = createSelector(
  selectOperationFicheDePosteState,
  (state: OperationFicheDePosteState) => state.loadingItem
);

export const error = createSelector(
  selectOperationFicheDePosteState,
  (state: OperationFicheDePosteState) => state.error
);
