import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'any',
})
export class QueryParticipantPlanningFilterMonthTypeGQL extends Query<Response> {
  document = gql`
    query ParticipantPlanningByMonthYearType(
      $id: ID!
      $filter: PlanningFilterInput!
    ) {
      participantPlanningByMonthYearType(id: $id, filter: $filter) {
        id
        type {
          label
          color
          colorLabel
        }
        content
        createAt
        dateStart
        referent {
          avatar
          displayName
          fonction
          setting {
            color
          }
        }
        auteur {
          avatar
          displayName
          fonction
          setting {
            color
          }
        }
      }
    }
  `;
}
