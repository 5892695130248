import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Ticket, TicketId } from './ticket.model';

export const loadTickets = createAction('[Ticket/Component] Load Tickets');

export const loadTicketsSuccess = createAction(
  '[Ticket/Effect] Load Tickets Success',
  props<{ tickets: TicketId[] }>()
);
export const loadTicketsFailure = createAction(
  '[Ticket/Effect] Load Tickets Failure',
  props<{ error: any }>()
);

export const addTicket = createAction(
  '[Ticket/Component] Add Ticket',
  props<{ ticket: Ticket }>()
);

export const addTicketSuccess = createAction(
  '[Ticket/Effect] Add Ticket Success',
  props<{ ticket: TicketId }>()
);

export const addTicketFailure = createAction(
  '[Ticket/Effect] Add Ticket Failure',
  props<{ error: any }>()
);

export const updateTicket = createAction(
  '[Ticket/Component] Update Ticket',
  props<{ ticket: Update<TicketId> }>()
);

export const updateTicketSuccess = createAction(
  '[Ticket/Effect] Update Ticket Success',
  props<{ ticket: TicketId }>()
);

export const updateTicketFailure = createAction(
  '[Ticket/Effect] Update Ticket Failure',
  props<{ error: any }>()
);

export const deleteTicket = createAction(
  '[Ticket/Component] Delete Ticket',
  props<{ id: string }>()
);

export const deleteTicketSuccess = createAction(
  '[Ticket/Effect] Delete Ticket Success'
);

export const deleteTicketFailure = createAction(
  '[Ticket/Effect] Delete Ticket Failure',
  props<{ error: any }>()
);

export const deleteTickets = createAction(
  '[Ticket/Effect] Delete Tickets',
  props<{ ids: string[] }>()
);

export const loadingTickets = createAction(
  '[Ticket/Effect] Loading Tickets',
  props<{ loader: boolean }>()
);

export const displayTickets = createAction(
  '[Ticket/Component] Display Tickets',
  props<{ display: boolean }>()
);
