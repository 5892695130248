<ng-container *ngIf="operation$ | async as operation">
  <div id="operationGanttContainer" #operationGanttContainer>
    <nz-page-header>
      <nz-page-header-extra>
        <nz-button-group nzSize="small">
          <button nz-button nzSize="small" (click)="on_view_change('Half Day')">
            <small>Demi-journée</small>
          </button>
          <button nz-button nzSize="small" (click)="on_view_change('Day')">
            <small>Journée</small>
          </button>
          <button nz-button nzSize="small" (click)="on_view_change('Week')">
            <small>Semaine</small>
          </button>
          <button nz-button nzSize="small" (click)="on_view_change('Month')">
            <small>Mois</small>
          </button>
          <button nz-button nzSize="small" (click)="on_view_change('Year')">
            <small>Année</small>
          </button>
          <button nz-button nzSize="small" (click)="fullScreen($event)">
            <i nz-icon nzTheme="outline" nzType="fullscreen"></i>
          </button>
          <!-- <button
              nz-button
              nzSize="small"
              nz-tooltip
              nzTooltipTitle
              nzTooltipTitle="Exporter le gantt"
              nzTooltipPlacement="bottom"
            >
              <i nz-icon nzTheme="outline" nzType="download"></i>
            </button> -->
        </nz-button-group>
      </nz-page-header-extra>
    </nz-page-header>

    <svg id="gantt" #gantt></svg>
  </div>

  <nz-modal
    id="markerText"
    style="display: none"
    nzTitle="Nouveau module"
    nzWidth="300"
  >
    <input nz-input id="inputModal" nzSize="small" />
    <!-- title : {{ newData?.content }} début : {{ newData?.start }} -->
    <!-- <nz-date-picker
      *ngIf="newData?.start"
      [(ngModel)]="newData.start"
    ></nz-date-picker> -->

    <div *nzModalFooter>
      <nz-button-group>
        <button nz-button nzType="primary">Ajouter</button>
        <button nz-button>Ignorer</button>
      </nz-button-group>
    </div>
  </nz-modal>
</ng-container>
