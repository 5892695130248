<div class="w-screen h-screen divider-y p-4 m-2">
  <div class="w-full flex items-start gap-x-4">
    <div *ngIf="new" class="item">
      <ng-container *ngTemplateOutlet="new"></ng-container>
    </div>

    <div
      *ngFor="let item of cardSource; index as i; trackBy: trackByFn"
      class="item"
    >
      <ng-container
        *ngTemplateOutlet="card; context: { $implicit: item, idx: i }"
      >
      </ng-container>
    </div>
  </div>
  <div class="w-full h-screen mt-2 mb-2">
    <nz-table
      #layoutTable
      [nzData]="tableSource"
      nzTableLayout="fixed"
      *ngIf="tableSource?.length; else noTableData"
    >
      <thead>
        <ng-container *ngTemplateOutlet="tableHeader"> </ng-container>
      </thead>
      <tbody class="w-full">
        <cdk-virtual-scroll-viewport
          itemSize="25"
          class="viewport"
          [ngStyle]="{ height: height }"
        >
          <tr
            *cdkVirtualFor="
              let item of layoutTable.data;
              index as i;
              trackBy: trackByFn
            "
            class="w-full"
          >
            <ng-container
              *ngTemplateOutlet="tableRow; context: { $implicit: item, idx: i }"
            >
            </ng-container>
          </tr>
        </cdk-virtual-scroll-viewport>
      </tbody>
    </nz-table>
  </div>
</div>

<ng-template #noTableData>
  <app-no-result [description]="description" [isButton]="false"></app-no-result>
</ng-template>

<ng-template #skeltonCard>
  <nz-skeleton [nzActive]="true"></nz-skeleton>
</ng-template>
