import { OrganisationHeaderComponent } from './../../../organisation/components/organisation-header/organisation-header.component';
import { PortalNavigationService } from './../../../../portals/portal-navigation.service';
import { takeUntil } from 'rxjs/operators';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, BehaviorSubject, Subject } from 'rxjs';
import { UserState } from '../../store/user.reducer';
import * as fromUserSelector from '../../store/user.selectors';
import * as fromUserAction from '../../store/user.actions';
import { OrganisationId } from 'src/app/components/organisation/store/organisation.model';
import { NzModalService } from 'ng-zorro-antd/modal';
import { UserFormComponent } from '../user-form/user-form.component';
import { ComponentPortal } from '@angular/cdk/portal';
@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersListComponent implements OnInit, OnChanges, OnDestroy {
  subscribe = new Subject();
  selectedUser$ = new BehaviorSubject<any>(null);
  user$: Observable<any> = of(null);
  users$: Observable<any> = of([]);
  avatarBackground: string = '#bfbfbf';
  modalWidth: number = 950;
  loading$: Observable<boolean> = of(false);

  avatarColor: string = 'white';
  title: string = 'Nouvel utilisateur';
  view$ = new BehaviorSubject<string>('list');
  newCardDefinition: string =
    "Un utilisateur est un collaborateur de l'organisation ayant un accès à la solution Protein";
  newDefinition: string = 'Ajouter un utilisateur';
  noTitle: string = 'Aucune utilisateur ajouté';
  componentPortalFilter: ComponentPortal<OrganisationHeaderComponent> | any;

  @ViewChild('userTitleForm', { static: false }) userTitleForm:
    | TemplateRef<any>
    | any;

  @Input() type: string = 'organisation';
  @Output() select: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    private userStore: Store<UserState>,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private portalNavigationService: PortalNavigationService
  ) {}

  ngOnInit(): void {
    this.getUser();
    this.getUsers();
    this.setFilterPortal();
  }
  ngOnChanges(changes: SimpleChanges): void {}
  setFilterPortal(): void {
    this.portalNavigationService.setPortal(this.componentPortalFilter);
  }

  getUser(): void {
    this.user$ = this.userStore.select(fromUserSelector.user);
  }

  getUsers(): void {
    this.users$ = this.userStore.select(fromUserSelector.team);
  }

  loadUsers(): void {
    this.userStore.dispatch(fromUserAction.loadUsers());
  }

  onSelect(user: any): any {
    if (!user) {
      return null;
    } else {
      this.select.emit(user);
      this.selectedUser$.next(user);
      this.view$.next('details');
    }
  }

  onBack(): void {
    this.view$.next('list');
  }

  onNew(currentUser: any, type: string): any {
    const alertMessage: string = "Vous n'avez pas accès à cette fonctionnalité";
    if (!currentUser) return;

    const modal = this.modal.create({
      nzContent: UserFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.userTitleForm,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });
    const instance = modal.getContentComponent();
    instance.currentUser = currentUser;
    instance.loadUtilityIdentity();
    instance.onLoadOrganisations(currentUser);

    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
    instance.add.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        this.onAdd(event);
        modal.close();
      }
    });
    instance.update.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
    instance.delete.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
    instance.activate.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
  }

  onAdd(user: any): void {
    if (!user) return;

    const createType: string = 'PAID';
    this.userStore.dispatch(
      fromUserAction.addUser({ newuser: user, createType })
    );
  }
  onUpdate(user: any, organisation: OrganisationId): void {
    if (!user || !organisation) return;
  }

  cancel(): any {
    return null;
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }
}
