import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaskFormComponent } from './components/task-form/task-form.component';
import { TaskHeaderComponent } from './components/task-header/task-header.component';
import { TaskItemComponent } from './components/task-item/task-item.component';
import { TaskKanbanComponent } from './components/task-kanban/task-kanban.component';
import { ZorroModule } from 'src/app/css/zorro.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipeModule } from 'src/app/pipe/pipe.module';
import { DirectiveModule } from 'src/app/directive/directive.module';
import { ContentsModule } from '../../contents/contents.module';
import { MaterialModule } from 'src/app/css/material.module';
import { StoreModule } from '@ngrx/store';
import * as fromTask from './store/task.reducer';
import { EffectsModule } from '@ngrx/effects';
import { TaskEffects } from './store/task.effects';
import { UserModule } from 'src/app/components/user/user.module';
import { ButtonsModule } from '../../contents/components/buttons/buttons.module';
import { MetiersModule } from '../metiers/metiers.module';
import { HeaderModule } from '../../contents/components/header-title/header.module';
import { TextEdithingModule } from 'src/app/features/text-edithing/text-edithing.module';
import { AvatarModule } from '../avatar/avatar.module';

@NgModule({
  declarations: [
    TaskFormComponent,
    TaskHeaderComponent,
    TaskItemComponent,
    TaskKanbanComponent,
  ],
  imports: [
    CommonModule,
    ZorroModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    PipeModule,
    DirectiveModule,
    ContentsModule,
    UserModule,
    ButtonsModule,
    MetiersModule,
    HeaderModule,
    TextEdithingModule,
    AvatarModule,
    StoreModule.forFeature(fromTask.tasksFeatureKey, fromTask.reducer),
    EffectsModule.forFeature([TaskEffects]),
  ],
  exports: [
    TaskFormComponent,
    TaskHeaderComponent,
    TaskItemComponent,
    TaskKanbanComponent,
  ],
})
export class TaskModule {}
