import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, of, lastValueFrom, from } from 'rxjs';
import { ParticipantAccompagnementState } from '../../../components/participant-actions-accompagnement/store/participant-action-accompagnement.reducer';
import * as fromParticipantAccompagnementSelector from '../../../components/participant-actions-accompagnement/store/participant-action-accompagnement.selectors';
import * as fromParticipantAccompagnementAction from '../../../components/participant-actions-accompagnement/store/participant-action-accompagnement.actions';

@Injectable({
  providedIn: 'root',
})
export class ParticipantActionsResolverService {
  constructor(
    private participantActionAccompagnementStore: Store<ParticipantAccompagnementState>,
    private router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    let id = route.parent?.params['id'];

    let find = lastValueFrom(this.loadParticipantActions(id))
      .then(() => {
        return this.getParticipantActions();
      })
      .catch(() => {
        this.router.navigate([`/participants/:${id}`]);
        return EMPTY;
      });

    return from(find);
  }

  getParticipantActions(): Observable<any> {
    return this.participantActionAccompagnementStore.select(
      fromParticipantAccompagnementSelector.accompagnments
    );
  }

  loadParticipantActions(id: string): Observable<any> {
    return of(
      this.participantActionAccompagnementStore.dispatch(
        fromParticipantAccompagnementAction.loadParticipantActionAccompagnements(
          { id }
        )
      )
    );
  }
}
