import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, of, lastValueFrom, from } from 'rxjs';
import { ParticipantSuiviState } from 'src/app/components/participant-suivi/store/participant-suivi.reducer';
import * as fromParticipantSuiviAction from 'src/app/components/participant-suivi/store/participant-suivi.actions';
import * as fromParticipantSuiviSelector from 'src/app/components/participant-suivi/store/participant-suivi.selectors';

@Injectable({
  providedIn: 'root',
})
export class ParticipantSuivisResolverService {
  constructor(
    private participantSuiviStore: Store<ParticipantSuiviState>,
    private router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    let id = route.parent?.params['id'];

    let find = lastValueFrom(this.loadParticipantSuivis(id))
      .then(() => {
        return this.getParticipantSuivis();
      })
      .catch(() => {
        this.router.navigate(['/participant', id]);
        return EMPTY;
      });

    return from(find);
  }

  getParticipantSuivis(): Observable<any> {
    return this.participantSuiviStore.select(
      fromParticipantSuiviSelector.suivis
    );
  }

  loadParticipantSuivis(id: string): Observable<any> {
    return of(
      this.participantSuiviStore.dispatch(
        fromParticipantSuiviAction.loadParticipantSuivis({ id })
      )
    );
  }
}
