import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { EntiteTask } from './entite-task.model';
import * as EntiteTaskActions from './entite-task.actions';

export const entiteTasksFeatureKey = 'entiteTasks';

export interface EntiteTaskState extends EntityState<EntiteTask> {
  loading: boolean;
  isFiltered: boolean;
  task: any;
  error: any;
}

export const adapter: EntityAdapter<EntiteTask> =
  createEntityAdapter<EntiteTask>();

export const initialState: EntiteTaskState = adapter.getInitialState({
  loading: false,
  isFiltered: false,
  task: undefined,
  error: undefined,
});

export const reducer = createReducer(
  initialState,
  // LOADING
  on(EntiteTaskActions.loadingEntiteTasks, (state, action) => {
    return {
      ...state,
      loading: action.loading,
      isFiltered: false,
      error: undefined,
    };
  }),

  //FILTER INDICATOR
  on(EntiteTaskActions.filterEntiteTasks, (state, action) => {
    return {
      ...state,
      isFiltered: action.filter,
    };
  }),
  //LOAD ALL
  on(EntiteTaskActions.loadEntiteTasks, (state, action) => {
    return {
      ...state,
      loading: false,
      isFiltered: false,
      error: undefined,
    };
  }),
  on(EntiteTaskActions.loadEntiteTasksSuccess, (state, action) =>
    adapter.setAll(action.tasks, state)
  ),
  on(EntiteTaskActions.loadEntiteTasksFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      isFiltered: false,
      error: action.error,
    };
  }),

  //LOAD MY ALL
  on(EntiteTaskActions.loadEntiteMyTasks, (state, action) => {
    return {
      ...state,
      loading: false,
      isFiltered: true,
      error: undefined,
    };
  }),
  on(EntiteTaskActions.loadEntiteMyTasksSuccess, (state, action) =>
    adapter.setAll(action.tasks, state)
  ),
  on(EntiteTaskActions.loadEntiteMyTasksFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  }),

  //LOAD DAILY ALL
  on(EntiteTaskActions.loadEntiteDailyTasks, (state, action) => {
    return {
      ...state,
      loading: false,
      isFiltered: true,
      error: undefined,
    };
  }),
  on(EntiteTaskActions.loadEntiteDailyTasksSuccess, (state, action) =>
    adapter.setAll(action.tasks, state)
  ),
  on(EntiteTaskActions.loadEntiteDailyTasksFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  }),

  //LOAD WEEKLYALL
  on(EntiteTaskActions.loadEntiteWeeklyTasks, (state, action) => {
    return {
      ...state,
      loading: false,
      isFiltered: true,
      error: undefined,
    };
  }),
  on(EntiteTaskActions.loadEntiteWeeklyTasksSuccess, (state, action) =>
    adapter.setAll(action.tasks, state)
  ),
  on(EntiteTaskActions.loadEntiteWeeklyTasksFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  }),

  //LOAD MONTHLY ALL
  on(EntiteTaskActions.loadEntiteMonthlyTasks, (state, action) => {
    return {
      ...state,
      loading: false,
      isFiltered: true,
      error: undefined,
    };
  }),

  on(EntiteTaskActions.loadEntiteMonthlyTasksSuccess, (state, action) =>
    adapter.setAll(action.tasks, state)
  ),
  on(EntiteTaskActions.loadEntiteMonthlyTasksFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  }),

  //LOAD ONE

  //ADD
  on(EntiteTaskActions.addEntiteTaskSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      isFiltered: false,
      error: undefined,
    };
  }),
  on(EntiteTaskActions.addEntiteTaskFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      isFiltered: false,
      error: action.error,
    };
  }),

  // UPDATE TASK
  on(EntiteTaskActions.updateEntiteTaskSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      isFiltered: false,
    };
  }),

  on(EntiteTaskActions.updateEntiteTaskFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      isFiltered: false,
      error: action.error,
    };
  }),

  // DELETE TASK
  on(EntiteTaskActions.deleteEntiteTask, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(EntiteTaskActions.deleteEntiteTaskSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      isFiltered: false,
    };
  }),
  on(EntiteTaskActions.deleteEntiteTaskFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      isFiltered: false,

      error: action.error,
    };
  }),

  // DELETE ALL TASKS
  on(EntiteTaskActions.deleteEntiteTasks, (state, action) =>
    adapter.removeAll(state)
  ),
  on(EntiteTaskActions.deleteEntiteTasksSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      isFiltered: false,

      error: undefined,
    };
  }),
  on(EntiteTaskActions.deleteEntiteTasksFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      isFiltered: false,

      error: action.error,
    };
  }),

  //CLEAR
  on(EntiteTaskActions.clearEntiteTasks, (state) => adapter.removeAll(state))
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
