import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { OperationDashboard } from './operation-dashboard.model';
import * as OperationDashboardActions from './operation-dashboard.actions';

export const operationDashboardsFeatureKey = 'operationDashboards';

export interface OperationDashboardState extends EntityState<any> {
  loading: boolean;
  dashboard: any;
  error: any;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>();

export const initialState: OperationDashboardState = adapter.getInitialState({
  loading: false,
  dashboard: undefined,
  error: undefined,
});

export const reducer = createReducer(
  initialState,
  on(OperationDashboardActions.loadOperationDashboard, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(
    OperationDashboardActions.loadOperationDashboardSuccess,
    (state, action) => {
      return {
        ...state,
        dashboard: action.dashboard,
        loading: false,
      };
    }
  ),
  on(
    OperationDashboardActions.loadOperationDashboardFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
      };
    }
  ),
  on(OperationDashboardActions.clearOperationDashboards, (state) =>
    adapter.removeAll(state)
  )
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
