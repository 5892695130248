import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'any',
})
export class QueryEntitesSearchServiceGQL extends Query<Response> {
  document = gql`
    query EntreprisesUniteLegale($search: EtablissementQueryInput!) {
      entreprisesUniteLegale(search: $search) {
        etablissements {
          siret
          etablissementSiege
          nic
          siren
          trancheEffectifsEtablissement
          adresseEtablissement {
            codeCedexEtablissement
            codeCommuneEtablissement
            codePostalEtablissement
            fullAdresse
            libelleCedexEtablissement
            libelleCommuneEtablissement
            libelleVoieEtablissement
            typeVoieEtablissement
            numeroVoieEtablissement
            commune {
              departement {
                nom
              }
              region {
                nom
              }
            }
          }
          uniteLegale {
            activitePrincipaleUniteLegale
            categorieEntreprise
            categorieJuridiqueUniteLegale
            activite_principale_libelle {
              code_naf
              intitule_naf
            }
            denominationUniteLegale
            economieSocialeSolidaireUniteLegale
            identifiantAssociationUniteLegale
            nicSiegeUniteLegale
            nomenclatureActivitePrincipaleUniteLegale
            societeMissionUniteLegale
            trancheEffectifsUniteLegale
            categorie_juridique_libelle {
              code
              libelle
            }
          }
        }
      }
    }
  `;
}
