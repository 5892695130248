import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NzProgressFormatter } from 'ng-zorro-antd/progress';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-progression',
  templateUrl: './progression.component.html',
  styleUrls: ['./progression.component.scss'],
})
export class ProgressionComponent implements OnInit {
  progressionValue: number[] = [
    0, 0.1, 0.15, 0.25, 0.3, 0.4, 0.45, 0.5, 0.65, 0.75, 0.8, 0.85, 0.9, 1,
  ];

  progressionSelected$ = new BehaviorSubject<number>(0);
  formatTemps: NzProgressFormatter = (percent: number) => `${percent}%`;
  formatObjectif: NzProgressFormatter = (percent: number) => `${percent}%`;

  @Input() source: any;
  @Input() start: any;
  @Input() end: any;
  @Input() progression: number = 0;

  @Output() select = new EventEmitter<number>();
  constructor() {}

  ngOnInit(): void {}

  onChangeProgression(event: any): void {
    this.progressionSelected$.next(event);
    this.select.emit(event);
  }
}
