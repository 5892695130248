<ng-container *ngIf="cadrage">
  <nz-row nzAlign="top" nzJustify="center" nzGutter="4">
    <nz-col nzSpan="15">
      <div *ngIf="!(editing$ | async)">
        <nz-card
          class="livrableDetails"
          nzSize="small"
          [nzTitle]="livrableItemTitlte"
          nzBorderless
          *ngIf="
            editingListItemShow$ | async as editingListItemShow;
            else noLivrable
          "
        >
          <ng-template #livrableItemTitlte>
            <nz-row nzAlign="top" nzJustify="start" nzGutter="2">
              <nz-col nzSpan="24">
                <h5 nz-typography>{{ editingListItemShow?.title }}</h5>
              </nz-col>
            </nz-row>
            <nz-row nzAlign="top" nzJustify="start" nzGutter="2">
              <nz-col nzSpan="2">
                <mat-icon class="iconSujetItem">event</mat-icon>
              </nz-col>
              <nz-col nzSpan="22">
                <small>{{
                  editingListItemShow?.echeance | dateFr: "small"
                }}</small>
              </nz-col>
            </nz-row>
          </ng-template>
          <nz-descriptions nzSize="small" nzBordered>
            <nz-descriptions-item nzSpan="3" nzTitle="Pilote | Contributeur">
              {{ editingListItemShow?.pilote }}
            </nz-descriptions-item>

            <nz-descriptions-item nzSpan="3" [nzTitle]="itemDescription">
              <p>
                {{ editingListItemShow?.description }}
              </p>
            </nz-descriptions-item>
          </nz-descriptions>
          <ng-template #itemDescription>
            <mat-icon class="iconSujetItem">subject</mat-icon>
          </ng-template>
        </nz-card>
      </div>
    </nz-col>
    <nz-col nzSpan="1">
      <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
    </nz-col>
    <nz-col nzSpan="8">
      <nz-list [nzHeader]="livrableHeader" [nzBordered]="false" nzSize="small">
        <ng-template #livrableHeader>
          <nz-row nzJustify="start" nzAlign="middle" nzGutter="4">
            <nz-col nzSpan="12">
              <button
                nz-button
                nzBlock
                (click)="onNewLivrable(cadrage)"
                nz-tooltip
                [nzTooltipMouseEnterDelay]="0.5"
                nzTooltipPlacement="bottom"
                nzTooltipTitle="Nouveau livrable"
                nzType="text"
                class="btnPrimary"
              >
                <p nz-typography>Nouveau</p>
              </button>
            </nz-col>
            <nz-col nzSpan="12">
              <button
                nz-button
                nzBlock
                nzType="text"
                nz-tooltip
                [nzTooltipMouseEnterDelay]="0.5"
                nzTooltipPlacement="bottom"
                nzTooltipTitle="Supprimler tous les livrables"
                nz-popconfirm
                nzPopconfirmTitle="Voulez-vous supprimer tous les livrables ?"
                nzPopconfirmPlacement="bottom"
                (nzOnConfirm)="onDeleteAllList(cadrage)"
                (nzOnCancel)="onCancel()"
              >
                <p nz-typography>Supprimer</p>
              </button>
            </nz-col>
          </nz-row>
        </ng-template>

        <cdk-virtual-scroll-viewport appendOnly itemSize="75" class="viewport">
          <nz-list-item
            *cdkVirtualFor="let item of cadrage?.livrables"
            (click)="$event.stopPropagation(); editingListItemShow$.next(item)"
          >
            <nz-row nzAlign="middle" nzJustify="start" nzGutter="4">
              <nz-col nzSpan="20">
                <span>{{ item?.title }}</span>
                <nz-row nzAlign="top" nzGutter="2" nzJustify="center">
                  <nz-col nzSpan="12">
                    <small>{{ item?.pilote | carLimitation: 25 }}</small>
                  </nz-col>
                  <nz-col [nzSpan]="item?.pilote ? 12 : 24">
                    <small>{{ item?.echeance | dateFr: "small" }}</small>
                  </nz-col>
                </nz-row>
              </nz-col>
              <nz-col nzSpan="4">
                <button
                  nzShape="circle"
                  nz-button
                  nz-dropdown
                  [nzDropdownMenu]="livrableMenu"
                  nzTrigger="click"
                  (click)="$event.stopPropagation()"
                >
                  <i nz-icon nzType="more"></i>
                </button>
              </nz-col>
            </nz-row>

            <nz-dropdown-menu #livrableMenu="nzDropdownMenu">
              <ul nz-menu>
                <li nz-menu-item (click)="onNewLivrable(cadrage, item)">
                  <i nz-icon nzType="edit"></i>
                  <span class="dropdowMenuSpan">Modifier</span>
                </li>
                <li nz-menu-divider></li>
                <li
                  nz-menu-item
                  nzDanger
                  nz-popconfirm
                  nzPopconfirmTitle="Voulez-vous supprimer ce livrable ?"
                  nzPopconfirmPlacement="bottom"
                  (nzOnConfirm)="onDeleteItem(item, cadrage)"
                  (nzOnCancel)="onCancel()"
                >
                  <i nz-icon nzType="delete"></i>
                  <span class="dropdowMenuSpan">Supprimer</span>
                </li>
              </ul>
            </nz-dropdown-menu>
          </nz-list-item>
        </cdk-virtual-scroll-viewport>
      </nz-list>
    </nz-col>
  </nz-row>
</ng-container>
<ng-template #noLivrable>
  <div class="containerEmpty">
    <div>
      <mat-icon class="iconEmpty">app_registration</mat-icon>
      <h4 nz-typography class="contentEmpty">Aucun livrable sélectionné</h4>
    </div>
  </div>
</ng-template>
