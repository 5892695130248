<div class="container">
  <nz-card [nzBordered]="false">
    <form nz-form nzLayout="vertical" [formGroup]="contactForm">
      <nz-row nzAlign="middle" nzJustify="start" nzGutter="8">
        <nz-col nzSpan="4">
          <nz-form-item nzRequired="true">
            <nz-form-label nzRequired="true">Civilité</nz-form-label>
            <app-utilities-select
              [id]="utilitairesId"
              [variable]="utilitaireCivilite"
              [size]="'default'"
              [multi]="false"
              [utilitySelected]="civiliteUtilitySelected"
              (select)="onSelectCivilite($event)"
              [placeholder]="'Civilité...'"
            ></app-utilities-select>
          </nz-form-item>
        </nz-col>

        <nz-col nzSpan="8">
          <nz-form-item nzRequired="true">
            <nz-form-label nzRequired="true">Nom</nz-form-label>
            <nz-form-control>
              <input
                nz-input
                nzBorderless
                formControlName="lastName"
                nzPlaceHolder="Nom..."
                placeholder="Nom..."
              />
            </nz-form-control>
          </nz-form-item>
        </nz-col>

        <nz-col nzSpan="6">
          <nz-form-item nzRequired="true">
            <nz-form-label nzRequired="true">Prénom</nz-form-label>
            <nz-form-control>
              <input
                nz-input
                nzBorderless
                formControlName="firstName"
                nzPlaceHolder="Prénom..."
                placeholder="Prénom..."
              />
            </nz-form-control>
          </nz-form-item>
        </nz-col>
        <nz-col nzSpan="6">
          <nz-form-item>
            <nz-form-label>Parrainage</nz-form-label>
            <nz-form-control class="parrainContainer">
              <label nz-checkbox formControlName="isParrain"
                >Parrain | Maraine</label
              >
            </nz-form-control>
          </nz-form-item>
        </nz-col>
      </nz-row>

      <!-- COORDONNES -->
      <nz-row nzAlign="top" nzJustify="start" nzGutter="4">
        <nz-col nzSpan="24">
          <app-coordonnees
            [update]="updateCoordonnees"
            (mobile)="onMobile($event)"
            (phoneNumber)="onPhoneNumber($event)"
            (fixe)="onFixe($event)"
            (email)="onEmail($event)"
            (reseaux)="onReseaux($event)"
          ></app-coordonnees>
        </nz-col>
      </nz-row>
      <nz-space
        [nzSize]="-80"
        nzAlign="center"
        nzDirection="horizontal"
      ></nz-space>
      <nz-row nzAlign="middle" nzJustify="start" nzGutter="8" class="adresses">
        <nz-col nzSpan="24">
          <app-communes-france
            [updateItem]="updateAdresse"
            [updateComplement]="updateAdresseComplement"
            [borderless]="true"
            [size]="'default'"
            (adresse)="onAdresse($event)"
            (complementIn)="onAdresseComplement($event)"
          ></app-communes-france>
        </nz-col>
      </nz-row>

      <!-- <nz-space
        [nzSize]="100"
        nzAlign="center"
        nzDirection="horizontal"
      ></nz-space>
      <nz-row nzAlign="middle" nzJustify="start" nzGutter="8" class="adresses">
        <nz-col nzSpan="24" formGroupName="coordonnees" class="coordonnees">
          <nz-badge [nzCount]="reseaux.length" [nzOverflowCount]="2">
            <button
              nz-button
              nzShape="round"
              nzType="dashed"
              nz-popover
              [nzPopoverTitle]="reseauTitle"
              nzPopoverTrigger="click"
              [nzPopoverContent]="templaceReseaux"
              nzPopoverPlacement="left"
            >
              Réseaux sociaux
              <i nz-icon nzTheme="outline" nzType="plus-circle"></i>
            </button>
          </nz-badge>

          <ng-container *ngIf="reseaux.length <= 1">
            <nz-tag
              *ngFor="let r of reseaux.controls"
              nz-tooltip
              [nzTooltipTitle]="r.value?.value"
              nzTooltipPlacement="bottom"
            >
              {{ r.value?.type }}
            </nz-tag>
          </ng-container>

          <ng-template #reseauTitle>
            <nz-row nzAlign="middle" nzJustify="start" nzGutter="4">
              <nz-col nzSpan="14">Réseaux sociaux</nz-col>
              <nz-col nzSpan="6" nzOffset="2">
                <nz-button-group nzSize="small">
                  <button
                    nz-button
                    nzShape="round"
                    nzSize="small"
                    (click)="onReseaux($event)"
                  >
                    <i nz-icon nzTheme="outline" nzType="plus"></i>
                  </button>
                  <button
                    nz-button
                    nzShape="round"
                    nzDanger="true"
                    nzSize="small"
                    (click)="onReseauxRemoveAll($event)"
                    *ngIf="reseaux.length"
                  >
                    <i nz-icon nzTheme="outline" nzType="delete"></i>
                  </button>
                </nz-button-group>
              </nz-col>
            </nz-row>
          </ng-template>

          <ng-template #templaceReseaux>
            <ng-container formArrayName="reseaux">
                  <nz-list
                    *ngFor="let el of reseaux.controls; let i = index"
                    [formGroupName]="i"
                  >
                    <nz-list-item
                      [formGroup]="el"
                      style="margin-top: 2px !important"
                    >
                      <nz-form-item style="width: 40%">
                        <nz-form-label nzRequired="true" nzSpan="14"
                          >Type</nz-form-label
                        >
                        
                        <nz-form-control *ngIf="utilityContact$ | async as utility">
                          <nz-select
                            nzSize="small"
                            formControlName="type"
                            nzShowSearch="true"
                            nzPlaceHolder="Type..."
                            placeholder="Type..."
                          >
                            <nz-option
                              *ngFor="let item of utility.reseauxSociaux"
                              [nzLabel]="item"
                              [nzValue]="item"
                            >
                            </nz-option>
                          </nz-select>
                        </nz-form-control>
                      </nz-form-item>
    
                      <nz-form-item style="width: 50%">
                        <nz-form-label nzRequired="true" nzSpan="40"
                          >Adresse</nz-form-label
                        >
                        <nz-form-control>
                          <input
                            nz-input
                            formControlName="value"
                            nzSize="small"
                            nzPlaceHolder="Adresse..."
                            placeholder="Adresse..."
                          />
                        </nz-form-control>
                      </nz-form-item>
    
                      <i
                        nz-icon
                        nzType="delete"
                        nzTheme="outline"
                        class="iconDelete"
                        (click)="removeReseau(i)"
                        style="width: 5%; float: right; margin-top: 5px"
                      ></i>
                    </nz-list-item>
                  </nz-list>
                </ng-container>
          </ng-template>
        </nz-col>
      </nz-row> -->
    </form>

    <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
    <nz-row nzAlign="middle" nzJustify="end" nzGutter="8">
      <nz-col nzSpan="16"></nz-col>
      <nz-col nzSpan="4">
        <button nz-button nzBlock nzType="text" (click)="onCancel()">
          <h5 nz-typography>Annuler</h5>
        </button>
      </nz-col>

      <nz-col nzSpan="4">
        <button
          nz-button
          nzBlock
          nzType="primary"
          *ngIf="!isUpdate"
          [disabled]="!contactForm.valid"
          (click)="onAdd()"
        >
          Ajouter
        </button>
        <ng-container *ngIf="contact$ | async as contactUpdate">
          <button
            nz-button
            *ngIf="isUpdate"
            [disabled]="!contactForm.valid"
            (click)="onUpdate($event, contactUpdate)"
            nzType="primary"
            nzBlock
          >
            Modifier
          </button>
        </ng-container>
      </nz-col>
    </nz-row>
  </nz-card>
</div>

<ng-template #contactTitleForm>
  <app-header-title [icon]="'person'" [title]="title"></app-header-title>
</ng-template>
<ng-template #noReseau>
  <nz-empty></nz-empty>
</ng-template>
