<nz-card nzBorderless [nzTitle]="logo">
  <nz-card [nzTitle]="title" class="card_content">
    <h4 nz-typography>{{ messageCard }}</h4>
  </nz-card>
</nz-card>

<ng-template #logo>
  <img
    width="160px"
    height="80px"
    src="../../../../../assets/protein_logo.svg"
    alt="proteinLogo"
  />
</ng-template>

<ng-template #title>
  <app-header-title
    [title]="titleCard"
    [subtitle]="true"
    [icon]="icon"
  ></app-header-title>
</ng-template>

<ng-template #contactFormTitle>
  <app-header-title [icon]="'person'" [title]="'title'"></app-header-title>
</ng-template>

<ng-template #userFormTitle>
  <app-header-title
    [icon]="'person'"
    [title]="'Compte utilisateur'"
    [subtitle]="true"
  ></app-header-title>
</ng-template>
<ng-template #organisationFormTitle>
  <app-header-title
    [icon]="'corporate_fare'"
    [title]="'Compte organisation'"
    [subtitle]="true"
  ></app-header-title>
</ng-template>

<ng-template #loadingAnimation>
  <app-loading-animation [loading$]="loading$"></app-loading-animation>
</ng-template>
