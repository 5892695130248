import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  catchError,
  EMPTY,
  first,
  firstValueFrom,
  from,
  lastValueFrom,
  map,
  Observable,
  of,
} from 'rxjs';
import { OperationFicheDePosteState } from '../store/operation-fiche-de-poste.reducer';
import * as fromOperationFicheDePosteSelector from '../store/operation-fiche-de-poste.selectors';
import * as fromOperationFicheDePosteAction from '../store/operation-fiche-de-poste.actions';

@Injectable({
  providedIn: 'any',
})
export class OperationFicheDePosteResolverService implements Resolve<any> {
  constructor(
    private operationFicheDePosteStore: Store<OperationFicheDePosteState>,
    private router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> | any {
    let id = route.parent?.params['id'];
    let itemId = route.params['itemId'];

    return this.loadFiche(id, itemId).pipe(
      map(() => {
        return this.getFiche();
      }),
      catchError(() => {
        this.router.navigate(['/projet', id, 'fichesDePoste']);
        return EMPTY;
      })
    );
  }

  getFiche(): Observable<any> {
    return this.operationFicheDePosteStore.select(
      fromOperationFicheDePosteSelector.fiche
    );
  }

  loadFiche(id: string, ficheId: string): Observable<any> {
    return of(
      this.operationFicheDePosteStore.dispatch(
        fromOperationFicheDePosteAction.loadOperationFicheDePoste({
          id,
          ficheId,
        })
      )
    );
  }
}
