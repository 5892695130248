<ng-container *ngIf="appelation; else noData">
  {{ appelation?.libelle }}
  {{ appelation?.code }}
</ng-container>

<ng-template #noData>
  <nz-card nzBorderless class="emptyCard">
    <mat-icon class="iconEmpty"> business_center </mat-icon>
    <h4 nz-typography class="contentEmpty">Aucun métier sélectionné</h4>
  </nz-card>
</ng-template>
