import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-check-card',
  templateUrl: './check-card.component.html',
  styleUrls: ['./check-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckCardComponent implements OnInit, OnChanges {
  colorDefault: string = '#808080';
  colorActive: string = '#062949';
  @Input() title: string = '';
  @Input() icon: string = '';
  @Input() height: string = '40px';
  @Input() width: string = '100%';
  @Input() fontSize: string = '12px';

  @Input() isActive: boolean = false;
  @Input() definition: string = '';
  @Output() select = new EventEmitter<boolean>(false);

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}

  onSelect(status: boolean): void {
    const nextStatus: boolean = !status;
    this.select.emit(nextStatus);
  }
}
