import { Component, OnInit } from '@angular/core';
import { UtilitiesState } from '../../store/utilities.reducer';
import * as fromUtilitiesAction from '../../store/utilities.actions';
import * as fromUtilitiesSelector from '../../store/utilities.selectors';
import { Store } from '@ngrx/store';
import { Observable, of, BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-utilities-list',
  templateUrl: './utilities-list.component.html',
  styleUrls: ['./utilities-list.component.scss'],
})
export class UtilitiesListComponent implements OnInit {
  utilities$: Observable<any> = of(null);
  utility$ = new BehaviorSubject<any>(null);
  items: any = [];
  variable: string = '';
  currentTag: string = '';

  constructor(private utilitiesStore: Store<UtilitiesState>) {}

  ngOnInit(): void {
    this.getUtilities();
    this.loadUtilities();
  }

  loadUtilities(): void {
    this.utilitiesStore.dispatch(fromUtilitiesAction.loadUtilities());
  }

  getUtilities(): void {
    this.utilities$ = this.utilitiesStore.select(
      fromUtilitiesSelector.utilities
    );
  }

  onSelect(item: any): void {
    this.utility$.next(item);
    this.items = [];
    this.variable = '';
    this.currentTag = '';
  }
}
