import { PortalNavigationService } from './../../portals/portal-navigation.service';
import { ContentsModule } from './../../contents/contents.module';
import { RouterModule } from '@angular/router';
import { UserModule } from './../user/user.module';
import { NavigationService } from './../../service/navigation.service';
import { SharedModule } from './../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationComponent } from './components/navigation/navigation.component';
import { UserAvatarComponent } from './components/user-avatar/user-avatar.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { NavigationButtonsComponent } from './components/navigation-buttons/navigation-buttons.component';
import { StoreModule } from '@ngrx/store';
import * as fromNavigation from './store/navigation.reducer';
import { EffectsModule } from '@ngrx/effects';
import { NavigationEffects } from './store/navigation.effects';
import { NavigationIconsComponent } from './components/navigation-icons/navigation-icons.component';
import { PortalModule } from '@angular/cdk/portal';
import { SideNavigationModule } from '../side-navigation/side-navigation.module';
@NgModule({
  declarations: [
    NavigationComponent,
    UserAvatarComponent,
    BreadcrumbComponent,
    NavigationButtonsComponent,
    NavigationIconsComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ContentsModule,
    UserModule,
    RouterModule,
    PortalModule,
    StoreModule.forFeature(
      fromNavigation.navigationsFeatureKey,
      fromNavigation.reducer
    ),
    EffectsModule.forFeature([NavigationEffects]),
  ],
  exports: [
    NavigationComponent,
    UserAvatarComponent,
    BreadcrumbComponent,
    NavigationButtonsComponent,
    NavigationIconsComponent,
  ],
  providers: [NavigationService, PortalNavigationService],
})
export class NavigationModule {}
