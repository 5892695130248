import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromContactTask from './store/contact-task.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ContactTaskEffects } from './store/contact-task.effects';
import { ContactTaskComponent } from './components/contact-task/contact-task.component';
import { ContactTasksService } from 'src/app/service/contact-tasks.service';
import { TaskModule } from 'src/app/features/task/task.module';
import { MetiersModule } from 'src/app/features/metiers/metiers.module';
import { LayoutModule } from '@angular/cdk/layout';
import { AvatarModule } from 'src/app/features/avatar/avatar.module';
import { SuiviModule } from 'src/app/features/suivi/suivi.module';

@NgModule({
  declarations: [ContactTaskComponent],
  imports: [
    CommonModule,
    TaskModule,
    LayoutModule,
    MetiersModule,
    AvatarModule,
    SuiviModule,

    StoreModule.forFeature(
      fromContactTask.contactTasksFeatureKey,
      fromContactTask.reducer
    ),
    EffectsModule.forFeature([ContactTaskEffects]),
  ],
  providers: [ContactTasksService],
})
export class ContactTaskModule {}
