<nz-card nzBorderless *ngIf="statistiques$ | async as statistiques">
  <nz-row [nzGutter]="16" nzAlign="middle" nzJustify="center">
    <nz-col [nzSpan]="12">
      <nz-statistic
        [nzValue]="(statistiques?.contacts?.total | number)!"
        [nzTitle]="'Collaborateurs'"
        [nzPrefix]="prefixTpl"
      ></nz-statistic>
      <ng-template #prefixTpl><i nz-icon nzType="team"></i></ng-template>
    </nz-col>
    <nz-col [nzSpan]="12">
      <nz-statistic
        [nzValue]="(statistiques?.operations?.size | number)!"
        [nzTitle]="'Projets'"
        [nzPrefix]="preProjectTpl"
      ></nz-statistic>
      <ng-template #preProjectTpl><i nz-icon nzType="project"></i></ng-template>
    </nz-col>
  </nz-row>
  <nz-row [nzGutter]="16" nzAlign="middle" nzJustify="center">
    <nz-col [nzSpan]="12">
      <nz-statistic
        [nzValue]="(statistiques?.suivis?.total | number)!"
        [nzTitle]="'Suivis'"
        [nzPrefix]="preSuiviTpl"
      ></nz-statistic>
      <ng-template #preSuiviTpl><i nz-icon nzType="file-text"></i></ng-template>
    </nz-col>
    <nz-col [nzSpan]="12">
      <nz-statistic
        [nzValue]="(statistiques?.documents?.size | number)!"
        [nzTitle]="'Documents'"
        [nzPrefix]="preDocumentTpl"
      ></nz-statistic>
      <ng-template #preDocumentTpl><i nz-icon nzType="file"></i></ng-template>
    </nz-col>
  </nz-row>

  <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
</nz-card>
