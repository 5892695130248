import { createAction, props } from '@ngrx/store';
import { Candidature } from './candidature.model';

//Expporter les candidatures au format PDF
export const exportCandidaturesPdf = createAction(
  '[Candidature/API] Export Candidatures Pdf',
  props<{ candidatures: any[]; fiche: any; isAnonyme: boolean; user: any }>()
);
export const exportCandidaturesPdfSuccess = createAction(
  '[Candidature/API] Export Candidatures Pdf Success',
  props<{ success: string }>()
);
export const exportCandidaturesPdfFailure = createAction(
  '[Candidature/API] Export Candidatures Pdf Failure',
  props<{ error: any }>()
);

//Expporter les candidatures au format Excel
export const exportCandidaturesExcel = createAction(
  '[Candidature/API] Export Candidatures Excel',
  props<{ candidatures: any[]; fiche: any; isAnonyme: boolean; user: any }>()
);
export const exportCandidaturesExcelSuccess = createAction(
  '[Candidature/API] Export Candidatures Excel Success',
  props<{ success: string }>()
);
export const exportCandidaturesExcelFailure = createAction(
  '[Candidature/API] Export Candidatures Excel Failure',
  props<{ error: any }>()
);

//Expporter les candidatures au format Word
export const exportCandidaturesWord = createAction(
  '[Candidature/API] Export Candidatures Word',
  props<{ candidatures: any[]; fiche: any; isAnonyme: boolean; user: any }>()
);
export const exportCandidaturesWordSuccess = createAction(
  '[Candidature/API] Export Candidatures Word Success',
  props<{ success: string }>()
);
export const exportCandidaturesWordFailure = createAction(
  '[Candidature/API] Export Candidatures Word Failure',
  props<{ error: any }>()
);
