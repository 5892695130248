import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { OperationParticipants } from './operation-participants.model';

export const loadOperationParticipantss = createAction(
  '[OperationParticipants/API] Load OperationParticipantss', 
  props<{ operationParticipantss: OperationParticipants[] }>()
);

export const addOperationParticipants = createAction(
  '[OperationParticipants/API] Add OperationParticipants',
  props<{ operationParticipants: OperationParticipants }>()
);

export const upsertOperationParticipants = createAction(
  '[OperationParticipants/API] Upsert OperationParticipants',
  props<{ operationParticipants: OperationParticipants }>()
);

export const addOperationParticipantss = createAction(
  '[OperationParticipants/API] Add OperationParticipantss',
  props<{ operationParticipantss: OperationParticipants[] }>()
);

export const upsertOperationParticipantss = createAction(
  '[OperationParticipants/API] Upsert OperationParticipantss',
  props<{ operationParticipantss: OperationParticipants[] }>()
);

export const updateOperationParticipants = createAction(
  '[OperationParticipants/API] Update OperationParticipants',
  props<{ operationParticipants: Update<OperationParticipants> }>()
);

export const updateOperationParticipantss = createAction(
  '[OperationParticipants/API] Update OperationParticipantss',
  props<{ operationParticipantss: Update<OperationParticipants>[] }>()
);

export const deleteOperationParticipants = createAction(
  '[OperationParticipants/API] Delete OperationParticipants',
  props<{ id: string }>()
);

export const deleteOperationParticipantss = createAction(
  '[OperationParticipants/API] Delete OperationParticipantss',
  props<{ ids: string[] }>()
);

export const clearOperationParticipantss = createAction(
  '[OperationParticipants/API] Clear OperationParticipantss'
);
