import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { TicketComponent } from '../../../tickets/components/ticket/ticket.component';
import { Store } from '@ngrx/store';
import { AuthState } from 'src/app/components/auth/store/auth.reducer';
import * as fromAuthAction from 'src/app/components/auth/store/auth.actions';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { FavoriesComponent } from 'src/app/components/favories/components/favories/favories.component';
import { CalculatriceComponent } from 'src/app/contents/components/calculatrice/calculatrice.component';

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
})
export class UserAvatarComponent implements OnInit {
  visible: boolean = false;
  avatarBackground: string = '#bfbfbf';
  avatarColor: string = 'white';
  updateVisible: boolean = false;
  drawerWidth: number = 950;

  @Input() user: any;
  @Input() size: number = 24;

  @Input() theme: string = '';
  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private authStore: Store<AuthState>,
    private drawerService: NzDrawerService
  ) {}

  ngOnInit(): void {}

  onVisibleChange(event: boolean): any {
    this.visible = event;
    if (!event) {
      this.updateVisible = false;
    } else {
      return null;
    }
  }

  onUpdateVisible(event: any, user: any): any {
    event.preventDefault();
    event.stopPropagation();
    if (!user) {
      return null;
    } else {
      this.updateVisible = true;
    }
  }

  onUpdateCancel(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.updateVisible = true;
  }

  onDisconnect(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.authStore.dispatch(fromAuthAction.logout());
  }

  onResetPass(user: any): void {
    this.authStore.dispatch(
      fromAuthAction.resetPassword(user.coordonnees.email)
    );
  }

  onNewTicket(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.visible = false;

    const drawerRef = this.drawerService.create({
      nzTitle: 'Tickets',
      nzContent: TicketComponent,
      nzContentParams: {},
      nzWidth: this.drawerWidth,
    });

    const instance = drawerRef.getContentComponent();
    instance?.close.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        drawerRef.close();
      }
    });
  }

  onFavoris(): void {
    const drawerRef = this.drawerService.create({
      nzTitle: 'Favoris',
      nzContent: FavoriesComponent,
      nzContentParams: {},
      nzWidth: this.drawerWidth,
    });
  }

  onCalculator(): void {
    const drawerRef = this.drawerService.create({
      nzTitle: 'Évaluation clause sociale',
      nzContent: CalculatriceComponent,
      nzContentParams: {},
      nzWidth: this.drawerWidth,
    });
  }
}
