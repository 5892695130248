import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-layout-list-skelton',
  templateUrl: './layout-list-skelton.component.html',
  styleUrls: ['./layout-list-skelton.component.scss']
})
export class LayoutListSkeltonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
