import {
  NzNotificationPlacement,
  NzNotificationService,
} from 'ng-zorro-antd/notification';
import {
  OperationTaskId,
  OperationTaskText,
} from './../../store/operation-task.model';
import { OperationId } from 'src/app/components/operation/store/operation.model';
import { OperationState } from 'src/app/components/operation/store/operation.reducer';
import * as fromOperationSelector from 'src/app/components/operation/store/operation.selectors';

import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';
import { Store } from '@ngrx/store';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { OperationTaskState } from '../../store/operation-task.reducer';
import * as fromOperationTaskSelector from '../../store/operation-task.selectors';
import * as fromOperationTaskAction from '../../store/operation-task.actions';

import { Observable, of } from 'rxjs';
import { Update } from '@ngrx/entity';

@Component({
  selector: 'app-operation-task-item',
  templateUrl: './operation-task-item.component.html',
  styleUrls: ['./operation-task-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationTaskItemComponent implements OnInit, OnChanges {
  user$: Observable<any> = of(null);
  operation$: Observable<OperationId | any> = of(null);
  users: any[] = [];
  mouseEnterDelay: number = 0.5;
  NOTIFICATION_PLACEMENT: NzNotificationPlacement = 'topRight';
  default: string = '#bfbfbf';

  @Input() task: OperationTaskId | any;
  @Input() type: any;

  constructor(
    private storeUser: Store<UserState>,
    private storeOperation: Store<OperationState>,
    private storeOperationTask: Store<OperationTaskState>,
    private notifications: NzNotificationService
  ) {}

  ngOnInit(): void {
    this.getUser();
    this.getOperation();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  getUser(): void {
    this.user$ = this.storeUser.select(fromUserSelector.user);
  }
  getOperation(): void {
    this.operation$ = this.storeOperation.select(
      fromOperationSelector.operation
    );
  }

  onChangeStatus(
    todo: OperationTaskId,
    text: OperationTaskText,
    operation: OperationId
  ): void {
    const nextText: OperationTaskText = {
      value: text.value,
      status: !text.status,
    };
    const prevTodo = todo.texts.filter((item) => item.value !== text.value);
    const taskInProgress: OperationTaskText[] = todo.texts.filter(
      (el: OperationTaskText) => el.status
    );
    const nextTexts: OperationTaskText[] = [...prevTodo, nextText];

    const operationTask: Update<OperationTaskId> = {
      id: todo.id,
      changes: {
        ...todo,
        texts: nextTexts,
        completed: taskInProgress?.length === nextTexts?.length ? true : false,
        inprogress:
          taskInProgress?.length === nextTexts?.length ||
          taskInProgress?.length > 0
            ? true
            : false,
      },
    };

    this.storeOperationTask.dispatch(
      fromOperationTaskAction.updateOperationTask({
        operationTask,
      })
    );
  }

  onCompleted(todo: OperationTaskId, operation: OperationId): void {
    const operationTask: Update<OperationTaskId> = {
      id: todo.id,
      changes: {
        ...todo,
        completed: !todo.completed,
        inprogress: true,
      },
    };

    this.storeOperationTask.dispatch(
      fromOperationTaskAction.updateOperationTask({ operationTask })
    );
  }

  onInProgress(todo: OperationTaskId, operation: OperationId): void {
    const operationTask: Update<OperationTaskId> = {
      id: todo.id,
      changes: {
        ...todo,
        tested: !todo.tested,
        inprogress: !todo.inprogress,
      },
    };

    this.storeOperationTask.dispatch(
      fromOperationTaskAction.updateOperationTask({ operationTask })
    );
  }

  onVerified(todo: OperationTaskId, operation: OperationId): void {
    const operationTask: Update<OperationTaskId> = {
      id: todo.id,
      changes: {
        ...todo,
        verifed: !todo.verifed,
        inprogress: !todo.inprogress,
      },
    };

    this.storeOperationTask.dispatch(
      fromOperationTaskAction.updateOperationTask({ operationTask })
    );
  }

  addUser(member: any, todo: OperationTaskId, operation: OperationId): void {
    if (!member || !todo || !operation) return;

    const isExist = todo?.users.find((el) => el.user.id === member.user.id);

    if (isExist) {
      const isExistUserName: string = isExist?.user?.displayName;

      const message: string = `Cette tâche a déjà été attribuée à ${isExistUserName}`;

      this.notifications.info('', message, {
        nzDuration: 6000,
        nzAnimate: true,
        nzPlacement: this.NOTIFICATION_PLACEMENT,
      });
      return;
    }

    const nextUsers: any[] = [...todo.users, member];
    const ids: string[] = [...todo.ids, member.user.id];

    const operationTask: Update<OperationTaskId> = {
      id: todo.id,
      changes: {
        ...todo,
        auths: {
          ...todo.auths,
          [`${member.user.id}`]: true,
        },
        users: nextUsers,
        ids: ids,
      },
    };

    this.storeOperationTask.dispatch(
      fromOperationTaskAction.updateOperationTask({ operationTask })
    );
    return;
  }

  removeUser(member: any, operation: OperationId, todo: OperationTaskId): void {
    if (!member || !operation || !todo) {
      return;
    }

    delete todo.auths[`${member.user.id}`];

    const operationTask: Update<OperationTaskId> = {
      id: todo.id,
      changes: {
        ...todo,
        users: todo.users.filter((item) => item.user.id !== member.user.id),
        ids: todo.ids.filter((id) => id !== member.user.id),
      },
    };

    this.storeOperationTask.dispatch(
      fromOperationTaskAction.updateOperationTask({ operationTask })
    );
  }

  onDelete(operationTask: OperationTaskId, operation: OperationId): void {
    this.storeOperationTask.dispatch(
      fromOperationTaskAction.deleteOperationTask({
        operationId: operation.id,
        id: operationTask.id,
      })
    );
  }
}
