import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NzMessageService } from 'ng-zorro-antd/message';
import { catchError, map, mergeMap, of } from 'rxjs';
import * as fromDocumentActions from './document.actions';
import { DocumentService } from '../../../services/document.service';

@Injectable()
export class DocumentEffects {
  loadDocumentFilters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromDocumentActions.loadDocumentFilters),
      mergeMap((action) =>
        this.documentService.getDocumentFilters().pipe(
          map((snap: any) => {
            const filters: any = snap.data?.documentFilters?.typeList;
            return fromDocumentActions.loadDocumentFiltersSuccess({
              filters: filters,
            });
          }),
          catchError((error) =>
            of(fromDocumentActions.loadDocumentFiltersFailure({ error }))
          )
        )
      )
    );
  });

  exportDocument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromDocumentActions.exportDocumentFilters),
      mergeMap((action) =>
        this.documentService.downloadDocument(action.document).pipe(
          map((success: string) => {
            this.message.success(success, { nzDuration: 6000 });
            return fromDocumentActions.exportDocumentFiltersSuccess({
              success: success,
            });
          }),
          catchError((error) =>
            of(fromDocumentActions.exportDocumentFiltersFailure({ error }))
          )
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private documentService: DocumentService,
    private message: NzMessageService
  ) {}
}
