import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { OperationFicheDePosteCandidature } from './operation-fiche-de-poste-candidature.model';

//SET DISPLAY VIEW
export const setOperationFicheCandidatView = createAction(
  '[OperationFicheDePosteCandidature/API] Set Vew Candidature',
  props<{ view: string }>()
);

//LOAD CANDIDATURES
export const loadOperationCandidatures = createAction(
  '[OperationFicheDePosteCandidature/API] Load Operation Candidatures',
  props<{ operationId: string; ficheId: string }>()
);
export const loadOperationCandidaturesSuccess = createAction(
  '[OperationFicheDePosteCandidature/API] Load Operation Candidatures Success',
  props<{ candidats: any[] }>()
);

export const loadOperationCandidaturesFailure = createAction(
  '[OperationFicheDePosteCandidature/API] Load Operation Candidatures Failure',
  props<{ error: any }>()
);

//LOAD CANDIDATURES SELECTED
export const loadOperationCandidaturesSelected = createAction(
  '[OperationFicheDePosteCandidature/API] Load Operation Candidatures Selected',
  props<{ operationId: string; ficheId: string }>()
);
export const loadOperationCandidaturesSelectedSuccess = createAction(
  '[OperationFicheDePosteCandidature/API] Load Operation Candidatures Selected Success',
  props<{ candidats: any[] }>()
);

export const loadOperationCandidaturesSelectedFailure = createAction(
  '[OperationFicheDePosteCandidature/API] Load Operation Candidatures Selected Failure',
  props<{ error: any }>()
);

//LOAD CANDIDATURES REJECTED
export const loadOperationCandidaturesRejected = createAction(
  '[OperationFicheDePosteCandidature/API] Load Operation Candidatures Rejected',
  props<{ operationId: string; ficheId: string }>()
);
export const loadOperationCandidaturesRejectedSuccess = createAction(
  '[OperationFicheDePosteCandidature/API] Load Operation Candidatures Rejected Success',
  props<{ candidats: any[] }>()
);
export const loadOperationCandidaturesRejectedFailure = createAction(
  '[OperationFicheDePosteCandidature/API] Load Operation Candidatures Rejected Failure',
  props<{ error: any }>()
);

//LOAD CANDIDATURE
export const loadOperationCandidature = createAction(
  '[OperationFicheDePosteCandidature/API] Load Operation Candidature ',
  props<{ operationId: string; ficheId: string; candidatId: string }>()
);
export const loadOperationCandidatureSuccess = createAction(
  '[OperationFicheDePosteCandidature/API] Load Operation Candidature  Success',
  props<{ candidat: any }>()
);
export const loadOperationCandidatureFailure = createAction(
  '[OperationFicheDePosteCandidature/API] Load Operation Candidature Failure',
  props<{ error: any }>()
);

//CREATE CANDIDAT
export const addOperationCandidature = createAction(
  '[OperationFicheDePosteCandidature/API] Add Operation Candidature',
  props<{ operationId: string; ficheId: string; candidats: any }>()
);
export const addOperationCandidatureSuccess = createAction(
  '[OperationFicheDePosteCandidature/API] Add Operation Candidature Success',
  props<{ candidat: any }>()
);
export const addOperationCandidatureFailure = createAction(
  '[OperationFicheDePosteCandidature/API] Add Operation Candidature Failure',
  props<{ error: any }>()
);

//UPDATE CANDIDAT
export const updateOperationCandidature = createAction(
  '[OperationFicheDePosteCandidature/API] Update Operation Candidature',
  props<{ operationId: string; ficheId: string; candidat: Update<any> }>()
);
export const updateOperationCandidatureSuccess = createAction(
  '[OperationFicheDePosteCandidature/API] Update Operation Candidature Success',
  props<{ candidat: any }>()
);
export const updateOperationCandidatureFailure = createAction(
  '[OperationFicheDePosteCandidature/API] Update Operation Candidature Failure',
  props<{ error: any }>()
);

//DELETE CANDIDAT
export const deleteOperationCandidature = createAction(
  '[OperationFicheDePosteCandidature/API] Delete Operation Candidature',
  props<{ operationId: string; ficheId: string; candidatId: string }>()
);
export const deleteOperationCandidatureSuccess = createAction(
  '[OperationFicheDePosteCandidature/API] Delete Operation Candidature Success',
  props<{ success: string }>()
);
export const deleteOperationCandidatureFailure = createAction(
  '[OperationFicheDePosteCandidature/API] Delete Operation Candidature Failure',
  props<{ error: any }>()
);

//DELETE CANDIDATS
export const deleteOperationCandidatures = createAction(
  '[OperationFicheDePosteCandidature/API] Delete Operation Candidatures ',
  props<{ operationId: string; ficheId: string; ids: string[] }>()
);
export const deleteOperationCandidaturesSuccess = createAction(
  '[OperationFicheDePosteCandidature/API] Delete Operation Candidatures Success',
  props<{ success: string }>()
);
export const deleteOperationCandidaturesFailure = createAction(
  '[OperationFicheDePosteCandidature/API] Delete Operation Candidatures Failure',
  props<{ error: any }>()
);

//CLEAR ALL
export const clearOperationFicheDePosteCandidatures = createAction(
  '[OperationFicheDePosteCandidature/API] Clear OperationFicheDePosteCandidatures'
);
