import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'any',
})
export class QueryUtilPlanningTypesGQL extends Query<Response> {
  document = gql`
    query ReferentielPlanningTypes {
      referentielPlanningTypes {
        label
        value
        color
        colorLabel
      }
    }
  `;
}
