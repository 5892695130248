import { UserState } from './../../../user/store/user.reducer';
import { Component, OnInit } from '@angular/core';
import * as fromUserSelectors from '../../../user/store/user.selectors';

import { TicketId } from '../../../tickets/store/ticket.model';
import { TicketState } from '../../../tickets/store/ticket.reducer';
import * as fromTicketsSelectors from '../../../tickets/store/ticket.selectors';
import * as fromTicketsActions from '../../../tickets/store/ticket.actions';

import { Store, select } from '@ngrx/store';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss'],
})
export class TicketsComponent implements OnInit {
  loading$: Observable<boolean> = of(false);
  user$: Observable<any> = of(null);
  tickets$: Observable<TicketId[] | any> = of([]);

  constructor(
    private userStore: Store<UserState>,
    private storeTicket: Store<TicketState>
  ) {}

  ngOnInit(): void {
    this.get__USER();
    this.getLoading__TICKETS();
    this.get__TICKETS();
    this.load__TICKETS();
  }

  get__USER(): void {
    this.user$ = this.userStore.select(fromUserSelectors.user);
  }

  get__TICKETS(): void {
    this.tickets$ = this.storeTicket.select(fromTicketsSelectors.selectTickets);
  }

  getLoading__TICKETS(): void {
    this.loading$ = this.storeTicket.select(fromTicketsSelectors.selectLoading);
  }

  load__TICKETS(): void {
    this.storeTicket.dispatch(fromTicketsActions.loadTickets());
  }

  onSelect(ticket: TicketId): void {}

  onDelete(ticket: TicketId): void {
    this.storeTicket.dispatch(
      fromTicketsActions.deleteTicket({ id: ticket.id })
    );
  }
}
