<ng-container *ngIf="suivi">
  <div [ngSwitch]="suivi.type">
    <div *ngSwitchCase="'Email'">
      <div class="avatar placeholder">
        <div class="bg-slate-50 rounded-full text-neutral-content w-12">
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g
              id="页面-1"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g
                id="Contact"
                transform="translate(-144.000000, 0.000000)"
                fill-rule="nonzero"
              >
                <g id="mail_line" transform="translate(144.000000, 0.000000)">
                  <path
                    d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z"
                    id="MingCute"
                    fill-rule="nonzero"
                  ></path>
                  <path
                    d="M20,4 C21.0543909,4 21.9181678,4.81587733 21.9945144,5.85073759 L22,6 L22,18 C22,19.0543909 21.18415,19.9181678 20.1492661,19.9945144 L20,20 L4,20 C2.94563773,20 2.08183483,19.18415 2.00548573,18.1492661 L2,18 L2,6 C2,4.94563773 2.81587733,4.08183483 3.85073759,4.00548573 L4,4 L20,4 Z M20,7.41424 L13.0606,14.3536 C12.4748,14.9394 11.5251,14.9394 10.9393,14.3536 L4,7.41424 L4,18 L20,18 L20,7.41424 Z M18.5858,6 L5.41409,6 L12,12.5859 L18.5858,6 Z"
                    id="形状"
                    fill="#09244BFF"
                  ></path>
                </g>
              </g>
            </g>
          </svg>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="'Téléphone'">
      <div class="avatar placeholder">
        <div class="bg-slate-50 rounded-full text-neutral-content w-12">
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g
              id="页面-1"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g id="Contact" transform="translate(-384.000000, 0.000000)">
                <g id="phone_line" transform="translate(384.000000, 0.000000)">
                  <path
                    d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z"
                    id="MingCute"
                    fill-rule="nonzero"
                  ></path>
                  <path
                    d="M8.17155,15.8285 C12.0174,19.6743 15.5802,20.0954 16.6259,20.1339 C17.8904,20.1804 19.1802,19.1483 19.7376,18.0906 C18.848,17.0465 17.6892,16.2367 16.4195,15.3587 C15.6712,16.107 14.7484,17.4973 13.5186,16.9999 C12.8203,16.7175 11.0941,15.9226 9.58577,14.4143 C8.07739,12.9059 7.28253,11.1797 7.00013,10.4815 C6.50231,9.25062 7.89573,8.32613 8.64459,7.57727 C7.76704,6.28658 6.97058,5.09819 5.92867,4.25321 C4.85725,4.8137 3.81906,6.09371 3.86617,7.37413 C3.90465,8.41979 4.32575,11.9827 8.17155,15.8285 Z M16.5523,22.1325 C15.1114,22.0795 11.0307,21.5161 6.75734,17.2427 C2.48395,12.9693 1.92055,8.88863 1.86753,7.44768 C1.78673,5.25222 3.46853,3.11903 5.41214,2.28606 C5.8682,2.0906 6.42059,2.12637 6.85727,2.4449 C8.4649,3.61757 9.57405,5.39532 10.5266,6.78732 C10.9324,7.38038 10.8654,8.18491 10.3502,8.70013 L8.99377,10.0565 C9.30858,10.7518 9.95085,11.9509 11,13 C12.0491,14.0492 13.2482,14.6914 13.9435,15.0063 L15.2992,13.6505 C15.8149,13.1349 16.6229,13.0669 17.2173,13.4787 C18.6373,14.4625 20.305,15.556 21.521,17.1133 C21.8625,17.5507 21.9133,18.1227 21.7096,18.5981 C20.8728,20.5507 18.7552,22.2136 16.5523,22.1325 Z"
                    id="形状"
                    fill="#09244BFF"
                  ></path>
                </g>
              </g>
            </g>
          </svg>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="'Rencontre'">
      <div class="avatar placeholder">
        <div class="bg-slate-50 rounded-full text-neutral-content w-12">
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g
              id="页面-1"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g id="User" transform="translate(-480.000000, 0.000000)">
                <g id="group_line" transform="translate(480.000000, 0.000000)">
                  <path
                    d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z"
                    id="MingCute"
                    fill-rule="nonzero"
                  ></path>
                  <path
                    d="M13,13 C15.2091,13 17,14.7909 17,17 L17,19 C17,19.5523 16.5523,20 16,20 C15.4477,20 15,19.5523 15,19 L15,17 C15,15.8954 14.1046,15 13,15 L6,15 C4.89543,15 4,15.8954 4,17 L4,19 C4,19.5523 3.55228,20 3,20 C2.44772,20 2,19.5523 2,19 L2,17 C2,14.7909 3.79086,13 6,13 L13,13 Z M18.9999,13.0002 C20.6568,13.0002 21.9999,14.3434 21.9999,16.0002 L21.9999,18.0002 C21.9999,18.5525 21.5522,19.0002 20.9999,19.0002 C20.4477,19.0002 19.9999,18.5525 19.9999,18.0002 L19.9999,16.0002 C19.9999,15.448 19.5522,15.0002 18.9999,15.0002 L17.5841,15.0002 C17.2362,14.204 16.687,13.5159 16.0008,13.0002 L18.9999,13.0002 Z M9.49998,3 C11.9853,3 14,5.01472 14,7.5 C14,9.98528 11.9853,12 9.49998,12 C7.01469,12 4.99998,9.98528 4.99998,7.5 C4.99998,5.01472 7.01469,3 9.49998,3 Z M18,6 C19.6569,6 21,7.34315 21,9 C21,10.6569 19.6569,12 18,12 C16.3431,12 15,10.6569 15,9 C15,7.34315 16.3431,6 18,6 Z M9.49998,5 C8.11926,5 6.99998,6.11929 6.99998,7.5 C6.99998,8.88071 8.11926,10 9.49998,10 C10.8807,10 12,8.88071 12,7.5 C12,6.11929 10.8807,5 9.49998,5 Z M18,8 C17.4477,8 17,8.44772 17,9 C17,9.55228 17.4477,10 18,10 C18.5523,10 19,9.55228 19,9 C19,8.44772 18.5523,8 18,8 Z"
                    id="形状"
                    fill="#09244BFF"
                  ></path>
                </g>
              </g>
            </g>
          </svg>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="'Campagne'">
      <div class="avatar placeholder">
        <div class="bg-slate-50 rounded-full text-neutral-content w-12">
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g
              id="页面-1"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g
                id="Contact"
                transform="translate(-240.000000, 0.000000)"
                fill-rule="nonzero"
              >
                <g
                  id="mail_send_line"
                  transform="translate(240.000000, 0.000000)"
                >
                  <path
                    d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z"
                    id="MingCute"
                    fill-rule="nonzero"
                  ></path>
                  <path
                    d="M20,4 C21.0543909,4 21.9181678,4.81587733 21.9945144,5.85073759 L22,6 L22,18 C22,19.0543909 21.18415,19.9181678 20.1492661,19.9945144 L20,20 L4,20 C2.94563773,20 2.08183483,19.18415 2.00548573,18.1492661 L2,18 L2,17 L4,17 L4,18 L20,18 L20,7.41437 L13.0607,14.3536 C12.5116062,14.9027875 11.6425027,14.9371117 11.0533254,14.4565727 L10.9394,14.3536 L4,7.41421 L4,8 L2,8 L2,6 C2,4.94563773 2.81587733,4.08183483 3.85073759,4.00548573 L4,4 L20,4 Z M6,13 C6.55228,13 7,13.4477 7,14 C7,14.5523 6.55228,15 6,15 L1,15 C0.447715,15 0,14.5523 0,14 C0,13.4477 0.447715,13 1,13 L6,13 Z M18.5859,6 L5.41421,6 L12.0001,12.5859 L18.5859,6 Z M5,10 C5.55228,10 6,10.4477 6,11 C6,11.51285 5.61395571,11.9355092 5.11662025,11.9932725 L5,12 L2,12 C1.44772,12 1,11.5523 1,11 C1,10.48715 1.38604429,10.0644908 1.88337975,10.0067275 L2,10 L5,10 Z"
                    id="形状"
                    fill="#09244BFF"
                  ></path>
                </g>
              </g>
            </g>
          </svg>
        </div>
      </div>
    </div>
    <div *ngSwitchDefault class="tracking-wider font-bold">
      {{ suivi.type | titlecase | carLimitation : 15 }}
    </div>
  </div>
</ng-container>
