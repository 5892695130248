import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { OperationTeam } from './operation-team.model';

//LOAD
export const loadOperationTeams = createAction(
  '[OperationTeam/COMPONENT] Load OperationTeams',
  props<{ id: string }>()
);
export const loadOperationTeamsSucces = createAction(
  '[OperationTeam/API] Load OperationTeams Succes',
  props<{ team: any }>()
);

export const loadOperationTeamsFailure = createAction(
  '[OperationTeam/API] Load OperationTeams Failure',
  props<{ error: any }>()
);

//LOAD TEAM SELECTION
export const loadOperationTeamsSelection = createAction(
  '[OperationTeam/COMPONENT] Load OperationTeamsSelection',
  props<{ id: string }>()
);
export const loadOperationTeamsSelectionSucces = createAction(
  '[OperationTeam/API] Load OperationTeamsSelection Succes',
  props<{ team: any }>()
);

export const loadOperationTeamsSelectionFailure = createAction(
  '[OperationTeam/API] Load OperationTeamsSelection Failure',
  props<{ error: any }>()
);

//ADD
export const addOperationTeams = createAction(
  '[OperationTeam/COMPONENT] Add OperationTeams',
  props<{ id: string; users: any[] }>()
);
export const addOperationTeamsSuccess = createAction(
  '[OperationTeam/API] Add OperationTeams Success',
  props<{ success: string }>()
);
export const addOperationTeamsFailure = createAction(
  '[OperationTeam/API] Add OperationTeams Failure',
  props<{ error: any }>()
);

//UPDATE
export const updateOperationTeam = createAction(
  '[OperationTeam/COMPONENT] Update OperationTeam',
  props<{ operationId: string; user: Update<OperationTeam> }>()
);
export const updateOperationTeamSuccess = createAction(
  '[OperationTeam/API] Update OperationTeam Success',
  props<{ success: string }>()
);
export const updateOperationTeamFailure = createAction(
  '[OperationTeam/API] Update OperationTeam Failure',
  props<{ error: any }>()
);

//DELETE ONE
export const deleteOperationTeam = createAction(
  '[OperationTeam/COMPONENT] Delete OperationTeam',
  props<{ operationId: string; id: string }>()
);
export const deleteOperationTeamSuccess = createAction(
  '[OperationTeam/API] Delete OperationTeam Success',
  props<{ success: string }>()
);
export const deleteOperationTeamFailure = createAction(
  '[OperationTeam/API] Delete OperationTeam Failure',
  props<{ error: any }>()
);

//DELETE ALL
export const deleteOperationTeams = createAction(
  '[OperationTeam/COMPONENT] Delete OperationTeams',
  props<{ ids: string[]; operationId: string }>()
);
export const deleteOperationTeamsSuccess = createAction(
  '[OperationTeam/API] Delete OperationTeams Success',
  props<{ success: string }>()
);
export const deleteOperationTeamsFailure = createAction(
  '[OperationTeam/API] Delete OperationTeams Failure',
  props<{ error: any }>()
);

//CLEAR
export const clearOperationTeams = createAction(
  '[OperationTeam/COMPONENT] Clear OperationTeams'
);
