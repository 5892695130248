<nz-card *ngIf="note$ | async as note">
  <ng-template #noteDocumentHeader>
    Documents
    <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>

    <nz-badge
      [nzCount]="(documents$ | async)?.length"
      [nzOverflowCount]="99"
      [nzStyle]="{
        backgroundColor: '#ffab00',
        color: 'black',
        fontWeigth: 'bold'
      }"
    ></nz-badge>
  </ng-template>

  <div *ngIf="sujet$ | async as sujet" class="input__documents">
    <input
      *ngIf="operation$ | async as operation"
      nz-input
      [hidden]="true"
      class="ng-hide"
      id="input-file-id"
      multiple
      type="file"
      (change)="handleChange__DOCUMENT($event, sujet, note, operation)"
    />
    <label for="input-file-id" class="iconStandard">
      <i
        nz-icon
        nzType="cloud-upload"
        nzTheme="outline"
        style="margin-right: 10px; margin-top: 2px"
      ></i>
      Sélectionner les fichiers
    </label>
  </div>

  <div class="list__documents">
    <nz-list nzItemLayout="horizontal" *ngIf="documents$ | async as documents">
      <nz-list-item *ngFor="let item of documents">
        <nz-list-item-meta
          [nzAvatar]="avatarDocument"
          [nzDescription]="descriptionDocument"
        >
          <ng-template #avatarDocument>
            <nz-avatar nzIcon="file"></nz-avatar>
          </ng-template>

          <ng-template #descriptionDocument>
            <span>{{ item.title }}</span>
            <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
            <span>Type : {{ item.extension }}</span>
            <button
              nz-button
              nzSize="small"
              (click)="$event.stopPropagation(); onSelectDocument(item.file)"
            >
              Ouvrir
            </button>
            <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
            <button
              nz-button
              nzSize="small"
              class="iconDelete"
              (click)="$event.stopPropagation()"
              nz-popconfirm
              nzPopconfirmTitle="Voulez-vous supprimer ce document : {{
                item.title
              }} ?"
              nzPopconfirmPlacement="bottom"
              (nzOnConfirm)="delete__DOCUMENT(item)"
              (nzOnCancel)="cancel_delete__DDOCUMENT()"
            >
              Supprimer
            </button>
          </ng-template>

          <nz-list-item-meta-title>
            <span
              >Date : {{ item.dateStart | dateFr: "time" }} par
              {{ item.auteur.displayName }}</span
            >
          </nz-list-item-meta-title>
        </nz-list-item-meta>
      </nz-list-item>
      <nz-list-empty *ngIf="documents.length === 0"></nz-list-empty>
    </nz-list>
  </div>
</nz-card>
