import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CommunesFranceService } from '../../../../service/communes-france.service';
import * as fromGeoActions from './geo-adresse.actions';
import {
  map,
  catchError,
  concatMap,
  mergeMap,
  debounceTime,
  distinctUntilChanged,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';

@Injectable()
export class GeoAdresseEffects {
  loadGeo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromGeoActions.loadGeoAdresses),
      debounceTime(2000),
      distinctUntilChanged(),
      mergeMap((action) =>
        this.geoService.getGeoAdresse(action.input).pipe(
          map((snap: any) => {
            const adresses = snap.data.geoAdresse;
            return fromGeoActions.loadGeoAdressesSuccess({
              adresses: adresses?.features,
            });
          }),
          catchError((error) => {
            this.message.error(error.message, { nzDuration: 6000 });
            return of(fromGeoActions.loadGeoAdressesFailure({ error }));
          })
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private geoService: CommunesFranceService,
    private message: NzMessageService
  ) {}
}
