import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-title',
  template: `
    <div class="w-full flex justify-between items-center h-6">
      <div class="w-1/12 place-self-start ml-2  mb-6 mr-6">
        <div
          [ngStyle]="{
            height: height,
            width: width,
            'background-color': iconBackgroundColor
          }"
          [ngClass]="{
            matAvatarCircle: type === 'circle',
            matAvatarRectable: type === 'rect'
          }"
        >
          <mat-icon [ngStyle]="{ 'margin-left': left }">{{ icon }}</mat-icon>
        </div>
      </div>
      <div
        class="w-11/12 flex m-4 mt-2 items-center justify-start whitespace-nowrap"
      >
        <h4 class="text-lg font-bold" *ngIf="!subtitle && !isItemList">
          {{ title }}
        </h4>
        <h5 class="text-lg font-bold" *ngIf="subtitle && !isItemList">
          {{ title }}
        </h5>
        <p class="text-lg font-bold " *ngIf="isItemList && !subtitle">
          {{ title }}
        </p>
        <nz-col nzSpan="4">
          <nz-badge
            *ngIf="count"
            [nzCount]="count"
            [nzOffset]="[-5, -8]"
          ></nz-badge>
        </nz-col>
      </div>
    </div>
  `,
  styles: [
    `
      nz-row {
        width: 100%;
      }
      mat-icon {
        align-items: center;
      }
      .matAvatarCircle {
        color: #062949;
        padding-left: 5px;
        width: 30px !important;
        height: 30px !important;
        border-radius: 50%;

        text-align: center;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        justify-items: center;
      }

      .matAvatarRectable {
        color: #062949;
        padding-left: 5px;
        width: 30px !important;
        height: 30px !important;
        border-radius: 20%;

        text-align: center;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        justify-items: center;
      }

      .matAvatarHeader {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        text-align: center;
        padding-left: 10px;
        background-color: #bfbfbf;
        color: #fff;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        justify-items: center;
      }

      .matAvatarHeader {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        text-align: center;
        padding-left: 10px;
        background-color: #bfbfbf;
        color: #fff;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        justify-items: center;
      }
      h4,
      h5,
      p {
        color: #062949;
      }
      h4,
      h5 {
        margin-top: 10px;
      }
      p {
        margin-top: 20px;
      }
    `,
  ],
})
export class HeaderTitleComponent implements OnInit {
  @Input() icon: string = '';
  @Input() title: string = '';
  @Input() height: string = '40px';
  @Input() width: string = '40px';
  @Input() left: string = '-2px';
  @Input() subtitle: boolean = false;
  @Input() isItemList: boolean = false;
  @Input() isItemMenu: boolean = false;
  @Input() isIcon: boolean = true;
  @Input() type: string = 'circle';
  @Input() iconColor: string = '#062949';
  @Input() iconBackgroundColor: string = '#d1e4ff';
  @Input() count: number = 0;

  constructor() {}

  ngOnInit(): void {}
}
