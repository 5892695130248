<ng-container *ngIf="events$ | async | size; else noDate">
  <app-participant-planning-list-header></app-participant-planning-list-header>
  <nz-list nzItemLayout="vertical">
    <nz-list-item *ngFor="let items of events$ | async; trackBy: trackByFn">
      <nz-card nzSize="small" [nzTitle]="items[0]?.createAt">
        <app-participant-planning-list-card
          [items]="items"
        ></app-participant-planning-list-card>
      </nz-card>
    </nz-list-item>
    <nz-list-item-action></nz-list-item-action>
  </nz-list>
</ng-container>
<ng-template #noDate>
  <app-no-result
    [icon]="'event'"
    [description]="'Aucun événement ajouté'"
    [isButton]="false"
  ></app-no-result>
</ng-template>
