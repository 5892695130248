<nz-select
  [(ngModel)]="personSelected"
  (ngModelChange)="onSelect($event)"
  [nzPlaceHolder]="placeholder"
  [nzNotFoundContent]="'Aucun collarateur trouvé'"
  [nzDisabled]="loading$ | async"
  [nzLoading]="loading$ | async"
  [nzClearIcon]="clearIconTpl"
  nzAllowClear
  [nzMode]="'default'"
  [nzMaxTagCount]="8"
  nzBorderless
  [nzDropdownStyle]="{ height: '200px' }"
>
  <nz-option
    *ngFor="let person of persons$ | async; trackBy: trackBy"
    nzCustomContent
    [nzLabel]="person?.displayName || (person | displayName : true)"
    [nzValue]="person"
  >
    <div class="w-full flex items-start space-x-2">
      <div class="w-12">
        <nz-avatar
          [nzText]="!person?.photo ? person?.avatar : ''"
          [nzSrc]="person?.photo ? person?.photo : ''"
          [nzSize]="24"
          [ngStyle]="{
            'background-color': person?.setting?.color
              ? person?.setting?.color
              : default,
            color: avatarColor
          }"
        ></nz-avatar>
      </div>
      <div class="w-3/4">
        <p class="text-lg">
          {{ person?.displayName || (person | displayName : true) }}
        </p>
        <p class="text-xs" *ngIf="isFonction">{{ person?.fonction }}</p>
        <p class="text-xs" *ngIf="isRole">{{ person?.role }}</p>
        <div class="w-full flex items-start" *ngIf="isCoordonnees">
          <div class="w-1/2 text-xs">
            Mobile :
            {{
              (person?.coodonnees?.mobile | phone) ||
                person?.coodonnees?.phoneNumber
            }}
          </div>
          <div class="w-1/2 text-xs">
            E-mail : {{ person?.coodonnees?.email }}
          </div>
        </div>
      </div>
    </div>
  </nz-option>
</nz-select>

<ng-template #clearIconTpl>
  <mat-icon>cancel</mat-icon>
</ng-template>

<ng-template #suffixIconTpl>
  <button nz-button nzType="primary" (click)="$event.stopPropagation()">
    <mat-icon
      nz-tooltip
      [nzTooltipMouseEnterDelay]="0.5"
      nzTooltipPlacement="bottom"
      nzTooltipTitle="Ajouter"
      >person_add_alt</mat-icon
    >
  </button>
</ng-template>
