import { OperationNoteSubtitleId } from './../components/operation-note-subtitle/store/operation-note-subtitle.model';
import { Injectable } from '@angular/core';

import {
  Firestore,
  collection,
  doc,
  docSnapshots,
  collectionChanges,
  getDoc,
  addDoc,
  setDoc,
  deleteDoc,
  serverTimestamp,
  DocumentData,
  Timestamp,
  query,
  where,
  arrayUnion,
  arrayRemove,
  collectionSnapshots,
  CollectionReference,
  DocumentReference,
  getDocs,
  documentId,
} from '@angular/fire/firestore';

import {
  OperationNoteDetailId,
  OperationNoteId,
} from './../components/operation-note/store/operation-note.model';

import jsPDF from 'jspdf';
require('jspdf-autotable');
import * as html2canvas from 'html2canvas';
//import * as html2pdf from 'html2pdf.js';
import { format, fromUnixTime } from 'date-fns';
import { fr } from 'date-fns/locale';
import {
  BehaviorSubject,
  Observable,
  of,
  from,
  EMPTY,
  lastValueFrom,
} from 'rxjs';
import { map, filter, switchMap, finalize, mergeMap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { UserState } from '../components/user/store/user.reducer';
import * as fromUserSelector from '../components/user/store/user.selectors';
import { OperationId } from '../components/operation/store/operation.model';
import { OperationNoteSujetId } from '../components/operation-note-sujet/store/operation-note-sujet.model';

import {
  Document,
  Packer,
  Paragraph,
  TextRun,
  Table,
  TableRow,
  TableCell,
  Footer,
  Header,
  AlignmentType,
  PageNumber,
} from 'docx';
import saveAs from 'file-saver';
import { OperationNoteSujetState } from '../components/operation-note-sujet/store/operation-note-sujet.reducer';
import * as fromOperationNoteSujetAction from '../components/operation-note-sujet/store/operation-note-sujet.actions';
import * as fromOperationNoteSujetSelector from '../components/operation-note-sujet/store/operation-note-sujet.selectors';
import { flatten, upperCase } from 'lodash';

declare var require: any;

@Injectable({
  providedIn: 'any',
})
export class OperationNoteExportService {
  sujets: any = null;

  constructor(
    private userStore: Store<UserState>,
    private operationNoteSujetsStore: Store<OperationNoteSujetState>
  ) {}

  //GET PARTICIPANTS

  //GET SUJETS
  async loadNoteSujets(note: OperationNoteId) {
    this.operationNoteSujetsStore.dispatch(
      fromOperationNoteSujetAction.loadOperationNoteSujetsByNote({ note })
    );
  }

  async getNoteSujets(note: OperationNoteId) {
    await this.loadNoteSujets(note);
    const sujets = await lastValueFrom(
      this.operationNoteSujetsStore.select(
        fromOperationNoteSujetSelector.sujetsByNote
      )
    );

    return sujets;
  }

  //EXPORT FROM HTML
  export__PDF__fromHTML(): Observable<any> {
    return EMPTY;
  }

  //EXPORT PDF VERSION
  export__PDF(
    operation: OperationId,
    note: OperationNoteDetailId,
    subtitles: OperationNoteSubtitleId[],
    sujets: any[]
  ): Observable<any> {
    try {
      return this.userStore.pipe(
        select(fromUserSelector.user),
        switchMap((user) => {
          if (!user || !note || !operation || !subtitles?.length) EMPTY;

          const participants: any = [];

          const checkCrossOrigin = false;
          const xhr = new XMLHttpRequest();
          return from(
            new Promise((resolve, reject) => {
              xhr.open('GET', user.organisation.logo);
              xhr.responseType = 'blob';
              xhr.send();
              xhr.addEventListener('load', () => {
                const reader = new FileReader();
                reader.readAsDataURL(xhr.response);
                reader.addEventListener('loadend', () => {
                  const logo = reader.result;
                  if (logo) {
                    const currentDate = format(new Date(Date.now()), 'yyyyMM', {
                      locale: fr,
                    });

                    const pdfSize = 'a4';
                    const doc = new jsPDF('p', 'pt', pdfSize);

                    this.exportPDF_settings(user, operation, note, doc);

                    var parser = new DOMParser();
                    var data = `<strong>Hello world ${note.title}</strong>`;
                    var html = parser.parseFromString(data, 'text/html');

                    //header
                    this.exportPDF__header(user, operation, note, doc);

                    //participants
                    this.exportPDF__participants(
                      user,
                      operation,
                      note,
                      participants,
                      doc,
                      logo
                    );

                    //sujets page
                    doc.addPage();

                    //legendes
                    //this.exportPDF__note__legende(doc);

                    //subtitle
                    this.exportPDF__subtitles(
                      user,
                      doc,
                      subtitles,
                      sujets,
                      logo
                    );

                    //sujets
                    this.exportPDF__sujets(user, doc, subtitles, sujets, logo);

                    //open || download || print
                    doc.save(
                      `CR_${operation.denomination}_${operation.type}_${note.title}_${currentDate}.pdf`
                    );
                  }
                });
              });
            }).then(() => `Compte rendu en téléchargement`)
          ).pipe(mergeMap((res) => of(res)));
        })
      );
    } catch (err) {
      return of(err);
    }
  }

  exportPDF_settings(
    user: any,
    operation: OperationId,
    note: OperationNoteId,
    doc: any
  ): any {
    return doc.setProperties({
      title: `CR ${note.title}`,
      type: operation.type,
      subject: operation.denomination,
      adresse: note?.adresse?.adresse?.properties.label
        ? note?.adresse?.adresse?.properties.label
        : '',
      date: format(fromUnixTime(note?.dateUpdate['seconds']), 'dd/MM/yyyy', {
        locale: fr,
      }),
      author: user?.coordonnees?.email,
      keywords: operation?.type,
    });
  }

  exportPDF__header(
    user: any,
    operation: OperationId,
    note: OperationNoteId,
    doc: any
  ): any {
    //DATE
    const currentDate: string = format(new Date(Date.now()), 'dd/MM/yyyy', {
      locale: fr,
    });
    // OPERATION
    doc.setFontSize(12);
    doc.setFont('helvetica', 'normal', 'bold');
    doc.text(`${operation.denomination}`, 40, 90);
    doc.setFont('helvetica', 'normal', 'normal');
    doc.setFontSize(12);
    doc.text(`${operation.type}`, 40, 100);
    if (operation?.financeurs?.length) {
      operation?.financeurs.forEach((d: any) => {
        doc.text(`${d?.denomination}`, 40, 110);
      });
    }
    doc.setFont('helvetica', 'normal', 'normal');
    doc.setFontSize(10);
    doc.text(
      `${
        operation.description?.adresse?.adresse?.properties?.label
          ? operation?.description?.adresse?.adresse?.properties?.label
          : ''
      }`,
      40,
      120
    );

    //AUTEUR
    doc.setFontSize(10);
    doc.setFont('helvetica', 'normal', 'normal');
    doc.text(`Auteur: ${user.displayName}`, 40, 140);
    doc.text(`Date: ${currentDate}`, 40, 155);

    //NOTE
    doc.setFontSize(12);
    doc.text(`${note.title}`, 250, 90);
    doc.setFontSize(10);
    doc.setFont('helvetica', 'normal', 'normal');
    doc.text(
      `${
        note.adresse?.adresse?.properties.label
          ? note.adresse?.adresse?.properties.label
          : ''
      }`,
      250,
      100
    );
  }

  exportPDF__participants(
    user: any,
    operation: OperationId,
    note: OperationNoteId,
    participants: any,
    doc: any,
    logo: any
  ): any {
    if (participants && participants.length) {
      participants.forEach((p: any) => {
        // HEAD
        const head: any[] = [
          [
            {
              content: p.qualite,
              colSpan: 7,
              styles: {
                font: 'helvetica',
                halign: 'center',
                fontStyle: 'bold',
                fillColor: [0, 0, 0],
                textColor: [255, 255, 255],
                fontSize: 9,
              },
            },
          ],
          [
            {
              content: 'Entités',
              styles: {
                fontStyle: 'normal',
                fillColor: [124, 124, 124],
                textColor: [225, 225, 225],
              },
            },
            {
              content: 'Représentants',
              styles: {
                fontStyle: 'normal',
                fillColor: [124, 124, 124],
                textColor: [225, 225, 225],
              },
            },
            {
              content: 'Coordonnées',
              styles: {
                fontStyle: 'normal',
                fillColor: [124, 124, 124],
                textColor: [225, 225, 225],
              },
            },
            {
              content: 'P',
              styles: {
                halign: 'center',
                fontStyle: 'normal',
                fillColor: [124, 124, 124],
                textColor: [225, 225, 225],
              },
            },
            {
              content: 'A',
              styles: {
                halign: 'center',
                fontStyle: 'normal',
                fillColor: [124, 124, 124],
                textColor: [225, 225, 225],
              },
            },
            {
              content: 'C',
              styles: {
                halign: 'center',
                fontStyle: 'normal',
                fillColor: [124, 124, 124],
                textColor: [225, 225, 225],
              },
            },
            {
              content: 'D',
              styles: {
                halign: 'center',
                fontStyle: 'normal',
                fillColor: [124, 124, 124],
                textColor: [225, 225, 225],
              },
            },
          ],
        ];

        // BODY PARTICIPANT
        const body: any[] = [];

        p.representants.forEach((r: any) => {
          body.push([
            /*                       {
            content: `${r.contact.entite} - ${r.contact.adresse.numero}, ${r.contact.adresse.rue} - ${r.contact.adresse.commune} ${r.contact.adresse.codePostal}`,
          }, */
            {
              content: `${r.contact.civilite}. ${r.contact.firstName} ${r.contact.lastName} - ${r.contact.coordonnees.email}`,
            },
            {
              content: `${r.contact.coordonnees.mobile}  ${r.contact.coordonnees.fixe}`,
            },
            {
              content: this.getStatut(r.present),
              styles: {
                halign: 'center',
                fontStyle: 'bold',
              },
            },
            {
              content: this.getStatut(r.absent),
              styles: {
                halign: 'center',
                fontStyle: 'bold',
              },
            },
            {
              content: this.getStatut(r.convoque),
              styles: {
                halign: 'center',
                fontStyle: 'bold',
              },
            },
            {
              content: this.getStatut(r.diffuse),
              styles: {
                halign: 'center',
                fontStyle: 'bold',
              },
            },
          ]);
        });

        // FOOT PARTICIPANT

        doc.autoTable({
          theme: 'grid',
          head: head,
          body: body,
          /* foot: foot, */
          styles: {
            cellPadding: 0.5,
            fontSize: 8,
            textColor: [0, 0, 0],
          },
          headStyles: {
            /* fillColor: [0, 0, 0], textColor: [255, 255, 255] */
          },
          columnStyles: {
            0: { cellWidth: 160 },
            1: { cellWidth: 150 },
            2: { cellWidth: 80 },
            P: { cellWidth: 50 },
            A: { cellWidth: 50 },
            C: { cellWidth: 50 },
            D: { cellWidth: 50 },
          },
          bodyStyles: {
            0: { fontStyle: 'bold' },
          },
          didDrawPage: (data: any) => {
            /* PAGE HEADER */
            // Author
            doc.setFont('helvetica', 'normal', 'normal');

            // Set logo organisme
            doc.setFontSize(10);
            doc.addImage(logo, 'PNG', 10, 10, 100, 60);

            // Set organisme
            doc.setFontSize(14);
            doc.setFont('helvetica', 'normal', 'bold');
            doc.text(`${user.organisation.nom_raison_sociale}`, 260, 40);

            /* PAGE FOOTER */

            const str = `Page ${doc.internal.getNumberOfPages()}`;
            /*  if (typeof doc.putTotalPages === 'function') {
           str = `${data.pageCount} sur ${str}`;
         } */
            doc.setFontSize(7);
            doc.setFont('helvetica', 'normal', 'normal');
            doc.text(`${user.organisation.nom_raison_sociale}`, 280, 810);
            doc.text(`${str}`, 550, 810);
          },
          margin: { left: 10, top: 220, right: 10 },
        });
      });
    } else {
      const head = [
        [
          {
            content: '',
            colSpan: 7,
            styles: {
              font: 'helvetica',
              halign: 'center',
              fontStyle: 'bold',
              fillColor: [0, 0, 0],
              textColor: [255, 255, 255],
              fontSize: 9,
            },
          },
        ],
        [
          {
            content: 'Entités',
            styles: {
              fontStyle: 'normal',
              fillColor: [124, 124, 124],
              textColor: [225, 225, 225],
            },
          },
          {
            content: 'Représentants',
            styles: {
              fontStyle: 'normal',
              fillColor: [124, 124, 124],
              textColor: [225, 225, 225],
            },
          },
          {
            content: 'Coordonnées',
            styles: {
              fontStyle: 'normal',
              fillColor: [124, 124, 124],
              textColor: [225, 225, 225],
            },
          },
          {
            content: 'P',
            styles: {
              halign: 'center',
              fontStyle: 'normal',
              fillColor: [124, 124, 124],
              textColor: [225, 225, 225],
            },
          },
          {
            content: 'A',
            styles: {
              halign: 'center',
              fontStyle: 'normal',
              fillColor: [124, 124, 124],
              textColor: [225, 225, 225],
            },
          },
          {
            content: 'C',
            styles: {
              halign: 'center',
              fontStyle: 'normal',
              fillColor: [124, 124, 124],
              textColor: [225, 225, 225],
            },
          },
          {
            content: 'D',
            styles: {
              halign: 'center',
              fontStyle: 'normal',
              fillColor: [124, 124, 124],
              textColor: [225, 225, 225],
            },
          },
        ],
      ];

      const body: any = [];

      doc.autoTable({
        theme: 'grid',
        head: head,
        body: body,
        /* foot: foot, */
        styles: {
          cellPadding: 0.5,
          fontSize: 8,
          textColor: [0, 0, 0],
        },
        headStyles: {
          /* fillColor: [0, 0, 0], textColor: [255, 255, 255] */
        },
        columnStyles: {
          0: { cellWidth: 160 },
          1: { cellWidth: 150 },
          2: { cellWidth: 80 },
          P: { cellWidth: 50 },
          A: { cellWidth: 50 },
          C: { cellWidth: 50 },
          D: { cellWidth: 50 },
        },
        bodyStyles: {
          0: { fontStyle: 'bold' },
        },
        didDrawPage: (data: any) => {
          /* PAGE HEADER */

          // Set logo organisme
          doc.setFontSize(10);
          doc.addImage(logo, 'PNG', 40, 2, 80, 80);

          // Set organisme
          doc.setFontSize(14);
          doc.setFont('helvetica', 'normal', 'normal');
          doc.text(`${user.organisation.nom_raison_sociale}`, 260, 40);

          /* PAGE FOOTER */

          const str = `Page ${doc.internal.getNumberOfPages()}`;
          /*  if (typeof doc.putTotalPages === 'function') {
         str = `${data.pageCount} sur ${str}`;
       } */
          doc.setFontSize(7);
          doc.setFont('helvetica', 'normal', 'normal');
          doc.text(`${user.organisation.nom_raison_sociale}`, 280, 810);
          doc.text(`${user.organisation?.geo_adresse}`, 250, 817);
          doc.text(`${str}`, 550, 810);
        },
        margin: { left: 10, top: 220, right: 10 },
      });
    }

    doc.autoTable({
      head: [
        [
          {
            content: 'P: Présent',
            styles: {
              font: 'helvetica',
              halign: 'left',
              fontStyle: 'italic',
              fontSize: 9,
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              cellPadding: 1,
            },
          },
          {
            content: 'A: Absent',
            styles: {
              font: 'helvetica',
              halign: 'left',
              fontStyle: 'italic',
              fontSize: 9,
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              cellPadding: 1,
            },
          },
          {
            content: 'C: Convoqué',
            styles: {
              font: 'helvetica',
              halign: 'left',
              fontStyle: 'italic',
              fontSize: 9,
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              cellPadding: 1,
            },
          },
          {
            content: 'D: Diffusé',
            styles: {
              font: 'helvetica',
              halign: 'left',
              fontStyle: 'italic',
              fontSize: 9,
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              cellPadding: 1,
            },
          },
        ],
      ],
      styles: { cellPadding: 0.5, fontSize: 9 },
      columnStyles: {
        0: { cellWidth: 250 },
        1: { cellWidth: 250 },
        2: { cellWidth: 250 },
        3: { cellWidth: 250 },
      },
      margin: { left: 10, right: 10 },
    });
  }

  exportPDF__note__legende(doc: any): any {
    doc.setDrawColor(0);
    doc.setFillColor(0, 0, 0);
    doc.rect(10, 90, 15, 15, 'F');
    doc.setFont('helvetica', 'normal', 'normal');
    doc.setFontSize(10);
    doc.text('Titres', 30, 102);

    doc.setDrawColor(0);
    doc.setFillColor(128, 128, 128);
    doc.rect(60, 90, 15, 15);
    doc.text('Sujets', 80, 102);
  }

  exportPDF__subtitles(
    user: any,
    doc: any,
    subtitles: any[],
    sujets: any[],
    logo: any
  ): any {
    //COLOR TEMPLATE
    const black_color = [0, 0, 0];
    const white_color = [255, 255, 255];

    let head = [
      {
        content: `ORDRES DU JOUR`,
      },
    ];

    let body: any[] = flatten(
      subtitles.map((subtitle: OperationNoteSubtitleId, index: number) => {
        index += 1;

        let row = [
          {
            content: upperCase(`${index} '-' ${subtitle.title}`),
          },
        ];

        return row;
      })
    );

    doc.autoTable({
      theme: 'plain',
      head: head,
      body: body,
      styles: {
        cellPadding: 1,
        fontSize: 9,
        overflow: 'linebreak',
      },
      columnWidth: 'wrap',

      headStyles: {
        textColor: black_color,
        fillColor: white_color,
        fontSize: 13,
        font: 'helvetica',
        cellPadding: 6,
        rowPageBreak: 'auto',
      },
      bodyStyles: {
        textColor: black_color,
        fillColor: white_color,
        fontSize: 11,
        font: 'helvetica',
        cellPadding: 6,
        rowPageBreak: 'auto',
      },

      footStyles: {
        cellPadding: 2,
        fontSize: 8,
        font: 'helvetica',
        fontStyle: 'normal',
        fillColor: [124, 124, 124],
        textColor: [255, 255, 255],
      },
      margin: { left: 10, top: 110, right: 10 },
    });
  }

  exportPDF__sujets(
    user: any,
    doc: any,
    subtitles: any[],
    sujets: any[],
    logo: any
  ): any {
    //COLOR TEMPLATE
    const black_color = [0, 0, 0];
    const white_color = [255, 255, 255];
    const grey_color = [243, 243, 243];
    const red_color = [245, 34, 45];
    const orange_color = [250, 140, 22];
    const blue_color = [24, 144, 255];
    const green_color = [160, 217, 17];

    sujets.forEach((sujetGroup: any[], index: number) => {
      index += 1;
      let head: any[] = [
        [
          {
            content: upperCase(`
                          ${index} '-' ${sujetGroup[0].subtitle.title}`),
            colSpan: 2,
            styles: {
              font: 'helvetica',
              fontStyle: 'bold',
              fontSize: 9,
              fillColor: [0, 0, 0],
              textColor: [255, 255, 255],
              cellPadding: 6,
              rowPageBreak: 'auto',
            },
          },
          {
            content: `Crée le : ${format(
              fromUnixTime(sujetGroup[0].subtitle?.dateStart['seconds']),
              'dd/MM/yyyy',
              { locale: fr }
            )}`,
            colSpan: 1,
            styles: {
              font: 'helvetica',
              fontStyle: 'normal',
              valign: 'center',
              fontSize: 8,
              fillColor: [0, 0, 0],
              textColor: [255, 255, 255],
              cellPadding: 2,
              rowPageBreak: 'auto',
            },
          },
          {
            content: `Modifié le : ${format(
              fromUnixTime(sujetGroup[0].subtitle?.dateUpdate['seconds']),
              'dd/MM/yyyy',
              { locale: fr }
            )}`,
            colSpan: 1,
            styles: {
              font: 'helvetica',
              fontStyle: 'normal',
              valign: 'center',
              fontSize: 8,
              fillColor: [0, 0, 0],
              textColor: [255, 255, 255],
              cellPadding: 2,
              rowPageBreak: 'auto',
            },
          },
          {
            content: ``,
            colSpan: 1,
            styles: {
              font: 'helvetica',
              fontStyle: 'normal',
              valign: 'center',
              fontSize: 8,
              fillColor: [0, 0, 0],
              textColor: [255, 255, 255],
              cellPadding: 2,
              rowPageBreak: 'auto',
            },
          },
        ],
        [
          {
            content: `Sujet`,
            colSpan: 2,
            styles: {
              font: 'helvetica',
              halign: 'left',
              fontSize: 8,
              fillColor: [255, 255, 255],
              textColor: [124, 124, 124],
              cellPadding: 6,
              rowPageBreak: 'auto',
            },
          },
          {
            content: 'Crée le',
            colSpan: 1,

            styles: {
              font: 'helvetica',
              halign: 'left',
              fontSize: 8,
              fillColor: [255, 255, 255],
              textColor: [124, 124, 124],
              cellPadding: 6,
              rowPageBreak: 'auto',
            },
          },
          {
            content: 'Modifié le',
            colSpan: 1,

            styles: {
              font: 'helvetica',
              halign: 'left',
              fontSize: 8,
              fillColor: [255, 255, 255],
              textColor: [124, 124, 124],
              cellPadding: 6,
              rowPageBreak: 'auto',
            },
          },
          {
            content: 'Fait',
            colSpan: 1,

            styles: {
              font: 'helvetica',
              halign: 'left',
              fontSize: 8,
              fillColor: [255, 255, 255],
              textColor: [124, 124, 124],
              cellPadding: 6,
              rowPageBreak: 'auto',
            },
          },
        ],
      ];
      doc.autoTable({
        theme: 'grid',
        head: head,
        styles: {
          cellPadding: 1,
          fontSize: 9,
          overflow: 'linebreak',
        },
        columnWidth: 'wrap',
        bodyStyles: { textColor: [0, 0, 0], fontSize: 9 },
        columnStyles: {
          0: { cellWidth: 275 },
          1: { cellWidth: 75 },
          2: { cellWidth: 75 },
          3: { cellWidth: 75 },
        },

        footStyles: {
          cellPadding: 2,
          fontSize: 8,
          font: 'helvetica',
          fontStyle: 'normal',
          fillColor: [124, 124, 124],
          textColor: [255, 255, 255],
        },
        margin: { left: 10, top: 110, right: 10 },

        didDrawPage: (data: any) => {
          /* PAGE HEADER */
          // Set logo organisme
          doc.setFontSize(10);
          doc.addImage(logo, 'PNG', 40, 2, 80, 80);

          // Set organisme
          doc.setFontSize(14);
          doc.setFont('helvetica', 'normal', 'normal');
          doc.text(`${user.organisation.nom_raison_sociale}`, 260, 40);

          /* PAGE FOOTER */
          const str = `Page ${doc.internal.getNumberOfPages()}`;
          /* if (typeof doc.putTotalPages === 'function') {
            str = `${data.pageCount} sur ${str}`;
          } */
          doc.setFontSize(7);
          doc.setFont('helvetica', 'normal', 'normal');
          doc.text(`${user.organisation.nom_raison_sociale}`, 280, 810);
          doc.text(`${user.organisation?.geo_adresse}`, 250, 817);
          doc.text(`${str}`, 550, 810);
        },
      });

      let sujet: any[] = sujetGroup.map((el) => {
        //const html = this.strToHtml(el.textHTML).innerHTML;
        const headSujet = [
          {
            content: el.text,
            colSpan: 2,
            styles: {
              font: 'helvetica',
              fontStyle: 'normal',
              fontSize: 10,
              textColor: el?.isWarning ? red_color : black_color,
              cellPadding: 4,
              rowPageBreak: 'auto',
            },
          },
          {
            content: format(
              fromUnixTime(el.dateStart['seconds']),
              'dd/MM/yyyy',
              { locale: fr }
            ),
            colSpan: 1,
            styles: {
              font: 'helvetica',
              fontStyle: 'normal',
              halign: 'center',
              valign: 'center',

              fontSize: 8,
              cellPadding: 4,
              rowPageBreak: 'auto',
            },
          },
          {
            content: format(
              fromUnixTime(el.dateUpdate['seconds']),
              'dd/MM/yyyy',
              { locale: fr }
            ),
            colSpan: 1,
            styles: {
              font: 'helvetica',
              fontStyle: 'normal',
              halign: 'center',
              valign: 'center',

              fontSize: 8,
              cellPadding: 4,
              rowPageBreak: 'auto',
            },
          },
          {
            content: `${el?.isDone ? 'Réalisé' : ''}`,
            colSpan: 1,
            styles: {
              font: 'helvetica',
              fontStyle: 'normal',
              halign: 'center',
              valign: 'center',
              textColor: el?.isDone ? green_color : black_color,

              fontSize: 8,
              cellPadding: 4,

              rowPageBreak: 'auto',
            },
          },
        ];
        const commentSujet = flatten(
          el?.children.map((child: any) => {
            return [
              {
                content: child.text,
                colSpan: 2,
                styles: {
                  font: 'helvetica',
                  fontStyle: 'normal',
                  fontSize: 10,
                  cellPadding: 4,
                  fillColor: grey_color,
                  textColor: black_color,

                  rowPageBreak: 'auto',
                },
              },
              {
                content: format(
                  fromUnixTime(child.dateStart['seconds']),
                  'dd/MM/yyyy',
                  { locale: fr }
                ),
                colSpan: 1,
                styles: {
                  font: 'helvetica',
                  fontStyle: 'normal',
                  halign: 'center',
                  valign: 'center',
                  fillColor: grey_color,
                  textColor: black_color,

                  fontSize: 8,
                  cellPadding: 4,
                  rowPageBreak: 'auto',
                },
              },
              {
                content: child.isWarning ? 'Alerte' : '',
                colSpan: 1,
                styles: {
                  font: 'helvetica',
                  fontStyle: 'bold',
                  halign: 'center',
                  valign: 'center',
                  fontSize: 8,
                  cellPadding: 4,
                  fillColor: child?.isWarning ? red_color : white_color,
                  textColor: child?.isWarning ? white_color : black_color,
                  rowPageBreak: 'auto',
                },
              },
              {
                content: '',
                colSpan: 1,
                styles: {
                  font: 'helvetica',
                  fontStyle: 'normal',
                  halign: 'center',
                  valign: 'center',
                  fillColor: grey_color,
                  textColor: black_color,

                  fontSize: 8,
                  cellPadding: 4,
                  rowPageBreak: 'auto',
                },
              },
            ];
          })
        );

        return headSujet;
      });

      let body: any[] = sujetGroup.map((el) => {
        //const html = this.strToHtml(el.textHTML).innerHTML;
        return flatten(
          el?.children.map((child: any) => {
            return [
              {
                content: child.text,
                colSpan: 2,
                styles: {
                  font: 'helvetica',
                  fontStyle: 'normal',
                  fontSize: 10,
                  cellPadding: 4,
                  fillColor: grey_color,
                  textColor: black_color,

                  rowPageBreak: 'auto',
                },
              },
              {
                content: format(
                  fromUnixTime(child.dateStart['seconds']),
                  'dd/MM/yyyy',
                  { locale: fr }
                ),
                colSpan: 1,
                styles: {
                  font: 'helvetica',
                  fontStyle: 'normal',
                  halign: 'center',
                  valign: 'center',
                  fillColor: grey_color,
                  textColor: black_color,

                  fontSize: 8,
                  cellPadding: 4,
                  rowPageBreak: 'auto',
                },
              },
              {
                content: child.isWarning ? 'Alerte' : '',
                colSpan: 1,
                styles: {
                  font: 'helvetica',
                  fontStyle: 'bold',
                  halign: 'center',
                  valign: 'center',
                  fontSize: 8,
                  cellPadding: 4,
                  fillColor: child?.isWarning ? red_color : white_color,
                  textColor: child?.isWarning ? white_color : black_color,
                  rowPageBreak: 'auto',
                },
              },
              {
                content: '',
                colSpan: 1,
                styles: {
                  font: 'helvetica',
                  fontStyle: 'normal',
                  halign: 'center',
                  valign: 'center',
                  fillColor: grey_color,
                  textColor: black_color,

                  fontSize: 8,
                  cellPadding: 4,
                  rowPageBreak: 'auto',
                },
              },
            ];
          })
        );
      });

      let foot: any[] = [];

      //         subtitle.sujets.forEach((sujet: OperationNoteSujetId) => {
      //           const row__SUJET = [
      //             {
      //               content: sujet.title,
      //               startY: 10,
      //               styles: {
      //                 halign: 'left',
      //                 fontStyle: 'bold',
      //                 rowPageBreak: 'auto',
      //                 cellWidth: 200,
      //                 cellPadding: 3,
      //               },
      //             },
      //             {
      //               content: this.strToHtml(sujet.textHTML).innerHTML
      //                 ? this.strToHtml(sujet.textHTML).innerHTML
      //                 : '',
      //               // this.strToHtml(
      //               //   sujet.textHTML
      //               // ) /* .replace(/ (<([^>] +) >) / ig, "") */,
      //               startY: 10,
      //               styles: {
      //                 halign: 'left',
      //                 rowPageBreak: 'auto',
      //                 cellWith: 800,
      //                 cellPadding: 3,
      //                 overflow: 'linebreak',
      //               },
      //             },
      //           ];

      //           body.push(row__SUJET);

      //           sujet.actions.forEach((a) => {
      //             const row__statut_ACTION = [
      //               {
      //                 content: 'Action',
      //                 styles: {
      //                   halign: 'left',
      //                   cellWidth: 200,
      //                   fontStyle: 'normal',
      //                   rowPageBreak: 'auto',
      //                   fillColor: grey_color,
      //                   textColor: black_color,
      //                 },
      //               },

      //               {
      //                 content: a.statut,
      //                 styles: {
      //                   halign: 'left',
      //                   fontStyle: 'bold',
      //                   cellWidth: 'warp',
      //                   rowPageBreak: 'auto',
      //                   fillColor: grey_color,
      //                   textColor:
      //                     a.statut === 'En attente'
      //                       ? blue_color
      //                       : a.statut === 'En cours'
      //                       ? orange_color
      //                       : a.statut === 'Relance'
      //                       ? red_color
      //                       : a.statut === 'Réalisée'
      //                       ? green_color
      //                       : white_color,
      //                 },
      //               },
      //             ];

      //             body.push(row__statut_ACTION);

      //             const row__ACTION = [
      //               {
      //                 content: a.text,
      //                 styles: {
      //                   halign: 'left',
      //                   cellWidth: 200,
      //                   rowPageBreak: 'auto',
      //                   fillColor: grey_color,
      //                   textColor: black_color,
      //                 },
      //               },

      //               {
      //                 content: `Démarrage : ${format(
      //                   fromUnixTime(a.dates[0]['seconds']),
      //                   'dd/MM/yyyy',
      //                   {
      //                     locale: fr,
      //                   }
      //                 )} - Échéance : ${format(
      //                   fromUnixTime(a.dates[1]['seconds']),
      //                   'dd/MM/yyyy',
      //                   { locale: fr }
      //                 )}
      // Référent(s) : ${a?.responsables?.length ? a.responsables : ''} `,
      //                 styles: {
      //                   halign: 'left',
      //                   fontStyle: 'normal',
      //                   cellWidth: 'warp',
      //                   rowPageBreak: 'auto',
      //                   fillColor: grey_color,
      //                   textColor: black_color,
      //                 },
      //               },
      //             ];

      //             body.push(row__ACTION);
      //           });
      //         });

      doc.autoTable({
        theme: 'grid',
        body: sujet,
        styles: {
          cellPadding: 1,
          fontSize: 9,
          overflow: 'linebreak',
        },
        columnWidth: 'wrap',
        headStyles: { textColor: black_color, fillColor: white_color },
        bodyStyles: { textColor: [0, 0, 0], fontSize: 9 },
        columnStyles: {
          0: { cellWidth: 275 },
          1: { cellWidth: 75 },
          2: { cellWidth: 75 },
          3: { cellWidth: 75 },
        },

        footStyles: {
          cellPadding: 2,
          fontSize: 8,
          font: 'helvetica',
          fontStyle: 'normal',
          fillColor: [124, 124, 124],
          textColor: [255, 255, 255],
        },
        margin: { left: 10, top: 110, right: 10 },

        didDrawPage: (data: any) => {
          /* PAGE HEADER */
          doc.addImage(logo, 'PNG', 40, 2, 80, 80);

          // Set organisme
          doc.setFontSize(14);
          doc.setFont('helvetica', 'normal', 'normal');
          doc.text(`${user.organisation.nom_raison_sociale}`, 260, 40);

          /* PAGE FOOTER */
          const str = `Page ${doc.internal.getNumberOfPages()}`;
          /* if (typeof doc.putTotalPages === 'function') {
            str = `${data.pageCount} sur ${str}`;
          } */
          doc.setFontSize(7);
          doc.setFont('helvetica', 'normal', 'normal');
          doc.text(`${user.organisation.nom_raison_sociale}`, 280, 810);
          doc.text(`${user.organisation?.geo_adresse}`, 250, 817);
          doc.text(`${str}`, 550, 810);
        },
      });
    });
  }

  //EXPORT WORD VERSION

  export__WORD(
    operation: OperationId,
    note: OperationNoteId,
    subtitles: OperationNoteSubtitleId[]
  ): Observable<any> {
    try {
      return this.userStore.pipe(
        select(fromUserSelector.user),
        switchMap((user) => {
          if (!user || !note || !operation) {
            return EMPTY;
          }

          const currentDate: string = format(
            new Date(new Date()),
            'dd/MM/yyyy',
            {
              locale: fr,
            }
          );

          const doc = new Document({
            creator: `${user?.displayName}`,
            description: `${operation.description?.definition}`,
            title: `${note.title}`,
            keywords: operation.type,
            sections: [
              {
                properties: {},
                headers: {
                  default: new Header({
                    children: [
                      new Paragraph(`${user.organisation.denomination}`),
                    ],
                  }),
                },
                footers: {
                  default: new Footer({
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun(`${user.organisation.denomination}`),
                          new TextRun({
                            children: [' '],
                          }),
                          new TextRun({
                            children: ['Page: ', PageNumber.CURRENT],
                          }),
                          new TextRun({
                            children: [' sur ', PageNumber.TOTAL_PAGES],
                          }),
                        ],
                      }),
                    ],
                  }),
                },
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: `${operation.denomination}`,
                        bold: true,
                      }),
                    ],
                  }),
                  new Paragraph({
                    children: [
                      new TextRun({ text: `${operation.type}`, bold: false }),
                    ],
                  }),
                  new Paragraph({
                    children: [
                      new TextRun({ text: `${note.title}`, bold: true }),
                    ],
                  }),
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: `Auteur : ${user?.displayName}`,
                        bold: false,
                      }),
                    ],
                  }),
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: `Date : ${currentDate}`,
                        bold: false,
                      }),
                    ],
                  }),
                ],
              },
            ],
          });

          const docTitle: string = `CR_${operation.denomination}_${currentDate}`;
          return Packer.toBlob(doc)
            .then((blob) => {
              saveAs(blob, `${docTitle}.docx`);
            })
            .then(() => `Compte rendu téléchargé`)
            .catch((err) => err.message);
        })
      );
    } catch (err: any) {
      return of(err.message);
    }
  }

  getIndex(item: number): number {
    const numb = item + 1;
    return numb;
  }

  support = (function () {
    if (!window.DOMParser) return false;
    var parser = new DOMParser();
    try {
      parser.parseFromString('x', 'text/html');
    } catch (err) {
      return false;
    }
    return true;
  })();

  strToHtml(string: string) {
    if (this.support) {
      var parser = new DOMParser();
      var doc = parser.parseFromString(string, 'text/html');

      return doc.body;
    }

    var dom = document.createElement('div');
    dom.innerHTML = string;
    return dom;

    // const parser = new DOMParser();
    // const doc = parser.parseFromString(string, 'text/xml');
    // const html = doc.body;

    // return html;
  }

  getStatut(statut: boolean): string {
    if (statut !== true) {
      return '';
    } else {
      return 'X';
    }
  }

  getDate(item: any): Date {
    const date = item['seconds'] * 1000;
    return new Date(date);
  }

  pageBreak(): any {
    // const table = document.getElementById('idSujetTbale').offsetHeight;
    // const el = document.getElementById('idTitle').offsetHeight;
    // const header = document.getElementById('idHeader').offsetHeight;
    // const sujet = document.getElementById('rowSujet').offsetHeight;
    // const action = document.getElementById('rowAction').offsetHeight;
  }

  itemHeigh(item: any): any {
    const h = item.clientHeight;
    if (h > 200) {
      return 'break';
    }
  }
}
