import { OperationPrescripteursModule } from './../operation-prescripteurs/operation-prescripteurs.module';
import { UserModule } from './../user/user.module';
import { OperationParticipantsModule } from './../operation-participants/operation-participants.module';
import { OperationEntitesModule } from './../operation-entites/operation-entites.module';
import { OperationExportPdfService } from './../../service/operation-export-pdf.service';
import { OperationExportExcelService } from './../../service/operation-export-excel.service';
import { OperationGanttModule } from './../operation-gantt/operation-gantt.module';
import { OperationCadrageModule } from './../operation-cadrage/operation-cadrage.module';
import { OperationTaskModule } from './../operation-task/operation-task.module';
import { OperationParticipantsService } from './../../service/operation-participants.service';
import { OperationContactsService } from './../../service/operation-contacts.service';
import { OperationEntitesService } from './../../service/operation-entites.service';
import { OperationDocumentsComponent } from './components/operation-documents/operation-documents.component';
import { ModuleModule } from './../module/module.module';
import { ActionModule } from './../action/action.module';
import { OperationSuivisComponent } from './components/operation-suivis/operation-suivis.component';
import { OperationStorageService } from './../../service/operation-storage.service';
import { OperationSuivisService } from './../../service/operation-suivis.service';
import { PortalNavigationService } from './../../portals/portal-navigation.service';
import { QueryOperationsService } from './../../service/query-operations.service';
import { OperationItemComponent } from './components/operation-item/operation-item.component';
import { OperationResolverService } from './../../resolvers/operation-resolver.service';
import { OperationsResolverService } from './../../resolvers/operations-resolver.service';
import { OperationService } from './../../service/operation.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { ContentsModule } from './../../contents/contents.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OperationRoutingModule } from './operation-routing.module';
import { OperationComponent } from './components/operation/operation.component';
import { OperationListComponent } from './components/operation-list/operation-list.component';
import { OperationCardComponent } from './components/operation-card/operation-card.component';
import { OperationFormComponent } from './components/operation-form/operation-form.component';
import { StoreModule } from '@ngrx/store';
import * as fromOperation from './store/operation.reducer';
import { EffectsModule } from '@ngrx/effects';
import { OperationEffects } from './store/operation.effects';
import { OperationActionsComponent } from './components/operation-actions/operation-actions.component';
import { OperationDetailsComponent } from './components/operation-details/operation-details.component';
import { OperationTimeComponent } from './components/operation-time/operation-time.component';
import { OperationFiltersComponent } from './components/operation-filters/operation-filters.component';
import { OperationItemHeaderComponent } from './components/operation-item-header/operation-item-header.component';
import { MissionsModule } from '../missions/missions.module';
import { OperationDashboardModule } from '../operation-dashboard/operation-dashboard.module';
import { ParticipantModule } from '../participant/participant.module';
import { DepartementModule } from '../departement/departement.module';
import { OperationExportPptService } from 'src/app/service/operation-export-ppt.service';
import { OperationSharingComponent } from './components/operation-sharing/operation-sharing.component';
import { OperationMissionsService } from 'src/app/service/operation-missions.service';
import { OperationSharingService } from 'src/app/service/operation-sharing.service';
import { UtilitiesService } from 'src/app/service/utilities/utilities.service';
import { FichesDePosteModule } from '../../features/fiches-de-poste/fiches-de-poste.module';
import { OperationContactsModule } from '../operation-contacts/operation-contacts.module';
import { OperationEventsModule } from '../operation-events/operation-events.module';
import { OperationNoteModule } from '../operation-note/operation-note.module';
import { OperationTeamModule } from '../operation-team/operation-team.module';
import { NoResultModule } from 'src/app/contents/components/no-result/no-result.module';
import { CoordonneesModule } from 'src/app/contents/components/coordonnees/coordonnees.module';
import { CommunesFranceModule } from 'src/app/contents/components/communes-france/communes-france.module';
import { LayoutModule } from 'src/app/contents/components/layout/layout.module';
import { HeaderModule } from 'src/app/contents/components/header-title/header.module';
import { AvatarModule } from 'src/app/features/avatar/avatar.module';
import { SuiviModule } from 'src/app/features/suivi/suivi.module';
import { ButtonsModule } from 'src/app/contents/components/buttons/buttons.module';
import { UiModule } from 'src/app/ui/ui/ui.module';
import { DocumentModule } from 'src/app/features/document/document.module';
@NgModule({
  declarations: [
    OperationComponent,
    OperationListComponent,
    OperationCardComponent,
    OperationFormComponent,
    OperationItemComponent,
    OperationActionsComponent,
    OperationDetailsComponent,
    OperationTimeComponent,
    OperationFiltersComponent,
    OperationSuivisComponent,
    OperationDocumentsComponent,
    OperationItemHeaderComponent,
    OperationSharingComponent,
  ],
  imports: [
    CommonModule,
    ContentsModule,
    SharedModule,
    ActionModule,
    ModuleModule,
    MissionsModule,
    OperationTaskModule,
    OperationDashboardModule,
    OperationCadrageModule,
    OperationGanttModule,
    OperationEventsModule,
    DepartementModule,
    ParticipantModule,
    OperationRoutingModule,
    FichesDePosteModule,
    OperationEntitesModule,
    OperationContactsModule,
    OperationParticipantsModule,
    OperationNoteModule,
    OperationPrescripteursModule,
    OperationTeamModule,
    UserModule,
    NoResultModule,
    CoordonneesModule,
    HeaderModule,
    CommunesFranceModule,
    LayoutModule,
    AvatarModule,
    SuiviModule,
    ButtonsModule,
    UiModule,
    DocumentModule,
    StoreModule.forFeature(
      fromOperation.operationsFeatureKey,
      fromOperation.reducer
    ),
    EffectsModule.forFeature([OperationEffects]),
  ],
  providers: [
    OperationService,
    OperationsResolverService,
    OperationResolverService,
    PortalNavigationService,
    OperationSuivisService,
    OperationStorageService,
    OperationEntitesService,
    OperationContactsService,
    OperationParticipantsService,
    OperationExportPptService,
    OperationExportExcelService,
    OperationExportPdfService,
    OperationMissionsService,
    OperationSharingService,
    UtilitiesService,
  ],
})
export class OperationModule {}
