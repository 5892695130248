import {
  OperationId,
  OperationCadrage,
} from './../../../operation/store/operation.model';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-operation-cadrage-indicators',
  templateUrl: './operation-cadrage-indicators.component.html',
  styleUrls: ['./operation-cadrage-indicators.component.scss'],
})
export class OperationCadrageIndicatorsComponent implements OnInit {
  @Input() operation: OperationId | any;
  @Input() cadrage: OperationCadrage | any;
  @Output() validation = new EventEmitter<any>(false);
  constructor() {}

  ngOnInit(): void {}
}
