import {
  ParticipantId,
  ProjetProfessionnelId,
} from './../../participant/store/participant.model';
import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { ProjetProfessionnel } from './projet-professionnel.model';

export const loadingProjetsProfessionnels = createAction(
  '[ProjetProfessionnel/COMPONENT] Loading ProjetProfessionnels',
  props<{ loading: boolean }>()
);

export const loadProjetsProfessionnels = createAction(
  '[ProjetProfessionnel/COMPONENT] Load Projets Professionnels',
  props<{ id: string }>()
);

export const loadProjetsProfessionnelsSuccess = createAction(
  '[ProjetProfessionnel/API] Load Projets Professionnels Success',
  props<{ projetProfessionnels: ProjetProfessionnelId[] }>()
);

export const loadProjetsProfessionnelsFailure = createAction(
  '[ProjetProfessionnel/API] Load Projets Professionnels Failure',
  props<{ error: any }>()
);

export const loadProjetProfessionnel = createAction(
  '[ProjetProfessionnel/COMPONENT] Load Projet Professionnel',
  props<{ participantId: string; id: string }>()
);

export const loadProjetProfessionnelSuccess = createAction(
  '[ProjetProfessionnel/API] Load Projet Professionnel Success',
  props<{ projetProfessionnel: ProjetProfessionnelId }>()
);

export const loadProjetProfessionnelFailure = createAction(
  '[ProjetProfessionnel/API] Load Projet Professionnel Failure',
  props<{ error: any }>()
);

export const addProjetProfessionnel = createAction(
  '[ProjetProfessionnel/COMPONENT] Add ProjetProfessionnel',
  props<{ id: string; projetProfessionnel: ProjetProfessionnel }>()
);
export const addProjetProfessionnelSuccess = createAction(
  '[ProjetProfessionnel/API] Add ProjetProfessionnel Success',
  props<{ success: string }>()
);
export const addProjetProfessionnelFailure = createAction(
  '[ProjetProfessionnel/API] Add ProjetProfessionnel Failure',
  props<{ error: any }>()
);

export const updateProjetProfessionnel = createAction(
  '[ProjetProfessionnel/COMPONENT] Update ProjetProfessionnel',
  props<{ id: string; projetProfessionnel: Update<ProjetProfessionnelId> }>()
);
export const updateProjetProfessionnelSuccess = createAction(
  '[ProjetProfessionnel/API] Update ProjetProfessionnel Success',
  props<{ success: string }>()
);
export const updateProjetProfessionnelFailure = createAction(
  '[ProjetProfessionnel/API] Update ProjetProfessionnel Failure',
  props<{ error: any }>()
);

export const deleteProjetProfessionnel = createAction(
  '[ProjetProfessionnel/COMPONENT] Delete ProjetProfessionnel',
  props<{ participantId: string; id: string }>()
);
export const deleteProjetProfessionnelSuccess = createAction(
  '[ProjetProfessionnel/API] Delete ProjetProfessionnel Success',
  props<{ success: string }>()
);
export const deleteProjetProfessionnelFailure = createAction(
  '[ProjetProfessionnel/API] Delete ProjetProfessionnel Failure',
  props<{ error: any }>()
);

export const deleteProjetProfessionnels = createAction(
  '[ProjetProfessionnel/COMPONENT] Delete ProjetProfessionnels',
  props<{ participantId: string; ids: string[] }>()
);

//STATISTIQUES EMPLOIS
export const loadStatistiquesEmploi = createAction(
  '[ProjetProfessionnel/COMPONENT] Load Statistiques Emploi',
  props<{ codeRome: string; areaCode: string; codeFap: string; age: number }>()
);
export const loadStatistiquesEmploiSuccess = createAction(
  '[ProjetProfessionnel/API] Load Statistiques Success',
  props<{ statistiques: any }>()
);
export const loadStatistiquesEmploiFailure = createAction(
  '[ProjetProfessionnel/API] Load Statistiques Failure',
  props<{ error: any }>()
);

//FORMATIONS SUGGESTIONS
export const loadFormationsSuggestions = createAction(
  '[ProjetProfessionnel/COMPONENT] Load Formations Suggestions',
  props<{
    rome: string;
    latitude: string;
    longitude: string;
    radius: string;
    diploma: string;
  }>()
);
export const loadFormationsSuggestionsSuccess = createAction(
  '[ProjetProfessionnel/API] Load Formations Suggestions Success',
  props<{ formations: any }>()
);
export const loadFormationsSuggestionsFailure = createAction(
  '[ProjetProfessionnel/API] Load Formations Suggestions Failure',
  props<{ error: any }>()
);

export const loadFormationsSuggestionsClear = createAction(
  '[ProjetProfessionnel/API] Load Formations Suggestions Clear'
);

//FORMATION SUGGESTION DETAILS
export const loadFormationSuggestionDetails = createAction(
  '[ProjetProfessionnel/COMPONENT] Load Formations suggestion details Emploi',
  props<{ libelle: string }>()
);
export const loadFormationSuggestionDetailsSuccess = createAction(
  '[ProjetProfessionnel/API] Load Formations suggestion details  Success',
  props<{ details: any }>()
);
export const loadFormationSuggestionDetailsFailure = createAction(
  '[ProjetProfessionnel/API] Load Formations suggestion details  Failure',
  props<{ error: any }>()
);

//LOAD FORMATIONS
export const LoadFormations = createAction(
  '[ProjetProfessionnel/COMPONENT] Load formations Emploi',
  props<{ participantId: string; projetpro: any }>()
);
export const LoadFormationsSuccess = createAction(
  '[ProjetProfessionnel/API] Load formations  Success',
  props<{ formations: any[] }>()
);
export const LoadFormationsFailure = createAction(
  '[ProjetProfessionnel/API] Load formations  Failure',
  props<{ error: any }>()
);

//FORMATIONS

//ADD FORMATION
export const addFormation = createAction(
  '[ProjetProfessionnel/COMPONENT] Add formation Emploi',
  props<{ participantId: string; projetpro: any; formation: any }>()
);
export const addFormationSuccess = createAction(
  '[ProjetProfessionnel/API] Add formation  Success',
  props<{ succes: string }>()
);
export const addFormationFailure = createAction(
  '[ProjetProfessionnel/API] Add formation  Failure',
  props<{ error: any }>()
);

//UPDATE FORMATION
export const updateFormation = createAction(
  '[ProjetProfessionnel/COMPONENT] Update formation Emploi',
  props<{ participantId: string; projetpro: any; formation: Update<any> }>()
);
export const updateFormationSuccess = createAction(
  '[ProjetProfessionnel/API] Update formation  Success',
  props<{ succes: string }>()
);
export const updateFormationFailure = createAction(
  '[ProjetProfessionnel/API] Update formation  Failure',
  props<{ error: any }>()
);

//DELETE FORMATION
export const deleteFormation = createAction(
  '[ProjetProfessionnel/COMPONENT] Delete formation Emploi',
  props<{ participantId: string; projetpro: any; id: string }>()
);
export const deleteFormationSuccess = createAction(
  '[ProjetProfessionnel/API] Delete formation  Success',
  props<{ succes: string }>()
);
export const deleteFormationFailure = createAction(
  '[ProjetProfessionnel/API] Delete formation  Failure',
  props<{ error: any }>()
);

// FORMATIONS JOBS
//load entites suggestions
export const loadFormationsJobs = createAction(
  '[ProjetProfessionnel/COMPONENT] Load Formations Jobs',
  props<{
    rome: string;
    latitude: string;
    longitude: string;
    radius: string;
    insee: string;
    source: string;
  }>()
);
export const loadFormationsJobsSuccess = createAction(
  '[ProjetProfessionnel/API] Load Formations Jobs Success',
  props<{ jobs: any }>()
);
export const loadFormationsJobsFailure = createAction(
  '[ProjetProfessionnel/API] Load Formations Jobs Failure',
  props<{ error: any }>()
);

//ENTITES
//load entites suggestions
export const loadEntitesSuggestions = createAction(
  '[ProjetProfessionnel/COMPONENT] Load Entite Suggestions',
  props<{
    code: string;
    contract: string;
    distance: string;
    latitude: string;
    longitude: string;
    page: number;
  }>()
);
export const loadEntitesSuggestionsSuccess = createAction(
  '[ProjetProfessionnel/API] Load Entite Suggestions Success',
  props<{ entitesSuggestions: any }>()
);
export const loadEntitesSuggestionsFailure = createAction(
  '[ProjetProfessionnel/API] Load Entite Suggestions Failure',
  props<{ error: any }>()
);

//load entites
export const loadEntitesProjetPro = createAction(
  '[ProjetProfessionnel/COMPONENT] Load Entite Projet Pro',
  props<{
    participantId: string;
    projetpro: any;
  }>()
);
export const loadEntitesProjetProSuccess = createAction(
  '[ProjetProfessionnel/API] Load Entite Projet Pro Success',
  props<{ entitesProjetPro: any[] }>()
);
export const loadEntitesProjetProFailure = createAction(
  '[ProjetProfessionnel/API] Load Entite Projet Pro Failure',
  props<{ error: any }>()
);

//add entite
export const addEntiteProjetPro = createAction(
  '[ProjetProfessionnel/COMPONENT] Add Entite Projet Pro',
  props<{
    participantId: string;
    projetpro: any;
    entite: any;
  }>()
);
export const addEntiteProjetProSuccess = createAction(
  '[ProjetProfessionnel/API] Add Entite Projet Pro Success',
  props<{ succes: string }>()
);
export const addEntiteProjetProFailure = createAction(
  '[ProjetProfessionnel/API] Add Entite Projet Pro Failure',
  props<{ error: any }>()
);

//update entite
export const updateEntiteProjetPro = createAction(
  '[ProjetProfessionnel/COMPONENT] Update Entite Projet Pro',
  props<{
    participantId: string;
    projetpro: any;
    entite: Update<any>;
  }>()
);
export const updateEntiteProjetProSuccess = createAction(
  '[ProjetProfessionnel/API] Update Entite Projet Pro Success',
  props<{ succes: string }>()
);
export const updateEntiteProjetProFailure = createAction(
  '[ProjetProfessionnel/API] Update Entite Projet Pro Failure',
  props<{ error: any }>()
);

//delete entite
export const deleteEntiteProjetPro = createAction(
  '[ProjetProfessionnel/COMPONENT] Delete Entite Projet Pro',
  props<{
    participantId: string;
    projetpro: any;
    id: string;
  }>()
);
export const deleteEntiteProjetProSuccess = createAction(
  '[ProjetProfessionnel/API] Delete Entite Projet Pro Success',
  props<{ succes: string }>()
);
export const deleteEntiteProjetProFailure = createAction(
  '[ProjetProfessionnel/API] Delete Entite Projet Pro Failure',
  props<{ error: any }>()
);

//CONTACTS
//load contacts suggestions
export const loadContactsSuggestions = createAction(
  '[ProjetProfessionnel/COMPONENT] Load Contacts Suggestions',
  props<{
    code: string;
    contract: string;
    distance: string;
    latitude: string;
    longitude: string;
  }>()
);
export const loadContactsSuggestionsSuccess = createAction(
  '[ProjetProfessionnel/API] Load Contacts Suggestions Success',
  props<{ contactsSuggestions: any }>()
);
export const loadContactsSuggestionsFailure = createAction(
  '[ProjetProfessionnel/API] Load Contacts Suggestions Failure',
  props<{ error: any }>()
);
//load contacts
export const loadContactProjetPro = createAction(
  '[ProjetProfessionnel/COMPONENT] Load Contact Projet Pro',
  props<{
    participantId: string;
    projetpro: any;
  }>()
);
export const loadContactProjetProSuccess = createAction(
  '[ProjetProfessionnel/API] Load Contact Projet Pro Success',
  props<{ contactsProjetPro: any[] }>()
);
export const loadContactProjetProFailure = createAction(
  '[ProjetProfessionnel/API] Load Contact Projet Pro Failure',
  props<{ error: any }>()
);

//add contact
export const addContactProjetPro = createAction(
  '[ProjetProfessionnel/COMPONENT] Add Contact Projet Pro',
  props<{
    participantId: string;
    projetpro: any;
    contact: any;
  }>()
);
export const addContactProjetProSuccess = createAction(
  '[ProjetProfessionnel/API] Add Contact Projet Pro Success',
  props<{ succes: string }>()
);
export const addContactProjetProFailure = createAction(
  '[ProjetProfessionnel/API] Add Contact Projet Pro Failure',
  props<{ error: any }>()
);

//update contact
export const updateContactProjetPro = createAction(
  '[ProjetProfessionnel/COMPONENT] Update Contact Projet Pro',
  props<{
    participantId: string;
    projetpro: any;
    contact: Update<any>;
  }>()
);
export const updateContactProjetProSuccess = createAction(
  '[ProjetProfessionnel/API] Update Contact Projet Pro Success',
  props<{ succes: string }>()
);
export const updateContactProjetProFailure = createAction(
  '[ProjetProfessionnel/API] Update Contact Projet Pro Failure',
  props<{ error: any }>()
);

//delete contact
export const deleteContactProjetPro = createAction(
  '[ProjetProfessionnel/COMPONENT] Delete Contact Projet Pro',
  props<{
    participantId: string;
    projetpro: any;
    id: string;
  }>()
);

//EVALUATIONS
//load évaluations
export const loadEvaluationProjetPro = createAction(
  '[ProjetProfessionnel/COMPONENT] Load Evaluation Projet Pro',
  props<{
    participantId: string;
    projetpro: any;
  }>()
);
export const loadEvaluationProjetProSuccess = createAction(
  '[ProjetProfessionnel/API] Load Evaluation Projet Pro Success',
  props<{ evaluations: any[] }>()
);
export const loadEvaluationProjetProFailure = createAction(
  '[ProjetProfessionnel/API] Load Evaluation Projet Pro Failure',
  props<{ error: any }>()
);

//add contact
export const addEvaluationProjetPro = createAction(
  '[ProjetProfessionnel/COMPONENT] Add Evaluation Projet Pro',
  props<{
    participantId: string;
    projetpro: any;
    evaluation: any;
  }>()
);
export const addEvaluationProjetProSuccess = createAction(
  '[ProjetProfessionnel/API] Add Evaluation Projet Pro Success',
  props<{ succes: string }>()
);
export const addEvaluationProjetProFailure = createAction(
  '[ProjetProfessionnel/API] Add Contact Projet Pro Failure',
  props<{ error: any }>()
);

//update contact
export const updateEvaluationProjetPro = createAction(
  '[ProjetProfessionnel/COMPONENT] Update Evaluation Projet Pro',
  props<{
    participantId: string;
    projetpro: any;
    evaluation: Update<any>;
  }>()
);
export const updateEvaluationProjetProSuccess = createAction(
  '[ProjetProfessionnel/API] Update Evaluation Projet Pro Success',
  props<{ succes: string }>()
);
export const updateEvaluationProjetProFailure = createAction(
  '[ProjetProfessionnel/API] Update Evaluation Projet Pro Failure',
  props<{ error: any }>()
);

//delete contact
export const deleteEvaluationProjetPro = createAction(
  '[ProjetProfessionnel/COMPONENT] Delete Evaluation Projet Pro',
  props<{
    participantId: string;
    projetpro: any;
    id: string;
  }>()
);

export const deleteEvaluationProjetProSuccess = createAction(
  '[ProjetProfessionnel/API] Delete Evaluation Projet Pro Success',
  props<{ succes: string }>()
);
export const deleteEvaluationProjetProFailure = createAction(
  '[ProjetProfessionnel/API] DeleteEvaluation Projet Pro Failure',
  props<{ error: any }>()
);

export const clearProjetProfessionnels = createAction(
  '[ProjetProfessionnel/API] Clear ProjetProfessionnels'
);

//TODOS
//load
export const loadTodoProjetpro = createAction(
  '[ProjetProfessionnel/COMPOENENT] LOAD todo projet professionalisation',
  props<{ participantId: string; projetpro: any }>()
);
export const loadTodoProjetproSuccess = createAction(
  '[ProjetProfessionnel/API] LOAD todo projet professionalisation Success',
  props<{ todos: any }>()
);
export const loadTodoProjetproFailure = createAction(
  '[ProjetProfessionnel/API] LOAD todo projet professionalisation Failure',
  props<{ error: any }>()
);

//add
export const addTodoProjetpro = createAction(
  '[ProjetProfessionnel/COMPOENENT] ADD todo projet professionalisation',
  props<{ participantId: string; projetpro: any; todo: any }>()
);
export const addTodoProjetproSuccess = createAction(
  '[ProjetProfessionnel/API] ADD todo projet professionalisation Success',
  props<{ success: string }>()
);
export const addTodoProjetproFailure = createAction(
  '[ProjetProfessionnel/API] ADD todo projet professionalisation Failure',
  props<{ error: any }>()
);
//update
export const updateTodoProjetpro = createAction(
  '[ProjetProfessionnel/COMPOENENT] UPDATE todo projet professionalisation',
  props<{ participantId: string; projetpro: any; todo: Update<any> }>()
);
export const updateTodoProjetproSuccess = createAction(
  '[ProjetProfessionnel/API] UPDATE todo projet professionalisation Success',
  props<{ success: string }>()
);
export const updateTodoProjetproFailure = createAction(
  '[ProjetProfessionnel/API] UPDATE todo projet professionalisation Failure',
  props<{ error: any }>()
);
//DELETE
export const deleteTodoProjetpro = createAction(
  '[ProjetProfessionnel/COMPOENENT] DELETE todo projet professionalisation',
  props<{ participantId: string; projetpro: any; id: string }>()
);
export const deleteTodoProjetproSuccess = createAction(
  '[ProjetProfessionnel/API] DELETE todo projet professionalisation Success',
  props<{ success: string }>()
);
export const deleteTodoProjetproFailure = createAction(
  '[ProjetProfessionnel/API] DELETE todo projet professionalisation Failure',
  props<{ error: any }>()
);

//EVALUATIONS
//load
export const loadEvaluationProjetpro = createAction(
  '[ProjetProfessionnel/COMPOENENT] LOAD evaluation projet professionalisation',
  props<{ participantId: string; projetpro: any }>()
);
export const loadEvaluationProjetproSuccess = createAction(
  '[ProjetProfessionnel/API] LOAD evaluation projet professionalisation Success',
  props<{ evaluations: any }>()
);
export const loadEvaluationProjetproFailure = createAction(
  '[ProjetProfessionnel/API] LOAD evaluation projet professionalisation Failure',
  props<{ error: any }>()
);
//add
export const addEvaluationProjetpro = createAction(
  '[ProjetProfessionnel/COMPOENENT] ADD evaluation projet professionalisation',
  props<{ participantId: string; projetpro: any; evaluation: any }>()
);
export const addEvaluationProjetproSuccess = createAction(
  '[ProjetProfessionnel/API] ADD evaluation projet professionalisation Success',
  props<{ success: string }>()
);
export const addEvaluationProjetproFailure = createAction(
  '[ProjetProfessionnel/API] ADD evaluation projet professionalisation Failure',
  props<{ error: any }>()
);
//update
export const updateEvaluationProjetpro = createAction(
  '[ProjetProfessionnel/COMPOENENT] UPDATE evaluation projet professionalisation',
  props<{
    participantId: string;
    projetpro: any;
    evaluation: Update<any>;
  }>()
);
export const updateEvaluationProjetproSuccess = createAction(
  '[ProjetProfessionnel/API] UPDATE evaluation projet professionalisation Success',
  props<{ success: string }>()
);
export const updateEvaluationProjetproFailure = createAction(
  '[ProjetProfessionnel/API] UPDATE evaluation projet professionalisation Failure',
  props<{ error: any }>()
);
//DELETE
export const deleteEvaluationProjetpro = createAction(
  '[ProjetProfessionnel/COMPOENENT] DELETE evaluation projet professionalisation',
  props<{ participantId: string; projetpro: any; id: string }>()
);
export const deleteEvaluationProjetproSuccess = createAction(
  '[ProjetProfessionnel/API] DELETE evaluation projet professionalisation Success',
  props<{ success: string }>()
);
export const deleteEvaluationProjetproFailure = createAction(
  '[ProjetProfessionnel/API] DELETE evaluation projet professionalisation Failure',
  props<{ error: any }>()
);

//EXPORT PDF
export const exportPDF = createAction(
  '[ProjetProfessionnel/COMPOENENT] EXPORT PDF  projet professionalisation',
  props<{ participant: any; projetpro: any }>()
);
export const exportPDFSuccess = createAction(
  '[ProjetProfessionnel/API] EXPORT PDF  projet professionalisation Success',
  props<{ success: string }>()
);
export const exportPDFFailure = createAction(
  '[ProjetProfessionnel/API] EXPORT PDF  projet professionalisation Failure',
  props<{ error: any }>()
);
