<form
  nz-form
  [formGroup]="exportForm"
  nzLayout="horizontal"
  *ngIf="user$ | async as user"
>
  <nz-card nzSize="small">
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="center">
      <nz-col nzSpan="6"></nz-col>
      <nz-col nzSpan="2">
        <nz-form-item>
          <nz-form-label nzSpan="24"></nz-form-label>
          <nz-form-control>
            <i
              nz-icon
              nzType="file-pdf"
              class="file-pdf"
              *ngIf="exportForm.value.type === 'PDF'"
            ></i>
            <i
              nz-icon
              *ngIf="exportForm.value.type === 'EXCEL'"
              nzType="file-excel"
              class="file-excel"
            ></i>

            <i
              nz-icon
              *ngIf="exportForm.value.type === 'WORD'"
              nzType="file-word"
              class="file-word"
            ></i>
            <i
              nz-icon
              *ngIf="exportForm.value.type === 'POWERPOINT'"
              nzType="file-powerpoint"
              class="file-ppt"
            ></i>
          </nz-form-control>
        </nz-form-item>
      </nz-col>
      <nz-col nzSpan="4">
        <nz-form-item>
          <nz-form-label nzSpan="24">Format</nz-form-label>
          <nz-form-control class="control-select">
            <nz-select
              formControlName="type"
              [compareWith]="selectCompare"
              nzPlaceHolder="Format du ficher"
              [nzShowArrow]="true"
              nzBorderless
            >
              <nz-option
                *ngFor="let item of types"
                [nzLabel]="item | titlecase"
                [nzValue]="item"
                nzCustomContent
              >
                <nz-space nzDirection="horizontal">
                  <ng-container *ngIf="item === 'PDF'">
                    <i
                      nz-icon
                      *nzSpaceItem
                      nzType="file-pdf"
                      class="file-pdf"
                    ></i>
                  </ng-container>
                  <ng-container *ngIf="item === 'EXCEL'">
                    <i
                      nz-icon
                      *nzSpaceItem
                      nzType="file-excel"
                      class="file-excel"
                    ></i>
                  </ng-container>
                  <ng-container *ngIf="item === 'WORD'">
                    <i
                      nz-icon
                      *nzSpaceItem
                      nzType="file-word"
                      class="file-word"
                    ></i>
                  </ng-container>
                  <ng-container *ngIf="item === 'POWERPOINT'">
                    <i
                      nz-icon
                      *nzSpaceItem
                      nzType="file-powerpoint"
                      class="file-ppt"
                    ></i>
                  </ng-container>

                  <span *nzSpaceItem></span>
                  <span *nzSpaceItem>{{ item | titlecase }}</span>
                </nz-space>
              </nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </nz-col>
      <nz-col nzSpan="4">
        <nz-form-item>
          <nz-form-label nzSpan="24">Anonymiser</nz-form-label>
          <nz-form-control>
            <label nz-checkbox formControlName="isAnonyme">
              {{ exportForm.value.isAnonyme ? "Oui" : "Non" }}
            </label>
          </nz-form-control>
        </nz-form-item>
      </nz-col>
      <nz-col nzSpan="8"></nz-col>
    </nz-row>

    <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
    <nz-row nzGutter="8" nzJustify="end">
      <nz-col nzSpan="16"></nz-col>
      <nz-col nzSpan="4">
        <button
          class="btn btn-sm btn-ghost"
          (click)="$event.stopPropagation(); onCancel()"
        >
          Annuler
        </button>
      </nz-col>
      <nz-col nzSpan="4">
        <button
          class="btn btn-sm btn-primary"
          (click)="
            $event.stopPropagation(); onValidation(candidats, fiche, user)
          "
        >
          Exporter
        </button>
      </nz-col>
    </nz-row>
  </nz-card>
</form>
