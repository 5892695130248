import { DiffusionModule } from '../diffusion/diffusion.module';
import { PrescripteurModule } from '../../components/prescripteur/prescripteur.module';
import { GeoTerritoireModule } from '../../contents/components/geo-territoire/geo-territoire.module';
import { EntiteSearchModule } from '../../contents/components/entite-search/entite-search.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FichesDePosteListComponent } from './components/fiches-de-poste-list/fiches-de-poste-list.component';
import { FichesDePosteItemComponent } from './components/fiches-de-poste-item/fiches-de-poste-item.component';
import { FichesDePosteFormComponent } from './components/fiches-de-poste-form/fiches-de-poste-form.component';
import { FichesDePosteService } from './service/fiches-de-poste.service';
import { ContentsModule } from 'src/app/contents/contents.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { FichesDePosteFiltersComponent } from './components/fiches-de-poste-filters/fiches-de-poste-filters.component';
import { StoreModule } from '@ngrx/store';
import * as fromFicheDePoste from './store/fiche-de-poste.reducer';
import { EffectsModule } from '@ngrx/effects';
import { FicheDePosteEffects } from './store/fiche-de-poste.effects';
import { MissionsModule } from '../../components/missions/missions.module';
import { FichesDePosteFormValidationComponent } from './components/fiches-de-poste-form-validation/fiches-de-poste-form-validation.component';
import { FichesDePosteFormContactComponent } from './components/fiches-de-poste-form-contact/fiches-de-poste-form-contact.component';
import { PipeModule } from 'src/app/pipe/pipe.module';
import { QueryRisqueProfessionnelDangereuxComponent } from './graphql/queries/query-risque-professionnel-dangereux/query-risque-professionnel-dangereux.component';
import { QueryRisqueProfessionnelSanteComponent } from './graphql/queries/query-risque-professionnel-sante/query-risque-professionnel-sante.component';
import { FichesDePosteExportPDFService } from './service/fiches-de-poste-export-pdf.service';
import { FichesDePosteItemHeaderComponent } from './components/fiches-de-poste-item-header/fiches-de-poste-item-header.component';
import { FichesDePosteFormExportComponent } from './components/fiches-de-poste-form-export/fiches-de-poste-form-export.component';
import { FichesDePosteItemDetailComponent } from './components/fiches-de-poste-item-detail/fiches-de-poste-item-detail.component';
import { NoResultModule } from 'src/app/contents/components/no-result/no-result.module';
import { CommunesFranceModule } from 'src/app/contents/components/communes-france/communes-france.module';
import { CoordonneesModule } from 'src/app/contents/components/coordonnees/coordonnees.module';
import { FiltersModule } from 'src/app/contents/components/filters/filters.module';
import { ButtonsModule } from 'src/app/contents/components/buttons/buttons.module';
import { LayoutModule } from 'src/app/contents/components/layout/layout.module';
import { HeaderModule } from 'src/app/contents/components/header-title/header.module';
import { NoteModule } from 'src/app/features/note/note.module';
import { TaskModule } from 'src/app/features/task/task.module';
import { ContratModule } from 'src/app/contents/components/contrat/contrat.module';
import { UiModule } from 'src/app/ui/ui/ui.module';
import { FichesDePosteItemContratComponent } from './components/fiches-de-poste-item-contrat/fiches-de-poste-item-contrat.component';
import { FichesDePosteItemEntrepriseComponent } from './components/fiches-de-poste-item-entreprise/fiches-de-poste-item-entreprise.component';
import { FichesDePosteItemDashboardComponent } from './components/fiches-de-poste-item-dashboard/fiches-de-poste-item-dashboard.component';
import { PersonModule } from 'src/app/features/person/person.module';
import { CandidatureModule } from 'src/app/features/candidature/candidature.module';
import { AvatarModule } from 'src/app/features/avatar/avatar.module';
import { SuiviModule } from 'src/app/features/suivi/suivi.module';

@NgModule({
  declarations: [
    FichesDePosteListComponent,
    FichesDePosteItemComponent,
    FichesDePosteFormComponent,
    FichesDePosteFiltersComponent,
    FichesDePosteFormValidationComponent,
    FichesDePosteFormContactComponent,
    QueryRisqueProfessionnelDangereuxComponent,
    QueryRisqueProfessionnelSanteComponent,
    FichesDePosteItemHeaderComponent,
    FichesDePosteFormExportComponent,
    FichesDePosteItemDetailComponent,
    FichesDePosteItemContratComponent,
    FichesDePosteItemEntrepriseComponent,
    FichesDePosteItemDashboardComponent,
  ],
  imports: [
    CommonModule,
    ContentsModule,
    SharedModule,
    EntiteSearchModule,
    MissionsModule,
    PipeModule,
    GeoTerritoireModule,
    PrescripteurModule,
    CandidatureModule,
    DiffusionModule,
    NoResultModule,
    HeaderModule,
    CommunesFranceModule,
    PersonModule,
    CoordonneesModule,
    FiltersModule,
    ButtonsModule,
    LayoutModule,
    NoteModule,
    TaskModule,
    ContratModule,
    UiModule,
    NoteModule,
    TaskModule,
    AvatarModule,
    SuiviModule,

    StoreModule.forFeature(
      fromFicheDePoste.ficheDePostesFeatureKey,
      fromFicheDePoste.reducer
    ),
    EffectsModule.forFeature([FicheDePosteEffects]),
  ],
  exports: [FichesDePosteListComponent, FichesDePosteItemComponent],
  providers: [FichesDePosteService, FichesDePosteExportPDFService],
})
export class FichesDePosteModule {}
