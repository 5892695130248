<nz-row nzAlign="middle" nzGutter="8" nzJustify="start" *ngIf="user">
  <nz-col nzSpan="4">
    <nz-avatar
      [nzSize]="40"
      [nzSrc]="user?.photo ? user.photo : ''"
      [nzText]="!user?.photo ? user.avatar : ''"
      [ngStyle]="{
        'background-color': user?.setting?.color
          ? user?.setting?.color
          : default,
        color: avatarColor
      }"
    ></nz-avatar>
  </nz-col>
  <nz-col nzSpan="18">
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="24"
        ><strong>{{ user?.displayName }}</strong></nz-col
      >
    </nz-row>
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="24"
        ><small>{{ user?.fonction }}</small></nz-col
      >
    </nz-row>
  </nz-col>
  <nz-col nzSpan="2">
    <button
      nz-button
      nzType="text"
      nzShape="circle"
      nz-popconfirm
      (nzOnConfirm)="onDelete(user)"
      (nzOnCancel)="onCancel()"
      [nzPopconfirmTitle]="alertMessage"
      nzPopconfirmPlacement="bottomRight"
      *ngIf="(currentUser | isAdmin) || (currentUser | isDev)"
    >
      <mat-icon>delete</mat-icon>
    </button>
  </nz-col>
</nz-row>
