import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayContain',
})
export class ArrayContainPipe implements PipeTransform {
  transform(values: any[], arg: any): boolean {
    if (!values?.length) {
      return false;
    }

    if (!values.find((item) => item === arg)) {
      return false;
    }

    if (values.find((item) => item === arg)) {
      return true;
    }

    return false;
  }
}
