import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';
import { OperationId } from 'src/app/components/operation/store/operation.model';
import { OperationState } from 'src/app/components/operation/store/operation.reducer';
import * as fromOperationSelector from 'src/app/components/operation/store/operation.selectors';

import { ActionSmallId } from '../../store/action.model';
import { ActionState } from '../../store/action.reducer';
import * as fromActionAction from '../../store/action.actions';
import * as fromActionSelector from '../../store/action.selectors';

@Component({
  selector: 'app-action-module-select',
  templateUrl: './action-module-select.component.html',
  styleUrls: ['./action-module-select.component.scss'],
})
export class ActionModuleSelectComponent implements OnInit, OnChanges {
  subscribe = new Subject();
  actions$: Observable<ActionSmallId[] | any> = of(null);
  operation$: Observable<OperationId | any> = of(null);

  inputSearch$ = new BehaviorSubject<string>('');
  inputModel: string = '';

  @Input() placeHolder: string = 'Ajouter une action';
  @Input() disabled: boolean = true;

  @Input() filter$ = new BehaviorSubject<any>(null);
  @Output() select = new EventEmitter<any>(false);

  constructor(
    private actionStore: Store<ActionState>,
    private operationStore: Store<OperationState>
  ) {}

  ngOnInit(): void {
    this.getOperation();
    this.getActions();
    this.loadActions();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getOperation(): void {
    this.operation$ = this.operationStore.select(
      fromOperationSelector.operation
    );
  }
  getActions(): void {
    this.actions$ = this.actionStore.select(fromActionSelector.actionsModule);
  }

  loadActions(): void {
    this.filter$.pipe(takeUntil(this.subscribe)).subscribe((filter: any) => {
      if (!filter) return;
      const { operationId, module } = filter;

      this.actionStore.dispatch(
        fromActionAction.queryActionsByModule({ operationId, module })
      );
    });
  }

  onSeach(event: any, filter: any): void {}

  onSelect(action: ActionSmallId): void {
    if (!action) return;
    this.select.emit(action);
  }
}
