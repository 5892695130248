import { MetiersSuggestion } from './metiers-suggestion.model';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EntiteMetiersSuggestionsService } from 'src/app/service/entite-metiers-suggestions.service';
import { catchError, map, mergeMap, of } from 'rxjs';
import * as fromMetiersSuggestionsAction from './metiers-suggestion.actions';

@Injectable()
export class MetiersSuggestionEffects {
  loadSuggestions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMetiersSuggestionsAction.loadMetiersSuggestions),
      mergeMap((action) =>
        this.metierNafSuggestion.getNafMetiersSuggestions(action.naf).pipe(
          map((response: any) => {
            const { data } = response;
            const { entrepriseMetiersSuggestions } = data;
            const { correspondances, total } = entrepriseMetiersSuggestions;

            return fromMetiersSuggestionsAction.loadMetiersSuggestionsSuccess({
              suggestions: correspondances,
              total: total,
            });
          }),
          catchError((error) =>
            of(
              fromMetiersSuggestionsAction.loadMetiersSuggestionsFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  loadByDomaineSuggestions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMetiersSuggestionsAction.loadDomaineMetiersSuggestions),
      mergeMap((action) =>
        this.metierNafSuggestion.getDomaineMetiersSuggestions(action.code).pipe(
          map((response: any) => {
            const { data } = response;
            const { metiersByDomaine } = data;

            const total: number = metiersByDomaine?.length
              ? metiersByDomaine.length
              : 0;

            return fromMetiersSuggestionsAction.loadDomaineMetiersSuggestionsSuccess(
              {
                suggestions: metiersByDomaine,
                total: total,
              }
            );
          }),
          catchError((error) =>
            of(
              fromMetiersSuggestionsAction.loadDomaineMetiersSuggestionsFailure(
                {
                  error,
                }
              )
            )
          )
        )
      )
    )
  );

  loadMetierAppelations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMetiersSuggestionsAction.loadMetierAppelations),
      mergeMap((action) =>
        this.metierNafSuggestion.getMetierAppelations(action.code).pipe(
          map((response: any) => {
            const { data } = response;
            const { appelations } = data;

            const total: number = appelations?.length ? appelations.length : 0;

            return fromMetiersSuggestionsAction.loadMetierAppelationsSuccess({
              appelations,
              total,
            });
          }),
          catchError((error) =>
            of(
              fromMetiersSuggestionsAction.loadMetierAppelationsFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  loadMetierDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMetiersSuggestionsAction.loadMetierDetails),
      mergeMap((action) =>
        this.metierNafSuggestion.getMetierDetails(action.rom).pipe(
          map((metier: any) => {
            return fromMetiersSuggestionsAction.loadMetierDetailsSuccess({
              metier,
            });
          }),
          catchError((error) =>
            of(fromMetiersSuggestionsAction.loadMetierDetailsFailure({ error }))
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private message: NzMessageService,
    private metierNafSuggestion: EntiteMetiersSuggestionsService
  ) {}
}
