import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { ProjetPrpfessionnelState } from '../../store/projet-professionnel.reducer';
import * as fromProjetProAction from '../../store/projet-professionnel.actions';
import * as fromProjetProSelector from '../../store/projet-professionnel.selectors';
import { of, Observable, takeUntil, Subject, BehaviorSubject } from 'rxjs';
import { UtilitiesState } from 'src/app/contents/components/utilities/store/utilities.reducer';
import * as fromUtilityAction from 'src/app/contents/components/utilities/store/utilities.actions';
import * as fromUtilitySelector from 'src/app/contents/components/utilities/store/utilities.selectors';

@Component({
  selector: 'app-projet-professionnel-formations-form',
  templateUrl: './projet-professionnel-formations-form.component.html',
  styleUrls: ['./projet-professionnel-formations-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjetProfessionnelFormationsFormComponent
  implements OnInit, OnChanges, OnDestroy
{
  loading$: Observable<boolean> = of(false);
  formations$: Observable<any> = of(null);
  utilityIdentity$: Observable<any> = of(null);
  loadingUtilities$: Observable<any> = of(false);
  currentFormationDetails$: Observable<any> = of(null);
  subscribe = new Subject();
  currentFormation = new BehaviorSubject<any>(null);
  distanceSelectors: string[] = ['10', '30', '50', '100', '200', '500'];
  view$ = new BehaviorSubject<string>('list');
  currentDetails = new BehaviorSubject<string>('Accès');

  projetproFormationForm: UntypedFormGroup = this.fb.group({});
  diploma: UntypedFormControl = new UntypedFormControl('');
  rome: UntypedFormControl = new UntypedFormControl('');
  latitude: UntypedFormControl = new UntypedFormControl('');
  longitude: UntypedFormControl = new UntypedFormControl('');
  radius: UntypedFormControl = new UntypedFormControl('');
  insee: UntypedFormControl = new UntypedFormControl('');

  @Output() cancel = new EventEmitter<any>(false);
  @Output() add = new EventEmitter<any>(false);
  @Input() item = new BehaviorSubject<any>(null);
  @Input() participant = new BehaviorSubject<any>(null);

  constructor(
    private projetProfessionnelStore: Store<ProjetPrpfessionnelState>,
    private fb: UntypedFormBuilder,
    private utilityStore: Store<UtilitiesState>
  ) {}

  ngOnInit(): void {
    this.getLoading();
    this.getFormationsSuggestions();
    this.loadFormationsSuggestions();
    this.clearFormationsSuggestions();
    this.getCurrentFormationDetails();
    this.form();
    this.utilities();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  getLoading(): void {
    this.loading$ = this.projetProfessionnelStore.select(
      fromProjetProSelector.loading
    );
  }

  form(): void {
    this.formInit();
    this.formCreate();
    this.setProjetVariables();
  }

  formInit(): void {
    this.diploma = new UntypedFormControl('');
    this.rome = new UntypedFormControl('', Validators.required);
    this.latitude = new UntypedFormControl('', Validators.required);
    this.longitude = new UntypedFormControl('', Validators.required);
    this.radius = new UntypedFormControl('10', Validators.required);
    this.insee = new UntypedFormControl('', Validators.required);
  }

  formCreate(): void {
    this.projetproFormationForm = this.fb.group({
      diploma: this.diploma,
      rome: this.rome,
      latitude: this.latitude,
      longitude: this.longitude,
      radius: this.radius,
      insee: this.insee,
    });
  }

  setProjetVariables(): void {
    this.item.pipe(takeUntil(this.subscribe)).subscribe((projetPro: any) => {
      if (!projetPro) return;
      const { adresse, code } = projetPro;
      const { geometry, properties } = adresse;
      const { citycode } = properties;
      let latitude = geometry.coordinates[1].toString();
      let longitude = geometry.coordinates[0].toString();

      this.projetproFormationForm.patchValue({
        rome: code,
        latitude: latitude,
        longitude: longitude,
        radius: '10',
        insee: citycode,
      });
    });
  }

  utilities(): void {
    this.getUtilitiesIdentity();
    this.loadUtilityIdentity();
    this.getUtilitiesLoading();
  }

  getUtilitiesIdentity(): void {
    this.utilityIdentity$ = this.utilityStore.select(
      fromUtilitySelector.participantIdentity
    );
  }

  loadUtilityIdentity(): void {
    this.utilityStore.dispatch(
      fromUtilityAction.loadParticipantUtilitiesIdentity()
    );
  }

  getUtilitiesLoading(): void {
    this.loadingUtilities$ = this.utilityStore.select(
      fromUtilitySelector.loading
    );
  }

  validedSearch(): void {
    if (!this.projetproFormationForm.valid) return;
    const values = this.projetproFormationForm.value;
    const { diploma, rome, latitude, longitude, radius, insee } = values;
    const diploma_level_job =
      diploma.level === 'level_1'
        ? '7'
        : diploma.value === 'level_2'
        ? '6'
        : diploma.value === 'level_3'
        ? '5'
        : diploma.value === 'level_4'
        ? '4'
        : diploma.value === 'level_5'
        ? '3'
        : '';

    const diploma_level_formation =
      diploma.level === 'level_1'
        ? '7 (Master, titre ingénieur...)'
        : diploma.value === 'level_2'
        ? '6 (Licence...)'
        : diploma.value === 'level_3'
        ? '5 (BTS, DEUST...)'
        : diploma.value === 'level_4'
        ? '4 (BAC...)'
        : diploma.value === 'level_5'
        ? '3 (CAP...)'
        : '';
    const source: string = '';
    this.projetProfessionnelStore.dispatch(
      fromProjetProAction.loadFormationsSuggestions({
        rome,
        latitude,
        longitude,
        radius,
        diploma: diploma_level_formation,
      })
    );
  }

  getFormationsSuggestions(): void {
    this.formations$ = this.projetProfessionnelStore.select(
      fromProjetProSelector.formationsSuggestions
    );
  }

  getCurrentFormationDetails(): void {
    this.currentFormationDetails$ = this.projetProfessionnelStore.select(
      fromProjetProSelector.formationSuggestionDetail
    );
  }

  clearFormationsSuggestions(): void {
    this.projetProfessionnelStore.dispatch(
      fromProjetProAction.loadFormationsSuggestionsClear()
    );
  }
  loadFormationsSuggestions(): void {
    this.item.pipe(takeUntil(this.subscribe)).subscribe((projetPro: any) => {
      if (!projetPro) return;
      const { appellation, code, adresse } = projetPro;
      const { geometry, properties } = adresse;
      const { coordinates } = geometry;
      const { citycode } = properties;

      const longitude: string = coordinates[0];
      const latitude: string = coordinates[1];

      // this.projetProfessionnelStore.dispatch(
      //   fromProjetProAction.loadFormationsSuggestions({ libelle })
      // );
    });
  }

  onSelect(formation: any, projetPro: any): void {
    if (!formation || !projetPro) return;
    this.view$.next('details');
    this.currentFormation.next(formation);
  }

  onSelectDetailElement(event: any): void {}

  onBack(): void {
    this.view$.next('list');
    this.currentFormation.next(null);
  }

  onAdd(formation: any, detail: any): void {
    if (!formation || !detail) return;
    const mergeFormation = {
      ...formation,
      acces: detail[0].acces,
      blocsCompetences: detail[0].blocsCompetences,
      ccn: detail[0].ccn,
      nsf: detail[0].nsf,
      partenaires: detail[0].partenaires,
      inscription: {
        status: false,
        dateStart: null,
        dateEnd: null,
      },
      validation: {
        status: false,
        date: null,
      },
    };

    this.add.emit(mergeFormation);
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
    this.formations$ = of(null);
  }
}
