import { Pipe, PipeTransform } from '@angular/core';
import {
  fromUnixTime,
  differenceInHours,
  differenceInBusinessDays,
  differenceInCalendarWeeks,
  differenceInWeeks,
  differenceInMonths,
  differenceInYears,
  isBefore,
  isAfter,
} from 'date-fns';
@Pipe({
  name: 'distancePercent',
})
export class DistancePercentPipe implements PipeTransform {
  transform(values: any[], arg: string): any {
    if (!values || !arg) {
      return 0;
    }

    const dateStart = values[0] ? values[0] : null;
    const dateEnd = values[1] ? values[1] : null;

    if (!dateStart || !dateEnd) {
      return 0;
    }
    const today: Date = new Date(Date.now());
    const start: Date = fromUnixTime(dateStart['seconds']);
    const end: Date = fromUnixTime(dateEnd['seconds']);

    switch (arg) {
      case 'hours':
        var toEnd: number = isAfter(end, start)
          ? differenceInHours(start, end)
          : 0;
        var toToday: number = isAfter(today, start)
          ? differenceInHours(start, today)
          : 0;
        var isPass: number = isAfter(today, end) ? 100 : 0;

        var percent: number =
          isPass === 100 ? 100 : Math.floor((toToday / toEnd) * 100);
        var increase: number =
          toEnd >= 0 ? Math.floor(((toEnd - toToday) / toToday) * 100) : 0;
        var decrease: number =
          toEnd >= 0 ? Math.floor(((toEnd - toToday) / toEnd) * 100) : 0;

        return { percent, increase, decrease };

        break;
      case 'day':
        var toEnd: number = isAfter(end, start)
          ? differenceInBusinessDays(start, end)
          : 0;
        var toToday: number = isAfter(today, start)
          ? differenceInBusinessDays(start, today)
          : 0;
        var isPass: number = isAfter(today, end) ? 100 : 0;

        var percent: number =
          isPass === 100 ? 100 : Math.floor((toToday / toEnd) * 100);
        var increase: number =
          toEnd >= 0 ? Math.floor(((toEnd - toToday) / toToday) * 100) : 0;
        var decrease: number =
          toEnd >= 0 ? Math.floor(((toEnd - toToday) / toEnd) * 100) : 0;

        return { percent, increase, decrease };

        break;
      case 'week':
        var toEnd: number = isAfter(end, start)
          ? differenceInWeeks(start, end)
          : 0;
        var toToday: number = isAfter(today, start)
          ? differenceInWeeks(start, today)
          : 0;
        var isPass: number = isAfter(today, end) ? 100 : 0;

        var percent: number =
          isPass === 100 ? 100 : Math.floor((toToday / toEnd) * 100);
        var increase: number =
          toEnd >= 0 ? Math.floor(((toEnd - toToday) / toToday) * 100) : 0;
        var decrease: number =
          toEnd >= 0 ? Math.floor(((toEnd - toToday) / toEnd) * 100) : 0;

        return { percent, increase, decrease };

        break;
      case 'month':
        var toEnd: number = isAfter(end, start)
          ? differenceInMonths(start, end)
          : 0;
        var toToday: number = isAfter(today, start)
          ? differenceInMonths(start, today)
          : 0;
        var isPass: number = isAfter(today, end) ? 100 : 0;

        var percent: number =
          isPass === 100 ? 100 : Math.floor((toToday / toEnd) * 100);
        var increase: number =
          toEnd >= 0 ? Math.floor(((toEnd - toToday) / toToday) * 100) : 0;
        var decrease: number =
          toEnd >= 0 ? Math.floor(((toEnd - toToday) / toEnd) * 100) : 0;

        return { percent, increase, decrease };

        break;
      case 'year':
        var toEnd: number = isAfter(end, start)
          ? differenceInYears(start, end)
          : 0;
        var toToday: number = isAfter(today, start)
          ? differenceInYears(start, today)
          : 0;
        var isPass: number = isAfter(today, end) ? 100 : 0;

        var percent: number =
          isPass === 100 ? 100 : Math.floor((toToday / toEnd) * 100);
        var increase: number =
          toEnd >= 0 ? Math.floor(((toEnd - toToday) / toToday) * 100) : 0;
        var decrease: number =
          toEnd >= 0 ? Math.floor(((toEnd - toToday) / toEnd) * 100) : 0;

        return { percent, increase, decrease };

        break;

      default:
        break;
    }
  }
}
