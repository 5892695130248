import { Component, OnInit } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-page-brand',
  templateUrl: './page-brand.component.html',
  styleUrls: ['./page-brand.component.scss'],
})
export class PageBrandComponent implements OnInit {
  title: string =
    'Pilotez vos dispositifs et accompagnez vos bénéficiaires sur Protein';
  definition: string =
    "Professionnelle Technologie pour l'Emploi et l'inclusion";

  options: AnimationOptions = {
    path: '../assets/animations/login_page_animation.json',
    loop: true,
    autoplay: true,
  };

  lottie: Partial<CSSStyleDeclaration> = {
    marginBottom: '-50rem !important',
  };

  animationCreated(animationItem: AnimationItem): void {}

  constructor() {}

  ngOnInit(): void {}
}
