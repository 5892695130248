import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Observable } from 'rxjs';
import { SelectType } from 'src/app/models/select.display.model';
import { Store } from '@ngrx/store';
import { OperationFicheCandidatNoteState } from '../../store/operation-fiche-candidat-note.reducer';
import * as fromOperationFicheCandidatNoteAction from '../../store/operation-fiche-candidat-note.actions';
import * as fromOperationFicheCandidatNoteSelector from '../../store/operation-fiche-candidat-note.selectors';
import { OperationCandidatureState } from 'src/app/components/operation-fiche-de-poste-candidature/store/operation-fiche-de-poste-candidature.reducer';
import * as fromOperationFicheCandidatSelector from 'src/app/components/operation-fiche-de-poste-candidature/store/operation-fiche-de-poste-candidature.selectors';
import * as fromOperationFicheCandidatAction from 'src/app/components/operation-fiche-de-poste-candidature/store/operation-fiche-de-poste-candidature.actions';
import { Update } from '@ngrx/entity';

@Component({
  selector: 'app-operation-fiche-candidat-notes',
  templateUrl: './operation-fiche-candidat-notes.component.html',
  styleUrls: ['./operation-fiche-candidat-notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationFicheCandidatNotesComponent implements OnInit, OnChanges {
  loading$: Observable<any> = this.operationFicheCandidatNoteStore.select(
    fromOperationFicheCandidatSelector.loading
  );
  notes$: Observable<any> = this.operationFicheCandidatNoteStore.select(
    fromOperationFicheCandidatNoteSelector.notes
  );

  selectType: SelectType = SelectType.OVERLAY;
  @Input() fiche: any;
  @Input() candidat: any;
  @Input() view$: Observable<string> =
    this.operationFicheCandidatNoteStore.select(
      fromOperationFicheCandidatNoteSelector.view
    );
  @Output() viewChanges = new EventEmitter();

  constructor(
    private operationFicheCandidatNoteStore: Store<OperationFicheCandidatNoteState>,
    private operationFicheCandidatStore: Store<OperationCandidatureState>
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.loadCandidatNotes(
      changes?.fiche?.currentValue,
      changes?.candidat?.currentValue
    );
  }

  /**
   * Changement d'affichage
   * @param {string} view
   */
  onViewChanges(view: string): void {
    this.viewChanges.emit(view);
    this.operationFicheCandidatNoteStore.dispatch(
      fromOperationFicheCandidatNoteAction.setViewOperationFicheCandidatNotes({
        view,
      })
    );
  }

  /**
   * Chargement des notes du candidat
   * @param {any} fiche
   * @param {any} candidat
   * @returns
   */
  loadCandidatNotes(fiche: any, candidat: any): void {
    if (!fiche || !candidat) return;
    const operationId: string = fiche?.mission?.operation.id;
    const ficheId: string = fiche.id;
    const candidatId: string = candidat.id;
    this.operationFicheCandidatNoteStore.dispatch(
      fromOperationFicheCandidatNoteAction.loadOperationFicheCandidatNotes({
        operationId,
        ficheId,
        candidatId,
      })
    );
  }

  /**
   * Création d'une note
   * @param {any} note
   * @param {any} fiche
   * @param {any} candidat
   * @returns
   */
  onAdd(note: any, fiche: any, candidat: any): void {
    this.onViewChanges('display');
    const operationId: string = fiche?.mission?.operation.id;
    const ficheId: string = fiche.id;
    const candidatId: string = candidat.id;

    if (!ficheId || !operationId || !candidatId || !note) return;

    this.operationFicheCandidatNoteStore.dispatch(
      fromOperationFicheCandidatNoteAction.addOperationFicheCandidatNote({
        operationId,
        ficheId,
        candidatId,
        note,
      })
    );
  }

  /**
   * Modification d'un note
   * @param {any} note
   * @param {any} fiche
   * @param {any} candidat
   * @returns
   */
  onUpdate(note: any, fiche: any, candidat: any): void {
    if (!fiche || !candidat || !note) return;

    const operationId: string = fiche?.mission?.operation.id;
    const ficheId: string = fiche.id;
    const candidatId: string = candidat.id;
    const updatedNote: Update<any> = {
      id: note.id,
      changes: { ...note },
    };

    this.operationFicheCandidatNoteStore.dispatch(
      fromOperationFicheCandidatNoteAction.updateOperationFicheCandidatNote({
        operationId,
        ficheId,
        candidatId,
        note: updatedNote,
      })
    );
  }

  /**
   * Suppresion d'une note
   * @param {any} note
   * @param {any} fiche
   * @param {any} candidat
   * @returns
   */
  onDelete(note: any, fiche: any, candidat: any): void {
    if (!fiche || !candidat || !note) return;
    const operationId: string = fiche?.mission?.operation.id;
    const ficheId: string = fiche.id;
    const candidatId: string = candidat.id;
    const noteId: string = note.id;

    this.operationFicheCandidatNoteStore.dispatch(
      fromOperationFicheCandidatNoteAction.deleteOperationFicheCandidatNote({
        operationId,
        ficheId,
        candidatId,
        noteId,
      })
    );
  }

  /**
   * Suppresion de toutes les notes du candidat
   * @param  {any} fiche
   * @param {any} candidat
   * @returns
   */
  onDeleteAll(fiche: any, candidat: any): void {
    if (!fiche || !candidat) return;
    const operationId: string = fiche?.mission?.operation.id;
    const ficheId: string = fiche.id;
    const candidatId: string = candidat.id;

    this.operationFicheCandidatNoteStore.dispatch(
      fromOperationFicheCandidatNoteAction.deleteOperationFicheCandidatNotes({
        operationId,
        ficheId,
        candidatId,
        ids: [],
      })
    );
  }

  /**
   * Annulation des changement en cours
   */
  onCancel(): void {
    this.onViewChanges('display');
  }
}
