import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextEdithingComponent } from './components/text-edithing/text-edithing.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { QuillModule } from 'ngx-quill';

@NgModule({
  declarations: [TextEdithingComponent],
  exports: [TextEdithingComponent],
  imports: [CommonModule, SharedModule, QuillModule.forRoot()],
})
export class TextEdithingModule {}
