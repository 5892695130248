import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';
import { UtilitiesState } from 'src/app/contents/components/utilities/store/utilities.reducer';
import * as fromUtilitiesSelector from 'src/app/contents/components/utilities/store/utilities.selectors';
import * as fromUtilitiesAction from 'src/app/contents/components/utilities/store/utilities.actions';
import { ContratState } from 'src/app/contents/components/contrat/store/contrat.reducer';
import * as fromContratSelector from 'src/app/contents/components/contrat/store/contrat.selectors';
import * as fromContratAction from 'src/app/contents/components/contrat/store/contrat.actions';

@Component({
  selector: 'app-participant-diagnostic-form-mobilite',
  templateUrl: './participant-diagnostic-form-mobilite.component.html',
  styleUrls: ['./participant-diagnostic-form-mobilite.component.scss'],
})
export class ParticipantDiagnosticFormMobiliteComponent
  implements OnInit, OnChanges, OnDestroy
{
  subscribe = new Subject();
  permis$: Observable<any> = of([]);
  geo_mobilite$: Observable<any> = of(null);
  transport_mode$: Observable<any> = of(null);

  //VARIABLES
  isFreinTitle: string = 'La mobilité est-elle un frein identifié ?';

  //FORM FIELD
  mobiliteForm: FormGroup = this.fb.group({});
  permis: FormControl<any> = new FormControl([]);
  transport_mode: FormControl<any> = new FormControl([]);
  geo_mobility: FormControl<any> = new FormControl([]);
  isFrein: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );

  //BIND
  @Input() mobilite$ = new BehaviorSubject<any>(null);
  @Output() add = new EventEmitter();
  constructor(
    private fb: FormBuilder,
    private utilitiesStore: Store<UtilitiesState>,
    private contratStore: Store<ContratState>
  ) {}

  ngOnInit(): void {
    this.form();
    this.onFormChanges();
    this.getModeTransportReferentiel();
    this.loadModeTransportReferentiel();
    this.getGeoMobiliteReferentiel();
    this.loadGeoMobiliteReferentiel();
    this.getPermisReferentiel();
    this.loadPermisReferentiel();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getPermisReferentiel(): any {
    this.permis$ = this.contratStore.select(fromContratSelector.permis);
  }

  loadPermisReferentiel(): any {
    this.contratStore.dispatch(fromContratAction.loadPermis());
  }

  getModeTransportReferentiel(): any {
    this.transport_mode$ = this.utilitiesStore.select(
      fromUtilitiesSelector.transport
    );
  }

  loadModeTransportReferentiel(): any {
    this.utilitiesStore.dispatch(
      fromUtilitiesAction.loadTransportReferentiel()
    );
  }

  getGeoMobiliteReferentiel(): any {
    this.geo_mobilite$ = this.utilitiesStore.select(
      fromUtilitiesSelector.geoMobilite
    );
  }

  loadGeoMobiliteReferentiel(): any {
    this.utilitiesStore.dispatch(
      fromUtilitiesAction.loadGeoMobiliteReferentiel()
    );
  }

  form(): void {
    this.formInit();
    this.formCreate();
    this.formSet();
  }

  formInit(): void {
    this.permis = new FormControl<any>([]);
    this.transport_mode = new FormControl<any>([]);
    this.geo_mobility = new FormControl<any>([]);
    this.isFrein = new FormControl<boolean>(false, Validators.required);
  }

  formCreate(): void {
    this.mobiliteForm = this.fb.group({
      permis: this.permis,
      transport_mode: this.transport_mode,
      geo_mobility: this.geo_mobility,
      isFrein: this.isFrein,
    });
  }

  formSet(): void {
    this.mobilite$.pipe(takeUntil(this.subscribe)).subscribe((mobilite) => {
      if (!mobilite) return;
      this.mobiliteForm.patchValue({ ...mobilite });
    });
  }

  //COMPARE SELECT LIST UPDATE
  compareMobiliteGeographique(obj1: any, obj2: any) {
    return (
      obj1 && obj2 && obj1.libelleTypeTerritoire === obj2.libelleTypeTerritoire
    );
  }

  compareMobilitePermis(obj1: any, obj2: any) {
    return obj1 && obj2 && obj1.libelle === obj2.libelle;
  }

  onFormChanges(): void {
    this.mobiliteForm.valueChanges
      .pipe(takeUntil(this.subscribe))
      .subscribe((values) => this.add.emit(values));
  }
}
