import { filter, takeUntil } from 'rxjs/operators';
import { Observable, of, Subject, BehaviorSubject } from 'rxjs';
import {
  Component,
  OnDestroy,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';
import * as fromUserAction from 'src/app/components/user/store/user.actions';

import { Store, select } from '@ngrx/store';
import { uniq } from 'lodash';

@Component({
  selector: 'app-users-select',
  template: `
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="24">
        <nz-select
          [nzMode]="multi ? 'tags' : 'default'"
          [nzMaxMultipleCount]="2"
          [nzSize]="users$ | async"
          [nzPlaceHolder]="placeholder"
          nzAllowClear
          [nzBorderless]="borderless"
          [ngClass]="{ borderless: borderless }"
          nzShowSearch
          nzServerSearch
          [(ngModel)]="selectedUser"
          (nzOnSearch)="onSearch($event)"
          (ngModelChange)="onSelect($event)"
        >
          <ng-container
            *ngFor="let item of [users$ | async, source] | differenceById"
          >
            <nz-option
              nzCustomContent
              [nzValue]="item"
              [nzLabel]="item.displayName | titlecase | carLimitation: 20"
            >
              <span>{{
                item.displayName | titlecase | carLimitation: 20
              }}</span>
            </nz-option>
          </ng-container>
        </nz-select>
      </nz-col>
    </nz-row>
    <nz-space
      [nzSize]="50"
      nzDirection="horizontal"
      nzAlign="center"
    ></nz-space>
  `,
  styles: [
    `
      :host {
        width: 100%;
        height: 150px;
      }
      nz-select {
        width: 100%;
        border-radius: 12px;
        background-color: #f5f5f5;
      }

      nz-avatar {
        padding: 20px;
      }
      .borderless {
        border-radius: 12px;
        background-color: #f5f5f5;
      }
      .btnPrimaty {
        background-color: #f5f5f5;
        h5 {
          color: #1890ff !important;
        }
      }
    `,
  ],
})
export class UsersSelectComponent implements OnInit, OnChanges, OnDestroy {
  users$: Observable<any> = of([]);
  subscribe = new Subject();
  selectedValue: string = '';
  usersSearch$: Observable<any[]> = of([]);
  selectedUser: any = [];
  nextSource = new BehaviorSubject<any[]>([]);

  nzFilterOption = () => true;

  @Input() borderless: boolean = true;
  @Input() multi: boolean = false;

  @Input() size: string = 'default';
  @Input() placeholder: string = 'Sélectionner un collaborateur...';
  @Input() source: any[] = [];
  @Output() select = new EventEmitter<any>(false);
  @Output() cancel = new EventEmitter<any>(false);

  constructor(private userStore: Store<UserState>) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): any {
    if (!changes?.source) {
      return null;
    } else {
      this.getExist__USERS(changes.source.currentValue);
    }
  }

  getUsers(): void {
    this.users$ = this.userStore.select(fromUserSelector.team);
    this.selectedValue = '';
    this.usersSearch$ = of([]);
    this.selectedUser = [];
  }

  loadUsers(): void {
    this.userStore.dispatch(fromUserAction.loadTeam());
  }

  onSelect(user: any): any {
    if (!user) return null;
    this.select.emit(user);
  }

  onUsersFilter(user: any): any {
    if (!this.users$ || !user) {
      return null;
    } else {
      this.users$ = this.users$.pipe(filter((item) => item.id !== user.id));
      // this.users$.pipe(takeUntil(this.subscribe)).subscribe((users) => {
      //   const next: any[] = users.filter((item: any) => item.id !== user.id);
      //   this.selectedUser = next;

      //   this.users$ = of(this.selectedUser);
      // });
    }
  }

  onSearch(filter: string): void {}

  getExist__USERS(source: any[]): any {
    if (!source || !source?.length) {
      return null;
    } else if (!this.users$) {
      return null;
    } else {
      source.forEach((el: any) => this.onUsersFilter(el));

      return null;
    }
  }

  getFilter__USERS(): void {}

  onCancel(): void {
    this.cancel.emit(true);
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }
}
