import { Injectable } from '@angular/core';
import { Subscription, Observable, of, from, EMPTY } from 'rxjs';
import { map, tap, mergeMap, switchMap, catchError } from 'rxjs/operators';
import { Apollo, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'any',
})
export class QueryMetiersService {
  domaines: Observable<any> = of(null);

  GET_DOMAINES = gql`
    query GrandDomaine {
      grandDomaine {
        code
        libelle
      }
    }
  `;

  GET_THEMES = gql`
    query ThemeMetiers {
      themeMetiers {
        code
        libelle
      }
    }
  `;

  GET_DOMAINES_PROFESSIONNEL = gql`
    query DomainesProfessionnels {
      domainesProfessionnels {
        code
        libelle
        grandDomaine {
          code
          libelle
        }
      }
    }
  `;

  GET_DOMAINES_PROFESSIONNEL_BY_GRAND_DOMAINE = gql`
    query DomainesProfessionnelsByGrandDomaine($code: String!) {
      domainesProfessionnelsByGrandDomaine(code: $code) {
        code
        libelle
        grandDomaine {
          code
          libelle
        }
      }
    }
  `;

  GET_METIERS_BY_DOMAINE_PROFESSIONNEL = gql`
    query MetiersByDomaine($code: String!) {
      metiersByDomaine(code: $code) {
        code
        libelle
        riasecMajeur
        riasecMineur
        codeIsco
        particulier
      }
    }
  `;

  GET_APPELATIONS_BY_METIER = gql`
    query Appelations($code: String!) {
      appelations(code: $code) {
        code
        libelle
        libelleCourt
        particulier
      }
    }
  `;

  GET_APPELATION_DETAIL = gql`
    query AppelationDetail($code: String!) {
      appelationDetail(code: $code) {
        code
        libelle
        libelleCourt
        particulier
        metier {
          libelle
          particulier
          riasecMajeur
          riasecMineur
          id
          domaineProfessionnel {
            grandDomaine {
              code
              libelle
            }
            libelle
            code
          }
          codeIsco
          code
          informations {
            definition
            acces
            accesArray
            accesSize
            condition
          }
        }
        competencesDeBase {
          code
          libelle
          noeudCompetence {
            code
            libelle
            racineCompetence {
              libelle
              code
            }
          }
          competenceCle
          typeCompetence
          riasecMineur
          riasecMajeur
        }
        competencesDeBaseSize
        competencesCleDeBaseSize
        partCompetencesDeBaseSize
        competencesDeBaseType {
          SavoirFaire {
            code
            libelle
            competenceCle
            noeudCompetence {
              racineCompetence {
                libelle
                code
              }
              libelle
              code
            }
            typeCompetence
            riasecMineur
            riasecMajeur
          }
          Savoir {
            code
            libelle
            competenceCle
            noeudCompetence {
              racineCompetence {
                libelle
                code
              }
              libelle
              code
            }
            typeCompetence
            riasecMineur
            riasecMajeur
          }
        }
        groupesCompetencesSpecifiques {
          competences {
            riasecMajeur
            riasecMineur
            typeCompetence
            competenceCle
            code
            libelle
            noeudCompetence {
              libelle
              code
              racineCompetence {
                libelle
                code
              }
            }
          }
        }
        competencesSpecifiquesSize
        competencesCleSpecifiquesSize
        partCompetencesSpeficiquesSize
        environnementsTravail {
          code
          libelle
          typeEnvironnementTravail
        }
      }
    }
  `;

  GET_METIER_DETAIL = gql`
    query Metier($code: String!) {
      metier(code: $code) {
        code
        libelle
        definition
        condition
        qualification
        acces
        accesArray
        accesSize
        riasecMajeur
        riasecMineur
        codeIsco
        particulier
        domaineProfessionnel {
          code
          libelle
          grandDomaine {
            libelle
            code
          }
        }
        appellations {
          libelle
          code
          libelleCourt
          particulier
        }
        competencesDeBase {
          code
          libelle
          competenceCle
          noeudCompetence {
            racineCompetence {
              libelle
              code
            }
            libelle
            code
          }
          typeCompetence
          riasecMineur
          riasecMajeur
        }
        competencesDeBaseSize
        groupesCompetencesSpecifiques {
          competences {
            libelle
            code
            competenceCle
            noeudCompetence {
              racineCompetence {
                libelle
                code
              }
              libelle
              code
            }
            typeCompetence
            riasecMineur
            riasecMajeur
          }
        }
        groupesCompetencesSpecifiquesSize
        themes {
          code
          libelle
        }
        softskillsSize
        experiences
        softskills {
          details
          importance
          score
          summary
        }
        mobilitesEvolutionsAppellationsVersAppellations {
          appellationCible {
            metier {
              libelle
              code
            }
            libelleCourt
            libelle
            code
          }
          metierCible {
            libelle
            code
          }
          appellationOrigine {
            metier {
              libelle
              code
            }
            libelleCourt
            libelle
            code
          }
          metierOrigine {
            libelle
            code
          }
        }
        environnementsTravail {
          code
          libelle
          typeEnvironnementTravail
        }
        mobilitesProchesVersMetiers {
          metierCible {
            libelle
            code
          }
        }
        mobilitesEvolutionsVersMetiers {
          metierCible {
            code
            libelle
          }
        }
        mobilitesProchesVersAppellations {
          appellationCible {
            code
            libelle
            libelleCourt
            metier {
              libelle
              code
            }
          }
        }
        mobilitesEvolutionsVersAppellations {
          appellationCible {
            code
            libelle
            libelleCourt
            metier {
              code
              libelle
            }
          }
        }
        mobilitesProchesAppellationsVersMetiers {
          appellationOrigine {
            code
            libelle
            libelleCourt
            metier {
              libelle
              code
            }
          }
          metierCible {
            libelle
            code
          }
          metierOrigine {
            libelle
            code
          }
        }
        mobilitesEvolutionsAppellationsVersMetiers {
          appellationOrigine {
            code
            libelle
            libelleCourt
            metier {
              libelle
              code
            }
          }
          metierCible {
            libelle
            code
          }
          metierOrigine {
            libelle
            code
          }
        }
        mobilitesProchesAppellationsVersAppellations {
          appellationOrigine {
            libelle
            code
            libelleCourt
            metier {
              libelle
              code
            }
          }
          appellationCible {
            libelleCourt
            metier {
              libelle
              code
            }
            libelle
            code
          }
          metierCible {
            libelle
            code
          }
          metierOrigine {
            libelle
            code
          }
        }
      }
    }
  `;

  GET_METIER_QUALIFICATION = gql`
    query ReferentielNiveauQualification {
      referentielNiveauQualification {
        caracteristiques {
          codeCaracteristique
          libelleCaracteristique
        }
      }
    }
  `;

  GET_METIER_FAP = gql`
    query MetierFap($code: String!) {
      metierFap(code: $code) {
        fap {
          code
          domaine {
            libelle
            code
          }
          libelle
          famille {
            code
            libelle
          }
          grand_group {
            domaine
            libelle
            code
          }
          pcs_ese {
            libelle
            code
          }
        }
        romes
      }
    }
  `;

  constructor(private apollo: Apollo) {}

  getThemes(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_THEMES,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  getDomaines(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_DOMAINES,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  getDomainesProfessionnel(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_DOMAINES_PROFESSIONNEL,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  getDomainesProfessionnelByGrandDomaine(code: string): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_DOMAINES_PROFESSIONNEL_BY_GRAND_DOMAINE,
        variables: {
          code: code,
        },
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  getMetiersByDomainesProfessionnel(code: string): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_METIERS_BY_DOMAINE_PROFESSIONNEL,
        variables: {
          code: code,
        },
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  getAppelationByMetier(code: string): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_APPELATIONS_BY_METIER,
        variables: {
          code: code,
        },
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  getAppelationDetail(code: string): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_APPELATION_DETAIL,
        variables: {
          code: code,
        },
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  getMetierDetail(code: string): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_METIER_DETAIL,
        variables: {
          code: code,
        },
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  getMetierqQualification(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_METIER_QUALIFICATION,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  getMetierFap(code: string): Observable<any> {
    console.log(code);
    return this.apollo
      .watchQuery<any>({
        query: this.GET_METIER_FAP,
        variables: {
          code: code,
        },
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }
}
