import { ModuleState } from 'src/app/components/module/store/module.reducer';
import * as fromModuleSelector from 'src/app/components/module/store/module.selectors';

import { ActionSmallId } from './../components/action/store/action.model';
import { ActionService } from './action.service';
import { Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  doc,
  docSnapshots,
  collectionChanges,
  getDoc,
  serverTimestamp,
  DocumentData,
  DocumentSnapshot,
  Timestamp,
  query,
  where,
  collectionSnapshots,
  getDocs,
  documentId,
  CollectionReference,
} from '@angular/fire/firestore';
import { Observable, of, from, EMPTY } from 'rxjs';
import { switchMap, mergeMap, map, catchError } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { UserState } from '../components/user/store/user.reducer';
import * as fromUserSelector from '../components/user/store/user.selectors';
import { OperationState } from 'src/app/components/operation/store/operation.reducer';
import * as fromOperationSelector from 'src/app/components/operation/store/operation.selectors';
import { Apollo, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'any',
})
export class QueryActionsService {
  GET_UTILITIES = gql`
    query ActionType {
      actionType {
        types
        roles
        etats
      }
    }
  `;

  constructor(
    private actionService: ActionService,
    private db: Firestore,
    private userStore: Store<UserState>,
    private operationStore: Store<OperationState>,
    private moduleStore: Store<ModuleState>,
    private apollo: Apollo
  ) {}

  queryActions(filter: {
    operationId: string;
    event: string;
    type: string;
  }): Observable<ActionSmallId[] | any> {
    try {
      return this.userStore.pipe(
        select(fromUserSelector.user),
        switchMap((user: any): any => {
          if (user) {
            switch (filter.type) {
              case 'title':
                return this.queryTitle(filter.operationId, filter.event);
                break;
              default:
                return this.actionService.getAll(filter.operationId);
                break;
            }
          } else {
            return of(null);
          }
        })
      );
    } catch (error) {
      return of(null);
    }
  }

  getByOperation(): Observable<any> {
    try {
      return this.operationStore.select(fromOperationSelector.operation).pipe(
        switchMap((operation) => {
          if (!operation || !operation?.id) {
            return of([]);
          } else {
            const dbCollection: CollectionReference<DocumentData> = collection(
              this.db,
              `organisations/${operation.organisation.id}/operations/${operation.id}/actions`
            );

            const dbQuery = query(dbCollection, where('isFinish', '==', false));

            return from(
              getDocs(dbQuery)
                .then((snap) => {
                  return snap.docs.map((doc: DocumentData) => {
                    const data = doc.data() as any;
                    const id = doc.id;
                    return { id, ...data };
                  });
                })
                .catch((error) => error)
            ).pipe(mergeMap((res) => of(res)));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  getByModule(operationId?: string, module?: any): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) {
            return EMPTY;
          } else {
            if (operationId && module) {
              return this.getByModuleId(operationId, module);
            }

            return this.getByModuleNoId(module);
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  getByModuleNoId(module: any): Observable<any> {
    try {
      if (!module) {
        return EMPTY;
      }
      const dbCollection = collection(
        this.db,
        `organisations/${module?.organisation.id}/operations/${module.operation.id}/actions`
      );

      return collectionSnapshots(dbCollection).pipe(
        map((snap: DocumentSnapshot<DocumentData>[]) => {
          return snap.map((doc: DocumentData) => {
            const data = doc.data() as any;
            const id = doc.id;
            return { id, ...data };
          });
        }),
        catchError((error) => error)
      );
    } catch (error) {
      return of(error);
    }
  }

  getByModuleId(operationId: string, module: any): Observable<any> {
    try {
      if (!module || !operationId) {
        return EMPTY;
      }

      const dbCollection = collection(
        this.db,
        `organisations/${module.organisation.id}/operations/${operationId}/actions`
      );

      const { id } = module;
      const dbQuery = query(dbCollection, where('module.id', '==', id));

      return collectionSnapshots(dbQuery).pipe(
        map((snap: DocumentSnapshot<DocumentData>[]) => {
          return snap.map((doc: DocumentData) => {
            const data = doc.data() as any;
            const id = doc.id;
            return { id, ...data };
          });
        }),
        catchError((error) => error)
      );
    } catch (error) {
      return of(error);
    }
  }

  queryTitle(
    operationId: string,
    value: string
  ): Observable<ActionSmallId[] | any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (user) {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/operations/${operationId}/actions`
            );
            const dbQuery = query(
              dbCollection,
              where(
                'metadata.arrayTitle',
                'array-contains',
                value.toUpperCase()
              )
            );

            return from(
              getDocs(dbQuery)
                .then((snap) => {
                  return snap.docs.map((doc: DocumentData) => {
                    const data = doc.data() as any;
                    const id = doc.id;
                    return { id, ...data };
                  });
                })
                .catch((error) => error)
            ).pipe(mergeMap((res) => of(res)));
          } else {
            return of(null);
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  queryUtilities(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_UTILITIES,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }
}
