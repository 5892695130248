<ng-container *ngIf="source; else noData">
  <div nz-row nzAlign="middle" nzJustify="space-between" nzGutter="8">
    <div nz-col [nzSpan]="9">
      <nz-progress
        *ngIf="start && end"
        nzStrokeWidth="8"
        [nzFormat]="formatTemps ? formatTemps : undefined"
        [nzPercent]="source | timeProgression: start:end"
        nzType="circle"
        [nzWidth]="100"
      ></nz-progress>
      <br />
      <div class="title"><small>Temps</small></div>
    </div>
    <div nz-col [nzSpan]="10">
      <nz-progress
        [nzFormat]="formatObjectif ? formatObjectif : undefined"
        nzStrokeWidth="8"
        [nzPercent]="progression ? progression * 100 : 0 + '%'"
        nzType="circle"
        [nzWidth]="100"
        [nzStatus]="
          progression && progression >= 0.5 && progression <= 0.99
            ? 'active'
            : progression && progression >= 1
            ? 'success'
            : 'exception'
        "
      ></nz-progress>
      <br />
      <div class="title"><small>Progression</small></div>
    </div>
    <div nz-col [nzSpan]="5">
      <nz-select
        nzSize="small"
        [ngModel]="progressionSelected$ | async"
        (ngModelChange)="onChangeProgression($event)"
      >
        <nz-option
          *ngFor="let item of progressionValue"
          [nzValue]="item"
          [nzLabel]="item | percent"
        >
        </nz-option>
      </nz-select>
    </div>
  </div>
</ng-container>

<ng-template #noData>
  <nz-empty></nz-empty>
</ng-template>
