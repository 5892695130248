import { NzModalService } from 'ng-zorro-antd/modal';
import { ParticipantValidatorComponent } from './../../../../contents/components/participant-validator/participant-validator.component';
import { takeUntil } from 'rxjs/operators';
import { ParticipantId } from './../../store/participant.model';
import { Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { ParticipantState } from './../../store/participant.reducer';
import * as fromParticipantAction from './../../store/participant.actions';
import * as fromParticipantSelector from './../../store/participant.selectors';
import { of, Observable, Subject } from 'rxjs';
import { OperationSmallId } from 'src/app/components/operation/store/operation.model';

@Component({
  selector: 'app-participant-operations',
  templateUrl: './participant-operations.component.html',
  styleUrls: ['./participant-operations.component.scss'],
})
export class ParticipantOperationsComponent implements OnInit, OnDestroy {
  participant$: Observable<ParticipantId | any> = of(null);
  operations$: Observable<any> = of(null);
  subscribe = new Subject();
  identity: string = 'participant';
  addProjetTitle: string = 'Ajouter le participant à un projet';
  modalWidth: number = 650;

  constructor(
    private participantStore: Store<ParticipantState>,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.getParticipant();
    this.getOperations();
    this.loadOperations();
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getParticipant(): void {
    this.participant$ = this.participantStore.select(
      fromParticipantSelector.participant
    );
  }

  getOperations(): void {
    this.operations$ = this.participantStore.select(
      fromParticipantSelector.operations
    );
  }

  loadOperations(): void {
    this.participant$
      .pipe(takeUntil(this.subscribe))
      .subscribe((participant: ParticipantId) => {
        if (!participant) {
          return;
        } else {
          const participantId: string = participant.id;
          this.participantStore.dispatch(
            fromParticipantAction.loadOperations({ participantId })
          );
        }
      });
  }

  onAdd(operation: OperationSmallId, participant: ParticipantId): void {
    if (!operation || !participant) return;
    this.participantStore.dispatch(
      fromParticipantAction.addOperation({ participant, operation })
    );
  }
  onUpdate(operation: any, participant: ParticipantId): void {
    if (!operation || !participant) return;
    this.participantStore.dispatch(
      fromParticipantAction.updateOperation({ participant, operation })
    );
  }

  onDelete(operation: OperationSmallId, participant: ParticipantId): void {
    if (!operation || !participant) return;

    this.participantStore.dispatch(
      fromParticipantAction.deleteOperation({ participant, operation })
    );
  }

  onUpdateValidation(event: any): void {}
}
