import { DepartementState } from './../../store/departement.reducer';
import * as fromDepartementSelector from './../../store/departement.selectors';
import * as fromDepartementAction from './../../store/departement.actions';

import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-departement-item-header',
  templateUrl: './departement-item-header.component.html',
  styleUrls: ['./departement-item-header.component.scss'],
})
export class DepartementItemHeaderComponent implements OnInit {
  departement$: Observable<DepartementState | any> = of(null);
  deleteType: string =
    'Les collaborateurs concernés par ce service ne seront plus associés à un service. Voulez-vous supprimer ce service ? ';

  constructor(private departementStore: Store<DepartementState>) {}

  ngOnInit(): void {
    this.getDepartement();
  }

  getDepartement(): void {
    this.departement$ = this.departementStore.select(
      fromDepartementSelector.departement
    );
  }

  onAction(event: string, departement: any): void {
    if (!event || !departement) return;
    switch (event) {
      case 'delete':
        this.onDelete(departement);
        break;

      default:
        break;
    }
  }

  onDelete(departement: any): void {
    const { id } = departement;
    this.departementStore.dispatch(
      fromDepartementAction.deleteDepartement({ id })
    );
  }
}
