<div class="form-control">
  <label [for]="inputId">{{ label }}</label>
  <input
    nz-input
    [class.inputError]="control.invalid && control.dirty && control.touched"
    [formControl]="control"
    [type]="type"
    [id]="inputId"
    [placeholder]="placeholder"
    [nzBorderless]="borderless"
    [required]="required"
  />
  <ng-container *ngIf="control.invalid && control.dirty && control.touched">
    <small *ngFor="let error of control.errors | keyvalue">
      {{ errorMessages[error.key] }}
    </small>
  </ng-container>
</div>
