import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { format, fromUnixTime } from 'date-fns';
import { fr } from 'date-fns/locale';
import { flatten, groupBy, isNull, isUndefined, sum } from 'lodash';
import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-operation-dashboard-missions',
  templateUrl: './operation-dashboard-missions.component.html',
  styleUrls: ['./operation-dashboard-missions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationDashboardMissionsComponent
  implements OnInit, OnChanges, OnDestroy
{
  missions$ = new BehaviorSubject<any>([]);
  missionsByMonths$ = new BehaviorSubject<any>([]);
  missionsByYears$ = new BehaviorSubject<any>([]);
  missionsLibelle$ = new BehaviorSubject<any>([]);
  missionsDomaine$ = new BehaviorSubject<any>([]);
  missionsGrandDomaine$ = new BehaviorSubject<any>([]);
  missionsQualification$ = new BehaviorSubject<any>([]);
  missionsFapPcs$ = new BehaviorSubject<any>([]);
  missionsTypeContrat$ = new BehaviorSubject<any>([]);
  missionsNatureContrat$ = new BehaviorSubject<any>([]);
  missionsExperience$ = new BehaviorSubject<any>([]);
  missionsLibellesByHours$ = new BehaviorSubject<any>([]);
  missionsGrandsDomainesByHours$ = new BehaviorSubject<any>([]);
  missionsDomainesProfessionnelsByHours$ = new BehaviorSubject<any>([]);
  missionsQualificationsByHours$ = new BehaviorSubject<any>([]);
  missionsFapPcsByHours$ = new BehaviorSubject<any>([]);
  missionsTypesDeContratsByHours$ = new BehaviorSubject<any>([]);
  missionsNaturesDeContratsByHours$ = new BehaviorSubject<any>([]);
  missionsExperiencesByHours$ = new BehaviorSubject<any>([]);
  missionsLibelleByPercent$ = new BehaviorSubject<any>([]);
  missionsDomaineByPercent$ = new BehaviorSubject<any>([]);
  missionsGrandDomaineByPercent$ = new BehaviorSubject<any>([]);
  missionsQualificationByPercent$ = new BehaviorSubject<any>([]);
  missionsFapPcsByPercent$ = new BehaviorSubject<any>([]);
  missionsTypeContratByPercent$ = new BehaviorSubject<any>([]);
  missionsNatureContratByPercent$ = new BehaviorSubject<any>([]);
  missionsExperienceByPercent$ = new BehaviorSubject<any>([]);

  subscribe = new Subject();
  noExist = 'Non renseigné';

  @Input() dashboard$: Observable<any> = of(null);

  constructor() {}

  ngOnInit(): void {
    this.setMissionsDashboard();
  }

  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  chartsValues = (statistiques: any) => {
    const values = statistiques
      ? Object.values(statistiques).map((item) => {
          if (isNull(item) || isUndefined(item) || !item) {
            return this.noExist;
          }
          return item;
        })
      : [];

    const labels = statistiques
      ? Object.keys(statistiques).map((item) => {
          if (isNull(item) || isUndefined(item) || !item) {
            return this.noExist;
          }
          return item;
        })
      : [];

    return { values, labels };
  };

  itemsCounter = (items: any[]) => {
    return items.reduce((acc, cur) => {
      const curSize = items.filter((el) => el === cur).length;

      acc[cur] = curSize;
      return acc;
    }, {});
  };

  chartsTypePie = (count: any): any => {
    const transformToArray: any[] = Object.entries(count);
    const transformForChart: any[] = transformToArray.map((el) => ({
      name: el[0],
      value: el[1],
    }));

    return transformForChart;
  };

  setMissionsDashboard(): void {
    this.dashboard$.pipe(takeUntil(this.subscribe)).subscribe((dashboard) => {
      if (!dashboard) return;

      const missions: any[] = dashboard?.missions?.data;
      const objectifs: number = dashboard?.objectifs ? dashboard?.objectifs : 0;
      if (!missions?.length) return;

      this.missions$.next(missions);
      this.setMissionsCharts(missions, objectifs);
    });
  }

  setMissionsCharts(missions: any[], objectifs: number): void {
    this.setMissionsLibelle(missions);
    this.setMissionsDomaineProfessionnel(missions);
    this.setMissionsGrandDomaine(missions);
    this.setMissionsQualification(missions);
    this.setMissionsFapPcs(missions);
    this.setMissionsTypeContrat(missions);
    this.setMissionsNatureContrat(missions);
    this.setMissionsExperiencesSouhait(missions);
    this.setMissionsLibellesByHours(missions);
    this.setMissionsGrandsDomainesByHours(missions);
    this.setMissionsDomaineProfessionnelsByHours(missions);
    this.setMissionsQualificationByHours(missions);
    this.setMissionsFamillesProfessionnellesByHours(missions);
    this.setMissionsTypesDeContratByHours(missions);
    this.setMissionsNaturesDeContratByHours(missions);
    this.setMissionsExperiencesSouhaitByHours(missions);
    this.setMissionsByMonths(missions);
    this.setMissionsYears(missions);

    if (!objectifs) return;
    this.setMissionsLibellesByPercent(missions, objectifs);
    this.setMissionsGrandsDomainesByPercent(missions, objectifs);
    this.setMissionsDomaineProfessionnelsByPercent(missions, objectifs);
    this.setMissionsQualificationByPercent(missions, objectifs);
    this.setMissionsFamillesProfessionnellesByPercent(missions, objectifs);
    this.setMissionsTypesDeContratByPercent(missions, objectifs);
    this.setMissionsNaturesDeContratByPercent(missions, objectifs);
    this.setMissionsExperiencesSouhaitByPercent(missions, objectifs);
  }

  setMissionsLibelle(missions: any[]): void {
    const items: string[] = missions.map(
      (mission: any) => mission?.libelleCourt
    );

    const dataCounter: any = this.itemsCounter(items);
    const pieData: any[] = this.chartsTypePie(dataCounter);

    const itemsChartOption = {
      title: 'Métiers concernés',
      chartsTitle: '',
      type: 'donut',
      data: pieData,
      center: ['50%', '70%'],
      radius: 120,
      height: '400px',
    };

    this.missionsLibelle$.next(itemsChartOption);
  }

  setMissionsByMonths(missions: any[]): void {
    const transformMissionDates: string[] = missions.map((mission: any) => {
      const el = {
        ...mission,
        startMission: format(
          fromUnixTime(mission?.startMission['seconds']),
          'MM/yyyy',
          { locale: fr }
        ),
      };

      return el;
    });
    const groupByDates = groupBy(transformMissionDates, 'startMission');
    const groupToArray = Object.entries(groupByDates);

    const labels = groupToArray.map((el) => el[0]);
    const values = groupToArray.map((el) => el[1]?.length);

    const options = {
      title: 'Répartition des missions par mois',
      chartsTitle: '',
      type: 'barSimpler',
      data: {
        xAxis: labels,
        yAxis: values,
      },
      center: ['50%', '70%'],
      radius: 120,
      rotate: 0,
      height: '400px',
    };

    this.missionsByMonths$.next(options);
  }

  setMissionsYears(missions: any[]): void {
    const transformMissionDates: string[] = missions.map((mission: any) => {
      const el = {
        ...mission,
        startMission: format(
          fromUnixTime(mission?.startMission['seconds']),
          'yyyy',
          { locale: fr }
        ),
      };

      return el;
    });
    const groupByDates = groupBy(transformMissionDates, 'startMission');
    const groupToArray = Object.entries(groupByDates);

    const labels = groupToArray.map((el) => el[0]);
    const values = groupToArray.map((el) => el[1]?.length);

    const options = {
      title: 'Répartition des missions par année',
      chartsTitle: '',
      type: 'barSimpler',
      data: {
        xAxis: labels,
        yAxis: values,
      },
      center: ['50%', '70%'],
      radius: 120,
      rotate: 0,

      height: '400px',
    };
    this.missionsByYears$.next(options);
  }

  setMissionsDomaineProfessionnel(missions: any[]): void {
    const items: string[] = missions.map(
      (mission: any) => mission?.domaineProfessionnelLibelle
    );
    const dataCounter: any = this.itemsCounter(items);
    const pieData: any[] = this.chartsTypePie(dataCounter);

    const itemsChartOption = {
      title: 'Domaines professionnels concernés',
      chartsTitle: '',
      type: 'donut',
      data: pieData,
      center: ['50%', '70%'],
      radius: 120,
      height: '400px',
    };

    this.missionsDomaine$.next(itemsChartOption);
  }
  setMissionsGrandDomaine(missions: any[]): void {
    const items: string[] = missions.map(
      (mission: any) => mission?.domaineProfessionneGrandDomaine
    );

    const dataCounter: any = this.itemsCounter(items);
    const pieData: any[] = this.chartsTypePie(dataCounter);

    const itemsChartOption = {
      title: 'Grands domaines concernés',
      chartsTitle: '',
      type: 'donut',
      data: pieData,
      center: ['50%', '70%'],
      radius: 120,
      height: '400px',
    };

    this.missionsGrandDomaine$.next(itemsChartOption);
  }

  setMissionsQualification(missions: any[]): void {
    const items: string[] = missions.map(
      (mission: any) => mission?.qualification
    );
    const dataCounter: any = this.itemsCounter(items);
    const pieData: any[] = this.chartsTypePie(dataCounter);

    const itemsChartOption = {
      title: 'Qualifications professionnelles concernées',
      chartsTitle: '',
      type: 'donut',
      data: pieData,
      center: ['50%', '70%'],
      radius: 120,
      height: '400px',
    };

    this.missionsQualification$.next(itemsChartOption);
  }

  setMissionsFapDomaine(missions: any[]): void {
    const items: string[] = missions.map((mission: any) => mission?.fapDomaine);
    const dataCounter: any = this.itemsCounter(items);
    const pieData: any[] = this.chartsTypePie(dataCounter);

    const itemsChartOption = {
      title: 'Qualifications professionnelles concernées',
      chartsTitle: '',
      type: 'donut',
      data: pieData,
      center: ['50%', '70%'],
      radius: 120,
      height: '400px',
    };

    this.missionsQualification$.next(itemsChartOption);
  }

  setMissionsFapPcs(missions: any[]): void {
    const items: string[] = missions.map((mission: any) => mission?.fapPcs);
    const dataCounter: any = this.itemsCounter(items);
    const pieData: any[] = this.chartsTypePie(dataCounter);

    const itemsChartOption = {
      title: 'Familles professionnelles concernées',
      chartsTitle: '',
      type: 'donut',
      data: pieData,
      center: ['50%', '70%'],
      radius: 120,
      height: '400px',
    };

    this.missionsFapPcs$.next(itemsChartOption);
  }

  setMissionsTypeContrat(missions: any[]): void {
    const items: string[] = missions.map(
      (mission: any) => mission?.type_contrat
    );
    const dataCounter: any = this.itemsCounter(items);
    const pieData: any[] = this.chartsTypePie(dataCounter);

    const itemsChartOption = {
      title: 'Types de contrat utilisé',
      chartsTitle: '',
      type: 'donut',
      data: pieData,
      center: ['50%', '70%'],
      radius: 120,
      height: '400px',
    };

    this.missionsTypeContrat$.next(itemsChartOption);
  }
  setMissionsNatureContrat(missions: any[]): void {
    const items: string[] = missions.map(
      (mission: any) => mission?.nature_contrat
    );
    const dataCounter: any = this.itemsCounter(items);
    const pieData: any[] = this.chartsTypePie(dataCounter);

    const itemsChartOption = {
      title: 'Natures de contrat utilisée',
      chartsTitle: '',
      type: 'donut',
      data: pieData,
      center: ['50%', '70%'],
      radius: 120,
      height: '400px',
    };

    this.missionsNatureContrat$.next(itemsChartOption);
  }
  setMissionsExperiencesSouhait(missions: any[]): void {
    const items: string[] = missions.map(
      (mission: any) => mission?.experience_souhait
    );
    const dataCounter: any = this.itemsCounter(items);
    const pieData: any[] = this.chartsTypePie(dataCounter);

    const itemsChartOption = {
      title: 'Expérience souhaitée lors du recrutement',
      chartsTitle: '',
      type: 'donut',
      data: pieData,
      center: ['50%', '70%'],
      radius: 120,
      height: '400px',
    };

    this.missionsExperience$.next(itemsChartOption);
  }

  //MISSIONS HOURS
  setMissionsLibellesByHours(missions: any[]): void {
    const group: any = groupBy(missions, 'libelleCourt');
    const groupToArray: any[][] = Object.entries(group);
    const labels: any[] = flatten(groupToArray.map((el: any[]) => el[0]));
    const values: any[] = flatten(
      groupToArray.map((arrayOfMissions: any[]) =>
        arrayOfMissions[1].map((mission: any) =>
          sum(mission?.hours.map((hours: any) => hours?.sum))
        )
      )
    );

    const xAxis: any = labels?.length ? labels : [];
    const yAxis: any =
      values?.length && labels?.length === 1
        ? [sum(values)]
        : values?.length && labels?.length > 1
        ? values
        : [];

    const options = {
      title: 'Répartition des réalisations par métier',
      chartsTitle: '',
      type: 'barSimpler',
      data: {
        xAxis: xAxis,
        yAxis: yAxis,
      },
      typeValue: 'hours',

      center: ['50%', '70%'],
      radius: 120,
      height: '400px',
    };

    this.missionsLibellesByHours$.next(options);
  }
  setMissionsGrandsDomainesByHours(missions: any[]): void {
    const group: any = groupBy(missions, 'domaineProfessionneGrandDomaine');
    const groupToArray: any[][] = Object.entries(group);
    const labels: any[] = flatten(groupToArray.map((el: any[]) => el[0]));
    const values: any[] = flatten(
      groupToArray.map((arrayOfMissions: any[]) =>
        arrayOfMissions[1].map((mission: any) =>
          sum(mission?.hours.map((hours: any) => hours?.sum))
        )
      )
    );

    const xAxis: any = labels?.length ? labels : [];
    const yAxis: any =
      values?.length && labels?.length === 1
        ? [sum(values)]
        : values?.length && labels?.length > 1
        ? values
        : [];

    const options = {
      title: 'Répartition des réalisations par grand domaine',
      chartsTitle: '',
      type: 'barSimpler',
      data: {
        xAxis: xAxis,
        yAxis: yAxis,
      },
      typeValue: 'hours',

      center: ['50%', '70%'],
      radius: 120,
      height: '400px',
    };

    this.missionsGrandsDomainesByHours$.next(options);
  }
  setMissionsDomaineProfessionnelsByHours(missions: any[]): void {
    const group: any = groupBy(missions, 'domaineProfessionnelLibelle');
    const groupToArray: any[][] = Object.entries(group);
    const labels: any[] = flatten(groupToArray.map((el: any[]) => el[0]));
    const values: any[] = flatten(
      groupToArray.map((arrayOfMissions: any[]) =>
        arrayOfMissions[1].map((mission: any) =>
          sum(mission?.hours.map((hours: any) => hours?.sum))
        )
      )
    );

    const xAxis: any = labels?.length ? labels : [];
    const yAxis: any =
      values?.length && labels?.length === 1
        ? [sum(values)]
        : values?.length && labels?.length > 1
        ? values
        : [];

    const options = {
      title: 'Répartition des réalisations par domaine professionnel',
      chartsTitle: '',
      type: 'barSimpler',
      data: {
        xAxis: xAxis,
        yAxis: yAxis,
      },
      typeValue: 'hours',

      center: ['50%', '70%'],
      radius: 120,
      height: '400px',
    };

    this.missionsDomainesProfessionnelsByHours$.next(options);
  }
  setMissionsQualificationByHours(missions: any[]): void {
    const group: any = groupBy(missions, 'qualification');
    const groupToArray: any[][] = Object.entries(group);
    const labels: any[] = flatten(groupToArray.map((el: any[]) => el[0]));
    const values: any[] = flatten(
      groupToArray.map((arrayOfMissions: any[]) =>
        arrayOfMissions[1].map((mission: any) =>
          sum(mission?.hours.map((hours: any) => hours?.sum))
        )
      )
    );

    const xAxis: any = labels?.length ? labels : [];
    const yAxis: any =
      values?.length && labels?.length === 1
        ? [sum(values)]
        : values?.length && labels?.length > 1
        ? values
        : [];

    const options = {
      title: 'Répartition des réalisations par qualification',
      chartsTitle: '',
      type: 'barSimpler',
      data: {
        xAxis: xAxis,
        yAxis: yAxis,
      },
      typeValue: 'hours',

      center: ['50%', '70%'],
      radius: 120,
      height: '400px',
    };

    this.missionsQualificationsByHours$.next(options);
  }
  setMissionsFamillesProfessionnellesByHours(missions: any[]): void {
    const group: any = groupBy(missions, 'fapPcs');
    const groupToArray: any[][] = Object.entries(group);
    const labels: any[] = flatten(groupToArray.map((el: any[]) => el[0]));
    const values: any[] = flatten(
      groupToArray.map((arrayOfMissions: any[]) =>
        arrayOfMissions[1].map((mission: any) =>
          sum(mission?.hours.map((hours: any) => hours?.sum))
        )
      )
    );

    const xAxis: any = labels?.length ? labels : [];
    const yAxis: any =
      values?.length && labels?.length === 1
        ? [sum(values)]
        : values?.length && labels?.length > 1
        ? values
        : [];

    const options = {
      title: 'Répartition des réalisations par famille professionnelle',
      chartsTitle: '',
      type: 'barSimpler',
      data: {
        xAxis: xAxis,
        yAxis: yAxis,
      },
      typeValue: 'hours',

      center: ['50%', '70%'],
      radius: 120,
      height: '400px',
    };

    this.missionsFapPcsByHours$.next(options);
  }
  setMissionsTypesDeContratByHours(missions: any[]): void {
    const group: any = groupBy(missions, 'type_contrat');
    const groupToArray: any[][] = Object.entries(group);
    const labels: any[] = flatten(groupToArray.map((el: any[]) => el[0]));
    const values: any[] = flatten(
      groupToArray.map((arrayOfMissions: any[]) =>
        arrayOfMissions[1].map((mission: any) =>
          sum(mission?.hours.map((hours: any) => hours?.sum))
        )
      )
    );

    const xAxis: any = labels?.length ? labels : [];
    const yAxis: any =
      values?.length && labels?.length === 1
        ? [sum(values)]
        : values?.length && labels?.length > 1
        ? values
        : [];

    const options = {
      title: 'Répartition des réalisations par type de contrat',
      chartsTitle: '',
      type: 'barSimpler',
      data: {
        xAxis: xAxis,
        yAxis: yAxis,
      },
      typeValue: 'hours',

      center: ['50%', '70%'],
      radius: 120,
      height: '400px',
    };

    this.missionsTypesDeContratsByHours$.next(options);
  }
  setMissionsNaturesDeContratByHours(missions: any[]): void {
    const group: any = groupBy(missions, 'nature_contrat');
    const groupToArray: any[][] = Object.entries(group);
    const labels: any[] = flatten(groupToArray.map((el: any[]) => el[0]));
    const values: any[] = flatten(
      groupToArray.map((arrayOfMissions: any[]) =>
        arrayOfMissions[1].map((mission: any) =>
          sum(mission?.hours.map((hours: any) => hours?.sum))
        )
      )
    );

    const xAxis: any = labels?.length ? labels : [];
    const yAxis: any =
      values?.length && labels?.length === 1
        ? [sum(values)]
        : values?.length && labels?.length > 1
        ? values
        : [];

    const options = {
      title: 'Répartition des réalisations par nature de contrat',
      chartsTitle: '',
      type: 'barSimpler',
      data: {
        xAxis: xAxis,
        yAxis: yAxis,
      },
      typeValue: 'hours',

      center: ['50%', '70%'],
      radius: 120,
      height: '400px',
    };

    this.missionsNaturesDeContratsByHours$.next(options);
  }
  setMissionsExperiencesSouhaitByHours(missions: any[]): void {
    const group: any = groupBy(missions, 'experience_souhait');
    const groupToArray: any[][] = Object.entries(group);
    const labels: any[] = flatten(groupToArray.map((el: any[]) => el[0]));
    const values: any[] = flatten(
      groupToArray.map((arrayOfMissions: any[]) =>
        arrayOfMissions[1].map((mission: any) =>
          sum(mission?.hours.map((hours: any) => hours?.sum))
        )
      )
    );

    const xAxis: any = labels?.length ? labels : [];
    const yAxis: any =
      values?.length && labels?.length === 1
        ? [sum(values)]
        : values?.length && labels?.length > 1
        ? values
        : [];

    const options = {
      title: 'Répartition des réalisations par expérience souhaitée',
      chartsTitle: '',
      type: 'barSimpler',
      data: {
        xAxis: xAxis,
        yAxis: yAxis,
      },
      typeValue: 'hours',
      center: ['50%', '70%'],
      radius: 120,
      height: '400px',
    };

    this.missionsExperiencesByHours$.next(options);
  }

  //MISSIONS POURCENT
  setMissionsLibellesByPercent(missions: any[], objectifs: number): void {
    const group: any = groupBy(missions, 'libelleCourt');
    const groupToArray: any[][] = Object.entries(group);
    const labels: any[] = flatten(groupToArray.map((el: any[]) => el[0]));
    const values: any[] = flatten(
      groupToArray.map((arrayOfMissions: any[]) =>
        arrayOfMissions[1].map(
          (mission: any) =>
            Number(
              (
                sum(mission?.hours.map((hours: any) => hours?.sum)) / objectifs
              ).toFixed(2)
            ) * 100
        )
      )
    );

    const xAxis: any = labels?.length ? labels : [];
    const yAxis: any =
      values?.length && labels?.length === 1
        ? [sum(values)]
        : values?.length && labels?.length > 1
        ? values
        : [];

    const options = {
      title: "Part des réalisations par métier sur l'objectif",
      chartsTitle: '',
      type: 'barSimpler',
      data: {
        xAxis: xAxis,
        yAxis: yAxis,
      },
      center: ['50%', '70%'],
      typeValue: 'percent',
      rotate: 0,
      radius: 120,
      height: '400px',
    };

    this.missionsLibelleByPercent$.next(options);
  }
  setMissionsGrandsDomainesByPercent(missions: any[], objectifs: number): void {
    const group: any = groupBy(missions, 'domaineProfessionneGrandDomaine');
    const groupToArray: any[][] = Object.entries(group);
    const labels: any[] = flatten(groupToArray.map((el: any[]) => el[0]));
    const values: any[] = flatten(
      groupToArray.map((arrayOfMissions: any[]) =>
        arrayOfMissions[1].map(
          (mission: any) =>
            Number(
              (
                sum(mission?.hours.map((hours: any) => hours?.sum)) / objectifs
              ).toFixed(2)
            ) * 100
        )
      )
    );

    const xAxis: any = labels?.length ? labels : [];
    const yAxis: any =
      values?.length && labels?.length === 1
        ? [sum(values)]
        : values?.length && labels?.length > 1
        ? values
        : [];

    const options = {
      title: "Part des réalisations par grand domaine sur l'objectif",
      chartsTitle: '',
      type: 'barSimpler',
      data: {
        xAxis: xAxis,
        yAxis: yAxis,
      },
      center: ['50%', '70%'],
      typeValue: 'percent',
      rotate: 0,
      radius: 120,
      height: '400px',
    };

    this.missionsGrandDomaineByPercent$.next(options);
  }
  setMissionsDomaineProfessionnelsByPercent(
    missions: any[],
    objectifs: number
  ): void {
    const group: any = groupBy(missions, 'domaineProfessionnelLibelle');
    const groupToArray: any[][] = Object.entries(group);
    const labels: any[] = flatten(groupToArray.map((el: any[]) => el[0]));
    const values: any[] = flatten(
      groupToArray.map((arrayOfMissions: any[]) =>
        arrayOfMissions[1].map(
          (mission: any) =>
            Number(
              (
                sum(mission?.hours.map((hours: any) => hours?.sum)) / objectifs
              ).toFixed(2)
            ) * 100
        )
      )
    );

    const xAxis: any = labels?.length ? labels : [];
    const yAxis: any =
      values?.length && labels?.length === 1
        ? [sum(values)]
        : values?.length && labels?.length > 1
        ? values
        : [];

    const options = {
      title: "Part des réalisations par domaine professionnel sur l'objectif",
      chartsTitle: '',
      type: 'barSimpler',
      data: {
        xAxis: xAxis,
        yAxis: yAxis,
      },
      center: ['50%', '70%'],
      radius: 120,
      typeValue: 'percent',
      rotate: 0,

      height: '400px',
    };

    this.missionsDomaineByPercent$.next(options);
  }
  setMissionsQualificationByPercent(missions: any[], objectifs: number): void {
    const group: any = groupBy(missions, 'qualification');
    const groupToArray: any[][] = Object.entries(group);
    const labels: any[] = flatten(groupToArray.map((el: any[]) => el[0]));
    const values: any[] = flatten(
      groupToArray.map((arrayOfMissions: any[]) =>
        arrayOfMissions[1].map(
          (mission: any) =>
            Number(
              (
                sum(mission?.hours.map((hours: any) => hours?.sum)) / objectifs
              ).toFixed(2)
            ) * 100
        )
      )
    );

    const xAxis: any = labels?.length ? labels : [];
    const yAxis: any =
      values?.length && labels?.length === 1
        ? [sum(values)]
        : values?.length && labels?.length > 1
        ? values
        : [];

    const options = {
      title: "Part des réalisations par qualification sur l'objectif",
      chartsTitle: '',
      type: 'barSimpler',
      data: {
        xAxis: xAxis,
        yAxis: yAxis,
      },
      center: ['50%', '70%'],
      radius: 120,
      typeValue: 'percent',
      rotate: 0,

      height: '400px',
    };

    this.missionsQualificationByPercent$.next(options);
  }
  setMissionsFamillesProfessionnellesByPercent(
    missions: any[],
    objectifs: number
  ): void {
    const group: any = groupBy(missions, 'fapPcs');
    const groupToArray: any[][] = Object.entries(group);
    const labels: any[] = flatten(groupToArray.map((el: any[]) => el[0]));
    const values: any[] = flatten(
      groupToArray.map((arrayOfMissions: any[]) =>
        arrayOfMissions[1].map(
          (mission: any) =>
            Number(
              (
                sum(mission?.hours.map((hours: any) => hours?.sum)) / objectifs
              ).toFixed(2)
            ) * 100
        )
      )
    );

    const xAxis: any = labels?.length ? labels : [];
    const yAxis: any =
      values?.length && labels?.length === 1
        ? [sum(values)]
        : values?.length && labels?.length > 1
        ? values
        : [];

    const options = {
      title: "Part des réalisations par famille professionnelle sur l'objectif",
      chartsTitle: '',
      type: 'barSimpler',
      data: {
        xAxis: xAxis,
        yAxis: yAxis,
      },
      center: ['50%', '70%'],
      radius: 120,
      typeValue: 'percent',
      rotate: 0,

      height: '400px',
    };

    this.missionsFapPcsByPercent$.next(options);
  }
  setMissionsTypesDeContratByPercent(missions: any[], objectifs: number): void {
    const group: any = groupBy(missions, 'type_contrat');
    const groupToArray: any[][] = Object.entries(group);
    const labels: any[] = flatten(groupToArray.map((el: any[]) => el[0]));
    const values: any[] = flatten(
      groupToArray.map((arrayOfMissions: any[]) =>
        arrayOfMissions[1].map(
          (mission: any) =>
            Number(
              (
                sum(mission?.hours.map((hours: any) => hours?.sum)) / objectifs
              ).toFixed(2)
            ) * 100
        )
      )
    );

    const xAxis: any = labels?.length ? labels : [];
    const yAxis: any =
      values?.length && labels?.length === 1
        ? [sum(values)]
        : values?.length && labels?.length > 1
        ? values
        : [];

    const options = {
      title: "Part des réalisations par type de contrat sur l'objectif",
      chartsTitle: '',
      type: 'barSimpler',
      data: {
        xAxis: xAxis,
        yAxis: yAxis,
      },
      center: ['50%', '70%'],
      radius: 120,
      typeValue: 'percent',
      rotate: 0,

      height: '400px',
    };

    this.missionsTypeContratByPercent$.next(options);
  }
  setMissionsNaturesDeContratByPercent(
    missions: any[],
    objectifs: number
  ): void {
    const group: any = groupBy(missions, 'nature_contrat');
    const groupToArray: any[][] = Object.entries(group);
    const labels: any[] = flatten(groupToArray.map((el: any[]) => el[0]));
    const values: any[] = flatten(
      groupToArray.map((arrayOfMissions: any[]) =>
        arrayOfMissions[1].map(
          (mission: any) =>
            Number(
              (
                sum(mission?.hours.map((hours: any) => hours?.sum)) / objectifs
              ).toFixed(2)
            ) * 100
        )
      )
    );

    const xAxis: any = labels?.length ? labels : [];
    const yAxis: any =
      values?.length && labels?.length === 1
        ? [sum(values)]
        : values?.length && labels?.length > 1
        ? values
        : [];

    const options = {
      title: "Part des réalisations par nature de contrat sur l'objectif",
      chartsTitle: '',
      type: 'barSimpler',
      data: {
        xAxis: xAxis,
        yAxis: yAxis,
      },
      center: ['50%', '70%'],
      radius: 120,
      typeValue: 'percent',
      rotate: 0,

      height: '400px',
    };

    this.missionsNatureContratByPercent$.next(options);
  }
  setMissionsExperiencesSouhaitByPercent(
    missions: any[],
    objectifs: number
  ): void {
    const group: any = groupBy(missions, 'experience_souhait');
    const groupToArray: any[][] = Object.entries(group);
    const labels: any[] = flatten(groupToArray.map((el: any[]) => el[0]));
    const values: any[] = flatten(
      groupToArray.map((arrayOfMissions: any[]) =>
        arrayOfMissions[1].map(
          (mission: any) =>
            Number(
              (
                sum(mission?.hours.map((hours: any) => hours?.sum)) / objectifs
              ).toFixed(2)
            ) * 100
        )
      )
    );

    const xAxis: any = labels?.length ? labels : [];
    const yAxis: any =
      values?.length && labels?.length === 1
        ? [sum(values)]
        : values?.length && labels?.length > 1
        ? values
        : [];

    const options = {
      title: "Part des réalisations par expérience souhaitée sur l'objectifs",
      chartsTitle: '',
      type: 'barSimpler',
      data: {
        xAxis: xAxis,
        yAxis: yAxis,
      },
      center: ['50%', '70%'],
      radius: 120,
      typeValue: 'percent',
      rotate: 0,

      height: '400px',
    };

    this.missionsExperienceByPercent$.next(options);
  }
}
