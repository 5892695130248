<nz-card
  [nzTitle]="coverTitleTpl"
  nzHoverable
  [nzExtra]="operationtActions"
  [routerLink]="['/projet', operation.id]"
  *ngIf="operation"
>
  <ng-template #coverTitleTpl>
    <h4 class="tracking-wider">
      {{ operation?.denomination | carLimitation : 25 }}
    </h4>
  </ng-template>
  <ng-template #operationCardTitle>
    <app-header-title [icon]="'analytics'"></app-header-title>
  </ng-template>

  <div class="w-full space-y-2">
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="24">
        <nz-tag nzColor="geekblue">{{
          operation?.type ? (operation?.type | carLimitation : 20) : ""
        }}</nz-tag>
      </nz-col>
    </nz-row>

    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="2">
        <mat-icon>ssid_chart</mat-icon>
      </nz-col>
      <nz-col nzSpan="2"></nz-col>
      <nz-col nzSpan="20">
        <nz-progress
          *ngIf="operation?.progression < 1"
          [nzPercent]="operation?.progression * 100"
          [nzStrokeColor]="progressDefaultColor"
          [nzShowInfo]="true"
        ></nz-progress>
        <nz-progress
          *ngIf="operation?.progression >= 1"
          [nzPercent]="operation?.progression * 100"
          [nzPercent]="100"
          [nzStrokeColor]="progressCompleteColor"
          [nzShowInfo]="true"
        ></nz-progress>
      </nz-col>
    </nz-row>

    <div class="w-full flex items-start gap-x-2 p-2">
      <div class="w-8/12">
        <nz-tag
          [nzColor]="
            (operation?.description?.calendrier?.dateStart | isFutur)
              ? 'processing'
              : operation?.completed
              ? 'green'
              : 'orange'
          "
          >{{ operation?.status }}</nz-tag
        >
      </div>
      <div class="w-4/12">
        <app-avatar-group [source]="[operation?.auteur]"></app-avatar-group>
      </div>
    </div>
  </div>

  <ng-template #operationtActions>
    <button
      nz-button
      nzShape="circle"
      nzType="text"
      nz-dropdown
      [nzDropdownMenu]="coperationActionsMenu"
      nzPlacement="bottomRight"
      (click)="$event.stopPropagation()"
      nzTrigger="click"
    >
      <mat-icon>more_vert</mat-icon>
    </button>
  </ng-template>

  <nz-dropdown-menu #coperationActionsMenu="nzDropdownMenu">
    <ul nz-menu>
      <li nz-menu-item (click)="$event.stopPropagation(); onExport(operation)">
        <i nz-icon nzType="download"></i>
        <span class="item-dropdown">Exporter</span>
      </li>

      <li nz-menu-divider></li>
      <li
        nz-menu-item
        nzDanger
        nz-popconfirm
        [nzPopconfirmTitle]="'Voulez-vous supprimer ce projet ?'"
        nzPopconfirmPlacement="bottom"
        (nzOnConfirm)="onDelete(operation.id)"
        (nzOnCancel)="onCancel()"
      >
        <i nz-icon nzType="delete"></i>
        <span class="item-dropdown">Supprimer</span>
      </li>
    </ul>
  </nz-dropdown-menu>
</nz-card>
