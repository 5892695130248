<ng-container *ngIf="operation$ | async as operation">
  <nz-page-header
    class="page-header"
    [nzTitle]="header"
    [nzSubtitle]="cardExtra"
    *ngIf="header"
    nzBackIcon
    (nzBack)="onBack()"
  >
    <nz-page-header-extra>
      <button
        nz-button
        nzShape="circle"
        nzType="text"
        nz-dropdown
        [nzDropdownMenu]="operationInterventionMenu"
        nzPlacement="bottomRight"
        nzTrigger="click"
        (click)="$event.stopPropagation()"
      >
        <mat-icon>more_vert</mat-icon>
      </button>

      <nz-dropdown-menu #operationInterventionMenu="nzDropdownMenu">
        <ul nz-menu>
          <li
            nz-menu-item
            nzDanger
            nz-popconfirm
            nzPopconfirmTitle="Voulez-vous retirer ce projet ?"
            nzPopconfirmPlacement="bottom"
            (nzOnConfirm)="onDelete(operation)"
            (nzOnCancel)="onCancel()"
          >
            <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
              <nz-col nzSpan="6">
                <mat-icon class="delete">delete</mat-icon>
              </nz-col>
              <nz-col nzSpan="2">
                <nz-divider
                  nzOrientation="center"
                  nzType="vertical"
                ></nz-divider>
              </nz-col>
              <nz-col nzSpan="16">Supprimer</nz-col>
            </nz-row>
          </li>
        </ul>
      </nz-dropdown-menu>
    </nz-page-header-extra>
    <nz-page-header-content>
      <nz-descriptions
        nzBordered
        nzSize="small"
        [nzColumn]="{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }"
      >
        <nz-descriptions-item nzTitle="Type" nzSpan="1">
          {{ operation?.operation?.type }}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Service" nzSpan="1">
          {{ operation?.operation?.service?.title }}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Statut" nzSpan="1">
          {{ operation?.operation?.status }}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Équipe" nzSpan="1">
          <app-avatar-group
            [source]="operation?.operation?.equipe"
            [limit]="6"
          ></app-avatar-group>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Démarrage" nzSpan="1">
          {{
            operation?.operation?.description?.calendrier?.dateStart
              | dateFr: "small"
          }}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Échéance" nzSpan="1">
          {{
            operation?.operation?.description?.calendrier?.dateEnd
              | dateFr: "small"
          }}
        </nz-descriptions-item>
      </nz-descriptions>
    </nz-page-header-content>
    <ng-template #header>
      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col nzSpan="24">
          <h5 nz-typography>
            {{ operation?.operation?.denomination | carLimitation: 200 }}
          </h5>
        </nz-col>
      </nz-row>
    </ng-template>
    <ng-template #cardExtra>
      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col nzSpan="24"> </nz-col>
      </nz-row>
    </ng-template>
  </nz-page-header>

  <nz-tabset
    nzCentered
    (nzSelectedIndexChange)="selectedIndexChange($event)"
    [nzTabBarStyle]="tabSetColor"
    [nzTabBarExtraContent]="tabExtraContentTpl"
  >
    <ng-template #tabExtraContentTpl>
      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col nzSpan="8">
          <button
            @fade
            *ngIf="isFormChanges"
            nz-button
            nzType="text"
            (click)="hide_modification_validation()"
          >
            Annuler
          </button>
        </nz-col>
        <nz-col nzSpan="2"></nz-col>
        <nz-col nzSpan="8">
          <button
            @fade
            *ngIf="isFormChanges"
            nz-button
            nzType="primary"
            nz-popconfirm
            nzPopconfirmTitle="Voulez-vous modifier l'intervention ?"
            nzPopconfirmPlacement="bottom"
            (nzOnConfirm)="onUpdate(operation)"
            (nzOnCancel)="onCancel()"
            nz-tooltip
            nz-tooltipTitle="Valider la modification"
            nzTooltipTrigger="hover"
            nzTooltipPlacement="bottomRight"
            [nzTooltipMouseEnterDelay]="0.5"
          >
            Modifier
          </button>
        </nz-col>
        <nz-col nzSpan="6"> </nz-col>
      </nz-row>
    </ng-template>

    <nz-tab [nzTitle]="tabIntervention">
      <ng-template #tabIntervention>
        <app-tab-title
          [title]="'Intervention'"
          [isActiv]="currentSelected === 0 ? true : false"
        ></app-tab-title>
      </ng-template>
      <div [ngSwitch]="identity">
        <div *ngSwitchCase="'participant'">
          <nz-card class="operationInterventionElements">
            <form nz-form [formGroup]="interventionForm" nzLayout="vertical">
              <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
                <nz-col nzSpan="6">
                  <app-check-card
                    [title]="'Inscription'"
                    [definition]="definitionInscription"
                    [isActive]="interventionForm.value.isInscription"
                    (select)="selectStatusChange($event, 'inscription')"
                    [height]="'80px'"
                  ></app-check-card>
                </nz-col>
                <nz-col nzSpan="6">
                  <app-check-card
                    [title]="'Intégration'"
                    [definition]="definitionIntegration"
                    [isActive]="interventionForm.value.isParticipant"
                    (select)="selectStatusChange($event, 'integration')"
                    [height]="'80px'"
                  ></app-check-card>
                </nz-col>
                <nz-col nzSpan="6">
                  <app-check-card
                    [title]="'Désinscription'"
                    [definition]="definitionDesinscription"
                    [isActive]="interventionForm.value.isDesinscription"
                    (select)="selectStatusChange($event, 'desinscription')"
                    [height]="'80px'"
                  ></app-check-card>
                </nz-col>
                <nz-col nzSpan="6">
                  <app-check-card
                    [title]="'Sortie'"
                    [definition]="definitionSortie"
                    [isActive]="interventionForm.value.isSortie"
                    (select)="selectStatusChange($event, 'sortie')"
                    [height]="'80px'"
                  ></app-check-card>
                </nz-col>
              </nz-row>
              <nz-space nzDirection="vertical" nzSize="small"></nz-space>
              <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
                <nz-col nzSpan="6">
                  <nz-form-item>
                    <nz-form-label>
                      <small>Date d'inscription</small>
                    </nz-form-label>
                    <nz-form-control>
                      <nz-date-picker
                        nzBorderless
                        [nzFormat]="dateFormat"
                        formControlName="dateInscription"
                      ></nz-date-picker>
                    </nz-form-control>
                  </nz-form-item>
                </nz-col>
                <nz-col nzSpan="6">
                  <nz-form-item>
                    <nz-form-label>
                      <small>Date d'intégration</small>
                    </nz-form-label>
                    <nz-form-control>
                      <nz-date-picker
                        nzBorderless
                        [nzFormat]="dateFormat"
                        formControlName="dateIntegration"
                      ></nz-date-picker>
                    </nz-form-control>
                  </nz-form-item>
                </nz-col>
                <nz-col nzSpan="6">
                  <nz-form-item>
                    <nz-form-label>
                      <small>Date de sortie</small>
                    </nz-form-label>
                    <nz-form-control>
                      <nz-date-picker
                        nzBorderless
                        [nzFormat]="dateFormat"
                        formControlName="dateSortie"
                      ></nz-date-picker>
                    </nz-form-control>
                  </nz-form-item>
                </nz-col>
                <nz-col nzSpan="6">
                  <nz-form-item>
                    <nz-form-label>
                      <small>Dernière modification</small>
                    </nz-form-label>
                    <nz-form-control>
                      <nz-date-picker
                        nzBorderless
                        [nzFormat]="dateFormat"
                        formControlName="dateUpdate"
                      ></nz-date-picker>
                    </nz-form-control>
                  </nz-form-item>
                </nz-col>
              </nz-row>
              <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
                <nz-col [nzSpan]="(loading$ | async) ? 2 : 0">
                  <i nz-icon nzType="loading"></i>
                </nz-col>
                <nz-col [nzSpan]="(loading$ | async) ? 22 : 24">
                  <nz-form-item>
                    <nz-form-label [nzSpan]="24" nzFor="motif"
                      >Motif de sortie du projet
                    </nz-form-label>
                    <nz-form-control
                      nzErrorTip="Aucun motif de sortie renseigné"
                    >
                      <nz-select
                        nzShowSearch
                        nzBorderless
                        nzAllowClear="true"
                        formControlName="typeSorties"
                        [compareWith]="compareSortie"
                        nzPlaceHolder="Motif de sortie du projet..."
                        id="motif"
                      >
                        <nz-option-group
                          *ngFor="let group of utilitySortie$ | async"
                          [nzLabel]="group.type"
                        >
                          <nz-option
                            nzCustomContent="true"
                            *ngFor="let item of group.sortie"
                            [nzLabel]="item.motif"
                            [nzValue]="item"
                          >
                            <p
                              nz-popover
                              nzPopoverTitle="{{ item.motif }}"
                              nzPopoverContent="{{ item.situation }}"
                              nzPopoverPlacement="left"
                              [nzPopoverMouseEnterDelay]="popoverDelay"
                            >
                              {{ item.motif }}
                            </p>
                          </nz-option>
                        </nz-option-group>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </nz-col>
              </nz-row>
              <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
                <nz-col nzSpan="24">
                  <nz-form-item>
                    <nz-form-label
                      [nzSpan]="24"
                      nzFor="sortiProgrammeQualitatif"
                      >Note qualitative
                    </nz-form-label>
                    <nz-form-control>
                      <textarea
                        nz-input
                        nzBorderless
                        placeholder="Commentaire..."
                        formControlName="sortiProgrammeQualitatif"
                        [nzAutosize]="{ minRows: 6, maxRows: 6 }"
                        name="sortiProgrammeQualitatif"
                        type="text"
                        id="sortiProgrammeQualitatif"
                      ></textarea>
                    </nz-form-control>
                  </nz-form-item>
                </nz-col>
              </nz-row>
            </form>
          </nz-card>
        </div>
        <div *ngSwitchCase="'entite'">
          <nz-card class="operationInterventionElements">
            <form nz-form [formGroup]="interventionForm" nzLayout="vertical">
              <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
                <nz-col nzSpan="24">
                  <nz-form-item>
                    <nz-form-label nzRequired>
                      <small>Rôle</small>
                    </nz-form-label>
                    <nz-form-control>
                      <input
                        nz-input
                        formControlName="role"
                        nzBorderless
                        placeholder="Rôle"
                      />
                    </nz-form-control>
                  </nz-form-item>
                </nz-col>
              </nz-row>

              <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
                <nz-col nzSpan="24">
                  <nz-form-item>
                    <nz-form-control class="radioContainer">
                      <nz-radio-group
                        [(ngModel)]="radioValue"
                        (ngModelChange)="onSetRole($event)"
                        nzButtonStyle="solid"
                        [ngModelOptions]="{ standalone: true }"
                      >
                        <label [nzValue]="'financeur'" nz-radio
                          >Financeur</label
                        >
                        <label [nzValue]="'assistance'" nz-radio
                          >Assistance du projet</label
                        >
                        <label [nzValue]="'pilotage'" nz-radio
                          >Pilotage du projet</label
                        >
                        <label [nzValue]="'titulaire'" nz-radio
                          >Titulaire d'un marché</label
                        >
                        <label [nzValue]="'cotraitant'" nz-radio
                          >Co-traitant d'un marché</label
                        >
                        <label [nzValue]="'soustraitant'" nz-radio
                          >Sous-traitant d'un marché</label
                        >

                        <label [nzValue]="'support'" nz-radio
                          >Support du projet</label
                        >
                        <label [nzValue]="'prescripteur'" nz-radio
                          >Prescripteur du projet</label
                        >
                        <label [nzValue]="'parrainage'" nz-radio
                          >Parrainage</label
                        >
                        <label [nzValue]="'control'" nz-radio
                          >Contrôle du projet</label
                        >
                        <label [nzValue]="'autre'" nz-radio>Autre</label>
                      </nz-radio-group>
                    </nz-form-control>
                  </nz-form-item>
                </nz-col>
              </nz-row>
              <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
                <nz-col nzSpan="24">
                  <nz-form-item *ngIf="radioValue === 'autre'">
                    <nz-form-label nzRequired>Précision</nz-form-label>
                    <nz-form-control>
                      <input
                        nz-input
                        nzBorderless
                        formControlName="autrePrecision"
                        placeholder="Précisez le type d'intervention"
                      />
                    </nz-form-control>
                  </nz-form-item>

                  <ng-container *ngIf="radioValue === 'soustraitant'">
                    <nz-divider
                      nzOrientation="center"
                      nzType="horizontal"
                      nzText="Sous-traitance"
                    ></nz-divider>
                  </ng-container>
                </nz-col>
              </nz-row>
              <nz-space nzDirection="vertical" nzSize="small"></nz-space>
              <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
                <nz-col nzSpan="8">
                  <nz-form-item>
                    <nz-form-label>
                      <small>Date de démarrage</small>
                    </nz-form-label>
                    <nz-form-control>
                      <nz-date-picker
                        nzBorderless
                        [nzFormat]="dateFormat"
                        formControlName="dateStartIntervention"
                      ></nz-date-picker>
                    </nz-form-control>
                  </nz-form-item>
                </nz-col>
                <nz-col nzSpan="8">
                  <nz-form-item>
                    <nz-form-label>
                      <small>Date d'échéance</small>
                    </nz-form-label>
                    <nz-form-control>
                      <nz-date-picker
                        nzBorderless
                        [nzFormat]="dateFormat"
                        formControlName="dateEndIntervention"
                      ></nz-date-picker>
                    </nz-form-control>
                  </nz-form-item>
                </nz-col>
                <nz-col nzSpan="8">
                  <nz-form-item>
                    <nz-form-label>
                      <small>Dernière modification</small>
                    </nz-form-label>
                    <nz-form-control>
                      <nz-date-picker
                        nzBorderless
                        [nzFormat]="dateFormat"
                        formControlName="dateUpdate"
                      ></nz-date-picker>
                    </nz-form-control>
                  </nz-form-item>
                </nz-col>
              </nz-row>
              <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
                <nz-col [nzSpan]="24"> </nz-col>
              </nz-row>
              <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
                <nz-col nzSpan="24"> </nz-col>
              </nz-row>
            </form>
          </nz-card>
        </div>
        <div *ngSwitchCase="'contact'">
          <nz-card class="operationInterventionElements">
            <form nz-form [formGroup]="interventionForm" nzLayout="vertical">
              <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
                <nz-col nzSpan="24">
                  <nz-form-item>
                    <nz-form-label nzRequired>
                      <small>Rôle</small>
                    </nz-form-label>
                    <nz-form-control>
                      <input
                        nz-input
                        formControlName="role"
                        nzBorderless
                        placeholder="Rôle"
                      />
                    </nz-form-control>
                  </nz-form-item>
                </nz-col>
              </nz-row>

              <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
                <nz-col nzSpan="24">
                  <nz-form-item>
                    <nz-form-control class="radioContainer">
                      <nz-radio-group
                        [(ngModel)]="radioValue"
                        (ngModelChange)="onSetRole($event)"
                        nzButtonStyle="solid"
                        [ngModelOptions]="{ standalone: true }"
                      >
                        <label [nzValue]="'financeur'" nz-radio
                          >Financeur</label
                        >
                        <label [nzValue]="'assistance'" nz-radio
                          >Assistance du projet</label
                        >
                        <label [nzValue]="'pilotage'" nz-radio
                          >Pilotage du projet</label
                        >
                        <label [nzValue]="'titulaire'" nz-radio
                          >Titulaire d'un marché</label
                        >
                        <label [nzValue]="'cotraitant'" nz-radio
                          >Co-traitant d'un marché</label
                        >
                        <label [nzValue]="'soustraitant'" nz-radio
                          >Sous-traitant d'un marché</label
                        >

                        <label [nzValue]="'support'" nz-radio
                          >Support du projet</label
                        >
                        <label [nzValue]="'prescripteur'" nz-radio
                          >Prescripteur du projet</label
                        >
                        <label [nzValue]="'parrainage'" nz-radio
                          >Parrainage</label
                        >
                        <label [nzValue]="'control'" nz-radio
                          >Contrôle du projet</label
                        >
                        <label [nzValue]="'autre'" nz-radio>Autre</label>
                      </nz-radio-group>
                    </nz-form-control>
                  </nz-form-item>
                </nz-col>
              </nz-row>
              <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
                <nz-col nzSpan="24">
                  <nz-form-item *ngIf="radioValue === 'autre'">
                    <nz-form-label nzRequired>Précision</nz-form-label>
                    <nz-form-control>
                      <input
                        nz-input
                        nzBorderless
                        formControlName="autrePrecision"
                        placeholder="Précisez le type d'intervention"
                      />
                    </nz-form-control>
                  </nz-form-item>

                  <ng-container *ngIf="radioValue === 'soustraitant'">
                    <nz-divider
                      nzOrientation="center"
                      nzType="horizontal"
                      nzText="Sous-traitance"
                    ></nz-divider>
                  </ng-container>
                </nz-col>
              </nz-row>
              <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
                <nz-col [nzSpan]="12">
                  <nz-form-item>
                    <nz-form-label>
                      <small>Heures de bénévolat</small>
                    </nz-form-label>
                    <nz-form-control>
                      <nz-input-number
                        nzBorderless
                        [nzMin]="0"
                        formControlName="hoursBenevolat"
                      ></nz-input-number>
                    </nz-form-control>
                  </nz-form-item>
                </nz-col>
                <nz-col [nzSpan]="12">
                  <nz-form-item>
                    <nz-form-label>
                      <small>Durée d'intervention (en mois)</small>
                    </nz-form-label>
                    <nz-form-control>
                      <nz-input-number
                        nzBorderless
                        [nzMin]="0"
                        formControlName="durationIntervention"
                      ></nz-input-number>
                    </nz-form-control>
                  </nz-form-item>
                </nz-col>
              </nz-row>
              <nz-space nzDirection="vertical" nzSize="small"></nz-space>
              <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
                <nz-col nzSpan="8">
                  <nz-form-item>
                    <nz-form-label>
                      <small>Date de démarrage</small>
                    </nz-form-label>
                    <nz-form-control>
                      <nz-date-picker
                        nzBorderless
                        [nzFormat]="dateFormat"
                        formControlName="dateStartIntervention"
                      ></nz-date-picker>
                    </nz-form-control>
                  </nz-form-item>
                </nz-col>
                <nz-col nzSpan="8">
                  <nz-form-item>
                    <nz-form-label>
                      <small>Date d'échéance</small>
                    </nz-form-label>
                    <nz-form-control>
                      <nz-date-picker
                        nzBorderless
                        [nzFormat]="dateFormat"
                        formControlName="dateEndIntervention"
                      ></nz-date-picker>
                    </nz-form-control>
                  </nz-form-item>
                </nz-col>
                <nz-col nzSpan="8">
                  <nz-form-item>
                    <nz-form-label>
                      <small>Dernière modification</small>
                    </nz-form-label>
                    <nz-form-control>
                      <nz-date-picker
                        nzBorderless
                        [nzFormat]="dateFormat"
                        formControlName="dateUpdate"
                      ></nz-date-picker>
                    </nz-form-control>
                  </nz-form-item>
                </nz-col>
              </nz-row>
              <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
                <nz-col [nzSpan]="24"> </nz-col>
              </nz-row>
              <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
                <nz-col nzSpan="24"> </nz-col>
              </nz-row>
            </form>
          </nz-card>
        </div>
        <div *ngSwitchDefault></div>
      </div>
    </nz-tab>

    <nz-tab [nzTitle]="tabModules">
      <ng-template #tabModules>
        <app-tab-title
          [title]="'Modules'"
          [isActiv]="currentSelected === 1 ? true : false"
        ></app-tab-title>
      </ng-template>
      <nz-card
        [nzTitle]="operationInterventionModulesTitle"
        class="operationInterventionElements"
      ></nz-card>
      <ng-template #operationInterventionModulesTitle>
        <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
          <nz-col nzSpan="4"><mat-icon>dynamic_feed</mat-icon></nz-col>
          <nz-col nzSpan="12"><h5 nz-typography>Modules</h5></nz-col>
          <nz-col nzSpan="8">
            <nz-badge
              [nzCount]="operation?.intervention?.modules | size"
            ></nz-badge>
          </nz-col>
        </nz-row>
      </ng-template>
    </nz-tab>

    <nz-tab [nzTitle]="tabAction">
      <ng-template #tabAction>
        <app-tab-title
          [title]="'Actions'"
          [isActiv]="currentSelected === 2 ? true : false"
        ></app-tab-title>
      </ng-template>
      <nz-card
        [nzTitle]="operationInterventionActionsTitle"
        class="operationInterventionElements"
      >
        <ng-template #operationInterventionActionsTitle>
          <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
            <nz-col nzSpan="4"><mat-icon>dashboard</mat-icon></nz-col>

            <nz-col nzSpan="12"><h5 nz-typography>Actions</h5></nz-col>
            <nz-col nzSpan="8">
              <nz-badge
                [nzCount]="operation?.intervention?.actions | size"
              ></nz-badge>
            </nz-col>
          </nz-row>
        </ng-template>
      </nz-card>
    </nz-tab>

    <nz-tab [nzTitle]="tabMission">
      <ng-template #tabMission>
        <app-tab-title
          [title]="'Missions'"
          [isActiv]="currentSelected === 3 ? true : false"
        ></app-tab-title>
      </ng-template>
      <nz-card
        [nzTitle]="operationInterventionMissionsTitle"
        class="operationInterventionElements"
      >
        <ng-template #operationInterventionMissionsTitle>
          <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
            <nz-col nzSpan="4"><mat-icon>topic</mat-icon></nz-col>

            <nz-col nzSpan="12"><h5 nz-typography>Missions</h5></nz-col>
            <nz-col nzSpan="8">
              <nz-badge
                [nzCount]="operation?.intervention?.actions | size"
              ></nz-badge>
            </nz-col>
          </nz-row>
        </ng-template>
      </nz-card>
    </nz-tab>
  </nz-tabset>
</ng-container>
