import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';
import { OrganisationId } from 'src/app/components/organisation/store/organisation.model';
import { OrganisationState } from 'src/app/components/organisation/store/organisation.reducer';
import * as fromOrganisationAction from 'src/app/components/organisation/store/organisation.actions';
import * as fromOrganisationSelector from 'src/app/components/organisation/store/organisation.selectors';
import { Timestamp } from 'firebase/firestore';
import { UtilitiesState } from 'src/app/contents/components/utilities/store/utilities.reducer';
import * as fromUtilityAction from 'src/app/contents/components/utilities/store/utilities.actions';
import * as fromUtilitySelector from 'src/app/contents/components/utilities/store/utilities.selectors';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserAction from 'src/app/components/user/store/user.actions';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';
import { camelCase, capitalize } from 'lodash';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserFormComponent implements OnInit, OnChanges, OnDestroy {
  user$: Observable<any> = of(null);
  isAdd: boolean = true;
  organisations$: Observable<OrganisationId[] | any> = of(null);
  organisation$: Observable<OrganisationId[] | any> = of(null);
  civilities$: Observable<string[] | any> | any;

  acces: string = '';
  checkCard: string = '100px';
  organisationSelected: any = null;
  subscribe = new Subject();
  alertMessage: string =
    'Nous vous invitons à vérifier vos e-mails indésirables en cas de non-réception des identifiants.';

  //VARIABLES
  isAdmin: boolean = false;
  isManager: boolean = false;
  isMember: boolean = false;
  isDev: boolean = false;
  isCalculator: boolean = false;
  setNewAdresse: boolean = false;
  coordonnees$ = new BehaviorSubject<any>(null);
  adresse$ = new BehaviorSubject<any>(null);
  adresseComplement$ = new BehaviorSubject<any>(null);
  loading$: Observable<boolean> = of(false);
  utilityIdentity$: Observable<any> = of(null);

  @Input() currentUser = new EventEmitter<any>(false);
  @Input() item$ = new BehaviorSubject<any>(null);
  @Input() type: string = 'add';
  @Output() cancel = new EventEmitter<boolean>(false);
  @Output() add = new EventEmitter<any>(false);
  @Output() update = new EventEmitter<any>(false);
  @Output() delete = new EventEmitter<any>(false);
  @Output() activate = new EventEmitter<any>(false);
  @Output() disactivate = new EventEmitter<any>(false);

  //FORM
  userForm: UntypedFormGroup = this.fb.group({});
  civilite: UntypedFormControl = new UntypedFormControl();
  lastName: UntypedFormControl = new UntypedFormControl();
  avatar: UntypedFormControl = new UntypedFormControl();
  displayName: UntypedFormControl = new UntypedFormControl();
  firstName: UntypedFormControl = new UntypedFormControl();
  fonction: UntypedFormControl = new UntypedFormControl();
  service: UntypedFormControl = new UntypedFormControl();
  mobile: UntypedFormControl = new UntypedFormControl();
  phoneNumber: UntypedFormControl = new UntypedFormControl();
  fixe: UntypedFormControl = new UntypedFormControl();
  email: UntypedFormControl = new UntypedFormControl();
  fields: UntypedFormControl = new UntypedFormControl();
  complement: UntypedFormControl = new UntypedFormControl();
  organisation: UntypedFormControl = new UntypedFormControl();
  dateStart: UntypedFormControl = new UntypedFormControl();
  dateUpdate: UntypedFormControl = new UntypedFormControl();
  auths: UntypedFormControl = new UntypedFormControl();
  contrats: UntypedFormControl = new UntypedFormControl();
  evaluations: UntypedFormControl = new UntypedFormControl();
  conges: UntypedFormControl = new UntypedFormControl();
  rtt: UntypedFormControl = new UntypedFormControl();
  frais: UntypedFormControl = new UntypedFormControl();
  formations: UntypedFormControl = new UntypedFormControl();
  objectifs: UntypedFormControl = new UntypedFormControl();
  ficheDePoste: UntypedFormControl = new UntypedFormControl();
  accounting: UntypedFormControl = new UntypedFormControl();
  admin: UntypedFormControl = new UntypedFormControl();
  coordinator: UntypedFormControl = new UntypedFormControl();
  dev: UntypedFormControl = new UntypedFormControl();
  manager: UntypedFormControl = new UntypedFormControl();
  member: UntypedFormControl = new UntypedFormControl();
  owner: UntypedFormControl = new UntypedFormControl();
  organisationName: UntypedFormControl = new UntypedFormControl();
  organisationAPP: UntypedFormControl = new UntypedFormControl();
  organisationWeb: UntypedFormControl = new UntypedFormControl();
  organisationPhone: UntypedFormControl = new UntypedFormControl();
  organisationType: UntypedFormControl = new UntypedFormControl();
  organisationEmail: UntypedFormControl = new UntypedFormControl();
  si: UntypedFormControl = new UntypedFormControl();
  projetExtend: UntypedFormControl = new UntypedFormControl();
  calculator: UntypedFormControl = new UntypedFormControl();
  departementId: UntypedFormControl = new UntypedFormControl();
  participant: UntypedFormControl = new UntypedFormControl();
  partner: UntypedFormControl = new UntypedFormControl();
  affectationsDepartemental: UntypedFormControl = new UntypedFormControl();
  affectationsArray: UntypedFormControl = new UntypedFormControl();
  affectationsOperationnelles: UntypedFormControl = new UntypedFormControl();
  senders: UntypedFormControl = new UntypedFormControl();
  pass: UntypedFormControl = new UntypedFormControl();
  photo: UntypedFormControl = new UntypedFormControl();
  sexe: UntypedFormControl = new UntypedFormControl();
  disabled: UntypedFormControl = new UntypedFormControl();
  fileRef: UntypedFormControl = new UntypedFormControl();
  searchFields: UntypedFormControl = new UntypedFormControl();
  camelName: UntypedFormControl = new UntypedFormControl();
  letterLastName: UntypedFormControl = new UntypedFormControl();
  letterFirstName: UntypedFormControl = new UntypedFormControl();
  arrayLastName: UntypedFormControl = new UntypedFormControl();
  arrayFirstName: UntypedFormControl = new UntypedFormControl();
  organisationId: UntypedFormControl = new UntypedFormControl();
  organisationNom_raison_sociale: UntypedFormControl = new UntypedFormControl();
  organisationLicence: UntypedFormControl = new UntypedFormControl();
  organisationLogo: UntypedFormControl = new UntypedFormControl();
  organisationAdresse: UntypedFormControl = new UntypedFormControl();
  organisationSiret: UntypedFormControl = new UntypedFormControl();
  serviceId: UntypedFormControl = new UntypedFormControl();
  serviceTitle: UntypedFormControl = new UntypedFormControl();
  licenceMode: UntypedFormControl = new UntypedFormControl();
  licenceCreateAt: UntypedFormControl = new UntypedFormControl();
  licenceAdminId: UntypedFormControl = new UntypedFormControl();
  licenceAdminEmail: UntypedFormControl = new UntypedFormControl();
  licenceAdminPhone: UntypedFormControl = new UntypedFormControl();
  licenceClientId: UntypedFormControl = new UntypedFormControl();

  constructor(
    private fb: UntypedFormBuilder,
    private organisationStore: Store<OrganisationState>,
    private utilityStore: Store<UtilitiesState>,
    private userStore: Store<UserState>
  ) {}

  ngOnInit(): void {
    this.getUser();
    this.form();
    this.onGetOrganisations();
    this.getUtilitiesLoading();
  }
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {
    this.subscribe.next(false);
    this.subscribe.complete();
  }

  getUser(): void {
    this.user$ = this.userStore.select(fromUserSelector.user);
  }

  getCivilitiesUtilities(): void {
    this.civilities$ = this.userStore.select(fromUserSelector.civilities);
  }
  loadCivilitiesUtilities(): void {
    this.userStore.dispatch(fromUserAction.loadCivilities());
  }

  form(): void {
    this.getCivilitiesUtilities();
    this.loadCivilitiesUtilities();
    this.formSet();
    this.formSetFromCurrentUser();
    this.setGender();
  }
  formSetFromCurrentUser(): void {
    this.user$.pipe(takeUntil(this.subscribe)).subscribe((user) => {
      if (!user) {
        this.formInitUnauthorized();
        this.formCreate();
        return;
      }
      this.formInitAuthorized();
      this.formCreate();
    });
  }
  formInitAuthorized(): void {
    this.lastName = new UntypedFormControl('', Validators.required);
    this.civilite = new UntypedFormControl('', Validators.required);
    this.firstName = new UntypedFormControl('', Validators.required);
    this.fonction = new UntypedFormControl('', Validators.required);
    this.service = new UntypedFormControl('');
    this.mobile = new UntypedFormControl('', Validators.required);
    this.email = new UntypedFormControl('', Validators.required);
    this.phoneNumber = new UntypedFormControl('', Validators.required);
    this.fixe = new UntypedFormControl();
    this.disabled = new UntypedFormControl(false);
    this.fields = new UntypedFormControl('');
    this.complement = new UntypedFormControl('');
    this.avatar = new UntypedFormControl('');
    this.displayName = new UntypedFormControl('');

    this.organisation = new UntypedFormControl('');
    this.dateStart = new UntypedFormControl(Timestamp.now());
    this.dateUpdate = new UntypedFormControl(Timestamp.now());
    this.auths = new UntypedFormControl([]);
    this.contrats = new UntypedFormControl([]);
    this.evaluations = new UntypedFormControl([]);
    this.conges = new UntypedFormControl([]);
    this.rtt = new UntypedFormControl([]);
    this.frais = new UntypedFormControl([]);
    this.formations = new UntypedFormControl([]);
    this.objectifs = new UntypedFormControl([]);
    this.ficheDePoste = new UntypedFormControl([]);
    this.accounting = new UntypedFormControl(false);
    this.admin = new UntypedFormControl(false, Validators.required);
    this.manager = new UntypedFormControl(false, Validators.required);
    this.dev = new UntypedFormControl(false, Validators.required);
    this.member = new UntypedFormControl(false, Validators.required);
    this.coordinator = new UntypedFormControl(false);
    this.owner = new UntypedFormControl(false);
    this.participant = new UntypedFormControl(false);
    this.organisationAPP = new UntypedFormControl('');
    this.organisationLogo = new UntypedFormControl('');
    this.organisationAdresse = new UntypedFormControl('');
    this.organisationWeb = new UntypedFormControl('');
    this.organisationPhone = new UntypedFormControl('');
    this.organisationType = new UntypedFormControl('');
    this.organisationEmail = new UntypedFormControl('');
    this.si = new UntypedFormControl(false);
    this.projetExtend = new UntypedFormControl(false);
    this.calculator = new UntypedFormControl(false);
    this.departementId = new UntypedFormControl(false);

    this.partner = new UntypedFormControl(false);
    this.affectationsDepartemental = new UntypedFormControl([]);
    this.affectationsArray = new UntypedFormControl([]);
    this.affectationsOperationnelles = new UntypedFormControl([]);
    this.senders = new UntypedFormControl([]);
    this.photo = new UntypedFormControl('');
    this.fileRef = new UntypedFormControl('');
    this.sexe = new UntypedFormControl('');
    this.searchFields = new UntypedFormControl('');
    this.camelName = new UntypedFormControl('');
    this.letterLastName = new UntypedFormControl();
    this.letterFirstName = new UntypedFormControl();
    this.arrayLastName = new UntypedFormControl();
    this.arrayFirstName = new UntypedFormControl();
    this.serviceId = new UntypedFormControl();
    this.serviceTitle = new UntypedFormControl();

    this.licenceMode = new UntypedFormControl();
    this.licenceCreateAt = new UntypedFormControl();
    this.licenceAdminId = new UntypedFormControl();
    this.licenceAdminEmail = new UntypedFormControl();
    this.licenceAdminPhone = new UntypedFormControl();
    this.licenceClientId = new UntypedFormControl();
  }
  formInitUnauthorized(): void {
    this.lastName = new UntypedFormControl('', Validators.required);
    this.civilite = new UntypedFormControl('', Validators.required);
    this.firstName = new UntypedFormControl('', Validators.required);
    this.fonction = new UntypedFormControl('', Validators.required);
    this.service = new UntypedFormControl('');
    this.mobile = new UntypedFormControl('', Validators.required);
    this.email = new UntypedFormControl('', Validators.required);
    this.phoneNumber = new UntypedFormControl('', Validators.required);
    this.fixe = new UntypedFormControl();
    this.disabled = new UntypedFormControl(false);
    this.fields = new UntypedFormControl('');
    this.complement = new UntypedFormControl('');
    this.avatar = new UntypedFormControl('');
    this.displayName = new UntypedFormControl('');

    this.organisation = new UntypedFormControl('');
    this.dateStart = new UntypedFormControl(Timestamp.now());
    this.dateUpdate = new UntypedFormControl(Timestamp.now());
    this.auths = new UntypedFormControl([]);
    this.contrats = new UntypedFormControl([]);
    this.evaluations = new UntypedFormControl([]);
    this.conges = new UntypedFormControl([]);
    this.rtt = new UntypedFormControl([]);
    this.frais = new UntypedFormControl([]);
    this.formations = new UntypedFormControl([]);
    this.objectifs = new UntypedFormControl([]);
    this.ficheDePoste = new UntypedFormControl([]);
    this.accounting = new UntypedFormControl(false);
    this.admin = new UntypedFormControl(true);
    this.manager = new UntypedFormControl(false);
    this.dev = new UntypedFormControl(false);
    this.member = new UntypedFormControl(false);
    this.coordinator = new UntypedFormControl(false);
    this.owner = new UntypedFormControl(false);
    this.participant = new UntypedFormControl(false);
    this.organisationAPP = new UntypedFormControl('');
    this.organisationLogo = new UntypedFormControl('');
    this.organisationAdresse = new UntypedFormControl('');
    this.organisationWeb = new UntypedFormControl('');
    this.organisationPhone = new UntypedFormControl('');
    this.organisationType = new UntypedFormControl('');
    this.organisationEmail = new UntypedFormControl('');
    this.si = new UntypedFormControl(false);
    this.projetExtend = new UntypedFormControl(false);
    this.calculator = new UntypedFormControl(false);
    this.departementId = new UntypedFormControl(false);

    this.partner = new UntypedFormControl(false);
    this.affectationsDepartemental = new UntypedFormControl([]);
    this.affectationsArray = new UntypedFormControl([]);
    this.affectationsOperationnelles = new UntypedFormControl([]);
    this.senders = new UntypedFormControl([]);
    this.photo = new UntypedFormControl('');
    this.fileRef = new UntypedFormControl('');
    this.sexe = new UntypedFormControl('');
    this.searchFields = new UntypedFormControl('');
    this.camelName = new UntypedFormControl('');
    this.letterLastName = new UntypedFormControl();
    this.letterFirstName = new UntypedFormControl();
    this.arrayLastName = new UntypedFormControl();
    this.arrayFirstName = new UntypedFormControl();
    this.serviceId = new UntypedFormControl();
    this.serviceTitle = new UntypedFormControl();
    this.licenceMode = new UntypedFormControl();
    this.licenceCreateAt = new UntypedFormControl();
    this.licenceAdminId = new UntypedFormControl();
    this.licenceAdminEmail = new UntypedFormControl();
    this.licenceAdminPhone = new UntypedFormControl();
    this.licenceClientId = new UntypedFormControl();
  }

  formCreate(): void {
    this.userForm = this.fb.group({
      civilite: this.civilite,
      lastName: this.lastName,
      firstName: this.firstName,
      avatar: this.avatar,
      displayName: this.displayName,
      fonction: this.fonction,
      service: new UntypedFormGroup({
        id: this.serviceId,
        title: this.serviceTitle,
      }),
      photo: this.photo,
      fileRef: this.fileRef,
      sexe: this.sexe,
      disabled: this.disabled,
      coordonnees: this.fb.group({
        mobile: this.mobile,
        phoneNumber: this.phoneNumber,
        email: this.email,
        fixe: this.fixe,
      }),
      adresse: this.fb.group({
        adresse: this.fields,
        complement: this.complement,
      }),
      organisation: new UntypedFormGroup({
        id: this.organisationId,
        nom_raison_sociale: this.organisationNom_raison_sociale,
        logo: this.organisationLogo,
        licence: this.organisationLicence,
        adresse: this.organisationAdresse,
      }),
      dateStart: this.dateStart,
      dateUpdate: this.dateUpdate,
      auths: this.auths,
      claims: this.fb.group({
        admin: this.admin,
        dev: this.dev,
        manager: this.manager,
        member: this.member,
        organisationId: this.organisationId,
        si: this.si,
        calculator: this.calculator,
      }),
      senders: this.senders,
      searchFields: this.searchFields,
      camelName: this.camelName,
      letterLastName: this.letterLastName,
      letterFirstName: this.letterFirstName,
      arrayLastName: this.arrayLastName,
      arrayFirstName: this.arrayFirstName,
      // licence: new FormGroup({
      //   mode: this.licenceMode,
      //   createAt: this.licenceCreateAt,
      //   adminId: this.licenceAdminId,
      //   adminEmail: this.licenceAdminEmail,
      //   adminPhone: this.licenceAdminPhone,
      //   clientId: this.licenceClientId,
      // }),
    });
  }
  formSet(): void {
    this.item$.pipe(takeUntil(this.subscribe)).subscribe((user: any): any => {
      if (!user) {
        this.userForm.reset();
        this.coordonnees$.next(null);
        this.adresseComplement$.next(null);

        this.isAdmin = false;
        this.isManager = false;
        this.isMember = false;
        this.isDev = false;
        this.isCalculator = false;

        return null;
      }

      const { coordonnees, claims } = user;
      this.coordonnees$.next(coordonnees);
      const adresse = user?.adresse ? user.adresse.fields : null;
      const adresseComplement =
        user?.adresse && user?.adresse?.complement
          ? user?.adresse?.complement
          : null;

      this.adresse$.next(adresse);
      this.adresseComplement$.next(adresseComplement);

      this.isAdmin = claims.admin ? true : false;
      this.isManager = claims.manager ? true : false;
      this.isMember = claims.member ? true : false;
      this.isDev = claims.dev ? true : false;

      const first: string = user.lastName
        ? user.lastName.split('')[0].toUpperCase()
        : '.';
      const last: string = user.firstName
        ? user.firstName.split('')[0].toUpperCase()
        : '.';

      const detailDisplayName = `${user.civilite}. ${user.lastName} ${user.firstName}`;
      const avatar = user?.avatar ? user.avatar : `${first}${last}`;
      const displayName = user?.displayName
        ? user.displayName
        : detailDisplayName;

      this.userForm.patchValue({
        civilite: user.civilite,
        lastName: user.lastName,
        firstName: user.firstName,
        fonction: user.fonction,
        avatar: avatar,
        displayName: displayName,
        service: user?.service ? user?.service : null,
        photo: user?.photo ? user?.photo : null,
        fileRef: user?.fileRef ? user.fileRef : null,
        sexe: user?.sexe ? user.sexe : null,
        coordonnees: {
          mobile: user.coordonnees.mobile,
          phoneNumber: user?.coordonnees?.phoneNumber
            ? user.coordonnees.phoneNumber
            : null,
          email: user.coordonnees.email,
          fixe: user.coordonnees?.fixe ? user.coordonnees.fixe : null,
        },
        adresse: {
          fields: user?.adresse?.fields ? user.adresse.fields : null,
          complement: user?.adresse?.complement
            ? user.adresse.complement
            : null,
        },
        organisation: user.organisation,
        dateStart: user?.dateStart ? user.dateStart : Timestamp.now(),
        dateUpdate: Timestamp.now(),
        auths: user?.auths ? user.auths : null,
        claims: {
          admin: user.claims.admin,
          manager: user.claims.manager,
          member: user.claims.member,
          organisationId: user.claims.organisationId,
          calculator: user.claims.calculator,
        },
        disabled: user?.disabled ? user.disabled : false,

        senders: user?.senders ? user.senders : null,
      });
    });
  }
  setGender(): void {
    this.userForm.get('civilite')?.valueChanges.subscribe((val) => {
      if (!val) {
        return;
      } else {
        if (val === 'M') {
          this.userForm.patchValue({ sexe: 'Masculin' });
        } else if (val === 'Mme') {
          this.userForm.patchValue({ sexe: 'Féminin' });
        } else {
          this.userForm.patchValue({ sexe: 'Autre' });
        }
      }
    });
  }

  getUtilitiesLoading(): void {
    this.loading$ = this.utilityStore.select(fromUtilitySelector.loading);
  }

  getUtilitiesIdentity(): void {
    this.utilityIdentity$ = this.utilityStore.select(
      fromUtilitySelector.participantIdentity
    );
  }

  loadUtilityIdentity(): void {
    this.utilityStore.dispatch(
      fromUtilityAction.loadParticipantUtilitiesIdentity()
    );
  }

  setUserRole(event: boolean, type: string): void {
    if (type === 'admin') {
      if (event) {
        this.userForm.patchValue({
          claims: {
            admin: event,
            manager: false,
            member: false,
            dev: false,
          },
        });
      }
    }

    if (type === 'manager') {
      if (event) {
        this.userForm.patchValue({
          claims: {
            admin: false,
            manager: event,
            member: false,
            dev: false,
          },
        });
      }
    }

    if (type === 'member') {
      if (event) {
        this.userForm.patchValue({
          claims: {
            admin: false,
            manager: false,
            member: event,
            dev: false,
          },
        });
      }
    }

    if (type === 'dev') {
      if (event) {
        this.userForm.patchValue({
          claims: {
            admin: false,
            manager: false,
            member: false,
            dev: event,
          },
        });
      }
    }
  }

  onSelectOrganisation(organisation: any): void {
    if (!organisation) {
      this.organisationSelected = null;
      this.organisation$ = of(null);
      this.userForm.patchValue({
        organisation: null,
      });
    }

    this.organisationSelected = organisation;
    this.organisation$ = of(organisation);

    this.userForm.patchValue({
      organisation: organisation,
    });
  }

  onSetCoodonneesFixe(event: any): void {
    if (!event) {
      this.userForm.patchValue({
        coordonnees: {
          fixe: null,
        },
      });
    }
    this.userForm.patchValue({
      coordonnees: {
        fixe: event,
      },
    });
  }

  onSetAdresse(event: any) {
    if (event) {
      this.userForm.patchValue({
        adresse: {
          adresse: event,
        },
      });
    }
  }

  onSetAdresseComplement(event: any) {
    if (event) {
      this.userForm.patchValue({
        adresse: {
          complement: event,
        },
      });
    }
  }

  onSetCoordonneesEmail(event: any) {
    if (!event) {
      this.userForm.patchValue({
        coordonnees: {
          email: null,
        },
      });
    }

    this.userForm.patchValue({
      coordonnees: {
        email: event,
      },
    });
  }

  onSetCoordonneesPhoneNumber(event: any) {
    if (!event) {
      this.userForm.patchValue({
        coordonnees: {
          phoneNumber: null,
        },
      });
    }

    this.userForm.patchValue({
      coordonnees: {
        phoneNumber: event,
      },
    });
  }

  onSetCoordonneesMobile(event: any) {
    if (!event) {
      this.userForm.patchValue({
        coordonnees: {
          mobile: null,
        },
      });
    }
    this.userForm.patchValue({
      coordonnees: {
        mobile: event,
      },
    });
  }

  changeAdresse__USER(): void {
    this.setNewAdresse = true;
  }

  onGetOrganisations(): void {
    this.organisations$ = this.organisationStore.select(
      fromOrganisationSelector.organisations
    );
  }

  onLoadOrganisations(user: any): void {
    if (!user || !user?.claims?.dev) {
      this.organisation$ = this.organisationStore.select(
        fromOrganisationSelector.organisation
      );
    }

    this.organisationStore.dispatch(fromOrganisationAction.loadOrganisations());
  }

  onAdd(): void {
    if (!this.userForm.valid) return;

    const formValue = this.userForm.value;

    const first: string = formValue.lastName
      ? formValue.lastName.split('')[0].toUpperCase()
      : '.';
    const last: string = formValue.firstName
      ? formValue.firstName.split('')[0].toUpperCase()
      : '.';
    const avatar = `${first}${last}`;
    const displayName = `${capitalize(formValue.lastName)} ${capitalize(
      formValue.firstName
    )}`;
    const camelName: string = camelCase(
      `${formValue.firstName} ${formValue.lastName}`
    );
    const letterLastName = this.capitalizeFirstLetter(formValue.lastName);
    const letterFirstName = this.capitalizeFirstLetter(formValue.firstName);
    const arrayLastName = this.createKeywords(formValue.lastName);
    const arrayFirstName = this.createKeywords(formValue.firstName);
    const searchFields = [...arrayLastName, ...arrayFirstName];

    const user = {
      ...formValue,
      lastName: formValue.lastName.toLowerCase(),
      firstName: capitalize(formValue.firstName),
      camelName: camelName,
      avatar,
      displayName,
      letterLastName,
      letterFirstName,
      arrayLastName,
      arrayFirstName,
      searchFields,
    };

    this.add.emit(user);

    this.userForm.reset();
    this.coordonnees$.next(null);
    this.isAdmin = false;
    this.isManager = false;
    this.isMember = false;
    this.isDev = false;
    this.setNewAdresse = false;
  }

  onUpdate(event: any, userDetail: any): void {
    event.preventDefault();
    event.stopPropagation();

    if (!this.userForm.valid) return;

    const formValue = this.userForm.value;
    const first: string = formValue.lastName
      ? formValue.lastName.split('')[0].toUpperCase()
      : '.';
    const last: string = formValue.firstName
      ? formValue.firstName.split('')[0].toUpperCase()
      : '.';
    const avatar = `${first}${last}`;
    const displayName = `${formValue.civilite}. ${formValue.lastName} ${formValue.firstName}`;

    delete formValue.pass;
    const user = { ...userDetail, ...formValue, avatar, displayName };

    this.update.emit(user);

    this.onCancel();
    this.userForm.reset();
    this.isAdmin = false;
    this.isManager = false;
    this.isMember = false;
    this.isDev = false;
    this.setNewAdresse = false;
    this.coordonnees$.next(null);
  }

  onCancel(): void {
    this.cancel.emit(true);
  }
  onDelete(user: any): void {
    if (!user) return;
    this.delete.emit(user);
  }
  onActivate(user: any): void {
    if (!user) return;
  }
  onDisactivate(user: any): void {
    if (!user) return;
  }

  capitalizeFirstLetter = (str: string): any => {
    return str.charAt(0).toUpperCase();
  };

  createKeywords = (name: any): any => {
    if (name) {
      const arrName: any = [];
      let curName = '';
      name.split('').forEach((letter: any) => {
        curName += letter;
        arrName.push(curName);
      });
      return arrName;
    }
  };
}
