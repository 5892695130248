import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-aids-form',
  templateUrl: './aids-form.component.html',
  styleUrls: ['./aids-form.component.scss'],
})
export class AidsFormComponent implements OnInit {
  @Output() cancel = new EventEmitter<any>(false);
  @Output() add = new EventEmitter<any>(false);

  aidForm: UntypedFormGroup = this.fb.group({});
  name: UntypedFormControl = new UntypedFormControl('');
  what: UntypedFormControl = new UntypedFormControl('');
  short_description: UntypedFormControl = new UntypedFormControl('');
  how_much: UntypedFormControl = new UntypedFormControl('');
  additionnal_conditions: UntypedFormControl = new UntypedFormControl('');
  how_and_when: UntypedFormControl = new UntypedFormControl('');
  slug: UntypedFormControl = new UntypedFormControl('');
  limitations: UntypedFormControl = new UntypedFormControl('');

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.form();
  }

  form(): void {
    this.formInit();
    this.fromCreate();
  }

  formInit(): void {
    this.name = new UntypedFormControl('', Validators.required);
    this.what = new UntypedFormControl('', Validators.required);
    this.short_description = new UntypedFormControl('');
    this.how_much = new UntypedFormControl('', Validators.required);
    this.additionnal_conditions = new UntypedFormControl('');
    this.how_and_when = new UntypedFormControl('', Validators.required);
    this.slug = new UntypedFormControl('');
    this.limitations = new UntypedFormControl('', Validators.required);
  }
  fromCreate(): void {
    this.aidForm = this.fb.group({
      name: this.name,
      what: this.what,
      short_description: this.short_description,
      how_much: this.how_much,
      additionnal_conditions: this.additionnal_conditions,
      how_and_when: this.how_and_when,
      slug: this.slug,
      limitations: this.limitations,
    });
  }

  onCancel(): void {
    this.cancel.emit(true);
  }
  onAdd(): void {
    if (!this.aidForm.valid) return;
    const formValues = this.aidForm.value;
    this.add.emit(formValues);
  }
}
