<div class="overflow-hidden">
  <app-progress-loading *ngIf="loading$ | async"></app-progress-loading>
  <app-candidature-item
    *ngIf="candidat$ | async as candidat"
    [view$]="currentView$"
    [candidat$]="candidat$"
    [notesTpl]="candidatNotes"
    [documentTpl]="candidatDocuments"
    [tabIndex]="0"
    (tabChanges)="onTabChanges($event, fiche, candidat)"
    (update)="onUpdate($event, fiche)"
  ></app-candidature-item>
  <app-no-result
    *ngIf="!(candidat$ | async)"
    [isButton]="false"
    [description]="description"
    [notitle]="notitle"
  ></app-no-result>
</div>

<ng-template #candidatNotes>
  <app-operation-fiche-candidat-notes
    [fiche]="fiche"
    [candidat]="candidat$ | async"
    (viewChanges)="onViewChanges($event)"
  ></app-operation-fiche-candidat-notes>
</ng-template>
<ng-template #candidatDocuments>
  <app-operation-fiche-candidat-documents
    [fiche]="fiche"
    [candidat]="candidat$ | async"
  ></app-operation-fiche-candidat-documents>
</ng-template>
