import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ParticipantDocument } from './participant-document.model';
import * as ParticipantDocumentActions from './participant-document.actions';

export const participantDocumentsFeatureKey = 'participantDocuments';

export interface ParticipantDocumentState
  extends EntityState<ParticipantDocument> {
  loading: boolean;
  document: any;
  error: any;
}

export const adapter: EntityAdapter<ParticipantDocument> =
  createEntityAdapter<ParticipantDocument>();

export const initialState: ParticipantDocumentState = adapter.getInitialState({
  loading: false,
  document: undefined,
  error: undefined,
});

export const reducer = createReducer(
  initialState,

  //LOAD ALL
  on(ParticipantDocumentActions.loadParticipantDocuments, (state, action) => {
    return {
      ...state,
      error: undefined,
      loading: true,
    };
  }),
  on(
    ParticipantDocumentActions.loadParticipantDocumentsSuccess,
    (state, action) => adapter.setAll(action.documents, state)
  ),
  on(
    ParticipantDocumentActions.loadParticipantDocumentsSuccess,
    (state, action) => {
      return {
        ...state,
        error: undefined,
        loading: false,
      };
    }
  ),
  on(
    ParticipantDocumentActions.loadParticipantDocumentsFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
  ),

  //LOAD ONE
  on(ParticipantDocumentActions.loadParticipantDocument, (state, action) => {
    return {
      ...state,
      error: undefined,
      loading: true,
    };
  }),
  on(
    ParticipantDocumentActions.loadParticipantDocumentSuccess,
    (state, action) => {
      return {
        ...state,
        document: action.document,
      };
    }
  ),
  on(
    ParticipantDocumentActions.loadParticipantDocumentSuccess,
    (state, action) => {
      return {
        ...state,
        error: undefined,
        loading: false,
      };
    }
  ),
  on(
    ParticipantDocumentActions.loadParticipantDocumentFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
  ),

  //ADD MANY
  on(ParticipantDocumentActions.addParticipantDocuments, (state, action) => {
    return {
      ...state,
      error: undefined,
    };
  }),
  on(
    ParticipantDocumentActions.addParticipantDocumentsSuccess,
    (state, action) => adapter.addOne(action.document, state)
  ),

  on(
    ParticipantDocumentActions.addParticipantDocumentsFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
  ),

  //UPDATE ONE

  on(ParticipantDocumentActions.updateParticipantDocument, (state, action) =>
    adapter.updateOne(action.document, state)
  ),

  on(
    ParticipantDocumentActions.updateParticipantDocumentFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
  ),
  //DELETE ONE
  on(ParticipantDocumentActions.deleteParticipantDocument, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(
    ParticipantDocumentActions.deleteParticipantDocumentFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
  ),
  // on(ParticipantDocumentActions.deleteParticipantDocumentSuccess,
  //   (state, action) => adapter.upsertMany(action.success, state)
  // ),
  // on(ParticipantDocumentActions.deleteParticipantDocumentSuccess,
  //   (state, action) => adapter.upsertMany(action.documents, state)
  // ),

  //DELETE ALL
  on(ParticipantDocumentActions.deleteParticipantDocuments, (state, action) => {
    return {
      ...state,
      error: undefined,
      loading: true,
    };
  }),
  on(ParticipantDocumentActions.deleteParticipantDocuments, (state, action) =>
    adapter.removeAll(state)
  ),
  on(
    ParticipantDocumentActions.deleteParticipantDocumentsSuccess,
    (state, action) => {
      return {
        ...state,
        error: undefined,
        loading: false,
      };
    }
  ),
  on(
    ParticipantDocumentActions.deleteParticipantDocumentsFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
  ),

  //CLEAR
  on(ParticipantDocumentActions.clearParticipantDocuments, (state) =>
    adapter.removeAll(state)
  )
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
