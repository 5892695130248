import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { EntiteSMALLid } from '../../store/entite.model';
import { EntiteState } from '../../store/entite.reducer';
import * as fromEntiteAction from '../../store/entite.actions';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserAction from 'src/app/components/user/store/user.actions';

@Component({
  selector: 'app-entite-card',
  templateUrl: './entite-card.component.html',
  styleUrls: ['./entite-card.component.scss'],
})
export class EntiteCardComponent implements OnInit {
  default: string = '#bfbfbf';

  @Input() entite: EntiteSMALLid | any;
  @Input() favoris: any = [];
  constructor(
    private entiteStore: Store<EntiteState>,
    private userStore: Store<UserState>
  ) {}

  ngOnInit(): void {}

  onDelete(id: string): void {
    if (!id) return;
    this.entiteStore.dispatch(fromEntiteAction.deleteEntite({ id }));
  }

  onAddToFavori(entite: EntiteSMALLid): void {
    if (!entite) return;
    const nom_raison_sociale: string = entite.etablissement.denomination;
    const id: string = entite.id;
    this.userStore.dispatch(
      fromUserAction.addUserFavorisEntites({ id, nom_raison_sociale })
    );
  }
  onRemoveToFavori(entite: EntiteSMALLid): void {
    if (!entite) return;
    const nom_raison_sociale: string = entite.etablissement.denomination;
    const id: string = entite.id;
    this.userStore.dispatch(
      fromUserAction.removeUserFavorisEntites({ id, nom_raison_sociale })
    );
  }

  onExport(entite: EntiteSMALLid): void {}

  onCancel(): void {
    return;
  }
}
