import { SharedModule } from 'src/app/shared/shared.module';
import { ContentsModule } from './../../contents/contents.module';
import { OperationNoteParticipantService } from './../../service/operation-note-participant.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromOperationNoteParticipant from './store/operation-note-participant.reducer';
import { EffectsModule } from '@ngrx/effects';
import { OperationNoteParticipantEffects } from './store/operation-note-participant.effects';
import { OperationNoteParticipantListComponent } from './components/operation-note-participant-list/operation-note-participant-list.component';
import { OperationNoteParticipantItemComponent } from './components/operation-note-participant-item/operation-note-participant-item.component';
import { OperationNoteParticipantFormComponent } from './components/operation-note-participant-form/operation-note-participant-form.component';
import { OperationNoteParticipantTableComponent } from './components/operation-note-participant-table/operation-note-participant-table.component';
import { NoResultModule } from 'src/app/contents/components/no-result/no-result.module';
import { CoordonneesModule } from 'src/app/contents/components/coordonnees/coordonnees.module';
import { CommunesFranceModule } from 'src/app/contents/components/communes-france/communes-france.module';
import { ButtonsModule } from 'src/app/contents/components/buttons/buttons.module';
import { HeaderModule } from 'src/app/contents/components/header-title/header.module';

@NgModule({
  declarations: [
    OperationNoteParticipantListComponent,
    OperationNoteParticipantItemComponent,
    OperationNoteParticipantFormComponent,
    OperationNoteParticipantTableComponent,
  ],
  imports: [
    CommonModule,
    ContentsModule,
    SharedModule,
    NoResultModule,
    CoordonneesModule,
    HeaderModule,
    CommunesFranceModule,
    ButtonsModule,
    StoreModule.forFeature(
      fromOperationNoteParticipant.operationNoteParticipantsFeatureKey,
      fromOperationNoteParticipant.reducer
    ),
    EffectsModule.forFeature([OperationNoteParticipantEffects]),
  ],
  exports: [
    OperationNoteParticipantListComponent,
    OperationNoteParticipantItemComponent,
    OperationNoteParticipantFormComponent,
    OperationNoteParticipantTableComponent,
  ],
  providers: [OperationNoteParticipantService],
})
export class OperationNoteParticipantModule {}
