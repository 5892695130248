import {
  OperationTask,
  OperationTaskId,
  OperationTaskText,
} from './operation-task.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  OperationTaskState,
  operationTasksFeatureKey,
  selectAll,
} from './operation-task.reducer';
import { toPairs } from 'lodash';
import {
  fromUnixTime,
  isThisMonth,
  isThisWeek,
  isThisYear,
  isToday,
} from 'date-fns';

export const selectOpearationTasksState =
  createFeatureSelector<OperationTaskState>(operationTasksFeatureKey);

export const tasks = createSelector(selectOpearationTasksState, selectAll);

export const filters = createSelector(
  selectOpearationTasksState,
  (state: OperationTaskState) => state.filters
);

export const loading = createSelector(
  selectOpearationTasksState,
  (state: OperationTaskState) => state.loading
);

export const tasksCurrents = createSelector(
  selectOpearationTasksState,
  (state: OperationTaskState) => {
    const todosTopairs = toPairs(state.entities);
    const todosflat: OperationTaskId[] | any = todosTopairs.map(
      (item) => item[1]
    );

    const todos: OperationTask[] = todosflat.filter(
      (item: any) => !item.completed && !item.inprogress && !item.verifed
    );

    const tasksInProgress = todos.filter(
      (el: OperationTask) =>
        el.texts.filter((i: OperationTaskText) => i.status).length === 0
    );

    return tasksInProgress;
  }
);

export const tasksInProgress = createSelector(
  selectOpearationTasksState,
  (state: OperationTaskState) => {
    const todosTopairs = toPairs(state.entities);
    const todosflat: OperationTaskId[] | any = todosTopairs.map(
      (item) => item[1]
    );

    const todos: OperationTask[] = todosflat.filter(
      (item: any) => !item.completed && !item.verifed
    );

    const tasksInProgress = todos.filter(
      (el: OperationTask) =>
        el.texts.filter((i: OperationTaskText) => i.status).length > 0 ||
        el.inprogress
    );

    return tasksInProgress;
  }
);

export const tasksVerifed = createSelector(
  selectOpearationTasksState,
  (state: OperationTaskState) => {
    const todosTopairs = toPairs(state.entities);
    const todosflat: OperationTaskId[] | any = todosTopairs.map(
      (item) => item[1]
    );

    const todos = todosflat.filter(
      (item: any) =>
        (!item.completed && item.inprogress && item.verifed) ||
        (!item.completed && !item.inprogress && item.verifed)
    );
    return todos;
  }
);

export const tasksDoned = createSelector(
  selectOpearationTasksState,
  (state: OperationTaskState) => {
    const todosTopairs = toPairs(state.entities);
    const todosflat: OperationTaskId[] | any = todosTopairs.map(
      (item) => item[1]
    );

    const todos = todosflat.filter((item: any) => item.completed);
    return todos;
  }
);

//TODAY TASKS
export const tasksToday = createSelector(
  selectOpearationTasksState,
  (state: OperationTaskState) => {
    const todosTopairs = toPairs(state.entities);
    const todosflat: OperationTaskId[] | any = todosTopairs.map(
      (item) => item[1]
    );

    const todos = todosflat.filter(
      (item: any) =>
        !item.completed &&
        !item.tested &&
        !item.verifed &&
        isToday(fromUnixTime(item.dateStart['seconds']))
    );
    return todos;
  }
);

//WEEK TASKS
export const tasksOfWeek = createSelector(
  selectOpearationTasksState,
  (state: OperationTaskState) => {
    const todosTopairs = toPairs(state.entities);
    const todosflat: OperationTaskId[] | any = todosTopairs.map(
      (item) => item[1]
    );

    const todos = todosflat.filter(
      (item: any) =>
        !item.completed &&
        !item.tested &&
        !item.verifed &&
        isThisWeek(fromUnixTime(item.dateStart['seconds']))
    );
    return todos;
  }
);

//MONTH TASKS
export const tasksOfMonth = createSelector(
  selectOpearationTasksState,
  (state: OperationTaskState) => {
    const todosTopairs = toPairs(state.entities);
    const todosflat: OperationTaskId[] | any = todosTopairs.map(
      (item) => item[1]
    );

    const todos = todosflat.filter(
      (item: any) =>
        !item.completed &&
        !item.tested &&
        !item.verifed &&
        isThisMonth(fromUnixTime(item.dateStart['seconds']))
    );
    return todos;
  }
);

//YEARS TAKS
export const tasksOfYear = createSelector(
  selectOpearationTasksState,
  (state: OperationTaskState) => {
    const todosTopairs = toPairs(state.entities);
    const todosflat: OperationTaskId[] | any = todosTopairs.map(
      (item) => item[1]
    );

    const todos = todosflat.filter(
      (item: any) =>
        !item.completed &&
        !item.tested &&
        !item.verifed &&
        isThisYear(fromUnixTime(item.dateStart['seconds']))
    );
    return todos;
  }
);

export const isFiltered = createSelector(
  selectOpearationTasksState,
  (state: OperationTaskState) => state.isFiltered
);

export const selectError = createSelector(
  selectOpearationTasksState,
  (state: OperationTaskState) => state.error
);
