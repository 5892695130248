import { NzSelectSizeType } from 'ng-zorro-antd/select';
import { switchMap } from 'rxjs/operators';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  AfterViewInit,
  ChangeDetectorRef,
  AfterContentChecked,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, Subject } from 'rxjs';
import { UtilitiesState } from '../../store/utilities.reducer';
import * as fromUtilitiesAction from '../../store/utilities.actions';
import * as fromUtilitiesSelector from '../../store/utilities.selectors';
import { UtilitiesIds } from '../../store/utilities.model';

@Component({
  selector: 'app-utilities-select',
  templateUrl: './utilities-select.component.html',
  styleUrls: ['./utilities-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UtilitiesSelectComponent
  implements OnInit, OnChanges, AfterViewInit, AfterContentChecked
{
  items$: Observable<any> = of([]);
  utilities$: Observable<any> = of(null);
  subscribe = new Subject();
  @Input() utilitySelected: string | any = null;

  @Input() id: UtilitiesIds = UtilitiesIds.action;
  @Input() variable: string | any = '';
  @Input() precisList: string | any = '';
  @Input() placeholder: string = 'Sélection...';
  @Input() borderless: boolean = true;
  @Input() multi: boolean = true;
  @Input() other: boolean = false;
  @Input() infos: boolean = false;

  @Input() size: NzSelectSizeType = 'small';
  @Output() select = new EventEmitter<any>(false);
  constructor(
    private utilitieStore: Store<UtilitiesState>,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.getUtilities();
    this.loadUtilities();
  }
  ngAfterContentChecked(): void {
    this.changeDetectorRef.detectChanges();
  }
  ngAfterViewInit(): void {
    this.setUtilityItems(this.id, this.variable);
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if (changes.id.currentValue && changes.variable.currentValue) {
    // }
  }

  getUtilities(): void {
    this.utilities$ = this.utilitieStore.select(
      fromUtilitiesSelector.utilities
    );
  }
  loadUtilities(): void {
    this.utilitieStore.dispatch(fromUtilitiesAction.loadUtilities());
  }
  setUtilityItems(id: string, variable: string): void {
    if (!id || !variable) {
      return;
    } else {
      this.items$ = this.utilities$.pipe(
        switchMap((utilities: any): any => {
          if (!utilities) {
            return of([]);
          } else {
            const findUtility = utilities.find((el: any) => el.id === id);
            if (!findUtility) {
              return of([]);
            } else {
              const utilityItems = findUtility[variable];
              return of(utilityItems);
            }
          }
        })
      );
    }
  }

  isNotSelected(value: any): boolean {
    if (!value) {
      return false;
    } else {
      return this.utilitySelected.indexOf(value) === -1;
    }
  }

  onSelect(item: any): void {
    this.select.emit(item);
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }
}
