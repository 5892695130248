<ng-container *ngIf="participant$ | async as participant">
  <ng-container *ngIf="accompagnement$ | async as accompagnement">
    <nz-page-header
      class="site-page-header"
      (nzBack)="onBack(participant)"
      nzBackIcon
    >
      <!--title-->
      <nz-page-header-title>
        {{ accompagnement.objectif }}
      </nz-page-header-title>

      <!--subtitle-->
      <nz-page-header-subtitle>
        {{ accompagnement.type | titlecase }}
      </nz-page-header-subtitle>

      <!--tags-->
      <nz-page-header-tags>
        <nz-tag [nzColor]="accompagnement?.statusColor"
          >{{ accompagnement?.status }}
        </nz-tag>
      </nz-page-header-tags>

      <nz-page-header-title> </nz-page-header-title>
      <nz-page-header-extra>
        <nz-space>
          <button *nzSpaceItem nz-button>Operation</button>
          <button *nzSpaceItem nz-button nzShape="circle" nzType="text">
            <app-button-dropdown
              [isDelete]="true"
              [isUpdate]="true"
            ></app-button-dropdown>
          </button>
        </nz-space>
      </nz-page-header-extra>
      <nz-page-header-content>
        <nz-card>
          <nz-descriptions nzSize="small" [nzColumn]="3">
            <nz-descriptions-item nzTitle="Champs d'intervention" [nzSpan]="3">
              <strong>{{ accompagnement?.champs.libelle }}</strong>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Distance" [nzSpan]="1">
              {{ accompagnement?.distance }}
            </nz-descriptions-item>

            <nz-descriptions-item nzTitle="Progression" [nzSpan]="1">
              <strong>{{ accompagnement?.progress | percent }}</strong>
            </nz-descriptions-item>

            <nz-descriptions-item nzTitle="Durée" [nzSpan]="1">
              {{ accompagnement.duration?.months
              }}{{ accompagnement.duration?.months ? " mois " : "" }}
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Créée le" [nzSpan]="1">
              {{ accompagnement.dateStart | dateFr : "long" }}
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Échéance le" [nzSpan]="1">
              {{ accompagnement.dateEnd | dateFr : "long" }}
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Auteur" [nzSpan]="1">
              {{ accompagnement.auteur.displayName }}
            </nz-descriptions-item>
          </nz-descriptions>
        </nz-card>
      </nz-page-header-content>
    </nz-page-header>

    <nz-space nzSize="small" nzDirection="vertical"></nz-space>
    <nz-card class="action_accompagnement_item_container">
      <app-participant-actions-accompagnement-action
        [type]="accompagnement.type"
      ></app-participant-actions-accompagnement-action>
    </nz-card>
  </ng-container>
</ng-container>
