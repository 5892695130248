import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-export-select',
  template: `
    <nz-card nzSize="small" *ngIf="isInclusSuivi || isAnomyne">
      <nz-row nzAlign="middle" nzGutter="8" nzJustify="center">
        <nz-col [nzSpan]="isInclusSuivi ? 12 : 24" *ngIf="isAnomyne">
          <label
            nz-checkbox
            [nzChecked]="anonym"
            (nzCheckedChange)="checkChangeAnomym($event)"
          >
            Anonymiser
          </label>
        </nz-col>
        <nz-col [nzSpan]="isAnomyne ? 12 : 24" *ngIf="isInclusSuivi">
          <label
            nz-checkbox
            [nzChecked]="suivi"
            (nzCheckedChange)="checkChangeSuivi($event)"
          >
            Inclure les suivis
          </label>
        </nz-col>
      </nz-row>
    </nz-card>
    <nz-divider
      nzOrientation="center"
      nzType="horizontal"
      *ngIf="isInclusSuivi || isAnomyne"
    ></nz-divider>
    <nz-card nzSize="small">
      <nz-row nzAlign="middle" nzGutter="8" nzJustify="center">
        <nz-col [nzSpan]="6">
          <button
            nz-button
            nzBlock
            nzType="text"
            [disabled]="!isPDF"
            (click)="onSelect(selections[0].name)"
          >
            <span>PDF</span>
            <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>

            <i nz-icon class="pdf" [nzType]="selections[0].icon"></i>
          </button>
        </nz-col>
        <nz-col [nzSpan]="3"></nz-col>
        <nz-col [nzSpan]="6">
          <button
            nz-button
            nzBlock
            nzType="text"
            [disabled]="!isEXL"
            (click)="onSelect(selections[1].name)"
          >
            <span>EXCEL</span>
            <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>

            <i nz-icon class="excel" [nzType]="selections[1].icon"></i>
          </button>
        </nz-col>
        <nz-col [nzSpan]="3"></nz-col>
        <nz-col [nzSpan]="6">
          <button
            nz-button
            nzBlock
            [disabled]="!isPPT"
            nzType="text"
            (click)="onSelect(selections[2].name)"
          >
            <span>PPT</span>
            <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>

            <i nz-icon class="ppt" [nzType]="selections[2].icon"></i>
          </button>
        </nz-col>
      </nz-row>
    </nz-card>
  `,
  styles: [
    `
      nz-row {
        width: 100%;
      }
      button {
        background-color: transparent !important;
      }

      h6 {
        font-size: 12px;
      }

      i {
        font-size: 25px;
      }

      .pdf {
        color: #ff4d4f;
      }
      .excel {
        color: #73d13d;
      }
      .ppt {
        color: #ffa940;
      }
    `,
  ],
})
export class ExportSelectComponent implements OnInit {
  anonym: boolean = false;
  suivi: boolean = false;

  selections = [
    {
      name: 'PDF',
      icon: 'file-pdf',
      disabled: true,
    },
    {
      name: 'EXCEL',
      icon: 'file-excel',
      disabled: true,
    },
    {
      name: 'PPT',
      icon: 'file-ppt',
      disabled: true,
    },
  ];

  @Input() isPDF: boolean = true;
  @Input() isEXL: boolean = false;
  @Input() isWORD: boolean = false;
  @Input() isPPT: boolean = false;
  @Input() isInclusSuivi: boolean = false;
  @Input() isAnomyne: boolean = false;

  @Output() select = new EventEmitter<any>(false);
  @Output() cancel = new EventEmitter<boolean>(false);

  constructor() {}

  ngOnInit(): void {}

  checkChangeAnomym(event: boolean): void {
    this.anonym = event;
  }

  checkChangeSuivi(event: boolean): void {
    this.suivi = event;
  }

  onSelect(item: string): void {
    if (!item) return;

    this.select.emit({ item, anonym: this.anonym, suivi: this.suivi });
  }
  onCandcel(item: string): void {
    if (!item) return;
    this.cancel.emit(true);
  }
}
