import { Injectable } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { QueryEntitesSearchServiceGQL } from '../graphql/queries/query-entites-search.service';
import { QueryEntiteSiretServiceGQL } from '../graphql/queries/query-entite-siret.service';

@Injectable({
  providedIn: 'any',
})
export class EntiteSearchService {
  constructor(
    private queryEntitesSearchServiceGQL: QueryEntitesSearchServiceGQL,
    private queryEntiteSiretServiceGQL: QueryEntiteSiretServiceGQL
  ) {}

  getEntreprises(search: any): Observable<any> {
    try {
      if (!search || !search.input.length) return EMPTY;

      return this.queryEntitesSearchServiceGQL
        .watch({
          search,
        })
        .valueChanges.pipe(
          map(({ data, loading, error }) => {
            return { data, loading, error };
          })
        );
    } catch (error) {
      return of(error);
    }
  }
  getEtablissementBySiret(siret: string): Observable<any> {
    try {
      if (!siret) return EMPTY;

      return this.queryEntiteSiretServiceGQL
        .watch({
          siret,
        })
        .valueChanges.pipe(
          map(({ data, loading, error }) => {
            return { data, loading, error };
          })
        );
    } catch (error) {
      return of(error);
    }
  }
}
