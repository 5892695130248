import { mergeMap, catchError, map } from 'rxjs/operators';
import { OperationGanttService } from './../../../service/operation-gantt.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromOperationGanttAction from './operation-gantt.actions';
import { of } from 'rxjs';

@Injectable()
export class OperationGanttEffects {
  loadGantts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationGanttAction.loadOperationGantt),
      mergeMap((action) =>
        this.operationGanttService.getGantt(action.operationId).pipe(
          map(
            (gantt: any) =>
              fromOperationGanttAction.loadOperationGanttSuccess({
                gantt,
              }),
            catchError((error) =>
              of(
                fromOperationGanttAction.loadOperationGanttFailure({
                  error,
                })
              )
            )
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private operationGanttService: OperationGanttService
  ) {}
}
