import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-projet-professionnel-diagnostics-item',
  templateUrl: './projet-professionnel-diagnostics-item.component.html',
  styleUrls: ['./projet-professionnel-diagnostics-item.component.scss']
})
export class ProjetProfessionnelDiagnosticsItemComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
