import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Objectif } from './objectif.model';
import * as ObjectifActions from './objectif.actions';

export const objectivesFeatureKey = 'objectives';

export interface ObjectifState extends EntityState<Objectif> {
  // additional entities state properties
  participantUtilities: any;
  error: any;
}

export const adapter: EntityAdapter<Objectif> = createEntityAdapter<Objectif>();

export const initialState: ObjectifState = adapter.getInitialState({
  // additional entity state properties
  participantUtilities: undefined,
  error: undefined,
});

export const reducer = createReducer(
  initialState,
  on(ObjectifActions.loadParticipantUtilitiesSuccess, (state, action) => {
    return {
      ...state,
      participantUtilities: action.utilities,
    };
  }),
  on(ObjectifActions.loadParticipantUtilitiesFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
