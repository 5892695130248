import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';
import { UtilitiesState } from 'src/app/contents/components/utilities/store/utilities.reducer';
import * as fromUtilitiesSelector from 'src/app/contents/components/utilities/store/utilities.selectors';
import * as fromUtilitiesAction from 'src/app/contents/components/utilities/store/utilities.actions';
import { ContratState } from 'src/app/contents/components/contrat/store/contrat.reducer';
import * as fromContratSelector from 'src/app/contents/components/contrat/store/contrat.selectors';
import * as fromContratAction from 'src/app/contents/components/contrat/store/contrat.actions';

@Component({
  selector: 'app-participant-diagnostic-form-events',
  templateUrl: './participant-diagnostic-form-events.component.html',
  styleUrls: ['./participant-diagnostic-form-events.component.scss'],
})
export class ParticipantDiagnosticFormEventsComponent
  implements OnInit, OnChanges, OnDestroy
{
  subscribe = new Subject();

  //FORM FIELD
  eventsForm: FormGroup = this.fb.group({});

  @Input() droitUpdate$ = new BehaviorSubject<any>(null);
  @Output() add = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private utilitiesStore: Store<UtilitiesState>
  ) {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {}

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  onFormChanges(): void {
    this.eventsForm.valueChanges
      .pipe(takeUntil(this.subscribe))
      .subscribe((values) => this.add.emit(values));
  }
}
