import {
  Component,
  OnInit,
  Input,
  Output,
  OnDestroy,
  EventEmitter,
  SimpleChanges,
  ChangeDetectionStrategy,
  OnChanges,
} from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { Timestamp } from '@angular/fire/firestore';
import {
  Validators,
  FormGroup,
  FormControl,
  FormBuilder,
} from '@angular/forms';
import { ItemEventService } from '../../../../service/item-event.service';
import { ItemEvent, ItemEventId } from './itemEvent.model';
import { fromUnixTime } from 'date-fns';

@Component({
  selector: 'app-item-event',
  templateUrl: './item-event.component.html',
  styleUrls: ['./item-event.component.scss'],
  animations: [
    trigger('fade', [
      transition('void =>*', [
        style({ opacity: 0 }),
        animate(400, style({ opacity: 1 })),
      ]),
      transition('* =>void', [
        animate(400, style({ color: 'red', opacity: 0 })),
      ]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemEventComponent implements OnInit, OnChanges, OnDestroy {
  utility$: Observable<any> = this.ies.getUtility();
  subscribe = new Subject();
  dateFormat = 'dd/MM/yyyy HH:mm';
  dateUpdateEvent: Date = new Date(Date.now());
  today: Date = new Date(Date.now());
  titleForm: string = 'Nouveau suivi';
  beneficiaireProjet = null;
  edithUpdate = new BehaviorSubject<string>('');

  event: FormGroup = new FormGroup({
    type: new FormControl('', Validators.required),
    title: new FormControl('', Validators.required),
    object: new FormControl('', Validators.required),
    dateStart: new FormControl(this.today, Validators.required),
    dateUpdate: new FormControl(Timestamp.now()),
    hourStart: new FormControl(Timestamp.now()),
    hourUpdate: new FormControl(Timestamp.now()),
    textHtml: new FormControl('', Validators.required),
    text: new FormControl('', Validators.required),
    correspondanceId: new FormControl(''),
    realisations: new FormControl(''),
    preconisation: new FormControl(''),
    besoins: new FormControl(''),
    operationId: new FormControl(''),
    operationDenomination: new FormControl(''),
  });

  @Input() item: ItemEvent | any = null;
  @Input() interlocteur = new BehaviorSubject<any>(null);
  @Input() size: string = '200px';
  @Input() updateActivity: boolean = false;
  @Output() add = new EventEmitter<ItemEvent>();
  @Output() update = new EventEmitter<ItemEventId>();
  @Output() cancel = new EventEmitter<boolean>();

  constructor(private fb: FormBuilder, private ies: ItemEventService) {}

  ngOnInit() {
    // this.formInit();
    // this.formCreate();
    this.onSetType();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes?.item?.currentValue) return;

    this.onItemUpdate(changes?.item?.currentValue);
  }

  /**
   * Mise à jour des menus de choix du formulaire
   * @param {any} item1
   * @param {any} item2
   * @returns
   */
  formCompare(item1: any, item2: any): any {
    return item1 === item2;
  }

  onNewText(event: any): any {
    const { textValue, textHTML } = event;

    if (!textValue) {
      return null;
    }

    this.event.patchValue({
      text: textValue,
      textHtml: textHTML,
    });
  }

  onSetType(): any {
    this.event.get('object')?.valueChanges.subscribe((val: any) => {
      if (val) {
        this.event.patchValue({
          title: val,
        });
      }
    });
  }

  onModifyText(event: any): any {
    const { textValue, textHTML } = event;
    if (!textValue) {
      return null;
    }

    this.event.patchValue({
      text: textValue,
      textHtml: textHTML,
    });
  }

  /**
   * Création d'une nouvelle note
   * @returns
   */
  onAdd(): any {
    if (this.event.invalid) {
      return null;
    } else {
      const value: ItemEvent = this.event.value;
      const dateStart = Timestamp.fromDate(
        new Date(value.dateStart.toString())
      );
      value.dateStart = dateStart;
      value.dateUpdate = dateStart;
      this.add.next(value);
    }
  }

  /**
   * Modification de la note
   * @param event
   * @returns
   */
  onUpdate(note: ItemEventId): any {
    if (this.event.invalid) {
      return null;
    } else {
      const { id } = note;
      const updatedNote: ItemEventId = this.event.value;
      updatedNote.id = id;
      const dateStart = Timestamp.fromDate(
        new Date(updatedNote.dateStart.toString())
      );
      updatedNote.dateStart = dateStart;
      updatedNote.dateUpdate = dateStart;
      this.titleForm = 'Modifier le suivi';

      this.update.next(updatedNote);
    }
  }

  /**
   * Actualisation du formulaire de note avec les informations de la note à modifier
   * @param {ItemEventId} data
   */
  onItemUpdate(data: ItemEventId): void {
    this.dateUpdateEvent = fromUnixTime(data.dateStart['seconds']);

    const dateStart = fromUnixTime(data.dateStart['seconds']);
    const dateUpdate = Timestamp.now();
    const hourStart = data.hourStart;
    const hourUpdate = data.hourUpdate;

    this.event.patchValue({
      text: data.text,
      textHtml: data.textHtml,
      auteur: data.auteur,
      type: data.type,
      object: data.object,
      title: data.title,
      userUpdate: data.userUpdate,
      dateStart: dateStart,
      dateUpdate: dateUpdate,
      hourStart: hourStart,
      hourUpdate: hourUpdate,
      correspondanceId: data.correspondanceId,
      besoins: data.besoins,
      realisations: data.realisations,
      preconisation: data.preconisation,
    });

    this.updateActivity = true;
    this.edithUpdate.next(data.textHtml);
  }

  objetOrder(items: any[]): any {
    if (items) {
      const objOrder = items.sort();
      return objOrder;
    }
  }

  onCancel(): void {
    this.edithUpdate.next('');
    this.cancel.next(true);
  }

  ngOnDestroy() {
    this.subscribe.next(null);
    this.subscribe.complete();
  }
}
