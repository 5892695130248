import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-participant-diagnostic-form-resum',
  templateUrl: './participant-diagnostic-form-resum.component.html',
  styleUrls: ['./participant-diagnostic-form-resum.component.scss'],
})
export class ParticipantDiagnosticFormResumComponent implements OnInit {
  currentStep: number = 0;

  @Output() add = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  onIndexChange(index: number): void {
    this.currentStep = index;
  }
}
