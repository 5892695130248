import { Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  doc,
  docSnapshots,
  collectionChanges,
  getDoc,
  addDoc,
  setDoc,
  deleteDoc,
  serverTimestamp,
  DocumentData,
  Timestamp,
  query,
  where,
  arrayUnion,
  arrayRemove,
  collectionSnapshots,
  CollectionReference,
  DocumentReference,
  getDocs,
  documentId,
} from '@angular/fire/firestore';
import { Observable, of, from, EMPTY } from 'rxjs';
import { switchMap, mergeMap, map, catchError } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { UserState } from '../components/user/store/user.reducer';
import * as fromUserSelector from '../components/user/store/user.selectors';
import { v4 as uuidV4 } from 'uuid';
import { format, fromUnixTime } from 'date-fns';
import { Apollo, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'any',
})
export class ParticipantProjetproTodoService {
  constructor(
    private db: Firestore,
    private fns: Functions,
    private userStore: Store<UserState>,
    private apollo: Apollo
  ) {}

  getAll(participantId: string, projetpro: any): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !participantId || !projetpro) {
            return of([]);
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/participants/${participantId}/projetsprofessionnels/${projetpro.id}/todos`
            );

            const changes = collectionSnapshots(dbCollection).pipe(
              map((changes) => {
                return changes.map((snap) => {
                  const data = snap.data();
                  const id = snap.id;
                  return { id, ...data };
                });
              })
            );

            return changes;
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  addOne(participantId: string, projetpro: any, todo: any): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !projetpro || !todo) {
            return of(null);
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/participants/${participantId}/projetsprofessionnels/${projetpro.id}/todos`
            );

            const document = {
              ...todo,
              user: user,
              userUpdate: user,
              dateUpdate: serverTimestamp(),
            };

            return from(
              addDoc(dbCollection, document)
                .then((docRef) => {
                  if (docRef.id) {
                    return of(`Tâche ajoutée au projet professionnel`);
                  } else {
                    return of(null);
                  }
                })
                .catch((error) => of(error))
            ).pipe(mergeMap((res) => res));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  updateOne(
    participantId: string,
    projetpro: any,
    todo: Partial<any>
  ): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !projetpro || !todo) {
            return of(null);
          } else {
            const dbDocument = doc(
              this.db,
              `organisations/${user.organisation.id}/participants/${participantId}/projetsprofessionnels/${projetpro.id}/todos/${todo.id}`
            );

            return from(
              setDoc(dbDocument, todo, { merge: true })
                .then(() => of(`Tâche modifiée`))
                .catch((error) => of(error))
            ).pipe(mergeMap((res) => res));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  deleteOne(
    participantId: string,
    projetpro: any,
    id: string
  ): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !projetpro || !id) {
            return of(null);
          } else {
            const dbDocument = doc(
              this.db,
              `organisations/${user.organisation.id}/participants/${participantId}/projetsprofessionnels/${projetpro.id}/todos/${id}`
            );

            return from(
              deleteDoc(dbDocument)
                .then(() => of(`Tâche retirée du projet professionnel`))
                .catch((error) => of(error.message))
            ).pipe(mergeMap((res) => res));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  deleteAll(participantId: string, ids: string[]): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) {
            return of(null);
          } else {
            //CALL HTTP

            return of(null);
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }
}
