import { ShellComponent } from './components/shell/shell.component';
import { SideNavigationModule } from './components/side-navigation/side-navigation.module';
import { UserService } from './service/user.service';
import { NavigationModule } from './components/navigation/navigation.module';
import { ZorroModule } from './css/zorro.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, TitleStrategy } from '@angular/router';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { initializeApp, provideFirebaseApp, getApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import {
  provideAnalytics,
  getAnalytics,
  ScreenTrackingService,
  UserTrackingService,
} from '@angular/fire/analytics';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideDatabase, getDatabase } from '@angular/fire/database';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { provideFunctions, getFunctions } from '@angular/fire/functions';
import { provideMessaging, getMessaging } from '@angular/fire/messaging';
import { providePerformance, getPerformance } from '@angular/fire/performance';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { fr_FR } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import fr from '@angular/common/locales/fr';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  SETTINGS as AUTH_SETTINGS,
  LANGUAGE_CODE,
  PERSISTENCE,
} from '@angular/fire/compat/auth';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { reducers, metaReducers } from './reducers';
import { AppEffects } from './app.effects';
import { GraphQLModule } from './graphql.module';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { RouteCustomTitleStrategyService } from './resolvers/router/route-custom-title-strategy.service';
import { PortalModule } from '@angular/cdk/portal';

registerLocaleData(fr);

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [AppComponent, ShellComponent],
  imports: [
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    AppRoutingModule,
    PortalModule,
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    provideFunctions(() => getFunctions()),

    provideDatabase(() => getDatabase()),
    provideStorage(() => getStorage()),
    provideMessaging(() => getMessaging()),

    providePerformance(() => getPerformance()),
    provideAnalytics(() => getAnalytics()),

    HttpClientModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot([AppEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
      autoPause: true,
    }),
    FormsModule,
    ReactiveFormsModule,
    ZorroModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    GraphQLModule,
    NavigationModule,
    SideNavigationModule,
    LottieModule.forRoot({ player: playerFactory }),
  ],
  providers: [
    ScreenTrackingService,
    UserTrackingService,
    UserService,
    { provide: NZ_I18N, useValue: fr_FR },
    {
      provide: AUTH_SETTINGS,
      useValue: { appVerificationDisabledForTesting: true },
    },
    { provide: LANGUAGE_CODE, useValue: 'fr' },
    { provide: PERSISTENCE, useValue: 'session' },
    { provide: TitleStrategy, useClass: RouteCustomTitleStrategyService },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
