import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, Validators } from '@angular/forms';
import { fromUnixTime } from 'date-fns';
import { sum } from 'lodash';
import {
  BehaviorSubject,
  Observable,
  of,
  Subject,
  takeUntil,
  merge,
  timer,
} from 'rxjs';
import { fade } from 'src/app/app-animation';
import { MissionHour } from '../../store/mission.model';
import { delay, finalize, map, scan } from 'rxjs/operators';

@Component({
  selector: 'app-missions-hours',
  templateUrl: './missions-hours.component.html',
  styleUrls: ['./missions-hours.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fade],
})
export class MissionsHoursComponent implements OnInit, OnDestroy, OnChanges {
  subscribe = new Subject();
  @Input() total: number = 0;
  @Input() totalWork: number = 0;
  @Input() totalTraining: number = 0;
  @Input() planing$ = new BehaviorSubject<any[]>([]);
  @Output() update = new EventEmitter<any>(false);
  @Input() updateLoading$: Observable<boolean> = of(false);
  planingForm: UntypedFormGroup = this.fb.group({});
  planigItems = new UntypedFormArray([]);
  updateActived$ = new BehaviorSubject<boolean>(false);

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.form();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  form(): void {
    this.formInit();
    this.formCreate();
    this.formSet();
  }

  formInit(): void {
    this.planigItems = new UntypedFormArray([]);
  }
  formCreate(): void {
    this.planingForm = this.fb.group({
      items: this.planigItems,
    });
  }
  formSet(): void {
    this.planing$
      .pipe(takeUntil(this.subscribe))
      .subscribe((items: MissionHour[]) => {
        if (!items?.length) return;

        items.forEach((item: MissionHour) => this.onAddItem(item));
      });
  }

  get planigItemsArray() {
    return this.planingForm.get('items') as UntypedFormArray;
  }

  onAddItem(item: any): void {
    if (!item) return;
    const nextItem = this.fb.group({
      ...item,
    });
    this.planigItems.push(nextItem);
  }
  onRemoveItem(index: number): void {
    this.planigItems.removeAt(index);
  }

  onValidation(): void {
    const formValue = this.planingForm.value;
    const summing: MissionHour[] = formValue.items?.length
      ? formValue.items.map((item: any) => {
          const nextItem: MissionHour = {
            ...item,
            date: fromUnixTime(item.date['seconds']),
            sum: sum([item.work, item.training]),
          };
          return nextItem;
        })
      : [];

    this.updateActived$.next(true);
    this.planingForm.markAsPristine();
    this.update.emit(summing);
  }
}
