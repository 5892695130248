import { ProjetPrpfessionnelState } from './../../store/projet-professionnel.reducer';
import * as fromProjetProAction from './../../store/projet-professionnel.actions';

import {
  UntypedFormBuilder,
  FormGroup,
  FormControl,
  FormArray,
  Validators,
} from '@angular/forms';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Update } from '@ngrx/entity';
import { BehaviorSubject, of } from 'rxjs';
import { ParticipantId } from 'src/app/components/participant/store/participant.model';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-projet-professionnel-item',
  templateUrl: './projet-professionnel-item.component.html',
  styleUrls: ['./projet-professionnel-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjetProfessionnelItemComponent implements OnInit, OnChanges {
  chartType = new BehaviorSubject<any>(null);
  employablity: string = 'Employabilité';
  currentTabIndex: number = 0;
  chartMax: number = 20;

  typeTitle: string = 'projet professionnel';

  @Input() projetprofessionnel: any;
  @Input() projetprofessionnel$ = new BehaviorSubject<any>(null);
  @Input() participant: ParticipantId | any = null;

  @Output() back: EventEmitter<any> = new EventEmitter(false);
  @Output() delete: EventEmitter<any> = new EventEmitter(false);
  @Output() update: EventEmitter<any> = new EventEmitter(false);
  @Output() export: EventEmitter<any> = new EventEmitter(false);

  @Output() cancel: EventEmitter<any> = new EventEmitter(false);

  constructor(
    private fb: UntypedFormBuilder,
    private projetProfessionnelStore: Store<ProjetPrpfessionnelState>
  ) {}

  ngOnInit(): void {
    this.currentTabIndex = 0;
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.setChart(changes.projetprofessionnel.currentValue);
  }

  onSelectedTab(event: number, projetProfessionnel: any): void {
    this.currentTabIndex = event;
    switch (event) {
      case 7:
        this.loadStatistiques(projetProfessionnel);
        break;

      default:
        break;
    }
  }

  loadStatistiques(projetProfessionnel: any): void {
    if (!projetProfessionnel) return;

    const { code, areaCode, age } = projetProfessionnel;
    const fabCode = projetProfessionnel?.fap?.fap?.code
      ? projetProfessionnel.fap.fap.code
      : null;
    if (!fabCode) return;
    this.projetProfessionnelStore.dispatch(
      fromProjetProAction.loadStatistiquesEmploi({
        codeRome: code,
        areaCode: areaCode,
        codeFap: fabCode,
        age: Number(age),
      })
    );
  }

  setChart(item: any): void {
    if (!item) return;
    this.projetprofessionnel$.next(item);

    const competencesCleDeBaseSize: number =
      item.appellation.competencesCleDeBaseSize;
    const competencesCleSpecifiquesSize: number =
      item.appellation.competencesCleSpecifiquesSize;
    const accesSize: number = item.accesSize;
    const skills: number = item.softskills.skills.filter(
      (skill: any) => Number(skill.score) >= 0.5
    )?.length;

    const mobilitesMetiersSize: number =
      item.mobilitesProchesVersMetiers.length;

    const formations = [];
    const experiencesSize = 0;
    // const title = {
    //   text: '',
    //   subtext: '',
    //   top: 10,
    //   left: 10,
    // };
    const title = '';
    const isLegend = false;
    // legend: {
    //   type: 'scroll',
    //   bottom: 10,
    //   data: (function () {
    //     var list = [];
    //     for (var i = 1; i <= 28; i++) {
    //       list.push(i + 2000 + '');
    //     }
    //     return list;
    //   })()
    // },
    const legend: any = ['Métier', 'Situation'];
    const center = ['50%', '55%'];
    const radius = 160;
    const height: string = '505px';
    const type = 'radar';
    const data = [
      {
        value: [0, 0, 0, 0, 0, 0],
        name: 'Situation',
        symbolSize: 10,
        label: {
          show: true,
          formatter: function (params: any) {
            return params.value;
          },
        },
      },
      {
        value: [
          accesSize,
          competencesCleDeBaseSize,
          competencesCleSpecifiquesSize,
          skills,
          mobilitesMetiersSize,
          experiencesSize,
        ],
        name: 'Métier',
        symbolSize: 11,
        label: {
          show: true,
          formatter: function (params: any) {
            return params.value;
          },
        },
      },
    ];
    const serieName = '';
    const indicator = [
      { name: 'Prérequis', max: this.chartMax },
      { name: 'Compétences de base', max: this.chartMax },
      { name: 'Compétences Spécifiques', max: this.chartMax },
      { name: 'Softskills', max: this.chartMax },
      { name: 'Mobilité professionnelle', max: this.chartMax },
      { name: 'Expériences', max: this.chartMax },
    ];
    const options = {
      title,
      type,
      data,
      isLegend,
      legend,
      indicator,
      serieName,
      center,
      radius,
      height,
      option: {
        toolbox: true,
        saveImageName: 'projet_professionnel',
      },
    };
    this.chartType.next(options);
  }

  onBack(): void {
    this.back.emit(true);
  }

  onCancel(): void {
    this.cancel.emit(true);
  }
  onUpdate(item: any): void {
    if (!item) return;

    const nextProjetPro: Update<any> = {
      id: item.id,
      changes: {},
    };

    this.update.emit(nextProjetPro);
  }

  onExport(item: any): void {
    if (!item) return;
    this.export.emit(item);
  }

  onDelete(item: any): void {
    if (!item) return;
    this.delete.emit(item);
  }
}
