import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-item-operations-type',
  templateUrl: './item-operations-type.component.html',
  styleUrls: ['./item-operations-type.component.scss'],
})
export class ItemOperationsTypeComponent implements OnInit {
  defaultColor: string = '#808080';
  COLORS: any = {
    clauses: '#40a9ff',
    education: '#36cfc9',
    culture: '#36cfc9',
    entreprise: '#f759ab',
    emploi: '#ffa940',
    territoire: '#9254de',
  };

  @Input() type: any = null;
  constructor() {}

  ngOnInit(): void {}
}
