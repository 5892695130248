import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { isNull, isUndefined } from 'lodash';
import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-operation-dashboard-modules',
  templateUrl: './operation-dashboard-modules.component.html',
  styleUrls: ['./operation-dashboard-modules.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationDashboardModulesComponent
  implements OnInit, OnChanges, OnDestroy
{
  subscribe = new Subject();
  noExist = 'Non renseigné';

  //MODULES
  modules$ = new BehaviorSubject<any>([]);
  modulesCompare$ = new BehaviorSubject<any>(null);
  modulesProgressions$ = new BehaviorSubject<any>(null);

  @Input() dashboard$: Observable<any> = of(null);

  constructor() {}

  ngOnInit(): void {
    this.setModulesDashboard();
  }

  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  chartsValues = (statistiques: any) => {
    const values = statistiques
      ? Object.values(statistiques).map((item) => {
          if (isNull(item) || isUndefined(item) || !item) {
            return this.noExist;
          }
          return item;
        })
      : [];

    const labels = statistiques
      ? Object.keys(statistiques).map((item) => {
          if (isNull(item) || isUndefined(item) || !item) {
            return this.noExist;
          }
          return item;
        })
      : [];

    return { values, labels };
  };

  itemsCounter = (items: any[]) => {
    return items.reduce((acc, cur) => {
      const curSize = items.filter((el) => el === cur).length;

      acc[cur] = curSize;
      return acc;
    }, {});
  };

  chartsTypePie = (count: any): any => {
    const transformToArray: any[] = Object.entries(count);
    const transformForChart: any[] = transformToArray.map((el) => ({
      name: el[0],
      value: el[1],
    }));

    return transformForChart;
  };

  setModulesDashboard(): void {
    this.dashboard$.pipe(takeUntil(this.subscribe)).subscribe((dashboard) => {
      if (!dashboard) return;
      const modules = dashboard?.modules?.data?.length
        ? dashboard.modules.data
        : [];

      this.modules$.next(modules);
      this.setModulesCharts(modules);
    });
  }

  setModulesCharts(modules: any): void {
    if (!modules?.length) return;
    this.setModuleCompareObjectif(modules);
    this.setModuleProgression(modules);
  }

  setModuleCompareObjectif(modules: any[]): void {
    const modulesTitles = modules.map((module) => module.titre);
    const modulesObjectifs = modules.map((module) =>
      module?.objectifHours ? module.objectifHours : 0
    );
    const modulesObjectifsPersons = modules.map((module) =>
      module?.objectifHours ? module.objectifHours : 0
    );
    const modulesRealisations = modules.map((module) =>
      module?.realisations ? module.realisations : 0
    );
    const modulesProgression = modules.map((module) =>
      module?.progressionHours ? module.progressionHours * 100 : 0
    );
    const modulesProgressionPersons = modules.map((module) =>
      module?.progressionPersons ? module.progressionPersons * 100 : 0
    );

    const modulesStarts = modules.map((module) => module?.demarrage);
    const modulesEnds = modules.map((module) => module?.echeance);
    const modulesTypes = modules.map((module) => module?.type);
    const modulesStatut = modules.map((module) => module?.statut);

    const items = {
      title: 'Modules : Objectifs - Réalisations - Progressions',
      chartsTitle: '',
      type: 'barCompare',
      data: {
        items: modulesTitles,
        objectifs: modulesObjectifs,
        realisations: modulesRealisations,
        progressions: modulesProgression,
      },
      typeValue: 'hours',

      center: ['50%', '70%'],
      radius: 120,
      height: '400px',
    };

    this.modulesCompare$.next(items);
  }
  setModuleProgression(modules: any[]): void {
    const modulesProgression = modules.map((module) => {
      const label: string = module.titre;
      const value: string = module?.progression ? module.progression : 0;

      return { label, value };
    });

    const genre = {
      title: 'Modules : progressions',
      chartsTitle: '',
      type: 'barProgress',
      data: modulesProgression,
      center: ['50%', '70%'],
      radius: 120,
      height: '400px',
    };

    this.modulesProgressions$.next(genre);
  }
}
