import { Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  doc,
  getDoc,
  collectionSnapshots,
  addDoc,
  updateDoc,
  setDoc,
  deleteDoc,
  getDocs,
  serverTimestamp,
} from '@angular/fire/firestore';
import { Observable, Subject, of, from } from 'rxjs';
import { switchMap, mergeMap, map } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Functions } from '@angular/fire/functions';
import { TicketId } from '../components/tickets/store/ticket.model';
import { UserState } from '../components/user/store/user.reducer';
import * as fromUserSelector from '../components/user/store/user.selectors';

@Injectable({
  providedIn: 'any',
})
export class TicketsService {
  ticketsCollection: any;
  ticketDocument: any;
  tickets$: Observable<any[]> = of([]);
  ticket$: Observable<any> = of(null);

  constructor(
    private userStore: Store<UserState>,
    private db: Firestore,
    private fns: Functions
  ) {}

  get__TICKETS(): Observable<any> {
    try {
      return this.userStore.pipe(
        select(fromUserSelector.user),
        switchMap((user) => {
          if (!user) of([]);
          if (!user?.claims?.dev) {
            return this.getMy__TICKETS(user);
          } else {
            this.ticketsCollection = collection(this.db, 'tickets');

            return from(
              getDocs(this.ticketsCollection).then((snap): any => {
                return snap.docs.map((doc) => {
                  const id = doc.id;
                  const data = doc.data() as any;
                  return { id, ...data };
                });
              })
            ).pipe(
              mergeMap((res) => {
                console.log(res);
                return of(res);
              })
            );
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  get__TICKET(id: string): Observable<TicketId | any> {
    try {
      return this.userStore.pipe(
        select(fromUserSelector.user),
        switchMap((user) => {
          if (!user) {
            return of(null);
          }
          this.ticketDocument = doc(this.db, `users/${user.id}/tickets/${id}`);

          return from(
            getDoc(this.ticketDocument)
              .then((snap) => {
                const id = snap.id;
                const data = snap.data() as any;
                return { id, ...data };
              })
              .catch((error) => error)
          ).pipe(mergeMap((res) => of(res)));
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  getMy__TICKETS(user: any): Observable<any> {
    try {
      this.ticketsCollection = collection(this.db, `users/${user.id}/tickets`);

      return from(
        getDocs(this.ticketsCollection)
          .then((snap) => {
            return snap.docs.map((doc) => {
              const id = doc.id;
              const data = doc.data() as any;
              return { id, ...data };
            });
          })
          .catch((error) => error)
      ).pipe(mergeMap((res) => of(res)));
    } catch (error) {
      return of(error);
    }
  }

  add__TICKET(ticket: any): Observable<any> {
    try {
      return this.userStore.pipe(
        select(fromUserSelector.user),
        switchMap((user) => {
          if (!user) {
            return of(null);
          }

          this.ticketsCollection = doc(this.db, `users/${user.id}/tickets`);
          const newTicket = {
            ...ticket,
            statut: false,
            auteur: user,
            dateStart: serverTimestamp(),
          };

          return from(
            addDoc(this.ticketsCollection, newTicket)
              .then((docRef) => {
                if (docRef.id) {
                  return this.get__TICKET(docRef.id);
                } else {
                  return of(null);
                }
              })
              .catch((error) => of(error))
          ).pipe(mergeMap((res) => res));
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  update__TICKET(
    id: string | number,
    ticket: Partial<TicketId | any>
  ): Observable<any> {
    try {
      return this.userStore.pipe(
        select(fromUserSelector.user),
        switchMap((user) => {
          if (!user?.claims?.dev) {
            return of(null);
          }

          this.ticketsCollection = doc(this.db, `users/${user.id}/tickets`);
          const nexTicket = {
            ...ticket,
            dateStart: serverTimestamp(),
          };
          const ticketId: string = ticket.id;
          return from(
            setDoc(this.ticketsCollection, nexTicket, { merge: true })
              .then(() => this.get__TICKET(ticketId))
              .catch((error) => of(error))
          ).pipe(mergeMap((res) => res));
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  delete__TICKET(id: string): Observable<any> {
    try {
      return this.userStore.pipe(
        select(fromUserSelector.user),
        switchMap((user) => {
          if (!user?.claims?.dev) {
            return of(null);
          }

          const ticketDoc = doc(this.db, `users/${user.id}/tickets/${id}`);

          return from(
            deleteDoc(ticketDoc)
              .then(() => of('Ticket supprimé'))
              .catch((error) => of(error))
          ).pipe(mergeMap((res) => of(res)));
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  deleteAll__TICKETS(userId: string): Observable<any> {
    try {
      if (!userId) {
        return of(null);
      } else {
        const tickestDocs = collection(this.db, `users/${userId}/tickets`);
        return from(
          getDocs(tickestDocs)
            .then((snap) => {
              snap.docs.forEach((item) => {
                const ticketDoc = doc(
                  this.db,
                  `users/${userId}/tickets/${item.id}`
                );

                deleteDoc(ticketDoc);
              });
              return 'Success';
            })
            .catch((error) => error)
        ).pipe(mergeMap((res) => of(res)));
      }
    } catch (error) {
      return of(error);
    }
  }
}
