import {
  UtilitiesIds,
  UtilitiesProjetVariables,
} from './../../../../contents/components/utilities/store/utilities.model';
import { takeUntil } from 'rxjs/operators';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  FormControl,
} from '@angular/forms';
import { Observable, of, Subject, BehaviorSubject } from 'rxjs';
import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { OperationId } from '../../store/operation.model';
import { Store } from '@ngrx/store';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';
import { OperationState } from '../../store/operation.reducer';
import * as fromOperationAction from '../../store/operation.actions';
import * as fromOperationSelector from '../../store/operation.selectors';
import { Timestamp } from '@firebase/firestore';
import { add, differenceInCalendarDays, fromUnixTime } from 'date-fns';
import { Update } from '@ngrx/entity';
import { UtilitiesState } from 'src/app/contents/components/utilities/store/utilities.reducer';
import * as fromUtilitiesSelector from 'src/app/contents/components/utilities/store/utilities.selectors';
import * as fromUtilitiesAction from 'src/app/contents/components/utilities/store/utilities.actions';

@Component({
  selector: 'app-operation-form',
  templateUrl: './operation-form.component.html',
  styleUrls: ['./operation-form.component.scss'],
})
export class OperationFormComponent implements OnInit, OnDestroy {
  user$: Observable<any> = of(null);
  operation$: Observable<OperationId> = of();
  marcheTypes$: Observable<any> = of();
  objectifTypes$: Observable<any> = of();
  currentStep: number = 0;
  subscribe = new Subject();
  currentDate: Date = new Date(Date.now());
  isAdd: boolean = true;
  isUpdate: boolean = false;
  selectId: UtilitiesIds = UtilitiesIds.projet;
  selectVarable: UtilitiesProjetVariables = UtilitiesProjetVariables.type;
  statusList: string[] = ['En attente', 'En cours', 'Terminé'];

  @Output() cancel = new EventEmitter<boolean>(false);
  @Output() add = new EventEmitter<any>(false);
  @Output() update = new EventEmitter<Update<OperationId>>(false);

  operationForm: UntypedFormGroup = this.fb.group({});
  auths: UntypedFormControl = new UntypedFormControl('');
  acces: UntypedFormControl = new UntypedFormControl();
  roles: UntypedFormControl = new UntypedFormControl();
  auteur: UntypedFormControl = new UntypedFormControl();
  link: UntypedFormControl = new UntypedFormControl('');
  logoProgramme: UntypedFormControl = new UntypedFormControl();
  organisationsId: UntypedFormControl = new UntypedFormControl();
  type: UntypedFormControl = new UntypedFormControl();
  nature: UntypedFormControl = new UntypedFormControl();
  progression: UntypedFormControl = new UntypedFormControl();
  organisationId: UntypedFormControl = new UntypedFormControl();
  organisationCoordonneesEmail: UntypedFormControl = new UntypedFormControl();
  organisationCoordonneesFixe: UntypedFormControl = new UntypedFormControl();
  organisationCoordonneesMobile: UntypedFormControl = new UntypedFormControl();
  organisationCoordonneesWeb: UntypedFormControl = new UntypedFormControl();
  organisationEmailCommunication: UntypedFormControl = new UntypedFormControl();
  organisationGeo_adresse?: UntypedFormControl = new UntypedFormControl();
  organisationNom_raison_sociale: UntypedFormControl = new UntypedFormControl();
  organisationLogo: UntypedFormControl = new UntypedFormControl();
  organisationSiren: UntypedFormControl = new UntypedFormControl();
  organisationSiret: UntypedFormControl = new UntypedFormControl();
  equipe: UntypedFormControl = new UntypedFormControl([]);
  equipeIds: UntypedFormControl = new UntypedFormControl([]);
  ressource: UntypedFormControl = new UntypedFormControl([]);
  departementId: UntypedFormControl = new UntypedFormControl();
  departementTitle: UntypedFormControl = new UntypedFormControl();
  denomination: UntypedFormControl = new UntypedFormControl();
  financeursIds: UntypedFormControl = new UntypedFormControl([]);
  entitesIds: UntypedFormControl = new UntypedFormControl([]);
  contactsIds: UntypedFormControl = new UntypedFormControl([]);
  participantsIds: UntypedFormControl = new UntypedFormControl([]);
  soutiens: UntypedFormControl = new UntypedFormControl([]);
  status: UntypedFormControl = new UntypedFormControl('En attente');
  completed: UntypedFormControl = new UntypedFormControl(true);
  descriptionDefinition: UntypedFormControl = new UntypedFormControl();
  descriptionAdresse: UntypedFormControl = new UntypedFormControl();
  descriptionComplement: UntypedFormControl = new UntypedFormControl();
  descriptionQpv: UntypedFormControl = new UntypedFormControl();
  calendrierDateStart: UntypedFormControl = new UntypedFormControl();
  calendrierDateEnd: UntypedFormControl = new UntypedFormControl();
  calendrierDateUpdate: UntypedFormControl = new UntypedFormControl();
  calendrierDuree: UntypedFormControl = new UntypedFormControl();
  calendrierBusinessHours: UntypedFormControl = new UntypedFormControl();
  conventions: UntypedFormControl = new UntypedFormControl();
  objectifs: UntypedFormControl = new UntypedFormControl();
  objectifsType: UntypedFormControl = new UntypedFormControl();
  budget: UntypedFormControl = new UntypedFormControl();
  paramsParent: UntypedFormControl = new UntypedFormControl();
  dateCreate: UntypedFormControl = new UntypedFormControl();
  userUpdate: UntypedFormControl = new UntypedFormControl();
  updateKey: UntypedFormControl = new UntypedFormControl();
  courrier: UntypedFormControl = new UntypedFormControl([]);
  services: UntypedFormControl = new UntypedFormControl([]);
  servicesIds: UntypedFormControl = new UntypedFormControl([]);
  stats: UntypedFormControl = new UntypedFormControl();
  metadata: UntypedFormControl = new UntypedFormControl();
  adminIds: UntypedFormControl = new UntypedFormControl([]);
  operationObjectifs: UntypedFormControl = new UntypedFormControl();
  numeroMarche: UntypedFormControl = new UntypedFormControl();
  service: UntypedFormControl = new UntypedFormControl();
  objectifHours: FormControl<number> = new FormControl(0, {
    nonNullable: true,
  });
  objectifHoursTheoric: FormControl<number> = new FormControl(0, {
    nonNullable: true,
  });
  objectifPersons: FormControl<number> = new FormControl(0, {
    nonNullable: true,
  });
  objectifPersonsTheoric: FormControl<number> = new FormControl(0, {
    nonNullable: true,
  });
  progressionHours: FormControl<number> = new FormControl(0, {
    nonNullable: true,
  });
  progressionPersons: FormControl<number> = new FormControl(0, {
    nonNullable: true,
  });

  constructor(
    private userStore: Store<UserState>,
    private fb: UntypedFormBuilder,
    private operationStore: Store<OperationState>,
    private utilitiesStore: Store<UtilitiesState>
  ) {}

  ngOnInit(): void {
    this.getUser();
    this.getOperation();
    this.form();
    this.getOperationMarcheType();
    this.loadOperationMarcheType();
    this.getOperationObjectifType();
    this.loadOperationObjectifType();
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getUser(): void {
    this.user$ = this.userStore.select(fromUserSelector.user);
  }

  getOperation(): void {
    this.operation$ = this.operationStore.select(
      fromOperationSelector.operation
    );
  }
  getOperationMarcheType(): void {
    this.marcheTypes$ = this.utilitiesStore.select(
      fromUtilitiesSelector.operationMarcheTypes
    );
  }

  loadOperationMarcheType(): void {
    this.utilitiesStore.dispatch(
      fromUtilitiesAction.loadOperationMarcheTypes()
    );
  }

  //OBJECTIFS TYPE
  getOperationObjectifType(): void {
    this.objectifTypes$ = this.utilitiesStore.select(
      fromUtilitiesSelector.operationObjectifTypes
    );
  }

  loadOperationObjectifType(): void {
    this.utilitiesStore.dispatch(
      fromUtilitiesAction.loadOperationObjectifTypes()
    );
  }

  form(): void {
    this.formInit();
    this.formCreate();
    this.formSet();
    this.onSelectAvancementt();
  }
  formInit(): void {
    this.auths = new UntypedFormControl('');
    this.acces = new UntypedFormControl();
    this.roles = new UntypedFormControl();
    this.auteur = new UntypedFormControl();
    this.link = new UntypedFormControl('');
    this.logoProgramme = new UntypedFormControl();
    this.organisationsId = new UntypedFormControl();
    this.type = new UntypedFormControl('', Validators.required);
    this.nature = new UntypedFormControl();
    this.progression = new UntypedFormControl();
    this.organisationId = new UntypedFormControl();
    this.organisationCoordonneesEmail = new UntypedFormControl();
    this.organisationCoordonneesFixe = new UntypedFormControl();
    this.organisationCoordonneesMobile = new UntypedFormControl();
    this.organisationCoordonneesWeb = new UntypedFormControl();
    this.organisationEmailCommunication = new UntypedFormControl();
    this.organisationGeo_adresse = new UntypedFormControl();
    this.organisationNom_raison_sociale = new UntypedFormControl();
    this.organisationLogo = new UntypedFormControl();
    this.organisationSiren = new UntypedFormControl();
    this.organisationSiret = new UntypedFormControl();
    this.equipe = new UntypedFormControl([]);
    this.equipeIds = new UntypedFormControl([]);
    this.ressource = new UntypedFormControl([]);
    this.departementId = new UntypedFormControl();
    this.departementTitle = new UntypedFormControl();
    this.denomination = new UntypedFormControl('', Validators.required);
    this.financeursIds = new UntypedFormControl([]);
    this.entitesIds = new UntypedFormControl([]);
    this.contactsIds = new UntypedFormControl([]);
    this.participantsIds = new UntypedFormControl([]);
    this.soutiens = new UntypedFormControl([]);
    this.status = new UntypedFormControl('En attente');
    this.completed = new UntypedFormControl(false, Validators.required);
    this.descriptionDefinition = new UntypedFormControl();
    this.descriptionAdresse = new UntypedFormControl();
    this.descriptionComplement = new UntypedFormControl();
    this.descriptionQpv = new UntypedFormControl();
    this.calendrierDateStart = new UntypedFormControl(
      this.currentDate,
      Validators.required
    );
    this.calendrierDateEnd = new UntypedFormControl(
      add(this.currentDate, { months: 12 }),
      Validators.required
    );
    this.calendrierDateUpdate = new UntypedFormControl();
    this.calendrierDuree = new UntypedFormControl();
    this.calendrierBusinessHours = new UntypedFormControl();
    this.conventions = new UntypedFormControl();
    this.objectifs = new UntypedFormControl(0, Validators.required);
    this.objectifsType = new UntypedFormControl('', Validators.required);
    this.budget = new UntypedFormControl();
    this.paramsParent = new UntypedFormControl();
    this.dateCreate = new UntypedFormControl();
    this.userUpdate = new UntypedFormControl();
    this.updateKey = new UntypedFormControl();
    this.courrier = new UntypedFormControl([]);
    this.services = new UntypedFormControl('');
    this.servicesIds = new UntypedFormControl('');
    this.stats = new UntypedFormControl();
    this.metadata = new UntypedFormControl();
    this.adminIds = new UntypedFormControl([]);
    this.operationObjectifs = new UntypedFormControl();
    this.numeroMarche = new UntypedFormControl();
    this.service = new UntypedFormControl();
    this.objectifHours = new FormControl(0, { nonNullable: true });
    this.objectifHoursTheoric = new FormControl(0, { nonNullable: true });
    this.objectifPersons = new FormControl(0, { nonNullable: true });
    this.objectifPersonsTheoric = new FormControl(0, { nonNullable: true });
    this.progressionHours = new FormControl(0, { nonNullable: true });
    this.progressionPersons = new FormControl(0, { nonNullable: true });
  }
  formCreate(): void {
    this.operationForm = this.fb.group({
      auths: this.auths,
      acces: this.acces,
      roles: this.roles,
      auteur: this.auteur,
      link: this.link,
      logoProgramme: this.logoProgramme,
      organisationsId: this.organisationsId,
      type: this.type,
      nature: this.nature,
      progression: this.progression,
      organisation: this.fb.group({
        id: this.organisationId,
        coordonnes: this.fb.group({
          email: this.organisationCoordonneesEmail,
          fixe: this.organisationCoordonneesFixe,
          mobile: this.organisationCoordonneesMobile,
          web: this.organisationCoordonneesWeb,
        }),
        emailCommunication: this.organisationEmailCommunication,
        geo_adresse: this.organisationGeo_adresse,
        nom_raison_sociale: this.organisationNom_raison_sociale,
        logo: this.organisationLogo,
        siren: this.organisationSiren,
        siret: this.organisationSiret,
      }),
      equipe: this.equipe,
      equipeIds: this.equipeIds,
      ressource: this.ressource,
      departement: this.fb.group({
        id: this.departementId,
        title: this.departementTitle,
      }),
      denomination: this.denomination,
      financeursIds: this.financeursIds,
      entitesIds: this.entitesIds,
      contactsIds: this.contactsIds,
      participantsIds: this.participantsIds,
      soutiens: this.soutiens,
      status: this.status,
      completed: this.completed,
      description: this.fb.group({
        definition: this.descriptionDefinition,
        adresse: this.fb.group({
          adresse: this.descriptionAdresse,
          complement: this.descriptionComplement,
          qpv: this.descriptionQpv,
        }),
        calendrier: this.fb.group({
          dateStart: this.calendrierDateStart,
          dateEnd: this.calendrierDateEnd,
          dateUpdate: this.calendrierDateUpdate,
          duree: this.calendrierDuree,
          businessHours: this.calendrierBusinessHours,
        }),
        objectifs: this.objectifs,
        objectifsType: this.objectifsType,
        conventions: this.conventions,
        budget: this.budget,
        objectifHours: this.objectifHours,
        objectifHoursTheoric: this.objectifHoursTheoric,
        objectifPersons: this.objectifPersons,
        objectifPersonsTheoric: this.objectifPersonsTheoric,
        progressionHours: this.progressionHours,
        progressionPersons: this.progressionPersons,
      }),
      params: this.fb.group({
        parent: this.paramsParent,
      }),
      dateCreate: this.dateCreate,
      userUpdate: this.userUpdate,
      updateKey: this.updateKey,
      courrier: this.courrier,
      services: this.services,
      servicesIds: this.servicesIds,
      stats: this.stats,
      metadata: this.metadata,
      adminIds: this.adminIds,
      operationObjectifs: this.operationObjectifs,
      numeroMarche: this.numeroMarche,
      service: this.service,
    });
  }
  formSet(): void {
    this.operation$
      .pipe(takeUntil(this.subscribe))
      .subscribe((operation: OperationId): any => {
        if (!operation) {
          this.operationForm.reset();
          this.isAdd = true;
          this.isUpdate = false;
          return null;
        } else {
          this.formUpdate(operation);
        }
      });
  }
  formUpdate(operation: OperationId): void {
    this.operationForm.patchValue({
      auths: operation?.auths,
      acces: operation?.acces,
      roles: operation?.roles,
      auteur: operation?.auteur,
      link: operation?.link,
      logoProgramme: operation?.logoProgramme,
      organisationsId: operation?.organisationsId,
      type: operation?.type,
      progression: operation?.progression,
      organisation: {
        id: operation.organisation.id,
        coordonnes: {
          email: operation.organisation?.coordonnees?.email,
          fixe: operation.organisation?.coordonnees?.fixe,
          mobile: operation.organisation?.coordonnees?.mobile,
          web: operation.organisation?.coordonnees?.web,
        },
        emailCommunication: operation.organisation?.emailCommunication,
        geo_adresse: operation.organisation?.geo_adresse,
        nom_raison_sociale: operation.organisation?.nom_raison_sociale,
        logo: operation.organisation?.logo,
        siren: operation.organisation?.siren,
        siret: operation.organisation?.siret,
      },
      equipe: operation?.equipe,
      equipeIds: operation?.equipeIds,
      ressource: operation?.ressource,
      departement: {
        id: operation?.departement?.id,
        title: operation?.departement?.title,
      },
      denomination: operation?.denomination,
      financeursIds: operation?.financeursIds,
      entitesIds: operation?.entitesIds,
      contactsIds: operation?.contactsIds,
      participantsIds: operation?.participantsIds,
      soutiens: operation?.soutiens,
      status: operation?.status,
      completed: operation?.completed,
      description: {
        definition: operation.description?.definition,
        adresse: {
          adresse: operation.description?.adresse?.adresse,
          complement: operation.description?.adresse?.complement,
          qpv: operation.description?.adresse?.qpv,
        },
        calendrier: {
          dateStart: operation.description.calendrier?.dateStart
            ? fromUnixTime(
                operation.description.calendrier.dateStart['seconds']
              )
            : null,
          dateEnd: operation.description.calendrier?.dateEnd
            ? fromUnixTime(
                operation.description.calendrier.dateStart['seconds']
              )
            : null,
          dateUpdate: operation.description.calendrier?.dateUpdate
            ? fromUnixTime(
                operation.description.calendrier.dateStart['seconds']
              )
            : null,
          duree: operation.description.calendrier?.duree,
          businessHours: operation.description.calendrier?.businessHours,
        },
        objectifs: operation.description.objectifs,
        conventions: operation?.description?.conventions,
        budget: operation?.description?.budget,
      },
      params: {
        parent: operation?.params?.parent,
      },
      dateCreate: operation?.dateCreate,
      userUpdate: operation?.userUpdate,
      updateKey: operation?.updateKey,
      courrier: operation?.courrier,
      services: operation?.services,
      servicesIds: operation?.servicesIds,
      stats: operation?.stats,
      metadata: operation?.metadata,
      adminIds: operation?.adminIds,
      operationObjectifs: operation?.objectifs,
    });

    this.isAdd = false;
    this.isUpdate = true;
  }

  onSelectDateStart(date: Date): void {
    const time = Timestamp.fromDate(date);
    this.operationForm.patchValue({
      description: {
        calendrier: {
          dateStart: time,
          dateUpdate: time,
        },
      },
    });
  }

  onSelectDateEnd(date: Date): void {
    const time = Timestamp.fromDate(date);
    const dateStart = this.operationForm.get('dateStart')?.value;
    const start = dateStart ? fromUnixTime(dateStart['seconds']) : null;
    const duration: number = start ? differenceInCalendarDays(start, date) : 0;

    this.operationForm.patchValue({
      description: {
        calendrier: {
          dateEnd: time,
          duree: duration,
        },
      },
    });
  }
  onSelectDeplacement(departement: any): void {
    this.operationForm.patchValue({
      services: departement,
      servicesIds: departement.id,
    });
  }
  onSelectAvancementt(): void {
    this.operationForm
      .get('status')
      ?.valueChanges.subscribe((value: string) => {
        if (!value) return;

        if (value === 'terminé') {
          this.operationForm.patchValue({ completed: true });
          return;
        }

        this.operationForm.patchValue({ completed: false });
      });
  }

  onSelectType(type: string): void {
    this.operationForm.patchValue({
      type: type,
    });
  }

  onAddAdresse(adress: any): void {
    if (!adress) return;
    this.operationForm.patchValue({
      description: {
        adresse: {
          adresse: adress,
        },
      },
    });
  }

  onAddAdresseComplete(complement: any): void {
    if (!complement) return;
    this.operationForm.patchValue({
      description: {
        adresse: {
          complement: complement,
        },
      },
    });
  }

  onPrevStep(): void {
    this.currentStep = this.currentStep === 0 ? 0 : this.currentStep - 1;
  }

  onNextStep(): void {
    this.currentStep = this.currentStep === 2 ? 2 : this.currentStep + 1;
  }

  onAdd(): void {
    if (!this.operationForm.valid) return;
    const operation = this.operationForm.value;
    const denomination = operation.denomination.toUpperCase();
    operation.denomination = denomination;
    this.add.emit(operation);
  }

  onUpdate(operation: OperationId): void {
    if (!operation || !this.operationForm.valid) return;
    const values = this.operationForm.value;
    const operartionChanges: Update<OperationId> = {
      id: operation.id,
      changes: {
        ...operation,
        ...values,
      },
    };
    this.update.emit(operartionChanges);
  }

  onCancel(): void {
    this.operationForm.reset();
    this.isAdd = true;
    this.isUpdate = false;
    this.cancel.emit(true);
  }
}
