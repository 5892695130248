import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  @Input() user: any = null;
  @Input() url: string = '';
  @Input() theme: string = '';
  @Output() selector = new EventEmitter<any>(false);
  constructor() {}

  ngOnInit(): void {}

  onSelect(event: any): void {}
}
