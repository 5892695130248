import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { OperationFicheCandidatNote } from './operation-fiche-candidat-note.model';
import * as OperationFicheCandidatNoteActions from './operation-fiche-candidat-note.actions';

export const operationFicheCandidatNotesFeatureKey =
  'operationFicheCandidatNotes';

export interface OperationFicheCandidatNoteState
  extends EntityState<OperationFicheCandidatNote> {
  loading: boolean;
  error: any;
  success: string;
  note: any;
  view: string;
}

export const adapter: EntityAdapter<OperationFicheCandidatNote> =
  createEntityAdapter<OperationFicheCandidatNote>();

export const initialState: OperationFicheCandidatNoteState =
  adapter.getInitialState({
    loading: false,
    error: undefined,
    success: '',
    note: undefined,
    view: 'display',
  });

export const reducer = createReducer(
  initialState,

  /**
   * Indicateur de chargement
   */
  on(
    OperationFicheCandidatNoteActions.loadingOperationFicheCandidatNotes,
    (state, action) => {
      return {
        ...state,
        loading: action.loading,
      };
    }
  ),

  /**
   * Changer l'affichage des notes en cours
   */
  on(
    OperationFicheCandidatNoteActions.setViewOperationFicheCandidatNotes,
    (state, action) => {
      return {
        ...state,
        view: action.view,
      };
    }
  ),

  /**
   * Charger les notes du candidat
   */
  on(
    OperationFicheCandidatNoteActions.loadOperationFicheCandidatNotes,
    (state, action) => adapter.removeAll(state)
  ),
  on(
    OperationFicheCandidatNoteActions.loadOperationFicheCandidatNotesSuccess,
    (state, action) => adapter.setAll(action.notes, state)
  ),
  on(
    OperationFicheCandidatNoteActions.loadOperationFicheCandidatNotesFailure,
    (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
  ),

  /**
   * Charger une note du candidat
   */
  on(
    OperationFicheCandidatNoteActions.loadOperationFicheCandidatNote,
    (state, action) => {
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    }
  ),
  on(
    OperationFicheCandidatNoteActions.loadOperationFicheCandidatNoteSuccess,
    (state, action) => {
      return {
        ...state,
        loading: false,
        error: undefined,
      };
    }
  ),
  on(
    OperationFicheCandidatNoteActions.loadOperationFicheCandidatNoteFailure,
    (state, action) => {
      return {
        ...state,
        note: undefined,
        loading: false,
        error: action.error,
      };
    }
  ),

  /**
   * Ajouter une nouvelle note
   */
  on(
    OperationFicheCandidatNoteActions.addOperationFicheCandidatNoteSuccess,
    (state, action) => adapter.addOne(action.note, state)
  ),

  on(
    OperationFicheCandidatNoteActions.addOperationFicheCandidatNoteFailure,
    (state, action) => {
      return {
        ...state,
        note: undefined,
        loading: false,
        error: action.error,
      };
    }
  ),

  /**
   * Modifier la note du candidat
   */
  on(
    OperationFicheCandidatNoteActions.updateOperationFicheCandidatNote,
    (state, action) => {
      return {
        ...state,
        note: action.note.changes,
        loading: false,
        error: undefined,
        success: '',
      };
    }
  ),

  on(
    OperationFicheCandidatNoteActions.updateOperationFicheCandidatNote,
    (state, action) => adapter.updateOne(action.note, state)
  ),
  on(
    OperationFicheCandidatNoteActions.updateOperationFicheCandidatNoteSuccess,
    (state, action) => {
      return {
        ...state,
        note: undefined,
        loading: false,
        error: undefined,
        success: action.success,
      };
    }
  ),

  /**
   * Supprimer une note
   */
  on(
    OperationFicheCandidatNoteActions.deleteOperationFicheCandidatNote,
    (state, action) => adapter.removeOne(action.noteId, state)
  ),
  on(
    OperationFicheCandidatNoteActions.deleteOperationFicheCandidatNoteSuccess,
    (state, action) => {
      return {
        ...state,
        note: undefined,
        loading: false,
        error: undefined,
        success: action.success,
      };
    }
  ),

  /**
   * Supprimer toutes les notes du candidat
   */
  on(
    OperationFicheCandidatNoteActions.deleteOperationFicheCandidatNotes,
    (state) => adapter.removeAll(state)
  ),
  on(
    OperationFicheCandidatNoteActions.deleteOperationFicheCandidatNoteSuccess,
    (state, action) => {
      return {
        ...state,
        note: undefined,
        loading: false,
        error: undefined,
        success: action.success,
      };
    }
  ),
  on(
    OperationFicheCandidatNoteActions.deleteOperationFicheCandidatNotesFailure,
    (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
  )
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
