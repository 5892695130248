import { Observable, of } from 'rxjs';
import { ParticipantId } from './../../store/participant.model';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserState } from 'src/app/components/user/store/user.reducer';
import { ParticipantState } from '../../store/participant.reducer';
import * as fromParticipantAction from '../../store/participant.actions';
import * as fromParticipantSelector from '../../store/participant.selectors';
import { SideNavigationState } from 'src/app/components/side-navigation/store/side-navigation.reducer';
import * as fromSideNavigationAction from 'src/app/components/side-navigation/store/side-navigation.actions';
import * as fromSideNavigationSelector from 'src/app/components/side-navigation/store/side-navigation.selectors';
import { SideNavigation } from 'src/app/components/side-navigation/store/side-navigation.model';

@Component({
  selector: 'app-participant-item-header',
  templateUrl: './participant-item-header.component.html',
  styleUrls: ['./participant-item-header.component.scss'],
})
export class ParticipantItemHeaderComponent implements OnInit {
  participant$: Observable<ParticipantId | any> = of(null);
  title: string = 'PARTICIPANT';

  constructor(
    private userStore: Store<UserState>,
    private participantStore: Store<ParticipantState>,
    private sideNavigationStore: Store<SideNavigationState>
  ) {}

  ngOnInit(): void {
    this.getParticipant();
  }

  getParticipant(): void {
    this.participant$ = this.participantStore.select(
      fromParticipantSelector.participant
    );
  }

  onSelectAction(event: string, participant: ParticipantId): void {
    if (!event || !participant || !participant.id) return;

    switch (event) {
      case 'details':
        this.onChangeNavigation('participant_details', participant);

        break;
      case 'dashboard':
        this.onChangeNavigation('participant_dashboard', participant);

        break;
      case 'update':
        this.onChangeNavigation('participant_update', participant);

        break;
      case 'exportPDF':
        this.onChangeNavigation('participant_exportPDF', participant);

        break;
      case 'delete':
        this.onDelete(participant);

        break;
      default:
        break;
    }
  }

  onChangeNavigation(
    navigationEvent: string,
    participant: ParticipantId
  ): void {
    const navigation: SideNavigation = {
      type: 'side',
      name: navigationEvent,
      item: participant,
    };

    this.sideNavigationStore.dispatch(
      fromSideNavigationAction.loadSideNavigation({ navigation })
    );
  }

  onDelete(participant: ParticipantId): void {
    if (!participant?.id) return;
    this.participantStore.dispatch(
      fromParticipantAction.deleteParticipant({ id: participant.id })
    );
  }
}
