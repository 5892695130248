<div
  [class]="
    type === 'success'
      ? 'text-xs font-bold  text-green-900 w-20 p-4 m-4 rounded-xl bg-green-200'
      : type === 'process'
      ? 'text-xs font-bold  text-blue-900 w-20 p-4 m-4 rounded-xl bg-blue-200'
      : type === 'warning'
      ? 'text-xs font-bold  text-red-900 w-20 p-4 m-4 rounded-xl bg-red-200'
      : 'text-xs font-bold  text-slate-900 w-20 p-4 m-4 rounded-xl bg-slate-200'
  "
>
  {{ text }}
</div>
