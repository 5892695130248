import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-operation-dashboard-header',
  templateUrl: './operation-dashboard-header.component.html',
  styleUrls: ['./operation-dashboard-header.component.scss'],
})
export class OperationDashboardHeaderComponent implements OnInit {
  @Input() dashboard: any = null;
  @Input() operation: any = null;

  moduleColor: string = '#062949';
  moduleIcon: string = 'ssid_chart';
  actionColor: string = '#126782';
  actionIcon: string = 'dashboard';
  missionColorGreen: string = '#59CD90';

  missionColor: string = '#DBE9EE';
  missionIcon: string = 'topic';

  entiteColorYellow: string = '#FFB703';
  entiteColor: string = '#DBE9EE';
  entiteIcon: string = 'domain';

  interventionColor: string = '#FD9E02';
  interventionIcon: string = 'person';

  participantColorOrange: string = '#FB8500';
  participantColor: string = '#DBE9EE';

  participantIcon: string = 'people';

  @Output() select: EventEmitter<any> = new EventEmitter(false);

  constructor() {}

  ngOnInit(): void {}

  onSelect(type: string): void {
    this.select.emit(type);
  }
}
