import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError, exhaustMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as fromOperationDashboardAction from './operation-dashboard.actions';
import { OperationStatistiquesService } from '../services/operation-statistiques.service';

@Injectable()
export class OperationDashboardEffects {
  loadOperationDashboard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationDashboardAction.loadOperationDashboard),
      mergeMap((action) =>
        this.operationDashboardService
          .getOneStatistiques(action.operationId)
          .pipe(
            map((dashboard: any) =>
              fromOperationDashboardAction.loadOperationDashboardSuccess({
                dashboard,
              })
            ),
            catchError((error) =>
              of(
                fromOperationDashboardAction.loadOperationDashboardFailure({
                  error,
                })
              )
            )
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private operationDashboardService: OperationStatistiquesService
  ) {}
}
