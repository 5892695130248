import { Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  doc,
  docSnapshots,
  collectionChanges,
  getDoc,
  addDoc,
  setDoc,
  deleteDoc,
  serverTimestamp,
  DocumentData,
  Timestamp,
  query,
  where,
  arrayUnion,
  arrayRemove,
  collectionSnapshots,
  CollectionReference,
  DocumentReference,
  getDocs,
  documentId,
} from '@angular/fire/firestore';
import { Observable, of, from, EMPTY } from 'rxjs';
import { switchMap, mergeMap, map, catchError } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { UserState } from '../components/user/store/user.reducer';
import * as fromUserSelector from '../components/user/store/user.selectors';
import { v4 as uuidV4 } from 'uuid';
import { format, fromUnixTime } from 'date-fns';
import { Apollo, gql } from 'apollo-angular';
import { sum } from 'lodash';

@Injectable({
  providedIn: 'any',
})
export class ParticipantProjetproService {
  GET_STATISTIQUES = gql`
    query MetierStatistiques(
      $code: String!
      $areaCode: String!
      $fapCode: String!
      $age: Int
    ) {
      metierStatistiques(
        code: $code
        areaCode: $areaCode
        fapCode: $fapCode
        age: $age
      ) {
        code
        areaCode
        statistiques_offres_retour {
          SEASONAL
          NB_OFFER_END_MONTH
          NB_APPLICATION_END_MONTH
          RICHER_CATCHMENT_AREA_CODE
          RICHER_CATCHMENT_AREA_NAME
          TENSION_RATIO
          NB_APPLICATION_LAST_WEEK
          NB_OFFER_LAST_WEEK
          AREA_NAME
          ROME_PROFESSION_CARD_NAME
        }
        statistiques_types_contrats {
          OFFERS_PERCENT
          ROME_PROFESSION_CARD_NAME
          CONTRACT_TYPE_NAME
          CONTRACT_TYPE_CODE
          NB_OFFERS
          AREA_NAME
        }
        statistiques_reprise_emploi {
          APPLICATION_TYPE_CODE
          APPLICATION_TYPE_NAME
          RECRUT_PERCENT
          APPLICATION_TYPE_ORDER
        }
        besoin_main_dOeuvre {
          PROFESSION_FAMILY_CODE
          DEPARTEMENT_NAME
          CATCHMENT_AREA_NAME
          PROFESSION_FAMILY_NAME
          NB_DIFF_RECRUT_PROJECTS
          REGION_LIB
          REGION_CODE
          CATCHMENT_AREA_CODE
          NB_RECRUT_PROJECTS
        }
        statistiques_salaires {
          MAXIMUM_SALARY
          AREA_NAME
          MINIMUM_SALARY
          AGE_GROUP_NAME
          PCS_PROFESSION_NAME
          PCS_PROFESSION_CODE
        }
      }
    }
  `;

  GET_CONTACTS_PROJET_PROFESSIONNEL = gql`
    query FormationOnisep($libelle: String!) {
      formationOnisep(libelle: $libelle) {
        certif_info_ci_identifiant
        ci_niveau_europeen
        ci_intitule_type_diplome
        ci_intitule
        ci_certifiant
        ci_code_rncp
        ci_code_scolarite
        rncp_intitule
        rncp_type_enregistrement
        v_formation_diplome_libelle_long
        ideo_identifiant_formation
        ideo_libelle_complet_formation
        statut
      }
    }
  `;

  constructor(
    private db: Firestore,
    private fns: Functions,
    private userStore: Store<UserState>,
    private apollo: Apollo
  ) {}

  getAll(participantId: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) {
            return of([]);
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/participants/${participantId}/projetsprofessionnels`
            );

            const changes = collectionSnapshots(dbCollection).pipe(
              map((changes) => {
                return changes.map((snap) => {
                  const data = snap.data();
                  const id = snap.id;
                  return { id, ...data };
                });
              })
            );

            return changes;
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  getStatistiques(
    codeRome: string,
    areaCode: string,
    fapCode: string,
    age: number
  ): Observable<any> {
    if (!codeRome || !areaCode || !fapCode || !age) return of(null);
    return this.apollo
      .watchQuery<any>({
        query: this.GET_STATISTIQUES,
        variables: {
          code: codeRome,
          areaCode: areaCode,
          fapCode: fapCode,
          age: age ? age : null,
        },
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  getOne(participantId: string, id: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user && !id) {
            return of(null);
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/participants/${participantId}/projetsprofessionnels`
            );

            const documentRef = doc(dbCollection, id);
            return from(
              getDoc(documentRef)
                .then((snap) => {
                  const data = snap.data();
                  const id = snap.id;
                  return { id, ...data };
                })
                .catch((error) => error)
            ).pipe(mergeMap((res: any) => of(res)));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  addOne(participantId: string, projetpro: any): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user && !projetpro && !participantId) {
            return of(null);
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/participants/${participantId}/projetsprofessionnels`
            );

            const competencesCleDeBaseSize: number =
              projetpro.appellation.competencesCleDeBaseSize;
            const competencesCleSpecifiquesSize: number =
              projetpro.appellation.competencesCleSpecifiquesSize;
            const accesSize: number = projetpro.accesSize;
            const skills: number = projetpro.softskills.skills.filter(
              (skill: any) => Number(skill.score) >= 0.5
            )?.length;

            const mobilitesMetiersSize: number =
              projetpro.mobilitesProchesVersMetiers.length;

            const formations = [];
            const experiencesSize = 3;

            const objectif: number = sum([
              accesSize,
              competencesCleDeBaseSize,
              competencesCleSpecifiquesSize,
              skills,
              experiencesSize,
              mobilitesMetiersSize,
            ]);
            const tauxEmployability: number = 0;

            const document = {
              ...projetpro,

              user: user,
              userUpdate: user,
              dateUpdate: serverTimestamp(),
            };

            return from(
              addDoc(dbCollection, document)
                .then((docRef) => {
                  if (docRef.id) {
                    return of(
                      `Projet professionnel ${projetpro?.appellation?.libelle} ajouté`
                    );
                  } else {
                    return of(null);
                  }
                })
                .catch((error) => of(error))
            ).pipe(mergeMap((res) => res));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  updateOne(participantId: string, projetpro: Partial<any>): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user && !projetpro) {
            return of(null);
          } else {
            const dbDocument = doc(
              this.db,
              `organisations/${user.organisation.id}/participants/${participantId}/projetsprofessionnels/${projetpro?.id}`
            );

            return from(
              setDoc(dbDocument, projetpro, { merge: true })
                .then(() =>
                  of(
                    `Projet professionnel ${projetpro?.appellation?.libelle} modifié`
                  )
                )
                .catch((error) => of(error))
            ).pipe(mergeMap((res) => res));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  deleteOne(participantId: string, id: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user && !id) {
            return of(null);
          } else {
            const dbDocument = doc(
              this.db,
              `organisations/${user.organisation.id}/participants/${participantId}/projetsprofessionnels/${id}`
            );

            return from(
              deleteDoc(dbDocument)
                .then(() => of(`Projet professionnel supprimé`))
                .catch((error) => of(error))
            ).pipe(mergeMap((res) => res));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  deleteAll(participantId: string, ids: string[]): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) {
            return of(null);
          } else {
            //CALL HTTP

            return of(null);
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }
}
