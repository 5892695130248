import { EMPTY, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { EntiteState } from '../components/entite/store/entite.reducer';
import * as fromEntiteAction from '../components/entite/store/entite.actions';
import * as fromEntiteSelector from '../components/entite/store/entite.selectors';
import { EntiteSMALLid } from '../components/entite/store/entite.model';

@Injectable({
  providedIn: 'any',
})
export class EntitesResolverService {
  constructor(
    private entiteStore: Store<EntiteState>,
    private router: Router
  ) {}

  resolve(
    route: ActivatedRouteSnapshot
  ):
    | Observable<EntiteSMALLid[]>
    | Promise<EntiteSMALLid[] | any>
    | EntiteSMALLid[]
    | any {
    let find = this.loadEntites().toPromise();

    return find
      .then(() => {
        return this.getEntites();
      })
      .catch(() => {
        this.router.navigate(['/entites']);
        return EMPTY;
      });
  }

  getEntites(): Observable<EntiteSMALLid[] | any> {
    return this.entiteStore.select(fromEntiteSelector.entites);
  }

  loadEntites(): Observable<any> {
    return of(this.entiteStore.dispatch(fromEntiteAction.loadEntites()));
  }
}
