import { Pipe, PipeTransform } from '@angular/core';
import { format, fromUnixTime, isDate } from 'date-fns';
import { fr } from 'date-fns/locale';

@Pipe({
  name: 'eventsByDate',
})
export class EventsByDatePipe implements PipeTransform {
  transform(values: any[], date: Date): number {
    if (!values || !values.length || !isDate(date)) {
      return 0;
    }
    const month = format(date, 'MM/yyyy', { locale: fr });
    const events = values.filter(
      (el) =>
        format(fromUnixTime(el.dateStart['seconds']), 'MM/yyyy', {
          locale: fr,
        }) === month
    );

    return events.length;
  }
}
