import { Injectable } from '@angular/core';
import { Firestore, doc, getDoc } from '@angular/fire/firestore';
import { Observable, of, from, EMPTY } from 'rxjs';
import { switchMap, mergeMap, map, catchError } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { UserState } from '../components/user/store/user.reducer';
import * as fromUserSelector from '../components/user/store/user.selectors';
import {
  toUpper,
  upperFirst,
  capitalize,
  forEach,
  camelCase,
  isUndefined,
  sum,
  compact,
  groupBy,
  isNumber,
  flattenDeep,
} from 'lodash';
import {
  fromUnixTime,
  format,
  formatDistance,
  formatDistanceToNow,
  formatDistanceStrict,
  differenceInYears,
  differenceInHours,
  sub,
  eachYearOfInterval,
} from 'date-fns';
import { OperationState } from '../components/operation/store/operation.reducer';
import * as fromOperationAction from '../components/operation/store/operation.actions';
import * as fromOperationSelector from '../components/operation/store/operation.selectors';
import { OperationId } from '../components/operation/store/operation.model';
import { NzMessageService } from 'ng-zorro-antd/message';
import { fr } from 'date-fns/locale';
// var FileSaver = require('file-saver');
import { saveAs } from 'file-saver';
declare const require: any;
const { jsPDF } = require('jspdf');
require('jspdf-autotable');

@Injectable({
  providedIn: 'any',
})
export class OperationExportPdfService {
  constructor(
    private db: Firestore,
    private userStore: Store<UserState>,
    private operationStore: Store<OperationState>,
    private message: NzMessageService
  ) {}

  onExportPDF(operation: OperationId): Observable<any> {
    try {
      return this.userStore.pipe(
        select(fromUserSelector.user),
        switchMap((user) => {
          if (!user || !operation) {
            return `Erreur d'exportation. Veuillez renouveller l'action`;
          }

          const dbDocument = doc(
            this.db,
            `organisations/${user.organisation.id}/operations/${operation.id}/statistiques/${operation.id}`
          );

          const statistiquesDoc = getDoc(dbDocument).then((snap) =>
            snap.data()
          );

          const checkCrossOrigin = false;

          return from(
            statistiquesDoc.then((statistiques: any): any => {
              if (!statistiques) return `Aucune statistique trouvée`;
              const xhr = new XMLHttpRequest();
              xhr.open('GET', user.organisation.logo);
              xhr.responseType = 'blob';
              xhr.send();
              xhr.addEventListener('load', () => {
                const reader = new FileReader();
                reader.readAsDataURL(xhr.response);
                reader.addEventListener('loadend', () => {
                  const logo = reader.result;
                  if (logo) {
                    const currentDate = format(
                      new Date(Date.now()),
                      'dd/MM/yyyy',
                      {
                        locale: fr,
                      }
                    );

                    const { description } = operation;
                    const { adresse, calendrier, definition, objectifs } =
                      description;

                    const start = format(
                      fromUnixTime(calendrier.dateStart['seconds']),
                      'dd/MM/yyyy',
                      { locale: fr }
                    );

                    const end = format(
                      fromUnixTime(calendrier.dateEnd['seconds']),
                      'dd/MM/yyyy',
                      { locale: fr }
                    );

                    const modules =
                      statistiques &&
                      statistiques?.modules &&
                      statistiques?.modules?.data?.length
                        ? statistiques.modules.data
                        : [];
                    const modulesSize = modules.length;
                    const actions =
                      statistiques &&
                      statistiques?.actions &&
                      statistiques?.actions?.data?.length
                        ? statistiques.actions.data
                        : [];
                    const actionsSize = actions.length;
                    const participants =
                      statistiques &&
                      statistiques?.participants &&
                      statistiques?.participants?.data?.length
                        ? statistiques.participants.data
                        : [];
                    const participantsSize = participants.length;
                    const entites =
                      statistiques &&
                      statistiques?.entites &&
                      statistiques?.entites?.data?.length
                        ? statistiques.entites.data
                        : [];
                    const entitesSize = entites.length;
                    const contacts =
                      statistiques &&
                      statistiques?.contacts &&
                      statistiques?.contacts?.data?.length
                        ? statistiques.contacts.data
                        : [];
                    const contactsSize = contacts.length;

                    const titleStyle = {
                      font: 'helvetica',
                      halign: 'center',
                      fontStyle: 'bold',
                      fillColor: [0, 55, 102],
                      textColor: [255, 255, 255],
                    };

                    const subtitleStyle = {
                      font: 'helvetica',
                      halign: 'center',
                      fontStyle: 'normal',
                      fillColor: [0, 55, 102],
                      textColor: [255, 255, 255],
                    };

                    const rowStyle = {
                      fontStyle: 'normal',
                      fillColor: [217, 217, 217],
                      textColor: [0, 0, 0],
                    };

                    const rowStyleCenter = {
                      fontStyle: 'normal',
                      halign: 'center',
                      fillColor: [217, 217, 217],
                      textColor: [0, 0, 0],
                    };

                    const pdfSize = 'a4';
                    const doc = new jsPDF('1', 'pt', pdfSize);
                    doc.page = 1;
                    doc.setProperties({
                      title: `Bilan ${operation.denomination}`,
                      type: `Bilan qualitatif`,
                      date: currentDate,
                      author: user.displayName,
                      keywords: `bilan, module, qualitatif, ${operation.denomination}`,
                    });

                    const subtitle = {
                      fontStyle: 'normal',
                      halign: 'center',
                      fillColor: [217, 217, 217],
                      textColor: [0, 0, 0],
                      cellPadding: 4,
                    };

                    const headEnsemble = [
                      [
                        {
                          content: `Projet : ${operation.denomination.toUpperCase()}`,
                          colSpan: 6,
                          styles: {
                            font: 'helvetica',
                            halign: 'center',
                            fontStyle: 'bold',
                            fillColor: [0, 55, 102],
                            textColor: [255, 255, 255],
                          },
                        },
                      ],
                      [
                        {
                          content: `Démarrage : ${start} - Échéance : ${end}`,
                          colSpan: 6,
                          styles: {
                            halign: 'center',
                            fontStyle: 'normal',
                            fontSize: 8,
                            fillColor: [255, 255, 255],
                          },
                        },
                      ],
                      [
                        {
                          content: `Adresse : ${
                            operation.description?.adresse?.adresse?.label
                              ? operation.description.adresse?.adresse?.label
                              : ''
                          }`,
                          colSpan: 6,
                          styles: {
                            halign: 'center',
                            fontStyle: 'normal',
                            fontSize: 8,
                            fillColor: [255, 255, 255],
                          },
                        },
                      ],
                      [
                        {
                          content: `Description : ${operation.description.definition}`,
                          colSpan: 6,
                          styles: {
                            halign: 'center',
                            fontStyle: 'normal',
                            fontSize: 8,
                            fillColor: [255, 255, 255],
                          },
                        },
                      ],

                      [
                        {
                          content: 'Modules',
                          styles: subtitle,
                        },
                        {
                          content: 'Actions',
                          styles: subtitle,
                        },
                        {
                          content: 'Entités',
                          styles: subtitle,
                        },
                        {
                          content: 'Collaborateurs',
                          styles: subtitle,
                        },
                        {
                          content: 'Participants',
                          styles: subtitle,
                        },
                        {
                          content: 'Progression',
                          styles: subtitle,
                        },
                      ],
                    ];

                    const bodyEnsemble = [
                      [
                        {
                          content: modulesSize > 0 ? modulesSize : 'Aucune',
                          styles: {
                            halign: 'center',
                            fontStyle: 'bold',
                          },
                        },
                        {
                          content: actionsSize > 0 ? actionsSize : 'Aucune',
                          styles: {
                            halign: 'center',
                            fontStyle: 'bold',
                          },
                        },
                        {
                          content: entitesSize > 0 ? entitesSize : 'Aucune',
                          styles: {
                            halign: 'center',
                            fontStyle: 'bold',
                          },
                        },
                        {
                          content: contactsSize > 0 ? contactsSize : 'Aucune',
                          styles: {
                            halign: 'center',
                            fontStyle: 'bold',
                          },
                        },
                        {
                          content:
                            participantsSize > 0 ? participantsSize : 'Aucun',
                          styles: {
                            halign: 'center',
                            fontStyle: 'bold',
                          },
                        },
                        {
                          content: operation?.progression
                            ? operation.progression * 100 + '%'
                            : 'Aucune',
                          styles: {
                            halign: 'center',
                            fontStyle: 'bold',
                            textColor:
                              operation?.progression &&
                              operation?.progression >= 0.5 &&
                              operation?.progression <= 0.99
                                ? [255, 236, 61]
                                : operation?.progression &&
                                  operation?.progression >= 1
                                ? [115, 209, 61]
                                : [255, 77, 79],
                          },
                        },
                      ],
                    ];

                    doc.autoTable({
                      theme: 'grid',
                      head: headEnsemble,
                      body: bodyEnsemble,
                      /* foot: foot, */
                      styles: {
                        cellPadding: 3,
                        fontSize: 10,
                        textColor: [0, 0, 0],
                      },
                      headStyles: {
                        /* fillColor: [0, 0, 0], textColor: [255, 255, 255] */
                      },

                      columnStyles: {
                        0: { cellWidth: 85 },
                        1: { cellWidth: 85 },
                        2: { cellWidth: 85 },
                        3: { cellWidth: 86 },
                        4: { cellWidth: 86 },
                        5: { cellWidth: 85 },
                      },

                      bodyStyles: {
                        0: { fontStyle: 'bold' },
                      },
                      didDrawPage: () => {
                        /* PAGE HEADER */
                        // Author
                        doc.setFont('helvetica');
                        doc.setFont('normal');

                        doc.setFontSize(10);
                        doc.setFont('bold');
                        doc.text(
                          `${user.organisation.nom_raison_sociale}`,
                          40,
                          40
                        );
                        doc.setFontSize(7);
                        doc.setFont('normal');
                        doc.text(`Siret : ${user.organisation.siret}`, 40, 50);
                        doc.text(
                          `Adresse : ${user.organisation.geo_adresse}`,
                          40,
                          58
                        );

                        doc.setFontSize(7);
                        doc.setFont('bold');
                        doc.text(`Date de création : ${currentDate}`, 40, 80);

                        // Set author
                        doc.setFontSize(14);
                        doc.setFont('normal');
                        doc.setFont('bold');
                        doc.text(`Bilan`, 280, 40);

                        // Set logo organisme
                        doc.setFontSize(10);
                        logo
                          ? doc.addImage(logo, 'PNG', 470, 15, 80, 40)
                          : null;

                        /* PAGE FOOTER */

                        const str = `Page ${doc.internal.getNumberOfPages()}`;
                        doc.setFontSize(7);
                        doc.setFont('normal');
                        doc.text(
                          `${user.organisation.nom_raison_sociale}`,
                          280,
                          810
                        );
                        doc.text(`${str}`, 550, 810);
                      },

                      margin: { top: 100 },
                    });

                    //CHARTS
                    // charts ? this.exportPDF__Charts(doc, charts) : null;

                    //EQUIPE
                    operation && operation.equipe
                      ? this.exportPDF__EQUIPE(doc, operation.equipe, user)
                      : null;

                    //ACTIONS
                    actions && actions?.length
                      ? this.exportPDF__Actions(doc, actions)
                      : null;

                    //ENTITÉ
                    entites && entites?.length
                      ? this.exportPDF__Entites(doc, entites)
                      : null;

                    //COLLABORATEURS
                    contacts && contacts?.length
                      ? this.exportPDF__Collaborateurs(doc, contacts)
                      : null;

                    //PARTICIPANTS
                    // participants && participants?.length
                    //   ? this.exportPDF__Participants(
                    //       doc,
                    //       participants,
                    //       user.organisation.nom_raison_sociale
                    //     )
                    //   : null;

                    //SORTIES PARTICIPANTS
                    // participants && participants?.length
                    //   ? this.exportPDF__Sorties_Participants(
                    //       doc,
                    //       participants,
                    //       user.organisation.nom_raison_social
                    //     )
                    //   : null;

                    //CRITERES PARTICIPANTS
                    participants && participants?.length
                      ? this.exportPDF__Criteres_Participants(
                          doc,
                          participants,
                          user.organisation.nom_raison_social
                        )
                      : null;

                    //COMMUNES PARTICIPANTS
                    // participants && participants?.length
                    //   ? this.exportPDF__Commune_Participants(
                    //       doc,
                    //       participants,
                    //       user.organisation.nom_raison_social
                    //     )
                    //   : null;

                    //DÉPARTEMENTS PARTICIPANTS
                    participants && participants?.length
                      ? this.exportPDF__Departement_Participants(
                          doc,
                          participants,
                          user.organisation.nom_raison_social
                        )
                      : null;

                    //NATIONALITY
                    participants && participants?.length
                      ? this.exportPDF__Nationality_Participants(
                          doc,
                          participants,
                          user.organisation.nom_raison_social
                        )
                      : null;

                    //SEXE
                    participants && participants?.length
                      ? this.exportPDF__Sexe_Participants(
                          doc,
                          participants,
                          user.organisation.nom_raison_social
                        )
                      : null;

                    //AGE
                    participants && participants?.length
                      ? this.exportPDF__Tranche_Participants(
                          doc,
                          participants,
                          user.organisation.nom_raison_social
                        )
                      : null;

                    //open || download || print
                    doc.save(
                      `Bilan ${operation.denomination}_${currentDate}.pdf`
                    );
                  }
                });

                return of(null);
              });

              const success: string = 'Votre bilan opération est prêt';
              return of(success);
            })
          );
        })
      );
    } catch (err) {
      return of(err);
    }
  }

  exportPDF__Charts(doc: any, charts: any): any {
    const head = [
      [
        {
          content: ``,
          colSpan: 4,
          styles: {
            font: 'helvetica',
            halign: 'center',
            fontStyle: 'bold',
            fillColor: [0, 0, 0],
            textColor: [0, 0, 0],
            fontSize: 11,
          },
        },
      ],
    ];

    const body = [
      [
        {
          content: ``,
          colSpan: 4,
          styles: {
            font: 'helvetica',
            halign: 'center',
            fontStyle: 'bold',
            fillColor: [0, 0, 0],
            textColor: [0, 0, 0],
            fontSize: 11,
          },
        },
      ],
    ];

    return doc.autoTable({
      theme: 'grid',
      showHead: 'everyPage',
      bodyStyles: { minCellHeight: 500 },
      didDrawCell: function (data: any) {
        if (data.column.index === 5 && data.cell.section === 'body') {
          var td = data.cell.raw;
          var img = charts;
          var dim = data.cell.height - data.cell.padding('vertical');
          var textPos = data.cell.textPos;
          doc.addImage(img.src, textPos.x, textPos.y, dim, dim);
        }
      },
    });
  }

  exportPDF__EQUIPE(doc: any, referents: any[], user: any): any {
    const rowStyle = {
      fontSize: 9,
      halign: 'left',
      fontStyle: 'normal',
    };

    const subtitle = {
      fontStyle: 'normal',
      fontSize: 8,
      fillColor: [217, 217, 217],
      textColor: [0, 0, 0],
    };

    const { organisation } = user;
    const { nom_raison_sociale } = organisation;

    const EQUIPEheadDetail = [
      [
        {
          content: `Équipe ${nom_raison_sociale} mobilisée`,
          colSpan: 3,
          styles: {
            font: 'helvetica',
            halign: 'center',
            fontStyle: 'bold',
            fillColor: [0, 55, 102],
            textColor: [255, 255, 255],
            fontSize: 11,
          },
        },
      ],
      [
        {
          content: 'Identité',
          styles: subtitle,
        },
        {
          content: 'Fonction',
          styles: subtitle,
        },
        {
          content: 'Service',
          styles: subtitle,
        },
      ],
    ];

    const referentsFlatten = flattenDeep(referents);

    const mergeEquipe: any[] = referentsFlatten?.length
      ? [user, ...referentsFlatten]
      : [user];

    const EQUIPEbodyDetail = mergeEquipe.map((el) => {
      const detail = [
        {
          content: el?.displayName ? el.displayName : 'Non défini',
          styles: {
            ...rowStyle,
            fontStyle: 'bold',
            fontSize: 8,
          },
        },
        {
          content: el?.fonction ? el.fonction : 'Non définie',
          styles: {
            ...rowStyle,
            fontSize: 8,
          },
        },
        {
          content: el?.service?.title ? el.service.title : 'Non défini',
          styles: {
            ...rowStyle,
            fontSize: 8,
          },
        },
      ];

      return detail;
    });

    return doc.autoTable({
      theme: 'grid',
      head: EQUIPEheadDetail,
      body: EQUIPEbodyDetail,
      showHead: 'everyPage',

      /* foot: foot, */
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },
      headStyles: {},

      columnStyles: {
        0: { cellWidth: 200 },
        1: { cellWidth: 200 },
        2: { cellWidth: 112 },
      },

      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
    });
  }

  exportPDF__Modules(doc: any, modules: any[]): any {
    const rowStyle = {
      fontSize: 9,
      halign: 'left',
      fontStyle: 'normal',
    };

    const subtitle = {
      fontStyle: 'normal',
      fontSize: 8,
      fillColor: [217, 217, 217],
      textColor: [0, 0, 0],
    };

    const spanColumn: number = 5;

    const ACTIONheadDetail = [
      [
        {
          content: 'Détails des modules',
          colSpan: spanColumn,
          styles: {
            font: 'helvetica',
            halign: 'center',
            fontStyle: 'bold',
            fillColor: [0, 55, 102],
            textColor: [255, 255, 255],
            fontSize: 11,
          },
        },
      ],
    ];
    doc.autoTable({
      theme: 'grid',
      head: ACTIONheadDetail,
      showHead: 'everyPage',

      /* foot: foot, */
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },
      headStyles: {},

      columnStyles: {
        0: { cellWidth: 102.4 },
        1: { cellWidth: 102.4 },
        2: { cellWidth: 102.4 },
        3: { cellWidth: 102.4 },
        4: { cellWidth: 102.4 },
      },

      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
    });

    modules.map((el) => {
      const duration = differenceInHours(
        fromUnixTime(el.dateStart['seconds']),
        fromUnixTime(el.dateEnd['seconds'])
      );
      const detail = [
        [
          {
            content: el?.title?.toUpperCase(),
            colSpan: spanColumn,
            styles: {
              ...rowStyle,
              fontStyle: 'bold',
              fontSize: 8,
              fillColor: [255, 255, 255],
            },
          },
        ],
        [
          {
            content: `Démarrage : ${format(
              fromUnixTime(el?.dateStart['seconds']),
              'dd/MM/yyyy',
              {
                locale: fr,
              }
            )}`,
            colSpan: 2,
            styles: {
              ...rowStyle,
              fontStyle: 'bold',
              halign: 'left',
              fontSize: 8,
              fillColor: [255, 255, 255],
            },
          },
          {
            content: `Échéance : ${format(
              fromUnixTime(el.dateEnd['seconds']),
              'dd/MM/yyyy',
              {
                locale: fr,
              }
            )}`,
            colSpan: 2,
            styles: {
              ...rowStyle,
              fontStyle: 'bold',
              fontSize: 8,
              halign: 'left',
              fillColor: [255, 255, 255],
            },
          },
          {
            content: `Durée : ${duration} ${duration > 1 ? 'heures' : 'heure'}`,
            colSpan: 1,
            styles: {
              ...rowStyle,
              fontStyle: 'bold',
              fontSize: 8,
              halign: 'left',
              fillColor: [255, 255, 255],
            },
          },
        ],
        [
          {
            content: `Description : ${el.definition}`,
            colSpan: spanColumn,
            styles: {
              ...subtitle,
              fillColor: [255, 255, 255],
            },
          },
        ],
        [
          {
            content: `Référents : ${
              el?.referents && el?.referents?.length
                ? el.referents
                    .map((referent: any) => referent.displayName)
                    .join(',  ')
                : ''
            }`,
            colSpan: spanColumn,
            styles: {
              ...subtitle,
              fillColor: [255, 255, 255],
            },
          },
        ],
        [
          {
            content: `Entités : ${
              el?.entites && el?.entites?.length
                ? el.entites
                    .map((entite: any) => entite.nom_raison_sociale)
                    .join(',  ')
                : ''
            }`,
            colSpan: spanColumn,
            styles: {
              ...subtitle,
              fontSize: 9,
              fontStyle: 'bold',
              fillColor: [255, 255, 255],
            },
          },
        ],
        [
          {
            content: 'Collaborateurs',
            styles: subtitle,
          },
          {
            content: 'Participants',
            styles: subtitle,
          },
          {
            content: 'Objectif',
            styles: subtitle,
          },
          {
            content: 'Réalisation',
            styles: {
              ...subtitle,
              fontStyle: 'bold',
            },
          },
          {
            content: 'Progression',
            styles: {
              ...subtitle,
              fontStyle: 'bold',
            },
          },
        ],

        [
          {
            content:
              el?.contacts && el.contacts?.length
                ? el.contacts
                    .map(
                      (contact: any) =>
                        `${contact.civilite}. ${contact.lastName} ${contact.firstName}`
                    )
                    .join(', ')
                : 'Aucune',
            styles: {
              ...rowStyle,
            },
          },
          {
            content:
              el?.participants && el.participants?.length
                ? el.participants
                    .map((participant: any) => `${participant.displayName}`)
                    .join(', ')
                : 'Aucune',
            styles: {
              ...rowStyle,
            },
          },

          {
            content: el?.objectif ? el.objectif : null,
            styles: {
              ...rowStyle,
              fontSize: 8,
            },
          },
          {
            content: el?.realisation ? el?.realisation : null,
            styles: {
              ...rowStyle,
              fontSize: 8,
              halign: 'center',
              fontStyle: 'bold',
            },
          },
          {
            content: el?.progression ? el?.progression : null,
            styles: {
              ...rowStyle,
              fontSize: 8,
              fontStyle: 'bold',
              halign: 'center',
            },
          },
        ],
      ];

      return doc.autoTable({
        theme: 'grid',
        body: detail,
        showHead: 'everyPage',

        /* foot: foot, */
        styles: {
          cellPadding: 3,
          fontSize: 10,
          textColor: [0, 0, 0],
        },
        headStyles: {},

        columnStyles: {
          0: { cellWidth: 102.4 },
          1: { cellWidth: 102.4 },
          2: { cellWidth: 102.4 },
          3: { cellWidth: 102.4 },
          4: { cellWidth: 102.4 },
        },

        bodyStyles: {
          0: { fontStyle: 'bold' },
        },
      });
    });
  }

  exportPDF__Actions(doc: any, actions: any[]): any {
    const rowStyle = {
      fontSize: 9,
      halign: 'left',
      fontStyle: 'normal',
    };

    const subtitle = {
      fontStyle: 'normal',
      fontSize: 8,
      fillColor: [217, 217, 217],
      textColor: [0, 0, 0],
    };

    const spanColumn: number = 5;

    const ACTIONheadDetail = [
      [
        {
          content: 'Détails des actions',
          colSpan: spanColumn,
          styles: {
            font: 'helvetica',
            halign: 'center',
            fontStyle: 'bold',
            fillColor: [0, 55, 102],
            textColor: [255, 255, 255],
            fontSize: 11,
          },
        },
      ],
    ];
    doc.autoTable({
      theme: 'grid',
      head: ACTIONheadDetail,
      showHead: 'everyPage',

      /* foot: foot, */
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },
      headStyles: {},

      columnStyles: {
        0: { cellWidth: 102.4 },
        1: { cellWidth: 102.4 },
        2: { cellWidth: 102.4 },
        3: { cellWidth: 102.4 },
        4: { cellWidth: 102.4 },
      },

      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
    });

    actions
      ? actions.map((el) => {
          const duration = 0;
          const detail = [
            [
              {
                content: el?.['Titre']?.toUpperCase(),
                colSpan: spanColumn,
                styles: {
                  ...rowStyle,
                  fontStyle: 'bold',
                  fontSize: 8,
                  fillColor: [255, 255, 255],
                },
              },
            ],
            [
              {
                content: ``,
                colSpan: 2,
                styles: {
                  ...rowStyle,
                  fontStyle: 'bold',
                  halign: 'left',
                  fontSize: 8,
                  fillColor: [255, 255, 255],
                },
              },
              {
                content: ``,
                colSpan: 2,
                styles: {
                  ...rowStyle,
                  fontStyle: 'bold',
                  fontSize: 8,
                  halign: 'left',
                  fillColor: [255, 255, 255],
                },
              },
              {
                content: `Durée : `,
                colSpan: 1,
                styles: {
                  ...rowStyle,
                  fontStyle: 'bold',
                  fontSize: 8,
                  halign: 'left',
                  fillColor: [255, 255, 255],
                },
              },
            ],
            [
              {
                content: `Description : ${el.definition}`,
                colSpan: spanColumn,
                styles: {
                  ...subtitle,
                  fillColor: [255, 255, 255],
                },
              },
            ],
            [
              {
                content: `Entités : ${
                  el['Entites'] && el['Entites']?.length
                    ? el['Entites']
                        .map((entite: any) => entite.nom_raison_sociale)
                        .join(',  ')
                    : ''
                }`,
                colSpan: spanColumn,
                styles: {
                  ...subtitle,
                  fontSize: 9,
                  fontStyle: 'bold',
                  fillColor: [255, 255, 255],
                },
              },
            ],
            [
              {
                content: 'Collaborateurs',
                styles: subtitle,
              },
              {
                content: 'Participants',
                styles: subtitle,
              },
              {
                content: 'Objectif',
                styles: subtitle,
              },
              {
                content: 'Réalisation',
                styles: {
                  ...subtitle,
                  fontStyle: 'bold',
                },
              },
              {
                content: 'Progression',
                styles: {
                  ...subtitle,
                  fontStyle: 'bold',
                },
              },
            ],

            [
              {
                content:
                  el['Contacts'] && el['Contacts']?.length
                    ? el['Contacts']
                        .map(
                          (contact: any) =>
                            `${contact.civilite}. ${contact.lastName} ${contact.firstName}`
                        )
                        .join(', ')
                    : 'Aucune',
                styles: {
                  ...rowStyle,
                },
              },
              {
                content:
                  el['Participants'] && el['Participants']?.length
                    ? el['Participants']
                        .map((participant: any) => `${participant.displayName}`)
                        .join(', ')
                    : 'Aucune',
                styles: {
                  ...rowStyle,
                },
              },

              {
                content: el['Objectifs'] ? el['Objectifs'] : null,
                styles: {
                  ...rowStyle,
                  fontSize: 8,
                },
              },
              {
                content: el['Réalisations'] ? el['Réalisations'] : null,
                styles: {
                  ...rowStyle,
                  fontSize: 8,
                  halign: 'center',
                  fontStyle: 'bold',
                },
              },
              {
                content: el['Progression'] ? el['Progression'] : null,
                styles: {
                  ...rowStyle,
                  fontSize: 8,
                  fontStyle: 'bold',
                  halign: 'center',
                },
              },
            ],
          ];

          return doc.autoTable({
            theme: 'grid',
            body: detail,
            showHead: 'everyPage',

            /* foot: foot, */
            styles: {
              cellPadding: 3,
              fontSize: 10,
              textColor: [0, 0, 0],
            },
            headStyles: {},

            columnStyles: {
              0: { cellWidth: 102.4 },
              1: { cellWidth: 102.4 },
              2: { cellWidth: 102.4 },
              3: { cellWidth: 102.4 },
              4: { cellWidth: 102.4 },
            },

            bodyStyles: {
              0: { fontStyle: 'bold' },
            },
          });
        })
      : null;
  }

  exportPDF__Entites(doc: any, entites: any[]): any {
    const titleStyle = {
      font: 'helvetica',
      halign: 'center',
      fontStyle: 'bold',
      fillColor: [0, 55, 102],
      textColor: [255, 255, 255],
      fontSize: 11,
    };

    //     Activité: "Autres organisations fonctionnant par adhésion volontaire"
    // Adresse: "9 Rue des Cascades 75020 Paris"
    // Categorie: "PME"
    // Commune: "PARIS 20E ARRONDISSEMENT"
    // Critères: "Non renseigné"
    // Département: "75"
    // Objectifs: "Non renseigné"
    // Raison sociale: "FACE PARIS"
    // Secteur: "Transport et Logistique"
    // Siret: "49241040200027"
    // Type: "Établissement public scientifique"

    const subtitle = {
      fontStyle: 'normal',
      fontSize: 8,
      fillColor: [217, 217, 217],
      textColor: [0, 0, 0],
    };

    const rowStyle = {
      fontSize: 8,
      halign: 'left',
      fontStyle: 'normal',
    };

    const spanColumn: number = 5;

    const ENTITEheadDetail = [
      [
        {
          content: 'Entités en intervention',
          colSpan: spanColumn,
          styles: titleStyle,
        },
      ],
    ];

    doc.autoTable({
      theme: 'grid',
      head: ENTITEheadDetail,
      showHead: 'everyPage',

      /* foot: foot, */
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },
      headStyles: {},

      columnStyles: {
        0: { cellWidth: 102.4 },
        1: { cellWidth: 102.4 },
        2: { cellWidth: 102.4 },
        3: { cellWidth: 102.4 },
        4: { cellWidth: 102.4 },
      },

      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
    });

    entites.map((el) => {
      const detail = [
        [
          {
            content: el?.nom_raison_sociale?.toUpperCase(),
            colSpan: spanColumn,
            styles: {
              ...rowStyle,
              fontStyle: 'bold',
            },
          },
        ],
        [
          {
            content: el.secteur,
            colSpan: spanColumn,
            styles: {
              ...rowStyle,
            },
          },
        ],
        [
          {
            content: 'Intervention',
            styles: subtitle,
          },
          {
            content: 'Participants',
            styles: subtitle,
          },
          {
            content: 'Objectif',
            styles: subtitle,
          },
          {
            content: 'Réalisation',
            styles: subtitle,
          },
          {
            content: 'Progression',
            styles: subtitle,
          },
        ],

        [
          {
            content: el.role,
            styles: {
              ...rowStyle,
            },
          },
          {
            content:
              el?.participants && el?.participants?.length
                ? el?.participants?.length
                : 'Aucun',
            styles: {
              ...rowStyle,
            },
          },
          {
            content: el?.objectif ? el.objectif : 'Aucune',
            styles: {
              ...rowStyle,
            },
          },

          {
            content: el?.realisation ? el.realiation : 'Aucune',
            styles: {
              ...rowStyle,
              fontSize: 8,
              fontStyle: 'bold',
            },
          },
          {
            content: el?.progression ? el.progression : 'Aucune',
            styles: {
              ...rowStyle,
              fontSize: 8,
              fontStyle: 'bold',
              halign: 'center',
            },
          },
        ],
      ];

      return doc.autoTable({
        theme: 'grid',
        body: detail,
        showHead: 'everyPage',

        /* foot: foot, */
        styles: {
          cellPadding: 3,
          fontSize: 10,
          textColor: [0, 0, 0],
        },
        headStyles: {},

        columnStyles: {
          0: { cellWidth: 102.4 },
          1: { cellWidth: 102.4 },
          2: { cellWidth: 102.4 },
          3: { cellWidth: 102.4 },
          4: { cellWidth: 102.4 },
        },

        bodyStyles: {
          0: { fontStyle: 'bold' },
        },
      });
    });
  }

  exportPDF__Collaborateurs(doc: any, contacts: any[]): any {
    const contactsGroup = groupBy(contacts, 'entite.nom_raison_sociale');
    const groupToArray = Object.entries(contactsGroup);

    const titleStyle = {
      font: 'helvetica',
      halign: 'center',
      fontStyle: 'bold',
      fillColor: [0, 55, 102],
      textColor: [255, 255, 255],
      fontSize: 11,
    };

    const subtitle = {
      fontStyle: 'normal',
      fontSize: 8,
      fillColor: [217, 217, 217],
      textColor: [0, 0, 0],
    };

    const rowStyle = {
      fontSize: 9,
      halign: 'left',
      fontStyle: 'normal',
    };

    const CONTACTheadDetail: any = [
      [
        {
          content: 'Collaborateurs engagés',
          colSpan: 3,
          styles: titleStyle,
        },
      ],
    ];

    doc.autoTable({
      theme: 'grid',
      head: CONTACTheadDetail,
      showHead: 'everyPage',

      /* foot: foot, */
      styles: {
        cellPadding: 3,
        fontSize: 9,
        textColor: [0, 0, 0],
      },
      headStyles: {},

      columnStyles: {
        0: { cellWidth: 170 },
        1: { cellWidth: 170 },
        2: { cellWidth: 172 },
      },

      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
    });

    groupToArray?.length
      ? groupToArray.map((el) => {
          const entite: string = el[0];
          const collaborateurs: any[] = el[1];

          const entiteHeadTitle = [
            [
              {
                content: entite,
                colSpan: 3,
                styles: {
                  ...subtitle,
                  fontStyle: 'bold',
                  fillColor: [255, 255, 255],
                },
              },
            ],
            [
              {
                content: 'Fonction',
                styles: subtitle,
              },
              {
                content: 'Actions',
                styles: subtitle,
              },
              {
                content: 'Participants',
                styles: subtitle,
              },
            ],
          ];

          const entiteCollaborateurs = collaborateurs.map((item) => {
            const rowCollaborateur = [
              {
                content: item.fonction,
                styles: {
                  ...rowStyle,
                },
              },
              {
                content:
                  item?.actions && item?.actions?.length
                    ? item?.actions?.length
                    : 'Aucune',
                styles: {
                  ...rowStyle,
                },
              },
              {
                content:
                  item?.participants && item?.participants?.length
                    ? item?.participants?.length
                    : 'Aucune',
                styles: {
                  ...rowStyle,
                },
              },
            ];
            return rowCollaborateur;
          });

          return doc.autoTable({
            theme: 'grid',
            head: entiteHeadTitle,
            body: entiteCollaborateurs,
            showHead: 'everyPage',

            /* foot: foot, */
            styles: {
              cellPadding: 3,
              fontSize: 9,
              textColor: [0, 0, 0],
            },
            headStyles: {},

            columnStyles: {
              0: { cellWidth: 170 },
              1: { cellWidth: 170 },
              2: { cellWidth: 172 },
            },

            bodyStyles: {
              0: { fontStyle: 'bold' },
            },
            margin: { top: -50 },
          });
        })
      : null;
  }

  exportPDF__Participants(
    doc: any,
    participants: any[],
    organisation: any
  ): any {
    const titleStyle = {
      font: 'helvetica',
      halign: 'center',
      fontStyle: 'bold',
      fillColor: [0, 55, 102],
      textColor: [255, 255, 255],
      fontSize: 11,
    };

    const subtitle = {
      font: 'helvetica',
      halign: 'center',
      fontStyle: 'normal',
      fillColor: [217, 217, 217],
      textColor: [0, 0, 0],
      fontSize: 8,
    };

    const rowStyle = {
      halign: 'center',
      fontSize: 8,
      fontStyle: 'normal',
    };

    const PARTICIPANTHeader = [
      [
        {
          content: 'Participants mobilisés',
          colSpan: 5,
          styles: titleStyle,
        },
      ],
      [
        {
          content: `Identité`,
          styles: subtitle,
        },
        {
          content: 'Actions',
          styles: subtitle,
        },
        {
          content: 'Présences',
          styles: subtitle,
        },
        {
          content: 'Excusés',
          styles: subtitle,
        },
        {
          content: 'Absences',
          styles: subtitle,
        },
      ],
    ];

    const PARTICIPANTSbody = participants?.length
      ? participants.map((participant) => {
          const { civilite, lastName, firstName, informations } = participant;

          const displayName: string = `${civilite}. ${lastName} ${firstName}`;
          const { adresse, poleEmploi, missionLocal } = informations;

          const PARTICIPANTBody = [
            {
              content: displayName,
              styles: rowStyle,
            },
            {
              content:
                participant?.actions && participant?.actions?.length
                  ? participant?.actions?.length
                  : 'Aucune',
              styles: rowStyle,
            },
            {
              content:
                participant?.actions && participant?.actions?.length
                  ? participant?.actions.map((action: any) => action.present)
                      ?.length
                  : 'Aucune',
              styles: rowStyle,
            },
            {
              content:
                participant?.actions && participant?.actions?.length
                  ? participant?.actions.map((action: any) => action.excuse)
                      ?.length
                  : 'Aucune',
              styles: rowStyle,
            },
            {
              content:
                participant?.actions && participant?.actions?.length
                  ? participant?.actions.map((action: any) => action.absent)
                      ?.length
                  : 'Aucune',
              styles: rowStyle,
            },
          ];

          return PARTICIPANTBody;
        })
      : [];

    return doc.autoTable({
      theme: 'grid',
      head: PARTICIPANTHeader,
      body: PARTICIPANTSbody,
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },
      headStyles: {},
      columnStyles: {
        0: { cellWidth: 103 },
        1: { cellWidth: 103 },
        2: { cellWidth: 103 },
        3: { cellWidth: 103 },
        4: { cellWidth: 103 },
      },

      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
    });
  }

  exportPDF__Sorties_Participants(
    doc: any,
    participants: any[],
    organisation: any
  ): any {
    const titleStyle = {
      font: 'helvetica',
      halign: 'center',
      fontStyle: 'bold',
      fillColor: [0, 55, 102],
      textColor: [255, 255, 255],
      fontSize: 11,
    };

    const subtitle = {
      font: 'helvetica',
      halign: 'center',
      fontStyle: 'normal',
      fillColor: [217, 217, 217],
      textColor: [0, 0, 0],
      fontSize: 8,
    };

    const rowStyle = {
      halign: 'left',
      fontSize: 8,
      fontStyle: 'normal',
    };

    const PARTICIPANTHeader = [
      [
        {
          content: 'Participants par types de sorties',
          colSpan: 3,
          styles: titleStyle,
        },
      ],
      [
        {
          content: `Type`,
          styles: subtitle,
        },
        {
          content: 'Nombre',
          styles: subtitle,
        },
        {
          content: 'Pourcentage',
          styles: subtitle,
        },
      ],
    ];

    const PARTICIPANTSbody = participants?.length
      ? participants.map((participant) => {
          const { sortiProgrammeMotif, sortiInscriptionMotif } = participant;

          const PARTICIPANTBody = [
            {
              content:
                participant?.actions && participant?.actions?.length
                  ? participant?.actions?.length
                  : 'Aucune',
              styles: rowStyle,
            },
            {
              content:
                participant?.actions && participant?.actions?.length
                  ? participant?.actions.map((action: any) => action.present)
                      ?.length
                  : 'Aucune',
              styles: rowStyle,
            },
            {
              content:
                participant?.actions && participant?.actions?.length
                  ? participant?.actions.map((action: any) => action.excuse)
                      ?.length
                  : 'Aucune',
              styles: rowStyle,
            },
          ];

          return PARTICIPANTBody;
        })
      : [];

    return doc.autoTable({
      theme: 'grid',
      head: PARTICIPANTHeader,
      body: PARTICIPANTSbody,
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },
      headStyles: {},
      columnStyles: {
        0: { cellWidth: 259 },
        1: { cellWidth: 128 },
        2: { cellWidth: 128 },
      },

      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
      // didDrawPage: (data) => {

      //   const str = `Page ${doc.internal.getNumberOfPages()}`;
      //   doc.setFontSize(7);
      //   doc.setFont('normal');
      //   doc.text(`${organisation}`, 280, 810);
      //   doc.text(`${str}`, 550, 810);
      // },
    });
  }

  exportPDF__Commune_Participants(
    doc: any,
    participants: any[],
    organisation: any
  ): any {
    const titleStyle = {
      font: 'helvetica',
      halign: 'center',
      fontStyle: 'bold',
      fillColor: [0, 55, 102],
      textColor: [255, 255, 255],
      fontSize: 11,
    };

    const subtitle = {
      font: 'helvetica',
      halign: 'center',
      fontStyle: 'normal',
      fillColor: [217, 217, 217],
      textColor: [0, 0, 0],
      fontSize: 8,
    };

    const rowStyle = {
      halign: 'center',
      fontSize: 8,
      fontStyle: 'normal',
    };

    const PARTICIPANTHeader = [
      [
        {
          content: 'Participants par commune',
          colSpan: 3,
          styles: titleStyle,
        },
      ],
      [
        {
          content: `Commune`,
          styles: subtitle,
        },
        {
          content: 'Nombre',
          styles: subtitle,
        },
        {
          content: 'Pourcentage',
          styles: subtitle,
        },
      ],
    ];

    const setParticipantGroup = groupBy(
      participants,
      'informations.adresse.adresse.properties.city'
    );
    const transformToArray = Object.entries(setParticipantGroup);

    const PARTICIPANTSbody = transformToArray?.length
      ? transformToArray.map((item) => {
          const key: string = capitalize(item[0]);
          const value: number = item[1]?.length;
          const percent = Math.round((value / participants.length) * 100);

          const PARTICIPANTBody = [
            {
              content:
                key === 'Null' || key === 'Undefined'
                  ? 'Non renseigné'
                  : key
                  ? key
                  : 'Aucune',
              styles: rowStyle,
            },
            {
              content: value ? value : 'Aucune',
              styles: rowStyle,
            },
            {
              content: percent ? `${percent} %` : 'Aucune',
              styles: rowStyle,
            },
          ];

          return PARTICIPANTBody;
        })
      : [];

    return doc.autoTable({
      theme: 'grid',
      head: PARTICIPANTHeader,
      body: PARTICIPANTSbody,
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },
      headStyles: {},
      columnStyles: {
        0: { cellWidth: 259 },
        1: { cellWidth: 128 },
        2: { cellWidth: 128 },
      },

      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
      // didDrawPage: (data) => {

      //   const str = `Page ${doc.internal.getNumberOfPages()}`;
      //   doc.setFontSize(7);
      //   doc.setFont('normal');
      //   doc.text(`${organisation}`, 280, 810);
      //   doc.text(`${str}`, 550, 810);
      // },
    });
  }

  exportPDF__Departement_Participants(
    doc: any,
    participants: any[],
    organisation: any
  ): any {
    const titleStyle = {
      font: 'helvetica',
      halign: 'center',
      fontStyle: 'bold',
      fillColor: [0, 55, 102],
      textColor: [255, 255, 255],
      fontSize: 11,
    };

    const subtitle = {
      font: 'helvetica',
      halign: 'center',
      fontStyle: 'normal',
      fillColor: [217, 217, 217],
      textColor: [0, 0, 0],
      fontSize: 8,
    };

    const rowStyle = {
      halign: 'center',
      fontSize: 8,
      fontStyle: 'normal',
    };

    const PARTICIPANTHeader = [
      [
        {
          content: 'Participants par département',
          colSpan: 3,
          styles: titleStyle,
        },
      ],
      [
        {
          content: `Département`,
          styles: subtitle,
        },
        {
          content: 'Nombre',
          styles: subtitle,
        },
        {
          content: 'Pourcentage',
          styles: subtitle,
        },
      ],
    ];

    const setParticipantGroup = groupBy(
      participants,
      'informations.adresse.adresse.properties.postcode'
    );
    const transformToArray = Object.entries(setParticipantGroup);

    const PARTICIPANTSbody = transformToArray?.length
      ? transformToArray.map((item) => {
          const key: string = capitalize(item[0]);
          const value: number = item[1]?.length;
          const percent = Math.round((value / participants.length) * 100);

          const PARTICIPANTBody = [
            {
              content:
                key === 'Null' || key === 'Undefined'
                  ? 'Non renseigné'
                  : key
                  ? key
                  : 'Aucune',
              styles: rowStyle,
            },
            {
              content: value ? value : 'Aucune',
              styles: rowStyle,
            },
            {
              content: percent ? `${percent} %` : 'Aucune',
              styles: rowStyle,
            },
          ];

          return PARTICIPANTBody;
        })
      : [];

    return doc.autoTable({
      theme: 'grid',
      head: PARTICIPANTHeader,
      body: PARTICIPANTSbody,
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },
      headStyles: {},
      columnStyles: {
        0: { cellWidth: 259 },
        1: { cellWidth: 128 },
        2: { cellWidth: 128 },
      },

      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
      // didDrawPage: (data) => {

      //   const str = `Page ${doc.internal.getNumberOfPages()}`;
      //   doc.setFontSize(7);
      //   doc.setFont('normal');
      //   doc.text(`${organisation}`, 280, 810);
      //   doc.text(`${str}`, 550, 810);
      // },
    });
  }

  exportPDF__Nationality_Participants(
    doc: any,
    participants: any[],
    organisation: any
  ): any {
    const titleStyle = {
      font: 'helvetica',
      halign: 'center',
      fontStyle: 'bold',
      fillColor: [0, 55, 102],
      textColor: [255, 255, 255],
      fontSize: 11,
    };

    const subtitle = {
      font: 'helvetica',
      halign: 'center',
      fontStyle: 'normal',
      fillColor: [217, 217, 217],
      textColor: [0, 0, 0],
      fontSize: 8,
    };

    const rowStyle = {
      halign: 'center',
      fontSize: 8,
      fontStyle: 'normal',
    };

    const PARTICIPANTHeader = [
      [
        {
          content: 'Participants par nationalité',
          colSpan: 3,
          styles: titleStyle,
        },
      ],
      [
        {
          content: `Nationalité`,
          styles: {
            ...subtitle,
            halign: 'center',
          },
        },
        {
          content: 'Nombre',
          styles: subtitle,
        },
        {
          content: 'Pourcentage',
          styles: subtitle,
        },
      ],
    ];

    const setParticipantGroup = groupBy(
      participants,
      'nationality.nationalite'
    );
    const transformToArray = Object.entries(setParticipantGroup);

    const PARTICIPANTSbody = transformToArray?.length
      ? transformToArray.map((item) => {
          const key: string = capitalize(item[0]);
          const value: number = item[1]?.length;
          const percent = Math.round((value / participants.length) * 100);

          const PARTICIPANTBody = [
            {
              content: key ? key : 'Aucune',
              styles: rowStyle,
            },
            {
              content: value ? value : 'Aucune',
              styles: rowStyle,
            },
            {
              content: percent ? `${percent} %` : 'Aucune',
              styles: rowStyle,
            },
          ];

          return PARTICIPANTBody;
        })
      : [];

    return doc.autoTable({
      theme: 'grid',
      head: PARTICIPANTHeader,
      body: PARTICIPANTSbody,
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },
      headStyles: {},
      columnStyles: {
        0: { cellWidth: 259 },
        1: { cellWidth: 128 },
        2: { cellWidth: 128 },
      },

      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
      // didDrawPage: (data) => {

      //   const str = `Page ${doc.internal.getNumberOfPages()}`;
      //   doc.setFontSize(7);
      //   doc.setFont('normal');
      //   doc.text(`${organisation}`, 280, 810);
      //   doc.text(`${str}`, 550, 810);
      // },
    });
  }

  exportPDF__Sexe_Participants(
    doc: any,
    participants: any[],
    organisation: any
  ): any {
    const titleStyle = {
      font: 'helvetica',
      halign: 'center',
      fontStyle: 'bold',
      fillColor: [0, 55, 102],
      textColor: [255, 255, 255],
      fontSize: 11,
    };

    const subtitle = {
      font: 'helvetica',
      halign: 'center',
      fontStyle: 'normal',
      fillColor: [217, 217, 217],
      textColor: [0, 0, 0],
      fontSize: 8,
    };

    const rowStyle = {
      halign: 'center',
      fontSize: 8,
      fontStyle: 'normal',
    };

    const PARTICIPANTHeader = [
      [
        {
          content: 'Participants par genre',
          colSpan: 3,
          styles: titleStyle,
        },
      ],
      [
        {
          content: `Genre`,
          styles: subtitle,
        },
        {
          content: 'Nombre',
          styles: subtitle,
        },
        {
          content: 'Pourcentage',
          styles: subtitle,
        },
      ],
    ];

    const setParticipantGroup = groupBy(participants, 'sexe');
    const transformToArray = Object.entries(setParticipantGroup);

    const PARTICIPANTSbody = transformToArray?.length
      ? transformToArray.map((item) => {
          const key: string = capitalize(item[0]);
          const value: number = item[1]?.length;
          const percent = Math.round((value / participants.length) * 100);

          const PARTICIPANTBody = [
            {
              content: key ? key : 'Aucune',
              styles: rowStyle,
            },
            {
              content: value ? value : 'Aucune',
              styles: rowStyle,
            },
            {
              content: percent ? `${percent} %` : 'Aucune',
              styles: rowStyle,
            },
          ];

          return PARTICIPANTBody;
        })
      : [];

    return doc.autoTable({
      theme: 'grid',
      head: PARTICIPANTHeader,
      body: PARTICIPANTSbody,
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },
      headStyles: {},
      columnStyles: {
        0: { cellWidth: 259 },
        1: { cellWidth: 128 },
        2: { cellWidth: 128 },
      },

      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
      // didDrawPage: (data) => {

      //   const str = `Page ${doc.internal.getNumberOfPages()}`;
      //   doc.setFontSize(7);
      //   doc.setFont('normal');
      //   doc.text(`${organisation}`, 280, 810);
      //   doc.text(`${str}`, 550, 810);
      // },
    });
  }

  exportPDF__Tranche_Participants(
    doc: any,
    participants: any[],
    organisation: any
  ): any {
    const titleStyle = {
      font: 'helvetica',
      halign: 'center',
      fontStyle: 'bold',
      fillColor: [0, 55, 102],
      textColor: [255, 255, 255],
      fontSize: 11,
    };

    const subtitle = {
      font: 'helvetica',
      halign: 'center',
      fontStyle: 'normal',
      fillColor: [217, 217, 217],
      textColor: [0, 0, 0],
      fontSize: 8,
    };

    const rowStyle = {
      halign: 'center',
      fontSize: 8,
      fontStyle: 'normal',
    };

    const PARTICIPANTHeader = [
      [
        {
          content: "Participants par tranches d'âge",
          colSpan: 3,
          styles: titleStyle,
        },
      ],
      [
        {
          content: `Tranche`,
          styles: subtitle,
        },
        {
          content: 'Nombre',
          styles: subtitle,
        },
        {
          content: 'Pourcentage',
          styles: subtitle,
        },
      ],
    ];

    const setParticipantGroup = groupBy(participants, 'tranche');
    const transformToArray = Object.entries(setParticipantGroup);

    const PARTICIPANTSbody = transformToArray?.length
      ? transformToArray.map((item) => {
          const key: string = capitalize(item[0]);
          const value: number = item[1]?.length;
          const percent = Math.round((value / participants.length) * 100);

          const PARTICIPANTBody = [
            {
              content: key === 'Null' ? 'Non renseigné' : key ? key : 'Aucune',
              styles: rowStyle,
            },
            {
              content: value ? value : 'Aucune',
              styles: rowStyle,
            },
            {
              content: percent ? `${percent} %` : 'Aucune',
              styles: rowStyle,
            },
          ];

          return PARTICIPANTBody;
        })
      : [];

    return doc.autoTable({
      theme: 'grid',
      head: PARTICIPANTHeader,
      body: PARTICIPANTSbody,
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },
      headStyles: {},
      columnStyles: {
        0: { cellWidth: 259 },
        1: { cellWidth: 128 },
        2: { cellWidth: 128 },
      },

      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
      // didDrawPage: (data) => {

      //   const str = `Page ${doc.internal.getNumberOfPages()}`;
      //   doc.setFontSize(7);
      //   doc.setFont('normal');
      //   doc.text(`${organisation}`, 280, 810);
      //   doc.text(`${str}`, 550, 810);
      // },
    });
  }

  exportPDF__Criteres_Participants(
    doc: any,
    participants: any[],
    organisation: any
  ): any {
    const titleStyle = {
      font: 'helvetica',
      halign: 'center',
      fontStyle: 'bold',
      fillColor: [0, 55, 102],
      textColor: [255, 255, 255],
      fontSize: 11,
    };

    const subtitle = {
      font: 'helvetica',
      halign: 'center',
      fontStyle: 'normal',
      fillColor: [217, 217, 217],
      textColor: [0, 0, 0],
      fontSize: 8,
    };

    const rowStyle = {
      halign: 'center',
      fontSize: 8,
      fontStyle: 'normal',
    };

    const PARTICIPANTHeader = [
      [
        {
          content: "Participants par critère d'éligibilité",
          colSpan: 3,
          styles: titleStyle,
        },
      ],
      [
        {
          content: `Critère`,
          styles: {
            ...subtitle,
            halign: 'left',
          },
        },
        {
          content: 'Nombre',
          styles: subtitle,
        },
        {
          content: 'Pourcentage',
          styles: subtitle,
        },
      ],
    ];

    const participantsCriteres: any[] = participants.map((participant) =>
      participant.criteres.map((critere: any) => ({
        item: critere.item,
        id: critere.id,
      }))
    );

    const setParticipantGroup = groupBy(
      flattenDeep(participantsCriteres),
      'item'
    );

    const transformToArray = Object.entries(setParticipantGroup);

    const PARTICIPANTSbody = transformToArray?.length
      ? transformToArray.map((item) => {
          const key: string = capitalize(item[0]);
          const value: number = item[1]?.length;
          const percent = Math.round((value / participants.length) * 100);

          const PARTICIPANTBody = [
            {
              content:
                key === 'Null' || key === 'Undefined'
                  ? 'Non renseigné'
                  : key
                  ? key
                  : 'Aucune',
              styles: {
                ...rowStyle,
                halign: 'left',
              },
            },
            {
              content: value ? value : 'Aucune',
              styles: rowStyle,
            },
            {
              content: percent ? `${percent} %` : 'Aucune',
              styles: rowStyle,
            },
          ];

          return PARTICIPANTBody;
        })
      : [];

    return doc.autoTable({
      theme: 'grid',
      head: PARTICIPANTHeader,
      body: PARTICIPANTSbody,
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },
      headStyles: {},
      columnStyles: {
        0: { cellWidth: 259 },
        1: { cellWidth: 128 },
        2: { cellWidth: 128 },
      },

      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
      // didDrawPage: (data) => {

      //   const str = `Page ${doc.internal.getNumberOfPages()}`;
      //   doc.setFontSize(7);
      //   doc.setFont('normal');
      //   doc.text(`${organisation}`, 280, 810);
      //   doc.text(`${str}`, 550, 810);
      // },
    });
  }

  exportPDF__SortiParticipants(
    doc: any,
    participants: any[],
    organisation: any
  ): any {
    const PARTICIPANTSheader = [
      [
        {
          content: 'Participant',
          colSpan: 5,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'bold',
            fillColor: [0, 55, 102],
            textColor: [255, 255, 255],
          },
        },
      ],
      [
        {
          content: 'Identité',
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'bold',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Sexe',
          styles: {
            fontStyle: 'normal',
            halign: 'center',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Naissance',
          styles: {
            fontStyle: 'normal',
            halign: 'center',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Âge',
          styles: {
            fontStyle: 'normal',
            halign: 'center',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Situation matrimoniale',
          styles: {
            fontStyle: 'normal',
            halign: 'center',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
    ];

    const PARTICIPANTSbody = participants.map((participant) => {
      const {
        civilite,
        lastName,
        firstName,
        informations,
        operation,
        referents,
        actions,
        entites,
        contacts,
        contrats,
        dateStart,
        dateEnd,
        datetIntegration,
        dateNaissance,
        prescripteur,
        priseEnCharge,
        situationPersonnelle,
      } = participant;

      const displayName = `${civilite}. ${lastName} ${firstName}`;
      const { adresse, poleEmploi, missionLocal } = informations;

      const PARTICIPANTbodyTitle = [
        [
          {
            content: `${displayName.toUpperCase()}`,
            styles: {
              halign: 'left',
              fontStyle: 'normal',
            },
          },
          {
            content: participant.sexe ? participant.sexe : 'Non renseigné',
            styles: {
              halign: 'center',
              fontStyle: 'normal',
            },
          },
          {
            content: participant?.dateNaissance
              ? format(
                  fromUnixTime(participant?.dateNaissance['seconds']),
                  'dd/MM/yyyy',
                  { locale: fr }
                )
              : 'Non renseignée',
            styles: {
              halign: 'center',
              fontStyle: 'normal',
            },
          },
          {
            content: participant?.dateNaissance
              ? `${differenceInYears(
                  new Date(Date.now()),
                  fromUnixTime(participant?.dateNaissance['seconds'])
                )} ans`
              : 'Non renseigné',
            styles: {
              halign: 'center',
              fontStyle: 'normal',
            },
          },
          {
            content: participant?.situationPersonnelle?.familiale
              ? participant?.situationPersonnelle?.familiale
              : 'Non renseigné',
            styles: {
              halign: 'center',
              fontStyle: 'normal',
            },
          },
        ],
      ];

      const PARTICIPANTheadTitle: any = [];

      doc.autoTable({
        theme: 'grid',
        head: PARTICIPANTheadTitle,
        body: PARTICIPANTbodyTitle,
        styles: {
          cellPadding: 3,
          fontSize: 10,
          textColor: [0, 0, 0],
        },
        headStyles: {},
        columnStyles: {
          0: { cellWidth: 170 },
          1: { cellWidth: 75 },
          2: { cellWidth: 75 },
          3: { cellWidth: 75 },
          4: { cellWidth: 120 },
        },

        bodyStyles: {
          0: { fontStyle: 'bold' },
        },
      });

      //REFERENT
      const REFERENTheadProjetTitle = [
        [
          {
            content: 'Référents.es',
            colSpan: 2,
            styles: {
              font: 'helvetica',
              halign: 'left',
              fontStyle: 'bold',
              fillColor: [0, 55, 102],
              textColor: [255, 255, 255],
            },
          },
        ],
        [
          {
            content: 'Identité',
            styles: {
              fontStyle: 'normal',
              halign: 'left',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
          {
            content: 'Fonction',
            styles: {
              fontStyle: 'normal',
              halign: 'left',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
        ],
      ];

      const REFERENTbodyProjetTitle = referents?.length
        ? referents.map((el: any) => {
            const referentData = [
              {
                content: el?.displayName ? el?.displayName : 'Non renseignée',
                styles: {
                  halign: 'left',
                  fontStyle: 'normal',
                },
              },
              {
                content: el?.fonction ? el?.fonction : 'Non renseignée',
                styles: {
                  halign: 'left',
                  fontStyle: 'normal',
                },
              },
            ];
            return referentData;
          })
        : [];

      doc.autoTable({
        theme: 'grid',
        head: REFERENTheadProjetTitle,
        body: REFERENTbodyProjetTitle,
        styles: {
          cellPadding: 3,
          fontSize: 10,
          textColor: [0, 0, 0],
        },
        headStyles: {
          /* fillColor: [0, 0, 0], textColor: [255, 255, 255] */
        },
        columnStyles: {
          0: { cellWidth: 220 },
          1: { cellWidth: 295 },
        },
        bodyStyles: {
          0: { fontStyle: 'bold' },
        },
      });

      //INFORMATIONS
      const info2headProjetTitle = [
        [
          {
            content: 'Informations',
            colSpan: 4,
            styles: {
              font: 'helvetica',
              halign: 'left',
              fontStyle: 'bold',
              fillColor: [0, 55, 102],
              textColor: [255, 255, 255],
              fontSize: 11,
            },
          },
        ],
        [
          {
            content: 'Nationalité',
            styles: {
              fontStyle: 'normal',
              halign: 'center',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
          {
            content: 'Niveau de français',
            styles: {
              fontStyle: 'normal',
              halign: 'center',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
          {
            content: 'Qualification',
            styles: {
              fontStyle: 'normal',
              halign: 'center',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
          {
            content: 'Droit de travail',
            styles: {
              fontStyle: 'normal',
              halign: 'center',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
        ],
      ];

      const info2bodyProjetTitle = [
        [
          {
            content: participant?.nationality?.nationalite
              ? participant?.nationality?.nationalite
              : 'Non renseignée',
            styles: {
              halign: 'center',
              fontStyle: 'normal',
            },
          },
          {
            content: participant?.maitriseFrancais?.item
              ? `${participant?.maitriseFrancais?.item} - ${participant?.maitriseFrancais?.type}`
              : 'Non renseignée',
            styles: {
              halign: 'center',
              fontStyle: 'normal',
            },
          },
          {
            content: participant?.qualification?.titre
              ? `${participant?.qualification?.titre} | ${participant?.qualification?.niveau} | ${participant?.qualification?.apresBac}`
              : 'Non renseignée',
            styles: {
              halign: 'center',
              fontStyle: 'normal',
            },
          },
          {
            content: participant?.droitDeTravail ? 'Oui' : 'Non renseigné',
            styles: {
              halign: 'center',
              fontStyle: 'normal',
            },
          },
        ],
      ];

      doc.autoTable({
        theme: 'grid',
        head: info2headProjetTitle,
        body: info2bodyProjetTitle,
        styles: {
          cellPadding: 3,
          fontSize: 10,
          textColor: [0, 0, 0],
        },

        columnStyles: {
          0: { cellWidth: 125 },
          1: { cellWidth: 135 },
          2: { cellWidth: 135 },
          3: { cellWidth: 120 },
        },
        bodyStyles: {
          0: { fontStyle: 'bold' },
        },
      });

      //SEJOURS
      const sejoursheadProjetTitle = [
        [
          {
            content: 'Type de séjour',
            styles: {
              fontStyle: 'normal',
              halign: 'left',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
          {
            content: 'Carte de séjour',
            styles: {
              fontStyle: 'normal',
              halign: 'left',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
        ],
      ];

      const sejoursbodyProjetTitle = [
        [
          {
            content: participant?.typeCarteSejour?.type
              ? participant?.typeCarteSejour?.type
              : 'Non renseigné',
            styles: {
              halign: 'left',
              fontStyle: 'normal',
            },
          },
          {
            content: participant?.typeCarteSejour?.titre
              ? participant?.typeCarteSejour?.titre
              : 'Non renseigné',
            styles: {
              halign: 'left',
              fontStyle: 'normal',
            },
          },
        ],
      ];

      doc.autoTable({
        theme: 'grid',
        head: sejoursheadProjetTitle,
        body: sejoursbodyProjetTitle,
        styles: {
          cellPadding: 3,
          fontSize: 10,
          textColor: [0, 0, 0],
        },

        columnStyles: {
          0: { cellWidth: 215 },
          1: { cellWidth: 300 },
        },
        bodyStyles: {
          0: { fontStyle: 'bold' },
        },
      });

      //LOCALISATION
      const localisationheadProjetTitle = [
        [
          {
            content: 'Commune',
            styles: {
              fontStyle: 'normal',
              halign: 'center',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
          {
            content: 'Département',
            styles: {
              fontStyle: 'normal',
              halign: 'center',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
          {
            content: 'Contexte',
            styles: {
              fontStyle: 'normal',
              halign: 'center',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
        ],
      ];

      const city = adresse?.adresse?.properties?.city
        ? adresse?.adresse.properties?.city
        : adresse?.fields?.properties?.city
        ? adresse?.fields.properties?.city
        : 'Non renseignée';

      const postcode = adresse?.adresse?.properties?.postcode
        ? adresse?.adresse.properties?.postcode
        : adresse?.fields?.properties?.postcode
        ? adresse?.fields.properties?.postcode
        : 'Non renseigné';

      const context = adresse?.adresse?.properties?.context
        ? adresse?.adresse.properties?.context
        : adresse?.fields?.properties?.context
        ? adresse?.fields.properties?.context
        : 'Non renseigné';

      const localisationbodyProjetTitle = [
        [
          {
            content: city,
            styles: {
              halign: 'center',
              fontStyle: 'normal',
            },
          },
          {
            content: postcode,
            styles: {
              halign: 'center',
              fontStyle: 'normal',
            },
          },
          {
            content: context,
            styles: {
              halign: 'center',
              fontStyle: 'normal',
            },
          },
        ],
      ];

      doc.autoTable({
        theme: 'grid',
        head: localisationheadProjetTitle,
        body: localisationbodyProjetTitle,
        styles: {
          cellPadding: 3,
          fontSize: 10,
          textColor: [0, 0, 0],
        },

        columnStyles: {
          0: { cellWidth: 125 },
          1: { cellWidth: 150 },
          2: { cellWidth: 240 },
        },
        bodyStyles: {
          0: { fontStyle: 'bold' },
        },
      });

      //COORDONNEES
      const coordonneesheadProjetTitle = [
        [
          {
            content: 'Communication',
            styles: {
              fontStyle: 'normal',
              halign: 'left',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
          {
            content: 'Mobile',
            styles: {
              fontStyle: 'normal',
              halign: 'left',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
          {
            content: 'Fixe',
            styles: {
              fontStyle: 'normal',
              halign: 'left',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
          {
            content: 'E-mail',
            styles: {
              fontStyle: 'left',
              halign: 'center',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
        ],
      ];

      const coordonneesbodyProjetTitle = [
        [
          {
            content: participant?.communication
              ? participant.communication
              : 'Aucun',
            styles: {
              halign: 'left',
              fontStyle: 'normal',
            },
          },
          {
            content: participant?.informations?.coordonnees?.mobile
              ? participant?.informations?.coordonnees?.mobile
              : 'Aucun',
            styles: {
              halign: 'left',
              fontStyle: 'normal',
            },
          },
          {
            content: participant?.informations?.coordonnees?.fixe
              ? participant?.informations?.coordonnees?.fixe
              : 'Aucun',
            styles: {
              halign: 'left',
              fontStyle: 'normal',
            },
          },
          {
            content: participant?.informations?.coordonnees?.email
              ? participant?.informations?.coordonnees?.email
              : 'Aucun',
            styles: {
              halign: 'left',
              fontStyle: 'normal',
              fontSize: 9,
            },
          },
        ],
      ];

      doc.autoTable({
        theme: 'grid',
        head: coordonneesheadProjetTitle,
        body: coordonneesbodyProjetTitle,
        styles: {
          cellPadding: 3,
          fontSize: 10,
          textColor: [0, 0, 0],
        },

        columnStyles: {
          0: { cellWidth: 75 },
          1: { cellWidth: 100 },
          2: { cellWidth: 100 },
          3: { cellWidth: 240 },
        },
        bodyStyles: {
          0: { fontStyle: 'bold' },
        },
      });

      //RQTH && MOBILITY
      const rqthMobilityheadProjetTitle = [
        [
          {
            content: 'RQTH',
            styles: {
              fontStyle: 'normal',
              halign: 'center',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
          {
            content: 'Mobilité',
            styles: {
              fontStyle: 'normal',
              halign: 'center',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
          {
            content: 'Santé',
            styles: {
              fontStyle: 'normal',
              halign: 'center',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
          {
            content: 'Logement',
            styles: {
              fontStyle: 'normal',
              halign: 'center',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
        ],
      ];

      const rqthMobilitybodyProjetTitle = [
        [
          {
            content: participant?.rqth ? 'RQTH' : 'Non renseigné',
            styles: {
              halign: 'center',
              fontStyle: 'normal',
            },
          },
          {
            content: participant?.situationPersonnelle?.mobilite?.item
              ? participant?.situationPersonnelle?.mobilite?.item
              : 'Non renseignée',
            styles: {
              halign: 'center',
              fontStyle: 'normal',
            },
          },
          {
            content: participant?.situationPersonnelle?.sante
              ? participant?.situationPersonnelle?.sante
              : 'Non renseigné',
            styles: {
              halign: 'center',
              fontStyle: 'normal',
            },
          },
          {
            content: participant?.situationPersonnelle?.logement
              ? "Dispose d'un logement"
              : 'Non renseigné',
            styles: {
              halign: 'center',
              fontStyle: 'normal',
            },
          },
        ],
      ];

      doc.autoTable({
        theme: 'grid',
        head: rqthMobilityheadProjetTitle,
        body: rqthMobilitybodyProjetTitle,
        styles: {
          cellPadding: 3,
          fontSize: 10,
          textColor: [0, 0, 0],
        },

        columnStyles: {
          0: { cellWidth: 100 },
          1: { cellWidth: 125 },
          2: { cellWidth: 150 },
          3: { cellWidth: 140 },
        },
        bodyStyles: {
          0: { fontStyle: 'bold' },
        },
      });

      //CRITERES
      const CRTITERESheadProjetTitle = [
        [
          {
            content: "Critères d'éligibilité",
            colSpan: 1,
            styles: {
              font: 'helvetica',
              halign: 'left',
              fontStyle: 'bold',
              fillColor: [0, 55, 102],
              textColor: [255, 255, 255],
            },
          },
        ],
      ];

      const CRTITERESbodyProjetTitle = [
        [
          {
            content: participant?.criteres?.length
              ? participant.criteres.map((el: any) => el.item).join(', ')
              : 'Non renseigné',
            styles: {
              halign: 'center',
              fontStyle: 'normal',
              fontSize: 10,
            },
          },
        ],
      ];

      doc.autoTable({
        theme: 'grid',
        head: CRTITERESheadProjetTitle,
        body: CRTITERESbodyProjetTitle,
        styles: {
          cellPadding: 3,
          fontSize: 10,
          textColor: [0, 0, 0],
        },

        columnStyles: {
          0: { cellWidth: 515 },
        },
        bodyStyles: {
          0: { fontStyle: 'bold' },
        },
      });

      //POLE EMPLOI
      const poleEmploiheadProjetTitle = [
        [
          {
            content: 'Pôle Emploi',
            colSpan: 4,
            styles: {
              font: 'helvetica',
              halign: 'left',
              fontStyle: 'bold',
              fillColor: [0, 55, 102],
              textColor: [255, 255, 255],
            },
          },
        ],
        [
          {
            content: 'Agence',
            styles: {
              fontStyle: 'normal',
              halign: 'left',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
          {
            content: 'Adresse',
            styles: {
              fontStyle: 'normal',
              halign: 'left',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
          {
            content: "Date d'inscription",
            styles: {
              fontStyle: 'normal',
              halign: 'left',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
          {
            content: 'Niminam socuaux',
            styles: {
              fontStyle: 'normal',
              halign: 'left',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
        ],
      ];

      const poleEmploibodyProjetTitle = [
        [
          {
            content: participant?.informations?.poleEmploi?.libelle
              ? participant?.informations?.poleEmploi?.libelle
              : 'Non renseignée',
            styles: {
              halign: 'left',
              fontStyle: 'normal',
            },
          },
          {
            content: participant?.informations?.poleEmploi?.adressePrincipale
              ? `${participant?.informations?.poleEmploi?.adressePrincipale?.ligne4}, ${participant?.informations?.poleEmploi?.adressePrincipale?.ligne6}`
              : 'Non renseignée',
            styles: {
              halign: 'left',
              fontStyle: 'normal',
              fontSize: 9,
            },
          },
          {
            content: participant?.informations?.poleEmploi?.dateStart
              ? format(
                  fromUnixTime(
                    participant?.informations?.poleEmploi?.dateStart['seconds']
                  ),
                  'dd/MM/yyyy',
                  { locale: fr }
                )
              : 'Non renseignée',
            styles: {
              halign: 'left',
              fontStyle: 'normal',
            },
          },
          {
            content: participant?.minimaSociaux
              ? participant?.minimaSociaux
              : 'Non renseigné',
            styles: {
              halign: 'left',
              fontStyle: 'normal',
            },
          },
        ],
      ];

      doc.autoTable({
        theme: 'grid',
        head: poleEmploiheadProjetTitle,
        body: poleEmploibodyProjetTitle,
        styles: {
          cellPadding: 3,
          fontSize: 10,
          textColor: [0, 0, 0],
        },

        columnStyles: {
          0: { cellWidth: 125 },
          1: { cellWidth: 150 },
          2: { cellWidth: 120 },
          3: { cellWidth: 120 },
        },
        bodyStyles: {
          0: { fontStyle: 'bold' },
        },
      });

      //MISSION LOCALE
      const missionLocaleheadProjetTitle = [
        [
          {
            content: 'Mission locale',
            colSpan: 2,
            styles: {
              font: 'helvetica',
              halign: 'left',
              fontStyle: 'bold',
              fillColor: [0, 55, 102],
              textColor: [255, 255, 255],
            },
          },
        ],
        [
          {
            content: 'Structure',
            styles: {
              fontStyle: 'normal',
              halign: 'left',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
          {
            content: 'Adresse',
            styles: {
              fontStyle: 'normal',
              halign: 'left',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
        ],
      ];

      const missionLocalebodyProjetTitle = [
        [
          {
            content: participant?.informations?.missionLocal?.denomination
              ? participant?.informations?.missionLocal?.denomination
              : 'Non renseignée',
            styles: {
              halign: 'left',
              fontStyle: 'normal',
            },
          },
          {
            content: participant?.informations?.missionLocal?.adresse
              ? participant?.informations?.missionLocal?.adresse
              : 'Non renseignée',
            styles: {
              halign: 'left',
              fontStyle: 'normal',
            },
          },
        ],
      ];

      doc.autoTable({
        theme: 'grid',
        head: missionLocaleheadProjetTitle,
        body: missionLocalebodyProjetTitle,
        styles: {
          cellPadding: 3,
          fontSize: 10,
          textColor: [0, 0, 0],
        },

        columnStyles: {
          0: { cellWidth: 215 },
          1: { cellWidth: 300 },
        },
        bodyStyles: {
          0: { fontStyle: 'bold' },
        },
      });

      //PRESCRIPTEUR
      const prescripteurheadProjetTitle = [
        [
          {
            content: 'Prescripteur',
            colSpan: 2,
            styles: {
              font: 'helvetica',
              halign: 'left',
              fontStyle: 'bold',
              fillColor: [0, 55, 102],
              textColor: [255, 255, 255],
            },
          },
        ],
        [
          {
            content: 'Raison sociale',
            styles: {
              fontStyle: 'normal',
              halign: 'left',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
          {
            content: 'Adresse',
            styles: {
              fontStyle: 'normal',
              halign: 'left',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
        ],
      ];

      const prescripteurbodyProjetTitle = [
        [
          {
            content: participant?.prescripteur?.etablissement
              ?.nom_raison_sociale
              ? participant?.prescripteur?.etablissement?.nom_raison_sociale.toUpperCase()
              : 'Non renseigné',
            styles: {
              halign: 'left',
              fontStyle: 'normal',
            },
          },
          {
            content: participant?.prescripteur?.etablissement?.geo_adresse
              ? participant?.prescripteur?.etablissement?.geo_adresse
              : 'Non renseignée',
            styles: {
              halign: 'left',
              fontStyle: 'normal',
            },
          },
        ],
      ];

      doc.autoTable({
        theme: 'grid',
        head: prescripteurheadProjetTitle,
        body: prescripteurbodyProjetTitle,
        styles: {
          cellPadding: 3,
          fontSize: 10,
          textColor: [0, 0, 0],
        },

        columnStyles: {
          0: { cellWidth: 215 },
          1: { cellWidth: 300 },
        },
        bodyStyles: {
          0: { fontStyle: 'bold' },
        },
      });

      //NIVEAU NUMERIQUE
      const NUMERIQUEheadProjetTitle = [
        [
          {
            content: 'Accès au numérique',
            colSpan: 4,
            styles: {
              font: 'helvetica',
              halign: 'left',
              fontStyle: 'bold',
              fillColor: [0, 55, 102],
              textColor: [255, 255, 255],
              fontSize: 11,
            },
          },
        ],
        [
          {
            content: "Dispose d'un mobile",
            styles: {
              fontStyle: 'normal',
              halign: 'center',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
          {
            content: "Dispose d'un ordinateur",
            styles: {
              fontStyle: 'normal',
              halign: 'center',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
          {
            content: "Dispose d'un accès internet",
            styles: {
              fontStyle: 'normal',
              halign: 'center',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
          {
            content: 'Niveau numérique',
            styles: {
              fontStyle: 'normal',
              halign: 'center',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
        ],
      ];

      const NUMERIQUEbodyProjetTitle = [
        [
          {
            content: participant?.situationPersonnelle?.mobile
              ? 'Oui'
              : 'Non renseigné',
            styles: {
              halign: 'center',
              fontStyle: 'normal',
            },
          },
          {
            content: participant?.situationPersonnelle?.ordinateur
              ? 'Oui'
              : 'Non renseignée',
            styles: {
              halign: 'center',
              fontStyle: 'normal',
            },
          },
          {
            content: participant?.situationPersonnelle?.internet
              ? 'Oui'
              : 'Non renseigné',
            styles: {
              halign: 'center',
              fontStyle: 'normal',
            },
          },
          {
            content: participant?.situationPersonnelle?.numerique?.length
              ? participant?.situationPersonnelle?.numerique
                  .map((el: any) => el.item)
                  .join(', ')
              : 'Non renseigné',
            styles: {
              halign: 'center',
              fontStyle: 'normal',
              fontSize: 9,
            },
          },
        ],
      ];

      doc.autoTable({
        theme: 'grid',
        head: NUMERIQUEheadProjetTitle,
        body: NUMERIQUEbodyProjetTitle,
        /* foot: foot, */
        styles: {
          cellPadding: 3,
          fontSize: 10,
          textColor: [0, 0, 0],
        },
        headStyles: {
          /* fillColor: [0, 0, 0], textColor: [255, 255, 255] */
        },
        columnStyles: {
          0: { cellWidth: 80 },
          1: { cellWidth: 80 },
          2: { cellWidth: 80 },
          3: { cellWidth: 275 },
        },
        bodyStyles: {
          0: { fontStyle: 'bold' },
        },
      });

      //PROJET PROFESSIONNEL && COMMENT VOUS NOUS AVEZ CONNU
      const PROJETPROheadProjetTitle = [
        [
          {
            content: 'Objectif professionnel',
            colSpan: 3,
            styles: {
              font: 'helvetica',
              halign: 'left',
              fontStyle: 'bold',
              fillColor: [0, 55, 102],
              textColor: [255, 255, 255],
            },
          },
        ],
        [
          {
            content: 'Mise en relation',
            styles: {
              fontStyle: 'normal',
              halign: 'left',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
          {
            content: 'Présentation',
            styles: {
              fontStyle: 'normal',
              halign: 'left',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
          {
            content: 'Projet professionnel',
            styles: {
              fontStyle: 'normal',
              halign: 'left',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
        ],
      ];

      const PROJETPRObodyProjetTitle = [
        [
          {
            content: participant?.commentVousNousAvezConu
              ? participant?.commentVousNousAvezConu
              : 'Non renseignée',
            styles: {
              halign: 'left',
              fontStyle: 'normal',
              fontSize: 9,
            },
          },
          {
            content: participant?.presentation
              ? participant?.presentation
              : 'Non renseignée',
            styles: {
              halign: 'left',
              fontStyle: 'normal',
              fontSize: 9,
            },
          },
          {
            content: participant?.objectifPro
              ? participant.objectifPro
              : 'Non renseigné',
            styles: {
              halign: 'left',
              fontStyle: 'normal',
              fontSize: 9,
            },
          },
        ],
      ];

      doc.autoTable({
        theme: 'grid',
        head: PROJETPROheadProjetTitle,
        body: PROJETPRObodyProjetTitle,
        styles: {
          cellPadding: 3,
          fontSize: 10,
          textColor: [0, 0, 0],
        },
        headStyles: {
          /* fillColor: [0, 0, 0], textColor: [255, 255, 255] */
        },
        columnStyles: {
          0: { cellWidth: 155 },
          1: { cellWidth: 180 },
          2: { cellWidth: 180 },
        },
        bodyStyles: {
          0: { fontStyle: 'bold' },
        },
      });

      // doc.addPage();
      // VUE D'ENSEMBLE

      //SORTIE
      const sortieheadProjetTitle = [
        [
          {
            content: 'Type de sortie',
            styles: {
              fontStyle: 'normal',
              halign: 'center',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
          {
            content: 'Motif de sortie',
            styles: {
              fontStyle: 'normal',
              halign: 'center',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
          {
            content: 'Date de sortie',
            styles: {
              fontStyle: 'normal',
              halign: 'center',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
          {
            content: 'Note',
            styles: {
              fontStyle: 'normal',
              halign: 'center',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
        ],
      ];

      const sortiebodyProjetTitle = [
        [
          {
            content: participant?.sortiProgrammeType?.type
              ? participant?.sortiProgrammeType?.type
              : 'Non renseigné',
            styles: {
              halign: 'center',
              fontStyle: 'bold',
            },
          },
          {
            content: participant?.sortiProgrammeType?.motif
              ? participant?.sortiProgrammeType?.motif
              : 'Non renseignée',
            styles: {
              halign: 'center',
              fontStyle: 'normal',
            },
          },
          {
            content: participant?.dateEnd
              ? format(
                  fromUnixTime(participant.dateEnd['seconds']),
                  'dd/MM/yyyy',
                  { locale: fr }
                )
              : 'Non renseignée',
            styles: {
              halign: 'center',
              fontStyle: 'normal',
            },
          },
          {
            content: participant?.sortiProgrammeQualitatif
              ? participant?.sortiProgrammeQualitatif
              : 'Non renseigné',
            styles: {
              halign: 'center',
              fontStyle: 'normal',
              fontSize: 9,
            },
          },
        ],
      ];

      doc.autoTable({
        theme: 'grid',
        head: sortieheadProjetTitle,
        body: sortiebodyProjetTitle,
        styles: {
          cellPadding: 3,
          fontSize: 10,
          textColor: [0, 0, 0],
        },

        columnStyles: {
          0: { cellWidth: 105 },
          1: { cellWidth: 105 },
          2: { cellWidth: 105 },
          3: { cellWidth: 200 },
        },
        bodyStyles: {
          0: { fontStyle: 'bold' },
        },
      });

      //IMPACTS
      //après 3 mois
      const impact3moisheadProjetTitle = [
        [
          {
            content: "Impact 3 mois après l'accompagnement",
            styles: {
              fontStyle: 'normal',
              halign: 'center',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
          {
            content: "Date d'entretien",
            styles: {
              fontStyle: 'normal',
              halign: 'center',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
          {
            content: 'Note',
            styles: {
              fontStyle: 'normal',
              halign: 'center',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
        ],
      ];

      const impact3moisbodyProjetTitle = [
        [
          {
            content: participant?.impact3mois?.type?.motif
              ? participant?.impact3mois?.type?.motif
              : 'Non renseigné',
            styles: {
              halign: 'left',
              fontStyle: 'bold',
            },
          },
          {
            content: participant?.impact3mois?.date
              ? format(
                  fromUnixTime(participant.impact3mois.date['seconds']),
                  'dd/MM/yyyy',
                  { locale: fr }
                )
              : 'Non renseignée',
            styles: {
              halign: 'left',
              fontStyle: 'normal',
            },
          },
          {
            content: participant?.impact3mois?.info
              ? participant?.impact3mois?.info
              : 'Non renseigné',
            styles: {
              halign: 'left',
              fontStyle: 'normal',
              fontSize: 9,
            },
          },
        ],
      ];

      doc.autoTable({
        theme: 'grid',
        head: impact3moisheadProjetTitle,
        body: impact3moisbodyProjetTitle,
        styles: {
          cellPadding: 3,
          fontSize: 10,
          textColor: [0, 0, 0],
        },

        columnStyles: {
          0: { cellWidth: 190 },
          1: { cellWidth: 85 },
          2: { cellWidth: 240 },
        },
        bodyStyles: {
          0: { fontStyle: 'bold' },
        },
      });

      //après 6 mois
      const impact6moisheadProjetTitle = [
        [
          {
            content: "Impact 6 mois après l'accompagnement",
            styles: {
              fontStyle: 'normal',
              halign: 'center',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
          {
            content: "Date d'entretien",
            styles: {
              fontStyle: 'normal',
              halign: 'center',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
          {
            content: 'Note',
            styles: {
              fontStyle: 'normal',
              halign: 'center',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
        ],
      ];

      const impact6moisbodyProjetTitle = [
        [
          {
            content: participant?.impact6mois?.type?.motif
              ? participant?.impact6mois?.type?.motif
              : 'Non renseigné',
            styles: {
              halign: 'left',
              fontStyle: 'bold',
            },
          },
          {
            content: participant?.impact6mois?.date
              ? format(
                  fromUnixTime(participant.impact6mois.date['seconds']),
                  'dd/MM/yyyy',
                  { locale: fr }
                )
              : 'Non renseignée',
            styles: {
              halign: 'left',
              fontStyle: 'normal',
            },
          },
          {
            content: participant?.impact6mois?.info
              ? participant?.impact6mois?.info
              : 'Non renseigné',
            styles: {
              halign: 'left',
              fontStyle: 'normal',
              fontSize: 9,
            },
          },
        ],
      ];

      doc.autoTable({
        theme: 'grid',
        head: impact6moisheadProjetTitle,
        body: impact6moisbodyProjetTitle,
        styles: {
          cellPadding: 3,
          fontSize: 10,
          textColor: [0, 0, 0],
        },

        columnStyles: {
          0: { cellWidth: 190 },
          1: { cellWidth: 85 },
          2: { cellWidth: 240 },
        },
        bodyStyles: {
          0: { fontStyle: 'bold' },
        },
      });

      //après 12 mois
      const impact12moisheadProjetTitle = [
        [
          {
            content: "Impact 12 mois après l'accompagnement",
            styles: {
              fontStyle: 'normal',
              halign: 'center',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
          {
            content: "Date d'entretien",
            styles: {
              fontStyle: 'normal',
              halign: 'center',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
          {
            content: 'Note',
            styles: {
              fontStyle: 'normal',
              halign: 'center',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
            },
          },
        ],
      ];

      const impact12moisbodyProjetTitle = [
        [
          {
            content: participant?.impact12mois?.type?.motif
              ? participant?.impact12mois?.type?.motif
              : 'Non renseigné',
            styles: {
              halign: 'left',
              fontStyle: 'bold',
            },
          },
          {
            content: participant?.impact12mois?.date
              ? format(
                  fromUnixTime(participant.impact12mois.date['seconds']),
                  'dd/MM/yyyy',
                  { locale: fr }
                )
              : 'Non renseignée',
            styles: {
              halign: 'left',
              fontStyle: 'normal',
            },
          },
          {
            content: participant?.impact12mois?.info
              ? participant?.impact12mois?.info
              : 'Non renseigné',
            styles: {
              halign: 'left',
              fontStyle: 'normal',
              fontSize: 9,
            },
          },
        ],
      ];
    });

    return doc.autoTable({
      theme: 'grid',
      head: PARTICIPANTSheader,
      body: PARTICIPANTSbody,
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },

      columnStyles: {
        0: { cellWidth: 190 },
        1: { cellWidth: 85 },
        2: { cellWidth: 240 },
      },
      bodyStyles: {
        0: { fontStyle: 'bold' },
      },

      didDrawPage: () => {
        /* PAGE FOOTER */

        const str = `Page ${doc.internal.getNumberOfPages()}`;
        // if (typeof doc.putTotalPages === 'function') {
        //    str = `${data.pageCount} sur ${str}`;
        //  }
        doc.setFontSize(7);
        doc.setFont('normal');
        doc.text(`${organisation}`, 280, 810);
        doc.text(`${str}`, 550, 810);
      },
    });
  }
}
