<nz-tag nzColor="gold" *ngIf="operation?.intervention?.isFinanceur"
  >Financeur</nz-tag
>
<nz-tag nzColor="geekblue" *ngIf="operation?.intervention?.isParrain"
  >Parrainage</nz-tag
>
<nz-tag
  nzColor="volcano"
  *ngIf="
    !operation?.intervention?.isFinanceur || !operation?.intervention?.isParrain
  "
>
  {{ operation?.intervention?.role | carLimitation: 15 }}
</nz-tag>
