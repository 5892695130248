<button
  nz-button
  nzType="text"
  nzShape="circle"
  nz-dropdown
  [nzDropdownMenu]="profilActionDropdownMenu"
  nzTrigger="click"
  nzPlacement="bottomRight"
>
  <mat-icon>more_vert</mat-icon>
</button>

<nz-dropdown-menu #profilActionDropdownMenu="nzDropdownMenu">
  <ul nz-menu>
    <li
      nz-menu-item
      (click)="$event.stopPropagation(); onUpdate(item)"
      *ngIf="isUpdate"
    >
      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col nzSpan="8"><mat-icon>mode_edit</mat-icon></nz-col>
        <nz-col nzSpan="16">Modifier</nz-col>
      </nz-row>
    </li>
    <li
      nz-menu-item
      (click)="$event.stopPropagation(); onExport(item)"
      *ngIf="isExport"
    >
      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col nzSpan="8"><mat-icon>download</mat-icon></nz-col>
        <nz-col nzSpan="16">Exporter</nz-col>
      </nz-row>
    </li>
    <li
      nz-menu-item
      (click)="$event.stopPropagation(); onExport(item)"
      *ngIf="isFavori"
    >
      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col nzSpan="8"><mat-icon>grade</mat-icon></nz-col>
        <nz-col nzSpan="16">Favorie</nz-col>
      </nz-row>
    </li>
    <li nz-menu-divider></li>
    <li
      *ngIf="isDelete"
      nz-menu-item
      nzDanger
      nz-popconfirm
      [nzPopconfirmTitle]="
        'Voulez-vous supprimer ' + (name | carLimitation: 25) + ' ?'
      "
      nzPopconfirmPlacement="bottom"
      (nzOnConfirm)="onDelete(item.id)"
      (nzOnCancel)="onCancel()"
      (click)="$event.stopPropagation()"
    >
      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col nzSpan="8"><mat-icon>delete</mat-icon></nz-col>
        <nz-col nzSpan="16">Supprimer</nz-col>
      </nz-row>
    </li>
  </ul>
</nz-dropdown-menu>
