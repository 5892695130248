import {
  ParticipantCriteres,
  ParticipantId,
} from 'src/app/components/participant/store/participant.model';
import { Injectable } from '@angular/core';
import { Firestore, doc, getDoc } from '@angular/fire/firestore';
import { Observable, of, from, EMPTY } from 'rxjs';
import { switchMap, mergeMap, map, catchError } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { UserState } from '../components/user/store/user.reducer';
import * as fromUserSelector from '../components/user/store/user.selectors';
import { toUpper, upperFirst, capitalize, forEach, camelCase } from 'lodash';
import {
  fromUnixTime,
  format,
  formatDistance,
  formatDistanceToNow,
  formatDistanceStrict,
  differenceInYears,
} from 'date-fns';
import { fr } from 'date-fns/locale';
// var FileSaver = require('file-saver');
import { saveAs } from 'file-saver';
declare const require: any;
const { jsPDF } = require('jspdf');
require('jspdf-autotable');
import * as html2canvas from 'html2canvas';
import { ParticipantState } from '../components/participant/store/participant.reducer';
import { ParticipantService } from './participant.service';
import * as fromParticipantAction from './../components/participant/store/participant.actions';
import * as fromParticipantSelector from './../components/participant/store/participant.selectors';

@Injectable({
  providedIn: 'any',
})
export class ParticipantExportPdfService {
  pageWidth: number = 515.28;
  constructor(
    private db: Firestore,
    private userStore: Store<UserState>,
    private participantStore: Store<ParticipantState>
  ) {}

  //ONE
  export_PARTICIPANT(
    participant: ParticipantId,
    incluSuivi?: boolean,
    cv?: boolean,
    anonym?: boolean
  ): Observable<any> {
    try {
      return this.userStore.pipe(
        select(fromUserSelector.user),
        switchMap((user) => {
          if (!user || !participant) EMPTY;

          const checkCrossOrigin = false;
          const dbDocument = doc(
            this.db,
            `organisations/${user.organisation.id}/participants/${participant.id}/statistiques/${participant.id}`
          );

          const statsDoc = getDoc(dbDocument).then((snap) => snap.data());

          return from(
            statsDoc
              .then((stats) => {
                const {
                  civilite,
                  lastName,
                  firstName,
                  informations,
                  operation,
                  referents,
                  dateStart,
                  dateEnd,
                  datetIntegration,
                  dateNaissance,
                  prescripteurs,
                  priseEnCharge,
                  situationPersonnelle,
                } = participant;

                const displayName = `${civilite}. ${lastName} ${firstName}`;
                const { adresse, poleEmploi, missionLocal } = informations;
                const partData = stats;
                const modules = partData?.modules ? partData?.modules : [];
                const actions = partData?.actions ? partData?.actions : [];
                const suivis =
                  partData?.suivis && incluSuivi ? partData?.suivis : [];
                const contrats = partData?.contrats ? partData?.contrats : [];

                const xhr = new XMLHttpRequest();
                xhr.open('GET', user.organisation.logo);
                xhr.responseType = 'blob';
                xhr.send();
                xhr.addEventListener('load', () => {
                  const reader = new FileReader();
                  reader.readAsDataURL(xhr.response);
                  reader.addEventListener('loadend', (): any => {
                    const logo = reader.result;
                    if (logo) {
                      const date = format(new Date(Date.now()), 'yyyyMM', {
                        locale: fr,
                      });

                      // Date
                      // set PDF
                      const pdfSize = 'a4';
                      const doc = new jsPDF('p', 'pt', pdfSize);
                      doc.page = 1;
                      // set propretie informations pdf
                      doc.setProperties({
                        title: `Fiche liaison ${displayName}`,
                        type: 'Fiche liaison',
                        particpant: `${displayName}`,
                        date: format(new Date(Date.now()), 'dd/MM/yyyy', {
                          locale: fr,
                        }),
                        author: user.displayName,
                        keywords: 'fiche liaison',
                      });

                      if (participant) {
                        //IDENTITE
                        this.identity_table(
                          displayName,
                          participant,
                          doc,
                          logo,
                          user,
                          anonym
                        );

                        //INFORMATIONS
                        this.informations_table(participant, doc);
                        //LINGUSTIQUE
                        this.lingustique_table(participant, doc);
                        //RQTH && MOBILITY
                        this.rqth_table(participant, doc);
                        //JUDICIAIRE
                        this.judiciaire_table(participant, doc);

                        //SITUATION FAMILIALE
                        this.situation_familiale_table(participant, doc);

                        //SEJOURS
                        this.sejours_table(participant, doc);

                        //HERBERGEMENT
                        this.herbergement_table(participant, doc, anonym);
                        this.herbergement1_table(participant, doc);
                        this.herbergement2_table(participant, doc);
                        this.herbergement3_table(participant, doc);

                        //COORDONNEES
                        this.coordonnees_table(participant, doc, anonym);

                        this.bureautique_table(participant, doc);

                        //NIVEAU NUMERIQUE
                        this.numerique_table(participant, doc);

                        //PERMIS
                        this.mobility_table(participant, doc);

                        //CRITERES
                        this.criteres_table(participant, doc);

                        //POLITIQUE EMPLOI
                        this.politiqueEmploi_table(adresse, doc);

                        //POLE EMPLOI
                        this.poleEmploi_table(participant, doc);

                        //AIDES
                        this.aide_table(stats, doc);

                        //PRESCRIPTEUR
                        this.prescripteurs_table(
                          participant.prescripteurs,
                          doc
                        );

                        //SORTIE
                        this.sortie_table(participant, doc);

                        //IMPACTS
                        //après 3 mois
                        participant?.impact3mois
                          ? this.impact_table(
                              participant.impact12mois,
                              doc,
                              '3'
                            )
                          : null;

                        //après 6 mois
                        participant?.impact6mois
                          ? this.impact_table(
                              participant.impact12mois,
                              doc,
                              '6'
                            )
                          : null;

                        //après 12 mois
                        participant?.impact12mois
                          ? this.impact_table(
                              participant.impact12mois,
                              doc,
                              '12'
                            )
                          : null;

                        //REFERENT
                        this.referents_tables(participant.referents, doc);

                        // doc.addPage();
                        // VUE D'ENSEMBLE
                        this.overview_table(
                          stats,
                          operation,
                          modules,
                          actions,
                          contrats,
                          suivis,
                          doc
                        );
                        //PROJETS PROFESSIONNELS
                        stats?.projetsprofessionnels?.data?.length
                          ? this.projetProfessionnels_table(stats, doc)
                          : null;

                        //PROJETS DETAILS
                        operation?.data?.length
                          ? this.operations_table(operation, doc)
                          : null;

                        //MODULES
                        modules?.data?.length
                          ? this.modules_table(modules, doc)
                          : null;

                        //ACTIONS
                        actions?.data?.length
                          ? this.actions_table(actions, doc)
                          : null;

                        //MISSIONS
                        actions?.data?.length
                          ? this.missions_table(actions, doc)
                          : null;

                        //SUIVIS
                        suivis?.data?.length
                          ? this.suivis_table(user, suivis, incluSuivi, doc)
                          : null;

                        this.footerPdf(user, doc);

                        //open || download || print
                        const nameFile: string = anonym
                          ? `Fiche_Liaison_${date}.pdf`
                          : `Fiche_Liaison_${civilite}. ${lastName} ${firstName}_${date}.pdf`;
                        doc.save(`${nameFile}`);
                      } else {
                        return of(null);
                      }
                    }
                  });

                  return of(null);
                });
              })
              .then(() => {
                this.participantStore.dispatch(
                  fromParticipantAction.loading({ loading: false })
                );
                return of(`Fiche participant téléchargée`);
              })
              // .then(() => {
              //   if (cv) {
              //     const list = this.participantsCollection
              //       .doc(participant.id)
              //       .collection('documents', (ref) =>
              //         ref.where('type', '==', 'Curriculum vitæ')
              //       )
              //       .get()
              //       .toPromise();

              //     list.then((snap:any) => {
              //       if (snap.size > 0) {
              //         snap.docs.forEach((doc:any) => {
              //           const docFile = doc.data();
              //           const displayName = camelCase(
              //             `${participant.lastName}${participant.firstName}`
              //           );
              //           const { type, file } = docFile;
              //           const fileName = `${displayName}_${type}${docFile.extension}`;

              //           FileSaver.saveAs(file, fileName);
              //         });
              //       } else {
              //         return of(null);
              //       }
              //     });
              //     return of(`Document téléchargé`);
              //   } else {
              //     return of(null);
              //   }
              // })
              .catch((err) => of(err.message))
          );
        })
      );
    } catch (err) {
      return of(err);
    }
  }

  //LIST
  export_PARTICIPANTS(
    ids: string[],
    incluSuivi?: boolean,
    cv?: boolean,
    anonym?: boolean
  ): Observable<any> {
    try {
      return this.userStore.pipe(
        select(fromUserSelector.user),
        switchMap((user) => {
          if (!user || !ids?.length) {
            return of(null);
          }

          ids.forEach((id: string) => {
            const dbDocument = doc(
              this.db,
              `organisations/${user.organisation.id}/participants/${id}/details/${id}`
            );

            const participantData = getDoc(dbDocument).then((snap) => {
              const id = snap.id;
              const data = snap.data() as any;
              return { id, ...data };
            });

            participantData.then((participant) => {
              this.export_PARTICIPANT(participant, incluSuivi, cv, anonym);
            });
          });

          return of(null);
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  //UTILS
  headerPdf(data: any, user: any, doc: any, anonym: boolean, logo: any): any {
    doc.setFont('helvetica', 'normal');

    doc.setFontSize(10);
    doc.setFont('helvetica', 'bold');
    doc.text(`${user?.organisation?.nom_raison_sociale}`, 40, 40);
    doc.setFontSize(7);
    doc.setFont('helvetica', 'normal');
    doc.text(`Siret : ${user?.organisation?.siret}`, 40, 50);

    doc.text(40, 58, `Adresse : ${user?.organisation?.geo_adresse}`, {
      maxWidth: 140,
    });

    doc.setFontSize(10);
    doc.setFont('helvetica', 'bold');
    doc.text(
      `Date de création : ${format(new Date(Date.now()), 'dd/MM/yyyy', {
        locale: fr,
      })}`,
      40,
      90
    );

    // Set author
    doc.setFontSize(14);
    doc.setFont('helvetica', 'normal');
    doc.setFont('helvetica', 'bold');
    doc.text(`Fiche de liaison`, 257.64, 40);
    doc.setFont('helvetica', 'italic');
    doc.setFontSize(12);
    doc.text(`${anonym ? 'Anonymisée' : ''}`, 257.64, 60);

    // Set logo organisme
    doc.setFontSize(10);
    // doc.addImage(`${logo ? logo : ''}`, 'PNG', 480, 15, 80, 80);
  }

  footerPdf(user: any, doc: any): any {
    const pageCount = doc.internal.getNumberOfPages();
    let str = `Page ${doc.internal.getNumberOfPages()}`;
    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(7);
      doc.text(
        'Page ' + String(i) + ' sur ' + String(pageCount),
        515.28,
        810,
        null,
        null,
        'right'
      );
    }

    doc.setFontSize(7);
    doc.setFont('helvetica', 'normal');
    doc.text(
      `${user.organisation.nom_raison_sociale}`,
      280,
      810,
      null,
      null,
      'center'
    );
  }

  identity_table(
    displayName: string,
    participant: ParticipantId,
    doc: any,
    logo: any,
    user: any,
    anonym: boolean = false
  ): any {
    const PARTICIPANTheadTitle = [
      [
        {
          content: 'Participant',
          colSpan: 4,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'bold',
            fillColor: [0, 55, 102],
            textColor: [255, 255, 255],
          },
        },
      ],
      [
        {
          content: 'Identité',
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Sexe',
          styles: {
            fontStyle: 'normal',
            halign: 'center',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Naissance',
          styles: {
            fontStyle: 'normal',
            halign: 'center',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Âge',
          styles: {
            fontStyle: 'normal',
            halign: 'center',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
    ];

    const PARTICIPANTbodyTitle = [
      [
        {
          content: anonym ? '' : `${displayName.toUpperCase()}`,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: participant.sexe ? participant.sexe : 'Non renseigné',
          styles: {
            halign: 'center',
            fontStyle: 'normal',
          },
        },
        {
          content: participant?.dateNaissance
            ? format(
                fromUnixTime(participant?.dateNaissance['seconds']),
                'dd/MM/yyyy',
                { locale: fr }
              )
            : 'Non renseignée',
          styles: {
            halign: 'center',
            fontStyle: 'normal',
          },
        },
        {
          content: participant?.dateNaissance
            ? `${differenceInYears(
                new Date(Date.now()),
                fromUnixTime(participant?.dateNaissance['seconds'])
              )} ans`
            : 'Non renseigné',
          styles: {
            halign: 'center',
            fontStyle: 'normal',
          },
        },
      ],
    ];

    doc.autoTable({
      theme: 'grid',
      head: PARTICIPANTheadTitle,
      body: PARTICIPANTbodyTitle,
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },
      headStyles: {},
      columnStyles: {
        0: { cellWidth: 290.28 },
        1: { cellWidth: 75 },
        2: { cellWidth: 75 },
        3: { cellWidth: 75 },
      },

      bodyStyles: {
        0: { fontStyle: 'bold' },
      },

      didDrawPage: (data: any) => {
        this.headerPdf(data, user, doc, anonym, logo);
      },

      margin: { top: 100 },
    });
  }

  informations_table(participant: ParticipantId, doc: any): any {
    const info2headProjetTitle = [
      [
        {
          content: 'Nationalité',
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Qualification',
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Droit de travail',
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
    ];

    const info2bodyProjetTitle = [
      [
        {
          content: participant?.nationality?.nationalite
            ? participant?.nationality?.nationalite
            : 'Non renseignée',
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: participant?.maitriseFrancais?.item
            ? `${participant?.maitriseFrancais?.item} - ${participant?.maitriseFrancais?.type}`
            : 'Non renseignée',
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: participant?.qualification?.titre
            ? `${participant?.qualification?.titre} | ${participant?.qualification?.niveau} | ${participant?.qualification?.apresBac}`
            : 'Non renseignée',
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: participant?.droitDeTravail ? 'Oui' : 'Non renseigné',
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
    ];

    doc.autoTable({
      theme: 'grid',
      head: info2headProjetTitle,
      body: info2bodyProjetTitle,
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },

      columnStyles: {
        0: { cellWidth: 171.76 },
        1: { cellWidth: 171.76 },
        2: { cellWidth: 171.76 },
      },
      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
    });
  }

  lingustique_table(participant: ParticipantId, doc: any): any {
    const info2headProjetTitle = [
      [
        {
          content: 'Niveau de français oral',
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Niveau de français écrit',
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
    ];

    const info2bodyProjetTitle = [
      [
        {
          content: participant?.maitriseFrancais?.item
            ? `${participant?.maitriseFrancais?.item} - ${participant?.maitriseFrancais?.type}`
            : 'Non renseignée',
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: participant?.maitriseFrancaisEcrit?.item
            ? `${participant?.maitriseFrancaisEcrit?.item} - ${participant?.maitriseFrancaisEcrit?.type}`
            : 'Non renseignée',
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
    ];

    doc.autoTable({
      theme: 'grid',
      head: info2headProjetTitle,
      body: info2bodyProjetTitle,
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },

      columnStyles: {
        0: { cellWidth: 257.64 },
        1: { cellWidth: 257.64 },
      },
      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
    });
  }

  situation_familiale_table(participant: ParticipantId, doc: any): any {
    const familyTitle = [
      [
        {
          content: 'Situation familiale',
          colSpan: 4,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'bold',
            fillColor: [0, 55, 102],
            textColor: [255, 255, 255],
          },
        },
      ],
      [
        {
          content: 'Matrimoniale',
          styles: {
            fontStyle: 'normal',
            halign: 'center',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Enfants à charge',
          styles: {
            fontStyle: 'normal',
            halign: 'center',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Moyen de garde',
          styles: {
            fontStyle: 'normal',
            halign: 'center',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Enfants scolarié',
          styles: {
            fontStyle: 'normal',
            halign: 'center',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
    ];

    const familyBody = [
      [
        {
          content: participant?.situationPersonnelle?.familiale
            ? participant?.situationPersonnelle?.familiale
            : 'Non renseigné',
          styles: {
            halign: 'center',
            fontStyle: 'normal',
          },
        },

        {
          content: participant?.situationPersonnelle?.enfantsAcharges
            ? 'Oui'
            : 'Non renseigné',
          styles: {
            halign: 'center',
            fontStyle: 'normal',
          },
        },
        {
          content: participant?.situationPersonnelle?.enfantMoyenDeGarde
            ? `Oui`
            : 'Non renseignée',
          styles: {
            halign: 'center',
            fontStyle: 'normal',
          },
        },
        {
          content: participant?.situationPersonnelle?.enfantScolarise
            ? 'Oui'
            : 'Non renseignée',
          styles: {
            halign: 'center',
            fontStyle: 'normal',
          },
        },
      ],
    ];

    doc.autoTable({
      theme: 'grid',
      head: familyTitle,
      body: familyBody,
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },

      columnStyles: {
        0: { cellWidth: 125 },
        1: { cellWidth: 135 },
        2: { cellWidth: 135 },
        3: { cellWidth: 120.28 },
      },
      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
    });
  }

  sejours_table(participant: ParticipantId, doc: any): any {
    const sejoursheadProjetTitle = [
      [
        {
          content: 'Séjour',
          colSpan: 2,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'bold',
            fillColor: [0, 55, 102],
            textColor: [255, 255, 255],
          },
        },
      ],
      [
        {
          content: 'Type de séjour',
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Carte de séjour',
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
    ];

    const sejoursbodyProjetTitle = [
      [
        {
          content: participant?.typeCarteSejour?.type
            ? participant?.typeCarteSejour?.type
            : 'Non renseigné',
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: participant?.typeCarteSejour?.titre
            ? participant?.typeCarteSejour?.titre
            : 'Non renseigné',
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
    ];

    doc.autoTable({
      theme: 'grid',
      head: sejoursheadProjetTitle,
      body: sejoursbodyProjetTitle,
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },

      columnStyles: {
        0: { cellWidth: 257.64 },
        1: { cellWidth: 257.64 },
      },
      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
    });
  }

  judiciaire_table(participant: ParticipantId, doc: any): any {
    const judiciaireTitle = [
      [
        {
          content: 'Casier judiciaire',
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Restriction judiciaire',
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
    ];

    const judiciaireBody = [
      [
        {
          content: participant?.situationPersonnelle?.caseJudiciaire
            ? 'Oui'
            : 'Non renseignée',
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: participant?.situationPersonnelle?.justice
            ? participant?.situationPersonnelle?.justice
            : 'Non renseignée',
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
    ];

    doc.autoTable({
      theme: 'grid',
      head: judiciaireTitle,
      body: judiciaireBody,
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },

      columnStyles: {
        0: { cellWidth: 257.64 },
        1: { cellWidth: 257.64 },
      },
      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
    });
  }

  herbergement_table(
    participant: ParticipantId,
    doc: any,
    anonym: boolean = false
  ): any {
    const city = participant.informations.adresse?.adresse?.properties?.city
      ? participant.informations.adresse?.adresse.properties?.city
      : participant.informations.adresse?.fields?.properties?.city
      ? participant.informations.adresse?.fields.properties?.city
      : 'Non renseignée';

    const postcode = participant.informations.adresse?.adresse?.properties
      ?.postcode
      ? participant.informations.adresse?.adresse.properties?.postcode
      : participant.informations.adresse?.fields?.properties?.postcode
      ? participant.informations.adresse?.fields.properties?.postcode
      : 'Non renseigné';

    const adresse = participant.informations.adresse?.adresse?.properties?.label
      ? participant.informations.adresse?.adresse.properties?.label
      : participant.informations.adresse?.fields?.properties?.label
      ? participant.informations.adresse?.fields.properties?.label
      : 'Non renseigné';

    const hebergementTitle = [
      [
        {
          content: 'Hébergement',
          colSpan: 3,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'bold',
            fillColor: [0, 55, 102],
            textColor: [255, 255, 255],
          },
        },
      ],
      [
        {
          content: 'Commune',
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Département',
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Adresse',
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
    ];

    const hebergementBody = [
      [
        {
          content: city,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: postcode,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: anonym ? '' : adresse,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
    ];

    doc.autoTable({
      theme: 'grid',
      head: hebergementTitle,
      body: hebergementBody,
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },

      columnStyles: {
        0: { cellWidth: 171.76 },
        1: { cellWidth: 171.76 },
        2: { cellWidth: 171.76 },
      },
      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
    });
  }

  herbergement1_table(participant: ParticipantId, doc: any): any {
    const hebergementTitle = [
      [
        {
          content: 'Propriétaire',
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Locataire',
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Chez ses parents',
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Chez un tier',
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
    ];

    const hebergementBody = [
      [
        {
          content: participant?.situationPersonnelle?.hebergement?.proprietaire
            ? 'Oui'
            : 'Non',
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: participant?.situationPersonnelle?.hebergement?.locataire
            ? 'Oui'
            : 'Non',
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: participant?.situationPersonnelle?.hebergement?.chezParent
            ? 'Oui'
            : 'Non',
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: participant?.situationPersonnelle?.hebergement?.chezTier
            ? 'Oui'
            : 'Non',
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
    ];

    doc.autoTable({
      theme: 'grid',
      head: hebergementTitle,
      body: hebergementBody,
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },

      columnStyles: {
        0: { cellWidth: 128.82 },
        1: { cellWidth: 128.82 },
        2: { cellWidth: 128.82 },
        3: { cellWidth: 128.82 },
      },
      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
    });
  }

  herbergement2_table(participant: ParticipantId, doc: any): any {
    const hebergementTitle = [
      [
        {
          content: 'Dans un hôtel',
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: "En centre d'hébergement",
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Dans un lieu public',
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Dans un autre lieu',
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
    ];

    const hebergementBody = [
      [
        {
          content: participant?.situationPersonnelle?.hebergement?.hotel
            ? 'Oui'
            : 'Non',
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: participant?.situationPersonnelle?.hebergement
            ?.centreHebergement
            ? 'Oui'
            : 'Non',
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: participant?.situationPersonnelle?.hebergement?.lieuPublic
            ? 'Oui'
            : 'Non',
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: participant?.situationPersonnelle?.hebergement?.autreLieu
            ? 'Oui'
            : 'Non',
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
    ];

    doc.autoTable({
      theme: 'grid',
      head: hebergementTitle,
      body: hebergementBody,
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },

      columnStyles: {
        0: { cellWidth: 128.82 },
        1: { cellWidth: 128.82 },
        2: { cellWidth: 128.82 },
        3: { cellWidth: 128.82 },
      },
      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
    });
  }

  herbergement3_table(participant: ParticipantId, doc: any): any {
    const hebergementTitle = [
      [
        {
          content: 'Autre',
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Ne sait pas',
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Précision',
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
    ];

    const hebergementBody = [
      [
        {
          content: participant?.situationPersonnelle?.hebergement?.autre
            ? 'Oui'
            : 'Non',
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: participant?.situationPersonnelle?.hebergement?.nesaitpas
            ? 'Oui'
            : 'Non',
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: participant?.situationPersonnelle?.hebergementPrecision
            ? participant?.situationPersonnelle?.hebergementPrecision
            : 'Non',
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
    ];

    doc.autoTable({
      theme: 'grid',
      head: hebergementTitle,
      body: hebergementBody,
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },

      columnStyles: {
        0: { cellWidth: 171.76 },
        1: { cellWidth: 171.76 },
        2: { cellWidth: 171.76 },
      },
      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
    });
  }

  adresse_table(adresse: any, doc: any): any {
    const localisationheadProjetTitle = [
      [
        {
          content: 'Commune',
          styles: {
            fontStyle: 'normal',
            halign: 'center',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Département',
          styles: {
            fontStyle: 'normal',
            halign: 'center',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Contexte',
          styles: {
            fontStyle: 'normal',
            halign: 'center',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
    ];

    const city = adresse?.adresse?.properties?.city
      ? adresse?.adresse.properties?.city
      : adresse?.fields?.properties?.city
      ? adresse?.fields.properties?.city
      : 'Non renseignée';

    const postcode = adresse?.adresse?.properties?.postcode
      ? adresse?.adresse.properties?.postcode
      : adresse?.fields?.properties?.postcode
      ? adresse?.fields.properties?.postcode
      : 'Non renseigné';

    const context = adresse?.adresse?.properties?.context
      ? adresse?.adresse.properties?.context
      : adresse?.fields?.properties?.context
      ? adresse?.fields.properties?.context
      : 'Non renseigné';

    const localisationbodyProjetTitle = [
      [
        {
          content: city,
          styles: {
            halign: 'center',
            fontStyle: 'normal',
          },
        },
        {
          content: postcode,
          styles: {
            halign: 'center',
            fontStyle: 'normal',
          },
        },
        {
          content: context,
          styles: {
            halign: 'center',
            fontStyle: 'normal',
          },
        },
      ],
    ];

    doc.autoTable({
      theme: 'grid',
      head: localisationheadProjetTitle,
      body: localisationbodyProjetTitle,
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },

      columnStyles: {
        0: { cellWidth: 125.28 },
        1: { cellWidth: 150 },
        2: { cellWidth: 240 },
      },
      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
    });
  }

  coordonnees_table(
    participant: ParticipantId,
    doc: any,
    anonym: boolean = false
  ): void {
    const coordonneesheadProjetTitle = [
      [
        {
          content: 'Coordonnées',
          colSpan: 4,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'bold',
            fillColor: [0, 55, 102],
            textColor: [255, 255, 255],
          },
        },
      ],
      [
        {
          content: 'Type de communication',
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Mobile',
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Fixe',
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'E-mail',
          styles: {
            fontStyle: 'left',
            halign: 'center',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
    ];

    const coordonneesbodyProjetTitle = [
      [
        {
          content: participant?.communication
            ? participant.communication
            : 'Aucun',
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: anonym
            ? ''
            : participant?.informations?.coordonnees?.mobile
            ? participant?.informations?.coordonnees?.mobile
            : 'Aucun',
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: anonym
            ? ''
            : participant?.informations?.coordonnees?.fixe
            ? participant?.informations?.coordonnees?.fixe
            : 'Aucun',
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: anonym
            ? ''
            : participant?.informations?.coordonnees?.email
            ? participant?.informations?.coordonnees?.email
            : 'Aucun',
          styles: {
            halign: 'left',
            fontStyle: 'normal',
            fontSize: 9,
          },
        },
      ],
    ];

    doc.autoTable({
      theme: 'grid',
      head: coordonneesheadProjetTitle,
      body: coordonneesbodyProjetTitle,
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },

      columnStyles: {
        0: { cellWidth: 75.28 },
        1: { cellWidth: 100 },
        2: { cellWidth: 100 },
        3: { cellWidth: 240 },
      },
      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
    });
  }

  politiqueEmploi_table(adresse: any, doc: any): any {
    const politiqueEmploiTitle = [
      [
        {
          content: "Politiques de l'emploi",
          colSpan: 3,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'bold',
            fillColor: [0, 55, 102],
            textColor: [255, 255, 255],
          },
        },
      ],
      [
        {
          content: 'Quartier Politique de la Ville',
          styles: {
            fontStyle: 'normal',
            halign: 'center',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Quartiers de veille active',
          styles: {
            fontStyle: 'normal',
            halign: 'center',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Zone de Revitalisation Rurale',
          styles: {
            fontStyle: 'normal',
            halign: 'center',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
    ];

    const qpv = adresse?.isQPV ? 'Oui' : 'Non';

    const qva = adresse?.isQVA ? 'Oui' : 'Non';

    const zrr = adresse?.isZRR ? 'Oui' : 'Non';

    const politiqueEmploi = [
      [
        {
          content: qpv,
          styles: {
            halign: 'center',
            fontStyle: 'normal',
          },
        },
        {
          content: qva,
          styles: {
            halign: 'center',
            fontStyle: 'normal',
          },
        },
        {
          content: zrr,
          styles: {
            halign: 'center',
            fontStyle: 'normal',
          },
        },
      ],
    ];

    doc.autoTable({
      theme: 'grid',
      head: politiqueEmploiTitle,
      body: politiqueEmploi,
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },

      columnStyles: {
        0: { cellWidth: 170.28 },
        1: { cellWidth: 175 },
        2: { cellWidth: 170 },
      },
      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
    });
  }

  rqth_table(participant: ParticipantId, doc: any): any {
    const rqthMobilityheadProjetTitle = [
      [
        {
          content: 'RQTH',
          styles: {
            fontStyle: 'normal',
            halign: 'center',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Handicap',
          styles: {
            fontStyle: 'normal',
            halign: 'center',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Santé',
          styles: {
            fontStyle: 'normal',
            halign: 'center',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
    ];

    const rqthMobilitybodyProjetTitle = [
      [
        {
          content: participant?.rqth ? 'Oui' : 'Non renseigné',
          styles: {
            halign: 'center',
            fontStyle: 'normal',
          },
        },
        {
          content: participant?.situationPersonnelle?.handicap?.item
            ? participant?.situationPersonnelle?.handicap?.item
            : 'Non renseigné',
          styles: {
            halign: 'center',
            fontStyle: 'normal',
          },
        },
        {
          content: participant?.situationPersonnelle?.sante
            ? participant?.situationPersonnelle?.sante
            : 'Non renseigné',
          styles: {
            halign: 'center',
            fontStyle: 'normal',
          },
        },
      ],
    ];

    doc.autoTable({
      theme: 'grid',
      head: rqthMobilityheadProjetTitle,
      body: rqthMobilitybodyProjetTitle,
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },

      columnStyles: {
        0: { cellWidth: 171.76 },
        1: { cellWidth: 171.76 },
        2: { cellWidth: 171.76 },
      },
      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
    });
  }

  numerique_table(participant: ParticipantId, doc: any): any {
    const NUMERIQUEheadProjetTitle = [
      [
        {
          content: 'Accès au numérique',
          colSpan: 4,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'bold',
            fillColor: [0, 55, 102],
            textColor: [255, 255, 255],
          },
        },
      ],
      [
        {
          content: "Dispose d'un mobile",
          styles: {
            fontStyle: 'normal',
            halign: 'center',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: "Dispose d'un ordinateur",
          styles: {
            fontStyle: 'normal',
            halign: 'center',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: "Dispose d'un accès internet",
          styles: {
            fontStyle: 'normal',
            halign: 'center',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Niveau numérique',
          styles: {
            fontStyle: 'normal',
            halign: 'center',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
    ];

    const NUMERIQUEbodyProjetTitle = [
      [
        {
          content: participant?.situationPersonnelle?.mobile
            ? 'Oui'
            : 'Non renseigné',
          styles: {
            halign: 'center',
            fontStyle: 'normal',
          },
        },
        {
          content: participant?.situationPersonnelle?.ordinateur
            ? 'Oui'
            : 'Non renseignée',
          styles: {
            halign: 'center',
            fontStyle: 'normal',
          },
        },
        {
          content: participant?.situationPersonnelle?.internet
            ? 'Oui'
            : 'Non renseigné',
          styles: {
            halign: 'center',
            fontStyle: 'normal',
          },
        },
        {
          content: participant?.situationPersonnelle?.numerique?.length
            ? participant?.situationPersonnelle?.numerique
                .map((el: any) => el.item)
                .join(', ')
            : 'Non renseigné',
          styles: {
            halign: 'center',
            fontStyle: 'normal',
          },
        },
      ],
    ];

    doc.autoTable({
      theme: 'grid',
      head: NUMERIQUEheadProjetTitle,
      body: NUMERIQUEbodyProjetTitle,
      /* foot: foot, */
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },
      headStyles: {
        /* fillColor: [0, 0, 0], textColor: [255, 255, 255] */
      },
      columnStyles: {
        0: { cellWidth: 80.28 },
        1: { cellWidth: 80 },
        2: { cellWidth: 80 },
        3: { cellWidth: 275 },
      },
      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
    });
  }

  bureautique_table(participant: ParticipantId, doc: any): any {
    const bureautiqueTitle = [
      [
        {
          content: 'Niveau en bureautique',
          colSpan: 2,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'bold',
            fillColor: [0, 55, 102],
            textColor: [255, 255, 255],
          },
        },
      ],
      [
        {
          content: 'Niveau en bureautique',
          styles: {
            fontStyle: 'normal',
            halign: 'center',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Outils bureautiques',
          styles: {
            fontStyle: 'normal',
            halign: 'center',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
    ];

    const bureautiqueBody = [
      [
        {
          content: participant?.situationPersonnelle?.bureautique_level
            ? participant?.situationPersonnelle?.bureautique_level.NAME
            : 'Non renseignée',
          styles: {
            halign: 'center',
            fontStyle: 'normal',
          },
        },
        {
          content: participant?.situationPersonnelle?.bureautique_tools?.length
            ? participant?.situationPersonnelle?.bureautique_tools
                .map((el: any) => el.NAME)
                .join(', ')
            : 'Non renseignée',
          styles: {
            halign: 'center',
            fontStyle: 'normal',
          },
        },
      ],
    ];

    doc.autoTable({
      theme: 'grid',
      head: bureautiqueTitle,
      body: bureautiqueBody,
      /* foot: foot, */
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },
      headStyles: {
        /* fillColor: [0, 0, 0], textColor: [255, 255, 255] */
      },
      columnStyles: {
        0: { cellWidth: 257.64 },
        1: { cellWidth: 257.64 },
      },
      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
    });
  }

  mobility_table(participant: ParticipantId, doc: any): any {
    const mobilityTitle = [
      [
        {
          content: 'Mobilité',
          colSpan: 3,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'bold',
            fillColor: [0, 55, 102],
            textColor: [255, 255, 255],
          },
        },
      ],
      [
        {
          content: 'Mode de transport',
          styles: {
            fontStyle: 'normal',
            halign: 'center',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Mobilité professionnelle',
          styles: {
            fontStyle: 'normal',
            halign: 'center',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Permis',
          styles: {
            fontStyle: 'normal',
            halign: 'center',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
    ];

    const mobilityBody = [
      [
        {
          content: participant?.situationPersonnelle?.mobilite?.length
            ? participant.situationPersonnelle.mobilite
                .map((el: any) => el)
                .join(', ')
            : 'Non renseigné',
          styles: {
            halign: 'center',
            fontStyle: 'normal',
          },
        },
        {
          content: participant?.situationPersonnelle?.geo_mobility?.length
            ? participant.situationPersonnelle.geo_mobility
                .map((el: any) => el.NAME)
                .join(', ')
            : 'Non renseigné',
          styles: {
            halign: 'center',
            fontStyle: 'normal',
          },
        },
        {
          content: participant?.situationPersonnelle?.permis?.length
            ? participant.situationPersonnelle.permis
                .map((el: any) => el.NAME)
                .join(', ')
            : 'Non renseigné',
          styles: {
            halign: 'center',
            fontStyle: 'normal',
          },
        },
      ],
    ];

    doc.autoTable({
      theme: 'grid',
      head: mobilityTitle,
      body: mobilityBody,
      /* foot: foot, */
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },
      headStyles: {
        /* fillColor: [0, 0, 0], textColor: [255, 255, 255] */
      },
      columnStyles: {
        0: { cellWidth: 100 },
        1: { cellWidth: 100 },
        2: { cellWidth: 315.28 },
      },
      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
    });
  }

  referents_tables(referents: any, doc: any): any {
    const REFERENTheadProjetTitle = [
      [
        {
          content: 'Référents.es',
          colSpan: 3,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'bold',
            fillColor: [0, 55, 102],
            textColor: [255, 255, 255],
          },
        },
      ],
      [
        {
          content: 'Identité',
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Fonction',
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Service',
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
    ];

    const REFERENTbodyProjetTitle = referents?.length
      ? referents.map((el: any) => {
          const referentData = [
            {
              content: el?.displayName ? el?.displayName : 'Non renseignée',
              styles: {
                halign: 'left',
                fontStyle: 'normal',
              },
            },
            {
              content: el?.fonction ? el?.fonction : 'Non renseignée',
              styles: {
                halign: 'left',
                fontStyle: 'normal',
              },
            },
            {
              content: el?.service?.title
                ? el?.service?.title
                : 'Non renseigné',
              styles: {
                halign: 'left',
                fontStyle: 'normal',
              },
            },
          ];
          return referentData;
        })
      : [];

    doc.autoTable({
      theme: 'grid',
      head: REFERENTheadProjetTitle,
      body: REFERENTbodyProjetTitle,
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },
      headStyles: {
        /* fillColor: [0, 0, 0], textColor: [255, 255, 255] */
      },
      columnStyles: {
        0: { cellWidth: 220 },
        1: { cellWidth: 200 },
        2: { cellWidth: 95.28 },
      },
      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
    });
  }
  prescripteurs_table(prescripteurs: any, doc: any): any {
    const prescripteurheadProjetTitle = [
      [
        {
          content: 'Prescripteurs',
          colSpan: 3,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'bold',
            fillColor: [0, 55, 102],
            textColor: [255, 255, 255],
          },
        },
      ],
      [
        {
          content: 'Dénomination',
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Activité',
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Statut',
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
    ];

    const prescripteurbodyProjetTitle = prescripteurs?.length
      ? prescripteurs.map((prescripteur: any) => {
          return [
            {
              content: prescripteur?.denomination
                ? prescripteur?.denomination
                : 'Non renseigné',
              styles: {
                halign: 'left',
                fontStyle: 'normal',
              },
            },
            {
              content: prescripteur?.activite_principale_libelle?.intitule_naf
                ? prescripteur?.activite_principale_libelle?.intitule_naf
                : 'Non renseignée',
              styles: {
                halign: 'left',
                fontStyle: 'normal',
              },
            },
            {
              content: prescripteur?.categorie_juridique_libelle?.libelle
                ? prescripteur?.categorie_juridique_libelle?.libelle
                : 'Non renseignée',
              styles: {
                halign: 'left',
                fontStyle: 'normal',
              },
            },
          ];
        })
      : [];
    doc.autoTable({
      theme: 'grid',
      head: prescripteurheadProjetTitle,
      body: prescripteurbodyProjetTitle,
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },

      columnStyles: {
        0: { cellWidth: 215 },
        1: { cellWidth: 150.28 },
        2: { cellWidth: 150 },
      },
      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
    });
  }

  overview_table(
    stats: any,
    operation: any,
    modules: any,
    actions: any,
    contrats: any,
    suivis: any,
    doc: any
  ): any {
    const headEnsemble = [
      [
        {
          content: "Vue d'ensemble",
          colSpan: 6,
          styles: {
            font: 'helvetica',
            halign: 'center',
            fontStyle: 'bold',
            fillColor: [0, 55, 102],
            textColor: [255, 255, 255],
          },
        },
      ],
      [
        {
          content: 'Projets professionnels',
          styles: {
            fontStyle: 'normal',
            halign: 'center',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Projets',
          styles: {
            fontStyle: 'normal',
            halign: 'center',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
            cellPadding: 4,
          },
        },
        {
          content: 'Modules',
          styles: {
            fontStyle: 'normal',
            halign: 'center',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
            cellPadding: 4,
          },
        },
        {
          content: 'Actions',
          styles: {
            fontStyle: 'normal',
            halign: 'center',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Missions',
          styles: {
            fontStyle: 'normal',
            halign: 'center',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },

        {
          content: 'Suivis',
          styles: {
            fontStyle: 'normal',
            halign: 'center',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
    ];

    const bodyEnsemble = [
      [
        {
          content: stats?.projetsprofessionnels?.data?.length
            ? stats?.projetsprofessionnels?.data?.length
            : 'Aucun',
          styles: {
            halign: 'center',
            fontStyle: 'bold',
          },
        },
        {
          content: stats?.operation?.length
            ? stats?.operation?.length
            : 'Aucun',
          styles: {
            halign: 'center',
            fontStyle: 'bold',
          },
        },
        {
          content: stats?.modules?.data?.length
            ? stats?.modules.data.length
            : 'Aucun',
          styles: {
            halign: 'center',
            fontStyle: 'bold',
          },
        },
        {
          content: stats?.actions?.data?.length
            ? stats?.actions.data?.length
            : 'Aucune',
          styles: {
            halign: 'center',
            fontStyle: 'bold',
          },
        },
        {
          content: stats?.missions?.data?.length
            ? stats?.missions?.data?.length
            : 'Aucun',
          styles: {
            halign: 'center',
            fontStyle: 'bold',
          },
        },

        {
          content: stats?.suivis?.data?.length
            ? stats?.suivis.data.length
            : 'Aucun',
          styles: {
            halign: 'center',
            fontStyle: 'bold',
          },
        },
      ],
    ];

    doc.autoTable({
      theme: 'grid',
      head: headEnsemble,
      body: bodyEnsemble,
      /* foot: foot, */
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },
      headStyles: {
        /* fillColor: [0, 0, 0], textColor: [255, 255, 255] */
      },

      columnStyles: {
        0: { cellWidth: 85.88 },
        1: { cellWidth: 85.88 },
        2: { cellWidth: 85.88 },
        3: { cellWidth: 85.88 },
        4: { cellWidth: 85.88 },
        5: { cellWidth: 85.88 },
      },

      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
    });
  }

  criteres_table(participant: ParticipantId, doc: any): any {
    const CRTITERESheadProjetTitle = [
      [
        {
          content: "Critères d'éligibilité",
          colSpan: 1,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'bold',
            fillColor: [0, 55, 102],
            textColor: [255, 255, 255],
          },
        },
      ],
    ];

    const CRTITERESbodyProjetTitle = [
      [
        {
          content: participant?.criteres?.length
            ? participant.criteres.map((el: any) => el.item).join(', ')
            : 'Non renseigné',
          styles: {
            halign: 'center',
            fontStyle: 'normal',
            fontSize: 10,
          },
        },
      ],
    ];

    doc.autoTable({
      theme: 'grid',
      head: CRTITERESheadProjetTitle,
      body: CRTITERESbodyProjetTitle,
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },

      columnStyles: {
        0: { cellWidth: 515.28 },
      },
      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
    });
  }

  poleEmploi_table(participant: ParticipantId, doc: any): any {
    const poleEmploiheadProjetTitle = [
      [
        {
          content: 'Pôle Emploi',
          colSpan: 2,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'bold',
            fillColor: [0, 55, 102],
            textColor: [255, 255, 255],
          },
        },
      ],
      [
        {
          content: "Période d'inscription",
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Allocation',
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
    ];

    const poleEmploibodyProjetTitle = [
      [
        {
          content: participant?.informations?.poleEmploi?.inscription
            ? capitalize(
                participant?.informations?.poleEmploi?.inscription?.label
              )
            : 'Non renseignée',
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: participant?.minimaSociaux?.value
            ? participant?.minimaSociaux?.value.split('_').join(', ')
            : 'Non renseigné',
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
    ];

    doc.autoTable({
      theme: 'grid',
      head: poleEmploiheadProjetTitle,
      body: poleEmploibodyProjetTitle,
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },

      columnStyles: {
        0: { cellWidth: 257.67 },
        1: { cellWidth: 257.67 },
      },
      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
    });
  }

  missionLocale_table(participant: ParticipantId, doc: any): any {
    const missionLocaleheadProjetTitle = [
      [
        {
          content: 'Mission locale',
          colSpan: 2,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'bold',
            fillColor: [0, 55, 102],
            textColor: [255, 255, 255],
          },
        },
      ],
      [
        {
          content: 'Structure',
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Adresse',
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
    ];

    const missionLocalebodyProjetTitle = [
      [
        {
          content: participant?.informations?.missionLocal?.denomination
            ? participant?.informations?.missionLocal?.denomination
            : 'Non renseignée',
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: participant?.informations?.missionLocal?.adresse
            ? participant?.informations?.missionLocal?.adresse
            : 'Non renseignée',
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
      ],
    ];

    doc.autoTable({
      theme: 'grid',
      head: missionLocaleheadProjetTitle,
      body: missionLocalebodyProjetTitle,
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },

      columnStyles: {
        0: { cellWidth: 215.28 },
        1: { cellWidth: 300 },
      },
      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
    });
  }

  sortie_table(participant: ParticipantId, doc: any): any {
    const sortieheadProjetTitle = [
      [
        {
          content: 'Sortie du parcours',
          colSpan: 4,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'bold',
            fillColor: [0, 55, 102],
            textColor: [255, 255, 255],
          },
        },
      ],
      [
        {
          content: 'Type de sortie',
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Motif de sortie',
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Date de sortie',
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Note',
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
    ];

    const sortiebodyProjetTitle = [
      [
        {
          content: participant?.sortiProgrammeType?.type
            ? participant?.sortiProgrammeType?.type
            : 'Non renseigné',
          styles: {
            halign: 'left',
            fontStyle: 'bold',
          },
        },
        {
          content: participant?.sortiProgrammeType?.motif
            ? participant?.sortiProgrammeType?.motif
            : 'Non renseignée',
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: participant?.dateEnd
            ? format(
                fromUnixTime(participant.dateEnd['seconds']),
                'dd/MM/yyyy',
                { locale: fr }
              )
            : 'Non renseignée',
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: participant?.sortiProgrammeQualitatif
            ? participant?.sortiProgrammeQualitatif
            : 'Non renseigné',
          styles: {
            halign: 'left',
            fontStyle: 'normal',
            fontSize: 9,
          },
        },
      ],
    ];

    doc.autoTable({
      theme: 'grid',
      head: sortieheadProjetTitle,
      body: sortiebodyProjetTitle,
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },

      columnStyles: {
        0: { cellWidth: 105.28 },
        1: { cellWidth: 105 },
        2: { cellWidth: 105 },
        3: { cellWidth: 200 },
      },
      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
    });
  }

  impact_table(impact: any, doc: any, period: string): any {
    const impactheadProjetTitle = [
      [
        {
          content: `Impact ${period} mois`,
          colSpan: 3,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'bold',
            fillColor: [0, 55, 102],
            textColor: [255, 255, 255],
          },
        },
      ],
      [
        {
          content: `Situation`,
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: "Date d'évaluation",
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Note',
          styles: {
            fontStyle: 'normal',
            halign: 'left',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
    ];

    const impactbodyProjetTitle = [
      [
        {
          content: impact?.type?.motif ? impact?.type?.motif : 'Non renseigné',
          styles: {
            halign: 'left',
            fontStyle: 'bold',
          },
        },
        {
          content: impact?.date
            ? format(fromUnixTime(impact.date['seconds']), 'dd/MM/yyyy', {
                locale: fr,
              })
            : 'Non renseignée',
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: impact?.info ? impact?.info : 'Non renseigné',
          styles: {
            halign: 'left',
            fontStyle: 'normal',
            fontSize: 9,
          },
        },
      ],
    ];

    doc.autoTable({
      theme: 'grid',
      head: impactheadProjetTitle,
      body: impactbodyProjetTitle,
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },

      columnStyles: {
        0: { cellWidth: 190 },
        1: { cellWidth: 85.28 },
        2: { cellWidth: 240 },
      },
      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
    });
  }

  projetProfessionnels_table(statistiques: any, doc: any): any {
    if (!statistiques?.projetsprofessionnels?.data?.length) return;
    const { projetsprofessionnels } = statistiques;

    // status: "",
    // dateStart: data?.dateStart

    const PROJETPROheadProjetTitle = [
      [
        {
          content: 'Projets professionnels',
          colSpan: 4,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'bold',
            fillColor: [0, 55, 102],
            textColor: [255, 255, 255],
          },
        },
      ],
    ];

    doc.autoTable({
      theme: 'grid',
      head: PROJETPROheadProjetTitle,
      showHead: 'everyPage',
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },
      headStyles: {
        /* fillColor: [0, 0, 0], textColor: [255, 255, 255] */
      },
      columnStyles: {
        0: { cellWidth: 128.82 },
        1: { cellWidth: 128.82 },
        2: { cellWidth: 128.82 },
        3: { cellWidth: 128.82 },
      },
      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
    });

    projetsprofessionnels.data.map((el: any, index: number) => {
      const projet = [
        [
          {
            content: `${index + 1} - ${el.appelation}`,
            colSpan: 4,
            styles: {
              fontStyle: 'bold',
              halign: 'left',
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              lineWidth: 1,
            },
          },
        ],
        [
          {
            content: 'Employabilité',
            colSpan: 2,
            styles: {
              fontStyle: 'normal',
              halign: 'left',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
              lineWidth: 1,
            },
          },
          {
            content: 'Évaluations réalisées',
            colSpan: 2,
            styles: {
              fontStyle: 'normal',
              halign: 'left',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
              lineWidth: 1,
            },
          },
        ],
        [
          {
            content: `${66}%`,
            colSpan: 2,

            styles: {
              fontStyle: 'bold',
              halign: 'left',
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              lineWidth: 1,
            },
          },
          {
            content: el?.evaluations?.length ? el?.evaluations?.length : 0,
            colSpan: 2,
            styles: {
              fontStyle: 'bold',
              halign: 'left',
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              lineWidth: 1,
            },
          },
        ],
        [
          {
            content: 'Date de création',
            styles: {
              fontStyle: 'normal',
              halign: 'left',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
              lineWidth: 1,
            },
          },
          {
            content: 'Code appellation',
            styles: {
              fontStyle: 'normal',
              halign: 'left',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
              lineWidth: 1,
            },
          },
          {
            content: 'Métier',
            styles: {
              fontStyle: 'normal',
              halign: 'left',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
              lineWidth: 1,
            },
          },
          {
            content: 'Code métier',
            styles: {
              fontStyle: 'normal',
              halign: 'left',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
              lineWidth: 1,
            },
          },
        ],
        [
          {
            content: el?.dateStart ? el.dateStart : '',
            styles: {
              halign: 'left',
              fontStyle: 'normal',
              fontSize: 9,
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              lineWidth: 1,
            },
          },
          {
            content: el.appelationCode,
            styles: {
              halign: 'left',
              fontStyle: 'normal',
              fontSize: 9,
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              lineWidth: 1,
            },
          },
          {
            content: el.metier,
            styles: {
              halign: 'left',
              fontStyle: 'normal',
              fontSize: 9,
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              lineWidth: 1,
            },
          },
          {
            content: el.metierCode,
            styles: {
              halign: 'left',
              fontStyle: 'normal',
              fontSize: 9,
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              lineWidth: 1,
            },
          },
        ],

        [
          {
            content: 'Domaine professionnel',
            styles: {
              fontStyle: 'normal',
              halign: 'left',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
              lineWidth: 1,
            },
          },
          {
            content: 'Code domaine professionnel',
            styles: {
              fontStyle: 'normal',
              halign: 'left',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
              lineWidth: 1,
            },
          },
          {
            content: 'Grand domaine',
            styles: {
              fontStyle: 'normal',
              halign: 'left',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
              lineWidth: 1,
            },
          },
          {
            content: 'Code grand domaine',
            styles: {
              fontStyle: 'normal',
              halign: 'left',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
              lineWidth: 1,
            },
          },
        ],
        [
          {
            content: el.domaine,
            styles: {
              halign: 'left',
              fontStyle: 'normal',
              fontSize: 9,
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              lineWidth: 1,
            },
          },
          {
            content: el.domaineCode,
            styles: {
              halign: 'left',
              fontStyle: 'normal',
              fontSize: 9,
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              lineWidth: 1,
            },
          },
          {
            content: el.grandDomaine,
            styles: {
              halign: 'left',
              fontStyle: 'normal',
              fontSize: 9,
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              lineWidth: 1,
            },
          },
          {
            content: el.grandDomaineCode,
            styles: {
              halign: 'left',
              fontStyle: 'normal',
              fontSize: 9,
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              lineWidth: 1,
            },
          },
        ],
        [
          {
            content: 'Famille Professionnelle',
            styles: {
              fontStyle: 'normal',
              halign: 'left',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
              lineWidth: 1,
            },
          },
          {
            content: 'Code Famille Professionnelle',
            styles: {
              fontStyle: 'normal',
              halign: 'left',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
              lineWidth: 1,
            },
          },
          {
            content: 'Professions et Catégorie socioprofessionnelles',
            colSpan: 2,
            styles: {
              fontStyle: 'normal',
              halign: 'left',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
              lineWidth: 1,
            },
          },
        ],
        [
          {
            content: el?.fap,
            styles: {
              fontStyle: 'normal',
              halign: 'left',
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              lineWidth: 1,
            },
          },
          {
            content: el?.fapCode,
            styles: {
              fontStyle: 'normal',
              halign: 'left',
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              lineWidth: 1,
            },
          },
          {
            content: el?.pcs_ese?.length
              ? el.pcs_ese.map((el: any) => el.libelle).join('. ')
              : '',
            colSpan: 2,
            styles: {
              fontStyle: 'normal',
              halign: 'left',
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              lineWidth: 1,
            },
          },
        ],
        [
          {
            content: 'Entités impliquées',
            styles: {
              fontStyle: 'normal',
              halign: 'left',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
              lineWidth: 1,
            },
          },
          {
            content: 'Collaborateurs impliqués',
            styles: {
              fontStyle: 'normal',
              halign: 'left',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
              lineWidth: 1,
            },
          },
          {
            content: 'Formations proposées',
            styles: {
              fontStyle: 'normal',
              halign: 'left',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
              lineWidth: 1,
            },
          },
          {
            content: 'Tâches réalisées',
            styles: {
              fontStyle: 'normal',
              halign: 'left',
              fillColor: [217, 217, 217],
              textColor: [0, 0, 0],
              lineWidth: 1,
            },
          },
        ],
        [
          {
            content: el?.entites?.length ? el?.entites?.length : 0,
            styles: {
              fontStyle: 'bold',
              halign: 'center',
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              lineWidth: 1,
            },
          },
          {
            content: el?.contacts?.length ? el?.contacts?.length : 0,
            styles: {
              fontStyle: 'bold',
              halign: 'center',
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              lineWidth: 1,
            },
          },
          {
            content: el?.formations?.length ? el?.formations?.length : 0,
            styles: {
              fontStyle: 'bold',
              halign: 'center',
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              lineWidth: 1,
            },
          },
          {
            content: el?.todos?.length ? el?.todos?.length : 0,
            styles: {
              fontStyle: 'bold',
              halign: 'center',
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              lineWidth: 1,
            },
          },
        ],
      ];

      doc.autoTable({
        theme: 'grid',
        head: projet,
        styles: {
          cellPadding: 3,
          fontSize: 10,
          textColor: [0, 0, 0],
        },
        headStyles: {
          /* fillColor: [0, 0, 0], textColor: [255, 255, 255] */
        },
        columnStyles: {
          0: { cellWidth: 128.82 },
          1: { cellWidth: 128.82 },
          2: { cellWidth: 128.82 },
          3: { cellWidth: 128.82 },
        },
        bodyStyles: {
          0: { fontStyle: 'bold' },
        },
      });
    });
  }

  operations_table(operation: any, doc: any): any {
    const PROJETheadDetail = [
      [
        {
          content: 'Détails des projets',
          colSpan: 6,
          styles: {
            font: 'helvetica',
            halign: 'center',
            fontStyle: 'bold',
            fillColor: [0, 55, 102],
            textColor: [255, 255, 255],
          },
        },
      ],
      [
        {
          content: 'Intitulé',
          styles: {
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Financeur(s)',
          styles: {
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Inscription',
          styles: {
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Intégration',
          styles: {
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Sortie',
          styles: {
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Description',
          styles: {
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
    ];

    const PROJETbodyDetail = operation?.length
      ? operation.map((el: any) => {
          const detail = [
            {
              content: el.denomination.toUpperCase(),
              styles: {
                halign: 'left',
                fontStyle: 'bold',
              },
            },
            {
              content: 'Non renseignée',
              styles: {
                halign: 'left',
                fontStyle: 'normal',
              },
            },
            {
              content: 'Non renseignée',
              styles: {
                halign: 'left',
                fontStyle: 'normal',
              },
            },
            {
              content: 'Non renseignée',
              styles: {
                halign: 'left',
                fontStyle: 'normal',
              },
            },
            {
              content: 'Non renseignée',
              styles: {
                halign: 'left',
                fontStyle: 'normal',
              },
            },
            {
              content: el?.definition ? el?.definition : 'Non renseignée',
              styles: {
                halign: 'left',
                fontStyle: 'normal',
                fontSize: 8,
              },
            },
          ];

          return detail;
        })
      : [];

    doc.autoTable({
      theme: 'grid',
      head: PROJETheadDetail,
      body: PROJETbodyDetail,
      showHead: 'everyPage',

      /* foot: foot, */
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },
      headStyles: {},

      columnStyles: {
        0: { cellWidth: 115 },
        1: { cellWidth: 75.28 },
        2: { cellWidth: 75 },
        3: { cellWidth: 75 },
        4: { cellWidth: 75 },
        5: { cellWidth: 100 },
      },

      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
    });
  }

  modules_table(modules: any, doc: any): any {
    const MODULEheadDetail = [
      [
        {
          content: 'Détails des modules',
          colSpan: 6,
          styles: {
            font: 'helvetica',
            halign: 'center',
            fontStyle: 'bold',
            fillColor: [0, 55, 102],
            textColor: [255, 255, 255],
          },
        },
      ],
      [
        {
          content: 'Intitulé',
          styles: {
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Type',
          styles: {
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Date',
          styles: {
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },

        {
          content: 'Entité(s)',
          styles: {
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Intervenant(s)',
          styles: {
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },

        {
          content: 'Description',
          styles: {
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
    ];

    const MODULEbodyDetail = modules?.length
      ? modules.map((el: any) => {
          const detail = [
            {
              content: el.title.toUpperCase(),
              styles: {
                halign: 'left',
                fontStyle: 'bold',
              },
            },
            {
              content: el.type,
              styles: {
                halign: 'left',
                fontStyle: 'normal',
                fontSize: 9,
              },
            },
            {
              content: el?.dateStart
                ? `${format(
                    fromUnixTime(el.dateStart['seconds']),
                    'dd/MM/yyyy',
                    { locale: fr }
                  )} -  ${format(
                    fromUnixTime(el.dateEnd['seconds']),
                    'dd/MM/yyyy',
                    { locale: fr }
                  )}`
                : 'Non renseignées',
              styles: {
                halign: 'left',
                fontStyle: 'normal',
              },
            },

            {
              content: el?.entites?.length
                ? el?.entites.map((el: any) => el.nom_raison_sociale).join(', ')
                : 'Non renseignées',
              styles: {
                halign: 'left',
                fontStyle: 'normal',
              },
            },
            {
              content: el?.contacts?.length
                ? el?.contacts.map((el: any) => el.displayName).join(', ')
                : 'Non renseignées',
              styles: {
                halign: 'left',
                fontStyle: 'normal',
              },
            },

            {
              content: el?.description?.definition
                ? el?.description?.definition
                : 'Non renseignée',
              styles: {
                halign: 'left',
                fontStyle: 'normal',
                fontSize: 8,
              },
            },
          ];

          return detail;
        })
      : [];

    doc.autoTable({
      theme: 'grid',
      head: MODULEheadDetail,
      body: MODULEbodyDetail,
      showHead: 'everyPage',

      /* foot: foot, */
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },
      headStyles: {},

      columnStyles: {
        0: { cellWidth: 115 },
        1: { cellWidth: 75.28 },
        2: { cellWidth: 50 },
        3: { cellWidth: 75 },
        4: { cellWidth: 100 },
        5: { cellWidth: 100 },
      },

      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
    });
  }

  actions_table(actions: any, doc: any): any {
    const ACTIONheadDetail = [
      [
        {
          content: 'Détails des actions',
          colSpan: 7,
          styles: {
            font: 'helvetica',
            halign: 'center',
            fontStyle: 'bold',
            fillColor: [0, 55, 102],
            textColor: [255, 255, 255],
            fontSize: 11,
          },
        },
      ],
      [
        {
          content: 'Intitulé',
          styles: {
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Type',
          styles: {
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Entité(s)',
          styles: {
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Intervenant(s)',
          styles: {
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Date',
          styles: {
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Durée',
          styles: {
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },

        {
          content: 'Présence',
          styles: {
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
    ];

    const ACTIONbodyDetail = actions?.length
      ? actions.map((el: any) => {
          const detail = [
            {
              content: el.title.toUpperCase(),
              styles: {
                halign: 'left',
                fontStyle: 'bold',
              },
            },
            {
              content: el.type,
              styles: {
                halign: 'left',
                fontStyle: 'normal',
              },
            },
            {
              content: '',
              styles: {
                halign: 'left',
                fontStyle: 'normal',
              },
            },
            {
              content: '',
              styles: {
                halign: 'left',
                fontStyle: 'normal',
              },
            },
            {
              content: el?.dateStart
                ? format(fromUnixTime(el.dateStart['seconds']), 'dd/MM/yyyy', {
                    locale: fr,
                  })
                : 'Non renseignée',
              styles: {
                halign: 'left',
                fontStyle: 'normal',
              },
            },
            {
              content: formatDistanceStrict(
                fromUnixTime(el.dateEnd['seconds']),
                fromUnixTime(el.dateStart['seconds'])
              ),
              styles: {
                halign: 'left',
                fontStyle: 'normal',
              },
            },

            {
              content: '',
              styles: {
                halign: 'left',
                fontStyle: 'normal',
              },
            },
          ];

          return detail;
        })
      : [];

    doc.autoTable({
      theme: 'grid',
      head: ACTIONheadDetail,
      body: ACTIONbodyDetail,
      showHead: 'everyPage',

      /* foot: foot, */
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },
      headStyles: {},

      columnStyles: {
        0: { cellWidth: 100 },
        1: { cellWidth: 50.28 },
        2: { cellWidth: 75 },
        3: { cellWidth: 75 },
        4: { cellWidth: 75 },
        5: { cellWidth: 50 },
        6: { cellWidth: 75 },
      },

      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
    });
  }

  missions_table(actions: any, doc: any): any {
    const ACTIONheadDetail = [
      [
        {
          content: 'Missions',
          colSpan: 7,
          styles: {
            font: 'helvetica',
            halign: 'center',
            fontStyle: 'bold',
            fillColor: [0, 55, 102],
            textColor: [255, 255, 255],
            fontSize: 11,
          },
        },
      ],
      [
        {
          content: 'Intitulé',
          styles: {
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Type',
          styles: {
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Entité(s)',
          styles: {
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Intervenant(s)',
          styles: {
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Date',
          styles: {
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Durée',
          styles: {
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },

        {
          content: 'Présence',
          styles: {
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
    ];

    const ACTIONbodyDetail = actions?.length
      ? actions.map((el: any) => {
          const detail = [
            {
              content: el.title.toUpperCase(),
              styles: {
                halign: 'left',
                fontStyle: 'bold',
              },
            },
            {
              content: el.type,
              styles: {
                halign: 'left',
                fontStyle: 'normal',
              },
            },
            {
              content: '',
              styles: {
                halign: 'left',
                fontStyle: 'normal',
              },
            },
            {
              content: '',
              styles: {
                halign: 'left',
                fontStyle: 'normal',
              },
            },
            {
              content: el?.dateStart
                ? format(fromUnixTime(el.dateStart['seconds']), 'dd/MM/yyyy', {
                    locale: fr,
                  })
                : 'Non renseignée',
              styles: {
                halign: 'left',
                fontStyle: 'normal',
              },
            },
            {
              content: formatDistanceStrict(
                fromUnixTime(el.dateEnd['seconds']),
                fromUnixTime(el.dateStart['seconds'])
              ),
              styles: {
                halign: 'left',
                fontStyle: 'normal',
              },
            },

            {
              content: '',
              styles: {
                halign: 'left',
                fontStyle: 'normal',
              },
            },
          ];

          return detail;
        })
      : [];

    doc.autoTable({
      theme: 'grid',
      head: ACTIONheadDetail,
      body: ACTIONbodyDetail,
      showHead: 'everyPage',

      /* foot: foot, */
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },
      headStyles: {},

      columnStyles: {
        0: { cellWidth: 100 },
        1: { cellWidth: 50.28 },
        2: { cellWidth: 75 },
        3: { cellWidth: 75 },
        4: { cellWidth: 75 },
        5: { cellWidth: 50 },
        6: { cellWidth: 75 },
      },

      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
    });
  }

  projetsprofessionnels_table(actions: any, doc: any): any {
    const ACTIONheadDetail = [
      [
        {
          content: 'Projets professionnels',
          colSpan: 7,
          styles: {
            font: 'helvetica',
            halign: 'center',
            fontStyle: 'bold',
            fillColor: [0, 55, 102],
            textColor: [255, 255, 255],
            fontSize: 11,
          },
        },
      ],
      [
        {
          content: 'Intitulé',
          styles: {
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Type',
          styles: {
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Entité(s)',
          styles: {
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Intervenant(s)',
          styles: {
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Date',
          styles: {
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Durée',
          styles: {
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },

        {
          content: 'Présence',
          styles: {
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
    ];

    const ACTIONbodyDetail = actions?.length
      ? actions.map((el: any) => {
          const detail = [
            {
              content: el.title.toUpperCase(),
              styles: {
                halign: 'left',
                fontStyle: 'bold',
              },
            },
            {
              content: el.type,
              styles: {
                halign: 'left',
                fontStyle: 'normal',
              },
            },
            {
              content: '',
              styles: {
                halign: 'left',
                fontStyle: 'normal',
              },
            },
            {
              content: '',
              styles: {
                halign: 'left',
                fontStyle: 'normal',
              },
            },
            {
              content: el?.dateStart
                ? format(fromUnixTime(el.dateStart['seconds']), 'dd/MM/yyyy', {
                    locale: fr,
                  })
                : 'Non renseignée',
              styles: {
                halign: 'left',
                fontStyle: 'normal',
              },
            },
            {
              content: formatDistanceStrict(
                fromUnixTime(el.dateEnd['seconds']),
                fromUnixTime(el.dateStart['seconds'])
              ),
              styles: {
                halign: 'left',
                fontStyle: 'normal',
              },
            },

            {
              content: '',
              styles: {
                halign: 'left',
                fontStyle: 'normal',
              },
            },
          ];

          return detail;
        })
      : [];

    doc.autoTable({
      theme: 'grid',
      head: ACTIONheadDetail,
      body: ACTIONbodyDetail,
      showHead: 'everyPage',

      /* foot: foot, */
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },
      headStyles: {},

      columnStyles: {
        0: { cellWidth: 100 },
        1: { cellWidth: 50.28 },
        2: { cellWidth: 75 },
        3: { cellWidth: 75 },
        4: { cellWidth: 75 },
        5: { cellWidth: 50 },
        6: { cellWidth: 75 },
      },

      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
    });
  }

  suivis_table(user: any, suivis: any, incluSuivi: any, doc: any): any {
    const headDetail = incluSuivi
      ? [
          [
            {
              content: 'Détails des suivis',
              colSpan: 5,
              styles: {
                font: 'helvetica',
                halign: 'center',
                fontStyle: 'bold',
                fillColor: [0, 55, 102],
                textColor: [255, 255, 255],
                fontSize: 11,
              },
            },
          ],
          [
            {
              content: 'Date',
              styles: {
                fontStyle: 'normal',
                fillColor: [217, 217, 217],
                textColor: [0, 0, 0],
              },
            },
            {
              content: 'Type',
              styles: {
                fontStyle: 'normal',
                fillColor: [217, 217, 217],
                textColor: [0, 0, 0],
              },
            },
            {
              content: 'Titre',
              styles: {
                fontStyle: 'normal',
                fillColor: [217, 217, 217],
                textColor: [0, 0, 0],
              },
            },
            {
              content: 'Référent(e)',
              styles: {
                fontStyle: 'normal',
                fillColor: [217, 217, 217],
                textColor: [0, 0, 0],
              },
            },
            {
              content: 'Note',
              styles: {
                fontStyle: 'normal',
                fillColor: [217, 217, 217],
                textColor: [0, 0, 0],
              },
            },
          ],
        ]
      : [];

    // "id": suivi.id,
    // "title": suivi.title,
    // "type": suivi.type,
    // "duration": suivi?.duration ? suivi?.duration : 0,
    // "objet": suivi?.object ? suivi?.object : null,
    // "createAt": suivi.dateStart ?
    //   format(fromUnixTime(suivi.dateStart["seconds"]), "dd/MM/yyyy", {
    //     locale: fr,
    //   }) :
    //   noExist,

    // "content": suivi?.text ? suivi?.text : noExist,
    // "needs": suivi?.besoins ? suivi?.besoins : noExist,
    // "author": suivi?.auteur ? suivi.auteur.displayName : noExist,
    // "realisation": suivi?.realisations ? suivi?.realisations : noExist,
    // "preconisation": suivi?.preconisation ? suivi?.preconisation : noExist,

    const bodyDetail =
      incluSuivi && suivis?.data?.length
        ? suivis.data.map((el: any) => {
            const detail = [
              {
                content: el['Date de création'],
                styles: {
                  halign: 'left',
                  fontStyle: 'normal',
                },
              },
              {
                content: el['Type'],
                styles: {
                  halign: 'left',
                  fontStyle: 'normal',
                },
              },
              {
                content: el['Titre'],
                styles: {
                  halign: 'left',
                  fontStyle: 'normal',
                  fontSize: 9,
                },
              },
              {
                content: el['Auteur'] ? el['Auteur'] : 'Non renseigné',
                styles: {
                  halign: 'left',
                  fontStyle: 'normal',
                  fontSize: 9,
                },
              },
              {
                content: el['Contenu'],
                styles: {
                  halign: 'left',
                  fontStyle: 'normal',
                  fontSize: 9,
                },
              },
            ];

            return detail;
          })
        : [];
    doc.autoTable({
      theme: 'grid',
      head: incluSuivi ? headDetail : null,
      body: incluSuivi ? bodyDetail : null,
      showHead: 'everyPage',
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },
      headStyles: {},
      columnStyles: incluSuivi
        ? {
            0: { cellWidth: 75.28 },
            1: { cellWidth: 75 },
            2: { cellWidth: 75 },
            3: { cellWidth: 90 },
            4: { cellWidth: 200 },
          }
        : {
            0: { cellWidth: 515.28 },
          },

      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
    });
  }

  aide_table(statistiques: any, doc: any): any {
    if (!statistiques?.aids?.data?.length) return;
    const { aids } = statistiques;
    const headDetail = [
      [
        {
          content: "Aides à l'emploi",
          colSpan: 3,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'bold',
            fillColor: [0, 55, 102],
            textColor: [255, 255, 255],
          },
        },
      ],
      [
        {
          content: "Type d'aide",
          styles: {
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Validé',
          styles: {
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Date de validation',
          styles: {
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
    ];
    const bodyDetail = aids.data.map((el: any) => {
      const detail = [
        {
          content: el.title,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: el.receive,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
          },
        },
        {
          content: el.date,
          styles: {
            halign: 'left',
            fontStyle: 'normal',
            fontSize: 9,
          },
        },
      ];

      return detail;
    });
    doc.autoTable({
      theme: 'grid',
      head: headDetail,
      body: bodyDetail,
      showHead: 'everyPage',
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },
      headStyles: {},
      columnStyles: {
        0: { cellWidth: 343.52 },
        1: { cellWidth: 85.88 },
        2: { cellWidth: 85.88 },
      },

      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
    });
  }

  criteres_tables(critere: ParticipantCriteres, doc: any): void {
    if (!critere) return;

    const headDetail = [
      [
        {
          content: 'Critères sociaux',
          colSpan: 3,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'bold',
            fillColor: [0, 55, 102],
            textColor: [255, 255, 255],
          },
        },
      ],
      [
        {
          content: 'Jeune de moins de 26ans',
          colSpan: 3,
          styles: {
            font: 'helvetica',
            halign: 'left',
            fontStyle: 'bold',
            fillColor: [0, 55, 102],
            textColor: [255, 255, 255],
          },
        },
        {
          content: 'Jeune décrocheur',
          styles: {
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Neet',
          styles: {
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Parent isolé',
          styles: {
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Personne prise en charge par une SIAE',
          styles: {
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: "Personne employé d'un GEIQ",
          styles: {
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Réfugié',
          styles: {
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Primo-arrivant',
          styles: {
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Protection subsidiaire',
          styles: {
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Protection subsidiaire',
          styles: {
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: "Aide Sociale à l'Enfance",
          styles: {
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Fonds Social Européen',
          styles: {
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
        {
          content: 'Autoentrepreneur en QPV',
          styles: {
            fontStyle: 'normal',
            fillColor: [217, 217, 217],
            textColor: [0, 0, 0],
          },
        },
      ],
    ];

    // beneficiaireProtectionInterSubsidiaire: false,
    // ase: false,
    // autre: false,
    // autrePrecision: '',
    // autoentrepreneurQPV: false,

    const bodyDetail = [
      {
        content: critere.jeunesDeMoinsDe26ans,
        styles: {
          halign: 'left',
          fontStyle: 'normal',
        },
      },
      {
        content: critere.jeuneDecrocheur,
        styles: {
          halign: 'left',
          fontStyle: 'normal',
        },
      },
      {
        content: critere.neet,
        styles: {
          halign: 'left',
          fontStyle: 'normal',
        },
      },
      {
        content: critere.parentIsole,
        styles: {
          halign: 'left',
          fontStyle: 'normal',
        },
      },
      {
        content: critere.personnePriseEnChargeIAE,
        styles: {
          halign: 'left',
          fontStyle: 'normal',
        },
      },
      {
        content: critere.PersonneEmployeGEIQ,
        styles: {
          halign: 'left',
          fontStyle: 'normal',
          fontSize: 9,
        },
      },
      {
        content: critere.beneficiaireProtectionInterRefugie,
        styles: {
          halign: 'left',
          fontStyle: 'normal',
          fontSize: 9,
        },
      },
      {
        content: critere.primoArrivant,
        styles: {
          halign: 'left',
          fontStyle: 'normal',
          fontSize: 9,
        },
      },
    ];

    doc.autoTable({
      theme: 'grid',
      head: headDetail,
      body: bodyDetail,
      showHead: 'everyPage',
      styles: {
        cellPadding: 3,
        fontSize: 10,
        textColor: [0, 0, 0],
      },
      headStyles: {},
      columnStyles: {
        0: { cellWidth: 343.52 },
        1: { cellWidth: 85.88 },
        2: { cellWidth: 85.88 },
      },

      bodyStyles: {
        0: { fontStyle: 'bold' },
      },
    });
  }
}
