import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DiffusionListComponent } from './components/diffusion-list/diffusion-list.component';
import { DiffusionItemComponent } from './components/diffusion-item/diffusion-item.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { NoResultModule } from 'src/app/contents/components/no-result/no-result.module';
import { CoordonneesModule } from 'src/app/contents/components/coordonnees/coordonnees.module';
import { CommunesFranceModule } from 'src/app/contents/components/communes-france/communes-france.module';
import { PersonModule } from '../person/person.module';
import { HeaderModule } from 'src/app/contents/components/header-title/header.module';
import { DiffusionFormComponent } from './components/diffusion-form/diffusion-form.component';
import { DiffusionCardComponent } from './components/diffusion-card/diffusion-card.component';
import { LayoutModule } from 'src/app/contents/components/layout/layout.module';
import { ButtonsModule } from 'src/app/contents/components/buttons/buttons.module';
import { DiffusionFormContactsComponent } from './components/diffusion-form-contacts/diffusion-form-contacts.component';
import { ContactControlerModule } from '../contact-controler/contact-controler.module';
import { UserModule } from 'src/app/components/user/user.module';
import { UiModule } from 'src/app/ui/ui/ui.module';
import { DiffusionContactsComponent } from './components/diffusion-contacts/diffusion-contacts.component';
import { EntiteSearchModule } from 'src/app/contents/components/entite-search/entite-search.module';
import { TextEdithingModule } from '../text-edithing/text-edithing.module';
import { SuiviModule } from '../suivi/suivi.module';
import { AvatarModule } from '../avatar/avatar.module';

@NgModule({
  declarations: [
    DiffusionListComponent,
    DiffusionItemComponent,
    DiffusionFormComponent,
    DiffusionCardComponent,
    DiffusionFormContactsComponent,
    DiffusionContactsComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    NoResultModule,
    CoordonneesModule,
    HeaderModule,
    CommunesFranceModule,
    PersonModule,
    LayoutModule,
    ButtonsModule,
    ContactControlerModule,
    UserModule,
    UiModule,
    EntiteSearchModule,
    TextEdithingModule,
    SuiviModule,
    AvatarModule,
  ],
  exports: [DiffusionListComponent, DiffusionItemComponent],
})
export class DiffusionModule {}
