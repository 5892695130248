<nz-card nzSize="small" nzBorderless [nzTitle]="ficheDePosteFormRechercheTitle">
  <form nz-form [formGroup]="ficheDePosteValidationForm" class="6">
    <div class="w-full flex items-start space-x-6">
      <div class="w-1/2">
        <label class="label required">Nombre de salariés</label>
        <div class="numberForm">
          <nz-input-number
            [nzStep]="1"
            [nzMin]="0"
            class="numberField"
            formControlName="participantsSize"
          ></nz-input-number>
        </div>
      </div>
      <div class="w-1/2">
        <label class="label">Contrat avec clause sociale</label>
        <label
          class="label cursor-pointer flex items-baseline justify-between mt-3"
        >
          <span class="label-text text-sm mt-2 mr-2">{{
            ficheDePosteValidationForm.value?.isClauseInsertion ? "Oui" : "Non"
          }}</span>
          <input
            type="checkbox"
            checked="checked"
            class="checkbox h-4 w-4 mt-2 cursor-pointer"
            formControlName="isClauseInsertion"
          />
        </label>
      </div>
    </div>
  </form>
  <nz-divider
    nzOrientation="center"
    nzType="horizontal"
    [nzText]="'Limitation territoriale'"
  ></nz-divider>
  <app-geo-territoire-search
    [type]="'list'"
    (geoterritoire)="onLimitTerritoire($event)"
  ></app-geo-territoire-search>
</nz-card>
<!-- <nz-divider
  nzOrientation="center"
  nzType="horizontal"
  nzText="Risques professionnels"
></nz-divider> -->

<!-- <nz-card nzSize="small" nzBorderless [nzTitle]="ficheDePosteFormRisqueTitle">
</nz-card> -->

<ng-template #ficheDePosteFormRechercheTitle>
  <app-header-title
    [icon]="'people'"
    [title]="''"
    [subtitle]="true"
  ></app-header-title>
</ng-template>
<ng-template #ficheDePosteFormRisqueTitle>
  <app-header-title
    [icon]="'priority_high'"
    [title]="''"
    [subtitle]="true"
  ></app-header-title>
</ng-template>
