import { ContactsSelect } from './contacts-select.model';
import { catchError, mergeMap, map, filter } from 'rxjs/operators';
import { QueryContactsService } from './../../../../service/query-contacts.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import * as fromContactAction from './contacts-select.actions';
import { ContactSMALLid } from 'src/app/components/contact/store/contact.model';

@Injectable()
export class ContactsSelectEffects {
  queryContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromContactAction.queryContacts),
      mergeMap((action) =>
        this.queryContactsService
          .queryContacts({ event: action.filter, type: action.filterType })
          .pipe(
            map(
              (contacts: ContactSMALLid[] | any) =>
                fromContactAction.queryContactsSuccess({ contacts }),
              catchError((error) =>
                of(fromContactAction.queryContactsFailure({ error }))
              )
            )
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private queryContactsService: QueryContactsService
  ) {}
}
