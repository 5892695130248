import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Departement } from './departement.model';

//LOADING INDICATOR
export const loadingDepartements = createAction(
  '[Departement/API] Loading Departements',
  props<{ loading: boolean }>()
);

//LOAD DEPARTEMENTS
export const loadDepartements = createAction(
  '[Departement/COMPONENTS] Load Departements'
);

export const loadDepartementsSuccess = createAction(
  '[Departement/API] Load Departements Success',
  props<{ departements: any }>()
);

export const loadDepartementsFailure = createAction(
  '[Departement/API] Load Departements Failure',
  props<{ error: any }>()
);

//LOAD DEPARTEMENTS LIST
export const loadDepartementsList = createAction(
  '[Departement/COMPONENTS] Load Departements List'
);

export const loadDepartementsListSuccess = createAction(
  '[Departement/API] Load Departements List Success',
  props<{ departements: any }>()
);

export const loadDepartementsListFailure = createAction(
  '[Departement/API] Load Departements List Failure',
  props<{ error: any }>()
);

//LOAD DEPARTEMENT
export const loadDepartementsServces = createAction(
  '[Departement/COMPONENTS] Load Departements Services'
);

export const loadDepartementsServcesSuccess = createAction(
  '[Departement/API] Load Departements Services Success',
  props<{ departements: any }>()
);

export const loadDepartementsServcesFailure = createAction(
  '[Departement/API] Load Departements Services Failure',
  props<{ error: any }>()
);

export const loadDepartement = createAction(
  '[Departement/COMPONENTS] Load Departement',
  props<{ id: string }>()
);

export const loadDepartementSuccess = createAction(
  '[Departement/API] Load Departement Success',
  props<{ departement: any }>()
);

export const loadDepartementFailure = createAction(
  '[Departement/API] Load Departement Failure',
  props<{ error: any }>()
);

//ADD DEPARTEMENTS

export const addDepartement = createAction(
  '[Departement/COMPONENT] Add Departement',
  props<{ departement: any }>()
);

export const addDepartementSuccess = createAction(
  '[Departement/API] Add Departement Success',
  props<{ success: any }>()
);

export const addDepartementFailure = createAction(
  '[Departement/API] Add Departement Failure',
  props<{ error: any }>()
);

//UPDATE DEPARTEMENTS

export const updateDepartement = createAction(
  '[Departement/COMPONENT] Update Departement',
  props<{ departement: Update<any> }>()
);

export const updateDepartementSuccess = createAction(
  '[Departement/API] Update Departement Success'
);

export const updateDepartementFailure = createAction(
  '[Departement/API] Update Departement Failure',
  props<{ error: any }>()
);

//DELETE DEPARTEMENTS

export const deleteDepartement = createAction(
  '[Departement/COMPONENT] Delete Departement',
  props<{ id: string }>()
);
export const deleteDepartementSuccess = createAction(
  '[Departement/API] Delete Departement Success',
  props<{ success: any }>()
);
export const deleteDepartementFailure = createAction(
  '[Departement/API] Delete Departement Failure',
  props<{ error: any }>()
);

//DELETE ALL DEPARTEMENTS

export const deleteDepartements = createAction(
  '[Departement/API] Delete Departements'
);

export const deleteDepartementsSuccess = createAction(
  '[Departement/API] Delete Departements Success',
  props<{ success: string }>()
);

export const deleteDepartementsFailure = createAction(
  '[Departement/API] Delete Departements Failure',
  props<{ error: any }>()
);

//QUERY
export const queryDepartements = createAction(
  '[Departement/COMPONENTS] Query Departements',
  props<{ filter: { event: string; type: string } }>()
);

export const queryDepartementsSuccess = createAction(
  '[Departement/API] Query Departements Success',
  props<{ departements: any }>()
);

export const queryDepartementsFailure = createAction(
  '[Departement/API] Query Departements Failure',
  props<{ error: any }>()
);

export const clearDepartements = createAction(
  '[Departement/API] Clear Departements'
);
export const clearDepartement = createAction(
  '[Departement/API] Clear Departement'
);
