<ng-container>
  <app-layout-grid [details]="details"></app-layout-grid>

  <ng-template #details>
    <nz-card nzBorderless>
      <router-outlet></router-outlet>
    </nz-card>
  </ng-template>
</ng-container>

<ng-template #participantTitleForm>
  <app-header-title [icon]="'person'" [title]="title"></app-header-title>
</ng-template>

<ng-template #exportTitleForm>
  <app-header-title
    [icon]="'download'"
    [title]="titleDownload"
  ></app-header-title>
</ng-template>
