<ng-container *ngIf="operation">
  <ng-container *ngIf="note$ | async as note">
    <nz-card
      nzSize="small"
      *ngIf="participants$ | async | size; else noData"
      [nzTitle]="noteParticipantForm"
    >
      <nz-table
        #participantTable
        [nzData]="participants$ | async"
        nzTableLayout="fixed"
        nzSize="small"
        [nzScroll]="scroll"
        [nzLoadingDelay]="2"
        [nzBordered]="false"
        [nzOuterBordered]="false"
        [nzPageSize]="2"
      >
        <thead>
          <tr>
            <th nzLeft nzWidth="20">
              {{ operation.type | operationType: "module" }}
            </th>
            <th nzLeft>Entreprises</th>
            <th nzWidth="20">Contacts</th>
            <th nzWidth="30">E-mails</th>
            <th>Mobiles</th>
            <th nzRight></th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let data of participantTable.data"
            (click)="$event.stopPropagation()"
          >
            <td nzLeft nzWidth="20">
              <strong>{{ data?.module?.title }}</strong>
            </td>
            <td>
              {{ data.entite.denomination }}
            </td>

            <td nzWidth="20">
              <nz-list nzSize="small" [nzBordered]="false" nzSplit="true">
                <nz-list-item *ngFor="let contact of data?.contacts" nzNoFlex>
                  <p>{{ contact?.displayName }}</p>
                  <br />
                  <small>{{ contact?.fonction }}</small>
                </nz-list-item>
              </nz-list>
            </td>
            <td nzWidth="30">
              <nz-list
                nzSize="small"
                [nzBordered]="false"
                nzSplit="true"
                nzNoFlex
              >
                <nz-list-item *ngFor="let contact of data?.contacts">
                  <span>{{ contact?.coordonnees?.email }}</span>
                </nz-list-item>
              </nz-list>
            </td>
            <td>
              <nz-list nzSize="small" [nzBordered]="false" nzSplit="true">
                <nz-list-item *ngFor="let contact of data?.contacts">
                  <span>{{ contact?.coordonnees?.phoneNumber }}</span>
                </nz-list-item>
              </nz-list>
            </td>

            <td nzRight>
              <div class="action">
                <app-button-dropdown
                  [direction]="'horizontal'"
                  (click)="$event.stopPropagation()"
                  [isDelete]="true"
                  [deleteType]="'Voulez-vous supprimer ce participant ?'"
                  (select)="onAction($event, note, data)"
                ></app-button-dropdown>
              </div>
            </td>
          </tr>
        </tbody>
      </nz-table>
    </nz-card>

    <ng-template #noData>
      <app-no-result
        (click)="$event.stopPropagation()"
        [icon]="'person'"
        [description]="''"
        [notitle]="'Aucun participant à la réunion'"
        [isButton]="false"
      ></app-no-result>
    </ng-template>
    <ng-template #noteParticipantForm>
      <app-operation-note-participant-form></app-operation-note-participant-form>
    </ng-template>
  </ng-container>
</ng-container>
