<form nz-form [formGroup]="operationForm" nzLayout="vertical">
  <nz-steps [nzCurrent]="currentStep" nzSize="small">
    <nz-step nzTitle="Description"> </nz-step>
    <nz-step nzTitle="Calendrier"> </nz-step>
    <nz-step nzTitle="Objectif"> </nz-step>
  </nz-steps>
  <nz-space nzDirection="vertical"></nz-space>
  <div [ngSwitch]="currentStep">
    <div *ngSwitchCase="0">
      <nz-card nzSize="small" [nzTitle]="operationDescription">
        <ng-template #operationDescription>
          <app-header-title
            [icon]="'subject'"
            [title]="''"
            [subtitle]="true"
          ></app-header-title>
        </ng-template>
        <nz-form-item>
          <nz-form-label nzRequired>Titre</nz-form-label>
          <nz-form-control>
            <input
              nz-input
              nzBorderless
              formControlName="denomination"
              placeholder="Titre du projet..."
            />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item formGroupName="description">
          <nz-form-label>Description</nz-form-label>
          <nz-form-control>
            <textarea
              nz-input
              placeholder="Description..."
              formControlName="definition"
              nzBorderless
              [nzAutosize]="{ minRows: 4, maxRows: 4 }"
            ></textarea>
          </nz-form-control>
        </nz-form-item>

        <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
          <nz-col nzSpan="12">
            <nz-form-item class="operationForm__avancementLabel">
              <nz-form-label nzRequired>Type de projet</nz-form-label>
            </nz-form-item>
            <app-utilities-select
              [placeholder]="'Type de projet'"
              [id]="selectId"
              [borderless]="true"
              [multi]="false"
              [variable]="selectVarable"
              [size]="'default'"
              (select)="onSelectType($event)"
            ></app-utilities-select>
          </nz-col>
          <nz-col nzSpan="12">
            <nz-form-item>
              <nz-form-label>Nature du marché</nz-form-label>
              <nz-form-control>
                <nz-select
                  formControlName="nature"
                  nzBorderless
                  nzPlaceHolder="Travaux,..."
                >
                  <nz-option
                    *ngFor="let item of (marcheTypes$ | async)?.types"
                    [nzLabel]="item"
                    [nzValue]="item"
                  ></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </nz-col>
        </nz-row>
        <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
          <nz-col nzSpan="12">
            <nz-form-item>
              <nz-form-label>N° de marché</nz-form-label>
              <nz-form-control>
                <input
                  nz-input
                  nzBorderless
                  formControlName="numeroMarche"
                  placeholder="Identifiant financeur"
                />
              </nz-form-control>
            </nz-form-item>
          </nz-col>
          <nz-col nzSpan="12">
            <nz-form-item>
              <nz-form-label>Service financeur</nz-form-label>
              <nz-form-control>
                <input
                  nz-input
                  nzBorderless
                  formControlName="service"
                  placeholder="Service financeur"
                />
              </nz-form-control>
            </nz-form-item>
          </nz-col>
        </nz-row>
      </nz-card>
      <nz-space nzDirection="vertical"></nz-space>
      <nz-card nzSize="small" [nzTitle]="operationAdresse">
        <ng-template #operationAdresse>
          <app-header-title
            [icon]="'place'"
            [title]="''"
            [subtitle]="true"
          ></app-header-title>
        </ng-template>

        <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
          <nz-col nzSpan="24">
            <app-communes-france
              [borderless]="true"
              [isLabel]="false"
              [isComplement]="false"
              [required]="false"
              (adresse)="onAddAdresse($event)"
              (complementIn)="onAddAdresseComplete($event)"
            ></app-communes-france>
          </nz-col>
        </nz-row>
      </nz-card>
      <nz-space nzDirection="vertical"></nz-space>
      <nz-card nzSize="small" [nzTitle]="operationService">
        <app-departement-selector
          (select)="onSelectDeplacement($event)"
        ></app-departement-selector>
      </nz-card>
    </div>
    <div *ngSwitchCase="1">
      <nz-card nzSize="small" [nzTitle]="operationCalendrier">
        <ng-template #operationCalendrier>
          <app-header-title
            [icon]="'event'"
            [title]="''"
            [subtitle]="true"
          ></app-header-title>
        </ng-template>
        <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
          <ng-container formGroupName="description">
            <nz-col nzSpan="8">
              <nz-form-item formGroupName="calendrier">
                <nz-form-label nzRequired>Démmarrage</nz-form-label>
                <nz-form-control>
                  <app-date-picker
                    [type]="'date'"
                    [borderless]="true"
                    [placeholder]="'Démmarrage...'"
                    [time]="false"
                    (select)="onSelectDateStart($event)"
                  ></app-date-picker>
                </nz-form-control>
              </nz-form-item>
            </nz-col>

            <nz-col nzSpan="8">
              <nz-form-item formGroupName="calendrier">
                <nz-form-label nzRequired>Échéance</nz-form-label>
                <nz-form-control>
                  <app-date-picker
                    [type]="'date'"
                    [borderless]="true"
                    [placeholder]="'Échéance...'"
                    [time]="false"
                    (select)="onSelectDateEnd($event)"
                  ></app-date-picker>
                </nz-form-control>
              </nz-form-item>
            </nz-col>
          </ng-container>
          <nz-col nzSpan="8">
            <nz-form-item>
              <nz-form-label nzRequired>Avancement</nz-form-label>
              <nz-form-control>
                <nz-select
                  formControlName="status"
                  nzPlaceHolder="État d'avancement"
                  nzBorderless
                >
                  <nz-option
                    *ngFor="let item of statusList"
                    [nzLabel]="item"
                    [nzValue]="item"
                  ></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </nz-col>
        </nz-row>
      </nz-card>
    </div>
    <div *ngSwitchCase="2">
      <nz-card nzSize="small" [nzTitle]="operationContexte">
        <ng-template #operationContexte>
          <app-header-title
            [icon]="'article'"
            [title]="''"
            [subtitle]="true"
          ></app-header-title>
        </ng-template>

        <nz-row
          nzAlign="middle"
          nzGutter="8"
          nzJustify="start"
          formGroupName="description"
        >
          <nz-col nzSpan="12">
            <nz-form-item>
              <nz-form-label nzRequired>Objectifs</nz-form-label>
              <nz-form-control>
                <nz-input-number
                  formControlName="objectifs"
                  nzPlaceHolder="Objectif quantitatif"
                  [nzMin]="0"
                ></nz-input-number>
              </nz-form-control>
            </nz-form-item>
          </nz-col>
          <nz-col nzSpan="12">
            <nz-form-item>
              <nz-form-label nzRequired>Type d'objectifs</nz-form-label>
              <nz-form-control>
                <nz-select
                  formControlName="objectifsType"
                  nzBorderless
                  nzPlaceHolder="Nombre de..."
                >
                  <nz-option
                    *ngFor="let item of (objectifTypes$ | async)?.objectifs"
                    [nzLabel]="item | titlecase"
                    [nzValue]="item"
                  ></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </nz-col>
        </nz-row>
      </nz-card>
    </div>
    <div *ngSwitchDefault></div>
  </div>
</form>

<nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
<nz-row nzAlign="middle" nzGutter="8" nzJustify="end" *ngIf="isAdd">
  <nz-col nzSpan="6"></nz-col>
  <nz-col nzSpan="6">
    <button
      nz-button
      nzBlock
      nzType="text"
      (click)="$event.stopPropagation(); onCancel()"
    >
      Annuler
    </button>
  </nz-col>
  <nz-col nzSpan="6">
    <button
      *ngIf="isAdd"
      nz-button
      nzBlock
      (click)="$event.stopPropagation(); onPrevStep()"
    >
      Précédent
    </button>
  </nz-col>

  <nz-col nzSpan="6">
    <button
      *ngIf="isAdd && currentStep < 2"
      nz-button
      nzBlock
      (click)="$event.stopPropagation(); onNextStep()"
    >
      Suivant
    </button>

    <button
      *ngIf="isAdd && currentStep === 2"
      nz-button
      nzBlock
      nzType="primary"
      [disabled]="!operationForm.valid"
      (click)="$event.stopPropagation(); onAdd()"
    >
      Créer le projet
    </button>

    <button
      *ngIf="isUpdate && (operation$ | async) as operation"
      nz-button
      nzBlock
      nzType="primary"
      [disabled]="!operationForm.valid"
      (click)="$event.stopPropagation(); onUpdate(operation)"
    >
      Modifier le projet
    </button>
  </nz-col>
</nz-row>

<ng-template #cardUpdateActions>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="end" *ngIf="update">
    <nz-col nzSpan="12">
      <button nz-button nzBlock nzType="text" (click)="onCancel()">
        <h5 nz-typography>Annuler</h5>
      </button>
    </nz-col>

    <nz-col nzSpan="12">
      <button
        nz-button
        nzBlock
        nzType="primary"
        [disabled]="!operationForm.valid"
      >
        Modifier
      </button>
    </nz-col>
  </nz-row>
</ng-template>

<ng-template #cardUpdateTitle>
  <h4 nz-typography *ngIf="update">Modification du projet</h4>
</ng-template>
<ng-template #operationService>
  <app-header-title [icon]="'group_work'"></app-header-title>
</ng-template>
