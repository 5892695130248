import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OperationSmall, OperationSmallId } from '../../store/operation.model';

@Component({
  selector: 'app-operation-card',
  templateUrl: './operation-card.component.html',
  styleUrls: ['./operation-card.component.scss'],
})
export class OperationCardComponent implements OnInit {
  progressCompleteColor: string = '#49d49d';
  progressDefaultColor: string = '#062949';

  @Input() operation: OperationSmallId | any;
  @Output() export = new EventEmitter<any>(false);
  @Output() delete = new EventEmitter<string>(false);

  constructor() {}

  ngOnInit(): void {}

  onExport(operation: OperationSmall): void {
    if (!operation) return;
    this.export.emit(operation);
  }

  onDelete(id: string): void {
    if (!id) return;
    this.delete.emit(id);
  }

  onCancel(): void {
    return;
  }
}
