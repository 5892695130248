import { ContentsModule } from './../../contents/contents.module';
import { OperationCadrageService } from './../../service/operation-cadrage.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OperationCadrageItemComponent } from './components/operation-cadrage-item/operation-cadrage-item.component';
import { OperationCadrageIndicatorsComponent } from './components/operation-cadrage-indicators/operation-cadrage-indicators.component';
import { OperationCadrageDescriptionComponent } from './components/operation-cadrage-description/operation-cadrage-description.component';
import { OperationCadrageInterfaceComponent } from './components/operation-cadrage-interface/operation-cadrage-interface.component';
import { OperationCadrageLivrableComponent } from './components/operation-cadrage-livrable/operation-cadrage-livrable.component';
import { OperationCadragePilotageComponent } from './components/operation-cadrage-pilotage/operation-cadrage-pilotage.component';
import { OperationCadrageObjectifComponent } from './components/operation-cadrage-objectif/operation-cadrage-objectif.component';
import { OperationCadragePlanComponent } from './components/operation-cadrage-plan/operation-cadrage-plan.component';
import { OperationCadrageRisquesComponent } from './components/operation-cadrage-risques/operation-cadrage-risques.component';
import { OperationCadrageRessourcesComponent } from './components/operation-cadrage-ressources/operation-cadrage-ressources.component';
import { StoreModule } from '@ngrx/store';
import * as fromOperationCadrage from './store/operation-cadrage.reducer';
import { EffectsModule } from '@ngrx/effects';
import { OperationCadrageEffects } from './store/operation-cadrage.effects';
import { SharedModule } from 'src/app/shared/shared.module';
import { OperationCadrageHeaderComponent } from './components/operation-cadrage-header/operation-cadrage-header.component';
import { OperationCadrageSimpleComponent } from './components/operation-cadrage-simple/operation-cadrage-simple.component';
import { OperationCadrageLivrableFormComponent } from './components/operation-cadrage-livrable-form/operation-cadrage-livrable-form.component';
import { OperationCadragePlanFormComponent } from './components/operation-cadrage-plan-form/operation-cadrage-plan-form.component';
import { OperationCadragePilotageFormComponent } from './components/operation-cadrage-pilotage-form/operation-cadrage-pilotage-form.component';
import { OperationCadrageRisquesFormComponent } from './components/operation-cadrage-risques-form/operation-cadrage-risques-form.component';
import { OperationCadrageRessourcesFormComponent } from './components/operation-cadrage-ressources-form/operation-cadrage-ressources-form.component';
import { OperationCadragePartenairesComponent } from './components/operation-cadrage-partenaires/operation-cadrage-partenaires.component';
import { OperationCadragePartenairesFormComponent } from './components/operation-cadrage-partenaires-form/operation-cadrage-partenaires-form.component';
import { OperationCadrageFinanceursComponent } from './components/operation-cadrage-financeurs/operation-cadrage-financeurs.component';
import { OperationCadrageFinanceursFormComponent } from './components/operation-cadrage-financeurs-form/operation-cadrage-financeurs-form.component';
import { OperationCadrageObjectifFormComponent } from './components/operation-cadrage-objectif-form/operation-cadrage-objectif-form.component';
import { NoResultModule } from 'src/app/contents/components/no-result/no-result.module';
import { CoordonneesModule } from 'src/app/contents/components/coordonnees/coordonnees.module';
import { CommunesFranceModule } from 'src/app/contents/components/communes-france/communes-france.module';
import { HeaderModule } from 'src/app/contents/components/header-title/header.module';
import { TextEdithingModule } from 'src/app/features/text-edithing/text-edithing.module';

@NgModule({
  declarations: [
    OperationCadrageItemComponent,
    OperationCadrageIndicatorsComponent,
    OperationCadrageDescriptionComponent,
    OperationCadrageInterfaceComponent,
    OperationCadrageLivrableComponent,
    OperationCadragePilotageComponent,
    OperationCadrageObjectifComponent,
    OperationCadragePlanComponent,
    OperationCadrageRisquesComponent,
    OperationCadrageRessourcesComponent,
    OperationCadrageHeaderComponent,
    OperationCadrageSimpleComponent,
    OperationCadrageLivrableFormComponent,
    OperationCadragePlanFormComponent,
    OperationCadragePilotageFormComponent,
    OperationCadrageRisquesFormComponent,
    OperationCadrageRessourcesFormComponent,
    OperationCadragePartenairesComponent,
    OperationCadragePartenairesFormComponent,
    OperationCadrageFinanceursComponent,
    OperationCadrageFinanceursFormComponent,
    OperationCadrageObjectifFormComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ContentsModule,
    NoResultModule,
    CoordonneesModule,
    HeaderModule,
    CommunesFranceModule,
    TextEdithingModule,
    StoreModule.forFeature(
      fromOperationCadrage.operationCadragesFeatureKey,
      fromOperationCadrage.reducer
    ),
    EffectsModule.forFeature([OperationCadrageEffects]),
  ],
  exports: [
    OperationCadrageItemComponent,
    OperationCadrageIndicatorsComponent,
    OperationCadrageDescriptionComponent,
    OperationCadrageInterfaceComponent,
    OperationCadrageLivrableComponent,
    OperationCadragePilotageComponent,
    OperationCadrageObjectifComponent,
    OperationCadragePlanComponent,
    OperationCadrageRisquesComponent,
    OperationCadrageRessourcesComponent,
    OperationCadrageHeaderComponent,
  ],
  providers: [OperationCadrageService],
})
export class OperationCadrageModule {}
