import { UserState } from 'src/app/components/user/store/user.reducer';
import { Injectable } from '@angular/core';
import * as fromUserAction from 'src/app/components/user/store/user.actions';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';
import { Store, select } from '@ngrx/store';
import {
  Firestore,
  collection,
  doc,
  getDocs,
  getDoc,
  collectionSnapshots,
  collectionChanges,
  collectionData,
  docData,
  query,
  docSnapshots,
  onSnapshot,
  where,
  serverTimestamp,
  addDoc,
  setDoc,
  deleteDoc,
} from '@angular/fire/firestore';
import { Observable, of, from, EMPTY } from 'rxjs';
import { switchMap, mergeMap, map } from 'rxjs/operators';
import {
  MessageId,
  MessageItem,
  MessageUser,
} from '../components/messages/store/message.model';
import { Update } from '@ngrx/entity';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { environment } from '../../environments/environment';
import { DocumentChangeType } from 'rxfire/firestore/interfaces';

@Injectable({
  providedIn: 'any',
})
export class MessagesService {
  messagesCollection: any = null;
  messages$: Observable<any[]> = of([]);
  messageDocument: any = null;
  message$: Observable<any> = of(null);

  notificationAudio: string = '';
  constructor(
    private userStore: Store<UserState>,
    private db: Firestore,
    private fns: Functions
  ) {}

  getNotificationAlert(): void {}

  get__MESSAGES(): Observable<any> {
    return this.userStore.pipe(
      select(fromUserSelector.user),
      switchMap((user) => {
        if (!user) of([]);
        const messagesCol = collection(this.db, `users/${user.id}/messages`);

        return collectionSnapshots(messagesCol).pipe(
          map((snap) => {
            return snap.map((item) => {
              const data = item.data() as any;
              const id = item.id;
              const message = { id, ...data };
              return message;
            });
          })
        );
      })
    );
  }

  getNotificationAudio__MESSAGE(): void {
    this.userStore.pipe(
      select(fromUserSelector.user),
      switchMap((user) => {
        if (!user) {
          return EMPTY;
        } else {
          const lenggoDoc = doc(this.db, 'lenggo/loggo');

          return from(
            docData(lenggoDoc).pipe(
              map((snap): any => {
                this.notificationAudio = snap.notification;
              })
            )
          );
        }
      })
    );
  }

  listner__MESSAGES(): Observable<any> {
    return this.userStore.pipe(
      select(fromUserSelector.user),
      switchMap((user) => {
        if (!user) {
          return EMPTY;
        } else {
          const messagesCol = collection(this.db, `users/${user.id}/messages`);

          return collectionChanges(messagesCol).pipe(
            map((action) =>
              action.map((snap) => {
                if (snap.type === 'added' || snap.type === 'modified') {
                  const oldIndex = snap.oldIndex;
                  const newIndex = snap.newIndex;
                  const data: any = snap.doc.data();
                  const type = snap.type;
                  const index = oldIndex;
                  const audioURL: string = this.notificationAudio;

                  return { data, type, index, audioURL, user };
                } else {
                  return EMPTY;
                }
              })
            ),
            mergeMap((res) => res)
          );
        }
      })
    );
  }

  getMy__MESSAGES(): Observable<any[]> {
    try {
      return this.userStore.pipe(
        select(fromUserSelector.user),
        switchMap((user) => {
          if (!user) {
            return EMPTY;
          } else {
            const messagesCol = collection(
              this.db,
              `users/${user.id}/messages`
            );
            const queryCollection = query(
              messagesCol,
              where('auteur.id', '==', user.id)
            );

            return collectionSnapshots(queryCollection).pipe(
              map((snap) => {
                return snap.map((item) => {
                  const data = item.data() as any;
                  const id = item.id;
                  const message = { id, ...data };
                  return message;
                });
              })
            );
          }
        })
      );
    } catch (err) {
      return of([]);
    }
  }

  getReceive__MESSAGES(): Observable<any[]> {
    try {
      return this.userStore.pipe(
        select(fromUserSelector.user),
        switchMap((user) => {
          if (!user) {
            return EMPTY;
          } else {
            const messagesCol = collection(
              this.db,
              `users/${user.id}/messages`
            );
            const queryCollection = query(
              messagesCol,
              where('auteur.id', '!=', user.id)
            );

            return collectionSnapshots(queryCollection).pipe(
              map((snap) => {
                return snap.map((item) => {
                  const data = item.data() as any;
                  const id = item.id;
                  const message = { id, ...data };
                  return message;
                });
              })
            );
          }
        })
      );
    } catch (err) {
      return of([]);
    }
  }

  get__MESSAGE(id: string): Observable<MessageId | any> {
    try {
      return this.userStore.pipe(
        select(fromUserSelector.user),
        switchMap((user) => {
          if (!user) {
            return EMPTY;
          } else {
            const messagesCol = collection(
              this.db,
              `users/${user.id}/messages`
            );

            const messageDoc = doc(messagesCol, id);

            return docSnapshots(messageDoc).pipe(
              map((snap) => {
                const data = snap.data() as any;
                const id = snap.id;
                return { id, ...data };
              })
            );
          }
        })
      );
    } catch (err) {
      return of(err);
    }
  }

  add__MESSAGE(message: any): Observable<any> {
    try {
      return this.userStore.pipe(
        select(fromUserSelector.user),
        switchMap((user) => {
          if (!user) {
            return of(null);
          } else {
            const messageCol = collection(this.db, `users/${user.id}/messages`);
            const newMessage = {
              ...message,
              auteur: user,
              dateUpdate: serverTimestamp(),
              userUpdate: {
                id: user.id,
                avatar: user.avatar,
                displayName: user.displayName,
                fonction: user.fonction,
              },
            };

            return from(
              addDoc(messageCol, newMessage)
                .then((docRef): Observable<any> => {
                  if (docRef.id) {
                    const newessage = doc(messageCol, docRef.id);
                    return from(
                      getDoc(newessage).then((snap) => {
                        const data = snap.data() as any;
                        const id = snap.id;
                        return { id, ...data };
                      })
                    );
                  } else {
                    return of(null);
                  }
                })
                .catch((err) => err)
            ).pipe(
              mergeMap((res) => {
                return res;
              })
            );
          }
        })
      );
    } catch (err) {
      return of(err);
    }
  }

  add__new_text_MESSAGE(
    messageId: string | number,
    message: Partial<MessageId>
  ): Observable<any> {
    try {
      return this.userStore.pipe(
        select(fromUserSelector.user),
        switchMap((user) => {
          if (!user) {
            return EMPTY;
          } else {
            const messageDoc = doc(
              this.db,
              `users/${user.id}/messages/${message.id}`
            );
            const nextMessage = {
              ...message,
              dataUpdate: serverTimestamp(),
            };

            return from(
              setDoc(messageDoc, nextMessage, { merge: true })
                .then(() =>
                  from(
                    getDoc(messageDoc)
                      .then((snap) => {
                        const data = snap.data() as any;
                        const id = snap.id;
                        return { id, ...data };
                      })
                      .catch((error) => error)
                  )
                )
                .catch((err) => of(err))
            ).pipe(mergeMap((res) => res));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  delete__MESSAGE(id: string): Observable<any> {
    try {
      return this.userStore.pipe(
        select(fromUserSelector.user),
        switchMap((user) => {
          if (!user) {
            return EMPTY;
          } else {
            const messageDoc = doc(this.db, `users/${user.id}/messages/${id}`);

            return from(
              deleteDoc(messageDoc)
                .then(() => {
                  this.messageDocument = null;
                  this.message$ = of(null);
                  return null;
                })
                .then(() => 'Message supprimé')
                .catch((err) => err)
            ).pipe(mergeMap((res) => of(res)));
          }
        })
      );
    } catch (err) {
      return of(err);
    }
  }

  deleteAll__MESSAGES(): Observable<any> {
    try {
      return this.userStore.pipe(
        select(fromUserSelector.user),
        switchMap((user) => {
          if (!user) {
            return EMPTY;
          } else {
            const auteur = {
              id: user.id,
              displayName: user.displayName,
              fonction: user.fonction,
              avatar: user.avatar,
            };
            const type = 'all';

            const data = { auteur, type };
            const call = httpsCallable(
              this.fns,
              'messages-onDeleteMultiMessages'
            );
            return from(
              call(data)
                .then((rest) => rest)
                .catch((err) => err)
            ).pipe(
              mergeMap((res) => {
                return of(res);
              })
            );
          }
        })
      );
    } catch (err) {
      return of(err);
    }
  }

  deleteAllReceive__MESSAGES(): Observable<any> {
    try {
      return this.userStore.pipe(
        select(fromUserSelector.user),
        switchMap((user) => {
          if (!user) {
            return of(null);
          } else {
            const auteur = {
              id: user.id,
              displayName: user.displayName,
              fonction: user.fonction,
              avatar: user.avatar,
            };
            const type = 'other';

            const data = { auteur, type };
            const call = httpsCallable(
              this.fns,
              'messages-onDeleteMultiMessages'
            );
            return from(
              call(data)
                .then((rest) => rest)
                .catch((err) => err)
            ).pipe(
              mergeMap((res) => {
                return of(res);
              })
            );
          }
        })
      );
    } catch (err) {
      return of(err);
    }
  }
}
