<app-candidature-list
  [adresseMission]="{
    latitude: fiche?.mission?.adresse?.adresse?.geometry?.coordinates[0],
    longitude: fiche?.mission?.adresse?.adresse?.geometry?.coordinates[1]
  }"
  [candidats]="candidats"
  [candidat]="candidat$ | async"
  [loading$]="loading$"
  [candidatTemplate]="operationFicheCandidatTpl"
  (select)="onSelect($event, fiche)"
  (add)="onAddCandidats($event, fiche)"
  (delete)="onDeleteCandidat($event, fiche)"
  (deleteAll)="onDeleteCandidats($event, fiche)"
  (candidatActions)="onCandidatActions($event, fiche)"
></app-candidature-list>

<ng-template #operationFicheCandidatTpl>
  <app-operation-fiche-de-poste-candidature
    [currentView$]="currentView$"
    [fiche]="fiche"
    (changesView)="onSetCandidatItemView($event)"
  ></app-operation-fiche-de-poste-candidature>
</ng-template>
