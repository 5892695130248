import { Injectable } from '@angular/core';
import { Observable, of, from, EMPTY } from 'rxjs';
import { map, tap, mergeMap, switchMap, catchError } from 'rxjs/operators';
import { Apollo, gql } from 'apollo-angular';
import { Auth } from '@angular/fire/auth';
import { Store } from '@ngrx/store';
import {
  Firestore,
  collection,
  doc,
  docSnapshots,
  getDoc,
  collectionChanges,
  addDoc,
  setDoc,
  deleteDoc,
  serverTimestamp,
  Timestamp,
  query,
  where,
  arrayUnion,
  arrayRemove,
  collectionSnapshots,
  getDocs,
  DocumentData,
  DocumentSnapshot,
} from '@angular/fire/firestore';
import { UserState } from '../../user/store/user.reducer';
import * as fromUserSelector from '../../user/store/user.selectors';

@Injectable({
  providedIn: 'any',
})
export class OperationStatistiquesService {
  GET_STATISTIQUES = gql`
    query OperationStatistiques($operationId: ID!) {
      operationStatistiques(operationId: $operationId) {
        totalModules
        totalActions
        totalParticipants
        totalEntites
        totalMissions
        totalIntervenants
      }
    }
  `;

  constructor(
    private apollo: Apollo,
    private auth: Auth,
    private db: Firestore,

    private userStore: Store<UserState>
  ) {}

  getStatistiques(operationId: string): Observable<any> {
    if (!operationId) {
      return EMPTY;
    } else {
      return this.apollo
        .watchQuery<any>({
          query: this.GET_STATISTIQUES,
          variables: {
            operationId: operationId,
          },
        })
        .valueChanges.pipe(
          map(({ data, loading, error }) => {
            return { data, loading, error };
          })
        );
    }
  }

  getOneStatistiques(id: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !id) {
            return of(null);
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/operations/${id}/statistiques`
            );

            const documentRef = doc(dbCollection, id);

            return docSnapshots(documentRef).pipe(
              map((snap: DocumentSnapshot<DocumentData>) => {
                const data = snap.data();
                const id = snap.id;
                return { id, ...data };
              }),
              catchError((error) => EMPTY)
            );
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }
}
