import { OperationNoteExportService } from './../../../service/operation-note-export.service';
import { OperationNoteService } from './../../../service/operation-note.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NzMessageService } from 'ng-zorro-antd/message';
import * as fromOperationNoteAction from './operation-note.actions';
import { of, timer, concatMap, delay } from 'rxjs';
import { tap, mergeMap, exhaustMap, map, catchError } from 'rxjs/operators';
import {
  NzNotificationPlacement,
  NzNotificationService,
} from 'ng-zorro-antd/notification';

const NOTIFICATION_PLACEMENT: NzNotificationPlacement = 'topRight';

@Injectable()
export class OperationNoteEffects {
  source = of(1, 2, 3);

  //LOAD ALL
  loadOperationNotes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationNoteAction.loadOperationNotes),
      tap(() =>
        fromOperationNoteAction.loadingOperationNotes({ loading: true })
      ),
      mergeMap((action) =>
        this.operationNoteService.getAll(action.operationId).pipe(
          delay(2000),
          map((notes: any) =>
            fromOperationNoteAction.loadOperationNotesSuccess({ notes })
          ),
          catchError((error) =>
            of(fromOperationNoteAction.loadOperationNotesFailure({ error }))
          )
        )
      ),
      tap(() =>
        fromOperationNoteAction.loadingOperationNotes({ loading: false })
      )
    )
  );

  //LOAD ONE
  loadOperationNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationNoteAction.loadOperationNote),
      tap(() =>
        fromOperationNoteAction.loadingOperationNotes({ loading: true })
      ),
      mergeMap((action) =>
        this.operationNoteService
          .getOne(action.operationId, action.noteId)
          .pipe(
            delay(2000),
            map((note: any) =>
              fromOperationNoteAction.loadOperationNoteSuccess({ note })
            ),
            catchError((error) =>
              of(fromOperationNoteAction.loadOperationNoteFailure({ error }))
            )
          )
      ),
      tap(() =>
        fromOperationNoteAction.loadingOperationNotes({ loading: false })
      )
    )
  );

  //ADD
  addOperationNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationNoteAction.addOperationNote),
      tap(() => {
        this.notification.info(
          'Création de la note',
          'Création de la note en cours...',
          {
            nzDuration: 6000,
            nzAnimate: true,
            nzPlacement: NOTIFICATION_PLACEMENT,
            nzKey: 'ADD_ONE_OPERATION_NOTE',
          }
        );
      }),
      mergeMap((action) =>
        this.operationNoteService.createOne(action.note).pipe(
          map((respons: any) => {
            const success: string = respons.data.createOperationNote;
            this.notification.success('Création de la note', success, {
              nzDuration: 8000,
              nzAnimate: true,
              nzPlacement: NOTIFICATION_PLACEMENT,
              nzKey: 'ADD_ONE_OPERATION_NOTE',
            });
            return fromOperationNoteAction.addOperationNoteSuccess({
              success,
            });
          }),
          catchError((error) => {
            this.onErrorNotification(error, 'ADD_ONE_OPERATION_NOTE');

            return of(
              fromOperationNoteAction.addOperationNoteFailure({ error })
            );
          })
        )
      )
    )
  );

  //UPDATE
  updateOperationNote$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromOperationNoteAction.updateOperationNote),
        tap(() => {
          this.notification.info(
            'Modification de la note',
            'Modification de la note en cours...',
            {
              nzDuration: 6000,
              nzAnimate: true,
              nzPlacement: NOTIFICATION_PLACEMENT,
              nzKey: 'UPDATE_ONE_OPERATION_NOTE',
            }
          );
          fromOperationNoteAction.loadingOperationNotes({ loading: true });
        }),
        mergeMap((action) =>
          this.operationNoteService.updateOne(action.note.changes).pipe(
            map((success: any) => {
              this.notification.success('Modification de la note', success, {
                nzDuration: 8000,
                nzAnimate: true,
                nzPlacement: NOTIFICATION_PLACEMENT,
                nzKey: 'UPDATE_ONE_OPERATION_NOTE',
              });
              return fromOperationNoteAction.updateOperationNoteSuccess({
                success,
              });
            }),
            catchError((error) => {
              this.onErrorNotification(error, 'UPDATE_ONE_OPERATION_NOTE');

              return of(
                fromOperationNoteAction.updateOperationNoteFailure({ error })
              );
            })
          )
        ),
        tap(() =>
          fromOperationNoteAction.loadingOperationNotes({ loading: false })
        )
      ),
    { dispatch: false }
  );

  //EXPORT

  //DELETE ONE
  deleteOperationNote$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromOperationNoteAction.deleteOperationNote),
        tap(() => {
          this.notification.info(
            'Suppression de la note',
            'Suppression de la note en cours...',
            {
              nzDuration: 6000,
              nzAnimate: true,
              nzPlacement: NOTIFICATION_PLACEMENT,
              nzKey: 'DELETE_ONE_OPERATION_NOTE',
            }
          );
          fromOperationNoteAction.loadingOperationNotes({ loading: true });
        }),
        mergeMap((action) =>
          this.operationNoteService
            .deleteOne(action.operationId, action.id)
            .pipe(
              map((respons: any) => {
                const { data } = respons;
                const success = data.deleteOperationNote;
                this.notification.success('Suppression de la note', success, {
                  nzDuration: 8000,
                  nzAnimate: true,
                  nzPlacement: NOTIFICATION_PLACEMENT,
                  nzKey: 'DELETE_ONE_OPERATION_NOTE',
                });
                return fromOperationNoteAction.deleteOperationNoteSuccess({
                  success,
                });
              }),
              catchError((error) => {
                this.onErrorNotification(error, 'DELETE_ONE_OPERATION_NOTE');

                return of(
                  fromOperationNoteAction.deleteOperationNoteFailure({ error })
                );
              })
            )
        ),
        tap(() =>
          fromOperationNoteAction.loadingOperationNotes({ loading: false })
        )
      ),
    { dispatch: false }
  );

  //DELETE ALL
  deleteAllOperationNoteS$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromOperationNoteAction.deleteOperationNotes),
        tap(() => {
          this.notification.info(
            'Suppression des notes',
            'Suppression des notes en cours...',
            {
              nzDuration: 6000,
              nzAnimate: true,
              nzPlacement: NOTIFICATION_PLACEMENT,
              nzKey: 'DELETE_ALL_OPERATION_NOTES',
            }
          );
          fromOperationNoteAction.loadingOperationNotes({ loading: true });
        }),
        mergeMap((action) =>
          this.operationNoteService.deleteAll(action.operationId).pipe(
            map((respons: any) => {
              const { data } = respons;
              const success = data.deleteAllOperationNotes;
              this.notification.success('Suppression des notes', success, {
                nzDuration: 8000,
                nzAnimate: true,
                nzPlacement: NOTIFICATION_PLACEMENT,
                nzKey: 'EXPORT_PDF_OPERATION_NOTE',
              });
              return fromOperationNoteAction.deleteOperationNotesSuccess({
                success,
              });
            }),
            catchError((error) => {
              this.onErrorNotification(error, 'DELETE_ALL_OPERATION_NOTES');

              return of(
                fromOperationNoteAction.deleteOperationNotesFailure({ error })
              );
            })
          )
        ),
        tap(() =>
          fromOperationNoteAction.loadingOperationNotes({ loading: false })
        )
      ),
    { dispatch: false }
  );

  //EXPORT PDF VERSION
  exportPdfOperationNote$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromOperationNoteAction.exportPdfOperationNote),
        tap(() => {
          this.notification.info(
            'Export format pdf',
            "Création de l'export en cours...",
            {
              nzDuration: 6000,
              nzAnimate: true,
              nzPlacement: NOTIFICATION_PLACEMENT,
              nzKey: 'EXPORT_PDF_OPERATION_NOTE',
            }
          );
        }),
        mergeMap((action) =>
          this.operationNoteExportService
            .export__PDF(
              action.operation,
              action.note,
              action.subtitles,
              action.sujets
            )
            .pipe(
              map((success: any) => {
                this.notification.success('Export format word', success, {
                  nzDuration: 8000,
                  nzAnimate: true,
                  nzPlacement: NOTIFICATION_PLACEMENT,
                  nzKey: 'EXPORT_PDF_OPERATION_NOTE',
                });
                return fromOperationNoteAction.exportPdfOperationNoteSuccess({
                  success,
                });
              }),
              catchError((error) => {
                this.onErrorNotification(error, 'EXPORT_PDF_OPERATION_NOTE');
                return of(
                  fromOperationNoteAction.exportPdfOperationNoteFailure({
                    error,
                  })
                );
              })
            )
        )
      ),
    { dispatch: false }
  );

  //EXPORT PDF VERSION
  exportWordOperationNote$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromOperationNoteAction.exportWordOperationNote),
        tap(() => {
          this.notification.info(
            'Export format word',
            "Création de l'export en cours...",
            {
              nzDuration: 6000,
              nzAnimate: true,
              nzPlacement: NOTIFICATION_PLACEMENT,
              nzKey: 'EXPORT_WORD_OPERATION_NOTE',
            }
          );
        }),
        mergeMap((action) =>
          this.operationNoteExportService
            .export__WORD(action.operation, action.note, action.subtitles)
            .pipe(
              map((success: any) => {
                this.notification.success('Export format word', success, {
                  nzDuration: 8000,
                  nzAnimate: true,
                  nzPlacement: NOTIFICATION_PLACEMENT,
                  nzKey: 'EXPORT_WORD_OPERATION_NOTE',
                });
                return fromOperationNoteAction.exportPdfOperationNoteSuccess({
                  success,
                });
              }),
              catchError((error) => {
                this.onErrorNotification(error, 'EXPORT_WORD_OPERATION_NOTE');

                return of(
                  fromOperationNoteAction.exportPdfOperationNoteFailure({
                    error,
                  })
                );
              })
            )
        )
      ),
    { dispatch: false }
  );

  //COPY ONE
  copyOperationNote$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromOperationNoteAction.copyOperationNote),
        tap(() => {
          this.notification.info(
            'Copie de la note',
            'Création de la copie de la note en cours...',
            {
              nzDuration: 6000,
              nzAnimate: true,
              nzPlacement: NOTIFICATION_PLACEMENT,
              nzKey: 'COPY_OPERATION_NOTE',
            }
          );
        }),
        mergeMap((action) =>
          this.operationNoteService
            .copyOne(action.operationId, action.noteId)
            .pipe(
              map((response: any) => {
                const { data } = response;
                const success = data.copyOperationNote;

                this.notification.success('Copie de la note', success, {
                  nzDuration: 8000,
                  nzAnimate: true,
                  nzPlacement: NOTIFICATION_PLACEMENT,
                  nzKey: 'COPY_OPERATION_NOTE',
                });
                return fromOperationNoteAction.copyOperationNoteSuccess({
                  success,
                });
              }),
              catchError((error) => {
                this.onErrorNotification(error, 'COPY_OPERATION_NOTE');
                return of(
                  fromOperationNoteAction.copyOperationNoteFailure({
                    error,
                  })
                );
              })
            )
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private operationNoteService: OperationNoteService,
    private operationNoteExportService: OperationNoteExportService,
    private notification: NzNotificationService
  ) {}

  onErrorNotification(error: any, key: string): void {
    this.notification.error('Error', error.message, {
      nzDuration: 10000,
      nzAnimate: true,
      nzPlacement: NOTIFICATION_PLACEMENT,
      nzKey: key ? key : '',
    });
  }
}
