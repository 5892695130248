import { OperationNoteListComponent } from './components/operation-note/components/operation-note-list/operation-note-list.component';
import { OperationDashboardResolverService } from './components/operation-dashboard/resolvers/operation-dashboard-resolver.service';
import { OperationPrescripteursListComponent } from './components/operation-prescripteurs/components/operation-prescripteurs-list/operation-prescripteurs-list.component';
import { OperationParticipantsComponent } from './components/operation-participants/components/operation-participants/operation-participants.component';
import { ParticipantDiagnosticResolverService } from './resolvers/participant/diagnostics/participant-diagnostic-resolver.service';
import { InformationCollectiveListComponent } from './components/information-collective/components/information-collective-list/information-collective-list.component';
import { ParticipantSuiviComponent } from './components/participant-suivi/components/participant-suivi/participant-suivi.component';
import { ParticipantSuivisResolverService } from './resolvers/participant/suivis/participant-suivis-resolver.service';
import { ParticipantDocumentsComponent } from './components/participant-documents/components/participant-documents/participant-documents.component';
import { ParticipantDocumentsResolverService } from './resolvers/participant/documents/participant-documents-resolver.service';
import { ParticipantDiagnosticTableComponent } from './components/participant-diagnostic/components/participant-diagnostic-table/participant-diagnostic-table.component';
import { ParticipantDiagnosticItemComponent } from './components/participant-diagnostic/components/participant-diagnostic-item/participant-diagnostic-item.component';
import { ParticipantActionAccompagnementTableComponent } from './components/participant-actions-accompagnement/components/participant-action-accompagnement-table/participant-action-accompagnement-table.component';
import { ParticipantPlanningComponent } from './components/participant-planning/components/participant-planning/participant-planning.component';
import { ParticipantDashboardComponent } from './components/participant-dashboard/components/participant-dashboard/participant-dashboard.component';
import { ParticipantItemComponent } from './components/participant/components/participant-item/participant-item.component';
import { ParticipantResolverService } from './resolvers/participant/participant-resolver.service';
import { ParticipantsResolverService } from './resolvers/participant/participants-resolver.service';
import { ParticipantListComponent } from './components/participant/components/participant-list/participant-list.component';
import { ContactItemComponent } from './components/contact/components/contact-item/contact-item.component';
import { ContactResolverService } from './resolvers/contact-resolver.service';
import { ContactsResolverService } from './resolvers/contacts-resolver.service';
import { EntiteItemComponent } from './components/entite/components/entite-item/entite-item.component';
import { EntiteResolverService } from './resolvers/entite-resolver.service';
import { EntitesResolverService } from './resolvers/entites-resolver.service';
import { OperationResolverService } from './resolvers/operation-resolver.service';
import { OperationItemComponent } from './components/operation/components/operation-item/operation-item.component';
import { OperationsResolverService } from './resolvers/operations-resolver.service';
import { DepartementsResolverService } from './resolvers/departements-resolver.service';
import { DepartementItemComponent } from './components/departement/components/departement-item/departement-item.component';
import { DepartementResolverService } from './resolvers/departement-resolver.service';
import { DepartementListComponent } from './components/departement/components/departement-list/departement-list.component';
import { OperationListComponent } from './components/operation/components/operation-list/operation-list.component';
import { EntiteListComponent } from './components/entite/components/entite-list/entite-list.component';
import { ContactListComponent } from './components/contact/components/contact-list/contact-list.component';
import { DashboardComponent } from './components/dashboard/components/dashboard/dashboard.component';
import { AdminComponent } from './components/admin/components/admin/admin.component';
import { OrganisationComponent } from './components/organisation/components/organisation/organisation.component';
import { ShellComponent } from './components/shell/shell.component';
import { ForgetPasswordComponent } from './components/auth/components/forget-password/forget-password.component';
import { UserComponent } from './components/user/components/user/user.component';
import { LoginComponent } from './components/auth/components/login/login.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotfoundComponent } from './components/auth/components/notfound/notfound.component';
import {
  AuthGuard,
  hasCustomClaim,
  redirectUnauthorizedTo,
  redirectLoggedInTo,
} from '@angular/fire/auth-guard';
import { RedactionListComponent } from './components/redaction/components/redaction-list/redaction-list.component';
import { NewAccountComponent } from './components/auth/components/new-account/new-account.component';
import { UsersResolverService } from './resolvers/user/users-resolver.service';
import { UsersListComponent } from './components/user/components/users-list/users-list.component';
import { LoginSmsValidatorComponent } from './components/auth/components/login-sms-validator/login-sms-validator.component';
import { LoginCaptchaComponent } from './components/auth/components/login-captcha/login-captcha.component';
import { MissionsListComponent } from './components/missions/components/missions-list/missions-list.component';
import { MissionsResolverService } from './resolvers/missions-resolver.service';
import { ParticipantActionsAccompagnementItemComponent } from './components/participant-actions-accompagnement/components/participant-actions-accompagnement-item/participant-actions-accompagnement-item.component';
import { ParticipantActionsResolverService } from './resolvers/participant/actions/participant-actions-resolver.service';
import { ParticipantImpactsComponent } from './components/participant-impacts/components/participant-impacts/participant-impacts.component';
import { ParticipantActionResolverService } from './resolvers/participant/actions/participant-action-resolver.service';
import { ParticipantDetailComponent } from './components/participant/components/participant-detail/participant-detail.component';
import { OrganisationTitleResolverService } from './resolvers/router/organisation-title-resolver.service';
import { ParticipantDiagnosticListeComponent } from './components/participant-diagnostic/components/participant-diagnostic-liste/participant-diagnostic-liste.component';
import { InformationCollectiveItemComponent } from './components/information-collective/components/information-collective-item/information-collective-item.component';
import { OrganisationResolverService } from './resolvers/organisation/organisation-resolver.service';
import { OperationDashboardComponent } from './components/operation-dashboard/components/operation-dashboard/operation-dashboard.component';
import { OperationSuivisComponent } from './components/operation/components/operation-suivis/operation-suivis.component';
import { OperationDocumentsComponent } from './components/operation/components/operation-documents/operation-documents.component';
import { OperationTaskKanbanComponent } from './components/operation-task/components/operation-task-kanban/operation-task-kanban.component';
import { ModuleListComponent } from './components/module/components/module-list/module-list.component';
import { ActionListComponent } from './components/action/components/action-list/action-list.component';
import { OperationFichesDePosteListComponent } from './components/operation-fiche-de-poste/components/operation-fiches-de-poste-list/operation-fiches-de-poste-list.component';
import { OperationMissionsComponent } from './components/operation-mission/components/operation-missions/operation-missions.component';
import { OperationFichesDePosteItemComponent } from './components/operation-fiche-de-poste/components/operation-fiches-de-poste-item/operation-fiches-de-poste-item.component';
import { OperationFichesDePosteResolverService } from './components/operation-fiche-de-poste/resolvers/operation-fiches-de-poste-resolver.service';
import { OperationFicheDePosteResolverService } from './components/operation-fiche-de-poste/resolvers/operation-fiche-de-poste-resolver.service';
import { OperationDetailsComponent } from './components/operation/components/operation-details/operation-details.component';
import { OperationTeamComponent } from './components/operation-team/components/operation-team/operation-team.component';
import { OperationEntitesComponent } from './components/operation-entites/components/operation-entites/operation-entites.component';
import { OperationNoteTableComponent } from './components/operation-note/components/operation-note-table/operation-note-table.component';

const adminOnly = () => hasCustomClaim('admin');
const devOnly = () => hasCustomClaim('dev');
const clientOnly = () => hasCustomClaim('client');
const managerOnly = () => hasCustomClaim('manager');
const memberOnly = () => hasCustomClaim('member');
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToItems = () => redirectLoggedInTo(['items']);
//const belongsToAccount = (next) => hasCustomClaim(`account-${next.params.id}`);

const routes: Routes = [
  {
    path: '',
    component: ShellComponent,
    children: [
      {
        path: 'user',
        component: UserComponent,
        canActivate: [AuthGuard],
        data: {
          authGuardPipe: redirectUnauthorizedToLogin,
          animation: 'userPage',
        },

        loadChildren: () =>
          import('./components/user/user.module').then((m) => m.UserModule),
      },
      {
        path: 'user/:id',
        component: UserComponent,
        canActivate: [AuthGuard],
        title: 'user',

        data: {
          authGuardPipe: redirectUnauthorizedToLogin,
          animation: 'userDetailsPage',
        },
        loadChildren: () =>
          import('./components/user/user.module').then((m) => m.UserModule),
      },
      {
        path: 'organisation/:id',
        component: OrganisationComponent,
        canActivate: [AuthGuard],
        title: OrganisationTitleResolverService,

        data: {
          authGuardPipe: redirectUnauthorizedToLogin,
          animation: 'organisationDetailsPage',
        },
        resolve: { organisation: OrganisationResolverService },
        loadChildren: () =>
          import('./components/organisation/organisation.module').then(
            (m) => m.OrganisationModule
          ),
      },
      {
        path: 'organisation/:id/users',
        component: UsersListComponent,
        canActivate: [AuthGuard],
        title: OrganisationTitleResolverService,

        data: {
          authGuardPipe: redirectUnauthorizedToLogin,
          animation: 'userDetailsPage',
        },
        resolve: { users: UsersResolverService },
        loadChildren: () =>
          import('./components/user/user.module').then((m) => m.UserModule),
      },
      {
        path: 'services',
        component: DepartementListComponent,
        canActivate: [AuthGuard],
        title: 'Services',

        data: {
          authGuardPipe: redirectUnauthorizedToLogin,
          animation: 'servicesPage',
        },
        resolve: { departements: DepartementsResolverService },
        loadChildren: () =>
          import('./components/departement/departement.module').then(
            (m) => m.DepartementModule
          ),
      },
      {
        path: 'service/:id',
        component: DepartementItemComponent,
        canActivate: [AuthGuard],
        title: 'Service',

        data: {
          authGuardPipe: redirectUnauthorizedToLogin,
          animation: 'serviceDetailsPage',
        },
        resolve: { departement: DepartementResolverService },
      },
      {
        path: 'missions',
        component: MissionsListComponent,
        canActivate: [AuthGuard],
        title: 'Missions',

        data: {
          authGuardPipe: redirectUnauthorizedToLogin,
          animation: 'missionsPage',
        },
        resolve: { missions: MissionsResolverService },
        loadChildren: () =>
          import('./components/missions/missions.module').then(
            (m) => m.MissionsModule
          ),
      },

      {
        path: 'entites',
        component: EntiteListComponent,
        canActivate: [AuthGuard],
        title: 'Entités',

        data: {
          authGuardPipe: redirectUnauthorizedToLogin,
          animation: 'entitesPage',
        },
        resolve: { entites: EntitesResolverService },
        loadChildren: () =>
          import('./components/entite/entite.module').then(
            (m) => m.EntiteModule
          ),
      },
      {
        path: 'entite/:id',
        component: EntiteItemComponent,
        canActivate: [AuthGuard],
        title: 'Entité',

        data: {
          authGuardPipe: redirectUnauthorizedToLogin,
          animation: 'entiteDetailsPage',
        },
        resolve: { entite: EntiteResolverService },
      },

      {
        path: 'contacts',
        component: ContactListComponent,
        canActivate: [AuthGuard],
        title: 'Contacts',

        data: {
          authGuardPipe: redirectUnauthorizedToLogin,
          animation: 'contactsPage',
        },
        resolve: { contacts: ContactsResolverService },
        loadChildren: () =>
          import('./components/contact/contact.module').then(
            (m) => m.ContactModule
          ),
      },
      {
        path: 'contact/:id',
        component: ContactItemComponent,
        resolve: { contact: ContactResolverService },
        title: 'Contact',

        canActivate: [AuthGuard],
        data: {
          authGuardPipe: redirectUnauthorizedToLogin,
          animation: 'contactDetailsPage',
        },
      },
      {
        path: 'participants',
        component: ParticipantListComponent,
        canActivate: [AuthGuard],
        title: 'Participants',

        data: {
          authGuardPipe: redirectUnauthorizedToLogin,
          animation: 'participantsPage',
        },
        resolve: { participants: ParticipantsResolverService },
        loadChildren: () =>
          import('./components/participant/participant.module').then(
            (m) => m.ParticipantModule
          ),
      },
      {
        path: 'participant/:id',
        component: ParticipantItemComponent,
        canActivate: [AuthGuard],
        title: 'Participant',
        data: {
          authGuardPipe: redirectUnauthorizedToLogin,
          animation: 'participantDetailsPage',
        },
        resolve: { participant: ParticipantResolverService },
        children: [
          {
            path: '',
            component: ParticipantDiagnosticItemComponent,
            data: {
              authGuardPipe: redirectUnauthorizedToLogin,
              animation: 'participantDetailsPage',
            },
            resolve: { action: ParticipantDiagnosticResolverService },
            loadChildren: () =>
              import(
                './components/participant-diagnostic/participant-diagnostic.module'
              ).then((m) => m.ParticipantDiagnosticModule),
          },
          {
            path: 'suivis',
            component: ParticipantSuiviComponent,
            data: {
              authGuardPipe: redirectUnauthorizedToLogin,
              animation: 'participantDetailsPage',
            },
            resolve: { action: ParticipantSuivisResolverService },
            loadChildren: () =>
              import(
                './components/participant-suivi/participant-suivi.module'
              ).then((m) => m.ParticipantSuiviModule),
          },
          {
            path: 'documents',
            component: ParticipantDocumentsComponent,
            data: {
              authGuardPipe: redirectUnauthorizedToLogin,
              animation: 'participantDetailsPage',
            },
            resolve: { action: ParticipantDocumentsResolverService },
            loadChildren: () =>
              import(
                './components/participant-documents/participant-documents.module'
              ).then((m) => m.ParticipantDocumentsModule),
          },
          {
            path: 'diagnostic',
            component: ParticipantDiagnosticItemComponent,
            data: {
              authGuardPipe: redirectUnauthorizedToLogin,
              animation: 'participantDetailsPage',
              page: 'participant',
            },
            resolve: { action: ParticipantDiagnosticResolverService },
            loadChildren: () =>
              import(
                './components/participant-diagnostic/participant-diagnostic.module'
              ).then((m) => m.ParticipantDiagnosticModule),
          },

          {
            path: 'actions',
            component: ParticipantActionAccompagnementTableComponent,
            data: {
              authGuardPipe: redirectUnauthorizedToLogin,
              animation: 'participantDetailsPage',
              page: 'participant',
            },
            resolve: { action: ParticipantActionsResolverService },
          },
          {
            path: 'action/:itemId',
            component: ParticipantActionsAccompagnementItemComponent,
            data: {
              authGuardPipe: redirectUnauthorizedToLogin,
              animation: 'participantDetailsPage',
            },
            resolve: { action: ParticipantActionResolverService },
          },
          {
            path: 'planning',
            component: ParticipantPlanningComponent,
            data: {
              authGuardPipe: redirectUnauthorizedToLogin,
              animation: 'participantDetailsPage',
            },
            //resolve: { action: ParticipantActionsResolverService },
          },
          {
            path: 'impacts',
            component: ParticipantImpactsComponent,
            data: {
              authGuardPipe: redirectUnauthorizedToLogin,
              animation: 'participantDetailsPage',
            },
            //resolve: { action: ParticipantActionsResolverService },
          },
          {
            path: 'dashboard',
            component: ParticipantDashboardComponent,
            data: {
              authGuardPipe: redirectUnauthorizedToLogin,
              animation: 'participantDetailsPage',
            },
            //resolve: { action: ParticipantActionsResolverService },
          },
        ],
      },
      {
        path: 'informationsCollectives',
        component: InformationCollectiveListComponent,
        canActivate: [AuthGuard],
        title: 'Info. collectives',

        data: {
          authGuardPipe: redirectUnauthorizedToLogin,
          animation: 'informationsCollectivesPage',
        },
        //resolve: { departements: DepartementsResolverService },
        loadChildren: () =>
          import(
            './components/information-collective/information-collective.module'
          ).then((m) => m.InformationCollectiveModule),
      },
      {
        path: 'informationCollective/:id',
        component: InformationCollectiveItemComponent,
        canActivate: [AuthGuard],
        title: 'Info. collective',

        data: {
          authGuardPipe: redirectUnauthorizedToLogin,
          animation: 'informationCollectivePage',
        },
        //resolve: { departement: DepartementResolverService },
      },
      {
        path: 'projets',
        component: OperationListComponent,
        canActivate: [AuthGuard],
        title: 'Projets',

        data: {
          authGuardPipe: redirectUnauthorizedToLogin,
          animation: 'projectPage',
          title: 'Projects',
        },
        resolve: { operations: OperationsResolverService },
        loadChildren: () =>
          import('./components/operation/operation.module').then(
            (m) => m.OperationModule
          ),
      },
      {
        path: 'projet/:id',
        component: OperationItemComponent,
        title: 'Projet',

        canActivate: [AuthGuard],
        resolve: { operation: OperationResolverService },
        data: {
          authGuardPipe: redirectUnauthorizedToLogin,
          animation: 'projetDetailsPage',
        },
        children: [
          {
            path: '',
            component: OperationDashboardComponent,
            title: 'Projet - Dashboard',

            canActivate: [AuthGuard],
            data: {
              authGuardPipe: redirectUnauthorizedToLogin,
              animation: 'projetDetailsPage',
              title: 'Tableau de bord',
            },
            resolve: { operationDashboard: OperationDashboardResolverService },

            loadChildren: () =>
              import(
                './components/operation-dashboard/operation-dashboard.module'
              ).then((m) => m.OperationDashboardModule),
          },
          {
            path: 'parametres',
            component: OperationDetailsComponent,
            title: 'Projet - Paramètres',
            canActivate: [AuthGuard],
            resolve: { operationDashboard: OperationDashboardResolverService },
            data: {
              authGuardPipe: redirectUnauthorizedToLogin,
              animation: 'projetDetailsPage',
              title: 'Paramètres',
            },
          },
          {
            path: 'team',
            component: OperationTeamComponent,
            title: 'Projet - Équipe',
            canActivate: [AuthGuard],
            resolve: { operationDashboard: OperationDashboardResolverService },
            data: {
              authGuardPipe: redirectUnauthorizedToLogin,
              animation: 'projetDetailsPage',
              title: 'Équipe',
            },
            loadChildren: () =>
              import('./components/operation-team/operation-team.module').then(
                (m) => m.OperationTeamModule
              ),
          },
          {
            path: 'suivis',
            component: OperationNoteTableComponent,
            title: 'Projet - Notes',

            canActivate: [AuthGuard],
            data: {
              authGuardPipe: redirectUnauthorizedToLogin,
              animation: 'projetDetailsPage',
              title: 'Notes',
            },
            loadChildren: () =>
              import('./components/operation-note/operation-note.module').then(
                (m) => m.OperationNoteModule
              ),
          },
          {
            path: 'documents',
            component: OperationDocumentsComponent,
            title: 'Projet - Documents',

            canActivate: [AuthGuard],
            data: {
              authGuardPipe: redirectUnauthorizedToLogin,
              animation: 'projetDetailsPage',
              title: 'Documents',
            },
          },
          {
            path: 'tasks',
            component: OperationTaskKanbanComponent,
            title: 'Projet - Tâches',

            canActivate: [AuthGuard],
            data: {
              authGuardPipe: redirectUnauthorizedToLogin,
              animation: 'projetDetailsPage',
              title: 'Tâches',
            },
            loadChildren: () =>
              import('./components/operation-task/operation-task.module').then(
                (m) => m.OperationTaskModule
              ),
          },
          {
            path: 'participants',
            component: OperationParticipantsComponent,
            title: 'Projet - Participants',

            canActivate: [AuthGuard],
            data: {
              authGuardPipe: redirectUnauthorizedToLogin,
              animation: 'projetDetailsPage',
              title: 'Participants',
            },
            loadChildren: () =>
              import(
                './components/operation-participants/operation-participants.module'
              ).then((m) => m.OperationParticipantsModule),
          },
          {
            path: 'entites',
            component: OperationEntitesComponent,
            title: 'Projet - Entreprises',

            canActivate: [AuthGuard],
            data: {
              authGuardPipe: redirectUnauthorizedToLogin,
              animation: 'projetDetailsPage',
              title: 'Entreprises',
            },
            loadChildren: () =>
              import(
                './components/operation-entites/operation-entites.module'
              ).then((m) => m.OperationEntitesModule),
          },
          {
            path: 'prescripteurs',
            component: OperationPrescripteursListComponent,
            title: 'Projet - Prescripteurs',

            canActivate: [AuthGuard],
            data: {
              authGuardPipe: redirectUnauthorizedToLogin,
              animation: 'projetDetailsPage',
              title: 'Prescripteurs',
            },
            loadChildren: () =>
              import(
                './components/operation-prescripteurs/operation-prescripteurs.module'
              ).then((m) => m.OperationPrescripteursModule),
          },
          {
            path: 'modules',
            component: ModuleListComponent,
            title: 'Projet - Lots',

            canActivate: [AuthGuard],
            data: {
              authGuardPipe: redirectUnauthorizedToLogin,
              animation: 'projetDetailsPage',
              title: 'Marchés',
            },
            loadChildren: () =>
              import('./components/module/module.module').then(
                (m) => m.ModuleModule
              ),
          },
          {
            path: 'actions',
            component: ActionListComponent,
            title: 'Projet - Marchés',

            canActivate: [AuthGuard],
            data: {
              authGuardPipe: redirectUnauthorizedToLogin,
              animation: 'projetDetailsPage',
              title: "Corps d'état",
            },
            loadChildren: () =>
              import('./components/action/action.module').then(
                (m) => m.ActionModule
              ),
          },
          {
            path: 'fichesDePoste',
            component: OperationFichesDePosteListComponent,
            title: 'Projet - Fiches de poste',

            canActivate: [AuthGuard],
            data: {
              authGuardPipe: redirectUnauthorizedToLogin,
              animation: 'projetDetailsPage',
              title: 'Fiches de poste',
            },
            resolve: { fiches: OperationFichesDePosteResolverService },
            loadChildren: () =>
              import(
                './components/operation-fiche-de-poste/operation-fiche-de-poste.module'
              ).then((m) => m.OperationFicheDePosteModule),
          },
          {
            path: 'ficheDePoste/:itemId',
            component: OperationFichesDePosteItemComponent,
            title: 'Projet - Fiche de poste',

            canActivate: [AuthGuard],
            data: {
              authGuardPipe: redirectUnauthorizedToLogin,
              animation: 'projetDetailsPage',
              title: 'Fiche de poste',
            },
            resolve: { fiche: OperationFicheDePosteResolverService },
            loadChildren: () =>
              import(
                './components/operation-fiche-de-poste/operation-fiche-de-poste.module'
              ).then((m) => m.OperationFicheDePosteModule),
          },
          {
            path: 'missions',
            component: OperationMissionsComponent,
            title: 'Projet - Missions',

            canActivate: [AuthGuard],
            data: {
              authGuardPipe: redirectUnauthorizedToLogin,
              animation: 'projetDetailsPage',
              title: 'Contrats',
            },
            loadChildren: () =>
              import(
                './components/operation-mission/operation-mission.module'
              ).then((m) => m.OperationMissionModule),
          },
          {
            path: 'mission/:itemId',
            component: OperationMissionsComponent,
            title: 'Projet - Missions',

            canActivate: [AuthGuard],
            data: {
              authGuardPipe: redirectUnauthorizedToLogin,
              animation: 'projetDetailsPage',
            },
            loadChildren: () =>
              import(
                './components/operation-mission/operation-mission.module'
              ).then((m) => m.OperationMissionModule),
          },
        ],
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard],
        title: 'Tableau de bord',

        data: {
          authGuardPipe: redirectUnauthorizedToLogin,
          animation: 'dashboardPage',
        },
        loadChildren: () =>
          import('./components/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'redactions',
        component: RedactionListComponent,
        canActivate: [AuthGuard],
        title: 'Rédactions',

        data: {
          authGuardPipe: redirectUnauthorizedToLogin,
          animation: 'redactionPage',
        },
        loadChildren: () =>
          import('./components/redaction/redaction.module').then(
            (m) => m.RedactionModule
          ),
      },

      {
        path: 'admin',
        component: AdminComponent,
        canActivate: [AuthGuard],
        title: 'Administrateur',

        data: {
          authGuardPipe: redirectUnauthorizedToLogin,
          animation: 'adminPage',
        },
        loadChildren: () =>
          import('./components/admin/admin.module').then((m) => m.AdminModule),
      },
      {
        path: '',
        redirectTo: 'user',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      animation: 'loginPage',
    },
    loadChildren: () =>
      import('./components/auth/auth.module').then((m) => m.AuthModule),
  },

  {
    path: 'loginCaptchaVerify',
    component: LoginCaptchaComponent,
    data: {
      animation: 'loginPage',
    },
    loadChildren: () =>
      import('./components/auth/auth.module').then((m) => m.AuthModule),
  },

  {
    path: 'loginPhoneVerify',
    component: LoginSmsValidatorComponent,
    data: {
      animation: 'loginPage',
    },
    loadChildren: () =>
      import('./components/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'account',
    component: NewAccountComponent,
    data: {
      animation: 'loginPage',
    },
    loadChildren: () =>
      import('./components/auth/auth.module').then((m) => m.AuthModule),
  },

  {
    path: 'lostpassword',
    component: ForgetPasswordComponent,
    data: { animation: 'lostPassPage' },
    loadChildren: () =>
      import('./components/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '**',
    component: NotfoundComponent,
    data: { animation: 'notFoundPage' },
    loadChildren: () =>
      import('./components/auth/auth.module').then((m) => m.AuthModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
