import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  AfterContentChecked,
  ChangeDetectionStrategy,
  SimpleChanges,
  Input,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EtablissementState } from '../../store/entite-search.reducer';
import * as fromEtablissementAction from '../../store/entite-search.actions';
import * as fromEtablissementSelector from '../../store/entite-search.selectors';
import { Store, select } from '@ngrx/store';
import { fade } from 'src/app/app-animation';

@Component({
  selector: 'app-entite-search',
  templateUrl: './entite-search.component.html',
  styleUrls: ['./entite-search.component.scss'],
  animations: [fade],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntiteSearchComponent
  implements OnInit, AfterContentChecked, OnDestroy
{
  subscribe = new Subject();
  loading$: Observable<boolean> = this.etablissementStore.pipe(
    select(fromEtablissementSelector.loading)
  );
  etablissements$: Observable<any> = this.etablissementStore.pipe(
    select(fromEtablissementSelector.etablissements)
  );
  etablissement$: Observable<any> = this.etablissementStore.pipe(
    select(fromEtablissementSelector.etablissement)
  );
  header$: Observable<any> = this.etablissementStore.pipe(
    select(fromEtablissementSelector.header)
  );

  currentEtablissement$ = new BehaviorSubject<any>('');

  listOfOption: Array<{ value: string; label: string }> = [];

  searchForm: FormGroup<any> = this.fb.group({});
  input = new FormControl<string>('', Validators.required);
  cursor = new FormControl<string>('');
  codeCommune = new FormControl<string>('');
  codePostal = new FormControl<string>('');
  categorieJuridique = new FormControl<string>('');
  etablissementSiege = new FormControl<boolean>(false);

  placeholder: string = 'Recherche par raison sociale, le siren ou le siret';
  paramCommune: string = '';
  paramDepartement: string = '';

  inputModel: string = '';
  isOpenSelect: boolean = false;
  numberAlert: string = '';
  currentSearchInput: string = '';
  currentFilterRegion: string = '';
  currentFilterCommune: string = '';
  currentFilterDepartement: string = '';

  isFilterOpened: boolean = false;

  dropdownStyle = {
    'max-height': '160px !important',
  };

  @Input() isFilter: boolean = true;
  @Input() etablissementUpdate: any;
  @Output() addItem = new EventEmitter<any>();
  @Output() select = new EventEmitter<any>();

  constructor(
    private etablissementStore: Store<EtablissementState>,
    private fb: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.form();
    this.getLoading();
    this.searchAPI();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.isOpenSelect = false;

    this.onUpdateEtablissement(changes?.etablissementUpdate?.currentValue);
  }

  ngAfterContentChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  getLoading(): void {
    this.loading$ = this.etablissementStore.select(
      fromEtablissementSelector.loading
    );
  }

  form(): void {
    this.formCreate();
  }

  formInit(): void {
    this.input = new FormControl<string>('', Validators.required);
    this.cursor = new FormControl<string>('');
    this.codeCommune = new FormControl<string>('');
    this.codePostal = new FormControl<string>('');
    this.categorieJuridique = new FormControl<string>('');
    this.etablissementSiege = new FormControl<boolean>(false);
  }

  formCreate(): void {
    this.searchForm = this.fb.group({
      input: this.input,
      cursor: this.cursor,
      codeCommune: this.codeCommune,
      codePostal: this.codePostal,
      categorieJuridique: this.categorieJuridique,
      etablissementSiege: this.etablissementSiege,
    });
  }

  etablissementComparer(item1: any, item2: any) {
    return item1 == item2 && item1.siret === item2.siret;
  }

  onUpdateEtablissement(denominationUniteLegale: any): void {
    if (!denominationUniteLegale) return;
    this.currentEtablissement$.next(denominationUniteLegale);
  }

  onClearUpdateEtablissement(): void {
    this.currentEtablissement$.next('');
  }

  getHeader(): void {
    this.header$ = this.etablissementStore.pipe(
      select(fromEtablissementSelector.header)
    );
  }

  setListOfOptions(): void {
    this.etablissements$
      .pipe(takeUntil(this.subscribe))
      .subscribe((etablissements: any[]) => {
        if (!etablissements?.length) {
          this.listOfOption = [];
        } else {
          const listOfData = etablissements.map((el) => ({
            value: el,
            label: el?.denomination,
          }));
          this.listOfOption = listOfData;
          this.getEtablissement();
        }
      });
  }

  trackByFn(index: number, item: any) {
    return index; // or item.id
  }

  searchAPI(): void {
    this.searchForm.valueChanges
      .pipe(takeUntil(this.subscribe))
      .subscribe((values) => {
        const {
          input,
          cursor,
          codeCommune,
          codePostal,
          categorieJuridique,
          etablissementSiege,
        } = values;

        const filter = {
          input: input,
          cursor: cursor ? cursor : '',
          codeCommune: codeCommune ? codeCommune : '',
          codePostal: codePostal ? codePostal : '',
          categorieJuridique: categorieJuridique ? categorieJuridique : '',
          etablissementSiege: etablissementSiege ? true : false,
        };

        if (!input?.length) {
          this.isOpenSelect = false;
          return;
        }

        this.currentSearchInput = input;
        this.etablissementStore.dispatch(
          fromEtablissementAction.loadEntiteSearch({ filter })
        );
      });
  }

  onFilterSelected(): void {
    this.isFilterOpened = !this.isFilterOpened;
  }
  onCloseFilterSelected(): void {
    this.isFilterOpened = false;
  }

  searchFilter(filters: any): void {
    const { region, departement, commune } = filters;

    this.currentFilterRegion = region?.nom;
    this.currentFilterDepartement = departement?.nom;
    this.currentFilterCommune = commune?.nom;

    this.searchForm.patchValue({
      input: this.currentSearchInput,
      codeCommune: commune.code ? commune.code : '',
      codePostal: departement.code ? departement.code : '',
    });
  }

  onOpenSearchList(): void {
    this.isOpenSelect = true;
  }

  onReset(): void {
    this.searchForm.reset();
    this.isOpenSelect = false;
    this.etablissementStore.dispatch(
      fromEtablissementAction.loadEntiteSearchClear()
    );
  }

  onSearchEtablissements(text: string): void {
    if (!text) {
      this.searchForm.reset();
    }

    if (text?.length < 3) return;

    this.searchForm.patchValue({ input: text });
  }

  onSelectEtablissement(etablissement: any): void {
    if (!etablissement) return;
    const { siret } = etablissement;
    this.etablissementStore.dispatch(
      fromEtablissementAction.loadEtablissement({ siret })
    );

    this.getEtablissement();
  }

  getEtablissement(): void {
    this.etablissementStore
      .select(fromEtablissementSelector.etablissement)
      .pipe(takeUntil(this.subscribe))
      .subscribe((item) => {
        if (!item) return;

        if (!item?.uniteLegale?.denominationUniteLegale) return;
        const etablissement = {
          ...item,
          activitePrincipaleRegistreMetiersEtablissement:
            item?.activitePrincipaleRegistreMetiersEtablissement
              ? item.activitePrincipaleRegistreMetiersEtablissement
              : '',
          anneeEffectifsEtablissement: item?.anneeEffectifsEtablissement
            ? item.anneeEffectifsEtablissement
            : '',
          trancheEffectifsEtablissement: item?.trancheEffectifsEtablissement
            ? item.trancheEffectifsEtablissement
            : '',

          adresse2Etablissement: this.onVerifyEtablissementAdresse(
            item.adresse2Etablissement
          ),
          adresseEtablissement: this.onVerifyEtablissementAdresse(
            item.adresseEtablissement
          ),
          uniteLegale: this.onVerifyEtablissementUniteLegale(item),
        };
        const { denominationUniteLegale } = etablissement.uniteLegale;

        this.select.emit(etablissement);
        this.currentEtablissement$.next(denominationUniteLegale);

        this.etablissementStore.dispatch(
          fromEtablissementAction.loadEntiteSearchClear()
        );
        this.etablissements$ = of(null);
      });
  }

  onFilterRegionClose(event: any): void {
    this.searchForm.patchValue({
      input: this.currentSearchInput,
      codeCommune: '',
      codePostal: '',
    });
  }
  onFilterDepartementClose(event: any): void {
    this.searchForm.patchValue({
      input: this.currentSearchInput,
      codeCommune: '',
      codePostal: '',
    });
  }
  onFilterCommuneClose(event: any): void {
    this.searchForm.patchValue({
      input: this.currentSearchInput,
      codeCommune: '',
    });
  }

  close(event: boolean): void {
    this.searchForm.reset();
    this.isOpenSelect = false;
  }

  onCurrentEtablissement(): void {
    this.etablissement$.pipe(takeUntil(this.subscribe)).subscribe((item) => {
      if (!item) return;

      if (!item?.uniteLegale?.denominationUniteLegale) return;
      const etablissement = {
        ...item,
        activitePrincipaleRegistreMetiersEtablissement:
          item?.activitePrincipaleRegistreMetiersEtablissement
            ? item.activitePrincipaleRegistreMetiersEtablissement
            : '',
        anneeEffectifsEtablissement: item?.anneeEffectifsEtablissement
          ? item.anneeEffectifsEtablissement
          : '',
        trancheEffectifsEtablissement: item?.trancheEffectifsEtablissement
          ? item.trancheEffectifsEtablissement
          : '',

        adresse2Etablissement: this.onVerifyEtablissementAdresse(
          item.adresse2Etablissement
        ),
        adresseEtablissement: this.onVerifyEtablissementAdresse(
          item.adresseEtablissement
        ),
        uniteLegale: this.onVerifyEtablissementUniteLegale(item),
      };
      this.select.emit(etablissement);
      this.etablissementStore.dispatch(
        fromEtablissementAction.loadEntiteSearchClear()
      );
      this.etablissements$ = of(null);
    });
  }

  onVerifyEtablissementUniteLegale(etablissement: any): any {
    const { uniteLegale } = etablissement;
    const etablissementUniteLegale = {
      ...uniteLegale,
      unitePurgeeUniteLegale: uniteLegale?.unitePurgeeUniteLegale
        ? uniteLegale.unitePurgeeUniteLegale
        : '',
      sigleUniteLegale: uniteLegale?.sigleUniteLegale
        ? uniteLegale.sigleUniteLegale
        : '',
      sexeUniteLegale: uniteLegale?.sexeUniteLegale
        ? uniteLegale.sexeUniteLegale
        : '',
      prenom1UniteLegale: uniteLegale?.prenom1UniteLegale
        ? uniteLegale.prenom1UniteLegale
        : '',
      prenom2UniteLegale: uniteLegale?.prenom2UniteLegale
        ? uniteLegale.prenom2UniteLegale
        : '',
      prenom3UniteLegale: uniteLegale?.prenom3UniteLegale
        ? uniteLegale.prenom3UniteLegale
        : '',
      prenom4UniteLegale: uniteLegale?.prenom4UniteLegale
        ? uniteLegale.prenom4UniteLegale
        : '',
      prenomUsuelUniteLegale: uniteLegale?.prenomUsuelUniteLegale
        ? uniteLegale.prenomUsuelUniteLegale
        : '',
      pseudonymeUniteLegale: uniteLegale?.pseudonymeUniteLegale
        ? uniteLegale.pseudonymeUniteLegale
        : '',
      identifiantAssociationUniteLegale:
        uniteLegale?.identifiantAssociationUniteLegale
          ? uniteLegale.identifiantAssociationUniteLegale
          : '',
      trancheEffectifsUniteLegale: uniteLegale?.trancheEffectifsUniteLegale
        ? uniteLegale.trancheEffectifsUniteLegale
        : '',
      anneeEffectifsUniteLegale: uniteLegale?.anneeEffectifsUniteLegale
        ? uniteLegale.anneeEffectifsUniteLegale
        : '',
      nombrePeriodesUniteLegale: uniteLegale?.nombrePeriodesUniteLegale
        ? uniteLegale.nombrePeriodesUniteLegale
        : 0,
      nomUniteLegale: uniteLegale?.nomUniteLegale
        ? uniteLegale.nomUniteLegale
        : '',
      denominationUsuelle1UniteLegale:
        uniteLegale?.denominationUsuelle1UniteLegale
          ? uniteLegale.denominationUsuelle1UniteLegale
          : '',
      denominationUsuelle2UniteLegale:
        uniteLegale?.denominationUsuelle2UniteLegale
          ? uniteLegale.denominationUsuelle2UniteLegale
          : '',
      denominationUsuelle3UniteLegale:
        uniteLegale?.denominationUsuelle3UniteLegale
          ? uniteLegale.denominationUsuelle3UniteLegale
          : '',
      nomUsageUniteLegale: uniteLegale?.nomUsageUniteLegale
        ? uniteLegale.nomUsageUniteLegale
        : '',
    };

    return etablissementUniteLegale;
  }

  onVerifyEtablissementAdresse(item: any): any {
    const adresse = {
      complementAdresseEtablissement: item?.complementAdresseEtablissement
        ? item.complementAdresseEtablissement
        : '',
      numeroVoieEtablissement: item?.numeroVoieEtablissement
        ? item.numeroVoieEtablissement
        : '',
      indiceRepetitionEtablissement: item?.indiceRepetitionEtablissement
        ? item.indiceRepetitionEtablissement
        : '',
      typeVoieEtablissement: item?.typeVoieEtablissement
        ? item.typeVoieEtablissement
        : '',
      libelleVoieEtablissement: item?.libelleVoieEtablissement
        ? item.libelleVoieEtablissement
        : '',
      codePostalEtablissement: item?.codePostalEtablissement
        ? item.codePostalEtablissement
        : '',
      libelleCommuneEtablissement: item?.libelleCommuneEtablissement
        ? item.libelleCommuneEtablissement
        : '',
      libelleCommuneEtrangerEtablissement:
        item?.libelleCommuneEtrangerEtablissement
          ? item.libelleCommuneEtrangerEtablissement
          : '',
      distributionSpecialeEtablissement: item?.distributionSpecialeEtablissement
        ? item.distributionSpecialeEtablissement
        : '',
      codeCommuneEtablissement: item?.codeCommuneEtablissement
        ? item.codeCommuneEtablissement
        : '',
      codeCedexEtablissement: item?.codeCedexEtablissement
        ? item.codeCedexEtablissement
        : '',
      libelleCedexEtablissement: item?.libelleCedexEtablissement
        ? item.libelleCedexEtablissement
        : '',
      codePaysEtrangerEtablissement: item?.codePaysEtrangerEtablissement
        ? item.codePaysEtrangerEtablissement
        : '',
      libellePaysEtrangerEtablissement: item?.libellePaysEtrangerEtablissement
        ? item.libellePaysEtrangerEtablissement
        : '',
      fullAdresse: item?.fullAdresse ? item.fullAdresse : '',
      commune: item?.commune
        ? item.commune
        : this.onVerifyCommune(item?.commune),
    };

    return adresse;
  }

  onVerifyCommune(item: any): any {
    const commune = item
      ? item
      : {
          code: '',
          nom: '',
          codesPostaux: [],
          siren: '',
          codeEpci: '',
          codeDepartement: '',
          codeRegion: '',
          epci: {
            code: '',
            nom: '',
            type: '',
            financement: '',
            codesDepartements: [],
            codesRegions: [],
            population: 0,
            surface: 0,
            centre: '',
            contour: '',
            bbox: '',
          },
          departement: {
            code: '',
            nom: '',
            codeRegion: '',
            region: {
              code: '',
              nom: '',
            },
          },
          region: {
            code: '',
            nom: '',
          },
          population: 0,
          surface: 0,
          centre: '',
          contour: '',
          mairie: '',
          bbox: '',
        };

    return commune;
  }

  onVerifyEtablissementPeriodArray(items: any[]): any {
    return items?.length
      ? items.map((el) => this.onVerifyEtablissementPeriod(el))
      : [];
  }

  onVerifyEtablissementPeriod(item: any): any {
    const period = {
      dateFin: item?.dateFin ? item.dateFin : '',
      dateDebut: item?.dateDebut ? item?.dateDebut : '',
      etatAdministratifEtablissement: item?.etatAdministratifEtablissement
        ? item?.etatAdministratifEtablissement
        : '',
      changementEtatAdministratifEtablissement:
        item?.changementEtatAdministratifEtablissement
          ? item.changementEtatAdministratifEtablissement
          : false,
      enseigne1Etablissement: item?.enseigne1Etablissement
        ? item.enseigne1Etablissement
        : '',
      enseigne2Etablissement: item?.enseigne2Etablissement
        ? item.enseigne2Etablissement
        : '',
      enseigne3Etablissement: item?.enseigne3Etablissement
        ? item.enseigne3Etablissement
        : '',
      changementEnseigneEtablissement: item?.changementEnseigneEtablissement
        ? item.changementEnseigneEtablissement
        : false,
      denominationUsuelleEtablissement: item?.denominationUsuelleEtablissement
        ? item.denominationUsuelleEtablissement
        : '',
      changementDenominationUsuelleEtablissement:
        item?.changementDenominationUsuelleEtablissement
          ? item.changementDenominationUsuelleEtablissement
          : false,
      activitePrincipaleEtablissement: item?.activitePrincipaleEtablissement
        ? item.activitePrincipaleEtablissement
        : '',
      nomenclatureActivitePrincipaleEtablissement:
        item?.nomenclatureActivitePrincipaleEtablissement
          ? item.nomenclatureActivitePrincipaleEtablissement
          : '',
      changementActivitePrincipaleEtablissement:
        item?.changementActivitePrincipaleEtablissement
          ? item.changementActivitePrincipaleEtablissement
          : false,
      caractereEmployeurEtablissement: item?.caractereEmployeurEtablissement
        ? item.caractereEmployeurEtablissement
        : '',
      changementCaractereEmployeurEtablissement:
        item?.changementCaractereEmployeurEtablissement
          ? item.changementCaractereEmployeurEtablissement
          : true,
    };

    return period;
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }
}
