import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isLoading',
})
export class IsLoadingPipe implements PipeTransform {
  transform(value: any): boolean {
    if (!value) {
      return true;
    }

    return false;
  }
}
