import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

export const loadingUtilities = createAction(
  '[Utilities/COMPONENT] Loading Utilities',
  props<{ loading: boolean }>()
);

//CIVILITIES
export const loadCivilities = createAction(
  '[Utilities/COMPONENT] Load Civilities Utilities'
);

export const loadCivilitiesSuccess = createAction(
  '[Utilities/API] Load Civilities Utilities Success',
  props<{ civilities: string[] }>()
);

export const loadCivilitiesFailure = createAction(
  '[Utilities/API] Load Civilities Utilities Failure',
  props<{ error: any }>()
);

//DIAGNOSTIC TYPES
export const loadDiagnosticTypes = createAction(
  '[Utilities/COMPONENT] Load Diagnostic types Utilities'
);

export const loadDiagnosticTypesSuccess = createAction(
  '[Utilities/API] Load Diagnostic types Utilities Success',
  props<{ types: any[] }>()
);

export const loadDiagnosticTypesFailure = createAction(
  '[Utilities/API] Load Diagnostic types Utilities Failure',
  props<{ error: any }>()
);

//COMMUNICATIONS
export const loadCommunication = createAction(
  '[Utilities/COMPONENT] Load Communications Utilities'
);

export const loadCommunicationSuccess = createAction(
  '[Utilities/API] Load Communications Utilities Success',
  props<{ communications: string[] }>()
);

export const loadCommunicationFailure = createAction(
  '[Utilities/API] Load Communications Utilities Failure',
  props<{ error: any }>()
);

//NUMERIQUE
export const loadNumeriqueReferentiel = createAction(
  '[Utilities/COMPONENT] Load Numerique Referentiel'
);

export const loadNumeriqueReferentielSuccess = createAction(
  '[Utilities/API] Load Numerique Referentiel Success',
  props<{ utilities: any }>()
);

export const loadNumeriqueReferentielFailure = createAction(
  '[Utilities/API] Load Numerique Referentiel Failure',
  props<{ error: any }>()
);

//CRITERES
export const loadCriteresReferentiel = createAction(
  '[Utilities/COMPONENT] Load Criteres Referentiel'
);

export const loadCriteresReferentielSuccess = createAction(
  '[Utilities/API] Load Criteres Referentiel Success',
  props<{ utilities: any }>()
);

export const loadCriteresReferentielFailure = createAction(
  '[Utilities/API] Load Criteres Referentiel Failure',
  props<{ error: any }>()
);

//SORTIE
export const loadSortieReferentiel = createAction(
  '[Utilities/COMPONENT] Load Sortie Referentiel'
);

export const loadSortieReferentielSuccess = createAction(
  '[Utilities/API] Load Sortie Referentiel Success',
  props<{ utilities: any }>()
);

export const loadSortieReferentielFailure = createAction(
  '[Utilities/API] Load Sortie Referentiel Failure',
  props<{ error: any }>()
);

//MOBILITY
export const loadMobilityReferentiel = createAction(
  '[Utilities/COMPONENT] Load Mobility Referentiel'
);

export const loadMobilityReferentielSuccess = createAction(
  '[Utilities/API]Load Mobility Referentiel Success',
  props<{ utilities: any }>()
);

export const loadMobilityReferentielFailure = createAction(
  '[Utilities/API] Load Mobility Referentiel Failure',
  props<{ error: any }>()
);

//COUNTRY
export const loadCountryReferentiel = createAction(
  '[Utilities/COMPONENT] Load Country Referentiel'
);

export const loadCountryReferentielSuccess = createAction(
  '[Utilities/API]Load Country Referentiel Success',
  props<{ utilities: any }>()
);

export const loadCountryReferentielFailure = createAction(
  '[Utilities/API] Load Country Referentiel Failure',
  props<{ error: any }>()
);

//SEJOUR
export const loadSejourReferentiel = createAction(
  '[Utilities/COMPONENT] Load Sejour Referentiel'
);

export const loadSejourReferentielSuccess = createAction(
  '[Utilities/API]Load Sejour Referentiel Success',
  props<{ utilities: any }>()
);

export const loadSejourReferentielFailure = createAction(
  '[Utilities/API] Load Mobility Referentiel Failure',
  props<{ error: any }>()
);

//FRENCH LEVEL
export const loadFrenchLevelReferentiel = createAction(
  '[Utilities/COMPONENT] Load FrenchLevel Referentiel'
);

export const loadFrenchLevelReferentielSuccess = createAction(
  '[Utilities/API]Load FrenchLevel Referentiel Success',
  props<{ utilities: any }>()
);

export const loadFrenchLevelReferentielFailure = createAction(
  '[Utilities/API] Load FrenchLevel Referentiel Failure',
  props<{ error: any }>()
);

//DIPLOMA
export const loadDiplomaReferentiel = createAction(
  '[Utilities/COMPONENT] Load Diploma Referentiel'
);

export const loadDiplomaReferentielSuccess = createAction(
  '[Utilities/API]Load Diploma Referentiel Success',
  props<{ utilities: any }>()
);

export const loadDiplomaReferentielFailure = createAction(
  '[Utilities/API] Load Diploma Referentiel Failure',
  props<{ error: any }>()
);

//CATEGORIE
export const loadCategorieReferentiel = createAction(
  '[Utilities/COMPONENT] Load Categorie Referentiel'
);

export const loadCategorieReferentielSuccess = createAction(
  '[Utilities/API]Load Categorie Referentiel Success',
  props<{ utilities: any }>()
);

export const loadCategorieReferentielFailure = createAction(
  '[Utilities/API] Load Categorie Referentiel Failure',
  props<{ error: any }>()
);

//PERIOD INSCRIPTION
export const loadPeriodInscriptionReferentiel = createAction(
  '[Utilities/COMPONENT] Load PeriodInscription Referentiel'
);

export const loadPeriodInscriptionReferentielSuccess = createAction(
  '[Utilities/API]Load PeriodInscription Referentiel Success',
  props<{ utilities: any }>()
);

export const loadPeriodInscriptionReferentielFailure = createAction(
  '[Utilities/API] Load PeriodInscription Referentiel Failure',
  props<{ error: any }>()
);

//ALLOCATIONS
export const loadAllocationReferentiel = createAction(
  '[Utilities/COMPONENT] Load Allocations Referentiel'
);

export const loadAllocationReferentielSuccess = createAction(
  '[Utilities/API]Load Allocations Referentiel Success',
  props<{ utilities: any }>()
);

export const loadAllocationReferentielFailure = createAction(
  '[Utilities/API] Load Allocations Referentiel Failure',
  props<{ error: any }>()
);

//RESEAUX SOCIAUX
export const loadReseauxSociauxReferentiel = createAction(
  '[Utilities/COMPONENT] Load ReseauxSociaux Referentiel'
);

export const loadReseauxSociauxReferentielSuccess = createAction(
  '[Utilities/API]Load ReseauxSociaux Referentiel Success',
  props<{ utilities: any }>()
);

export const loadReseauxSociauxReferentielFailure = createAction(
  '[Utilities/API] Load ReseauxSociaux Referentiel Failure',
  props<{ error: any }>()
);

//COUVERTURE MALADIE
export const loadCouvertureMaladieReferentiel = createAction(
  '[Utilities/COMPONENT] Load CouvertureMaladie Referentiel'
);

export const loadCouvertureMaladieReferentielSuccess = createAction(
  '[Utilities/API]Load CouvertureMaladie Referentiel Success',
  props<{ utilities: any }>()
);

export const loadCouvertureMaladieReferentielFailure = createAction(
  '[Utilities/API] Load CouvertureMaladie Referentiel Failure',
  props<{ error: any }>()
);

//MINIMA
export const loadMinimaReferentiel = createAction(
  '[Utilities/COMPONENT] Load Minima Referentiel'
);

export const loadMinimaReferentielSuccess = createAction(
  '[Utilities/API]Load Minima Referentiel Success',
  props<{ utilities: any }>()
);

export const loadMinimaReferentielFailure = createAction(
  '[Utilities/API] Load Minima Referentiel Failure',
  props<{ error: any }>()
);

//SANTE
export const loadSanteReferentiel = createAction(
  '[Utilities/COMPONENT] Load Sante Referentiel'
);

export const loadSanteReferentielSuccess = createAction(
  '[Utilities/API]Load Sante Referentiel Success',
  props<{ utilities: any }>()
);

export const loadSanteReferentielFailure = createAction(
  '[Utilities/API] Load Sante Referentiel Failure',
  props<{ error: any }>()
);

//GENRE
export const loadGenreReferentiel = createAction(
  '[Utilities/COMPONENT] Load Genre Referentiel'
);

export const loadGenreReferentielSuccess = createAction(
  '[Utilities/API]Load Genre Referentiel Success',
  props<{ utilities: any }>()
);

export const loadGenreReferentielFailure = createAction(
  '[Utilities/API] Load Genre Referentiel Failure',
  props<{ error: any }>()
);

//STATUS
export const loadStatusReferentiel = createAction(
  '[Utilities/COMPONENT] Load Status Referentiel'
);

export const loadStatusReferentielSuccess = createAction(
  '[Utilities/API]Load Status Referentiel Success',
  props<{ utilities: any }>()
);

export const loadStatusReferentielFailure = createAction(
  '[Utilities/API] Load Status Referentiel Failure',
  props<{ error: any }>()
);

//TRANSPORT
export const loadTransportReferentiel = createAction(
  '[Utilities/COMPONENT] Load Transport Referentiel'
);

export const loadTransportReferentielSuccess = createAction(
  '[Utilities/API]Load Transport Referentiel Success',
  props<{ utilities: any }>()
);

export const loadTransportReferentielFailure = createAction(
  '[Utilities/API] Load Transport Referentiel Failure',
  props<{ error: any }>()
);

//GEOGRAPHIQUE MOBILITE
export const loadGeoMobiliteReferentiel = createAction(
  '[Utilities/COMPONENT] Load Geographique Mobilite Referentiel'
);

export const loadGeoMobiliteReferentielSuccess = createAction(
  '[Utilities/API]Load Geographique Mobilite Referentiel Success',
  props<{ utilities: any }>()
);

export const loadGeoMobiliteReferentielFailure = createAction(
  '[Utilities/API] Load Geographique Mobilite Referentiel Failure',
  props<{ error: any }>()
);

//JUDICIAIRE
export const loadJudiciaireReferentiel = createAction(
  '[Utilities/COMPONENT] Load Judiciaire Referentiel'
);

export const loadJudiciaireReferentielSuccess = createAction(
  '[Utilities/API]Load Judiciaire Referentiel Success',
  props<{ utilities: any }>()
);

export const loadJudiciaireReferentielFailure = createAction(
  '[Utilities/API] Load Judiciaire Referentiel Failure',
  props<{ error: any }>()
);

//MATRIMONIAL
export const loadMatrimonialReferentiel = createAction(
  '[Utilities/COMPONENT] Load Matrimonial Referentiel'
);

export const loadMatrimonialReferentielSuccess = createAction(
  '[Utilities/API]Load Matrimonial Referentiel Success',
  props<{ utilities: any }>()
);

export const loadMatrimonialReferentielFailure = createAction(
  '[Utilities/API] Load Matrimonial Referentiel Failure',
  props<{ error: any }>()
);

//HANDICAP
export const loadHandicapReferentiel = createAction(
  '[Utilities/COMPONENT] Load Handicap Referentiel'
);

export const loadHandicapReferentielSuccess = createAction(
  '[Utilities/API]Load Handicap Referentiel Success',
  props<{ utilities: any }>()
);

export const loadHandicapReferentielFailure = createAction(
  '[Utilities/API] Load Handicap Referentiel Failure',
  props<{ error: any }>()
);

//LEVEL NUMERIQUE
export const loadLevelBureautiqueReferentiel = createAction(
  '[Utilities/COMPONENT] Load Level Bureautique Referentiel'
);

export const loadLevelBureautiqueReferentielSuccess = createAction(
  '[Utilities/API]Load Level Bureautique Referentiel Success',
  props<{ utilities: any }>()
);

export const loadLevelBureautiqueReferentielFailure = createAction(
  '[Utilities/API] Load Level Bureautique Referentiel Failure',
  props<{ error: any }>()
);

//PERMIS
export const loadPermisReferentiel = createAction(
  '[Utilities/COMPONENT] Load Permis Referentiel'
);

export const loadPermisReferentielSuccess = createAction(
  '[Utilities/API]Load Permis Referentiel Success',
  props<{ utilities: any }>()
);

export const loadPermisReferentielFailure = createAction(
  '[Utilities/API] Load Permis Referentiel Failure',
  props<{ error: any }>()
);

//SOFTSKILLS
export const loadSoftskillsReferentiel = createAction(
  '[Utilities/COMPONENT] Load Softskills Referentiel'
);

export const loadSoftskillsReferentielSuccess = createAction(
  '[Utilities/API]Load Softskills Referentiel Success',
  props<{ utilities: any }>()
);

export const loadSoftskillsReferentielFailure = createAction(
  '[Utilities/API] Load Softskills Referentiel Failure',
  props<{ error: any }>()
);

//UTILITIES
export const loadUtilities = createAction(
  '[Utilities/COMPONENT] Load Utilities'
);

export const loadUtilitiesSuccess = createAction(
  '[Utilities/API] Load Utilities Success',
  props<{ utilities: any }>()
);

export const loadUtilitiesFailure = createAction(
  '[Utilities/API] Load Utilities Failure',
  props<{ error: any }>()
);

export const updateUtility = createAction(
  '[Utilities/COMPONENT] Update Utility',
  props<{ utility: Update<any> }>()
);

export const updateUtilitySuccess = createAction(
  '[Utilities/API] Update Utility Success',
  props<{ utility: any }>()
);
export const updateUtilityFailure = createAction(
  '[Utilities/API] Update Utility Failure',
  props<{ error: any }>()
);

export const deleteUtility = createAction(
  '[Utilities/COMPONENT] Delete Utility',
  props<{ id: string; item: any }>()
);
export const deleteUtilitySuccess = createAction(
  '[Utilities/API] Delete Utility Success',
  props<{ utility: any }>()
);
export const deleteUtilityFailure = createAction(
  '[Utilities/API] Delete Utility Failure',
  props<{ error: any }>()
);

//PARTICIPANTS
export const loadParticipantUtilities = createAction(
  '[Utilities/COMPONENT] Load Participant Utilities'
);

export const loadParticipantUtilitiesSuccess = createAction(
  '[Utilities/API] Load Participant Utilities Success',
  props<{ utilities: any }>()
);

export const loadParticipantUtilitiesFailure = createAction(
  '[Utilities/API] Load Participant Utilities Failure',
  props<{ error: any }>()
);

//IDENTITY
export const loadParticipantUtilitiesIdentity = createAction(
  '[Utilities/COMPONENT] Load Participant Utilities Identity'
);

export const loadParticipantUtilitiesIdentitySuccess = createAction(
  '[Utilities/API] Load Participant Utilities Identity Success',
  props<{ utilities: any }>()
);

export const loadParticipantUtilitiesIdentityFailure = createAction(
  '[Utilities/API] Load Participant Utilities Identity Failure',
  props<{ error: any }>()
);

//SITUATION
export const loadParticipantUtilitiesSituation = createAction(
  '[Utilities/COMPONENT] Load Participant Utilities Situation'
);

export const loadParticipantUtilitiesSituationSuccess = createAction(
  '[Utilities/API] Load Participant Utilities Situation Success',
  props<{ utilities: any }>()
);

export const loadParticipantUtilitiesSituationFailure = createAction(
  '[Utilities/API] Load Participant Utilities Situation Failure',
  props<{ error: any }>()
);
//ENTITES
export const loadEntiteUtilities = createAction(
  '[Utilities/COMPONENT] Load Entite Utilities'
);

export const loadEntiteUtilitiesSuccess = createAction(
  '[Utilities/API] Load Entite Utilities Success',
  props<{ utilities: any }>()
);

export const loadEntiteUtilitiesFailure = createAction(
  '[Utilities/API] Load Entite Utilities Failure',
  props<{ error: any }>()
);

//CONTACTS
export const loadContactUtilities = createAction(
  '[Utilities/COMPONENT] Load Contact Utilities'
);

export const loadContactUtilitiesSuccess = createAction(
  '[Utilities/API] Load Contact Utilities Success',
  props<{ utilities: any }>()
);

export const loadContactUtilitiesFailure = createAction(
  '[Utilities/API] Load Contact Utilities Failure',
  props<{ error: any }>()
);
//OPERATIONS
export const loadOperationUtilities = createAction(
  '[Utilities/COMPONENT] Load Operation Utilities'
);

export const loadOperationUtilitiesSuccess = createAction(
  '[Utilities/API] Load Operation Utilities Success',
  props<{ utilities: any }>()
);

export const loadOperationUtilitiesFailure = createAction(
  '[Utilities/API] Load Operation Utilities Failure',
  props<{ error: any }>()
);

//MISSION
//MISSIONS CONTRACTS
export const loadMissionUtilities = createAction(
  '[Utilities/COMPONENT] Load Mission Utilities'
);

export const loadMissionUtilitiesSuccess = createAction(
  '[Utilities/API] Load Mission Utilities Success',
  props<{ utilities: any }>()
);

export const loadMissionUtilitiesFailure = createAction(
  '[Utilities/API] Load Mission Utilities Failure',
  props<{ error: any }>()
);

//MISSIONS DEPLACEMENTS
export const loadMissionDeplacementUtilities = createAction(
  '[Utilities/COMPONENT] Load Mission Deplacement Utilities'
);

export const loadMissionDeplacementUtilitiesSuccess = createAction(
  '[Utilities/API] Load Mission Deplacement Utilities Success',
  props<{ utilities: any }>()
);

export const loadMissionDeplacementUtilitiesFailure = createAction(
  '[Utilities/API] Load Mission Deplacement Utilities Failure',
  props<{ error: any }>()
);

//OPERATION MARCHÉS TYPE
export const loadOperationMarcheTypes = createAction(
  '[Utilities/COMPONENT] Load Operation Marches type'
);

export const loadOperationMarcheTypesSuccess = createAction(
  '[Utilities/API] Load Operation Marches type Success',
  props<{ types: string[] }>()
);

export const loadOperationMarcheTypesFailure = createAction(
  '[Utilities/API] Load Operation Marches type Failure',
  props<{ error: any }>()
);

//OPERATION OBJECTIFS TYPE
export const loadOperationObjectifTypes = createAction(
  '[Utilities/COMPONENT] Load Operation Objectif type'
);

export const loadOperationObjectifTypesSuccess = createAction(
  '[Utilities/API] Load Operation Objectif type Success',
  props<{ objectifs: string[] }>()
);

export const loadOperationObjectifTypesFailure = createAction(
  '[Utilities/API] Load Operation Objectif type Failure',
  props<{ error: any }>()
);

//PLANNING
export const loadPlanningfTypes = createAction(
  '[Utilities/COMPONENT] Load Planning type'
);

export const loadPlanningfTypesSuccess = createAction(
  '[Utilities/API] Load Planning type Success',
  props<{ types: any[] }>()
);

export const loadPlanningfTypesFailure = createAction(
  '[Utilities/API] Load Planning type Failure',
  props<{ error: any }>()
);

export const clearUtilitiess = createAction('[Utilities/API] Clear Utilitiess');
