<app-operation-dashboard-header
  [dashboard]="dashboard$ | async"
  [operation]="operation$ | async"
  (select)="onSelect($event)"
></app-operation-dashboard-header>

<nz-space></nz-space>

<div [ngSwitch]="view$ | async">
  <!-- OPERATION -->
  <div *ngSwitchCase="'operation'" @fade>
    <app-item-tabset
      [tabs]="[
        {
          name: 'Opération',
          template: operationTemplate
        },
        {
          name: 'Par modules',
          template: operationModulesTemplate
        },
        {
          name: 'Par actions',
          template: operationActionsTemplate
        }
      ]"
    ></app-item-tabset>

    <ng-template #operationTemplate>
      <app-operation-dashboard-main
        [dashboard$]="dashboard$"
      ></app-operation-dashboard-main>
    </ng-template>
    <ng-template #operationModulesTemplate>
      <app-operation-dashboard-modules
        [dashboard$]="dashboard$"
      ></app-operation-dashboard-modules>
    </ng-template>
    <ng-template #operationActionsTemplate>
      <app-operation-dashboard-actions
        [dashboard$]="dashboard$"
      ></app-operation-dashboard-actions>
    </ng-template>
  </div>

  <!-- MISSIONS -->
  <div *ngSwitchCase="'missions'" @fade>
    <app-operation-dashboard-missions
      [dashboard$]="dashboard$"
    ></app-operation-dashboard-missions>
  </div>

  <!-- PARTICIPANTS -->
  <div *ngSwitchCase="'participants'" @fade>
    <app-operation-dashboard-participants
      [dashboard$]="dashboard$"
    ></app-operation-dashboard-participants>
  </div>

  <!-- ENTITES -->
  <div *ngSwitchCase="'entites'" @fade>
    <app-operation-dashboard-entites
      [dashboard$]="dashboard$"
    ></app-operation-dashboard-entites>
  </div>

  <!-- INTERVENANTS -->
  <div *ngSwitchCase="'interventions'" @fade>
    <app-operation-dashboard-intervenants
      [dashboard$]="dashboard$"
    ></app-operation-dashboard-intervenants>
  </div>

  <div *ngSwitchDefault>
    <app-no-result
      [icon]="'query_stats'"
      [isButton]="false"
      [title]="''"
    ></app-no-result>
  </div>
</div>

<ng-template #noData>
  <app-no-result
    [icon]="'query_stats'"
    [isButton]="false"
    [title]="''"
  ></app-no-result>
</ng-template>
