<ng-container *ngIf="entite">
  <nz-page-header
    class="site-page-header"
    (nzBack)="onBack()"
    nzBackIcon
    [nzTitle]="entite?.entite?.etablissement?.denomination"
    nzSubtitle="Entreprise"
  >
    <nz-page-header-content>
      {{ entite?.intervention?.role }}
      <!-- <nz-descriptions nzSize="small" [nzColumn]="3">
    <nz-descriptions-item nzTitle="Démarrage" [nzSpan]="1">{{
      module?.calendrier?.dateStart | dateFr: "time"
    }}</nz-descriptions-item>
    <nz-descriptions-item nzTitle="Échéance" [nzSpan]="1">{{
      module?.calendrier?.dateEnd | dateFr: "time"
    }}</nz-descriptions-item>
    <nz-descriptions-item
      nzTitle="Creation Time"
      [nzSpan]="1"
    ></nz-descriptions-item>
    <nz-descriptions-item
      nzTitle="Effective Time"
      [nzSpan]="1"
    ></nz-descriptions-item>
    <nz-descriptions-item nzTitle="Remarks" [nzSpan]="2">
    </nz-descriptions-item>
  </nz-descriptions> -->
    </nz-page-header-content>
    <nz-page-header-extra>
      <app-button-dropdown
        [isUpdate]="true"
        [isExportPDF]="true"
        [isExportEXL]="true"
        [isExportPPT]="true"
        [isDelete]="true"
      ></app-button-dropdown>
    </nz-page-header-extra>
  </nz-page-header>

  <app-item-tabset
    [tabs]="[
      {
        name: 'Intervention',
        index: 0,
        template: operation_entite_item_intervention
      },
      {
        name: 'Contacts',
        index: 1,
        template: operation_entite_item_contacts
      },
      {
        name: 'Marchés',
        index: 2,
        template: operation_entite_item_modules
      },
      {
        name: 'Actions',
        index: 3,
        template: operation_entite_item_actions
      },
      {
        name: 'Participants',
        index: 4,
        template: operation_entite_item_participants
      },
      {
        name: 'Missions',
        index: 5,
        template: operation_entite_item_missions
      },
      {
        name: 'Suivis',
        index: 6,
        template: operation_entite_item_suivis
      },
      {
        name: 'Documents',
        index: 7,
        template: operation_entite_item_documents
      }
    ]"
  ></app-item-tabset>

  <ng-template #entiteTitle>
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="6">
        <nz-card class="cardTitle"> </nz-card>
      </nz-col>
      <nz-col nzSpan="2"></nz-col>
      <nz-col nzSpan="12">
        <h4 nz-typography>
          {{ entite?.denomination }}
        </h4>
      </nz-col>
      <nz-col nzSpan="4"></nz-col>
    </nz-row>
  </ng-template>

  <ng-template #operation_entite_item_intervention>
    <h1>INTERVENTION</h1>
  </ng-template>

  <ng-template #operation_entite_item_contacts>
    <app-table-contacts></app-table-contacts>
  </ng-template>

  <ng-template #operation_entite_item_participants>
    <app-table-participants></app-table-participants>
  </ng-template>

  <ng-template #operation_entite_item_modules>
    <app-table-modules></app-table-modules>
  </ng-template>
  <ng-template #operation_entite_item_actions>
    <app-table-actions></app-table-actions>
  </ng-template>

  <ng-template #operation_entite_item_missions>
    <app-table-missions></app-table-missions>
  </ng-template>

  <ng-template #operation_entite_item_suivis>
    <app-table-suivis></app-table-suivis>
  </ng-template>

  <ng-template #operation_entite_item_documents>
    <app-table-documents></app-table-documents>
  </ng-template>
</ng-container>
