<ng-container *ngIf="user$ | async as user">
  <ng-container *ngIf="departement$ | async as departement">
    <app-layout-list
      *ngIf="departement.users | size; else noData"
      [new]="new"
      [card]="card"
      [source]="(departement$ | async)?.users"
    ></app-layout-list>

    <ng-template #new>
      <app-card-new
        [type]="'person'"
        [height]="'300px'"
        [title]="newCardTitle"
        [user]="true"
        (add)="onNewUser(departement, user)"
      ></app-card-new>
    </ng-template>

    <ng-template #card let-item let-position="idx">
      <app-user-card [user]="item" (select)="onSelect($event)"></app-user-card>
    </ng-template>

    <ng-template #userFormTitle>
      <app-header-title
        [icon]="'person'"
        [title]="titleUser"
      ></app-header-title>
    </ng-template>
    <ng-template #noData>
      <app-no-result
        *ngIf="(user | isDev) || (user | isAdmin) || (user | isManager)"
        [icon]="'person'"
        [addtitle]="'Ajouté un nouveau membre au service'"
        [notitle]="'Aucun membre ajouté au service'"
        (new)="onNewUser(departement, user)"
      ></app-no-result>
      <app-no-result
        *ngIf="user | isMember"
        [icon]="'person'"
        [isButton]="false"
        [notitle]="'Aucun membre ajouté au service'"
      ></app-no-result>
    </ng-template>
  </ng-container>
</ng-container>
