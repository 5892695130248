<nz-card [nzTitle]="diagnosticCardTitle">
  <form nz-form [formGroup]="definitionForm">
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="12">
        <nz-form-item>
          <nz-form-label nzSpan="24" nzRequired
            >Date du diagnostic</nz-form-label
          >
          <nz-form-control>
            <nz-date-picker
              formControlName="dateStart"
              nzBorderless
              nzPlaceHolder="Date"
              [nzFormat]="dateFormat"
              [nzShowToday]="true"
              [nzShowTime]="{ nzFormat: timeFormat }"
            ></nz-date-picker>
          </nz-form-control>
        </nz-form-item>
      </nz-col>
      <nz-col nzSpan="12">
        <nz-form-item>
          <nz-form-label nzSpan="24" nzRequired
            >Besoins d'accompagnement</nz-form-label
          >
          <nz-form-control>
            <nz-select
              nzPlaceHolder="Besoins d'accompagnement"
              nzBorderless
              formControlName="besoins"
              nzMode="tags"
              [nzMaxTagCount]="2"
              nzServerSearch
              nzShowSearch
              [nzShowArrow]="false"
              [nzFilterOption]="nzFilterOption"
              [compareWith]="compareBesoins"
              (nzOnSearch)="search($event)"
            >
              <nz-option
                *ngFor="let item of besoins$ | async"
                [nzLabel]="item.libelle"
                [nzValue]="item"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </nz-col>
    </nz-row>
    <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>

    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="24">
        <nz-form-item>
          <nz-form-label nzSpan="24">Présentation</nz-form-label>
          <nz-form-control>
            <textarea
              nz-input
              formControlName="presentation"
              nzBorderless
              placeholder="Présentation, Observation..."
              [nzAutosize]="{ minRows: 4, maxRows: 5 }"
            ></textarea>
          </nz-form-control>
        </nz-form-item>
      </nz-col>
    </nz-row>
  </form>
</nz-card>
<ng-template #diagnosticCardTitle>
  <app-header-title [icon]="'edit_note'"></app-header-title>
</ng-template>
