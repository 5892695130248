<nz-select
  [(ngModel)]="userSelected"
  (ngModelChange)="onSelectChange($event)"
  [nzSize]="size"
  [nzPlaceHolder]="placeholder"
  [nzOptionHeightPx]="80"
  [nzNotFoundContent]="noTeam"
  [nzDisabled]="loading$ | async"
  [nzLoading]="loading$ | async"
  [nzClearIcon]="clearIconTpl"
  nzAllowClear
  [nzMode]="mode"
  [nzMaxTagCount]="8"
  [nzBorderless]="borderless"
  class="bg-transparent w-full border-none"
>
  <nz-option
    *ngFor="let user of users$ | async; trackBy: trackBy"
    nzCustomContent
    [nzLabel]="user.displayName"
    [nzValue]="user"
  >
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="4"
        ><nz-avatar
          [nzText]="!user?.phone ? user?.avatar : ''"
          [nzSrc]="user?.phone ? user?.phone : ''"
          [nzSize]="40"
          [ngStyle]="{
            'background-color': user?.setting?.color
              ? user?.setting?.color
              : default,
            color: avatarColor
          }"
        ></nz-avatar
      ></nz-col>
      <nz-col nzSpan="20">
        <nz-row nzAlign="middle" nzGutter="8" nzJustify="start"
          ><h5 nz-typography>{{ user?.displayName }}</h5></nz-row
        >
        <nz-row nzAlign="middle" nzGutter="8" nzJustify="start"
          ><small nz-typography>{{ user?.fonction }}</small></nz-row
        >
      </nz-col>
    </nz-row>
  </nz-option>
</nz-select>

<ng-template #noTeam>
  <app-no-result
    [icon]="'person'"
    [isButton]="false"
    [description]="'Aucun utilisateur'"
  ></app-no-result>
</ng-template>
<ng-template #loading>
  <app-loading></app-loading>
</ng-template>

<ng-template #clearIconTpl>
  <mat-icon>cancel</mat-icon>
</ng-template>

<ng-template #suffixIconTpl>
  <button nz-button nzType="primary" (click)="$event.stopPropagation()">
    <mat-icon
      nz-tooltip
      [nzTooltipMouseEnterDelay]="0.5"
      nzTooltipPlacement="bottom"
      nzTooltipTitle="Ajouter"
      >person_add_alt</mat-icon
    >
  </button>
</ng-template>
