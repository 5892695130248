import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';

import { ContactId } from './../../store/contact.model';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Store, select } from '@ngrx/store';

@Component({
  selector: 'app-contact-actions',
  templateUrl: './contact-actions.component.html',
  styleUrls: ['./contact-actions.component.scss'],
})
export class ContactActionsComponent implements OnInit {
  user$: Observable<any> = of(null);
  @Input() contact: ContactId | any = null;
  @Output() select = new EventEmitter<string>(false);

  constructor(private userStore: Store<UserState>) {}

  ngOnInit(): void {
    this.getUser();
  }

  getUser(): void {
    this.user$ = this.userStore.select(fromUserSelector.user);
  }

  onSelect(event: string): void {
    this.select.emit(event);
  }
}
