<form nz-form [formGroup]="roleForm">
  <nz-form-item>
    <nz-form-label nzRequired>Rôle</nz-form-label>
    <nz-form-control>
      <input
        nz-input
        nzBorderless
        formControlName="role"
        placeholder="Rôle..."
      />
    </nz-form-control>
  </nz-form-item>
  <nz-divider
    nzOrientation="center"
    nzType="horizontal"
    nzText="Type d'intervention"
  ></nz-divider>

  <nz-form-item>
    <nz-form-control class="radioContainer">
      <nz-radio-group
        [(ngModel)]="radioValue"
        (ngModelChange)="onSetRole($event)"
        nzButtonStyle="solid"
        [ngModelOptions]="{ standalone: true }"
      >
        <label [nzValue]="'financeur'" nz-radio>Financeur</label>
        <label [nzValue]="'assistance'" nz-radio>Assistance du projet</label>
        <label [nzValue]="'pilotage'" nz-radio>Pilotage du projet</label>
        <label [nzValue]="'titulaire'" nz-radio>Titulaire d'un marché</label>
        <label [nzValue]="'soustraitant'" nz-radio
          >Sous-traitant d'un marché</label
        >
        <label [nzValue]="'support'" nz-radio>Support du projet</label>
        <label [nzValue]="'prescripteur'" nz-radio
          >Prescripteur du projet</label
        >
        <label [nzValue]="'parrainage'" nz-radio>Parrainage</label>
        <label [nzValue]="'control'" nz-radio>Contrôle du projet</label>
        <label [nzValue]="'autre'" nz-radio>Autre</label>
      </nz-radio-group>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item *ngIf="radioValue === 'autre'">
    <nz-form-label nzRequired>Précision</nz-form-label>
    <nz-form-control>
      <input
        nz-input
        nzBorderless
        formControlName="autrePrecision"
        placeholder="Précisez le type d'intervention"
      />
    </nz-form-control>
  </nz-form-item>

  <ng-container *ngIf="radioValue === 'soustraitant'">
    <nz-divider
      nzOrientation="center"
      nzType="horizontal"
      nzText="Sous-traitance"
    ></nz-divider>
  </ng-container>

  <nz-divider
    nzOrientation="center"
    nzType="horizontal"
    nzText="Date d'intervention"
  ></nz-divider>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="12">
      <nz-form-item>
        <nz-form-label nzRequired>Démarrage</nz-form-label>
        <nz-form-control>
          <nz-date-picker
            formControlName="dateStartIntervention"
            nzBorderless
            nzFormat="dd/MM/yyyy"
            nzPlaceHolder="Date de démmarage..."
          ></nz-date-picker>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col nzSpan="12">
      <nz-form-item>
        <nz-form-label nzRequired>Échéance</nz-form-label>
        <nz-form-control>
          <nz-date-picker
            formControlName="dateEndIntervention"
            nzBorderless
            [nzDisabledDate]="disabledDate"
            nzFormat="dd/MM/yyyy"
            nzPlaceHolder="Date d'échéance..."
          ></nz-date-picker>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>
  <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
</form>

<nz-row nzAlign="middle" nzGutter="8" nzJustify="end">
  <nz-col nzSpan="12"> </nz-col>
  <nz-col nzSpan="6">
    <button nz-button nzBlock nzType="text" (click)="onCancel()">
      <h5 nz-typography>Annuler</h5>
    </button>
  </nz-col>
  <nz-col nzSpan="6">
    <button
      nz-button
      nzBlock
      nzType="primary"
      (click)="onValidate()"
      [disabled]="!roleForm.valid"
    >
      Valider
    </button>
  </nz-col>
</nz-row>
