import {
  NzNotificationPlacement,
  NzNotificationService,
} from 'ng-zorro-antd/notification';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
} from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DocumentComponent } from '../document/document.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  ViewContainerRef,
  ViewChild,
  TemplateRef,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { DocumentId } from 'src/app/models/document.model';
import { last, first, uniqWith, isEqual, sortBy } from 'lodash';
import { fr } from 'date-fns/locale';
import { format, fromUnixTime } from 'date-fns';
import {
  NzTableFilterFn,
  NzTableFilterList,
  NzTableSortFn,
  NzTableSortOrder,
} from 'ng-zorro-antd/table';
import { PdfViewerComponent } from '../../../../contents/components/pdf-viewer/pdf-viewer.component';
import { enterAnimation, listAnimation } from 'src/app/app-animation';
import { SelectType } from 'src/app/models/select.display.model';
import { NzDrawerService } from 'ng-zorro-antd/drawer';

interface ColumnItem {
  name: string;
  sortOrder: NzTableSortOrder | null;
  sortFn: NzTableSortFn<DocumentId> | null;
  listOfFilter: NzTableFilterList;
  filterFn: NzTableFilterFn<DocumentId> | null;
  filterMultiple: boolean;
  sortDirections: NzTableSortOrder[] | any;
  width?: string | any;
}

@Component({
  selector: 'app-table-documents',
  templateUrl: './table-documents.component.html',
  styleUrls: ['./table-documents.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [enterAnimation, listAnimation],
})
export class TableDocumentsComponent implements OnInit, OnChanges, OnDestroy {
  subscribe = new Subject();
  newCardDefinition: string = '';
  newDocument = false;
  newEvent = false;
  modalWidth: number = 950;
  drawserChildWidth: number = 700;

  listOfColumns: ColumnItem[] = [];
  searchForm: UntypedFormGroup = this.fb.group({});
  searchInput: UntypedFormControl = new UntypedFormControl();

  NOTIFICATION_PLACEMENT: NzNotificationPlacement = 'bottomRight';

  @Input() scroll: any = { x: '1100px', y: '300px' };
  @Input() newCardTitle: string = 'Nouveau document';
  @Input() newCardDescription: string = 'Ajouter un document';
  @Input() titlePDFView: string = '';
  @Input() messageAlert: string = 'Voulez-vous supprimer ce suivi ?';
  @Input() isSearch: boolean = false;
  @Input() isCanAdd: boolean = true;
  @Input() currentDocument$ = new BehaviorSubject<any>(null);
  @Input() displayView$ = new BehaviorSubject<string>('table');
  @Input() selectTyped: SelectType = SelectType.MODAL;
  @Input() sizeItem: string = '180px';

  @Input() source$: Observable<DocumentId[] | any> = of([]);
  @Output() search = new EventEmitter<any>(false);
  @Output() add = new EventEmitter<any>(false);
  @Output() delete = new EventEmitter<any>(false);
  @Output() deleteAll = new EventEmitter<any>(false);
  @ViewChild('documentTitleForm', { static: false }) documentTitleForm:
    | TemplateRef<any>
    | any;
  @ViewChild('documentTitlePDFView', { static: false }) documentTitlePDFView:
    | TemplateRef<any>
    | any;

  //FILTERS
  filterByTypes: any = [];
  filterByTypesFn = (list: string[], item: any) =>
    list.some((type) => item.type === type);

  //SORTS

  sortFnByDates = (a: any, b: any) =>
    a.dateStart['seconds'] - b.dateStart['seconds'];

  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private notification: NzNotificationService,
    private fb: UntypedFormBuilder,
    private drawerService: NzDrawerService
  ) {}

  ngOnInit(): void {
    this.onSetFilter();
    this.form();
  }
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  form(): void {
    this.forInput();
    this.formCreate();
    this.formChanges();
  }
  forInput(): void {
    this.searchInput = new UntypedFormControl();
  }
  formCreate(): void {
    this.searchForm = this.fb.group({
      input: this.searchInput,
    });
  }
  formChanges(): void {
    this.searchForm.valueChanges.subscribe((values) => {
      if (!values) return;
      const { input } = values;

      if (input?.length > 0) return;
      this.search.emit(input);
    });
  }

  onSetFilter(): void {
    this.source$?.pipe(takeUntil(this.subscribe)).subscribe((source) => {
      if (!source?.length) return;
      const types = source.map((el: any) => {
        const item = el?.type;
        return {
          text: item,
          value: item,
        };
      });
      this.filterByTypes = types;
    });
  }

  onNewDocument(): void {
    const modal = this.modal.create({
      nzContent: DocumentComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.documentTitleForm,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
    });
    const instance = modal.getContentComponent();
    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });

    instance.addDocument.subscribe((event): any => {
      this.onAddDocument(event);
      modal.close();
    });
  }

  onSelectDropdown(event: string, item: any): void {
    if (!event || !item) return;

    switch (event) {
      case 'delete':
        this.delete.emit(item);

        break;

      default:
        break;
    }
  }

  onSelect(document: DocumentId, selectType: SelectType): void {
    if (!document) return;
    const { extension, title } = document;
    if (this.isPdf(extension)) {
      this.titlePDFView = title;
      this.onOpenPdfViewer(document, selectType);
      return;
    }

    this.onDowloadFile(document);
    return;
  }

  isPdf(extension: string): boolean {
    if (!extension) return false;

    if (extension === 'application/pdf' || extension === 'pdf') return true;

    return false;
  }

  onOpenPdfViewer(document: DocumentId, selectType: SelectType): void {
    this.newCardTitle = `${document?.title}`;

    if (selectType === SelectType.SIDE) {
      this.onSelectTypeSide(document);
    }

    if (selectType === SelectType.MODAL) {
      this.onSelectTypeModal(document);
    }

    if (selectType === SelectType.OVERLAY) {
      this.onselectTypeOverlay(document);
    }
  }

  onSelectTypeModal(document: any): void {
    const { fileRef } = document;
    const modal = this.modal.create({
      nzContent: PdfViewerComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.documentTitlePDFView,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
    });
    const instance = modal.getContentComponent();
    instance.pdfSrc = fileRef;
    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });

    instance.download.subscribe((event): any => {
      if (!event) return;
      this.onDowloadFile(document);
      modal.close();
    });
  }

  onSelectTypeSide(document: DocumentId): void {
    const { fileRef } = document;

    const drawser = this.drawerService.create({
      nzContent: PdfViewerComponent,
      nzWidth: this.drawserChildWidth,
      nzTitle: this.documentTitlePDFView,
      nzContentParams: {
        pdfSrc: fileRef,
      },

      nzClosable: true,
    });

    const instance = drawser.getContentComponent();

    instance?.cancel.subscribe((event): any => drawser.close());

    instance?.download.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        this.onDowloadFile(document);
        drawser.close();
      }
    });
  }

  onselectTypeOverlay(document: any): void {
    this.currentDocument$.next(document);
    this.displayView$.next('Detail');
  }

  onDowloadFile(item: DocumentId | any): void {
    let filename = item.title
      .substring(item.title.lastIndexOf('/') + 1)
      .split('?')[0];
    let xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = function () {
      var a = document.createElement('a');
      a.href = window.URL.createObjectURL(xhr.response);
      a.download = filename;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
    };
    xhr.open('GET', item.fileRef);
    xhr.send();

    const success: string = `Votre document a été téléchargé`;
    this.notification.success('Téléchargement', success, {
      nzPlacement: this.NOTIFICATION_PLACEMENT,
      nzDuration: 6000,
      nzAnimate: true,
      nzKey: 'DOWNLOAD_SELECT_FILE',
    });
  }

  onAddDocument(documents: any): void {
    this.add.emit(documents);
    this.newDocument = false;
  }

  onNewDocumentCancel(event: any): void {
    this.newDocument = false;
  }

  onCancel() {
    this.currentDocument$.next(null);
    this.displayView$.next('table');
    return;
  }

  onDelete(document: DocumentId): void {
    this.delete.emit(document);
  }
  onDeleteAll(event: string): void {
    if (event !== 'deleteAll') return;

    this.deleteAll.emit(true);
  }
}
