import { Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  doc,
  docSnapshots,
  getDoc,
  collectionChanges,
  addDoc,
  setDoc,
  deleteDoc,
  serverTimestamp,
  Timestamp,
  query,
  where,
  arrayUnion,
  arrayRemove,
  collectionSnapshots,
  getDocs,
  DocumentData,
  DocumentSnapshot,
} from '@angular/fire/firestore';
import { Observable, of, from, EMPTY } from 'rxjs';
import { switchMap, mergeMap, map, catchError } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Apollo, gql } from 'apollo-angular';
import { QueryOperationTeamServiceGQL } from '../graphql/queries/query-operation-team.service';

@Injectable({
  providedIn: 'any',
})
export class OperationTeamService {
  GET_OPERATION_TEAM = gql`
    query GetOperationTeam($id: ID!) {
      getOperationTeam(id: $id) {
        canRead
        canWrite
        isAdmin
        role
        user {
          id
          avatar
          displayName
          fonction
          photo
          service {
            id
            title
          }
          setting {
            color
          }
          coordonnees {
            email
            phoneNumber
          }
        }
      }
    }
  `;

  CREATE_OPERATION_USER = gql`
    mutation CreateOperationTeam($id: ID!, $users: [OperationTeamUserInput]!) {
      createOperationTeam(input: { id: $id, users: $users })
    }
  `;
  DELETE_OPERATION_USER = gql`
    mutation DeleteOperationTeam($id: ID!, $teamId: String!) {
      deleteOperationTeam(id: $id, teamId: $teamId)
    }
  `;
  DELETE_OPERATION_ALL_USERS = gql`
    mutation DeleteOperationAllTeam($id: ID!) {
      deleteOperationAllTeam(id: $id)
    }
  `;

  constructor(
    private db: Firestore,
    private apollo: Apollo,
    private queryOperationTeamServiceGQL: QueryOperationTeamServiceGQL
  ) {}

  getAll(getOperationTeamId: string): Observable<any> {
    try {
      if (!getOperationTeamId) return EMPTY;
      return this.queryOperationTeamServiceGQL
        .watch({ getOperationTeamId })
        .valueChanges.pipe(
          map(({ data, loading, error }) => {
            return { data, loading, error };
          })
        );
    } catch (error) {
      return of(error);
    }
  }

  getOne(operationId: string, id: string): Observable<any> {
    try {
      return EMPTY;
    } catch (error) {
      return of(error);
    }
  }

  createOperatiosUsers(id: string, users: any[]): Observable<any> {
    try {
      if (!id || !users || !users.length) return EMPTY;
      return this.apollo.mutate({
        mutation: this.CREATE_OPERATION_USER,
        variables: {
          id: id,
          users: users,
        },
      });
    } catch (error) {
      return of(error);
    }
  }

  updateOne(
    id: string | number,
    member: Partial<any>,
    operationId: string
  ): Observable<any> {
    try {
      return EMPTY;
    } catch (error) {
      return of(error);
    }
  }

  deleteOne(operationId: string, id: string): Observable<any> {
    try {
      if (!operationId || !id) return EMPTY;
      return this.apollo.mutate({
        mutation: this.DELETE_OPERATION_USER,
        variables: {
          id: operationId,
          teamId: id,
        },
      });
    } catch (error) {
      return of(error);
    }
  }

  deleteAll(id: string): Observable<any> {
    try {
      if (!id) return EMPTY;
      return this.apollo.mutate({
        mutation: this.DELETE_OPERATION_ALL_USERS,
        variables: {
          id: id,
        },
      });
    } catch (error) {
      return of(error);
    }
  }
}
