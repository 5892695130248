<ng-container *ngIf="participant$ | async as participant">
  <nz-page-header
    class="site-page-header"
    (nzBack)="onBack()"
    nzBackIcon
    [nzTitle]="participantTitle"
  >
    <nz-page-header-content>
      <!-- <nz-descriptions nzSize="small" [nzColumn]="3">
    <nz-descriptions-item nzTitle="Démarrage" [nzSpan]="1">{{
      module?.calendrier?.dateStart | dateFr: "time"
    }}</nz-descriptions-item>
    <nz-descriptions-item nzTitle="Échéance" [nzSpan]="1">{{
      module?.calendrier?.dateEnd | dateFr: "time"
    }}</nz-descriptions-item>
    <nz-descriptions-item
      nzTitle="Creation Time"
      [nzSpan]="1"
    ></nz-descriptions-item>
    <nz-descriptions-item
      nzTitle="Effective Time"
      [nzSpan]="1"
    ></nz-descriptions-item>
    <nz-descriptions-item nzTitle="Remarks" [nzSpan]="2">
    </nz-descriptions-item>
  </nz-descriptions> -->
    </nz-page-header-content>
  </nz-page-header>

  <app-item-tabset
    [tabs]="[
      {
        name: 'Profil',
        index: 0,
        template: ''
      },
      {
        name: 'Suivis',
        index: 1,
        template: ''
      },
      {
        name: 'Missions',
        index: 2,
        template: ''
      }
    ]"
  ></app-item-tabset>

  <ng-template #participantTitle>
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="24">
        <app-header-title
          [isIcon]="false"
          [title]="participant?.participant | displayName"
        ></app-header-title>
      </nz-col>
    </nz-row>
  </ng-template>
</ng-container>
