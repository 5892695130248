import { PortalNavigationService } from './../../../../portals/portal-navigation.service';
import { Observable, of, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import {
  Router,
  NavigationEnd,
  Event as NavigationEvent,
  ActivatedRoute,
} from '@angular/router';

import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  AfterViewInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';
import * as fromUserAction from 'src/app/components/user/store/user.actions';
import {
  Portal,
  ComponentPortal,
  TemplatePortal,
  DomPortal,
} from '@angular/cdk/portal';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements AfterViewInit, OnDestroy {
  user$: Observable<any> = of(null);
  subscribe = new Subject();
  currentURL: string = '';
  displaySearchBar: boolean = false;
  selectedPortal: Portal<any>;
  //componentPortal: ComponentPortal<ComponentPortalExample>;
  templatePortal: TemplatePortal<any>;
  domPortal: DomPortal<any>;

  @Input() theme: any;
  @Output() collapsedChange = new EventEmitter(false);

  constructor(
    private userStore: Store<UserState>,
    private router: Router,
    private activieRoute: ActivatedRoute,
    private portalNavigationService: PortalNavigationService
  ) {
    this.getUser();
  }

  ngAfterViewInit(): void {
    this.getCurrentRouter();
    this.getPortal();
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  onCollapseChanges(): void {
    this.collapsedChange.emit(true);
  }

  getCurrentRouter(): void {
    this.router.events.subscribe((event: NavigationEvent) => {
      if (event instanceof NavigationEnd) {
        this.currentURL = event.url;
      }
    });
  }

  getPortal(): void {
    this.portalNavigationService.portal$
      .pipe(takeUntil(this.subscribe))
      .subscribe((portal: ComponentPortal<any>) => {
        this.selectedPortal = new ComponentPortal<any>(portal.component);
      });
  }

  getUser(): void {
    this.user$ = this.userStore.select(fromUserSelector.user);
  }

  getRoute(): void {}
}
