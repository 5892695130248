import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { messageState } from '../../store/message.reducer';
import * as fromMessageActions from '../../store/message.actions';
import * as fromMessageSelectors from '../../store/message.selectors';
import { Observable, Subject, of } from 'rxjs';
import { MessageId, MessageItem, MessageUser } from '../../store/message.model';
import { v4 as uuidv4 } from 'uuid';
import { Update } from '@ngrx/entity';
import { takeUntil } from 'rxjs/operators';
import { NzMessageService } from 'ng-zorro-antd/message';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';
import { Timestamp } from '@angular/fire/firestore';

@Component({
  selector: 'app-messages-screen-input',
  templateUrl: './messages-screen-input.component.html',
  styleUrls: ['./messages-screen-input.component.scss'],
})
export class MessagesScreenInputComponent implements OnInit, OnDestroy {
  subscribe = new Subject();
  message$: Observable<MessageId | any> = of(null);
  user$: Observable<any> = of(null);

  newMessageForm: UntypedFormGroup = this.fb.group({});
  input: UntypedFormControl = new UntypedFormControl('');

  constructor(
    private fb: UntypedFormBuilder,
    private usrStore: Store<UserState>,
    private storeMessage: Store<messageState>,
    private nzMessage: NzMessageService
  ) {}

  ngOnInit(): void {
    this.get__current_USER();
    this.get__current_MESSAGE();
    this.formInit();
    this.formCreate();
    this.onClose__MESSAGE();
  }

  get__current_USER(): void {
    this.user$ = this.usrStore.select(fromUserSelector.user);
  }

  get__current_MESSAGE(): void {
    this.message$ = this.storeMessage.select(
      fromMessageSelectors.selectMessage
    );
  }

  formInit(): void {
    this.input = new UntypedFormControl('', Validators.required);
  }

  formCreate(): void {
    this.newMessageForm = this.fb.group({
      text: this.input,
    });
  }

  add__MESSAGE(user: any, currentMessage: MessageId): any {
    if (!user || !currentMessage) {
      this.nzMessage.error('Aucun message renseigné !');
      return null;
    }

    const formValue = this.newMessageForm.value;
    const userUpdate = this.get__auteur_MESSAGE(user);
    const text = formValue.text;

    const newMessage: MessageItem = {
      id: uuidv4(),
      date: Timestamp.now(),
      auteur: user,
      text: text,
    };

    const conversation: MessageItem[] = [
      ...currentMessage.messages,
      newMessage,
    ];

    const message: Update<MessageId> = {
      id: currentMessage?.id ? currentMessage.id : '',
      changes: {
        ...currentMessage,
        messages: conversation,
        userUpdate: user,
        lastMessage: newMessage,
      },
    };

    this.storeMessage.dispatch(fromMessageActions.updateMessage({ message }));

    this.clear__MESSAGE();
  }

  get__auteur_MESSAGE(user: any): MessageUser | any {
    if (!user) {
      return null;
    }
    const auteur: MessageUser = {
      id: user.id,
      fonction: user.fonction,
      displayName: user.displayName,
      avatar: user.avatar,
    };

    return auteur;
  }

  onClose__MESSAGE(): void {
    this.storeMessage
      .select(fromMessageSelectors.selectDisplay)
      .pipe(takeUntil(this.subscribe))
      .subscribe((val): any => {
        if (!val) {
          return null;
        }

        this.clear__MESSAGE();
      });
  }

  clear__MESSAGE(): void {
    this.newMessageForm.reset();
  }

  ngOnDestroy() {
    this.subscribe.next(null);
    this.subscribe.complete();
  }
}
