import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { UtilitiesState } from 'src/app/contents/components/utilities/store/utilities.reducer';
import { v4 as uuidV4 } from 'uuid';
import * as fromUtilitiesAction from 'src/app/contents/components/utilities/store/utilities.actions';
import * as fromUtilitiesSelector from 'src/app/contents/components/utilities/store/utilities.selectors';
import { capitalize, isDate, isNull, toUpper } from 'lodash';
import { Timestamp } from 'firebase/firestore';
import { differenceInYears, fromUnixTime } from 'date-fns';

@Component({
  selector: 'app-person-form',
  templateUrl: './person-form.component.html',
  styleUrls: ['./person-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonFormComponent implements OnInit, OnChanges, OnDestroy {
  subscribe = new Subject();
  today: Date = new Date(Date.now());
  civilite$: Observable<any> = this.utilitiesStore.select(
    fromUtilitiesSelector.civilities
  );
  dateFormat: string = 'dd/MM/yyyy';
  etablissementUniteLegaleForm = new FormGroup({
    statutDiffusionUniteLegale: new FormControl(''),
    unitePurgeeUniteLegale: new FormControl(''),
    dateCreationUniteLegale: new FormControl(''),
    sigleUniteLegale: new FormControl(''),
    sexeUniteLegale: new FormControl(''),
    prenom1UniteLegale: new FormControl(''),
    prenom2UniteLegale: new FormControl(''),
    prenom3UniteLegale: new FormControl(''),
    prenom4UniteLegale: new FormControl(''),
    prenomUsuelUniteLegale: new FormControl(''),
    pseudonymeUniteLegale: new FormControl(''),
    identifiantAssociationUniteLegale: new FormControl(''),
    trancheEffectifsUniteLegale: new FormControl(''),
    anneeEffectifsUniteLegale: new FormControl(''),
    dateDernierTraitementUniteLegale: new FormControl(''),
    nombrePeriodesUniteLegale: new FormControl(0),
    categorieEntreprise: new FormControl(''),
    anneeCategorieEntreprise: new FormControl(''),
    etatAdministratifUniteLegale: new FormControl(''),
    nomUniteLegale: new FormControl(''),
    denominationUniteLegale: new FormControl(''),
    denominationUsuelle1UniteLegale: new FormControl(''),
    denominationUsuelle2UniteLegale: new FormControl(''),
    denominationUsuelle3UniteLegale: new FormControl(''),
    activitePrincipaleUniteLegale: new FormControl(''),
    categorieJuridiqueUniteLegale: new FormControl(''),
    nicSiegeUniteLegale: new FormControl(''),
    nomenclatureActivitePrincipaleUniteLegale: new FormControl(''),
    nomUsageUniteLegale: new FormControl(''),
    economieSocialeSolidaireUniteLegale: new FormControl(''),
    societeMissionUniteLegale: new FormControl(''),
    caractereEmployeurUniteLegale: new FormControl(''),
    activite_principale_libelle: new FormGroup({
      intitule_naf: new FormControl(''),
      intitule_naf_40: new FormControl(''),
      intitule_naf_65: new FormControl(''),
      code_naf: new FormControl(''),
    }),
    categorie_juridique_libelle: new FormGroup({
      libelle: new FormControl(''),
      code: new FormControl(''),
    }),
  });
  etablissementAdresseCommune = new FormGroup({
    code: new FormControl(''),
    nom: new FormControl(''),
    codesPostaux: new FormControl([]),
    siren: new FormControl(''),
    codeEpci: new FormControl(''),
    codeDepartement: new FormControl(''),
    codeRegion: new FormControl(''),
    epci: new FormGroup({
      code: new FormControl(''),
      nom: new FormControl(''),
      type: new FormControl(''),
      financement: new FormControl(''),
      codesDepartements: new FormControl(['']),
      codesRegions: new FormControl(['']),
      population: new FormControl(0),
      surface: new FormControl(0),
      centre: new FormControl(null),
      contour: new FormControl(null),
      bbox: new FormControl(null),
    }),
    departement: new FormGroup({
      code: new FormControl(''),
      nom: new FormControl(''),
      codeRegion: new FormControl(''),
      region: new FormGroup({}),
    }),
    region: new FormGroup({
      code: new FormControl(''),
      nom: new FormControl(''),
    }),
    population: new FormControl(0),
    surface: new FormControl(0),
    centre: new FormControl(''),
    contour: new FormControl(''),
    mairie: new FormControl(''),
    bbox: new FormControl(''),
  });
  etablissementAdresse = new FormGroup({
    complementAdresseEtablissement: new FormControl(''),
    numeroVoieEtablissement: new FormControl(''),
    indiceRepetitionEtablissement: new FormControl(''),
    typeVoieEtablissement: new FormControl(''),
    libelleVoieEtablissement: new FormControl(''),
    codePostalEtablissement: new FormControl(''),
    libelleCommuneEtablissement: new FormControl(''),
    libelleCommuneEtrangerEtablissement: new FormControl(''),
    distributionSpecialeEtablissement: new FormControl(''),
    codeCommuneEtablissement: new FormControl(''),
    codeCedexEtablissement: new FormControl(''),
    libelleCedexEtablissement: new FormControl(''),
    codePaysEtrangerEtablissement: new FormControl(''),
    libellePaysEtrangerEtablissement: new FormControl(''),
    fullAdresse: new FormControl(''),
    commune: this.etablissementAdresseCommune,
  });

  @Input() noDataTitle: string = 'Contacts entreprise';
  @Input() isList: boolean = true;
  @Input() isAdresse: boolean = false;
  @Input() isRole: boolean = false;
  @Input() isFonction: boolean = false;
  @Input() isCoordonnees: boolean = false;
  @Input() isDateNaissance: boolean = false;
  @Input() isEtablissement: boolean = false;
  @Input() isFonctionRequired: boolean = false;

  @Input() isAdresseRequired: boolean = false;
  @Input() isMobile: boolean = true;
  @Input() isEmailRequired: boolean = false;
  @Input() isMobileRequired: boolean = false;
  @Input() isFixeRequired: boolean = false;
  @Input() isDateNaissanceRequired: boolean = false;

  @Input() contactsInput: any;
  @Output() onCurrentChanges = new EventEmitter<any>(false);
  @Output() add = new EventEmitter<any>(false);

  contactEtablissement = new FormControl<{
    siret: string;
    denominationUniteLegale: string;
    categorie: string;
    activite: string;
    adresseLabel: string;
  }>({
    siret: '',
    denominationUniteLegale: '',
    categorie: '',
    activite: '',
    adresseLabel: '',
  });

  adresse = new FormControl<{
    adresse: {
      properties: {
        label: string;
        score: number;
        housenumber: string;
        id: string;
        type: string;
        name: string;
        postcode: string;
        citycode: string;
        x: number;
        y: number;
        city: string;
        context: string;
        importance: number;
        street: string;
      };
      geometry: {
        type: string;
        coordinates: [number];
      };
    };
    complement: string;
    qpv: string;
  }>({
    adresse: {
      properties: {
        label: '',
        score: 0,
        housenumber: '',
        id: '',
        type: '',
        name: '',
        postcode: '',
        citycode: '',
        x: 0,
        y: 0,
        city: '',
        context: '',
        importance: 0.0,
        street: '',
      },
      geometry: {
        type: '',
        coordinates: [0],
      },
    },
    complement: '',
    qpv: '',
  });

  contacts: FormArray = this.fb.array<
    {
      id: string;
      civilite: string;
      lastName: string;
      firstName: string;
      avatar: string;
      displayName: string;
      fonction: string;
      role: string;
      genre: string;
      age: string;
      tranche: string;
      dateNaissance: Date;
      isSelected: boolean;
      isRejected: boolean;
      isInterviewed: boolean;
      isValided: boolean;

      coordonnees: {
        mobile: string;
        phoneNumber: string;
        email: string;
        fixe: string;
      };
      adresse: {
        adresse: {
          properties: {
            label: string;
            score: number;
            housenumber: string;
            id: string;
            type: string;
            name: string;
            postcode: string;
            citycode: string;
            x: number;
            y: number;
            city: string;
            context: string;
            importance: number;
            street: string;
          };
          geometry: {
            type: string;
            coordinates: [number];
          };
        };
        complement: string;
        qpv: string;
      };

      etablissement: {
        siret: string;
        denominationUniteLegale: string;
        categorie: string;
        activite: string;
        adresseLabel: string;
      };
    }[]
  >([]);

  contactForm = this.fb.group({
    contacts: this.contacts,
  });

  constructor(
    private fb: FormBuilder,
    private utilitiesStore: Store<UtilitiesState>
  ) {}

  ngOnInit(): void {
    this.loadUtilitiesCivilite();
    this.onChangesForm();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.setFormInit(changes.isList?.currentValue);
    this.onUpdateItems(
      changes?.contactsInput?.currentValue,
      changes.isList?.currentValue
    );
  }
  ngOnDestroy(): void {
    this.subscribe.next(false);
    this.subscribe.complete();
  }

  setFormInit(isList: boolean): void {
    if (!isList) {
      this.onAdd();
    }

    return;
  }

  get contactsArrayControler() {
    return this.contactForm.get('contacts') as FormArray;
  }

  compareCivilite(item1: any, item2: any) {
    return item1 === item2;
  }

  loadUtilitiesCivilite(): void {
    this.utilitiesStore.dispatch(fromUtilitiesAction.loadCivilities());
  }

  /**
   * Actualisation des informations de la personne
   * @param items Information de la personnne
   * @param isList indentifier si les données sont une liste de personne ou une seule personne
   * @returns
   */
  onUpdateItems(items: any, isList: boolean): void {
    if (!items?.length && isList) {
      return;
    }

    if (!items?.length && !isList && items.id) {
      this.contacts.clear();

      const administratifForm = items?.diagnostic.administratif;
      const administratif = {
        ...administratifForm,
        dateEndPassIae: !isNull(administratifForm?.dateEndPassIae)
          ? fromUnixTime(administratifForm?.dateEndPassIae['seconds'])
          : null,
        dateEntree: !isNull(administratifForm?.dateEntree)
          ? fromUnixTime(administratifForm?.dateEntree['seconds'])
          : null,
        dateInscription: !isNull(administratifForm?.dateInscription)
          ? fromUnixTime(administratifForm?.dateInscription['seconds'])
          : null,
        dateRecrutement: !isNull(administratifForm?.dateRecrutement)
          ? fromUnixTime(administratifForm?.dateRecrutement['seconds'])
          : null,
        dateStartPassIae: !isNull(administratifForm?.dateStartPassIae)
          ? fromUnixTime(administratifForm?.dateStartPassIae['seconds'])
          : null,
      };

      const person = {
        ...items,
        diagnostic: { ...items?.diagnostic, administratif },
      };

      this.onUpdateItem(person);

      return;
    }

    this.contacts.clear();

    items.forEach((item: any) => {
      const administratifForm = item?.diagnostic.administratif;

      const administratif = {
        ...administratifForm,
        dateEndPassIae: !isNull(administratifForm?.dateEndPassIae)
          ? fromUnixTime(administratifForm?.dateEndPassIae['seconds'])
          : null,
        dateEntree: !isNull(administratifForm?.dateEntree)
          ? fromUnixTime(administratifForm?.dateEntree['seconds'])
          : null,
        dateInscription: !isNull(administratifForm?.dateInscription)
          ? fromUnixTime(administratifForm?.dateInscription['seconds'])
          : null,
        dateRecrutement: !isNull(administratifForm?.dateRecrutement)
          ? fromUnixTime(administratifForm?.dateRecrutement['seconds'])
          : null,
        dateStartPassIae: !isNull(administratifForm?.dateStartPassIae)
          ? fromUnixTime(administratifForm?.dateStartPassIae['seconds'])
          : null,
      };

      const person = {
        ...item,
        diagnostic: { ...item?.diagnostic, administratif },
      };

      this.onUpdateItem(person);
    });
  }

  onAdd(): void {
    const contact = this.fb.group({
      id: [uuidV4(), Validators.required],
      civilite: ['', Validators.required],
      lastName: ['', Validators.required],
      firstName: ['', Validators.required],
      fonction: ['', Validators.required],
      avatar: [''],
      displayName: ['', Validators.required],
      isSelected: [false],
      isRejected: [false],
      isInterviewed: [false],
      isValided: [false],
      role: [''],
      genre: [''],
      age: [''],
      tranche: [''],
      dateNaissance: [this.today],
      coordonnees: this.fb.group({
        mobile: ['', Validators.required],
        phoneNumber: ['', Validators.required],
        fixe: [''],
        email: ['', Validators.required],
      }),
      adresse: this.fb.group({
        adresse: this.fb.group({
          properties: this.fb.group({
            label: [''],
            score: [0],
            housenumber: [''],
            id: [''],
            type: [''],
            name: [''],
            postcode: [''],
            citycode: [''],
            x: [0],
            y: [0],
            city: [''],
            context: [''],
            importance: [0.0],
            street: [''],
          }),
          geometry: this.fb.group({
            type: [''],
            coordinates: [0, 0],
          }),
        }),
        complement: [''],
        qpv: [''],
      }),
      etablissement: new FormGroup({
        siren: new FormControl(''),
        nic: new FormControl(''),
        siret: new FormControl(''),
        statutDiffusionEtablissement: new FormControl(''),
        dateCreationEtablissement: new FormControl(''),
        trancheEffectifsEtablissement: new FormControl(''),
        anneeEffectifsEtablissement: new FormControl(''),
        activitePrincipaleRegistreMetiersEtablissement: new FormControl(''),
        dateDernierTraitementEtablissement: new FormControl(''),
        etablissementSiege: new FormControl(false),
        nombrePeriodesEtablissement: new FormControl(0),
        uniteLegale: this.etablissementUniteLegaleForm,
        adresseEtablissement: this.etablissementAdresse,
        adresse2Etablissement: this.etablissementAdresse,
        periodesEtablissement: new FormGroup([]),
        conventionsCollectives: new FormGroup({
          activitePrincipale: new FormControl(''),
          activitePrincipaleUniteLegale: new FormControl(''),
          caractereEmployeurUniteLegale: new FormControl(''),
          categorieJuridiqueUniteLegale: new FormControl(''),
          conventions: new FormControl([]),
          dateCreationUniteLegale: new FormControl(''),
          dateDebut: new FormControl(''),
          etablissements: new FormControl(0),
          etatAdministratifUniteLegale: new FormControl(''),
          highlightLabel: new FormControl(''),
          label: new FormControl(''),
          matching: new FormControl(0),
          simpleLabel: new FormControl(''),
          siren: new FormControl(''),
          activitePrincipaleEtablissement: new FormControl(''),
          address: new FormControl(''),
          categorieEntreprise: new FormControl(''),
          codeCommuneEtablissement: new FormControl(''),
          codePostalEtablissement: new FormControl(''),
          etablissementSiege: new FormControl(false),
          etatAdministratifEtablissement: new FormControl(''),
          idccs: new FormControl([]),
          libelleCommuneEtablissement: new FormControl(''),
          siret: new FormControl(''),
        }),
      }),
      diagnostic: new FormGroup({
        administratif: new FormGroup({
          dateRecrutement: new FormControl<Date | any>(null),
          dateInscription: new FormControl<Date | any>(null),
          dateEntree: new FormControl<Date | any>(null),
          dateStartPassIae: new FormControl<Date | any>(null),
          dateEndPassIae: new FormControl<Date | any>(null),
          numbCarteSejour: new FormControl(''),
          numCaiCir: new FormControl(''),
          criteres1: new FormControl<{
            justificatif: string;
            label: string;
            value: string;
          }>({ justificatif: '', label: '', value: '' }, Validators.required),
          typeCarteSejour: new FormControl<{
            info: string;
            titre: string;
            type: string;
          }>({ info: '', titre: '', type: '' }),
          criteres2: new FormControl<{ info: string; item: string }[] | any>(
            [],
            Validators.required
          ),
          isPassIae: new FormControl(false, Validators.required),
          isFse: new FormControl(false, Validators.required),
          isFrein: new FormControl<boolean>(false, Validators.required),
        }),
        presentation: new FormControl(''),
      }),
      setting: this.fb.group({
        color: new FormControl(''),
      }),
    });

    this.contacts.insert(0, contact);
  }

  /**
   * Ajouter une adresse à la personne
   * @param event Adresse informations
   * @param index index de la personne dans le formulaire
   */
  onAddAdresse(event: any, index: number): void {
    const { geometry, properties } = event;

    this.contacts.at(index).patchValue({
      adresse: {
        adresse: { geometry, properties },
      },
    });
  }

  /**
   * Ajouter une entreprise à la personne
   * @param etablissement Entreprise information
   * @param index index de la personne dans le formulaire
   */
  onAddDEtablissement(etablissement: any, index: number): void {
    this.contacts.at(index).patchValue({
      etablissement: etablissement,
    });
  }

  onUpdateItem(item: any): void {
    const dateNaissance = item?.dateNaissance
      ? fromUnixTime(item?.dateNaissance['seconds'])
      : Timestamp.now();
    const role = item?.role || '';
    const contact = this.fb.group({ ...item, role, dateNaissance });

    this.contacts.push(contact);
  }

  /**
   * Supprimer une personne du formulaire
   * @param index index - emplacement de la personne
   */
  onRemoveContact(index: number): void {
    this.contacts.removeAt(index);
  }

  /**
   * Effacer toutes les personnes ajoutées
   */
  onRemoveAllContacts(): void {
    this.contacts.clear();
  }

  /**
   * Ajouter des coordonnées téléphoniques à la personne
   * @param coordonnees Information sur les coordonnées mobile - phonenumber
   * @param index emplacement de la personne dans le formulaire
   */
  onChangesCoordonnees(coordonnees: any, index: number): void {
    this.contacts.at(index).patchValue({
      coordonnees: {
        fixe: coordonnees?.fixe ? coordonnees.fixe : '',
        mobile: coordonnees?.mobile ? coordonnees.mobile : '',
        phoneNumber: coordonnees?.phoneNumber ? coordonnees.phoneNumber : '',
        email: coordonnees?.email ? coordonnees.email : '',
      },
    });
  }

  /**
   * Ajouter une adresse e-mail à la personne
   * @param email Information sur l'adresse email
   * @param index Emplacement de la personne dans le formulaire
   */
  onChangesCoordonneesEmail(email: any, index: number): void {
    this.contacts.at(index).patchValue({
      coordonnees: {
        email: email ? email : '',
      },
    });
  }

  onChangesCoordonneesFixe(fixe: any, index: number): void {
    this.contacts.at(index).patchValue({
      coordonnees: {
        fixe: fixe ? fixe : '',
      },
    });
  }

  onRemove(index: number): void {
    this.contacts.removeAt(index);
  }

  onRemoveAll(): void {
    this.contacts.clear();
  }

  onSetTrancheAge(age: number): string {
    if (age > 12 && age < 15) {
      return 'moins de 15 ans';
    }
    if (age > 14 && age < 20) {
      return '15-19 ans';
    }
    if (age > 19 && age < 25) {
      return '20-24 ans';
    }
    if (age > 24 && age < 30) {
      return '25-29 ans';
    }
    if (age > 29 && age < 35) {
      return '30-34 ans';
    }
    if (age > 34 && age < 40) {
      return '35-39 ans';
    }
    if (age > 39 && age < 45) {
      return '40-44 ans';
    }
    if (age > 44 && age < 50) {
      return '45-49 ans';
    }
    if (age > 49 && age < 55) {
      return '50-54 ans';
    }

    if (age > 54 && age < 60) {
      return '55-59 ans';
    }
    if (age > 59 && age < 65) {
      return '60-64 ans';
    }
    if (age > 64 && age < 70) {
      return '65-69 ans';
    }
    if (age > 69 && age < 75) {
      return '70-74 ans';
    }
    if (age > 74) {
      return '75 ans ou plus';
    }

    return 'non renseignée';
  }

  onChangesForm(): void {
    this.contactForm
      .get('contacts')
      ?.valueChanges.pipe(takeUntil(this.subscribe))
      .subscribe((value: any) => {
        if (!this.isList) {
          const item = value[0];
          const lastName = toUpper(item?.lastName.trim());
          const firstName = capitalize(item?.firstName.trim());
          const fonction = capitalize(item?.fonction.trim());
          const role = capitalize(item?.role.trim());
          const displayName: string = `${item?.civilite} ${lastName} ${firstName}`;
          const avatar: string = `${lastName ? lastName.charAt(0) : ''}${
            firstName ? toUpper(firstName.charAt(0)) : ''
          }`;
          const dateNaissance = Timestamp.fromDate(item.dateNaissance);
          const age: number = differenceInYears(
            this.today,
            fromUnixTime(dateNaissance['seconds'])
          );
          const tranche: string = this.onSetTrancheAge(age);

          const diagnostic = item.diagnostic;
          const { administratif } = diagnostic;
          const dateEndPassIae = !isNull(administratif.dateEndPassIae)
            ? Timestamp.fromDate(administratif.dateEndPassIae)
            : null;
          const dateEntree = !isNull(administratif.dateEntree)
            ? Timestamp.fromDate(administratif.dateEntree)
            : null;
          const dateInscription = !isNull(administratif.dateInscription)
            ? Timestamp.fromDate(administratif.dateInscription)
            : null;
          const dateRecrutement = !isNull(administratif.dateRecrutement)
            ? Timestamp.fromDate(administratif.dateRecrutement)
            : null;
          const dateStartPassIae = !isNull(administratif.dateStartPassIae)
            ? Timestamp.fromDate(administratif.dateStartPassIae)
            : null;

          const contact = {
            ...item,
            lastName,
            firstName,
            fonction,
            role,
            displayName,
            avatar,
            age: age < 0 ? 0 : age,
            tranche,
            dateNaissance,
            genre:
              item?.civilite === 'M'
                ? 'Masculin'
                : item?.civilite === 'Mme'
                ? 'Féminin'
                : 'Autre',
            diagnostic: {
              ...diagnostic,
              administratif: {
                ...administratif,
                dateEndPassIae,
                dateEntree,
                dateInscription,
                dateRecrutement,
                dateStartPassIae,
              },
            },
          };

          this.onCurrentChanges.emit(contact);
          return;
        }

        const contacts = value?.length
          ? value.map((el: any) => {
              const lastName = toUpper(el?.lastName.trim());
              const firstName = capitalize(el?.firstName.trim());
              const fonction = capitalize(el?.fonction.trim());
              const role = capitalize(el?.role.trim());
              const displayName: string = `${el?.civilite} ${lastName} ${firstName}`;
              const avatar: string = `${lastName ? lastName.charAt(0) : ''}${
                firstName ? toUpper(firstName.charAt(0)) : ''
              }`;
              const dateNaissance = Timestamp.fromDate(el.dateNaissance);
              const age = differenceInYears(
                this.today,
                fromUnixTime(dateNaissance['seconds'])
              );
              const tranche: string = this.onSetTrancheAge(age);
              const diagnostic = el.diagnostic;
              const { administratif } = diagnostic;
              const dateEndPassIae = !isNull(administratif.dateEndPassIae)
                ? Timestamp.fromDate(administratif.dateEndPassIae)
                : null;
              const dateEntree = !isNull(administratif.dateEntree)
                ? Timestamp.fromDate(administratif.dateEntree)
                : null;
              const dateInscription = !isNull(administratif.dateInscription)
                ? Timestamp.fromDate(administratif.dateInscription)
                : null;
              const dateRecrutement = !isNull(administratif.dateRecrutement)
                ? Timestamp.fromDate(administratif.dateRecrutement)
                : null;
              const dateStartPassIae = !isNull(administratif.dateStartPassIae)
                ? Timestamp.fromDate(administratif.dateStartPassIae)
                : null;

              const contact = {
                ...el,
                lastName,
                firstName,
                fonction,
                role,
                displayName,
                avatar,
                age: age < 0 ? 0 : age,
                tranche,
                dateNaissance,
                genre:
                  el?.civilite === 'M'
                    ? 'Masculin'
                    : el?.civilite === 'Mme'
                    ? 'Féminin'
                    : 'Autre',
                diagnostic: {
                  ...diagnostic,
                  administratif: {
                    ...administratif,
                    dateEndPassIae,
                    dateEntree,
                    dateInscription,
                    dateRecrutement,
                    dateStartPassIae,
                  },
                },
              };

              return contact;
            })
          : [];

        this.onCurrentChanges.emit(contacts);
      });
  }
}
