<div class="container" *ngIf="operation$ | async as operation">
  <div [ngSwitch]="view$ | async">
    <div *ngSwitchCase="'list'">
      <ng-container *ngIf="contacts$ | async | size; else noResults">
        <app-operation-contacts-filters
          [operation$]="operation$"
          [contacts$]="contacts$"
        ></app-operation-contacts-filters>
        <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
        <app-layout-list
          [card]="card"
          [new]="new"
          [source]="contacts$ | async"
        ></app-layout-list>
      </ng-container>
    </div>
    <div *ngSwitchCase="'details'">
      <app-operation-contacts-item
        (back)="onBack()"
      ></app-operation-contacts-item>
    </div>
    <div *ngSwitchDefault>
      <app-layout-list
        *ngIf="contacts$ | async | size; else noResults"
        [card]="card"
        [new]="new"
        [source]="contacts$ | async"
      ></app-layout-list>
    </div>
  </div>

  <ng-template #new>
    <app-card-new
      [type]="'person'"
      [title]="newCardTitle"
      [description]="newCardDescription"
      [definition]="newCardDefinition"
      [width]="'300px'"
      [height]="'280px'"
      (add)="onNew(operation)"
      (back)="onBack()"
      (cancel)="onBack()"
    ></app-card-new>
  </ng-template>

  <ng-template #card let-item let-position="idx">
    <nz-card
      nzHoverable
      (click)="onSelect(operation, item)"
      [nzTitle]="op_entite_card_title"
      [nzExtra]="op_entite_card_extra"
    >
      <ng-template #op_entite_card_title>
        <app-header-title [icon]="'person'"></app-header-title>
      </ng-template>
      <ng-template #op_entite_card_extra>
        <app-button-dropdown
          (delete)="onDelete(item, operation)"
        ></app-button-dropdown>
      </ng-template>

      <strong>{{ item?.contact | displayName }}</strong>

      <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>

      <nz-row [nzGutter]="4" nzAlign="middle" nzJustify="start">
        <nz-col [nzSpan]="24">
          <nz-tag nzColor="processing">
            {{item?.contact?.entitesTitles?.[0]}}
          </nz-tag>
        </nz-col>
      </nz-row>
      <nz-space nzDirection="vertical" nzSize="small"></nz-space>
      <nz-row [nzGutter]="4" nzAlign="middle" nzJustify="start">
        <nz-col [nzSpan]="10"
          ><small class="contentTitleCard">
            {{ item?.contact?.intervention?.role | carLimitation: 20 }}
          </small></nz-col
        >
        <nz-col [nzSpan]="4">
          <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
        </nz-col>
        <nz-col [nzSpan]="12">
          <small></small>
        </nz-col>
      </nz-row>
    </nz-card>
  </ng-template>

  <ng-template #noResults>
    <app-no-result
      [icon]="'person'"
      [addtitle]="newCardDescription"
      [notitle]="noModuleTitle"
      [description]="newCardDefinition"
      (new)="onNew(operation)"
    ></app-no-result>
  </ng-template>

  <ng-template #contactFormTitle>
    <app-header-title [icon]="'people'" [title]="title"></app-header-title>
  </ng-template>
</div>
