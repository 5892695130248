import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OperationEventsListComponent } from './components/operation-events-list/operation-events-list.component';
import { OperationEventsItemComponent } from './components/operation-events-item/operation-events-item.component';
import { OperationEventsCardComponent } from './components/operation-events-card/operation-events-card.component';
import { OperationEventsFormComponent } from './components/operation-events-form/operation-events-form.component';
import { ContentsModule } from 'src/app/contents/contents.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { NoResultModule } from 'src/app/contents/components/no-result/no-result.module';
import { HeaderModule } from 'src/app/contents/components/header-title/header.module';
import { LayoutModule } from 'src/app/contents/components/layout/layout.module';

@NgModule({
  declarations: [
    OperationEventsListComponent,
    OperationEventsItemComponent,
    OperationEventsCardComponent,
    OperationEventsFormComponent,
  ],
  imports: [
    CommonModule,
    ContentsModule,
    SharedModule,
    NoResultModule,
    HeaderModule,
    LayoutModule,
  ],
  exports: [
    OperationEventsListComponent,
    OperationEventsItemComponent,
    OperationEventsCardComponent,
    OperationEventsFormComponent,
  ],
})
export class OperationEventsModule {}
