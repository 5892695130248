<ng-container *ngIf="planigItemsArray?.length; else noPlaning">
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="2">
      <app-header-title [icon]="'timer'"></app-header-title>
    </nz-col>
    <nz-col nzSpan="10">
      <h5 nz-typography>
        Total réalisé: {{ total | number: "2.1-2":"fr" }}
        <small *ngIf="total">heures</small>
      </h5>
    </nz-col>
    <nz-col nzSpan="6">
      <p nz-typography class="total">
        Heures de travail : {{ totalWork | number: "2.1-2":"fr" }}
        <small *ngIf="total">h</small>
      </p>
    </nz-col>
    <nz-col nzSpan="6">
      <p nz-typography class="total">
        Heures de formation : {{ totalTraining | number: "2.1-2":"fr" }}
        <small *ngIf="total">h</small>
      </p>
    </nz-col>
  </nz-row>
  <nz-divider></nz-divider>
  <form nz-form [formGroup]="planingForm" nzLayout="vertical">
    <div formArrayName="items" class="container">
      <nz-row
        nzAlign="middle"
        nzJustify="start"
        nzGutter="8"
        *ngFor="let el of planigItemsArray.controls; let i = index"
        [formGroupName]="i"
      >
        <nz-col nzSpan="2"></nz-col>

        <nz-col nzSpan="2">
          <mat-icon>today</mat-icon>
        </nz-col>
        <nz-col nzSpan="4">
          <nz-form-item>
            <nz-form-label nzSpan="24">Date</nz-form-label>
            <p nz-typography>{{ el.value?.timestamp | dateFr: "monthYear" }}</p>
          </nz-form-item>
        </nz-col>
        <nz-col nzSpan="6"></nz-col>

        <nz-col nzSpan="4">
          <nz-form-item>
            <nz-form-label nzSpan="24">Heures de travail</nz-form-label>
            <nz-form-control>
              <nz-input-number
                formControlName="work"
                [nzMin]="0"
                [nzMax]="300"
                [nzStep]="0.11"
              ></nz-input-number>
            </nz-form-control>
          </nz-form-item>
        </nz-col>
        <nz-col nzSpan="2">
          <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
        </nz-col>
        <nz-col nzSpan="4">
          <nz-form-item>
            <nz-form-label nzSpan="24">Heures de formation</nz-form-label>
            <nz-form-control>
              <nz-input-number
                formControlName="training"
                [nzMin]="0"
                [nzMax]="300"
                [nzPrecision]="00"
                [nzStep]="0.11"
              ></nz-input-number>
            </nz-form-control>
          </nz-form-item>
        </nz-col>
        <nz-divider></nz-divider>
      </nz-row>
    </div>

    <nz-divider></nz-divider>
    <nz-row nzAlign="middle" nzJustify="end" nzGutter="8">
      <nz-col nzSpan="16"></nz-col>
      <nz-col nzSpan="4"> </nz-col>
      <nz-col nzSpan="4">
        <button
          nz-button
          nzType="primary"
          nzBlock
          *ngIf="planingForm.dirty"
          (click)="$event.preventDefault(); onValidation()"
        >
          Valider les heures
        </button>
        <app-loading *ngIf="updateLoading$ | async"></app-loading>
      </nz-col>
    </nz-row>
  </form>
</ng-container>

<ng-template #noPlaning>
  <app-no-result
    [icon]="'work_history'"
    [title]="'Aucune heure de travail'"
    [description]="'Mission n\'a pas démarrée.'"
    ``
    [isButton]="false"
  ></app-no-result>
</ng-template>

<ng-template #loading>
  <app-loading></app-loading>
</ng-template>
