<ng-container *ngIf="participant$ | async as participant">
  <ng-container *ngIf="diagnostic$ | async as diagnostic; else noResults">
    <nz-page-header class="site-page-header" [nzTitle]="diagnosticItemTitleTpl">
      <ng-template #diagnosticItemTitleTpl>
        <app-header-title
          [icon]="'fact_check'"
          [isIcon]="true"
        ></app-header-title>
      </ng-template>
      <nz-page-header-tags>
        <app-participant-diagnostic-item-header-tags
          [besoins]="diagnostic.besoins"
        ></app-participant-diagnostic-item-header-tags>
      </nz-page-header-tags>
      <!-- <nz-page-header-content>
        <app-participant-diagnostic-item-header></app-participant-diagnostic-item-header>
      </nz-page-header-content> -->
      <nz-page-header-extra>
        <nz-row nzAlign="middle" nzGutter="8" nzJustify="end">
          <nz-col nzSpan="10"> </nz-col>
          <nz-col nzSpan="2"></nz-col>
          <nz-col nzSpan="10">
            <app-button-dropdown
              [isDelete]="true"
              [isUpdate]="true"
              [isExportPDF]="true"
              [isExportWRD]="true"
              [deleteType]="deleteType"
              (select)="onDropdownAction($event, participant, diagnostic)"
            ></app-button-dropdown>
          </nz-col>
        </nz-row>
      </nz-page-header-extra>
    </nz-page-header>

    <nz-space nzAlign="center" nzDirection="vertical" nzSize="small"></nz-space>

    <app-item-tabset
      [tabs]="[
        {
          name: 'Social',
          index: 0,
          template: diagnosticSocial
        },
        {
          name: 'Professionnel',
          index: 1,
          template: diagnosticProfessionnel
        },
        {
          name: 'Présentation',
          index: 2,
          template: diagnosticPresentation
        }
      ]"
    ></app-item-tabset>

    <ng-template #diagnosticTitle>
      <app-header-title [icon]="icon"></app-header-title>
    </ng-template>
    <ng-template #diagnosticExtra> </ng-template>

    <ng-template #diagnosticSocial>
      <app-participant-diagnostic-item-social></app-participant-diagnostic-item-social>
    </ng-template>
    <ng-template #diagnosticProfessionnel>
      <app-participant-diagnostic-item-professionnel></app-participant-diagnostic-item-professionnel>
    </ng-template>
    <ng-template #diagnosticPresentation>
      <app-participant-diagnostic-item-presentation></app-participant-diagnostic-item-presentation>
    </ng-template>
  </ng-container>

  <ng-template #noResults>
    <app-no-result
      [icon]="icon"
      [addtitle]="addtitle"
      [notitle]="notitle"
      [description]="description"
      (new)="onNew(participant)"
    ></app-no-result>
  </ng-template>
</ng-container>

<ng-template #diagnosticTitleForm>
  <app-header-title [icon]="icon" [title]="title"></app-header-title>
</ng-template>
