import { Pipe, PipeTransform } from '@angular/core';
import { fromUnixTime, format } from 'date-fns'
import { fr } from 'date-fns/locale'
import memo from 'memo-decorator'


@Pipe({
  name: 'hours'
})
export class HoursPipe implements PipeTransform {
  @memo()
  transform(value: any, args?: string): any {
    if(!value){
      return ''
    }else {
      const time: number = value['seconds']
      const unix: Date = fromUnixTime(time)
      const newFormat = format(unix, 'p', { locale: fr })
      return newFormat
    }
  }

}
