import {
  trigger,
  transition,
  style,
  query,
  animateChild,
  group,
  animate,
  state,
  stagger,
} from '@angular/animations';

export const slideInAnimation = trigger('routeAnimations', [
  // transition('userDetailsPage <=> organisationDetailsPage', [
  //   style({ position: 'relative' }),
  //   query(
  //     ':enter, :leave',
  //     [
  //       style({
  //         position: 'absolute',
  //         top: 0,
  //         left: 0,
  //         width: '100%',
  //       }),
  //     ],
  //     { optional: true }
  //   ),
  //   query(':enter', [style({ left: '-100%' })], { optional: true }),
  //   query(':leave', animateChild(), { optional: true }),
  //   group([
  //     query(':leave', [animate('300ms ease-out', style({ left: '100%' }))], {
  //       optional: true,
  //     }),
  //     query(':enter', [animate('300ms ease-out', style({ left: '0%' }))], {
  //       optional: true,
  //     }),
  //   ]),
  // ]),
  transition('* <=> *', [
    style({ position: 'relative' }),
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
        }),
      ],
      { optional: true }
    ),
    query(':enter', [style({ left: '-100%' })], { optional: true }),
    query(':leave', animateChild(), { optional: true }),
    group([
      query(
        ':leave',
        [animate('200ms ease-out', style({ left: '100%', opacity: 0 }))],
        { optional: true }
      ),
      query(':enter', [animate('300ms ease-out', style({ left: '0%' }))], {
        optional: true,
      }),
      query('@*', animateChild(), { optional: true }),
    ]),
  ]),
]);

export const fader = trigger('routerFadeAnimations', [
  transition('*<=>*', [
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          left: 0,
          width: '100%',
          opacity: 0,
          transform: 'scale(0) translateY(100%)',
        }),
      ],
      { optional: true }
    ),
    query(
      ':enter',
      [
        animate(
          '60ms ease',
          style({ opacity: 1, transform: 'scale(1) translateY(0)' })
        ),
      ],
      { optional: true }
    ),
  ]),
]);

export const slider = trigger('slider', [
  transition('void => *', [
    style({ position: 'relative' }),
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
        }),
      ],
      { optional: true }
    ),
    query(':enter', [style({ left: '-100%' })], { optional: true }),
    query(':leave', animateChild(), { optional: true }),
    group([
      query(
        ':leave',
        [animate('200ms ease-out', style({ left: '100%', opacity: 0 }))],
        { optional: true }
      ),
      query(':enter', [animate('300ms ease-out', style({ left: '0%' }))], {
        optional: true,
      }),
      query('@*', animateChild(), { optional: true }),
    ]),
  ]),
]);

export const push = trigger('push', [
  transition('void', [
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          left: 0,
          width: '100%',
          opacity: 0,
          transform: 'scale(0) translateY(100%)',
        }),
      ],
      { optional: true }
    ),
    query(
      ':enter',
      [
        animate(
          '60ms ease',
          style({ opacity: 1, transform: 'scale(1) translateY(0)' })
        ),
      ],
      { optional: true }
    ),
  ]),
]);

export const fade = trigger('fade', [
  state(
    'void',
    style({
      opacity: 0,
    })
  ),
  transition(':enter, :leave', [animate(400)]),
]);

export const listAnimation = trigger('listAnimation', [
  transition('* => *', [
    query(':leave', [stagger(100, [animate('0.5s', style({ opacity: 0 }))])], {
      optional: true,
    }),
    query(
      ':enter',
      [
        style({ opacity: 0 }),
        stagger(100, [animate('0.5s', style({ opacity: 1 }))]),
      ],
      { optional: true }
    ),
  ]),
]);

export const itemAnimation = trigger('itemAnimation', [
  transition('void => *', [
    style({
      height: 0,
      opacity: 0,
      transform: 'scale(0.85)',
      'margin-left': 0,
      'padding-left': 0,
      'padding-right': 0,
      'padding-top': 0,
      'padding-bottom': 0,
    }),
    animate(
      '50ms',
      style({
        height: '*',
        'margin-left': '*',
        'padding-left': '*',
        'padding-right': '*',
        'padding-top': '*',
        'padding-bottom': '*',
      })
    ),
    animate(68),
  ]),
]);

export const enterAnimation = trigger('enterAnimation', [
  transition(':enter', [
    style({ transform: 'translateX(100%)', opacity: 0 }),
    animate(
      '500ms',
      style({
        transform: 'translateX(0)',
        opacity: 1,
      })
    ),
  ]),
  transition(':leave', [
    style({ transform: 'translateX(0)', opacity: 1 }),
    animate('500ms', style({ transform: 'translateX(100%)', opacity: 0 })),
  ]),
]);

export const sliderIn = trigger('slideIn', [
  state('*', style({ 'overflow-y': 'hidden' })),
  state('void', style({ 'overflow-y': 'hidden' })),
  transition('* => void', [
    style({ height: '*' }),
    animate(250, style({ height: 0 })),
  ]),
  transition('void => *', [
    style({ height: '0' }),
    animate(250, style({ height: '*' })),
  ]),
]);
