import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  NzTableFilterFn,
  NzTableFilterList,
  NzTableSortFn,
  NzTableSortOrder,
} from 'ng-zorro-antd/table';
import { Observable, of, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-table-participants',
  templateUrl: './table-participants.component.html',
  styleUrls: ['./table-participants.component.scss'],
})
export class TableParticipantsComponent implements OnInit {
  @Input() source$: Observable<any> = of([]);
  @Input() scroll: any = { x: '1100px', y: '100%' };
  @Output() add = new EventEmitter();
  @Output() update = new EventEmitter();
  @Output() delete = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
  onNew(): void {
    this.add.emit(true);
  }
}
