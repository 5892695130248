import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ViewContainerRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { NzModalService } from 'ng-zorro-antd/modal';

import { TodoId } from 'src/app/models/todo.model';
import { OrganisationId } from 'src/app/components/organisation/store/organisation.model';
import { UserState } from 'src/app/components/user/store/user.reducer';
import { TodoState } from '../../store/todo.reducer';
import * as fromTodoAction from '../../store/todo.actions';

@Component({
  selector: 'app-todo-list',
  templateUrl: './todo-list.component.html',
  styleUrls: ['./todo-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TodoListComponent implements OnInit {
  todosUncompleted: boolean = false;
  todosCompleted: boolean = false;
  newDefinition: string = 'Ajouter une tâche à réaliser.';
  noTodoTitle: string = 'Aucune tâche ajoutée';
  green: string = '#73d13d';
  red: string = '#ff4d4f';

  @Input() source: any[] = [];
  @Output() new = new EventEmitter<boolean>(false);
  @Output() select = new EventEmitter<TodoId>(false);
  @Output() delete = new EventEmitter<TodoId>(false);

  constructor(
    private userStore: Store<UserState>,
    private todoStore: Store<TodoState>
  ) {}

  ngOnInit(): void {}

  onSelect(todo: TodoId | any): void {
    this.select.emit(todo);
  }

  show__TODO(todo: TodoId): void {
    this.select.emit(todo);
    this.todoStore.dispatch(fromTodoAction.loadTodo({ todo }));
  }

  onNew(): void {
    this.new.emit(true);
  }

  onDelete(todo: TodoId): void {
    this.delete.emit(todo);
  }
}
