import { OperationGantt, GanttItem } from './../../store/operation-gantt.model';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { OperationId } from './../../../operation/store/operation.model';
import { Subject, Observable, of } from 'rxjs';
import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { OperationGanttState } from '../../store/operation-gantt.reducer';
import * as fromOperationGanttAction from '../../store/operation-gantt.actions';
import * as fromOperationGanttSelector from '../../store/operation-gantt.selectors';
import { differenceInBusinessDays, format } from 'date-fns';
import { floor, multiply, divide, omit } from 'lodash';

@Component({
  selector: 'app-operation-gantt',
  templateUrl: './operation-gantt.component.html',
  styleUrls: ['./operation-gantt.component.scss'],
})
export class OperationGanttComponent implements OnInit, OnDestroy {
  subscribe = new Subject();
  @Input() operation$: Observable<OperationId | any> = of(null);
  gantt$: Observable<any> = of(null);
  gantt: any;
  title = 'OPERATION GANTT';
  new: boolean = false;
  update: boolean = false;
  remove: boolean = false;
  expandable: boolean = true;

  newData: any = null;
  taskShow: boolean = false;
  task: any;

  @ViewChild('operationGanttContainer', { static: false })
  operationGanttContainer: ElementRef | any;

  constructor(private operatinGanttStore: Store<OperationGanttState>) {}

  ngOnInit(): void {
    this.getGantt();
    this.loadGantt();
  }
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getGantt(): void {
    this.gantt$ = this.operatinGanttStore.select(
      fromOperationGanttSelector.gantt
    );
  }
  loadGantt(): void {
    this.operation$.pipe(takeUntil(this.subscribe)).subscribe((operation) => {
      if (!operation) return;
      this.operatinGanttStore.dispatch(
        fromOperationGanttAction.loadOperationGantt({
          operationId: operation.id,
        })
      );
    });
  }

  ganttInit(): void {
    this.gantt$
      .pipe(takeUntil(this.subscribe))
      .subscribe((ganttData: OperationGantt): any => {
        if (!ganttData) {
          return null;
        }

        const { dateStart, dateEnd, actions, modules } = ganttData;
        const actionsSet: any = actions.map((a) => omit(a, '__typename'));
        const modulesSet: any = modules.map((a) => omit(a, '__typename'));

        const tasks = [
          {
            id: 'Task 1',
            name: 'Redesign website',
            start: '2016-12-28',
            end: '2016-12-31',
            progress: 20,
            dependencies: 'Task 2, Task 3',
          },
        ];

        // this.gantt = new Gantt('#gantt', [...modulesSet, ...actionsSet], {
        //   // header_height: 50,
        //   // column_width: 30,
        //   // step: 24,
        //   // view_modes: [...Object.values(VIEW_MODE)],
        //   // bar_height: 20,
        //   // bar_corner_radius: 3,
        //   // arrow_curve: 5,
        //   // padding: 18,
        //   view_mode: 'Month',
        //   //date_format: 'YYYY-MM-DD',
        //   //popup_trigger: 'click',
        //   language: 'fr',
        //   custom_popup_html: function (task: any) {
        //     return `
        //     <div class="details-container">
        //       <h5>${task.name}</h5>
        //       <p class="details-container-estimation">Échéance prévue le ${
        //         task.end
        //       }</p>
        //       <p>${task.progress}% ${task.progress === 100 ? 'Terminé' : ''}</p>
        //     </div>
        //     `;
        //   },

        //   on_click: function (task: any) {
        //     // this.taskShow = true;
        //     // this.task = task;
        //   },
        //   on_date_change: function (task: any, start: any, end: any): any {
        //     const { type } = task;
        //     if (!type) {
        //       return null;
        //     }

        //     if (type === 'module') {
        //       const { id, operationId } = task;
        //     }

        //     if (task === 'action') {
        //       const { id } = task;
        //     }
        //   },

        //   on_progress_change: function (task: any, progress: any): any {
        //     const { type } = task;

        //     if (!type) {
        //       return null;
        //     }

        //     if (type === 'module') {
        //     }

        //     if (task === 'action') {
        //     }
        //   },

        //   on_view_change: function (mode: any) {},
        // });
      });
  }

  on_view_change(view: string): void {
    this.gantt.change_view_mode(view);
  }

  fullScreen(event: any): void {
    let elem = this.operationGanttContainer.nativeElement;
    let methodToBeInvoked =
      elem.requestFullscreen ||
      elem.requestFullscreen ||
      elem['mozRequestFullscreen'] ||
      elem['msRequestFullscreen'];
    if (methodToBeInvoked) methodToBeInvoked.call(elem);
  }

  exportToImage(): void {
    const selector = document.getElementById('gantt');
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    let svgText = 'M10 10 h 80 v 80 h -80 Z';
    let p = new Path2D('M10 10 h 80 v 80 h -80 Z');
    ctx?.stroke(p);
    let url = canvas.toDataURL();
    const img = new Image();
    img.src = url;
  }

  actionsGroup(items: any[]): any[] {
    const actionTimelineSet = items.map((item: any) => item.id);

    return actionTimelineSet;
  }

  distanceTime(start: Date, end: Date): any {
    const distanceToEnd = differenceInBusinessDays(start, end);
    const distanceToNow = differenceInBusinessDays(start, Date.now());
    const progressionFromStart = floor(
      multiply(divide(distanceToNow, distanceToEnd), 100)
    );

    return { distanceToEnd, distanceToNow, progressionFromStart };
  }

  onUpdateModule(
    operationId: string,
    id: string,
    start: Date,
    end: Date,
    progress: number
  ): void {}
}
