import { Injectable } from '@angular/core';
import { gql, Query } from 'apollo-angular';

@Injectable({
  providedIn: 'any',
})
export class QueryNiveauNumeriqueContratGQLService extends Query<Response> {
  document = gql`
    query Referentiel_levelNumerique {
      referentiel_levelNumerique
    }
  `;
}
