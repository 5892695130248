import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { CandidatureFormComponent } from '../candidature-form/candidature-form.component';
import { GeoDistanceCoordonnees } from 'src/app/contents/components/geo-territoire/store/geo-territoire.model';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';

import { Store } from '@ngrx/store';
import { CandidatureExportComponent } from '../candidature-export/candidature-export.component';
import { CandidatureItemComponent } from '../candidature-item/candidature-item.component';
import { CandidatureItemTransfertComponent } from '../candidature-item-transfert/candidature-item-transfert.component';
import { CandidatureItemDocumentsComponent } from '../candidature-item-documents/candidature-item-documents.component';
import { ButtonMenu } from 'src/app/contents/components/buttons/store/button.model';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
@Component({
  selector: 'app-candidature-list',
  templateUrl: './candidature-list.component.html',
  styleUrls: ['./candidature-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CandidatureListComponent implements OnInit, OnChanges, OnDestroy {
  // TODO : <!-- 1/ Transferer de candidat sur d'autres fiches de poste 2/ Copier de candidat
  // sur d'autres fiches de poste 3/ Quid des candidatures non retenues -
  // Candidat intéréssant, en attente etc.. Transférer les candidatas non retenus
  // dans une liste d'attente + indicateur du nombre de candidats en attente -->
  user$: Observable<any> = of(null);
  subscribe = new Subject();
  currentView$ = new BehaviorSubject<string>('display');

  title: string = 'Nouvelle candidature';
  titleDetail: string = 'Information du candidat';
  titleTransfert: string = 'Transferer le candidat';
  titleDocuments: string = 'Documents du candidat';
  titleExport: string = 'Exporter les candidats';

  newAddItemTitle: string = 'Ajouter une candidature';
  noDataTitle: string = 'Aucune candidature ajoutée';
  newItemDefinition: string =
    "Une candidature est le positionnement d'un bénéficiaire/participant à un projet (recrutement/action/projet/atelier, etc.)";

  newCardDefinitionClauses: string =
    "Partie d'une construction formant une unité ou présentant une certaine autonomie";

  deleteCandidatType: string = 'Voulez-vous supprimer ce candidat ?';
  menuNewItems: ButtonMenu[] = [
    {
      icon: '',
      isMatIcon: false,
      title: 'Valider',
      color: '',
      isDivider: true,
    },
    {
      icon: '',
      isMatIcon: false,
      title: 'Sélectioner',
      color: '',
      isDivider: false,
    },
    {
      icon: '',
      isMatIcon: false,
      title: '',
      color: '',
      isDivider: true,
    },
    {
      icon: 'swap_horiz',
      isMatIcon: true,
      title: 'Transférer',
      color: '',
      isDivider: false,
    },
    {
      icon: 'folder_open',
      isMatIcon: true,
      title: 'Documents',
      color: '',
      isDivider: false,
    },
  ];
  @Input() candidats: any[] = [];
  @Input() candidat: any = null;
  @Input() candidatTemplate: TemplateRef<any>;

  @Input() loading$: Observable<boolean> = of(false);
  @Input() fiche: any = null;
  @Input() isNew: boolean = false;

  @Input() adresseMission: GeoDistanceCoordonnees = {
    latitude: 0,
    longitude: 0.0,
  };

  @Output() add = new EventEmitter<any>(false);
  @Output() select = new EventEmitter<any>(false);
  @Output() update = new EventEmitter<any>(false);
  @Output() delete = new EventEmitter<any>(false);
  @Output() deleteAll = new EventEmitter<any>(false);

  @Output() candidatActions = new EventEmitter<any>(false);

  @ViewChild('ficheDePosteCandidatFormTitle', { static: false })
  ficheDePosteCandidatFormTitle: TemplateRef<any> | any;
  @ViewChild('ficheDePosteCandidatDetailFormTitle', { static: false })
  ficheDePosteCandidatDetailFormTitle: TemplateRef<any> | any;
  @ViewChild('ficheDePosteCandidatTransfertFormTitle', { static: false })
  ficheDePosteCandidatTransfertFormTitle: TemplateRef<any> | any;
  @ViewChild('ficheDePosteCandidatDocumentFormTitle', { static: false })
  ficheDePosteCandidatDocumentFormTitle: TemplateRef<any> | any;
  @ViewChild('ficheDePosteCandidatsExportFormTitle', { static: false })
  ficheDePosteCandidatsExportFormTitle: TemplateRef<any> | any;

  @ViewChild('candidatureItemExtraTpl', { static: false })
  candidatureItemExtraTpl: TemplateRef<any> | any;

  modalWidth: number = 850;

  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private userStore: Store<UserState>,
    private drawerService: NzDrawerService
  ) {}

  ngOnInit(): void {
    this.getUser();
  }
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getUser(): void {
    this.user$ = this.userStore.select(fromUserSelector.user);
  }

  onNew(adresseMission: GeoDistanceCoordonnees): void {
    const modal = this.modal.create({
      nzContent: CandidatureFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.ficheDePosteCandidatFormTitle,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
    });
    const instance = modal.getContentComponent();
    instance.adresseMission = adresseMission;
    instance.cancel.subscribe((): any => {
      modal.close();
    });
    instance.add.subscribe((candidat: any): any => {
      if (!candidat) {
        return null;
      } else {
        this.onAdd(candidat);
        modal.close();
      }
    });
    instance.update.subscribe((candidat: any): any => {
      if (!candidat) {
        return null;
      } else {
        this.onUpdate(candidat);
        modal.close();
      }
    });
  }

  onSelect(candidat: any): void {
    if (!candidat) return;
    this.select.emit(candidat);
    const { person } = candidat;
    const { displayName } = person;
    this.titleDetail = displayName;

    const modal = this.modal.create({
      nzContent: CandidatureItemComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.ficheDePosteCandidatDetailFormTitle,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
    });
    const instance = modal.getContentComponent();
    // instance.candidat$.next(candidat);
  }

  onSelectDrawser(candidat: any): void {
    if (!candidat) return;
    this.select.emit(candidat);

    const drawerRef = this.drawerService.create({
      nzTitle: 'Candidat',
      nzExtra: this.candidatureItemExtraTpl
        ? this.candidatureItemExtraTpl
        : null,
      nzContent: this.candidatTemplate,
      nzContentParams: {
        view$: this.currentView$,
        currentView$: this.currentView$,
      },
      nzBodyStyle: { overflow: 'hidden' },
    });

    drawerRef.nzWidth = this.modalWidth;

    drawerRef.afterOpen.subscribe(() => {});

    drawerRef.afterClose.subscribe(() => {});
  }

  onCloseDrawer(): void {}

  onCandidaAction(event: string, candidat: any): void {
    this.candidatActions.emit({ event, candidat });

    switch (event) {
      case 'Annuler':
        this.currentView$.next('display');

        break;
      case 'update':
        this.currentView$.next('form');

        break;
      case 'delete':
        break;

      default:
        this.currentView$.next(event);

        break;
    }
  }

  onTransfertItem(candidat: any): void {
    if (!candidat) return;
    this.select.emit(candidat);
    const modal = this.modal.create({
      nzContent: CandidatureItemTransfertComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.ficheDePosteCandidatTransfertFormTitle,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
    });
    const instance = modal.getContentComponent();
  }
  onDocumentsItem(candidat: any): void {
    if (!candidat) return;
    this.select.emit(candidat);
    const modal = this.modal.create({
      nzContent: CandidatureItemDocumentsComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.ficheDePosteCandidatDocumentFormTitle,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
    });
    const instance = modal.getContentComponent();
  }

  onExportActions(event: string): void {}

  onAdd(candidat: any): void {
    if (!candidat) return;
    this.add.emit(candidat);
  }
  onUpdate(candidat: any): void {
    if (!candidat) return;
    this.update.emit(candidat);
  }
  onDelete(candidat: any): void {
    if (!candidat) return;
    this.delete.emit(candidat);
  }
  onDeleteAll(): void {
    this.deleteAll.emit(true);
  }

  onExport(event: string): void {}

  onExportPDF(): void {
    const modal = this.modal.create({
      nzContent: CandidatureExportComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.ficheDePosteCandidatFormTitle,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
    });
    const instance = modal.getContentComponent();
    instance.cancel.subscribe((): any => {
      modal.close();
    });
    instance.export.subscribe((candidat: any): any => {
      if (!candidat) {
        return null;
      } else {
        modal.close();
      }
    });
  }
  onExportExel(): void {
    const modal = this.modal.create({
      nzContent: CandidatureExportComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.ficheDePosteCandidatsExportFormTitle,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
    });
    const instance = modal.getContentComponent();
    instance.cancel.subscribe((): any => {
      modal.close();
    });
    instance.export.subscribe((candidat: any): any => {
      if (!candidat) {
        return null;
      } else {
        modal.close();
      }
    });
  }
  onExcelWord(): void {
    const modal = this.modal.create({
      nzContent: CandidatureExportComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.ficheDePosteCandidatFormTitle,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
    });
    const instance = modal.getContentComponent();
    instance.cancel.subscribe((): any => {
      modal.close();
    });
    instance.export.subscribe((candidat: any): any => {
      if (!candidat) {
        return null;
      } else {
        modal.close();
      }
    });
  }

  onDeleteCancel(): void {}
}
