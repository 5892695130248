import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TodosService } from 'src/app/service/todos.service';
import { TodoState } from './todo.reducer';
import { Store, select } from '@ngrx/store';
import { catchError, mergeMap, map, tap } from 'rxjs/operators';
import * as fromTodoAction from './todo.actions';
import { of } from 'rxjs';
import {
  NzNotificationPlacement,
  NzNotificationService,
} from 'ng-zorro-antd/notification';

const NOTIFICATION_PLACEMENT: NzNotificationPlacement = 'bottomRight';
@Injectable()
export class TodoEffects {
  loadTodos$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromTodoAction.loadTodos),
      mergeMap(() =>
        this.todoService.getAll().pipe(
          map((respons: any) => {
            const todos: any[] = respons.data.todos;
            return fromTodoAction.loadTodosSuccess({ todos });
          }),
          catchError((error) => of(fromTodoAction.loadTodosFailure({ error })))
        )
      ),
      tap(() =>
        this.todoStore.dispatch(
          fromTodoAction.loadTodosLoading({ event: false })
        )
      )
    )
  );

  loadCompletedTodos$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromTodoAction.loadCompletedTodos),
      mergeMap(() =>
        this.todoService.getAllCompleted().pipe(
          map((respons: any) => {
            const todos: any[] = respons.data.completedTodos;
            return fromTodoAction.loadCompletedTodosSuccess({ todos });
          }),
          catchError((error) =>
            of(fromTodoAction.loadCompletedTodosFailure({ error }))
          )
        )
      ),
      tap(() =>
        this.todoStore.dispatch(
          fromTodoAction.loadTodosLoading({ event: false })
        )
      )
    )
  );

  loadTodo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromTodoAction.loadTodo),
      mergeMap((action) =>
        this.todoService.getOne(action.todo.id).pipe(
          map((respons: any) => {
            const todo: any = respons.data.todo;
            return fromTodoAction.loadTodoSuccess({ todo });
          }),
          catchError((error) => of(fromTodoAction.loadTodoFailure({ error })))
        )
      )
    )
  );

  addTodo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromTodoAction.addTodo),
      tap(() =>
        this.todoStore.dispatch(fromTodoAction.addTodoAdding({ event: true }))
      ),
      mergeMap((action) =>
        this.todoService.addOne(action.todo).pipe(
          map((respons: any) => {
            const todo: any = respons.data.createTodo;
            return fromTodoAction.addTodoSuccess({ todo });
          }),
          catchError((error) => {
            this.onErrorNotification(error, 'CREATE_TODO');

            return of(fromTodoAction.addTodoFailure({ error }));
          })
        )
      ),
      tap(() =>
        this.todoStore.dispatch(fromTodoAction.addTodoAdding({ event: false }))
      )
    )
  );

  completedTodo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromTodoAction.completedTodo),
        mergeMap((action) =>
          this.todoService.completeOne(action.todo.changes.id).pipe(
            map((respons: any) => {
              const todo: string = respons.data.compled;

              return fromTodoAction.completedTodoSuccess();
            }),
            catchError((error) => {
              this.onErrorNotification(error, 'COMPLETE_TODO');

              return of(fromTodoAction.completedTodoFailure({ error }));
            })
          )
        )
      ),
    { dispatch: false }
  );

  updateTodo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromTodoAction.updateTodo),
        mergeMap((action) =>
          this.todoService.updateOne(action.todo.changes).pipe(
            map((respons: any) => {
              const todo: any = respons.data.updateTodo;
              return fromTodoAction.updateTodoSuccess({ todo });
            }),
            catchError((error) => {
              this.onErrorNotification(error, 'UPDATE_TODO');

              return of(fromTodoAction.updateTodoFailure({ error }));
            })
          )
        )
      ),
    { dispatch: false }
  );

  deleteTodo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromTodoAction.deleteTodo),
        mergeMap((action) =>
          this.todoService.deleteOne(action.id).pipe(
            map((respons: any) => {
              const success: string = respons.data.deleteTodo;

              this.notification.success('', success, {
                nzDuration: 8000,
                nzAnimate: true,
                nzPlacement: NOTIFICATION_PLACEMENT,
                nzKey: 'DELETE_TODO',
              });
              this.todoStore.dispatch(fromTodoAction.clearTodo());
              return fromTodoAction.deleteTodoSuccess({ success });
            }),
            catchError((error) => {
              this.onErrorNotification(error, 'DELETE_TODO');

              return of(fromTodoAction.deleteTodoFailure({ error }));
            })
          )
        )
      ),
    { dispatch: false }
  );

  deleteTodos$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromTodoAction.deleteTodos),
        mergeMap((action) =>
          this.todoService.deleteAll().pipe(
            map((respons: any) => {
              const success: string = respons.data.deleteTodos;
              this.notification.success('', success, {
                nzDuration: 8000,
                nzAnimate: true,
                nzPlacement: NOTIFICATION_PLACEMENT,
                nzKey: 'DELETE_COMPLETED_TODOS',
              });

              this.todoStore.dispatch(fromTodoAction.clearTodo());
              return fromTodoAction.deleteTodosSuccess({ success });
            }),
            catchError((error) => {
              this.onErrorNotification(error, 'DELETE_TODOS');

              return of(fromTodoAction.deleteTodosFailure({ error }));
            })
          )
        )
      ),
    { dispatch: false }
  );

  deleteCompletedTodos$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromTodoAction.deleteCompletedTodos),
        mergeMap((action) =>
          this.todoService.deleteAllCompleted().pipe(
            map((respons: any) => {
              const success: string = respons.data.deleteCompletedTodos;
              this.notification.success('', success, {
                nzDuration: 8000,
                nzAnimate: true,
                nzPlacement: NOTIFICATION_PLACEMENT,
                nzKey: 'DELETE_COMPLETED_TODOS',
              });

              this.todoStore.dispatch(fromTodoAction.clearTodo());
              return fromTodoAction.deleteCompletedTodosSuccess({ success });
            }),
            catchError((error) => {
              this.onErrorNotification(error, 'DELETE_COMPLETED_TODOS');
              return of(fromTodoAction.deleteCompletedTodosFailure({ error }));
            })
          )
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private todoService: TodosService,
    private todoStore: Store<TodoState>,
    private notification: NzNotificationService
  ) {}

  onErrorNotification(error: any, key: string): void {
    this.notification.error('Error', error.message, {
      nzDuration: 10000,
      nzAnimate: true,
      nzPlacement: NOTIFICATION_PLACEMENT,
      nzKey: key ? key : '',
    });
  }
}
