<nz-card
  nzHoverable
  (click)="$event.stopPropagation(); onSelect(operation, item)"
  [nzTitle]="op_entite_card_title"
  [nzExtra]="
    (user$ | async | isAdmin) || (user$ | async | isDev)
      ? op_entite_card_extra
      : op_entite_card_extra_noAdmin
  "
>
  <ng-template #op_entite_card_title>
    <app-header-title [icon]="'person'"></app-header-title>
  </ng-template>
  <ng-template #op_entite_card_extra>
    <app-button-dropdown
      *ngIf="(user$ | async | isAdmin) || (user$ | async | isDev)"
      [isDelete]="true"
      [deleteType]="'Voulez-vous retirer le collaborateur du projet ?'"
      (select)="onAction($event, operation, item)"
    ></app-button-dropdown>
  </ng-template>
  <ng-template #op_entite_card_extra_noAdmin> </ng-template>

  <nz-dropdown-menu #op_entite_Actions="nzDropdownMenu">
    <ul nz-menu nzSelectable>
      <li nz-menu-item>Exporter le bilan</li>
      <li
        nz-menu-item
        nzDanger
        *ngIf="operation"
        nz-popconfirm
        [nzPopconfirmTitle]="'Supprimer ce collaborateur ?'"
        nzPopconfirmPlacement="bottom"
        (nzOnConfirm)="onDelete(operation, item)"
        (nzOnCancel)="onCancel()"
      >
        Supprimer
      </li>
    </ul>
  </nz-dropdown-menu>

  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="4">
      <nz-avatar
        [nzSize]="'large'"
        [nzText]="item?.user?.avatar"
        [ngStyle]="{
          'background-color': item?.user?.setting?.color
            ? item?.user?.setting?.color
            : bgColor,
          color: '#fff'
        }"
      ></nz-avatar>
    </nz-col>
    <nz-col nzSpan="2"></nz-col>
    <nz-col nzSpan="18">
      <h5 nz-typography>{{ item?.user?.displayName | carLimitation: 25 }}</h5>
    </nz-col>
  </nz-row>
  <nz-row [nzGutter]="4" nzJustify="center" nzAlign="middle">
    <nz-col [nzSpan]="4"> </nz-col>
    <nz-col nzSpan="2"></nz-col>
    <nz-col [nzSpan]="18">
      <small *ngIf="!item?.isAdmin">Rôle : {{ item?.role | titlecase }}</small>
      <small *ngIf="item?.isAdmin">Responsable projet</small>
    </nz-col>
  </nz-row>

  <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
  <nz-row [nzGutter]="4" nzJustify="center" nzAlign="middle">
    <nz-col [nzSpan]="12">
      <nz-tag *ngIf="item?.canWrite" nzColor="geekblue">Lecture</nz-tag>
    </nz-col>
    <nz-col [nzSpan]="12">
      <nz-tag *ngIf="item?.canRead" nzColor="geekblue">Écriture</nz-tag>
    </nz-col>
  </nz-row>
</nz-card>
