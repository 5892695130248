import { createAction, props } from '@ngrx/store';

import { SideNavigation } from './side-navigation.model';

export const loadNavigationState = createAction(
  '[SideNavigation/COMPONENT] Load Navigation State'
);
export const loadNavigationStateSuccess = createAction(
  '[SideNavigation/COMPONENT] Load Navigation State Success',
  props<{ state: any }>()
);

export const loadSideNavigation = createAction(
  '[SideNavigation/COMPONENT] Load SideNavigation',
  props<{ navigation: SideNavigation }>()
);

export const selectSideNavigation = createAction(
  '[SideNavigation/COMPONENT] Select SideNavigation',
  props<{ navigation: SideNavigation }>()
);

export const loadSideNavigationSuccess = createAction(
  '[SideNavigation/API] Load SideNavigation Success'
);

export const clearSideNavigations = createAction(
  '[SideNavigation/API] Clear SideNavigations'
);
