import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-redaction-list',
  templateUrl: './redaction-list.component.html',
  styleUrls: ['./redaction-list.component.scss'],
})
export class RedactionListComponent implements OnInit {
  redactions$: Observable<any> = of(null);
  title: string = 'Rédaction';
  definition: string = '';
  description: string = '';

  constructor() {}

  ngOnInit(): void {}

  onNew(): void {}
}
