<div class="w-full divide-y space-y-4">
  <div class="w-1/2 flex items-center justify-center">
    <nz-steps [nzCurrent]="currentStep" nzSize="small">
      <nz-step [nzTitle]="candidatureFormPrescripteurTitleTpl"> </nz-step>
      <ng-template #candidatureFormPrescripteurTitleTpl>
        <p class="text-sm tracking-wider">
          {{
            (candidatureForm.value?.prescripteur?.etablissement?.uniteLegale
              ?.denominationUniteLegale | carLimitation : 30) || "Prescripteur"
          }}
        </p>
      </ng-template>

      <nz-step [nzTitle]="candidatureFormCandidatsTitleTpl"></nz-step>

      <ng-template #candidatureFormCandidatsTitleTpl>
        <div class="w-full flex items-start">
          <p class="text-sm tracking-wider">Candidats</p>
          <nz-badge
            [nzCount]="candidatureForm.value?.candidats?.length"
          ></nz-badge>
        </div>
      </ng-template>
    </nz-steps>
  </div>

  <form nz-form [formGroup]="candidatureForm" class="w-full">
    <div [ngSwitch]="currentStep" class="w-full">
      <div *ngSwitchCase="0">
        <app-person-form
          class="w-full"
          [contactsInput]="currentPrescripteur$ | async"
          [isList]="false"
          [isEtablissement]="true"
          [isRole]="false"
          [isDateNaissance]="false"
          [isEmailRequired]="true"
          [isMobileRequired]="true"
          [isFonction]="true"
          (onCurrentChanges)="onAddPrescripteur($event)"
        ></app-person-form>
      </div>
      <div *ngSwitchCase="1" class="w-full">
        <app-candidature-form-person
          class="w-full"
          [candidatsInput]="currentCandidats$ | async"
          (back)="onPrevStep()"
          (cancel)="onCancel()"
          (currentChanges)="onCandidatsChanges($event)"
          (add)="onAddCandidats($event)"
        ></app-candidature-form-person>
      </div>
      <div *ngSwitchDefault></div>
    </div>
  </form>
  <div
    class="w-full flex items-end p-6 gap-x-2"
    *ngIf="currentStep === 0 || currentStep === 2"
  >
    <div
      [class]="currentStep === 0 || currentStep === 2 ? 'w-6/12' : 'w-6/12'"
    ></div>
    <div class="w-2/12">
      <button class="btn btn-ghost btn-sm" (click)="onCancel()">Annuler</button>
    </div>
    <div class="w-2/12" *ngIf="currentStep > 0">
      <button class="btn btn-sm" (click)="onPrevStep()">Précédent</button>
    </div>
    <div class="w-4/12" *ngIf="currentStep === 0">
      <button
        [class]="
          !candidatureForm.value?.prescripteur?.etablissement?.siret ||
          !candidatureForm.value?.prescripteur?.civilite ||
          !candidatureForm.value?.prescripteur?.lastName ||
          !candidatureForm.value?.prescripteur?.firstName ||
          !candidatureForm.value?.prescripteur?.coordonnees?.mobile ||
          !candidatureForm.value?.prescripteur?.coordonnees?.email ||
          !candidatureForm.value?.prescripteur?.fonction
            ? 'btn btn-sm btn-disabled'
            : 'btn btn-sm btn-primary'
        "
        (click)="onNextStep()"
        [disabled]="
          !candidatureForm.value?.prescripteur?.etablissement?.siret ||
          !candidatureForm.value?.prescripteur?.civilite ||
          !candidatureForm.value?.prescripteur?.lastName ||
          !candidatureForm.value?.prescripteur?.firstName ||
          !candidatureForm.value?.prescripteur?.coordonnees?.mobile ||
          !candidatureForm.value?.prescripteur?.coordonnees?.email ||
          !candidatureForm.value?.prescripteur?.fonction
        "
      >
        Valider le prescripteur
      </button>
    </div>

    <div class="w-4/12" *ngIf="currentStep === 2"></div>
  </div>
</div>
<ng-template #personCardTitle>
  <app-header-title [icon]="'person'"></app-header-title>
</ng-template>
