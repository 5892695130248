import { createAction, props } from '@ngrx/store';
import { OperationSmallId } from 'src/app/components/operation/store/operation.model';

export const loadOperationsSelects = createAction(
  '[OperationsSelect/API] Load OperationsSelects'
);
export const loadOperationsSelectsSuccess = createAction(
  '[OperationsSelect/API] Load OperationsSelects Success',
  props<{ operations: OperationSmallId[] }>()
);
export const loadOperationsSelectsFailure = createAction(
  '[OperationsSelect/API] Load OperationsSelects Failure',
  props<{ error: any }>()
);

export const clearOperationsSelects = createAction(
  '[OperationsSelect/API] Clear OperationsSelects'
);
