import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  EtablissementState,
  entiteSearchesFeatureKey,
  selectAll,
} from './entite-search.reducer';

export const selectSearchState = createFeatureSelector<EtablissementState>(
  entiteSearchesFeatureKey
);

export const etablissementsAll = createSelector(selectSearchState, selectAll);

export const etablissements = createSelector(
  selectSearchState,
  (state: EtablissementState) => state.etablissements
);

export const etablissement = createSelector(
  selectSearchState,
  (state: EtablissementState) => state.etablissement
);

export const header = createSelector(
  selectSearchState,
  (state: EtablissementState) => state.header
);

export const loading = createSelector(
  selectSearchState,
  (state: EtablissementState) => state.loading
);

export const error = createSelector(
  selectSearchState,
  (state: EtablissementState) => state.error
);
