import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DashboardState } from '../../store/dashboard.reducer';
import * as fromDashboardAction from '../../store/dashboard.actions';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-dashboard-filters',
  templateUrl: './dashboard-filters.component.html',
  styleUrls: ['./dashboard-filters.component.scss'],
})
export class DashboardFiltersComponent implements OnInit {
  title: string = 'Tableau de bord';
  selectors: any[] = [
    {
      name: 'Projets',
      select: 'projets',
      icon: '',
      col: 3,
    },
    {
      name: 'Entités',
      select: 'entites',
      icon: '',
      col: 3,
    },
    {
      name: 'Participants',
      select: 'participants',
      icon: '',
      col: 4,
    },
    {
      name: 'Contacts',
      select: 'contacts',
      icon: '',
      col: 3,
    },
  ];
  selected: string = 'projets';

  constructor(private dashboardStore: Store<DashboardState>) {}

  ngOnInit(): void {}

  onSelect(item: string): void {
    this.selected = item;

    this.dashboardStore.dispatch(
      fromDashboardAction.selectDashboards({ select: item })
    );
  }
}
