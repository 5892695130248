import { OperationTeamModule } from './../operation-team/operation-team.module';
import { OperationTaskService } from './../../service/operation-task.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OperationTaskRoutingModule } from './operation-task-routing.module';
import { StoreModule } from '@ngrx/store';
import * as fromOperationTask from './store/operation-task.reducer';
import { EffectsModule } from '@ngrx/effects';
import { OperationTaskEffects } from './store/operation-task.effects';
import { OperationTaskKanbanComponent } from './components/operation-task-kanban/operation-task-kanban.component';
import { OperationTaskItemComponent } from './components/operation-task-item/operation-task-item.component';
import { OperationTaskFormComponent } from './components/operation-task-form/operation-task-form.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ContentsModule } from 'src/app/contents/contents.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { OperationTaskHeaderComponent } from './components/operation-task-header/operation-task-header.component';
import { UserModule } from '../user/user.module';
import { NoResultModule } from 'src/app/contents/components/no-result/no-result.module';
import { CommunesFranceModule } from 'src/app/contents/components/communes-france/communes-france.module';
import { HeaderModule } from 'src/app/contents/components/header-title/header.module';
import { AvatarModule } from 'src/app/features/avatar/avatar.module';

@NgModule({
  declarations: [
    OperationTaskKanbanComponent,
    OperationTaskItemComponent,
    OperationTaskFormComponent,
    OperationTaskHeaderComponent,
  ],
  imports: [
    CommonModule,
    DragDropModule,
    OperationTaskRoutingModule,
    ContentsModule,
    SharedModule,
    OperationTeamModule,
    NoResultModule,
    HeaderModule,
    CommunesFranceModule,
    AvatarModule,
    StoreModule.forFeature(
      fromOperationTask.operationTasksFeatureKey,
      fromOperationTask.reducer
    ),
    EffectsModule.forFeature([OperationTaskEffects]),
  ],
  exports: [
    OperationTaskKanbanComponent,
    OperationTaskItemComponent,
    OperationTaskFormComponent,
    OperationTaskHeaderComponent,
  ],
  providers: [OperationTaskService],
})
export class OperationTaskModule {}
