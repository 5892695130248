import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-operation-cadrage-financeurs',
  templateUrl: './operation-cadrage-financeurs.component.html',
  styleUrls: ['./operation-cadrage-financeurs.component.scss']
})
export class OperationCadrageFinanceursComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
