import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { OperationNoteParticipantId } from './operation-note-participant.model';
import { OperationNoteId } from '../../operation-note/store/operation-note.model';

//LOAD
export const loadOperationNoteParticipants = createAction(
  '[OperationNoteParticipant/COMPONENT] Load OperationNoteParticipants',
  props<{ note: OperationNoteId }>()
);
export const loadOperationNoteParticipantsSuccess = createAction(
  '[OperationNoteParticipant/API] Load OperationNoteParticipants Success',
  props<{ participants: OperationNoteParticipantId[] }>()
);
export const loadOperationNoteParticipantsFailure = createAction(
  '[OperationNoteParticipant/API] Load OperationNoteParticipants Failure',
  props<{ error: any }>()
);

//ADD
export const addOperationNoteParticipant = createAction(
  '[OperationNoteParticipant/COMPONENT] Add OperationNoteParticipant',
  props<{
    note: OperationNoteId;
    participant: OperationNoteParticipantId;
  }>()
);
export const addOperationNoteParticipantSuccess = createAction(
  '[OperationNoteParticipant/API] Add OperationNoteParticipant Success',
  props<{ success: string }>()
);
export const addOperationNoteParticipantFailure = createAction(
  '[OperationNoteParticipant/API] Add OperationNoteParticipant Failure',
  props<{ error: any }>()
);

//UPDATE
export const updateOperationNoteParticipant = createAction(
  '[OperationNoteParticipant/COMPONENT] Update OperationNoteParticipant',
  props<{
    note: OperationNoteId;
    participant: Update<OperationNoteParticipantId>;
  }>()
);
export const updateOperationNoteParticipantSuccess = createAction(
  '[OperationNoteParticipant/API] Update OperationNoteParticipant Success',
  props<{ success: string }>()
);
export const updateOperationNoteParticipantFailure = createAction(
  '[OperationNoteParticipant/API] Update OperationNoteParticipant Failure',
  props<{ error: any }>()
);

//DELETE ONE
export const deleteOperationNoteParticipant = createAction(
  '[OperationNoteParticipant/COMPONENT] Delete OperationNoteParticipant',
  props<{ note: OperationNoteId; id: string }>()
);
export const deleteOperationNoteParticipantSuccess = createAction(
  '[OperationNoteParticipant/API] Delete OperationNoteParticipant Success',
  props<{ success: string }>()
);
export const deleteOperationNoteParticipantFailure = createAction(
  '[OperationNoteParticipant/API] Delete OperationNoteParticipant Failure',
  props<{ error: any }>()
);

//DELETE MANY
export const deleteOperationNoteParticipants = createAction(
  '[OperationNoteParticipant/COMPONENT] Delete OperationNoteParticipants',
  props<{ note: OperationNoteId; ids: string[] }>()
);
export const deleteOperationNoteParticipantsSuccess = createAction(
  '[OperationNoteParticipant/API] Delete OperationNoteParticipants Success',
  props<{ success: string }>()
);
export const deleteOperationNoteParticipantsFailure = createAction(
  '[OperationNoteParticipant/API] Delete OperationNoteParticipants Failure',
  props<{ error: any }>()
);

//CLEAR
export const clearOperationNoteParticipants = createAction(
  '[OperationNoteParticipant/API] Clear OperationNoteParticipants'
);
