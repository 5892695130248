import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { ItemEventComponent } from 'src/app/features/suivi/components/item-event/item-event.component';
import { ItemEvent } from 'src/app/features/suivi/components/item-event/itemEvent.model';

@Component({
  selector: 'app-candidature-item',
  templateUrl: './candidature-item.component.html',
  styleUrls: ['./candidature-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CandidatureItemComponent implements OnInit, OnChanges {
  subscribe = new Subject();
  modalWidth: number = 950;

  @Input() newCardTitle: string = 'Nouveau note';
  @Input() newCardDescription: string = "Ajouter un suivi à l'entité";
  @Input() newCardDefinition: string =
    'Un suivi est une note, une synthèse, un diagnostic suite un échange.';
  @Input() tabIndex: number = 0;
  @Input() currentCandidat: any = null;
  @Input() view$ = new BehaviorSubject('display');
  @Input() candidat$: Observable<any>;
  @Input() notesTpl: TemplateRef<any>;
  @Input() documentTpl: TemplateRef<any>;

  @Output() tabChanges = new EventEmitter(false);

  @Output() action = new EventEmitter(false);
  @Output() update = new EventEmitter(false);

  @ViewChild('suiviTitleForm', { static: false }) suiviTitleForm:
    | TemplateRef<any>
    | any;

  interlocteur = new BehaviorSubject<any>(false);
  eventUpdate = new BehaviorSubject<any>(null);

  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private drawerService: NzDrawerService
  ) {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {}

  onTabChanges(index: number): void {
    this.tabChanges.emit(index);
  }

  onViewChanges(view: string): void {
    this.view$.next(view);
    this.action.emit(view);

    if (view === 'update') {
      this.view$.next('form');
    }
  }

  onCandidatChanges(candidat: any): void {
    this.update.emit(candidat);
  }

  onNewEvent(): void {
    const modal = this.drawerService.create({
      nzContent: ItemEventComponent,
      nzWidth: this.modalWidth,
      nzTitle: this.suiviTitleForm,
      nzClosable: false,
    });
    // const instance = modal.getContentComponent();
    // instance.cancel.subscribe((event): any => {
    //   if (!event) {
    //     return null;
    //   } else {
    //     modal.close();
    //     this.onEventCancel();
    //   }
    // });
    // instance.add.subscribe((suivi: ItemEvent): void => {
    //   if (!suivi) {
    //     return;
    //   } else {
    //     this.onAddEvent(suivi);
    //     modal.close();
    //     this.onEventCancel();
    //   }
    // });
    // instance.update.subscribe((suivi: ItemEvent): void => {
    //   if (!suivi) {
    //     return;
    //   } else {
    //     this.updateEvent(suivi);
    //     modal.close();
    //     this.onEventCancel();
    //   }
    // });
    // instance.interlocteur = this.interlocteur;
    // instance.item = this.eventUpdate;
    // modal.afterClose.subscribe(() => this.onEventCancel());
  }

  onNewDocument(): void {}

  onAddEvent(suivi: any): void {}
  updateEvent(suivi: any): void {}

  onEventCancel(): void {}
}
