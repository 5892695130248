import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';
import { ContratState } from '../../store/contrat.reducer';
import * as fromContratAction from '../../store/contrat.actions';
import * as fromContratSelector from '../../store/contrat.selectors';
import { add, eachDayOfInterval, eachHourOfInterval, getHours } from 'date-fns';
import { Timestamp } from 'firebase/firestore';

@Component({
  selector: 'app-contrat',
  templateUrl: './contrat.component.html',
  styleUrls: ['./contrat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContratComponent implements OnInit, OnChanges, OnDestroy {
  //VARIABLES
  loading$: Observable<boolean> = of(false);
  subscribe = new Subject();
  today: Date = new Date(Date.now());
  dates: Date[] = [this.today, add(this.today, { days: 5 })];
  timeStart: any;
  timeEnd: any;
  timeDefaultOpenValue: Date = this.today;
  typesContrat$: Observable<any[]> = of([]);
  natureContrat$: Observable<any[]> = of([]);
  experienceContrat$: Observable<any[]> = of([]);
  niveauSalaire$: Observable<any[]> = of([]);
  permis$: Observable<any[]> = of([]);
  levelNumerique$: Observable<any[]> = of([]);
  complementSalaire$: Observable<any[]> = of([]);
  typesHoraires$: Observable<any[]> = of([]);
  mobilityGeographic$: Observable<any[]> = of([]);
  contrat_days$: Observable<string[]> = of([]);
  evolutions_types$: Observable<any[]> = of([]);

  dateFormat: string = 'dd/MM/yyyy';

  //FORM ITEM
  contratForm: FormGroup = this.fb.group({});
  typeControl: FormControl<{ code: string; libelle: string }> = new FormControl(
    { code: 'TTI', libelle: 'Contrat travail temporaire insertion' },
    { nonNullable: true }
  );
  natureControl: FormControl<{ code: string; libelle: string }> =
    new FormControl(
      { code: 'I1', libelle: "Insertion par l'activ.éco." },
      { nonNullable: true }
    );
  experienceControl: FormControl<{
    codeTypeCaracteristique: string;
    libelleCaracteristique: string;
  }> = new FormControl(
    {
      codeTypeCaracteristique: 'NIVEXP',
      libelleCaracteristique: 'Non renseigné',
    },
    { nonNullable: true }
  );
  salaireControl = new FormControl<{
    codeTypeCaracteristique: string;
    libelleCaracteristique: string;
  }>(
    { codeTypeCaracteristique: '', libelleCaracteristique: '' },
    Validators.required
  );
  salaireComplement = new FormControl<{ libelle: string; type: string }[]>(
    [
      {
        libelle: 'frais de transport',
        type: 'Véhicule',
      },
    ],
    Validators.required
  );

  permisControl: FormControl<any> = new FormControl<
    { code: string; libelle: string }[]
  >([], Validators.required);
  bureautique_level = new FormControl<string[]>([]);
  startMission = new FormControl<Date>(this.today, Validators.required);
  endMission = new FormControl<Date>(this.today, Validators.required);
  startHours = new FormControl<number>(9, Validators.required);
  endHours = new FormControl<number>(17, Validators.required);
  durationHours = new FormControl<number>(0);
  durationDays = new FormControl<number>(0);

  observations = new FormControl<string>('', Validators.required);
  risques = new FormControl<any[]>([]);
  conventionCollective = new FormControl<any>({});

  zone_deplacement = new FormControl<{
    codeTypeTerritoire: string;
    libelleTypeTerritoire: string;
  }>(
    { codeTypeTerritoire: '', libelleTypeTerritoire: '' },
    Validators.required
  );
  work_price = new FormControl<number>(11.07, Validators.required);
  work_days = new FormControl<string[]>(
    ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi'],
    Validators.required
  );
  pause_dejeuner = new FormControl<number>(1, Validators.required);
  amplitude_horaire = new FormControl<number>(8, Validators.required);
  isDeplacement = new FormControl<boolean>(false, Validators.required);
  isSupplementaires = new FormControl<boolean>(false, Validators.required);
  isAstreinte = new FormControl<boolean>(false, Validators.required);
  suiviInterne = new FormControl<string>('');
  isTeamWork = new FormControl<boolean>(true, Validators.required);
  isReferentPresence = new FormControl<boolean>(true, Validators.required);
  evolution = new FormControl<{ code: string; libelle: string }>({
    code: '',
    libelle: '',
  });
  evolutionCondition = new FormControl<string>('');

  @Input() contrat$ = new BehaviorSubject<any>(null);
  @Output() add = new EventEmitter(false);

  constructor(
    private fb: FormBuilder,
    private contratStore: Store<ContratState>
  ) {}

  ngOnInit(): void {
    this.form();
    this.get_loading();
    this.get_referentiels();
    this.load_referentiels();
    this.onChange();
  }

  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {
    this.subscribe.next(false);
    this.subscribe.complete();
  }

  form(): void {
    this.formInit();
    this.formCreate();
    this.formSet();
  }
  formInit(): void {
    this.typeControl = new FormControl(
      { code: 'TTI', libelle: 'Contrat travail temporaire insertion' },
      { nonNullable: true }
    );
    this.natureControl = new FormControl(
      { code: 'I1', libelle: "Insertion par l'activ.éco." },
      { nonNullable: true }
    );
    this.experienceControl = new FormControl(
      {
        codeTypeCaracteristique: 'NIVEXP',
        libelleCaracteristique: 'Non renseigné',
      },
      { nonNullable: true }
    );
    this.salaireControl = new FormControl<{
      codeTypeCaracteristique: string;
      libelleCaracteristique: string;
    }>(
      { codeTypeCaracteristique: '', libelleCaracteristique: '' },
      Validators.required
    );
    this.permisControl = new FormControl<{ code: string; libelle: string }[]>(
      [],
      Validators.required
    );
    this.startMission = new FormControl<Date>(this.today, Validators.required);
    this.endMission = new FormControl<Date>(this.today, Validators.required);
    this.startHours = new FormControl<number>(9, Validators.required);
    this.endHours = new FormControl<number>(17, Validators.required);

    this.durationHours = new FormControl<number>(0);
    this.durationDays = new FormControl<number>(0);

    this.bureautique_level = new FormControl<string[]>([]);
    this.risques = new FormControl<any>([]);
    this.conventionCollective = new FormControl<any>({});
    this.observations = new FormControl<string>('');
    this.zone_deplacement = new FormControl<{
      codeTypeTerritoire: string;
      libelleTypeTerritoire: string;
    }>(
      { codeTypeTerritoire: '', libelleTypeTerritoire: '' },
      Validators.required
    );
    this.work_price = new FormControl<number>(11.07, Validators.required);
    this.work_days = new FormControl<string[]>(
      ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi'],
      Validators.required
    );
    this.pause_dejeuner = new FormControl<number>(1, Validators.required);
    this.salaireComplement = new FormControl<
      { type: string; libelle: string }[]
    >([], Validators.required);
    this.isDeplacement = new FormControl<boolean>(false, Validators.required);
    this.isSupplementaires = new FormControl<boolean>(
      false,
      Validators.required
    );
    this.isAstreinte = new FormControl<boolean>(false, Validators.required);
    this.amplitude_horaire = new FormControl<number>(8, Validators.required);
    this.suiviInterne = new FormControl<string>('', Validators.required);
    this.isTeamWork = new FormControl<boolean>(true, Validators.required);
    this.isReferentPresence = new FormControl<boolean>(
      false,
      Validators.required
    );
    this.evolution = new FormControl<{ code: string; libelle: string }>({
      code: '',
      libelle: '',
    });
    this.evolutionCondition = new FormControl<string>('');
  }
  formCreate(): void {
    this.contratForm = this.fb.group({
      type: this.typeControl,
      nature: this.natureControl,
      experience: this.experienceControl,
      salaire: this.salaireControl,
      permis: this.permisControl,
      startMission: this.startMission,
      endMission: this.endMission,
      startHours: this.startHours,
      endHours: this.endHours,
      durationDays: this.durationDays,
      durationHours: this.durationHours,

      bureautique: this.bureautique_level,
      observations: this.observations,
      salaireComplement: this.salaireComplement,
      isDeplacement: this.isDeplacement,
      isSupplementaires: this.isSupplementaires,
      risques: this.risques,
      conventionCollective: this.conventionCollective,
      zone_deplacement: this.zone_deplacement,
      work_price: this.work_price,
      work_days: this.work_days,
      pause_dejeuner: this.pause_dejeuner,
      amplitude_horaire: this.amplitude_horaire,
      isAstreinte: this.isAstreinte,
      suiviInterne: this.suiviInterne,
      isTeamWork: this.isTeamWork,
      isReferentPresence: this.isReferentPresence,
      evolution: this.evolution,
      evolutionCondition: this.evolutionCondition,
    });
  }
  formSet(): void {
    this.contrat$.pipe(takeUntil(this.subscribe)).subscribe((contrat) => {
      if (!contrat) return;

      this.formUpdate(contrat);
    });
  }

  formUpdate(contrat: any): void {
    this.contratForm.patchValue({ ...contrat });
  }

  formCompare(item1: any, item2: any): any {
    return item1 === item2;
  }

  get_loading(): void {
    this.loading$ = this.contratStore.select(fromContratSelector.loading);
  }

  get_referentiels(): void {
    this.complementSalaire$ = this.contratStore.select(
      fromContratSelector.complement
    );

    this.levelNumerique$ = this.contratStore.select(
      fromContratSelector.numerique
    );

    this.niveauSalaire$ = this.contratStore.select(
      fromContratSelector.salaires
    );

    this.experienceContrat$ = this.contratStore.select(
      fromContratSelector.experiences
    );

    this.natureContrat$ = this.contratStore.select(fromContratSelector.natures);
    this.typesContrat$ = this.contratStore.select(fromContratSelector.types);
    this.permis$ = this.contratStore.select(fromContratSelector.permis);
    this.typesHoraires$ = this.contratStore.select(
      fromContratSelector.horaires
    );

    this.mobilityGeographic$ = this.contratStore.select(
      fromContratSelector.mobilities
    );
  }

  load_referentiels(): void {
    this.load_naturescontrat();
    this.load_typescontrat();
    this.load_experiencescontrat();
    this.load_salairescontrat();
    this.load_permis();
    this.load_level_numerique();
    this.load_geo_deplacement();
    this.load_complement_salaire();
  }

  load_typescontrat(): void {
    this.contratStore.dispatch(fromContratAction.loadTypesContrats());
  }
  load_naturescontrat(): void {
    this.contratStore.dispatch(fromContratAction.loadNaturesContrats());
  }
  load_experiencescontrat(): void {
    this.contratStore.dispatch(fromContratAction.loadNiveauExperiences());
  }
  load_salairescontrat(): void {
    this.contratStore.dispatch(fromContratAction.loadNiveauSalaire());
  }
  load_permis(): void {
    this.contratStore.dispatch(fromContratAction.loadPermis());
  }
  load_level_numerique(): void {
    this.contratStore.dispatch(fromContratAction.loadLevelNumerique());
  }

  load_geo_deplacement(): void {
    this.contratStore.dispatch(fromContratAction.loadMobilityGeographic());
  }
  load_complement_salaire(): void {
    this.contratStore.dispatch(fromContratAction.loadComplementSalaire());
  }

  onChange(): void {
    this.contratForm.valueChanges
      .pipe(takeUntil(this.subscribe))
      .subscribe((contrat) => {
        if (!this.contratForm.valid && !contrat) {
          return;
        }

        const start = Timestamp.fromDate(contrat.startMission);
        const end = Timestamp.fromDate(contrat.endMission);
        const durationDays: number = eachDayOfInterval({
          start: contrat.startMission,
          end: contrat.endMission,
        })?.length;
        const durationHours: number = eachHourOfInterval({
          start: contrat.startMission,
          end: contrat.endMission,
        })?.length;

        const newContrat = {
          ...contrat,
          startMission: start,
          endMission: end,
          durationDays: durationDays,
          durationHours: durationHours,
        };

        this.add.emit(newContrat);
      });
  }

  onDateRange(dates: Date[]): void {
    if (dates.length) return;
    const start = dates[0];
    const end = dates[1];

    this.contratForm.patchValue({
      startMission: start,
      endMission: end,
    });
  }

  onTimeStart(time: Date): void {
    if (!time) return;
    const hours: number = getHours(time);
    this.contratForm.patchValue({
      startHours: hours,
    });
  }

  onTimeEnd(time: Date): void {
    if (!time) return;
    const hours: number = getHours(time);

    this.contratForm.patchValue({
      endHours: hours,
    });
  }

  onEvolutionChange(event: any): void {
    this.contratForm.patchValue({
      ...event,
    });
  }

  onObservationChange(event: any): void {
    this.contratForm.patchValue({
      observations: event,
    });
  }
  onTravailChange(event: any): void {
    this.contratForm.patchValue({
      ...event,
    });
  }

  onDetailChange(event: any): void {
    this.contratForm.patchValue({
      ...event,
    });
  }

  onCancel(): void {
    this.contratForm.reset();
  }
}
