<ng-container *ngIf="entites$ | async | size; else noEntite">
  <app-item-tabset
    [tabs]="[
      {
        name: 'Par entités',
        template: entitesTemplate
      },
      {
        name: 'Par missions',
        template: entitesByMissionTemplate
      },
      {
        name: 'Sur objectif',
        template: entitesByObjectifTemplate
      }
    ]"
  ></app-item-tabset>

  <ng-template #entitesTemplate>
    <div class="Operation_Dashboard_Container">
      <nz-row nzJustify="center" nzAlign="middle" nzGutter="2">
        <nz-col nzSpan="24">
          <app-charts
            *ngIf="activite_principale$ | async; else noData"
            [source]="activite_principale$"
            [icon]="'query_stats'"
            [isBorderless]="false"
            [isTitle]="true"
          ></app-charts>
        </nz-col>
      </nz-row>
      <nz-space></nz-space>

      <nz-row nzJustify="center" nzAlign="middle" nzGutter="2">
        <nz-col nzSpan="12">
          <app-charts
            *ngIf="categorie$ | async; else noData"
            [source]="categorie$"
            [icon]="'query_stats'"
            [isBorderless]="false"
            [isTitle]="true"
          ></app-charts>
        </nz-col>
        <nz-col nzSpan="12">
          <app-charts
            *ngIf="grandDomaine_libelle$ | async; else noData"
            [source]="grandDomaine_libelle$"
            [icon]="'query_stats'"
            [isBorderless]="false"
            [isTitle]="true"
          ></app-charts>
        </nz-col>
      </nz-row>
      <nz-space></nz-space>

      <nz-row nzJustify="center" nzAlign="middle" nzGutter="2">
        <nz-col nzSpan="12"> </nz-col>
      </nz-row>
    </div>
  </ng-template>

  <ng-template #entitesByMissionTemplate>
    <div class="Operation_Dashboard_Container"></div>
  </ng-template>
  <ng-template #entitesByObjectifTemplate>
    <div class="Operation_Dashboard_Container"></div>
  </ng-template>
</ng-container>

<ng-template #noData>
  <app-no-result
    [icon]="'query_stats'"
    [isButton]="false"
    [title]="''"
  ></app-no-result>
</ng-template>
<ng-template #noEntite>
  <app-no-result
    [icon]="'query_stats'"
    [isButton]="false"
    [title]="'aucune entite'"
    [description]="'Aucune entite'"
  ></app-no-result>
</ng-template>
