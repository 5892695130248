<ng-container *ngIf="participant">
  <div [ngSwitch]="view">
    <div *ngSwitchCase="'list'">
      <nz-page-header
        class="page-header"
        [nzTitle]="header"
        [nzSubtitle]="cardExtra"
        *ngIf="projetsProfessionnels$ | async | size"
      >
        <nz-page-header-content> </nz-page-header-content>
        <nz-page-header-extra>
          <app-button-dropdown [isDelete]="true"></app-button-dropdown>
        </nz-page-header-extra>
      </nz-page-header>
      <nz-divider
        nzOrientation="center"
        nzType="horizontal"
        *ngIf="projetsProfessionnels$ | async | size"
      ></nz-divider>

      <ng-template #header>
        <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
          <nz-col nzSpan="24">
            <h4 nz-typography>{{ titlePage }}</h4>
          </nz-col>
        </nz-row>
      </ng-template>
      <ng-template #cardExtra>
        <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
          <nz-col nzSpan="24">
            <nz-badge
              [nzCount]="projetsProfessionnels$ | async | size"
              [nzOverflowCount]="10"
            >
            </nz-badge>
          </nz-col>
        </nz-row>
      </ng-template>

      <app-layout-list
        *ngIf="projetsProfessionnels$ | async | size; else noResults"
        [new]="new"
        [card]="card"
        [source]="projetsProfessionnels$ | async"
      ></app-layout-list>
    </div>
    <div *ngSwitchCase="'details'">
      <app-projet-professionnel-item
        [projetprofessionnel]="projetProfessionnel$ | async"
        [projetprofessionnel$]="projetProfessionnel$"
        [participant]="participant"
        (back)="onBack()"
        (delete)="onDelete($event, participant)"
        (export)="onExport($event, participant)"
      ></app-projet-professionnel-item>
    </div>
    <div *ngSwitchDefault>
      <app-layout-list
        *ngIf="projetsProfessionnels$ | async | size; else noResults"
        [new]="new"
        [card]="card"
        [source]="projetsProfessionnels$ | async"
      ></app-layout-list>
    </div>
  </div>

  <ng-template #new>
    <app-card-new
      [type]="'business'"
      [title]="newCardTitle"
      [description]="''"
      [definition]="newCardDefinition"
      (add)="onNew(participant)"
      (back)="onBack()"
      (cancel)="onBack()"
    ></app-card-new>
  </ng-template>

  <ng-template #card let-item let-position="idx">
    <app-projet-professionnel-card
      [item]="item"
      (delete)="onDelete($event, participant)"
      (click)="$event.stopPropagation(); onSelect(item, participant)"
    ></app-projet-professionnel-card>
  </ng-template>

  <ng-template #noResults>
    <app-no-result
      [icon]="'business_center'"
      [addtitle]="'Ajouter un projet professionnel'"
      [notitle]="'Aucun projet professionnel'"
      [description]="newCardDefinition"
      (new)="onNew(participant)"
    ></app-no-result>
  </ng-template>

  <ng-template #projetProfessionnelTitleForm>
    <app-header-title
      [icon]="'business_center'"
      [title]="title"
    ></app-header-title>
  </ng-template>
</ng-container>
