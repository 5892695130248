import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ProjetPrpfessionnelState } from '../../store/projet-professionnel.reducer';
import * as fromProjetProAction from '../../store/projet-professionnel.actions';
import * as fromProjetProSelector from '../../store/projet-professionnel.selectors';
import { Store } from '@ngrx/store';
import { of, Observable, takeUntil, Subject, BehaviorSubject } from 'rxjs';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ProjetProfessionnelFormationsFormComponent } from '../projet-professionnel-formations-form/projet-professionnel-formations-form.component';
import { ParticipantId } from 'src/app/components/participant/store/participant.model';

@Component({
  selector: 'app-projet-professionnel-formations',
  templateUrl: './projet-professionnel-formations.component.html',
  styleUrls: ['./projet-professionnel-formations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjetProfessionnelFormationsComponent
  implements OnInit, OnChanges, OnDestroy
{
  loading$: Observable<boolean> = of(false);
  formations$: Observable<any> = of(null);
  subscribe = new Subject();
  currentFilter = new BehaviorSubject<any>(null);
  modalWidth: number = 960;
  title: string = 'Nouvelle formation';
  view$ = new BehaviorSubject<string>('list');
  currentFormation$ = new BehaviorSubject<any>(null);
  newCardTitle: string = 'Nouveau projet professionnel';
  newCardDescription: string = 'Ajouter un projet professionnel au participant';
  newCardDefinition: string =
    "Un projet professionnel est un processus d'accompagnement d'un participant vers son employabilité.";

  @ViewChild('projetProfessionnelFormationForm', { static: false })
  projetProfessionnelFormationForm: TemplateRef<any> | any;

  @Input() projetprofessionnel$ = new BehaviorSubject<any>(null);
  @Input() participant: ParticipantId | any = null;

  constructor(
    private projetProfessionnelStore: Store<ProjetPrpfessionnelState>,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.getLoading();
    this.getFormations();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadFormations(changes.participant.currentValue);
  }

  getLoading(): void {
    this.loading$ = this.projetProfessionnelStore.select(
      fromProjetProSelector.loading
    );
  }
  getFormations(): void {
    this.formations$ = this.projetProfessionnelStore.select(
      fromProjetProSelector.formations
    );
  }

  loadFormations(participant: ParticipantId): void {
    if (!participant) return;
    this.projetprofessionnel$
      .pipe(takeUntil(this.subscribe))
      .subscribe((projetPro: any) => {
        if (!projetPro) return;

        this.projetProfessionnelStore.dispatch(
          fromProjetProAction.LoadFormations({
            participantId: participant.id,
            projetpro: projetPro,
          })
        );
      });
  }

  onSelect(formation: any): void {
    this.currentFormation$.next(formation);
    this.view$.next('details');
  }

  onBack(): void {
    this.currentFormation$.next(null);
    this.view$.next('list');
  }

  onNew(participant: any, projet: any): void {
    if (!participant || !projet) return;
    const modal = this.modal.create({
      nzContent: ProjetProfessionnelFormationsFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.projetProfessionnelFormationForm,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });
    const instance = modal.getContentComponent();
    instance.item.next(projet);
    instance.formations$ = of(null);
    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
    instance.add.subscribe((formation): any => {
      if (!formation) {
        return null;
      } else {
        this.onAdd(participant, projet, formation);
        modal.close();
      }
    });
  }

  onAdd(participant: any, projet: any, formation: any): void {
    if (!participant || !projet || !formation) return;
    this.projetProfessionnelStore.dispatch(
      fromProjetProAction.addFormation({
        participantId: participant.id,
        projetpro: projet,
        formation: formation,
      })
    );
  }

  onUpdate(participant: any, projet: any, formation: any): void {
    if (!participant || !projet || !formation) return;
  }

  onDelete(formation: any, participant: any, projet: any): void {
    if (!participant || !projet || !formation) return;
    this.projetProfessionnelStore.dispatch(
      fromProjetProAction.deleteFormation({
        participantId: participant.id,
        projetpro: projet,
        id: formation.id,
      })
    );
  }

  onCancel(): void {
    return;
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }
}
