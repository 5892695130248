import { ContactId } from './../../store/contact.model';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ContactState } from '../../store/contact.reducer';
import * as fromContactSelector from '../../store/contact.selectors';
import { Observable, of } from 'rxjs';
import * as sideNavigationSelector from 'src/app/components/side-navigation/store/side-navigation.selectors';
import * as sideNavigationAction from 'src/app/components/side-navigation/store/side-navigation.actions';
import { SideNavigationState } from 'src/app/components/side-navigation/store/side-navigation.reducer';
import { SideNavigation } from 'src/app/components/side-navigation/store/side-navigation.model';

@Component({
  selector: 'app-contact-item-header',
  templateUrl: './contact-item-header.component.html',
  styleUrls: ['./contact-item-header.component.scss'],
})
export class ContactItemHeaderComponent implements OnInit {
  contact$: Observable<ContactId | any> = of(null);
  constructor(
    private contactStore: Store<ContactState>,
    private sideNavigationStore: Store<SideNavigationState>
  ) {}

  ngOnInit(): void {
    this.getContact();
  }

  getContact(): void {
    this.contact$ = this.contactStore.select(fromContactSelector.contact);
  }

  onSelectAction(type: string, contact: ContactId): void {
    if (!type || !contact) return;

    switch (type) {
      case 'details':
        const navigation: SideNavigation = {
          type: 'contact',
          name: 'details',
          item: contact,
        };
        this.sideNavigationStore.dispatch(
          sideNavigationAction.loadSideNavigation({ navigation })
        );
        break;
      case 'dashboard':
        break;
      case 'download':
        break;
      case 'share':
        break;

      default:
        break;
    }
  }
}
