import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { SuiviItemComponent } from '../suivi-item/suivi-item.component';
import { ItemEventComponent } from '../item-event/item-event.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { takeUntil } from 'rxjs/operators';
import { format } from 'date-fns';
import { ItemEvent, ItemEventId } from '../item-event/itemEvent.model';
import { BehaviorSubject, Subject, Observable, of } from 'rxjs';
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
  TemplateRef,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import fromUnixTime from 'date-fns/fromUnixTime';
import { fr } from 'date-fns/locale';
import { sortBy, last, first, uniqWith, isEqual } from 'lodash';
import { NzTableComponent } from 'ng-zorro-antd/table';
import {
  NzTableFilterFn,
  NzTableFilterList,
  NzTableSortFn,
  NzTableSortOrder,
} from 'ng-zorro-antd/table';
import { enterAnimation, listAnimation } from 'src/app/app-animation';
import { SelectType } from 'src/app/models/select.display.model';

interface ColumnItem {
  name: string;
  sortOrder: NzTableSortOrder | null;
  sortFn: NzTableSortFn<ItemEventId> | null;
  listOfFilter: NzTableFilterList;
  filterFn: NzTableFilterFn<ItemEventId> | null;
  filterMultiple: boolean;
  sortDirections: NzTableSortOrder[] | any;
  width?: string | any;
}

@Component({
  selector: 'app-table-suivis',
  templateUrl: './table-suivis.component.html',
  styleUrls: ['./table-suivis.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [enterAnimation, listAnimation],
})
export class TableSuivisComponent implements OnInit, OnChanges, OnDestroy {
  listOfColumns: ColumnItem[] = [];

  //FILTERS
  filterByTypes: any = [];
  filterByTypeFn = (list: string[], item: any) =>
    list.some((status) => item.type === status);

  //SORTS

  sortFnByDate = (a: any, b: any) =>
    a.dateStart['seconds'] - b.dateStart['seconds'];

  @Input() scroll: any = { x: '1100px', y: '100%' };
  @Input() currentSuivi$ = new BehaviorSubject<ItemEvent | any>(null);
  @Input() displayView$ = new BehaviorSubject<string>('table');

  @Input() newCardTitle: string = 'Nouveau suivi';
  @Input() newCardDescription: string = "Ajouter un suivi à l'entité";
  @Input() newCardDefinition: string =
    'Un suivi est une note, une synthèse, un diagnostic suite un échange.';

  @Input() source: ItemEventId[] | any = [];
  @Input() messageAlert: string = 'Voulez-vous supprimer ce suivi ?';
  @Input() isCanAdd: boolean = true;
  @Input() view$ = new BehaviorSubject<string>('table');
  @Input() selectTyped: SelectType = SelectType.SIDE;
  @Input() sizeItem: string = '180px';
  @Output() add = new EventEmitter<ItemEventId>(false);
  @Output() update = new EventEmitter<ItemEventId>(false);
  @Output() delete = new EventEmitter<any>(false);
  @Output() deleteAll = new EventEmitter<any>(false);

  @Output() select = new EventEmitter<any>(false);

  subscribe = new Subject();
  modalWidth: number = 950;

  newDocument = false;
  newEvent = false;
  itemDisplay = false;
  updateAcitivity = false;

  eventTextSelect: any = null;
  eventTextBesoin: any = null;
  eventTextPreconisation: any = null;
  eventTextRealisation: any = null;
  eventUpdate = new BehaviorSubject<any>(null);
  interlocteur = new BehaviorSubject<any>(false);

  @ViewChild('virtualTable', { static: false })
  nzTableComponent?: NzTableComponent<ItemEventId>;

  @ViewChild('suiviTitleForm', { static: false }) suiviTitleForm:
    | TemplateRef<any>
    | any;

  scrollToIndex(index: number): void {
    this.nzTableComponent?.cdkVirtualScrollViewport?.scrollToIndex(index);
  }

  trackByIndex(_: number, data: ItemEventId): string {
    return data.id;
  }

  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private drawerService: NzDrawerService
  ) {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {
    this.onSetFilter(changes?.source?.currentValue);
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  onSetFilter(source: any): void {
    if (!source?.length) return;

    const types = source.map((el: any) => {
      const item = el?.type;
      return {
        text: item,
        value: item,
      };
    });
    this.filterByTypes = types;

    const titleFilters = source?.lenght
      ? source.map((el: any) => {
          const { title } = el;
          return { text: title, value: title };
        })
      : [];
    this.listOfColumns = [
      {
        name: 'Type',
        sortOrder: null,
        sortFn: null,
        sortDirections: ['ascend', 'descend', null],
        filterMultiple: true,
        listOfFilter: [],
        filterFn: null,
      },
      {
        name: 'Titre',
        sortOrder: null,
        sortFn: null,
        sortDirections: ['ascend', 'descend', null],
        filterMultiple: true,
        listOfFilter: titleFilters,
        filterFn: null,
      },
      {
        name: 'Date',
        sortOrder: null,
        sortFn: null,
        sortDirections: ['ascend', 'descend', null],
        filterMultiple: true,
        listOfFilter: [],
        filterFn: null,
      },
      {
        name: 'Référent',
        sortOrder: null,
        sortFn: null,
        sortDirections: ['ascend', 'descend', null],
        filterMultiple: true,
        listOfFilter: [],
        filterFn: null,
      },
      {
        name: '',
        sortOrder: null,
        sortFn: null,
        sortDirections: [],
        filterMultiple: false,
        listOfFilter: [],
        filterFn: null,
      },
    ];
  }

  onSelect(suivi: ItemEventId, selectType: SelectType): void {
    this.itemDisplay = true;
    this.newEvent = false;
    this.eventUpdate.next(suivi);
    this.newCardTitle = `Suivi`;

    if (selectType === SelectType.SIDE) {
      this.onSelectTypeSide(suivi);
    }

    if (selectType === SelectType.MODAL) {
      this.onSelectTypeModal(suivi);
    }

    if (selectType === SelectType.OVERLAY) {
      this.onselectTypeOverlay(suivi);
    }
  }

  onSelectTypeModal(suivi: any): void {
    const modal = this.modal.create({
      nzContent: SuiviItemComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.suiviTitleForm,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
    });
    const instance = modal.getContentComponent();
    instance.suivi = suivi;
    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
        this.onEventCancel();
      }
    });
    instance.update.subscribe((suivi: ItemEventId): void => {
      if (!suivi) {
        return;
      } else {
        this.onUpdateEvent(suivi);
        modal.close();
        this.onEventCancel();
      }
    });
    modal.afterClose.subscribe(() => this.onEventCancel());
  }

  onSelectTypeSide(suivi: any): void {
    const drawser = this.drawerService.create({
      nzContent: SuiviItemComponent,
      nzWidth: this.modalWidth,
      nzTitle: this.suiviTitleForm,
      nzContentParams: {
        suivi,
        size: this.sizeItem,
      },
      nzClosable: false,
    });
  }

  onselectTypeOverlay(suivi: any): void {
    this.currentSuivi$.next(suivi);
    this.displayView$.next('suivi');
  }

  onSelectDropdownHeader(event: string): void {
    if (!event) return;

    switch (event) {
      case 'deleteAll':
        this.deleteAll.emit(true);

        break;
      default:
        break;
    }
  }

  onSelectDropdown(event: string, item: any): void {
    if (!event || !item) return;
    switch (event) {
      case 'delete':
        this.delete.emit(item);

        break;

      default:
        break;
    }
  }

  onDelete(event: any): void {
    this.delete.emit(event);
  }

  onAddEvent(suivi: any): void {
    if (suivi) {
      this.add.emit(suivi);
      this.newEvent = false;
      this.eventUpdate.next(null);
    }
  }

  updateEvent(suivi: any): void {
    if (!suivi) {
      this.onCancel();
    }

    this.update.emit(suivi);
    this.newEvent = false;
    this.eventUpdate.next(null);
    this.onCancel();
  }

  onUpdateEvenement(event: any): void {
    this.onNewEvent();
  }

  onUpdateEvenementTable(event: any): void {
    this.itemDisplay = false;
    this.newEvent = true;
  }

  onEventCancel(): void {
    this.newEvent = false;
    this.eventUpdate.next(null);
  }

  onCloseEvenement(event: any): void {
    this.itemDisplay = false;
    this.newEvent = false;
  }

  onNewEvent(): void {
    const modal = this.modal.create({
      nzContent: ItemEventComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.suiviTitleForm,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });
    const instance = modal.getContentComponent();
    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
        this.onEventCancel();
      }
    });
    instance.add.subscribe((suivi: ItemEvent): void => {
      if (!suivi) {
        return;
      } else {
        this.onAddEvent(suivi);
        modal.close();
        this.onEventCancel();
      }
    });
    instance.update.subscribe((suivi: ItemEvent): void => {
      if (!suivi) {
        return;
      } else {
        this.updateEvent(suivi);
        modal.close();
        this.onEventCancel();
      }
    });
    instance.interlocteur = this.interlocteur;
    instance.item = this.eventUpdate;
    modal.afterClose.subscribe(() => this.onEventCancel());
  }

  onUpdateEvent(suivi: ItemEventId): void {
    if (!suivi) return;
    const modal = this.modal.create({
      nzContent: ItemEventComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.suiviTitleForm,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });
    const instance = modal.getContentComponent();
    instance.item = suivi;
    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
        this.onEventCancel();
      }
    });
    instance.add.subscribe((suivi: ItemEvent): void => {
      if (!suivi) {
        return;
      } else {
        this.onAddEvent(suivi);
        modal.close();
        this.onEventCancel();
      }
    });
    instance.update.subscribe((suivi: ItemEvent): void => {
      if (!suivi) {
        return;
      } else {
        this.updateEvent(suivi);
        modal.close();
        this.onEventCancel();
      }
    });
    instance.interlocteur = this.interlocteur;
    modal.afterClose.subscribe(() => this.onEventCancel());
  }

  onSetUpdateSuivi(suivi: ItemEvent): void {
    this.displayView$.next('suiviUpdate');
    this.currentSuivi$.next(suivi);
  }

  onCancel() {
    this.displayView$.next('table');
    this.currentSuivi$.next(null);

    return;
  }
}
