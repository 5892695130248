import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  OperationGanttState,
  operationGanttsFeatureKey,
  selectAll,
} from './operation-gantt.reducer';

export const selectOperatioGanttState =
  createFeatureSelector<OperationGanttState>(operationGanttsFeatureKey);

export const gantts = createSelector(selectOperatioGanttState, selectAll);

export const gantt = createSelector(
  selectOperatioGanttState,
  (state: OperationGanttState) => state.gantt
);

export const loading = createSelector(
  selectOperatioGanttState,
  (state: OperationGanttState) => state.loading
);
export const error = createSelector(
  selectOperatioGanttState,
  (state: OperationGanttState) => state.error
);
