import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  GeoTerritoireState,
  geoTerritoiresFeatureKey,
  selectAll,
} from './geo-territoire.reducer';

export const selectGeoTerritoire = createFeatureSelector<GeoTerritoireState>(
  geoTerritoiresFeatureKey
);

export const territoires = createSelector(selectGeoTerritoire, selectAll);

export const regions = createSelector(
  selectGeoTerritoire,
  (state: GeoTerritoireState) => state.regions
);

export const region = createSelector(
  selectGeoTerritoire,
  (state: GeoTerritoireState) => state.region
);

export const departement = createSelector(
  selectGeoTerritoire,
  (state: GeoTerritoireState) => state.departement
);

export const departements = createSelector(
  selectGeoTerritoire,
  (state: GeoTerritoireState) => state.departements
);

export const communes = createSelector(
  selectGeoTerritoire,
  (state: GeoTerritoireState) => state.communes
);

export const loadingRegions = createSelector(
  selectGeoTerritoire,
  (state: GeoTerritoireState) => state.loadingRegions
);
export const loadingDepartements = createSelector(
  selectGeoTerritoire,
  (state: GeoTerritoireState) => state.loadingDepartements
);
export const loadingCommunes = createSelector(
  selectGeoTerritoire,
  (state: GeoTerritoireState) => state.loadingCommunes
);

//DISTANCE
export const loadingDistance = createSelector(
  selectGeoTerritoire,
  (state: GeoTerritoireState) => state.loadingDistance
);
export const distance = createSelector(
  selectGeoTerritoire,
  (state: GeoTerritoireState) => state.distance
);

export const error = createSelector(
  selectGeoTerritoire,
  (state: GeoTerritoireState) => state.error
);
