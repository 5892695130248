import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { OperationFicheCandidatNote } from './operation-fiche-candidat-note.model';

/**
 * charger l'affichage en cours
 */
export const setViewOperationFicheCandidatNotes = createAction(
  '[OperationFicheCandidatNote/API] Set View OperationFicheCandidatNotes',
  props<{ view: string }>()
);

/**
 * Indicateur de chargement des notes
 */
export const loadingOperationFicheCandidatNotes = createAction(
  '[OperationFicheCandidatNote/API] Loading OperationFicheCandidatNotes',
  props<{ loading: boolean }>()
);

/**
 * charger les notes du candidat
 */
export const loadOperationFicheCandidatNotes = createAction(
  '[OperationFicheCandidatNote/API] Load OperationFicheCandidatNotes',
  props<{ operationId: string; ficheId: string; candidatId: string }>()
);
export const loadOperationFicheCandidatNotesSuccess = createAction(
  '[OperationFicheCandidatNote/API] Load OperationFicheCandidatNotes Success',
  props<{ notes: any[] }>()
);

export const loadOperationFicheCandidatNotesFailure = createAction(
  '[OperationFicheCandidatNote/API] Load OperationFicheCandidatNotes Failure',
  props<{ error: any }>()
);

/**
 * charger une note du candidat
 */
export const loadOperationFicheCandidatNote = createAction(
  '[OperationFicheCandidatNote/API] Load OperationFicheCandidatNote',
  props<{
    operationId: string;
    ficheId: string;
    candidatId: string;
    noteId: string;
  }>()
);
export const loadOperationFicheCandidatNoteSuccess = createAction(
  '[OperationFicheCandidatNote/API] Load OperationFicheCandidatNote Success',
  props<{ note: any }>()
);

export const loadOperationFicheCandidatNoteFailure = createAction(
  '[OperationFicheCandidatNote/API] Load OperationFicheCandidatNote Failure',
  props<{ error: any }>()
);

/**
 * Ajouter une nouvelle note au candidat
 */
export const addOperationFicheCandidatNote = createAction(
  '[OperationFicheCandidatNote/API] Add OperationFicheCandidatNote',
  props<{
    operationId: string;
    ficheId: string;
    candidatId: string;
    note: any;
  }>()
);
export const addOperationFicheCandidatNoteSuccess = createAction(
  '[OperationFicheCandidatNote/API] Add OperationFicheCandidatNote Success',
  props<{ note: any }>()
);
export const addOperationFicheCandidatNoteFailure = createAction(
  '[OperationFicheCandidatNote/API] Add OperationFicheCandidatNote Failure',
  props<{ error: any }>()
);

/**
 * Modifier une note
 */
export const updateOperationFicheCandidatNote = createAction(
  '[OperationFicheCandidatNote/API] Update OperationFicheCandidatNote',
  props<{
    operationId: string;
    ficheId: string;
    candidatId: string;
    note: Update<any>;
  }>()
);
export const updateOperationFicheCandidatNoteSuccess = createAction(
  '[OperationFicheCandidatNote/API] Update OperationFicheCandidatNote Success',
  props<{ success: string }>()
);
export const updateOperationFicheCandidatNoteFailure = createAction(
  '[OperationFicheCandidatNote/API] Update OperationFicheCandidatNote Failure',
  props<{ error: any }>()
);

/**
 * Supprimer une note
 */
export const deleteOperationFicheCandidatNote = createAction(
  '[OperationFicheCandidatNote/API] Delete OperationFicheCandidatNote',
  props<{
    operationId: string;
    ficheId: string;
    candidatId: string;
    noteId: string;
  }>()
);
export const deleteOperationFicheCandidatNoteSuccess = createAction(
  '[OperationFicheCandidatNote/API] Delete OperationFicheCandidatNote Success',
  props<{ success: string }>()
);
export const deleteOperationFicheCandidatNoteFailure = createAction(
  '[OperationFicheCandidatNote/API] Delete OperationFicheCandidatNote Failure',
  props<{ error: any }>()
);

/**
 * Supprimer toutes les notes du candidat
 */
export const deleteOperationFicheCandidatNotes = createAction(
  '[OperationFicheCandidatNote/API] Delete OperationFicheCandidatNotes',
  props<{
    operationId: string;
    ficheId: string;
    candidatId: string;
    ids: string[];
  }>()
);
export const deleteOperationFicheCandidatNotesSuccess = createAction(
  '[OperationFicheCandidatNote/API] Delete OperationFicheCandidatNotes Success',
  props<{ success: string }>()
);
export const deleteOperationFicheCandidatNotesFailure = createAction(
  '[OperationFicheCandidatNote/API] Delete OperationFicheCandidatNotes Failure',
  props<{ error: any }>()
);

/**
 * Reinitialiser le store des notes du candidat
 */
export const clearOperationFicheCandidatNotes = createAction(
  '[OperationFicheCandidatNote/API] Clear OperationFicheCandidatNotes'
);
