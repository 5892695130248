import { ContactQuery } from './../../store/contact.model';
import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { ContactState } from '../../store/contact.reducer';
import * as fromContactAction from '../../store/contact.actions';
import * as fromContactSelector from '../../store/contact.selectors';
import { Observable, of } from 'rxjs';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';

@Component({
  selector: 'app-contact-filters',
  templateUrl: './contact-filters.component.html',
  styleUrls: ['./contact-filters.component.scss'],
})
export class ContactFiltersComponent implements OnInit {
  title: string = 'Contacts';

  contacts$: Observable<any> = of(null);
  loading$: Observable<boolean> = of(false);
  filters$: Observable<any> = of(null);
  favoris$: Observable<any> = of([]);
  tags$: Observable<any> = of([]);
  filtersActivated: boolean = false;
  filtersOrders: any[] = [];
  cleaner: boolean = false;

  optionSecteur: any;
  optionCommune: any;
  optionPostcode: any;
  optionFonction: any;
  optionEntite: any;
  optionSexe: any;
  optionCategorie: any;
  optionActivity: any;
  optionDomaines: any;
  optionGrandsDomaines: any;
  optionMetiers: any;
  optionAppelations: any;

  optionYear: any;

  constructor(
    private contactStore: Store<ContactState>,
    private userStore: Store<UserState>
  ) {}

  ngOnInit(): void {
    this.getLoading();

    this.getContacts();
    this.getFilters();
    this.getFavoris();
    this.getTags();
    this.loadFilters();
  }

  getContacts(): void {
    this.contacts$ = this.contactStore.select(fromContactSelector.contacts);
  }

  getFilters(): void {
    this.filters$ = this.contactStore.select(fromContactSelector.filters);
  }
  getLoading(): void {
    this.loading$ = this.contactStore.select(fromContactSelector.loading);
  }

  loadFilters(): void {
    this.contactStore.dispatch(fromContactAction.loadContactsFilters());
  }

  getFavoris(): void {
    this.favoris$ = this.userStore.select(fromUserSelector.favorisContacts);
  }

  getTags(): void {
    this.tags$ = this.userStore.select(fromUserSelector.favorisContacts);
  }

  loadContacts(): void {
    this.filtersActivated = false;
    this.cleaner = false;
    this.filtersOrders = [];
    this.optionSecteur = null;
    this.optionCommune = null;
    this.optionPostcode = null;
    this.optionFonction = null;
    this.optionEntite = null;
    this.optionSexe = null;
    this.optionCategorie = null;
    this.optionActivity = null;
    this.optionYear = null;
    this.optionDomaines = null;
    this.optionGrandsDomaines = null;
    this.optionMetiers = null;
    this.optionAppelations = null;
    this.contactStore.dispatch(fromContactAction.loadContacts());
  }

  filtersByLastName(event: string): void {
    if (!event) {
      this.loadContacts();
      this.filtersActivated = false;
    } else {
      const type = 'lastName';
      this.filtersActivated = true;
      this.contactStore.dispatch(
        fromContactAction.queryContacts({ filter: { event, type } })
      );
    }
  }

  filtersByFirstName(event: string): void {
    if (!event) {
      this.loadContacts();
      this.filtersActivated = false;
    } else {
      const type = 'firstName';
      this.filtersActivated = true;
      this.contactStore.dispatch(
        fromContactAction.queryContacts({ filter: { event, type } })
      );
    }
  }

  onQuery(event: any, typeFilter: string, arrayContains?: any): any {
    if (!event) {
      this.loadContacts();
      return null;
    } else {
      const type = this.setFilterType(typeFilter);
      this.filtersActivated = true;
      this.cleaner = true;

      if (type !== 'favoris') {
        this.contactStore.dispatch(
          fromContactAction.queryContacts({ filter: { event, type } })
        );
      } else {
        this.contactStore.dispatch(
          fromContactAction.queryContacts({
            filter: { event: arrayContains, type },
          })
        );
      }
    }
  }

  setFilterType(type: string): any {
    switch (type) {
      case 'last':
        return ContactQuery.last;
        break;
      case 'first':
        return ContactQuery.first;
        break;
      case 'secteur':
        return ContactQuery.secteur;
        break;
      case 'commune':
        return ContactQuery.commune;
        break;
      case 'postcode':
        return ContactQuery.code;
        break;
      case 'fonction':
        return ContactQuery.fonction;
        break;
      case 'entite':
        return ContactQuery.entite;
        break;
      case 'sexe':
        return ContactQuery.sexe;
        break;
      default:
        return '';
        break;
    }
  }

  onClear(): void {
    this.loadContacts();
  }
}
