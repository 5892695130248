<div class="space-y-2 divide-y">
  <div class="w-full flex items-end justify-end">
    <div [class]="(source | size) ? 'block' : 'hidden'">
      <button
        type="button"
        (click)="
          $event.stopPropagation();
          onRemoveAll(
            isSoftskills,
            isEnvironnement,
            isCompetences,
            isCompetencesSpeficiques
          )
        "
        class="btn btn-sm btn-ghost text-red-600"
      >
        Tout supprimer
      </button>
    </div>
    <div
      [class]="isCompetences || isCompetencesSpeficiques ? 'block' : 'hidden'"
    >
      <button
        *ngIf="isCompetences || isCompetencesSpeficiques"
        type="button"
        (click)="$event.stopPropagation(); onAddNew()"
        class="btn btn-sm btn-ghost"
      >
        Nouveau
      </button>
    </div>
  </div>

  <div *ngIf="competencesArrayControls?.length; else noCompetences">
    <form
      nz-form
      [formGroup]="competencesForm"
      class="w-full h-72 overflow-y-auto overflow-x-hidden"
    >
      <div formArrayName="competences" class="w-full">
        <div
          class="w-full flex items-start space-x-2 space-y-2"
          *ngFor="let el of competencesArrayControls; let i = index"
          [formGroupName]="i"
        >
          <ng-container *ngIf="isCompetences || isCompetencesSpeficiques">
            <div [class]="'w-60'">
              <nz-form-item class="flex flex-col">
                <label class="label">Libellé</label>

                <!-- <ng-container *ngIf="!isAdd">
                  <strong *ngIf="isItemLabel">
                    {{ el.value?.[itemLabel] }}
                  </strong>
                  <p nz-typography>{{ el.value?.[field] }}</p>
                </ng-container>
                <ng-container *ngIf="isAdd"> -->
                <nz-form-control
                  nzErrorTip="Vous devez renseigner un libellé"
                  class="inputForm"
                >
                  <input
                    formControlName="libelle"
                    [value]="el?.value?.[field]"
                    placeholder="Libellé"
                  />
                </nz-form-control>
                <!-- </ng-container> -->
              </nz-form-item>
            </div>
          </ng-container>

          <!-- COMPETENCES DE BASE ET SPÉCIFIQUES -->
          <ng-container *ngIf="isCompetences || isCompetencesSpeficiques">
            <div [class]="'w-24'">
              <div class="mt-6">
                <nz-tag nzColor="magenta" class="rounded-md" *ngIf="!isAdd">{{
                  el.value?.typeCompetence === "SavoirFaire"
                    ? "Savoir Faire"
                    : el.value?.typeCompetence
                }}</nz-tag>
                <nz-form-item *ngIf="isAdd">
                  <label class="label">Type</label>
                  <nz-form-control
                    nzErrorTip="Vous devez renseigner un type : Savoir / Savoir Faire"
                  >
                    <div class="select-menu">
                      <nz-select formControlName="typeCompetence" nzBorderless>
                        <nz-option
                          *ngFor="let item of competensType"
                          [nzLabel]="
                            item === 'SavoirFaire' ? 'Savoir Faire' : item
                          "
                          [nzValue]="item"
                        ></nz-option>
                      </nz-select>
                    </div>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>

            <div [class]="'w-32'">
              <nz-form-item class="flex flex-col">
                <label class="label">Compétence clé</label>
                <nz-form-control
                  nzErrorTip="Vous devez renseigner la compétence clé"
                >
                  <div class="select-menu">
                    <nz-select
                      formControlName="competenceCle"
                      nzBorderless
                      nzPlaceHolder=""
                    >
                      <nz-option
                        *ngFor="let item of competensCles"
                        [nzLabel]="item ? 'Oui' : 'Non'"
                        [nzValue]="item"
                      ></nz-option>
                    </nz-select>
                  </div>
                </nz-form-control>
              </nz-form-item>
            </div>
          </ng-container>

          <!-- SOFTSKILLS -->
          <ng-container *ngIf="isSoftskills">
            <div [class]="'w-50'">
              <div class="flex flex-col items-center mt-4">
                <label class="label"></label>
                <div class="select-menu">
                  <nz-select formControlName="importance" nzBorderless>
                    <nz-option
                      *ngFor="let item of softskillImportance"
                      [nzLabel]="item"
                      [nzValue]="item"
                    ></nz-option>
                  </nz-select>
                </div>
              </div>
            </div>
          </ng-container>

          <!-- ENVIRONNEMENTS -->
          <ng-container *ngIf="isEnvironnement">
            <div [class]="'w-12'">
              <div class="mt-6 flex flex-col">
                <label class="label"></label>
                <nz-tag nzColor="geekblue">{{
                  el.value?.typeEnvironnementTravail
                }}</nz-tag>
              </div>
            </div>
          </ng-container>

          <div class="w-12 -mt-8">
            <button
              class="btn btn-sm btn-circle btn-ghost"
              type="button"
              (click)="onRemove(i)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 28 28"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<ng-template #noCompetences>
  <app-no-result
    [icon]="'article'"
    [title]="''"
    [description]="noDataTitle"
    [isButton]="false"
  ></app-no-result>
</ng-template>
