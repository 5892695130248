import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  ChangeDetectionStrategy,
} from '@angular/core';
import { add } from 'date-fns';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerComponent implements OnInit, OnChanges {
  @Input() currentDate = new Date(Date.now());
  date: Date = new Date(Date.now());
  dates: Date[] = [this.currentDate, add(this.currentDate, { months: 6 })];

  @Input() type: string = 'date';
  @Input() format: string = 'dd/MM/yyyy';
  @Input() time: boolean = false;
  @Input() placeholder: string = 'Démarrage';
  @Input() item = new BehaviorSubject<Date | any>(null);

  @Input() label: boolean = false;
  @Input() labelTiles: string | string[] = '';
  @Input() borderless: boolean = true;
  @Input() dateUpdate: Date | Date[] | any = null;
  @Output() select = new EventEmitter<Date | Date[] | any>(false);
  constructor() {}

  ngOnInit(): void {
    this.select.emit(this.date);
  }

  ngOnChanges(changes: SimpleChanges): void {}

  onSelect(date: Date): void {
    this.select.emit(date);
  }

  onUpdate(): void {}
}
