import { UntypedFormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Timestamp } from 'firebase/firestore';
import { ParticipantId } from 'src/app/components/participant/store/participant.model';
import { BehaviorSubject } from 'rxjs';
@Component({
  selector: 'app-projet-professionnel-form',
  templateUrl: './projet-professionnel-form.component.html',
  styleUrls: ['./projet-professionnel-form.component.scss'],
})
export class ProjetProfessionnelFormComponent implements OnInit {
  @Output() cancel = new EventEmitter<boolean>(false);
  @Output() add = new EventEmitter<any>(false);
  @Input() participant = new BehaviorSubject<ParticipantId | any>(null);
  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {}

  formInit(): void {}
  formCreate(): void {}

  onAdd(event: any): void {
    const { metier } = event;

    this.add.emit(metier);
  }

  onValidation(): void {}

  onCancel(): void {
    this.cancel.emit(true);
  }
}
