import { Pipe, PipeTransform } from '@angular/core';
import { differenceInHours, fromUnixTime, isAfter } from 'date-fns';

@Pipe({
  name: 'timeProgression',
})
export class TimeProgressionPipe implements PipeTransform {
  transform(value: any, arg1: any, arg2: any): any {
    if (!value || !arg1 || !arg2) {
      return 0;
    }

    const start: Date = fromUnixTime(arg1['seconds']);
    const end: Date = fromUnixTime(arg2['seconds']);
    const today: Date = new Date(Date.now());
    const isAfterToday: boolean = isAfter(start, today);
    const distanceToEnd: number = differenceInHours(end, start);
    const distanceToNow: number = differenceInHours(today, start);
    const percent: number = Math.floor((distanceToNow / distanceToEnd) * 100);

    return isAfterToday ? 0 : percent;
  }
}
