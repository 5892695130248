import { NzMessageService } from 'ng-zorro-antd/message';
import { Store } from '@ngrx/store';
import { ContactState } from './../../store/contact.reducer';
import * as fromContactSelector from './../../store/contact.selectors';
import * as fromContactAction from './../../store/contact.actions';
import { EntiteSelectComponent } from './../../../../contents/components/entite-select/components/entite-select.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ContactId } from './../../store/contact.model';
import { of, Observable } from 'rxjs';
import { ContactSMALLid } from 'src/app/components/contact/store/contact.model';
import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
  TemplateRef,
  ChangeDetectionStrategy,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { EntiteSMALLid } from 'src/app/components/entite/store/entite.model';
import { I } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-contact-entites',
  templateUrl: './contact-entites.component.html',
  styleUrls: ['./contact-entites.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactEntitesComponent implements OnInit, OnChanges {
  title$: Observable<string | any> = of('Entités');
  modalWidth: number = 650;
  entites$: Observable<EntiteSMALLid[] | any> = of(null);

  @ViewChild('entiteModalTitle', { static: false }) entiteModalTitle:
    | TemplateRef<any>
    | any;

  @Input() contact: ContactId | any = null;
  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private contactStore: Store<ContactState>,
    private message: NzMessageService
  ) {}

  ngOnInit(): void {
    this.getEntite();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadEntite(changes.contact.currentValue);
  }

  getEntite(): void {
    this.entites$ = this.contactStore.select(fromContactSelector.entites);
  }
  loadEntite(contact: ContactId): void {
    if (!contact) return;
    this.contactStore.dispatch(
      fromContactAction.loadContactEntites({ contact })
    );
  }

  onNewEntite(contact: ContactId): void {
    const modal = this.modal.create({
      nzContent: EntiteSelectComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.entiteModalTitle,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
    });

    const instance = modal.getContentComponent();

    instance.select.subscribe((entite): any => {
      if (!entite) {
        return null;
      } else {
        modal.close();
        this.onAdd(contact, entite);
      }
    });
    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
        this.onCancel();
      }
    });
  }

  onAdd(contact: ContactId, entite: EntiteSMALLid): void {
    if (!contact || !entite) return;

    if (this.isExist(contact, entite)) {
      const alert: string = `L'entité ${entite.etablissement.denomination} est déjà existante.`;
      this.message.info(alert, { nzDuration: 6000 });
      return;
    }

    this.contactStore.dispatch(
      fromContactAction.addContactEntites({ contact, entite })
    );
  }

  isExist(contact: ContactId, entite: EntiteSMALLid): boolean {
    const exist = contact.entitesIds?.find((el) => el === entite.id);
    return exist ? true : false;
  }

  onDelete(contact: ContactId, entite: EntiteSMALLid): void {
    if (!contact || !entite) return;
    this.contactStore.dispatch(
      fromContactAction.deleteContactEntites({ contact, entite })
    );
  }

  onCancel(): void {
    return;
  }
}
