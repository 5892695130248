import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'any',
})
export class AllParticipantAccompagnementsGQL extends Query<Response> {
  document = gql`
    query Accompagnements($id: ID!) {
      accompagnements(id: $id) {
        id
        champs {
          code
          libelle
        }
        type
        dateStart
        dateEnd
        duration {
          months
        }
        objectif
        isCompleted
        auteur {
          avatar
          displayName
          fonction
          setting {
            color
          }
        }
      }
    }
  `;
}
