import { createFeatureSelector, createSelector } from '@ngrx/store';

import { OperationNote, OperationNoteId } from './operation-note.model';
import {
  OperationNoteState,
  operationNotesFeatureKey,
  selectAll,
} from './operation-note.reducer';
import { toPairs } from 'lodash';
import {
  fromUnixTime,
  isThisMonth,
  isThisWeek,
  isThisYear,
  isToday,
} from 'date-fns';

export const selectOpearationNotesState =
  createFeatureSelector<OperationNoteState>(operationNotesFeatureKey);

export const notes = createSelector(selectOpearationNotesState, selectAll);

export const note = createSelector(
  selectOpearationNotesState,
  (state: OperationNoteState) => state.note
);
export const loading = createSelector(
  selectOpearationNotesState,
  (state: OperationNoteState) => state.loading
);

export const error = createSelector(
  selectOpearationNotesState,
  (state: OperationNoteState) => state.error
);
