import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'app-diffusion-contacts',
  templateUrl: './diffusion-contacts.component.html',
  styleUrls: ['./diffusion-contacts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiffusionContactsComponent implements OnInit {
  @Input() contacts: any;

  constructor() {}

  ngOnInit(): void {}
}
