import { OperationId } from './../../store/operation.model';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { of, Observable, Subject } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { OperationState } from './../../store/operation.reducer';
import * as fromOperationAction from './../../store/operation.actions';
import * as fromOperationSelector from './../../store/operation.selectors';

@Component({
  selector: 'app-operation-documents',
  templateUrl: './operation-documents.component.html',
  styleUrls: ['./operation-documents.component.scss'],
})
export class OperationDocumentsComponent implements OnInit, OnDestroy {
  documents$: Observable<any> = of(null);
  operation$: Observable<OperationId | any> = of();
  subscribe = new Subject();

  constructor(private operationStore: Store<OperationState>) {}

  ngOnInit(): void {
    this.getOperation();
    this.getDocuments();
    this.getDocuments();
    this.loadDocuments();
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getOperation(): void {
    this.operation$ = this.operationStore.select(
      fromOperationSelector.operation
    );
  }
  getDocuments(): void {
    this.documents$ = this.operationStore.select(
      fromOperationSelector.documents
    );
  }

  loadDocuments(): void {
    this.operation$
      .pipe(takeUntil(this.subscribe))
      .subscribe((operation: OperationId) => {
        if (!operation) {
          return;
        } else {
          this.operationStore.dispatch(
            fromOperationAction.loadDocuments({
              operationId: operation.id,
            })
          );
        }
      });
  }

  onAdd(event: any, operation: OperationId): void {
    if (!event || !operation) {
      return;
    } else {
      const { id } = operation;
      this.operationStore.dispatch(
        fromOperationAction.addDocuments({ operation, documents: event })
      );
    }
  }
  onDelete(document: any, operation: OperationId): void {
    if (!document || !operation) {
      return;
    } else {
      const operationId: string = operation.id;

      this.operationStore.dispatch(
        fromOperationAction.deleteDocument({
          operationId,
          document,
        })
      );
    }
  }
  onDeleteAll(): void {}
}
