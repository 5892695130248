import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { of, Observable, takeUntil, Subject, BehaviorSubject } from 'rxjs';
import { ProjetPrpfessionnelState } from '../../store/projet-professionnel.reducer';
import * as fromProjetProAction from '../../store/projet-professionnel.actions';
import * as fromProjetProSelector from '../../store/projet-professionnel.selectors';

@Component({
  selector: 'app-projet-professionnel-statistiques',
  templateUrl: './projet-professionnel-statistiques.component.html',
  styleUrls: ['./projet-professionnel-statistiques.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjetProfessionnelStatistiquesComponent
  implements OnInit, OnChanges
{
  statistiques$: Observable<any> = of(null);
  loading$: Observable<any> = of(null);
  subscribe = new Subject();
  bmo$ = new BehaviorSubject<any>(null);
  bmoTitle: string = "Besoins en main d'œuvre";
  salaireTitle: string = "Besoins en main d'œuvre";

  typesDeContrats$ = new BehaviorSubject<any>(null);
  salaires$ = new BehaviorSubject<any>(null);
  repriseEmploi$ = new BehaviorSubject<any>(null);
  offresEmploi$ = new BehaviorSubject<any>(null);

  @Input() item = new BehaviorSubject<any>(null);
  constructor(
    private projetProfessionnelStore: Store<ProjetPrpfessionnelState>
  ) {}

  ngOnInit(): void {
    this.getLoading();
    this.getStatistiques();
    this.setStatistiques();
    //this.loadStatistiques();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.item.currentValue) return;
    //this.loadStatistiques();
  }

  getLoading(): void {
    this.loading$ = this.projetProfessionnelStore.select(
      fromProjetProSelector.loading
    );
  }

  loadStatistiques(): void {
    this.item.pipe(takeUntil(this.subscribe)).subscribe((projetPro: any) => {
      if (!projetPro) return;

      const { code, areaCode, age } = projetPro;
      const fabCode = projetPro?.fap?.fap?.code ? projetPro.fap.fap.code : null;
      if (!fabCode) return;
      this.projetProfessionnelStore.dispatch(
        fromProjetProAction.loadStatistiquesEmploi({
          codeRome: code,
          areaCode: areaCode,
          codeFap: fabCode,
          age: Number(age),
        })
      );
    });
  }

  getStatistiques(): void {
    this.statistiques$ = this.projetProfessionnelStore.select(
      fromProjetProSelector.statistiques
    );
  }

  setStatistiques(): void {
    this.statistiques$
      .pipe(takeUntil(this.subscribe))
      .subscribe((statistiques: any) => {
        if (!statistiques) return;
        const {
          besoin_main_dOeuvre,
          statistiques_offres_retour,
          statistiques_reprise_emploi,
          statistiques_salaires,
          statistiques_types_contrats,
        } = statistiques;

        //TYPE DE CONTRATS
        const typeContratsData: string[] = statistiques_types_contrats?.length
          ? statistiques_types_contrats.map((el: any) => {
              const name = el['CONTRACT_TYPE_NAME'];
              const value = el['OFFERS_PERCENT'];
              return { name, value };
            })
          : [];

        const dataChartsTypeDeContrats = {
          title: 'Type de contrats',
          chartsTitle: '',
          type: 'donut',
          data: typeContratsData,
          center: ['50%', '70%'],
          radius: 120,
          height: '400px',
        };

        this.typesDeContrats$.next(dataChartsTypeDeContrats);

        //SALAIRES
        const yAxis: any = statistiques_salaires?.length
          ? statistiques_salaires.map((el: any) => {
              const profession = el['PCS_PROFESSION_NAME'];
              const age = el['AGE_GROUP_NAME'];
              const departement = el['AREA_NAME'];
              const title: string = `${departement} - ${age}`;

              return title;
            })
          : [];

        const serie1: any = statistiques_salaires?.length
          ? statistiques_salaires.map((el: any) => {
              const salaireMin = Number(el['MINIMUM_SALARY']);
              const profession = el['PCS_PROFESSION_NAME'];

              return salaireMin;
            })
          : [];

        const serie2: any = statistiques_salaires?.length
          ? statistiques_salaires.map((el: any) => {
              const salaireMax = Number(el['MAXIMUM_SALARY']);
              return salaireMax;
            })
          : [];

        const dataChartsSalaires = {
          title: 'Salaires',
          chartsTitle: '',
          subtitle: 'Brut par mois',
          type: 'barHorizontal',
          data: {
            yAxis: yAxis,
            series: [
              {
                title: 'Mimimum',
                data: Object.values(serie1),
              },
              {
                title: 'Maximum',
                data: Object.values(serie2),
              },
            ],
          },
        };
        this.salaires$.next(dataChartsSalaires);

        //REPRISE
        const canauxRecrutementsData: string[] =
          statistiques_reprise_emploi?.length
            ? statistiques_reprise_emploi.map((el: any) => {
                const name = el['APPLICATION_TYPE_NAME'];
                const value = el['RECRUT_PERCENT'];
                return { name, value };
              })
            : [];

        const dataChartsCanauxRecrutement = {
          title: 'Canaux de recrutement',
          chartsTitle: '',
          type: 'donut',
          data: canauxRecrutementsData,
          center: ['50%', '70%'],
          radius: 120,
          height: '400px',
        };

        this.repriseEmploi$.next(dataChartsCanauxRecrutement);

        //OFFRES
        const offresData = statistiques_offres_retour?.length
          ? statistiques_offres_retour.map((el: any) => {
              const departement = el['AREA_NAME'];
              const demandesFinMois =
                Number(el['NB_APPLICATION_END_MONTH']) > 0
                  ? Number(el['NB_APPLICATION_END_MONTH'])
                  : 0;
              const demandesDeniereSemaine =
                Number(el['NB_APPLICATION_LAST_WEEK']) > 0
                  ? Number(el['NB_APPLICATION_LAST_WEEK'])
                  : 0;
              const offreFinMois =
                Number(el['NB_OFFER_END_MONTH']) > 0
                  ? Number(el['NB_OFFER_END_MONTH'])
                  : 0;
              const offreDeniereSemaine =
                Number(el['NB_OFFER_LAST_WEEK']) > 0
                  ? Number(el['NB_OFFER_LAST_WEEK'])
                  : 0;
              const bestDepartement = el['RICHER_CATCHMENT_AREA_NAME'];
              const tension = el['TENSION_RATIO'];
              const seasonal = el['SEASONAL'] === 'N' ? 'Non' : 'Oui';
              return {
                departement,
                demandesFinMois,
                demandesDeniereSemaine,
                offreFinMois,
                offreDeniereSemaine,
                bestDepartement,
                tension,
                seasonal,
              };
            })
          : [];
        const dataChartsOffreEmploi = {
          title: 'Offres et demandes',
          chartsTitle: '',
          type: 'pieClassique',
          data: offresData?.length ? offresData[0] : null,
          height: '400px',
        };
        this.offresEmploi$.next(dataChartsOffreEmploi);

        //BMO

        const yAxisBmo: any = besoin_main_dOeuvre?.length
          ? besoin_main_dOeuvre.map((el: any) => {
              const departement = el['DEPARTEMENT_NAME'];
              const commune = el['CATCHMENT_AREA_NAME'];
              const professionFamilleName = el['PROFESSION_FAMILY_NAME'];
              const region = el['REGION_LIB'];

              const title: string = `${commune}`;

              return title;
            })
          : [];

        const serie1Bmo: any = besoin_main_dOeuvre?.length
          ? besoin_main_dOeuvre.map((el: any) => {
              const differentRecrutementProjet = el['NB_DIFF_RECRUT_PROJECTS']
                ? Number(el['NB_DIFF_RECRUT_PROJECTS'])
                : 0;
              return differentRecrutementProjet;
            })
          : [];

        const serie2Bmo: any = besoin_main_dOeuvre?.length
          ? besoin_main_dOeuvre.map((el: any) => {
              const recrutementProjet = el['NB_RECRUT_PROJECTS']
                ? Number(el['NB_RECRUT_PROJECTS'])
                : 0;
              return recrutementProjet;
            })
          : [];

        const dataChartsOffre = {
          title: "Besoins en main d'œuvre",
          chartsTitle: '',
          center: ['50%', '70%'],
          radius: 120,
          height: '400px',
          subtitle: '',
          type: 'barHorizontal',
          data: {
            yAxis: yAxisBmo,
            series: [
              {
                title: 'Nombre de projets de recrutement',
                data: Object.values(serie2Bmo),
              },
              {
                title:
                  'Nombre de projets de recrutement avec difficulté de recruter',
                data: Object.values(serie1Bmo),
              },
            ],
          },
        };

        this.bmo$.next(dataChartsOffre);
      });
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
    this.statistiques$ = of(null);
  }
}
