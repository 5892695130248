import { ActivatedRoute, Router } from '@angular/router';
import { PortalNavigationService } from './../../../../portals/portal-navigation.service';
import { ComponentPortal } from '@angular/cdk/portal';
import { OrganisationHeaderComponent } from './../organisation-header/organisation-header.component';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';
import { OrganisationState } from './../../store/organisation.reducer';
import * as sideNavigationAction from 'src/app/components/side-navigation/store/side-navigation.actions';
import { SideNavigationState } from 'src/app/components/side-navigation/store/side-navigation.reducer';

@Component({
  selector: 'app-organisation',
  templateUrl: './organisation.component.html',
  styleUrls: ['./organisation.component.scss'],
})
export class OrganisationComponent implements OnInit, OnDestroy {
  user$: Observable<any> = of(null);
  organisation$: Observable<any> = of(null);
  subscribe = new Subject();
  itemSelected: any;
  componentPortalFilter: ComponentPortal<OrganisationHeaderComponent> | any;
  bodyComponent: string = '';

  constructor(
    private userStore: Store<UserState>,
    private organisationStore: Store<OrganisationState>,
    private portalNavigationService: PortalNavigationService,
    private sideNavigationStore: Store<SideNavigationState>,
    private router: Router,
    private activieRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.componentPortalFilter = new ComponentPortal(
      OrganisationHeaderComponent
    );
    this.setFilterPortal();
  }

  getUser(): void {
    this.user$ = this.userStore.select(fromUserSelector.user);
  }

  onSetNavigation(navigation: string): void {
    this.sideNavigationStore.dispatch(
      sideNavigationAction.selectSideNavigation({
        navigation: { type: 'side', name: navigation },
      })
    );
  }

  setFilterPortal(): void {
    this.portalNavigationService.setPortal(this.componentPortalFilter);
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
    this.componentPortalFilter.detach();
  }
}
