import { takeUntil } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, Subject, BehaviorSubject } from 'rxjs';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';
import { DashboardState } from '../../store/dashboard.reducer';
import * as fromDashboardSelector from '../../store/dashboard.selectors';
import * as fromDashboardAction from '../../store/dashboard.actions';

@Component({
  selector: 'app-dashboard-contacts',
  templateUrl: './dashboard-contacts.component.html',
  styleUrls: ['./dashboard-contacts.component.scss'],
})
export class DashboardContactsComponent implements OnInit, OnDestroy {
  user$: Observable<any> = of(null);
  subscribe = new Subject();
  statistiques$: Observable<any> = of(null);
  currentDate = new Date(Date.now());
  currentYear: string = this.currentDate.getFullYear().toString();
  selectedYear: string = '';

  selectors: any = [
    {
      name: 'Entités',
      icon: 'banck',
      select: 'entites',
    },
    {
      name: 'Contacts',
      icon: 'teams',
      select: 'contacts',
    },
    {
      name: 'Participants',
      icon: 'team',
      select: 'participants',
    },
    {
      name: 'Projets',
      icon: 'project',
      select: 'projets',
    },
  ];

  contactsTotal: number = 0;
  contactsObjectif: number = 0;
  contactByMonths$ = new BehaviorSubject<any>(null);
  genres$ = new BehaviorSubject<any>(null);
  departements$ = new BehaviorSubject<any>(null);
  communes$ = new BehaviorSubject<any>(null);
  appelations$ = new BehaviorSubject<any>(null);
  metiers$ = new BehaviorSubject<any>(null);
  domaines$ = new BehaviorSubject<any>(null);
  grandsDomaines$ = new BehaviorSubject<any>(null);

  constructor(
    private userStore: Store<UserState>,
    private dashboardStore: Store<DashboardState>
  ) {}

  ngOnInit(): void {
    this.getUser();
    this.loadStatistiques();
    this.getStatistiques();
    this.setStatistiques();
  }
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getUser(): void {
    this.user$ = this.userStore.select(fromUserSelector.user);
  }

  getStatistiques(): any {
    this.statistiques$ = this.dashboardStore.select(
      fromDashboardSelector.contacts
    );
  }

  loadStatistiques(year: string = this.currentYear): any {
    if (!year) {
      return null;
    } else {
      this.dashboardStore.dispatch(
        fromDashboardAction.loadDashboardsContacts({ year })
      );
    }
  }

  setStatistiques(): void {
    this.statistiques$
      .pipe(takeUntil(this.subscribe))
      .subscribe((statistique) => {
        this.getContactSTATS(statistique);
      });
  }

  getContactSTATS(stats: any): any {
    if (!stats) {
      return null;
    }

    const sexes = stats?.charts?.sexe ? stats.charts.sexe : null;
    const fonctions = stats?.charts?.fonctions ? stats.charts.fonctions : null;
    const total = stats?.total ? stats.total : 0;
    const objectif = stats?.objectif ? stats.objectif : 0;
    const byMonths = stats?.charts?.byMonths ? stats.charts.byMonths : null;
    const commune = stats?.charts?.commune ? stats.charts.commune : null;
    const context = stats?.charts?.context ? stats.charts.context : null;
    const departement = stats?.charts?.departement
      ? stats.charts.departement
      : null;
    const title: string = 'Contacts';
    //SETTING

    this.contactsTotal = total ? total : 0;
    this.contactsObjectif = objectif ? objectif : 0;

    this.contactByMonths$.next({
      title: 'Répartition par mois',
      type: 'barMonths',
      data: stats.byMonths,
    });

    this.genres$.next({
      title: 'Genres',
      type: 'pie',
      data: stats.sexe,
    });

    this.appelations$.next({
      title: 'Appelations',
      type: 'pie',
      data: stats.appelations,
    });

    this.metiers$.next({
      title: 'Métiers',
      type: 'pie',
      data: stats.metiers,
    });

    this.domaines$.next({
      title: 'Domaines',
      type: 'pie',
      data: stats.domaines,
    });

    this.grandsDomaines$.next({
      title: 'Grands domaines',
      type: 'pie',
      data: stats.grandDomaines,
    });

    this.departements$.next({
      title: 'Département',
      type: 'pie',
      data: stats.departement,
    });

    this.communes$.next({
      title: 'Commune',
      type: 'pie',
      data: stats.commune,
    });
  }

  selectYear(year: any): void {}
}
