import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, Subject } from 'rxjs';
import { ParticipantId } from 'src/app/components/participant/store/participant.model';
import { ParticipantState } from 'src/app/components/participant/store/participant.reducer';
import * as fromParticipantSelector from 'src/app/components/participant/store/participant.selectors';

@Component({
  selector: 'app-participant-bilan',
  templateUrl: './participant-bilan.component.html',
  styleUrls: ['./participant-bilan.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantBilanComponent implements OnInit, OnChanges, OnDestroy {
  participant$: Observable<ParticipantId | any> = of(null);

  subscribe = new Subject();
  suivis$: Observable<any[] | any> = of([]);

  constructor(private participantStore: Store<ParticipantState>) {}

  ngOnInit(): void {
    this.getParticipant();
  }
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getParticipant(): void {
    this.participant$ = this.participantStore.select(
      fromParticipantSelector.participant
    );
  }
}
