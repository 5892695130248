<app-contrat-form-detail
  [typeInput]="contratForm.value?.type"
  [natureInput]="contratForm.value?.nature"
  [experienceInput]="contratForm.value?.experience"
  [salaireInput]="contratForm.value?.salaire"
  [permisInput]="contratForm.value?.permis"
  [bureautiqueInput]="contratForm.value?.bureautique"
  [work_priceInput]="contratForm.value?.work_price"
  [isDeplacementInput]="contratForm.value?.isDeplacement"
  [zone_deplacementInput]="contratForm.value?.zone_deplacement"
  [salaireComplementInput]="contratForm.value?.salaireComplement"
  (onCurrentChanges)="onDetailChange($event)"
></app-contrat-form-detail>

<nz-space nzDirection="vertical" nzSize="small"></nz-space>
<app-contrat-form-travail
  [work_daysInput]="contratForm.value?.work_days"
  [pause_dejeunerInput]="contratForm.value?.pause_dejeuner"
  [amplitude_horaireInput]="contratForm.value?.amplitude_horaire"
  [isSupplementairesInput]="contratForm.value?.isSupplementaires"
  [isAstreinteInput]="contratForm.value?.isAstreinte"
  [suiviInterneInput]="contratForm.value?.suiviInterne"
  [isTeamWorkInput]="contratForm.value?.isTeamWork"
  [isReferentPresenceInput]="contratForm.value?.isReferentPresence"
  (onCurrentChanges)="onTravailChange($event)"
></app-contrat-form-travail>
<nz-space nzDirection="vertical" nzSize="small"></nz-space>
<app-contrat-form-evolution
  [evolutionInput]="contratForm.value?.evolution"
  [evolutionConditionInput]="contratForm.value?.evolutionCondition"
  (onCurrentChanges)="onEvolutionChange($event)"
></app-contrat-form-evolution>

<nz-space nzDirection="vertical" nzSize="small"></nz-space>
