import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
  Input,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 'app-layout-list-item',
  templateUrl: './layout-list-item.component.html',
  styleUrls: ['./layout-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutListItemComponent implements OnInit, OnChanges {
  @Input() icon: TemplateRef<any> | any;
  @Input() content: TemplateRef<any> | any;
  @Input() actions: TemplateRef<any> | any;
  @Input() cardHeigth: string = '80px';

  constructor() {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {}
}
