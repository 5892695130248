import {
  NzNotificationPlacement,
  NzNotificationService,
} from 'ng-zorro-antd/notification';
import { NzMessageService } from 'ng-zorro-antd/message';
import { OperationTaskService } from './../../../service/operation-task.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromOperationTaskAction from './operation-task.actions';
import { of, timer, concatMap, delay } from 'rxjs';
import { tap, mergeMap, exhaustMap, map, catchError } from 'rxjs/operators';
import { OperationTaskState } from './operation-task.reducer';
import { Store } from '@ngrx/store';

const NOTIFICATION_PLACEMENT: NzNotificationPlacement = 'topRight';
@Injectable()
export class OperationTaskEffects {
  source = of(1, 2, 3);

  //LOAD
  loadOperationTaks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationTaskAction.loadOperationTasks),
      mergeMap((action) =>
        this.operationTaskService.getAll(action.operationId).pipe(
          map((tasks: any) => {
            this.operationTaskStore.dispatch(
              fromOperationTaskAction.loadingOperationTasks({ loading: false })
            );

            return fromOperationTaskAction.loadOperationTasksSuccess({ tasks });
          }),
          catchError((error) => {
            this.onErrorNotification(error, 'LOAD_OPERATION_TAKS');
            return of(
              fromOperationTaskAction.loadOperationTasksFailure({ error })
            );
          })
        )
      )
    )
  );

  //QUERIES MY TASKS
  loadOperationMyTaks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationTaskAction.loadOperationMyTasks),
      mergeMap((action) =>
        this.operationTaskService.getAllUserTasks(action.operationId).pipe(
          map((tasks: any) => {
            this.operationTaskStore.dispatch(
              fromOperationTaskAction.loadingOperationTasks({ loading: false })
            );

            return fromOperationTaskAction.loadOperationMyTasksSuccess({
              tasks,
            });
          }),
          catchError((error) => {
            this.onErrorNotification(error, 'LOAD_OPERATION_MY_TAKS');
            return of(
              fromOperationTaskAction.loadOperationMyTasksFailure({ error })
            );
          })
        )
      )
    )
  );

  //QUERIES DAILY TASKS
  loadOperationDailyTaks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationTaskAction.loadOperationTodayTasks),
      mergeMap((action) =>
        this.operationTaskService.getAllTodayTasks(action.operationId).pipe(
          map((tasks: any) => {
            this.operationTaskStore.dispatch(
              fromOperationTaskAction.loadingOperationTasks({ loading: false })
            );

            return fromOperationTaskAction.loadOperationTodayTasksSuccess({
              tasks,
            });
          }),
          catchError((error) => {
            this.onErrorNotification(error, 'LOAD_OPERATION_TODAY_TAKS');
            return of(
              fromOperationTaskAction.loadOperationTodayTasksFailure({ error })
            );
          })
        )
      )
    )
  );

  //QUERIES WEEKLY TASKS
  loadOperationWeeklyTaks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationTaskAction.loadOperationWeekTasks),
      mergeMap((action) =>
        this.operationTaskService.getAllWeekTasks(action.operationId).pipe(
          map((tasks: any) => {
            this.operationTaskStore.dispatch(
              fromOperationTaskAction.loadingOperationTasks({ loading: false })
            );

            return fromOperationTaskAction.loadOperationWeekTasksSuccess({
              tasks,
            });
          }),
          catchError((error) => {
            this.onErrorNotification(error, 'LOAD_OPERATION_WEEK_TAKS');
            return of(
              fromOperationTaskAction.loadOperationWeekTasksFailure({ error })
            );
          })
        )
      )
    )
  );

  //QUERIES MONTH TASKS
  loadOperationMonthlyTaks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationTaskAction.loadOperationMonthTasks),
      mergeMap((action) =>
        this.operationTaskService.getAllMonthTasks(action.operationId).pipe(
          map((tasks: any) => {
            this.operationTaskStore.dispatch(
              fromOperationTaskAction.loadingOperationTasks({ loading: false })
            );

            return fromOperationTaskAction.loadOperationMonthTasksSuccess({
              tasks,
            });
          }),
          catchError((error) => {
            this.onErrorNotification(error, 'LOAD_OPERATION_MONTH_TAKS');
            return of(
              fromOperationTaskAction.loadOperationMonthTasksFailure({ error })
            );
          })
        )
      )
    )
  );

  //ADD
  addOperationTaks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationTaskAction.addOperationTask),
      tap(() => {
        this.notification.success(
          'Nouveau',
          'Nouvelle tâche en cours de création...',
          {
            nzDuration: 6000,
            nzAnimate: true,
            nzPlacement: NOTIFICATION_PLACEMENT,
            nzKey: 'ADD_TASK_OPERATION',
          }
        );
        fromOperationTaskAction.loadingOperationTasks({ loading: true });
      }),
      mergeMap((action) =>
        this.operationTaskService.addOne(action.operation, action.task).pipe(
          map((respons: any) => {
            const success: string = respons.data.createOperationTask;

            this.notification.success('Nouveau', success, {
              nzDuration: 8000,
              nzAnimate: true,
              nzPlacement: NOTIFICATION_PLACEMENT,
              nzKey: 'ADD_TASK_OPERATION',
            });

            return fromOperationTaskAction.addOperationTaskSuccess({
              success,
            });
          }),
          catchError((error) => {
            this.onErrorNotification(error, 'ADD_TASK_OPERATION');

            return of(
              fromOperationTaskAction.addOperationTaskFailure({ error })
            );
          })
        )
      ),
      tap(() =>
        fromOperationTaskAction.loadingOperationTasks({ loading: false })
      )
    )
  );

  //UPDATE
  updateOperationTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationTaskAction.updateOperationTask),
      tap(() =>
        fromOperationTaskAction.loadingOperationTasks({ loading: true })
      ),
      mergeMap((action) =>
        this.operationTaskService
          .updateOne(action.operationTask.changes, action.updateType)
          .pipe(
            map((success: any) => {
              if (!success) {
                return fromOperationTaskAction.updateOperationTaskSuccess({
                  success,
                });
              }
              this.notification.success('Modification de tâche', success, {
                nzDuration: 8000,
                nzAnimate: true,
                nzPlacement: NOTIFICATION_PLACEMENT,
                nzKey: 'UPDATE_TASK_OPERATION',
              });

              return fromOperationTaskAction.updateOperationTaskSuccess({
                success,
              });
            }),
            catchError((error) => {
              this.onErrorNotification(error, 'UPDATE_TASK_OPERATION');

              return of(
                fromOperationTaskAction.updateOperationTaskFailure({ error })
              );
            })
          )
      ),
      tap(() =>
        fromOperationTaskAction.loadingOperationTasks({ loading: false })
      )
    )
  );

  //DELETE ONE
  deleteOperationTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationTaskAction.deleteOperationTask),
      tap(() => {
        this.notification.success(
          'Suppression',
          `Suppression de la tâche en cours...`,
          {
            nzDuration: 6000,
            nzAnimate: true,
            nzPlacement: NOTIFICATION_PLACEMENT,
            nzKey: 'DELETE_TASK_OPERATION',
          }
        );
        fromOperationTaskAction.loadingOperationTasks({ loading: true });
      }),
      mergeMap((action) =>
        this.operationTaskService.deleteOne(action.operationId, action.id).pipe(
          map((respons: any) => {
            const success: string = respons.data.deleteOperationTask;

            this.notification.success('Suppression', success, {
              nzDuration: 8000,
              nzAnimate: true,
              nzPlacement: NOTIFICATION_PLACEMENT,
              nzKey: 'DELETE_TASK_OPERATION',
            });

            return fromOperationTaskAction.deleteOperationTaskSuccess({
              success,
            });
          }),
          catchError((error) => {
            this.onErrorNotification(error, 'DELETE_TASK_OPERATION');

            return of(
              fromOperationTaskAction.deleteOperationTaskFailure({ error })
            );
          })
        )
      ),
      tap(() =>
        fromOperationTaskAction.loadingOperationTasks({ loading: false })
      )
    )
  );

  //DELETE ALL
  deleteOperationTaks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationTaskAction.deleteOperationTasks),
      tap(() => {
        this.notification.success(
          'Suppression',
          `Suppression de la tâche en cours...`,
          {
            nzDuration: 6000,
            nzAnimate: true,
            nzPlacement: NOTIFICATION_PLACEMENT,
            nzKey: 'DELETE_TASKS_OPERATION',
          }
        );
        fromOperationTaskAction.loadingOperationTasks({ loading: true });
      }),
      mergeMap((action) =>
        this.operationTaskService.deleteAll(action.operationId).pipe(
          map((respons: any) => {
            const success: string = respons.data.deleteOperationTasks;
            this.notification.success('Suppression', success, {
              nzDuration: 8000,
              nzAnimate: true,
              nzPlacement: NOTIFICATION_PLACEMENT,
              nzKey: 'DELETE_TASKS_OPERATION',
            });

            return fromOperationTaskAction.deleteOperationTasksSuccess({
              success,
            });
          }),
          catchError((error) => {
            this.onErrorNotification(error, 'DELETE_TASKS_OPERATION');

            return of(
              fromOperationTaskAction.deleteOperationTasksFailure({ error })
            );
          })
        )
      ),
      tap(() =>
        fromOperationTaskAction.loadingOperationTasks({ loading: false })
      )
    )
  );

  constructor(
    private actions$: Actions,
    private operationTaskService: OperationTaskService,
    private notification: NzNotificationService,
    private operationTaskStore: Store<OperationTaskState>
  ) {}

  onErrorNotification(error: any, key: string): void {
    this.notification.error('Error', error.message, {
      nzDuration: 10000,
      nzAnimate: true,
      nzPlacement: NOTIFICATION_PLACEMENT,
      nzKey: key ? key : '',
    });
  }
}
