import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'string',
})
export class StringPipe implements PipeTransform {
  transform(value: number): string {
    if (!value) {
      return '';
    } else {
      const toString = value.toString();
      return toString;
    }
  }
}
