import { takeUntil } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, Subject, BehaviorSubject } from 'rxjs';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';
import { DashboardState } from '../../store/dashboard.reducer';
import * as fromDashboardSelector from '../../store/dashboard.selectors';
import * as fromDashboardAction from '../../store/dashboard.actions';
import { format } from 'date-fns';

@Component({
  selector: 'app-dashboard-projets',
  templateUrl: './dashboard-projets.component.html',
  styleUrls: ['./dashboard-projets.component.scss'],
})
export class DashboardProjetsComponent implements OnInit, OnDestroy {
  user$: Observable<any> = of(null);
  subscribe = new Subject();
  statistiques$: Observable<any> = of(null);
  currentDate = new Date(Date.now());
  currentYear: string = this.currentDate.getFullYear().toString();
  currentString: string = format(this.currentDate, 'yyyy');

  selectedYear: string = '';

  contactsTotal: number = 0;
  contactsObjectif: number = 0;
  byMonths$ = new BehaviorSubject<any>(null);
  services$ = new BehaviorSubject<any>(null);
  types$ = new BehaviorSubject<any>(null);

  constructor(
    private userStore: Store<UserState>,
    private dashboardStore: Store<DashboardState>
  ) {}

  ngOnInit(): void {
    this.getUser();
    this.loadStatistiques();
    this.getStatistiques();
    this.setStatistiques();
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getUser(): void {
    this.user$ = this.userStore.select(fromUserSelector.user);
  }

  getStatistiques(): any {
    this.statistiques$ = this.dashboardStore.select(
      fromDashboardSelector.projets
    );
  }

  loadStatistiques(year: string = this.currentYear): any {
    if (!year) {
      return null;
    } else {
      this.dashboardStore.dispatch(
        fromDashboardAction.loadDashboardsProjets({ year })
      );
    }
  }

  setStatistiques(): void {
    this.statistiques$
      .pipe(takeUntil(this.subscribe))
      .subscribe((statistique) => {
        this.setProjets__STATISTIQUES(statistique);
      });
  }

  setProjets__STATISTIQUES(statistiques: any): any {
    if (!statistiques) {
      return null;
    } else {
      const dates = statistiques?.byMonths ? statistiques?.byMonths : null;
      const services = statistiques?.services ? statistiques.services : null;
      const types = statistiques?.types ? statistiques.types : null;
      this.byMonths$.next({
        title: 'Répartition par mois',
        type: 'barMonths',
        data: dates,
        width: '300px',
        height: '200px',
      });

      this.services$.next({
        title: 'Services',
        type: 'pie',
        data: services,
        radius: '25%',
        center: ['25%', '25%'],
      });
      this.types$.next({
        title: 'Type',
        type: 'pie',
        data: types,
        radius: '25%',
        center: ['25%', '25%'],
      });
    }
  }
}
