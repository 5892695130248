import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer,
} from '@ngrx/store';
import { environment } from '../../environments/environment';
import * as fromUserAction from '../components/auth/store/auth.actions';

const LOGOUT: string = '[Auth/COMPONENT] LOGOUT Auth';
export interface State {}

export const reducers: ActionReducerMap<State> = {};

export function logout(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state, action) => {
    if (action != null && action.type === fromUserAction.logout.type) {
      return reducer(undefined, { type: 'INIT' });
    }
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<State>[] = !environment.production
  ? []
  : [];
