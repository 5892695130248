import { ParticipantId } from './../../store/participant.model';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-participant-actions',
  templateUrl: './participant-actions.component.html',
  styleUrls: ['./participant-actions.component.scss'],
})
export class ParticipantActionsComponent implements OnInit {
  @Input() participant: ParticipantId | any = null;
  @Output() select = new EventEmitter<string>(false);
  constructor() {}

  ngOnInit(): void {}

  onSelect(item: string): void {
    this.select.emit(item);
  }
}
