import { ContactSMALLid } from './../components/contact/store/contact.model';
import { Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  doc,
  docSnapshots,
  collectionChanges,
  getDoc,
  addDoc,
  setDoc,
  deleteDoc,
  serverTimestamp,
  DocumentData,
  Timestamp,
  query,
  where,
  arrayUnion,
  arrayRemove,
  collectionSnapshots,
  getDocs,
  documentId,
} from '@angular/fire/firestore';
import { Observable, of, from, EMPTY } from 'rxjs';
import { switchMap, mergeMap, map, catchError } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { UserState } from '../components/user/store/user.reducer';
import * as fromUserSelector from '../components/user/store/user.selectors';
import { ContactsService } from './contacts.service';
import { Apollo, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'any',
})
export class QueryContactsService {
  GET_FILTERS = gql`
    query Me {
      me {
        organisation {
          filtersContact {
            sexes
            postcode
            years
            communes
            entites
            appelations
            metiers
            grandsDomaines
            domaines
            dateUpdate
          }
        }
      }
    }
  `;

  constructor(
    private db: Firestore,
    private fns: Functions,
    private userStore: Store<UserState>,
    private contactService: ContactsService,
    private apollo: Apollo
  ) {}

  //FILTERS
  getFilters(): Observable<any[] | any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_FILTERS,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  //QUERIES
  queryContacts(filter: any): Observable<ContactSMALLid[] | any> {
    try {
      return this.userStore.pipe(
        select(fromUserSelector.user),
        switchMap((user: any): any => {
          if (user) {
            switch (filter.type) {
              case 'entite':
                return this.queryEntites(filter.event, user);
                break;
              case 'fonction':
                return this.queryFonctions(filter.event, user);
                break;
              case 'sexe':
                return this.querySexe(filter.event, user);

                break;
              case 'commune':
                return this.queryCommune(filter.event, user);

                break;
              case 'codepostal':
                return this.queryPostalCode(filter.event, user);
                break;
              case 'secteur':
                return this.querySecteur(filter.event, user);
                break;
              case 'lastName':
                return this.queryLastName(filter.event);
                break;
              case 'firstName':
                return this.queryFirstName(filter.event);
                break;
              default:
                return this.contactService.getAll();
                break;
            }
          } else {
            return of(null);
          }
        })
      );
    } catch (error) {
      return of(null);
    }
  }

  queryLastName(value: string): Observable<ContactSMALLid[] | any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (user) {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/contacts`
            );
            const dbQuery = query(
              dbCollection,
              where(
                'metadata.arrayLastName',
                'array-contains',
                value.toUpperCase()
              )
            );

            return from(
              getDocs(dbQuery)
                .then((snap) => {
                  return snap.docs.map((doc: DocumentData) => {
                    const data = doc.data() as any;
                    const id = doc.id;
                    return { id, ...data };
                  });
                })
                .catch((error) => error)
            ).pipe(mergeMap((res) => of(res)));
          } else {
            return of(null);
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  queryFirstName(value: string): Observable<ContactSMALLid[] | any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (user) {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/contacts`
            );
            const dbQuery = query(
              dbCollection,
              where(
                'metadata.arrayFirstName',
                'array-contains',
                value.toUpperCase()
              )
            );

            return from(
              getDocs(dbQuery)
                .then((snap) => {
                  return snap.docs.map((doc: DocumentData) => {
                    const data = doc.data() as any;
                    const id = doc.id;
                    return { id, ...data };
                  });
                })
                .catch((error) => error)
            ).pipe(mergeMap((res) => of(res)));
          } else {
            return of(null);
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }
  queryCommune(filter: string, user: any): Observable<ContactSMALLid[]> {
    const dbCollection = collection(
      this.db,
      `organisations/${user.organisation.id}/contacts`
    );

    const dbQuery = query(
      dbCollection,
      where('adresse.adresse.properties.city', '==', filter)
    );

    return from(
      getDocs(dbQuery)
        .then((snap) => {
          return snap.docs.map((doc: DocumentData) => {
            const data = doc.data() as any;
            const id = doc.id;
            return { id, ...data };
          });
        })
        .catch((error) => error)
    ).pipe(mergeMap((res) => of(res)));
  }

  queryPostalCode(filter: string, user: any): Observable<ContactSMALLid[]> {
    const dbCollection = collection(
      this.db,
      `organisations/${user.organisation.id}/contacts`
    );

    const startAt = `${filter}000`;
    const endAt = `${filter}999`;

    const dbQuery = query(
      dbCollection,
      where('adresse.adresse.properties.postcode', '>=', startAt),
      where('adresse.adresse.properties.postcode', '<=', endAt)
    );

    return from(
      getDocs(dbQuery)
        .then((snap) => {
          return snap.docs.map((doc: DocumentData) => {
            const data = doc.data() as any;
            const id = doc.id;
            return { id, ...data };
          });
        })
        .catch((error) => error)
    ).pipe(mergeMap((res) => of(res)));
  }

  querySexe(filter: string, user: any): Observable<ContactSMALLid[]> {
    const dbCollection = collection(
      this.db,
      `organisations/${user.organisation.id}/contacts`
    );

    const dbQuery = query(dbCollection, where('sexe', '==', filter));

    return from(
      getDocs(dbQuery)
        .then((snap) => {
          return snap.docs.map((doc: DocumentData) => {
            const data = doc.data() as any;
            const id = doc.id;
            return { id, ...data };
          });
        })
        .catch((error) => error)
    ).pipe(mergeMap((res) => of(res)));
  }

  queryEntites(filter: string, user: any): Observable<ContactSMALLid[]> {
    const dbCollection = collection(
      this.db,
      `organisations/${user.organisation.id}/contacts`
    );

    const dbQuery = query(
      dbCollection,
      where('entitesTitles', 'array-contains', filter)
    );

    return from(
      getDocs(dbQuery)
        .then((snap) => {
          return snap.docs.map((doc: DocumentData) => {
            const data = doc.data() as any;
            const id = doc.id;
            return { id, ...data };
          });
        })
        .catch((error) => error)
    ).pipe(mergeMap((res) => of(res)));
  }

  queryFonctions(filter: string, user: any): Observable<ContactSMALLid[]> {
    const dbCollection = collection(
      this.db,
      `organisations/${user.organisation.id}/contacts`
    );

    const dbQuery = query(dbCollection, where('fonction', '==', filter));

    return from(
      getDocs(dbQuery)
        .then((snap) => {
          return snap.docs.map((doc: DocumentData) => {
            const data = doc.data() as any;
            const id = doc.id;
            return { id, ...data };
          });
        })
        .catch((error) => error)
    ).pipe(mergeMap((res) => of(res)));
  }

  querySecteur(filter: string, user: any): Observable<ContactSMALLid[]> {
    const dbCollection = collection(
      this.db,
      `organisations/${user.organisation.id}/contacts`
    );

    const dbQuery = query(
      dbCollection,
      where('entite.secteurs', 'array-contains', filter)
    );

    return from(
      getDocs(dbQuery)
        .then((snap) => {
          return snap.docs.map((doc: DocumentData) => {
            const data = doc.data() as any;
            const id = doc.id;
            return { id, ...data };
          });
        })
        .catch((error) => error)
    ).pipe(mergeMap((res) => of(res)));
  }
}
