import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as OperationFicheDePosteCandidatureActions from './operation-fiche-de-poste-candidature.actions';

export const operationFicheDePosteCandidaturesFeatureKey =
  'operationFicheDePosteCandidatures';

export interface OperationCandidatureState extends EntityState<any> {
  loading: boolean;
  candidat: any;
  documents: any;
  error: any;
  view: string;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>();

export const initialState: OperationCandidatureState = adapter.getInitialState({
  loading: false,
  candidat: undefined,
  documents: undefined,
  error: undefined,
  view: 'display',
});

export const reducer = createReducer(
  initialState,

  //SET DISPLAY VIEW
  on(
    OperationFicheDePosteCandidatureActions.setOperationFicheCandidatView,
    (state, action) => {
      return {
        ...state,
        view: action.view,
      };
    }
  ),

  //LOAD CANDIDATS
  on(
    OperationFicheDePosteCandidatureActions.loadOperationCandidatures,
    (state, action) => {
      return {
        ...state,
        loading: true,
        candidats: undefined,
        documents: undefined,
        error: undefined,
      };
    }
  ),
  on(
    OperationFicheDePosteCandidatureActions.loadOperationCandidaturesSuccess,
    (state, action) => adapter.setAll(action.candidats, state)
  ),
  on(
    OperationFicheDePosteCandidatureActions.loadOperationCandidaturesSuccess,
    (state, action) => {
      return {
        ...state,
        loading: false,
        documents: undefined,
        error: undefined,
      };
    }
  ),
  on(
    OperationFicheDePosteCandidatureActions.loadOperationCandidaturesFailure,
    (state, action) => {
      return {
        ...state,
        loading: false,
        candidat: undefined,
        documents: undefined,
        error: action.error,
      };
    }
  ),

  //LOAD CANDIDAT
  on(
    OperationFicheDePosteCandidatureActions.loadOperationCandidature,
    (state, action) => {
      return {
        ...state,
        loading: true,
        candidat: undefined,
        error: undefined,
      };
    }
  ),
  on(
    OperationFicheDePosteCandidatureActions.loadOperationCandidatureSuccess,
    (state, action) => {
      return {
        ...state,
        candidat: action.candidat,
        error: undefined,
        loading: false,
      };
    }
  ),
  on(
    OperationFicheDePosteCandidatureActions.loadOperationCandidatureFailure,
    (state, action) => {
      return {
        ...state,
        loading: false,
        candidat: undefined,
        error: action.error,
      };
    }
  ),

  //LOAD CANDIDATS SELECTED
  on(
    OperationFicheDePosteCandidatureActions.loadOperationCandidaturesSelected,
    (state, action) => {
      return {
        ...state,
        loading: true,
        candidats: undefined,
        documents: undefined,
        error: undefined,
      };
    }
  ),
  on(
    OperationFicheDePosteCandidatureActions.loadOperationCandidaturesSelectedSuccess,
    (state, action) => adapter.setAll(action.candidats, state)
  ),
  on(
    OperationFicheDePosteCandidatureActions.loadOperationCandidaturesSelectedSuccess,
    (state, action) => {
      return {
        ...state,
        loading: false,
        documents: undefined,
        error: undefined,
      };
    }
  ),
  on(
    OperationFicheDePosteCandidatureActions.loadOperationCandidaturesSelectedFailure,
    (state, action) => {
      return {
        ...state,
        loading: false,
        candidat: undefined,
        documents: undefined,
        error: action.error,
      };
    }
  ),

  //LOAD CANDIDATS REJECTED
  on(
    OperationFicheDePosteCandidatureActions.loadOperationCandidaturesRejected,
    (state, action) => {
      return {
        ...state,
        loading: true,
        candidats: undefined,
        documents: undefined,
        error: undefined,
      };
    }
  ),
  on(
    OperationFicheDePosteCandidatureActions.loadOperationCandidaturesRejectedSuccess,
    (state, action) => adapter.setAll(action.candidats, state)
  ),
  on(
    OperationFicheDePosteCandidatureActions.loadOperationCandidaturesRejectedSuccess,
    (state, action) => {
      return {
        ...state,
        loading: false,
        documents: undefined,
        error: undefined,
      };
    }
  ),
  on(
    OperationFicheDePosteCandidatureActions.loadOperationCandidaturesRejectedFailure,
    (state, action) => {
      return {
        ...state,
        loading: false,
        candidat: undefined,
        documents: undefined,
        error: action.error,
      };
    }
  ),

  //CREATE CANDIDAT

  on(
    OperationFicheDePosteCandidatureActions.addOperationCandidatureSuccess,
    (state, action) => adapter.addOne(action.candidat, state)
  ),
  on(
    OperationFicheDePosteCandidatureActions.addOperationCandidatureFailure,
    (state, action) => {
      return {
        ...state,
        loading: false,
        candidat: undefined,
        documents: undefined,
        error: action.error,
      };
    }
  ),

  //UPDATE CANDIDAT
  on(
    OperationFicheDePosteCandidatureActions.updateOperationCandidature,
    (state, action) => {
      return {
        ...state,
        loading: false,
        candidat: action.candidat.changes,
        documents: undefined,
        error: undefined,
      };
    }
  ),
  on(
    OperationFicheDePosteCandidatureActions.updateOperationCandidatureSuccess,
    (state, action) => adapter.updateOne(action.candidat, state)
  ),
  on(
    OperationFicheDePosteCandidatureActions.updateOperationCandidatureFailure,
    (state, action) => {
      return {
        ...state,
        loading: false,
        candidat: undefined,
        documents: undefined,
        error: action.error,
      };
    }
  ),

  //DELETE CANDIDAT
  on(
    OperationFicheDePosteCandidatureActions.deleteOperationCandidature,
    (state, action) => adapter.removeOne(action.candidatId, state)
  ),
  on(
    OperationFicheDePosteCandidatureActions.deleteOperationCandidature,
    (state, action) => {
      return {
        ...state,
        loading: false,
        candidat: undefined,
        documents: undefined,
        error: undefined,
      };
    }
  ),
  on(
    OperationFicheDePosteCandidatureActions.deleteOperationCandidatureSuccess,
    (state, action) => {
      return {
        ...state,
        loading: false,
        candidat: undefined,
        documents: undefined,
        error: undefined,
      };
    }
  ),
  on(
    OperationFicheDePosteCandidatureActions.deleteOperationCandidatureFailure,
    (state, action) => {
      return {
        ...state,
        loading: false,
        candidat: undefined,
        documents: undefined,
        error: action.error,
      };
    }
  ),

  //DELETE CANDIDATS
  on(
    OperationFicheDePosteCandidatureActions.deleteOperationCandidatures,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(
    OperationFicheDePosteCandidatureActions.deleteOperationCandidaturesSuccess,
    (state, action) => {
      return {
        ...state,
        loading: false,
        candidat: undefined,
        documents: undefined,
        error: undefined,
      };
    }
  ),
  on(
    OperationFicheDePosteCandidatureActions.deleteOperationCandidaturesFailure,
    (state, action) => {
      return {
        ...state,
        loading: false,
        candidat: undefined,
        documents: undefined,
        error: action.error,
      };
    }
  ),

  //CLEAR
  on(
    OperationFicheDePosteCandidatureActions.clearOperationFicheDePosteCandidatures,
    (state) => adapter.removeAll(state)
  )
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
