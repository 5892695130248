import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { OperationNoteId } from '../../store/operation-note.model';

@Component({
  selector: 'app-operation-note-item-header',
  templateUrl: './operation-note-item-header.component.html',
  styleUrls: ['./operation-note-item-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationNoteItemHeaderComponent
  implements OnInit, OnChanges, OnDestroy
{
  subscribe = new Subject();

  @Input() note$: Observable<OperationNoteId | any> = of(null);
  constructor() {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {}
}
