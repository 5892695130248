<div class="w-full flex flex-col items-start justify-between">
  <label
    for="email-address-icon"
    [class]="required ? 'label required' : 'label'"
    >E-mail</label
  >

  <div class="relative w-full">
    <div
      class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
    >
      <svg
        class="iconSvg w-6 h-6"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
        />
      </svg>
    </div>

    <input
      class="inputIconForm"
      #input
      placeholder="Courriel..."
      [formControl]="mailControl"
      (keyup)="sendMailAdresse($event)"
      required
      id="email-address-icon"
      type="text"
    />
  </div>
  <small
    *ngIf="
      !mailControl.valid && required && mailControl.dirty && mailControl.touched
    "
    class="inputFormAlert"
    >{{
      !mailControl.valid && required && mailControl.dirty && mailControl.touched
        ? "Veuillez renseigner une adresse e-mail"
        : ""
    }}</small
  >
</div>
