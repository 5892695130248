import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  NzTableFilterFn,
  NzTableFilterList,
  NzTableSortFn,
  NzTableSortOrder,
} from 'ng-zorro-antd/table';
import { Observable, of, Subject, takeUntil } from 'rxjs';

interface ColumnItem {
  name: string;
  sortOrder: NzTableSortOrder | null;
  sortFn: NzTableSortFn<any> | null;
  listOfFilter: NzTableFilterList;
  filterFn: NzTableFilterFn<any> | null;
  filterMultiple: boolean;
  sortDirections: NzTableSortOrder[] | any;
  width?: string | any;
}

@Component({
  selector: 'app-table-missions',
  templateUrl: './table-missions.component.html',
  styleUrls: ['./table-missions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableMissionsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() isParticipant: boolean = true;
  @Input() isEntite: boolean = true;

  @Input() source$: Observable<any> = of([]);
  @Output() select = new EventEmitter(false);
  @Output() add = new EventEmitter(false);
  @Output() update = new EventEmitter(false);
  @Output() delete = new EventEmitter(false);
  @Output() export = new EventEmitter(false);
  subscribe = new Subject();
  @Input() scroll: any = { x: '1100px', y: '100%' };

  listOfColumns: ColumnItem[] = [];
  expandSet = new Set<number>();

  //FILTERS
  filterByStatus: any = [
    { text: 'En cours', value: false },
    { text: 'Terminée', value: true },
  ];
  filterByStatusFn = (list: string[], item: any) =>
    list.some((status) => item.isFinished === status);

  filterByEntite: any = [];
  filterByEntiteFn = (list: string[], item: any) =>
    list.some(
      (denomination) =>
        item?.entite?.etablissement?.denomination.indexOf(denomination) !== -1
    );

  filterByEmployeur: any = [];
  filterByEmployeurFn = (list: string[], item: any) =>
    list.some((name) => item.name.indexOf(name) !== -1);

  filterByContrats: any = [];
  filterByContratsFn = (list: string[], item: any) =>
    list.some((contrat) => item.type_contrat?.CODE.indexOf(contrat) !== -1);

  //SORTS
  sortFnByMetier = (a: any, b: any) =>
    a.metier?.metier?.libelle.localeCompare(b.metier?.metier?.libelle);
  sortFnByEntite = (a: any, b: any) =>
    a?.entite?.etablissement?.denomination.localeCompare(
      b.entite?.etablissement?.denomination
    );
  sortFnByParticipant = (a: any, b: any) =>
    a?.participant?.displayName.localeCompare(b.participant?.displayName);

  sortFnByTotal = (a: any, b: any) => a.total - b.total;

  constructor() {}

  ngOnInit(): void {
    this.onSetFilter();
  }
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {
    this.subscribe.next(false);
    this.subscribe.complete();
  }

  onSetFilter(): void {
    this.source$.pipe(takeUntil(this.subscribe)).subscribe((source) => {
      if (!source?.length) return;
      const entites = source.map((el: any) => {
        const item = el?.metier?.metier?.libelle;
        return {
          text: item,
          value: item,
        };
      });
      this.filterByEntite = entites;

      const type_contrats = source.map((el: any) => {
        const item = el?.type_contrat?.CODE;
        return {
          text: item,
          value: item,
        };
      });
      this.filterByContrats = type_contrats;

      const titleFilters = source?.lenght
        ? source.map((el: any) => {
            const { title } = el;
            return { text: title, value: title };
          })
        : [];
      this.listOfColumns = [
        {
          name: 'Poste',
          sortOrder: null,
          sortFn: null,
          sortDirections: ['ascend', 'descend', null],
          filterMultiple: true,
          listOfFilter: titleFilters,
          filterFn: null,
        },
        {
          name: 'Entité',
          sortOrder: null,
          sortFn: null,
          sortDirections: ['ascend', 'descend', null],
          filterMultiple: true,
          listOfFilter: [],
          filterFn: null,
        },
        {
          name: 'Employeur',
          sortOrder: null,
          sortFn: null,
          sortDirections: ['ascend', 'descend', null],
          filterMultiple: true,
          listOfFilter: [],
          filterFn: null,
        },
        {
          name: 'Participant',
          sortOrder: null,
          sortFn: null,
          sortDirections: ['ascend', 'descend', null],
          filterMultiple: true,
          listOfFilter: [],
          filterFn: null,
        },
        {
          name: 'Échéance',
          sortOrder: null,
          sortFn: null,
          sortDirections: ['ascend', 'descend', null],
          filterMultiple: true,
          listOfFilter: [],
          filterFn: null,
        },

        {
          name: 'Contrat',
          sortOrder: null,
          sortFn: null,
          sortDirections: ['ascend', 'descend', null],
          filterMultiple: true,
          listOfFilter: [],
          filterFn: null,
        },
        {
          name: 'Heures',
          sortOrder: null,
          sortFn: null,
          sortDirections: ['ascend', 'descend', null],
          filterMultiple: true,
          listOfFilter: [],
          filterFn: null,
        },

        {
          name: '',
          sortOrder: null,
          sortFn: null,
          sortDirections: [],
          filterMultiple: false,
          listOfFilter: [],
          filterFn: null,
        },
      ];
    });
  }

  onExpandChange(id: number, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }

  onSelect(item: any): void {
    if (!item) return;
    this.select.emit(item);
  }

  onNew(): void {
    this.add.emit(true);
  }

  onSelectDropdown(event: string, item: any): void {
    if (!event || !item) return;

    switch (event) {
      case 'delete':
        this.delete.emit(item);

        break;

      default:
        break;
    }
  }
}
