import { Pipe, PipeTransform } from '@angular/core';
import memo from 'memo-decorator'

@Pipe({
  name: 'timestampToDate'
})
export class TimestampToDatePipe implements PipeTransform {
  @memo()
  transform(value: any, args?: any): any {
    if (value) {
      const date: Date = new Date(value['seconds'] * 1000)
      return date
    }
  }
}
