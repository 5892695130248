import { OperationNoteSujetId } from 'src/app/components/operation-note-sujet/store/operation-note-sujet.model';
import { OperationNoteSubtitleId } from './../../../operation-note-subtitle/store/operation-note-subtitle.model';
import { OperationNoteId } from './../../store/operation-note.model';
import { OperationId } from 'src/app/components/operation/store/operation.model';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { OperationNoteSujetState } from 'src/app/components/operation-note-sujet/store/operation-note-sujet.reducer';
import * as fromOperationNoteSujetAction from 'src/app/components/operation-note-sujet/store/operation-note-sujet.actions';
import * as fromOperationNoteSujetSelector from 'src/app/components/operation-note-sujet/store/operation-note-sujet.selectors';
import { format, fromUnixTime } from 'date-fns';
import { fr } from 'date-fns/locale';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {
  NzNotificationPlacement,
  NzNotificationService,
} from 'ng-zorro-antd/notification';
declare let html2pdf: any;

@Component({
  selector: 'app-operation-note-export',
  templateUrl: './operation-note-export.component.html',
  styleUrls: ['./operation-note-export.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationNoteExportComponent
  implements OnInit, OnChanges, OnDestroy
{
  subscribe = new Subject();
  @Input() exportElements$: Observable<any> = of(null);
  @Input() user$: Observable<any> = of(null);
  @Input() operation$: Observable<OperationId | any> = of(null);
  @Input() note$: Observable<OperationNoteId | any> = of(null);
  @Input() subtitles$: Observable<OperationNoteSubtitleId[] | any> = of(null);
  @Input() sujets$: Observable<OperationNoteSujetId[] | any> = of(null);
  @Input() participants$: Observable<any> = of(null);

  @ViewChild('exportHTML', { static: false })
  exportHTML: ElementRef | any;

  NOTIFICATION_PLACEMENT: NzNotificationPlacement = 'topRight';

  @Output() cancel: EventEmitter<boolean> = new EventEmitter(false);

  constructor(
    private operationNoteSujetsStore: Store<OperationNoteSujetState>,
    private notification: NzNotificationService
  ) {}

  ngOnInit(): void {
    this.getSujets();
  }
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getStatut__Participant(event: any): any {
    if (event !== true) {
      return '';
    } else {
      return 'X';
    }
  }

  getSujets(): void {
    this.sujets$ = this.operationNoteSujetsStore.select(
      fromOperationNoteSujetSelector.sujetsByNote
    );
  }

  //page break
  pageBreak(): any {
    // const table = document.getElementById('idSujetTbale').offsetHeight;
    // const el = document.getElementById('idTitle').offsetHeight;
    // const header = document.getElementById('idHeader').offsetHeight;
    // const sujet = document.getElementById('rowSujet').offsetHeight;
    // const action = document.getElementById('rowAction').offsetHeight;
  }

  //Item height
  itemHeigh(item: any): any {
    const h = item.clientHeight;
    if (h > 200) {
      return 'break';
    }
  }

  create_html(html: any): Element | null {
    //create HTML Element from HTML string
    //   Ex: const html: string = `
    //         <h1>
    //            Bonjour
    //        </h1>
    // `;
    const template = document.createElement('template');
    template.innerHTML = html;
    return template.content.firstElementChild;
  }

  //EXPORT VALIDATION
  onExportValidation(
    user: any,
    operation: OperationId,
    note: OperationNoteId
  ): void {
    this.notification.info('Export', 'Exportation en cours...', {
      nzDuration: 6000,
      nzKey: 'EXPORT_OPERATION_NOTE',
      nzPlacement: this.NOTIFICATION_PLACEMENT,
    });

    const currentDate: string = format(new Date(Date.now()), 'dd/MM/yyyy', {
      locale: fr,
    });

    const organisationName: string = user?.organisation?.nom_raison_sociale;

    const element = this.exportHTML.nativeElement;
    const filename: string = `CR_${operation.denomination}_${note.title}_${currentDate}.pdf`;
    const options = {
      margin: [1.2, 0.2, 0.8, 0.2],
      filename: filename,
      image: { type: 'png', quality: 1 },
      html2canvas: { scale: 2, dpi: 300, useCORS: true },
      pageBreak: {
        mode: ['css', 'legacy'],
        before: ['rowHeader', '.rowSujet'],
        after: ['.cardSujet'],
      },
      jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
    };
    const checkCrossOrigin = false;
    const xhr = new XMLHttpRequest();
    xhr.open('GET', user.organisation.logo);
    xhr.responseType = 'blob';
    xhr.send();
    xhr.addEventListener('load', () => {
      const reader = new FileReader();
      reader.readAsDataURL(xhr.response);
      reader.addEventListener('loadend', () => {
        const logo = reader.result as string;
        if (logo) {
          return html2pdf()
            .from(element)
            .set(options)
            .toPdf()
            .get('pdf')
            .then((pdf: any = new jsPDF()) => {
              let totalPages: number = pdf.internal.getNumberOfPages();

              for (var i = 1; i <= totalPages; i++) {
                pdf.setPage(i);
                // pdf.addImage(
                //   logo,
                //   'PNG',
                //   20,
                //   20,
                //   pdf.internal.pageSize.getWidth() / 5,
                //   0.2
                // );

                //SET HEADER CARD

                // Set organisme
                pdf.setFontSize(10);
                pdf.setTextColor(40);
                pdf.text(
                  `${user.organisation.nom_raison_sociale}`,
                  pdf.internal.pageSize.getWidth() / 20,
                  0.4
                );

                pdf.setFontSize(8);
                pdf.text(
                  `${
                    user.organisation?.geo_adresse
                      ? user.organisation?.geo_adresse
                      : ''
                  }`,
                  pdf.internal.pageSize.getWidth() / 20,
                  0.6
                );

                //set title
                pdf.setFontSize(14);
                pdf.setFont('helvetica', 'bold');
                i === 1
                  ? pdf.text(
                      `COMPTE RENDU`,
                      pdf.internal.pageSize.getWidth() / 2.5,
                      1.2
                    )
                  : null;

                pdf.setFontSize(12);

                i === 1
                  ? pdf.text(
                      operation.denomination,
                      pdf.internal.pageSize.getWidth() / 20,
                      1.6
                    )
                  : null;

                pdf.setFont('helvetica', 'normal');
                pdf.setFontSize(10);

                i === 1
                  ? pdf.text(
                      operation.type,
                      pdf.internal.pageSize.getWidth() / 20,
                      1.8
                    )
                  : null;

                pdf.setFont('helvetica', 'bold');
                pdf.setFontSize(10);

                i === 1
                  ? pdf.text(
                      note.title,
                      pdf.internal.pageSize.getWidth() / 20,
                      2
                    )
                  : null;

                pdf.setFont('helvetica', 'normal');
                pdf.setFontSize(10);

                i === 1
                  ? pdf.text(
                      `Date de création : ${format(
                        fromUnixTime(note.dateStart['seconds']),
                        'dd/MM/yyyy'
                      )}`,
                      pdf.internal.pageSize.getWidth() / 20,
                      2.2
                    )
                  : null;

                i === 1
                  ? pdf.text(
                      `Auteur : ${user?.displayName}`,
                      pdf.internal.pageSize.getWidth() / 20,
                      2.4
                    )
                  : null;

                // i === 1
                //   ? pdf.text(
                //       operation.description?.adresse?.adresse?.properties?.label,
                //       pdf.internal.pageSize.getWidth() / 16,
                //       2.4
                //     )
                //   : null;

                //set footer
                pdf.setFontSize(10);
                pdf.setTextColor(150);
                //divided by 2 to go center
                pdf.text(
                  'Page ' + i + ' / ' + totalPages,
                  pdf.internal.pageSize.getWidth() / 2,
                  pdf.internal.pageSize.getHeight() - 0.4
                );
                pdf.setFontSize(9);
                pdf.text(
                  organisationName.toUpperCase().substring(0, 25),
                  pdf.internal.pageSize.getWidth() / 20,
                  pdf.internal.pageSize.getHeight() - 0.4
                );
                organisationName;
              }
            })
            .then(() => {
              this.notification.success(
                'Export',
                'Exportation terminée avec succès',
                {
                  nzDuration: 6000,
                  nzKey: 'EXPORT_OPERATION_NOTE',
                  nzPlacement: this.NOTIFICATION_PLACEMENT,
                }
              );
            })
            .save();
        }
      });
    });
  }

  onExportCanvas(
    user: any,
    operation: OperationId,
    note: OperationNoteId
  ): void {
    const element = this.exportHTML.nativeElement;
    const currentDate: string = format(new Date(Date.now()), 'dd/MM/yyyy', {
      locale: fr,
    });

    const organisationName: string = user?.organisation?.nom_raison_sociale;
    const filename: string = `CR_${operation.denomination}_${note.title}_${currentDate}.pdf`;
    const options = {
      scale: 1.5,
      width: element.clientWidth,
      height: element.clientHeight,
    };

    const xhr = new XMLHttpRequest();
    xhr.open('GET', user.organisation.logo);
    xhr.responseType = 'blob';
    xhr.send();
    xhr.addEventListener('load', () => {
      const reader = new FileReader();
      reader.readAsDataURL(xhr.response);
      reader.addEventListener('loadend', () => {
        const logo = reader.result as string;
        if (logo) {
          html2canvas(element, options).then((canvas) => {
            let fileWidth = 208;
            let fileHeight = (canvas.height * fileWidth) / canvas.width;
            const FILEURI = canvas.toDataURL('image/png');
            let PDF = new jsPDF('p', 'mm', 'a4');
            let position = 0;
            PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);
            let totalPages: number = PDF.internal.pages.length;

            for (var i = 1; i <= totalPages; i++) {
              PDF.setPage(i);
              PDF.addImage(logo, 'PNG', 4, 14, 40, 40);

              //SET HEADER CARD

              // Set organisme
              PDF.setFontSize(10);
              PDF.setTextColor(40);
              PDF.text(
                `${user.organisation.nom_raison_sociale}`,
                PDF.internal.pageSize.getWidth() / 20,
                10
              );

              PDF.setFontSize(8);
              PDF.text(
                `${
                  user.organisation?.geo_adresse
                    ? user.organisation?.geo_adresse
                    : ''
                }`,
                PDF.internal.pageSize.getWidth() / 20,
                18
              );

              //set title
              PDF.setFontSize(14);
              PDF.setFont('helvetica', 'bold');
              i === 1
                ? PDF.text(
                    `COMPTE RENDU`,
                    PDF.internal.pageSize.getWidth() / 2.5,
                    14
                  )
                : null;

              PDF.setFontSize(12);

              i === 1
                ? PDF.text(
                    operation.denomination,
                    PDF.internal.pageSize.getWidth() / 20,
                    20
                  )
                : null;

              PDF.setFont('helvetica', 'normal');
              PDF.setFontSize(10);

              i === 1
                ? PDF.text(
                    operation.type,
                    PDF.internal.pageSize.getWidth() / 20,
                    24
                  )
                : null;

              PDF.setFont('helvetica', 'bold');
              PDF.setFontSize(10);

              i === 1
                ? PDF.text(
                    note.title,
                    PDF.internal.pageSize.getWidth() / 20,
                    24
                  )
                : null;

              PDF.setFont('helvetica', 'normal');
              PDF.setFontSize(10);

              i === 1
                ? PDF.text(
                    `Date de création : ${format(
                      fromUnixTime(note.dateStart['seconds']),
                      'dd/MM/yyyy'
                    )}`,
                    PDF.internal.pageSize.getWidth() / 20,
                    26
                  )
                : null;

              i === 1
                ? PDF.text(
                    `Auteur : ${user?.displayName}`,
                    PDF.internal.pageSize.getWidth() / 20,
                    28
                  )
                : null;

              // i === 1
              //   ? pdf.text(
              //       operation.description?.adresse?.adresse?.properties?.label,
              //       pdf.internal.pageSize.getWidth() / 16,
              //       2.4
              //     )
              //   : null;

              //set footer
              PDF.setFontSize(10);
              PDF.setTextColor(150);
              //divided by 2 to go center
              PDF.text(
                'Page ' + i + ' / ' + totalPages,
                PDF.internal.pageSize.getWidth() / 2,
                PDF.internal.pageSize.getHeight()
              );
              PDF.setFontSize(9);
              PDF.text(
                organisationName.toUpperCase().substring(0, 25),
                PDF.internal.pageSize.getWidth() / 20,
                PDF.internal.pageSize.getHeight()
              );
              organisationName;
            }
            PDF.save(filename);
          });
        }
      });
    });
  }

  getBase64StringFromDataURL(dataURL: string): any {
    return dataURL.replace('data:', '').replace(/^.+,/, '');
  }

  set_imageURL(url: string, callback: any): any {
    if (!url) return '';
    let image = new Image();
    image.setAttribute('crossOrigin', 'anonymous'); //getting images from external domain

    image.onload = function () {
      let canvas: any = document.createElement('canvas');
      canvas.width = 100;
      canvas.height = 100;

      //next three lines for white background in case png has a transparent background
      let ctx: any = canvas.getContext('2d');
      ctx.fillStyle = '#fff'; /// set white fill style
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      canvas.getContext('2d').drawImage(this, 0, 0);

      callback(canvas.toDataURL('image/png'));
    };

    image.src = url;
  }
  onCancel(): void {
    this.cancel.emit(true);
  }
}
