import { InformationCollectiveFilterComponent } from './../information-collective-filter/information-collective-filter.component';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { of, Subject, Observable } from 'rxjs';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { PortalNavigationService } from 'src/app/portals/portal-navigation.service';
import { InformationCollectiveFormComponent } from '../information-collective-form/information-collective-form.component';

@Component({
  selector: 'app-information-collective-list',
  templateUrl: './information-collective-list.component.html',
  styleUrls: ['./information-collective-list.component.scss'],
})
export class InformationCollectiveListComponent implements OnInit, OnDestroy {
  subscribe = new Subject();
  data$: Observable<any> = of(null);
  informationsCollectives$: Observable<any> = of([]);

  //VARIABLES
  modalWidth: number = 850;
  componentPortalFilter:
    | ComponentPortal<InformationCollectiveFilterComponent>
    | any;
  title: string = 'Nouvelle information collective';
  newCardTitle: string = 'Nouvelle information collective';
  newCardDescription: string = 'Ajouter une information collective';
  newCardDefinition: string =
    "Une information collective sert à informer et non à sélectionner, de l'efficience et de la qualité : à la fin de cette rencontre, demandez aux participant·e·s de confirmer leur intérêt en cas d'ouverture de poste ou pour la sélection en cours.";

  @ViewChild('participantTitleForm', { static: false }) participantTitleForm:
    | TemplateRef<any>
    | any;

  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private activateRouter: ActivatedRoute,
    private router: Router,
    private portalNavigationService: PortalNavigationService
  ) {}

  ngOnInit(): void {
    this.getRouteData();
    this.componentPortalFilter = new ComponentPortal(
      InformationCollectiveFilterComponent
    );
    this.setFilterPortal();
  }
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getRouteData(): void {
    this.data$ = this.activateRouter.data;
  }

  setFilterPortal(): void {
    this.portalNavigationService.setPortal(this.componentPortalFilter);
  }

  onNewInfoCollective(): void {
    const modal = this.modal.create({
      nzContent: InformationCollectiveFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.participantTitleForm,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });
    const instance = modal.getContentComponent();
    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
    instance.add.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        this.onAdd(event);
        modal.close();
      }
    });
    instance.update.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        this.onUpdate(event);
        modal.close();
      }
    });
  }

  onAdd(informationCollective: any): void {}
  onUpdate(informationCollective: any): void {}

  onDelete(id: string): void {}
}
