import { Pipe, PipeTransform } from '@angular/core';
import memo from 'memo-decorator';

@Pipe({
  name: 'numberToPercent',
})
export class NumberToPercentPipe implements PipeTransform {
  percent: number = 100;
  @memo()
  transform(value: number, ...args: string[]): unknown {
    if (!value) {
      return null;
    }

    const percent = value * this.percent;
    return percent;
  }
}
