import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

//LOAD JOUR FERIER
export const loadJoursFerier = createAction(
  '[ParticipantPlanning/API] Load Jours Feries'
);
export const loadJoursFerierSuccess = createAction(
  '[ParticipantPlanning/API] Load Jours Feries Success',
  props<{ jours: any }>()
);
export const loadJoursFerierFailure = createAction(
  '[ParticipantPlanning/API] Load Jours Feries Failure',
  props<{ error: any }>()
);

//LOAD PLANNING EVENTS
export const loadParticipantPlannings = createAction(
  '[ParticipantPlanning/API] Load ParticipantPlannings',
  props<{ id: string }>()
);
export const loadParticipantPlanningsSuccess = createAction(
  '[ParticipantPlanning/API] Load ParticipantPlannings Success',
  props<{ events: any[] }>()
);
export const loadParticipantPlanningsFailure = createAction(
  '[ParticipantPlanning/API] Load ParticipantPlannings Failure',
  props<{ error: any }>()
);

//LOAD PLANNING EVENTS FILTERED
export const loadParticipantPlanningsFiltered = createAction(
  '[ParticipantPlanning/API] Load ParticipantPlannings Filtered',
  props<{ id: string; filter: { type: string; createAtMonthYear: string } }>()
);
export const loadParticipantPlanningsFilteredSuccess = createAction(
  '[ParticipantPlanning/API] Load ParticipantPlannings Filtered Success',
  props<{ events: any[] }>()
);
export const loadParticipantPlanningsFilteredFailure = createAction(
  '[ParticipantPlanning/API] Load ParticipantPlannings Filtered Failure',
  props<{ error: any }>()
);

//ADD PLANNING EVENT
export const addParticipantPlanning = createAction(
  '[ParticipantPlanning/API] Add ParticipantPlanning',
  props<{ id: string; event: any }>()
);
export const addParticipantPlanningSuccess = createAction(
  '[ParticipantPlanning/API] Add ParticipantPlanning Success',
  props<{ event: any }>()
);
export const addParticipantPlanningFailure = createAction(
  '[ParticipantPlanning/API] Add ParticipantPlanning Failure',
  props<{ error: any }>()
);

//UPDATE PLANNING EVENT
export const updateParticipantPlanning = createAction(
  '[ParticipantPlanning/API] Update ParticipantPlanning',
  props<{ id: string; event: Update<any> }>()
);
export const updateParticipantPlanningSuccess = createAction(
  '[ParticipantPlanning/API] Update ParticipantPlanning Success',
  props<{ succes: String }>()
);
export const updateParticipantPlanningFailure = createAction(
  '[ParticipantPlanning/API] Update ParticipantPlanning Failure',
  props<{ error: any }>()
);

//DELETE PLANNING EVENT
export const deleteParticipantPlanning = createAction(
  '[ParticipantPlanning/API] Delete ParticipantPlanning',
  props<{ participantId: string; id: string }>()
);
export const deleteParticipantPlanningSuccess = createAction(
  '[ParticipantPlanning/API] Delete ParticipantPlanning Success',
  props<{ success: string }>()
);
export const deleteParticipantPlanningFailure = createAction(
  '[ParticipantPlanning/API] Delete ParticipantPlanning Failure',
  props<{ error: any }>()
);

//DELETE PLANNING EVENTS
export const deleteParticipantPlannings = createAction(
  '[ParticipantPlanning/API] Delete ParticipantPlannings',
  props<{ id: string; ids: string[] }>()
);
export const deleteParticipantPlanningsSuccess = createAction(
  '[ParticipantPlanning/API] Delete ParticipantPlannings Success',
  props<{ success: string }>()
);
export const deleteParticipantPlanningsFailure = createAction(
  '[ParticipantPlanning/API] Delete ParticipantPlannings Failure',
  props<{ error: any }>()
);

//CLEAR
export const clearParticipantPlannings = createAction(
  '[ParticipantPlanning/API] Clear ParticipantPlannings'
);
