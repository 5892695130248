<nz-card
  [nzTitle]="departemetCardTitle"
  [nzExtra]="departementCardExtra"
  nzHoverable
  *ngIf="departement"
  (click)="$event.stopPropagation()"
  [routerLink]="['/service', departement.id]"
>
  <ng-template #departemetCardTitle>
    <app-header-title [icon]="'group_work'"></app-header-title>
  </ng-template>

  <div class="serviceCard_title">
    <strong>{{ departement.title | uppercase | carLimitation : 80 }}</strong>
  </div>
  <div class="serviceCard_definition">
    {{ departement?.definition | carLimitation : 140 }}
  </div>

  <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="center">
    <nz-col nzSpan="24">
      <app-avatar-group
        [source]="departement?.users"
        [limit]="8"
      ></app-avatar-group>
    </nz-col>
  </nz-row>
</nz-card>

<ng-template #departementCardExtra>
  <app-button-dropdown
    *ngIf="(user$ | async | isAdmin) || (user$ | async | isDev)"
    [isDelete]="true"
    [deleteType]="deleteType"
    (click)="$event.stopPropagation()"
    (select)="onAction($event, departement)"
  ></app-button-dropdown>
</ng-template>
