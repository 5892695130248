import {
  NzNotificationPlacement,
  NzNotificationService,
} from 'ng-zorro-antd/notification';
import { DepartementService } from './../../../service/departement.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromOrganisationAction from './departement.actions';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { QueryDepartementsService } from 'src/app/service/query-departements.service';
import { DepartementId } from './departement.model';

const NOTIFICATION_PLACEMENT: NzNotificationPlacement = 'topRight';

@Injectable()
export class DepartementEffects {
  loadDepartements$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOrganisationAction.loadDepartements),
      mergeMap((action) =>
        this.departementService.getAll().pipe(
          map((departements: any) => {
            //const departements = repons.data.departements;
            return fromOrganisationAction.loadDepartementsSuccess({
              departements,
            });
          }),
          catchError((error) =>
            of(fromOrganisationAction.loadDepartementsFailure({ error }))
          )
        )
      )
    )
  );
  loadDepartementsServices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOrganisationAction.loadDepartementsServces),
      mergeMap((action) =>
        this.departementService.getService().pipe(
          map((snap: any) => {
            const departements: any = snap?.data ? snap.data.services : null;
            return fromOrganisationAction.loadDepartementsServcesSuccess({
              departements,
            });
          }),
          catchError((error) => {
            return of(
              fromOrganisationAction.loadDepartementsServcesFailure({ error })
            );
          })
        )
      )
    )
  );

  loadDepartement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOrganisationAction.loadDepartement),
      mergeMap((action) =>
        this.departementService.getOne(action.id).pipe(
          map((departement: any) =>
            fromOrganisationAction.loadDepartementSuccess({ departement })
          ),
          catchError((error) =>
            of(fromOrganisationAction.loadDepartementFailure({ error }))
          )
        )
      )
    )
  );
  loadDepartementsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOrganisationAction.loadDepartementsList),
      mergeMap((action) =>
        this.departementService.getDepartementsList().pipe(
          map((respons: any) => {
            const departements = respons.data.departementsList;

            return fromOrganisationAction.loadDepartementsListSuccess({
              departements,
            });
          }),
          catchError((error) => {
            this.onErrorNotification(error, 'LOAD_DEPARTEMENTS_LIST');
            return of(
              fromOrganisationAction.loadDepartementsListFailure({ error })
            );
          })
        )
      )
    )
  );

  addDepartement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOrganisationAction.addDepartement),
      tap(() => {
        this.notification.info(
          'Nouveau service',
          'Création du nouveau service en cours...',
          {
            nzDuration: 6000,
            nzAnimate: true,
            nzPlacement: NOTIFICATION_PLACEMENT,
            nzKey: 'CREATE_DEPARTEMENT',
          }
        );
      }),
      mergeMap((action) =>
        this.departementService.createDepartement(action.departement).pipe(
          map((respons: any) => {
            const success = respons.data.createDepartement;
            this.notification.success('Nouveau service', success, {
              nzDuration: 8000,
              nzAnimate: true,
              nzPlacement: NOTIFICATION_PLACEMENT,
              nzKey: 'CREATE_DEPARTEMENT',
            });
            fromOrganisationAction.loadingDepartements({ loading: false });
            return fromOrganisationAction.addDepartementSuccess({
              success,
            });
          }),
          catchError((error) => {
            this.onErrorNotification(error, 'CREATE_DEPARTEMENT');
            return of(fromOrganisationAction.addDepartementFailure({ error }));
          })
        )
      )
    )
  );

  updateDepartement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOrganisationAction.updateDepartement),
      tap(() => {
        this.notification.info(
          'Modification service',
          'Modification du service en cours...',
          {
            nzDuration: 6000,
            nzAnimate: true,
            nzPlacement: NOTIFICATION_PLACEMENT,
            nzKey: 'UPDATE_DEPARTEMENT',
          }
        );
      }),

      mergeMap((action) =>
        this.departementService
          .updateDepartement(action.departement.changes)
          .pipe(
            map((respons) => {
              const success = respons.data.updateDepartement;
              this.notification.success('Modification service', success, {
                nzDuration: 8000,
                nzAnimate: true,
                nzPlacement: NOTIFICATION_PLACEMENT,
                nzKey: 'UPDATE_DEPARTEMENT',
              });
              fromOrganisationAction.loadingDepartements({ loading: false });
              return fromOrganisationAction.updateDepartementSuccess();
            }),
            catchError((error) => {
              this.onErrorNotification(error, 'UPDATE_DEPARTEMENT');

              return of(
                fromOrganisationAction.updateDepartementFailure({ error })
              );
            })
          )
      )
    )
  );

  deleteDepartement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOrganisationAction.deleteDepartement),
      tap(() => this.router.navigate(['/services'])),
      tap(() => {
        this.notification.success(
          'Suppression service',
          'Suppression du service en cours...',
          {
            nzDuration: 6000,
            nzAnimate: true,
            nzPlacement: NOTIFICATION_PLACEMENT,
            nzKey: 'DELETE_DEPARTEMENT',
          }
        );
      }),
      mergeMap((action) =>
        this.departementService.deleteDepartement(action.id).pipe(
          map((respons: any) => {
            const success = respons.data.deleteDepartement;
            this.notification.success('Suppression service', success, {
              nzDuration: 8000,
              nzAnimate: true,
              nzPlacement: NOTIFICATION_PLACEMENT,
              nzKey: 'DELETE_DEPARTEMENT',
            });
            return fromOrganisationAction.deleteDepartementSuccess({
              success,
            });
          }),
          catchError((error) => {
            this.onErrorNotification(error, 'DELETE_DEPARTEMENT');

            return of(
              fromOrganisationAction.deleteDepartementFailure({ error })
            );
          })
        )
      )
    )
  );

  deleteAllDepartement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOrganisationAction.deleteDepartements),
      tap(() => this.router.navigate(['/services'])),
      tap(() => {
        this.notification.success(
          'Suppression des services',
          'Suppression des services en cours...',
          {
            nzDuration: 6000,
            nzAnimate: true,
            nzPlacement: NOTIFICATION_PLACEMENT,
            nzKey: 'DELETE_ALL_DEPARTEMENTS',
          }
        );
      }),
      mergeMap((action) =>
        this.departementService.deleteAllDepartements().pipe(
          map((respons: any) => {
            const success = respons.data.deleteAllDepartement;
            this.notification.success('Suppression des services', success, {
              nzDuration: 8000,
              nzAnimate: true,
              nzPlacement: NOTIFICATION_PLACEMENT,
              nzKey: 'DELETE_ALL_DEPARTEMENTS',
            });
            return fromOrganisationAction.deleteDepartementsSuccess({
              success,
            });
          }),
          catchError((error) => {
            this.onErrorNotification(error, 'DELETE_ALL_DEPARTEMENTS');

            return of(
              fromOrganisationAction.deleteDepartementsFailure({ error })
            );
          })
        )
      )
    )
  );

  queryDepartements$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOrganisationAction.queryDepartements),
      mergeMap((action) =>
        this.queryDepartement.queryDepartements(action.filter).pipe(
          map((departements: DepartementId[] | any) =>
            fromOrganisationAction.queryDepartementsSuccess({ departements })
          ),
          catchError((error) =>
            of(fromOrganisationAction.queryDepartementsFailure({ error }))
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private message: NzMessageService,
    private departementService: DepartementService,
    private queryDepartement: QueryDepartementsService,
    private router: Router,
    private notification: NzNotificationService
  ) {}

  onErrorNotification(error: any, key: string): void {
    this.notification.error('Error', error.message, {
      nzDuration: 10000,
      nzAnimate: true,
      nzPlacement: NOTIFICATION_PLACEMENT,
      nzKey: key ? key : '',
    });
  }
}
