import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ParticipantId } from 'src/app/components/participant/store/participant.model';

@Component({
  selector: 'app-projet-professionnel-diagnostics',
  templateUrl: './projet-professionnel-diagnostics.component.html',
  styleUrls: ['./projet-professionnel-diagnostics.component.scss'],
})
export class ProjetProfessionnelDiagnosticsComponent implements OnInit {
  @Input() projetprofessionnel$ = new BehaviorSubject<any>(null);
  @Input() participant: ParticipantId | any = null;

  constructor() {}

  ngOnInit(): void {}
}
