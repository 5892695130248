import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableDocumentsComponent } from './components/table-documents/table-documents.component';
import { DocumentTypeComponent } from './components/document-type/document-type.component';
import { DocumentsListComponent } from './components/documents-list/documents-list.component';
import { DocumentService } from './services/document.service';
import * as fromDocument from './components/document/store/document.reducer';
import { DocumentEffects } from './components/document/store/document.effects';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ZorroModule } from 'src/app/css/zorro.module';
import { AvatarModule } from '../avatar/avatar.module';
import { PipeModule } from 'src/app/pipe/pipe.module';
import { ButtonsModule } from 'src/app/contents/components/buttons/buttons.module';
import { HeaderModule } from 'src/app/contents/components/header-title/header.module';
import { NoResultModule } from 'src/app/contents/components/no-result/no-result.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/css/material.module';
import { UiModule } from 'src/app/ui/ui/ui.module';
import { LayoutModule } from 'src/app/contents/components/layout/layout.module';
import { DocumentComponent } from './components/document/document.component';
import { ContentsModule } from 'src/app/contents/contents.module';
import { DirectiveModule } from 'src/app/directive/directive.module';

@NgModule({
  declarations: [
    TableDocumentsComponent,
    DocumentTypeComponent,
    DocumentsListComponent,
    DocumentComponent,
  ],
  exports: [
    TableDocumentsComponent,
    DocumentTypeComponent,
    DocumentsListComponent,
    DocumentComponent,
  ],
  imports: [
    CommonModule,
    ZorroModule,
    AvatarModule,
    PipeModule,
    ButtonsModule,
    HeaderModule,
    NoResultModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    UiModule,
    LayoutModule,
    ContentsModule,
    DirectiveModule,
    EffectsModule.forFeature([DocumentEffects]),
    StoreModule.forFeature(
      fromDocument.documentsFeatureKey,
      fromDocument.reducer
    ),
  ],
  providers: [DocumentService],
})
export class DocumentModule {}
