<ng-container *ngIf="participant$ | async as participant">
  <div [ngSwitch]="view">
    <div *ngSwitchCase="'list'">
      <nz-page-header
        class="page-header"
        [nzTitle]="header"
        [nzSubtitle]="cardExtra"
        *ngIf="experiences$ | async | size"
      >
        <nz-page-header-extra> </nz-page-header-extra>
        <nz-page-header-content> </nz-page-header-content>
      </nz-page-header>
      <nz-divider
        nzOrientation="center"
        nzType="horizontal"
        *ngIf="experiences$ | async | size"
      ></nz-divider>

      <ng-template #header>
        <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
          <nz-col nzSpan="24">
            <h4 nz-typography>{{ titlePage }}</h4>
          </nz-col>
        </nz-row>
      </ng-template>
      <ng-template #cardExtra>
        <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
          <nz-col nzSpan="24">
            <nz-badge
              [nzCount]="experiences$ | async | size"
              [nzOverflowCount]="10"
            >
            </nz-badge>
          </nz-col>
        </nz-row>
      </ng-template>

      <app-layout-list
        *ngIf="experiences$ | async | size; else noResults"
        [new]="new"
        [card]="card"
        [source]="experiences$ | async"
      ></app-layout-list>
    </div>
    <div *ngSwitchCase="'details'">
      <app-participant-experiences-item
        [experience]="currentSelected"
        (back)="onBack()"
      ></app-participant-experiences-item>
    </div>
    <div *ngSwitchDefault>
      <app-layout-list
        *ngIf="experiences$ | async | size; else noResults"
        [new]="new"
        [card]="card"
        [source]="experiences$ | async"
      ></app-layout-list>
    </div>
  </div>

  <ng-template #new>
    <app-card-new
      [type]="'experience'"
      [title]="newCardTitle"
      [description]="''"
      [definition]="newCardDefinition"
      (add)="onNew(participant)"
      (back)="onBack()"
      (cancel)="onBack()"
    ></app-card-new>
  </ng-template>

  <ng-template #card let-item let-position="idx">
    <nz-card
      nzHoverable
      [nzTitle]="experienceCardTitle"
      [nzExtra]="experienceCardExtra"
      (click)="$event.stopPropagation(); onSelect(item, participant)"
    >
      <ng-template #experienceCardTitle>
        <app-header-title [icon]="'work_history'"></app-header-title>
      </ng-template>
      <ng-template #experienceCardExtra>
        <button
          nz-button
          nzShape="circle"
          nzType="text"
          nz-dropdown
          [nzDropdownMenu]="cardExperienceMenu"
          nzPlacement="bottomRight"
          nzTrigger="click"
          (click)="$event.stopPropagation()"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
      </ng-template>

      <strong>{{ item?.metier?.libelle | carLimitation: 100 }}</strong>

      <nz-divider></nz-divider>

      <nz-row nzAlign="top" nzGutter="8" nzJustify="start">
        <nz-col nzSpan="4"> <mat-icon>domain</mat-icon></nz-col>
        <nz-col nzSpan="20">
          <p>{{ item?.entite?.denomination | carLimitation: 80 }}</p>
        </nz-col>
      </nz-row>

      <nz-divider></nz-divider>
      <nz-row [nzGutter]="16" nzAlign="middle" nzJustify="center">
        <nz-col [nzSpan]="24">
          <nz-statistic
            [nzValue]="(item?.period?.durationMonths | number)!"
            [nzTitle]="'Nombre de mois'"
            [nzPrefix]="prefixTpl"
          ></nz-statistic>
          <ng-template #prefixTpl
            ><i nz-icon nzType="field-time"></i
          ></ng-template>
        </nz-col>
      </nz-row>
    </nz-card>

    <nz-dropdown-menu #cardExperienceMenu="nzDropdownMenu">
      <ul nz-menu>
        <li
          nz-menu-item
          nzDanger
          nz-popconfirm
          nzPopconfirmTrigger="click"
          nzPopconfirmTitle="Supprimer l'expérience ?"
          (nzOnCancel)="onCancel()"
          (nzOnConfirm)="onDelete(participant, item)"
        >
          <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
            <nz-col nzSpan="6"><mat-icon>delete</mat-icon></nz-col>
            <nz-col nzSpan="18">Supprimer</nz-col>
          </nz-row>
        </li>
      </ul>
    </nz-dropdown-menu>
  </ng-template>

  <ng-template #noResults>
    <app-no-result
      [icon]="'work_history'"
      [addtitle]="newCardDescription"
      [notitle]="newCardNone"
      [description]="newCardDefinition"
      (new)="onNew(participant)"
    ></app-no-result>
  </ng-template>

  <ng-template #experienceTitleForm>
    <app-header-title
      [icon]="'work_history'"
      [title]="newCardTitle"
    ></app-header-title>
  </ng-template>
</ng-container>
