import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';
import * as fromUserAction from 'src/app/components/user/store/user.actions';

import { ParticipantState } from '../../store/participant.reducer';
import * as fromParticipantSelector from '../../store/participant.selectors';
import * as fromParticipantAction from '../../store/participant.actions';
import { ParticipantSmallId } from '../../store/participant.model';

@Component({
  selector: 'app-participant-filters',
  templateUrl: './participant-filters.component.html',
  styleUrls: ['./participant-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantFiltersComponent implements OnInit {
  title: string = 'Participants';
  participants$: Observable<ParticipantSmallId[] | any> = of(null);
  currentView$ = new BehaviorSubject<string>('participants');

  filters$: Observable<any> = of(null);
  roms$: Observable<any> = of([]);
  favoris$: Observable<any> = of([]);
  tags$: Observable<any> = of([]);
  filtersActivated: boolean = false;
  filtersOrders: any[] = [];
  cleaner: boolean = false;
  optionCategorie: any;
  optionActivity: any;
  optionSecteur: any;
  optionCommune: any;
  optionCodePostal: any;
  optionRegion: any;
  optionNature: any;
  optionType: any;
  optionStatut: any;
  optionEss: any;
  optionAdhesion: any;
  optionYear: any;
  optionOrgine: any;
  optionMetiers: any;

  filterForm: UntypedFormGroup = this.fb.group({});

  constructor(
    private fb: UntypedFormBuilder,
    private userStore: Store<UserState>,
    private participantStore: Store<ParticipantState>
  ) {}

  ngOnInit(): void {
    this.getParticipants();
    this.getFilters();
    this.loadParticipants();
    this.getFavoris();
  }

  getParticipants(): void {
    this.participants$ = this.participantStore.select(
      fromParticipantSelector.participants
    );
  }

  setCurrentView(event: string): void {
    if (!event) return;
    this.currentView$.next(event);
  }

  loadFilters(): void {
    this.participantStore.dispatch(
      fromParticipantAction.loadParticipantFilters()
    );
  }

  getFilters(): void {
    this.filters$ = this.participantStore.select(
      fromParticipantSelector.filters
    );
  }

  getFavoris(): void {
    this.favoris$ = this.userStore.select(fromUserSelector.favorisEntites);
  }

  getTags(): void {
    this.tags$ = this.userStore.select(fromUserSelector.favorisEntites);
  }

  loadParticipants(): void {
    this.filtersActivated = false;
    this.cleaner = false;
    this.filtersOrders = [];
    this.participantStore.dispatch(fromParticipantAction.loadParticipants());
  }

  filtersByLastName(event: string): void {
    if (!event) {
      this.loadParticipants();
      this.filtersActivated = false;
    } else {
      const type = 'lastname';
      this.filtersActivated = true;
      this.participantStore.dispatch(
        fromParticipantAction.queryParticipants({
          filter: event,
          filterType: type,
        })
      );
    }
  }

  filtersByFirstName(event: string): void {
    if (!event) {
      this.loadParticipants();
      this.filtersActivated = false;
    } else {
      const type = 'firstname';
      this.filtersActivated = true;
      this.participantStore.dispatch(
        fromParticipantAction.queryParticipants({
          filter: event,
          filterType: type,
        })
      );
    }
  }

  onQuery(event: any, typeFilter: string, arrayContains?: any): any {
    // if (!event) {
    //   this.loadContacts();
    //   return null;
    // } else {
    //   const type = this.setFilterType(typeFilter);
    //   this.filtersActivated = true;
    //   this.cleaner = true;
    //   if (type !== 'favoris') {
    //     this.contactStore.dispatch(
    //       fromContactAction.queryContacts({ filter: { event, type } })
    //     );
    //   } else {
    //     this.contactStore.dispatch(
    //       fromContactAction.queryContacts({
    //         filter: { event: arrayContains, type },
    //       })
    //     );
    //   }
    // }
  }
  onClear(): void {
    this.loadParticipants();
  }
}
