import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  EntiteSuiviState,
  entiteSuivisFeatureKey,
  selectAll,
} from './entite-suivi.reducer';

export const selectEntitesSuiviState = createFeatureSelector<EntiteSuiviState>(
  entiteSuivisFeatureKey
);

export const suivis = createSelector(selectEntitesSuiviState, selectAll);

export const suivi = createSelector(
  selectEntitesSuiviState,
  (state: EntiteSuiviState) => state.suivi
);

export const loading = createSelector(
  selectEntitesSuiviState,
  (state: EntiteSuiviState) => state.loading
);
export const error = createSelector(
  selectEntitesSuiviState,
  (state: EntiteSuiviState) => state.error
);
