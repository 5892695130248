<nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
  <nz-col nzSpan="8">
    <app-header-title
      [icon]="'share'"
      [title]="title"
      [subtitle]="true"
    ></app-header-title>
  </nz-col>
  <nz-col nzSpan="2">
    <nz-badge
      [nzCount]="personsArray.length"
      [nzOffset]="[10, 5]"
      nzStandalone="true"
    >
    </nz-badge>
  </nz-col>
  <nz-col nzSpan="10"></nz-col>
  <nz-col nzSpan="2">
    <button
      nz-button
      nzBlock
      (click)="onAddPerson()"
      nz-tooltip
      nzTooltipPlacement="bottom"
      nzTooltipTitle="Ajouter un destinataire"
      [nzTooltipMouseEnterDelay]="0.5"
    >
      Ajouter
    </button>
  </nz-col>
  <nz-col nzSpan="2">
    <button
      nz-button
      nzBlock
      nzType="primary"
      (click)="onSharing()"
      [disabled]="!personsArray.length"
    >
      Partager
    </button>
  </nz-col>
</nz-row>
<nz-space nzDirection="vertical"></nz-space>

<nz-card>
  <form [formGroup]="sharingForm" *ngIf="personsArray?.length; else noData">
    <div class="formControl" formArrayName="persons">
      <div
        *ngFor="let el of personsArray?.controls; let i = index"
        [formGroupName]="i"
      >
        <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
          <nz-col nzSpan="6">
            <nz-form-item>
              <nz-form-label required nzSpan="24">Nom</nz-form-label>
              <nz-form-control>
                <input
                  nz-input
                  nzBorderless
                  placeholder="Nom"
                  formControlName="lastName"
                  required
                />
              </nz-form-control>
            </nz-form-item>
          </nz-col>
          <nz-col nzSpan="6">
            <nz-form-item>
              <nz-form-label required nzSpan="24">Prénom</nz-form-label>
              <nz-form-control>
                <input
                  nz-input
                  nzBorderless
                  placeholder="Prénom"
                  formControlName="firstName"
                  required
                />
              </nz-form-control>
            </nz-form-item>
          </nz-col>
          <nz-col nzSpan="6">
            <nz-form-item>
              <nz-form-label required nzSpan="24">E-mail</nz-form-label>
              <nz-form-control>
                <input
                  nz-input
                  nzBorderless
                  placeholder="E-mail"
                  formControlName="email"
                  required
                />
              </nz-form-control>
            </nz-form-item>
          </nz-col>
          <nz-col nzSpan="3">
            <nz-form-item>
              <nz-form-label required nzSpan="24">Mobile</nz-form-label>
              <nz-form-control>
                <nz-input-number
                  [nzMin]="0"
                  nzBorderless
                  nzPlaceHolder="Numéro de mobile"
                  formControlName="phoneNumber"
                  required
                ></nz-input-number>
              </nz-form-control>
            </nz-form-item>
          </nz-col>
          <nz-col nzSpan="1">
            <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
          </nz-col>

          <nz-col nzSpan="2">
            <button
              nz-button
              nzShape="circle"
              nzType="text"
              (click)="onRemovePerson(i)"
            >
              <mat-icon class="delete">delete</mat-icon>
            </button>
          </nz-col>
        </nz-row>
        <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
      </div>
    </div>
  </form>
</nz-card>

<ng-template #noData>
  <app-no-result
    [icon]="'person'"
    [isButton]="false"
    [title]="'Aucun destinataire ajouté'"
    [description]="'Aucun destinataire ajouté'"
  ></app-no-result>
</ng-template>
