import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TodoId } from 'src/app/models/todo.model';

@Component({
  selector: 'app-todo-form-header',
  templateUrl: './todo-form-header.component.html',
  styleUrls: ['./todo-form-header.component.scss'],
})
export class TodoFormHeaderComponent implements OnInit {
  mouseEnterDelay: number = 0.5;
  @Input() user: any = null;
  @Input() todo: TodoId | any = null;
  @Input() update: boolean = false;
  @Output() add = new EventEmitter<boolean>(false);
  @Output() updated = new EventEmitter<TodoId>(false);
  @Output() completed = new EventEmitter<boolean>(false);
  @Output() cancel = new EventEmitter<boolean>(false);
  @Output() delete = new EventEmitter<TodoId>(false);

  constructor() {}

  ngOnInit(): void {}

  onAdd(): void {
    this.add.emit(true);
  }

  onUpdate(todo: TodoId): void {
    this.updated.emit(todo);
  }

  onCompleted(): void {}

  onDelete(todo: TodoId): void {
    this.delete.emit(todo);
  }

  onCancel(): void {
    this.cancel.emit(true);
  }
}
