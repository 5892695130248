import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';
import { UtilitiesState } from 'src/app/contents/components/utilities/store/utilities.reducer';
import * as fromUtilitiesSelector from 'src/app/contents/components/utilities/store/utilities.selectors';
import * as fromUtilitiesAction from 'src/app/contents/components/utilities/store/utilities.actions';
import { Timestamp } from 'firebase/firestore';

@Component({
  selector: 'app-participant-diagnostic-form-certifications',
  templateUrl: './participant-diagnostic-form-certifications.component.html',
  styleUrls: ['./participant-diagnostic-form-certifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantDiagnosticFormCertificationsComponent
  implements OnInit, OnChanges, OnDestroy
{
  subscribe = new Subject();

  //FORM FIELDS
  certificationForm: FormGroup = this.fb.group({});

  //INIT
  @Input() certificationUpdate$: Observable<any> = of(null);
  @Output() add = new EventEmitter(false);

  constructor(
    private fb: FormBuilder,
    private utilitiesStore: Store<UtilitiesState>
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }
  form(): void {
    this.formInit();
    this.formCreate();
    this.formSet();
  }

  formInit(): void {}

  formCreate(): void {
    this.certificationForm = this.fb.group({});
  }

  formSet(): void {
    this.certificationUpdate$
      .pipe(takeUntil(this.subscribe))
      .subscribe((certification) => {
        if (!certification) return;
        this.certificationForm.patchValue({ ...certification });
      });
  }

  checkboxChanges(event: boolean, type: string): void {}

  onFormChanges(): void {
    this.certificationForm.valueChanges
      .pipe(takeUntil(this.subscribe))
      .subscribe((values) => this.add.emit(values));
  }
}
