import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isAdmin',
})
export class IsAdminPipe implements PipeTransform {
  transform(user: any): boolean {
    if (!user) {
      return false;
    }
    const { claims } = user;
    if (!claims.admin) {
      return false;
    }

    return true;
  }
}
