<nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
  <nz-col nzSpan="12">
    <app-operation-dashboard-header-card
      [title]="''"
      [total]="dashboard?.totalModules"
      [color]="moduleColor"
      [icon]="moduleIcon"
      [isKpi]="true"
      [type]="'primary'"
      [dateStart]="
        operation?.description?.calendrier?.dateStart | dateFr : 'small'
      "
      [dateEnd]="operation?.description?.calendrier?.dateEnd | dateFr : 'small'"
      [dateUpdate]="
        operation?.description?.calendrier?.dateUpdate | dateFr : 'small'
      "
      [kpiPourcent]="operation?.progression"
      [kpiReal]="operation?.totalsHours"
      [kpiObjectif]="operation?.description?.objectifs"
      [modulesSize]="dashboard?.modules?.data | size"
      [actionsSize]="dashboard?.actions?.data | size"
      (click)="onSelect('operation')"
    ></app-operation-dashboard-header-card>
  </nz-col>

  <nz-col nzSpan="4">
    <app-operation-dashboard-header-card
      [title]="'Entités'"
      [total]="operation?.entitesSize"
      [color]="entiteColor"
      [icon]="entiteIcon"
      (click)="onSelect('entites')"
    ></app-operation-dashboard-header-card>
  </nz-col>

  <nz-col nzSpan="4">
    <app-operation-dashboard-header-card
      [title]="'Participants'"
      [total]="operation?.participantsSize"
      [color]="participantColor"
      [icon]="participantIcon"
      (click)="onSelect('participants')"
    ></app-operation-dashboard-header-card>
  </nz-col>
  <nz-col nzSpan="4">
    <app-operation-dashboard-header-card
      [title]="'Missions'"
      [total]="operation?.missionsSize"
      [color]="missionColor"
      [icon]="missionIcon"
      (click)="onSelect('missions')"
    ></app-operation-dashboard-header-card>
  </nz-col>
</nz-row>
