<ng-container *ngIf="contact$ | async as contact">
  <app-layout-grid [details]="details"></app-layout-grid>

  <ng-template #details>
    <nz-card nzBorderless>
      <div [ngSwitch]="bodyComponent">
        <div *ngSwitchCase="'details'">
          <app-contact-detail [contact]="contact"></app-contact-detail>
        </div>
        <div *ngSwitchCase="'form'">
          <app-contact-form></app-contact-form>
        </div>
        <div *ngSwitchCase="'suivis'">
          <app-contact-suivis></app-contact-suivis>
        </div>
        <div *ngSwitchCase="'documents'">
          <app-contact-documents></app-contact-documents>
        </div>
        <div *ngSwitchCase="'projets'">
          <app-contact-operations></app-contact-operations>
        </div>
        <div *ngSwitchCase="'dashboard'">
          <app-contact-dashboard></app-contact-dashboard>
        </div>
        <div *ngSwitchCase="'metiers'">
          <app-contact-metiers
            [contact]="contact$ | async"
          ></app-contact-metiers>
        </div>
        <div *ngSwitchCase="'entites'">
          <app-contact-entites
            [contact]="contact$ | async"
          ></app-contact-entites>
        </div>
        <div *ngSwitchDefault></div>
      </div>
    </nz-card>
  </ng-template>
</ng-container>

<ng-template #contactFormTitle>
  <app-header-title [icon]="'person'" [title]="title"></app-header-title>
</ng-template>
