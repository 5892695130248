<nz-row nzAlign="middle" nzGutter="4" nzJustify="start">
  <nz-col nzSpan="6">
    <h4 nz-typography>{{ title }}</h4>
  </nz-col>
  <nz-col *ngFor="let item of selectors" [nzSpan]="item.col">
    <button
      nz-button
      nzShape="round"
      (click)="onSelect(item.select)"
      nzMode="checkable"
      [nzType]="selected === item.select ? 'primary' : 'text'"
      nzSize="default"
    >
      <i *ngIf="item?.icon" nz-icon [nzType]="item?.icon"></i>
      <span>{{ item.name }}</span>
    </button>
  </nz-col>
  <nz-col nzSpan="5"></nz-col>
</nz-row>
