import {
  NzNotificationPlacement,
  NzNotificationService,
} from 'ng-zorro-antd/notification';
import { ParticipantExportPdfService } from './../../../service/participant-export-pdf.service';
import { AidsEligibleService } from './../../../service/aids-eligible.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Router } from '@angular/router';
import { ParticipantState } from './participant.reducer';
import { Store } from '@ngrx/store';
import { OperationSmallId } from 'src/app/components/operation/store/operation.model';
import { ParticipantOperationsService } from './../../../service/participant-operations.service';
import { ItemEventId } from '../../../features/suivi/components/item-event/itemEvent.model';
import { QueryParticipantsService } from './../../../service/query-participants.service';
import { ParticipantService } from './../../../service/participant.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  mergeMap,
  map,
  tap,
  debounceTime,
  exhaustMap,
  delay,
} from 'rxjs/operators';
import * as fromParticipantAction from './participant.actions';
import { of } from 'rxjs';
import {
  Participant,
  ParticipantId,
  ParticipantSmallId,
} from './participant.model';
import { ParticipantAidsService } from 'src/app/service/participant-aids.service';
import { UtilitiesService } from 'src/app/service/utilities/utilities.service';
import { ExportPDFService } from 'src/app/service/export-pdf.service';
import { ParticipantExportWordService } from 'src/app/service/participant-export-word.service';
import { ParticipantExportPptService } from 'src/app/service/participant-export-ppt.service';
import { ParticipantStatistiquesService } from 'src/app/service/participant-statistiques.service';
import { ParticipantExperiencesService } from 'src/app/service/participant-experiences.service';

const NOTIFICATION_PLACEMENT: NzNotificationPlacement = 'topRight';
@Injectable()
export class ParticipantEffects {
  filtersParticipant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromParticipantAction.loadParticipantFilters),
      mergeMap((action) =>
        this.aidsService.getFiltersParticipants().pipe(
          delay(2000),

          map((response: any) => {
            const filters = response.data.aidesEligiblesFilters;
            this.participantStore.dispatch(
              fromParticipantAction.loading({ loading: false })
            );
            return fromParticipantAction.loadParticipantFiltersSuccess({
              filters: filters,
            });
          }),
          catchError((error) => {
            this.participantStore.dispatch(
              fromParticipantAction.loading({ loading: false })
            );
            return of(
              fromParticipantAction.loadParticipantFiltersFailure({ error })
            );
          })
        )
      )
    )
  );

  loadParticipants$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromParticipantAction.loadParticipants),
      mergeMap((action) =>
        this.participantService.getAll().pipe(
          delay(2000),

          map((participants: ParticipantSmallId[]) =>
            fromParticipantAction.loadParticipantsSuccess({
              participants,
            })
          ),
          catchError((error) =>
            of(fromParticipantAction.loadParticipantsFailure({ error }))
          )
        )
      )
    )
  );
  loadParticipant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromParticipantAction.loadParticipant),
      mergeMap((action) =>
        this.participantService.getOneDetail(action.id).pipe(
          map((participant: ParticipantId) =>
            fromParticipantAction.loadParticipantSuccess({
              participant,
            })
          ),
          catchError((error) =>
            of(fromParticipantAction.loadParticipantFailure({ error }))
          )
        )
      )
    )
  );

  addParticipant$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromParticipantAction.addParticipant),
        mergeMap((action) =>
          this.participantService.addOne(action.participant).pipe(
            map((response) => {
              const { exists, user } = response;
              if (exists?.length || exists?.length > 0) {
                const participantFind: ParticipantSmallId = exists[0];
                this.warningExistParticipant(
                  action.participant,
                  participantFind,
                  user
                );
              } else {
                this.participantStore.dispatch(
                  fromParticipantAction.addParticipantConfirmation({
                    participant: action.participant,
                    user,
                  })
                );
              }
              return of(null);
            }),
            catchError((error) =>
              of(fromParticipantAction.addParticipantFailure({ error }))
            )
          )
        )
      ),
    { dispatch: false }
  );

  addConfirmationParticipant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromParticipantAction.addParticipantConfirmation),
      mergeMap((action) =>
        this.participantService
          .addConfirmation(action.participant, action.user)
          .pipe(
            map((participant: ParticipantSmallId) => {
              const { civilite, lastName, firstName } = participant;
              const displayName: string = `${civilite}. ${lastName} ${firstName}`;
              const success: string = `${displayName} ajouté.e`;
              this.message.success(success, { nzDuration: 6000 });
              return fromParticipantAction.addParticipantSuccess({
                participant,
              });
            }),
            catchError((error) =>
              of(fromParticipantAction.addParticipantFailure({ error }))
            )
          )
      )
    )
  );

  updateParticipant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromParticipantAction.updateParticipant),
      mergeMap((action) =>
        this.participantService
          .updateOne(action.participant.id, action.participant.changes)
          .pipe(
            map((success: string) => {
              this.message.success(success, { nzDuration: 6000 });
              return fromParticipantAction.updateParticipantSuccess({
                success,
              });
            }),
            catchError((error) =>
              of(fromParticipantAction.updateParticipantFailure({ error }))
            )
          )
      )
    )
  );

  deleteParticipant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromParticipantAction.deleteParticipant),

      mergeMap((action) =>
        this.participantService.deleteOne(action.id).pipe(
          map((success: string) => {
            this.router.navigate(['/participants']);
            this.message.success(success, { nzDuration: 6000 });
            return fromParticipantAction.deleteParticipantSuccess({
              success,
            });
          }),
          catchError((error) =>
            of(fromParticipantAction.deleteParticipantFailure({ error }))
          )
        )
      )
    )
  );

  queryParticipants$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromParticipantAction.queryParticipants),
      mergeMap((action) =>
        this.queryParticipantService
          .queryParticipants(action.filter, action.filterType)
          .pipe(
            map((participants: ParticipantSmallId[]) =>
              fromParticipantAction.queryParticipantsSuccess({
                participants,
              })
            ),
            catchError((error) =>
              of(fromParticipantAction.queryParticipantsFailure({ error }))
            )
          )
      )
    )
  );

  //OPERATIONS
  //load
  loadOperations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromParticipantAction.loadOperations),
      mergeMap((action) =>
        this.participantOperations.getAll(action.participantId).pipe(
          map((operations: any) =>
            fromParticipantAction.loadOperationsSuccess({
              operations,
            })
          ),
          catchError((error) =>
            of(fromParticipantAction.loadOperationsFailure({ error }))
          )
        )
      )
    )
  );
  //add
  addOperations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromParticipantAction.addOperation),
      exhaustMap((action) =>
        this.participantOperations
          .addOne(action.participant, action.operation)
          .pipe(
            map((operation: OperationSmallId) => {
              const success = `Projet : ${action.operation.denomination} ajouté.`;
              this.message.success(success, { nzDuration: 6000 });

              return fromParticipantAction.addOperationSuccess({
                operation,
              });
            }),
            catchError((error) =>
              of(fromParticipantAction.addOperationFailure({ error }))
            )
          )
      )
    )
  );
  updateOperations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromParticipantAction.updateOperation),
      exhaustMap((action) =>
        this.participantOperations
          .updateOne(action.participant, action.operation)
          .pipe(
            map((success: string) => {
              this.message.success(success, { nzDuration: 6000 });

              return fromParticipantAction.updateOperationSuccess({
                success,
              });
            }),
            catchError((error) =>
              of(fromParticipantAction.addOperationFailure({ error }))
            )
          )
      )
    )
  );

  //delete
  deleteOperations$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromParticipantAction.deleteOperation),
        mergeMap((action) =>
          this.participantOperations
            .deleteOne(action.participant, action.operation)
            .pipe(
              map((success: string) => {
                this.message.success(success, { nzDuration: 6000 });

                return fromParticipantAction.deleteOperationSuccess({
                  success,
                });
              }),
              catchError((error) =>
                of(fromParticipantAction.deleteOperationFailure({ error }))
              )
            )
        )
      ),
    { dispatch: false }
  );

  //EXPERIENCES
  //load
  loadExperiences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromParticipantAction.loadParticipantExperiences),
      mergeMap((action) =>
        this.participantExperiences.getAll(action.id).pipe(
          map((experiences: any) =>
            fromParticipantAction.loadParticipantExperiencesSuccess({
              experiences,
            })
          ),
          catchError((error) =>
            of(
              fromParticipantAction.loadParticipantExperiencesFailure({
                error,
              })
            )
          )
        )
      )
    )
  );
  //add
  addExperience$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromParticipantAction.addParticipantExperience),
      exhaustMap((action) =>
        this.participantExperiences.addOne(action.id, action.experience).pipe(
          map((success: string) => {
            this.message.success(success, { nzDuration: 6000 });

            return fromParticipantAction.addParticipantExperienceSuccess({
              success,
            });
          }),
          catchError((error) =>
            of(fromParticipantAction.addParticipantExperienceFailure({ error }))
          )
        )
      )
    )
  );

  //update
  updateExperience$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromParticipantAction.updateParticipantExperience),
        mergeMap((action) =>
          this.participantExperiences
            .updateOne(action.id, action.experience)
            .pipe(
              map((success: string) => {
                this.message.success(success, { nzDuration: 6000 });

                return fromParticipantAction.updateParticipantExperienceSuccess(
                  {
                    success,
                  }
                );
              }),
              catchError((error) =>
                of(
                  fromParticipantAction.updateParticipantExperienceFailure({
                    error,
                  })
                )
              )
            )
        )
      ),
    { dispatch: false }
  );
  //delete
  deleteExperience$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromParticipantAction.deleteParticipantExperience),
        mergeMap((action) =>
          this.participantExperiences
            .deleteOne(action.participantId, action.experienceId)
            .pipe(
              map((success: string) => {
                this.message.success(success, { nzDuration: 6000 });
                return fromParticipantAction.deleteParticipantExperienceSuccess(
                  {
                    success,
                  }
                );
              }),
              catchError((error) =>
                of(
                  fromParticipantAction.deleteParticipantExperienceFailure({
                    error,
                  })
                )
              )
            )
        )
      ),
    { dispatch: false }
  );

  //AIDS
  //load
  loadAids$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromParticipantAction.loadAids),
      mergeMap((action) =>
        this.participantAids.getAll(action.id).pipe(
          map(
            (aids: any) =>
              fromParticipantAction.loadAidsSuccess({
                aids,
              }),
            catchError((error) =>
              of(fromParticipantAction.loadAidsFailure({ error }))
            )
          )
        )
      )
    )
  );
  //add
  addAid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromParticipantAction.addAid),
      exhaustMap((action) =>
        this.participantAids.addOne(action.id, action.aids).pipe(
          map(() => {
            const size: number = action.aids.length;
            const success = `${size} allocation${size > 1 ? 's' : ''} ajoutée${
              size > 1 ? 's' : ''
            }`;
            this.message.success(success, { nzDuration: 6000 });

            return fromParticipantAction.addAidSuccess({
              success,
            });
          }),
          catchError((error) =>
            of(fromParticipantAction.addAidFailure({ error }))
          )
        )
      )
    )
  );

  //update
  updateAid$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromParticipantAction.updateAid),
        mergeMap((action) =>
          this.participantAids
            .updateOne(action.aid.id, action.aid.changes, action.id)
            .pipe(
              map((success: string) => {
                this.message.success(success, { nzDuration: 6000 });

                return fromParticipantAction.updateAidSuccess({
                  success,
                });
              }),
              catchError((error) =>
                of(fromParticipantAction.updateAidFailure({ error }))
              )
            )
        )
      ),
    { dispatch: false }
  );
  //delete
  deleteAid$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromParticipantAction.deleteAid),
        mergeMap((action) =>
          this.participantAids
            .deleteOne(action.participantId, action.aidId)
            .pipe(
              map((success: string) => {
                this.message.success(success, { nzDuration: 6000 });

                return fromParticipantAction.deleteAidSuccess({
                  success,
                });
              }),
              catchError((error) =>
                of(fromParticipantAction.deleteAidFailure({ error }))
              )
            )
        )
      ),
    { dispatch: false }
  );

  //STATISTIQUES
  participantStats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromParticipantAction.loadParticipantStatistiques),
      mergeMap((action) =>
        this.participantStatistiques.getOne(action.participantId).pipe(
          map((statistiques: any) => {
            return fromParticipantAction.loadParticipantStatistiquesSuccess({
              statistiques,
            });
          }),
          catchError((error) =>
            of(
              fromParticipantAction.loadParticipantStatistiquesFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  //EXPORT
  exportPARTICIPANT$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromParticipantAction.exportParticipant),
        tap(() => {
          this.notification.info(
            'Export fiche de liaison',
            "Fiche de liaison en cours d'exportation",
            {
              nzDuration: 6000,
              nzAnimate: true,
              nzPlacement: NOTIFICATION_PLACEMENT,
              nzKey: 'EXPORT_PDF_PARTICIPANT',
            }
          );
        }),
        debounceTime(200),
        mergeMap((action) =>
          this.participantExportPDFService
            .export_PARTICIPANT(
              action.participant,
              action.incluSuivi,
              action.cv,
              action.anonym
            )
            .pipe(
              map((success: string) => {
                const alertInfo: string = 'Fichier en téléchargé avec succès';
                this.notification.success(
                  'Export fiche de liaison',
                  alertInfo,
                  {
                    nzDuration: 8000,
                    nzAnimate: true,
                    nzPlacement: NOTIFICATION_PLACEMENT,
                    nzKey: 'EXPORT_PDF_PARTICIPANT',
                  }
                );

                return fromParticipantAction.exportParticipantSuccess({
                  success,
                });
              }),
              catchError((error) => {
                this.onErrorNotification(error, 'EXPORT_PDF_PARTICIPANT');
                return of(
                  fromParticipantAction.exportParticipantFailure({ error })
                );
              })
            )
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private participantStore: Store<ParticipantState>,
    private message: NzMessageService,
    private participantService: ParticipantService,
    private queryParticipantService: QueryParticipantsService,
    private participantOperations: ParticipantOperationsService,
    private participantAids: ParticipantAidsService,
    private modal: NzModalService,
    private aidsService: AidsEligibleService,
    private participantExportPDFService: ParticipantExportPdfService,
    private participantStatistiques: ParticipantStatistiquesService,
    private participantExperiences: ParticipantExperiencesService,
    private notification: NzNotificationService
  ) {}

  warningExistParticipant(
    participant: Participant,
    existParticipant: ParticipantSmallId,
    user: any
  ): void {
    const { civilite, lastName, firstName } = existParticipant;
    const displayName: string = `${civilite}. ${lastName} ${firstName}`;
    const modal = this.modal.create({
      nzTitle: 'Participant trouvé',
      nzContent: `<strong>${displayName}</strong> <br> a déjà été ajouté. Voulez-vous confirmer l\'enregistrement de ce participant ?`,

      nzFooter: [
        {
          label: 'Confirmer',
          type: 'primary',
          onClick: () => {
            this.participantStore.dispatch(
              fromParticipantAction.addParticipantConfirmation({
                participant,
                user,
              })
            );
            modal.destroy();
          },
        },
        {
          label: 'Annuler',
          type: 'text',
          onClick: () => {
            this.onCancel();
            modal.destroy();
          },
        },
      ],
    });
  }

  onErrorNotification(error: any, key: string): void {
    this.notification.error('Error', error.message, {
      nzDuration: 10000,
      nzAnimate: true,
      nzPlacement: NOTIFICATION_PLACEMENT,
      nzKey: key ? key : '',
    });
  }

  onCancel(): void {
    return;
  }
}
