import { ContactModule } from './../contact/contact.module';
import { OperationModule } from './../operation/operation.module';
import { RouterModule } from '@angular/router';
import { ContentsModule } from './../../contents/contents.module';
import { SideNavigationService } from './../../service/side-navigation.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideNavigationComponent } from './components/side-navigation/side-navigation.component';
import { StoreModule } from '@ngrx/store';
import * as fromSideNavigation from './store/side-navigation.reducer';
import { EffectsModule } from '@ngrx/effects';
import { SideNavigationEffects } from './store/side-navigation.effects';
import { ParticipantModule } from '../participant/participant.module';
import { EntiteModule } from '../entite/entite.module';
import { DepartementModule } from '../departement/departement.module';
import { SideNavigationSubmenuOperationComponent } from './components/side-navigation-submenu-operation/side-navigation-submenu-operation.component';
import { SideNavigationSubmenuParticipantComponent } from './components/side-navigation-submenu-participant/side-navigation-submenu-participant.component';
import { SideNavigationSubmenuEntiteComponent } from './components/side-navigation-submenu-entite/side-navigation-submenu-entite.component';
import { SideNavigationSubmenuContactComponent } from './components/side-navigation-submenu-contact/side-navigation-submenu-contact.component';
import { SideNavigationSubmenuServiceComponent } from './components/side-navigation-submenu-service/side-navigation-submenu-service.component';
import { SideNavigationSubmenuRedactionComponent } from './components/side-navigation-submenu-redaction/side-navigation-submenu-redaction.component';
import { SideNavigationOrganisationComponent } from './components/side-navigation-organisation/side-navigation-organisation.component';
import { RouterActivateStateService } from 'src/app/resolvers/router/router-activate-state.service';
import { HeaderModule } from 'src/app/contents/components/header-title/header.module';
import { NavigationModule } from '../navigation/navigation.module';
import { AvatarModule } from 'src/app/features/avatar/avatar.module';

@NgModule({
  declarations: [
    SideNavigationComponent,
    SideNavigationSubmenuOperationComponent,
    SideNavigationSubmenuParticipantComponent,
    SideNavigationSubmenuEntiteComponent,
    SideNavigationSubmenuContactComponent,
    SideNavigationSubmenuServiceComponent,
    SideNavigationSubmenuRedactionComponent,
    SideNavigationOrganisationComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ContentsModule,
    RouterModule,
    OperationModule,
    ParticipantModule,
    EntiteModule,
    ContactModule,
    DepartementModule,
    HeaderModule,
    NavigationModule,
    AvatarModule,

    StoreModule.forFeature(
      fromSideNavigation.sideNavigationsFeatureKey,
      fromSideNavigation.reducer
    ),
    EffectsModule.forFeature([SideNavigationEffects]),
  ],
  exports: [SideNavigationComponent],
  providers: [SideNavigationService, RouterActivateStateService],
})
export class SideNavigationModule {}
