import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NzMessageService } from 'ng-zorro-antd/message';
import { MissionExportPdfService } from 'src/app/service/mission-export-pdf.service';
import { MissionsService } from 'src/app/service/missions.service';
import * as fromMissionAction from './mission.actions';
import { MissionState } from './mission.reducer';
import { Store, select } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, mergeMap, map, tap, exhaustMap } from 'rxjs/operators';

@Injectable()
export class MissionEffects {
  exportMissionPdf$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMissionAction.exportMissionPDF),
      mergeMap((action) =>
        this.missionExportPdf.export_MISSION(action.mission).pipe(
          map((success: string) => {
            this.message.success(success, { nzDuration: 6000 });
            return fromMissionAction.exportMissionPDFSuccess({ success });
          }),
          catchError((error) =>
            of(fromMissionAction.exportMissionPDFFailure({ error }))
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private message: NzMessageService,
    private missionService: MissionsService,
    private missionExportPdf: MissionExportPdfService
  ) {}
}
