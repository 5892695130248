<ng-container *ngIf="participant">
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="center">
    <nz-col nzSpan="12">
      <nz-card [nzTitle]="cadreVie">
        <nz-descriptions
          nzSize="small"
          [nzColumn]="{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }"
          nzBordered
        >
          <nz-descriptions-item
            nzTitle="Ressources financières et matérielles"
            nzSpan="3"
          ></nz-descriptions-item>
          <nz-descriptions-item
            nzTitle="Scolaire"
            nzSpan="3"
          ></nz-descriptions-item>
          <nz-descriptions-item
            nzTitle="Santé"
            nzSpan="3"
          ></nz-descriptions-item>
          <nz-descriptions-item
            nzTitle="Handicap"
            nzSpan="3"
          ></nz-descriptions-item>
          <nz-descriptions-item
            nzTitle="Rélation au numérique"
            nzSpan="3"
          ></nz-descriptions-item>
        </nz-descriptions>
      </nz-card>
    </nz-col>
    <nz-col nzSpan="12">
      <nz-card [nzTitle]="vieSocial">
        <nz-descriptions
          nzSize="small"
          [nzColumn]="{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }"
          nzBordered
        >
          <nz-descriptions-item
            nzTitle="Environnement familial"
            nzSpan="3"
          ></nz-descriptions-item>
          <nz-descriptions-item
            nzTitle="Environnement social"
            nzSpan="3"
          ></nz-descriptions-item>
          <nz-descriptions-item
            nzTitle="Environnement citoyen"
            nzSpan="3"
          ></nz-descriptions-item>
          <nz-descriptions-item
            nzTitle="Expériences/compétences professionnelle"
            nzSpan="3"
          ></nz-descriptions-item>
          <nz-descriptions-item
            nzTitle="Autonomie dans la recherche d'emploi"
            nzSpan="3"
          ></nz-descriptions-item>
        </nz-descriptions>
      </nz-card>
    </nz-col>
  </nz-row>

  <nz-space nzDirection="vertical"></nz-space>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="center">
    <nz-col nzSpan="24">
      <nz-card [nzTitle]="frensEvolution" class="freinsEvolutions"> </nz-card>
    </nz-col>
  </nz-row>

  <ng-template #cadreVie>
    <app-header-title
      [subtitle]="true"
      [title]="'Cadre de vie'"
      [icon]="'hub'"
    ></app-header-title>
  </ng-template>

  <ng-template #vieSocial>
    <app-header-title
      [subtitle]="true"
      [title]="'Vie sociale'"
      [icon]="'diversity_3'"
    ></app-header-title>
  </ng-template>

  <ng-template #frensEvolution>
    <app-header-title
      [subtitle]="true"
      [title]="'Évolutions'"
      [icon]="'track_changes'"
    ></app-header-title>
  </ng-template>
</ng-container>
