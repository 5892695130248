import { Injectable } from '@angular/core';
import { gql, Query } from 'apollo-angular';

@Injectable({
  providedIn: 'any',
})
export class QueryTypesHorairesContratGQLService extends Query<Response> {
  document = gql`
    query Referentiel_type_horaire {
      referentiel_type_horaire {
        infos
        type
      }
    }
  `;
}
