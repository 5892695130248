import {
  NzNotificationService,
  NzNotificationPlacement,
} from 'ng-zorro-antd/notification';
import { Router } from '@angular/router';
import { EntiteContactsService } from './../../../service/entite-contacts.service';
import { EntiteOperationsService } from './../../../service/entite-operations.service';
import { EntiteStorageService } from './../../../service/entite-storage.service';
import { ItemEventId } from '../../../features/suivi/components/item-event/itemEvent.model';
import { EntiteSuivisService } from './../../../service/entite-suivis.service';
import { QueryEntitesService } from './../../../service/query-entites.service';
import { EntiteService } from './../../../service/entite.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, map, tap, delay } from 'rxjs/operators';
import * as fromEntiteAction from './entite.actions';
import { of } from 'rxjs';
import { EntiteState } from './entite.reducer';
import { Entite, EntiteId, EntiteSMALLid } from './entite.model';
import { OperationSmallId } from '../../operation/store/operation.model';
import { ContactSMALLid } from '../../contact/store/contact.model';
import { Store } from '@ngrx/store';

const NOTIFICATION_PLACEMENT: NzNotificationPlacement = 'bottomRight';

@Injectable()
export class EntiteEffects {
  loadEntites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromEntiteAction.loadEntites),
      mergeMap((action) =>
        this.entiteService.getAll().pipe(
          map((entites: any[] | any) => {
            return fromEntiteAction.loadEntitesSuccess({
              entites,
            });
          }),
          catchError((error) =>
            of(fromEntiteAction.loadEntitesFailure({ error }))
          )
        )
      )
    )
  );
  loadEntite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromEntiteAction.loadEntite),
      mergeMap((action) =>
        this.entiteService.getOneDetail(action.id).pipe(
          map((entite: any) =>
            fromEntiteAction.loadEntiteSuccess({
              entite,
            })
          ),
          catchError((error) =>
            of(fromEntiteAction.loadEntiteFailure({ error }))
          )
        )
      )
    )
  );

  loadEntiteStatistiques$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromEntiteAction.loadEntiteStatistiques),
      mergeMap((action) =>
        this.entiteService.getOneStatistiques(action.id).pipe(
          map((statistiques: any) =>
            fromEntiteAction.loadEntiteStatistiquesSuccess({
              statistiques,
            })
          ),
          catchError((error) =>
            of(fromEntiteAction.loadEntiteStatistiquesFailure({ error }))
          )
        )
      )
    )
  );

  //ADD
  addEntite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromEntiteAction.addEntite),
      tap(() => {
        this.notification.info(
          'Nouvelle entité',
          'Création de la nouvelle entité en cours...',
          {
            nzDuration: 6000,
            nzAnimate: true,
            nzKey: 'ADD_ENTITE_NOTIFICATION',
            nzPlacement: NOTIFICATION_PLACEMENT,
          }
        );
      }),
      mergeMap((action) =>
        this.entiteService.addOne(action.entite).pipe(
          map(() => {
            const success: string = `${action.entite.etablissement.denomination} ajouté avec succès`;
            this.notification.success('Nouvelle entité', success, {
              nzDuration: 6000,
              nzAnimate: true,
              nzKey: 'ADD_ENTITE_NOTIFICATION',
              nzPlacement: NOTIFICATION_PLACEMENT,
            });
            return fromEntiteAction.addEntiteSuccess();
          }),
          catchError((error) => {
            this.notification.error('Erreur', error.message, {
              nzDuration: 6000,
              nzAnimate: true,
              nzKey: 'ADD_ENTITE_NOTIFICATION',
              nzPlacement: NOTIFICATION_PLACEMENT,
            });

            return of(fromEntiteAction.addEntiteFailure({ error }));
          })
        )
      )
    )
  );

  //UPDATE
  updateEntite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromEntiteAction.updateEntite),
      tap((action) => {
        this.notification.info(
          'Modification',
          `Modification de ${action.entite.changes.etablissement?.denomination} en cours...`,
          {
            nzDuration: 6000,
            nzAnimate: true,
            nzKey: 'UPDATE_ENTITE_NOTIFICATION',
            nzPlacement: NOTIFICATION_PLACEMENT,
          }
        );
      }),
      mergeMap((action) =>
        this.entiteService
          .updateOne(action.entite.id, action.entite.changes)
          .pipe(
            map(() => {
              this.notification.success(
                'Modification',
                `${action.entite.changes.etablissement?.denomination} modifiée avec succès`,
                {
                  nzDuration: 6000,
                  nzAnimate: true,
                  nzKey: 'UPDATE_ENTITE_NOTIFICATION',
                  nzPlacement: NOTIFICATION_PLACEMENT,
                }
              );

              return fromEntiteAction.updateEntiteSuccess();
            }),
            catchError((error) => {
              this.notification.error('Erreur', error.message, {
                nzDuration: 6000,
                nzAnimate: true,
                nzKey: 'UPDATE_ENTITE_NOTIFICATION',
                nzPlacement: NOTIFICATION_PLACEMENT,
              });

              return of(fromEntiteAction.updateEntiteFailure({ error }));
            })
          )
      )
    )
  );

  //DELETE
  deleteEntite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromEntiteAction.deleteEntite),
      tap(() => {
        this.notification.info(
          'Suppression',
          `Suppression de l'entité en cours...`,
          {
            nzDuration: 6000,
            nzAnimate: true,
            nzKey: 'DELETE_ENTITE_NOTIFICATION',
            nzPlacement: NOTIFICATION_PLACEMENT,
          }
        );

        this.router.navigate(['/entites']);
      }),
      mergeMap((action) =>
        this.entiteService.deleteOne(action.id).pipe(
          map((success: string) => {
            this.notification.success('Suppression', success, {
              nzDuration: 6000,
              nzAnimate: true,
              nzKey: 'DELETE_ENTITE_NOTIFICATION',
              nzPlacement: NOTIFICATION_PLACEMENT,
            });

            return fromEntiteAction.deleteEntiteSuccess({
              success,
            });
          }),
          catchError((error) => {
            this.notification.error('Suppression', error.message, {
              nzDuration: 6000,
              nzAnimate: true,
              nzKey: 'DELETE_ENTITE_NOTIFICATION',
              nzPlacement: NOTIFICATION_PLACEMENT,
            });

            return of(fromEntiteAction.deleteEntiteFailure({ error }));
          })
        )
      )
    )
  );

  //DELETE ALL
  deleteAllEntites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromEntiteAction.deleteEntites),
      mergeMap((action) =>
        this.entiteService.deleteAll(action.ids).pipe(
          map((success: string) => {
            this.message.success(success, { nzDuration: 4000 });
            return fromEntiteAction.deleteEntitesSuccess({
              success,
            });
          }),
          catchError((error) =>
            of(fromEntiteAction.deleteEntitesFailure({ error }))
          )
        )
      )
    )
  );

  //QUERY
  entiteFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromEntiteAction.loadEntiteFilters),
      tap(() =>
        this.entiteStore.dispatch(fromEntiteAction.loading({ loading: true }))
      ),
      mergeMap((action) =>
        this.queryEntitesService.getFilters().pipe(
          map((respons: any) => {
            const filters = respons?.data?.me?.organisation?.filtersEntite;
            this.entiteStore.dispatch(
              fromEntiteAction.loading({ loading: false })
            );
            return fromEntiteAction.loadEntiteFiltersSuccess({
              filters,
            });
          }),
          catchError((error) => {
            this.entiteStore.dispatch(
              fromEntiteAction.loading({ loading: false })
            );
            return of(fromEntiteAction.loadEntiteFiltersFailure({ error }));
          })
        )
      )
    )
  );

  queryEntites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromEntiteAction.queryEntites),
      mergeMap((action) =>
        this.queryEntitesService
          .queryEntites(action.filter, action.filterType)
          .pipe(
            map((entites: EntiteSMALLid[]) =>
              fromEntiteAction.queryEntitesSuccess({
                entites,
              })
            ),
            catchError((error) =>
              of(fromEntiteAction.queryEntitesFailure({ error }))
            )
          )
      )
    )
  );

  entiteSirets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromEntiteAction.loadEntiteSirets),
      mergeMap((action) =>
        this.entiteService.getSirets().pipe(
          map((sirets: any) =>
            fromEntiteAction.loadEntiteSiretsSuccess({
              sirets,
            })
          ),
          catchError((error) =>
            of(fromEntiteAction.loadEntiteSiretsFailure({ error }))
          )
        )
      )
    )
  );

  //DOCUMENT
  //load
  loadDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromEntiteAction.loadDocuments),
      mergeMap((action) =>
        this.entiteStorage.getAll(action.entiteId).pipe(
          map((documents: any) =>
            fromEntiteAction.loadDocumentsSuccess({
              documents,
            })
          ),
          catchError((error) =>
            of(fromEntiteAction.loadDocumentsFailure({ error }))
          )
        )
      )
    )
  );

  //add
  addDocument$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromEntiteAction.addDocuments),
        tap(() => {
          this.notification.info(
            'Document',
            'Création du nouveau en cours...',
            {
              nzDuration: 6000,
              nzAnimate: true,
              nzKey: 'ADD_ENTITE_DOCUMENT_NOTIFICATION',
              nzPlacement: NOTIFICATION_PLACEMENT,
            }
          );
        }),
        mergeMap((action) =>
          this.entiteStorage.addFiles(action.entite, action.documents).pipe(
            map((documents: any) => {
              const plurial: string = `${
                action.documents?.length > 1 ? 's' : ''
              }`;

              const success: string = `Document${plurial} ajouté${plurial}`;
              this.notification.success('Document', success, {
                nzDuration: 6000,
                nzAnimate: true,
                nzKey: 'ADD_ENTITE_DOCUMENT_NOTIFICATION',
                nzPlacement: NOTIFICATION_PLACEMENT,
              });
              return fromEntiteAction.addDocumentsSuccess({
                documents,
              });
            }),
            catchError((error) => {
              this.notification.error('Erreur', error.message, {
                nzDuration: 6000,
                nzAnimate: true,
                nzKey: 'ADD_ENTITE_DOCUMENT_NOTIFICATION',
                nzPlacement: NOTIFICATION_PLACEMENT,
              });
              return of(fromEntiteAction.addDocumentsFailure({ error }));
            })
          )
        )
      ),
    { dispatch: false }
  );

  //delete
  deleteDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromEntiteAction.deleteDocument),
      tap(() => {
        this.notification.success(
          'Document',
          'Suppression du document en cours...',
          {
            nzDuration: 6000,
            nzAnimate: true,
            nzKey: 'DELETE_ENTITE_DOCUMENT_NOTIFICATION',
            nzPlacement: NOTIFICATION_PLACEMENT,
          }
        );
      }),
      mergeMap((action) =>
        this.entiteStorage.deleteOne(action.entiteId, action.document).pipe(
          map((success: string) => {
            this.notification.success('Document', success, {
              nzDuration: 6000,
              nzAnimate: true,
              nzKey: 'DELETE_ENTITE_DOCUMENT_NOTIFICATION',
              nzPlacement: NOTIFICATION_PLACEMENT,
            });
            return fromEntiteAction.deleteDocumentSuccess({
              success,
            });
          }),
          catchError((error) => {
            this.notification.error('Erreur', error.message, {
              nzDuration: 6000,
              nzAnimate: true,
              nzKey: 'DELETE_ENTITE_DOCUMENT_NOTIFICATION',
              nzPlacement: NOTIFICATION_PLACEMENT,
            });
            return of(fromEntiteAction.deleteDocumentFailure({ error }));
          })
        )
      )
    )
  );

  //OPERATIONS
  //load
  loadOperations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromEntiteAction.loadOperations),
      mergeMap((action) =>
        this.entiteOperations.getAll(action.entiteId).pipe(
          map((operations: any) =>
            fromEntiteAction.loadOperationsSuccess({
              operations,
            })
          ),
          catchError((error) =>
            of(fromEntiteAction.loadOperationsFailure({ error }))
          )
        )
      )
    )
  );
  //add
  addOperations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromEntiteAction.addOperation),
      mergeMap((action) =>
        this.entiteOperations
          .addOne(action.entite, action.operation, action.intervention)
          .pipe(
            map((success: string) => {
              this.message.success(success, { nzDuration: 4000 });
              return fromEntiteAction.addOperationSuccess({
                success,
              });
            }),
            catchError((error) =>
              of(fromEntiteAction.addOperationFailure({ error }))
            )
          )
      )
    )
  );
  //update
  updateOperations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromEntiteAction.updateOperation),
      mergeMap((action) =>
        this.entiteOperations.updateOne(action.entite, action.operation).pipe(
          map((success: string) => {
            this.message.success(success, { nzDuration: 4000 });
            return fromEntiteAction.updateOperationSuccess({
              success,
            });
          }),
          catchError((error) =>
            of(fromEntiteAction.updateOperationFailure({ error }))
          )
        )
      )
    )
  );

  //delete
  deleteOperations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromEntiteAction.deleteOperation),
      mergeMap((action) =>
        this.entiteOperations.deleteOne(action.entite, action.operation).pipe(
          map((success: string) => {
            this.message.success(success, { nzDuration: 4000 });
            return fromEntiteAction.deleteOperationSuccess({
              success,
            });
          }),
          catchError((error) =>
            of(fromEntiteAction.deleteOperationFailure({ error }))
          )
        )
      )
    )
  );

  //CONTACTS
  loadEntiteContacts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromEntiteAction.loadEntiteContacts),
      mergeMap((action) =>
        this.entiteContacts.getAll(action.id).pipe(
          map((contacts: ContactSMALLid[]) =>
            fromEntiteAction.loadEntiteContactsSuccess({
              contacts,
            })
          ),
          catchError((error) =>
            of(fromEntiteAction.loadEntiteContactsFailure({ error }))
          )
        )
      )
    )
  );

  //ENTITES BY OPERATION
  loadEntitesByOperation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromEntiteAction.loadEntitesByOpeation),
      mergeMap((action) =>
        this.entiteOperations.getAllByOperation().pipe(
          map((entites: any[] | any) =>
            fromEntiteAction.loadEntitesByOpeationSuccess({
              entites,
            })
          ),
          catchError((error) =>
            of(fromEntiteAction.loadEntitesByOpeationFailure({ error }))
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private message: NzMessageService,
    private entiteStore: Store<EntiteState>,
    private entiteService: EntiteService,
    private queryEntitesService: QueryEntitesService,
    private entiteSuivis: EntiteSuivisService,
    private entiteStorage: EntiteStorageService,
    private entiteOperations: EntiteOperationsService,
    private entiteContacts: EntiteContactsService,
    private router: Router,
    private notification: NzNotificationService
  ) {}
}
