import { OperationSmallId } from 'src/app/components/operation/store/operation.model';
import { ItemEventId } from '../../../features/suivi/components/item-event/itemEvent.model';
import { OperationId } from './../../operation/store/operation.model';
import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import {
  Participant,
  ParticipantId,
  ParticipantSmallId,
} from './participant.model';
import { ItemEvent } from 'src/app/features/suivi/components/item-event/itemEvent.model';

export const loading = createAction(
  '[Participant/COMPONENT] Loading Participants',
  props<{ loading: boolean }>()
);

export const loadParticipants = createAction(
  '[Participant/COMPONENT] Load Participants'
);
export const loadParticipantsSuccess = createAction(
  '[Participant/API] Load Participants Success',
  props<{ participants: ParticipantSmallId[] }>()
);
export const loadParticipantsFailure = createAction(
  '[Participant/API] Load Participants Failure',
  props<{ error: any }>()
);

export const loadParticipant = createAction(
  '[Participant/COMPONENT] Load Participant',
  props<{ id: string }>()
);
export const loadParticipantSuccess = createAction(
  '[Participant/API] Load Participant Success',
  props<{ participant: ParticipantId }>()
);
export const loadParticipantFailure = createAction(
  '[Participant/API] Load Participant Failure',
  props<{ error: any }>()
);

//ADD
export const addParticipant = createAction(
  '[Participant/COMPONENT] Add Participant',
  props<{ participant: Participant; operation?: OperationId }>()
);

export const addParticipantConfirmation = createAction(
  '[Participant/API] Add Participant',
  props<{ participant: Participant; user: any }>()
);

export const addParticipantSuccess = createAction(
  '[Participant/API] Add Participant Success',
  props<{ participant: ParticipantSmallId }>()
);

export const addParticipantFailure = createAction(
  '[Participant/API] Add Participants Failure',
  props<{ error: any }>()
);

//UPDATE
export const updateParticipant = createAction(
  '[Participant/COMPONENT] Update Participant',
  props<{ participant: Update<Participant> }>()
);

export const updateParticipantSuccess = createAction(
  '[Participant/API] Update Participant Success',
  props<{ success: string }>()
);

export const updateParticipantFailure = createAction(
  '[Participant/API] Update Participant Failure',
  props<{ error: any }>()
);

//DELETE ONE
export const deleteParticipant = createAction(
  '[Participant/API] Delete Participant',
  props<{ id: string }>()
);
export const deleteParticipantSuccess = createAction(
  '[Participant/API] Delete Participant Success',
  props<{ success: string }>()
);
export const deleteParticipantFailure = createAction(
  '[Participant/API] Delete Participant Failure',
  props<{ error: any }>()
);

//DELETE MANY
export const deleteParticipants = createAction(
  '[Participant/API] Delete Participants',
  props<{ ids: string[] }>()
);

export const clearParticipants = createAction(
  '[Participant/API] Clear Participants'
);
export const clearParticipant = createAction(
  '[Participant/API] Clear Participant'
);

export const loadFileProgress = createAction(
  '[Participant/API] File upload progreess Participant',
  props<{ progress: any }>()
);

//OPERATIONS
//load
export const loadOperations = createAction(
  '[Participant/COMPONENT] Load Operation Participant',
  props<{ participantId: string }>()
);

export const loadOperationsSuccess = createAction(
  '[Participant/API] Load Operation Participant Success',
  props<{ operations: any[] }>()
);

export const loadOperationsFailure = createAction(
  '[Participant/API] Load Operation Participant Failure',
  props<{ error: any }>()
);

//add
export const addOperation = createAction(
  '[Participant/COMPONENT] Add Operation Participant',
  props<{ participant: ParticipantId; operation: OperationSmallId }>()
);

export const addOperationSuccess = createAction(
  '[Participant/API] Add Operation Participant Success',
  props<{ operation: OperationSmallId }>()
);

export const addOperationFailure = createAction(
  '[Participant/API] Add Operation Participant Failure',
  props<{ error: any }>()
);

//update
export const updateOperation = createAction(
  '[Participant/COMPONENT] Update Operation Participant',
  props<{ participant: ParticipantId; operation: Update<any> }>()
);

export const updateOperationSuccess = createAction(
  '[Participant/API] Update Operation Participant Success',
  props<{ success: string }>()
);

export const updateOperationFailure = createAction(
  '[Participant/API] Update Operation Participant Failure',
  props<{ error: any }>()
);

//delete
export const deleteOperation = createAction(
  '[Participant/COMPONENT] Delete Operation Participant',
  props<{ participant: ParticipantId; operation: OperationSmallId }>()
);

export const deleteOperationSuccess = createAction(
  '[Participant/API] Delete Operation Participant Success',
  props<{ success: string }>()
);

export const deleteOperationFailure = createAction(
  '[Participant/API] Delete Operation Participant Failure',
  props<{ error: any }>()
);

//AIDS
//load aids
export const loadAids = createAction(
  '[Participant/COMPONENT] Load Aids Participant',
  props<{ id: string }>()
);
export const loadAidsSuccess = createAction(
  '[Participant/API] Load Aids Participant Success',
  props<{ aids: any[] }>()
);
export const loadAidsFailure = createAction(
  '[Participant/API] Load Aids Participant Failure',
  props<{ error: any }>()
);

//add aid
export const addAid = createAction(
  '[Participant/COMPONENT] Add Aid Participant',
  props<{ id: string; aids: any[] }>()
);
export const addAidSuccess = createAction(
  '[Participant/API] Add Aid Participant Success',
  props<{ success: string }>()
);
export const addAidFailure = createAction(
  '[Participant/API] Add Aid Participant Failure',
  props<{ error: any }>()
);

//update aid
export const updateAid = createAction(
  '[Participant/COMPONENT] Upload Aid Participant',
  props<{ id: string; aid: Update<any> }>()
);
export const updateAidSuccess = createAction(
  '[Participant/API] Upload Aid Participant Success',
  props<{ success: string }>()
);
export const updateAidFailure = createAction(
  '[Participant/API] Upload Aid Participant Failure',
  props<{ error: any }>()
);

//delete aid
export const deleteAid = createAction(
  '[Participant/COMPONENT] Delete Aid Participant',
  props<{ participantId: string; aidId: string }>()
);
export const deleteAidSuccess = createAction(
  '[Participant/API] Delete Aid Participant Success',
  props<{ success: string }>()
);
export const deleteAidFailure = createAction(
  '[Participant/API] Delete Aid Participant Failure',
  props<{ error: any }>()
);

//FILTERS
export const loadParticipantFilters = createAction(
  '[Participant/COMPONENT] Load filters Participants'
);
export const loadParticipantFiltersSuccess = createAction(
  '[Participant/API] Load filters Participants',
  props<{ filters: any }>()
);
export const loadParticipantFiltersFailure = createAction(
  '[Participant/API] Load filters Participants',
  props<{ error: any }>()
);

//STATISTIQUES
export const loadParticipantStatistiques = createAction(
  '[Participant/COMPONENT] Load Statistiques Participant',
  props<{ participantId: string }>()
);
export const loadParticipantStatistiquesSuccess = createAction(
  '[Participant/API] Load Statistiques Participant Success',
  props<{ statistiques: any }>()
);
export const loadParticipantStatistiquesFailure = createAction(
  '[Participant/API] Load Statistiques Participant Failure',
  props<{ error: any }>()
);

//QUERY
export const queryParticipants = createAction(
  '[Participant/COMPONENT] Query Participants',
  props<{ filter: string; filterType: string }>()
);
export const queryParticipantsSuccess = createAction(
  '[Participant/API] Query Participants Success',
  props<{ participants: ParticipantSmallId[] }>()
);
export const queryParticipantsFailure = createAction(
  '[Participant/API] Query Participants Failure',
  props<{ error: any }>()
);

//EXPERIENCES
//load
export const loadParticipantExperiences = createAction(
  '[Participant/COMPONENT] Load Participant Experience',
  props<{ id: string }>()
);
export const loadParticipantExperiencesSuccess = createAction(
  '[Participant/API] Load Participant Experience Success',
  props<{ experiences: any[] }>()
);
export const loadParticipantExperiencesFailure = createAction(
  '[Participant/API] Load Participant Experience Failure',
  props<{ error: any }>()
);
//add
export const addParticipantExperience = createAction(
  '[Participant/COMPONENT] Add Participant Experience',
  props<{ id: string; experience: any }>()
);
export const addParticipantExperienceSuccess = createAction(
  '[Participant/API] Add Participant Experience Success',
  props<{ success: string }>()
);
export const addParticipantExperienceFailure = createAction(
  '[Participant/API] Add Participant Experience Failure',
  props<{ error: any }>()
);

//update
export const updateParticipantExperience = createAction(
  '[Participant/COMPONENT] Update Participant Experience',
  props<{ id: string; experience: Update<any> }>()
);
export const updateParticipantExperienceSuccess = createAction(
  '[Participant/API] Update Participant Experience Success',
  props<{ success: string }>()
);
export const updateParticipantExperienceFailure = createAction(
  '[Participant/API] Update Participant Experience Failure',
  props<{ error: any }>()
);

//delete
export const deleteParticipantExperience = createAction(
  '[Participant/COMPONENT] Delete Participant Experience',
  props<{ participantId: string; experienceId: string }>()
);
export const deleteParticipantExperienceSuccess = createAction(
  '[Participant/API] Delete Participant Experience Success',
  props<{ success: string }>()
);
export const deleteParticipantExperienceFailure = createAction(
  '[Participant/API] Delete Participant Experience Failure',
  props<{ error: any }>()
);
//delete ALL
export const deleteAllParticipantExperiences = createAction(
  '[Participant/COMPONENT] Delete All Participant Experiences',
  props<{ participantId: string; experienceId: string }>()
);
export const deleteAllParticipantExperiencesSuccess = createAction(
  '[Participant/API] Delete All Participant Experiences Success',
  props<{ success: string }>()
);
export const deleteAllParticipantExperiencesFailure = createAction(
  '[Participant/API] Delete All Participant Experiences Failure',
  props<{ error: any }>()
);

//EXPORT PARTICIPANT
export const exportParticipant = createAction(
  '[Participant/COMPONENT] Exporter Participant',
  props<{
    participant: ParticipantId;
    incluSuivi?: boolean;
    cv?: boolean;
    anonym?: boolean;
  }>()
);
export const exportParticipantSuccess = createAction(
  '[Participant/API] Exporter Participant Success',
  props<{ success: string }>()
);
export const exportParticipantFailure = createAction(
  '[Participant/API] Exporter Participant Failure',
  props<{ error: any }>()
);
