import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
  UntypedFormArray,
  Validators,
} from '@angular/forms';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { v4 as uuidV4 } from 'uuid';

@Component({
  selector: 'app-operation-cadrage-plan-form',
  templateUrl: './operation-cadrage-plan-form.component.html',
  styleUrls: ['./operation-cadrage-plan-form.component.scss'],
})
export class OperationCadragePlanFormComponent implements OnInit, OnDestroy {
  subscribe = new Subject();
  isAdd: boolean = true;
  isUpdate: boolean = false;
  today: Date = new Date(Date.now());

  @Input() updateItem$ = new BehaviorSubject<any>(null);
  @Output() add = new EventEmitter<any>(false);
  @Output() update = new EventEmitter<any>(false);
  @Output() cancel = new EventEmitter<boolean>(false);

  itemform: UntypedFormGroup = this.fb.group({});
  title: UntypedFormControl = new UntypedFormControl('', Validators.required);
  sujets: UntypedFormArray = this.fb.array([]);
  id: UntypedFormControl = new UntypedFormControl(uuidV4(), Validators.required);

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.formCreate();
    this.formSet();
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  formCreate(): void {
    this.itemform = this.fb.group({
      title: this.title,
      sujets: this.sujets,
      id: this.id,
    });
  }

  formSet(): void {
    this.updateItem$.pipe(takeUntil(this.subscribe)).subscribe((val): void => {
      if (!val) {
        this.isAdd = true;
        this.isUpdate = false;
        return;
      }
      if (val) {
        this.updateForm(val);
      }
    });
  }

  updateForm(val: any): void {
    this.itemform.patchValue({
      id: val?.id,
      title: val?.title,
      sujets: val?.sujets,
    });
    this.isAdd = false;
    this.isUpdate = true;

    if (!val?.sujets?.length) {
      return;
    }

    val.sujets.forEach((el: any) => {
      const sujet = this.fb.group({
        id: el.id,
        title: el?.title,
      });

      this.sujets.push(sujet);
    });
  }

  get sujetsArray(): UntypedFormArray {
    return this.itemform.get('sujets') as UntypedFormArray;
  }

  newSujet(): void {
    const sujet = this.fb.group({
      id: [uuidV4(), Validators.required],
      title: [''],
    });

    this.sujetsArray.push(sujet);
  }

  removeSujet(index: number): void {
    this.sujetsArray.removeAt(index);
  }

  onAdd(): void {
    const form = this.itemform.value;
    this.add.emit(form);
    this.itemform.reset();
  }

  onUpdate(): void {
    const form = this.itemform.value;
    this.update.emit(form);
    this.itemform.reset();
  }

  onCancel(): void {
    this.cancel.emit(true);
  }
}
