import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-reseau',
  templateUrl: './reseau.component.html',
  styleUrls: ['./reseau.component.scss'],
})
export class ReseauComponent implements OnInit {
  @Input() borderless: boolean = true;

  constructor() {}

  ngOnInit() {}
}
