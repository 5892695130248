import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  sideNavigationsFeatureKey,
  SideNavigationState,
} from './side-navigation.reducer';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';

export const selectSideNavigationState =
  createFeatureSelector<SideNavigationState>(sideNavigationsFeatureKey);

export const navigation = createSelector(
  selectSideNavigationState,
  (state: SideNavigationState) => state.navigation
);

export const navigationState = createSelector(
  selectSideNavigationState,
  (state: SideNavigationState) => state.navigationState
);

export const currentNavigation = createSelector(
  selectSideNavigationState,
  (state: SideNavigationState) => state.currentNavigation
);
