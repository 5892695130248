import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-favories-list',
  templateUrl: './favories-list.component.html',
  styleUrls: ['./favories-list.component.scss'],
})
export class FavoriesListComponent implements OnInit {
  @Input() source: any = [];
  @Input() type: string = '';
  constructor() {}

  ngOnInit(): void {}
}
