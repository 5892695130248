<div class="container" *ngIf="operation$ | async as operation">
  <div [ngSwitch]="view$ | async">
    <div *ngSwitchCase="'list'">
      <ng-container *ngIf="entites$ | async | size; else noResults">
        <app-operation-entites-filters
          [operation$]="operation$"
          [entites$]="entites$"
        ></app-operation-entites-filters>
        <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>

        <i
          nz-icon
          nzType="loading"
          class="loading"
          *ngIf="loading$ | async"
        ></i>
        <app-layout-list
          [card]="card"
          [new]="new"
          [source]="entites$ | async"
        ></app-layout-list>
      </ng-container>
    </div>
    <div *ngSwitchCase="'details'">
      <app-operation-entites-item
        [entite]="currentSelected$ | async"
        (back)="onBack()"
      ></app-operation-entites-item>
    </div>
    <div *ngSwitchDefault>
      <app-layout-list
        *ngIf="entites$ | async | size; else noResults"
        [card]="card"
        [new]="new"
        [source]="entites$ | async"
      ></app-layout-list>
    </div>
  </div>

  <ng-template #new>
    <app-card-new
      [type]="'domain'"
      [title]="newCardTitle"
      [description]="newCardDescription"
      [definition]="newCardDefinition"
      [width]="'300px'"
      [height]="'280px'"
      (add)="onNew(operation)"
      (back)="onBack()"
      (cancel)="onBack()"
    ></app-card-new>
  </ng-template>

  <ng-template #card let-item let-position="idx">
    <nz-card
      nzHoverable
      (click)="$event.stopPropagation(); onSelect(operation, item)"
      [nzTitle]="op_entite_card_title"
      [nzExtra]="op_entite_card_extra"
    >
      <ng-template #op_entite_card_title>
        <app-header-title [icon]="'domain'"></app-header-title>
      </ng-template>
      <ng-template #op_entite_card_extra>
        <button
          nz-button
          nzShape="circle"
          nz-dropdown
          [nzDropdownMenu]="op_entite_Actions"
          nzTrigger="click"
          nzPlacement="bottomRight"
          nzType="text"
          (click)="$event.stopPropagation()"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
      </ng-template>

      <nz-dropdown-menu #op_entite_Actions="nzDropdownMenu">
        <ul nz-menu nzSelectable>
          <li
            nz-menu-item
            nzDanger
            *ngIf="operation$ | async as operation"
            nz-popconfirm
            [nzPopconfirmTitle]="'Supprimer cette entité ?'"
            nzPopconfirmPlacement="bottom"
            (nzOnConfirm)="onDelete(operation, item)"
            (nzOnCancel)="onCancel()"
          >
            <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
              <nz-col nzSpan="6">
                <mat-icon>delete</mat-icon>
              </nz-col>
              <nz-col nzSpan="4">
                <nz-divider
                  nzOrientation="center"
                  nzType="vertical"
                ></nz-divider>
              </nz-col>
              <nz-col nzSpan="14"> Supprimer </nz-col>
            </nz-row>
          </li>
        </ul>
      </nz-dropdown-menu>

      <strong>
        {{
          item?.entite?.etablissement?.denomination | carLimitation: 25
        }}</strong
      >
      <nz-space></nz-space>
      <nz-row [nzGutter]="4" nzAlign="middle" nzJustify="start">
        <nz-col [nzSpan]="24">
          <small>{{
            item?.entite?.etablissement?.activite_principale_libelle
              ?.intitule_naf | carLimitation: 30
          }}</small>
        </nz-col>
      </nz-row>
      <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>

      <nz-row [nzGutter]="4" nzAlign="middle" nzJustify="start">
        <nz-col [nzSpan]="7">
          <small class="contentTitleCard">Intervention</small></nz-col
        >
        <nz-col [nzSpan]="1">
          <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
        </nz-col>
        <nz-col [nzSpan]="16">
          <small>{{ item?.entite?.statut | carLimitation: 40 }}</small>
        </nz-col>
      </nz-row>
    </nz-card>
  </ng-template>

  <ng-template #noResults>
    <app-no-result
      [icon]="'domain_add'"
      [addtitle]="newCardDescription"
      [notitle]="noModuleTitle"
      [description]="newCardDefinition"
      (new)="onNew(operation)"
    ></app-no-result>
  </ng-template>

  <ng-template #entiteFormTitle>
    <app-header-title [icon]="'domaine'" [title]="title"></app-header-title>
  </ng-template>
  <ng-template #entiteInterventionFormTitle>
    <app-header-title
      [icon]="'domaine'"
      [title]="titleIntervention"
      [subtitle]="true"
    ></app-header-title>
  </ng-template>
</div>
