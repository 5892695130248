import { createFeatureSelector, createSelector } from '@ngrx/store';
import { messageState, messagesFeatureKey, selectAll } from './message.reducer';

export const selectMessageState =
  createFeatureSelector<messageState>(messagesFeatureKey);

export const selectMessages = createSelector(selectMessageState, selectAll);

export const selectMessage = createSelector(
  selectMessageState,
  (state: messageState) => state.message
);

export const selectDisplay = createSelector(
  selectMessageState,
  (state: messageState) => state.show
);

export const selectLoader = createSelector(
  selectMessageState,
  (state: messageState) => state.loading
);

export const selectError = createSelector(
  selectMessageState,
  (state: messageState) => state.error
);
