<form nz-form [formGroup]="missionOperationForm" class="space-y-6 p-6">
  <div class="w-full flex items-start justify- gap-4">
    <div class="w-1/2 flex items-start flex-col">
      <label class="label">Dénomination</label>
      <input
        class="inputForm"
        formControlName="denomination"
        placeholder="Nom du projet / programme / opération..."
      />
      <small
        class="inputFormAlert"
        *ngIf="
          !missionOperationForm.value.denomination &&
          missionOperationForm.controls.denomination.dirty &&
          missionOperationForm.controls.denomination.touched &&
          missionOperationForm.controls.denomination.invalid
        "
      >
        {{
          !missionOperationForm.value.denomination &&
          missionOperationForm.controls.denomination.dirty &&
          missionOperationForm.controls.denomination.touched &&
          missionOperationForm.controls.denomination.invalid
            ? "Veuillez renseigner la dénomination du projet"
            : ""
        }}
      </small>
    </div>
    <nz-col class="w-1/2 flex items-start flex-col">
      <label class="label">Type</label>
      <select
        formControlName="type"
        nzPlaceHolder="Type de projet"
        [compareWith]="compareWithType"
        class="inputForm block p-2.5"
      >
        <option
          *ngFor="let type of (types$ | async)?.types"
          [label]="type"
          [value]="type"
        ></option>
      </select>
      <small
        class="inputFormAlert"
        *ngIf="
          !missionOperationForm.value.type &&
          missionOperationForm.controls.type.dirty &&
          missionOperationForm.controls.type.touched &&
          missionOperationForm.controls.type.invalid
        "
        >{{
          !missionOperationForm.value.type &&
          missionOperationForm.controls.type.dirty &&
          missionOperationForm.controls.type.touched &&
          missionOperationForm.controls.type.invalid
            ? "Veuillez renseigner le type de projet"
            : ""
        }}</small
      >
    </nz-col>
  </div>
  <div class="w-full flex items-start flex-col">
    <label class="label">Description</label>
    <textarea
      formControlName="description"
      placeholder="Description du projet / programme / de l'opération"
      class="textareaForm"
    ></textarea>
  </div>
</form>
