import { CandidatureStartistiquesExportPdfService } from './../services/candidature-startistiques-export-pdf.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CandidatureExportPdfService } from '../services/candidature-export-pdf.service';
import { CandidatureExportExcelService } from '../services/candidature-export-excel.service';
import { CandidatureExportWordService } from '../services/candidature-export-word.service';
import {
  NzNotificationPlacement,
  NzNotificationService,
} from 'ng-zorro-antd/notification';
import { catchError, map, mergeMap, of, tap } from 'rxjs';
import * as fromCandidatureAction from './candidature.actions';
import { CandidatureStartistiquesExportPptService } from '../services/candidature-startistiques-export-ppt.service';

const NOTIFICATION_PLACEMENT: NzNotificationPlacement = 'bottomRight';

@Injectable()
export class CandidatureEffects {
  exportCandidaturesPdf$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromCandidatureAction.exportCandidaturesPdf),
        tap(() => {
          this.notification.info(
            'Export au format PDF',
            "Les candidatures sont en cours d'exportation...",
            {
              nzAnimate: true,
              nzDuration: 6000,
              nzPlacement: NOTIFICATION_PLACEMENT,
              nzKey: 'EXPORT CANDIDATS PDF',
            }
          );
        }),
        mergeMap((action) =>
          this.candidatureExportPDFService
            .onExportCandidatsPDF({
              candidats: action.candidatures,
              fiche: action.fiche,
              isAnonyme: action.isAnonyme,
              user: action.user,
            })
            .pipe(
              map((success: any) => {
                this.notification.success(
                  'Export au format PDF',
                  'Les candidatures sont prêtes',
                  {
                    nzAnimate: true,
                    nzDuration: 6000,
                    nzPlacement: NOTIFICATION_PLACEMENT,
                    nzKey: 'EXPORT CANDIDATS PDF',
                  }
                );
                return fromCandidatureAction.exportCandidaturesPdfSuccess({
                  success,
                });
              }),
              catchError((error) => {
                this.notification.error(
                  'Export au format PDF',
                  error?.message,
                  {
                    nzAnimate: true,
                    nzDuration: 6000,
                    nzPlacement: NOTIFICATION_PLACEMENT,
                    nzKey: 'EXPORT CANDIDATS PDF',
                  }
                );
                return of(
                  fromCandidatureAction.exportCandidaturesPdfFailure({ error })
                );
              })
            )
        )
      ),
    { dispatch: false }
  );
  exportCandidaturesExcel$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromCandidatureAction.exportCandidaturesExcel),
        tap(() => {
          this.notification.info(
            'Export au format Excel',
            "Les candidatures sont en cours d'exportation...",
            {
              nzAnimate: true,
              nzDuration: 6000,
              nzPlacement: NOTIFICATION_PLACEMENT,
              nzKey: 'EXPORT CANDIDATS EXCEL',
            }
          );
        }),
        mergeMap((action) =>
          this.candidatureExportExcelService
            .onExportCandidatsExcel({
              candidats: action.candidatures,
              fiche: action.fiche,
              isAnonyme: action.isAnonyme,
              user: action.user,
            })
            .pipe(
              map((success: any) => {
                this.notification.success(
                  'Export au format Excel',
                  'Les candidatures sont prêtes',
                  {
                    nzAnimate: true,
                    nzDuration: 6000,
                    nzPlacement: NOTIFICATION_PLACEMENT,
                    nzKey: 'EXPORT CANDIDATS EXCEL',
                  }
                );
                return fromCandidatureAction.exportCandidaturesExcelSuccess({
                  success,
                });
              }),
              catchError((error) => {
                this.notification.error(
                  'Export au format Excel',
                  error?.message,
                  {
                    nzAnimate: true,
                    nzDuration: 6000,
                    nzPlacement: NOTIFICATION_PLACEMENT,
                    nzKey: 'EXPORT CANDIDATS EXCEL',
                  }
                );
                return of(
                  fromCandidatureAction.exportCandidaturesExcelFailure({
                    error,
                  })
                );
              })
            )
        )
      ),
    { dispatch: false }
  );

  exportCandidaturesWord$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromCandidatureAction.exportCandidaturesWord),
        tap(() => {
          this.notification.info(
            'Export au format Word',
            "Les candidatures sont en cours d'exportation...",
            {
              nzAnimate: true,
              nzDuration: 6000,
              nzPlacement: NOTIFICATION_PLACEMENT,
              nzKey: 'EXPORT CANDIDATS WORD',
            }
          );
        }),
        mergeMap((action) =>
          this.candidatureExportWordService
            .onExportCandidatureWord({
              candidats: action.candidatures,
              fiche: action.fiche,
              isAnonyme: action.isAnonyme,
              user: action.user,
            })
            .pipe(
              map((success: any) => {
                this.notification.success(
                  'Export au format Word',
                  'Les candidatures sont prêtes',
                  {
                    nzAnimate: true,
                    nzDuration: 6000,
                    nzPlacement: NOTIFICATION_PLACEMENT,
                    nzKey: 'EXPORT CANDIDATS WORD',
                  }
                );
                return fromCandidatureAction.exportCandidaturesWordSuccess({
                  success,
                });
              }),
              catchError((error) => {
                this.notification.error(
                  'Export au format Word',
                  error?.message,
                  {
                    nzAnimate: true,
                    nzDuration: 6000,
                    nzPlacement: NOTIFICATION_PLACEMENT,
                    nzKey: 'EXPORT CANDIDATS WORD',
                  }
                );
                return of(
                  fromCandidatureAction.exportCandidaturesWordFailure({ error })
                );
              })
            )
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private candidatureExportPDFService: CandidatureExportPdfService,
    private candidatureExportExcelService: CandidatureExportExcelService,
    private candidatureExportWordService: CandidatureExportWordService,
    private notification: NzNotificationService,
    private candidatureStartistiquesExportPdfService: CandidatureStartistiquesExportPdfService,
    private candidatureStartistiquesExportPptService: CandidatureStartistiquesExportPptService
  ) {}

  onErrorNotification(error: any, key: string): void {
    this.notification.error('Error', error.message, {
      nzDuration: 10000,
      nzAnimate: true,
      nzPlacement: NOTIFICATION_PLACEMENT,
      nzKey: key ? key : '',
    });
  }
}
