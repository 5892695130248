import { SharedModule } from 'src/app/shared/shared.module';
import { ContentsModule } from 'src/app/contents/contents.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParticipantActionsAccompagnementListComponent } from './components/participant-actions-accompagnement-list/participant-actions-accompagnement-list.component';
import { ParticipantActionsAccompagnementItemComponent } from './components/participant-actions-accompagnement-item/participant-actions-accompagnement-item.component';
import { ParticipantActionsAccompagnementCardComponent } from './components/participant-actions-accompagnement-card/participant-actions-accompagnement-card.component';
import { ParticipantActionsAccompagnementFormComponent } from './components/participant-actions-accompagnement-form/participant-actions-accompagnement-form.component';
import { StoreModule } from '@ngrx/store';
import * as fromParticipantActionAccompagnement from './store/participant-action-accompagnement.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ParticipantActionAccompagnementEffects } from './store/participant-action-accompagnement.effects';
import { ParticipantAccompagnementService } from 'src/app/service/participant/accompagnement/participant-accompagnement.service';
import { ParticipantActionAccompagnementTableComponent } from './components/participant-action-accompagnement-table/participant-action-accompagnement-table.component';
import { ParticipantActionAccompagnementTypeComponent } from './components/participant-action-accompagnement-type/participant-action-accompagnement-type.component';
import { ParticipantActionAccompagnementChampsComponent } from './components/participant-action-accompagnement-champs/participant-action-accompagnement-champs.component';
import { ParticipantActionsAccompagnementComponent } from './components/participant-actions-accompagnement/participant-actions-accompagnement.component';
import { ParticipantActionsAccompagnementActionComponent } from './components/participant-actions-accompagnement-action/participant-actions-accompagnement-action.component';
import { NoResultModule } from 'src/app/contents/components/no-result/no-result.module';
import { CoordonneesModule } from 'src/app/contents/components/coordonnees/coordonnees.module';
import { CommunesFranceModule } from 'src/app/contents/components/communes-france/communes-france.module';
import { ButtonsModule } from 'src/app/contents/components/buttons/buttons.module';
import { HeaderModule } from 'src/app/contents/components/header-title/header.module';
import { LayoutModule } from 'src/app/contents/components/layout/layout.module';
import { AvatarModule } from 'src/app/features/avatar/avatar.module';
import { UiModule } from 'src/app/ui/ui/ui.module';

@NgModule({
  declarations: [
    ParticipantActionsAccompagnementListComponent,
    ParticipantActionsAccompagnementItemComponent,
    ParticipantActionsAccompagnementCardComponent,
    ParticipantActionsAccompagnementFormComponent,
    ParticipantActionAccompagnementTableComponent,
    ParticipantActionAccompagnementTypeComponent,
    ParticipantActionAccompagnementChampsComponent,
    ParticipantActionsAccompagnementComponent,
    ParticipantActionsAccompagnementActionComponent,
  ],
  imports: [
    CommonModule,
    ContentsModule,
    SharedModule,
    NoResultModule,
    CoordonneesModule,
    HeaderModule,
    CommunesFranceModule,
    ButtonsModule,
    LayoutModule,
    AvatarModule,
    UiModule,
    StoreModule.forFeature(
      fromParticipantActionAccompagnement.participantActionAccompagnementsFeatureKey,
      fromParticipantActionAccompagnement.reducer
    ),
    EffectsModule.forFeature([ParticipantActionAccompagnementEffects]),
  ],
  exports: [
    ParticipantActionsAccompagnementListComponent,
    ParticipantActionsAccompagnementItemComponent,
    ParticipantActionsAccompagnementCardComponent,
    ParticipantActionsAccompagnementFormComponent,
    ParticipantActionAccompagnementTableComponent,
    ParticipantActionAccompagnementTypeComponent,
    ParticipantActionAccompagnementChampsComponent,
    ParticipantActionsAccompagnementComponent,
  ],
  providers: [ParticipantAccompagnementService],
})
export class ParticipantActionsAccompagnementModule {}
