import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { capitalize } from 'lodash';
import { Store } from '@ngrx/store';
import { UtilitiesState } from 'src/app/contents/components/utilities/store/utilities.reducer';
import * as fromUtilityAction from 'src/app/contents/components/utilities/store/utilities.actions';
import * as fromUtilitySelector from 'src/app/contents/components/utilities/store/utilities.selectors';

@Component({
  selector: 'app-participant-prescripteur-item',
  templateUrl: './participant-prescripteur-item.component.html',
  styleUrls: ['./participant-prescripteur-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantPrescripteurItemComponent implements OnInit {
  filters$: Observable<any> = of(null);
  loading$: Observable<boolean> = of(false);
  addtitle: string = 'Ajouter un référent';
  notitle: string = 'Aucun référent ajouté';
  newReferentForm: boolean = false;
  add: boolean = false;
  currentReferent: any = null;

  @Input() prescripteur$ = new BehaviorSubject<any>(null);
  @Output() update = new EventEmitter<any>(false);

  prescripteurReferentForm: UntypedFormGroup = this.fb.group({});
  lastName: UntypedFormControl = new UntypedFormControl('', Validators.required);
  firstName: UntypedFormControl = new UntypedFormControl('', Validators.required);
  civilite: UntypedFormControl = new UntypedFormControl('', Validators.required);
  fonction: UntypedFormControl = new UntypedFormControl('');
  coordonnees: UntypedFormControl = new UntypedFormControl('');
  coordonneesUpdate$ = new BehaviorSubject<any>(null);

  constructor(
    private fb: UntypedFormBuilder,
    private utilityStore: Store<UtilitiesState>
  ) {}

  ngOnInit(): void {
    this.form();
    this.getFilters();
    this.getloading();
  }

  form(): void {
    this.formInit();
    this.formCreate();
  }

  formInit(): void {
    this.lastName = new UntypedFormControl('', Validators.required);
    this.firstName = new UntypedFormControl('', Validators.required);
    this.civilite = new UntypedFormControl('', Validators.required);
    this.fonction = new UntypedFormControl('');
    this.coordonnees = new UntypedFormControl('');
  }

  formCreate(): void {
    this.prescripteurReferentForm = this.fb.group({
      lastName: this.lastName,
      firstName: this.firstName,
      civilite: this.civilite,
      fonction: this.fonction,
      coordonnees: this.coordonnees,
    });
  }

  formUpdate(referent: any): void {
    this.prescripteurReferentForm.patchValue({
      lastName: referent.lastName,
      firstName: referent.firstName,
      civilite: referent.civilite,
      fonction: referent.fonction,
      coordonnees: referent.coordonnees,
    });
    this.coordonneesUpdate$.next(referent.coordonnees);
  }

  compareCivilite(obj1: any, obj2: any) {
    return obj1 && obj2 && obj1 === obj2;
  }

  getFilters(): void {
    this.filters$ = this.utilityStore.select(
      fromUtilitySelector.participantIdentity
    );
  }

  getloading(): void {
    this.loading$ = this.utilityStore.select(fromUtilitySelector.loading);
  }

  loadFilters(): void {
    this.utilityStore.dispatch(
      fromUtilityAction.loadParticipantUtilitiesIdentity()
    );
  }

  setNewReferent(status: boolean): void {
    this.newReferentForm = status;
    this.add = true;
  }

  newReferent(): void {
    this.loadFilters();
    this.setNewReferent(true);
    this.form();
  }
  setUpdateReferent(referent: any, prescripteur: any): void {
    this.formUpdate(referent);
    this.setNewReferent(true);
    this.add = false;
    this.currentReferent = referent;
  }

  onSetMobile(mobile: any): void {
    const values: any = this.prescripteurReferentForm.get('coordonnees')?.value;

    this.prescripteurReferentForm.patchValue({
      coordonnees: {
        ...values,
        mobile: mobile,
      },
    });
  }

  onSetPhoneNumber(phoneNumber: any): void {
    const values: any = this.prescripteurReferentForm.get('coordonnees')?.value;
    this.prescripteurReferentForm.patchValue({
      coordonnees: {
        ...values,
        phoneNumber: phoneNumber,
      },
    });
  }

  onSetEmail(email: any): void {
    const values: any = this.prescripteurReferentForm.get('coordonnees')?.value;
    this.prescripteurReferentForm.patchValue({
      coordonnees: {
        ...values,
        email: email,
      },
    });
  }
  onSetFixe(fixe: any): void {
    const values: any = this.prescripteurReferentForm.get('coordonnees')?.value;

    this.prescripteurReferentForm.patchValue({
      coordonnees: {
        ...values,
        fixe: fixe,
      },
    });
  }

  addReferent(prescripteur: any): void {
    if (!this.prescripteurReferentForm.valid) return;

    const values = this.prescripteurReferentForm.value;
    const referent = {
      ...values,
      lastName: values.lastName.toUpperCase(),
      firstName: capitalize(values.firstName),
      fonction: capitalize(values.fonction),
      id: uuidv4(),
    };

    const referents: any[] = prescripteur?.referents?.length
      ? prescripteur?.referents
      : [];
    const nextReferents: any[] = referents?.length
      ? [...referents, referent]
      : [referent];
    const nextPrescripteur = {
      ...prescripteur,
      referents: nextReferents,
    };

    this.update.emit(nextPrescripteur);
    this.prescripteur$.next(nextPrescripteur);
    this.onCancel();
  }

  updateReferent(prescripteur: any, referent: any): void {
    if (!this.prescripteurReferentForm.valid) return;

    const values = this.prescripteurReferentForm.value;
    const nextReferent = {
      ...referent,
      ...values,
      lastName: values.lastName.toUpperCase(),
      firstName: capitalize(values.firstName),
      fonction: capitalize(values.fonction),
    };

    const referents: any[] = prescripteur?.referents.filter(
      (el: any) => el.id !== referent.id
    );
    const nextReferents: any[] = referents?.length
      ? [...referents, nextReferent]
      : [nextReferent];
    const nextPrescripteur = {
      ...prescripteur,
      referents: nextReferents,
    };

    this.update.emit(nextPrescripteur);
    this.prescripteur$.next(nextPrescripteur);
    this.onCancel();
  }

  removeReferent(referent: any, prescripteur: any): void {
    const referents: any[] = prescripteur?.referents?.length
      ? prescripteur?.referents
      : [];
    const nextReferents: any[] = referents?.length
      ? referents.filter((el) => el.id !== referent.id)
      : [];
    const nextPrescripteur = {
      ...prescripteur,
      referents: nextReferents,
    };

    this.prescripteur$.next(nextPrescripteur);

    this.update.emit(nextPrescripteur);
    this.onCancel();
  }

  onCancel(): void {
    this.setNewReferent(false);
    this.prescripteurReferentForm.reset();
    this.currentReferent = null;
  }
}
