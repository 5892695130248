<nz-card *ngIf="note$ | async as note">
  <ng-template #noteActionHeader>
    <nz-space nzDirection="horizontal" nzSize="small">
      Actions
      <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>

      <nz-badge
        [nzCount]="(actions$ | async)?.length"
        [nzOverflowCount]="99"
        [nzStyle]="{
          backgroundColor: '#ffab00',
          color: 'black',
          fontWeigth: 'bold'
        }"
      ></nz-badge>
      <ul
        style="list-style: none"
        class="filter__ACTION"
        *ngIf="actionsStatut$ | async as statuts"
      >
        <li *ngFor="let item of statuts">
          <nz-tag
            [nzColor]="item.color"
            (click)="$event.stopPropagation(); select_filter__ACTION(item)"
          >
            {{ item.statut }}
            <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
            {{ item?.length }}
          </nz-tag>
        </li>
      </ul>
    </nz-space>
  </ng-template>
  <ng-container *ngIf="sujet$ | async as sujet">
    <nz-input-group
      nzSize="default"
      [nzAddOnAfter]="addActionAfter"
      style="width: 80%"
    >
      <input
        nz-input
        [(ngModel)]="new__ACTION_input"
        placeholder="Actions.."
        (keyup.enter)="confirm_action(sujet)"
      />
    </nz-input-group>
    <button nz-button (click)="$event.stopPropagation(); add__ACTIONS(note)">
      Tout valider
    </button>

    <ng-template #addActionAfter>
      <i
        nz-icon
        nzTheme="outline"
        nzType="plus-circle"
        (click)="confirm_action(sujet)"
      ></i>
    </ng-template>
  </ng-container>

  <form
    nz-form
    [formGroup]="actionForm"
    nzLayout="vertical"
    *ngIf="actionsArray?.length"
  >
    <div
      formArrayName="actions"
      *ngIf="actionsArray?.length; else noData"
      style="height: 200px; width: 100%; overflow: auto"
    >
      <nz-list nzSize="small">
        <nz-list-item
          style="height: 100px"
          *ngFor="let el of actionsArray.controls; let i = index"
          [formGroupName]="i"
        >
          <p nz-typography nzEllipsis nzEllipsisRows="2" style="width: 40%">
            {{ el?.value.text }}
          </p>

          <nz-form-item style="width: 30%">
            <nz-form-label nzRequired="true" [nzSpan]="24" nzFor="dateStart"
              >Démarrage - Échéance</nz-form-label
            >
            <nz-range-picker
              nzSize="small"
              nzFormat="dd-MM-yyyy"
              formControlName="dates"
            ></nz-range-picker>
          </nz-form-item>

          <nz-form-item style="width: 25%">
            <nz-form-label nzRequired="true" [nzSpan]="24" nzFor="responsables"
              >Référent</nz-form-label
            >
            <nz-form-control [nzSpan]="24" required>
              <nz-select
                nzShowSearch="true"
                style="width: 100%"
                nzPlaceHolder="Référent(s)..."
                formControlName="responsables"
                nzSize="small"
                required
                id="responsables"
              >
                <!-- <nz-option
                  *ngFor="let role of ROLES_LIST"
                  [nzLabel]="role.item"
                  [nzValue]="role.item"
                  nz-popover nzPopoverTitle=""
                  [nzPopoverContent]="role.info"
                  nzPopoverPlacement="rigth"
                >
                  {{ role.item }}
                </nz-option> -->
              </nz-select>
            </nz-form-control>
          </nz-form-item>

          <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>

          <i
            nz-icon
            nzTheme="outline"
            nzType="delete"
            class="iconDelete"
            style="float: right; cursor: pointer"
            (click)="$event.stopPropagation(); remove_action(i)"
          ></i>
        </nz-list-item>
      </nz-list>
    </div>
  </form>

  <div *ngIf="!actionsArray?.length" class="list__actions">
    <nz-list nzItemLayout="horizontal" *ngIf="actions$ | async as actions">
      <nz-list-item *ngFor="let item of actions">
        <nz-list-item-meta
          [nzDescription]="descriptionAction"
          *ngIf="!editCache__ACTION[item.id]?.edit; else actionEdit"
        >
          <ng-template #descriptionAction>
            <span>
              <nz-badge
                nzColor="blue"
                *ngIf="item.statut === 'En attente'"
              ></nz-badge>
              <nz-badge
                nzColor="orange"
                *ngIf="item.statut === 'En cours'"
              ></nz-badge>
              <nz-badge
                nzColor="red"
                *ngIf="item.statut === 'Relance'"
              ></nz-badge>
              <nz-badge
                nzColor="green"
                *ngIf="item.statut === 'Réalisée'"
              ></nz-badge>

              <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
              <p
                nz-typography
                nzEllipsis
                nzExpandable
                [nzEllipsisRows]="2"
                style="color: black"
              >
                {{ item.text }}
              </p>
            </span>

            <br />
            <span
              >Création : {{ item.dateStart | dateFr }} par
              {{ item.auteur.displayName }}</span
            >
            <br />
            <span>Démarrage : {{ item.dates[0] | dateFr: "time" }}</span>
            <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
            <span>Échéance : {{ item.dates[1] | dateFr: "time" }}</span>

            <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
            <br />
            <span
              >Référent(s) :
              <span *ngIf="item?.responsables?.length">
                {{ item.responsables[0].representants[0].contact.lastName }}
                {{ item.responsables?.length }}
              </span>
            </span>
          </ng-template>

          <nz-list-item-meta-title>
            <button
              nz-button
              nzSize="small"
              (click)="$event.stopPropagation(); onUpdate__ACTION_edit(item)"
            >
              Modifier
            </button>
            <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
            <button
              nz-button
              nzSize="small"
              class="iconDelete"
              (click)="$event.stopPropagation()"
              nz-popconfirm
              nzPopconfirmTitle="Voulez-vous supprimer cette action ?"
              nzPopconfirmPlacement="bottom"
              (nzOnConfirm)="delete__ACTION(item)"
              (nzOnCancel)="cancel_delete__DDOCUMENT()"
            >
              Supprimer
            </button>
          </nz-list-item-meta-title>
        </nz-list-item-meta>

        <ng-template #actionEdit>
          <form
            nz-form
            [formGroup]="update_ActionForm"
            (ngSubmit)="$event.stopPropagation(); update__ACTION()"
            nzLayout="horizontal"
          >
            <nz-card
              style="width: 100%"
              nzTitle="Modification"
              [nzExtra]="actionEditing"
            >
              <nz-form-item nzRequired="true">
                <nz-form-control>
                  <textarea
                    style="width: 100%"
                    nz-input
                    formControlName="text"
                    [nzAutosize]="{ minRows: 4, maxRows: 4 }"
                  ></textarea>
                </nz-form-control>
              </nz-form-item>

              <nz-space
                nzDirection="horizontal"
                nzSize="small"
                styles="margin-bottom: -10px"
              >
                <nz-form-item nzRequired="true">
                  <nz-form-control>
                    <nz-range-picker
                      nzSize="small"
                      nzFormat="dd-MM-yyyy"
                      formControlName="dates"
                    ></nz-range-picker>
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item nzRequired="true">
                  <nz-form-control>
                    <nz-select formControlName="statut" nzSize="small">
                      <nz-option
                        *ngFor="let item of (utilities$ | async)?.actionType"
                        [nzLabel]="item"
                        [nzValue]="item"
                      ></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </nz-space>
            </nz-card>

            <ng-template #actionEditing>
              <nz-button-group nzSize="small">
                <a (click)="$event.stopPropagation(); update__ACTION()">
                  Modifier
                </a>
                <nz-divider
                  nzType="vertical"
                  nzOrientation="center"
                ></nz-divider>
                <a (click)="cancelEdit(item)"> Ignorer </a>
              </nz-button-group>
            </ng-template>
          </form>
        </ng-template>
      </nz-list-item>
      <nz-list-empty *ngIf="actions?.length === 0"></nz-list-empty>
    </nz-list>
  </div>
</nz-card>

<ng-template #noData>
  <app-no-result [description]="'Aucune action'"></app-no-result>
</ng-template>
