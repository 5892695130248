<div class="p-2 w-[20rem] rounded-xl bg-slate-50 flex items-start space-x-2">
  <ng-container *ngFor="let item of items">
    <div class="card card-compact w-96 bg-base-100 shadow-xl">
      <figure>
        <img
          src="/images/stock/photo-1606107557195-0e29a4b5b4aa.jpg"
          alt="Shoes"
        />
      </figure>
      <div class="card-body">
        <h2 class="card-title">Shoes!</h2>
        <p>If a dog chews shoes whose shoes does he choose?</p>
        <div class="card-actions justify-end">
          <button class="btn btn-sm btn-primary">Buy Now</button>
        </div>
      </div>
    </div>
  </ng-container>
</div>
