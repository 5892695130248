import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiInputComponent } from './components/ui-input/ui-input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { PersonSelectorComponent } from './components/person-selector/person-selector.component';
import { TagSelectorComponent } from './components/tag-selector/tag-selector.component';
import { TagIndicatorComponent } from './components/tag-indicator/tag-indicator.component';
import { ProgressLoadingComponent } from './components/progress-loading/progress-loading.component';
import { CardNewComponent } from './components/card-new/card-new.component';
import { ButtonActionNewComponent } from './components/button-action-new/button-action-new.component';
import { CoordonneesDisplayComponent } from './components/coordonnees-display/coordonnees-display.component';

@NgModule({
  declarations: [
    UiInputComponent,
    PersonSelectorComponent,
    TagSelectorComponent,
    TagIndicatorComponent,
    ProgressLoadingComponent,
    CardNewComponent,
    ButtonActionNewComponent,
    CoordonneesDisplayComponent,
  ],
  exports: [
    UiInputComponent,
    PersonSelectorComponent,
    TagSelectorComponent,
    TagIndicatorComponent,
    ProgressLoadingComponent,
    CardNewComponent,
    ButtonActionNewComponent,
    CoordonneesDisplayComponent,
  ],
  imports: [CommonModule, ReactiveFormsModule, SharedModule],
})
export class UiModule {}
