import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  OperationDashboardState,
  operationDashboardsFeatureKey,
  selectAll,
} from './operation-dashboard.reducer';

export const selectOperatioDashboardState =
  createFeatureSelector<OperationDashboardState>(operationDashboardsFeatureKey);

export const dashboards = createSelector(
  selectOperatioDashboardState,
  selectAll
);

export const dashboard = createSelector(
  selectOperatioDashboardState,
  (state: OperationDashboardState) => state.dashboard
);

export const loading = createSelector(
  selectOperatioDashboardState,
  (state: OperationDashboardState) => state.loading
);
export const error = createSelector(
  selectOperatioDashboardState,
  (state: OperationDashboardState) => state.error
);
