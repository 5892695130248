<ng-container *ngIf="operation$ | async as operation">
  <div [ngSwitch]="view$ | async">
    <div *ngSwitchCase="'list'">
      <ng-container *ngIf="members$ | async | size; else noResults">
        <nz-page-header
          class="page-header"
          [nzTitle]="operationTeamHeader"
          [nzSubtitle]="operationTeamCardExtra"
        >
          <nz-page-header-extra>
            <ng-container
              *ngIf="(user$ | async | isDev) || (user$ | async | isAdmin)"
            >
              <button
                nz-button
                nzType="text"
                nzShape="circle"
                *ngIf="members$ | async as members"
                (click)="onNew(operation, members)"
              >
                <mat-icon>person_add_alt</mat-icon>
              </button>
              <app-button-dropdown
                *ngIf="members$ | async as members"
                [isDeleteAll]="true"
                [deleteAllType]="deleteAllType"
                (select)="onAction($event, operation)"
              ></app-button-dropdown>
            </ng-container>
          </nz-page-header-extra>
          <nz-page-header-content> </nz-page-header-content>
          <ng-template #operationTeamHeader>
            <app-header-title
              [title]="titlePage"
              [subtitle]="true"
              [icon]="''"
            ></app-header-title>
          </ng-template>
          <ng-template #operationTeamCardExtra>
            <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
              <nz-col nzSpan="24">
                <nz-badge
                  [nzCount]="members$ | async | size"
                  [nzOverflowCount]="99"
                >
                </nz-badge>
              </nz-col>
            </nz-row>
          </ng-template>
        </nz-page-header>

        <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>

        <app-layout-list
          [card]="card"
          [new]="(user$ | async).id === operation.auteur.id ? new : null"
          [source]="members$ | async"
        ></app-layout-list>
      </ng-container>
    </div>
    <div *ngSwitchCase="'details'">
      <app-operation-team-member (back)="onBack()"></app-operation-team-member>
    </div>
    <div *ngSwitchDefault></div>
  </div>

  <ng-template #new>
    <app-card-new
      [type]="'person'"
      [description]="newCardDescription"
      [width]="'300px'"
      [height]="'250px'"
      *ngIf="members$ | async as members"
      (add)="onNew(operation, members)"
      (back)="onBack()"
      (cancel)="onBack()"
    ></app-card-new>
  </ng-template>

  <ng-template #card let-item let-position="idx">
    <app-operation-team-card
      [item]="item"
      [user$]="user$"
      [operation]="operation"
      (delete)="onDelete($event)"
    ></app-operation-team-card>
  </ng-template>

  <ng-template #noResults>
    <app-no-result
      [icon]="'people'"
      [addtitle]="newCardDescription"
      [notitle]="noModuleTitle"
      [description]="newCardDefinition"
      [isButton]="(user$ | async).id === operation.auteur.id"
      *ngIf="members$ | async as members"
      (new)="onNew(operation, members)"
    ></app-no-result>
  </ng-template>

  <ng-template #teamFormTitle>
    <app-header-title
      [icon]="'people'"
      [title]="title"
      [subtitle]="true"
    ></app-header-title>
  </ng-template>
  <ng-template #userFormTitle>
    <app-header-title
      [icon]="'people'"
      [title]="roleTitle"
      [subtitle]="true"
    ></app-header-title>
  </ng-template>
</ng-container>
