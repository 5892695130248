import { takeUntil } from 'rxjs/operators';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable, of, Subject } from 'rxjs';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ContactSMALLid } from 'src/app/components/contact/store/contact.model';
import { Store } from '@ngrx/store';
import { EntiteState } from '../../store/entite.reducer';
import * as fromEntiteAction from '../../store/entite.actions';
import * as fromEntiteSelector from '../../store/entite.selectors';
import { EntiteId } from '../../store/entite.model';

@Component({
  selector: 'app-entite-contacts',
  templateUrl: './entite-contacts.component.html',
  styleUrls: ['./entite-contacts.component.scss'],
})
export class EntiteContactsComponent implements OnInit, OnDestroy {
  contacts$: Observable<ContactSMALLid[] | any> = of([]);
  contact$: Observable<ContactSMALLid | any> = of(null);
  subscribe = new Subject();

  modalWidth: number = 650;
  newCardTitle: string = 'Nouveau collaborateur';
  newCardDescription: string = 'Ajouter un collaborateur à cette entité';
  newCardDefinition: string = `Un.e collaborateur.trice est un contact au sein de l'entité.`;

  @Input() entite$: Observable<EntiteId | any> = of();

  constructor(
    private entiteStore: Store<EntiteState>,
    private modale: NzModalService
  ) {}

  ngOnInit(): void {
    this.getEntite();
    this.getContacts();
    this.laodContacts();
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getEntite(): void {
    this.entite$ = this.entiteStore.select(fromEntiteSelector.entite);
  }

  setTitle(): void {
    this.entite$
      .pipe(takeUntil(this.subscribe))
      .subscribe((entite: EntiteId) => {
        if (!entite) return;

        const nom_raison_sociale: string = `${
          entite.etablissement.denomination.substring(0, 25)?.length > 25
            ? entite.etablissement.denomination.substring(0, 25) + '...'
            : entite.etablissement.denomination.substring(0, 25)
        }`;
        this.newCardTitle = `Nouveau contact`;
        this.newCardDescription = ``;
        this.newCardDefinition = `Un contact est un collaborateur de l'entité  ${nom_raison_sociale}.`;
      });
  }

  getContacts(): void {
    this.contacts$ = this.entiteStore.select(fromEntiteSelector.contacts);
  }
  laodContacts(): void {
    this.entite$.pipe(takeUntil(this.subscribe)).subscribe((entite) => {
      if (!entite) return;
      this.entiteStore.dispatch(
        fromEntiteAction.loadEntiteContacts({ id: entite.id })
      );
    });
  }

  onNewContactForm(entite: EntiteId): void {}

  onAdd(): void {}
  onDelete(): void {}

  onCancel(): void {}
}
