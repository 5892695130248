<div class="w-full p-2 h-[40rem] space-y-4">
  <div>
    <nz-steps [nzCurrent]="currentStep" nzSize="small">
      <nz-step [nzTitle]="'Description'"> </nz-step>

      <nz-step [nzTitle]="'Destinataires'"></nz-step>
    </nz-steps>

    <form [formGroup]="diffusionForm" class="w-full m-4 mt-6">
      <div [ngSwitch]="currentStep" class="w-full m2">
        <div *ngSwitchCase="0">
          <form
            [formGroup]="diffusionForm"
            class="w-full flex flex-col items-start space-y-12 h-96"
          >
            <div class="w-full flex items-start space-x-4">
              <div [class]="isAdd ? 'w-2/4' : 'hidden'">
                <div class="w-full">
                  <label class="label required">Titre</label>
                  <div>
                    <input
                      formControlName="title"
                      class="inputForm"
                      placeholder="Titre de la diffusion"
                    />
                  </div>
                  <small
                    class="inputFormAlert"
                    *ngIf="!title.valid && title.touched && title.dirty"
                    >Veuillez renseigner le titre de la diffussion</small
                  >
                </div>
              </div>
              <div [class]="isAdd ? 'w-1/4' : 'hidden'">
                <div class="w-full flex flex-col">
                  <label class="label required">Type de destinataires</label>
                  <div class="select-menu">
                    <nz-select
                      formControlName="destinataireType"
                      [compareWith]="compareSelect"
                      nzBorderless
                      class="w-full bg-transparent border-none"
                      nzPlaceHolder="Destinataire"
                    >
                      <nz-option
                        *ngFor="let option of destinataireType$ | async"
                        [nzLabel]="option"
                        [nzValue]="option"
                      ></nz-option>
                    </nz-select>
                  </div>

                  <small
                    class="inputFormAlert"
                    *ngIf="
                      !destinataireType.valid &&
                      destinataireType.touched &&
                      destinataireType.dirty
                    "
                    >Veuillez renseigner le type destinataire</small
                  >
                </div>
              </div>
              <div [class]="isUpdate ? 'w-2/4' : 'w-1/4'">
                <div class="w-full flex flex-col items-start">
                  <label class="label required">Date de clôture</label>
                  <div class="numberForm">
                    <nz-date-picker
                      class="numberField"
                      name="value"
                      nzPlaceHolder="Date d\'échéance"
                      formControlName="endAt"
                      nzFormat="dd/MM/yyyy"
                    ></nz-date-picker>
                  </div>
                  <small
                    class="inputFormAlert"
                    *ngIf="!endAt.valid && endAt.touched && endAt.dirty"
                    >Veuillez renseigner une date d'échéance</small
                  >
                </div>
              </div>
              <div [class]="isUpdate ? 'w-2/4' : 'w-1/4'">
                <div class="w-full flex flex-col items-start">
                  <label class="label required">Nombre de profils</label>
                  <div class="numberForm">
                    <input
                      class="numberField"
                      type="number"
                      min="1"
                      max="100"
                      name="diffusionDrofilNumber"
                      formControlName="profilNumber"
                      placeholder="Nombre"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="w-full flex items-start space-x-4" *ngIf="isAdd">
              <div class="w-full flex flex-col">
                <label class="label required"
                  >Expéditeur en charge de la diffusion</label
                >
                <div class="select-menu">
                  <app-person-selector
                    [persons$]="users$"
                    [isRole]="true"
                    (select)="onSelectUser($event)"
                    class="bg-transparent border-none w-full"
                  ></app-person-selector>
                </div>
              </div>
            </div>
            <div class="w-full flex items-start">
              <div class="w-full flex flex-col items-start">
                <label class="label">Note d'information</label>
                <div class="w-full">
                  <app-text-edithing
                    [update]="informationComplementaireUpdate$"
                    (add)="onComplementInformation($event)"
                  ></app-text-edithing>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div *ngSwitchCase="1">
          <app-person-form
            [isList]="true"
            [isDateNaissance]="isDateNaissance"
            [isEtablissement]="isEtablissement"
            [isAdresse]="isAdresse"
            [isFonction]="true"
            [isFonctionRequired]="true"
            [isEmailRequired]="true"
            [isRole]="false"
            [contactsInput]="contactsUpdate$ | async"
            (onCurrentChanges)="onAddContacs($event)"
          >
          </app-person-form>
        </div>
        <div *ngSwitchDefault></div>
      </div>
    </form>
  </div>
  <div class="w-full flex items-end space-x-2">
    <div class="w-3/4"></div>
    <div class="w-20">
      <button class="btn btn-sm btn-ghost" (click)="onCancel()">Annuler</button>
    </div>
    <div class="w-40" *ngIf="currentStep === 1">
      <button class="btn btn-sm" (click)="onStepBack()">Précédent</button>
    </div>
    <div class="w-40" *ngIf="currentStep === 0">
      <button
        [class]="
          !title.valid ||
          !destinataireType.valid ||
          !endAt.valid ||
          !diffusionForm.value.recrutementBy?.id
            ? 'btn btn-sm btn-disabled'
            : 'btn btn-sm btn-primary'
        "
        [disabled]="
          !title.valid ||
          !destinataireType.valid ||
          !endAt.valid ||
          !diffusionForm.value.recrutementBy?.id
        "
        (click)="onStepNext()"
      >
        Suivant
      </button>
    </div>
    <div class="w-40" *ngIf="currentStep === 1">
      <button
        *ngIf="isAdd"
        [disabled]="
          !diffusionForm.value.recrutementBy ||
          !diffusionForm.value.contacts?.[0]?.lastName ||
          !diffusionForm.value.contacts?.[0]?.firstName ||
          !diffusionForm.value.contacts?.[0]?.coordonnees?.email ||
          !diffusionForm.value.contacts?.[0]?.fonction ||
          !diffusionForm.value.contacts?.[0]?.etablissement?.siret ||
          !diffusionForm.value.contacts?.[0]?.etablissement?.uniteLegale?.denominationUniteLegale
        "
        [class]="
        !diffusionForm.value.recrutementBy ||
        !diffusionForm.value.contacts?.[0]?.lastName ||
        !diffusionForm.value.contacts?.[0]?.firstName ||
        !diffusionForm.value.contacts?.[0]?.coordonnees?.email ||
        !diffusionForm.value.contacts?.[0]?.fonction ||
        !diffusionForm.value.contacts?.[0]?.etablissement?.siret ||
        !diffusionForm.value.contacts?.[0]?.etablissement?.uniteLegale?.denominationUniteLegale


            ? 'btn btn-sm btn-disabled'
            : 'btn btn-sm btn-primary'
        "
        (click)="onAdd()"
      >
        Ajouter
      </button>
      <ng-container *ngIf="isUpdate">
        <button
          *ngIf="diffusion$ | async as diffusion"
          [disabled]="
        !diffusionForm.valid || 
        !diffusionForm.value.contacts?.[0]?.lastName ||
        !diffusionForm.value.contacts?.[0]?.firstName ||
        !diffusionForm.value.contacts?.[0]?.coordonnees?.mobile ||
        !diffusionForm.value.contacts?.[0]?.coordonnees?.email ||
        !diffusionForm.value.contacts?.[0]?.fonction ||
        !diffusionForm.value.contacts?.[0]?.etablissement?.siret ||
        !diffusionForm.value.contacts?.[0]?.etablissement?.denominationUniteLegale
"
          [class]="
        !diffusionForm.valid || 
        !diffusionForm.value.contacts?.[0]?.lastName ||
        !diffusionForm.value.contacts?.[0]?.firstName ||
        !diffusionForm.value.contacts?.[0]?.coordonnees?.mobile ||
        !diffusionForm.value.contacts?.[0]?.coordonnees?.email ||
        !diffusionForm.value.contacts?.[0]?.fonction ||
        !diffusionForm.value.contacts?.[0]?.etablissement?.siret ||
        !diffusionForm.value.contacts?.[0]?.etablissement?.denominationUniteLegale
          ? 'btn btn-sm btn-disabled'
            : 'btn btn-sm btn-primary'
        "
          (click)="onUpdate(diffusion)"
        >
          Modifier
        </button>
      </ng-container>
    </div>
  </div>
</div>
