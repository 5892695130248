import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { EntiteSuivi } from './entite-suivi.model';
import * as EntiteSuiviActions from './entite-suivi.actions';

export const entiteSuivisFeatureKey = 'entiteSuivis';

export interface EntiteSuiviState extends EntityState<EntiteSuivi> {
  suivi: any;
  loading: boolean;
  error: any;
}

export const adapter: EntityAdapter<EntiteSuivi> =
  createEntityAdapter<EntiteSuivi>();

export const initialState: EntiteSuiviState = adapter.getInitialState({
  suivi: undefined,
  loading: false,
  error: undefined,
});

export const reducer = createReducer(
  initialState,

  //LOAD ALL
  on(EntiteSuiviActions.loadEntiteSuivis, (state, action) => {
    return {
      ...state,
      loading: true,
      error: undefined,
    };
  }),
  on(EntiteSuiviActions.loadEntiteSuivisSuccess, (state, action) =>
    adapter.setAll(action.suivis, state)
  ),
  on(EntiteSuiviActions.loadEntiteSuivisSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      error: undefined,
    };
  }),
  on(EntiteSuiviActions.loadEntiteSuivisFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
      loading: false,
    };
  }),

  //LOAD ONE
  on(EntiteSuiviActions.loadEntiteSuivi, (state, action) => {
    return {
      ...state,
      loading: true,
      error: undefined,
    };
  }),
  on(EntiteSuiviActions.loadEntiteSuiviSuccess, (state, action) => {
    return {
      ...state,
      suivi: action.suivi,
      loading: false,
    };
  }),
  on(EntiteSuiviActions.loadEntiteSuiviFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      error: undefined,
    };
  }),

  //ADD ONE
  on(EntiteSuiviActions.addEntiteSuivi, (state, action) => {
    return {
      ...state,
      error: undefined,
      loading: false,
    };
  }),
  on(EntiteSuiviActions.addEntiteSuiviSuccess, (state, action) =>
    adapter.addOne(action.suivi, state)
  ),
  on(EntiteSuiviActions.addEntiteSuiviFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
      loading: false,
    };
  }),

  //UPDATE ONE
  on(EntiteSuiviActions.updateEntiteSuivi, (state, action) =>
    adapter.updateOne(action.suivi, state)
  ),
  on(EntiteSuiviActions.updateEntiteSuivi, (state, action) => {
    return {
      ...state,
      error: undefined,
      loading: false,
    };
  }),

  //DELETE ONE
  on(EntiteSuiviActions.deleteEntiteSuivi, (state, action) =>
    adapter.removeOne(action.id, state)
  ),

  on(EntiteSuiviActions.deleteEntiteSuiviFailure, (state, action) => {
    return {
      ...state,
      error: undefined,
      loading: false,
    };
  }),

  //DELETE ALL
  on(EntiteSuiviActions.deleteEntiteSuivis, (state, action) =>
    adapter.removeAll(state)
  ),

  on(EntiteSuiviActions.deleteEntiteSuivisFailure, (state, action) => {
    return {
      ...state,
      error: undefined,
      loading: false,
    };
  }),

  //CLEAR
  on(EntiteSuiviActions.clearEntiteSuivis, (state) => adapter.removeAll(state))
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
