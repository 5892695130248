import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-projet-professionnel-formations-form-card',
  templateUrl: './projet-professionnel-formations-form-card.component.html',
  styleUrls: ['./projet-professionnel-formations-form-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjetProfessionnelFormationsFormCardComponent
  implements OnInit, OnChanges
{
  isApprentissage: string = 'Apprentissage';
  @Input() formation: any = null;
  @Output() select = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}

  onSelect(formation: any): void {
    this.select.emit(formation);
  }
}
