import { EntiteId } from './../../store/entite.model';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-entite-detail',
  templateUrl: './entite-detail.component.html',
  styleUrls: ['./entite-detail.component.scss'],
})
export class EntiteDetailComponent implements OnInit {
  default: string = '#bfbfbf';

  @Input() entite: EntiteId | any;
  @Input() statistiques: any;
  constructor() {}

  ngOnInit(): void {}
}
