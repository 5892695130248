import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { OperationNoteParticipantId } from './operation-note-participant.model';
import * as OperationNoteParticipantActions from './operation-note-participant.actions';

export const operationNoteParticipantsFeatureKey = 'operationNoteParticipants';

export interface OperationNoteParticipantState
  extends EntityState<OperationNoteParticipantId> {
  loading: boolean;
  error: any;
}

export const adapter: EntityAdapter<OperationNoteParticipantId> =
  createEntityAdapter<OperationNoteParticipantId>();

export const initialState: OperationNoteParticipantState =
  adapter.getInitialState({
    loading: false,
    error: undefined,
  });

export const reducer = createReducer(
  initialState,
  //LOAD
  on(
    OperationNoteParticipantActions.loadOperationNoteParticipants,
    (state, action) => {
      return {
        ...state,
        loading: true,
      };
    }
  ),
  on(
    OperationNoteParticipantActions.loadOperationNoteParticipantsSuccess,
    (state, action) => adapter.setAll(action.participants, state)
  ),
  on(
    OperationNoteParticipantActions.loadOperationNoteParticipantsFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
  ),

  //ADD
  on(
    OperationNoteParticipantActions.addOperationNoteParticipant,
    (state, action) => {
      return {
        ...state,
        loading: true,
      };
    }
  ),
  on(
    OperationNoteParticipantActions.addOperationNoteParticipantSuccess,
    (state, action) => {
      return {
        ...state,
        loading: false,
      };
    }
  ),
  on(
    OperationNoteParticipantActions.addOperationNoteParticipantFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
  ),

  //UPDATE
  on(
    OperationNoteParticipantActions.updateOperationNoteParticipant,
    (state, action) => adapter.updateOne(action.participant, state)
  ),
  on(
    OperationNoteParticipantActions.updateOperationNoteParticipantSuccess,
    (state, action) => {
      return {
        ...state,
        loading: false,
      };
    }
  ),
  on(
    OperationNoteParticipantActions.updateOperationNoteParticipantFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
  ),

  //DELETE ONE
  on(
    OperationNoteParticipantActions.deleteOperationNoteParticipant,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(
    OperationNoteParticipantActions.deleteOperationNoteParticipantSuccess,
    (state, action) => {
      return {
        ...state,
        loading: false,
      };
    }
  ),
  on(
    OperationNoteParticipantActions.deleteOperationNoteParticipantFailure,
    (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
  ),

  //DELETE MANY
  on(
    OperationNoteParticipantActions.deleteOperationNoteParticipants,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(
    OperationNoteParticipantActions.deleteOperationNoteParticipantsSuccess,
    (state, action) => {
      return {
        ...state,
        loading: false,
      };
    }
  ),
  on(
    OperationNoteParticipantActions.deleteOperationNoteParticipantsFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
  ),

  //DELETE ALL

  on(OperationNoteParticipantActions.clearOperationNoteParticipants, (state) =>
    adapter.removeAll(state)
  )
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
