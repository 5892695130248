import { MetiersNafSuggestionsComponent } from './components/metiers-naf-suggestions/metiers-naf-suggestions.component';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ContentsModule } from './../../contents/contents.module';
import { SharedModule } from './../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntiteMetiersSuggestionsService } from 'src/app/service/entite-metiers-suggestions.service';
import * as fromMetiersSuggestions from './store/metiers-suggestion.reducer';
import { MetiersSuggestionEffects } from './store/metiers-suggestion.effects';
import { MetiersNafDetailsComponent } from './components/metiers-naf-details/metiers-naf-details.component';
import { MetiersItemComponent } from './components/metiers-item/metiers-item.component';
import { MetiersCardComponent } from './components/metiers-card/metiers-card.component';
import { MetiersFormComponent } from './components/metiers-form/metiers-form.component';
import { NoResultModule } from 'src/app/contents/components/no-result/no-result.module';
import { HeaderModule } from 'src/app/contents/components/header-title/header.module';

@NgModule({
  declarations: [
    MetiersNafSuggestionsComponent,
    MetiersNafDetailsComponent,
    MetiersItemComponent,
    MetiersCardComponent,
    MetiersFormComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ContentsModule,
    NoResultModule,
    HeaderModule,

    StoreModule.forFeature(
      fromMetiersSuggestions.metiersSuggestionsFeatureKey,
      fromMetiersSuggestions.reducer
    ),
    EffectsModule.forFeature([MetiersSuggestionEffects]),
  ],
  exports: [
    MetiersNafSuggestionsComponent,
    MetiersNafDetailsComponent,
    MetiersItemComponent,
    MetiersCardComponent,
    MetiersFormComponent,
  ],
  providers: [EntiteMetiersSuggestionsService],
})
export class MetiersSuggestionsModule {}
