import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import {
  OperationNoteSujet,
  OperationNoteSujetId,
} from './operation-note-sujet.model';
import * as OperationNoteSujetActions from './operation-note-sujet.actions';

export const operationNoteSujetsFeatureKey = 'operationNoteSujets';

export interface OperationNoteSujetState
  extends EntityState<OperationNoteSujetId> {
  loading: boolean;
  error: any;
  sujet: OperationNoteSujetId | any;
  sujets: OperationNoteSujetId[] | any[];
  sujetsWarning: any;
}

export const adapter: EntityAdapter<OperationNoteSujetId> =
  createEntityAdapter<OperationNoteSujetId>();

export const initialState: OperationNoteSujetState = adapter.getInitialState({
  loading: false,
  error: undefined,
  sujet: undefined,
  sujets: [],
  sujetsWarning: undefined,
});

export const reducer = createReducer(
  initialState,

  //LOADING INDICATOR
  on(OperationNoteSujetActions.loadingOperationNoteSujets, (state, action) => {
    return {
      ...state,
      loading: action.loading,
    };
  }),

  //LOAD ALL BY SUBTITLE
  on(OperationNoteSujetActions.loadOperationNoteSujets, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),

  on(
    OperationNoteSujetActions.loadOperationNoteSujetsSuccess,
    (state, action) => adapter.setAll(action.sujets, state)
  ),
  on(
    OperationNoteSujetActions.loadOperationNoteSujetsSuccess,
    (state, action) => {
      return {
        ...state,
        loading: false,
      };
    }
  ),
  on(
    OperationNoteSujetActions.loadOperationNoteSujetsFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
  ),

  //LOAD ALL BY NOTE
  on(
    OperationNoteSujetActions.loadOperationNoteSujetsByNoteSuccess,
    (state, action) => {
      return {
        ...state,
        sujets: action.sujets,
      };
    }
  ),
  on(
    OperationNoteSujetActions.loadOperationNoteSujetsByNoteFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
      };
    }
  ),

  //LOAD WARNING SUJETS
  on(
    OperationNoteSujetActions.loadOperationNoteSujetsWarning,
    (state, action) => {
      return {
        ...state,
        loading: false,
        sujetsWarning: undefined,
        error: undefined,
      };
    }
  ),
  on(
    OperationNoteSujetActions.loadOperationNoteSujetsWarningSuccess,
    (state, action) => {
      return {
        ...state,
        sujetsWarning: action.snap,
        loading: false,
        error: undefined,
      };
    }
  ),
  on(
    OperationNoteSujetActions.loadOperationNoteSujetsWarningFailure,
    (state, action) => {
      return {
        ...state,
        loading: false,
        sujetsWarning: undefined,
        error: action.error,
      };
    }
  ),

  //ADD ONE
  on(
    OperationNoteSujetActions.addOperationNoteSujetFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
      };
    }
  ),

  on(OperationNoteSujetActions.updateOperationNoteSujet, (state, action) =>
    adapter.updateOne(action.sujet, state)
  ),
  on(
    OperationNoteSujetActions.updateOperationNoteSujetFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
  ),

  on(OperationNoteSujetActions.deleteOperationNoteSujet, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(
    OperationNoteSujetActions.deleteOperationNoteSujetFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
      };
    }
  ),
  on(OperationNoteSujetActions.deleteOperationNoteSujets, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),

  on(OperationNoteSujetActions.clearOperationNoteSujets, (state) =>
    adapter.removeAll(state)
  ),

  on(OperationNoteSujetActions.deleteAllOperationNoteSujets, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(
    OperationNoteSujetActions.deleteAllOperationNoteSujetsFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
      };
    }
  )
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
