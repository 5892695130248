import { OperationQuery } from './../../store/operation.model';
import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { OperationState } from '../../store/operation.reducer';
import * as fromOperationAction from '../../store/operation.actions';
import * as fromOperationSelector from '../../store/operation.selectors';

@Component({
  selector: 'app-operation-filters',
  templateUrl: './operation-filters.component.html',
  styleUrls: ['./operation-filters.component.scss'],
})
export class OperationFiltersComponent implements OnInit {
  title: string = 'Projets';
  operations$: Observable<any> = of(null);
  filters$: Observable<any> = of(null);
  tags$: Observable<any> = of([]);
  filtersActivated: boolean = false;
  filtersOrders: any[] = [];
  cleaner: boolean = false;
  optionYear: any;
  optionService: any;
  optionFinanceur: any;
  optionCompleted: any;
  optionType: any;
  optionStatut: any;

  constructor(private operationStore: Store<OperationState>) {}

  ngOnInit(): void {
    this.loadFilters();
    this.getFilters();
  }

  loadFilters(): void {
    this.operationStore.dispatch(fromOperationAction.loadOperationFilters());
  }

  getFilters(): void {
    this.filters$ = this.operationStore.select(fromOperationSelector.filters);
  }

  loadOperations(): void {
    this.filtersActivated = false;
    this.cleaner = false;
    this.filtersOrders = [];
    this.operationStore.dispatch(fromOperationAction.loadOperations());
  }

  filtersByTitle(event: string): void {
    if (!event) {
      this.loadOperations();
    } else {
      const type = OperationQuery.title;
      this.operationStore.dispatch(
        fromOperationAction.queryOperations({ filter: { event, type } })
      );
      this.filtersActivated = true;
    }
  }

  onQuery(event: any, typeFilter: string, arrayContains?: any): any {
    if (!event) {
      this.loadOperations();
      return null;
    } else {
      const type = this.setFilterType(typeFilter);
      this.filtersActivated = true;
      this.cleaner = true;

      if (type !== 'favoris') {
        this.operationStore.dispatch(
          fromOperationAction.queryOperations({ filter: { event, type } })
        );
      } else {
        this.operationStore.dispatch(
          fromOperationAction.queryOperations({
            filter: { event: arrayContains, type },
          })
        );
      }
    }
  }

  setFilterType(type: string): string {
    switch (type) {
      case 'title':
        return OperationQuery.title;
        break;
      case 'financeur':
        return OperationQuery.financeurs;
        break;
      case 'service':
        return OperationQuery.service;
        break;
      case 'typz':
        return OperationQuery.type;
        break;
      case 'completed':
        return OperationQuery.complete;
        break;
      case 'status':
        return OperationQuery.status;
        break;
      case 'year':
        return OperationQuery.year;
        break;
      case 'letter':
        return OperationQuery.letter;
        break;
      default:
        return '';
        break;
    }
  }

  onClear(): void {
    this.loadOperations();
  }
}
