import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { fromUnixTime, format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { last, first, uniqWith, isEqual, sortBy } from 'lodash';
import {
  NzTableFilterFn,
  NzTableFilterList,
  NzTableSortFn,
  NzTableSortOrder,
} from 'ng-zorro-antd/table';

interface ColumnItem {
  name?: string;
  sortOrder?: NzTableSortOrder | null;
  sortFn?: NzTableSortFn<any> | null;
  listOfFilter?: NzTableFilterList;
  filterFn?: NzTableFilterFn<any> | null;
  filterMultiple?: boolean;
  sortDirections?: NzTableSortOrder[];
  filterTrigger?: boolean;
  customFilter?: boolean;
  width?: string;
}

@Component({
  selector: 'app-table-portal',
  templateUrl: './table-portal.component.html',
  styleUrls: ['./table-portal.component.scss'],
})
export class TablePortalComponent implements OnInit, AfterViewInit, OnChanges {
  styleBadge = {
    backgroundColor: '#ffab00',
    color: 'black',
    fontWeigth: 'bold',
  };

  selectionData: any[] = [];
  notSelectedList: any[] = [];
  selectionLoading = false;
  addList = false;
  expandSet = new Set<number>();

  tableHeader: any[] = [];
  total: number = 0;
  pageCache = [];
  pageIndexe = 1;
  nav = null;
  loading = true;
  filtered = false;
  lastData = null;
  isFirst = false;
  firstData = null;
  data: any = null;
  sort: string | null = null;
  value: string | null = null;
  listDate: any[] = [];
  listType: any[] = [];
  listTitle: any[] = [];
  listprojet: any[] = [];
  listStatut: any[] = [];
  listParent: any[] = [];
  searchDate: string = '';
  searchItem: string = '';
  listCivilite: any[] = [];
  listLastName: any[] = [];
  listFirstName: any[] = [];
  listFonction: any[] = [];
  listOfSearch: string[] = [];
  listOfData: any[] = [];
  listOfDisplayData = this.data ? [...this.data] : [];
  listOfColumns: any[] = [];

  @Input() source: any[] = [];
  @Input() clauses: boolean = false;
  @Input() new: boolean = false;
  @Input() type: string = '';
  @Input() expendable: boolean = false;
  @Input() updatable: boolean = false;
  @Input() extra: boolean = false;
  @Input() count: boolean = false;
  @Input() mode: string = 'small';
  @Output() select = new EventEmitter<any>();
  @Output() delete = new EventEmitter<any>();
  @Output() update = new EventEmitter<any>();
  @Output() newItem = new EventEmitter<any>();

  public tableHeight!: number;
  @ViewChild('tableContainer') private readonly _tableContainer!: ElementRef;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.tableHeight =
        (this._tableContainer.nativeElement as HTMLImageElement).clientHeight -
        10; // X depend of your page display
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const currrentSource = changes?.source?.currentValue
      ? changes.source.currentValue
      : [];
    const currrentType = changes?.type?.currentValue
      ? changes.type.currentValue
      : null;
    const currrentMode = changes?.mode?.currentValue
      ? changes.mode.currentValue
      : 'small';

    this.onDispatch(currrentSource, currrentType, currrentMode);
  }

  onDispatch(source: any[], type: string, mode: string): void {
    switch (type) {
      case 'operation':
        if (source) {
          this.loading = true;
          this.data = source;
          this.total = source.length;
          this.lastData = last(source);
          this.firstData = first(source);
          const type: any[] = [];
          const title: any[] = [];
          const date: any[] = [];
          const hours = [];
          const moduleObj: any[] = [];
          const statut: any[] = [];
          source.forEach((e) => {
            const titleItem = e.denomination;
            const objTitle = {
              text: titleItem,
              value: titleItem,
            };
            title.push(objTitle);
          });
          this.listType = uniqWith(type, isEqual);
          this.listDate = uniqWith(date, isEqual);
          this.listTitle = uniqWith(title, isEqual);
          this.listParent = uniqWith(moduleObj, isEqual);
          this.listStatut = uniqWith(statut, isEqual);
          this.loading = false;

          this.listOfColumns = [
            {
              name: 'Dénomination',
              sortOrder: null,
              sortFn: (a: any, b: any) =>
                a?.denomination
                  .toLowerCase()
                  .localeCompare(b?.denomination.toLowerCase()),

              filterMultiple: true,
              listOfFilter: this.listTitle,
              filterFn: (list: string[], item: any) =>
                list.some(
                  (denomination) =>
                    item?.denomination.indexOf(denomination) !== -1
                ),
              filterTrigger: true,
              customFilter: true,
              width: '30%',
            },
            {
              name: 'Description',
            },
            {
              name: 'Démarrage',
              sortOrder: 'descend',
              sortFn: (a: any, b: any) =>
                a?.description?.calendrier?.dateStart['seconds'] -
                b?.description?.calendrier?.dateStart['seconds'],
              sortDirections: ['descend', 'ascend'],
              filterTrigger: true,
              customFilter: true,
              width: '15%',
            },
            {
              name: 'Échéance',
              sortOrder: 'descend',
              sortFn: (a: any, b: any) =>
                a?.description?.calendrier?.dateEnd['seconds'] -
                b?.description?.calendrier?.dateEnd['seconds'],
              sortDirections: ['descend', 'ascend'],
              width: '15%',
            },
            {
              name: 'Financeurs',
            },
          ];
          this.listOfDisplayData = this.data;
          this.listOfData = source;
        } else {
          this.listOfDisplayData = [];
          this.listOfColumns = [
            {
              name: 'Dénomination',
              sortOrder: null,
              sortFn: (a: any, b: any) =>
                a.denomination
                  .toLowerCase()
                  .localeCompare(b.denomination.toLowerCase()),

              filterMultiple: true,
              listOfFilter: this.listTitle,
              filterFn: (list: string[], item: any) =>
                list.some(
                  (denomination) =>
                    item.denomination.indexOf(denomination) !== -1
                ),
              filterTrigger: true,
              customFilter: true,
              width: '30%',
            },
            {
              name: 'Description',
            },
            {
              name: 'Démarrage',
              sortOrder: 'descend',
              sortFn: (a: any, b: any) =>
                a.description.calendrier.dateStart['seconds'] -
                b.description.calendrier.dateStart['seconds'],
              sortDirections: ['descend', 'ascend'],
              filterTrigger: true,
              customFilter: true,
              width: '15%',
            },
            {
              name: 'Échéance',
              sortOrder: 'descend',
              sortFn: (a: any, b: any) =>
                a.description.calendrier.dateEnd['seconds'] -
                b.description.calendrier.dateEnd['seconds'],
              sortDirections: ['descend', 'ascend'],
              width: '15%',
            },
            {
              name: 'Financeurs',
            },
          ];
          this.listOfData = [];

          this.loading = false;
        }

        break;
      case 'module':
        if (source) {
          this.loading = true;
          this.data = source;

          this.total = source.length;
          this.lastData = last(source);
          this.firstData = first(source);
          const type: any[] = [];
          const title: any[] = [];
          const date: any[] = [];
          const hours: any[] = [];
          const moduleObj: any[] = [];
          const statut: any[] = [];
          source.forEach((e) => {
            const typeItem = e.type;
            const objType = {
              text: typeItem,
              value: typeItem,
            };
            type.push(objType);
            const titleItem = e.title;
            const objTitle = {
              text: titleItem,
              value: titleItem,
            };
            title.push(objTitle);
          });
          this.listType = uniqWith(type, isEqual);
          this.listDate = uniqWith(date, isEqual);
          this.listTitle = uniqWith(title, isEqual);
          this.listParent = uniqWith(moduleObj, isEqual);
          this.listStatut = uniqWith(statut, isEqual);
          this.loading = false;

          this.listOfColumns = [
            {
              name: 'Titre',
              sortOrder: null,
              sortFn: (a: any, b: any) =>
                a?.title.toLowerCase().localeCompare(b?.title.toLowerCase()),

              filterMultiple: true,
              listOfFilter: this.listTitle,
              filterFn: (list: string[], item: any) =>
                list.some((title) => item?.title.indexOf(title) !== -1),
              filterTrigger: true,
              customFilter: false,
              width: '45%',
            },
            {
              name: 'Type',
              sortOrder: null,
              sortFn: (a: any, b: any) =>
                a?.type.toLowerCase().localeCompare(b?.type.toLowerCase()),

              filterMultiple: true,
              listOfFilter: this.listType,
              filterFn: (list: string[], item: any) =>
                list.some((type) => item?.type.indexOf(type) !== -1),
              filterTrigger: false,
              customFilter: false,
              width: '25%',
            },
            {
              name: 'Démarrage',
              sortOrder: 'descend',
              sortFn: (a: any, b: any) =>
                a?.calendrier?.dateStart['seconds'] -
                b?.calendrier?.dateStart['seconds'],
              sortDirections: ['descend', 'ascend'],
              filterTrigger: true,
              customFilter: true,
              width: '15%',
            },
            {
              name: 'Échéance',
              sortOrder: 'descend',
              sortFn: (a: any, b: any) =>
                a?.calendrier?.dateEnd['seconds'] -
                b?.calendrier?.dateEnd['seconds'],
              sortDirections: ['descend', 'ascend'],
              width: '15%',
            },
          ];
          this.listOfDisplayData = this.data;
          this.listOfData = source;
        } else {
          this.listOfDisplayData = [];
          this.loading = false;
          this.listOfColumns = [
            {
              name: 'Titre',
              sortOrder: null,
              sortFn: (a: any, b: any) =>
                a?.title.toLowerCase().localeCompare(b?.title.toLowerCase()),

              filterMultiple: true,
              listOfFilter: this.listTitle,
              filterFn: (list: string[], item: any) =>
                list.some((title) => item?.title.indexOf(title) !== -1),
              filterTrigger: true,
              customFilter: false,
              width: '45%',
            },
            {
              name: 'Type',
              sortOrder: null,
              sortFn: (a: any, b: any) =>
                a?.type.toLowerCase().localeCompare(b?.type.toLowerCase()),

              filterMultiple: true,
              listOfFilter: this.listType,
              filterFn: (list: string[], item: any) =>
                list.some((type) => item?.type.indexOf(type) !== -1),
              filterTrigger: false,
              customFilter: false,
              width: '25%',
            },
            {
              name: 'Démarrage',
              sortOrder: 'descend',
              sortFn: (a: any, b: any) =>
                a?.calendrier?.dateStart['seconds'] -
                b?.calendrier?.dateStart['seconds'],
              sortDirections: ['descend', 'ascend'],
              filterTrigger: true,
              customFilter: true,
              width: '15%',
            },
            {
              name: 'Échéance',
              sortOrder: 'descend',
              sortFn: (a: any, b: any) =>
                a?.calendrier?.dateEnd['seconds'] -
                b?.calendrier?.dateEnd['seconds'],
              sortDirections: ['descend', 'ascend'],
              width: '15%',
            },
          ];
          this.listOfData = [];
        }

        break;
      case 'action':
        if (source) {
          this.loading = true;
          this.data = source;
          this.total = source.length;
          this.lastData = last(source);
          this.firstData = first(source);
          const type: any[] = [];
          const title: any[] = [];
          const date: any[] = [];
          const hours: any[] = [];
          const moduleObj: any[] = [];
          const statut: any[] = [];
          source.forEach((e) => {
            const typeItem = e.type;
            const objType = {
              text: typeItem,
              value: typeItem,
            };
            type.push(objType);
            const titleItem = e.title;
            const objTitle = {
              text: titleItem,
              value: titleItem,
            };
            title.push(objTitle);
            const dateItem = format(fromUnixTime(e.dateStart['seconds']), 'P', {
              locale: fr,
            });
            const objDate = {
              text: dateItem,
              value: dateItem,
            };
            date.push(objDate);
            const hourItem = format(fromUnixTime(e.dateStart['seconds']), 'p', {
              locale: fr,
            });
            const objHours = {
              text: hourItem,
              value: hourItem,
            };
            hours.push(objHours);
            const moduleItem = e.module.title;
            const objModule = {
              text: moduleItem,
              value: moduleItem,
            };
            moduleObj.push(objModule);
            /*                     const statutItem = e.extras.statut;
                        const objStatut = {
                          'text': statutItem,
                          'value': statutItem
                        }
                        statut.push(objStatut) */
          });
          this.listType = uniqWith(type, isEqual);
          this.listDate = uniqWith(date, isEqual);
          this.listTitle = uniqWith(title, isEqual);
          this.listParent = uniqWith(moduleObj, isEqual);
          this.listStatut = uniqWith(statut, isEqual);
          this.loading = false;
          this.listOfColumns = [
            {
              name: 'Titre',
              sortOrder: null,
              sortFn: (a: any, b: any) =>
                a?.title.toLowerCase().localeCompare(b?.title.toLowerCase()),

              filterMultiple: true,
              listOfFilter: this.listTitle,
              filterFn: (list: string[], item: any) =>
                list.some((title) => item?.title.indexOf(title) !== -1),
              filterTrigger: true,
              customFilter: false,
              width: '45%',
            },
            {
              name: 'Type',
              sortOrder: null,
              sortFn: (a: any, b: any) =>
                a.type.toLowerCase().localeCompare(b.type.toLowerCase()),

              filterMultiple: true,
              listOfFilter: this.listType,
              filterFn: (list: string[], item: any) =>
                list.some((type) => item.type.indexOf(type) !== -1),
              filterTrigger: false,
              customFilter: false,
              width: '25%',
            },
            {
              name: 'Démarrage',
              sortOrder: 'descend',
              sortFn: (a: any, b: any) =>
                a?.dateStart['seconds'] - b?.dateStart['seconds'],
              sortDirections: ['descend', 'ascend'],
              filterTrigger: true,
              customFilter: true,
              width: '15%',
            },
            {
              name: 'Échéance',
              sortOrder: 'descend',
              sortFn: (a: any, b: any) =>
                a?.dateEnd['seconds'] - b?.dateEnd['seconds'],
              sortDirections: ['descend', 'ascend'],
              width: '15%',
            },
          ];
          this.listOfDisplayData = this.data;
          this.listOfData = source;
        } else {
          this.listOfDisplayData = [];
          this.loading = false;
          this.listOfColumns = [
            {
              name: 'Titre',
              sortOrder: null,
              sortFn: (a: any, b: any) =>
                a.title.toLowerCase().localeCompare(b.title.toLowerCase()),

              filterMultiple: true,
              listOfFilter: this.listTitle,
              filterFn: (list: string[], item: any) =>
                list.some((title) => item.title.indexOf(title) !== -1),
              filterTrigger: true,
              customFilter: false,
              width: '45%',
            },
            {
              name: 'Type',
              sortOrder: null,
              sortFn: (a: any, b: any) =>
                a.type.toLowerCase().localeCompare(b.type.toLowerCase()),

              filterMultiple: true,
              listOfFilter: this.listType,
              filterFn: (list: string[], item: any) =>
                list.some((type) => item.type.indexOf(type) !== -1),
              filterTrigger: false,
              customFilter: false,
              width: '25%',
            },
            {
              name: 'Démarrage',
              sortOrder: 'descend',
              sortFn: (a: any, b: any) =>
                a.dateStart['seconds'] - b.dateStart['seconds'],
              sortDirections: ['descend', 'ascend'],
              filterTrigger: true,
              customFilter: true,
              width: '15%',
            },
            {
              name: 'Échéance',
              sortOrder: 'descend',
              sortFn: (a: any, b: any) =>
                a.dateEnd['seconds'] - b.dateEnd['seconds'],
              sortDirections: ['descend', 'ascend'],
              width: '15%',
            },
          ];
          this.listOfData = [];
        }

        break;
      case 'participant':
        if (source) {
          this.data = source;
          this.total = source.length;
          this.lastData = last(source);
          this.firstData = first(source);
          const civiliList: any[] = [];
          const lastNameList: any[] = [];
          const firstNameList: any[] = [];
          const fonctionList: any[] = [];

          source.forEach((e) => {
            const civilite = e.civilite;
            const objCivilite = {
              text: civilite,
              value: civilite,
            };
            civiliList.push(objCivilite);

            const lastName = e.lastName;
            const objLastName = {
              text: lastName,
              value: lastName,
            };
            lastNameList.push(objLastName);

            const firstName = e.firstName;
            const objFirstName = {
              text: firstName,
              value: firstName,
            };
            firstNameList.push(objFirstName);
          });
          this.listCivilite = uniqWith(civiliList, isEqual);
          this.listLastName = uniqWith(lastNameList, isEqual);
          this.listFirstName = uniqWith(firstNameList, isEqual);
          this.loading = false;
          this.listOfColumns = [
            {
              name: 'Civilité',
              sortOrder: null,
              sortFn: (a: any, b: any) =>
                a.civilite
                  .toLowerCase()
                  .localeCompare(b.civilite.toLowerCase()),
              filterTrigger: true,
              customFilter: false,
              width: '20%',
            },
            {
              name: 'Nom',
              sortOrder: null,
              sortFn: (a: any, b: any) =>
                a.lastName
                  .toLowerCase()
                  .localeCompare(b.lastName.toLowerCase()),

              filterMultiple: true,
              listOfFilter: this.listLastName,
              filterFn: (list: string[], item: any) =>
                list.some((lastName) => item.lastName.indexOf(lastName) !== -1),
              filterTrigger: true,
              customFilter: false,
              width: '30%',
            },
            {
              name: 'Prénom',
              sortOrder: null,
              sortFn: (a: any, b: any) =>
                a.firstName
                  .toLowerCase()
                  .localeCompare(b.firstName.toLowerCase()),
              filterTrigger: true,
              customFilter: false,
            },
            mode === 'middle' || mode === 'large'
              ? {
                  name: 'Fonction',
                  sortOrder: null,
                  sortFn: (a: any, b: any) =>
                    a.firstName
                      .toLowerCase()
                      .localeCompare(b.firstName.toLowerCase()),
                  filterTrigger: true,
                  customFilter: false,
                  width: '20%',
                }
              : {},
          ];
        } else {
          this.listCivilite = [];
          this.listLastName = [];
          this.listFirstName = [];
          this.listFonction = [];
          this.data = [];
          this.listOfDisplayData = [];
          this.loading = false;
          this.listOfColumns = [
            {
              name: 'Civilité',
              sortOrder: null,
              sortFn: (a: any, b: any) =>
                a.civilite
                  .toLowerCase()
                  .localeCompare(b.civilite.toLowerCase()),
              filterTrigger: true,
              customFilter: false,
              width: '20%',
            },
            {
              name: 'Nom',
              sortOrder: null,
              sortFn: (a: any, b: any) =>
                a.lastName
                  .toLowerCase()
                  .localeCompare(b.lastName.toLowerCase()),

              filterMultiple: true,
              listOfFilter: this.listLastName,
              filterFn: (list: string[], item: any) =>
                list.some((lastName) => item.lastName.indexOf(lastName) !== -1),
              filterTrigger: true,
              customFilter: false,
              width: '30%',
            },
            {
              name: 'Prénom',
              sortOrder: null,
              sortFn: (a: any, b: any) =>
                a.firstName
                  .toLowerCase()
                  .localeCompare(b.firstName.toLowerCase()),
              filterTrigger: true,
              customFilter: false,
            },
          ];
        }
        break;
      case 'entite':
        break;
      case 'contact':
        // if (source) {
        //   this.total = source.length;
        //   this.lastData = last(source);
        //   this.firstData = first(source);
        //   const civilite: any = [];
        //   const lastName: any[] = [];
        //   const firstName: any[] = [];
        //   const fonction: any[] = [];

        //   source.forEach((e) => {
        //     const objCivilite = {
        //       text: e.civilite,
        //       value: e.civilite,
        //     };
        //     civilite.push(objCivilite);

        //     const objLastName = {
        //       text: e.lastName,
        //       value: e.lastName,
        //     };
        //     lastName.push(objLastName);

        //     const objFirsName = {
        //       text: e.firstName,
        //       value: e.firstName,
        //     };
        //     firstName.push(objFirsName);

        //     const objFonction = {
        //       text: e.fonction,
        //       value: e.fonction,
        //     };
        //     fonction.push(objFonction);
        //   });
        //   this.listCivilite = uniqWith(civilite, isEqual);
        //   this.listLastName = uniqWith(lastName, isEqual);
        //   this.listFirstName = uniqWith(firstName, isEqual);
        //   this.listFonction = uniqWith(fonction, isEqual);

        //   this.listOfColumns = [
        //     extendable
        //       ? {
        //           name: '',
        //           width: '5%',
        //         }
        //       : null,
        //     {
        //       name: 'Civilité',
        //       sortOrder: null,
        //       sortFn: (a: any, b: any) =>
        //         a.civilite
        //           .toLowerCase()
        //           .localeCompare(b.civilite.toLowerCase()),
        //       filterTrigger: false,
        //       customFilter: false,
        //       width: '15%',
        //     },

        //     {
        //       name: 'Nom',
        //       sortOrder: null,
        //       sortFn: (a: any, b: any) =>
        //         a.lastName
        //           .toLowerCase()
        //           .localeCompare(b.lastName.toLowerCase()),
        //       filterTrigger: false,
        //       customFilter: false,
        //       width: '25%',
        //     },

        //     {
        //       name: 'Prénom',
        //       sortOrder: null,
        //       sortFn: (a: any, b: any) =>
        //         a.firstName
        //           .toLowerCase()
        //           .localeCompare(b.firstName.toLowerCase()),
        //       filterTrigger: false,
        //       customFilter: false,
        //       width: '25%',
        //     },
        //     mode === 'middle' || mode === 'large'
        //       ? {
        //           name: 'Fonction',
        //           sortOrder: null,
        //           sortFn: (a: any, b: any) =>
        //             a.firstName
        //               .toLowerCase()
        //               .localeCompare(b.firstName.toLowerCase()),
        //           filterTrigger: false,
        //           customFilter: false,
        //         }
        //       : null,
        //   ];
        // } else {
        //   this.listOfColumns = [
        //     extendable
        //       ? {
        //           name: '',
        //           width: '5%',
        //         }
        //       : null,

        //     {
        //       name: 'Civilité',
        //       sortOrder: null,
        //       sortFn: (a: any, b: any) =>
        //         a.civilite
        //           .toLowerCase()
        //           .localeCompare(b.civilite.toLowerCase()),
        //       filterTrigger: false,
        //       customFilter: false,
        //       width: '15%',
        //     },

        //     {
        //       name: 'Nom',
        //       sortOrder: null,
        //       sortFn: (a: any, b: any) =>
        //         a.lastName
        //           .toLowerCase()
        //           .localeCompare(b.lastName.toLowerCase()),
        //       filterTrigger: false,
        //       customFilter: false,
        //       width: '25%',
        //     },

        //     {
        //       name: 'Prénom',
        //       sortOrder: null,
        //       sortFn: (a: any, b: any) =>
        //         a.firstName
        //           .toLowerCase()
        //           .localeCompare(b.firstName.toLowerCase()),
        //       filterTrigger: false,
        //       customFilter: false,
        //       width: '25%',
        //     },
        //     mode === 'middle' || mode === 'large'
        //       ? {
        //           name: 'Fonction',
        //           sortOrder: null,
        //           sortFn: (a: any, b: any) =>
        //             a.firstName
        //               .toLowerCase()
        //               .localeCompare(b.firstName.toLowerCase()),
        //           filterTrigger: false,
        //           customFilter: false,
        //         }
        //       : null,
        //   ];

        //   this.listOfDisplayData = [];
        //   this.data = [];
        // }

        break;

      default:
        break;
    }
  }

  onSelect(item: any): void {
    this.select.emit(item);
  }

  onNewItem(event: any): void {}

  onUpdate(item: any, event: any): void {
    this.update.emit({ item, event });
  }
  onDelete(item: any): void {
    this.delete.emit(item);
  }

  sorted(sort: { key: string; value: string }): void {
    this.sort = sort.key;
    this.value = sort.value;
    this.filtered = true;
    this.search();
  }

  filter(listofSearchLastName: string[], searchItem: string): void {
    this.listOfSearch = listofSearchLastName;
    this.searchDate = searchItem;
    this.filtered = true;
    this.search();
  }

  search(): void {
    /** filter data **/
    const filterFunc = (item: any) =>
      (this.searchDate
        ? format(fromUnixTime(item.dateStart['seconds']), 'P', {
            locale: fr,
          }) !== this.searchDate
        : true) &&
      (this.listOfSearch.length
        ? this.listOfSearch.some(
            (search) => item.denomination === search || item.type === search
          )
        : true) &&
      (this.value
        ? format(fromUnixTime(item.dateStart['seconds']), 'P', {
            locale: fr,
          }).includes(this.value) ||
          item.type.toLowerCase().includes(this.value.toLowerCase()) ||
          item.object.toLowerCase().includes(this.value.toLowerCase())
        : true);
    const data = this.data.filter((item: any) => filterFunc(item));

    /** sort data **/
    if (this.sort && this.value) {
      const asc = sortBy(this.data, this.sort, 'asc');
      const desc = sortBy(this.data, this.sort, 'desc');
      this.listOfDisplayData = this.value === 'ascend' ? asc : desc;
    } else {
      this.listOfDisplayData = data;
    }
  }

  onExpandChange(item: any, event: any): any {}

  reset(): void {
    this.value = '';
    this.sort = '';
    this.filtered = false;
    this.searchItem = '';
    this.listOfSearch = [];
    this.search();
  }
}
