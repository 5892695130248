<app-card-actions *ngIf="operation && user$ | async as user">
  <ul nz-menu [nzMode]="'inline'" class="menuList">
    <li nz-menu-item (click)="onSelect('details')">
      <mat-icon>analytics</mat-icon>
      <span>Détails</span>
    </li>
    <li nz-submenu [nzTitle]="ressourceIcon">
      <ul>
        <li
          nz-menu-item
          (click)="onSelect('team')"
          nz-tooltip
          [nzTooltipMouseEnterDelay]="tooltipMouseEnterDelay"
          nzTooltipPlacement="right"
        >
          <mat-icon>groups</mat-icon>
          <nz-space nzDirection="vertical" [nzSize]="space"></nz-space>
          <span>Équipe</span>
        </li>

        <li
          nz-menu-item
          (click)="onSelect('suivi')"
          nz-tooltip
          [nzTooltipMouseEnterDelay]="tooltipMouseEnterDelay"
          nzTooltipPlacement="right"
        >
          <mat-icon>article</mat-icon>
          <nz-space nzDirection="vertical" [nzSize]="space"></nz-space>
          <span>Suivis</span>
        </li>
        <li
          nz-menu-item
          (click)="onSelect('documents')"
          nz-tooltip
          [nzTooltipMouseEnterDelay]="tooltipMouseEnterDelay"
          nzTooltipPlacement="right"
        >
          <mat-icon>insert_drive_file</mat-icon>
          <nz-space nzDirection="vertical" [nzSize]="space"></nz-space>
          <span>Documents</span>
        </li>
      </ul>
    </li>
    <li nz-submenu [nzTitle]="partiPrenanteIcon" nzOpen>
      <ul>
        <li nz-menu-item (click)="onSelect('entites')">
          <mat-icon>domain</mat-icon>
          <nz-space nzDirection="vertical" [nzSize]="space"></nz-space>
          <span *ngIf="operation?.type !== 'Clauses sociales'">Entités</span>
          <span *ngIf="operation?.type === 'Clauses sociales'"
            >Entreprises</span
          >
        </li>
        <li nz-menu-item (click)="onSelect('contacts')">
          <mat-icon>person</mat-icon>
          <nz-space nzDirection="vertical" [nzSize]="space"></nz-space>
          <span *ngIf="operation?.type !== 'Clauses sociales'"
            >Intervenants</span
          >
          <span *ngIf="operation?.type === 'Clauses sociales'">Contacts</span>
        </li>
        <li nz-menu-item (click)="onSelect('participants')">
          <mat-icon>supervisor_account</mat-icon>
          <nz-space nzDirection="vertical" [nzSize]="space"></nz-space>
          <span>Participants</span>
        </li>
        <li nz-menu-item (click)="onSelect('prescripteurs')">
          <mat-icon>real_estate_agent</mat-icon>
          <nz-space nzDirection="vertical" [nzSize]="space"></nz-space>
          <span>Prescripteurs</span>
        </li>
      </ul>
    </li>
    <li nz-submenu [nzTitle]="executionIcon" nzOpen>
      <ul>
        <!-- <li
          nz-menu-item
          (click)="onSelect('planification')"
          nz-tooltip
          [nzTooltipMouseEnterDelay]="tooltipMouseEnterDelay"
          nzTooltipPlacement="right"
          nzTooltipTitle="Planification permet le management des tâches à réaliser pour le projet."
        >
          <mat-icon>task</mat-icon>
          <nz-space nzDirection="vertical" [nzSize]="space"></nz-space>
          <span>Planification</span>
        </li> -->
        <li
          nz-menu-item
          (click)="onSelect('modules')"
          nz-tooltip
          [nzTooltipMouseEnterDelay]="tooltipMouseEnterDelay"
          nzTooltipPlacement="right"
        >
          <mat-icon outline>dynamic_feed</mat-icon>
          <nz-space nzDirection="vertical" [nzSize]="space"></nz-space>
          <span *ngIf="operation?.type !== 'Clauses sociales'">Modules</span>
          <span *ngIf="operation?.type === 'Clauses sociales'">Lots</span>
        </li>
        <li
          nz-menu-item
          (click)="onSelect('actions')"
          nz-tooltip
          [nzTooltipMouseEnterDelay]="tooltipMouseEnterDelay"
          nzTooltipPlacement="right"
        >
          <mat-icon>dashboard</mat-icon>
          <nz-space nzDirection="vertical" [nzSize]="space"></nz-space>
          <span *ngIf="operation?.type !== 'Clauses sociales'">Actions</span>
          <span *ngIf="operation?.type === 'Clauses sociales'"
            >Corps d'état</span
          >
        </li>
        <li
          nz-menu-item
          (click)="onSelect('besoins')"
          nz-tooltip
          [nzTooltipMouseEnterDelay]="tooltipMouseEnterDelay"
          nzTooltipPlacement="right"
        >
          <mat-icon>checklist_rtl</mat-icon>
          <nz-space nzDirection="vertical" [nzSize]="space"></nz-space>
          <span>Fiches de poste</span>
        </li>

        <li
          nz-menu-item
          (click)="onSelect('missions')"
          nz-tooltip
          [nzTooltipMouseEnterDelay]="tooltipMouseEnterDelay"
          nzTooltipPlacement="right"
        >
          <mat-icon>work_history</mat-icon>
          <nz-space nzDirection="vertical" [nzSize]="space"></nz-space>
          <span>Missions</span>
        </li>

        <!-- <li
          nz-menu-item
          (click)="onSelect('time')"
          nz-tooltip
          [nzTooltipMouseEnterDelay]="tooltipMouseEnterDelay"
          nzTooltipPlacement="right"
          nzTooltipTitle="Suivi temps permet le management du temps passé par membre de l'équipe projet."
        >
          <mat-icon>timer</mat-icon>
          <nz-space nzDirection="vertical" [nzSize]="space"></nz-space>
          <span>Suivi temps</span>
        </li> -->
      </ul>
    </li>
    <!-- <li nz-menu-divider></li>
    <li nz-submenu [nzTitle]="analyseIcon">
      <ul>
        <li
          nz-menu-item
          (click)="onSelect('gantt')"
          nz-tooltip
          [nzTooltipMouseEnterDelay]="tooltipMouseEnterDelay"
          nzTooltipPlacement="right"
          nzTooltipTitle="Gantt permet la visualisation graphique dans le temps des modules et actions du projet."
        >
          <mat-icon class="gantt">stacked_bar_chart</mat-icon>
          <nz-space nzDirection="vertical" [nzSize]="space"></nz-space>
          <span>Gantt</span>
        </li>
      </ul>
    </li> -->
    <!-- <li
      nz-menu-item
      (click)="onSelect('impacts')"
      nz-tooltip
      [nzTooltipMouseEnterDelay]="tooltipMouseEnterDelay"
      nzTooltipPlacement="right"
      nzTooltipTitle="Pilotage de l'impact du projet sur le parcours des participants."
    >
      <mat-icon>view_timeline</mat-icon>
      <nz-space nzDirection="vertical" [nzSize]="space"></nz-space>
      <span>Impacts</span>
    </li> -->
  </ul>
</app-card-actions>

<ng-template #analyseIcon>
  <mat-icon>show_chart</mat-icon>

  Analyse
</ng-template>

<ng-template #executionIcon>
  <mat-icon>account_tree</mat-icon>
  <span>Exécution</span>
</ng-template>

<ng-template #partiPrenanteIcon>
  <mat-icon>manage_accounts</mat-icon>
  <span>Parties prenantes</span>
</ng-template>

<ng-template #ressourceIcon>
  <mat-icon>dashboard_customize</mat-icon>
  <span>Ressources</span>
</ng-template>

<ng-template #impactIcon>
  <mat-icon>view_timeline</mat-icon>
  Impacts
</ng-template>
