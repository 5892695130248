import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  DepartementState,
  departementsFeatureKey,
  selectAll,
} from './departement.reducer';

export const selectDepartementState = createFeatureSelector<DepartementState>(
  departementsFeatureKey
);

export const departements = createSelector(selectDepartementState, selectAll);

export const list = createSelector(
  selectDepartementState,
  (state: DepartementState) => state.list
);

export const departement = createSelector(
  selectDepartementState,
  (state: DepartementState) => state.departement
);
export const departementServices = createSelector(
  selectDepartementState,
  (state: DepartementState) => state.departementServices
);

export const users = createSelector(
  selectDepartementState,
  (state: DepartementState) => state.users
);

export const loading = createSelector(
  selectDepartementState,
  (state: DepartementState) => state.loading
);

export const error = createSelector(
  selectDepartementState,
  (state: DepartementState) => state.error
);
