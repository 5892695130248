<div class="w-screen h-screen space-y-2 p-2" *ngIf="userState">
  <div class="w-full min-w-[60rem]">
    <app-navigation
      (collapsedChange)="onCollapseChange($event)"
    ></app-navigation>
  </div>
  <div class="w-screen h-screen bg-slate-50 rounded-xl p-2">
    <router-outlet #outlet="outlet"> </router-outlet>
  </div>
</div>
