import { ParticipantId } from './../../store/participant.model';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ParticipantState } from '../../store/participant.reducer';
import * as fromParticipantSelector from '../../store/participant.selectors';
import * as fromParticipantAction from '../../store/participant.actions';

import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-participant-detail',
  templateUrl: './participant-detail.component.html',
  styleUrls: ['./participant-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantDetailComponent
  implements OnInit, OnChanges, OnDestroy
{
  loading$: Observable<boolean> = of(false);
  default: string = '#bfbfbf';
  avatarBackgroundColor: string = '#1890ff';
  currentSelected: number = 0;
  suivis: any[] = [];
  subscribe = new Subject();
  statistiques$: Observable<any> = of(null);
  data$: Observable<any> = of(null);
  participant$: Observable<any> = of(null);

  @Output() update = new EventEmitter<boolean>(false);
  @Output() select = new EventEmitter<any>(false);
  @Output() delete = new EventEmitter<any>(false);

  constructor(
    private participantStore: Store<ParticipantState>,
    private activateRouter: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getRouteData();
    this.getParticipant();
    this.getParticipantLoading();
    this.getStatistiques();
    this.loadStatistiques();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }
  getRouteData(): void {
    this.data$ = this.activateRouter.data;
  }
  getParticipant(): void {
    this.data$.pipe(takeUntil(this.subscribe)).subscribe((data) => {
      const { participant } = data;
      this.participant$ = participant;
    });
  }

  getParticipantLoading(): void {
    this.loading$ = this.participantStore.select(
      fromParticipantSelector.loading
    );
  }

  getStatistiques(): void {
    this.statistiques$ = this.participantStore.select(
      fromParticipantSelector.statistiques
    );
  }

  loadStatistiques(): void {
    this.data$.pipe(takeUntil(this.subscribe)).subscribe((data: any) => {
      if (!data?.participant?.id) return;

      this.participantStore.dispatch(
        fromParticipantAction.loadParticipantStatistiques({
          participantId: data?.participant.id,
        })
      );
    });
  }

  selectedIndexChange(index: number): void {
    this.currentSelected = index;
  }

  onSelectDropdown(event: string, participant: ParticipantId): void {
    switch (event) {
      case 'update':
        this.onUpdate(participant);

        break;
      case 'exportPDF':
        this.onExport(participant);

        break;
      case 'delete':
        this.onDelete(participant.id);

        break;

      default:
        break;
    }
  }

  onUpdate(participant: ParticipantId): void {
    this.select.emit('update');
  }

  onExport(participant: ParticipantId): void {
    this.select.emit('export');
  }

  onDelete(id: string): void {
    if (!id) {
      return;
    } else {
      this.select.emit('delete');
      // this.participantStore.dispatch(
      //   fromParticipantAction.deleteParticipant({ id })
      // );
      // this.router.navigate(['participants']);
    }
  }

  onSortieImpact(type: string): void {
    this.select.emit(type);
  }
  onCancel(): void {
    return;
  }
}
