<nz-row
  *ngIf="organisation$ | async as organisation"
  nzAlign="middle"
  nzGutter="8"
  nzJustify="end"
  class="rowTitle"
>
  <nz-col nzXl="3" nzXXl="3" nzLg="0" nzXs="0" nzSm="0" nzMd="0"> </nz-col>

  <nz-col nzXl="17" nzXXl="17" nzLg="24" nzXs="24" nzSm="24" nzMd="24">
    <div class="titleHeader">
      <h4
        nz-typography
        (click)="$event.stopPropagation()"
        [routerLink]="['/organisation', organisation.id]"
      >
        {{ organisation?.etablissement?.denomination | carLimitation : 80 }}
      </h4>
      <app-underline></app-underline>
    </div>
  </nz-col>

  <nz-col nzXl="2" nzXXl="2" nzLg="0" nzXs="0" nzSm="0" nzMd="0">
    <button
      nz-button
      nzShape="circle"
      nzType="text"
      nz-tooltip
      [nzTooltipMouseEnterDelay]="0.5"
      nzTooltipTitle="Utilisateurs"
      nzTooltipPlacement="bottom"
      class="btn_action"
      (click)="$event.stopPropagation()"
      [routerLink]="['/organisation', organisation.id, 'users']"
    >
      <mat-icon class="iconLarge">groups</mat-icon>
    </button>
  </nz-col>

  <nz-col nzXl="2" nzXXl="2" nzLg="0" nzXs="0" nzSm="0" nzMd="0">
    <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
  </nz-col>
</nz-row>
