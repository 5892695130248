<ng-container *ngIf="user$ | async as user">
  <app-item-tabset
    [tabs]="[
      {
        name: 'Actions requises',
        index: 0,
        template: messageActions
      },
      {
        name: 'Notifications',
        index: 1,
        template: messageNotifications
      }
    ]"
  ></app-item-tabset>

  <ng-template #messageNotifications>
    <app-message-notifications></app-message-notifications>
  </ng-template>
  <ng-template #messageActions>
    <app-message-actions></app-message-actions>
  </ng-template>
</ng-container>
