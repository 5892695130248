import { OperationId } from './../components/operation/store/operation.model';
import { Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  doc,
  docSnapshots,
  collectionChanges,
  getDoc,
  addDoc,
  setDoc,
  deleteDoc,
  serverTimestamp,
  DocumentData,
  Timestamp,
  query,
  where,
  arrayUnion,
  arrayRemove,
  collectionSnapshots,
  CollectionReference,
  DocumentReference,
  getDocs,
  documentId,
} from '@angular/fire/firestore';
import { Observable, of, from, EMPTY } from 'rxjs';
import { switchMap, mergeMap, map, catchError } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Functions, httpsCallable } from '@angular/fire/functions';

import { UserState } from '../components/user/store/user.reducer';
import * as fromUserSelector from '../components/user/store/user.selectors';
import { OperationTaskId } from '../components/operation-task/store/operation-task.model';
import {
  OperationNote,
  OperationNoteId,
} from '../components/operation-note/store/operation-note.model';
import { Apollo, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'any',
})
export class OperationNoteService {
  CREATE_ONE_OPERATION_NOTE = gql`
    mutation CreateOperationNote($note: NoteInput!) {
      createOperationNote(note: $note)
    }
  `;
  UPDATE_ONE_OPERATION_NOTE = gql`
    mutation UpdateOperationNote($note: NoteUpdateInput!) {
      updateOperationNote(note: $note)
    }
  `;
  COPY_ONE_OPERATION_NOTE = gql`
    mutation CopyOperationNote($operationId: String!, $noteId: String!) {
      copyOperationNote(operationId: $operationId, noteId: $noteId)
    }
  `;

  DELETE_ALL_OPERATION_NOTES = gql`
    mutation DeleteAllOperationNotes($operationId: String!) {
      deleteAllOperationNotes(operationId: $operationId)
    }
  `;

  DELETE_ONE_OPERATION_NOTE = gql`
    mutation DeleteOperationNote($operationId: String!, $noteId: String!) {
      deleteOperationNote(operationId: $operationId, noteId: $noteId)
    }
  `;

  constructor(
    private db: Firestore,
    private userStore: Store<UserState>,
    private apollo: Apollo
  ) {}

  //GET
  getAll(operationId: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !operationId) of([]);
          const dbCollection = collection(
            this.db,
            `organisations/${user.organisation.id}/operations/${operationId}/notes`
          );

          const changes = collectionSnapshots(dbCollection).pipe(
            map((changes) => {
              return changes.map((snap) => {
                const data = snap.data();
                const id = snap.id;
                return { id, ...data };
              });
            })
          );

          return changes;
        })
      );
    } catch (error) {
      return of(error);
    }
  }
  getOne(operationId: string, id: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !operationId || !id) {
            return EMPTY;
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/operations/${operationId}/notes/`
            );

            const documentRef = doc(dbCollection, id);
            return docSnapshots(documentRef).pipe(
              map((snap) => {
                const data = snap.data();
                const id = snap.id;
                return { id, ...data };
              })
            );
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }
  getUserNotes(operationId: string, id: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !operationId || !id) {
            return EMPTY;
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/operations/${operationId}/notes`
            );

            const collectionQuery = query(
              dbCollection,
              where('auteur.id', '==', user.id)
            );

            return from(collectionSnapshots(collectionQuery)).pipe(
              map((changes) => {
                return changes.map((snap) => {
                  const data = snap.data();
                  const id = snap.id;
                  return { id, ...data };
                });
              })
            );
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  //ADD
  addOne(note: OperationNote): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !note) EMPTY;

          const dbCollection = collection(
            this.db,
            `organisations/${user.organisation.id}/operations/${note.operation.id}/notes`
          );

          const document: OperationNote = {
            ...note,
            auteur: user,
            acces: {
              [`${user.id}`]: true,
            },
            organisation: user.organisation,
            userUpdate: user,
            dateUpdate: Timestamp.now(),
          };

          return from(
            addDoc(dbCollection, document)
              .then((docRef) => {
                if (!docRef.id) {
                  const succss: string = `Compte rendu n\'a pas été ajouté ! Veuillez rééssayer`;
                  return of(succss);
                }

                const succss: string = `Compte rendu ajouté`;
                return of(succss);
              })
              .catch((error) => of(error.message))
          ).pipe(mergeMap((res) => res));
        })
      );
    } catch (error) {
      return of(error);
    }
  }
  createOne(note: any): Observable<any> {
    try {
      if (!note) return EMPTY;

      return this.apollo.mutate({
        mutation: this.CREATE_ONE_OPERATION_NOTE,
        variables: {
          note: note,
        },
      });
    } catch (error) {
      return of(error);
    }
  }

  updateOne(
    note: Partial<OperationNoteId | any>,
    type?: string
  ): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !note) EMPTY;

          const dbDocument = doc(
            this.db,
            `organisations/${user.organisation.id}/operations/${note.operation.id}/notes/${note.id}`
          );

          return from(
            setDoc(
              dbDocument,
              { ...note, userUpdate: user, dateUpdate: serverTimestamp() },
              { merge: true }
            )
              .then(() => of(`Compte rendu modifié`))
              .catch((error) => of(error))
          ).pipe(mergeMap((res) => res));
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  copyOne(operationId: string, noteId: string): Observable<any> {
    try {
      if (!operationId || !noteId) return EMPTY;

      return this.apollo.mutate({
        mutation: this.COPY_ONE_OPERATION_NOTE,
        variables: {
          operationId: operationId,
          noteId: noteId,
        },
      });
    } catch (error) {
      return of(error);
    }
  }

  deleteOne(operationId: string, id: string): Observable<any> {
    try {
      if (!operationId || !id) EMPTY;

      return this.apollo.mutate({
        mutation: this.DELETE_ONE_OPERATION_NOTE,
        variables: {
          operationId: operationId,
          noteId: id,
        },
      });
    } catch (error) {
      return of(error);
    }
  }

  deleteAll(operationId: string): Observable<any> {
    try {
      if (!operationId) EMPTY;

      return this.apollo.mutate({
        mutation: this.DELETE_ALL_OPERATION_NOTES,
        variables: {
          operationId: operationId,
        },
      });
    } catch (error) {
      return of(error);
    }
  }
}
