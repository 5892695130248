import { Pipe, PipeTransform } from '@angular/core';
import memo from 'memo-decorator';

@Pipe({
  name: 'phone',
})
export class PhonePipe implements PipeTransform {
  @memo()
  transform(phone: number | string): string {
    if (phone === null) {
      return '';
    } else {
      const num1 = phone.toString().substring(0, 2);
      const num2 = phone.toString().substring(2, 4);
      const num3 = phone.toString().substring(4, 6);
      const num4 = phone.toString().substring(6, 8);
      const num5 = phone.toString().substring(8, 10);
      const num6 = phone.toString().substring(10, 12);
      const tel = `${num1} ${num2} ${num3} ${num4} ${num5} ${num6}`;

      return tel;
    }
  }
}
