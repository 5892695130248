import { OperationId } from './../../store/operation.model';
import { AfterViewInit, Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { OperationState } from '../../store/operation.reducer';
import * as fromOperationAction from '../../store/operation.actions';
import * as fromOperationSelector from '../../store/operation.selectors';
import { Observable, filter, map, of } from 'rxjs';
import { SideNavigationState } from 'src/app/components/side-navigation/store/side-navigation.reducer';
import * as fromSideNavigationAction from 'src/app/components/side-navigation/store/side-navigation.actions';
import { SideNavigation } from 'src/app/components/side-navigation/store/side-navigation.model';
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router';
import { ButtonMenu } from 'src/app/contents/components/buttons/store/button.model';
@Component({
  selector: 'app-operation-item-header',
  templateUrl: './operation-item-header.component.html',
  styleUrls: ['./operation-item-header.component.scss'],
})
export class OperationItemHeaderComponent implements OnInit, AfterViewInit {
  operationTitle: string = 'Projets';
  @Input() btnMenus: ButtonMenu[] = [
    {
      icon: '',
      title: 'Parties prenantes',
      isDivider: true,
    },

    {
      icon: '',
      title: 'Participants',
    },
    {
      icon: '',
      title: 'Entreprises',
    },
    {
      icon: '',
      title: 'Mise en oeuvre',
      isDivider: true,
    },

    {
      icon: '',
      title: 'Marchés',
    },
    {
      icon: '',
      title: 'Lots',
    },
    {
      icon: '',
      title: 'Missions',
    },
    {
      icon: '',
      title: 'Fiches de poste',
    },
    {
      icon: '',
      title: 'Pilotage',
      isDivider: true,
    },
    {
      icon: ``,
      title: 'Notes',
    },
    {
      icon: '',
      title: 'Documents',
    },
    {
      icon: '',
      title: 'Tâches',
    },
  ];

  operation$: Observable<OperationId | any> = of(null);
  childtitle$: Observable<any> = this.operationStore.select(
    fromOperationSelector.activeroutetitle
  );
  data: Data;
  constructor(
    private operationStore: Store<OperationState>,
    private sideNavigationStore: Store<SideNavigationState>,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getOperation();
  }
  ngAfterViewInit(): void {}

  onSelectAction(type: string, operation: OperationId): void {
    if (!type || !operation) return;

    switch (type) {
      case 'Notes':
        this.router.navigate(['/projet', operation.id, 'suivis']);

        break;
      case 'Documents':
        this.router.navigate(['/projet', operation.id, 'documents']);

        break;
      case 'Tâches':
        this.router.navigate(['/projet', operation.id, 'tasks']);

        break;
      case 'Participants':
        this.router.navigate(['/projet', operation.id, 'participants']);

        break;
      case 'Entreprises':
        this.router.navigate(['/projet', operation.id, 'entites']);

        break;
      case 'Marchés':
        this.router.navigate(['/projet', operation.id, 'modules']);

        break;
      case 'Lots':
        this.router.navigate(['/projet', operation.id, 'actions']);

        break;
      case 'Contrats':
        this.router.navigate(['/projet', operation.id, 'missions']);

        break;

      case 'Fiches de poste':
        this.router.navigate(['/projet', operation.id, 'fichesDePoste']);

        break;

      default:
        break;
    }
  }

  onDetailView(operation: OperationId): void {
    const navigation: SideNavigation = {
      type: 'side',
      name: 'operation_details',
      item: operation,
    };

    this.sideNavigationStore.dispatch(
      fromSideNavigationAction.loadSideNavigation({ navigation })
    );
  }

  onDashboardView(operation: OperationId): void {
    const navigation: SideNavigation = {
      type: 'side',
      name: 'operation_dashboard',
      item: operation,
    };

    this.sideNavigationStore.dispatch(
      fromSideNavigationAction.loadSideNavigation({ navigation })
    );
  }
  onDownloadView(operation: OperationId): void {
    const navigation: SideNavigation = {
      type: 'side',
      name: 'operation_download',
      item: operation,
    };

    this.sideNavigationStore.dispatch(
      fromSideNavigationAction.loadSideNavigation({ navigation })
    );
  }
  onShareView(operation: OperationId): void {
    const navigation: SideNavigation = {
      type: 'side',
      name: 'operation_share',
      item: operation,
    };

    this.sideNavigationStore.dispatch(
      fromSideNavigationAction.loadSideNavigation({ navigation })
    );
  }

  getOperation(): void {
    this.operation$ = this.operationStore.select(
      fromOperationSelector.operation
    );
  }

  onDelete(operation: OperationId): void {
    if (!operation || !operation.id) return;
    const { id } = operation;
    this.operationStore.dispatch(fromOperationAction.deleteOperation({ id }));
  }

  onCancel(): void {}
}
