<nz-page-header
  class="page-header"
  [nzTitle]="title$ | async"
  [nzSubtitle]="headerExtra"
  *ngIf="entites$ | async | size"
>
  <nz-page-header-extra>
    <button
      *ngIf="contact?.entitesIds | size"
      nz-button
      nzType="text"
      (click)="$event.stopPropagation(); onNewEntite(contact)"
      nz-popover
      class="btnNew"
    >
      <h5 nz-typography>Nouveau</h5>
    </button>
  </nz-page-header-extra>
  <nz-page-header-content> </nz-page-header-content>
</nz-page-header>
<nz-divider *ngIf="entites$ | async | size"></nz-divider>

<ng-container>
  <app-layout-list
    *ngIf="entites$ | async | size; else noResults"
    [card]="card"
    [source]="entites$ | async"
  ></app-layout-list>
</ng-container>

<ng-template #card let-item let-position="idx">
  <nz-card
    nzHoverable
    [nzTitle]="contactEntiteTitle"
    class="entiteContactCard"
    [routerLink]="['/entites', item.id]"
  >
    <ng-template #contactEntiteTitle>
      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col nzSpan="4">
          <app-header-title [icon]="'domaine'"></app-header-title>
        </nz-col>
        <nz-col nzSpan="2"></nz-col>
        <nz-col nzSpan="18">
          {{ item?.etablissement?.denomination | carLimitation: 15 }}
        </nz-col>
      </nz-row>
    </ng-template>

    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="24">
        <strong>
          {{
            item?.etablissement?.activite_principale_libelle?.intitule_naf
              | carLimitation: 25
          }}
        </strong>
      </nz-col>
    </nz-row>

    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="24">
        <p>{{ item?.metiersGrandDomaine?.libelle | carLimitation: 30 }}</p>
        <p nz-typography>
          {{ item?.metiersDomaine?.libelle | carLimitation: 30 }}
        </p>
      </nz-col>
    </nz-row>

    <nz-space nzDirection="horizontal" [nzSize]="20"></nz-space>
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="4" class="iconCol">
        <mat-icon class="iconDescriptionLocation">numbers</mat-icon>
      </nz-col>
      <nz-col nzSpan="20">
        <nz-tag class="tagDescription">
          {{ item?.etablissement?.etablissement_siege?.siret }}
        </nz-tag>
      </nz-col>
    </nz-row>
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="4" class="iconCol">
        <mat-icon class="iconDescriptionLocation">location_on</mat-icon>
      </nz-col>
      <nz-col nzSpan="20">
        <nz-tag class="tagDescription">
          <small>{{
            item?.etablissement?.etablissement_siege?.geo_adresse
              | carLimitation: 35
          }}</small>
        </nz-tag>
      </nz-col>
    </nz-row>
    <nz-divider></nz-divider>

    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="24">
        <button
          nz-button
          nzBlock
          nz-popconfirm
          [nzPopconfirmTitle]="
            'Voulez-vous supprimer ' + item?.etablissement?.denomination + ' ?'
          "
          (click)="$event.stopPropagation()"
          nzPopconfirmPlacement="bottom"
          (nzOnConfirm)="onDelete(contact, item)"
          (nzOnCancel)="onCancel()"
          class="btnCardDelete"
        >
          <h5 nz-typography>Supprimer</h5>
        </button>
      </nz-col>
    </nz-row>
  </nz-card>

  <ng-template #btnMoreEntiteContact>
    <button
      nz-button
      nzShape="circle"
      nz-dropdown
      [nzDropdownMenu]="contactEntiteMenu"
      nzPlacement="bottomRight"
      nzTrigger="click"
    >
      <i nz-icon nzType="more"></i>
    </button>
  </ng-template>

  <nz-dropdown-menu #contactEntiteMenu="nzDropdownMenu">
    <ul nz-menu>
      <li nz-menu-divider></li>
      <li
        nz-menu-item
        nzDanger
        nz-popconfirm
        [nzPopconfirmTitle]="
          'Voulez-vous supprimer ' + item?.etablissement?.denomination + ' ?'
        "
        nzPopconfirmPlacement="bottom"
        (nzOnConfirm)="onDelete(contact, item)"
        (nzOnCancel)="onCancel()"
      >
        <i nz-icon nzType="delete"></i>
        <span class="item-dropdown">Supprimer</span>
      </li>
    </ul>
  </nz-dropdown-menu>
</ng-template>

<ng-template #headerExtra>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="24">
      <nz-badge [nzCount]="entites$ | async | size" [nzOverflowCount]="99">
      </nz-badge>
    </nz-col>
  </nz-row>
</ng-template>

<ng-template #noResults>
  <app-no-result
    [icon]="'domaine'"
    [notitle]="'Aucune entité'"
    [addtitle]="'Nouveau'"
    (click)="$event.stopPropagation(); onNewEntite(contact)"
  ></app-no-result>
</ng-template>

<ng-template #entiteModalTitle>
  <app-header-title
    [icon]="'domaine'"
    [title]="title$ | async"
  ></app-header-title>
</ng-template>
