import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MetierState, metiersFeatureKey, selectAll } from './metier.reducer';

export const selectMetierState =
  createFeatureSelector<MetierState>(metiersFeatureKey);

export const metiers = createSelector(selectMetierState, selectAll);

export const loading = createSelector(
  selectMetierState,
  (state: MetierState) => state.loading
);

export const grandDomaines = createSelector(
  selectMetierState,
  (state: MetierState) => state.grandDomaine
);

export const themes = createSelector(
  selectMetierState,
  (state: MetierState) => state.themes
);

export const domainesProfessionnels = createSelector(
  selectMetierState,
  (state: MetierState) => state.domainesProfessionnels
);

export const domainesProfessionnelsByGrandDomaine = createSelector(
  selectMetierState,
  (state: MetierState) => state.domainesProfessionnelsByGrandDomaine
);

export const selectedMetiers = createSelector(
  selectMetierState,
  (state: MetierState) => state.selectedMetiers
);

export const appelations = createSelector(
  selectMetierState,
  (state: MetierState) => state.appelations
);

export const appelation = createSelector(
  selectMetierState,
  (state: MetierState) => state.appelation
);

export const metier = createSelector(
  selectMetierState,
  (state: MetierState) => state.metier
);

export const qualifications = createSelector(
  selectMetierState,
  (state: MetierState) => state.qualifications
);

export const fap = createSelector(
  selectMetierState,
  (state: MetierState) => state.fap
);

export const error = createSelector(
  selectMetierState,
  (state: MetierState) => state.error
);
