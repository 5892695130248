import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { OperationCandidatureState } from '../../store/operation-fiche-de-poste-candidature.reducer';
import { Store } from '@ngrx/store';
import * as fromOperationFicheCandidatureSelector from '../../store/operation-fiche-de-poste-candidature.selectors';
import * as fromOperationFicheCandidatureAction from '../../store/operation-fiche-de-poste-candidature.actions';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Update } from '@ngrx/entity';
import { omit } from 'lodash';
import { OperationFicheCandidatDocumentState } from 'src/app/components/operation-fiche-candidat-documents/store/operation-fiche-candidat-document.reducer';
import * as fromOperationFicheCandidatDocumentAction from 'src/app/components/operation-fiche-candidat-documents/store/operation-fiche-candidat-document.actions';
import * as fromOperationFicheCandidatDocumentSelector from 'src/app/components/operation-fiche-candidat-documents/store/operation-fiche-candidat-document.selectors';
import { OperationFicheCandidatNoteState } from 'src/app/components/operation-fiche-candidat-notes/store/operation-fiche-candidat-note.reducer';
import * as fromOperationFicheCandidatNoteAction from 'src/app/components/operation-fiche-candidat-notes/store/operation-fiche-candidat-note.actions';
import * as fromOperationFicheCandidatNoteSelector from 'src/app/components/operation-fiche-candidat-notes/store/operation-fiche-candidat-note.selectors';

@Component({
  selector: 'app-operation-fiche-de-poste-candidature',
  templateUrl: './operation-fiche-de-poste-candidature.component.html',
  styleUrls: ['./operation-fiche-de-poste-candidature.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationFicheDePosteCandidatureComponent implements OnInit {
  candidat$: Observable<any> = this.operationFicheCandidatStore.select(
    fromOperationFicheCandidatureSelector.candidat
  );
  loading$: Observable<boolean> = this.operationFicheCandidatStore.select(
    fromOperationFicheCandidatureSelector.loading
  );
  description: string = 'Aucun candidat sélectionnée';
  notitle: string = 'Veuillez sélectionner un candidat';

  @Input() fiche: any = null;
  @Input() currentView$ = new BehaviorSubject<string>('display');
  @Output() changesView = new EventEmitter();

  constructor(
    private operationFicheCandidatStore: Store<OperationCandidatureState>,
    private operationFicheCandidatDocumentStore: Store<OperationFicheCandidatDocumentState>,
    private operationFicheCandidatNoteStore: Store<OperationFicheCandidatNoteState>
  ) {}

  ngOnInit(): void {}

  /**
   * Indicateur de la tab sélectionnée
   * @param index - Current tab
   * @param fiche - Fiche de poste
   * @param candidat - Candidat
   * @returns
   */
  onTabChanges(index: number, fiche: any, candidat: any): void {
    if (!fiche || !candidat) return;

    const operationId: string = fiche.mission.operation.id;
    const ficheId: string = fiche.id;
    const candidatId: string = candidat.id;

    switch (index) {
      case 0:
        this.loadNotes(operationId, ficheId, candidatId);
        break;
      case 1:
        this.loadDocuments(operationId, ficheId, candidatId);

        break;
      default:
        break;
    }
  }

  /**
   * Chargement des notes du candidat
   * @param operationId - Identifiant de l'opération
   * @param ficheId - Identifiant de la fiche de poste
   * @param candidatId - Identifiant du candidats
   */
  loadNotes(operationId: string, ficheId: string, candidatId: string): void {
    this.operationFicheCandidatNoteStore.dispatch(
      fromOperationFicheCandidatNoteAction.loadOperationFicheCandidatNotes({
        operationId,
        ficheId,
        candidatId,
      })
    );
  }

  /**
   * Chargement des documents du candidat
   * @param operationId - Identifiant de l'opération
   * @param ficheId - Identifiant de la fiche de poste
   * @param candidatId - Identifiant du candidat
   */
  loadDocuments(
    operationId: string,
    ficheId: string,
    candidatId: string
  ): void {
    this.operationFicheCandidatDocumentStore.dispatch(
      fromOperationFicheCandidatDocumentAction.loadOperationFicheCandidatDocuments(
        { operationId, ficheId, candidatId }
      )
    );
  }

  /**
   * Modification le candidat
   * @param {any} candidat - Candidature actuelle
   * @param {any} fiche - Fiche de poste concernée
   * @returns
   */
  onUpdate(candidat: Update<any>, fiche: any): void {
    if (!candidat || !fiche) return;

    const operation = fiche.mission.operation;

    this.operationFicheCandidatStore.dispatch(
      fromOperationFicheCandidatureAction.updateOperationCandidature({
        operationId: operation.id,
        ficheId: fiche.id,
        candidat: candidat,
      })
    );

    this.changesView.emit('display');
  }

  /**
   * Ecoute des changements de fenêtre
   * @param event - nom de la vue sélectionnée
   */
  onViewChanges(event: string): void {
    this.changesView.emit(event);

    this.operationFicheCandidatNoteStore.dispatch(
      fromOperationFicheCandidatNoteAction.setViewOperationFicheCandidatNotes({
        view: event,
      })
    );
    this.operationFicheCandidatDocumentStore.dispatch(
      fromOperationFicheCandidatDocumentAction.setViewOperationFicheCandidatDocuments(
        { view: event }
      )
    );
  }
}
