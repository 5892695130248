import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModuleImport } from '../../store/module.model';

@Component({
  selector: 'app-module-import-excel',
  templateUrl: './module-import-excel.component.html',
  styleUrls: ['./module-import-excel.component.scss'],
})
export class ModuleImportExcelComponent implements OnInit {
  @Output() add = new EventEmitter();
  @Output() cancel = new EventEmitter();

  source: ModuleImport[] | any = [];
  @Input() scroll: any = { x: '100%', y: '450px' };

  constructor() {}

  ngOnInit(): void {}

  onImport(items: any[]): void {
    if (!items?.length) return;
    const converItems: ModuleImport[] = items.map((item) => {
      const title = item['Lots'];
      const entiteName = item['Entreprises'];
      const dateStart = item['Date démarrage'];
      const dateEnd = item['Date livraison'];
      const objectifTheorique = item['Heures théoriques'];
      const etpTheorique = item['ETP théorique'];
      const montant = item['Montants'];
      const objectifContrat = item['Heures contractuelles'];

      const convertItem: ModuleImport = {
        title,
        entiteName,
        dateStart: dateStart ? new Date(dateStart * 1000) : null,
        dateEnd: dateEnd ? new Date(dateEnd * 1000) : null,
        objectifTheorique,
        objectifContrat,
        montant,
        etpTheorique,
      };
      return convertItem;
    });

    this.source = converItems;
  }

  onEvaluateObjectif(): void {}
  onEvaluateEtp(): void {}
  onFindEntites(): void {}
  onAdd(): void {}
  onDelete(): void {}
  onCancel(): void {
    this.cancel.emit(true);
  }
}
