<div *ngIf="searchBar" class="w-full flex items-end justify-between">
  <form [formGroup]="searchForm" class="w-full flex items-center justify-start">
    <input
      formControlName="search"
      type="text"
      [placeholder]="placeholder"
      class="inputForm"
    />
    <div class="hidden">
      <button
        type="button"
        (click)="clearSearchForm()"
        *ngIf="searchForm.value.search.dirty"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-7 h-7 hover:shadow-lg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      <button type="button" (click)="$event.stopPropagation(); onSearch()">
        <mat-icon>search</mat-icon>
      </button>
    </div>
  </form>
</div>
