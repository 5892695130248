<ng-container *ngIf="entite">
  <div class="w-full flex flex-col rounded-lg p-12 space-y-12">
    <div class="w-full flex items-center justify-between">
      <div class="w-20"></div>

      <div class="max-w-30">
        <button
          class="btn-default"
          (click)="$event.stopPropagation(); onClean()"
        >
          Modifier l'entreprise
        </button>
      </div>
    </div>

    <div class="w-full flex items-start">
      <nz-card
        class="w-1/2 space-y-6"
        nzSize="small"
        [nzTitle]="etablissementTitleHeaderTpl"
        nzBorderless
      >
        <div class="w-full flex items-start">
          <span class="text-base">Siret : {{ entite?.siret }}</span>
        </div>
        <div
          class="w-full flex items-start"
          *ngIf="entite?.etablissementSiege === 'O'"
        >
          <span class="text-base">{{
            entite?.etablissementSiege === "O" ? "Siège" : ""
          }}</span>
        </div>
        <div class="w-full flex items-start">
          <span class="text-base">{{
            entite.uniteLegale?.categorie_juridique_libelle?.libelle
          }}</span>
        </div>
        <div class="w-full flex items-start">
          <span class="text-sm">
            {{
              entite.uniteLegale?.activite_principale_libelle?.intitule_naf
            }}</span
          >
        </div>
        <div class="w-full flex items-start">
          <span class="text-sm">{{
            entite?.adresseEtablissement?.fullAdresse
          }}</span>
        </div>
      </nz-card>
      <ng-container *ngIf="isConventions">
        <nz-card
          class="w-1/2"
          nzSize="small"
          [nzTitle]="etablissementConventionHeaderTpl"
          nzBorderless
          *ngIf="
            entite?.conventionsCollectives?.conventions | size;
            else etablissementNoConvention
          "
        >
          <nz-list [nzBordered]="false" nzSize="small">
            <nz-list-item
              *ngFor="
                let convention of entite?.conventionsCollectives?.conventions;
                let i = index
              "
            >
              <div class="w-full grid grid-cols-1 space-y-4 divide-y">
                <p class="text-sm font-bold tracking-wider">
                  {{ i + 1 }} - {{ convention?.shortTitle }}
                </p>
                <span class="text-base">{{ convention?.title }}</span>
                <a [innerHTML]="convention?.url"></a>
              </div>
            </nz-list-item>
          </nz-list>
        </nz-card>
      </ng-container>
    </div>
  </div>

  <nz-space nzDirection="vertical" nzSize="small"></nz-space>
  <nz-row nzGutter="8" nzAlign="middle" nzJustify="end">
    <nz-col [nzSpan]="isValidation ? 20 : 24"></nz-col>
    <nz-col [nzSpan]="isValidation ? 4 : 0">
      <button
        nz-button
        nzBlock
        (click)="$event.stopPropagation(); onValidate(entite)"
      >
        <mat-icon>{{ btnTitle }}</mat-icon>
      </button>
    </nz-col>
  </nz-row>

  <ng-template #ficheDePosteEntiteCard>
    <div class="pr-6">
      <button class="btn-default" (click)="$event.stopPropagation(); onClean()">
        Retirer l'entreprise
      </button>
    </div>
  </ng-template>

  <ng-template #etablissementTitleHeaderTpl>
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="2">
        <svg
          class="iconSvg"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21"
          />
        </svg>
      </nz-col>
      <nz-col nzSpan="22"
        ><p class="font-bold text-base tracking-wider">
          {{
            entite?.uniteLegale?.denominationUniteLegale | carLimitation : 25
          }}
        </p></nz-col
      >
    </nz-row>
  </ng-template>

  <ng-template #etablissementConventionHeaderTpl>
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="2">
        <svg
          class="iconSvg"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
          />
        </svg>
      </nz-col>
      <nz-col nzSpan="20"
        ><p class="font-bold text-base tracking-wider">
          Conventions collectives
        </p></nz-col
      >
      <nz-col nzSpan="2">
        <nz-badge
          [nzCount]="entite?.conventionsCollectives?.conventions | size"
        ></nz-badge>
      </nz-col>
    </nz-row>
  </ng-template>

  <ng-template #etablissementNoConvention>
    <app-no-result
      [icon]="'article'"
      [description]="'Aucun convention collective trouvée'"
      [isButton]="false"
    ></app-no-result>
  </ng-template>
</ng-container>
