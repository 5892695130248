import { ParticipantFormComponent } from './../participant-form/participant-form.component';
import { ParticipantFiltersComponent } from './../participant-filters/participant-filters.component';
import { ComponentPortal } from '@angular/cdk/portal';
import { PortalNavigationService } from './../../../../portals/portal-navigation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ParticipantState } from './../../store/participant.reducer';
import * as fromParticipantAction from './../../store/participant.actions';
import * as fromParticipantSelection from './../../store/participant.selectors';

import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
  TemplateRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserAction from 'src/app/components/user/store/user.actions';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';

import { ParticipantSmallId } from '../../store/participant.model';

@Component({
  selector: 'app-participant-list',
  templateUrl: './participant-list.component.html',
  styleUrls: ['./participant-list.component.scss'],
})
export class ParticipantListComponent implements OnInit, OnDestroy {
  data$: Observable<any> = of(null);
  participants$: Observable<ParticipantSmallId[] | any> = of([]);
  favoris$: Observable<any> = of(null);
  modalWidth: number = 1100;
  componentPortalFilter: ComponentPortal<ParticipantFiltersComponent> | any;
  title: string = 'Nouveau participant';
  newCardTitle: string = 'Nouveau participant';
  newCardDescription: string = 'Ajouter un participant à votre organisation';
  newCardDefinition: string =
    "Un participant est une personne qui bénéficie d'un accompagnement socioprofessionnel";

  @ViewChild('participantTitleForm', { static: false }) participantTitleForm:
    | TemplateRef<any>
    | any;

  constructor(
    private participantStore: Store<ParticipantState>,
    private userStore: Store<UserState>,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private activateRouter: ActivatedRoute,
    private router: Router,
    private portalNavigationService: PortalNavigationService
  ) {}

  ngOnInit(): void {
    this.getRouteData();
    this.componentPortalFilter = new ComponentPortal(
      ParticipantFiltersComponent
    );
    this.setFilterPortal();
    this.loadFavorisParticipants();
    this.getFavorisParticipants();
    this.getParticipants();
  }

  ngOnDestroy(): void {
    this.componentPortalFilter.detach();
  }

  getRouteData(): void {
    this.data$ = this.activateRouter.data;
  }

  getParticipants(): void {
    this.participants$ = this.participantStore.select(
      fromParticipantSelection.participants
    );
  }

  loadParticipants(): void {
    this.participantStore.dispatch(fromParticipantAction.loadParticipants());
  }

  setFilterPortal(): void {
    this.portalNavigationService.setPortal(this.componentPortalFilter);
  }

  getFavorisParticipants(): void {
    this.favoris$ = this.userStore.select(fromUserSelector.favorisParticipants);
  }

  loadFavorisParticipants(): void {
    this.userStore.dispatch(fromUserAction.loadUserFavorisParticipants());
  }

  onParticipantForm(): void {
    this.title = 'Nouveau participant';
    const modal = this.modal.create({
      nzContent: ParticipantFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.participantTitleForm,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });
    const instance = modal.getContentComponent();
    instance.loadUtilityIdentity();
    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
  }
}
