import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  ParticipantDiagnosticState,
  participantDiagnosticsFeatureKey,
  selectAll,
} from './participant-diagnostic.reducer';

export const selectParticipantDiagnosticsState =
  createFeatureSelector<ParticipantDiagnosticState>(
    participantDiagnosticsFeatureKey
  );

export const diagnostics = createSelector(
  selectParticipantDiagnosticsState,
  selectAll
);

export const diagnostic = createSelector(
  selectParticipantDiagnosticsState,
  (state: ParticipantDiagnosticState) => state.diagnostic
);
export const error = createSelector(
  selectParticipantDiagnosticsState,
  (state: ParticipantDiagnosticState) => state.error
);
