import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  OperationState,
  operationsFeatureKey,
  selectAll,
} from './operation.reducer';

export const selectOperationState =
  createFeatureSelector<OperationState>(operationsFeatureKey);

export const operations = createSelector(selectOperationState, selectAll);

export const entitesSelection = createSelector(
  selectOperationState,
  (state: OperationState) => state.entitesSelection
);

export const operation = createSelector(
  selectOperationState,
  (state: OperationState) => state.operation
);

export const statistiques = createSelector(
  selectOperationState,
  (state: OperationState) => state.statistiques
);

export const activeroutetitle = createSelector(
  selectOperationState,
  (state: OperationState) => state.activeRouteTitle
);

export const filters = createSelector(
  selectOperationState,
  (state: OperationState) => state.filters
);

export const suivis = createSelector(
  selectOperationState,
  (state: OperationState) => state.suivis
);

export const suivi = createSelector(
  selectOperationState,
  (state: OperationState) => state.suivi
);
export const documents = createSelector(
  selectOperationState,
  (state: OperationState) => state.documents
);
export const document = createSelector(
  selectOperationState,
  (state: OperationState) => state.document
);

export const entites = createSelector(
  selectOperationState,
  (state: OperationState) => state.entites
);
export const entite = createSelector(
  selectOperationState,
  (state: OperationState) => state.entite
);

export const contacts = createSelector(
  selectOperationState,
  (state: OperationState) => state.contacts
);
export const contact = createSelector(
  selectOperationState,
  (state: OperationState) => state.contact
);

export const participants = createSelector(
  selectOperationState,
  (state: OperationState) => state.participants
);
export const participant = createSelector(
  selectOperationState,
  (state: OperationState) => state.participant
);

export const missions = createSelector(
  selectOperationState,
  (state: OperationState) => state.missions
);
export const mission = createSelector(
  selectOperationState,
  (state: OperationState) => state.mission
);

export const updateLoading = createSelector(
  selectOperationState,
  (state: OperationState) => state.updateLoading
);

export const loading = createSelector(
  selectOperationState,
  (state: OperationState) => state.loading
);

export const error = createSelector(
  selectOperationState,
  (state: OperationState) => state.error
);
