<div class="w-full grid grid-cols-1 space-y-8">
  <div class="w-full flex items-end content-center space-x-8 p-2">
    <div class="w-8/12 flex items-end content-end space-x-8">
      <button class="btn btn-sm btn-circle btn-ghost" (click)="onBackToList()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
          />
        </svg>
      </button>
      <div class="font-bold text-lg tracking-wider">
        {{ fiche?.mission?.metier?.libelleCourt | carLimitation : 60 }}
      </div>

      <div class="badge badge-lg badge-ghost">
        {{
          fiche?.recherche?.isClauseInsertion
            ? "Clause sociale"
            : "Hors clause sociale"
        }}
      </div>
      <div
        [class]="
          fiche?.isClosed
            ? 'badge badge-lg badge-success'
            : 'badge badge-lg badge-ghost'
        "
      >
        {{ fiche?.isClosed ? "Clôturé" : "En cours" }}
      </div>
    </div>
    <div class="navbar-end w-4/12 gap-x-4">
      <app-button-menu
        [title]="'Ajouter'"
        [type]="''"
        [menu]="menuNewItems"
        (select)="onNewActions($event)"
      ></app-button-menu>

      <app-button-menu
        [title]="'Exporter'"
        [type]="''"
        [menu]="menuExportItems"
        (select)="onExportActions($event)"
      ></app-button-menu>
      <button class="btn btn-sm btn-ghost">Clôturer</button>

      <app-button-dropdown
        [isDelete]="true"
        [isUpdate]="true"
        [deleteType]="deleteType"
        (select)="onActionsDropdown($event)"
      ></app-button-dropdown>
    </div>
  </div>
  <!-- <nz-page-header nzBackIcon [nzGhost]="false" (nzBack)="onBackToList()">
    <nz-page-header-title>{{
      fiche?.mission?.metier?.libelleCourt | carLimitation : 60
    }}</nz-page-header-title>
    <nz-page-header-subtitle>
      <nz-space nzDirection="horizontal" nzAlign="start" nzSize="small">
        <nz-tag
          *nzSpaceItem
          [nzColor]="fiche?.recherche?.isClauseInsertion ? 'blue' : 'green'"
        >
          Fiche de poste
          <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
          {{
            fiche?.recherche?.isClauseInsertion
              ? "Clause sociale"
              : "Hors clause sociale"
          }}
        </nz-tag>
        <nz-tag *nzSpaceItem [nzColor]="fiche?.isClosed ? 'success' : 'blue'">
          {{ fiche?.isClosed ? "Clôturé" : "En cours" }}
        </nz-tag>
      </nz-space>
    </nz-page-header-subtitle>
    <nz-page-header-extra>
      <div class="w-full flex items-end justify-end">
        <app-button-menu
          [title]="'Nouveau'"
          [type]="'primary'"
          [menu]="menuNewItems"
          (select)="onNewActions($event)"
          class="mr-2"
        ></app-button-menu>
        <app-button-menu
          [title]="'Export'"
          [type]="'default'"
          [menu]="menuExportItems"
          (select)="onExportActions($event)"
          class="mr-6"
        ></app-button-menu>

        <app-button-dropdown
          [isDelete]="true"
          [isUpdate]="true"
          [deleteType]="deleteType"
          (select)="onActionsDropdown($event)"
        ></app-button-dropdown>
      </div>
    </nz-page-header-extra>
  </nz-page-header> -->

  <app-fiches-de-poste-item-dashboard
    [fiche]="fiche"
    (select)="onDashboarSelect($event)"
  ></app-fiches-de-poste-item-dashboard>
</div>
