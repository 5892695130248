import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { OperationNote, OperationNoteId } from './operation-note.model';
import * as OperationNoteActions from './operation-note.actions';

export const operationNotesFeatureKey = 'operationNotes';

export interface OperationNoteState extends EntityState<OperationNoteId> {
  loading: boolean;
  error: any;
  note: OperationNoteId | any;
}

export const adapter: EntityAdapter<OperationNoteId> =
  createEntityAdapter<OperationNoteId>();

export const initialState: OperationNoteState = adapter.getInitialState({
  loading: false,
  error: undefined,
  note: undefined,
});

export const reducer = createReducer(
  initialState,

  //LOADING
  on(OperationNoteActions.loadingOperationNotes, (state, action) => {
    return {
      ...state,
      loading: action.loading,
    };
  }),

  //LOAD ALL
  on(OperationNoteActions.loadOperationNotes, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(OperationNoteActions.loadOperationNotesSuccess, (state, action) =>
    adapter.setAll(action.notes, state)
  ),
  on(OperationNoteActions.loadOperationNotesSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
    };
  }),

  on(OperationNoteActions.loadOperationNotesFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
      loading: false,
    };
  }),

  //LOAD ONE
  on(OperationNoteActions.loadOperationNote, (state, action) => {
    return {
      ...state,
      note: undefined,
      loading: true,
    };
  }),
  on(OperationNoteActions.loadOperationNoteSuccess, (state, action) => {
    return {
      ...state,
      note: action.note,
      loading: false,
    };
  }),
  on(OperationNoteActions.loadOperationNoteFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
      note: undefined,
      loading: false,
    };
  }),

  //ADD ONE
  // on(OperationNoteActions.addOperationNote, (state, action) => {
  //   return {
  //     ...state,
  //     loading: true,
  //     error: undefined,
  //   };
  // }),
  // on(OperationNoteActions.addOperationNoteSuccess, (state, action) => {
  //   return {
  //     ...state,
  //     loading: false,
  //     error: undefined,
  //   };
  // }),

  on(OperationNoteActions.addOperationNoteFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  }),

  //UPDATE ONE
  on(OperationNoteActions.updateOperationNote, (state, action) =>
    adapter.updateOne(action.note, state)
  ),

  on(OperationNoteActions.updateOperationNoteFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
      loading: false,
    };
  }),

  //DELETE ONE
  on(OperationNoteActions.deleteOperationNote, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(OperationNoteActions.deleteOperationNoteFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //DELETE ALL
  on(OperationNoteActions.deleteOperationNotes, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(OperationNoteActions.deleteOperationNotesFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //EXPORT PDF VERSION
  on(OperationNoteActions.exportPdfOperationNoteSuccess, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(OperationNoteActions.exportPdfOperationNoteFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
      loading: false,
    };
  }),

  //EXPORT WORD VERSION
  on(OperationNoteActions.exportWordOperationNoteSuccess, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(OperationNoteActions.exportWordOperationNoteFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
      loading: false,
    };
  }),

  //COPY ONE
  on(OperationNoteActions.copyOperationNote, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(OperationNoteActions.copyOperationNoteSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
    };
  }),
  on(OperationNoteActions.copyOperationNoteFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
      loading: false,
    };
  }),

  //CLEAR
  on(OperationNoteActions.clearOperationNotes, (state) =>
    adapter.removeAll(state)
  )
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
