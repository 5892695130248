import {
  UtilitiesIds,
  UtilitiesEntityVariables,
} from './../../../../contents/components/utilities/store/utilities.model';
import { NzMessageService } from 'ng-zorro-antd/message';
import { takeUntil } from 'rxjs/operators';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  FormArray,
} from '@angular/forms';
import { Entite, EntiteId } from './../../store/entite.model';
import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { EntiteState } from '../../store/entite.reducer';
import * as fromEntiteAction from '../../store/entite.actions';
import * as fromEntiteSelector from '../../store/entite.selectors';
import { Observable, of, Subject } from 'rxjs';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';
import { Timestamp } from '@firebase/firestore';
import { Update } from '@ngrx/entity';
import { reject } from 'lodash';

@Component({
  selector: 'app-entite-form',
  templateUrl: './entite-form.component.html',
  styleUrls: ['./entite-form.component.scss'],
})
export class EntiteFormComponent implements OnInit, OnDestroy {
  title: string = 'Nouvelle entité';
  sirets$: Observable<any> = of(null);
  @Input() isSiretCheck: boolean = true;
  add: boolean = true;
  update: boolean = false;
  subscribe = new Subject();
  user$: Observable<any> = of(null);
  utility$: Observable<any> = of(null);
  utilitairesId: UtilitiesIds = UtilitiesIds.entite;
  utilitaireSecteurs = UtilitiesEntityVariables.secteur;
  utilitaireStatut = UtilitiesEntityVariables.status;
  utilitaireOrigine = UtilitiesEntityVariables.origine;
  utilitaireEngagement = UtilitiesEntityVariables.engagements;
  utilitaireType = UtilitiesEntityVariables.type;

  entiteForm: UntypedFormGroup = this.fb.group({});
  //CUSTOM FIELS
  auths: UntypedFormControl = new UntypedFormControl();
  stats: UntypedFormControl = new UntypedFormControl();
  logo: UntypedFormControl = new UntypedFormControl();
  fileRef: UntypedFormControl = new UntypedFormControl();
  antenne: UntypedFormControl = new UntypedFormControl();
  effectifSalarier: UntypedFormControl = new UntypedFormControl();
  type: UntypedFormControl = new UntypedFormControl();
  statut: UntypedFormControl = new UntypedFormControl();
  member: UntypedFormControl = new UntypedFormControl();
  fixe: UntypedFormControl = new UntypedFormControl();
  mobile: UntypedFormControl = new UntypedFormControl();
  coordonneesEmail: UntypedFormControl = new UntypedFormControl();
  web: UntypedFormControl = new UntypedFormControl();
  categorie: UntypedFormControl = new UntypedFormControl();
  adherent: UntypedFormControl = new UntypedFormControl();
  adhesionId: UntypedFormControl = new UntypedFormControl();
  adhesionStatut: UntypedFormControl = new UntypedFormControl();
  adhesionDataStart: UntypedFormControl = new UntypedFormControl();
  adhesionMontant: UntypedFormControl = new UntypedFormControl();
  radhesionRception: UntypedFormControl = new UntypedFormControl();
  adhesionPaiement: UntypedFormControl = new UntypedFormControl();
  adhesionMode: UntypedFormControl = new UntypedFormControl();
  adhesionYear: UntypedFormControl = new UntypedFormControl();
  secteur: UntypedFormControl = new UntypedFormControl();
  tags: UntypedFormControl = new UntypedFormControl();
  axeDeTravail: UntypedFormControl = new UntypedFormControl();
  dateStart: UntypedFormControl = new UntypedFormControl();
  dateUpdate: UntypedFormControl = new UntypedFormControl();
  dateSelected: UntypedFormControl = new UntypedFormControl();
  metiers: UntypedFormControl = new UntypedFormControl();
  metiersCompetencesDeBase: UntypedFormControl = new UntypedFormControl();
  metiersCompetencesDeSpeficique: UntypedFormControl = new UntypedFormControl();
  metiersThemes: UntypedFormControl = new UntypedFormControl();
  metiersEnvironnement: UntypedFormControl = new UntypedFormControl();
  metiersDomaine: UntypedFormControl = new UntypedFormControl();
  metiersGrandDomaine: UntypedFormControl = new UntypedFormControl();
  metiersAppelations: UntypedFormControl = new UntypedFormControl();
  metiersMap: UntypedFormControl = new UntypedFormControl();
  metiersCompetencesDeBaseMap: UntypedFormControl = new UntypedFormControl();
  metiersCompetencesDeBaseCleMap: UntypedFormControl = new UntypedFormControl();
  metiersCompetencesDeSpeficiqueMap: UntypedFormControl = new UntypedFormControl();
  metiersCompetencesDeSpeficiqueCleMap: UntypedFormControl = new UntypedFormControl();
  metiersEnvironnementMap: UntypedFormControl = new UntypedFormControl();
  metiersSoftskills: UntypedFormControl = new UntypedFormControl();
  metiersSoftskillsMap: UntypedFormControl = new UntypedFormControl();
  metiersAppelationsMap: UntypedFormControl = new UntypedFormControl();
  services: UntypedFormControl = new UntypedFormControl();
  servicesMap: UntypedFormControl = new UntypedFormControl();

  operations: UntypedFormControl = new UntypedFormControl();
  operationsId: UntypedFormControl = new UntypedFormControl();
  contacts: UntypedFormControl = new UntypedFormControl();
  contactsIds: UntypedFormControl = new UntypedFormControl();
  contactsMapIds: UntypedFormControl = new UntypedFormControl();
  modules: UntypedFormControl = new UntypedFormControl();
  modulesIds: UntypedFormControl = new UntypedFormControl();
  modulesMapIds: UntypedFormControl = new UntypedFormControl();
  actions: UntypedFormControl = new UntypedFormControl();
  actionsIds: UntypedFormControl = new UntypedFormControl();
  actionsMapIds: UntypedFormControl = new UntypedFormControl();
  participants: UntypedFormControl = new UntypedFormControl();
  participantsIds: UntypedFormControl = new UntypedFormControl();
  participantsMapIds: UntypedFormControl = new UntypedFormControl();
  notes: UntypedFormControl = new UntypedFormControl();
  origine: UntypedFormControl = new UntypedFormControl();
  customOrganisation: UntypedFormControl = new UntypedFormControl();
  engagement: UntypedFormControl = new UntypedFormControl();
  auteur: UntypedFormControl = new UntypedFormControl();
  userSelected: UntypedFormControl = new UntypedFormControl();
  userUpdate: UntypedFormControl = new UntypedFormControl();
  actif: UntypedFormControl = new UntypedFormControl();
  ess: UntypedFormControl = new UntypedFormControl();
  prescripteur: UntypedFormControl = new UntypedFormControl();
  email: UntypedFormControl = new UntypedFormControl();
  groups: UntypedFormControl = new UntypedFormControl();
  subscriptions: UntypedFormControl = new UntypedFormControl();
  parrainage: UntypedFormControl = new UntypedFormControl(false);

  organisation: UntypedFormControl = new UntypedFormControl();
  metadata: UntypedFormControl = new UntypedFormControl();
  urlRegex =
    /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?%#[]@!\$&'()*\+,;=.]+$/;

  //API FIELDS
  idEtablissement: UntypedFormControl = new UntypedFormControl();
  numero_tva_intra: UntypedFormControl = new UntypedFormControl();
  siren: UntypedFormControl = new UntypedFormControl();
  denomination: UntypedFormControl = new UntypedFormControl();
  categorie_juridique: UntypedFormControl = new UntypedFormControl();
  libelleCategorie_juridique_libelle: UntypedFormControl = new UntypedFormControl();
  codeCategorie_juridique_libelle: UntypedFormControl = new UntypedFormControl();
  categorie_entreprise: UntypedFormControl = new UntypedFormControl();
  activite_principale: UntypedFormControl = new UntypedFormControl();
  nomenclature_activite_principale: UntypedFormControl = new UntypedFormControl();
  nic_siege: UntypedFormControl = new UntypedFormControl();
  economie_sociale_solidaire: UntypedFormControl = new UntypedFormControl();
  intitule_nafActivite_principale_libelle: UntypedFormControl = new UntypedFormControl();
  intitule_naf_40Activite_principale_libelle: UntypedFormControl = new UntypedFormControl();
  intitule_naf_65Activite_principale_libelle: UntypedFormControl = new UntypedFormControl();
  code_nafActivite_principale_libelle: UntypedFormControl = new UntypedFormControl();
  etat_administratif: UntypedFormControl = new UntypedFormControl();
  denomination_usuelle_1: UntypedFormControl = new UntypedFormControl();
  nicEtablissement_siege: UntypedFormControl = new UntypedFormControl();
  siretEtablissement_siege: UntypedFormControl = new UntypedFormControl();
  etablissement_siegeEtablissement_siege: UntypedFormControl = new UntypedFormControl();
  type_voieEtablissement_siege: UntypedFormControl = new UntypedFormControl();
  libelle_voieEtablissement_siege: UntypedFormControl = new UntypedFormControl();
  code_postalEtablissement_siege: UntypedFormControl = new UntypedFormControl();
  libelle_communeEtablissement_siege: UntypedFormControl = new UntypedFormControl();
  code_communeEtablissement_siege: UntypedFormControl = new UntypedFormControl();
  longitudeEtablissement_siege: UntypedFormControl = new UntypedFormControl();
  latitudeEtablissement_siege: UntypedFormControl = new UntypedFormControl();
  geo_adresseEtablissement_siege: UntypedFormControl = new UntypedFormControl();
  geo_typeEtablissement_siege: UntypedFormControl = new UntypedFormControl();
  geo_scoreEtablissement_siege: UntypedFormControl = new UntypedFormControl();
  geo_ligneEtablissement_siege: UntypedFormControl = new UntypedFormControl();
  geo_l4Etablissement_siege: UntypedFormControl = new UntypedFormControl();
  geo_l5Etablissement_siege: UntypedFormControl = new UntypedFormControl();
  unite_legale_idEtablissement_siege: UntypedFormControl = new UntypedFormControl();
  geo_idEtablissement_siege: UntypedFormControl = new UntypedFormControl();

  @Input() entite$: Observable<EntiteId | any> = of(null);
  @Output() confirm = new EventEmitter(false);

  @Output() cancel = new EventEmitter(false);

  constructor(
    private entiteStore: Store<EntiteState>,
    private fb: UntypedFormBuilder,
    private userStore: Store<UserState>,
    private message: NzMessageService
  ) {}

  ngOnInit(): void {
    this.getUser();
    //this.getEntite();
    this.getSirets();
    this.loadSirets();
    this.form();
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getUser(): void {
    this.user$ = this.userStore.select(fromUserSelector.user);
  }

  getEntite(): void {
    this.entite$ = this.entiteStore.select(fromEntiteSelector.entite);
  }

  getSirets(): void {
    this.sirets$ = this.entiteStore.select(fromEntiteSelector.sirets);
  }

  loadSirets(): void {
    this.entiteStore.dispatch(fromEntiteAction.loadEntiteSirets());
  }

  form(): void {
    this.formInit();
    this.formCreate();
    this.formSet();
  }
  formInit(): void {
    this.auths = new UntypedFormControl();
    this.stats = new UntypedFormControl();
    this.logo = new UntypedFormControl();
    this.fileRef = new UntypedFormControl();
    this.antenne = new UntypedFormControl();
    this.effectifSalarier = new UntypedFormControl();
    this.type = new UntypedFormControl();
    this.statut = new UntypedFormControl('', Validators.required);
    this.member = new UntypedFormControl();
    this.fixe = new UntypedFormControl('', [
      Validators.pattern(new RegExp('[0-9 ]{10}')),
      Validators.minLength(10),
    ]);
    this.mobile = new UntypedFormControl('', [
      Validators.pattern(new RegExp('[0-9 ]{10}')),
      Validators.minLength(10),
    ]);
    this.coordonneesEmail = new UntypedFormControl('', Validators.email);
    // this.web = new FormControl('', Validators.pattern(this.urlRegex));
    this.web = new UntypedFormControl('');

    this.categorie = new UntypedFormControl();
    this.adherent = new UntypedFormControl();
    this.adhesionId = new UntypedFormControl();
    this.adhesionStatut = new UntypedFormControl();
    this.adhesionDataStart = new UntypedFormControl();
    this.adhesionMontant = new UntypedFormControl();
    this.radhesionRception = new UntypedFormControl();
    this.adhesionPaiement = new UntypedFormControl();
    this.adhesionMode = new UntypedFormControl();
    this.adhesionYear = new UntypedFormControl();
    this.secteur = new UntypedFormControl();
    this.tags = new UntypedFormControl();
    this.axeDeTravail = new UntypedFormControl();
    this.dateStart = new UntypedFormControl(Timestamp.now(), Validators.required);
    this.dateUpdate = new UntypedFormControl(Timestamp.now(), Validators.required);
    this.dateSelected = new UntypedFormControl(Timestamp.now(), Validators.required);
    this.metiers = new UntypedFormControl();
    this.metiersCompetencesDeBase = new UntypedFormControl();
    this.metiersCompetencesDeSpeficique = new UntypedFormControl();
    this.metiersThemes = new UntypedFormControl();
    this.metiersEnvironnement = new UntypedFormControl();
    this.metiersGrandDomaine = new UntypedFormControl('', Validators.required);
    this.metiersDomaine = new UntypedFormControl('', Validators.required);
    this.metiersAppelations = new UntypedFormControl();
    this.metiersMap = new UntypedFormControl();
    this.metiersCompetencesDeBaseMap = new UntypedFormControl();
    this.metiersCompetencesDeBaseCleMap = new UntypedFormControl();
    this.metiersCompetencesDeSpeficiqueMap = new UntypedFormControl();
    this.metiersCompetencesDeSpeficiqueCleMap = new UntypedFormControl();
    this.metiersEnvironnementMap = new UntypedFormControl();
    this.metiersSoftskills = new UntypedFormControl();
    this.metiersSoftskillsMap = new UntypedFormControl();
    this.metiersAppelationsMap = new UntypedFormControl();
    this.services = new UntypedFormControl();
    this.servicesMap = new UntypedFormControl();
    this.operations = new UntypedFormControl();
    this.operationsId = new UntypedFormControl();
    this.contacts = new UntypedFormControl();
    this.contactsIds = new UntypedFormControl();
    this.contactsMapIds = new UntypedFormControl();
    this.modules = new UntypedFormControl();
    this.modulesIds = new UntypedFormControl();
    this.modulesMapIds = new UntypedFormControl();
    this.actions = new UntypedFormControl();
    this.actionsIds = new UntypedFormControl();
    this.actionsMapIds = new UntypedFormControl();
    this.participants = new UntypedFormControl();
    this.participantsIds = new UntypedFormControl();
    this.participantsMapIds = new UntypedFormControl();
    this.notes = new UntypedFormControl();
    this.origine = new UntypedFormControl('', Validators.required);
    this.customOrganisation = new UntypedFormControl();
    this.engagement = new UntypedFormControl('', Validators.required);
    this.auteur = new UntypedFormControl();
    this.userSelected = new UntypedFormControl();
    this.userUpdate = new UntypedFormControl();
    this.actif = new UntypedFormControl(true);
    this.ess = new UntypedFormControl();
    this.prescripteur = new UntypedFormControl();
    this.email = new UntypedFormControl();
    this.organisation = new UntypedFormControl();
    this.metadata = new UntypedFormControl();
    this.groups = new UntypedFormControl();
    this.subscriptions = new UntypedFormControl();

    //API FIELDS
    this.idEtablissement = new UntypedFormControl();
    this.numero_tva_intra = new UntypedFormControl();
    this.siren = new UntypedFormControl();
    this.denomination = new UntypedFormControl();
    this.categorie_juridique = new UntypedFormControl();
    this.libelleCategorie_juridique_libelle = new UntypedFormControl();
    this.codeCategorie_juridique_libelle = new UntypedFormControl();
    this.categorie_entreprise = new UntypedFormControl();
    this.activite_principale = new UntypedFormControl();
    this.nomenclature_activite_principale = new UntypedFormControl();
    this.nic_siege = new UntypedFormControl();
    this.economie_sociale_solidaire = new UntypedFormControl();
    this.intitule_nafActivite_principale_libelle = new UntypedFormControl();
    this.intitule_naf_40Activite_principale_libelle = new UntypedFormControl();
    this.intitule_naf_65Activite_principale_libelle = new UntypedFormControl();
    this.code_nafActivite_principale_libelle = new UntypedFormControl();
    this.etat_administratif = new UntypedFormControl();
    this.denomination_usuelle_1 = new UntypedFormControl();
    this.nicEtablissement_siege = new UntypedFormControl();
    this.siretEtablissement_siege = new UntypedFormControl();
    this.etablissement_siegeEtablissement_siege = new UntypedFormControl();
    this.type_voieEtablissement_siege = new UntypedFormControl();
    this.libelle_voieEtablissement_siege = new UntypedFormControl();
    this.code_postalEtablissement_siege = new UntypedFormControl();
    this.libelle_communeEtablissement_siege = new UntypedFormControl();
    this.code_communeEtablissement_siege = new UntypedFormControl();
    this.longitudeEtablissement_siege = new UntypedFormControl();
    this.latitudeEtablissement_siege = new UntypedFormControl();
    this.geo_adresseEtablissement_siege = new UntypedFormControl();
    this.geo_typeEtablissement_siege = new UntypedFormControl();
    this.geo_scoreEtablissement_siege = new UntypedFormControl();
    this.geo_ligneEtablissement_siege = new UntypedFormControl();
    this.geo_l4Etablissement_siege = new UntypedFormControl();
    this.geo_l5Etablissement_siege = new UntypedFormControl();
    this.unite_legale_idEtablissement_siege = new UntypedFormControl();
    this.geo_idEtablissement_siege = new UntypedFormControl();
  }
  formCreate(): void {
    this.entiteForm = this.fb.group({
      custom: this.fb.group({
        auths: this.auths,
        stats: this.stats,
        logo: this.logo,
        fileRef: this.fileRef,
        antenne: this.antenne,
        effectifSalarier: this.effectifSalarier,
        type: this.type,
        statut: this.statut,
        member: this.member,
        coordonnees: this.fb.group({
          fixe: this.fixe,
          mobile: this.mobile,
          email: this.coordonneesEmail,
          web: this.web,
        }),
        categorie: this.categorie,
        adhesion: this.fb.group({
          adherent: this.adherent,
          adhesionId: this.adhesionId,
          adhesionStatut: this.adhesionStatut,
          adhesionDataStart: this.adhesionDataStart,
          adhesionMontant: this.adhesionMontant,
          radhesionRception: this.radhesionRception,
          adhesionPaiement: this.adhesionPaiement,
          adhesionMode: this.adhesionMode,
          adhesionYear: this.adhesionYear,
        }),

        secteur: this.secteur,
        tags: this.tags,
        axeDeTravail: this.axeDeTravail,
        dateStart: this.dateStart,
        dateUpdate: this.dateUpdate,
        dateSelected: this.dateSelected,
        metiers: this.metiers,
        metiersCompetencesDeBase: this.metiersCompetencesDeBase,
        metiersCompetencesDeSpeficique: this.metiersCompetencesDeSpeficique,
        metiersThemes: this.metiersThemes,
        metiersEnvironnement: this.metiersEnvironnement,
        metiersDomaine: this.metiersDomaine,
        metiersGrandDomaine: this.metiersGrandDomaine,
        metiersAppelations: this.metiersAppelations,
        metiersCompetencesDeBaseMap: this.metiersCompetencesDeBaseMap,
        metiersCompetencesDeBaseCleMap: this.metiersCompetencesDeBaseCleMap,
        metiersCompetencesDeSpeficiqueMap:
          this.metiersCompetencesDeSpeficiqueMap,
        metiersCompetencesDeSpeficiqueCleMap:
          this.metiersCompetencesDeSpeficiqueCleMap,
        metiersEnvironnementMap: this.metiersEnvironnementMap,
        metiersSoftskills: this.metiersSoftskills,
        metiersSoftskillsMap: this.metiersSoftskillsMap,
        metiersAppelationsMap: this.metiersAppelationsMap,

        services: this.services,
        servicesMap: this.servicesMap,
        operations: this.operations,
        operationsId: this.operationsId,
        contacts: this.contacts,
        contactsIds: this.contactsIds,
        contactsMapIds: this.contactsMapIds,
        modules: this.modules,
        modulesIds: this.modulesIds,
        modulesMapIds: this.modulesMapIds,
        actions: this.actions,
        actionsIds: this.actionsIds,
        actionsMapIds: this.actionsMapIds,
        participants: this.participants,
        participantsIds: this.participantsIds,
        participantsMapIds: this.participantsMapIds,
        notes: this.notes,
        origine: this.origine,
        organisation: this.customOrganisation,
        engagement: this.engagement,
        auteur: this.auteur,
        userSelected: this.userSelected,
        userUpdate: this.userUpdate,
        actif: this.actif,
        ess: this.ess,
        prescripteur: this.prescripteur,
        groups: this.groups,
        subscriptions: this.subscriptions,
      }),
      etablissement: this.fb.group({
        numero_tva_intra: this.numero_tva_intra,
        siren: this.siren,
        denomination: this.denomination,
        categorie_juridique: this.categorie_juridique,
        categorie_juridique_libelle: this.fb.group({
          libelle: this.libelleCategorie_juridique_libelle,
          code: this.codeCategorie_juridique_libelle,
        }),
        categorie_entreprise: this.categorie_entreprise,
        activite_principale: this.activite_principale,
        nomenclature_activite_principale: this.nomenclature_activite_principale,
        nic_siege: this.nic_siege,
        economie_sociale_solidaire: this.economie_sociale_solidaire,
        activite_principale_libelle: this.fb.group({
          intitule_naf: this.intitule_nafActivite_principale_libelle,
          intitule_naf_40: this.intitule_naf_40Activite_principale_libelle,
          intitule_naf_65: this.intitule_naf_65Activite_principale_libelle,
          code_naf: this.code_nafActivite_principale_libelle,
        }),
        etat_administratif: this.etat_administratif,
        denomination_usuelle_1: this.denomination_usuelle_1,
        etablissement_siege: this.fb.group({
          nic: this.nicEtablissement_siege,
          siret: this.siretEtablissement_siege,
          etablissement_siege: this.etablissement_siegeEtablissement_siege,
          type_voie: this.type_voieEtablissement_siege,
          libelle_voie: this.libelle_voieEtablissement_siege,
          code_postal: this.code_postalEtablissement_siege,
          libelle_commune: this.libelle_communeEtablissement_siege,
          code_commune: this.code_communeEtablissement_siege,
          longitude: this.longitudeEtablissement_siege,
          latitude: this.latitudeEtablissement_siege,
          geo_adresse: this.geo_adresseEtablissement_siege,
          geo_type: this.geo_typeEtablissement_siege,
          geo_score: this.geo_scoreEtablissement_siege,
          geo_ligne: this.geo_ligneEtablissement_siege,
          geo_l4: this.geo_l4Etablissement_siege,
          geo_l5: this.geo_l5Etablissement_siege,
          unite_legale_id: this.unite_legale_idEtablissement_siege,
          geo_id: this.geo_idEtablissement_siege,
        }),
      }),
      organisation: this.organisation,
      metadata: this.metadata,
    });
  }
  formSet(): void {
    this.entite$
      .pipe(takeUntil(this.subscribe))
      .subscribe((entite: EntiteId | any): any => {
        if (!entite) {
          this.add = true;
          this.update = false;
          this.title = 'Nouvelle entité';

          return null;
        } else {
          this.add = false;
          this.update = true;
          this.formUpdate(entite);
          this.title = `Modification :  ${entite.etablissement?.denomination}`;
        }
      });
  }
  formUpdate(entite: EntiteId | any): void {
    this.entiteForm.patchValue({ ...entite });
  }

  onSelectEntite(etablissement: any): void {
    if (!etablissement) return;
    this.sirets$.pipe(takeUntil(this.subscribe)).subscribe((data: any): any => {
      if (!data) {
        return null;
      }

      const sirest = data?.sirets || !data?.sirets?.length ? data.sirets : null;

      const { etablissement_siege, denomination } = etablissement;
      const { siret } = etablissement_siege;

      if (!sirest) {
        this.entiteForm.patchValue({ etablissement });
        return null;
      }

      const isSiretExist = sirest.find((el: string) => el === siret);

      if (!isSiretExist) {
        this.entiteForm.patchValue({ etablissement });

        return null;
      } else {
        const messageAlert: string = `${denomination} existe dans vos entités`;
        this.message.warning(messageAlert, { nzDuration: 6000 });
        return null;
      }
    });
  }
  onSelectStatus(status: string): void {
    this.entiteForm.patchValue({
      custom: { statut: status },
    });
  }
  onSelectEngagement(engagement: string): void {
    this.entiteForm.patchValue({
      custom: { engagement: engagement },
    });
  }
  onSelectOrigine(origine: string): void {
    this.entiteForm.patchValue({
      custom: { origine: origine },
    });
  }

  onSelectTheme(theme: any): void {}
  onSelectDomaine(domaine: any): void {
    if (!domaine) return;
    this.entiteForm.patchValue({
      custom: {
        metiersGrandDomaine: domaine,
      },
    });
  }
  onSelectDomaineProfessionnel(domaine: any): void {
    if (!domaine) return;
    this.entiteForm.patchValue({
      custom: {
        metiersDomaine: domaine,
      },
    });
  }

  onSelectMetiers(metiers: any): void {}
  onSelectAppelations(appelations: any): void {}
  onSelectAppelation(appelation: any): void {}
  onSelectSofskill(softskill: any): void {}

  onAdd(): void {
    if (!this.entiteForm.valid) {
      return;
    } else {
      const entite: Entite = this.entiteForm.value;
      this.entiteStore.dispatch(fromEntiteAction.addEntite({ entite }));
      this.onCancel();
    }
  }
  onUpdate(entite: EntiteId | any): void {
    if (!this.entiteForm.valid || !entite || !entite.id) {
      return;
    } else {
      const formValue: Entite = this.entiteForm.value;
      const entiteUpdate: Update<EntiteId> = {
        id: entite.id,
        changes: { ...entite, ...formValue },
      };
      this.entiteStore.dispatch(
        fromEntiteAction.updateEntite({ entite: entiteUpdate })
      );
      this.onCancel();
    }
  }

  onCancel(): void {
    this.entiteForm.reset();
    this.add = false;
    this.update = false;
    this.cancel.emit(true);
    this.entiteStore.dispatch(fromEntiteAction.loading({ loading: false }));
  }
}
