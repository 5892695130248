import { Pipe, PipeTransform } from '@angular/core';
import { isAfter, isBefore, isToday, fromUnixTime, format } from 'date-fns';

@Pipe({
  name: 'moduleActivity',
})
export class ModuleActivityPipe implements PipeTransform {
  today: Date = new Date(Date.now());

  transform(value: any[], type: string): any {
    if (!value || !value?.length) {
      return null;
    }

    switch (type) {
      case 'today':
        var source: any[] = value
          .map((el) => fromUnixTime(el.calendrier.dateStart['seconds']))
          .filter((d) => isBefore(d, this.today));
        return source;

        break;
      case 'later':
        var source: any[] = value
          .map((el) => fromUnixTime(el.calendrier.dateEnd['seconds']))
          .filter((d) => isBefore(d, this.today));

        return source;

        break;
      case 'futur':
        var source: any[] = value
          .map((el) => fromUnixTime(el.calendrier.dateStart['seconds']))
          .filter((d) => isAfter(d, this.today));

        return source;
        break;

      default:
        break;
    }
  }
}
