import { ParticipantValidatorComponent } from './../../../../contents/components/participant-validator/participant-validator.component';
import { ParticipantsSelectComponent } from './../../../../contents/components/participants-select/components/participants-select.component';
import { Observable, of, BehaviorSubject, Subject } from 'rxjs';
import { NzModalService } from 'ng-zorro-antd/modal';
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewContainerRef,
} from '@angular/core';
import { ParticipantSmallId } from 'src/app/components/participant/store/participant.model';

import { Store } from '@ngrx/store';
import { OperationId } from 'src/app/components/operation/store/operation.model';
import { OperationState } from 'src/app/components/operation/store/operation.reducer';
import * as fromOperationAction from 'src/app/components/operation/store/operation.actions';
import * as fromOperationSelector from 'src/app/components/operation/store/operation.selectors';

@Component({
  selector: 'app-operation-participants-filters',
  template: `
    <nz-row
      nzAlign="middle"
      nzGutter="4"
      nzJustify="center"
      *ngIf="operation$ | async as operation"
    >
      <nz-col nzSpan="6" class="filterTitle">
        <nz-badge [nzCount]="participants$ | async | size" [nzOffset]="[10, 0]">
          <h4 nz-typography [ngStyle]="{ color: buttonColor }">
            {{ operation?.type | operationType: 'participant' }}
          </h4>
        </nz-badge>
      </nz-col>
      <nz-col nzSpan="14" class="inputSearch">
        <input
          nz-input
          [(ngModel)]="inputModel"
          (ngModelChange)="onSeach($event, operation)"
          nzBorderless
          placeholder="Rechercher un participant..."
        />
      </nz-col>

      <nz-col nzSpan="1"></nz-col>
      <nz-col nzSpan="1">
        <button
          nz-button
          nzShape="circle"
          nzType="text"
          nz-tooltip
          nzTooltipTitle="Filter les participants"
          nzTooltipPlacement="bottom"
          [nzTooltipMouseEnterDelay]="0.5"
          nzTooltipPlacement="bottom"
        >
          <i nz-icon nzType="filter"></i>
        </button>
      </nz-col>
      <nz-col nzSpan="2">
        <button nz-button nzType="text" nzShape="circle">
          <mat-icon>more_vert</mat-icon>
        </button>
      </nz-col>
    </nz-row>
  `,
  styles: [
    `
      .filterTitle {
        display: flex;
        flex-direction: row;
        justify-content: center;
        justify-items: center;
        margin-top: 10px;
      }

      .addBtn {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        justify-items: flex-end;
        margin-top: -10px;
      }

      .btnFilter {
        margin-top: -10px;
      }

      .inputSearch {
        border-radius: 12px;
        background-color: #f5f5f5;
      }
    `,
  ],
})
export class OperationParticipantsFiltersComponent
  implements OnInit, OnDestroy
{
  buttonColor: string = '#062949';
  modalWidth: number = 650;
  subscribe = new Subject();
  inputSearch$ = new BehaviorSubject<string>('');
  inputModel: string = '';

  @Input() operation$: Observable<OperationId | any> = of();
  @Input() participants$: Observable<any> = of(null);

  constructor(
    private operationStore: Store<OperationState>,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  onNewParticipantForm(operation: OperationId): void {
    const modal = this.modal.create({
      nzContent: ParticipantsSelectComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: `Nouveau participant au projet ${operation.denomination}`,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });
    const instance = modal.getContentComponent();
    instance.cancel.subscribe((event: any): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
    instance.select.subscribe((participant: ParticipantSmallId): any => {
      if (!participant) {
        return null;
      } else {
        modal.close();
        this.onAdd(participant, operation);
      }
    });
    // instance.operation$ = this.operation$;
  }

  onType(participant: ParticipantSmallId, operation: OperationId): void {
    const modal = this.modal.create({
      nzContent: ParticipantValidatorComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: `Type d'intervention au projet ${operation.denomination}`,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });
    const instance = modal.getContentComponent();
    instance.cancel.subscribe((event: any): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
    instance.validate.subscribe((intervention: any): any => {
      if (!intervention) {
        return null;
      } else {
        modal.close();
        this.onAdd(participant, operation);
      }
    });
  }

  onAdd(participant: ParticipantSmallId, operation: OperationId): void {
    if (!operation || !participant) return;
    this.operationStore.dispatch(
      fromOperationAction.addParticipantOperation({ operation, participant })
    );
  }

  onSeach(event: string, operation: OperationId): void {
    // if (!event) {
    //   this.moduleStore.dispatch(
    //     fromModuleAction.loadModules({ operationId: operation.id })
    //   );
    //   return;
    // } else {
    //   const filter = {
    //     operationId: operation.id,
    //     event: event,
    //     type: 'title',
    //   };
    //   this.moduleStore.dispatch(fromModuleAction.queryModules({ filter }));
    // }
  }

  onClearMdole(): void {}
}
