import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import {
  MetierAppelation,
  MetiersDomaineSuggestion,
  MetiersSuggestion,
} from './metiers-suggestion.model';
import * as MetiersSuggestionActions from './metiers-suggestion.actions';

export const metiersSuggestionsFeatureKey = 'metiersSuggestions';

export interface SuggestionsNafState extends EntityState<any> {
  loading: boolean;
  total: number;
  metier: any;
  error: any;
  suggestions: MetiersSuggestion | MetiersDomaineSuggestion | any;
  appelations: MetierAppelation[] | any;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>();

export const initialState: SuggestionsNafState = adapter.getInitialState({
  loading: false,
  total: 0,
  metier: undefined,
  error: undefined,
  suggestions: [],
  appelations: [],
});

export const reducer = createReducer(
  initialState,
  //BY NAF
  on(MetiersSuggestionActions.loadMetiersSuggestions, (state, action) => {
    return {
      ...state,
      loading: true,
      total: 0,
    };
  }),
  on(
    MetiersSuggestionActions.loadMetiersSuggestionsSuccess,
    (state, action) => {
      return {
        ...state,
        suggestions: action.suggestions,
        total: action.total,
        loading: false,
      };
    }
  ),

  on(
    MetiersSuggestionActions.loadMetiersSuggestionsFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
        loading: false,
        total: 0,
      };
    }
  ),

  //BY DOMAINE PROFESSIONNEL
  on(
    MetiersSuggestionActions.loadDomaineMetiersSuggestions,
    (state, action) => {
      return {
        ...state,
        loading: true,
        total: 0,
      };
    }
  ),
  on(
    MetiersSuggestionActions.loadDomaineMetiersSuggestionsSuccess,
    (state, action) => {
      return {
        ...state,
        suggestions: action.suggestions,
        total: action.total,
        loading: false,
      };
    }
  ),
  on(
    MetiersSuggestionActions.loadDomaineMetiersSuggestionsFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
        total: 0,
        loading: false,
      };
    }
  ),

  //APPELATIONS
  on(MetiersSuggestionActions.loadMetierAppelations, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(MetiersSuggestionActions.loadMetierAppelationsSuccess, (state, action) => {
    return {
      ...state,
      appelations: action.appelations,
      loading: false,
    };
  }),
  on(MetiersSuggestionActions.loadMetierAppelationsFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
      loading: false,
    };
  }),

  //METIER DETAILS

  on(MetiersSuggestionActions.loadMetierDetails, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),

  on(MetiersSuggestionActions.loadMetierDetailsSuccess, (state, action) => {
    return {
      ...state,
      metier: action.metier,
      loading: false,
    };
  }),
  on(MetiersSuggestionActions.loadMetierDetailsFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
      loading: false,
    };
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
