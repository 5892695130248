<ng-container *ngIf="entite$ | async as entite">
  <app-layout-grid [details]="details"></app-layout-grid>

  <ng-template #details>
    <nz-card nzBorderless>
      <div [ngSwitch]="bodyComponent">
        <div *ngSwitchCase="'details'">
          <app-entite-detail
            [entite]="entite"
            [statistiques]="statistiques$ | async"
          ></app-entite-detail>
        </div>
        <div *ngSwitchCase="'form'">
          <app-entite-form></app-entite-form>
        </div>
        <div *ngSwitchCase="'collaborateurs'">
          <app-entite-contacts [entite$]="entite$"></app-entite-contacts>
        </div>
        <div *ngSwitchCase="'suivis'">
          <app-entite-suivi [entite$]="entite$"></app-entite-suivi>
        </div>
        <div *ngSwitchCase="'tasks'">
          <app-entite-task></app-entite-task>
        </div>
        <div *ngSwitchCase="'documents'">
          <app-entite-documents></app-entite-documents>
        </div>
        <div *ngSwitchCase="'projets'">
          <app-entite-operations></app-entite-operations>
        </div>
        <div *ngSwitchCase="'dashboard'">
          <app-entite-dashboard></app-entite-dashboard>
        </div>
        <div *ngSwitchCase="'metiers'">
          <app-entite-metiers [entite]="entite$ | async"></app-entite-metiers>
        </div>
        <div *ngSwitchDefault></div>
      </div>
    </nz-card>
  </ng-template>
</ng-container>
