import { Pipe, PipeTransform } from '@angular/core';
import { fromUnixTime } from 'date-fns';

@Pipe({
  name: 'sortBy',
})
export class SortByPipe implements PipeTransform {
  transform(
    values: any[],
    propertie: string,
    direction: string,
    type: string
  ): any[] {
    if (!values || !propertie || !direction) {
      return [];
    }
    if (values && direction) {
      if (direction === 'asc') {
        const valuesSorted: any[] = values.sort(function compare(a, b) {
          var itemA = type === 'date' ? fromUnixTime(a['seconds']) : a;
          var itemB = type === 'date' ? fromUnixTime(b['seconds']) : b;
          return itemA - itemB;
        });

        return valuesSorted;
      }

      if (direction === 'des') {
        const valuesSorted: any[] = values.sort(function compare(a, b) {
          var itemA = type === 'date' ? fromUnixTime(a['seconds']) : a;
          var itemB = type === 'date' ? fromUnixTime(b['seconds']) : b;
          return itemB - itemA;
        });

        return valuesSorted;
      }
    }

    if (values && propertie && direction) {
      if (direction === 'asc') {
        const valuesSorted: any[] = values.sort(function compare(a, b) {
          var itemA =
            type === 'date'
              ? fromUnixTime(a[`${propertie}`]['seconds'])
              : a[`${propertie}`];
          var itemB =
            type === 'date'
              ? fromUnixTime(b[`${propertie}`]['seconds'])
              : b[`${propertie}`];
          return itemA - itemB;
        });

        return valuesSorted;
      }

      if (direction === 'des') {
        const valuesSorted: any[] = values.sort(function compare(a, b) {
          var itemA =
            type === 'date'
              ? fromUnixTime(a[`${propertie}`]['seconds'])
              : a[`${propertie}`];
          var itemB =
            type === 'date'
              ? fromUnixTime(b[`${propertie}`]['seconds'])
              : b[`${propertie}`];
          return itemB - itemA;
        });

        return valuesSorted;
      }
    }

    return []
  }
}
