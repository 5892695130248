<nz-steps
  [nzCurrent]="currentStep"
  nzSize="small"
  nzDirection="vertical"
  (nzIndexChange)="onStep($event)"
>
  <nz-step [nzTitle]="stepDocumentTitle" [nzIcon]="stepDocument"> </nz-step>

  <nz-step [nzTitle]="stepSocialeTitle" [nzIcon]="stepSociale"></nz-step>
  <nz-step [nzTitle]="stepFormationTitle" [nzIcon]="stepFormation"></nz-step>
  <nz-step [nzTitle]="stepRessourcesTitle" [nzIcon]="stepRessources"></nz-step>
  <nz-step [nzTitle]="stepAptitudeTitle" [nzIcon]="stepAptitude"></nz-step>
  <nz-step
    [nzTitle]="stepProfessionnelleTitle"
    [nzIcon]="stepProfessionnelle"
  ></nz-step>
  <nz-step [nzTitle]="stepResumeTitle" [nzIcon]="stepResume"></nz-step>
  <nz-step [nzTitle]="stepActionsTitle" [nzIcon]="stepActions"></nz-step>
  <nz-step [nzTitle]="stepEventsTitle" [nzIcon]="stepEvents"></nz-step>
</nz-steps>

<!-- DOCUMENT -->
<ng-template #stepDocumentTitle>
  <p
    [ngClass]="{
      active: currentStep === 0,
      nonactive: currentStep !== 0
    }"
  >
    Définition
  </p>
</ng-template>

<ng-template #stepDocument>
  <mat-icon
    [ngClass]="{
      active: currentStep === 0,
      nonactive: currentStep !== 0
    }"
    >edit_note</mat-icon
  >
</ng-template>

<!-- SOCIALE -->
<ng-template #stepSocialeTitle>
  <p
    [ngClass]="{
      active: currentStep === 1,
      nonactive: currentStep !== 1
    }"
  >
    Sociale
  </p>
</ng-template>

<ng-template #stepSociale>
  <mat-icon
    [ngClass]="{
      active: currentStep === 1,
      nonactive: currentStep !== 1
    }"
    >volunteer_activism</mat-icon
  >
</ng-template>

<!-- FORMATION -->
<ng-template #stepFormationTitle>
  <p
    [ngClass]="{
      active: currentStep === 2,
      nonactive: currentStep !== 2
    }"
  >
    Formations
  </p>
</ng-template>

<ng-template #stepFormation>
  <mat-icon
    [ngClass]="{
      active: currentStep === 2,
      nonactive: currentStep !== 2
    }"
    >school</mat-icon
  >
</ng-template>

<!-- RESSOURCE -->
<ng-template #stepRessourcesTitle>
  <p
    [ngClass]="{
      active: currentStep === 3,
      nonactive: currentStep !== 3
    }"
  >
    Ressources
  </p>
</ng-template>

<ng-template #stepRessources>
  <mat-icon
    [ngClass]="{
      active: currentStep === 3,
      nonactive: currentStep !== 3
    }"
    >account_balance_wallet</mat-icon
  >
</ng-template>

<!-- APTITUDE -->
<ng-template #stepAptitudeTitle>
  <p
    [ngClass]="{
      active: currentStep === 4,
      nonactive: currentStep !== 4
    }"
  >
    Aptitudes
  </p>
</ng-template>

<ng-template #stepAptitude>
  <mat-icon
    [ngClass]="{
      active: currentStep === 4,
      nonactive: currentStep !== 4
    }"
    >psychology</mat-icon
  >
</ng-template>

<!-- PROFESSIONNELLE -->
<ng-template #stepProfessionnelleTitle>
  <p
    [ngClass]="{
      active: currentStep === 5,
      nonactive: currentStep !== 5
    }"
  >
    Professionnelle
  </p>
</ng-template>
<ng-template #stepProfessionnelle>
  <mat-icon
    [ngClass]="{
      active: currentStep === 5,
      nonactive: currentStep !== 5
    }"
    >business_center</mat-icon
  >
</ng-template>

<!-- RESUME -->
<ng-template #stepResumeTitle>
  <p
    [ngClass]="{
      active: currentStep === 6,
      nonactive: currentStep !== 6
    }"
  >
    Résumé
  </p>
</ng-template>
<ng-template #stepResume>
  <mat-icon
    [ngClass]="{
      active: currentStep === 6,
      nonactive: currentStep !== 6
    }"
    >summarize</mat-icon
  >
</ng-template>

<!-- ACTION -->
<ng-template #stepActionsTitle>
  <p
    [ngClass]="{
      active: currentStep === 7,
      nonactive: currentStep !== 7
    }"
  >
    Actions
  </p>
</ng-template>
<ng-template #stepActions>
  <mat-icon
    [ngClass]="{
      active: currentStep === 7,
      nonactive: currentStep !== 7
    }"
    >checklist</mat-icon
  >
</ng-template>

<!-- EVENTS -->
<ng-template #stepEventsTitle>
  <p
    [ngClass]="{
      active: currentStep === 8,
      nonactive: currentStep !== 8
    }"
  >
    Rendez-vous
  </p>
</ng-template>
<ng-template #stepEvents>
  <mat-icon
    [ngClass]="{
      active: currentStep === 8,
      nonactive: currentStep !== 8
    }"
    >calendar_month</mat-icon
  >
</ng-template>
