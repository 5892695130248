import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import {
  GeoCommune,
  GeoDepartement,
  GeoDistance,
  GeoRegion,
  GeoTerritoire,
} from './geo-territoire.model';
import * as GeoTerritoireActions from './geo-territoire.actions';

export const geoTerritoiresFeatureKey = 'geoTerritoires';

export interface GeoTerritoireState extends EntityState<GeoTerritoire> {
  loadingRegions: boolean;
  regions: any;
  region: any;
  loadingDepartements: boolean;
  departements: any;
  departement: any;
  loadingCommunes: boolean;
  communes: any;
  loadingDistance: boolean;
  distance: GeoDistance;

  error: any;
}

export const adapter: EntityAdapter<GeoTerritoire> =
  createEntityAdapter<GeoTerritoire>();

export const initialState: GeoTerritoireState = adapter.getInitialState({
  loadingRegions: false,
  regions: undefined,
  region: undefined,
  loadingDepartements: false,
  departements: undefined,
  departement: undefined,
  loadingCommunes: false,
  communes: undefined,
  loadingDistance: false,
  distance: { distanceKilometer: 0.0, distanceMeter: 0, distanceText: '' },

  error: undefined,
});

export const reducer = createReducer(
  initialState,
  //LOAD REGIONS
  on(GeoTerritoireActions.loadGeoTerritoireRegions, (state, action) => {
    return {
      ...state,
      loadingRegions: true,
      regions: undefined,
      region: undefined,
      departements: undefined,
      departement: undefined,
      communes: undefined,
      error: undefined,
    };
  }),
  on(GeoTerritoireActions.loadGeoTerritoireRegionsSuccess, (state, action) => {
    return {
      ...state,
      regions: action.regions,
      region: undefined,
      departements: undefined,
      departement: undefined,
      communes: undefined,

      loadingRegions: false,
      error: undefined,
    };
  }),

  on(GeoTerritoireActions.loadGeoTerritoireRegionsFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      regions: undefined,
      region: undefined,
      departements: undefined,
      departement: undefined,
      communes: undefined,

      loadingRegions: action.error,
    };
  }),

  //LOAD DEPARTEMENTS
  on(GeoTerritoireActions.loadGeoTerritoireDepartements, (state, action) => {
    return {
      ...state,
      loadingDepartements: true,
      region: undefined,
      departement: undefined,
      departements: undefined,
      commune: undefined,
      communes: undefined,
      error: undefined,
    };
  }),
  on(
    GeoTerritoireActions.loadGeoTerritoireDepartementsSuccess,
    (state, action) => {
      return {
        ...state,
        region: action.region,
        departements: action.region.departements,
        communes: undefined,

        loadingDepartements: false,
        error: undefined,
      };
    }
  ),

  on(
    GeoTerritoireActions.loadGeoTerritoireDepartementsFailure,
    (state, action) => {
      return {
        ...state,
        loadingDepartements: false,
        regions: undefined,
        region: undefined,
        departement: undefined,
        departements: undefined,
        communes: undefined,
        error: action.error,
      };
    }
  ),

  //LOAD COMMUNES
  on(GeoTerritoireActions.loadGeoTerritoireCommunes, (state, action) => {
    return {
      ...state,
      loadingCommunes: true,
      commune: undefined,
      communes: undefined,
      error: undefined,
    };
  }),
  on(GeoTerritoireActions.loadGeoTerritoireCommunesSuccess, (state, action) => {
    return {
      ...state,
      commune: action.communes,
      communes: action.communes,
      loadingCommunes: false,
      error: undefined,
    };
  }),
  on(GeoTerritoireActions.loadGeoTerritoireCommunesFailure, (state, action) => {
    return {
      ...state,
      loadingCommunes: false,
      commune: undefined,
      communes: undefined,
      error: action.error,
    };
  }),

  // DISTANCE
  //LOAD COMMUNES
  on(GeoTerritoireActions.loadGeoDistance, (state, action) => {
    return {
      ...state,
      loadingDistance: true,
      distance: { distanceKilometer: 0.0, distanceMeter: 0, distanceText: '' },
      error: undefined,
    };
  }),
  on(GeoTerritoireActions.loadGeoDistanceSuccess, (state, action) => {
    return {
      ...state,
      distance: action.distance,
      loadingDistance: false,
      error: undefined,
    };
  }),
  on(GeoTerritoireActions.loadGeoDistanceFailure, (state, action) => {
    return {
      ...state,
      loadingDistance: false,
      distance: { distanceKilometer: 0.0, distanceMeter: 0, distanceText: '' },
      error: action.error,
    };
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
