<div class="container" *ngIf="user$ | async as user">
  <!-- <ng-container *ngIf="filters$ | async as filters">
      <nz-row nzAlign="top" nzGutter="2" nzJustify="center">
        <nz-col nzSpan="6"></nz-col>
        <nz-col nzSpan="4">
          <nz-select
            nzBorderless
            [(ngModel)]="typeSelectors"
            (ngModelChange)="selectType($event)"
          >
            <nz-option
              *ngFor="let type of dashboardType$ | async"
              nzCustomContent
              [nzValue]="type"
              [nzLabel]="type"
            >
              <nz-tag [nzColor]="type === 'Entité' ? 'purple' : 'magenta'">
                {{ type }}
              </nz-tag>
            </nz-option>
          </nz-select>
        </nz-col>
  
        <nz-col nzSpan="4">
          <nz-select
            nzBorderless
            [(ngModel)]="yearsSelectors"
            (ngModelChange)="selectYear($event)"
          >
            <nz-option
              *ngFor="let year of filters.filters.years"
              nzCustomContent
              [nzValue]="year"
              [nzLabel]="year"
            >
              <nz-tag [nzColor]="year === actualYear ? 'purple' : 'default'">
                {{ year }}
              </nz-tag>
            </nz-option>
          </nz-select>
        </nz-col>
        <nz-col nzSpan="2">
          <button
            nz-button
            nzType="text"
            nz-dropdown
            nzTrigger="click"
            nz-tooltip
            nzTooltipTitle="Ajouter un objectif annuel"
            nzTooltipPlacement="bottom"
            [nzClickHide]="objectifVisibility__ENTITE"
            nzTooltipMouseEnterDelay="1"
            [nzDropdownMenu]="objectifMenu"
          >
            <i nz-icon nzType="plus-circle" class="iconSelectionHeader"></i>
            <h4 nz-typography>Objectif</h4>
          </button>
          <nz-dropdown-menu #objectifMenu="nzDropdownMenu">
            <ul nz-menu>
              <li nz-menu-item>
                <nz-input-number
                  [(ngModel)]="objectif__PARTICIPANT"
                  nzPlaceHolder="Objectif..."
                  nzMin="0"
                ></nz-input-number>
                <button
                  nz-button
                  nzShape="circle"
                  *ngIf="objectif__ENTITE"
                  (click)="add_participant_objectif()"
                >
                  <i nz-icon nzType="plus-circle"></i>
                </button>
              </li>
            </ul>
          </nz-dropdown-menu>
        </nz-col>
        <nz-col nzSpan="4">
          <nz-button-group>
            <ng-container *ngIf="statistiquesYear$ | async as statistiquesYear">
              <button
                nzType="text"
                nz-tooltip
                nzTooltipTitle="Exporter les statistiques annuelles"
                nzTooltipPlacement="bottom"
                nz-button
                nz-dropdown
                [nzDropdownMenu]="exportListMenu"
                nzTooltipMouseEnterDelay="1"
                nzTrigger="click"
                nzPlacement="bottomRight"
              >
                <i
                  nz-icon
                  nzTheme="outline"
                  nzType="download"
                  class="iconSelectionHeader"
                ></i>
              </button>
  
              <nz-dropdown-menu #exportListMenu="nzDropdownMenu">
                <ul nz-menu>
                  <li
                    nz-menu-item
                    *ngFor="let item of exportList"
                    [nzDisabled]="item.disabled"
                    (click)="
                      onExport(item.name, yearsSelectors, statistiquesYear)
                    "
                  >
                    <i nz-icon [nzType]="item.icon"></i>
                    <span>{{ item.name }}</span>
                  </li>
                </ul>
              </nz-dropdown-menu>
            </ng-container>
          </nz-button-group>
        </nz-col>
        <nz-col nzSpan="4">
          <small *ngIf="participantsDateUpdate">
            Dernière mise à jour :
            {{ participantsDateUpdate | dateFr: 'small' }}
          </small>
        </nz-col>
      </nz-row>
    </ng-container> -->

  <div class="container" *ngIf="statistiques$ | async; else noData">
    <nz-row nzJustify="center" nzAlign="middle" nzGutter="0">
      <nz-col nzSpan="6" class="indicators">
        <nz-card nzBorderless>
          <app-charts-indicators
            [total]="participantsTotal"
            [objectif]="participantsObjectif"
            [type]="'Participants'"
          ></app-charts-indicators>
        </nz-card>
      </nz-col>
      <nz-col nzSpan="18">
        <app-charts [source]="byMonths$"></app-charts>
      </nz-col>
    </nz-row>

    <nz-row nzJustify="center" nzAlign="middle" nzGutter="4">
      <nz-col nzSpan="6">
        <app-charts [source]="sexes$"></app-charts>
      </nz-col>
      <nz-col nzSpan="6">
        <app-charts [source]="tranches$"></app-charts>
      </nz-col>
      <nz-col nzSpan="6">
        <app-charts [source]="nationality$"></app-charts>
      </nz-col>
      <nz-col nzSpan="6">
        <app-charts [source]="rqth$"></app-charts>
      </nz-col>
    </nz-row>

    <nz-row nzJustify="center" nzAlign="middle" nzGutter="4">
      <nz-col nzSpan="12">
        <app-charts [source]="typeSorties$"></app-charts>
      </nz-col>
      <nz-col nzSpan="12">
        <app-charts [source]="motifSorties$"></app-charts>
      </nz-col>
    </nz-row>

    <nz-row nzJustify="center" nzAlign="middle" nzGutter="4">
      <nz-col nzSpan="12">
        <app-charts [source]="typeSejours$"></app-charts>
      </nz-col>
      <nz-col nzSpan="12">
        <app-charts [source]="titreSejours$"></app-charts>
      </nz-col>
    </nz-row>

    <nz-row nzJustify="center" nzAlign="middle" nzGutter="4">
      <nz-col nzSpan="12">
        <app-charts [source]="situationFamiliale$"></app-charts>
      </nz-col>
      <nz-col nzSpan="12">
        <app-charts [source]="droitDeTravail$"></app-charts>
      </nz-col>
    </nz-row>

    <nz-row nzJustify="center" nzAlign="middle" nzGutter="4">
      <nz-col nzSpan="12">
        <app-charts [source]="qualificationsNiveau$"></app-charts>
      </nz-col>
      <nz-col nzSpan="12">
        <app-charts [source]="qualificationsTitres$"></app-charts>
      </nz-col>
    </nz-row>

    <nz-row nzJustify="center" nzAlign="middle" nzGutter="4">
      <nz-col nzSpan="12">
        <app-charts [source]="missionsLocales$"></app-charts>
      </nz-col>
      <nz-col nzSpan="12">
        <app-charts [source]="poleEmploi$"></app-charts>
      </nz-col>
    </nz-row>

    <nz-row nzJustify="center" nzAlign="middle" nzGutter="4">
      <nz-col nzSpan="12">
        <app-charts [source]="contexts$"></app-charts>
      </nz-col>
      <nz-col nzSpan="12">
        <app-charts [source]="departements$"></app-charts>
      </nz-col>
    </nz-row>

    <nz-row nzJustify="center" nzAlign="middle" nzGutter="4">
      <nz-col nzSpan="12">
        <app-charts [source]="communes$"></app-charts>
      </nz-col>
      <nz-col nzSpan="12"> </nz-col>
    </nz-row>

    <nz-row nzJustify="center" nzAlign="middle" nzGutter="4">
      <nz-col nzSpan="12">
        <app-charts [source]="maitriseFRItems$"></app-charts>
      </nz-col>
      <nz-col nzSpan="12">
        <app-charts [source]="maitriseFRType$"></app-charts>
      </nz-col>
    </nz-row>

    <nz-row nzJustify="center" nzAlign="middle" nzGutter="4">
      <nz-col nzSpan="12">
        <app-charts [source]="accesInternet$"></app-charts>
      </nz-col>
      <nz-col nzSpan="12">
        <app-charts [source]="accesMobile$"></app-charts>
      </nz-col>
    </nz-row>

    <nz-row nzJustify="center" nzAlign="middle" nzGutter="4">
      <nz-col nzSpan="12">
        <app-charts [source]="accessLogement$"></app-charts>
      </nz-col>
      <nz-col nzSpan="12"> </nz-col>
    </nz-row>

    <nz-row nzJustify="center" nzAlign="middle" nzGutter="4">
      <nz-col nzSpan="24">
        <app-charts [source]="criteres$"></app-charts>
      </nz-col>
    </nz-row>
  </div>
</div>

<ng-template #noData>
  <nz-empty nzNotFoundImage="simple"></nz-empty>
</ng-template>
