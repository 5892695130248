<nz-card
  nzBorderless
  nzHoverable
  [nzCover]="layoutListItemCardCoverTpl"
  [ngStyle]="{ height: cardHeigth }"
>
</nz-card>
<ng-template #layoutListItemCardCoverTpl>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="1">
      <ng-container
        *ngTemplateOutlet="icon ? icon : layoutListItemIconTpl"
      ></ng-container>
      <ng-template #layoutListItemIconTpl>
        <mat-icon>short_text</mat-icon>
      </ng-template>
    </nz-col>
    <nz-col nzSpan="18">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </nz-col>
    <nz-col nzSpan="3">
      <ng-container *ngTemplateOutlet="actions"></ng-container>
    </nz-col>
  </nz-row>
</ng-template>
