import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { Timestamp } from 'firebase/firestore';
import { BehaviorSubject, Observable, Subject, of, takeUntil } from 'rxjs';
import { UtilitiesState } from 'src/app/contents/components/utilities/store/utilities.reducer';
import * as fromUtilitiesSelector from 'src/app/contents/components/utilities/store/utilities.selectors';
import * as fromUtilitiesAction from 'src/app/contents/components/utilities/store/utilities.actions';

import {
  NzNotificationPlacement,
  NzNotificationService,
} from 'ng-zorro-antd/notification';
import { addBusinessDays, fromUnixTime } from 'date-fns';
import { omit } from 'lodash';

const NOTIFICATION_PLACEMENT: NzNotificationPlacement = 'bottomRight';

@Component({
  selector: 'app-diffusion-form',
  templateUrl: './diffusion-form.component.html',
  styleUrls: ['./diffusion-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiffusionFormComponent implements OnInit, OnChanges, OnDestroy {
  civilite$: Observable<any> = this.utilitiesStore.select(
    fromUtilitiesSelector.civilities
  );
  subscribe = new Subject();
  informationComplementaireUpdate$ = new BehaviorSubject<string>('');
  contactsUpdate$ = new BehaviorSubject<any[]>([]);
  @Input() diffusion$ = new BehaviorSubject<any>(null);
  @Input() isAdd: boolean = true;
  @Input() isUpdate: boolean = false;
  @Input() users$: Observable<any> = of();
  @Input() isDateNaissance: boolean = false;
  @Input() isEtablissement: boolean = false;
  @Input() isAdresse: boolean = false;

  @Input() destinataireType$ = new BehaviorSubject<string[]>([
    'PRESCRIPTEURS',
    'ENTREPRISE',
  ]);

  @Output() add = new EventEmitter();
  @Output() update = new EventEmitter();
  @Output() cancel = new EventEmitter();
  currentStep: number = 0;
  currentDate: Date = new Date(Date.now());
  adresse = new FormControl({});
  operation = new FormControl({});
  contacts = new FormControl<any[]>([], Validators.required);
  title = new FormControl('Appel à candidature', Validators.required);
  destinataireType = new FormControl('PRESCRIPTEURS', Validators.required);
  endAt = new FormControl(
    addBusinessDays(this.currentDate, 6),
    Validators.required
  );
  recrutementBy = new FormControl<{
    id: string;
    cooordonnees: {
      mobile: string;
      fixe: string;
      phoneNumber: string;
      email: string;
    };
    displayName: string;
    fonction: string;
    avatar: string;
    photo: string;
    searchFields: string[];
    service: { id: string; title: string };
    setting: { color: string };
  }>({
    id: '',
    cooordonnees: { mobile: '', fixe: '', phoneNumber: '', email: '' },
    displayName: '',
    fonction: '',
    avatar: '',
    photo: '',
    searchFields: [],
    service: { id: '', title: '' },
    setting: { color: '' },
  });

  informationComplementaire = new FormControl<{
    textHTML: string;
    textValue: string;
  }>({
    textHTML: '',
    textValue: '',
  });
  profilNumber = new FormControl(2);

  diffusionForm = this.fb.group({
    title: this.title,
    destinataireType: this.destinataireType,
    endAt: this.endAt,
    contacts: this.contacts,
    profilNumber: this.profilNumber,
    informationComplementaire: this.informationComplementaire,
    isClosed: new FormControl(false),
    recrutementBy: this.recrutementBy,
    operation: this.operation,
    adresse: this.adresse,
    entiteDenomination: new FormControl(''),
    entiteSiret: new FormControl(''),
    metierLibelle: new FormControl(''),
    metierRome: new FormControl(''),
    grangDomaineLibelle: new FormControl(''),
    domaineLibelle: new FormControl(''),
    contratTypeLibelle: new FormControl(''),
    contratNatureLibelle: new FormControl(''),
    missionStart: new FormControl({}),
    missionEnd: new FormControl({}),
  });

  constructor(
    private fb: FormBuilder,
    private utilitiesStore: Store<UtilitiesState>,
    private notificationService: NzNotificationService
  ) {}

  ngOnInit(): void {
    this.loadUtilitiesCivilite();
    this.onUpdateDiffusion();
  }
  ngOnChanges(changes: SimpleChanges): void {}

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  loadUtilitiesCivilite(): void {
    this.utilitiesStore.dispatch(fromUtilitiesAction.loadCivilities());
  }

  onSelectUser(user: any): void {
    if (!user) return;

    this.diffusionForm.patchValue({ recrutementBy: user });
  }

  compareSelect(item1: any, item2: any): any {
    return item1 === item2;
  }

  onStepBack(): void {
    this.currentStep = this.currentStep > 0 ? this.currentStep - 1 : 0;
  }
  onStepNext(): void {
    this.currentStep = +1;

    const contacts: any[] = this.diffusionForm.get('contacts')?.value as any[];
    this.contactsUpdate$.next(contacts);
  }

  onAddContacs(items: any[]): void {
    if (!items?.length) return;

    this.diffusionForm.patchValue({ contacts: items });
  }

  onUpdateDiffusion(): void {
    this.diffusion$
      .pipe(takeUntil(this.subscribe))
      .subscribe((diffusion: any) => {
        if (!diffusion) {
          this.isAdd = true;
          this.isUpdate = false;
          this.contactsUpdate$.next([]);
          this.informationComplementaireUpdate$.next('');
          return;
        }

        this.isAdd = false;
        this.isUpdate = true;
        const diffusionInput = omit(diffusion, [
          'dateStart',
          'dateUpdate',
          'auteur',
          'userUpdate',
        ]);
        const endAtToDate: Date = fromUnixTime(diffusionInput.endAt['seconds']);
        this.informationComplementaireUpdate$.next(
          diffusionInput?.informationComplementaire?.textHTML
        );
        const { contacts } = diffusionInput;
        this.contactsUpdate$.next(contacts);
        this.diffusionForm.patchValue({
          ...diffusionInput,
          endAt: endAtToDate,
        });
      });
  }

  onComplementInformation(event: any): void {
    this.informationComplementaireUpdate$.next(event?.textHTML);
    this.diffusionForm.patchValue({ informationComplementaire: event });
  }

  onAdd(): void {
    if (!this.diffusionForm.valid) return;

    if (!this.diffusionForm.value.contacts?.length) {
      const message: string = "Aucun destinataire n'a été ajouté";
      this.notificationService.info('Information', message, {
        nzDuration: 6000,
        nzPlacement: NOTIFICATION_PLACEMENT,
        nzAnimate: true,
      });
    }

    const value = this.diffusionForm.value;
    const endAt: Date = value?.endAt as Date;
    const endAtTimestapm = Timestamp.fromDate(endAt);
    const diffusion = {
      ...value,
      endAt: endAtTimestapm,
    };

    this.add.emit(diffusion);
  }

  onUpdate(diffusionUpdate: any): void {
    if (!diffusionUpdate && !this.diffusionForm.valid) return;
    if (!diffusionUpdate && !this.diffusionForm.value.contacts?.length) {
      const message: string = "Aucun destinataire n'a été ajouté";
      this.notificationService.info('Information', message, {
        nzDuration: 6000,
        nzPlacement: NOTIFICATION_PLACEMENT,
        nzAnimate: true,
      });
    }

    const formValue = this.diffusionForm.value;
    const endAt: Date = formValue?.endAt as Date;

    const endAtTimestapm = Timestamp.fromDate(endAt);

    const diffusion = {
      ...diffusionUpdate,
      ...formValue,
      endAt: endAtTimestapm,
    };
    this.update.emit(diffusion);
  }

  onCancel(): void {
    this.cancel.emit(true);
  }
}
