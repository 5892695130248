import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuivisComponent } from './components/suivis/suivis.component';
import { TableSuivisComponent } from './components/table-suivis/table-suivis.component';
import { SuiviTypeComponent } from './components/suivi-type/suivi-type.component';
import { SuiviItemComponent } from './components/suivi-item/suivi-item.component';
import { NoResultModule } from 'src/app/contents/components/no-result/no-result.module';
import { ButtonsModule } from 'src/app/contents/components/buttons/buttons.module';
import { PipeModule } from 'src/app/pipe/pipe.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/css/material.module';
import { ZorroModule } from 'src/app/css/zorro.module';
import { ItemEventService } from 'src/app/service/item-event.service';
import { HeaderModule } from 'src/app/contents/components/header-title/header.module';
import { AvatarModule } from '../avatar/avatar.module';
import { LayoutModule } from 'src/app/contents/components/layout/layout.module';
import { ContentsModule } from 'src/app/contents/contents.module';
import { ItemEventComponent } from './components/item-event/item-event.component';
import { TextEdithingModule } from '../text-edithing/text-edithing.module';
import { TableModule } from '../table/table.module';
import { UiModule } from 'src/app/ui/ui/ui.module';

@NgModule({
  declarations: [
    SuivisComponent,
    TableSuivisComponent,
    SuiviTypeComponent,
    SuiviItemComponent,
    ItemEventComponent,
  ],
  imports: [
    CommonModule,
    ZorroModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    PipeModule,
    ButtonsModule,
    NoResultModule,
    HeaderModule,
    AvatarModule,
    LayoutModule,
    ContentsModule,
    TextEdithingModule,
    TableModule,
    UiModule,
  ],
  exports: [
    SuivisComponent,
    TableSuivisComponent,
    SuiviTypeComponent,
    SuiviItemComponent,
    ItemEventComponent,
  ],
  providers: [ItemEventService],
})
export class SuiviModule {}
