import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-entite-search-item',
  templateUrl: './entite-search-item.component.html',
  styleUrls: ['./entite-search-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntiteSearchItemComponent implements OnInit, OnChanges {
  @Input() entite: any;
  @Input() btnTitle: string = 'Valider';
  @Input() isValidation: boolean = true;
  @Input() isConventions: boolean = true;
  @Input() isAdd: boolean = false;
  @Input() isCardLabel: boolean = true;

  @Output() clear = new EventEmitter();
  @Output() validate = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {}

  onClean(): void {
    this.clear.emit(true);
  }

  onValidate(entite: any): void {
    this.validate.emit(entite);
  }
}
