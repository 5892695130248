import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { ParticipantState } from 'src/app/components/participant/store/participant.reducer';
import * as fromParticipantSelector from 'src/app/components/participant/store/participant.selectors';

import { ParticipantDiagnosticState } from '../../store/participant-diagnostic.reducer';
import * as fromParticipantDiagnosticSelector from '../../store/participant-diagnostic.selectors';

@Component({
  selector: 'app-participant-diagnostic-item-social',
  templateUrl: './participant-diagnostic-item-social.component.html',
  styleUrls: ['./participant-diagnostic-item-social.component.scss'],
})
export class ParticipantDiagnosticItemSocialComponent implements OnInit {
  diagnostic$: Observable<any> = of(null);
  participant$: Observable<any> = of(null);

  default: string = '#bfbfbf';
  colorValidation: string = '#85a5ff';
  colorAlert: string = '#ff7875';
  avatarBackgroundColor: string = '#1890ff';
  colorActive: string = '#52c41a';
  tooltipMouseEnterDelay: number = 0.5;

  constructor(
    private participantDiagnosticStore: Store<ParticipantDiagnosticState>,
    private participantStore: Store<ParticipantState>
  ) {}

  ngOnInit(): void {
    this.getParticipant();
    this.getParticipantDiagnostic();
  }

  getParticipant(): void {
    this.participant$ = this.participantStore.select(
      fromParticipantSelector.participant
    );
  }

  getParticipantDiagnostic(): void {
    this.diagnostic$ = this.participantDiagnosticStore.select(
      fromParticipantDiagnosticSelector.diagnostic
    );
  }
}
