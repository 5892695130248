<form nz-form [formGroup]="actionAccompagnementForm">
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="12">
      <nz-form-item>
        <nz-form-label nzSpan="24" nzRequired>Type</nz-form-label>
        <nz-form-control>
          <nz-select
            nzPlaceHolder="Type d'accompagnement"
            nzBorderless
            formControlName="type"
            nzServerSearch
            [nzDisabled]="(types$ | async) ? false : true"
          >
            <nz-option
              *ngFor="let item of types$ | async"
              [nzLabel]="item | titlecase"
              [nzValue]="item"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col nzSpan="12">
      <nz-form-item>
        <nz-form-label nzSpan="24" nzRequired>Dates</nz-form-label>
        <nz-form-control>
          <nz-range-picker
            nzBorderless
            [(ngModel)]="dates"
            [nzFormat]="dateFormat"
            [nzPlaceHolder]="['Démarrage', 'Échéance']"
            (ngModelChange)="onChangeDates($event)"
            [ngModelOptions]="{ standalone: true }"
          ></nz-range-picker>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="12">
      <nz-form-item>
        <nz-form-label nzSpan="24" nzRequired>Champs</nz-form-label>
        <nz-form-control>
          <nz-select
            nzPlaceHolder="Champs d'accompagnement"
            nzBorderless
            formControlName="champs"
            nzServerSearch
            [nzDisabled]="(champs$ | async) ? false : true"
          >
            <nz-option
              *ngFor="let item of champs$ | async"
              [nzLabel]="item.libelle"
              [nzValue]="item"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col nzSpan="12">
      <nz-form-item>
        <nz-form-label nzSpan="24" nzRequired>Objectif</nz-form-label>
        <nz-form-control>
          <input
            nz-input
            formControlName="objectif"
            nzBorderless
            placeholder="Définissez l'objectif de cette action"
          />
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>

  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="24">
      <nz-form-item>
        <nz-form-label nzSpan="24">Description</nz-form-label>
        <nz-form-control>
          <textarea
            nz-input
            [nzAutosize]="{ minRows: 4, maxRows: 4 }"
            formControlName="description"
            nzBorderless
            placeholder="Décrivez l'objet de l'accompagnement..."
          >
          </textarea>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>

  <nz-divider nzOrientation="center" nzGutter="horizontal"></nz-divider>

  <nz-row nzAlign="middle" nzGutter="8" nzJustify="end">
    <nz-col nzSpan="12"> </nz-col>
    <nz-col nzSpan="4">
      <button nz-button nzBlock nzType="text" (click)="onCancel()">
        Annuler
      </button>
    </nz-col>
    <nz-col nzSpan="4">
      <button nz-button nzBlock nzType="primary" (click)="onAdd()">
        Ajouter
      </button>
    </nz-col>
  </nz-row>
</form>
