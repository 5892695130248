import { Update } from '@ngrx/entity';
import {
  UtilitiesIds,
  UtilitiesEntityVariables,
  UtilitiesParticipantVariables,
} from './../../../../contents/components/utilities/store/utilities.model';
import { Coordonnees } from './../../../../contents/components/coordonnees/coordonnees.model';
import { takeUntil } from 'rxjs/operators';
import { Contact, ContactId } from './../../store/contact.model';
import {
  UntypedFormGroup,
  UntypedFormControl,
  UntypedFormArray,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import {
  Component,
  OnDestroy,
  OnInit,
  EventEmitter,
  Output,
} from '@angular/core';
import {
  EntiteId,
  EntiteSMALLid,
} from 'src/app/components/entite/store/entite.model';
import { Subject, BehaviorSubject, Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { ContactState } from './../../store/contact.reducer';
import * as fromContactAction from './../../store/contact.actions';
import * as fromContactSelector from './../../store/contact.selectors';
import { Timestamp } from '@firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import { capitalize, trim } from 'lodash';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent implements OnInit, OnDestroy {
  subscribe = new Subject();
  isAdd: boolean = true;
  isUpdate: boolean = false;

  contact$: Observable<ContactId | any> = of(null);
  itemUpdateContactEntite: string = '';
  civiliteUtilitySelected: string = '';

  //CONTACT
  title: string = '';
  contactForm: UntypedFormGroup = this.fb.group({});
  auths: UntypedFormControl = new UntypedFormControl();
  civilite: UntypedFormControl = new UntypedFormControl();
  firstName: UntypedFormControl = new UntypedFormControl();
  lastName: UntypedFormControl = new UntypedFormControl();
  fonction: UntypedFormControl = new UntypedFormControl();
  sexe: UntypedFormControl = new UntypedFormControl();
  mobile: UntypedFormControl = new UntypedFormControl();
  phoneNumber: UntypedFormControl = new UntypedFormControl();
  fixe: UntypedFormControl = new UntypedFormControl();
  mail: UntypedFormControl = new UntypedFormControl();
  web: UntypedFormControl = new UntypedFormControl();
  reseaux: UntypedFormArray = this.fb.array([]);
  benevolatHours: UntypedFormControl = new UntypedFormControl();
  adresse: UntypedFormControl = new UntypedFormControl();
  complement: UntypedFormControl = new UntypedFormControl();
  qpv: UntypedFormControl = new UntypedFormControl();
  entites: UntypedFormControl = new UntypedFormControl();
  entitesIds: UntypedFormControl = new UntypedFormControl();
  entitesTitles: UntypedFormControl = new UntypedFormControl();
  entitesMapIds: UntypedFormControl = new UntypedFormControl();
  events: UntypedFormControl = new UntypedFormControl();
  operations: UntypedFormControl = new UntypedFormControl();
  service: UntypedFormControl = new UntypedFormControl();
  dateStart: UntypedFormControl = new UntypedFormControl();
  dateUpdate: UntypedFormControl = new UntypedFormControl();
  dateSelected: UntypedFormControl = new UntypedFormControl();
  notes: UntypedFormControl = new UntypedFormControl();
  entite: UntypedFormControl = new UntypedFormControl();
  organisationId: UntypedFormControl = new UntypedFormControl();
  userSelected: UntypedFormControl = new UntypedFormControl();
  departements: UntypedFormControl = new UntypedFormControl();
  utilisateur: UntypedFormControl = new UntypedFormControl();
  contrats: UntypedFormControl = new UntypedFormControl();
  evaluations: UntypedFormControl = new UntypedFormControl();
  conges: UntypedFormControl = new UntypedFormControl();
  rtt: UntypedFormControl = new UntypedFormControl();
  auteur: UntypedFormControl = new UntypedFormControl();
  userUpdate: UntypedFormControl = new UntypedFormControl();
  actif: UntypedFormControl = new UntypedFormControl();
  stats: UntypedFormControl = new UntypedFormControl();
  competences: UntypedFormControl = new UntypedFormControl();
  thematiques: UntypedFormControl = new UntypedFormControl();
  groups: UntypedFormControl = new UntypedFormControl();
  participants: UntypedFormControl = new UntypedFormControl();
  benevolat: UntypedFormControl = new UntypedFormControl();
  disponibility: UntypedFormControl = new UntypedFormControl();
  interets: UntypedFormArray = this.fb.array([]);
  metiersThemes: UntypedFormControl = new UntypedFormControl();
  metiersDomainesArray: UntypedFormControl = new UntypedFormControl();
  metiersDomainesMap: UntypedFormControl = new UntypedFormControl();
  metiersGrandDomainesArray: UntypedFormControl = new UntypedFormControl();
  metiersGrandDomainesMap: UntypedFormControl = new UntypedFormControl();
  metiersArray: UntypedFormControl = new UntypedFormControl();
  metiersMap: UntypedFormControl = new UntypedFormControl();
  appelationsArray: UntypedFormControl = new UntypedFormControl();
  appelationsMap: UntypedFormControl = new UntypedFormControl();
  isParrain: UntypedFormControl = new UntypedFormControl(false);

  utilitairesId: UtilitiesIds = UtilitiesIds.participant;
  utilitaireReseau = UtilitiesParticipantVariables.reseauxSociaux;
  utilitaireCivilite = UtilitiesParticipantVariables.civility;
  utilitaireSexe = UtilitiesParticipantVariables.sexe;

  adresseInput$ = new Subject() || null;

  adresseEdit = false;
  selectedAdresse: any;
  adresses: any[] = [];

  dateStartDateEnd: Date[] = [];
  adresseComplementEdit = false;
  selectedAdresseComplement: any;

  adresseUpdate = new BehaviorSubject<any>(null);
  adresseComplementUpdate = new BehaviorSubject<any>(null);
  coordonneesUpdate = new BehaviorSubject<any>(null);

  updateCoordonnees = new BehaviorSubject<Coordonnees | any>(null);
  updateAdresse = new BehaviorSubject<any>(null);
  updateAdresseComplement = new BehaviorSubject<any>(null);

  resaeauSociauxVisible = false;
  itemResaeauSociauxVisible = false;
  validateForm!: UntypedFormGroup;
  listOfControlReseaux: Array<{
    id: any;
    type: string;
    value: string;
  }> = [];

  @Output() cancel = new EventEmitter<boolean>();
  @Output() add = new EventEmitter<Contact | any>(false);
  @Output() update = new EventEmitter<ContactId | any>(false);

  constructor(
    private fb: UntypedFormBuilder,
    private contactStore: Store<ContactState>
  ) {}

  ngOnInit(): void {
    this.form();
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getContact(): void {
    this.contact$ = this.contactStore.select(fromContactSelector.contact);
  }

  form(): void {
    this.formInit();
    this.formCreate();
    this.formSet();
  }

  formInit() {
    this.auths = new UntypedFormControl([]);
    this.civilite = new UntypedFormControl('Mme', Validators.required);
    this.firstName = new UntypedFormControl('', Validators.required);
    this.lastName = new UntypedFormControl('', Validators.required);
    this.sexe = new UntypedFormControl('Féminin', Validators.required);
    this.fonction = new UntypedFormControl('');
    this.mobile = new UntypedFormControl('');
    this.benevolat = new UntypedFormControl(false);
    this.phoneNumber = new UntypedFormControl('');
    this.fixe = new UntypedFormControl('');
    this.mail = new UntypedFormControl('');
    this.web = new UntypedFormControl('');
    this.reseaux = this.fb.array([]);
    this.entites = new UntypedFormControl();
    this.entitesIds = new UntypedFormControl([]);
    this.entitesTitles = new UntypedFormControl([]);
    this.entitesMapIds = new UntypedFormControl({});

    this.adresse = new UntypedFormControl('');
    this.complement = new UntypedFormControl('');
    this.qpv = new UntypedFormControl('');
    this.benevolatHours = new UntypedFormControl([]);
    this.events = new UntypedFormControl([]);
    this.notes = new UntypedFormControl([]);
    this.service = new UntypedFormControl('');
    this.dateStart = new UntypedFormControl(Timestamp.now(), Validators.required);
    this.dateUpdate = new UntypedFormControl(Timestamp.now(), Validators.required);
    this.dateSelected = new UntypedFormControl();
    this.entite = new UntypedFormControl('');

    this.departements = new UntypedFormControl([]);
    this.organisationId = new UntypedFormControl([]);
    this.utilisateur = new UntypedFormControl(false);
    this.contrats = new UntypedFormControl([]);
    this.auteur = new UntypedFormControl();
    this.userSelected = new UntypedFormControl({});
    this.userUpdate = new UntypedFormControl({});
    this.stats = new UntypedFormControl({});
    this.actif = new UntypedFormControl(true, Validators.required);
    this.competences = new UntypedFormControl('');
    this.thematiques = new UntypedFormControl('');
    this.interets = this.fb.array([]);
    this.groups = new UntypedFormControl([]);
    this.participants = new UntypedFormControl([]);
    this.disponibility = new UntypedFormControl(true);

    this.metiersThemes = new UntypedFormControl();
    this.metiersDomainesArray = new UntypedFormControl();
    this.metiersDomainesMap = new UntypedFormControl();
    this.metiersGrandDomainesArray = new UntypedFormControl();
    this.metiersGrandDomainesMap = new UntypedFormControl();
    this.metiersArray = new UntypedFormControl();
    this.metiersMap = new UntypedFormControl();
    this.appelationsArray = new UntypedFormControl();
    this.appelationsMap = new UntypedFormControl();
    this.isParrain = new UntypedFormControl(false);
  }

  formCreate() {
    this.contactForm = this.fb.group({
      auths: this.auths,
      civilite: this.civilite,
      firstName: this.firstName,
      lastName: this.lastName,
      sexe: this.sexe,
      benevolat: this.benevolat,
      fonction: this.fonction,
      disponibility: this.disponibility,
      service: this.service,
      competences: this.competences,
      thematiques: this.thematiques,
      interets: this.interets,
      benevolatHours: this.benevolatHours,
      coordonnees: new UntypedFormGroup({
        mobile: this.mobile,
        phoneNumber: this.phoneNumber,
        fixe: this.fixe,
        email: this.mail,
        web: this.web,
        reseaux: this.reseaux,
      }),
      adresse: new UntypedFormGroup({
        adresse: this.adresse,
        complement: this.complement,
        qpv: this.qpv,
      }),

      events: this.events,
      notes: this.notes,
      dateStart: this.dateStart,
      dateUpdate: this.dateUpdate,
      dateSelected: this.dateSelected,
      userSelected: this.userSelected,
      userUpdate: this.userUpdate,
      isParrain: this.isParrain,
      entite: this.entite,
      entites: this.entites,
      entitesIds: this.entitesIds,
      entitesTitles: this.entitesTitles,
      entitesMapIds: this.entitesMapIds,

      stats: this.stats,
      departements: this.departements,
      auteur: this.auteur,
      actif: this.actif,
      groups: this.groups,
      participants: this.participants,

      metiersThemes: this.metiersThemes,
      metiersDomainesArray: this.metiersDomainesArray,
      metiersDomainesMap: this.metiersDomainesMap,
      metiersGrandDomainesArray: this.metiersGrandDomainesArray,
      metiersGrandDomainesMap: this.metiersGrandDomainesMap,
      metiersArray: this.metiersArray,
      metiersMap: this.metiersMap,
      appelationsArray: this.appelationsArray,
      appelationsMap: this.appelationsMap,
    });
  }

  formSet() {
    this.contact$
      .pipe(takeUntil(this.subscribe))
      .subscribe((contact: ContactId) => {
        if (!contact) {
          this.newContactForm();
        } else {
          this.fromContactUpdate();
        }
      });
  }

  fromContactUpdate(): void {
    this.contact$.pipe(takeUntil(this.subscribe)).subscribe((item): void => {
      if (!item) {
        return;
      }
      this.isUpdate = true;
      this.isAdd = false;
      const { civilite, lastName, firstName, coordonnees } = item;
      const displayName = `${civilite}. ${lastName} ${firstName}`;
      this.title = `Modifier : ${displayName}`;
      this.civiliteUtilitySelected = civilite;
      this.itemUpdateContactEntite = item.entite.nom_raison_sociale;

      this.updateCoordonnees.next(item.coordonnees);
      this.updateAdresse.next(item.adresse.adresse);
      this.updateAdresseComplement.next(item.adresse.complement);

      coordonnees.reseaux.forEach((reseau: any) => {
        this.setReseaux(reseau);
      });

      this.contactForm.patchValue({
        ...item,
      });
    });
  }

  newContactForm(): void {
    // this.addEntite
    //   .pipe(takeUntil(this.subscribe))
    //   .subscribe((selected_ENTITE) => {
    //     if (selected_ENTITE) {
    //       this.add = true;
    //       this.update = false;
    //       this.title = 'Nouveau contact';
    //       this.contactForm.patchValue({
    //         entite: {
    //           id: selected_ENTITE.id,
    //           siret: selected_ENTITE.etablissement.siret,
    //           nom_raison_sociale:
    //             selected_ENTITE.etablissement.nom_raison_sociale,
    //           geo_adresse: selected_ENTITE.etablissement.geo_adresse,
    //           activite_principale_entreprise:
    //             selected_ENTITE.etablissement.activite_principale_entreprise,
    //           secteur: selected_ENTITE.custom.secteur,
    //         },
    //       });
    //     } else {
    //       this.isAdd = true;
    //       this.update = false;
    //       this.title = 'Nouveau contact';
    //     }
    //   });
  }
  //ENTITE

  onSelectEntite(entite: EntiteSMALLid): void {
    const formValue = this.contactForm?.value;
    this.contactForm.patchValue({
      entites: [entite],
      entitesIds: [entite.id],
      entitesTitles: [entite.etablissement.denomination],
      entitesMapIds: {
        [`${entite.id}`]: entite,
      },
    });
  }

  //CIVILITY
  onSelectCivilite(civilite: any): void {
    if (!civilite) {
      return;
    }
    const sexe: string =
      civilite === 'M' ? 'Masculin' : civilite === 'Mme' ? 'Féminin' : 'Autre';

    this.contactForm.patchValue({
      civilite: civilite,
      sexe: sexe,
    });
  }

  //COORDONNEES

  onMobile(event: string): void {
    this.contactForm.patchValue({
      coordonnees: {
        mobile: event,
      },
    });
  }

  onPhoneNumber(event: string): void {
    this.contactForm.patchValue({
      coordonnees: {
        phoneNumber: event,
      },
    });
  }

  onFixe(event: string): void {
    this.contactForm.patchValue({
      coordonnees: {
        fixe: event,
      },
    });
  }

  onEmail(event: string): void {
    this.contactForm.patchValue({
      coordonnees: {
        email: event,
      },
    });
  }

  get reseauArray() {
    return this.contactForm.get('coordonnees')?.get('reseaux') as UntypedFormArray;
  }

  get getEntiteControl() {
    return this.contactForm.get('entite');
  }

  onReseaux(event: any): void {
    event.stopPropagation();
    event.preventDefault();
    const reseau = this.fb.group({
      id: [uuidv4(), Validators.required],
      type: ['', Validators.required],
      value: ['', Validators.required],
    });

    this.reseaux.push(reseau);
  }

  setReseaux(reseaux: any): void {
    const reseau = this.fb.group({
      id: [reseaux.id],
      type: [reseaux.type],
      value: [reseaux.value],
    });

    this.reseaux.push(reseau);
  }

  removeReseau(i: number): void {
    this.reseauArray.removeAt(i);
  }

  onReseauxRemoveAll(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.reseaux.clear();
  }

  /* ADRESSE */
  onAdresse(event: any): void {
    this.contactForm.patchValue({
      adresse: {
        adresse: event,
      },
    });
  }

  onAdresseComplement(event: any): void {
    this.contactForm.patchValue({
      adresse: {
        complement: event,
      },
    });
  }

  compareAdresse(o1: any, o2: any) {
    return o1 === o2 ? o1.properties.label === o2.properties.label : o1 === o2;
  }

  //METIERS
  onSetGrandDomaine(item: any): void {
    this.contactForm.patchValue({
      grandDomaine: item,
    });
  }
  onSetDomaine(item: any): void {
    this.contactForm.patchValue({
      domaine: item,
    });
  }
  onSetMetier(item: any): void {
    this.contactForm.patchValue({
      metiers: item,
      metiersMap: {
        [`${item.libelle}`]: item,
      },
    });
  }
  onSetAppelation(item: any): void {
    this.contactForm.patchValue({
      appelation: item,
      appelationMap: {
        [`${item.libelle}`]: item,
      },
    });
  }

  onAdd(): void {
    const contact: any = this.contactForm.value;
    contact.lastName = trim(contact.lastName.toUpperCase());
    contact.firstName = trim(capitalize(contact.firstName));
    contact.fonction = trim(capitalize(contact.fonction));
    contact.adresse.adresse = contact.adresse.adresse?.properties
      ? contact.adresse.adresse
      : {};

    this.add.emit(contact);
    this.reseaux.clear();
    this.coordonneesUpdate.next(null);
    this.updateCoordonnees.next(null);
    this.updateAdresse.next(null);
    this.updateAdresseComplement.next(null);
    this.formInit();
    this.formCreate();
    this.formSet();
  }

  onUpdate(event: any, contact: ContactId): void {
    event.preventDefault();
    event.stopPropagation();

    const contactForm: Contact = this.contactForm.value;
    contactForm.lastName = trim(contactForm.lastName.toUpperCase());
    contactForm.firstName = trim(capitalize(contactForm.firstName));
    contactForm.fonction = trim(capitalize(contactForm.fonction));

    const changes: ContactId = { ...contact, ...contactForm };

    const contactUpdate: Update<ContactId> = {
      id: contact.id,
      changes: {
        ...changes,
      },
    };

    this.update.emit(contactUpdate);
    this.reseaux.clear();
    this.coordonneesUpdate.next(null);
    this.contactForm.reset();
    this.isUpdate = false;
  }

  onCancel() {
    this.contactForm.reset();
    this.form();
    this.cancel.emit(true);
  }
}
