import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-participant-diagnostic-card',
  templateUrl: './participant-diagnostic-card.component.html',
  styleUrls: ['./participant-diagnostic-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantDiagnosticCardComponent
  implements OnInit, OnChanges, OnDestroy
{
  subscribe = new Subject();

  @Input() diagnostic: any;
  @Output() delete = new EventEmitter<any>(false);
  constructor() {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {}

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  onItemDropdown(event: string, diagnostic: any): void {
    if (!event || !diagnostic) return;

    switch (event) {
      case 'delete':
        this.delete.next(diagnostic);
        break;

      default:
        break;
    }
  }
}
