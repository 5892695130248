import { OperationId } from './../../operation/store/operation.model';
import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { OperationEntites } from './operation-entites.model';
import { EntiteSMALLid } from '../../entite/store/entite.model';

//LOADING INDICATOR
export const loadingState = createAction(
  '[OperationEntites/API] Loading State OperationEntites',
  props<{ loading: boolean }>()
);

//LOAD
export const loadOperationEntites = createAction(
  '[OperationEntites/API] Load OperationEntites',
  props<{ operationId: string }>()
);
export const loadOperationEntitesSuccess = createAction(
  '[OperationEntites/API] Load OperationEntites Success',
  props<{ entites: any[] }>()
);
export const loadOperationEntitesFailure = createAction(
  '[OperationEntites/API] Load OperationEntites Failure',
  props<{ error: any }>()
);

export const loadOperationEntite = createAction(
  '[OperationEntites/API] Load OperationEntite',
  props<{ operationId: string; entiteId: string }>()
);
export const loadOperationEntiteSuccess = createAction(
  '[OperationEntites/API] Load OperationEntite Success',
  props<{ entite: any }>()
);
export const loadOperationEntiteFailure = createAction(
  '[OperationEntites/API] Load OperationEntite Failure',
  props<{ error: any }>()
);

//ADD
export const addOperationEntite = createAction(
  '[OperationEntites/API] Add OperationEntites',
  props<{ operation: OperationId; entite: EntiteSMALLid; intervention: any }>()
);

export const addOperationEntiteSuccess = createAction(
  '[Operation/API] Add Entite Operation Success',
  props<{ entite: any }>()
);

export const addOperationEntiteFailure = createAction(
  '[Operation/API] Add Entite Operation Failure',
  props<{ error: any }>()
);

//DELETE
export const deleteOperationEntite = createAction(
  '[OperationEntites/API] Delete OperationEntites',
  props<{ operationId: string; entiteId: string }>()
);

export const deleteOperationEntiteSuccess = createAction(
  '[OperationEntites/API] Delete OperationEntites Success',
  props<{ success: string }>()
);

export const deleteOperationEntiteFailure = createAction(
  '[Operation/API] Delete OperationEntites Failure',
  props<{ error: any }>()
);

//CLEAR

export const clearOperationEntites = createAction(
  '[OperationEntites/API] Clear OperationEntitess'
);

//UPDATE
export const updateOperationEntites = createAction(
  '[OperationEntites/API] Update OperationEntites',
  props<{ operationEntites: Update<OperationEntites> }>()
);

export const updateOperationEntitess = createAction(
  '[OperationEntites/API] Update OperationEntites',
  props<{ operationEntitess: Update<OperationEntites>[] }>()
);

//QUERY
export const queryOperationEntites = createAction(
  '[OperationEntites/COMPONENT] Query OperationEntites',
  props<{ filter: string; filterType: string; operationId: string }>()
);
export const queryOperationEntitesSuccess = createAction(
  '[OperationEntites/API] Query OperationEntites Success',
  props<{ entites: any[] }>()
);
export const queryOperationEntitesFailure = createAction(
  '[OperationEntites/API] Query OperationEntites Failure',
  props<{ error: any }>()
);
