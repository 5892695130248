<ng-container *ngIf="projetprofessionnel$ | async as projetprofessionnel">
  <i nz-icon nzType="loading" class="loading" *ngIf="loading$ | async"></i>
  <div [ngSwitch]="view$ | async">
    <div *ngSwitchCase="'list'">
      <app-layout-list
        *ngIf="entites$ | async | size; else noResults"
        [card]="card"
        [new]="new"
        [source]="entites$ | async"
      ></app-layout-list>
    </div>
    <div *ngSwitchCase="'details'"></div>
    <div *ngSwitchDefault>
      <app-layout-list
        *ngIf="entites$ | async | size; else noResults"
        [card]="card"
        [new]="new"
        [source]="entites$ | async"
      ></app-layout-list>
    </div>
  </div>

  <ng-template #new>
    <app-card-new
      [type]="'domain'"
      [title]="newCardTitle"
      [description]="newCardDescription"
      [definition]="newCardDefinition"
      [width]="'300px'"
      [height]="'280px'"
      (add)="onNew(participant, projetprofessionnel)"
      (back)="onBack()"
      (cancel)="onBack()"
    ></app-card-new>
  </ng-template>

  <ng-template #card let-item let-position="idx">
    <nz-card
      nzHoverable
      nzSize="small"
      [nzTitle]="entiteProjetproTitle"
      class="formationSuggestion_card"
      (click)="onSelect(item)"
      [nzExtra]="cardEntiteProjetproExtra"
    >
      <ng-template #entiteProjetproTitle>
        <app-header-title
          [icon]="'domaine'"
          [subtitle]="true"
          [height]="'35px'"
          [width]="'35px'"
        ></app-header-title>
      </ng-template>
      <div class="formation_title">
        {{ item?.name | carLimitation: 100 }}
      </div>
      <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>

      <div class="formation_subtitle">
        <strong>{{ item?.naf_text | carLimitation: 100 }}</strong>
      </div>
    </nz-card>
    <nz-dropdown-menu #cardExtraEntiteProjetprojetpro="nzDropdownMenu">
      <ul nz-menu>
        <li
          nz-menu-item
          nzDanger
          nz-popconfirm
          nzPopconfirmTitle="Voulez-vous retirer cette entité ?"
          nzPopconfirmPlacement="bottom"
          (nzOnConfirm)="onDelete(item, participant, projetprofessionnel)"
          (nzOnCancel)="onCancel()"
        >
          <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
            <nz-col nzSpan="6">
              <i nz-icon nzType="delete"></i>
            </nz-col>
            <nz-col nzSpan="18"> Supprimer </nz-col>
          </nz-row>
        </li>
      </ul>
    </nz-dropdown-menu>
    <ng-template #cardEntiteProjetproExtra>
      <button
        nz-button
        nzShape="circle"
        nz-dropdown
        [nzDropdownMenu]="cardExtraEntiteProjetprojetpro"
        nzTrigger="click"
        nzPlacement="bottomRight"
        nzType="text"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
    </ng-template>
  </ng-template>

  <ng-template #noResults>
    <app-no-result
      [icon]="'domaine'"
      [addtitle]="newCardDescription"
      [notitle]="'Aucun entité projet professionnel ajoutée'"
      (new)="onNew(participant, projetprofessionnel)"
    ></app-no-result>
  </ng-template>

  <ng-template #projetProfessionnelEntiteForm>
    <app-header-title [icon]="'domaine'" [title]="title"></app-header-title>
  </ng-template>
</ng-container>
