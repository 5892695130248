<nz-row nzAlign="top" nzGutter="8" nzJustify="start">
  <nz-col [nzSpan]="isLoading ? 4 : 0">
    <i nz-icon nzType="loading"></i>
  </nz-col>
  <nz-col [nzSpan]="(selectedItem$ | async) || isLoading ? 20 : 24">
    <form nz-form [formGroup]="searchForm">
      <nz-form-item>
        <nz-form-control>
          <input
            *ngIf="type === 'list'"
            nz-input
            search
            formControlName="input"
            [placeholder]="placeholder"
            nzBorderless
            [nzSize]="size"
          />

          <nz-select
            *ngIf="type === 'select'"
            formControlName="input"
            [nzPlaceHolder]="placeholder"
            nzBorderless
            nzShowSearch
            nzAllowClear
            [nzLoading]="isLoading"
            [nzSize]="size"
          >
            <nz-option
              *ngFor="let item of list"
              [nzLabel]="item.nom"
              [nzValue]="item"
              nzCustomContent
            >
              {{ item.code }} - {{ item.nom }}
            </nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </form>
  </nz-col>
  <nz-col [nzSpan]="(selectedItem$ | async) ? 4 : 0">
    <button
      nz-button
      (click)="onClean()"
      nzSize="small"
      nzShape="circle"
      class="search_etablissement_btn_clear"
    >
      <mat-icon>clear</mat-icon>
    </button>
  </nz-col>
</nz-row>
