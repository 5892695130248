import { Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  doc,
  docSnapshots,
  collectionChanges,
  getDoc,
  addDoc,
  setDoc,
  deleteDoc,
  serverTimestamp,
  DocumentData,
  Timestamp,
  query,
  where,
  arrayUnion,
  arrayRemove,
  collectionSnapshots,
  CollectionReference,
  DocumentReference,
  getDocs,
  documentId,
} from '@angular/fire/firestore';
import { Observable, of, from, EMPTY } from 'rxjs';
import { switchMap, mergeMap, map, catchError } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { UserState } from '../components/user/store/user.reducer';
import * as fromUserSelector from '../components/user/store/user.selectors';
import { v4 as uuidV4 } from 'uuid';
import { format, fromUnixTime } from 'date-fns';
import { Apollo, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'any',
})
export class ParticipantProjetproContactService {
  GET_CONTACTS_PROJET_PROFESSIONNEL = gql`
    query EntitesProjetProffessionnel(
      $code: String!
      $contract: String!
      $distance: String!
      $latitude: String!
      $longitude: String!
    ) {
      entitesProjetProffessionnel(
        code: $code
        contract: $contract
        distance: $distance
        latitude: $latitude
        longitude: $longitude
      ) {
        companies_count
        rome_code
        companies_lenggo {
          address
        }
        companies {
          name
          address
          contact_mode
          city
          distance
          headcount_text
          matched_rome_label
          alternance
          naf_text
          naf
          matched_rome_code
          siret
          lat
          lon
        }
        companies_lenggo_count
        rome_label
        total
      }
    }
  `;

  constructor(
    private db: Firestore,
    private fns: Functions,
    private userStore: Store<UserState>,
    private apollo: Apollo
  ) {}

  getContactProjetProfessionnel(
    code: string,
    contract: string,
    distance: string,
    latitude: string,
    longitude: string
  ): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_CONTACTS_PROJET_PROFESSIONNEL,
        variables: {
          code: code,
          contract: contract,
          distance: distance,
          latitude: latitude,
          longitude: longitude,
        },
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  getAll(participantId: string, projetpro: any): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !participantId || !projetpro) {
            return of([]);
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/participants/${participantId}/projetsprofessionnels/${projetpro.id}/contacts`
            );

            const changes = collectionSnapshots(dbCollection).pipe(
              map((changes) => {
                return changes.map((snap) => {
                  const data = snap.data();
                  const id = snap.id;
                  return { id, ...data };
                });
              })
            );

            return changes;
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  addOne(participantId: string, projetpro: any, contact: any): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !projetpro || !contact) {
            return of(null);
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/participants/${participantId}/projetsprofessionnels/${projetpro.id}/contacts`
            );

            const document = {
              ...contact,
              user: user,
              userUpdate: user,
              dateUpdate: serverTimestamp(),
            };

            return from(
              addDoc(dbCollection, document)
                .then((docRef) => {
                  if (docRef.id) {
                    return of(
                      `Contact ${contact?.lastName} ajouté au projet professionnel ${projetpro?.appellation?.libelle}`
                    );
                  } else {
                    return of(null);
                  }
                })
                .catch((error) => of(error))
            ).pipe(mergeMap((res) => res));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  updateOne(
    participantId: string,
    projetpro: any,
    contact: Partial<any>
  ): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !projetpro || !contact) {
            return of(null);
          } else {
            const dbDocument = doc(
              this.db,
              `organisations/${user.organisation.id}/participants/${participantId}/projetsprofessionnels/${projetpro.id}/contacts/${contact.id}`
            );

            return from(
              setDoc(dbDocument, contact, { merge: true })
                .then(() => of(`Contact ${contact?.lastName} modifié`))
                .catch((error) => of(error))
            ).pipe(mergeMap((res) => res));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  deleteOne(
    participantId: string,
    projetpro: any,
    id: string
  ): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !projetpro || !id) {
            return of(null);
          } else {
            const dbDocument = doc(
              this.db,
              `organisations/${user.organisation.id}/participants/${participantId}/projetsprofessionnels/${projetpro.id}/contacts/${id}`
            );

            return from(
              deleteDoc(dbDocument)
                .then(() => of(`Contact supprimés`))
                .catch((error) => of(error))
            ).pipe(mergeMap((res) => res));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  deleteAll(participantId: string, ids: string[]): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) {
            return of(null);
          } else {
            //CALL HTTP

            return of(null);
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }
}
