<ng-container *ngIf="entite">
  <nz-ribbon
    [nzText]="ribbonText"
    nzColor="#722ed1"
    nzPlacement="start"
    *ngIf="entite | isfavori : favoris?.data"
  >
    <nz-card
      [nzTitle]="entiteCardTitle"
      nzHoverable
      [nzExtra]="entiteActions"
      *ngIf="entite"
      [routerLink]="['/entite', entite.id]"
    >
      <div class="entiteCard__denomination">
        <strong>{{
          entite?.etablissement?.denomination | carLimitation : 40
        }}</strong>
      </div>

      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col nzSpan="24">
          <small>
            {{
              entite?.etablissement?.activite_principale_libelle?.intitule_naf
                | carLimitation : 40
            }}
          </small>
        </nz-col>
      </nz-row>

      <nz-space nzDirection="vertical" nzSize="small"></nz-space>

      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col nzSpan="24">
          <nz-tag nzColor="geekblue">
            {{ entite?.metiersDomaine?.libelle | carLimitation : 40 }}
          </nz-tag>
        </nz-col>
      </nz-row>

      <nz-space nzDirection="horizontal" [nzSize]="50"></nz-space>

      <ng-template #prefixSiret
        ><i nz-icon nzType="field-number" class="iconDescription"></i
      ></ng-template>

      <ng-template #prefixAdresse
        ><mat-icon class="iconDescriptionLocation"
          >location_on</mat-icon
        ></ng-template
      >

      <ng-template #auteutAvatar>
        <div class="avatarContainer">
          <app-avatar-group
            [source]="[entite.auteur]"
            [limit]="4"
            [size]="25"
          ></app-avatar-group>
        </div>
      </ng-template>

      <ng-template #entiteActions>
        <button
          nz-button
          nzBlock
          nzSize="default"
          nzShape="circle"
          nzType="text"
          nz-dropdown
          [nzDropdownMenu]="EntiteActionsMenu"
          nzPlacement="bottomRight"
          nzTrigger="click"
          (click)="$event.stopPropagation()"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
      </ng-template>

      <nz-dropdown-menu #EntiteActionsMenu="nzDropdownMenu">
        <ul nz-menu>
          <!-- <li nz-menu-item>
            <i nz-icon nzType="eye"></i>
            <span class="item-dropdown">Exporter la fiche entité</span>
          </li>
          <li nz-menu-divider></li> -->
          <li
            nz-menu-item
            *ngIf="entite | isfavori : favoris?.data"
            (click)="onRemoveToFavori(entite)"
          >
            <i nz-icon nzType="star"></i>
            <span class="item-dropdown">Retirer des favoris</span>
          </li>
          <li nz-menu-divider></li>
          <li
            nz-menu-item
            nzDanger
            nz-popconfirm
            [nzPopconfirmTitle]="
              'Voulez-vous supprimer ' + entite?.nom_raison_sociale + ' ?'
            "
            nzPopconfirmPlacement="bottom"
            (nzOnConfirm)="onDelete(entite)"
            (nzOnCancel)="onCancel()"
          >
            <i nz-icon nzType="delete"></i>
            <span class="item-dropdown">Supprimer</span>
          </li>
        </ul>
      </nz-dropdown-menu>
    </nz-card>
  </nz-ribbon>

  <nz-card
    [nzTitle]="entiteCardTitle"
    nzHoverable
    [nzExtra]="entiteActions"
    *ngIf="!(entite | isfavori : favoris?.data)"
    [routerLink]="['/entite', entite.id]"
  >
    <div class="entiteCard__denomination">
      <strong>{{
        entite?.etablissement?.denomination | carLimitation : 40
      }}</strong>
    </div>

    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="24">
        <small>
          {{
            entite?.etablissement?.activite_principale_libelle?.intitule_naf
              | carLimitation : 60
          }}
        </small>
      </nz-col>
    </nz-row>

    <nz-space nzDirection="vertical" nzSize="small"></nz-space>

    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="24">
        <nz-tag nzColor="geekblue">
          {{ entite?.metiersDomaine?.libelle | carLimitation : 40 }}
        </nz-tag>
      </nz-col>
    </nz-row>

    <nz-space nzDirection="horizontal" [nzSize]="50"></nz-space>

    <ng-template #entiteActions>
      <button
        nz-button
        nzBlock
        nzSize="default"
        nzShape="circle"
        nzType="text"
        nz-dropdown
        [nzDropdownMenu]="EntiteActionsMenu"
        nzPlacement="bottomRight"
        nzTrigger="click"
        (click)="$event.stopPropagation()"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
    </ng-template>

    <nz-dropdown-menu #EntiteActionsMenu="nzDropdownMenu">
      <ul nz-menu>
        <!-- <li nz-menu-item>
          <i nz-icon nzType="download"></i>
          <span class="item-dropdown">Exporter la fiche entité</span>
        </li>
        <li nz-menu-divider></li> -->
        <li
          nz-menu-item
          *ngIf="!(entite | isfavori : favoris?.data)"
          (click)="onAddToFavori(entite)"
        >
          <i nz-icon nzType="star"></i>
          <span class="item-dropdown">Ajouter aux favoris</span>
        </li>
        <li nz-menu-divider></li>
        <li
          nz-menu-item
          nzDanger
          nz-popconfirm
          [nzPopconfirmTitle]="
            'Voulez-vous supprimer ' +
            entite?.etablissement?.denomination +
            ' ?'
          "
          nzPopconfirmPlacement="bottom"
          (nzOnConfirm)="onDelete(entite.id)"
          (nzOnCancel)="onCancel()"
        >
          <i nz-icon nzType="delete"></i>
          <span class="item-dropdown">Supprimer</span>
        </li>
      </ul>
    </nz-dropdown-menu>
  </nz-card>

  <ng-template #entiteCardTitle>
    <app-header-title [icon]="'domain'"></app-header-title>
  </ng-template>

  <ng-template #ribbonText>
    <i nz-icon nzType="star"></i>
  </ng-template>
</ng-container>
