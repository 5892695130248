import {
  UtilitiesIds,
  UtilitiesDocumentVariables,
} from '../../../../contents/components/utilities/store/utilities.model';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { Subject, Observable, of } from 'rxjs';
import {
  UntypedFormGroup,
  FormControl,
  Validators,
  UntypedFormBuilder,
  UntypedFormArray,
  FormGroup,
} from '@angular/forms';
import { trigger, transition, animate, style } from '@angular/animations';
import { forEach } from 'lodash';
import { Timestamp } from '@angular/fire/firestore';
import { Store } from '@ngrx/store';
import { StateDocument } from './store/document.reducer';
import * as fromDocumentAction from './store/document.actions';
import * as fromDocumentSelector from './store/document.selectors';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss'],
  animations: [
    trigger('fade', [
      transition('void =>*', [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 })),
      ]),
      transition('* =>void', [
        animate(500, style({ color: 'red', opacity: 0 })),
      ]),
    ]),
  ],
})
export class DocumentComponent implements OnInit, OnDestroy {
  user$: Observable<any> = of(null);
  loading$: Observable<boolean> = of(false);
  filters$: Observable<any> = of(null);
  dropzoneActive: boolean = false;

  formTitle = 'Nouveau document';
  subscribe = new Subject();
  utilitairesId: UtilitiesIds = UtilitiesIds.document;
  utilitaireType = UtilitiesDocumentVariables.type;

  selectedFile: any = null;
  downloadURL: string = '';
  progress: number = 0;
  base64 = 'data:image/jpeg;base64,';
  url: any;

  typeList$: Observable<any> = of(null);

  fileAdding: File[] = [];
  uploadPourcentage: any = null;
  fileListSize = 0;

  showUploadList = {
    showPreviewIcon: true,
    showRemoveIcon: true,
    hidePreviewIconInNonImage: true,
  };

  fileList = [];
  previewImage: string | undefined = '';
  previewVisible = false;
  accept =
    'image/*,application/pdf,application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint';

  document: FormGroup = this.fb.group({
    elements: this.fb.array([]),
  });

  @Output() addDocument = new EventEmitter<any>(false);
  @Output() removeDocument = new EventEmitter<any>(false);
  @Output() cancel = new EventEmitter<any>(false);

  constructor(
    private fb: UntypedFormBuilder,
    private documentStore: Store<StateDocument>
  ) {}

  ngOnInit() {
    //this.formCreate();
    this.getlaoding();
    this.getFilters();
    this.loadFilters();
  }

  getlaoding() {
    this.loading$ = this.documentStore.select(fromDocumentSelector.loading);
  }

  onDropZoneState($event: boolean): void {
    this.dropzoneActive = $event;
  }

  getFilters() {
    this.filters$ = this.documentStore.select(fromDocumentSelector.filters);
  }

  loadFilters() {
    this.documentStore.dispatch(fromDocumentAction.loadDocumentFilters());
  }

  get elementArray() {
    return this.document.get('elements') as UntypedFormArray;
  }

  addDocumentFile(files: FileList): void {
    if (files && files[0]) {
      forEach(files, (file) => {
        const { name, type } = file;
        const fileName = name.split('.')[0];
        const doc = this.fb.group({
          file: [file],
          doc: this.fb.group({
            auths: [''],
            access: [{}],
            dateStart: [Timestamp.now(), Validators.required],
            file: [''],
            type: [type, Validators.required],
            extension: [file.type, Validators.required],
            title: [fileName, Validators.required],
            auteur: [''],
            description: [''],
            fileRef: [''],
            recu: [true],
            valid: [false],
            dateValidation: [''],
            correspondanceId: [''],
            organisation: [''],
            operation: [],
          }),
        });
        this.elementArray.push(doc);
      });
    }
  }

  removeDocumentFile(i: number): void {
    this.elementArray.removeAt(i);
    this.fileListSize = this.fileListSize - 1;
  }

  onSelectType(type: any, i: number): void {
    this.elementArray.controls[i].patchValue({ type: type });
  }

  handleDropFiles(files: FileList): void {
    if (!files?.length) return;
    const size: number = files?.length;
    this.fileListSize = size;
    this.addDocumentFile(files);
  }

  handleChange(fileInput: any): void {
    if (fileInput.target.files && fileInput.target.files[0]) {
      const { files } = fileInput.target;
      this.fileListSize = files.length;
      this.addDocumentFile(files);
    }
  }

  saveDoc(): void {
    const docList: any = this.document.value;
    if (this.elementArray.length > 0) {
      this.addDocument.emit(docList.elements);
      this.onCancel();
    }
  }

  onCancel(): void {
    this.document.reset();
    this.elementArray.clear();
    this.cancel.emit(true);
  }

  removeAllFiles(): void {
    this.elementArray.clear();
    this.fileAdding = [];
    this.fileListSize = 0;
    this.document.reset();
  }

  listSort(items: any): any {
    if (items) {
      const sort = items.sort();
      return sort;
    }
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }
}
