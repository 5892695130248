<nz-row nzAlign="middle" nzGutter="8" nzJustify="start" *ngIf="isSearch">
  <form nz-form [formGroup]="listSearchForm" nzLayout="vertical">
    <nz-form-item>
      <nz-form-control>
        <input
          nz-input
          nzBorderless
          formControlName="input"
          [placeholder]="placeholder"
        />
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-row>
<div class="listSearch__container">
  <ul
    nz-list
    [nzDataSource]="list"
    nzBordered
    nzSize="small"
    *ngIf="list | size; else noData"
  >
    <li
      nz-list-item
      *ngFor="let item of list"
      nzNoFlex
      (click)="$event.stopPropagation(); onSelect(item)"
    >
      <ul nz-list-item-actions *ngIf="isUpdate || isDelete">
        <nz-list-item-action *ngIf="isUpdate">
          <button
            nz-button
            nzShape="circle"
            nzType="text"
            nz-dropdown
            [nzDropdownMenu]="listActionsMenu"
            nzPlacement="bottomRight"
            nzTrigger="click"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
        </nz-list-item-action>

        <nz-dropdown-menu #listActionsMenu="nzDropdownMenu">
          <ul nz-menu>
            <li
              nz-menu-item
              *ngIf="isUpdate"
              (click)="$event.stopPropagation(); onUpdate(item)"
            >
              <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
                <nz-col [nzSpan]="4">
                  <mat-icon>edit</mat-icon>
                </nz-col>
                <nz-col [nzSpan]="4">
                  <nz-divider
                    nzOrientation="center"
                    nzType="vertical"
                  ></nz-divider>
                </nz-col>
                <nz-col [nzSpan]="16"> modifier </nz-col>
              </nz-row>
            </li>
            <ng-container *ngIf="isDelete">
              <li nz-menu-divider></li>
              <li
                nz-menu-item
                nzDanger
                (click)="$event.stopPropagation(); onDelete(item)"
              >
                <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
                  <nz-col [nzSpan]="4">
                    <mat-icon>delete</mat-icon>
                  </nz-col>
                  <nz-col [nzSpan]="4">
                    <nz-divider
                      nzOrientation="center"
                      nzType="vertical"
                    ></nz-divider>
                  </nz-col>
                  <nz-col [nzSpan]="16"> supprimer </nz-col>
                </nz-row>
              </li>
            </ng-container>
          </ul>
        </nz-dropdown-menu>
      </ul>
      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col [nzSpan]="icon ? 4 : 0">
          <mat-icon>{{ icon }}</mat-icon>
        </nz-col>
        <nz-col [nzSpan]="icon ? 20 : 24">
          <span *ngIf="title">
            <strong>{{ item[title] | carLimitation: 20 }}</strong>
          </span>
          <span *ngIf="!title">
            <strong>{{ item | carLimitation: 20 }} </strong>
          </span>
          <small *ngIf="subtitle"> {{ subtitle | carLimitation: 20 }}</small>
        </nz-col>
      </nz-row>
    </li>
  </ul>
</div>

<ng-template #noData>
  <app-no-result
    [icon]="'storage'"
    [notitle]="notitle"
    [isButton]="false"
  ></app-no-result>
</ng-template>
