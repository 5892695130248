import { OperationId } from './../../../operation/store/operation.model';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import {
  Observable,
  of,
  BehaviorSubject,
  Subject,
  takeUntil,
  debounceTime,
  distinctUntilChanged,
} from 'rxjs';
import { OperationEntiteState } from '../../store/operation-entites.reducer';
import * as fromOperationEntitesSelector from '../../store/operation-entites.selectors';
import * as fromOperationEntitesAction from '../../store/operation-entites.actions';

@Component({
  selector: 'app-operation-entites-selectors',
  templateUrl: './operation-entites-selectors.component.html',
  styleUrls: ['./operation-entites-selectors.component.scss'],
})
export class OperationEntitesSelectorsComponent implements OnInit {
  operation$: Observable<OperationId | any> = of(null);

  entites$: Observable<any> = of([]);
  loading$: Observable<boolean> = of(false);
  filterForm: UntypedFormGroup = this.fb.group({});
  input: UntypedFormControl = new UntypedFormControl();
  subscribe = new Subject();

  filterInputMode: string = '';
  filterInput$ = new BehaviorSubject<string>('');

  @Output() select = new EventEmitter<any>(false);
  @Output() cancel = new EventEmitter<any>(false);

  constructor(
    private fb: UntypedFormBuilder,
    private operationEntiteStore: Store<OperationEntiteState>
  ) {}

  ngOnInit(): void {
    this.getEntites();
  }

  ngOnDestroy(): void {
    this.subscribe.next(false);
    this.subscribe.complete();
  }

  form(): void {
    this.formInit();
    this.formCreate();
  }
  formInit(): void {
    this.input = new UntypedFormControl('');
  }
  formCreate(): void {
    this.filterForm = this.fb.group({
      input: this.input,
    });
  }

  getEntites(): void {
    this.entites$ = this.operationEntiteStore.select(
      fromOperationEntitesSelector.queries
    );
  }
  queryEntites(operation: OperationId): void {
    if (!operation) return;

    this.filterInput$
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.subscribe),
        debounceTime(200)
      )
      .subscribe((filter) => {
        if (!filter || filter?.length < 2) return;
        const filterType: string = 'raisonSociale';
        const operationId: string = operation.id;

        this.operationEntiteStore.dispatch(
          fromOperationEntitesAction.queryOperationEntites({
            filter,
            filterType,
            operationId,
          })
        );
      });
  }

  onSelect(item: any): void {
    if (!item) return;
    this.select.emit(item);
  }

  onCancel(): void {
    this.cancel.emit(true);
  }
}
