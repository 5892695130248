import { AllParticipantDiagnosticGQL } from './../../GRAPHQL/queries/PARTICIPANT/diagnostic/query-participant-diagnostic.service';
import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { EMPTY, map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'any',
})
export class ParticipantDiagnosticService {
  CREATE_PARTICIPANT_DIAGNOSTIC = gql`
    mutation CreateParticipantDiagnostic(
      $id: ID!
      $diagnostic: DiagnosticInput!
    ) {
      createParticipantDiagnostic(id: $id, diagnostic: $diagnostic) {
        id
        dateStart
        dateUpdate
        auteur {
          avatar
          displayName
          fonction
          setting {
            color
          }
        }
        userUpdate {
          avatar
          displayName
          fonction
          setting {
            color
          }
        }
        besoins {
          libelle
        }
        administratif {
          isFrein
          isFse
          isPassIae
          numCaiCir
          numbCarteSejour
          dateEntree
          dateInscription
          dateRecrutement
          typeCarteSejour {
            info
            titre
            type
          }
          criteres1 {
            justificatif
            label
            value
          }
          criteres2 {
            info
            item
            pieceAfournir
          }
        }

        famille {
          enfantMoyenDeGarde
          enfantScolarise
          enfantsAcharges
          familiale
          isFrein
        }

        logement {
          chezTier
          centreHebergement
          autre
          autreLieu
          proprietaire
          lieuPublic
          chezParent
          locataire
          hotel
          nesaitpas
          isQPV
          isQVA
          isZRR
          precision
          isFrein
        }
        mobilite {
          geo_mobility {
            codeTypeTerritoire
            libelleTypeTerritoire
          }
          permis {
            code
            libelle
          }
          transport_mode
          contrainte_horaire
          isFrein
        }
        sante {
          couvertureMaladie
          handicap
          isFrein
          nirSecuriteSociale
          sante
        }
        justice {
          caseJudiciaire
          executionDePeineEnCours
          isFrein
          justice
          suiviSpipPjj
        }
        droit {
          neConnaitSesDroitsAucuneDemarche
          difficulteDeRenouvellementDeDroit
          connaitSesDroitsDemarcheEnCours
          beneficieDeSesDroits
          precision
          consentementRGPD
          droitDeTravail
          droitAimage
          isFrein
        }
        qualification {
          isFrein
          isFrenchLevelFle
          isFrenchLevelIlletrisme
          isFrenchLevelLaborieux
          isFrenchLevelParfait
          maitriseFrancais {
            info
            item
            type
          }
          qualification {
            label
            value
          }
        }
        numerique {
          bureautique_level
          internet
          isFrein
          mobile
          numerique_level {
            item
            type
          }
          ordinateur
        }
        ressources {
          besoinPrimaireLibelle
          besoinsPrimaires
          isFrein
          saitGererSonBudget
          saitGererSonBudgetLibelle
          surendettementAvecDossier
          surendettementAvecDossierLibelle
          surendettementSansDossier
          surendettementSansDossierLibelle
        }
        aptitudes {
          softskills {
            details
            summary
          }
        }
        disponibilite {
          disponibiliteAorganiser
          disponibiliteAorganiserLibelle
          disponibiliteFaibleVoireDifficile
          disponibiliteFaibleVoireDifficileLibelle
          disponibiliteImmediatementAvec
          disponibiliteImmediatementAvecLibelle
          disponibiliteImmediatementSans
          disponibiliteImmediatementSansLibelle
          isFrein
          precision
        }
        posture {
          aBesoinDaccompagnement
          incapaciteAoccuperUnPoste
          isFrein
          peutOccuperUnPosteAvecCondition
          peutOccuperUnPosteSansCondition
          precision
        }
      }
    }
  `;

  UPDATE_PARTICIPANT_DIAGNOSTIC = gql`
    mutation UpdateParticipantDiagnostic(
      $id: ID!
      $diagnostic: DiagnosticUpdateInput!
    ) {
      updateParticipantDiagnostic(id: $id, diagnostic: $diagnostic) {
        id
        dateStart
        dateUpdate
        auteur {
          avatar
          displayName
          fonction
          setting {
            color
          }
        }
        userUpdate {
          avatar
          displayName
          fonction
          setting {
            color
          }
        }
        besoins {
          libelle
        }
        administratif {
          isFrein
          isFse
          isPassIae
          numCaiCir
          numbCarteSejour
          dateEntree
          dateInscription
          dateRecrutement
          typeCarteSejour {
            info
            titre
            type
          }
          criteres1 {
            justificatif
            label
            value
          }
          criteres2 {
            info
            item
            pieceAfournir
          }
        }

        famille {
          enfantMoyenDeGarde
          enfantScolarise
          enfantsAcharges
          familiale
          isFrein
        }

        logement {
          chezTier
          centreHebergement
          autre
          autreLieu
          proprietaire
          lieuPublic
          chezParent
          locataire
          hotel
          nesaitpas
          isQPV
          isQVA
          isZRR
          precision
          isFrein
        }
        mobilite {
          geo_mobility {
            codeTypeTerritoire
            libelleTypeTerritoire
          }
          permis {
            code
            libelle
          }
          transport_mode
          contrainte_horaire
          isFrein
        }
        sante {
          couvertureMaladie
          handicap
          isFrein
          nirSecuriteSociale
          sante
        }
        justice {
          caseJudiciaire
          executionDePeineEnCours
          isFrein
          justice
          suiviSpipPjj
        }
        droit {
          neConnaitSesDroitsAucuneDemarche
          difficulteDeRenouvellementDeDroit
          connaitSesDroitsDemarcheEnCours
          beneficieDeSesDroits
          precision
          consentementRGPD
          droitDeTravail
          droitAimage
          isFrein
        }
        qualification {
          isFrein
          isFrenchLevelFle
          isFrenchLevelIlletrisme
          isFrenchLevelLaborieux
          isFrenchLevelParfait
          maitriseFrancais {
            info
            item
            type
          }
          qualification {
            label
            value
          }
        }
        numerique {
          bureautique_level
          internet
          isFrein
          mobile
          numerique_level {
            item
            type
          }
          ordinateur
        }
        ressources {
          besoinPrimaireLibelle
          besoinsPrimaires
          isFrein
          saitGererSonBudget
          saitGererSonBudgetLibelle
          surendettementAvecDossier
          surendettementAvecDossierLibelle
          surendettementSansDossier
          surendettementSansDossierLibelle
        }
        aptitudes {
          softskills {
            details
            summary
          }
        }
        disponibilite {
          disponibiliteAorganiser
          disponibiliteAorganiserLibelle
          disponibiliteFaibleVoireDifficile
          disponibiliteFaibleVoireDifficileLibelle
          disponibiliteImmediatementAvec
          disponibiliteImmediatementAvecLibelle
          disponibiliteImmediatementSans
          disponibiliteImmediatementSansLibelle
          isFrein
          precision
        }
        posture {
          aBesoinDaccompagnement
          incapaciteAoccuperUnPoste
          isFrein
          peutOccuperUnPosteAvecCondition
          peutOccuperUnPosteSansCondition
          precision
        }
      }
    }
  `;

  DELETE_PARTICIPANT_DIAGNOSTIC = gql`
    mutation DeleteParticipantDiagnostic($participantId: ID!, $id: ID!) {
      deleteParticipantDiagnostic(participantId: $participantId, id: $id)
    }
  `;

  DELETE_PARTICIPANT_DIAGNOSTICS = gql`
    mutation DeleteParticipantDiagnostics($id: ID!) {
      deleteParticipantDiagnostics(id: $id)
    }
  `;

  constructor(
    private apollo: Apollo,
    private allParticipantDiagnosticGQL: AllParticipantDiagnosticGQL
  ) {}

  getAll(id: string): Observable<any> {
    try {
      if (!id) return EMPTY;
      return this.allParticipantDiagnosticGQL
        .watch({
          id,
        })
        .valueChanges.pipe(
          map(({ data, loading, error }) => {
            return { data, loading, error };
          })
        );
    } catch (error) {
      return of(error);
    }
  }
  getOne(id: string): Observable<any> {
    try {
      if (!id) return EMPTY;

      return this.allParticipantDiagnosticGQL
        .watch({
          id,
        })
        .valueChanges.pipe(
          map(({ data, loading, error }) => {
            return { data, loading, error };
          })
        );
    } catch (error) {
      return of(error);
    }
  }
  addOne(id: string, diagnostic: any): Observable<any> {
    try {
      if (!id || !diagnostic) return EMPTY;

      const {
        dateStart,
        presentation,
        besoins,
        administratif,
        famille,
        logement,
        mobilite,
        sante,
        justice,
        droit,
        qualification,
        numerique,
        ressources,
        aptitudes,
        poleEmploi,
        disponibilite,
        posture,
      } = diagnostic;

      return this.apollo.mutate<any>({
        mutation: this.CREATE_PARTICIPANT_DIAGNOSTIC,
        variables: {
          id,
          diagnostic: {
            dateStart,
            presentation,
            besoins,
            administratif,
            famille,
            logement,
            mobilite,
            sante,
            justice,
            droit,
            qualification,
            numerique,
            ressources,
            aptitudes,
            poleEmploi,
            disponibilite,
            posture,
          },
        },
        update: (cache, { data: { createParticipantDiagnostic } }) => {
          const { participantDiagnostic }: any = cache.readQuery({
            query: this.allParticipantDiagnosticGQL.document,
            variables: { id },
          });

          cache.writeQuery({
            query: this.allParticipantDiagnosticGQL.document,
            variables: { id },
            data: createParticipantDiagnostic,
          });
        },
      });
    } catch (error) {
      return of(error);
    }
  }
  updateOne(id: string, diagnostic: Partial<any>): Observable<any> {
    try {
      if (!id || !diagnostic) return EMPTY;
      const {
        dateStart,
        presentation,
        besoins,
        administratif,
        famille,
        logement,
        mobilite,
        sante,
        justice,
        droit,
        qualification,
        numerique,
        ressources,
        aptitudes,
        poleEmploi,
        disponibilite,
        posture,
      } = diagnostic.changes;

      return this.apollo.mutate<any>({
        mutation: this.UPDATE_PARTICIPANT_DIAGNOSTIC,
        variables: {
          id,
          diagnostic: {
            id: diagnostic.id,
            dateStart,
            presentation,
            besoins,
            administratif,
            famille,
            logement,
            mobilite,
            sante,
            justice,
            droit,
            qualification,
            numerique,
            ressources,
            aptitudes,
            poleEmploi,
            disponibilite,
            posture,
          },
        },
        update: (cache, { data: { updateParticipantDiagnostic } }) => {
          const { participantDiagnostic }: any = cache.readQuery({
            query: this.allParticipantDiagnosticGQL.document,
            variables: { id },
          });
          const cacheId = cache.identify(participantDiagnostic);
          cache.modify({
            id: cacheId,
            fields: {
              ...updateParticipantDiagnostic,
            },
          });
        },
      });
    } catch (error) {
      return of(error);
    }
  }
  deleteOne(participantId: string, id: string): Observable<any> {
    try {
      if (!id || !participantId) return EMPTY;
      return this.apollo.mutate<any>({
        mutation: this.DELETE_PARTICIPANT_DIAGNOSTIC,
        variables: { participantId, id },
        update: (cache, { data: { deleteParticipantDiagnostic } }) => {
          cache.writeQuery({
            query: this.allParticipantDiagnosticGQL.document,
            variables: { id: participantId },
            data: {},
          });
        },
      });
    } catch (error) {
      return of(error);
    }
  }
}
