import { Component, OnInit } from '@angular/core';
import { Observable, BehaviorSubject, of } from 'rxjs';
@Component({
  selector: 'app-participant-planning-list-header',
  templateUrl: './participant-planning-list-header.component.html',
  styleUrls: ['./participant-planning-list-header.component.scss'],
})
export class ParticipantPlanningListHeaderComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
