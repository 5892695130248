<form nz-form [formGroup]="ticketForm" nzLayout="vertical">
  <nz-select
    formControlName="type"
    nzPlaceHolder="Type de ticket..."
    nzBorderless
  >
    <nz-option
      *ngFor="let bug of ticketBugs"
      [nzLabel]="bug"
      [nzValue]="bug"
      nzCustomContent="true"
    >
      <i nz-icon nzTheme="outline" nzType="bug"></i>
      <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
      <span>{{ bug }}</span>
    </nz-option>
  </nz-select>
</form>
<app-text-edithing
  [autosave]="true"
  [update]="updateText"
  (add)="setMessage($event)"
></app-text-edithing>

<nz-row nzAlign="middle" nzJustify="end" nzGutter="8">
  <nz-col nzSpan="8"></nz-col>
  <nz-col nzSpan="8"></nz-col>
  <nz-col nzSpan="8">
    <nz-button-group>
      <button
        nz-button
        nzType="text"
        (click)="sendTicket()"
        [disabled]="!ticketForm.valid"
      >
        <i nz-icon nzTheme="outline" nzType="send"></i>

        Envoyer
      </button>
      <button nz-button nzType="text" (click)="onCancelTicket()">
        <i nz-icon nzTheme="outline" nzType="close"></i>

        Annuler
      </button>
    </nz-button-group>
  </nz-col>
</nz-row>
