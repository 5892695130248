import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isMember',
})
export class IsMemberPipe implements PipeTransform {
  transform(user: any): boolean {
    if (!user) {
      return false;
    }
    const { claims } = user;
    if (!claims.member) {
      return false;
    }

    return true;
  }
}
