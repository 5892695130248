import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { OperationState } from 'src/app/components/operation/store/operation.reducer';
import * as fromOperationAction from 'src/app/components/operation/store/operation.actions';

@Component({
  selector: 'app-operation-missions',
  templateUrl: './operation-missions.component.html',
  styleUrls: ['./operation-missions.component.scss'],
})
export class OperationMissionsComponent implements OnInit {
  missions$: Observable<any>;

  constructor(
    private operationStore: Store<OperationState>,
    private route: ActivatedRoute
  ) {
    const data: Data = this.route.snapshot.data;
    this.missions$ = data?.missions;
    this.operationStore.dispatch(
      fromOperationAction.setActviteRoute({ title: data?.title })
    );
  }

  ngOnInit(): void {}
}
