import { SharedModule } from './../../shared/shared.module';
import { OperationTeamService } from './services/operation-team.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentsModule } from 'src/app/contents/contents.module';
import { EffectsModule } from '@ngrx/effects';
import { OperationTeamEffects } from './store/operation-team.effects';
import { StoreModule } from '@ngrx/store';
import * as fromOperationTeam from './store/operation-team.reducer';
import { OperationTeamComponent } from './components/operation-team/operation-team.component';
import { OperationTeamMemberComponent } from './components/operation-team-member/operation-team-member.component';
import { OperationTeamRoleComponent } from './components/operation-team-role/operation-team-role.component';
import { OperationTeamCardComponent } from './components/operation-team-card/operation-team-card.component';
import { OperationTeamSelectorComponent } from './components/operation-team-selector/operation-team-selector.component';
import { NoResultModule } from 'src/app/contents/components/no-result/no-result.module';
import { CoordonneesModule } from 'src/app/contents/components/coordonnees/coordonnees.module';
import { CommunesFranceModule } from 'src/app/contents/components/communes-france/communes-france.module';
import { ButtonsModule } from 'src/app/contents/components/buttons/buttons.module';
import { HeaderModule } from 'src/app/contents/components/header-title/header.module';
import { LayoutModule } from 'src/app/contents/components/layout/layout.module';
import { UiModule } from 'src/app/ui/ui/ui.module';

@NgModule({
  declarations: [
    OperationTeamComponent,
    OperationTeamMemberComponent,
    OperationTeamRoleComponent,
    OperationTeamCardComponent,
    OperationTeamSelectorComponent,
  ],
  imports: [
    CommonModule,
    ContentsModule,
    SharedModule,
    NoResultModule,
    CoordonneesModule,
    HeaderModule,
    CommunesFranceModule,
    ButtonsModule,
    LayoutModule,
    UiModule,
    EffectsModule.forFeature([OperationTeamEffects]),
    StoreModule.forFeature(
      fromOperationTeam.operationTeamsFeatureKey,
      fromOperationTeam.reducer
    ),
  ],
  providers: [OperationTeamService],
  exports: [
    OperationTeamComponent,
    OperationTeamMemberComponent,
    OperationTeamRoleComponent,
    OperationTeamSelectorComponent,
  ],
})
export class OperationTeamModule {}
