<div
  class="w-60 flex flex-col items-center h-40 overflow-y-auto overflow-x-hidden"
>
  <div *ngFor="let item of contacts" class="grid grid-cols-1 divide-y">
    <div class="w-full m-4">
      <p class="text-sm">{{ item?.displayName || item | displayName }}</p>
      <p class="text-xs text-slate-800">{{ item?.fonction }}</p>
      <p class="text-xs text-slate-800 font-semibold">
        {{ item?.etablissement?.denominationUniteLegale }}
      </p>
    </div>
  </div>
</div>
