import { NzModalService } from 'ng-zorro-antd/modal';
import { MissionsFormComponent } from './../missions-form/missions-form.component';
import { ActionSmallId } from './../../../action/store/action.model';
import { OperationId } from './../../../operation/store/operation.model';
import { Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import { Subject, BehaviorSubject, Observable, of } from 'rxjs';

@Component({
  selector: 'app-missions-filters',
  template: `
    <nz-row
      nzAlign="middle"
      nzGutter="4"
      nzJustify="center"
      *ngIf="operation$ | async as operation"
    >
      <nz-col nzSpan="6" class="filterTitle">
        <nz-badge [nzCount]="missions$ | async | size" [nzOffset]="[10, 0]">
          <h4 nz-typography>Missions</h4>
        </nz-badge>
      </nz-col>
      <nz-col nzSpan="15" class="inputSearch">
        <input
          nz-input
          [(ngModel)]="inputModel"
          (ngModelChange)="onSeach($event, operation)"
          nzBorderless
          placeholder="Rechercher une mission..."
        />
      </nz-col>

      <nz-col nzSpan="1"></nz-col>
      <nz-col nzSpan="1">
        <button
          nz-button
          nzShape="circle"
          nzType="text"
          nz-tooltip
          nzTooltipTitle="Filtrer les missions"
          nzTooltipPlacement="bottom"
          [nzTooltipMouseEnterDelay]="0.5"
          nzTooltipPlacement="bottom"
          class="btnFilter"
        >
          <i nz-icon nzType="filter"></i>
        </button>
      </nz-col>

      <nz-col nzSpan="1">
        <app-button-dropdown [isRelance]="true"></app-button-dropdown>
      </nz-col>
    </nz-row>
  `,
  styles: [
    `
      .filterTitle {
        display: flex;
        flex-direction: row;
        justify-content: center;
        justify-items: center;
        margin-top: 10px;
      }

      .addBtn {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        justify-items: flex-end;
        margin-top: -10px;
      }

      .btnFilter {
        margin-top: 4px;
      }

      .inputSearch {
        border-radius: 12px;
        background-color: #f5f5f5;
      }
    `,
  ],
})
export class MissionsFiltersComponent implements OnInit {
  buttonColor: string = '#597ef7';
  modalWidth: number = 950;
  subscribe = new Subject();
  inputSearch$ = new BehaviorSubject<string>('');
  inputModel: string = '';

  @Input() operation$: Observable<OperationId | any> = of();
  @Input() missions$: Observable<any> = of([]);

  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {}

  onNewActionForm(): void {
    const modal = this.modal.create({
      nzContent: MissionsFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: 'Nouvelle mission',
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });
    const instance = modal.getContentComponent();
    // instance.cancel.subscribe(
    //   (event: { operation: OperationId; action: Action }): any => {
    //     if (!event) {
    //       return null;
    //     } else {
    //       modal.close();
    //     }
    //   }
    // );
    // instance.add.subscribe((event: any): any => {
    //   if (!event) {
    //     return null;
    //   } else {
    //     this.onAdd(event);
    //   }
    // });
    // instance.operation$ = this.operation$;
  }

  onAdd(event: { operation: OperationId; mission: any }): void {
    // this.actionStore.dispatch(
    //   fromActionAction.addAction({
    //     operationId: event.operation.id,
    //     action: event.action,
    //   })
    // );
  }

  onSeach(event: string, operation: OperationId): void {
    // if (!event) {
    //   this.actionStore.dispatch(
    //     fromActionAction.loadActions({ operationId: operation.id })
    //   );
    //   return;
    // } else {
    //   const filter = {
    //     operationId: operation.id,
    //     event: event,
    //     type: 'title',
    //   };
    //   this.actionStore.dispatch(fromActionAction.queryActions({ filter }));
    // }
  }

  onClearMdole(): void {}
}
