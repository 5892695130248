<nz-card nzSize="small">
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="4">
      <nz-badge
        [nzStatus]="frenchLevel ? 'success' : 'error'"
        [nzOffset]="[50, -10]"
      >
        <mat-icon
          nz-tooltip
          nzTooltipTitle="Maîtrise de la langue française"
          nzTooltipPlacement="bottom"
          >abc</mat-icon
        ></nz-badge
      >
    </nz-col>
    <nz-col nzSpan="1"> </nz-col>

    <nz-col nzSpan="4">
      <nz-badge [nzStatus]="house ? 'success' : 'error'">
        <mat-icon
          nz-tooltip
          nzTooltipTitle="Logement stable"
          nzTooltipPlacement="bottom"
          >house</mat-icon
        >
      </nz-badge>
    </nz-col>
    <nz-col nzSpan="1"> </nz-col>

    <nz-col nzSpan="4">
      <nz-badge [nzStatus]="lawAcces ? 'success' : 'error'">
        <mat-icon
          nz-tooltip
          nzTooltipTitle="Accès aux droit"
          nzTooltipPlacement="bottom"
          >gavel</mat-icon
        >
      </nz-badge>
    </nz-col>
    <nz-col nzSpan="1"> </nz-col>

    <nz-col nzSpan="4">
      <nz-badge [nzStatus]="justice ? 'success' : 'error'">
        <mat-icon
          nz-tooltip
          nzTooltipTitle="Justice"
          nzTooltipPlacement="bottom"
          >balance</mat-icon
        >
      </nz-badge>
    </nz-col>
    <nz-col nzSpan="1"> </nz-col>

    <nz-col nzSpan="4">
      <nz-badge [nzStatus]="justice ? 'success' : 'error'">
        <mat-icon nz-tooltip nzTooltipTitle="Santé" nzTooltipPlacement="bottom"
          >favorite</mat-icon
        >
      </nz-badge>
    </nz-col>
    <nz-col nzSpan="4"> </nz-col>
  </nz-row>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="4">
      <nz-badge [nzStatus]="finance ? 'success' : 'error'">
        <mat-icon
          nz-tooltip
          nzTooltipTitle="Ressources financières"
          nzTooltipPlacement="bottom"
          >account_balance_wallet</mat-icon
        >
      </nz-badge>
    </nz-col>
    <nz-col nzSpan="1"> </nz-col>

    <nz-col nzSpan="4">
      <nz-badge [nzStatus]="mobility ? 'success' : 'error'">
        <mat-icon
          nz-tooltip
          nzTooltipTitle="Mobilité"
          nzTooltipPlacement="bottom"
          >commute</mat-icon
        >
      </nz-badge>
    </nz-col>
    <nz-col nzSpan="1"> </nz-col>

    <nz-col nzSpan="4">
      <nz-badge [nzStatus]="workReady ? 'success' : 'error'">
        <mat-icon
          nz-tooltip
          nzTooltipTitle="Capacité à occuper un poste de travail"
          nzTooltipPlacement="bottom"
          >business_center</mat-icon
        >
      </nz-badge>
    </nz-col>
    <nz-col nzSpan="1"> </nz-col>

    <nz-col nzSpan="4">
      <nz-badge [nzStatus]="disponility ? 'success' : 'error'">
        <mat-icon
          nz-tooltip
          nzTooltipTitle="Niveau numérique"
          nzTooltipPlacement="bottom"
          >language</mat-icon
        >
      </nz-badge>
    </nz-col>
    <nz-col nzSpan="1"> </nz-col>

    <nz-col nzSpan="4">
      <nz-badge [nzStatus]="disponility ? 'success' : 'error'">
        <mat-icon
          nz-tooltip
          nzTooltipTitle="Disponibilité"
          nzTooltipPlacement="bottom"
          >sensor_occupied</mat-icon
        >
      </nz-badge>
    </nz-col>
    <nz-col nzSpan="4"> </nz-col>
  </nz-row>
</nz-card>
