<nz-row nzAlign="middle" nzGutter="2" nzJustify="start">
  <nz-col nzSpan="3"></nz-col>

  <nz-col nzSpan="1">
    <nz-badge
      [nzCount]="contacts$ | async | size"
      *ngIf="filtersActivated"
      nzStandalone="true"
      [nzOffset]="[-20, 0]"
    ></nz-badge>
  </nz-col>
  <nz-col nzSpan="16">
    <app-search-bar
      [filter]="true"
      [cleaner]="cleaner"
      (clear)="onClear()"
      [placeholder]="'Recherche par nom du contact'"
      (searching)="filtersByLastName($event)"
    ></app-search-bar>
  </nz-col>
  <nz-col nzSpan="2">
    <!-- <button
      nz-button
      nzShape="circle"
      nz-popover
      [nzPopoverContent]="contactsFilterTemplate"
      nzPopoverPlacement="bottomRight"
      nzPopoverTrigger="click"
      nzType="text"
    >
      <i nz-icon nzType="filter"></i>
    </button> -->
  </nz-col>
  <nz-col nzSpan="2">
    <i nz-icon nzType="loading" *ngIf="loading$ | async"></i>
  </nz-col>
</nz-row>
