<app-tag-selector
  *ngIf="currentEtablissement$ | async; else entiteSearchTpl"
  [text]="currentEtablissement$ | async"
  (clear)="onClearUpdateEtablissement()"
></app-tag-selector>
<ng-template #entiteSearchTpl>
  <nz-row nzJustify="start" nzAlign="top" nzGutter="8">
    <nz-col nzSpan="24">
      <nz-tag
        nzColor="geekblue"
        *ngIf="currentFilterRegion"
        nzMode="closeable"
        (nzCheckedChange)="onFilterRegionClose($event)"
      >
        {{ currentFilterRegion }}
      </nz-tag>
      <nz-tag
        nzColor="geekblue"
        *ngIf="currentFilterDepartement"
        nzMode="closeable"
        (nzCheckedChange)="onFilterDepartementClose($event)"
      >
        {{ currentFilterDepartement }}
      </nz-tag>
      <nz-tag
        nzColor="geekblue"
        *ngIf="currentFilterCommune"
        nzMode="closeable"
        (nzCheckedChange)="onFilterCommuneClose($event)"
      >
        {{ currentFilterCommune }}
      </nz-tag>
    </nz-col>
  </nz-row>
  <nz-space nzDirection="vertical"></nz-space>
  <div class="w-full flex items-center">
    <nz-col [nzSpan]="(loading$ | async) ? 2 : 0">
      <i nz-icon nzType="loading"></i>
    </nz-col>
    <nz-col [nzSpan]="(loading$ | async) ? 20 : 22">
      <form nz-form [formGroup]="searchForm">
        <div
          class="w-full pt-1 bg-slate-100 rounded-md h-10 hover:outline-1 hover:outline-slate-200 focus:outline-slate-400 active:outline-slate-400"
        >
          <nz-select
            [nzPlaceHolder]="placeholder"
            nzShowSearch="true"
            nzAllowClear
            nzBorderless
            [(ngModel)]="inputModel"
            name="inputModel"
            (nzOnSearch)="onSearchEtablissements($event)"
            [nzOptionOverflowSize]="2"
            nzAutoClearSearchValue="true"
            [nzLoading]="loading$ | async"
            (ngModelChange)="onSelectEtablissement($event)"
            [nzOpen]="isOpenSelect"
            (click)="$event.stopPropagation()"
            [nzOptionHeightPx]="240"
            [nzDropdownStyle]="dropdownStyle"
            [nzNotFoundContent]="noData"
            nzServerSearch
            [ngModelOptions]="{ standalone: true }"
            [compareWith]="etablissementComparer"
          >
            <nz-option
              *ngFor="
                let item of etablissements$ | async as etablissements;
                trackBy: trackByFn
              "
              [nzLabel]="item?.uniteLegale.denominationUniteLegale"
              [nzValue]="item"
              nzCustomContent
            >
              <app-entite-search-card
                [etablissement]="item"
              ></app-entite-search-card>
            </nz-option>

            <ng-template #noFound>
              <nz-empty nzNotFoundContent="simple"></nz-empty>
            </ng-template>
          </nz-select>
        </div>
      </form>
    </nz-col>

    <nz-col [nzSpan]="2">
      <button
        (click)="
          $event.stopPropagation(); onOpenSearchList(); onFilterSelected()
        "
        nz-popover
        nzPopoverTrigger="click"
        nzPopoverPlacement="bottom"
        [nzPopoverContent]="etablissementSearchFilter"
        class="btn-icon group/button"
      >
        <svg
          class="iconSvg"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 28 28"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z"
          />
        </svg>
      </button>
      <ng-template #etablissementSearchFilterExtra>
        <nz-row nzAlign="middle" nzGutter="8" nzJustify="end">
          <nz-col nzSpan="20"></nz-col>
          <nz-col nzSpan="4">
            <buton
              nz-button
              (click)="onCloseFilterSelected()"
              nzShape="round"
              nzSize="small"
            >
              <mat-icon>clear</mat-icon>
            </buton>
          </nz-col>
        </nz-row>
      </ng-template>

      <ng-template #etablissementSearchFilter>
        <app-entite-search-filters
          (filters)="searchFilter($event)"
        ></app-entite-search-filters>
      </ng-template>
    </nz-col>
  </div>
</ng-template>

<ng-template #noData>
  <app-no-result
    [icon]="'domain'"
    [isButton]="false"
    [description]="'Aucune entité trouvée'"
  ></app-no-result>
</ng-template>
