import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthState } from 'src/app/components/auth/store/auth.reducer';
import * as fromAuthAction from 'src/app/components/auth/store/auth.actions';
import * as fromAuthSelector from 'src/app/components/auth/store/auth.selectors';
import { Store, select } from '@ngrx/store';
import { of, Observable } from 'rxjs';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss'],
})
export class UserDetailComponent implements OnInit {
  avatarBackground: string = '#bfbfbf';
  avatarColor: string = 'white';
  buttonColor: string = '#36cfc9';
  bgColor: string = '#bfbfbf';
  bgDefault: string = '#fff';
  tabStyle: any = {
    'background-color': '#f5f5f5',
    color: 'grey',
  };

  operations$: Observable<any> = of(null);
  currentSelected: number = 0;

  @Input() user: any = null;
  @Input() cuurentUser: any = null;

  @Input() deleteTitle: string = 'Supprimer le collaborateur';
  @Output() back = new EventEmitter<boolean>(true);
  @Output() delete = new EventEmitter<any>(true);

  constructor(private auth: Store<AuthState>) {}

  ngOnInit(): void {}

  onBack() {
    this.back.emit(true);
  }

  selectedIndexChange(index: number): void {
    this.currentSelected = index;
  }
  onDisabled(user: any): void {}
  onActived(user: any): void {}

  onDelete(user: any): void {
    if (!user) return;
    this.delete.emit(user);
  }
  cancel(): any {
    return null;
  }
}
