<nz-card
  nzSize="small"
  [nzTitle]="title"
  [nzExtra]="extra"
  nzBorderless
  nzType="inner"
>
  <nz-comment
    [nzAuthor]="item.auteur.displayName | carLimitation : 20"
    [nzDatetime]="calendarCardTimeTpl"
  >
    <ng-template #calendarCardTimeTpl>
      {{ item.dateStart | dateFr : "time" }}
    </ng-template>
    <nz-avatar
      nz-comment-avatar
      [nzText]="item.auteur.avatar"
      [ngStyle]="{
        'background-color': item.auteur.setting?.color
          ? item.auteur.setting?.color
          : 'grey'
      }"
    ></nz-avatar>
    <nz-comment-content>
      <p nz-typography>{{ item.content }}</p>
      <nz-divider
        nzOrientation="left"
        nzType="horizontal"
        [nzText]="referentTitle"
        nzPlain
      ></nz-divider>
      {{ item?.referent?.displayName | carLimitation : 30 }}
    </nz-comment-content>
  </nz-comment>
</nz-card>

<ng-template #title>
  <nz-badge [nzColor]="item.type.color"> </nz-badge>
  <strong>{{ item.type.label }}</strong>
</ng-template>

<ng-template #referentTitle>
  <small>Destinataire</small>
</ng-template>

<ng-template #extra>
  <app-button-dropdown
    [isDelete]="true"
    [deleteType]="deleteAlert"
    (select)="onAction($event, item)"
  ></app-button-dropdown>
</ng-template>
