import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UtilitiesState } from '../../store/utilities.reducer';
import * as fromUtilityAction from '../../store/utilities.actions';
import { Store } from '@ngrx/store';
import { Update } from '@ngrx/entity';

@Component({
  selector: 'app-utilities-form',
  templateUrl: './utilities-form.component.html',
  styleUrls: ['./utilities-form.component.scss'],
})
export class UtilitiesFormComponent implements OnInit {
  utilityGroup = this.fb.group({});
  input: UntypedFormControl = new UntypedFormControl('', Validators.required);

  @Input() utitlityId: string = '';
  @Input() items: any = [];
  @Input() utility: any = null;
  @Input() variable: string = '';
  @Input() borderless: boolean = true;
  @Output() refreshItems = new EventEmitter<any>(false);
  constructor(
    private fb: UntypedFormBuilder,
    private utitlityStore: Store<UtilitiesState>
  ) {}

  ngOnInit(): void {
    this.formInit();
    this.formCreate();
  }

  formInit(): void {
    this.input = new UntypedFormControl('', Validators.required);
  }
  formCreate(): void {
    this.utilityGroup = this.fb.group({
      input: this.input,
    });
  }

  onAdd(items: any, utility: any, variable: string): any {
    if (!this.utilityGroup.valid || !items || !utility || !variable) {
      return null;
    } else {
      const newVariable = this.utilityGroup.get('input')?.value;
      const nextList = [...items, newVariable];
      const nextUtility: Update<any> = {
        id: utility.id,
        changes: {
          ...utility,
          [`${variable}`]: nextList,
        },
      };
      this.utitlityStore.dispatch(
        fromUtilityAction.updateUtility({ utility: nextUtility })
      );

      this.refreshItems.emit(nextList);
      this.utilityGroup.reset();
    }
  }
}
