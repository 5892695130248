import { SideNavigationState } from 'src/app/components/side-navigation/store/side-navigation.reducer';
import { ExportSelectComponent } from './../../../../contents/components/export-select/export-select.component';
import { EntiteFormComponent } from './../entite-form/entite-form.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { PortalNavigationService } from './../../../../portals/portal-navigation.service';
import { EntiteItemHeaderComponent } from './../entite-item-header/entite-item-header.component';
import { ComponentPortal } from '@angular/cdk/portal';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { EntiteId } from './../../store/entite.model';
import {
  Component,
  OnDestroy,
  OnInit,
  ChangeDetectorRef,
  AfterViewInit,
  ViewContainerRef,
} from '@angular/core';
import { Subject, Observable, of, EMPTY } from 'rxjs';
import { Store } from '@ngrx/store';
import { EntiteState } from '../../store/entite.reducer';
import * as fromEntiteAction from '../../store/entite.actions';
import * as fromEntiteSelector from '../../store/entite.selectors';
import * as sideNavigationSelector from 'src/app/components/side-navigation/store/side-navigation.selectors';
import * as sideNavigationAction from 'src/app/components/side-navigation/store/side-navigation.actions';

@Component({
  selector: 'app-entite-item',
  templateUrl: './entite-item.component.html',
  styleUrls: ['./entite-item.component.scss'],
})
export class EntiteItemComponent implements OnInit, AfterViewInit, OnDestroy {
  subscribe = new Subject();
  routeData$: Observable<any> = of(null);
  entite$: Observable<EntiteId | any> = of(null);
  statistiques$: Observable<any> = of(null);
  bodyComponent: string = 'details';
  modalWidth: number = 680;
  modalExportWidth: number = 480;
  componentPortalFilter: ComponentPortal<EntiteItemHeaderComponent> | any;

  constructor(
    private activieRoute: ActivatedRoute,
    private router: Router,
    private entiteStore: Store<EntiteState>,
    private portalNavigationService: PortalNavigationService,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private sideNavigationStore: Store<SideNavigationState>
  ) {}

  ngOnInit(): void {
    this.getRouteData();
    this.getEntite();

    this.getStatistiques();
    this.componentPortalFilter = new ComponentPortal(EntiteItemHeaderComponent);
    this.setFilterPortal();
    this.onSetNavigation('entite');

    this.onNavigation();
  }

  ngAfterViewInit(): void {
    //this.loadStatistiques();
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
    this.componentPortalFilter.detach();
  }

  getRouteData(): void {
    this.routeData$ = this.activieRoute.data;
  }

  getEntite(): void {
    this.routeData$
      .pipe(takeUntil(this.subscribe))
      .subscribe((data: any): any => {
        if (!data?.entite) {
          this.router.navigate(['/entites']);
          return EMPTY;
        } else {
          this.entite$ = data?.entite;
        }
      });
  }

  loadStatistiques(): void {
    this.entiteStore
      .select(fromEntiteSelector.entite)
      .pipe(takeUntil(this.subscribe))
      .subscribe((entite: EntiteId): any => {
        if (!entite) {
          this.router.navigate(['/entites']);
          return EMPTY;
        } else {
          this.entiteStore.dispatch(
            fromEntiteAction.loadEntiteStatistiques({ id: entite.id })
          );
        }
      });
  }

  onSetNavigation(navigation: string): void {
    this.sideNavigationStore.dispatch(
      sideNavigationAction.selectSideNavigation({
        navigation: { type: 'side', name: navigation },
      })
    );
  }
  onNavigation(): void {
    this.sideNavigationStore
      .select(sideNavigationSelector.navigation)
      .pipe(takeUntil(this.subscribe))
      .subscribe((navigation) => {
        switch (navigation?.name) {
          case 'entite_collaborateurs':
            this.bodyComponent = 'collaborateurs';

            break;
          case 'entite_suivis':
            this.bodyComponent = 'suivis';

            break;
          case 'entite_documents':
            this.bodyComponent = 'documents';
            break;
          case 'entite_metiers':
            this.bodyComponent = 'metiers';

            break;
          case 'entite_projets':
            this.bodyComponent = 'projets';

            break;
          case 'entite_tasks':
            this.bodyComponent = 'tasks';

            break;
          default:
            this.bodyComponent = 'details';

            break;
        }
      });
  }

  getStatistiques(): void {
    this.statistiques$ = this.entiteStore.select(
      fromEntiteSelector.statistiques
    );
  }
  setFilterPortal(): void {
    this.portalNavigationService.setPortal(this.componentPortalFilter);
  }

  onSelectActions(item: string, entite: EntiteId): void {
    switch (item) {
      case 'details':
        this.bodyComponent = item;
        break;
      case 'update':
        this.onModify(entite);
        break;
      case 'export':
        this.onExport(entite);
        break;

      case 'collaborateurs':
        this.bodyComponent = item;
        break;
      case 'projets':
        this.bodyComponent = item;
        break;
      case 'documents':
        this.bodyComponent = item;
        break;
      case 'suivis':
        this.bodyComponent = item;
        break;
      case 'dashboard':
        this.bodyComponent = item;
        break;
      case 'metiers':
        this.bodyComponent = item;
        break;
      case 'besoins':
        this.bodyComponent = item;
        break;
      case 'delete':
        this.onDelete(entite);
        break;
      default:
        break;
    }
  }

  onModify(entite: EntiteId): void {
    if (!entite) return;
    const modal = this.modal.create({
      nzContent: EntiteFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: '',
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });

    const instance = modal.getContentComponent();
    instance.entite$ = of(entite);

    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
  }

  onExport(entite: EntiteId): void {
    if (!entite) return;
    const modal = this.modal.create({
      nzContent: ExportSelectComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalExportWidth,
      nzFooter: [],
      nzTitle: `Exporter la fiche entité`,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
    });

    const instance = modal.getContentComponent();

    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
    instance.select.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        this.onExportValidation(event);
        modal.close();
      }
    });
  }

  onExportValidation(type: string): void {
    switch (type) {
      case 'PDF':
        break;
      case 'EXCEL':
        break;
      case 'PPT':
        break;

      default:
        break;
    }
  }

  onDelete(entite: EntiteId): void {
    if (!entite) return;
    this.entiteStore.dispatch(fromEntiteAction.deleteEntite({ id: entite.id }));
  }
}
