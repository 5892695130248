import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BehaviorSubject, of, Subject, Observable } from 'rxjs';
import { OperationId } from 'src/app/components/operation/store/operation.model';

@Component({
  selector: 'app-fiches-de-poste-filters',
  templateUrl: './fiches-de-poste-filters.component.html',
  styleUrls: ['./fiches-de-poste-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FichesDePosteFiltersComponent
  implements OnInit, OnChanges, OnDestroy
{
  buttonColor: string = '#597ef7';
  modalWidth: number = 950;
  subscribe = new Subject();
  inputSearch$ = new BehaviorSubject<string>('');
  inputModel: string = '';

  @Input() operation$: Observable<OperationId | any> = of();
  @Input() fiches$: Observable<any> = of([]);

  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  onSeach(event: string, operation: OperationId): void {
    // if (!event) {
    //   this.actionStore.dispatch(
    //     fromActionAction.loadActions({ operationId: operation.id })
    //   );
    //   return;
    // } else {
    //   const filter = {
    //     operationId: operation.id,
    //     event: event,
    //     type: 'title',
    //   };
    //   this.actionStore.dispatch(fromActionAction.queryActions({ filter }));
    // }
  }

  onClearMdole(): void {}
}
