import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'any',
})
export class QueryParticipantPlanningGQL extends Query<Response> {
  document = gql`
    query ParticipantPlanningEvent($participantId: ID!, $id: ID!) {
      participantPlanningEvent(participantId: $participantId, id: $id) {
        id
        type {
          label
          color
          colorLabel
          value
        }
        content
        createAt
        auteur {
          avatar
          displayName
          fonction
          setting {
            color
          }
        }

        referent {
          avatar
          displayName
          fonction
          setting {
            color
          }
        }
        dateStart
        dateUpdate
        isValided
        isPresent
        userUpdate {
          avatar
          displayName
          fonction
          setting {
            color
          }
        }
      }
    }
  `;
}
