import { DepartementService } from './departement.service';
import { Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  doc,
  docSnapshots,
  collectionChanges,
  getDoc,
  serverTimestamp,
  DocumentData,
  Timestamp,
  query,
  where,
  collectionSnapshots,
  getDocs,
  documentId,
} from '@angular/fire/firestore';
import { Observable, of, from } from 'rxjs';
import { switchMap, mergeMap, map, catchError } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { UserState } from '../components/user/store/user.reducer';
import * as fromUserSelector from '../components/user/store/user.selectors';
import { DepartementId } from '../components/departement/store/departement.model';

@Injectable({
  providedIn: 'any',
})
export class QueryDepartementsService {
  constructor(
    private departementService: DepartementService,
    private db: Firestore,
    private userStore: Store<UserState>
  ) {}

  queryDepartements(filter: {
    event: string;
    type: string;
  }): Observable<DepartementId[] | any> {
    try {
      return this.userStore.pipe(
        select(fromUserSelector.user),
        switchMap((user: any): any => {
          if (user) {
            switch (filter.type) {
              case 'title':
                return this.queryTitle(filter.event);
                break;
              default:
                return this.departementService.getAll();
                break;
            }
          } else {
            return of(null);
          }
        })
      );
    } catch (error) {
      return of(null);
    }
  }

  queryTitle(value: string): Observable<DepartementId[] | any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (user) {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/departements`
            );
            const dbQuery = query(
              dbCollection,
              where(
                'metadata.arrayTitle',
                'array-contains',
                value.toUpperCase()
              )
            );

            return from(
              getDocs(dbQuery)
                .then((snap) => {
                  return snap.docs.map((doc: DocumentData) => {
                    const data = doc.data() as any;
                    const id = doc.id;
                    return { id, ...data };
                  });
                })
                .catch((error) => error)
            ).pipe(mergeMap((res) => of(res)));
          } else {
            return of(null);
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }
}
