<div
  class="w-full h-full flex items-start flex-col overflow-hidden space-y-4"
  *ngIf="utility$ | async as utility"
>
  <div class="w-full mb-8">
    <div class="w-full">
      <form
        nz-form
        [formGroup]="event"
        nzLayout="vertical"
        class="w-full space-y-1"
      >
        <div class="w-full flex items-start justify-between space-x-2">
          <div class="w-3/12">
            <nz-form-item nzRequired="true">
              <nz-form-control nzErrorTip="Aucun moment renseigné!">
                <nz-date-picker
                  nzShowTime
                  nzBorderless
                  nzFormat="dd/MM/yyyy HH:mm"
                  nzPlaceHolder="Date..."
                  formControlName="dateStart"
                  nzAllowClear="true"
                ></nz-date-picker>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div class="w-3/12">
            <nz-form-item nzRequired="true">
              <nz-form-control nzErrorTip="Aucun type renseigné!">
                <nz-select
                  nzBorderless
                  nzShowSearch
                  nzPlaceHolder="Type..."
                  formControlName="type"
                  [compareWith]="formCompare"
                >
                  <nz-option
                    *ngFor="let item of objetOrder(utility.type)"
                    [nzLabel]="item"
                    [nzValue]="item"
                  >
                  </nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div class="w-6/12">
            <nz-form-item nzRequired="true">
              <nz-form-control nzErrorTip="Aucun objet renseigné!">
                <nz-select
                  nzBorderless
                  nzShowSearch
                  nzPlaceHolder="Objet..."
                  formControlName="object"
                  [compareWith]="formCompare"
                >
                  <nz-option
                    *ngFor="let item of objetOrder(utility.object)"
                    [nzLabel]="item"
                    [nzValue]="item"
                  >
                  </nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div class="w-full flex items-start justify-between space-x-2">
          <nz-form-item nzRequired="true" class="w-full">
            <nz-form-control nzErrorTip="Aucun titre renseigné!" class="w-full">
              <input
                nzBorderless
                formControlName="title"
                nz-input
                placeholder="Titre"
              />
            </nz-form-control>
          </nz-form-item>
        </div>
      </form>
    </div>

    <div class="w-full flex items-start">
      <app-text-edithing
        [size]="size"
        [update]="edithUpdate"
        (add)="onNewText($event)"
        (modify)="onModifyText($event)"
      >
      </app-text-edithing>
    </div>
  </div>
  <div class="w-full flex items-end justify-end space-x-2">
    <div>
      <button class="btn btn-sm btn-ghost" (click)="onCancel()">Annuler</button>
    </div>
    <div>
      <button
        [class]="
          !event.valid ? 'btn btn-sm btn-disabled' : 'btn btn-sm btn-primary'
        "
        [disabled]="!event.valid"
        *ngIf="!updateActivity"
        (click)="$event.stopPropagation(); onAdd()"
      >
        Ajouter
      </button>
      <button
        [class]="
          !event.valid ? 'btn btn-sm btn-disabled' : 'btn btn-sm btn-primary'
        "
        [disabled]="!event.valid"
        *ngIf="updateActivity"
        (click)="$event.stopPropagation(); onUpdate(item)"
      >
        Valider les modifications
      </button>
    </div>
  </div>
</div>

<ng-template #cardTitle>
  <h5 nz-typography>{{ titleForm }}</h5>
</ng-template>

<ng-template #itemEventExtraBtn> </ng-template>
