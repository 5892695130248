import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Router } from '@angular/router';
import { AuthState } from './../../store/auth.reducer';
import { BehaviorSubject, fromEvent, Observable, of, Subject } from 'rxjs';
import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  debounceTime,
  map,
  takeUntil,
  tap,
  distinctUntilChanged,
} from 'rxjs/operators';
import * as fromAuthAction from '../../store/auth.actions';

import * as fromAuthSelector from '../../store/auth.selectors';
import { Store } from '@ngrx/store';
import { Auth } from '@angular/fire/auth';
import { EmailValidation } from '../../store/auth.model';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { fade } from 'src/app/app-animation';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { UserFormComponent } from 'src/app/components/user/components/user-form/user-form.component';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [fade],
})
export class LoginComponent implements OnInit, OnDestroy, AfterContentInit {
  title: string = 'Protein';
  titleForm: string = 'Authentification';
  forgot: string = 'Mot de passe oublié ?';
  logoLink: string = '../../../../../assets/protein_logo.png';
  errorMessage: string = "Error d'authentification.";
  alertMessageNoEmail: string = 'Veuillez renseigner votre e-amil';
  createNewUser__Title: string =
    "Vous n'avez pas encore de compte ? Créez-en un !";
  logginEmailForm: FormGroup = new FormGroup({});
  hide = true;
  majHide = false;
  forgetPass = false;
  subscribe = new Subject();
  isEmailExist$: Observable<boolean> = of(false);
  isUserExist$: Observable<boolean> = of(false);
  error$: Observable<any> = of({ message: 'ERROR' });
  loading$: Observable<boolean> = of(false);
  loadingEmailCheck$: Observable<boolean> = of(false);

  emailValidationCheck$: Observable<EmailValidation | any> = of(null);
  isScreenSmall$: Observable<boolean> = of(false);
  modalWidth: number = 480;
  newUserTitle: string = 'Créer votre profil';

  options: AnimationOptions = {
    path: '../assets/animations/loge_page_animation_whiteBG.json',
    loop: true,
    autoplay: true,
  };

  lottie: Partial<CSSStyleDeclaration> = {
    marginBottom: '-50rem !important',
  };

  animationCreated(animationItem: AnimationItem): void {}

  @ViewChild('userFormTitle', { static: false }) userFormTitle:
    | TemplateRef<any>
    | any;

  @ViewChild('verificationBtn', { static: true }) verificationBtn:
    | ElementRef
    | any;

  email: FormControl = new FormControl<string>('', [
    Validators.required,
    Validators.email,
    Validators.pattern('[^@]*@[^@]*'),
  ]);
  password: FormControl = new FormControl<string>('', [Validators.required]);

  logginForm = new FormGroup({
    email: this.email,
    password: this.password,
  });
  constructor(
    private fb: UntypedFormBuilder,
    private authStore: Store<AuthState>,
    private auth: Auth,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private notification: NzNotificationService
  ) {
    this.breackPointer();
  }

  ngOnInit(): void {
    this.getError();
    this.getEmailValidationCheck();
    this.getLaoding();
    this.getLaodingEmailCheck();
  }

  ngAfterContentInit(): void {}

  breackPointer(): void {
    this.isScreenSmall$ = this.breakpointObserver
      .observe(['(max-width: 968px)'])
      .pipe(map(({ matches }) => matches));
  }

  isAlreayLogin(): void {
    if (this.auth.currentUser?.uid) {
      this.router.navigate(['user']);
    } else {
      this.router.navigate(['login']);
    }
  }

  getEmailValidationCheck(): void {
    this.emailValidationCheck$ = this.authStore.select(
      fromAuthSelector.emailValidation
    );
  }

  isValidateEmail = (email: string) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  emailVerification(): void {
    const values = this.logginForm.value;

    if (!values) {
      this.notification.warning('Erreur', 'Aucune valeur renseignée !', {
        nzDuration: 6000,
        nzAnimate: true,
        nzKey: 'LOGIN_EMAIL_VERIFICATION',
        nzPlacement: 'topRight',
      });
      return;
    }
    if (!values?.email?.length) {
      this.notification.warning('Erreur', 'Adresse e-mail erronée !', {
        nzDuration: 6000,
        nzAnimate: true,
        nzKey: 'LOGIN_EMAIL_VERIFICATION',
        nzPlacement: 'topRight',
      });
      return;
    }
    const { email } = values;
    if (!email) {
      this.notification.warning('Erreur', 'Aucun e-mail renseigné !', {
        nzDuration: 6000,
        nzAnimate: true,
        nzKey: 'LOGIN_EMAIL_VERIFICATION',
        nzPlacement: 'topRight',
      });

      return;
    }
    if (!this.isValidateEmail(email)) {
      this.notification.warning('Erreur', 'E-mail invalide !', {
        nzDuration: 6000,
        nzAnimate: true,
        nzKey: 'LOGIN_EMAIL_VERIFICATION',
        nzPlacement: 'topRight',
      });
      return;
    }
    this.authStore.dispatch(fromAuthAction.emailCheck({ email }));
  }

  formEmailCreate() {
    this.logginEmailForm = new FormGroup({
      email: new FormControl<string>('', [
        Validators.required,
        Validators.email,
        Validators.pattern('[^@]*@[^@]*'),
      ]),
    });
  }

  onChange(): void {
    this.logginForm.valueChanges
      .pipe(takeUntil(this.subscribe))
      .subscribe((value: any) => {
        if (!value) {
          return null;
        } else {
          return null;
        }
      });
  }

  onSubmit(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    if (!this.logginForm.valid) {
      this.logginForm.reset();
      return;
    }

    const { email, password } = this.logginForm.value;
    const MESSAGE_ALERT: string = `${
      !email ? 'Votre E-mail' : !password ? 'Votre mot de passe' : ''
    } n'est pas rensignée`;

    if (!email || !password) {
      this.notification.warning('Alerte', MESSAGE_ALERT, {
        nzDuration: 6000,
        nzAnimate: true,
        nzPlacement: 'topRight',
      });
      return;
    }

    this.authStore.dispatch(
      fromAuthAction.login({ email: email.trim(), password: password.trim() })
    );
  }

  onPasswordForget(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.forgetPass = true;
  }

  onPasswordForgetCancel(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.forgetPass = false;
  }
  onPasswordForgetValidation(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    const { email } = this.logginForm.value;
    const register = {
      lastName: '',
      firstName: '',
      pass: '',
      coordonnees: '',
    };
    if (email) {
      this.forgetPass = false;
    }
  }

  onMajuscul(event: any): void {
    if (event.getModifierState('CapsLock')) {
      this.majHide = true;
    } else {
      this.majHide = false;
    }
  }

  onForgetPassword(): void {
    this.authStore.dispatch(fromAuthAction.lostPassword());
  }

  onCreate_User(): void {
    const modal = this.modal.create({
      nzContent: UserFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.userFormTitle,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
    });

    const instance = modal.getContentComponent();

    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
    instance.add.subscribe((operation): any => {
      if (!operation) {
        return null;
      } else {
        modal.close();
      }
    });
    instance.update.subscribe((operation): any => {
      if (!operation) {
        return null;
      } else {
        modal.close();
      }
    });
  }

  onNewAccount(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.router.navigate(['/account']);
  }

  getLaoding(): void {
    this.loading$ = this.authStore.select(fromAuthSelector.loading);
  }

  getLaodingEmailCheck(): void {
    this.loadingEmailCheck$ = this.authStore.select(
      fromAuthSelector.loadingCheckEmail
    );
  }

  getError(): void {
    this.error$ = this.authStore.select(fromAuthSelector.error);
  }

  onCancel(): void {
    this.logginForm.reset();
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }
}
