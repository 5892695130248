<nz-card>
  <app-excel-json-viewer
    [render]="moduleTableViewerTpl"
    (import)="onImport($event)"
  ></app-excel-json-viewer>
</nz-card>

<nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
<ng-template #moduleTableViewerTpl>
  <nz-table
    #moduleTable
    [nzData]="source"
    nzTableLayout="fixed"
    nzSize="small"
    [nzScroll]="scroll"
    [nzLoadingDelay]="2"
    [nzBordered]="false"
    [nzOuterBordered]="false"
    [nzPageSize]="5"
  >
    <thead>
      <tr>
        <th nzLeft nzWidth="150px">Lots</th>
        <th nzLeft nzWidth="150px">Entreprises</th>
        <th>Montant</th>
        <th>Démarrage</th>
        <th>Livraison</th>
        <th nzRight>Heures théroriques</th>
        <th>ETP théroriques</th>
        <th nzRight>Heures contractuelles</th>
        <th nzRight></th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let data of moduleTable.data"
        (click)="$event.stopPropagation()"
      >
        <td nzLeft nzWidth="100px">
          {{ data?.title | carLimitation: 40 }}
        </td>
        <td nzLeft>{{ data?.entiteName | carLimitation: 40 }}</td>
        <td>
          {{ data?.montant | currency: "EUR":"symbol-narrow" }}
        </td>

        <td>
          <span *ngIf="data?.dateStart">{{ data?.dateStart }}</span>
        </td>
        <td>
          <span *ngIf="data?.dateEnd">{{ data?.dateEnd }}</span>
        </td>
        <td nzRight>
          {{ data?.objectifTheorique }}
        </td>
        <td>
          {{ data?.etpTheorique }}
        </td>
        <td nzRight>
          <span *ngIf="data?.objectifContrat">{{ data?.objectifContrat }}</span>
          <input
            *ngIf="!data?.objectifContrat"
            nz-input
            nzSize="small"
            placeholder="heures"
          />
        </td>

        <td nzRight>
          <app-button-dropdown
            [direction]="'horizontal'"
            (click)="$event.stopPropagation()"
            [isDelete]="true"
            [deleteType]="'Voulez-vous supprimer ce module ?'"
          ></app-button-dropdown>
        </td>
      </tr>
    </tbody>
  </nz-table>
</ng-template>

<nz-row nzAlign="middle" nzGutter="8" nzJustify="center">
  <nz-col nzSpan="12"></nz-col>
  <nz-col nzSpan="6">
    <button
      nz-button
      nzBlock
      nzType="text"
      (click)="$event.stopPropagation(); onCancel()"
    >
      Annuler
    </button>
  </nz-col>
  <nz-col nzSpan="6">
    <button
      nz-button
      nzBlock
      nzType="primary"
      (click)="$event.stopPropagation(); onAdd()"
    >
      Ajouter
    </button>
  </nz-col>
</nz-row>
