import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { OperationTeam } from './operation-team.model';
import * as OperationTeamActions from './operation-team.actions';

export const operationTeamsFeatureKey = 'operationTeams';

export interface OperationTeamState extends EntityState<OperationTeam> {
  loading: boolean;
  userItem: any;
  teamSelection: any;
  error: any;
}

export const adapter: EntityAdapter<OperationTeam> =
  createEntityAdapter<OperationTeam>();

export const initialState: OperationTeamState = adapter.getInitialState({
  loading: false,
  userItem: undefined,
  teamSelection: undefined,
  error: undefined,
});

export const reducer = createReducer(
  initialState,

  //LOAD
  on(OperationTeamActions.loadOperationTeamsSucces, (state, action) =>
    adapter.setAll(action.team, state)
  ),
  on(OperationTeamActions.loadOperationTeamsFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
      loading: false,
    };
  }),

  //LOAD OPERATION TEAM
  on(OperationTeamActions.loadOperationTeamsSelection, (state, action) => {
    return {
      ...state,
      error: undefined,
      loading: true,
    };
  }),
  on(
    OperationTeamActions.loadOperationTeamsSelectionSucces,
    (state, action) => {
      return {
        ...state,
        teamSelection: action.team,
        loading: false,
      };
    }
  ),
  on(
    OperationTeamActions.loadOperationTeamsSelectionFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
  ),

  //ADD

  on(OperationTeamActions.addOperationTeamsFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
      loading: false,
    };
  }),

  //UPDATE
  on(OperationTeamActions.updateOperationTeam, (state, action) =>
    adapter.updateOne(action.user, state)
  ),
  on(OperationTeamActions.updateOperationTeamFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
      loading: false,
    };
  }),

  //DELETE ONE
  on(OperationTeamActions.deleteOperationTeam, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(OperationTeamActions.deleteOperationTeamFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
      loading: false,
    };
  }),

  //DELETE All
  on(OperationTeamActions.deleteOperationTeams, (state, action) =>
    adapter.removeAll(state)
  ),
  on(OperationTeamActions.deleteOperationTeamsFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
      loading: false,
    };
  }),

  //CLEAR
  on(OperationTeamActions.clearOperationTeams, (state) =>
    adapter.removeAll(state)
  )
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
