import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Mission } from './mission.model';
import * as MissionActions from './mission.actions';

export const missionsFeatureKey = 'missions';

export interface MissionState extends EntityState<Mission> {
  loading: boolean;
}

export const adapter: EntityAdapter<Mission> = createEntityAdapter<Mission>();

export const initialState: MissionState = adapter.getInitialState({
  loading: false,
});

export const reducer = createReducer(
  initialState,
  on(MissionActions.loadMissions, (state, action) =>
    adapter.setAll(action.missions, state)
  ),
  on(MissionActions.exportMissionPDF, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(MissionActions.exportMissionPDFSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
    };
  }),
  on(MissionActions.exportMissionPDFFailure, (state, action) => {
    return {
      ...state,
      loading: false,
    };
  }),

  on(MissionActions.clearMissions, (state) => adapter.removeAll(state))
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
