<nz-row [nzGutter]="[8, 24]" nzJustify="start" nzAlign="middle">
  <nz-col
    [nzXs]="{ span: 24 }"
    [nzMd]="{ span: 12 }"
    [nzLg]="{ span: 8 }"
    [nzXl]="{ span: 6 }"
  >
    <nz-card
      nzSize="small"
      [nzCover]="cardTaches"
      nzHoverable="true"
      class="USER__header_cardTaches"
      (click)="onSelect('todoToday')"
    >
    </nz-card>
    <ng-template #cardTaches>
      <div class="USER__headerCardContent">
        <section class="USER__headerCardContent_head">
          <div class="USER__headerCardContent_head_date">
            <app-header-title [icon]="'events'" [title]="''"></app-header-title>
          </div>
          <div class="USER__headerCardContent_headNumber"></div>
          <nz-tag nzColor="blue"><h4>Tâches du jour</h4></nz-tag>
        </section>

        <section class="USER__headerCardContent_counter">
          <span>{{
            (todos$ | async | todosCalendar : "today")?.items.length
          }}</span>
        </section>

        <section class="USER__headerCardContent_priority">
          <nz-tag nzColor="cyan" class="USER__indicators_tagDate">
            <span>{{ todayString }}</span>
            <span>{{ currentWeekNumber }} sem.</span>
          </nz-tag>
          <!-- <div class="USER__heardCardContent_priorityIndicator">
            <i nz-icon nzTheme="outline" nzType="flag"></i>
            <span class="USER__headerCardContent_IndicatorNumber">{{
              TODOS__nowPriority
            }}</span>
            <span
              *ngIf="
                (todos$ | async | todosCalendar: 'today')?.priority.length ===
                  1 ||
                (todos$ | async | todosCalendar: 'today')?.priority.length === 0
              "
              >priorité</span
            >
            <span
              *ngIf="
                (todos$ | async | todosCalendar: 'today')?.priority.length > 1
              "
              >priorités</span
            >
          </div>
          <div class="USER__heardCardContent_lateIndicator">
            <i
              nz-icon
              nzTheme="fill"
              nzType="flag"
              nzDanger
              class="USER__headerCardContent_LateIcon"
            ></i>
            <span class="USER__headerCardContent_IndicatorNumber">{{
              (todos$ | async | todosCalendar: "latest")?.items.length
            }}</span>
            <span>en retard</span>
          </div> -->
        </section>
      </div>
    </ng-template>
  </nz-col>
  <nz-col
    [nzXs]="{ span: 0 }"
    [nzMd]="{ span: 12 }"
    [nzLg]="{ span: 8 }"
    [nzXl]="{ span: 6 }"
  >
    <nz-card
      nzSize="small"
      [nzCover]="cardTachesFutur"
      nzHoverable="true"
      class="USER__header_cardTachesFutur"
      (click)="onSelect('todoFutur')"
    >
    </nz-card>
    <ng-template #cardTachesFutur>
      <div class="USER__headerCardContent">
        <section class="USER__headerCardContent_head">
          <div class="USER__headerCardContent_head_date">
            <app-header-title
              [icon]="'schedule'"
              [title]="''"
            ></app-header-title>
          </div>

          <nz-tag nzColor="processing"><h4>Tâches à venir</h4></nz-tag>
        </section>

        <section class="USER__headerCardContent_counter">
          <span>{{
            (todos$ | async | todosCalendar : "futur")?.items.length
          }}</span>
        </section>

        <!-- <section class="USER__headerCardContent_priority">
          <div>
            <i nz-icon nzTheme="outline" nzType="flag"></i>
            <span class="USER__headerCardContent_IndicatorNumber">{{
              (todos$ | async | todosCalendar: "futur")?.items.length
            }}</span>
            <span
              *ngIf="
                (todos$ | async | todosCalendar: 'futur')?.priority.length ===
                  1 ||
                (todos$ | async | todosCalendar: 'futur')?.priority.length === 0
              "
              >priorité</span
            >
            <span
              *ngIf="
                (todos$ | async | todosCalendar: 'futur')?.priority.length > 1
              "
              >priorités</span
            >
          </div>
          <div></div>
        </section> -->
      </div>
    </ng-template>
  </nz-col>
  <nz-col
    [nzXs]="{ span: 0 }"
    [nzMd]="{ span: 0 }"
    [nzLg]="{ span: 8 }"
    [nzXl]="{ span: 6 }"
  >
    <nz-card
      nzSize="small"
      [nzCover]="cardActions"
      nzHoverable="true"
      class="USER__header_cardActions"
      (click)="onSelect('entites')"
    >
    </nz-card>
    <ng-template #cardActions>
      <div class="USER__headerCardContent">
        <section class="USER__headerCardContent_head">
          <div class="USER__headerCardContent_head_date">
            <app-header-title
              [icon]="'star_outline'"
              [title]="''"
            ></app-header-title>
          </div>
          <nz-tag nzColor="purple"><h4>Entités</h4></nz-tag>
        </section>

        <section class="USER__headerCardContent_counter">
          <span
            *ngIf="
              (favorisEntites$ | async)?.data as favorisEntites;
              else noFavorisEntites
            "
          >
            {{ favorisEntites.length }}
          </span>
          <ng-template #noFavorisEntites>
            <span>{{ noTODOS }}</span>
          </ng-template>
        </section>

        <section
          class="USER__headerCardContent_favoris"
          *ngIf="(favorisEntites$ | async)?.data as favorisEntites"
        >
          <div class="USER__headerCardContent_favoris_iconNdate">
            <i nz-icon nzTheme="outline" nzType="alert"></i>
            <span class="USER__headerCardContent_favoris_date">{{
              favorisEntites | indicatorCard : "date"
            }}</span>
          </div>
          <span class="USER__headerCardContent_favoris_nom">
            {{
              favorisEntites
                | indicatorCard : "name"
                | titlecase
                | carLimitation : 40
            }}
          </span>
        </section>
      </div>
    </ng-template>
  </nz-col>
  <nz-col
    [nzXs]="{ span: 0 }"
    [nzMd]="{ span: 0 }"
    [nzLg]="{ span: 0 }"
    [nzXl]="{ span: 6 }"
  >
    <nz-card
      nzSize="small"
      [nzCover]="cardActionsFutur"
      nzHoverable="true"
      class="USER__header_cardActionsFutur"
      (click)="onSelect('participants')"
    >
    </nz-card>
    <ng-template #cardActionsFutur>
      <div class="USER__headerCardContent">
        <section class="USER__headerCardContent_head">
          <div class="USER__headerCardContent_head_date">
            <app-header-title
              [icon]="'star_outline'"
              [title]="''"
            ></app-header-title>
          </div>

          <nz-tag nzColor="cyan"><h4>Participants</h4></nz-tag>
        </section>

        <section class="USER__headerCardContent_counter">
          <span
            *ngIf="
              (favorisParticipants$ | async)?.data as favorisParticipants;
              else noFavorisParticipants
            "
          >
            {{ favorisParticipants.length }}
          </span>
          <ng-template #noFavorisParticipants>
            <span>{{ noTODOS }}</span>
          </ng-template>
        </section>

        <section
          *ngIf="(favorisParticipants$ | async)?.data as favorisParticipants"
          class="USER__headerCardContent_favoris"
        >
          <div class="USER__headerCardContent_favoris_iconNdate">
            <i nz-icon nzTheme="outline" nzType="alert"></i>
            <span class="USER__headerCardContent_favoris_date">{{
              favorisParticipants | indicatorCard : "date"
            }}</span>
          </div>
          <span class="USER__headerCardContent_favoris_nom">
            {{
              favorisParticipants
                | indicatorCard : "name"
                | titlecase
                | carLimitation : 50
            }}
          </span>
        </section>
      </div>
    </ng-template>
  </nz-col>
</nz-row>
