import { OperationNoteSubtitleService } from './../../../service/operation-note-subtitle.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, timer, concatMap, delay } from 'rxjs';
import { tap, mergeMap, exhaustMap, map, catchError } from 'rxjs/operators';
import * as fromOperationNoteSubtitleAction from './operation-note-subtitle.actions';
import { OperationNoteSubtitleId } from './operation-note-subtitle.model';
import {
  NzNotificationPlacement,
  NzNotificationService,
} from 'ng-zorro-antd/notification';

const NOTIFICATION_PLACEMENT: NzNotificationPlacement = 'topRight';

@Injectable()
export class OperationNoteSubtitleEffects {
  source = of(1, 2, 3);

  //LOAD ALL
  loadOperationNoteSubtitles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationNoteSubtitleAction.loadOperationNoteSubtitles),
      tap(() =>
        fromOperationNoteSubtitleAction.loadingOperationNoteSubtitles({
          loading: true,
        })
      ),
      mergeMap((action) =>
        this.operationNoteSubtitleService.getAll(action.note).pipe(
          delay(2000),
          map((substitles: OperationNoteSubtitleId[]) =>
            fromOperationNoteSubtitleAction.loadOperationNoteSubtitlesSuccess({
              substitles,
            })
          ),
          catchError((error) => {
            this.message.error(error, { nzDuration: 6000 });

            return of(
              fromOperationNoteSubtitleAction.loadOperationNoteSubtitlesFailure(
                { error }
              )
            );
          })
        )
      ),
      tap(() =>
        fromOperationNoteSubtitleAction.loadingOperationNoteSubtitles({
          loading: false,
        })
      )
    )
  );

  //ADD
  addOperationNoteSubtitle$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromOperationNoteSubtitleAction.addOperationNoteSubtitle),
        tap(() => {
          this.notification.info(
            'Création du titre',
            'Création du titre en cours...',
            {
              nzDuration: 6000,
              nzAnimate: true,
              nzPlacement: NOTIFICATION_PLACEMENT,
              nzKey: 'ADD_ONE_OPERATION_NOTE_SUBTITLE',
            }
          );
          fromOperationNoteSubtitleAction.loadingOperationNoteSubtitles({
            loading: true,
          });
        }),
        mergeMap((action) =>
          this.operationNoteSubtitleService
            .addOne(action.note, action.subtitle)
            .pipe(
              map((success: string) => {
                this.notification.success('Création du titre', success, {
                  nzDuration: 8000,
                  nzAnimate: true,
                  nzPlacement: NOTIFICATION_PLACEMENT,
                  nzKey: 'ADD_ONE_OPERATION_NOTE_SUBTITLE',
                });
                return fromOperationNoteSubtitleAction.addOperationNoteSubtitleSuccess(
                  {
                    success,
                  }
                );
              }),
              catchError((error) => {
                this.onErrorNotification(
                  error,
                  'ADD_ONE_OPERATION_NOTE_SUBTITLE'
                );

                return of(
                  fromOperationNoteSubtitleAction.addOperationNoteSubtitleFailure(
                    { error }
                  )
                );
              })
            )
        ),
        tap(() =>
          fromOperationNoteSubtitleAction.loadingOperationNoteSubtitles({
            loading: false,
          })
        )
      ),
    { dispatch: false }
  );

  //UPDATE
  updateOperationNoteSubtitle$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromOperationNoteSubtitleAction.updateOperationNoteSubtitle),
        tap(() => {
          this.notification.info(
            'Modification du titre',
            'Modification du titre en cours...',
            {
              nzDuration: 6000,
              nzAnimate: true,
              nzPlacement: NOTIFICATION_PLACEMENT,
              nzKey: 'UPDATE_ONE_OPERATION_NOTE_SUBTITLE',
            }
          );
          fromOperationNoteSubtitleAction.loadingOperationNoteSubtitles({
            loading: true,
          });
        }),
        mergeMap((action) =>
          this.operationNoteSubtitleService
            .updateOne(action.note, action.subtitle.changes)
            .pipe(
              map((success: any) => {
                this.notification.success('Modification du titre', success, {
                  nzDuration: 8000,
                  nzAnimate: true,
                  nzPlacement: NOTIFICATION_PLACEMENT,
                  nzKey: 'UPDATE_ONE_OPERATION_NOTE_SUBTITLE',
                });
                return fromOperationNoteSubtitleAction.updateOperationNoteSubtitleSuccess(
                  {
                    success,
                  }
                );
              }),
              catchError((error) => {
                this.onErrorNotification(
                  error,
                  'UPDATE_ONE_OPERATION_NOTE_SUBTITLE'
                );

                return of(
                  fromOperationNoteSubtitleAction.updateOperationNoteSubtitleFailure(
                    { error }
                  )
                );
              })
            )
        ),
        tap(() =>
          fromOperationNoteSubtitleAction.loadingOperationNoteSubtitles({
            loading: false,
          })
        )
      ),
    { dispatch: false }
  );

  //DELETE
  deleteOperationNoteSubtitle$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromOperationNoteSubtitleAction.deleteOperationNoteSubtitle),
        tap(() => {
          this.notification.info(
            'Suppression du titre',
            'Suppression du titre en cours...',
            {
              nzDuration: 6000,
              nzAnimate: true,
              nzPlacement: NOTIFICATION_PLACEMENT,
              nzKey: 'DELETE_ONE_OPERATION_NOTE_SUBTITLE',
            }
          );
          fromOperationNoteSubtitleAction.loadingOperationNoteSubtitles({
            loading: true,
          });
        }),
        mergeMap((action) =>
          this.operationNoteSubtitleService
            .deleteOne(action.note, action.id)
            .pipe(
              map((success: any) => {
                this.notification.success('Suppression du titre', success, {
                  nzDuration: 8000,
                  nzAnimate: true,
                  nzPlacement: NOTIFICATION_PLACEMENT,
                  nzKey: 'DELETE_ONE_OPERATION_NOTE_SUBTITLE',
                });
                return fromOperationNoteSubtitleAction.deleteOperationNoteSubtitleSuccess(
                  {
                    success,
                  }
                );
              }),
              catchError((error) => {
                this.onErrorNotification(
                  error,
                  'DELETE_ONE_OPERATION_NOTE_SUBTITLE'
                );

                return of(
                  fromOperationNoteSubtitleAction.deleteOperationNoteSubtitleFailure(
                    { error }
                  )
                );
              })
            )
        ),
        tap(() =>
          fromOperationNoteSubtitleAction.loadingOperationNoteSubtitles({
            loading: false,
          })
        )
      ),
    { dispatch: false }
  );

  //DELETE ALL
  deleteAllOperationNoteSubtitles$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromOperationNoteSubtitleAction.deleteAllOperationNoteSubtitles),
        tap(() => {
          this.notification.info(
            'Suppression des titres de la note',
            'Suppression des titres en cours...',
            {
              nzDuration: 6000,
              nzAnimate: true,
              nzPlacement: NOTIFICATION_PLACEMENT,
              nzKey: 'DELETE_ALL_OPERATION_NOTE_SUBTITLE',
            }
          );
          fromOperationNoteSubtitleAction.loadingOperationNoteSubtitles({
            loading: true,
          });
        }),
        mergeMap((action) =>
          this.operationNoteSubtitleService.deleteAll(action.note).pipe(
            map((respons: any) => {
              const success = respons.data.deleteAllOperationNoteSubtitles;

              this.notification.success(
                'Suppression des titres de la note',
                success,
                {
                  nzDuration: 8000,
                  nzAnimate: true,
                  nzPlacement: NOTIFICATION_PLACEMENT,
                  nzKey: 'DELETE_ALL_OPERATION_NOTE_SUBTITLE',
                }
              );

              return fromOperationNoteSubtitleAction.deleteOperationNoteSubtitleSuccess(
                {
                  success,
                }
              );
            }),
            catchError((error) => {
              this.onErrorNotification(
                error,
                'DELETE_ALL_OPERATION_NOTE_SUBTITLE'
              );
              return of(
                fromOperationNoteSubtitleAction.deleteOperationNoteSubtitleFailure(
                  { error }
                )
              );
            })
          )
        ),
        tap(() =>
          fromOperationNoteSubtitleAction.loadingOperationNoteSubtitles({
            loading: false,
          })
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private message: NzMessageService,
    private operationNoteSubtitleService: OperationNoteSubtitleService,
    private notification: NzNotificationService
  ) {}

  onErrorNotification(error: any, key: string): void {
    this.notification.error('Error', error.message, {
      nzDuration: 10000,
      nzAnimate: true,
      nzPlacement: NOTIFICATION_PLACEMENT,
      nzKey: key ? key : '',
    });
  }
}
