import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { NzCalendarMode } from 'ng-zorro-antd/calendar';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { ParticipantId } from 'src/app/components/participant/store/participant.model';
import { ParticipantPlanningState } from '../../store/participant-planning.reducer';
import * as fromParticipantPlanningAction from './../../store/participant-planning.actions';
import * as fromParticipantPlanningSelector from './../../store/participant-planning.selectors';
import { ParticipantState } from 'src/app/components/participant/store/participant.reducer';
import * as fromParticipantSelector from 'src/app/components/participant/store/participant.selectors';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

@Component({
  selector: 'app-participant-planning-calendar',
  templateUrl: './participant-planning-calendar.component.html',
  styleUrls: ['./participant-planning-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantPlanningCalendarComponent
  implements OnInit, OnChanges, OnDestroy
{
  subscribe = new Subject();

  selectedValue = new Date(Date.now());
  loading$: Observable<boolean> = of(false);
  events$: Observable<any> = of([]);
  participant$: Observable<ParticipantId | any> = of(null);
  joursFeries$: any = null;
  calendarMode: NzCalendarMode = 'month';
  currentDate$ = new BehaviorSubject<{
    date: Date | any;
    participant: ParticipantId | any;
  }>({ date: null, participant: null });

  constructor(
    private participantPlanningStore: Store<ParticipantPlanningState>,
    private participantStore: Store<ParticipantState>
  ) {}

  ngOnInit(): void {
    this.getParticipant();
    this.setCurrentDate();
    this.getEvents();
    this.getParticipantPlanningLoadings();
    this.onDateChanges();
  }
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  trackByFn(index: number, item: any): number {
    return item.id;
  }
  getParticipant(): void {
    this.participant$ = this.participantStore.select(
      fromParticipantSelector.participant
    );
  }

  getParticipantPlanningLoadings(): void {
    this.loading$ = this.participantPlanningStore.select(
      fromParticipantPlanningSelector.loading
    );
  }

  setCurrentDate(): void {
    const today = new Date(Date.now());
    this.selectedValue = today;
  }
  getJoursFeries(): void {
    this.joursFeries$ = this.participantPlanningStore.select(
      fromParticipantPlanningSelector.joursFeries
    );
  }
  loadJoursFeries(): void {
    this.participantPlanningStore.dispatch(
      fromParticipantPlanningAction.loadJoursFerier()
    );
  }

  getMonthData(date: Date): number | null {
    if (!date) return null;
    const d = (
      date.getDate() +
      '-' +
      date.getMonth() +
      '-' +
      date.getFullYear()
    ).toString();
    return date.getMonth() + 1;
  }

  selectChange(date: Date, participant: ParticipantId): void {
    if (!participant || !date) return;
    this.currentDate$.next({ date, participant });
  }

  panelChange(event: any, participant: ParticipantId): void {}

  getEvents(): void {
    this.events$ = this.participantPlanningStore.select(
      fromParticipantPlanningSelector.events
    );
  }

  onDateChanges(): void {
    this.currentDate$
      .pipe(
        distinctUntilChanged((prev, curr) => {
          return (
            prev.date === curr.date || prev.participant === curr.participant
          );
        }),
        debounceTime(200),
        takeUntil(this.subscribe)
      )
      .subscribe((data: { date: Date; participant: ParticipantId }) => {
        if (!data.date) return;
        const { date, participant } = data;
        const createAtMonthYear: string = format(date, 'MM/yyyy', {
          locale: fr,
        });
        const { id } = participant;

        const filter: { type: string; createAtMonthYear: string } = {
          type: '',
          createAtMonthYear: createAtMonthYear,
        };

        this.onFilter(id, filter);
      });
  }

  onFilter(
    id: string,
    filter: { type: string; createAtMonthYear: string }
  ): void {
    if (!id || !filter.createAtMonthYear) return;
    this.participantPlanningStore.dispatch(
      fromParticipantPlanningAction.loadParticipantPlanningsFiltered({
        id,
        filter,
      })
    );
  }

  onDelete(id: string, participant: ParticipantId): void {
    if (!id || !participant) return;
    this.participantPlanningStore.dispatch(
      fromParticipantPlanningAction.deleteParticipantPlanning({
        participantId: participant.id,
        id: id,
      })
    );
  }
}
