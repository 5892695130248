import { slideInAnimation, fader } from './../../app-animation';
import {
  Component,
  OnInit,
  HostBinding,
  ChangeDetectionStrategy,
} from '@angular/core';

import { messageState } from 'src/app/components/messages/store/message.reducer';
import * as fromMessageAction from 'src/app/components/messages/store/message.actions';

import { Store } from '@ngrx/store';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';
import * as fromUserAction from 'src/app/components/user/store/user.actions';

import { Auth, onAuthStateChanged } from '@angular/fire/auth';
import { ChildrenOutletContexts, RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
  animations: [slideInAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShellComponent implements OnInit {
  title = 'Lenggo';
  userState: boolean = false;
  isDark = false;
  sideMode: boolean = true;
  sideWidth: number = 200;

  constructor(
    private auth: Auth,
    private userStore: Store<UserState>,
    private messageStore: Store<messageState>,
    private contexts: ChildrenOutletContexts
  ) {
    this.onUserChanges();
  }

  @HostBinding('class')
  get themMode() {
    return this.isDark ? 'theme-dark' : 'theme-ligth ';
  }

  ngOnInit(): void {
    //this.lister__MESSAGES();
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet?.activatedRouteData?.['animation'];
  }

  getRouteAnimationData() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.[
      'animation'
    ];
  }

  onUserChanges(): void {
    onAuthStateChanged(this.auth, (snap) => {
      if (!snap?.uid) {
        this.userState = false;
      } else {
        this.userState = true;
        //this.userStore.dispatch(fromUserAction.loadUser({ uid: snap.uid }));
        this.userStore.dispatch(
          fromUserAction.loadUserStatisques({ uid: snap.uid })
        );
      }
    });
  }

  lister__MESSAGES(): void {
    this.messageStore.dispatch(fromMessageAction.listenOnNewMessages());
  }

  onCollapseChange(event: any): void {
    this.sideMode = !this.sideMode;
  }

  onSwitchMode(event: boolean): void {
    this.isDark = event;
  }
}
