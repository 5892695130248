import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import {
  Router,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
  Event,
} from '@angular/router';
import { drop, take } from 'lodash';
import { fade, itemAnimation, listAnimation } from 'src/app/app-animation';

@Component({
  selector: 'app-layout-list',
  templateUrl: './layout-list.component.html',
  styleUrls: ['./layout-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fade, listAnimation, itemAnimation],
})
export class LayoutListComponent implements OnInit, OnChanges {
  loading: boolean = false;
  @Input() height: string = 'calc(100vh - 8em)';
  @Input() new: TemplateRef<any> | any;
  @Input() card: TemplateRef<any> | any;
  @Input() tableHeader: TemplateRef<any> | any;
  @Input() tableRow: TemplateRef<any> | any;
  @Input() source: any = [];
  @Input() tableSource: any = [];
  @Input() cardSource: any = [];
  @Input() description: string = '';

  constructor(private router: Router) {
    router.events.subscribe((routerEevent: Event) => {
      this.checkRouterEvent(routerEevent);
    });
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.setLayoutSource(changes?.source?.currentValue);
  }

  setLayoutSource(source: any): void {
    const size: number = source?.length;
    if (!size || size === 0) {
      return;
    }

    const sizeCard: number = 4;
    const sizeTable: number = size ? size - sizeCard : 0;

    const cardSource = size ? source?.slice(0, sizeCard) : [];
    const tableSource = size ? source?.slice(sizeCard, sizeTable) : [];

    this.cardSource = cardSource;
    this.tableSource = tableSource;
  }

  checkRouterEvent(routerEvent: Event): void {
    if (routerEvent instanceof NavigationStart) {
      this.loading = true;
    }
    if (
      routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError
    ) {
      this.loading = false;
    }
  }

  trackByFn(index: number, item: any) {
    return index;
  }
}
