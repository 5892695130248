import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { add } from 'date-fns';
import { Timestamp } from 'firebase/firestore';
import { Observable, of, Subject, takeUntil } from 'rxjs';
import { Update } from '@ngrx/entity';
import { UtilitiesState } from 'src/app/contents/components/utilities/store/utilities.reducer';
import * as fromUtilitiesSelector from 'src/app/contents/components/utilities/store/utilities.selectors';
import * as fromUtilitiesAction from 'src/app/contents/components/utilities/store/utilities.actions';

@Component({
  selector: 'app-participant-planning-form',
  templateUrl: './participant-planning-form.component.html',
  styleUrls: ['./participant-planning-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantPlanningFormComponent
  implements OnInit, OnChanges, OnDestroy
{
  subscribe = new Subject();
  today = new Date(Date.now());
  isAddForm: boolean = true;
  dateFormat: string = 'dd/MM/yyyy HH:mm';
  timeFormat: string = 'HH:mm';

  types$: Observable<
    { alue: string; label: string; color: string; colorLabel: string }[]
  > = of([]);

  eventForm: FormGroup<any> = this.fb.group({});
  type = new FormControl<
    { value: string; label: string; color: string; colorLabel: string } | any
  >(null, Validators.required);
  content = new FormControl<string>('', Validators.required);
  dateStart = new FormControl<Date>(this.today, Validators.required);
  isValided = new FormControl<boolean>(false, Validators.required);
  referent = new FormControl<any>({});
  isPresent = new FormControl<boolean>(false, Validators.required);

  @Input() event$: Observable<any> = of(null);
  @Output() add = new EventEmitter();
  @Output() update = new EventEmitter();
  @Output() cancel = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private utilitiesStore: Store<UtilitiesState>
  ) {}

  ngOnInit(): void {
    this.form();
    this.getPlanningTypes();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  form(): void {
    this.formInit();
    this.formCreate();
    this.formSet();
  }

  formInit(): void {
    this.type = new FormControl<string>('', Validators.required);
    this.content = new FormControl<string>('', Validators.required);
    this.dateStart = new FormControl<Date>(this.today, Validators.required);
    this.isValided = new FormControl<boolean>(false, Validators.required);
    this.referent = new FormControl<any>(null, Validators.required);
    this.isPresent = new FormControl<boolean>(false, Validators.required);
  }

  formCreate(): void {
    this.eventForm = this.fb.group({
      type: this.type,
      content: this.content,
      dateStart: this.dateStart,
      isValided: this.isValided,
      isPresent: this.isPresent,
      referent: this.referent,
    });
  }

  formSet(): void {
    this.event$.pipe(takeUntil(this.subscribe)).subscribe((event) => {
      if (!event) {
        this.isAddForm = true;
        return;
      }

      this.isAddForm = false;
      this.eventForm.patchValue({ ...event });
    });
  }

  getPlanningTypes(): void {
    this.types$ = this.utilitiesStore.select(
      fromUtilitiesSelector.planningTypes
    );
  }

  onSelectReferent(user: any): void {
    if (!user) return;
    this.eventForm.patchValue({ referent: user });
  }
  onAdd(): void {
    if (!this.eventForm.valid) return;
    const formValues = this.eventForm.value;
    const { dateStart } = formValues;
    const start = Timestamp.fromDate(dateStart);
    this.add.emit({ ...formValues, dateStart: start });
  }

  onUpdate(event: any): void {
    if (!this.eventForm.valid) return;
    const formValues = this.eventForm.value;
    const { dateStart } = formValues;
    const start = Timestamp.fromDate(dateStart);
    const changes = { ...event, ...formValues, dateStart: start };

    const nextEvent: Update<any> = {
      id: event.id,
      changes: changes,
    };

    this.update.emit(nextEvent);
  }

  onCancel(): void {
    this.cancel.emit(true);
  }
}
