import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as ContactsSelectActions from './contacts-select.actions';
import { ContactSMALLid } from 'src/app/components/contact/store/contact.model';

export const contactsSelectsFeatureKey = 'contactsSelects';

export interface ContactSelectState extends EntityState<ContactSMALLid> {
  loading: boolean;
  error: any;
}

export const adapter: EntityAdapter<ContactSMALLid> =
  createEntityAdapter<ContactSMALLid>();

export const initialState: ContactSelectState = adapter.getInitialState({
  loading: false,
  error: undefined,
});

export const reducer = createReducer(
  initialState,

  on(ContactsSelectActions.queryContactsSuccess, (state, action) =>
    adapter.setAll(action.contacts, state)
  ),

  on(ContactsSelectActions.queryContactsFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
