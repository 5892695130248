import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import {
  OperationNoteSubtitle,
  OperationNoteSubtitleId,
} from './operation-note-subtitle.model';
import { OperationNoteId } from '../../operation-note/store/operation-note.model';

//LOADING
export const loadingOperationNoteSubtitles = createAction(
  '[OperationNoteSubtitle/API] Loading OperationNoteSubtitles',
  props<{ loading: boolean }>()
);

//LOAD

export const loadOperationNoteSubtitles = createAction(
  '[OperationNoteSubtitle/COMPONENT] Load OperationNoteSubtitles',
  props<{
    note: OperationNoteId;
  }>()
);
export const loadOperationNoteSubtitlesSuccess = createAction(
  '[OperationNoteSubtitle/API] Load OperationNoteSubtitles Success',
  props<{ substitles: OperationNoteSubtitleId[] }>()
);
export const loadOperationNoteSubtitlesFailure = createAction(
  '[OperationNoteSubtitle/API] Load OperationNoteSubtitles',
  props<{ error: any }>()
);

//ADD
export const addOperationNoteSubtitle = createAction(
  '[OperationNoteSubtitle/COMPONENT] Add OperationNoteSubtitle',
  props<{
    note: OperationNoteId;
    subtitle: OperationNoteSubtitle;
  }>()
);
export const addOperationNoteSubtitleSuccess = createAction(
  '[OperationNoteSubtitle/API] Add OperationNoteSubtitle Success',
  props<{ success: string }>()
);
export const addOperationNoteSubtitleFailure = createAction(
  '[OperationNoteSubtitle/API] Add OperationNoteSubtitle Failure',
  props<{ error: any }>()
);

//UPDATE

export const updateOperationNoteSubtitle = createAction(
  '[OperationNoteSubtitle/API] Update OperationNoteSubtitle',
  props<{
    note: OperationNoteId;
    subtitle: Update<OperationNoteSubtitleId>;
  }>()
);
export const updateOperationNoteSubtitleSuccess = createAction(
  '[OperationNoteSubtitle/API] Update OperationNoteSubtitle Success',
  props<{ success: string }>()
);
export const updateOperationNoteSubtitleFailure = createAction(
  '[OperationNoteSubtitle/API] Update OperationNoteSubtitle Failure',
  props<{ error: any }>()
);

//DELETE
export const deleteOperationNoteSubtitle = createAction(
  '[OperationNoteSubtitle/API] Delete OperationNoteSubtitle',
  props<{ note: OperationNoteId; id: string }>()
);
export const deleteOperationNoteSubtitleSuccess = createAction(
  '[OperationNoteSubtitle/API] Delete OperationNoteSubtitleSuccess',
  props<{ success: string }>()
);

export const deleteOperationNoteSubtitleFailure = createAction(
  '[OperationNoteSubtitle/API] Delete OperationNoteSubtitleFailure',
  props<{ error: any }>()
);

//DELETE ALL
export const deleteAllOperationNoteSubtitles = createAction(
  '[OperationNoteSubtitle/API] Delete OperationNoteSubtitles',
  props<{ note: OperationNoteId; ids: string[] }>()
);

export const deleteAllOperationNoteSubtitlesSuccess = createAction(
  '[OperationNoteSubtitle/API] Delete OperationNoteSubtitles Success',
  props<{ success: string }>()
);

export const deleteAllOperationNoteSubtitlesFailure = createAction(
  '[OperationNoteSubtitle/API] Delete OperationNoteSubtitles Failure',
  props<{ error: any }>()
);

//CLEAR
export const clearOperationNoteSubtitles = createAction(
  '[OperationNoteSubtitle/COMPONENT] Clear OperationNoteSubtitles'
);
