import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ParticipantActionAccompagnement } from './participant-action-accompagnement.model';
import * as ParticipantActionAccompagnementActions from './participant-action-accompagnement.actions';

export const participantActionAccompagnementsFeatureKey =
  'participantActionAccompagnements';

export interface ParticipantAccompagnementState
  extends EntityState<ParticipantActionAccompagnement> {
  types: any[];
  champs: any[];
  accompagnement: any;
  loading: boolean;
  error: any;
}

export const adapter: EntityAdapter<ParticipantActionAccompagnement> =
  createEntityAdapter<ParticipantActionAccompagnement>();

export const initialState: ParticipantAccompagnementState =
  adapter.getInitialState({
    types: [],
    champs: [],
    accompagnement: undefined,
    loading: false,
    error: undefined,
  });

export const reducer = createReducer(
  initialState,
  on(
    ParticipantActionAccompagnementActions.loadParticipantAccompagnementTypesSuccess,
    (state, action) => {
      return {
        ...state,
        types: action.types,
      };
    }
  ),
  on(
    ParticipantActionAccompagnementActions.loadParticipantAccompagnementTypesFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
      };
    }
  ),
  on(
    ParticipantActionAccompagnementActions.loadParticipantAccompagnementChampsTypesSuccess,
    (state, action) => {
      return {
        ...state,
        champs: action.champs,
      };
    }
  ),
  on(
    ParticipantActionAccompagnementActions.loadParticipantAccompagnementChampsTypesFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
      };
    }
  ),

  //ACCOMPAGNEMENTS
  on(
    ParticipantActionAccompagnementActions.loadParticipantActionAccompagnements,
    (state, action) => {
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    }
  ),
  on(
    ParticipantActionAccompagnementActions.loadParticipantActionAccompagnementsSuccess,
    (state, action) => adapter.setAll(action.accompagnements, state)
  ),
  on(
    ParticipantActionAccompagnementActions.loadParticipantActionAccompagnementsSuccess,
    (state, action) => {
      return {
        ...state,
        loading: false,
        error: undefined,
      };
    }
  ),
  on(
    ParticipantActionAccompagnementActions.loadParticipantActionAccompagnementsSuccess,
    (state, action) => {
      return {
        ...state,
        loading: false,
        error: undefined,
      };
    }
  ),
  on(
    ParticipantActionAccompagnementActions.loadParticipantActionAccompagnementsFailure,
    (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
  ),

  //LOAD ONE
  on(
    ParticipantActionAccompagnementActions.loadParticipantActionAccompagnementSuccess,
    (state, action) => {
      return {
        ...state,
        accompagnement: action.accompagnement,
      };
    }
  ),
  on(
    ParticipantActionAccompagnementActions.loadParticipantActionAccompagnementFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
      };
    }
  ),

  //CREATE ONE
  on(
    ParticipantActionAccompagnementActions.addParticipantActionAccompagnementSuccess,
    (state, action) => adapter.addOne(action.accompagnement, state)
  ),
  on(
    ParticipantActionAccompagnementActions.addParticipantActionAccompagnementFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
      };
    }
  ),

  //UPDATE ONE

  on(
    ParticipantActionAccompagnementActions.updateParticipantActionAccompagnement,
    (state, action) => adapter.updateOne(action.accompagnement, state)
  ),
  on(
    ParticipantActionAccompagnementActions.updateParticipantActionAccompagnementFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
      };
    }
  ),

  //DELETE ONE

  on(
    ParticipantActionAccompagnementActions.deleteParticipantActionAccompagnement,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(
    ParticipantActionAccompagnementActions.deleteParticipantActionAccompagnementFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
      };
    }
  ),

  //DELETE ALL

  on(
    ParticipantActionAccompagnementActions.deleteParticipantActionAccompagnements,
    (state, action) => adapter.removeAll(state)
  ),
  on(
    ParticipantActionAccompagnementActions.deleteParticipantActionAccompagnementsFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
      };
    }
  ),

  //CLEAR ONE

  on(
    ParticipantActionAccompagnementActions.clearParticipantActionAccompagnements,
    (state) => adapter.removeAll(state)
  )
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
