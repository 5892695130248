<nz-row nzAlign="top" nzGutter="4" nzJustify="end">
  <nz-col nzSpan="2">
    <div class="matAvatar">
      <mat-icon>task</mat-icon>
    </div>
  </nz-col>
  <nz-col nzSpan="10"> </nz-col>
  <nz-col [nzSpan]="!update ? 3 : 7"></nz-col>
  <nz-col nzSpan="4">
    <nz-tag
      [ngStyle]="{ float: 'center', width: '100%' }"
      nzMode="default"
      [nzColor]="todo?.completed ? 'success' : 'orange'"
    >
      <strong>{{ todo?.completed ? "Réalisée" : "En attente" }}</strong>
    </nz-tag>
  </nz-col>

  <nz-col [nzSpan]="!update ? 4 : 0">
    <button
      nz-button
      *ngIf="!update"
      nzType="primary"
      nzBlock
      nz-tooltip
      nzTooltipTitle="Enregistrer"
      nzTooltipPlacement="bottom"
      [nzTooltipMouseEnterDelay]="mouseEnterDelay"
      (click)="$event.stopPropagation(); onAdd()"
      nzSize="small"
    >
      Ajouter
    </button>
  </nz-col>

  <nz-col nzSpan="1">
    <button
      nz-button
      nzType="text"
      nz-tooltip
      nzTooltipTitle="Fermer"
      nzTooltipPlacement="bottom"
      (click)="onCancel()"
      [nzTooltipMouseEnterDelay]="mouseEnterDelay"
      nzShape="circle"
    >
      <i
        nz-icon
        nzTheme="outline"
        nzType="close"
        class="IconFormTODO"
      ></i></button
  ></nz-col>
</nz-row>
