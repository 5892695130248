<nz-card *ngIf="user" nzBorderless>
  <ng-container>
    <nz-page-header
      class="page-header"
      [nzTitle]="itemTitle"
      nzBackIcon
      (nzBack)="onBack()"
    >
      <ng-template #itemTitle>
        <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
          <nz-col nzSpan="8">
            <app-avatar-group
              [source]="[user]"
              [limit]="8"
              [size]="60"
            ></app-avatar-group>
          </nz-col>
          <nz-col nzSpan="1"></nz-col>
          <nz-col nzSpan="15">
            <h5 nz-typography>
              {{ user | displayName | uppercase | carLimitation: 40 }}
            </h5>
          </nz-col>
        </nz-row>
      </ng-template>

      <nz-page-header-content> </nz-page-header-content>
      <nz-page-header-extra>
        <button
          *ngIf="(cuurentUser | isDev) || (cuurentUser | isAdmin)"
          nz-button
          nzShape="circle"
          (click)="$event.stopPropagation()"
          nz-dropdown
          [nzDropdownMenu]="userDetailMenu"
          nzTrigger="click"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
      </nz-page-header-extra>

      <ng-template #workIcon><mat-icon>work</mat-icon></ng-template>
      <ng-template #roleIcon><mat-icon>verified_user</mat-icon></ng-template>
    </nz-page-header>

    <nz-tabset
      nzCentered
      (nzSelectedIndexChange)="selectedIndexChange($event)"
      [nzTabBarStyle]="tabStyle"
    >
      <nz-tab [nzTitle]="tabIdentity">
        <ng-template #tabIdentity>
          <app-tab-title
            [title]="'Profil'"
            [isActiv]="currentSelected === 0 ? true : false"
          ></app-tab-title>
        </ng-template>
        <nz-descriptions
          [nzColumn]="{ xxl: 4, xl: 4, lg: 4, md: 4, sm: 2, xs: 1 }"
          nzSize="small"
          nzBordered
        >
          <nz-descriptions-item [nzSpan]="4" nzTitle="Fonction">
            {{ user?.fonction | titlecase }}
          </nz-descriptions-item>
          <nz-descriptions-item [nzSpan]="4" nzTitle="Service">
            {{ user?.service?.title | titlecase }}
          </nz-descriptions-item>
          <nz-descriptions-item [nzSpan]="4" nzTitle="Accès">
            <nz-tag nzColor="magenta" *ngIf="user | isDev"> Développer </nz-tag>
            <nz-tag nzColor="green" *ngIf="user | isAdmin">
              Administrateur
            </nz-tag>
            <nz-tag nzColor="purple" *ngIf="user | isManager"> Manager </nz-tag>
            <nz-tag nzColor="geekblue" *ngIf="user | isMember">
              Chargé de mission
            </nz-tag>
          </nz-descriptions-item>
          <nz-descriptions-item [nzSpan]="2" nzTitle="Modile">
            {{ user?.coordonnees?.mobile | phone }}
          </nz-descriptions-item>
          <nz-descriptions-item [nzSpan]="2" nzTitle="Fixe">
            {{ user?.coordonnees?.fixe | phone }}
          </nz-descriptions-item>
          <nz-descriptions-item [nzSpan]="4" nzTitle="E-mail">
            {{ user?.coordonnees?.email }}
          </nz-descriptions-item>
          <nz-descriptions-item [nzSpan]="4" nzTitle="Activité">
            <nz-tag [nzColor]="!user?.disabled ? 'green' : 'red'">
              {{ !user?.disabled ? "Compte activé" : "Compte désactivé" }}
            </nz-tag>
          </nz-descriptions-item>
        </nz-descriptions>
      </nz-tab>
    </nz-tabset>
  </ng-container>

  <ng-template #projetsTpl>
    <app-item-operations
      [operations$]="operations$ | async"
    ></app-item-operations>
  </ng-template>

  <ng-template #noData>
    <nz-card nzBorderless class="emptyCard">
      <i nz-icon nzType="team" class="iconEmpty"></i>
      <h5 nz-typography class="contentEmpty">
        Aucun collaborateur sélectionné
      </h5>
    </nz-card>
  </ng-template>

  <nz-dropdown-menu #userDetailMenu="nzDropdownMenu">
    <ul nz-menu>
      <li nz-menu-item></li>
      <li nz-menu-divider></li>
      <li
        nz-menu-item
        nzDanger
        *ngIf="(user | isDev) || (user | isAdmin)"
        nz-popconfirm
        [nzPopconfirmTitle]="
          'Voulez-vous' + deleteTitle + (user?.displayName | titlecase) + '?'
        "
        nzPopconfirmPlacement="bottom"
        (nzOnConfirm)="onDelete(user)"
        (nzOnCancel)="cancel()"
      >
        {{ deleteTitle }}
      </li>
    </ul>
  </nz-dropdown-menu>

  <ng-template #userActions>
    <nz-row
      nzAlign="middle"
      nzGutter="8"
      nzJustify="space-between"
      *ngIf="(user | isDev) || (user | isAdmin)"
    >
      <nz-col nzSpan="8">
        <button
          *ngIf="user?.disabled"
          nz-button
          nzType="text"
          nz-popconfirm
          [nzPopconfirmTitle]="
            'Voulez-vous activer ' + (user?.displayName | titlecase) + '?'
          "
          nzPopconfirmPlacement="bottom"
          (nzOnConfirm)="onActived(user)"
          (nzOnCancel)="cancel()"
        >
          <h3>Déactiver</h3>
        </button>
      </nz-col>
      <nz-col nzSpan="8">
        <button
          *ngIf="!user?.disabled"
          nz-button
          nzType="text"
          (click)="onDisabled(user)"
          nz-popconfirm
          [nzPopconfirmTitle]="
            'Voulez-vous désactiver ' + (user?.displayName | titlecase) + '?'
          "
          nzPopconfirmPlacement="bottom"
          (nzOnConfirm)="onDisabled(user)"
          (nzOnCancel)="cancel()"
        >
          <h3>Déactiver</h3>
        </button>
      </nz-col>
      <nz-col nzSpan="8"></nz-col>
    </nz-row>
  </ng-template>
</nz-card>
