import { Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  doc,
  docSnapshots,
  collectionChanges,
  getDoc,
  addDoc,
  setDoc,
  deleteDoc,
  serverTimestamp,
  DocumentData,
  Timestamp,
  query,
  where,
  arrayUnion,
  arrayRemove,
  collectionSnapshots,
  getDocs,
  documentId,
} from '@angular/fire/firestore';
import { Observable, of, from, EMPTY } from 'rxjs';
import { switchMap, mergeMap, map, catchError } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { UserState } from '../components/user/store/user.reducer';
import * as fromUserSelector from '../components/user/store/user.selectors';
import {
  EntiteId,
  EntiteSMALLid,
} from '../components/entite/store/entite.model';
import { EntiteService } from './entite.service';
import { Apollo, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'any',
})
export class QueryOperationEntitesService {
  GET_FILTERS = gql`
    query Me {
      me {
        organisation {
          filtersEntite {
            categorie_entreprise
            code_postal
            communes
            economie_sociale_solidaire
            engagement
            libelle_activite_principale
            metiers
            metiersDomaine
            metiersGrandDomaine
            nature_juridique
            origine
            statuts
            types
          }
        }
      }
    }
  `;
  constructor(
    private db: Firestore,
    private fns: Functions,
    private userStore: Store<UserState>,
    private entiteService: EntiteService,
    private apollo: Apollo
  ) {}

  getFilters(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_FILTERS,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  //QUERY
  queryEntites(
    filter: any,
    type: string,
    operationId: string
  ): Observable<EntiteSMALLid[] | any> {
    switch (type) {
      case 'raisonSociale':
        return this.queryRaisonSociale(filter, operationId);
        break;
      case 'grandDomaine':
        return this.queryGrandDomaines(filter);
        break;
      case 'domaine':
        return this.queryDomaines(filter);
        break;
      case 'metier':
        return this.queryMetiers(filter);
        break;
      case 'activite':
        return this.queryActivity(filter);
        break;

      case 'ess':
        return this.queryEss(filter);
        break;
      case 'adherent':
        return this.queryAdherents(filter);
        break;
      case 'commune':
        return this.queryCommunes(filter);
        break;
      case 'codepostal':
        return this.queryDepartements(filter);
        break;

      case 'engagement':
        return this.queryEngagements(filter);
        break;
      case 'origine':
        return this.queryOrigines(filter);
        break;
      case 'status':
        return this.queryStatut(filter);
        break;
      case 'favoris':
        return this.queryFavoris(filter);
        break;
      default:
        return this.entiteService.getAll();
        break;
    }
  }
  queryRaisonSociale(
    value: string,
    operationId: string
  ): Observable<EntiteSMALLid[] | any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (user) {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/operations/${operationId}/entites`
            );
            const dbQuery = query(
              dbCollection,
              where(
                'entite.metadata.arrayRaisonSociale',
                'array-contains',
                value.toUpperCase()
              )
            );

            return from(
              getDocs(dbQuery)
                .then((snap) => {
                  return snap.docs.map((doc: DocumentData) => {
                    const data = doc.data() as any;
                    const id = doc.id;
                    return { id, ...data };
                  });
                })
                .catch((error) => error)
            ).pipe(mergeMap((res) => of(res)));
          } else {
            return of(null);
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  queryGrandDomaines(value: string): Observable<EntiteSMALLid[] | any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (user) {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/entites`
            );
            const dbQuery = query(
              dbCollection,
              where('entite.metiersGrandDomaine.libelle', '==', value)
            );

            return from(
              getDocs(dbQuery)
                .then((snap) => {
                  return snap.docs.map((doc: DocumentData) => {
                    const data = doc.data() as any;
                    const id = doc.id;
                    return { id, ...data };
                  });
                })
                .catch((error) => error)
            ).pipe(mergeMap((res) => of(res)));
          } else {
            return of(null);
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  queryDomaines(value: string): Observable<EntiteSMALLid[] | any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (user) {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/entites`
            );
            const dbQuery = query(
              dbCollection,
              where('entite.metiersDomaine.libelle', '==', value)
            );

            return from(
              getDocs(dbQuery)
                .then((snap) => {
                  return snap.docs.map((doc: DocumentData) => {
                    const data = doc.data() as any;
                    const id = doc.id;
                    return { id, ...data };
                  });
                })
                .catch((error) => error)
            ).pipe(mergeMap((res) => of(res)));
          } else {
            return of(null);
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }
  queryActivity(value: string): Observable<EntiteSMALLid[] | any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (user) {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/entites`
            );
            const dbQuery = query(
              dbCollection,
              where(
                'entite.etablisement.activite_principale_libelle.intitule_naf',
                '==',
                value
              )
            );

            return from(
              getDocs(dbQuery)
                .then((snap) => {
                  return snap.docs.map((doc: DocumentData) => {
                    const data = doc.data() as any;
                    const id = doc.id;
                    return { id, ...data };
                  });
                })
                .catch((error) => error)
            ).pipe(mergeMap((res) => of(res)));
          } else {
            return of(null);
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  queryEss(value: boolean): Observable<EntiteSMALLid[] | any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (user) {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/entites`
            );
            const dbQuery = query(
              dbCollection,
              where(
                'entite.etablissement.economie_sociale_solidaire',
                '==',
                value
              )
            );

            return from(
              getDocs(dbQuery)
                .then((snap) => {
                  return snap.docs.map((doc: DocumentData) => {
                    const data = doc.data() as any;
                    const id = doc.id;
                    return { id, ...data };
                  });
                })
                .catch((error) => error)
            ).pipe(mergeMap((res) => of(res)));
          } else {
            return of(null);
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  queryAdherents(value: boolean): Observable<EntiteSMALLid[] | any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (user) {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/entites`
            );
            const dbQuery = query(dbCollection, where('adherent', '==', value));

            return from(
              getDocs(dbQuery)
                .then((snap) => {
                  return snap.docs.map((doc: DocumentData) => {
                    const data = doc.data() as any;
                    const id = doc.id;
                    return { id, ...data };
                  });
                })
                .catch((error) => error)
            ).pipe(mergeMap((res) => of(res)));
          } else {
            return of(null);
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  queryStatut(value: string): Observable<EntiteSMALLid[] | any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (user) {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/entites`
            );
            const dbQuery = query(dbCollection, where('statut', '==', value));

            return from(
              getDocs(dbQuery)
                .then((snap) => {
                  return snap.docs.map((doc: DocumentData) => {
                    const data = doc.data() as any;
                    const id = doc.id;
                    return { id, ...data };
                  });
                })
                .catch((error) => error)
            ).pipe(mergeMap((res) => of(res)));
          } else {
            return of(null);
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  queryCommunes(value: string): Observable<EntiteSMALLid[] | any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (user) {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/entites`
            );
            const dbQuery = query(
              dbCollection,
              where(
                'entite.etablisement.etablissement_siege.commune_libelle',
                '==',
                value
              )
            );

            return from(
              getDocs(dbQuery)
                .then((snap) => {
                  return snap.docs.map((doc: DocumentData) => {
                    const data = doc.data() as any;
                    const id = doc.id;
                    return { id, ...data };
                  });
                })
                .catch((error) => error)
            ).pipe(mergeMap((res) => of(res)));
          } else {
            return of(null);
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }
  queryMetiers(value: string): Observable<EntiteSMALLid[] | any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (user) {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/entites`
            );
            const dbQuery = query(
              dbCollection,
              where(`metiersMap[${value}]`, '==', value)
            );

            return from(
              getDocs(dbQuery)
                .then((snap) => {
                  return snap.docs.map((doc: DocumentData) => {
                    const data = doc.data() as any;
                    const id = doc.id;
                    return { id, ...data };
                  });
                })
                .catch((error) => error)
            ).pipe(mergeMap((res) => of(res)));
          } else {
            return of(null);
          }
        })
      );
    } catch (err) {
      return of(err);
    }
  }

  queryDepartements(value: string): Observable<EntiteSMALLid[] | any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (user) {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/entites`
            );
            const dbQuery = query(
              dbCollection,
              where(
                'entite.etablisement.etablissement_siege.commune_libelle',
                '>=',
                value
              )
            );

            return from(
              getDocs(dbQuery)
                .then((snap) => {
                  return snap.docs.map((doc: DocumentData) => {
                    const data = doc.data() as any;
                    const id = doc.id;
                    return { id, ...data };
                  });
                })
                .catch((error) => error)
            ).pipe(mergeMap((res) => of(res)));
          } else {
            return of(null);
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  queryOrigines(value: string): Observable<EntiteSMALLid[] | any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (user) {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/entites`
            );
            const dbQuery = query(
              dbCollection,
              where('metadata.origine', '==', value)
            );

            return from(
              getDocs(dbQuery)
                .then((snap) => {
                  return snap.docs.map((doc: DocumentData) => {
                    const data = doc.data() as any;
                    const id = doc.id;
                    return { id, ...data };
                  });
                })
                .catch((error) => error)
            ).pipe(mergeMap((res) => of(res)));
          } else {
            return of(null);
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  queryEngagements(value: string): Observable<EntiteSMALLid[] | any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (user) {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/entites`
            );
            const dbQuery = query(
              dbCollection,
              where('metadata.engagement', '==', value)
            );

            return from(
              getDocs(dbQuery)
                .then((snap) => {
                  return snap.docs.map((doc: DocumentData) => {
                    const data = doc.data() as any;
                    const id = doc.id;
                    return { id, ...data };
                  });
                })
                .catch((error) => error)
            ).pipe(mergeMap((res) => of(res)));
          } else {
            return of(null);
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  querySiege(value: string): Observable<EntiteSMALLid[] | any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (user) {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/entites`
            );
            const dbQuery = query(
              dbCollection,
              where(
                'etablisement.etablissement_siege.etablissement_siege',
                '==',
                value
              )
            );

            return from(
              getDocs(dbQuery)
                .then((snap) => {
                  return snap.docs.map((doc: DocumentData) => {
                    const data = doc.data() as any;
                    const id = doc.id;
                    return { id, ...data };
                  });
                })
                .catch((error) => error)
            ).pipe(mergeMap((res) => of(res)));
          } else {
            return of(null);
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  queryFavoris(favoris: any[]): Observable<EntiteSMALLid[] | any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (user) {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/entites`
            );
            const ids = favoris.map((item) => item.id);
            const dbQuery = query(dbCollection, where(documentId(), 'in', ids));

            return from(
              getDocs(dbQuery)
                .then((snap) => {
                  return snap.docs.map((doc: DocumentData) => {
                    const data = doc.data() as any;
                    const id = doc.id;
                    return { id, ...data };
                  });
                })
                .catch((error) => error)
            ).pipe(mergeMap((res) => of(res)));
          } else {
            return of(null);
          }
        })
      );
    } catch (err) {
      return of(err);
    }
  }
}
