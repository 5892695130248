<ng-container *ngIf="projetprofessionnel$ | async as projetprofessionnel">
  <nz-page-header
    class="page-header"
    [nzTitle]="
      projetprofessionnel?.appellation?.libelle | uppercase | carLimitation: 60
    "
    [nzSubtitle]="typeTitle | uppercase"
    nzBackIcon
    (nzBack)="onBack()"
  >
    <nz-page-header-extra>
      <button
        nz-button
        nzShape="circle"
        (click)="$event.stopPropagation()"
        nz-dropdown
        nzTrigger="click"
        [nzDropdownMenu]="projetProActionMenu"
        nzPlacement="bottomRight"
        nzType="text"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
    </nz-page-header-extra>
    <nz-page-header-content>
      <nz-card>
        <nz-row nzAlign="middle" nzGutter="8" nzJustify="center">
          <nz-col nzSpan="18">
            <nz-tag class="appelationCode" nzColor="geekblue">
              <strong>ROME : {{ projetprofessionnel?.code }} </strong>
            </nz-tag>
            <nz-tag class="appelationCode" nzColor="geekblue">
              <strong>FAP : {{ projetprofessionnel?.fap?.fap?.code }}</strong>
            </nz-tag>
          </nz-col>
          <nz-col nzSpan="6">
            <!-- <app-participant-indicator
          [frenchLevel]="false"
          [workReady]="false"
          [house]="false"
          [lawAcces]="false"
          [mobility]="false"
          [finance]="false"
          [justice]="false"
          [disponility]="false"
        ></app-participant-indicator> -->
          </nz-col>
        </nz-row>
      </nz-card>
    </nz-page-header-content>
  </nz-page-header>
  <nz-space nzDirection="vertical" nzSize="large"></nz-space>

  <div>
    <app-item-tabset
      (select)="onSelectedTab($event, projetprofessionnel)"
      [tabs]="[
        {
          name: 'Employabilité',
          index: 0,
          template: employabilityTpl
        },
        {
          name: 'Métier',
          index: 1,
          template: metierTpl
        },
        {
          name: 'Diagnostic',
          index: 2,
          template: diagnosticTpl
        },
        {
          name: 'Actions',
          index: 3,
          template: tasksTpl
        },
        {
          name: 'Formations',
          index: 4,
          template: formationsTpl
        },
        {
          name: 'Entités',
          index: 5,
          template: entitesTpl
        },
        {
          name: 'Contacts',
          index: 6,
          template: contactTpl
        },
        {
          name: 'Statistiques',
          index: 7,
          template: statisticTpl
        }
      ]"
    ></app-item-tabset>
  </div>

  <ng-template #employabilityTpl>
    <div class="projet_pro_container">
      <nz-row nzAlign="top" nzGutter="4" nzJustify="center">
        <nz-col nzSpan="24">
          <nz-card>
            <app-charts [source]="chartType"></app-charts>
          </nz-card>
        </nz-col>
      </nz-row>
      <nz-row nzAlign="top" nzGutter="4" nzJustify="center">
        <nz-col nzSpan="24">
          <app-projet-professionnel-info
            [item]="projetprofessionnel"
          ></app-projet-professionnel-info>
        </nz-col>
      </nz-row>
    </div>
  </ng-template>

  <ng-template #metierTpl>
    <app-projet-professionnel-metier
      [item]="projetprofessionnel"
    ></app-projet-professionnel-metier>
  </ng-template>

  <ng-template #diagnosticTpl>
    <app-projet-professionnel-diagnostics
      [projetprofessionnel$]="projetprofessionnel$"
      [participant]="participant"
    ></app-projet-professionnel-diagnostics>
  </ng-template>

  <ng-template #tasksTpl>
    <app-projet-professionnel-todo
      [projetprofessionnel$]="projetprofessionnel$"
      [participant]="participant"
    ></app-projet-professionnel-todo>
  </ng-template>

  <ng-template #formationsTpl>
    <app-projet-professionnel-formations
      [projetprofessionnel$]="projetprofessionnel$"
      [participant]="participant"
    ></app-projet-professionnel-formations>
  </ng-template>

  <ng-template #entitesTpl>
    <app-projet-professionnel-entites
      [projetprofessionnel$]="projetprofessionnel$"
      [participant]="participant"
    ></app-projet-professionnel-entites>
  </ng-template>

  <ng-template #contactTpl>
    <app-projet-professionnel-contacts
      [item]="projetprofessionnel$"
      [participant]="participant"
    ></app-projet-professionnel-contacts>
  </ng-template>

  <ng-template #statisticTpl>
    <app-projet-professionnel-statistiques
      [item]="projetprofessionnel$"
    ></app-projet-professionnel-statistiques>
  </ng-template>

  <ng-template #header>
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="2"
        ><app-header-title [icon]="'business_center'"></app-header-title
      ></nz-col>
      <nz-col nzSpan="1"></nz-col>
      <nz-col nzSpan="21">
        <h6 nz-typography></h6>
      </nz-col>
    </nz-row>
  </ng-template>
  <ng-template #proCardExtra>
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="end">
      <nz-col nzSpan="24"> </nz-col>
    </nz-row>
  </ng-template>

  <nz-dropdown-menu #projetProActionMenu="nzDropdownMenu">
    <ul nz-menu>
      <li
        nz-menu-item
        (click)="$event.stopPropagation(); onExport(projetprofessionnel)"
      >
        <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
          <nz-col nzSpan="4"><mat-icon>download</mat-icon></nz-col>
          <nz-col nzSpan="4">
            <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
          </nz-col>
          <nz-col nzSpan="16"><span>Avis de situation</span></nz-col>
        </nz-row>
      </li>
      <li nz-menu-divider></li>
      <li
        nz-menu-item
        nzDanger
        (click)="$event.stopPropagation(); onDelete(projetprofessionnel)"
      >
        <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
          <nz-col nzSpan="4">
            <mat-icon class="delete">delete</mat-icon></nz-col
          >
          <nz-col nzSpan="4">
            <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
          </nz-col>
          <nz-col nzSpan="16"><span>Supprimer</span></nz-col>
        </nz-row>
      </li>
    </ul>
  </nz-dropdown-menu>
</ng-container>
