import { ParticipantSmallId } from './../../../../components/participant/store/participant.model';
import { createAction, props } from '@ngrx/store';

export const queryParticipants = createAction(
  '[ParticipantsSelect/COMPONENT] Query Participants',
  props<{ filter: string; filterType: string }>()
);
export const queryParticipantsSuccess = createAction(
  '[ParticipantsSelect/API] Query Participants Success',
  props<{ participants: ParticipantSmallId[] }>()
);
export const queryParticipantsFailure = createAction(
  '[ParticipantsSelect/API] Query Participants Failure',
  props<{ error: any }>()
);
