import { UserState } from './../../../user/store/user.reducer';
import * as fromUserSelector from './../../../user/store/user.selectors';

import { OperationItemHeaderComponent } from './../operation-item-header/operation-item-header.component';
import { ComponentPortal } from '@angular/cdk/portal';
import { PortalNavigationService } from './../../../../portals/portal-navigation.service';
import { map, takeUntil } from 'rxjs/operators';
import { EMPTY, Observable, of, Subject } from 'rxjs';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { OperationState } from '../../store/operation.reducer';
import * as fromOperationAction from '../../store/operation.actions';
import * as fromOperationSelector from '../../store/operation.selectors';
import { OperationId } from '../../store/operation.model';
import { NzModalService } from 'ng-zorro-antd/modal';
import { OperationFormComponent } from '../operation-form/operation-form.component';
import { ExportSelectComponent } from 'src/app/contents/components/export-select/export-select.component';
import { Update } from '@ngrx/entity';
import { fade, listAnimation } from 'src/app/app-animation';
import { SharingComponent } from 'src/app/contents/components/sharing/sharing.component';
import { ModuleState } from 'src/app/components/module/store/module.reducer';
import * as fromModuleAction from 'src/app/components/module/store/module.actions';
import * as fromModuleSelector from 'src/app/components/module/store/module.selectors';
import { ActionState } from 'src/app/components/action/store/action.reducer';
import * as fromActionAction from 'src/app/components/action/store/action.actions';
import * as fromActionSelector from 'src/app/components/action/store/action.selectors';
import { SideNavigationState } from 'src/app/components/side-navigation/store/side-navigation.reducer';
import * as sideNavigationSelector from 'src/app/components/side-navigation/store/side-navigation.selectors';
import * as sideNavigationAction from 'src/app/components/side-navigation/store/side-navigation.actions';

@Component({
  selector: 'app-operation-item',
  templateUrl: './operation-item.component.html',
  styleUrls: ['./operation-item.component.scss'],
  animations: [fade, listAnimation],
})
export class OperationItemComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  subscribe = new Subject();
  routeData$: Observable<any> = of(null);
  operation$: Observable<OperationId | any> = of(null);
  modules$: Observable<any> = of(null);
  actions$: Observable<any> = of(null);
  entites$: Observable<any> = of(null);
  updateLoading$: Observable<any> = of(false);
  missions$: Observable<any> = of(null);
  mission$: Observable<any> = of(null);

  user$: Observable<any> = of(null);
  bodyComponent: string = '';
  componentPortalFilter: ComponentPortal<OperationItemHeaderComponent>;
  modalExportWidth: number = 480;
  modalWidth: number = 650;

  title: string = 'Nouveau projet';
  titleDownload: string = '';
  titleShare: string = '';

  @ViewChild('projetTitleForm', { static: false })
  projetTitleForm: TemplateRef<any>;

  @ViewChild('exportTitleForm', { static: false })
  exportTitleForm: TemplateRef<any>;

  constructor(
    private activieRoute: ActivatedRoute,
    private router: Router,
    private operationStore: Store<OperationState>,
    private moduleStore: Store<ModuleState>,
    private actionStore: Store<ActionState>,

    private userStore: Store<UserState>,
    private portalNavigationService: PortalNavigationService,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private sideNavigationStore: Store<SideNavigationState>
  ) {}

  ngOnInit(): void {
    this.getRouteData();
    this.getOperation();
    this.getUser();
    this.componentPortalFilter = new ComponentPortal(
      OperationItemHeaderComponent
    );

    this.getModules();
    this.getMissions();
    this.getMission();
    this.getUpdateLoading();
    this.onSetNavigation('operation_dashboard');
    this.onNavigation();
  }
  ngAfterViewInit(): void {
    this.setFilterPortal();
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
    //this.componentPortalFilter.detach();
  }

  getRouteData(): void {
    this.routeData$ = this.activieRoute.data;
  }
  getUser(): void {
    this.user$ = this.userStore.select(fromUserSelector.user);
  }

  onSetNavigation(navigation: string): void {
    this.sideNavigationStore.dispatch(
      sideNavigationAction.selectSideNavigation({
        navigation: { type: 'side', name: navigation },
      })
    );
  }

  onNavigation(): void {
    this.sideNavigationStore
      .select(sideNavigationSelector.navigation)
      .pipe(takeUntil(this.subscribe))
      .subscribe((navigation) => {
        switch (navigation?.name) {
          case 'operation_details':
            this.bodyComponent = 'details';

            break;
          case 'operation_dashboard':
            this.bodyComponent = 'dashboard';

            break;
          case 'operation_share':
            this.bodyComponent = 'share';
            break;
          case 'operation_download':
            this.onExportSelect(navigation?.item);
            break;
          case 'operation_details':
            this.bodyComponent = 'details';

            break;
          case 'operation_team':
            this.bodyComponent = 'team';

            break;
          case 'operation_suivis':
            this.bodyComponent = 'suivi';

            break;
          case 'operation_documents':
            this.bodyComponent = 'documents';

            break;
          case 'operation_entites':
            this.bodyComponent = 'entites';

            break;
          case 'operation_contacts':
            this.bodyComponent = 'contacts';

            break;
          case 'operation_participants':
            this.bodyComponent = 'participants';

            break;
          case 'operation_prescripteurs':
            this.bodyComponent = 'prescripteurs';

            break;
          case 'operation_modules':
            this.loadModules();

            this.bodyComponent = 'modules';

            break;
          case 'operation_actions':
            this.bodyComponent = 'actions';

            break;
          case 'operation_besoins':
            this.bodyComponent = 'besoins';

            break;

          case 'operation_missions':
            this.bodyComponent = 'missions';
            this.loadMissions();
            break;
          case 'operation_tasks':
            this.bodyComponent = 'tasks';
            this.loadMissions();
            break;

          case 'operation_events':
            this.bodyComponent = 'events';
            break;
          case 'operation_notes':
            this.bodyComponent = 'notes';
            break;

          default:
            this.bodyComponent = 'dashboard';

            break;
        }
      });
  }

  getOperation(): void {
    this.routeData$
      .pipe(takeUntil(this.subscribe))
      .subscribe((data: any): any => {
        if (!data?.operation) {
          this.router.navigate(['/projects']);
          return EMPTY;
        } else {
          this.operation$ = data?.operation;
        }
      });
  }

  setFilterPortal(): void {
    this.portalNavigationService.setPortal(this.componentPortalFilter);
  }

  onSelectActions(item: string, operation: OperationId): void {
    switch (item) {
      case 'download':
        this.onExportSelect(operation);
        break;
      case 'update':
        this.onNewOperationForm(operation);

        break;
      case 'missions':
        this.loadMissions();
        this.bodyComponent = item;

        break;

      default:
        this.bodyComponent = item;

        break;
    }
  }

  onNewOperationForm(operation: OperationId): void {
    this.title = `${operation.denomination}`;
    const modal = this.modal.create({
      nzContent: OperationFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.projetTitleForm,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });

    const instance = modal.getContentComponent();

    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });

    instance.update.subscribe((operation): any => {
      if (!operation) {
        return null;
      } else {
        this.onUpdate(operation);
        modal.close();
      }
    });
  }

  onExportSelect(operation: OperationId): void {
    if (!operation) return;
    const { denomination } = operation;
    this.titleDownload = `${denomination}`;

    const modal = this.modal.create({
      nzContent: ExportSelectComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalExportWidth,
      nzFooter: [],
      nzTitle: this.exportTitleForm,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
    });

    const instance = modal.getContentComponent();
    instance.isAnomyne = false;
    instance.isInclusSuivi = false;
    instance.isEXL = true;
    instance.isPDF = true;
    instance.isPPT = true;

    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
    instance.select.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        this.onExportValidation(event, operation);
        modal.close();
      }
    });
  }

  onExportValidation(event: any, operation: OperationId): void {
    if (!operation) return;

    switch (event.item) {
      case 'PPT':
        this.onExportPPT(operation);
        break;
      case 'EXCEL':
        this.onExportEXCEL(operation);
        break;
      case 'PDF':
        this.onExportPDF(operation);
        break;
      default:
        break;
    }
  }

  onSharing(operation: OperationId): void {
    if (!operation) return;
    const { denomination } = operation;
    this.titleDownload = `Partage : ${denomination}`;

    const modal = this.modal.create({
      nzContent: SharingComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalExportWidth,
      nzFooter: [],
      nzTitle: this.exportTitleForm,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
    });

    const instance = modal.getContentComponent();

    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
    instance.add.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        this.onSharingValidation(event, operation);
        modal.close();
      }
    });
  }

  onExportPPT(operation: OperationId): void {
    this.operationStore.dispatch(
      fromOperationAction.exportPPTOperation({ operation })
    );
  }
  onExportPDF(operation: OperationId): void {
    this.operationStore.dispatch(
      fromOperationAction.exportPDFOperation({ operation })
    );
  }
  onExportEXCEL(operation: OperationId): void {
    this.operationStore.dispatch(
      fromOperationAction.exportEXCELOperation({ operation })
    );
  }

  onUpdate(operation: Update<OperationId>): void {
    if (!operation) return;
    this.operationStore.dispatch(
      fromOperationAction.updateOperation({ operation })
    );
  }

  onSharingValidation(share: any, operation: OperationId): void {
    if (!operation || !share) return;
  }

  //MODULES
  loadModules(): void {
    this.operation$.pipe(takeUntil(this.subscribe)).subscribe((operation) => {
      if (!operation) return;
      const { id } = operation;
      this.moduleStore.dispatch(
        fromModuleAction.loadModules({ operationId: id })
      );
    });
  }

  getModules(): void {
    this.modules$ = this.moduleStore.select(fromModuleSelector.modules);
  }

  //ACTIONS
  loadActions(): void {
    this.operation$.pipe(takeUntil(this.subscribe)).subscribe((operation) => {
      if (!operation) return;
      const { id } = operation;
      this.actionStore.dispatch(
        fromActionAction.loadActions({ operationId: id })
      );
    });
  }

  getActions(): void {
    this.actions$ = this.actionStore.select(fromActionSelector.actions);
  }
  //ENTITES

  //MISSION
  getUpdateLoading(): void {
    this.updateLoading$ = this.operationStore.select(
      fromOperationSelector.updateLoading
    );
  }

  loadMissions(): void {
    this.operation$.pipe(takeUntil(this.subscribe)).subscribe((operation) => {
      if (!operation) return;
      const { id } = operation;
      this.operationStore.dispatch(
        fromOperationAction.loadOperationMissions({ id })
      );
    });
  }
  loadMission(mission: any, operation: OperationId): void {
    if (!operation || !mission) return;
    const { id } = mission;

    if (!id) return;

    this.operationStore.dispatch(
      fromOperationAction.loadOperationMission({
        operationId: operation.id,
        id: id,
      })
    );
  }

  getMissions(): void {
    this.missions$ = this.operationStore.select(fromOperationSelector.missions);
  }
  getMission(): void {
    this.mission$ = this.operationStore.select(fromOperationSelector.mission);
  }

  onAddMission(mission: any): void {
    if (!mission) return;
    this.operationStore.dispatch(
      fromOperationAction.addOperationMission({ mission })
    );
  }

  onUpdateMission(mission: any): void {
    if (!mission) return;
    const nextMission: Update<any> = {
      id: mission.id,
      changes: { ...mission },
    };

    this.operationStore.dispatch(
      fromOperationAction.updateOperationMission({
        id: mission.id,
        mission: nextMission,
      })
    );
  }

  onClearMission(): void {
    this.operationStore.dispatch(fromOperationAction.clearOperationMission());
  }

  onDeleteMission(mission: any): void {
    if (!mission) return;
    this.operationStore.dispatch(
      fromOperationAction.deleteOperationMission({
        operationId: mission.operation.id,
        id: mission.id,
      })
    );
  }

  //DELETE OPERATION
  onDelete(operation: OperationId): void {
    if (!operation) return;
    this.operationStore.dispatch(
      fromOperationAction.deleteOperation({ id: operation.id })
    );
  }
}
