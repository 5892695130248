import { QueryModulesService } from './../../../service/query-modules.service';
import { ItemEventId } from '../../../features/suivi/components/item-event/itemEvent.model';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ModuleStorageService } from './../../../service/module-storage.service';
import { ModuleSuivisService } from './../../../service/module-suivis.service';
import { ModuleService } from './../../../service/module.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, map, tap, exhaustMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as fromModuleAction from './module.actions';
import { ModuleSmallId, ModuleId } from './module.model';
import { MissionsService } from 'src/app/service/missions.service';
import { QueryMissionsService } from 'src/app/service/query-missions.service';
import { QueryActionsService } from 'src/app/service/query-actions.service';
import {
  NzNotificationPlacement,
  NzNotificationService,
} from 'ng-zorro-antd/notification';

const NOTIFICATION_PLACEMENT: NzNotificationPlacement = 'topRight';

@Injectable()
export class ModuleEffects {
  loadModules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromModuleAction.loadModules),
      mergeMap((action) =>
        this.moduleService.getAll(action.operationId).pipe(
          map((modules: ModuleSmallId[]) =>
            fromModuleAction.loadModulesSuccess({
              modules,
            })
          ),
          catchError((error) =>
            of(fromModuleAction.loadModulesFailure({ error }))
          )
        )
      )
    )
  );
  loadModuleTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromModuleAction.loadModuleTypes),
      mergeMap(() =>
        this.moduleService.getModuleTypes().pipe(
          map((respons: any) => {
            const types = respons.data.moduleType;
            return fromModuleAction.loadModuleTypesSuccess({
              types,
            });
          }),
          catchError((error) =>
            of(fromModuleAction.loadModuleTypesFailure({ error }))
          )
        )
      )
    )
  );

  queryModules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromModuleAction.queryModules),
      mergeMap((action) =>
        this.queryModule.queryModules(action.filter).pipe(
          map((modules: ModuleSmallId[]) =>
            fromModuleAction.queryModulesSuccess({
              modules,
            })
          ),
          catchError((error) =>
            of(fromModuleAction.queryModulesFailure({ error }))
          )
        )
      )
    )
  );

  loadModule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromModuleAction.loadModule),
      mergeMap((action) =>
        this.moduleService
          .getOneDetails(action.operationId, action.moduleId)
          .pipe(
            map((module: ModuleId) =>
              fromModuleAction.loadModuleSuccess({
                module,
              })
            ),
            catchError((error) =>
              of(fromModuleAction.loadModuleFailure({ error }))
            )
          )
      )
    )
  );

  //ADD
  addModule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromModuleAction.addModule),
      tap(() => {
        this.notification.info(
          'Nouveau',
          'Création du nouveau module en cours...',
          {
            nzDuration: 6000,
            nzAnimate: true,
            nzPlacement: NOTIFICATION_PLACEMENT,
            nzKey: 'CREATE_MODULE',
          }
        );
      }),

      mergeMap((action) =>
        this.moduleService.createModule(action.module).pipe(
          map((respons: any) => {
            const success: string = respons.data.createModule;
            this.notification.success('Nouveau', success, {
              nzDuration: 6000,
              nzAnimate: true,
              nzPlacement: NOTIFICATION_PLACEMENT,
              nzKey: 'CREATE_MODULE',
            });

            return fromModuleAction.addModuleSuccess({
              success,
            });
          }),
          catchError((error) => {
            this.onErrorNotification(error, 'CREATE_MODULE');

            return of(fromModuleAction.addModuleFailure({ error }));
          })
        )
      )
    )
  );

  //UPDATE
  updateModule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromModuleAction.updateModule),
      tap(() => {
        this.notification.info('Modification', 'Modification en cours...', {
          nzDuration: 6000,
          nzAnimate: true,
          nzPlacement: NOTIFICATION_PLACEMENT,
          nzKey: 'UPDATE_MODULE',
        });
      }),
      mergeMap((action) =>
        this.moduleService
          .updateOne(action.operationId, action.module.changes)
          .pipe(
            map((success: string) => {
              this.notification.success('Modification', success, {
                nzDuration: 8000,
                nzAnimate: true,
                nzPlacement: NOTIFICATION_PLACEMENT,
                nzKey: 'UPDATE_MODULE',
              });

              return fromModuleAction.updateModuleSuccess({
                success,
              });
            }),
            catchError((error) => {
              this.onErrorNotification(error, 'UPDATE_MODULE');

              return of(fromModuleAction.updateModuleFailure({ error }));
            })
          )
      )
    )
  );

  //DELETE
  deleteModule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromModuleAction.deleteModule),
      tap(() => {
        this.notification.info('Suppression', 'Suppression en cours...', {
          nzDuration: 6000,
          nzAnimate: true,
          nzPlacement: NOTIFICATION_PLACEMENT,
          nzKey: 'DELETE_MODULE',
        });
      }),
      mergeMap((action) =>
        this.moduleService.deleteModule(action.operationId, action.id).pipe(
          map((respons: any) => {
            const success: string = respons.data.deleteModule;
            this.notification.success('Suppression', success, {
              nzDuration: 8000,
              nzAnimate: true,
              nzPlacement: NOTIFICATION_PLACEMENT,
              nzKey: 'DELETE_MODULE',
            });

            return fromModuleAction.deleteModuleSuccess({
              success,
            });
          }),
          catchError((error) => {
            this.onErrorNotification(error, 'DELETE_MODULE');
            return of(fromModuleAction.deleteModuleFailure({ error }));
          })
        )
      )
    )
  );

  //DELETE
  deleteModules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromModuleAction.deleteModules),
      tap(() => {
        this.notification.info('Suppression', 'Suppression en cours...', {
          nzDuration: 6000,
          nzAnimate: true,
          nzPlacement: NOTIFICATION_PLACEMENT,
          nzKey: 'DELETE_MODULES',
        });
      }),
      mergeMap((action) =>
        this.moduleService.deleteModules(action.operationId).pipe(
          map((respons: any) => {
            const success: string = respons.data.deleteAllModules;

            this.notification.success('Suppression', success, {
              nzDuration: 8000,
              nzAnimate: true,
              nzPlacement: NOTIFICATION_PLACEMENT,
              nzKey: 'DELETE_MODULES',
            });

            return fromModuleAction.deleteModuleSuccess({
              success,
            });
          }),
          catchError((error) => {
            this.onErrorNotification(error, 'DELETE_MODULE');
            return of(fromModuleAction.deleteModuleFailure({ error }));
          })
        )
      )
    )
  );

  //SUIVIS
  loadSuivis$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromModuleAction.loadSuivis),
      mergeMap((action) =>
        this.moduleSuivis.getAll().pipe(
          map((suivis: ItemEventId[]) =>
            fromModuleAction.loadSuivisSuccess({
              suivis,
            })
          ),
          catchError((error) =>
            of(fromModuleAction.loadSuivisFailure({ error }))
          )
        )
      )
    )
  );
  addSuivi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromModuleAction.addSuivi),
      mergeMap((action) =>
        this.moduleSuivis
          .addOne(action.operationId, action.moduleId, action.suivi)
          .pipe(
            map((suivi: ItemEventId) => {
              this.message.success(`Suivi ajouté`, { nzDuration: 6000 });
              return fromModuleAction.addSuiviSuccess({
                suivi,
              });
            }),
            catchError((error) =>
              of(fromModuleAction.addSuiviFailure({ error }))
            )
          )
      )
    )
  );
  updateSuivi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromModuleAction.updateSuivi),
      mergeMap((action) =>
        this.moduleSuivis
          .updateOne(action.operationId, action.moduleId, action.suivi.changes)
          .pipe(
            map((suivi: ItemEventId) => {
              this.message.success(`Suivi modifié`, { nzDuration: 6000 });
              return fromModuleAction.updateSuiviSuccess({
                suivi,
              });
            }),
            catchError((error) =>
              of(fromModuleAction.updateSuiviFailure({ error }))
            )
          )
      )
    )
  );
  deleteSuivi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromModuleAction.deleteSuivi),
      mergeMap((action) =>
        this.moduleSuivis
          .deleteOne(action.operationId, action.moduleId, action.suiviId)
          .pipe(
            map((success: string) => {
              this.message.success(success, { nzDuration: 6000 });
              return fromModuleAction.deleteSuiviSuccess({
                success,
              });
            }),
            catchError((error) =>
              of(fromModuleAction.deleteSuiviFailure({ error }))
            )
          )
      )
    )
  );
  //DOCUMENT
  //load
  loadDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromModuleAction.loadDocuments),
      mergeMap((action) =>
        this.moduleStorage.getAll().pipe(
          map((documents: any) =>
            fromModuleAction.loadDocumentsSuccess({
              documents,
            })
          ),
          catchError((error) =>
            of(fromModuleAction.loadDocumentsFailure({ error }))
          )
        )
      )
    )
  );

  //add
  addDocument$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromModuleAction.addDocuments),
        mergeMap((action) =>
          this.moduleStorage.addFiles(action.module, action.documents).pipe(
            map((documents: any) => {
              const plurial: string = `${
                action.documents?.length > 1 ? 's' : ''
              }`;

              const success: string = `Document${plurial} ajouté${plurial}`;
              this.message.success(success, { nzDuration: 6000 });
              return fromModuleAction.addDocumentsSuccess({
                documents,
              });
            }),
            catchError((error) =>
              of(fromModuleAction.addDocumentsFailure({ error }))
            )
          )
        )
      ),
    { dispatch: false }
  );

  //delete
  deleteDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromModuleAction.deleteDocument),
      mergeMap((action) =>
        this.moduleStorage.deleteOne(action.module, action.document).pipe(
          map((success: string) => {
            this.message.success(success, { nzDuration: 6000 });
            return fromModuleAction.deleteDocumentSuccess({
              success,
            });
          }),
          catchError((error) =>
            of(fromModuleAction.deleteDocumentFailure({ error }))
          )
        )
      )
    )
  );

  //MISSIONS
  //load missions
  loadMissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromModuleAction.loadMissions),
      mergeMap((action) =>
        this.queryMissions.getAllByModule().pipe(
          map((missions: any) =>
            fromModuleAction.loadMissionsSuccess({
              missions,
            })
          ),
          catchError((error) =>
            of(fromModuleAction.loadMissionsFailure({ error }))
          )
        )
      )
    )
  );

  //ACTIONS
  //load actions
  loadActions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromModuleAction.loadActions),
      mergeMap((action) =>
        this.queryActions.getByModule().pipe(
          map((actions: any) =>
            fromModuleAction.loadActionsSuccess({
              actions,
            })
          ),
          catchError((error) =>
            of(fromModuleAction.loadActionsFailure({ error }))
          )
        )
      )
    )
  );

  //QUERY
  queryModulesByOperation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromModuleAction.queryModulesByOperation),
      exhaustMap((action) =>
        this.queryModule.queryByOperation().pipe(
          map((modules: ModuleId[] | any) => {
            return fromModuleAction.queryModulesByOperationSuccess({
              modules,
            });
          }),
          catchError((error) =>
            of(fromModuleAction.queryModulesByOperationFailure({ error }))
          )
        )
      )
    )
  );

  //UTILS
  loadModuleUtils$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromModuleAction.loadUtils),
      mergeMap((action) =>
        this.queryModule.queryUtilities().pipe(
          map((snap: any) => {
            const utils = snap.data?.moduleType;
            return fromModuleAction.loadUtilsSuccess({
              utils,
            });
          }),
          catchError((error) =>
            of(fromModuleAction.loadUtilsFailure({ error }))
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private moduleService: ModuleService,
    private moduleSuivis: ModuleSuivisService,
    private moduleStorage: ModuleStorageService,
    private queryModule: QueryModulesService,
    private message: NzMessageService,
    private queryMissions: QueryMissionsService,
    private queryActions: QueryActionsService,
    private notification: NzNotificationService
  ) {}

  onErrorNotification(error: any, key: string): void {
    this.notification.error('Error', error.message, {
      nzDuration: 10000,
      nzAnimate: true,
      nzPlacement: NOTIFICATION_PLACEMENT,
      nzKey: key ? key : '',
    });
  }
}
