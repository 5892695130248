import { Injectable } from '@angular/core';
import { gql, Query } from 'apollo-angular';

@Injectable({
  providedIn: 'any',
})
export class QueryComplementSalaireContratGQLService extends Query<Response> {
  document = gql`
    query Referentiel_complement_salaire {
      referentiel_complement_salaire {
        libelle
        type
      }
    }
  `;
}
