import { ActivatedRoute, Router } from '@angular/router';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Observable, of, Subject, takeUntil } from 'rxjs';
import {
  NzTableFilterFn,
  NzTableFilterList,
  NzTableSortFn,
  NzTableSortOrder,
} from 'ng-zorro-antd/table';
import { ParticipantId } from 'src/app/components/participant/store/participant.model';
import { ParticipantActionsAccompagnementFormComponent } from '../participant-actions-accompagnement-form/participant-actions-accompagnement-form.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Store } from '@ngrx/store';
import { ParticipantAccompagnementState } from '../../store/participant-action-accompagnement.reducer';
import * as fromParticipantAccompagnementAction from '../../store/participant-action-accompagnement.actions';
import * as fromParticipantAccompagnementSelector from '../../store/participant-action-accompagnement.selectors';
import { Update } from '@ngrx/entity';
import {
  animate,
  query,
  stagger,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { enterAnimation, listAnimation } from 'src/app/app-animation';
import { ParticipantState } from 'src/app/components/participant/store/participant.reducer';
import * as fromParticipantSelector from 'src/app/components/participant/store/participant.selectors';

interface ColumnItem {
  name: string;
  sortOrder: NzTableSortOrder | null;
  sortFn: NzTableSortFn<any> | null;
  listOfFilter: NzTableFilterList;
  filterFn: NzTableFilterFn<any> | null;
  filterMultiple: boolean;
  sortDirections: NzTableSortOrder[] | any;
  width?: string | any;
}

const ExitStyle = {
  color: 'red',
  border: '1px solid green',
};
const EnterStyle = {
  color: 'green',
  border: '5px solid red',
};

@Component({
  selector: 'app-participant-action-accompagnement-table',
  templateUrl: './participant-action-accompagnement-table.component.html',
  styleUrls: ['./participant-action-accompagnement-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [enterAnimation, listAnimation],
})
export class ParticipantActionAccompagnementTableComponent
  implements OnInit, OnChanges
{
  subscribe = new Subject();
  data$: Observable<any> = of(null);
  participant$: Observable<ParticipantId | any> = of(null);

  newCardDefinition: string = '';
  newDocument = false;
  newEvent = false;
  accompagnements$: Observable<any> = of([]);
  loading$: Observable<boolean> = of(false);

  modalWidth: number = 870;
  title: string = 'Nouvelle action';

  listOfColumns: ColumnItem[] = [];
  //FILTERS
  filterByTypes: any = [];
  filterByTypesFn = (list: string[], item: any) =>
    list.some((type) => item.type === type);

  //SORTS

  sortFnByDates = (a: any, b: any) =>
    a.dateStart['seconds'] - b.dateStart['seconds'];

  @Input() scroll: any = { x: '1100px', y: '100%' };
  @Output() selected = new EventEmitter(false);
  @ViewChild('actionAccompagnementTitleForm', { static: false })
  actionAccompagnementTitleForm: TemplateRef<any> | any;

  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private participantAccompagnementStore: Store<ParticipantAccompagnementState>,
    private activateRouter: ActivatedRoute,
    private router: Router,
    private participantStore: Store<ParticipantState>
  ) {}

  ngOnInit(): void {
    this.getParticipant();
    this.loadAccompagnementTypes();
    this.loadAccompagnementChampsTypes();
    this.getAccompagnements();
    this.getLoading();
  }

  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getRouteData(): void {
    this.data$ = this.activateRouter.data;
  }

  getParticipant(): void {
    this.participant$ = this.participantStore.select(
      fromParticipantSelector.participant
    );
  }

  loadAccompagnementChampsTypes(): void {
    this.participantAccompagnementStore.dispatch(
      fromParticipantAccompagnementAction.loadParticipantAccompagnementChampsTypes()
    );
  }
  loadAccompagnementTypes(): void {
    this.participantAccompagnementStore.dispatch(
      fromParticipantAccompagnementAction.loadParticipantAccompagnementTypes()
    );
  }

  loadAccompagnements(): void {
    this.participant$
      .pipe(takeUntil(this.subscribe))
      .subscribe((participant) => {
        if (!participant) return;
        this.participantAccompagnementStore.dispatch(
          fromParticipantAccompagnementAction.loadParticipantActionAccompagnements(
            { id: participant.id }
          )
        );
      });
  }

  getAccompagnements(): void {
    this.accompagnements$ = this.participantAccompagnementStore.select(
      fromParticipantAccompagnementSelector.accompagnments
    );
  }
  getLoading(): void {
    this.loading$ = this.participantAccompagnementStore.select(
      fromParticipantAccompagnementSelector.loading
    );
  }

  trackByFn(index: number, name: any): number {
    return name.id;
  }

  onSelect(participant: ParticipantId, item: any): void {
    if (!item) return;
    const id: string = participant.id;
    const itemId: string = item.id;
    this.router.navigate(['/participant', id, 'action', itemId]);
  }

  onSelectDropdown(event: string, item: any, participant: ParticipantId): void {
    if (!event || !item || !participant) return;

    switch (event) {
      case 'delete':
        this.onDelete(participant.id, item.id);

        break;

      default:
        break;
    }
  }
  onNew(participant: ParticipantId): void {
    if (!participant) return;

    const modal = this.modal.create({
      nzContent: ParticipantActionsAccompagnementFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.actionAccompagnementTitleForm,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });
    const instance = modal.getContentComponent();
    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
    instance.add.subscribe((event) => {
      if (!event) return;
      this.onAdd(participant.id, event);
      modal.close();
    });
  }

  onAdd(participantId: string, accompagnement: any): void {
    if (!participantId || !accompagnement) return;
    this.participantAccompagnementStore.dispatch(
      fromParticipantAccompagnementAction.addParticipantActionAccompagnement({
        participantId,
        accompagnement,
      })
    );
  }

  onUpdate(participantId: string, accompagnement: Update<any>): void {
    if (!participantId || !accompagnement) return;
    this.participantAccompagnementStore.dispatch(
      fromParticipantAccompagnementAction.updateParticipantActionAccompagnement(
        { participantId, accompagnement }
      )
    );
  }

  onDelete(participantId: string, id: string): void {
    if (!participantId || !id) return;
    this.participantAccompagnementStore.dispatch(
      fromParticipantAccompagnementAction.deleteParticipantActionAccompagnement(
        { participantId, id }
      )
    );
  }

  onDeleteAll(participantId: string): void {
    if (!participantId) return;
    this.participantAccompagnementStore.dispatch(
      fromParticipantAccompagnementAction.deleteParticipantActionAccompagnements(
        { participantId }
      )
    );
  }
}
