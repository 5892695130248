import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Contrat } from './contrat.model';
import * as ContratActions from './contrat.actions';

export const contratsFeatureKey = 'contrats';

export interface ContratState extends EntityState<Contrat> {
  loading: boolean;
  types: any[];
  natures: any[];
  salaires: any[];
  experiences: any[];
  permis: any[];
  numerique: string[];
  complement: string[];
  horaires: string[];
  mobilities: string[];
  jours: string[];
  evolutions: any[];
  error: any;
}

export const adapter: EntityAdapter<Contrat> = createEntityAdapter<Contrat>();

export const initialState: ContratState = adapter.getInitialState({
  loading: false,
  types: [],
  natures: [],
  salaires: [],
  experiences: [],
  permis: [],
  numerique: [],
  complement: [],
  horaires: [],
  mobilities: [],
  jours: ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
  evolutions: [],
  error: undefined,
});

export const reducer = createReducer(
  initialState,

  //TYPE CONTRAT
  on(ContratActions.loadTypesContrats, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(ContratActions.loadTypesContratsSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      types: action.types,
    };
  }),
  on(ContratActions.loadTypesContratsFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  }),

  //NATURE CONTRAT
  on(ContratActions.loadNaturesContrats, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(ContratActions.loadNaturesContratsSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      natures: action.natures,
    };
  }),
  on(ContratActions.loadNaturesContratsFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  }),

  //NIVEAU D'EXPERIENCE
  on(ContratActions.loadNiveauExperiencesSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      experiences: action.experiences,
    };
  }),
  on(ContratActions.loadNiveauExperiencesFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  }),

  //NIVEAU DE SALAIRE
  on(ContratActions.loadNiveauSalaireSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      salaires: action.salaires,
    };
  }),
  on(ContratActions.loadNiveauSalaireFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  }),

  //PERMIS
  on(ContratActions.loadPermisSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      permis: action.permis,
    };
  }),
  on(ContratActions.loadPermisFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  }),

  //NUMERIQUE
  on(ContratActions.loadLevelNumeriqueSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      numerique: action.numerique,
    };
  }),
  on(ContratActions.loadLevelNumeriqueFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  }),

  //COMPLEMENT SALAIRE
  on(ContratActions.loadComplementSalaireSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      complement: action.complement,
    };
  }),
  on(ContratActions.loadComplementSalaireFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  }),

  //TYPE HORAIRES
  on(ContratActions.loadTypesHoraireSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      horaires: action.horaires,
    };
  }),
  on(ContratActions.loadTypesHoraireFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  }),

  //MOBILITY GEOGRAPHIC
  on(ContratActions.loadMobilityGeographicSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      mobilities: action.mobilities,
    };
  }),
  on(ContratActions.loadMobilityGeographicFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  }),

  //CONTRAT JOURS
  on(ContratActions.loadContratJoursSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      jours: action.jours,
    };
  }),
  on(ContratActions.loadContratJoursFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  }),

  //EVOLUTIONS
  on(ContratActions.loadEvolutionTypesSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      evolutions: action.evolutions,
    };
  }),
  on(ContratActions.loadEvolutionTypesFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
