import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  DashboardState,
  dashboardsFeatureKey,
  selectAll,
} from './dashboard.reducer';

export const selectDashboardState =
  createFeatureSelector<DashboardState>(dashboardsFeatureKey);

export const dashboard = createSelector(selectDashboardState, selectAll);

export const entites = createSelector(
  selectDashboardState,
  (state: DashboardState) => state.entitesStatistique
);

export const contacts = createSelector(
  selectDashboardState,
  (state: DashboardState) => state.contactsStatistique
);

export const participants = createSelector(
  selectDashboardState,
  (state: DashboardState) => state.participantsStatistique
);

export const projets = createSelector(
  selectDashboardState,
  (state: DashboardState) => state.projetsStatistique
);

export const select = createSelector(
  selectDashboardState,
  (state: DashboardState) => state.select
);

export const error = createSelector(
  selectDashboardState,
  (state: DashboardState) => state.error
);
