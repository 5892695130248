<nz-steps
  [nzCurrent]="currentStep"
  nzSize="small"
  nzDirection="vertical"
  nzProgressDot
>
  <ng-container *ngIf="isAdd || isMetierUpdate === 'UPDATE_METIER'; else null">
    <nz-step
      [nzTitle]="isOperation ? 'Projet' : 'Adresse'"
      *ngIf="isContrat"
    ></nz-step>
    <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>

    <nz-step [nzTitle]="titleMission | titlecase"></nz-step>
    <nz-step nzTitle="Description"></nz-step>
    <nz-step nzTitle="Compétences"></nz-step>
  </ng-container>
  <nz-divider
    nzOrientation="center"
    nzType="horizontal"
    *ngIf="isAdd"
  ></nz-divider>

  <ng-container
    *ngIf="
      isContrat && (isAdd || isContratUpdate === 'UPDATE_CONTRAT');
      else null
    "
  >
    <nz-step nzTitle="Contrat"></nz-step>
    <nz-step nzTitle="Rémunération"></nz-step>
    <nz-step nzTitle="Temps de travail"></nz-step>
    <nz-step nzTitle="Évolution"></nz-step>
  </ng-container>
</nz-steps>
