import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';

import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { MessageId, MessageItem } from '../../store/message.model';

@Component({
  selector: 'app-messages-screen-chat-event',
  templateUrl: './messages-screen-chat-event.component.html',
  styleUrls: ['./messages-screen-chat-event.component.scss'],
})
export class MessagesScreenChatEventComponent implements OnInit {
  user$: Observable<any> = of(null);

  eventBlue: string = '#003566';
  eventGrey: string = '#f0f0f0';

  @Input() message: MessageItem | any = null;

  constructor(private userStore: Store<UserState>) {}

  ngOnInit(): void {
    this.get__USER();
  }

  get__USER(): void {
    this.user$ = this.userStore.pipe(select(fromUserSelector.user));
  }
}
