import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, of, Subject, takeUntil, BehaviorSubject } from 'rxjs';
import { UtilitiesState } from 'src/app/contents/components/utilities/store/utilities.reducer';
import * as fromUtilitiesSelector from 'src/app/contents/components/utilities/store/utilities.selectors';
import * as fromUtilitiesAction from 'src/app/contents/components/utilities/store/utilities.actions';

@Component({
  selector: 'app-participant-diagnostic-form-sante',
  templateUrl: './participant-diagnostic-form-sante.component.html',
  styleUrls: ['./participant-diagnostic-form-sante.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantDiagnosticFormSanteComponent
  implements OnInit, OnChanges, OnDestroy
{
  sante$: Observable<any> = of(null);
  couvertureMaladie$: Observable<any> = of(null);
  handicap$: Observable<any> = of(null);

  subscribe = new Subject();

  //VARIABLES
  isFreinTitle: string = 'La santé est-elle un frein identifié ?';
  formatterNir = (value: string): string =>
    `${value.substring(0, 1)} ${value.substring(2, 3)} ${value.substring(
      4,
      5
    )} ${value.substring(6, 8)} ${value.substring(9, 11)} ${value.substring(
      12,
      13
    )}`;

  //FORM
  santeForm: FormGroup = this.fb.group({});
  sante: FormControl<any> = new FormControl('');
  couvertureMaladie: FormControl<any> = new FormControl('');
  handicap: FormControl<string | any> = new FormControl('');
  nirSecuriteSociale: FormControl<number | any> = new FormControl();
  isFrein: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );

  @Input() santeInput$ = new BehaviorSubject<any>(null);
  @Output() add = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private utilitiesStore: Store<UtilitiesState>
  ) {}

  ngOnInit(): void {
    this.getSanteReferentiel();
    this.loadSanteReferentiel();
    this.getCouvertureMaladieReferentiel();
    this.loadCouvertureMaladieReferentiel();
    this.getHandicapReferentiel();
    this.loadHandicapReferentiel();
    this.form();
    this.onFormChanges();
  }
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getSanteReferentiel(): void {
    this.sante$ = this.utilitiesStore.select(fromUtilitiesSelector.sante);
  }
  loadSanteReferentiel(): void {
    this.utilitiesStore.dispatch(fromUtilitiesAction.loadSanteReferentiel());
  }
  getCouvertureMaladieReferentiel(): void {
    this.couvertureMaladie$ = this.utilitiesStore.select(
      fromUtilitiesSelector.couvertureMaladie
    );
  }
  loadCouvertureMaladieReferentiel(): void {
    this.utilitiesStore.dispatch(
      fromUtilitiesAction.loadCouvertureMaladieReferentiel()
    );
  }
  getHandicapReferentiel(): void {
    this.handicap$ = this.utilitiesStore.select(fromUtilitiesSelector.handicap);
  }
  loadHandicapReferentiel(): void {
    this.utilitiesStore.dispatch(fromUtilitiesAction.loadHandicapReferentiel());
  }

  form(): void {
    this.formInit();
    this.formCreate();
    this.formSet();
  }

  formInit(): void {
    this.sante = new FormControl<string>('');
    this.couvertureMaladie = new FormControl<any>('', Validators.required);
    this.handicap = new FormControl<string>('');
    this.nirSecuriteSociale = new FormControl<number | any>(0);

    this.isFrein = new FormControl<boolean>(false, Validators.required);
  }

  formCreate(): void {
    this.santeForm = this.fb.group({
      sante: this.sante,
      couvertureMaladie: this.couvertureMaladie,
      handicap: this.handicap,
      nirSecuriteSociale: this.nirSecuriteSociale,
      isFrein: this.isFrein,
    });
  }

  formSet(): void {
    this.santeInput$.pipe(takeUntil(this.subscribe)).subscribe((sante) => {
      if (!sante) return;
      this.santeForm.patchValue({ ...sante });
    });
  }

  //COMPARE SELECT LIST UPDATE
  compareHandicap(obj1: any, obj2: any) {
    return obj1 && obj2 && obj1 === obj2;
  }
  compareCouverture(obj1: any, obj2: any) {
    return obj1 && obj2 && obj1 === obj2;
  }

  onSelectHandicap(event: any): void {
    this.santeForm.patchValue({
      handicap: event,
    });
  }

  onFormChanges(): void {
    this.santeForm.valueChanges
      .pipe(takeUntil(this.subscribe))
      .subscribe((values) => this.add.emit(values));
  }
}
