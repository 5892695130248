import { NzSelectSizeType } from 'ng-zorro-antd/select';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BehaviorSubject, map, Observable, of, Subject, takeUntil } from 'rxjs';
import { GeoTerritoireState } from './../../store/geo-territoire.reducer';
import * as fromGeoTerritoireAction from './../../store/geo-territoire.actions';
import * as fromGeoTerritoireSelector from './../../store/geo-territoire.selectors';

@Component({
  selector: 'app-geo-territoire-search',
  templateUrl: './geo-territoire-search.component.html',
  styleUrls: ['./geo-territoire-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeoTerritoireSearchComponent
  implements OnInit, OnChanges, OnDestroy
{
  regions$: Observable<any> = of(null);
  departements$: Observable<any> = of(null);
  communes$: Observable<any> = of(null);
  selectedRegion$ = new BehaviorSubject<string>('');
  selectedDepartement$ = new BehaviorSubject<string>('');
  selectedCommune$ = new BehaviorSubject<string>('');
  communeTitle$ = new BehaviorSubject<string>('Communes');
  selectedDisplayLimit: number = 30;
  regionFormControl = new FormControl<any>('');
  departementFormControl = new FormControl<any>('');
  communeFormControl = new FormControl<any>('');

  geoTerritoireForm: FormGroup = this.fb.group({
    region: this.regionFormControl,
    departement: this.departementFormControl,
    commune: this.communeFormControl,
  });

  searchFilterForm: FormGroup = this.fb.group({
    searchCommune: '',
    searchDepartement: '',
    searchRegion: '',
  });

  subscribe = new Subject<any>();
  isRegionsLoading$: Observable<boolean> = of(false);
  isDepartementsLoading$: Observable<boolean> = of(false);
  isCommunesLoading$: Observable<boolean> = of(false);

  @Input() type: string = 'list';
  @Input() size: NzSelectSizeType = 'small';
  @Output() geoterritoire = new EventEmitter<{
    region: any;
    departement: any;
    commune: any;
  }>();

  constructor(
    private GeoTerritoireStore: Store<GeoTerritoireState>,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.getLoadingRegions();
    this.getLoadingDepartements();
    this.getLoadingCommunes();
    this.getRegions();
    this.getDepartements();
    this.getCommunes();

    this.loadRegions();
    this.onSearchCommunes();
    this.onSearchDepartements();
    this.onSearchRegions();
    this.onChanges();
  }
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  // REGIONS
  getLoadingRegions(): void {
    this.isRegionsLoading$ = this.GeoTerritoireStore.select(
      fromGeoTerritoireSelector.loadingRegions
    );
  }
  loadRegions(): void {
    this.selectedRegion$.next('');
    this.GeoTerritoireStore.dispatch(
      fromGeoTerritoireAction.loadGeoTerritoireRegions()
    );
  }

  getRegions(): void {
    this.regions$ = this.GeoTerritoireStore.select(
      fromGeoTerritoireSelector.regions
    );
  }
  onChangeRegion(event: string | any, type: string): void {
    if (type === 'select') {
      this.onSelectRegion(event);
    }
    this.searchFilterForm.patchValue({ searchRegion: event });
  }

  onSearchRegions(): void {
    this.searchFilterForm
      .get('searchRegion')
      ?.valueChanges.pipe(takeUntil(this.subscribe))
      .subscribe((input) => {
        if (!input) {
          this.getRegions();
        }

        this.regions$ = this.regions$?.pipe(
          map((regions: any[]) => {
            if (!regions?.length) [];

            return regions?.filter((region) =>
              region?.nom?.toLowerCase().includes(input)
            );
          })
        );
      });
  }

  onCleanSearchRegion(): void {
    this.searchFilterForm.patchValue({ searchRegion: '' });
    this.selectedRegion$.next('');
    this.getRegions();
    this.onCleanSearchDepartement();
    this.departements$ = of(null);
    this.geoTerritoireForm.patchValue({
      region: '',
      departement: '',
      commune: '',
    });
  }

  onSelectRegion(region: any): void {
    const { code, nom } = region;
    this.geoTerritoireForm.patchValue({
      region: { code, nom },
    });

    const display: string = `${code} - ${nom.substring(
      0,
      this.selectedDisplayLimit
    )}`;
    this.selectedRegion$.next(display);
    this.searchFilterForm.patchValue({
      searchRegion: display,
    });
    this.selectedDepartement$.next('');
    this.selectedCommune$.next('');

    this.loadDepartements(code);
    this.getCommunes();
  }

  // COMMUNES
  getLoadingCommunes(): void {
    this.isCommunesLoading$ = this.GeoTerritoireStore.select(
      fromGeoTerritoireSelector.loadingCommunes
    );
  }
  loadCommunes(code: string): void {
    this.GeoTerritoireStore.dispatch(
      fromGeoTerritoireAction.loadGeoTerritoireCommunes({ code })
    );
  }
  getCommunes(): void {
    this.communes$ = this.GeoTerritoireStore.select(
      fromGeoTerritoireSelector.communes
    );
  }

  onChangeCommune(event: string | any, type: string): void {
    if (type === 'select') {
      this.onSelectCommune(event);
    }

    this.searchFilterForm.patchValue({ searchCommune: event });
  }

  onSearchCommunes(): void {
    this.searchFilterForm
      .get('searchCommune')
      ?.valueChanges.pipe(takeUntil(this.subscribe))
      .subscribe((input) => {
        if (!input) {
          this.getCommunes();
        }

        this.communes$ = this.communes$?.pipe(
          map((communes: any[]) => {
            if (!communes?.length) [];

            return communes?.filter((commune) =>
              commune?.nom?.toLowerCase().includes(input)
            );
          })
        );
      });
  }

  onCleanSearchCommune(): void {
    this.searchFilterForm.patchValue({ searchCommune: '' });
    this.selectedCommune$.next('');
    this.geoTerritoireForm.patchValue({ commune: '' });
  }

  onSelectCommune(commune: any): void {
    const { code, nom } = commune;
    this.geoTerritoireForm.patchValue({
      commune: {
        code,
        nom,
      },
    });

    const display: string = `${code} - ${nom.substring(
      0,
      this.selectedDisplayLimit
    )}`;
    this.searchFilterForm.patchValue({
      searchCommune: display,
    });
    this.selectedCommune$.next(display);
  }

  // DEPARTEMENTS
  getLoadingDepartements(): void {
    this.isDepartementsLoading$ = this.GeoTerritoireStore.select(
      fromGeoTerritoireSelector.loadingDepartements
    );
  }
  getDepartements(): void {
    this.departements$ = this.GeoTerritoireStore.select(
      fromGeoTerritoireSelector.departements
    );
  }

  loadDepartements(code: string): void {
    this.GeoTerritoireStore.dispatch(
      fromGeoTerritoireAction.loadGeoTerritoireDepartements({ code })
    );
  }

  onChangeDepartement(event: string | any, type: string): void {
    if (type === 'select') {
      this.onSelectCommune(event);
    }

    this.searchFilterForm.patchValue({ searchDepartement: event });
  }

  onSearchDepartements(): void {
    this.searchFilterForm
      .get('searchDepartement')
      ?.valueChanges.pipe(takeUntil(this.subscribe))
      .subscribe((input) => {
        if (!input) {
          this.getDepartements();
        }

        this.departements$ = this.departements$?.pipe(
          map((departements: any[]) => {
            if (!departements?.length) [];

            return departements?.filter((departement) =>
              departement?.nom?.toLowerCase().includes(input)
            );
          })
        );
      });
  }

  onCleanSearchDepartement(): void {
    this.searchFilterForm.patchValue({ searchDepartement: '' });
    this.selectedDepartement$.next('');
    this.searchFilterForm.patchValue({ searchCommune: '' });
    this.selectedCommune$.next('');
    this.communes$ = of(null);
    this.geoTerritoireForm.patchValue({ departement: '' });
  }
  onSelectDepartement(departement: any): void {
    const { code, nom } = departement;
    this.geoTerritoireForm.patchValue({
      departement: { code, nom },
    });

    const display: string = `${code} - ${nom.substring(
      0,
      this.selectedDisplayLimit
    )}`;
    this.selectedDepartement$.next(display);
    this.searchFilterForm.patchValue({
      searchDepartement: display,
    });
    this.selectedCommune$.next('');
    this.getCommunes();
    this.loadCommunes(code);
  }

  onChanges(): void {
    this.geoTerritoireForm.valueChanges
      .pipe(takeUntil(this.subscribe))
      .subscribe((formValues) => this.geoterritoire.emit(formValues));
  }
}
