import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CandidatureState } from '../../store/candidature.reducer';
import * as fromCandidatureAction from '../../store/candidature.actions';
import * as fromCandidatureSelector from '../../store/candidature.selectors';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';
import {
  NzNotificationPlacement,
  NzNotificationService,
} from 'ng-zorro-antd/notification';

const NOTIFICATION_PLACEMENT: NzNotificationPlacement = 'bottomRight';

@Component({
  selector: 'app-candidature-export',
  templateUrl: './candidature-export.component.html',
  styleUrls: ['./candidature-export.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CandidatureExportComponent implements OnInit, OnChanges {
  loading$: Observable<boolean>;
  error$: Observable<any>;
  success$: Observable<string>;
  user$: Observable<string>;

  @Input() fiche: any;
  @Input() candidats: any[];
  @Input() types: string[] = ['PDF', 'EXCEL', 'WORD'];
  isAnonyme = new FormControl(false);
  type = new FormControl('PDF');

  exportForm = new FormGroup({
    isAnonyme: this.isAnonyme,
    type: this.type,
  });

  @Output() cancel = new EventEmitter();
  @Output() export = new EventEmitter();

  constructor(
    private candidatureStore: Store<CandidatureState>,
    private userStore: Store<UserState>,
    private notification: NzNotificationService
  ) {
    this.getUser();
  }

  ngOnInit(): void {
    this.getLoading();
    this.getSuccess();
    this.getError();
  }
  ngOnChanges(changes: SimpleChanges): void {}
  getUser(): void {
    this.user$ = this.userStore.select(fromUserSelector.user);
  }

  getLoading(): void {
    this.loading$ = this.candidatureStore.select(
      fromCandidatureSelector.loading
    );
  }
  getSuccess(): void {
    this.success$ = this.candidatureStore.select(
      fromCandidatureSelector.success
    );
  }

  getError(): void {
    this.error$ = this.candidatureStore.select(fromCandidatureSelector.error);
  }

  onCancel(): void {
    this.cancel.emit(true);
  }

  selectCompare(item1: any, item2: any): any {
    return item1 === item2;
  }

  onValidation(candidatures: any[], fiche: any, user: any): void {
    if (!candidatures?.length) {
      this.notification.info(
        'Export',
        "Vous n'avez aucune candidature. Veuillez penser à lancer une diffusion vers des prescripteurs.",
        {
          nzPlacement: NOTIFICATION_PLACEMENT,
          nzDuration: 6000,
          nzKey: 'EXPORT CANDIDAT ALERT',
        }
      );

      return;
    }
    if (!fiche || !user) {
      this.notification.info('Export', 'La fiche de poste est inexistant', {
        nzPlacement: NOTIFICATION_PLACEMENT,
        nzDuration: 6000,
        nzKey: 'EXPORT CANDIDAT ALERT',
      });

      return;
    }

    if (!user) {
      this.notification.info(
        'Export',
        "Vous n'avez pas été authentifié. Veuillez vous déconnecter puis vous connecter à votre compte utilisateur.",
        {
          nzPlacement: NOTIFICATION_PLACEMENT,
          nzDuration: 6000,
          nzKey: 'EXPORT CANDIDAT ALERT',
        }
      );

      return;
    }

    const value: { type: string; isAnonyme: boolean } | any =
      this.exportForm.value;
    this.export.emit(value);

    const { type, isAnonyme } = value;

    switch (type) {
      case 'PDF':
        this.candidatureStore.dispatch(
          fromCandidatureAction.exportCandidaturesPdf({
            candidatures,
            fiche,
            isAnonyme,
            user,
          })
        );

        break;
      case 'EXCEL':
        this.candidatureStore.dispatch(
          fromCandidatureAction.exportCandidaturesExcel({
            candidatures,
            fiche,
            isAnonyme,
            user,
          })
        );

        break;
      case 'WORD':
        this.candidatureStore.dispatch(
          fromCandidatureAction.exportCandidaturesWord({
            candidatures,
            fiche,
            isAnonyme,
            user,
          })
        );

        break;

      default:
        break;
    }
  }
}
