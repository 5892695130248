import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-diffusion-item',
  templateUrl: './diffusion-item.component.html',
  styleUrls: ['./diffusion-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiffusionItemComponent implements OnInit {
  @Input() diffusion$: Observable<any> = of(null);
  @Output() cancel = new EventEmitter();
  @Output() update = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onUpdate(): void {}
  onCancel(): void {}
}
