import { Adresse } from 'src/app/models/adresse.model';
import { OrganisationEssential } from '../../organisation/store/organisation.model';

export interface Participant {
  access?: any;
  stats: any;
  auteur: any;
  searchKey?: any;
  civilite: string;
  lastName: string;
  firstName: string;
  fonction?: string;
  dateDeNaissance?: string;
  couvertureMaladie?: string;
  group?: boolean;
  groupNumber?: number;
  communication: string; //telephone ou email ou whapp
  age?: string;
  sexe?: string;
  nationality?: any;
  formation?: string | any;
  qualification?: any;
  dateNaissance?: Date | any;
  tranche: string;
  motifAccompagnement: string; //
  country?: any;
  droitAimage?: boolean;
  consentementDonneesPersonnelles?: boolean;
  dateEntree?: Date | any;
  dureeEntre?: number;
  dureeParcours?: any;
  numbCarteSejour?: string;
  typeCarteSejour?: any;
  numCaiCir?: string;
  maitriseFrancais?: any;
  maitriseFrancaisEcrit?: any;
  permis?: any;
  reprisDeJustice?: boolean;
  auths: string[] | any;
  parcours: any;
  codePays: string;
  dateStart: Date | any;
  dateEnd: Date | any;
  dateSelect: Date | any;
  dateUpdate: Date | any;
  datetIntegration: Date | any;
  droitDeTravail?: boolean;
  disponibility?: any[];
  lieuDeNaissance?: any;
  sortiProgrammeMotif?: any;
  integration: boolean;
  objectifPro: any;
  projetsPro?: ProjetProfessionnel[];
  commentVousNousAvezConu: string;
  aideFamiliale?: string;
  minimaSociaux: any;
  userIntegration?: any;
  userUpdate?: any;
  userSelected?: any;
  prescripteurs: any;
  formulaireId: string;
  inscription: boolean;
  organisationId: string;
  rgpdConscentement: boolean;
  organisation: OrganisationEssential;
  departements: any[];
  operations: any[];
  operation: any;
  operationsMap?: any;
  modules?: any[];
  modulesIds?: any[];
  modulesMapIds?: any;
  actions?: any[];
  actionsIds?: any[];
  actionsMapIds?: any;
  contacts?: any[];
  contactsIds?: any[];
  contactsMapIds?: any;
  entites?: any[];
  entitesIds?: any[];
  entitesMapIds?: any;
  service?: any;
  servicesMap?: any;
  informations: {
    adresse: {
      fields?: any;
      adresse?: any;
      qpv: string | any;
      qpvLibelle?: string;
      complement: string;
      isQPV?: boolean;
      isQVA?: boolean;
      isZRR?: boolean;
    };
    coordonnees: {
      mobile: string;
      fixe: string;
      email: string;
      phoneNumber: string;
    };
    poleEmploi: any;
    missionLocal: {
      inscription?: boolean;
      id: string;
      denomination: string;
      adresse: any;
      telephone?: any;
      referent: {
        firstName: string;
        lastName: string;
        email: string;
        telephone: string;
      };
    };
  };
  relations?: any;
  sortiInscription: boolean;
  sortiInscriptionMotif: any;
  sortiProgramme: boolean;
  sortiProgrammeType: any;
  sortiProgrammeQualitatif?: string;
  sortiByProgramme?: any;
  observation?: string;
  dateSortie: Date | any;
  criteres: any;
  criteresMap?: ParticipantCriteres;
  presentation: string;
  entretiens: any[];
  situationPrecedente: any;
  situationPersonnelle?: SituationPersonnelle | any;
  rqth?: string | boolean;
  groups: any[];
  suiteDeparcours: boolean;
  contrats: any;
  suggestion: any;
  documents: any[];
  referents: any;
  referentsId?: any;
  referentsMapIds?: any;
  sourcing?: string | any;
  duration: number;
  documentsValidation?: any;
  priseEnCharge?: any;
  metadata?: ParticipantMetadata | any;
  status?: string;
  userSharing?: any;
  dateSharing?: any;
  impact3mois?: any;
  impact6mois?: any;
  impact12mois?: any;
  accompagnementDuration?: number;
}

export interface ParticipantId extends Participant {
  id: string;
}

export interface ParticipantSmall {
  access?: any;
  civilite: string;
  lastName?: string;
  firstName?: string;
  displayName?: string;
  referents: any;
  referentsId?: any;
  referentsMapIds?: any;
  integration: boolean;
  group?: boolean;
  groupNumber?: number;
  sortiInscription: boolean;
  sortiProgramme: boolean;
  suiteDeparcours?: boolean;
  inscription: boolean;
  dateStart: Date | any;
  droitAimage?: boolean;
  consentementDonneesPersonnelles?: boolean;

  dateEnd: Date | any;
  datetIntegration: Date | any;
  couvertureMaladie?: string;
  dureeEntre?: number;
  dureeParcours?: any;
  dateInscriptionPoleEmploi?: Date | any;
  poleEmploi?: string;
  referentPoleEmploi?: any;
  missionLocale?: any;
  referentMissionLocale?: any;
  relations?: any;
  informations: {
    fields?: any;
    adresse: Adresse;
    coordonnees: {
      mobile: string;
      fixe: string;
      email: string;
      phoneNumber: string;
    };
  };
  organisation: OrganisationEssential;

  metadata?: ParticipantMetadata | any;
  searchKey?: any;

  criteres: any;
  criteresMap?: ParticipantCriteres;
  rqth?: string | boolean;
  age?: string;
  sexe?: string;
  communication: string;
  nationality?: any;
  qualification?: any;
  tranche: string;
  motifAccompagnement: string;
  dateEntree?: Date | any;
  dateNaissance?: Date | any;
  typeCarteSejour?: any;
  maitriseFrancais?: any;
  maitriseFrancaisEcrit?: any;
  permis?: any;
  reprisDeJustice?: boolean;
  droitDeTravail?: boolean;
  disponibility?: any[];
  sortiProgrammeMotif?: any;
  sortiInscriptionMotif: any;
  sortiProgrammeType: any;
  minimaSociaux?: string;
  numbCarteSejour?: string | any;
  numCaiCir?: string | any;
  prescripteurs: any;
  situationPersonnelle?: SituationPersonnelle;
  priseEnCharge?: any;
  status?: string;
  userSharing?: any;
  dateSharing?: any;
  impact3mois?: any;
  impact6mois?: any;
  impact12mois?: any;
  operations: any[];
  operationsMap?: any;
  modules?: any[];
  modulesIds?: any[];
  modulesMapIds?: any;
  actions?: any[];
  actionsIds?: any[];
  actionsMapIds?: any;
  contacts?: any[];
  contactsIds?: any[];
  contactsMapIds?: any;
  entites?: any[];
  entitesIds?: any[];
  entitesMapIds?: any;
  service?: any;
  servicesMap?: any;

  accompagnementDuration?: number;
}

export interface ParticipantSmallId extends ParticipantSmall {
  id: string;
}

export interface ParticipantCriteres {
  jeunesDeMoinsDe26ans?: boolean;
  personnePriseEnChargeIAE?: boolean;
  PersonneEmployeGEIQ?: boolean;
  beneficiaireProtectionInterRefugie?: boolean;
  beneficiaireProtectionInterSubsidiaire?: boolean;
  parentIsole?: boolean;
  ase?: boolean;
  autre?: boolean;
  autrePrecision?: string;
  primoArrivant?: boolean;
  jeuneDecrocheur?: boolean;
  autoentrepreneurQPV?: boolean;
  fse?: boolean;
  neet?: boolean;
}

export interface ParticipantStatut {
  codeStatutIndividu: string;
  libelleStatutIndividu: string;
}

export interface ParticipantExperienceProfessionnelles {
  date: ParticipantExperienceProfessionnellesDates;
  description: string;
  duree: number;
  enPoste: boolean;
  entreprise: string;
  etranger: boolean;
  intitule: string;
  lieu: string;
}

export interface ParticipantExperienceProfessionnellesDates {
  debut: string;
  fin: string;
}

export interface ParticipantContrat {
  dateDebut: string;
  datefin: string;
  intitulePoste: string;
  natureContrat: string;
  niveauQualification: string;
  dureeContrat: string;
  quantiteTravail: string;
  entreprise: ParticipantContratEntreprise;
}

export enum ParticipantContratEntrepriseSecteur {
  Privé,
  Publique,
}

export interface ParticipantContratEntreprise {
  codeApe: string;
  nom: string;
  regime: string;
  secteur: ParticipantContratEntrepriseSecteur;
  siret: string;
}

export interface ParticipantFormation {
  anneeFin: number;
  description: string;
  diplomeObtenu: boolean;
  domaine: {
    code: string;
    libelle: string;
  };
  etranger: boolean;
  intitule: string;
  lieu: string;
  niveau: {
    code: string;
    libelle: string;
  };
}

export interface ParticipantPermis {
  code: string;
  libelle: string;
}

export interface ParticipantCoordonnees {
  adresse1: string;
  adresse2: string;
  adresse3: string;
  adresse4: string;
  codePostal: string;
  codeINSEE: string;
  libelleCommune: string;
  codePays: string;
  libellePays: string;
}

export interface ParticipantIndemnisation {
  beneficiairePrestationSolidarite: boolean;
  beneficiaireAssuranceChomage: boolean;
}

export interface ParticipantCompetenceProfessionnelle {
  code: string;
  libelle: string;
  niveau: {
    code: string;
    libelle: string;
  };
  type: string;
}

export interface ParticipantLangue {
  code: string;
  libelle: string;
  niveau: {
    code: string;
    libelle: string;
  };
}

export interface ParticipantCentreInteret {
  complement: string;
  intitule: string;
}

export interface ProjetProfessionnel {
  grandDomaine: any;
  domaineProfessionnel: any;
}

export interface Hebergement {
  chezMoi: boolean;
  proprietaire?: boolean;
  locataire?: boolean;
  chezParent?: boolean;
  chezTier: boolean;
  hotel: boolean;
  centreHebergement: boolean;
  lieuPublic: boolean;
  autreLieu: boolean;
  autre: boolean;
  nesaitpas: boolean;
}

export interface ProjetProfessionnelId extends ProjetProfessionnel {
  id: string;
}

export interface ParticipantMetadata {
  letterLastName: any;
  letterFirstName: any;
  arrayLastName: any;
  arrayFirstName: any;
  group?: boolean;
  groupNumber?: number;

  criteres: any;
  criteresMap?: any;

  operations: any;
  startYear: any;
  startMonth: any;
  endYear: any;
  endMonth: any;
  integrationYear: any;
  integrationMonth: any;
  postcode: any;
  city: any;
  arrEntite: any;
  arrContact: any;
  arrDepartement: any;
  languageWeb?: any;
  projetsPro?: any[];
}

export interface SituationPersonnelle {
  familiale?: string | any;
  mobilite?: string | any;
  handicap?: string | any;
  enfantsAcharges?: boolean;
  enfantScolarise?: boolean;
  enfantMoyenDeGarde?: boolean;
  permis?: any;
  sante?: string | any;
  numerique?: any;
  bureautique_level?: any;
  bureautique_tools?: any;
  mobile?: any;
  geo_mobility?: any;
  internet?: boolean;
  logement?: string;
  ordinateur?: boolean;
  numeriqueObservation?: string;
  languageWeb?: any;
  justice?: any;
  caseJudiciaire?: boolean;
  hebergement?: any | Hebergement;
  hebergementPrecision?: string;
}

export interface Parcours {
  auteur?: any;
  userUpdate?: any;
  dateStart: Date | any;
  dateUpdate: Date | any;
  referent?: any;
  events: ParcoursEvent[] | any[];
  organisation: OrganisationEssential;
}

export interface ParcoursId extends Parcours {
  id: string;
}

export interface ParcoursEvent {
  type: string;
  dateStart: Date | any;
  dateUpdate: Date | any;
  operation?: {
    id: string;
    denomination: string;
  };
  module?: any;
  actions?: any;
  contact?: any;
  entite?: any;

  action: string;
  referent?: any;
  commentaire: string;
}

export interface ParcoursEventId extends ParcoursEvent {
  id: string;
}

export interface ParticipantFavori {
  id: string;
  displayName: string;
  dateUpdate: any | Date;
  type: string;
}

export interface ParticipantImpact {
  date: any | Date;
  type: any | string;
  info: string;
}
