import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card-identity',
  templateUrl: './card-identity.component.html',
  styleUrls: ['./card-identity.component.scss'],
})
export class CardIdentityComponent implements OnInit {
  @Input() title: string = '';
  constructor() {}

  ngOnInit(): void {}
}
