import { Pipe, PipeTransform } from '@angular/core';
import { eachDayOfInterval, fromUnixTime, format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { last, chunk } from 'lodash';

@Pipe({
  name: 'dateMiddle',
})
export class DateMiddlePipe implements PipeTransform {
  transform(values: any[], type?: string): string {
    if (!values?.length) return '';

    if (values?.length !== 2) return '';
    const dateType: string = type ? type : 'dd/MM/yyyy HH:mm';
    const start: Date = fromUnixTime(values[0]['seconds']);
    const end: Date = fromUnixTime(values[1]['seconds']);
    const daysArray: Date[] = eachDayOfInterval({ start, end });
    const size: number = Math.round(daysArray.length);
    const daysArrayParts: Date[][] = chunk(daysArray, size);
    const firstPart: Date[] = daysArrayParts[0];
    const lastDayOfFistPart: Date = last(firstPart) as Date;

    return format(lastDayOfFistPart, dateType, { locale: fr });
  }
}
