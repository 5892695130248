<ng-container *ngIf="user$ | async as user">
  <app-layout-list
    *ngIf="
      (data$ | async).departements.length || (departements$ | async) | size;
      else noResults
    "
    [new]="(user | isDev) || (user | isAdmin) ? new : null"
    [card]="card"
    [source]="(data$ | async)?.departements"
  ></app-layout-list>

  <ng-template #new>
    <app-card-new
      [title]="title"
      [type]="'service'"
      [height]="'250px'"
      [description]="''"
      [definition]="definition"
      (add)="onNewDepartementForm(user)"
    ></app-card-new>
  </ng-template>

  <ng-template #card let-item let-position="idx">
    <app-departement-card [departement]="item"></app-departement-card>
  </ng-template>

  <ng-template #noResults>
    <app-no-result
      [icon]="'add_circle_outline'"
      [addtitle]="'Ajouter un service'"
      [notitle]="'Aucun service'"
      [description]="description"
      (new)="onNewDepartementForm(user)"
    ></app-no-result>
  </ng-template>

  <ng-template #departementFormTitle>
    <app-header-title
      [title]="'Service'"
      [icon]="'group_work'"
      [subtitle]="true"
    ></app-header-title>
  </ng-template>
  <ng-template #departementDropdownAction>
    <app-button-dropdown
      [deleteAllType]="deleteAllType"
      [isDeleteAll]="true"
      (select)="onDropdownAction($event)"
    ></app-button-dropdown>
  </ng-template>
</ng-container>
