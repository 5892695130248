import { Injectable } from '@angular/core';
import { Observable, of, from } from 'rxjs';
import { map, tap, mergeMap, switchMap, catchError } from 'rxjs/operators';
import { Apollo, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'any',
})
export class AidsEligibleService {
  GET_FILTERS_PARTICIPANTS = gql`
    query AidesEligiblesFilters {
      aidesEligiblesFilters {
        diploma {
          value
          label
        }
        category {
          value
          label
        }
        inscription_period {
          value
          label
        }
        allocation_type {
          label
          value
        }
      }
    }
  `;

  GET_AIDS_SUGGESTIONS = gql`
    query AidesEligibles(
      $age: Int
      $inscriptionPeriod: String
      $category: String
      $diploma: String
      $allocationType: String
      $monthlyAllocationValue: Int
      $locationCitycode: String
      $filters: String
      $disabled: Boolean
      $spectacle: Boolean
    ) {
      aidesEligibles(
        age: $age
        inscription_period: $inscriptionPeriod
        category: $category
        diploma: $diploma
        allocation_type: $allocationType
        monthly_allocation_value: $monthlyAllocationValue
        location_citycode: $locationCitycode
        filters: $filters
        disabled: $disabled
        spectacle: $spectacle
      ) {
        name
        slug
        short_description
        filters {
          slug
        }
        contract_type
        details {
          aid {
            name
            what
            slug
            short_description
            how_much
            additionnal_conditions
            how_and_when
            limitations
          }
        }
      }
    }
  `;

  constructor(private apollo: Apollo) {}

  getFiltersParticipants(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_FILTERS_PARTICIPANTS,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  getAidsSuggestions(filterslist: any): Observable<any> {
    const {
      age,
      inscription_period,
      category,
      diploma,
      allocation_type,
      monthly_allocation_value,
      location_citycode,
      filters,
      disabled,
      spectacle,
    } = filterslist;

    return this.apollo
      .watchQuery<any>({
        query: this.GET_AIDS_SUGGESTIONS,
        variables: {
          age: age,
          inscription_period: inscription_period,
          category: category,
          diploma: diploma,
          allocation_type: allocation_type,
          monthly_allocation_value: monthly_allocation_value,
          location_citycode: location_citycode,
          filters: filters,
          disabled: disabled,
          spectacle: spectacle,
        },
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }
}
