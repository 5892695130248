import { NzNotificationService } from 'ng-zorro-antd/notification';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AuthState } from '../../store/auth.reducer';
import * as fromAuthAction from '../../store/auth.actions';
import * as fromAuthSelector from '../../store/auth.selectors';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

import { Store } from '@ngrx/store';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss'],
})
export class ForgetPasswordComponent implements OnInit {
  titleCard: string = 'Mot de passe oublié';
  retour: string = "revenir à l'authentification";
  emailForm: UntypedFormGroup = this.fb.group({});
  emailInput: UntypedFormControl = new UntypedFormControl('');
  loading$: Observable<boolean> = of(false);
  isScreenSmall$: Observable<boolean> = of(false);
  icon: string = 'password';
  regexEmail: string = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';

  constructor(
    private fb: UntypedFormBuilder,
    private authStore: Store<AuthState>,
    private breakpointObserver: BreakpointObserver,
    private notification: NzNotificationService
  ) {
    this.breackPointer();
  }

  ngOnInit(): void {
    this.formInit();
    this.formCreate();
    this.getLoading();
  }

  breackPointer(): void {
    this.isScreenSmall$ = this.breakpointObserver
      .observe(['(max-width: 968px)'])
      .pipe(map(({ matches }) => matches));
  }

  formInit(): void {
    this.emailInput = new UntypedFormControl('', [
      Validators.required,
      Validators.email,
      Validators.pattern(this.regexEmail),
    ]);
  }

  get EmailValidation() {
    return this.emailForm.get('email');
  }

  getLoading(): void {
    this.loading$ = this.authStore.select(fromAuthSelector.loading);
  }

  formCreate(): void {
    this.emailForm = this.fb.group({
      email: this.emailInput,
    });
  }

  isValidateEmail = (email: string) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  onSubmit(event: any): any {
    event.preventDefault();
    event.stopPropagation();

    if (!this.emailForm.valid) {
      this.notification.warning('Erreur', 'Aucun e-mail renseigné !', {
        nzDuration: 6000,
        nzAnimate: true,
        nzPlacement: 'topRight',
        nzKey: 'FORGET_PASSWORD_RESET',
      });
      return null;
    }

    const data: any = this.emailForm.value;
    const email = data.email.trim();
    if (!email) {
      this.notification.warning('Erreur', 'Aucun e-mail renseigné !', {
        nzDuration: 6000,
        nzAnimate: true,
        nzPlacement: 'topRight',
        nzKey: 'FORGET_PASSWORD_RESET',
      });
      return;
    }

    if (!this.isValidateEmail(email)) {
      this.notification.warning('Erreur', 'Adresse e-mail erronée !', {
        nzDuration: 6000,
        nzAnimate: true,
        nzPlacement: 'topRight',
        nzKey: 'FORGET_PASSWORD_RESET',
      });
      return;
    }

    this.authStore.dispatch(fromAuthAction.resetPassword({ email }));
  }

  onBackToLogin(): void {
    this.authStore.dispatch(fromAuthAction.backToLogin());
  }
}
