import { SuiviItemComponent } from '../suivi-item/suivi-item.component';
import { ItemEventComponent } from '../item-event/item-event.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { takeUntil } from 'rxjs/operators';
import { format } from 'date-fns';
import { ItemEvent, ItemEventId } from '../item-event/itemEvent.model';
import { BehaviorSubject, Subject, Observable, of } from 'rxjs';
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
  TemplateRef,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import fromUnixTime from 'date-fns/fromUnixTime';
import { fr } from 'date-fns/locale';
import { sortBy, last, first, uniqWith, isEqual } from 'lodash';
import { NzTableComponent } from 'ng-zorro-antd/table';

@Component({
  selector: 'app-suivis',
  templateUrl: './suivis.component.html',
  styleUrls: ['./suivis.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuivisComponent implements OnInit, OnChanges, OnDestroy {
  @Input() newCardTitle: string = 'Nouveau suivi';
  @Input() newCardDescription: string = "Ajouter un suivi à l'entité";
  @Input() newCardDefinition: string =
    'Un suivi est une note, une synthèse, un diagnostic suite un échange.';

  @Input() source$: Observable<ItemEventId[] | any> = of([]);
  @Input() messageAlert: string = 'Voulez-vous supprimer ce suivi ?';

  @Output() add = new EventEmitter<ItemEventId>(false);
  @Output() update = new EventEmitter<ItemEventId>(false);
  @Output() delete = new EventEmitter<any>(false);
  @Output() select = new EventEmitter<any>(false);

  subscribe = new Subject();
  modalWidth: number = 950;

  newDocument = false;
  newEvent = false;
  itemDisplay = false;
  updateAcitivity = false;

  eventTextSelect: any = null;
  eventTextBesoin: any = null;
  eventTextPreconisation: any = null;
  eventTextRealisation: any = null;
  eventUpdate = new BehaviorSubject<any>(null);
  interlocteur = new BehaviorSubject<any>(false);

  @ViewChild('virtualTable', { static: false })
  nzTableComponent?: NzTableComponent<ItemEventId>;

  @ViewChild('suiviTitleForm', { static: false }) suiviTitleForm:
    | TemplateRef<any>
    | any;

  scrollToIndex(index: number): void {
    this.nzTableComponent?.cdkVirtualScrollViewport?.scrollToIndex(index);
  }

  trackByIndex(_: number, data: ItemEventId): string {
    return data.id;
  }
  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  onSelect(suivi: ItemEventId): void {
    this.itemDisplay = true;
    this.newEvent = false;
    this.eventUpdate.next(suivi);
    this.newCardTitle = `Suivi`;

    const modal = this.modal.create({
      nzContent: SuiviItemComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.suiviTitleForm,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
    });
    const instance = modal.getContentComponent();
    instance.suivi = suivi;
    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
        this.onEventCancel();
      }
    });
    instance.update.subscribe((suivi: ItemEventId): void => {
      if (!suivi) {
        return;
      } else {
        this.onUpdateEvent(suivi);
        modal.close();
        this.onEventCancel();
      }
    });
    modal.afterClose.subscribe(() => this.onEventCancel());
  }

  onDelete(event: any): void {
    this.delete.emit(event);
  }

  onAddEvent(suivi: any): void {
    if (suivi) {
      this.add.emit(suivi);
      this.newEvent = false;
      this.eventUpdate.next(null);
    }
  }
  updateEvent(suivi: any): void {
    if (suivi) {
      this.update.emit(suivi);
      this.newEvent = false;
      this.eventUpdate.next(null);
    }
  }

  onUpdateEvenement(event: any): void {
    this.onNewEvent();
  }

  onUpdateEvenementTable(event: any): void {
    this.itemDisplay = false;
    this.newEvent = true;
  }

  onEventCancel(): void {
    this.newEvent = false;
    this.eventUpdate.next(null);
  }

  onCloseEvenement(event: any): void {
    this.itemDisplay = false;
    this.newEvent = false;
  }

  onNewEvent(): void {
    const modal = this.modal.create({
      nzContent: ItemEventComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.suiviTitleForm,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });
    const instance = modal.getContentComponent();
    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
        this.onEventCancel();
      }
    });
    instance.add.subscribe((suivi: ItemEvent): void => {
      if (!suivi) {
        return;
      } else {
        this.onAddEvent(suivi);
        modal.close();
        this.onEventCancel();
      }
    });
    instance.update.subscribe((suivi: ItemEvent): void => {
      if (!suivi) {
        return;
      } else {
        this.updateEvent(suivi);
        modal.close();
        this.onEventCancel();
      }
    });
    instance.interlocteur = this.interlocteur;
    instance.item = this.eventUpdate;
    modal.afterClose.subscribe(() => this.onEventCancel());
  }

  onUpdateEvent(suivi: ItemEventId): void {
    if (!suivi) return;
    const modal = this.modal.create({
      nzContent: ItemEventComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.suiviTitleForm,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });
    const instance = modal.getContentComponent();
    instance.item.next(suivi);
    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
        this.onEventCancel();
      }
    });
    instance.add.subscribe((suivi: ItemEvent): void => {
      if (!suivi) {
        return;
      } else {
        this.onAddEvent(suivi);
        modal.close();
        this.onEventCancel();
      }
    });
    instance.update.subscribe((suivi: ItemEvent): void => {
      if (!suivi) {
        return;
      } else {
        this.updateEvent(suivi);
        modal.close();
        this.onEventCancel();
      }
    });
    instance.interlocteur = this.interlocteur;
    modal.afterClose.subscribe(() => this.onEventCancel());
  }

  cancelDelete() {}
}
