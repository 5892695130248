import { TodosComponent } from './../../../todos/components/todos/todos.component';
import { FavoriesComponent } from './../../../favories/components/favories/favories.component';
import { takeUntil } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { MessagesScreenComponent } from './../../../messages/components/messages-screen/messages-screen.component';
import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  Input,
  OnDestroy,
} from '@angular/core';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';

import { Store, select } from '@ngrx/store';
import { messageState } from 'src/app/components/messages/store/message.reducer';
import * as fromMessageSelector from 'src/app/components/messages/store/message.selectors';
import * as fromMessageAction from 'src/app/components/messages/store/message.actions';

import { NavigationState } from '../../store/navigation.reducer';
import * as fromNavigationSelector from '../../store/navigation.selectors';
import * as fromNavigationAction from '../../store/navigation.actions';
import { TodoState } from 'src/app/components/todos/store/todo.reducer';
import * as fromTodoAction from 'src/app/components/todos/store/todo.actions';
import { CalculatriceComponent } from 'src/app/contents/components/calculatrice/calculatrice.component';

@Component({
  selector: 'app-navigation-icons',
  templateUrl: './navigation-icons.component.html',
  styleUrls: ['./navigation-icons.component.scss'],
})
export class NavigationIconsComponent implements OnInit, OnDestroy {
  tooltipDelay: number = 0.5;
  messages$: Observable<number | any> = of(10);
  todosSize: number = 0;
  badgeOffset: [number, number] = [10, 10];
  drawerWidth: number = 950;
  modalWidth: number = 1050;
  statistiques$: Observable<any> = of(null);
  subscribe = new Subject();

  @Input() theme: string = '';
  @Input() user: any;

  constructor(
    private userStore: Store<UserState>,
    private messageStore: Store<messageState>,
    private todoStore: Store<TodoState>,
    private drawerService: NzDrawerService,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private navigationStore: Store<NavigationState>
  ) {}

  ngOnInit(): void {
    this.getStatistiques();
    this.getMessages();
    this.onOpenMessageDrawer();
  }

  getStatistiques(): void {
    this.statistiques$ = this.userStore.select(fromUserSelector.statistiques);
  }

  getMessages(): void {
    this.messages$ = this.messageStore.select(
      fromMessageSelector.selectMessages
    );
  }

  onOpenMessageDrawer(): void {
    this.navigationStore
      .select(fromNavigationSelector.messageDrawer)
      .pipe(takeUntil(this.subscribe))
      .subscribe((event): any => {
        if (!event) {
          return null;
        } else {
          this.onMessage();
        }
      });
  }

  onMessage(): void {
    const drawerRef = this.drawerService.create({
      nzContent: MessagesScreenComponent,
      nzTitle: 'Messages',
      nzContentParams: {},
      nzWidth: this.drawerWidth,
    });
    drawerRef.afterClose.subscribe(() => {
      this.navigationStore.dispatch(
        fromNavigationAction.openMessageDrawer({ event: false })
      );
      this.messageStore.dispatch(fromMessageAction.loadMessageClear());
    });
  }

  onTodos(): void {
    const drawerRef = this.drawerService.create({
      nzTitle: 'Tâches',
      nzContent: TodosComponent,
      nzContentParams: {},
      nzWidth: this.drawerWidth,
    });

    // const modal = this.modal.create({
    //   nzContent: TodosComponent,
    //   nzViewContainerRef: this.viewContainerRef,
    //   nzWidth: this.modalWidth,
    //   nzFooter: [],
    //   nzComponentParams: {
    //     modalMode: true,
    //   },
    //   nzCloseIcon: '',
    //   nzClosable: false,
    //   nzStyle: { top: '20px' },
    // });

    // const instance = modal.getContentComponent();
    // modal.afterClose.subscribe((event) => {
    //   instance.modalMode = false;
    //   this.todoStore.dispatch(fromTodoAction.clearTodo());
    // });

    // instance.closeForm.subscribe((event): any => {
    //   if (!event) {
    //     return null;
    //   } else {
    //     modal.close();
    //     this.todoStore.dispatch(fromTodoAction.clearTodo());
    //     return null;
    //   }
    // });
  }

  onFavoris(): void {
    const drawerRef = this.drawerService.create({
      nzTitle: 'Favoris',
      nzContent: FavoriesComponent,
      nzContentParams: {},
      nzWidth: this.drawerWidth,
    });
  }

  onCalculator(): void {
    const drawerRef = this.drawerService.create({
      nzTitle: 'Évaluation clause sociale',
      nzContent: CalculatriceComponent,
      nzContentParams: {},
      nzWidth: this.drawerWidth,
    });
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }
}
