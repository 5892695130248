<ul nz-list [nzDataSource]="items" nzSize="small" class="list">
  <li nz-list-item *ngFor="let item of items" nzNoFlex>
    <ul nz-list-item-actions>
      <nz-list-item-action>
        <button
          nz-button
          nzShape="circle"
          nzSize="small"
          nz-tooltip
          [nzTooltipMouseEnterDelay]="0.5"
          nzTooltipPlacement="left"
          nzTooltipTitle="Supprimer"
          nz-popconfirm
          nzPopconfirmTitle="Voulez-vous supprimer cette valeur ?"
          nzPopconfirmPlacement="bottom"
          (nzOnConfirm)="onDelete(item, items, utility, variable)"
          (nzOnCancel)="cancel()"
        >
          <i nz-icon nzType="delete"></i>
        </button>
      </nz-list-item-action>
    </ul>
    {{ item | titlecase }}
  </li>
</ul>
