import { takeUntil } from 'rxjs/operators';
import { OperationId } from './../../../operation/store/operation.model';
import { OperationState } from './../../../operation/store/operation.reducer';
import * as fromOperationSelector from './../../../operation/store/operation.selectors';

import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, Subject, BehaviorSubject } from 'rxjs';
import { ModuleSmallId } from '../../store/module.model';
import { ModuleState } from '../../store/module.reducer';
import * as fromModuleAction from '../../store/module.actions';
import * as fromModuleSelector from '../../store/module.selectors';

@Component({
  selector: 'app-module-select',
  template: ` <nz-select
    nzShowSearch
    nzBorderless
    nzServerSearch
    [nzPlaceHolder]="placeholder"
    [(ngModel)]="inputModel"
    [nzShowArrow]="false"
    [nzDropdownStyle]="{ height: '200px' }"
    (ngModelChange)="onSelect($event)"
  >
    <nz-option
      *ngFor="let module of modules$ | async"
      [nzLabel]="module.title"
      [nzValue]="module"
      nzCustomContent
    >
      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col nzSpan="4">
          <mat-icon class="matIcon">dynamic_feed</mat-icon>
        </nz-col>
        <nz-col nzSpan="16">
          <p nz-typography *ngIf="!module?.isFinished">
            {{ module?.title | uppercase }}
          </p>
          <del nz-typography *ngIf="module?.isFinished">{{
            module?.title | uppercase
          }}</del>
        </nz-col>
        <nz-col nzSpan="4">
          <nz-tag nzColor="success" *ngIf="!module?.isFinished"
            >En cours</nz-tag
          >
          <nz-tag nzColor="green" *ngIf="module?.isFinished">Terminé</nz-tag>
        </nz-col>
      </nz-row>
    </nz-option>
  </nz-select>`,
  styles: [
    `
      nz-select {
        width: 100% !important;
        border-radius: 12px;
        background-color: #f5f5f5;
      }

      mat-ion,
      h5 {
        color: #062949;
      }

      nz-tag {
        border: none;
        border-radius: 12px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModuleSelectComponent implements OnInit, OnDestroy {
  modules$: Observable<ModuleSmallId[] | any> = of(null);
  subscribe = new Subject();
  inputSearch$ = new BehaviorSubject<string>('');
  inputModel: string = '';

  @Input() placeholder: string = 'Ajouter un module';
  @Output() select = new EventEmitter<any>(false);

  constructor(private moduleStore: Store<ModuleState>) {}

  ngOnInit(): void {
    this.loadModules();
    this.getModules();
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  loadModules(): void {
    this.moduleStore.dispatch(fromModuleAction.queryModulesByOperation());
  }
  getModules(): void {
    this.modules$ = this.moduleStore.select(fromModuleSelector.modulesAll);
  }
  onSeach(event: string, operation: OperationId): void {
    if (!event) {
      this.moduleStore.dispatch(
        fromModuleAction.loadModules({ operationId: operation.id })
      );
      return;
    } else {
      const filter = {
        operationId: operation.id,
        event: event,
        type: 'title',
      };
      this.moduleStore.dispatch(fromModuleAction.queryModules({ filter }));
    }
  }

  onSelect(module: ModuleSmallId | any): void {
    if (!module || module.isFinished) return;
    this.select.emit(module);
  }
}
