import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-message-actions',
  templateUrl: './message-actions.component.html',
  styleUrls: ['./message-actions.component.scss'],
})
export class MessageActionsComponent implements OnInit {
  noDataTitle: string = 'Aucune action requise';
  constructor() {}

  ngOnInit(): void {}
}
