import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
('@angular/forms');
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-participant-orientations-form',
  templateUrl: './participant-orientations-form.component.html',
  styleUrls: ['./participant-orientations-form.component.scss'],
})
export class ParticipantOrientationsFormComponent implements OnInit {
  @Input() orientation = new BehaviorSubject<any>(null);
  @Output() add = new EventEmitter(false);
  @Output() update = new EventEmitter(false);
  @Output() cancel = new EventEmitter(false);

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {}

  onAdd(): void {}
  onUpdate(): void {}
  onDelete(): void {}
  onCancel(): void {}
}
