<ng-container *ngIf="operation$ | async as operation">
  <app-progress-loading *ngIf="loading$ | async"></app-progress-loading>
  <ng-container *ngIf="fiche$ | async as fiche">
    <app-fiches-de-poste-item
      [fiche]="fiche"
      [operation]="
        (fiche$ | async)?.mission?.operation
          ? (fiche$ | async)?.mission?.operation
          : {
              id: operation.id,
              denomination: operation.denomination,
              description: operation.description?.definition,
              type: operation?.nature
            }
      "
      [isOperation]="false"
      [users]="users$ | async"
      (back)="onBackToList(operation.id)"
      (update)="onUpdate($event, operation.id)"
      (delete)="onDelete($event, operation.id)"
      [candidatsTemplate]="operationficheCandidatsTpl"
      [candidats]="fiche?.candidats || []"
      (addCandidats)="onAddCandidats($event, operation.id)"
      (deleteCandidats)="onDeleteCandidats($event, operation.id)"
      (tabsChanges)="onTabsChanges($event, operation, fiche)"
      [diffusions]="fiche?.diffusions || []"
      (addDiffusion)="onAddDiffusion($event, operation.id, fiche.id)"
      (updateDiffusion)="
        onUpdateDiffusion($event, operation.id, fiche.id, fiche)
      "
      (deleteDiffusion)="onDeleteDiffusion($event, operation.id, fiche)"
      [notes]="fiche?.notes || []"
      (addNote)="onAddFicheNote($event, operation.id, fiche.id)"
      (updateNote)="onUpdateFicheNote($event, operation.id, fiche.id, fiche)"
      (deleteNote)="onDeleteFicheNote($event, operation.id, fiche)"
    ></app-fiches-de-poste-item>

    <ng-template #operationficheCandidatsTpl>
      <app-operation-fiche-de-poste-candidatures
        [fiche]="fiche"
        [candidats]="fiche?.candidats || []"
      ></app-operation-fiche-de-poste-candidatures>
    </ng-template>

    <ng-template #operationficheDiffusionsTpl> </ng-template>
    <ng-template #operationficheNotesTpl> </ng-template>
  </ng-container>
</ng-container>
