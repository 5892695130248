<form nz-form [formGroup]="eventForm">
  <nz-card nzSize="small" [nzTitle]="planningFormDefinitionTitle">
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="6">
        <nz-form-item nzRequired>
          <nz-form-label nzSpan="24" nzRequired>Début</nz-form-label>
          <nz-form-control>
            <nz-date-picker
              formControlName="dateStart"
              [nzFormat]="dateFormat"
              [nzShowTime]="timeFormat"
              nzBorderless
              nzPlaceHolder="Date"
            ></nz-date-picker>
          </nz-form-control>
        </nz-form-item>
      </nz-col>
      <nz-col nzSpan="6">
        <nz-form-item nzRequired>
          <nz-form-label nzSpan="24" nzRequired>Échéance</nz-form-label>
          <nz-form-control>
            <!-- <nz-date-picker
              formControlName="dateStart"
              [nzFormat]="dateFormat"
              [nzShowTime]="timeFormat"
              nzBorderless
              nzPlaceHolder="Date"
            ></nz-date-picker> -->
          </nz-form-control>
        </nz-form-item>
      </nz-col>
      <nz-col nzSpan="12">
        <nz-form-item nzRequired>
          <nz-form-label nzRequired nzSpan="24">Type</nz-form-label>
          <nz-form-control>
            <nz-select
              formControlName="type"
              nzPlaceHolder="Type d'événement"
              nzBorderless
              [nzLoading]="(types$ | async) ? false : true"
            >
              <nz-option
                *ngFor="let item of types$ | async"
                nzCustomContent
                [nzLabel]="item.label"
                [nzValue]="item"
              >
                <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
                  <nz-col nzSpan="4">
                    <nz-badge [nzColor]="item.color"></nz-badge>
                  </nz-col>
                  <nz-col nzSpan="20">
                    {{ item.label }}
                  </nz-col>
                </nz-row>
              </nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </nz-col>
    </nz-row>
  </nz-card>
  <nz-space nzAlign="start" nzDirection="vertical" nzSize="small"></nz-space>

  <nz-card nzSize="small" [nzTitle]="planningFormReferentTitle">
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="24">
        <nz-form-item nzRequired>
          <nz-form-label nzSpan="24" nzRequired></nz-form-label>
          <nz-form-control>
            <app-user-list-selector
              [borderless]="true"
              [size]="'small'"
              (select)="onSelectReferent($event)"
            ></app-user-list-selector>
          </nz-form-control>
        </nz-form-item>
      </nz-col>
    </nz-row>
  </nz-card>
  <nz-space nzAlign="start" nzDirection="vertical" nzSize="small"></nz-space>

  <nz-card nzSize="small" [nzTitle]="planningFormNoteTitle">
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="24">
        <nz-form-item nzRequired>
          <nz-form-label nzSpan="24" nzRequired></nz-form-label>
          <nz-form-control>
            <textarea
              nz-input
              nzBorderless
              [nzAutosize]="{ minRows: 4, maxRows: 4 }"
              formControlName="content"
              placeholder="Demande, besoin, note..."
            ></textarea>
          </nz-form-control>
        </nz-form-item>
      </nz-col>
    </nz-row>
  </nz-card>

  <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="end">
    <nz-col nzSpan="16"></nz-col>
    <nz-col nzSpan="4">
      <button
        nz-button
        nzBlock
        nzType="text"
        (click)="$event.stopPropagation(); onCancel()"
      >
        Anuler
      </button>
    </nz-col>
    <nz-col nzSpan="4">
      <button
        *ngIf="isAddForm"
        nz-button
        [disabled]="!eventForm.valid"
        nzBlock
        nzType="primary"
        (click)="$event.stopPropagation(); onAdd()"
      >
        Ajouter
      </button>
      <ng-container *ngIf="event$ | async as event">
        <button
          *ngIf="!isAddForm"
          nz-button
          [disabled]="!eventForm.valid"
          nzBlock
          nzType="primary"
          (click)="$event.stopPropagation(); onUpdate(event)"
        >
          Modifier
        </button>
      </ng-container>
    </nz-col>
  </nz-row>
</form>

<ng-template #planningFormDefinitionTitle>
  <app-header-title
    [icon]="'short_text'"
    [title]="'Définition'"
    [subtitle]="true"
  ></app-header-title>
</ng-template>
<ng-template #planningFormReferentTitle>
  <app-header-title
    [icon]="'person'"
    [title]="'Référent'"
    [subtitle]="true"
  ></app-header-title>
</ng-template>
<ng-template #planningFormNoteTitle>
  <app-header-title
    [icon]="'subject'"
    [title]="'Note'"
    [subtitle]="true"
  ></app-header-title>
</ng-template>
