import { ItemEvent } from 'src/app/features/suivi/components/item-event/itemEvent.model';
import { ItemEventId } from '../../../features/suivi/components/item-event/itemEvent.model';
import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Entite, EntiteId, EntiteSMALLid } from './entite.model';
import { OperationSmallId } from '../../operation/store/operation.model';
import { ContactSMALLid } from '../../contact/store/contact.model';

//LOAD ALL
export const loadEntites = createAction('[Entite/COMPONENT] Load Entites');

export const loadEntitesSuccess = createAction(
  '[Entite/API] Load Entites Success',
  props<{ entites: EntiteSMALLid[] }>()
);
export const loadEntitesFailure = createAction(
  '[Entite/API] Load Entites Failure',
  props<{ error: any }>()
);

//LOAD ONE
export const loadEntite = createAction(
  '[Entite/COMPONENT] Load Entite',
  props<{ id: string }>()
);
export const loadEntiteSuccess = createAction(
  '[Entite/API] Load Entite Success',
  props<{ entite: Entite }>()
);
export const loadEntiteFailure = createAction(
  '[Entite/API] Load Entite Failure',
  props<{ error: any }>()
);

//LOAD STATISTICS
export const loadEntiteStatistiques = createAction(
  '[Entite/COMPONENT] Load Entite Statistiques',
  props<{ id: string }>()
);
export const loadEntiteStatistiquesSuccess = createAction(
  '[Entite/API] Load Entite Statistiques Success',
  props<{ statistiques: any }>()
);
export const loadEntiteStatistiquesFailure = createAction(
  '[Entite/API] Load Entite Statistiques Failure',
  props<{ error: any }>()
);

//LOAD FILTERS
export const loadEntiteFilters = createAction(
  '[Entite/COMPONENT] Load Entite Filters'
);
export const loadEntiteFiltersSuccess = createAction(
  '[Entite/API] Load Entite Filters Success',
  props<{ filters: any }>()
);
export const loadEntiteFiltersFailure = createAction(
  '[Entite/API] Load Entite Filters Failure',
  props<{ error: any }>()
);

//QUERIES
export const queryEntites = createAction(
  '[Entite/COMPONENT] Query Entites',
  props<{ filter: string; filterType: string }>()
);
export const queryEntitesSuccess = createAction(
  '[Entite/API] Query Entites Success',
  props<{ entites: EntiteSMALLid[] }>()
);
export const queryEntitesFailure = createAction(
  '[Entite/API] Query Entites Failure',
  props<{ error: any }>()
);

//QUERIES SIRET
export const loadEntiteSirets = createAction(
  '[Entite/COMPONENT] Load Entite Sirets'
);
export const loadEntiteSiretsSuccess = createAction(
  '[Entite/API] Load Entite Sirets Success',
  props<{ sirets: any }>()
);
export const loadEntiteSiretsFailure = createAction(
  '[Entite/API] Load Entite Sirets Failure',
  props<{ error: any }>()
);

//ADD
export const addEntite = createAction(
  '[Entite/API] Add Entite',
  props<{ entite: Entite }>()
);

export const addEntiteSuccess = createAction('[Entite/API] Add Entite Success');

export const addEntiteFailure = createAction(
  '[Entite/API] Add Entite Failure',
  props<{ error: any }>()
);

//UPDATE
export const updateEntite = createAction(
  '[Entite/COMPONENT] Update Entite',
  props<{ entite: Update<EntiteId> }>()
);

export const updateEntiteSuccess = createAction(
  '[Entite/API] Update Entite Success'
);

export const updateEntiteFailure = createAction(
  '[Entite/API] Update Entite Failure',
  props<{ error: any }>()
);

//DELETE
export const deleteEntite = createAction(
  '[Entite/API] Delete Entite',
  props<{ id: string }>()
);

export const deleteEntiteSuccess = createAction(
  '[Entite/API] Delete Entite Success',
  props<{ success: string }>()
);

export const deleteEntiteFailure = createAction(
  '[Entite/API] Delete Entite Failure',
  props<{ error: any }>()
);

//DELETE ALL
export const deleteEntites = createAction(
  '[Entite/API] Delete Entites',
  props<{ ids: string[] }>()
);
export const deleteEntitesSuccess = createAction(
  '[Entite/API] Delete Entites Success',
  props<{ success: string }>()
);
export const deleteEntitesFailure = createAction(
  '[Entite/API] Delete Entites Failure',
  props<{ error: any }>()
);

export const clearEntite = createAction('[Entite/API] Clear Entite');

export const clearEntites = createAction('[Entite/API] Clear Entites');

//DOCUMENTS
export const loading = createAction(
  '[Entite/COMPONENT] Loading Entite',
  props<{ loading: boolean }>()
);
export const loadFileProgress = createAction(
  '[Entite/API] File upload progreess Entite',
  props<{ progress: any }>()
);

//load documents
export const loadDocuments = createAction(
  '[Entite/COMPONENT] Load Documents Entite',
  props<{ entiteId: string }>()
);

export const loadDocumentsSuccess = createAction(
  '[Entite/API] Load Documents Entite Success',
  props<{ documents: any }>()
);

export const loadDocumentsFailure = createAction(
  '[Entite/API] Load Documents Entite Failure',
  props<{ error: any }>()
);

//add document
export const addDocuments = createAction(
  '[Entite/COMPONENT] Add Documents Entite',
  props<{ entite: EntiteId; documents: any[] }>()
);

export const addDocumentsSuccess = createAction(
  '[Entite/API] Add Documents Entite Success',
  props<{ documents: any[] }>()
);

export const addDocumentsFailure = createAction(
  '[Entite/API] Add Documents Entite Failure',
  props<{ error: any }>()
);

//delete document
export const deleteDocument = createAction(
  '[Entite/COMPONENT] Delete Document Entite',
  props<{ entiteId: string; document: any }>()
);

export const deleteDocumentSuccess = createAction(
  '[Entite/API] Delete Document Entite Success',
  props<{ success: string }>()
);

export const deleteDocumentFailure = createAction(
  '[Entite/API] Delete Document Entite Failure',
  props<{ error: any }>()
);

//CONTACTS
//load
export const loadEntiteContacts = createAction(
  '[Entite/COMPONENT] Load Contacts Entite',
  props<{ id: string }>()
);

export const loadEntiteContactsSuccess = createAction(
  '[Entite/API] Load Contacts Entite Success',
  props<{ contacts: ContactSMALLid[] }>()
);

export const loadEntiteContactsFailure = createAction(
  '[Entite/API] Load Contacts Entite Failure',
  props<{ error: any }>()
);

//OPERATIONS
//load
export const loadOperations = createAction(
  '[Entite/COMPONENT] Load Operation Entite',
  props<{ entiteId: string }>()
);

export const loadOperationsSuccess = createAction(
  '[Entite/API] Load Operation Entite Success',
  props<{ operations: any[] }>()
);

export const loadOperationsFailure = createAction(
  '[Entite/API] Load Operation Entite Failure',
  props<{ error: any }>()
);

//add
export const addOperation = createAction(
  '[Entite/COMPONENT] Add Operation Entite',
  props<{ entite: EntiteId; operation: OperationSmallId; intervention: any }>()
);

export const addOperationSuccess = createAction(
  '[Entite/API] Add Operation Entite Success',
  props<{ success: string }>()
);

export const addOperationFailure = createAction(
  '[Entite/API] Add Operation Entite Failure',
  props<{ error: any }>()
);

//update
export const updateOperation = createAction(
  '[Entite/COMPONENT] Update Operation Entite',
  props<{ entite: EntiteId; operation: Update<any> }>()
);

export const updateOperationSuccess = createAction(
  '[Entite/API] Update Operation Entite Success',
  props<{ success: string }>()
);

export const updateOperationFailure = createAction(
  '[Entite/API] Update Operation Entite Failure',
  props<{ error: any }>()
);

//delete
export const deleteOperation = createAction(
  '[Entite/COMPONENT] Delete Operation Entite',
  props<{ entite: EntiteId; operation: OperationSmallId }>()
);

export const deleteOperationSuccess = createAction(
  '[Entite/API] Delete Operation Entite Success',
  props<{ success: string }>()
);

export const deleteOperationFailure = createAction(
  '[Entite/API] Delete Operation Entite Failure',
  props<{ error: any }>()
);

// ENTITES LIST BY OPERATION
export const loadEntitesByOpeation = createAction(
  '[Entite/API] Load Entites By Operation'
);

export const loadEntitesByOpeationSuccess = createAction(
  '[Entite/API] Load Entites By Operation Success',
  props<{ entites: EntiteSMALLid[] }>()
);
export const loadEntitesByOpeationFailure = createAction(
  '[Entite/API] Load Entites By Operation Failure',
  props<{ error: any }>()
);
