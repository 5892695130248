import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { OperationContacts } from './operation-contacts.model';
import * as OperationContactsActions from './operation-contacts.actions';

export const operationContactsFeatureKey = 'operationContacts';

export interface OperationContactState extends EntityState<OperationContacts> {
  loading: boolean;
  contact: any;
  suivis: any;
  documents: any;
  queries: any;
  error: any;
}

export const adapter: EntityAdapter<OperationContacts> =
  createEntityAdapter<OperationContacts>();

export const initialState: OperationContactState = adapter.getInitialState({
  loading: false,
  contact: undefined,
  suivis: undefined,
  documents: undefined,
  queries: undefined,
  error: undefined,
});

export const reducer = createReducer(
  initialState,
  // on(OperationContactsActions.addOperationContactSuccess,
  //   (state, action) => adapter.addOne(action.contact, state)
  // ),

  on(OperationContactsActions.updateOperationContacts, (state, action) =>
    adapter.updateOne(action.contact, state)
  ),
  on(OperationContactsActions.deleteOperationContact, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(OperationContactsActions.deleteOperationContacts, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(OperationContactsActions.loadOperationContactsSuccess, (state, action) =>
    adapter.setAll(action.contacts, state)
  ),
  on(OperationContactsActions.clearOperationContactss, (state) =>
    adapter.removeAll(state)
  ),
  on(OperationContactsActions.clearOperationContactss, (state) =>
    adapter.removeAll(state)
  ),

  //LOADING
  on(OperationContactsActions.loadingState, (state, action) => {
    return {
      ...state,
      loading: action.loading,
    };
  }),

  //QUERY
  on(
    OperationContactsActions.queryOperationContactsSuccess,
    (state, action) => {
      return {
        ...state,
        queries: action.contacts,
      };
    }
  ),

  //ERROR
  on(OperationContactsActions.loadOperationContactsFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  on(OperationContactsActions.addOperationContactFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  on(
    OperationContactsActions.updateOperationContactFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
      };
    }
  ),
  on(OperationContactsActions.loadOperationContactsFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
