import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-missions-form-footer',
  templateUrl: './missions-form-footer.component.html',
  styleUrls: ['./missions-form-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MissionsFormFooterComponent implements OnInit {
  currentStep$ = new BehaviorSubject<number>(0);

  @Input() currentStep: number = 0;
  @Input() missionForm: FormGroup<any> = new FormGroup({});
  @Input() isAdd: boolean = false;
  @Input() isMetierUpdate: any;
  @Input() isContratUpdate: any;

  @Output() add: EventEmitter<any> = new EventEmitter(false);
  @Output() update: EventEmitter<any> = new EventEmitter(false);
  @Output() step: EventEmitter<number> = new EventEmitter<number>();
  @Output() cancel: EventEmitter<any> = new EventEmitter(false);

  constructor() {}

  ngOnInit(): void {}

  onStepBack(
    step: number,
    isAdd: boolean,
    isMissionUpdate: any,
    isContratUpdate: any
  ): void {
    const nextStep = step > 0 ? step - 1 : 0;
    this.currentStep = nextStep;

    this.currentStep$.next(nextStep);
    this.step.emit(nextStep);
  }
  onStepNext(
    step: number,
    isAdd: boolean,
    isMissionUpdate: any,
    isContratUpdate: any
  ): void {
    const nextStep = step < 7 ? step + 1 : 7;
    this.currentStep = nextStep;
    this.currentStep$.next(nextStep);
    this.step.emit(nextStep);
  }

  onValidation(): void {
    this.add.emit(true);
  }

  onUpdateValidation(): void {
    this.update.emit(true);
  }

  onCancel(): void {
    this.cancel.emit(true);
  }
}
