import { QueryGeoTerritoireRegionsServiceGQL } from './graphql/queries/query-geo-territoire-regions.service';
import { QueryGeoTerritoireCommunesServiceGQL } from './graphql/queries/query-geo-territoire-communes.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromGeoTerritoire from './store/geo-territoire.reducer';
import { EffectsModule } from '@ngrx/effects';
import { GeoTerritoireEffects } from './store/geo-territoire.effects';
import { GeoTerritoireService } from './services/geo-territoire.service';
import { QueryGeoTerritoireDepartementsServiceGQL } from './graphql/queries/query-geo-territoire-departements.service';
import { GeoTerritoireSearchComponent } from './components/geo-territoire-search/geo-territoire-search.component';
import { GeoTerritoireSearchListHeaderComponent } from './components/geo-territoire-search-list-header/geo-territoire-search-list-header.component';
import { GeoTerritoireSearchListComponent } from './components/geo-territoire-search-list/geo-territoire-search-list.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { GeoTerritoireSearchSelectComponent } from './components/geo-territoire-search-select/geo-territoire-search-select.component';

@NgModule({
  declarations: [
    GeoTerritoireSearchComponent,
    GeoTerritoireSearchListHeaderComponent,
    GeoTerritoireSearchListComponent,
    GeoTerritoireSearchSelectComponent,
  ],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromGeoTerritoire.geoTerritoiresFeatureKey,
      fromGeoTerritoire.reducer
    ),
    EffectsModule.forFeature([GeoTerritoireEffects]),
    SharedModule,
  ],
  providers: [
    GeoTerritoireService,
    QueryGeoTerritoireCommunesServiceGQL,
    QueryGeoTerritoireRegionsServiceGQL,
    QueryGeoTerritoireDepartementsServiceGQL,
  ],
  exports: [GeoTerritoireSearchComponent],
})
export class GeoTerritoireModule {}
