<ng-container *ngIf="user$ | async as user">
  <ng-container *ngIf="operation$ | async as operation">
    <ng-container *ngIf="!(loading$ | async); else loadingAnimation">
      <ng-container *ngIf="note$ | async as note">
        <nz-page-header
          class="site-page-header"
          (nzBack)="onBack()"
          nzBackIcon
          [nzTitle]="notetitleTpl"
          [nzSubtitle]="noteSubtitleTpl"
        >
          <ng-template #noteSubtitleTpl> </ng-template>
          <ng-template #notetitleTpl>
            <h5 nz-typography>
              {{ note.title | uppercase | carLimitation: 100 }}
            </h5>
          </ng-template>
          <nz-page-header-content>
            <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
              <nz-col nzSpan="12">
                <p nz-typography>
                  {{ note?.adresse?.adresse?.properties?.label }}
                </p>
              </nz-col>
              <nz-col nzSpan="12"></nz-col>
            </nz-row>

            <!-- <app-operation-note-item-header
      [note$]="note$"
    ></app-operation-note-item-header> -->
          </nz-page-header-content>
          <nz-page-header-extra>
            <button
              nz-button
              nzType="text"
              nzShape="circle"
              nz-popover
              [nzPopoverMouseEnterDelay]="0.15"
              nzPopoverPlacement="bottomRight"
              nzPopoverTrigger="click"
              [nzPopoverContent]="sujetsWarningTpl"
              [nzPopoverTitle]="sujetsWarningTitleTpl"
              nz-tooltip
              nzTooltipPlacement="bottom"
            >
              <nz-badge [nzCount]="(sujetsWarning$ | async)?.size">
                <mat-icon
                  [ngClass]="{
                    alert:
                      (sujetsWarning$ | async)?.size ||
                      (sujetsWarning$ | async)?.size > 0,
                    default:
                      !(sujetsWarning$ | async)?.size ||
                      (sujetsWarning$ | async)?.size === 0
                  }"
                  >notification_important</mat-icon
                >
              </nz-badge>
            </button>
            <nz-space nzDirection="horizontal" nzSize="small"></nz-space>
            <button
              nz-button
              nzType="text"
              nzShape="circle"
              nz-tooltip
              nzTooltipTitle="Participants à la réunion"
              nzTooltipPlacement="bottom"
              [nzTooltipMouseEnterDelay]="0.5"
              nz-popover
              nzPopoverPlacement="bottomRight"
              nzPopoverTrigger="click"
              [nzPopoverContent]="participantTpl"
              [nzPopoverTitle]="participantTitleTpl"
            >
              <mat-icon>group</mat-icon>
            </button>
            <nz-space nzDirection="horizontal" nzSize="small"></nz-space>

            <ng-template #participantTpl>
              <app-operation-note-participant-table
                [operation]="operation"
                [note$]="note$"
              ></app-operation-note-participant-table>
            </ng-template>
            <ng-template #participantTitleTpl>
              <app-header-title
                [icon]="'group'"
                [title]="'Participants à la réunion'"
                [subtitle]="true"
              ></app-header-title>
            </ng-template>

            <app-button-dropdown
              *ngIf="subtitles$ | async as subtitles; else noSubtitleSize"
              [isDelete]="true"
              [isUpdate]="true"
              [isExportPDF]="true"
              [isExportWRD]="false"
              [deleteType]="'Supprimer ce compte rendu ?'"
              (select)="
                onSelectDropdown($event, user, operation, note, subtitles)
              "
            ></app-button-dropdown>
            <ng-template #noSubtitleSize>
              <app-button-dropdown
                [isDelete]="true"
                [isUpdate]="true"
                [deleteType]="'Supprimer ce compte rendu ?'"
                (select)="onSelectDropdown($event, user, operation, note, [])"
              ></app-button-dropdown>
            </ng-template>
          </nz-page-header-extra>
        </nz-page-header>
        <nz-space nzSize="small" nzDirection="vertical"></nz-space>

        <nz-row nzAlign="top" nzAlign="top" nzJustify="start">
          <nz-col nzSpan="8">
            <app-operation-note-subtitle-list
              [note$]="note$"
              (select)="onSelectSubtitle($event)"
            ></app-operation-note-subtitle-list>
          </nz-col>
          <nz-col nzSpan="16">
            <div class="note_item_content">
              <app-operation-note-sujet-list
                [note$]="note$"
                [subtitle$]="subtitle$"
              ></app-operation-note-sujet-list>
            </div>
          </nz-col>
        </nz-row>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-template #loadingAnimation>
    <app-loading-animation [loading$]="loading$"></app-loading-animation>
  </ng-template>

  <ng-template #noData>
    <app-no-result [description]="'Aucun compte rendu'"></app-no-result>
  </ng-template>

  <ng-template #sujetsWarningTpl>
    <app-operation-note-sujet-warning-list
      [sujets]="(sujetsWarning$ | async)?.sujets"
    ></app-operation-note-sujet-warning-list>
  </ng-template>
  <ng-template #sujetsWarningTitleTpl>
    <app-header-title
      [icon]="'notification_important'"
      [title]="'Sujets en alerte'"
      [subtitle]="true"
    ></app-header-title>
  </ng-template>

  <ng-template #exportNoteFormTitle>
    <app-header-title
      [icon]="'picture_as_pdf'"
      [title]="'Exporter'"
      [subtitle]="true"
    ></app-header-title>
  </ng-template>
</ng-container>
