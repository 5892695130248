<ng-container *ngIf="formation">
  <nz-page-header
    class="page-header"
    [nzTitle]="formation.longTitle | carLimitation: 100"
    nzBackIcon
    (nzBack)="onBack()"
  >
    <nz-page-header-extra>
      <ng-container *ngIf="formation">
        <button
          nz-button
          nzType="primary"
          (click)="onAdd(formation)"
          nzShape="round"
          *ngIf="isForm"
        >
          Ajouter
        </button>
      </ng-container>
    </nz-page-header-extra>
    <nz-page-header-content>
      <strong>{{ formation?.diploma }}</strong>
      <nz-tag nzColor="volcano" *ngIf="formation?.rncpEligibleApprentissage">
        {{ isApprentissage }}
      </nz-tag>
      <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>

      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col nzSpan="6">
          <nz-tag nzColor="geekblue"> RNCP : {{ formation?.rncpCode }} </nz-tag>
        </nz-col>
        <nz-col nzSpan="18">
          <nz-tag nzColor="geekblue">
            RNCP Libellé :
            {{ formation?.rncpLabel }}
          </nz-tag>
        </nz-col>
      </nz-row>
      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col nzSpan="6">
          <nz-tag nzColor="geekblue">
            Effectif : {{ formation?.capacity }}
          </nz-tag>
        </nz-col>
        <nz-col nzSpan="6"> Périodes : </nz-col>
        <nz-col nzSpan="12">
          <nz-tag nzColor="cyan" *ngFor="let period of formation?.period">
            {{ period }}
          </nz-tag>
        </nz-col>
      </nz-row>
    </nz-page-header-content>
  </nz-page-header>
  <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
  <nz-row nzAlign="top" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="4">
      <mat-icon>domain</mat-icon>
    </nz-col>
    <nz-col nzSpan="20">
      {{ formation?.company?.name }}
    </nz-col>
  </nz-row>
  <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
  <nz-row nzAlign="top" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="4">
      <mat-icon>map</mat-icon>
    </nz-col>
    <nz-col nzSpan="20">
      {{ formation?.place?.fullAddress }}
    </nz-col>
  </nz-row>
  <nz-row nzAlign="top" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="4"> </nz-col>
    <nz-col nzSpan="10">
      <strong>{{ formation?.place?.city | uppercase }}</strong>
    </nz-col>
    <nz-col nzSpan="10">
      <strong>{{ formation?.place?.region | uppercase }}</strong>
    </nz-col>
  </nz-row>

  <nz-divider
    nzOrientation="center"
    nzType="horizontal"
    *ngIf="
      formation?.contact?.name ||
      formation?.contact?.phone ||
      formation?.contact?.email
    "
  ></nz-divider>
  <nz-row
    nzAlign="top"
    nzGutter="8"
    nzJustify="start"
    *ngIf="formation?.contact?.name"
  >
    <nz-col nzSpan="4">
      <mat-icon>person</mat-icon>
    </nz-col>

    <nz-col nzSpan="20">
      {{ formation?.contact?.name }}
    </nz-col>
  </nz-row>
  <nz-row
    nzAlign="top"
    nzGutter="8"
    nzJustify="start"
    *ngIf="formation?.contact?.phone || formation?.contact?.email"
  >
    <nz-col nzSpan="4"> </nz-col>
    <nz-col nzSpan="2">
      <mat-icon>call</mat-icon>
    </nz-col>
    <nz-col nzSpan="8">
      {{ formation?.contact?.phone }}
    </nz-col>
    <nz-col nzSpan="2">
      <mat-icon>mail</mat-icon>
    </nz-col>
    <nz-col nzSpan="8">
      {{ formation?.contact?.email }}
    </nz-col>
  </nz-row>
  <nz-row nzAlign="top" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="4"> </nz-col>
    <nz-col nzSpan="20">
      <mat-icon>{{ formation?.contact?.info }}</mat-icon>
    </nz-col>
  </nz-row>

  <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
  <nz-row nzAlign="top" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="4">
      <mat-icon>language</mat-icon>
    </nz-col>
    <nz-col nzSpan="22">
      <button
        nz-button
        nzBlock
        nzType="link"
        (click)="onOpenOnisepLinkClick(formation.onisepUrl)"
      >
        {{ formation?.onisepUrl }}
      </button>
    </nz-col>
  </nz-row>
</ng-container>

<!-- title
diplomaLevel
company {
  name
  headquarter {
    place {
      address
      zipCode
      city
    }
  }
}

diploma
cfd
longTitle
rncpEligibleApprentissage
training -->
