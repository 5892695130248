import { takeUntil } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { of, Observable, Subject } from 'rxjs';
import { OperationSmallId } from 'src/app/components/operation/store/operation.model';
import { EntiteState } from './../../store/entite.reducer';
import * as fromEntiteAction from './../../store/entite.actions';
import * as fromEntiteSelector from './../../store/entite.selectors';
import { EntiteId } from './../../store/entite.model';

@Component({
  selector: 'app-entite-operations',
  templateUrl: './entite-operations.component.html',
  styleUrls: ['./entite-operations.component.scss'],
})
export class EntiteOperationsComponent implements OnInit, OnDestroy {
  entite$: Observable<EntiteId | any> = of(null);
  operations$: Observable<any> = of(null);
  subscribe = new Subject();
  identity: string = 'entite';
  constructor(private entiteStore: Store<EntiteState>) {}

  ngOnInit(): void {
    this.getEntite();
    this.getOperations();
    this.loadOperations();
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getEntite(): void {
    this.entite$ = this.entiteStore.select(fromEntiteSelector.entite);
  }

  getOperations(): void {
    this.operations$ = this.entiteStore.select(fromEntiteSelector.operations);
  }

  loadOperations(): void {
    this.entite$
      .pipe(takeUntil(this.subscribe))
      .subscribe((entite: EntiteId) => {
        if (!entite) {
          return;
        } else {
          const entiteId: string = entite.id;
          this.entiteStore.dispatch(
            fromEntiteAction.loadOperations({ entiteId })
          );
        }
      });
  }

  onAdd(
    event: { intervention: any; operation: OperationSmallId },
    entite: EntiteId
  ): void {
    this.entiteStore.dispatch(
      fromEntiteAction.addOperation({
        entite,
        operation: event.operation,
        intervention: event.intervention,
      })
    );
  }

  onUpdate(operation: any, entite: EntiteId): void {
    if (!operation || !entite) return;
    this.entiteStore.dispatch(
      fromEntiteAction.updateOperation({ entite, operation })
    );
  }

  onDelete(operation: OperationSmallId, entite: EntiteId): void {
    this.entiteStore.dispatch(
      fromEntiteAction.deleteOperation({ entite, operation })
    );
  }
}
