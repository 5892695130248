import { TodoFormComponent } from './../todo-form/todo-form.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewContainerRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import { of, Observable, BehaviorSubject } from 'rxjs';
import { TodoId } from '../../store/todo.model';
import { TodoState } from '../../store/todo.reducer';
import * as fromTodoAction from '../../store/todo.actions';
import * as fromTodoSelector from '../../store/todo.selectors';
import { fromUnixTime, isAfter, isSameDay } from 'date-fns';

@Component({
  selector: 'app-todos',
  templateUrl: './todos.component.html',
  styleUrls: ['./todos.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TodosComponent implements OnInit {
  todos$: Observable<TodoId[] | any> = of(null);
  todo$: Observable<TodoId | any> = of(null);
  modalMode: boolean = false;
  modalWidth: number = 850;
  today: Date = new Date(Date.now());

  todoFormVisibility$ = new BehaviorSubject<boolean>(false);

  @Output() closeForm = new EventEmitter<any>(false);
  constructor(
    private todoStore: Store<TodoState>,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.getAll();
    this.getTodo();
    this.loadAllUncompleted();
  }

  getAll(): void {
    this.todos$ = this.todoStore.select(fromTodoSelector.todos);
  }

  getTodo(): void {
    this.todo$ = this.todoStore.select(fromTodoSelector.todo);
  }

  onOpen(modalMode: boolean): void {
    if (!modalMode) {
      this.onOpenForm();
    } else {
      this.todoFormVisibility$.next(true);
      this.closeForm.emit(false);
    }
  }

  onClose(modalMode: boolean): any {
    if (!modalMode) {
      this.todoFormVisibility$.next(false);
      this.closeForm.emit(true);
      this.todoStore.dispatch(fromTodoAction.clearTodo());
      return null;
    } else {
      this.todoStore.dispatch(fromTodoAction.clearTodo());
      this.todoFormVisibility$.next(false);
      this.closeForm.emit(true);
      return null;
    }
  }

  onCloseNewForm(modalMode: boolean): void {
    if (!modalMode) {
      this.onClose(modalMode);
      this.todoFormVisibility$.next(false);
    } else {
      this.todoFormVisibility$.next(false);
      this.todoStore.dispatch(fromTodoAction.clearTodo());
    }
  }

  onOpenForm(): void {
    const modal = this.modal.create({
      nzContent: TodoFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });

    const instance = modal.getContentComponent();
    modal.afterClose.subscribe((event) =>
      this.todoStore.dispatch(fromTodoAction.clearTodo())
    );
    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
        this.todoStore.dispatch(fromTodoAction.clearTodo());
      }
    });
  }

  loadAllUncompleted(): void {
    this.todoStore.dispatch(fromTodoAction.loadTodos({ completed: false }));
  }

  loadAllCompleted(): void {
    this.todoStore.dispatch(fromTodoAction.loadTodos({ completed: true }));
  }

  removeTodosFilters(event: boolean): any {
    if (!event) {
      return null;
    } else {
      this.getAll();
    }
  }

  getTodosToday(event: boolean): any {
    if (!event) {
      return null;
    } else {
      let todosOfToday: any[] = [];
      this.todoStore.pipe(select(fromTodoSelector.todos)).subscribe((todos) => {
        if (todos.length) {
          const filters = todos.filter((el) =>
            isSameDay(this.today, fromUnixTime(el.dateStart['seconds']))
          );
          todosOfToday = filters;
        } else {
          todosOfToday = [];
        }
      });

      this.todos$ = of(todosOfToday);
    }
  }

  getTodosPriority(event: boolean): any {
    if (!event) {
      return null;
    } else {
      let todosPriority: any[] = [];
      this.todoStore.pipe(select(fromTodoSelector.todos)).subscribe((todos) => {
        if (todos.length) {
          const filters = todos.filter((el) => el?.priority && !el?.completed);
          todosPriority = filters;
        } else {
          todosPriority = [];
        }
      });

      this.todos$ = of(todosPriority);
    }
  }

  getTodosFutur(event: boolean): any {
    if (!event) {
      return null;
    } else {
      let todosFutur: any = [];
      this.todoStore.pipe(select(fromTodoSelector.todos)).subscribe((todos) => {
        if (todos?.length) {
          const filter = todos.filter((el): any => {
            const start = fromUnixTime(el.dateStart['seconds']);
            if (isAfter(start, new Date(Date.now())) && !el?.completed) {
              return el;
            }
          });

          todosFutur = filter;
        } else {
          todosFutur = [];
        }
      });

      this.todos$ = of(todosFutur);
    }
  }

  getTodosLate(event: boolean): any {
    if (!event) {
      return null;
    } else {
      let todosFutur: any = [];
      this.todoStore.pipe(select(fromTodoSelector.todos)).subscribe((todos) => {
        if (todos?.length) {
          const filter = todos.filter((el): any => {
            const end = fromUnixTime(el.dateEnd['seconds']);
            if (isAfter(new Date(Date.now()), end) && !el?.completed) {
              return el;
            }
          });

          todosFutur = filter;
        } else {
          todosFutur = [];
        }
      });

      this.todos$ = of(todosFutur);
    }
  }

  onCloseTodoForm(): void {
    this.todoFormVisibility$.next(false);
    this.todoStore.dispatch(fromTodoAction.clearTodo());
  }
}
