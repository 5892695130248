import {
  NzNotificationPlacement,
  NzNotificationService,
} from 'ng-zorro-antd/notification';
import { Injectable } from '@angular/core';
import { Observable, of, EMPTY, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { Apollo, gql } from 'apollo-angular';
import {
  Storage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  StorageReference,
  UploadTask,
  UploadMetadata,
} from '@angular/fire/storage';

import { ParticipantDocumentState } from 'src/app/components/participant-documents/store/participant-document.reducer';
import { Store } from '@ngrx/store';
import * as fromParticipantDocumentAction from 'src/app/components/participant-documents/store/participant-document.actions';
import { AllParticipantDocumentsGQL } from '../../GRAPHQL/queries/participant/documents/query-participant-documents.service';
import { ParticipantDocumentGQL } from '../../GRAPHQL/queries/participant/documents/query-participant-document.service';
import { CreateParticipantDocumentGQL } from '../../GRAPHQL/mutations/mutation-create-participant-document.service';

@Injectable({
  providedIn: 'any',
})
export class ParticipantStorageService {
  NOTIFICATION_PLACEMENT: NzNotificationPlacement = 'bottomRight';

  GET_PARTICIPANT_DOCUMENTS = gql`
    query ParticipantDocuments($participantDocumentsId: ID!) {
      participantDocuments(id: $participantDocumentsId) {
        id
        fileName
        fileRef
        title
        type
        extension
        description
        correspondanceId
        auteur {
          avatar
          displayName
          setting {
            color
          }
        }
        dateStart
      }
    }
  `;

  CREATE_PARTICIPANT_DOCUMENTS = gql`
    mutation CreateParticipantDocuments(
      $createParticipantDocumentsId: ID!
      $document: DocumentInput!
    ) {
      createParticipantDocuments(
        id: $createParticipantDocumentsId
        document: $document
      ) {
        id
        fileName
        fileRef
        title
        type
        extension
        description
        correspondanceId
        auteur {
          avatar
          displayName
          setting {
            color
          }
        }
        dateStart
      }
    }
  `;
  DELETE_PARTICIPANT_DOCUMENT = gql`
    mutation DeleteParticipantDocument(
      $participantId: ID!
      $deleteParticipantDocumentId: ID!
    ) {
      deleteParticipantDocument(
        participantId: $participantId
        id: $deleteParticipantDocumentId
      )
    }
  `;
  DELETE_PARTICIPANT_DOCUMENTS = gql`
    mutation DeleteParticipantDocuments($participantId: ID!) {
      deleteParticipantDocuments(participantId: $participantId)
    }
  `;

  constructor(
    private apollo: Apollo,
    private storage: Storage,
    private notification: NzNotificationService,
    private pariticpantDocumentStore: Store<ParticipantDocumentState>,
    private allParticipantDocumentsGQL: AllParticipantDocumentsGQL,
    private participantDocumentGQL: ParticipantDocumentGQL,
    private createParticipantDocumentGQL: CreateParticipantDocumentGQL
  ) {}

  getAll(id: string): Observable<any> {
    try {
      return this.allParticipantDocumentsGQL
        .watch({
          id,
        })
        .valueChanges.pipe(
          map(({ data, loading, error }) => {
            return { data, loading, error };
          })
        );
    } catch (error) {
      return of(error);
    }
  }

  getOne(participantId: string, id: string): Observable<any> {
    try {
      return this.participantDocumentGQL
        .watch({
          participantId,
          id,
        })
        .valueChanges.pipe(
          map(({ data, loading, error }) => {
            return { data, loading, error };
          })
        );
    } catch (error) {
      return of(error);
    }
  }

  uploadFiles(
    organisationId: string,
    id: string,
    documents: any[]
  ): Observable<any> {
    try {
      if (!organisationId || !id || !documents?.length) EMPTY;

      if (this.checkDocumentsLength(documents)) EMPTY;

      this.notification.info('Téléchargement', '0 %', {
        nzAnimate: true,
        nzKey: 'CREATE_PARTICIPANT_DOCUMENT_PROGRESS',
        nzDuration: 6000,
        nzPlacement: this.NOTIFICATION_PLACEMENT,
      });

      documents.forEach((document) => {
        const { file, doc } = document;
        const extension: string = `${file.name.split('.').pop()}`;
        const fileName: string = this.formatFileName(file.name);
        const storagePah: string = `organisations/${organisationId}/participants/${id}/documents/${fileName}`;
        const storageRef: StorageReference = ref(this.storage, storagePah);
        const storageMetadata: UploadMetadata = {
          contentType: file.type,
          contentEncoding: '',
          contentLanguage: 'fr',
          cacheControl: '',
          customMetadata: {
            participantId: id,
            organisationId: organisationId,
            name: file.name,
            extension: extension,
            date: new Date(Date.now()).toDateString(),
          },
          md5Hash: '',
        };

        const task: UploadTask = uploadBytesResumable(
          storageRef,
          file,
          storageMetadata
        );
        task.on(
          'state_changed',
          (snap) => {
            let progress = (
              (snap.bytesTransferred / snap.totalBytes) *
              100
            ).toString();
            this.notification.info('Téléchargement', `${progress} %`, {
              nzAnimate: true,
              nzKey: 'CREATE_PARTICIPANT_DOCUMENT_PROGRESS',
              nzDuration: 8000,
              nzPlacement: this.NOTIFICATION_PLACEMENT,
            });
          },
          (error) => {
            this.notification.info('Téléchargement', error.message, {
              nzAnimate: true,
              nzKey: 'CREATE_PARTICIPANT_DOCUMENT_PROGRESS',
              nzDuration: 8000,
              nzPlacement: this.NOTIFICATION_PLACEMENT,
            });
          },
          () => {
            return from(
              getDownloadURL(task.snapshot.ref)
                .then((downloadURL) => downloadURL)
                .then((fileUrl) => {
                  const documentFile = {
                    fileRef: fileUrl,
                    extension: file.type,
                    recu: doc.recu,
                    title: doc.title,
                    type: doc.type,
                    valid: doc.valid,
                    path: storagePah,
                    fileName: fileName,
                    size: file.size,
                    description: '',
                  };

                  this.pariticpantDocumentStore.dispatch(
                    fromParticipantDocumentAction.addParticipantDocuments({
                      id: id,
                      document: documentFile,
                    })
                  );

                  return;
                })
                .catch((error) => of(error))
            );
          }
        );
        return of(null);
      });

      return of(`${documents.length} ajouté.s avec succès`);
    } catch (error) {
      return of(error);
    }
  }

  addFile(id: string, document: any): Observable<any> {
    try {
      return this.apollo.mutate<any>({
        mutation: this.CREATE_PARTICIPANT_DOCUMENTS,
        variables: { createParticipantDocumentsId: id, document: document },

        update: (cache, { data: { createParticipantDocuments } }) => {
          const data: any = cache.readQuery({
            query: this.allParticipantDocumentsGQL.document,
            variables: { id },
          });
          const participantDocuments = [
            ...data.participantDocuments,
            createParticipantDocuments,
          ];
          cache.writeQuery({
            query: this.allParticipantDocumentsGQL.document,
            variables: { id },
            data: { participantDocuments },
          });
        },
      });
    } catch (error) {
      return of(error);
    }
  }

  // updateApolloCache(cache: ApolloCache<any>, data: any, id: string): void {
  //   const documents: any = cache.readQuery({
  //     query: this.GET_PARTICIPANT_DOCUMENTS,
  //     variables: { participantDocumentsId: id },
  //   });

  //   cache.writeQuery({
  //     query: this.GET_PARTICIPANT_DOCUMENTS,
  //     data: {
  //       documents: [data.createParticipantDocuments, ...documents],
  //     },
  //   });
  // }

  updateOne(participantId: string, document: string): Observable<any> {
    try {
      return EMPTY;
      // return this.apollo.mutate<any>({
      //   mutation: this.DELETE_PARTICIPANT_DOCUMENT,
      //   variables: { participantId, document },
      // });
    } catch (error) {
      return of(error);
    }
  }

  deleteOne(participantId: string, id: string): Observable<any> {
    try {
      if (!participantId || !id) EMPTY;

      return this.apollo.mutate<any>({
        mutation: this.DELETE_PARTICIPANT_DOCUMENT,
        variables: { participantId, deleteParticipantDocumentId: id },
        update: (cache, { data: { deleteParticipantDocument } }) => {
          const data: any = cache.readQuery({
            query: this.allParticipantDocumentsGQL.document,
            variables: { id: participantId },
          });
          const participantDocuments = data.participantDocuments.filter(
            (doc: any) => doc.id !== deleteParticipantDocument.id
          );

          cache.writeQuery({
            query: this.allParticipantDocumentsGQL.document,
            variables: { id },
            data: { participantDocuments },
          });
        },
      });
    } catch (error) {
      return of(error);
    }
  }

  deleteAll(participantId: string): Observable<any> {
    try {
      if (!participantId) EMPTY;

      return this.apollo.mutate<any>({
        mutation: this.DELETE_PARTICIPANT_DOCUMENTS,
        variables: { participantId },
        update: (cache, { data: { deleteParticipantDocument } }) => {
          const data: any = cache.readQuery({
            query: this.allParticipantDocumentsGQL.document,
            variables: { id: participantId },
          });
          const participantDocuments: any = [];

          cache.writeQuery({
            query: this.allParticipantDocumentsGQL.document,
            variables: { id: participantId },
            data: { participantDocuments },
          });
        },
      });
    } catch (error) {
      return of(error);
    }
  }

  formatFileName(filename: string): string {
    const cleanFileName = filename.replace(/[^a-zA-Z0-9]/g, '-');
    const newFileName = cleanFileName.substring(0, 60);
    return newFileName;
  }

  checkDocumentsLength(documents: any[]): boolean {
    if (documents.length > 10) {
      this.notification.info(
        'Téléchargement',
        `Vous avez dépassé le nombre limite de fichiers à transférer : ${documents.length} sur 10 !`,
        {
          nzAnimate: true,
          nzKey: 'CREATE_PARTICIPANT_DOCUMENT_PROGRESS',
          nzDuration: 6000,
          nzPlacement: this.NOTIFICATION_PLACEMENT,
        }
      );
      return true;
    }

    return false;
  }
}
