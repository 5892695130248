<ng-container *ngIf="participant$ | async as participant">
  <ng-container *ngIf="diagnostic$ | async as diagnostic">
    <nz-descriptions [nzColumn]="{ xxl: 4, xl: 4, lg: 4, md: 4, sm: 2, xs: 1 }">
      <nz-descriptions-item nzTitle="Créé le">
        {{ diagnostic.dateStart | dateFr : "small" }}
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Créé par">
        <app-avatar-group [source]="[diagnostic.auteur]"></app-avatar-group>
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Modifié le">
        {{ diagnostic.dateUpdate | dateFr : "small" }}
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Modifié par">
        <app-avatar-group [source]="[diagnostic.userUpdate]"></app-avatar-group>
      </nz-descriptions-item>
    </nz-descriptions>
  </ng-container>
</ng-container>
