import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, Subject, takeUntil } from 'rxjs';
import { OperationId } from 'src/app/components/operation/store/operation.model';
import { EntiteState } from '../../store/entite.reducer';
import * as fromEntiteAction from '../../store/entite.actions';
import * as fromEntiteSelector from '../../store/entite.selectors';

@Component({
  selector: 'app-entite-list-by-operation',
  templateUrl: './entite-list-by-operation.component.html',
  styleUrls: ['./entite-list-by-operation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntiteListByOperationComponent
  implements OnInit, OnChanges, OnDestroy
{
  subscribe = new Subject();
  entites$: Observable<any> = of([]);
  currentSelected = null;
  @Input() entiteUpdate$: Observable<any> = of(null);
  @Input() noitemtitle: string = 'Aucune information';
  @Input() placeHolder: string = 'Sélectionner une entité';

  @Input() borderless: boolean = true;

  @Output() select = new EventEmitter();

  constructor(private entiteStore: Store<EntiteState>) {}

  ngOnInit(): void {
    this.onLoadEntitesOperation();
    this.onGetEntites();
    this.onUpdateEntiteOperation();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  onLoadEntitesOperation(): void {
    this.entiteStore.dispatch(fromEntiteAction.loadEntitesByOpeation());
  }

  onUpdateEntiteOperation(): void {
    this.entiteUpdate$
      .pipe(takeUntil(this.subscribe))
      .subscribe((entite: any) => {
        if (!entite) return;

        this.currentSelected = entite;
      });
  }

  onGetEntites(): void {
    this.entites$ = this.entiteStore.select(
      fromEntiteSelector.entitesByOperation
    );
  }

  onSelect(entite: any): void {
    if (!entite) return;
    this.select.emit(entite);
  }
}
