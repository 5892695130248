import { organisation } from './organisation.selectors';
import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Organisation, OrganisationId } from './organisation.model';

//LOAD ORGANISATIONS
export const loadOrganisations = createAction(
  '[Organisation/COMPONENT] Load Organisations'
);
export const loadOrganisationsSuccess = createAction(
  '[Organisation/API] Load Organisations Success',
  props<{ organisations: OrganisationId[] }>()
);
export const loadOrganisationsFailure = createAction(
  '[Organisation/API] Load Organisations Failure',
  props<{ error: any }>()
);

//LOAD ORGANISATION
export const loadOrganisation = createAction(
  '[Organisation/COMPONENT] Load Organisation'
);
export const loadOrganisationSuccess = createAction(
  '[Organisation/API] Load Organisation Success',
  props<{ organisation: OrganisationId }>()
);
export const loadOrganisationFailure = createAction(
  '[Organisation/API] Load Organisation Failure',
  props<{ error: any }>()
);

//ADD ORGANISATION
export const addOrganisation = createAction(
  '[Organisation/COMPONENT] Add Organisation',
  props<{ organisation: any; userAdmin: any }>()
);
export const addOrganisationSuccess = createAction(
  '[Organisation/API] Add Organisation Success',
  props<{ success: string }>()
);
export const addOrganisationFailure = createAction(
  '[Organisation/API] Add Organisation Failure',
  props<{ error: any }>()
);

//UPDATE ORGANISATION
export const updateOrganisation = createAction(
  '[Organisation/COMPONENT] Update Organisation',
  props<{ organisation: Update<OrganisationId> }>()
);

export const updateOrganisationSuccess = createAction(
  '[Organisation/API] Update Organisation Success',
  props<{ organisation: OrganisationId }>()
);

export const updateOrganisationFailure = createAction(
  '[Organisation/API] Update Organisation Failure',
  props<{ error: any }>()
);

//DELETE ORGANISATION
export const deleteOrganisation = createAction(
  '[Organisation/COMPONENT] Delete Organisation',
  props<{ id: string }>()
);
export const deleteOrganisationSuccess = createAction(
  '[Organisation/API] Delete Organisation',
  props<{ success: any }>()
);
export const deleteOrganisationFailure = createAction(
  '[Organisation/API] Delete Organisation',
  props<{ error: any }>()
);

//ADD LOGO ORGANISATION
export const addLogoOrganisation = createAction(
  '[Organisation/COMPONENT] Add Logo Organisation',
  props<{ file: File }>()
);
export const addLogoOrganisationSuccess = createAction(
  '[Organisation/API] Add Logo Organisation Success',
  props<{ success: string }>()
);
export const addLogoOrganisationFailure = createAction(
  '[Organisation/API] Add Logo Organisation Failure',
  props<{ error: any }>()
);

//LOAD LOGO ORGANISATION
export const loadLogoOrganisation = createAction(
  '[Organisation/COMPONENT] LOADING Logo Organisation',
  props<{ progress: number }>()
);

//REMOVE LOGO ORGANISATION
export const removeLogoOrganisation = createAction(
  '[Organisation/COMPONENT] Remove Logo Organisation',
  props<{ organisation: OrganisationId }>()
);
export const removeLogoOrganisationSuccess = createAction(
  '[Organisation/API] Remove Logo Organisation Success',
  props<{ success: string }>()
);
export const removeLogoOrganisationFailure = createAction(
  '[Organisation/API] Remove Logo Organisation Failure',
  props<{ error: any }>()
);

//CLEAR
export const clearOrganisations = createAction(
  '[Organisation/API] Clear Organisations'
);
