<app-card-identity *ngIf="entite">
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="4">
      <nz-avatar
        nzSize="default"
        nzIcon="mail"
        [ngStyle]="{ 'background-color': '#85a5ff' }"
      >
      </nz-avatar>
    </nz-col>
    <nz-col nzSpan="20">
      <nz-tag class="tagCoordonnes">
        {{ entite?.custom?.coordonnees?.email | carLimitation: 5 }}
      </nz-tag>
    </nz-col>
  </nz-row>
  <nz-space [nzSize]="5"></nz-space>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="4">
      <nz-avatar
        nzSize="default"
        nzIcon="mobile"
        [ngStyle]="{ 'background-color': '#ffc53d' }"
      >
      </nz-avatar>
    </nz-col>
    <nz-col nzSpan="20">
      <nz-tag class="tagCoordonnes">
        {{ entite?.custom?.coordonnees?.mobile | phone }}
      </nz-tag>
    </nz-col>
  </nz-row>
  <nz-space [nzSize]="5"></nz-space>

  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="4">
      <nz-avatar
        nzSize="default"
        nzIcon="phone"
        [ngStyle]="{ 'background-color': '#f759ab' }"
      >
      </nz-avatar>
    </nz-col>
    <nz-col nzSpan="20">
      <nz-tag class="tagCoordonnes">
        {{ entite?.custom?.coordonnees?.fixe | phone }}
      </nz-tag>
    </nz-col>
  </nz-row>

  <nz-divider class="identityDivider"></nz-divider>

  <nz-row nzAlign="middle" nzGutter="8" nzJustify="center">
    <nz-col nzSpan="4"></nz-col>
    <nz-col nzSpan="4">
      <button
        nz-button
        nzShape="circle"
        nz-tooltip
        [nzTooltipMouseEnterDelay]="0.5"
        nzTooltipTitle="Détails"
        (click)="onSelect('details')"
      >
        <i nz-icon nzType="info"></i>
      </button>
    </nz-col>
    <nz-col nzSpan="4">
      <button
        nz-button
        nzShape="circle"
        nz-tooltip
        [nzTooltipMouseEnterDelay]="0.5"
        nzTooltipTitle="Modifier"
        (click)="onSelect('update')"
      >
        <i nz-icon nzType="form"></i>
      </button>
    </nz-col>
    <nz-col nzSpan="4">
      <button
        nz-button
        nzShape="circle"
        nz-tooltip
        [nzTooltipMouseEnterDelay]="0.5"
        nzTooltipTitle="Exporter"
        (click)="onSelect('export')"
      >
        <i nz-icon nzType="download"></i>
      </button>
    </nz-col>

    <nz-col nzSpan="4">
      <button
        *ngIf="entite"
        nz-button
        nzShape="circle"
        nz-tooltip
        [nzTooltipMouseEnterDelay]="0.5"
        nzTooltipTitle="Supprimer"
        nz-popconfirm
        [nzPopconfirmTitle]="
          'Voulez-vous supprimer ' +
          (entite?.etablissement?.nom_raison_sociale | carLimitation: 25) +
          ' ?'
        "
        nzPopconfirmPlacement="bottom"
        (nzOnConfirm)="onSelect('delete')"
        (nzOnCancel)="onCancel()"
      >
        <i nz-icon nzType="delete"></i>
      </button>
    </nz-col>
    <nz-col nzSpan="4"></nz-col>
  </nz-row>
</app-card-identity>
