import { Injectable } from '@angular/core';
import { of, Observable, from, EMPTY } from 'rxjs';
import { mergeMap, switchMap, map, catchError } from 'rxjs/operators';
import {
  Auth,
  UserCredential,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  IdTokenResult,
  verifyBeforeUpdateEmail,
  sendPasswordResetEmail,
  sendEmailVerification,
  updatePassword,
  getAuth,
  sendSignInLinkToEmail,
  EmailAuthProvider,
  signOut,
} from '@angular/fire/auth';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { Router } from '@angular/router';
import { camelCase, trim } from 'lodash';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Apollo, gql } from 'apollo-angular';
import { UserService } from './user.service';
import { UserState } from '../components/user/store/user.reducer';
import * as fromUserAction from '../components/user/store/user.actions';
import * as fromUserSelector from '../components/user/store/user.selectors';

import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'any',
})
export class AuthService {
  denied: string =
    "Vous n'avez pas accès à cette fonctionnalité. Veuillez vous rapprocher de votre administrateur.";
  nullMessage: string = 'Aucun utilisateur sélectionné.';
  updatePasswordMessage: string =
    'Votre mot de passe a été modifié. Veuillez vous connecter avec votre nouveau mot de passe.';

  GET_ME = gql`
    query Me {
      me {
        avatar
        claims {
          admin
          dev
          manager
          member
        }
        coordonnees {
          email
          phoneNumber
        }
        organisation {
          id
          nom_raison_sociale
          licence
          logo
        }
        createTime
        disabled
        displayName
        fonction
        photo
        emailVerified
        readTime
        id
        updateTime
        service {
          id
          title
        }
        setting {
          color
        }
        searchFields
      }
    }
  `;

  EMAIL_CHECK = gql`
    mutation LoginEmailCheck($email: String!) {
      loginEmailCheck(email: $email) {
        message
        status
        title
      }
    }
  `;
  RESET_PASSWORD = gql`
    mutation ResetPassword($email: String!) {
      resetPassword(email: $email)
    }
  `;

  constructor(
    private auth: Auth,
    private fns: Functions,
    private router: Router,
    private message: NzMessageService,
    private apollo: Apollo,
    private userStore: Store<UserState>
  ) {}

  sendResetPassword(email: string): Observable<any> {
    return this.apollo
      .mutate<any>({
        mutation: this.RESET_PASSWORD,
        variables: { email },
      })
      .pipe(
        map(({ data }) => {
          return { data };
        }),
        (error) => {
          return error;
        }
      );
  }

  signInMethod(email: string): Observable<any> {
    return EMPTY;
  }

  emailCheck(email: string): Observable<any> {
    try {
      if (!email) EMPTY;

      return this.apollo.mutate({
        mutation: this.EMAIL_CHECK,
        variables: { email },
      });
    } catch (error) {
      return of(error);
    }
  }

  logIn(email: string, password: string): Observable<any> {
    try {
      if (!email || !password) EMPTY;

      return from(signInWithEmailAndPassword(this.auth, email, password)).pipe(
        mergeMap((credential: UserCredential) => {
          if (!credential) EMPTY;

          return of(credential);
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  getMe(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_ME,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  emailVerifySuccess(credential: UserCredential): Observable<any> {
    return this.getMe().pipe(
      map((respons) => {
        if (!respons) return EMPTY;
        const me = respons?.data?.me;

        this.userStore.dispatch(fromUserAction.loadMeSuccess({ user: me }));
        return of(`Succès`);
      })
    );
  }

  emailVerifyFailure(user: any): Observable<any> {
    const onCall = httpsCallable(this.fns, 'user-emailVerificationSendEmail');
    const email: string = user.email;
    const sendCall = onCall({ email })
      .then((res: any) => {
        if (res.data?.error) {
          this.message.warning(res.data.error, { nzDuration: 6000 });
        } else {
          this.message.success(res.data.result?.success, {
            nzDuration: 6000,
          });
        }

        this.router.navigate(['/login']);
        return of(null);
      })
      .catch((error) => error);

    return from(sendCall);
  }

  logOut(): Observable<any> {
    return from(
      signOut(this.auth)
        .then(() => {
          document.location.reload();
          this.apollo.client.resetStore();
          return;
        })
        .then(() => {
          this.router.navigate(['/']);
          return of('Déconnexion');
        })
        .catch((error) => of(error))
    ).pipe(mergeMap((res) => of(res)));
  }

  resetCache(): Observable<any> {
    this.apollo.client.resetStore();

    return EMPTY;
  }

  lostPassword(): Observable<any> {
    this.router.navigate(['/lostpassword']);
    return of(null);
  }

  backToLogin(): Observable<any> {
    try {
      this.router.navigate(['login']);
      return of(null);
    } catch (error) {
      return of(error);
    }
  }

  disabledUser(user: any): Observable<any> {
    try {
      if (!user) {
        this.message.warning(this.nullMessage, { nzDuration: 6000 });
        return of(null);
      } else {
        const onCall = httpsCallable(this.fns, 'user-disabledUser');

        return from(
          onCall({ ...user }).then((data: any) => {
            if (data?.error) {
              this.message.warning(data.error, { nzDuration: 6000 });
            } else {
              this.message.success(data.result, { nzDuration: 6000 });
              return data;
            }
          })
        );
      }
    } catch (error) {
      return of(error);
    }
  }

  activatedUser(user: any): Observable<any> {
    try {
      if (!user) {
        this.message.warning(this.nullMessage, { nzDuration: 6000 });
        return of(null);
      } else {
        const onCall = httpsCallable(this.fns, 'user-activatedUser');

        return from(
          onCall({ ...user }).then((data: any) => {
            if (data?.error) {
              this.message.warning(data.error, { nzDuration: 6000 });
            } else {
              this.message.success(data.result, { nzDuration: 6000 });
              return data;
            }
          })
        );
      }
    } catch (error) {
      return of(error);
    }
  }

  validationEmail(email: string): Observable<any> {
    try {
      if (!email) {
        this.message.warning('Aucun e-mail renseigné.', { nzDuration: 6000 });
        return of(null);
      } else {
        const onCall = httpsCallable(this.fns, 'user-emailValidation');

        return from(
          onCall({ email }).then((data: any) => {
            if (data?.error) {
              this.message.warning(data.error, { nzDuration: 6000 });
            } else {
              this.message.success(data.result, { nzDuration: 6000 });
              return data;
            }
          })
        );
      }
    } catch (error) {
      return of(error);
    }
  }

  updatePassword(password: string): Observable<any> {
    try {
      const user = this.auth.currentUser;
      if (!user) {
        return of(null);
      } else {
        return from(
          updatePassword(user, password)
            .then(() => {
              this.message.success(this.updatePasswordMessage, {
                nzDuration: 6000,
              });
              return null;
            })
            .catch((error) => {
              this.message.warning(error.message, { nzDuration: 6000 });
              return error;
            })
        ).pipe(mergeMap((res) => of(res)));
      }
    } catch (error) {
      return of(error);
    }
  }

  deleteUser(user: any): Observable<any> {
    try {
      const authUser = this.auth.currentUser;
      if (!authUser) {
        return of(null);
      } else {
        const onCall = httpsCallable(this.fns, 'user-deleteUser');

        return from(
          onCall({ ...user }).then((data: any) => {
            if (data?.error) {
              this.message.warning(data.error, { nzDuration: 6000 });
            } else {
              this.message.success(data.result, { nzDuration: 6000 });
              return data;
            }
          })
        ).pipe(mergeMap((res) => of(res)));
      }
    } catch (error) {
      return of(error);
    }
  }
}
