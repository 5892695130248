import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  EntiteSelectState,
  entiteSelectsFeatureKey,
  selectAll,
} from './entite-select.reducer';

export const entiteSelectState = createFeatureSelector<EntiteSelectState>(
  entiteSelectsFeatureKey
);

export const entitesSelect = createSelector(entiteSelectState, selectAll);
