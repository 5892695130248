import { UserState } from 'src/app/components/user/store/user.reducer';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { messageState } from '../../store/message.reducer';
import * as fromMessageActions from '../../store/message.actions';
import * as fromMessageSelectors from '../../store/message.selectors';
import { MessageId } from '../../store/message.model';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';
import { NzCalendarMode } from 'ng-zorro-antd/calendar';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MessageComponent implements OnInit, OnDestroy {
  subscribe = new Subject();
  user$: Observable<any> = of(null);
  users$: Observable<any> = of(null);
  messages$: Observable<MessageId[]> = of([]);
  error$: Observable<any> = of(null);
  newMessage: boolean = false;
  messageBadgeStyle = {
    backgroundColor: '#fff',
    color: '#999',
    boxShadow: '0 0 0 1px #d9d9d9 inset',
  };

  messageTitle: string = 'MESSAGES';
  messageFilter: boolean = false;
  newMessage$ = new BehaviorSubject<boolean>(false);

  date = new Date(2012, 11, 21);
  mode: NzCalendarMode = 'month';

  constructor(
    private storeMessage: Store<messageState>,
    private useStore: Store<UserState>
  ) {}

  ngOnInit(): void {
    this.get__USER();
  }

  get__USER(): void {
    this.user$ = this.useStore.pipe(select(fromUserSelector.user));
  }

  lister__MESSAGES(): void {
    this.storeMessage.dispatch(fromMessageActions.listenOnNewMessages());
  }

  new_MESSAGE(): void {
    this.newMessage$.next(true);
  }

  onCancel_MESSAGE(): void {
    this.newMessage$.next(false);
  }

  ngOnDestroy() {
    this.subscribe.next(null);
    this.subscribe.complete();
  }
}
