import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-participant-planning-list-card',
  templateUrl: './participant-planning-list-card.component.html',
  styleUrls: ['./participant-planning-list-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantPlanningListCardComponent implements OnInit {
  deleteAlert: string = 'Voulez-vous supprimer cet événement ?';

  @Input() items: any[] = [];
  @Output() delete = new EventEmitter<any>();
  constructor() {}

  ngOnInit(): void {}

  trackByFn(index: number, item: any): number {
    return item.id;
  }

  onAction(event: string, item: any): void {
    switch (event) {
      case 'delete':
        this.delete.emit(item.id);
        break;

      default:
        break;
    }
  }
}
