import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ChangeDetectionStrategy,
} from '@angular/core';
import { eachDayOfInterval, endOfWeek, isAfter, format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { fromUnixTime, startOfWeek } from 'date-fns/esm';
import { Observable, Subject, of } from 'rxjs';
import { TodoId } from '../../store/todo.model';
import { select, Store } from '@ngrx/store';
import { TodoState } from '../../store/todo.reducer';
import * as fromTodoSelector from '../../store/todo.selectors';
import * as fromTodoAction from '../../store/todo.actions';

import { Update } from '@ngrx/entity';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';

@Component({
  selector: 'app-todo-item',
  templateUrl: './todo-item.component.html',
  styleUrls: ['./todo-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TodoItemComponent implements OnInit, OnDestroy {
  subscribe = new Subject();
  user$: Observable<any> = of(null);

  green: string = '#73d13d';
  red: string = '#ff4d4f';

  todoEventsParse = {};
  todoHeatMapIterval = [];
  todayString: string = format(Date.now(), 'd EEE', { locale: fr });
  today: Date = new Date(Date.now());
  todayFns: string = format(Date.now(), 'P', { locale: fr });

  currentWeekNumber: string = format(Date.now(), 'I', { locale: fr });
  currentStartWeek: Date = startOfWeek(this.today, {
    locale: fr,
    weekStartsOn: 1,
  });

  currentEndWeek: Date = endOfWeek(this.today, { locale: fr, weekStartsOn: 1 });
  currentWeek = eachDayOfInterval({
    start: this.currentStartWeek,
    end: this.currentEndWeek,
  });

  TODOS__now: number = 0;
  TODOS__nowPriority: number = 0;
  TODOS__futurs: number = 0;
  TODOS__laters: number = 0;
  TODOS__futursPriority: number = 0;
  TODOS__latersPriority: number = 0;
  @Input() user: any = null;

  @Input() todo: TodoId | any = null;
  @Input() todosCompleted: boolean = false;
  @Input() todosUncompleted: boolean = false;
  @Output() selected = new EventEmitter<TodoId>(false);
  @Output() delete = new EventEmitter<TodoId>(false);

  constructor(
    private userStore: Store<UserState>,
    private todoStore: Store<TodoState>
  ) {}

  ngOnInit(): void {
    this.getUser();
  }

  getUser(): void {
    this.user$ = this.userStore.select(fromUserSelector.user);
  }

  isTodoFutur(todo: TodoId): boolean {
    const start = fromUnixTime(todo.dateStart['seconds']);
    if (isAfter(start, new Date(Date.now())) && !todo?.completed) {
      return true;
    } else {
      return false;
    }
  }

  isTodoLate(todo: TodoId): boolean {
    const end = fromUnixTime(todo.dateEnd['seconds']);
    if (isAfter(new Date(Date.now()), end) && !todo.completed) {
      return true;
    } else {
      return false;
    }
  }

  complete_TODO(todo: TodoId): any {
    if (!todo) {
      return null;
    } else {
      const updateTodo: Update<TodoId> = {
        id: todo.id,
        changes: {
          ...todo,
          completed: true,
          dateEnd_string: format(
            fromUnixTime(todo.dateStart['seconds']),
            'dd/MM/yyyy H:mm',
            {
              locale: fr,
            }
          ),
        },
      };
      this.todoStore.dispatch(
        fromTodoAction.completedTodo({ todo: updateTodo })
      );
    }
  }

  delete_TODO(todo: TodoId): any {
    if (!todo) {
      return null;
    } else {
      this.todoStore.dispatch(fromTodoAction.deleteTodo({ id: todo.id }));
    }
  }

  show__TODO(todo: TodoId): void {
    this.todoStore.dispatch(fromTodoAction.loadTodo({ todo }));
    this.selected.emit(todo);
  }

  onCancel(): any {
    return null;
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }
}
