import { ComponentPortal } from '@angular/cdk/portal';
import { ContactFiltersComponent } from './../contact-filters/contact-filters.component';
import { PortalNavigationService } from './../../../../portals/portal-navigation.service';
import { ContactFormComponent } from './../contact-form/contact-form.component';
import { ActivatedRoute, Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, Subject } from 'rxjs';
import { ContactState } from '../../store/contact.reducer';
import * as fromContactAction from '../../store/contact.actions';
import * as fromContactSelector from '../../store/contact.selectors';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserAction from 'src/app/components/user/store/user.actions';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';
import { Contact } from '../../store/contact.model';

@Component({
  selector: 'app-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.scss'],
})
export class ContactListComponent implements OnInit, OnDestroy {
  subscribe = new Subject();
  data$: Observable<any> = of(null);
  favoris$: Observable<any> = of(null);
  componentPortalContactFilter: ComponentPortal<ContactFiltersComponent> | any;
  title: string = 'Nouveau';
  contacts$: Observable<any> = of([]);
  modalWidth: number = 950;
  newCardTitle: string = 'Nouveau contact';
  newCardDescription: string = 'Ajouter un contact à votre organisation';
  newCardDefinition: string =
    "Un contact est un collaborateur d'une entreprise, structure ou entité partenaire ou cliente.";

  @ViewChild('contactFormTitle', { static: false }) contactFormTitle:
    | TemplateRef<any>
    | any;

  constructor(
    private contactStore: Store<ContactState>,
    private userStore: Store<UserState>,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private activateRouter: ActivatedRoute,
    private router: Router,
    private portalNavigationService: PortalNavigationService
  ) {}

  ngOnInit(): void {
    this.getRouteData();
    this.componentPortalContactFilter = new ComponentPortal(
      ContactFiltersComponent
    );
    this.setFilterPortal();
    this.getFavorisContacts();
    this.loadFavorisContacts();
    this.getContacts();
  }

  ngOnDestroy(): void {
    this.componentPortalContactFilter.detach();
  }

  getRouteData(): void {
    this.data$ = this.activateRouter.data;
  }

  getContacts(): void {
    this.contacts$ = this.contactStore.select(fromContactSelector.contacts);
  }

  getFavorisContacts(): void {
    this.favoris$ = this.userStore.select(fromUserSelector.favorisContacts);
  }

  loadFavorisContacts(): void {
    this.userStore.dispatch(fromUserAction.loadUserFavorisContacts());
  }

  loadContacts(): void {
    this.contactStore.dispatch(fromContactAction.loadContacts());
  }

  loadFilters(): void {
    this.contactStore.dispatch(fromContactAction.loadContactsFilters());
  }

  setFilterPortal(): void {
    this.portalNavigationService.setPortal(this.componentPortalContactFilter);
  }

  onNewContactForm(): void {
    const modal = this.modal.create({
      nzContent: ContactFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.contactFormTitle,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });

    const instance = modal.getContentComponent();
    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
    instance.add.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        this.onAdd(event);
      }
    });
    instance.update.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        this.onUpdate(event);
      }
    });
  }

  onAdd(contact: Contact): void {
    this.contactStore.dispatch(fromContactAction.addContact({ contact }));
  }
  onUpdate(contact: Contact): void {}
}
