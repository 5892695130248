import { takeUntil } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { of, Observable, Subject } from 'rxjs';
import { OperationSmallId } from 'src/app/components/operation/store/operation.model';
import { ContactState } from './../../store/contact.reducer';
import * as fromContactAction from './../../store/contact.actions';
import * as fromContactSelector from './../../store/contact.selectors';

import { ContactId } from './../../store/contact.model';

@Component({
  selector: 'app-contact-operations',
  templateUrl: './contact-operations.component.html',
  styleUrls: ['./contact-operations.component.scss'],
})
export class ContactOperationsComponent implements OnInit, OnDestroy {
  contact$: Observable<ContactId | any> = of(null);
  operations$: Observable<any> = of(null);
  subscribe = new Subject();
  identity: string = 'contact';
  constructor(private contactStore: Store<ContactState>) {}

  ngOnInit(): void {
    this.getContact();
    this.getOperations();
    this.loadOperations();
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getContact(): void {
    this.contact$ = this.contactStore.select(fromContactSelector.contact);
  }

  getOperations(): void {
    this.operations$ = this.contactStore.select(fromContactSelector.operations);
  }

  loadOperations(): void {
    this.contact$
      .pipe(takeUntil(this.subscribe))
      .subscribe((contact: ContactId) => {
        if (!contact) {
          return;
        } else {
          const contactId: string = contact.id;
          this.contactStore.dispatch(
            fromContactAction.loadOperations({ contactId })
          );
        }
      });
  }

  onAdd(event: any, contact: ContactId): void {
    this.contactStore.dispatch(
      fromContactAction.addOperation({
        contact: contact,
        operation: event.operation,
        intervention: event.event,
      })
    );
  }
  onDelete(operation: OperationSmallId, contact: ContactId): void {
    this.contactStore.dispatch(
      fromContactAction.deleteOperation({ contact, operation })
    );
  }
}
