import { Update } from '@ngrx/entity';
import { EntiteId } from './../../store/entite.model';
import { Store } from '@ngrx/store';
import { EntiteState } from './../../store/entite.reducer';
import * as fromEntiteAction from './../../store/entite.actions';
import * as fromEntiteSelector from './../../store/entite.selectors';

import { Component, Input, OnInit } from '@angular/core';
import { of, Observable, BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-entite-metiers',
  templateUrl: './entite-metiers.component.html',
  styleUrls: ['./entite-metiers.component.scss'],
})
export class EntiteMetiersComponent implements OnInit {
  title$ = new BehaviorSubject<string | any>('Métiers');
  tab$ = new BehaviorSubject<string | any>('Métiers');
  type$ = new BehaviorSubject<string | any>('Métiers');
  @Input() entite: EntiteId | any = null;
  constructor(private entiteStore: Store<EntiteState>) {}

  ngOnInit(): void {}

  onSelectTab(tab: string, type: string): void {
    if (tab === 'Nouveau') return;

    this.tab$.next(tab);
    this.type$.next(type);
  }

  onUpdate(event: any, entite: EntiteId): void {
    if (!event || !entite) return;
    const { metiers, metiersMap } = event;

    const nextEntite: Update<EntiteId> = {
      id: entite.id,
      changes: {
        ...entite,
        custom: {
          ...entite.custom,
          metiers,
          metiersMap,
        },
      },
    };

    this.entiteStore.dispatch(
      fromEntiteAction.updateEntite({ entite: nextEntite })
    );
    this.onSelectTab('Métiers', '');
  }

  onDelete(metier: any, entite: EntiteId): void {
    if (!metier || !entite) return;
    const metiers = entite.custom.metiers.filter(
      (el: any) => el.code !== metier.code
    );
    const metiersMap = this.transforToMap(metiers);

    const nextEntite: Update<EntiteId> = {
      id: entite.id,
      changes: {
        ...entite,
        custom: {
          ...entite.custom,
          metiers,
          metiersMap,
        },
      },
    };

    this.entiteStore.dispatch(
      fromEntiteAction.updateEntite({ entite: nextEntite })
    );
    this.onSelectTab('Métiers', '');
  }

  transforToMap(array: any[]): any {
    if (!array?.length) {
      return null;
    }

    if (array?.length) {
      const reduce = array.reduce((acc, cur) => {
        acc[cur['libelle']] = cur;
        return acc;
      }, {});

      return reduce;
    }
  }
}
