import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  OperationCadrageState,
  operationCadragesFeatureKey,
  selectAll,
} from './operation-cadrage.reducer';

export const selectOperationCadrageState =
  createFeatureSelector<OperationCadrageState>(operationCadragesFeatureKey);

export const cadrages = createSelector(selectOperationCadrageState, selectAll);

export const cadrage = createSelector(
  selectOperationCadrageState,
  (state: OperationCadrageState) => state.cadrage
);

export const loading = createSelector(
  selectOperationCadrageState,
  (state: OperationCadrageState) => state.loading
);
export const error = createSelector(
  selectOperationCadrageState,
  (state: OperationCadrageState) => state.error
);
