import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { OperationParticipants } from './operation-participants.model';
import * as OperationParticipantsActions from './operation-participants.actions';

export const operationParticipantsesFeatureKey = 'operationParticipantses';

export interface State extends EntityState<OperationParticipants> {
  // additional entities state properties
}

export const adapter: EntityAdapter<OperationParticipants> = createEntityAdapter<OperationParticipants>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(OperationParticipantsActions.addOperationParticipants,
    (state, action) => adapter.addOne(action.operationParticipants, state)
  ),
  on(OperationParticipantsActions.upsertOperationParticipants,
    (state, action) => adapter.upsertOne(action.operationParticipants, state)
  ),
  on(OperationParticipantsActions.addOperationParticipantss,
    (state, action) => adapter.addMany(action.operationParticipantss, state)
  ),
  on(OperationParticipantsActions.upsertOperationParticipantss,
    (state, action) => adapter.upsertMany(action.operationParticipantss, state)
  ),
  on(OperationParticipantsActions.updateOperationParticipants,
    (state, action) => adapter.updateOne(action.operationParticipants, state)
  ),
  on(OperationParticipantsActions.updateOperationParticipantss,
    (state, action) => adapter.updateMany(action.operationParticipantss, state)
  ),
  on(OperationParticipantsActions.deleteOperationParticipants,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(OperationParticipantsActions.deleteOperationParticipantss,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(OperationParticipantsActions.loadOperationParticipantss,
    (state, action) => adapter.setAll(action.operationParticipantss, state)
  ),
  on(OperationParticipantsActions.clearOperationParticipantss,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
