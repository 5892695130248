import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ContactState, contactsFeatureKey, selectAll } from './contact.reducer';

export const selectContactState =
  createFeatureSelector<ContactState>(contactsFeatureKey);

export const contacts = createSelector(selectContactState, selectAll);

export const contact = createSelector(
  selectContactState,
  (state: ContactState) => state.contact
);
export const statistiques = createSelector(
  selectContactState,
  (state: ContactState) => state.statistiques
);

export const filters = createSelector(
  selectContactState,
  (state: ContactState) => state.filters
);

export const suivis = createSelector(
  selectContactState,
  (state: ContactState) => state.suivis
);
export const suivi = createSelector(
  selectContactState,
  (state: ContactState) => state.suivi
);

export const documents = createSelector(
  selectContactState,
  (state: ContactState) => state.documents
);

export const document = createSelector(
  selectContactState,
  (state: ContactState) => state.document
);

export const operations = createSelector(
  selectContactState,
  (state: ContactState) => state.operations
);
export const operation = createSelector(
  selectContactState,
  (state: ContactState) => state.operation
);

export const entites = createSelector(
  selectContactState,
  (state: ContactState) => state.entites
);

export const metiers = createSelector(
  selectContactState,
  (state: ContactState) => state.metiers
);

export const loading = createSelector(
  selectContactState,
  (state: ContactState) => state.loading
);

export const error = createSelector(
  selectContactState,
  (state: ContactState) => state.error
);
