import { QueryOperationEntitesService } from './../../service/query-operation-entites.service';
import { SharedModule } from './../../shared/shared.module';
import { ContentsModule } from 'src/app/contents/contents.module';
import { OperationEntitesSelectorsComponent } from './components/operation-entites-selectors/operation-entites-selectors.component';
import { OperationEntitesItemComponent } from './components/operation-entites-item/operation-entites-item.component';
import { OperationEntitesFiltersComponent } from './components/operation-entites-filters/operation-entites-filters.component';
import { OperationEntitesComponent } from './components/operation-entites/operation-entites.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromOperationEntites from './store/operation-entites.reducer';
import { EffectsModule } from '@ngrx/effects';
import { OperationEntitesEffects } from './store/operation-entites.effects';
import { OperationEntitesService } from 'src/app/service/operation-entites.service';
import { EntiteModule } from '../entite/entite.module';
import { NoResultModule } from 'src/app/contents/components/no-result/no-result.module';
import { ButtonsModule } from 'src/app/contents/components/buttons/buttons.module';
import { HeaderModule } from 'src/app/contents/components/header-title/header.module';
import { LayoutModule } from 'src/app/contents/components/layout/layout.module';
import { SuiviModule } from 'src/app/features/suivi/suivi.module';
import { AvatarModule } from 'src/app/features/avatar/avatar.module';
import { DocumentModule } from 'src/app/features/document/document.module';
import { UiModule } from 'src/app/ui/ui/ui.module';

@NgModule({
  declarations: [
    OperationEntitesComponent,
    OperationEntitesFiltersComponent,
    OperationEntitesItemComponent,
    OperationEntitesSelectorsComponent,
  ],
  imports: [
    CommonModule,
    ContentsModule,
    SharedModule,
    EntiteModule,
    NoResultModule,
    HeaderModule,
    ButtonsModule,
    LayoutModule,
    AvatarModule,
    SuiviModule,
    DocumentModule,
    UiModule,
    StoreModule.forFeature(
      fromOperationEntites.operationEntitesFeatureKey,
      fromOperationEntites.reducer
    ),
    EffectsModule.forFeature([OperationEntitesEffects]),
  ],
  exports: [
    OperationEntitesComponent,
    OperationEntitesFiltersComponent,
    OperationEntitesItemComponent,
    OperationEntitesSelectorsComponent,
  ],
  providers: [OperationEntitesService, QueryOperationEntitesService],
})
export class OperationEntitesModule {}
