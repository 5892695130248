import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-message-notifications',
  templateUrl: './message-notifications.component.html',
  styleUrls: ['./message-notifications.component.scss'],
})
export class MessageNotificationsComponent implements OnInit {
  noDataTitle: string = 'Aucune notification';
  constructor() {}

  ngOnInit(): void {}
}
