import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterListComponent } from './components/filter-list/filter-list.component';
import { FilterItemComponent } from './components/filter-item/filter-item.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ButtonsModule } from '../buttons/buttons.module';

@NgModule({
  declarations: [FilterListComponent, FilterItemComponent],
  exports: [FilterListComponent, FilterItemComponent],
  imports: [CommonModule, SharedModule, ButtonsModule],
})
export class FiltersModule {}
