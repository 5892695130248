import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParticipantNotesComponent } from './components/participant-notes/participant-notes.component';



@NgModule({
  declarations: [
    ParticipantNotesComponent
  ],
  imports: [
    CommonModule
  ]
})
export class ParticipantNotesModule { }
