<nz-card [nzTitle]="diagnosticCardTitle">
  <nz-form nz-form [formGroup]="ressourceForm" nzLayout="vertical">
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="12">
        <app-check-card
          [title]="'Surendettement sans dossier'"
          [isActive]="ressourceForm.value.surendettementSansDossier"
          [definition]="surendettementSansDossierTitle"
          [height]="'60px'"
          [fontSize]="'14px'"
          (select)="checkboxChanges($event, 'surendettementSansDossier')"
        ></app-check-card>
      </nz-col>
      <nz-col nzSpan="12">
        <app-check-card
          [title]="'Surendettement avec dossier'"
          [isActive]="ressourceForm.value.surendettementAvecDossier"
          [definition]="surendettementAvecDossierTitle"
          [height]="'60px'"
          [fontSize]="'14px'"
          (select)="checkboxChanges($event, 'surendettementAvecDossier')"
        ></app-check-card>
      </nz-col>
    </nz-row>

    <nz-space nzDirection="vertical" nzSize="small"></nz-space>
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="12">
        <app-check-card
          [title]="'Besoins primaires'"
          [isActive]="ressourceForm.value.besoinsPrimaires"
          [definition]="besoinPrimaireTitle"
          (select)="checkboxChanges($event, 'besoinsPrimaires')"
          [height]="'60px'"
          [fontSize]="'14px'"
        ></app-check-card>
      </nz-col>
      <nz-col nzSpan="12">
        <app-check-card
          [title]="'Gestion budgetaire'"
          [isActive]="ressourceForm.value.saitGererSonBudget"
          [definition]="saitGererSonBudgetTitle"
          (select)="checkboxChanges($event, 'saitGererSonBudget')"
          [height]="'60px'"
          [fontSize]="'14px'"
        ></app-check-card>
      </nz-col>
    </nz-row>

    <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="24">
        <nz-form-item>
          <nz-form-control>
            <label nz-checkbox formControlName="isFrein">{{
              isFreinTitle
            }}</label>
          </nz-form-control>
        </nz-form-item>
      </nz-col>
    </nz-row>
  </nz-form>
</nz-card>

<ng-template #diagnosticCardTitle>
  <app-header-title [icon]="'account_balance_wallet'"></app-header-title>
</ng-template>
