import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  ParticipantDocumentState,
  participantDocumentsFeatureKey,
  selectAll,
} from './participant-document.reducer';

export const selectParticipantDocumentsState =
  createFeatureSelector<ParticipantDocumentState>(
    participantDocumentsFeatureKey
  );

export const documents = createSelector(
  selectParticipantDocumentsState,
  selectAll
);
export const document = createSelector(
  selectParticipantDocumentsState,
  (state: ParticipantDocumentState) => state.document
);
export const loading = createSelector(
  selectParticipantDocumentsState,
  (state: ParticipantDocumentState) => state.loading
);

export const error = createSelector(
  selectParticipantDocumentsState,
  (state: ParticipantDocumentState) => state.error
);
