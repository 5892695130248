import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-button-action-new',
  templateUrl: './button-action-new.component.html',
  styleUrls: ['./button-action-new.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonActionNewComponent implements OnInit {
  @Input() items: any[] = [];
  @Output() selected = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onSelect(event: string): void {
    this.selected.emit(event);
  }
}
