import { NzModalService } from 'ng-zorro-antd/modal';
import { ContactsSelectComponent } from './../../../../contents/components/contacts-select/components/contacts-select.component';
import { OperationId } from './../../../operation/store/operation.model';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject, of, Subject } from 'rxjs';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
  UntypedFormArray,
  Validators,
} from '@angular/forms';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewContainerRef,
} from '@angular/core';
import { v4 as uuidV4 } from 'uuid';
import { fromUnixTime } from 'date-fns';
import { uniqBy } from 'lodash';

@Component({
  selector: 'app-operation-cadrage-pilotage-form',
  templateUrl: './operation-cadrage-pilotage-form.component.html',
  styleUrls: ['./operation-cadrage-pilotage-form.component.scss'],
})
export class OperationCadragePilotageFormComponent implements OnInit {
  subscribe = new Subject();
  isAdd: boolean = true;
  isUpdate: boolean = false;
  today: Date = new Date(Date.now());

  @Input() updateItem$ = new BehaviorSubject<any>(null);
  @Input() operation: OperationId | any = null;
  @Input() entites: any = null;

  @Output() add = new EventEmitter<any>(false);
  @Output() update = new EventEmitter<any>(false);
  @Output() cancel = new EventEmitter<boolean>(false);
  modalWidth: number = 650;
  types: string[] = [
    'Risques politiques',
    'Risques marché',
    'Risque fournisseurs',
    'Risques environnement',
    'Risque client',
    'Management du projet',
    'Autre',
  ];

  itemform: UntypedFormGroup = this.fb.group({});
  title: UntypedFormControl = new UntypedFormControl('', Validators.required);
  id: UntypedFormControl = new UntypedFormControl(uuidV4(), Validators.required);
  type: UntypedFormControl = new UntypedFormControl('');
  description: UntypedFormControl = new UntypedFormControl('');
  intervenants: UntypedFormArray = this.fb.array([]);
  date: UntypedFormControl = new UntypedFormControl('');

  constructor(
    private fb: UntypedFormBuilder,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.formCreate();
    this.formSet();
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  formCreate(): void {
    this.itemform = this.fb.group({
      id: this.id,
      title: this.title,
      type: this.type,
      description: this.description,
      date: this.date,
      intervenants: this.intervenants,
    });
  }

  formSet(): void {
    this.updateItem$.pipe(takeUntil(this.subscribe)).subscribe((val): void => {
      if (!val) {
        this.isAdd = true;
        this.isUpdate = false;
        return;
      }
      if (val) {
        this.updateForm(val);
      }
    });
  }

  updateForm(val: any): void {
    this.itemform.patchValue({
      id: val?.id,
      title: val?.title,
      type: val?.type,
      description: val?.description,
      date: val?.date ? fromUnixTime(val?.date['seconds']) : null,
      intervenants: val?.intervenants ? val.intervenants : [],
    });
    this.isAdd = false;
    this.isUpdate = true;

    if (!val?.intervenants?.length) {
      return;
    }

    this.addIntervenant(val.intervenants);
  }

  onNewIntervenant(operation: OperationId, entites?: any): void {
    const modal = this.modal.create({
      nzContent: ContactsSelectComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: `Ajouter un intervenant du pilotage`,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });
    const instance = modal.getContentComponent();
    instance.cancel.subscribe((event: any): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
    instance.select.subscribe((event: any): any => {
      if (!event) {
        return null;
      } else {
        this.addIntervenant(event);
        modal.close();
      }
    });
    instance.operation$ = of(operation);
    instance.entites$ = of(entites);
  }

  addIntervenant(items: any[]): void {
    if (!items?.length) {
      return;
    }

    const uniq = uniqBy(items, 'id');

    uniq.forEach((el) => {
      const newIntervant = this.fb.group({
        ...el,
      });
      this.intervenants.push(newIntervant);
    });
  }

  removeIntervenant(index: number): void {
    this.intervenants.removeAt(index);
  }

  get intevenantsArray(): UntypedFormArray {
    return this.itemform.get('intervenants') as UntypedFormArray;
  }

  onAdd(): void {
    const form = this.itemform.value;
    this.add.emit(form);
    this.itemform.reset();
  }

  onUpdate(): void {
    const form = this.itemform.value;
    this.update.emit(form);
    this.itemform.reset();
  }

  onCancel(): void {
    this.cancel.emit(true);
  }
}
