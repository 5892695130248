<nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
  <nz-col nzSpan="4"></nz-col>
  <nz-col nzSpan="16">
    <app-search-bar
      [filter]="true"
      [cleaner]="cleaner"
      (clear)="onClear()"
      [placeholder]="'Rechercher le service'"
      (searching)="filtersByTitle($event)"
    ></app-search-bar>
  </nz-col>
  <nz-col nzSpan="4">
    <button nz-button nzShape="circle" nzType="text"></button>
  </nz-col>
</nz-row>
