import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-prescripteur-form',
  templateUrl: './prescripteur-form.component.html',
  styleUrls: ['./prescripteur-form.component.scss']
})
export class PrescripteurFormComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
