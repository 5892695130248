import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { GeoAdresse } from './geo-adresse.model';

export const loadGeoAdresses = createAction(
  '[GeoAdresse/Component] Load GeoAdresses',
  props<{
    input: string;
    limit?: number;
    city?: string;
    postcode?: number | string;
  }>()
);

export const loadGeoAdressesSuccess = createAction(
  '[GeoAdresse/Effect] Load GeoAdresses success',
  props<{ adresses: GeoAdresse[] }>()
);

export const loadGeoAdressesFailure = createAction(
  '[GeoAdresse/Effect] Load GeoAdresses failure',
  props<{ error: any }>()
);

export const searchGeoAdresses = createAction(
  '[GeoAdresse/Component] Search GeoAdresses',
  props<{ input: string; limit?: number }>()
);

export const searchGeoAdressesSuccess = createAction(
  '[GeoAdresse/Effect] Search GeoAdresses success',
  props<{ adresses: any[] }>()
);

export const searchGeoAdressesFailure = createAction(
  '[GeoAdresse/Effect] Search GeoAdresses failure',
  props<{ error: any }>()
);
