import { OperationNoteParticipantModule } from './../operation-note-participant/operation-note-participant.module';
import { OperationNoteActionModule } from './../operation-note-action/operation-note-action.module';
import { OperationNoteExportService } from './../../service/operation-note-export.service';
import { ContentsModule } from './../../contents/contents.module';
import { OperationNoteService } from './../../service/operation-note.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OperationNoteListComponent } from './components/operation-note-list/operation-note-list.component';
import { OperationNoteTableComponent } from './components/operation-note-table/operation-note-table.component';
import { OperationNoteFormComponent } from './components/operation-note-form/operation-note-form.component';
import { OperationNoteItemComponent } from './components/operation-note-item/operation-note-item.component';
import { OperationNoteCardComponent } from './components/operation-note-card/operation-note-card.component';
import { StoreModule } from '@ngrx/store';
import * as fromOperationNote from './store/operation-note.reducer';
import { EffectsModule } from '@ngrx/effects';
import { OperationNoteEffects } from './store/operation-note.effects';
import { OperationNoteExportComponent } from './components/operation-note-export/operation-note-export.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { OperationNoteSubtitleModule } from '../operation-note-subtitle/operation-note-subtitle.module';
import { OperationNoteSujetModule } from '../operation-note-sujet/operation-note-sujet.module';
import { OperationNoteDocumentModule } from '../operation-note-document/operation-note-document.module';
import { OperationNoteItemHeaderComponent } from './components/operation-note-item-header/operation-note-item-header.component';
import { OperationNoteExportSujetsComponent } from './components/operation-note-export-sujets/operation-note-export-sujets.component';
import { OperationNoteExportParticipantsComponent } from './components/operation-note-export-participants/operation-note-export-participants.component';
import { OperationNoteExportHeaderComponent } from './components/operation-note-export-header/operation-note-export-header.component';
import { NoResultModule } from 'src/app/contents/components/no-result/no-result.module';
import { CoordonneesModule } from 'src/app/contents/components/coordonnees/coordonnees.module';
import { CommunesFranceModule } from 'src/app/contents/components/communes-france/communes-france.module';
import { ButtonsModule } from 'src/app/contents/components/buttons/buttons.module';
import { HeaderModule } from 'src/app/contents/components/header-title/header.module';
import { AvatarModule } from 'src/app/features/avatar/avatar.module';

@NgModule({
  declarations: [
    OperationNoteListComponent,
    OperationNoteTableComponent,
    OperationNoteFormComponent,
    OperationNoteItemComponent,
    OperationNoteCardComponent,
    OperationNoteExportComponent,
    OperationNoteItemHeaderComponent,
    OperationNoteExportSujetsComponent,
    OperationNoteExportParticipantsComponent,
    OperationNoteExportHeaderComponent,
  ],
  imports: [
    CommonModule,
    ContentsModule,
    SharedModule,
    OperationNoteSubtitleModule,
    OperationNoteSujetModule,
    OperationNoteActionModule,
    OperationNoteDocumentModule,
    OperationNoteParticipantModule,
    NoResultModule,
    CoordonneesModule,
    HeaderModule,
    CommunesFranceModule,
    ButtonsModule,
    AvatarModule,
    StoreModule.forFeature(
      fromOperationNote.operationNotesFeatureKey,
      fromOperationNote.reducer
    ),
    EffectsModule.forFeature([OperationNoteEffects]),
  ],
  exports: [
    OperationNoteListComponent,
    OperationNoteTableComponent,
    OperationNoteFormComponent,
    OperationNoteItemComponent,
    OperationNoteCardComponent,
  ],
  providers: [OperationNoteService, OperationNoteExportService],
})
export class OperationNoteModule {}
