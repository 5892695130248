import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Component({
  selector: 'app-missions-item',
  templateUrl: './missions-item.component.html',
  styleUrls: ['./missions-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MissionsItemComponent implements OnInit, OnChanges {
  suivisSource$: Observable<any[]> = of([]);
  documentsSource$: Observable<any[]> = of([]);
  missionsSource$: Observable<any[]> = of([]);
  actionsSource$: Observable<any[]> = of([]);
  participantsSource$: Observable<any[]> = of([]);

  @Input() mission$: Observable<any> = of(false);
  @Input() planing$ = new BehaviorSubject<any>(null);
  @Input() updateLoading$: Observable<boolean> = of(false);
  @Output() back = new EventEmitter<boolean>();
  @Output() update = new EventEmitter<any>();
  @Output() export = new EventEmitter<any>();
  @Output() delete = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}

  onBack(): void {
    this.back.emit(true);
  }

  onUpdateForm(middion: any): void {}

  dropdownSelect(item: string, mission: any): void {
    switch (item) {
      case 'update':
        break;
      case 'exportPDF':
        this.onExportAsPdf(mission);
        break;
      case 'delete':
        this.onDelete(mission);
        break;
      default:
        break;
    }
  }

  onUpdate(item: any, mission: any): void {
    if (!mission) return;
    const nextMission = {
      ...mission,
      ...item,
    };

    this.update.emit(nextMission);
  }

  onUpdateHours(hours: any, mission: any): void {
    if (!mission || !hours) return;

    const nextMission = {
      ...mission,
      hours: hours,
    };

    this.update.emit(nextMission);
  }

  onExportAsPdf(mission: any): void {
    if (!mission) return;
    this.export.emit(mission);
  }

  onDelete(mission: any): void {
    if (!mission) return;
    this.delete.emit(mission);
  }

  onCancel(): void {
    return;
  }
}
