import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  TemplateRef,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import * as XLSX from 'xlsx';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-excel-json-viewer',
  templateUrl: './excel-json-viewer.component.html',
  styleUrls: ['./excel-json-viewer.component.scss'],
})
export class ExcelJsonViewerComponent implements OnInit, OnChanges {
  title: string = 'Excel to Json';
  selectionMessage: string = 'Ajouter votre tableur';
  JSON = new BehaviorSubject<any>(null);
  convertedJSON!: string;

  @Input() render: TemplateRef<any> | any;
  @Output() import = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {}

  fileUpload(event: any): void {
    const selectedFile = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsBinaryString(selectedFile);
    fileReader.onload = (event) => {
      let binaryData = event?.target?.result;
      let workbook = XLSX.read(binaryData, { type: 'binary' });

      const { SheetNames } = workbook;
      SheetNames.forEach((sheet) => {
        const data = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
        this.convertedJSON = JSON.stringify(data, undefined, 4);

        this.JSON.next(this.convertedJSON);
        this.onAdd(data);
      });
    };
  }

  onAdd(data: any): any {
    if (!data) return null;

    this.import.emit(data);
  }
}
