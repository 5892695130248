import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ModuleState, modulesFeatureKey, selectAll } from './module.reducer';

export const selectModuleState =
  createFeatureSelector<ModuleState>(modulesFeatureKey);

export const modules = createSelector(selectModuleState, selectAll);

export const modulesAll = createSelector(
  selectModuleState,
  (state: ModuleState) => state.modules
);

export const module = createSelector(
  selectModuleState,
  (state: ModuleState) => state.module
);

export const missions = createSelector(
  selectModuleState,
  (state: ModuleState) => state.missions
);

export const actions = createSelector(
  selectModuleState,
  (state: ModuleState) => state.actions
);

export const suivis = createSelector(
  selectModuleState,
  (state: ModuleState) => state.suivis
);

export const documents = createSelector(
  selectModuleState,
  (state: ModuleState) => state.documents
);

export const utils = createSelector(
  selectModuleState,
  (state: ModuleState) => state.utils
);

export const types = createSelector(
  selectModuleState,
  (state: ModuleState) => state.types
);

export const error = createSelector(
  selectModuleState,
  (state: ModuleState) => state.error
);
