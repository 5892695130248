import { createAction, props } from '@ngrx/store';

import { EntiteSMALLid } from 'src/app/components/entite/store/entite.model';

export const loadEntiteSelects = createAction(
  '[EntiteSelect/COMPONENT] Load EntiteSelects',
  props<{ filter: string; filterType: string }>()
);
export const loadEntiteSelectsSuccess = createAction(
  '[EntiteSelect/API] Load EntiteSelects',
  props<{ entites: EntiteSMALLid[] }>()
);
export const loadEntiteSelectsFailure = createAction(
  '[EntiteSelect/API] Load EntiteSelects',
  props<{ error: any }>()
);

export const clearEntiteSelects = createAction(
  '[EntiteSelect/API] Clear EntiteSelects'
);
