import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'any',
})
export class QueryMetiersAppelationDetailsService extends Query<Response> {
  document = gql`
    query AppelationDetail($code: String!) {
      appelationDetail(code: $code) {
        code
        libelle
        libelleCourt
        particulier
        metier {
          libelle
          particulier
          riasecMajeur
          riasecMineur
          id
          domaineProfessionnel {
            grandDomaine {
              code
              libelle
            }
            libelle
            code
          }
          codeIsco
          code
          informations {
            definition
            acces
            accesArray
            accesSize
            condition
          }
        }
        competencesDeBase {
          code
          libelle
          noeudCompetence {
            code
            libelle
            racineCompetence {
              libelle
              code
            }
          }
          competenceCle
          typeCompetence
          riasecMineur
          riasecMajeur
        }
        competencesDeBaseSize
        competencesCleDeBaseSize
        partCompetencesDeBaseSize
        competencesDeBaseType {
          SavoirFaire {
            code
            libelle
            competenceCle
            noeudCompetence {
              racineCompetence {
                libelle
                code
              }
              libelle
              code
            }
            typeCompetence
            riasecMineur
            riasecMajeur
          }
          Savoir {
            code
            libelle
            competenceCle
            noeudCompetence {
              racineCompetence {
                libelle
                code
              }
              libelle
              code
            }
            typeCompetence
            riasecMineur
            riasecMajeur
          }
        }
        groupesCompetencesSpecifiques {
          competences {
            riasecMajeur
            riasecMineur
            typeCompetence
            competenceCle
            code
            libelle
            noeudCompetence {
              libelle
              code
              racineCompetence {
                libelle
                code
              }
            }
          }
        }
        competencesSpecifiquesSize
        competencesCleSpecifiquesSize
        partCompetencesSpeficiquesSize
        environnementsTravail {
          code
          libelle
          typeEnvironnementTravail
        }
      }
    }
  `;
}
