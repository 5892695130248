import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { EntiteSuivi } from './entite-suivi.model';

//LOAD ALL
export const loadEntiteSuivis = createAction(
  '[EntiteSuivi/API] Load EntiteSuivis',
  props<{ id: string }>()
);
export const loadEntiteSuivisSuccess = createAction(
  '[EntiteSuivi/API] Load EntiteSuivis Success',
  props<{ suivis: any[] }>()
);
export const loadEntiteSuivisFailure = createAction(
  '[EntiteSuivi/API] Load EntiteSuivis Failure',
  props<{ error: any }>()
);

//LOAD ONE
export const loadEntiteSuivi = createAction(
  '[EntiteSuivi/API] Load EntiteSuivi ',
  props<{ entiteId: string; id: string }>()
);
export const loadEntiteSuiviSuccess = createAction(
  '[EntiteSuivi/API] Load EntiteSuivi Success',
  props<{ suivi: any }>()
);
export const loadEntiteSuiviFailure = createAction(
  '[EntiteSuivi/API] Load EntiteSuivi Failure',
  props<{ error: any }>()
);

//ADD ONE
export const addEntiteSuivi = createAction(
  '[EntiteSuivi/API] Add EntiteSuivi',
  props<{ id: string; suivi: any }>()
);
export const addEntiteSuiviSuccess = createAction(
  '[EntiteSuivi/API] Add EntiteSuivi Success',
  props<{ suivi: any }>()
);
export const addEntiteSuiviFailure = createAction(
  '[EntiteSuivi/API] Add EntiteSuivi Failure',
  props<{ error: any }>()
);

//UPDATE
export const updateEntiteSuivi = createAction(
  '[EntiteSuivi/API] Update EntiteSuivi',
  props<{ id: string; suivi: Update<EntiteSuivi> }>()
);
export const updateEntiteSuiviSuccess = createAction(
  '[EntiteSuivi/API] Update EntiteSuivi Success',
  props<{ success: string }>()
);
export const updateEntiteSuiviFailure = createAction(
  '[EntiteSuivi/API] Update EntiteSuivi Failure',
  props<{ error: any }>()
);

//DELETE ONE
export const deleteEntiteSuivi = createAction(
  '[EntiteSuivi/API] Delete EntiteSuivi',
  props<{ entiteId: string; id: string }>()
);
export const deleteEntiteSuiviSuccess = createAction(
  '[EntiteSuivi/API] Delete EntiteSuivi Success',
  props<{ success: string }>()
);
export const deleteEntiteSuiviFailure = createAction(
  '[EntiteSuivi/API] Delete EntiteSuivi Failure',
  props<{ error: any }>()
);

//DELETE ALL
export const deleteEntiteSuivis = createAction(
  '[EntiteSuivi/API] Delete EntiteSuivis',
  props<{ id: string; ids: string[] }>()
);
export const deleteEntiteSuivisSuccess = createAction(
  '[EntiteSuivi/API] Delete EntiteSuivis Success',
  props<{ success: string }>()
);
export const deleteEntiteSuivisFailure = createAction(
  '[EntiteSuivi/API] Delete EntiteSuivis Failure',
  props<{ error: any }>()
);

//CLEAR
export const clearEntiteSuivis = createAction(
  '[EntiteSuivi/API] Clear EntiteSuivis'
);
