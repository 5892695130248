import {
  NzNotificationPlacement,
  NzNotificationService,
} from 'ng-zorro-antd/notification';
import {
  Event,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { SideNavigation } from './../../store/side-navigation.model';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { NzMenuThemeType } from 'ng-zorro-antd/menu';
import { SideNavigationState } from '../../store/side-navigation.reducer';

import { Store } from '@ngrx/store';
import { CalculatriceComponent } from 'src/app/contents/components/calculatrice/calculatrice.component';
import { TicketsComponent } from './../../../tickets/components/tickets/tickets.component';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';

import { BehaviorSubject, map, Observable, of, Subject, takeUntil } from 'rxjs';
import { OperationState } from 'src/app/components/operation/store/operation.reducer';
import * as fromOperationSelector from 'src/app/components/operation/store/operation.selectors';

import { fade, listAnimation } from 'src/app/app-animation';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ParticipantState } from 'src/app/components/participant/store/participant.reducer';
import * as fromParticipantSelector from 'src/app/components/participant/store/participant.selectors';
import { EntiteState } from 'src/app/components/entite/store/entite.reducer';
import * as fromEntiteSelector from 'src/app/components/entite/store/entite.selectors';

import { ContactState } from 'src/app/components/contact/store/contact.reducer';
import * as fromContactSelector from 'src/app/components/contact/store/contact.selectors';

import { DepartementState } from 'src/app/components/departement/store/departement.reducer';
import * as fromDepartementSelector from 'src/app/components/departement/store/departement.selectors';
import { OrganisationState } from 'src/app/components/organisation/store/organisation.reducer';
import * as fromOrganisationSelector from 'src/app/components/organisation/store/organisation.selectors';
import { messageState } from 'src/app/components/messages/store/message.reducer';

import * as fromMessageSelector from 'src/app/components/messages/store/message.selectors';
import * as fromMessageAction from 'src/app/components/messages/store/message.actions';

import { TodoState } from 'src/app/components/todos/store/todo.reducer';
import * as fromTodoAction from 'src/app/components/todos/store/todo.actions';
import { NavigationState } from 'src/app/components/navigation/store/navigation.reducer';
import * as fromNavigationAction from 'src/app/components/navigation/store/navigation.actions';
import * as fromNavigationSelector from 'src/app/components/navigation/store/navigation.selectors';

import { FavoriesComponent } from 'src/app/components/favories/components/favories/favories.component';
import { TodosComponent } from 'src/app/components/todos/components/todos/todos.component';
import { MessagesScreenComponent } from 'src/app/components/messages/components/messages-screen/messages-screen.component';

@Component({
  selector: 'app-side-navigation',
  templateUrl: './side-navigation.component.html',
  styleUrls: ['./side-navigation.component.scss'],
  animations: [fade, listAnimation],
})
export class SideNavigationComponent implements OnInit, OnChanges {
  user$: Observable<any> = of(null);
  navigation$: Observable<any> = of(null);
  currentPage$ = new BehaviorSubject<string>('');
  currentUrl$ = new BehaviorSubject<string>('');
  subscribe = new Subject();
  tooltipDelay: number = 0.5;
  logo: string = '';
  tooltipMouseEnterDelay: number = 1;
  space: number = 600;
  currentRoute: any = null;
  titleCalculator: string = 'Calculatrice';
  badgeOffset: [number, number] = [10, 10];
  drawerWidth: number = 950;
  modalWidth: number = 1050;
  statistiques$: Observable<any> = of(null);
  messages$: Observable<number | any> = of(10);
  todosSize: number = 0;

  isOperation$: Observable<any> = of(null);
  isParticipant$: Observable<any> = of(null);
  isEntite$: Observable<any> = of(null);
  isContact$: Observable<any> = of(null);
  isDepartement$: Observable<any> = of(null);
  isOrganisation$: Observable<any> = of(null);
  isUser$: Observable<any> = of(null);
  isMission$: Observable<any> = of(null);

  isScreenSmall$: Observable<boolean> = of(false);
  NOTIFICATION_PLACEMENT: NzNotificationPlacement = 'bottomRight';

  @ViewChild('calculatorFormTitle', { static: false })
  calculatorFormTitle: TemplateRef<any> | any;

  titleTicket: string = 'Ticket';
  @ViewChild('ticketFormTitle', { static: false })
  ticketFormTitle: TemplateRef<any> | any;

  @Input() collapse: boolean = true;
  @Input() theme: NzMenuThemeType = 'light';
  @Output() select = new EventEmitter<any>(false);
  @Output() mode = new EventEmitter<any>(false);
  constructor(
    private nvigationStore: Store<SideNavigationState>,
    private drawerService: NzDrawerService,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private userStore: Store<UserState>,
    private router: Router,
    private operationStore: Store<OperationState>,
    private participantStore: Store<ParticipantState>,
    private entiteStore: Store<EntiteState>,
    private contactStore: Store<ContactState>,
    private departementStore: Store<DepartementState>,
    private organisationStore: Store<OrganisationState>,
    private notification: NzNotificationService,
    private breakpointObserver: BreakpointObserver,
    private messageStore: Store<messageState>,
    private todoStore: Store<TodoState>,
    private navigationStore: Store<NavigationState>
  ) {
    //this.breackPointer();
  }

  ngOnInit(): void {
    this.getUser();
    this.onSetOperation();
    this.onSetParticipant();
    this.onSetEntite();
    this.onSetContact();
    this.onSetDepartement();
    //this.onNavigationChanges();
    this.onSetOrganisation();

    this.getCurrentURL();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  breackPointer(): void {
    this.isScreenSmall$ = this.breakpointObserver
      .observe(['(max-width: 1210px)'])
      .pipe(map(({ matches }) => matches));
  }

  getCurrentURL(): void {
    let url: string = this.router.routerState.snapshot.url;
  }

  getUser(): void {
    this.user$ = this.userStore.select(fromUserSelector.user);
  }

  onSelect(event: any): void {
    const navigation: SideNavigation = {
      type: 'side',
      name: event,
    };

    // this.nvigationStore.dispatch(
    //   fromNavigationAction.loadSideNavigation({ navigation })
    // );
  }

  onSetOrganisation(): void {
    this.isOrganisation$ = this.organisationStore.select(
      fromOrganisationSelector.organisation
    );
  }

  onSetOperation(): void {
    this.isOperation$ = this.operationStore.select(
      fromOperationSelector.operation
    );
  }
  onSetParticipant(): void {
    this.isParticipant$ = this.participantStore.select(
      fromParticipantSelector.participant
    );
  }
  onSetEntite(): void {
    this.isEntite$ = this.entiteStore.select(fromEntiteSelector.entite);
  }
  onSetContact(): void {
    this.isContact$ = this.contactStore.select(fromContactSelector.contact);
  }
  onSetDepartement(): void {
    this.isDepartement$ = this.departementStore.select(
      fromDepartementSelector.departement
    );
  }

  onTickets(): void {
    const drawerRef = this.drawerService.create({
      nzTitle: this.ticketFormTitle,
      nzContent: TicketsComponent,
      nzContentParams: {},
      nzWidth: this.drawerWidth,
    });
  }

  onCalculator(): void {
    this.modal.create({
      nzContent: CalculatriceComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.calculatorFormTitle,
    });
  }

  onSwitchMode(event: any): void {
    this.mode.emit(event);
  }

  onNavigationChanges(): void {
    this.currentRoute = '';

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.currentRoute = event.id;
        let url: string[] = event.url.split('/');
        this.currentPage$.next(url[1]);
      }

      if (event instanceof NavigationEnd) {
      }

      if (event instanceof NavigationError) {
        this.notification.error('Erreur', '404', {
          nzDuration: 6000,
          nzPlacement: this.NOTIFICATION_PLACEMENT,
          nzAnimate: true,
        });
      }
    });
  }

  //DRAWER
  getStatistiques(): void {
    this.statistiques$ = this.userStore.select(fromUserSelector.statistiques);
  }

  getMessages(): void {
    this.messages$ = this.messageStore.select(
      fromMessageSelector.selectMessages
    );
  }
  onOpenMessageDrawer(): void {
    this.navigationStore
      .select(fromNavigationSelector.messageDrawer)
      .pipe(takeUntil(this.subscribe))
      .subscribe((event): any => {
        if (!event) {
          return null;
        } else {
          this.onMessage();
        }
      });
  }

  onMessage(): void {
    const drawerRef = this.drawerService.create({
      nzContent: MessagesScreenComponent,
      nzTitle: 'Messages',
      nzContentParams: {},
      nzWidth: 600,
      nzHeight: 200,
    });
    drawerRef.afterClose.subscribe(() => {
      this.navigationStore.dispatch(
        fromNavigationAction.openMessageDrawer({ event: false })
      );
      this.messageStore.dispatch(fromMessageAction.loadMessageClear());
    });
  }

  onTodos(): void {
    const modal = this.modal.create({
      nzContent: TodosComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzComponentParams: {
        modalMode: true,
      },
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });

    const instance = modal.getContentComponent();
    modal.afterClose.subscribe((event) => {
      instance.modalMode = false;
      this.todoStore.dispatch(fromTodoAction.clearTodo());
    });

    instance.closeForm.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
        this.todoStore.dispatch(fromTodoAction.clearTodo());
        return null;
      }
    });
  }

  onFavoris(): void {
    const drawerRef = this.drawerService.create({
      nzTitle: 'Favoris',
      nzContent: FavoriesComponent,
      nzContentParams: {},
      nzWidth: 800,
    });
  }
}
