import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OperationNoteActionListComponent } from './components/operation-note-action-list/operation-note-action-list.component';
import { OperationNoteActionItemComponent } from './components/operation-note-action-item/operation-note-action-item.component';
import { OperationNoteActionFormComponent } from './components/operation-note-action-form/operation-note-action-form.component';
import { OperationNoteActionService } from 'src/app/service/operation-note-action.service';
import { ContentsModule } from 'src/app/contents/contents.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { EffectsModule } from '@ngrx/effects';
import { NoResultModule } from 'src/app/contents/components/no-result/no-result.module';
import { CoordonneesModule } from 'src/app/contents/components/coordonnees/coordonnees.module';
import { CommunesFranceModule } from 'src/app/contents/components/communes-france/communes-france.module';
import { HeaderModule } from 'src/app/contents/components/header-title/header.module';

@NgModule({
  declarations: [
    OperationNoteActionListComponent,
    OperationNoteActionItemComponent,
    OperationNoteActionFormComponent,
  ],
  imports: [
    CommonModule,
    ContentsModule,
    SharedModule,
    NoResultModule,
    CoordonneesModule,
    HeaderModule,
    CommunesFranceModule,
  ],
  exports: [
    OperationNoteActionListComponent,
    OperationNoteActionItemComponent,
    OperationNoteActionFormComponent,
  ],
  providers: [OperationNoteActionService],
})
export class OperationNoteActionModule {}
