import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  enterAnimation,
  fade,
  itemAnimation,
  listAnimation,
} from 'src/app/app-animation';

@Component({
  selector: 'app-layout-list-container',
  templateUrl: './layout-list-container.component.html',
  styleUrls: ['./layout-list-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [enterAnimation, listAnimation],
})
export class LayoutListContainerComponent implements OnInit, OnChanges {
  @Input() list: any;
  @Input() card: TemplateRef<any> | any;
  @Input() itemSize: string = '73';
  @Input() extra: TemplateRef<any> | any;
  @Input() title: TemplateRef<any> | any;
  @Input() titleCenter: TemplateRef<any> | any;
  @Input() subtitle: TemplateRef<any> | any;
  @Input() headerContent: TemplateRef<any> | any;
  @Input() height: string = '300px';
  @Input() headerHeight: string = '100px';
  @Input() noDataIcon: string = 'short_text';
  @Input() newAddTitle: string = '';
  @Input() newNoDataTitle: string = '';
  @Input() newDescription: string = '';
  @Input() loading$: Observable<boolean> = of(false);

  @Input() isNew: boolean = true;
  @Input() isBack: boolean = true;
  @Input() isBorder: boolean = true;
  @Input() isHeader: boolean = true;

  @Output() search = new EventEmitter();
  @Output() export = new EventEmitter();
  @Output() new = new EventEmitter();
  @Output() add = new EventEmitter();
  @Output() update = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Output() deleteAll = new EventEmitter();
  @Output() back = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {}
  trackByFn(index: number, item: any) {
    return index;
  }
  onNew(): void {
    this.new.emit(true);
  }

  onBack(event: any): void {
    this.back.emit(true);
  }
}
