<nz-page-header
  class="site-page-header"
  (nzBack)="onBack()"
  nzBackIcon
  [nzTitle]="contactTitle"
>
  <nz-page-header-content>
    <!-- <nz-descriptions nzSize="small" [nzColumn]="3">
    <nz-descriptions-item nzTitle="Démarrage" [nzSpan]="1">{{
      module?.calendrier?.dateStart | dateFr: "time"
    }}</nz-descriptions-item>
    <nz-descriptions-item nzTitle="Échéance" [nzSpan]="1">{{
      module?.calendrier?.dateEnd | dateFr: "time"
    }}</nz-descriptions-item>
    <nz-descriptions-item
      nzTitle="Creation Time"
      [nzSpan]="1"
    ></nz-descriptions-item>
    <nz-descriptions-item
      nzTitle="Effective Time"
      [nzSpan]="1"
    ></nz-descriptions-item>
    <nz-descriptions-item nzTitle="Remarks" [nzSpan]="2">
    </nz-descriptions-item>
  </nz-descriptions> -->
  </nz-page-header-content>
</nz-page-header>

<app-item-tabset
  [tabs]="[
    {
      name: 'Profil',
      index: 0,
      template: null
    }
  ]"
></app-item-tabset>

<ng-template #contactTitle>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="8">
      <nz-card class="cardTitle"> </nz-card>
    </nz-col>
    <nz-col nzSpan="2"></nz-col>
    <nz-col nzSpan="8">
      <h4 nz-typography>INTERVENANT</h4>
    </nz-col>
    <nz-col nzSpan="2"></nz-col>
    <nz-col nzSpan="4"></nz-col>
  </nz-row>
</ng-template>
