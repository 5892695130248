import { DepartementId } from './../components/departement/store/departement.model';
import { Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  doc,
  docSnapshots,
  collectionChanges,
  addDoc,
  setDoc,
  deleteDoc,
  serverTimestamp,
  query,
  where,
  arrayUnion,
  arrayRemove,
  collectionSnapshots,
  getDocs,
} from '@angular/fire/firestore';
import { Observable, of, from, EMPTY } from 'rxjs';
import { switchMap, mergeMap, map } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { UserState } from '../components/user/store/user.reducer';
import * as fromUserSelector from '../components/user/store/user.selectors';
import { Apollo, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'any',
})
export class DepartementService {
  GET_SERVICES = gql`
    query Services {
      services {
        id
        title
        definition
      }
    }
  `;
  GET_DEPARTEMENTS = gql`
    query Departements {
      departements {
        id
        title
      }
    }
  `;
  GET_DEPARTEMENTS_LIST = gql`
    query DepartementsList {
      departementsList {
        id
        title
      }
    }
  `;

  CREATE_DEPARTEMENT = gql`
    mutation CreateDepartement($departement: DepartementInput!) {
      createDepartement(departement: $departement)
    }
  `;
  UPDATE_DEPARTEMENT = gql`
    mutation UpdateDepartement($departement: DepartementUpdateInput!) {
      updateDepartement(departement: $departement)
    }
  `;

  DELETE_DEPARTEMENT = gql`
    mutation DeleteDepartement($id: ID!) {
      deleteDepartement(id: $id)
    }
  `;

  DELETE_ALL_DEPARTEMENT = gql`
    mutation DeleteAllDepartement {
      deleteAllDepartement
    }
  `;

  constructor(
    private db: Firestore,
    private apollo: Apollo,
    private userStore: Store<UserState>
  ) {}

  getService(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_SERVICES,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  getDepartements(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_DEPARTEMENTS,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  getDepartementsList(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_DEPARTEMENTS_LIST,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  createDepartement(departement: any): Observable<any> {
    return this.apollo.mutate({
      mutation: this.CREATE_DEPARTEMENT,
      variables: {
        departement: departement,
      },
    });
  }
  updateDepartement(departement: any): Observable<any> {
    const {
      id,
      title,
      definition,
      adresse,
      users,
      ids,
      managersIds,
      managers,
    } = departement;
    const nextDepartement = {
      id,
      title,
      definition,
      adresse,
      users,
      ids,
      managersIds,
      managers,
    };

    return this.apollo.mutate({
      mutation: this.UPDATE_DEPARTEMENT,
      variables: {
        departement: nextDepartement,
      },
    });
  }

  deleteDepartement(id: string): Observable<any> {
    return this.apollo.mutate({
      mutation: this.DELETE_DEPARTEMENT,
      variables: {
        id: id,
      },
    });
  }

  deleteAllDepartements(): Observable<any> {
    return this.apollo.mutate({
      mutation: this.DELETE_ALL_DEPARTEMENT,
    });
  }

  getAll(): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) of([]);
          const dbCollection = collection(
            this.db,
            `organisations/${user.organisation.id}/departements`
          );

          const changes = collectionSnapshots(dbCollection).pipe(
            map((changes) => {
              return changes.map((snap) => {
                const data = snap.data();
                const id = snap.id;
                return { id, ...data };
              });
            })
          );

          return changes;
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  getOne(id: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) return of([]);

          const departementsCol = collection(
            this.db,
            `organisations/${user.organisation.id}/departements`
          );

          const departementRef = doc(departementsCol, id);
          return docSnapshots(departementRef).pipe(
            map((snap) => {
              const data = snap.data();
              const id = snap.id;
              return { id, ...data };
            })
          );
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase();
  }

  createKeywords(name: any): any {
    if (name) {
      const arrName: any = [];
      let curName = '';
      name.split('').forEach((letter: any) => {
        curName += letter;
        arrName.push(curName);
      });
      return arrName;
    }
  }

  createParticipantMetadata(item: any): any {
    const { title } = item;
    const letterTitle = title ? this.capitalizeFirstLetter(title) : '';
    const arrayTitle = title ? this.createKeywords(title) : [];
    const metatada = { letterTitle, arrayTitle };

    return metatada;
  }
}
