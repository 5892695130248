import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  EventEmitter,
  Output,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';

import { DepartementState } from '../../store/departement.reducer';
import * as fromDepartementAction from '../../store/departement.actions';
import * as fromDepartementSelector from '../../store/departement.selectors';

@Component({
  selector: 'app-departement-selector',
  templateUrl: './departement-selector.component.html',
  styleUrls: ['./departement-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DepartementSelectorComponent
  implements OnInit, OnDestroy, OnChanges
{
  user$: Observable<any> = of(null);
  departement$: Observable<any> = of(null);
  departements$: Observable<any> = of(null);
  loading$: Observable<boolean> = of(false);
  subscribe = new Subject();
  departementSelected: any = null;
  icon: string = 'group_work';

  @Output() select = new EventEmitter<any>(false);

  constructor(
    private userStore: Store<UserState>,
    private departementStore: Store<DepartementState>
  ) {}

  ngOnInit(): void {
    this.getUser();
    this.getLoading();
    this.getDepartements();
    this.loadDepartements();
  }
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getUser(): void {
    this.user$ = this.userStore.select(fromUserSelector.user);
  }

  getLoading(): void {
    this.loading$ = this.departementStore.select(
      fromDepartementSelector.loading
    );
  }

  getDepartements(): void {
    this.departements$ = this.departementStore.select(
      fromDepartementSelector.list
    );
  }

  loadDepartements(): void {
    this.departementStore.dispatch(
      fromDepartementAction.loadDepartementsList()
    );
  }

  onSelect(departement: any): void {
    if (!departement) return;
    this.departementSelected = departement;
    this.select.emit(departement);
  }

  onClear(): void {
    this.departementSelected = null;
  }
}
