<nz-spin
  nzSimple
  [nzSize]="'large'"
  [nzIndicator]="spin"
  *ngIf="loading$ | async"
></nz-spin>

<ng-template #spin>
  <ng-lottie
    [options]="options"
    (animationCreated)="animationCreated($event)"
    width="350px"
    height="350px"
  ></ng-lottie>
</ng-template>
