<nz-card nzBorderless>
  <form nz-form [formGroup]="departementForm" nzLayout="vertical">
    <nz-form-item nzRequired>
      <nz-form-label nzRequired="true"
        ><h5 nz-typography>Titre</h5></nz-form-label
      >
      <nz-form-control>
        <input
          nz-input
          nzBorderless
          placeholder="Titre..."
          formControlName="title"
          class="borderlessClass"
        />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label nzRequired
        ><h5 nz-typography>Description</h5></nz-form-label
      >
      <nz-form-control>
        <textarea
          nz-input
          nzBorderless
          formControlName="definition"
          [nzAutosize]="{ minRows: 4, maxRows: 4 }"
          placeholder="Description..."
          class="borderlessClass"
        ></textarea>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label nzRequired><h5 nz-typography>Adresse</h5></nz-form-label>
      <nz-form-control>
        <nz-tag
          *ngIf="updateItem$ | async"
          (nzOnClose)="onClearAdresse()"
          nzMode="closeable"
        >
          {{ (updateItem$ | async)?.properties?.label }}
        </nz-tag>
        <app-communes-france
          *ngIf="!(updateItem$ | async)"
          [borderless]="true"
          [isComplement]="false"
          [isLabel]="false"
          [required]="true"
          [updateItem]="updateItem$"
          [updateComplement]="updateComplement$"
          [size]="'default'"
          (adresse)="onAdresse($event)"
          (complementIn)="onAdresseComplete($event)"
        ></app-communes-france>
      </nz-form-control>
    </nz-form-item>
  </form>

  <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="end">
    <nz-col nzSpan="16"></nz-col>

    <nz-col nzSpan="4">
      <button nz-button nzBlock nzType="text" (click)="onCancel()">
        Annuler
      </button>
    </nz-col>
    <nz-col nzSpan="4">
      <button
        nz-button
        nzBlock
        (click)="onAdd()"
        nzType="primary"
        [disabled]="!departementForm.valid"
        *ngIf="isAdd"
      >
        Ajouter
      </button>
      <button
        nz-button
        nzBlock
        nzType="primary"
        [disabled]="!departementForm.valid"
        *ngIf="isUpdate && (departement$ | async) as departement"
        (click)="onUpdate(departement)"
      >
        Modifier
      </button>
    </nz-col>
  </nz-row>
</nz-card>

<ng-template #formTitle>
  <h5 nz-typography *ngIf="isUpdate">
    Modifier : {{ (departement$ | async)?.title }}
  </h5>
</ng-template>

<ng-template #formAction>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="end" *ngIf="isUpdate">
    <nz-col nzSpan="12">
      <button
        nz-button
        nzBlock
        nzType="primary"
        *ngIf="isUpdate && (departement$ | async) as departement"
        (click)="onUpdate(departement)"
      >
        Modifier
      </button>
    </nz-col>
    <nz-col nzSpan="12">
      <button nz-button nzBlock nzType="text" (click)="onCancel()">
        Annuler
      </button>
    </nz-col>
  </nz-row>
</ng-template>
