<div class="container" *ngIf="operation$ | async as operation">
  <div [ngSwitch]="view$ | async">
    <div *ngSwitchCase="'list'">
      <i nz-icon nzType="loading" class="loading" *ngIf="loading$ | async"></i>

      <ng-container *ngIf="actions$ | async | size; else noResults">
        <app-action-filters
          [operation$]="operation$"
          [actions$]="actions$"
        ></app-action-filters>
        <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
        <app-layout-list
          [card]="card"
          [new]="new"
          [source]="actions$ | async"
        ></app-layout-list>
      </ng-container>
    </div>
    <div *ngSwitchCase="'details'">
      <app-action-item
        [action]="currentAction$ | async"
        (back)="onBack()"
      ></app-action-item>
    </div>
    <div *ngSwitchDefault>
      <app-layout-list
        *ngIf="actions$ | async | size; else noResults"
        [card]="card"
        [new]="new"
        [source]="actions$ | async"
      ></app-layout-list>
    </div>
  </div>

  <ng-template #new>
    <app-card-new
      [type]="'action'"
      [title]="
        'Nouveau' +
        ' ' +
        (operation?.type | operationType: 'action' | lowercase)
      "
      [description]="newCardDescription"
      [definition]="
        operation?.type !== 'Clauses sociales'
          ? newCardDefinition
          : newCardDefinitionClauses
      "
      [width]="'300px'"
      [height]="'250px'"
      (add)="onNew(operation)"
      (back)="onBack()"
      (cancel)="onBack()"
    ></app-card-new>
  </ng-template>

  <ng-template #card let-item let-position="idx">
    <app-action-card
      [action]="item"
      (click)="onSelect(item)"
      (delete)="onDelete(operation, item)"
    ></app-action-card>
  </ng-template>

  <ng-template #noResults>
    <app-no-result
      [icon]="'dashboard'"
      [addtitle]="newCardDescription"
      [notitle]="noModuleTitle"
      [description]="newCardDefinition"
      (new)="onNew(operation)"
    ></app-no-result>
  </ng-template>

  <ng-template #actionFormTitle>
    <app-header-title [icon]="'dashboard'" [title]="title"></app-header-title>
  </ng-template>
</div>
