<div class="container" *ngIf="operation$ | async as operation">
  <i nz-icon nzType="loading" class="loading" *ngIf="loading$ | async"></i>
  <div [ngSwitch]="view$ | async">
    <div *ngSwitchCase="'list'">
      <ng-container *ngIf="modules$ | async | size; else noResults">
        <app-module-filters
          [operation$]="operation$"
          [modules$]="modules$"
        ></app-module-filters>
        <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>

        <app-layout-list
          [card]="card"
          [new]="new"
          [height]="'calc(100vh - 18em)'"
          [source]="modules$ | async"
        ></app-layout-list>
      </ng-container>
    </div>
    <div *ngSwitchCase="'details'">
      <app-module-item
        [module]="currentModule$ | async"
        (back)="onBack()"
      ></app-module-item>
    </div>
    <div *ngSwitchDefault></div>
  </div>

  <ng-template #new>
    <app-card-new
      [type]="'module'"
      [title]="operation?.type | operationType: 'module'"
      [description]="''"
      [definition]="
        operation?.type !== 'Clauses sociales'
          ? newCardDefinition
          : newCardDefinitionClauses
      "
      [width]="'300px'"
      [height]="'250px'"
      (add)="onNew(operation)"
      (back)="onBack()"
      (cancel)="onBack()"
    ></app-card-new>
  </ng-template>

  <ng-template #card let-item let-position="idx">
    <app-module-card
      [module]="item"
      (select)="onSelect(item)"
      (delete)="onDelete(operation, item)"
      (click)="$event.stopPropagation()"
    ></app-module-card>
  </ng-template>

  <ng-template #noResults>
    <app-no-result
      [icon]="'dynamic_feed'"
      [addtitle]="newCardDescription"
      [notitle]="noModuleTitle"
      [description]="newCardDefinition"
      nz-popover
      [nzPopoverContent]="newModuleActionsSelector"
      nzTooltipPlacement="bottom"
      nzPopoverTrigger="click"
    ></app-no-result>
  </ng-template>

  <ng-template #newModuleActionsSelector>
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="center">
      <nz-col nzSpan="12">
        <nz-card
          nzSize="small"
          nzHoverable
          [nzTitle]="moduleFormTitle"
          (click)="$event.stopPropagation(); onNew(operation)"
        >
          <div class="cardContent">
            <p nz-typography>Ajouter un module</p>
          </div>
        </nz-card>
      </nz-col>
      <nz-col nzSpan="12">
        <nz-card
          nzHoverable
          nzSize="small"
          [nzTitle]="moduleImportTitle"
          (click)="$event.stopPropagation(); onNewImport(operation)"
        >
          <div class="cardContent">
            <p nz-typography>
              Ajouter une liste de modules à partir d'un tableur
            </p>
          </div>
        </nz-card>
      </nz-col>
    </nz-row>
  </ng-template>

  <ng-template #moduleFormTitle>
    <app-header-title
      [icon]="'dynamic_feed'"
      [title]="title"
      [subtitle]="true"
    ></app-header-title>
  </ng-template>
  <ng-template #moduleImportTitle>
    <app-header-title
      [icon]="'table_view'"
      [title]="titleImport"
      [subtitle]="true"
    ></app-header-title>
  </ng-template>
</div>
