import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, of, Subject, takeUntil } from 'rxjs';
import { UtilitiesState } from 'src/app/contents/components/utilities/store/utilities.reducer';
import * as fromUtilitiesSelector from 'src/app/contents/components/utilities/store/utilities.selectors';
import * as fromUtilitiesAction from 'src/app/contents/components/utilities/store/utilities.actions';

@Component({
  selector: 'app-participant-diagnostic-form-numerique',
  templateUrl: './participant-diagnostic-form-numerique.component.html',
  styleUrls: ['./participant-diagnostic-form-numerique.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantDiagnosticFormNumeriqueComponent
  implements OnInit, OnChanges, OnDestroy
{
  numerique$: Observable<any> = of(null);
  bureautique$: Observable<string[] | any> = of([]);
  subscribe = new Subject();

  //VARIABLES
  isFreinTitle: string = 'Le numérique est-il un frein identifié ?';
  checkboxDefinitionMobile: string =
    "Le participant dispose d'un téléphone mobile.";
  checkboxDefinitionOrdinateur: string =
    "Le participant dispose d'un ordinateur.";
  checkboxDefinitionInternet: string =
    "Le participant dispose d'un accès à internet.";

  //FORM FIELDS
  numeriqueForm: FormGroup = this.fb.group({});
  numerique_level: FormControl<any[] | any> = new FormControl(
    [],
    Validators.required
  );
  mobile: FormControl<boolean | any> = new FormControl(
    true,
    Validators.required
  );
  internet: FormControl<boolean | any> = new FormControl(
    true,
    Validators.required
  );
  ordinateur: FormControl<boolean | any> = new FormControl(
    true,
    Validators.required
  );
  bureautique_level: FormControl<string[] | any> = new FormControl(
    [],
    Validators.required
  );
  isFrein: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );

  //INIT
  @Input() numeriqueInput$: Observable<any> = of(null);
  @Output() add = new EventEmitter(false);

  constructor(
    private fb: FormBuilder,
    private utilitiesStore: Store<UtilitiesState>
  ) {}

  ngOnInit(): void {
    this.form();
    this.loadNumeriqueUtilities();
    this.loadBureautiqueReferentiel();
    this.getNumeriqueUtilities();
    this.getBureautiqueReferentiel();
    this.onFormChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getNumeriqueUtilities(): void {
    this.numerique$ = this.utilitiesStore.select(
      fromUtilitiesSelector.numerique
    );
  }

  loadNumeriqueUtilities(): void {
    this.utilitiesStore.dispatch(
      fromUtilitiesAction.loadNumeriqueReferentiel()
    );
  }

  getBureautiqueReferentiel(): void {
    this.bureautique$ = this.utilitiesStore.select(
      fromUtilitiesSelector.bureautique
    );
  }
  loadBureautiqueReferentiel(): void {
    this.utilitiesStore.dispatch(
      fromUtilitiesAction.loadLevelBureautiqueReferentiel()
    );
  }

  form(): void {
    this.formInit();
    this.formCreate();
    this.formSet();
  }

  formInit(): void {
    this.mobile = new FormControl<boolean>(false, Validators.required);
    this.internet = new FormControl<boolean>(false, Validators.required);
    this.ordinateur = new FormControl<boolean>(false, Validators.required);
    this.numerique_level = new FormControl<any[]>([], Validators.required);
    this.bureautique_level = new FormControl<any[]>([]);
    this.isFrein = new FormControl<boolean>(false, Validators.required);
  }

  formCreate(): void {
    this.numeriqueForm = this.fb.group({
      mobile: this.mobile,
      internet: this.internet,
      ordinateur: this.ordinateur,
      numerique_level: this.numerique_level,
      bureautique_level: this.bureautique_level,
      isFrein: this.isFrein,
    });
  }

  formSet(): void {
    this.numeriqueInput$
      .pipe(takeUntil(this.subscribe))
      .subscribe((numerique) => {
        if (!numerique) return;
        this.numeriqueForm.patchValue({ ...numerique });
      });
  }

  //COMPARE SELECT LIST UPDATE
  compareNumericLevel(obj1: any, obj2: any) {
    return (
      obj1 &&
      obj2 &&
      obj1.type === obj2.type &&
      obj1.items === obj2.items &&
      obj1.item === obj2.item
    );
  }
  compareBureautiqueLevel(obj1: any, obj2: any) {
    return obj1 && obj2 && obj1 === obj2;
  }

  checkboxChanges(event: boolean, type: string): void {
    switch (type) {
      case 'mobile':
        this.numeriqueForm.patchValue({
          mobile: event,
        });

        break;
      case 'ordinateur':
        this.numeriqueForm.patchValue({
          ordinateur: event,
        });

        break;
      case 'internet':
        this.numeriqueForm.patchValue({
          internet: event,
        });

        break;

      default:
        break;
    }
  }

  onFormChanges(): void {
    this.numeriqueForm.valueChanges
      .pipe(takeUntil(this.subscribe))
      .subscribe((values) => this.add.emit(values));
  }
}
