import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TaskState, tasksFeatureKey, selectAll } from './task.reducer';

export const selectTasksState =
  createFeatureSelector<TaskState>(tasksFeatureKey);

export const isFiltered = createSelector(
  selectTasksState,
  (state: TaskState) => state.isFiltered
);

export const filters = createSelector(
  selectTasksState,
  (state: TaskState) => state.filters
);
