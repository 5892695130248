<table *ngFor="let groupSujets of sujets; let i = index">
  <thead [ngStyle]="{ 'break-after': 'always' }">
    <tr>
      <th class="sujetRowHeader">
        <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
          <nz-col nzSpan="24">
            {{ i + 1 }}
            <nz-divider nzType="vertical" class="whiteTitle"></nz-divider>

            <strong nz-typography class="whiteTitle">
              {{ groupSujets?.[0]?.subtitle?.title | uppercase }}
            </strong>
          </nz-col>
        </nz-row>
      </th>
    </tr>
  </thead>

  <tbody id="idBody" *ngFor="let el of groupSujets">
    <tr class="rowSujet" id="rowSujet">
      <td>
        <nz-card
          [nzTitle]="sujettitle"
          [nzExtra]="sujetExtra"
          class="cardSujet"
        >
          <p
            [innerHTML]="el.textHTML | stringhtml"
            class="paragraph"
            [ngStyle]="{ color: el?.isWarning ? 'red' : '' }"
          ></p>
          <ng-template #sujettitle>
            <p nz-typography>
              <nz-row nzAlign="middle" nzGutter="8" nzJustify="end">
                <nz-col nzSpan="12">
                  <p>Crée le {{ el?.dateStart | dateFr: "small" }}</p>
                </nz-col>
                <nz-col nzSpan="12">
                  <p>Modifié le {{ el?.dateUpdate | dateFr: "small" }}</p>
                </nz-col>
              </nz-row>
            </p>
          </ng-template>
          <ng-template #sujetExtra>
            <nz-row nzAlign="middle" nzGutter="8" nzJustify="end">
              <nz-col nzSpan="12">
                <nz-tag *ngIf="el?.isWarning" nzColor="error"> Alerte </nz-tag>
              </nz-col>

              <nz-col nzSpan="12">
                <nz-tag *ngIf="el?.isDone" nzColor="success"> Réalisé </nz-tag>
              </nz-col>
            </nz-row>
          </ng-template>
        </nz-card>
      </td>
      <nz-divider nzType="horizontal"></nz-divider>
    </tr>
  </tbody>
</table>
