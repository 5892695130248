<form nz-form [formGroup]="itemform" nzLayout="vertical">
  <nz-row nzAlign="top" nzGutter="2" nzJustify="start">
    <nz-col nzSpan="16">
      <nz-form-item>
        <nz-form-label nzRequired>Titre</nz-form-label>
        <nz-form-control>
          <input
            nz-input
            nzBorderless
            formControlName="title"
            placeholder="Titre"
          />
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col nzSpan="8">
      <nz-form-item>
        <nz-form-label>Date</nz-form-label>
        <nz-form-control>
          <nz-date-picker
            nzBorderless
            formControlName="date"
            nzAllowClear="true"
            nzFormat="dd/MM/yyyy"
          ></nz-date-picker>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>
  <!-- <nz-row
    nzAlign="top"
    nzGutter="2"
    nzJustify="start"
    formArrayName="intervenants"
  >
    <nz-col nzSpan="18">
      <div *ngFor="let item of intevenantsArray.controls; let i = index">
        <nz-tag nzMode="closeable" (nzOnClose)="removeIntervenant(i)">{{
          item.value?.displayName | carLimitation: 20
        }}</nz-tag>
      </div>
    </nz-col>
    <nz-col nzSpan="6">
      <button nz-button>
        <i nz-icon nzType="plus"></i>
        Intervenants
      </button>
    </nz-col>
  </nz-row> -->
  <nz-row nzAlign="top" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="24">
      <nz-form-item>
        <nz-form-control>
          <textarea
            nz-input
            nzBorderless
            formControlName="description"
            placeholder="Description"
            [nzAutosize]="{ minRows: 2, maxRows: 2 }"
          ></textarea>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>
</form>

<nz-divider nzType="horizontal" nzOrientation="center"></nz-divider>

<nz-row nzAlign="middle" nzGutter="8" nzJustify="end">
  <nz-col nzSpan="16"></nz-col>
  <nz-col nzSpan="4">
    <button
      *ngIf="isAdd"
      nz-button
      (click)="onAdd()"
      nzType="primary"
      [disabled]="!itemform.valid"
      nzBlock
    >
      <i nz-icon nzType="check-circle"></i>
      <span>Ajouter</span>
    </button>
    <button
      *ngIf="isUpdate"
      nz-button
      (click)="onUpdate()"
      nzType="primary"
      [disabled]="!itemform.valid"
      nzBlock
    >
      <i nz-icon nzType="check-circle"></i>
      <span>Modifier</span>
    </button>
  </nz-col>
  <nz-col nzSpan="4">
    <button nz-button (click)="onCancel()" nzBlock nzType="text">
      <i nz-icon nzType="close-circle"></i>
      <span>Annuler</span>
    </button>
  </nz-col>
</nz-row>
