import { takeUntil } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, Subject, BehaviorSubject } from 'rxjs';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';
import { DashboardState } from '../../store/dashboard.reducer';
import * as fromDashboardSelector from '../../store/dashboard.selectors';
import * as fromDashboardAction from '../../store/dashboard.actions';

@Component({
  selector: 'app-dashboard-participants',
  templateUrl: './dashboard-participants.component.html',
  styleUrls: ['./dashboard-participants.component.scss'],
})
export class DashboardParticipantsComponent implements OnInit, OnDestroy {
  user$: Observable<any> = of(null);
  subscribe = new Subject();
  statistiques$: Observable<any> = of(null);
  currentDate = new Date(Date.now());
  currentYear: string = this.currentDate.getFullYear().toString();
  selectedYear: string = '';

  //PARTICIPANTS
  participantsTotal: number = 0;
  participantsObjectif: number = 0;
  participantsDateUpdate: Date | any;
  participantsOfYear: any[] = [];

  //ITRMS
  byMonths$ = new BehaviorSubject<any>(null);
  sexes$ = new BehaviorSubject<any>(null);
  tranches$ = new BehaviorSubject<any>(null);
  ages$ = new BehaviorSubject<any>(null);
  criteres$ = new BehaviorSubject<any>(null);
  rqth$ = new BehaviorSubject<any>(null);
  nationality$ = new BehaviorSubject<any>(null);
  typeSejours$ = new BehaviorSubject<any>(null);
  typeSorties$ = new BehaviorSubject<any>(null);
  motifSorties$ = new BehaviorSubject<any>(null);
  titreSejours$ = new BehaviorSubject<any>(null);

  situationFamiliale$ = new BehaviorSubject<any>(null);
  qualificationsTitres$ = new BehaviorSubject<any>(null);
  qualificationsNiveau$ = new BehaviorSubject<any>(null);
  poleEmploi$ = new BehaviorSubject<any>(null);
  missionsLocales$ = new BehaviorSubject<any>(null);
  droitDeTravail$ = new BehaviorSubject<any>(null);

  communes$ = new BehaviorSubject<any>(null);
  contexts$ = new BehaviorSubject<any>(null);
  departements$ = new BehaviorSubject<any>(null);

  maitriseFRItems$ = new BehaviorSubject<any>(null);
  maitriseFRType$ = new BehaviorSubject<any>(null);

  accesInternet$ = new BehaviorSubject<any>(null);
  accesMobile$ = new BehaviorSubject<any>(null);
  accessLogement$ = new BehaviorSubject<any>(null);

  nationalities$ = new BehaviorSubject<any>(null);
  mobilities$ = new BehaviorSubject<any>(null);

  constructor(
    private userStore: Store<UserState>,
    private dashboardStore: Store<DashboardState>
  ) {}

  ngOnInit(): void {
    this.getUser();
    this.loadStatistiques();
    this.getStatistiques();
    this.setStatistiques();
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getUser(): void {
    this.user$ = this.userStore.select(fromUserSelector.user);
  }

  getStatistiques(): any {
    this.statistiques$ = this.dashboardStore.select(
      fromDashboardSelector.participants
    );
  }

  loadStatistiques(year: string = this.currentYear): any {
    if (!year) {
      return null;
    } else {
      this.dashboardStore.dispatch(
        fromDashboardAction.loadDashboardsParticipants({ year })
      );
    }
  }

  setStatistiques(): void {
    this.statistiques$
      .pipe(takeUntil(this.subscribe))
      .subscribe((statistique) => {
        this.setParticipantSTATS(statistique);
      });
  }

  setParticipantSTATS(stats: any): any {
    if (!stats) {
      return null;
    }

    const items = stats?.items ? stats.items : null;
    const byOperations = stats?.items?.byOperations
      ? stats.items.byOperations
      : null;
    const byServices = stats?.items?.byServices ? stats.items.byServices : null;
    const size = items?.ids?.length ? stats.items.ids.length : 0;
    const objectif = stats?.objectif ? stats.objectif : 0;
    const dateUpdate = stats?.dateUpdate ? stats.dateUpdate : 0;

    const dataStats = items?.dataStats ? items.dataStats : null;
    const dataCharts = items?.dataCharts ? items.dataCharts : null;

    this.participantsOfYear = items;

    this.participantsTotal = size;
    this.participantsObjectif = objectif;
    this.participantsDateUpdate = dateUpdate;

    //CHARTS VALUES
    const dates = dataStats?.dates ? dataStats.dates : null;
    const sexes = dataStats?.sexe ? dataStats.sexe : null;
    const ages = dataStats?.age ? dataStats.sexe : null;
    const context = dataStats?.context ? dataStats?.context : null;
    const commune = dataStats?.commune ? dataStats?.commune : null;
    const departement = dataStats?.departement ? dataStats?.departement : null;
    const droitDeTravail = dataStats?.droitDeTravail
      ? dataStats?.droitDeTravail
      : null;
    const rqth = dataStats?.rqth ? dataStats?.rqth : null;
    const tranches = dataStats?.tranche ? dataStats?.tranche : null;
    const nationality = dataStats?.nationality ? dataStats?.nationality : null;
    const poleEmploi = dataStats?.poleEmploi ? dataStats?.poleEmploi : null;
    const typeCarteSejour = dataStats?.typeCarteSejour
      ? dataStats?.typeCarteSejour
      : null;
    const missionLocale = dataStats?.missionLocale
      ? dataStats?.missionLocale
      : null;
    const minimaSociaux = dataStats?.minimaSociaux
      ? dataStats?.minimaSociaux
      : null;
    const maitriseFrancaisItem = dataStats?.maitriseFrancaisItem
      ? dataStats?.maitriseFrancaisItem
      : null;
    const maitriseFrancaisType = dataStats?.maitriseFrancaisType
      ? dataStats?.maitriseFrancaisType
      : null;
    const qualificationsNiveau = dataStats?.qualificationsNiveau
      ? dataStats?.qualificationsNiveau
      : null;
    const qualificationsTitre = dataStats?.qualificationsTitre
      ? dataStats?.qualificationsTitre
      : null;
    const criteres = dataStats?.criteres ? dataStats?.criteres : null;
    const situationFamiliale = dataStats?.situationFamiliale
      ? dataStats?.situationFamiliale
      : null;
    const internet = dataStats?.internet ? dataStats?.internet : null;
    const logement = dataStats?.logement ? dataStats?.logement : null;
    const mobile = dataStats?.mobile ? dataStats?.mobile : null;
    const titreSejours = dataStats?.titreSejours
      ? dataStats?.titreSejours
      : null;
    const typeSorties = dataStats?.typeSorties ? dataStats?.typeSorties : null;
    const motifsSorties = dataStats?.motifsSorties
      ? dataStats?.motifsSorties
      : null;
    const impact3 = dataStats?.impact3 ? dataStats?.impact3 : null;
    const impact6 = dataStats?.impact6 ? dataStats?.impact6 : null;
    const impact12 = dataStats?.impact12 ? dataStats?.impact12 : null;

    //SETTING
    const width = 500;
    const height = 350;
    //SET INDICATEUR

    //SET BY MONTHS
    this.byMonths$.next({
      title: 'Répartition par mois',
      type: 'barMonths',
      data: dates,
      width: '300px',
      height: '200px',
    });

    //SET SEXES
    this.sexes$.next({
      title: 'Sexe',
      type: 'pie',
      data: sexes,
      radius: '25%',
      center: ['25%', '25%'],
    });

    //SET tranches
    this.tranches$.next({
      title: "Tranche d'âge",
      type: 'pie',
      data: tranches,
      radius: '25%',
      center: ['25%', '25%'],
    });

    //SET RQTH
    this.rqth$.next({
      title: 'Reconnaissance RQTH',
      type: 'pie',
      data: rqth,
      radius: '25%',
      center: ['25%', '25%'],
    });
    //SET NATIONALITY
    this.nationality$.next({
      title: 'Nationalité',
      type: 'pie',
      data: nationality,
      radius: '25%',
      center: ['25%', '25%'],
    });

    //SET TITRE SEJOURS
    this.titreSejours$.next({
      title: 'Titre de séjour',
      type: 'pie',
      data: titreSejours,
    });

    //SET TITRE SEJOURS
    this.typeSejours$.next({
      title: 'Type de séjour',
      type: 'pie',
      data: typeCarteSejour,
    });

    //SET TYPE SORTIES
    this.typeSorties$.next({
      title: 'Type de sortie',
      type: 'pie',
      data: typeSorties,
    });

    //SET MOTIF SORTIES
    this.motifSorties$.next({
      title: 'Motif de sortie',
      type: 'pie',
      data: motifsSorties,
    });

    //SET SITUATION FAMILIALE
    this.situationFamiliale$.next({
      title: 'Situation familiale',
      type: 'pie',
      data: situationFamiliale,
    });

    //SET QUALIFICATION TITRE
    this.qualificationsTitres$.next({
      title: 'Titre de qualification',
      type: 'pie',
      data: qualificationsTitre,
    });

    //SET QUALIFICATION NIVEAU
    this.qualificationsNiveau$.next({
      title: 'Niveau de qualification',
      type: 'pie',
      data: qualificationsNiveau,
    });

    //SET POLE EMPLOI
    this.poleEmploi$.next({
      title: 'Pôle Emploi',
      type: 'pie',
      data: poleEmploi,
    });

    //SET POLE EMPLOI
    this.missionsLocales$.next({
      title: 'Mission Locale',
      type: 'pie',
      data: missionLocale,
    });

    //SET DROIT DE TRAVAIL
    this.droitDeTravail$.next({
      title: 'Droit de travail',
      type: 'pie',
      data: droitDeTravail,
    });

    //SET CRITERES
    this.criteres$.next({
      title: 'Critères',
      type: 'pie',
      data: criteres,
    });

    //SET Contexte
    this.contexts$.next({
      title: 'Contexte',
      type: 'pie',
      data: context,
    });

    //SET Communes
    this.communes$.next({
      title: 'Communes',
      type: 'pie',
      data: commune,
    });

    //SET Département
    this.departements$.next({
      title: 'Département',
      type: 'pie',
      data: departement,
    });

    //SET maitriseFRItems
    this.maitriseFRItems$.next({
      title: 'Maitrise du français',
      type: 'pie',
      data: maitriseFrancaisItem,
    });

    //SET maitriseFRType
    this.maitriseFRType$.next({
      title: 'Maitrise du français',
      type: 'pie',
      data: maitriseFrancaisType,
    });

    //SET maitriseFRType
    this.accesInternet$.next({
      title: 'Accès internet',
      type: 'pie',
      data: internet,
    });

    //SET maitriseFRType
    this.accesMobile$.next({
      title: 'Possésion mobile',
      type: 'pie',
      data: mobile,
    });

    //SET logement
    this.accessLogement$.next({
      title: 'Possède un logement',
      type: 'pie',
      data: logement,
    });
  }
}
