<ng-container *ngIf="participant$ | async as participant">
  <nz-card
    nzBorderless
    [nzTitle]="cardTitle"
    nzSize="small"
    [nzExtra]="cardExtra"
    *ngIf="accompagnements$ | async | size; else noResults"
  >
    <nz-table
      #table
      [nzData]="accompagnements$ | async"
      nzTableLayout="fixed"
      nzSize="small"
      [nzScroll]="scroll"
      [nzLoadingDelay]="2"
      [nzBordered]="false"
      [nzOuterBordered]="false"
    >
      <thead>
        <tr>
          <th nzLeft nzWidth="50px">Type</th>
          <th nzLeft>Champs</th>
          <th>Objectif</th>
          <th [nzSortFn]="sortFnByDates" nzWidth="100px">Date</th>
          <th nzRight nzWidth="100px">Statut</th>

          <th nzWidth="100px">Référent</th>
          <th nzRight nzWidth="50px"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          [@listAnimation]
          *ngFor="let data of table.data; trackBy: trackByFn"
          (click)="$event.stopPropagation(); onSelect(participant, data)"
        >
          <td nzLeft nzWidth="50px">
            <app-participant-action-accompagnement-type
              [type]="data.type"
            ></app-participant-action-accompagnement-type>
          </td>
          <td nzLeft>
            <app-participant-action-accompagnement-champs
              [champs]="data?.champs"
            ></app-participant-action-accompagnement-champs>
          </td>

          <td>{{ data?.objectif | carLimitation : 40 }}</td>

          <td nzWidth="100px">
            {{ data?.dateStart | dateFr : "medium" }}
          </td>
          <td nzRight nzWidth="100px">
            <nz-tag [nzColor]="data.isCompleted ? 'success' : 'error'"
              >{{ data.isCompleted ? "Réalisée" : "Non réalisée" }}
            </nz-tag>
          </td>
          <td nzWidth="100px">
            <app-avatar-group
              [source]="[data.auteur]"
              [size]="30"
            ></app-avatar-group>
          </td>

          <td nzRight nzWidth="50px">
            <app-button-dropdown
              [direction]="'horizontal'"
              (click)="$event.stopPropagation()"
              [isDelete]="true"
              [deleteType]="
                'Voulez-vous supprimer  l\'action ' + data.champs.libelle + ' ?'
              "
              (select)="onSelectDropdown($event, data, participant)"
            ></app-button-dropdown>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </nz-card>

  <ng-template #cardTitle>
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="2">
        <app-header-title
          [icon]="'checklist'"
          [title]="''"
          [subtitle]="true"
          [isIcon]="true"
        ></app-header-title>
      </nz-col>
      <nz-col nzSpan="2"></nz-col>
      <nz-col nzSpan="6">
        <!-- <form nz-form [formGroup]="searchForm">
        <nz-form-item>
          <nz-form-control *ngIf="isSearch">
            <input
              nz-input
              placeholder="Recherche"
              nzBorderless
              formControlName="input"
            />
          </nz-form-control>
        </nz-form-item>
      </form> -->
      </nz-col>
      <nz-col nzSpan="14"></nz-col>
    </nz-row>
  </ng-template>
  <ng-template #cardExtra>
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="end">
      <nz-col nzSpan="16">
        <button
          nz-button
          nz-tooltip
          nzBlock
          class="btn_add"
          (click)="onNew(participant)"
        >
          Nouveau
        </button>
      </nz-col>
      <nz-col nzSpan="8">
        <app-button-dropdown [isDeleteAll]="true"></app-button-dropdown>
      </nz-col>
    </nz-row>
  </ng-template>

  <ng-template #unknownExtension>
    <i nz-icon nzType="file-unknown"></i>
  </ng-template>

  <ng-template #noResults>
    <app-no-result
      [icon]="'checklist'"
      [addtitle]="'Ajouter une action'"
      [notitle]="'Aucune action ajoutés'"
      [description]="''"
      (new)="onNew(participant)"
    ></app-no-result>
  </ng-template>

  <ng-template #actionAccompagnementTitleForm>
    <app-header-title
      [icon]="'checklist'"
      [title]="title"
      [subtitle]="true"
    ></app-header-title>
  </ng-template>
</ng-container>
