import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import {
  OperationNoteSujet,
  OperationNoteSujetId,
} from './operation-note-sujet.model';
import { OperationNoteId } from '../../operation-note/store/operation-note.model';
import { OperationNoteSubtitleId } from '../../operation-note-subtitle/store/operation-note-subtitle.model';

//LOADING INDICATOR
export const loadingOperationNoteSujets = createAction(
  '[OperationNoteSujet/API] Loading OperationNoteSujets',
  props<{ loading: boolean }>()
);

//LOAD BY SUBTITLE

export const loadOperationNoteSujets = createAction(
  '[OperationNoteSujet/API] Load OperationNoteSujets',
  props<{ subtitle: OperationNoteSubtitleId }>()
);
export const loadOperationNoteSujetsSuccess = createAction(
  '[OperationNoteSujet/API] Load OperationNoteSujets success',
  props<{ sujets: OperationNoteSujetId[] }>()
);
export const loadOperationNoteSujetsFailure = createAction(
  '[OperationNoteSujet/API] Load OperationNoteSujets failure',
  props<{ error: any }>()
);

//LOAD SUJETS WARNING

export const loadOperationNoteSujetsWarning = createAction(
  '[OperationNoteSujet/COMPONENT] Load OperationNoteSujets Warning',
  props<{ id: string; noteId: string }>()
);
export const loadOperationNoteSujetsWarningSuccess = createAction(
  '[OperationNoteSujet/API] Load OperationNoteSujets Warning success',
  props<{ snap: any }>()
);
export const loadOperationNoteSujetsWarningFailure = createAction(
  '[OperationNoteSujet/API] Load OperationNoteSujets Warning failure',
  props<{ error: any }>()
);

//LOAD BY NOTE

export const loadOperationNoteSujetsByNote = createAction(
  '[OperationNoteSujet/API] Load OperationNoteSujets By Note',
  props<{ note: OperationNoteId }>()
);
export const loadOperationNoteSujetsByNoteSuccess = createAction(
  '[OperationNoteSujet/API] Load OperationNoteSujets By Note success',
  props<{ sujets: OperationNoteSujetId[] }>()
);
export const loadOperationNoteSujetsByNoteFailure = createAction(
  '[OperationNoteSujet/API] Load OperationNoteSujets By Note failure',
  props<{ error: any }>()
);

//ADD ONE

export const addOperationNoteSujet = createAction(
  '[OperationNoteSujet/API] Add OperationNoteSujet',
  props<{ note: OperationNoteId; sujet: OperationNoteSujet }>()
);
export const addOperationNoteSujetSuccess = createAction(
  '[OperationNoteSujet/API] Add OperationNoteSujet Success',
  props<{ success: string }>()
);
export const addOperationNoteSujetFailure = createAction(
  '[OperationNoteSujet/API] Add OperationNoteSujet Failure',
  props<{ error: any }>()
);

//UPDATE ONE

export const updateOperationNoteSujet = createAction(
  '[OperationNoteSujet/API] Update OperationNoteSujet',
  props<{ note: OperationNoteId; sujet: Update<OperationNoteSujetId> }>()
);
export const updateOperationNoteSujetSuccess = createAction(
  '[OperationNoteSujet/API] Update OperationNoteSujet Success',
  props<{ success: string }>()
);
export const updateOperationNoteSujetFailure = createAction(
  '[OperationNoteSujet/API] Update OperationNoteSujet Failure',
  props<{ error: any }>()
);

//DELETE ONE

export const deleteOperationNoteSujet = createAction(
  '[OperationNoteSujet/API] Delete OperationNoteSujet',
  props<{ note: OperationNoteId; id: string }>()
);
export const deleteOperationNoteSujetSuccess = createAction(
  '[OperationNoteSujet/API] Delete OperationNoteSujet Success',
  props<{ success: string }>()
);
export const deleteOperationNoteSujetFailure = createAction(
  '[OperationNoteSujet/API] Delete OperationNoteSujet Failure',
  props<{ error: any }>()
);

//DELETE MANY

export const deleteOperationNoteSujets = createAction(
  '[OperationNoteSujet/API] Delete OperationNoteSujets',
  props<{ note: OperationNoteId; ids: string[] }>()
);
export const deleteOperationNoteSujetsSuccess = createAction(
  '[OperationNoteSujet/API] Delete OperationNoteSujets Success',
  props<{ success: string }>()
);
export const deleteOperationNoteSujetsFailure = createAction(
  '[OperationNoteSujet/API] Delete OperationNoteSujets Failure',
  props<{ error: any }>()
);

//DELETE ALL
export const deleteAllOperationNoteSujets = createAction(
  '[OperationNoteSujet/API] Delete OperationNoteSujets',
  props<{ note: OperationNoteId; subtitleId: string; ids: string[] }>()
);
export const deleteAllOperationNoteSujetsSuccess = createAction(
  '[OperationNoteSujet/API] Delete OperationNoteSujets Success',
  props<{ success: string }>()
);
export const deleteAllOperationNoteSujetsFailure = createAction(
  '[OperationNoteSujet/API] Delete OperationNoteSujets Failure',
  props<{ error: any }>()
);

//RESET
export const resetOperationNoteSujets = createAction(
  '[OperationNoteSujet/API] Reset OperationNoteSujets'
);

//CLEAR
export const clearOperationNoteSujets = createAction(
  '[OperationNoteSujet/API] Clear OperationNoteSujets'
);
