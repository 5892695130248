import { ContactId } from './../components/contact/store/contact.model';
import { Update } from '@ngrx/entity';
import { Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  doc,
  docSnapshots,
  collectionChanges,
  getDoc,
  addDoc,
  setDoc,
  deleteDoc,
  serverTimestamp,
  DocumentData,
  Timestamp,
  query,
  where,
  arrayUnion,
  arrayRemove,
  collectionSnapshots,
  QueryDocumentSnapshot,
  CollectionReference,
  DocumentReference,
  getDocs,
  documentId,
} from '@angular/fire/firestore';
import { Observable, of, from, EMPTY } from 'rxjs';
import { switchMap, mergeMap, map, catchError } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { UserState } from '../components/user/store/user.reducer';
import * as fromUserSelector from '../components/user/store/user.selectors';
import { format, fromUnixTime, add } from 'date-fns';
import { ParticipantId } from './../components/participant/store/participant.model';
import { OperationSmallId } from './../components/operation/store/operation.model';

@Injectable({
  providedIn: 'any',
})
export class ContactOperationsService {
  today = new Date(Date.now());

  constructor(
    private db: Firestore,
    private fns: Functions,
    private userStore: Store<UserState>
  ) {}

  getAll(contactId: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !contactId) {
            return of([]);
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/contacts/${contactId}/operations`
            );

            const changes = collectionSnapshots(dbCollection).pipe(
              map((changes) => {
                return changes.map((snap) => {
                  const data = snap.data();
                  const id = snap.id;
                  return { id, ...data };
                });
              })
            );

            return changes;
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  getOne(contactId: string, id: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !contactId) {
            return of(null);
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/contacts/${contactId}/operations`
            );

            const documentRef = doc(dbCollection, id);
            return from(
              getDoc(documentRef)
                .then((snap) => {
                  const data = snap.data();
                  const id = snap.id;
                  return { id, ...data };
                })
                .catch((error) => error)
            ).pipe(mergeMap((res: any) => of(res)));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  addOne(
    contact: ContactId,
    operation: OperationSmallId,
    intervention: any
  ): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !contact || !operation) {
            return of(null);
          } else {
            const dbDocument = doc(
              this.db,
              `organisations/${user.organisation.id}/contacts/${contact.id}/operations/${operation.id}`
            );

            const entiteOperation = {
              intervention: {
                ...intervention,
                isInscription: false,
                isParticipant: false,
                isSortie: false,
                isDesinscription: false,
                isInMission: false,
                missions: [],
                modules: [],
                actions: [],
                projetpro: [],
                participants: [],
                yearString: new Date(Date.now()).getFullYear().toString(),
                monthString: new Date(Date.now()).getMonth().toString(),
                yearNumber: new Date(Date.now()).getFullYear(),
                monthNumber: new Date(Date.now()).getMonth(),
                sortiProgrammeQualitatif: null,
                motifSorties: null,
                typeSorties: null,
                isFinished: false,
                titulaire: null,
                autrePrecision: null,
                auteur: user,
                userUpdate: user,
              },
              operation: operation,
            };

            return from(
              setDoc(dbDocument, {
                ...entiteOperation,
              })
                .then(() => of(`Projet ajouté`))
                .catch((error) => of(error))
            ).pipe(mergeMap((res) => res));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  updateOne(contact: ContactId, operation: Partial<any>): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !contact || !operation) {
            return of(null);
          } else {
            const documentPath = doc(
              this.db,
              `organisations/${user.organisation.id}/contacts/${contact.id}/operations/${operation.id}`
            );

            return from(
              setDoc(
                documentPath,
                {
                  intervention: { ...operation.changes.intervention },
                  userUpdate: user,
                },
                { merge: true }
              )
                .then(() => of(`Intervention modifiée`))
                .catch((error) => of(error))
            ).pipe(mergeMap((res) => res));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  deleteOne(contact: ContactId, operation: OperationSmallId): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !contact || !operation) {
            return of(null);
          } else {
            const dbDocument = doc(
              this.db,
              `organisations/${user.organisation.id}/contacts/${contact.id}/operations/${operation.id}`
            );
            return from(
              deleteDoc(dbDocument)
                .then(() => of(`Projet retiré`))
                .catch((error) => error.message)
            ).pipe(mergeMap((res) => res));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  deleteAll(operationId: string, ids: string[]): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) {
            return of(null);
          } else {
            //CALL HTTP

            return of(null);
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }
}
