import {
  NzNotificationPlacement,
  NzNotificationService,
} from 'ng-zorro-antd/notification';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ParticipantSuivisService } from 'src/app/service/participant/suivi/participant-suivis.service';
import { mergeMap, map, catchError, of, tap } from 'rxjs';
import * as fromParticipantSuiviAction from './participant-suivi.actions';

const NOTIFICATION_PLACEMENT: NzNotificationPlacement = 'bottomRight';

@Injectable()
export class ParticipantSuiviEffects {
  loadParticipantSuivis$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromParticipantSuiviAction.loadParticipantSuivis),
      mergeMap((action) =>
        this.participantSuiviService.getAll(action.id).pipe(
          map((respons: any) => {
            const suivis: any[] = respons.data.participantSuivis;
            return fromParticipantSuiviAction.loadParticipantSuivisSuccess({
              suivis,
            });
          }),
          catchError((error) =>
            of(
              fromParticipantSuiviAction.loadParticipantSuivisFailure({ error })
            )
          )
        )
      )
    )
  );

  loadParticipantSuivi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromParticipantSuiviAction.loadParticipantSuivi),
      mergeMap((action) =>
        this.participantSuiviService
          .getOne(action.participantId, action.id)
          .pipe(
            map((respons: any) => {
              const suivi: any[] = respons.data.participantSuivi;
              return fromParticipantSuiviAction.loadParticipantSuiviSuccess({
                suivi,
              });
            }),
            catchError((error) =>
              of(
                fromParticipantSuiviAction.loadParticipantSuiviFailure({
                  error,
                })
              )
            )
          )
      )
    )
  );

  createParticipantSuivi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromParticipantSuiviAction.addParticipantSuivi),
      tap(() => {
        this.notification.info(
          'Nouveau',
          'Création du nouveau suivi en cours...',
          {
            nzKey: 'CREATE_PARTICIPANT_SUIVI',
            nzPlacement: NOTIFICATION_PLACEMENT,
            nzDuration: 6000,
          }
        );
      }),
      mergeMap((action) =>
        this.participantSuiviService.addOne(action.id, action.suivi).pipe(
          map((respons: any) => {
            const suivi: any = respons.data.createParticipantSuivi;
            this.notification.success('Nouveau', `Suivi ajouté avec succès`, {
              nzKey: 'CREATE_PARTICIPANT_SUIVI',
              nzPlacement: NOTIFICATION_PLACEMENT,
              nzDuration: 6000,
            });

            return fromParticipantSuiviAction.addParticipantSuiviSuccess({
              suivi,
            });
          }),
          catchError((error) => {
            this.onErrorNotification(error, 'CREATE_PARTICIPANT_SUIVI');
            return of(
              fromParticipantSuiviAction.addParticipantSuiviFailure({ error })
            );
          }),
          tap(() => {
            return fromParticipantSuiviAction.loadParticipantSuivis({
              id: action.id,
            });
          })
        )
      )
    )
  );

  updateParticipantSuivi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromParticipantSuiviAction.updateParticipantSuivi),
      tap(() => {
        this.notification.info(
          'Modification',
          'Modification du suivi en cours...',
          {
            nzKey: 'UPDATE_PARTICIPANT_SUIVI',
            nzPlacement: NOTIFICATION_PLACEMENT,
            nzDuration: 6000,
          }
        );
      }),
      mergeMap((action) =>
        this.participantSuiviService.updateOne(action.id, action.suivi).pipe(
          map((respons: any) => {
            const suivi: any = respons.data.updateParticipantSuivi;
            this.notification.success(
              'Modification',
              `Suivi modifié avec succès`,
              {
                nzKey: 'UPDATE_PARTICIPANT_SUIVI',
                nzPlacement: NOTIFICATION_PLACEMENT,
                nzDuration: 8000,
              }
            );

            return fromParticipantSuiviAction.updateParticipantSuiviSuccess({
              suivi,
            });
          }),
          catchError((error) => {
            this.onErrorNotification(error, 'UPDATE_PARTICIPANT_SUIVI');
            return of(
              fromParticipantSuiviAction.updateParticipantSuiviFailure({
                error,
              })
            );
          })
        )
      )
    )
  );

  deleteParticipantSuivi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromParticipantSuiviAction.deleteParticipantSuivi),
      tap(() => {
        this.notification.info(
          'Suppression',
          'Suppression du suivi en cours...',
          {
            nzKey: 'DELETE_PARTICIPANT_SUIVI',
            nzPlacement: NOTIFICATION_PLACEMENT,
            nzDuration: 6000,
          }
        );
      }),
      mergeMap((action) =>
        this.participantSuiviService
          .deleteOne(action.participantId, action.id)
          .pipe(
            map((respons: any) => {
              const success: any = respons.data.deleteParticipantSuivi;
              this.notification.success(
                'Suppression',
                `Suivi supprimé avec succès`,
                {
                  nzKey: 'DELETE_PARTICIPANT_SUIVI',
                  nzPlacement: NOTIFICATION_PLACEMENT,
                  nzDuration: 8000,
                }
              );

              return fromParticipantSuiviAction.deleteParticipantSuiviSuccess({
                success,
              });
            }),
            catchError((error) => {
              this.onErrorNotification(error, 'DELETE_PARTICIPANT_SUIVI');
              return of(
                fromParticipantSuiviAction.deleteParticipantSuiviFailure({
                  error,
                })
              );
            }),
            tap(() => {
              return fromParticipantSuiviAction.loadParticipantSuivis({
                id: action.id,
              });
            })
          )
      )
    )
  );

  deleteParticipantSuivis$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromParticipantSuiviAction.deleteParticipantSuivis),
      tap(() => {
        this.notification.info(
          'Tout supprimer',
          'Suppression des suivis en cours...',
          {
            nzKey: 'DELETE_ALL_PARTICIPANT_SUIVIS',
            nzPlacement: NOTIFICATION_PLACEMENT,
            nzDuration: 6000,
          }
        );
      }),
      mergeMap((action) =>
        this.participantSuiviService.deleteAll(action.id, action.ids).pipe(
          map((respons: any) => {
            const success: any = respons.data.deleteAccompagnements;
            this.notification.success(
              'Tout supprimer',
              `Les suivis ont été supprimés avec succès`,
              {
                nzKey: 'DELETE_ALL_PARTICIPANT_SUIVIS',
                nzPlacement: NOTIFICATION_PLACEMENT,
                nzDuration: 8000,
              }
            );

            return fromParticipantSuiviAction.deleteParticipantSuivisSuccess({
              success,
            });
          }),
          catchError((error) => {
            this.onErrorNotification(error, 'DELETE_ALL_PARTICIPANT_SUIVIS');
            return of(
              fromParticipantSuiviAction.deleteParticipantSuivisFailure({
                error,
              })
            );
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private participantSuiviService: ParticipantSuivisService,
    private notification: NzNotificationService
  ) {}

  onErrorNotification(error: any, key: string): void {
    this.notification.error('Error', error.message, {
      nzDuration: 10000,
      nzAnimate: true,
      nzPlacement: NOTIFICATION_PLACEMENT,
      nzKey: key ? key : '',
    });
  }
}
