import { Injectable } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import { ParticipantId } from 'src/app/components/participant/store/participant.model';

@Injectable({
  providedIn: 'any',
})
export class ParticipantDiagnosticExportService {
  constructor() {}

  exportPDF(
    participant: ParticipantId | any,
    diagnostic: any
  ): Observable<any> {
    try {
      return EMPTY;
    } catch (error) {
      return of(error);
    }
  }

  exportWORD(
    participant: ParticipantId | any,
    diagnostic: any
  ): Observable<any> {
    try {
      return EMPTY;
    } catch (error) {
      return of(error);
    }
  }
}
