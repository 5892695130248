import { OperationNoteParticipantService } from './../../../service/operation-note-participant.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NzMessageService } from 'ng-zorro-antd/message';
import { catchError, delay, map, mergeMap, of } from 'rxjs';
import * as fromOperationNoteParticipantAction from './operation-note-participant.actions';
import { OperationNoteParticipantId } from './operation-note-participant.model';

@Injectable()
export class OperationNoteParticipantEffects {
  source = of(1, 2, 3);

  //LOAD ALL
  loadOperationNoteParticipants$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOperationNoteParticipantAction.loadOperationNoteParticipants),
      mergeMap((action) =>
        this.operationNoteParticipantService.getAll(action.note).pipe(
          delay(2000),
          map((participants: OperationNoteParticipantId[]) =>
            fromOperationNoteParticipantAction.loadOperationNoteParticipantsSuccess(
              {
                participants,
              }
            )
          ),
          catchError((error) => {
            this.message.error(error, { nzDuration: 6000 });

            return of(
              fromOperationNoteParticipantAction.loadOperationNoteParticipantsFailure(
                { error }
              )
            );
          })
        )
      )
    )
  );

  //ADD
  addOperationNoteParticipant$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromOperationNoteParticipantAction.addOperationNoteParticipant),
        mergeMap((action) =>
          this.operationNoteParticipantService
            .addOne(action.note, action.participant)
            .pipe(
              map((success: string) => {
                this.message.success(success, { nzDuration: 6000 });
                return fromOperationNoteParticipantAction.addOperationNoteParticipantSuccess(
                  {
                    success,
                  }
                );
              }),
              catchError((error) => {
                this.message.error(error, { nzDuration: 6000 });
                return of(
                  fromOperationNoteParticipantAction.addOperationNoteParticipantFailure(
                    { error }
                  )
                );
              })
            )
        )
      ),
    { dispatch: false }
  );

  //UPDATE
  updateOperationNoteParticipant$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          fromOperationNoteParticipantAction.updateOperationNoteParticipant
        ),

        mergeMap((action) =>
          this.operationNoteParticipantService
            .updateOne(action.note, action.participant.changes)
            .pipe(
              map((success: any) => {
                this.message.success(success, { nzDuration: 6000 });
                return fromOperationNoteParticipantAction.updateOperationNoteParticipantSuccess(
                  {
                    success,
                  }
                );
              }),
              catchError((error) => {
                this.message.error(error, { nzDuration: 6000 });
                return of(
                  fromOperationNoteParticipantAction.updateOperationNoteParticipantFailure(
                    { error }
                  )
                );
              })
            )
        )
      ),
    { dispatch: false }
  );

  //DELETE
  deleteOperationNoteParticipant$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          fromOperationNoteParticipantAction.deleteOperationNoteParticipant
        ),
        mergeMap((action) =>
          this.operationNoteParticipantService
            .deleteOne(action.note, action.id)
            .pipe(
              map((success: any) => {
                this.message.success(success, { nzDuration: 6000 });

                return fromOperationNoteParticipantAction.deleteOperationNoteParticipantSuccess(
                  {
                    success,
                  }
                );
              }),
              catchError((error) => {
                this.message.error(error, { nzDuration: 6000 });

                return of(
                  fromOperationNoteParticipantAction.deleteOperationNoteParticipantFailure(
                    { error }
                  )
                );
              })
            )
        )
      ),
    { dispatch: false }
  );

  //DELETE ALL
  deleteAllOperationNoteParticipants$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          fromOperationNoteParticipantAction.deleteOperationNoteParticipants
        ),

        mergeMap((action) =>
          this.operationNoteParticipantService.deleteAll(action.note).pipe(
            map((respons: any) => {
              const { data } = respons;
              const success = data.deleteAllOperationNoteParticipants;

              this.message.success(success, { nzDuration: 6000 });

              return fromOperationNoteParticipantAction.deleteOperationNoteParticipantsSuccess(
                {
                  success,
                }
              );
            }),
            catchError((error) => {
              this.message.error(error, { nzDuration: 6000 });

              return of(
                fromOperationNoteParticipantAction.deleteOperationNoteParticipantsFailure(
                  { error }
                )
              );
            })
          )
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private message: NzMessageService,
    private operationNoteParticipantService: OperationNoteParticipantService
  ) {}
}
