<nz-card nzSize="small">
  <nz-descriptions nzSize="small" nzBordered>
    <nz-descriptions-item nzTitle="Etablissement" nzSpan="3">
      {{
        candidature?.prescripteur?.etablissement?.uniteLegale
          ?.denominationUniteLegale
      }}
    </nz-descriptions-item>
    <nz-descriptions-item nzTitle="Référent" nzSpan="3">
      {{ candidature?.prescripteur?.contact | displayName | titlecase }}
    </nz-descriptions-item>
    <nz-descriptions-item nzTitle="Candidat" nzSpan="3">
      {{ candidature | displayName | titlecase }}
    </nz-descriptions-item>
    <nz-descriptions-item nzTitle="Situation" nzSpan="3">
      <nz-space nzDirection="vertical">
        <nz-tag nzColor="blue" *nzSpaceItem>
          Critère niveau 1 :
          {{ candidature.diagnostic?.administratif?.criteres1?.label }}
        </nz-tag>
        <nz-tag nzColor="blue" *nzSpaceItem>
          Critère niveau 2 :
          <span
            *ngFor="
              let critere of candidature.diagnostic?.administratif?.criteres2;
              let i = index
            "
          >
            {{ critere?.item }},
          </span>
        </nz-tag>
        <nz-tag nzColor="blue" *nzSpaceItem>
          Passe IAE :
          {{ candidature.diagnostic?.administratif?.isPassIae ? "OUI" : "NON" }}
        </nz-tag>
        <nz-tag nzColor="blue" *nzSpaceItem>
          Réfugié :
          {{ candidature.diagnostic?.administratif?.typeCarteSejour?.titre }}
        </nz-tag>
      </nz-space>
    </nz-descriptions-item>
    <nz-descriptions-item nzTitle="Distance" nzSpan="3" *ngIf="isDistance">
      <nz-descriptions nzSize="small" *ngIf="distance$ | async as distance">
        <nz-descriptions-item>
          <span *ngIf="!(distanceLoading$ | async)">{{
            distance?.distanceText
          }}</span>
          <i nz-icon nzType="loading" *ngIf="distanceLoading$ | async"></i>
        </nz-descriptions-item>
      </nz-descriptions>
    </nz-descriptions-item>
  </nz-descriptions>
</nz-card>
