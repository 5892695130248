import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AidState, aidsesFeatureKey, selectAll } from './aids.reducer';

export const selectAidsState =
  createFeatureSelector<AidState>(aidsesFeatureKey);

export const loading = createSelector(
  selectAidsState,
  (state: AidState) => state.loading
);

export const loadingAids = createSelector(
  selectAidsState,
  (state: AidState) => state.loadingAids
);

export const aids = createSelector(
  selectAidsState,
  (state: AidState) => state.aids
);

export const filters = createSelector(
  selectAidsState,
  (state: AidState) => state.filters
);

export const error = createSelector(
  selectAidsState,
  (state: AidState) => state.error
);
