import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-geo-territoire-search-select',
  templateUrl: './geo-territoire-search-select.component.html',
  styleUrls: ['./geo-territoire-search-select.component.scss']
})
export class GeoTerritoireSearchSelectComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
