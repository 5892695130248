import { Pipe, PipeTransform } from '@angular/core';
import { format } from 'date-fns';

@Pipe({
  name: 'dateToString',
})
export class DateToStringPipe implements PipeTransform {
  transform(date: Date): string {
    if (!date) return '';
    return format(date, 'dd/MM/yyyy');
  }
}
