<ng-container *ngIf="user$ | async as user">
  <ng-container *ngIf="operation$ | async as operation">
    <div
      nzAlign="top"
      nzJustify="start"
      cdkDropListGroup
      class="group_container"
      *ngIf="!(loading$ | async); else taskLoading"
    >
      <div nzAlign="top" class="container content_todo">
        <app-operation-task-header
          [isButton]="true"
          [operation$]="operation$"
          [user$]="user$"
          [taskSize]="tasks$ | async | size"
          (add)="onNew(operation)"
          [title]="'À faire'"
          [count]="(tasks$ | async | size) ? (tasks$ | async | size) : 0"
          [icon]="''"
        ></app-operation-task-header>

        <nz-space></nz-space>
        <div
          cdkDropList
          #todoList="cdkDropList"
          [cdkDropListData]="tasks$ | async"
          (cdkDropEntered)="entered($event)"
          (cdkDropExited)="exited($event)"
          [cdkDropListConnectedTo]="[doneList, testList, verifedList]"
          class="list"
          (cdkDropListDropped)="drop($event, operation)"
        >
          <app-operation-task-item
            [task]="item"
            class="box"
            *ngFor="let item of tasks$ | async; trackBy: trackBy"
            cdkDrag
          ></app-operation-task-item>
        </div>
      </div>

      <div nzSpan="6" class="container content_inprogress">
        <app-operation-task-header
          [title]="'En cours'"
          [count]="
            (tasksInProgress$ | async | size)
              ? (tasksInProgress$ | async | size)
              : 0
          "
          [icon]="'security-scan'"
        ></app-operation-task-header>
        <nz-space></nz-space>

        <div
          cdkDropList
          #testList="cdkDropList"
          [cdkDropListData]="tasksInProgress$ | async"
          (cdkDropEntered)="entered($event)"
          (cdkDropExited)="exited($event)"
          [cdkDropListConnectedTo]="[todoList, doneList, verifedList]"
          class="list"
          (cdkDropListDropped)="drop($event, operation)"
        >
          <app-operation-task-item
            class="box"
            *ngFor="let item of tasksInProgress$ | async; trackBy: trackBy"
            cdkDrag
            [task]="item"
            [type]="'tested'"
          ></app-operation-task-item>
        </div>
      </div>
      <div nzSpan="6" class="container content_verified">
        <app-operation-task-header
          [title]="'Vérifiées'"
          [count]="(verifed$ | async | size) ? (verifed$ | async | size) : 0"
          [icon]="'safety'"
        ></app-operation-task-header>
        <nz-space></nz-space>

        <div
          cdkDropList
          #verifedList="cdkDropList"
          [cdkDropListData]="verifed$ | async"
          (cdkDropEntered)="entered($event)"
          (cdkDropExited)="exited($event)"
          [cdkDropListConnectedTo]="[todoList, doneList, testList]"
          class="list"
          (cdkDropListDropped)="drop($event, operation)"
        >
          <app-operation-task-item
            class="box"
            *ngFor="let item of verifed$ | async; trackBy: trackBy"
            cdkDrag
            [task]="item"
            [type]="'verifed'"
          ></app-operation-task-item>
        </div>
      </div>
      <div nzSpan="6" class="container content_done">
        <app-operation-task-header
          [title]="'Terminées'"
          [count]="(dones$ | async | size) ? (dones$ | async | size) : 0"
          [icon]="'check-circle'"
        ></app-operation-task-header>
        <nz-space></nz-space>

        <div
          cdkDropList
          #doneList="cdkDropList"
          [cdkDropListData]="dones$ | async"
          (cdkDropEntered)="entered($event)"
          (cdkDropExited)="exited($event)"
          [cdkDropListConnectedTo]="[todoList, testList, verifedList]"
          class="list"
          (cdkDropListDropped)="drop($event, operation)"
        >
          <app-operation-task-item
            class="box"
            *ngFor="let item of dones$ | async; trackBy: trackBy"
            cdkDrag
            [task]="item"
            [type]="'done'"
          ></app-operation-task-item>
        </div>
      </div>
    </div>

    <ng-template #noData>
      <div class="list">
        <nz-empty></nz-empty>
      </div>
    </ng-template>
  </ng-container>

  <ng-template #taskFormTitle>
    <app-header-title
      [icon]="'playlist_add_check'"
      [title]="title"
    ></app-header-title>
  </ng-template>
</ng-container>

<ng-template #taskLoading>
  <app-loading-animation [loading$]="loading$"></app-loading-animation>
</ng-template>
