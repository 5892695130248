import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-participant-indicator',
  templateUrl: './participant-indicator.component.html',
  styleUrls: ['./participant-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantIndicatorComponent implements OnInit, OnChanges {
  green: string = '#52B788';
  red: string = '#D62839';
  blue: string = '#062949';

  @Input() frenchLevel: boolean = false;
  @Input() workReady: boolean = false;
  @Input() house: boolean = false;
  @Input() lawAcces: boolean = false;
  @Input() mobility: boolean = false;
  @Input() finance: boolean = false;
  @Input() justice: boolean = false;
  @Input() disponility: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}
}
