import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numbersPercent',
})
export class NumbersPercentPipe implements PipeTransform {
  transform(values: number[], ...args: unknown[]): number {
    if (!values || !values?.length) {
      return 0;
    }

    if (values.length) {
      const value1 = values[0];
      const value2 = values[1];
      const percent: number = (value1 / value2) * 100;

      return percent;
    }else{
      return 0
    }
  }
}
