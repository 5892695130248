import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { OperationId } from 'src/app/components/operation/store/operation.model';

@Component({
  selector: 'app-side-navigation-submenu-operation',
  templateUrl: './side-navigation-submenu-operation.component.html',
  styleUrls: ['./side-navigation-submenu-operation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideNavigationSubmenuOperationComponent implements OnInit {
  tooltipDelay: number = 0.5;

  @Input() activeRouter: any = null;
  @Input() collapse: boolean = true;
  @Input() theme: string = 'light';
  @Input() operation: OperationId | any;
  @Output() select = new EventEmitter<string>(false);

  menuItems = [
    {
      title: 'Tâches',
      icon: 'checklist',
      event: 'operation_tasks',
    },
  ];

  constructor() {}

  ngOnInit(): void {}

  onSelect(event: string): void {
    if (!event) return;
    this.select.emit(event);
  }
}
