<ng-container *ngIf="orientation === 'vertical'">
  <nz-row nzAlign="top" nzGutter="4" nzJustify="center">
    <nz-col nzSpan="16">
      <nz-card nzBorderless nzSize="small">
        <nz-statistic
          [nzValue]="(total | number)!"
          [nzTitle]="type"
          [nzPrefix]="prefixTpl"
        ></nz-statistic>
        <ng-template #prefixTpl
          ><i
            nz-icon
            [nzType]="
              type === 'Entités'
                ? 'bank'
                : type === 'Contacts' || type === 'Participants'
                ? 'user'
                : type
            "
            class="indicatorsIcon"
          ></i
        ></ng-template>
      </nz-card>

      <nz-card nzBorderless nzSize="small">
        <nz-statistic
          *ngIf="objectif"
          [nzValue]="(total | number: '1.0-2')!"
          [nzTitle]="'Objectif'"
          [nzPrefix]="preAimfixTpl"
          [nzSuffix]="'/ ' + objectif"
        ></nz-statistic>
        <ng-template #preAimfixTpl
          ><i nz-icon nzType="aim" class="indicatorsIcon"></i
        ></ng-template>
        <nz-card nzBorderless> </nz-card>
      </nz-card>

      <nz-card nzBorderless nzSize="small">
        <nz-statistic
          *ngIf="objectif && ([total, objectif] | numbersPercent) >= 95"
          [nzValue]="([total, objectif] | numbersPercent | number: '1.0-2')!"
          [nzTitle]="'Progression'"
          [nzPrefix]="prefixTplOne"
          [nzSuffix]="'%'"
          [nzValueStyle]="{ color: '#3F8600' }"
        ></nz-statistic>
        <ng-template #prefixTplOne
          ><i nz-icon nzType="arrow-up"></i
        ></ng-template>

        <nz-statistic
          *ngIf="
            objectif &&
            ([total, objectif] | numbersPercent) > 50 &&
            ([total, objectif] | numbersPercent) < 95
          "
          [nzValue]="([total, objectif] | numbersPercent | number: '1.0-2')!"
          [nzTitle]="'Progression'"
          [nzPrefix]="prefixTplTwoMiddle"
          [nzSuffix]="'%'"
          [nzValueStyle]="{ color: '#ffa940' }"
        ></nz-statistic>
        <ng-template #prefixTplTwoMiddle>
          <i nz-icon nzType="vertical-align-middle" nzTheme="outline"></i>
        </ng-template>

        <nz-statistic
          *ngIf="objectif && ([total, objectif] | numbersPercent) <= 50"
          [nzValue]="([total, objectif] | numbersPercent | number: '1.0-2')!"
          [nzTitle]="'Progression'"
          [nzPrefix]="prefixTplTwo"
          [nzSuffix]="'%'"
          [nzValueStyle]="{ color: '#CF1322' }"
        ></nz-statistic>
        <ng-template #prefixTplTwo
          ><i nz-icon nzType="arrow-up"></i
        ></ng-template>
      </nz-card>
    </nz-col>
    <nz-col nzSpan="4">
      <small *ngIf="extraInfo1">{{ extraInfo1 }}</small>
    </nz-col>
    <nz-col nzSpan="4">
      <small *ngIf="extraInfo2">{{ extraInfo2 }}</small>
    </nz-col>
  </nz-row>
</ng-container>

<ng-container *ngIf="orientation === 'horizontal'">
  <nz-row nzAlign="top" nzGutter="4" nzJustify="center">
    <nz-col nzSpan="8"></nz-col>
    <nz-col nzSpan="8">
      <nz-col nzSpan="6">
        <nz-card nzBorderless nzSize="small">
          <nz-statistic
            [nzValue]="(total | number)!"
            [nzTitle]="'Entites'"
            [nzPrefix]="prefixTpl"
          ></nz-statistic>
          <ng-template #prefixTpl
            ><i nz-icon nzType="bank" class="indicatorsIcon"></i
          ></ng-template>
        </nz-card>
        <nz-card nzBorderless nzSize="small">
          <nz-statistic
            *ngIf="objectif"
            [nzValue]="(total | number: '1.0-2')!"
            [nzTitle]="'Objectif'"
            [nzPrefix]="preAimfixTpl"
            [nzSuffix]="'/ ' + objectif"
          ></nz-statistic>
          <ng-template #preAimfixTpl
            ><i nz-icon nzType="aim" class="indicatorsIcon"></i
          ></ng-template>
          <nz-card nzBorderless> </nz-card>
        </nz-card>

        <nz-card nzBorderless nzSize="small">
          <nz-statistic
            *ngIf="objectif && ([total, objectif] | numbersPercent) >= 95"
            [nzValue]="([total, objectif] | numbersPercent | number: '1.0-2')!"
            [nzTitle]="'Progression'"
            [nzPrefix]="prefixTplOne"
            [nzSuffix]="'%'"
            [nzValueStyle]="{ color: '#3F8600' }"
          ></nz-statistic>
          <ng-template #prefixTplOne
            ><i nz-icon nzType="arrow-up"></i
          ></ng-template>

          <nz-statistic
            *ngIf="
              objectif &&
              ([total, objectif] | numbersPercent) > 50 &&
              ([total, objectif] | numbersPercent) < 95
            "
            [nzValue]="([total, objectif] | numbersPercent | number: '1.0-2')!"
            [nzTitle]="'Progression'"
            [nzPrefix]="prefixTplTwoMiddle"
            [nzSuffix]="'%'"
            [nzValueStyle]="{ color: '#ffa940' }"
          ></nz-statistic>
          <ng-template #prefixTplTwoMiddle>
            <i nz-icon nzType="vertical-align-middle" nzTheme="outline"></i>
          </ng-template>

          <nz-statistic
            *ngIf="objectif && ([total, objectif] | numbersPercent) <= 50"
            [nzValue]="([total, objectif] | numbersPercent | number: '1.0-2')!"
            [nzTitle]="'Progression'"
            [nzPrefix]="prefixTplTwo"
            [nzSuffix]="'%'"
            [nzValueStyle]="{ color: '#CF1322' }"
          ></nz-statistic>
          <ng-template #prefixTplTwo
            ><i nz-icon nzType="arrow-up"></i
          ></ng-template>
        </nz-card>
      </nz-col>
    </nz-col>
    <nz-col nzSpan="8"></nz-col>
  </nz-row>
</ng-container>
