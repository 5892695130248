import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { add, differenceInCalendarDays } from 'date-fns';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-entite-validator',
  templateUrl: './entite-validator.component.html',
  styleUrls: ['./entite-validator.component.scss'],
})
export class EntiteValidatorComponent implements OnInit {
  defaultColor: string = '#d9d9d9';
  today = new Date(Date.now());
  radioValue: any = null;
  entitesOperation: Observable<any> = of(null);
  roleForm: UntypedFormGroup = this.fb.group({});
  role: UntypedFormControl = new UntypedFormControl();
  isParrain: UntypedFormControl = new UntypedFormControl(false);
  isFinanceur: UntypedFormControl = new UntypedFormControl(false);
  isPrescripteur: UntypedFormControl = new UntypedFormControl(false);
  isSupport: UntypedFormControl = new UntypedFormControl(false);
  isTitulaire: UntypedFormControl = new UntypedFormControl(false);
  isSousTraitant: UntypedFormControl = new UntypedFormControl(false);
  isCoTraitant: UntypedFormControl = new UntypedFormControl(false);

  isPilotage: UntypedFormControl = new UntypedFormControl(false);
  isAssistance: UntypedFormControl = new UntypedFormControl(false);
  isAutre: UntypedFormControl = new UntypedFormControl(false);
  isControl: UntypedFormControl = new UntypedFormControl(false);
  autrePrecision: UntypedFormControl = new UntypedFormControl('');
  titulaire: UntypedFormControl = new UntypedFormControl('');
  hoursBenevolat: UntypedFormControl = new UntypedFormControl(0);
  dateStartIntervention: UntypedFormControl = new UntypedFormControl(this.today);
  dateEndIntervention: UntypedFormControl = new UntypedFormControl(
    add(this.today, { months: 6 })
  );
  duration: UntypedFormControl = new UntypedFormControl(6);

  disabledDate = (current: Date): boolean =>
    // Can not select days before today and today
    differenceInCalendarDays(this.today, current) > 0;

  @Output() cancel = new EventEmitter<boolean>(false);
  @Output() validate = new EventEmitter<any>(false);

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.form();
    this.onSetDateStart();
  }

  form(): void {
    this.formInit();
    this.formCreate();
  }

  formInit(): void {
    this.role = new UntypedFormControl('', Validators.required);
    this.isParrain = new UntypedFormControl(false);
    this.isFinanceur = new UntypedFormControl(true);
    this.isPrescripteur = new UntypedFormControl(false);
    this.isSupport = new UntypedFormControl(false);
    this.isTitulaire = new UntypedFormControl(false);
    this.isSousTraitant = new UntypedFormControl(false);
    this.isCoTraitant = new UntypedFormControl(false);

    this.isPilotage = new UntypedFormControl(false);
    this.isAssistance = new UntypedFormControl(false);
    this.isControl = new UntypedFormControl(false);
    this.isAutre = new UntypedFormControl(false);
    this.hoursBenevolat = new UntypedFormControl(0);
    this.dateStartIntervention = new UntypedFormControl(this.today);
    this.dateEndIntervention = new UntypedFormControl(add(this.today, { months: 6 }));
    this.duration = new UntypedFormControl(6);
    this.autrePrecision = new UntypedFormControl('');
    this.titulaire = new UntypedFormControl('');
  }

  formCreate(): void {
    this.roleForm = this.fb.group({
      role: this.role,
      isParrain: this.isParrain,
      isFinanceur: this.isFinanceur,
      isPrescripteur: this.isPrescripteur,
      isSupport: this.isSupport,
      isTitulaire: this.isTitulaire,
      isSousTraitant: this.isSousTraitant,
      isCoTraitant: this.isCoTraitant,
      isPilotage: this.isPilotage,
      isAssistance: this.isAssistance,
      isControl: this.isControl,
      isAutre: this.isAutre,
      dateStartIntervention: this.dateStartIntervention,
      dateEndIntervention: this.dateEndIntervention,
      duration: this.duration,
      hoursBenevolat: this.hoursBenevolat,
      autrePrecision: this.autrePrecision,
      titulaire: this.titulaire,
    });
  }

  onSetRole(event: any): void {
    switch (event) {
      case 'financeur':
        this.roleForm.patchValue({
          isFinanceur: true,
          isParrain: false,
          isPrescripteur: false,
          isSupport: false,
          isTitulaire: false,
          isSousTraitant: false,
          isCoTraitant: false,
          isPilotage: false,
          isAssistance: false,
          isControl: false,
          isAutre: false,
          autrePrecision: '',
          titulaire: null,
        });
        break;
      case 'prescripteur':
        this.roleForm.patchValue({
          isFinanceur: false,
          isParrain: false,
          isPrescripteur: true,
          isSupport: false,
          isTitulaire: false,
          isSousTraitant: false,
          isCoTraitant: false,

          isPilotage: false,
          isAssistance: false,
          isControl: false,
          isAutre: false,

          autrePrecision: '',
          titulaire: null,
        });
        break;
      case 'support':
        this.roleForm.patchValue({
          isFinanceur: false,
          isParrain: false,
          isPrescripteur: false,
          isSupport: true,
          isTitulaire: false,
          isSousTraitant: false,
          isPilotage: false,
          isAssistance: false,
          isCoTraitant: false,

          isControl: false,
          isAutre: false,

          autrePrecision: '',
          titulaire: null,
        });

        break;
      case 'titulaire':
        this.roleForm.patchValue({
          isFinanceur: false,
          isParrain: false,
          isPrescripteur: false,
          isSupport: false,
          isTitulaire: true,
          isSousTraitant: false,
          isPilotage: false,
          isAssistance: false,
          isCoTraitant: false,

          isControl: false,
          isAutre: false,

          autrePrecision: '',
        });

        break;
      case 'parrainage':
        this.roleForm.patchValue({
          isFinanceur: false,
          isParrain: true,
          isPrescripteur: false,
          isSupport: false,
          isTitulaire: false,
          isSousTraitant: false,
          isCoTraitant: false,

          isPilotage: false,
          isAssistance: false,
          isControl: false,
          isAutre: false,

          autrePrecision: '',
          titulaire: null,
        });

        break;
      case 'sousTraitant':
        this.roleForm.patchValue({
          isFinanceur: false,
          isParrain: false,
          isPrescripteur: false,
          isSupport: false,
          isTitulaire: false,
          isSousTraitant: true,
          isCoTraitant: false,

          isPilotage: false,
          isAssistance: false,
          isControl: false,
          isAutre: false,

          autrePrecision: '',
          titulaire: null,
        });

        break;
      case 'pilotage':
        this.roleForm.patchValue({
          isFinanceur: false,
          isParrain: false,
          isPrescripteur: false,
          isSupport: false,
          isTitulaire: false,
          isSousTraitant: false,
          isCoTraitant: false,

          isPilotage: true,
          isAssistance: false,
          isControl: false,
          isAutre: false,

          autrePrecision: '',
          titulaire: null,
        });

        break;
      case 'assistance':
        this.roleForm.patchValue({
          isFinanceur: false,
          isParrain: false,
          isPrescripteur: false,
          isSupport: false,
          isTitulaire: false,
          isSousTraitant: false,
          isCoTraitant: false,

          isPilotage: false,
          isAssistance: true,
          isControl: false,
          isAutre: false,

          autrePrecision: '',
          titulaire: null,
        });

        break;
      case 'control':
        this.roleForm.patchValue({
          isFinanceur: false,
          isParrain: false,
          isPrescripteur: false,
          isSupport: false,
          isTitulaire: false,
          isSousTraitant: false,
          isPilotage: false,
          isAssistance: false,
          isControl: true,
          isAutre: false,

          autrePrecision: '',
          titulaire: null,
        });

        break;
      case 'cotraitant':
        this.roleForm.patchValue({
          isFinanceur: false,
          isParrain: false,
          isPrescripteur: false,
          isSupport: false,
          isTitulaire: false,
          isSousTraitant: false,
          isCoTraitant: true,

          isPilotage: false,
          isAssistance: false,
          isControl: false,
          isAutre: false,
          autrePrecision: '',
          titulaire: null,
        });
        break;
      case 'autre':
        this.roleForm.patchValue({
          isFinanceur: false,
          isParrain: false,
          isPrescripteur: false,
          isSupport: false,
          isTitulaire: false,
          isSousTraitant: false,
          isCoTraitant: false,

          isPilotage: false,
          isAssistance: false,
          isControl: false,
          isAutre: true,
          autrePrecision: '',
          titulaire: null,
        });

        break;
      default:
        break;
    }
  }

  onSetDateStart(): void {
    this.roleForm
      .get('dateStartIntervention')
      ?.valueChanges.subscribe((date: Date) => {
        if (!date) {
          return;
        } else {
          this.roleForm.patchValue({
            dateEndIntervention: add(date, { months: 2 }),
          });
        }
      });
  }

  onValidate(): void {
    if (!this.roleForm.valid) {
      return;
    } else {
      const intervention = this.roleForm.value;
      this.validate.emit(intervention);
    }
  }

  onCancel(): void {
    this.cancel.emit(true);
  }
}
