import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-person-item',
  templateUrl: './person-item.component.html',
  styleUrls: ['./person-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonItemComponent implements OnInit, OnChanges {
  @Input() person: any;
  @Output() clear = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {}

  onClear(): void {
    this.clear.emit(true);
  }
}
