import { TodoId } from 'src/app/models/todo.model';
import { uniqBy } from 'lodash';
import { TodoSujet } from './../../store/todo.model';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder, FormControl, UntypedFormArray } from '@angular/forms';
import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ChangeDetectionStrategy,
  OnDestroy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-todo-form-sujets',
  templateUrl: './todo-form-sujets.component.html',
  styleUrls: ['./todo-form-sujets.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TodoFormSujetsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() user: any = null;
  @Input() todo: any = null;
  @Input() source = new BehaviorSubject<TodoSujet[] | any>([]);
  @Output() add = new EventEmitter<any>(false);
  @Output() update = new EventEmitter<any>(false);
  @Output() delete = new EventEmitter<TodoSujet[]>(false);

  green: string = '#73d13d';
  red: string = '#ff4d4f';
  subscribe = new Subject();
  todoForm: UntypedFormGroup = this.fb.group({});
  sujets: UntypedFormArray = this.fb.array([]);
  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.formCreate();
  }

  ngOnChanges(changes: SimpleChanges): any {
    if (!changes.source?.currentValue) {
      return null;
    } else {
      this.formSet(changes.source?.currentValue);
    }
  }

  formCreate(): void {
    this.todoForm = this.fb.group({
      sujets: this.sujets,
    });
  }

  formSet(array: any): any {
    this.source.subscribe((sujets: TodoSujet[] | any) => {
      if (!sujets?.length) {
        this.sujets = this.fb.array([]);
      } else {
        sujets.forEach((sujet: TodoSujet) => this.addSujet(sujet));
      }
    });
  }

  get sujetsArray(): UntypedFormArray {
    return this.todoForm.get('sujets') as UntypedFormArray;
  }

  addSujet(sujet: any): void {
    const group = this.fb.group({ ...sujet });
    this.sujets.push(group);
  }

  updateSujet(item: any, user: any): void {}

  deleteSujet(sujet: TodoSujet): void {
    const sujets = this.sujets.value;
    const nextSujets = sujets?.length
      ? sujets.filter((el: TodoSujet) => el.id !== sujet.id)
      : [];

    //this.delete.emit(nextSujets);
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }
}
