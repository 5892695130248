import { ParticipantSuiviGQL } from './../../GRAPHQL/queries/PARTICIPANT/suivis/query-participant-suivi.service';
import { AllParticipantSuivisGQL } from './../../GRAPHQL/queries/PARTICIPANT/suivis/query-participant-suivis.service';
import { Apollo, gql } from 'apollo-angular';
import { ItemEventId } from '../../../features/suivi/components/item-event/itemEvent.model';
import { Injectable } from '@angular/core';
import { Observable, of, EMPTY } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'any',
})
export class ParticipantSuivisService {
  CREATE_PARTICIPANT_SUIVI = gql`
    mutation CreateParticipantSuivi($id: ID!, $suivi: SuiviInput!) {
      createParticipantSuivi(id: $id, suivi: $suivi) {
        id
        auteur {
          avatar
          displayName
          fonction
          setting {
            color
          }
        }
        dateStart
        dateUpdate
        hourStart
        hourUpdate
        object
        title
        type
        text
        textHtml
        userUpdate {
          avatar
          displayName
          fonction
          setting {
            color
          }
        }
      }
    }
  `;
  UPDATE_PARTICIPANT_SUIVI = gql`
    mutation UpdateParticipantSuivi($id: ID!, $suivi: SuiviUpdateInput!) {
      updateParticipantSuivi(id: $id, suivi: $suivi) {
        id
        auteur {
          avatar
          displayName
          fonction
          setting {
            color
          }
        }
        dateStart
        dateUpdate
        hourStart
        hourUpdate
        object
        title
        type
        text
        textHtml
        userUpdate {
          avatar
          displayName
          fonction
          setting {
            color
          }
        }
      }
    }
  `;
  DELETE_PARTICIPANT_SUIVI = gql`
    mutation DeleteParticipantSuivi($participantId: ID!, $id: ID!) {
      deleteParticipantSuivi(participantId: $participantId, id: $id)
    }
  `;
  DELETE_PARTICIPANT_SUIVIS = gql`
    mutation DeleteParticipantSuivis($id: ID!) {
      deleteParticipantSuivis(id: $id)
    }
  `;

  constructor(
    private apollo: Apollo,
    private allParticipantSuivisGQL: AllParticipantSuivisGQL,
    private participantSuivisGQL: ParticipantSuiviGQL
  ) {}

  getAll(id: string): Observable<any> {
    try {
      if (!id) return EMPTY;
      return this.allParticipantSuivisGQL
        .watch({
          id,
        })
        .valueChanges.pipe(
          map(({ data, loading, error }) => {
            return { data, loading, error };
          })
        );
    } catch (error) {
      return of(error);
    }
  }

  getOne(participantId: string, id: string): Observable<any> {
    try {
      if (!participantId || !id) return EMPTY;
      return this.participantSuivisGQL
        .watch({
          participantId,
          id,
        })
        .valueChanges.pipe(
          map(({ data, loading, error }) => {
            return { data, loading, error };
          })
        );
    } catch (error) {
      return of(error);
    }
  }

  addOne(id: string, suivi: any): Observable<any> {
    try {
      if (!id || !suivi) EMPTY;
      return this.apollo.mutate<any>({
        mutation: this.CREATE_PARTICIPANT_SUIVI,
        variables: { id, suivi },
        update: (cache, { data: { createParticipantSuivi } }) => {
          const data: any = cache.readQuery({
            query: this.allParticipantSuivisGQL.document,
            variables: { id },
          });
          const participantSuivis = [
            ...data.participantSuivis,
            createParticipantSuivi,
          ];
          cache.writeQuery({
            query: this.allParticipantSuivisGQL.document,
            variables: { id },
            data: { participantSuivis },
          });
        },
      });
    } catch (error) {
      return of(error);
    }
  }

  updateOne(id: string, suivi: Partial<ItemEventId | any>): Observable<any> {
    try {
      if (!id || !suivi) EMPTY;
      return this.apollo.mutate<any>({
        mutation: this.UPDATE_PARTICIPANT_SUIVI,
        variables: { id, suivi: suivi.changes },
        update: (cache, { data: { updateParticipantSuivi } }) => {
          const { participantSuivis }: any = cache.readQuery({
            query: this.allParticipantSuivisGQL.document,
            variables: { id },
          });

          const cacheId = cache.identify(participantSuivis);
          cache.modify({
            id: cacheId,
            fields: {
              ...updateParticipantSuivi,
            },
          });
        },
      });
    } catch (error) {
      return of(error);
    }
  }

  deleteOne(participantId: string, id: string): Observable<any> {
    try {
      if (!participantId || !id) EMPTY;
      return this.apollo.mutate<any>({
        mutation: this.DELETE_PARTICIPANT_SUIVI,
        variables: { participantId, id },
        update: (cache, { data: { deleteParticipantSuivi } }) => {
          const data: any = cache.readQuery({
            query: this.allParticipantSuivisGQL.document,
            variables: { id: participantId },
          });
          const participantSuivis = data.participantSuivis.filter(
            (doc: any) => doc.id === id
          );
          cache.writeQuery({
            query: this.allParticipantSuivisGQL.document,
            variables: { id },
            data: { participantSuivis },
          });
        },
      });
    } catch (error) {
      return of(error);
    }
  }

  deleteAll(participantId: string, ids: string[]): Observable<any> {
    try {
      if (!participantId) EMPTY;
      return this.apollo.mutate<any>({
        mutation: this.DELETE_PARTICIPANT_SUIVIS,
        variables: { participantId },
        update: (cache, { data: { deleteParticipantSuivis } }) => {
          cache.writeQuery({
            query: this.allParticipantSuivisGQL.document,
            variables: { id: participantId },
            data: { participantSuivis: [] },
          });
        },
      });
    } catch (error) {
      return of(error);
    }
  }
}
