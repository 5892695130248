import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';
import { UtilitiesState } from 'src/app/contents/components/utilities/store/utilities.reducer';
import * as fromUtilitiesSelector from 'src/app/contents/components/utilities/store/utilities.selectors';
import * as fromUtilitiesAction from 'src/app/contents/components/utilities/store/utilities.actions';

@Component({
  selector: 'app-participant-diagnostic-qualification',
  templateUrl: './participant-diagnostic-qualification.component.html',
  styleUrls: ['./participant-diagnostic-qualification.component.scss'],
})
export class ParticipantDiagnosticQualificationComponent
  implements OnInit, OnChanges, OnDestroy
{
  diploma$: Observable<any> = of(null);
  french_level$: Observable<any> = of(null);

  subscribe = new Subject();

  //VARIBALES
  isFreinTitle: string = 'La qualification est-elle un frein identifié ?';
  isFrenchLevelIlletrismeTitle: string = 'Analphabète à illettré';
  isFrenchLevelFleTitle: string =
    'Relève d’une formation FLE et/ou savoirs de base';
  isFrenchLevelLaborieuxTitle: string = 'Laborieuse à limitée';
  isFrenchLevelParfaitTitle: string = 'Bonne à parfaite maîtrise du français';

  //FORM FIELD
  qualificationForm: FormGroup = this.fb.group({});
  qualification: FormControl<any> = new FormControl<any>({});
  maitriseFrancais: FormControl<
    { info: string; item: string; type: string } | any
  > = new FormControl<any>({ info: '', item: '', type: '' });
  isFrenchLevelIlletrisme: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );
  isFrenchLevelFle: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );
  isFrenchLevelLaborieux: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );
  isFrenchLevelParfait: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );

  isFrein = new FormControl(false, Validators.required);

  @Input() qualificationInput$ = new BehaviorSubject<any>(null);
  @Input() add = new EventEmitter<any>(false);
  constructor(
    private fb: FormBuilder,
    private utilitiesStore: Store<UtilitiesState>
  ) {}

  ngOnInit(): void {
    this.getDiplomaReferentiel();
    this.loadDiplomaReferentiel();
    this.getFrenchLevelReferentiel();
    this.loadFrenchLevelReferentiel();

    this.form();
    this.onFormChanges();
  }
  ngOnChanges(changes: SimpleChanges): void {}

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getDiplomaReferentiel(): void {
    this.diploma$ = this.utilitiesStore.select(fromUtilitiesSelector.diploma);
  }
  loadDiplomaReferentiel(): void {
    this.utilitiesStore.dispatch(fromUtilitiesAction.loadDiplomaReferentiel());
  }
  getFrenchLevelReferentiel(): void {
    this.french_level$ = this.utilitiesStore.select(
      fromUtilitiesSelector.frenchLevel
    );
  }
  loadFrenchLevelReferentiel(): void {
    this.utilitiesStore.dispatch(
      fromUtilitiesAction.loadFrenchLevelReferentiel()
    );
  }

  form(): void {
    this.formInit();
    this.formCreate();
    this.formSet();
  }

  formInit(): void {
    this.qualification = new FormControl<
      { label: string; value: string } | any
    >({
      label: '',
      value: '',
    });
    this.isFrenchLevelIlletrisme = new FormControl(false, Validators.required);
    this.isFrenchLevelFle = new FormControl(false, Validators.required);
    this.isFrenchLevelLaborieux = new FormControl(false, Validators.required);
    this.isFrenchLevelParfait = new FormControl(false, Validators.required);
    this.maitriseFrancais = new FormControl<
      { info: string; item: string; type: string } | any
    >({
      info: '',
      item: '',
      type: '',
    });

    this.isFrein = new FormControl(false, Validators.required);
  }
  formCreate(): void {
    this.qualificationForm = this.fb.group({
      qualification: this.qualification,
      isFrenchLevelIlletrisme: this.isFrenchLevelIlletrisme,
      isFrenchLevelFle: this.isFrenchLevelFle,
      isFrenchLevelLaborieux: this.isFrenchLevelLaborieux,
      isFrenchLevelParfait: this.isFrenchLevelParfait,
      maitriseFrancais: this.maitriseFrancais,
      isFrein: this.isFrein,
    });
  }
  formSet(): void {
    this.qualificationInput$
      .pipe(takeUntil(this.subscribe))
      .subscribe((values: any) => {
        if (!values) return;
        this.qualificationForm.patchValue({ ...values });
      });
  }

  //COMPARE SELECT LIST UPDATE
  compareDiplomaLevele(obj1: any, obj2: any) {
    return obj1 && obj2 && obj1.label === obj2.label;
  }

  compareFrenchLevel(obj1: any, obj2: any) {
    return (
      obj1 &&
      obj2 &&
      obj1.type === obj2.type &&
      obj1.niveau === obj2.niveau &&
      obj1.item === obj2.item
    );
  }

  checkboxChanges(event: boolean, type: string): void {
    switch (type) {
      case 'isFrenchLevelIlletrisme':
        this.qualificationForm.patchValue({
          isFrenchLevelIlletrisme: event,
        });

        break;
      case 'isFrenchLevelFle':
        this.qualificationForm.patchValue({
          isFrenchLevelFle: event,
        });

        break;
      case 'isFrenchLevelLaborieux':
        this.qualificationForm.patchValue({
          isFrenchLevelLaborieux: event,
        });

        break;
      case 'isFrenchLevelParfait':
        this.qualificationForm.patchValue({
          isFrenchLevelParfait: event,
        });

        break;

      default:
        break;
    }
  }

  onFormChanges(): void {
    this.qualificationForm.valueChanges
      .pipe(takeUntil(this.subscribe))
      .subscribe((formValue) => {
        this.add.emit(formValue);
      });
  }
}
