<nz-card
  *ngFor="let item of items; trackBy: trackByFn"
  [nzExtra]="extra"
  [nzTitle]="title"
  nzSize="small"
>
  <nz-comment
    [nzAuthor]="item.auteur.displayName | carLimitation : 20"
    [nzDatetime]="calendarCardTimeTpl"
  >
    <ng-template #calendarCardTimeTpl>
      {{ item.dateStart | dateFr : "time" }}
    </ng-template>

    <nz-avatar
      nz-comment-avatar
      [nzText]="item.auteur.avatar"
      [ngStyle]="{
        'background-color': item.auteur.setting?.color
          ? item.auteur.setting?.color
          : 'grey'
      }"
    ></nz-avatar>
    <nz-comment-content>
      <p nz-typography>{{ item.content }}</p>
      <nz-divider
        nzOrientation="left"
        nzType="horizontal"
        [nzText]="referentTitle"
        nzPlain
      ></nz-divider>
      <nz-row nzAlign="middle" nzJustify="start" nzGutter="8">
        <nz-col nzSpan="2">
          <app-avatar-group
            [source]="[item?.referent]"
            [size]="24"
          ></app-avatar-group>
        </nz-col>
        <nz-col nzSpan="22">
          {{ item?.referent?.displayName | carLimitation : 30 }}
        </nz-col>
      </nz-row>
    </nz-comment-content>
  </nz-comment>
  <ng-template #title>
    <nz-badge [nzColor]="item.type.color"> </nz-badge>
    <strong>{{ item.type.label }}</strong>
  </ng-template>

  <ng-template #referentTitle>
    <small>Destinataire : </small>
  </ng-template>

  <ng-template #extra>
    <app-button-dropdown
      [isDelete]="true"
      [deleteType]="deleteAlert"
      (select)="onAction($event, item)"
    ></app-button-dropdown>
  </ng-template>
</nz-card>
