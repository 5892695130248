import { arrayToMap } from './../../../../utils/utils.array';
import {
  UtilitiesIds,
  UtilitiesEntityVariables,
  UtilitiesParticipantVariables,
} from './../../../../contents/components/utilities/store/utilities.model';
import { takeUntil } from 'rxjs/operators';
import { Participant, ParticipantId } from './../../store/participant.model';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
  UntypedFormArray,
  Validators,
} from '@angular/forms';
import { Observable, of, Subject, BehaviorSubject } from 'rxjs';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  Input,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { ParticipantState } from './../../store/participant.reducer';
import * as fromParticipantAction from './../../store/participant.actions';
import * as fromParticipantSelector from './../../store/participant.selectors';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';
import { Timestamp } from '@angular/fire/firestore';
import { Update } from '@ngrx/entity';
import { v4 as uuidV4 } from 'uuid';
import {
  add,
  differenceInCalendarYears,
  differenceInYears,
  fromUnixTime,
  sub,
} from 'date-fns';
import { capitalize } from 'lodash';
import { UtilitiesState } from 'src/app/contents/components/utilities/store/utilities.reducer';
import * as fromUtilityAction from 'src/app/contents/components/utilities/store/utilities.actions';
import * as fromUtilitySelector from 'src/app/contents/components/utilities/store/utilities.selectors';

@Component({
  selector: 'app-participant-form',
  templateUrl: './participant-form.component.html',
  styleUrls: ['./participant-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantFormComponent implements OnInit, OnChanges, OnDestroy {
  user$: Observable<any> = of(null);
  subscribe = new Subject();
  tabActiv: string = 'identity';
  @Input() participant$: Observable<ParticipantId | any> = of(null);
  @Output() cancel = new EventEmitter<boolean>(false);

  //FORM
  @Input() title: string = `Nouveau participant`;
  @Input() add: boolean = true;
  @Input() isIdentity: boolean = false;
  @Input() update: boolean = false;
  @Input() isSituation: boolean = false;
  @Input() isNumerique: boolean = false;
  @Input() isPresentation: boolean = false;
  @Input() isHebergement: boolean = false;
  @Input() isSortie: boolean = false;
  @Input() isImpact: boolean = false;
  @Input() isImpact6: boolean = false;
  @Input() isImpact12: boolean = false;
  @Input() isMobilite: boolean = false;
  @Input() isCriteres: boolean = false;
  @Output() updateParticipant = new EventEmitter<boolean>(false);

  participantForm: UntypedFormGroup = this.fb.group({});

  civilite: UntypedFormControl = new UntypedFormControl();
  firstName: UntypedFormControl = new UntypedFormControl();
  lastName: UntypedFormControl = new UntypedFormControl();
  dateNaissance: UntypedFormControl = new UntypedFormControl();
  codePays: UntypedFormControl = new UntypedFormControl();
  country: UntypedFormControl = new UntypedFormControl();
  sexe: UntypedFormControl = new UntypedFormControl();
  age: UntypedFormControl = new UntypedFormControl();
  qualification: UntypedFormControl = new UntypedFormControl();
  dateStart: UntypedFormControl = new UntypedFormControl();
  dateEnd: UntypedFormControl = new UntypedFormControl();
  dateSelect: UntypedFormControl = new UntypedFormControl();
  prescripteurs: UntypedFormControl = new UntypedFormControl();
  events: UntypedFormArray = this.fb.array([]);
  fields: UntypedFormControl = new UntypedFormControl();
  adresse: UntypedFormControl = new UntypedFormControl();
  identifiant: UntypedFormControl = new UntypedFormControl();
  qpv: UntypedFormControl = new UntypedFormControl();
  mobile: UntypedFormControl = new UntypedFormControl();
  fixe: UntypedFormControl = new UntypedFormControl();
  email: UntypedFormControl = new UntypedFormControl();
  dateUpdate: UntypedFormControl = new UntypedFormControl();
  agencePoleEmploi: UntypedFormControl = new UntypedFormControl();
  agencePoleEmploiEmail: UntypedFormControl = new UntypedFormControl();
  agencePoleEmploiType: UntypedFormControl = new UntypedFormControl();
  agencePoleEmploiPublicNumber: UntypedFormControl = new UntypedFormControl();
  dateStartPoleEmploi: UntypedFormControl = new UntypedFormControl();
  dateEndPoleEmploi: UntypedFormControl = new UntypedFormControl();
  dureePoleEmploi: UntypedFormControl = new UntypedFormControl();
  agencePoleEmploiAdresse: UntypedFormControl = new UntypedFormControl();
  agencePoleEmploiComplement: UntypedFormControl = new UntypedFormControl();
  agencePoleEmploiQpv: UntypedFormControl = new UntypedFormControl();
  referentFirstName: UntypedFormControl = new UntypedFormControl();
  referentLastName: UntypedFormControl = new UntypedFormControl();
  referentEmail: UntypedFormControl = new UntypedFormControl();
  referentPhone: UntypedFormControl = new UntypedFormControl();
  dateEntreeSurTerritoire: UntypedFormControl = new UntypedFormControl();
  inscriptionPoleEmploi: UntypedFormControl = new UntypedFormControl();
  inscriptionMissionLocale: UntypedFormControl = new UntypedFormControl();
  numbCarteSejour: UntypedFormControl = new UntypedFormControl();
  typeCarteSejour: UntypedFormControl = new UntypedFormControl();
  numCaiCir: UntypedFormControl = new UntypedFormControl();
  maitriseFrancais: UntypedFormControl = new UntypedFormControl();
  rqth: UntypedFormControl = new UntypedFormControl();
  nationality: UntypedFormControl = new UntypedFormControl();
  criteres: UntypedFormControl = new UntypedFormControl();
  contrats: UntypedFormArray = this.fb.array([]);
  groups: UntypedFormArray = this.fb.array([]);
  suggestion: UntypedFormArray = this.fb.array([]);
  documents: UntypedFormArray = this.fb.array([]);
  documentsValidation: UntypedFormArray = this.fb.array([]);
  formulaireId: UntypedFormControl = new UntypedFormControl();
  auteur: UntypedFormControl = new UntypedFormControl();
  suiteDeparcours: UntypedFormControl = new UntypedFormControl();
  duration: UntypedFormControl = new UntypedFormControl();
  dureeEntre: UntypedFormControl = new UntypedFormControl();
  dureeParcours: UntypedFormControl = new UntypedFormControl();
  datetIntegration: UntypedFormControl = new UntypedFormControl();
  integrationActif: UntypedFormControl = new UntypedFormControl();
  userIntergrationId: UntypedFormControl = new UntypedFormControl();
  userIntergrationDisplayName: UntypedFormControl = new UntypedFormControl();
  userUpdateI: UntypedFormControl = new UntypedFormControl();
  userUpdateDisplayName: UntypedFormControl = new UntypedFormControl();
  userSeletedId: UntypedFormControl = new UntypedFormControl();
  userSelectedDisplayName: UntypedFormControl = new UntypedFormControl();
  familiale: UntypedFormControl = new UntypedFormControl();
  mobilite: UntypedFormControl = new UntypedFormControl();
  sante: UntypedFormControl = new UntypedFormControl();
  numerique: UntypedFormControl = new UntypedFormControl();
  stats: UntypedFormControl = new UntypedFormControl();
  complement: UntypedFormControl = new UntypedFormControl();
  organisationId: UntypedFormControl = new UntypedFormControl();
  auths: UntypedFormControl = new UntypedFormControl();
  access: UntypedFormControl = new UntypedFormControl();
  familleSituationPrecedente: UntypedFormControl = new UntypedFormControl();
  parcours: UntypedFormControl = new UntypedFormControl();
  phoneNumber: UntypedFormControl = new UntypedFormControl();
  presentation: UntypedFormControl = new UntypedFormControl();
  objectifPro: UntypedFormControl = new UntypedFormControl();
  tranche: UntypedFormControl = new UntypedFormControl();
  commentVousNousAvezConu: UntypedFormControl = new UntypedFormControl();
  aideFamiliale: UntypedFormControl = new UntypedFormControl();
  minimaSociaux: UntypedFormControl = new UntypedFormControl();
  rgpdConscentement: UntypedFormControl = new UntypedFormControl();
  sortiInscription: UntypedFormControl = new UntypedFormControl();
  sortiInscriptionMotif: UntypedFormControl = new UntypedFormControl();
  sortiProgramme: UntypedFormControl = new UntypedFormControl();
  sortiProgrammeType: UntypedFormControl = new UntypedFormControl();
  sortiProgrammeQualitatif: UntypedFormControl = new UntypedFormControl();
  observation: UntypedFormControl = new UntypedFormControl();
  droitDeTravail: UntypedFormControl = new UntypedFormControl();
  disponibility: UntypedFormControl = new UntypedFormControl();
  lieuDeNaissance: UntypedFormControl = new UntypedFormControl();
  referentsId: UntypedFormControl = new UntypedFormControl();
  referents: UntypedFormControl = new UntypedFormControl();
  searchKey: UntypedFormControl = new UntypedFormControl();
  organisation: UntypedFormControl = new UntypedFormControl();
  relations: UntypedFormControl = new UntypedFormControl();
  adressePrincipale: UntypedFormControl = new UntypedFormControl();
  code: UntypedFormControl = new UntypedFormControl();
  codeRegionINSEE: UntypedFormControl = new UntypedFormControl();
  contact: UntypedFormControl = new UntypedFormControl();
  dispositifADEDA: UntypedFormControl = new UntypedFormControl();
  horaires: UntypedFormControl = new UntypedFormControl();
  libelle: UntypedFormControl = new UntypedFormControl();
  type: UntypedFormControl = new UntypedFormControl();
  typeAccueil: UntypedFormControl = new UntypedFormControl();
  zoneCompetences: UntypedFormControl = new UntypedFormControl();
  missionLocaleNom_structure: UntypedFormControl = new UntypedFormControl();
  missionLocaleAdresse_siege: UntypedFormControl = new UntypedFormControl();
  missionLocaleTelephone_siege: UntypedFormControl = new UntypedFormControl();
  missionLocaleFirstName: UntypedFormControl = new UntypedFormControl();
  missionLocaleLastName: UntypedFormControl = new UntypedFormControl();
  missionLocaleEmail: UntypedFormControl = new UntypedFormControl();
  missionLocaleTelephone: UntypedFormControl = new UntypedFormControl();
  numeriqueMobile: UntypedFormControl = new UntypedFormControl();
  internet: UntypedFormControl = new UntypedFormControl();
  logement: UntypedFormControl = new UntypedFormControl();
  ordinateur: UntypedFormControl = new UntypedFormControl();
  numeriqueObservation: UntypedFormControl = new UntypedFormControl();
  impact3date: UntypedFormControl = new UntypedFormControl();
  impact3type: UntypedFormControl = new UntypedFormControl();
  impact3info: UntypedFormControl = new UntypedFormControl();
  impact6date: UntypedFormControl = new UntypedFormControl();
  impact6type: UntypedFormControl = new UntypedFormControl();
  impact6info: UntypedFormControl = new UntypedFormControl();
  impact12date: UntypedFormControl = new UntypedFormControl();
  impact12type: UntypedFormControl = new UntypedFormControl();
  impact12info: UntypedFormControl = new UntypedFormControl();
  languageWeb: UntypedFormControl = new UntypedFormControl();
  communication: UntypedFormControl = new UntypedFormControl();
  operations: UntypedFormControl = new UntypedFormControl();
  operation: UntypedFormArray = this.fb.array([]);
  operationsMap: UntypedFormControl = new UntypedFormControl();
  modules: UntypedFormControl = new UntypedFormControl();
  modulesIds: UntypedFormControl = new UntypedFormControl();
  modulesMapIds: UntypedFormControl = new UntypedFormControl();
  actions: UntypedFormControl = new UntypedFormControl();
  actionsIds: UntypedFormControl = new UntypedFormControl();
  actionsMapIds: UntypedFormControl = new UntypedFormControl();
  contacts: UntypedFormControl = new UntypedFormControl();
  contactsIds: UntypedFormControl = new UntypedFormControl();
  contactsMapI: UntypedFormControl = new UntypedFormControl();
  entites: UntypedFormControl = new UntypedFormControl();
  entitesIds: UntypedFormControl = new UntypedFormControl();
  entitesMapIds: UntypedFormControl = new UntypedFormControl();
  criteresMap: UntypedFormControl = new UntypedFormControl();
  accompagnementDuration: UntypedFormControl = new UntypedFormControl();
  service: UntypedFormControl = new UntypedFormControl();
  servicesMap: UntypedFormControl = new UntypedFormControl();
  chezMoi: UntypedFormControl = new UntypedFormControl();
  lieuPublic: UntypedFormControl = new UntypedFormControl();
  autreLieu: UntypedFormControl = new UntypedFormControl();
  chezTier: UntypedFormControl = new UntypedFormControl();
  hotel: UntypedFormControl = new UntypedFormControl();
  centreHebergement: UntypedFormControl = new UntypedFormControl();
  chezFamille: UntypedFormControl = new UntypedFormControl();
  autre: UntypedFormControl = new UntypedFormControl();
  nesaitpas: UntypedFormControl = new UntypedFormControl();
  maitriseFrancaisEcrit: UntypedFormControl = new UntypedFormControl();
  permis: UntypedFormControl = new UntypedFormControl();
  reprisDeJustice: UntypedFormControl = new UntypedFormControl(false);
  qpvLibelle: UntypedFormControl = new UntypedFormControl();
  handicap: UntypedFormControl = new UntypedFormControl();
  justice: UntypedFormControl = new UntypedFormControl();
  caseJudiciaire: UntypedFormControl = new UntypedFormControl(false);
  bureautique_level: UntypedFormControl = new UntypedFormControl();
  bureautique_tools: UntypedFormControl = new UntypedFormControl();
  dateSortie: UntypedFormControl = new UntypedFormControl();
  enfantsAcharges: UntypedFormControl = new UntypedFormControl();
  enfantScolarise: UntypedFormControl = new UntypedFormControl();
  enfantMoyenDeGarde: UntypedFormControl = new UntypedFormControl();
  geo_mobility: UntypedFormControl = new UntypedFormControl();
  hebergementPrecision: UntypedFormControl = new UntypedFormControl();
  isQPV: UntypedFormControl = new UntypedFormControl(false);
  isQVA: UntypedFormControl = new UntypedFormControl(false);
  isZRR: UntypedFormControl = new UntypedFormControl(false);
  proprietaire: UntypedFormControl = new UntypedFormControl(false);
  locataire: UntypedFormControl = new UntypedFormControl(false);
  chezParent: UntypedFormControl = new UntypedFormControl(false);
  droitAimage: UntypedFormControl = new UntypedFormControl(false);
  consentementDonneesPersonnelles: UntypedFormControl = new UntypedFormControl(
    false
  );
  jeunesDeMoinsDe26ans: UntypedFormControl = new UntypedFormControl(false);
  personnePriseEnChargeIAE: UntypedFormControl = new UntypedFormControl(false);
  PersonneEmployeGEIQ: UntypedFormControl = new UntypedFormControl(false);
  beneficiaireProtectionInterRefugie: UntypedFormControl =
    new UntypedFormControl(false);
  beneficiaireProtectionInterSubsidiaire: UntypedFormControl =
    new UntypedFormControl(false);
  parentIsole: UntypedFormControl = new UntypedFormControl(false);
  ase: UntypedFormControl = new UntypedFormControl(false);
  autreCritere: UntypedFormControl = new UntypedFormControl(false);
  autrePrecision: UntypedFormControl = new UntypedFormControl(false);
  primoArrivant: UntypedFormControl = new UntypedFormControl(false);
  jeuneDecrocheur: UntypedFormControl = new UntypedFormControl(false);
  autoentrepreneurQPV: UntypedFormControl = new UntypedFormControl(false);
  fse: UntypedFormControl = new UntypedFormControl(false);
  neet: UntypedFormControl = new UntypedFormControl(false);

  civilities$: Observable<string[]> = of([]);
  communications$: Observable<string[]> = of([]);

  utility$: Observable<any> = of(null);
  utilitties$: Observable<any> = of(null);
  utilityIdentity$: Observable<any> = of(null);
  utilitySituation$: Observable<any> = of(null);
  utilityNumerique$: Observable<any> = of(null);
  utilityCriteres$: Observable<any> = of(null);

  utilitySortie$: Observable<any> = of(null);

  loading$: Observable<any> = of(null);
  utilitairesId: UtilitiesIds = UtilitiesIds.participant;
  utilitaireCivilite = UtilitiesParticipantVariables.civility;
  utilitaireSexe = UtilitiesParticipantVariables.sexe;
  utilitaireSante = UtilitiesParticipantVariables.sante;
  utilitaireCriteres = UtilitiesParticipantVariables.criteres;
  utilitaireCommunication = UtilitiesParticipantVariables.communication;
  utilitaireSociaux = UtilitiesParticipantVariables.minima;
  utilitaireDiplome = UtilitiesParticipantVariables.diplome;
  utilitaireNationality = UtilitiesParticipantVariables.pays;
  utilitaireMatrimonial = UtilitiesParticipantVariables.matrimonial;
  utilitaireMobility = UtilitiesParticipantVariables.mobility;
  utilitaireNumerique = UtilitiesParticipantVariables.numerique;
  utilitaireLanguage = UtilitiesParticipantVariables.languageWeb;
  utilitaireFrancais = UtilitiesParticipantVariables.niveauFrancais;
  utilitaireSejour = UtilitiesParticipantVariables.titreSejours;
  utilitaireSortis = UtilitiesParticipantVariables.sorties;
  utilitairePermis = UtilitiesParticipantVariables.permis;
  utilitaireTransports = UtilitiesParticipantVariables.transports;

  countriesList: any[] = [];
  critereList: any[] = [];
  filteredCountries: any[] = [];
  agencePoleEmploiList: any[] = [];
  qualificationList: string[] = [];
  operationsList: any[] = [];
  GroupList: string[] = [];
  ageValue: string = '';
  paysSelect: string[] = [];
  critereSelect: any[] = [];
  critereCurrent: any;
  dateFormat = 'dd/MM/yyyy';
  today: Date = new Date();
  popoverDelay: number = 0.5;
  durationDELD: string = '';
  adresseUpdate = new BehaviorSubject<any>(null);
  adresseComplementUpdate = new BehaviorSubject<any>(null);
  coordonneesUpdate = new BehaviorSubject<any>(null);
  civiliteUtilitySelected: string = '';
  communicationUtilitySelected: string = '';
  nationalityUtilitySelected: any;
  mobilityUtilitySelected: string = '';
  hebergementProprietaire: boolean = false;
  hebergementLocataire: boolean = false;

  hebergementChezUnTier: boolean = false;
  hebergementDansLieuPublic: boolean = false;
  hebergementDansUnAutreLieu: boolean = false;
  hebergementDansUnHotel: boolean = false;
  hebergementDansUnCentreDhebergement: boolean = false;
  hebergementAutre: boolean = false;
  hebergementNeSaitPas: boolean = false;
  critereCardCheckboxHeight: string = '220px';
  droitDeTravailDefinition: string =
    'Autorisation à travailler pour les participants hors Union Européenne.';
  rqthDefinition: string =
    'Reconnaissance de la qualité de travailleur handicapé.';
  droitAimageDefinition: string =
    "Le droit à l'image permet d'autoriser ou de refuser la reproduction et la diffusion publique de votre image.";
  checkboxDefinitionMobile: string =
    "Le participant dispose d'un téléphone mobile.";
  checkboxDefinitionOrdinateur: string =
    "Le participant dispose d'un ordinateur.";
  checkboxDefinitionInternet: string =
    "Le participant dispose d'un accès à internet.";
  checkboxDefinitionConsentement: string =
    "Toute manifestation de volonté, libre, spécifique, éclairée et univoque par laquelle la personne concernée accepte, par une déclaration ou par un acte positif clair, que des données à caractère personnel la concernant fassent l'objet d'un traitement.";

  constructor(
    private participantStore: Store<ParticipantState>,
    private userStore: Store<UserState>,
    private utilityStore: Store<UtilitiesState>,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.getUser();
    this.getCivilities();
    this.getCommunications();
    this.loadCivilities();
    this.loadCommunication();
    this.getUtilitiesLoading();
    this.getParticipant();
    this.getUtilities();
    this.getUtilitiesSituation();
    this.getUtilitiesNumerique();
    this.getUtilitiesCriteres();
    this.getUtilitiesIdentity();
    this.getUtilitiesSortie();

    this.form();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  //UTILITIES
  //CIVILITIES

  getCivilities(): void {
    this.civilities$ = this.utilityStore.select(fromUtilitySelector.civilities);
  }
  getCommunications(): void {
    this.communications$ = this.utilityStore.select(
      fromUtilitySelector.communications
    );
  }
  loadCivilities(): void {
    this.utilityStore.dispatch(fromUtilityAction.loadCivilities());
  }
  loadCommunication(): void {
    this.utilityStore.dispatch(fromUtilityAction.loadCommunication());
  }

  getUtilities(): void {
    this.utilitties$ = this.utilityStore.select(
      fromUtilitySelector.participant
    );
  }

  getUtilitiesIdentity(): void {
    this.utilityIdentity$ = this.utilityStore.select(
      fromUtilitySelector.participantIdentity
    );
  }
  getUtilitiesSituation(): void {
    this.utilitySituation$ = this.utilityStore.select(
      fromUtilitySelector.participantSituation
    );
  }

  getUtilitiesNumerique(): void {
    this.utilityNumerique$ = this.utilityStore.select(
      fromUtilitySelector.numerique
    );
  }

  getUtilitiesCriteres(): void {
    this.utilityCriteres$ = this.utilityStore.select(
      fromUtilitySelector.criteres
    );
  }

  getUtilitiesSortie(): void {
    this.utilitySortie$ = this.utilityStore.select(fromUtilitySelector.sortie);
  }

  getUtilitiesLoading(): void {
    this.loading$ = this.utilityStore.select(fromUtilitySelector.loading);
  }

  loadUtilityIdentity(): void {
    this.utilityStore.dispatch(
      fromUtilityAction.loadParticipantUtilitiesIdentity()
    );
  }

  loadUtilitySituation(): void {
    this.utilityStore.dispatch(
      fromUtilityAction.loadParticipantUtilitiesSituation()
    );
  }

  loadUtilityNumerique(): void {
    this.utilityStore.dispatch(fromUtilityAction.loadNumeriqueReferentiel());
  }

  loadUtilityCriteres(): void {
    this.utilityStore.dispatch(fromUtilityAction.loadCriteresReferentiel());
  }

  loadUtilitySortie(): void {
    this.utilityStore.dispatch(fromUtilityAction.loadSortieReferentiel());
  }

  getUser(): void {
    this.user$ = this.userStore.select(fromUserSelector.user);
  }

  getParticipant(): void {
    this.participant$ = this.participantStore.select(
      fromParticipantSelector.participant
    );
  }

  form(): void {
    this.formInit();
    this.formCreate();
    this.formSet();
    this.setGender();
    this.setParcoursEnd();
    this.setAge();
  }

  formInit(): void {
    this.inscriptionPoleEmploi = new UntypedFormControl(false);
    this.inscriptionMissionLocale = new UntypedFormControl(false);
    this.organisation = new UntypedFormControl();
    this.droitDeTravail = new UntypedFormControl(true);
    this.disponibility = new UntypedFormControl({});
    this.lieuDeNaissance = new UntypedFormControl('');
    (this.handicap = new UntypedFormControl()),
      (this.justice = new UntypedFormControl());
    this.access = new UntypedFormControl({});
    this.presentation = new UntypedFormControl('');
    this.communication = new UntypedFormControl('Téléphone', [
      Validators.required,
    ]);
    this.auths = new UntypedFormControl([]);
    this.parcours = new UntypedFormControl([]);
    this.civilite = new UntypedFormControl('', [Validators.required]);
    this.firstName = new UntypedFormControl('', [Validators.required]);
    this.lastName = new UntypedFormControl('', [Validators.required]);
    this.organisationId = new UntypedFormControl('');
    this.dateNaissance = new UntypedFormControl();
    this.dateEntreeSurTerritoire = new UntypedFormControl();
    this.dureeEntre = new UntypedFormControl();
    this.dureeParcours = new UntypedFormControl();
    this.qualification = new UntypedFormControl({});
    this.codePays = new UntypedFormControl('');
    this.nationality = new UntypedFormControl({});
    this.sexe = new UntypedFormControl('', [Validators.required]);
    this.age = new UntypedFormControl('');
    this.dateStart = new UntypedFormControl(this.today, Validators.required);
    this.dateEnd = new UntypedFormControl('');
    this.dateSelect = new UntypedFormControl(Timestamp.now());
    this.prescripteurs = new UntypedFormControl([]);
    this.events = this.fb.array([]);
    this.fields = new UntypedFormControl({});
    this.adresse = new UntypedFormControl({});
    this.dateStartPoleEmploi = new UntypedFormControl('');
    this.dateEndPoleEmploi = new UntypedFormControl('');
    this.dureePoleEmploi = new UntypedFormControl('');
    this.criteres = new UntypedFormControl();
    this.contrats = this.fb.array([]);
    this.actions = new UntypedFormControl();
    this.groups = this.fb.array([]);
    this.suggestion = this.fb.array([]);
    this.documents = this.fb.array([]);
    this.documentsValidation = this.fb.array([]);
    this.formulaireId = new UntypedFormControl('');
    this.auteur = new UntypedFormControl({});
    this.identifiant = new UntypedFormControl('');
    this.agencePoleEmploi = new UntypedFormControl('');
    this.qpv = new UntypedFormControl();
    this.agencePoleEmploiPublicNumber = new UntypedFormControl('');
    this.agencePoleEmploiEmail = new UntypedFormControl('');
    this.agencePoleEmploiType = new UntypedFormControl('');
    this.agencePoleEmploiQpv = new UntypedFormControl(null);
    this.agencePoleEmploiAdresse = new UntypedFormControl('');
    this.agencePoleEmploiComplement = new UntypedFormControl('');
    this.referentFirstName = new UntypedFormControl('');
    this.referentLastName = new UntypedFormControl('');
    this.referentEmail = new UntypedFormControl('');
    this.referentPhone = new UntypedFormControl('');
    this.suiteDeparcours = new UntypedFormControl(false);
    this.dateUpdate = new UntypedFormControl(Timestamp.now());
    this.duration = new UntypedFormControl(6, Validators.required);
    this.datetIntegration = new UntypedFormControl('');
    this.integrationActif = new UntypedFormControl(false);
    this.userIntergrationId = new UntypedFormControl();
    this.userIntergrationDisplayName = new UntypedFormControl('');
    this.userUpdateI = new UntypedFormControl('');
    this.userUpdateDisplayName = new UntypedFormControl('');
    this.userSeletedId = new UntypedFormControl('');
    this.userSelectedDisplayName = new UntypedFormControl('');
    this.familiale = new UntypedFormControl('');
    this.mobilite = new UntypedFormControl('');
    this.sante = new UntypedFormControl('');
    this.numerique = new UntypedFormControl('');
    this.stats = new UntypedFormControl('');
    this.complement = new UntypedFormControl('');
    this.mobile = new UntypedFormControl('');
    this.fixe = new UntypedFormControl('');
    this.email = new UntypedFormControl('');
    this.phoneNumber = new UntypedFormControl('');
    this.tranche = new UntypedFormControl('');
    this.numbCarteSejour = new UntypedFormControl('');
    this.typeCarteSejour = new UntypedFormControl({});
    this.numCaiCir = new UntypedFormControl('');
    this.maitriseFrancais = new UntypedFormControl({});
    this.objectifPro = new UntypedFormControl('');
    this.commentVousNousAvezConu = new UntypedFormControl('');
    this.aideFamiliale = new UntypedFormControl('');
    this.minimaSociaux = new UntypedFormControl('');
    this.rgpdConscentement = new UntypedFormControl(true);
    this.sortiInscription = new UntypedFormControl(false);
    this.sortiInscriptionMotif = new UntypedFormControl({});
    this.dateSortie = new UntypedFormControl();
    this.familleSituationPrecedente = new UntypedFormControl({});
    this.sortiProgramme = new UntypedFormControl(false);
    this.sortiProgrammeType = new UntypedFormControl({});
    this.sortiProgrammeQualitatif = new UntypedFormControl('');
    this.observation = new UntypedFormControl('');
    this.rqth = new UntypedFormControl(false);
    this.referentsId = new UntypedFormControl([]);
    this.referents = new UntypedFormControl([]);
    this.searchKey = new UntypedFormControl('');
    this.adressePrincipale = new UntypedFormControl();
    this.code = new UntypedFormControl();
    this.codeRegionINSEE = new UntypedFormControl();
    this.contact = new UntypedFormControl();
    this.dispositifADEDA = new UntypedFormControl();
    this.horaires = new UntypedFormControl();
    this.libelle = new UntypedFormControl();
    this.type = new UntypedFormControl();
    this.typeAccueil = new UntypedFormControl();
    this.zoneCompetences = new UntypedFormControl();
    this.missionLocaleNom_structure = new UntypedFormControl();
    this.missionLocaleAdresse_siege = new UntypedFormControl();
    this.missionLocaleTelephone_siege = new UntypedFormControl();
    this.missionLocaleFirstName = new UntypedFormControl({
      value: '',
      disabled: true,
    });
    this.missionLocaleLastName = new UntypedFormControl('');
    this.missionLocaleEmail = new UntypedFormControl('');
    this.missionLocaleTelephone = new UntypedFormControl({
      value: '',
      disabled: true,
    });

    this.numeriqueMobile = new UntypedFormControl();
    this.bureautique_level = new UntypedFormControl();
    this.bureautique_tools = new UntypedFormControl();
    this.internet = new UntypedFormControl(false);
    this.logement = new UntypedFormControl(false);
    this.ordinateur = new UntypedFormControl(false);
    this.numeriqueObservation = new UntypedFormControl('');
    this.impact3date = new UntypedFormControl();
    this.impact3type = new UntypedFormControl();
    this.impact3info = new UntypedFormControl();
    this.impact6date = new UntypedFormControl();
    this.impact6type = new UntypedFormControl();
    this.impact6info = new UntypedFormControl();
    this.impact12date = new UntypedFormControl();
    this.impact12type = new UntypedFormControl();
    this.impact12info = new UntypedFormControl();
    this.languageWeb = new UntypedFormControl();
    this.relations = new UntypedFormControl({});
    this.operations = new UntypedFormControl([]);
    this.operation = this.fb.array([]);
    this.operationsMap = new UntypedFormControl();
    this.modules = new UntypedFormControl();
    this.modulesIds = new UntypedFormControl();
    this.modulesMapIds = new UntypedFormControl();
    this.actions = new UntypedFormControl();
    this.actionsIds = new UntypedFormControl();
    this.actionsMapIds = new UntypedFormControl();
    this.contacts = new UntypedFormControl();
    this.contactsIds = new UntypedFormControl();
    this.contactsMapI = new UntypedFormControl();
    this.entites = new UntypedFormControl();
    this.entitesIds = new UntypedFormControl();
    this.entitesMapIds = new UntypedFormControl();
    this.criteresMap = new UntypedFormControl();
    this.accompagnementDuration = new UntypedFormControl(6);
    this.service = new UntypedFormControl();
    this.servicesMap = new UntypedFormControl();
    this.chezMoi = new UntypedFormControl(false);
    this.lieuPublic = new UntypedFormControl(false);
    this.autreLieu = new UntypedFormControl(false);
    this.chezTier = new UntypedFormControl(false);
    this.hotel = new UntypedFormControl(false);
    this.centreHebergement = new UntypedFormControl(false);
    this.chezFamille = new UntypedFormControl(false);
    this.autre = new UntypedFormControl(false);
    this.nesaitpas = new UntypedFormControl(false);
    this.enfantsAcharges = new UntypedFormControl(false);
    this.enfantScolarise = new UntypedFormControl(false);
    this.enfantMoyenDeGarde = new UntypedFormControl(false);
    this.caseJudiciaire = new UntypedFormControl(false);
    this.geo_mobility = new UntypedFormControl();
    this.hebergementPrecision = new UntypedFormControl();
    this.isQPV = new UntypedFormControl(false);
    this.isQVA = new UntypedFormControl(false);
    this.isZRR = new UntypedFormControl(false);
    this.proprietaire = new UntypedFormControl(false);
    this.locataire = new UntypedFormControl(false);
    this.chezParent = new UntypedFormControl(false);
    this.droitAimage = new UntypedFormControl(false);
    this.consentementDonneesPersonnelles = new UntypedFormControl(false);
    this.jeunesDeMoinsDe26ans = new UntypedFormControl(false);
    this.personnePriseEnChargeIAE = new UntypedFormControl(false);
    this.PersonneEmployeGEIQ = new UntypedFormControl(false);
    this.beneficiaireProtectionInterRefugie = new UntypedFormControl(false);
    this.beneficiaireProtectionInterSubsidiaire = new UntypedFormControl(false);
    this.parentIsole = new UntypedFormControl(false);
    this.ase = new UntypedFormControl(false);
    this.autreCritere = new UntypedFormControl(false);
    this.autrePrecision = new UntypedFormControl(false);
    this.primoArrivant = new UntypedFormControl(false);
    this.jeuneDecrocheur = new UntypedFormControl(false);
    this.autoentrepreneurQPV = new UntypedFormControl(false);
    this.fse = new UntypedFormControl(false);
    this.neet = new UntypedFormControl(false);
  }

  formCreate(): void {
    this.participantForm = this.fb.group({
      auths: this.auths,
      access: this.access,
      droitDeTravail: this.droitDeTravail,
      disponibility: this.disponibility,
      lieuDeNaissance: this.lieuDeNaissance,
      searchKey: this.searchKey,
      dureeEntre: this.dureeEntre,
      dureeParcours: this.dureeParcours,
      relations: this.relations,
      presentation: this.presentation,
      communication: this.communication,
      parcours: this.parcours,
      civilite: this.civilite,
      firstName: this.firstName,
      lastName: this.lastName,
      droitAimage: this.droitAimage,
      consentementDonneesPersonnelles: this.consentementDonneesPersonnelles,

      dateNaissance: this.dateNaissance,
      dateEntree: this.dateEntreeSurTerritoire,
      sexe: this.sexe,
      age: this.age,
      codePays: this.codePays,
      nationality: this.nationality,
      qualification: this.qualification,
      dateStart: this.dateStart,
      dateEnd: this.dateEnd,
      dateSelect: this.dateSelect,
      dateUpdate: this.dateUpdate,
      datetIntegration: this.datetIntegration,
      prescripteurs: this.prescripteurs,
      formulaireId: this.formulaireId,
      duration: this.duration,
      events: this.events,
      tranche: this.tranche,
      organisationId: this.organisationId,
      organisation: this.organisation,
      suiteDeparcours: this.suiteDeparcours,
      integration: this.integrationActif,
      numbCarteSejour: this.numbCarteSejour,
      typeCarteSejour: this.typeCarteSejour,
      numCaiCir: this.numCaiCir,
      maitriseFrancais: this.maitriseFrancais,
      maitriseFrancaisEcrit: this.maitriseFrancaisEcrit,
      objectifPro: this.objectifPro,
      commentVousNousAvezConu: this.commentVousNousAvezConu,
      aideFamiliale: this.aideFamiliale,
      minimaSociaux: this.minimaSociaux,
      rgpdConscentement: this.rgpdConscentement,
      rqth: this.rqth,
      userIntegration: new UntypedFormGroup({}),
      userUpdate: new UntypedFormGroup({}),
      userSelected: new UntypedFormGroup({}),
      informations: new UntypedFormGroup({
        adresse: new UntypedFormGroup({
          fields: this.fields,
          adresse: this.adresse,
          qpv: this.qpvLibelle,
          complement: this.complement,
          isQPV: this.isQPV,
          isQVA: this.isQVA,
          isZRR: this.isZRR,
        }),
        coordonnees: new UntypedFormGroup({
          mobile: this.mobile,
          fixe: this.fixe,
          email: this.email,
          phoneNumber: this.phoneNumber,
        }),
        poleEmploi: new UntypedFormGroup({
          inscription: this.inscriptionPoleEmploi,
          identifiant: this.identifiant,
          dateStart: this.dateStartPoleEmploi,
          dateEnd: this.dateEndPoleEmploi,
          duree: this.dureePoleEmploi,
          adressePrincipale: this.adressePrincipale,
          code: this.code,
          codeRegionINSEE: this.codeRegionINSEE,
          contact: this.contact,
          dispositifADEDA: this.dispositifADEDA,
          horaires: this.horaires,
          libelle: this.libelle,
          type: this.type,
          typeAccueil: this.typeAccueil,
          zoneCompetences: this.zoneCompetences,

          agencePoleEmploi: this.agencePoleEmploi,
          agencePoleEmploiType: this.agencePoleEmploiType,
          agencePoleEmploiPublicNumber: this.agencePoleEmploiPublicNumber,
          agencePoleEmploiAdresse: new UntypedFormGroup({
            adresse: this.agencePoleEmploiAdresse,
            complement: this.agencePoleEmploiComplement,
            qpv: this.agencePoleEmploiQpv,
          }),

          agencePoleEmploiEmail: this.agencePoleEmploiEmail,
          referent: new UntypedFormGroup({
            firstName: this.referentFirstName,
            lastName: this.referentLastName,
            email: this.referentEmail,
            telephone: this.referentPhone,
          }),
        }),
        missionLocale: new UntypedFormGroup({
          inscription: this.inscriptionMissionLocale,
          denomination: this.missionLocaleNom_structure,
          adresse: this.missionLocaleAdresse_siege,
          telephone: this.missionLocaleTelephone_siege,
          referent: new UntypedFormGroup({
            firstName: this.missionLocaleFirstName,
            lastName: this.missionLocaleLastName,
            email: this.missionLocaleEmail,
            telephone: this.missionLocaleTelephone,
          }),
        }),
      }),
      contrats: this.contrats,
      situationPrecedente: this.familleSituationPrecedente,
      situationPersonnelle: this.fb.group({
        familiale: this.familiale,
        mobilite: this.mobilite,
        handicap: this.handicap,
        justice: this.justice,
        caseJudiciaire: this.caseJudiciaire,
        permis: this.permis,
        enfantsAcharges: this.enfantsAcharges,
        enfantScolarise: this.enfantScolarise,
        enfantMoyenDeGarde: this.enfantMoyenDeGarde,
        hebergementPrecision: this.hebergementPrecision,
        sante: this.sante,
        numerique: this.numerique,
        mobile: this.numeriqueMobile,
        internet: this.internet,
        logement: this.logement,
        ordinateur: this.ordinateur,
        numeriqueObservation: this.numeriqueObservation,
        bureautique_level: this.bureautique_level,
        bureautique_tools: this.bureautique_tools,
        geo_mobility: this.geo_mobility,
        languageWeb: this.languageWeb,
        hebergement: this.fb.group({
          proprietaire: this.proprietaire,
          locataire: this.locataire,
          chezParent: this.chezParent,
          chezMoi: this.chezMoi,
          lieuPublic: this.lieuPublic,
          autreLieu: this.autreLieu,
          chezTier: this.chezTier,
          hotel: this.hotel,
          centreHebergement: this.centreHebergement,
          autre: this.autre,
          nesaitpas: this.nesaitpas,
        }),
      }),
      criteres: this.criteres,
      criteresMap: this.fb.group({
        jeunesDeMoinsDe26ans: this.jeunesDeMoinsDe26ans,
        personnePriseEnChargeIAE: this.personnePriseEnChargeIAE,
        PersonneEmployeGEIQ: this.PersonneEmployeGEIQ,
        beneficiaireProtectionInterRefugie:
          this.beneficiaireProtectionInterRefugie,
        beneficiaireProtectionInterSubsidiaire:
          this.beneficiaireProtectionInterSubsidiaire,
        parentIsole: this.parentIsole,
        ase: this.ase,
        autre: this.autreCritere,
        autrePrecision: this.autrePrecision,
        primoArrivant: this.primoArrivant,
        jeuneDecrocheur: this.jeuneDecrocheur,
        autoentrepreneurQPV: this.autoentrepreneurQPV,
        fse: this.fse,
        neet: this.neet,
      }),

      groups: this.groups,
      suggestion: this.suggestion,
      documents: this.documents,
      stats: this.stats,
      documentsValidation: this.documentsValidation,
      auteur: this.auteur,
      dateSortie: this.dateSortie,
      sortiInscription: this.sortiInscription,
      sortiInscriptionMotif: this.sortiInscriptionMotif,
      referentsId: this.referentsId,
      referents: this.referents,
      sortiProgramme: this.sortiProgramme,
      sortiProgrammeType: this.sortiProgrammeType,
      sortiProgrammeQualitatif: this.sortiProgrammeQualitatif,
      observation: this.observation,
      impact3mois: new UntypedFormGroup({
        date: this.impact3date,
        type: this.impact3type,
        info: this.impact3info,
      }),
      impact6mois: new UntypedFormGroup({
        date: this.impact6date,
        type: this.impact6type,
        info: this.impact6info,
      }),
      impact12mois: new UntypedFormGroup({
        date: this.impact12date,
        type: this.impact12type,
        info: this.impact12info,
      }),
      operations: this.operations,
      operation: this.operation,
      operationsMap: this.operationsMap,
      modules: this.modules,
      modulesIds: this.modulesIds,
      modulesMapIds: this.modulesMapIds,
      actions: this.actions,
      actionsIds: this.actionsIds,
      actionsMapIds: this.actionsMapIds,
      contacts: this.contacts,
      contactsIds: this.contactsIds,
      contactsMapI: this.contactsMapI,
      entites: this.entites,
      entitesIds: this.entitesIds,
      entitesMapIds: this.entitesMapIds,
      accompagnementDuration: this.accompagnementDuration,
      service: this.service,
      servicesMap: this.servicesMap,
      permis: this.permis,
      reprisDeJustice: this.reprisDeJustice,
      qpvLibelle: this.qpvLibelle,
    });
  }

  formSet(): void {
    this.participant$
      .pipe(takeUntil(this.subscribe))
      .subscribe((participant): any => {
        if (!participant) {
          this.newForm();
        } else {
          this.updateForm(participant);
        }
      });
  }

  checkboxChanges(event: boolean, type: string): void {
    switch (type) {
      case 'droitTravail':
        this.participantForm.patchValue({
          droitDeTravail: event,
        });

        break;
      case 'rqth':
        this.participantForm.patchValue({
          rqth: event,
        });

        break;
      case 'droitImage':
        this.participantForm.patchValue({
          droitAimage: event,
        });

        break;
      case 'mobile':
        this.participantForm.patchValue({
          situationPersonnelle: {
            mobile: event,
          },
        });

        break;
      case 'ordinateur':
        this.participantForm.patchValue({
          situationPersonnelle: {
            ordinateur: event,
          },
        });

        break;
      case 'internet':
        this.participantForm.patchValue({
          situationPersonnelle: {
            internet: event,
          },
        });

        break;
      case 'consentement':
        this.participantForm.patchValue({
          consentementDonneesPersonnelles: event,
        });

        break;

      default:
        break;
    }
  }

  checkboxCriteresChanges(event: boolean, type: any): void {
    const { item } = type;
    switch (item) {
      case 'Jeune de moins de 26 ans':
        this.participantForm.patchValue({
          criteresMap: {
            jeunesDeMoinsDe26ans: event,
          },
        });

        break;
      case 'Prise en charge SIAE':
        this.participantForm.patchValue({
          criteresMap: {
            personnePriseEnChargeIAE: event,
          },
        });

        break;
      case 'Employé dans un GEIQ':
        this.participantForm.patchValue({
          criteresMap: {
            PersonneEmployeGEIQ: event,
          },
        });

        break;
      case 'Autre':
        this.participantForm.patchValue({
          criteresMap: {
            autre: event,
            autrePrecision: event ? '' : '',
          },
        });

        break;
      case "Aide Sociale à l'Enfance":
        this.participantForm.patchValue({
          criteresMap: {
            ase: event,
          },
        });

        break;
      case 'Parent isolé':
        this.participantForm.patchValue({
          criteresMap: {
            parentIsole: event,
          },
        });

        break;

      case 'Protection subsidiaire':
        this.participantForm.patchValue({
          criteresMap: {
            beneficiaireProtectionInterSubsidiaire: event,
          },
        });
        break;

      case 'Réfugié':
        this.participantForm.patchValue({
          criteresMap: {
            beneficiaireProtectionInterRefugie: event,
          },
        });

        break;

      case 'Primo-arrivant':
        this.participantForm.patchValue({
          criteresMap: {
            primoArrivant: event,
          },
        });

        break;

      case 'Jeune décrocheur':
        this.participantForm.patchValue({
          criteresMap: {
            jeuneDecrocheur: event,
          },
        });

        break;
      case 'Autoentrepreneur en QPV':
        this.participantForm.patchValue({
          criteresMap: {
            autoentrepreneurQPV: event,
          },
        });

        break;
      case 'Fonds Social Européen':
        this.participantForm.patchValue({
          criteresMap: {
            fse: event,
          },
        });

        break;
      case 'NEET':
        this.participantForm.patchValue({
          criteresMap: {
            neet: event,
          },
        });

        break;

      default:
        break;
    }
  }

  newForm(): void {
    this.participantForm.reset();
    this.add = true;
    this.isIdentity = true;
    this.update = false;
  }
  updateForm(participant: ParticipantId): void {
    this.add = false;
    this.isIdentity = false;
    this.update = true;
    const {
      dateEnd,
      dateStart,
      dateUpdate,
      dateSelect,
      informations,
      dateEntree,
      dateNaissance,
      datetIntegration,
      situationPersonnelle,
    } = participant;
    const { adresse } = informations;
    this.civiliteUtilitySelected = participant.civilite;
    this.communicationUtilitySelected = participant.communication;
    this.nationalityUtilitySelected = participant.nationality;
    this.mobilityUtilitySelected = situationPersonnelle?.mobilite
      ? situationPersonnelle?.mobilite
      : null;
    this.setTypeHebergement(situationPersonnelle?.logement);

    const nextDateEnd = dateEnd ? fromUnixTime(dateEnd['seconds']) : null;
    const nextDateStart = fromUnixTime(dateStart['seconds']);
    const nextDateUpdate = this.today;
    const nextDateEntree = dateEntree
      ? fromUnixTime(dateEntree['seconds'])
      : null;
    const nextDateSelect = dateSelect
      ? fromUnixTime(dateSelect['seconds'])
      : this.today;

    const nextDateNaissance = dateNaissance
      ? fromUnixTime(dateNaissance['seconds'])
      : null;

    const nextDateIntegration = participant?.dateSortie
      ? fromUnixTime(participant.dateSortie['seconds'])
      : null;

    const nextDateSortie = datetIntegration
      ? fromUnixTime(datetIntegration['seconds'])
      : null;

    this.participantForm.patchValue({
      ...participant,
      dateUpdate: nextDateUpdate,
      dateStart: nextDateStart,
      dateEnd: nextDateEnd,
      dateSelect: nextDateSelect,
      dateEntree: nextDateEntree,
      dateNaissance: nextDateNaissance,
      datetIntegration: nextDateIntegration,
      dateSortie: nextDateSortie,
    });
    if (adresse?.adresse) {
      this.adresseUpdate.next(adresse.adresse);
    }

    if (adresse?.complement) {
      this.adresseComplementUpdate.next(adresse.complement);
    }

    this.coordonneesUpdate.next(informations?.coordonnees);
  }

  selectedIndexChange(
    index: number,
    isSortie: boolean,
    isImpact: boolean
  ): void {
    if (!isSortie || !isImpact) {
      this.loadUtilityIdentity();
      switch (index) {
        case 0:
          this.tabActiv = 'identity';

          break;
        case 1:
          this.tabActiv = 'situation';

          break;
        case 2:
          this.tabActiv = 'familly';

          break;
        case 3:
          this.tabActiv = 'criteres';

          break;
        case 4:
          this.tabActiv = 'hebergement';

          break;
        case 5:
          this.tabActiv = 'mobility';

          break;
        case 6:
          this.tabActiv = 'numerique';

          break;
        case 7:
          this.tabActiv = 'judiciaire';

          break;
        case 8:
          this.tabActiv = 'presentation';

          break;
        default:
          break;
      }
    } else {
      this.loadUtilitySortie();
    }
  }

  //COMPARE BEFORE UPDATE
  compareQualification(obj1: any, obj2: any) {
    return obj1 && obj2 && obj1.titre === obj2.titre;
  }

  compareProjet(obj1: any, obj2: any) {
    return obj1.denomination === obj2.denomination;
  }

  compareCarteSejour(obj1: any, obj2: any) {
    return obj1 && obj2 && obj1.titre === obj2.titre;
  }

  compareCarteIdentite(obj1: any, obj2: any) {
    return obj1 && obj2 && obj1.titre === obj2.titre;
  }

  compareSortie(obj1: any, obj2: any) {
    return obj1 && obj2 && obj1.motif === obj2.motif;
  }

  compareNationality(obj1: any, obj2: any) {
    return obj1 && obj2 && obj1.nationalite === obj2.nationalite;
  }

  compareFrancais(obj1: any, obj2: any) {
    return obj1 && obj2 && obj1.item === obj2.item;
  }

  compareDiploma(obj1: any, obj2: any) {
    return obj1 && obj2 && obj1.label === obj2.label;
  }

  comparePoleEmploi(obj1: any, obj2: any) {
    return obj1 && obj2 && obj1.label === obj2.label;
  }

  compareAllocation(obj1: any, obj2: any) {
    return obj1 && obj2 && obj1.value === obj2.value;
  }

  compareSante(item1: any, item2: any) {
    return item1 && item2 && item1 === item2;
  }

  compareHandicap(item1: any, item2: any) {
    return item1 && item2 && item1.item === item2.item;
  }

  compareMatrimonial(item1: any, item2: any) {
    return item1 && item2 && item1 === item2;
  }

  compareModeDeplacement(item1: any, item2: any) {
    return item1 && item2 && item1 === item2;
  }

  compareDeplacementProfessionnel(obj1: any, obj2: any) {
    return obj1 && obj2 && obj1.NAME === obj2.NAME;
  }
  comparePermis(obj1: any, obj2: any) {
    return obj1 && obj2 && obj1.NAME === obj2.NAME;
  }
  compareNumeriqueLevel(obj1: any, obj2: any) {
    return obj1 && obj2 && obj1.item === obj2.item;
  }

  compareBureautiqueLevel(obj1: any, obj2: any) {
    return obj1 && obj2 && obj1.NAME === obj2.NAME;
  }
  compareBureautiqueTools(obj1: any, obj2: any) {
    return obj1 && obj2 && obj1.NAME === obj2.NAME;
  }

  compareJudiciaire(item1: any, item2: any) {
    return item1 && item2 && item1 === item2;
  }

  compareCritere(obj1: any, obj2: any) {
    return obj1 && obj2 && obj1.item === obj2.item;
  }

  // addCritere(event: any[]): void {
  //   if (!event?.length) {
  //     return;
  //   } else {
  //     event.forEach((el) => {
  //       const isExist = this.criteres.value?.length
  //         ? this.criteres.value.find((i: any) => i?.item === el.item)
  //         : null;
  //       if (isExist) {
  //         return;
  //       } else {
  //         const critere = this.fb.group({
  //           id: [uuidV4()],
  //           item: [el.item],
  //           info: [el.info],
  //           pieceAfournir: [''],
  //           transmis: [false],
  //           valid: [false],
  //         });
  //         this.critereSelect.push(event);
  //         this.criteres.push(critere);
  //       }
  //     });
  //   }
  // }

  // removeCritere(i: number) {
  //   this.critereSelect.splice(i, 1);
  //   this.criteres.removeAt(i);
  // }

  addQualification(event: any) {
    const { source } = event;
    const { value } = source;
  }

  getTrancheAge(age: number): string {
    const tranche = [
      '16 à 24 ans',
      '25 à 29 ans',
      '30 à 39 ans',
      '40 à 44 ans',
      '45 à 49 ans',
      '50 à 64 ans',
      '65 ans ou plus',
      'Non renseigné',
    ];

    if (!age || age === 0) {
      return 'Non renseigné';
    }

    if (age >= 1 && age <= 24) {
      return tranche[0];
    }
    if (age >= 25 && age <= 29) {
      return tranche[1];
    }
    if (age >= 30 && age <= 39) {
      return tranche[2];
    }
    if (age >= 40 && age <= 45) {
      return tranche[3];
    }
    if (age >= 45 && age <= 49) {
      return tranche[4];
    }
    if (age >= 50 && age <= 65) {
      return tranche[5];
    }
    if (age >= 65) {
      return tranche[6];
    }

    return 'Non renseigné';
  }

  /* ADRESSE */
  onAdresse(event: any): void {
    if (event) {
      this.participantForm.patchValue({
        informations: {
          adresse: {
            adresse: event,
          },
        },
      });
    }
  }

  onComplement(event: any): void {
    if (event) {
      this.participantForm.patchValue({
        informations: {
          adresse: {
            complement: event,
          },
        },
      });
    }
  }

  onQpv(event: any): void {
    if (event) {
      this.participantForm.patchValue({
        informations: {
          adresse: {
            qpv: event,
          },
        },
      });
    }
  }

  /* COORDONNEES */
  onMobile(event: any): void {
    if (event) {
      this.participantForm.patchValue({
        informations: {
          coordonnees: {
            mobile: event,
          },
        },
      });
    }
  }

  onPhoneNumber(event: any): void {
    if (event) {
      this.participantForm.patchValue({
        informations: {
          coordonnees: {
            phoneNumber: event,
          },
        },
      });
    }
  }

  onFixe(event: any): void {
    if (event) {
      this.participantForm.patchValue({
        informations: {
          coordonnees: {
            fixe: event,
          },
        },
      });
    }
  }

  onEmail(event: any): void {
    if (event) {
      this.participantForm.patchValue({
        informations: {
          coordonnees: {
            email: event,
          },
        },
      });
    }
  }

  onDesinscription(event: boolean): void {
    if (event) {
      this.participantForm.patchValue({
        sortiProgramme: false,
      });
    } else {
      return;
    }
  }

  onSortie(event: boolean): void {
    if (event) {
      this.participantForm.patchValue({
        sortiInscription: false,
      });
    } else {
      return;
    }
  }

  onSelectPermis(permis: any): void {
    if (!permis) return;
    this.participantForm.patchValue({
      situationPersonnelle: {
        permis: permis,
      },
    });
  }

  onSelectDeplacement(mobilite: any): void {
    if (!mobilite) return;
    this.participantForm.patchValue({
      situationPersonnelle: {
        mobilite: mobilite,
      },
    });
  }

  onSelectLogement(logement: any, type: string): void {
    this.setTypeHebergement(type);

    this.participantForm.patchValue({
      situationPersonnelle: {
        logement: type,
      },
    });
  }

  setTypeHebergement(type: string): void {
    switch (type) {
      case 'Propriétaire':
        this.hebergementProprietaire = true;
        this.hebergementLocataire = true;
        this.hebergementChezUnTier = false;
        this.hebergementDansLieuPublic = false;
        this.hebergementDansUnAutreLieu = false;
        this.hebergementDansUnHotel = false;
        this.hebergementDansUnCentreDhebergement = false;
        this.hebergementAutre = false;
        this.hebergementNeSaitPas = false;

        break;
      case 'Locataire':
        this.hebergementProprietaire = false;
        this.hebergementLocataire = true;
        this.hebergementChezUnTier = false;
        this.hebergementDansLieuPublic = false;
        this.hebergementDansUnAutreLieu = false;
        this.hebergementDansUnHotel = false;
        this.hebergementDansUnCentreDhebergement = false;
        this.hebergementAutre = false;
        this.hebergementNeSaitPas = false;

        break;
      case 'Chez un tier':
        this.hebergementProprietaire = false;
        this.hebergementLocataire = false;
        this.hebergementChezUnTier = true;
        this.hebergementDansLieuPublic = false;
        this.hebergementDansUnAutreLieu = false;
        this.hebergementDansUnHotel = false;
        this.hebergementDansUnCentreDhebergement = false;
        this.hebergementAutre = false;
        this.hebergementNeSaitPas = false;

        break;
      case 'Dans un lieu public':
        this.hebergementProprietaire = false;
        this.hebergementLocataire = false;
        this.hebergementChezUnTier = false;
        this.hebergementDansLieuPublic = true;
        this.hebergementDansUnAutreLieu = false;
        this.hebergementDansUnHotel = false;
        this.hebergementDansUnCentreDhebergement = false;
        this.hebergementAutre = false;
        this.hebergementNeSaitPas = false;

        break;
      case 'Dans un autre lieu':
        this.hebergementProprietaire = false;
        this.hebergementLocataire = false;
        this.hebergementChezUnTier = false;
        this.hebergementDansLieuPublic = false;
        this.hebergementDansUnAutreLieu = true;
        this.hebergementDansUnHotel = false;
        this.hebergementDansUnCentreDhebergement = false;
        this.hebergementAutre = false;
        this.hebergementNeSaitPas = false;

        break;
      case 'Dans un hôtel':
        this.hebergementProprietaire = false;
        this.hebergementLocataire = false;
        this.hebergementChezUnTier = false;
        this.hebergementDansLieuPublic = false;
        this.hebergementDansUnAutreLieu = false;
        this.hebergementDansUnHotel = true;
        this.hebergementDansUnCentreDhebergement = false;
        this.hebergementAutre = false;
        this.hebergementNeSaitPas = false;

        break;
      case "Dans un centre d'hébergement":
        this.hebergementProprietaire = false;
        this.hebergementLocataire = false;
        this.hebergementChezUnTier = false;
        this.hebergementDansLieuPublic = false;
        this.hebergementDansUnAutreLieu = false;
        this.hebergementDansUnHotel = false;
        this.hebergementDansUnCentreDhebergement = true;
        this.hebergementAutre = false;
        this.hebergementNeSaitPas = false;

        break;
      case 'Autre':
        this.hebergementProprietaire = false;
        this.hebergementLocataire = false;
        this.hebergementChezUnTier = false;
        this.hebergementDansLieuPublic = false;
        this.hebergementDansUnAutreLieu = false;
        this.hebergementDansUnHotel = false;
        this.hebergementDansUnCentreDhebergement = false;
        this.hebergementAutre = true;
        this.hebergementNeSaitPas = false;

        break;
      case 'Ne sait pas':
        this.hebergementProprietaire = false;
        this.hebergementLocataire = false;
        this.hebergementChezUnTier = false;
        this.hebergementDansLieuPublic = false;
        this.hebergementDansUnAutreLieu = false;
        this.hebergementDansUnHotel = false;
        this.hebergementDansUnCentreDhebergement = false;
        this.hebergementAutre = false;
        this.hebergementNeSaitPas = true;

        break;
      default:
        break;
    }
  }

  onSelectCivilite(civilite: any): void {
    const genre: string =
      civilite === 'M' ? 'Masculin' : civilite === 'Mme' ? 'Féminin' : 'Autre';
    this.participantForm.patchValue({
      civilite: civilite,
      sexe: genre,
    });
  }

  onSelectCommunication(item: any): void {
    this.participantForm.patchValue({
      communication: item,
    });
  }

  onSelectNationalite(item: any): void {
    this.participantForm.patchValue({
      nationality: item,
    });
  }

  onSelectSociaux(item: any): void {
    this.participantForm.patchValue({
      minimaSociaux: item,
    });
  }

  onSelectSante(item: any): void {
    this.participantForm.patchValue({
      situationPersonnelle: {
        sante: item,
      },
    });
  }
  onSelectMobility(item: any): void {
    this.participantForm.patchValue({
      situationPersonnelle: {
        mobilite: item,
      },
    });
  }

  onSelectHandicap(item: any): void {
    this.participantForm.patchValue({
      situationPersonnelle: {
        handicap: item,
      },
    });
  }
  onSelectMatrimonial(item: any): void {
    this.participantForm.patchValue({
      situationPersonnelle: {
        familiale: item,
      },
    });
  }

  onSelectNumerique(item: any): void {
    this.participantForm.patchValue({
      situationPersonnelle: {
        numerique: item,
      },
    });
  }

  onSelectSejour(item: any): void {
    this.participantForm.patchValue({
      typeCarteSejour: item,
    });
  }

  onSelectDiplome(item: any): void {
    this.participantForm.patchValue({
      qualification: item,
    });
  }

  onSelectFrancaisOral(item: any): void {
    this.participantForm.patchValue({
      maitriseFrancais: item,
    });
  }

  onSelectFrancaisEcrit(item: any): void {
    this.participantForm.patchValue({
      maitriseFrancaisEcrit: item,
    });
  }

  onSelectLanguageWeb(item: any): void {
    this.participantForm.patchValue({
      situationPersonnelle: {
        languageWeb: item,
      },
    });
  }

  setGender(): void {
    this.participantForm.get('civilite')?.valueChanges.subscribe((val) => {
      if (!val) {
        return;
      } else {
        if (val === 'M') {
          this.participantForm.patchValue({ sexe: 'Masculin' });
        } else if (val === 'Mme') {
          this.participantForm.patchValue({ sexe: 'Féminin' });
        } else {
          this.participantForm.patchValue({ sexe: 'Autre' });
        }
      }
    });
  }

  setAge(): void {
    this.participantForm.get('age')?.valueChanges.subscribe((age) => {
      const birthday = this.participantForm.get('dateNaissance')?.value;

      if (!age && !birthday) return;

      if (!age && birthday) {
        this.participantForm.patchValue({
          age: differenceInYears(birthday, this.today),
        });
        return;
      }

      if ((age && !birthday) || (age && birthday)) {
        const date: Date = sub(this.today, { years: age });
        this.participantForm.patchValue({ dateNaissance: date });
        return;
      }
    });
  }

  setParcoursEnd(): void {
    this.participantForm.get('duration')?.valueChanges.subscribe((val) => {
      if (!val) {
        return;
      } else {
        const start = this.participantForm.get('dateStart')?.value;
        if (!start) return;
        this.participantForm.patchValue({
          dateEnd: add(start, { months: val }),
        });
      }
    });
  }

  onAdd(): void {
    if (!this.participantForm.valid) {
      this.onCancel();
      return;
    } else {
      const participant: Participant = this.participantForm.value;
      const { lastName, firstName, age, criteres } = participant;
      const participantLastName = lastName.toUpperCase();
      const participantFirstName = capitalize(firstName);

      const criteresMap = criteres?.length
        ? arrayToMap(criteres, 'item')
        : null;
      const birthdayDate = age
        ? age
        : participant?.dateNaissance
        ? participant?.dateNaissance
        : null;
      const dateDeNaissance = birthdayDate
        ? differenceInCalendarYears(new Date(Date.now()), birthdayDate)
        : 0;

      participant.age = age ? age : dateDeNaissance.toString();
      participant.tranche = this.getTrancheAge(dateDeNaissance);
      participant.criteresMap = criteresMap;
      participant.lastName = participantLastName;
      participant.firstName = participantFirstName;

      this.participantStore.dispatch(
        fromParticipantAction.addParticipant({ participant })
      );
      this.onCancel();
    }
  }

  onUpdate(currentParticipant: ParticipantId): void {
    if (!this.participantForm.valid || !currentParticipant) {
      this.onCancel();
      return;
    } else {
      const participantForm: Participant = this.participantForm.value;
      const birthdayDate = participantForm?.dateNaissance
        ? participantForm?.dateNaissance
        : null;
      const dateDeNaissance = birthdayDate
        ? differenceInCalendarYears(new Date(Date.now()), birthdayDate)
        : 0;
      const participant: Update<ParticipantId> = {
        id: currentParticipant?.id,
        changes: {
          ...currentParticipant,
          ...participantForm,
          age: dateDeNaissance.toString(),
        },
      };
      this.participantStore.dispatch(
        fromParticipantAction.updateParticipant({ participant })
      );
      this.updateParticipant.emit(true);
      this.onCancel();
    }
  }

  onCancel(): void {
    this.participantForm.reset();
    this.cancel.emit(true);
  }
}
