<ng-container *ngIf="entite$ | async as entite">
  <app-task-kanban
    [loading$]="loading$"
    [user$]="user$"
    [tasks$]="tasks$"
    [verifed$]="verifed$"
    [tasksInProgress$]="tasksInProgress$"
    [dones$]="dones$"
    (action)="onAction($event, entite)"
    (add)="onAdd($event, entite)"
    (update)="onUpdate($event, entite)"
    (delete)="onDelete($event, entite)"
    (deleteAll)="onDeleteAll(entite)"
  ></app-task-kanban>
</ng-container>
