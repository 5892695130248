import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-list-search',
  templateUrl: './list-search.component.html',
  styleUrls: ['./list-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListSearchComponent implements OnInit {
  @Input() list: any[] = [];
  @Input() title: string = '';
  @Input() subtitle: string = '';
  @Input() icon: string = '';
  @Input() placeholder: string = "Rechercher l'adresse";
  @Input() notitle: string = 'Aucune adresse trouvée';
  @Input() isSearch: boolean = true;
  @Input() isSelect: boolean = true;
  @Input() isUpdate: boolean = false;
  @Input() isDelete: boolean = false;

  @Output() search = new EventEmitter(false);
  @Output() select = new EventEmitter(false);
  @Output() update = new EventEmitter(false);
  @Output() delete = new EventEmitter(false);

  listSearchForm: UntypedFormGroup = this.fb.group({});
  listSearchInput: UntypedFormControl = new UntypedFormControl('');

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.form();
  }
  ngOnChanges(changes: SimpleChanges): void {}

  form(): void {
    this.formInit();
    this.formCreate();
    this.onSearch();
  }

  formInit() {
    this.listSearchInput = new UntypedFormControl('');
  }

  formCreate(): void {
    this.listSearchForm = this.fb.group({
      input: this.listSearchInput,
    });
  }

  onSearch(): void {
    this.listSearchForm.valueChanges.subscribe((event: string) => {
      if (!event) return;
      this.search.emit(event);
    });
  }
  onSelect(item: any): void {
    if (!item) return;
    this.select.emit(item);
  }
  onUpdate(item: any): void {
    if (!item) return;
    this.update.emit(item);
  }
  onDelete(item: any): void {
    if (!item) return;
    this.delete.emit(item);
  }

  trackByFn(index: number, item: any) {
    return index;
  }
}
