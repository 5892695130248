<ng-container *ngIf="user$ | async as user">
  <nz-row nzAlign="top" [nzGutter]="[8, 24]" nzJustify="center">
    <nz-col [nzXs]="{ span: 24 }" [nzMd]="{ span: 24 }" [nzLg]="{ span: 24 }">
      <app-user-indicators></app-user-indicators>
    </nz-col>
  </nz-row>
  <nz-row nzAlign="top" [nzGutter]="[8, 24]" nzJustify="start">
    <nz-col [nzXs]="{ span: 24 }" [nzMd]="{ span: 24 }" [nzLg]="{ span: 24 }">
      <app-todos></app-todos>
    </nz-col>
  </nz-row>

  <router-outlet> </router-outlet>
</ng-container>
