import { Injectable } from '@angular/core';
import { Firestore, collection, doc, getDoc } from '@angular/fire/firestore';
import { map, switchMap, finalize, take, catchError } from 'rxjs/operators';
import { Observable, BehaviorSubject, Subject, of, from } from 'rxjs';

import { Auth, onAuthStateChanged } from '@angular/fire/auth';

@Injectable({
  providedIn: 'any',
})
export class ItemEventService {
  constructor(private auth: Auth, private db: Firestore) {}

  getUtility(): Observable<any> {
    try {
      const utilityCollection = collection(this.db, 'utility');
      const document = doc(utilityCollection, 'itemEventUtility');

      return from(
        getDoc(document).then((snap) => {
          return snap.data();
        })
      );
    } catch (err) {
      return of(err);
    }
  }
}
