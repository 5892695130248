import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';
import { UtilitiesState } from 'src/app/contents/components/utilities/store/utilities.reducer';
import * as fromUtilitiesSelector from 'src/app/contents/components/utilities/store/utilities.selectors';
import * as fromUtilitiesAction from 'src/app/contents/components/utilities/store/utilities.actions';
import { ContratState } from 'src/app/contents/components/contrat/store/contrat.reducer';
import * as fromContratSelector from 'src/app/contents/components/contrat/store/contrat.selectors';
import * as fromContratAction from 'src/app/contents/components/contrat/store/contrat.actions';

@Component({
  selector: 'app-participant-diagnostic-form-droit',
  templateUrl: './participant-diagnostic-form-droit.component.html',
  styleUrls: ['./participant-diagnostic-form-droit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantDiagnosticFormDroitComponent
  implements OnInit, OnChanges, OnDestroy
{
  subscribe = new Subject();

  //VARIABLES
  isFreinTitle: string = "L'accès au droit est-il un frein identifié ?";
  neConnaitSesDroitsAucuneDemarcheTitle: string =
    'Ne connait pas ses droits, en situation de non recours';
  difficulteDeRenouvellementDeDroitTitle: string =
    'Renoncement ou en rupture ou difficulté de renouvellement de droits';
  connaitSesDroitsDemarcheEnCoursTitle: string =
    'Connait ses droits et démarches en cours';
  beneficieDeSesDroitsTitle: string =
    'Bénéficie des droits afférents à sa situation';
  consentementRgpdTitle: string =
    "Le participant a manifesté sa volonté libre, spécifique, éclairée et univoque qu'il.elle accepte, que ses données à caractère personnel fassent l'objet d'un traitement et d'une analyse statistique.";

  droitDeTravailTitle: string =
    "La situation du participant permet d'exercer toute activité professionnelle";
  droitAimageTitle: string = 'Bénéficie des droits afférents à sa situation';

  //FORM FIELD
  droitForm: FormGroup = this.fb.group({});
  neConnaitSesDroitsAucuneDemarche: FormControl<boolean | any> =
    new FormControl(false, Validators.required);
  difficulteDeRenouvellementDeDroit: FormControl<boolean | any> =
    new FormControl(false, Validators.required);
  connaitSesDroitsDemarcheEnCours: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );
  beneficieDeSesDroits: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );
  precision: FormControl<string | any> = new FormControl('');
  consentementRGPD: FormControl<boolean | any> = new FormControl(
    true,
    Validators.required
  );
  droitDeTravail: FormControl<boolean | any> = new FormControl(
    true,
    Validators.required
  );
  droitAimage: FormControl<boolean | any> = new FormControl(
    true,
    Validators.required
  );
  isFrein: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );

  //BIND
  @Input() droit$ = new BehaviorSubject<any>(null);
  @Output() add = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private utilitiesStore: Store<UtilitiesState>
  ) {}

  ngOnInit(): void {
    this.form();
    this.onFormChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  form(): void {
    this.formInit();
    this.formCreate();
    this.formSet();
  }

  formInit(): void {
    this.isFrein = new FormControl<boolean>(false, Validators.required);
    this.neConnaitSesDroitsAucuneDemarche = new FormControl(
      false,
      Validators.required
    );
    this.difficulteDeRenouvellementDeDroit = new FormControl(
      false,
      Validators.required
    );
    this.connaitSesDroitsDemarcheEnCours = new FormControl(
      false,
      Validators.required
    );
    this.beneficieDeSesDroits = new FormControl(false, Validators.required);
    this.consentementRGPD = new FormControl<boolean>(true, Validators.required);
    this.droitDeTravail = new FormControl<boolean>(true, Validators.required);
    this.droitAimage = new FormControl(true, Validators.required);
    this.precision = new FormControl('');
  }

  formCreate(): void {
    this.droitForm = this.fb.group({
      neConnaitSesDroitsAucuneDemarche: this.neConnaitSesDroitsAucuneDemarche,
      difficulteDeRenouvellementDeDroit: this.difficulteDeRenouvellementDeDroit,
      connaitSesDroitsDemarcheEnCours: this.connaitSesDroitsDemarcheEnCours,
      beneficieDeSesDroits: this.beneficieDeSesDroits,
      precision: this.precision,
      consentementRGPD: this.consentementRGPD,
      droitDeTravail: this.droitDeTravail,
      droitAimage: this.droitAimage,
      isFrein: this.isFrein,
    });
  }

  formSet(): void {
    this.droit$.pipe(takeUntil(this.subscribe)).subscribe((values) => {
      if (!values) return;
      this.droitForm.patchValue({ ...values });
    });
  }

  checkboxChanges(event: boolean, type: string): void {
    switch (type) {
      case 'neConnaitSesDroitsAucuneDemarche':
        this.droitForm.patchValue({
          neConnaitSesDroitsAucuneDemarche: event,
        });

        break;
      case 'difficulteDeRenouvellementDeDroit':
        this.droitForm.patchValue({
          difficulteDeRenouvellementDeDroit: event,
        });

        break;
      case 'connaitSesDroitsDemarcheEnCours':
        this.droitForm.patchValue({
          connaitSesDroitsDemarcheEnCours: event,
        });

        break;
      case 'beneficieDeSesDroits':
        this.droitForm.patchValue({
          beneficieDeSesDroits: event,
        });

        break;
      case 'rgpd':
        this.droitForm.patchValue({
          consentementRGPD: event,
        });

        break;
      case 'droitDeTravail':
        this.droitForm.patchValue({
          droitDeTravail: event,
        });

        break;
      case 'image':
        this.droitForm.patchValue({
          droitAimage: event,
        });

        break;

      default:
        break;
    }
  }

  onFormChanges(): void {
    this.droitForm.valueChanges
      .pipe(takeUntil(this.subscribe))
      .subscribe((values) => this.add.emit(values));
  }
}
