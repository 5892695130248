import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-avatar-group',
  templateUrl: './avatar-group.component.html',
  styleUrls: ['./avatar-group.component.scss'],
})
export class AvatarGroupComponent implements OnInit {
  default: string = '#bfbfbf';
  mouseEnterDelay: number = 0.5;
  @Input() source: any[] = [];
  @Input() limit: number = 6;
  @Input() size: number = 25;
  @Input() operation: any = null;
  @Output() select = new EventEmitter<any>();
  constructor() {}

  ngOnInit(): void {}

  onSelect(event: any): void {
    this.select.emit(event);
  }
}
