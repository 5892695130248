<div class="contentParticipantIdentity">
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzXXl="12" nzXl="12" nzLg="24" nzMd="24" nzXs="24" nzSm="24">
      <nz-card [nzTitle]="portaitTitle">
        <ng-template #portaitTitle>
          <app-header-title
            [icon]="'portrait'"
            [title]="'Identité'"
            [subtitle]="true"
          ></app-header-title>
        </ng-template>

        <nz-descriptions
          nzSize="small"
          nzBordered
          class="identity_container"
          [nzColumn]="{ xxl: 4, xl: 4, lg: 4, md: 4, sm: 0, xs: 0 }"
        >
          <nz-descriptions-item [nzTitle]="'Date de naissance'" [nzSpan]="4">
            <nz-row nzAlign="middle" nzJustify="start">
              <nz-col nzSpan="24">
                <span>
                  {{ participant.dateNaissance | dateFr : "small" }}
                </span>
              </nz-col>
            </nz-row>
          </nz-descriptions-item>
          <nz-descriptions-item [nzTitle]="'Âge'" [nzSpan]="4">
            <nz-row nzAlign="middle" nzJustify="start">
              <nz-col nzSpan="24">
                <span>
                  {{
                    participant?.age || participant?.age !== "0"
                      ? participant?.age
                      : "Non renseigné"
                  }}
                </span>
              </nz-col>
            </nz-row>
          </nz-descriptions-item>
          <nz-descriptions-item [nzTitle]="'Genre'" [nzSpan]="4">
            <nz-row nzAlign="middle" nzJustify="start">
              <nz-col nzSpan="24">
                <strong>
                  {{ participant?.sexe }}
                </strong>
              </nz-col>
            </nz-row>
          </nz-descriptions-item>
          <nz-descriptions-item [nzTitle]="'Nationalité'" [nzSpan]="4">
            <strong
              >{{
                participant?.nationality?.libelle
                  ? participant?.nationality?.libelle
                  : null
              }}
            </strong>
          </nz-descriptions-item>

          <nz-descriptions-item [nzTitle]="'Handicap'" [nzSpan]="4">
            <nz-row nzAlign="middle" nzJustify="start">
              <nz-col nzSpan="24">
                <strong
                  *ngIf="participant.situationPersonnelle || participant?.rqth"
                >
                  {{ participant?.rqth ? "RQTH" : "NON RQTH" }}
                  <nz-divider nzType="vertical"></nz-divider>
                  {{ participant?.situationPersonnelle?.handicap?.item }}
                </strong>
              </nz-col>
            </nz-row>
          </nz-descriptions-item>
          <nz-descriptions-item [nzTitle]="'Qualification'" [nzSpan]="4">
            <span *ngIf="participant.qualification">
              {{ participant?.qualification?.niveau }}
              <nz-divider nzType="vertical"></nz-divider>
              {{ participant?.qualification?.titre }}
            </span>
          </nz-descriptions-item>
          <nz-descriptions-item [nzTitle]="'Téléphone'" [nzSpan]="2">
            {{ participant?.informations?.coordonnees?.fixe }}
          </nz-descriptions-item>
          <nz-descriptions-item [nzTitle]="'Mobile'" [nzSpan]="2">
            {{ participant?.informations?.coordonnees?.mobile }}
          </nz-descriptions-item>
          <nz-descriptions-item [nzTitle]="'E-mail'" [nzSpan]="4">
            {{ participant?.informations?.coordonnees?.email }}
          </nz-descriptions-item>
        </nz-descriptions>
      </nz-card>
    </nz-col>
    <nz-col nzXXl="12" nzXl="12" nzLg="0" nzMd="0" nzXs="0" nzSm="0">
      <nz-card [nzTitle]="infoTitle">
        <ng-template #infoTitle>
          <app-header-title
            [icon]="'subject'"
            [title]="'Administratifs'"
            [subtitle]="true"
          ></app-header-title>
        </ng-template>

        <nz-descriptions
          nzSize="small"
          nzBordered
          [nzColumn]="{ xxl: 4, xl: 4, lg: 4, md: 2, sm: 0, xs: 0 }"
        >
          <!-- NIVEAU DE FRANCAIS -->
          <nz-descriptions-item nzTitle="Expression orale" [nzSpan]="4">
            <p nz-typography *ngIf="participant?.maitriseFrancais">
              {{ participant?.maitriseFrancais.type }}
            </p>
            <nz-divider
              nzType="vertical"
              *ngIf="participant?.maitriseFrancais"
            ></nz-divider>
            <span *ngIf="participant?.maitriseFrancais">{{
              participant?.maitriseFrancais?.item
            }}</span>
          </nz-descriptions-item>

          <!-- NIVEAU DE FRANCAIS ECRIS-->
          <nz-descriptions-item nzTitle="Expression écrite" [nzSpan]="4">
            <p nz-typography *ngIf="participant?.maitriseFrancaisEcrit">
              {{ participant?.maitriseFrancaisEcrit.type }}
            </p>
            <nz-divider
              nzType="vertical"
              *ngIf="participant?.maitriseFrancaisEcrit"
            ></nz-divider>
            <span *ngIf="participant?.maitriseFrancaisEcrit">{{
              participant?.maitriseFrancaisEcrit?.item
            }}</span>
          </nz-descriptions-item>

          <nz-descriptions-item nzTitle="Communication" [nzSpan]="4">
            {{ participant?.communication }}
          </nz-descriptions-item>

          <!-- INSCRIPTION POLE EMPLOI -->
          <nz-descriptions-item
            nzTitle="Inscription au Pôle Emploi"
            [nzSpan]="4"
          >
            <strong>{{
              participant?.informations?.poleEmploi?.inscription?.label
                ? (participant?.informations?.poleEmploi?.inscription?.label
                  | titlecase)
                : "Non renseigné"
            }}</strong>
          </nz-descriptions-item>

          <!-- DROIT DE TRAVAIL-->
          <nz-descriptions-item nzTitle="Droit de travail" [nzSpan]="4">
            <span *ngIf="participant?.droitDeTravail">
              {{
                participant?.droitDeTravail
                  ? "Droit de travail"
                  : "Pas de droit de travail"
              }}
            </span>
          </nz-descriptions-item>

          <nz-descriptions-item [nzTitle]="'Allocation'" [nzSpan]="4">
            <div>
              <nz-row nzAlign="middle" nzJustify="start">
                <nz-col nzSpan="24">
                  <strong
                    *ngIf="participant.minimaSociaux || participant?.rqth"
                  >
                    {{
                      participant.minimaSociaux?.value | splitJoin : "_" : ", "
                    }}
                  </strong>
                </nz-col>
              </nz-row>
            </div>
          </nz-descriptions-item>
          <nz-descriptions-item [nzTitle]="'Judiciaire'" [nzSpan]="4">
            <div>
              <strong>
                {{
                  participant?.situationPersonnelle?.caseJudiciaire
                    ? "Casier judiciaire"
                    : "Aucun casier judiciaire"
                }}
                <nz-divider
                  nzOrientation="center"
                  nzType="vertical"
                ></nz-divider>
                {{ participant?.situationPersonnelle?.justice }}
              </strong>
            </div>
          </nz-descriptions-item>
        </nz-descriptions>
      </nz-card>
    </nz-col>
  </nz-row>
  <nz-space [nzSize]="100"></nz-space>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzXXl="12" nzXl="12" nzLg="24" nzMd="24" nzXs="24" nzSm="24">
      <nz-card [nzTitle]="mobilityTitle">
        <ng-template #mobilityTitle>
          <app-header-title
            [icon]="'emoji_transportation'"
            [title]="'Mobilité'"
            [subtitle]="true"
          ></app-header-title>
        </ng-template>
        <nz-descriptions
          nzSize="small"
          nzBordered
          [nzColumn]="{ xxl: 4, xl: 4, lg: 4, md: 2, sm: 0, xs: 0 }"
          class="identity_container"
        >
          <!-- MOBILITÉ -->
          <nz-descriptions-item [nzTitle]="'Mode de transport'" [nzSpan]="4">
            <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
              <nz-col
                nzSpan="24"
                *ngIf="participant?.situationPersonnelle?.mobilite | size"
              >
                <p
                  nz-typography
                  class="tagPermis"
                  *ngFor="
                    let item of participant?.situationPersonnelle?.mobilite
                  "
                >
                  {{ item }}
                </p>
              </nz-col>
            </nz-row>
          </nz-descriptions-item>
          <nz-descriptions-item
            [nzTitle]="'Mobilité professionnelle'"
            [nzSpan]="4"
          >
            <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
              <nz-col
                nzSpan="24"
                *ngIf="participant?.situationPersonnelle?.geo_mobility | size"
              >
                <p
                  nz-typography
                  class="tagPermis"
                  *ngFor="
                    let item of participant?.situationPersonnelle?.geo_mobility
                  "
                >
                  {{ item?.NAME ? item.NAME : null }}
                </p>
              </nz-col>
            </nz-row>
          </nz-descriptions-item>

          <!-- PERMIS -->
          <nz-descriptions-item [nzTitle]="'Permis'" [nzSpan]="4">
            <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
              <div class="permisContainer">
                <nz-col nzSpan="24" *ngIf="participant?.permis | size">
                  <div *ngFor="let item of participant?.permis">
                    <strong>{{ item?.NAME ? item?.NAME : null }}</strong>
                  </div>
                </nz-col>
              </div>
            </nz-row>
          </nz-descriptions-item>
        </nz-descriptions>
      </nz-card>
    </nz-col>
    <nz-col nzXXl="12" nzXl="12" nzLg="0" nzMd="0" nzXs="0" nzSm="0">
      <nz-card [nzTitle]="numeriqueTitle">
        <ng-template #numeriqueTitle>
          <app-header-title
            [icon]="'language'"
            [title]="'Numérique'"
            [subtitle]="true"
          ></app-header-title>
        </ng-template>
        <nz-descriptions
          nzSize="small"
          nzBordered
          [nzColumn]="{ xxl: 4, xl: 4, lg: 4, md: 2, sm: 0, xs: 0 }"
        >
          <!-- NUMÉRIQUE ACCES -->
          <nz-descriptions-item nzTitle="Accès au numérique" [nzSpan]="4">
            <nz-tag
              *ngIf="participant?.situationPersonnelle?.mobile"
              nz-tooltip
              nzTooltipTitle="Dispose d'un mobile"
              nzTooltipPlacement="bottom"
            >
              <i nz-icon nzType="mobile" nzTheme="outline"></i>
            </nz-tag>
            <nz-space [nzSize]="500"></nz-space>

            <nz-tag
              *ngIf="participant?.situationPersonnelle?.ordinateur"
              nzColor="magenta"
              nz-tooltip
              nzTooltipTitle="Dispose d'un ordinateur"
              nzTooltipPlacement="bottom"
            >
              <i nz-icon nzType="desktop" nzTheme="outline"></i>
            </nz-tag>
            <nz-space [nzSize]="500"></nz-space>

            <nz-tag
              *ngIf="participant?.situationPersonnelle?.internet"
              nzColor="geekblue"
              nz-tooltip
              nzTooltipTitle="Dispose d'un accès internet"
              nzTooltipPlacement="bottom"
            >
              <i nz-icon nzType="global" nzTheme="outline"></i>
            </nz-tag>
          </nz-descriptions-item>

          <!-- NUMÉRIQUE NIVEAU -->
          <nz-descriptions-item nzTitle="Niveau numérique" [nzSpan]="4">
            <div
              class="numeriqueContainer"
              *ngIf="participant?.situationPersonnelle?.numerique | size"
            >
              <span
                nz-typography
                *ngFor="
                  let item of participant?.situationPersonnelle?.numerique
                "
              >
                {{ item?.item }}
              </span>
            </div>
          </nz-descriptions-item>

          <!-- BUREAUTIQUE -->
          <nz-descriptions-item nzTitle="Niveau en bureautique" [nzSpan]="4">
            <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
              <nz-col
                nzSpan="24"
                *ngIf="
                  participant?.situationPersonnelle as situationPersonnelle
                "
              >
                <span class="tagPermis">
                  {{
                    situationPersonnelle?.bureautique_level?.NAME
                      ? situationPersonnelle?.bureautique_level?.NAME
                      : null
                  }}
                </span>
              </nz-col>
            </nz-row>
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="Outils de bureautique" [nzSpan]="4">
            <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
              <nz-col
                nzSpan="24"
                *ngIf="
                  participant?.situationPersonnelle as situationPersonnelle
                "
              >
                <p
                  nz-typography
                  class="tagPermis"
                  *ngFor="let item of situationPersonnelle?.bureautique_tools"
                >
                  {{ item?.NAME ? item?.NAME : null }}
                </p>
              </nz-col>
            </nz-row>
          </nz-descriptions-item>
        </nz-descriptions>
      </nz-card>
    </nz-col>
  </nz-row>

  <ng-template #prefixMobile>
    <nz-avatar
      nzIcon="mobile"
      nzSize="small"
      class="iconCoordonnees"
      [style]="{ 'background-color': avatarBackgroundColor }"
    ></nz-avatar>
  </ng-template>
  <ng-template #prefixMail>
    <nz-avatar
      nzIcon="mail"
      nzSize="small"
      class="iconCoordonnees"
      [style]="{ 'background-color': avatarBackgroundColor }"
    ></nz-avatar>
  </ng-template>
  <ng-template #preReferent
    ><i nz-icon nzType="user" class="iconCoordonnees"></i
  ></ng-template>
  <ng-template #preHandicap
    ><mat-icon aria-hidden="false" aria-label="Handicap" class="iconCoordonnees"
      >accessibility</mat-icon
    ></ng-template
  >
  <ng-template #preDate
    ><mat-icon aria-hidden="false" aria-label="Handicap" class="iconCoordonnees"
      >today</mat-icon
    ></ng-template
  >
  <ng-template #preSchool
    ><mat-icon aria-hidden="false" aria-label="Handicap" class="iconCoordonnees"
      >school</mat-icon
    ></ng-template
  >
  <ng-template #preNumber>
    <i nz-icon nzType="field-number" class="iconCoordonnees"></i>
  </ng-template>

  <ng-template #preSortie
    ><mat-icon aria-hidden="false" aria-label="Handicap" class="iconCoordonnees"
      >event</mat-icon
    ></ng-template
  >

  <ng-template #prePalce
    ><mat-icon aria-hidden="false" aria-label="Handicap" class="iconMaterial"
      >place</mat-icon
    ></ng-template
  >

  <ng-template #prePalce
    ><mat-icon aria-hidden="false" aria-label="Handicap" class="iconMaterial"
      >place</mat-icon
    ></ng-template
  >

  <ng-template #preCritere
    ><mat-icon class="iconCoordonnees">fact_check</mat-icon></ng-template
  >

  <ng-template #preJustice
    ><mat-icon class="iconCoordonnees">gavel</mat-icon></ng-template
  >

  <ng-template #prePermis
    ><mat-icon class="iconCoordonnees">auto_stories</mat-icon></ng-template
  >
</div>

<ng-template #noDataMobility>
  <app-no-result
    [icon]="'room'"
    [notitle]="'Aucune information mobilité renseignée'"
    [isButton]="false"
  ></app-no-result>
</ng-template>

<ng-template #noDataNumerique>
  <app-no-result
    [icon]="'room'"
    [notitle]="'Aucune information numérique renseignée'"
    [isButton]="false"
  ></app-no-result>
</ng-template>

<ng-template #noDataCritereAdministratif>
  <app-no-result
    [icon]="'room'"
    [notitle]="'Aucun critère administratif renseigné'"
    [isButton]="false"
  ></app-no-result>
</ng-template>
