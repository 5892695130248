<nz-row
  nzAlign="middle"
  nzGutter="8"
  nzJustify="center"
  *ngIf="fullscreen"
  class="headerSearch__MESSAGE"
>
  <nz-col [nzSpan]="(searchInput$ | async)?.text ? 20 : 24">
    <input
      nz-input
      nzBorderless
      placeholder="Recherche"
      *ngIf="messages$ | async as messages"
      [disabled]="
        !(messages$ | async | size) || (messages$ | async | size) === 0
      "
      nzBorderless
      [(ngModel)]="searchInput"
      (ngModelChange)="
        searchInput$.next({ text: $event, messages: this.messages })
      "
    />
  </nz-col>
  <nz-col nzSpan="4" *ngIf="(searchInput$ | async)?.text">
    <i
      class="headerSearchIcon__MESSAGE"
      *ngIf="messages$ | async as messages"
      nz-icon
      nzType="close-circle"
      (click)="searchInput$.next({ text: '', messages: this.messages })"
    >
    </i>
  </nz-col>
</nz-row>

<cdk-virtual-scroll-viewport
  itemSize="120"
  class="viewport"
  *ngIf="!(loading$ | async); else skeleton"
>
  <ng-container *ngIf="messages$ | async | size; else noData">
    <app-message-item
      *cdkVirtualFor="
        let item of messages$ | async | sortBy: 'dateUpdate':'des':'date'
      "
      class="item"
      [message]="item"
      [fullscreen]="fullscreen"
    ></app-message-item>
  </ng-container>
</cdk-virtual-scroll-viewport>

<ng-template #skeleton>
  <app-skeleton></app-skeleton>
</ng-template>

<ng-template #noData>
  <nz-card nzBorderless class="emptyCard">
    <nz-empty
      [nzNotFoundImage]="noDataIcon"
      [nzNotFoundContent]="noDataContent"
    ></nz-empty>
  </nz-card>
</ng-template>

<ng-template #noDataIcon>
  <i nz-icon nzType="message" class="iconEmpty"></i>
</ng-template>
<ng-template #noDataContent>
  <h5 nz-typography class="contentEmpty">Aucun message</h5>
</ng-template>
