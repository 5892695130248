import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  UntypedFormArray,
} from '@angular/forms';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { Timestamp } from 'firebase/firestore';
import { fromUnixTime } from 'date-fns';

@Component({
  selector: 'app-projet-professionnel-todo-form',
  templateUrl: './projet-professionnel-todo-form.component.html',
  styleUrls: ['./projet-professionnel-todo-form.component.scss'],
})
export class ProjetProfessionnelTodoFormComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() item = new BehaviorSubject<any>(null);
  @Output() add = new EventEmitter<any>(false);
  @Output() update = new EventEmitter<any>(false);
  @Output() delete = new EventEmitter<any>(false);
  @Output() cancel = new EventEmitter<any>(false);

  isAdd: boolean = true;
  subscribe = new Subject();
  todoProjetproForm: UntypedFormGroup = this.fb.group({});
  dateStart: UntypedFormControl = new UntypedFormControl('', Validators.required);
  dateEnd: UntypedFormControl = new UntypedFormControl('', Validators.required);
  dateUpdate: UntypedFormControl = new UntypedFormControl('', Validators.required);
  title: UntypedFormControl = new UntypedFormControl('', Validators.required);
  shared: UntypedFormControl = new UntypedFormControl(false, Validators.required);

  todos: UntypedFormArray = new UntypedFormArray([]);
  dateFormat: string = 'dd/MM/yyyy';
  today: Date = new Date();

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.form();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.item.currentValue) return;
    this.formSet(changes.item.currentValue);
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  form(): void {
    this.formInit();
    this.formCreate();
    this.isAdd = true;
  }

  formInit(): void {
    this.dateStart = new UntypedFormControl(this.today, Validators.required);
    this.dateEnd = new UntypedFormControl(this.today, Validators.required);
    this.dateUpdate = new UntypedFormControl('');
    this.title = new UntypedFormControl('', Validators.required);
    this.todos = new UntypedFormArray([]);
    this.shared = new UntypedFormControl(false, Validators.required);
  }
  formCreate(): void {
    this.todoProjetproForm = this.fb.group({
      dateStart: this.dateStart,
      dateEnd: this.dateEnd,
      dateUpdate: this.dateUpdate,
      title: this.title,
      todos: this.todos,
      shared: this.shared,
    });
  }
  formSet(todo: any): void {
    todo.pipe(takeUntil(this.subscribe)).subscribe((item: any) => {
      if (!item) return;

      this.isAdd = false;
      const start = fromUnixTime(item.dateStart);
      const end = fromUnixTime(item.dateEnd);

      this.todoProjetproForm.patchValue({
        ...item,
        title: item.title,
        dateStart: start,
        dateEnd: end,
        shared: item.shared,
        todos: item.todos,
      });

      this.updateTodo(item?.todos);
    });
  }

  get todosArray(): UntypedFormArray {
    return this.todoProjetproForm.get('todos') as UntypedFormArray;
  }

  addTodo(): void {
    const todo = this.fb.group({
      title: [''],
      completed: [false],
    });
    this.todos.push(todo);
  }

  updateTodo(todos: any): void {
    if (!todos?.length) return;
    todos.forEach((el: any) => {
      const todo = this.fb.group({
        title: [el.title],
        completed: [el.completed],
      });
      this.todos.push(todo);
    });
  }
  removeTodo(index: number): void {
    this.todos.removeAt(index);
  }

  onAdd(): void {
    if (!this.todoProjetproForm.valid) return;

    const value = this.todoProjetproForm.value;

    const start = Timestamp.fromDate(value.dateStart);
    const end = Timestamp.fromDate(value.dateEnd);

    const todo = {
      ...value,
      dateStart: start,
      dateEnd: end,
    };

    this.add.emit(todo);
    this.onCancel();
  }
  onUpdate(item: any): void {
    if (!this.todoProjetproForm.valid) return;

    const value = this.todoProjetproForm.value;
    const start = Timestamp.fromDate(value.dateStart);
    const end = Timestamp.fromDate(value.dateEnd);

    const todo = {
      ...item,
      ...value,
      dateStart: start,
      dateEnd: end,
    };

    this.update.emit(todo);
    this.onCancel();
  }
  onDelete(): void {}

  onCancel(): void {
    this.todoProjetproForm.reset();
    this.isAdd = true;
    this.cancel.emit(true);
  }
}
