import { Component, OnInit } from '@angular/core';
import { remove, join, size, reduce, multiply, divide } from 'lodash';
import {
  UntypedFormGroup,
  UntypedFormControl,
  UntypedFormBuilder,
} from '@angular/forms';

@Component({
  selector: 'app-calculatrice',
  templateUrl: './calculatrice.component.html',
  styleUrls: ['./calculatrice.component.scss'],
})
export class CalculatriceComponent implements OnInit {
  display: any;
  numbers: number[] = [];
  numberCach: number = 0;
  etpNumber: number = 1600;

  operator: any;

  rapportInsertion: any;

  operations: any[] = [];
  currentValue: any;
  resultat: any[] = [];

  value: any;
  waitingForOperand: boolean = false;
  multiplicateur: any;
  etp: any;

  calculatorForm: UntypedFormGroup = this.fb.group({});
  montantInput: UntypedFormControl = new UntypedFormControl('');
  effortInput: UntypedFormControl = new UntypedFormControl('');
  mainDoeuvreInput: UntypedFormControl = new UntypedFormControl('');
  coutHoraire: UntypedFormControl = new UntypedFormControl('');
  heureInput: UntypedFormControl = new UntypedFormControl('');

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit() {
    this.formInit();
    this.formCreate();
    this.setUpCalculatrice();
  }

  //SET

  setUpCalculatrice(): void {
    (this.display = '0'),
      (this.rapportInsertion = '0'),
      remove(this.numbers),
      remove(this.operations),
      remove(this.resultat),
      (this.currentValue = null),
      (this.value = null),
      (this.waitingForOperand = false);
  }

  /* FORM */
  formInit(): void {
    (this.montantInput = new UntypedFormControl('')),
      (this.effortInput = new UntypedFormControl('')),
      (this.mainDoeuvreInput = new UntypedFormControl('')),
      (this.coutHoraire = new UntypedFormControl('')),
      (this.heureInput = new UntypedFormControl(''));
  }

  formCreate(): void {
    this.calculatorForm = this.fb.group({
      montantInput: this.montantInput,
      effortInput: this.effortInput,
      mainDoeuvreInput: this.mainDoeuvreInput,
      coutHoraire: this.coutHoraire,
      heureInput: this.heureInput,
    });
  }

  numberTouch(i: any): void {}

  // TOUCHES CLEAR
  clear() {
    (this.display = '0'), (this.rapportInsertion = '0'), (this.numberCach = 0);
    remove(this.numbers);
    remove(this.operations);
    remove(this.resultat);
    (this.currentValue = null),
      (this.value = null),
      (this.multiplicateur = null),
      (this.waitingForOperand = false);

    this.calculatorForm.reset();
  }

  // TOUCHES NUMERIQUES
  inputDigit(number: number) {
    this.numbers.push(number);
    this.display = join(this.numbers, '');
  }

  // TOUCHES OPERATEUUS
  getOperator(operator: any): void {
    this.operator = operator;
    this.display = '0';
    /* this.numberCach.push(parseFloat(join(this.numbers, ''))) */

    const cal = [];

    if (size(this.operations) === 0) {
      this.operations.push(join(this.numbers, ''));
      remove(this.numbers);
    } else {
      const preVal: number = parseFloat(join(this.operations, ''));
      const nextVal: number = parseFloat(join(this.numbers, ''));

      cal.push(preVal, nextVal);
      remove(this.operations);

      switch (operator) {
        case '+':
          this.operations.push(
            reduce(cal, (a, b) => {
              return a + b;
            })
          );
          remove(this.numbers);
          break;

        case '-':
          this.operations.push(
            reduce(cal, (a, b) => {
              return a - b;
            })
          );
          remove(this.numbers);
          break;

        case '/':
          this.operations.push(
            reduce(cal, (a, b) => {
              return a / b;
            })
          );
          remove(this.numbers);
          break;

        case '*':
          this.operations.push(
            reduce(cal, (a, b) => {
              return a * b;
            })
          );
          remove(this.numbers);

          break;

        default:
          break;
      }
    }
  }

  // TOUCHES OPERATIONS
  performOperation(): void {
    const prevVal: number = parseFloat(join(this.operations, ''));
    const nextVal: number = parseFloat(join(this.numbers, ''));

    this.resultat.push(prevVal, nextVal);

    const operator = this.operator;

    switch (operator) {
      case '+':
        remove(this.operations);
        remove(this.numbers);
        this.display = reduce(this.resultat, (a, b) => {
          return a + b;
        });
        this.operations.push(
          reduce(this.resultat, (a, b) => {
            return a + b;
          })
        );
        this.numbers.push(
          reduce(this.resultat, (a, b) => {
            return a + b;
          })
        );

        break;

      case '-':
        remove(this.operations);
        remove(this.numbers);
        this.display = reduce(this.resultat, (a, b) => {
          return a - b;
        });
        this.operations.push(
          reduce(this.resultat, (a, b) => {
            return a - b;
          })
        );
        this.numbers.push(
          reduce(this.resultat, (a, b) => {
            return a - b;
          })
        );
        break;

      case '*':
        remove(this.operations);
        remove(this.numbers);
        this.display = reduce(this.resultat, (a, b) => {
          return a * b;
        });
        this.operations.push(
          reduce(this.resultat, (a, b) => {
            return a * b;
          })
        );
        this.numbers.push(
          reduce(this.resultat, (a, b) => {
            return a * b;
          })
        );
        break;

      case '/':
        remove(this.operations);
        remove(this.numbers);
        this.display = reduce(this.resultat, (a, b) => {
          return a / b;
        });
        this.operations.push(
          reduce(this.resultat, (a, b) => {
            return a / b;
          })
        );
        this.numbers.push(
          reduce(this.resultat, (a, b) => {
            return a / b;
          })
        );
        break;

      default:
        this.display = 0;
    }
  }

  performOperationClause(): void {
    const montant: number = this.calculatorForm.get('montantInput')?.value;
    const heure: number = this.calculatorForm.get('heureInput')?.value;
    const effort: number = this.calculatorForm.get('effortInput')?.value / 100;
    const cout: number = this.calculatorForm.get('coutHoraire')?.value;
    const mo: number = this.calculatorForm.get('mainDoeuvreInput')?.value / 100;

    if (montant) {
      // RESULTATS
      const heures = divide(multiply(multiply(montant, mo), effort), cout);
      const multiple = multiply(divide(effort, cout), mo);

      // RFETURN
      this.display = parseInt(String(heures), 0).toLocaleString() + ' h';
      this.rapportInsertion = divide(multiply(mo, effort), divide(cout, 100));
      this.multiplicateur = multiple.toFixed(6);
      this.etp = `ETP : ${(heures / this.etpNumber).toFixed(2).toString()}`;
    } else if (heure) {
      // RESULTATS
      const montantt = divide(divide(multiply(heure, cout), effort), mo);
      const multiple = multiply(divide(effort, cout), mo);

      // RETURN
      this.display = parseInt(String(montantt), 0).toLocaleString() + ' ht';
      this.multiplicateur = multiple.toFixed(6);
      this.etp = `ETP : ${(heure / this.etpNumber).toFixed(2).toString()}`;
    } else {
    }
  }
}
