<nz-card [nzTitle]="missionContratHeaderTpl" nzSize="small" nzBorderless>
  <ng-template #missionContratHeaderTpl>
    <app-header-title
      [icon]="'business_center'"
      [title]="''"
      [subtitle]="true"
    ></app-header-title>
  </ng-template>

  <form [formGroup]="contratForm" class="w-full space-y-6 p-2">
    <!-- TYPE ET NATURE DU CONTRAT -->
    <div class="w-full flex items-start space-x-2">
      <div class="w-6/12">
        <label class="label required">Type de contrat</label>
        <div class="select-menu">
          <nz-select
            nzPlaceHolder="Type de contrat"
            nzBorderless
            formControlName="type"
            nzServerSearch
            [nzLoading]="loading$ | async"
            [compareWith]="formCompareNature"
          >
            <nz-option
              *ngFor="let item of typesContrat$ | async"
              [nzLabel]="item?.libelle"
              [nzValue]="item"
            ></nz-option>
          </nz-select>
        </div>
        <small
          *ngIf="
            !contratForm.value?.type?.valid &&
            contratForm.value?.type?.touched &&
            contratForm.value?.type?.dirty
          "
          class="inputFormAlert"
          >Veuillez renseigner la nature du contrat</small
        >
      </div>
      <div class="w-6/12">
        <label class="label required">Nature du contrat</label>
        <div class="select-menu">
          <nz-select
            nzPlaceHolder="Nature du contrat"
            nzBorderless
            formControlName="nature"
            nzServerSearch
            [nzLoading]="(natureContrat$ | async | size) ? false : true"
            [compareWith]="formCompareNature"
          >
            <nz-option
              *ngFor="let item of natureContrat$ | async"
              [nzLabel]="item?.libelle"
              [nzValue]="item"
            ></nz-option>
          </nz-select>
        </div>
        <small
          *ngIf="
            !contratForm.value?.nature?.valid &&
            contratForm.value?.nature?.touched &&
            contratForm.value?.nature?.dirty
          "
          class="inputFormAlert"
          >Veuillez renseigner la nature du contrat</small
        >
      </div>
    </div>

    <div class="w-full flex items-center">
      <div class="w-3/12 mr-4">
        <label class="label required">Expérience souhaitée</label>
        <div class="select-menu">
          <nz-select
            nzPlaceHolder="Expérience souhaitée"
            nzBorderless
            formControlName="experience"
            nzServerSearch
            [nzLoading]="loading$ | async"
            [compareWith]="formCompareExperience"
          >
            <nz-option
              *ngFor="let item of experienceContrat$ | async"
              [nzLabel]="item?.libelleCaracteristique"
              [nzValue]="item"
            ></nz-option>
          </nz-select>
        </div>
        <small
          class="inputFormAlert"
          *ngIf="
            !contratForm.value?.experience?.valid &&
            contratForm.value?.experience?.touched &&
            contratForm.value?.experience?.dirty
          "
          >Veuillez renseigner le niveau d'expérience</small
        >
      </div>

      <div class="w-2/12 mr-4">
        <label class="label">Déplacement</label>
        <label
          class="label cursor-pointer flex items-start justify-between mt-3"
        >
          <span class="label-text text-sm mt-2 mr-2">{{
            contratForm.value?.isDeplacement ? "Oui" : "Non"
          }}</span>
          <input
            type="checkbox"
            checked="checked"
            class="checkbox h-4 w-4 mt-2"
            formControlName="isDeplacement"
          />
        </label>
      </div>

      <div class="w-3/12 mr-4">
        <label class="label">Zone de déplacement</label>
        <div class="select-menu">
          <nz-select
            nzPlaceHolder="Déplacement"
            nzBorderless
            formControlName="zone_deplacement"
            nzServerSearch
            nzAllowClear
          >
            <nz-option
              *ngFor="let item of mobilityGeographic$ | async"
              [nzLabel]="item?.libelleTypeTerritoire"
              [nzValue]="item"
            ></nz-option>
          </nz-select>
        </div>
      </div>

      <div class="w-2/12 mr-4">
        <label class="label">Astreinte</label>
        <label
          class="label cursor-pointer flex items-start justify-between mt-3"
        >
          <span class="label-text text-sm mt-2 mr-2">{{
            contratForm.value.isAstreinte ? "Oui" : "Non"
          }}</span>
          <input
            type="checkbox"
            checked="checked"
            class="checkbox h-4 w-4 mt-2"
            formControlName="isAstreinte"
          />
        </label>
      </div>

      <div class="w-2/12">
        <div class="flex items-center whitespace-nowrap">
          <label class="label"> Suivi</label>

          <svg
            class="iconSvg"
            nz-tooltip
            nzTooltipTitle="Souhaitez-vous la mise en place d'un suivi renforcé : présence lors de la prise de poste, suivi en emploi, etc. ?"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 30 30"
            stroke-width="1.5"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
            />
          </svg>
        </div>
        <label
          class="label cursor-pointer flex items-start justify-between mt-3"
        >
          <span class="label-text text-sm mt-2 mr-2">{{
            contratForm.value.isSuviRenforce ? "Oui" : "Non"
          }}</span>
          <input
            type="checkbox"
            checked="checked"
            class="checkbox h-4 w-4 mt-2"
            formControlName="isSuviRenforce"
          />
        </label>
      </div>
    </div>

    <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>

    <!-- PERMIS -->
    <div class="w-full flex items-start">
      <div class="w-full p-2">
        <label class="label">Permis</label>
        <div class="select-menu">
          <nz-select
            nzPlaceHolder="Permis"
            nzBorderless
            formControlName="permis"
            nzMode="tags"
            [nzMaxTagCount]="3"
          >
            <nz-option
              *ngFor="let el of permis$ | async"
              [nzLabel]="el.libelle"
              [nzValue]="el"
            ></nz-option>
          </nz-select>
        </div>
      </div>
    </div>

    <!-- BUREAUTIQUE -->
    <div class="w-full flex items-start">
      <div class="w-full p-2">
        <label class="label">Niveau en bureautique</label>
        <div class="select-menu">
          <nz-select
            nzPlaceHolder="Niveau en bureautique"
            nzBorderless
            formControlName="bureautique"
            nzMode="tags"
          >
            <nz-option
              *ngFor="let item of levelNumerique$ | async"
              [nzLabel]="item"
              [nzValue]="item"
            ></nz-option>
          </nz-select>
        </div>
      </div>
    </div>

    <!-- OBSERVATIONS -->
    <div class="w-full flex items-center justify-between">
      <div class="w-1/2 flex flex-col mr-2 p-2">
        <label class="label">Motif du contrat</label>
        <div class="w-full">
          <textarea
            name="floating_observation_motif"
            id="floating_observation_motif"
            class="textareaForm"
            formControlName="motif"
            placeholder="Motif du contrat"
          ></textarea>
        </div>
      </div>
      <div class="w-1/2 flex flex-col p-2">
        <label class="label">Justificatif</label>
        <div class="w-full">
          <textarea
            class="textareaForm"
            formControlName="justificatif"
            placeholder="Justificatif du contrat"
          ></textarea>
        </div>
      </div>
    </div>
  </form>
</nz-card>
