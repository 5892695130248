import { createAction, props } from '@ngrx/store';

export const exportFicheDePoste = createAction(
  '[FicheDePoste/API] Export FicheDePoste',
  props<{
    fiche: any;
    user: any;
    exportType: string;
    isAnonyme: boolean;
    referentRecrutement: any;
  }>()
);
export const exportFicheDePosteSuccess = createAction(
  '[FicheDePoste/API] Export FicheDePoste Success',
  props<{ success: string }>()
);
export const exportFicheDePosteFailure = createAction(
  '[FicheDePoste/API] Export FicheDePoste Failure',
  props<{ error: any }>()
);

export const clearFicheDePostes = createAction(
  '[FicheDePoste/API] Clear FicheDePostes'
);
