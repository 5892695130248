import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  OperationTeamState,
  operationTeamsFeatureKey,
  selectAll,
} from './operation-team.reducer';

export const selectOperationTeamState =
  createFeatureSelector<OperationTeamState>(operationTeamsFeatureKey);

export const team = createSelector(selectOperationTeamState, selectAll);

export const teamSelection = createSelector(
  selectOperationTeamState,
  (state: OperationTeamState) => state.teamSelection
);

export const userItem = createSelector(
  selectOperationTeamState,
  (state: OperationTeamState) => state.userItem
);

export const loading = createSelector(
  selectOperationTeamState,
  (state: OperationTeamState) => state.loading
);

export const error = createSelector(
  selectOperationTeamState,
  (state: OperationTeamState) => state.error
);
