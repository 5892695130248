import { EntiteSearchModule } from './../../contents/components/entite-search/entite-search.module';
import { MetiersSuggestionsModule } from './../metiers-suggestions/metiers-suggestions.module';
import { ContactModule } from './../contact/contact.module';
import { EntiteOperationsService } from './../../service/entite-operations.service';
import { EntiteStorageService } from './../../service/entite-storage.service';
import { EntiteSuivisService } from './../../service/entite-suivis.service';
import { UserFavorisService } from './../../service/user-favoris.service';
import { PortalNavigationService } from './../../portals/portal-navigation.service';
import { EntiteResolverService } from './../../resolvers/entite-resolver.service';
import { EntitesResolverService } from './../../resolvers/entites-resolver.service';
import { EntiteService } from './../../service/entite.service';
import { ContentsModule } from './../../contents/contents.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EntiteRoutingModule } from './entite-routing.module';
import { EntiteComponent } from './components/entite/entite.component';
import { EntiteListComponent } from './components/entite-list/entite-list.component';
import { EntiteCardComponent } from './components/entite-card/entite-card.component';
import { EntiteActionsComponent } from './components/entite-actions/entite-actions.component';
import { EntiteDetailComponent } from './components/entite-detail/entite-detail.component';
import { StoreModule } from '@ngrx/store';
import * as fromEntite from './store/entite.reducer';
import { EffectsModule } from '@ngrx/effects';
import { EntiteEffects } from './store/entite.effects';
import { EntiteItemComponent } from './components/entite-item/entite-item.component';
import { EntiteFormComponent } from './components/entite-form/entite-form.component';
import { EntiteIdentityComponent } from './components/entite-identity/entite-identity.component';
import { EntiteDocumentsComponent } from './components/entite-documents/entite-documents.component';
import { EntiteFiltersComponent } from './components/entite-filters/entite-filters.component';
import { QueryEntitesService } from 'src/app/service/query-entites.service';
import { EntiteOperationsComponent } from './components/entite-operations/entite-operations.component';
import { EntiteDashboardComponent } from './components/entite-dashboard/entite-dashboard.component';
import { EntiteContactsComponent } from './components/entite-contacts/entite-contacts.component';
import { EntiteMetiersComponent } from './components/entite-metiers/entite-metiers.component';
import { EntiteItemHeaderComponent } from './components/entite-item-header/entite-item-header.component';
import { EntiteContactsService } from 'src/app/service/entite-contacts.service';
import { EntiteFormUpdateComponent } from './components/entite-form-update/entite-form-update.component';
import { EntiteFiltersContentComponent } from './components/entite-filters-content/entite-filters-content.component';
import { EntiteListByOperationComponent } from './components/entite-list-by-operation/entite-list-by-operation.component';
import { EntiteSelectorsComponent } from './components/entite-selectors/entite-selectors.component';
import { EntiteTaskModule } from '../entite-task/entite-task.module';
import { EntiteSuiviModule } from '../entite-suivi/entite-suivi.module';
import { EntiteDashboardModule } from '../entite-dashboard/entite-dashboard.module';
import { EntiteDocumentsModule } from '../entite-documents/entite-documents.module';
import { EntiteMetiersModule } from '../entite-metiers/entite-metiers.module';
import { EntiteOperationsModule } from '../entite-operations/entite-operations.module';
import { NoResultModule } from 'src/app/contents/components/no-result/no-result.module';
import { CoordonneesModule } from 'src/app/contents/components/coordonnees/coordonnees.module';
import { CommunesFranceModule } from 'src/app/contents/components/communes-france/communes-france.module';
import { ButtonsModule } from 'src/app/contents/components/buttons/buttons.module';
import { MetiersModule } from 'src/app/features/metiers/metiers.module';
import { HeaderModule } from 'src/app/contents/components/header-title/header.module';
import { LayoutModule } from 'src/app/contents/components/layout/layout.module';
import { AvatarModule } from 'src/app/features/avatar/avatar.module';
import { DocumentModule } from 'src/app/features/document/document.module';
import { UiModule } from 'src/app/ui/ui/ui.module';

@NgModule({
  declarations: [
    EntiteComponent,
    EntiteListComponent,
    EntiteCardComponent,
    EntiteActionsComponent,
    EntiteDetailComponent,
    EntiteItemComponent,
    EntiteFormComponent,
    EntiteIdentityComponent,
    EntiteDocumentsComponent,
    EntiteFiltersComponent,
    EntiteOperationsComponent,
    EntiteDashboardComponent,
    EntiteContactsComponent,
    EntiteMetiersComponent,
    EntiteItemHeaderComponent,
    EntiteFormUpdateComponent,
    EntiteFiltersContentComponent,
    EntiteListByOperationComponent,
    EntiteSelectorsComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ContentsModule,
    ContactModule,
    EntiteRoutingModule,
    EntiteTaskModule,
    EntiteSuiviModule,
    EntiteDashboardModule,
    EntiteDocumentsModule,
    EntiteMetiersModule,
    EntiteOperationsModule,
    MetiersSuggestionsModule,
    EntiteSearchModule,
    NoResultModule,
    CoordonneesModule,
    HeaderModule,
    CommunesFranceModule,
    MetiersModule,
    ButtonsModule,
    LayoutModule,
    AvatarModule,
    DocumentModule,
    UiModule,
    StoreModule.forFeature(fromEntite.entitesFeatureKey, fromEntite.reducer),
    EffectsModule.forFeature([EntiteEffects]),
  ],
  providers: [
    EntiteService,
    EntitesResolverService,
    EntiteResolverService,
    PortalNavigationService,
    QueryEntitesService,
    UserFavorisService,
    EntiteSuivisService,
    EntiteStorageService,
    EntiteOperationsService,
    EntiteContactsService,
  ],
  exports: [
    EntiteComponent,
    EntiteListComponent,
    EntiteCardComponent,
    EntiteActionsComponent,
    EntiteDetailComponent,
    EntiteItemComponent,
    EntiteFormComponent,
    EntiteIdentityComponent,
    EntiteDocumentsComponent,
    EntiteFiltersComponent,
    EntiteOperationsComponent,
    EntiteDashboardComponent,
    EntiteContactsComponent,
    EntiteMetiersComponent,
    EntiteItemHeaderComponent,
    EntiteFormUpdateComponent,
    EntiteFiltersContentComponent,
    EntiteListByOperationComponent,
    EntiteSelectorsComponent,
  ],
})
export class EntiteModule {}
