<div
  class="container"
  *ngIf="statistiques$ | async as statistique; else noData"
>
  <nz-row nzJustify="center" nzAlign="middle" nzGutter="0">
    <nz-col nzSpan="6">
      <nz-card nzBorderless [nzTitle]="numberStatsHeader">
        <ng-template #numberStatsHeader>
          <app-item-header
            [title]="'Projets ' + currentString"
            [source]="statistique?.denominations"
            [color]="true"
          ></app-item-header>
        </ng-template>

        <ng-container *ngIf="statistique?.denominations | size">
          <nz-tag *ngFor="let item of statistique?.denominations">
            {{ item }}
          </nz-tag>
        </ng-container>

        <!-- <app-charts-indicators
            [total]="participantsTotal"
            [objectif]="participantsObjectif"
            [type]="'Participants'"
          ></app-charts-indicators> -->
      </nz-card>
    </nz-col>
    <nz-col nzSpan="18">
      <app-charts [source]="byMonths$"></app-charts>
    </nz-col>
  </nz-row>
  <nz-row nzJustify="center" nzAlign="middle" nzGutter="0">
    <nz-col nzSpan="12">
      <nz-card nzBorderless>
        <app-charts [source]="services$"></app-charts>
      </nz-card>
    </nz-col>
    <nz-col nzSpan="12">
      <nz-card nzBorderless>
        <app-charts [source]="types$"></app-charts>
      </nz-card>
    </nz-col>
  </nz-row>
</div>

<ng-template #noData>
  <nz-card>
    <nz-empty nzNotFoundImage="simple"></nz-empty>
  </nz-card>
</ng-template>
