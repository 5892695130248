import { OperationId } from './../../../operation/store/operation.model';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  Input,
} from '@angular/core';
import { Observable, of, Subject } from 'rxjs';

@Component({
  selector: 'app-operation-prescripteurs-card',
  templateUrl: './operation-prescripteurs-card.component.html',
  styleUrls: ['./operation-prescripteurs-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationPrescripteursCardComponent
  implements OnInit, OnChanges, OnDestroy
{
  subscribe = new Subject();
  newCardTitle: string = '';
  newCardDefinition: string = '';
  newCardDescription: string = '';
  title: string = '';
  @Input() operation$: Observable<OperationId | any> = of(null);
  constructor() {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  onNew(): void {}
  onBack(): void {}
}
