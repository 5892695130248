import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { MessageId } from '../../store/message.model';
import { messageState } from '../../store/message.reducer';
import * as fromMessageSelectors from '../../store/message.selectors';
import * as fromMessageActions from '../../store/message.actions';
import { Update } from '@ngrx/entity';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';

@Component({
  selector: 'app-messages-screen',
  templateUrl: './messages-screen.component.html',
  styleUrls: ['./messages-screen.component.scss'],
})
export class MessagesScreenComponent implements OnInit {
  user$: Observable<any> = of(null);
  message$: Observable<MessageId | any> = of(null);
  addingUser: boolean = false;
  fullscreen: boolean = true;
  messageTitle: string = '';
  visible: boolean = false;
  constructor(
    private storeMessage: Store<messageState>,
    private userStore: Store<UserState>
  ) {}

  ngOnInit(): void {
    this.get__USER();
    this.get__MESSAGE();
  }

  get__USER(): void {
    this.user$ = this.userStore.select(fromUserSelector.user);
  }

  get__MESSAGE(): void {
    this.message$ = this.storeMessage.select(
      fromMessageSelectors.selectMessage
    );
  }

  changeUsers(event: boolean): void {
    this.addingUser = event;
  }

  onAddUsers(users: any, message: MessageId): any {
    if (!users?.length || !message) {
      return null;
    }

    if (users?.length && message) {
      const nextUsers: any[] = message.interlocuteurs?.length
        ? [...message.interlocuteurs, ...users]
        : message.interlocuteurs;
      const nextMessage: Update<MessageId> = {
        id: message?.id ? message?.id : '',
        changes: { ...message, interlocuteurs: nextUsers },
      };

      this.storeMessage.dispatch(
        fromMessageActions.updateMessage({ message: nextMessage })
      );
      this.changeUsers(false);
    }
  }
}
