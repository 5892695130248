<ng-container *ngIf="item | async as projetpro">
  <nz-row nzAlign="top" nzGutter="4" nzJustify="end">
    <nz-col [nzSpan]="(loadingUtilities$ | async) ? 2 : 0">
      <i nz-icon nzType="loading" *ngIf="loadingUtilities$ | async"></i>
    </nz-col>
    <nz-col nzSpan="12">
      <form nz-form [formGroup]="projetproFormationForm">
        <nz-form-item>
          <nz-form-label>Diplôme</nz-form-label>
          <nz-form-control>
            <nz-select
              nzBorderless
              formControlName="diploma"
              placeholder="Diplôme"
              nzPlaceHolder="Diplôme"
            >
              <nz-option
                *ngFor="let item of (utilityIdentity$ | async)?.diploma?.data"
                [nzLabel]="item.label"
                [nzValue]="item"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </form>
    </nz-col>
    <nz-col nzSpan="6">
      <form nz-form [formGroup]="projetproFormationForm">
        <nz-form-item>
          <nz-form-label nzRequired>Distance</nz-form-label>
          <nz-form-control>
            <nz-select
              nzBorderless
              formControlName="radius"
              placeholder="Distance"
              nzPlaceHolder="Distance"
            >
              <nz-option
                *ngFor="let item of distanceSelectors"
                [nzLabel]="item"
                [nzValue]="item"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </form>
    </nz-col>
    <nz-col [nzSpan]="(loadingUtilities$ | async) ? 0 : 2"> </nz-col>

    <nz-col nzSpan="4">
      <button
        nz-button
        nzBlock
        nzType="text"
        (click)="validedSearch()"
        [disabled]="
          (loadingUtilities$ | async) || !projetproFormationForm.valid
        "
      >
        <h5 nz-typography><i nz-icon nzType="search"></i> Rechercher</h5>
      </button>
    </nz-col>
  </nz-row>
  <nz-divider
    nzOrientation="center"
    nzType="horizontal"
    [nzText]="formationSuggestionsSize"
  ></nz-divider>

  <ng-template #formationSuggestionsSize>
    <nz-badge
      [nzCount]="formations$ | async | size"
      *ngIf="!(currentFormation | async) && !(loading$ | async)"
    ></nz-badge>

    <i nz-icon nzType="loading" class="loading" *ngIf="loading$ | async"></i>
  </ng-template>

  <div [ngSwitch]="view$ | async">
    <div *ngSwitchCase="'list'">
      <app-layout-list
        *ngIf="formations$ | async | size; else noResults"
        [card]="card"
        [source]="formations$ | async"
      ></app-layout-list>
    </div>
    <div *ngSwitchCase="'details'" class="formationSugestionDetails">
      <app-projet-professionnel-formations-form-detail
        [formation]="currentFormation | async"
        (back)="onBack()"
        (add)="onAdd($event, projetpro)"
      ></app-projet-professionnel-formations-form-detail>
    </div>
    <div *ngSwitchDefault>
      <app-layout-list
        *ngIf="formations$ | async | size; else noResults"
        [card]="card"
        [source]="formations$ | async"
      ></app-layout-list>
    </div>
  </div>

  <ng-template #card let-item let-position="idx">
    <app-projet-professionnel-formations-form-card
      [formation]="item"
      (click)="onSelect(item, projetpro)"
    ></app-projet-professionnel-formations-form-card>
  </ng-template>

  <ng-template #noResults>
    <app-no-result
      [icon]="'school'"
      [isButton]="false"
      [notitle]="'Aucun formation trouvée'"
    ></app-no-result>
  </ng-template>
</ng-container>
