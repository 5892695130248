<nz-select
  [(ngModel)]="departementSelected"
  nzBorderless
  (ngModelChange)="onSelect($event)"
  nzPlaceHolder="Sélectionner un service"
  [nzLoading]="loading$ | async"
  [nzNotFoundContent]="noData"
  [nzOptionHeightPx]="40"
  [nzServerSearch]="true"
  [nzAllowClear]="true"
>
  <nz-option
    *ngFor="let item of departements$ | async"
    nzCustomContent
    [nzLabel]="item.title | titlecase"
    [nzValue]="item"
  >
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="24"
        ><h5 nz-typography>{{ item.title | titlecase }}</h5></nz-col
      >
    </nz-row>
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="24"
        ><small nz-typography>{{
          item.adresse?.adresse?.properties?.label | carLimitation: 80
        }}</small></nz-col
      >
    </nz-row>
  </nz-option>
</nz-select>

<ng-template #noData>
  <app-no-result
    [icon]="icon"
    [description]="'Aucun service'"
    [isButton]="false"
  ></app-no-result>
</ng-template>
