<nz-row nzAlign="top" nzJustify="center" nzGutter="4">
  <nz-col nzSpan="15">
    <div class="content" *ngIf="!(editing$ | async)">
      <nz-card
        nzBorderless
        [nzTitle]="risqueItemTitlte"
        *ngIf="
          editingListItemShow$ | async as editingListItemShow;
          else noLivrable
        "
      >
        <ng-template #risqueItemTitlte>
          {{ editingListItemShow?.title }}
        </ng-template>
        <nz-descriptions nzBordered nzSize="small">
          <nz-descriptions-item nzSpan="3" [nzTitle]="descriptionItem">
            <p class="itemDescription">
              {{ editingListItemShow?.description }}
            </p>
          </nz-descriptions-item>
        </nz-descriptions>
        <ng-template #descriptionItem>
          <mat-icon class="iconSujetItem">subject</mat-icon>
        </ng-template>
      </nz-card>
    </div>
  </nz-col>
  <nz-col nzSpan="1">
    <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
  </nz-col>
  <nz-col nzSpan="8">
    <nz-list [nzHeader]="risqueHeader" [nzBordered]="false" nzSize="small">
      <ng-template #risqueHeader>
        <nz-row nzJustify="start" nzAlign="middle" nzGutter="4">
          <nz-col nzSpan="12">
            <button
              nz-button
              nz-tooltip
              nzBlock
              [nzTooltipMouseEnterDelay]="0.5"
              nzTooltipPlacement="bottom"
              nzTooltipTitle="Nouveau risque"
              (click)="onNew(cadrage)"
              nzType="text"
              class="btnPrimary"
            >
              <p nz-typography>Nouveau</p>
            </button>
          </nz-col>
          <nz-col nzSpan="12">
            <button
              nz-button
              nz-tooltip
              nzBlock
              [nzTooltipMouseEnterDelay]="0.5"
              nzTooltipPlacement="bottom"
              nzTooltipTitle="Supprimler tous les risques"
              nz-popconfirm
              nzPopconfirmTitle="Voulez-vous supprimer tous les risques ?"
              nzPopconfirmPlacement="bottom"
              (nzOnConfirm)="onDeleteAllList(cadrage)"
              (nzOnCancel)="onCancel()"
              nzType="text"
              (click)="onDeleteAllList(cadrage)"
            >
              <p nz-typography>Supprimer</p>
            </button>
          </nz-col>
        </nz-row>
      </ng-template>
      <cdk-virtual-scroll-viewport appendOnly itemSize="75" class="viewport">
        <ng-container>
          <nz-list-item
            *cdkVirtualFor="let item of cadrage?.risques"
            (click)="$event.stopPropagation(); editingListItemShow$.next(item)"
          >
            <nz-row nzAlign="top" nzJustify="center" nzGutter="4">
              <nz-col nzSpan="20">
                <span>{{ item?.title }}</span>
                <nz-row nzAlign="top" nzGutter="2" nzJustify="start">
                  <nz-col nzSpan="24">
                    <small>{{ item?.echeance | dateFr: "small" }}</small>
                  </nz-col>
                </nz-row>
              </nz-col>
              <nz-col nzSpan="4">
                <button
                  nzShape="circle"
                  nz-button
                  nz-dropdown
                  [nzDropdownMenu]="menu"
                  nzTrigger="click"
                  (click)="$event.stopPropagation()"
                >
                  <i nz-icon nzType="more"></i>
                </button>
              </nz-col>
            </nz-row>
            <nz-dropdown-menu #menu="nzDropdownMenu">
              <ul nz-menu>
                <li nz-menu-item (click)="onNew(cadrage, item)">
                  <i nz-icon nzType="edit"></i>
                  <span class="dropdowMenuSpan">Modifier</span>
                </li>
                <li nz-menu-divider></li>
                <li
                  nz-menu-item
                  nzDanger
                  nz-popconfirm
                  nzPopconfirmTitle="Voulez-vous supprimer ce risque ?"
                  nzPopconfirmPlacement="bottom"
                  (nzOnConfirm)="onDeleteItem(item, cadrage)"
                  (nzOnCancel)="onCancel()"
                >
                  <i nz-icon nzType="delete"></i>
                  <span class="dropdowMenuSpan">Supprimer</span>
                </li>
              </ul>
            </nz-dropdown-menu>
          </nz-list-item>
        </ng-container>
      </cdk-virtual-scroll-viewport>
    </nz-list>
  </nz-col>
</nz-row>
<ng-template #noLivrable>
  <div class="containerEmpty">
    <div>
      <mat-icon class="iconEmpty">app_registration</mat-icon>
      <h4 nz-typography class="contentEmpty">Aucun risque sélectionné</h4>
    </div>
  </div>
</ng-template>
