<nz-tabset
  nzCentered
  [nzAnimated]="true"
  nzSize="small"
  [nzTabBarExtraContent]="tabBarExtraContentTpl"
  [nzSelectedIndex]="selectedIndex"
  (nzSelectedIndexChange)="onSelect($event)"
  [nzTabBarStyle]="{
    'background-color': tabBarBgColor,
    color: 'grey'
  }"
>
  <nz-tab *ngFor="let item of tabs; let i = index" [nzTitle]="tabTitleTpl">
    <ng-template #tabTitleTpl>
      <app-tab-title
        [title]="item.name"
        [isActiv]="selectedIndex === i ? true : false"
        [count]="item?.count"
      ></app-tab-title>
    </ng-template>
    <nz-card nzBorderless>
      <ng-container *ngTemplateOutlet="item.template"></ng-container>
    </nz-card>
  </nz-tab>
</nz-tabset>

<ng-template #tabBarExtraContentTpl>
  <ng-container *ngTemplateOutlet="extraTemplate"></ng-container>
</ng-template>
