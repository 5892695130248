<nz-row
  *ngIf="departement$ | async as departement"
  nzAlign="middle"
  nzGutter="8"
  nzJustify="end"
  class="rowTitle"
>
  <nz-col nzXl="1" nzXXl="1" nzLg="0" nzXs="0" nzSm="0" nzMd="0">
    <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
  </nz-col>
  <nz-col nzXl="1" nzXXl="1" nzLg="0" nzXs="0" nzSm="0" nzMd="0">
    <mat-icon class="itemTypeIcon">group_work</mat-icon>
  </nz-col>
  <nz-col nzXl="15" nzXXl="15" nzLg="24" nzXs="24" nzSm="24" nzMd="24">
    <div class="titleHeader">
      <h5 nz-typography>
        {{ departement?.title | uppercase | carLimitation: 40 }}
      </h5>
    </div>
  </nz-col>
  <nz-col nzXl="2" nzXXl="2" nzLg="0" nzXs="0" nzSm="0" nzMd="0">
    <div class="btn_dropdown">
      <app-button-dropdown
        [isDelete]="true"
        [deleteType]="deleteType"
        (select)="onAction($event, departement)"
      ></app-button-dropdown>
    </div>
  </nz-col>
  <nz-col nzXl="3" nzXXl="3" nzLg="0" nzXs="0" nzSm="0" nzMd="0"></nz-col>
  <nz-col nzXl="1" nzXXl="1" nzLg="0" nzXs="0" nzSm="0" nzMd="0">
    <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
  </nz-col>
</nz-row>
