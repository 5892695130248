<form nz-form [formGroup]="filterForm" *ngIf="operation$ | async as operation">
  <nz-form-item>
    <nz-form-control>
      <nz-select
        nzServerSearch
        nzBorderless
        nzAllowClear
        nzShowSearch
        [ngModelOptions]="{ standalone: true }"
        [nzNotFoundContent]="noData"
        nzPlaceHolder="Rechercher"
        [(ngModel)]="filterInputMode"
        (nzOnSearch)="filterInput$.next($event); queryEntites(operation)"
        [nzOptionHeightPx]="100"
        (ngModelChange)="onSelect($event)"
      >
        <nz-option
          *ngFor="let item of entites$ | async"
          [nzLabel]="item?.entite?.etablissement?.denomination"
          [nzValue]="item"
          nzCustomContent
        >
          <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
            <nz-col nzSpan="2">
              <mat-icon>domaine</mat-icon>
            </nz-col>
            <nz-col nzSpan="2">
              <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
            </nz-col>

            <nz-col nzSpan="20">
              <strong>{{
                item?.entite?.etablissement?.denomination | carLimitation: 100
              }}</strong>
            </nz-col>
          </nz-row>
          <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
            <nz-col nzSpan="2"> </nz-col>
            <nz-col nzSpan="2"> </nz-col>

            <nz-col nzSpan="10">
              <small>{{
                item?.entite?.etablissement?.activite_principale_libelle
                  ?.intitule_naf | carLimitation: 60
              }}</small>
            </nz-col>
            <nz-col nzSpan="2">
              <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
            </nz-col>
            <nz-col nzSpan="6">
              <small>SIREN : {{ item?.entite?.etablissement?.siren }}</small>
            </nz-col>
            <nz-col nzSpan="2">
              <nz-tag
                nzColor="success"
                *ngIf="item?.entite?.etablissement?.economie_sociale_solidaire"
                >ESS</nz-tag
              >
            </nz-col>
          </nz-row>
          <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
        </nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
</form>

<ng-template #noData>
  <app-no-result
    [icon]="'domaine'"
    [description]="'Aucune entité trouvée'"
    [isButton]="false"
  ></app-no-result>
</ng-template>
