<div [ngSwitch]="limit">
  <div *ngSwitchCase="4">
    <nz-avatar-group *ngIf="source | size">
      <div
        class="avatar placeholder"
        nz-tooltip
        [nzTooltipMouseEnterDelay]="mouseEnterDelay"
        nzTooltipPlacement="bottom"
        [nzTooltipTitle]="source[0]?.displayName"
        (click)="onSelect(source[0])"
      >
        <div
          class="text-neutral-content rounded-full w-12"
          [ngStyle]="{
            'background-color': source[0]?.setting?.color
              ? source[0].setting?.color
              : default
          }"
        >
          <span>{{ source[0].avatar }}</span>
        </div>
      </div>

      <nz-avatar
        *ngIf="(source | size) === 2"
        [nzSize]="size"
        [nzText]="source[1].avatar"
        [ngStyle]="{
          'background-color': source[1]?.setting?.color
            ? source[1].setting?.color
            : default
        }"
        nz-tooltip
        [nzTooltipMouseEnterDelay]="mouseEnterDelay"
        nzTooltipPlacement="bottom"
        [nzTooltipTitle]="source[1]?.displayName"
        (click)="onSelect(source[1])"
      ></nz-avatar>
      <nz-avatar
        *ngIf="(source | size) === 3"
        [nzSize]="size"
        [nzText]="source[2].avatar"
        [ngStyle]="{
          'background-color': source[2]?.setting?.color
            ? source[2].setting?.color
            : default
        }"
        nz-tooltip
        [nzTooltipMouseEnterDelay]="mouseEnterDelay"
        nzTooltipPlacement="bottom"
        [nzTooltipTitle]="source[2]?.displayName"
        (click)="onSelect(source[2])"
      ></nz-avatar>
      <nz-avatar
        *ngIf="(operation?.equipe | size) === 4"
        [nzText]="source[3].avatar"
        [nzSize]="size"
        [ngStyle]="{
          'background-color': source[3]?.setting?.color
            ? source[3].setting?.color
            : default
        }"
        nz-tooltip
        [nzTooltipMouseEnterDelay]="mouseEnterDelay"
        nzTooltipPlacement="bottom"
        [nzTooltipTitle]="source[3]?.displayName"
        (click)="onSelect(source[3])"
      ></nz-avatar>
      <nz-avatar
        *ngIf="(source | size) - limit > 0"
        [nzSize]="size"
        [nzText]="(source | size) - limit | string"
        [ngStyle]="{ 'background-color': '#faad14' }"
      ></nz-avatar>
    </nz-avatar-group>
  </div>
  <div *ngSwitchCase="6">
    <nz-avatar-group *ngIf="source | size">
      <nz-avatar
        [nzSize]="size"
        [nzText]="source[0].avatar"
        [ngStyle]="{
          'background-color': source[0]?.setting?.color
            ? source[0].setting?.color
            : default
        }"
        nz-tooltip
        [nzTooltipMouseEnterDelay]="mouseEnterDelay"
        nzTooltipPlacement="bottom"
        [nzTooltipTitle]="source[0]?.displayName"
        (click)="onSelect(source[0])"
      ></nz-avatar>
      <nz-avatar
        *ngIf="(source | size) === 2"
        [nzText]="source[1].avatar"
        [nzSize]="size"
        [ngStyle]="{
          'background-color': source[1]?.setting?.color
            ? source[1].setting?.color
            : default
        }"
        nz-tooltip
        [nzTooltipMouseEnterDelay]="mouseEnterDelay"
        nzTooltipPlacement="bottom"
        [nzTooltipTitle]="source[1]?.displayName"
        (click)="onSelect(source[1])"
      ></nz-avatar>
      <nz-avatar
        *ngIf="(source | size) === 3"
        [nzText]="source[2].avatar"
        [nzSize]="size"
        [ngStyle]="{
          'background-color': source[2]?.setting?.color
            ? source[2].setting?.color
            : default
        }"
        nz-tooltip
        [nzTooltipMouseEnterDelay]="mouseEnterDelay"
        nzTooltipPlacement="bottom"
        [nzTooltipTitle]="source[2]?.displayName"
        (click)="onSelect(source[2])"
      ></nz-avatar>
      <nz-avatar
        *ngIf="(operation?.equipe | size) === 4"
        [nzText]="source[3].avatar"
        [nzSize]="size"
        [ngStyle]="{
          'background-color': source[3]?.setting?.color
            ? source[3].setting?.color
            : default
        }"
        nz-tooltip
        [nzTooltipMouseEnterDelay]="mouseEnterDelay"
        nzTooltipPlacement="bottom"
        [nzTooltipTitle]="source[3]?.displayName"
        (click)="onSelect(source[3])"
      ></nz-avatar>
      <nz-avatar
        *ngIf="(operation?.equipe | size) === 5"
        [nzText]="source[4].avatar"
        [nzSize]="size"
        [ngStyle]="{
          'background-color': source[4]?.setting?.color
            ? source[4].setting?.color
            : default
        }"
        nz-tooltip
        [nzTooltipMouseEnterDelay]="mouseEnterDelay"
        nzTooltipPlacement="bottom"
        [nzTooltipTitle]="source[4]?.displayName"
        (click)="onSelect(source[4])"
      ></nz-avatar>
      <nz-avatar
        *ngIf="(operation?.equipe | size) === 6"
        [nzText]="source[5].avatar"
        [nzSize]="size"
        [ngStyle]="{
          'background-color': source[5]?.setting?.color
            ? source[5].setting?.color
            : default
        }"
        nz-tooltip
        [nzTooltipMouseEnterDelay]="mouseEnterDelay"
        nzTooltipPlacement="bottom"
        [nzTooltipTitle]="source[5]?.displayName"
        (click)="onSelect(source[5])"
      ></nz-avatar>
      <nz-avatar
        *ngIf="(source | size) - limit > 0"
        [nzSize]="size"
        [nzText]="(source | size) - limit | string"
        [ngStyle]="{ 'background-color': '#faad14' }"
      ></nz-avatar>
    </nz-avatar-group>
  </div>
  <div *ngSwitchCase="8">
    <nz-avatar-group *ngIf="source | size">
      <nz-avatar
        [nzText]="source[0].avatar"
        [nzSize]="size"
        [ngStyle]="{
          'background-color': source[0]?.setting?.color
            ? source[0].setting?.color
            : default
        }"
        nz-tooltip
        [nzTooltipMouseEnterDelay]="mouseEnterDelay"
        nzTooltipPlacement="bottom"
        [nzTooltipTitle]="source[0]?.displayName"
        (click)="onSelect(source[0])"
      ></nz-avatar>
      <nz-avatar
        *ngIf="(source | size) === 2"
        [nzText]="source[1].avatar"
        [nzSize]="size"
        [ngStyle]="{
          'background-color': source[1]?.setting?.color
            ? source[1].setting?.color
            : default
        }"
        nz-tooltip
        [nzTooltipMouseEnterDelay]="mouseEnterDelay"
        nzTooltipPlacement="bottom"
        [nzTooltipTitle]="source[1]?.displayName"
        (click)="onSelect(source[1])"
      ></nz-avatar>
      <nz-avatar
        *ngIf="(source | size) === 3"
        [nzText]="source[2].avatar"
        [nzSize]="size"
        [ngStyle]="{
          'background-color': source[2]?.setting?.color
            ? source[2].setting?.color
            : default
        }"
        nz-tooltip
        [nzTooltipMouseEnterDelay]="mouseEnterDelay"
        nzTooltipPlacement="bottom"
        [nzTooltipTitle]="source[2]?.displayName"
        (click)="onSelect(source[2])"
      ></nz-avatar>
      <nz-avatar
        *ngIf="(operation?.equipe | size) === 4"
        [nzText]="source[3].avatar"
        [nzSize]="size"
        [ngStyle]="{
          'background-color': source[3]?.setting?.color
            ? source[3].setting?.color
            : default
        }"
        nz-tooltip
        [nzTooltipMouseEnterDelay]="mouseEnterDelay"
        nzTooltipPlacement="bottom"
        [nzTooltipTitle]="source[3]?.displayName"
        (click)="onSelect(source[3])"
      ></nz-avatar>
      <nz-avatar
        *ngIf="(operation?.equipe | size) === 5"
        [nzText]="source[4].avatar"
        [nzSize]="size"
        [ngStyle]="{
          'background-color': source[4]?.setting?.color
            ? source[4].setting?.color
            : default
        }"
        nz-tooltip
        [nzTooltipMouseEnterDelay]="mouseEnterDelay"
        nzTooltipPlacement="bottom"
        [nzTooltipTitle]="source[4]?.displayName"
        (click)="onSelect(source[4])"
      ></nz-avatar>
      <nz-avatar
        *ngIf="(operation?.equipe | size) === 6"
        [nzText]="source[5].avatar"
        [nzSize]="size"
        [ngStyle]="{
          'background-color': source[5]?.setting?.color
            ? source[5].setting?.color
            : default
        }"
        nz-tooltip
        [nzTooltipMouseEnterDelay]="mouseEnterDelay"
        nzTooltipPlacement="bottom"
        [nzTooltipTitle]="source[5]?.displayName"
        (click)="onSelect(source[5])"
      ></nz-avatar>
      <nz-avatar
        *ngIf="(operation?.equipe | size) === 7"
        [nzText]="source[6].avatar"
        [nzSize]="size"
        [ngStyle]="{
          'background-color': source[6]?.setting?.color
            ? source[6].setting?.color
            : default
        }"
        nz-tooltip
        [nzTooltipMouseEnterDelay]="mouseEnterDelay"
        nzTooltipPlacement="bottom"
        [nzTooltipTitle]="source[6]?.displayName"
        (click)="onSelect(source[6])"
      ></nz-avatar>
      <nz-avatar
        *ngIf="(operation?.equipe | size) === 8"
        [nzText]="source[7].avatar"
        [nzSize]="size"
        [ngStyle]="{
          'background-color': source[7]?.setting?.color
            ? source[7].setting?.color
            : default
        }"
        nz-tooltip
        [nzTooltipMouseEnterDelay]="mouseEnterDelay"
        nzTooltipPlacement="bottom"
        [nzTooltipTitle]="source[7]?.displayName"
        (click)="onSelect(source[7])"
      ></nz-avatar>
      <nz-avatar
        *ngIf="(source | size) - limit > 0"
        [nzText]="(source | size) - limit | string"
        [nzSize]="size"
        [ngStyle]="{ 'background-color': '#faad14' }"
      ></nz-avatar>
    </nz-avatar-group>
  </div>
  <div *ngSwitchCase="10">
    <nz-avatar-group *ngIf="source | size">
      <nz-avatar
        [nzText]="source[0].avatar"
        [nzSize]="size"
        [ngStyle]="{
          'background-color': source[0]?.setting?.color
            ? source[0].setting?.color
            : default
        }"
        nz-tooltip
        [nzTooltipMouseEnterDelay]="mouseEnterDelay"
        nzTooltipPlacement="bottom"
        [nzTooltipTitle]="source[0]?.displayName"
        (click)="onSelect(source[0])"
      ></nz-avatar>
      <nz-avatar
        *ngIf="(source | size) === 2"
        [nzText]="source[1].avatar"
        [nzSize]="size"
        [ngStyle]="{
          'background-color': source[1]?.setting?.color
            ? source[1].setting?.color
            : default
        }"
        nz-tooltip
        [nzTooltipMouseEnterDelay]="mouseEnterDelay"
        nzTooltipPlacement="bottom"
        [nzTooltipTitle]="source[1]?.displayName"
        (click)="onSelect(source[1])"
      ></nz-avatar>
      <nz-avatar
        *ngIf="(source | size) === 3"
        [nzText]="source[2].avatar"
        [nzSize]="size"
        [ngStyle]="{
          'background-color': source[2]?.setting?.color
            ? source[2].setting?.color
            : default
        }"
        nz-tooltip
        [nzTooltipMouseEnterDelay]="mouseEnterDelay"
        nzTooltipPlacement="bottom"
        [nzTooltipTitle]="source[2]?.displayName"
        (click)="onSelect(source[2])"
      ></nz-avatar>
      <nz-avatar
        *ngIf="(operation?.equipe | size) === 4"
        [nzText]="source[3].avatar"
        [nzSize]="size"
        [ngStyle]="{
          'background-color': source[3]?.setting?.color
            ? source[3].setting?.color
            : default
        }"
        nz-tooltip
        [nzTooltipMouseEnterDelay]="mouseEnterDelay"
        nzTooltipPlacement="bottom"
        [nzTooltipTitle]="source[3]?.displayName"
        (click)="onSelect(source[3])"
      ></nz-avatar>
      <nz-avatar
        *ngIf="(operation?.equipe | size) === 5"
        [nzText]="source[4].avatar"
        [nzSize]="size"
        [ngStyle]="{
          'background-color': source[4]?.setting?.color
            ? source[4].setting?.color
            : default
        }"
        nz-tooltip
        [nzTooltipMouseEnterDelay]="mouseEnterDelay"
        nzTooltipPlacement="bottom"
        [nzTooltipTitle]="source[4]?.displayName"
        (click)="onSelect(source[4])"
      ></nz-avatar>
      <nz-avatar
        *ngIf="(operation?.equipe | size) === 6"
        [nzText]="source[5].avatar"
        [nzSize]="size"
        [ngStyle]="{
          'background-color': source[5]?.setting?.color
            ? source[5].setting?.color
            : default
        }"
        nz-tooltip
        [nzTooltipMouseEnterDelay]="mouseEnterDelay"
        nzTooltipPlacement="bottom"
        [nzTooltipTitle]="source[5]?.displayName"
        (click)="onSelect(source[5])"
      ></nz-avatar>
      <nz-avatar
        *ngIf="(operation?.equipe | size) === 7"
        [nzText]="source[6].avatar"
        [nzSize]="size"
        [ngStyle]="{
          'background-color': source[6]?.setting?.color
            ? source[6].setting?.color
            : default
        }"
        nz-tooltip
        [nzTooltipMouseEnterDelay]="mouseEnterDelay"
        nzTooltipPlacement="bottom"
        [nzTooltipTitle]="source[6]?.displayName"
        (click)="onSelect(source[6])"
      ></nz-avatar>
      <nz-avatar
        *ngIf="(operation?.equipe | size) === 8"
        [nzText]="source[7].avatar"
        [nzSize]="size"
        [ngStyle]="{
          'background-color': source[7]?.setting?.color
            ? source[7].setting?.color
            : default
        }"
        nz-tooltip
        [nzTooltipMouseEnterDelay]="mouseEnterDelay"
        nzTooltipPlacement="bottom"
        [nzTooltipTitle]="source[7]?.displayName"
        (click)="onSelect(source[7])"
      ></nz-avatar>
      <nz-avatar
        *ngIf="(operation?.equipe | size) === 9"
        [nzText]="source[8].avatar"
        [nzSize]="size"
        [ngStyle]="{
          'background-color': source[8]?.setting?.color
            ? source[8].setting?.color
            : default
        }"
        nz-tooltip
        [nzTooltipMouseEnterDelay]="mouseEnterDelay"
        nzTooltipPlacement="bottom"
        [nzTooltipTitle]="source[8]?.displayName"
        (click)="onSelect(source[8])"
      ></nz-avatar>
      <nz-avatar
        *ngIf="(operation?.equipe | size) === 10"
        [nzText]="source[9].avatar"
        [nzSize]="size"
        [ngStyle]="{
          'background-color': source[9]?.setting?.color
            ? source[9].setting?.color
            : default
        }"
        nz-tooltip
        [nzTooltipMouseEnterDelay]="mouseEnterDelay"
        nzTooltipPlacement="bottom"
        [nzTooltipTitle]="source[9]?.displayName"
        (click)="onSelect(source[9])"
      ></nz-avatar>
      <nz-avatar
        *ngIf="(source | size) - limit > 0"
        [nzText]="(source | size) - limit | string"
        [nzSize]="size"
        [ngStyle]="{ 'background-color': '#faad14' }"
      ></nz-avatar>
    </nz-avatar-group>
  </div>
  <div *ngSwitchDefault>Aucun limite renseignée</div>
</div>
