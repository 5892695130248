import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Ticket, TicketId } from './ticket.model';
import * as TicketActions from './ticket.actions';

export const ticketsFeatureKey = 'tickets';

export interface TicketState extends EntityState<TicketId> {
  ticket: TicketId | any;
  loading: boolean;
  display: boolean;
  error: any;
}

export const adapter: EntityAdapter<TicketId> = createEntityAdapter<TicketId>();

export const initialState: TicketState = adapter.getInitialState({
  ticket: undefined,
  loading: false,
  display: false,
  error: undefined,
});

export const reducer = createReducer(
  initialState,
  on(TicketActions.addTicketSuccess, (state, action) =>
    adapter.addOne(action.ticket, state)
  ),
  on(TicketActions.updateTicket, (state, action) =>
    adapter.updateOne(action.ticket, state)
  ),
  on(TicketActions.deleteTicket, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(TicketActions.deleteTickets, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(TicketActions.loadTicketsSuccess, (state, action) =>
    adapter.setAll(action.tickets, state)
  ),
  on(TicketActions.loadingTickets, (state, action) => {
    return {
      ...state,
      loading: action.loader,
    };
  }),
  on(TicketActions.displayTickets, (state, action) => {
    return {
      ...state,
      display: action.display,
    };
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
