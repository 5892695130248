import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Aid, AidId } from './aids.model';
import * as AidsActions from './aids.actions';

export const aidsesFeatureKey = 'aidses';

export interface AidState extends EntityState<any> {
  filters: any;
  aids: any;
  loading: boolean;
  loadingAids: boolean;

  error: any;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>();

export const initialState: AidState = adapter.getInitialState({
  filters: undefined,
  aids: undefined,
  loading: false,
  loadingAids: false,
  error: undefined,
});

export const reducer = createReducer(
  initialState,
  on(AidsActions.loading, (state, action) => {
    return {
      ...state,
      loading: action.loading,
    };
  }),

  //FILTERS
  on(AidsActions.loadAidsFilters, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),

  on(AidsActions.loadAidsFiltersSuccess, (state, action) => {
    return {
      ...state,
      filters: action.filters,
    };
  }),

  on(AidsActions.loadAidsFiltersFailure, (state, action) => {
    return {
      ...state,
      loading: false,
    };
  }),
  //AIDS
  on(AidsActions.loadAids, (state, action) => {
    return {
      ...state,
      loadingAids: true,
    };
  }),

  on(AidsActions.loadAidsSuccess, (state, action) => {
    return {
      ...state,
      aids: action.aids,
      loadingAids: false,
    };
  }),

  on(AidsActions.loadAidsFailure, (state, action) => {
    return {
      ...state,
      loadingAids: false,
    };
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
