import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromEntiteTask from './store/entite-task.reducer';
import { EffectsModule } from '@ngrx/effects';
import { EntiteTaskEffects } from './store/entite-task.effects';
import { EntiteTaskService } from 'src/app/service/entite.task.service';
import { TaskModule } from 'src/app/features/task/task.module';
import { EntiteTaskComponent } from './components/entite-task/entite-task.component';
import { MetiersModule } from 'src/app/features/metiers/metiers.module';
import { AvatarModule } from 'src/app/features/avatar/avatar.module';
import { SuiviModule } from 'src/app/features/suivi/suivi.module';

@NgModule({
  declarations: [EntiteTaskComponent],
  imports: [
    CommonModule,
    TaskModule,
    MetiersModule,
    AvatarModule,
    SuiviModule,
    StoreModule.forFeature(
      fromEntiteTask.entiteTasksFeatureKey,
      fromEntiteTask.reducer
    ),
    EffectsModule.forFeature([EntiteTaskEffects]),
  ],
  exports: [EntiteTaskComponent],
  providers: [EntiteTaskService],
})
export class EntiteTaskModule {}
