<ng-container *ngIf="entite$ | async as entite">
  <nz-page-header
    class="page-header"
    [nzTitle]="header"
    [nzSubtitle]="cardExtra"
  >
    <nz-page-header-extra> </nz-page-header-extra>
    <nz-page-header-content> </nz-page-header-content>
  </nz-page-header>
  <nz-divider></nz-divider>
  <ng-template #header>
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="24">
        <h4 nz-typography>Collaborateurs</h4>
      </nz-col>
    </nz-row>
  </ng-template>
  <ng-template #cardExtra>
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="24">
        <nz-badge [nzCount]="contacts$ | async | size" [nzOverflowCount]="99">
        </nz-badge>
      </nz-col>
    </nz-row>
  </ng-template>

  <app-layout-list
    *ngIf="contacts$ | async | size; else noResults"
    [card]="card"
    [source]="contacts$ | async"
  ></app-layout-list>

  <ng-template #new>
    <app-card-new
      [title]="newCardTitle"
      [type]="'person'"
      [description]="newCardDescription"
      [definition]="newCardDefinition"
      [height]="'270px'"
      [width]="'300px'"
      (add)="onNewContactForm(entite)"
    ></app-card-new>
  </ng-template>

  <ng-template #card let-item let-position="idx">
    <app-contact-card [contact]="item"></app-contact-card>
  </ng-template>

  <ng-template #noResults>
    <app-no-result
      (new)="onNewContactForm(entite)"
      [icon]="'person_add'"
      [addtitle]="'Ajouter un collaborateur'"
      [notitle]="'Aucun collaborateur'"
    ></app-no-result>
  </ng-template>
</ng-container>
