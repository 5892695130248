import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { EMPTY, lastValueFrom, Observable, of } from 'rxjs';
import { OrganisationState } from 'src/app/components/organisation/store/organisation.reducer';
import * as fromOrganisationSelector from 'src/app/components/organisation/store/organisation.selectors';
import * as fromOrganisationAction from 'src/app/components/organisation/store/organisation.actions';

@Injectable({
  providedIn: 'any',
})
export class OrganisationResolverService {
  constructor(
    private organisationStore: Store<OrganisationState>,
    private router: Router
  ) {}

  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<any[]> | Promise<any[] | any> | any[] | any {
    let id = route.parent?.params['id'];
    let find = lastValueFrom(this.loadOrganisation());

    return find
      .then(() => {
        return this.getOrganisation();
      })
      .catch(() => {
        this.router.navigate(['/organisation', id]);
        return EMPTY;
      });
  }

  getOrganisation(): Observable<any[] | any> {
    return this.organisationStore.select(fromOrganisationSelector.organisation);
  }

  loadOrganisation(): Observable<any> {
    return of(
      this.organisationStore.dispatch(fromOrganisationAction.loadOrganisation())
    );
  }
}
