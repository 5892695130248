import { isNull, isUndefined } from 'lodash';

export const transformToIds = (items: any[]): any => {
  if (!items?.length) {
    return null;
  }

  const ids: string[] = items.map((item) => item.id);
  const idsMap: any = ids?.length
    ? ids.reduce((acc: any, cur: any) => {
        acc[cur] = true;
        return acc;
      }, {})
    : null;

  const transformers = { items: items, ids, idsMap };

  return transformers;
};

export const chartsValues = (statistiques: any) => {
  const values = statistiques
    ? Object.values(statistiques).map((item) => {
        if (isNull(item) || isUndefined(item) || !item) {
          return 'Non défini';
        }
        return item;
      })
    : [];

  const labels = statistiques
    ? Object.keys(statistiques).map((item) => {
        if (isNull(item) || isUndefined(item) || !item) {
          return 'Non défini';
        }
        return item;
      })
    : [];

  return { values, labels };
};

export const itemsCounter = (items: any[]) => {
  return items.reduce((acc, cur) => {
    const curSize = items.filter((el) => el === cur).length;

    acc[cur] = curSize;
    return acc;
  }, {});
};
