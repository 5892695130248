import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { ItemEventId } from '../item-event/itemEvent.model';

@Component({
  selector: 'app-suivi-item',
  templateUrl: './suivi-item.component.html',
  styleUrls: ['./suivi-item.component.scss'],
})
export class SuiviItemComponent implements OnInit {
  @Input() suivi: ItemEventId | any;
  @Input() size: string = '280px';

  @Output() update = new EventEmitter<ItemEventId>(false);
  @Output() cancel = new EventEmitter<boolean>(false);
  constructor() {}

  ngOnInit(): void {}

  onCancel(): void {
    this.cancel.emit(true);
  }

  onUpdate(suivi: ItemEventId): void {
    this.update.emit(suivi);
  }
}
