import { Apollo, gql } from 'apollo-angular';
import { OperationId } from './../components/operation/store/operation.model';
import { arrayToMap } from './../utils/utils.array';
import {
  ParticipantId,
  ParticipantSmall,
  ParticipantSmallId,
} from './../components/participant/store/participant.model';
import { Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  doc,
  docSnapshots,
  collectionChanges,
  getDoc,
  addDoc,
  setDoc,
  deleteDoc,
  serverTimestamp,
  DocumentData,
  Timestamp,
  query,
  where,
  arrayUnion,
  arrayRemove,
  collectionSnapshots,
  CollectionReference,
  DocumentReference,
  DocumentSnapshot,
  getDocs,
  documentId,
  limit,
} from '@angular/fire/firestore';
import { Observable, of, from, EMPTY } from 'rxjs';
import { switchMap, mergeMap, map, catchError } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { UserState } from '../components/user/store/user.reducer';
import * as fromUserSelector from '../components/user/store/user.selectors';
import { v4 as uuidV4 } from 'uuid';
import { format, fromUnixTime } from 'date-fns';
import { Participant } from '../components/participant/store/participant.model';
import { fr } from 'date-fns/locale';
import { upperFirst } from 'lodash';

@Injectable({
  providedIn: 'any',
})
export class ParticipantService {
  constructor(
    private db: Firestore,
    private fns: Functions,
    private userStore: Store<UserState>
  ) {}

  getAll(): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) of([]);

          if (user.claims.admin || user.claims.dev) {
            const dbCollection = collection(
              this.db,
              `organisations/${user?.organisation?.id}/participants`
            );

            const changes = collectionSnapshots(dbCollection).pipe(
              map((changes) => {
                return changes.map((snap) => {
                  const data = snap.data();
                  const id = snap.id;
                  return { id, ...data };
                });
              })
            );

            return changes;
          }

          const dbCollection = collection(
            this.db,
            `organisations/${user?.organisation?.id}/participants`
          );

          const collectionQuery = query(
            dbCollection,
            where(`access[${user.id}]`, '==', true)
          );

          const changes = collectionSnapshots(collectionQuery).pipe(
            map((changes) => {
              return changes.map((snap) => {
                const data = snap.data();
                const id = snap.id;
                return { id, ...data };
              });
            })
          );

          return changes;
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  getOneDetail(id: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) {
            return of(null);
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/participants/${id}/details`
            );

            const documentRef = doc(dbCollection, id);
            return docSnapshots(documentRef).pipe(
              map((snap) => {
                const data = snap.data();
                const id = snap.id;
                return { id, ...data };
              }),
              catchError((error) => EMPTY)
            );
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  getOne(id: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !id) {
            return of(null);
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/participants`
            );

            const documentRef = doc(dbCollection, id);
            return from(
              getDoc(documentRef)
                .then((snap) => {
                  const data = snap.data();
                  const id = snap.id;
                  return { id, ...data };
                })
                .catch((error) => error)
            ).pipe(mergeMap((res: any) => of(res)));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  getOneStatistiques(id: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) {
            return of(null);
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/participants/${id}/statistiques`
            );

            const documentRef = doc(dbCollection, id);
            return from(
              getDoc(documentRef)
                .then((doc: DocumentSnapshot<DocumentData>) => {
                  const data = doc.data() as any;
                  const id = doc.id;
                  return { id, ...data };
                })
                .catch((error) => error)
            ).pipe(mergeMap((res) => of(res)));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  addOne(participant: Participant): Observable<ParticipantSmallId | any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !participant) {
            return of(null);
          } else {
            return this.isExist(participant, user).pipe(
              switchMap((exists) => {
                return of({ exists, user });
              })
            );
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  addConfirmation(participant: Participant, user: any): Observable<any> {
    if (!user || !participant) {
      return of(null);
    } else {
      const smallVersionCollection = collection(
        this.db,
        `organisations/${user.organisation.id}/participants`
      );

      const newParticipant: Participant = this.participantDocument(
        user,
        participant
      );

      const participantSmall: ParticipantSmall = this.createSmallVersion(
        newParticipant,
        user
      );

      const addSmallVersionEntite = addDoc(
        smallVersionCollection,
        participantSmall
      );

      return from(
        addSmallVersionEntite
          .then((docRef) => {
            if (docRef.id) {
              const detailsCollection: DocumentReference<DocumentData> = doc(
                this.db,
                `organisations/${user.organisation.id}/participants/${docRef.id}/details/${docRef.id}`
              );

              return this.addDetailOne(
                detailsCollection,
                newParticipant,
                docRef.id
              );
            } else {
              return of(null);
            }
          })
          .catch((error) => of(error))
      ).pipe(mergeMap((res) => res));
    }
  }

  addDetailOne(
    detailsDollection: DocumentReference<DocumentData>,
    participant: Participant,
    id: string
  ): Observable<any> {
    try {
      if (!detailsDollection || !participant || !id) return of(null);

      return from(
        setDoc(detailsDollection, participant, { merge: true })
          .then(() => {
            return this.getOne(id);
          })
          .catch((error) => of(error))
      ).pipe(mergeMap((res) => res));
    } catch (error) {
      return of(error);
    }
  }

  isExist(
    participant: Participant | ParticipantId | ParticipantSmallId,
    user: any
  ): Observable<ParticipantSmallId[] | any> {
    try {
      if (user) {
        const { lastName, firstName } = participant;
        const contactsExistCollection = collection(
          this.db,
          `organisations/${user.organisation.id}/participants`
        );

        const queryContacts = query(
          contactsExistCollection,
          where('lastName', '==', lastName),
          where('firstName', '==', firstName),
          limit(1)
        );

        return from(
          getDocs(queryContacts)
            .then((snap) => {
              return snap.docs.map((doc) => {
                const data = doc.data();
                const id = doc.id;
                return { id, ...data };
              });
            })
            .catch((error) => error)
        ).pipe(mergeMap((res) => of(res)));
      } else {
        return of([]);
      }
    } catch (err) {
      return of(err);
    }
  }

  updateOne(
    id: string | number,
    participant: Partial<ParticipantId>
  ): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !participant) {
            return of(null);
          } else {
            const docRefId: string = participant?.id ? participant.id : '';
            const { civilite, lastName, firstName } = participant;
            const displayName: string = `${civilite} ${lastName} ${firstName}`;
            const documentRef: DocumentReference<DocumentData> = doc(
              this.db,
              `organisations/${user.organisation.id}/participants/${docRefId}/details/${docRefId}`
            );
            const nextDocument: any = {
              ...participant,
              dateUpdate: serverTimestamp(),
              userUpdate: user,
              auteur: user,
              referents: participant?.referents?.length
                ? participant?.referents
                : [user],
              referentsId: participant?.referentsId?.length
                ? participant?.referentsId
                : [user.id],
              metadata: this.createMetadata(participant),
            };
            return from(
              setDoc(documentRef, { ...nextDocument }, { merge: true })
                .then(() => of(`${displayName} modifié.e`))
                .catch((error) => of(error))
            )
              .pipe((res) => res)
              .pipe(mergeMap((res) => res));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  deleteOne(id: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !id) {
            return of(null);
          } else {
            const documentRef: DocumentReference<DocumentData> = doc(
              this.db,
              `organisations/${user.organisation.id}/participants/${id}/details/${id}`
            );

            return from(
              deleteDoc(documentRef)
                .then(() => `Participant supprimé.`)
                .catch((error) => error)
            ).pipe(mergeMap((res) => of(res)));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  deleteAll(ids: string[]): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !ids?.length) {
            return of(null);
          } else {
            //CALL HTTP

            return of(null);
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  participantDocument(
    user: any,
    participant: Participant | ParticipantId
  ): any {
    const referents =
      user?.claims?.member || user?.claims?.manager ? true : false;
    const referentsMap = referents
      ? {
          ...participant.referentsMapIds,
          [`${user.id}`]: user,
        }
      : participant.referentsMapIds;

    const criteresMap = participant?.criteres?.length
      ? arrayToMap(participant.criteres, 'item')
      : null;

    const newParticipant: Participant = {
      ...participant,
      modulesMapIds: null,
      actionsMapIds: null,
      entitesMapIds: null,
      contactsMapIds: null,
      service: participant?.service
        ? participant.service
        : user?.service
        ? user.service
        : null,
      metadata: this.createMetadata(participant),
      organisation: user.organisation,
      criteresMap: criteresMap,
      lastName: participant.lastName.toUpperCase(),
      firstName: upperFirst(participant.firstName),
      auths: [user.id],
      access: {
        [`${user.id}`]: true,
      },
      searchKey: `${participant.lastName.toLowerCase()} ${participant.firstName.toLowerCase()} ${
        participant?.sexe
      } ${participant?.age}`,

      organisationId: user.organisation.id,
      userUpdate: user,
      referents: referents ? arrayUnion(user) : participant.referents,
      referentsId: referents ? arrayUnion(user.id) : participant.referentsId,
      referentsMapIds: referentsMap,
      relations: null,
      dateUpdate: Timestamp.now(),
    };

    return newParticipant;
  }
  capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase();
  }

  createKeywords(name: any): any {
    if (name) {
      const arrName: any = [];
      let curName = '';
      name.split('').forEach((letter: any) => {
        curName += letter;
        arrName.push(curName);
      });
      return arrName;
    }
  }

  createMetadata(item: any): any {
    const { lastName, firstName } = item;
    const letterLastName = lastName ? this.capitalizeFirstLetter(lastName) : '';
    const letterFirstName = firstName
      ? this.capitalizeFirstLetter(firstName)
      : '';
    const arrayLastName = lastName ? this.createKeywords(lastName) : [];
    const arrayFirstName = firstName ? this.createKeywords(firstName) : [];
    const arrCritere = item.criteres
      ? item.criteres.reduce((acc: any, cur: any) => {
          acc[cur.item] = true;
          return acc;
        }, {})
      : {};

    const arrOperation = item.operation
      ? item.operation.reduce((obj: any, cur: any) => {
          obj[cur.id] = true;
          return obj;
        }, {})
      : {};

    const startYear =
      item.dateStart && item.dateStart['seconds']
        ? format(fromUnixTime(item.dateStart['seconds']), 'yyyy', {
            locale: fr,
          })
        : 'Non renseigné';
    const startMonth =
      item.dateStart && item.dateStart['seconds']
        ? format(fromUnixTime(item.dateStart['seconds']), 'MMMM', {
            locale: fr,
          })
        : 'Non renseigné';
    const endYear =
      item.dateEnd && item.dateEnd['seconds']
        ? format(fromUnixTime(item.dateEnd['seconds']), 'yyyy', { locale: fr })
        : 'Non renseigné';
    const endMonth =
      item.dateEnd && item.dateEnd['seconds']
        ? format(fromUnixTime(item.dateEnd['seconds']), 'MMMM', { locale: fr })
        : 'Non renseigné';
    const integrationYear =
      item.dateIntegration && item.dateIntegration['seconds']
        ? format(fromUnixTime(item.dateIntegration['seconds']), 'yyyy', {
            locale: fr,
          })
        : 'Non renseigné';
    const integrationMonth =
      item.dateIntegration && item.dateIntegration['seconds']
        ? format(fromUnixTime(item.dateIntegration['seconds']), 'MMMM', {
            locale: fr,
          })
        : 'Non renseigné';

    const postcode =
      item?.informations &&
      item?.informations?.adresse &&
      item?.informations?.adresse?.fields &&
      item?.informations?.adresse?.fields?.properties
        ? item?.informations?.adresse?.fields?.properties?.postcode
        : item?.informations &&
          item?.informations?.adresse &&
          item?.informations?.adresse?.adresse &&
          item?.informations?.adresse?.adresse?.properties
        ? item?.informations?.adresse?.adresse?.properties?.postcode
        : 'Non renseigné';

    const city =
      item?.informations &&
      item?.informations?.adresse &&
      item?.informations?.adresse?.fields &&
      item?.informations?.adresse?.fields?.properties
        ? item?.informations?.adresse?.fields?.properties?.city
        : item?.informations &&
          item?.informations?.adresse &&
          item?.informations?.adresse?.adresse &&
          item?.informations?.adresse?.adresse?.properties
        ? item?.informations?.adresse?.adresse?.properties?.city
        : 'Non renseignée';

    const context =
      item?.informations &&
      item?.informations?.adresse &&
      item?.informations?.adresse?.fields &&
      item?.informations?.adresse?.fields?.properties
        ? item?.informations.adresse.fields.properties.context
        : item?.informations &&
          item?.informations?.adresse &&
          item?.informations?.adresse?.adresse &&
          item?.informations?.adresse?.adresse?.properties
        ? item?.informations?.adresse?.adresse?.properties?.context
        : 'Non renseigné';

    const familiale = item?.situationPersonnelle?.familiale
      ? item?.situationPersonnelle?.familiale
      : null;

    const logement = item?.situationPersonnelle?.logement
      ? item?.situationPersonnelle?.logement
      : false;

    const internet = item?.situationPersonnelle?.internet
      ? item?.situationPersonnelle?.internet
      : false;

    const mobile = item?.situationPersonnelle?.mobile
      ? item?.situationPersonnelle?.mobile
      : false;

    const ordinateur = item?.situationPersonnelle?.ordinateur
      ? item?.situationPersonnelle?.ordinateur
      : false;

    const sante = item?.situationPersonnelle?.sante?.item
      ? item?.situationPersonnelle?.sante?.item
      : null;

    const mobilite = item?.situationPersonnelle?.mobilite?.item
      ? item?.situationPersonnelle?.mobilite?.item
      : null;

    const niveauNumerique = item?.situationPersonnelle?.numerique
      ? item?.situationPersonnelle?.numerique.reduce((obj: any, cur: any) => {
          obj[cur.id] = true;
          return obj;
        }, {})
      : {};

    const criteresMap = item?.criteres?.length
      ? arrayToMap(item.criteres, 'item')
      : null;

    const metatada = {
      letterLastName: letterLastName,
      letterFirstName: letterFirstName,
      arrayLastName: arrayLastName,
      arrayFirstName: arrayFirstName,
      criteres: arrCritere,
      criteresMap: criteresMap,
      niveauNumerique: niveauNumerique,
      mobilite: mobilite,
      sante: sante,
      ordinateur: ordinateur,
      internet: internet,
      logement: logement,
      familiale: familiale,
      mobile: mobile,
      operations: arrOperation,
      startYear: startYear,
      startMonth: startMonth,
      endYear: endYear,
      endMonth: endMonth,
      integrationYear: integrationYear,
      integrationMonth: integrationMonth,
      postcode: postcode,
      city: city,
      context: context,
    };

    return metatada;
  }

  setOperationMap(operation: OperationId): any | null {
    if (!operation) {
      return null;
    } else {
      const map = {
        [`${operation?.id}`]: {
          sortie: false,
          integration: false,
          desinscriptions: false,
          inscription: true,
          denomination: operation?.denomination ? operation.denomination : null,
          dateInscription: Timestamp.now(),
          dateIntegration: null,
          dateSortie: null,
          type: operation?.type ? operation.type : null,
          dateStart: operation?.description?.calendrier?.dateStart
            ? operation?.description?.calendrier?.dateStart
            : null,
          dateEnd: operation?.description?.calendrier?.dateEnd
            ? operation.description.calendrier.dateEnd
            : null,
          definition: operation?.description?.definition
            ? operation.description.definition
            : null,
          servicesMap: operation?.services ? operation.services : null,
          financeurs: operation?.financeursMap
            ? operation?.financeursMap
            : null,
          logoProgramme: operation?.logoProgramme
            ? operation.logoProgramme
            : null,
          sortiProgrammeQualitatif: null,
          sortiProgramme: null,
        },
      };

      return map;
    }
  }

  createSmallVersion(
    item: ParticipantId | Participant,
    user: any
  ): ParticipantSmall | ParticipantSmallId {
    const { lastName, firstName, sexe, age, informations } = item;
    const metadata = this.createMetadata(item);
    const organisation = {
      id: item.organisation.id,
      nom_raison_sociale: item.organisation.nom_raison_sociale,
      siret: item.organisation?.siret ? item.organisation?.siret : null,
      siren: item.organisation?.siren ? item.organisation?.siren : null,
      geo_adresse: item.organisation?.geo_adresse
        ? item.organisation?.geo_adresse
        : null,
      logo: item.organisation?.logo ? item.organisation?.logo : null,
    };

    const small: ParticipantSmall | ParticipantSmallId = {
      access: item?.access ? item?.access : null,
      civilite: item.civilite,
      lastName: item?.lastName ? item.lastName.toUpperCase() : 'Non renseigné',
      firstName: item?.firstName ? upperFirst(item.firstName) : 'Non renseigné',
      referents: item?.referents ? item.referents : [],
      referentsId: item?.referentsId ? item.referentsId : [],
      integration: item?.integration ? item.integration : false,
      droitAimage: item?.droitAimage ? item.droitAimage : false,
      consentementDonneesPersonnelles: item?.consentementDonneesPersonnelles
        ? item.consentementDonneesPersonnelles
        : false,
      sortiInscription: item?.sortiInscription ? item.sortiInscription : false,
      sortiProgramme: item?.sortiProgramme ? item?.sortiProgramme : false,
      suiteDeparcours: item?.suiteDeparcours ? item?.suiteDeparcours : false,
      inscription: item?.inscription ? item.inscription : true,
      dateStart: item.dateStart,
      dateEnd: item.dateEnd,
      maitriseFrancaisEcrit: item?.maitriseFrancaisEcrit
        ? item.maitriseFrancaisEcrit
        : null,
      permis: item?.permis ? item.permis : null,
      reprisDeJustice: item?.reprisDeJustice ? item.reprisDeJustice : false,
      dureeEntre: item?.dureeEntre ? item?.dureeEntre : 0,
      dureeParcours: item?.dureeParcours ? item?.dureeParcours : null,
      relations: null,
      dateNaissance: item?.dateNaissance ? item.dateNaissance : null,
      minimaSociaux: item?.minimaSociaux ? item?.minimaSociaux : null,
      numbCarteSejour: item?.numbCarteSejour ? item.numbCarteSejour : null,
      numCaiCir: item?.numCaiCir ? item.numCaiCir : null,

      dateInscriptionPoleEmploi: item?.informations?.poleEmploi?.dateStart
        ? item?.informations?.poleEmploi?.dateStart
        : null,

      poleEmploi: item?.informations?.poleEmploi?.libelle
        ? item?.informations?.poleEmploi?.libelle
        : null,

      referentPoleEmploi: item?.informations?.poleEmploi?.referent?.lastName
        ? `${item?.informations?.poleEmploi?.referent?.lastName} ${item?.informations?.poleEmploi?.referent?.firstName}`
        : null,

      missionLocale: item?.informations?.missionLocal?.referent?.lastName
        ? item?.informations?.missionLocal?.referent?.lastName
        : null,

      referentMissionLocale: item?.informations?.missionLocal?.denomination
        ? item?.informations?.missionLocal?.denomination
        : null,
      situationPersonnelle: item?.situationPersonnelle
        ? item.situationPersonnelle
        : null,
      datetIntegration: item.datetIntegration,
      informations: {
        adresse: {
          adresse: item?.informations?.adresse?.adresse
            ? item?.informations?.adresse.adresse
            : item?.informations?.adresse?.fields
            ? item?.informations?.adresse?.fields
            : null,

          qpv: item?.informations?.adresse?.qpv
            ? item?.informations?.adresse.qpv
            : false,
          qpvLibelle: item?.informations?.adresse?.qpvLibelle
            ? item?.informations?.adresse.qpvLibelle
            : '',
          complement: item?.informations?.adresse?.complement
            ? item.informations.adresse.complement
            : '',
          isQPV: item?.informations?.adresse?.isQPV
            ? item?.informations?.adresse?.isQPV
            : false,
          isQVA: item?.informations?.adresse?.isQVA
            ? item?.informations?.adresse?.isQVA
            : false,
          isZRR: item?.informations?.adresse?.isZRR
            ? item?.informations?.adresse?.isZRR
            : false,
        },
        coordonnees: {
          mobile: item?.informations?.coordonnees?.mobile
            ? item?.informations?.coordonnees?.mobile
            : '',
          phoneNumber: item?.informations?.coordonnees?.phoneNumber
            ? item?.informations?.coordonnees?.phoneNumber
            : '',
          email: item?.informations?.coordonnees?.email
            ? item?.informations?.coordonnees?.email
            : '',
          fixe: item?.informations?.coordonnees?.fixe
            ? item?.informations?.coordonnees?.fixe
            : '',
        },
      },
      organisation: organisation,
      metadata: metadata,
      searchKey: item?.searchKey
        ? item.searchKey
        : `${lastName.toLowerCase()} ${firstName.toLowerCase()} ${sexe} ${age}`,

      criteres: item?.criteres ? item.criteres : null,
      criteresMap: item?.criteresMap
        ? item.criteresMap
        : {
            jeunesDeMoinsDe26ans: false,
            personnePriseEnChargeIAE: false,
            PersonneEmployeGEIQ: false,
            beneficiaireProtectionInterRefugie: false,
            beneficiaireProtectionInterSubsidiaire: false,
            parentIsole: false,
            ase: false,
            autre: false,
            autrePrecision: '',
            primoArrivant: false,
            jeuneDecrocheur: false,
            autoentrepreneurQPV: false,
            fse: false,
            neet: false,
          },
      rqth: item?.rqth ? item.rqth : false,
      age: item?.age ? item.age : '',
      sexe: item?.sexe ? item.sexe : 'Autre',
      communication: item.communication,
      nationality: item?.nationality ? item.nationality : null,
      qualification: item?.qualification ? item.qualification : null,
      tranche: item?.tranche ? item.tranche : '',
      motifAccompagnement: item?.motifAccompagnement
        ? item.motifAccompagnement
        : '',
      dateEntree: item?.dateEntree ? item.dateEntree : null,
      typeCarteSejour: item?.typeCarteSejour ? item.typeCarteSejour : null,
      maitriseFrancais: item?.maitriseFrancais ? item.maitriseFrancais : null,
      droitDeTravail: item?.droitDeTravail ? item.droitDeTravail : false,
      disponibility: item?.disponibility ? item.disponibility : [],
      sortiProgrammeMotif: item?.sortiProgrammeMotif
        ? item.sortiProgrammeMotif
        : null,
      sortiInscriptionMotif: item?.sortiInscriptionMotif
        ? item.sortiInscriptionMotif
        : null,
      sortiProgrammeType: item?.sortiProgrammeType
        ? item.sortiProgrammeType
        : null,

      prescripteurs: item?.prescripteurs ? item.prescripteurs : null,
      priseEnCharge: item?.priseEnCharge ? item.priseEnCharge : null,
      status: item?.status ? item.status : '',
      impact3mois: item?.impact3mois ? item.impact3mois : null,
      impact6mois: item?.impact6mois ? item.impact6mois : null,
      impact12mois: item?.impact12mois ? item.impact12mois : null,
      operations: item?.operations ? item.operations : [],
      operationsMap: item?.operationsMap ? item.operationsMap : null,
      modules: item?.modules ? item.modules : [],
      modulesIds: item?.modulesIds ? item.modulesIds : [],
      modulesMapIds: item?.modulesMapIds ? item.modulesMapIds : null,
      actions: item?.actions ? item.actions : [],
      actionsIds: item?.actionsIds ? item.actionsIds : [],
      actionsMapIds: item?.actionsMapIds ? item.actionsMapIds : null,
      contacts: item?.contacts ? item.contacts : [],
      contactsIds: item?.contactsIds ? item.contactsIds : [],
      contactsMapIds: item?.contactsMapIds ? item.contactsMapIds : null,
      entites: item?.entites ? item.entites : [],
      entitesIds: item?.entitesIds ? item.entitesIds : [],
      entitesMapIds: item?.entitesMapIds ? item.entitesMapIds : null,
      accompagnementDuration: item?.accompagnementDuration
        ? item.accompagnementDuration
        : 0,
    };

    return small;
  }
}
