import { ItemEventId } from '../../../features/suivi/components/item-event/itemEvent.model';
import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Entite, EntiteId, EntiteSMALLid } from './entite.model';
import * as EntiteActions from './entite.actions';
import { OperationSmallId } from '../../operation/store/operation.model';
import { ContactSMALLid } from '../../contact/store/contact.model';

export const entitesFeatureKey = 'entites';

export interface EntiteState extends EntityState<EntiteSMALLid> {
  entite: EntiteId | any;
  entitesSearch: EntiteSMALLid[] | any;
  entitesOperation: EntiteSMALLid[] | any;

  statistiques: any;
  filters: any;
  sirets: any;
  suivis: ItemEventId[] | any;
  suivi: ItemEventId | any;
  documents: any;
  document: any;
  operations: OperationSmallId[] | any;
  operation: OperationSmallId | any;
  contacts: ContactSMALLid[] | any;
  contact: ContactSMALLid | any;
  loading: boolean;
  error: any;
}

export const adapter: EntityAdapter<EntiteSMALLid> =
  createEntityAdapter<EntiteSMALLid>();

export const initialState: EntiteState = adapter.getInitialState({
  entite: undefined,
  entitesSearch: undefined,
  entitesOperation: undefined,

  statistiques: undefined,
  filters: undefined,
  sirets: undefined,
  suivis: undefined,
  suivi: undefined,
  documents: undefined,
  document: undefined,
  operations: undefined,
  operation: undefined,
  contacts: undefined,
  contact: undefined,
  loading: false,
  error: undefined,
});

export const reducer = createReducer(
  initialState,
  on(EntiteActions.loading, (state, action) => {
    return {
      ...state,
      loading: action.loading,
    };
  }),

  on(EntiteActions.loadEntites, (state, action) => {
    return {
      ...state,
      entite: undefined,
      suivis: undefined,
      suivi: undefined,
      documents: undefined,
      document: undefined,
      operations: undefined,
      operation: undefined,

      statistiques: undefined,
      error: undefined,
    };
  }),

  on(EntiteActions.loadEntitesSuccess, (state, action) =>
    adapter.setAll(action.entites, state)
  ),
  on(EntiteActions.loadEntites, (state, action) => {
    return {
      ...state,
      entite: undefined,
      statistiques: undefined,
    };
  }),
  on(EntiteActions.loadEntitesFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  on(EntiteActions.loadEntiteSuccess, (state, action) => {
    return {
      ...state,
      entite: action.entite,
    };
  }),
  on(EntiteActions.loadEntiteFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //STATISTIQUES
  on(EntiteActions.loadEntiteStatistiquesSuccess, (state, action) => {
    return {
      ...state,
      statistiques: action.statistiques,
    };
  }),
  on(EntiteActions.loadEntiteStatistiquesFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  on(EntiteActions.queryEntitesSuccess, (state, action) =>
    adapter.setAll(action.entites, state)
  ),
  on(EntiteActions.queryEntitesFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  on(EntiteActions.loadEntiteFiltersSuccess, (state, action) => {
    return {
      ...state,
      filters: action.filters,
    };
  }),
  on(EntiteActions.loadEntiteFiltersFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  on(EntiteActions.loadEntiteSiretsSuccess, (state, action) => {
    return {
      ...state,
      sirets: action.sirets,
    };
  }),
  on(EntiteActions.loadEntiteSiretsFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //ADD
  on(EntiteActions.addEntite, (state, action) => {
    return {
      ...state,
      error: undefined,
      loading: true,
    };
  }),
  on(EntiteActions.addEntiteSuccess, (state, action) => {
    return {
      ...state,
      error: undefined,
      loading: false,
    };
  }),
  on(EntiteActions.addEntiteFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
      loading: false,
    };
  }),

  //UPDATE
  on(EntiteActions.updateEntite, (state, action) => {
    return {
      ...state,
      loading: true,
      error: undefined,
    };
  }),
  on(EntiteActions.updateEntiteSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      error: undefined,
    };
  }),
  on(EntiteActions.updateEntiteFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  }),

  //DELETE
  on(EntiteActions.deleteEntite, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(EntiteActions.deleteEntiteSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      error: undefined,
    };
  }),
  on(EntiteActions.deleteEntiteFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  }),
  //DELETE ALL

  on(EntiteActions.deleteEntites, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),

  //CLEAR
  on(EntiteActions.clearEntite, (state, action) => {
    return {
      ...state,
      entite: undefined,
    };
  }),

  on(EntiteActions.clearEntites, (state) => adapter.removeAll(state)),

  //DOCUMENTS
  on(EntiteActions.loadDocumentsSuccess, (state, action) => {
    return {
      ...state,
      documents: action.documents,
    };
  }),
  on(EntiteActions.loadDocumentsFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  on(EntiteActions.addDocumentsSuccess, (state, action) => {
    return {
      ...state,
      documents: action.documents,
    };
  }),
  // on(EntiteActions.addDocumentsFailure, (state, action) => {
  //   return {
  //     ...state,
  //     error: action.error,
  //   };
  // }),
  on(EntiteActions.deleteDocument, (state, action) => {
    return {
      ...state,
      documents: state.documents.filter(
        (doc: any) => doc.id !== action.document.id
      ),
    };
  }),
  on(EntiteActions.deleteDocumentFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //CONTACTS
  on(EntiteActions.loadEntiteContactsSuccess, (state, action) => {
    return {
      ...state,
      contacts: action.contacts,
    };
  }),
  on(EntiteActions.loadEntiteContactsFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //OPERATIONS
  on(EntiteActions.loadOperationsSuccess, (state, action) => {
    return {
      ...state,
      operations: action.operations,
    };
  }),
  on(EntiteActions.loadOperationsFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  on(EntiteActions.addOperationFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  on(EntiteActions.deleteOperation, (state, action) => {
    return {
      ...state,
      operations: state.operations.filter(
        (o: OperationSmallId) => o.id !== action.operation.id
      ),
    };
  }),
  on(EntiteActions.addOperationFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  on(EntiteActions.updateOperationFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  on(EntiteActions.deleteOperationFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //ENTITES BY OPERATION
  on(EntiteActions.loadEntitesByOpeationSuccess, (state, action) => {
    return {
      ...state,
      entitesOperation: action.entites,
    };
  }),
  on(EntiteActions.loadEntitesByOpeationFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
