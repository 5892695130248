<!-- <app-filter-list
    [tplLeft]="listHeaderFilterTitle"
    [placeholder]="titleHeaderPlaceholder"
    [isDropdownDeleteTitle]="titleHeaderDrowndownDeleteAll"
    [isDropdownDelete]="false"
    [isDropdownUpdate]="false"
    (currentFilter)="onFilerSearch($event)"
    (actionSelected)="onFilerAction($event)"
  ></app-filter-list> -->

<app-layout-list-header
  [placeholder]="titleHeaderPlaceholder"
  [deleteAllType]="titleHeaderDrowndownDeleteAll"
  [isDeleteAll]="true"
  (currentFilter)="onFilerSearch($event)"
  (actionSelected)="onFilerAction($event)"
  *ngIf="fiches$ | async | size"
></app-layout-list-header>
<app-layout-list
  *ngIf="fiches$ | async | size; else noResults"
  [new]="new"
  [card]="card"
  [source]="fiches$ | async"
></app-layout-list>

<ng-template #new>
  <app-card-new
    [type]="'ficheDePoste'"
    [height]="'200px'"
    [width]="'250px'"
    [title]="titleClauses"
    [description]="''"
    [definition]="newItemDefinition"
    (add)="onNew(operationItem, isOperation)"
  ></app-card-new>
</ng-template>

<ng-template #card let-item let-position="idx">
  <app-layout-list-card
    [title]="ficheDePosteCardCoverTileTpl"
    [subtitle]="ficheDePosteCardCoverSubtitleTpl"
    [body]="ficheDePosteCardCoverTpl"
    [extra]="ficheCardExtra"
    [spaceSize]="500"
    (click)="$event.stopPropagation(); onSelect(item)"
  ></app-layout-list-card>

  <ng-template #ficheDePosteCardCoverTileTpl>
    <p class="text-sm font-bold">
      {{ item?.mission?.metier?.libelleCourt | carLimitation : 20 }}
    </p>
  </ng-template>
  <ng-template #ficheDePosteCardCoverSubtitleTpl>
    <p class="text-sm tracking-wider">
      {{
        item?.entite?.uniteLegale?.denominationUniteLegale | carLimitation : 20
      }}
    </p>
  </ng-template>

  <ng-template #ficheDePosteCardCoverTpl>
    <div class="w-full grid grid-cols-1 space-y-6 mt-4">
      <div class="w-full flex flex-col">
        <nz-tag>
          <i nz-icon nzType="file-done"></i>
          {{ item?.mission?.contrat?.type?.libelle | carLimitation : 20 }}
        </nz-tag>
      </div>
      <div class="w-full flex items-center justify-start mt-2">
        <div class="w-12">
          <app-avatar-group
            [source]="[item?.auteur]"
            [size]="24"
          ></app-avatar-group>
        </div>
        <div class="w-auto">
          <nz-tag [nzColor]="item?.isClosed ? 'success' : 'blue'">
            <i nz-icon nzType="field-time"></i>
            {{ item?.isClosed ? "Clôturé" : "En cours" }}
          </nz-tag>

          <!-- <p class="text-sm">
              {{ item?.auteur?.displayName | carLimitation : 25 }}
            </p> -->
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #ficheCardExtra>
    <app-button-dropdown
      [isCopy]="true"
      [isDelete]="true"
      [deleteType]="deleteType"
      (select)="onCardDropdown($event, item)"
      (click)="$event.stopPropagation()"
    ></app-button-dropdown>
  </ng-template>
</ng-template>

<ng-template #noResults>
  <app-no-result
    [icon]="'ballot'"
    [addtitle]="newAddItemTitle"
    [notitle]="noDataTitle"
    [description]="newItemDefinition"
    [isButton]="true"
    (new)="onNew(operationItem, isOperation)"
  ></app-no-result>
</ng-template>

<ng-template #listHeaderFilterTitle>
  <app-header-title
    [icon]="'ballot'"
    [title]="titleHeader"
    [subtitle]="true"
  ></app-header-title>
</ng-template>

<ng-template #loadingSkelton>
  <app-layout-list-skelton></app-layout-list-skelton>
</ng-template>

<ng-template #ficheDePosteFormTitle>
  <app-header-title
    [icon]="'ballot'"
    [title]="title"
    [subtitle]="true"
  ></app-header-title>
</ng-template>

<ng-template #ficheDePosteLoadingTpl>
  <i nz-icon nzType="loading"></i>
</ng-template>
