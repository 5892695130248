import { Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  doc,
  docSnapshots,
  collectionChanges,
  getDoc,
  addDoc,
  setDoc,
  deleteDoc,
  serverTimestamp,
  DocumentData,
  Timestamp,
  query,
  where,
  arrayUnion,
  arrayRemove,
  collectionSnapshots,
  CollectionReference,
  DocumentReference,
  getDocs,
  documentId,
} from '@angular/fire/firestore';
import { Observable, of, from, EMPTY } from 'rxjs';
import { switchMap, mergeMap, map, catchError } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { UserState } from '../components/user/store/user.reducer';
import * as fromUserSelector from '../components/user/store/user.selectors';
import { v4 as uuidV4 } from 'uuid';
import { format, fromUnixTime } from 'date-fns';
import { Apollo, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'any',
})
export class ParticipantProjetproFormationService {
  GET_FORMATIONS_SUGGESTIONS_DETAILS = gql`
    query FormationOnisep($libelle: String!) {
      formationOnisep(libelle: $libelle) {
        acces {
          jury
        }
        partenaires {
          nomPartenaire
          siret
          habilitation
        }
        blocsCompetences {
          blocCompetencesCode
          blocCompetencesLibelle
        }
        ccn {
          ccnNumero
          ccnLibelle
        }
        nsf {
          nsfNumero
          nsfLibelle
        }
      }
    }
  `;

  GET_FORMATIONS_PROFESSIONNELLES = gql`
    query FormationsProByRome(
      $rome: String!
      $latitude: String!
      $longitude: String!
      $radius: String!
      $diploma: String
    ) {
      formationsProByRome(
        rome: $rome
        latitude: $latitude
        longitude: $longitude
        radius: $radius
        diploma: $diploma
      ) {
        title
        diplomaLevel
        rncpCode
        rncpLabel
        period
        capacity
        onisepUrl
        contact {
          name
          phone
          email
          info
        }
        company {
          name
          headquarter {
            place {
              address
              zipCode
              city
            }
          }
        }
        place {
          fullAddress
          latitude
          longitude
          city
          region
        }
        diploma
        cfd
        longTitle
        rncpEligibleApprentissage
        training
      }
    }
  `;

  GET_FORMATIONS_SUGGESTIONS = gql`
    query FormationsProJobs(
      $rome: String!
      $latitude: String!
      $longitude: String!
      $radius: String!
      $insee: String!
      $source: String
    ) {
      formationsProJobs(
        rome: $rome
        latitude: $latitude
        longitude: $longitude
        radius: $radius
        insee: $insee
        source: $source
      ) {
        lbaCompanies {
          title
          contact {
            name
            phone
          }
          job {
            elligibleHandicap
            romeDetails {
              libelle
              definition
              condition
              acces
              accesArray
              qualification
              riasecMajeur
              riasecMineur
              codeIsco
              particulier
              domaineProfessionnel {
                code
                libelle
                grandDomaine {
                  libelle
                  code
                }
              }
              appellations {
                code
                libelle
                libelleCourt
                particulier
              }
              competencesDeBase {
                libelle
                competenceCle
                typeCompetence
                riasecMineur
                riasecMajeur
              }
              themes {
                code
                libelle
              }
              environnementsTravail {
                libelle
                typeEnvironnementTravail
                code
              }
              mobilitesEvolutionsVersMetiers {
                metierCible {
                  libelle
                  code
                }
              }
              mobilitesEvolutionsVersAppellations {
                appellationCible {
                  code
                  libelle
                  libelleCourt
                  metier {
                    code
                    libelle
                  }
                }
              }
              groupesCompetencesSpecifiques {
                competences {
                  code
                  libelle
                  competenceCle
                  typeCompetence
                  riasecMineur
                  riasecMajeur
                }
              }
              mobilitesProchesVersMetiers {
                metierCible {
                  code
                  libelle
                }
              }
              mobilitesEvolutionsAppellationsVersMetiers {
                appellationOrigine {
                  libelle
                  code
                  libelleCourt
                  metier {
                    libelle
                    code
                  }
                }
                metierCible {
                  libelle
                  code
                }
                metierOrigine {
                  libelle
                  code
                }
              }
              mobilitesEvolutionsAppellationsVersAppellations {
                metierOrigine {
                  libelle
                  code
                }
                metierCible {
                  libelle
                  code
                }
                appellationCible {
                  metier {
                    libelle
                    code
                  }
                  libelleCourt
                  libelle
                  code
                }
                appellationOrigine {
                  code
                  libelle
                  libelleCourt
                  metier {
                    code
                    libelle
                  }
                }
              }
            }
            rythmeAlternance
            jobStartDate
            contractType
            dureeContrat
            quantiteContrat
            description
            duration
            contractDescription
          }
          company {
            name
            headquarter {
              place {
                address
                city
              }
            }
          }
          period
          place {
            fullAddress
            latitude
            distance
            longitude
            city
            address
            cedex
            zipCode
            departementNumber
            region
            insee
          }
          diplomaLevel
          longTitle
          rncpCode
          rncpLabel
          rncpEligibleApprentissage
          onisepUrl
          training
          nafs {
            label
            code
          }
          diploma
          url
          romes {
            label
            code
          }
          capacity
          cfd
          id
          ideaType
        }
        lbbCompanies {
          title
          ideaType
          longTitle
          id
          contact {
            info
            email
            phone
            name
          }
          place {
            distance
            fullAddress
            latitude
            longitude
            city
            address
            cedex
            zipCode
            departementNumber
            region
            insee
          }
          company {
            name
            siret
            id
            uai
            headquarter {
              hasConvention
              place {
                city
                zipCode
                cedex
                address
              }
              type
              siret
              uai
              id
            }
            place {
              city
            }
          }
          diplomaLevel
          diploma
          cfd
          rncpCode
          rncpLabel
          rncpEligibleApprentissage
          period
          capacity
          onisepUrl
          url
          romes {
            label
            code
          }
          nafs {
            label
          }
          job {
            contractType
            jobStartDate
            rythmeAlternance
            dureeContrat
            quantiteContrat
            contractDescription
            elligibleHandicap
            duration
            description
            romeDetails {
              libelle
              code
              definition
              condition
              qualification
              acces
              accesArray
              riasecMajeur
              riasecMineur
              codeIsco
              particulier
              domaineProfessionnel {
                code
                libelle
                grandDomaine {
                  libelle
                  code
                }
              }
              appellations {
                code
                libelle
                libelleCourt
                particulier
              }
              competencesDeBase {
                code
                libelle
                competenceCle
                noeudCompetence {
                  racineCompetence {
                    libelle
                    code
                  }
                  libelle
                  code
                }
                typeCompetence
                riasecMineur
                riasecMajeur
              }
              groupesCompetencesSpecifiques {
                competences {
                  code
                  libelle
                  competenceCle
                  noeudCompetence {
                    libelle
                    code
                    racineCompetence {
                      libelle
                      code
                    }
                  }
                  typeCompetence
                  riasecMineur
                  riasecMajeur
                }
              }
              themes {
                code
                libelle
              }
              environnementsTravail {
                code
                libelle
                typeEnvironnementTravail
              }
              mobilitesProchesVersMetiers {
                metierCible {
                  code
                  libelle
                }
              }
              mobilitesEvolutionsVersMetiers {
                metierCible {
                  code
                  libelle
                }
              }
              mobilitesEvolutionsVersAppellations {
                appellationCible {
                  code
                  libelle
                  libelleCourt
                  metier {
                    libelle
                    code
                  }
                }
              }
              mobilitesEvolutionsAppellationsVersMetiers {
                appellationOrigine {
                  code
                  libelle
                  libelleCourt
                  metier {
                    libelle
                    code
                  }
                }
                metierCible {
                  code
                  libelle
                }
                metierOrigine {
                  code
                  libelle
                }
              }
              mobilitesEvolutionsAppellationsVersAppellations {
                appellationOrigine {
                  code
                  libelle
                  libelleCourt
                  metier {
                    libelle
                    code
                  }
                }
                appellationCible {
                  code
                  libelle
                  libelleCourt
                  metier {
                    code
                    libelle
                  }
                }
                metierCible {
                  code
                  libelle
                }
                metierOrigine {
                  code
                  libelle
                }
              }
            }
            id
          }
          training
        }
        matchas {
          title
          contact {
            name
            phone
            email
            info
          }
          company {
            name
            headquarter {
              place {
                address
              }
            }
          }
          job {
            elligibleHandicap
            duration
            dureeContrat
            contractType
            quantiteContrat
            rythmeAlternance
            romeDetails {
              libelle
              condition
              qualification
              acces
              accesArray
              definition
              riasecMajeur
              riasecMineur
              codeIsco
              particulier
              appellations {
                particulier
                libelleCourt
                libelle
                code
              }
              domaineProfessionnel {
                code
                libelle
                grandDomaine {
                  libelle
                  code
                }
              }
              competencesDeBase {
                code
                libelle
                competenceCle
                typeCompetence
                riasecMineur
                riasecMajeur
              }
              themes {
                libelle
                code
              }
              environnementsTravail {
                libelle
                code
                typeEnvironnementTravail
              }
              mobilitesProchesVersMetiers {
                metierCible {
                  libelle
                  code
                }
              }
              mobilitesEvolutionsVersMetiers {
                metierCible {
                  libelle
                  code
                }
              }
              mobilitesEvolutionsVersAppellations {
                appellationCible {
                  code
                  libelle
                  libelleCourt
                  metier {
                    libelle
                    code
                  }
                }
              }
              mobilitesEvolutionsAppellationsVersMetiers {
                appellationOrigine {
                  code
                  libelle
                  libelleCourt
                  metier {
                    libelle
                    code
                  }
                }
                metierCible {
                  code
                  libelle
                }
                metierOrigine {
                  libelle
                  code
                }
              }
              mobilitesEvolutionsAppellationsVersAppellations {
                appellationOrigine {
                  code
                  libelle
                  libelleCourt
                  metier {
                    code
                    libelle
                  }
                }
                appellationCible {
                  libelleCourt
                  code
                  libelle
                  metier {
                    libelle
                    code
                  }
                }
                metierCible {
                  libelle
                  code
                }
                metierOrigine {
                  libelle
                  code
                }
              }
              groupesCompetencesSpecifiques {
                competences {
                  libelle
                  code
                  competenceCle
                  noeudCompetence {
                    racineCompetence {
                      code
                      libelle
                    }
                    libelle
                    code
                  }
                  typeCompetence
                  riasecMineur
                  riasecMajeur
                }
              }
              code
            }
            jobStartDate
            description
            contractDescription
          }
          longTitle
          diplomaLevel
          diploma
          rncpCode
          cfd
          rncpLabel
          rncpEligibleApprentissage
          period
          capacity
          onisepUrl
          url
          nafs {
            label
            code
          }
          training
          romes {
            label
            code
          }
          id
          place {
            distance
            fullAddress
            latitude
            longitude
            city
            address
            cedex
            zipCode
            departementNumber
            region
            insee
          }
          ideaType
        }
        peJobs {
          title
          ideaType
          longTitle
          contact {
            info
            email
            phone
            name
          }
          place {
            distance
            fullAddress
            latitude
            longitude
            city
            address
            cedex
            zipCode
            departementNumber
            region
            insee
          }
          cfd
          diploma
          diplomaLevel
          rncpCode
          rncpLabel
          rncpEligibleApprentissage
          period
          capacity
          onisepUrl
          url
          romes {
            label
            code
          }
          nafs {
            label
            code
          }
          training
          job {
            id
            contractType
            jobStartDate
            romeDetails {
              code
              libelle
              definition
              condition
              qualification
              acces
              accesArray
              riasecMajeur
              riasecMineur
              codeIsco
              particulier
              domaineProfessionnel {
                libelle
                code
                grandDomaine {
                  libelle
                  code
                }
              }
              appellations {
                particulier
                libelleCourt
                libelle
                code
              }
              competencesDeBase {
                code
                libelle
                competenceCle
                typeCompetence
                riasecMineur
                riasecMajeur
                noeudCompetence {
                  racineCompetence {
                    libelle
                    code
                  }
                  libelle
                  code
                }
              }
              groupesCompetencesSpecifiques {
                competences {
                  code
                  libelle
                  competenceCle
                  typeCompetence
                  riasecMineur
                  riasecMajeur
                  noeudCompetence {
                    racineCompetence {
                      libelle
                      code
                    }
                    libelle
                    code
                  }
                }
              }
              themes {
                code
                libelle
              }
              environnementsTravail {
                libelle
                code
                typeEnvironnementTravail
              }
              mobilitesProchesVersMetiers {
                metierCible {
                  code
                  libelle
                }
              }
              mobilitesEvolutionsVersMetiers {
                metierCible {
                  libelle
                  code
                }
              }
              mobilitesEvolutionsVersAppellations {
                appellationCible {
                  code
                  libelle
                  libelleCourt
                  metier {
                    libelle
                    code
                  }
                }
              }
              mobilitesEvolutionsAppellationsVersMetiers {
                appellationOrigine {
                  code
                  libelle
                  libelleCourt
                  metier {
                    code
                    libelle
                  }
                }
                metierCible {
                  code
                  libelle
                }
                metierOrigine {
                  code
                  libelle
                }
              }
              mobilitesEvolutionsAppellationsVersAppellations {
                appellationOrigine {
                  code
                  libelle
                  libelleCourt
                  metier {
                    code
                    libelle
                  }
                }
                appellationCible {
                  code
                  libelle
                  libelleCourt
                  metier {
                    libelle
                    code
                  }
                }
                metierCible {
                  libelle
                  code
                }
                metierOrigine {
                  code
                  libelle
                }
              }
            }
            rythmeAlternance
            dureeContrat
            quantiteContrat
            description
            contractDescription
            duration
            elligibleHandicap
          }
          company {
            name
            siret
            id
            uai
            headquarter {
              id
              uai
              siret
              type
              hasConvention
              place {
                address
                cedex
                zipCode
                city
              }
            }
            place {
              city
            }
          }
          id
        }
        peJobsSize
        matchasSize
        lbaCompaniesSize
        lbbCompaniesSize
      }
    }
  `;

  constructor(
    private db: Firestore,
    private fns: Functions,
    private userStore: Store<UserState>,
    private apollo: Apollo
  ) {}

  getFormationsProfessionnelles(
    rome: string,
    latitude: string,
    longitude: string,
    radius: string,
    diploma: string
  ): Observable<any> {
    if (!rome || !latitude || !longitude || !radius || !diploma) {
      return of(null);
    }

    return this.apollo
      .watchQuery<any>({
        query: this.GET_FORMATIONS_PROFESSIONNELLES,
        variables: {
          rome: rome,
          latitude: latitude,
          longitude: longitude,
          radius: radius,
          diploma: diploma,
        },
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  getFormationsSuggestions(
    rome: string,
    latitude: string,
    longitude: string,
    radius: string,
    insee: string,
    source: string
  ): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_FORMATIONS_SUGGESTIONS,
        variables: {
          rome: rome,
          latitude: latitude,
          longitude: longitude,
          radius: radius,
          insee: insee,
          source: source,
        },
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  getFormationDetail(libelle: string): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_FORMATIONS_SUGGESTIONS_DETAILS,
        variables: {
          libelle: libelle,
        },
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  getAll(participantId: string, projetpro: any): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !participantId || !projetpro) {
            return of([]);
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/participants/${participantId}/projetsprofessionnels/${projetpro.id}/formations`
            );

            const changes = collectionSnapshots(dbCollection).pipe(
              map((changes) => {
                return changes.map((snap) => {
                  const data = snap.data();
                  const id = snap.id;
                  return { id, ...data };
                });
              })
            );

            return changes;
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  addOne(
    participantId: string,
    projetpro: any,
    formation: any
  ): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !projetpro || !formation) {
            return of(null);
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/participants/${participantId}/projetsprofessionnels/${projetpro.id}/formations`
            );

            const document = {
              ...formation,
              user: user,
              userUpdate: user,
              dateUpdate: serverTimestamp(),
            };

            return from(
              addDoc(dbCollection, document)
                .then((docRef) => {
                  if (docRef.id) {
                    return of(
                      `Formation ${formation?.ci_intitule_type_diplome} ajoutée au projet professionnel ${projetpro?.appellation?.libelle} ajouté`
                    );
                  } else {
                    return of(null);
                  }
                })
                .catch((error) => of(error))
            ).pipe(mergeMap((res) => res));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  updateOne(
    participantId: string,
    projetpro: any,
    formation: Partial<any>
  ): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !projetpro || !formation) {
            return of(null);
          } else {
            const dbDocument = doc(
              this.db,
              `organisations/${user.organisation.id}/participants/${participantId}/projetsprofessionnels/${projetpro.id}/formations/${formation.id}`
            );

            return from(
              setDoc(dbDocument, formation, { merge: true })
                .then(() =>
                  of(
                    `Formation ${formation?.ci_intitule_type_diplome} modifiée`
                  )
                )
                .catch((error) => of(error))
            ).pipe(mergeMap((res) => res));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  deleteOne(
    participantId: string,
    projetpro: any,
    id: string
  ): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !projetpro || !id) {
            return of(null);
          } else {
            const dbDocument = doc(
              this.db,
              `organisations/${user.organisation.id}/participants/${participantId}/projetsprofessionnels/${projetpro.id}/formations/${id}`
            );

            return from(
              deleteDoc(dbDocument)
                .then(() => of(`Formation supprimés`))
                .catch((error) => of(error))
            ).pipe(mergeMap((res) => res));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  deleteAll(participantId: string, ids: string[]): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) {
            return of(null);
          } else {
            //CALL HTTP

            return of(null);
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }
}
