<form nz-form [formGroup]="poleEmploiForm" nzLayout="vertical">
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="12">
      <nz-form-item>
        <nz-form-label [nzSpan]="24" nzFor="inscriptionPoleEmploi"
          >Identifiant
        </nz-form-label>
        <nz-form-control>
          <input
            nz-input
            formControlName="identifiant"
            placeholder="Identifiant Pôle Emploi"
            nzBorderless
          />
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col nzSpan="12">
      <nz-form-item>
        <nz-form-label [nzSpan]="24" nzFor="inscriptionPoleEmploi"
          >Période d'inscription
        </nz-form-label>
        <ng-container>
          <nz-form-control>
            <nz-select
              formControlName="inscription"
              placeholder="Inscription au Pôle Emploi"
              nzBorderless
              nzPlaceHolder="Inscription au Pôle Emploi"
            >
              <nz-option
                *ngFor="let item of inscription_period$ | async"
                [nzLabel]="item.label | titlecase"
                [nzValue]="item"
              >
              </nz-option>
            </nz-select>
          </nz-form-control>
        </ng-container>
      </nz-form-item>
    </nz-col>
  </nz-row>
</form>
