<ng-container>
  <app-item-operations
    *ngIf="contact$ | async as contact"
    [operations$]="operations$"
    [identity]="identity"
    [role]="true"
    (add)="onAdd($event, contact)"
    (delete)="onDelete($event, contact)"
  ></app-item-operations>
</ng-container>
