import { EntiteId } from './../../store/entite.model';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-entite-actions',
  templateUrl: './entite-actions.component.html',
  styleUrls: ['./entite-actions.component.scss'],
})
export class EntiteActionsComponent implements OnInit {
  @Input() entite: EntiteId | any;
  @Input() statistiques: any;

  @Output() select = new EventEmitter<string>(false);

  constructor() {}

  ngOnInit(): void {}

  onSelect(item: string): void {
    this.select.emit(item);
  }
}
