import { OperationState } from 'src/app/components/operation/store/operation.reducer';
import * as fromOperationSelector from 'src/app/components/operation/store/operation.selectors';

import { ModuleService } from './module.service';
import { Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  doc,
  docSnapshots,
  collectionChanges,
  getDoc,
  serverTimestamp,
  DocumentData,
  Timestamp,
  query,
  where,
  collectionSnapshots,
  getDocs,
  documentId,
} from '@angular/fire/firestore';
import { Observable, of, from } from 'rxjs';
import { switchMap, mergeMap, map, catchError } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { UserState } from '../components/user/store/user.reducer';
import * as fromUserSelector from '../components/user/store/user.selectors';
import { ModuleSmallId } from '../components/module/store/module.model';
import { Apollo, gql } from 'apollo-angular';
@Injectable({
  providedIn: 'any',
})
export class QueryModulesService {
  GET_UTILITIES = gql`
    query ModuleType {
      moduleType {
        types
      }
    }
  `;

  constructor(
    private moduleService: ModuleService,
    private db: Firestore,
    private userStore: Store<UserState>,
    private operationStore: Store<OperationState>,
    private apollo: Apollo
  ) {}

  queryModules(filter: {
    operationId: string;
    event: string;
    type: string;
  }): Observable<ModuleSmallId[] | any> {
    try {
      return this.userStore.pipe(
        select(fromUserSelector.user),
        switchMap((user: any): any => {
          if (user) {
            switch (filter.type) {
              case 'title':
                return this.queryTitle(filter.operationId, filter.event);
                break;
              default:
                return this.moduleService.getAll(filter.operationId);
                break;
            }
          } else {
            return of(null);
          }
        })
      );
    } catch (error) {
      return of(null);
    }
  }

  queryByOperation(): Observable<ModuleSmallId[] | any> {
    try {
      return this.operationStore.select(fromOperationSelector.operation).pipe(
        switchMap((operation) => {
          if (operation) {
            const dbCollection = collection(
              this.db,
              `organisations/${operation.organisation.id}/operations/${operation.id}/modules`
            );
            const dbQuery = query(dbCollection, where('isFinish', '==', false));

            return from(
              getDocs(dbCollection)
                .then((snap) => {
                  return snap.docs.map((doc: DocumentData) => {
                    const data = doc.data() as any;
                    const id = doc.id;
                    return { id, ...data };
                  });
                })
                .catch((error) => error)
            ).pipe(mergeMap((res) => of(res)));
          } else {
            return of(null);
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  queryUtilities(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_UTILITIES,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  queryTitle(
    operationId: string,
    value: string
  ): Observable<ModuleSmallId[] | any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (user) {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/operations/${operationId}/modules`
            );
            const dbQuery = query(
              dbCollection,
              where(
                'metadata.arrayTitle',
                'array-contains',
                value.toUpperCase()
              )
            );

            return from(
              getDocs(dbQuery)
                .then((snap) => {
                  return snap.docs.map((doc: DocumentData) => {
                    const data = doc.data() as any;
                    const id = doc.id;
                    return { id, ...data };
                  });
                })
                .catch((error) => error)
            ).pipe(mergeMap((res) => of(res)));
          } else {
            return of(null);
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }
}
