import { Update } from '@ngrx/entity';
import { OperationCadrageLivrableFormComponent } from './../operation-cadrage-livrable-form/operation-cadrage-livrable-form.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { OperationCadrage } from './../../store/operation-cadrage.model';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewContainerRef,
} from '@angular/core';
import { of, Observable, BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-operation-cadrage-livrable',
  templateUrl: './operation-cadrage-livrable.component.html',
  styleUrls: ['./operation-cadrage-livrable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationCadrageLivrableComponent implements OnInit {
  modalWidth: number = 650;
  editing$ = new BehaviorSubject<boolean>(false);
  editingListItem$ = new BehaviorSubject<any>(null);
  editingListItemShow$ = new BehaviorSubject<any>(null);

  @Input() cadrage: OperationCadrage | any = null;
  @Output() update: EventEmitter<Update<OperationCadrage>> = new EventEmitter(
    false
  );
  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {}

  onNewLivrable(cadrage: OperationCadrage, livrable?: any): void {
    const modal = this.modal.create({
      nzContent: OperationCadrageLivrableFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: livrable ? `Modifier ${livrable.title}` : `Nouveau livrable`,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });
    const instance = modal.getContentComponent();
    instance.updateItem = new BehaviorSubject<any>(livrable);
    instance.cancel.subscribe((event: any): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
    instance.add.subscribe((event: any): any => {
      if (!event) {
        return null;
      } else {
        this.onAdd(cadrage, event);
        modal.close();
      }
    });
    instance.update.subscribe((event: any): any => {
      if (!event) {
        return null;
      } else {
        this.onUpdate(cadrage, event);
        modal.close();
      }
    });
  }

  onAdd(cadrage: OperationCadrage, livrable: any): void {
    const livrables = cadrage?.livrables?.length
      ? [...cadrage.livrables, livrable]
      : [livrable];

    const newCadrage: Update<OperationCadrage> = {
      id: cadrage.id,
      changes: {
        ...cadrage,
        livrables: livrables,
      },
    };

    this.update.emit(newCadrage);
  }

  onUpdate(cadrage: OperationCadrage, livrable: any): void {
    const livrables = cadrage?.livrables.filter(
      (element) => element.id !== livrable.id
    );

    const newCadrage: Update<OperationCadrage> = {
      id: cadrage.id,
      changes: {
        ...cadrage,
        livrables: [...livrables, livrable],
      },
    };

    this.update.emit(newCadrage);
  }

  onDeleteItem(item: any, cadrage: OperationCadrage): void {
    const livrables = cadrage.livrables.filter(
      (livrable) => livrable.id !== item.id
    );

    const newCadrage: Update<OperationCadrage> = {
      id: cadrage.id,
      changes: {
        ...cadrage,
        livrables: livrables,
      },
    };

    this.outputUpdate(newCadrage);
  }

  onDeleteAllList(cadrage: OperationCadrage): void {
    const newCadrage: Update<OperationCadrage> = {
      id: cadrage.id,
      changes: {
        ...cadrage,
        livrables: [],
      },
    };

    this.outputUpdate(newCadrage);
  }

  outputUpdate(cadrage: Update<OperationCadrage>): void {
    this.update.emit(cadrage);
    this.editingListItemShow$.next(null);
  }

  onCancel(): void {
    return;
  }
}
