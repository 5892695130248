import { ContentsModule } from './../../contents.module';
import { AidsEligibleService } from './../../../service/aids-eligible.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AidsEligiblesComponent } from './components/aids-eligibles/aids-eligibles.component';
import { AidsFormComponent } from './components/aids-form/aids-form.component';
import { StoreModule } from '@ngrx/store';
import * as fromAids from './store/aids.reducer';
import { EffectsModule } from '@ngrx/effects';
import { AidsEffects } from './store/aids.effects';
import { AidsFiltersComponent } from './components/aids-filters/aids-filters.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { AidsItemComponent } from './components/aids-item/aids-item.component';
import { ApolloModule } from 'apollo-angular';

@NgModule({
  declarations: [
    AidsEligiblesComponent,
    AidsFormComponent,
    AidsFiltersComponent,
    AidsItemComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ApolloModule,
    StoreModule.forFeature(fromAids.aidsesFeatureKey, fromAids.reducer),
    EffectsModule.forFeature([AidsEffects]),
  ],
  exports: [
    AidsEligiblesComponent,
    AidsFormComponent,
    AidsFiltersComponent,
    AidsItemComponent,
  ],
  providers: [AidsEligibleService],
})
export class AidsEligiblesModule {}
