import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'any',
})
export class QueryGeoTerritoireDepartementsServiceGQL extends Query<Response> {
  document = gql`
    query GeoRegion($code: String!) {
      geoRegion(code: $code) {
        code
        nom
        departements {
          nom
          code
        }
      }
    }
  `;
}
