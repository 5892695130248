<nz-card [nzTitle]="diagnosticItemPresentationTitlte">
  <p *ngIf="presentation; else noPresnation" nz-typography>
    {{ presentation }}
  </p>

  <ng-template #noPresnation>
    <div class="noDataContainer">
      <app-no-result
        [icon]="'article'"
        [isButton]="false"
        [description]="noData"
      ></app-no-result>
    </div>
  </ng-template>
</nz-card>

<ng-template #diagnosticItemPresentationTitlte>
  <app-header-title [icon]="'article'"></app-header-title>
</ng-template>
