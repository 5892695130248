<ng-container *ngIf="user$ | async as user">
  <nz-row
    nzAlign="middle"
    nzGutter="4"
    nzJustify="start"
    *ngIf="message$ | async as message"
  >
    <nz-col nzSpan="23">
      <form nz-form [formGroup]="newMessageForm" nzLayout="horizontal">
        <nz-form-item>
          <nz-form-control>
            <textarea
              nz-input
              nzSize="small"
              placeholder="Message..."
              formControlName="text"
              nzBorderless
              [nzAutosize]="{ minRows: 1, maxRows: 2 }"
            ></textarea>
          </nz-form-control>
        </nz-form-item>
      </form>
    </nz-col>
    <nz-col nzSpan="1">
      <button
        nz-button
        nzShape="circle"
        nzType="text"
        [disabled]="!newMessageForm.valid"
        (click)="add__MESSAGE(user, message)"
      >
        <i nz-icon nzType="send" class="sendIcon__MESSAGE"></i>
      </button>
    </nz-col>
  </nz-row>
</ng-container>
