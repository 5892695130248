import { OperationNoteId } from 'src/app/components/operation-note/store/operation-note.model';
import { Component, Input, OnInit } from '@angular/core';
import { OperationId } from 'src/app/components/operation/store/operation.model';

@Component({
  selector: 'app-operation-note-export-header',
  templateUrl: './operation-note-export-header.component.html',
  styleUrls: ['./operation-note-export-header.component.scss'],
})
export class OperationNoteExportHeaderComponent implements OnInit {
  @Input() user: any;

  @Input() operation: OperationId | any;
  @Input() note: OperationNoteId | any;
  constructor() {}

  ngOnInit(): void {}
}
