import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { isNull, isUndefined } from 'lodash';

@Component({
  selector: 'app-operation-dashboard-participants',
  templateUrl: './operation-dashboard-participants.component.html',
  styleUrls: ['./operation-dashboard-participants.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationDashboardParticipantsComponent
  implements OnInit, OnChanges, OnDestroy
{
  subscribe = new Subject();
  noExist = 'Non renseigné';

  //PARTICIPANTS
  participants$ = new BehaviorSubject<any>(null);
  genres$ = new BehaviorSubject<any>(null);
  tranche$ = new BehaviorSubject<any>(null);
  rqth$ = new BehaviorSubject<any>(null);
  commune$ = new BehaviorSubject<any>(null);
  codePostal$ = new BehaviorSubject<any>(null);
  nationality$ = new BehaviorSubject<any>(null);
  qualificationLibelle$ = new BehaviorSubject<any>(null);
  qualificationLevel$ = new BehaviorSubject<any>(null);
  matrimoniale$ = new BehaviorSubject<any>(null);
  francaisLevel$ = new BehaviorSubject<any>(null);
  minimaSociaux$ = new BehaviorSubject<any>(null);
  typeSejour$ = new BehaviorSubject<any>(null);
  typeSortie$ = new BehaviorSubject<any>(null);

  @Input() dashboard$: Observable<any> = of(null);
  constructor() {}

  ngOnInit(): void {
    this.setParticipantDashboard();
  }

  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  chartsValues = (statistiques: any) => {
    const values = statistiques
      ? Object.values(statistiques).map((item) => {
          if (isNull(item) || isUndefined(item) || !item) {
            return this.noExist;
          }
          return item;
        })
      : [];

    const labels = statistiques
      ? Object.keys(statistiques).map((item) => {
          if (isNull(item) || isUndefined(item) || !item) {
            return this.noExist;
          }
          return item;
        })
      : [];

    return { values, labels };
  };

  itemsCounter = (items: any[]) => {
    return items.reduce((acc, cur) => {
      const curSize = items.filter((el) => el === cur).length;

      acc[cur] = curSize;
      return acc;
    }, {});
  };

  chartsTypePie = (count: any): any => {
    const transformToArray: any[] = Object.entries(count);
    const transformForChart: any[] = transformToArray.map((el) => ({
      name: el[0],
      value: el[1],
    }));

    return transformForChart;
  };

  setParticipantDashboard(): void {
    this.dashboard$.pipe(takeUntil(this.subscribe)).subscribe((dashboard) => {
      if (!dashboard) return;

      const participants: any[] = dashboard?.participants?.data;

      if (!participants?.length) return;
      this.participants$.next(participants);
      //PARTICIPANTS CHARTS
      this.setParticipantsCharts(participants);
    });
  }

  setParticipantsCharts(participants: any[]): void {
    if (!participants?.length) return;

    this.setParticipantGenre(participants);
    this.setParticipantRQTH(participants);
    this.setParticipantCommune(participants);
    this.setParticipantCodePostal(participants);
    this.setParticipantTranche(participants);
    this.setParticipantQualification(participants);
    this.setParticipantMatrimoniale(participants);
    this.setParticipantMinima(participants);
    this.setParticipantSejour(participants);
    this.setParticipantFrenchLevel(participants);
  }

  setParticipantGenre(participants: any[]): void {
    const femininSize = participants.filter(
      (participant) => participant.sexe === 'Féminin'
    )?.length;
    const masculinSize = participants.filter(
      (participant) => participant.sexe === 'Masculin'
    )?.length;

    const data: any[] = [
      {
        name: 'Féminin',
        value: femininSize,
      },
      {
        name: 'Masculin',
        value: masculinSize,
      },
    ];

    const genre = {
      title: 'Genre',
      chartsTitle: '',
      type: 'donut',
      data: data,
      center: ['50%', '70%'],
      radius: 120,
      height: '400px',
    };

    this.genres$.next(genre);
  }
  setParticipantRQTH(participants: any[]): void {
    const rqthSize = participants.filter(
      (participant) => participant.rqth
    )?.length;
    const noRqthSize = participants.filter(
      (participant) => !participant.rqth
    )?.length;

    const data: any[] = [
      {
        name: 'RQTH',
        value: rqthSize,
      },
      {
        name: 'Non RQTH',
        value: noRqthSize,
      },
    ];

    const genre = {
      title: 'Reconnaissance de la qualité de travailleur handicapé',
      chartsTitle: '',
      type: 'donut',
      data: data,
      center: ['50%', '70%'],
      radius: 120,
      height: '400px',
    };

    this.rqth$.next(genre);
  }
  setParticipantQualification(participants: any[]): void {
    const qualification = participants.map(
      (participant) => participant.qualificationNiveau
    );

    const dataCounter: any = this.itemsCounter(qualification);
    const pieData: any[] = this.chartsTypePie(dataCounter);

    const genre = {
      title: 'Niveaux de qualification impactés',
      chartsTitle: '',
      type: 'donut',
      data: pieData,
      center: ['50%', '70%'],
      radius: 120,
      height: '400px',
    };

    this.qualificationLevel$.next(genre);
  }
  setParticipantMinima(participants: any[]): void {
    const minima = participants.map(
      (participant) => participant.minimaSociaux.value
    );

    const dataCounter: any = this.itemsCounter(minima);
    const pieData: any[] = this.chartsTypePie(dataCounter);

    const genre = {
      title: 'Minima sociaux',
      chartsTitle: '',
      type: 'donut',
      data: pieData,
      center: ['50%', '70%'],
      radius: 120,
      height: '400px',
    };

    this.minimaSociaux$.next(genre);
  }
  setParticipantFrenchLevel(participants: any[]): void {
    const items = participants.map((participant) => participant.francais);

    const dataCounter: any = this.itemsCounter(items);
    const pieData: any[] = this.chartsTypePie(dataCounter);

    const genre = {
      title: 'Niveau de français',
      chartsTitle: '',
      type: 'donut',
      data: pieData,
      center: ['50%', '70%'],
      radius: 120,
      height: '400px',
    };

    this.francaisLevel$.next(genre);
  }

  setParticipantSejour(participants: any[]): void {
    const sejour = participants.map((participant) => participant.typeSejour);

    const dataCounter: any = this.itemsCounter(sejour);
    const pieData: any[] = this.chartsTypePie(dataCounter);

    const genre = {
      title: 'Titre de séjour',
      chartsTitle: '',
      type: 'donut',
      data: pieData,
      center: ['50%', '70%'],
      radius: 120,
      height: '400px',
    };

    this.typeSejour$.next(genre);
  }

  setParticipantMatrimoniale(participants: any[]): void {
    const matrimoniale = participants.map(
      (participant) => participant.situationMatrimoniale
    );

    const dataCounter: any = this.itemsCounter(matrimoniale);
    const pieData: any[] = this.chartsTypePie(dataCounter);

    const genre = {
      title: 'Situations matrimoniales impactées',
      chartsTitle: '',
      type: 'donut',
      data: pieData,
      center: ['50%', '70%'],
      radius: 120,
      height: '400px',
    };

    this.qualificationLevel$.next(genre);
  }

  setParticipantTranche(participants: any[]): void {
    const tranches = participants.map((participant) => participant.tranche);

    const dataCounter: any = this.itemsCounter(tranches);
    const pieData: any[] = this.chartsTypePie(dataCounter);

    const genre = {
      title: "Tranches d'âge impactée",
      chartsTitle: '',
      type: 'donut',
      data: pieData,
      center: ['50%', '70%'],
      radius: 120,
      height: '400px',
    };

    this.tranche$.next(genre);
  }

  setParticipantCommune(participants: any[]): void {
    const communes: any[] = participants.map(
      (participant) => participant.commune
    );
    const counter = this.itemsCounter(communes);
    const chatsData = this.chartsValues(counter);

    const chartsOptions = {
      title: 'Communes impactées',
      chartsTitle: '',
      type: 'barVertical',
      data: counter,
      center: ['50%', '70%'],
      radius: 120,

      height: '400px',
      option: {
        saveImageName: 'participants_par_communes',
        xAxis: 'participants',
        yAxis: 'category',
        columns: ['communes', 'participants'],
        label: {
          show: true,
          position: 'inside',
          color: '#ffff',
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontSize: 20,
        },
        visualMap: {
          orient: 'horizontal',
          left: 'center',
          min: 10,
          max: 100,
          text: ['Plus de participants', 'Moins de participants'],
          dimension: 0,
          inRange: {
            color: ['#FD665F', '#FFCE34', '#65B581'],
          },
        },
        encode: {
          x: 'participants',
          y: 'communes',
        },
      },
    };
    this.commune$.next(chartsOptions);
  }
  setParticipantCodePostal(participants: any[]): void {
    const departements: any[] = participants.map(
      (participant) => participant.codePostal
    );
    const counter = this.itemsCounter(departements);
    const chatsData = this.chartsValues(counter);

    const chartsOptions = {
      title: 'Départements impactés',
      chartsTitle: '',
      type: 'barVertical',
      data: counter,
      center: ['50%', '70%'],
      radius: 120,

      height: '400px',
      option: {
        saveImageName: 'participants_par_departements',
        xAxis: 'participants',
        yAxis: 'category',
        columns: ['départements', 'participants'],
        label: {
          show: true,
          position: 'inside',
          color: '#ffff',
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontSize: 20,
        },
        visualMap: {
          orient: 'horizontal',
          left: 'center',
          min: 10,
          max: 100,
          text: ['Plus de participants', 'Moins de participants'],
          dimension: 0,
          inRange: {
            color: ['#FD665F', '#FFCE34', '#65B581'],
          },
        },
        encode: {
          x: 'participants',
          y: 'départements',
        },
      },
    };
    this.codePostal$.next(chartsOptions);
  }
}
