import {
  ParticipantId,
  ParticipantSmallId,
} from './../../store/participant.model';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { ParticipantState } from '../../store/participant.reducer';
import * as fromParticipantAction from '../../store/participant.actions';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';
import * as fromUserAction from 'src/app/components/user/store/user.actions';
import { ExportSelectComponent } from 'src/app/contents/components/export-select/export-select.component';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-participant-card',
  templateUrl: './participant-card.component.html',
  styleUrls: ['./participant-card.component.scss'],
})
export class ParticipantCardComponent implements OnInit {
  default: string = '#bfbfbf';
  avatarBackgroundColor: string = '#1890ff';
  titleDownload: string = '';
  modalExportWidth: number = 480;

  @ViewChild('exportTitleForm', { static: false }) exportTitleForm:
    | TemplateRef<any>
    | any;

  @Input() participant: ParticipantSmallId | any;
  @Input() favoris: any;
  @Input() isCoordonnate: boolean = true;
  @Input() isParticipantsPage: boolean = true;
  @Input() height: string = '350px';
  @Output() action: EventEmitter<any> = new EventEmitter<any>(false);

  constructor(
    private participantStore: Store<ParticipantState>,
    private userStore: Store<UserState>,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {}

  onAddToFavori(participant: ParticipantSmallId): void {
    if (!participant) return;
    const id: string = participant.id;
    const displayName: string = `${
      participant.civilite
    }. ${participant.lastName?.toUpperCase()} ${participant.firstName}`;
    this.userStore.dispatch(
      fromUserAction.addUserFavorisParticipant({ id, displayName })
    );
  }
  onRemoveToFavori(participant: ParticipantSmallId): void {
    if (!participant) return;
    const id: string = participant.id;
    const displayName: string = `${
      participant.civilite
    }. ${participant.lastName?.toUpperCase()} ${participant.firstName}`;
    this.userStore.dispatch(
      fromUserAction.removeUserFavorisParticipant({ id, displayName })
    );
  }

  onExportSelect(participant: any): void {
    if (!participant) return;
    const { civilite, lastName, firstName } = participant;
    const displayName = `${civilite}. ${lastName} ${firstName}`;
    this.titleDownload = `${displayName}`;

    const modal = this.modal.create({
      nzContent: ExportSelectComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalExportWidth,
      nzFooter: [],
      nzTitle: this.exportTitleForm,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
    });

    const instance = modal.getContentComponent();
    instance.isAnomyne = true;
    instance.isInclusSuivi = true;

    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
    instance.select.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        this.onExportValidation(event, participant);
        modal.close();
      }
    });
  }

  onExportValidation(event: any, participant: any): void {
    if (!participant) return;
    const { item, anonym, suivi } = event;
    this.participantStore.dispatch(
      fromParticipantAction.exportParticipant({
        participant,
        incluSuivi: suivi,
        anonym: anonym,
      })
    );
  }

  onDelete(participant: ParticipantSmallId): void {
    if (!participant) return;
    const id: string = participant.id;
    const displayName: string | any = participant?.displayName;

    this.participantStore.dispatch(
      fromParticipantAction.deleteParticipant({ id })
    );
  }

  onAction(type: string, participant: ParticipantSmallId): void {
    if (!type || !participant) return;
    this.action.emit({ type, participant });
  }

  onCancel(): void {
    return;
  }
}
