import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { ParticipantDiagnostic } from './participant-diagnostic.model';
import { ParticipantId } from '../../participant/store/participant.model';

//LOAD ALL
export const loadParticipantDiagnostics = createAction(
  '[ParticipantDiagnostic/API] Load ParticipantDiagnostics',
  props<{ id: string }>()
);
export const loadParticipantDiagnosticsSuccess = createAction(
  '[ParticipantDiagnostic/API] Load ParticipantDiagnostics Success',
  props<{ diagnostics: any[] }>()
);
export const loadParticipantDiagnosticsFailure = createAction(
  '[ParticipantDiagnostic/API] Load ParticipantDiagnostics Failure',
  props<{ error: any }>()
);

//LOAD ONE
export const loadParticipantDiagnostic = createAction(
  '[ParticipantDiagnostic/API] Load ParticipantDiagnostic',
  props<{ id: string }>()
);
export const loadParticipantDiagnosticSuccess = createAction(
  '[ParticipantDiagnostic/API] Load ParticipantDiagnostic Success',
  props<{ diagnostic: any }>()
);
export const loadParticipantDiagnosticFailure = createAction(
  '[ParticipantDiagnostic/API] Load ParticipantDiagnostic Failure',
  props<{ error: any }>()
);

//ADD ONE
export const addParticipantDiagnostic = createAction(
  '[ParticipantDiagnostic/API] Add ParticipantDiagnostic',
  props<{ id: string; diagnostic: any }>()
);
export const addParticipantDiagnosticSuccess = createAction(
  '[ParticipantDiagnostic/API] Add ParticipantDiagnostic Success',
  props<{ diagnostic: any }>()
);
export const addParticipantDiagnosticFailure = createAction(
  '[ParticipantDiagnostic/API] Add ParticipantDiagnostic Failure',
  props<{ error: any }>()
);

//UPDATE ONE
export const updateParticipantDiagnostic = createAction(
  '[ParticipantDiagnostic/API] Update ParticipantDiagnostic',
  props<{ id: string; diagnostic: Update<any> }>()
);
export const updateParticipantDiagnosticSuccess = createAction(
  '[ParticipantDiagnostic/API] Update ParticipantDiagnostic Success',
  props<{ diagnostic: any }>()
);
export const updateParticipantDiagnosticFailure = createAction(
  '[ParticipantDiagnostic/API] Update ParticipantDiagnostic Failure',
  props<{ error: any }>()
);

//DELETE ONE
export const deleteParticipantDiagnostic = createAction(
  '[ParticipantDiagnostic/API] Delete ParticipantDiagnostic',
  props<{ participantId: string; id: string }>()
);
export const deleteParticipantDiagnosticSuccess = createAction(
  '[ParticipantDiagnostic/API] Delete ParticipantDiagnostic Success',
  props<{ success: string }>()
);
export const deleteParticipantDiagnosticFailure = createAction(
  '[ParticipantDiagnostic/API] Delete ParticipantDiagnostic Failure',
  props<{ error: any }>()
);

//DELETE ALL
export const deleteParticipantDiagnostics = createAction(
  '[ParticipantDiagnostic/API] Delete ParticipantDiagnostics',
  props<{ id: string; ids: string[] }>()
);
export const deleteParticipantDiagnosticsSuccess = createAction(
  '[ParticipantDiagnostic/API] Delete ParticipantDiagnostics Success',
  props<{ success: string }>()
);
export const deleteParticipantDiagnosticsFailure = createAction(
  '[ParticipantDiagnostic/API] Delete ParticipantDiagnostics Failure',
  props<{ error: any }>()
);

//EXPORT PDF
export const exportParticipantDiagnosticPDF = createAction(
  '[ParticipantDiagnostic/API] Export ParticipantDiagnostic PDF',
  props<{ participant: ParticipantId | any; diagnostic: any }>()
);
export const exportParticipantDiagnosticPDFSuccess = createAction(
  '[ParticipantDiagnostic/API] Export ParticipantDiagnostic PDF Success',
  props<{ success: string }>()
);
export const exportParticipantDiagnosticPDFFailure = createAction(
  '[ParticipantDiagnostic/API] Export ParticipantDiagnostic PDF Failure',
  props<{ error: any }>()
);

//EXPORT WORD
export const exportParticipantDiagnosticWORD = createAction(
  '[ParticipantDiagnostic/API] Export ParticipantDiagnostic WORD',
  props<{ participant: ParticipantId | any; diagnostic: any }>()
);
export const exportParticipantDiagnosticWORDSuccess = createAction(
  '[ParticipantDiagnostic/API] Export ParticipantDiagnostic WORD Success',
  props<{ success: string }>()
);
export const exportParticipantDiagnosticWORDFailure = createAction(
  '[ParticipantDiagnostic/API] Export ParticipantDiagnostic WORD Failure',
  props<{ error: any }>()
);

//CLEAR
export const clearParticipantDiagnostics = createAction(
  '[ParticipantDiagnostic/API] Clear ParticipantDiagnostics'
);
