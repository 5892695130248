import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, of, lastValueFrom, from } from 'rxjs';
import { ParticipantDiagnosticState } from 'src/app/components/participant-diagnostic/store/participant-diagnostic.reducer';
import * as fromParticipantDiagnosticSelector from './../../../components/participant-diagnostic/store/participant-diagnostic.selectors';
import * as fromParticipantDiagnosticAction from './../../../components/participant-diagnostic/store/participant-diagnostic.actions';

@Injectable({
  providedIn: 'any',
})
export class ParticipantDiagnosticResolverService {
  constructor(
    private participantDiagnosticStore: Store<ParticipantDiagnosticState>,
    private router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    let id = route.parent?.params['id'];

    return from(
      lastValueFrom(this.loadParticipantDiagnostic(id))
        .then(() => {
          return this.getParticipantDiagnostic();
        })
        .catch(() => {
          return EMPTY;
        })
    );
  }

  getParticipantDiagnostic(): Observable<any> {
    return this.participantDiagnosticStore.select(
      fromParticipantDiagnosticSelector.diagnostic
    );
  }

  loadParticipantDiagnostic(id: string): Observable<any> {
    return of(
      this.participantDiagnosticStore.dispatch(
        fromParticipantDiagnosticAction.loadParticipantDiagnostic({
          id,
        })
      )
    );
  }
}
