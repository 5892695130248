import { ParticipantProjetproFormationService } from './../../service/participant-projetpro-formation.service';
import { ParticipantProjetproEntiteService } from './../../service/participant-projetpro-entite.service';
import { ParticipantProjetproContactService } from './../../service/participant-projetpro-contact.service';
import { SharedModule } from './../../shared/shared.module';
import { ContentsModule } from 'src/app/contents/contents.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjetProfessionnelFormComponent } from './components/projet-professionnel-form/projet-professionnel-form.component';
import { ProjetProfessionnelCardComponent } from './components/projet-professionnel-card/projet-professionnel-card.component';
import { ProjetProfessionnelItemComponent } from './components/projet-professionnel-item/projet-professionnel-item.component';
import { StoreModule } from '@ngrx/store';
import * as fromProjetProfessionnel from './store/projet-professionnel.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ProjetProfessionnelEffects } from './store/projet-professionnel.effects';
import { ProjetProfessionnelListComponent } from './components/projet-professionnel-list/projet-professionnel-list.component';
import { ParticipantProjetproService } from 'src/app/service/participant-projetpro.service';
import { ProjetProfessionnelTodoComponent } from './components/projet-professionnel-todo/projet-professionnel-todo.component';
import { ProjetProfessionnelInfoComponent } from './components/projet-professionnel-info/projet-professionnel-info.component';
import { ProjetProfessionnelMetierComponent } from './components/projet-professionnel-metier/projet-professionnel-metier.component';
import { ProjetProfessionnelStatistiquesComponent } from './components/projet-professionnel-statistiques/projet-professionnel-statistiques.component';
import { ProjetProfessionnelEntitesComponent } from './components/projet-professionnel-entites/projet-professionnel-entites.component';
import { ProjetProfessionnelContactsComponent } from './components/projet-professionnel-contacts/projet-professionnel-contacts.component';
import { ProjetProfessionnelFormationsComponent } from './components/projet-professionnel-formations/projet-professionnel-formations.component';
import { ProjetProfessionnelFormationsFormComponent } from './components/projet-professionnel-formations-form/projet-professionnel-formations-form.component';
import { ProjetProfessionnelFormationsItemComponent } from './components/projet-professionnel-formations-item/projet-professionnel-formations-item.component';
import { ProjetProfessionnelEntitesFormComponent } from './components/projet-professionnel-entites-form/projet-professionnel-entites-form.component';
import { ProjetProfessionnelEntitesItemComponent } from './components/projet-professionnel-entites-item/projet-professionnel-entites-item.component';
import { ProjetProfessionnelContactsFormComponent } from './components/projet-professionnel-contacts-form/projet-professionnel-contacts-form.component';
import { ProjetProfessionnelTodoFormComponent } from './components/projet-professionnel-todo-form/projet-professionnel-todo-form.component';
import { ProjetProfessionnelEvaluationFormComponent } from './components/projet-professionnel-evaluation-form/projet-professionnel-evaluation-form.component';
import { ProjetProfessionnelEvaluationComponent } from './components/projet-professionnel-evaluation/projet-professionnel-evaluation.component';
import { ParticipantProjetproTodoService } from 'src/app/service/participant-projetpro-todo.service';
import { ParticipantProjetproEvaluationService } from 'src/app/service/participant-projetpro-evaluation.service';
import { ParticipantProjetproExportPdfService } from 'src/app/service/participant-projetpro-export-pdf.service';
import { ProjetProfessionnelTodoItemComponent } from './components/projet-professionnel-todo-item/projet-professionnel-todo-item.component';
import { ProjetProfessionnelDiagnosticsComponent } from './components/projet-professionnel-diagnostics/projet-professionnel-diagnostics.component';
import { ProjetProfessionnelDiagnosticsFormComponent } from './components/projet-professionnel-diagnostics-form/projet-professionnel-diagnostics-form.component';
import { ProjetProfessionnelDiagnosticsItemComponent } from './components/projet-professionnel-diagnostics-item/projet-professionnel-diagnostics-item.component';
import { ProjetProfessionnelPreconisationComponent } from './components/projet-professionnel-preconisation/projet-professionnel-preconisation.component';
import { ProjetProfessionnelFormationsFormDetailComponent } from './components/projet-professionnel-formations-form-detail/projet-professionnel-formations-form-detail.component';
import { ProjetProfessionnelFormationsFormCardComponent } from './components/projet-professionnel-formations-form-card/projet-professionnel-formations-form-card.component';
import { ProjetProfessionnelJobsComponent } from './components/projet-professionnel-jobs/projet-professionnel-jobs.component';
import { ProjetProfessionnelJobsCardComponent } from './components/projet-professionnel-jobs-card/projet-professionnel-jobs-card.component';
import { NoResultModule } from 'src/app/contents/components/no-result/no-result.module';
import { CoordonneesModule } from 'src/app/contents/components/coordonnees/coordonnees.module';
import { CommunesFranceModule } from 'src/app/contents/components/communes-france/communes-france.module';
import { ButtonsModule } from 'src/app/contents/components/buttons/buttons.module';
import { MetiersModule } from 'src/app/features/metiers/metiers.module';
import { HeaderModule } from 'src/app/contents/components/header-title/header.module';
import { LayoutModule } from 'src/app/contents/components/layout/layout.module';
import { SuiviModule } from 'src/app/features/suivi/suivi.module';
import { UiModule } from 'src/app/ui/ui/ui.module';

@NgModule({
  declarations: [
    ProjetProfessionnelFormComponent,
    ProjetProfessionnelCardComponent,
    ProjetProfessionnelItemComponent,
    ProjetProfessionnelListComponent,
    ProjetProfessionnelTodoComponent,
    ProjetProfessionnelInfoComponent,
    ProjetProfessionnelMetierComponent,
    ProjetProfessionnelStatistiquesComponent,
    ProjetProfessionnelEntitesComponent,
    ProjetProfessionnelContactsComponent,
    ProjetProfessionnelFormationsComponent,
    ProjetProfessionnelFormationsFormComponent,
    ProjetProfessionnelFormationsItemComponent,
    ProjetProfessionnelEntitesFormComponent,
    ProjetProfessionnelEntitesItemComponent,
    ProjetProfessionnelContactsFormComponent,
    ProjetProfessionnelTodoFormComponent,
    ProjetProfessionnelEvaluationFormComponent,
    ProjetProfessionnelEvaluationComponent,
    ProjetProfessionnelTodoItemComponent,
    ProjetProfessionnelDiagnosticsComponent,
    ProjetProfessionnelDiagnosticsFormComponent,
    ProjetProfessionnelDiagnosticsItemComponent,
    ProjetProfessionnelPreconisationComponent,
    ProjetProfessionnelFormationsFormDetailComponent,
    ProjetProfessionnelFormationsFormCardComponent,
    ProjetProfessionnelJobsComponent,
    ProjetProfessionnelJobsCardComponent,
  ],
  imports: [
    CommonModule,
    ContentsModule,
    SharedModule,
    NoResultModule,
    CoordonneesModule,
    HeaderModule,
    CommunesFranceModule,
    ButtonsModule,
    MetiersModule,
    LayoutModule,
    SuiviModule,
    UiModule,
    StoreModule.forFeature(
      fromProjetProfessionnel.projetProfessionnelsFeatureKey,
      fromProjetProfessionnel.reducer
    ),
    EffectsModule.forFeature([ProjetProfessionnelEffects]),
  ],
  exports: [
    ProjetProfessionnelFormComponent,
    ProjetProfessionnelCardComponent,
    ProjetProfessionnelItemComponent,
    ProjetProfessionnelListComponent,
    ProjetProfessionnelStatistiquesComponent,
  ],
  providers: [
    ParticipantProjetproService,
    ParticipantProjetproContactService,
    ParticipantProjetproEntiteService,
    ParticipantProjetproFormationService,
    ParticipantProjetproTodoService,
    ParticipantProjetproEvaluationService,
    ParticipantProjetproExportPdfService,
  ],
})
export class ProjetsProfessionnelsModule {}
