import { Module } from './../components/module/store/module.model';
import { Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  doc,
  docSnapshots,
  collectionChanges,
  addDoc,
  setDoc,
  deleteDoc,
  serverTimestamp,
  CollectionReference,
  DocumentReference,
  DocumentChange,
  DocumentSnapshot,
  Timestamp,
  query,
  where,
  arrayUnion,
  arrayRemove,
  collectionSnapshots,
  getDocs,
  DocumentData,
} from '@angular/fire/firestore';
import { Observable, of, from, EMPTY } from 'rxjs';
import { switchMap, mergeMap, map, catchError } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { UserState } from '../components/user/store/user.reducer';
import * as fromUserSelector from '../components/user/store/user.selectors';
import {
  ModuleId,
  ModuleSmallId,
  ModuleSmall,
} from '../components/module/store/module.model';
import { format, fromUnixTime } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Apollo, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'any',
})
export class ModuleService {
  GET_MODULE_TYPES = gql`
    query ModuleType {
      moduleType
    }
  `;

  CREATE_MODULE = gql`
    mutation CreateModule($module: ModuleInput!) {
      createModule(module: $module)
    }
  `;
  CREATE_MODULES = gql`
    mutation CreateModules($modules: [ModuleInput]!) {
      createModules(modules: $modules)
    }
  `;
  // UPDATE_MODULE = gql``;
  DELETE_MODULE = gql`
    mutation DeleteModule($operationId: ID!, $moduleId: ID!) {
      deleteModule(operationId: $operationId, moduleId: $moduleId)
    }
  `;
  DELETE_MODULES = gql`
    mutation DeleteAllModules($id: ID!) {
      deleteAllModules(id: $id)
    }
  `;

  constructor(
    private db: Firestore,
    private userStore: Store<UserState>,
    private apollo: Apollo
  ) {}

  getAll(operationId: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !operationId) of([]);

          const dbCollection: CollectionReference<DocumentData> = collection(
            this.db,
            `organisations/${user.organisation.id}/operations/${operationId}/modules`
          );

          return collectionSnapshots(dbCollection).pipe(
            map((snap: DocumentSnapshot<DocumentData>[]) => {
              return snap.map((doc: DocumentData) => {
                const data = doc.data() as any;
                const id = doc.id;
                return { id, ...data };
              });
            }),
            catchError((error) => error)
          );
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  getOne(operationId: string, id: string): Observable<ModuleId | any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) EMPTY;
          const dbCollection: CollectionReference<DocumentData> = collection(
            this.db,
            `organisations/${user.organisation.id}/operations/${operationId}/modules`
          );

          const documentRef: DocumentReference<DocumentData> = doc(
            dbCollection,
            id
          );

          return docSnapshots(documentRef).pipe(
            map((snap: DocumentSnapshot<DocumentData>) => {
              const data = snap.data();
              const id = snap.id;
              return { id, ...data };
            }),
            catchError((error) => EMPTY)
          );
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  getOneDetails(operationId: string, id: string): Observable<ModuleId | any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !operationId || !id) {
            return EMPTY;
          } else {
            const dbCollection: CollectionReference<DocumentData> = collection(
              this.db,
              `organisations/${user.organisation.id}/operations/${operationId}/modules`
            );

            const documentRef: DocumentReference<DocumentData> = doc(
              dbCollection,
              id
            );

            return docSnapshots(documentRef).pipe(
              map((snap: DocumentSnapshot<DocumentData>) => {
                const data = snap.data();
                const id = snap.id;
                return { id, ...data };
              }),
              catchError((error) => EMPTY)
            );
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  getOneStatistiques(operationId: string, id: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) EMPTY;
          const dbCollection: CollectionReference<DocumentData> = collection(
            this.db,
            `organisations/${user.organisation.id}/operations/${operationId}/modules/${id}`
          );

          const documentRef: DocumentReference<DocumentData> = doc(
            dbCollection,
            id
          );
          return of(null);
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  getModuleTypes(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_MODULE_TYPES,
      })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          return { data, loading, error };
        })
      );
  }

  addOne(
    operationId: string,
    module: Module | any
  ): Observable<ModuleSmallId | any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !operationId || !module) {
            return EMPTY;
          } else {
            const dbCollection: CollectionReference<DocumentData> = collection(
              this.db,
              `organisations/${user.organisation.id}/operations/${operationId}/modules`
            );

            const newModule: Module = {
              ...module,
              userUpdate: user,
              auths: [user.id],
              access: {
                [`${user.id}`]: true,
              },
              metadata: this.createMetadata(module),
            };

            const smallVersion = this.createSmallVersion(newModule);

            return this.addOneDetails(operationId, newModule, user).pipe(
              (result) => result
            );
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  createModule(module: any): Observable<any> {
    try {
      if (!module) EMPTY;

      const {
        title,
        type,
        adresse,
        budget,
        calendrier,
        description,
        operation,
        contacts,
        contactsIds,
        effectifMax,
        effectifMin,
        entites,
        entitesIds,
        isFinish,
        referents,
        referentsId,
        objectifHours,
        objectifHoursTheoric,
        objectifPersons,
        objectifPersonsTheoric,
        progressionHours,
        progressionPersons,

        dependencies,
        statut,
        progression,
        remarque,
        formulaire,
        collaboration,
      } = module;

      const newModule = {
        title,
        type,
        adresse,
        budget,
        calendrier,
        description,
        operation: {
          id: operation.id,
        },
        contacts,
        contactsIds,
        effectifMax,
        effectifMin,
        entites,
        entitesIds,
        isFinish,
        referents,
        referentsId,
        objectifHours,
        objectifHoursTheoric,
        objectifPersons,
        objectifPersonsTheoric,
        progressionHours,
        progressionPersons,
        dependencies,
        statut,
        progression,
        remarque,
        formulaire: {
          id: formulaire?.id ? formulaire.id : '',
        },
        collaboration,
      };

      return this.apollo.mutate({
        mutation: this.CREATE_MODULE,
        variables: {
          module: newModule,
        },
      });
    } catch (error) {
      return of(error);
    }
  }

  createModuleS(modules: any[]): Observable<any> {
    try {
      if (!modules?.length) EMPTY;

      const newModules = modules.map((el) => {
        const {
          title,
          type,
          adresse,
          budget,
          calendrier,
          description,
          operation,
          contacts,
          contactsIds,
          effectifMax,
          effectifMin,
          entites,
          entitesIds,
          isFinish,
          referents,
          referentsId,
          objectifs,
          objectifsType,
          dependencies,
          statut,
          progression,
          remarque,
          formulaire,
          collaboration,
        } = el;

        const newModule = {
          title,
          type,
          adresse,
          budget,
          calendrier,
          description,
          operation: {
            id: operation.id,
          },
          contacts,
          contactsIds,
          effectifMax,
          effectifMin,
          entites,
          entitesIds,
          isFinish,
          referents,
          referentsId,
          objectifs,
          objectifsType,
          dependencies,
          statut,
          progression,
          remarque,
          formulaire: {
            id: formulaire?.id ? formulaire.id : '',
          },
          collaboration,
        };

        return newModule;
      });

      return this.apollo.mutate({
        mutation: this.CREATE_MODULES,
        variables: {
          modules: newModules,
        },
      });
    } catch (error) {
      return of(error);
    }
  }

  addOneDetails(
    operationId: string,
    module: Module,
    user: any
  ): Observable<ModuleSmallId | any> {
    try {
      if (!user || !operationId || !module) {
        return EMPTY;
      } else {
        const { title } = module;
        const dbCollection: CollectionReference<DocumentData> = collection(
          this.db,
          `organisations/${user.organisation.id}/operations/${operationId}/modules`
        );

        return from(
          addDoc(dbCollection, module)
            .then((ref) => {
              if (!ref.id) return `Erreur lors de la création du module.`;

              return of(`${title.toUpperCase()} ajouté`);
            })
            .catch((error) => error)
        );
      }
    } catch (error) {
      return of(error);
    }
  }

  updateOne(
    operationId: string,
    module: Partial<ModuleId | any>
  ): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !operationId || !module) {
            return EMPTY;
          } else {
            const { title } = module;
            const documentRef: DocumentReference<DocumentData> = doc(
              this.db,
              `organisations/${user.organisation.id}/operations/${operationId}/modules/${module.id}`
            );

            return from(
              setDoc(documentRef, module, { merge: true })
                .then(() => {
                  return `${title.toUpperCase()} modifié`;
                })
                .catch((error) => error)
            );
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  deleteModule(operationId: string, moduleId: string): Observable<any> {
    try {
      if (!operationId || !moduleId) EMPTY;

      return this.apollo.mutate({
        mutation: this.DELETE_MODULE,
        variables: {
          operationId: operationId,
          moduleId: moduleId,
        },
      });
    } catch (error) {
      return of(error);
    }
  }

  deleteModules(id: string): Observable<any> {
    try {
      if (!id) EMPTY;

      return this.apollo.mutate({
        mutation: this.DELETE_MODULES,
        variables: {
          id: id,
        },
      });
    } catch (error) {
      return of(error);
    }
  }

  //UTILS
  createSmallVersion(
    module: Module | ModuleId | Partial<ModuleId>
  ): ModuleSmall | any {
    if (module) {
      const small: ModuleSmall = {
        type: module.type as string,
        title: module.title as string,
        access: module.access,
        searchKey: module?.searchKey,
        metadata: module.metadata,
        objectifs: module.objectifs,
        budget: module?.budget ? module.budget : 0,
        progression: module?.progression ? module?.progression : 0,

        referents: module.referents,

        calendrier: {
          dateStart: module?.calendrier?.dateStart,
          dateUpdate: module?.calendrier?.dateUpdate,
          dateEnd: module?.calendrier?.dateEnd,
          duree: module?.calendrier?.duree as number,
        },

        description: {
          definition: module?.description?.definition as string,
          deroule: module?.description?.deroule as string,
          accroche: module?.description?.accroche,
        },

        operation: {
          id: module?.operation?.id as string,
          denomination: module?.operation?.denomination as string,
          description: module?.operation?.description as string,
        },

        organisation: {
          id: module?.organisation?.id as string,
          siret: module?.organisation?.siret as string,
          siren: module?.organisation?.siren as string,
          nom_raison_sociale: module?.organisation
            ?.nom_raison_sociale as string,
          logo: module.organisation?.logo
            ? (module.organisation.logo as any)
            : null,
        },

        actions: module?.actions ? module.actions : [],
        participants: module?.participants ? module.participants : [],
        entites: module.entites ? module.entites : [],
        departements: module.departements ? module.departements : null,
        contacts: module?.contacts ? module.contacts : [],
        entitesIds: module?.entitesIds ? (module.entitesIds as any) : null,
        entitesMapIds: module?.entitesMapIds ? module.entitesMapIds : null,
        contactsIds: module?.contactsIds ? (module.contactsIds as any) : null,
        contactsMapIds: module?.contactsMapIds ? module.contactsMapIds : null,
        actionsIds: module?.actionsIds ? (module.actionsIds as any) : null,
        actionsMapIds: module?.actionsMapIds ? module.actionsMapIds : null,
        participantsIds: module?.participantsIds
          ? (module.participantsIds as any)
          : null,
        participantsMapIds: module?.participantsMapIds
          ? module.participantsMapIds
          : null,
      };

      return small;
    } else {
      return null;
    }
  }
  createMetadata(module: Module | ModuleId | Partial<ModuleId>): any {
    const title: string = module?.title as string;
    const entiteDenomination: string = module?.entites?.length
      ? module?.entites[0].etablissement?.denomination
      : '';
    const search_key: string = entiteDenomination
      ? `${title} ${entiteDenomination}`
      : title;
    const letterTitle = title ? this.capitalizeFirstLetter(search_key) : '';
    const arrayTitle = title ? this.createKeywords(search_key) : [];
    const arrEntities = module.entites
      ? module.entites.reduce((acc: any, cur: any) => {
          acc[cur.item] = true;
          return acc;
        }, {})
      : {};

    const startYear =
      module?.calendrier?.dateStart && module.calendrier.dateStart['seconds']
        ? format(fromUnixTime(module.calendrier.dateStart['seconds']), 'yyyy', {
            locale: fr,
          })
        : 'Non renseigné';

    const startMonth =
      module?.calendrier?.dateStart && module.calendrier.dateStart['seconds']
        ? format(fromUnixTime(module.calendrier.dateStart['seconds']), 'MMMM', {
            locale: fr,
          })
        : 'Non renseigné';

    const endYear =
      module?.calendrier?.dateEnd && module.calendrier.dateEnd['seconds']
        ? format(fromUnixTime(module.calendrier.dateEnd['seconds']), 'yyyy', {
            locale: fr,
          })
        : 'Non renseigné';

    const endMonth =
      module?.calendrier?.dateEnd && module.calendrier.dateEnd['seconds']
        ? format(fromUnixTime(module.calendrier.dateEnd['seconds']), 'MMMM', {
            locale: fr,
          })
        : 'Non renseigné';

    const statut = module?.statut ? module?.statut : 'En cours';

    const metadata = {
      letterTitle: letterTitle,
      arrayTitle: arrayTitle,
      arrayEntities: arrEntities,
      statut: statut,
      startYear: startYear,
      startMonth: startMonth,
      endYear: endYear,
      endMonth: endMonth,
    };

    return metadata;
  }

  capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase();
  }

  createKeywords(name: any): any {
    if (name) {
      const arrName: any = [];
      let curName = '';
      name.split('').forEach((letter: any) => {
        curName += letter;
        arrName.push(curName);
      });
      return arrName;
    }
  }
}
