<div [ngSwitch]="type">
  <div *ngSwitchCase="'clauses sociales'">
    <nz-avatar
      nzIcon="project"
      [ngStyle]="{
        'background-color': type?.color ? type.color : defaultColor
      }"
    ></nz-avatar>
  </div>
  <div *ngSwitchDefault>
    <nz-avatar
      nzIcon="project"
      [ngStyle]="{
        'background-color': defaultColor
      }"
    >
    </nz-avatar>
  </div>
</div>
