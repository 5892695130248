import { Component, Input, OnInit } from '@angular/core';
import { ParticipantId } from '../../store/participant.model';

@Component({
  selector: 'app-participant-hebergement-tab',
  templateUrl: './participant-hebergement-tab.component.html',
  styleUrls: ['./participant-hebergement-tab.component.scss'],
})
export class ParticipantHebergementTabComponent implements OnInit {
  @Input() participant: ParticipantId | any = null;
  constructor() {}

  ngOnInit(): void {}
}
