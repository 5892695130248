import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { format, fromUnixTime } from 'date-fns';
import { fr } from 'date-fns/locale';
import { flatten, groupBy, isNull, isUndefined, sum } from 'lodash';
import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-operation-dashboard-intervenants',
  templateUrl: './operation-dashboard-intervenants.component.html',
  styleUrls: ['./operation-dashboard-intervenants.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationDashboardIntervenantsComponent
  implements OnInit, OnChanges, OnDestroy
{
  subscribe = new Subject();
  noExist = 'Non renseigné';

  @Input() dashboard$: Observable<any> = of(null);

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  chartsValues = (statistiques: any) => {
    const values = statistiques
      ? Object.values(statistiques).map((item) => {
          if (isNull(item) || isUndefined(item) || !item) {
            return this.noExist;
          }
          return item;
        })
      : [];

    const labels = statistiques
      ? Object.keys(statistiques).map((item) => {
          if (isNull(item) || isUndefined(item) || !item) {
            return this.noExist;
          }
          return item;
        })
      : [];

    return { values, labels };
  };

  itemsCounter = (items: any[]) => {
    return items.reduce((acc, cur) => {
      const curSize = items.filter((el) => el === cur).length;

      acc[cur] = curSize;
      return acc;
    }, {});
  };

  chartsTypePie = (count: any): any => {
    const transformToArray: any[] = Object.entries(count);
    const transformForChart: any[] = transformToArray.map((el) => ({
      name: el[0],
      value: el[1],
    }));

    return transformForChart;
  };
}
