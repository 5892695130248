<nz-card
  nzHoverable
  nzSize="small"
  (click)="onNew()"
  [ngStyle]="{ height: height, width: width }"
  class="bg-blue-950"
>
  <div class="titleNew">
    <button nz-button nzShape="circle" nzType="text" nzSize="large">
      <!-- <i nz-icon nzType="plus-circle" class="iconNew" *ngIf="!type"></i> -->
      <mat-icon *ngIf="type === 'person'" class="iconNewMat"
        >person_add_alt</mat-icon
      >
      <mat-icon *ngIf="type === 'domain'" class="iconNewMat"
        >domain_add</mat-icon
      >
      <mat-icon *ngIf="type === 'informationCollective'" class="iconNewMat"
        >recent_actors</mat-icon
      >

      <mat-icon *ngIf="type === 'task'" class="iconNewMat">task</mat-icon>

      <mat-icon *ngIf="type === 'participant'" class="iconNewMat">
        supervisor_account
      </mat-icon>
      <mat-icon *ngIf="type === 'module'" class="iconNewMat">
        dynamic_feed
      </mat-icon>
      <mat-icon *ngIf="type === 'action'" class="iconNewMat">
        dashboard
      </mat-icon>
      <mat-icon *ngIf="type === 'experience'" class="iconNewMat">
        work_history
      </mat-icon>

      <mat-icon *ngIf="type === 'document'" class="iconNewMat">
        insert_drive_file
      </mat-icon>
      <mat-icon *ngIf="type === 'service'" class="iconNewMat">
        group_work
      </mat-icon>

      <mat-icon *ngIf="type === 'suivi'" class="iconNewMat">
        edit_note
      </mat-icon>
      <mat-icon *ngIf="type === 'projet'" class="iconNewMat">
        analytics
      </mat-icon>
      <mat-icon *ngIf="type === 'aid'" class="iconNewMat">
        volunteer_activism
      </mat-icon>
      <mat-icon *ngIf="type === 'business'" class="iconNewMat">
        business_center
      </mat-icon>
      <mat-icon *ngIf="type === 'mission'" class="iconNewMat">
        work_history
      </mat-icon>

      <mat-icon *ngIf="type === 'plus'" class="iconNewMat">
        add_circle_outline
      </mat-icon>
      <mat-icon *ngIf="type === 'school'" class="iconNewMat"> school </mat-icon>

      <mat-icon *ngIf="type === 'ficheDePoste'" class="iconNewMat"
        >ballot</mat-icon
      >
    </button>
  </div>
  <nz-space [nzSize]="200"></nz-space>

  <div class="titleNew">
    <h4 nz-typography>{{ title }}</h4>
  </div>

  <nz-space [nzSize]="100"></nz-space>

  <div class="description">
    <p nz-typography>{{ description }}</p>
  </div>

  <div class="description">
    <small nz-typography>{{ definition }}</small>
  </div>
</nz-card>
