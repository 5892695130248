import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZorroModule } from 'src/app/css/zorro.module';
import { MaterialModule } from 'src/app/css/material.module';
import { AvatarGroupComponent } from './components/avatar-group/avatar-group.component';
import { PipeModule } from 'src/app/pipe/pipe.module';

@NgModule({
  declarations: [AvatarGroupComponent],
  imports: [CommonModule, ZorroModule, MaterialModule, PipeModule],
  exports: [AvatarGroupComponent],
})
export class AvatarModule {}
