<quill-editor
  #editor
  class="w-full rounded-md h-50"
  [ngStyle]="{ height: size }"
  placeholder="Note..."
  [modules]="quillConfig"
  [(ngModel)]="content"
  name="content"
  (onContentChanged)="onEditorTextChanged($event)"
>
  <div quill-editor-toolbar class="rounded-tr-lg rounded-tl-lg">
    <span class="ql-formats">
      <select class="ql-size">
        <option selected></option>
        <option value="large">Sous-titre</option>
        <option value="huge">Titre</option>
      </select>
    </span>
    <!-- <span class="ql-formats">
      <select class="ql-size">
        <option value="14px">14px</option>
        <option value="16px">16px</option>
        <option value="18px">18px</option>
        <option value="20px">20px</option>
        <option value="22px">22px</option>
        <option value="24px">24px</option>
      </select>
    </span> -->
    <span class="ql-formats">
      <button class="ql-bold" aria-label="Bold"></button>
      <button class="ql-italic" aria-label="Italic"></button>
      <button class="ql-underline" aria-label="Underline"></button>
      <button class="ql-strike" aria-label="Strike"></button>
    </span>

    <span class="ql-formats">
      <button class="ql-list" value="ordered"></button>
      <button class="ql-list" value="bullet"></button>
      <select title="Text Alignment" class="ql-align">
        <option selected>Gauche</option>
        <option value="center" label="Center"></option>
        <option value="right" label="Right"></option>
        <option value="justify" label="Justify"></option>
      </select>
    </span>

    <span class="ql-formats">
      <button class="ql-indent" value="-1"></button>
      <button class="ql-indent" value="+1"></button>
    </span>

    <span class="ql-formats">
      <select title="Text Color" class="ql-color" defaultValue="rgb(0, 0, 0)">
        <option value="rgb(0, 0, 0)" label="rgb(0, 0, 0)"></option>
        ...
        <option value="rgb(213, 0, 0)" label="rgb(213, 0, 0)"></option>
        <option value="rgb(48, 79, 254)" label="rgb(48, 79, 254)"></option>
        <option value="rgb(255, 214, 0)" label="rgb(255, 214, 0)"></option>
        <option value="rgb(0, 200, 83)" label="rgb(0, 200, 83)"></option>
        <option value="rgb(255, 109, 0)" label="rgb(255, 109, 0)"></option>
        <option value="rgb(255, 255, 255)" label="rgb(255, 255, 255)"></option>
      </select>
      <span class="ql-format-separator"></span>
      <select
        title="Background Color"
        class="ql-background"
        defaultValue="rgb(255, 255, 255)"
      >
        <option value="rgb(0, 0, 0)" label="rgb(0, 0, 0)"></option>
        ...
        <option value="rgb(213, 0, 0)" label="rgb(213, 0, 0)"></option>
        <option value="rgb(48, 79, 254)" label="rgb(48, 79, 254)"></option>
        <option value="rgb(255, 214, 0)" label="rgb(255, 214, 0)"></option>
        <option value="rgb(0, 200, 83)" label="rgb(0, 200, 83)"></option>
        <option value="rgb(255, 109, 0)" label="rgb(255, 109, 0)"></option>
        <option value="rgb(255, 255, 255)" label="rgb(255, 255, 255)"></option>
      </select>
    </span>

    <span class="ql-formats">
      <button class="ql-blockquote"></button>
      <button class="ql-code-block"></button>
    </span>
    <span class="ql-formats" *ngIf="save">
      <button
        nz-button
        nz-tooltip
        nzTooltipTitle="Enregistrer"
        nzTooltipPlacement="bottom"
        [nzTooltipMouseEnterDelay]="0.15"
        nzSize="small"
        type="submit"
        class="iconStandard"
        style="margin-left: 12px; margin-right: 25px !important"
        (click)="$event.stopPropagation(); onSubmit($event)"
      >
        Enregistrer
      </button>
    </span>
    <span class="ql-formats" style="margin-left: 6px">
      <button
        nz-button
        nzType="text"
        nz-tooltip
        nzTooltipTitle="Effacer"
        nzTooltipPlacement="bottom"
        [nzTooltipMouseEnterDelay]="0.15"
        nzSize="small"
        (click)="$event.stopPropagation(); onClear($event)"
        class="iconDelete"
      >
        <i nz-icon nzType="clear"></i>
      </button>
    </span>
  </div>
</quill-editor>
