import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'app-document-type',
  templateUrl: './document-type.component.html',
  styleUrls: ['./document-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentTypeComponent implements OnInit {
  @Input() item: string = '';
  constructor() {}

  ngOnInit(): void {}
}
