<div class="w-full grid grid-cols-1 space-y-2 -mt-24">
  <div class="w-full bg-slate-50 rounded-xl overflow-hidden">
    <div class="w-full space-y-2 p-2">
      <label
        [class]="
          dropzoneActive
            ? 'w-full flex items-center justify-between h-20 p-4 transition bg-white border-2 border-blue-400 border-solid rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-double '
            : 'w-full flex items-center justify-between h-20 p-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none'
        "
        appFileDrop
        (filesDropped)="handleDropFiles($event)"
        (filesHovered)="onDropZoneState($event)"
      >
        <svg
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <g
            id="页面-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="File" transform="translate(-48.000000, -192.000000)">
              <g
                id="upload_3_line"
                transform="translate(48.000000, 192.000000)"
              >
                <path
                  d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z"
                  id="MingCute"
                  fill-rule="nonzero"
                ></path>
                <path
                  d="M11.5,4 C8.85134,4 6.73903,6.38087 7.02729,8.99923 C7.08294,9.50471 6.75509,9.9678 6.26851,10.0896 C4.96468,10.4159 4,11.5965 4,13 C4,14.6569 5.34315,16 7,16 L8,16 C8.55228,16 9,16.4477 9,17 C9,17.5523 8.55228,18 8,18 L7,18 C4.23858,18 2,15.7614 2,13 C2,10.9486 3.23468,9.18749 5.00053,8.41614 C5.04543,4.86494 7.93814,2 11.5,2 C14.2856,2 16.66,3.75154 17.5856,6.21198 C20.13,6.90766 22,9.23462 22,12 C22,15.3137 19.3137,18 16,18 C15.4477,18 15,17.5523 15,17 C15,16.4477 15.4477,16 16,16 C18.2091,16 20,14.2091 20,12 C20,10.0145 18.5524,8.36524 16.6552,8.05324 C16.2653,7.98913 15.9477,7.70323 15.8441,7.32051 C15.326,5.40663 13.576,4 11.5,4 Z M13.0000009,12.4162 L14.2934,13.7077 C14.6843,14.0979 15.3174,14.0974 15.7077,13.7066 C16.0979,13.3157 16.0974,12.6826 15.7066,12.2923 L12.8832,9.47341 C12.3952,8.98618 11.6048,8.98618 11.1168,9.47341 L8.29345,12.2923 C7.90262,12.6826 7.90212,13.3157 8.29234,13.7066 C8.68256,14.0974 9.31572,14.0979 9.70655,13.7077 L11.0000009,12.4162 L11.0000009,21 C11.0000009,21.5523 11.4477,22 12.0000009,22 C12.5523,22 13.0000009,21.5523 13.0000009,21 L13.0000009,12.4162 Z"
                  id="形状"
                  fill="#09244BFF"
                ></path>
              </g>
            </g>
          </g>
        </svg>

        <span class="text-sm font-semibold text-gray-800"
          >Déposer vos fichiers</span
        >

        <p class="text-sm text-gray-800">ou</p>
        <span class="btn btn-md btn-ghost bg-slate-50 text-gray-800"
          >Sélectionner les fichiers</span
        >

        <input
          type="file"
          name="file_upload"
          class="hidden"
          multiple
          (change)="handleChange($event)"
        />
      </label>

      <form
        [formGroup]="document"
        class="w-full flex items-center justify-center space-y-4"
      >
        <!-- <div class="multi">
          <div>
            <input
              [hidden]="true"
              class="ng-hide"
              id="input-file-id"
              multiple
              type="file"
              (change)="handleChange($event)"
            />
            <label for="input-file-id" class="iconStandard">
              <i
                nz-icon
                nzType="cloud-upload"
                nzTheme="outline"
                style="margin-right: 10px; margin-top: 2px"
              ></i>
              Sélectionner les fichiers
              <nz-badge
                *ngIf="elementArray!.length > 0"
                [nzCount]="elementArray.length"
                [nzOverflowCount]="99"
                [nzStyle]="{
                  backgroundColor: '#ffab00',
                  color: 'black',
                  fontWeigth: 'bold'
                }"
              >
              </nz-badge>
            </label>
          </div>
        </div> -->

        <div
          class="w-full h-28 overflow-y-auto"
          formArrayName="elements"
          *ngIf="elementArray?.controls?.length; else noData"
        >
          <div
            *ngFor="let el of elementArray?.controls; let i = index"
            [formGroupName]="i"
            class="w-full"
          >
            <div class="w-full flex items-start justify-start space-x-2">
              <div class="w-5/12 badge badge-lg">
                {{ el?.value.file.name | carLimitation : 20 }}
              </div>
              <div class="w-6/12" formGroupName="doc">
                <nz-form-item>
                  <nz-form-label required></nz-form-label>
                  <nz-form-control>
                    <nz-select
                      nzBorderless
                      nzPlaceHolder="Type de document"
                      formControlName="type"
                    >
                      <nz-option
                        *ngFor="let item of filters$ | async"
                        [nzLabel]="item | carLimitation : 40"
                        [nzValue]="item"
                      >
                      </nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <!-- <div class="w-5/12" formGroupName="doc">
                <nz-form-item>
                  <nz-form-control>
                    <input
                      nz-input
                      nzBorderless
                      placeholder="Titre"
                      formControlName="title"
                      required
                    />
                  </nz-form-control>
                </nz-form-item>
              </div> -->
              <div class="w-1/12">
                <button
                  class="btn btn-sm btn-circle btn-ghost"
                  (click)="removeDocumentFile(i)"
                >
                  <svg
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <g
                      id="页面-1"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <g
                        id="System"
                        transform="translate(-576.000000, -192.000000)"
                        fill-rule="nonzero"
                      >
                        <g
                          id="delete_2_line"
                          transform="translate(576.000000, 192.000000)"
                        >
                          <path
                            d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z"
                            id="MingCute"
                            fill-rule="nonzero"
                          ></path>
                          <path
                            d="M14.2792,2 C15.1401,2 15.9044,2.55086 16.1766,3.36754 L16.7208,5 L20,5 C20.5523,5 21,5.44772 21,6 C21,6.55227 20.5523,6.99998 20,7 L19.9975,7.07125 L19.9975,7.07125 L19.1301,19.2137 C19.018,20.7837 17.7117,22 16.1378,22 L7.86224,22 C6.28832,22 4.982,20.7837 4.86986,19.2137 L4.00254,7.07125 C4.00083,7.04735 3.99998,7.02359 3.99996,7 C3.44769,6.99998 3,6.55227 3,6 C3,5.44772 3.44772,5 4,5 L7.27924,5 L7.82339,3.36754 C8.09562,2.55086 8.8599,2 9.72076,2 L14.2792,2 Z M17.9975,7 L6.00255,7 L6.86478,19.0712 C6.90216,19.5946 7.3376,20 7.86224,20 L16.1378,20 C16.6624,20 17.0978,19.5946 17.1352,19.0712 L17.9975,7 Z M10,10 C10.51285,10 10.9355092,10.386027 10.9932725,10.8833761 L11,11 L11,16 C11,16.5523 10.5523,17 10,17 C9.48715929,17 9.06449214,16.613973 9.00672766,16.1166239 L9,16 L9,11 C9,10.4477 9.44771,10 10,10 Z M14,10 C14.5523,10 15,10.4477 15,11 L15,16 C15,16.5523 14.5523,17 14,17 C13.4477,17 13,16.5523 13,16 L13,11 C13,10.4477 13.4477,10 14,10 Z M14.2792,4 L9.72076,4 L9.38743,5 L14.6126,5 L14.2792,4 Z"
                            id="形状"
                            fill="#09244BFF"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div
        class="w-full flex items-center justify-center text-sm leading-tight"
      ></div>
    </div>
  </div>
  <div class="w-full flex items-end justify-end space-x-4">
    <a
      class="btn btn-sm btn-ghost"
      (click)="$event.preventDefault(); $event.stopPropagation(); onCancel()"
      >Annuler</a
    >
    <a
      class="btn btn-sm"
      *ngIf="elementArray!.length > 0"
      (click)="
        $event.preventDefault(); $event.stopPropagation(); removeAllFiles()
      "
      >Tout supprimer</a
    >
    <a
      class="btn btn-sm btn-primary gap-2"
      *ngIf="elementArray.length > 0"
      (click)="$event.preventDefault(); $event.stopPropagation(); saveDoc()"
      >Télécharger

      <div class="badge badge-secondary">{{ elementArray.length }}</div>
    </a>
  </div>
</div>

<ng-template #noData>
  <app-no-result
    [isButton]="false"
    [addtitle]="'Aucun fichier ajouté'"
  ></app-no-result>
</ng-template>
