<div
  class="static flex flex-col items-start bg-blue-950 h-screen max-h-screen p-40"
>
  <div class="static flex items-start flex-col">
    <div class="static text-left w-72 m-2">
      <h4 class="text-lg font-bold text-white tracking-wider">{{ title }}</h4>
    </div>
    <div>
      <ng-lottie
        [options]="options"
        (animationCreated)="animationCreated($event)"
        [width]="'400px'"
        [height]="'400px'"
      ></ng-lottie>
    </div>
  </div>
</div>
