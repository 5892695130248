<nz-card
  [nzTitle]="filterTitle"
  class="filtersCard"
  nzBorderless
  nzSize="small"
>
  <nz-row
    nzAlign="top"
    nzJustify="start"
    nzGutter="8"
    *ngIf="filters$ | async as filters"
  >
    <nz-col nzSpan="12">
      <h5 nz-typography>Grand domaine</h5>
      <nz-select
        nzShowSearch
        nzAllowClear
        nzBorderless
        nzPlaceHolder="Grand domaine"
        [(ngModel)]="optionCategorie"
        (ngModelChange)="onQuery($event, 'grandDomaine')"
      >
        <nz-option
          *ngFor="let item of filters?.metiersGrandDomaine"
          [nzLabel]="item"
          [nzValue]="item"
        ></nz-option>
      </nz-select>
    </nz-col>
    <nz-col nzSpan="12">
      <h5 nz-typography>Domaine professionnel</h5>
      <nz-select
        nzShowSearch
        nzAllowClear
        nzBorderless
        nzPlaceHolder="Domaine professionnel"
        [(ngModel)]="optionActivity"
        (ngModelChange)="onQuery($event, 'domaine')"
      >
        <nz-option
          *ngFor="let item of filters?.metiersDomaine"
          [nzLabel]="item"
          [nzValue]="item"
        ></nz-option>
      </nz-select>
    </nz-col>
  </nz-row>

  <nz-row
    nzAlign="top"
    nzJustify="start"
    nzGutter="8"
    *ngIf="filters$ | async as filters"
  >
    <nz-col nzSpan="24">
      <h5 nz-typography>Activité professionnelle</h5>
      <nz-select
        nzShowSearch
        nzAllowClear
        nzBorderless
        nzPlaceHolder="Activité professionnelle"
        [(ngModel)]="optionSecteur"
        (ngModelChange)="onQuery($event, 'activite')"
      >
        <nz-option
          *ngFor="let item of filters?.libelle_activite_principale"
          [nzLabel]="item"
          [nzValue]="item"
        ></nz-option>
      </nz-select>
    </nz-col>
  </nz-row>

  <nz-row
    nzAlign="top"
    nzJustify="start"
    nzGutter="8"
    *ngIf="filters$ | async as filters"
  >
    <nz-col nzSpan="8">
      <h5 nz-typography>Commune</h5>

      <nz-select
        nzShowSearch
        nzAllowClear
        nzBorderless
        nzPlaceHolder="Commune..."
        [(ngModel)]="optionCommune"
        (ngModelChange)="onQuery($event, 'commune')"
      >
        <nz-option
          *ngFor="let item of filters?.communes"
          [nzLabel]="item"
          [nzValue]="item"
        ></nz-option>
      </nz-select>
    </nz-col>
    <nz-col nzSpan="8">
      <h5 nz-typography>Code postal</h5>
      <nz-select
        nzShowSearch
        nzAllowClear
        nzBorderless
        nzPlaceHolder="Code postal..."
        [(ngModel)]="optionCodePostal"
        (ngModelChange)="onQuery($event, 'postcode')"
      >
        <nz-option
          *ngFor="let item of filters?.code_postal"
          [nzLabel]="item"
          [nzValue]="item"
        ></nz-option>
      </nz-select>
    </nz-col>
    <nz-col nzSpan="8">
      <h5 nz-typography>ESS</h5>

      <nz-select
        nzShowSearch
        nzAllowClear
        nzBorderless
        nzPlaceHolder="ESS"
        [(ngModel)]="optionRegion"
        (ngModelChange)="onQuery($event, 'ess')"
      >
        <nz-option
          *ngFor="let item of filters?.economie_sociale_solidaire"
          [nzLabel]="item"
          [nzValue]="item"
        ></nz-option>
      </nz-select>
    </nz-col>
  </nz-row>

  <nz-row
    nzAlign="top"
    nzJustify="start"
    nzGutter="8"
    *ngIf="filters$ | async as filters"
  >
    <nz-col nzSpan="24">
      <h5 nz-typography>Métiers</h5>
      <nz-select
        nzShowSearch
        nzAllowClear
        nzBorderless
        nzPlaceHolder="Par métier..."
        [(ngModel)]="optionMetiers"
        (ngModelChange)="onQuery($event, 'metiers')"
      >
        <nz-option
          *ngFor="let item of filters?.metiers"
          [nzLabel]="item ? (item | titlecase) : 'Non renseigné'"
          [nzValue]="item"
        ></nz-option>
      </nz-select>
    </nz-col>
  </nz-row>

  <nz-divider
    nzOrientation="center"
    nzType="horizontal"
    nzText="Filtres personnalisés"
  ></nz-divider>
  <nz-row
    nzAlign="top"
    nzJustify="start"
    nzGutter="8"
    *ngIf="roms$ | async as roms"
  >
    <nz-col nzSpan="8">
      <button
        nz-button
        nzType="text"
        *ngIf="favoris$ | async as favoris"
        nzBlock
        (click)="onQuery($event, 'favoris', favoris?.data)"
        class="favorisFilterBtn"
      >
        <i nz-icon nzType="star"></i><span>Favoris</span>
      </button>
    </nz-col>
    <nz-col nzSpan="16"> </nz-col>
  </nz-row>
</nz-card>

<ng-template #filterTitle>
  <nz-row
    nzAlign="middle"
    nzJustify="start"
    nzGutter="2"
    class="headerRowfEntiteFilters"
  >
    <nz-col nzSpan="12"><h4 nz-typography>Filtres</h4></nz-col>
    <nz-col nzSpan="6">
      <i nz-icon nzType="loading" *ngIf="loading$ | async" class="loading"></i>
    </nz-col>
    <nz-col nzSpan="6">
      <button
        nz-button
        nzDanger
        nzSize="small"
        *ngIf="filtersActivated; else noFilterTitle"
        (click)="onClear()"
      >
        <i nz-icon nzType="filter"></i>
        Retier le filtre
      </button></nz-col
    >
  </nz-row>
</ng-template>

<ng-template #noFilterTitle> </ng-template>
