import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactControlerListComponent } from './components/contact-controler-list/contact-controler-list.component';
import { UtilitiesModule } from 'src/app/contents/components/utilities/utilities.module';
import { HeaderModule } from 'src/app/contents/components/header-title/header.module';
import { NoResultModule } from 'src/app/contents/components/no-result/no-result.module';
import { CoordonneesModule } from 'src/app/contents/components/coordonnees/coordonnees.module';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [ContactControlerListComponent],
  exports: [ContactControlerListComponent],
  imports: [
    CommonModule,
    UtilitiesModule,
    HeaderModule,
    NoResultModule,
    CoordonneesModule,
    SharedModule,
  ],
})
export class ContactControlerModule {}
