import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Organisation, OrganisationId } from './organisation.model';
import * as OrganisationActions from './organisation.actions';

export const organisationsFeatureKey = 'organisations';

export interface OrganisationState extends EntityState<any> {
  organisation: OrganisationId | any;
  loading: boolean;
  utilities: any;
  error: any;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<Organisation>();

export const initialState: OrganisationState = adapter.getInitialState({
  organisation: undefined,
  loading: false,
  utilities: undefined,
  error: undefined,
});

export const reducer = createReducer(
  initialState,
  on(OrganisationActions.loadOrganisationsSuccess, (state, action) =>
    adapter.setAll(action.organisations, state)
  ),
  on(OrganisationActions.loadOrganisationsFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //GET ONE
  on(OrganisationActions.loadOrganisation, (state, action) => {
    return {
      ...state,
      loading: true,
      error: undefined,
    };
  }),
  on(OrganisationActions.loadOrganisationSuccess, (state, action) => {
    return {
      ...state,
      organisation: action.organisation,
      loading: false,
      error: undefined,
    };
  }),
  on(OrganisationActions.loadOrganisationFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
      organisation: undefined,
    };
  }),

  //ADD
  on(OrganisationActions.addOrganisation, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(OrganisationActions.addOrganisationSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      error: undefined,
    };
  }),
  on(OrganisationActions.addOrganisationFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  }),

  //UPDATE
  on(OrganisationActions.updateOrganisation, (state, action) =>
    adapter.updateOne(action.organisation, state)
  ),
  on(OrganisationActions.updateOrganisationSuccess, (state, action) => {
    return {
      ...state,
      organisation: action.organisation,
    };
  }),
  on(OrganisationActions.loadLogoOrganisation, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),

  on(OrganisationActions.updateOrganisationFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //DELETE
  on(OrganisationActions.deleteOrganisation, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(OrganisationActions.deleteOrganisationFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  on(OrganisationActions.clearOrganisations, (state) =>
    adapter.removeAll(state)
  )
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
