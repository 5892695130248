import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'avatar',
})
export class AvatarPipe implements PipeTransform {
  transform(value: any): string {
    if (!value) {
      return '';
    }
    const { firstName, lastName } = value;
    const firstLetter: string = firstName.charAt(0).toUpperCase();
    const lastLetter: string = lastName.charAt(0).toUpperCase();
    const avatar: string = `${lastLetter}${firstLetter}`;
    return avatar;
  }
}
