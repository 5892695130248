import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
} from '@angular/core';
import { ParticipantId } from '../../store/participant.model';

@Component({
  selector: 'app-participant-identity-tab',
  templateUrl: './participant-identity-tab.component.html',
  styleUrls: ['./participant-identity-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantIdentityTabComponent implements OnInit {
  default: string = '#bfbfbf';
  colorValidation: string = '#85a5ff';
  colorAlert: string = '#ff7875';
  avatarBackgroundColor: string = '#1890ff';

  @Input() participant: ParticipantId | any;
  constructor() {}

  ngOnInit(): void {}
}
