import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  MaxValidator,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, of, Subject, takeUntil } from 'rxjs';
import { UtilitiesState } from 'src/app/contents/components/utilities/store/utilities.reducer';
import * as fromUtilitiesSelector from 'src/app/contents/components/utilities/store/utilities.selectors';
import * as fromUtilitiesAction from 'src/app/contents/components/utilities/store/utilities.actions';
import { Timestamp } from 'firebase/firestore';

@Component({
  selector: 'app-participant-diagnostic-form-professionnelle',
  templateUrl: './participant-diagnostic-form-professionnelle.component.html',
  styleUrls: ['./participant-diagnostic-form-professionnelle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantDiagnosticFormProfessionnelleComponent
  implements OnInit, OnChanges, OnDestroy
{
  subscribe = new Subject();
  today: Date = new Date(Date.now());
  dateFormat: string = 'dd/MM/yyyy';

  //VARIABLES

  //FORM FIELDS
  professionnelleForm: FormGroup = this.fb.group({});

  //INIT
  @Input() professionnelleUpdate$: Observable<any> = of(null);
  @Output() add = new EventEmitter(false);

  constructor(
    private fb: FormBuilder,
    private utilitiesStore: Store<UtilitiesState>
  ) {}

  ngOnInit(): void {
    this.form();
    this.onFormChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  form(): void {
    this.formInit();
    this.formCreate();
    this.formSet();
  }

  formInit(): void {}

  formCreate(): void {
    this.professionnelleForm = this.fb.group({});
  }

  formSet(): void {
    this.professionnelleUpdate$
      .pipe(takeUntil(this.subscribe))
      .subscribe((professionnelle) => {
        if (!professionnelle) return;
        this.professionnelleForm.patchValue({ ...professionnelle });
      });
  }

  onAdd(item: any): void {}

  checkboxChanges(event: boolean, type: string): void {
    switch (type) {
      case 'isCarteBtp':
        this.professionnelleForm.patchValue({
          isCarteBtp: event,
        });

        break;
      case 'isExpiredCarteBtp':
        this.professionnelleForm.patchValue({
          isExpiredCarteBtp: event,
        });

        break;
      case 'projet':
        break;

      default:
        break;
    }
  }

  onFormChanges(): void {
    this.professionnelleForm.valueChanges
      .pipe(takeUntil(this.subscribe))
      .subscribe((values) => {
        this.add.emit(values);
      });
  }
}
