import { OperationTask } from './../components/operation-task/store/operation-task.model';
import { Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  doc,
  getDoc,
  Timestamp,
  query,
  where,
  collectionSnapshots,
} from '@angular/fire/firestore';
import { Observable, of, from, EMPTY } from 'rxjs';
import { switchMap, mergeMap, map, catchError } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { UserState } from '../components/user/store/user.reducer';
import * as fromUserSelector from '../components/user/store/user.selectors';
import {
  endOfMonth,
  endOfWeek,
  format,
  fromUnixTime,
  startOfMinute,
  startOfWeek,
} from 'date-fns';
import { Apollo, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'any',
})
export class EntiteTaskService {
  CREATE_ENTITE_TASK = gql`
    mutation CreateEntiteTask($task: TaskInput!) {
      createEntiteTask(task: $task)
    }
  `;
  UPDATE_ENTITE_TASK = gql`
    mutation updateEntiteTask($task: TaskUpdateInput!) {
      updateEntiteTask(task: $task)
    }
  `;

  DELETE_ENTITE_TASK = gql`
    mutation DeleteEntiteTask($entiteId: ID!, $id: ID!) {
      deleteEntiteTask(entiteId: $entiteId, id: $id)
    }
  `;
  DELETE_ENTITE_TAKS = gql`
    mutation DeletEntiteTasks($id: ID!) {
      deleteEntiteTasks(id: $id)
    }
  `;

  constructor(
    private db: Firestore,
    private userStore: Store<UserState>,
    private apollo: Apollo
  ) {}

  getAll(entiteId: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !entiteId) {
            return of([]);
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/entites/${entiteId}/tasks`
            );

            const changes = collectionSnapshots(dbCollection).pipe(
              map((changes) => {
                return changes.map((snap) => {
                  const data = snap.data();
                  const id = snap.id;
                  return { id, ...data };
                });
              })
            );

            return changes;
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  getAllUserTasks(entiteId: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !entiteId) {
            return of([]);
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/entites/${entiteId}/tasks`
            );

            const queries = query(
              dbCollection,
              where(`ids`, 'array-contains', user.id)
            );

            const changes = collectionSnapshots(queries).pipe(
              map((changes) => {
                return changes.map((snap) => {
                  const data = snap.data();
                  const id = snap.id;
                  return { id, ...data };
                });
              })
            );

            return changes;
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  getAllTodayTasks(entiteId: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !entiteId) {
            return of([]);
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/entites/${entiteId}/tasks`
            );

            const today = new Date(Date.now());
            const todayDate = Timestamp.fromDate(today);

            const queries = query(
              dbCollection,
              where(`dateStart`, '==', todayDate)
            );

            const changes = collectionSnapshots(queries).pipe(
              map((changes) => {
                return changes.map((snap) => {
                  const data = snap.data();
                  const id = snap.id;
                  return { id, ...data };
                });
              })
            );

            return changes;
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }
  getAllWeekTasks(entiteId: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !entiteId) {
            return of([]);
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/entites/${entiteId}/tasks`
            );

            const today = new Date(Date.now());
            const startDate: Date = startOfWeek(today);
            const endDate: Date = endOfWeek(today);
            const startTime = Timestamp.fromDate(startDate);
            const endTime = Timestamp.fromDate(endDate);

            const queries = query(
              dbCollection,
              where(`dateStart`, '>=', startTime),
              where(`dateStart`, '<=', endTime)
            );

            const changes = collectionSnapshots(queries).pipe(
              map((changes) => {
                return changes.map((snap) => {
                  const data = snap.data();
                  const id = snap.id;
                  return { id, ...data };
                });
              })
            );

            return changes;
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }
  getAllMonthTasks(entiteId: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !entiteId) {
            return of([]);
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/entites/${entiteId}/tasks`
            );

            const today = new Date(Date.now());
            const startDate: Date = startOfMinute(today);
            const endDate: Date = endOfMonth(today);
            const startTime = Timestamp.fromDate(startDate);
            const endTime = Timestamp.fromDate(endDate);

            const queries = query(
              dbCollection,
              where(`dateStart`, '>=', startTime),
              where(`dateStart`, '<=', endTime)
            );

            const changes = collectionSnapshots(queries).pipe(
              map((changes) => {
                return changes.map((snap) => {
                  const data = snap.data();
                  const id = snap.id;
                  return { id, ...data };
                });
              })
            );

            return changes;
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  getOne(entiteId: string, id: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !entiteId) {
            return of(null);
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/entites/${entiteId}/tasks`
            );

            const documentRef = doc(dbCollection, id);
            return from(
              getDoc(documentRef)
                .then((snap) => {
                  const data = snap.data();
                  const id = snap.id;
                  return { id, ...data };
                })
                .catch((error) => error)
            ).pipe(mergeMap((res: any) => of(res)));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  addOne(entiteId: string, task: OperationTask): Observable<any> {
    try {
      if (!task || !entiteId) return EMPTY;

      const { title, description, texts, priority, dateStart, dateEnd } = task;

      const newTask = {
        title,
        description,
        texts,
        priority,
        dateStart,
        dateEnd,
        item: { id: entiteId },
      };
      return this.apollo.mutate({
        mutation: this.CREATE_ENTITE_TASK,
        variables: {
          task: newTask,
        },
      });
    } catch (error) {
      return of(error);
    }
  }

  updateOne(task: any, type?: string): Observable<any> {
    try {
      if (!task) return EMPTY;
      return this.apollo.mutate({
        mutation: this.UPDATE_ENTITE_TASK,
        variables: {
          task: task,
        },
      });
    } catch (error) {
      return of(error);
    }
  }

  deleteOne(entiteId: string, id: string): Observable<any> {
    try {
      if (!entiteId || !id) return EMPTY;
      return this.apollo.mutate({
        mutation: this.DELETE_ENTITE_TASK,
        variables: {
          entiteId: entiteId,
          id: id,
        },
      });
    } catch (error) {
      return of(error);
    }
  }

  deleteAll(id: string): Observable<any> {
    try {
      if (!id) return EMPTY;
      return this.apollo.mutate({
        mutation: this.DELETE_ENTITE_TAKS,
        variables: {
          id: id,
        },
      });
    } catch (error) {
      return of(error);
    }
  }
}
