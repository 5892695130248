import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { OperationTaskState } from '../../store/operation-task.reducer';
import * as fromOperationTaskSelector from '../../store/operation-task.selectors';

import * as fromOperationTaskAction from '../../store/operation-task.actions';
import { OperationId } from 'src/app/components/operation/store/operation.model';

@Component({
  selector: 'app-operation-task-header',
  templateUrl: './operation-task-header.component.html',
  styleUrls: ['./operation-task-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationTaskHeaderComponent implements OnInit, OnChanges {
  deleteAllType: string = 'Voulez-vous supprimer toutes les tâches ?';
  isFiltered$: Observable<boolean> = of(false);
  @Input() isButton: boolean = false;
  @Input() icon: string = '';
  @Input() title: string = '';
  @Input() count: number = 0;
  @Input() filters$: Observable<string[]> = of([]);
  @Input() operation$: Observable<OperationId | any> = of(null);
  @Input() user$: Observable<any> = of(null);
  @Input() taskSize: number = 0;

  @Output() add: EventEmitter<any> = new EventEmitter();

  constructor(private operationTaskStore: Store<OperationTaskState>) {}

  ngOnInit(): void {
    this.getOperationTaskFilters();
    this.getOperationTaskFilterIndicator();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  onNew(): void {
    this.add.emit(true);
  }

  getOperationTaskFilters(): void {
    this.filters$ = this.operationTaskStore.select(
      fromOperationTaskSelector.filters
    );
  }
  getOperationTaskFilterIndicator(): void {
    this.isFiltered$ = this.operationTaskStore.select(
      fromOperationTaskSelector.isFiltered
    );
  }

  onAction(event: string, operation: OperationId): void {
    switch (event) {
      case 'Tout':
        this.operationTaskStore.dispatch(
          fromOperationTaskAction.loadOperationTasks({
            operationId: operation.id,
          })
        );
        break;
      case 'Mes tâches':
        this.operationTaskStore.dispatch(
          fromOperationTaskAction.loadOperationMyTasks({
            operationId: operation.id,
          })
        );
        break;
      case "Aujourd'hui":
        this.operationTaskStore.dispatch(
          fromOperationTaskAction.loadOperationTodayTasks({
            operationId: operation.id,
          })
        );
        break;

      case 'Cette semaine':
        this.operationTaskStore.dispatch(
          fromOperationTaskAction.loadOperationWeekTasks({
            operationId: operation.id,
          })
        );
        break;

      case 'Ce mois':
        this.operationTaskStore.dispatch(
          fromOperationTaskAction.loadOperationMonthTasks({
            operationId: operation.id,
          })
        );
        break;

      default:
        break;
    }
  }

  onDeleteAll(operation: OperationId): void {
    if (!operation) return;
    this.operationTaskStore.dispatch(
      fromOperationTaskAction.deleteOperationTasks({
        ids: [],
        operationId: operation.id,
      })
    );
  }

  onCancel(): void {
    return;
  }
}
