import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-operation-note-sujet-warning-list',
  templateUrl: './operation-note-sujet-warning-list.component.html',
  styleUrls: ['./operation-note-sujet-warning-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationNoteSujetWarningListComponent
  implements OnInit, OnChanges
{
  @Input() sujets: any = [];
  constructor() {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {}
}
