import { Pipe, PipeTransform } from '@angular/core';
import { differenceBy } from 'lodash';

@Pipe({
  name: 'differenceById',
})
export class DifferenceByIdPipe implements PipeTransform {
  transform(value: any): any {
    if (!value || !value?.length) {
      return [];
    }

    if (value || value.length) {
      const firstArray: any[] = value[0];
      const secondArray: any[] = value[1];

      return differenceBy(firstArray, secondArray, 'id');
    }
  }
}
