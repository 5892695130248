import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-item-header-filters',
  template: `
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="6">
        <h5 nz-typography>{{ title }}</h5>
      </nz-col>
      <nz-col nzSpan="6">
        <p nz-typography>{{ description }}</p>
      </nz-col>
      <nz-col nzSpan="6"></nz-col>
      <nz-col nzSpan="3"></nz-col>
      <nz-col nzSpan="1"></nz-col>
      <nz-col nzSpan="1"></nz-col>
      <nz-col nzSpan="1"> </nz-col>
    </nz-row>
  `,
  styles: [``],
})
export class ItemHeaderFiltersComponent implements OnInit {
  @Input() title: string = '';
  @Input() description: string = '';
  @Input() source: any = null;
  @Output() filter = new EventEmitter<any>(false);
  constructor() {}

  ngOnInit(): void {}
}
