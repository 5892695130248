import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { of, Observable, Subject, BehaviorSubject } from 'rxjs';
import { TodoId } from '../../store/todo.model';
import { TodoState } from '../../store/todo.reducer';
import * as fromTodoAction from '../../store/todo.actions';

import * as fromTodoSelector from '../../store/todo.selectors';
import { fromUnixTime, isAfter, isSameDay } from 'date-fns';
import { Update } from '@ngrx/entity';

@Component({
  selector: 'app-todo-header',
  templateUrl: './todo-header.component.html',
  styleUrls: ['./todo-header.component.scss'],
})
export class TodoHeaderComponent implements OnInit {
  todos$: Observable<TodoId[] | any> = of(null);
  subscribe = new Subject();
  activeFilter: string = 'uncompleted';
  todo$: Observable<TodoId | any> = of(null);
  loading$: Observable<any> = of(false);
  addingTodo: boolean = false;
  todoTitle: string = 'TÂCHES';
  todosUncompleted: boolean = false;
  todosCompleted: boolean = false;
  todosFilter: boolean = false;
  today: Date = new Date(Date.now());
  todoBadgeStyle = {
    backgroundColor: '#fff',
    color: '#999',
    boxShadow: '0 0 0 1px #d9d9d9 inset',
  };

  newTodo$ = new BehaviorSubject<boolean>(false);
  modalClose = new BehaviorSubject<boolean>(false);

  @Input() isModal: boolean = false;
  @Input() select = new BehaviorSubject<string>('');
  @Output() openModal = new EventEmitter<boolean>(false);
  @Output() open = new EventEmitter<boolean>(false);
  @Output() closeModal = new EventEmitter<boolean>();
  @Output() title = new EventEmitter<string>();
  @Output() size = new EventEmitter<number>();
  @Output() fortoday = new EventEmitter<boolean>(false);
  @Output() forfutur = new EventEmitter<boolean>(false);
  @Output() forlater = new EventEmitter<boolean>(false);
  @Output() forpriority = new EventEmitter<boolean>(false);
  @Output() filter = new EventEmitter<boolean>(false);
  constructor(private todoStore: Store<TodoState>) {}

  ngOnInit(): void {
    this.getTodos();
  }

  getTodos(): void {
    this.todos$ = this.todoStore.select(fromTodoSelector.todos);
  }

  new__TODO(modal: boolean): any {
    this.open.next(true);
  }

  close__TODO(modal: boolean): any {
    if (!modal) {
      this.modalClose.next(true);
      this.modalClose.next(false);
    }

    if (modal) {
      this.addingTodo = false;
    }
  }

  getCompleted_TODOS(): void {
    this.todosUncompleted = true;
    this.todosCompleted = false;
    this.activeFilter = 'completed';

    this.todoStore.dispatch(fromTodoAction.loadCompletedTodos());
  }

  get_TODOS(): void {
    this.todosUncompleted = false;
    this.todosCompleted = true;
    this.todosFilter = false;
    this.activeFilter = 'uncompleted';
    this.todoStore.dispatch(fromTodoAction.loadTodos({ completed: false }));
  }

  isTodoLate(todo: TodoId): boolean {
    const end = fromUnixTime(todo?.dateEnd['seconds']);
    if (isAfter(new Date(Date.now()), end) && !todo.completed) {
      return true;
    } else {
      return false;
    }
  }

  isTodoFutur(todo: TodoId): boolean {
    const start = fromUnixTime(todo?.dateStart['seconds']);
    if (isAfter(start, new Date(Date.now())) && !todo?.completed) {
      return true;
    } else {
      return false;
    }
  }

  removeTodosFilters(): void {
    this.todoStore.pipe(select(fromTodoSelector.todos)).subscribe((todos) => {
      if (todos.length) {
        this.todosFilter = false;
        this.todos$ = of(todos);
      } else {
        this.todosFilter = false;
      }
    });

    this.filter.emit(true);
  }

  getTodosToday(): void {
    let todosOfToday: any[] = [];
    this.todoStore.pipe(select(fromTodoSelector.todos)).subscribe((todos) => {
      if (todos.length) {
        const filters = todos.filter((el) =>
          isSameDay(this.today, fromUnixTime(el.dateStart['seconds']))
        );
        todosOfToday = filters;
        this.todosFilter = true;
      } else {
        todosOfToday = [];
        this.todosFilter = true;
      }
    });

    this.fortoday.emit(true);
    this.todos$ = of(todosOfToday);
  }

  getTodosPriority(): void {
    let todosPriority: any[] = [];
    this.todoStore.pipe(select(fromTodoSelector.todos)).subscribe((todos) => {
      if (todos.length) {
        const filters = todos.filter((el) => el?.priority && !el?.completed);
        todosPriority = filters;
        this.todosFilter = true;
      } else {
        todosPriority = [];
        this.todosFilter = true;
      }
    });

    this.forpriority.emit(true);
    this.todos$ = of(todosPriority);
  }

  getTodosFutur(): void {
    let todosFutur: any = [];
    this.todoStore.pipe(select(fromTodoSelector.todos)).subscribe((todos) => {
      if (todos?.length) {
        const filter = todos.filter((el): any => {
          const start = fromUnixTime(el.dateStart['seconds']);
          if (isAfter(start, new Date(Date.now())) && !el?.completed) {
            return el;
          }
        });

        todosFutur = filter;
        this.todosFilter = true;
      } else {
        todosFutur = [];
        this.todosFilter = true;
      }
    });

    this.forfutur.emit(true);
    this.todos$ = of(todosFutur);
  }

  getTodosLate(): void {
    let todosFutur: any = [];
    this.todoStore.pipe(select(fromTodoSelector.todos)).subscribe((todos) => {
      if (todos?.length) {
        const filter = todos.filter((el): any => {
          const end = fromUnixTime(el.dateEnd['seconds']);
          if (isAfter(new Date(Date.now()), end) && !el?.completed) {
            return el;
          }
        });

        todosFutur = filter;
        this.todosFilter = true;
      } else {
        todosFutur = [];
        this.todosFilter = true;
      }
    });

    this.forlater.emit(true);
    this.todos$ = of(todosFutur);
  }

  completeAll_TODOS(): void {
    // this.todos$.pipe(takeUntil(this.subscribe)).subscribe((todos): any => {
    //   if (todos?.length) {
    //     const TodoChanges = todos.map((todo) => {
    //       const updateChanges: Update<TodoId> = {
    //         id: todo.id,
    //         changes: todo,
    //       };
    //       return updateChanges;
    //     });
    //     this.todoStore.dispatch(
    //       fromTodoAction.completeTodos({ todos: TodoChanges })
    //     );
    //   } else {
    //     return null;
    //   }
    // });
  }

  deleteAll_TODOS(): void {
    this.todoStore.dispatch(fromTodoAction.deleteTodos());
    this.cancelLoad_TODO();
  }

  cancelLoad_TODO(): void {
    //this.todoStore.dispatch(fromTodoAction.cancelloadTodo());
  }
}
