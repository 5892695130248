<div class="diagnosticContainer">
  <ng-container *ngIf="diagnostic$ | async as diagnostic">
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="center">
      <nz-col nzSpan="12">
        <nz-card [nzTitle]="diagosticPoleEmploiTitle">
          <ng-template #diagosticPoleEmploiTitle>
            <app-header-title
              [icon]="'real_estate_agent'"
              [title]="'Pôle Emploi'"
              [subtitle]="true"
              [isIcon]="true"
              [subtitle]="true"
            ></app-header-title>
          </ng-template>
        </nz-card>
      </nz-col>
      <nz-col nzSpan="12">
        <nz-card [nzTitle]="diagosticProjetProfessionnelTitle"> </nz-card>
      </nz-col>
      <ng-template #diagosticProjetProfessionnelTitle>
        <app-header-title
          [icon]="'business_center'"
          [title]="'Projet professionnel'"
          [subtitle]="true"
          [isIcon]="true"
          [subtitle]="true"
        ></app-header-title>
      </ng-template>
    </nz-row>

    <nz-space nzDirection="vertical" nzSize="small"></nz-space>
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="center">
      <nz-col nzSpan="24">
        <nz-card [nzTitle]="diagosticExperienceTitle"></nz-card>

        <ng-template #diagosticExperienceTitle>
          <app-header-title
            [icon]="'work_history'"
            [title]="'Expériences'"
            [subtitle]="true"
            [isIcon]="true"
            [subtitle]="true"
          ></app-header-title>
        </ng-template>
      </nz-col>
    </nz-row>
  </ng-container>
</div>
