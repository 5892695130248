<div class="submenu">
  <li
    nz-menu-item
    (click)="onSelect('contact_details')"
    nz-tooltip
    nzTooltipTitle="Profil"
    nzTooltipPlacement="right"
    [nzTooltipMouseEnterDelay]="tooltipDelay"
    class="active-subrouter"
  >
    <mat-icon>person</mat-icon>
    <span *ngIf="!collapse">Profil</span>
  </li>

  <li
    nz-menu-item
    (click)="onSelect('contact_entites')"
    nz-tooltip
    nzTooltipTitle="Entités"
    nzTooltipPlacement="right"
    [nzTooltipMouseEnterDelay]="tooltipDelay"
    class="active-subrouter"
  >
    <mat-icon>domaine</mat-icon>
    <span *ngIf="!collapse">Entités</span>
  </li>

  <li
    nz-menu-item
    (click)="onSelect('contact_metiers')"
    nz-tooltip
    nzTooltipTitle="Métiers"
    nzTooltipPlacement="right"
    [nzTooltipMouseEnterDelay]="tooltipDelay"
    class="active-subrouter"
  >
    <mat-icon>business_center</mat-icon>
    <span *ngIf="!collapse">Métiers</span>
  </li>

  <li
    nz-menu-item
    (click)="onSelect('contact_suivis')"
    nz-tooltip
    nzTooltipTitle="Suivis"
    nzTooltipPlacement="right"
    [nzTooltipMouseEnterDelay]="tooltipDelay"
    class="active-subrouter"
  >
    <mat-icon>article</mat-icon>
    <span *ngIf="!collapse">Suivis</span>
  </li>

  <li
    nz-menu-item
    (click)="onSelect('contact_documents')"
    nz-tooltip
    nzTooltipTitle="Documents"
    nzTooltipPlacement="right"
    [nzTooltipMouseEnterDelay]="tooltipDelay"
    class="active-subrouter"
  >
    <mat-icon>insert_drive_file</mat-icon>

    <span *ngIf="!collapse">Documents</span>
  </li>

  <li
    nz-menu-item
    (click)="onSelect('contact_projets')"
    nz-tooltip
    nzTooltipTitle="Projets"
    nzTooltipPlacement="right"
    [nzTooltipMouseEnterDelay]="tooltipDelay"
    class="active-subrouter"
  >
    <mat-icon> analytics </mat-icon>
    <span *ngIf="!collapse">Projets</span>
  </li>
</div>
