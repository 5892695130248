<nz-card
  [nzTitle]="todoHeaderCard"
  [nzActions]="[
    actionDone,
    actionTest,
    actionVerifed,
    actionAddUsers,
    actionDelete
  ]"
>
  <nz-content>
    <div *ngIf="task.texts | size">
      <nz-list
        nzSize="small"
        *ngFor="let text of task.texts"
        nzItemLayout="vertical"
      >
        <nz-list-item nzNoFlex class="element-text">
          <i
            nz-icon
            nzType="check-circle"
            *ngIf="text.status"
            class="puce__text"
          ></i>
          <span
            *ngIf="!text.status"
            (click)="$event.stopPropagation(); onChangeStatus(task, text)"
            >{{ text.value }}</span
          >
          <del
            *ngIf="text.status"
            (click)="$event.stopPropagation(); onChangeStatus(task, text)"
            >{{ text.value }}</del
          >
          <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
        </nz-list-item>
      </nz-list>
    </div>
  </nz-content>
</nz-card>

<ng-template #todoHeaderCard>
  <nz-row nzAlign="middle" nzAlign="middle" nzJustify="start">
    <nz-col nzSpan="2">
      <app-avatar-group [source]="[task?.auteur]"></app-avatar-group>
    </nz-col>
    <nz-col nzSpan="2"> </nz-col>

    <nz-col nzSpan="8">
      <small nz-typography>{{ task?.dateStart | dateFr: "small" }}</small>
      <br />
      <small nz-typography>{{ task?.dateEnd | dateFr: "small" }}</small>
    </nz-col>

    <nz-col nzSpan="2">
      <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
    </nz-col>
    <nz-col nzSpan="10">
      <nz-avatar-group *ngIf="task.users | size">
        <nz-avatar
          *ngFor="let item of task.users"
          nz-col
          [nzSrc]="
            item?.user?.photo ? item.user.photo : item?.photo ? item.photo : ''
          "
          [nzText]="
            item?.user?.photo || item?.photo
              ? ''
              : item?.user?.avatar
              ? item.user.avatar
              : item?.avatar
              ? item.avatar
              : ''
          "
          nzSize="small"
          nz-popover
          [nzPopoverTitle]="
            (item?.user?.displayName | titlecase) ||
            (item?.displayName | titlecase)
          "
          [nzPopoverContent]="popoverUserTemplate"
          nzPopoverPlacement="bottom"
          nzPopoverTrigger="click"
          [ngStyle]="{
            'background-color': item.user?.setting?.color
              ? item.user.setting?.color
              : item?.setting?.color
              ? item.setting?.color
              : default
          }"
        >
          <ng-template #popoverUserTemplate>
            <button
              *ngIf="user.id === task.auteur.id"
              nz-button
              nzSize="small"
              nzDanger
              nzBlock
              (click)="removeUser(item, task)"
            >
              <span>Retirer</span>
              <nz-space nzDirection="horizontal" nzSize="large"></nz-space>

              <i nz-icon nzType="user-delete"></i>
            </button>
          </ng-template>
        </nz-avatar>
      </nz-avatar-group>
    </nz-col>
  </nz-row>
  <nz-space nzDirection="vertical" nzSize="small"></nz-space>
  <nz-row nzAlign="top" nzAlign="middle" nzJustify="start">
    <nz-col nzSpan="24">
      <strong nz-typography class="task_item_title">{{ task?.title }}</strong>
    </nz-col>
  </nz-row>
</ng-template>

<ng-template #actionDone>
  <i
    nz-icon
    nzType="check-circle"
    [ngStyle]="{ color: task.completed ? '#73d13d' : 'grey' }"
    (click)="onCompleted(task)"
    nz-tooltip
    nzTooltipPlacement="bottomRight"
    [nzTooltipTitle]="!task.completed ? 'Terminée' : ''"
    [nzTooltipMouseEnterDelay]="mouseEnterDelay"
  ></i>
</ng-template>
<ng-template #actionTest>
  <i
    nz-icon
    nzType="field-time"
    [ngStyle]="{ color: task.inprogress ? '#73d13d' : '#ff4d4f' }"
    (click)="onInProgress(task)"
    nz-tooltip
    nzTooltipPlacement="bottomRight"
    [nzTooltipTitle]="!task.inprogress ? 'En cours' : ''"
    [nzTooltipMouseEnterDelay]="mouseEnterDelay"
  ></i>
</ng-template>
<ng-template #actionVerifed>
  <i
    nz-icon
    nzType="safety"
    [ngStyle]="{ color: task.verifed ? '#73d13d' : '#ff4d4f' }"
    (click)="onVerified(task)"
    nz-tooltip
    nzTooltipPlacement="bottomRight"
    [nzTooltipTitle]="!task.verifed ? 'Vérifier' : ''"
    [nzTooltipMouseEnterDelay]="mouseEnterDelay"
  ></i>
</ng-template>
<ng-template #actionAddUsers>
  <i
    nz-icon
    nzType="user-add"
    nz-popover
    nzPopoverTitle="Partager"
    [nzPopoverContent]="
      userSelectorRef ? userSelectorRef : popoverAddUserTemplate
    "
    nzPopoverPlacement="bottom"
    nzPopoverTrigger="click"
    *ngIf="user.id === task.auteur.id && !task.completed"
  ></i>
</ng-template>
<ng-template #actionDelete>
  <i nz-icon nzType="delete" (click)="onDelete(task)"></i>
</ng-template>

<ng-template #popoverAddUserTemplate>
  <div class="teamUserContainer">
    <app-user-list-selector
      [type$]="usersType$"
      (select)="addUser($event, task)"
    ></app-user-list-selector>
  </div>
</ng-template>
