import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { OrganisationState } from 'src/app/components/organisation/store/organisation.reducer';
import * as fromOrganisationSelector from 'src/app/components/organisation/store/organisation.selectors';

@Injectable({
  providedIn: 'any',
})
export class OrganisationTitleResolverService implements Resolve<string> {
  private denomination: string = '';

  constructor(private organisationStore: Store<OrganisationState>) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): string {
    this.organisationStore
      .select(fromOrganisationSelector.organisation)
      .pipe(
        map(
          (organisation) =>
            (this.denomination = `${organisation?.denomination.substring(
              0,
              10
            )}`)
        )
      );

    return this.denomination;
  }
}
