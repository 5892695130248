<nz-button-group>
  <button
    nz-button
    *ngFor="let item of buttons"
    nzType="text"
    (click)="onSelect($event, item.name)"
  >
    <h5 nz-typography [ngClass]="{ dark: theme === 'dark' }">
      {{ item.name }}
    </h5>
  </button>
</nz-button-group>
