import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ContratState, contratsFeatureKey, selectAll } from './contrat.reducer';

export const selectContratState =
  createFeatureSelector<ContratState>(contratsFeatureKey);

export const contrats = createSelector(selectContratState, selectAll);

export const loading = createSelector(
  selectContratState,
  (state: ContratState) => state.loading
);
export const types = createSelector(
  selectContratState,
  (state: ContratState) => state.types
);

export const natures = createSelector(
  selectContratState,
  (state: ContratState) => state.natures
);

export const experiences = createSelector(
  selectContratState,
  (state: ContratState) => state.experiences
);

export const salaires = createSelector(
  selectContratState,
  (state: ContratState) => state.salaires
);

export const permis = createSelector(
  selectContratState,
  (state: ContratState) => state.permis
);

export const numerique = createSelector(
  selectContratState,
  (state: ContratState) => state.numerique
);

export const complement = createSelector(
  selectContratState,
  (state: ContratState) => state.complement
);

export const horaires = createSelector(
  selectContratState,
  (state: ContratState) => state.horaires
);

export const mobilities = createSelector(
  selectContratState,
  (state: ContratState) => state.mobilities
);

export const jours = createSelector(
  selectContratState,
  (state: ContratState) => state.jours
);

export const evolutions = createSelector(
  selectContratState,
  (state: ContratState) => state.evolutions
);
