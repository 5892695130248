import { ParticipantService } from './participant.service';
import { Update } from '@ngrx/entity';
import { Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  doc,
  docSnapshots,
  collectionChanges,
  getDoc,
  addDoc,
  setDoc,
  deleteDoc,
  serverTimestamp,
  DocumentData,
  Timestamp,
  query,
  where,
  arrayUnion,
  arrayRemove,
  collectionSnapshots,
  CollectionReference,
  QueryDocumentSnapshot,
  DocumentChange,
  DocumentReference,
  getDocs,
  documentId,
} from '@angular/fire/firestore';
import { Observable, of, from, EMPTY } from 'rxjs';
import { switchMap, mergeMap, map, catchError } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { UserState } from '../components/user/store/user.reducer';
import * as fromUserSelector from '../components/user/store/user.selectors';
import { format, fromUnixTime, add } from 'date-fns';
import { ParticipantState } from './../components/participant/store/participant.reducer';
import * as fromParticipantAction from './../components/participant/store/participant.actions';
import * as fromParticipantSelector from './../components/participant/store/participant.selectors';

import {
  ParticipantId,
  ParticipantSmallId,
} from './../components/participant/store/participant.model';
import { OperationSmallId } from './../components/operation/store/operation.model';

@Injectable({
  providedIn: 'any',
})
export class ParticipantOperationsService {
  today = new Date(Date.now());
  constructor(
    private db: Firestore,
    private fns: Functions,
    private userStore: Store<UserState>,
    private participantStore: Store<ParticipantState>,
    private participantService: ParticipantService
  ) {}

  getAll(participantId: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !participantId) {
            return of([]);
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/participants/${participantId}/operations`
            );

            const changes = collectionSnapshots(dbCollection).pipe(
              map((changes) => {
                return changes.map((snap) => {
                  const data = snap.data();
                  const id = snap.id;
                  return { id, ...data };
                });
              })
            );

            return changes;
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  getOne(participantId: string, id: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !participantId) {
            return of(null);
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/participants/${participantId}/operations`
            );

            const documentRef = doc(dbCollection, id);
            return from(
              getDoc(documentRef)
                .then((snap) => {
                  const data = snap.data();
                  const id = snap.id;
                  return { id, ...data };
                })
                .catch((error) => error)
            ).pipe(mergeMap((res: any) => of(res)));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  addOne(
    participant: ParticipantId,
    operation: OperationSmallId,
    duration?: number
  ): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !participant || !operation) {
            return of(null);
          } else {
            const participantCollection = doc(
              this.db,
              `organisations/${user.organisation.id}/participants/${participant.id}/operations/${operation.id}`
            );

            const intervention = {
              role: 'participant',
              isInscription: true,
              isParticipant: false,
              isSortie: false,
              isDesinscription: false,
              isInMission: false,
              isParrain: false,
              isFinanceur: false,
              isPrescripteur: false,
              isPilotage: false,
              isSupport: false,
              isTitulaire: false,
              isSousTraitant: false,
              isCoTraitant: false,
              isFinished: false,
              isAutre: false,
              autrePrecision: null,
              missions: [],
              modules: [],
              actions: [],
              projetpro: [],
              participants: [],
              titulaire: null,
              dateEndIntervention: add(this.today, {
                months: 6,
              }),
              dateStartIntervention: serverTimestamp(),
              isAssistance: false,
              isControl: false,
              hoursBenevolat: 0,
              durationIntervention: 0,
              dateInscription: serverTimestamp(),
              dateIntegration: serverTimestamp(),
              dateSortie: add(this.today, {
                months: 6,
              }),
              dateUpdate: serverTimestamp(),
              duration: 6,
              motifSorties: null,
              typeSorties: null,
              yearString: new Date(Date.now()).getFullYear().toString(),
              monthString: new Date(Date.now()).getMonth().toString(),
              yearNumber: new Date(Date.now()).getFullYear(),
              monthNumber: new Date(Date.now()).getMonth(),
              sortiProgrammeQualitatif: null,
              userUpdate: user,
              auteur: user,
            };

            const newParticipant = {
              operation: { ...operation },
              intervention: { ...intervention },
            };

            return from(
              setDoc(participantCollection, {
                ...newParticipant,
              })
                .then(() => of(`Projet ajoutée`))
                .catch((error) => error)
            ).pipe(mergeMap((res) => res));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  updateOne(
    participant: ParticipantId,
    operation: Partial<any>
  ): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !participant || !operation) {
            return of(null);
          } else {
            const participantDocument = doc(
              this.db,
              `organisations/${user.organisation.id}/participants/${participant.id}/operations/${operation.id}`
            );

            return from(
              setDoc(
                participantDocument,
                {
                  intervention: { ...operation.changes.intervention },
                  userUpdate: user,
                },
                { merge: true }
              )
                .then(() => of(`Intervention modifiée`))
                .catch((error) => of(error))
            ).pipe(mergeMap((res) => res));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  deleteOne(
    participant: ParticipantId,
    operation: OperationSmallId
  ): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !participant || !operation) {
            return of(null);
          } else {
            const participantDocument = doc(
              this.db,
              `organisations/${user.organisation.id}/participants/${participant.id}/operations/${operation.id}`
            );

            return from(
              deleteDoc(participantDocument)
                .then(() => of(`Projet retirée`))
                .catch((error) => of(error))
            ).pipe(mergeMap((res) => res));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }
}
