import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'any',
})
export class QueryGeoTerritoireRegionsServiceGQL extends Query<Response> {
  document = gql`
    query GeoRegions {
      geoRegions {
        code
        nom
      }
    }
  `;
}
