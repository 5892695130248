<nz-card [nzTitle]="contratCalendar" nzBorderless>
  <ng-template #contratCalendar>
    <app-header-title
      [icon]="'date_range'"
      [title]="''"
      [subtitle]="true"
    ></app-header-title>
  </ng-template>
  <form nz-form [formGroup]="contratForm" class="space-y-12 p-4">
    <div class="w-full flex items-start mb-6">
      <div class="w-6/12 mr-2">
        <label class="label required"
          >Dates de démarrage et fin de mission</label
        >
        <div class="numberForm">
          <nz-range-picker
            [(ngModel)]="dates"
            name="dates"
            class="w-full"
            nzBorderless
            [nzFormat]="dateFormat"
            [ngModelOptions]="{ standalone: true }"
            (ngModelChange)="onDateRange($event)"
            [nzAllowClear]="false"
          >
          </nz-range-picker>
        </div>
      </div>
      <div class="w-6/12">
        <label
          class="label required"
          nz-tooltip
          [nzTooltipTitle]="souplesseTitle"
          >Souplesse démarrage de mission</label
        >
        <div class="numberForm">
          <nz-range-picker
            [(ngModel)]="datesSouplesseModel"
            class="w-full"
            nzBorderless
            [nzFormat]="dateFormat"
            [ngModelOptions]="{ standalone: true }"
            [nzAllowClear]="false"
            (ngModelChange)="onDateSouplesseRange($event)"
          >
          </nz-range-picker>
        </div>
      </div>
    </div>

    <div class="w-full flex items-start mb-4">
      <div class="w-6/12 mr-2">
        <nz-card nzSize="small" nzTitle="Horaire avant midi" class="rounded-md">
          <div class="w-full flex items-start">
            <div class="w-5/12 flex flex-col">
              <label class="label required">Prise de poste</label>

              <div class="numberForm">
                <nz-time-picker
                  class="w-full"
                  [(ngModel)]="timeStartAM"
                  [nzDefaultOpenValue]="timeDefaultOpenValue"
                  [ngModelOptions]="{ standalone: true }"
                  nzBorderless
                  [nzFormat]="'HH:mm'"
                  nzPlaceHolder="En heure"
                  [nzDisabledHours]="disabledHoursPM"
                  (ngModelChange)="onTimeStartAM($event)"
                  [nzAllowEmpty]="false"
                  [nzNowText]="''"
                ></nz-time-picker>
              </div>
            </div>
            <div class="w-2/12 ml-4">
              <label class="label"></label>
              <mat-icon class="text-slate-400">arrow_right_alt</mat-icon>
            </div>
            <div class="w-5/12">
              <label class="label required">Fin de matinée</label>

              <div class="numberForm">
                <nz-time-picker
                  class="w-full"
                  [(ngModel)]="timeEndAM"
                  [nzDefaultOpenValue]="timeDefaultOpenValue"
                  [ngModelOptions]="{ standalone: true }"
                  nzBorderless
                  [nzFormat]="'HH:mm'"
                  nzPlaceHolder="En heure"
                  [nzDisabledHours]="disabledHoursPM"
                  [nzAllowEmpty]="false"
                  (ngModelChange)="onTimeEndAM($event)"
                  [nzNowText]="''"
                ></nz-time-picker>
              </div>
            </div>
          </div>
        </nz-card>
      </div>
      <div class="w-6/12">
        <nz-card nzSize="small" nzTitle="Horaire Après-midi" class="rounded-md">
          <div class="w-full flex items-start">
            <div class="w-5/12">
              <label class="label required">Reprise de poste</label>

              <div class="numberForm">
                <nz-time-picker
                  class="w-full"
                  [(ngModel)]="timeStartPM"
                  [nzDefaultOpenValue]="timeDefaultOpenValue"
                  [ngModelOptions]="{ standalone: true }"
                  nzBorderless
                  [nzFormat]="'HH:mm'"
                  nzPlaceHolder="En heure"
                  [nzAllowEmpty]="false"
                  [nzDisabledHours]="disabledHoursAM"
                  (ngModelChange)="onTimeStartPM($event)"
                  [nzNowText]="''"
                ></nz-time-picker>
              </div>
            </div>
            <div class="w-2/12 ml-4">
              <mat-icon class="text-slate-400">arrow_right_alt</mat-icon>
            </div>
            <div class="w-5/12">
              <label class="label required">Fin de journée</label>

              <div class="numberForm">
                <nz-time-picker
                  class="w-full"
                  [(ngModel)]="timeEndPM"
                  [nzDefaultOpenValue]="timeDefaultOpenValue"
                  [ngModelOptions]="{ standalone: true }"
                  nzBorderless
                  [nzFormat]="'HH:mm'"
                  nzPlaceHolder="En heure"
                  [nzAllowEmpty]="false"
                  [nzNowText]="''"
                  [nzDisabledHours]="disabledHoursAM"
                  (ngModelChange)="onTimeEndPM($event)"
                ></nz-time-picker>
              </div>
            </div>
          </div>
        </nz-card>
      </div>
    </div>

    <nz-space nzDirection="vertical" nzSize="small"></nz-space>

    <div class="w-full flex items-start mb-6 space-x-2">
      <div class="w-1/4">
        <label class="label required">Jours travaillés</label>
        <div class="select-menu">
          <nz-select
            nzMode="tags"
            [nzMaxTagCount]="1"
            formControlName="work_days"
            nzBorderless
            nzPlaceHolder="Jour de semaine"
          >
            <nz-option
              *ngFor="let jour of contrat_days$ | async"
              [nzLabel]="jour"
              [nzValue]="jour"
            ></nz-option>
          </nz-select>
        </div>
      </div>

      <div class="w-1/4">
        <label class="label required">Durée hebdomadaire</label>
        <div class="numberForm">
          <nz-input-number
            formControlName="duree_hebdomadaire"
            nzPlaceHolder="En heures"
            class="numberField"
            [nzMin]="2"
          ></nz-input-number>
        </div>
      </div>

      <div class="w-1/4">
        <label
          class="label required"
          nzTooltipTitle="Durée de la pause déjeuner en heure"
          >Pause déjeuner</label
        >
        <div class="numberForm">
          <nz-input-number
            formControlName="pause_dejeuner"
            nzPlaceHolder="En heures"
            class="numberField"
            [nzMin]="0"
          ></nz-input-number>
        </div>
      </div>

      <div class="w-1/4">
        <label class="label required">Période d'essai</label>

        <div class="numberForm">
          <nz-input-number
            formControlName="periodeDessaie"
            nzPlaceHolder="En jour"
            class="numberField"
            [nzMin]="0"
          ></nz-input-number>
        </div>
      </div>
    </div>

    <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>

    <div class="w-full mb-4">
      <div class="w-full flex items-center justify-between whitespace-nowrap">
        <label class="label">Accueil et suivi interne</label>

        <svg
          class="iconSvg"
          nz-tooltip
          nzTooltipTitle="Qui encadre le salarié et effectue le suivi en emploi ?"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 30 30"
          stroke-width="1.5"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
          />
        </svg>
      </div>

      <div class="w-full">
        <input
          class="inputForm"
          formControlName="suiviInterne"
          nzBorderless
          placeholder="Qui accueil et encadre ?"
        />
      </div>
    </div>

    <div class="w-full">
      <label class="label">Observation</label>
      <div class="w-full">
        <textarea
          class="textareaForm"
          formControlName="observationTravail"
          placeholder="Observation lié au temps de travail"
        ></textarea>
      </div>
    </div>
  </form>
</nz-card>
