import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-missions-form-item',
  templateUrl: './missions-form-item.component.html',
  styleUrls: ['./missions-form-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MissionsFormItemComponent implements OnInit, OnChanges {
  @Input() mission: any;
  @Input() isAdd: boolean = false;
  @Output() cleanMetier = new EventEmitter();
  @Output() cleanContrat = new EventEmitter();
  @Output() back = new EventEmitter();

  @Output() validate = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}
  onCleanMetier(): void {
    this.cleanMetier.emit(true);
  }

  onBackToEtablissement(): void {
    this.back.emit(true);
  }

  onCleanContrat(): void {
    this.cleanContrat.emit(true);
  }

  onValidate(): void {
    this.validate.emit(true);
  }
}
