import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { v4 as uuidV4 } from 'uuid';
import { isEmpty } from 'lodash';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { UtilitiesState } from 'src/app/contents/components/utilities/store/utilities.reducer';
import { Store } from '@ngrx/store';
import * as fromUtilitiesAction from 'src/app/contents/components/utilities/store/utilities.actions';

@Component({
  selector: 'app-candidature-form-person',
  templateUrl: './candidature-form-person.component.html',
  styleUrls: ['./candidature-form-person.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CandidatureFormPersonComponent
  implements OnInit, OnChanges, OnDestroy
{
  subscribe = new Subject();
  step: number = 0;
  currentPersonIdentity$ = new BehaviorSubject<any>(null);
  currentPersonDiagnostic$ = new BehaviorSubject<any>(null);
  currentPersonPresentation$ = new BehaviorSubject<any>(null);
  today = new Date(Date.now());
  candiaturesInvalid: boolean = false;

  // FORM FIELDS
  etablissementUniteLegaleForm = new FormGroup({
    statutDiffusionUniteLegale: new FormControl(''),
    unitePurgeeUniteLegale: new FormControl(''),
    dateCreationUniteLegale: new FormControl(''),
    sigleUniteLegale: new FormControl(''),
    sexeUniteLegale: new FormControl(''),
    prenom1UniteLegale: new FormControl(''),
    prenom2UniteLegale: new FormControl(''),
    prenom3UniteLegale: new FormControl(''),
    prenom4UniteLegale: new FormControl(''),
    prenomUsuelUniteLegale: new FormControl(''),
    pseudonymeUniteLegale: new FormControl(''),
    identifiantAssociationUniteLegale: new FormControl(''),
    trancheEffectifsUniteLegale: new FormControl(''),
    anneeEffectifsUniteLegale: new FormControl(''),
    dateDernierTraitementUniteLegale: new FormControl(''),
    nombrePeriodesUniteLegale: new FormControl(0),
    categorieEntreprise: new FormControl(''),
    anneeCategorieEntreprise: new FormControl(''),
    etatAdministratifUniteLegale: new FormControl(''),
    nomUniteLegale: new FormControl(''),
    denominationUniteLegale: new FormControl(''),
    denominationUsuelle1UniteLegale: new FormControl(''),
    denominationUsuelle2UniteLegale: new FormControl(''),
    denominationUsuelle3UniteLegale: new FormControl(''),
    activitePrincipaleUniteLegale: new FormControl(''),
    categorieJuridiqueUniteLegale: new FormControl(''),
    nicSiegeUniteLegale: new FormControl(''),
    nomenclatureActivitePrincipaleUniteLegale: new FormControl(''),
    nomUsageUniteLegale: new FormControl(''),
    economieSocialeSolidaireUniteLegale: new FormControl(''),
    societeMissionUniteLegale: new FormControl(''),
    caractereEmployeurUniteLegale: new FormControl(''),
    activite_principale_libelle: new FormGroup({
      intitule_naf: new FormControl(''),
      intitule_naf_40: new FormControl(''),
      intitule_naf_65: new FormControl(''),
      code_naf: new FormControl(''),
    }),
    categorie_juridique_libelle: new FormGroup({
      libelle: new FormControl(''),
      code: new FormControl(''),
    }),
  });
  etablissementAdresseCommuneForm = new FormGroup({
    code: new FormControl(''),
    nom: new FormControl(''),
    codesPostaux: new FormControl([]),
    siren: new FormControl(''),
    codeEpci: new FormControl(''),
    codeDepartement: new FormControl(''),
    codeRegion: new FormControl(''),
    epci: new FormGroup({
      code: new FormControl(''),
      nom: new FormControl(''),
      type: new FormControl(''),
      financement: new FormControl(''),
      codesDepartements: new FormControl(['']),
      codesRegions: new FormControl(['']),
      population: new FormControl(0),
      surface: new FormControl(0),
      centre: new FormControl(null),
      contour: new FormControl(null),
      bbox: new FormControl(null),
    }),
    departement: new FormGroup({
      code: new FormControl(''),
      nom: new FormControl(''),
      codeRegion: new FormControl(''),
      region: new FormGroup({}),
    }),
    region: new FormGroup({
      code: new FormControl(''),
      nom: new FormControl(''),
    }),
    population: new FormControl(0),
    surface: new FormControl(0),
    centre: new FormControl(null),
    contour: new FormControl(null),
    mairie: new FormControl(null),
    bbox: new FormControl(null),
  });
  etablissementAdresseForm = new FormGroup({
    complementAdresseEtablissement: new FormControl(''),
    numeroVoieEtablissement: new FormControl(''),
    indiceRepetitionEtablissement: new FormControl(''),
    typeVoieEtablissement: new FormControl(''),
    libelleVoieEtablissement: new FormControl(''),
    codePostalEtablissement: new FormControl(''),
    libelleCommuneEtablissement: new FormControl(''),
    libelleCommuneEtrangerEtablissement: new FormControl(''),
    distributionSpecialeEtablissement: new FormControl(''),
    codeCommuneEtablissement: new FormControl(''),
    codeCedexEtablissement: new FormControl(''),
    libelleCedexEtablissement: new FormControl(''),
    codePaysEtrangerEtablissement: new FormControl(''),
    libellePaysEtrangerEtablissement: new FormControl(''),
    fullAdresse: new FormControl(''),
    commune: this.etablissementAdresseCommuneForm,
  });
  candidatures: FormArray = this.fb.array([]);
  candidatureForm: FormGroup = new FormGroup(
    {
      candidats: this.candidatures,
    },
    Validators.required
  );

  @Input() isUpdate: boolean = false;
  @Input() isList: boolean = true;

  @Input() candidatsInput: any[];
  @Output() currentChanges = new EventEmitter();
  @Output() add = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @Output() back = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private utilitiesStore: Store<UtilitiesState>
  ) {}

  ngOnInit(): void {
    this.administratifReferentiels();
    this.onCheckPersonValidation();
    this.onCandidatureChanges();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.onSetCandidats(changes.candidatsInput.currentValue);
  }
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  get candidatureControls() {
    return this.candidatureForm.get('candidats') as FormArray;
  }

  administratifReferentiels(): void {
    this.loadSejourReferentiel();
    this.loadAllocationsReferentiel();
    this.loadCriteresReferentiel();
  }

  //load referentiel sejour
  loadSejourReferentiel(): void {
    this.utilitiesStore.dispatch(fromUtilitiesAction.loadSejourReferentiel());
  }
  //load referentiel allocations

  loadAllocationsReferentiel(): void {
    this.utilitiesStore.dispatch(
      fromUtilitiesAction.loadAllocationReferentiel()
    );
  }

  //load referentiel criteres
  loadCriteresReferentiel(): void {
    this.utilitiesStore.dispatch(fromUtilitiesAction.loadCriteresReferentiel());
  }

  onIndexChanges(step: number, index: number): void {
    this.step = step;

    switch (step) {
      case 0:
        this.onSetCurrentIdentity(index);
        break;
      case 1:
        this.onSetCurrentDiagnostic(index);
        break;
      default:
        break;
    }
  }

  onSetCurrentIdentity(index: number): void {
    const itemFormValue = this.candidatures.at(index)?.value;
    const identity = {
      id: itemFormValue.id,
      civilite: itemFormValue.civilite,
      lastName: itemFormValue.lastName,
      firstName: itemFormValue.firstName,
      fonction: itemFormValue.fonction,
      avatar: itemFormValue.avatar,
      displayName: itemFormValue.displayName,
      role: itemFormValue.role,
      genre: itemFormValue.genre,
      age: itemFormValue.age,
      tranche: itemFormValue.tranche,
      dateNaissance: itemFormValue.dateNaissance,
      coordonnees: itemFormValue.coordonnees,
      adresse: itemFormValue.adresse,
      etablissement: itemFormValue.etablissement,
      diagnostic: itemFormValue.diagnostic,
      setting: itemFormValue.setting,
      isSelected: itemFormValue.isSelected,
      isRejected: itemFormValue.isRejected,
      isInterviewed: itemFormValue.isInterviewed,
      isValided: itemFormValue.isValided,
    };

    this.currentPersonIdentity$.next([identity]);
  }
  onSetCurrentDiagnostic(index: number): void {
    const itemFormValue = this.candidatures.at(index)?.value;
    const administratif = itemFormValue.diagnostic.administratif;

    this.currentPersonDiagnostic$.next(administratif);
  }

  //ajouter un nouveau candidat
  onNewCandidat(): void {
    const candidat = new FormGroup({
      id: new FormControl(uuidV4(), Validators.required),
      civilite: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      firstName: new FormControl('', Validators.required),
      fonction: new FormControl('', Validators.required),
      avatar: new FormControl(''),
      displayName: new FormControl(''),
      role: new FormControl(''),
      genre: new FormControl(''),
      age: new FormControl(''),
      tranche: new FormControl(''),
      dateNaissance: new FormControl(null, Validators.required),
      setting: new FormGroup({
        color: new FormControl(''),
      }),
      isSelected: new FormControl(false),
      isRejected: new FormControl(false),
      isInterviewed: new FormControl(false),
      isValided: new FormControl(false),

      coordonnees: new FormGroup({
        mobile: new FormControl(''),
        phoneNumber: new FormControl(''),
        fixe: new FormControl(''),
        email: new FormControl(''),
      }),
      adresse: new FormGroup({
        adresse: new FormGroup({
          properties: new FormGroup({
            label: new FormControl(''),
            score: new FormControl(0),
            housenumber: new FormControl(''),
            id: new FormControl(''),
            type: new FormControl(''),
            name: new FormControl(''),
            postcode: new FormControl(''),
            citycode: new FormControl(''),
            x: new FormControl(0),
            y: new FormControl(0),
            city: new FormControl(''),
            context: new FormControl(''),
            importance: new FormControl(0.0),
            street: new FormControl(''),
          }),
          geometry: new FormGroup({
            type: new FormControl(''),
            coordinates: new FormControl([0, 0]),
          }),
        }),
        complement: new FormControl(['']),
        qpv: new FormControl(['']),
      }),
      diagnostic: new FormGroup({
        administratif: new FormGroup({
          dateRecrutement: new FormControl<Date | any>(
            this.today,
            Validators.required
          ),
          dateInscription: new FormControl<Date | any>(null),
          dateEntree: new FormControl<Date | any>(null),
          numbCarteSejour: new FormControl(''),
          numCaiCir: new FormControl(''),
          criteres1: new FormControl<{
            justificatif: string;
            label: string;
            value: string;
          }>({ justificatif: '', label: '', value: '' }, Validators.required),
          typeCarteSejour: new FormControl<{
            info: string;
            titre: string;
            type: string;
          }>({ info: '', titre: '', type: '' }),
          criteres2: new FormControl<{ info: string; item: string }[] | any>(
            [],
            Validators.required
          ),
          isPassIae: new FormControl(false, Validators.required),
          isFse: new FormControl(false, Validators.required),
          isFrein: new FormControl<boolean>(false, Validators.required),
          dateStartPassIae: new FormControl<Date | any>(null),
          dateEndPassIae: new FormControl<Date | any>(null),
        }),
        presentation: new FormControl(''),
      }),
      etablissement: new FormGroup({
        siren: new FormControl(''),
        nic: new FormControl(''),
        siret: new FormControl(''),
        statutDiffusionEtablissement: new FormControl(''),
        dateCreationEtablissement: new FormControl(''),
        trancheEffectifsEtablissement: new FormControl(''),
        anneeEffectifsEtablissement: new FormControl(''),
        activitePrincipaleRegistreMetiersEtablissement: new FormControl(''),
        dateDernierTraitementEtablissement: new FormControl(''),
        etablissementSiege: new FormControl(false),
        nombrePeriodesEtablissement: new FormControl(0),
        uniteLegale: this.etablissementUniteLegaleForm,
        adresseEtablissement: this.etablissementAdresseForm,
        adresse2Etablissement: this.etablissementAdresseForm,
        periodesEtablissement: new FormGroup([]),
        conventionsCollectives: new FormGroup({
          activitePrincipale: new FormControl(''),
          activitePrincipaleUniteLegale: new FormControl(''),
          caractereEmployeurUniteLegale: new FormControl(''),
          categorieJuridiqueUniteLegale: new FormControl(''),
          conventions: new FormControl([]),
          dateCreationUniteLegale: new FormControl(''),
          dateDebut: new FormControl(''),
          etablissements: new FormControl(0),
          etatAdministratifUniteLegale: new FormControl(''),
          highlightLabel: new FormControl(''),
          label: new FormControl(''),
          matching: new FormControl(0),
          simpleLabel: new FormControl(''),
          siren: new FormControl(''),
          activitePrincipaleEtablissement: new FormControl(''),
          address: new FormControl(''),
          categorieEntreprise: new FormControl(''),
          codeCommuneEtablissement: new FormControl(''),
          codePostalEtablissement: new FormControl(''),
          etablissementSiege: new FormControl(false),
          etatAdministratifEtablissement: new FormControl(''),
          idccs: new FormControl([]),
          libelleCommuneEtablissement: new FormControl(''),
          siret: new FormControl(''),
        }),
      }),
    });

    this.step = 0;
    this.candidatures.insert(0, candidat);
    this.currentPersonIdentity$.next(null);
    this.currentPersonDiagnostic$.next(null);
  }

  //ajouter des candidats existants
  onSetCandidats(candidats: any): void {
    if (!candidats || !candidats.length) return;

    candidats.forEach((candidat: any) => this.onSetCandidat(candidat));
    this.onSetCurrentIdentity(0);
  }
  //ajouter un candidat existant
  onSetCandidat(item: any): void {
    if (item) return;

    const candidat = new FormGroup({
      id: new FormControl(item.id, Validators.required),
      civilite: new FormControl(item.civilite, Validators.required),
      lastName: new FormControl(item.lastName, Validators.required),
      firstName: new FormControl(item.firstName, Validators.required),
      fonction: new FormControl(item.fonction, Validators.required),
      role: new FormControl(item.role),
      genre: new FormControl(item.genre),
      age: new FormControl(item.age),
      tranche: new FormControl(item.tranche),
      dateNaissance: new FormControl(item.dateNaissance, Validators.required),
      coordonnees: new FormGroup({
        mobile: new FormControl(item.coordonnees.mobile),
        phoneNumber: new FormControl(item.coordonnees.phoneNumber),
        fixe: new FormControl(item.coordonnees.fixe),
        email: new FormControl(item.coordonnees.email),
      }),
      adresse: new FormGroup({
        adresse: new FormGroup({
          properties: new FormGroup({
            label: new FormControl(item.adresse.adresse.properties.label),
            score: new FormControl(item.adresse.adresse.properties.score),
            housenumber: new FormControl(
              item.adresse.adresse.properties.housenumber
            ),
            id: new FormControl(item.adresse.adresse.properties.id),
            type: new FormControl(item.adresse.adresse.properties.type),
            name: new FormControl(item.adresse.adresse.properties.name),
            postcode: new FormControl(item.adresse.adresse.properties.postcode),
            citycode: new FormControl(item.adresse.adresse.properties.citycode),
            x: new FormControl(item.adresse.adresse.properties.x),
            y: new FormControl(item.adresse.adresse.properties.y),
            city: new FormControl(item.adresse.adresse.properties.city),
            context: new FormControl(item.adresse.adresse.properties.context),
            importance: new FormControl(
              item.adresse.adresse.properties.importance
            ),
            street: new FormControl(item.adresse.adresse.properties.street),
          }),
          geometry: new FormGroup({
            type: new FormControl(item.adresse.adresse.geometry.type),
            coordinates: new FormControl(
              item.adresse.adresse.geometry.coordinates
            ),
          }),
        }),
        complement: new FormControl(item.adresse.complement),
        qpv: new FormControl(item.adresse.qpv),
      }),
      diagnostic: new FormGroup({
        administratif: new FormGroup({
          dateRecrutement: new FormControl<Date | any>(
            item.diagnostic.administratif.dateRecrutement,
            Validators.required
          ),
          dateInscription: new FormControl<Date | any>(
            item.diagnostic.administratif.dateInscription
          ),
          dateEntree: new FormControl<Date | any>(
            item.diagnostic.administratif.dateEntree
          ),
          numbCarteSejour: new FormControl(
            item.diagnostic.administratif.numbCarteSejour
          ),
          numCaiCir: new FormControl(item.diagnostic.administratif.numCaiCir),
          criteres1: new FormControl<{
            justificatif: string;
            label: string;
            value: string;
          }>(
            {
              justificatif:
                item.diagnostic.administratif.criteres1.justificatif,
              label: item.diagnostic.administratif.criteres1.label,
              value: item.diagnostic.administratif.criteres1.value,
            },
            Validators.required
          ),
          typeCarteSejour: new FormControl<{
            info: string;
            titre: string;
            type: string;
          }>({
            info: item.diagnostic.administratif.typeCarteSejour.info,
            titre: item.diagnostic.administratif.typeCarteSejour.titre,
            type: item.diagnostic.administratif.typeCarteSejour.type,
          }),
          criteres2: new FormControl<{ info: string; item: string }[] | any>(
            item.diagnostic.administratif.criteres2 || [],
            Validators.required
          ),
          isPassIae: new FormControl(
            item.diagnostic.administratif.isPassIae,
            Validators.required
          ),
          isFse: new FormControl(
            item.diagnostic.administratif.isFse,
            Validators.required
          ),
          isFrein: new FormControl<boolean>(
            item.diagnostic.administratif.isFrein,
            Validators.required
          ),
          dateStartPassIae: new FormControl<Date | any>(
            item.diagnostic.administratif.dateStartPassIae
          ),
          dateEndPassIae: new FormControl<Date | any>(
            item.diagnostic.administratif.dateEndPassIae
          ),
        }),
        presentation: new FormControl(item.diagnostic.presentation),
      }),
      etablissement: new FormGroup({ ...item.etablissement }),
      setting: new FormGroup({
        color: new FormControl(item.setting.color),
      }),
    });

    this.step = 0;
    this.candidatures.insert(0, candidat);
  }

  //supprimer un candidat
  onRemoveCandidat(index: number): void {
    this.candidatures.removeAt(index);
  }

  //afficher les données un candidat depuis la navigation
  onShowCandidat(index: number): void {
    if (index === 0) {
      return;
    }
    const item = this.candidatures.at(index);
    this.candidatures.removeAt(index);
    this.candidatures.insert(0, item);
    this.step = 0;
    this.onIndexChanges(0, 0);
  }

  //supprimer un candidat ajouté
  onRemoveSelectedCandidat(candidat: any): void {
    const candidats: any[] = this.candidatureForm.get('candidats')?.value;
    const nextCandidats: any[] = candidats.filter(
      (el: any) => el.id !== candidat.id
    );
    this.candidatureForm.setValue({ candidats: nextCandidats });
  }

  //actualiser l'identité du candidat sélectionné
  onPersonIdentityChanges(person: any, index: number): void {
    this.candidatures.at(index).patchValue({ ...person });
  }

  //actualiser le diagnostic du candidat sélectionné
  onPersonDiagnosticChanges(diagAdministratif: any, index: number): void {
    this.candidatures.at(index).patchValue({
      diagnostic: {
        administratif: diagAdministratif,
      },
    });
  }

  //vérifier les données renseignées
  onCheckPersonValidation(): void {
    this.candidatures.valueChanges
      .pipe(takeUntil(this.subscribe))
      .subscribe((persons: any[]) => {
        const invalidPersons = persons.filter(
          (person: any) => !this.onCheckPerson(person)
        );

        this.candiaturesInvalid = invalidPersons?.length ? true : false;
      });
  }

  //valider les données renseignées par candidat
  onCheckPerson(person: any): boolean {
    const checkPerson: boolean =
      isEmpty(person.lastName) ||
      isEmpty(person.firstName) ||
      !person.dateNaissance ||
      isEmpty(person.adresse.adresse.properties.label) ||
      isEmpty(person.diagnostic.administratif?.criteres1?.label) ||
      !person.diagnostic.administratif?.criteres2?.length
        ? false
        : true;

    return checkPerson;
  }

  //changements d'informations des candidatures
  onCandidatureChanges(): void {
    this.candidatureForm.valueChanges
      .pipe(takeUntil(this.subscribe))
      .subscribe((values) => {
        const { candidats } = values;
        this.currentChanges.emit(candidats);
      });
  }

  //validation des candidatures
  onValidate(): void {
    const candidats = this.candidatureForm.get('candidats')?.value;

    this.add.emit(candidats);
  }

  //revenir à la saisie du prescripteur
  onStepBack(): void {
    this.back.emit(true);
  }

  //annuler l'ajouter de nouveaux candidats
  onCancel(): void {
    this.cancel.emit(true);
  }
}
