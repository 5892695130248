import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { fromUnixTime } from 'date-fns';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { v4 as uuidV4 } from 'uuid';

@Component({
  selector: 'app-operation-cadrage-livrable-form',
  templateUrl: './operation-cadrage-livrable-form.component.html',
  styleUrls: ['./operation-cadrage-livrable-form.component.scss'],
})
export class OperationCadrageLivrableFormComponent
  implements OnInit, OnDestroy
{
  subscribe = new Subject();
  isAdd: boolean = true;
  isUpdate: boolean = false;
  today: Date = new Date(Date.now());

  @Input() updateItem = new BehaviorSubject<any>(null);
  @Output() add = new EventEmitter<any>(false);
  @Output() update = new EventEmitter<any>(false);
  @Output() cancel = new EventEmitter<boolean>(false);

  livrableForm: UntypedFormGroup = this.fb.group({});
  title: UntypedFormControl = new UntypedFormControl('', Validators.required);
  description: UntypedFormControl = new UntypedFormControl();
  pilote: UntypedFormControl = new UntypedFormControl();
  echeance: UntypedFormControl = new UntypedFormControl(this.today, Validators.required);
  id: UntypedFormControl = new UntypedFormControl(uuidV4(), Validators.required);

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.formCreate();
    this.formSet();
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  formCreate(): void {
    this.livrableForm = this.fb.group({
      id: this.id,
      title: this.title,
      description: this.description,
      pilote: this.pilote,
      echeance: this.echeance,
    });
  }

  formSet(): void {
    this.updateItem.pipe(takeUntil(this.subscribe)).subscribe((val) => {
      if (!val) {
        this.isAdd = true;
        this.isUpdate = false;
      }
      if (val) {
        this.setUpdate(val);
      }
    });
  }

  setUpdate(val: any): void {
    this.isAdd = false;
    this.isUpdate = true;
    this.livrableForm.patchValue({
      id: val?.id,
      title: val.title,
      description: val?.description,
      pilote: val?.pilote,
      echeance: val?.echeance
        ? fromUnixTime(val.echeance['seconds'])
        : new Date(Date.now()),
    });
  }

  onAdd(): void {
    const form = this.livrableForm.value;
    this.add.emit(form);
    this.livrableForm.reset();
  }

  onUpdate(): void {
    const form = this.livrableForm.value;
    this.update.emit(form);
    this.livrableForm.reset();
  }

  onCancel(): void {
    this.cancel.emit(true);
  }
}
