import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Departement, DepartementId } from './departement.model';
import * as DepartementActions from './departement.actions';

export const departementsFeatureKey = 'departements';

export interface DepartementState extends EntityState<any> {
  departement: DepartementId | any;
  list: DepartementId | any;
  departementServices: any;
  users: any;
  loading: boolean;
  error: any;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>();

export const initialState: DepartementState = adapter.getInitialState({
  departement: undefined,
  departementServices: undefined,
  list: undefined,
  users: undefined,
  loading: false,
  error: undefined,
});

export const reducer = createReducer(
  initialState,
  on(DepartementActions.loadingDepartements, (state, action) => {
    return {
      ...state,
      loading: action.loading,
      error: undefined,
    };
  }),

  //LOAD
  on(DepartementActions.loadDepartementsServces, (state, action) => {
    return {
      ...state,
      loading: true,
      error: undefined,
    };
  }),
  on(DepartementActions.loadDepartementsServcesSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      error: undefined,
    };
  }),
  on(DepartementActions.loadDepartementsServcesFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
      loading: false,
    };
  }),

  //LOAD LIST
  on(DepartementActions.loadDepartementsList, (state, action) => {
    return {
      ...state,
      loading: true,
      error: undefined,
    };
  }),
  on(DepartementActions.loadDepartementsListSuccess, (state, action) => {
    return {
      ...state,
      list: action.departements,
      loading: false,
      error: undefined,
    };
  }),
  on(DepartementActions.loadDepartementsListFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
      loading: false,
    };
  }),

  //ADD
  on(DepartementActions.addDepartement, (state, action) => {
    return {
      ...state,
      error: undefined,
      loading: true,
    };
  }),

  on(DepartementActions.addDepartementFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //UPDATE ITEM
  // on(DepartementActions.updateDepartement, (state, action) =>
  //   adapter.updateOne(action.departement, state)
  // ),
  // on(DepartementActions.addDepartementSuccess, (state, action) => {
  //   return {
  //     ...state,
  //     departement: action.departement,
  //   };
  // }),

  on(DepartementActions.addDepartementFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //DELETE ITEM
  on(DepartementActions.deleteDepartement, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(DepartementActions.deleteDepartementSuccess, (state, action) => {
    return {
      ...state,
      departement: undefined,
    };
  }),

  on(DepartementActions.deleteDepartementFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //DELETE ALL
  on(DepartementActions.deleteDepartements, (state, action) =>
    adapter.removeAll(state)
  ),
  on(DepartementActions.deleteDepartementsSuccess, (state, action) => {
    return {
      ...state,
      error: undefined,
      loading: false,
    };
  }),
  on(DepartementActions.deleteDepartementsFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
      loading: false,
    };
  }),

  //LOAD ALL
  on(DepartementActions.loadDepartements, (state, action) => {
    return {
      ...state,
      departement: undefined,
    };
  }),
  on(DepartementActions.loadDepartementsSuccess, (state, action) =>
    adapter.setAll(action.departements, state)
  ),
  on(DepartementActions.loadDepartementsFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //QUERY
  on(DepartementActions.queryDepartementsSuccess, (state, action) =>
    adapter.setAll(action.departements, state)
  ),
  on(DepartementActions.queryDepartementsFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //LOAD ITEM
  on(DepartementActions.loadDepartementSuccess, (state, action) => {
    return {
      ...state,
      departement: action.departement,
    };
  }),
  on(DepartementActions.loadDepartementFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //CLEAR
  on(DepartementActions.clearDepartements, (state) => adapter.removeAll(state)),
  on(DepartementActions.clearDepartement, (state, action) => {
    return {
      ...state,
      departement: undefined,
    };
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
