import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 'app-layout-list-card',
  templateUrl: './layout-list-card.component.html',
  styleUrls: ['./layout-list-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutListCardComponent implements OnInit, OnChanges {
  @Input() extra: TemplateRef<any> | any;
  @Input() title: TemplateRef<any> | any;
  @Input() subtitle: TemplateRef<any> | any;
  @Input() body: TemplateRef<any> | any;
  @Input() footer: TemplateRef<any> | any;
  @Input() spaceSize: number = 400;

  constructor() {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {}
}
