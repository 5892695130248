import { EntiteFiltersComponent } from './../entite-filters/entite-filters.component';
import { PortalNavigationService } from './../../../../portals/portal-navigation.service';
import { EntiteFormComponent } from './../entite-form/entite-form.component';
import { ActivatedRoute, Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable, of } from 'rxjs';
import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
  TemplateRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { EntiteState } from '../../store/entite.reducer';
import * as fromEntiteAction from '../../store/entite.actions';
import * as fromEntiteSelector from '../../store/entite.selectors';
import { ComponentPortal } from '@angular/cdk/portal';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';
import * as fromUserAction from 'src/app/components/user/store/user.actions';

@Component({
  selector: 'app-entite-list',
  templateUrl: './entite-list.component.html',
  styleUrls: ['./entite-list.component.scss'],
})
export class EntiteListComponent implements OnInit, OnDestroy {
  data$: Observable<any> = of(null);
  entites$: Observable<any> = of([]);
  favoris$: Observable<any> = of(null);
  modalWidth: number = 950;
  componentPortalFilter: ComponentPortal<EntiteFiltersComponent> | any;
  title: string = '';
  newCardTitle: string = 'Nouvelle entité';
  newCardDescription: string = 'Ajouter une entité à votre organisation';
  newCardDefinition: string =
    'Une entité est une organisation, une entreprise ou structure collaborant avec votre organisation pour vos projets ou accompagner des participants.';

  @ViewChild('entiteTitleForm', { static: false }) entiteTitleForm:
    | TemplateRef<any>
    | any;
  constructor(
    private entiteStore: Store<EntiteState>,
    private userStore: Store<UserState>,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private activateRouter: ActivatedRoute,
    private router: Router,
    private portalNavigationService: PortalNavigationService
  ) {}

  ngOnInit(): void {
    this.getRouteData();
    this.componentPortalFilter = new ComponentPortal(EntiteFiltersComponent);
    this.setFilterPortal();
    this.loadFavorisEntites();
    this.getFavorisEntites();
    this.getEntite();
  }

  ngOnDestroy(): void {
    this.componentPortalFilter.detach();
  }

  getRouteData(): void {
    this.data$ = this.activateRouter.data;
  }

  getEntite(): void {
    this.entites$ = this.entiteStore.select(fromEntiteSelector.entites);
  }

  loadEntite(): void {
    this.entiteStore.dispatch(fromEntiteAction.loadEntites());
  }

  setFilterPortal(): void {
    this.portalNavigationService.setPortal(this.componentPortalFilter);
  }

  getFavorisEntites(): void {
    this.favoris$ = this.userStore.select(fromUserSelector.favorisEntites);
  }

  loadFavorisEntites(): void {
    this.userStore.dispatch(fromUserAction.loadUserFavorisEntites());
  }

  onNewEntiteForm(): void {
    this.title = 'Nouvelle entité';
    const modal = this.modal.create({
      nzContent: EntiteFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.entiteTitleForm,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });
    const instance = modal.getContentComponent();
    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
  }
}
