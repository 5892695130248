import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Metier } from './metier.model';
import * as MetierActions from './metier.actions';

export const metiersFeatureKey = 'metiers';

export interface MetierState extends EntityState<any> {
  themes: any;
  grandDomaine: any;
  domainesProfessionnels: any;
  domainesProfessionnelsByGrandDomaine: any;
  selectedMetiers: any;
  appelations: any;
  appelation: any;
  metier: any;
  qualifications: any;
  fap: any;
  loading: boolean;
  error: any;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>();

export const initialState: MetierState = adapter.getInitialState({
  themes: undefined,
  grandDomaine: undefined,
  domainesProfessionnels: undefined,
  domainesProfessionnelsByGrandDomaine: undefined,
  selectedMetiers: undefined,
  appelations: undefined,
  appelation: undefined,
  metier: undefined,
  qualifications: undefined,
  fap: undefined,
  loading: false,
  error: undefined,
});

export const reducer = createReducer(
  initialState,
  //LOADING
  on(MetierActions.loadingMetiers, (state, action) => {
    return {
      ...state,
      loading: action.loading,
    };
  }),

  //THEMES
  on(MetierActions.loadThemesSuccess, (state, action) => {
    return {
      ...state,
      themes: action.themes,
    };
  }),
  on(MetierActions.loadThemesFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //DOMAINES
  on(MetierActions.loadDomainesSuccess, (state, action) => {
    return {
      ...state,
      error: undefined,
      domainesProfessionnels: undefined,
      domainesProfessionnelsByGrandDomaine: undefined,
      selectedMetiers: undefined,
      appelations: undefined,
      appelation: undefined,
      metier: undefined,
      fap: undefined,
    };
  }),
  on(MetierActions.loadDomainesSuccess, (state, action) => {
    return {
      ...state,
      grandDomaine: action.domaines,
    };
  }),
  on(MetierActions.loadDomainesFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //DOMAINES PROFESSIONNELS
  on(MetierActions.loadDomainesProfessionnels, (state, action) => {
    return {
      ...state,
      error: undefined,
      selectedMetiers: undefined,
      appelations: undefined,
      appelation: undefined,
      metier: undefined,
      fap: undefined,
    };
  }),

  on(MetierActions.loadDomainesProfessionnelsSuccess, (state, action) => {
    return {
      ...state,
      domainesProfessionnels: action.domaines,
    };
  }),
  on(MetierActions.loadDomainesProfessionnelsFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //DOMAINES PROFESSIONNELS BY GRAND DOMAINE
  on(
    MetierActions.loadDomainesProfessionnelsByGrandDomaineSuccess,
    (state, action) => {
      return {
        ...state,
        domainesProfessionnelsByGrandDomaine: action.domaines,
      };
    }
  ),
  on(
    MetierActions.loadDomainesProfessionnelsByGrandDomaineFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
      };
    }
  ),

  //METIERS BY DOMAINE
  on(MetierActions.loadMetiersByDomaine, (state, action) => {
    return {
      ...state,
      error: undefined,
      appelations: undefined,
      appelation: undefined,
      metier: undefined,
      fap: undefined,
    };
  }),

  on(MetierActions.loadMetiersByDomaineSuccess, (state, action) => {
    return {
      ...state,
      selectedMetiers: action.metiers,
    };
  }),
  on(MetierActions.loadMetiersByDomaineFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //APPELATIONS BY METIERS
  on(MetierActions.loadAppelationsByMetier, (state, action) => {
    return {
      ...state,
      error: undefined,
      appelation: undefined,
      metier: undefined,
      fap: undefined,
    };
  }),
  on(MetierActions.loadAppelationsByMetierSuccess, (state, action) => {
    return {
      ...state,
      appelations: action.appelations,
    };
  }),
  on(MetierActions.loadAppelationsByMetierFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //APPELATION DETAILS BY APPELATION
  on(MetierActions.loadAppelationDetailByAppelationSuccess, (state, action) => {
    return {
      ...state,
      appelation: action.appelation,
    };
  }),
  on(MetierActions.loadAppelationDetailByAppelationFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //METIER DETAILS
  on(MetierActions.loadMetierDetailSuccess, (state, action) => {
    return {
      ...state,
      metier: action.metier,
    };
  }),
  on(MetierActions.loadMetierDetailFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //METIER QUALIFICATION
  on(MetierActions.loadQualificationsSuccess, (state, action) => {
    return {
      ...state,
      qualifications: action.qualifications,
    };
  }),
  on(MetierActions.loadQualificationsFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //METIER FAP
  on(MetierActions.loadFapSuccess, (state, action) => {
    return {
      ...state,
      fap: action.fap,
    };
  }),
  on(MetierActions.loadFapFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  on(MetierActions.clearMetiers, (state) => adapter.removeAll(state))
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
