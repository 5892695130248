import { ParticipantDiagnosticModule } from './../participant-diagnostic/participant-diagnostic.module';
import { ParticipantImpactsModule } from './../participant-impacts/participant-impacts.module';
import { ParticipantActionsAccompagnementModule } from './../participant-actions-accompagnement/participant-actions-accompagnement.module';
import { ParticipantExportPptService } from './../../service/participant-export-ppt.service';
import { ParticipantOperationsService } from './../../service/participant-operations.service';
import { ParticipantStorageService } from '../../service/participant/document/participant-storage.service';
import { QueryParticipantsService } from './../../service/query-participants.service';
import { ParticipantResolverService } from '../../resolvers/participant/participant-resolver.service';
import { ContentsModule } from './../../contents/contents.module';
import { SharedModule } from './../../shared/shared.module';
import { ParticipantsResolverService } from '../../resolvers/participant/participants-resolver.service';
import { ParticipantService } from './../../service/participant.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ParticipantRoutingModule } from './participant-routing.module';
import { ParticipantComponent } from './components/participant/participant.component';
import { ParticipantListComponent } from './components/participant-list/participant-list.component';
import { ParticipantFormComponent } from './components/participant-form/participant-form.component';
import { ParticipantItemComponent } from './components/participant-item/participant-item.component';
import { ParticipantFiltersComponent } from './components/participant-filters/participant-filters.component';
import { ParticipantCardComponent } from './components/participant-card/participant-card.component';
import { ParticipantActionsComponent } from './components/participant-actions/participant-actions.component';
import { StoreModule } from '@ngrx/store';
import * as fromParticipant from './store/participant.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ParticipantEffects } from './store/participant.effects';
import { ParticipantDetailComponent } from './components/participant-detail/participant-detail.component';
import { ParticipantIdentityComponent } from './components/participant-identity/participant-identity.component';
import { ParticipantItemHeaderComponent } from './components/participant-item-header/participant-item-header.component';
import { ParticipantIdentityTabComponent } from './components/participant-identity-tab/participant-identity-tab.component';
import { ParticipantPresentationTabComponent } from './components/participant-presentation-tab/participant-presentation-tab.component';
import { ParticipantSortieTabComponent } from './components/participant-sortie-tab/participant-sortie-tab.component';
import { ParticipantProjetprofessionnelsComponent } from './components/participant-projetprofessionnels/participant-projetprofessionnels.component';
import { ParticipantMissionsComponent } from './components/participant-missions/participant-missions.component';
import { ParticipantOperationsComponent } from './components/participant-operations/participant-operations.component';
import { ParticipantRessourcesComponent } from './components/participant-ressources/participant-ressources.component';
import { ParticipantJusticeComponent } from './components/participant-justice/participant-justice.component';
import { ParticipantPrescripteurComponent } from './components/participant-prescripteur/participant-prescripteur.component';
import { ProjetsProfessionnelsModule } from '../projets-professionnels/projets-professionnels.module';
import { ParticipantPrescripteurCardComponent } from './components/participant-prescripteur-card/participant-prescripteur-card.component';
import { ParticipantSituationTabComponent } from './components/participant-situation-tab/participant-situation-tab.component';
import { ParticipantHebergementTabComponent } from './components/participant-hebergement-tab/participant-hebergement-tab.component';
import { ParticipantExportPdfService } from 'src/app/service/participant-export-pdf.service';
import { ParticipantExportWordService } from 'src/app/service/participant-export-word.service';
import { ParticipantStatistiquesService } from 'src/app/service/participant-statistiques.service';
import { ParticipantPrescripteurItemComponent } from './components/participant-prescripteur-item/participant-prescripteur-item.component';
import { ParticipantOrientationsComponent } from './components/participant-orientations/participant-orientations.component';
import { ParticipantOrientationsFormComponent } from './components/participant-orientations-form/participant-orientations-form.component';
import { ParticipantOrientationsItemComponent } from './components/participant-orientations-item/participant-orientations-item.component';
import { ParticipantExperiencesComponent } from './components/participant-experiences/participant-experiences.component';
import { ParticipantExperiencesItemComponent } from './components/participant-experiences-item/participant-experiences-item.component';
import { ParticipantExperiencesService } from 'src/app/service/participant-experiences.service';
import { ParticipantFreinsComponent } from './components/participant-freins/participant-freins.component';
import { ParticipantDocumentsModule } from '../participant-documents/participant-documents.module';
import { ParticipantNotesModule } from '../participant-notes/participant-notes.module';
import { ParticipantPlanningModule } from '../participant-planning/participant-planning.module';
import { ParticipantBilanModule } from '../participant-bilan/participant-bilan.module';
import { ParticipantDashboardModule } from '../participant-dashboard/participant-dashboard.module';
import { ParticipantSuiviModule } from '../participant-suivi/participant-suivi.module';
import { NoResultModule } from 'src/app/contents/components/no-result/no-result.module';
import { CoordonneesModule } from 'src/app/contents/components/coordonnees/coordonnees.module';
import { CommunesFranceModule } from 'src/app/contents/components/communes-france/communes-france.module';
import { ButtonsModule } from 'src/app/contents/components/buttons/buttons.module';
import { HeaderModule } from 'src/app/contents/components/header-title/header.module';
import { LayoutModule } from 'src/app/contents/components/layout/layout.module';
import { AvatarModule } from 'src/app/features/avatar/avatar.module';
import { SuiviModule } from 'src/app/features/suivi/suivi.module';
import { UiModule } from 'src/app/ui/ui/ui.module';

@NgModule({
  declarations: [
    ParticipantComponent,
    ParticipantListComponent,
    ParticipantFormComponent,
    ParticipantItemComponent,
    ParticipantFiltersComponent,
    ParticipantCardComponent,
    ParticipantActionsComponent,
    ParticipantDetailComponent,
    ParticipantIdentityComponent,
    ParticipantItemHeaderComponent,
    ParticipantIdentityTabComponent,
    ParticipantPresentationTabComponent,
    ParticipantSortieTabComponent,
    ParticipantProjetprofessionnelsComponent,
    ParticipantMissionsComponent,
    ParticipantOperationsComponent,
    ParticipantRessourcesComponent,
    ParticipantJusticeComponent,
    ParticipantPrescripteurComponent,
    ParticipantPrescripteurCardComponent,
    ParticipantSituationTabComponent,
    ParticipantHebergementTabComponent,
    ParticipantPrescripteurItemComponent,
    ParticipantOrientationsComponent,
    ParticipantOrientationsFormComponent,
    ParticipantOrientationsItemComponent,
    ParticipantExperiencesComponent,
    ParticipantExperiencesItemComponent,
    ParticipantFreinsComponent,
  ],
  imports: [
    CommonModule,
    ParticipantRoutingModule,
    SharedModule,
    ContentsModule,
    ProjetsProfessionnelsModule,
    ParticipantActionsAccompagnementModule,
    ParticipantImpactsModule,
    ParticipantDocumentsModule,
    ParticipantNotesModule,
    ParticipantPlanningModule,
    ParticipantBilanModule,
    ParticipantDashboardModule,
    ParticipantSuiviModule,
    ParticipantDiagnosticModule,
    NoResultModule,
    CoordonneesModule,
    HeaderModule,
    CommunesFranceModule,
    ButtonsModule,
    LayoutModule,
    AvatarModule,
    SuiviModule,
    UiModule,
    StoreModule.forFeature(
      fromParticipant.participantsFeatureKey,
      fromParticipant.reducer
    ),
    EffectsModule.forFeature([ParticipantEffects]),
  ],
  exports: [
    ParticipantComponent,
    ParticipantListComponent,
    ParticipantFormComponent,
    ParticipantItemComponent,
    ParticipantFiltersComponent,
    ParticipantCardComponent,
    ParticipantActionsComponent,
    ParticipantDetailComponent,
    ParticipantIdentityComponent,
    ParticipantItemHeaderComponent,
    ParticipantIdentityTabComponent,
    ParticipantPresentationTabComponent,
    ParticipantSortieTabComponent,
    ParticipantProjetprofessionnelsComponent,
    ParticipantMissionsComponent,
    ParticipantOperationsComponent,
    ParticipantRessourcesComponent,
    ParticipantJusticeComponent,
    ParticipantPrescripteurComponent,
    ParticipantPrescripteurCardComponent,
    ParticipantSituationTabComponent,
    ParticipantHebergementTabComponent,
    ParticipantPrescripteurItemComponent,
    ParticipantOrientationsComponent,
    ParticipantOrientationsFormComponent,
    ParticipantOrientationsItemComponent,
    ParticipantExperiencesComponent,
    ParticipantExperiencesItemComponent,
  ],
  providers: [
    ParticipantService,
    ParticipantsResolverService,
    ParticipantResolverService,
    QueryParticipantsService,
    ParticipantStorageService,
    ParticipantOperationsService,
    ParticipantExportPdfService,
    ParticipantExportWordService,
    ParticipantExportPptService,
    ParticipantStatistiquesService,
    ParticipantExperiencesService,
  ],
})
export class ParticipantModule {}
