<ng-container *ngIf="operation$ | async as operation">
  <form nz-form nzLayout="vertical" [formGroup]="actionForm">
    <nz-steps [nzCurrent]="currentStep" nzSize="small">
      <nz-step nzTitle="Description"> </nz-step>
      <nz-step nzTitle="Adresse"> </nz-step>
      <nz-step nzTitle="Calendrier"> </nz-step>
      <nz-step nzTitle="Objectif"> </nz-step>
    </nz-steps>
    <nz-space nzDirection="vertical"></nz-space>

    <div [ngSwitch]="currentStep">
      <div *ngSwitchCase="0">
        <nz-card [nzTitle]="cardTitleDefinition">
          <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
            <nz-col nzSpan="24">
              <nz-form-item nzRequired="true">
                <nz-form-label [nzSpan]="18" nzFor="module" nzRequired="true"
                  >Module</nz-form-label
                >
                <nz-form-control nzErrorTip="Aucun module sélectionné">
                  <nz-tag
                    *ngIf="inputModule$ | async; else moduleNoExist"
                    nzColor="success"
                  >
                    <i nz-icon nzType="check-circle"></i>
                    <span>{{ (inputModule$ | async)?.title | titlecase }}</span>
                  </nz-tag>

                  <ng-template #moduleNoExist>
                    <app-module-select
                      (select)="onSelectModule($event)"
                    ></app-module-select>
                  </ng-template>
                </nz-form-control>
              </nz-form-item>
            </nz-col>
          </nz-row>

          <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
            <nz-col nzSpan="16">
              <nz-form-item>
                <nz-form-label [nzSpan]="18" nzFor="title" nzRequired="true"
                  >Titre</nz-form-label
                >
                <nz-form-control nzErrorTip="Aucun titre renseigné">
                  <input
                    nz-input
                    nzBorderless
                    formControlName="title"
                    placeholder="Titre"
                    id="title"
                    required
                  />
                </nz-form-control>
              </nz-form-item>
            </nz-col>
            <nz-col nzSpan="8">
              <nz-form-item nzRequired="true">
                <nz-form-label [nzSpan]="18" nzFor="type" nzRequired="true"
                  >Type</nz-form-label
                >
                <nz-form-control nzErrorTip="Aucun type renseigné">
                  <nz-select
                    nzBorderless
                    [nzPlaceHolder]="'Choisir un type'"
                    [compareWith]="compareType"
                    nzShowSearch
                    *ngIf="types$ | async as types"
                    [nzDisabled]="!(types$ | async)"
                    formControlName="type"
                  >
                    <nz-option
                      *ngFor="let type of types"
                      [nzValue]="type"
                      [nzLabel]="type"
                    >
                      {{ type | titlecase }}
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </nz-col>
          </nz-row>

          <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
            <nz-col nzSpan="24">
              <nz-form-item>
                <nz-form-label [nzSpan]="18" nzFor="title" nzRequired="true"
                  >Description</nz-form-label
                >
                <nz-form-control>
                  <textarea
                    rows="2"
                    nz-input
                    nzBorderless
                    formControlName="definition"
                    placeholder="Description"
                    nzAutosize="false"
                    [nzAutosize]="{ minRows: 2, maxRows: 2 }"
                  ></textarea>
                </nz-form-control>
              </nz-form-item>
            </nz-col>
          </nz-row>
        </nz-card>
      </div>
      <div *ngSwitchCase="1">
        <nz-card [nzTitle]="cardAdresse">
          <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
            <nz-col nzSpan="24">
              <app-communes-france
                [borderless]="true"
                [size]="'default'"
                [isComplement]="false"
                (adresse)="onAdresse($event)"
                (complementIn)="onAdresseComplement($event)"
              ></app-communes-france>
            </nz-col>
          </nz-row>
        </nz-card>
      </div>
      <div *ngSwitchCase="2">
        <nz-card nzSize="small" [nzTitle]="cardTitleCalendar">
          <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
            <nz-col nzSpan="4">
              <nz-form-item nzRequired="false">
                <nz-form-label [nzSpan]="24" nzFor="min" nzRequired="false">
                  Phase
                </nz-form-label>
                <nz-form-control nzErrorTip="Aucune phase renseignée">
                  <nz-input-number
                    formControlName="phases"
                    nzPlaceHolder="Phase"
                    [nzMin]="1"
                    [nzMax]="1200"
                    [nzStep]="1"
                    id="min"
                  >
                  </nz-input-number>
                </nz-form-control>
              </nz-form-item>
            </nz-col>
            <nz-col nzSpan="10">
              <nz-form-item nzRequired="true">
                <nz-form-label [nzSpan]="24" nzFor="date" nzRequired="true"
                  >Démarrage</nz-form-label
                >
                <nz-form-control nzErrorTip="Aucune date de renseignée">
                  <nz-date-picker
                    [nzShowTime]="{
                      nzMinuteStep: 30,
                      nzFormat: 'HH:mm'
                    }"
                    nzBorderless
                    formControlName="dateStart"
                    nzPlaceHolder="Démarrage..."
                    nzFormat="dd/MM/yyyy HH:mm"
                    [nzDisabledDate]="disabledDateMin"
                    style="width: 100%"
                    id="dateStart"
                    required
                  >
                  </nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </nz-col>
            <nz-col nzSpan="10">
              <nz-form-item nzRequired="true">
                <nz-form-label [nzSpan]="24" nzFor="date" nzRequired="true"
                  >Échéance</nz-form-label
                >
                <nz-form-control
                  nzErrorTip="Aucune date de renseignée"
                  style="width: 100%"
                >
                  <nz-date-picker
                    [nzShowTime]="{
                      nzMinuteStep: 30,
                      nzFormat: 'HH:mm'
                    }"
                    nzBorderless
                    formControlName="dateEnd"
                    nzPlaceHolder="Démarrage..."
                    nzFormat="dd/MM/yyyy HH:mm"
                    [nzDisabledDate]="disabledDateMax"
                    style="width: 100%"
                    id="dateEnd"
                    required
                  >
                  </nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </nz-col>
          </nz-row>
        </nz-card>
      </div>
      <div *ngSwitchCase="3">
        <nz-card nzSize="small" [nzTitle]="cardObjectif">
          <nz-row nzAlign="middle" nzGutter="2" nzJustify="start">
            <nz-col nzSpan="2">
              <mat-icon>supervisor_account</mat-icon>
            </nz-col>
            <nz-col nzSpan="8">
              <nz-form-item>
                <nz-form-label>Participants</nz-form-label>
                <nz-form-control>
                  <nz-input-number
                    [nzMin]="0"
                    formControlName="objectifPersons"
                    nzPlaceHolder="Nombre de participants"
                  >
                  </nz-input-number>
                </nz-form-control>
              </nz-form-item>
            </nz-col>
            <nz-col nzSpan="4">
              <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
            </nz-col>
            <nz-col nzSpan="2">
              <mat-icon>timer</mat-icon>
            </nz-col>
            <nz-col nzSpan="8">
              <nz-form-item>
                <nz-form-label>Heures</nz-form-label>
                <nz-form-control>
                  <nz-input-number
                    [nzMin]="0"
                    formControlName="objectifHours"
                    nzPlaceHolder="Nombre d'heures d'insertion"
                  >
                  </nz-input-number>
                </nz-form-control>
              </nz-form-item>
            </nz-col>
          </nz-row>
        </nz-card>
        <nz-space nzSize="small"></nz-space>
        <nz-card nzSize="small" [nzTitle]="cardObjectifTheoric">
          <nz-row nzAlign="middle" nzGutter="2" nzJustify="start">
            <nz-col nzSpan="2">
              <mat-icon>supervisor_account</mat-icon>
            </nz-col>
            <nz-col nzSpan="8">
              <nz-form-item>
                <nz-form-label>Participants</nz-form-label>
                <nz-form-control>
                  <nz-input-number
                    [nzMin]="0"
                    formControlName="objectifPersonsTheoric"
                    nzPlaceHolder="Nombre de participants théorique"
                  >
                  </nz-input-number>
                </nz-form-control>
              </nz-form-item>
            </nz-col>
            <nz-col nzSpan="4">
              <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
            </nz-col>
            <nz-col nzSpan="2">
              <mat-icon>timer</mat-icon>
            </nz-col>
            <nz-col nzSpan="8">
              <nz-form-item>
                <nz-form-label>Heures</nz-form-label>
                <nz-form-control>
                  <nz-input-number
                    [nzMin]="0"
                    formControlName="objectifHoursTheoric"
                    nzPlaceHolder="Nombre théorique d'heures d'insertion"
                  >
                  </nz-input-number>
                </nz-form-control>
              </nz-form-item>
            </nz-col>
          </nz-row>
        </nz-card>
      </div>
      <div *ngSwitchDefault></div>
    </div>
  </form>

  <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="end">
    <nz-col nzSpan="12"></nz-col>
    <nz-col nzSpan="4">
      <button nz-button nzBlock nzType="text" (click)="onCancel()">
        Annuler
      </button>
    </nz-col>
    <nz-col nzSpan="4">
      <button
        *ngIf="isAdd"
        nz-button
        nzBlock
        (click)="$event.stopPropagation(); onPrevStep()"
      >
        Précédent
      </button>
    </nz-col>
    <nz-col nzSpan="4">
      <button
        *ngIf="isAdd && currentStep < 3"
        nz-button
        nzBlock
        (click)="$event.stopPropagation(); onNextStep()"
      >
        Suivant
      </button>

      <button
        nz-button
        nzBlock
        nzType="primary"
        *ngIf="update && action$ | async as action"
        (click)="onUpdate($event, operation, action)"
        [disabled]="actionForm.invalid"
      >
        Modifier
      </button>
      <button
        nz-button
        nzBlock
        nzType="primary"
        *ngIf="add && currentStep === 3"
        (click)="onAdd(operation)"
        [disabled]="actionForm.invalid"
      >
        Ajouter
      </button>
    </nz-col>
  </nz-row>
</ng-container>

<ng-template #noModule>
  <h3>Aucun module ajouté</h3>
</ng-template>

<ng-template #cardTitleDefinition>
  <app-header-title
    [icon]="'article'"
    [subtitle]="true"
    [title]="''"
  ></app-header-title>
</ng-template>

<ng-template #cardObjectif>
  <app-header-title
    [icon]="'track_changes'"
    [subtitle]="true"
    [title]="'Objectifs engagés'"
  ></app-header-title>
</ng-template>
<ng-template #cardObjectifTheoric>
  <app-header-title
    [icon]="'track_changes'"
    [subtitle]="true"
    [title]="'Objectifs théoriques'"
  ></app-header-title>
</ng-template>

<ng-template #cardTitleDescription>
  <app-header-title
    [icon]="'article'"
    [subtitle]="true"
    [title]="''"
  ></app-header-title>
</ng-template>

<ng-template #cardTitleCalendar>
  <app-header-title
    [icon]="'event'"
    [subtitle]="true"
    [title]="''"
  ></app-header-title>
</ng-template>

<ng-template #cardAdresse>
  <app-header-title
    [icon]="'place'"
    [subtitle]="true"
    [title]="'Adresse'"
  ></app-header-title>
</ng-template>
