import {
  OperationNoteDetailId,
  OperationNoteId,
} from './../../../operation-note/store/operation-note.model';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';
import { serverTimestamp } from 'firebase/firestore';
import { capitalize, omit } from 'lodash';
import { NzModalService } from 'ng-zorro-antd/modal';
import { OperationNoteSubtitleFormComponent } from '../operation-note-subtitle-form/operation-note-subtitle-form.component';
import {
  OperationNoteSubtitle,
  OperationNoteSubtitleId,
} from '../../store/operation-note-subtitle.model';
import { OperationNoteSubtitleState } from '../../store/operation-note-subtitle.reducer';
import * as fromOperationNoteSubtitleAction from '../../store/operation-note-subtitle.actions';
import * as fromOperationNoteSubtitleSelector from '../../store/operation-note-subtitle.selectors';

import { Store } from '@ngrx/store';
import { Update } from '@ngrx/entity';

@Component({
  selector: 'app-operation-note-subtitle-list',
  templateUrl: './operation-note-subtitle-list.component.html',
  styleUrls: ['./operation-note-subtitle-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationNoteSubtitleListComponent implements OnInit {
  subscribe = new Subject();
  loading$: Observable<boolean> = of(false);
  subtitles$: Observable<OperationNoteSubtitleId[] | any> = of([]);
  tooltipMouseEnterDelay: number = 0.5;

  @Input() note$: Observable<OperationNoteId | any> = of(null);
  @Input() sujet$: Observable<any> = of(null);
  @Output() select: EventEmitter<OperationNoteSubtitleId | any> =
    new EventEmitter();

  title: string = 'Nouveau sous-titre';
  @ViewChild('subtitleFormTitle', { static: false })
  subtitleFormTitle: TemplateRef<any> | any;
  modalWidth: number = 780;

  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private operationNoteSubtitleStore: Store<OperationNoteSubtitleState>
  ) {}

  ngOnInit(): void {
    this.getSubtitles();
    this.loadSubtitles();
    this.getLoading();
  }

  getLoading(): void {
    this.loading$ = this.operationNoteSubtitleStore.select(
      fromOperationNoteSubtitleSelector.loading
    );
  }

  getSubtitles(): void {
    this.subtitles$ = this.operationNoteSubtitleStore.select(
      fromOperationNoteSubtitleSelector.subtitles
    );
  }
  loadSubtitles(): void {
    this.note$
      .pipe(takeUntil(this.subscribe))
      .subscribe((note: OperationNoteId) => {
        if (!note) return;
        this.operationNoteSubtitleStore.dispatch(
          fromOperationNoteSubtitleAction.loadOperationNoteSubtitles({ note })
        );
      });
  }

  trackSubtitles(index: any, item: { id: string }) {
    return item ? item.id : undefined;
  }

  onSelect(subtitle: OperationNoteSubtitleId) {
    if (!subtitle) return;
    this.select.next(subtitle);
  }

  onNew(note: OperationNoteId): void {
    if (!note) return;
    const modal = this.modal.create({
      nzContent: OperationNoteSubtitleFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.subtitleFormTitle,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
    });
    const instance = modal.getContentComponent();
    instance.note$ = of(note);
    instance.cancel.subscribe((event: boolean): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
    instance.add.subscribe((subtitle: any): any => {
      if (!subtitle) {
        return null;
      } else {
        this.onAdd(note, subtitle);
        modal.close();
      }
    });
  }

  onUpdateForm(subtitle: OperationNoteSubtitleId, note: OperationNoteId): void {
    if (!note) return;
    const modal = this.modal.create({
      nzContent: OperationNoteSubtitleFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.subtitleFormTitle,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
    });
    const instance = modal.getContentComponent();
    instance.note$ = of(note);
    instance.subtitle$ = of(subtitle);
    instance.cancel.subscribe((event: boolean): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
    instance.update.subscribe(
      (subtitle: Update<OperationNoteSubtitleId>): any => {
        if (!subtitle) {
          return null;
        } else {
          this.onUpdate(note, subtitle);
          modal.close();
        }
      }
    );
  }

  onAdd(note: OperationNoteDetailId, subtitle: OperationNoteSubtitle): void {
    if (!note || !subtitle) return;

    this.operationNoteSubtitleStore.dispatch(
      fromOperationNoteSubtitleAction.addOperationNoteSubtitle({
        note,
        subtitle,
      })
    );
  }

  onCancel(): void {
    return;
  }

  onUpdate(
    note: OperationNoteId,
    subtitle: Update<OperationNoteSubtitleId>
  ): void {
    if (!note || !subtitle) return;

    this.operationNoteSubtitleStore.dispatch(
      fromOperationNoteSubtitleAction.updateOperationNoteSubtitle({
        note,
        subtitle,
      })
    );
  }

  onDelete(id: string, note: OperationNoteId): void {
    if (!id || !note) return;
    this.operationNoteSubtitleStore.dispatch(
      fromOperationNoteSubtitleAction.deleteOperationNoteSubtitle({ note, id })
    );
    this.subtitles$ = of(null);
  }

  onSelectDropdown(
    event: string,
    note: OperationNoteId,
    subtitles: OperationNoteSubtitleId[]
  ): void {
    if (!event) return;
    switch (event) {
      case 'deleteAll':
        const ids: string[] = subtitles.map((el) => el.id);
        this.operationNoteSubtitleStore.dispatch(
          fromOperationNoteSubtitleAction.deleteAllOperationNoteSubtitles({
            note,
            ids,
          })
        );

        break;

      default:
        break;
    }
  }
}
