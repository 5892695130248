import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, Subject, takeUntil } from 'rxjs';
import { ParticipantId } from 'src/app/components/participant/store/participant.model';
import { ParticipantState } from 'src/app/components/participant/store/participant.reducer';
import * as fromParticipantSelector from 'src/app/components/participant/store/participant.selectors';

@Component({
  selector: 'app-participant-dashboard',
  templateUrl: './participant-dashboard.component.html',
  styleUrls: ['./participant-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantDashboardComponent
  implements OnInit, OnChanges, OnDestroy
{
  participant$: Observable<ParticipantId | any> = of(null);
  statistiques$: Observable<any> = of(null);
  subscribe = new Subject();
  title: string = 'Tableau de bord';

  constructor(private participantStore: Store<ParticipantState>) {}

  ngOnInit(): void {
    this.getDashboard();
    this.getParticipant();
  }
  ngOnChanges(changes: SimpleChanges): void {}

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }
  getParticipant(): void {
    this.participant$ = this.participantStore.select(
      fromParticipantSelector.participant
    );
  }

  getDashboard(): void {
    this.participant$
      .pipe(takeUntil(this.subscribe))
      .subscribe((participant) => {
        if (!participant) return;
      });
  }
}
