<div class="module_details_container" *ngIf="module">
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="center">
    <nz-col nzSpan="12">
      <nz-card [nzTitle]="description">
        <nz-row
          nzAlign="top"
          nzGutter="2"
          nzJustify="start"
          *ngIf="module?.description?.definition"
          class="content"
        >
          <nz-col nzSpan="24">{{ module?.description?.definition }}</nz-col>
        </nz-row>
        <app-no-result
          *ngIf="!module?.description?.definition"
          [icon]="'subject'"
          [addtitle]="''"
          [description]="'Aucune description ajoutée'"
          [isButton]="false"
        ></app-no-result>
      </nz-card>
    </nz-col>
    <nz-col nzSpan="12">
      <nz-card [nzTitle]="context">
        <nz-row
          nzAlign="top"
          nzGutter="2"
          nzJustify="start"
          *ngIf="module?.adresse?.adresse?.properties?.label"
          class="content"
        >
          <nz-col nzSpan="2"><mat-icon>place</mat-icon></nz-col>
          <nz-col nzSpan="22">{{
            module?.adresse?.adresse?.properties?.label
          }}</nz-col>
        </nz-row>

        <app-no-result
          *ngIf="!module?.adresse?.adresse?.properties?.label"
          [icon]="'place'"
          [addtitle]="''"
          [description]="'Aucune adresse ajoutée'"
          [isButton]="false"
        ></app-no-result>
      </nz-card>
    </nz-col>
  </nz-row>
  <nz-space nzDirection="vertical"></nz-space>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="center">
    <nz-col nzSpan="12">
      <nz-card [nzTitle]="entitesTpl" [nzExtra]="entitesExtratTpl">
        <nz-row nzAlign="top" nzGutter="2" nzJustify="start" class="content">
          <nz-col nzSpan="24">
            <nz-list
              nzBordered
              nzSize="small"
              *ngIf="module?.entites?.length; else noDataEntite"
            >
              <nz-list-item *ngFor="let item of module?.entites">
                <ul nz-list-item-actions>
                  <nz-list-item-action>
                    <button
                      nz-button
                      nzShape="circle"
                      nzType="text"
                      (click)="$event.stopPropagation()"
                      nz-tooltip
                      nzTooltipTitle="Supprimer l'entité"
                      nzTooltipPlacement="bottom"
                      nz-popconfirm
                      nzPopconfirmTitle="Voulez-vous retirer cette entité ?"
                      (nzOnConfirm)="onDeleteEntite(item, module)"
                      (nzOnCancel)="onCancel()"
                      nzPopconfirmPlacement="bottom"
                    >
                      <mat-icon>delete</mat-icon>
                    </button>
                  </nz-list-item-action>
                </ul>

                <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
                  <nz-col nzSpan="12">
                    {{
                      item?.entite?.etablissement?.denomination
                        | carLimitation: 15
                    }}
                  </nz-col>
                  <nz-col nzSpan="2">
                    <nz-divider
                      nzOrientation="center"
                      nzType="vertical"
                    ></nz-divider>
                  </nz-col>
                  <nz-col nzSpan="8">
                    <small>
                      {{
                        item?.entite?.metiersDomaine?.grandDomaine?.libelle
                          | carLimitation: 30
                      }}
                    </small>
                  </nz-col>
                  <nz-col nzSpan="2">
                    <nz-divider
                      nzOrientation="center"
                      nzType="vertical"
                    ></nz-divider>
                  </nz-col>
                </nz-row>
              </nz-list-item>
            </nz-list>
          </nz-col>
        </nz-row>
      </nz-card>
    </nz-col>
    <nz-col nzSpan="12">
      <nz-card [nzTitle]="collaborateur" [nzExtra]="collaborateurExtratTpl">
        <nz-row nzAlign="top" nzGutter="2" nzJustify="start" class="content">
          <nz-col nzSpan="24">
            <nz-list
              nzBordered
              nzSize="small"
              *ngIf="module?.contacts?.length; else noDataContact"
            >
              <nz-list-item *ngFor="let item of module?.contacts">
                <ul nz-list-item-actions>
                  <nz-list-item-action>
                    <button
                      nz-button
                      nzShape="circle"
                      nzType="text"
                      (click)="$event.stopPropagation()"
                      nz-tooltip
                      nzTooltipTitle="Supprimer le collaborateur"
                      nzTooltipPlacement="bottom"
                      nz-popconfirm
                      nzPopconfirmTitle="Voulez-vous retirer ce collaborateur ?"
                      (nzOnConfirm)="onDeleteContact(item, module)"
                      (nzOnCancel)="onCancel()"
                      nzPopconfirmPlacement="bottom"
                    >
                      <mat-icon>delete</mat-icon>
                    </button>
                  </nz-list-item-action>
                </ul>

                <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
                  <nz-col nzSpan="12">
                    {{ item?.contact?.displayName | carLimitation: 25 }}
                  </nz-col>
                  <nz-col nzSpan="2">
                    <nz-divider
                      nzOrientation="center"
                      nzType="vertical"
                    ></nz-divider>
                  </nz-col>
                  <nz-col nzSpan="8">
                    <small>
                      {{
                      item?.contact?.entitesTitles?.[0]
                        | carLimitation: 25
                      }}
                    </small>
                  </nz-col>
                  <nz-col nzSpan="2">
                    <nz-divider
                      nzOrientation="center"
                      nzType="vertical"
                    ></nz-divider>
                  </nz-col>
                </nz-row>
              </nz-list-item>
            </nz-list>
          </nz-col>
        </nz-row>
      </nz-card>
    </nz-col>
  </nz-row>

  <ng-template #description>
    <app-header-title
      [title]="'Description'"
      [icon]="'subject'"
      [subtitle]="true"
    ></app-header-title>
  </ng-template>
  <ng-template #context>
    <app-header-title
      [title]="'Contexte'"
      [icon]="'subject'"
      [subtitle]="true"
    ></app-header-title>
  </ng-template>
  <ng-template #entitesTpl>
    <app-header-title
      [title]="'Entités'"
      [icon]="'domaine'"
      [subtitle]="true"
    ></app-header-title>
  </ng-template>
  <ng-template #collaborateur>
    <app-header-title
      [title]="'Collaborateurs'"
      [icon]="'groups'"
      [subtitle]="true"
    ></app-header-title>
  </ng-template>

  <ng-template #entitesExtratTpl>
    <button
      nz-button
      nzShape="circle"
      nzType="text"
      nz-tooltip
      nzTooltipPlacement="bottom"
      nzTooltipTitle="Ajouter une entité"
      (click)="onNewEntite(module)"
    >
      <mat-icon>domain_add</mat-icon>
    </button>
  </ng-template>

  <ng-template #collaborateurExtratTpl>
    <button
      nz-button
      nzShape="circle"
      nzType="text"
      nz-tooltip
      nzTooltipPlacement="bottom"
      nzTooltipTitle="Ajouter un collaborateur"
      (click)="onNewContact(module)"
    >
      <mat-icon>group_add</mat-icon>
    </button>
  </ng-template>

  <ng-template #entiteSelectorFormTitle>
    <app-header-title
      [icon]="'domaine'"
      [title]="titleDomaine"
      [subtitle]="true"
    ></app-header-title>
  </ng-template>
  <ng-template #contactSelectorFormTitle>
    <app-header-title
      [icon]="'person'"
      [title]="titleContact"
      [subtitle]="true"
    ></app-header-title>
  </ng-template>

  <ng-template #noDataEntite>
    <app-no-result
      [icon]="'domaine'"
      [addtitle]="''"
      [description]="'Aucune entité ajoutée'"
      [isButton]="false"
    ></app-no-result>
  </ng-template>
  <ng-template #noDataContact>
    <app-no-result
      [icon]="'person'"
      [addtitle]="''"
      [description]="'Aucun collaborateur ajouté'"
      [isButton]="false"
    ></app-no-result>
  </ng-template>
</div>
