import { OperationNoteSubtitleId } from 'src/app/components/operation-note-subtitle/store/operation-note-subtitle.model';
import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { OperationNote, OperationNoteId } from './operation-note.model';
import { OperationId } from '../../operation/store/operation.model';

//LOADING
export const loadingOperationNotes = createAction(
  '[OperationNote/API] Loading OperationNotes',
  props<{ loading: boolean }>()
);

//LOAD ALL
export const loadOperationNotes = createAction(
  '[OperationNote/COMPONENT] Load OperationNotes',
  props<{ operationId: string }>()
);

export const loadOperationNotesSuccess = createAction(
  '[OperationNote/API] Load OperationNotes success',
  props<{ notes: OperationNoteId[] }>()
);
export const loadOperationNotesFailure = createAction(
  '[OperationNote/API] Load OperationNotes failure',
  props<{ error: any }>()
);

//LOAD ONE
export const loadOperationNote = createAction(
  '[OperationNote/COMPONENT] Load OperationNote',
  props<{ operationId: string; noteId: string }>()
);

export const loadOperationNoteSuccess = createAction(
  '[OperationNote/API] Load OperationNote Success',
  props<{ note: OperationNoteId }>()
);

export const loadOperationNoteFailure = createAction(
  '[OperationNote/API] Load OperationNote failure',
  props<{ error: any }>()
);

//ADD ONE
export const addOperationNote = createAction(
  '[OperationNote/COMPONENT] Add OperationNote',
  props<{ note: OperationNote | any }>()
);
export const addOperationNoteSuccess = createAction(
  '[OperationNote/API] Add OperationNote success',
  props<{ success: string }>()
);
export const addOperationNoteFailure = createAction(
  '[OperationNote/API] Add OperationNote failure',
  props<{ error: any }>()
);

//UPDATE ONE
export const updateOperationNote = createAction(
  '[OperationNote/API] Update OperationNote',
  props<{ note: Update<OperationNoteId> }>()
);
export const updateOperationNoteSuccess = createAction(
  '[OperationNote/API] Update OperationNote success',
  props<{ success: string }>()
);
export const updateOperationNoteFailure = createAction(
  '[OperationNote/API] Update OperationNote failure',
  props<{ error: any }>()
);

//DELETE ONE

export const deleteOperationNote = createAction(
  '[OperationNote/COMPONENTS] Delete OperationNote',
  props<{ operationId: string; id: string }>()
);
export const deleteOperationNoteSuccess = createAction(
  '[OperationNote/API] Delete OperationNote success',
  props<{ success: string }>()
);
export const deleteOperationNoteFailure = createAction(
  '[OperationNote/API] Delete OperationNote failure',
  props<{ error: any }>()
);

//DELETE ALL
export const deleteOperationNotes = createAction(
  '[OperationNote/API] Delete OperationNotes',
  props<{ operationId: string; ids: string[] }>()
);
export const deleteOperationNotesSuccess = createAction(
  '[OperationNote/API] Delete OperationNotes Success',
  props<{ success: string }>()
);
export const deleteOperationNotesFailure = createAction(
  '[OperationNote/API] Delete OperationNotes Failure',
  props<{ error: any }>()
);

//EXPORT PDF VERSION
export const exportPdfOperationNote = createAction(
  '[OperationNote/COMPONENT] Export pdf version OperationNotes',
  props<{
    operation: OperationId;
    note: OperationNoteId;
    subtitles: OperationNoteSubtitleId[];
    sujets: any[];
  }>()
);
export const exportPdfOperationNoteSuccess = createAction(
  '[OperationNote/API] Export pdf version OperationNotes Success',
  props<{ success: string }>()
);
export const exportPdfOperationNoteFailure = createAction(
  '[OperationNote/API] Export pdf version OperationNotes Failure',
  props<{ error: any }>()
);

//EXPORT WORD VERSION
export const exportWordOperationNote = createAction(
  '[OperationNote/COMPONENT] Export word version OperationNotes',
  props<{
    operation: OperationId;
    note: OperationNoteId;
    subtitles: OperationNoteSubtitleId[];
  }>()
);
export const exportWordOperationNoteSuccess = createAction(
  '[OperationNote/API] Export word version OperationNotes Success',
  props<{ success: string }>()
);
export const exportWordOperationNoteFailure = createAction(
  '[OperationNote/API] Export word version OperationNotes Failure',
  props<{ error: any }>()
);

//COPY ONE
export const copyOperationNote = createAction(
  '[OperationNote/COMPONENT] Copy OperationNotes',
  props<{ operationId: string; noteId: string }>()
);
export const copyOperationNoteSuccess = createAction(
  '[OperationNote/API] Copy OperationNotes Success',
  props<{ success: string }>()
);
export const copyOperationNoteFailure = createAction(
  '[OperationNote/API] Copy OperationNotes Failure',
  props<{ error: any }>()
);

//CLEAR

export const clearOperationNotes = createAction(
  '[OperationNote/API] Clear OperationNotes'
);
