<div class="container" *ngIf="operation$ | async as operation">
  <div [ngSwitch]="view$ | async">
    <div *ngSwitchCase="'list'">
      <i nz-icon nzType="loading" class="loading" *ngIf="loading$ | async"></i>

      <ng-container *ngIf="events$ | async | size; else noResults">
        <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
      </ng-container>
    </div>
    <div *ngSwitchCase="'details'"></div>
    <div *ngSwitchDefault>
      <app-layout-list></app-layout-list>
    </div>
  </div>

  <ng-template #new> </ng-template>

  <ng-template #card let-item let-position="idx"> </ng-template>

  <ng-template #noResults>
    <app-no-result
      [icon]="'event'"
      [addtitle]="newAddItemTitle"
      [notitle]="noDataTitle"
      [description]="newItemDefinition"
      [isButton]="true"
    ></app-no-result>
  </ng-template>

  <ng-template #eventFormTitle>
    <app-header-title [icon]="'event'" [title]="title"></app-header-title>
  </ng-template>
</div>
