import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Pipe, PipeTransform } from '@angular/core';
import memo from 'memo-decorator';

@Pipe({
  name: 'nosanitizerpipe',
})
export class NosanitizerpipePipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) {}
  @memo()
  transform(html: string): SafeHtml {
    return this.domSanitizer.bypassSecurityTrustHtml(html);
  }
}
