<ng-container *ngIf="user$ | async as user">
  <div class="navbar bg-base-100">
    <div class="navbar-start w-1/12">
      <div class="dropdown">
        <label tabindex="0" class="btn btn-ghost btn-circle">
          <svg
            width="24px"
            class="h-6 w-6"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <title>menu_fill</title>
            <g
              id="页面-1"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g
                id="Editor"
                transform="translate(-1248.000000, -48.000000)"
                fill-rule="nonzero"
              >
                <g id="menu_fill" transform="translate(1248.000000, 48.000000)">
                  <path
                    d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z"
                    id="MingCute"
                    fill-rule="nonzero"
                  ></path>
                  <path
                    d="M20,17.5 C20.8284,17.5 21.5,18.1716 21.5,19 C21.5,19.7796706 20.9050879,20.4204457 20.1444558,20.4931332 L20,20.5 L4,20.5 C3.17157,20.5 2.5,19.8284 2.5,19 C2.5,18.2203294 3.09488554,17.5795543 3.85553954,17.5068668 L4,17.5 L20,17.5 Z M20,10.5 C20.8284,10.5 21.5,11.1716 21.5,12 C21.5,12.8284 20.8284,13.5 20,13.5 L4,13.5 C3.17157,13.5 2.5,12.8284 2.5,12 C2.5,11.1716 3.17157,10.5 4,10.5 L20,10.5 Z M20,3.5 C20.8284,3.5 21.5,4.17157 21.5,5 C21.5,5.82843 20.8284,6.5 20,6.5 L4,6.5 C3.17157,6.5 2.5,5.82843 2.5,5 C2.5,4.17157 3.17157,3.5 4,3.5 L20,3.5 Z"
                    id="形状"
                    fill="#09244BFF"
                  ></path>
                </g>
              </g>
            </g>
          </svg>
        </label>
        <ul
          tabindex="0"
          class="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52"
        >
          <li class="menu-title">
            <span>Parties prenantes</span>
          </li>

          <li [routerLink]="['/entites']"><a>Entités</a></li>
          <li [routerLink]="['/participants']"><a>Participants</a></li>

          <!-- <li [routerLink]="['/contacts']"><a>Contacts</a></li> -->

          <li class="divide"></li>

          <li class="menu-title">
            <span>Pilotage</span>
          </li>
          <li [routerLink]="['/projets']"><a>Projets</a></li>
          <li [routerLink]="['/missions']"><a>Missions</a></li>

          <li class="divide"></li>
          <li class="menu-title">
            <span>Agence</span>
          </li>
          <li [routerLink]="['/services']"><a>Services</a></li>

          <li [routerLink]="['/organisation', user.organisation.id]">
            <a>Organisation</a>
          </li>
        </ul>
      </div>

      <div class="flex-1" [routerLink]="['/user', user.id]">
        <span
          class="group/logo rounded-full cursor-pointer border-0 p-2 ml-4 inline-block tooltip tooltip-bottom btn-circle"
          data-tip="Accueil Protein"
        >
          <svg
            class="w-8 h-8"
            viewBox="0 0 306 335"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xml:space="preserve"
            xmlns:serif="http://www.serif.com/"
            style="
              fill-rule: evenodd;
              clip-rule: evenodd;
              stroke-linejoin: round;
              stroke-miterlimit: 2;
            "
          >
            <g id="logo">
              <path
                d="M120.356,153.999l-0.1,-0.05l0.094,-0.061l0.006,0.111Zm143.122,0.117l-0.583,-12.012l-139.989,6.817l-75.9,-117.828l-10.106,6.511l75.895,117.828l-61.511,119.617l10.7,5.5l61.505,-119.611l139.989,-6.822Z"
                style="fill: #062949; fill-rule: nonzero"
              />
              <path
                d="M72.494,50.057c-8.678,16.867 -29.384,23.511 -46.25,14.839c-16.867,-8.678 -23.511,-29.383 -14.839,-46.25c8.672,-16.867 29.383,-23.511 46.25,-14.839c16.872,8.672 23.517,29.378 14.839,46.25"
                style="fill: #f00; fill-rule: nonzero"
              />
              <path
                d="M300.334,167.215c-11.017,21.422 -37.322,29.867 -58.75,18.85c-21.428,-11.022 -29.867,-37.322 -18.85,-58.756c11.022,-21.427 37.322,-29.866 58.75,-18.844c21.428,11.017 29.866,37.322 18.85,58.75"
                style="fill: #f00; fill-rule: nonzero"
              />
              <path
                d="M106.99,303.689c-14.3,27.812 -48.438,38.767 -76.25,24.462c-27.811,-14.3 -38.761,-48.434 -24.461,-76.245c14.3,-27.817 48.439,-38.767 76.25,-24.467c27.811,14.306 38.761,48.439 24.461,76.25"
                style="fill: #f00; fill-rule: nonzero"
              />
            </g>
          </svg>
        </span>
      </div>
    </div>
    <div class="navbar-center items-start h-12 p-4 w-9/12">
      <ng-template [cdkPortalOutlet]="selectedPortal"></ng-template>
    </div>
    <div class="navbar-end gap-1 w-2/12">
      <app-navigation-icons
        [theme]="theme"
        [user]="user"
      ></app-navigation-icons>
      <app-user-avatar
        [user]="user"
        [theme]="theme"
        [size]="35"
      ></app-user-avatar>

      <!-- <div class="dropdown dropdown-end">
          <label tabindex="0" class="btn btn-ghost btn-circle">
            <div class="indicator">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                />
              </svg>
              <span class="badge badge-sm indicator-item">8</span>
            </div>
          </label>
          <div
            tabindex="0"
            class="mt-3 card card-compact dropdown-content w-52 bg-base-100 shadow"
          >
            <div class="card-body">
              <span class="font-bold text-lg">8 Items</span>
              <span class="text-info">Subtotal: $999</span>
              <div class="card-actions">
                <button class="btn btn-primary btn-block">View cart</button>
              </div>
            </div>
          </div>
        </div> -->
    </div>
  </div>
</ng-container>
