import { ModuleId } from './../../store/module.model';
import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-module-item-header',
  templateUrl: './module-item-header.component.html',
  styleUrls: ['./module-item-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModuleItemHeaderComponent implements OnInit, OnChanges {
  progressCompleteColor: string = '#49d49d';
  progressDefaultColor: string = '#062949';

  @Input() module: ModuleId | any = null;
  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}
}
