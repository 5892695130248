import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonMenu } from 'src/app/contents/components/buttons/store/button.model';

@Component({
  selector: 'app-candidature-item-actions',
  templateUrl: './candidature-item-actions.component.html',
  styleUrls: ['./candidature-item-actions.component.scss'],
})
export class CandidatureItemActionsComponent implements OnInit {
  actionsMenu: ButtonMenu[] = [
    {
      title: 'Valider',
      icon: '',
    },
    {
      title: 'En entretien',
      icon: '',
    },
    {
      title: 'Réjeter',
      icon: '',
    },
    {
      title: 'Trasnférer',
      icon: '',
    },
    {
      title: 'Modifier',
      icon: '',
    },
    {
      title: 'Supprimer',
      icon: '',
    },
  ];
  deleteType: string = 'Voulez-vous supprimer ce candidat ?';
  menuNew: ButtonMenu[] = [
    {
      icon: '',
      title: 'Note',
    },
    {
      icon: '',
      title: 'Document',
    },
  ];
  @Input() candidat: any;
  @Output() action = new EventEmitter(false);
  constructor() {}

  ngOnInit(): void {}

  onAction(event: string): void {
    this.action.emit(event);
  }
}
