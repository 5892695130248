import { Observable, of, Subject, takeUntil } from 'rxjs';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ContratState } from '../../store/contrat.reducer';
import * as fromContratAction from '../../store/contrat.actions';
import * as fromContratSelector from '../../store/contrat.selectors';
import { Store } from '@ngrx/store';
import {
  add,
  eachDayOfInterval,
  eachHourOfInterval,
  fromUnixTime,
  getHours,
  nextMonday,
  set,
} from 'date-fns';
import { Timestamp } from 'firebase/firestore';

@Component({
  selector: 'app-contrat-form-travail',
  templateUrl: './contrat-form-travail.component.html',
  styleUrls: ['./contrat-form-travail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContratFormTravailComponent
  implements OnInit, OnChanges, OnDestroy
{
  //Possibilité de favoriser les informations de travail par poste et entreprise à ajouter au CRM
  subscribe = new Subject();
  today: Date = new Date(Date.now());
  timeStartAM: Date = set(this.today, { hours: 9, minutes: 0 });
  timeEndAM: Date = set(this.today, { hours: 12, minutes: 0 });
  timeStartPM: Date = set(this.today, { hours: 13, minutes: 0 });
  timeEndPM: Date = set(this.today, { hours: 17, minutes: 0 });

  disabledHoursPM(): number[] {
    return [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 0];
  }
  disabledHoursAM(): number[] {
    return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  }

  timeDefaultOpenValue: Date = this.today;
  dateFormat: string = 'dd/MM/yyyy';
  dates: Date[] = [this.today, add(this.today, { days: 5 })];
  datesSouplesseModel: Date[] = [
    nextMonday(this.dates[1]),
    add(nextMonday(this.dates[1]), { days: 5 }),
  ];
  contrat_days$: Observable<string[]> = of([]);
  souplesseTitle: string =
    "Le terme de la mission prévu au contrat de mise à disposition ou fixé par avenant à ce dernier peut-être avancé ou reporté à raison d'un jour pour cinq jours de travail";

  isAstreinteModel: boolean = false;
  isReferentPresenceModel: boolean = false;

  //FORM
  contratForm: FormGroup = this.fb.group({});
  work_days = new FormControl<string[]>(
    ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi'],
    Validators.required
  );
  work_days_exception = new FormArray([]);
  pause_dejeuner = new FormControl<number>(1);
  amplitude_horaire = new FormControl<number>(8, Validators.required);
  duree_hebdomadaire = new FormControl<number>(35, Validators.required);
  suiviInterne = new FormControl<string>('');
  isReferentPresence = new FormControl<boolean>(true);
  datesContrat = new FormControl<any[]>(
    [Timestamp.fromDate(this.dates[0]), Timestamp.fromDate(this.dates[1])],
    Validators.required
  );
  startMission = new FormControl<any>(
    Timestamp.fromDate(this.dates[0]),
    Validators.required
  );
  endMission = new FormControl<any>(
    Timestamp.fromDate(this.dates[1]),
    Validators.required
  );
  heures = new FormControl();
  total = new FormControl(0);
  totalWork = new FormControl(0);
  totalTraining = new FormControl(0);
  totalExtra = new FormControl(0);
  periodeDessaie = new FormControl(3);
  retraitre = new FormControl('');
  datesSouplesse = new FormControl<any[]>(
    [
      Timestamp.fromDate(this.datesSouplesseModel[0]),
      Timestamp.fromDate(this.datesSouplesseModel[1]),
    ],
    Validators.required
  );
  startSouplesse = new FormControl<any>(
    Timestamp.fromDate(this.datesSouplesseModel[0]),
    Validators.required
  );
  endSouplesse = new FormControl<any>(
    Timestamp.fromDate(this.datesSouplesseModel[1]),
    Validators.required
  );
  horaireStartAM = new FormControl<number>(9, Validators.required);
  horaireEndAM = new FormControl<number>(12, Validators.required);
  horaireStartPM = new FormControl<number>(13, Validators.required);
  horaireEndPM = new FormControl<number>(17, Validators.required);
  horaireStartAMdate = new FormControl<any>(
    this.timeStartAM,
    Validators.required
  );
  horaireEndAMdate = new FormControl<any>(this.timeEndAM, Validators.required);
  horaireStartPMdate = new FormControl<any>(
    this.timeStartPM,
    Validators.required
  );
  horaireEndPMdate = new FormControl<any>(this.timeEndPM, Validators.required);

  observationTravail = new FormControl<string>('');
  observationHohaireTravail = new FormControl<string>('');

  hours = new FormArray([]);
  isFinished = new FormControl(false);

  @Input() work_daysInput: any = null;
  @Input() pause_dejeunerInput: any = null;
  @Input() amplitude_horaireInput: any = null;
  @Input() isAstreinteInput: any = null;
  @Input() suiviInterneInput: any = null;
  @Input() isTeamWorkInput: any = null;
  @Input() isReferentPresenceInput: any = null;
  @Input() isSupplementairesInput: any = null;
  @Input() datesContratInput: Date[] = [];

  @Output() onCurrentChanges = new EventEmitter<any>();

  constructor(
    private fb: FormBuilder,
    private contratStore: Store<ContratState>
  ) {}

  ngOnInit(): void {
    this.formCreate();
    this.getReferentiels();
    this.onChange();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.onUpdateForm(
      changes.work_daysInput.currentValue,
      changes.pause_dejeunerInput.currentValue,
      changes.amplitude_horaireInput.currentValue,
      changes.isAstreinteInput.currentValue,
      changes.suiviInterneInput.currentValue,
      changes.isTeamWorkInput.currentValue,
      changes.isReferentPresenceInput.currentValue,
      changes.isSupplementairesInput.currentValue,
      changes.datesContratInput.currentValue
    );
  }

  formCreate(): void {
    this.contratForm = this.fb.group({
      work_days: this.work_days,
      pause_dejeuner: this.pause_dejeuner,
      amplitude_horaire: this.amplitude_horaire,
      suiviInterne: this.suiviInterne,
      isReferentPresence: this.isReferentPresence,
      datesContrat: this.datesContrat,
      startMission: this.startMission,
      endMission: this.endMission,
      duree_hebdomadaire: this.duree_hebdomadaire,
      hours: this.hours,
      total: this.total,
      totalWork: this.totalWork,
      totalTraining: this.totalTraining,
      totalExtra: this.totalExtra,
      periodeDessaie: this.periodeDessaie,
      retraitre: this.retraitre,
      datesSouplesse: this.datesSouplesse,
      startSouplesse: this.startSouplesse,
      endSouplesse: this.endSouplesse,
      horaireStartAM: this.horaireStartAM,
      horaireEndAM: this.horaireEndAM,
      horaireStartPM: this.horaireStartPM,
      horaireEndPM: this.horaireEndPM,
      horaireStartAMdate: this.horaireStartAMdate,
      horaireEndAMdate: this.horaireEndAMdate,
      horaireStartPMdate: this.horaireStartPMdate,
      horaireEndPMdate: this.horaireEndPMdate,

      isFinished: this.isFinished,
      observationTravail: this.observationTravail,
      observationHohaireTravail: this.observationHohaireTravail,
    });
  }

  getReferentiels(): void {
    this.contrat_days$ = this.contratStore.select(fromContratSelector.jours);
  }

  onUpdateForm(
    work_days: string,
    pause_dejeuner: string,
    amplitude_horaire: string,
    isAstreinte: string,
    suiviInterne: string,
    isTeamWork: boolean,
    isReferentPresence: boolean,
    isSupplementaires: boolean,
    datesContrat: any
  ): void {
    const start = fromUnixTime(datesContrat?.[0]);
    const end = fromUnixTime(datesContrat?.[1]);

    this.contratForm.patchValue({
      work_days: work_days,
      pause_dejeuner: pause_dejeuner,
      amplitude_horaire: amplitude_horaire,
      isAstreinte: isAstreinte,
      suiviInterne: suiviInterne,
      isTeamWork: isTeamWork,
      isReferentPresence: isReferentPresence,
      isSupplementaires: isSupplementaires,
      datesContrat: [start, end],
      startMission: start,
      endMission: end,
    });
  }

  //HEURES AM
  onTimeStartAM(time: Date): void {
    if (!time) return;
    const hours: number = getHours(time);
    this.contratForm.patchValue({
      horaireStartAM: hours,
      horaireStartAMdate: Timestamp.fromDate(time),
    });
  }

  onTimeEndAM(time: Date): void {
    if (!time) return;
    const hours: number = getHours(time);

    this.contratForm.patchValue({
      horaireEndAM: hours,
      horaireEndAMdate: Timestamp.fromDate(time),
    });
  }

  //HEURES PM
  onTimeStartPM(time: Date): void {
    if (!time) return;
    const hours: number = getHours(time);
    this.contratForm.patchValue({
      horaireStartPM: hours,
      horaireStartPMdate: Timestamp.fromDate(time),
    });
  }

  onTimeEndPM(time: Date): void {
    if (!time) return;
    const hours: number = getHours(time);

    this.contratForm.patchValue({
      horaireEndPM: hours,
      horaireEndPMdate: Timestamp.fromDate(time),
    });
  }

  //DATE
  onDateRange(dates: Date[]): void {
    if (!dates.length) return;
    const start = Timestamp.fromDate(dates[0]);
    const end = Timestamp.fromDate(dates[1]);

    this.contratForm.patchValue({
      startMission: start,
      endMission: end,
      datesContrat: [start, end],
    });
  }

  onDateSouplesseRange(dates: Date[]): void {
    if (!dates.length) return;
    const start = Timestamp.fromDate(dates[0]);
    const end = Timestamp.fromDate(dates[1]);

    this.contratForm.patchValue({
      startSouplesse: start,
      endSouplesse: end,
      datesSouplesse: [start, end],
    });
  }

  onChange(): void {
    //if (!this.contratForm.valid) return;

    this.contratForm.valueChanges
      .pipe(takeUntil(this.subscribe))
      .subscribe((formValue) => {
        this.onCurrentChanges.emit(formValue);
      });
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }
}
