import { Coordonnees } from 'src/app/contents/components/coordonnees/coordonnees.model';
import { Adresse } from 'src/app/models/adresse.model';

export interface Contact {
  civilite: string;
  firstName: string;
  lastName: string;
  fonction: string;
  fonctionRome?: string;
  sexe?: string;
  competences: Competence[];
  thematiques: Thematique[];
  benevolat?: boolean;
  benevolatHours?: BenevolatHours[];
  antennes?: any;
  service: string;
  origine: string; //comment on les connai+t: rencontre || collaboration || "fléchage"
  coordonnees: Coordonnees;
  adresse?: Adresse;
  operationsId: string[];
  operations: string[];
  events: Event[];
  notes: string[];
  isParrain?: boolean;

  entite: {
    id: string;
    denomination: string;
    siret: string;
    geo_adresse: string;
    activite_principale_entreprise: string;
    secteur: string;
    region: string;
    categorie_entreprise: string;
  };
  entites?: ContactEntites[];
  entitesIds?: string[];
  entitesTitles?: string[];
  entitesMapIds?: any;
  dateStart: any;
  dateUpdate: any;
  dateSelected: any;
  userSelected: string;
  userUpdate: any;
  auteur: any | any;
  organisation?: any;
  actif: boolean;
  departements?: string[];
  participants: string[];
  utilisateur?: boolean;
  interets: InteretId[];
  stats: any;
  auths?: any;
  commentaire?: string;
  groups: string[];
  metadata?: any;
  disponibility?: boolean;
  metiersThemes?: any;
  metiersDomainesArray?: any;
  metiersDomaineMap?: any;
  metiersGrandDomainesArray?: any;
  metiersGrandDomainesMap?: any;
  metiersArray: any;
  metiersMap?: any;
  appelationsArray?: any;
  appelationsMap?: any;
}

export interface ContactId extends Contact {
  id: string;
}

export interface ContactSMALL {
  lastName: string;
  firstName: string;
  civilite: string;
  displayName: string;
  fonction: string;
  fonctionRome?: string;
  sexe?: string;
  coordonnees: Coordonnees;
  adresse: any;
  benevolat?: boolean;
  benevolatHours?: BenevolatHours[];
  disponibility?: boolean;
  auteur?: any;
  dateStart?: any | Date;
  createAtYear?: string;
  createAtMonth?: string | number;
  createAtDay?: string | number;
  secteurs?: string[];
  activite_principale_entreprise?: any;
  categorie_entreprise?: any;
  entite: {
    id: string;
    denomination: string;
    siret: string;
    geo_adresse: string;
    activite_principale_entreprise: string;
    secteur: string;
    region: string;
    categorie_entreprise: string;
  };
  entites?: ContactEntites[] | any;
  entitesIds?: string[];
  entitesTitles?: string[];
  entitesMapIds?: any;
  organisation: {
    id: string;
    denomination: string;
  };
  isParrain?: boolean;
  metadata: any;
  modules?: any[];
  modulesIds?: string[];
  modulesMapIds?: any;
  actions?: any[];
  actionsIds?: string[];
  actionsMapIds?: any;
  metiersThemes?: any;
  metiersDomainesArray?: any;
  metiersDomainesMap?: any;
  metiersGrandDomainesArray?: any;
  metiersGrandDomainesMap?: any;
  metiersArray: any;
  metiersMap?: any;
  appelationsArray?: any;
  appelationsMap?: any;
}

export interface ContactSMALLid extends ContactSMALL {
  id: string;
}

export interface ContactEntites {
  id: string;
  denomination: string;
  siret: string;
  geo_adresse: string;
  activite_principale_entreprise: string;
  secteur: string;
  region?: string;
  categorie_entreprise?: string;
}

export interface InteretId {
  id: string;
  dateStart: Date;
  dateUpdate: Date;
  operation: {
    id: string;
    denommination: string;
    departementId: string;
  };
}

export interface ContactEvent {
  auths: [string];
  roles: any;
  dateStart: Date;
  dateUpdate: Date;
  type: string;
  autresContacts: ContactId[];
  autresCollaborateurs: any;
  auteur: any;
  userUpdate: any;
  contact: ContactId;
  event: string;
}

export interface ContactEventId extends ContactEvent {
  id: string;
}

export interface Tag {
  id: string;
  value: string;
  dateStart: Date;
}

export interface BenevolatHours {
  id: string;
  denomination: string;
  hours: number;
}

export interface ContactStats {
  programmes: ContactStatsProgramme[];
  actions: ContactStatsActions[];
}

export interface ContactStatsProgramme {
  id: string;
  denomination: string;
  adresse: Adresse;
  objectifQuanti: number;
  objectifQuali: string;
  realiseQuanti: number;
  realiseQuali: string;
  participants: number;
}

export interface ContactStatsActions {
  id: string;
  denomiation: string;
}

export interface Thematique {
  id: string;
  item: string;
  dateStart: Date;
}

export interface Competence {
  id: string;
  categorie: string;
  item: string;
  dateStart: Date;
}

export interface ContactsGroup {
  title: string;
  comment: string;
  dateStart: Date | any;
  dateUpdate: Date | any;
  ids: string[];
  auteur: any;
}

export interface ContactsGroupId extends ContactsGroup {
  id: string;
}

export enum ContactQuery {
  last = 'lastName',
  first = 'firstName',
  secteur = 'secteur',
  sexe = 'sexe',
  fonction = 'fonction',
  commune = 'commune',
  code = 'codepostal',
  entite = 'entite',
  favoris = 'favoris',
  year = 'years',
  categorie = 'categorie',
  activity = 'activite',
}
