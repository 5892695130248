<div class="Operation_Dashboard_Container">
  <nz-row nzJustify="center" nzAlign="middle" nzGutter="2">
    <nz-col nzSpan="24">
      <nz-row nzJustify="center" nzAlign="middle" nzGutter="2">
        <nz-col nzSpan="24">
          <app-charts
            *ngIf="operationTime$ | async; else noData"
            [source]="operationTime$"
            [icon]="'query_stats'"
            [isBorderless]="false"
            [isTitle]="true"
          ></app-charts>
        </nz-col>
      </nz-row>
    </nz-col>
  </nz-row>
  <nz-space></nz-space>
  <nz-row nzJustify="center" nzAlign="middle" nzGutter="2">
    <nz-col nzSpan="24">
      <nz-row nzJustify="center" nzAlign="middle" nzGutter="2">
        <nz-col nzSpan="24">
          <app-charts
            *ngIf="operationBarByMonths$ | async; else noData"
            [source]="operationBarByMonths$"
            [icon]="'bar_chart'"
            [isBorderless]="false"
            [isTitle]="true"
          ></app-charts>
        </nz-col>
      </nz-row>
    </nz-col>
  </nz-row>
  <nz-space></nz-space>
  <nz-row nzJustify="center" nzAlign="middle" nzGutter="2">
    <nz-col nzSpan="24">
      <nz-row nzJustify="center" nzAlign="middle" nzGutter="2">
        <nz-col nzSpan="24">
          <app-charts
            *ngIf="operationBarByYears$ | async; else noData"
            [source]="operationBarByYears$"
            [icon]="'bar_chart'"
            [isBorderless]="false"
            [isTitle]="true"
          ></app-charts>
        </nz-col>
      </nz-row>
    </nz-col>
  </nz-row>
</div>

<ng-template #noData>
  <app-no-result
    [icon]="'query_stats'"
    [isButton]="false"
    [title]="''"
  ></app-no-result>
</ng-template>
