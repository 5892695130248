import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  ParticipantState,
  participantsFeatureKey,
  selectAll,
} from './participant.reducer';

export const selectParticipantsState = createFeatureSelector<ParticipantState>(
  participantsFeatureKey
);

export const participants = createSelector(selectParticipantsState, selectAll);

export const participant = createSelector(
  selectParticipantsState,
  (state: ParticipantState) => state.participant
);

export const loading = createSelector(
  selectParticipantsState,
  (state: ParticipantState) => state.loading
);

export const experiences = createSelector(
  selectParticipantsState,
  (state: ParticipantState) => state.experiences
);

export const fileProgress = createSelector(
  selectParticipantsState,
  (state: ParticipantState) => state.fileProgress
);

export const operations = createSelector(
  selectParticipantsState,
  (state: ParticipantState) => state.operations
);
export const operation = createSelector(
  selectParticipantsState,
  (state: ParticipantState) => state.operation
);

export const aids = createSelector(
  selectParticipantsState,
  (state: ParticipantState) => state.aids
);

export const filters = createSelector(
  selectParticipantsState,
  (state: ParticipantState) => state.filters
);
export const statistiques = createSelector(
  selectParticipantsState,
  (state: ParticipantState) => state.statistiques
);

//ERROR
export const error = createSelector(
  selectParticipantsState,
  (state: ParticipantState) => state.error
);
