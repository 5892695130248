import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
  TemplateRef,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';
import { Update } from '@ngrx/entity';
import { NzModalService } from 'ng-zorro-antd/modal';
import { of } from 'rxjs';
import { OperationContactsSelectorsComponent } from 'src/app/components/operation-contacts/components/operation-contacts-selectors/operation-contacts-selectors.component';
import { OperationEntitesSelectorsComponent } from 'src/app/components/operation-entites/components/operation-entites-selectors/operation-entites-selectors.component';
import { ModuleId } from '../../store/module.model';

@Component({
  selector: 'app-module-details',
  templateUrl: './module-details.component.html',
  styleUrls: ['./module-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModuleDetailsComponent implements OnInit {
  @Input() module: ModuleId | any = null;
  modalWidth: number = 850;
  titleDomaine: string = 'Sélection une entité';
  titleContact: string = 'Sélection un intervenant';

  @ViewChild('entiteSelectorFormTitle', { static: false })
  entiteSelectorFormTitle: TemplateRef<any> | any;
  @ViewChild('contactSelectorFormTitle', { static: false })
  contactSelectorFormTitle: TemplateRef<any> | any;

  @Output() update = new EventEmitter<Update<ModuleId>>(false);

  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {}

  //ENTITE
  onNewEntite(module: ModuleId): void {
    if (!module) return;
    const { operation } = module;
    const modal = this.modal.create({
      nzContent: OperationEntitesSelectorsComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.entiteSelectorFormTitle,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
    });
    const instance = modal.getContentComponent();
    instance.operation$ = of(operation);

    instance.cancel.subscribe((event: any): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });

    instance.select.subscribe((event: any): any => {
      if (!event) {
        return null;
      } else {
        this.onAddEntite(event, module);
        modal.close();
      }
    });
  }

  onAddEntite(entite: any, module: ModuleId): void {
    if (!entite || !module) return;

    const { id } = module;

    const entites = module?.entites?.length
      ? [...module.entites, entite]
      : [entite];
    const entitesIds = module?.entitesIds?.length
      ? [...module.entitesIds, entite.id]
      : [entite.id];
    const entitesMapIds = {
      ...module?.entitesMapIds,
      [`${entite.id}`]: true,
    };

    const nextModule: Update<ModuleId> = {
      id: id,
      changes: {
        ...this.module,
        entites,
        entitesIds,
        entitesMapIds,
      },
    };

    this.update.emit(nextModule);
  }

  onDeleteEntite(entite: any, module: ModuleId): void {
    if (!entite || !module) return;

    const { id } = entite;

    const entites = module?.entites?.length
      ? module?.entites.filter((el: any) => el.id !== entite.id)
      : [];

    const entitesIds = module?.entitesIds?.length
      ? module?.entitesIds.filter((el: any) => el.id !== entite.id)
      : [];

    delete module?.entitesMapIds?.id;

    const nextModule: Update<ModuleId> = {
      id: id,
      changes: {
        ...this.module,
        entites,
        entitesIds,
      },
    };

    this.update.emit(nextModule);
  }

  //CONTACT
  onNewContact(module: ModuleId): void {
    if (!module) return;

    const { operation } = module;
    const modal = this.modal.create({
      nzContent: OperationContactsSelectorsComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.contactSelectorFormTitle,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
    });
    const instance = modal.getContentComponent();
    instance.operation$ = of(operation);

    instance.cancel.subscribe((event: any): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });

    instance.select.subscribe((event: any): any => {
      if (!event) {
        return null;
      } else {
        this.onAddContact(event, module);
        modal.close();
      }
    });
  }

  onAddContact(contact: any, module: ModuleId): void {
    if (!contact || !module) return;

    const { id } = module;

    const contacts = module?.contacts?.length
      ? [...module.contacts, contact]
      : [contact];
    const contactsIds = module?.contactsIds?.length
      ? [...module.contactsIds, contact.id]
      : [contact.id];
    const contactsMapIds = {
      ...module?.contactsMapIds,
      [`${contact.id}`]: true,
    };

    const nextModule: Update<ModuleId> = {
      id: id,
      changes: {
        ...this.module,
        contacts,
        contactsIds,
        contactsMapIds,
      },
    };

    this.update.emit(nextModule);
  }

  onDeleteContact(contact: any, module: ModuleId): void {
    if (!contact || !module) return;

    const { id } = contact;

    const contacts = module?.contacts?.length
      ? module?.contacts.filter((el: any) => el.id !== contact.id)
      : [];

    const contactsIds = module?.contactsIds?.length
      ? module?.contactsIds.filter((el: any) => el.id !== contact.id)
      : [];

    delete module?.contactsMapIds?.id;

    const nextModule: Update<ModuleId> = {
      id: id,
      changes: {
        ...this.module,
        contacts,
        contactsIds,
      },
    };

    this.update.emit(nextModule);
  }

  onCancel(): void {}
}
