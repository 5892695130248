import { ParticipantId } from './../../store/participant.model';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-participant-presentation-tab',
  templateUrl: './participant-presentation-tab.component.html',
  styleUrls: ['./participant-presentation-tab.component.scss'],
})
export class ParticipantPresentationTabComponent implements OnInit {
  @Input() participant: ParticipantId | any;
  constructor() {}

  ngOnInit(): void {}
}
