import { EntiteState } from './../../store/entite.reducer';
import * as fromEntiteAction from './../../store/entite.actions';
import * as fromEntiteSelector from './../../store/entite.selectors';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { EntiteQuery } from '../../store/entite.model';

@Component({
  selector: 'app-entite-filters-content',
  templateUrl: './entite-filters-content.component.html',
  styleUrls: ['./entite-filters-content.component.scss'],
})
export class EntiteFiltersContentComponent implements OnInit {
  loading$: Observable<boolean> = of(false);
  filters$: Observable<any> = of(null);
  roms$: Observable<any> = of([]);
  favoris$: Observable<any> = of([]);
  tags$: Observable<any> = of([]);
  filtersActivated: boolean = false;
  filtersOrders: any[] = [];
  cleaner: boolean = false;
  optionCategorie: any;
  optionActivity: any;
  optionSecteur: any;
  optionCommune: any;
  optionCodePostal: any;
  optionRegion: any;
  optionNature: any;
  optionType: any;
  optionStatut: any;
  optionEss: any;
  optionAdhesion: any;
  optionYear: any;
  optionOrgine: any;
  optionMetiers: any;

  @Output() reload: EventEmitter<any> = new EventEmitter<boolean>(false);
  constructor(private entiteStore: Store<EntiteState>) {}

  ngOnInit(): void {
    this.loadFilters();
    this.getFilters();
    this.getLoading();
  }

  loadFilters(): void {
    this.entiteStore.dispatch(fromEntiteAction.loadEntiteFilters());
  }

  getFilters(): void {
    this.filters$ = this.entiteStore.select(fromEntiteSelector.filters);
  }

  getLoading(): void {
    this.loading$ = this.entiteStore.select(fromEntiteSelector.loading);
  }

  loadEntites(): void {
    this.reload.emit(true);
  }

  onQuery(filter: any, typeFilter: string, arrayContains?: any): any {
    if (!filter) {
      this.loadEntites();
      return null;
    } else {
      const type = this.setFilterType(typeFilter);
      this.filtersActivated = true;
      this.cleaner = true;

      if (type !== 'favoris') {
        this.entiteStore.dispatch(
          fromEntiteAction.queryEntites({ filter: filter, filterType: type })
        );
      } else {
        this.entiteStore.dispatch(
          fromEntiteAction.queryEntites({
            filter: arrayContains,
            filterType: type,
          })
        );
      }
    }
  }

  setFilterType(type: string): string {
    switch (type) {
      case 'grandDomaine':
        return EntiteQuery.grandDomaine;
        break;
      case 'domaine':
        return EntiteQuery.domaine;
        break;
      case 'activite':
        return EntiteQuery.activite;
        break;
      case 'commune':
        return EntiteQuery.commune;
        break;
      case 'postcode':
        return EntiteQuery.code;
        break;
      case 'ess':
        return EntiteQuery.ess;
        break;
      case 'metier':
        return EntiteQuery.metier;
        break;
      case 'favoris':
        return EntiteQuery.favoris;
        break;
      case 'engagement':
        return EntiteQuery.engagement;
        break;
      case 'status':
        return EntiteQuery.status;
        break;
      case 'origine':
        return EntiteQuery.origine;
        break;
      default:
        return '';
        break;
    }
  }
  onClear(): void {
    this.loadEntites();
  }
}
