import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  Output,
  EventEmitter,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  MaxValidator,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, of, Subject, takeUntil } from 'rxjs';
import { UtilitiesState } from 'src/app/contents/components/utilities/store/utilities.reducer';
import * as fromUtilitiesSelector from 'src/app/contents/components/utilities/store/utilities.selectors';
import * as fromUtilitiesAction from 'src/app/contents/components/utilities/store/utilities.actions';
import { Timestamp } from 'firebase/firestore';
import { listAnimation } from 'src/app/app-animation';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ParticipantDiagnosticFormExperienceFormComponent } from '../participant-diagnostic-form-experience-form/participant-diagnostic-form-experience-form.component';
import { ParticipantDiagnosticExperience } from '../../store/participant-diagnostic.model';

@Component({
  selector: 'app-participant-diagnostic-form-experience',
  templateUrl: './participant-diagnostic-form-experience.component.html',
  styleUrls: ['./participant-diagnostic-form-experience.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [listAnimation],
})
export class ParticipantDiagnosticFormExperienceComponent
  implements OnInit, OnChanges, OnDestroy
{
  subscribe = new Subject();
  today: Date = new Date(Date.now());
  dateFormat: string = 'dd/MM/yyyy';
  experiences$: Observable<any> = of([]);
  scroll: any = { x: '600px', y: '600px' };

  //VARIABLES
  isFreinTitle: string = "L'expérience est-elle un frein identifié ?";

  isCarteBtpTitle: string = "La Carte d'identification professionnelle du BTP.";
  isExpiredCarteBtpTitle: string =
    "La Carte d'identification professionnelle du BTP est-elle expirée ?";

  //FORM FIELDS
  experienceForm: FormGroup = this.fb.group({});
  isFrein: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );

  //MODAL
  title: string = 'Nouvelle expérience';

  modalWidth: number = 800;

  @ViewChild('diagnosticExperienceTitleForm', { static: false })
  diagnosticExperienceTitleForm: TemplateRef<any> | any;

  //INIT
  @Input() experienceUpdate$: Observable<any> = of(null);
  @Output() add = new EventEmitter(false);

  constructor(
    private fb: FormBuilder,
    private utilitiesStore: Store<UtilitiesState>,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.form();
    this.onFormChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  form(): void {
    this.formInit();
    this.formCreate();
    this.formSet();
  }

  formInit(): void {
    this.isFrein = new FormControl(false, Validators.required);
  }

  formCreate(): void {
    this.experienceForm = this.fb.group({
      isFrein: this.isFrein,
    });
  }

  formSet(): void {
    this.experienceUpdate$
      .pipe(takeUntil(this.subscribe))
      .subscribe((experiences) => {
        if (!experiences) return;
        this.experienceForm.patchValue({ ...experiences });
      });
  }

  onNew(): void {
    const modal = this.modal.create({
      nzContent: ParticipantDiagnosticFormExperienceFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.diagnosticExperienceTitleForm,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '50px' },
    });
    const instance = modal.getContentComponent();
    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });

    instance.add.subscribe((event): any => {
      if (!event) return;
    });
  }

  onAdd(experience: ParticipantDiagnosticExperience): void {}

  onSelectDropdown(event: string, item: any): void {}

  checkboxChanges(event: boolean, type: string): void {
    switch (type) {
      case 'isCarteBtp':
        this.experienceForm.patchValue({
          isCarteBtp: event,
        });

        break;
      case 'isExpiredCarteBtp':
        this.experienceForm.patchValue({
          isExpiredCarteBtp: event,
        });

        break;
      case 'projet':
        break;

      default:
        break;
    }
  }

  onFormChanges(): void {
    this.experienceForm.valueChanges
      .pipe(takeUntil(this.subscribe))
      .subscribe((values) => {
        this.add.emit(values);
      });
  }
}
