import { ItemEventId } from '../../../features/suivi/components/item-event/itemEvent.model';
import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Contact, ContactId, ContactSMALLid } from './contact.model';
import * as ContactActions from './contact.actions';
import { OperationSmallId } from '../../operation/store/operation.model';
import { EntiteSMALLid } from '../../entite/store/entite.model';

export const contactsFeatureKey = 'contacts';

export interface ContactState extends EntityState<ContactSMALLid> {
  contact: ContactId | any;
  filters: any;
  statistiques: any;
  suivis: ItemEventId[] | any;
  suivi: ItemEventId | any;
  documents: any;
  document: any;
  operations: OperationSmallId[] | any;
  operation: OperationSmallId | any;
  entites: EntiteSMALLid[] | any;
  metiers: any[] | any;
  loading: boolean;
  error: any;
}

export const adapter: EntityAdapter<ContactSMALLid> =
  createEntityAdapter<ContactSMALLid>();

export const initialState: ContactState = adapter.getInitialState({
  contact: undefined,
  filters: undefined,
  statistiques: undefined,
  suivis: undefined,
  suivi: undefined,
  documents: undefined,
  document: undefined,
  operations: undefined,
  operation: undefined,
  entites: undefined,
  metiers: undefined,
  loading: false,
  error: undefined,
});

export const reducer = createReducer(
  initialState,
  on(ContactActions.loading, (state, action) => {
    return {
      ...state,
      loading: action.loading,
    };
  }),
  on(ContactActions.loadContacts, (state, action) => {
    return {
      ...state,
      contact: undefined,
      statistiques: undefined,
      suivis: undefined,
      suivi: undefined,
      documents: undefined,
      document: undefined,
      error: undefined,
    };
  }),
  on(ContactActions.loadContactsSuccess, (state, action) =>
    adapter.setAll(action.contacts, state)
  ),
  on(ContactActions.loadContactsFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  on(ContactActions.loadContactSuccess, (state, action) => {
    return {
      ...state,
      contact: action.contact,
    };
  }),

  on(ContactActions.loadContactFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //ADD
  on(ContactActions.addContactSuccess, (state, action) =>
    adapter.addOne(action.contact, state)
  ),

  on(ContactActions.addContactFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //UPDATE
  on(ContactActions.updateContact, (state, action) =>
    adapter.updateOne(action.contact, state)
  ),

  on(ContactActions.updateContactFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //DELETE
  on(ContactActions.deleteContact, (state, action) =>
    adapter.removeOne(action.id, state)
  ),

  on(ContactActions.deleteContactFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //FILTERS
  on(ContactActions.loadContactsFiltersSuccess, (state, action) => {
    return {
      ...state,
      filters: action.filters,
    };
  }),

  on(ContactActions.queryContactsFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //QUERY
  on(ContactActions.queryContactsSuccess, (state, action) =>
    adapter.setAll(action.contacts, state)
  ),
  on(ContactActions.queryContactsFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  on(ContactActions.updateContact, (state, action) =>
    adapter.updateOne(action.contact, state)
  ),
  on(ContactActions.deleteContact, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(ContactActions.deleteContacts, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(ContactActions.clearContacts, (state) => adapter.removeAll(state)),

  //SUIVIS
  on(ContactActions.loadSuivisSuccess, (state, action) => {
    return {
      ...state,
      suivis: action.suivis,
    };
  }),
  on(ContactActions.loadSuivisFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  // on(ContactActions.addSuiviSuccess, (state, action) => {
  //   return {
  //     ...state,
  //     suivis: [...state.suivis, action.suivi],
  //   };
  // }),
  on(ContactActions.addSuiviFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  // on(ContactActions.updateSuivi, (state, action) => {
  //   const removePrev = state.suivis.filter(
  //     (el: any) => el.id !== action.suivi.changes.id
  //   );
  //   return {
  //     ...state,
  //     suivis: [...removePrev, action.suivi.changes],
  //   };
  // }),
  on(ContactActions.updateSuiviFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  on(ContactActions.deleteSuivi, (state, action) => {
    return {
      ...state,
      suivis: state.suivis.filter((el: any) => el.id !== action.suiviId),
    };
  }),
  on(ContactActions.deleteSuiviFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //DOCUMENTS
  on(ContactActions.loadDocumentsSuccess, (state, action) => {
    return {
      ...state,
      documents: action.documents,
    };
  }),
  on(ContactActions.loadDocumentsFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  // on(ContactActions.addDocumentsSuccess, (state, action) => {
  //   return {
  //     ...state,
  //     documents: action.documents,
  //   };
  // }),
  on(ContactActions.addDocumentsFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  on(ContactActions.deleteDocument, (state, action) => {
    return {
      ...state,
      documents: state.documents.filter(
        (doc: any) => doc.id !== action.document.id
      ),
    };
  }),
  on(ContactActions.deleteDocumentFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //OPERATIONS
  on(ContactActions.loadOperationsSuccess, (state, action) => {
    return {
      ...state,
      operations: action.operations,
    };
  }),
  on(ContactActions.loadOperationsFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  // on(ContactActions.addOperationSuccess, (state, action) => {
  //   return {
  //     ...state,
  //     operations: [action.operation, ...state.operations],
  //   };
  // }),
  on(ContactActions.addOperationFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  on(ContactActions.deleteOperation, (state, action) => {
    return {
      ...state,
      operations: state.operations.filter(
        (o: OperationSmallId) => o.id !== action.operation.id
      ),
    };
  }),
  on(ContactActions.addOperationFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //ENTITES
  on(ContactActions.loadContactEntitesSuccess, (state, action) => {
    return {
      ...state,
      entites: action.entites,
    };
  }),
  on(ContactActions.addOperationFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //add
  on(ContactActions.addContactEntites, (state, action) => {
    return {
      ...state,
      entites: [...state.entites, action.entite],
    };
  }),
  on(ContactActions.addOperationFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //delete
  on(ContactActions.deleteContactEntites, (state, action) => {
    return {
      ...state,
      entites: state.entites.filter(
        (o: EntiteSMALLid) => o.id !== action.entite.id
      ),
    };
  }),
  on(ContactActions.addOperationFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //CLEAR
  on(ContactActions.clearContact, (state, action) => {
    return {
      ...state,
      contact: undefined,
    };
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
