import { UntypedFormBuilder } from '@angular/forms';
import { EntiteId } from './../../store/entite.model';
import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';

@Component({
  selector: 'app-entite-form-update',
  templateUrl: './entite-form-update.component.html',
  styleUrls: ['./entite-form-update.component.scss'],
})
export class EntiteFormUpdateComponent implements OnInit {
  @Input() entite: EntiteId | any = null;
  @Output() cancel = new EventEmitter<any>(false);

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {}

  formInit(): void {}

  formCreate(): void {}

  formSet(): void {}

  onUpdate(entite: EntiteId): void {}

  onCancel(): void {
    this.cancel.emit(true);
  }
}
