import { Store } from '@ngrx/store';
import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';
import * as fromUserAction from 'src/app/components/user/store/user.actions';

import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';
import { NzSelectModeType, NzSelectSizeType } from 'ng-zorro-antd/select';

@Component({
  selector: 'app-user-list-selector',
  templateUrl: './user-list-selector.component.html',
  styleUrls: ['./user-list-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserListSelectorComponent implements OnInit, OnChanges, OnDestroy {
  users$: Observable<any> = of([]);
  loading$: Observable<boolean> | any;
  subscribe = new Subject();
  userSelected: any = null;
  usersListSelected: any[] = [];
  default: string = '#bfbfbf';
  avatarColor: string = 'white';

  @Input() size: NzSelectSizeType = 'large';
  @Input() open$: Observable<boolean> = of(true);
  @Input() type$ = new BehaviorSubject<string>('team');
  @Input() mode: NzSelectModeType = 'default';
  @Input() borderless: boolean = false;
  @Input() placeholder: string = 'Sélectionner un collaborateur';

  @Output() select = new EventEmitter();
  @Output() cancel = new EventEmitter();

  constructor(private userStore: Store<UserState>) {}

  ngOnInit(): void {
    this.getUserLoading();
    this.getUsers();
    this.setUserType();
  }
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {
    this.subscribe.next(false);
    this.subscribe.complete();
  }

  trackBy(index: number, item: any): number {
    return item.id;
  }

  onSelectChange(user: any): void {
    if (!user) return;
    const users: any[] = this.userSelected;
    this.select.emit(users);
  }

  getUserLoading(): void {
    this.loading$ = this.userStore.select(fromUserSelector.loading);
  }

  setUserType(): void {
    this.type$.pipe(takeUntil(this.subscribe)).subscribe((type: string) => {
      if (!type) return;
      switch (type) {
        case 'team':
          this.onLoadTeam();
          break;
        case 'all':
          this.onLoadAllUsers();
          break;
        default:
          this.onLoadTeam();
          break;
      }
    });
  }
  onLoadTeam(): void {
    this.userStore.dispatch(fromUserAction.loadTeam());
  }

  onLoadAllUsers(): void {
    this.userStore.dispatch(fromUserAction.loadUsers());
  }

  getUsers(): void {
    this.users$ = this.userStore.select(fromUserSelector.team);
  }

  onCancel(): void {
    this.cancel.emit(true);
  }
}
