import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-contacts',
  templateUrl: './table-contacts.component.html',
  styleUrls: ['./table-contacts.component.scss'],
})
export class TableContactsComponent implements OnInit {
  @Input() scroll: any = { x: '1100px', y: '100%' };

  constructor() {}

  ngOnInit(): void {}
}
