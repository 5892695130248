import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';
import { UtilitiesState } from 'src/app/contents/components/utilities/store/utilities.reducer';
import * as fromUtilitiesSelector from 'src/app/contents/components/utilities/store/utilities.selectors';
import * as fromUtilitiesAction from 'src/app/contents/components/utilities/store/utilities.actions';

@Component({
  selector: 'app-participant-diagnostic-form-ressources',
  templateUrl: './participant-diagnostic-form-ressources.component.html',
  styleUrls: ['./participant-diagnostic-form-ressources.component.scss'],
})
export class ParticipantDiagnosticFormRessourcesComponent
  implements OnInit, OnChanges, OnDestroy
{
  subscribe = new Subject();

  //VARIABLES
  isFreinTitle: string =
    'Les ressources financières sont-elles un frein identifié ?';

  surendettementSansDossierTitle: string =
    'Situation de surendettement sans dépôt de dossier et/ou sans ressources';
  surendettementAvecDossierTitle: string =
    'Surendettement avec dossier déposé et suivi';
  besoinPrimaireTitle: string =
    'Besoins primaires assurés au jour le jour et/ou mesure de protection type curatelle';
  saitGererSonBudgetTitle: string =
    'Sait gérer son budget, avec capacité de financer par exemple un permis de conduire';

  //FORM FIELD
  ressourceForm: FormGroup = this.fb.group({});
  surendettementSansDossier: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );
  surendettementAvecDossier: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );
  besoinsPrimaires: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );

  saitGererSonBudget: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );
  surendettementSansDossierLibelle: FormControl<string | any> = new FormControl(
    'Situation de surendettement sans dépôt de dossier et/ou sans ressources'
  );
  surendettementAvecDossierLibelle: FormControl<string | any> = new FormControl(
    'Surendettement avec dossier déposé et suivi'
  );
  besoinPrimaireLibelle: FormControl<string | any> = new FormControl(
    'Besoins primaires assurés au jour le jour et/ou mesure de protection type curatelle'
  );
  saitGererSonBudgetLibelle: FormControl<string | any> = new FormControl(
    'Sait gérer son budget, avec capacité de financer par exemple un permis de conduire'
  );

  isFrein: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );

  //
  @Input() ressourceInput$ = new BehaviorSubject<any>(null);
  @Output() add = new EventEmitter<any>(false);

  constructor(
    private fb: FormBuilder,
    private utilitiesStore: Store<UtilitiesState>
  ) {}

  ngOnInit(): void {
    this.form();
    this.onFormChanges();
  }
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  form(): void {
    this.formInit();
    this.formCreate();
    this.formSet();
  }

  formInit(): void {
    this.surendettementSansDossier = new FormControl(
      false,
      Validators.required
    );
    this.surendettementAvecDossier = new FormControl(
      false,
      Validators.required
    );
    this.besoinsPrimaires = new FormControl(false, Validators.required);

    this.saitGererSonBudget = new FormControl(false, Validators.required);
    this.surendettementSansDossierLibelle = new FormControl(
      'Situation de surendettement sans dépôt de dossier et/ou sans ressources'
    );
    this.surendettementAvecDossierLibelle = new FormControl(
      'Surendettement avec dossier déposé et suivi'
    );
    this.besoinPrimaireLibelle = new FormControl(
      'Besoins primaires assurés au jour le jour et/ou mesure de protection type curatelle'
    );
    this.saitGererSonBudgetLibelle = new FormControl(
      'Sait gérer son budget, avec capacité de financer par exemple un permis de conduire'
    );

    this.isFrein = new FormControl<boolean>(false, Validators.required);
  }

  formCreate(): void {
    this.ressourceForm = this.fb.group({
      surendettementSansDossier: this.surendettementSansDossier,
      surendettementAvecDossier: this.surendettementAvecDossier,
      besoinsPrimaires: this.besoinsPrimaires,
      saitGererSonBudget: this.saitGererSonBudget,
      surendettementSansDossierLibelle: this.surendettementSansDossierLibelle,
      surendettementAvecDossierLibelle: this.surendettementAvecDossierLibelle,
      besoinPrimaireLibelle: this.besoinPrimaireLibelle,
      saitGererSonBudgetLibelle: this.saitGererSonBudgetLibelle,
      isFrein: this.isFrein,
    });
  }

  formSet(): void {
    this.ressourceInput$
      .pipe(takeUntil(this.subscribe))
      .subscribe((ressource) => {
        if (!ressource) return;
        this.ressourceForm.patchValue({ ...ressource });
      });
  }

  checkboxChanges(event: boolean, type: string): void {
    switch (type) {
      case 'surendettementSansDossier':
        this.ressourceForm.patchValue({
          surendettementSansDossier: event,
        });

        break;
      case 'surendettementAvecDossier':
        this.ressourceForm.patchValue({
          surendettementAvecDossier: event,
        });

        break;
      case 'besoinsPrimaires':
        this.ressourceForm.patchValue({
          besoinsPrimaires: event,
        });

        break;
      case 'saitGererSonBudget':
        this.ressourceForm.patchValue({
          saitGererSonBudget: event,
        });

        break;

      default:
        break;
    }
  }

  onFormChanges(): void {
    this.ressourceForm.valueChanges
      .pipe(takeUntil(this.subscribe))
      .subscribe((values) => this.add.emit(values));
  }
}
