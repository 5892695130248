<nz-card nzHoverable [nzTitle]="avatarIcon" (click)="onSelect(user)">
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="24"
      ><h4 nz-typography>{{ user?.displayName | carLimitation: 80 }}</h4>
    </nz-col>
  </nz-row>
  <nz-row nzAlign="top" nzGutter="8" nzJustify="start" class="fontionUser">
    <nz-col nzSpan="24">
      <small nz-typography>
        {{ user?.fonction | titlecase | carLimitation: 80 }}
      </small></nz-col
    >
  </nz-row>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="24"
      ><p nz-typography>
        {{
          user?.service?.title
            ? "Service : " +
              (user?.service?.title | titlecase | carLimitation: 40)
            : ""
        }}
      </p></nz-col
    >
  </nz-row>

  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="24">
      <nz-tag nzColor="gold" *ngIf="user?.claims?.admin"
        >Role : Administrateur</nz-tag
      >
      <nz-tag nzColor="geekblue" *ngIf="user?.claims?.member"
        >Role : Collaborateur</nz-tag
      >
      <nz-tag nzColor="cyan" *ngIf="user?.claims?.manager"
        >Role : Manger</nz-tag
      >
    </nz-col>
  </nz-row>
</nz-card>

<ng-template #avatarIcon>
  <nz-avatar
    [nzText]="user.avatar"
    [ngStyle]="{
      'background-color': user?.setting?.color ? user.setting?.color : bgColor
    }"
  ></nz-avatar>
</ng-template>
<ng-template #userCardExtra>
  <button nz-button nzShape="circle" nzType="text">
    <mat-icon>more_vert</mat-icon>
  </button>
</ng-template>
