import { TodosModule } from './../todos/todos.module';
import { FavoriesModule } from './../favories/favories.module';
import { ContentsModule } from './../../contents/contents.module';
import { MessagesModule } from './../messages/messages.module';
import { TicketModule } from './../tickets/ticket.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { RouterModule } from '@angular/router';
import { SharedModule } from './../../shared/shared.module';
import { UserService } from '../../service/user.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserComponent } from './components/user/user.component';
import * as fromUser from './store/user.reducer';
import { UserEffects } from './store/user.effects';
import { TicketsService } from 'src/app/service/tickets.service';
import { UserFormComponent } from './components/user-form/user-form.component';
import { MessagesService } from 'src/app/service/messages.service';
import { UserIndicatorsComponent } from './components/user-indicators/user-indicators.component';
import { UsersListComponent } from './components/users-list/users-list.component';
import { UserCardComponent } from './components/user-card/user-card.component';
import { UserDetailComponent } from './components/user-detail/user-detail.component';
import { UserInfosComponent } from './components/user-infos/user-infos.component';
import { UserListSelectorComponent } from './components/user-list-selector/user-list-selector.component';
import { NoResultModule } from 'src/app/contents/components/no-result/no-result.module';
import { CoordonneesModule } from 'src/app/contents/components/coordonnees/coordonnees.module';
import { CommunesFranceModule } from 'src/app/contents/components/communes-france/communes-france.module';
import { HeaderModule } from 'src/app/contents/components/header-title/header.module';
import { LayoutModule } from 'src/app/contents/components/layout/layout.module';
import { AvatarModule } from 'src/app/features/avatar/avatar.module';
import { UiModule } from 'src/app/ui/ui/ui.module';

@NgModule({
  declarations: [
    UserComponent,
    UserFormComponent,
    UserIndicatorsComponent,
    UsersListComponent,
    UserCardComponent,
    UserDetailComponent,
    UserInfosComponent,
    UserListSelectorComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    TicketModule,
    FavoriesModule,
    MessagesModule,
    TodosModule,
    SharedModule,
    ContentsModule,
    NoResultModule,
    CoordonneesModule,
    CommunesFranceModule,
    HeaderModule,
    LayoutModule,
    AvatarModule,
    UiModule,
    EffectsModule.forFeature([UserEffects]),
    StoreModule.forFeature(fromUser.usersFeatureKey, fromUser.reducer),
  ],
  providers: [UserService, TicketsService, MessagesService],
  exports: [
    UserComponent,
    UserFormComponent,
    UserIndicatorsComponent,
    UsersListComponent,
    UserCardComponent,
    UserDetailComponent,
    UserInfosComponent,
    UserListSelectorComponent,
  ],
})
export class UserModule {}
