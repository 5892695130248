import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActionSmallId } from '../../store/action.model';

@Component({
  selector: 'app-action-card',
  templateUrl: './action-card.component.html',
  styleUrls: ['./action-card.component.scss'],
})
export class ActionCardComponent implements OnInit {
  progressCompleteColor: string = '#49d49d';
  progressDefaultColor: string = '#062949';
  @Input() action: ActionSmallId | any;
  @Output() delete: EventEmitter<ActionSmallId | any> = new EventEmitter(false);
  constructor() {}

  ngOnInit(): void {}

  onSelectDropdown(event: any, action: any): void {
    switch (event) {
      case 'delete':
        this.onDelete(action);
        break;

      default:
        break;
    }
  }

  onDelete(action: any): void {
    if (!action) return;
    this.delete.emit(action);
  }
  onCancel(): void {}
}
