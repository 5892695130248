<ng-container *ngIf="participant">
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="center">
    <nz-col nzSpan="24">
      <app-time-indicators
        [start]="participant?.dateStart"
        [end]="participant?.dateEnd"
        [distance]="'month'"
        [info]="true"
        [step]="18"
        [dates]="true"
        [timeFormat]="'small'"
        [status]="participant?.sortiProgramme || participant?.sortiInscription"
      ></app-time-indicators>
    </nz-col>
  </nz-row>
  <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>

  <nz-page-header
    class="page-header"
    [nzTitle]="header"
    [nzSubtitle]="cardExtra"
  >
    <nz-page-header-extra> </nz-page-header-extra>
    <nz-page-header-content> </nz-page-header-content>
  </nz-page-header>
  <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>

  <ng-template #header>
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="24">
        <h4 nz-typography>{{ title }}</h4>
      </nz-col>
    </nz-row>
  </ng-template>
  <ng-template #cardExtra>
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="24">
        <!-- <nz-badge
            [nzCount]="participant?.prescripteur | size"
            [nzOverflowCount]="99"
          >
          </nz-badge> -->
      </nz-col>
    </nz-row>
  </ng-template>
</ng-container>
