import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';
import {
  OperationNote,
  OperationNoteId,
} from '../../store/operation-note.model';
import { OperationId } from 'src/app/components/operation/store/operation.model';
import { Timestamp } from 'firebase/firestore';
import { OperationNoteState } from '../../store/operation-note.reducer';
import * as fromOperationNoteAction from '../../store/operation-note.actions';
import { Store } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { fromUnixTime } from 'date-fns';

@Component({
  selector: 'app-operation-note-form',
  templateUrl: './operation-note-form.component.html',
  styleUrls: ['./operation-note-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationNoteFormComponent
  implements OnInit, OnChanges, OnDestroy
{
  subscribe = new Subject();
  @Input() operation$: Observable<OperationId | any> = of(null);
  @Input() note$: Observable<OperationNoteId | any> = of(null);
  @Output() cancel = new EventEmitter(false);
  @Output() add = new EventEmitter(false);
  @Output() update = new EventEmitter(false);

  //VARIABLES FORM
  isAdd: boolean = false;
  isUpdate: boolean = false;
  today: Date = new Date(Date.now());
  noteForm: UntypedFormGroup = this.fb.group({});
  auths: UntypedFormControl = new UntypedFormControl();
  auteur: UntypedFormControl = new UntypedFormControl();
  acces: UntypedFormControl = new UntypedFormControl();
  title: UntypedFormControl = new UntypedFormControl();
  type: UntypedFormControl = new UntypedFormControl();
  label: UntypedFormControl = new UntypedFormControl();
  dateStart: UntypedFormControl = new UntypedFormControl(this.today);
  dateUpdate: UntypedFormControl = new UntypedFormControl();
  adresse: UntypedFormControl = new UntypedFormControl();
  complement: UntypedFormControl = new UntypedFormControl();
  qpv: UntypedFormControl = new UntypedFormControl();
  operationId: UntypedFormControl = new UntypedFormControl();
  operationDenomination: UntypedFormControl = new UntypedFormControl();
  operationDescription: UntypedFormControl = new UntypedFormControl();
  operationlogoProgramme: UntypedFormControl = new UntypedFormControl();
  operationfinanceurs: UntypedFormControl = new UntypedFormControl();
  organisation: UntypedFormControl = new UntypedFormControl();
  userUpdate: UntypedFormControl = new UntypedFormControl();
  participants: UntypedFormControl = new UntypedFormControl();

  city: UntypedFormControl = new UntypedFormControl('');
  adresseLabel: UntypedFormControl = new UntypedFormControl('');
  adresseType: UntypedFormControl = new UntypedFormControl('');
  postcode: UntypedFormControl = new UntypedFormControl('');
  citycode: UntypedFormControl = new UntypedFormControl('');
  context: UntypedFormControl = new UntypedFormControl('');
  name: UntypedFormControl = new UntypedFormControl('');
  id: UntypedFormControl = new UntypedFormControl('');
  street: UntypedFormControl = new UntypedFormControl('');

  geometryCoordonate: UntypedFormControl = new UntypedFormControl([]);
  geometryType: UntypedFormControl = new UntypedFormControl('');

  utilities$: Observable<any> = of(null);
  adresseItem$ = new BehaviorSubject<any>(null);

  constructor(
    private fb: UntypedFormBuilder,
    private operationNoteStore: Store<OperationNoteState>
  ) {}

  ngOnInit(): void {
    this.onForm();
  }
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  onForm(): void {
    this.onFormInit();
    this.onFormCreate();
    this.onFormSet();
  }

  onFormInit(): void {
    this.auths = new UntypedFormControl();
    this.auteur = new UntypedFormControl();
    this.acces = new UntypedFormControl();
    this.title = new UntypedFormControl('', Validators.required);
    this.type = new UntypedFormControl('');
    this.label = new UntypedFormControl('');
    this.dateStart = new UntypedFormControl(this.today, Validators.required);
    this.dateUpdate = new UntypedFormControl('');
    this.adresse = new UntypedFormControl({});
    this.complement = new UntypedFormControl('');
    this.qpv = new UntypedFormControl('');
    this.operationId = new UntypedFormControl();
    this.operationDenomination = new UntypedFormControl();
    this.operationlogoProgramme = new UntypedFormControl();
    this.operationfinanceurs = new UntypedFormControl();
    this.operationDescription = new UntypedFormControl();
    this.organisation = new UntypedFormControl();
    this.participants = new UntypedFormControl();

    this.city = new UntypedFormControl('');
    this.adresseLabel = new UntypedFormControl('');
    this.adresseType = new UntypedFormControl('');
    this.postcode = new UntypedFormControl('');
    this.citycode = new UntypedFormControl('');
    this.context = new UntypedFormControl('');
    this.name = new UntypedFormControl('');
    this.id = new UntypedFormControl('');
    this.street = new UntypedFormControl('');

    this.geometryCoordonate = new UntypedFormControl([0, 0]);
    this.geometryType = new UntypedFormControl('');
  }

  onFormCreate(): void {
    this.noteForm = this.fb.group({
      auths: this.auths,
      auteur: this.auteur,
      acces: this.acces,
      title: this.title,
      type: this.type,
      label: this.label,
      dateStart: this.dateStart,
      dateUpdate: this.dateUpdate,
      adresse: new UntypedFormGroup({
        adresse: new UntypedFormGroup({
          properties: new UntypedFormGroup({
            city: this.city,
            label: this.adresseLabel,
            type: this.adresseType,
            postcode: this.postcode,
            citycode: this.citycode,
            context: this.context,
            name: this.name,
            id: this.id,
            street: this.street,
          }),
          geometry: new UntypedFormGroup({
            coordinates: this.geometryCoordonate,
            type: this.geometryType,
          }),
        }),
        complement: this.complement,
      }),
      participants: this.participants,
      operation: new UntypedFormGroup({
        id: this.operationId,
        denomination: this.operationDenomination,
        logoProgramme: this.operationlogoProgramme,
        description: this.operationDescription,
        financeurs: this.operationfinanceurs,
      }),
      organisation: this.organisation,
      userUpdate: this.userUpdate,
    });
  }

  onFormSet(): void {
    this.note$.pipe(takeUntil(this.subscribe)).subscribe((note) => {
      if (!note) {
        this.isAdd = true;
        this.isUpdate = false;
      } else {
        this.isAdd = false;
        this.isUpdate = true;

        this.onFormUpdate(note);
      }
    });
  }

  onFormUpdate(note: OperationNoteId): void {
    const { adresse } = note;
    const adresseItem = adresse.adresse;
    this.noteForm.patchValue({
      auths: note.auths,
      auteur: note.auteur,
      acces: note.acces,
      title: note.title,
      type: note?.type,
      label: note?.label,
      dateStart: fromUnixTime(note.dateStart['seconds']),
      dateUpdate: fromUnixTime(note.dateUpdate['seconds']),
      participants: note.participants,
      adresse: {
        adresse: note.adresse.adresse,
        complement: note.adresse.complement,
        qpv: note.adresse.qpv,
      },
      operation: note.operation,
      organisation: note.organisation,
      userUpdate: note.userUpdate,
    });
    this.adresseItem$.next(adresseItem);
  }

  compareType(o1: any, o2: any) {
    return o1 && o2 && o1.type === o2.type;
  }

  compareAdresse(o1: any, o2: any) {
    return o1 === o2 ? o1.properties.label === o2.properties.label : o1 === o2;
  }

  onAddAdresse(adresse: any): void {
    if (!adresse) return;

    this.noteForm.patchValue({
      adresse: {
        adresse: adresse,
      },
    });
  }

  onAddAdresseComplement(complement: any): void {
    if (!complement) return;

    this.noteForm.patchValue({
      adresse: {
        complement: complement,
      },
    });
  }

  onAdd(operation: OperationId): void {
    if (!this.noteForm.valid) return;

    const noteForm: any = this.noteForm.value;
    const dateStart = Timestamp.fromDate(noteForm.dateStart);

    const note = {
      title: noteForm.title,
      type: noteForm.type,
      label: noteForm.label,
      adresse: noteForm.adresse,
      dateStart: dateStart,
      operation: {
        id: operation.id,
      },
      participants: [],
    };

    this.operationNoteStore.dispatch(
      fromOperationNoteAction.addOperationNote({ note })
    );

    this.noteForm.reset();
    this.onCancel();
  }
  onUpdate(operation: OperationId, currentNote: OperationNoteId): void {
    if (!this.noteForm.valid || !operation || !currentNote) return;

    const noteForm: OperationNote = this.noteForm.value;
    const dateStart = Timestamp.fromDate(noteForm.dateStart);
    const dateUpdate = Timestamp.now();

    (noteForm.dateStart = dateStart), (noteForm.dateUpdate = dateUpdate);

    // const note = {
    //   id: currentNote.id,
    //   title: noteForm.title,
    //   type: noteForm.type,
    //   label: noteForm.label,
    //   adresse: noteForm.adresse,
    //   dateStart: dateStart,
    //   operation: {
    //     id: operation.id,
    //   },
    //   participants: [],
    //    dateStart: Timestamp

    // };

    const changes: OperationNoteId = { ...currentNote, ...noteForm };

    const note: Update<OperationNoteId> = {
      id: currentNote.id,
      changes: changes,
    };

    this.operationNoteStore.dispatch(
      fromOperationNoteAction.updateOperationNote({
        note,
      })
    );
    this.update.emit(true);
  }
  onCancel(): void {
    this.cancel.next(true);
  }
}
