import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  ParticipantSelectState,
  participantsSelectsFeatureKey,
  selectAll,
} from './participants-select.reducer';

export const selectParticipantsState =
  createFeatureSelector<ParticipantSelectState>(participantsSelectsFeatureKey);

export const participants = createSelector(selectParticipantsState, selectAll);
