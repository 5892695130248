<ng-container *ngIf="item | async as projetprofessionnel">
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="4">
      <nz-tag nzColor="geekblue"
        >CODE POSTAL | {{ (item | async)?.areaCode }}</nz-tag
      >
    </nz-col>
    <nz-col nzSpan="3">
      <nz-tag nzColor="geekblue"
        >ROME | {{ projetproEntiteForm.value.code }}</nz-tag
      >
    </nz-col>
    <nz-col nzSpan="3">
      <nz-tag nzColor="geekblue"
        >LAT |{{ projetproEntiteForm.value.latitude }}</nz-tag
      >
    </nz-col>
    <nz-col nzSpan="3">
      <nz-tag nzColor="geekblue"
        >LON |{{ projetproEntiteForm.value.longitude }}</nz-tag
      >
    </nz-col>
    <nz-col nzSpan="3">
      <form nz-form [formGroup]="projetproEntiteForm">
        <nz-form-item>
          <nz-form-label nzRequired>Distance</nz-form-label>
          <nz-form-control>
            <nz-select
              nzBorderless
              formControlName="distance"
              placeholder="Distance"
            >
              <nz-option
                *ngFor="let item of distanceSelectors"
                [nzLabel]="item"
                [nzValue]="item"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </form>
    </nz-col>
    <nz-col nzSpan="4">
      <form nz-form [formGroup]="projetproEntiteForm">
        <nz-form-item>
          <nz-form-label nzRequired>Type de contrat</nz-form-label>
          <nz-form-control>
            <nz-select
              nzBorderless
              formControlName="contract"
              nzPlaceHolder="Contrat"
            >
              <nz-option
                *ngFor="let item of contractSelectors"
                [nzLabel]="item.name"
                [nzValue]="item.value"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </form>
    </nz-col>
    <nz-col nzSpan="4">
      <button nz-button nzBlock nzType="primary" (click)="validedSearch()">
        <i nz-icon nzType="search"></i>
        <span>Rechercher</span>
      </button>
    </nz-col>
  </nz-row>

  <nz-divider
    nzOrientation="center"
    nzType="horizontal"
    [nzText]="companiesCount"
  ></nz-divider>
  <ng-template #companiesCount>
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="center">
      <nz-col nzSpan="6">
        <button
          nz-button
          nzShape="circle"
          nzSize="small"
          *ngIf="entites$ | async as entites"
          (click)="onPaggination('prev', entites.total)"
        >
          <i nz-icon nzType="left"></i>
        </button>
      </nz-col>
      <nz-col nzSpan="12">
        <nz-badge
          *ngIf="!(loading$ | async)"
          [nzOverflowCount]="25"
          [nzCount]="(entites$ | async)?.total"
        ></nz-badge>
        <i
          nz-icon
          nzType="loading"
          class="loading"
          *ngIf="loading$ | async"
        ></i>
      </nz-col>
      <nz-col nzSpan="6">
        <button
          *ngIf="entites$ | async as entites"
          nz-button
          nzShape="circle"
          nzSize="small"
          (click)="onPaggination('next', entites.total)"
        >
          <i nz-icon nzType="right"></i>
        </button>
      </nz-col>
    </nz-row>
  </ng-template>

  <app-layout-list
    *ngIf="(entites$ | async)?.companies | size; else noResults"
    [card]="card"
    [source]="(entites$ | async)?.companies"
  ></app-layout-list>

  <ng-template #new>
    <app-card-new
      *ngIf="item | async as projetprofessionnel"
      [type]="'school'"
      [title]="newCardTitle"
      [description]="newCardDescription"
      [definition]="newCardDefinition"
      [width]="'300px'"
      [height]="'400'"
      (add)="onNew(participant, projetprofessionnel)"
      (back)="onBack()"
      (cancel)="onBack()"
    ></app-card-new>
  </ng-template>

  <ng-template #card let-item let-position="idx">
    <nz-card
      nzHoverable
      nzSize="small"
      class="formationSuggestion_card"
      (click)="onSelect(item, projetprofessionnel, participant)"
    >
      <div class="formation_title">
        {{ item?.name | carLimitation: 40 }}
      </div>
      <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>

      <div class="formation_subtitle">
        <strong>{{ item?.naf_text | carLimitation: 45 }}</strong>
      </div>
      <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>

      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col nzSpan="24"
          ><small>{{ item?.contact_mode }}</small></nz-col
        >
      </nz-row>

      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col nzSpan="24">
          <small>{{ item?.address | carLimitation: 40 }}</small>
        </nz-col>
      </nz-row>
      <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
      <nz-row nzAlign="middle" nzGutter="8" nzJustify="center">
        <nz-col nzSpan="12">
          <mat-icon *ngIf="item?.alternance" class="possibility"
            >school</mat-icon
          >
        </nz-col>
        <nz-col nzSpan="12">
          <mat-icon *ngIf="item?.parrainage" class="possibility"
            >school</mat-icon
          >
        </nz-col>
      </nz-row>
    </nz-card>
  </ng-template>

  <ng-template #noResults>
    <app-no-result
      [icon]="'school'"
      [addtitle]="'Ajouter une entité au projet professionnel'"
      [notitle]="'Aucun une entité'"
      [isButton]="false"
    ></app-no-result>
  </ng-template>
</ng-container>
