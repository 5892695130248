<form nz-form [formGroup]="qualificationForm" nzLayout="vertical">
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="24">
      <nz-form-item style="width: 100%">
        <nz-form-label [nzSpan]="24" nzFor="qualification"
          >Niveau de diplômes
        </nz-form-label>
        <nz-form-control>
          <nz-select
            formControlName="qualification"
            placeholder="Formation"
            nzBorderless
            nzPlaceHolder="Formation"
            nzServerSearch
            [nzLoading]="(diploma$ | async | size) ? false : true"
            [compareWith]="compareDiplomaLevele"
          >
            <nz-option
              *ngFor="let item of diploma$ | async"
              [nzLabel]="item.label"
              [nzValue]="item"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>

  <nz-card nzSize="small" nzTitle="Maîtrise de la langue française">
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="6">
        <app-check-card
          [title]="'Illétrisme'"
          [isActive]="qualificationForm.value.isFrenchLevelIlletrisme"
          [definition]="isFrenchLevelIlletrismeTitle"
          [height]="'60px'"
          [fontSize]="'14px'"
          (select)="checkboxChanges($event, 'isFrenchLevelIlletrisme')"
        ></app-check-card>
      </nz-col>
      <nz-col nzSpan="6">
        <app-check-card
          [title]="'FLE'"
          [isActive]="qualificationForm.value.isFrenchLevelFle"
          [definition]="isFrenchLevelFleTitle"
          [height]="'60px'"
          [fontSize]="'14px'"
          (select)="checkboxChanges($event, 'isFrenchLevelFle')"
        ></app-check-card>
      </nz-col>
      <nz-col nzSpan="6">
        <app-check-card
          [title]="'Laborieuse'"
          [isActive]="qualificationForm.value.isFrenchLevelLaborieux"
          [definition]="isFrenchLevelLaborieuxTitle"
          (select)="checkboxChanges($event, 'isFrenchLevelLaborieux')"
          [height]="'60px'"
          [fontSize]="'14px'"
        ></app-check-card>
      </nz-col>
      <nz-col nzSpan="6">
        <app-check-card
          [title]="'Parfaite maîtrise'"
          [isActive]="qualificationForm.value.isFrenchLevelParfait"
          [definition]="isFrenchLevelParfaitTitle"
          (select)="checkboxChanges($event, 'isFrenchLevelParfait')"
          [height]="'60px'"
          [fontSize]="'14px'"
        ></app-check-card>
      </nz-col>
    </nz-row>
    <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
    <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
      <nz-col nzSpan="24">
        <nz-form-item>
          <nz-form-label [nzSpan]="24" nzFor="maitriseFrancais"
            >Niveau de français
          </nz-form-label>

          <nz-form-control>
            <nz-select
              formControlName="maitriseFrancais"
              placeholder="Niveau de français"
              nzBorderless
              nzPlaceHolder="Niveau de français"
              nzServerSearch
              [compareWith]="compareFrenchLevel"
            >
              <nz-option-group
                *ngFor="let group of (french_level$ | async)?.data"
                [nzLabel]="group.type"
              >
                <nz-option
                  nzCustomContent="true"
                  *ngFor="let item of group.niveau"
                  [nzLabel]="item.item"
                  [nzValue]="item"
                  nz-tooltip
                  [nzTooltipMouseEnterDelay]="0.5"
                  [nzTooltipTitle]="item?.info"
                  nzTooltipPlacement="right"
                  ><p>
                    {{ item.item }}
                  </p>
                </nz-option>
              </nz-option-group>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </nz-col>
    </nz-row>
  </nz-card>

  <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>

  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="24">
      <nz-form-item>
        <nz-form-control>
          <label nz-checkbox formControlName="isFrein" class="itemCenter">{{
            isFreinTitle
          }}</label>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>
</form>
