import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Contrat } from './contrat.model';

export const loading = createAction(
  '[Contrat/API] Loading Contrats',
  props<{ loading: boolean }>()
);

//TYPES CONTRATS
export const loadTypesContrats = createAction(
  '[Contrat/API] Load Types Contrats'
);
export const loadTypesContratsSuccess = createAction(
  '[Contrat/API] Load Types Contrats Success',
  props<{ types: any[] }>()
);
export const loadTypesContratsFailure = createAction(
  '[Contrat/API] Load Types Contrats Failure',
  props<{ error: any }>()
);

//NATURE CONTRATS
export const loadNaturesContrats = createAction(
  '[Contrat/API] Load Natures Contrats'
);
export const loadNaturesContratsSuccess = createAction(
  '[Contrat/API] Load Natures Contrats Success',
  props<{ natures: any[] }>()
);
export const loadNaturesContratsFailure = createAction(
  '[Contrat/API] Load Natures Contrats Failure',
  props<{ error: any }>()
);

//NIVEAU EXPEREINCE
export const loadNiveauExperiences = createAction(
  '[Contrat/API] Load Niveau Experience'
);
export const loadNiveauExperiencesSuccess = createAction(
  '[Contrat/API] Load Niveau Experience Success',
  props<{ experiences: any[] }>()
);
export const loadNiveauExperiencesFailure = createAction(
  '[Contrat/API] Load Niveau Experience Failure',
  props<{ error: any }>()
);

//NIVEAU SALAIRE
export const loadNiveauSalaire = createAction(
  '[Contrat/API] Load Niveau Salaire'
);
export const loadNiveauSalaireSuccess = createAction(
  '[Contrat/API] Load Niveau Salaire Success',
  props<{ salaires: any[] }>()
);
export const loadNiveauSalaireFailure = createAction(
  '[Contrat/API] Load Niveau Salaire Failure',
  props<{ error: any }>()
);

//PERMIS
export const loadPermis = createAction('[Contrat/API] Load Permis');
export const loadPermisSuccess = createAction(
  '[Contrat/API] Load Permis Success',
  props<{ permis: any[] }>()
);
export const loadPermisFailure = createAction(
  '[Contrat/API] Load Permis Failure',
  props<{ error: any }>()
);

//NIVEAU NUMERIQUE
export const loadLevelNumerique = createAction(
  '[Contrat/API] Load Level Numerique'
);
export const loadLevelNumeriqueSuccess = createAction(
  '[Contrat/API] Load Level Numerique Success',
  props<{ numerique: any[] }>()
);
export const loadLevelNumeriqueFailure = createAction(
  '[Contrat/API] Load Level Numerique Failure',
  props<{ error: any }>()
);

//COMPLEMENT SALAIRE
export const loadComplementSalaire = createAction(
  '[Contrat/API] Load Complement Salaire'
);
export const loadComplementSalaireSuccess = createAction(
  '[Contrat/API] Load Complement Salaire Success',
  props<{ complement: any[] }>()
);
export const loadComplementSalaireFailure = createAction(
  '[Contrat/API] Load Complement Salaire Failure',
  props<{ error: any }>()
);

//TYPES HORAIRES
export const loadTypesHoraire = createAction(
  '[Contrat/API] Load Types Horaires'
);
export const loadTypesHoraireSuccess = createAction(
  '[Contrat/API] Load Types Horaires Success',
  props<{ horaires: any[] }>()
);
export const loadTypesHoraireFailure = createAction(
  '[Contrat/API] Load Types Horaires Failure',
  props<{ error: any }>()
);

//MOBILITY GEOGRAPHIC
export const loadMobilityGeographic = createAction(
  '[Contrat/API] Load Mobility Geographic'
);
export const loadMobilityGeographicSuccess = createAction(
  '[Contrat/API] Load Mobility Geographic Success',
  props<{ mobilities: any[] }>()
);
export const loadMobilityGeographicFailure = createAction(
  '[Contrat/API] Load Mobility Geographic Failure',
  props<{ error: any }>()
);

//JOURS TRAVAILLÉS
export const loadContratJours = createAction(
  '[Contrat/API] Load Contrat Jours'
);
export const loadContratJoursSuccess = createAction(
  '[Contrat/API] Load Contrat Jours Success',
  props<{ jours: string[] }>()
);
export const loadContratJoursFailure = createAction(
  '[Contrat/API] Load Contrat Jours Failure',
  props<{ error: any }>()
);

//EVOLUTIONS TYPES
export const loadEvolutionTypes = createAction(
  '[Contrat/API] Load Evolution types'
);
export const loadEvolutionTypesSuccess = createAction(
  '[Contrat/API] Load Evolution types Success',
  props<{ evolutions: any[] }>()
);
export const loadEvolutionTypesFailure = createAction(
  '[Contrat/API] Load Evolution types Failure',
  props<{ error: any }>()
);
