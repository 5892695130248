import { ItemEventId } from '../../../features/suivi/components/item-event/itemEvent.model';
import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as ActionActions from './action.actions';
import { ActionSmallId, ActionId } from './action.model';
import { DocumentId } from 'src/app/models/document.model';

export const actionsFeatureKey = 'actions';

export interface ActionState extends EntityState<ActionSmallId> {
  action: ActionId | any;
  actionUtils: any;
  participants: any;
  entites: any;
  contacts: any;
  suivis: ItemEventId[] | any;
  suivi: ItemEventId | any;
  documents: DocumentId[] | any;
  document: DocumentId | any;
  actionsByModule: ActionId[] | any;
  loading: boolean;
  utils: any;
  error: any;
}

export const adapter: EntityAdapter<ActionSmallId> =
  createEntityAdapter<ActionSmallId>();

export const initialState: ActionState = adapter.getInitialState({
  action: undefined,
  actionUtils: undefined,

  participants: undefined,
  entites: undefined,
  contacts: undefined,
  suivis: undefined,
  suivi: undefined,
  documents: undefined,
  document: undefined,
  actionsByModule: undefined,
  loading: false,
  utils: undefined,
  error: undefined,
});

export const reducer = createReducer(
  initialState,
  on(ActionActions.loadActions, (state, action) => {
    return {
      ...state,
      action: undefined,
      types: undefined,

      participants: undefined,
      entites: undefined,
      contacts: undefined,
      suivis: undefined,
      suivi: undefined,
      documents: undefined,
      document: undefined,
      error: undefined,
    };
  }),
  on(ActionActions.loadActionsSuccess, (state, action) =>
    adapter.setAll(action.actions, state)
  ),
  on(ActionActions.loadActionsFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  // LOAD TYPES
  on(ActionActions.loadActionTypesSuccess, (state, action) => {
    return {
      ...state,
      actionUtils: action.types,
    };
  }),

  on(ActionActions.loadActionTypesFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //UTILS
  on(ActionActions.loadUtilsSuccess, (state, action) => {
    return {
      ...state,
      utils: action.utils,
    };
  }),

  on(ActionActions.loadUtilsFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //QUERY ACTION
  on(ActionActions.queryActionsSuccess, (state, action) =>
    adapter.setAll(action.actions, state)
  ),
  on(ActionActions.queryActionsFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //QUERY ACTIONS BY MODULE
  on(ActionActions.queryActionsByModule, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(ActionActions.queryActionsByModuleSuccess, (state, action) => {
    return {
      ...state,
      actionsByModule: action.actions,
    };
  }),
  on(ActionActions.queryActionsByModuleFailure, (state, action) => {
    return {
      ...state,
      loading: false,
    };
  }),

  //LOAD ACTION
  on(ActionActions.loadActionSuccess, (state, action) => {
    return {
      ...state,
      action: action.action,
    };
  }),
  on(ActionActions.loadActionFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  // on(ActionActions.addAction,
  //   (state, action) => adapter.addOne(action.action, state)
  // ),
  // on(ActionActions.updateAction,
  //   (state, action) => adapter.updateOne(action.action, state)
  // ),

  //DELETE
  on(ActionActions.deleteAction, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(ActionActions.deleteActionFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //DELETE ALL
  on(ActionActions.deleteActions, (state, action) => adapter.removeAll(state)),
  on(ActionActions.deleteActionsFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //SUIVIS
  on(ActionActions.loadSuivisSuccess, (state, action) => {
    return {
      ...state,
      suivis: action.suivis,
    };
  }),
  on(ActionActions.loadSuivisFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  on(ActionActions.addSuiviSuccess, (state, action) => {
    return {
      ...state,
      suivis: [...state.suivis, action.suivi],
    };
  }),
  on(ActionActions.addSuiviFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  on(ActionActions.updateSuivi, (state, action) => {
    const removePrev = state.suivis.filter(
      (el: any) => el.id !== action.suivi.changes.id
    );
    return {
      ...state,
      suivis: [...removePrev, action.suivi.changes],
    };
  }),
  on(ActionActions.updateSuiviFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  on(ActionActions.deleteSuivi, (state, action) => {
    return {
      ...state,
      suivis: state.suivis.filter((el: any) => el.id !== action.suiviId),
    };
  }),
  on(ActionActions.deleteSuiviFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //DOCUMENTS
  on(ActionActions.loadDocumentsSuccess, (state, action) => {
    return {
      ...state,
      documents: action.documents,
    };
  }),
  on(ActionActions.loadDocumentsFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  on(ActionActions.addDocumentsSuccess, (state, action) => {
    return {
      ...state,
      documents: action.documents,
    };
  }),
  on(ActionActions.addDocumentsFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  on(ActionActions.deleteDocument, (state, action) => {
    return {
      ...state,
      documents: state.documents.filter(
        (doc: any) => doc.id !== action.document.id
      ),
    };
  }),
  on(ActionActions.deleteDocumentFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  on(ActionActions.clearActions, (state) => adapter.removeAll(state)),
  on(ActionActions.clearAction, (state, action) => {
    return {
      ...state,
      action: undefined,
    };
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
