<nz-steps
  [nzCurrent]="currentStep"
  nzSize="small"
  nzDirection="horizontal"
  nzLabelPlacement="horizontal"
  nzType="navigation"
  (nzIndexChange)="this.currentStep = $event"
>
  <nz-step [nzTitle]="'Métier'"> </nz-step>
  <nz-step [nzTitle]="'Entreprise'"> </nz-step>
  <nz-step [nzTitle]="'Lieu'"> </nz-step>
  <nz-step [nzTitle]="'Dates'"> </nz-step>
  <nz-step [nzTitle]="'Valider'"> </nz-step>
</nz-steps>
<nz-space nzSize="middle" nzDirection="vertical"></nz-space>

<div [ngSwitch]="currentStep">
  <div *ngSwitchCase="0">
    <nz-card>
      <app-metiers
        [type]="'grandDomaine'"
        [showTitle]="false"
        [showMetiers]="true"
        [showAppelations]="true"
        [showAppelation]="true"
        [showSoftskills]="true"
        [hideCancel]="false"
        [hideValidation]="true"
        [showQualification]="true"
        [showAppelationDetail]="true"
        [showAdresse]="false"
        (appelation)="onAddMetier($event)"
      ></app-metiers>
      <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>

      <!-- <app-participant-diagnostic-form-btp></app-participant-diagnostic-form-btp> -->
    </nz-card>
  </div>
  <div *ngSwitchCase="1">
    <nz-card>
      <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
        <nz-col nzSpan="12"></nz-col>
        <nz-col nzSpan="12"></nz-col>
      </nz-row>
      <app-entite-search></app-entite-search>
    </nz-card>
  </div>
  <div *ngSwitchCase="2">
    <nz-card>
      <app-communes-france
        [isComplement]="false"
        [borderless]="true"
      ></app-communes-france>
    </nz-card>
  </div>
  <div *ngSwitchCase="3"><nz-card>Dates</nz-card></div>
  <div *ngSwitchCase="4"><nz-card>Valider</nz-card></div>

  <div *ngSwitchDefault></div>
</div>
