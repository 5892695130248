import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, of, Subject, takeUntil } from 'rxjs';
import { UtilitiesState } from 'src/app/contents/components/utilities/store/utilities.reducer';
import { v4 as uuidV4 } from 'uuid';
import * as fromUtilitiesAction from 'src/app/contents/components/utilities/store/utilities.actions';
import * as fromUtilitiesSelector from 'src/app/contents/components/utilities/store/utilities.selectors';

@Component({
  selector: 'app-fiches-de-poste-form-contact',
  templateUrl: './fiches-de-poste-form-contact.component.html',
  styleUrls: ['./fiches-de-poste-form-contact.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FichesDePosteFormContactComponent implements OnInit, OnDestroy {
  subscribe = new Subject();
  noDataTitle: string = 'Contacts entreprise';
  contactsCtl: FormArray = this.fb.array<
    {
      id: string;
      civilite: string;
      lastName: string;
      firstName: string;
      fonction: string;
      role: string;
      coordonnees: {
        mobile: string;
        phoneNumber: string;
        email: string;
        fixe: string;
      };
    }[]
  >([]);
  civilite$: Observable<any> = of([]);

  ficheDePosteContactForm = new FormGroup({
    contacts: this.contactsCtl,
  });

  @Input() contacts: any;
  @Output() onCurrentChanges = new EventEmitter<any>(false);

  constructor(
    private fb: FormBuilder,
    private utilitiesStore: Store<UtilitiesState>
  ) {}

  ngOnInit(): void {
    this.onChange();
    this.loadUtilitiesCivilite();
    this.getUtilitiesCivilite();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.formUpdateSet(changes.contacts.currentValue);
  }

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  onPersonChange(item: any, index: number): void {
    const { civilite, lastName, firstName, coordonnees, fonction, role } = item;

    const itemForm = {
      id: uuidV4(),
      civilite: civilite,
      lastName: lastName,
      firstName: firstName,
      fonction: fonction,
      role: role,
      coordonnees: coordonnees,
    };

    this.contactsCtl.at(index).patchValue({ ...itemForm });
  }

  formUpdateSet(contacts: any[]): void {
    if (!contacts?.length) return;
    contacts.forEach((contact) => this.onContactUpdate(contact));
  }

  compareCivilite(item1: any, item2: any) {
    return item1 === item2;
  }

  get contactsArrayControler() {
    return this.ficheDePosteContactForm.get('contacts') as FormArray;
  }

  loadUtilitiesCivilite(): void {
    this.utilitiesStore.dispatch(fromUtilitiesAction.loadCivilities());
  }
  getUtilitiesCivilite(): void {
    this.civilite$ = this.utilitiesStore.select(
      fromUtilitiesSelector.civilities
    );
  }

  onAdd(): void {
    const itemForm: FormGroup = this.fb.group({
      id: [uuidV4(), Validators.required],
      civilite: ['', Validators.required],
      lastName: ['', Validators.required],
      firstName: ['', Validators.required],
      fonction: ['', Validators.required],
      role: ['', Validators.required],
      coordonnees: this.fb.group({
        fixe: [''],
        mobile: ['', Validators.required],
        phoneNumber: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
      }),
    });

    this.contactsCtl.insert(0, itemForm);
  }
  onContactUpdate(contact: any): void {
    const { civilite, lastName, firstName, fonction, role, id, coordonnees } =
      contact;

    const itemForm: FormGroup = this.fb.group({
      id: [id, Validators.required],
      civilite: [civilite, Validators.required],
      lastName: [lastName, Validators.required],
      firstName: [firstName, Validators.required],
      fonction: [fonction, Validators.required],
      role: [role, Validators.required],
      coordonnees: this.fb.group({
        fixe: [coordonnees?.fixe],
        mobile: [coordonnees?.mobile, Validators.required],
        phoneNumber: [coordonnees?.phoneNumber, Validators.required],
        email: [coordonnees?.email, [Validators.required, Validators.email]],
      }),
    });

    this.contactsCtl.insert(0, itemForm);
  }

  onChangesCoordonnees(coordonnees: any, index: number): void {
    this.contactsCtl.at(index).patchValue({
      coordonnees: {
        fixe: coordonnees?.fixe ? coordonnees.fixe : '',
        mobile: coordonnees?.mobile ? coordonnees.mobile : '',
        phoneNumber: coordonnees?.phoneNumber ? coordonnees.phoneNumber : '',
        email: coordonnees?.email ? coordonnees.email : '',
      },
    });
  }

  onChangesCoordonneesEmail(email: any, index: number): void {
    this.contactsCtl.at(index).patchValue({
      coordonnees: {
        email: email ? email : '',
      },
    });
  }
  onChangesCoordonneesFixe(fixe: any, index: number): void {
    this.contactsCtl.at(index).patchValue({
      coordonnees: {
        fixe: fixe ? fixe : '',
      },
    });
  }

  onRemove(index: number): void {
    this.contactsCtl.removeAt(index);
  }

  onRemoveAll(): void {
    this.contactsCtl.clear();
  }

  onChange(): void {
    this.ficheDePosteContactForm
      .get('contacts')
      ?.valueChanges.pipe(takeUntil(this.subscribe))
      .subscribe((contacts) => {
        this.onCurrentChanges.emit(contacts);
      });
  }
}
