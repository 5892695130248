import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { OperationFicheNote } from './operation-fiche-note.model';

//LOAD ALL
export const loadOperationFicheNotes = createAction(
  '[OperationFicheNote/API] Load OperationFicheNotes',
  props<{ operationId: string; ficheId: string }>()
);

export const loadOperationFicheNotesSuccess = createAction(
  '[OperationFicheNote/API] Load OperationFicheNotes Success',
  props<{ notes: any }>()
);

export const loadOperationFicheNotesFailure = createAction(
  '[OperationFicheNote/API] Load OperationFicheNotes Failiure',
  props<{ error: any }>()
);

//LOAD ONE
export const loadOperationFicheNote = createAction(
  '[OperationFicheNote/API] Load OperationFicheNote',
  props<{ operationId: string; ficheId: string; noteId: string }>()
);

export const loadOperationFicheNoteSuccess = createAction(
  '[OperationFicheNote/API] Load OperationFicheNote Success',
  props<{ note: any }>()
);

export const loadOperationFicheNoteFailure = createAction(
  '[OperationFicheNote/API] Load OperationFicheNote Failiure',
  props<{ error: any }>()
);

//ADD ONE
export const addOperationFicheNote = createAction(
  '[OperationFicheNote/API] Add OperationFicheNote',
  props<{ operationId: string; ficheId: string; note: any }>()
);
export const addOperationFicheNoteSuccess = createAction(
  '[OperationFicheNote/API] Add OperationFicheNote Success',
  props<{ note: any }>()
);
export const addOperationFicheNoteFailure = createAction(
  '[OperationFicheNote/API] Add OperationFicheNote Failure',
  props<{ error: any }>()
);

//UPDATE ONE
export const updateOperationFicheNote = createAction(
  '[OperationFicheNote/API] Update OperationFicheNote',
  props<{ operationId: string; ficheId: string; note: Update<any> }>()
);
export const updateOperationFicheNoteSuccess = createAction(
  '[OperationFicheNote/API] Update OperationFicheNoteSuccess',
  props<{ success: any }>()
);
export const updateOperationFicheNoteFailure = createAction(
  '[OperationFicheNote/API] Update OperationFicheNote Failure',
  props<{ error: any }>()
);

//DELETE ONE
export const deleteOperationFicheNote = createAction(
  '[OperationFicheNote/API] Delete OperationFicheNote',
  props<{ operationId: string; ficheId: string; noteId: string }>()
);
export const deleteOperationFicheNoteSuccess = createAction(
  '[OperationFicheNote/API] Delete OperationFicheNote Success',
  props<{ success: any }>()
);
export const deleteOperationFicheNoteFailure = createAction(
  '[OperationFicheNote/API] Delete OperationFicheNote Failure',
  props<{ error: any }>()
);

//DELETE ALL
export const deleteOperationFicheNotes = createAction(
  '[OperationFicheNote/API] Delete OperationFicheNotes',
  props<{ operationId: string; ficheId: string; ids: string[] }>()
);
export const deleteOperationFicheNotesSuccess = createAction(
  '[OperationFicheNote/API] Delete OperationFicheNotes Success',
  props<{ success: any }>()
);
export const deleteOperationFicheNotesFailure = createAction(
  '[OperationFicheNote/API] Delete OperationFicheNotes Failure',
  props<{ error: any }>()
);

//CLEAR ALL
export const clearOperationFicheNotes = createAction(
  '[OperationFicheNote/API] Clear OperationFicheNotes'
);
