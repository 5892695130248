import { UserState } from 'src/app/components/user/store/user.reducer';
import * as fromUserSelector from 'src/app/components/user/store/user.selectors';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { add } from 'date-fns';
import { NzMessageService } from 'ng-zorro-antd/message';
import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';
import { Timestamp } from 'firebase/firestore';
import {
  NzNotificationPlacement,
  NzNotificationService,
} from 'ng-zorro-antd/notification';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-task-form',
  templateUrl: './task-form.component.html',
  styleUrls: ['./task-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskFormComponent implements OnInit, OnChanges, OnDestroy {
  users$: Observable<any[]> = of([]);

  subscribe = new Subject();
  textInput: string = '';
  today: Date = new Date(Date.now());
  dates: Date[] = [this.today, add(this.today, { days: 2 })];
  datesFormat: string = 'dd/MM/yyyy';
  datesPalceholders: string[] = ['Démarrage', 'Livraison'];
  NOTIFICATION_PLACEMENT: NzNotificationPlacement = 'topRight';
  badgeCounterStyle = {
    backgroundColor: '#ffab00',
    color: 'black',
    fontWeigth: 'bold',
  };

  taskFormTitle: string = 'Nouvelle planification';
  taskFormMessage: string = '';
  taskForm = this.fb.group({});
  title = new UntypedFormControl();
  messages = new UntypedFormControl();
  description = new UntypedFormControl();
  texts = new UntypedFormArray([]);
  auteur = new UntypedFormControl();
  auths = new UntypedFormControl();
  ids = new UntypedFormControl();
  users = new UntypedFormControl();
  completed = new UntypedFormControl();
  tested = new UntypedFormControl();
  verifed = new UntypedFormControl();
  inprogress = new UntypedFormControl();
  userUpdate = new UntypedFormControl();
  priority = new UntypedFormControl(false);
  dateStart = new UntypedFormControl();
  dateUpdate = new UntypedFormControl();
  dateEnd = new UntypedFormControl();
  organisation = new UntypedFormControl();
  item = new UntypedFormControl();

  @Output() cancel = new EventEmitter<boolean>();
  @Output() add = new EventEmitter<any>();

  @Input() modalVisibility = new BehaviorSubject<boolean>(false);
  @Input() usersType$ = new BehaviorSubject<string>('');

  constructor(
    private fb: UntypedFormBuilder,
    private storeUser: Store<UserState>,
    private notification: NzNotificationService
  ) {}

  ngOnInit(): void {
    this.form();
  }
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  form(): void {
    this.formInit();
    this.formCreate();
  }

  formInit(): void {
    this.texts = new UntypedFormArray([]);
    this.title = new UntypedFormControl('', Validators.required);
    this.messages = new UntypedFormControl([]);
    this.description = new UntypedFormControl();
    this.auteur = new UntypedFormControl();
    this.auths = new UntypedFormControl();
    this.ids = new UntypedFormControl([]);
    this.users = new UntypedFormControl([]);
    this.completed = new UntypedFormControl(false, Validators.required);
    this.tested = new UntypedFormControl(false, Validators.required);
    this.verifed = new UntypedFormControl(false, Validators.required);
    this.inprogress = new UntypedFormControl(false, Validators.required);
    this.userUpdate = new UntypedFormControl();
    this.priority = new UntypedFormControl(false);
    this.dateStart = new UntypedFormControl(this.dates[0], Validators.required);
    this.dateUpdate = new UntypedFormControl();
    this.dateEnd = new UntypedFormControl(this.dates[1], Validators.required);
    this.organisation = new UntypedFormControl({});
    this.item = new UntypedFormControl({});
  }

  formCreate(): void {
    this.taskForm = this.fb.group({
      title: this.title,
      messages: this.messages,
      description: this.description,
      texts: this.texts,
      auteur: this.auteur,
      auths: this.auths,
      ids: this.ids,
      users: this.users,
      completed: this.completed,
      tested: this.tested,
      verifed: this.verifed,
      inprogress: this.inprogress,
      userUpdate: this.userUpdate,
      priority: this.priority,
      dateStart: this.dateStart,
      dateUpdate: this.dateUpdate,
      dateEnd: this.dateEnd,
      organisation: this.organisation,
      item: this.item,
    });
  }

  get textsArray() {
    return this.taskForm.get('texts') as UntypedFormArray;
  }

  ngModelChangeText(event: any): void {
    this.taskFormMessage = '';
  }

  addText(): void {
    const newText = this.fb.group({
      id: [uuidv4()],
      value: [this.textInput],
      status: [false],
    });

    this.texts.push(newText);
    this.textInput = '';
    this.taskFormMessage = '';
  }

  removeText(index: number): void {
    this.texts.removeAt(index);
  }

  onChangeDates(event: Date[]): void {
    const start: Date = event[0] ? event[0] : this.dates[0];
    const end: Date = event[1] ? event[1] : this.dates[1];

    this.taskForm.patchValue({
      dateStart: start,
      dateEnd: end,
    });
  }

  addUsers(users: any[]): void {
    if (!users?.length) {
      return;
    }

    this.taskForm.patchValue({
      users: users,
      ids: users.map((user) => user.id),
    });
  }

  addTask(): void {
    if (!this.taskForm.valid) {
      return;
    }

    const formValue: any = this.taskForm.value;

    if (!formValue.title) {
      const message: string = 'Veuillez ajouter une tâche !';
      this.taskFormMessage = message;
      this.notification.warning('Attention', message, {
        nzDuration: 6000,
        nzPlacement: this.NOTIFICATION_PLACEMENT,
        nzAnimate: true,
      });
      return;
    }

    const start = Timestamp.fromDate(formValue.dateStart);
    const end = Timestamp.fromDate(formValue.dateEnd);

    const task: any = {
      ...formValue,
      dateStart: start,
      dateEnd: end,
    };

    this.add.emit(task);

    this.onCancel();
    this.formInit();
    this.formCreate();
  }

  closeModal__OPERATION(): void {
    this.cancel.emit(true);
    this.taskFormMessage = '';
  }

  onCancel(): void {
    this.taskForm.reset();
    this.textInput = '';
    this.dates = [this.today, add(this.today, { days: 2 })];
    this.texts = new UntypedFormArray([]);
    this.textsArray.clear();
    this.taskFormMessage = '';
  }
}
