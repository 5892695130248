import { ItemEventId } from '../../../features/suivi/components/item-event/itemEvent.model';
import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Module, ModuleSmallId, ModuleId } from './module.model';
import * as ModuleActions from './module.actions';
import { DocumentId } from 'src/app/models/document.model';

export const modulesFeatureKey = 'modules';

export interface ModuleState extends EntityState<ModuleSmallId | ModuleId> {
  module: ModuleId | any;
  modules: ModuleId | any;
  types: string[];
  actions: any;
  action: any;
  participants: any;
  entites: any;
  contacts: any;
  missions: any;
  suivis: ItemEventId[] | any;
  suivi: ItemEventId | any;
  documents: DocumentId[] | any;
  document: DocumentId | any;
  utils: any;
  error: any;
}

export const adapter: EntityAdapter<ModuleSmallId | any> =
  createEntityAdapter<ModuleSmallId>();

export const initialState: ModuleState = adapter.getInitialState({
  module: undefined,
  modules: undefined,

  types: [],
  actions: undefined,
  action: undefined,
  participants: undefined,
  entites: undefined,
  contacts: undefined,
  missions: undefined,
  suivis: undefined,
  suivi: undefined,
  documents: undefined,
  document: undefined,
  utils: undefined,
  error: undefined,
});

export const reducer = createReducer(
  initialState,
  on(ModuleActions.loadModules, (state, action) => {
    return {
      ...state,
      module: undefined,
      actions: undefined,
      action: undefined,
      participants: undefined,
      entites: undefined,
      contacts: undefined,
      suivis: undefined,
      suivi: undefined,
      documents: undefined,
      document: undefined,
      error: undefined,
    };
  }),
  on(ModuleActions.clearModule, (state, action) => {
    return {
      ...state,
      module: undefined,
      actions: undefined,
      action: undefined,
      participants: undefined,
      entites: undefined,
      contacts: undefined,
      suivis: undefined,
      suivi: undefined,
      documents: undefined,
      document: undefined,
      error: undefined,
    };
  }),
  on(ModuleActions.loadModulesSuccess, (state, action) =>
    adapter.setAll(action.modules, state)
  ),

  on(ModuleActions.loadModulesFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //LOAD MODULE TYPES
  on(ModuleActions.loadModuleTypes, (state, action) => {
    return {
      ...state,
      types: [],
      error: undefined,
      loading: false,
    };
  }),
  on(ModuleActions.loadModuleTypesSuccess, (state, action) => {
    return {
      ...state,
      types: action.types,
      error: undefined,
      loading: false,
    };
  }),

  on(ModuleActions.loadModuleTypesFailure, (state, action) => {
    return {
      ...state,
      types: [],
      error: action.error,
      loading: false,
    };
  }),

  //QUERY
  on(ModuleActions.queryModulesSuccess, (state, action) =>
    adapter.setAll(action.modules, state)
  ),

  on(ModuleActions.queryModulesFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //LOAD ONE DETAILS
  on(ModuleActions.loadModuleSuccess, (state, action) => {
    return {
      ...state,
      module: action.module,
    };
  }),
  on(ModuleActions.loadModuleFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //ADD

  on(ModuleActions.addModuleFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //UPDATE
  on(ModuleActions.updateModule, (state, action) =>
    adapter.updateOne(action.module, state)
  ),

  on(ModuleActions.updateModuleFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //DELETE
  on(ModuleActions.deleteModule, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(ModuleActions.deleteModuleFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //DELETE ALL
  on(ModuleActions.deleteModules, (state, action) => adapter.removeAll(state)),
  on(ModuleActions.deleteModulesFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  on(ModuleActions.clearModules, (state) => adapter.removeAll(state)),

  //MISSIONS
  //load missions success
  on(ModuleActions.loadMissionsSuccess, (state, action) => {
    return {
      ...state,
      missions: action.missions,
    };
  }),

  //load mission failure
  on(ModuleActions.loadMissionsFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //ACTIONS
  //load actions success
  on(ModuleActions.loadActionsSuccess, (state, action) => {
    return {
      ...state,
      actions: action.actions,
    };
  }),

  //load mission failure
  on(ModuleActions.loadMissionsFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //SUIVIS
  on(ModuleActions.loadSuivisSuccess, (state, action) => {
    return {
      ...state,
      suivis: action.suivis,
    };
  }),
  on(ModuleActions.loadSuivisFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  on(ModuleActions.addSuiviSuccess, (state, action) => {
    return {
      ...state,
      suivis: [...state.suivis, action.suivi],
    };
  }),
  on(ModuleActions.addSuiviFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  on(ModuleActions.updateSuivi, (state, action) => {
    const removePrev = state.suivis.filter(
      (el: any) => el.id !== action.suivi.changes.id
    );
    return {
      ...state,
      suivis: [...removePrev, action.suivi.changes],
    };
  }),
  on(ModuleActions.updateSuiviFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  on(ModuleActions.deleteSuivi, (state, action) => {
    return {
      ...state,
      suivis: state.suivis.filter((el: any) => el.id !== action.suiviId),
    };
  }),
  on(ModuleActions.deleteSuiviFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //DOCUMENTS
  on(ModuleActions.loadDocumentsSuccess, (state, action) => {
    return {
      ...state,
      documents: action.documents,
    };
  }),
  on(ModuleActions.loadDocumentsFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  // on(ModuleActions.addDocumentsSuccess, (state, action) => {
  //   return {
  //     ...state,
  //     documents: action.documents,
  //   };
  // }),
  on(ModuleActions.addDocumentsFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  // on(ModuleActions.deleteDocument, (state, action) => {
  //   return {
  //     ...state,
  //     documents: state.documents.filter(
  //       (doc: any) => doc.id !== action.document.id
  //     ),
  //   };
  // }),
  on(ModuleActions.deleteDocumentFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),

  //QUERY
  on(ModuleActions.queryModulesByOperationSuccess, (state, action) => {
    return {
      ...state,
      modules: action.modules,
    };
  }),
  on(ModuleActions.queryModulesByOperationFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
