import { Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  doc,
  docSnapshots,
  getDoc,
  collectionChanges,
  addDoc,
  setDoc,
  deleteDoc,
  serverTimestamp,
  DocumentData,
  DocumentSnapshot,
  DocumentReference,
  CollectionReference,
  Timestamp,
  query,
  where,
  arrayUnion,
  arrayRemove,
  collectionSnapshots,
  getDocs,
  limit,
} from '@angular/fire/firestore';
import { Observable, of, from, EMPTY } from 'rxjs';
import { switchMap, mergeMap, map, catchError } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Functions, httpsCallable } from '@angular/fire/functions';
import {
  Storage,
  ref,
  uploadBytesResumable,
  deleteObject,
  getDownloadURL,
  StorageReference,
  UploadTask,
  UploadMetadata,
} from '@angular/fire/storage';
import { UserState } from '../components/user/store/user.reducer';
import * as fromUserSelector from '../components/user/store/user.selectors';
import { v4 as uuidV4 } from 'uuid';
import {
  Contact,
  ContactId,
  ContactSMALL,
  ContactSMALLid,
} from '../components/contact/store/contact.model';
import { format, fromUnixTime } from 'date-fns';
import { uniq, flattenDeep } from 'lodash';

@Injectable({
  providedIn: 'any',
})
export class ContactsService {
  constructor(
    private db: Firestore,
    private storage: Storage,
    private fns: Functions,
    private userStore: Store<UserState>
  ) {}

  getAll(): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) of([]);
          const dbCollection = collection(
            this.db,
            `organisations/${user.organisation.id}/contacts`
          );

          const changes = collectionSnapshots(dbCollection).pipe(
            map((changes) => {
              return changes.map((snap) => {
                const data = snap.data();
                const id = snap.id;
                return { id, ...data };
              });
            })
          );

          return changes;
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  getOne(id: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) {
            return of(null);
          } else {
            const dbCollection: CollectionReference<DocumentData> = collection(
              this.db,
              `organisations/${user.organisation.id}/contacts/${id}/details`
            );

            const documentRef: DocumentReference<DocumentData> = doc(
              dbCollection,
              id
            );
            return docSnapshots(documentRef).pipe(
              map((snap: DocumentSnapshot<DocumentData>) => {
                const data = snap.data();
                const id = snap.id;
                return { id, ...data };
              }),
              catchError((error) => error)
            );
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  getOneSmall(id: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) {
            return of(null);
          } else {
            const dbCollection: CollectionReference<DocumentData> = collection(
              this.db,
              `organisations/${user.organisation.id}/contacts`
            );

            const documentRef: DocumentReference<DocumentData> = doc(
              dbCollection,
              id
            );
            return from(
              getDoc(documentRef)
                .then((snap: DocumentSnapshot<DocumentData>) => {
                  const data = snap.data();
                  const id = snap.id;
                  return { id, ...data };
                })
                .catch((error) => error)
            ).pipe(mergeMap((res) => of(res)));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  getOneStatistiques(id: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) {
            return of(null);
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/contacts/${id}/statistiques`
            );

            const documentRef = doc(dbCollection, id);
            return from(
              getDoc(documentRef)
                .then((doc: DocumentSnapshot<DocumentData>) => {
                  const data = doc.data() as any;
                  const id = doc.id;
                  return { id, ...data };
                })
                .catch((error) => error)
            ).pipe(mergeMap((res) => of(res)));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  //ADD
  addOne(contact: any): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !contact) {
            return of(null);
          } else {
            return this.isExist(contact, user).pipe(
              switchMap((exists) => {
                return of({ exists, user });
              })
            );
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  //ADD CONFIRMATION
  addConfirmation(
    contact: Contact,
    user: any
  ): Observable<ContactSMALLid | any> {
    const small = this.small(contact, user);
    const dbCollection = collection(
      this.db,
      `organisations/${user.organisation.id}/contacts`
    );
    const newContact = {
      ...small,
      auteur: user,
      metadata: this.metadata(contact),
      organisation: {
        id: user.organisation.id,
        denomination: user.organisation.nom_raison_sociale,
      },
    };
    const addContact = addDoc(dbCollection, newContact);

    return from(
      addContact
        .then(async (docRef) => {
          if (docRef.id) {
            return this.addOneDetails(contact, docRef.id, user);
          } else {
            return of(null);
          }
        })
        .catch((error) => {
          return of(error);
        })
    ).pipe(
      mergeMap((res) => {
        return res;
      })
    );
  }

  //ADD DETAILS
  addOneDetails(
    contact: Contact,
    id: string,
    user: any
  ): Observable<ContactSMALLid | any> {
    try {
      const dbdocumentDetail: DocumentReference<DocumentData> = doc(
        this.db,
        `organisations/${user.organisation.id}/contacts/${id}/details/${id}`
      );

      const newContactDetail = {
        ...contact,
        auteur: user,
        userUpdate: user,
        metadata: this.metadata(contact),
        organisation: {
          id: user.organisation.id,
          denommination: null,
        },
      };

      return from(
        setDoc(dbdocumentDetail, newContactDetail, { merge: true })
          .then(() => {
            return this.getOneSmall(id);
          })
          .catch((error) => of(error))
      ).pipe(
        mergeMap((res) => {
          return res;
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  //CHECK IF EXIST
  isExist(
    contact: Contact | ContactId | ContactSMALLid,
    user: any
  ): Observable<ContactSMALLid[] | any> {
    try {
      if (user) {
        const { lastName, firstName } = contact;
        const contactsExistCollection = collection(
          this.db,
          `organisations/${user.organisation.id}/contacts`
        );

        const queryContacts = query(
          contactsExistCollection,
          where('lastName', '==', lastName),
          where('firstName', '==', firstName),
          limit(1)
        );

        return from(
          getDocs(queryContacts)
            .then((snap) => {
              return snap.docs.map((doc) => {
                const data = doc.data();
                const id = doc.id;
                return { id, ...data };
              });
            })
            .catch((error) => error)
        ).pipe(mergeMap((res) => of(res)));
      } else {
        return of([]);
      }
    } catch (err) {
      return of(err);
    }
  }

  updateOne(id: string | number, contact: Partial<any>): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !contact) {
            return of(null);
          } else {
            const documentRef = doc(
              this.db,
              `organisations/${user.organisation.id}/contacts/${contact.id}/details/${contact.id}`
            );
            const nextDocument = {
              ...contact,
              dateUpdate: serverTimestamp(),
              userUpdate: user,
              metadata: this.createParticipantMetadata(contact),
            };
            return from(
              setDoc(documentRef, { ...nextDocument }, { merge: true })
                .then(() =>
                  of(
                    `${contact.civilite}. ${contact.lastName} ${contact.firstName} modifié.e`
                  )
                )
                .catch((error) => of(error))
            ).pipe(mergeMap((res) => res));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  deleteOne(id: string): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user || !id) {
            return of(null);
          } else {
            const documentRef = doc(
              this.db,
              `organisations/${user.organisation.id}/contacts/${id}/details/${id}`
            );

            return from(
              deleteDoc(documentRef)
                .then(() => `Contact supprimé.`)
                .catch((error) => error)
            ).pipe(mergeMap((res) => of(res)));
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  deleteAll(): Observable<any> {
    try {
      return this.userStore.select(fromUserSelector.user).pipe(
        switchMap((user) => {
          if (!user) {
            return of(null);
          } else {
            const dbCollection = collection(
              this.db,
              `organisations/${user.organisation.id}/contacts`
            );

            return of(null);
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase();
  }

  createKeywords(name: any): any {
    if (name) {
      const arrName: any = [];
      let curName = '';
      name.split('').forEach((letter: any) => {
        curName += letter;
        arrName.push(curName);
      });
      return arrName;
    }
  }

  createParticipantMetadata(item: any): any {
    const { title } = item;
    const letterTitle = title ? this.capitalizeFirstLetter(title) : '';
    const arrayTitle = title ? this.createKeywords(title) : [];
    const metatada = { letterTitle, arrayTitle };

    return metatada;
  }

  metadata(contact: any) {
    const { firstName, lastName, adresse, entite, fonction, sexe, dateStart } =
      contact;
    const entites: string[] =
      contact?.entites && contact?.entites?.length
        ? contact.entites.map((el: any) =>
            el.etablissement?.denomination.toUpperCase()
          )
        : [];
    const entitesRaisonSocial = flattenDeep(
      entites.map((el) => {
        return this.createKeywords(el);
      })
    );

    const denomination = entite?.etablissement?.denomination
      ? entite.etablissement.denomination.toUpperCase()
      : '';
    const fonctionValue = fonction ? fonction.toUpperCase() : '';
    const sexeValue = sexe ? sexe.toUpperCase() : '';
    const year = dateStart
      ? format(fromUnixTime(dateStart['seconds']), 'yyyy')
      : '';
    const city = adresse?.adresse?.properties?.city
      ? adresse.adresse.properties.city.toUpperCase()
      : '';
    const postcode = adresse?.adresse?.properties?.postcode
      ? adresse.adresse.properties.postcode
      : '';

    const letterLastName = lastName ? this.capitalizeFirstLetter(lastName) : '';
    const letterFirstName = lastName
      ? this.capitalizeFirstLetter(firstName)
      : '';
    const letterCity = city ? this.capitalizeFirstLetter(city) : '';

    const letterPostCode = postcode ? this.capitalizeFirstLetter(postcode) : '';

    const letterEntite = denomination
      ? this.capitalizeFirstLetter(denomination)
      : '';

    const letterFonction = fonctionValue
      ? this.capitalizeFirstLetter(fonctionValue)
      : '';

    const letterSexe = sexeValue ? this.capitalizeFirstLetter(sexeValue) : '';

    const arrayEntites =
      entitesRaisonSocial && entitesRaisonSocial?.length
        ? entitesRaisonSocial
        : [];
    const arrayLastName = lastName ? this.createKeywords(lastName) : [];
    const arrayFirstName = lastName ? this.createKeywords(firstName) : [];
    const arrayCity = city ? this.createKeywords(city) : [];
    const arrayPostcode = postcode ? this.createKeywords(postcode) : [];

    const arrayEntite = denomination ? this.createKeywords(denomination) : [];
    const arrayFonction = fonction ? this.createKeywords(fonction) : [];
    const arraySexe = sexe ? this.createKeywords(sexe) : [];

    const metadata = {
      arrayEntites: arrayEntites,
      letterLastName: letterLastName,
      letterFirstName: letterFirstName,
      letterCity: letterCity,
      letterPostCode: letterPostCode,
      letterEntite: letterEntite,
      letterSexe: letterSexe,
      letterFonction: letterFonction,
      arrayLastName: arrayLastName,
      arrayFirstName: arrayFirstName,
      arrayCity: arrayCity,
      arrayPostcode: arrayPostcode,
      arraySexe: arraySexe,
      arrayEntite: arrayEntite,
      arrayFonction: arrayFonction,
      year: year,
      benevolat: contact?.benevolat ? contact.benevolat : false,
      disponibility: contact?.disponibility ? contact.disponibility : true,
    };

    return metadata;
  }

  displayName(contact: any) {
    const displayName = `${contact.civilite}. ${contact.lastName} ${contact.firstName}`;
    return displayName;
  }

  small(contact: any, user: any) {
    const smallVersion: ContactSMALL = {
      lastName: contact.lastName,
      firstName: contact.firstName,
      civilite: contact.civilite,
      displayName: this.displayName(contact),
      fonction: contact.fonction,
      sexe: contact.sexe,
      isParrain: contact?.isParrain ? contact.isParrain : false,
      coordonnees: contact.coordonnees,
      disponibility: contact?.disponibility ? contact.disponibility : true,
      benevolatHours: contact?.benevolatHours ? contact.benevolatHours : [],
      createAtYear: format(fromUnixTime(contact.dateStart['seconds']), 'yyyy'),
      createAtMonth: format(fromUnixTime(contact.dateStart['seconds']), 'M'),
      createAtDay: format(fromUnixTime(contact.dateStart['seconds']), 'd'),
      dateStart: contact.dateStart,
      benevolat: contact?.benevolat ? contact.benevolat : false,
      adresse:
        contact.adresse.adresse && contact.adresse.adresse.properties
          ? contact.adresse.adresse.properties.label
          : null,
      entite: contact.entite,
      entites: contact?.entites?.length ? uniq([...contact.entites]) : [],
      entitesTitles: contact?.entitesTitles?.length
        ? uniq([...contact.entitesTitles])
        : [],
      entitesIds: contact?.entitesIds?.length
        ? uniq([...contact.entitesIds])
        : [],
      entitesMapIds: contact?.entitesMapIds
        ? {
            ...contact.entitesMapIds,
            [`${contact.entite.id}`]: true,
          }
        : null,

      organisation: {
        id: user.organisation.id,
        denomination: user.organisation.nom_raison_sociale,
      },
      metiersThemes: contact?.metiersThemes ? contact.metiersThemes : null,
      metiersDomainesArray: contact?.metiersDomainesArray?.length
        ? contact.metiersDomainesArray
        : null,
      metiersDomainesMap: contact?.metiersDomainesMap
        ? contact.metiersDomainesMap
        : null,

      metiersGrandDomainesArray: contact?.metiersGrandDomainesArray?.length
        ? contact.metiersGrandDomainesArray
        : null,
      metiersGrandDomainesMap: contact?.metiersGrandDomainesMap
        ? contact.metiersGrandDomainesMap
        : null,
      metiersArray: contact?.metiersArray?.length ? contact.metiersArray : null,
      metiersMap: contact?.metiersMap ? contact.metiersMap : null,
      appelationsArray: contact?.appelationsArray?.length
        ? contact.appelationsArray
        : null,
      appelationsMap: contact?.appelationsMap ? contact.appelationsMap : null,

      metadata: this.metadata(contact),
    };

    return smallVersion;
  }
}
