import { Component, Input, OnInit } from '@angular/core';
import {
  NzTableFilterFn,
  NzTableFilterList,
  NzTableSortFn,
  NzTableSortOrder,
} from 'ng-zorro-antd/table';
import { Observable, of, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-table-fiches-poste',
  templateUrl: './table-fiches-poste.component.html',
  styleUrls: ['./table-fiches-poste.component.scss'],
})
export class TableFichesPosteComponent implements OnInit {
  @Input() source$: Observable<any> = of([]);
  @Input() scroll: any = { x: '1100px', y: '100%' };

  constructor() {}

  ngOnInit(): void {}
}
