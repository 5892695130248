import { OperationNoteId } from './../../../operation-note/store/operation-note.model';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Timestamp } from 'firebase/firestore';
import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';
import { OperationNoteDetailId } from 'src/app/components/operation-note/store/operation-note.model';
import { fromUnixTime } from 'date-fns';
import {
  OperationNoteAction,
  OperationNoteActionId,
} from '../../store/operation-note-action.model';
import { OperationNoteSujetId } from 'src/app/components/operation-note-sujet/store/operation-note-sujet.model';

@Component({
  selector: 'app-operation-note-action-list',
  templateUrl: './operation-note-action-list.component.html',
  styleUrls: ['./operation-note-action-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationNoteActionListComponent implements OnInit {
  actions$: Observable<OperationNoteActionId[] | any> = of(null);
  actionsStatut$: Observable<any[]> = of([]);
  subscribe = new Subject();
  new__ACTION = new BehaviorSubject<boolean>(false);
  new__ACTION_input: string = '';
  new__ACTION_date: Date[] = [];
  update_ACTION_edit = new BehaviorSubject<boolean>(false);
  statut_change_ACTION = '';
  editCache__ACTION: {
    [key: string]: { edit: boolean; data: OperationNoteActionId };
  } = {};

  actionForm: UntypedFormGroup = this.fb.group({});
  update_ActionForm: UntypedFormGroup = this.fb.group({});
  utilities$: Observable<any> = of(null);
  @Input() note$: Observable<OperationNoteId | any> = of(null);
  @Input() sujet$: Observable<OperationNoteSujetId | any> = of(null);

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {}

  get__SUJET_ACTIONS(): void {
    // this.actions$ = this.store.pipe(
    //   select(fromNoteSelectors.selectActionsBySujet),
    //   takeUntil(this.subscribe)
    // );
    // this.actionsStatut$ = this.store.pipe(
    //   select(fromNoteSelectors.selectActionsByStatut),
    //   takeUntil(this.subscribe)
    // );
  }

  get actionsArray() {
    return this.actionForm.get('actions') as UntypedFormArray;
  }

  select_filter__ACTION(filter: any): void {
    // if (filter.statut === 'Tout') {
    //   this.actions$ = this.store.pipe(
    //     select(fromNoteSelectors.selectActionsBySujet)
    //   );
    // } else {
    //   let search__ACTIONS$ = [];
    //   this.store
    //     .pipe(
    //       select(fromNoteSelectors.selectActionsBySujet),
    //       takeUntil(this.subscribe)
    //     )
    //     .subscribe((actions) => {
    //       const actions_filter: OperationNoteActionId[] = filter(
    //         actions,
    //         (action:any) => action.statut === filter.statut
    //       );
    //       search__ACTIONS$ = actions_filter;
    //     });
    //   this.actions$ = of(search__ACTIONS$);
    // }
  }

  actionFormCreate(): void {
    this.actionForm = new UntypedFormGroup({
      actions: this.fb.array([]),
    });
  }

  confirm_action(sujet: OperationNoteSujetId): void {
    this.new__ACTION.next(true);
    const doc = this.fb.group({
      subjet: [sujet],
      dates: [[], Validators.required],
      dateStart: [Timestamp.now(), Validators.required],
      dateEnd: [''],
      dateUpdate: [Timestamp.now(), Validators.required],
      text: [this.new__ACTION_input],
      responsables: [],
      statut: ['En attente'],
    });

    this.actionsArray.push(doc);
    this.new__ACTION_input = '';
  }

  actionDate(dates: Date[], index: number): void {
    this.actionsArray.at(index).patchValue({
      dateStart: Timestamp.fromDate(dates[0]),
      dateEnd: Timestamp.fromDate(dates[1]),
    });
  }

  remove_action(index: number): void {
    this.actionsArray.removeAt(index);
  }

  clear_action() {
    this.actionsArray.clear();
  }

  add__ACTIONS(note: OperationNoteDetailId): void {
    if (this.actionsArray.length === 0) return;

    const newActions: OperationNoteAction[] = this.actionsArray.value;

    // this.store.dispatch(
    //   fromNoteActions.addNoteActions({ note: note, actions: newActions })
    // );

    this.actionsArray.clear();
  }

  cancelEdit(action: OperationNoteActionId): void {
    if (action) {
      this.editCache__ACTION[action.id] = {
        data: { ...action },
        edit: false,
      };
    }
  }

  saveEdit(id: string): void {
    this.editCache__ACTION[id].edit = false;
  }

  onUpdate__ACTION_edit(action: OperationNoteActionId): void {
    this.update_ACTION_edit.next(true);
    this.editCache__ACTION[action.id].edit = true;

    const demarrage = fromUnixTime(action.dates[0]['seconds']);
    const echeance = fromUnixTime(action.dates[1]['seconds']);
    const datesArray = [demarrage, echeance];

    this.update_ActionForm = this.fb.group({
      id: [action.id],
      subjet: [action.subjet],
      dates: [datesArray, Validators.required],
      dateStart: [action.dateStart, Validators.required],
      dateEnd: [''],
      dateUpdate: [Timestamp.now(), Validators.required],
      text: [action.text],
      responsables: [action.responsables],
      statut: [action.statut],
    });
  }

  updateEditCache(): void {
    this.actions$.pipe(takeUntil(this.subscribe)).subscribe((actions) => {
      actions.forEach((item: any) => {
        this.editCache__ACTION[item.id] = {
          edit: false,
          data: { ...item },
        };
      });
    });
  }

  statut__compareWith(o1: any, o2: any) {
    o1 === o2;
  }

  update__ACTION(): void {
    if (!this.update_ActionForm.valid) return;

    const action: OperationNoteActionId = this.update_ActionForm.value;
    const demarrage = Timestamp.fromDate(action.dates[0]);
    const echeance = Timestamp.fromDate(action.dates[1]);
    const updateDates = [demarrage, echeance];
    action.dates = updateDates;

    // this.store.dispatch(fromNoteActions.updateAction({ action }));
    this.update_ActionForm.reset();
    this.update_ACTION_edit.next(false);
    this.editCache__ACTION[action.id].edit = false;
    this.select_filter__ACTION('Tout');
  }

  cancel_delete__DDOCUMENT(): void {
    return;
  }

  delete__ACTION(action: OperationNoteActionId): void {
    // this.store.dispatch(fromNoteActions.deleteAction({ id: action.id }));
  }
}
