<div #tableContainer class="table_container">
  <nz-table
    #Table
    [nzData]="source"
    nzSize="small"
    [nzTitle]="Header"
    [nzShowPagination]="false"
    [nzShowSizeChanger]="false"
    [nzFrontPagination]="false"
    [nzPageSize]="500"
    [nzScroll]="{ y: (tableHeight || 0) + 'px' }"
  >
    <thead nzSingleSort>
      <tr>
        <th
          *ngFor="let colum of listOfColumns"
          [nzSortOrder]="colum?.sortOrder"
          [nzSortFn]="colum?.sortFn"
          [nzSortDirections]="colum?.sortDirections"
          [nzFilterMultiple]="colum?.filterMultiple"
          [nzFilterFn]="colum?.filterFn"
          [nzWidth]="colum?.width"
        >
          {{ colum?.name }}
        </th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let data of Table.data">
        <ng-container *ngIf="type === 'operation'">
          <tr>
            <td>{{ data?.denomination | carLimitation: 50 }}</td>
            <td>
              <small>{{
                data?.description?.definition | carLimitation: 35
              }}</small>
            </td>
            <td>
              {{
                data?.description && data?.description?.calendrier?.dateStart
                  | dateFr: "small"
              }}
            </td>
            <td>
              {{
                data?.description && data?.description?.calendrier?.dateEnd
                  | dateFr: "small"
              }}
            </td>
            <td>
              <nz-tag *ngIf="data?.financeurs?.length">
                {{ data.financeurs[0].nom_raison_sociale | carLimitation: 40 }}
                <ng-container *ngIf="data?.financeurs?.length > 1">
                  <nz-divider
                    nzType="vertical"
                    nzOrientation="center"
                  ></nz-divider>
                  <nz-badge
                    [nzCount]="data.financeurs.length"
                    nzText="text"
                    [nzOverflowCount]="4"
                    [nzStyle]="{
                      backgroundColor: '#ffab00',
                      color: 'black',
                      fontWeigth: 'bold'
                    }"
                  ></nz-badge>
                </ng-container>
              </nz-tag>
            </td>
          </tr>
        </ng-container>
        <ng-container *ngIf="type === 'module'">
          <tr>
            <td
              *ngIf="expendable"
              [nzExpand]="expandSet.has(data.id)"
              (nzExpandChange)="onExpandChange(data.id, $event)"
            ></td>
            <td>{{ data.title }}</td>
            <td>{{ data.type }}</td>
            <td>{{ data.calendrier.dateStart | dateFr: "small" }}</td>
            <td>{{ data.calendrier.dateEnd | dateFr: "small" }}</td>
          </tr>
        </ng-container>
        <ng-container *ngIf="type === 'action'">
          <tr>
            <td
              *ngIf="expendable"
              [nzExpand]="expandSet.has(data.id)"
              (nzExpandChange)="onExpandChange(data.id, $event)"
            ></td>
            <td>{{ data.title }}</td>
            <td>{{ data.type }}</td>
            <td>{{ data.dateStart | dateFr: "time" }}</td>
            <td>{{ data.dateEnd | dateFr: "time" }}</td>
          </tr>
        </ng-container>
        <ng-container *ngIf="type === 'contact'">
          <tr>
            <td
              *ngIf="expendable"
              [nzExpand]="expandSet.has(data.id)"
              (nzExpandChange)="onExpandChange(data.id, $event)"
            ></td>
            <td>{{ data.civilite }}</td>
            <td>{{ data.lastName }}</td>
            <td>{{ data.firstName }}</td>
            <td>{{ data?.fonction }}</td>
          </tr>
        </ng-container>

        <ng-container *ngIf="type === 'participant'"></ng-container>
        <ng-container *ngIf="type === 'entite'"></ng-container>
        <ng-container *ngIf="type === 'document'"></ng-container>
        <ng-container *ngIf="type === 'suivi'"></ng-container>
        <ng-container *ngIf="type === 'contrat'"></ng-container>
      </ng-container>

      <tr *ngIf="expendable" [nzExpand]="expandSet.has(data.id)">
        <h1>EXPENDABLE</h1>
        <!-- <nz-space nzDirection="horizontal" nzSize="2" style="width: 100%">
          <nz-space-item style="width: 50%">{{
            data.description.definition
          }}</nz-space-item>
          <nz-space-item style="width: 40%" *ngIf="data.entites.length">
            <nz-tag>{{
              data.entites[0].etablissement.nom_raison_sociale
                | carLimitation: 20
            }}</nz-tag>
            <nz-badge
              nzCount="{{ data.entites.length }}"
              nzOverflowCount="20"
              nz-dropdown
              nzTrigger="click"
              [nzDropdownMenu]="menuModuleEntite"
            ></nz-badge>
          </nz-space-item>

          <nz-dropdown-menu #menuModuleEntite="nzDropdownMenu">
            <ul nz-menu>
              <li nz-menu-item *ngFor="let item of data.entites">
                {{ item.etablissement.nom_raison_sociale | carLimitation: 60 }}
              </li>
            </ul>
          </nz-dropdown-menu>

          <nz-space-item style="width: 10%">
            <button
              nz-button
              nzDanger="true"
              nzSize="small"
              style="float: rigth"
            >
              <i nz-icon nzType="delete" nzTheme="outline"></i>
            </button>
          </nz-space-item>
        </nz-space> -->
      </tr>
    </tbody>
  </nz-table>
</div>
<nz-dropdown-menu #menuLastNameParticipant="nzDropdownMenu">
  <!-- <div class="search-box">
  <input
    type="text"
    nz-input
    placeholder="Rechercher le nom..."
    [(ngModel)]="projetvalue"
    (keydown.enter)="search()"
  />
  <button
    nz-button
    nzSize="small"
    nzType="primary"
    (click)="search()"
    class="search-button"
  >
    Recherche...
  </button>
  <button nz-button nzSize="small" (click)="reset()">
    Réinitialiser
  </button>
</div> -->
</nz-dropdown-menu>

<ng-template #Header>
  <div class="selectGroup" style="width: 100%">
    <nz-badge
      *ngIf="count"
      [nzCount]="source.length"
      [nzOverflowCount]="50"
      [nzStyle]="styleBadge"
    >
    </nz-badge>

    <button
      *ngIf="new"
      nz-button
      nzSize="small"
      nzType="primary"
      (click)="onNewItem($event)"
      style="float: right"
    >
      Nouveau
    </button>
  </div>
</ng-template>
