import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, of, Subject, takeUntil } from 'rxjs';
import { UtilitiesState } from 'src/app/contents/components/utilities/store/utilities.reducer';
import * as fromUtilitiesSelector from 'src/app/contents/components/utilities/store/utilities.selectors';
import * as fromUtilitiesAction from 'src/app/contents/components/utilities/store/utilities.actions';

@Component({
  selector: 'app-participant-diagnostic-form-aptitudes',
  templateUrl: './participant-diagnostic-form-aptitudes.component.html',
  styleUrls: ['./participant-diagnostic-form-aptitudes.component.scss'],
})
export class ParticipantDiagnosticFormAptitudesComponent
  implements OnInit, OnChanges, OnDestroy
{
  subscribe = new Subject();
  aptitudes: string[] = ['Pondtualité', "Sens de l'écoute"];
  softkills$: Observable<any> = of([]);

  aptitudeForm: FormGroup = this.fb.group({});
  softskills: FormControl<any> = new FormControl<
    { details: string; summary: string }[] | any
  >([]);

  isFrein: FormControl<boolean | any> = new FormControl(
    false,
    Validators.required
  );

  @Output() add = new EventEmitter(false);

  constructor(
    private utilitiesStore: Store<UtilitiesState>,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.form();
    this.getSoftskills();
    this.loadSoftskills();
    this.onFormChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getSoftskills(): void {
    this.softkills$ = this.utilitiesStore.select(
      fromUtilitiesSelector.softskills
    );
  }
  loadSoftskills(): void {
    this.utilitiesStore.dispatch(
      fromUtilitiesAction.loadSoftskillsReferentiel()
    );
  }

  form(): void {
    this.formInit();
    this.formCreate();
  }

  formInit(): void {
    this.softskills = new FormControl<any>([]);
  }
  formCreate(): void {
    this.aptitudeForm = this.fb.group({
      softskills: this.softskills,
    });
  }

  onFormChanges(): void {
    this.aptitudeForm.valueChanges
      .pipe(takeUntil(this.subscribe))
      .subscribe((aptitudes) => {
        if (!aptitudes) return;

        this.add.emit(aptitudes?.softskills);
      });
  }
}
