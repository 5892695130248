<div class="container" *ngIf="operation$ | async as operation">
  <i nz-icon nzType="loading" class="loading" *ngIf="loading$ | async"></i>
  <div [ngSwitch]="view$ | async">
    <div *ngSwitchCase="'list'">
      <ng-container *ngIf="prescripteurs$ | async | size; else noResults">
        <app-layout-list
          [card]="card"
          [new]="new"
          [source]="prescripteurs$ | async"
        ></app-layout-list>
      </ng-container>
    </div>
    <div *ngSwitchCase="'details'">
      <app-operation-prescripteurs-item></app-operation-prescripteurs-item>
    </div>
    <div *ngSwitchDefault>
      <app-layout-list
        *ngIf="prescripteurs$ | async | size; else noResults"
        [card]="card"
        [new]="new"
        [source]="prescripteurs$ | async"
      ></app-layout-list>
    </div>
  </div>

  <ng-template #new>
    <!-- <app-card-new
        [type]="'participant'"
        [title]="newCardTitle"
        [description]="''"
        [definition]="newCardDefinition"
        [width]="'100%'"
        [height]="'260px'"
        (add)="onNew(operation)"
        (back)="onBack()"
        (cancel)="onBack()"
      ></app-card-new> -->
  </ng-template>

  <ng-template #card let-item let-position="idx">
    <app-operation-prescripteurs-card></app-operation-prescripteurs-card>
  </ng-template>

  <ng-template #noResults>
    <app-no-result
      [icon]="'real_estate_agent'"
      [addtitle]="newCardDescription"
      [notitle]="noModuleTitle"
      [description]="newCardDefinition"
      (new)="onNew(operation)"
    ></app-no-result>
  </ng-template>

  <ng-template #prescripteurFormTitle>
    <app-header-title
      [icon]="'real_estate_agent'"
      [title]="title"
    ></app-header-title>
  </ng-template>
</div>
