<nz-row nzAlign="middle" nzGutter="8" nzJustify="center">
  <nz-col nzSpan="12">
    <nz-card
      [nzTitle]="carteSocialeTitle"
      nzHoverable
      (click)="$event.stopPropagation(); onSelectCard('sociale')"
    ></nz-card>
  </nz-col>
  <nz-col nzSpan="12">
    <nz-card
      [nzTitle]="carteProTitle"
      nzHoverable
      (click)="$event.stopPropagation(); onSelectCard('professionnele')"
    ></nz-card>
  </nz-col>
</nz-row>
<nz-space nzSize="small"></nz-space>

<div [ngSwitch]="currenteCard$ | async">
  <div *ngSwitchCase="'sociale'">
    <h1>DIAGNOSTICS SOCIALS</h1>
  </div>
  <div *ngSwitchCase="'professionnele'"><h1>DIAGNOSTICS PROFESSIONELS</h1></div>

  <div *ngSwitchDefault></div>
</div>

<ng-template #carteSocialeTitle>
  <app-header-title
    [title]="'Carte sociale'"
    [icon]="'volunteer_activism'"
    [isIcon]="true"
    [subtitle]="true"
  ></app-header-title>
</ng-template>
<ng-template #carteProTitle>
  <app-header-title
    [title]="'Carte professionnelle'"
    [icon]="'business_center'"
    [isIcon]="true"
    [subtitle]="true"
  ></app-header-title>
</ng-template>
