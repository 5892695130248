import { OperationId } from './../../../operation/store/operation.model';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  Input,
  ViewChild,
  TemplateRef,
  ViewContainerRef,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { OperationState } from 'src/app/components/operation/store/operation.reducer';
import * as fromOperationSelector from 'src/app/components/operation/store/operation.selectors';

import { MissionsFormComponent } from '../missions-form/missions-form.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ModuleId } from 'src/app/components/module/store/module.model';
import { ActionId } from 'src/app/components/action/store/action.model';
import { MissionState } from '../../store/mission.reducer';
import * as fromMissionAction from '../../store/mission.actions';
import * as fromMissionSelector from '../../store/mission.selectors';
import { ContratState } from 'src/app/contents/components/contrat/store/contrat.reducer';
import * as fromContratAction from 'src/app/contents/components/contrat/store/contrat.actions';
import * as fromContratSelector from 'src/app/contents/components/contrat/store/contrat.selectors';

import { MetierState } from 'src/app/features/metiers/store/metier.reducer';
import * as fromMetierAction from 'src/app/features/metiers/store/metier.actions';

@Component({
  selector: 'app-missions-list',
  templateUrl: './missions-list.component.html',
  styleUrls: ['./missions-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MissionsListComponent implements OnInit, OnChanges, OnDestroy {
  subscribe = new Subject();
  @Input() missions$: Observable<any> = of(null);
  @Input() mission$: Observable<any> = of(null);
  @Input() operation$: Observable<OperationId | any> = of(null);
  @Input() modules$: Observable<ModuleId[] | any> = of(null);
  @Input() actions$: Observable<ActionId[] | any> = of(null);
  @Input() entites$: Observable<any> = of(null);
  @Input() updateLoading$: Observable<any> = of(null);
  view$ = new BehaviorSubject<string>('list');
  currentMission$: Observable<any> = of(null);
  currentPlaning$ = new BehaviorSubject<any>(null);

  loading$: Observable<boolean> = of(false);
  title: string = 'Nouvelle mission';
  newCardTitle: string = 'Nouvelle mission';
  newCardDescription: string = 'Ajouter une mission';
  noModuleTitle: string = 'Aucun mission';
  newCardDefinition: string =
    'Une mission est une relation contractuelle de travail pour une rémunération.';

  @ViewChild('missionFormTitle', { static: false })
  missionFormTitle: TemplateRef<any> | any;
  modalWidth: number = 920;

  @Output() select = new EventEmitter<any>(false);
  @Output() add = new EventEmitter<any>(false);
  @Output() update = new EventEmitter<any>(false);
  @Output() clear = new EventEmitter<boolean>(false);
  @Output() delete = new EventEmitter<any>(false);

  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private missionStore: Store<MissionState>,
    private contratStore: Store<ContratState>,
    private metierStore: Store<MetierState>
  ) {}

  ngOnInit(): void {
    this.load_metier_domaines();
    this.load_typescontrat();
    this.load_naturescontrat();
    this.load_experiencescontrat();
    this.load_salairescontrat();
    this.load_permis();
    this.load_level_numerique();
    this.load_complement_salaire();
    this.load_type_horaire();
    this.load_mobility_geographic();
    this.load_contrat_days();
    this.load_evolution_type();
  }

  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  onSelect(mission: any): void {
    if (!mission) return;
    this.select.emit(mission);
    const { hours } = mission;

    this.currentPlaning$.next(hours);

    this.view$.next('details');
  }

  onNew(): void {
    const modal = this.modal.create({
      nzContent: MissionsFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.missionFormTitle,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });
    const instance = modal.getContentComponent();
    instance.cancel.subscribe((): any => {
      modal.close();
    });
    instance.add.subscribe((event: any): any => {
      if (!event) {
        return null;
      } else {
        this.onAdd(event);
        modal.close();
      }
    });
  }

  onAdd(mission: any): void {
    if (!mission) return;
    this.add.emit(mission);
  }

  onUpdate(mission: any): void {
    if (!mission || !mission.id) return;

    this.update.emit(mission);
  }

  onExport(mission: any): void {
    if (!mission) return;

    this.missionStore.dispatch(fromMissionAction.exportMissionPDF({ mission }));
  }

  onDelete(mission: any): void {
    if (!mission || !mission.id) return;
    this.delete.emit(mission);
    this.onBack();
  }

  onBack(): void {
    this.view$.next('list');
    this.clear.next(true);
  }

  load_metier_domaines(): void {
    this.metierStore.dispatch(fromMetierAction.loadDomaines());
  }
  load_typescontrat(): any {
    this.contratStore.dispatch(fromContratAction.loadTypesContrats());
  }
  load_naturescontrat(): any {
    this.contratStore.dispatch(fromContratAction.loadNaturesContrats());
  }
  load_experiencescontrat(): any {
    this.contratStore.dispatch(fromContratAction.loadNiveauExperiences());
  }
  load_salairescontrat(): any {
    this.contratStore.dispatch(fromContratAction.loadNiveauSalaire());
  }
  load_permis(): any {
    this.contratStore.dispatch(fromContratAction.loadPermis());
  }
  load_level_numerique(): any {
    this.contratStore.dispatch(fromContratAction.loadLevelNumerique());
  }

  load_complement_salaire(): any {
    this.contratStore.dispatch(fromContratAction.loadComplementSalaire());
  }
  load_type_horaire(): any {
    this.contratStore.dispatch(fromContratAction.loadTypesHoraire());
  }
  load_mobility_geographic(): any {
    this.contratStore.dispatch(fromContratAction.loadMobilityGeographic());
  }

  load_contrat_days(): any {
    this.contratStore.dispatch(fromContratAction.loadContratJours());
  }
  load_evolution_type(): any {
    this.contratStore.dispatch(fromContratAction.loadEvolutionTypes());
  }

  onCancel(): void {
    return;
  }
}
