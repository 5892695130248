import { Update } from '@ngrx/entity';
import { takeUntil } from 'rxjs/operators';
import { transformToIds } from './../../../../utils/fx-transforms';
import { ModuleId, ModuleSmallId } from './../../../module/store/module.model';
import {
  FormControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { OperationId } from './../../../operation/store/operation.model';
import { Observable, of, Subject, BehaviorSubject } from 'rxjs';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnDestroy,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ActionId, Action } from '../../store/action.model';
import { Store } from '@ngrx/store';
import { ActionState } from '../../store/action.reducer';
import * as fromActionAction from '../../store/action.actions';
import * as fromActionSelector from '../../store/action.selectors';

import {
  differenceInMonths,
  differenceInCalendarDays,
  fromUnixTime,
} from 'date-fns';
import { Timestamp } from '@firebase/firestore';
import { capitalize } from 'lodash';

@Component({
  selector: 'app-action-form',
  templateUrl: './action-form.component.html',
  styleUrls: ['./action-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionFormComponent implements OnInit, OnChanges, OnDestroy {
  subscribe = new Subject();
  isAdd: boolean = true;
  isUpdate: boolean = false;
  titleModule: string = '';
  titleForm: string = '';
  moduleExist: boolean = false;
  updateAdresse = new BehaviorSubject<any>(null);
  updateAdresseComplement = new BehaviorSubject<any>('');
  dependencesSelected: any;
  //FORMAT DATES
  dateFormat = 'dd/mm/yyyy HH:mm';
  dateMin: Date | any;
  dateMax: Date | any;
  currentStep: number = 0;
  types$: Observable<any> = of(null);

  utilities$: Observable<any> = of(null);

  /* FROM */
  actionForm: UntypedFormGroup = this.fb.group({});
  type: UntypedFormControl = new UntypedFormControl();
  title: UntypedFormControl = new UntypedFormControl();
  dateStart: UntypedFormControl = new UntypedFormControl();
  dateEnd: UntypedFormControl = new UntypedFormControl();
  dateUpdate: UntypedFormControl = new UntypedFormControl();
  duration: UntypedFormControl = new UntypedFormControl();
  adresse: UntypedFormControl = new UntypedFormControl();
  complement: UntypedFormControl = new UntypedFormControl();
  qpv: UntypedFormControl = new UntypedFormControl();
  adressePrecision: UntypedFormControl = new UntypedFormControl();
  nombreInscrit: UntypedFormControl = new UntypedFormControl();
  nomnreValidation: UntypedFormControl = new UntypedFormControl();
  repriodicite: UntypedFormControl = new UntypedFormControl();
  status: FormControl<string> = new FormControl('En cours', {
    nonNullable: true,
  });
  userUpdate: UntypedFormControl = new UntypedFormControl();
  invitesBeneficiaire: UntypedFormControl = new UntypedFormControl();
  invitesEntite: UntypedFormControl = new UntypedFormControl();
  invitesContacts: UntypedFormControl = new UntypedFormControl();
  participantsBeneficiaire: UntypedFormControl = new UntypedFormControl();
  participantsEntite: UntypedFormControl = new UntypedFormControl();
  participantsContacts: UntypedFormControl = new UntypedFormControl();
  referents: UntypedFormControl = new UntypedFormControl();
  referentsId: UntypedFormControl = new UntypedFormControl();
  referentsMapIds: UntypedFormControl = new UntypedFormControl();
  participants: UntypedFormControl = new UntypedFormControl();
  commentaire: UntypedFormControl = new UntypedFormControl();
  link: FormControl<string> = new UntypedFormControl('');
  logo: FormControl<string> = new UntypedFormControl('');
  logoRef: FormControl<string> = new UntypedFormControl('');
  limitationInscrit: UntypedFormControl = new UntypedFormControl();
  allDay: FormControl<boolean> = new FormControl(false, { nonNullable: true });
  auths: UntypedFormControl = new UntypedFormControl();
  auteur: UntypedFormControl = new UntypedFormControl();
  organisation: UntypedFormControl = new UntypedFormControl();
  operation: UntypedFormControl = new UntypedFormControl();
  entiteNiveau1Id: UntypedFormControl = new UntypedFormControl();
  entiteNiveau1Etablissment: UntypedFormControl = new UntypedFormControl();
  entiteNiveau1Role: UntypedFormControl = new UntypedFormControl();
  entiteNiveau2Id: UntypedFormControl = new UntypedFormControl();
  entiteNiveau2Etablissment: UntypedFormControl = new UntypedFormControl();
  entiteNiveau2Role: UntypedFormControl = new UntypedFormControl();
  limitationMinimale: UntypedFormControl = new UntypedFormControl();
  module: UntypedFormControl = new UntypedFormControl();
  access: UntypedFormControl = new UntypedFormControl();
  etat: FormControl<number> = new FormControl(0, { nonNullable: true });
  isFinish: FormControl<boolean> = new FormControl(false, {
    nonNullable: true,
  });
  objectifs: UntypedFormControl = new UntypedFormControl();
  effectifMin: UntypedFormControl = new UntypedFormControl();
  effectifMax: UntypedFormControl = new UntypedFormControl();
  objectifHours: FormControl<number> = new FormControl(0, {
    nonNullable: true,
  });
  objectifHoursTheoric: FormControl<number> = new FormControl(0, {
    nonNullable: true,
  });
  objectifPersons: FormControl<number> = new FormControl(0, {
    nonNullable: true,
  });
  objectifPersonsTheoric: FormControl<number> = new FormControl(0, {
    nonNullable: true,
  });
  progressionHours: FormControl<number> = new FormControl(0, {
    nonNullable: true,
  });
  progressionPersons: FormControl<number> = new FormControl(0, {
    nonNullable: true,
  });

  dependences: UntypedFormControl = new UntypedFormControl([]);
  phases: FormControl<number> = new FormControl(0, { nonNullable: true });
  attentesBeneficiaire: UntypedFormControl = new UntypedFormControl();
  attentesEntite: UntypedFormControl = new UntypedFormControl();
  attentesContacts: UntypedFormControl = new UntypedFormControl();
  participantsId: UntypedFormControl = new UntypedFormControl();
  contactId: UntypedFormControl = new UntypedFormControl();
  entiteId: UntypedFormControl = new UntypedFormControl();
  definition: UntypedFormControl = new UntypedFormControl();
  formEntites: UntypedFormControl = new UntypedFormControl();
  entitesIds: UntypedFormControl = new UntypedFormControl();
  entitesMapIds: UntypedFormControl = new UntypedFormControl();
  contacts: UntypedFormControl = new UntypedFormControl();
  contactsIds: UntypedFormControl = new UntypedFormControl([]);
  contactsMapIds: UntypedFormControl = new UntypedFormControl();
  formParticipants: UntypedFormControl = new UntypedFormControl();
  participantsIds: UntypedFormControl = new UntypedFormControl();
  participantsMapIds: UntypedFormControl = new UntypedFormControl();

  adresseEdit = false;
  selectedAdresse: any;
  adresses: any[] = [];
  dateStartDateEnd: Date[] = [];
  adresseComplementEdit = false;
  selectedAdresseComplement: any;
  adresseUpdate = new BehaviorSubject<any>(null);
  adresseComplementUpdate = new BehaviorSubject<any>(null);
  coordonneesUpdate = new BehaviorSubject<any>(null);

  @Input() inputModule$ = new BehaviorSubject<ModuleId | any>(null);

  @Input() operation$: Observable<OperationId | any> = of(null);
  @Input() module$: Observable<ModuleId | any> = of(null);
  action$: Observable<ActionId | any> = of(null);

  @Output() cancel = new EventEmitter<boolean>(false);
  @Output() add = new EventEmitter<any>(false);
  @Output() update = new EventEmitter<any>(false);

  constructor(
    private fb: UntypedFormBuilder,
    private actionStore: Store<ActionState>
  ) {}

  ngOnInit(): void {
    this.form();
    this.getActionTypes();
  }
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {
    this.subscribe.next(null);
    this.subscribe.complete();
  }

  getActionTypes(): void {
    this.types$ = this.actionStore.select(fromActionSelector.types);
  }

  form(): void {
    this.formInit();
    this.formCreate();
  }

  formInit(): void {
    this.auths = new UntypedFormControl([]);
    this.auteur = new UntypedFormControl();
    this.access = new UntypedFormControl();
    this.userUpdate = new UntypedFormControl();
    this.module = new UntypedFormControl({}, Validators.required);
    this.etat = new UntypedFormControl(0, Validators.required);
    this.status = new UntypedFormControl('En cours', Validators.required);
    this.isFinish = new FormControl(false, {
      nonNullable: true,
    });
    this.effectifMin = new FormControl(0, { nonNullable: true });
    this.effectifMax = new FormControl(0, { nonNullable: true });

    this.objectifHours = new FormControl(0, { nonNullable: true });
    this.objectifHoursTheoric = new FormControl(0, { nonNullable: true });
    this.objectifPersons = new FormControl(0, { nonNullable: true });
    this.objectifPersonsTheoric = new FormControl(0, { nonNullable: true });
    this.progressionHours = new FormControl(0, { nonNullable: true });
    this.progressionPersons = new FormControl(0, { nonNullable: true });

    this.dependences = new UntypedFormControl([]);
    this.phases = new FormControl(0, { nonNullable: true });
    this.attentesBeneficiaire = new UntypedFormControl([]);
    this.attentesEntite = new UntypedFormControl([]);
    this.attentesContacts = new UntypedFormControl([]);
    this.allDay = new FormControl(false, { nonNullable: true });
    this.complement = new UntypedFormControl();
    this.qpv = new UntypedFormControl();
    this.limitationInscrit = new UntypedFormControl(0);
    this.dateStart = new UntypedFormControl(
      new Date(Date.now()),
      Validators.required
    );
    this.dateEnd = new UntypedFormControl(
      new Date(Date.now()),
      Validators.required
    );
    this.dateUpdate = new UntypedFormControl(
      new Date(Date.now()),
      Validators.required
    );
    this.type = new UntypedFormControl('', Validators.required);
    this.title = new UntypedFormControl('', {
      validators: Validators.required,
    });
    this.limitationMinimale = new UntypedFormControl(0);
    this.duration = new UntypedFormControl();
    this.adresse = new UntypedFormControl();
    this.adressePrecision = new UntypedFormControl('');
    this.nombreInscrit = new UntypedFormControl();
    this.nomnreValidation = new UntypedFormControl();
    this.repriodicite = new UntypedFormControl();

    this.invitesBeneficiaire = new UntypedFormControl([]);
    this.invitesEntite = new UntypedFormControl([]);
    this.invitesContacts = new UntypedFormControl([]);
    this.participantsBeneficiaire = new UntypedFormControl([]);
    this.participantsEntite = new UntypedFormControl([]);
    this.participantsContacts = new UntypedFormControl([]);
    this.participantsId = new UntypedFormControl([]);
    this.contactId = new UntypedFormControl([]);
    this.entiteId = new UntypedFormControl([]);
    this.definition = new UntypedFormControl('');
    this.referents = new UntypedFormControl([]);
    this.referentsId = new UntypedFormControl([]);
    this.referentsMapIds = new UntypedFormControl();
    this.participants = new UntypedFormControl([]);
    this.commentaire = new UntypedFormControl('');
    this.link = new UntypedFormControl('');
    this.logo = new UntypedFormControl('');
    this.logoRef = new UntypedFormControl('');
    this.organisation = new UntypedFormControl();
    this.operation = new UntypedFormControl();
    this.entiteNiveau1Id = new UntypedFormControl();
    this.entiteNiveau1Etablissment = new UntypedFormControl();
    this.entiteNiveau2Role = new UntypedFormControl();
    this.entiteNiveau1Role = new UntypedFormControl();
    this.entiteNiveau2Id = new UntypedFormControl();
    this.entiteNiveau2Etablissment = new UntypedFormControl();
    this.formEntites = new UntypedFormControl();
    this.entitesIds = new UntypedFormControl([]);
    this.entitesMapIds = new UntypedFormControl();
    this.contacts = new UntypedFormControl([]);
    this.contactsIds = new UntypedFormControl([]);
    this.contactsMapIds = new UntypedFormControl();
    this.formParticipants = new UntypedFormControl();
    this.participantsIds = new UntypedFormControl();
    this.participantsMapIds = new UntypedFormControl();
  }

  formCreate(): void {
    this.actionForm = this.fb.group({
      type: this.type,
      title: this.title,
      auths: this.auths,
      auteur: this.auteur,
      module: this.module,
      access: this.access,
      isFinish: this.isFinish,
      dateStart: this.dateStart,
      dateEnd: this.dateEnd,
      dateUpdate: this.dateUpdate,
      etat: this.etat,
      effectifMin: this.effectifMin,
      effectifMax: this.effectifMax,
      allDay: this.allDay,
      duration: this.duration,
      entiteLevel1: new UntypedFormArray([]),
      entiteLevel2: new UntypedFormArray([]),
      entitesIds: this.entitesIds,

      adresse: new UntypedFormGroup({
        adresse: this.adresse,
        complement: this.complement,
        qpv: this.qpv,
      }),
      adressePrecision: this.adressePrecision,
      status: this.status,
      referents: this.referents,
      referentsId: this.referentsId,
      referentsMapIds: this.referentsMapIds,
      commentaire: this.commentaire,
      link: this.link,
      logo: this.logo,
      logoRef: this.logoRef,
      organisation: this.organisation,

      objectifHours: this.objectifHours,
      objectifHoursTheoric: this.objectifHoursTheoric,
      objectifPersons: this.objectifPersons,
      objectifPersonsTheoric: this.objectifPersonsTheoric,
      progressionHours: this.progressionHours,
      progressionPersons: this.progressionPersons,

      userUpdate: this.userUpdate,
      phases: this.phases,

      dependences: this.dependences,
      attentesBeneficiaire: this.attentesBeneficiaire,
      attentesEntite: this.attentesEntite,
      attentesContacts: this.attentesContacts,
      definition: this.definition,

      nombreInscrit: this.nombreInscrit,
      nomnreValidation: this.nomnreValidation,
      repriodicite: this.repriodicite,
      invitesBeneficiaire: this.invitesBeneficiaire,
      invitesEntite: this.invitesEntite,
      invitesContact: this.invitesContacts,
      participantsBeneficiaire: this.participantsBeneficiaire,
      participantsEntite: this.participantsEntite,
      participantsContacts: this.participantsContacts,
      participantsId: this.participantsId,
      contactId: this.contactId,
      entiteId: this.entiteId,

      entitesMapIds: this.entitesMapIds,
      contacts: this.contacts,
      contactsIds: this.contactsIds,
      contactsMapIds: this.contactsMapIds,
      participants: this.formParticipants,
      participantsIds: this.participantsIds,
      participantsMapIds: this.participantsMapIds,
    });
  }

  formSet(): void {
    this.action$
      .pipe(takeUntil(this.subscribe))
      .subscribe((action: ActionId) => {
        if (!action) {
          this.newAction();
          return;
        } else {
          this.upsetAction(action);
        }
      });
  }

  //UTILS
  getUtilities(): void {
    this.utilities$ = this.actionStore.select(fromActionSelector.utils);
  }
  loadUtilities(): void {
    this.actionStore.dispatch(fromActionAction.loadUtils());
  }

  newAction(): void {
    this.isUpdate = false;
    this.isAdd = true;
  }
  upsetAction(action: ActionId): void {
    this.isUpdate = true;
    this.isAdd = false;
    const { title } = action;
    this.titleForm = `Modification : ${title}`;
    this.updateAdresse.next(action?.adresse?.adresse);
    this.updateAdresseComplement.next(action?.adresse?.complement);

    this.dependencesSelected = action?.dependences;

    this.actionForm.setValue({ ...action });
  }

  setActionFromModuleInput(): void {
    this.inputModule$
      .pipe(takeUntil(this.subscribe))
      .subscribe((module): void => {
        if (!module) {
          this.moduleExist = false;
          return;
        }

        if (module) {
          this.isAdd = false;
          this.isUpdate = false;
          this.moduleExist = true;
          this.dateMin = fromUnixTime(module.calendrier.dateStart['seconds']);
          this.dateMax = fromUnixTime(module.calendrier.dateEnd['seconds']);

          const { operation } = module;
          const { description, denomination } = operation;
          const { definition, calendrier } = description;

          this.actionForm.patchValue({
            module: {
              ...this.module,
              operation: {
                id: operation.id,
                denomination: denomination,
                description: {
                  definition: definition,
                  calendrier: calendrier,
                },
              },
            },
            type: module.type,
            title: module.title,
            definition: module.description.definition,
            commentaire: module.description.deroule,
          });
        }
      });
  }

  onChangeModule(): void {
    this.actionForm.get('module')?.valueChanges.subscribe((val) => {
      if (val) {
        const start = val?.calendrier?.dateStart
          ? fromUnixTime(val.calendrier.dateStart['seconds'])
          : null;
        const end = val?.calendrier?.dateEnd
          ? fromUnixTime(val.calendrier.dateEnd['seconds'])
          : null;
        this.dateMin = start;
        this.dateMax = end;
      }
    });
  }

  onSelectType(type: any): void {
    this.actionForm.patchValue({
      type: type,
    });
  }

  onSelectModule(module: ModuleSmallId | any): void {
    if (!module) return;

    const {
      operation,
      effectifMax,
      effectifMin,
      objectifHours,
      objectifHoursTheoric,
      objectifPersons,
      objectifPersonsTheoric,
    } = module;
    const { description, denomination } = operation;
    const { definition, calendrier } = description;

    this.actionForm.patchValue({
      module: {
        id: module.id,
        title: module.title,
        description: module.description,
        calendrier: module.calendrier,
        effectifMax: effectifMax ? effectifMax : 0,
        effectifMin: effectifMin ? effectifMin : 0,
        objectifHours,
        objectifHoursTheoric,
        objectifPersons,
        objectifPersonsTheoric,
        operation: {
          id: operation.id,
          type: operation.type,
          denomination: denomination,
          description: {
            definition: definition,
            calendrier: calendrier,
          },
        },
      },
    });
  }
  onSelectEntite(item: any): void {
    if (!item) return;
    const id = item?.id;
    this.actionForm.patchValue({
      entiteNiveau1: {
        id: id,
        etablissement: item?.entite?.etablissement,
        role: item?.intervention?.role,
      },
      entitesIds: [id],
      entitesMapIds: { id },
    });
  }

  compareType(o1: any, o2: any) {
    return o1 && o2 && o1 === o2;
  }

  compareModule(o1: any, o2: any) {
    return o1 && o2 && o1.title === o2.title;
  }
  compareAdresse(o1: any, o2: any) {
    return o1 === o2 ? o1.properties.label === o2.properties.label : o1 === o2;
  }

  disabledDateMin = (current: Date): boolean => {
    return differenceInCalendarDays(this.dateMin, current) > 0;
  };

  disabledDateMax = (current: Date): boolean => {
    return differenceInCalendarDays(current, this.dateMax) > 0;
  };

  onAdresse(event: any): void {
    this.actionForm.patchValue({
      adresse: {
        adresse: event,
      },
    });
  }

  onAdresseComplement(event: any): void {
    this.actionForm.patchValue({
      adresse: {
        complement: event,
      },
    });
  }

  onAllDay(event: any): void {
    if (event) {
      const { dateDebut, dateFin, hourDebut, HourFin, AllDay } = event;
      this.actionForm.patchValue({
        dateStart: dateDebut,
        dateEnd: dateFin,
        hourStart: hourDebut,
        hourEnd: HourFin,
        allDay: AllDay,
        dateUpdate: dateDebut,
      });
    }
  }

  onChangeDate(event: any): void {}

  onOkDate(event: any): void {
    const start: Date = event[0];
    const end: Date = event[1];
    const duree = differenceInMonths(end, start);
    this.actionForm.patchValue({
      dateStart: start,
      dateUpdate: Timestamp.fromDate(start),
      dateEnd: end,
      duration: duree,
    });
  }

  onReferents(event: any): void {
    if (!event?.length) {
      return;
    }

    const transformes = transformToIds(event);

    this.actionForm.patchValue({
      referents: transformes.items,
      referentsId: transformes.ids,
      referentsMapIds: transformes.idsMap,
    });
  }

  //STEPS
  onPrevStep(): void {
    this.currentStep = this.currentStep === 0 ? 0 : this.currentStep - 1;
  }

  onNextStep(): void {
    this.currentStep = this.currentStep === 3 ? 3 : this.currentStep + 1;
  }

  onAdd(operation: OperationId): void {
    const newAction: Action | any = this.actionForm.value;
    const {
      title,
      type,
      definition,
      isFinish,
      status,
      adresse,
      adressePrecision,
      allDay,
      module,
      dateStart,
      dateEnd,
      effectifMax,
      effectifMin,
      objectifHours,
      objectifHoursTheoric,
      objectifPersons,
      objectifPersonsTheoric,
      progressionHours,
      progressionPersons,
      entiteLevel1,
      entiteLevel2,
      contacts,
      contactsIds,
      contactsMapIds,
      etat,
      commentaire,
      dependences,
      link,
      logo,
      logoRef,
      phases,
    } = newAction;
    const start = Timestamp.fromDate(dateStart);
    const end = Timestamp.fromDate(dateEnd);
    const duration = differenceInMonths(dateEnd, dateStart);

    const action = {
      title: capitalize(title),
      type,
      definition,
      isFinish,
      status,
      adresse,
      adressePrecision,
      allDay,
      module,
      dateStart: start,
      dateEnd: end,
      duration,
      effectifMax,
      effectifMin,
      objectifHours,
      objectifHoursTheoric,
      objectifPersons,
      objectifPersonsTheoric,
      progressionHours,
      progressionPersons,
      entiteLevel1,
      entiteLevel2,
      contacts,
      contactsIds,
      etat,
      commentaire,
      dependences,
      link,
      logo,
      logoRef,
      phases,
    };

    this.add.emit({ operation, action });
    this.actionForm.reset();
    this.currentStep = 0;
    this.formInit();
    this.formCreate();
  }

  onUpdate(event: any, operation: OperationId, currentAction: ActionId): void {
    event.preventDefault();
    event.stopPropagation();
    const action: Action = this.actionForm.value;
    const { dateStart, dateEnd, dateUpdate, dateSend } = action;
    const start = Timestamp.fromDate(new Date(dateStart.toString()));
    const end = Timestamp.fromDate(new Date(dateEnd.toString()));
    const send = dateSend
      ? Timestamp.fromDate(new Date(dateSend.toString()))
      : null;

    action.dateStart = start;
    action.dateEnd = end;
    action.dateSend = send;

    const update_Action: Update<ActionId> = {
      id: currentAction.id,
      changes: {
        ...currentAction,
        ...action,
      },
    };

    this.update.emit({ operation, update_Action });
    this.actionForm.reset();
  }

  onCancel(): void {
    this.cancel.emit(true);
  }
}
