<form nz-form [formGroup]="santeForm">
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="12">
      <nz-form-item>
        <nz-form-label [nzSpan]="24">Santé</nz-form-label>
        <nz-form-control>
          <nz-select
            formControlName="sante"
            placeholder="Niveau de santé"
            nzBorderless
            nzPlaceHolder="Niveau de santé"
          >
            <nz-option
              *ngFor="let item of sante$ | async"
              [nzLabel]="item"
              [nzValue]="item"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col nzSpan="12">
      <nz-form-item>
        <nz-form-label [nzSpan]="24">Couverture maladie</nz-form-label>
        <nz-form-control>
          <nz-select
            formControlName="couvertureMaladie"
            placeholder="Couverture maladie"
            nzBorderless
            nzPlaceHolder="Couverture maladie"
            [compareWith]="compareCouverture"
          >
            <nz-option
              *ngFor="let item of couvertureMaladie$ | async"
              [nzLabel]="item"
              [nzValue]="item"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="12">
      <nz-form-item style="width: 100%">
        <nz-form-label [nzSpan]="24">Handicap </nz-form-label>
        <nz-form-control>
          <nz-select
            formControlName="handicap"
            placeholder="Type d'handicap"
            nzBorderless
            nzPlaceHolder="Type d'handicap"
            [nzDropdownRender]="renderOtherHandicapTemplate"
            [compareWith]="compareHandicap"
          >
            <nz-option
              *ngFor="let item of handicap$ | async"
              [nzLabel]="item | titlecase"
              [nzValue]="item"
            ></nz-option>
          </nz-select>
        </nz-form-control>
        <ng-template #renderOtherHandicapTemplate>
          <nz-divider></nz-divider>
          <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
            <nz-col nzSpan="2"></nz-col>
            <nz-col nzSpan="20">
              <nz-form-item style="width: 100%">
                <nz-form-label [nzSpan]="24">Autre</nz-form-label>
                <nz-form-control>
                  <input
                    nz-input
                    nzBorderless
                    formControlName="handicap"
                    class="borderlessClass"
                    placeholder="Autre à préciser..."
                  />
                </nz-form-control>
              </nz-form-item>
            </nz-col>
            <nz-col nzSpan="2"></nz-col>
          </nz-row>
        </ng-template>
      </nz-form-item>
    </nz-col>
    <nz-col nzSpan="12">
      <nz-form-item>
        <nz-form-label [nzSpan]="24">Sécurité sociale (NIR)</nz-form-label>
        <nz-form-control>
          <nz-input-number
            formControlName="nirSecuriteSociale"
            nzPlaceHolder="N° sécurité sociale"
          ></nz-input-number>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>
  <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
  <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
    <nz-col nzSpan="24">
      <nz-form-item>
        <nz-form-control>
          <label nz-checkbox formControlName="isFrein">{{
            isFreinTitle
          }}</label>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>
</form>
