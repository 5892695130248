import { Update } from '@ngrx/entity';
import { OperationId } from 'src/app/components/operation/store/operation.model';
import { Store } from '@ngrx/store';
import { ActionState } from 'src/app/components/action/store/action.reducer';
import * as fromActionSelector from 'src/app/components/action/store/action.selectors';
import * as fromActionAction from 'src/app/components/action/store/action.actions';

import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  OnChanges,
  ChangeDetectionStrategy,
  SimpleChanges,
} from '@angular/core';
import { ActionId } from '../../store/action.model';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-action-item',
  templateUrl: './action-item.component.html',
  styleUrls: ['./action-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionItemComponent implements OnInit, OnChanges {
  color: string = '#597ef7';
  progressCompleteColor: string = '#49d49d';
  progressDefaultColor: string = '#062949';

  title: string = 'Nouveau module';
  suivisSource$: Observable<any[]> = of([]);
  documentsSource$: Observable<any[]> = of([]);
  missionsSource$: Observable<any[]> = of([]);
  actionsSource$: Observable<any[]> = of([]);

  operation$: Observable<OperationId | any> = of();

  @Input() action: ActionId | any = null;
  @Output() back = new EventEmitter<string>(false);

  constructor(private actionStore: Store<ActionState>) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}

  onBack(): void {
    this.back.emit('actions');
  }

  //SUIVIS
  // getSuivis(): void {
  //   this.suivisSource$ = this.actionStore.select(fromActionSelector.suivis);
  // }
  // loadSuivis(): void {
  //   this.actionStore.dispatch(fromActionAction.loadSuivis());
  // }
  // addSuivi(suivi: any, action: ActionId): void {
  //   if (!suivi) return;

  //   this.actionStore.dispatch(
  //     fromActionAction.addSuivi({
  //       suivi: suivi,
  //       moduleId: module.id,
  //       operationId: module.operation.id,
  //     })
  //   );
  // }
  // updateSuivi(suivi: any, module: ActionId): void {
  //   if (!suivi) return;
  //   const nextSuivi: Update<any> = {
  //     id: suivi.id,
  //     changes: { ...suivi },
  //   };
  //   this.moduleStore.dispatch(
  //     fromModuleAction.updateSuivi({
  //       suivi: nextSuivi,
  //       moduleId: module.id,
  //       operationId: module.operation.id,
  //     })
  //   );
  // }
  // deleteSuivi(suivi: any, module: ModuleId): void {
  //   if (!suivi) return;
  //   this.moduleStore.dispatch(
  //     fromModuleAction.deleteSuivi({
  //       suiviId: suivi.id,
  //       moduleId: module.id,
  //       operationId: module.operation.id,
  //     })
  //   );
  // }

  //DOCUMENTS
  // getDocuments(): void {
  //   this.documentsSource$ = this.moduleStore.select(
  //     fromModuleSelector.documents
  //   );
  // }
  // loadDocuments(): void {
  //   this.moduleStore.dispatch(fromModuleAction.loadDocuments());
  // }
  // addDocument(documents: any[], module: ModuleId): void {
  //   if (!documents?.length || !module.id) return;

  //   this.moduleStore.dispatch(
  //     fromModuleAction.addDocuments({
  //       module: module,
  //       documents: documents,
  //     })
  //   );
  // }
  // deleteDocument(document: any, module: ModuleId): void {
  //   if (!document) return;
  //   this.moduleStore.dispatch(
  //     fromModuleAction.deleteDocument({
  //       module: module,
  //       document: document,
  //     })
  //   );
  // }

  //MISSIONS
  // getMissions(): void {
  //   this.missionsSource$ = this.moduleStore.select(fromModuleSelector.missions);
  // }
  // loadMissions(): void {
  //   this.moduleStore.dispatch(fromModuleAction.loadMissions());
  // }
}
