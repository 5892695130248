<ng-container *ngIf="user$ | async as user">
  <nz-card class="container">
    <div id="export" class="page" data-size="A4" #exportHTML>
      <!-- HEADER -->
      <div class="header__Card"></div>

      <!-- PARTICIPANTS -->
      <div class="sujetTable">
        <app-operation-note-export-participants
          [participants]="participants$ | async"
        >
        </app-operation-note-export-participants>
      </div>

      <!-- SUJETS TABLE -->
      <div class="sujetTable">
        <table
          *ngFor="let groupSujets of sujets$ | async as sujets; let i = index"
        >
          <thead>
            <tr class="rowHeader">
              <th class="sujetRowHeader">
                <nz-row nzAlign="middle" nzGutter="8" nzJustify="start">
                  <nz-col nzSpan="24">
                    {{ i + 1 }}
                    <nz-divider
                      nzType="vertical"
                      class="whiteTitle"
                    ></nz-divider>

                    <strong nz-typography class="whiteTitle">
                      {{ groupSujets?.[0]?.subtitle?.title | uppercase }}
                    </strong>
                  </nz-col>
                </nz-row>
              </th>
            </tr>
          </thead>

          <tbody id="idBody" *ngFor="let el of groupSujets">
            <tr class="rowSujet" id="rowSujet">
              <td>
                <nz-card
                  [nzTitle]="sujettitle"
                  [nzExtra]="sujetExtra"
                  class="cardSujet"
                >
                  <p
                    [innerHTML]="el.textHTML | stringhtml"
                    class="paragraph"
                    [ngStyle]="{ color: el?.isWarning ? 'red' : '' }"
                  ></p>
                  <ng-template #sujettitle>
                    <p nz-typography>
                      <nz-row nzAlign="middle" nzGutter="8" nzJustify="end">
                        <nz-col nzSpan="12">
                          <p>Crée le {{ el?.dateStart | dateFr: "small" }}</p>
                        </nz-col>
                        <nz-col nzSpan="12">
                          <p>
                            Modifié le {{ el?.dateUpdate | dateFr: "small" }}
                          </p>
                        </nz-col>
                      </nz-row>
                    </p>
                  </ng-template>
                  <ng-template #sujetExtra>
                    <nz-row nzAlign="middle" nzGutter="8" nzJustify="end">
                      <nz-col nzSpan="12">
                        <nz-tag *ngIf="el?.isWarning" nzColor="error">
                          Alerte
                        </nz-tag>
                      </nz-col>

                      <nz-col nzSpan="12">
                        <nz-tag *ngIf="el?.isDone" nzColor="success">
                          Réalisé
                        </nz-tag>
                      </nz-col>
                    </nz-row>
                  </ng-template>
                </nz-card>
              </td>
              <nz-divider nzType="horizontal"></nz-divider>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </nz-card>

  <nz-divider></nz-divider>

  <nz-row
    nzAlign="middle"
    nzGutter="8"
    nzJustify="end"
    *ngIf="operation$ | async as operation"
  >
    <nz-col nzSpan="16"></nz-col>
    <nz-col nzSpan="4">
      <button nz-button nzBlock nzType="text" (click)="onCancel()">
        Annuler
      </button>
    </nz-col>
    <nz-col nzSpan="4" *ngIf="note$ | async as note">
      <button
        nz-button
        nzBlock
        *ngIf="sujets$ | async as sujets"
        nzType="primary"
        (click)="onExportValidation(user, operation, note)"
      >
        Valider
      </button>
    </nz-col>
  </nz-row>

  <ng-template #loading>
    <app-loading></app-loading>
  </ng-template>
</ng-container>
