import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { OperationId } from 'src/app/components/operation/store/operation.model';

@Component({
  selector: 'app-operation-events-list',
  templateUrl: './operation-events-list.component.html',
  styleUrls: ['./operation-events-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationEventsListComponent implements OnInit, OnChanges {
  events$: Observable<any> = of(null);
  subscribe = new Subject();
  view$ = new BehaviorSubject<string>('list');
  loading$: Observable<boolean> = of(false);
  title: string = 'Nouvel évènement';

  newCardTitleClauses: string = "Nouveau corps d'état";

  newAddItemTitle: string = 'Ajouter un évènement';
  noDataTitle: string = 'Aucun évènement ajouté';
  newItemDefinition: string = 'Un évènement est un ';

  newCardDefinitionClauses: string =
    "Partie d'une construction formant une unité ou présentant une certaine autonomie";

  @ViewChild('eventFormTitle', { static: false })
  eventFormTitle: TemplateRef<any> | any;
  modalWidth: number = 850;

  @Input() operation$: Observable<OperationId | any> = of(null);
  @Output() select = new EventEmitter<any>(false);

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}
}
