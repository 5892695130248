<nz-card [nzTitle]="missionContratHeaderTpl" nzSize="small" nzBorderless>
  <ng-template #missionContratHeaderTpl>
    <app-header-title
      [icon]="'payments'"
      [title]="''"
      [subtitle]="true"
    ></app-header-title>
  </ng-template>

  <form nz-form [formGroup]="contratForm" class="space-y-6 p4">
    <!-- TYPE ET NATURE DU CONTRAT -->

    <div class="w-full space-x-2 flex items-start mb-12">
      <div class="w-1/2">
        <label class="label required">Taux horaire</label>
        <div class="numberForm">
          <nz-input-number
            formControlName="work_price"
            nzPlaceHolder="Taux horaire en euro"
            class="numberField"
            [nzMin]="0"
          ></nz-input-number>
        </div>
        <small
          *ngIf="
            !contratForm.value?.work_price.valid &&
            contratForm.value?.work_price.touched &&
            contratForm.value?.work_price.dirty
          "
          class="inputFormAlert"
          >Veuillez renseigner le taux horaire</small
        >
      </div>
      <div class="w-1/2">
        <label class="label required" nzTooltipTitle="Brut mensuel"
          >Niveau de salaire</label
        >
        <div class="select-menu">
          <nz-select
            nzPlaceHolder="Type de salaire"
            nzBorderless
            formControlName="salaire"
            nzServerSearch
          >
            <nz-option
              *ngFor="let item of niveauSalaire$ | async"
              [nzLabel]="item?.libelleCaracteristique"
              [nzValue]="item"
            ></nz-option>
          </nz-select>
          <small
            class="inputFormAlert"
            *ngIf="
              !contratForm.value?.salaire &&
              contratForm.value?.salaire.touched &&
              contratForm.value?.salaire.dirty
            "
          >
            Veuillez renseigner le niveau de rémunération
          </small>
        </div>
      </div>
    </div>

    <!-- COMPLÉMENTS DE SALAIRE -->
    <nz-divider
      nzOrientation="left"
      nzType="horizontal"
      [nzText]="completementDivider"
    ></nz-divider>
    <ng-template #completementDivider>
      <div class="w-full flex items-start">
        {{ complementSalaireDividerTitle }}
        <nz-badge
          [nzCount]="salaireComplementsArray.controls.length"
          [nzOffset]="[-2, -5]"
        ></nz-badge>
      </div>
    </ng-template>

    <!-- COMPLEMENT DE SAIARE LIST -->
    <nz-card nzSize="small" nzBorderless [nzTitle]="salaireComplementHeader">
      <form nz-form [formGroup]="contratForm">
        <nz-list
          nzSize="small"
          formArrayName="salaireComplements"
          class="w-full flex flex-col p-2"
          *ngIf="
            salaireComplementsArray.controls.length;
            else noComplementsSalaire
          "
        >
          <nz-list-item
            *ngFor="
              let complement of salaireComplementsArray.controls;
              let i = index
            "
            [formGroupName]="i"
            nzNoFlex
            class="w-full flex items-start"
          >
            <div class="w-full flex items-center space-x-2">
              <div class="w-1/12">
                <mat-icon>short_text</mat-icon>
              </div>
              <div class="w-4/12">
                {{ complement?.value?.libelle?.libelle | titlecase }}
              </div>

              <div class="w-2/12">
                <div class="numberForm">
                  <nz-input-number
                    formControlName="taux"
                    nzPlaceHolder="Taux"
                    [nzMin]="0"
                    [nzPrecision]="4"
                    class="numberField"
                  ></nz-input-number>
                </div>
              </div>

              <div class="w-2/12">
                <div class="w-full">
                  <div class="select-menu">
                    <nz-select
                      nzPlaceHolder="Unité"
                      formControlName="unit"
                      [compareWith]="formCompare"
                      nzBorderless
                    >
                      <nz-option
                        *ngFor="let el of unit_types$ | async"
                        [nzLabel]="el"
                        [nzValue]="el"
                      ></nz-option>
                    </nz-select>
                  </div>
                </div>
              </div>
              <div class="w-2/12">
                <button
                  class="btn-icon bg-white group/button"
                  type="button"
                  (click)="
                    $event.stopPropagation(); onRemoveComplementSalaire(i)
                  "
                >
                  <svg
                    class="iconSvg-delete"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 28 28"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </nz-list-item>
        </nz-list>
      </form>
    </nz-card>

    <!-- COMPLEMENT DE SALARIE HEADER -->
    <ng-template #salaireComplementHeader>
      <div class="w-full flex items-center space-x-1">
        <div class="w-6/12">
          <small class="tracking-wider text-xs">Libellé</small>
        </div>
        <div class="w-2/12">
          <small class="tracking-wider text-xs">Taux</small>
        </div>
        <div class="w-2/12">
          <small class="tracking-wider text-xs">Unité</small>
        </div>
        <div class="w-2/12"></div>
      </div>
      <div class="w-full flex items-center space-x-1">
        <div class="w-6/12">
          <div class="select-menu">
            <nz-select
              #inputElement
              nzPlaceHolder="Libellé"
              nzBorderless
              [ngStyle]="{ borbert: 'none', background: 'transparent' }"
              [nzDropdownRender]="renderComplementLibelleTemplate"
            >
              <nz-option
                *ngFor="let item of complementSalaire$ | async"
                [nzLabel]="item?.libelle | titlecase"
                [nzValue]="item"
              ></nz-option>
              <ng-template #renderComplementLibelleTemplate>
                <div class="divider"></div>
                <div
                  class="w-full whitespace-nowrap flex items-start space-x-1"
                >
                  <div class="w-4/6">
                    <input
                      placeholder="Libellé"
                      type="text"
                      class="inputForm"
                      #inputElement
                    />
                  </div>

                  <div class="w-2/6">
                    <button
                      class="btn-icon group/button"
                      nz-tooltip
                      nzTooltipPlacement="top"
                      nzTooltipTitle="Ajouter le nouveau libellé"
                      (click)="addItem(inputElement, tauxElement, unitElement)"
                    >
                      <svg
                        class="iconSvg"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 28 28"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </ng-template>
            </nz-select>
          </div>
        </div>
        <div class="w-2/12">
          <div class="numberForm">
            <nz-input-number
              #tauxElement
              aria-placeholder="'Taux'"
              nzPlaceHolder="Taux"
              class="numberField"
              [nzMin]="0"
            ></nz-input-number>
          </div>
        </div>
        <div class="w-2/12">
          <div class="select-menu">
            <nz-select
              #unitElement
              nzPlaceHolder="Unité"
              nzBorderless
              [ngStyle]="{ borbert: 'none', background: 'transparent' }"
            >
              <nz-option
                *ngFor="let el of unit_types$ | async"
                [nzLabel]="el"
                [nzValue]="el"
              ></nz-option>
            </nz-select>
          </div>
        </div>

        <div class="w-2/12">
          <button
            [class]="
              !inputElement || !tauxElement || !unitElement
                ? 'btn-disabled'
                : 'btn-default'
            "
            (click)="
              addItem(inputElement, tauxElement, unitElement);
              (inputElement.clearInput);
              tauxElement.setValue(0);
              (unitElement.clearInput)
            "
            [disabled]="!inputElement || !tauxElement || !unitElement"
          >
            Ajouter
          </button>
        </div>
      </div>
    </ng-template>

    <ng-template #noComplementsSalaire>
      <app-no-result
        [icon]="'subject'"
        [isButton]="false"
        [description]="noComplementsSalaireDefinition"
      ></app-no-result>
    </ng-template>
    <nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
  </form>
</nz-card>
