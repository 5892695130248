<nz-table #basicTable [nzData]="listOfData">
  <thead>
    <tr>
      <th>Identité</th>
      <th>Entité</th>
      <th>E-mail</th>
      <th>Mobile</th>
      <th>Action</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of basicTable.data">
      <td>{{ data.name }}</td>
      <td>{{ data.age }}</td>
      <td>{{ data.mobile }}</td>

      <td>{{ data.address }}</td>

      <td>
        <a>Action 一 {{ data.name }}</a>
        <nz-divider nzType="vertical"></nz-divider>
        <a>Delete</a>
      </td>
    </tr>
  </tbody>
</nz-table>
