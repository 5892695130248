<cdk-virtual-scroll-viewport
  itemSize="80"
  class="viewport"
  *ngIf="source | size; else noData"
>
  <nz-row
    nzAlign="middle"
    nzGutter="0"
    nzJustify="start"
    *cdkVirtualFor="let item of source"
    class="item"
  >
    <ng-container *ngIf="type === 'entites'">
      <nz-col nzSpan="4">
        <small>{{ item.dateUpdate | dateFr : "time" }}</small>
      </nz-col>
      <nz-col nzSpan="10">
        <strong>{{ item.nom_raison_sociale | titlecase }}</strong>
      </nz-col>
      <nz-col nzSpan="1">
        <i nz-icon nzType="info-circle"></i>
      </nz-col>
      <nz-col nzSpan="3">
        <small>{{ item.type }}</small>
      </nz-col>
    </ng-container>

    <ng-container *ngIf="type === 'participants'">
      <nz-col nzSpan="4">
        <small>{{ item.dateUpdate | dateFr : "time" }}</small>
      </nz-col>
      <nz-col nzSpan="10">
        <strong>{{ item?.lastName | titlecase | carLimitation : 15 }}</strong>
      </nz-col>
      <nz-col nzSpan="10">
        <strong>{{ item?.firstName | titlecase | carLimitation : 15 }}</strong>
      </nz-col>
      <nz-col nzSpan="1">
        <i nz-icon nzType="info-circle"></i>
      </nz-col>
      <nz-col nzSpan="3">
        <small>{{ item.type }}</small>
      </nz-col>
    </ng-container>
    <ng-container *ngIf="type === 'metiers'"> </ng-container>
  </nz-row>
</cdk-virtual-scroll-viewport>

<ng-template #noData>
  <nz-empty nzNotFoundImage="simple"></nz-empty>
</ng-template>
