import { Injectable } from '@angular/core';
import { Title, TransferState } from '@angular/platform-browser';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'any',
})
export class RouterActivateStateService implements Resolve<Observable<any>> {
  constructor(
    private routeTitle: Title,
    private transferState: TransferState
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    let id = route.parent?.params['id'];
    let data = route.data;
    let title = this.routeTitle.getTitle();
    let transfer = this.transferState.toJson();

    const routeState = { id, data };
    return of(routeState);
  }
}
