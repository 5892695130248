import {
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef,
  TemplateRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable, of } from 'rxjs';
import { ParticipantDiagnosticState } from '../../store/participant-diagnostic.reducer';
import * as fromParticipantDiagnosticAction from '../../store/participant-diagnostic.actions';
import * as fromParticipantDiagnosticSelector from '../../store/participant-diagnostic.selectors';
import { ParticipantId } from 'src/app/components/participant/store/participant.model';
import { ParticipantState } from 'src/app/components/participant/store/participant.reducer';
import * as fromParticipantSelector from 'src/app/components/participant/store/participant.selectors';
import { ParticipantDiagnosticFormComponent } from './../participant-diagnostic-form/participant-diagnostic-form.component';
import { UtilitiesState } from 'src/app/contents/components/utilities/store/utilities.reducer';
import * as fromUtilitiesAction from 'src/app/contents/components/utilities/store/utilities.actions';
import * as fromUtilitiesSelector from 'src/app/contents/components/utilities/store/utilities.selectors';

@Component({
  selector: 'app-participant-diagnostic-item',
  templateUrl: './participant-diagnostic-item.component.html',
  styleUrls: ['./participant-diagnostic-item.component.scss'],
})
export class ParticipantDiagnosticItemComponent implements OnInit {
  participant$: Observable<ParticipantId | any> = of(null);
  data$: Observable<any> = of(null);
  diagnostic$: Observable<any> = of(null);
  diagnosticItemTitle: string = 'Diagnostic';
  //VARIABLES
  icon: string = 'fact_check';
  title: string = 'Diagnostic';
  addtitle: string = 'Ajouter un diagnostic';
  notitle: string = 'Aucun diagnostic';
  description: string =
    "Le diagnostic est établi par un professionnel de l'inclusion sur deux axes : la situation professionnelle et la situation sociale de la personne en insertion.";

  modalWidth: number = 1100;
  deleteType: string = 'Voulez-vous supprimer ce diagnostic ?';
  @ViewChild('diagnosticTitleForm', { static: false }) diagnosticTitleForm:
    | TemplateRef<any>
    | any;

  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private diagnosticStore: Store<ParticipantDiagnosticState>,
    private participantStore: Store<ParticipantState>,
    private router: Router,
    private activateRouter: ActivatedRoute,
    private utilitiesStore: Store<UtilitiesState>
  ) {}

  ngOnInit(): void {
    this.getParticipant();
    this.getDiagnostic();
    this.loadReferentiels();
  }

  getRouteData(): void {
    this.data$ = this.activateRouter.data;
  }

  getParticipant(): void {
    this.participant$ = this.participantStore.select(
      fromParticipantSelector.participant
    );
  }
  getDiagnostic(): void {
    this.diagnostic$ = this.diagnosticStore.select(
      fromParticipantDiagnosticSelector.diagnostic
    );
  }

  loadReferentiels(): void {
    this.loadAllocationsReferentiel();
    this.loadSejourReferentiel();
    this.loadCriteresReferentiel();
  }

  loadAllocationsReferentiel(): void {
    this.utilitiesStore.dispatch(
      fromUtilitiesAction.loadAllocationReferentiel()
    );
  }

  loadSejourReferentiel(): void {
    this.utilitiesStore.dispatch(fromUtilitiesAction.loadSejourReferentiel());
  }

  loadCriteresReferentiel(): void {
    this.utilitiesStore.dispatch(fromUtilitiesAction.loadCriteresReferentiel());
  }

  onBack(participant: ParticipantId): void {
    if (!participant) return;

    this.router.navigate(['/participant', participant.id, 'diagnostics']);
  }

  onDropdownAction(
    event: string,
    participant: ParticipantId,
    diagnostic: any
  ): void {
    switch (event) {
      case 'update':
        this.onDiagnosticUpdate(participant, diagnostic);
        break;

      case 'delete':
        this.onDelete(participant, diagnostic);
        break;

      case 'exportPDF':
        break;
      case 'exportWORD':
        break;

      default:
        break;
    }
  }

  onDiagnosticUpdate(participant: ParticipantId, diagnostic: any): void {
    if (!participant) return;
    const modal = this.modal.create({
      nzContent: ParticipantDiagnosticFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.diagnosticTitleForm,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });
    const instance = modal.getContentComponent();
    instance.diagnostic$ = of(diagnostic);
    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });
    instance.update.subscribe((diagnostic): any => {
      if (!diagnostic) return;
      this.onUpdate(participant, diagnostic);
      modal.close();
    });
  }
  onNew(participant: ParticipantId): void {
    if (!participant) return;
    const modal = this.modal.create({
      nzContent: ParticipantDiagnosticFormComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: this.modalWidth,
      nzFooter: [],
      nzTitle: this.diagnosticTitleForm,
      nzComponentParams: {},
      nzCloseIcon: '',
      nzClosable: false,
      nzStyle: { top: '20px' },
    });
    const instance = modal.getContentComponent();
    instance.cancel.subscribe((event): any => {
      if (!event) {
        return null;
      } else {
        modal.close();
      }
    });

    instance.add.subscribe((diagnostic): any => {
      if (!diagnostic) return;
      this.onAdd(participant, diagnostic);
      modal.close();
    });
  }

  onAdd(participant: ParticipantId, diagnostic: any): void {
    if (!diagnostic || !participant) return;

    const { id } = participant;
    this.diagnosticStore.dispatch(
      fromParticipantDiagnosticAction.addParticipantDiagnostic({
        id,
        diagnostic,
      })
    );
  }

  onUpdate(participant: ParticipantId, diagnostic: any): void {
    if (!participant || !diagnostic) return;
    this.diagnosticStore.dispatch(
      fromParticipantDiagnosticAction.updateParticipantDiagnostic({
        id: participant.id,
        diagnostic,
      })
    );
  }

  onExportPDF(user: any, participant: ParticipantId, diagnostic: any): void {
    if (!user || !participant || !diagnostic) return;
    this.diagnosticStore.dispatch(
      fromParticipantDiagnosticAction.exportParticipantDiagnosticPDF({
        participant,
        diagnostic,
      })
    );
  }

  oExportWORD(user: any, participant: ParticipantId, diagnostic: any): void {
    if (!user || !participant || !diagnostic) return;
    this.diagnosticStore.dispatch(
      fromParticipantDiagnosticAction.exportParticipantDiagnosticWORD({
        participant,
        diagnostic,
      })
    );
  }

  onDelete(participant: ParticipantId, diagnostic: any): void {
    if (!participant || !diagnostic) return;
    this.diagnosticStore.dispatch(
      fromParticipantDiagnosticAction.deleteParticipantDiagnostic({
        participantId: participant.id,
        id: diagnostic.id,
      })
    );
  }
}
