import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  ProjetPrpfessionnelState,
  projetProfessionnelsFeatureKey,
  selectAll,
} from './projet-professionnel.reducer';

export const selectProjetsProfessionnelsState =
  createFeatureSelector<ProjetPrpfessionnelState>(
    projetProfessionnelsFeatureKey
  );

export const projetsprofessionnels = createSelector(
  selectProjetsProfessionnelsState,
  selectAll
);

export const projetprofessionnel = createSelector(
  selectProjetsProfessionnelsState,
  (state: ProjetPrpfessionnelState) => state.projetProfessionnel
);
export const statistiques = createSelector(
  selectProjetsProfessionnelsState,
  (state: ProjetPrpfessionnelState) => state.statistiques
);

export const loading = createSelector(
  selectProjetsProfessionnelsState,
  (state: ProjetPrpfessionnelState) => state.loading
);

export const formations = createSelector(
  selectProjetsProfessionnelsState,
  (state: ProjetPrpfessionnelState) => state.formations
);

export const jobs = createSelector(
  selectProjetsProfessionnelsState,
  (state: ProjetPrpfessionnelState) => state.jobs
);

export const entitesSuggestions = createSelector(
  selectProjetsProfessionnelsState,
  (state: ProjetPrpfessionnelState) => state.entitesSuggestions
);

export const entites = createSelector(
  selectProjetsProfessionnelsState,
  (state: ProjetPrpfessionnelState) => state.entites
);

export const contacts = createSelector(
  selectProjetsProfessionnelsState,
  (state: ProjetPrpfessionnelState) => state.contactsSuggestions
);

export const evaluations = createSelector(
  selectProjetsProfessionnelsState,
  (state: ProjetPrpfessionnelState) => state.evaluations
);

export const todos = createSelector(
  selectProjetsProfessionnelsState,
  (state: ProjetPrpfessionnelState) => state.todos
);

export const formationsSuggestions = createSelector(
  selectProjetsProfessionnelsState,
  (state: ProjetPrpfessionnelState) => state.formationsSuggestions
);

export const formationSuggestionDetail = createSelector(
  selectProjetsProfessionnelsState,
  (state: ProjetPrpfessionnelState) => state.formationSuggestionDetail
);

export const error = createSelector(
  selectProjetsProfessionnelsState,
  (state: ProjetPrpfessionnelState) => state.error
);
