import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import {
  GeoCommune,
  GeoDepartement,
  GeoDistance,
  GeoDistanceInput,
  GeoRegion,
  GeoTerritoire,
} from './geo-territoire.model';

//REGION
export const loadGeoTerritoireRegions = createAction(
  '[GeoTerritoire/API] Load GeoTerritoires Regions'
);
export const loadGeoTerritoireRegionsSuccess = createAction(
  '[GeoTerritoire/API] Load GeoTerritoires Regions Success',
  props<{ regions: GeoRegion[] }>()
);
export const loadGeoTerritoireRegionsFailure = createAction(
  '[GeoTerritoire/API] Load GeoTerritoires Regions Failure',
  props<{ error: any }>()
);

//DEPARTEMENTS
export const loadGeoTerritoireDepartements = createAction(
  '[GeoTerritoire/API] Load GeoTerritoires Departements',
  props<{ code: string }>()
);
export const loadGeoTerritoireDepartementsSuccess = createAction(
  '[GeoTerritoire/API] Load GeoTerritoires Departements Success',
  props<{ region: any }>()
);
export const loadGeoTerritoireDepartementsFailure = createAction(
  '[GeoTerritoire/API] Load GeoTerritoires Departements Failure',
  props<{ error: any }>()
);

//COMMUNES
export const loadGeoTerritoireCommunes = createAction(
  '[GeoTerritoire/API] Load GeoTerritoires Communes',
  props<{ code: string }>()
);
export const loadGeoTerritoireCommunesSuccess = createAction(
  '[GeoTerritoire/API] Load GeoTerritoires Communes Success',
  props<{ communes: GeoCommune[] }>()
);
export const loadGeoTerritoireCommunesFailure = createAction(
  '[GeoTerritoire/API] Load GeoTerritoires Communes Failure',
  props<{ error: any }>()
);

//DISTANCE
export const loadGeoDistance = createAction(
  '[GeoTerritoire/API] Load Geo Distance',
  props<{ adresses: GeoDistanceInput }>()
);
export const loadGeoDistanceSuccess = createAction(
  '[GeoTerritoire/API] Load Geo Distance Success',
  props<{ distance: GeoDistance }>()
);
export const loadGeoDistanceFailure = createAction(
  '[GeoTerritoire/API] Load Geo Distance Failure',
  props<{ error: any }>()
);

//CLEAR ALL

export const clearGeoTerritoires = createAction(
  '[GeoTerritoire/API] Clear GeoTerritoires'
);
