import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  OperationEntiteState,
  operationEntitesFeatureKey,
  selectAll,
} from './operation-entites.reducer';

export const selectOperationState = createFeatureSelector<OperationEntiteState>(
  operationEntitesFeatureKey
);

export const entites = createSelector(selectOperationState, selectAll);

export const loading = createSelector(
  selectOperationState,
  (state: OperationEntiteState) => state.loading
);

export const entite = createSelector(
  selectOperationState,
  (state: OperationEntiteState) => state.entite
);

export const suivis = createSelector(
  selectOperationState,
  (state: OperationEntiteState) => state.suivis
);

export const documents = createSelector(
  selectOperationState,
  (state: OperationEntiteState) => state.documents
);

export const queries = createSelector(
  selectOperationState,
  (state: OperationEntiteState) => state.queries
);

export const error = createSelector(
  selectOperationState,
  (state: OperationEntiteState) => state.error
);
