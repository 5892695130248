import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { PlatformModule } from '@angular/cdk/platform';
import { PortalModule } from '@angular/cdk/portal';
import { MatIconModule } from '@angular/material/icon';
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    DragDropModule,
    ScrollingModule,
    PlatformModule,
    PortalModule,
    MatIconModule,
  ],
  exports: [
    DragDropModule,
    ScrollingModule,
    PlatformModule,
    PortalModule,
    MatIconModule,
  ],
})
export class MaterialModule {}
