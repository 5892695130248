<ng-container *ngIf="user$ | async as user">
  <nz-comment
    [nzAuthor]="!fullscreen ? message?.auteur?.displayName : ''"
    [nzDatetime]="itemMessageTime"
    [ngStyle]="{
      cursor: fullscreen ? 'pointer' : 'default'
    }"
    (click)="$event.stopPropagation(); onDisplay(message, fullscreen)"
  >
    <nz-avatar
      nzSize="small"
      nz-comment-avatar
      [nzSrc]="message.auteur.photo"
      [ngStyle]="{
        'background-color': message?.auteur?.setting?.color
          ? message?.auteur?.setting?.color
          : '#bfbfbf',
        color: 'white'
      }"
      *ngIf="message?.auteur?.photo; else avatarView"
    ></nz-avatar>

    <ng-template #avatarView>
      <nz-avatar
        nz-comment-avatar
        [nzText]="message?.auteur?.avatar"
        nzSize="small"
        [ngStyle]="{
          'background-color': message?.auteur?.setting?.color
            ? message?.auteur?.setting?.color
            : '#bfbfbf',
          color: 'white'
        }"
      ></nz-avatar>
    </ng-template>

    <ng-template #auteur>
      <nz-tag nzColor="geekblue">{{ message.auteur.displayName }}</nz-tag>
    </ng-template>

    <ng-template #itemMessageTime>
      <nz-row nzAlign="middle" nzJustify="end" [nzGutter]="[0, 0]">
        <nz-col
          [nzXs]="{ span: 0 }"
          [nzMd]="{ span: 0 }"
          [nzLg]="{ span: 24 }"
          [nzXl]="{ span: 24 }"
          [nzXXl]="{ span: 24 }"
        >
          <nz-divider nzOrientation="center" nzType="vertical"></nz-divider>
          {{ message?.dateStart | dateFr: "time" }}</nz-col
        >
      </nz-row>
    </ng-template>

    <nz-comment-content>
      <nz-row
        nzAlign="middle"
        nzGutter="0"
        nzJustify="start"
        class="title__MESSAGE"
      >
        <nz-col
          [nzXs]="{ span: 0 }"
          [nzMd]="{ span: 0 }"
          [nzLg]="{ span: 4 }"
          [nzXl]="{ span: 4 }"
          [nzXXl]="{ span: 4 }"
          *ngIf="!fullscreen"
          ><span>Objet : </span></nz-col
        >
        <nz-col [nzSpan]="fullscreen ? 24 : 20"
          ><span>{{ message.subtitle | carLimitation }}</span></nz-col
        >
      </nz-row>
    </nz-comment-content>

    <nz-comment-content *ngIf="!fullscreen" class="lastMessage">
      <nz-row nzAlign="middle" nzGutter="0" nzJustify="start">
        <nz-col nzSpan="1">
          <nz-badge
            [nzStatus]="
              (message.lastMessage | isToday) ? 'processing' : 'default'
            "
          ></nz-badge>
        </nz-col>
        <nz-col nzSpan="5">
          {{ message.dateUpdate | dateFr: "distance" }}
        </nz-col>
        <nz-col
          [nzXs]="{ span: 0 }"
          [nzMd]="{ span: 8 }"
          [nzLg]="{ span: 8 }"
          [nzXl]="{ span: 8 }"
          [nzXXl]="{ span: 8 }"
        >
          <span
            *ngIf="
              (user$ | async).id !== message?.lastMessage?.auteur?.id;
              else selfMessage
            "
            >{{
              message?.lastMessage?.auteur?.displayName | carLimitation: 20
            }}</span
          >
        </nz-col>
        <nz-col
          [nzXs]="{ span: 0 }"
          [nzMd]="{ span: 0 }"
          [nzLg]="{ span: 10 }"
          [nzXl]="{ span: 10 }"
          [nzXXl]="{ span: 10 }"
        >
          <p class="text__LastMessage">
            {{ message?.lastMessage?.text | carLimitation: 20 }}
          </p>
        </nz-col>
      </nz-row>
    </nz-comment-content>

    <nz-comment-content class="statistiques__MESSAGE">
      <nz-row nzAlign="middle" nzGutter="0" nzJustify="start">
        <nz-col
          [nzXs]="{ span: 12 }"
          [nzMd]="{ span: 12 }"
          [nzLg]="{ span: 12 }"
          [nzXl]="{ span: 12 }"
          [nzXXl]="{ span: 12 }"
        >
          <nz-statistic
            [nzValueStyle]="{
              'font-size': fullscreen ? statistiqueSize : statistiqueSizeMax
            }"
            [nzValue]="message?.messages | size"
            [nzPrefix]="prefixMessages"
          ></nz-statistic>
          <ng-template #prefixMessages
            ><i nz-icon nzType="message"></i
          ></ng-template>
        </nz-col>
        <nz-col
          [nzXs]="{ span: 0 }"
          [nzMd]="{ span: 0 }"
          [nzLg]="{ span: 12 }"
          [nzXl]="{ span: 12 }"
          [nzXXl]="{ span: 12 }"
        >
          <nz-avatar-group
            *ngIf="message && (message?.interlocuteurs | size) && !fullscreen"
          >
            <nz-avatar
              *ngFor="let item of message.interlocuteurs"
              [nzText]="item.avatar"
              nzSize="small"
              [ngStyle]="{
                'background-color': item?.setting?.color
                  ? item.setting?.color
                  : '#bfbfbf',
                color: 'white'
              }"
            ></nz-avatar>
          </nz-avatar-group>

          <nz-statistic
            *ngIf="message && (message?.interlocuteurs | size) && fullscreen"
            [nzValueStyle]="{
              'font-size': fullscreen ? statistiqueSize : statistiqueSizeMax
            }"
            [nzValue]="message?.interlocuteurs | size"
            [nzPrefix]="prefixInterlocuteurs"
          ></nz-statistic>
          <ng-template #prefixInterlocuteurs
            ><i nz-icon nzType="user"></i
          ></ng-template>
        </nz-col>
      </nz-row>
    </nz-comment-content>

    <nz-comment-action *ngIf="!fullscreen">
      <label
        class="action__message"
        (click)="$event.stopPropagation(); onDisplay(message, fullscreen)"
      >
        Afficher
      </label>
    </nz-comment-action>

    <nz-comment-action>
      <label
        *ngIf="message?.auteur?.id === user.id"
        class="action__message"
        nz-popconfirm
        nzPopconfirmTitle="Voulez-vous supprimer ces messages ?"
        nzPopconfirmPlacement="bottom"
        (nzOnConfirm)="onDelete(message, fullscreen)"
        (nzOnCancel)="onCancel()"
        (click)="$event.stopPropagation()"
      >
        <i nz-icon nzTheme="outline" nzType="delete"></i>
        <span>Supprimer</span>
      </label>
    </nz-comment-action>
  </nz-comment>

  <ng-template #selfMessage>
    <span>Vous</span>
  </ng-template>
</ng-container>
<nz-divider nzOrientation="center" nzType="horizontal"></nz-divider>
