import {
  NzNotificationPlacement,
  NzNotificationService,
} from 'ng-zorro-antd/notification';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EntiteSuivisService } from 'src/app/service/entite-suivis.service';
import { EntiteSuiviState } from './entite-suivi.reducer';
import * as fromEntiteSuiviAction from './entite-suivi.actions';
import { catchError, map, mergeMap, of, tap } from 'rxjs';

const NOTIFICATION_PLACEMENT: NzNotificationPlacement = 'bottomRight';

@Injectable()
export class EntiteSuiviEffects {
  loadEntiteSuivis$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromEntiteSuiviAction.loadEntiteSuivis),
      mergeMap((action) =>
        this.entiteSuiviService.getAll(action.id).pipe(
          map((respons: any) => {
            const suivis = respons.data.entiteSuivis;
            return fromEntiteSuiviAction.loadEntiteSuivisSuccess({ suivis });
          }),
          catchError((error) => {
            this.onErrorNotification(error, 'LOAD_ENTITE_SUIVIS');
            return of(fromEntiteSuiviAction.loadEntiteSuivisFailure({ error }));
          })
        )
      )
    )
  );
  loadEntiteSuivi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromEntiteSuiviAction.loadEntiteSuivi),
      mergeMap((action) =>
        this.entiteSuiviService.getOne(action.entiteId, action.id).pipe(
          map((respons: any) => {
            const suivi = respons.data.entiteSuivi;
            return fromEntiteSuiviAction.loadEntiteSuiviSuccess({ suivi });
          }),
          catchError((error) => {
            this.onErrorNotification(error, 'LOAD_ENTITE_SUIVI');
            return of(fromEntiteSuiviAction.loadEntiteSuiviFailure({ error }));
          })
        )
      )
    )
  );

  addEntiteSuivi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromEntiteSuiviAction.addEntiteSuivi),
      tap(() => {
        this.notification.info('Nouveau', 'Création du suivi en cours...', {
          nzAnimate: true,
          nzDuration: 6000,
          nzPlacement: NOTIFICATION_PLACEMENT,
          nzKey: 'CREATION_ENTITE_SUIVI',
        });
      }),
      mergeMap((action) =>
        this.entiteSuiviService.addOne(action.id, action.suivi).pipe(
          map((respons: any) => {
            const suivi = respons.data.createEntiteSuivi;
            this.notification.success(
              'Nouveau',
              'Nouveau suivi en ajouté avec succès',
              {
                nzAnimate: true,
                nzDuration: 8000,
                nzPlacement: NOTIFICATION_PLACEMENT,
                nzKey: 'CREATION_ENTITE_SUIVI',
              }
            );

            return fromEntiteSuiviAction.addEntiteSuiviSuccess({ suivi });
          }),
          catchError((error) => {
            this.onErrorNotification(error, 'CREATION_ENTITE_SUIVI');
            return of(fromEntiteSuiviAction.addEntiteSuiviFailure({ error }));
          })
        )
      )
    )
  );
  updateEntiteSuivi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromEntiteSuiviAction.updateEntiteSuivi),
      tap(() => {
        this.notification.info(
          'Modification',
          'Modification du suivi en cours...',
          {
            nzAnimate: true,
            nzDuration: 6000,
            nzPlacement: NOTIFICATION_PLACEMENT,
            nzKey: 'UPDATE_ENTITE_SUIVI',
          }
        );
      }),
      mergeMap((action) =>
        this.entiteSuiviService.updateOne(action.id, action.suivi).pipe(
          map((respons: any) => {
            const success = respons.data.updateEntiteSuivi;
            this.notification.success(
              'Modification',
              'Le suivi a été modifié avec succès',
              {
                nzAnimate: true,
                nzDuration: 8000,
                nzPlacement: NOTIFICATION_PLACEMENT,
                nzKey: 'UPDATE_ENTITE_SUIVI',
              }
            );

            return fromEntiteSuiviAction.updateEntiteSuiviSuccess({ success });
          }),
          catchError((error) => {
            this.onErrorNotification(error, 'UPDATE_ENTITE_SUIVI');
            return of(
              fromEntiteSuiviAction.updateEntiteSuiviFailure({ error })
            );
          })
        )
      )
    )
  );
  deleteEntiteSuivi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromEntiteSuiviAction.deleteEntiteSuivi),
      tap(() => {
        this.notification.info(
          'Suppression',
          'Suprpession du suivi en cours...',
          {
            nzAnimate: true,
            nzDuration: 6000,
            nzPlacement: NOTIFICATION_PLACEMENT,
            nzKey: 'DELETE_ENTITE_SUIVI',
          }
        );
      }),
      mergeMap((action) =>
        this.entiteSuiviService.deleteOne(action.entiteId, action.id).pipe(
          map((respons: any) => {
            const success = respons.data.deleteEntiteSuivi;
            this.notification.success('Suppression', success, {
              nzAnimate: true,
              nzDuration: 8000,
              nzPlacement: NOTIFICATION_PLACEMENT,
              nzKey: 'DELETE_ENTITE_SUIVI',
            });

            return fromEntiteSuiviAction.deleteEntiteSuiviSuccess({ success });
          }),
          catchError((error) => {
            this.onErrorNotification(error, 'DELETE_ENTITE_SUIVI');
            return of(
              fromEntiteSuiviAction.deleteEntiteSuiviFailure({ error })
            );
          })
        )
      )
    )
  );
  deleteEntiteSuivis$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromEntiteSuiviAction.deleteEntiteSuivis),
      tap(() => {
        this.notification.info(
          'Tout supprimer',
          'Suppression de tous les suivis en cours...',
          {
            nzAnimate: true,
            nzDuration: 6000,
            nzPlacement: NOTIFICATION_PLACEMENT,
            nzKey: 'DELETE_ENTITE_SUIVIS',
          }
        );
      }),
      mergeMap((action) =>
        this.entiteSuiviService.deleteAll(action.id).pipe(
          map((respons: any) => {
            const success = respons.data.deleteEntiteSuivis;
            this.notification.success('Tout supprimer', success, {
              nzAnimate: true,
              nzDuration: 8000,
              nzPlacement: NOTIFICATION_PLACEMENT,
              nzKey: 'DELETE_ENTITE_SUIVIS',
            });

            return fromEntiteSuiviAction.deleteEntiteSuivisSuccess({ success });
          }),
          catchError((error) => {
            this.onErrorNotification(error, 'DELETE_ENTITE_SUIVIS');
            return of(
              fromEntiteSuiviAction.deleteEntiteSuivisFailure({ error })
            );
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private entiteSuiviService: EntiteSuivisService,
    private notification: NzNotificationService
  ) {}

  onErrorNotification(error: any, key: string): void {
    this.notification.error('Error', error.message, {
      nzDuration: 10000,
      nzAnimate: true,
      nzPlacement: NOTIFICATION_PLACEMENT,
      nzKey: key ? key : '',
    });
  }
}
